import React from "react";
import { MediumOrLarger, SmallOrSmaller } from "../../utilities/Responsive";
import { Route, Switch, Redirect } from "react-router-dom";

import { DropdownButton, MenuItem } from "react-bootstrap";

import {
  DataStore,
  DataActions,
  AuthorizationService,
  // ApiService
} from "../../services/AxoServices";

import {
  Icon,
  AxoLocal,
  LexNavButton,
  withRouter,
  FileViewerModal,
} from "../../utilities/LexUtilities";

import InvoicesViewV2 from "./InvoicesViewV2";
import InvoiceSingleView from "./InvoiceSingleView";
import ProductsView from "./ProductsView";
import InvoiceCreateView from "./InvoiceCreateView";
import InvoicePDFsViewV2 from "./InvoicePDFsViewV2";
import InvoiceSubscriptionsView from "./InvoiceSubscriptionsView";

class InvoicesTabView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      newPDF: {},
      //Internal state here
    };
    this.fileModal = React.createRef();
  }

  handleNavigation = (path) => {
    let { match } = this.props;

    this.props.history.push(match.path + "/" + path);
  };

  renderLargeScreenNavigationBar = () => {
    let { showDropdowns, location, userProfile } = this.props;

    let path = location.pathname;

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <MediumOrLarger>
        <div className="axo-nav-lg" id="noprint">
          {!showDropdowns ? (
            <div className="nopadding paddingToppx tabBannerRow">
              <div
                className="btn-group btn-group-justified"
                role="group"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  paddingLeft: "2px",
                }}
              >
                <LexNavButton
                  className="axonavTab axonavTabcolora"
                  ls
                  glyph="icon-fontello-th-2"
                  eventKey="Table"
                  path={path}
                  onClick={this.handleNavigation}
                >
                  <AxoLocal
                    entity="InvoicePDFsViewinvoices"
                    defaultValue={"Fakturaer"}
                  />
                </LexNavButton>
                {/* <LexNavButton className='axonavTab' ls glyph='icon-fontello-th-2' eventKey='PDF' path={path} onClick={this.handleNavigation}>
                  PDF
                </LexNavButton> */}
                <LexNavButton
                  className="axonavTab axonavTabcolorb"
                  ls
                  glyph="icon-fontello-plus-3"
                  eventKey="Create"
                  path={path}
                  onClick={this.handleNavigation}
                >
                  <AxoLocal
                    entity="axoEntityidcode63"
                    defaultValue={"Ny faktura"}
                  />
                </LexNavButton>
                <LexNavButton
                  className="axonavTab axonavTabcolorc"
                  ls
                  glyph="icon-fontello-plus-3"
                  eventKey="Products"
                  path={path}
                  onClick={this.handleNavigation}
                >
                  <AxoLocal
                    entity="presentationMarketingA"
                    defaultValue="Produkter"
                  />
                </LexNavButton>
                {userType !== "AccountingClient" && (
                  <>
                    <LexNavButton
                      className="axonavTab axonavTabcolord"
                      ls
                      glyph="icon-fontello-th-2"
                      eventKey="Subscriptions"
                      path={path}
                      onClick={this.handleNavigation}
                    >
                      <AxoLocal
                        entity="AdminTabView2"
                        defaultValue="Abonnementer"
                      />
                    </LexNavButton>
                    <LexNavButton
                      className="axonavTab axonavTabcolore"
                      ls
                      glyph="icon-fontello-plus-3"
                      eventKey="AddSubscription"
                      path={path}
                      onClick={this.handleNavigation}
                    >
                      <AxoLocal
                        entity="axoEntityidcode65"
                        defaultValue={"Nyt abonnement"}
                      />
                    </LexNavButton>
                  </>
                )}

                <LexNavButton
                  ls
                  listButton
                  className="axonavTab showListButton"
                  onClick={() => {
                    DataStore.setShowDropDowns(true);
                  }}
                >
                  <div className="flexbox-center">
                    <Icon
                      glyph="icon-fontello-align-justify"
                      style={{ fontSize: "30px" }}
                    />
                    &nbsp;
                    <AxoLocal
                      entity="AccountingTabViewEntity66"
                      defaultValue={"Liste Menu"}
                    />
                  </div>
                </LexNavButton>
              </div>
            </div>
          ) : (
            <div style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Table")}
                >
                  <AxoLocal
                    entity="InvoicePDFsViewinvoices"
                    defaultValue={"Fakturaer"}
                  />
                </MenuItem>
                {/* <MenuItem style={{ paddingBottom: '10px'}} className='axonavTab AxotabDropdownmenu' onClick={() => this.handleNavigation('PDF')}>
                  PDF
                </MenuItem> */}
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Create")}
                >
                  <AxoLocal
                    entity="axoEntityidcode63"
                    defaultValue={"Ny faktura"}
                  />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Products")}
                >
                  <AxoLocal
                    entity="presentationMarketingA"
                    defaultValue="Produkter"
                  />
                </MenuItem>
                {userType !== "AccountingClient" && (
                  <MenuItem
                    style={{ paddingBottom: "10px" }}
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleNavigation("Subscriptions")}
                  >
                    <AxoLocal
                      entity="AdminTabView2"
                      defaultValue="Abonnementer"
                    />
                  </MenuItem>
                )}
                {userType !== "AccountingClient" && (
                  <MenuItem
                    style={{ paddingBottom: "10px" }}
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleNavigation("AddSubscription")}
                  >
                    <AxoLocal
                      entity="axoEntityidcode65"
                      defaultValue={"Nyt abonnement"}
                    />
                  </MenuItem>
                )}
              </DropdownButton>
            </div>
          )}
        </div>
      </MediumOrLarger>
    );
  };

  renderSmallScreenNavigationBar = () => {
    return (
      <SmallOrSmaller>
        <div className="axo-nav" id="noprint">
          <div style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Table")}
              >
                <AxoLocal
                  entity="InvoicePDFsViewinvoices"
                  defaultValue={"Fakturaer"}
                />
              </MenuItem>
              {/* <MenuItem style={{ paddingBottom: '10px'}} className='axonavTab AxotabDropdownmenu' onClick={() => this.handleNavigation('PDF')}>
                PDF
              </MenuItem> */}
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Create")}
              >
                <AxoLocal
                  entity="axoEntityidcode63"
                  defaultValue={"Ny faktura"}
                />
              </MenuItem>
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Products")}
              >
                <AxoLocal
                  entity="presentationMarketingA"
                  defaultValue="Produkter"
                />
              </MenuItem>
            </DropdownButton>
          </div>
        </div>
      </SmallOrSmaller>
    );
  };

  onToggleInvoiceCards = () => {
    let { userSettings } = this.props;

    DataActions.updateUserSettings({
      ...userSettings,
      showInvoiceCards: !userSettings.showInvoiceCards,
    });
  };

  onShowPDF = (PDF) => {
    this.fileModal.current.open([PDF]);
  };

  tester = {};

  onCreateInvoice = async (invoice) => {
    try {
      let response = await DataActions.createInvoice(invoice);
      if (!response.ok) {
        return false;
      }
      let newInvoice = await response.json();
      this.onCreatedInvoice(newInvoice);

      if (invoice.isSubscription) {
        //Fetch potential new subscription invoices
        DataStore.fetchInvoices();
      }

      return true;
    } catch (reason) {
      console.log(reason);
      return false;
    }
  };

  onCreatedInvoice = (invoice) => {
    let route = "";
    if (invoice.isSubscription) {
      route = "Subscriptions";
    } else {
      route = "Single/" + invoice.id;
      if (invoice.status === "Approved") {
        route += "?approve=true"; //Auto approve individual invoices.
      }
    }

    this.handleNavigation(route);
  };

  render() {
    let {
      match,
      products,
      invoices,
      invoiceSubscriptions,
      userProfile,
      contactMap,
      clientPlan,
      fiscalYears,
      selectedFiscalYear,
      selectedStartDate,
      selectedEndDate,
      selectedContact,
      handleSelectedClient,
      onSelectFiscalYear,
      onSelectStartDate,
      onSelectEndDate,
      createContact,
      updateContact,
      myContactInfo, //Used on the clientDashboard
    } = this.props;

    return (
      <div>
        {this.renderSmallScreenNavigationBar()}
        {this.renderLargeScreenNavigationBar()}
        <Switch>
          <Route
            path={match.path + "/Table"}
            render={(props) => (
              <InvoicesViewV2
                history={props.history}
                invoices={invoices}
                userProfile={userProfile}
                userSettings={this.props.userSettings}
                clientsWithInvoices={this.props.clientsWithInvoices}
                contactMap={this.props.contactMap}
                fiscalYears={fiscalYears}
                selectedFiscalYear={selectedFiscalYear}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                selectedContact={selectedContact}
                handleSelectedClient={handleSelectedClient}
                onSelectFiscalYear={onSelectFiscalYear}
                onSelectStartDate={onSelectStartDate}
                onSelectEndDate={onSelectEndDate}
                // newInvoice={this.state.newInvoice}
                newInvoice={{}}
                onCreatePDF={() => {}}
                onCreateAndSendPDF={() => {}}
                selectedOptionId={this.state.selectedClientId}
                forceShowCards={this.props.userSettings.showInvoiceCards}
                onToggleCards={this.onToggleInvoiceCards}
                clientLabels={this.props.clientLabels}
                onCreateInvoice={this.onCreateInvoice}
                clientPlan={clientPlan}
                myContactInfo={myContactInfo}
              />
            )}
          />
          <Route
            path={match.path + "/PDF"}
            render={(props) => (
              <InvoicePDFsViewV2
                PDFs={this.props.PDFs}
                documentMap={this.props.documentMap}
                newPDF={this.state.newPDF}
                allClients={this.props.allClients}
                userProfile={userProfile}
              />
            )}
          />
          <Route
            path={match.path + "/Create"}
            render={(props) => (
              <InvoiceCreateView
                history={props.history}
                invoices={invoices}
                products={products}
                userProfile={userProfile}
                contactMap={contactMap}
                onCreateInvoice={this.onCreateInvoice}
                onCreatedInvoice={this.onCreatedInvoice}
                clientPlan={clientPlan}
                createContact={createContact}
                updateContact={updateContact}
              />
            )}
          />
          <Route
            path={match.path + "/Single/:id"}
            render={(props) => {
              return (
                <InvoiceSingleView
                  id={props.match.params.id}
                  invoices={invoices}
                  products={products}
                  userProfile={userProfile}
                  contactMap={contactMap}
                  onShowPDF={this.onShowPDF}
                  onCreateInvoice={this.onCreateInvoice}
                  clientPlan={clientPlan}
                  fiscalYears={fiscalYears}
                  createContact={createContact}
                  updateContact={updateContact}
                />
              );
            }}
          />
          <Route
            path={match.path + "/Products"}
            render={() => (
              <ProductsView
                entries={products}
                clientPlan={clientPlan}
                userProfile={userProfile}
              />
            )}
          />
          <Route
            path={match.path + "/Subscriptions"}
            render={(props) => (
              <InvoiceSubscriptionsView
                history={props.history}
                isSubscriptionTable
                invoices={invoiceSubscriptions}
                userProfile={userProfile}
                userSettings={this.props.userSettings}
                clientsWithInvoices={this.props.clientsWithInvoices}
                contactMap={this.props.contactMap}
                selectedContact={selectedContact}
                handleSelectedClient={handleSelectedClient}
                onSelectFiscalYear={onSelectFiscalYear}
                onSelectStartDate={onSelectStartDate}
                onSelectEndDate={onSelectEndDate}
                // newInvoice={this.state.newInvoice}
                newInvoice={{}}
                onCreatePDF={() => {}}
                onCreateAndSendPDF={() => {}}
                selectedOptionId={this.state.selectedClientId}
                forceShowCards={this.props.userSettings.showInvoiceCards}
                onToggleCards={this.onToggleInvoiceCards}
                clientLabels={this.props.clientLabels}
                onCreateInvoice={this.onCreateInvoice}
                clientPlan={clientPlan}
              />
            )}
          />
          <Route
            path={match.path + "/AddSubscription"}
            render={(props) => (
              <InvoiceCreateView
                history={props.history}
                invoices={invoices}
                products={products}
                userProfile={userProfile}
                contactMap={contactMap}
                onCreateInvoice={this.onCreateInvoice}
                onCreatedInvoice={this.onCreatedInvoice}
                clientPlan={clientPlan}
                createContact={createContact}
                updateContact={updateContact}
                isSubscription
              />
            )}
          />
          <Route
            path={match.path}
            render={(props) => (
              <Redirect {...props} to={match.path + "/Table"} />
            )}
          />
        </Switch>
        <FileViewerModal size="large" ref={this.fileModal} />
      </div>
    );
  }
}

export default withRouter(InvoicesTabView);
