export default () => {
  if (window.innerWidth < 768) {
    return -105;
  } else if (window.innerWidth < 992) {
    return -110;
  } else if (window.innerWidth < 1200) {
    return -70;
  }
  return -90;
};
