import React from "react";
import classNames from "classnames";

class AxoListGroup extends React.PureComponent {
  render() {
    var props = Object.assign({}, this.props);
    props.className = props.className
      ? (props.className += " axogrouplist")
      : "axogrouplist";

    return <div {...props}>{this.props.children}</div>;
  }
}

class AxoListItem extends React.PureComponent {
  //Props
  //active
  render() {
    var classes = classNames(
      this.props.className,
      { active: this.props.active },
      "axolistitem"
    );

    var props = Object.assign({}, this.props);
    delete props.active;
    props.className = classes;

    return <div {...props}>{this.props.children}</div>;
  }
}

export { AxoListGroup, AxoListItem };
