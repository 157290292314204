// @ts-check
import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import UserInfoService from "../../services/UserInfoService";
import InlineEdit from "./../../utilities/InlineEdit";
import ModalService from "../../services/ModalService";
import { ctx } from "../../utilities/L20n/L20n";
import Conditional from "../../utilities/Conditional";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import Slider from "react-slick";
import "../../resources/css/slick.min.css";
import "../../resources/css/slick-theme.min.css";
import { UserSearchBox } from "../../utilities/LexUtilities";
import { Image, InputGroup, Button } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class PrivateConversationBanner extends React.PureComponent {
  //Props
  //privateConversation
  //conversationUsers
  //invitableUsers
  //currentUser
  //invitableUserProfiles
  //isOwner
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: {
        id: 0,
      },
      invitedUser: false,
    };
  }

  renderLocationInfo = (userProfile) => {
    var showCity = userProfile.city && userProfile.showCity;
    var showCountry = userProfile.country && userProfile.showCountry;
    if (showCity && showCountry) {
      return userProfile.city + ", " + userProfile.country;
    } else if (showCity) {
      return userProfile.city;
    } else if (showCountry) {
      return userProfile.country;
    }
    return "";
  };

  renderUserInfo = (userProfile) => {
    var showPosition = userProfile.position && userProfile.showPosition;
    var showCity = userProfile.city && userProfile.showCity;
    if (showPosition && showCity) {
      return userProfile.position + ", " + userProfile.city;
    } else if (showPosition) {
      return userProfile.position;
    } else if (showCity) {
      return userProfile.city;
    }
    return <span style={{ visibility: "hidden" }}>---</span>;
  };

  renderEjectButton = (userProfile) => {
    var text =
      this.props.currentUser.id === userProfile.id ? (
        <AxoLocal
          entity="socialPrivateConversationBannerLeaveRoom"
          defaultValue={"Forlad rummet"}
        />
      ) : (
        <AxoLocal
          entity="socialPrivateConversationBannerRemoveUser"
          defaultValue={"Fjern bruger fra rum"}
        />
      );
    var locked = this.props.isLocked && this.props.isOwner;
    var notOwnerOrCurrent = !(
      this.props.isOwner || this.props.currentUser.id === userProfile.id
    );
    if (locked || notOwnerOrCurrent) {
      return (
        <div style={{ visibility: "hidden" }} className="text-center">
          <Button className="Lex-button-2 ">
            <span>{text}</span>
          </Button>
        </div>
      );
    }

    return (
      <div className="text-center">
        <Button
          className="Lex-button-2"
          onClick={this.props.onRemoveUser.bind(this, userProfile.id)}
        >
          <span>{text}</span>
        </Button>
      </div>
    );
  };

  getBackgroundImageUrl = (userProfile) => {
    return "url(" + UserInfoService.getBannerImageSource(userProfile) + ")";
  };

  renderUserCard = (userProfile) => {
    var name = userProfile.firstName + " " + userProfile.lastName;
    var userInfo = this.renderUserInfo(userProfile);
    var cardStyle = {
      position: "static",
      paddingTop: "10px",
      paddingBottom: "10px",
      width: "auto",
    };
    return (
      <div
        key={userProfile.id}
        style={{
          border: "solid",
          borderWidth: "1px",
          borderColor: "white",
          backgroundImage: this.getBackgroundImageUrl(userProfile),
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="socialAxoimg" style={cardStyle}>
          <Image
            src={UserInfoService.getProfileImageSource(userProfile)}
            height="50"
            width="50"
            style={{
              display: "block",
              borderRadius: 100,
              border: "2px solid #fff",
              margin: "auto",
              marginTop: 25,
            }}
          />
          {userProfile.firstName || userProfile.lastName ? (
            <h4 className="axowhite text-center">{name}</h4>
          ) : (
            <h4
              style={{ visibility: "hidden" }}
              className="axowhite text-center"
            >
              <AxoLocal
                entity="paymentDate12a"
                defaultValue={"CaseEditFormfirstName Hidden"}
              />
            </h4>
          )}
          {userInfo ? (
            <h5 className="axowhite text-center" style={{ opacity: 0.8 }}>
              {userInfo}
            </h5>
          ) : (
            <h5
              className="axowhite text-center"
              style={{ visibility: "hidden" }}
            >
              <AxoLocal entity="paymentDate13a" defaultValue={"Hidden"} />
            </h5>
          )}
          <hr className="border-white" style={{ borderWidth: 2 }} />
          {this.renderEjectButton(userProfile)}
        </div>
      </div>
    );
  };

  onChangeConvoName = (input) => {
    var convo = Object.assign({}, this.props.privateConversation);
    convo.name = input.value;
    DataActions.updatePrivateConversation(convo);
  };

  handleSelectedUser = (userProfile) => {
    this.setState({ selectedUser: userProfile });
  };

  onInviteUser = () => {
    if (!this.state.selectedUser.id) {
      return;
    }
    DataActions.createFriendRequest({
      requestUser: this.props.currentUser,
      receiver: this.state.selectedUser,
      privateConversation: this.props.privateConversation,
    }).then((response) => {
      if (response.status === 200) {
        this.setState({
          selectedUser: { id: 0 },
          invitedUser: true,
        });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="socialPrivateConversationBannerRejectInvite"
            defaultValue={"Invitationen kunne ikke sendes. Prøv igen senere."}
          />
        );
      }
    });
  };

  onBack = () => {
    this.setState({ invitedUser: false });
  };

  renderUserInviteBox = () => {
    if (this.state.invitedUser) {
      return (
        <div>
          <h5>
            <AxoLocal
              entity="socialPrivateConversationBannerSentInvite"
              defaultValue={"Invitationen blev sendt "}
            />
            &nbsp;&nbsp;
            <Button
              style={{ width: "100px" }}
              className="Lex-button-2 "
              onClick={this.onBack}
            >
              <Icon glyph="icon-fontello-left-bold-1" />
            </Button>
          </h5>
        </div>
      );
    }
    var addOnStyle = this.state.selectedUser.id ? { color: "#e6e6e6" } : {};
    return (
      <InputGroup style={{ paddingRight: "10px" }}>
        <UserSearchBox
          userProfiles={this.props.invitableUsers}
          handleSelectedUser={this.handleSelectedUser}
          maxWidth="100%"
        />
        <InputGroup.Addon style={addOnStyle}>
          <span role="button" onClick={this.onInviteUser}>
            <AxoLocal
              entity="socialPrivateConversationBannerInvite"
              defaultValue={"Inviter"}
            />
            <Icon glyph="icon-fontello-user " />
          </span>
        </InputGroup.Addon>
      </InputGroup>
    );
  };

  onToggleLock = () => {
    var convo = Object.assign({}, this.props.privateConversation);
    convo.locked = !convo.locked;
    DataActions.updatePrivateConversation(convo);
  };

  renderConvoCard = (privateConversation) => {
    var cardStyle = {
      position: "static",
      paddingTop: "10px",
      paddingBottom: "10px",
      width: "auto",
      background:
        "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 100%)",
    };
    return (
      <div
        key={privateConversation.id}
        style={{ border: "solid", borderWidth: "1px", borderColor: "white" }}
      >
        <div className="socialAxoimg" style={cardStyle}>
          {/* <div className='text-center' style={{ height: '75px', lineHeight: '75px' }}> */}
          <Conditional show={this.props.isOwner}>
            <div
              className="flexbox-center"
              style={{ paddingTop: "50px", height: "75px" }}
            >
              <Button className="Lex-button-2" onClick={this.onToggleLock}>
                {privateConversation.locked ? (
                  <span>
                    <AxoLocal
                      key="unlock"
                      entity="SocialPrivateConversationBannerUnlockRoom"
                      defaultValue={"Lås op"}
                    />
                  </span>
                ) : (
                  <span>
                    <AxoLocal
                      key="lock"
                      entity="SocialPrivateConversationBannerLockRoom"
                      defaultValue={"Lås rum"}
                    />
                  </span>
                )}
              </Button>
            </div>
          </Conditional>
          <h4 className="axoblack text-center">
            <AxoLocal
              entity="socialPrivateConversationBannerConference"
              defaultValue={"Konferencerum"}
            />
          </h4>
          <h5 className="axoblack text-center">
            <AxoLocal
              entity="socialPrivateConversationBannerConferenceName"
              defaultValue={"Navn"}
            />
            :
            <InlineEdit
              value={
                privateConversation.name ||
                ctx.getSync("placeholdersocialprivateConversationname") +
                  privateConversation.id
              }
              change={this.onChangeConvoName}
            />
          </h5>
          <hr className="border-white" style={{ borderWidth: 2 }} />
          <div style={{ paddingLeft: "20px" }}>
            {this.renderUserInviteBox()}
          </div>
          {/*{this.renderEjectButton(userProfile)}*/}
        </div>
      </div>
    );
  };

  renderUserCards = () => {
    var cards = [];
    var conversationUsers = this.props.conversationUsers;
    for (var i = 0; i < conversationUsers.length; i++) {
      cards.push(this.renderUserCard(conversationUsers[i]));
    }
    if (this.props.privateConversation && this.props.isOwner) {
      cards.push(this.renderConvoCard(this.props.privateConversation));
    }
    if (cards.length === 0) {
      cards.push(<div key={0}></div>);
    }
    return cards;
  };

  renderSlider() {
    let settings = {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        { breakpoint: 480, settings: { slidesToShow: 1 } },
        { breakpoint: 768, settings: { slidesToShow: 2 } },
        { breakpoint: 1024, settings: { slidesToShow: 3 } },
        { breakpoint: 1200, settings: { slidesToShow: 4 } },
      ],
    };
    return (
      <div style={{ marginRight: "25px", overflowX: "hidden" }}>
        <Slider {...settings}>{this.renderUserCards()}</Slider>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          marginLeft: "25px",
          marginRight: "25px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Large>{this.renderSlider()}</Large>
        <MediumOrSmaller>{this.renderUserCards()}</MediumOrSmaller>
      </div>
    );
  }
}
