import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Select from "react-select";
// import Steps, { Step } from 'rc-steps';

import moment from "moment";

import {
  ButtonToolbar,
  // Table as BTable
} from "react-bootstrap";

import {
  // ApiService,
  ContactService,
  NumberService,
  ApiService,
} from "../../../services/AxoServices";

import {
  LexButton,
  // Icon,
  FileViewerModal,
  ClientSearchBox,
  Flexbox,
  FlexElement,
  AxoLocal,
  Link,
  // InlineTextArea,
  getText,
  SimpleTinyMCEEditor,
  // MicroTinyMCE,
  AxoCheckbox,
  InlineNumberEdit,
  LoadingIndicator,
} from "../../../utilities/LexUtilities";

import standardTexts from "./StandardTexts";

const StepValues = {
  DATA: 0,
  BOARDSTATEMENT: 1,
  BOARDREPORT: 2,
  ACCOUNTANTSTATEMENT: 3,
  KEYFIGURES: 4,
  ACCOUNTINGPOLICY: 5,
  RESULT: 6,
  BALANCE: 7,
  NOTE: 8,
  APPROVE: 9,
};

class AnnualReportView extends React.PureComponent {
  static propTypes = {
    selectedPlan: PropTypes.object.isRequired,
    financeAccountMap: PropTypes.object.isRequired,
    selectedContact: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentStep: StepValues.DATA,

      boardStatement: standardTexts.boardStatement,
      skipBoardStatement: false,
      boardReport: standardTexts.boardReport,
      skipBoardReport: false,
      accountantStatement: standardTexts.accountantStatement,
      skipAccountantStatement: false,
      accountingPolicy: standardTexts.accountingPolicy,
      skipKeyFigures: false,
      skipAccountingPolicy: false,
      keyFiguresHtml: "",
      notes: [],
      notesText: standardTexts.notesText,
      skipNotes: false,
      editorKey: Math.random(),
      numberOfWorkers: 0,
      results: [],
      balanceAssets: [],
      balancePassives: [],
      base64File: "",
      hideRevenue: false,
      generatingReport: false,
    };

    this.keyFiguresBox = React.createRef();
  }

  componentDidMount() {
    this.updateStandardTexts();
  }

  componentDidUpdate = (prevProps, prevState) => {
    let {
      userProfile,
      selectedContact,
      accountingReport,
      fiscalYears,
      selectedFiscalYearId,
    } = this.props;

    let { base64File, currentStep } = this.state;

    //   selectedFiscalYearId,
    //   onSelectFiscalYear,
    //   // selectedPlan,
    //   // updatePlan,
    //   fixedClient
    // } = this.props;

    // // let selectedContactString = ContactService.getContactFullName(selectedContact);

    if (
      prevProps.userProfile.id !== userProfile.id ||
      prevProps.selectedContact.id !== selectedContact.id ||
      prevProps.selectedFiscalYearId !== selectedFiscalYearId ||
      Object.keys(prevProps.accountingReport).length !==
        Object.keys(accountingReport).length
    ) {
      this.updateStandardTexts();
    }

    if (
      currentStep === StepValues.APPROVE &&
      prevState.currentStep !== currentStep &&
      !!fiscalYears &&
      !!selectedFiscalYearId &&
      !base64File
    ) {
      let selectedFiscalYear = { id: 0 };
      if (!!fiscalYears) {
        selectedFiscalYear = fiscalYears.find(
          (f) => f.id === selectedFiscalYearId
        ) ||
          fiscalYears[0] || { id: 0 };
      }
      this.generateAnnualReport(selectedFiscalYear);
    }
  };

  updateStandardTexts = () => {
    let { selectedPlan } = this.props;

    let dividendAccount = selectedPlan.accounts.find((d) => d.number === 6960);
    let dividendAccountName = !!dividendAccount ? dividendAccount.name : "";

    this.setState({
      editorKey: Math.random(),
      boardStatement: this.replaceTokens(standardTexts.boardStatement),
      boardReport: this.replaceTokens(standardTexts.boardReport),
      accountantStatement: this.replaceTokens(
        standardTexts.accountantStatement
      ),
      accountingPolicy: this.replaceTokens(standardTexts.accountingPolicy),
      results: this.generateResults(dividendAccountName),
      balanceAssets: this.generateAssets(),
      balancePassives: this.generatePassives(dividendAccountName),
    });
  };

  replaceTokens = (text) => {
    let { userProfile, selectedContact, selectedFiscalYearId, fiscalYears } =
      this.props;

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let startDate = moment.utc(selectedFiscalYear.startDate).format("L");
    let endDate = moment.utc(selectedFiscalYear.endDate).format("L");

    let currentDate = moment.utc().format("L");

    return text
      .replace("STARTDATE", startDate)
      .replace(/ENDDATE/g, endDate)
      .replace(
        "COMPANYNAME",
        selectedContact.companyName || selectedContact.lastName
      )
      .replace("CITYNAME", selectedContact.city || "")
      .replace("CURRENTDATE", currentDate)
      .replace("DIRECTORNAME", selectedContact.lastName)
      .replace("COMPANYACTIVITY", "drive virksomhed med...")
      .replace("YEAR", moment.utc(selectedFiscalYear.endDate).year())
      .replace("ACCOUNTANTCITY", userProfile.city)
      .replace(
        "ACCOUNTANTNAME",
        userProfile.firstName + " " + (userProfile.lastName || "")
      )
      .replace("CURRENTDATE", currentDate)
      .replace("ACCOUNTANTCITY", userProfile.city)
      .replace(
        "FINALRESULT",
        NumberService.formatWithoutDecimals(-this.getAccountResult(3900))
      )
      .replace(
        "FINALBALANCE",
        NumberService.formatWithoutDecimals(this.getAccountResult(6691))
      )
      .replace(
        "FINALCAPITAL",
        NumberService.formatWithoutDecimals(
          -(this.getAccountResult(3900) + this.getAccountResult(6999))
        )
      );
  };

  decrementStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }));
  };

  incrementStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };

  dummyFunction = () => {};

  getAccountResult(accountNumber) {
    let { accountResultsMap } = this.props;

    return Math.round(accountResultsMap[accountNumber] || 0);
  }

  HorizontalLine = () => {
    return (
      <div
        style={{ margin: "10px 0px 10px 0px", border: "1px solid lightgrey" }}
      ></div>
    );
  };
  //  MissingEntity
  generateResults = (dividendAccountName) => {
    let grossResult = -(
      this.getAccountResult(1398) + this.getAccountResult(2898)
    );
    let ordinaryPrimaryResult =
      grossResult - this.getAccountResult(3148) - this.getAccountResult(3299);
    let annualBeforeTax =
      ordinaryPrimaryResult -
      this.getAccountResult(3369) -
      this.getAccountResult(3419) -
      this.getAccountResult(3459) -
      this.getAccountResult(3549) -
      this.getAccountResult(3579) -
      this.getAccountResult(3599) -
      this.getAccountResult(3729);
    let annualResult =
      annualBeforeTax -
      this.getAccountResult(3799) -
      this.getAccountResult(3849);

    let results = [
      {
        active: true,
        isRevenue: true,
        label: "Nettoomsætning",
        type: "sum",
        result: -this.getAccountResult(1398),
      },
      {
        active: true,
        isRevenue: true,
        label: "Eksterne omkostninger",
        type: "sum",
        result: -this.getAccountResult(2898),
      },
      // { active: true, label: 'BRUTTOFORTJENESTE/BRUTTOTAB', type: 'sum', isSum: true, result: -this.getAccountResult(2899)},
      {
        active: true,
        label: "BRUTTOFORTJENESTE/BRUTTOTAB",
        type: "sum",
        isSum: true,
        result: grossResult,
      },
      {
        active: true,
        label: "Personaleomkostninger",
        type: "standard",
        result: -this.getAccountResult(3148),
      },
      {
        active: true,
        label:
          "Af- og nedskrivninger af materielle og immaterielle anlægsaktiver",
        type: "standard",
        result: -this.getAccountResult(3299),
      },
      // { active: true, label: 'RESULTAT AF ORDINÆR PRIMÆR DRIFT', type: 'sum', isSum: true, result: -this.getAccountResult(3730)},
      {
        active: true,
        label: "RESULTAT AF ORDINÆR PRIMÆR DRIFT",
        type: "sum",
        isSum: true,
        result: ordinaryPrimaryResult,
      },
      {
        active: true,
        label: "Andre driftsomkostninger",
        type: "standard",
        result: -this.getAccountResult(3369),
      },
      {
        active: true,
        label:
          "Indtægter af andre kapitalandele. værdipapirer og tilgodehavender, der er anlægsaktiver",
        type: "standard",
        result: -this.getAccountResult(3419),
      },
      {
        active: true,
        label: "Andre finansielle indtægter fra tilknyttede virksomheder",
        type: "standard",
        result: -this.getAccountResult(3459),
      },
      {
        active: true,
        label: "Andre finansielle indtægter",
        type: "standard",
        result: -this.getAccountResult(3549),
      },
      {
        active: true,
        label: "Nedskrivning af finansielle aktiver",
        type: "standard",
        result: -this.getAccountResult(3579),
      },
      {
        active: true,
        label:
          "Finansielle omkostninger, der hidrører fra tilknyttede virksomheder",
        type: "standard",
        result: -this.getAccountResult(3599),
      },
      {
        active: true,
        label: "Øvrige finansielle omkostninger",
        type: "standard",
        result: -this.getAccountResult(3729),
      },
      {
        active: true,
        label: "ORDINÆRT RESULTAT FØR SKAT",
        type: "sum",
        isSum: true,
        result: annualBeforeTax,
      },
      {
        active: true,
        label: "Skat af årets resultat",
        type: "standard",
        result: -this.getAccountResult(3799),
      },
      {
        active: true,
        label: "Andre skatter",
        type: "standard",
        result: -this.getAccountResult(3849),
      },
      {
        active: true,
        label: "ÅRETS RESULTAT",
        type: "sum",
        isSum: true,
        result: annualResult,
      },
      {
        active: true,
        label:
          dividendAccountName ||
          "Foreslået udbytte indregnet under egenkapitalen",
        type: "custom",
        isCustom: true,
        result: 0,
      },
      {
        active: true,
        label: "Reserve for nettoopskrivning efter indre værdis metode",
        type: "standard",
        isCustom: true,
        result: 0,
      },
      {
        active: true,
        isFinalResult: true,
        label: "Overført resultat",
        type: "standard",
        isCustom: true,
        result: annualResult,
      },
      {
        active: true,
        label: "I ALT",
        type: "sum",
        isSum: true,
        isCustomSum: true,
        result: 0,
      },
    ];

    return results;
  };

  generateAssets = () => {
    let immaterialTotal =
      this.getAccountResult(5199) + this.getAccountResult(5298);
    let materialTotal =
      this.getAccountResult(5399) +
      this.getAccountResult(5499) +
      this.getAccountResult(5599) +
      this.getAccountResult(5699); // + this.getAccountResult(5799);
    let financialTotal =
      this.getAccountResult(5879) +
      this.getAccountResult(5899) +
      this.getAccountResult(5999) +
      this.getAccountResult(6097);
    let fixedTotal = immaterialTotal + materialTotal + financialTotal;

    let goodsTotal =
      this.getAccountResult(6149) +
      this.getAccountResult(6199) +
      this.getAccountResult(6249) +
      this.getAccountResult(6288);
    let receivablesTotal =
      this.getAccountResult(6349) +
      this.getAccountResult(6399) +
      this.getAccountResult(6419) +
      this.getAccountResult(6509) +
      this.getAccountResult(6539) +
      this.getAccountResult(6569) +
      this.getAccountResult(6598);

    let liquidTotal =
      goodsTotal +
      receivablesTotal +
      this.getAccountResult(6629) +
      this.getAccountResult(6688);

    let totalAssets = fixedTotal + liquidTotal;

    let assets = [
      {
        active: true,
        label: "Goodwill",
        type: "standard",
        result: this.getAccountResult(5199),
      },
      {
        active: true,
        label: "Erhvervede immaterielle anlægsaktiver",
        type: "standard",
        result: this.getAccountResult(5298),
      },
      // { active: true, label: 'IMMATERIELLE ANLÆGSAKTIVER I ALT', type: 'sum', isSum: true, result: this.getAccountResult(5299)},
      {
        active: true,
        label: "IMMATERIELLE ANLÆGSAKTIVER I ALT",
        type: "sum",
        isSum: true,
        result: immaterialTotal,
      },
      {
        active: true,
        label: "Grund og bygninger",
        type: "standard",
        result: this.getAccountResult(5399),
      },
      {
        active: true,
        label: "Produktionsanlæg og maskiner",
        type: "standard",
        result: this.getAccountResult(5499),
      },
      {
        active: true,
        label: "Indretning af lejede lokaler",
        type: "standard",
        result: this.getAccountResult(5599),
      },
      {
        active: true,
        label: "Andre anlæg, driftsmateriel og inventar",
        type: "standard",
        result: this.getAccountResult(5699),
      },
      // { active: true, label: 'MATERIELLE ANLÆGSAKTIVER I ALT', type: 'sum', isSum: true, result: this.getAccountResult(5799)},
      {
        active: true,
        label: "MATERIELLE ANLÆGSAKTIVER I ALT",
        type: "sum",
        isSum: true,
        result: materialTotal,
      },
      {
        active: true,
        label: "Langfristede tilgodehavender hos tilknyttede virksomheder",
        type: "standard",
        result: this.getAccountResult(5879),
      },
      {
        active: true,
        label: "Andre værdipapirer og kapitalandele",
        type: "standard",
        result: this.getAccountResult(5899),
      },
      {
        active: true,
        label: "Andre tilgodehavender",
        type: "standard",
        result: this.getAccountResult(5999),
      },
      {
        active: true,
        label: "Tilgodehavender hos virksomhedsdeltagere og ledelse",
        type: "standard",
        result: this.getAccountResult(6097),
      },
      // { active: true, label: 'FINANSIELLE ANLÆGSAKTIVER I ALT', type: 'sum', isSum: true, result: this.getAccountResult(6098)},
      {
        active: true,
        label: "FINANSIELLE ANLÆGSAKTIVER I ALT",
        type: "sum",
        isSum: true,
        result: financialTotal,
      },
      // { active: true, label: 'ANLÆGSAKTIVER I ALT', type: 'sum', isSum: true, result: this.getAccountResult(6099)},
      {
        active: true,
        label: "ANLÆGSAKTIVER I ALT",
        type: "sum",
        isSum: true,
        result: fixedTotal,
      },
      {
        active: true,
        label: "Råvarer og hjælpematerialer",
        type: "standard",
        result: this.getAccountResult(6149),
      },
      {
        active: true,
        label: "Varer under fremstilling",
        type: "standard",
        result: this.getAccountResult(6199),
      },
      {
        active: true,
        label: "Fremstillede varer og handelsvarer",
        type: "standard",
        result: this.getAccountResult(6249),
      },
      {
        active: true,
        label: "Forudbetalinger for varer",
        type: "standard",
        result: this.getAccountResult(6288),
      },
      // { active: true, label: 'VAREBEHOLDNINGER I ALT', type: 'sum', isSum: true, result: this.getAccountResult(6289)},
      {
        active: true,
        label: "VAREBEHOLDNINGER I ALT",
        type: "sum",
        isSum: true,
        result: goodsTotal,
      },
      {
        active: true,
        label: "Tilgodehavender fra salg og tjenesteydelser",
        type: "standard",
        result: this.getAccountResult(6349),
      },
      {
        active: true,
        label: "Kortfristede tilgodehavender hos tilknyttede virksomheder",
        type: "standard",
        result: this.getAccountResult(6399),
      },
      {
        active: true,
        label: "Iganværende arbejder for fremmed regning",
        type: "standard",
        result: this.getAccountResult(6419),
      },
      {
        active: true,
        label: "Andre tilgodehavender (kortfristede)",
        type: "standard",
        result: this.getAccountResult(6509),
      },
      {
        active: true,
        label: "Krav på indbetaling af virksomhedskapital og overkurs",
        type: "standard",
        result: this.getAccountResult(6539),
      },
      {
        active: true,
        label:
          "Kortfristede tilgodehavender hos virksomhedsdeltagere og ledelse",
        type: "standard",
        result: this.getAccountResult(6569),
      },
      {
        active: true,
        label: "Periodeafgrænsningsposter",
        type: "standard",
        result: this.getAccountResult(6598),
      },
      // { active: true, label: 'TILGODEHAVENDER I ALT', type: 'sum', isSum: true, result: this.getAccountResult(6599)},
      {
        active: true,
        label: "TILGODEHAVENDER I ALT",
        type: "sum",
        isSum: true,
        result: receivablesTotal,
      },
      {
        active: true,
        label: "Andre værdipapirer og kapitalandele",
        type: "standard",
        result: this.getAccountResult(6628),
      },
      {
        active: true,
        label: "Værdipapirer og kapitalandele",
        type: "standard",
        result: this.getAccountResult(6629),
      },
      {
        active: true,
        label: "Likvide beholdninger",
        type: "standard",
        result: this.getAccountResult(6688),
      },
      // { active: true, label: 'OMSÆTNINGSAKTIVER I ALT', type: 'sum', isSum: true, result: this.getAccountResult(6689)},
      {
        active: true,
        label: "OMSÆTNINGSAKTIVER I ALT",
        type: "sum",
        isSum: true,
        result: liquidTotal,
      },
      // { active: true, label: 'AKTIVER I ALT', type: 'sum', isSum: true, result: this.getAccountResult(6691)},
      {
        active: true,
        label: "AKTIVER I ALT",
        type: "sum",
        isSum: true,
        result: totalAssets,
      },
    ];

    return assets;
  };

  generatePassives = (dividendAccountName) => {
    let capitalTotal =
      -this.getAccountResult(6749) -
      this.getAccountResult(6799) -
      this.getAccountResult(6919) -
      this.getAccountResult(6949) -
      this.getAccountResult(6998);

    let deferredTotal = -this.getAccountResult(7029);
    let longTermDebt =
      -this.getAccountResult(7149) -
      this.getAccountResult(7179) -
      this.getAccountResult(7297);
    let shortTermDebt =
      -this.getAccountResult(7399) -
      this.getAccountResult(7429) -
      this.getAccountResult(7499) -
      this.getAccountResult(7559) -
      this.getAccountResult(8011) -
      (this.getAccountResult(8050) - this.getAccountResult(8011)) -
      this.getAccountResult(9010);

    let totalDebt = longTermDebt + shortTermDebt;
    let totalPassives = capitalTotal + deferredTotal + totalDebt;

    let passives = [
      {
        active: true,
        label: "Virksomhedskapital",
        type: "standard",
        result: -this.getAccountResult(6749),
      },
      {
        active: true,
        label: "Overkurs ved emission",
        type: "standard",
        result: -this.getAccountResult(6799),
      },
      {
        active: true,
        label: "Andre reserver",
        type: "standard",
        result: -this.getAccountResult(6919),
      },
      {
        active: true,
        label: "Overført resultat",
        type: "standard",
        isTransferResult: true,
        result: -this.getAccountResult(6949),
      },
      {
        active: true,
        label:
          dividendAccountName ||
          "Foreslået udbytte indregnet under egenkapitalen",
        type: "standard",
        result: -this.getAccountResult(6998),
      },
      // { active: true, label: 'EGENKAPITAL I ALT', isTransferResult: true, type: 'sum', isSum: true, result: -this.getAccountResult(6999)},
      {
        active: true,
        label: "EGENKAPITAL I ALT",
        isTransferResult: true,
        type: "sum",
        isSum: true,
        result: capitalTotal,
      },
      {
        active: true,
        label: "Hensættelse til udskudt skat",
        type: "standard",
        result: -this.getAccountResult(7029),
      },
      // { active: true, label: 'HENSATTE FORPLIGTELSER I ALT', type: 'sum', isSum: true, result: -this.getAccountResult(7099)},
      {
        active: true,
        label: "HENSATTE FORPLIGTELSER I ALT",
        type: "sum",
        isSum: true,
        result: deferredTotal,
      },
      {
        active: true,
        label: "Gæld til kreditinstitutter",
        type: "standard",
        result: -this.getAccountResult(7149),
      },
      {
        active: true,
        label: "Gæld til tilknyttede virksomheder - langfristet gæld",
        type: "standard",
        result: -this.getAccountResult(7179),
      },
      {
        active: true,
        label:
          "Anden gæld, herunder skyldige skatter og skyldige bidrag til social sikring",
        type: "standard",
        result: -this.getAccountResult(7297),
      },
      // { active: true, label: 'LANGFRISTET GÆLD I ALT', type: 'sum', isSum: true, result: -this.getAccountResult(7298)},
      {
        active: true,
        label: "LANGFRISTET GÆLD I ALT",
        type: "sum",
        isSum: true,
        result: longTermDebt,
      },
      {
        active: true,
        label: "Gæld til kreditinstitutter",
        type: "standard",
        result: -this.getAccountResult(7399),
      },
      {
        active: true,
        label: "Modtagne forudbetalinger fra kunder",
        type: "standard",
        result: -this.getAccountResult(7429),
      },
      {
        active: true,
        label: "Leverandører af varer og tjenesteydelser",
        type: "standard",
        result: -this.getAccountResult(7499),
      },
      {
        active: true,
        label: "Gæld til tilknyttede virksomheder",
        type: "standard",
        result: -this.getAccountResult(7559),
      },
      {
        active: true,
        label: "Skyldig selskabsskat",
        type: "standard",
        result: -this.getAccountResult(8011),
      },
      {
        active: true,
        label:
          "Anden gæld, herunder skyldige skatter og skyldige bidrag til social sikring",
        type: "standard",
        result: -(this.getAccountResult(8050) - this.getAccountResult(8011)),
      }, //Tax is shown on a separate field above
      {
        active: true,
        label: "Periodeafgrænsningsposter",
        type: "standard",
        result: -this.getAccountResult(9010),
      },
      // { active: true, label: 'KORTFRISTET GÆLD I ALT', type: 'sum', isSum: true, result: -this.getAccountResult(9020)},
      {
        active: true,
        label: "KORTFRISTET GÆLD I ALT",
        type: "sum",
        isSum: true,
        result: shortTermDebt,
      },
      // { active: true, label: 'GÆLDSFORPLIGTELSER I ALT', type: 'sum', isSum: true, result: -this.getAccountResult(0)},
      {
        active: true,
        label: "GÆLDSFORPLIGTELSER I ALT",
        type: "sum",
        isSum: true,
        result: totalDebt,
      },
      // { active: true, label: 'PASSIVER I ALT', type: 'sum', isTransferResult: true, isSum: true, result: -this.getAccountResult(9030)},
      {
        active: true,
        label: "PASSIVER I ALT",
        type: "sum",
        isTransferResult: true,
        isSum: true,
        result: totalPassives,
      },
    ];
    return passives;
  };

  transferFinalResult = () => {
    let {
      results,
      // balancePassives
    } = this.state;

    let reportFinalResult = results.find((r) => r.isFinalResult);
    if (!reportFinalResult) {
      return;
    }

    let { selectedPlan } = this.props;

    let dividendAccount = selectedPlan.accounts.find((d) => d.number === 6960);
    let dividendAccountName = !!dividendAccount ? dividendAccount.name : "";

    let passives = this.generatePassives(dividendAccountName);

    this.setState({
      balancePassives: passives.map((r) => {
        if (!r.isTransferResult) {
          return r;
        }
        return {
          ...r,
          result: r.result + reportFinalResult.result,
        };
      }),
    });
  };

  getKeyFiguresHTML = () => {
    let html = ReactDOM.findDOMNode(this.keyFiguresBox.current).innerHTML;
    return html;
  };

  generateAnnualReport = async (selectedFiscalYear) => {
    let { selectedContact } = this.props;

    let {
      boardStatement,
      skipBoardStatement,
      boardReport,
      skipBoardReport,
      accountantStatement,
      skipAccountantStatement,
      accountingPolicy,
      skipKeyFigures,
      skipAccountingPolicy,
      keyFiguresHtml,
      notesText,
      skipNotes,
      results,
      hideRevenue,
      balanceAssets,
      balancePassives,
    } = this.state;

    this.setState({ generatingReport: true });
    let activeResults = results;
    if (hideRevenue) {
      activeResults = activeResults.filter((r) => !r.isRevenue);
    }

    let customSum = activeResults
      .filter((r) => r.isCustom)
      .reduce((acc, r) => {
        return r.result + acc;
      }, 0);

    if (Math.abs(Math.floor(customSum)) === 0) {
      activeResults = activeResults.filter((r) => !r.isCustomSum);
    } else {
      activeResults = activeResults.map((r) => {
        if (!r.isCustomSum) {
          return r;
        }
        return {
          ...r,
          result: r.result + customSum,
        };
      });
    }

    let response = await ApiService.generateAnnualReport({
      clientId: selectedContact.id,
      fiscalYearId: selectedFiscalYear.id,
      boardStatement: !skipBoardStatement ? boardStatement : "",
      boardReport: !skipBoardReport ? boardReport : "",
      accountantStatement: !skipAccountantStatement ? accountantStatement : "",
      accountingPolicy: !skipAccountingPolicy ? accountingPolicy : "",
      keyFigures: !skipKeyFigures ? keyFiguresHtml : "",
      results: activeResults,
      balanceAssets,
      balancePassives,
      notesText: !skipNotes ? notesText : "",
    });

    this.setState({ generatingReport: false });

    if (!response.ok) {
      return false;
    }

    let result = await response.json();
    let fileData = result.fileData;

    this.setState({ base64File: fileData });

    // const element = document.createElement("a");
    // const file = new Blob([this._base64ToArrayBuffer(fileData)], {type: 'application/pdf'});
    // element.href = URL.createObjectURL(file);
    // element.download = "test.pdf";
    // document.body.appendChild(element); // Required for this to work in FireFox
    // element.click();

    return true;
  };

  downloadReport = () => {
    let { base64File } = this.state;

    const element = document.createElement("a");
    const file = new Blob([this._base64ToArrayBuffer(base64File)], {
      type: "application/pdf",
    });
    element.href = URL.createObjectURL(file);
    element.download = "Årsrapport.pdf";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  renderContent = (selectedFiscalYear) => {
    let { selectedContact, userSettings } = this.props;

    let {
      currentStep,
      editorKey,
      numberOfWorkers,
      base64File,
      generatingReport,
    } = this.state;

    let HorizontalLine = this.HorizontalLine;
    if (currentStep === StepValues.DATA) {
      return (
        <div
          className="leftPadding"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            maxWidth: "500px",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal entity="axoidcode204" defaultValue={"Årsrapport"} />
            &nbsp;{moment.utc(selectedFiscalYear.startDate).year()}
          </div>
          <div>
            <Flexbox className="bottomPadding">
              <FlexElement flex={1}>
                <AxoLocal entity="AdminTabView51" defaultValue="Virksomhed" />:
              </FlexElement>
              <FlexElement flex={1}>
                {selectedContact.companyName || selectedContact.firstName}
              </FlexElement>
            </Flexbox>
            <Flexbox className="bottomPadding">
              <FlexElement flex={1}>
                <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />:
              </FlexElement>
              <FlexElement flex={1}>{selectedContact.address}</FlexElement>
            </Flexbox>
            <Flexbox className="bottomPadding">
              <FlexElement flex={1}>
                <AxoLocal
                  entity="SidebarRightContainerpostalCode"
                  defaultValue={"Postnummer"}
                />
                :
              </FlexElement>
              <FlexElement flex={1}>{selectedContact.postalCode}</FlexElement>
            </Flexbox>
            <Flexbox className="bottomPadding">
              <FlexElement flex={1}>
                <AxoLocal
                  entity="SidebarRightContainerCity"
                  defaultValue={"By"}
                />
                :
              </FlexElement>
              <FlexElement flex={1}>{selectedContact.city}</FlexElement>
            </Flexbox>
            <Flexbox className="bottomPadding">
              <FlexElement flex={1}>
                <AxoLocal
                  entity="SidebarRightContainerCompanyCode"
                  defaultValue={"CVR"}
                />
                :
              </FlexElement>
              <FlexElement flex={1}>{selectedContact.identityCode}</FlexElement>
            </Flexbox>
            <Flexbox className="bottomPadding">
              <FlexElement flex={1}>
                <AxoLocal
                  entity="axoEntityidcode120"
                  defaultValue={"Regnskabsperiode"}
                />
                :
              </FlexElement>
              <FlexElement flex={1}>
                {moment.utc(selectedFiscalYear.startDate).format("L")} -{" "}
                {moment.utc(selectedFiscalYear.endDate).format("L")}
              </FlexElement>
            </Flexbox>
            <Flexbox className="bottomPadding">
              <FlexElement flex={1}>
                <AxoLocal
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
                :
              </FlexElement>
              <FlexElement flex={1}>{moment.utc().format("L")}</FlexElement>
            </Flexbox>
            <Flexbox className="bottomPadding">
              <FlexElement flex={1}>
                <AxoLocal
                  entity="axoEntityidcode121"
                  defaultValue={"Ejers navn"}
                />
                :
              </FlexElement>
              <FlexElement flex={1}>{selectedContact.lastName}</FlexElement>
            </Flexbox>
          </div>
          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton onClick={this.incrementStep}>
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </LexButton>
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    } else if (currentStep === StepValues.BOARDSTATEMENT) {
      return (
        <div
          className="leftPadding rightPadding standardMaxWidth"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal
              entity="axoEntityidcode122"
              defaultValue={"Ledelsespåtegning"}
            />
          </div>
          <div>
            <SimpleTinyMCEEditor
              key={currentStep + editorKey}
              hideFontControls
              text={this.state.boardStatement}
              height={500}
              locale={userSettings.locale}
              setupEditor={this.dummyFunction}
              onTextChange={(content) =>
                this.setState({ boardStatement: content })
              }
            />
          </div>
          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton onClick={this.decrementStep}>
                  <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipBoardStatement: false });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipBoardStatement: true });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="axoEntityidcode124"
                    defaultValue={"Spring over"}
                  />
                </LexButton>
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    } else if (currentStep === StepValues.BOARDREPORT) {
      return (
        <div
          className="leftPadding rightPadding standardMaxWidth"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal
              entity="axoEntityidcode141"
              defaultValue={"Ledelsesberetning"}
            />
          </div>
          <div>
            <SimpleTinyMCEEditor
              key={currentStep + editorKey}
              hideFontControls
              text={this.state.boardReport}
              height={500}
              locale={userSettings.locale}
              setupEditor={this.dummyFunction}
              onTextChange={(content) =>
                this.setState({ boardReport: content })
              }
            />
          </div>
          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton onClick={this.decrementStep}>
                  <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipBoardReport: false });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipBoardReport: true });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="axoEntityidcode124"
                    defaultValue={"Spring over"}
                  />
                </LexButton>
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    } else if (currentStep === StepValues.KEYFIGURES) {
      return (
        <div
          className="leftPadding rightPadding standardMaxWidth"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal
              entity="axoEntityidcode140"
              defaultValue={"Hoved- og nøgletal"}
            />
          </div>
          <div className="leftPadding rightPadding" ref={this.keyFiguresBox}>
            <div>
              <AxoLocal
                entity="axoEntityidcode139"
                defaultValue={
                  "Virksomhedens udvikling i hoved- og nøgletal kan beskrives således."
                }
              />
            </div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td></td>
                  <td>
                    <strong>
                      {moment.utc(selectedFiscalYear.endDate).year()}
                    </strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode138"
                      defaultValue={"Bruttofortjeneste"}
                    />
                  </td>
                  <td>
                    {NumberService.formatWithoutDecimals(
                      -this.getAccountResult(2899)
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode137"
                      defaultValue={"Lønomkostninger"}
                    />
                  </td>
                  <td>
                    {NumberService.formatWithoutDecimals(
                      this.getAccountResult(3148)
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode136"
                      defaultValue={"Indtjeningsbidrag"}
                    />
                  </td>
                  <td>
                    {NumberService.formatWithoutDecimals(
                      -(
                        this.getAccountResult(2899) -
                        this.getAccountResult(3300) -
                        this.getAccountResult(3148)
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode135"
                      defaultValue={"Årets resultat"}
                    />
                  </td>
                  <td>
                    {NumberService.formatWithoutDecimals(
                      -this.getAccountResult(3900)
                    )}
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode134"
                      defaultValue={"Aktiver i alt"}
                    />
                  </td>
                  <td>
                    {NumberService.formatWithoutDecimals(
                      this.getAccountResult(6691)
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode133"
                      defaultValue={"Egenkapital i alt"}
                    />
                  </td>
                  <td>
                    {NumberService.formatWithoutDecimals(
                      this.getAccountResult(6999)
                    )}
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode132"
                      defaultValue={"Likviditetsgrad"}
                    />
                  </td>
                  <td>
                    {NumberService.formatDecimal(
                      Math.abs(
                        (this.getAccountResult(6689) * 100) /
                          (this.getAccountResult(9020) || Number.MAX_VALUE)
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode131"
                      defaultValue={"Soliditetsgrad"}
                    />
                  </td>
                  <td>
                    {NumberService.formatDecimal(
                      -(
                        (this.getAccountResult(6999) +
                          this.getAccountResult(3900)) *
                        100
                      ) / (this.getAccountResult(6691) || Number.MAX_VALUE)
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode130"
                      defaultValue={"Egenkapitalens forrentning"}
                    />
                  </td>
                  <td>
                    {NumberService.formatDecimal(
                      -(
                        this.getAccountResult(3900) /
                        (this.getAccountResult(6999) || Number.MAX_VALUE)
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <AxoLocal
                      entity="axoEntityidcode129"
                      defaultValue={"Gms. antal fuldtidsmedarbejdere"}
                    />
                  </td>
                  <td>
                    <div>
                      <InlineNumberEdit
                        value={numberOfWorkers}
                        change={(input) =>
                          this.setState({ numberOfWorkers: input.value })
                        }
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div>
              <AxoLocal
                entity="axoEntityidcode128"
                defaultValue={
                  "Nøgletallene er udarbejdet i overensstemmelse med Den Danske Finansanalytikerforenings anbefalinger. Der henvises til definitioner i afsnittet om anvendt regnskabspraksis."
                }
              />
            </div>
          </div>
          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton onClick={this.decrementStep}>
                  <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({
                      keyFiguresHtml: this.getKeyFiguresHTML(),
                      skipKeyFigures: false,
                    });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipKeyFigures: true });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="axoEntityidcode124"
                    defaultValue={"Spring over"}
                  />
                </LexButton>
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    } else if (currentStep === StepValues.ACCOUNTANTSTATEMENT) {
      return (
        <div
          className="leftPadding rightPadding standardMaxWidth"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal
              entity="axoEntityidcode127"
              defaultValue={"Revisorpåtegning"}
            />
          </div>
          <div>
            <SimpleTinyMCEEditor
              key={currentStep + editorKey}
              hideFontControls
              text={this.state.accountantStatement}
              height={500}
              locale={userSettings.locale}
              setupEditor={this.dummyFunction}
              onTextChange={(content) =>
                this.setState({ accountantStatement: content })
              }
            />
          </div>
          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton onClick={this.decrementStep}>
                  <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipAccountantStatement: false });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipAccountantStatement: true });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="axoEntityidcode124"
                    defaultValue={"Spring over"}
                  />
                </LexButton>
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    } else if (currentStep === StepValues.ACCOUNTINGPOLICY) {
      return (
        <div
          className="leftPadding rightPadding standardMaxWidth"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal
              entity="axoEntityidcode126"
              defaultValue={"Anvendt regnskabspraksis"}
            />
          </div>
          <div>
            <SimpleTinyMCEEditor
              key={currentStep + editorKey}
              hideFontControls
              text={this.state.accountingPolicy}
              height={500}
              locale={userSettings.locale}
              setupEditor={this.dummyFunction}
              onTextChange={(content) =>
                this.setState({ accountingPolicy: content })
              }
            />
          </div>
          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton onClick={this.decrementStep}>
                  <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipAccountingPolicy: false });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipAccountingPolicy: true });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="axoEntityidcode124"
                    defaultValue={"Spring over"}
                  />
                </LexButton>
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    } else if (currentStep === StepValues.RESULT) {
      let { results, hideRevenue } = this.state;

      let activeResults = results.filter(
        (r) => r.isSum || r.isCustom || Math.abs(Math.floor(r.result)) > 0
      );

      if (hideRevenue) {
        activeResults = activeResults.filter((r) => !r.isRevenue);
      }

      let customSum = activeResults
        .filter((r) => r.isCustom)
        .reduce((acc, r) => {
          console.log(r);
          console.log(r.result);
          return r.result + acc;
        }, 0);

      if (Math.abs(Math.floor(customSum)) === 0) {
        activeResults = activeResults.filter((r) => !r.isCustomSum);
      }

      return (
        <div
          className="leftPadding rightPadding standardMaxWidth"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal
              entity="AccountingTabViewEntity3"
              defaultValue={"Resultatopgørelse"}
            />
          </div>
          <div>
            <AxoCheckbox
              onChange={(event) =>
                this.setState({ hideRevenue: event.target.checked })
              }
              checked={hideRevenue}
            />
            <AxoLocal
              entity="axoEntityidcode125"
              defaultValue={"Skjul omsætning"}
            />
          </div>
          <div className="leftPadding rightPadding">
            {/* <h4>Note</h4> */}
            {activeResults.map((result, index) => {
              let value = result.result;
              if (result.isCustomSum) {
                value = value + customSum;
              }

              return (
                <Flexbox key={result.label}>
                  {/* <FlexElement flexBasis='50px' className='text-center'>
                    { result.isSum && <div style={{ marginTop: '20px'}}></div>}
                    <input 
                      type='checkbox' 
                      checked={result.active}
                      onClick={() => this.setState(prevState => ({
                        results: prevState.results.map((r, rIndex) => {
                          if(rIndex === index) {
                            return {
                              ...r,
                              active: !r.active
                            }
                          }

                          return r;
                        })
                      }))}
                    />
                  </FlexElement> */}
                  <FlexElement flexGrow={1}>
                    {result.isSum && <HorizontalLine />}
                    {result.label}
                    {result.isSum && <HorizontalLine />}
                  </FlexElement>
                  <FlexElement>
                    {result.isSum && <div style={{ marginTop: "20px" }}></div>}
                    {result.isCustom ? (
                      <InlineNumberEdit
                        decimal
                        value={result.result}
                        change={(input) =>
                          this.setState((prevState) => ({
                            results: prevState.results.map((r) => {
                              if (r.label === result.label) {
                                return {
                                  ...r,
                                  result: parseFloat(input.value),
                                };
                              }

                              return r;
                            }),
                          }))
                        }
                      />
                    ) : (
                      <>{NumberService.formatWithoutDecimals(value)}</>
                    )}
                  </FlexElement>
                </Flexbox>
              );
            })}
          </div>
          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton onClick={this.decrementStep}>
                  <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.transferFinalResult();
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </LexButton>
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    } else if (currentStep === StepValues.BALANCE) {
      let { balanceAssets } = this.state;
      let { balancePassives } = this.state;

      let activeAssets = balanceAssets.filter(
        (r) => r.isSum || Math.abs(Math.floor(r.result)) > 0
      );
      let activePassives = balancePassives.filter(
        (r) => r.isSum || Math.abs(Math.floor(r.result)) > 0
      );

      return (
        <div
          className="leftPadding rightPadding standardMaxWidth"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal
              entity="AccountingTabViewEntity4"
              defaultValue={"Balance"}
            />
          </div>
          <div className="leftPadding rightPadding">
            <h4 className="text-center">
              <AxoLocal
                entity="AccountingTabViewEntity34"
                defaultValue={"Aktiver"}
              />
            </h4>
            {/* <h4>Note</h4> */}
            {activeAssets.map((result, index) => (
              <Flexbox key={result.label}>
                {/* <FlexElement flexBasis='50px' className='text-center'>
                    { result.isSum && <div style={{ marginTop: '20px'}}></div>}
                    <input 
                      type='checkbox' 
                      checked={result.active}
                      onClick={() => this.setState(prevState => ({
                        balanceAssets: prevState.balanceAssets.map((r, rIndex) => {
                          if(rIndex === index) {
                            return {
                              ...r,
                              active: !r.active
                            }
                          }

                          return r;
                        })
                      }))}
                    />
                  </FlexElement> */}
                <FlexElement flexGrow={1}>
                  {result.isSum && <HorizontalLine />}
                  {result.label}
                  {result.isSum && <HorizontalLine />}
                </FlexElement>
                <FlexElement>
                  {result.isSum && <div style={{ marginTop: "20px" }}></div>}
                  {NumberService.formatWithoutDecimals(result.result)}
                </FlexElement>
              </Flexbox>
            ))}
            <h4 className="text-center">
              <AxoLocal
                entity="AccountingTabViewEntity39"
                defaultValue={"Passiver"}
              />
            </h4>
            {activePassives.map((result, index) => (
              <Flexbox key={result.label}>
                {/* <FlexElement flexBasis='50px' className='text-center'>
                    { result.isSum && <div style={{ marginTop: '20px'}}></div>}
                    <input 
                      type='checkbox' 
                      checked={result.active}
                      onClick={() => this.setState(prevState => ({
                        balancePassives: prevState.balancePassives.map((r, rIndex) => {
                          if(rIndex === index) {
                            return {
                              ...r,
                              active: !r.active
                            }
                          }

                          return r;
                        })
                      }))}
                    />
                  </FlexElement> */}
                <FlexElement flexGrow={1}>
                  {result.isSum && <HorizontalLine />}
                  {result.label}
                  {result.isSum && <HorizontalLine />}
                </FlexElement>
                <FlexElement>
                  {result.isSum && <div style={{ marginTop: "20px" }}></div>}
                  {NumberService.formatWithoutDecimals(result.result)}
                </FlexElement>
              </Flexbox>
            ))}
          </div>
          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton onClick={this.decrementStep}>
                  <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
                </LexButton>
                <LexButton onClick={this.incrementStep}>
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </LexButton>
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    } else if (currentStep === StepValues.NOTE) {
      return (
        <div
          className="leftPadding rightPadding standardMaxWidth"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal entity="TimeEntryFormnotesA" defaultValue={"Noter"} />
          </div>
          <div>
            <SimpleTinyMCEEditor
              key={currentStep + editorKey}
              hideFontControls
              text={this.state.notesText}
              height={500}
              locale={userSettings.locale}
              setupEditor={this.dummyFunction}
              onTextChange={(content) => this.setState({ notesText: content })}
            />
          </div>
          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton onClick={this.decrementStep}>
                  <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipNotes: false });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ skipNotes: true });
                    this.incrementStep();
                  }}
                >
                  <AxoLocal
                    entity="axoEntityidcode124"
                    defaultValue={"Spring over"}
                  />
                </LexButton>
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    } else if (currentStep === StepValues.APPROVE) {
      return (
        <div
          className="leftPadding rightPadding standardMaxWidth"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            // border: '1px solid black'
          }}
        >
          <div className="largeText text-center topPadding bottomPadding">
            <AxoLocal entity="axoidcode204" defaultValue={"Årsrapport"} />
          </div>
          {!!base64File ? (
            <div className="frame-loader">
              <iframe
                title="viewport"
                id="viewport"
                style={{ flexGrow: 1, border: "none", minHeight: "600px" }}
                src={"data:application/pdf;base64," + base64File}
                width="100%"
                height="100%"
              />
            </div>
          ) : (
            <div>
              {generatingReport && (
                <div style={{ position: "relative" }}>
                  <LoadingIndicator />
                </div>
              )}
              {/* <AsyncButton onClick={() => this.generateAnnualReport(selectedFiscalYear)}>
                Generer årsrapport
              </AsyncButton> */}
            </div>
          )}

          <div className="topPadding bottomPadding">
            <Flexbox justifyCenter>
              <ButtonToolbar>
                <LexButton
                  onClick={() => {
                    this.setState({ base64File: "" });
                    this.decrementStep();
                  }}
                >
                  <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
                </LexButton>
                {base64File && (
                  <LexButton onClick={this.downloadReport}>
                    <AxoLocal
                      key="download"
                      entity="axoEntityidcode123"
                      defaultValue={"Download årsrapport"}
                    />
                  </LexButton>
                )}
              </ButtonToolbar>
            </Flexbox>
          </div>
        </div>
      );
    }
  };

  render() {
    // let {
    //   currentStep
    // } = this.state;

    let {
      // forceShowCards,
      contactMap,
      handleSelectedClient,
      selectedContact,
      fiscalYears,
      selectedFiscalYearId,
      onSelectFiscalYear,
      // selectedPlan,
      // updatePlan,
      fixedClient,
      onChangeIncludeDraft,
      includeDraft,
    } = this.props;

    // let selectedContactString = ContactService.getContactFullName(selectedContact);

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    return (
      <div style={{ marginLeft: "15px", marginRight: "15px" }}>
        <Flexbox
          responsive
          style={{ marginTop: "15px", paddingBottom: "10px" }}
        >
          {!fixedClient && (
            <FlexElement
              className="onTop"
              style={{ paddingRight: "5px", zIndex: 10 }}
            >
              <AxoLocal
                componentClass={ClientSearchBox}
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                disabled={false}
                componentAttribute="placeholder"
                entity="composeSelectClientPlaceholder1"
              />
            </FlexElement>
          )}
          {selectedContact.id && !!fiscalYears ? (
            <React.Fragment>
              <FlexElement flexBasis="250px" className="onTop rightPadding">
                <Select
                  name="fiscalYear"
                  menuPlacement="auto"
                  value={{
                    value: selectedFiscalYear.id.toString(),
                    label: selectedFiscalYear.name,
                  }}
                  onChange={(selection, arg) => {
                    onSelectFiscalYear(
                      !!selection && selection.value !== "0"
                        ? parseInt(selection.value, 10)
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "0",
                      label: getText("axoidcode100", "Vælg regnskabsår"),
                    },
                    ...fiscalYears.map((year) => {
                      return { value: year.id.toString(), label: year.name };
                    }),
                  ]}
                />
              </FlexElement>
              {/* <FlexElement style={{ paddingLeft: '15px' }}>
                <AsyncButton onClick={queryEntries}>Indlæs posteringer</AsyncButton>
              </FlexElement> */}
            </React.Fragment>
          ) : null}
          <FlexElement className="rightPadding">
            <AxoCheckbox
              onChange={(event) => onChangeIncludeDraft(event.target.checked)}
              checked={includeDraft}
            />
            <AxoLocal entity="axoidcode33" defaultValue={"Medtag kladde"} />
          </FlexElement>
        </Flexbox>
        <div>
          {/* const StepValues = {
          DATA: 0, 
          BOARDSTATEMENT: 1, 
          BOARDREPORT: 2, 
          ACCOUNTANTSTATEMENT: 3,
          KEYFIGURES: 4, 
          ACCOUNTINGPOLICY: 5,
          RESULT: 6,
          BALANCE: 7,
          NOTE: 8,
          APPROVE: 9
        } */}
          <div className="topPadding">
            {/* <Steps current={currentStep}>
              <Step title='Virksomhedsoplysninger' icon={<Icon glyph='glyphicon glyphicon-book'/>} />
              <Step title='Ledelsespåtegning'icon={<Icon glyph='glyphicon glyphicon-book'/>} />
              <Step title='Revisor Assistance' icon={<Icon glyph='glyphicon glyphicon-book'/>} />
              <Step title='Hoved- og nøgletal' icon={<Icon glyph='glyphicon glyphicon-book'/>} />
              <Step title='Anvendt regnskabspraksis'  icon={<Icon glyph='glyphicon glyphicon-book'/>} />
              <Step title='Resultat'  icon={<Icon glyph='glyphicon glyphicon-book'/>} />
              <Step title='Balance' icon={<Icon glyph='glyphicon glyphicon-book'/>} />
              <Step title='Noter' icon={<Icon glyph='glyphicon glyphicon-book'/>} />
              <Step title='Godkend' icon={<Icon glyph='glyphicon glyphicon-book'/>} />
            </Steps> */}
            {/* <ButtonToolbar>
              <LexButton disabled={currentStep < 1} onClick={onPrevious}>{'<--- Tilbage'}</LexButton>
              <LexButton disabled={currentStep >= Object.keys(stepValues).length - 1} onClick={onForward}>{'Fortsæt --->'}</LexButton>
            </ButtonToolbar> */}
          </div>
          {!!fiscalYears && selectedContact.id && fiscalYears.length === 0 ? (
            <div>
              <span style={{ fontSize: "18px" }}>
                <Link to="Setup">
                  <AxoLocal
                    entity="axoidcode67"
                    defaultValue={"Opret et regnskabsår for"}
                  />
                  &nbsp;{selectedContact.firstName} {selectedContact.lastName}
                </Link>
              </span>
            </div>
          ) : null}
        </div>
        {selectedContact.id &&
          !!selectedFiscalYearId &&
          this.renderContent(selectedFiscalYear)}

        {selectedContact.id && !!selectedFiscalYearId ? (
          <div className="axobg" style={{ marginBottom: "5px" }}>
            {/* <Flexbox style={{ paddingTop: '10px', flexWrap: 'wrap' }}>
              <FlexElement className='rightPadding'>
                <h4>
                  <Icon glyph="icon-fontello-doc-7"/>&nbsp;&nbsp;
                  { !!selectedContactString ? (
                    <span> <AxoLocal entity='axoidcode205' defaultValue={'Årsrapport for'}/>
                      &nbsp;{selectedContactString}</span>
                  ) : ( 
                    <span>
                       <AxoLocal entity='axoidcode204' defaultValue={'Årsrapport'}/>
                    </span> )}
                </h4>
              </FlexElement>
              <FlexElement>
                <LexButton href={ApiService.getAnnualReportDownloadPath( selectedContact.id, selectedFiscalYearId )}>
                <AxoLocal entity='axoidcode206' defaultValue={'Print rapport'}/>
                </LexButton>
              </FlexElement>
            </Flexbox> */}
            {/* <div className='standardMaxWidth'>
              <h4><AxoLocal entity='axoidcode207' defaultValue={'Revisorerklæring'}/>
                </h4>
              <div>
                <InlineTextArea
                  value={selectedPlan.accountantStatement || "---"}
                  change={input => updatePlan('accountantStatement', input.value)}
                  rows={5}
                />
              </div>
              <h4><AxoLocal entity='axoidcode208' defaultValue={'Indehavererklæring'}/></h4>
              <div>
                <InlineTextArea
                  value={selectedPlan.ownerStatement || "---"}
                  change={input => updatePlan('ownerStatement', input.value)}
                  rows={5}
                />
              </div>
            </div> */}
          </div>
        ) : null}
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
      </div>
    );
  }
}

export default AnnualReportView;
