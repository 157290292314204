import React from "react";
import FlagMenu from "../../utilities/FlagMenu";
import { withRouter } from "react-router-dom";
import { AxoLocal } from "../../utilities/LexUtilities";
class AccountLayoutView extends React.PureComponent {
  //props
  //children
  renderNavBar = () => {
    return (
      <div>
        <div
          className="testbackground"
          style={{ position: "relative", zIndex: 100 }}
        >
          <div className="testtransbox text-center">
            <div style={{ height: "100%", display: "inline-block" }}>
              <FlagMenu
                className="Lex-button-1xlink AxoNavigationButtonheight"
                userSettings={this.props.userSettings}
              />{" "}
              &nbsp;&nbsp;&nbsp;
            </div>
            <span
              role="button"
              className="Axooverskrifttext"
              onClick={this.onGotoFrontPage}
            >
              <AxoLocal
                entity="presentationMarketing34"
                defaultValue="Axolex IT-system"
              />
            </span>
          </div>
        </div>
      </div>
    );
  };
  onGotoFrontPage = () => {
    this.props.history.push("/freeware");
  };

  render() {
    return (
      <div>
        {this.renderNavBar()}
        {this.props.children}
        <div
          className="testbackground"
          style={{ position: "relative", zIndex: 100 }}
        >
          <div className="testtransbox text-center">
            <div style={{ height: "100%", display: "inline-block" }}>
              <FlagMenu
                className="Lex-button-1xlink AxoNavigationButtonfooter"
                dropup
                userSettings={this.props.userSettings}
              />{" "}
              &nbsp;&nbsp;&nbsp;
            </div>
            <span
              role="button"
              onClick={this.onGotoFrontPage}
              className="Axooverskrifttext"
            >
              <AxoLocal
                entity="presentationMarketing34"
                defaultValue="Axolex IT-system"
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountLayoutView);
