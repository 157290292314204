import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  DataStore,
  ThemeService,
  AuthorizationService,
  LocalizationService,
  DataActions,
  FeatureToggles,
  ReduxStore,
} from "./services/AxoServices";

import {
  Provider,
  ErrorBoundary,
  ctx,
  LoadingIndicator,
} from "./utilities/LexUtilities";

import { Provider as ReduxProvider } from "react-redux";

import BookingContainer from "./externals/booking/BookingContainer";
import LockContainer from "./Lock/LockContainer";
import ClientDashboardContainer from "./clientDashboard/ClientDashboardContainer";
import MarketingContainer from "./marketing/MarketingContainer";
import AccountContainer from "./marketing/Account/AccountContainer";
import PublicLayoutViewContainer from "./marketing/Public/PublicLayoutViewContainer";
import FrontPageContainer from "./FrontPage/FrontPageContainer";
import InfoFrontPageContainer from "./FrontPage/InfoFrontPageContainer";
import AliPayReceiver from "./routes/AliPayReceiver";
// import ITPageLayoutContainer from './itpage/ITPageLayoutContainer'
import InvoicePaymentContainer from "./payment/InvoicePaymentContainer";
import PaymentConfirmationView from "./payment/PaymentConfirmationView";
import FreewareLayoutContainer from "./freeware/FreewareLayoutContainer";
import QRCodeContainer from "./qrcode/QRCodeContainer";

import "moment/locale/da";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";

import DashboardLayoutContainer from "./maindashboard/DashboardLayoutContainer";
// import LegalLayoutContainer from './legal/LegalLayoutContainer'
import ScreenSharingWatchContainer from "./screenshare/ScreenSharingWatchContainer";
import EmbeddedContactForm from "./externals/contact/EmbeddedContactForm";
import EmbeddedCreateContactForm from "./externals/contact/EmbeddedCreateContactForm";
import VLPaymentForm from "./externals/vlpayment/VLPaymentView";
import PaymentStatusContainer from "./payment/PaymentStatusContainer";

import TestView from "./TestPages/TestView";
// import EasyIDTest from './utilities/EasyID/EasyIDSignatureView'
import EasyIDFrontPageContainer from "./EasyID/EasyIDFrontPageContainer";

import QueryString from "query-string";
import SimpleFrontPageContainer from "./SimpleFrontPage/SimpleFrontPageContainer";
import InvoicePrintContainer from "./print/InvoicePrintContainer";
import CarraLogin from "./Carra/CarraLogin";
import CustomLogin from "./CustomLogin/CustomLogin";
import SigningView from "./Signing/SigningView";
import ValidateSignedDocumentView from "./Signing/ValidateSignedDocumentView";
import EasyIDExternalLogin from "./EasyID/EasyIDExternalLogin";

import AliPayPayment from "./AliPay/AliPayPayment";
import AddressServiceView from "./Address/AddressServiceView";

import EmbeddedUploadView from "./externals/upload/EmbeddedUploadView";
import UploadView from "./externals/upload/UploadView";
import CaseLinkContainer from "./externals/caselink/CaseLinkContainer";

import TinglysningsView from "./tinglysning/TinglysningsView";
import OppanoPage from "./FrontPage/OppanoPage";
import HandleCardTransaction from "./payment/HandleCardTransactionView";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      store: DataStore.getStore(),
      contextReady: false,
    };

    this.name = "App";
  }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      this.setState({
        store: store,
      });
    });

    ctx.ready(() => {
      this.setState({ contextReady: true });
    });

    if (!!window) {
      let query = QueryString.parse(window.location.search);
      if (!!query.userType) {
        AuthorizationService.setUserTypeOverride(query.userType);
      }
      if (!!query.locale) {
        LocalizationService.changeLocale(query.locale);
        if (typeof Storage !== "undefined") {
          localStorage.setItem("locale", query.locale);
        }
      }
    }
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  componentDidUpdate(prevProps, prevState) {
    let { store } = this.state;

    if (
      !!store.globalSettings.theme &&
      store.globalSettings.theme !== prevState.store.globalSettings.theme
    ) {
      ThemeService.setThemeStyle(store.globalSettings.theme);
    }

    //Remove external stylesheet from server-side rendering
    let startupStyle = document.querySelector("link[id=startupStyle]");
    if (!!startupStyle) {
      startupStyle.remove();
    }
  }

  render() {
    let { store, contextReady } = this.state;

    if (!contextReady) {
      return <LoadingIndicator />;
    }

    if (FeatureToggles.signing) {
      return (
        <ErrorBoundary>
          <ReduxProvider store={ReduxStore}>
            <Provider
              value={{
                ...store,
                storeFunctions: { ...DataStore },
                actions: { ...DataActions },
              }}
            >
              <BrowserRouter>
                <Switch>
                  {/* <Route path='/dk' component={ITPageLayoutContainer} /> */}
                  {/* <Route path='/legal' component={LegalLayoutContainer} /> */}
                  {/* <Route path='/EasyID' component={EasyIDTest} /> */}
                  <Route
                    path="/ClientDashboard"
                    component={ClientDashboardContainer}
                  />
                  <Route path="/Lock" component={LockContainer} />
                  <Route path="/EasyID" component={EasyIDFrontPageContainer} />
                  <Route path="/EasyIDLogin" component={EasyIDExternalLogin} />
                  <Route path="/Users" component={SimpleFrontPageContainer} />
                  <Route path="/Signing/:guid" component={SigningView} />
                  <Route
                    path="/ValidateSignature/"
                    component={ValidateSignedDocumentView}
                  />
                  <Route
                    path="/AddressService"
                    component={AddressServiceView}
                  />
                  <Route path="/" component={EasyIDFrontPageContainer} />
                </Switch>
                <ToastContainer />
              </BrowserRouter>
            </Provider>
          </ReduxProvider>
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        <ReduxProvider store={ReduxStore}>
          <Provider
            value={{
              ...store,
              storeFunctions: { ...DataStore },
              actions: { ...DataActions },
            }}
          >
            <BrowserRouter>
              <Switch>
                <Route path="/Dashboard" component={DashboardLayoutContainer} />
                <Route path="/AliPay/:request" component={AliPayReceiver} />
                <Route path="/Booking/:id" component={BookingContainer} />
                <Route path="/Lock" component={LockContainer} />
                <Route
                  path="/ClientDashboard"
                  component={ClientDashboardContainer}
                />
                <Route path="/xlink/account" component={AccountContainer} />
                <Route path="/xlink" component={MarketingContainer} />
                <Route
                  path="/presentation"
                  component={PublicLayoutViewContainer}
                />
                <Route path="/freeware" component={FreewareLayoutContainer} />
                <Route path="/qr" component={QRCodeContainer} />
                {/* <Route path='/dk' component={ITPageLayoutContainer} /> */}
                {/* <Route path='/legal' component={LegalLayoutContainer} /> */}
                <Route
                  path="/InvoicePayment"
                  component={InvoicePaymentContainer}
                />
                <Route
                  path="/RegisterPayment"
                  component={PaymentConfirmationView}
                />
                <Route
                  path="/screenshare"
                  component={ScreenSharingWatchContainer}
                />
                <Route
                  path="/embeddedcontact/:toEmail"
                  component={EmbeddedContactForm}
                />
                <Route
                  path="/embeddedcreatecontact/:toEmail/:userName"
                  component={EmbeddedCreateContactForm}
                />
                <Route path="/vlpayment" component={VLPaymentForm} />
                <Route
                  path="/paymentstatus"
                  component={PaymentStatusContainer}
                />
                <Route path="/Security" component={TestView} />
                {/* <Route path='/EasyID' component={EasyIDTest} /> */}
                <Route path="/EasyID" component={EasyIDFrontPageContainer} />
                <Route path="/EasyIDLogin" component={EasyIDExternalLogin} />
                <Route path="/Users" component={SimpleFrontPageContainer} />
                <Route
                  path="/dk"
                  render={(props) => <Redirect {...props} to="/Users" />}
                />
                <Route path="/InvoicePrint" component={InvoicePrintContainer} />
                <Route path="/Carra" component={CarraLogin} />
                <Route path="/CustomLogin" component={CustomLogin} />
                <Route path="/Signing/:guid" component={SigningView} />
                <Route
                  path="/ValidateSignature/"
                  component={ValidateSignedDocumentView}
                />
                <Route path="/AliPayPayment/" component={AliPayPayment} />
                <Route path="/AddressService" component={AddressServiceView} />
                <Route
                  path="/EmbeddedUpload/:targetUserName"
                  component={EmbeddedUploadView}
                />
                <Route
                  path="/UploadDocuments/:targetUserName"
                  component={UploadView}
                />
                <Route path="/CaseLink/:guid" component={CaseLinkContainer} />
                <Route path="/Forside" component={FrontPageContainer} />
                <Route path="/Tinglysning" component={TinglysningsView} />
                <Route path="/Oppano" component={OppanoPage} />
                <Route path="/HandleCardTransaction" component={HandleCardTransaction} />
                <Route path="/" component={InfoFrontPageContainer} />
                {/* <Route path='/' component={FrontPageContainer} /> */}
              </Switch>
              <ToastContainer />
            </BrowserRouter>
          </Provider>
        </ReduxProvider>
      </ErrorBoundary>
    );
  }
}
