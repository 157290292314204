import React from "react";
import TimeEntryForm from "./TimeEntryForm";
import TimeEntryBoxForm from "./TimeEntryBoxForm";

import moment from "moment";

import { Col, Button, ButtonToolbar, Panel, PanelGroup } from "react-bootstrap";

import {
  ModalService,
  DataActions,
  TimeEntryService,
  UserInfoService,
} from "../../services/AxoServices";

import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class TimeEntryFormContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      timeEntry: this.makeNewTimeEntryModel(),
      expenses: [this.makeExpenseEntry()],
      timeEntryAdded: false,
      showValidationError: false,
    };
  }

  onResetTimeEntry = () => {
    this.setState({
      timeEntry: this.makeNewTimeEntryModel(),
      expenses: [this.makeExpenseEntry()],
      timeEntryAdded: false,
      showValidationError: false,
    });
  };

  //Props
  //timeEntryCase
  //onTimeEntryAdded
  //cases
  //clients
  //shadow
  //useBoxEdit
  makeNewTimeEntryModel = () => {
    return {
      clientId: null,
      caseModelId: null,
      description: "",
      workDate: moment(),
      timeSpent: 0,
      units: 0,
      unitType: "Hours",
      rate: 100.0,
      tax: 0.25,
      expenses: 0.0,
      aConto: 0,
      attachment: "",
    };
  };

  makeExpenseEntry = () => {
    return {
      clientId: null,
      caseModelId: null,
      description: "",
      workDate: moment(),
      timeSpent: 0,
      units: 0,
      unitType: "Units",
      rate: 0.0,
      tax: 0.0,
      expenses: 0.0,
      aConto: 0,
      attachment: "",
    };
  };

  onTimeEntryUpdate = (timeEntry) => {
    this.setState({ timeEntry: timeEntry });
  };

  onExpenseUpdate = (index, timeEntry) => {
    var expenses = this.state.expenses.slice();
    expenses[index] = timeEntry;
    this.setState({ expenses: expenses });
  };

  onAddExpense = () => {
    var expenses = this.state.expenses.slice();
    expenses.push(this.makeExpenseEntry());
    this.setState({ expenses: expenses });
  };

  onRemoveExpense = (index) => {
    var expenses = this.state.expenses.slice();
    expenses.splice(index, 1);
    this.setState({ expenses: expenses });
  };

  onSaveTimeEntries = () => {
    var promises = [];
    var { timeEntry, expenses } = this.state;
    if (timeEntry.units > 0 && timeEntry.rate > 0) {
      promises.push(this.saveTimeEntry(timeEntry));
    }
    expenses.forEach((expenseEntry) => {
      if (expenseEntry.expenses === 0) {
        return;
      }
      expenseEntry.clientId = timeEntry.clientId;
      expenseEntry.caseModelId = timeEntry.caseModelId;
      expenseEntry.workDate = timeEntry.workDate;
      promises.push(this.saveTimeEntry(expenseEntry));
    });
    if (promises.length === 0) {
      this.setState({ showValidationError: true });
      return;
    }
    Promise.all(promises)
      .then((responses) => {
        //Trigger callbacks if at least on time entry was created
        var successResponses = responses.filter(
          (response) => response.status === 200
        );
        if (successResponses.length > 0) {
          var jsonPromises = [];
          if (this.props.onTimeEntriesAdded) {
            successResponses.forEach((response) => {
              jsonPromises.push(response.json());
            });
            Promise.all(jsonPromises).then((newTimeEntries) => {
              this.props.onTimeEntriesAdded(newTimeEntries);
            });
          } else {
            this.setState({
              timeEntryAdded: true,
              showValidationError: false,
            });
          }
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="TimeEntryFormContainerresponse"
              defaultValue={
                "Tidsregistreringen kunne ikke gemmes. Prøv igen senere."
              }
            />
          );
        }
      })
      .catch((reason) => {
        console.log(reason);
        ModalService.openAlertModal(
          <AxoLocal
            entity="TimeEntryFormContainerresponse"
            defaultValue={
              "Tidsregistreringen kunne ikke gemmes. Prøv igen senere."
            }
          />
        );
      });
  };

  onTimeEntrySaved = (timeEntryResponse) => {
    if (this.props.onTimeEntryAdded) {
      timeEntryResponse.json().then((addedTimeEntry) => {
        this.setState({
          timeEntry: this.makeNewTimeEntryModel(),
          showValidationError: false,
        });
        this.props.onTimeEntryAdded(addedTimeEntry);
      });
    } else {
      this.setState({
        timeEntryAdded: true,
        showValidationError: false,
      });
    }
  };

  saveTimeEntry = (timeEntry) => {
    var timeEntryCase = this.props.timeEntryCase;
    //If this time entry is created from the case sidebar, attach it to the case
    if (timeEntryCase) {
      timeEntry.caseModelId = timeEntryCase.id;
      timeEntry.clientId =
        timeEntryCase && timeEntryCase.clients.length > 0
          ? timeEntryCase.clients[0].id
          : null;
    }
    return DataActions.createTimeEntry(timeEntry);
  };

  onSaveTimeEntry = () => {
    var timeEntry = this.state.timeEntry;
    var timeEntryCase = this.props.timeEntryCase;
    if (timeEntry.units === 0) {
      this.setState({ showValidationError: true });
      return;
    }
    //If this time entry is created from the case sidebar, attach it to the case
    if (timeEntryCase) {
      timeEntry.caseModelId = timeEntryCase.id;
      timeEntry.clientId =
        timeEntryCase && timeEntryCase.clients.length > 0
          ? timeEntryCase.clients[0].id
          : null;
    }
    DataActions.createTimeEntry(timeEntry).then((response) => {
      if (response.status === 200) {
        if (this.props.onTimeEntryAdded) {
          response.json().then((addedTimeEntry) => {
            this.setState({
              timeEntry: this.makeNewTimeEntryModel(),
              showValidationError: false,
            });
            this.props.onTimeEntryAdded(addedTimeEntry);
          });
        } else {
          this.setState({
            timeEntryAdded: true,
            showValidationError: false,
          });
        }
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="TimeEntryFormContainerresponse"
            defaultValue={
              "Tidsregistreringen kunne ikke gemmes. Prøv igen senere."
            }
          />
        );
      }
    });
  };

  renderAccordionHeader = (title) => {
    return (
      <div>
        <Col xs={11} style={{ padding: "0px" }}>
          <span>{title}</span>
        </Col>
        <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
          <span style={{ textAlign: "right" }}>
            <Icon glyph="icon-fontello-arrow-combo" />
          </span>
        </Col>
      </div>
    );
  };

  getAccordionClass = () => {
    var className = "Lex-Accordion";
    return className;
  };

  getHeaderTitle = () => {
    var title = (
      <span>
        <Icon glyph="icon-fontello-hourglass" />
        &nbsp;
        <AxoLocal
          entity="getHeaderTitletimeEntryAddedA"
          defaultValue={"Tidsregistrering"}
        />
      </span>
    );
    return title;
  };

  renderFormBody = () => {
    var confirmIcon = this.state.timeEntryAdded ? (
      <Icon style={{ color: "#4CAF50" }} glyph="icon-fontello-ok-3" />
    ) : null;
    return (
      <div>
        {!this.props.useBoxForm ? (
          <TimeEntryForm
            timeEntry={this.state.timeEntry}
            expenses={this.state.expenses}
            cases={this.props.cases}
            clients={this.props.clients}
            documentMap={this.props.documentMap}
            userProfile={this.props.userProfile}
            onTimeEntryUpdate={this.onTimeEntryUpdate}
            onExpenseUpdate={this.onExpenseUpdate}
            onAddExpense={this.onAddExpense}
            onRemoveExpense={this.onRemoveExpense}
          />
        ) : (
          <TimeEntryBoxForm
            timeEntry={this.state.timeEntry}
            expenses={this.state.expenses}
            cases={this.props.cases}
            clients={this.props.clients}
            documentMap={this.props.documentMap}
            userProfile={this.props.userProfile}
            onTimeEntryUpdate={this.onTimeEntryUpdate}
            onExpenseUpdate={this.onExpenseUpdate}
            onAddExpense={this.onAddExpense}
            onRemoveExpense={this.onRemoveExpense}
          />
        )}

        <div style={{ textAlign: "center", paddingBottom: "25px" }}>
          <ButtonToolbar style={{ display: "inline-block" }}>
            <Button className="Lex-button-2" onClick={this.onSaveTimeEntries}>
              {confirmIcon}{" "}
              <span>
                {" "}
                <Icon glyph="icon-fontello-floppy-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseGridViewcaseSave"
                  defaultValue={"Gem"}
                />{" "}
              </span>
            </Button>
            <Button className="Lex-button-2" onClick={this.onResetTimeEntry}>
              <span>
                &#x21bb; &nbsp;&nbsp;
                <AxoLocal
                  entity="CalendarEventBoxFormResetTime"
                  defaultValue={"Nulstil"}
                />{" "}
              </span>
            </Button>
          </ButtonToolbar>
          {this.state.showValidationError ? (
            <div className="text-center axored">
              <b>
                {" "}
                <AxoLocal
                  entity="CalendarEventBoxFormshowTimeSpentValidationError"
                  defaultValue={"Tidsforbrug skal være større end nul"}
                />
              </b>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  renderTimeEntryForm = () => {
    if (this.props.hideAccordion) {
      return this.renderFormBody();
    }
    return (
      <PanelGroup
        id="ITsubscriptionb"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {this.renderAccordionHeader(this.getHeaderTitle())}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            {this.renderFormBody()}
          </Panel.Body>
        </Panel>
      </PanelGroup>

      //  <Accordion className={this.getAccordionClass()} defaultActiveKey='0'>
      //   <Panel className='backgroundlineargradient3'
      //     header={this.renderAccordionHeader(this.getHeaderTitle())}
      //     eventKey="1">

      //     {this.renderFormBody()}
      //   </Panel>
      // </Accordion>
    );
  };

  onBackToTimeEntryForm = () => {
    this.setState({
      timeEntry: this.makeNewTimeEntryModel(),
      expenses: [this.makeExpenseEntry()],
      timeEntryAdded: false,
    });
  };

  renderExpensesView = () => {
    var expenses = this.state.expenses;
    var expenseNodes = [];
    for (var i = 0; i < expenses.length; i++) {
      var expense = expenses[i];
      expenseNodes.push(
        <span>
          <br />
          <b>
            <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />:{" "}
          </b>
          <small>{expense.expenses}</small>&nbsp;&nbsp;&nbsp;
          <b>
            {" "}
            <Icon glyph="icon-fontello-edit-3" />
            &nbsp;{" "}
            <AxoLocal
              entity="TimeEntryFormnotes"
              defaultValue={"Noter"}
            />:{" "}
          </b>
          <small>{expense.description}</small>
        </span>
      );
    }
    return expenseNodes;
  };

  getTimeEntriesTotal = () => {
    var timeEntryTotal = TimeEntryService.getTotalValue(this.state.timeEntry);
    var total =
      timeEntryTotal +
      this.state.expenses.reduce((sum, timeEntry) => {
        return sum + TimeEntryService.getTotalValue(timeEntry);
      }, 0);
    return total;
  };

  renderTimeEntryView = () => {
    var timeEntry = this.state.timeEntry;
    return (
      <PanelGroup
        id="ITsubscriptionb"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {this.renderAccordionHeader(
                <AxoLocal
                  entity="CalendarEventBoxFormAccordionHeader"
                  defaultValue={"Tidsregistrering blev oprettet"}
                />
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <Icon glyph="icon-fontello-calendar-1" />
            &nbsp;
            <b>
              <AxoLocal
                entity="TimeEntryFormntimeEntry"
                defaultValue={"Dato"}
              />
              :
            </b>{" "}
            <small>{moment(timeEntry.workDate).format("L LT")}</small>
            <br />
            <Icon glyph=" icon-fontello-pencil-alt-1" />
            &nbsp;
            <b>
              <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />:
            </b>{" "}
            <small>{timeEntry.description}</small>
            <br />
            <Icon glyph="icon-fontello-clock" />
            &nbsp;
            <b>
              <AxoLocal entity="InvoiceInvoiceunit" defaultValue={"Antal"} /> :
            </b>{" "}
            <small>
              {timeEntry.units}{" "}
              {TimeEntryService.getUnitTypeName(timeEntry.unitType)}
            </small>
            <br />
            <Icon glyph="icon-fontello-money-2" />
            &nbsp;
            <b>
              <AxoLocal
                entity="InvoiceInvoicePrisunit"
                defaultValue={"Pris/enhed"}
              />
              :
            </b>{" "}
            <small>{timeEntry.rate}</small>
            <br />
            <Icon glyph="icon-simple-line-icons-pie-chart" />
            &nbsp;
            <b>
              <AxoLocal
                entity="invoiPaymentattheSubtotalVAT"
                defaultValue={"Moms"}
              />
              :
            </b>{" "}
            <small>{timeEntry.tax}</small>
            <br />
            <b>
              <AxoLocal
                entity="TimeEntryFormExpensesLabel"
                defaultValue="Andre udgifter"
              />
            </b>
            {this.renderExpensesView()}
            <br />
            <Icon glyph="icon-fontello-database" />
            &nbsp;
            <b>
              {UserInfoService.getCurrency(this.props.userProfile)}{" "}
              <AxoLocal entity="TimeEntriesViewValue" defaultValue={"Værdi"} />:
            </b>{" "}
            <small>{this.getTimeEntriesTotal()}</small>
            <br />
            <div className="text-right">
              <Button
                style={{ width: "100px" }}
                className="Lex-button-2 "
                onClick={this.onBackToTimeEntryForm}
              >
                <Icon glyph="icon-fontello-left-bold-1" />
              </Button>
            </div>
          </Panel.Body>
        </Panel>
      </PanelGroup>

      // <Accordion className={this.getAccordionClass()} defaultActiveKey='0'>
      //   <Panel className='backgroundlineargradient3' header={this.renderAccordionHeader( <AxoLocal entity='CalendarEventBoxFormAccordionHeader'defaultValue={'Tidsregistrering blev oprettet'}/>)} eventKey="1">
      //    <Icon glyph="icon-fontello-calendar-1"/>&nbsp;
      //     <b><AxoLocal entity='TimeEntryFormntimeEntry'defaultValue={'Dato'}/>:</b> <small>{moment(timeEntry.workDate).format('L LT')}</small><br/>
      //      <Icon glyph=" icon-fontello-pencil-alt-1"/>&nbsp;
      //     <b><AxoLocal entity='Enhedspris6'defaultValue={'Beskrivelse'}/>:</b> <small>{timeEntry.description}</small><br/>
      //      <Icon glyph="icon-fontello-clock"/>&nbsp;
      //     <b><AxoLocal entity='InvoiceInvoiceunit'defaultValue={'Antal'}/> :</b> <small>{timeEntry.units} {TimeEntryService.getUnitTypeName(timeEntry.unitType)}</small><br/>
      //     <Icon glyph="icon-fontello-money-2"/>&nbsp;
      //     <b><AxoLocal entity='InvoiceInvoicePrisunit'defaultValue={'Pris/enhed'}/>:</b> <small>{timeEntry.rate}</small><br/>
      //     <Icon glyph="icon-simple-line-icons-pie-chart"/>&nbsp;
      //     <b><AxoLocal entity='invoiPaymentattheSubtotalVAT'defaultValue={'Moms'}/>:</b> <small>{timeEntry.tax}</small><br/>

      //     <b><AxoLocal entity='TimeEntryFormExpensesLabel' defaultValue='Andre udgifter' /></b>
      //     {this.renderExpensesView()}<br/>

      //     <Icon glyph="icon-fontello-database"/>&nbsp;
      //     <b>{UserInfoService.getCurrency(this.props.userProfile)} <AxoLocal entity='TimeEntriesViewValue'defaultValue={'Værdi'}/>:</b> <small>{this.getTimeEntriesTotal()}</small><br/>

      //     <div className='text-right'>
      //       <Button className="Lex-button-2 " onClick={this.onBackToTimeEntryForm}><Icon glyph='icon-fontello-left-bold-1'/></Button>
      //     </div>
      //   </Panel>
      // </Accordion>
    );
  };

  render() {
    if (this.state.timeEntryAdded) {
      return this.renderTimeEntryView();
    }
    return this.renderTimeEntryForm();
  }
}
