import React from "react";
import PropTypes from "prop-types";
import { CountryDropdown } from "./Dropdowns";
import { AxoLocal } from "../../utilities/LexUtilities";

export default class InlineCountrySelect extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string,
    countryName: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  selectCountry = (val) => {
    this.props.onChange(val);
    this.setState({ editing: false });
  };

  onEdit = () => {
    this.setState({ editing: true });
  };

  render() {
    let { customSelect } = this.props;
    let { editing } = this.state;

    if (!editing) {
      let { countryName, value } = this.props;

      return (
        <span onClick={this.onEdit} className="editable">
          {!!countryName ? (
            <React.Fragment>{countryName}</React.Fragment>
          ) : (
            <React.Fragment>
              {!!value ? (
                <AxoLocal
                  key={this.props.value}
                  entity="CountryNames"
                  data={{ countrySlug: this.props.value }}
                />
              ) : (
                "---"
              )}
            </React.Fragment>
          )}
        </span>
      );
    }
    return (
      <CountryDropdown
        value={this.props.value}
        valueType="short"
        customSelect={customSelect}
        onChange={(val) => this.selectCountry(val)}
      />
    );
  }
}
