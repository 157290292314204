import React from "react";
import SidebarRightContainer from "../common/SidebarRightContainer";
import SocialSidebarContainer from "../common/sidebars/SocialSidebarContainer";
import TimeEntriesSidebarContainer from "../common/sidebars/TimeEntriesSidebarContainer";
import RoutingService from "../services/RoutingService";
import ModalService from "../services/ModalService";
import { withRouter } from "react-router-dom";
import LoadingIndicator from "../utilities/LoadingIndicator";
import DataActions from "../services/DataAccess/DataActions";
import { MediumOrLarger, SmallOrSmaller } from "../utilities/Responsive";
import screenfull from "screenfull";
// import SidebarAccordion from './SidebarAccordion'
import SidebarSimple from "./SidebarSimple";
import Header from "./Header";

import { AuthorizationService, DataStore } from "../services/AxoServices";

import { Row, Col, Grid } from "react-bootstrap";

import {
  Icon,
  Timer,
  ConfirmModal,
  AlertModal,
  ErrorBoundary,
  getText,
  // Flexbox
} from "../utilities/LexUtilities";

class DashboardLayout extends React.PureComponent {
  getPath(path) {
    return RoutingService.getPath(path);
  }

  handleBackClick = () => {
    this.props.history.goBack();
  };

  onRoute = (route) => {
    this.props.onRoute(route);
  };

  onRelativeRoute = (path) => {
    this.onRoute(RoutingService.getPath(path));
  };

  onGotoSocial = () => {
    this.props.onCloseDropdown();
    let { userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);
    if (userType === "Admin") {
      this.props.onRoute(RoutingService.getPath("Social"));
    } else {
      this.props.onRoute(RoutingService.getPath(""));
    }
  };

  onLock = () => {
    var settings = Object.assign({}, this.props.userSettings);
    settings.locked = true;
    DataActions.updateUserSettings(settings).then((response) => {
      if (response.ok) {
        this.props.history.push("/Lock");
      }
    });
  };

  onGoToHome = () => {
    this.props.onCloseDropdown();
    let { userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);
    if (userType === "Admin") {
      this.props.onRoute(RoutingService.getPath("Home"));
    } else {
      this.props.onRoute(RoutingService.getPath(""));
    }
  };

  renderGenericSidebarRight = (isFrontPage) => {
    return (
      <SidebarRightContainer
        selectedCase={this.props.selectedCase}
        documentMap={this.props.documentMap}
        isFrontPage={isFrontPage}
      />
    );
  };

  renderSideBarRightBody = (isFrontPage, isSocial, isFinance) => {
    if (isSocial) {
      return <SocialSidebarContainer />;
    } else if (isFinance) {
      return <TimeEntriesSidebarContainer />;
    }
    return this.renderGenericSidebarRight(isFrontPage);
  };

  renderSideBarRight = (isFrontPage, isSocial, isFinance, columnWidth) => {
    return (
      <Col
        sm={columnWidth + 2}
        md={columnWidth + 1}
        lg={columnWidth}
        className="nopadding sidebar-right"
        style={{ border: "none" }}
      >
        {this.renderSideBarRightBody(isFrontPage, isSocial, isFinance)}
      </Col>
    );
  };

  onLogout = async () => {
    let { userProfile } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    let response = await DataActions.logout().then((response) => {
      if (response.ok) {
        if (userType === "Accountant") {
          window.location.href = "https://carra.dk";
        } else {
          this.props.history.push("/");
        }
      }
    });

    return response.ok;
  };

  onFullScreenToggle = () => {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
    this.setState({}); //Trigger a rerender to update the full screen button
  };

  renderFullScreenButtonContent = () => {
    if (screenfull.isFullscreen) {
      return (
        <Icon glyph="icon-fontello-resize-small-3" className="Topmenuimg" />
      );
    }
    return <Icon glyph="icon-fontello-resize-full-4" className="Topmenuimg" />;
  };

  renderSideBar = (fixed) => {
    var {
      activeRoute,
      userProfile,
      userGroup,
      sidebarSelection,
      messageCounter,
      onCloseDropdown,
      friendRequests,
      socialNotifications,
      bookings,
      deadlineNotifications,
      friends,
      onSidebarSelect,
      publicPosts,
      internalPosts,
      selectedUserProfile,
      addPrivateConversation,
      theme,
      hideSidebar,
      userSettings,
    } = this.props;

    var newMessageCount = messageCounter.unreadCount;

    let sideBarProps = {
      fixed,
      activeRoute,
      userProfile,
      userGroup,
      sidebarSelection,
      newMessageCount,
      onCloseDropdown,
      friendRequests,
      socialNotifications,
      bookings,
      deadlineNotifications,
      friends,
      onSidebarSelect,
      publicPosts,
      internalPosts,
      selectedUserProfile,
      addPrivateConversation,
      theme,
      locale: userSettings.locale,
      smallSidebar: hideSidebar && fixed,
      onRoute: this.onRoute,
      handleBackClick: this.handleBackClick,
      onToggleSidebar: this.onToggleSidebar,
    };

    return <SidebarSimple {...sideBarProps} />;

    // switch(theme) {
    //   case 'theme-redaxo15':
    //   case 'theme-aqua':
    //   return (
    //     <SidebarAccordion
    //       {...sideBarProps}
    //     />
    //   )
    // default:
    //   return (
    //     <SidebarSimple
    //       {...sideBarProps}
    //     />
    //   )
    // }
  };

  onToggleSidebar = () => {
    let { onSidebarSelect } = this.props;

    onSidebarSelect(0);
    DataStore.setState({
      hideSidebar: !DataStore.getStore().hideSidebar,
    });
  };

  render() {
    var {
      loaded,
      userSettings,
      userProfile,
      userGroup,
      globalSettings,
      isEditorFullScreen,
      // selectedCase,
      openDropdown,
      onToggleDropdown,
      hideSidebar,
      trashCount,
    } = this.props;

    if (
      !loaded ||
      !userSettings.id ||
      userSettings.locked ||
      !userProfile.id ||
      !globalSettings.theme
    ) {
      return <LoadingIndicator />;
    }

    let userType = AuthorizationService.getUserType(userProfile);
    //remove / characters

    var path = this.props.location.pathname.replace(/\//g, "").toLowerCase();
    var rootPath = RoutingService.getRootPath()
      .replace(/\//g, "")
      .toLowerCase();

    var isFrontPage =
      path === rootPath + "home" || path === rootPath + "subscribe";
    var isSocial = path.includes(rootPath + "social");
    var isFinance =
      path.includes(rootPath + "finance") && userType !== "Society"; //Societies do not need the finance sidebar.

    var showSidebar = isFrontPage || isSocial || isFinance;

    var sideBarRightColumnWidth = 3; //isFinance ? 2 : 3;
    if (isFinance) {
      sideBarRightColumnWidth = 2;
    }
    if (isFrontPage) {
      sideBarRightColumnWidth = 3;
    }

    return (
      <div>
        {/* Header */}
        <Header
          show={!isEditorFullScreen}
          openDropdown={openDropdown}
          onToggleDropdown={onToggleDropdown}
          userProfile={userProfile}
          userSettings={userSettings}
          userGroup={userGroup}
          trashCount={trashCount}
          onGotoSocial={this.onGotoSocial}
          onGoToHome={this.onGoToHome}
          onRelativeRoute={this.onRelativeRoute}
          onLock={this.onLock}
          onLogout={this.onLogout}
          handleBackClick={this.handleBackClick}
          dropdownContent={
            <SmallOrSmaller>{this.renderSideBar(false)}</SmallOrSmaller>
          }
        />
        <MediumOrLarger>
          {/* { !hideSidebar ? ( */}
          <div className="Axolexbg">
            {this.renderSideBar(true)}
            {!hideSidebar ? (
              <div
                className="collapse-arrow"
                role="button"
                onClick={this.onToggleSidebar}
              >
                <Icon
                  title={getText("axoidcode167", "Skjul menu")}
                  className="frontnavbarimg Axolexgul"
                  glyph="icon-fontello-left-4"
                />
              </div>
            ) : null}
          </div>
          {/* // ) : (
          //   <Flexbox role='button' justifyCenter className='mini-sidebar fixed' 
          //     onClick={this.onToggleSidebar}>
          //       <Icon className='Axolexgul' style={{ fontSize: '25px'}} glyph='icon-fontello-right-4'/>
          //   </Flexbox>
          // ) } */}
          <div className="timer-container TimeButtoncolor noprint">
            <Timer />
          </div>
        </MediumOrLarger>
        {/* Body */}
        <div className={hideSidebar ? "body-margin-no-sidebar" : "body-margin"}>
          <Grid fluid>
            <Row>
              <Col
                sm={showSidebar ? 12 - (sideBarRightColumnWidth + 2) : 12}
                md={showSidebar ? 12 - (sideBarRightColumnWidth + 1) : 12}
                lg={showSidebar ? 12 - sideBarRightColumnWidth : 12}
                className=" main-body nopadding"
              >
                <ErrorBoundary>{this.props.children}</ErrorBoundary>
              </Col>
              {showSidebar
                ? this.renderSideBarRight(
                    isFrontPage,
                    isSocial,
                    isFinance,
                    sideBarRightColumnWidth
                  )
                : null}
            </Row>
          </Grid>
        </div>
        <AlertModal ref={(c) => ModalService.registerAlertModal(c)} />
        <ConfirmModal ref={(c) => ModalService.registerConfirmModal(c)} />
      </div>
    );
  }
}

export default withRouter(DashboardLayout);
