import React from "react";
import PropTypes from "prop-types";
import AccountPlanView from "./AccountPlanView";

import { DataActions, RoutingService } from "../../../services/AxoServices";

export default class AccountPlanContainer extends React.PureComponent {
  static propTypes = {
    financeAccountPlans: PropTypes.array.isRequired,
    standardFinanceAccountPlans: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedPlanId: props.clientPlan.id || 0,
      newAccountNumber: 0,
      newAccountName: "",
      creatingHeader: false,

      editingPlan: false,
      creatingSumAccount: false,
      sumIntervalStart: 0,
      sumIntervalEnd: 0,
      summedAccounts: [],

      editingAccountPlanSetup: false,
    };
    this.addingAccountPlan = false;
    this.createdAccountNumbers = new Set(); //Account numbers that have been created, but not yet loaded from the server
  }

  componentDidUpdate(prevProps) {
    let { clientPlan } = this.props;

    if (prevProps.clientPlan.id !== clientPlan.id) {
      this.setState({ selectedPlanId: clientPlan.id || 0 });
    }

    this.createdAccountNumbers = new Set();
  }

  onSelectPlan = (id) => {
    this.setState({ selectedPlanId: id || 0 });
  };

  onUpdateProperty = (propertyName, newValue) => {
    this.setState({ [propertyName]: newValue });
  };

  onCreateHeader = () => {
    this.setState({ creatingHeader: true });
  };

  onCancelCreate = () => {
    this.setState({
      creatingHeader: false,
      creatingSumAccount: false,
      newAccountNumber: 0,
      newAccountName: "",
      sumIntervalStart: 0,
      sumIntervalEnd: 0,
      summedAccounts: [],
    });
  };

  doCreateAccount = async (type) => {
    let {
      newAccountName,
      newAccountNumber,
      selectedPlanId,
      sumIntervalStart,
      sumIntervalEnd,
      summedAccounts,
    } = this.state;
    if (!newAccountName || !newAccountNumber || !selectedPlanId) {
      return;
    }
    if (
      type === "Sum" &&
      sumIntervalEnd > 0 &&
      sumIntervalEnd <= sumIntervalStart
    ) {
      return;
    }

    try {
      let response = await DataActions.createFinanceAccount({
        type: type,
        number: newAccountNumber,
        name: newAccountName,
        financeAccountPlanId: selectedPlanId,
        sumIntervalStart,
        sumIntervalEnd,
        summedAccounts,
      });
      if (response.ok) {
        this.setState({
          creatingHeader: false,
          creatingSumAccount: false,
          newAccountName: "",
          newAccountNumber: 0,
          sumIntervalStart: 0,
          sumIntervalEnd: 0,
          summedAccounts: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getSelectedPlan = () => {
    let { financeAccountPlans, clientPlan } = this.props;
    let { selectedPlanId } = this.state;

    let selectedPlan = financeAccountPlans
      .concat([clientPlan])
      .find((f) => f.id === selectedPlanId);
    return selectedPlan;
  };

  getSelectedAccounts = () => {
    let selectedPlan = this.getSelectedPlan();
    if (!selectedPlan) {
      return;
    }
    return selectedPlan.accounts;
  };

  getAccountFromId = (accountId) => {
    return this.getSelectedAccounts().find((a) => a.id === accountId);
  };

  updateAccountProperty = (accountId, propertyName, value) => {
    let account = this.getAccountFromId(accountId);
    if (!account) {
      return;
    }

    let newAccount = {
      ...account,
      [propertyName]: value,
    };

    DataActions.updateFinanceAccount(newAccount);
  };

  addAccountToHeader = async (minNumber) => {
    //Can only add one account at a time
    if (this.addingAccountPlan) {
      return;
    }
    this.addingAccountPlan = true;
    let { selectedPlanId } = this.state;

    let accounts = this.getSelectedAccounts();
    let number = minNumber + 1;

    let numberAvailable = (numberVar) => {
      return (
        !this.createdAccountNumbers.has(numberVar) &&
        !accounts.find((a) => a.number === numberVar)
      );
    };

    while (!numberAvailable(number)) {
      number = number + 1;
    }

    this.createdAccountNumbers.add(number);

    try {
      await DataActions.createFinanceAccount({
        type: "Standard",
        financeAccountPlanId: selectedPlanId,
        number,
      });
      this.addingAccountPlan = false;
    } catch (error) {
      console.log(error.message);
      this.addingAccountPlan = false;
    }
  };

  onDeleteAccount = (account) => {
    return DataActions.deleteFinanceAccount(account);
  };

  onStopEditing = () => {
    this.setState({
      editingPlan: false,
      creatingHeader: false,
      creatingSumAccount: false,
      confirmPlanDeletion: false,
    });
  };

  updateSelectedPlanProperty = (propertyName, value) => {
    let selectedPlan = this.getSelectedPlan();
    if (!selectedPlan) {
      return;
    }
    DataActions.updateFinanceAccountPlan({
      ...selectedPlan,
      [propertyName]: value,
    });
  };

  updateSelectedPlanPropertyMap = async (propertyMap) => {
    let selectedPlan = this.getSelectedPlan();
    if (!selectedPlan) {
      return Promise.reject("No plan selected");
    }
    let response = await DataActions.updateFinanceAccountPlan({
      ...selectedPlan,
      ...propertyMap,
    });
    return response.ok;
  };

  getActiveTab = (props) => {
    let path = props.location.pathname.toLowerCase();
    if (path.includes("accounts")) {
      return "Accounts";
    }
    if (path.includes("setup")) {
      return "Setup";
    }
    return "Accounts";
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(RoutingService.getPath("AccountPlans/" + eventKey));
  };

  handleSelectedClient = (selectedClient) => {
    let { storeFunctions } = this.props;

    storeFunctions.setState({
      fiscalYears: null,
    });

    storeFunctions.setSelectedContact(selectedClient);

    storeFunctions.fetchFiscalYears();
    storeFunctions.fetchBookkeepingDraftEntries();
    storeFunctions.fetchClientPlan();
  };

  onFinalizePlanSelection = async () => {
    let { actions, updateContact, selectedContact } = this.props;
    let { selectedPlanId } = this.state;

    let response = await actions.copyPlanToClient({
      planId: selectedPlanId,
      clientId: selectedContact.id,
    });
    if (!response.ok) {
      return false;
    }

    let newPlan = await response.json();

    //Contact have been updated on the server, so we update it manually on the frontend.
    updateContact({
      ...selectedContact,
      financeAccountPlanId: newPlan.id,
      planLocked: true,
    });

    return response.ok;
  };

  render() {
    return (
      <AccountPlanView
        {...this.props}
        {...this.state}
        selectedPlan={this.getSelectedPlan()}
        activeTab={this.getActiveTab(this.props)}
        handleNavigation={this.handleNavigation}
        onSelectPlan={this.onSelectPlan}
        onUpdateProperty={this.onUpdateProperty}
        onCreateHeader={this.onCreateHeader}
        onCancelCreate={this.onCancelCreate}
        doCreateAccount={this.doCreateAccount}
        updateAccountProperty={this.updateAccountProperty}
        addAccountToHeader={this.addAccountToHeader}
        onDeleteAccount={this.onDeleteAccount}
        onStopEditing={this.onStopEditing}
        updateSelectedPlanProperty={this.updateSelectedPlanProperty}
        updateSelectedPlanPropertyMap={this.updateSelectedPlanPropertyMap}
        handleSelectedClient={this.handleSelectedClient}
        onFinalizePlanSelection={this.onFinalizePlanSelection}
      />
    );
  }
}
