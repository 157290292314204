import React from "react";

import { Grid, Row, Col } from "react-bootstrap";

import {
  ClientSearchBox,
  Icon,
  AxoLocal,
  InlineEdit,
  InlineTextArea,
} from "./LexUtilities";

export default class PrintLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headline: props.headline,
      leftAddress: props.leftAddress,
      rightAddress: props.rightAddress,
      imageSrc: props.imageSrc,
      contentHeader: props.contentHeader,
      content: props.content,
    };
  }

  onPropertyChange = (propertyName, input) => {
    this.setState({ [propertyName]: input.value });
  };

  onRemoveImage = () => {
    this.setState({ imageSrc: "" });
  };

  getClientAddress = (client) => {
    return client
      ? client.firstName +
          " " +
          client.lastName +
          "\n" +
          client.address +
          "\n" +
          client.postalCode +
          "\n" +
          client.city
      : "";
  };

  handleSelectedClient = (client) => {
    this.setState({ leftAddress: this.getClientAddress(client) });
  };

  render() {
    var {
      headline,
      leftAddress,
      rightAddress,
      imageSrc,
      contentHeader,
      content,
    } = this.state;
    var { contactMap, readOnly } = this.props;

    return (
      <div id="print-modal">
        <div>
          <Grid fluid>
            <Row>
              <Col xs={6} style={{ paddingTop: 25 }}>
                <span className="pull-left">
                  <h3
                    className="editable"
                    style={{ margin: 0, marginBottom: 12.5 }}
                  >
                    {readOnly ? (
                      <div style={{ whiteSpace: "pre-wrap" }}>{headline}</div>
                    ) : (
                      <InlineEdit
                        value={headline}
                        change={this.onPropertyChange.bind(this, "headline")}
                      />
                    )}
                  </h3>
                  {!readOnly ? (
                    <div className="hidden-print">
                      <AxoLocal
                        componentClass={ClientSearchBox}
                        clients={contactMap.contacts}
                        count={contactMap.count}
                        handleSelectedClient={this.handleSelectedClient}
                        clearOnSelect
                        disabled={false}
                        componentAttribute="placeholder"
                        entity="PrintLayoutSelectClient"
                      />
                    </div>
                  ) : null}
                  <address>
                    {readOnly ? (
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {leftAddress}
                      </div>
                    ) : (
                      <InlineTextArea
                        value={leftAddress}
                        rows={5}
                        change={this.onPropertyChange.bind(this, "leftAddress")}
                      />
                    )}
                  </address>
                </span>
              </Col>
              <Col xs={6} className="text-right" style={{ paddingTop: 25 }}>
                <span className="pull-right">
                  {imageSrc ? (
                    <div
                      style={{ paddingBottom: "10px" }}
                      className="text-right"
                    >
                      {!readOnly ? (
                        <span>
                          &nbsp;
                          <AxoLocal
                            componentClass={Icon}
                            role="button"
                            onClick={this.onRemoveImage}
                            className="pull-right hidden-print axored"
                            glyph="icon-fontello-scissors"
                            componentAttribute="title"
                            entity="RemoveBCCTitleRemovelogo"
                          />
                          &nbsp;
                        </span>
                      ) : null}
                      <img alt="" height="100px" src={imageSrc} />
                    </div>
                  ) : null}
                  <address>
                    <div style={{ fontSize: "13px" }}>
                      {readOnly ? (
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          {rightAddress}
                        </div>
                      ) : (
                        <InlineTextArea
                          value={rightAddress}
                          rows={5}
                          change={this.onPropertyChange.bind(
                            this,
                            "rightAddress"
                          )}
                        />
                      )}
                    </div>
                  </address>
                </span>
              </Col>
            </Row>
          </Grid>
        </div>
        <div>
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <h3>
                  {readOnly ? (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {contentHeader}
                    </div>
                  ) : (
                    <InlineEdit
                      value={contentHeader}
                      change={this.onPropertyChange.bind(this, "contentHeader")}
                    />
                  )}
                </h3>
                <span dangerouslySetInnerHTML={{ __html: content }}></span>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
