import React from "react";

import QueryString from "query-string";

import moment from "moment";

import { NumberService } from "../services/AxoServices";

import { LexButton, Flexbox, FlexElement } from "../utilities/LexUtilities";

import AliPayLogo from "../resources/images/alipay-logo.png";

let submitRef = React.createRef();

const AliPayPayment = (props) => {
  let query = QueryString.parse(props.location.search);
  let { amount, reference, status } = query;

  if (!amount || !reference) {
    return (
      <div className="standardMaxWidth center-block topPadding fillHeight">
        <h3>
          Invalid payment link. Contact Axolex Support at support@axolex.com.
        </h3>
      </div>
    );
  }

  return (
    <div className="topPadding fillHeight" style={{ background: "#eaecec" }}>
      <Flexbox
        column
        alignCenter
        justifyCenter
        className="standardMaxWidth center-block fillHeight"
      >
        <FlexElement>
          <h3>{reference}</h3>
        </FlexElement>
        <FlexElement>
          <h3>USD {NumberService.formatDecimal(amount)}</h3>
        </FlexElement>
        <FlexElement>
          {!status ? (
            <div className="topPadding">
              <LexButton onClick={() => submitRef.current.click()}>
                <img alt="" src={AliPayLogo} width="100" height="20" />
              </LexButton>
            </div>
          ) : (
            <>
              {status === "paid" ? (
                <h3 className="axogreen">Payment succeeded.</h3>
              ) : (
                <div className="axored text-center">
                  <h3>Payment failed.</h3>
                  <h3>Contact Axolex Support at support@axolex.com.</h3>
                </div>
              )}
            </>
          )}
        </FlexElement>
      </Flexbox>

      <form
        name="payFormCcard"
        method="post"
        action="
        https://www.paydollar.com/b2c2/eng/payment/payForm.jsp"
      >
        <input type="hidden" name="merchantId" value="88622826" />
        <input type="hidden" name="amount" value="1" />
        <input
          type="hidden"
          name="orderRef"
          value={`${reference}-${moment.utc().valueOf()}`}
        />
        <input type="hidden" name="currCode" value="840" />
        <input type="hidden" name="mpsMode" value="NIL" />
        <input
          type="hidden"
          name="successUrl"
          value={`http://www.axolex.com/AliPayPayment?reference=${reference}&amount=${amount}&status=paid`}
        />
        <input
          type="hidden"
          name="failUrl"
          value={`http://www.axolex.com/AliPayPayment?reference=${reference}&amount=${amount}&status=failed`}
        />
        <input
          type="hidden"
          name="cancelUrl"
          value={`http://www.axolex.com/AliPayPayment?reference=${reference}&amount=${amount}`}
        />
        <input type="hidden" name="payType" value="N" />
        <input type="hidden" name="lang" value="E" />
        <input type="hidden" name="payMethod" value="ALIPAY" />
        <input
          type="hidden"
          name="secureHash"
          value="44f3760c201d3688440f62497736bfa2aadd1bc0"
        />
        <input
          type="submit"
          name="submit"
          ref={submitRef}
          style={{ display: "none" }}
        />
      </form>
    </div>
  );
};

export default AliPayPayment;
