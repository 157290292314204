import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { Panel, FormControl, PanelGroup } from "react-bootstrap";

import {
  AccordionUtils,
  getText,
  Flexbox,
  FlexElement,
  LexButton,
  AxoLocal,
  Icon,
} from "../../utilities/LexUtilities";

import AccountPlansBasicSetupForm from "./AccountPlansBasicSetupForm";
import SystemAccountsSetupForm from "./SystemAccountsSetupForm";

export default class AccountPlansSetupView extends React.PureComponent {
  static propTypes = {
    financeAccountPlans: PropTypes.array.isRequired,
    onSelectPlan: PropTypes.func.isRequired,
    updateSelectedPlanPropertyMap: PropTypes.func.isRequired,
    onUpdateProperty: PropTypes.func.isRequired,
    selectedPlanId: PropTypes.number.isRequired,
    selectedPlan: PropTypes.object,
    balanceAccounts: PropTypes.array.isRequired,
    newPlanName: PropTypes.string.isRequired,
    onCreatePlan: PropTypes.func.isRequired,
  };

  render() {
    let {
      financeAccountPlans,
      onSelectPlan,
      updateSelectedPlanPropertyMap,
      onUpdateProperty,
      selectedPlanId,
      selectedPlan,
      balanceAccounts,
      newPlanName,
      onCreatePlan,
    } = this.props;

    let planOptions = financeAccountPlans.map((plan) => {
      return { value: plan.id.toString(), label: plan.name };
    });

    return (
      <div className="standardMaxWidth" style={{ margin: "15px" }}>
        <Flexbox justified style={{ marginBottom: "5px" }}>
          <FlexElement style={{ paddingRight: "15px" }}>
            <Select
              name="accountPlan"
              menuPlacement="auto"
              value={planOptions.find(
                (o) => o.value === selectedPlanId.toString()
              )}
              onChange={(selection, arg) => {
                onSelectPlan(
                  !!selection && selection.value !== "0"
                    ? parseInt(selection.value, 10)
                    : null
                );
              }}
              noOptionsMessage={() => ""}
              options={[
                {
                  value: "0",
                  label: getText("axoAccounting32", "Vælg kontoplan"),
                },
                ...planOptions,
              ]}
            />
          </FlexElement>
          <FlexElement flexGrow={1}>
            <div style={{ marginRight: "10px" }}>
              <FormControl
                type="text"
                value={newPlanName}
                onChange={(event) =>
                  onUpdateProperty("newPlanName", event.target.value)
                }
                placeholder={getText("axoAccounting6d", "Ny kontoplan...")}
                // style={{ maxWidth: '250px' }}
              />
            </div>
          </FlexElement>
          <FlexElement>
            <LexButton onClick={onCreatePlan} disabled={!newPlanName}>
              <Icon glyph="icon-fontello-plus-2" />
              &nbsp;&nbsp;
              <AxoLocal
                entity="axoAccounting25"
                defaultValue={"Opret kontoplan"}
              />
            </LexButton>
          </FlexElement>
        </Flexbox>
        {!!selectedPlan ? (
          <div>
            <PanelGroup
              id="ITsubscriptionf"
              accordion
              className="Lex-Accordion"
              defaultActiveKey="0"
              style={{
                borderRadius: "0px",
                paddingTop: "0px",
                borderBottom: "1px solid white",
              }}
            >
              <Panel className="backgroundlineargradient3" eventKey="1">
                <Panel.Heading>
                  <Panel.Title toggle>
                    {AccordionUtils.renderAccordionHeader(
                      <span>
                        <Icon glyph="icon-fontello-cogs" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="axoidcode60"
                          defaultValue={"Resultatopgørelse og balance"}
                        />
                        &nbsp;&nbsp;
                      </span>
                    )}
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible className="LexAccordionPanelody">
                  <AccountPlansBasicSetupForm
                    onSubmit={updateSelectedPlanPropertyMap}
                    financeAccountPlan={selectedPlan}
                    balanceAccounts={balanceAccounts}
                  />
                </Panel.Body>
              </Panel>
              <Panel className="backgroundlineargradient3" eventKey="2">
                <Panel.Heading>
                  <Panel.Title toggle>
                    {AccordionUtils.renderAccordionHeader(
                      <span>
                        <Icon glyph="icon-fontello-cogs" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="axoidcode61"
                          defaultValue={"Systemkonti"}
                        />
                        &nbsp;&nbsp;
                      </span>
                    )}
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible className="LexAccordionPanelody">
                  <SystemAccountsSetupForm
                    key={selectedPlanId}
                    onSubmit={updateSelectedPlanPropertyMap}
                    accounts={selectedPlan.accounts.filter(
                      (a) => a.type === "Standard"
                    )}
                    financeAccountPlan={selectedPlan}
                  />
                </Panel.Body>
              </Panel>
            </PanelGroup>

            {/* <Accordion className='Lex-Accordion' defaultActiveKey='0'>
              <Panel header={AccordionUtils.renderAccordionHeader(<span>
                <Icon glyph='icon-fontello-cogs'/>&nbsp;&nbsp;
                <AxoLocal entity='axoidcode60' defaultValue={'Resultatopgørelse og balance'}/>
                 &nbsp;&nbsp; 
                </span>)} eventKey="1">

                <AccountPlansBasicSetupForm
                  onSubmit={updateSelectedPlanPropertyMap}
                  financeAccountPlan={selectedPlan}
                  balanceAccounts={balanceAccounts}
                />

              </Panel>
              <Panel header={AccordionUtils.renderAccordionHeader(<span>
                <Icon glyph='icon-fontello-cogs'/>&nbsp;&nbsp;
                <AxoLocal entity='axoidcode61' defaultValue={'Systemkonti'}/>
                  &nbsp;&nbsp; 
                </span>)} eventKey="2">

                <SystemAccountsSetupForm
                  key={selectedPlanId}
                  onSubmit={updateSelectedPlanPropertyMap}
                  balanceAccounts={balanceAccounts}
                  financeAccountPlan={selectedPlan}
                />

              </Panel>
            </Accordion> */}
          </div>
        ) : null}
      </div>
    );
  }
}
