import React, { useState, useEffect } from "react";
import InnerHTML from 'dangerously-set-html-content'

import moment from "moment";

import {
  ApiService,
  // DataActions
} from "../../services/AxoServices";

import {
  // UploadButton,
  LexButton,
  LoadingIndicator,
  AxoLocal,
} from "../../utilities/LexUtilities";

let settings, setSettings;
let loginMessage, setLoginMessage;
// let fileId, setFileId;

function MitIDSignatureView(props) {
  [settings, setSettings] = useState(null);
  [loginMessage, setLoginMessage] = useState(null);

  let { guid, signerGuid, signerData } = props;

  useEffect(() => {
    updateMitIDSettings();
  }, []);

  const updateMitIDSettings = async () => {
    try {
      let newSettings = await ApiService.getMitIDSignatureSettings(
        guid,
        {
          signerGuid,
          ...signerData
        }
      );

      setSettings(newSettings);
    } catch {
      setFailureMessage("Dokumentet kunne ikke indlæses. Prøv igen senere.");
    }
  };

  const setFailureMessage = async (message) => {
    setLoginMessage(message);
  };

  if (!!loginMessage) {
    return <h4 className="leftPadding">{loginMessage}</h4>;
  }

  if (!settings) {
    return (
      <div>
        <h3>
          Dokumentet klargøres til MitID underskrift. Dette kan tage op til et par
          minutter.
        </h3>
        <div style={{ position: "relative" }}>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  return (
    <div className="leftPadding standardMaxWidth">
      <InnerHTML html={settings.scriptBlock} />
      <iframe src={settings.frameUrl} scrolling="no" allowFullScreen={true} style={{width: '600px', height: '550px', border: 'none', margin: 0, padding: 0}} />
    </div>
  );
}

export default MitIDSignatureView;
