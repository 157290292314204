import React from "react";
// import Conditional from '../utilities/Conditional'
import screenfull from "screenfull";
import { withRouter } from "react-router-dom";
import { ExtraSmall, SmallOrLarger } from "../utilities/Responsive";

import { Row, Col, Grid, Button, DropdownButton } from "react-bootstrap";

import {
  RoutingService,
  ModalService,
  DataActions,
  StringService,
} from "../services/AxoServices";

import {
  Icon,
  AlertModal,
  ConfirmModal,
  AxoLocal,
  FlagMenu,
  LexButton,
  Flexbox,
  FlexElement,
  AsyncButton,
} from "../utilities/LexUtilities";

class EasyIDDashboardView extends React.PureComponent {
  onClickRoute = (route) => {
    this.props.history.push(RoutingService.getPath(route));
  };

  onLogout = async () => {
    let response = await DataActions.logout().then((response) => {
      if (response.ok) {
        this.props.history.push("/");
      }
    });

    return response.ok;
  };

  onFullScreenToggle = () => {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
    this.setState({}); //Trigger a rerender to update the full screen button
  };

  renderFullScreenButtonContent = () => {
    if (screenfull.isFullscreen) {
      return (
        <Icon
          glyph="icon-fontello-resize-small-3"
          key="full"
          style={{ fontSize: "28px" }}
        />
      );
    }
    return (
      <Icon
        glyph="icon-fontello-resize-full-4"
        key="normal"
        style={{ fontSize: "28px" }}
      />
    );
  };

  onLock = () => {
    var settings = Object.assign({}, this.props.userSettings);
    settings.locked = true;
    DataActions.updateUserSettings(settings).then((response) => {
      if (response.ok) {
        this.props.history.push("/Lock");
      }
    });
  };

  renderNavBar = () => {
    let { userProfile } = this.props;
    // let newMessageCount = this.props.messageMap.received.filter(m => !m.read).length;
    // let newFriendRequestCount = this.props.unhandledFriendRequests.length;
    let path = this.props.location.pathname;

    let equalsWithoutCase = StringService.equalsWithoutCase;
    return (
      <div>
        <div className="header-position Bannecolorlogin Topheight">
          <Flexbox alignCenter layout className="standardMaxWidth center-block">
            <FlexElement className="leftPadding rightPadding buttonDropdown flexbox-center">
              <DropdownButton
                id="menuDropdown"
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;&nbsp;
                      {/* <AxoLocal style={{fontSize: '20px'}} entity='Enhedspris33' defaultValue={'Menu'}/> */}
                    </div>
                  </div>
                }
              >
                <div className="dropDownExpand">
                  <FlagMenu
                    className="axolextopmenuButton noboder Axolextopbtwidth Topheight"
                    userSettings={this.props.userSettings}
                  />
                </div>
                <div>
                  <LexButton
                    className="axolextopmenuButton"
                    onClick={this.onClickRoute.bind(this, "Setup")}
                    style={{ height: "45px" }}
                  >
                    <Flexbox alignCenter>
                      <FlexElement
                        flex={1}
                        className="text-center rightPadding"
                      >
                        <Icon
                          className="frontnavbarimg"
                          glyph="icon-fontello-cog-7"
                        />
                      </FlexElement>
                      <FlexElement flex={3} className="text-left">
                        <AxoLocal
                          entity="ClientSetupViewSetup"
                          defaultValue={"Setup"}
                        />
                      </FlexElement>
                    </Flexbox>
                  </LexButton>
                </div>
                <div>
                  <LexButton
                    className="axolextopmenuButton"
                    onClick={this.onClickRoute.bind(this, "Trash")}
                    style={{ height: "45px" }}
                  >
                    <Flexbox alignCenter>
                      <FlexElement
                        flex={1}
                        className="text-center rightPadding"
                      >
                        <Icon
                          className="frontnavbarimg"
                          glyph="icon-fontello-trash-1"
                        />
                      </FlexElement>
                      <FlexElement flex={3} className="text-left">
                        <AxoLocal
                          entity="trashMenuNav"
                          defaultValue={"Skraldespand"}
                        />
                      </FlexElement>
                    </Flexbox>
                  </LexButton>
                </div>
                <div>
                  <LexButton
                    className="axolextopmenuButton"
                    style={{ height: "45px" }}
                    onClick={this.onFullScreenToggle}
                  >
                    {this.renderFullScreenButtonContent()}
                  </LexButton>
                </div>
                {/* <div>
                  <LexButton className='axolextopmenuButton' style={{ height: '45px' }}
                    onClick={this.onLock}>
                      <Icon style={{ fontSize: '25px'}} glyph='icon-fontello-lock-2'  className='AxoNavigationheightIcon'/>
                  </LexButton>
                </div> */}
                <div>
                  <AsyncButton
                    className="axolextopmenuButton"
                    style={{ height: "45px" }}
                    onClick={this.onLogout}
                  >
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-logout-3"
                      className="AxoNavigationheightIcon"
                    />
                  </AsyncButton>
                </div>
              </DropdownButton>
            </FlexElement>
            <FlexElement className="rightPadding">
              <Button
                active={equalsWithoutCase(path, "cases")}
                className="axolextopmenuButton Axolextopbtwidth Topheight Topleftbutton"
                onClick={this.onClickRoute.bind(this, "Documents")}
              >
                <div>
                  <span className="icon-simple-line-icons-briefcase AxoNavigationheightIcon" />
                </div>
                <div className="AxoNavigationfonttaxt">
                  <AxoLocal
                    entity="documentMenuNav"
                    defaultValue={"Dokumenter"}
                  />
                </div>
              </Button>
            </FlexElement>
            <FlexElement flexGrow={1} className="text-right rightPadding">
              {userProfile.userName}
            </FlexElement>
          </Flexbox>
        </div>
        <div className="Topheight">{/* Margin */}</div>
      </div>
    );
    // return (
    //   <div>
    //   <Grid fluid>
    //     <Row>
    //       <Col xs={12} className="nopadding" >
    //         <Flexbox className='Axolexbg Topheight' alignCenter>
    //           <FlexElement className='leftPadding rightPadding buttonDropdown flexbox-center'>
    //             <DropdownButton id='menuDropdown'
    //               title={<div style={{ display:'inline-block'}}>
    //                   <div className='flexbox-center'>
    //                     <Icon style={{fontSize: '25px'}} glyph='icon-fontello-align-justify'/>&nbsp;&nbsp;&nbsp;
    //                     {/* <AxoLocal style={{fontSize: '20px'}} entity='Enhedspris33' defaultValue={'Menu'}/> */}
    //                   </div>
    //                 </div>}>
    //               <div className='dropDownExpand'>
    //                 <FlagMenu className='axolextopmenuButton noboder Axolextopbtwidth Topheight'
    //                   userSettings={this.props.userSettings}
    //                 />
    //               </div>
    //               <div>
    //                 <LexButton className='axolextopmenuButton' onClick={this.onClickRoute.bind(this, 'Setup')} style={{ height: '45px' }}>
    //                   <Flexbox alignCenter>
    //                     <FlexElement flex={1} className='text-center rightPadding'>
    //                       <Icon className="frontnavbarimg" glyph='icon-fontello-cog-7' />
    //                     </FlexElement>
    //                     <FlexElement flex={3} className='text-left'>
    //                       <AxoLocal entity='ClientSetupViewSetup' defaultValue={'Setup'}/>
    //                     </FlexElement>
    //                   </Flexbox>
    //                 </LexButton>
    //               </div>
    //               <div>
    //                 <LexButton className='axolextopmenuButton' onClick={this.onClickRoute.bind(this, 'Trash')}  style={{ height: '45px' }}>
    //                   <Flexbox alignCenter>
    //                     <FlexElement flex={1} className='text-center rightPadding'>
    //                       <Icon className="frontnavbarimg" glyph='icon-fontello-trash-1' />
    //                     </FlexElement>
    //                     <FlexElement flex={3} className='text-left'>
    //                       <AxoLocal entity='trashMenuNav' defaultValue={'Skraldespand'} />
    //                     </FlexElement>
    //                   </Flexbox>
    //                 </LexButton>
    //               </div>
    //               <div>
    //                 <LexButton className='axolextopmenuButton' style={{ height: '45px' }}
    //                   onClick={this.onFullScreenToggle}>
    //                     {this.renderFullScreenButtonContent()}
    //                 </LexButton>
    //               </div>
    //               <div>
    //                 <LexButton className='axolextopmenuButton' style={{ height: '45px' }}
    //                   onClick={this.onLock}>
    //                     <Icon style={{ fontSize: '25px'}} glyph='icon-fontello-lock-2'  className='AxoNavigationheightIcon'/>
    //                 </LexButton>
    //               </div>
    //               <div>
    //                 <LexButton className='axolextopmenuButton' style={{ height: '45px' }}
    //                   onClick={this.onLogout}>
    //                     <Icon style={{ fontSize: '25px'}}glyph='icon-fontello-logout-3' className='AxoNavigationheightIcon'/>
    //                 </LexButton>
    //               </div>
    //             </DropdownButton>
    //           </FlexElement>
    //           {/* <FlexElement className='rightPadding'>
    //             <Button active={equalsWithoutCase(path, 'mailbox')}
    //               className='AxoNavnoborder' onClick={this.onClickRoute.bind(this, '')}>
    //                 <div><span className='icon-fontello-mail-3 AxoNavigationheightIcon'/>
    //                   &nbsp;&nbsp; {newMessageCount > 0 ? newMessageCount : ''}
    //                 </div>

    //                 <div className="AxoNavigationfonttaxt">
    //                   <AxoLocal entity='mailBoxNav' defaultValue={'E-boks'}/>
    //                 </div>
    //             </Button>
    //           </FlexElement>               */}
    //           <FlexElement className='rightPadding'>
    //             <Button active={equalsWithoutCase(path, 'cases')}
    //               className='axolextopmenuButton Axolextopbtwidth Topheight Topleftbutton'  onClick={this.onClickRoute.bind(this, 'Documents')}>
    //               <div><span className='icon-simple-line-icons-briefcase AxoNavigationheightIcon'/>
    //                 </div>
    //               <div className="AxoNavigationfonttaxt">
    //                 <AxoLocal entity='documentMenuNav' defaultValue={'Dokumenter'}/>
    //               </div>
    //             </Button>
    //           </FlexElement>
    //           <FlexElement flexGrow={1} className='text-right rightPadding'>
    //             { userProfile.userName }
    //           </FlexElement>
    //         </Flexbox>
    //       </Col>
    //     </Row>
    //   </Grid>
    // </div>
    // )
  };

  renderFooter = () => {
    return (
      <div
        className="text-center"
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <LexButton style={{ width: "100%" }}>
          © &nbsp;
          <AxoLocal entity="Axolex ApS" defaultValue={"Axolex ApS"} />
        </LexButton>
      </div>
    );
  };

  renderBody = () => {
    return (
      <Grid fluid style={{ marginBottom: "45px" }}>
        <Row>
          <SmallOrLarger>
            <Col xs={12}>{this.props.children}</Col>
          </SmallOrLarger>
          <ExtraSmall>
            <Col xs={12} className="nopaddingRight nopaddingLeft">
              {this.props.children}
            </Col>
          </ExtraSmall>
        </Row>
      </Grid>
    );
  };

  render() {
    let { embedded } = this.props;
    let className = embedded ? "" : "center-block"

    return (
      <div>
        {!embedded && this.renderNavBar()}
        <div className={className} style={{ maxWidth: "1100px" }}>
          {this.renderBody()}
        </div>
        {this.renderFooter()}
        <AlertModal ref={(c) => ModalService.registerAlertModal(c)} />
        <ConfirmModal ref={(c) => ModalService.registerConfirmModal(c)} />
      </div>
    );
  }
}

export default withRouter(EasyIDDashboardView);
