// @ts-check
import React from "react";
import Conditional from "../../utilities/Conditional";
import moment from "moment";
import Select from "react-select";

import { Button, ButtonToolbar } from "react-bootstrap";

import {
  DataActions,
  UserInfoService,
  RoutingService,
  ModalService,
  // VatService,
  ApiKeys,
  NumberService,
  ApiService,
  // StripeAuthenticationService
} from "../../services/AxoServices";

import {
  LexButton,
  AxoLocal,
  LoadingIcon,
  ctx,
  withRouter,
  Icon,
  getText,
  AsyncButton,
} from "../../utilities/LexUtilities";

const UserGroupInfo = (props) => {
  const onGenerateCode = () => {
    DataActions.generateGroupCode();
  };

  var { userGroup, groupMembers } = props;
  if (!userGroup || !userGroup.id) {
    return null;
  }
  return (
    <div>
      <div className="text-center">
        <b>
          {" "}
          <AxoLocal
            entity="Gruppeinformation1"
            defaultValue={"Gruppeinformation"}
          />{" "}
        </b>
      </div>
      <div>
        <b>
          {" "}
          <AxoLocal entity="Gruppeinformation2" defaultValue={"Gruppenummer"} />
          :
        </b>{" "}
        {userGroup.id}
      </div>
      <div>
        <b>
          {" "}
          <AxoLocal
            entity="Gruppeinformation3"
            defaultValue={"Antal medlemmer"}
          />
          :
        </b>{" "}
        {userGroup.userCount}
      </div>
      <div>
        <b>
          {" "}
          <AxoLocal
            entity="Gruppeinformation4"
            defaultValue={"Maksimalt antal medlemmer"}
          />
          :
        </b>{" "}
        {userGroup.maxSize}
      </div>
      <div>
        <b>
          {" "}
          <AxoLocal entity="Gruppeinformation5" defaultValue={"Gruppelogin"} />:
        </b>{" "}
        {window.location.protocol +
          "//" +
          window.location.host +
          "/Users/GroupLogin"}
      </div>
      <div>
        <b>
          {" "}
          <AxoLocal entity="Gruppeinformation6" defaultValue={"Gruppekode"} />:
        </b>{" "}
        {userGroup.code}
      </div>
      <ButtonToolbar>
        {groupMembers.length > 0 ? (
          <Button onClick={props.clearGroupMembers} className="Lex-button-2 ">
            <AxoLocal
              entity="Gruppeinformation7"
              defaultValue={"Skjul brugere"}
            />
          </Button>
        ) : (
          <Button onClick={props.getGroupMembers} className="Lex-button-2 ">
            <AxoLocal
              entity="Gruppeinformation8"
              defaultValue={"Vis brugere"}
            />
          </Button>
        )}
        <Button onClick={onGenerateCode} className="Lex-button-2 ">
          <AxoLocal
            entity="Gruppeinformation9"
            defaultValue={"Generer ny kode"}
          />
        </Button>
      </ButtonToolbar>
      {groupMembers.length > 0 ? (
        <GroupMemberList
          userGroup={userGroup}
          groupMembers={groupMembers}
          removeGroupMember={props.removeGroupMember}
        />
      ) : null}
    </div>
  );
};

const GroupMemberList = (props) => {
  var { userGroup, groupMembers } = props;
  return (
    <div>
      <div className="text-center">
        <b>
          <AxoLocal entity="Gruppeinformation10" defaultValue={"Brugere"} />
        </b>
      </div>
      {groupMembers.map((member) => {
        return (
          <div key={member.id}>
            <Button
              disabled={member.id === userGroup.ownerId}
              onClick={props.removeGroupMember.bind(this, member.id)}
              className="Lex-button-2 "
            >
              <AxoLocal entity="Gruppeinformation11" defaultValue={"Fjern"} />
            </Button>
            &nbsp;&nbsp;&nbsp;
            <b>{UserInfoService.getDisplayName(member)}</b>
          </div>
        );
      })}
    </div>
  );
};

const GroupMemberInfo = (props) => {
  var { userGroup, storageStrings } = props;
  if (!userGroup || !userGroup.id) {
    return null;
  }
  return (
    <div>
      <div className="text-center">
        <b>
          <AxoLocal
            entity="Gruppeinformation1"
            defaultValue={"Gruppeinformation"}
          />
        </b>
      </div>
      <div>
        <b>
          <AxoLocal entity="Gruppeinformation2" defaultValue={"Gruppenummer"} />
          :
        </b>{" "}
        {userGroup.id}
      </div>
      <div>
        <b>
          <AxoLocal
            entity="Gruppeinformation14"
            defaultValue={"Administrator"}
          />
          :
        </b>{" "}
        {UserInfoService.getDisplayName(userGroup.owner)}
      </div>
      <div>
        <b>
          <AxoLocal
            entity="Gruppeinformation17"
            defaultValue={"Antal medlemmer"}
          />
          :
        </b>{" "}
        {userGroup.userCount}
      </div>
      <div>
        <b>
          <AxoLocal
            entity="Gruppeinformation16"
            defaultValue={"Personligt lagerforbrug"}
          />
          :
        </b>{" "}
        {storageStrings.localUsedString}
      </div>
    </div>
  );
};

class SubscriptionView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSubscriptionCancelledMessage: false,
      changingSubscriptionStatus: false,
      showSubscriptionSelectForm: false,
      changingSubscriptionType: false,
      selectedPlanId: props.userProfile.planId || "",
      removingCard: false,
      cardUpdateError: "",
      subscriptionUpdateError: "",
    };
    this.timeOut = null;
    // this.paylike = window.Paylike(ApiKeys.payLikeApiKey);
  }

  updateCard = async (cardId) => {
    try {
      let response = await DataActions.updateCard({
        userProfile: this.props.userProfile,
        paylikeCardId: cardId,
      });

      if (response.ok) {
        return;
      }

      if (response.status === 400) {
        let message = await response.text();
        this.handleCardUpdateError(message);
        return;
      }
      this.handleCardUpdateError("");
    } catch (error) {
      this.handleCardUpdateError(error.message);
    }
  };

  handleCardUpdateError = (reason) => {
    console.log(reason);
    this.setState({ cardUpdateError: reason });
    if (!!reason) {
      this.timeOut = setTimeout(() => {
        this.setState({ cardUpdateError: "" });
      }, 4000);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userProfile.planId !== this.props.userProfile.planId) {
      this.setState({ selectedPlanId: nextProps.userProfile.planId });
    }
  }

  getSubscriptionStatus = (sub) => {
    switch (sub.status) {
      case "Trial":
        return (
          <span>
            <AxoLocal
              key="free"
              entity="SubscripSubscriptiontrialing"
              defaultValue={"Gratis prøveperiode"}
            />{" "}
          </span>
        );
      case "Active":
        return (
          <span>
            <AxoLocal
              key="active"
              entity="SubscripSubscriptionactive"
              defaultValue={"Aktiv"}
            />
          </span>
        );
      case "PaymentFailed":
        return (
          <span className="axored">
            <AxoLocal
              key="failed"
              entity="SubscripSubscriptionpastdue"
              defaultValue={"Betaling fejlede"}
            />
          </span>
        );
      case "Canceled":
        return (
          <span>
            <AxoLocal
              key="cancelled"
              entity="SubscripSubscriptioncanceled"
              defaultValue={"Afbrudt"}
            />
          </span>
        );
      case "Unpaid":
        return (
          <span>
            <AxoLocal
              key="unpaid"
              entity="SubscripSubscriptionunpaid"
              defaultValue={"Betaling mangler"}
            />
          </span>
        );
      default:
        return <span></span>;
    }
  };

  onCancelSubscription = () => {
    this.setState({ changingSubscriptionStatus: true });
    DataActions.cancelSubscription(this.props.userProfile)
      .then((response) => {
        if (response.ok) {
          this.setState({ changingSubscriptionStatus: false });
          return true;
        }
        return Promise.reject("Subscription could not be cancelled.");
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  onActivateSubscription = async () => {
    this.setState({ changingSubscriptionStatus: true });
    let response = await DataActions.activateSubscription({
      userProfile: this.props.userProfile,
    })
    this.setState({ changingSubscriptionStatus: false });

    if(response.ok) {
      !!this.props.fetchRequests && this.props.fetchRequests();

      var reponseData = await response.json();
      if(!!reponseData.url) {
        window.location.href = reponseData.url
      }
    }

      // .then((response) => {
      //   if (response.ok) {
      //     !!this.props.fetchRequests && this.props.fetchRequests();
      //     this.setState({ changingSubscriptionStatus: false });
      //     return true;
      //   }
      //   return Promise.reject("Subscription could not be activated.");
      // })
      // .catch((reason) => {
      //   this.setState({ changingSubscriptionStatus: false });
      //   console.log(reason);
      // });
  };

  onUpdateSubscription = async () => {
    let { subscriptionPlans, userProfile, userGroup, userSubscription } =
      this.props;
    let { selectedPlanId } = this.state;

    let plan = subscriptionPlans.find((p) => p.planId === selectedPlanId);
    if (!plan) {
      return;
    }

    if (
      !!userGroup &&
      userGroup.userCount > 1 &&
      userGroup.userCount > plan.UserGroupSize
    ) {
      //MissingEntity
      ModalService.openAlertModal(
        "Dette abonnement kan ikke understøtte den nuværende brugergruppe."
      );
      return;
    }

    let sub = userSubscription;
    let amount = plan.amount;
    if (!!sub && !!sub.purchasedUnits) {
      let netPurchasedUnits = Math.max(
        sub.purchasedUnits - sub.freeUnitsPerInterval,
        0
      );
      amount += netPurchasedUnits * sub.costPerUnit;
    }
    this.doUpdateSubscription( {
        paylikeTransactionId: null,
        paylikeCardId: null,
      });

    //Do not require credit card when signing up for free or trial plans, unless there are unpaid units, or
    //the next plan requires you to pay for signatures right away
    // if (
    //   (amount === 0 || plan.trialPeriod > 0) &&
    //   (plan.costPerUnit === 0 || plan.freeUnitsPerInterval > 0)
    // ) {
    //   this.doUpdateSubscription({
    //     paylikeTransactionId: null,
    //     paylikeCardId: null,
    //   });
    // } else {
    //   this.paylike.popup(
    //     {
    //       currency: amount > 0 ? plan.currency.toUpperCase() : "",
    //       amount,
    //       title: plan.name,
    //       fields: [
    //         {
    //           name: "name",
    //           label: getText("axoAccounting7", "Navn"),
    //           type: "string",
    //           required: true,
    //           value: `${userProfile.firstName || ""} ${
    //             userProfile.lastName || ""
    //           }`,
    //         },
    //         {
    //           name: "email",
    //           label: getText("socialSidebarContainerEmail", "Email"),
    //           type: "email",
    //           required: true,
    //           value: userProfile.eMail,
    //         },
    //       ],
    //       custom: {
    //         userName: userProfile.userName,
    //         subscriptionPlan: plan.planId + " - " + plan.name,
    //       },
    //     },
    //     (err, res) => {
    //       if (err) {
    //         console.log(err);
    //         return;
    //       }

    //       this.doUpdateSubscription({
    //         paylikeTransactionId: !!res.transaction ? res.transaction.id : null,
    //         paylikeCardId: !!res.card ? res.card.id : null,
    //       });
    //     }
    //   );
    // }
  };

  doUpdateSubscription = async ({ paylikeTransactionId, paylikeCardId }) => {
    this.setState({ changingSubscriptionType: true });
    let userProfile = {
      ...this.props.userProfile,
      planId: this.state.selectedPlanId,
    };
    try {
      let response = await DataActions.activateSubscription({
        userProfile,
        paylikeTransactionId,
        paylikeCardId,
      });
      if (response.ok) {
        this.setState({
          showSubscriptionSelectForm: false,
          changingSubscriptionType: false,
          subscriptionUpdateError: "",
        });
        !!this.props.fetchRequests && this.props.fetchRequests();
        var responseData = await response.json();
        if(!!responseData.url) {
          location.href = responseData.url;
          return;
        }

        return;
      } else if (response.status === 400) {
        let message = await response.text();
        this.handleSubscriptionUpdateError(message);
        return;
      }
      this.handleSubscriptionUpdateError("");
    } catch (error) {
      this.handleSubscriptionUpdateError(error.message);
    }
  };

  handleSubscriptionUpdateError = (reason) => {
    console.log(reason);
    this.setState({
      changingSubscriptionType: false,
      subscriptionUpdateError: reason,
    });
    if (!!reason) {
      this.timeOut = setTimeout(() => {
        this.setState({ subscriptionUpdateError: "" });
      }, 25000);
    }
  };

  onUpdateCard = async () => {
    let response = await ApiService.getSubscriptionPaymentLink();

    if(!response.ok) {
      console.log(JSON.stringify(response));
      return false;
    }

    let urlObj = await response.json();
    window.location.href = urlObj.url;

    return true;
    // window.open(urlObj.url);

    // this.paylike.popup(
    //   {
    //     title: getText("axoidcode167", "Indtast kort"),
    //     description: getText("axoidcode168", "Indtast kortoplysninger"),
    //   },
    //   (err, res) => {
    //     if (err) {
    //       return console.log(err);
    //     }

    //     this.updateCard(res.card.id);
    //   }
    // );
  };

  onChangeSubscription = () => {
    this.setState({ showSubscriptionSelectForm: true });
  };

  onCancelChangeSubscription = () => {
    this.setState({
      showSubscriptionSelectForm: false,
      selectedPlanId: this.props.userProfile.planId,
    });
  };

  selectSubscription = (selection) => {
    if (!selection) {
      return;
    }
    this.setState({ selectedPlanId: selection.value });
  };

  getStorageStrings = () => {
    var storageInfo = this.props.storageInformation;
    if (!storageInfo.maximum) {
      return {
        usedString: "",
        localUsedString: "",
        maxStorageString: "",
      };
    }
    var used = storageInfo.used;
    var localUsed = storageInfo.localUsed;
    var maxStorage = storageInfo.maximum;
    var maxStorageString = (maxStorage / Math.pow(1024, 3)).toFixed(2) + " GB";
    var usedString = this.getStorageString(used);
    var localUsedString =
      used === localUsed ? usedString : this.getStorageString(localUsed);
    return {
      usedString,
      localUsedString,
      maxStorageString,
    };
  };

  getStorageString(storage) {
    var storageString = "";
    if (storage > Math.pow(1024, 3)) {
      storageString = (storage / Math.pow(1024, 3)).toFixed(2) + " GB";
    } else if (storage > Math.pow(1024, 2)) {
      storageString = (storage / Math.pow(1024, 2)).toFixed(2) + " MB";
    } else if (storage > 1024) {
      storageString = (storage / 1024).toFixed(2) + " KB";
    } else {
      storageString = storage.toFixed(2) + " bytes";
    }
    return storageString;
  }

  getIntervalLabel = (period) => {
    switch (period) {
      case 0:
        return ctx.getSync("DayLabel");
      case 1:
        return ctx.getSync("WeekLabel");
      case 2:
        return ctx.getSync("MonthLabel");
      case 3:
        return ctx.getSync("YearLabel");
      default:
        return "";
    }
  };

  getSubscriptionOptions = () => {
    let currency = this.props.userSubscription.currency;
    return this.props.subscriptionPlans
      .filter((plan) => {
        return (
          !currency || plan.currency.toLowerCase() === currency.toLowerCase()
        );
      })
      .map((plan) => {
        return this.getPlanOption(plan);
      });
  };

  getSelectedSubscription = (planId) => {
    let plan = this.props.subscriptionPlans.find((s) => s.planId === planId);
    if (!plan) {
      return { value: "", label: "" };
    }
    return this.getPlanOption(plan);
  };

  getPlanOption = (plan) => {
    return {
      value: plan.planId,
      label:
        plan.name +
        " " +
        plan.currency.toUpperCase() +
        " " +
        (plan.amount / 100).toFixed(2) +
        " / " +
        this.getIntervalLabel(plan.interval),
    };
  };

  onCustomizeSubscription = () => {
    this.props.history.push(RoutingService.getPath("Subscribe"));
  };

  onRemoveCard = () => {
    this.setState({ removingCard: true });
    DataActions.removeCard()
      .then(() => {
        this.setState({ removingCard: false });
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ removingCard: false });
      });
  };

  onSelectPaymentRequest = (selection) => {
    if (!selection) {
      return selection;
    }

    var url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/InvoicePayment?code=" +
      selection.value;
    window.open(url);
  };

  render() {
    let {
      // userProfile,
      userGroup,
      paymentRequests,
      subscriptionPlans,
      userSubscription,
    } = this.props;

    let {
      selectedPlanId,
      removingCard,
      cardUpdateError,
      subscriptionUpdateError,
      changingSubscriptionType,
    } = this.state;

    let sub = userSubscription;
    let card = sub.card;
    let subTrial = sub.status === "Trial" && !sub.cancelAtPeriodEnd;
    let subActive = sub.status === "Active" && !sub.cancelAtPeriodEnd;
    let subMissingPayment =
      sub.status === "PaymentFailed" || sub.status === "Unpaid";
    let planId = this.props.userProfile.planId;
    let activePlan = subscriptionPlans.find((p) => p.planId === planId);
    let selectedPlan = subscriptionPlans.find(
      (p) => p.planId === selectedPlanId
    );
    let planRequiresPayment =
      !!activePlan && (activePlan.amount > 0 || activePlan.costPerUnit > 0);

    let storageStrings = this.getStorageStrings();

    // let vatRate = VatService.getVatRate(userProfile.country);

    let invoiceOptions = paymentRequests.map((r) => ({
      value: r.guid,
      label: moment(r.invoice.creationDate).format("LL"),
    }));

    if (!sub.planId && userGroup && userGroup.id) {
      return (
        <GroupMemberInfo
          userGroup={userGroup}
          storageStrings={storageStrings}
          groupMembers={this.props.groupMembers}
        />
      );
    }
    return (
      <div>
        {!!sub.planId ? (
          <React.Fragment>
            <div className="text-center">
              <b>
                <AxoLocal
                  entity="CaseEditInlineStatusSelect"
                  defaultValue="Status"
                />
              </b>
            </div>
            <div>
              <b>
                {" "}
                <AxoLocal
                  entity="SubscriptionSubscriptionName"
                  defaultValue="Abonnement"
                />
                :
              </b>{" "}
              {sub.planName}
            </div>
            <div>
              <b>
                <AxoLocal
                  entity="SubscriptionSubscriptionPrice"
                  defaultValue="Pris"
                />
                :{" "}
              </b>
              {/* MissingEntity */}
              {NumberService.formatDecimal(sub.amount / 100)} {sub.currency} /{" "}
              <AxoLocal
                entity="SubscriptiongetSubscriptionPrice"
                defaultValue={"måned"}
              />{" "}
              inkl. moms
            </div>
            <div>
              <b>
                <AxoLocal
                  entity="CaseEditInlineStatusSelect"
                  defaultValue="Status"
                />
                :
              </b>{" "}
              {this.getSubscriptionStatus(sub)}
              <Conditional
                inline
                show={sub.status === "Active" && sub.cancelAtPeriodEnd}
              >
                &nbsp;{" "}
                <AxoLocal
                  entity="SubscriptioncancelAtPeriodEndcancelAtPeriodEnd"
                  defaultValue="(stopper efter udløb)"
                />
              </Conditional>
            </div>
            <Conditional show={subTrial}>
              <b>
                <AxoLocal
                  entity="SubscriptioncancelAtPeriodsubTrial"
                  defaultValue="Prøveperiode slutter"
                />
                :
              </b>{" "}
              {moment(sub.trialEnd).format("L")}
            </Conditional>
            <Conditional show={subActive}>
              <b>
                <AxoLocal
                  entity="SubscriptioncurrentPeriodEnd"
                  defaultValue="Næste betaling"
                />
                :
              </b>{" "}
              {moment(sub.currentPeriodEnd).format("L")}
            </Conditional>
            <Conditional show={sub.cancelAtPeriodEnd}>
              <b>
                <AxoLocal
                  entity="SubscriptioncancelAtPeriodEnd"
                  defaultValue="Abonnement udløber"
                />
                :
              </b>{" "}
              {moment(sub.currentPeriodEnd).format("L")}
            </Conditional>
            <Conditional show={storageStrings.usedString}>
              <b>
                <AxoLocal
                  entity="SubscriptionstorageStrings"
                  defaultValue="Lager"
                />
                :
              </b>{" "}
              {storageStrings.usedString} / {storageStrings.maxStorageString}
              &nbsp;
            </Conditional>
            {storageStrings.usedString !== storageStrings.localUsedString ? (
              <div>
                <b>
                  {" "}
                  <AxoLocal
                    entity="axoAccounting40"
                    defaultValue="Personligt lagerforbrug"
                  />
                  :
                </b>{" "}
                {storageStrings.localUsedString}
              </div>
            ) : null}
            {/* MissingEntity */}
            {activePlan && activePlan.type === "EasyID" && (
              <>
                {sub.freeUnitsPerInterval > 0 && (
                  <div>
                    <b>Gratis underskrifter:</b>{" "}
                    {Math.min(sub.purchasedUnits, sub.freeUnitsPerInterval)} /{" "}
                    {sub.freeUnitsPerInterval}
                  </div>
                )}
                <div>
                  <b>Underskrifter:</b>{" "}
                  {Math.max(sub.purchasedUnits - sub.freeUnitsPerInterval, 0)}
                </div>
                <div>
                  <b>Pris per underskrift:</b>{" "}
                  {NumberService.formatDecimal(sub.costPerUnit / 100)}{" "}
                  {sub.currency} inkl. moms. (
                  {NumberService.formatDecimal(sub.costPerUnit / 100 / 1.25)}{" "}
                  {sub.currency} ekskl. moms)
                </div>
                <div>
                  <b>Næste opkrævning:</b>
                  &nbsp;
                  {NumberService.formatDecimal(
                    (sub.amount +
                      Math.max(
                        sub.purchasedUnits - sub.freeUnitsPerInterval,
                        0
                      ) *
                        sub.costPerUnit) /
                      100
                  )}{" "}
                  {sub.currency}
                </div>
              </>
            )}
          </React.Fragment>
        ) : null}
        {subMissingPayment && (
          <>
            <div className="text-center axored">
              <strong>Betaling mangler</strong>
            </div>
            <div className="text-center bottomPadding">
              <LexButton
                disabled={changingSubscriptionType}
                onClick={this.onUpdateSubscription}
              >
                Betal abonnement
                <LoadingIcon show={changingSubscriptionType} />
              </LexButton>
            </div>
          </>
        )}
        {planRequiresPayment && (
          <>
            <div className="text-center">
              <b>
                <AxoLocal
                  entity="Subscriptionstoragecard"
                  defaultValue="Kortinformation"
                />
              </b>
            </div>
            {!!card ? (
              <div>
                <div>
                  <b>
                    <AxoLocal
                      entity="Subscriptionstoragecardbrand"
                      defaultValue="Type"
                    />
                    :
                  </b>{" "}
                  {card.brand}
                </div>
                <div>
                  <b>
                    {" "}
                    <AxoLocal
                      entity="SubscriptionstorageexpirationMonth"
                      defaultValue="Udløbsdato"
                    />
                    :
                  </b>{" "}
                  {NumberService.formatMonth(card.expirationMonth)} / {card.expirationYear}
                </div>
                <div>
                  <b>
                    {" "}
                    <AxoLocal
                      entity="Subscriptionstoragecardlastnumber"
                      defaultValue="Kortnummer"
                    />
                    :
                  </b>{" "}
                  ************{card.last4}
                </div>
              </div>
            ) : (
              <div className="text-center">
                <AsyncButton onClick={this.onUpdateCard}>
                  <AxoLocal
                    entity="axoAccounting41"
                    defaultValue="Vælg betalingskort"
                  />
                </AsyncButton>
              </div>
            )}
          </>
        )}
        {this.renderUpdateError(cardUpdateError)}
        {invoiceOptions.length > 0 && (
          <div>
            <div className="text-center">
              <strong>
                {" "}
                <AxoLocal
                  entity="documentMenuNavF"
                  defaultValue={"Fakturaer"}
                />
              </strong>
            </div>
            <div style={{ maxWidth: "500px" }}>
              <Select
                name="invoiceSelect"
                menuPlacement="auto"
                value={{ value: 0, label: "Vælg faktura..." }}
                onChange={this.onSelectPaymentRequest}
                noOptionsMessage={() => ""}
                options={invoiceOptions}
              />
            </div>
          </div>
        )}
        <UserGroupInfo
          userGroup={this.props.userGroup}
          groupMembers={this.props.groupMembers}
          getGroupMembers={this.props.getGroupMembers}
          clearGroupMembers={this.props.clearGroupMembers}
          removeGroupMember={this.props.removeGroupMember}
        />
        <br />
        <Conditional show={!sub.cancelAtPeriodEnd}>
          <Button
            disabled={this.state.changingSubscriptionStatus}
            style={{ width: "100%" }}
            className="Lex-button-2 "
            onClick={this.onCancelSubscription}
          >
            <AxoLocal
              entity="SubscriptionstoragechangingSubscriptionStatus"
              defaultValue="Afbryd abonnement"
            />
            <Conditional inline show={this.state.changingSubscriptionStatus}>
              &nbsp;
              <img
                alt=""
                src="/imgs/app/loading.gif"
                className="img-circle"
                width="20"
                height="20"
              />
            </Conditional>
          </Button>
        </Conditional>
        <Conditional show={sub.cancelAtPeriodEnd}>
          <br />
          <Button
            disabled={this.state.changingSubscriptionStatus}
            style={{ width: "100%" }}
            className="Lex-button-2 "
            onClick={this.onActivateSubscription}
          >
            <AxoLocal
              entity="SubscriptionstorageonActivateSubscription"
              defaultValue="Genaktiver abonnement"
            />
            <Conditional inline show={this.state.changingSubscriptionStatus}>
              &nbsp;
              <img
                alt=""
                src="/imgs/app/loading.gif"
                className="img-circle"
                width="20"
                height="20"
              />
            </Conditional>
          </Button>
        </Conditional>
        <Conditional show={!this.state.showSubscriptionSelectForm}>
          <br />
          <Button
            style={{ width: "100%" }}
            className="Lex-button-2 "
            onClick={this.onChangeSubscription}
          >
            <AxoLocal
              entity="SubscriptionstorageonChangeSubscription"
              defaultValue="Skift abonnement"
            />
          </Button>
        </Conditional>
        <Conditional show={this.state.showSubscriptionSelectForm}>
          <br />
          <div className="text-center">
            <b>
              <AxoLocal
                entity="SubscriptionstorageshowSubscriptionSelectForm"
                defaultValue="Vælg nyt abonnement (inkl. moms)"
              />
            </b>
          </div>
          <br />
          <div style={{ width: "90%", margin: "auto" }}>
            {/* <AxoLocal componentClass={ReactOption} value='A' componentAttribute='text' entity='CaseDataTableFixedselectoptionA' defaultValue={'A'}/> */}
            <Select
              name="form-field-name"
              menuPlacement="auto"
              value={this.getSelectedSubscription(selectedPlanId)}
              onChange={this.selectSubscription}
              noOptionsMessage={() => ""}
              options={this.getSubscriptionOptions()}
            />
            {!!selectedPlan && (
              <>
                {selectedPlan.trialPeriod > 0 && (
                  <div>Prøvedage: {selectedPlan.trialPeriod}</div>
                )}
                <div>
                  <AxoLocal
                    entity="SubscriptionstorageStrings"
                    defaultValue="Lager"
                  />
                  : {selectedPlan.storage} GB
                </div>
                {selectedPlan.userGroupSize > 0 && (
                  <div>
                    <AxoLocal
                      entity="Gruppeinformation4"
                      defaultValue={"Maksimalt antal medlemmer"}
                    />
                    : {selectedPlan.userGroupSize}
                  </div>
                )}
                {selectedPlan.type === "EasyID" && (
                  <>
                    {selectedPlan.freeUnitsPerInterval > 0 && (
                      <div>
                        Gratis underskrifter:{" "}
                        {selectedPlan.freeUnitsPerInterval}
                      </div>
                    )}
                    <div>
                      Pris per underskrift:{" "}
                      {NumberService.formatDecimal(
                        selectedPlan.costPerUnit / 100
                      )}{" "}
                      {selectedPlan.currency} inkl. moms. (
                      {NumberService.formatDecimal(
                        selectedPlan.costPerUnit / 100 / 1.25
                      )}{" "}
                      {selectedPlan.currency} ekskl. moms)
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <Conditional show={selectedPlanId !== planId}>
            <br />
            <div className="text-center axored">
              <Conditional show={subTrial}>
                <AxoLocal
                  entity="SubscriptionstorageselectSubscriptiontrialing"
                  defaultValue={
                    'Betaling vil blive trukket ved udløbet af den nuværende periode." '
                  }
                />
              </Conditional>
              <Conditional show={!subTrial}>
                {/* <div><AxoLocal entity='clientSetupCreditCard'  defaultValue={'Betalingskort'}/>: {!!card &&  <span>************{card.last4}</span>}</div> */}
                <div>
                  <AxoLocal
                    entity="SubscriptionstorageselectSubscriptionstatus"
                    defaultValue={
                      'Betaling for første måned vil blive opkrævet efter tryk på "Godkend" '
                    }
                  />
                </div>
                <div>
                  <AxoLocal
                    entity="axoidcode166"
                    defaultValue={
                      "Restbeløb fra eksisterende abonnement vil ikke blive refunderet."
                    }
                  />
                </div>
                {!!activePlan && activePlan.type === "EasyID" && (
                  <div>
                    Forbrugte underskrifter vil blive afregnet til den
                    eksisterende abonnementspris.
                  </div>
                )}
              </Conditional>
            </div>
          </Conditional>
          <br />
          <div className="flexbox-center">
            <ButtonToolbar>
              <Button
                disabled={
                  selectedPlanId === planId ||
                  this.state.changingSubscriptionType
                }
                className="Lex-button-2"
                onClick={this.onUpdateSubscription}
              >
                <AxoLocal
                  entity="SubscriptionstorageselectSubscriptionsubIndex"
                  defaultValue={"Godkend"}
                />
                <Conditional inline show={this.state.changingSubscriptionType}>
                  &nbsp;
                  <img
                    alt=""
                    src="/imgs/app/loading.gif"
                    className="img-circle"
                    width="20"
                    height="20"
                  />
                </Conditional>
              </Button>
              <Button
                className="Lex-button-2 "
                onClick={this.onCancelChangeSubscription}
              >
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </Button>
              <Button
                className="Lex-button-2 "
                onClick={this.onCustomizeSubscription}
              >
                <AxoLocal
                  entity="SubscriptionViewPricingFreetrialAB10"
                  defaultValue="Kustomiser abonnement"
                />
              </Button>
            </ButtonToolbar>
          </div>
        </Conditional>
        {this.renderUpdateError(subscriptionUpdateError)}
        <br />
        <div>
          <AsyncButton
            style={{ width: "100%" }}
            className="Lex-button-2 "
            onClick={this.onUpdateCard}
          >
            <AxoLocal
              entity="SubscriptionstorageonUpdateCard"
              defaultValue={"Skift kort"}
            />
          </AsyncButton>
        </div>
        <br />
        {/* {!!card ? (
          <div>
            <Button
              style={{ width: "100%" }}
              className="Lex-button-2"
              disabled={removingCard}
              onClick={this.onRemoveCard}
            >
              <AxoLocal entity="axoAccounting42" defaultValue=" Fjern kort" />
              <LoadingIcon show={removingCard} />
            </Button>
          </div>
        ) : null} */}
      </div>
    );
  }

  renderUpdateError(errorMessage) {
    if (!errorMessage) {
      return;
    }
    if (errorMessage.includes("CountryMismatch")) {
      return (
        <div className="axored">
          <AxoLocal
            entity="axoAccounting61"
            defaultValue={
              "Dit kreditkort skal være udstedt i samme land som du valgte da du udfyldte din brugerprofil."
            }
          />
        </div>
      );
    }
    if (errorMessage.includes("MissingVatNumber")) {
      return (
        <div className="axored">
          <AxoLocal
            entity="axoAccounting65"
            defaultValue={"CVR nummeret kunne ikke valideres."}
          />
        </div>
      );
    }
    return (
      <div className="axored">
        <Icon glyph="icon-fontello-info-3" />
        &nbsp;&nbsp;
        <AxoLocal
          entity="LoginshowServerError"
          defaultValue={"Der skete en fejl. Prøv igen senere."}
        />
        <div>{errorMessage}</div>
      </div>
    );
  }
}

export default withRouter(SubscriptionView);
