import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// import {
//   PrintService
// } from '../../services/AxoServices'

import { LexButton, AxoLocal } from "../../utilities/LexUtilities";

import { ButtonToolbar } from "react-bootstrap";

const ListItem = (props) => {
  return <div style={{ paddingBottom: "5px" }}>{props.children}</div>;
};

export default class OfficeWorkList extends React.PureComponent {
  static propTypes = {
    model: PropTypes.object.isRequired,
  };

  onPrint = () => {
    window.print();
    // if (typeof window !== 'undefined') {
    //   PrintService.printElement(document.getElementById('printList'));
    // }
  };

  render() {
    let { model, onEdit } = this.props;

    return (
      <div className="leftPadding topPadding standardMaxWidth pageSection">
        <div className="hidden-print">
          <ButtonToolbar>
            <LexButton onClick={onEdit}>
              <AxoLocal
                entity="presentationMarketing30"
                defaultValue="Rediger"
              />{" "}
            </LexButton>
            <LexButton onClick={this.onPrint}>
              <AxoLocal
                entity="PrintCaseClientadressePrint"
                defaultValue={"Print"}
              />
            </LexButton>
          </ButtonToolbar>
        </div>
        <div id="printList">
          <div>
            <h2>Ansættelseskontrakt</h2>
          </div>
          <ListItem>
            <h4>Mellem undertegnede</h4>
          </ListItem>
          <div>
            <strong>{model.employerName}</strong>
          </div>
          <div>{model.employerAddress}</div>
          <div>
            {model.employerPostalCode} {model.employerCity}
          </div>
          <ListItem>herefter kaldet ”Virksomheden”</ListItem>
          <ListItem>
            <h4>og</h4>
          </ListItem>
          <div>
            <strong>{model.workerName}</strong>
          </div>
          <div>{model.workerAddress}</div>
          <div>
            {model.workerPostalCode} {model.workerCity}
          </div>
          <ListItem>herefter kaldet ”Medarbejderen”</ListItem>

          <ListItem>
            <strong>1. Indledning</strong>
          </ListItem>
          <ListItem>
            Medarbejderen er forpligtet til altid at holde Virksomheden
            underrettet om ændringer i ovenstående personlige oplysninger.
          </ListItem>
          <ListItem>
            Der er indgået aftale om ansættelse på følgende vilkår:
          </ListItem>
          <ListItem>
            <strong>2. Arbejdssted</strong>
          </ListItem>
          <ListItem>
            Medarbejderens arbejdssted er på selskabets adresse:
          </ListItem>
          <ListItem>{model.workPlaceAddress}</ListItem>
          <ListItem>
            Medarbejderen kan udføre arbejdet hjemmefra, i den udstrækning det
            er foreneligt med virksomhedens drift og medarbejderens ønske.
          </ListItem>
          <ListItem>
            <strong>3. Arbejdsbeskrivelse</strong>
          </ListItem>
          <ListItem>Medarbejderen ansættes som:</ListItem>
          <ListItem>
            <strong>{model.jobTitle}</strong>
          </ListItem>
          <ListItem>
            Medarbejderens primære arbejdsopgaver er følgende:{" "}
          </ListItem>
          <ListItem>{model.jobDescription}</ListItem>
          <ListItem>
            <strong>4. Tiltrædelsestidspunkt</strong>
          </ListItem>
          <ListItem>Ansættelsesforholdet påbegyndes den :</ListItem>
          <ListItem>{moment.utc(model.startDate).format("L")}</ListItem>
          <div className="footerpagebreak"></div>
          <div className="printTopPadding"></div>
          <ListItem>
            <strong>5. Vederlag</strong>
          </ListItem>
          <ListItem>
            Lønnen er fastsat til {model.salary}kr. per måned, som udbetales
            bagud den sidste bankdag i måneden.
          </ListItem>
          <ListItem>
            Lønnen forhandles hvert år i januar måned, første gang i januar i
            ansættelsesåret.
          </ListItem>
          <ListItem>
            Der er ved fastsættelsen af nævnte løn taget højde for forekommende
            overarbejde.
          </ListItem>
          <ListItem>Overarbejde kan også afspadseres efter aftale.</ListItem>
          <ListItem>
            Funktionæren må forvente, at der i periode kan forekomme overarbejde
            som ikke honoreres særskilt.
          </ListItem>
          {!!model.pensionPercent && (
            <ListItem>
              Udover grundlønnen indbetaler Virksomheden et tillæg på{" "}
              {model.pensionPercent}% til pension.
            </ListItem>
          )}
          {!!model.workerPensionPercent && (
            <ListItem>
              Medarbejderen er forpligtet til at indbetale{" "}
              {model.workerPensionPercent}% af den aftalte løn til pension.
            </ListItem>
          )}
          <ListItem>
            <strong>6. Arbejdstid</strong>
          </ListItem>
          <ListItem>
            Den normale ugentlige arbejdstid er aftalt til {model.workTime}{" "}
            timer.
          </ListItem>
          <ListItem>
            Med hensyn til den ugentlige arbejdstid tilstræbes det, at arbejdet
            i videst muligt omfang udføres indenfor den normale arbejdstid,{" "}
          </ListItem>
          <ListItem>
            Arbejdstiden er eksklusiv ½ times daglig frokostpause.
          </ListItem>
          <ListItem>
            <strong>7. Ferie</strong>
          </ListItem>
          <ListItem>
            Medarbejderen er berettiget til 5 ugers ferie med løn i henhold til
            Ferieloven. Ferie optjenes og afholdes i overensstemmelse med
            reglerne i Ferieloven.
          </ListItem>
          <ListItem>
            Det særlige ferietillæg i henhold til Ferieloven er aftalt til:{" "}
            {model.holidayBonusPercent}%.
          </ListItem>
          <ListItem>Der er aftalt fridage på helligdage.</ListItem>
          <ListItem>
            <strong>8. Opsigelse</strong>
          </ListItem>
          <ListItem>
            <strong>Prøvetid</strong>
          </ListItem>
          {!!model.trialPeriod && (
            <>
              <ListItem>
                De første {model.trialPeriod} måneder af ansættelsen anses som
                prøvetid, i denne periode kan opsigelses fra såvel arbejdsgiver
                som medarbejderens side finde sted med 14 dages varsel til
                fratræden en hvilken som helst dag i måneden.
              </ListItem>
              <ListItem>
                <strong>Efter {model.trialPeriod} måneder</strong>
              </ListItem>
            </>
          )}
          <ListItem>
            Ansættelsesforholdet kan{" "}
            {!!model.trialPeriod && <>efter prøvetiden </>}opsiges af
            medarbejderen og arbejdsgiveren i henhold til reglerne i
            funktionærlovens § 2.
          </ListItem>
          <ListItem>Opsigelsesvarslet fra arbejdsgiver udgør:</ListItem>
          <ListItem>
            1 måned ved opsigelse inden udgangen af 5 måneders ansættelse
          </ListItem>
          <ListItem>
            3 måneder ved opsigelse inden udgangen af 2 år og 9 måneders
            ansættelse
          </ListItem>
          <ListItem>
            4 måneder ved opsigelse inden udgangen af 5 år og 8 måneders
            ansættelse
          </ListItem>
          <ListItem>
            5 måneder ved opsigelse inden udgangen af 8 år og 7 måneders
            ansættelse
          </ListItem>
          <ListItem>
            6 måneder ved opsigelse ved længere ansættelse end 8 år og 7
            måneder.
          </ListItem>
          <ListItem>For medarbejderen er opsigelsesvarslet 1 måned.</ListItem>
          <ListItem>
            Såfremt medarbejderen for et tidsrum af 12 følgende måneder har
            oppebåret løn under sygdom, i alt 120 dage, kan medarbejderen
            opsiges med en måneds varsel til fratræden ved en måneds udgang jf.
            Funktionærloven § 5 stk. 2.
          </ListItem>
          <div className="footerpagebreak"></div>
          <div className="printTopPadding"></div>

          <ListItem>
            Ved Virksomhedens opsigelse har medarbejderen ret til efter eget
            ønske, at blive fritstillet i en del af eller i hele
            opsigelsesperioden. Fritstillingen sker uden modregning for eventuel
            anden lønindtægt.
          </ListItem>
          <ListItem>
            Der kan ikke varsles ferieafholdelse i opsigelses- eller
            fritstillingsperioden.
          </ListItem>

          <ListItem>
            <strong>9. Tavshedspligt</strong>
          </ListItem>
          <ListItem>
            Medarbejderen har tavshedspligt med hensyn til alt, hvad
            medarbejderen erfarer i forbindelse
          </ListItem>
          <ListItem>
            med udførelsen af sit arbejde, med mindre der er tale om forhold,
            der efter sagens natur må
          </ListItem>
          <ListItem>
            bringes til tredjemands kundskab. Denne tavshedspligt er også
            gældende efter
          </ListItem>
          <ListItem>ansættelsesforholdet ophør.</ListItem>
          <ListItem>
            Når medarbejderen fratræder sin stilling – uanset af hvilken årsag –
            skal alt materiale, der tilhører
          </ListItem>
          <ListItem>
            arbejdsgiveren, og som er i medarbejderens besiddelse, afleveres til
            arbejdsgiveren.
          </ListItem>
          <ListItem>
            Herudover gælder markedsføringslovens § 23, hvorefter medarbejderen
            ikke må viderebringe eller benytte Virksomhedens
            erhvervshemmeligheder.
          </ListItem>
          <ListItem>
            <strong>10. Øvrige bestemmelser</strong>
          </ListItem>
          <ListItem>
            Funktionærloven er gældende for ansættelsesforholdet.
          </ListItem>
          <ListItem>
            <strong>11. Underskrift og godkendelse</strong>
          </ListItem>
          <ListItem>
            Ansættelseskontrakten er udarbejdet i 2 ens eksemplarer. Hver af
            parterne modtager et originalt dokument.{" "}
          </ListItem>
          <ListItem>
            Begge parter er indforstået med de anførte ansættelsesvilkår.
          </ListItem>
          <ListItem>{model.other}</ListItem>
          <br />
          {/* <ListItem>{moment.utc(model.contractDate).format('L')}</ListItem>
        <br/> */}
          <ListItem></ListItem>
          <ListItem>Dato og sted:</ListItem>
          <ListItem></ListItem>
          <ListItem>___________________________</ListItem>
          <ListItem>{model.employerName}</ListItem>
          <br />
          <ListItem>Dato og sted:</ListItem>
          <ListItem></ListItem>
          <ListItem>___________________________</ListItem>
          <ListItem>{model.workerName} </ListItem>
        </div>
      </div>
    );
  }
}
