import React from "react";
import { Link } from "react-router-dom";
import RoutingService from "../services/RoutingService";

import { AxoLocal, AxoFooter } from "../utilities/LexUtilities";

import { Row, Col, Grid } from "react-bootstrap";

export default class ResetPasswordConfirmation extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
    // $('html').addClass('authentication');
  }

  // componentWillUnmount() {
  //   $('html').removeClass('authentication');
  // }
  render() {
    return (
      <div className="login SideHeight" style={{ paddingTop: "25px" }}>
        <div>
          <div>
            <Grid fluid>
              <Row>
                <Col
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <div className="frontPageaxolexdiv Bannecolorlogin">
                    <AxoLocal
                      entity="ResetPasswordConfirmationUpdatepassword"
                      defaultValue={"Opdater kodeord."}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img
                      alt=""
                      className="hidden-lg hidden-sm hidden-md"
                      src="/imgs/app/Axologo3-30x30.png"
                    />
                    <img
                      alt=""
                      className="hidden-lg hidden-md hidden-xs"
                      src="/imgs/app/Axologo3-35x35.png"
                    />
                    <img
                      alt=""
                      className="hidden-lg hidden-sm hidden-xs"
                      src="/imgs/app/Axologo3-40x40.png"
                    />
                    <img
                      alt=""
                      className="hidden-md hidden-sm hidden-xs"
                      src="/imgs/app/Axologo3-40x40.png"
                    />
                  </div>
                </Col>
                <Col
                  md={6}
                  mdOffset={3}
                  sm={8}
                  smOffset={2}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <div>
                    <div>
                      <div style={{ padding: 0 }}>
                        <div>
                          <h4 className="text-center" style={{ padding: 12.5 }}>
                            <AxoLocal
                              entity="ResetPasswordConfirmationupdated"
                              defaultValue={"Dit kodeord er blevet opdateret."}
                            />
                          </h4>
                          <h4 className="text-center" style={{ padding: 12.5 }}>
                            <Link to={RoutingService.getAccountPath("Login")}>
                              <AxoLocal
                                entity="ResetPasswordConfirmationClickhere"
                                defaultValue={"Klik her for at logge ind"}
                              />
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <AxoFooter />
      </div>
    );
  }
}
