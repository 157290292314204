import React from "react";
import DataStore from "../../services/DataAccess/DataStore";
import InvoicesTabView from "../../routes/Invoices/InvoicesTabView";
import { withRouter } from "react-router-dom";
// import QueryString from 'query-string'
import moment from "moment";

import {
  Uniqueomat,
  withRTKData,
  withRTKCaseData,
} from "../../utilities/LexUtilities";

import // RoutingService,
// AuthorizationService,
// TimeEntryService,
// DataActions,
// ApiService
"../../services/AxoServices";

class CustomerInvoicesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      timeEntryMap: {
        timeEntries: [],
        count: 0,
        trashed: [],
        trashedCount: 0,
      },
      allCases: [],
      casesWithEntries: [],
      sharedCasesWithEntries: [],
      clientProfiles: [],
      invoices: [],
      PDFs: [],
      documents: [],
      documentMap: {
        documents: [],
        count: 0,
      },
      sharedPDFs: [],
      userProfile: {},
      userSettings: {},
      friends: [],
      clientLabels: [],
      selectedFiscalYearId: 0,
      selectedFiscalYear: {},
      selectedStartDate: moment.utc().format(),
      selectedEndDate: moment.utc().format(),
      myContactInfo: {},
      selectedInvoiceContact: {},
    };
    this.name = "CustomerInvoicesContainer";
  }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      let activeCases = store.caseMap.cases;
      let casesWithEntries = activeCases.filter(
        (c) => !c.trashed && c.timeEntries.length > 0
      );
      let sharedCasesWithEntries = store.sharedCases.filter(
        (c) => c.timeEntries.length > 0
      );

      let activeInvoices = store.invoices.filter((i) => !i.trashed);

      let singleInvoices = activeInvoices.filter((i) => !i.isSubscription);

      let sortedInvoices = singleInvoices.sort((l, r) => {
        return r.number - l.number;
      });

      let invoiceSubscriptions = activeInvoices
        .filter((i) => i.isSubscription)
        .sort((l, r) => new Date(r.creationDate) - new Date(l.creationDate));

      let activePDFs = store.documentMap.invoices;
      let sharedPDFs = store.sharedDocuments.filter(
        (d) => !d.trashed && d.invoice
      );

      let filteredLabels = store.labels.filter((l) => l.type === 3); //Clients
      let sortedLabels = filteredLabels.sort((l1, l2) =>
        l1.name.localeCompare(l2.name)
      );

      let selectedFiscalYear = !!store.fiscalYears
        ? store.fiscalYears.find((f) => f.id === store.selectedFiscalYearId)
        : null;

      this.setState({
        userProfile: store.userProfile,
        userSettings: store.userSettings,
        friends: store.friends,
        clientProfiles: store.clientProfiles,
        timeEntryMap: store.timeEntryMap,
        allCases: activeCases,
        casesWithEntries,
        sharedCasesWithEntries,
        invoices: sortedInvoices,
        invoiceSubscriptions,
        PDFs: activePDFs,
        documentMap: store.documentMap,
        sharedPDFs,
        clientLabels: sortedLabels,
        myContactInfo: store.myContactInfo,
        products: store.products,
        clientPlan: store.clientPlan,
        fiscalYears: store.fiscalYears,
        showDropdowns: store.showDropdowns,
        selectedFiscalYearId: store.selectedFiscalYearId,
        selectedFiscalYear,
        selectedStartDate: store.selectedInvoiceStartDate,
        selectedEndDate: store.selectedInvoiceEndDate,
      });
    });

    //Fiscal years should come from the accounting client, not the selected client.
    //Accounting client data is fetched in ComponentDidUpdate(), if available
    DataStore.setState({
      fiscalYears: null,
      selectedFiscalYearId: null,
      selectedInvocieStartDate: moment.utc().startOf("year"),
      selectedInvoiceEndDate: moment.utc().endOf("year"),
    });

    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.initializeTimeEntries();
    // DataStore.fetchCases();
    // DataStore.fetchSharedCases();
    DataStore.fetchClientProfiles();
    // DataStore.fetchInvoices();
    DataStore.initializeDocuments();
    DataStore.fetchSharedDocuments();
    DataStore.initializeLabels();
    DataStore.fetchProducts();

    this.fetchedInvoices = false;
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  componentDidUpdate() {
    let { selectedContact } = this.props;
    let { myContactInfo } = this.state;

    console.log(myContactInfo);
    if (!!myContactInfo.id && !this.fetchedInvoices) {
      console.log("Hej");
      this.oldSelectedContact = selectedContact;
      //Fiscal years must belong to the accounting client, whereas invoices belong to the selected client
      DataStore.setSelectedContact(myContactInfo);

      DataStore.fetchClientPlan();
      DataStore.fetchFiscalYears().then(() => DataStore.fetchInvoices());
      this.fetchedInvoices = true;

      DataStore.setSelectedContact(this.oldSelectedContact);
    }
  }

  getPDFs = () => {
    var allPDFs = this.state.PDFs.concat(this.state.sharedPDFs);
    var sortedPDFs = allPDFs.sort((l, r) => {
      return new Date(r.uploadDate) - new Date(l.uploadDate);
    });
    return sortedPDFs;
  };

  getTimeEntries = () => {
    let { timeEntries } = this.state.timeEntryMap;

    let sharedCaseEntries = this.getTimeEntriesFromSharedCases();
    let sharedClientEntries = this.getTimeEntriesFromSharedClients();
    let sharedTimeEntries = sharedCaseEntries.concat(sharedClientEntries);

    let allTimeEntries = timeEntries.concat(sharedTimeEntries);
    let uniqueTimeEntries = Uniqueomat.uniqueById(allTimeEntries);
    return uniqueTimeEntries;
  };

  getTimeEntriesFromSharedCases = () => {
    var sharedTimeEntries = this.state.sharedCasesWithEntries.reduce(
      (accumulator, caseModel) => {
        return accumulator.concat(caseModel.timeEntries);
      },
      []
    );
    return sharedTimeEntries;
  };

  getTimeEntriesFromSharedClients = () => {
    var { userProfile } = this.state;
    var sharedClients = this.state.clientsWithEntries.filter(
      (c) => c.userName !== userProfile.userName
    );
    var sharedTimeEntries = sharedClients.reduce((accumulator, client) => {
      return accumulator.concat(client.timeEntries);
    }, []);

    return sharedTimeEntries;
  };

  getCasesWithEntries = () => {
    let allCases = this.state.casesWithEntries.concat(
      this.state.sharedCasesWithEntries
    );
    let uniqueCases = Uniqueomat.uniqueById(allCases);

    return uniqueCases;
  };

  getActiveTab = (props) => {
    let path = props.location.pathname.toLowerCase();
    if (path.includes("table")) {
      return "Table";
    }
    if (path.includes("pdf")) {
      return "PDF";
    }
    if (path.includes("create")) {
      return "Create";
    }
    if (path.includes("products")) {
      return "Products";
    }
    if (path.includes("single")) {
      return "Single";
    }
    // if(path.includes('subscriptions')) {
    //   return 'Subscriptions';
    // }
    // if(path.includes('addsubscription')) {
    //   return 'AddSubscription';
    // }
    return "Table";
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(eventKey);
  };

  handleSelectedClient = (selectedClient) => {
    this.setState({ selectedInvoiceContact: selectedClient });

    DataStore.setSelectedContact(selectedClient);

    DataStore.fetchInvoices();
  };

  onSelectFiscalYear = (fiscalYearId) => {
    DataStore.selectFiscalYear(fiscalYearId);
    DataStore.fetchInvoices();
  };

  onSelectStartDate = (selectedStartDate) => {
    DataStore.updateProperty("selectedInvoiceStartDate", selectedStartDate);
    DataStore.fetchInvoices();
  };

  onSelectEndDate = (selectedEndDate) => {
    DataStore.updateProperty("selectedInvoiceEndDate", selectedEndDate);
    DataStore.fetchInvoices();
  };

  render() {
    var PDFs = this.getPDFs();

    let { contactMap } = this.props;
    let clients = [...contactMap.contacts];
    clients.sort((c1, c2) => {
      return (c1.lastName || "").localeCompare(c2.lastName || "");
    });
    let clientsWithEntries = clients.filter(
      (cl) => cl.timeEntries && cl.timeEntries.length > 0
    );
    let clientsWithInvoices = clients.filter(
      (cl) => cl.invoices && cl.invoices.length > 0
    );
    let clientsWithPDFs = clientsWithInvoices.filter((cl) =>
      cl.invoices.find((i) => i.hasPDFs)
    );

    let clientData = {
      allClients: clients,
      clientsWithEntries,
      clientsWithInvoices,
      clientsWithPDFs,
    };

    return (
      <InvoicesTabView
        activeTab={this.getActiveTab(this.props)}
        {...this.state}
        {...clientData}
        contactMap={contactMap}
        handleNavigation={this.handleNavigation}
        handleSelectedClient={this.handleSelectedClient}
        onSelectFiscalYear={this.onSelectFiscalYear}
        onSelectStartDate={this.onSelectStartDate}
        onSelectEndDate={this.onSelectEndDate}
        timeEntries={this.getTimeEntries()}
        casesWithEntries={this.getCasesWithEntries()}
        PDFs={PDFs}
      />
    );
  }
}

export default withRTKCaseData(
  withRTKData(withRouter(CustomerInvoicesContainer))
);
