import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import {
  // AxoLocal,
  LexButton,
  Flexbox,
  AxoLocal,
  AsyncButton,
  Icon,
  getText,
} from "../../../utilities/LexUtilities";

export default class SystemAccountsSetupForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    financeAccountPlan: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...props.financeAccountPlan,
      taxSpecifications: props.financeAccountPlan.taxSpecifications.map((t) => {
        return { ...t };
      }),
      showErrorMessage: false,
      showServerError: false,
    };
    this.newId = 0;
  }

  onPropertyChange = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  componentDidUpdate(prevProps) {
    //Assign ids to added tax specifications
    if (
      prevProps.financeAccountPlan.taxSpecifications.length !==
      this.props.financeAccountPlan.taxSpecifications.length
    ) {
      this.setState({
        taxSpecifications: this.props.financeAccountPlan.taxSpecifications.map(
          (t) => {
            return { ...t };
          }
        ),
      });
    }
  }

  onaAddTaxSpecification = () => {
    this.setState((prevState) => ({
      taxSpecifications: prevState.taxSpecifications.concat([
        {
          id: "New-" + this.newId,
          financeAccountPlanId: prevState.id,
        },
      ]),
    }));
    this.newId++;
  };

  onRemoveTaxSpecification = (id) => {
    this.setState((prevState) => ({
      taxSpecifications: prevState.taxSpecifications.filter(
        (tax) => tax.id !== id
      ),
    }));
  };

  onUpdateTaxSpecification = (id, propertyName, value) => {
    this.setState((prevState) => ({
      taxSpecifications: prevState.taxSpecifications.map((tax) => {
        if (tax.id !== id) {
          return tax;
        }
        return {
          ...tax,
          [propertyName]: value,
        };
      }),
    }));
  };

  validateBankReconAccounts = () => {
    let { bankReconAccounts } = this.state;

    if (!bankReconAccounts) {
      return true;
    }

    return this.validateIntervalString(bankReconAccounts);
  };

  validateBankReconDebitorAccounts = () => {
    let { bankReconDebitorAccounts } = this.state;

    if (!bankReconDebitorAccounts) {
      return true;
    }

    return this.validateIntervalString(bankReconDebitorAccounts);
  };

  validateIntervalString = (intervalString) => {
    var intervalArray = intervalString.split(";").map((m) => m.trim());
    for (let i = 0; i < intervalArray.length; ++i) {
      let interval = intervalArray[i];
      let intervalNumbers = interval.split("-").map((m) => m.trim());
      for (let j = 0; j < intervalNumbers.length; ++j) {
        let number = parseInt(intervalNumbers[j], 10);
        if (isNaN(number)) {
          return false;
        }
      }
    }

    return true;
  };

  onSubmit = async () => {
    if (!this.validateBankReconAccounts()) {
      this.setState({ showBankReconError: true });
      setTimeout(() => this.setState({ showBankReconError: false }), 3000);
      return false;
    }

    if (!this.validateBankReconAccounts()) {
      this.setState({ showBankReconDebitorError: true });
      setTimeout(
        () => this.setState({ showBankReconDebitorError: false }),
        3000
      );
      return false;
    }

    let newPlan = {
      ...this.state,
      taxSpecifications: this.state.taxSpecifications.map((taxSpec) => {
        if (isNaN(taxSpec.id)) {
          let newSpec = { ...taxSpec };
          delete newSpec.id;
          return newSpec;
        }
        return taxSpec;
      }),
    };
    try {
      let result = await this.props.onSubmit(newPlan);
      if (!result) {
        throw new Error("Plan submission could not be completed.");
      }
      return true;
    } catch (reason) {
      console.log(reason.message);
      this.setState({ showServerError: true });
      setTimeout(() => this.setState({ showServerError: false }), 3000);
      return false;
    }
  };

  getVatTypeOptions = () => {
    return [
      { value: "Ingoing", label: getText("axoidcode144", "Købsmoms") },
      { value: "Outgoing", label: getText("axoidcode145", "Salgsmoms") },
      {
        value: "ForeignGoods",
        label: getText("axoEntityidcode9", "Varekøb fra udlandet"),
      },
      {
        value: "ServiceReverseCharge",
        label: getText(
          "axoEntityidcode10",
          "Ydelseskøb m. omvendt betalingspligt"
        ),
      },
      { value: "Other", label: getText("axoidcode146", "Anden moms") },
    ];
  };

  render() {
    let { accounts } = this.props;

    let {
      customerReceivablesAccountId,
      vatSettlementAccountId,
      resultAccountId,
      bankAccountIntervalStart,
      bankAccountIntervalEnd,
      customerBankAccountId,
      customerPrivateAccountId,
      taxSpecifications,
      showServerError,
    } = this.state;

    let accountOptions = [{ value: "0", label: "..." }].concat(
      accounts.map((acc) => {
        return { value: acc.id, label: acc.number + " - " + acc.name };
      })
    );

    let vatTypeOptions = this.getVatTypeOptions();

    return (
      <Form style={{ paddingBottom: "25px" }}>
        <h3>
          <AxoLocal entity="axoidcode50" defaultValue={"Kontoplan opsætning"} />
          &nbsp;
          {/* <AxoLocal entity='axoidcode63' defaultValue='(Der skal anvendes konti fra balancen)'/> */}
        </h3>
        <FormGroup controlId="customerPrivate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal entity="axoidcode51" defaultValue={"Momskoder"} />
            &nbsp;&nbsp;&nbsp;
            <LexButton onClick={this.onaAddTaxSpecification}>
              <Icon glyph="icon-fontello-plus-1" />
              &nbsp;&nbsp;
              <AxoLocal entity="axoidcode52" defaultValue={"Tilføj kode"} />
            </LexButton>
          </ControlLabel>
          {taxSpecifications.map((taxSpec) => {
            return (
              <Flexbox justified collapseXS key={taxSpec.id}>
                <FormGroup
                  controlId="equityIntervalStart"
                  style={{ margin: "0px", paddingRight: "15px" }}
                >
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal
                      entity="SidebarRightContaineruseruserName"
                      defaultValue={"Navn"}
                    />
                    &nbsp;&nbsp;&nbsp;
                  </ControlLabel>
                  <FormControl
                    type="text"
                    className="borderFormControlfocus"
                    value={taxSpec.name}
                    onChange={(event) =>
                      this.onUpdateTaxSpecification(
                        taxSpec.id,
                        "name",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
                <FormGroup
                  controlId="equityIntervalEnd"
                  style={{ margin: "0px", paddingRight: "15px" }}
                >
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal entity="axoidcode53" defaultValue={"Momskode"} />
                    &nbsp;&nbsp;&nbsp;
                  </ControlLabel>
                  <FormControl
                    type="text"
                    className="borderFormControlfocus"
                    value={taxSpec.code}
                    onChange={(event) =>
                      this.onUpdateTaxSpecification(
                        taxSpec.id,
                        "code",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
                <FormGroup
                  controlId="equityIntervalEnd"
                  style={{ margin: "0px", paddingRight: "15px" }}
                >
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal
                      entity="invoiPaymentattheSubtotalVAT"
                      defaultValue={"Moms"}
                    />
                    &nbsp;&nbsp;&nbsp;
                  </ControlLabel>
                  <FormControl
                    type="number"
                    className="borderFormControlfocus"
                    value={
                      isNaN(taxSpec.taxPercentage) ? "" : taxSpec.taxPercentage
                    }
                    onChange={(event) =>
                      this.onUpdateTaxSpecification(
                        taxSpec.id,
                        "taxPercentage",
                        event.target.valueAsNumber
                      )
                    }
                  />
                </FormGroup>
                <FormGroup
                  controlId="salesTax"
                  style={{ margin: "0px", paddingRight: "15px" }}
                >
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal
                      entity="AccountingTabViewEntity13"
                      defaultValue={"Konto"}
                    />
                  </ControlLabel>
                  <Select
                    name="salesTax"
                    menuPlacement="auto"
                    value={accountOptions.find(
                      (o) => o.value === taxSpec.receivingAccountId
                    )}
                    onChange={(selection, arg) => {
                      this.onUpdateTaxSpecification(
                        taxSpec.id,
                        "receivingAccountId",
                        !!selection && selection.value !== "0"
                          ? selection.value
                          : null
                      );
                    }}
                    noOptionsMessage={() => ""}
                    options={accountOptions}
                    placeholder="..."
                  />
                </FormGroup>
                <FormGroup
                  controlId="type"
                  style={{ margin: "0px", paddingRight: "15px" }}
                >
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal
                      entity="DocumentGridViewType"
                      defaultValue={"Type"}
                    />
                  </ControlLabel>
                  <Select
                    name="typeSelect"
                    menuPlacement="auto"
                    value={vatTypeOptions.find(
                      (o) => o.value === taxSpec.taxType
                    )}
                    onChange={(selection, arg) => {
                      this.onUpdateTaxSpecification(
                        taxSpec.id,
                        "taxType",
                        !!selection && selection.value !== "0"
                          ? selection.value
                          : null
                      );
                    }}
                    noOptionsMessage={() => ""}
                    options={vatTypeOptions}
                  />
                </FormGroup>
                {/* Only new codes can be removed */}
                {isNaN(taxSpec.id) ? (
                  <FormGroup>
                    <ControlLabel
                      style={{
                        visibility: "hidden",
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                    >
                      ...
                    </ControlLabel>
                    <div>
                      <LexButton
                        onClick={() =>
                          this.onRemoveTaxSpecification(taxSpec.id)
                        }
                      >
                        <Icon glyph="icon-fontello-trash-1" />
                        &nbsp;&nbsp;
                        <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                      </LexButton>
                    </div>
                  </FormGroup>
                ) : null}
              </Flexbox>
            );
          })}
        </FormGroup>
        <FormGroup controlId="receivables">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="axoidcode54"
              defaultValue={"Konto til tilgodehavende"}
            />
          </ControlLabel>
          <Select
            name="receivables"
            menuPlacement="auto"
            value={accountOptions.find(
              (o) => o.value === customerReceivablesAccountId
            )}
            onChange={(selection) => {
              this.onPropertyChange(
                "customerReceivablesAccountId",
                !!selection && selection.value !== "0" ? selection.value : null
              );
            }}
            noOptionsMessage={() => ""}
            options={accountOptions}
          />
        </FormGroup>
        <FormGroup controlId="vatSettlement">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="axoidcode127"
              defaultValue={"Momsafregningskonto"}
            />
          </ControlLabel>
          <Select
            name="vatSettlement"
            menuPlacement="auto"
            value={accountOptions.find(
              (o) => o.value === vatSettlementAccountId
            )}
            onChange={(selection, arg) => {
              this.onPropertyChange(
                "vatSettlementAccountId",
                !!selection && selection.value !== "0" ? selection.value : null
              );
            }}
            noOptionsMessage={() => ""}
            options={accountOptions}
          />
        </FormGroup>
        <FormGroup controlId="vatSettlement">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="axoidcode128"
              defaultValue={"Resultat af tidligere år"}
            />
          </ControlLabel>
          <Select
            name="resultat"
            menuPlacement="auto"
            value={accountOptions.find((o) => o.value === resultAccountId)}
            onChange={(selection, arg) => {
              this.onPropertyChange(
                "resultAccountId",
                !!selection && selection.value !== "0" ? selection.value : null
              );
            }}
            noOptionsMessage={() => ""}
            options={accountOptions}
          />
        </FormGroup>
        <FormGroup controlId="customerBank">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="AccountingTabViewEntity24"
              defaultValue={"Bank"}
            />
          </ControlLabel>
          <Select
            name="customerBank"
            menuPlacement="auto"
            value={accountOptions.find(
              (o) => o.value === customerBankAccountId
            )}
            onChange={(selection, arg) => {
              this.onPropertyChange(
                "customerBankAccountId",
                !!selection && selection.value !== "0" ? selection.value : null
              );
            }}
            noOptionsMessage={() => ""}
            options={accountOptions}
          />
        </FormGroup>
        <FormGroup controlId="customerPrivate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="ContactCreateViewPrivateCustomer"
              defaultValue={"Privat"}
            />{" "}
            udlæg
          </ControlLabel>
          <Select
            name="receivables"
            menuPlacement="auto"
            value={accountOptions.find(
              (o) => o.value === customerPrivateAccountId
            )}
            onChange={(selection, arg) => {
              this.onPropertyChange(
                "customerPrivateAccountId",
                !!selection && selection.value !== "0" ? selection.value : null
              );
            }}
            noOptionsMessage={() => ""}
            options={accountOptions}
          />
        </FormGroup>
        <FormGroup controlId="customerPrivate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Beholdningskonti
          </ControlLabel>
          <Flexbox justified>
            <FormGroup
              controlId="bankIntervalStart"
              style={{ margin: "0px", paddingRight: "15px" }}
              className="flexbox-standard"
            >
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  entity="SidebarRightContainercalendarEventstart"
                  defaultValue={"Start"}
                />
                &nbsp;&nbsp;&nbsp;
              </ControlLabel>
              <FormControl
                type="number"
                className="borderFormControlfocus"
                value={
                  isNaN(bankAccountIntervalStart)
                    ? ""
                    : bankAccountIntervalStart
                }
                onChange={(event) =>
                  this.onPropertyChange(
                    "bankAccountIntervalStart",
                    event.target.valueAsNumber
                  )
                }
              />
            </FormGroup>
            <FormGroup
              controlId="bankIntervalEnd"
              style={{ margin: "0px" }}
              className="flexbox-standard"
            >
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  entity="CalendarEventBoxFormSlut"
                  defaultValue={"Slut"}
                />
                &nbsp;&nbsp;&nbsp;
              </ControlLabel>
              <FormControl
                type="number"
                className="borderFormControlfocus"
                value={
                  isNaN(bankAccountIntervalEnd) ? "" : bankAccountIntervalEnd
                }
                onChange={(event) =>
                  this.onPropertyChange(
                    "bankAccountIntervalEnd",
                    event.target.valueAsNumber
                  )
                }
              />
            </FormGroup>
          </Flexbox>
        </FormGroup>
        <ButtonToolbar>
          <AsyncButton onClick={this.onSubmit}>
            <Icon glyph="icon-fontello-floppy-1" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="CalendarEventBoxFormonSave"
              defaultValue={"Gem"}
            />
          </AsyncButton>
          <LexButton
            onClick={() =>
              this.setState({
                ...this.props.financeAccountPlan,
                taxSpecifications:
                  this.props.financeAccountPlan.taxSpecifications.map((t) => {
                    return { ...t };
                  }),
              })
            }
          >
            <Icon glyph="icon-fontello-ccw" />
            &nbsp;&nbsp;
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
        {showServerError ? (
          <div className="axored">
            <Icon glyph="icon-fontello-info-3" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="LoginshowServerError"
              defaultValue={"Der skete en fejl. Prøv igen senere."}
            />
          </div>
        ) : null}
      </Form>
    );
  }
}
