import React from "react";

//Component that allows injection of props into a select option
export default class ReactOption extends React.PureComponent {
  //Props
  //value
  //text
  render() {
    return <option value={this.props.value}>{this.props.text}</option>;
  }
}
