import React from "react";

import {
  ApiService,
  DataStore,
  DataActions,
} from "../../../services/AxoServices";

import { Consumer, withRTKData } from "../../../utilities/LexUtilities";

import AccountingSetupView from "./AccountingSetupView";

class AccountingSetupContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeFiscalYear: {},
      showConfirmationMessage: false,
    };
  }

  handleSelectedClient = (selectedClient) => {
    DataStore.updateProperty("selectedContact", selectedClient);
    DataStore.fetchFiscalYears();
  };

  onSubmitFiscalYear = (fiscalYear) => {
    if (fiscalYear.id) {
      return DataActions.updateFiscalYear(fiscalYear);
    }
    return DataActions.createFiscalYear(fiscalYear);
  };

  hasPendingVat = async (clientId, fiscalYearId) => {
    let vatResult = await ApiService.getVatReport({ clientId, fiscalYearId });
    return vatResult.ingoing > 0 || vatResult.outgoing > 0;
  };

  onTransferBalance = (activeFiscalYear) => {
    this.setState({
      activeFiscalYear,
    });
  };

  onCancelTransfer = () => {
    this.setState({
      activeFiscalYear: {},
    });
  };

  doTransferBalance = async (clientId) => {
    let { activeFiscalYear } = this.state;

    let response = await DataActions.transferBalance({
      clientId,
      fiscalYearId: activeFiscalYear.id,
    });

    if (!!response && !!response.ok) {
      let newEntries = await response.json();
      DataStore.setState({
        importantEntryIds: new Set([
          ...DataStore.getStore().importantEntryIds,
          ...newEntries.map((e) => e.id),
        ]),
      });

      this.setState({
        showConfirmationMessage: true,
        activeFiscalYear: {},
      });
      this.timeout = setTimeout(
        () => this.setState({ showConfirmationMessage: false }),
        4000
      );
      return true;
    }
    return false;
  };

  render() {
    return (
      <div style={{ maxWidth: "1200px", marginLeft: "15px" }}>
        <AccountingSetupView
          {...this.props}
          {...this.state}
          hasPendingVatFunc={this.hasPendingVat}
          onTransferBalance={this.onTransferBalance}
          onCancelTransfer={this.onCancelTransfer}
          doTransferBalance={this.doTransferBalance}
          handleSelectedClient={this.handleSelectedClient}
          onSubmitFiscalYear={this.onSubmitFiscalYear}
        />
      </div>
    );
  }
}

class AccountingSetupConsumer extends React.PureComponent {
  componentDidMount() {
    DataStore.fetchFiscalYears();
    DataStore.fetchClientPlan();
  }

  mapStoreToProps = (store) => {
    return {
      entries: store.fiscalYears || [],
      clientPlan: store.clientPlan,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => (
          <AccountingSetupContainer
            {...this.mapStoreToProps(store)}
            {...this.props}
          />
        )}
      </Consumer>
    );
  }
}

export default withRTKData(AccountingSetupConsumer);
