import React, { useState } from "react";
import Conditional from "../../utilities/Conditional";
import moment from "moment";
import CardLogos1 from "../../resources/images/Visa.png";
import CardLogos2 from "../../resources/images/MasterCard.jpg";
import CardLogos3 from "../../resources/images/gold2.png";

import {
  Row,
  Col,
  Grid,
  Table,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";

import {
  ApiService,
  DataActions,
  ModalService,
  UserInfoService,
  TimeEntryService,
  DataStore,
  NumberService,
  AuthorizationService,
} from "../../services/AxoServices";

import {
  AxoCheckbox,
  Icon,
  InlineEdit,
  ctx,
  AxoLocal,
  InlineTextArea,
  InlineNumberEdit,
  ClientSearchBox,
  getText,
  Flexbox,
  FlexElement,
  AsyncButton,
  LexButton,
  LoadingIcon,
  InlineCountrySelect,
  AxoDateTime,
  FileViewerModal,
} from "../../utilities/LexUtilities";

import ReactSelect from "react-select";

import NewProductForm from "./NewProductForm";

const PostingGenerationProgress = {
  NONE: 0,
  FETCHINGHTML: 1,
  GENERATING: 2,
  ERROR: 3,
};

const SendingStatus = {
  NONE: 0,
  CONFIRM: 1,
  SENDING: 2,
  SENT: 3,
};

export default class InvoiceV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.invoice) {
      this.state = {
        invoice: { ...props.invoice },
        paymentRequests: [],
      };
    } else {
      this.state = {
        invoice: {
          number: 0,
          invoiceType: ctx.getSync("InvoiceInvoicenr"), // <AxoLocal entity='InvoiceInvoicenr'defaultValue={'Faktura #'}/>,
          invoiceNo: "",
          currency: props.userProfile.currency,
          creationDate: moment().format("YYYY-MM-DD"),
          paymentType: "Net",
          dueDate: moment().add(8, "days").format("YYYY-MM-DD"),
          sendReminder: false,
          status: "Created",
          outstandingAmount: this.getTotal(props.timeEntries),
          // message: ctx.getSync('InvoiceInvoicemessage'),//'Tak for samarbejdet. Husk at oplyse dit ID-nummer ved betalingen. Betaling kan ske via online betalingsservice.
          // message: ctx.getSync('InvoiceInvoicemessage') + ' ' + (props.userProfile.accountNo || ''),//'Tak for samarbejdet. Husk at oplyse dit ID-nummer ved betalingen. Betaling kan ske via online betalingsservice.
          thankYou: ctx.getSync("InvoiceInvoicethankYou"), //'Tak for at vælge vores firma. Det har været en fornøjelse at samarbejde med dig.'}/>,
          client: this.getClient(props.timeEntries),
          userProfile: props.userProfile,
          timeEntries: props.timeEntries,
          paymentRequests: [],
          reverseVAT: false,
        },
      };
      if (props.isSubscription) {
        this.state.invoice = {
          ...this.state.invoice,
          isSubscription: true,
          //For subscriptions, creationDate and dueDate are the start and end dates of the subscription
          dueDate: null,
          paymentDays: 8,
          subscriptionInterval: "Month",
          subscriptionAction: "ApproveAndSend",
        };
        //IsSubscription should be set here
        this.state.invoice = {
          ...this.state.invoice,
          subscriptionSenderMail: props.userProfile.eMail,
          subscriptionReceiverMail: "",
          subscriptionMailSubject: this.getDefaultSubject(this.state.invoice),
          subscriptionMailBody: this.getDefaultMessage(),
          subscriptionSendCopy: false,
        };
      }
      this.state.invoice.message = this.getInvoiceMessage(
        props.userProfile.accountNo,
        0
      );
    }

    this.state = {
      ...this.state,
      addingProductForEntryIndex: null,
    };

    this.state.showImageRemoveButton = false;
    this.state.showSharedMessage = false;
    this.state.isGeneratingPayment = false;
    this.state.showPaymentErrorMessage = false;
    this.state.showPaymentCompletedMessage = false;
    this.state.sendingStatus = SendingStatus.NONE;

    this.fileModal = React.createRef();
  }

  getInvoiceMessage = (accountNo, invoiceNo) => {
    let numberString = this.isSubscription()
      ? "((InvoiceNumber))"
      : invoiceNo.toString();
    return (
      getText("axoEntityidcode173", "Beløbet indbetales på bankkonto:") +
      "\n" +
      (accountNo || "") +
      "\n" +
      getText("axoEntityidcode174", "Faktura nr.") +
      " " +
      numberString +
      " " +
      getText("axoEntityidcode175", "bedes angivet ved bankoverførsel.")
    );
    // return 'Beløbet indbetales på bankkonto:\n' + (accountNo || '') + '\nFaktura nr. ' + numberString + ' bedes angivet ved bankoverførsel.';
  };

  componentDidMount() {
    this.initializeInvoice();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.invoice &&
      JSON.stringify(nextProps.invoice) !== JSON.stringify(this.props.invoice)
    ) {
      this.setState({ invoice: Object.assign({}, nextProps.invoice) }, () => {
        this.initializeInvoice();
      });
    }
  }

  initializeInvoice = () => {
    let { showPaymentRequests } = this.props;
    let { invoice } = this.state;

    console.log(invoice.number);
    if (!invoice.number && !invoice.isSubscription) {
      ApiService.getNextInvoiceNumber().then((numberInfo) => {
        var newInvoice = Object.assign({}, this.state.invoice);
        newInvoice.number = numberInfo.next;
        this.setState((prevState) => ({
          invoice: {
            ...prevState.invoice,
            number: numberInfo.next,
            message: this.getInvoiceMessage(
              invoice.userProfile.accountNo,
              numberInfo.next
            ),
          },
        }));
      });
    }
    //Fetch the full client
    // if(invoice.client && invoice.client.id) {
    //   ApiService.getSingleContact(this.state.invoice.client.id)
    //   .then( fullClient => {
    //     var newInvoice = Object.assign({}, this.state.invoice);
    //     newInvoice.client = fullClient;
    //     this.setState({ invoice: newInvoice });
    //   })
    // };
    if (invoice.id && showPaymentRequests) {
      ApiService.getPaymentRequestsForInvoice(invoice.id).then(
        (paymentRequests) => {
          this.setState({ paymentRequests });
        }
      );
    }
  };

  hasUnits = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.units > 0);
  };

  hasDiscounts = () => {
    return !!this.state.invoice.timeEntries.find((t) => !!t.discountPercentage);
  };

  onAddTimeEntry = () => {
    this.setState((prevState) => ({
      invoice: {
        ...prevState.invoice,
        timeEntries: [
          ...prevState.invoice.timeEntries,
          {
            unitType: "Hours",
            units: 0,
            rate: 0,
            tax: 0.25,
            discountPercentage: 0.0,
            expenses: 0,
            aConto: 0,
          },
        ],
      },
    }));
  };

  onRemoveTimeEntry = (index) => {
    this.setState((prevState) => ({
      invoice: {
        ...prevState.invoice,
        timeEntries: [
          ...prevState.invoice.timeEntries.filter(
            (t) => t !== prevState.invoice.timeEntries[index]
          ),
        ],
      },
    }));
  };

  //Props
  //timeEntries
  //onCreatePDF
  renderTimeEntriesTable = (subTotal) => {
    // var hasUnits = this.hasUnits();
    let hasDiscounts = this.hasDiscounts();
    let readOnly = this.isReadOnly();

    // var footerOffset = hasDiscounts || !readOnly ? 5 : 4;
    return (
      <Table striped>
        <thead className="axocblueabanne">
          <tr>
            <th>
              #
              {!readOnly ? (
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <Icon
                    className=""
                    glyph="icon-fontello-plus-3"
                    role="button"
                    onClick={this.onAddTimeEntry}
                  />
                </span>
              ) : null}
            </th>
            <th>
              <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
            </th>
            <th className="text-right">
              <AxoLocal entity="InvoiceInvoiceunit" defaultValue={"Antal"} />
            </th>
            <th className="text-right">
              <AxoLocal entity="unitUnitType" defaultValue={"Enhedstype"} />
            </th>
            <th className="text-right">
              <AxoLocal
                entity="InvoiceInvoicePrisunit"
                defaultValue={"Pris pr. enhed"}
              />
            </th>
            {(hasDiscounts || !readOnly) && (
              <th className="text-right">
                <AxoLocal entity="axoEntityidcode88" defaultValue={"Rabat"} />
              </th>
            )}
            <th className="text-right">
              <AxoLocal entity="InvoiceInvoicesum" defaultValue={"Beløb"} />
            </th>
          </tr>
        </thead>
        <tbody>{this.renderTimeEntryRows()}</tbody>
        {/* <tfoot className='axocblueabanne'>
          <tr>
            <th colSpan={footerOffset}></th>
            <th><AxoLocal entity='InvoiceInvoicetotal'defaultValue={'Total'}/></th>
            <th>{NumberService.formatDecimal(subTotal)}</th>
          </tr>
        </tfoot> */}
      </Table>
    );
  };

  onTimeEntryPropertyChange = (index, propertyName, value) => {
    this.setState((prevState) => ({
      invoice: {
        ...prevState.invoice,
        timeEntries: prevState.invoice.timeEntries.map((t) => {
          if (t !== prevState.invoice.timeEntries[index]) {
            return t;
          }
          return {
            ...t,
            [propertyName]: value,
          };
        }),
      },
    }));
  };

  onSelectProductForEntry = (selectedProduct, entryIndex) => {
    if (selectedProduct.value === 0) {
      this.setState({ addingProductForEntryIndex: entryIndex });
      return;
    }

    let { products } = this.props;
    let product = products.find((p) => p.id === selectedProduct.value);
    if (!product) {
      return;
    }

    this.setProductForEntry(product, entryIndex);
  };

  setProductForEntry = (product, entryIndex) => {
    this.setState((prevState) => ({
      invoice: {
        ...prevState.invoice,
        timeEntries: prevState.invoice.timeEntries.map((t, index) => {
          if (index !== entryIndex) {
            return t;
          }
          return {
            ...t,
            description: product.name,
            details: product.description,
            productId: product.id,
            units: product.units,
            unitType: product.unitType,
            rate: product.price,
            tax: product.vat / 100,
          };
        }),
      },
    }));
  };

  renderTimeEntryRows = () => {
    let { invoice } = this.state;
    // var hasUnits = this.hasUnits();
    let hasDiscounts = this.hasDiscounts();

    let readOnly = this.isReadOnly();

    if (readOnly) {
      return invoice.timeEntries.map((timeEntry, index) => {
        let total = this.getEntryPriceWithoutVat(timeEntry);

        return (
          <tr key={timeEntry.id || index} className="axoinvoicebanne">
            <td>{index + 1}</td>
            <td>
              {timeEntry.description}
              {!!timeEntry.details && (
                <div className="smallText">{timeEntry.details}</div>
              )}
            </td>
            {
              <td className="text-right">
                {timeEntry.units > 0 ? <span>{timeEntry.units}</span> : ""}
              </td>
            }
            <td className="text-right">
              {TimeEntryService.getUnitTypeFullName(timeEntry.unitType)}
            </td>
            {
              <td className="text-right">
                {timeEntry.units > 0 ? (
                  <span>{NumberService.formatDecimal(timeEntry.rate)}</span>
                ) : (
                  ""
                )}
              </td>
            }
            {hasDiscounts && (
              <td className="text-right">
                {!!timeEntry.discountPercentage ? (
                  <span>
                    {NumberService.formatDecimal(timeEntry.discountPercentage)}%
                  </span>
                ) : (
                  ""
                )}
              </td>
            )}
            <td className="text-right">{NumberService.formatDecimal(total)}</td>
          </tr>
        );
      });
    }

    let { products } = this.props;
    let productOptions = 
      [
        {
          value: 0,
          label: (
            <span>
              <Icon glyph="icon-fontello-plus-1" />
              &nbsp;{" "}
              <AxoLocal entity="axoidcode245" defaultValue={"Tilføj produkt"} />
            </span>
          ),
        },
      ].concat(products
      .map((p) => ({ value: p.id, label: p.name, description: p.description })));

    let hourLabel = getText("unitHoursFullName", "Timer");
    let unitLabel = getText("unitUnitsFullName", "Antal");

    return invoice.timeEntries.map((timeEntry, index) => {
      let total = this.getEntryPriceWithoutVat(timeEntry);

      let selectedProduct = productOptions.find(
        (p) => p.value === timeEntry.productId
      ) || {
        value: 0,
        label: (
          <span>
            <AxoLocal
              entity="axoEntityidcode89"
              defaultValue={"Vælg produkt"}
            />
          </span>
        ),
      };

      return (
        <tr key={timeEntry.id || index} className="axoinvoicebanne">
          <td>{index + 1}</td>
          <td>
            <div style={{ maxWidth: "350px" }}>
              <ReactSelect
                name="productSelect"
                menuPlacement="auto"
                value={selectedProduct}
                onChange={(selection) => {
                  if (!selection) {
                    return;
                  }
                  this.onSelectProductForEntry(selection, index);
                }}
                noOptionsMessage={() => ""}
                options={productOptions}
              />
              {!!selectedProduct.value && (
                <div>
                  <InlineTextArea
                    value={timeEntry.details || ""}
                    change={(input) => {
                      this.onTimeEntryPropertyChange(
                        index,
                        "details",
                        input.value
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </td>
          <td className="text-right">
            <InlineNumberEdit
              decimal
              editStyle={{ maxWidth: "100px" }}
              value={timeEntry.units || 0}
              change={(input) =>
                this.onTimeEntryPropertyChange(index, "units", input.value)
              }
            />
            &nbsp;
          </td>
          <td>
            <FormControl
              componentClass="select"
              value={timeEntry.unitType}
              onChange={(event) =>
                this.onTimeEntryPropertyChange(
                  index,
                  "unitType",
                  event.target.value
                )
              }
            >
              <option value="Hours">{hourLabel}</option>
              <option value="Units">{unitLabel}</option>
            </FormControl>
          </td>
          <td className="text-right">
            <InlineNumberEdit
              decimal
              editStyle={{ maxWidth: "100px" }}
              value={timeEntry.rate || 0}
              change={(input) =>
                this.onTimeEntryPropertyChange(index, "rate", input.value)
              }
            />
          </td>
          <td className="text-right">
            <InlineNumberEdit
              decimal
              editStyle={{ maxWidth: "100px" }}
              value={timeEntry.discountPercentage || 0}
              change={(input) =>
                this.onTimeEntryPropertyChange(
                  index,
                  "discountPercentage",
                  parseFloat(input.value)
                )
              }
            />
            %
          </td>
          <td className="text-right">
            <Flexbox>
              <FlexElement className="text-right rightPadding" flexGrow={1}>
                {NumberService.formatDecimal(total)}
              </FlexElement>
              <FlexElement className="text-right">
                <Icon
                  glyph="icon-fontello-trash-1"
                  role="button"
                  onClick={() => this.onRemoveTimeEntry(index)}
                />
              </FlexElement>
            </Flexbox>
          </td>
        </tr>
      );
    });
  };

  getTotal = (timeEntries) => {
    return NumberService.formatDecimal(
      timeEntries.reduce((acc, timeEntry) => {
        return acc + TimeEntryService.getTotalValue(timeEntry);
      }, 0)
    );
  };

  getClient = (timeEntries) => {
    if (timeEntries.length === 0) {
      return {};
    }
    return timeEntries[0].client;
  };

  onPropertyChangeDate = (propertyName, event) => {
    var invoice = Object.assign({}, this.state.invoice);
    invoice[propertyName] = event.target.valueAsDate;
    // invoice.sendReminder = true;
    invoice.reminderSent = false;
    this.setState({ invoice: invoice });
    if (this.props.invoice) {
      DataActions.updateInvoice(invoice);
    }
  };

  onPropertyChangeInline = (propertyName, input) => {
    this.onPropertyValueChange(propertyName, input.value);
    // if(this.props.invoice) {
    //   DataActions.updateInvoice(invoice);
    // }
  };

  onPropertyValueChange = (propertyName, value) => {
    this.setState({
      invoice: {
        ...this.state.invoice,
        [propertyName]: value,
      },
    });
  };

  onUserProfilePropertyChange = async (propertyName, value) => {
    let { userProfile } = this.state.invoice;

    let newProfile = {
      ...userProfile,
      [propertyName]: value,
    };

    let response = await DataActions.updateUserProfile(newProfile);
    if (!response.ok) {
      return;
    }

    this.setState((prevState) => ({
      invoice: {
        ...prevState.invoice,
        userProfile: newProfile,
      },
    }));
  };

  onChangeSendReminder = (event) => {
    var invoice = Object.assign({}, this.state.invoice);
    invoice.sendReminder = event.target.checked;
    invoice.reminderSent = false;
    this.setState({ invoice: invoice });
    if (this.props.invoice) {
      DataActions.updateInvoice(invoice);
    }
  };

  isReadOnly = () => {
    return !!this.props.readOnly || this.state.invoice.status !== "Created";
  };

  isSubscription = () => {
    return this.state.invoice.isSubscription;
  };

  isCreditNote = () => {
    return !!this.state.invoice.invoiceCreditedId;
  };

  renderInvoiceTypeField = (invoice) => {
    if (this.isReadOnly()) {
      return invoice.invoiceType;
    }
    return (
      <InlineEdit
        value={invoice.invoiceType || "---"}
        change={this.onPropertyChangeInline.bind(this, "invoiceType")}
      />
    );
  };

  renderNumberField = (invoice) => {
    if (this.isReadOnly()) {
      return invoice.number.toString();
    }
    return (
      <InlineNumberEdit
        value={invoice.number || 0}
        change={(input) => {
          let { invoice } = this.state;
          let number = input.value;
          let newInvoice = {
            ...invoice,
            number,
            message:
              invoice.paymentType !== "Paid"
                ? this.getInvoiceMessage(invoice.userProfile.accountNo, number)
                : invoice.message,
          };
          this.setState({
            invoice: newInvoice,
          });
          if (this.props.invoice) {
            DataActions.updateInvoice(invoice);
          }
        }}
      />
    );
  };

  renderMessageField = (invoice) => {
    if (this.isReadOnly()) {
      return (
        <div style={{ whiteSpace: "pre-wrap", fontSize: "12px" }}>
          {invoice.message}
        </div>
      );
    }
    return (
      <InlineTextArea
        value={invoice.message || "---"}
        change={this.onPropertyChangeInline.bind(this, "message")}
      />
    );
  };

  renderCreationDateField = (invoice) => {
    if (this.isReadOnly()) {
      return moment(invoice.creationDate).format("L");
    }
    return (
      <input
        type="date"
        style={{ border: "none", padding: "0px 0px 0px 0px" }}
        onChange={this.onPropertyChangeDate.bind(this, "creationDate")}
        value={moment(this.state.invoice.creationDate).format("YYYY-MM-DD")}
      />
    );
  };

  renderDueDateField = (invoice) => {
    if (this.isSubscription()) {
      return null;
    }

    let label = (
      <AxoLocal
        entity="invoiPaymentatthelatest"
        defaultValue={"Forfaldsdato"}
      />
    );
    if (this.isReadOnly()) {
      return (
        <>
          {label}: {moment(invoice.dueDate).format("L")}
        </>
      );
    }
    return (
      <>
        {label}:&nbsp;
        <input
          style={{ border: "none", padding: "0px 0px 0px 0px" }}
          type="date"
          onChange={this.onPropertyChangeDate.bind(this, "dueDate")}
          value={moment(this.state.invoice.dueDate).format("YYYY-MM-DD")}
        />
      </>
    );
  };

  renderGenerateButtonContent = () => {
    let { invoice } = this.props;
    let hasPDF = !!invoice.files && invoice.files.length > 0;

    if (this.props.isGeneratingPDF) {
      return (
        <span>
          <AxoLocal
            entity="DocumentTemplateGeneraisGenerating"
            defaultValue={"Genererer..."}
          />{" "}
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </span>
      );
    } else {
      return (
        <span>
          <Icon glyph="icon-fontello-acrobat" style={{ fontSize: "17px" }} />
          &nbsp;&nbsp;
          {hasPDF ? (
            <>
              <AxoLocal
                entity="axoEntityidcode90"
                defaultValue={"Generer ny PDF"}
              />
            </>
          ) : (
            <>
              <AxoLocal
                entity="DocumentTemplateEditView"
                defaultValue={"Generer til PDF"}
              />
            </>
          )}
          &nbsp;
          {this.props.generatedPDF ? <Icon glyph="icon-fontello-ok-3" /> : ""}
        </span>
      );
    }
  };

  renderGenerateAndSendButtonContent = () => {
    if (this.props.isGeneratingAndSending) {
      return (
        <span>
          <AxoLocal
            entity="DocumentTemplateGeneraisGenerating"
            defaultValue={"Genererer..."}
          />
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </span>
      );
    } else {
      return (
        <span>
          <Icon glyph="icon-fontello-mail-1" style={{ fontSize: "17px" }} />
          &nbsp;&nbsp;
          <AxoLocal
            entity="DocumentGenerateandsendtoclient"
            defaultValue={"Generer og send  PDF"}
          />
        </span>
      );
    }
  };

  onShareWithClient = () => {
    var invoice = this.props.invoice;
    invoice.sharedWithClient = true;
    invoice.status = "Sent";
    DataActions.updateInvoice(invoice)
      .then((response) => {
        if (response.ok) {
          this.setState({ showSharedMessage: true });
          setTimeout(() => {
            this.setState({ showSharedMessage: false });
          }, 5000);
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="InvoiceInvoiceresponse"
              defaultValue={
                "Fakturaen kunne ikke gemmes. Tjek at der er forbindelse til serveren."
              }
            />
          );
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  renderShareButton = () => {
    var invoice = this.props.invoice;
    if (!invoice.client || !invoice.client.clientUserName) {
      return null;
    }
    if (invoice.sharedWithClient) {
      return (
        <Button disabled className="Lex-button-2">
          <Icon glyph="icon-fontello-mail-1" style={{ fontSize: "17px" }} />
          &nbsp;&nbsp;
          <AxoLocal
            entity="InvoiceSentToClienta1"
            defaultValue={"Sendt til klientselvbetjening"}
          />
        </Button>
      );
    }
    return (
      <Button className="Lex-button-2" onClick={this.onShareWithClient}>
        <Icon glyph="icon-fontello-mail-1" style={{ fontSize: "17px" }} />
        &nbsp;&nbsp;
        <AxoLocal
          entity="InvoiceSentToClienta2"
          defaultValue={"Send til klientselvbetjening"}
        />
      </Button>
    );
  };

  onCreateCreditNote = async () => {
    let { invoice } = this.state;
    let { id, number, creationDate, paymentDate, timeEntries, ...creditNote } =
      invoice;

    let numberInfo = await ApiService.getNextInvoiceNumber();

    creditNote.creationDate = moment().format();
    creditNote.timeEntries = timeEntries.map((t) => {
      let { id, invoiceId, ...newEntry } = t;
      return newEntry;
    });
    creditNote.paymentDate = creditNote.creationDate;
    creditNote.invoiceType = `${getText("axoidcode246", "Kreditnota")} #`;
    creditNote.number = numberInfo.next;
    creditNote.invoiceCreditedId = invoice.id;
    creditNote.invoiceCredited = invoice;
    creditNote.status = "Created";
    creditNote.message = "";

    return this.props.onCreate(creditNote);
  };

  getDefaultSubject = (invoice) => {
    return !this.isCreditNote()
      ? getText("axoidcode43", "Faktura til betaling") +
          (!invoice.isSubscription ? " " + invoice.number : "")
      : getText("axoidcode246", "Kreditnota") +
          " " +
          invoice.invoiceCredited.invoiceType +
          " " +
          invoice.invoiceCredited.number;
  };

  getDefaultMessage = () => {
    return !this.isCreditNote()
      ? getText(
          "axoidcode44",
          "Du har modtaget en faktura. Klik her for at betale:"
        ) + " <PAYMENTLINK>"
      : getText(
          "axoidcode248",
          "Du har modtaget en kreditnota. Klik her for at se den"
        ) + "<PAYMENTLINK>";
  };

  ApprovedButtons = (props) => {
    let { clientPlan, fiscalYears, postingGenerationProgress } = this.props;
    let { invoice, isFetchingHTML } = props;

    if (isFetchingHTML) {
      return null;
    }

    let client = invoice.client || {};

    let { userProfile } = invoice;

    let { sendingStatus } = this.state;

    let defaultSubject = this.getDefaultSubject(invoice);

    let defaultMessage = this.getDefaultMessage();

    // const [sendingStatus, setSendingStatus] = useState(SendingStatus.NONE);
    const [senderMail, setSenderMail] = useState(userProfile.eMail);
    const [sendCopyToSender, setSendCopyToSender] = useState(false);
    const [receiverMail, setReceiverMail] = useState(client.eMail || "");
    const [subject, setSubject] = useState(defaultSubject);
    const [messageBody, setMessageBody] = useState(defaultMessage);
    const [confirmRegisterPayment, setConfirmRegisterPayment] = useState(false);
    const [selectedPaymentDate, setSelectedPaymentDate] = useState(
      moment.utc().format()
    );

    const onSendToClient = () => {
      this.setState({
        sendingStatus: SendingStatus.CONFIRM,
      });
      // setSendingStatus(SendingStatus.CONFIRM);
    };

    const doSendToClient = () => {
      ModalService.openConfirmModal(
        `Send faktura til ${receiverMail}?`,
        (value) => {
          if (!value) {
            return;
          }
          this.setState({
            sendingStatus: SendingStatus.SENDING,
          });
          // setSendingStatus(SendingStatus.SENDING);
          return ApiService.sendInvoiceToClients({
            clientIds: [client.id],
            invoiceId: invoice.id,
            showFullInvoice: true,
            resetPaymentRequest: invoice.status !== "Paid", //If the invoice is not marked as paid, reset the payment request
            messageSenderEmail: senderMail,
            sendCopyToSender,
            receiverEmail: receiverMail,
            messageSubject: subject,
            messageText: messageBody,
            // messageSubject: 'Medlemskontingent for Vinterbadelauget Bifrost',
            // messageText: 'Det er tid til at betale medlemskontingent for Vinterbadelauget Bifrost. Klik her for at betale:',
            messageUrl:
              window.location.protocol +
              "//" +
              window.location.host +
              "/InvoicePayment",
          })
            .then(() => {
              onEmailSuccess();
              return true;
            })
            .catch((reason) => {
              console.log(reason);
              return false;
            });
        }
      );
    };

    const onEmailSuccess = () => {
      DataActions.updateInvoice({
        ...this.props.invoice,
        status: "Sent",
      });

      this.setState({
        sendingStatus: SendingStatus.SENT,
      });
      // setSendingStatus(SendingStatus.SENT);
      setTimeout(() => {
        this.setState({
          sendingStatus: SendingStatus.NONE,
        });
        // setSendingStatus(SendingStatus.NONE);
      }, 3000);
    };

    let EmailSettingsForm = this.EmailSettingsForm;
    if (sendingStatus > SendingStatus.NONE) {
      return (
        <EmailSettingsForm
          {...{
            senderMail,
            setSenderMail,
            receiverMail,
            setReceiverMail,
            subject,
            setSubject,
            messageBody,
            setMessageBody,
            sendCopyToSender,
            setSendCopyToSender,
            doSendToClient,
          }}
        />
      );
    }

    let now = moment.utc();
    let validFiscalYear =
      !!fiscalYears &&
      !!fiscalYears.find(
        (f) =>
          moment.utc(f.startDate).isSameOrBefore(now) &&
          moment.utc(f.endDate).isSameOrAfter(now)
      );

    return (
      <div>
        {confirmRegisterPayment && (
          <Flexbox className="bottomPadding">
            <FlexElement className="rightPadding">
              <AxoDateTime
                value={moment.utc(selectedPaymentDate)}
                dateFormat={true}
                timeFormat={false}
                onChange={(selection) => {
                  setSelectedPaymentDate(selection.format());
                }}
              />
            </FlexElement>
            <FlexElement className="rightPadding">
              <AsyncButton
                onClick={async () => {
                  let result = await this.doRegisterPayment(
                    selectedPaymentDate
                  );
                  if (!result) {
                    return false;
                  }
                  setConfirmRegisterPayment(false);
                  return true;
                }}
              >
                <AxoLocal
                  entity="friendRequestTimelineonApproveRequest"
                  defaultValue={"Godkend"}
                />
              </AsyncButton>
            </FlexElement>
            <FlexElement className="rightPadding">
              <LexButton onClick={() => setConfirmRegisterPayment(false)}>
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </LexButton>
            </FlexElement>
          </Flexbox>
        )}
        <ButtonToolbar className="hidden-print">
          {!!invoice.files && invoice.files.length > 0 && (
            <LexButton
              onClick={() => this.fileModal.current.open(invoice.files)}
            >
              <Icon
                glyph="icon-fontello-acrobat"
                style={{ fontSize: "17px" }}
              />
              &nbsp;
              <AxoLocal entity="axoEntityidcode99" defaultValue={"Vis PDF"} />
            </LexButton>
          )}
          <LexButton
            disabled={this.props.isGeneratingPDF}
            onClick={this.props.onGeneratePdf}
          >
            {this.renderGenerateButtonContent()}
          </LexButton>
          {!!client.id && (
            <LexButton
              disabled={sendingStatus > SendingStatus.NONE}
              onClick={onSendToClient}
            >
              <Icon glyph="icon-fontello-mail-1" style={{ fontSize: "17px" }} />
              &nbsp;&nbsp;
              <AxoLocal
                entity="ContactTableViewclients1ab"
                defaultValue={"Send til klient email"}
              />
              {sendingStatus === SendingStatus.SENDING ? (
                <span>
                  &nbsp;
                  <img
                    alt=""
                    src="/imgs/app/loading.gif"
                    className="img-circle"
                    width="20"
                    height="20"
                  />
                </span>
              ) : null}
              {sendingStatus === SendingStatus.SENT ? (
                <span>
                  &nbsp;
                  <Icon glyph="icon-fontello-check-1" />
                </span>
              ) : null}
              {/* <AxoLocal entity='InvoiceSendToClient' defaultValue={'Send til klient'}/> */}
            </LexButton>
          )}
          {!!clientPlan.id && (
            <>
              {invoice.status !== "Paid" && (
                <>
                  {!invoice.postings || invoice.postings.length === 0 ? (
                    <LexButton
                      key="post"
                      disabled={
                        !validFiscalYear ||
                        postingGenerationProgress >
                          PostingGenerationProgress.NONE
                      }
                      onClick={this.onGeneratePostings}
                    >
                      <AxoLocal
                        entity="axoidcode239"
                        defaultValue={"Bogfør faktura"}
                      />
                      <LoadingIcon
                        show={
                          postingGenerationProgress >
                            PostingGenerationProgress.NONE &&
                          postingGenerationProgress <
                            PostingGenerationProgress.ERROR
                        }
                      />
                    </LexButton>
                  ) : (
                    <>
                      <LexButton
                        key="pay"
                        disabled={!validFiscalYear || confirmRegisterPayment}
                        onClick={() => setConfirmRegisterPayment(true)}
                      >
                        <AxoLocal
                          entity="axoidcode238"
                          defaultValue={"Registrer betaling"}
                        />
                      </LexButton>
                    </>
                  )}
                  {!this.isCreditNote() && (
                    <AsyncButton onClick={this.onCreateCreditNote}>
                      <AxoLocal
                        entity="axoidcode237"
                        defaultValue={"Opret kreditnota"}
                      />
                    </AsyncButton>
                  )}
                </>
              )}
              {invoice.status === "Paid" && (
                <LexButton disabled>
                  <AxoLocal
                    entity="InvoiceInvoicePaid"
                    defaultValue={"Betalt"}
                  />
                </LexButton>
              )}
            </>
          )}
        </ButtonToolbar>
      </div>
    );
  };

  EmailSettingsForm = (props) => {
    let {
      senderMail,
      setSenderMail,
      receiverMail,
      setReceiverMail,
      subject,
      setSubject,
      messageBody,
      setMessageBody,
      sendCopyToSender,
      setSendCopyToSender,
      doSendToClient,
    } = props;

    let { sendingStatus } = this.state;

    return (
      <div>
        <Flexbox responsive>
          <FormGroup controlId="sender" className="rightPadding">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="axoidcode256" defaultValue={"Afsender email"} />
            </ControlLabel>
            <FormControl
              type="email"
              className="borderFormControlfocus"
              value={senderMail}
              onChange={(event) => setSenderMail(event.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="receiver" className="rightPadding">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="axoidcode257" defaultValue={"Modtager email"} />
            </ControlLabel>
            <FormControl
              type="email"
              className="borderFormControlfocus"
              value={receiverMail}
              onChange={(event) => setReceiverMail(event.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="subject" className="rightPadding">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="CaseEditFormcasesubject"
                defaultValue={"Emne"}
              />
            </ControlLabel>
            <FormControl
              type="text"
              className="borderFormControlfocus"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
            />
          </FormGroup>
        </Flexbox>
        <FormGroup controlId="body">
          <FormControl
            componentClass="textarea"
            className="borderFormControlfocus"
            value={messageBody}
            onChange={(event) => setMessageBody(event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <AxoCheckbox
            checked={sendCopyToSender}
            onChange={(event) => setSendCopyToSender(event.target.checked)}
          />
          &nbsp;
          <AxoLocal
            entity="axoidcode227"
            defaultValue={"Send kopi til afsender"}
          />
        </FormGroup>
        {!!doSendToClient && (
          <ButtonToolbar>
            <LexButton
              onClick={() =>
                this.setState({
                  sendingStatus: SendingStatus.NONE,
                })
              }
            >
              <AxoLocal
                entity="axoEntityidcode91"
                defaultValue={"Annuller afsending"}
              />
            </LexButton>
            <LexButton
              disabled={sendingStatus === SendingStatus.SENDING}
              onClick={doSendToClient}
            >
              <AxoLocal
                entity="currentMessageisSendButtonContent"
                defaultValue="Send"
              />
              <LoadingIcon show={sendingStatus === SendingStatus.SENDING} />
            </LexButton>
          </ButtonToolbar>
        )}
      </div>
    );
  };

  onSave = () => {
    return this.props.onSave(this.state.invoice);
  };

  onApprove = async () => {
    let { invoice } = this.state;
    let result = await this.props.onApprove(invoice);
    if (!result) {
      return false;
    }

    this.setState({
      invoice: {
        ...invoice,
        status: "Approved",
      },
      sendingStatus: SendingStatus.CONFIRM,
    });

    return true;
  };

  onGeneratePostings = async () => {
    let { invoice } = this.state;
    await this.props.onGeneratePostings(invoice);
  };

  onRegisterPayment = async () => {
    this.setState({ confirmRegisterPayment: true });
    // let { invoice } = this.state;

    // let response = await ApiService.generateInvoicePaymentPosting(invoice.id, moment.utc().format());
    // if(!response.ok) {
    //   return false;
    // }

    // let newInvoice = {
    //   ...invoice,
    //   status: 'Paid'
    // };

    // //Update front-end data, since back-end updating has been completed above.
    // DataStore.updateArrayObject(newInvoice, 'invoices');
    // this.setState({ invoice: newInvoice });

    // return true;
  };

  doRegisterPayment = async (selectedPaymentDate) => {
    let { invoice } = this.state;

    let response = await ApiService.generateInvoicePaymentPosting(
      invoice.id,
      selectedPaymentDate
    );
    if (!response.ok) {
      return false;
    }

    let newInvoice = {
      ...invoice,
      status: "Paid",
    };

    //Update front-end data, since back-end updating has been completed above.
    DataStore.updateArrayObject(newInvoice, "invoices");
    this.setState({ invoice: newInvoice });

    return true;
  };

  renderButtons = () => {
    var {
      renderButtons,
      renderPaymentLinkButton,
      isFetchingHTML,
      postingGenerationProgress,
    } = this.props;

    var { invoice } = this.state;

    if (!renderButtons) {
      return null;
    }

    let EmailSettingsForm = this.EmailSettingsForm;
    if (invoice.isSubscription) {
      return (
        <div>
          {invoice.subscriptionAction === "ApproveAndSend" && (
            <EmailSettingsForm
              senderMail={invoice.subscriptionSenderMail}
              setSenderMail={(value) =>
                this.onPropertyValueChange("subscriptionSenderMail", value)
              }
              receiverMail={invoice.subscriptionReceiverMail}
              setReceiverMail={(value) =>
                this.onPropertyValueChange("subscriptionReceiverMail", value)
              }
              subject={invoice.subscriptionMailSubject}
              setSubject={(value) =>
                this.onPropertyValueChange("subscriptionMailSubject", value)
              }
              messageBody={invoice.subscriptionMailBody}
              setMessageBody={(value) =>
                this.onPropertyValueChange("subscriptionMailBody", value)
              }
              sendCopyToSender={invoice.subscriptionSendCopy}
              setSendCopyToSender={(value) =>
                this.onPropertyValueChange("subscriptionSendCopy", value)
              }
            />
          )}
          <ButtonToolbar>
            <AsyncButton
              disabled={
                !invoice.client ||
                invoice.timeEntries.length === 0 ||
                (invoice.subscriptionAction === "ApproveAndSend" &&
                  !invoice.subscriptionReceiverMail)
              }
              className="Lex-button-2"
              onClick={this.onSave}
            >
              <Icon glyph="icon-fontello-floppy" style={{ fontSize: "17px" }} />
              &nbsp;&nbsp;
              {!!invoice.id ? (
                <>
                  <AxoLocal
                    entity="axoEntityidcode92"
                    defaultValue={"Gem abonnement"}
                  />{" "}
                </>
              ) : (
                <>
                  <AxoLocal
                    entity="axoEntityidcode93"
                    defaultValue={"Godkend abonnement"}
                  />
                </>
              )}
            </AsyncButton>
          </ButtonToolbar>
        </div>
      );
    }

    let ApprovedButtons = this.ApprovedButtons;
    if (!!invoice.id) {
      if (invoice.status !== "Created" && renderPaymentLinkButton) {
        return (
          <>
            {postingGenerationProgress === PostingGenerationProgress.ERROR && (
              <div className="largeText axored">
                <AxoLocal
                  entity="LoginshowServerError"
                  defaultValue={"Der skete en fejl. Prøv igen senere."}
                />
              </div>
            )}
            <ApprovedButtons
              invoice={invoice}
              isFetchingHTML={isFetchingHTML}
            />
          </>
        );
      }

      return (
        <ButtonToolbar>
          <AsyncButton className="Lex-button-2" onClick={this.onSave}>
            <Icon glyph="icon-fontello-floppy" style={{ fontSize: "17px" }} />
            &nbsp;&nbsp;
            <AxoLocal
              entity="CaseEditFormonSave"
              defaultValue={"Gem ændringer"}
            />
          </AsyncButton>
          <AsyncButton className="Lex-button-2" onClick={this.onApprove}>
            <Icon glyph="icon-fontello-floppy" style={{ fontSize: "17px" }} />
            &nbsp;&nbsp;
            <AxoLocal
              entity="friendRequestTimelineonApproveRequest"
              defaultValue={"Godkend"}
            />
          </AsyncButton>
          {/* {this.renderShareButton()} */}
        </ButtonToolbar>
      );
    }
    return (
      <ButtonToolbar>
        <AsyncButton className="Lex-button-2" onClick={this.onSave}>
          <Icon glyph="icon-fontello-floppy-1" />
          &nbsp;&nbsp;
          <AxoLocal entity="axoidcode249" defaultValue={"Gem kladde"} />
        </AsyncButton>
        <AsyncButton className="Lex-button-2" onClick={this.onApprove}>
          <Icon glyph="icon-fontello-floppy" style={{ fontSize: "17px" }} />
          &nbsp;&nbsp;
          <AxoLocal
            entity="friendRequestTimelineonApproveRequest"
            defaultValue={"Godkend"}
          />
        </AsyncButton>
      </ButtonToolbar>
    );
  };

  onPayInvoice = (invoiceId) => {
    this.setState({ isGeneratingPayment: true });
    this.props
      .onPayInvoice(invoiceId)
      .then(() => {
        this.setState({
          isGeneratingPayment: false,
          showPaymentCompletedMessage: true,
        });
      })
      .catch((reason) => {
        if (reason === "closed") {
          this.setState({ isGeneratingPayment: false });
        } else {
          this.showPaymentErrorMessage();
        }
      });
  };

  onPayInvoiceWithCard = (invoiceId) => {
    this.setState({ isGeneratingPayment: true });
    this.props
      .onPayInvoiceWithCard(invoiceId)
      .then(() => {
        this.setState({
          isGeneratingPayment: false,
          showPaymentCompletedMessage: true,
        });
      })
      .catch((reason) => {
        if (reason === "closed") {
          this.setState({ isGeneratingPayment: false });
        } else {
          this.showPaymentErrorMessage();
        }
      });
  };

  showPaymentErrorMessage = () => {
    this.setState({
      isGeneratingPayment: false,
      showPaymentErrorMessage: true,
    });
    setTimeout(() => {
      this.setState({ showPaymentErrorMessage: false });
    }, 5000);
  };

  renderPaymentButtons = () => {
    let { invoice, cardInformation, paymentDate } = this.props;
    let { isGeneratingPayment } = this.state;

    // if(!this.props.cardInformation.brand) {
    //   return (
    //     <Button disabled className='Lex-button-2' style={{fontSize:'17px'}}>
    //       <Icon glyph='icon-fontello-credit-card' style={{fontSize:'17px'}}/>&nbsp; &#128179; &#x1f4b3; &nbsp; &nbsp;
    //       <AxoLocal entity='InvoiceRegisterCard' defaultValue={'Betal'}/>
    //     </Button>
    //   )
    // }
    if (invoice.status === "Paid" || !!paymentDate) {
      //Paid
      return (
        <Button disabled className="Lex-button-2 axoblue">
          <Icon glyph="icon-fontello-check-1" style={{ fontSize: "17px" }} />{" "}
          &nbsp;
          <AxoLocal
            key="paid"
            entity="InvoiceInvoicePaid"
            defaultValue={"Betalt"}
          />
          {!!paymentDate ? (
            <span>&nbsp;{moment(paymentDate).format("L")}</span>
          ) : null}
        </Button>
      );
    }

    if (!!cardInformation && !!cardInformation.brand) {
      return (
        <Button
          key="payWithCard"
          className="Lex-button-2"
          disabled={isGeneratingPayment}
          onClick={this.onPayInvoiceWithCard.bind(this, invoice.id)}
        >
          <img alt="" src={CardLogos1} width="30" height="20" />
          &nbsp;&nbsp;
          <img alt="" src={CardLogos2} width="30" height="20" />
          &nbsp;&nbsp;
          <img alt="" src={CardLogos3} width="30" height="20" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="axoAccounting50"
            defaultValue={" Betal med kort ************"}
          />
          {cardInformation.last4}
          <Conditional inline show={isGeneratingPayment}>
            &nbsp;
            <img
              alt=""
              src={CardLogos2}
              className="img-circle"
              width="20"
              height="20"
            />
          </Conditional>
        </Button>
      );
    }
    return (
      <Button
        key="pay"
        className="Lex-button-2"
        disabled={isGeneratingPayment}
        onClick={this.onPayInvoice.bind(this, invoice.id)}
      >
        <img alt="" src={CardLogos1} width="20" height="20" />
        &nbsp;&nbsp;
        <img alt="" src={CardLogos2} width="20" height="20" />
        &nbsp;&nbsp;
        <img alt="" src={CardLogos3} width="20" height="20" />
        &nbsp;&nbsp;
        <AxoLocal
          key="payment"
          entity="InvoicePayInvoice"
          defaultValue={"Betal faktura"}
        />
        <Conditional inline show={isGeneratingPayment}>
          &nbsp;
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </Conditional>
      </Button>
    );
  };

  renderClientButtons = () => {
    let { renderClientButtons, onClose, isGeneratingPDF, onGeneratePdf } =
      this.props;

    if (!renderClientButtons) {
      return null;
    }
    return (
      <ButtonGroup>
        {!!onClose ? (
          <Button className="Lex-button-2" onClick={onClose}>
            {" "}
            <span>
              {" "}
              <Icon glyph="icon-fontello-cancel" style={{ fontSize: "17px" }} />
              &nbsp;&nbsp;
              <AxoLocal
                entity="DocumentCloseinvoice"
                defaultValue={"Luk faktura"}
              />
            </span>
          </Button>
        ) : null}
        {this.renderPaymentButtons()}
        {!!onGeneratePdf ? (
          <Button
            className="Lex-button-2"
            disabled={isGeneratingPDF}
            onClick={onGeneratePdf}
          >
            {this.renderGenerateButtonContent()}
          </Button>
        ) : null}
      </ButtonGroup>
    );
  };

  ClientField = React.memo(({ client, propertyName }) => {
    const updateClient = async (input) => {
      this.updateClientProperty(propertyName, input.value);
    };

    if (!client || !client.id) {
      let { invoice } = this.state;
      //Overrides are currently only used by subscription invoice generated for the Axolex system, since system users do not correspond to clients
      if (propertyName === "firstName" && !!invoice.clientNameOverride) {
        return (
          <span style={{ whiteSpace: "pre-wrap" }}>
            {invoice.clientNameOverride}
          </span>
        );
      }
      if (propertyName === "address" && !!invoice.clientAddressOverride) {
        return (
          <span style={{ whiteSpace: "pre-wrap" }}>
            {invoice.clientAddressOverride}
          </span>
        );
      }

      return null;
    }
    if (this.isReadOnly()) {
      return <span>{client[propertyName]}</span>;
    }

    return (
      <InlineEdit value={client[propertyName] || ""} change={updateClient} />
    );
  });

  updateClientProperty = async (propertyName, value) => {
    let { updateContact } = this.props;

    let newClient = { ...this.state.invoice.client, [propertyName]: value };

    await updateContact(newClient).unwrap();

    this.setState((prevState) => ({
      invoice: {
        ...prevState.invoice,
        client: newClient,
      },
    }));
  };

  getEntryPriceWithoutVat = (entry) => {
    return (
      entry.units * entry.rate * (1 - (entry.discountPercentage || 0.0) / 100)
    );
  };

  calculateInvoiceSubTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + this.getEntryPriceWithoutVat(timeEntry);
    }, 0);
  };

  calculateInvoiceSubTotalTaxable = (invoice) => {
    return invoice.timeEntries
      .filter((t) => !!t.tax)
      .reduce((sum, timeEntry) => {
        return sum + this.getEntryPriceWithoutVat(timeEntry);
      }, 0);
  };

  calculateInvoiceSubTotalNotTaxable = (invoice) => {
    return invoice.timeEntries
      .filter((t) => !t.tax)
      .reduce((sum, timeEntry) => {
        return sum + this.getEntryPriceWithoutVat(timeEntry);
      }, 0);
  };

  calculateExpensesSubTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.expenses;
    }, 0);
  };

  calculateTaxTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + this.getEntryPriceWithoutVat(timeEntry) * timeEntry.tax;
    }, 0);
  };

  calculateAContoTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.aConto;
    }, 0);
  };

  renderInlineEdit = (propertyName, defaultValue) => {
    var invoice = this.state.invoice;
    if (this.isReadOnly()) {
      return invoice[propertyName] || defaultValue;
    }
    return (
      <InlineEdit
        value={invoice[propertyName] || defaultValue}
        change={this.onPropertyChangeInline.bind(this, propertyName)}
      />
    );
  };

  renderInlineUserProfileEdit = (propertyName) => {
    let { userProfile } = this.state.invoice;
    if (this.isReadOnly()) {
      return userProfile[propertyName];
    }
    return (
      <InlineEdit
        value={userProfile[propertyName]}
        editStyle={{ maxWidth: "200px" }}
        change={(input) =>
          this.onUserProfilePropertyChange(propertyName, input.value)
        }
      />
    );
  };

  toggleImageRemovalButton = () => {
    if (this.isReadOnly()) {
      return;
    }

    this.setState({ showImageRemoveButton: !this.state.showImageRemoveButton });
  };

  onRemoveImage = () => {
    var invoice = Object.assign({}, this.state.invoice);
    invoice.hideLogo = true;
    DataActions.updateInvoice(invoice);
    this.setState({ invoice: invoice });
  };

  renderLogo = () => {
    var invoice = this.state.invoice;
    if (invoice.hideLogo) {
      return;
    }
    return (
      <div className="text-right" style={{ overflow: "hidden" }}>
        {this.state.showImageRemoveButton ? (
          <AxoLocal
            componentClass={Icon}
            role="button"
            onClick={this.onRemoveImage}
            className="pull-right axored"
            glyph="icon-fontello-scissors axored"
            componentAttribute="title"
            entity="RemoveBCCTitleRemovelogo"
          />
        ) : null}
        <img
          alt=""
          onClick={this.toggleImageRemovalButton}
          height="150px"
          src={UserInfoService.getLogoImageSource(invoice.userProfile)}
        />
      </div>
    );
  };

  handleSelectedClient = (selectedClient) => {
    var invoice = Object.assign({}, this.state.invoice);

    invoice.client = selectedClient;
    invoice.clientNameOverride = "";
    invoice.clientAddressOverride = "";

    if (invoice.isSubscription) {
      invoice.subscriptionReceiverMail = selectedClient.eMail;
    }

    this.setState({ invoice: invoice });
    if (this.props.invoice) {
      DataActions.updateInvoice(invoice);
    }
  };

  onSubmitNewProduct = async (newProduct) => {
    let { addingProductForEntryIndex } = this.state;
    let response = await DataActions.createProduct(newProduct);
    if (!response.ok) {
      return false;
    }
    let createdProduct = await response.json();
    this.setProductForEntry(createdProduct, addingProductForEntryIndex);
    this.setState({ addingProductForEntryIndex: null });
    return true;
  };

  onAddClient = async () => {
    let { userProfile, createContact } = this.props;

    let newClient = {
      firstName: getText("placeholderfirstName", "Fornavn"),
      lastName: getText("placeholderlastName", "Efternavn"),
      address: getText("axoEntityidcode167", "Vej"),
      postalCode: getText("SidebarRightContainerpostalCode", "Postnummer"),
      city: getText("CaseEditFormcity", "By"),
      country: userProfile.country,
      identityCode: "CVR",
    };
    try {
      let createdClient = await createContact(newClient).unwrap();
      this.setState((prevState) => ({
        invoice: {
          ...prevState.invoice,
          client: createdClient,
        },
      }));
    } catch {
      return false;
    }

    return true;
  };

  PaymentTypeField = () => {
    let { invoice } = this.state;
    let { paymentType } = invoice;
    let readOnly = this.isReadOnly();
    let dueDateField = this.renderDueDateField(invoice);

    let creation = moment(invoice.creationDate);
    let payment = !!invoice.dueDate ? moment(invoice.dueDate) : moment();
    let paymentDays = 0;

    if (readOnly) {
      switch (paymentType) {
        case "Net":
          paymentDays = payment.diff(creation, "days");
          return (
            <>
              <AxoLocal
                entity="axoidcode253"
                defaultValue={"Betaling: Netto"}
              />
              &nbsp;
              {paymentDays > 0 && (
                <>
                  {" "}
                  {paymentDays}{" "}
                  <AxoLocal entity="axoEntityidcode98" defaultValue={"dage"} />{" "}
                  {!this.isSubscription() && <> - {dueDateField}</>}
                </>
              )}
            </>
          );
        case "Month":
          paymentDays = payment.subtract(1, "month").diff(creation, "days");
          return (
            <>
              <AxoLocal
                entity="axoidcode252"
                defaultValue={"Betaling: Løbende måned"}
              />
              &nbsp;
              {paymentDays > 0 && (
                <>
                  {" "}
                  {paymentDays}{" "}
                  <AxoLocal entity="axoEntityidcode98" defaultValue={"dage"} />{" "}
                  {!this.isSubscription() && <> - {dueDateField}</>}
                </>
              )}
            </>
          );
        case "Now":
          return (
            <>
              {" "}
              <AxoLocal
                entity="axoidcode251"
                defaultValue={"Betaling: Netto kontant"}
              />{" "}
            </>
          );
        case "Paid":
          return (
            <>
              <AxoLocal
                entity="axoidcode250"
                defaultValue={"Fakturaen er betalt"}
              />
            </>
          );
        default:
          return;
      }
    }

    let typeOptions = [
      { value: "Net", label: getText("axoEntityidcode168", "Netto") },
      { value: "Month", label: getText("axoEntityidcode169", "Løbende måned") },
      { value: "Now", label: getText("axoEntityidcode170", "Netto kontant") },
      { value: "Paid", label: getText("axoidcode250", "Fakturaen er betalt") },
    ];

    let currentOption = typeOptions.find((o) => o.value === paymentType);
    let optionSelect = (
      <ReactSelect
        name="typeSelect"
        menuPlacement="auto"
        value={currentOption}
        onChange={(selection) => {
          if (!selection) {
            return;
          }
          let type = selection.value;
          let dueDate = invoice.dueDate;
          if (type === "Net") {
            dueDate = moment(invoice.creationDate).add(8, "days").format();
          }
          if (type === "Month") {
            dueDate = moment(invoice.creationDate)
              .add(1, "months")
              .add(8, "days")
              .format();
          }
          let defaultMessage = this.getInvoiceMessage(
            this.props.userProfile.accountNo,
            invoice.number
          );
          let message = invoice.message;
          if (type === "Paid" && message === defaultMessage) {
            message = "";
          } else if (!message) {
            message = defaultMessage;
          }

          this.setState((prevState) => ({
            invoice: {
              ...prevState.invoice,
              paymentType: type,
              message,
              dueDate,
            },
          }));
        }}
        noOptionsMessage={() => ""}
        options={typeOptions}
      />
    );

    const onChangeDays = (days) => {
      if (this.isSubscription()) {
        this.setState({
          invoice: {
            ...invoice,
            paymentDays: days,
          },
        });

        return;
      }

      let dueDate = "";
      if (paymentType === "Month") {
        dueDate = moment(invoice.creationDate)
          .add(1, "months")
          .add(days, "days")
          .format();
      } else {
        dueDate = moment(invoice.creationDate).add(days, "days").format();
      }
      this.setState({
        invoice: {
          ...invoice,
          dueDate,
        },
      });
    };

    switch (paymentType) {
      case "Net":
      case "Month":
        let paymentDays = 8;
        if (this.isSubscription()) {
          paymentDays = invoice.paymentDays;
        } else if (paymentType === "Month") {
          paymentDays = payment.subtract(1, "month").diff(creation, "days");
        } else {
          paymentDays = payment.diff(creation, "days");
        }
        return (
          <Flexbox>
            <FlexElement className="rightPadding">
              <AxoLocal entity="axoAccounting43" defaultValue={"Betaling"} />:
            </FlexElement>
            <FlexElement flexBasis="200px" className="rightPadding">
              {optionSelect}
            </FlexElement>
            <FlexElement className="rightPadding">
              <FormControl
                type="number"
                style={{ maxWidth: "75px" }}
                value={isNaN(paymentDays) ? "" : paymentDays}
                onChange={(event) => onChangeDays(event.target.valueAsNumber)}
              />
            </FlexElement>
            <FlexElement>
              <AxoLocal entity="AdminTabView32" defaultValue="dage" />
              &nbsp; {!this.isSubscription() && <> - {dueDateField}</>}
            </FlexElement>
          </Flexbox>
        );
      default:
        return (
          <Flexbox>
            <FlexElement className="rightPadding">
              <AxoLocal entity="axoAccounting43" defaultValue={"Betaling"} />:
            </FlexElement>
            <FlexElement flexBasis="200px" className="rightPadding">
              {optionSelect}
            </FlexElement>
          </Flexbox>
        );
    }
  };

  intervalOptions = [
    { value: "Day", label: getText("axoEntityidcode76", "Daglig") },
    { value: "Week", label: getText("axoEntityidcode77", "Ugentlig") },
    { value: "Month", label: getText("axoEntityidcode78", "Månedlig") },
    { value: "Year", label: getText("axoEntityidcode79", "Årlig") },
  ];

  actionOptions = [
    { value: "Draft", label: getText("CaseEditInlineprioritydraft", "Kladde") },
    {
      value: "Approve",
      label: getText("friendRequestTimelineonApproveRequest", "Godkend"),
    },
    {
      value: "ApproveAndSend",
      label: getText("axoEntityidcode81", "Godkend og send"),
    },
  ];

  render() {
    let {
      contactMap,
      cardInformation,
      renderButtons,
      renderClientButtons,
      clientPlan,
      isFetchingHTML,
    } = this.props;

    let { invoice, addingProductForEntryIndex } = this.state;

    // var client = invoice.client;
    let userProfile = invoice.userProfile;
    let subTotalWithVat = this.calculateInvoiceSubTotalTaxable(invoice);
    let subTotalNoVat = this.calculateInvoiceSubTotalNotTaxable(invoice);
    let subTotal = subTotalWithVat + subTotalNoVat;
    // let subTotal = this.calculateInvoiceSubTotal(invoice);
    let taxTotal = this.calculateTaxTotal(invoice);
    let expensesTotal = this.calculateExpensesSubTotal(invoice);
    let aConto = this.calculateAContoTotal(invoice);
    // let payment = subTotal + taxTotal + expensesTotal - aConto;
    let currency = (invoice.currency || "").toUpperCase();

    let userType = AuthorizationService.getUserType(userProfile);

    let ClientField = this.ClientField;
    let PaymentTypeField = this.PaymentTypeField;
    return (
      <div style={{ margin: "0px 15px 15px 15px" }}>
        <Grid fluid style={isFetchingHTML ? { minHeight: "1000px" } : {}}>
          {this.isSubscription() && (
            <Flexbox className="topPadding">
              <FlexElement className="rightPadding">
                <AxoLocal
                  entity="axoEntityidcode94"
                  defaultValue={"Abonnement start"}
                />
                <AxoDateTime
                  value={moment.utc(invoice.creationDate)}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={(selection) => {
                    this.onPropertyValueChange(
                      "creationDate",
                      selection.format()
                    );
                  }}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <AxoLocal
                  entity="axoEntityidcode95"
                  defaultValue={"Slut (tom = aldrig)"}
                />
                <AxoDateTime
                  value={moment.utc(invoice.dueDate)}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={(selection) => {
                    this.onPropertyValueChange("dueDate", selection.format());
                  }}
                />
              </FlexElement>
              <FlexElement flexBasis="250px" className="rightPadding">
                <AxoLocal entity="AdminTabView30" defaultValue="Periode" />
                <ReactSelect
                  name="intervalSelection"
                  menuPlacement="auto"
                  value={this.intervalOptions.find(
                    (o) => o.value === invoice.subscriptionInterval
                  )}
                  onChange={(selection) => {
                    if (!selection) {
                      return;
                    }
                    this.onPropertyValueChange(
                      "subscriptionInterval",
                      selection.value
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={this.intervalOptions}
                />
              </FlexElement>
              <FlexElement flexBasis="250px" className="rightPadding">
                <AxoLocal
                  entity="axoEntityidcode80"
                  defaultValue={"Handling"}
                />
                <ReactSelect
                  name="actionSelection"
                  menuPlacement="auto"
                  value={this.actionOptions.find(
                    (o) => o.value === invoice.subscriptionAction
                  )}
                  onChange={(selection) => {
                    if (!selection) {
                      return;
                    }
                    this.onPropertyValueChange(
                      "subscriptionAction",
                      selection.value
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={this.actionOptions}
                />
              </FlexElement>
            </Flexbox>
          )}

          <Row>
            <Col xs={12}>
              <div className="hugeText text-center topPadding">
                <strong>{userProfile.company}</strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              {/* <h3 className='editable' style={{margin: 0, marginBottom: 12.5}}>
                {this.renderInvoiceTypeField(invoice)} {this.renderNumberField(invoice)}
              </h3> */}
              {!this.isReadOnly() && !!contactMap ? (
                <Flexbox>
                  <FlexElement className="rightPadding">
                    <AxoLocal
                      componentClass={ClientSearchBox}
                      clients={contactMap.contacts}
                      count={contactMap.count}
                      handleSelectedClient={this.handleSelectedClient}
                      clearOnSelect
                      disabled={false}
                      componentAttribute="placeholder"
                      entity="PrintLayoutSelectClient"
                    />
                  </FlexElement>
                  <FlexElement>
                    <AsyncButton onClick={this.onAddClient}>
                      <Icon glyph="icon-fontello-plus-1" />
                    </AsyncButton>
                  </FlexElement>
                </Flexbox>
              ) : null}
              <div style={{ marginBottom: "150px" }}></div>
              <address>
                <strong className="editable">
                  <ClientField
                    client={invoice.client}
                    propertyName="firstName"
                  />
                  &nbsp;
                  <ClientField
                    client={invoice.client}
                    propertyName="lastName"
                  />
                </strong>
                <div>
                  <ClientField
                    client={invoice.client}
                    propertyName="address"
                  />
                </div>
                <div>
                  <ClientField
                    client={invoice.client}
                    propertyName="postalCode"
                  />
                  &nbsp;
                  <ClientField
                    client={invoice.client}
                    propertyName="city"
                  />
                </div>
                <div>
                  {!!invoice.client && !!invoice.client.id && (
                    <>
                      {this.isReadOnly() ? (
                        <>
                          {!!invoice.client.country
                            ? ctx.getSync("CountryNames", {
                                countrySlug: invoice.client.country,
                              })
                            : ""}
                        </>
                      ) : (
                        <InlineCountrySelect
                          countryName={
                            !!invoice.client.country
                              ? ctx.getSync("CountryNames", {
                                  countrySlug: invoice.client.country,
                                })
                              : "---"
                          }
                          valueType="short"
                          customSelect
                          onChange={(val) =>
                            this.updateClientProperty("country", val)
                          }
                        />
                      )}
                    </>
                  )}
                </div>
                {!!invoice.client && !!invoice.client.identityCode && (
                  <div>
                    <AxoLocal
                      entity="SidebarRightContainerCompanyCode"
                      defaultValue={"CVR"}
                    />
                    :&nbsp;
                    <ClientField
                      client={invoice.client}
                      propertyName="identityCode"
                    />
                  </div>
                )}
              </address>
            </Col>
            <Col xs={6} className="text-right">
              <div>{this.renderLogo()}</div>
              {!invoice.isSubscription && (
                <div
                  className="inlineBlock text-left bottomPadding"
                  style={{ minWidth: "175px" }}
                >
                  <div className="largeText">
                    <strong>
                      {this.isCreditNote()
                        ? getText("axoidcode246", "Kreditnota")
                        : getText("axoidcode210", "Faktura")}
                    </strong>
                  </div>
                  <Flexbox>
                    <FlexElement>
                      {this.isCreditNote()
                        ? getText("axoEntityidcode177", "Nr")
                        : getText("axoEntityidcode174", "Fakturanr")}
                      &nbsp;
                    </FlexElement>
                    <FlexElement
                      style={{
                        borderBottom: "dotted 2px black",
                        marginBottom: "7px",
                      }}
                      flexGrow={1}
                    ></FlexElement>
                    <FlexElement className="text-right">
                      &nbsp;{this.renderNumberField(invoice)}
                    </FlexElement>
                  </Flexbox>
                  <Flexbox>
                    <FlexElement>
                      <AxoLocal
                        entity="TimeEntryFormntimeEntry"
                        defaultValue={"Dato"}
                      />
                      &nbsp;
                    </FlexElement>
                    <FlexElement
                      style={{
                        borderBottom: "dotted 2px black",
                        marginBottom: "7px",
                      }}
                      flexGrow={1}
                    ></FlexElement>
                    <FlexElement className="text-right">
                      &nbsp;{this.renderCreationDateField(invoice)}
                    </FlexElement>
                  </Flexbox>
                  <Flexbox>
                    <FlexElement>
                      <AxoLocal
                        entity="axoEntityidcode97"
                        defaultValue={"Side"}
                      />{" "}
                      &nbsp;
                    </FlexElement>
                    <FlexElement
                      style={{
                        borderBottom: "dotted 2px black",
                        marginBottom: "7px",
                      }}
                      flexGrow={1}
                    ></FlexElement>
                    <FlexElement className="text-right">&nbsp;1</FlexElement>
                  </Flexbox>
                </div>
              )}
            </Col>
          </Row>
          <div style={{ marginBottom: "50px" }}></div>
          {this.isCreditNote() && !!invoice.invoiceCredited && (
            <div className="largeText">
              <AxoLocal entity="axoidcode211" defaultValue={"Kreditering af"} />{" "}
              {invoice.invoiceCredited.invoiceType}{" "}
              {invoice.invoiceCredited.number}
            </div>
          )}
          <div>{this.renderTimeEntriesTable(subTotal + expensesTotal)}</div>
          {addingProductForEntryIndex !== null ? (
            <div>
              <NewProductForm
                clientPlan={clientPlan}
                onSubmit={(newProduct) => this.onSubmitNewProduct(newProduct)}
                onCancel={() =>
                  this.setState({ addingProductForEntryIndex: null })
                }
                userType={userType}
              />
            </div>
          ) : null}
          <hr style={{ marginTop: 0 }} />
          <Row>
            <Col xs={8}>
              {/* <div>
                <strong>
                  {this.renderThankYouField(invoice)}
                </strong>
              </div> */}
            </Col>
            <Col xs={4}>
              <div className="axocblueabanne text-uppercase text-centered">
                <h5 className="subheader" style={{ margin: 0, padding: 12.5 }}>
                  <AxoLocal
                    entity="invoiPaymentattheTotal"
                    defaultValue={"i alt"}
                  />{" "}
                </h5>
              </div>
              <div>
                <Table>
                  <tbody>
                    {this.hasUnits() && !invoice.reverseVAT ? (
                      <>
                        {!subTotalNoVat ? (
                          <tr>
                            <td>
                              {" "}
                              <AxoLocal
                                entity="invoiPaymentattheSubtotal"
                                defaultValue={"Subtotal"}
                              />
                            </td>
                            <td className="text-right">
                              {NumberService.formatDecimal(subTotal)}
                            </td>
                          </tr>
                        ) : (
                          <>
                            <tr>
                              <td>
                                {" "}
                                <AxoLocal
                                  entity="invoiPaymentattheSubtotal"
                                  defaultValue={"Subtotal"}
                                />
                                &nbsp;
                                <AxoLocal
                                  entity="axoidcode254"
                                  defaultValue={"momspligtig"}
                                />{" "}
                              </td>
                              <td className="text-right">
                                {" "}
                                {NumberService.formatDecimal(subTotalWithVat)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <AxoLocal
                                  entity="invoiPaymentattheSubtotal"
                                  defaultValue={"Subtotal"}
                                />
                                &nbsp;
                                <AxoLocal
                                  entity="axoidcode255"
                                  defaultValue={"uden moms"}
                                />{" "}
                              </td>
                              <td className="text-right">
                                {NumberService.formatDecimal(subTotalNoVat)}
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : null}
                    {this.hasUnits() && taxTotal > 0 ? (
                      <tr>
                        <td>
                          <AxoLocal
                            entity="invoiPaymentattheSubtotalVAT"
                            defaultValue={"Moms"}
                          />
                          (
                          {NumberService.formatDecimal(
                            (taxTotal * 100) / subTotalWithVat
                          )}
                          %)
                        </td>
                        <td className="text-right">
                          {NumberService.formatDecimal(taxTotal)}
                        </td>
                      </tr>
                    ) : null}
                    {taxTotal === 0 && invoice.reverseVAT ? (
                      <tr>
                        <td>
                          <AxoLocal
                            entity="invoiPaymentattheSubtotalVAT"
                            defaultValue={"Moms"}
                          />
                        </td>
                        <td>
                          <AxoLocal
                            entity="axoAccounting67"
                            defaultValue={"Omvendt betalingspligt"}
                          />
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>
                        <AxoLocal
                          entity="InvoiceInvoicetotal"
                          defaultValue={"Total"}
                        />{" "}
                        {currency}
                      </td>
                      <td className="text-right">
                        <span style={{ borderBottom: "4px double" }}>
                          {NumberService.formatDecimal(
                            subTotal + taxTotal + expensesTotal
                          )}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Grid>
        <div className="rightPadding leftPadding">
          <div>{this.renderMessageField(invoice)}</div>
          {!this.isCreditNote() && (
            <>
              {/* <div>
                <AxoLocal entity='invoiceEditFormeMailissued'defaultValue={'Udstedt'}/>:&nbsp;{this.renderCreationDateField(invoice)}
              </div> */}
              <div style={{ fontSize: "12px" }} className="bottomPadding">
                <PaymentTypeField />
              </div>
            </>
          )}
          <div>
            <Conditional show={this.state.showSharedMessage}>
              <div className="axoblue">
                <AxoLocal
                  entity="InvoiceClientCanPay"
                  defaultValue={
                    "Klienten kan nu se og betale fakturaen på selvbetjeningspanelet"
                  }
                />
              </div>
            </Conditional>
            <hr />
            <div className="text-center smallText bottomPadding">
              <div>
                <strong className="editable">
                  {this.renderInlineUserProfileEdit("company")}
                </strong>{" "}
                -&nbsp;
                {this.renderInlineUserProfileEdit("address")} -&nbsp;
                {this.renderInlineUserProfileEdit("city")},&nbsp;
                {this.renderInlineUserProfileEdit("postalCode")} -&nbsp;
                {this.isReadOnly() ? (
                  <>
                    {!!userProfile.country
                      ? ctx.getSync("CountryNames", {
                          countrySlug: userProfile.country,
                        })
                      : ""}
                  </>
                ) : (
                  <InlineCountrySelect
                    className="editable"
                    countryName={
                      !!userProfile.country
                        ? ctx.getSync("CountryNames", {
                            countrySlug: userProfile.country,
                          })
                        : "---"
                    }
                    valueType="short"
                    onChange={(val) =>
                      this.onUserProfilePropertyChange("country", val)
                    }
                  />
                )}{" "}
                -&nbsp;
                {userProfile.companyCode ? (
                  <span>
                    <AxoLocal
                      entity="SidebarRightContainerCompanyCode"
                      defaultValue={"CVR"}
                    />
                    :&nbsp;
                    {this.renderInlineUserProfileEdit("companyCode")}
                  </span>
                ) : null}
              </div>
              <div>
                <abbr>
                  <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />:
                </abbr>{" "}
                {this.renderInlineUserProfileEdit("phone")} -&nbsp;
                <abbr>
                  <AxoLocal entity="CaseEditFormeMail" defaultValue={"Email"} />
                  :
                </abbr>
                &nbsp;
                <span>{this.renderInlineUserProfileEdit("eMail")}</span>
              </div>
              <div>
                <AxoLocal
                  entity="AccountingTabViewEntity24"
                  defaultValue={"Bank"}
                />
                : {this.renderInlineUserProfileEdit("accountNo")}
              </div>
            </div>
            {this.isSubscription() && (
              <div className="bottomPadding">
                <strong>
                  <AxoLocal
                    entity="axoEntityidcode96"
                    defaultValue={
                      "Dynamiske tags: ((Year)), ((Month)), ((Week)), ((Day)), ((InvoiceNumber))."
                    }
                  />
                </strong>
                {/* <strong>Dynamiske tags: ((InvoiceNumber)).</strong> */}
              </div>
            )}
            {renderButtons || renderClientButtons ? (
              <ButtonToolbar>
                {this.renderButtons()}
                {this.renderClientButtons()}
              </ButtonToolbar>
            ) : null}
            <Conditional show={this.state.showPaymentCompletedMessage}>
              <div style={{ marginTop: "15px" }}>
                <AxoLocal
                  entity="InvoicePaymentCompleted"
                  defaultValue={"Betalingen er gennemført"}
                />
                .&nbsp;
                {invoice.currency}{" "}
                {NumberService.formatDecimal(
                  subTotal + taxTotal + expensesTotal - aConto
                )}
                &nbsp;
                {!!cardInformation ? (
                  <>
                    <AxoLocal
                      entity="InvoicePaymentInformation"
                      defaultValue={"vil blive trukket på kortnummer"}
                    />{" "}
                    ************{cardInformation ? cardInformation.last4 : ""}.
                  </>
                ) : (
                  <>
                    <AxoLocal
                      entity="axoidcode258"
                      defaultValue={"Vil blive trukket på kortet."}
                    />
                  </>
                )}
              </div>
            </Conditional>
            {this.props.showGeneratePDFError ? (
              <span className="axored">
                <AxoLocal
                  entity="DocumentTemplateCreateViewisGeneratingPDF"
                  defaultValue={
                    "PDF`en kunne ikke genereres. Prøv igen senere."
                  }
                />
              </span>
            ) : null}
            <Conditional show={this.state.showPaymentErrorMessage}>
              <div className="axored">
                <AxoLocal
                  entity="InvoicePaymentFailed"
                  defaultValue={
                    "Betalingen kunne ikke gennemføres. Prøv igen senere."
                  }
                />
              </div>
            </Conditional>
          </div>
        </div>
        <FileViewerModal ref={this.fileModal} />
      </div>
    );
  }
}
