import * as $ from "jquery";

const themes = [
  "theme-aqua",
  "theme-aquaA",
  "theme-darkViolet",
  "theme-gold",
  "theme-green",
  "theme-mediumSpringGreen",
  "theme-olive",
  "theme-peru",
  "theme-pink",
  "theme-red",
  "theme-redaxo",
  "theme-redaxo1",
  "theme-redaxo2",
  "theme-redaxo3",
  "theme-redaxo14",
  "theme-redaxo15",
  "theme-Standard",
];

const standard = "theme-Standard";

export default {
  getStandard() {
    return standard;
  },

  setThemeStyle(newTheme) {
    if ($("html").hasClass(newTheme)) {
      return;
    }

    themes.forEach((theme) => {
      if (theme !== newTheme) {
        $("html").removeClass(theme);
      } else {
        $("html").addClass(newTheme);
      }
    });
  },

  updateThemeStyle(theme, globalSettings, userType) {
    if (theme === standard) {
      switch (userType) {
        case "Accountant":
          theme = globalSettings.themeAccountant || theme;
          break;
        case "Society":
          theme = globalSettings.themeSociety || theme;
          break;
        default:
          //Lawyers and admins
          theme = globalSettings.theme || theme;
          break;
      }
    }
    this.setThemeStyle(theme);
  },
};
