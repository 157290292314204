import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import BusinessOperatingTabView from "./BusinessOperatingTabView";

import { Consumer } from "../utilities/LexUtilities";

export default class BusinessOperatingContainer extends React.PureComponent {
  componentDidMount() {
    //Fetch data on page load
    DataStore.fetchBusinessOperatings();
  }

  componentWillUnmount() {
    //Do stuff when navigating away
  }

  //Filter out needed data from DataStore.js
  mapStoreToProps = (store) => {
    return {
      documentMap: store.documentMap,
      businessOperatings: store.businessOperatings,
      showDropdowns: store.showDropdowns,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => {
          let props = this.mapStoreToProps(store);
          return <BusinessOperatingTabView {...props} />;
        }}
      </Consumer>
    );
  }
}
