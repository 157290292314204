import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Select from "react-select";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import { ApiService } from "../services/AxoServices";

import {
  LexButton,
  AsyncButton,
  AxoDateTime,
  AxoLocal,
} from "../utilities/LexUtilities";

//This form receives starting state and submit function from outside.
export default class EmploymentContractFormView extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    startValue: PropTypes.object,
  };

  constructor(props) {
    super(props);

    if (props.startValue) {
      this.state = { ...props.startValue };
    } else {
      this.state = this.getStartState();
    }
  }

  getStartState = () => {
    return {
      employerName: "",
      employerAddress: "",
      employerCity: "",
      employerPostalCode: "0000",
      workerName: "",
      workerAddress: "",
      workerCity: "",
      workerPostalCode: "0000",
      workPlaceAddress: "",
      hasDifferent: "",
      jobTitle: "",
      jobDescription: "",
      startDate: moment.utc(),
      salary: 36500,
      pensionPercent: 0,
      workTime: "37",
      holidayBonusPercent: 1,
      trialPeriod: "3",
      terminationPeriod: "",
      other: "",
      // contractDate: moment.utc(),
      contractType: "OfficeWork",
      cvr: "",
    };
  };

  handleChange = (event) => {
    let { type, name } = event.target;

    let value = null;
    switch (type) {
      case "number":
        value = event.target.valueAsNumber;
        break;
      case "checkbox":
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
    }

    this.onUpdateProperty(name, value);
  };

  onUpdateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  onSubmit = async () => {
    let { onSubmit } = this.props;

    let result = await onSubmit(this.state);
    return result;
  };

  onCancel = () => {
    let { onCancel, startValue } = this.props;

    if (onCancel) {
      onCancel();
      return;
    }

    if (startValue) {
      this.setState(this.props.startValue);
    } else {
      this.setState(this.getStartState());
    }
  };

  //Skabelon. Der skal ikke laves entities
  render() {
    let {
      employerName,
      employerAddress,
      employerCity,
      employerPostalCode,
      workerName,
      workerAddress,
      workerCity,
      workerPostalCode,
      workPlaceAddress,
      // hasDifferent,
      jobTitle,
      jobDescription,
      startDate,
      salary,
      pensionPercent,
      workerPensionPercent,
      workTime,
      holidayBonusPercent,
      trialPeriod,
      terminationPeriod,
      other,
      // contractDate,
      contractType,
      cvr,
    } = this.state;

    let selectOptions = [
      // { value: 'EmploymentContract', label: 'EmploymentContract' },
      { value: "ManagementContract", label: "Direktørkontrakt" },
      { value: "OfficeWork", label: "Funktionær" },
      { value: "FlexibleContract", label: "Timeløn" },
    ];

    let typeOption = selectOptions.find((o) => o.value === contractType);

    return (
      <Form className="leftPadding standardMaxWidth">
        <FormGroup controlId="contractType">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Kontrakttype
          </ControlLabel>
          <Select
            name="select"
            menuPlacement="auto"
            value={typeOption}
            onChange={(selection) => {
              if (!!selection) {
                this.onUpdateProperty("contractType", selection.value);
              }
            }}
            noOptionsMessage={() => ""}
            options={selectOptions}
          />
        </FormGroup>

        <FormGroup controlId="employerAddress">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Arbejdsgivers CVR
          </ControlLabel>
          <FormControl
            name="cvr"
            type="text"
            className="borderFormControlfocus"
            value={cvr}
            onChange={this.handleChange}
          />
          <LexButton
            disabled={!cvr}
            onClick={async () => {
              let companyInfo = await ApiService.getCVRInformation(cvr, "DK");
              this.setState({
                employerName: companyInfo.name,
                employerAddress: companyInfo.address,
                employerCity: companyInfo.city,
                employerPostalCode: companyInfo.zipcode,
              });
            }}
          >
            Hent data
          </LexButton>
        </FormGroup>

        <FormGroup controlId="employerName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Arbejdsgivers navn
          </ControlLabel>
          <FormControl
            name="employerName" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={employerName}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="employerAddress">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Arbejdsgivers adresse (vej)
          </ControlLabel>
          <FormControl
            name="employerAddress"
            type="text"
            className="borderFormControlfocus"
            value={employerAddress}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="employerPostalCode">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="SidebarRightContainerpostalCode"
              defaultValue={"Postnummer"}
            />
          </ControlLabel>
          <FormControl
            name="employerPostalCode"
            type="number"
            className="borderFormControlfocus"
            value={employerPostalCode}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="employerCity">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            By
          </ControlLabel>
          <FormControl
            name="employerCity" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={employerCity}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="workerName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Ansattes navn
          </ControlLabel>
          <FormControl
            name="workerName" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={workerName}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="workerAddress">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Ansattes adresse (vej)
          </ControlLabel>
          <FormControl
            name="workerAddress" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={workerAddress}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="workerPostalCode">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="SidebarRightContainerpostalCode"
              defaultValue={"Postnummer"}
            />
          </ControlLabel>
          <FormControl
            name="workerPostalCode"
            type="number"
            className="borderFormControlfocus"
            value={workerPostalCode}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="workerCity">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            By
          </ControlLabel>
          <FormControl
            name="workerCity" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={workerCity}
            onChange={this.handleChange}
          />
        </FormGroup>
        <br />
        <LexButton
          onClick={() => {
            let { employerAddress, employerPostalCode, employerCity } =
              this.state;
            this.setState({
              workPlaceAddress:
                employerAddress + " " + employerPostalCode + " " + employerCity,
            });
          }}
        >
          Samme som firma adresse
        </LexButton>

        <FormGroup controlId="workPlaceAddress">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Arbejdspladsens adresse
          </ControlLabel>
          <FormControl
            name="workPlaceAddress" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={workPlaceAddress}
            onChange={this.handleChange}
          />
        </FormGroup>

        {contractType !== "ManagementContract" && (
          <>
            <FormGroup controlId="jobTitle">
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  entity="CalendarEventBoxFormtitle"
                  defaultValue={"Titel"}
                />
              </ControlLabel>
              <FormControl
                name="jobTitle" //Name must correspond to the name of the property
                type="text"
                className="borderFormControlfocus"
                value={jobTitle}
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup controlId="jobDescription">
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                Jobbeskrivelse
              </ControlLabel>
              <FormControl
                name="jobDescription" //Name must correspond to the name of the property
                type="text"
                className="borderFormControlfocus"
                value={jobDescription}
                onChange={this.handleChange}
              />
            </FormGroup>
          </>
        )}

        <FormGroup controlId="startDate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Startdato
          </ControlLabel>
          <AxoDateTime
            value={moment(startDate)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("startDate", dateTime.format())
            }
          />
        </FormGroup>

        <FormGroup controlId="salary">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Løn
          </ControlLabel>
          <FormControl
            name="salary" //Name must correspond to the name of the property
            type="number"
            className="borderFormControlfocus"
            value={salary}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="pensionPercent">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Pensionstillæg (%)
          </ControlLabel>
          <FormControl
            name="pensionPercent" //Name must correspond to the name of the property
            type="number"
            className="borderFormControlfocus"
            value={pensionPercent}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="pensionPercent">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Medarbejder pensionsprocent (%)
          </ControlLabel>
          <FormControl
            name="workerPensionPercent" //Name must correspond to the name of the property
            type="number"
            className="borderFormControlfocus"
            value={workerPensionPercent}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="workTime">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Arbejdstid
          </ControlLabel>
          <FormControl
            name="workTime" //Name must correspond to the name of the property
            type="number"
            className="borderFormControlfocus"
            value={workTime}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="holidayBonusPercent">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Ferieprocent
          </ControlLabel>
          <FormControl
            name="holidayBonusPercent"
            type="number"
            className="borderFormControlfocus"
            value={holidayBonusPercent}
            onChange={this.handleChange}
          />
        </FormGroup>

        {contractType !== "ManagementContract" && (
          <FormGroup controlId="trialPeriod">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              Prøveperiode (måneder)
            </ControlLabel>
            <FormControl
              name="trialPeriod" //Name must correspond to the name of the property
              type="text"
              className="borderFormControlfocus"
              value={trialPeriod}
              onChange={this.handleChange}
            />
          </FormGroup>
        )}

        {contractType === "FlexibleContract" && (
          <FormGroup controlId="terminationPeriod">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              Opsigelsesperiode
            </ControlLabel>
            <FormControl
              name="terminationPeriod" //Name must correspond to the name of the property
              type="text"
              className="borderFormControlfocus"
              value={terminationPeriod}
              onChange={this.handleChange}
            />
          </FormGroup>
        )}

        <FormGroup controlId="other">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Andre
          </ControlLabel>
          <FormControl
            name="other"
            componentClass="textarea"
            className="other"
            value={other}
            onChange={this.handleChange}
            rows={3}
          />
        </FormGroup>

        {/* <FormGroup controlId='contractDate'>
          <ControlLabel style={{ textAlign: 'left', fontSize:'16px', fontWeight:'normal' }}>
          Kontraktdato
          </ControlLabel>
          <AxoDateTime
            value={moment(contractDate)}
            dateFormat={true}
            timeFormat= {false}
            utc
            onChange={dateTime => this.onUpdateProperty('contractDate', dateTime.format())}
          />
        </FormGroup> */}

        <ButtonToolbar>
          <AsyncButton hideOkIcon onClick={this.onSubmit}>
            <AxoLocal
              entity="friendRequestTimelineonApproveRequest"
              defaultValue={"Godkend"}
            />
          </AsyncButton>
          <LexButton onClick={this.onCancel}>Fortryd</LexButton>
        </ButtonToolbar>
      </Form>
    );
  }
}
