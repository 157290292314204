import React from "react";

import Conditional from "../utilities/Conditional";

import { Button, ButtonGroup } from "react-bootstrap";
import { Icon } from "../utilities/LexUtilities";
export default class AxoNavButton extends React.PureComponent {
  //Props
  //glyph
  //children
  //ls
  //onClick
  //eventKey
  //activeTab
  //btnWidth
  getButtonClass = (eventKey) => {
    if (this.props.eventKey === this.props.activeTab) {
      return "Axo-button-1 active";
    }
    return "Axo-button-1";
  };

  renderContent = () => {
    var bsStyle = this.props.btnWidth ? { width: this.props.btnWidth } : null;
    if (this.props.ls) {
      return (
        <Button
          style={bsStyle}
          className={this.getButtonClass()}
          onClick={this.props.onClick.bind(this, this.props.eventKey)}
        >
          <Conditional inline show={!!this.props.glyph}>
            <Icon glyph={this.props.glyph || ""} />
            &nbsp;
          </Conditional>
          {this.props.children}
        </Button>
      );
    } else {
      return (
        <Button
          style={bsStyle}
          className={this.getButtonClass()}
          onClick={this.props.onClick.bind(this, this.props.eventKey)}
        >
          <Conditional inline show={!!this.props.glyph}>
            <div>
              <Icon glyph={this.props.glyph || ""} />
              &nbsp;
            </div>
          </Conditional>
          <div>{this.props.children}</div>
        </Button>
      );
    }
  };

  render() {
    return <ButtonGroup>{this.renderContent()}</ButtonGroup>;
  }
}
