import React from "react";
import BookingView from "../../Booking/BookingView";
import { withRouter } from "react-router-dom";
import { Row, Col, Grid } from "react-bootstrap";
import { DataStore } from "../../services/AxoServices";
import { UserSearchBox, AxoLocal } from "../../utilities/LexUtilities";

class ClientBookingContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      lawyers: [],
      selectedLawyer: {},
    };
    this.name = "ClientBookingContainer";
  }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      let lawyers = store.lawyerProfiles;
      this.setState({
        userProfile: store.userProfile,
        lawyers: lawyers,
        selectedLawyer: lawyers.length > 0 ? lawyers[0] : {},
      });
    });
    DataStore.initializeUserProfile();
    DataStore.fetchLawyerProfiles();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  onSelectLawyer = (lawyer) => {
    this.setState({ selectedLawyer: lawyer });
  };

  //Placeholder: Vælg advokat
  renderSearchBox = () => {
    if (this.state.lawyers.length < 2) {
      return null;
    }
    return (
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <AxoLocal
              componentClass={UserSearchBox}
              maxWidth={250}
              userProfiles={this.state.lawyers}
              handleSelectedUser={this.onSelectLawyer}
              inputBorder="1px solid #4CAF50"
              componentAttribute="placeholder"
              entity="ClientBookingSelectLawyer"
            />
          </Col>
        </Row>
      </Grid>
    );
  };

  onGotoFrontPage = () => {
    this.props.history.push("/");
  };

  render() {
    var { selectedLawyer, userProfile } = this.state;
    return (
      <div>
        <div className="flexbox-center">
          <div style={{ paddingTop: "25px" }}>
            <h4>
              <AxoLocal
                entity="Companyaboutostaxt26"
                defaultValue={"Vælg en dag for at booke tid på denne dag."}
              />
            </h4>
            <div className="flexbox-center">
              <div>
                {this.renderSearchBox()}
                <BookingView
                  receiverUserName={selectedLawyer.userName}
                  bookingUserName={userProfile.userName}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div
          className="testbackground"
          style={{ position: "relative", zIndex: 100 }}
        >
          <div className="testtransbox text-center">
            <div style={{ height: "100%", display: "inline-block" }}>
              &nbsp;&nbsp;&nbsp;
            </div>
            {/* <span role='button' onClick={this.onGotoFrontPage} className='Axooverskrifttext'> 
                <AxoLocal entity='presentationMarketing34' defaultValue='Axolex IT-system'/>
              </span> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ClientBookingContainer);
