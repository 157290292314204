import React from "react";

import { LexButton, AxoCheckbox, UserSearchBox } from "./LexUtilities";

import { AxoLocal, Icon } from "./LexUtilities";

import { DropdownButton, MenuItem } from "react-bootstrap";

export default class SharingDropDown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shareAll: false,
      shareWithGroup: false,
      userId: 0,
      success: false,
    };
  }

  onUpdateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  onSelectUser = (selectedUser) => {
    this.setState({ userId: selectedUser.id });
  };

  onShare = () => {
    var { onShare } = this.props;
    if (!onShare) {
      return;
    }

    var { shareAll, shareWithGroup, userId } = this.state;

    onShare(shareAll, shareWithGroup, userId)
      .then((responses) => {
        this.setState({ success: true });
        setTimeout(() => {
          this.setState({
            shareAll: false,
            shareWithGroup: false,
            success: false,
          });
        }, 3000);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  render() {
    var {
      objectName,
      userGroup,
      userProfile,
      friends,
      title,
      hasSelectedEntries,
    } = this.props;
    var { shareAll, shareWithGroup, success } = this.state;

    let hasUserGroup =
      !!userGroup && !!userGroup.members && userGroup.members.length > 1;
    let totalFriends = friends || [];
    if (hasUserGroup) {
      totalFriends = totalFriends.concat(
        userGroup.members.filter((m) => m.id !== userProfile.id)
      );
    }
    let hasFriends = totalFriends.length > 0;

    if (!hasUserGroup && !hasFriends) {
      return (
        <DropdownButton
          id="ColumnDropdown"
          className="delButtoncolor axoDropdownButton"
          title={
            <span style={{ fontSize: "15px" }}>
              {title ? (
                <span>{title}</span>
              ) : (
                <span>
                  <AxoLocal
                    entity="presentationMarketing3"
                    defaultValue={"Del"}
                  />
                </span>
              )}
            </span>
          }
        >
          <MenuItem eventKey="all">
            <div>
              <AxoLocal
                entity="axoAccounting78"
                defaultValue={"Du har ingen kontakter at dele med"}
              />
            </div>
          </MenuItem>
        </DropdownButton>
      );
    }

    return (
      // <div className='dropdown-overflow'>
      <DropdownButton
        id="ColumnDropdown"
        className="delButtoncolor axoDropdownButton"
        title={
          <span style={{ fontSize: "15px" }}>
            {title ? (
              <span>{title}</span>
            ) : (
              <span>
                <AxoLocal
                  entity="presentationMarketing3"
                  defaultValue={"Del"}
                />
              </span>
            )}
          </span>
        }
      >
        <div className="text-center">
          <AxoLocal
            entity="presentationMarketing3a1"
            defaultValue={"Del alle eller valgte"}
          />
        </div>
        <MenuItem eventKey="all">
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <AxoCheckbox
              checked={shareAll}
              onChange={(event) => {
                this.onUpdateProperty("shareAll", event.target.checked);
              }}
            />
            <AxoLocal
              entity="presentationMarketing3d"
              defaultValue={"Del alle"}
            />{" "}
            {objectName}
          </div>
        </MenuItem>
        <div
          className="text-center delButtoncolor"
          style={{ fontWeight: "bold", color: "white" }}
        >
          <AxoLocal
            entity="PrintLayoutSelectClient"
            defaultValue={"Vælg modtager"}
          />
        </div>
        {hasUserGroup ? (
          <MenuItem eventKey="all">
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <AxoCheckbox
                checked={shareWithGroup}
                disabled={!hasSelectedEntries && !shareAll}
                onChange={(event) => {
                  this.onUpdateProperty("shareWithGroup", event.target.checked);
                }}
              />
              <AxoLocal
                entity="presentationMarketing3c"
                defaultValue={"Del med din brugergruppe"}
              />
            </div>
          </MenuItem>
        ) : null}
        {hasFriends ? (
          <MenuItem eventKey="all">
            <div
              className="text-left"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <div className="text-center">
                <AxoLocal
                  entity="presentationMarketing3b1"
                  defaultValue={"Del med kontakt"}
                />
              </div>
              <AxoLocal
                componentClass={UserSearchBox}
                maxWidth={250}
                userProfiles={totalFriends}
                disabled={!hasSelectedEntries && !shareAll}
                handleSelectedUser={this.onSelectUser}
                inputBorder="1px solid #4CAF50"
                componentAttribute="placeholder"
                entity="ClientBookingSelectLawyer"
              />
            </div>
          </MenuItem>
        ) : null}
        <MenuItem eventKey="all">
          <div
            className="text-center "
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <LexButton
              disabled={success || (!hasSelectedEntries && !shareAll)}
              onClick={this.onShare}
            >
              {success ? (
                <span>
                  <span>
                    <AxoLocal
                      key="shared"
                      entity="presentationMarketing3a"
                      defaultValue={"Delt"}
                    />
                    &nbsp;
                    <Icon glyph="icon-fontello-check-1" />
                  </span>{" "}
                </span>
              ) : (
                <span>
                  <span>
                    <AxoLocal
                      key="share"
                      entity="presentationMarketing3"
                      defaultValue={"Del"}
                    />
                  </span>{" "}
                </span>
              )}
            </LexButton>
          </div>
        </MenuItem>
      </DropdownButton>
      // </div>
    );
  }
}
