import React, {

  // useState,
  // useEffect
} from 'react'

import singimg4 from '../resources/images/reception26.jpg';
import singimg5 from '../resources/images/kitchen.jpg';
import singimg6 from '../resources/images/office81.jpg';
import singimg7 from '../resources/images/office82.jpg';
import singimg8 from '../resources/images/reception2.jpg';
import singimg9 from '../resources/images/reception12.jpg';
import singimg10 from '../resources/images/reception19.jpg';
import singimg11 from '../resources/images/reception25.jpg';
import singimg12 from '../resources/images/reception21.jpg';
import singimg13 from '../resources/images/language1.jpg';
import singimg14 from '../resources/images/reception22.jpg';


import {
  withRouter,
  Flexbox,
  AxoLocal,
  FlexElement,
  AxoFooter,
  Icon,
  AxoPanelGroup,
  AxoPanel,
  
} from '../utilities/LexUtilities';

import { 
  SmallOrSmaller, 
  MediumOrLarger, 
} from '../utilities/Responsive';

import {
  Col,
  Grid,
  Row
} from 'react-bootstrap'

// http://localhost:3000/AddressService
function AddressServiceView(props) {
  return (
    <div className='login SideHeight bgSignSide' style={{ paddingTop: '10px'}}>
      <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center" >
      芯法地址服务
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img alt=''  className='hidden-lg hidden-sm hidden-md' src='/imgs/app/Axologo3-30x30.png'/>   
            <img alt=''  className='hidden-lg hidden-md hidden-xs' src='/imgs/app/Axologo3-35x35.png'/>  
            <img alt=''  className='hidden-lg hidden-sm hidden-xs' src='/imgs/app/Axologo3-40x40.png'/> 
            <img alt=''  className='hidden-md hidden-sm hidden-xs' src='/imgs/app/Axologo3-40x40.png'/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <FlagMenu
          // className='Lex-button-2'
          userSettings={{}}
        /> */}
      </div>
      <div className='standardMaxWidth center-block bgSignCenter'>
      <div className='standardMaxWidth paddingBottom center-block'>
        <AxoPanelGroup>
        <AxoPanel
             className='bottomMargin' eventKey="1"
             title='虚拟办公室/企业地址服务'>
          <Flexbox collapseXS justifyCenter>
            
            <FlexElement>
              <div className='overtext'>您在寻找丹麦或德国的代表处/公司地址吗？</div>
              <div className='overtext'>芯法地址服务为您的企业提供专业公司地址.</div>
              <div className='overtext'>我们提供便捷的虚拟办公室服务给任何有需要的客户们,</div>
              <div className='overtext'>高层主管,专业人士，只需少量付费，您就可以拥有以下服务:</div>
              <div className='undertext'>注册公司地址 (699 kr.)/月.</div>
              <div className='undertext'>企业专线个性化电话接听 (499 kr.)/月.</div>
              <div className='undertext'>企业信息，邮件通知服务 (10 件内免费， 10 件以上6，99 kr./件).</div>
              <div className='undertext'>信件以及快递管理 (按量收费).</div>
              <div className='undertext'>预定会议室，贵宾会见室 (5至250人不等).</div>
              <div className='undertext'>预定服务式办公室的日租和时租服务.</div>
              <div className='undertext'>E-boks 代收息件 (299 kr./月.面议授权).</div>
              <div className='undertext'>24小时信箱系统.</div>
              <div className='undertext'>专业的前台接待给予您的访客专业接待服务.</div>
              <div className='undertext'>所有服务均以中，英，丹，德语服务.</div>
              <div className='undertext'>特别员价使用我们的商业服务，设备器材，</div>
              <div className='undertext'>业务外包服务，秘书服务等等.</div>
              <div className='undertext'>无论您是事业刚起步还是需要一个完美的企业形象，</div>
              <div  className='undertext'>虚拟办公室配套将会是您最佳的选择.使用芯法专业的虚拟办公室，</div>
              </FlexElement>
              <FlexElement >
              <div><img className='hidden-xs hidden-sm  ' alt='' src={singimg4}width='400px' height='400px'/></div>
              <div><img className='hidden-lg hidden-md  ' alt='' src={singimg4}width='400px' height='400px'/></div>
              <div  className='undertext leftPadding topPadding'>经济，方便，专业.</div>
              <div className='undertext leftPadding topPadding'>您将更加享受事业的乐趣，保护您的家庭住址.</div>
              <div className='undertext leftPadding topPadding'>30 天退约期.</div>
              <div className='undertext leftPadding topPadding'>注册费 500 kr.</div>
              <div className='undertext leftPadding topPadding'>0 押金.</div>
            </FlexElement>
            </Flexbox>
          </AxoPanel>
        <AxoPanel
             className='bottomMargin' eventKey="2"
             title='私人地址服务'>
               <Flexbox collapseXS justifyCenter>
          <FlexElement>
          <div className='undertext'>使用我们的地址作为您自己的私人地址 (599 kr.)/月.</div>
               <div className='undertext'>信件将被扫描转发到您的地址或我们的邮箱(15件内免费).</div>
               <div className='undertext'>必要时我们会签收包裹 (29 kr./件).</div>
               <div className='undertext'>当收到新邮件时，您将收到一条消息.</div>
               <div className='undertext'>E-boks 代收息件 (199 kr./月.面议授权).</div>
               <div className='undertext'>您选择是要自己转发，存储.</div>
               <div className='undertext'>可提供专业的接待，秘书服务.</div>
               <div className='undertext'>专业的团队满足您各方面的需求.</div>
               <div className='undertext'>舒适茶水间和电视休息室的使用权.</div>
               <div className='undertext'>免费停车位.</div>
               <div className='undertext'>短时离境储物空间，我们为您储存任何物件.</div>
               <div className='undertext'>短时入境居住套房(日租 299 kr/晚 月租 5000 kr.). </div>
               <div className='undertext'>30 天退约期.</div>
               <div className='undertext'>注册费 500 kr.</div>
               <div className='undertext'>0 押金.</div>
               <div  className='undertext'></div>
            </FlexElement>
            <FlexElement>
            <div><img className='hidden-xs hidden-sm  ' src={singimg5}alt=''width='400px' height='400px'/></div>
            </FlexElement>
            </Flexbox>
          </AxoPanel>
          <AxoPanel
             className='bottomMargin' eventKey="3"
             title='服务式办公室'>
               <Flexbox collapseXS justifyCenter>
          <FlexElement>
          <div className='overtext'>精美装修和商务设备齐全的办公空间，同时也有用前台行政服务，会议室设施和通讯设备等支援。</div>
               <div className='undertext'>专属办公桌 (999 kr.至1500 kr.)/月.</div>
               <div className='undertext'>专属办公室 (3000 kr.至15000 kr.)/月.</div>
               <div className='undertext'>全装修的办公室和高科技通讯设备.</div>
               <div className='undertext'>可供视频会议和电话会议的会议室和会见室.</div>
               <div className='undertext'>高端的通讯设备让您无论在哪都能和您的客户保持最密切的关系.</div>
               <div className='undertext'>专业的接待，秘书，行政服务.</div>
               <div className='undertext'>电话专线号码 (399 kr./月).</div>
               <div className='undertext'>专业的团队满足您各方面的需求.</div>
               <div className='undertext'>舒适茶水间和电视休息室的使用权.</div>
               <div className='undertext'>90 天退约期.</div>
              <div className='undertext'>注册费 500 kr.</div>
            </FlexElement>
            <FlexElement>
            <div><img src={singimg6} alt=''width='400px' height='300px'/></div>
            
            <div className='undertext topPadding'>3个月押金.</div>
            </FlexElement>
            </Flexbox>
          </AxoPanel>
          <AxoPanel
             className='bottomMargin' eventKey="4"
             title='会议室，课程和讲习班' >
               <Flexbox collapseXS justifyCenter>
          <FlexElement>
                <div className='overtext'>可以按小时或一或多天租用我们不同大小的会议室</div>
               <div className='undertext'>4/6人会议室（299 kr./小时，999 kr./天）.</div>
               <div className='undertext'>6/10人会议室（349 kr./小时，1299 kr./天）.</div>
               <div className='undertext'>10/50人会议室（499 kr./小时，1999 kr./天）.</div>
               <div className='undertext'>50/150人会议室（1999 kr./小时，8999 kr./天）.</div>
               <div className='undertext'>150/250人会议室（按活动议价，进行安排）.</div>
               <div className='undertext'>1/2定金.</div>
               <div className='undertext'>0押金.</div>
            </FlexElement>
            <FlexElement>
            <div><img src={singimg7} alt=''width='400px' height='300px'/></div>
            </FlexElement>
            </Flexbox>
          </AxoPanel>
          <AxoPanel
             className='bottomMargin' eventKey="5"
             title='注册公司 & 业务外包服务' >
            <Flexbox collapseXS justifyCenter>
          <FlexElement>
                <div className='overtext'>电话接听服务，财务会计，员工薪水分发服务，人力资源服务以及其他各项商业服务。</div>
               <div className='overtext'>财务会计（按凭证件数计算，随时询价，现时优惠价 4000 kr/月）</div>
               <div className='overtext'>年终报表 (15000 kr.)</div>
               <div className='overtext'>人力资源部服务:（按凭工作量计算，随时询价） </div>
               <div className='overtext'>人事部员工招聘，档案，安排面试时间 (面试将由您出席)，</div>
               <div className='undertext'>准备劳工合同 (1600 kr.).</div>
               <div className='undertext'>核对文件资料创建员工档案(5人以下 399 kr./月,5人以上询价).</div>
               <div className='overtext'>入境: </div>
               <div className='undertext'>申请各种工作签证(面仪).</div>
               <div className='undertext'>监督工作许可人有效期,更新及取消，(500 kr.).</div>
               <div className='undertext'>代发工资单（5人以下50 kr./人/月).(5人以上39 kr./人/月).</div>
               <div className='undertext'>网页寄存/网页设计 (面仪).</div>
               <div className='undertext'>旅行出差订票服务 (面仪).</div>
               <div className='undertext'>会计服务30天退约期.</div>
               <div className='undertext'>注册费 0 kr.</div>
               <div className='undertext'>0押金.</div>
            </FlexElement>
            <FlexElement>
            <div><img alt=''src={singimg8} width='400px' height='400px'/></div>
            <div className='overtext leftPadding'>注册公司服务（国际）:</div>
               <div className='undertext leftPadding'>注册私人公司 (5999 kr.).</div>
               <div className='undertext leftPadding'>注册有限公司 (6999 kr.).</div>
               <div className='undertext leftPadding'>注册协会及其他不盈利机构 (5999 kr.).</div>
               <div className='undertext leftPadding'>注册独资及合资公司 (6999 kr.).</div>
               <div className='undertext leftPadding'>注册代理办事处 (6999 kr.).</div>
            </FlexElement>
            </Flexbox>
          </AxoPanel>
          <AxoPanel
             className='bottomMargin' eventKey="6"
             title='公司秘书服务' >
               <Flexbox collapseXS justifyCenter>
               <FlexElement>
              <div><img alt=''src={singimg9} width='400px' height='300px'/></div>
              </FlexElement>
                <FlexElement>
                  <div className='overtext leftPadding'>更改注册地址(799 kr.).</div>
                  <div className='overtext leftPadding'>更改董事 (799 kr.).</div>
                  <div className='overtext leftPadding'>银行开户 (8999 kr.).</div>
                  <div className='overtext leftPadding '>更改名字 (799 kr.).</div>
                  <div className='overtext leftPadding'>更改公司章程 (999 kr.).</div>
                  <div className='overtext leftPadding'>分配股份 (999 kr.).</div>
                  <div className='undertext leftPadding'>股份转让(面仪).</div>
              </FlexElement>
              
            </Flexbox>
          </AxoPanel>
          <AxoPanel
             className='bottomMargin' eventKey="7"
             title='机场接机服务' >
               <Flexbox collapseXS justifyCenter>
               <FlexElement>
              <div  className='leftPadding'><img alt=''src={singimg10} width='400px' height='300px'/></div>
              </FlexElement>
                <FlexElement>
                <div className='undertext leftPadding'>您需要到机场接送您的贵宾吗?</div>
               <div className='undertext leftPadding'>我们提供豪华轿车接送服务.</div>
               <div className='undertext leftPadding'>为您的贵宾提供一个舒适的享受.</div>
               <div className='undertext leftPadding'>(面仪客户要求).</div>
              </FlexElement>
            </Flexbox>
          </AxoPanel>
          
          <AxoPanel
             className='bottomMargin' eventKey="9"
             title='许可证代理' >
               <Flexbox collapseXS justifyCenter>
                <FlexElement>
                <div className='overtext'>申请任何经营许可证件</div>
               <div className='undertext'>酒牌(15000 kr.).</div>
               <div className='undertext'>如烟草专卖许可证.</div>
               <div className='undertext'>药品经营许可证.</div>
               <div className='undertext'>危险化学品经营许可证.</div>
               <div className='undertext'>医疗器械经营许可证.</div>
               <div className='undertext'>电信业务经营许可证.</div>
               <div className='undertext'>烟花爆竹零售经营许可证等.</div>
              </FlexElement>
              <FlexElement>
              <div  className='leftPadding'><img alt='' src={singimg11} width='400px' height='300px'/></div>
              </FlexElement>
            </Flexbox>
          </AxoPanel>
          <AxoPanel
             className='bottomMargin' eventKey="8"
             title='IT 服务' >
                <Flexbox collapseXS justifyCenter>
                <FlexElement>
              <div><img alt='' src={singimg12} width='400px' height='300px'/></div>
              </FlexElement>
                <FlexElement>
                <div className='undertext leftPadding'>高效的IT专业人员将会为您提供及时的IT服务.</div>
              <div className='undertext leftPadding'>当地办公软件系统中，英，丹，德语.</div>
              </FlexElement>
            </Flexbox>
          </AxoPanel>
          <AxoPanel
             className='bottomMargin' eventKey="10"
             title='翻译服务' >
               <Flexbox collapseXS justifyCenter>
                <FlexElement>
                <div className='undertext'>专业人员、内外部人员提供翻译服务.</div>
               <div className='undertext'>服务中，英，丹，德语.</div>
              </FlexElement>
              <FlexElement>
              <div  className='leftPadding'><img alt='' src={singimg13} width='400px' height='300px'/></div>
              </FlexElement>
            </Flexbox>
          </AxoPanel>
          <AxoPanel
             className='bottomMargin' eventKey="11"
             title='其他基本费用' >
               <Flexbox collapseXS justifyCenter>
               <FlexElement>
              <div ><img alt='' src={singimg14}width='400px' height='300px'/></div>
              </FlexElement>
                <FlexElement>
                <div className='undertext leftPadding'>退约期.</div>
                <div className='undertext leftPadding'>注册费</div>
                <div className='undertext leftPadding'>押金.</div>
              </FlexElement>
              
            </Flexbox>
          </AxoPanel>
          {/* <AxoPanel
             className='bottomMargin' eventKey="11"
             title='PanelTitle' >
               <Flexbox collapseXS justifyCenter>
                <FlexElement>
                <div className='undertext'>Indhold</div>
              </FlexElement>
              <FlexElement>
                <div><img className='' alt=''src={singimg3} width='120px' height='120px' /></div>
              </FlexElement>
            </Flexbox>
          </AxoPanel> */}
        </AxoPanelGroup>
      </div>
      <div>
        <div className='marginonly' ></div>
        <div className='marginonly' ></div>
        <div className='marginonly' ></div>
        <div className='marginonly' ></div>
        <div></div>
      <AxoFooter />
      </div>
      </div>

      <div>
        <Grid fluid className="Axolex-Menu-lg text-center" >
        <Row>
          <Col sm={10} smOffset={1} xs={12} className="text-center nopadding"  > 

            <Col sm={4} xs={12} className='flexbox-center nopadding'>
              <div className='text-left frontPageaxolexdivtextbox' style={{ width: '400px',marginLeft:'10px' }}>    
                  <div><h3><AxoLocal entity='FrontPageindextaxt11' defaultValue={'Find os på'}/></h3></div> 
                  <div><a href='https://www.facebook.com/Axolex.dk/' target='_blank' rel="noopener noreferrer"><Icon className='axolexwebsideicon' glyph='icon-fontello-facebook-squared' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactCreateViewFacebook'defaultValue={'Facebook'}/></a></div>
                  {/* <div><span><Icon className='axolexwebsideicon' glyph='icon-fontello-linkedin-squared' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactCreateViewLinkedIn'defaultValue={'LinkedIn'}/></span></div> */}
                  <div> <a href='https://twitter.com/AxolexApS' target='_blank' rel="noopener noreferrer"><Icon  className='axolexwebsideicon' glyph='icon-fontello-twitter-squared' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactCreateViewTwitter'defaultValue={'Twitter'}/> </a></div>
                  {/* <div> <span><Icon className='axolexwebsideicon' glyph='icon-fontello-chat-1' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactWeChat'defaultValue={'WeChat'}/> </span></div> */}
                  <div style={{ fontSize:'22px' }}>© &ensp;&ensp;&ensp;</div>
                  <div className='text-left ' role="button" >
                  <div><img alt=''  height='200px' width='200px' src='/svg/Axologo1-014.png'/></div>
                  </div>
                  <div>
                    {/* <LexButton onClick={() => { this.props.history.push('freeware')}}>
                    <AxoLocal entity='axolexQRcode17' defaultValue={'Benyt vores freeware produkter'}/>
                    </LexButton> */}
                  </div>
              </div>
              </Col>
              <Col sm={3} xs={12} className='flexbox-center nopadding'>                 
                <div className='text-left frontPageaxolexdivtextbox' style={{ width: '400px' }}>
                  <div ><h3> <AxoLocal entity='FrontPageviewtext11' defaultValue={'Vi tilbyder'}/> </h3></div>
                  <div>MitID signering</div>
                  <div>Systemintegration</div>
                  <div>PAdES PDF generering</div>
                  <div>Tegnet signatur</div>
                  <div>Validering</div>
                </div>
              </Col>
              <Col sm={3} xs={12} className='flexbox-center nopadding'>
                <div className='text-left frontPageaxolexdivtextbox' style={{ width: '400px' }}>
                  <div><h3> <AxoLocal entity='FrontPageviewtext12' defaultValue={'Du kan vælge'}/></h3></div>
                  <div>Konto hos Axolex</div>
                  <div>Systemintegration med jeres system.</div>
                  <div>Fleksible priser.</div>
                  <div>Abonnementsløsning.</div>     
              </div>
              </Col>
                <Col sm={2} xs={12}  className='flexbox-center nopadding' >
                  <div className='text-left frontPageaxolexdivtextbox'style={{ width: '400px' }}> 
                    <div><h3> <AxoLocal entity='Companyaboutostaxt11' defaultValue={'Axolex ApS'}/></h3></div> 
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}><AxoLocal entity='SignupAdresse'defaultValue={'Adresse'}/>:</div> 
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}>Strandesplanaden 110</div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}>2665 Vallensbæk Strand</div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}>Denmark</div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}>CVR: 39076969</div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}><AxoLocal entity='CaseEditFormphone'defaultValue={'Tlf'}/>:53780153</div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}><AxoLocal entity='CaseEditFormeMail'defaultValue={'Email'}/>: </div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}><a href="mailto:info@axolex.com">info@axolex.com</a> </div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}><a href="mailto:it@axolex.com">it@axolex.com</a></div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}><a href="mailto:legal@axolex.com">legal@axolex.com</a></div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}><a href="mailto:support@axolex.com">support@axolex.com</a></div>
                    <div style={{fontSize:' 12px',lineHeight: '15px'}}><a href="mailto:job@axolex.com">job@axolex.com</a>  </div>  
                  </div>
                </Col>
            </Col>
          </Row>
      </Grid>

      <Grid fluid>
         <Row>
            <Col  xs={12} className="nopadding">
            <div className='image-banner'>
              <div className="banner-container">
                <div className="banner-item">
                  <MediumOrLarger>
                  <img alt='' className="img-responsive bg_img" style={{maxWidth: '100%'}}  src="/svg/Axolex3-lg.jpg" />
                  </MediumOrLarger>
                  <SmallOrSmaller>
                  <img alt='' className="img-responsive bg_img" style={{maxWidth: '100%'}}  src="/svg/Axolex3-xs.jpg" />
                  </SmallOrSmaller>
                  <div className="text_wrapper">
                    <div className="text_position chbannetaxt" style={{Color:"white"}}>
                      <h2 className="item--title"><AxoLocal entity='Companyaboutostaxt11' defaultValue={'Axolex'}/></h2>
                      <p className="item--text" ><AxoLocal entity='Companyaboutostaxt12' defaultValue={' Komplet juridisk IT-løsning'}/></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
       </Grid>  
    </div>
    </div>

  )
}

export default withRouter(AddressServiceView);