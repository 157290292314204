import React from "react";
import MinyTinyMCE from "../../utilities/Editors/MinyTinyMCE";
import { debounce } from "lodash";

import { Grid, Row, Col } from "react-bootstrap";
import { AxoLocal } from "../../utilities/LexUtilities";

// import {
//   AxoCheckbox,
//   AxoLocal
// } from '../../utilities/LexUtilities'

export default class PaymentConfigurationView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderEditor: false,
    };
  }

  updatePropertyDebounced = debounce((propertyName, value) => {
    this.props.onUpdateProperty(propertyName, value);
  }, 500);

  dummyFunction = () => {};

  //Rendering TinyMce editor in the initial rendering pass sometimes causes errors, for some reason
  componentDidMount() {
    this.setState({ renderEditor: true });
  }

  render() {
    var {
      userSettings,
      showSinglePayment,
      showSubscriptionTerms,
      // onUpdateProperty,
      // emailAccounts
    } = this.props;

    let { renderEditor } = this.state;

    return (
      <div className="leftPadding largeMaxWidth">
        <Grid fluid className="nopadding">
          {showSinglePayment && (
            <>
              <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <Col xs={12}>
                  <div
                    className="Axobt BanneDashboard"
                    style={{ paddingLeft: "10px" }}
                  >
                    <AxoLocal
                      entity="Enhedspris35"
                      defaultValue={"Købsbetingelser"}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {renderEditor ? (
                    <MinyTinyMCE
                      text={userSettings.paymentTerms}
                      height={250}
                      locale={userSettings.locale}
                      setupEditor={this.dummyFunction}
                      onTextChange={(content) =>
                        this.updatePropertyDebounced("paymentTerms", content)
                      }
                    />
                  ) : null}
                </Col>
              </Row>
            </>
          )}
          <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
            <Col xs={12}>
              <div
                className="Axobt BanneDashboard"
                style={{ lineHeight: "40px" }}
              >
                <div style={{ paddingLeft: "10px", lineHeight: "40px" }}>
                  <AxoLocal
                    entity="updategetAccountName8"
                    defaultValue="Privatlivspolitik"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {renderEditor ? (
                <MinyTinyMCE
                  text={userSettings.paymentPrivacyPolicy}
                  height={250}
                  locale={userSettings.locale}
                  setupEditor={this.dummyFunction}
                  onTextChange={(content) =>
                    this.updatePropertyDebounced(
                      "paymentPrivacyPolicy",
                      content
                    )
                  }
                />
              ) : null}
            </Col>
          </Row>
          {showSubscriptionTerms && (
            <>
              <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <Col xs={12}>
                  <div
                    className="Axobt BanneDashboard"
                    style={{ lineHeight: "40px" }}
                  >
                    <div style={{ paddingLeft: "10px", lineHeight: "40px" }}>
                      Abonnementsbetingelser
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {renderEditor ? (
                    <MinyTinyMCE
                      text={userSettings.paymentSubscriptionTerms}
                      height={250}
                      locale={userSettings.locale}
                      setupEditor={this.dummyFunction}
                      onTextChange={(content) =>
                        this.updatePropertyDebounced(
                          "paymentSubscriptionTerms",
                          content
                        )
                      }
                    />
                  ) : null}
                </Col>
              </Row>
            </>
          )}
        </Grid>
      </div>
    );
  }
}
