import React from "react";

import { ButtonGroup, ButtonToolbar, Modal, Table } from "react-bootstrap";
import ContactService from "../../services/ContactService";
import { Icon, Flexbox, AsyncButton } from "../../utilities/LexUtilities";

import LexButton from "../LexButton";

export default class DepartmentsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      confirmDeleteDepartmentId: 0,
      callback: () => {},
    };
  }

  close = () => {
    let { callback } = this.state;

    callback();

    this.setState({
      showModal: false,
      callback: () => {},
    });
  };

  open = (callback) => {
    this.setState({
      showModal: true,
      callback: callback || (() => {}),
    });
  };

  render() {
    let { departmentOwner, doRemoveDepartment } = this.props;
    let { confirmDeleteDepartmentId } = this.state;

    let departments = departmentOwner.departments || [];

    return (
      <Modal
        className="center-dialog"
        show={this.state.showModal}
        onHide={this.close}
      >
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
          }}
          closeButton
        >
          <Modal.Title>
            Afdelinger af {ContactService.getContactFullName(departmentOwner)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="editable"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
          }}
        >
          <Table condensed hover>
            <tbody>
              {departments.map((d) => (
                <tr key={d.id}>
                  <td>
                    <div className="largeText">
                      {ContactService.getContactFullName(d)}
                      {confirmDeleteDepartmentId === d.id && (
                        <Flexbox>
                          <Flexbox alignCenter className="rightPadding ">
                            <div>Fjern afdeling?</div>
                          </Flexbox>
                          <ButtonToolbar>
                            <AsyncButton
                              onClick={async () => {
                                let result = await doRemoveDepartment(
                                  departmentOwner,
                                  d
                                );
                                if (!result) {
                                  return false;
                                }
                                this.setState({ confirmDeleteDepartmentId: 0 });
                                return true;
                              }}
                            >
                              Ok
                            </AsyncButton>
                            <LexButton
                              onClick={() =>
                                this.setState({ confirmDeleteDepartmentId: 0 })
                              }
                            >
                              Fortryd
                            </LexButton>
                          </ButtonToolbar>
                        </Flexbox>
                      )}
                    </div>
                  </td>
                  <td>
                    <Icon
                      className="axored"
                      role="button"
                      glyph="icon-fontello-scissors"
                      onClick={() =>
                        this.setState({ confirmDeleteDepartmentId: d.id })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <LexButton onClick={this.close}>
                <Icon glyph="icon-fontello-ok" />
                &nbsp; Luk
              </LexButton>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
