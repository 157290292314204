import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Select from "react-select";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import {
  LexButton,
  Flexbox,
  AxoDateTime,
  AxoLocal,
  // getText
} from "../../../utilities/LexUtilities";

export default class FiscalYearForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    startValue: PropTypes.object,
    isFirst: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    if (props.startValue) {
      this.state = {
        ...props.startValue,
        settingReceiptNumbers: false,
      };
    } else {
      var today = new Date();
      this.state = {
        name: "",
        vatPeriod: "Quarter",
        receiptNumberStart: 0,
        startDate: moment(today).format(),
        settingReceiptNumbers: false,
      };

      this.state.endDate = moment(
        new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
      ).format();
    }
  }

  resetReceiptNumbers = () => {
    this.setState({
      receiptNumberStart: 1,
      settingReceiptNumbers: true,
    });
  };

  validateFiscalYear = () => {
    let { isFirst } = this.props;
    let { name, receiptNumberStart, settingReceiptNumbers } = this.state;

    if (
      !name ||
      ((isFirst || settingReceiptNumbers) && receiptNumberStart < 1)
    ) {
      return false;
    }

    return true;
  };

  onUpdateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  getVatPeriodOptions = () => {
    let { getVatPeriodName } = this.props;

    return [
      { value: "Quarter", label: getVatPeriodName("Quarter") },
      { value: "HalfYear", label: getVatPeriodName("HalfYear") },
      { value: "Month", label: getVatPeriodName("Month") },
      { value: "Year", label: getVatPeriodName("Year") },
    ];
  };

  render() {
    let { onSubmit, onCancel, isFirst } = this.props;

    let {
      name,
      startDate,
      endDate,
      vatPeriod,
      receiptNumberStart,
      settingReceiptNumbers,
    } = this.state;

    let vatPeriodOptions = this.getVatPeriodOptions();

    return (
      <Form>
        <Flexbox responsive collapseXS>
          <FormGroup
            controlId="name"
            style={{ margin: "0px", paddingRight: "15px" }}
          >
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />
            </ControlLabel>
            <FormControl
              type="text"
              className="borderFormControlfocus"
              value={name}
              onChange={(event) =>
                this.onUpdateProperty("name", event.target.value)
              }
            />
          </FormGroup>
          <FormGroup
            controlId="startDate"
            style={{ margin: "0px", paddingRight: "15px" }}
          >
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="axoidcode87" defaultValue={"Startdato"} />
            </ControlLabel>
            <AxoDateTime
              id="startDate"
              value={moment(startDate)}
              inputProps={{ disabled: !isFirst }}
              utc
              dateFormat={true}
              timeFormat={false}
              onChange={(dateTime) =>
                this.onUpdateProperty("startDate", dateTime.format())
              }
            />
          </FormGroup>
          <FormGroup
            controlId="endDate"
            style={{ margin: "0px", paddingRight: "15px" }}
          >
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="axoidcode88" defaultValue={"Slutdato"} />
            </ControlLabel>
            <AxoDateTime
              id="endDate"
              value={moment(endDate)}
              utc
              dateFormat={true}
              timeFormat={false}
              onChange={(dateTime) =>
                this.onUpdateProperty("endDate", dateTime.format())
              }
              isValidDate={(currentDate) => {
                let endOfMonth = moment(currentDate).endOf("month");
                return currentDate.date() === endOfMonth.date();
              }}
            />
          </FormGroup>
          <FormGroup
            controlId="vatPeriod"
            className="onTop rightPadding"
            style={{ margin: "0px" }}
          >
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="axoidcode106" defaultValue={"Momsperiode"} />
            </ControlLabel>
            <Select
              name="vatPeriodSelect"
              menuPlacement="auto"
              // style={{ width: '200px' }}
              value={vatPeriodOptions.find((v) => v.value === vatPeriod)}
              onChange={(selection) => {
                if (!!selection) {
                  this.onUpdateProperty("vatPeriod", selection.value);
                }
              }}
              noOptionsMessage={() => ""}
              options={vatPeriodOptions}
            />
          </FormGroup>
          {!isFirst && !settingReceiptNumbers ? (
            <FormGroup className="rightPadding" style={{ margin: "0px" }}>
              <ControlLabel
                style={{
                  visibility: "hidden",
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="axoidcode92" defaultValue={"Buttons"} />
              </ControlLabel>
              <ButtonToolbar>
                <LexButton onClick={this.resetReceiptNumbers}>
                  <AxoLocal
                    entity="axoidcode122"
                    defaultValue={"Nulstil bilagsnumre"}
                  />
                </LexButton>
              </ButtonToolbar>
            </FormGroup>
          ) : null}
          {isFirst || settingReceiptNumbers ? (
            <FormGroup
              controlId="name"
              style={{ margin: "0px", paddingRight: "15px" }}
            >
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  entity="axoidcode123"
                  defaultValue={" Start bilagsnummer"}
                />
              </ControlLabel>
              <FormControl
                type="number"
                className="borderFormControlfocus"
                value={isNaN(receiptNumberStart) ? "" : receiptNumberStart}
                onChange={(event) =>
                  this.onUpdateProperty(
                    "receiptNumberStart",
                    event.target.value
                  )
                }
              />
            </FormGroup>
          ) : null}
        </Flexbox>
        <FormGroup>
          {/* <ControlLabel style={{ visibility: 'hidden', textAlign: 'left', fontSize:'16px', fontWeight:'normal' }}>
            <AxoLocal entity='axoidcode92' defaultValue={'Buttons'}/>
          </ControlLabel> */}
          <ButtonToolbar>
            <LexButton
              disabled={!this.validateFiscalYear()}
              onClick={() => onSubmit(this.state)}
            >
              <AxoLocal
                entity="friendRequestTimelineonApproveRequest"
                defaultValue={"Godkend"}
              />
            </LexButton>
            <LexButton onClick={onCancel}>
              <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
            </LexButton>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    );
  }
}
