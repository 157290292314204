import React from "react";

const EnglishLicense = (props) => {
  return (
    <div>
      <div>
        <div>
          <strong>License for Axolex software (Subscription)</strong>
        </div>
        <div>
          This License Agreement is made between Axolex ApS (Licensor, CVR. No.
          39076969) and Users / Partners (Licensee) of this software. The
          subscription terms set forth in this license apply to all users of the
          licensor's systems. By using our software, the terms of this agreement
          will prevail. Licensee will retain all rights to the content stored on
          the system. These terms apply from the date of the agreement.
        </div>
      </div>
      <div>
        <div>
          <strong>§1. General agreement</strong>
        </div>
        <div>
          <strong>PCS. 1. Law choice</strong>
        </div>
        <div>
          The agreement is made with Axolex ApS in Denmark and is subject to
          Danish and European law.
        </div>
      </div>
      <div>
        <div>
          <strong>PCS. 2. Privacy.</strong>
        </div>
        <div>
          All private and personal information supplied at axolex.com is subject
          to the Axolex Privacy Policy. Personal data is stored within the EU,
          and Axolex follows the terms of the EU General Data Protection
          Regulation.
        </div>
      </div>
      <div>
        <div>
          <strong>PCS. 3. Validity</strong>
        </div>
        <div>
          The terms of this agreement applies to users who possess a valid
          license, and are at least 18 years old.
        </div>
        <div>
          You can use the software if you have a valid license, are over 18
          years old and capable of entering into legally binding agreements.
        </div>
      </div>
      <div>
        <div>
          <strong>PCS. 4. Area of use</strong>
        </div>
        <div>
          The Licensee will have access to the application from all areas of the
          world. However, Axolex does not guarantee that all features will be
          available in any specific country. The Licensee can provide feedback
          on how the system can be made more user friendly within the desired
          usage area.
        </div>
      </div>
      <div>
        <div>
          <strong>PCS. 5. Changes</strong>
        </div>
        <div>
          Axolex reserves the right to change the terms of this agreement and to
          terminate the right of use to the extent that geographical or other
          external circumstances cause us to be unable to ensure that your data
          are adequately protected. If the cause is not the responsibility of
          Axolex, the subscription will not be terminated unless the Licensee
          chooses to cancel the subscription.
        </div>
      </div>
      <div>
        <div>
          <strong>PCS. 6. Disputes</strong>
        </div>
        <div>
          In the event of a dispute, venue will take place in the city where the
          Licensor is headquartered. This provision may be waived by agreement.
        </div>
      </div>
      <div>
        <div>
          <strong>§2. Delivery obligation for Axolex</strong>
        </div>
        <div>
          Axolex ApS undertakes to maintain the system's operational stability
          and remedy defects within a reasonable time horizon. In the event of
          unusual operating changes to the system, Axolex will inform the
          Licensee as soon as possible, to safeguard the interests of the
          licensee.
        </div>
        <div>
          With regards to the free version of the software, Axolex ApS accept no
          liability for errors and omissions in the system.
        </div>
      </div>
      <div>
        <div>
          <strong>
            §3. Licensee's confidential information and data security
          </strong>
        </div>
        <div>
          <strong>PCS. 1. Ownership</strong>
        </div>
        <div>
          Licensee retains the full rights to all information and content stored
          in the system. Axolex makes no use of this information and content,
          either for disclosure or resale, but reserves the right to determine
          the details of how the data are stored, in order to ensure data
          security. If it becomes necessary to take steps to change data
          storage, the Licensee will be informed of this.{" "}
        </div>
        <div>
          <strong>PCS. 2. Confidentiality</strong>
        </div>
        <div>
          Axolex ApS accepts duty of confidentiality with regards to the
          Licensee’s data, and is not entitled to disclose any information to
          third parties unless valid consent from the Licensee is obtained.{" "}
        </div>
        <div>
          <strong>PCS. 3. Data security</strong>
        </div>
        <div>
          Axolex is obligated to store the Licensee's data in a confidential and
          secure manner. Data is currently stored safely in the cloud on
          Microsoft's Azure network, within the EU, and particularly sensitive
          data is stored in encrypted form. All information entered in the
          Axolex system is transmitted encrypted and the website security is
          approved by Go Daddy Root Certificate Authority, which has partnered
          with Microsoft.{" "}
        </div>
        <div>
          <strong>PCS. 4. Sharing Licensee's Data</strong>
        </div>
        <div>
          Licensee is fully responsible for the sharing their own data and
          content.{" "}
        </div>
        <div>
          <strong>PCS. 5. Backup of data</strong>
        </div>
        <div>
          Backup of data is performed regularly, to protect against data loss.{" "}
        </div>
      </div>
      <div>
        <div>
          <strong>§4. Account information</strong>
        </div>
        <div>
          Licensee is fully responsible for all activities undertaken through
          the licensee's account. If Licensee suspects that unauthorized use of
          the account has occurred, Licensee is obligated to inform Axolex ApS
          as soon as possible. Axolex Administrators reserve the right to access
          Licensee’s account information when managing Licensee’s access to the
          application.
        </div>
      </div>
      <div>
        <div>
          <strong>§5. Rights of use</strong>
        </div>
        <div>
          <strong>PCS. 1. Application of the subscription.</strong>
        </div>
        <div>
          The subscription provides a full-use license for all features of the
          Axolex system, in the absence of any special agreement. Axolex will
          continuously update and expand the system's functionality. New
          features will automatically be covered by the right of use. This right
          of use also extends to Licensee’s employees, if Licensee has purchased
          a group license.{" "}
        </div>
        <div>
          <strong>PCS. 2. Personal License</strong>
        </div>
        <div>
          The license is personal and the licensee is not entitled to resell the
          right of use to any other person or for any other commercial purpose.
          Licensor's system is protected by copyright law. Violation or abuse of
          these rights may result in closing of the account, as well as other
          legal proceedings.
        </div>
        <div>
          <strong>PCS. 3. Business license</strong>
        </div>
        <div>
          In the case where the licensee is a group-related company, the license
          can be transferred to other group-related companies, provided that the
          Licensor is notified.
        </div>
      </div>
      <div>
        <div>
          <strong>§ 6. Violation</strong>
        </div>
        <div>
          The parties to the agreement may terminate the contract at any time
          without notice of termination if the counterparty has breached the
          contract substantially. In the case of minor non-compliance, a written
          warning will be provided. Significant breach may exist, for example,
          in cases where the licensee requests bankruptcy and the rules of
          applicable bankruptcy laws will not therefore preclude this provision.
        </div>
        <div>
          <strong>PCS. 2. Repeal as a result of default</strong>
        </div>
        <div>
          The party wishing to terminate the agreement as a result of breach
          shall notify the counterparty in writing. The party will then be
          permitted to terminate the agreement unless the breach is rectified
          within seven days of the notification.{" "}
        </div>
      </div>
      <div>
        <div>
          <strong>§7. Licensee's other obligations</strong>
        </div>
        <div>
          As the system is designed with the purpose of helping professionals
          establish professional networks, it is strictly prohibited to market
          services outside the Licensee's profession, unless an agreement is
          made with the Licensor. Violation of this provision may result in
          exclusion from the professional network. This will not affect use of
          other features of the system.
        </div>
      </div>
      <div>
        <div>
          <strong>§8. Subscription Duration</strong>
        </div>
        <div>
          The agreement is an ongoing agreement. The Licensee is not bound by
          the agreement beyond the expiration of the next payment term.
        </div>
      </div>
      <div>
        <div>
          <strong>§9. Licensee's payment obligation</strong>
        </div>
        <div>
          After the expiration of any trial period, payment will be charged
          prior to the coming payment term. The subscription price is valid 2
          years from the subscription. The price can then be adjusted by Axolex.
        </div>
      </div>
      <div>
        <div>
          <strong>§ 10. Termination</strong>
        </div>
        <div>
          Termination of the license subscription may occur from within the
          system or by written notification to the licensor.
        </div>
      </div>
      <div>
        <div>
          <strong>§11. Reservations</strong>
        </div>
        <div>
          Licensor reserves the right to terminate the agreement at any time
          with six months notice if it becomes necessary to shut down the
          system. With regards to the free version, the Licensor reserves the
          right to terminate the subscription with one months’ notice, in the
          interest of maintaining operational capacity. In the event that this
          occurs, Licensor can change to a paid subscription, to continue using
          the system.
        </div>
      </div>
      <div>
        <div>
          <strong>§12. Intellectual property rights</strong>
        </div>
        <div>
          All intellectual property rights relating to the system belong to
          Axolex ApS, unless a special license explicitly specifies otherwise.
        </div>
      </div>
      <div>
        <div>
          <strong>§13. Support</strong>
        </div>
        <div>
          Licensee has access to full IT support in connection with the use of
          the system's functionalities in the licensor's standard system and
          standard subscription agreement. Special agreements apply to
          customized systems.
        </div>
      </div>
    </div>
  );
};

const DanishLicense = (props) => {
  return (
    <div>
      <div>
        <div>
          <strong>Licens for Axolex software (Abonnement)</strong>
        </div>
        <div>
          Denne Licensaftale er indgået mellem Axolex ApS (Licensgiver, CVR. Nr.
          39076969) og brugere / partnere (Licenstager) af denne software. De
          abonnementsbetingelser der fremgår af denne licens gælder for brugere
          af licensgivers systemer samlet. Ved brug af vores software vil
          vilkårene i den pågældende aftale have forrang, dog bibeholder
          Licenstager alle rettigheder til det indhold der opbevares på
          systemet. Disse vilkår gælder fra datoen for aftalens indgåelse.
        </div>
      </div>
      <div>
        <div>
          <strong>§1. Almen aftale</strong>
        </div>
        <div>
          <strong>stk. 1. Lovvalg</strong>
        </div>
        <div>
          Aftalen er indgået med Axolex ApS i Danmark, og er underlagt dansk og
          europæisk lovgivning.
        </div>
      </div>
      <div>
        <div>
          <strong>stk. 2. Beskyttelse af personlige oplysninger.</strong>
        </div>
        <div>
          Beskyttelse af personlige oplysninger på axolex.com og axolex.dk. For
          de personlige oplysninger du giver os som bruger af tjenesten
          accepterer du vores Privatlivspolitik om beskyttelse af personlige
          oplysninger. Personoplysninger opbevares inden for EU og Axolex lever
          op til EU persondataforordningens bestemmelser.
        </div>
      </div>
      <div>
        <div>
          <strong>stk. 3. Gyldighed</strong>
        </div>
        <div>
          Du må bruge softwaren hvis du har en gyldig licens, er over 18 år
          gammel og myndig.
        </div>
      </div>
      <div>
        <div>
          <strong>stk. 4. Brugsområde.</strong>
        </div>
        <div>
          Du har adgang til applikationen fra alle steder i verden. Dette
          garanterer dog ikke at alle funktioner vil være til rådighed i de land
          hvor du anvender softwaren. Du kan dog til ethvert tidspunkt give os
          feedback til hvordan vi kan gøre systemet mere brugervenligt inden for
          det geografiske områder hvor du benytter systemet.
        </div>
      </div>
      <div>
        <div>
          <strong>stk. 5. Ændringer</strong>
        </div>
        <div>
          Axolex forbeholder sig ret til at ændre betingelserne i denne aftale,
          samt at afbryde brugsretten i det omfang at geografiske eller andre
          udefrakommende omstændigheder gør at vi ikke er i stand til at sikre
          at dine data er tilstrækkeligt beskyttede. Såfremt årsagen ikke er
          Axolex’ ansvar, vil licensbetalingen ikke blive stoppet, med mindre
          Licenstager vælger at afbryde abonnementet.
        </div>
      </div>
      <div>
        <div>
          <strong>stk. 6. Tvister</strong>
        </div>
        <div>
          I tilfælde af at der opstår tvist, vil værneting finde sted i den by
          hvor Licensgiver har hovedsæde. Denne bestemmelse kan fraviges ved
          aftale.
        </div>
      </div>
      <div>
        <div>
          <strong>§2. Leveringsforpligtelse for Axolex</strong>
        </div>
        <div>
          Axolex ApS forpligter sig til at vedligeholde systemets
          driftsstabilitet og afhjælpe fejl og mangler inden for en rimelig
          tidshorisont. Såfremt der sker usædvanlige driftsændringer af
          systemet, vil Axolex straks underrette brugerne, og dermed varetage
          brugernes interesse.
        </div>
        <div>
          For den fri version af softwaren tager Axolex forbehold for fejl og
          mangler.
        </div>
      </div>
      <div>
        <div>
          <strong>
            §3. Licenstagers fortrolige oplysninger og datasikkerhed
          </strong>
        </div>
        <div>
          <strong>stk. 1. Ejerskab</strong>
        </div>
        <div>
          Licenstager bevarer de fulde rettigheder til alle oplysninger og
          indhold som opbevares i systemet. Axolex gør ikke nogen form for brug
          af disse oplysninger og indhold, hverken ved videregivelse eller
          videresalg, men forbeholder sig retten til at håndtere placeringen af
          disse informationer, for at kunne sikre tjenestens sikre opbevaring af
          disse data, i IT-teknisk omfang. Såfremt der sker ændringer i
          datahåndteringen, vil brugeren blive underrettet om dette.{" "}
        </div>
        <div>
          <strong>stk. 2. Tavshedspligt</strong>
        </div>
        <div>
          Axolex ApS har tavshedspligt over for indholdet af licenstagers
          oplysninger, og er ikke berettiget til at videregive nogle
          informationer til tredjemand, med mindre der foreligger et gyldigt
          samtykke fra Licenstager.
        </div>
        <div>
          <strong>stk. 3. Datasikkerhed</strong>
        </div>
        <div>
          Axolex forpligter sig til at opbevare Licenstagers data på fortrolig
          og forsvarlig vis. Data opbevares på nuværende tidspunkt sikkert i
          skyen på Microsofts Azure netværk inden for EU, og særlig følsom data
          opbevares krypteret. Al information der indtastes i Axolex system
          sendes krypteret og hjemmesiden er godkendt af Go Daddy Root
          Certificate Authority, som har indgået samarbejde med Microsoft.
        </div>
        <div>
          <strong>stk. 4. Deling af Licenstagers data</strong>
        </div>
        <div>
          Licenstager har det fulde ansvar for deling af deres egne data og
          indhold.
        </div>
        <div>
          <strong>stk. 5. Backup af data</strong>
        </div>
        <div>Backup af data sker løbende.</div>
      </div>
      <div>
        <div>
          <strong>§4. Kontoplysninger</strong>
        </div>
        <div>
          Licenstager har det fulde ansvar for alle aktiviteter, som foretages
          via licenstagers konto. Såfremt Licenstager mistænker at der er sket
          uautoriseret brug af kontoen, har Licenstager pligt til straks at
          informere Axolex ApS om dette. Axolex’ administratorer forbeholder sig
          ret til at håndtere dine kontoinformationer, i forbindelse med
          administration af din adgang til applikationen.
        </div>
      </div>
      <div>
        <div>
          <strong>§5. Brugsret</strong>
        </div>
        <div>
          <strong>stk. 1. Abonnementets anvendelse.</strong>
        </div>
        <div>
          Abonnementet giver licens til fuldt gyldig anvendelsesbrugsret til
          Axolex’ fuldt funktionelle system, såfremt der ikke foreligger nogen
          særaftale. Axolex vil I vidt omfang løbende opdatere og udvide
          systemets funktionalitet. Sådanne nye funktioner vil automatisk være
          omfattet af brugsretten. Denne brugsret udvides også til at omfatte
          Licenstagers ansatte, såfremt der er betalt for en gruppelicens.
        </div>
        <div>
          <strong>stk. 2. Personlig licens</strong>
        </div>
        <div>
          Licensen er personlig, og licenstager har ikke ret til at videresælge
          brugsretten til nogen anden person, eller til noget andet kommercielt
          formål. Licensgivers system er beskyttet af loven om ophavsret.
          Krænkelse eller misbrug af disse rettigheder, kan medføre lukning af
          kontoen, samt andre eventuelle retsfølger.
        </div>
        <div>
          <strong>stk. 3. Virksomhedslicens</strong>
        </div>
        <div>
          I det tilfælde hvor licenstager er koncernforbundet virksomhed, kan
          licensen overdrages til andre koncernforbundne virksomheder, forudsat
          at dette meddeles til licensgiver.
        </div>
      </div>
      <div>
        <div>
          <strong>§ 6. Misligholdelse</strong>
        </div>
        <div>
          Aftalens parter kan hæve kontrakten på ethvert tidspunkt, uden
          opsigelsesvarsel, såfremt modparten har misligholdt kontrakten
          væsentligt. Ved mindre væsentlig misligholdelse, vil der blive givet
          en skriftlig advarsel. Væsentlig misligholdelse kan for eksempel
          foreligge i tilfælde hvor licenstager begærer konkurs, og
          konkurslovens regler vil dermed ikke være til hinder for denne
          bestemmelse.
        </div>
        <div>
          <strong>stk. 2. Ophævelse som følge af misligholdelse</strong>
        </div>
        <div>
          Den part som ønsker at ophæve aftalen som følge af misligholdelse skal
          skriftligt meddele dette til modparten. Aftalen vil derefter kunne
          afbrydes, med mindre misligholdelsen er ophørt inden for syv dage fra
          afsendelsen af påkravet.
        </div>
      </div>
      <div>
        <div>
          <strong>§7. Licenstagers øvrige forpligtelser</strong>
        </div>
        <div>
          Det er forbudt at anvende systemet til at markedsføre
          ydelser der ligger uden for Licenstagers erhverv, med mindre der
          foreligger samtykke fra Licensgiver.
        </div>
      </div>
      <div>
        <div>
          <strong>§8. Abonnementets varighed</strong>
        </div>
        <div>
          Aftalen har natur af en løbende aftale.
          Opsigelse skal fra Licenstagers side ske med tre måneders varsel til udgangen af en måned.
        </div>
      </div>
      <div>
        <div>
          <strong>§9. Licenstagers betalingspligt</strong>
        </div>
        <div>
          Efter udløbet af en eventuel prøveperiode, vil betaling blive trukket
          forud for den kommende betalingsperiode. Prisen på abonnementet gælder
          til enhver tid for det abonnement der er indgået på tidspunktet og 2
          år fremad. Derefter justeres prisen ved aftale.
        </div>
      </div>
      <div>
        <div>
          <strong>§ 10. Opsigelse</strong>
        </div>
        <div>
          Opsigelse kan foregå via systemet, eller ved skriftlig meddelelse til
          licensgiver.
        </div>
        <div>
          Opsigelse skal ske med tre måneders varsel til udgangen af en måned.
        </div>
      </div>
      <div>
        <div>
          <strong>§11. Forbehold</strong>
        </div>
        <div>
          Licensgiver forbeholder sig retten til på ethvert tidspunkt at afbryde
          aftalen med seks måneders varsel, såfremt det bliver nødvendigt at
          nedlægge systemet. For den fri version, forbeholder Licensgiver sig
          ret til at afbryde abonnementet med en måneds varsel af hensyn til
          driftskapaciteten. Brugeren kan derefter skifte til et betalt
          abonnement.
        </div>
      </div>
      <div>
        <div>
          <strong>§12. Immaterialrettigheder</strong>
        </div>
        <div>
          Alle immaterielle rettigheder vedrørende systemet tilhører Axolex ApS,
          med mindre en særlig licens udtrykkeligt specificerer andet.
        </div>
      </div>
      <div>
        <div>
          <strong>§13. Support</strong>
        </div>
        <div>
          Licenstager har adgang til fuld IT-support i forbindelse med brug af
          systemets funktionaliteter i licensgivers standardsystem og standard
          abonnementsaftale. Skræddersyede IT-systemer følger en særaftale.
        </div>
      </div>
    </div>
  );
};

const ChineseLicense = (props) => {
  return (
    <div>
      <div>
        {" "}
        软件使用协议
        本协议由芯法软件（CVR。编号39076969）与软件的用户/合作伙伴（简称用户）签订。本协议中规定的订购条款适用于系统的用户。通过使用我们的软件，协议的条款有优先效力。
        芯法软件注明协议书并向用户提供注册码视为用户同意按照本协议规定使用芯法律师办案解决方案软件。从芯法网站注册用户账号.{" "}
      </div>
      <div>
        一 ， 软件名称：芯法律师办案解决方案高级整套版
        芯法软件授予用户软件产品的使用权，双方在平等互利的基础上，遵循诚实信用的原则，通过友好协商达成如下使用协议:{" "}
      </div>
      <div>
        {" "}
        1. 用户的机密信息和数据安全
        芯法软件保护注册用户个人资料及其所发布的信息。芯法软件不对外公开或向第三方提供用户的注册资料以及在使用本网站服务时所提供的其他非公开信息内容。但保留处理这些信息储存位置的权利，以确保IT技术范围内的数据安全存储。如果这是必要的，芯法软件应通知用户处理他们的数据。
      </div>
      <div>
        2.数据安全
        芯法软件承诺保持用户的数据保密和可靠，数据目前安全地存储在微软Azure网络的云端，特别敏感的数据被加密存储。在Axolex系统中输入的所有信息都经过加密发送，并由Go
        Daddy根证书颁发机构（已与Microsoft合作）批准网站。
      </div>
      <div>
        3.共享的数据
        用户完全负责自己在系统内与其他专业人士分享的数据和内容，用户将保留对系统中存储的内容的所有权利。{" "}
      </div>
      <div> 4.数据备份 数据陆续备份。 </div>
      <div>
        二、账户信息
        用户需对自己的账户信息和所有的活动遵循诚实信用使用的原则，如果用户怀疑其他人未经授权使用该帐户，
        或误用，用户有义务立即通知芯法软件。芯法软件管理员将保留处理您的帐户信息与管理你对应用程序的访问权限的权利。{" "}
      </div>
      <div>
        三、软件版权及使用权
        本协议许可的是软件使用权，许可使用的软件产品版权属芯法软件公司所有，并受丹麦和欧洲有关法律、法规的保护。用户按本协议条款规定支付合软件产品的全部软件使用许可月/年费，芯法软件授予用户上述软件产品的合法使用权。
      </div>
      <div>
        {" "}
        四、 隐私 隐私政策 你在axolex.com和axolex.dk
        提供给我们的用户信息属个人信息，您同意我们的隐私政策。
      </div>
      <div>五、交货地点、方式， 使用范围</div>
      <div>1、交货地点：芯法软件公司注册所在地。</div>
      <div>
        2、交货方式：在线云端使用。 3、使用范围
        用户可以从世界任何地方访问应用程序，但是，鉴于用户计算机软硬件,
        网络的差异性和复杂性，芯法软件所提供的各项功能和准确性不能保证在任何情况下都能正常执行或达到用户所期望的结果，用户可以随时向芯法软件反馈如何使系统和在使用系统的地理区域内，
        以便合作。芯法软件对上述的后果，包括用户的直接经济损失和间接经济损失，不负责任。
      </div>
      <div>
        4. 变化
        如果地理或其他外部环境导致我们无法确保您的信息和信息得到充分保护，芯法软件保留更改本协议条款和终止使用权的权利。如果原因不是芯法软件的责任，许可费将不会被终止，除非用户选择取消订阅。{" "}
      </div>
      <div>六、限制规定 </div>
      <div>
        1、芯法软件许可用户使用的软件产品，只限于用户本身使用，未经芯法软件书面同意，用户不得将协议项下的权利或信息转让给任何第三方，包括用户的关联公司。{" "}
      </div>
      <div>
        2.
        用户有妥善保管账户信息，和禁止非指定人员使用许可软件的义务。未经芯法软件公司书面授权，用户不得将授予的软件使用权进行出租、销售、转让或非存档目的的拷贝及其他商业用途，由于用户违反上述义务，造成芯法软件损失，用户应按本协议承担责任。{" "}
      </div>
      <div>
        3. 用户的其他义务
        由于系统的结构是为了帮助专业人员建立专业网络，除非得到书面协议，否则严格禁止在用户的专业之外进行服务。违反这条例可能会导致用户被排除专业网络系统。这不会影响使用系统的其余部分。{" "}
      </div>
      <div>
        七、服务
        为了确保许可软件产品实时处于最新版本状态，芯法软件应随时对该软件产品进行升级换版本软件用户享有陆续更新服务，服务内容为解决用户使用软件过程中由于软件自身出现的问题或操作不当等问题提供电话咨询服务。
      </div>
      <div>
        八、违约责任　 如果用户违反本协议，芯法软件有权终止本协议
        恕不另行通知。如果发现轻微不遵守规定，将给予书面警告。例如，在用户要求破产的情况下，“破产法”的规定不会排除这一条例。
      </div>
      <div>九、 因违约而废除 </div>
      <div>
        {" "}
        因违约而终止协议的一方应书面通知对方，协议将被无条件终止，除非违约行为终止。
      </div>
      <div>
        十、解决纠纷方式
        所有由本协议产生的争议，双方应当友好协商解决。如不能通过友好协商解决争议，任何诉诸于诉讼程序的法律争议，将以芯法软件工商注册所在地法院为管辖法院.{" "}
      </div>
      <div>
        十一、本协议经用户点击接受后即时生效。 芯法软件网站各类作品
        均出于为用户有益法律的信息之目的，并不意味着赞同其观点或证实其内容的真实性，芯法软件不对其科学性、严肃性等作任何形式的保证，其仅代表作者本人的观点，不代表芯法软件的观点和看法，与芯法软件立场无关，用户因使用芯法软件联谊网络服务而产生的一切后果由用户自己承担，芯法软件不承担任何相关责任。{" "}
      </div>
      <div>
        十二， 终止
        鉴于网络的特殊性，芯法软件有权随时变更、中断或终止用户的部分或全部的网络服务，可另行通知用户，无需对任何用户或任何第三方承担任何责任，终止只可以通过系统或通过书面通知用户。
      </div>
      <div>
        十三， 免责声明
        如果需要关闭系统，芯法软件有权随时取消对用户的软件使用许可。芯法软件取消许可软件的使用许可应当提前6个月书面通知用户。{" "}
      </div>
      <div>
        十四， 知识产权
        与该系统相关的所有知识产权均属于芯法软件所有，除非特殊许可另有明确书面授权规定。{" "}
      </div>
      <div> </div>
      <div> </div>
    </div>
  );
};

export { EnglishLicense, DanishLicense, ChineseLicense };
