import React from "react";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";

var theme = {
  container: {
    position: "relative",
  },
  input: {
    maxWidth: 240,
    height: 30,
    padding: "10px 20px",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 12,
    border: "1px solid #aaa",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 30,
    width: 350,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
    maxHeight: "500px",
    overflowY: "auto",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#e6e6e6",
  },
};

export default class EmailAutoComplete extends React.PureComponent {
  static propTypes = {
    emailSuggestions: PropTypes.array.isRequired, //[{ displayName: 'Simon', email: 'simonchris1729@gmail.com'}]
    handleSelectedOption: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      suggestions: [], //[{ displayName: 'Simon', email: 'simonchris1729@gmail.com'}]
    };
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let { emailSuggestions } = this.props;

    return inputLength === 0
      ? []
      : emailSuggestions.filter((suggestion) => {
          let text = (suggestion.displayName || "") + suggestion.email;
          return text.toLowerCase().includes(inputValue);
        });
  };

  //Value in input element
  getSuggestionValue = (suggestion) => suggestion.email;

  //Value in dropdown
  renderSuggestion = (suggestion) => (
    <div>
      "{suggestion.displayName || suggestion.email}"{" "}
      {"<" + suggestion.email + ">"}
    </div>
  );

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    let { handleSelectedOption } = this.props;

    handleSelectedOption(suggestion);
  };

  shouldRenderSuggestions(value) {
    return value.trim().length > 0;
  }

  render() {
    let { value, onChange } = this.props;

    const inputProps = {
      className: "searchbox",
      value,
      disabled: !!this.props.disabled,
      onChange: onChange,
    };

    if (this.props.inputBorder) {
      theme.input.border = this.props.inputBorder;
    }
    if (this.props.maxWidth) {
      theme.input.maxWidth = this.props.maxWidth;
      theme.input.display = "block";
      theme.input.width = "100%";
    }

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        theme={theme}
      />
    );
  }
}
