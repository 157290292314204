import React from "react";

import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  FormGroup,
  FormControl,
  InputGroup,
} from "react-bootstrap";

import { AxoLocal } from "../../utilities/LexUtilities";

export default class TextModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      text: "",
      onSubmit: (text) => {},
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = (startText, onSubmit) => {
    this.setState({
      showModal: true,
      text: startText || "",
      onSubmit,
    });
  };

  updateText = (e) => {
    this.setState({ text: e.target.value });
  };

  onOK = () => {
    let { onSubmit, text } = this.state;

    if (!!onSubmit) {
      onSubmit(text);
    }

    this.close();
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onOK();
    }
  };

  render() {
    let { title, placeholder } = this.props;

    return (
      <Modal show={this.state.showModal} onHide={this.close} bsSize="small">
        <Modal.Header className="backgroundModalHeaderFooter" closeButton>
          <Modal.Title>{title || "Skriv tekst"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId="labelName">
            {/* <ControlLabel><AxoLocal entity='socialPrivateConversationBannerConferenceName' defaultValue={'Navn'}/></ControlLabel> */}
            <InputGroup>
              <FormControl
                value={this.state.text}
                autoFocus
                onChange={this.updateText}
                onKeyDown={this.onKeyDown}
                type="text"
                placeholder={placeholder || ""}
              />
            </InputGroup>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.onOK}>
                {
                  <AxoLocal
                    key="caseSave"
                    entity="CaseGridViewcaseSave"
                    defaultValue={"Gem"}
                  />
                }
              </Button>
              <Button className="Lex-button-2" onClick={this.close}>
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
