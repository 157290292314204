import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { DataActions } from "../services/AxoServices";

import { Icon, AxoLocal } from "../utilities/LexUtilities";

import { Table } from "react-bootstrap";

export default class EmploymentContractTableView extends React.PureComponent {
  static propTypes = {
    employmentContracts: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  onDelete = (id) => {
    DataActions.deleteEmploymentContract(id);
  };

  onUpdate = (model) => {
    //Replaces the object with the same id with this object
    DataActions.updateEmploymentContract(model);
  };

  getTypeLabel = (type) => {
    switch (type) {
      case "ManagementContract":
        return "Direktørkontrakt";
      case "OfficeWork":
        return "Funktionær";
      case "FlexibleContract":
        return "Timeløn";
      default:
        return "Funktionær";
    }
  };

  render() {
    let { employmentContracts, onGotoDetails } = this.props;

    return (
      <div className="standardMaxWidth leftPadding">
        <Table bordered condensed hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>
                <AxoLocal entity="AdminTabView51" defaultValue="Virksomhed" />
              </th>
              <th>By</th>
              <th>Stiling</th>
              <th>Ansat</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {employmentContracts.map((model) => (
              <tr
                role="button"
                key={model.id}
                onClick={() => onGotoDetails(model.id)}
              >
                <td>{this.getTypeLabel(model.contractType)}</td>
                <td>{model.employerName}</td>
                <td>{model.employerCity}</td>
                <td>{model.jobTitle}</td>
                <td>{model.workerName}</td>
                <td>{moment.utc(model.contractDate).format("L")}</td>
                <td>
                  <Icon
                    role="button"
                    onClick={() => this.onDelete(model.id)}
                    glyph="icon-fontello-trash-1"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
