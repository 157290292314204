import React from "react";
import moment from "moment";

import UserInfoService from "../../services/UserInfoService";
import InlineEdit from "../../utilities/InlineEdit";

import ReactOption from "../../utilities/ReactOption";
import AxoDateTime from "../../utilities/AxoDateTime";
import TimeEntryService from "../../services/TimeEntryService";

import {
  DocumentSearchBox,
  Icon,
  AxoLocal,
  InlineNumberEdit,
  InlineTextArea,
} from "../../utilities/LexUtilities";

import { Row, Col, Grid, Button } from "react-bootstrap";

export default class TimeEntryForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availableCases: props.cases || [],
      availableClients: props.clients || [],
    };
    this.timeEntryId = props.timeEntry.id;
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.timeEntryId !== nextProps.timeEntry.id ||
      (this.props.cases &&
        this.props.cases.length !== nextProps.cases.length) ||
      (this.props.clients &&
        this.props.clients.length !== nextProps.clients.length)
    ) {
      this.setState({
        availableCases: nextProps.cases,
        availableClients: nextProps.clients,
      });
      this.timeEntryId = nextProps.timeEntry.id;
    }
  }

  onChangeTimeEntry = (timeEntry) => {
    this.props.onTimeEntryUpdate(timeEntry);
  };

  onChangeWorkDate = (dateTime) => {
    this.onChangeProperty("workDate", dateTime.format());
  };

  onDescChange = (input) => {
    this.onChangeProperty("description", input.value);
  };

  onChangeRate = (input) => {
    this.onChangeProperty("rate", input.value);
  };

  onChangeTax = (input) => {
    this.onChangeProperty("tax", input.value / 100);
  };

  onChangeCase = (event) => {
    var caseId = null;
    var availableClients = this.state.availableClients;
    if (event.target.value !== "empty") {
      caseId = parseInt(event.target.value, 10);
      var selectedCase = this.props.cases.find((c) => c.id === caseId);
      availableClients = this.props.clients.filter((c) =>
        selectedCase.clients.find((cl) => cl.id === c.id)
      );
    } else {
      availableClients = this.props.clients;
    }
    this.props.timeEntry.caseModelId = caseId;
    this.onChangeTimeEntry(this.props.timeEntry);
    this.setState({
      availableClients: availableClients,
    });
  };

  onChangeClient = (event) => {
    var clientId = null;
    var availableCases = this.state.availableCases;
    if (event.target.value !== "empty") {
      clientId = parseInt(event.target.value, 10);
      availableCases = this.props.cases.filter((c) =>
        c.clients.find((cl) => cl.id === clientId)
      );
    } else {
      availableCases = this.props.cases;
    }
    this.props.timeEntry.clientId = clientId;
    this.onChangeTimeEntry(this.props.timeEntry);
    this.setState({
      availableCases: availableCases,
    });
  };

  onChangeProperty = (propertyName, value) => {
    this.onChangeTimeEntry({
      ...this.props.timeEntry,
      [propertyName]: value,
    });
  };

  getTitleWithMaxLength(title) {
    if (title.length > 20) {
      return title.substring(0, 20) + "...";
    }
    return title;
  }

  renderClientSelect = () => {
    if (!this.props.clients) {
      return null;
    }
    var clientSelectNodes = this.state.availableClients.map((client) => {
      return (
        <option key={client.id} value={client.id}>
          {this.getTitleWithMaxLength(client.firstName + " " + client.lastName)}
        </option>
      );
    });
    return (
      <Row>
        <Col xs={3}>
          <b>
            <AxoLocal entity="CaseEditFormclients" defaultValue={"Klient"} />
            :&nbsp;
          </b>
        </Col>
        <Col xs={9}>
          <select
            className="axoblue selectbg"
            value={this.props.timeEntry.clientId || "empty"}
            onChange={this.onChangeClient}
          >
            <AxoLocal
              componentClass={ReactOption}
              disabled
              value="empty"
              componentAttribute="text"
              entity="TimeEntryBoxFormnoclientId"
              defaultValue={"Empty"}
            />
            {clientSelectNodes}
          </select>
        </Col>
      </Row>
    );
  };

  renderCaseSelect = () => {
    if (!this.props.cases) {
      return null;
    }
    var caseSelectNodes = this.state.availableCases.map((caseModel) => {
      return (
        <option key={caseModel.id} value={caseModel.id}>
          {this.getTitleWithMaxLength(caseModel.caseNumber)}
        </option>
      );
    });
    return (
      <Row>
        <Col xs={3}>
          <b>
            <AxoLocal
              entity="ContainercaseNumber"
              defaultValue={"Sagsnummer"}
            />
            :&nbsp;
          </b>
        </Col>
        <Col xs={9}>
          <select
            className="axoblue selectbg"
            value={this.props.timeEntry.caseModelId || "empty"}
            onChange={this.onChangeCase}
          >
            <AxoLocal
              componentClass={ReactOption}
              disabled
              value="empty"
              componentAttribute="text"
              entity="TimeEntryBoxFormnoclientId"
              defaultValue={"Empty"}
            />
            {caseSelectNodes}
          </select>
        </Col>
      </Row>
    );
  };

  onChangeExpenseValue = (index, input) => {
    var timeEntry = Object.assign({}, this.props.expenses[index]);
    timeEntry.expenses = parseFloat(input.value);
    this.props.onExpenseUpdate(index, timeEntry);
  };

  onChangeAttachmentText = (index, input) => {
    var timeEntry = Object.assign({}, this.props.expenses[index]);
    timeEntry.description = input.value;
    this.props.onExpenseUpdate(index, timeEntry);
  };

  onAttachDocument = (index, document) => {
    var timeEntry = Object.assign({}, this.props.expenses[index]);
    timeEntry.expenseAttachment = document;
    this.props.onExpenseUpdate(index, timeEntry);
  };

  renderExpenses = () => {
    var expenses = this.props.expenses;
    var expenseNodes = [];
    for (var i = 0; i < expenses.length; i++) {
      var expense = expenses[i];
      expenseNodes.push(
        <Row key={i} className="text-m">
          <Col sm={6} className="nopadding">
            {i > 0 ? (
              <span
                className="Lex-button-2"
                role="button"
                onClick={this.props.onRemoveExpense.bind(this, i)}
              >
                &nbsp;&nbsp;&nbsp;
                <Icon
                  role="button"
                  glyph="icon-fontello-scissors axored"
                />{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            ) : null}
            <b>
              <AxoLocal
                entity="TimeEntryFormExpensesLabel"
                defaultValue="Andre udgifter"
              />
              :{" "}
            </b>
            <InlineNumberEdit
              value={expense.expenses}
              change={this.onChangeExpenseValue.bind(this, i)}
            />
          </Col>
          <Col sm={6} className="nopadding">
            <Icon glyph="icon-fontello-edit-3" />
            &nbsp;
            <b>
              {" "}
              <AxoLocal
                entity="TimeEntryFormnotesA"
                defaultValue={"Noter"}
              />:{" "}
            </b>
            <InlineEdit
              value={expense.description}
              change={this.onChangeAttachmentText.bind(this, i)}
            />
          </Col>
        </Row>
      );
      expenseNodes.push(
        <Row key={"attachment-" + i.toString()} className="text-m">
          <Icon glyph="icon-fontello-attach-7" />
          &nbsp;
          <div style={{ display: "inline-block" }}>
            <AxoLocal
              componentClass={DocumentSearchBox}
              documentMap={this.props.documentMap}
              handleSelectedDocument={this.onAttachDocument.bind(this, i)}
              maxWidth="100%"
              componentAttribute="placeholder"
              entity="TimeEntryFormAttachmentPlaceholder"
            />
          </div>
        </Row>
      );
    }
    return expenseNodes;
  };

  getTimeEntriesTotal = () => {
    var timeEntryTotal = TimeEntryService.getTotalValue(this.props.timeEntry);
    var total =
      timeEntryTotal +
      this.props.expenses.reduce((sum, timeEntry) => {
        return sum + TimeEntryService.getTotalValue(timeEntry);
      }, 0);
    return total;
  };

  render() {
    var { timeEntry } = this.props;

    return (
      <div>
        <Grid fluid className="text-m">
          <Row>
            <Icon glyph="icon-fontello-calendar-1" />
            &nbsp;
            <b>
              <AxoLocal
                entity="TimeEntryFormntimeEntry"
                defaultValue={"Dato"}
              />
              :{" "}
            </b>
            <div style={{ display: "inline-block" }}>
              <AxoDateTime
                id="workDate"
                value={moment(timeEntry.workDate)}
                dateFormat={true}
                timeFormat={true}
                onChange={this.onChangeWorkDate}
                inputProps={{
                  style: { border: "none", padding: "0px 0px 0px 0px" },
                }}
              />
            </div>
          </Row>
          {this.renderClientSelect()}
          {this.renderCaseSelect()}
          <Row className="text-m">
            <Icon glyph=" icon-fontello-pencil-alt-1" />
            &nbsp;
            <b>
              {" "}
              <AxoLocal
                entity="TimeEntryFormnotes"
                defaultValue={"Noter"}
              />:{" "}
            </b>
            <InlineTextArea
              value={timeEntry.description || "---"}
              change={this.onDescChange}
            />
          </Row>
          <Row className="text-m">
            <Icon glyph=" icon-fontello-pencil-alt-1" />
            &nbsp;
            <b>
              <AxoLocal entity="unitUnitType" defaultValue={"Enhedstype"} />:{" "}
            </b>
            <select
              className="axoblue selectbg"
              value={timeEntry.unitType}
              onChange={(event) =>
                this.onChangeProperty("unitType", event.target.value)
              }
            >
              <AxoLocal
                componentClass={ReactOption}
                value="Hours"
                componentAttribute="text"
                entity="unitHoursFullName"
                defaultValue={"Timer"}
              />
              <AxoLocal
                componentClass={ReactOption}
                value="Units"
                componentAttribute="text"
                entity="unitUnitsFullName"
                defaultValue={"Enheder"}
              />
              {/* <AxoLocal componentClass={ReactOption} disabled value='empty'disabled componentAttribute='text' entity='TimeEntryBoxFormnoclientId' defaultValue={'Empty'}/> */}
            </select>
          </Row>
          <Row className="text-m">
            <Icon glyph="icon-fontello-money-2" />
            &nbsp;
            <b>
              {timeEntry.unitType === "Hours" ? (
                <AxoLocal
                  key="hours"
                  entity="unitHoursFullName"
                  defaultValue={"Timer"}
                />
              ) : (
                <AxoLocal
                  key="units"
                  entity="unitUnitsFullName"
                  defaultValue={"Antal enheder"}
                />
              )}
              :&nbsp;
            </b>
            <InlineNumberEdit
              value={timeEntry.units}
              change={(input) => {
                this.onChangeProperty("units", input.value);
              }}
            />
          </Row>
          <Row className="text-m">
            <Icon glyph="icon-fontello-money-2" />
            &nbsp;
            <b>
              {timeEntry.unitType === "Hours" ? (
                <span>
                  <AxoLocal
                    key="hours"
                    entity="unitHoursRate"
                    defaultValue={"Timepris"}
                  />
                  &nbsp;({UserInfoService.getCurrency(this.props.userProfile)}/
                  <AxoLocal
                    key="hour"
                    entity="unitHoursSingular"
                    defaultValue={"time"}
                  />
                  )
                </span>
              ) : (
                <span>
                  <AxoLocal
                    key="units"
                    entity="CalendarEventFormRate"
                    defaultValue={"Pris / enhed"}
                  />
                  &nbsp;({UserInfoService.getCurrency(this.props.userProfile)}/
                  <AxoLocal
                    key="unit"
                    entity="TimeEntriesViewTimeUnit"
                    defaultValue={"unit"}
                  />
                  )
                </span>
              )}
              :&nbsp;
            </b>
            <InlineNumberEdit
              value={timeEntry.rate || 100}
              change={this.onChangeRate}
            />
          </Row>
          <Row className="text-m">
            <Icon glyph="icon-simple-line-icons-pie-chart" />
            &nbsp;
            <b>
              <AxoLocal
                entity="invoiPaymentattheSubtotalVAT"
                defaultValue={"Moms"}
              />
              :{" "}
            </b>
            <InlineNumberEdit
              value={timeEntry.tax * 100}
              format={(value) => {
                return value.toString() + " %";
              }}
              change={this.onChangeTax}
            />
          </Row>
          {this.renderExpenses()}
          <Row>
            <Button
              onClick={this.props.onAddExpense}
              className="Lex-button-2 lg"
            >
              <Icon
                role="button"
                glyph="icon-fontello-plus-3"
                className="Addicon"
              />
              &nbsp;
              <AxoLocal
                entity="TimeEntryFormAddExpense"
                defaultValue="Tilføj udgift"
              />
            </Button>
          </Row>
          <br />
          <Row className="text-m">
            <Icon glyph="icon-fontello-database" />
            &nbsp;
            <b>
              <AxoLocal entity="TimeEntriesViewValue" defaultValue={"Værdi"} />:{" "}
            </b>
            <span>
              {UserInfoService.getCurrency(this.props.userProfile)}{" "}
              {this.getTimeEntriesTotal().toFixed(2)}
            </span>
          </Row>
        </Grid>
      </div>
    );
  }
}
