import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import { withRouter } from "react-router-dom";
import SortHeaderCell from "../../utilities/FixedDataTable/SortHeaderCell";
import DataListWrapper from "../../utilities/FixedDataTable/DataListWrapper";
import { Table, Column, Cell } from "fixed-data-table-2";
import ModalService from "../../services/ModalService";
import moment from "moment";

import {
  AxoCheckbox,
  UserSearchBox,
  TableBase,
  Icon,
  Dimensions,
  AxoLocal,
  InlineEdit,
} from "../../utilities/LexUtilities";

import {
  Row,
  Col,
  Grid,
  FormControl,
  FormGroup,
  InputGroup,
} from "react-bootstrap";

const defaultColumnDefs = {
  select: {
    width: 100,
    shown: true,
  },
  title: {
    width: 400,
    shown: true,
  },
  type: {
    width: 400,
    shown: true,
  },
  creationDate: {
    width: 300,
    shown: true,
  },
  actions: {
    width: 100,
    shown: true,
  },
};

class DocumentTemplateTableComponent extends TableBase {
  constructor(props) {
    super(props);
    this.name = "DocumentTemplateTableComponent";
    this.defaultColumnDefs = defaultColumnDefs;

    var tableEntries = this.copyTemplates(props.templates);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(tableEntries);
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      deletingTemplateId: 0,
    };
  }

  copyTemplates = (entries) => {
    return entries.map((template) => {
      var sortTemplate = Object.assign({}, template);
      return sortTemplate;
    });
  };

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.copyTemplates(nextProps.templates);
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  onClickTemplate = (templateId, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onClickTemplate(templateId);
  };

  onTrashTemplate = (templateId) => {
    this.setState({ deletingTemplateId: templateId });
    var template = this.props.templates.find(
      (t) => t.id === parseInt(templateId, 10)
    );

    if (template.trashed) {
      this.deleteTemplate(template.id);
    }
    template.trashed = true;
    DataActions.updateTemplate(template).then((response) => {
      this.setState({ deletingTemplateId: 0 });
    });
  };

  deleteTemplate = (templateId) => {
    ModalService.openConfirmModal(
      <AxoLocal
        entity="Documentdeletethetemplatepermanently"
        defaultValue={"Vil du slette skabelonen permanent?"}
      />,
      (value) => {
        if (value) {
          DataActions.deleteTemplate(templateId);
        }
      }
    );
  };

  renderButtons = (objectId) => {
    return (
      <div>
        {this.state.deletingTemplateId === objectId ? (
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        ) : (
          <Icon
            role="button"
            onClick={this.onTrashTemplate.bind(this, objectId)}
            className="editable"
            glyph="icon-fontello-trash-1"
          />
        )}
      </div>
    );
  };

  onTemplateTypeChange = (templateId, input) => {
    var template = this.props.templates.find((t) => t.id === templateId);
    template.type = input.value;
    DataActions.updateTemplate(template);
  };

  onSearch = (event) => {
    let { tableEntries } = this.state;

    var entries = this.getFilteredEntries(tableEntries, event.target.value);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }
    return entries.filter((t) => {
      return t.title.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  render() {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth, showSharingIcon } = this.props;
    var tableWidth = containerWidth - 30;

    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <Col sm={4}>
                  <h4>
                    {" "}
                    <Icon glyph="icon-fontello-hourglass" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="Documenttemplates"
                      defaultValue={"Skabeloner"}
                    />
                  </h4>
                </Col>
                <Col sm={4}>
                  <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon
                            className="editable"
                            glyph="icon-fontello-search"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
                <Col
                  sm={4}
                  className="hidden-xs text-right"
                  style={{ paddingTop: "10px" }}
                ></Col>
              </Row>
            </Grid>
          </div>
          <div>
            <Grid fluid>
              <Row style={{ paddingLeft: "20px" }}>
                <Col
                  xs={12}
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "25px",
                    paddingBottom: "15px",
                  }}
                >
                  <Icon glyph="icon-fontello-spread" /> &nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="DocumentSharetemplateswithuser"
                    defaultValue={"Del skabeloner med bruger"}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;
                  <div style={{ display: "inline-block" }}>
                    <UserSearchBox
                      userProfiles={this.props.friends}
                      handleSelectedUser={
                        this.props.shareTemplatesWithSelectedUser
                      }
                      clearOnSelect
                      disabled={this.props.selectedTemplates.size === 0}
                      inputBorder="1px solid #4CAF50"
                    />
                  </div>
                  {showSharingIcon ? (
                    <span>
                      &nbsp;
                      <Icon className="axogreen" glyph="icon-fontello-check" />
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row style={{ paddingLeft: "20px" }}>
                <Col xs={12} style={{ padding: 0 }}>
                  <Table
                    rowHeight={40}
                    rowsCount={sortedDataList.getSize()}
                    height={containerHeight}
                    width={tableWidth}
                    isColumnResizing={false}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    headerHeight={40}
                  >
                    <Column
                      columnKey="select"
                      header={
                        <Cell>
                          <AxoLocal
                            entity="Documentselect"
                            defaultValue={"Valgt"}
                          />
                        </Cell>
                      }
                      cell={(props) => {
                        var template = sortedDataList.getObjectAt(
                          props.rowIndex
                        );
                        return (
                          <Cell
                            key={template.id}
                            className="text-center"
                            {...props}
                          >
                            <AxoCheckbox
                              checked={this.props.selectedTemplates.has(
                                template.id
                              )}
                              onChange={this.props.onSelectTemplate.bind(
                                this,
                                template.id
                              )}
                            />
                          </Cell>
                        );
                      }}
                      width={columnDefs.select.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="title"
                      header={
                        <SortHeaderCell
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.title}
                        >
                          <Icon
                            style={{ color: "#4CAF50" }}
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="socialPrivateConversationBannerConferenceName"
                            defaultValue={"Navn"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var template = sortedDataList.getObjectAt(
                          props.rowIndex
                        );
                        return (
                          <Cell
                            key={template.id}
                            {...props}
                            onClick={this.onClickTemplate.bind(
                              this,
                              template.id
                            )}
                          >
                            <div role="button">
                              <a href="document">{template.title}</a>
                              {template.draft ? (
                                <span>
                                  &ensp;(
                                  {
                                    <AxoLocal
                                      entity="CaseEditFormcasedraft"
                                      defaultValue="Kladde"
                                    />
                                  }
                                  )
                                </span>
                              ) : null}
                              {template.sharedUsers.length > 0 ? (
                                <span>
                                  &ensp;
                                  <Icon
                                    className="editable"
                                    glyph="icon-fontello-share"
                                  />
                                </span>
                              ) : null}
                            </div>
                          </Cell>
                        );
                      }}
                      width={columnDefs.title.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="type"
                      header={
                        <SortHeaderCell
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.type}
                        >
                          <Icon
                            style={{ color: "#4CAF50" }}
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="DocumentGridViewType"
                            defaultValue={"Type"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var template = sortedDataList.getObjectAt(
                          props.rowIndex
                        );
                        return (
                          <Cell key={template.id} {...props}>
                            <InlineEdit
                              value={template.type || "---"}
                              change={this.onTemplateTypeChange.bind(
                                this,
                                template.id
                              )}
                            />
                          </Cell>
                        );
                      }}
                      width={columnDefs.type.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="creationDate"
                      header={
                        <SortHeaderCell
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.creationDate}
                        >
                          <Icon
                            style={{ color: "#4CAF50" }}
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="DocumentTabViewcreationDate"
                            defaultValue={"Oprettelsesdato"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var template = sortedDataList.getObjectAt(
                          props.rowIndex
                        );
                        return (
                          <Cell key={template.id} {...props}>
                            {moment(template.creationDate).format("L")}
                          </Cell>
                        );
                      }}
                      width={columnDefs.creationDate.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="actions"
                      header={<Cell></Cell>}
                      cell={(props) => {
                        var template = sortedDataList.getObjectAt(
                          props.rowIndex
                        );
                        return (
                          <Cell key={template.id} {...props}>
                            {this.renderButtons(template.id)}
                          </Cell>
                        );
                      }}
                      width={columnDefs.actions.width}
                      isResizable={true}
                    />
                  </Table>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

class DocumentTemplateTableView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplates: new Set(),
      showSharingIcon: false,
    };
  }

  onSelectTemplate = (templateId, event) => {
    let selectedTemplates = new Set(this.state.selectedTemplates);
    if (event.target.checked) {
      selectedTemplates.add(templateId);
    } else {
      selectedTemplates.delete(templateId);
    }
    this.setState({ selectedTemplates: selectedTemplates });
  };

  shareTemplatesWithSelectedUser = (userProfile) => {
    this.state.selectedTemplates.forEach((templateId) => {
      var template = this.props.templates.find((t) => t.id === templateId);
      if (!template) {
        return;
      }
      var shareCopy = Object.assign({}, template);
      shareCopy.id = undefined;
      shareCopy.title = shareCopy.title + "(+)";
      DataActions.createTemplate(shareCopy)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return Promise.reject(
              new Error(
                "Fejl: serveren returnerede: " + response.status.toString()
              )
            );
          }
        })
        .then((newTemplate) => {
          return DataActions.shareTemplate({
            templateId: newTemplate.id,
            userProfileId: userProfile.id,
          });
        })
        .then((response) => {
          if (response.ok) {
            this.setState({
              selectedTemplates: new Set(),
              showSharingIcon: true,
            });
            setTimeout(() => {
              this.setState({ showSharingIcon: false });
            }, 3000);
          } else {
            return Promise.reject(
              new Error("Error sharing document: " + response.status.toString())
            );
          }
        })
        .catch((error) => {
          ModalService.openAlertModal(
            <AxoLocal
              entity="DocumentGridViewopenAlertModalreject"
              defaultValue={"Der skete en fejl. Prøv igen senere."}
            />
          );
        });
    });
  };

  renderDocumentTemplateTableComponent = () => {
    return (
      <DocumentTemplateTableComponent
        {...this.props}
        selectedTemplates={this.state.selectedTemplates}
        showSharingIcon={this.state.showSharingIcon}
        onSelectTemplate={this.onSelectTemplate}
        shareTemplatesWithSelectedUser={this.shareTemplatesWithSelectedUser}
      />
    );
  };

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} style={{ paddingTop: "10px", paddingLeft: "0px" }}>
            {this.renderDocumentTemplateTableComponent()}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight - 260;
    },
  })(DocumentTemplateTableView)
);
