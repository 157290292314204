import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import moment from "moment";

import { Grid, Row, Table } from "react-bootstrap";

import {
  getText,
  ClientSearchBox,
  Flexbox,
  FlexElement,
  Link,
  AxoDateTime,
  // AsyncButton,
  LexButton,
  AxoLocal,
  AxoCheckbox,
  Icon,
} from "../../../utilities/LexUtilities";

import {
  PrintService,
  NumberService,
  ContactService,
} from "../../../services/AxoServices";

const DisplayStatus = {
  Both: 0,
  Result: 1,
  Balance: 2,
};

export default class AccountingResultsView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      printing: false,
    };
    this.printRef = React.createRef();
  }

  static propTypes = {
    // entries: PropTypes.array.isRequired,
    contactMap: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
    selectedContact: PropTypes.object.isRequired,
    selectedPlan: PropTypes.object.isRequired,
  };

  onPrint = () => {
    this.setState({ printing: true }, () => {
      if (typeof window !== "undefined") {
        PrintService.printElement(document.getElementById("print-report"));
      }
      this.setState({ printing: false });
    });
  };

  render() {
    let {
      contactMap,
      handleSelectedClient,
      selectedContact,
      fiscalYears,
      onSelectFiscalYear,
      onSelectStartDate,
      onSelectEndDate,
      displayStatus,
      setDisplayStatus,
      includeDraft,
      onChangeIncludeDraft,
      hideEmpty,
      onChangeHideEmpty,
      selectedStartDate,
      selectedEndDate,
      selectedFiscalYearId,
      fixedClient,
    } = this.props;

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    return (
      <div className="leftPadding">
        <Flexbox responsive style={{ marginTop: "15px" }}>
          {!fixedClient && (
            <FlexElement>
              <ClientSearchBox
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                disabled={false}
                placeholder={getText(
                  "composeSelectClientPlaceholder1",
                  "Vælg klient"
                )}
              />
            </FlexElement>
          )}
          {selectedContact.id ? (
            <>
              <FlexElement className="leftPadding rightPadding text-center">
                <LexButton
                  disabled={!this.printRef.current}
                  onClick={this.onPrint}
                >
                  <Icon glyph="icon-fontello-print-3" />
                </LexButton>
              </FlexElement>
            </>
          ) : null}
          {!!fiscalYears && selectedContact.id ? (
            <>
              <FlexElement flexBasis="150px" className="rightPadding">
                <Select
                  name="fiscalYear"
                  menuPlacement="auto"
                  value={{
                    value: selectedFiscalYear.id.toString(),
                    label: selectedFiscalYear.name,
                  }}
                  onChange={(selection, arg) => {
                    onSelectFiscalYear(
                      !!selection && selection.value !== "0"
                        ? parseInt(selection.value, 10)
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "0",
                      label: getText("axoidcode100", "Vælg regnskabsår"),
                    },
                    ...fiscalYears.map((year) => {
                      return { value: year.id.toString(), label: year.name };
                    }),
                  ]}
                />
              </FlexElement>
              <FlexElement flexBasis="125px" className="rightPadding">
                <AxoDateTime
                  utc
                  value={startDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectStartDate}
                  isValidDate={(currentDate) => {
                    return !currentDate.isAfter(endDateSelection);
                  }}
                />
              </FlexElement>
              <FlexElement flexBasis="125px" className="rightPadding">
                <AxoDateTime
                  utc
                  value={endDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectEndDate}
                  isValidDate={(currentDate) => {
                    return !currentDate.isBefore(startDateSelection);
                  }}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <AxoCheckbox
                  onChange={(event) => onChangeHideEmpty(event.target.checked)}
                  checked={hideEmpty}
                />
                <AxoLocal entity="axoidcode32" defaultValue={"Skjul tomme"} />
              </FlexElement>
              <FlexElement className="rightPadding">
                <AxoCheckbox
                  onChange={(event) =>
                    onChangeIncludeDraft(event.target.checked)
                  }
                  checked={includeDraft}
                />
                <AxoLocal entity="axoidcode33" defaultValue={"Medtag kladde"} />
              </FlexElement>
              <FlexElement flexGrow={1} className="text-right rightPadding">
                <div
                  className="inlineBlock text-left"
                  style={{ minWidth: "250px" }}
                >
                  <Select
                    name="displayStatus"
                    menuPlacement="auto"
                    value={this.statusOptions.find(
                      (o) => o.value === displayStatus.toString()
                    )}
                    onChange={(selection, arg) => {
                      setDisplayStatus(
                        !!selection ? parseInt(selection.value, 10) : 0
                      );
                    }}
                    noOptionsMessage={() => ""}
                    options={this.statusOptions}
                  />
                </div>
              </FlexElement>
            </>
          ) : null}
        </Flexbox>
        {!!fiscalYears && selectedContact.id && fiscalYears.length === 0 ? (
          <div>
            <span style={{ fontSize: "18px" }}>
              <Link to="Setup">
                {!fixedClient ? (
                  <>
                    <AxoLocal
                      entity="axoidcode67"
                      defaultValue={"Opret et regnskabsår for"}
                    />
                    &nbsp;{selectedContact.firstName} {selectedContact.lastName}
                  </>
                ) : (
                  <>
                    <AxoLocal
                      entity="axoidcode215"
                      defaultValue={"Opret et regnskabsår"}
                    />
                  </>
                )}
              </Link>
            </span>
          </div>
        ) : null}
        {!!fiscalYears && selectedContact.id && fiscalYears.length > 0
          ? this.renderAccountResults()
          : null}
      </div>
    );
  }

  isBoldType = (account) => {
    return account.type !== "Standard";
  };

  formatAmount(amount) {
    if (!amount) {
      return "0.00";
    }
    return NumberService.formatDecimal(amount);
  }

  getAccountName(account) {
    if (account.type !== "Standard") {
      return account.name;
    }

    let { printing } = this.state;

    return (
      account.name + (!printing ? (account.isCredit ? " (C)" : " (D)") : "")
    );
  }

  statusOptions = [
    { value: "0", label: getText("axoidcode124", "Vis alle") },
    { value: "1", label: getText("axoidcode125", "Kun resultatopgørelse") },
    { value: "2", label: getText("axoidcode126", "Kun balancekonti") },
  ];

  renderAccountResults() {
    let {
      selectedPlan,
      fiscalYears,
      selectedFiscalYearId,
      selectedStartDate,
      selectedEndDate,
      accountingReport,
      displayStatus,
      selectedContact,
      fixedClient,
    } = this.props;

    let { printing } = this.state;

    let resultAccounts = selectedPlan.accounts.filter(
      (acc) =>
        acc.number >= selectedPlan.resultStart &&
        acc.number <= selectedPlan.resultEnd
    );
    let balanceAccounts = selectedPlan.accounts.filter(
      (acc) =>
        acc.number >= selectedPlan.balanceStart &&
        acc.number <= selectedPlan.balanceEnd
    );

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    let ResultRow = this.ResultRow;
    return (
      <Grid fluid>
        <Row id="print-report" ref={this.printRef}>
          {printing ? (
            <div>
              <div className="leftPadding text-center">
                <span style={{ fontSize: "18px", paddingTop: "15px" }}>
                  {!fixedClient ? (
                    <>
                      <AxoLocal
                        entity="axoidcode83"
                        defaultValue={"Saldobalance for"}
                      />
                      &nbsp;
                      {ContactService.getContactAccountingName(selectedContact)}
                    </>
                  ) : (
                    <>
                      <AxoLocal
                        entity="AccountingTabViewEntity2"
                        defaultValue={"Saldobalance"}
                      />
                    </>
                  )}
                </span>
              </div>
              <div
                className="leftPadding text-center"
                style={{ fontSize: "18px" }}
              >
                {startDateSelection.format("L")} -{" "}
                {endDateSelection.format("L")}
              </div>
            </div>
          ) : null}
          <Flexbox
            justified
            collapseXS
            className={
              displayStatus !== DisplayStatus.Both ? "standardMaxWidth" : ""
            }
          >
            {displayStatus === DisplayStatus.Both ||
            displayStatus === DisplayStatus.Result ? (
              <FlexElement className="leftPadding rightPadding">
                <h4>
                  <AxoLocal
                    entity="AccountingTabViewEntity3"
                    defaultValue={"Resultatopgørelse"}
                  />
                </h4>
                <Table condensed hover className="borderless mediumText">
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <AxoLocal
                          entity="axoAccounting6"
                          defaultValue={"Kontonummer"}
                        />{" "}
                      </th>
                      <th>
                        {" "}
                        <AxoLocal
                          entity="Enhedspris6"
                          defaultValue="Beskrivelse"
                        />{" "}
                      </th>
                      <th>
                        <AxoLocal
                          entity="InvoiceInvoicesum"
                          defaultValue={"Beløb"}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultAccounts.map((acc) => (
                      <ResultRow
                        key={acc.id}
                        account={acc}
                        accountArray={selectedPlan.accounts}
                        accountingReport={accountingReport}
                      />
                    ))}
                  </tbody>
                </Table>
              </FlexElement>
            ) : null}
            {displayStatus === DisplayStatus.Both ||
            displayStatus === DisplayStatus.Balance ? (
              <FlexElement className="leftPadding rightPadding">
                <h4>
                  <AxoLocal
                    entity="AccountingTabViewEntity4"
                    defaultValue={"Balance"}
                  />{" "}
                </h4>
                <Table condensed hover className="borderless mediumText">
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <AxoLocal
                          entity="axoAccounting6"
                          defaultValue={"Kontonummer"}
                        />{" "}
                      </th>
                      <th>
                        {" "}
                        <AxoLocal
                          entity="Enhedspris6"
                          defaultValue="Beskrivelse"
                        />{" "}
                      </th>
                      <th>
                        <AxoLocal
                          entity="InvoiceInvoicesum"
                          defaultValue={"Beløb"}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {balanceAccounts.map((acc) => (
                      <ResultRow
                        key={acc.id}
                        account={acc}
                        accountArray={selectedPlan.accounts}
                        accountingReport={accountingReport}
                      />
                    ))}
                  </tbody>
                </Table>
              </FlexElement>
            ) : null}
          </Flexbox>
        </Row>
      </Grid>
    );
  }

  ResultRow = (props) => {
    let { hideEmpty, onNavigateToAccount, selectedPlan } = this.props;
    let { account, accountArray, accountingReport } = props;

    let amount = accountingReport[account.id];

    if (hideEmpty && !amount) {
      if (account.type === "Standard") {
        return null;
      } else if (account.type === "Sum") {
        let activity = 0;
        if (account.summedAccounts) {
          activity = account.summedAccounts.reduce((acc, id) => {
            return acc + accountingReport[id] || 0;
          }, 0);
        }
        if (!!account.sumIntervalStart) {
          activity += selectedPlan.accounts
            .filter(
              (a) =>
                a.number >= account.sumIntervalStart &&
                a.number <= account.sumIntervalEnd
            )
            .reduce((acc, account) => {
              return acc + accountingReport[account.id] || 0;
            }, 0);
        }

        if (activity === 0) {
          return null;
        }
      } else if (account.type === "Header") {
        let index = accountArray.findIndex((a) => a.id === account.id);
        if (index > -1 && index < accountArray.length - 1) {
          let foundAmount = false;
          let headersToBeMatched = 1; //If more headers are passed, each should be matched with a sum account.
          for (
            let i = index + 1;
            i < accountArray.length && headersToBeMatched > 0 && !foundAmount;
            i++
          ) {
            let nextAccount = accountArray[i];
            if (nextAccount.type === "Header") {
              headersToBeMatched++;
            } else if (nextAccount.type === "Sum") {
              headersToBeMatched--;
            } else if (!!accountingReport[nextAccount.id]) {
              foundAmount = true;
            }
          }
          if (!foundAmount) {
            return null;
          }
        }
      }
    }

    let style = account.type !== "Standard" ? { fontWeight: "bold" } : null;

    return (
      <tr key={account.id}>
        <td>
          {
            <span style={style}>
              {account.type !== "Header" && account.type !== "Sum"
                ? account.number
                : ""}
            </span>
          }
        </td>
        <td>{<span style={style}>{this.getAccountName(account)}</span>}</td>
        <td className="text-right">
          {account.type !== "Header" ? (
            <span style={style}>
              {account.type === "Standard" ? (
                <>
                  <a
                    href="Postings"
                    className="hidden-print"
                    onClick={(event) =>
                      onNavigateToAccount(account.number, event)
                    }
                  >
                    {this.formatAmount(amount)}
                  </a>
                  <span className="visible-print">
                    {this.formatAmount(amount)}
                  </span>
                </>
              ) : (
                <>{this.formatAmount(amount)}</>
              )}
            </span>
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  };
}
