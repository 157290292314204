import React from "react";
import InfoFrontPageView from "./InfoFrontPageView";
import { Route, Switch } from "react-router-dom";
// import Signup from '../Login/Signup'
// import SignupAccountantContainer from '../Login/SignupAccountantContainer'
// import GroupLogin from '../Login/GroupLogin'
import InfoPageContent from "./InfoPageContent";
// import SolutionsContainer from "./SolutionsContainer";
// import CompanyContainer from "./CompanyContainer";
// import PackagesContainer from "./PackagesContainer";
// import IndividualPackage from "./IndividualPackage";
// import Login from '../Login/Login'
// import ForgotPassword from '../Login/ForgotPassword'
// import ResetPassword from '../Login/ResetPassword'
// import ResetPasswordConfirmation from '../Login/ResetPasswordConfirmation'
// import SubscribeContainer from '../Login/SubscribeContainer'
// import AccessDenied from '../Login/AccessDenied'
// import RequestConfirmEmail from '../Login/RequestConfirmEmail'
// import ConfirmEmail from '../Login/ConfirmEmail'
import PaymentDemoView from "../demoPages/PaymentDemoView";
import SocietyPaymentDemoView from "../demoPages/SocietyPaymentDemoView";
// import PricingDemoView from "../demoPages/PricingDemoView";
// import ApplicationUDContainer from '../routes/ApplicationUD/ApplicationUDContainer'

import {
  DataStore,
  RoutingService,
  ThemeService,
} from "../services/AxoServices";

import { LoadingIndicator, Consumer } from "../utilities/LexUtilities";

class InfoFrontPageContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserSettings();
    DataStore.initializeUserProfile();
    DataStore.fetchGlobalSettings();

    RoutingService.setAccountRootPath("/Users");
  }

  componentDidUpdate(prevProps) {
    let { globalSettings } = this.props;
    if (!!globalSettings.themeFront) {
      ThemeService.setThemeStyle(globalSettings.themeFront);
    }
  }

  render() {
    let { loaded, globalSettings, userSettings, userProfile } = this.props;

    if (!loaded || !globalSettings.theme) {
      return <LoadingIndicator />;
    }
    return (
      <InfoFrontPageView userSettings={userSettings} userProfile={userProfile}>
        {this.props.children}
      </InfoFrontPageView>
    );
  }
}

class InfoFrontPageConsumer extends React.PureComponent {
  render() {
    return (
      <Consumer>
        {(store) => {
          return (
            <InfoFrontPageContainer
              loaded={store.loaded}
              globalSettings={store.globalSettings}
              userSettings={store.userSettings}
              userProfile={store.userProfile}
            >
              {/* <InfoPageContent /> */}
              <Switch>
                <Route exact path="/" component={InfoPageContent} />

                <Route path="/PaymentDemo" component={PaymentDemoView} />
                <Route
                  path="/SocietyPayment"
                  component={SocietyPaymentDemoView}
                />
                {/* <Route path="/Solutions" component={SolutionsContainer} />
                <Route path="/Company" component={CompanyContainer} />
                <Route path="/Packages" component={PackagesContainer} />
                <Route
                  path="/IndividualPackage"
                  component={IndividualPackage}
                /> */}
              {/* <Route path='/Users/Login' component={Login}/>
                <Route path='/Users/Signup' component={Signup}/>
                <Route path='/Users/SignupAccountant' component={SignupAccountantContainer}/>
                <Route path='/Users/ForgotPassword' component={ForgotPassword}/>
                <Route path='/Users/ResetPassword' component={ResetPassword}/>
                <Route path='/Users/ResetPasswordConfirmation' component={ResetPasswordConfirmation}/>
                <Route path='/Users/Subscribe' component={SubscribeContainer}/>
                <Route path='/Users/AccessDenied' component={AccessDenied}/>
                <Route path='/Users/RequestConfirmEmail' component={RequestConfirmEmail}/>
                <Route path='/Users/ConfirmEmail' component={ConfirmEmail}/>
                <Route path='/Users/GroupLogin' component={GroupLogin}/> */}
              {/* <Route path="/PaymentDemo" component={PaymentDemoView} />
                <Route
                  path="/SocietyPayment"
                  component={SocietyPaymentDemoView}
                />
                <Route path="/Pricing" component={PricingDemoView} /> */}
              {/* <Route path='/ApplicationUD' component={ApplicationUDContainer} /> */}
              </Switch>
            </InfoFrontPageContainer>
          );
        }}
      </Consumer>
    );
  }
}

export default InfoFrontPageConsumer;
