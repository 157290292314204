import React from "react";
import ClientDocumentTableView from "./ClientDocumentTableView";
export default class ClientDocumentUploadView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploadedDocuments: [],
    };
  }

  getUploadedDocuments = () => {
    return this.props.documents.filter((f) =>
      this.state.uploadedDocuments.find((id) => id === f.id)
    );
  };
  //Props
  //documents
  render() {
    return (
      <div>
        <ClientDocumentTableView
          documents={this.props.documents}
          userProfile={this.props.userProfile}
          onClickShared={this.props.onClickShared}
          forceShowCards={this.props.forceShowCards}
          onToggleCards={this.props.onToggleCards}
          showUploadButton={true}
        />
      </div>
    );
  }
}
