import React from "react";

import { withRouter } from "../../utilities/LexUtilities";

import ContactForm from "../../FrontPage/ContactForm";
import QueryString from "query-string";
import { LocalizationService } from "../../services/AxoServices";

class EmbeddedContactForm extends React.Component {
  componentDidMount() {
    let query = QueryString.parse(window.location.search);

    if (!!query.locale) {
      LocalizationService.changeLocale(query.locale);
    }
  }

  render() {
    let { toEmail } = this.props.match.params;
    let { location } = this.props;

    let query = QueryString.parse(location.search);

    if (!toEmail) {
      return <div>Der skal angives en email adresse i linket.</div>;
    }

    return (
      <ContactForm
        // messageLabel=''
        // sendLabel=''
        toEmail={toEmail}
        hideCountry
        attachFiles
        externalStyle={{
          background: query.background || "",
          color: query.color || "",
        }}
      />
    );
  }
}

export default withRouter(EmbeddedContactForm);
