import React from "react";
import PropTypes from "prop-types";

export default class Flexbox extends React.PureComponent {
  static propTypes = {
    column: PropTypes.bool,
    spaceBetween: PropTypes.bool,
    justifyCenter: PropTypes.bool,
    alignCenter: PropTypes.bool,
    justified: PropTypes.bool,
    responsive: PropTypes.bool,
    collapseXS: PropTypes.bool,
    collapseSM: PropTypes.bool,
    collapseMD: PropTypes.bool,
  };

  getClasses = () => {
    let {
      column,
      spaceBetween,
      justifyCenter,
      alignCenter,
      justified,
      responsive,
      collapseXS,
      collapseSM,
      collapseMD,
    } = this.props;
    let className =
      "flexbox" + (!!this.props.className ? " " + this.props.className : "");
    if (column) {
      className += " flex-column";
    }
    if (spaceBetween) {
      className += " spaceBetween";
    }
    if (justifyCenter) {
      className += " justifyCenter";
    }
    if (alignCenter) {
      className += " alignCenter";
    }
    if (justified) {
      className += " justified";
    }
    if (responsive) {
      className += " responsive";
    }
    if (collapseXS) {
      className += " collapse-xs";
    }
    if (collapseSM) {
      className += " collapse-sm";
    }
    if (collapseMD) {
      className += " collapse-md";
    }
    return className;
  };

  render() {
    let {
      column,
      spaceBetween,
      justifyCenter,
      alignCenter,
      justified,
      responsive,
      collapseXS,
      collapseSM,
      collapseMD,
      className,
      ...otherProps
    } = this.props;

    return (
      <div className={this.getClasses()} {...otherProps}>
        {this.props.children}
      </div>
    );
  }
}
