import React from "react";

import { Carousel } from "react-bootstrap";
import { AxoLocal } from "../../utilities/LexUtilities";
class MailCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2EM4.png" />
          <Carousel.Caption>
            <div
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
              className="axolexCarouselItem"
            >
              <AxoLocal
                entity="IndividualPackagetext185"
                defaultValue={"Unik løsning til virksomhedens mailsystem."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext186"
                defaultValue={
                  "Kollaborativ integration med hele kontrolsystemet."
                }
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2EM3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext185"
                defaultValue={"Unik løsning til virksomhedens mailsystem."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext186"
                defaultValue={
                  "Kollaborativ integration med hele kontrolsystemet."
                }
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2EM1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext185"
                defaultValue={"Unik løsning til virksomhedens mailsystem."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext186"
                defaultValue={
                  "Kollaborativ integration med hele kontrolsystemet."
                }
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class NetworkCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2S1-1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2S1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2S2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2S3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2S4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2S5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2S6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class FinanceCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2OE1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2OE2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2OE3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2OE4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class CasesCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS7.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS8.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS9.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2RS10.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class ClientsCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2K1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2K2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2K3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2K4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class SelfserviceCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2SB1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2SB2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2SB3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2SB4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class DocumentsCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2D1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2D2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2D3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2D4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2D5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class BookingCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK7.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class CalendarCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2BK2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class OnlinepaymentCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
      >
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexEC11.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="FrontPageindextaxt14"
                defaultValue={"Online betalingssystem"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class LabelsCarouselDa extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/2MP1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext200"
                defaultValue={"Digital arkivering og administration"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}
export {
  MailCarouselDa,
  NetworkCarouselDa,
  FinanceCarouselDa,
  CasesCarouselDa,
  ClientsCarouselDa,
  SelfserviceCarouselDa,
  DocumentsCarouselDa,
  BookingCarouselDa,
  CalendarCarouselDa,
  LabelsCarouselDa,
  OnlinepaymentCarouselDa,
};
