import React from "react";
import ClientDashboardView from "./ClientDashboardView";
import LawyerClientDashboardView from "./LawyerClientDashboardView";
import { Route, Switch, Redirect } from "react-router-dom";
import { SmallOrSmaller, MediumOrLarger } from "../utilities/Responsive";

import {
  ApiService,
  DataStore,
  RoutingService,
  LocalizationService,
  ThemeService,
  AuthorizationService,
} from "../services/AxoServices";

import {
  LoadingIndicator,
  withRouter,
  Consumer,
  withRTKMessageData,
} from "../utilities/LexUtilities";

import ClientComposeContainer from "./Email/ClientComposeContainer";
import ClientInboxContainer from "./Email/ClientInboxContainer";
import ClientBookingContainer from "./Booking/ClientBookingContainer";
import AccountingUploadContainer from "./Accounting/AccountingUploadContainer";
import AccountingEntriesContainer from "./Accounting/AccountingEntriesContainer";
import ClientDocumentContainer from "./Documents/ClientDocumentContainer";
import ClientRecordingContainer from "./Record/ClientRecordingContainer";
import ClientSetupContainer from "./Setup/ClientSetupContainer";
import ClientInvoicesContainer from "./Invoices/ClientInvoicesContainer";
import ClientTrashContainer from "./Trash/ClientTrashContainer";
import MailContainer from "../routes/Email/MailContainer";
import MailConfigurationContainer from "../routes/Email/MailConfigurationContainer";
import ExternalMailSetupContainer from "../routes/Email/ExternalMailSetupContainer";
import AccountingMenu from "./AccountingMenu/AccountingMenu";
import CasesContainer from "./Cases/CasesContainer";
import SingleCaseContainer from "./Cases/SingleCaseContainer";
import ClientCustomerContainer from "./Customers/ClientCustomerContainer";
// import InvoicesContainer from '../routes/Invoices/InvoicesContainer'
import CustomerInvoicesContainer from "./CustomerInvoices/CustomerInvoicesContainer";
import EasyIDDashboardView from "./EasyIDDashboardView";
import SigningView from "../Signing/SigningView";
import SigningRequestContainer from "./SigningRequests/SigningRequestContainer";

class ClientDashboardContainer extends React.PureComponent {
  componentDidMount() {
    ApiService.checkLoginStatus().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.props.history.push("/Users/Login");
      } else {
        this.InitializeData();
        this.checkSettings();
      }
    });
  }

  InitializeData = () => {
    RoutingService.setRootPath("/ClientDashboard");

    DataStore.initializeDocuments();
    DataStore.initializeFriendRequests();
    DataStore.initializeUserSettings();
    DataStore.initializeUserProfile();
    DataStore.fetchGlobalSettings();
    DataStore.fetchMyContactInfo();
  };

  componentDidUpdate(prevProps) {
    let { userSettings } = this.props;

    if (
      !!userSettings.locale &&
      userSettings.locale !== prevProps.userSettings.locale
    ) {
      //In case the context isn't ready, the language will be loaded later
      try {
        LocalizationService.changeLocale(userSettings.locale);
      } catch (error) {
        console.log(error);
      }
    }

    this.checkSettings();
  }

  checkSettings() {
    let { globalSettings, userSettings, userProfile } = this.props;

    if (
      userProfile.id &&
      (!userProfile.paidUntil ||
        new Date(userProfile.paidUntil) < new Date()) &&
      userProfile.userType === "EasyID"
    ) {
      if (!!globalSettings.theme) {
        ThemeService.setThemeStyle(globalSettings.theme);
      }

      this.props.history.push("/Users/Subscribe");
      return;
    }

    if (!!globalSettings.themeClient) {
      ThemeService.setThemeStyle(globalSettings.themeClient);
    }

    if (userSettings.locked) {
      this.props.history.push("/Lock");
    }
  }

  render() {
    let { loaded, globalSettings, userProfile, userType } = this.props;

    if (!loaded || !globalSettings.theme || !userProfile.id) {
      return <LoadingIndicator />;
    }
    let unhandledFriendRequests = this.props.friendRequests.filter(
      (f) => f.status === 0
    );
    if (userType === "Client") {
      return (
        <LawyerClientDashboardView
          {...this.props}
          unhandledFriendRequests={unhandledFriendRequests}
        >
          {this.props.children}
        </LawyerClientDashboardView>
      );
    }
    if (userType === "EasyID") {
      return (
        <EasyIDDashboardView {...this.props}>
          {this.props.children}
        </EasyIDDashboardView>
      );
    }
    return (
      <ClientDashboardView
        {...this.props}
        unhandledFriendRequests={unhandledFriendRequests}
      >
        {this.props.children}
      </ClientDashboardView>
    );
  }
}

class ClientDashboardConsumer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserProfile();
  }

  render() {
    let { match } = this.props;

    return (
      <Consumer>
        {(store) => {
          let userType = AuthorizationService.getUserType(store.userProfile);
          if (!userType) {
            return <LoadingIndicator />;
          }

          let showInvoiceDashboard =
            (userType === "AccountingClient" &&
              store.myContactInfo.enableInvoiceDashboard) ||
            userType === "Admin";

          return (
            <ClientDashboardContainer
              loaded={store.loaded}
              {...this.props}
              friendRequests={store.friendRequests}
              globalSettings={store.globalSettings}
              userSettings={store.userSettings}
              userProfile={store.userProfile}
              myContactInfo={store.myContactInfo}
              userType={userType}
              history={this.props.history}
              showDropdowns={store.showDropdowns}
            >
              <Switch>
                {userType === "Client" && (
                  <Route
                    exact
                    path={match.path}
                    component={() => <Redirect to={match.path + "/cases"} />}
                  />
                )}
                {userType === "SocietyMember" && (
                  <Route
                    exact
                    path={match.path}
                    component={() => <Redirect to={match.path + "/setup"} />}
                  />
                )}
                {userType === "EasyID" && (
                  <Route
                    exact
                    path={match.path}
                    component={() => (
                      <Redirect to={match.path + "/Documents"} />
                    )}
                  />
                )}
                {userType === "EasyID" && (
                  <Route
                    path={match.path + "/Signing/:guid"}
                    render={(props) => <SigningView {...props} isOwner />}
                  />
                )}
                {userType === "EasyID" && (
                  <Route
                    path={match.path + "/SigningRequest/:id"}
                    render={(props) => (
                      <SigningRequestContainer {...props} isOwner />
                    )}
                  />
                )}
                {userType === "AccountingClient" ? (
                  <Route
                    exact
                    path={match.path}
                    component={() => {
                      return (
                        <React.Fragment>
                          <MediumOrLarger>
                            <Redirect to={match.path + "/Upload"} />
                          </MediumOrLarger>
                          <SmallOrSmaller>
                            <Redirect to={match.path + "/Menu"} />
                          </SmallOrSmaller>
                        </React.Fragment>
                      );
                    }}
                  />
                ) : (
                  <Route
                    exact
                    path={match.path}
                    component={() => (
                      <Redirect to={match.path + "/Mailbox/Inbox"} />
                    )}
                  />
                )}
                {userType === "AccountingClient" || userType === "Admin" ? (
                  <Route
                    path={match.path + "/Upload"}
                    component={AccountingUploadContainer}
                  />
                ) : null}
                {userType === "AccountingClient" || userType === "Admin" ? (
                  <Route
                    path={match.path + "/Accounting"}
                    component={AccountingEntriesContainer}
                  />
                ) : null}
                {showInvoiceDashboard && (
                  <Route
                    path={match.path + "/Clients"}
                    component={ClientCustomerContainer}
                  />
                )}
                {showInvoiceDashboard && (
                  // Invoices sent from the client to customers
                  <Route
                    path={match.path + "/Invoices"}
                    component={CustomerInvoicesContainer}
                  />
                )}
                <Route path={match.path + "/menu"} component={AccountingMenu} />
                <Route
                  path={match.path + "/mailbox/inbox"}
                  component={ClientInboxContainer}
                />
                <Route
                  path={match.path + "/mailbox/mail/:shown/:id"}
                  component={MailContainer}
                />
                <Route
                  path={match.path + "/mailbox/compose"}
                  component={ClientComposeContainer}
                />
                <Route
                  path={match.path + "/mailbox/composeDraft/:draftId"}
                  component={ClientComposeContainer}
                />
                <Route
                  path={match.path + "/mailbox/sendExternal/:email"}
                  component={ClientComposeContainer}
                />
                <Route
                  path={match.path + "/mailbox/internalSetup"}
                  component={MailConfigurationContainer}
                />
                <Route
                  path={match.path + "/mailbox/setup"}
                  component={ExternalMailSetupContainer}
                />
                <Route
                  path={match.path + "/mailbox/setup/edit/:id"}
                  component={ExternalMailSetupContainer}
                />
                <Route
                  path={match.path + "/singleCase/:id"}
                  component={SingleCaseContainer}
                />
                <Route
                  path={match.path + "/cases"}
                  component={CasesContainer}
                />
                <Route
                  path={match.path + "/booking"}
                  component={ClientBookingContainer}
                />
                <Route
                  path={match.path + "/documents"}
                  component={ClientDocumentContainer}
                />
                <Route
                  path={match.path + "/recording"}
                  component={ClientRecordingContainer}
                />

                {userType === "EasyID" ? (
                  <Route
                    path={match.path + "/setup/"}
                    render={(props) => (
                      <ClientSetupContainer {...props} isEasyID />
                    )}
                  />
                ) : (
                  // <Route path={match.path + '/setup'} component={ClientSetupContainer} />
                  <Route
                    path={match.path + "/setup"}
                    component={ClientSetupContainer}
                  />
                )}

                {/* Invoices send from the lawyer to the client. Currently not used. */}
                <Route
                  path={match.path + "/clientinvoices"}
                  component={ClientInvoicesContainer}
                />

                <Route
                  path={match.path + "/trash"}
                  component={ClientTrashContainer}
                />
              </Switch>
            </ClientDashboardContainer>
          );
        }}
      </Consumer>
    );
  }
}

export default withRTKMessageData(withRouter(ClientDashboardConsumer));
