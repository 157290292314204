import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { DataStore } from "../../services/AxoServices";

import { withData, withRTKData } from "../../utilities/LexUtilities";

import AccountingLayoutView from "./AccountingLayoutView";
import BookkeepingConsumer from "./Bookkeeping/BookkeepingConsumer";

import AccountingSetupMainContainer from "./Setup/AccountingSetupMainContainer";
// import AccountingSetupContainer from './Setup/AccountingSetupContainer';
import AccountingResultsContainer from "./Results/AccountingResultsContainer";
import AccountingJournalContainer from "./Journal/AccountingJournalContainer";
import AccountingAccountPostingsContainer from "./AccountPostings/AccountingAccountPostingsContainer";
import AccountingVatContainer from "./Vat/AccountingVatContainer";
import BankBalancingContainer from "./BankBalancing/BankBalancingContainer";
import AnnualReportContainer from "./AnnualReport/AnnualReportContainer";
import AccountPlanConsumer from "./AccountPlan/AccountPlanConsumer";

const ViewType = {
  NORMAL: 0,
  SCAN: 1,
  TRASH: 2,
};

class AccountingLayoutContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      draftSortIndexes: [], //Caches sort order in the draft table
    };
  }

  updateSortIndexes = (sortIndexes) => {
    this.setState({ sortIndexes });
  };

  async componentDidMount() {
    let {
      selectedContact,
      userProfile,
      sharedClient,
      checkoutContact,
      updateContact,
    } = this.props;

    // Automatically check out for editing
    if (
      sharedClient &&
      selectedContact.id &&
      userProfile.id &&
      !selectedContact.editor
    ) {
      await checkoutContact(selectedContact.id).unwrap();

      updateContact({
        //Necessary to ensure that selected contact is updated
        ...selectedContact,
        editor: userProfile,
        editorId: userProfile.id,
      });
    }

    DataStore.initializeFinanceAccountPlans();
    DataStore.fetchClientPlan();
  }

  componentWillUnmount() {
    let { selectedContact, userProfile, sharedClient, updateContact } =
      this.props;

    // Check in when leaving
    if (
      sharedClient &&
      selectedContact.id &&
      userProfile.id &&
      !!selectedContact.editor
    ) {
      let newContact = {
        ...selectedContact,
        editor: null,
        editorId: null,
      };

      updateContact(newContact);
    }
  }

  async componentDidUpdate(prevProps) {
    let {
      selectedContact,
      userProfile,
      sharedClient,
      updateContact,
      checkoutContact,
    } = this.props;

    //Ensure that current selected contact is checked in, if applicable
    if (userProfile.id && prevProps.selectedContact.id !== selectedContact.id) {
      //Checking out will automatically check in the previous contact, if applicable.
      if (sharedClient && selectedContact.id && !selectedContact.editor) {
        await checkoutContact(selectedContact.id).unwrap();

        updateContact({
          //Necessary to ensure that selected contact is updated
          ...selectedContact,
          editor: userProfile,
          editorId: userProfile.id,
        });
      }
      //If the new contact cannot be checked out, we should manually check in the previous contact.
      else if (
        prevProps.sharedClient &&
        prevProps.selectedContact.id &&
        !!prevProps.selectedContact.editor
      ) {
        let newContact = {
          ...prevProps.selectedContact,
          editor: null,
          editorId: null,
        };

        updateContact(newContact);
      }
    }
  }

  render() {
    let { match, fixedClient } = this.props;

    let { sortIndexes } = this.state;

    return (
      <AccountingLayoutView {...this.props}>
        <Switch>
          <Route
            exact
            path={match.path + "/Result"}
            render={(props) => (
              <AccountingResultsContainer
                {...props}
                fixedClient={fixedClient}
              />
            )}
          />
          <Route
            exact
            path={match.path + "/Incoming"}
            render={(props) => (
              <BookkeepingConsumer
                key="Incoming"
                {...props}
                viewType={ViewType.SCAN}
                sortIndexes={sortIndexes}
                updateSortIndexes={this.updateSortIndexes}
                fixedClient={fixedClient}
                isIncoming
              />
            )}
          />
          <Route
            exact
            path={match.path + "/Drafts"}
            render={(props) => (
              <BookkeepingConsumer
                key="Drafts"
                {...props}
                viewType={ViewType.NORMAL}
                sortIndexes={sortIndexes}
                updateSortIndexes={this.updateSortIndexes}
                fixedClient={fixedClient}
              />
            )}
          />

          <Route
            exact
            path={match.path + "/Bookkeeping"}
            render={(props) => (
              <AccountingJournalContainer
                {...props}
                fixedClient={fixedClient}
              />
            )}
          />
          <Route
            exact
            path={match.path + "/Postings"}
            render={(props) => (
              <AccountingAccountPostingsContainer
                {...props}
                fixedClient={fixedClient}
              />
            )}
          />
          <Route
            exact
            path={match.path + "/Vat"}
            render={(props) => (
              <AccountingVatContainer
                {...props}
                fixedClient={fixedClient}
                useDetailedVatView
              />
            )}
          />
          <Route
            exact
            path={match.path + "/Moms"}
            render={(props) => (
              <AccountingVatContainer {...props} fixedClient={fixedClient} />
            )}
          />

          {/* <Route exact path={match.path + '/Setup'} 
            render={(props) => <AccountingSetupContainer { ...props } fixedClient={fixedClient}  />} 
          /> */}
          <Route
            exact
            path={match.path + "/Setup"}
            render={(props) => (
              <AccountingSetupMainContainer
                {...props}
                fixedClient={fixedClient}
              />
            )}
          />
          <Route
            exact
            path={match.path + "/Bank"}
            render={(props) => (
              <BankBalancingContainer {...props} fixedClient={fixedClient} />
            )}
          />
          <Route
            exact
            path={match.path + "/Report"}
            render={(props) => (
              <AnnualReportContainer {...props} fixedClient={fixedClient} />
            )}
          />
          <Route
            exact
            path={match.path + "/AccountPlan"}
            render={(props) => (
              <AccountPlanConsumer {...props} fixedClient={fixedClient} />
            )}
          />
          <Route
            path={match.path}
            render={(props) => (
              <Redirect {...props} to={match.path + "/Result"} />
            )}
          />
        </Switch>
      </AccountingLayoutView>
    );
  }
}

const mapStoreToProps = (store) => {
  let globalContactSharings = store.sharingGlobals.filter((s) => s.type === 0); //Clients

  let sharedWithMe =
    store.userProfile.userName !== store.selectedContact.userName;
  let sharedWithOthers =
    !sharedWithMe &&
    (globalContactSharings.length > 0 ||
      (!!store.selectedContact.singleSharings &&
        !!store.selectedContact.singleSharings.find(
          (s) => s.userProfileId !== store.userProfile.id
        )));

  let sharedClient = sharedWithMe || sharedWithOthers;

  return {
    selectedContact: store.selectedContact,
    showDropdowns: store.showDropdowns,
    userProfile: store.userProfile,
    sharedClient,
  };
};

export default withRTKData(
  withData(AccountingLayoutContainer, mapStoreToProps)
);
