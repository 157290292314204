import React from "react";
import PropTypes from "prop-types";
// import { ExtraSmall, SmallOrLarger } from '../utilities/Responsive';
import { Table, Column, Cell } from "fixed-data-table-2";

import moment from "moment";

import { NumberService } from "../../../services/AxoServices";

import {
  AxoCheckbox,
  TableBaseSimple,
  Dimensions,
  DataListWrapper,
  SortHeaderCell,
  AxoLocal,
  getText,
  Flexbox,
  FlexElement,
  FilterTypes,
  Icon,
} from "../../../utilities/LexUtilities";

import ReactSelect from "react-select";

class BankPostingTable extends TableBaseSimple {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    selectedPostings: PropTypes.object.isRequired,
    onSelectPosting: PropTypes.func.isRequired,
    onSelectAllPostings: PropTypes.func.isRequired,
    clearSelectedPostings: PropTypes.func.isRequired,
    showSelectors: PropTypes.bool.isRequired,
    selectedPostingAccountIdMap: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this._defaultSortIndexes = TableBaseSimple.generateDefaultSortIndices(
      props.entries
    );
    let tableEntries = props.entries;
    this.state = {
      tableEntries,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
    };

    this.colorIndices = [];
    for (let i = 0; i < 15; i++) {
      this.colorIndices.push(i);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.entries) === JSON.stringify(prevProps.entries)
    ) {
      return;
    }

    let tableEntries = this.props.entries;
    this.updateEntries(tableEntries, tableEntries);
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   let { sortedDataList } = prevState;

  //   let tableEntries = nextProps.entries;
  //   return TableBaseSimple.getNewEntries(tableEntries, tableEntries, sortedDataList);
  // }

  _rowClassNameGetter = (rowIndex) => {
    let { postingWarnings, showDoublesHighlight, postingMultiMatches } =
      this.props;

    let { sortedDataList } = this.state;

    let posting = sortedDataList.getObjectAt(rowIndex);
    if (!!postingMultiMatches[posting.id]) {
      return (
        "highlight-row-" +
        this.colorIndices[
          (postingMultiMatches[posting.id] - 1) % this.colorIndices.length
        ]
      );
    }
    if (postingWarnings[posting.id]) {
      return "highlight-row-rejected";
    }
    if (posting.approved) {
      return "highlight-row-approved";
    }
    if (showDoublesHighlight) {
      return "highlight-row-warning";
    }
  };

  filterValues = ({ label }, search) => {
    return label.toLowerCase().includes(search.toLowerCase());
  };

  ColumnFilterButtons = ({ propertyName, filterType = FilterTypes.TEXT }) => {
    let {
      columnFilters,
      removeColumnFilter,
      editTextFilter,
      editNumberFilter,
      editDateFilter,
    } = this.props;

    let func = () => {
      switch (filterType) {
        case FilterTypes.TEXT:
          return editTextFilter(true);
        case FilterTypes.NUMBER:
          return editNumberFilter(propertyName, true);
        case FilterTypes.DATE:
          return editDateFilter(true);
        default:
          return;
      }
    };

    return (
      <span onClick={(event) => event.stopPropagation()}>
        <span title={getText("axoEntityidcode117", "Filtrer")} onClick={func}>
          &nbsp;
          <Icon className="editable" glyph="icon-fontello-filter" />
          &nbsp;
        </span>
        &nbsp;
        {!!columnFilters[propertyName] && (
          <span
            title={getText("axoEntityidcode207", "Fjern filter")}
            onClick={() => removeColumnFilter(propertyName)}
          >
            &nbsp;
            <Icon className="axored" glyph="icon-fontello-trash" />
            &nbsp;
          </span>
        )}
      </span>
    );
  };

  render() {
    let { sortedDataList, colSortDirs } = this.state;

    let {
      entries,
      containerHeight,
      containerWidth,
      selectedPostings,
      onSelectPosting,
      onSelectAllPostings,
      // showDoublesHighlight,
      showSelectors,
      showAccounts,
      financeAccounts,
      selectMapAccount,
      // selectedBankPostingColumn,
      // onSelectBankPostingColumn,
      showColumnSelectors,
      hasFilter,
      postingMultiMatches,
      selectedPostingAccountIdMap,
      // priceFilter,
      // onUpdatePriceFilter,
      // monthFilter,
      // onUpdateMonthFilter,
      standardReconAccountId,
      activeBankAccountId,
      // clearSelectedPostings
    } = this.props;

    let tableWidth = containerWidth;
    let colNumber = showAccounts ? 5 : 4;
    let selectWidth = showSelectors ? 50 : 0;
    let widthUnit = (tableWidth - selectWidth) / colNumber;

    let selectAccountLabel = getText("axoAccounting6f", "Vælg konto");

    let ColumnFilterButtons = this.ColumnFilterButtons;
    return (
      <Table
        rowHeight={35}
        rowsCount={sortedDataList.getSize()}
        rowClassNameGetter={this._rowClassNameGetter}
        width={tableWidth}
        height={Math.max(containerHeight, 100)}
        headerHeight={40}
      >
        {showSelectors ? (
          <Column
            columnKey="select"
            header={
              <Cell className="text-center">
                <AxoCheckbox
                  checked={
                    selectedPostings.size > 0 &&
                    selectedPostings.size === sortedDataList.getSize()
                  }
                  onChange={() => onSelectAllPostings(sortedDataList)}
                />
              </Cell>
            }
            cell={(props) => {
              var entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} className="text-center" {...props}>
                  <AxoCheckbox
                    checked={selectedPostings.has(entry.id)}
                    onChange={onSelectPosting.bind(this, entry.id)}
                  />
                </Cell>
              );
            }}
            width={selectWidth}
          />
        ) : null}
        <Column
          columnKey="date"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.date}
            >
              {/* <Flexbox>
                  <FlexElement>
                    <AxoLocal entity='TimeEntryFormntimeEntry'defaultValue={'Dato'}/>
                  </FlexElement>
                  { showColumnSelectors ? (
                    <React.Fragment>
                      <FlexElement flexGrow={1} className='text-right'>
                        <span onClick={(event) => { event.stopPropagation() }}>
                          <AxoCheckbox
                            checked={selectedBankPostingColumn === 'date'}
                            onChange={() => onSelectBankPostingColumn('date')}
                          />
                        </span>
                      </FlexElement>
                      <FlexElement 
                        flexGrow={1} 
                        className='text-right'
                        style={{ paddingRight: '5px' }}
                        onClick={(event) => { event.stopPropagation() }}>
                        <select
                          value={monthFilter}
                          onChange={event => onUpdateMonthFilter(event.target.value)}
                        >
                          <option value={-1}>...</option>
                          <option value={0}>{getText('getMonthNameJan', 'Jan')}</option>
                          <option value={1}>{getText('getMonthNameFeb', 'Feb')}</option>
                          <option value={2}>{getText('getMonthNameMar', 'Mar')}</option>
                          <option value={3}>{getText('getMonthNameApr', 'Apr')}</option>
                          <option value={4}>{getText('getMonthNameMay', 'Maj')}</option>
                          <option value={5}>{getText('getMonthNameJun', 'Jun')}</option>
                          <option value={6}>{getText('getMonthNameJul', 'Jul')}</option>
                          <option value={7}>{getText('getMonthNameAug', 'Aug')}</option>
                          <option value={8}>{getText('getMonthNameSep', 'Sep')}</option>
                          <option value={9}>{getText('getMonthNameOct', 'Okt')}</option>
                          <option value={10}>{getText('getMonthNameNov', 'Nov')}</option>
                          <option value={11}>{getText('getMonthNameDec', 'Dec')}</option>
                        </select>
                      </FlexElement>
                    </React.Fragment>
                  ) : null }
                </Flexbox> */}
              <Flexbox>
                <FlexElement>
                  <AxoLocal
                    entity="TimeEntryFormntimeEntry"
                    defaultValue={"Dato"}
                  />
                </FlexElement>
                {showColumnSelectors && (
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="creationDate"
                      filterType={FilterTypes.DATE}
                    />
                  </FlexElement>
                )}
              </Flexbox>
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {moment(entry.date).format("L")}
              </Cell>
            );
          }}
          width={widthUnit}
        />
        <Column
          columnKey="text"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.text}
            >
              {/* <Flexbox>
                  <FlexElement>
                    <AxoLocal entity='axoidcode77' defaultValue={'Fritekst'}/>
                  </FlexElement>
                  { showColumnSelectors ? (
                    <FlexElement flexGrow={1} className='text-right'>
                      <span onClick={(event) => { event.stopPropagation() }}>
                        <AxoCheckbox
                          checked={selectedBankPostingColumn === 'text'}
                          onChange={() => onSelectBankPostingColumn('text')}
                        />
                      </span>
                    </FlexElement>
                  ) : null }
                </Flexbox> */}
              <Flexbox>
                <FlexElement>
                  <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />
                </FlexElement>
                {showColumnSelectors && (
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="description"
                      filterType={FilterTypes.TEXT}
                    />
                  </FlexElement>
                )}
              </Flexbox>
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {!!postingMultiMatches[entry.id] ? (
                  <span>(Match #{postingMultiMatches[entry.id]})&nbsp;</span>
                ) : null}
                {entry.text}
              </Cell>
            );
          }}
          width={widthUnit * 2}
        />
        {showAccounts ? (
          <Column
            columnKey="account"
            header={
              <Cell>
                <AxoLocal
                  entity="AccountingTabViewEntity13"
                  defaultValue={"Konto"}
                />
              </Cell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);

              let accountOptions = financeAccounts
                .filter((a) => a.type === "Standard")
                .filter((a) => a.id !== standardReconAccountId)
                .filter((a) => a.id !== activeBankAccountId);

              let selectOptions = accountOptions.map((f) => {
                return {
                  value: f.id,
                  label: f.number.toString() + " - " + f.name,
                };
              });

              let selectedAccount = selectedPostingAccountIdMap[entry.id];

              return (
                <Cell key={entry.id} {...props}>
                  <ReactSelect
                    name="select"
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    closeMenuOnScroll
                    // menuPlacement='top'
                    value={
                      selectedAccount || {
                        value: "",
                        label: selectAccountLabel,
                      }
                    }
                    onChange={(selectedAccount) => {
                      if (!!selectedAccount) {
                        if (
                          selectedPostings.size > 0 &&
                          selectedPostings.has(entry.id)
                        ) {
                          selectedPostings.forEach((id) => {
                            selectMapAccount(id, selectedAccount);
                          });
                        } else if (hasFilter) {
                          entries.forEach((posting) => {
                            selectMapAccount(posting.id, selectedAccount);
                          });
                        } else {
                          selectMapAccount(entry.id, selectedAccount);
                        }
                        // this.updateMainAccountAndVat(entry, selectedAccount.value);
                      }
                    }}
                    noOptionsMessage={() => ""}
                    options={[
                      { value: "", label: selectAccountLabel },
                      ...selectOptions,
                    ]}
                    filterOption={this.filterValues}
                  />
                </Cell>
              );
            }}
            width={widthUnit}
          />
        ) : null}
        <Column
          columnKey="amount"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.amount}
            >
              {/* <Flexbox>
                  <FlexElement>
                    <AxoLocal entity='InvoiceInvoicesum' defaultValue='Beløb'/>
                  </FlexElement>
                  { showColumnSelectors ? (
                    <React.Fragment>
                      <FlexElement flexGrow={1} className='text-right'>
                        <span onClick={(event) => { event.stopPropagation() }}>
                          <AxoCheckbox
                            checked={selectedBankPostingColumn === 'amount'}
                            onChange={() => onSelectBankPostingColumn('amount')}
                          />
                        </span>
                      </FlexElement>
                      <FlexElement 
                        flexGrow={1} 
                        className='text-right'
                        style={{ paddingRight: '5px' }}
                        onClick={(event) => { event.stopPropagation() }}>
                        <select
                          value={priceFilter}
                          onChange={event => onUpdatePriceFilter(event.target.value)}
                        >
                          <option value={0}>...</option>
                          <option value={1}>+</option>
                          <option value={2}>-</option>
                        </select>
                      </FlexElement>
                    </React.Fragment>
                  ) : null }
                </Flexbox> */}
              <Flexbox>
                <FlexElement>
                  <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />
                </FlexElement>
                {showColumnSelectors && (
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="amount"
                      filterType={FilterTypes.NUMBER}
                    />
                  </FlexElement>
                )}
              </Flexbox>
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell
                key={entry.id}
                {...props}
                className="text-right rightPadding"
              >
                {this.formatAmount(entry.amount)}
              </Cell>
            );
          }}
          width={widthUnit}
        />
        {/* <Column
            columnKey="accountBalance"
            header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.amount}>
                <Flexbox>
                  <FlexElement>
                     <AxoLocal entity='axoidcode157'defaultValue={'Saldo'}/>
                  </FlexElement>
                  { showColumnSelectors ? (
                    <FlexElement flexGrow={1} className='text-right'>
                      <span onClick={(event) => { event.stopPropagation() }}>
                        <AxoCheckbox
                          checked={selectedBankPostingColumn === 'accountBalance'}
                          onChange={() => onSelectBankPostingColumn('accountBalance')}
                        />
                      </span>
                    </FlexElement>
                  ) : null }
                </Flexbox>
              </SortHeaderCell>}
            cell={props => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
              <Cell key={entry.id} {...props} className='text-right'>
                {this.formatAmount(entry.accountBalance)}
              </Cell> )}}
            width={widthUnit}
          /> */}
      </Table>
    );
  }

  formatAmount(amount) {
    if (!amount) {
      return "0.00";
    }
    return NumberService.formatDecimal(amount);
  }
}

export default Dimensions({
  elementResize: true,
})(BankPostingTable);
