import React from "react";
import UserInfoService from "../../services/UserInfoService";
import DataActions from "../../services/DataAccess/DataActions";

import {
  Grid,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
} from "react-bootstrap";

import { AxoLocal } from "../../utilities/LexUtilities";
export default class UserListModal extends React.PureComponent {
  //Props
  //userProfile
  //friends
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      users: [],
      friendRequestUserIds: [],
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = (userProfileArray) => {
    this.setState({
      showModal: true,
      users: userProfileArray,
    });
  };

  renderFriendButton = (user) => {
    if (this.props.friends.find((f) => f.id === user.id)) {
      return (
        <span>
          <strong>
            <AxoLocal entity="UserListModalFriend" defaultValue={"Venner"} />{" "}
          </strong>
        </span>
      );
    } else if (this.props.userProfile.id === user.id) {
      return (
        <span>
          <strong>
            <AxoLocal entity="UserListModalYou" defaultValue={"Dig"} />{" "}
          </strong>
        </span>
      );
    } else if (this.state.friendRequestUserIds.find((id) => id === user.id)) {
      return (
        <span>
          <strong>
            <AxoLocal
              entity="UserListModalFriendrequestsent"
              defaultValue={"Venneanmodning afsendt"}
            />
          </strong>
        </span>
      );
    }
    return (
      <Button
        className="Lex-button-2"
        onClick={this.onAddFriend.bind(this, user)}
      >
        <AxoLocal entity="UserListModaladdfriend" defaultValue={"Tilføj ven"} />
      </Button>
    );
  };

  onAddFriend = (user) => {
    DataActions.createFriendRequest({
      requestUser: this.props.userProfile,
      receiver: user,
    }).then((response) => {
      if (response.status === 200) {
        this.state.friendRequestUserIds.push(user.id);
        this.setState({
          friendRequestUserIds: this.state.friendRequestUserIds,
        });
      }
    });
  };

  renderUserNodes = () => {
    return this.state.users.map((user) => {
      var userString = UserInfoService.getDisplayName(user);
      return (
        <Row key={user.id} style={{ padding: "5px" }}>
          <Col xs={6}>
            <img
              alt=""
              className="img-circle"
              src={UserInfoService.getProfileImageSource(user)}
              width="30"
              height="30"
            />{" "}
            {userString}
          </Col>
          <Col xs={6}>{this.renderFriendButton(user)}</Col>
        </Row>
      );
    });
  };

  render() {
    return (
      <Modal
        className="center-dialog"
        show={this.state.showModal}
        onHide={this.close}
      >
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
          closeButton
        >
          <Modal.Title>
            <AxoLocal
              entity="UserListModaladdUserswholiketheposting"
              defaultValue={"Brugere der synes om opslaget"}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          <Grid fluid>{this.renderUserNodes()}</Grid>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.close}>
                <AxoLocal
                  entity="DocumentselectedDocument"
                  defaultValue={"Ok"}
                />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
