import React from "react";

import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import { MediumOrLarger } from "../utilities/Responsive";

import {
  RoutingService,
  ApiService,
  DataStore,
  LocalizationService,
  // StripeAuthenticationService,
  // VatService,
  ApiKeys,
  DataActions,
} from "../services/AxoServices";

import {
  AxoCheckbox,
  Icon,
  LoadingIcon,
  AxoLocal,
  getText,
  ctx,
  Link,
  withRouter,
  FlagMenu,
  LexButton,
  AxoFooter,
} from "../utilities/LexUtilities";

import MitID from "../resources/images/MitID.png";

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginViewModel: {
        userName: "",
        password: "",
        rememberMe: false,
      },
      showAccountError: false,
      showTwoFactorCodeError: false,
      userRole: "",
      checkTwoFactor: false,
      awaitTwoFactorCode: false,
      twoFactorProvider: "",
      twoFactorCode: "",
      rememberTwoFactor: false,
      attemptingLogin: false,
      showServerError: false,
      showConfirmMessage: false,
      verifyingTwoFactorCode: false,
      sendingCode: "",
      subscriptionErrorType: "",
    };
    this.userProfile = {};
    this.role = "";
    this.timeOut = null;
    this.tokenSubmitted = false;
    // this.paylike = window.Paylike(ApiKeys.payLikeApiKey);
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale("Login");
    if (!!this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  getPath = (path) => {
    return RoutingService.getPath(path);
  };

  onModelPropertyChange = (propertyName, event) => {
    this.setState({
      loginViewModel: {
        ...this.state.loginViewModel,
        [propertyName]: event.target.value,
      },
    });
  };

  onLogin = () => {
    var model = Object.assign({}, this.state.loginViewModel);
    if (!model.userName || !model.password) {
      return;
    }
    this.setState({ attemptingLogin: true });

    model.registerEmailSubject = ctx.getSync("SignupMailSubject");
    model.registerEmailMessage = ctx.getSync("SignupMailMessage");
    model.messageUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      RoutingService.getAccountPath("ConfirmEmail");

    DataActions.login(model)
      .then((response) => {
        this.setState({ attemptingLogin: false });
        if (response.ok) {
          this.setState({ showAccountError: false });
          response.json().then((body) => {
            if (body.result === "Success") {
              this.handleSuccess(body.role);
            } else if (body.result === "TwoFactor") {
              this.setState({
                checkTwoFactor: true,
                twoFactorOptions: body.options,
                userRole: body.role,
              });
            } else if (body.result === "RequireConfirmed") {
              this.setState({
                showConfirmMessage: true,
              });
            }
          });
        } else {
          this.setState({ showAccountError: true });
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ showServerError: true });
      });
  };

  handleSuccess = (role) => {
    let plan = DataStore.getSelectedPlan();
    var planId = plan.planId;
    if (planId) {
      ApiService.getUserProfile().then((userProfile) => {
        if (!userProfile.planId) {
          this.userProfile = userProfile;
          this.userProfile.planId = planId;
          this.role = role;
          this.activateSubscription();
        } else {
          DataStore.setSelectedPlan(null);
          this.setState({ verifyingTwoFactorCode: false });
          this.redirectByRole(role);
        }
      });
    } else {
      this.setState({ verifyingTwoFactorCode: false });
      this.redirectByRole(role);
    }
  };

  activateSubscription = () => {
      this.doActivateSubscription({
        paylikeTransactionId: null,
        paylikeCardId: null,
      });
    
    //var plan = DataStore.getSelectedPlan();
    //Do not require credit card when signing up for free or trial plans
    // if (
    //   (plan.amount === 0 || plan.trialPeriod > 0) &&
    //   (plan.costPerUnit === 0 || plan.freeUnitsPerInterval > 0)
    // ) {
    //   this.doActivateSubscription({
    //     paylikeTransactionId: null,
    //     paylikeCardId: null,
    //   });
    // } else {
    //   this.paylike.popup(
    //     {
    //       currency: plan.currency.toUpperCase(),
    //       amount: plan.amount,
    //       title: plan.name,
    //       fields: [
    //         {
    //           name: "name",
    //           label: getText("axoAccounting7", "Navn"),
    //           type: "string",
    //           required: true,
    //           value:
    //             this.userProfile.firstName + " " + this.userProfile.lastName,
    //         },
    //         {
    //           name: "email",
    //           label: getText("socialSidebarContainerEmail", "Email"),
    //           type: "email",
    //           required: true,
    //           value: this.userProfile.eMail,
    //         },
    //       ],
    //       custom: {
    //         userName: this.userProfile.userName,
    //         subscriptionPlan: plan.planId + " - " + plan.name,
    //       },
    //     },
    //     (err, res) => {
    //       if (err) {
    //         console.log(err);
    //         return;
    //       }

    //       this.doActivateSubscription({
    //         paylikeTransactionId: !!res.transaction ? res.transaction.id : null,
    //         paylikeCardId: !!res.card ? res.card.id : null,
    //       });
    //     }
    //   );
    // }
  };

  doActivateSubscription = async ({ paylikeTransactionId, paylikeCardId }) => {
    try {
      let response = await ApiService.activateSubscription({
        userProfile: this.userProfile,
        paylikeTransactionId,
        paylikeCardId,
      });

      if (response.ok) {
        var responseData = await response.json();
        if(!!responseData.url) {
          location.href = responseData.url;
          return;
        }

        let updatedUserProfile = await DataStore.fetchUserProfile();
        if (updatedUserProfile.id) {
          DataStore.setSelectedPlan(null);
          this.setState({ verifyingTwoFactorCode: false });
          this.redirectByRole(this.role);
        } else {
          this.handleActivationError("Could not fetch user profile.");
        }
      } else if (response.status === 400) {
        let message = await response.text();
        this.handleActivationError(message);
      }
    } catch (error) {
      this.handleActivationError(error.message);
    }
  };

  handleActivationError = (reason) => {
    console.log(reason);

    let subscriptionErrorType = reason;
    this.setState({
      awaitTwoFactorCode: false,
      verifyingTwoFactorCode: false,
      twoFactorCode: "",
      subscriptionErrorType,
    });
    if (subscriptionErrorType) {
      this.timeOut = setTimeout(() => {
        this.setState({ subscriptionErrorType: "" });
      }, 5000);
    }
  };

  onRemember = (event) => {
    var model = Object.assign({}, this.state.loginViewModel);
    model.rememberMe = event.target.checked;
    this.setState({ loginViewModel: model });
  };

  onRememberTwoFactor = (event) => {
    this.setState({ rememberTwoFactor: event.target.checked });
  };

  onChangeCode = (event) => {
    this.setState({ twoFactorCode: event.target.value });
  };

  sendCode = (option) => {
    this.setState({
      twoFactorProvider: option,
      sendingCode: option,
    });
    ApiService.sendTwoFactor({
      selectedProvider: option,
      providers: [],
      returnUrl: "",
      rememberMe: this.state.loginViewModel.rememberMe,
      messageSubject: getText("loginTwoFactorSubject", "Nem kode"),
      messageText: getText("loginTwoFactorMessage", "Din kode er:"),
    })
      .then((response) => {
        this.setState({ sendingCode: "" });
        if (response.ok) {
          this.setState({
            checkTwoFactor: false,
            awaitTwoFactorCode: true,
          });
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ sendingCode: "" });
      });
  };

  onVerifyCode = () => {
    this.setState({ verifyingTwoFactorCode: true });
    ApiService.verifyCode({
      provider: this.state.twoFactorProvider,
      code: this.state.twoFactorCode,
      rememberBrowser: this.state.rememberTwoFactor,
      rememberMe: this.state.rememberMe,
    }).then((response) => {
      if (response.ok) {
        this.handleSuccess(this.state.userRole);
      } else {
        this.setState({
          showTwoFactorCodeError: true,
          verifyingTwoFactorCode: false,
        });
      }
    });
  };

  redirectByRole = (role) => {
    var { history, redirect } = this.props;

    DataStore.resetStore();

    if (redirect) {
      history.push(this.props.redirect);
    } else if (role === "Lawyer" || role === "Accountant" || role === "Admin") {
      history.push("/Dashboard");
    } else if (
      role === "Client" ||
      role === "AccountingClient" ||
      role === "SocietyMember" ||
      role === "EasyID"
    ) {
      history.push("/ClientDashboard");
    } else if (role === "XLink") {
      history.push("/Xlink");
    } else if (role === "Society") {
      history.push("/Dashboard");
    }
  };

  renderFooter = () => {
    let {
      checkTwoFactor,
      twoFactorOptions,
      sendingCode,
      awaitTwoFactorCode,
      showTwoFactorCodeError,
      subscriptionErrorType,
    } = this.state;
    if (checkTwoFactor && twoFactorOptions.length > 0) {
      let emailOption = twoFactorOptions.includes("Email");
      let phoneOption = twoFactorOptions.includes("Phone");
      return (
        <span>
          <FormGroup>
            <Grid fluid>
              <Row className="axoorange mediumText">
                <strong>
                  <AxoLocal
                    entity="LogintwoFactorOptions"
                    defaultValue={
                      "To-faktor autentificering er slået til. Vælg hvordan valideringskoden skal sendes."
                    }
                  />
                </strong>
              </Row>
              <Row>
                <Col
                  sm={2}
                  className="nopadding"
                  style={{ paddingTop: 10 }}
                ></Col>
                <Col sm={4} className="nopadding" style={{ paddingTop: 10 }}>
                  {emailOption ? (
                    <Button
                      disabled={!!sendingCode}
                      className="Lex-button-2 center"
                      style={{
                        height: "65px",
                        width: "250px",
                        fontSize: "17px",
                        fontWeight: "normal",
                      }}
                      onClick={this.sendCode.bind(this, "Email")}
                    >
                      <AxoLocal
                        entity="LoginsendCodeEmail"
                        defaultValue={"Send email"}
                      />
                      <LoadingIcon show={sendingCode === "Email"} />
                    </Button>
                  ) : null}
                </Col>
                <Col sm={4} className="nopadding" style={{ paddingTop: 10 }}>
                  {phoneOption ? (
                    <Button
                      disabled={!!sendingCode}
                      className="Lex-button-2 center"
                      style={{
                        height: "65px",
                        width: "250px",
                        fontSize: "17px",
                        fontWeight: "normal",
                      }}
                      onClick={this.sendCode.bind(this, "Phone")}
                    >
                      <AxoLocal
                        entity="LoginsendCodePhone"
                        defaultValue={"Send sms"}
                      />
                      <LoadingIcon show={sendingCode === "Phone"} />
                    </Button>
                  ) : null}
                </Col>
                <Col
                  sm={2}
                  className="nopadding"
                  style={{ paddingTop: 10 }}
                ></Col>
              </Row>
            </Grid>
          </FormGroup>
        </span>
      );
    }
    if (awaitTwoFactorCode) {
      return (
        <span>
          <FormGroup>
            {showTwoFactorCodeError ? (
              <div className="axored">
                <AxoLocal
                  entity="LoginshowTwoFactorCodeError"
                  defaultValue={"Koden kunne ikke verificeres"}
                />
              </div>
            ) : null}
            <div className="axoorange mediumText bottomPadding">
              <strong>
                Du skal nu indtaste den valideringskode som du har modtaget på
                enten sms eller email.
              </strong>
            </div>
            <InputGroup bsSize="large">
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-lock-5" />
              </InputGroup.Addon>
              <AxoLocal
                componentClass={FormControl}
                autoFocus
                type="text"
                className="borderFormControlfocus"
                value={this.state.twoFactorCode}
                onChange={this.onChangeCode}
                componentAttribute="placeholder"
                entity="LoginEntercodehere"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <AxoCheckbox
              checked={this.state.rememberTwoFactor}
              onChange={this.onRememberTwoFactor}
            />
            {/* MissingEntity */}
            <AxoLocal
              entity="LoginonRememberTwoFactor"
              defaultValue={
                "Husk min valideringskode, så jeg ikke skal indtaste den næste gang jeg logger på fra denne computer."
              }
            />
          </FormGroup>
          <FormGroup>
            <Grid fluid>
              <Row>
                <Col
                  xs={6}
                  className="nopadding"
                  style={{ paddingTop: 10 }}
                ></Col>
                <Col xs={6} className="text-center nopadding">
                  <Button
                    className="Lex-button-2"
                    style={{
                      height: "65px",
                      width: "250px",
                      fontSize: "17px",
                      fontWeight: "normal",
                    }}
                    disabled={
                      this.state.twoFactorCode.length === 0 ||
                      this.state.verifyingTwoFactorCode
                    }
                    onClick={this.onVerifyCode}
                  >
                    {this.state.verifyingTwoFactorCode ? (
                      <span>
                        <img
                          alt=""
                          src="/imgs/app/loading.gif"
                          className="img-circle"
                          width="20"
                          height="20"
                        />
                        &nbsp;
                      </span>
                    ) : null}
                    <AxoLocal
                      entity="LoginonVerifyCode"
                      defaultValue={"Indsend koden"}
                    />
                  </Button>
                </Col>
              </Row>
            </Grid>
          </FormGroup>
        </span>
      );
    }
    if (!!subscriptionErrorType) {
      if (subscriptionErrorType.includes("CountryMismatch")) {
        return (
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <div className="axored">
                  <AxoLocal
                    entity="axoAccounting61"
                    defaultValue={
                      "Dit kreditkort skal være udstedt i samme land som du valgte da du udfyldte din brugerprofil."
                    }
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        );
      }
      if (subscriptionErrorType.includes("MissingVatNumber")) {
        return (
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <div className="axored">
                  <AxoLocal
                    entity="axoAccounting65"
                    defaultValue={"CVR nummeret kunne ikke valideres."}
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        );
      }
      return (
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <div className="axored">
                <AxoLocal
                  entity="axoAccounting66"
                  defaultValue={
                    "Der skete en fejl under aktiveringen af dit abonnement. Prøv igen senere."
                  }
                />
              </div>
            </Col>
          </Row>
        </Grid>
      );
    }
    return (
      <Grid fluid>
        <Row>
          <Col sm={6} className="nopadding"></Col>
          <Col sm={6} className="text-center nopadding">
            <Button
              className="Lex-button-2"
              style={{
                height: "65px",
                width: "250px",
                fontSize: "17px",
                fontWeight: "normal",
              }}
              disabled={this.state.attemptingLogin}
              onClick={this.onLogin}
            >
              <span>
                {this.state.attemptingLogin ? (
                  <img
                    alt=""
                    src="/imgs/app/loading.gif"
                    className="img-circle"
                    width="20"
                    height="20"
                  />
                ) : (
                  <Icon glyph="icon-fontello-login" />
                )}
                &nbsp;&nbsp;
                <AxoLocal entity="LoginonLogin" defaultValue={"Log ind"} />
              </span>
            </Button>
          </Col>
        </Row>
      </Grid>
    );
  };

  goToMitID = () => {
    let { history } = this.props;

    history.push("/Users/MitID");
  };

  goToFront = () => {
    let { history } = this.props;

    history.push("/");
    window.scrollTo(0, 0);
  };

  render() {
    var model = this.state.loginViewModel;
    return (
      <div className="login SideHeight" style={{ paddingTop: "10px" }}>
        <Grid fluid className="nopadding">
          <Row>
            <Col xs={12} style={{ maxWidth: "100%" }}>
              <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center">
                <AxoLocal entity="LoginonLogin" defaultValue={"Log ind"} />
                <span role="button" onClick={this.goToFront}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img
                    alt=""
                    className="hidden-lg hidden-sm hidden-md"
                    src="/imgs/app/Axologo3-30x30.png"
                  />
                  <img
                    alt=""
                    className="hidden-lg hidden-md hidden-xs"
                    src="/imgs/app/Axologo3-35x35.png"
                  />
                  <img
                    alt=""
                    className="hidden-lg hidden-sm hidden-xs"
                    src="/imgs/app/Axologo3-40x40.png"
                  />
                  <img
                    alt=""
                    className="hidden-md hidden-sm hidden-xs"
                    src="/imgs/app/Axologo3-40x40.png"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <FlagMenu
                  // className='Lex-button-2'
                  userSettings={{}}
                />
                &nbsp;&nbsp;&nbsp;
              </div>
            </Col>
          </Row>
        </Grid>
        <div style={{ maxWidth: "990px", margin: "auto" }}>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ marginTop: "25px" }}>
                <div>
                  <div>
                    <div style={{ padding: 0 }}>
                      <div
                        className="text-center axoblue"
                        style={{ borderRadius: "0px" }}
                      ></div>
                      <div className="text-center" style={{ padding: 12.5 }}>
                        {/* <div style={{marginTop: 12.5, marginBottom: 12.5}}>
                          <Button id='facebook-btn' lg bsStyle='darkblue' type='submit' onClick={this.back}>
                            <Icon glyph='icon-fontello-facebook' />
                            <span>Sign in <span className='hidden-xs'>with facebook</span></span>
                          </Button>
                        </div> */}
                        {/* <div>
                          <a id='twitter-link' href='#' onClick={this.back}><Icon glyph='icon-fontello-twitter' /><span> or with twitter</span></a>
                        </div> */}
                      </div>
                      <div>
                        <div className="text-center" style={{ padding: 12.5 }}>
                          <AxoLocal
                            entity="LoginUseAxolexlog"
                            defaultValue={"Anvend dit Axolex login"}
                          />
                          <span
                            className="axoblue"
                            style={{ fontSize: "26px" }}
                          >
                            &nbsp;&nbsp; / &nbsp;&nbsp;
                          </span>
                          <LexButton onClick={this.goToMitID}>
                            <img alt="" src={MitID} width="90" height="20" />
                          </LexButton>
                        </div>
                        &nbsp;&nbsp;
                        <div
                          style={{
                            padding: 25,
                            paddingTop: 0,
                            paddingBottom: 0,
                            margin: "auto",
                            marginBottom: 25,
                            marginTop: 25,
                          }}
                        >
                          <Form>
                            <FormGroup controlId="emailaddress">
                              {this.state.showAccountError ? (
                                <div className="axored">
                                  <Icon glyph="icon-fontello-info-3" />
                                  &nbsp;&nbsp;
                                  <AxoLocal
                                    entity="LoginshowAccountError"
                                    defaultValue={
                                      "Forkert brugernavn eller password"
                                    }
                                  />
                                </div>
                              ) : null}
                              {this.state.showServerError ? (
                                <div className="axored">
                                  <Icon glyph="icon-fontello-info-3" />
                                  &nbsp;&nbsp;
                                  <AxoLocal
                                    entity="LoginshowServerError"
                                    defaultValue={
                                      "Der skete en fejl. Prøv igen senere."
                                    }
                                  />
                                </div>
                              ) : null}
                              {this.state.showConfirmMessage ? (
                                <div className="axored">
                                  <Icon glyph="icon-fontello-info-3" />
                                  &nbsp;&nbsp;
                                  <div>
                                    <AxoLocal
                                      entity="LoginshowAccountError1a"
                                      defaultValue={
                                        "Din email skal bekræftes før du kan logge ind."
                                      }
                                    />
                                  </div>
                                  <div>
                                    {" "}
                                    <AxoLocal
                                      entity="LoginshowAccountError2a"
                                      defaultValue={
                                        "Et link til bekræftelse er blevet sendt til din email."
                                      }
                                    />
                                  </div>
                                </div>
                              ) : null}
                              <InputGroup bsSize="large">
                                <MediumOrLarger>
                                  <InputGroup.Addon>
                                    <span className="axolexunicode">
                                      {" "}
                                      &#9993;{" "}
                                    </span>{" "}
                                    &nbsp;/&nbsp;
                                    <span className="axolexunicode">
                                      {" "}
                                      &#x260f;{" "}
                                    </span>{" "}
                                    &nbsp;/&nbsp;
                                    <span>
                                      {" "}
                                      <Icon glyph="icon-simple-line-icons-users" />{" "}
                                    </span>
                                  </InputGroup.Addon>
                                </MediumOrLarger>
                                <AxoLocal
                                  componentClass={FormControl}
                                  autoFocus
                                  type="text"
                                  className="borderFormControlfocus"
                                  value={model.userName}
                                  onChange={this.onModelPropertyChange.bind(
                                    this,
                                    "userName"
                                  )}
                                  componentAttribute="placeholder"
                                  entity="bccMailplaceholderBrugernavn"
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup controlId="password">
                              <InputGroup bsSize="large">
                                <MediumOrLarger>
                                  <InputGroup.Addon>
                                    <Icon glyph="icon-fontello-key" />
                                  </InputGroup.Addon>
                                </MediumOrLarger>
                                <AxoLocal
                                  componentClass={FormControl}
                                  type="password"
                                  className="borderFormControlfocus"
                                  value={model.password}
                                  onChange={this.onModelPropertyChange.bind(
                                    this,
                                    "password"
                                  )}
                                  onKeyUp={(event) => {
                                    //Log in when pressing return button
                                    if (event.keyCode === 13) {
                                      this.onLogin();
                                    }
                                  }}
                                  componentAttribute="placeholder"
                                  entity="bccMailplaceholderpassword"
                                />
                              </InputGroup>
                              <br />
                              <Link
                                to={RoutingService.getAccountPath("Signup")}
                              >
                                <AxoLocal
                                  entity="LoginsSignup"
                                  defaultValue={"Opret ny konto"}
                                />
                              </Link>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <Link
                                to={RoutingService.getAccountPath(
                                  "ForgotPassword"
                                )}
                              >
                                <AxoLocal
                                  entity="LoginForgotUsersPassword"
                                  defaultValue={"Glemt dit password?"}
                                />
                              </Link>
                            </FormGroup>
                            <FormGroup controlId="password">
                              <AxoCheckbox
                                checked={model.rememberMe}
                                onChange={this.onRemember}
                              />
                              <AxoLocal
                                entity="LoginrememberMe"
                                defaultValue={"Husk mig på denne computer"}
                              />
                            </FormGroup>
                            {this.renderFooter()}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
        <AxoFooter />
      </div>
    );
  }
}

export default withRouter(Login);
