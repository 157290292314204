import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { axolexApi } from "./ApiSlice";
import contactDataSliceReducer from "./ReduxData/contactData";
import { throttle } from "lodash";
import StorageService from "../StorageService";

const store = configureStore({
  reducer: {
    contactData: contactDataSliceReducer,
    [axolexApi.reducerPath]: axolexApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(axolexApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

store.subscribe(
  throttle(() => {
    StorageService.saveSessionItem(
      "selectedContact",
      store.getState().contactData.selectedContact
    );
  }),
  1000
);

export default store;
