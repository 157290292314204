import React from "react";
import Responsive from "react-responsive";

const ExtraSmall = ({ children }) => (
  <Responsive maxWidth={767} children={children} />
);
const Small = ({ children }) => (
  <Responsive minWidth={768} maxWidth={991} children={children} />
);
const Medium = ({ children }) => (
  <Responsive minWidth={992} maxWidth={1199} children={children} />
);
const Large = ({ children }) => (
  <Responsive minWidth={1200} children={children} />
);

const SmallOrSmaller = ({ children }) => (
  <Responsive maxWidth={991} children={children} />
);
const MediumOrSmaller = ({ children }) => (
  <Responsive maxWidth={1199} children={children} />
);
// const ExtraSmallOrLarger = ({ children }) => <Responsive minWidth={300} children={children} />;
const SmallOrLarger = ({ children }) => (
  <Responsive minWidth={768} children={children} />
);
const MediumOrLarger = ({ children }) => (
  <Responsive minWidth={992} children={children} />
);

// Specialized queries can be done as:
// import MediaQuery from 'react-responsive';

// <MediaQuery minWidth={1200}>
//   <div>This will only render on large screens</div>
// </MediaQuery>

export {
  ExtraSmall,
  Small,
  Medium,
  Large,
  SmallOrSmaller,
  MediumOrSmaller,
  SmallOrLarger,
  MediumOrLarger,
};
