import React from "react";
import UserInfoService from "../../services/UserInfoService";

import { Button, ButtonToolbar } from "react-bootstrap";

import { UserSearchBox, AxoLocal } from "../../utilities/LexUtilities";

export default class CaseSharing extends React.PureComponent {
  //Props
  //sharingUsers
  //groupUsers
  //friends
  //onShare
  constructor(props) {
    super(props);

    this.state = {
      sharingUsers: props.sharingUsers,
    };
  }

  handleSelectedUser = (selectedUser) => {
    var sharingUsers = this.state.sharingUsers.slice();
    sharingUsers.push(selectedUser);
    this.setState({ sharingUsers });
  };

  onRemoveUser = (userId) => {
    var sharingUsers = this.state.sharingUsers.filter((p) => p.id !== userId);
    this.setState({ sharingUsers });
  };

  renderUserNodes = () => {
    return this.state.sharingUsers.map((sharingUser) => {
      return (
        <div
          key={sharingUser.id}
          style={{
            borderBottom: "1px solid #4CAF50",
            marginRight: "10px",
            hover: "#A0C7F2",
            paddingTop: "10px",
          }}
        >
          {UserInfoService.getDisplayName(sharingUser)}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span
            role="button"
            onClick={this.onRemoveUser.bind(this, sharingUser.id)}
            className="icon-fontello-scissors axored"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      );
    });
  };

  onShare = () => {
    this.props.onShare(this.state.sharingUsers);
  };

  onChooseGroup = () => {
    this.setState({ sharingUsers: this.props.groupUsers });
  };

  render() {
    var { groupUsers, friends } = this.props;
    var availableUsers = groupUsers
      .concat(friends)
      .filter((user) => !this.state.sharingUsers.find((s) => s.id === user.id));

    return (
      <div style={{ maxWidth: "600px" }}>
        <div>
          <AxoLocal
            componentClass={UserSearchBox}
            maxWidth="600px"
            userProfiles={availableUsers}
            handleSelectedUser={this.handleSelectedUser}
            clearOnSelect
            componentAttribute="placeholder"
            entity="CasesharingAB"
          />
        </div>
        <div className="flexbox-responsive" style={{ marginBottom: "10px" }}>
          {this.renderUserNodes()}
        </div>
        <div>
          <ButtonToolbar>
            {groupUsers.length > 0 ? (
              <Button onClick={this.onChooseGroup} className="Lex-button-2">
                <AxoLocal
                  entity="Casesharing1"
                  defaultValue={"Vælg gruppebrugere"}
                />
              </Button>
            ) : null}
            <Button onClick={this.onShare} className="Lex-button-2">
              {this.props.creating ? (
                <span>
                  {" "}
                  <AxoLocal
                    entity="Casesharing2"
                    defaultValue={"Opret sag og del med brugere"}
                  />
                </span>
              ) : (
                <span>
                  <AxoLocal
                    entity="Casesharing3"
                    defaultValue={"Gem og del med brugere"}
                  />
                </span>
              )}
            </Button>
            <Button onClick={this.props.onCancel} className="Lex-button-2">
              <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
            </Button>
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}
