import React from "react";
import moment from "moment";
import NumericInput from "react-numeric-input";
import ReactSelect from "react-select";

// import Select from 'react-select';

import {
  // UserInfoService,
  TimeEntryService,
  // ApiService
  // AuthorizationService
} from "../../services/AxoServices";

import {
  // Icon,
  AxoLocal,
  AsyncButton,
  getText,
  AxoDateTime,
  AxoCheckbox,
} from "../../utilities/LexUtilities";

import {
  Form,
  ControlLabel,
  FormGroup,
  FormControl,
  // InputGroup,
} from "react-bootstrap";

//Creates a request for signing for a subscription, using the invoice and payment request system
export default class SimpleSubscriptionForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      series: 1,
      title: "",
      subject: "",
      message: "",
      timeEntry: {
        description: "",
        timeSpent: 0,
        units: 1,
        unitType: "Units",
        rate: 0.0,
        tax: 0.0,
        attachment: "",
        expenses: 0,
        aConto: 0,
      },
      amount: 0.0,
      isRegistration: true,
      showErrorMessage: false,
      showServerErrorMessage: false,
      subscriptionAction: "Draft", //Set to Approve to activate subscription payment.
      subscriptionCurrentPeriodEnd: moment.utc().add(1, "months"),
      subscriptionInterval: "Month",
      reverseVAT: false, //Used to track whether subscriptions should be charged on initial registration.
    };
  }

  componentDidMount() {
    // ApiService.getNextInvoiceNumber()
    // .then(numberInfo => {
    //   this.setState({ series: numberInfo.next });
    // })
  }

  componentWillReceiveProps(nextProps) {}

  onChangeTimeEntry = (timeEntry) => {
    this.setState({ timeEntry });
    // this.props.onTimeEntryUpdate(timeEntry);
  };

  onChangeWorkDate = (dateTime) => {
    this.onChangeProperty("workDate", dateTime.format());
  };

  onDescChange = (event) => {
    this.onChangeProperty("description", event.target.value);
  };

  onChangeRate = (valueAsNumber) => {
    this.onChangeProperty("rate", valueAsNumber);
  };

  onChangeTax = (valueAsNumber) => {
    this.onChangeProperty("tax", valueAsNumber / 100);
  };

  onChangeProperty = (propertyName, value) => {
    this.onChangeTimeEntry({
      ...this.state.timeEntry,
      [propertyName]: value,
    });
  };

  getTimeEntriesTotal = () => {
    let { timeEntry } = this.state;

    var timeEntryTotal = TimeEntryService.getTotalValue(timeEntry);
    return timeEntryTotal;
  };

  validateForm = () => {
    let { title, subject, timeEntry } = this.state;

    if (!title || !subject || !timeEntry.description) {
      return false;
    }
    return true;
  };

  onSubmit = async () => {
    let { createSimpleInvoice } = this.props;

    if (!this.validateForm()) {
      this.setState({ showErrorMessage: true });
      return false;
    }

    let result = await createSimpleInvoice(this.state);
    if (!result) {
      this.setState({ showServerErrorMessage: true });
      setTimeout(() => {
        this.setState({ showServerErrorMessage: true });
      }, 5000);
    }
    return result;
  };

  getPeriodOptions = () => {
    return [
      { value: "Day", label: getText("AdminTabView9") }, //Dag
      { value: "Week", label: getText("AdminTabView10") }, //Uge
      { value: "Month", label: getText("AdminTabView11") }, //Måned
      { value: "Year", label: getText("AdminTabView12") }, //År
    ];
  };

  render() {
    // let {
    //   userProfile,
    // } = this.props;

    let {
      timeEntry,
      title,
      subject,
      message,
      amount,
      subscriptionAction,
      subscriptionCurrentPeriodEnd,
      subscriptionInterval,
      reverseVAT,
      showErrorMessage,
      showServerErrorMessage,
    } = this.state;

    let activeSubscription = subscriptionAction === "Approve";

    let periodOptions = this.getPeriodOptions();
    let startOfToday = moment.utc().startOf("day");

    return (
      <div className="standardMaxWidth leftPadding topPadding">
        <Form>
          <FormGroup>
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
                paddingTop: "10px",
              }}
            >
              <AxoLocal
                entity="CalendarEventBoxFormtitle"
                defaultValue={"Titel"}
              />
            </ControlLabel>
            <FormControl
              type="text"
              placeholder={"Intern titel"}
              value={title}
              onChange={(event) => this.setState({ title: event.target.value })}
            />
          </FormGroup>
          {/* <ControlLabel style={{textAlign: 'left',fontSize:'16px',fontWeight:'normal'}}>
            { timeEntry.unitType === 'Hours' ? (
              <AxoLocal key='hours' entity='unitHoursFullName' defaultValue={'Timer'}/>
            ) : (
              <AxoLocal key='units' entity='unitUnitsFullName' defaultValue={'Antal enheder'}/>
            ) }
          </ControlLabel>
          <NumericInput 
            className="form-control" 
            onChange={(value) => { this.onChangeProperty('units', value )} }
            step={1} 
            precision={2}
            value={timeEntry.units}
          /> */}

          {/* <ControlLabel style={{textAlign: 'left',fontSize:'16px',fontWeight:'normal', paddingTop:'10px'}}>
            <Icon glyph='icon-fontello-money-1' />&nbsp;
            <span>
              <AxoLocal key='units' entity='CalendarEventFormRate' defaultValue={'Pris / enhed'}/>
              &nbsp;({UserInfoService.getCurrency(userProfile)}/<AxoLocal key='unit' entity='TimeEntriesViewTimeUnit'defaultValue={'unit'}/>)
            </span>
          </ControlLabel>
          <NumericInput 
            className="form-control" 
            onChange={this.onChangeRate}
            step={1} 
            precision={2}
            value={timeEntry.rate}
          /> */}

          {/* <ControlLabel style={{textAlign: 'left',fontSize:'16px',fontWeight:'normal', paddingTop:'10px'}}>
            <Icon glyph='icon-simple-line-icons-pie-chart' />&nbsp; 
            <AxoLocal entity='CalendarEventFormVAT'defaultValue={'Moms i %'}/></ControlLabel>
          <NumericInput 
            className="form-control" 
            onChange={this.onChangeTax}
            step={1} 
            precision={2}
            value={timeEntry.tax * 100}
          />
          <br/>
          <FormGroup controlId='inputWithIcon' bsSize='large'>
            <ControlLabel style={{textAlign: 'left',fontSize:'16px',fontWeight:'normal'}}>
              <AxoLocal entity='CalendarEventFormTotal'defaultValue={'Samlet værdi i'}/>
              &nbsp;{UserInfoService.getCurrency(userProfile)}.</ControlLabel>
            <InputGroup>
              <InputGroup.Addon>
                <Icon glyph='icon-fontello-math' />
              </InputGroup.Addon>
              <FormControl type='number' disabled step="1" value={this.getTimeEntriesTotal().toFixed(2)} />
            </InputGroup>
          </FormGroup> */}
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
              paddingTop: "10px",
            }}
          >
            <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />
          </ControlLabel>
          <NumericInput
            className="form-control"
            onChange={(value) => this.setState({ amount: value })}
            step={1}
            precision={2}
            value={amount}
          />
          <FormGroup>
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="axoEntityidcode194"
                defaultValue="Beskrivelse af abonnement"
              />
            </ControlLabel>
            <FormControl
              componentClass="textarea"
              rows={3}
              placeholder={"Månedlig betaling af medlemskontingent"}
              value={timeEntry.description}
              onChange={this.onDescChange}
            />
          </FormGroup>
          <FormGroup>
            <AxoCheckbox
              checked={activeSubscription}
              onChange={(event) =>
                this.setState({
                  subscriptionAction: event.target.checked
                    ? "Approve"
                    : "Draft",
                })
              }
            />
            &nbsp; Automatisk opkrævning
          </FormGroup>
          {activeSubscription && (
            <>
              <FormGroup controlId="startDate">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  Startdato
                </ControlLabel>
                <AxoDateTime
                  utc
                  value={subscriptionCurrentPeriodEnd}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={(selectedDate) =>
                    this.setState({
                      subscriptionCurrentPeriodEnd: selectedDate,
                    })
                  }
                  isValidDate={(currentDate) => {
                    if (!activeSubscription) {
                      return false;
                    }
                    return !currentDate.isBefore(startOfToday);
                  }}
                />
              </FormGroup>
              <FormGroup controlId="interval" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="AdminTabView8" defaultValue="Interval" />
                </ControlLabel>
                <ReactSelect
                  name="form-field-name"
                  menuPlacement="auto"
                  isDisabled={!activeSubscription}
                  value={periodOptions.find(
                    (p) => p.value === subscriptionInterval
                  )}
                  onChange={(selection, arg) => {
                    if (!selection) {
                      return;
                    }
                    this.setState({ subscriptionInterval: selection.value });
                  }}
                  noOptionsMessage={() => ""}
                  options={periodOptions}
                />
              </FormGroup>
              <FormGroup title="Der vil blive opkrævet betaling når medlemmet tilmelder sig og igen ved den kommende opkrævningsdato.">
                <AxoCheckbox
                  disabled={!activeSubscription}
                  checked={reverseVAT}
                  onChange={(event) =>
                    this.setState({ reverseVAT: event.target.checked })
                  }
                />
                &nbsp; Opkræv ved tilmelding
              </FormGroup>
            </>
          )}
          <FormGroup>
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="axoidcode236"
                defaultValue={"Emnefelt På Email"}
              />
            </ControlLabel>
            <FormControl
              type="text"
              placeholder={"KONTINGENT " + moment().year().toString()}
              value={subject}
              onChange={(event) =>
                this.setState({ subject: event.target.value })
              }
            />
          </FormGroup>
          <FormGroup controlId="description">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              {getText(
                "axoEntityidcode197",
                "Besked (skriv <PAYMENTLINK> hvor tilmeldingslinket skal indsættes)."
              )}
            </ControlLabel>
            <FormControl
              componentClass="textarea"
              placeholder="Klik her for at tilmelde dig automatisk betaling: <PAYMENTLINK>"
              rows={3}
              value={message}
              onChange={(event) =>
                this.setState({ message: event.target.value })
              }
              // onChange={this.onDescChange}
            />
          </FormGroup>
          <div className="text-center">
            <AsyncButton onClick={this.onSubmit}>
              <AxoLocal
                entity="axoEntityidcode195"
                defaultValue="Opret abonnementstilmelding"
              />
            </AsyncButton>
          </div>
          {showErrorMessage ? (
            <div className="axored largeText text-center">
              <AxoLocal
                entity="axoEntityidcode196"
                defaultValue="Der skal indtastes titel, beskrivelse og besked."
              />
            </div>
          ) : null}
          {showServerErrorMessage ? (
            <div className="axored largeText text-center">
              <AxoLocal
                entity="LoginshowServerError"
                defaultValue="Der skete en fejl. Prøv igen senere."
              />
            </div>
          ) : null}
        </Form>
      </div>
    );
  }
}
