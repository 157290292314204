import React from "react";
import moment from "moment";
import Config from "./config";
import { AxoLocal } from "../LexUtilities";
// import 'moment/locale/da'
// moment.locale('da')

export default class DailyForecast extends React.PureComponent {
  translateConditions = (conditions) => {
    var cond = conditions.toLowerCase();
    return (
      <AxoLocal
        key={cond}
        entity="weatherConditions"
        data={{ cond }}
        defaultValue={"Clear"}
      />
    );
  };

  render() {
    var date = moment.unix(this.props.date).format("dddd");
    var icon = Config.imgPath + this.props.icon + ".png";
    var min = Math.round(this.props.min);
    var max = Math.round(this.props.max);

    return (
      <div className="list-group-item">
        <div className="row">
          <div className="col-xs-4">
            <div>
              <strong>{date}</strong>
            </div>
            <div>{this.translateConditions(this.props.conditions)}</div>
          </div>
          <div className="col-xs-4 text-center">
            <img alt="" src={icon} width="40px" />
          </div>
          <div className="col-xs-4 text-right">
            <div>
              <strong>{max}&deg;c</strong>
            </div>
            <div>{min}&deg;c</div>
          </div>
        </div>
      </div>
    );
  }
}
