import DataActions from "./DataAccess/DataActions";
import moment from "moment";

var timer;
var callback;
var calendarEvents = [];
var casesWithDeadlines = [];
var documentsWithDeadlines = [];
var invoicesWithReminders = [];
var notifications = [];

function calculateIntervalMinutes(dateTime) {
  return (new Date(dateTime) - new Date()) / (60 * 1000);
}

function getNotificationTitle(calendarEvent) {
  return calendarEvent.title + " " + moment(calendarEvent.start).format("L LT");
}

function addReminder(event) {
  var notification = {
    text: getNotificationTitle(event),
    type: 0,
    receiverId: 0,
  };
  notifications.push(notification);
  DataActions.createNotification(notification);
}

function checkReminders() {
  notifications = [];
  checkEventReminders();
  checkCaseReminders();
  checkDocumentReminders();
  checkInvoiceReminders();
  if (callback) {
    callback(notifications);
  }
}

function checkEventReminders() {
  calendarEvents.forEach((e) => {
    var remainingMinutes = calculateIntervalMinutes(e.start);
    var changes = false;
    if (e.firstReminder !== null && remainingMinutes < e.firstReminder) {
      addReminder(e);
      e.firstReminder = null;
      if (e.secondReminder !== null && remainingMinutes < e.secondReminder) {
        e.secondReminder = null;
      }
      changes = true;
    } else if (
      e.secondReminder !== null &&
      remainingMinutes < e.secondReminder
    ) {
      addReminder(e);
      e.secondReminder = null;
      changes = true;
    }
    if (changes) {
      DataActions.updateCalendarEvent(e);
    }
  });
}

function checkCaseReminders() {
  casesWithDeadlines.forEach((c) => {
    if (c.reminded || new Date(c.actionDate).getFullYear() < 1971) {
      return;
    }
    var remainingMinutes = calculateIntervalMinutes(c.actionDate);
    if (remainingMinutes < 18 * 60) {
      addCaseReminder(c);
    }
  });
}

function checkDocumentReminders() {
  documentsWithDeadlines.forEach((d) => {
    if (d.reminded || new Date(d.deadline).getFullYear() < 1971) {
      return;
    }
    var remainingMinutes = calculateIntervalMinutes(d.deadline);
    if (remainingMinutes < 18 * 60) {
      addDocumentReminder(d);
    }
  });
}

function checkInvoiceReminders() {
  invoicesWithReminders.forEach((i) => {
    if (!i.sendReminder || i.reminderSent) {
      return;
    }
    var remainingMinutes = calculateIntervalMinutes(i.dueDate);
    if (remainingMinutes < 18 * 60) {
      addInvoiceReminder(i);
    }
  });
}

function addCaseReminder(caseModelHeader) {
  var notification = makeCaseModelReminder(caseModelHeader, 0);
  notifications.push(notification);

  //If the case is shared, send to all the shared users
  if (caseModelHeader.sharingUsers && caseModelHeader.sharingUsers.length > 0) {
    caseModelHeader.sharingUsers.forEach((user) => {
      DataActions.createNotification(
        makeCaseModelReminder(caseModelHeader, user.id)
      );
    });
  }
  //If the owner is not the current user, send to the owner
  if (caseModelHeader.owner) {
    DataActions.createNotification(
      makeCaseModelReminder(caseModelHeader, caseModelHeader.owner.id)
    );
  }
  //If the owner is the current user, send to the current user
  else {
    DataActions.createNotification(notification);
  }

  DataActions.updateCaseReminder(caseModelHeader.id);
}

function makeCaseModelReminder(caseModelHeader, receiverId) {
  return {
    // text:l20n.ctx.getSync('DocumentaddCaseReminderRememberdeadlineforcase') + caseModel.caseNumber + ' ' + new Date(caseModel.actionDate).toLocaleDateString(),
    entity: "DocumentaddCaseReminderRememberdeadlineforcase",
    variable: caseModelHeader.caseNumber,
    messageDate: moment(caseModelHeader.actionDate).format(),
    type: 0,
    receiverId: receiverId,
  };
}

function addDocumentReminder(document) {
  var notification = {
    entity: "DocumentaddCaseRememberdeadlinefordocument",
    variable: document.fileName,
    messageDate: moment(document.deadline).format(),
    type: 0,
    receiverId: 0,
  };
  notifications.push(notification);
  DataActions.createNotification(notification);
  document.reminded = true;
  DataActions.updateDocument(document);
}

function addInvoiceReminder(invoice) {
  var notification = {
    entity: "DocumentaddCaseInvoicewithinvoicenumber",
    variable: "# " + invoice.number.toString(),
    messageDate: moment(invoice.dueDate).format(),
    type: 0,
    receiverId: 0,
  };
  notifications.push(notification);
  DataActions.createNotification(notification);
  invoice.reminderSent = true;
  DataActions.updateInvoice(invoice);
}

export default {
  initializeTimer(callbackFunction) {
    timer = setInterval(checkReminders, 1000 * 15 * 1);
    callback = callbackFunction;
  },
  stopTimer() {
    clearInterval(timer);
  },
  updateEvents(eventModels) {
    calendarEvents = eventModels;
  },
  updateCaseDeadlines(cases) {
    casesWithDeadlines = cases;
  },
  updateDocumentDeadlines(documents) {
    documentsWithDeadlines = documents;
  },
  updateInvoicesWithReminders(invoices) {
    invoicesWithReminders = invoices;
  },
};
