import React from "react";
import PropTypes from "prop-types";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";

import { StringService, FileInfoService } from "../../services/AxoServices";

import {
  Icon,
  InlineEdit,
  AxoLocal,
  InlineNumberEdit,
  ReactOption,
  LexButton,
  LoadingIcon,
  getText,
  AsyncButton,
} from "../../utilities/LexUtilities";

class BookkeepingEntryCard extends React.PureComponent {
  static propTypes = {
    entry: PropTypes.object.isRequired,
    getAccountName: PropTypes.func.isRequired,
    onChangePropertyValue: PropTypes.func.isRequired,
    onShowReceipt: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    markDate: PropTypes.bool.isRequired,
    updateDate: PropTypes.func.isRequired,
    updateAmountAndVat: PropTypes.func.isRequired,
    onApproveEntry: PropTypes.func.isRequired,
  };

  updateAmountAndVat = (amount) => {
    let { entry, updateAmountAndVat } = this.props;

    updateAmountAndVat(entry.id, amount);
  };

  renderInformation = () => {
    let {
      entry,
      getAccountName,
      onChangePropertyValue,
      onShowReceipt,
      onUploadReceiptForExistingEntry,
      markDate,
      updateDate,
      onAddImagePage,
      addingPageToId,
      addedPageToId,
    } = this.props;

    return (
      <div>
        <table className="table Lex-Grid-tabl">
          <tbody>
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-acrobat" />
                &nbsp;
                <AxoLocal entity="DocumentGridViewType" defaultValue={"Type"} />
                :
              </th>
              <td>
                {entry.isIncome ? (
                  <span>
                    <AxoLocal
                      entity="AccountingTabViewEntity16"
                      defaultValue={"Indtægt"}
                    />
                  </span>
                ) : (
                  <span>
                    <AxoLocal
                      entity="AccountingTabViewEntity161a"
                      defaultValue={"Udgift"}
                    />{" "}
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />:
              </th>
              <td>
                {entry.status === "Approved" ? (
                  <div>{entry.description}</div>
                ) : (
                  <InlineEdit
                    value={entry.description || "---"}
                    change={(input) =>
                      onChangePropertyValue("description", input.value)
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />:
              </th>
              <td>
                {entry.status === "Approved" ? (
                  <div>{entry.amount}</div>
                ) : (
                  <InlineNumberEdit
                    decimal
                    value={entry.amount || 0}
                    change={(input) =>
                      this.updateAmountAndVat(parseFloat(input.value))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  entity="invoiPaymentattheSubtotalVAT"
                  defaultValue={"Moms"}
                />
                :
              </th>
              <td>
                {entry.status === "Approved" ? (
                  <div>{entry.vat}</div>
                ) : (
                  <InlineNumberEdit
                    decimal
                    value={entry.vat || 0}
                    change={(input) =>
                      onChangePropertyValue("vat", input.value)
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal entity="axoAccounting43" defaultValue={"Betaling"} />:
              </th>
              <td>
                {entry.isIncome || entry.status === "Approved" ? (
                  <span>{getAccountName(entry.balanceAccount)}</span>
                ) : (
                  <select
                    value={entry.balanceAccount}
                    onChange={(event) =>
                      onChangePropertyValue(
                        "balanceAccount",
                        event.target.value
                      )
                    }
                  >
                    <AxoLocal
                      componentClass={ReactOption}
                      value="Bank"
                      componentAttribute="text"
                      entity="AccountingTabViewEntity24"
                      defaultValue={"Bank"}
                    />
                    <AxoLocal
                      componentClass={ReactOption}
                      value="Cash"
                      componentAttribute="text"
                      entity="axoidcode154"
                      defaultValue={"Kontant"}
                    />
                    <AxoLocal
                      componentClass={ReactOption}
                      value="Private"
                      componentAttribute="text"
                      entity="ContactCreateViewPrivateCustomer"
                      defaultValue={"Private"}
                    />
                  </select>
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  entity="CaseDataTableFixedlabelAddedToCasedocuments"
                  defaultValue={"Bilag"}
                />
                :
              </th>
              <td>
                {!!entry.receipt ? (
                  <div>
                    {entry.receiptNumber > 0 ? (
                      <span>#{entry.receiptNumber}&nbsp;</span>
                    ) : null}
                    <Icon
                      className="editable"
                      role="button"
                      onClick={onShowReceipt}
                      glyph="icon-fontello-attach-7"
                    />
                    &nbsp;
                    {FileInfoService.isImage(entry.receipt) ? (
                      <LexButton
                        disabled={!!addingPageToId}
                        title={getText("axoidcode209", "Tilføj side")}
                        onClick={() => onAddImagePage(entry.id)}
                      >
                        {(() => {
                          if (addingPageToId === entry.id) {
                            return (
                              <LoadingIcon show={addingPageToId === entry.id} />
                            );
                          } else if (addedPageToId === entry.id) {
                            return <Icon glyph="icon-fontello-check" />;
                          }
                          return <Icon glyph="icon-fontello-plus-2" />;
                        })()}
                      </LexButton>
                    ) : null}
                  </div>
                ) : (
                  <LexButton onClick={onUploadReceiptForExistingEntry}>
                    <AxoLocal
                      entity="DocumentTabViewUpload"
                      defaultValue={"Upload"}
                    />
                  </LexButton>
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
                :
              </th>
              <td>
                {entry.status === "Approved" ? (
                  <div>{moment.utc(entry.creationDate).format("L")}</div>
                ) : (
                  <input
                    type="date"
                    style={{ border: markDate ? "1px solid red" : "none" }}
                    onChange={(event) =>
                      updateDate(entry, event.target.valueAsDate)
                    }
                    value={moment.utc(entry.creationDate).format("YYYY-MM-DD")}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderContent = () => {
    return this.renderInformation();
  };

  onApproveEntry = async () => {
    let { entry, onApproveEntry } = this.props;

    return onApproveEntry(entry);
  };

  render() {
    let { entry, onDelete } = this.props;

    return (
      <div className="data-card">
        <div className="gradient-baggrund">
          <Grid fluid>
            <Row>
              <Col xs={12} className="text-center">
                {StringService.enforceMaxLength(
                  entry.description ||
                    (entry.isIncome ? (
                      <span>
                        <AxoLocal
                          entity="AccountingTabViewEntity16"
                          defaultValue={"Indtægt"}
                        />
                      </span>
                    ) : (
                      <span>
                        <AxoLocal
                          entity="AccountingTabViewEntity161a"
                          defaultValue={"Udgift"}
                        />{" "}
                      </span>
                    )),
                  30
                )}
              </Col>
            </Row>
          </Grid>
        </div>
        <div>
          <Grid fluid>
            <Row>
              <Col xs={12}>{this.renderContent()}</Col>
            </Row>
          </Grid>
        </div>
        <div className="Lex-CardFooter">
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ padding: "0px" }}>
                {entry.status !== "Approved" ? (
                  <ButtonGroup justified>
                    <ButtonGroup>
                      <AsyncButton
                        hideOkIcon
                        onClick={() => this.onApproveEntry()}
                      >
                        <Icon glyph="icon-fontello-check" /> &nbsp;&nbsp;
                        <AxoLocal
                          key="send"
                          entity="friendRequestTimelineonApproveRequest"
                          defaultValue={"Godkend"}
                        />
                      </AsyncButton>
                    </ButtonGroup>
                    <ButtonGroup>
                      <button
                        type="button"
                        className="btn Lex-button"
                        onClick={onDelete}
                      >
                        <Icon role="button" glyph="icon-fontello-trash-1" />
                        &nbsp;&nbsp;
                        <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                      </button>
                    </ButtonGroup>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup justified>
                    <ButtonGroup>
                      <button disabled type="button" className="btn Lex-button">
                        <Icon role="button" glyph="icon-fontello-check" />{" "}
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="approved"
                          entity="inbosViewsent"
                          defaultValue={"Sendt"}
                        />
                      </button>
                    </ButtonGroup>
                    <ButtonGroup>
                      <button
                        type="button"
                        className="btn Lex-button"
                        onClick={onDelete}
                      >
                        <Icon role="button" glyph="icon-fontello-trash-1" />
                        &nbsp;&nbsp;
                        <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                      </button>
                    </ButtonGroup>
                  </ButtonGroup>
                )}
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

class AccountingGridView extends React.PureComponent {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    getAccountName: PropTypes.func.isRequired,
    onChangePropertyValue: PropTypes.func.isRequired,
    onShowReceipt: PropTypes.func.isRequired,
    onDeleteEntry: PropTypes.func.isRequired,
    receiptsWhoseDatesCouldNotBeScanned: PropTypes.array.isRequired,
    updateDate: PropTypes.func.isRequired,
    updateAmountAndVat: PropTypes.func.isRequired,
    onApproveEntry: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateDocCols = (entries) => {
    let {
      getAccountName,
      onDeleteEntry,
      onChangePropertyValue,
      onShowReceipt,
      onUploadReceiptForExistingEntry,
      receiptsWhoseDatesCouldNotBeScanned,
      updateDate,
      updateAmountAndVat,
      onApproveEntry,
      onAddImagePage,
      addingPageToId,
      addedPageToId,
    } = this.props;

    let cols = [[], [], []];
    let smallCols = [[], []];
    for (var i = 0; i < entries.length; i++) {
      let entry = entries[i];
      let entryCard = (
        <BookkeepingEntryCard
          key={entry.id}
          entry={entry}
          getAccountName={getAccountName}
          onChangePropertyValue={(propertyName, value) =>
            onChangePropertyValue(entry.id, propertyName, value)
          }
          onShowReceipt={() => onShowReceipt(entry.id)}
          onUploadReceiptForExistingEntry={() =>
            onUploadReceiptForExistingEntry(entry.id)
          }
          onDelete={() => onDeleteEntry(entry)}
          markDate={
            !!entry.receiptId &&
            !!receiptsWhoseDatesCouldNotBeScanned.includes(entry.receiptId)
          }
          updateDate={updateDate}
          updateAmountAndVat={updateAmountAndVat}
          onApproveEntry={onApproveEntry}
          onAddImagePage={onAddImagePage}
          addingPageToId={addingPageToId}
          addedPageToId={addedPageToId}
        />
      );

      cols[i % 3].push(entryCard);
      smallCols[i % 2].push(entryCard);
    }
    return [cols, smallCols];
  };

  renderDocumentGrid = (entries) => {
    let cols = this.generateDocCols(entries);
    let largeCols = cols[0];
    let smallCols = cols[1];

    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[0]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[1]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  getShownEntries = () => {
    return this.props.entries.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    let { entries } = this.props;
    let { numberShown } = this.state;

    return (
      <Grid fluid style={{ background: "#ffffff" }}>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            {this.renderDocumentGrid(this.getShownEntries())}
          </Col>
        </Row>
        {numberShown < entries.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col xs={12} className="text-center" style={{ paddingTop: "10px" }}>
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2 "
                style={{ width: "75%" }}
              >
                <AxoLocal
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(AccountingGridView);
