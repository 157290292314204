import React from "react";

export default class AxoTimeline extends React.PureComponent {
  render() {
    var { singleColumn } = this.props;

    var className = singleColumn ? "single-column" : "";

    return (
      <div className={className}>
        <div className="axotimeline">{this.props.children}</div>
      </div>
    );
  }
}
