import React from "react";
import { withRouter } from "react-router-dom";
import PresentationApiService from "../services/PresentationApiService";
import AdminList from "../AdminList/AdminList";
import { debounce } from "lodash";
import QueryString from "query-string";

class PublicQueryListContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      presentations: [],
      shown: 10,
      query: "",
    };
  }

  componentDidMount() {
    var { shown, query } = QueryString.parse(this.props.location.search);

    query = query || "";
    this.setState(
      {
        query,
        shown: parseInt(shown.toString(), 10),
      },
      () => {
        this.performQuery();
      }
    );
  }

  onSearch = (event) => {
    var query = event.target.value;
    this.setState(
      {
        query,
      },
      () => {
        this.performQuery();
      }
    );
  };

  performQuery = debounce(() => {
    var { shown, query } = this.state;
    PresentationApiService.queryPresentations(shown, query).then(
      (presentations) => {
        this.setState({ presentations });
      }
    );
    this.props.history.push(
      "/Presentation/all?shown=" + this.state.shown + "&query=" + query
    );
  }, 500);

  selectPresentation = (presentationId) => {
    var presentation = this.state.presentations.find(
      (p) => p.id === presentationId
    );
    this.props.history.push(
      "/Presentation/post/" + presentation.userId + "/" + presentationId
    );
  };

  onShowMore = () => {
    this.setState(
      {
        shown: this.state.shown + 10,
      },
      () => {
        this.performQuery();
      }
    );
  };

  render() {
    return (
      <AdminList
        presentations={this.state.presentations}
        shown={this.state.shown}
        onShowMore={this.onShowMore}
        selectPresentation={this.selectPresentation}
        query={this.state.query}
        onSearch={this.onSearch}
      />
    );
  }
}

export default withRouter(PublicQueryListContainer);
