import React from "react";

export default class Conditional extends React.PureComponent {
  //Props
  //show
  //inline
  render() {
    if (!this.props.show) {
      return null;
    }
    if (this.props.inline) {
      return <span>{this.props.children}</span>;
    }
    return <div>{this.props.children}</div>;
  }
}
