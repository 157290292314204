import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  ControlLabel,
  Modal,
  FormGroup,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { AxoLocal } from "../../utilities/LexUtilities";
export default class LabelModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      label: undefined,
      labelName: "",
      create: true,
      showDuplicateNameWarning: false,
    };
  }

  setCreate = () => {
    this.setState({ label: undefined });
    return this;
  };

  setEdit = (label) => {
    this.setState({
      label: label,
      labelName: label.name,
    });
    return this;
  };

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  updateLabelName = (e) => {
    this.setState({ labelName: e.target.value });
  };

  onOK = () => {
    if (this.props.labels.find((l) => l.name === this.state.labelName)) {
      this.showDuplicateNameWarning();
      return;
    } else if (!this.state.label) {
      this.createLabel();
    } else {
      this.updateLabel();
    }
    this.close();
  };

  showDuplicateNameWarning = () => {
    this.setState({
      showDuplicateNameWarning: true,
    });
    setTimeout(() => {
      this.setState({
        showDuplicateNameWarning: false,
      });
    }, 3000);
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onOK();
    }
  };

  createLabel = () => {
    DataActions.createLabel({
      name: this.state.labelName,
      type: this.props.labelType,
    });
  };

  updateLabel = () => {
    var { label, labelName } = this.state;

    DataActions.updateLabel({
      ...label,
      name: labelName,
    });
  };

  render() {
    var create = !this.state.label;
    return (
      <Modal show={this.state.showModal} onHide={this.close} bsSize="small">
        <Modal.Header className="backgroundModalHeaderFooter" closeButton>
          <Modal.Title>
            {create ? (
              <AxoLocal
                key="create"
                entity="Createyouownfolder"
                defaultValue={"Opret din egen mappe"}
              />
            ) : (
              <AxoLocal
                key="Enternewname"
                entity="CreateyouownfolderEnternewname"
                defaultValue={"Indtast nyt navn"}
              />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId="labelName">
            <ControlLabel>
              <AxoLocal
                entity="socialPrivateConversationBannerConferenceName"
                defaultValue={"Navn"}
              />
            </ControlLabel>
            <InputGroup>
              <FormControl
                value={this.state.labelName}
                onChange={this.updateLabelName}
                onKeyDown={this.onKeyDown}
                type="text"
                placeholder={create ? "Indtast folderens navn" : ""}
              />
            </InputGroup>
            {this.state.showDuplicateNameWarning ? (
              <span className="axored">
                <AxoLocal entity="ModalServicelabelNameAlertModal" />
                &nbsp;"{this.state.labelName}"
              </span>
            ) : null}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.onOK}>
                {create ? (
                  <AxoLocal
                    key="Create"
                    entity="ADDViewCreate"
                    defaultValue={"Opret"}
                  />
                ) : (
                  <AxoLocal
                    key="caseSave"
                    entity="CaseGridViewcaseSave"
                    defaultValue={"Gem"}
                  />
                )}
              </Button>
              <Button className="Lex-button-2" onClick={this.close}>
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
