import React from "react";
import PresentationActions from "../../../marketing/services/PresentationActions";
import PresentationView from "../../../marketing/Presentation/PresentationView";
import { withRouter } from "react-router-dom";

import { DataStore, RoutingService } from "../../../services/AxoServices";

class LegalPresentationSingleContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      presentation: {
        items: [],
      },
      userProfile: {},
    };
    this.name = "LegalPresentationSingleContainer";
  }

  componentDidMount() {
    let { id } = this.props.match.params;

    DataStore.subscribe(this.name, (store) => {
      let presentation = store.legalPresentations.find(
        (p) => p.id === parseInt(id, 10)
      ) || { items: [] };

      this.setState({
        presentation,
        userProfile: store.userProfile,
      });
    });

    DataStore.fetchLegalPresentations();
    DataStore.initializeUserProfile();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  onTogglePublic = (presentationId) => {
    let newPresentation = Object.assign({}, this.state.presentation);
    newPresentation.public = !newPresentation.public;
    PresentationActions.updatePresentation(newPresentation);
  };

  onBackButton = () => {
    this.props.history.goBack();
  };

  onEdit = (presentationId) => {
    let link = RoutingService.getPath(
      "Admin/CreatePresentation?id=" + presentationId
    );
    this.props.history.push(link);
  };

  render() {
    return (
      <PresentationView
        presentation={this.state.presentation}
        userProfile={this.state.userProfile}
        onBackButton={this.onBackButton}
        includeLinks
        includeLinkBox
        onTogglePublic={this.onTogglePublic}
        allowEdit
        onEdit={this.onEdit}
      />
    );
  }
}

export default withRouter(LegalPresentationSingleContainer);
