import React from "react";
import BankBalancingView from "./BankBalancingView";
import moment from "moment";
import { debounce } from "lodash";

import {
  ApiService,
  DataStore,
  DataActions,
  RoutingService,
  StorageService,
  ModalService,
} from "../../../services/AxoServices";

import {
  Consumer,
  withRouter,
  getText,
  TextFilterModal,
  NumberFilterModal,
  // FilterTypes,
  TextFilter,
  TextFilterTypes,
  NumberFilter,
  NumberFilterTypes,
  DateFilter,
  DateFilterTypes,
  DateFilterModal,
  withRTKData,
} from "../../../utilities/LexUtilities";

const StepValues = {
  // UPLOAD: 0, //Uploading bank postings
  AUTO: 0, //Automatic one-to-one matches
  DOUBLES: 1, //Entries with more than one potential match
  APPROX: 2, //Approximate matches
  ALGORITM: 3, //Many-to-one matches
  // FINAL: 5, //Manual matching
  ACCOUNTS: 4, //Changing accounts for entries
  BANKPOSTING: 5, //Selecting accounts for bank postings,
  FINALPOSTING: 6, //Adjust remaining entries and finalize
};

const Operations = {
  RECON: 0,
  DELETE: 1,
};

const ObjectType = {
  POSTING: 0,
  ENTRY: 1,
};

const PriceFilter = {
  ALL: 0,
  POSITIVE: 1,
  NEGATIVE: 2,
};

class ReconOperation {
  constructor(entryIds = new Set(), postingIds = new Set()) {
    this.type = Operations.RECON;
    this.entryIds = entryIds;
    this.postingIds = postingIds;
  }
}

class DeleteOperation {
  constructor(objectList = []) {
    this.type = Operations.DELETE;
    this.objectList = objectList;
  }
}

const BankImportProgress = {
  IDLE: 0,
  ACTIVE: 1,
  FAILURE: 2,
  SUCCESS: 3,
};

class BankBalancingContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getStartState(),
      ...this.loadAccountSelection(),
    };

    this.textFilterModal = React.createRef();
    this.numberFilterModal = React.createRef();
    this.dateFilterModal = React.createRef();
  }

  getStartState = () => {
    return {
      bankImportProgress: BankImportProgress.IDLE,
      importResult: null,
      selectedEntries: new Set(),
      selectedPostings: new Set(),
      selectedBankAccountId: 0,
      entryWarnings: {},
      postingWarnings: {},
      numberReconciliated: -1,

      selectedEntryAccountIdMap: {},
      selectedPostingAccountIdMap: {},
      hiddenBankPostings: new Set(),
      doubleGroupArray: [],
      doubleGroupProgress: 0,

      postingMultiMatches: {},
      entryMultiMatches: {},
      loadingMatches: false,

      postingApproxMatchIds: new Set(),
      entryApproxMatchIds: new Set(),

      selectedEntryColumn: "",
      selectedBankPostingColumn: "",

      entryFilterTexts: {},
      bankPostingFilterTexts: {},
      priceFilter: PriceFilter.ALL,
      monthFilter: -1,

      singlePostingMatch: {},

      operations: [],

      hideStartValueWarning: false,
      creditorEntryIds: {},
      debitorEntryIds: {},

      columnFilters: {}, //Map from column names to filters,
    };
  };

  defaulAccountSelection = {
    selectedEntryAccountIdMap: {},
    selectedPostingAccountIdMap: {},
    hiddenBankPostings: [],
  };

  editTextFilter = (isPostings = false) => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let { columnFilters } = this.state;

    let { filteredEntries, filteredPostings } = this.applyAllFilters(
      bookKeepingDraftEntries,
      bankPostings,
      "description"
    );

    let textOptions;
    if (isPostings) {
      textOptions = new Set([...filteredPostings.map((e) => e.text || "")]);
    } else {
      textOptions = new Set([
        ...filteredEntries.map((e) => e.description || ""),
      ]);
    }

    let arrayOptions = [...textOptions]
      .map((t) => ({ id: t, name: t }))
      .sort((l, r) => (l.name || "").localeCompare(r.name || ""));

    let existingFilter = columnFilters.description;
    let textFilter;
    if (!!existingFilter) {
      textFilter = new TextFilter(existingFilter);
      textFilter.options = arrayOptions;
    } else {
      textFilter = new TextFilter({
        options: arrayOptions,
        selectedOptions: new Set(),
      });
    }

    this.textFilterModal.current.open(textFilter, (textFilter) => {
      this.updateColumnFilter("description", textFilter);
    });
  };

  //Text filter using the full name of the account
  editAccountFilter = () => {
    let { financeAccountMap, bookKeepingDraftEntries, bankPostings } =
      this.props;

    let { columnFilters } = this.state;

    let { filteredEntries } = this.applyAllFilters(
      bookKeepingDraftEntries,
      bankPostings,
      "balanceFinanceAccountId"
    );

    let accountOptions = new Set(
      filteredEntries
        .filter((e) => !!e.balanceFinanceAccountId)
        .map((e) => e.balanceFinanceAccountId)
    );

    let arrayOptions = [...accountOptions]
      .map((id) => ({
        id,
        name:
          financeAccountMap[id].number.toString() +
          " - " +
          financeAccountMap[id].name,
      }))
      .sort((l, r) => (l.name || "").localeCompare(r.name || ""));

    let existingFilter = columnFilters.balanceFinanceAccountId;
    let textFilter;
    if (!!existingFilter) {
      textFilter = new TextFilter(existingFilter);
      textFilter.options = arrayOptions;
    } else {
      textFilter = new TextFilter({
        options: arrayOptions,
        selectedOptions: new Set(),
      });
    }

    this.textFilterModal.current.open(textFilter, (textFilter) => {
      this.updateColumnFilter("balanceFinanceAccountId", textFilter);
    });
  };

  editNumberFilter = (propertyName, isPostings = false) => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let { columnFilters } = this.state;

    let { filteredEntries, filteredPostings } = this.applyAllFilters(
      bookKeepingDraftEntries,
      bankPostings,
      propertyName
    );

    let isAmount = propertyName === "amount";

    let getEntryValue = (entry) => {
      if (isAmount) {
        return this.getAmountForEntry(entry);
      }

      return entry[propertyName];
    };

    let options;
    if (isPostings) {
      options = new Set([
        ...filteredPostings.map((e) => e[propertyName] || ""),
      ]);
    } else {
      options = new Set([...filteredEntries.map((e) => getEntryValue(e))]);
    }

    let arrayOptions = [...options]
      .map((t) => ({ id: t, name: t }))
      .sort((l, r) => l.name - r.name);

    let existingFilter = columnFilters[propertyName];
    let numberFilter;
    if (!!existingFilter) {
      numberFilter = new NumberFilter(existingFilter);
      numberFilter.options = arrayOptions;
    } else {
      numberFilter = new NumberFilter({
        options: arrayOptions,
        selectedOptions: new Set(),
      });
    }

    this.numberFilterModal.current.open(numberFilter, (numberFilter) => {
      this.updateColumnFilter(propertyName, numberFilter);
    });
  };

  editDateFilter = (isPostings = false) => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let { columnFilters } = this.state;

    let { filteredEntries, filteredPostings } = this.applyAllFilters(
      bookKeepingDraftEntries,
      bankPostings,
      "creationDate"
    );

    let options = new Set([
      ...filteredEntries.map((e) => moment(e.creationDate).format("L")),
      ...filteredPostings.map((e) => moment(e.date).format("L")),
    ]);

    if (isPostings) {
      options = new Set([
        ...filteredPostings.map((e) => moment(e.date).format("L")),
      ]);
    } else {
      options = new Set([
        ...filteredEntries.map((e) => moment(e.creationDate).format("L")),
      ]);
    }

    let arrayOptions = [...options]
      .map((t) => ({ id: t, name: t }))
      .sort((l, r) => l.name - r.name);

    let existingFilter = columnFilters.creationDate;
    let filter;
    if (!!existingFilter) {
      filter = new DateFilter(existingFilter);
      filter.options = arrayOptions;
    } else {
      filter = new DateFilter({
        options: arrayOptions,
        selectedOptions: new Set(),
      });
    }

    this.dateFilterModal.current.open(filter, (filter) => {
      this.updateColumnFilter("creationDate", filter);
    });
  };

  removeColumnFilter = (propertyName) => {
    let { columnFilters } = this.state;

    let { [propertyName]: property, ...newFilter } = columnFilters;

    this.setState({ columnFilters: newFilter });

    // this.updateEntryFilter(entryFilterTexts, newFilter);
  };

  updateColumnFilter = (propertyName, filter) => {
    let { columnFilters } = this.state;

    let newColumnFilters = {
      ...columnFilters,
      [propertyName]: filter,
    };

    this.setState({ columnFilters: newColumnFilters });

    // this.updateEntryFilter(entryFilterTexts, newColumnFilters);
  };

  applyColumnFilters = (
    entries,
    bankPostings,
    columnFilters,
    skipFilterName = ""
  ) => {
    let skipFilter = columnFilters[skipFilterName]; //Apply only other filters, to determine available options for this filter
    let filteredEntries = entries;
    let filteredPostings = bankPostings;
    if (
      !!columnFilters.description &&
      columnFilters.description !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters.description,
        "description"
      );
      filteredPostings = this.applyTextFilter(
        filteredPostings,
        columnFilters.description,
        "text"
      );
    }
    if (
      !!columnFilters.balanceFinanceAccountId &&
      columnFilters.balanceFinanceAccountId !== skipFilter
    ) {
      filteredEntries = this.applyAccountFilter(
        filteredEntries,
        columnFilters,
        "balanceFinanceAccountId"
      );
    }
    if (
      !!columnFilters.receiptNumber &&
      columnFilters.receiptNumber !== skipFilter
    ) {
      filteredEntries = this.applyNumberFilter(
        filteredEntries,
        columnFilters,
        "receiptNumber"
      );
    }
    if (!!columnFilters.amount && columnFilters.amount !== skipFilter) {
      filteredEntries = this.applyNumberFilter(
        filteredEntries,
        columnFilters,
        "amount",
        true
      );
      filteredPostings = this.applyNumberFilter(
        filteredPostings,
        columnFilters,
        "amount"
      );
    }
    if (
      !!columnFilters.creationDate &&
      columnFilters.creationDate !== skipFilter
    ) {
      filteredEntries = this.applyDateFilter(
        filteredEntries,
        columnFilters.creationDate,
        "creationDate"
      );
      filteredPostings = this.applyDateFilter(
        filteredPostings,
        columnFilters.creationDate,
        "date"
      );
    }
    if (!!columnFilters.accountBalance) {
      filteredPostings = this.applyNumberFilter(
        filteredPostings,
        columnFilters,
        "accountBalance"
      );
    }

    return { filteredEntries, filteredPostings };
  };

  applyAccountFilter = (entries, columnFilters, propertyName) => {
    let { financeAccountMap } = this.props;
    const getName = (entry) => {
      if (!entry[propertyName]) {
        return "";
      }

      let account = financeAccountMap[entry[propertyName]];
      if (!account) {
        return "";
      }

      return account.name || "";
    };

    let filteredEntries = entries;
    // if(columnFilters[propertyName].selectedOptions.size > 0) {
    //   filteredEntries = filteredEntries.filter(e =>
    //     columnFilters[propertyName].selectedOptions.has(e[propertyName]));
    // }

    if (columnFilters[propertyName].selectedOptions.length > 0) {
      let selectedOptionSet = new Set(
        columnFilters[propertyName].selectedOptions
      );

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(e[propertyName] || "")
      );
    }

    if (!!columnFilters[propertyName].filterString) {
      let search = columnFilters[propertyName].filterString.toLowerCase();
      switch (columnFilters[propertyName].filterStringType) {
        case TextFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => getName(f).toLowerCase() === search
          );
          break;
        case TextFilterTypes.NOTEQUEALS:
          filteredEntries = filteredEntries.filter(
            (f) => getName(f).toLowerCase() !== search
          );
          break;
        case TextFilterTypes.STARTSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            getName(f).toLowerCase().startsWith(search)
          );
          break;
        case TextFilterTypes.ENDSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            getName(f).toLowerCase().endsWith(search)
          );
          break;
        case TextFilterTypes.CONTAINS:
          filteredEntries = filteredEntries.filter((f) =>
            getName(f).toLowerCase().includes(search)
          );
          break;
        case TextFilterTypes.NOTCONTAINS:
          filteredEntries = filteredEntries.filter(
            (f) => !getName(f).toLowerCase().includes(search)
          );
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  applyTextFilter = (entries, filter, propertyName) => {
    let filteredEntries = entries;
    // if(filter.selectedOptions.size > 0) {
    //   filteredEntries = filteredEntries.filter(e => filter.selectedOptions
    //     .has(e[propertyName] || ''));
    // }

    if (filter.selectedOptions.length > 0) {
      let selectedOptionSet = new Set(filter.selectedOptions);

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(e[propertyName] || "")
      );
    }

    if (!!filter.filterString) {
      let search = filter.filterString.toLowerCase();
      switch (filter.filterStringType) {
        case TextFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => (f[propertyName] || "").toLowerCase() === search
          );
          break;
        case TextFilterTypes.NOTEQUEALS:
          filteredEntries = filteredEntries.filter(
            (f) => (f[propertyName] || "").toLowerCase() !== search
          );
          break;
        case TextFilterTypes.STARTSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            (f[propertyName] || "").toLowerCase().startsWith(search)
          );
          break;
        case TextFilterTypes.ENDSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            (f[propertyName] || "").toLowerCase().endsWith(search)
          );
          break;
        case TextFilterTypes.CONTAINS:
          filteredEntries = filteredEntries.filter((f) =>
            (f[propertyName] || "").toLowerCase().includes(search)
          );
          break;
        case TextFilterTypes.NOTCONTAINS:
          filteredEntries = filteredEntries.filter(
            (f) => !(f[propertyName] || "").toLowerCase().includes(search)
          );
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  applyNumberFilter = (entries, columnFilters, propertyName, isEntryAmount) => {
    let getValue = (entry) => {
      if (isEntryAmount) {
        return this.getAmountForEntry(entry);
      }

      return entry[propertyName];
    };

    let filteredEntries = entries;
    // if(columnFilters[propertyName].selectedOptions.size > 0) {
    //   filteredEntries = filteredEntries.filter(e =>
    //     columnFilters[propertyName].selectedOptions.has(getValue(e)));
    // }

    if (columnFilters[propertyName].selectedOptions.length > 0) {
      let selectedOptionSet = new Set(
        columnFilters[propertyName].selectedOptions
      );

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(e[propertyName] || "")
      );
    }

    if (
      columnFilters[propertyName].filterNumberType !== NumberFilterTypes.NONE
    ) {
      let search = columnFilters[propertyName].filterNumber;
      switch (columnFilters[propertyName].filterNumberType) {
        case NumberFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => getValue(f) === search
          );
          break;
        case NumberFilterTypes.NOTEQUEALS:
          filteredEntries = filteredEntries.filter(
            (f) => getValue(f) !== search
          );
          break;
        case NumberFilterTypes.GREATERTHAN:
          filteredEntries = filteredEntries.filter((f) => getValue(f) > search);
          break;
        case NumberFilterTypes.GREATERTHANEQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => getValue(f) >= search
          );
          break;
        case NumberFilterTypes.LESSTHAN:
          filteredEntries = filteredEntries.filter((f) => getValue(f) < search);
          break;
        case NumberFilterTypes.LESSTHANEQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => getValue(f) <= search
          );
          break;
        case NumberFilterTypes.BETWEEN:
          // filteredEntries = filteredEntries.filter(f => !(f[propertyName] || '').toLowerCase().includes(search))
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  applyDateFilter = (entries, filter, propertyName) => {
    let filteredEntries = entries;
    // if(filter.selectedOptions.size > 0) {
    //   filteredEntries = filteredEntries.filter(e => filter
    //     .selectedOptions.has(moment.utc(e[propertyName]).format('L')));
    // }

    if (filter.selectedOptions.length > 0) {
      let selectedOptionSet = new Set(filter.selectedOptions);

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(moment.utc(e[propertyName]).format("L"))
      );
    }

    if (filter.filterMonth > -1) {
      filteredEntries = filteredEntries.filter(
        (e) => moment.utc(e[propertyName]).month() === filter.filterMonth
      );
    }
    if (filter.filterMonthArray.length > 0) {
      let filterMonthMap = new Set(filter.filterMonthArray);
      filteredEntries = filteredEntries.filter((e) =>
        filterMonthMap.has(moment.utc(e[propertyName]).month())
      );
    }
    if (filter.filterDateType !== DateFilterTypes.NONE) {
      let search = moment.utc(filter.filterDate);
      switch (filter.filterDateType) {
        case DateFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => moment.utc(f[propertyName]).valueOf() === search.valueOf()
          );
          break;
        case DateFilterTypes.BEFORE:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isBefore(search)
          );
          break;
        case DateFilterTypes.BEFOREORSAME:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isSameOrBefore(search)
          );
          break;
        case DateFilterTypes.AFTER:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isAfter(search)
          );
          break;
        case DateFilterTypes.AFTERORSAME:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isSameOrAfter(search)
          );
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  loadAccountSelection = () => {
    let { selectedContact } = this.props;
    if (!selectedContact.id) {
      return;
    }

    let selection = StorageService.loadItem(
      "accountSelection-" + selectedContact.id,
      this.defaulAccountSelection
    );
    return {
      ...selection,
      hiddenBankPostings: new Set(selection.hiddenBankPostings),
    };
  };

  saveAccountSelection = (accountSelection = this.defaulAccountSelection) => {
    let { selectedContact } = this.props;
    if (!selectedContact.id) {
      return;
    }
    if (
      JSON.stringify(accountSelection) ===
      JSON.stringify(this.defaulAccountSelection)
    ) {
      StorageService.saveItem("accountSelection-" + selectedContact.id, null);
    } else {
      StorageService.saveItem(
        "accountSelection-" + selectedContact.id,
        accountSelection
      );
    }
  };

  saveCurrentAccountSelection = () => {
    let {
      selectedEntryAccountIdMap,
      selectedPostingAccountIdMap,
      hiddenBankPostings,
    } = this.state;

    this.saveAccountSelection({
      selectedEntryAccountIdMap,
      selectedPostingAccountIdMap,
      hiddenBankPostings: [...hiddenBankPostings],
    });
  };

  onCancelOperation = async () => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let { operations, selectedEntryAccountIdMap, hiddenBankPostings } =
      this.state;

    if (Object.keys(selectedEntryAccountIdMap).length > 0) {
      this.setState({ selectedEntryAccountIdMap: {} });
      return true;
    }

    if (hiddenBankPostings.size > 0) {
      this.initializeBankPostingAccounts();
      return true;
    }

    if (operations.length === 0) {
      return true;
    }

    let latestOperation = operations[operations.length - 1];
    let promises = [];
    if (latestOperation.type === Operations.RECON) {
      let entries = bookKeepingDraftEntries.filter((e) =>
        latestOperation.entryIds.has(e.id)
      );
      let postings = bankPostings.filter((p) =>
        latestOperation.postingIds.has(p.id)
      );
      entries.forEach((entry) => {
        promises.push(
          ApiService.updateBookkeepingDraftEntry({
            ...entry,
            approved: false,
          })
        );
      });
      postings.forEach((posting) => {
        promises.push(
          ApiService.updateBankPosting({
            ...posting,
            approved: false,
          })
        );
      });
    } else if (latestOperation.type === Operations.DELETE) {
      latestOperation.objectList.forEach((object) => {
        if (object.type === ObjectType.ENTRY) {
          let { id, ...newEntry } = object;
          promises.push(ApiService.createBookkeepingDraftEntry(newEntry));
        } else if (object.type === ObjectType.POSTING) {
          let { id, ...newPosting } = object;
          promises.push(ApiService.createBankPosting(newPosting));
        }
      });
    }

    await Promise.all(promises);
    this.fetchBankBalancingData();
    this.setState({ operations: operations.slice(0, -1) }); //Removes last element
    return true;
  };

  addOperation = (operation) => {
    this.setState((prevState) => ({
      operations: [...prevState.operations, operation],
    }));
  };

  componentDidMount() {
    this.updateDerivedState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      let {
        selectedEntryAccountIdMap,
        selectedPostingAccountIdMap,
        hiddenBankPostings,
      } = this.state;
      if (
        prevState.selectedEntryAccountIdMap !== selectedEntryAccountIdMap ||
        prevState.selectedPostingAccountIdMap !== selectedPostingAccountIdMap ||
        prevState.hiddenBankPostings !== hiddenBankPostings
      ) {
        this.saveCurrentAccountSelection();
      }
    }

    if (prevProps === this.props) {
      return;
    }

    let {
      selectedContact,
      currentStep,
      bookKeepingDraftEntries,
      bankPostings,
    } = this.props;

    if (prevProps.selectedContact.id !== selectedContact.id) {
      this.setState({ ...this.loadAccountSelection() });
    }

    if (
      (prevProps.currentStep === 0 && prevProps.currentStep !== currentStep) || //When opening page, cached step loads later.
      JSON.stringify(prevProps.bookKeepingDraftEntries) !==
        JSON.stringify(bookKeepingDraftEntries) ||
      JSON.stringify(prevProps.bankPostings) !== JSON.stringify(bankPostings)
    ) {
      this.updateDerivedState();
    }
  }

  fetchBankBalancingData = async () => {
    await DataStore.fetchBankReconEntries();
    await DataStore.fetchBankPostings();
    await DataStore.fetchAccountingReportWithDraft();
  };

  updateDerivedState = () => {
    let { currentStep } = this.props;
    let { hiddenBankPostings } = this.state;

    this.calculateCreditAndDebitEntryIds();

    if (currentStep === StepValues.DOUBLES) {
      // this.findDoubles();
    } else if (currentStep === StepValues.ALGORITM) {
      this.findMultiMatches();
    } else if (currentStep === StepValues.APPROX) {
      this.findApproximateMatches();
    } else if (
      (currentStep === StepValues.BANKPOSTING ||
        currentStep === StepValues.APPROX) &&
      hiddenBankPostings.size === 0
    ) {
      this.initializeBankPostingAccounts();
    }
  };

  loadBankStatement = async (files) => {
    let { selectedContact, bankImportSettings } = this.props;

    this.setState({ bankImportProgress: BankImportProgress.ACTIVE });
    return ApiService.importBankStatement(files, {
      clientId: selectedContact.id,
      ...bankImportSettings,
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Could not load bank postings");
        }

        DataStore.fetchBankPostings();
        return response.json();
      })
      .then((importResult) => {
        this.setState({
          importResult,
          bankImportProgress: BankImportProgress.IDLE,
        });
        setTimeout(() => this.setState({ importResult: null }), 5000);
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({
          bankImportProgress: BankImportProgress.FAILURE,
        });
        setTimeout(
          () =>
            this.setState({
              bankImportProgress: BankImportProgress.IDLE,
            }),
          4000
        );
      });
  };

  handleSelectedClient = (selectedClient) => {
    let { selectedContact } = this.props;

    if (selectedClient.id === selectedContact.id) {
      return;
    }

    DataStore.setState({
      fiscalYears: null,
    });

    DataStore.setSelectedContact(selectedClient);

    DataStore.fetchFiscalYears().then(() => {
      this.fetchBankBalancingData();
      DataStore.fetchClientPlan();
    });
  };

  onSelectFiscalYear = async (fiscalYearId) => {
    DataStore.selectFiscalYear(fiscalYearId);
    await DataStore.fetchBankReconEntries();
    await DataStore.fetchBankPostings();
    DataStore.fetchAccountingReportWithDraft();

    this.updateDerivedState();
  };

  onSelectStartDate = (selectedStartDate) => {
    DataStore.updateProperty("selectedFiscalStartDate", selectedStartDate);
    this.fetchBankBalancingData();
  };

  onSelectEndDate = (selectedEndDate) => {
    DataStore.updateProperty("selectedFiscalEndDate", selectedEndDate);
    this.fetchBankBalancingData();
  };

  updateDateColumnIndex = (dateColumnIndex) => {
    DataStore.updateBankImportSettings({ dateColumnIndex });
  };

  updateTextColumnIndex = (textColumnIndex) => {
    DataStore.updateBankImportSettings({ textColumnIndex });
  };

  updateAmountColumnIndex = (amountColumnIndex) => {
    DataStore.updateBankImportSettings({ amountColumnIndex });
  };

  updateAccountBalanceColumnIndex = (accountBalanceColumnIndex) => {
    DataStore.updateBankImportSettings({ accountBalanceColumnIndex });
  };

  clearSelectedEntries = () => {
    this.setState({ selectedEntries: new Set() });
  };

  onSelectEntry = (id, event) => {
    let selectedEntries = new Set(this.state.selectedEntries);
    if (event.target.checked) {
      selectedEntries.add(id);
    } else {
      selectedEntries.delete(id);
    }
    this.setState({ selectedEntries });
  };

  onSelectAllEntries = (sortedDataList) => {
    let { selectedEntries } = this.state;

    let allSelected = selectedEntries.size === sortedDataList.getSize();

    if (allSelected) {
      this.setState({ selectedEntries: new Set() });
    } else {
      this.setState({
        selectedEntries: new Set(sortedDataList._data.map((d) => d.id)),
      });
    }
  };

  clearSelectedPostings = () => {
    this.setState({ selectedPostings: new Set() });
  };

  onSelectPosting = (id, event) => {
    let selectedPostings = new Set(this.state.selectedPostings);
    if (event.target.checked) {
      selectedPostings.add(id);
    } else {
      selectedPostings.delete(id);
    }
    this.setState({ selectedPostings });
  };

  onSelectAllPostings = (sortedDataList) => {
    let { selectedPostings } = this.state;

    let allSelected = selectedPostings.size === sortedDataList.getSize();

    if (allSelected) {
      this.setState({ selectedPostings: new Set() });
    } else {
      this.setState({
        selectedPostings: new Set(sortedDataList._data.map((d) => d.id)),
      });
    }
  };

  onDeletePostings = async () => {
    let { bankPostings } = this.props;

    let { selectedPostings } = this.state;

    let promises = [];
    selectedPostings.forEach((id) => {
      promises.push(ApiService.deleteBankPosting(id));
    });

    let deletedPostings = bankPostings
      .filter((p) => !!selectedPostings.has(p.id))
      .map((p) => ({ ...p, type: ObjectType.POSTING }));

    let deletionOp = new DeleteOperation(deletedPostings);
    this.addOperation(deletionOp);

    this.clearSelectedPostings();

    let responses = await Promise.all(promises);

    DataStore.fetchBankPostings();

    return responses;
  };

  onSelectBankAccount = (selectedBankAccountId) => {
    this.setState({ selectedBankAccountId });
  };

  findDoubles = () => {
    let { doubleGroupProgress } = this.state;

    if (doubleGroupProgress > 0) {
      return;
    }

    this.doReconciliateAll(true);
  };

  selectReconciliated = () => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    this.setState({
      selectedEntries: new Set(
        bookKeepingDraftEntries.filter((e) => !!e.approved).map((e) => e.id)
      ),
      selectedPostings: new Set(
        bankPostings.filter((p) => !!p.approved).map((e) => e.id)
      ),
    });
  };

  doReconciliateAll = async (onlyDoubles = false) => {
    let {
      bookKeepingDraftEntries,
      bankPostings,
      // currentStep
    } = this.props;

    let filteredPostings = bankPostings.filter((p) => !p.approved);
    let filteredEntries = bookKeepingDraftEntries.filter((p) => !p.approved);

    let { postingToEntryMap, entryToPostingMap } = await this.getMatches(
      filteredPostings,
      filteredEntries
    );

    let reconciliationCount = !onlyDoubles ? 0 : -1;
    //Map from prices to postings with those prices
    let doubleGroups = {};

    let selectedEntries = new Set();
    let selectedPostings = new Set();

    var promises = [];
    let approvedPostingIds = new Set();
    let approvedEntryIds = new Set();
    Object.keys(postingToEntryMap).forEach((idString) => {
      let postingId = parseInt(idString, 10);
      let entryMatches = postingToEntryMap[postingId];
      //Approve one-to-one matches
      if (
        !onlyDoubles &&
        entryMatches.length === 1 &&
        entryToPostingMap[entryMatches[0].id].length === 1
      ) {
        approvedPostingIds.add(postingId);
        // promises.push(ApiService.updateBankPosting({
        //   ...filteredPostings.find(p => p.id === postingId),
        //   approved: true
        // }));
        approvedEntryIds.add(entryMatches[0].id);
        // promises.push(ApiService.updateBookkeepingDraftEntry({
        //   ...filteredEntries.find(e => e.id === entryMatches[0].id),
        //   approved: true
        // }));
        reconciliationCount++;
        selectedPostings.add(postingId);
        selectedEntries.add(entryMatches[0].id);
      } else {
        //Cache double matches for later
        if (entryMatches.length > 1) {
          let firstEntry = entryMatches[0];
          let amount = firstEntry.amount;

          if (!!doubleGroups[amount]) {
            doubleGroups[amount].entryIds = new Set([
              ...doubleGroups[amount].entryIds,
              ...entryMatches.map((e) => e.id),
            ]);
            doubleGroups[amount].postingIds = new Set([
              ...doubleGroups[amount].postingIds,
              postingId,
            ]);
          } else {
            doubleGroups[amount] = {
              postingIds: new Set([postingId]),
              entryIds: new Set(entryMatches.map((e) => e.id)),
            };
          }
        }
      }
    });

    Object.keys(entryToPostingMap).forEach((idString) => {
      let entryId = parseInt(idString, 10);
      let postingMatches = entryToPostingMap[entryId];

      //Cache double matches for later.
      //One-to-one matches have already been approved above.
      if (postingMatches.length > 1) {
        let firstPosting = postingMatches[0];
        let amount = firstPosting.amount;

        if (!!doubleGroups[amount]) {
          doubleGroups[amount].postingIds = new Set([
            ...doubleGroups[amount].postingIds,
            ...postingMatches.map((p) => p.id),
          ]);
          doubleGroups[amount].entryIds = new Set([
            ...doubleGroups[amount].entryIds,
            entryId,
          ]);
        } else {
          doubleGroups[amount] = {
            postingIds: new Set(postingMatches.map((p) => p.id)),
            entryIds: new Set([entryId]),
          };
        }
      }
    });

    let doubleGroupArray = Object.keys(doubleGroups)
      .map((key) => ({
        amount: parseInt(key, 10),
        postingIds: doubleGroups[key].postingIds,
        entryIds: doubleGroups[key].entryIds,
      }))
      .sort((g1, g2) => {
        if (
          (g1.postingIds.size === g1.entryIds.size) !==
          (g2.postingIds.size === g2.entryIds.size)
        ) {
          return g1.postingIds.size === g1.entryIds.size ? -1 : 1;
        }

        return Math.abs(g1.amount) - Math.abs(g2.amount);
      });

    if (
      onlyDoubles &&
      doubleGroupArray.length > 0 &&
      selectedEntries.size === 0 &&
      selectedPostings.size === 0
    ) {
      this.selectDoubleGroup(doubleGroupArray[0]);
    } else {
      this.setState({
        selectedEntries,
        selectedPostings,
      });
    }

    try {
      if (approvedEntryIds.size > 0) {
        let updateResponse = await ApiService.updateBookkeepingDraftEntryList({
          entryIds: [...approvedEntryIds],
          approve: true,
        });

        if (!updateResponse.ok) {
          this.fetchBankBalancingData();
          return;
        }
      }

      if (approvedPostingIds.size > 0) {
        let updateResponse = await ApiService.updateBankPostingList({
          postingIds: [...approvedPostingIds],
          approve: true,
        });

        if (!updateResponse.ok) {
          this.fetchBankBalancingData();
          return;
        }
      }

      if (promises.length > 0) {
        let responses = await Promise.all(promises);
        if (responses.find((r) => !r.ok)) {
          this.fetchBankBalancingData();
        }
      }

      //Optimistic approval updating
      if (approvedPostingIds.size > 0 || approvedEntryIds.size > 0) {
        let store = DataStore.getStore();
        DataStore.setState({
          bankPostings: [
            ...store.bankPostings.filter((p) => !approvedPostingIds.has(p.id)),
            ...store.bankPostings
              .filter((p) => approvedPostingIds.has(p.id))
              .map((p) => ({ ...p, approved: true })),
          ],
          bookKeepingDraftEntries: [
            ...store.bookKeepingDraftEntries.filter(
              (e) => !approvedEntryIds.has(e.id)
            ),
            ...store.bookKeepingDraftEntries
              .filter((e) => approvedEntryIds.has(e.id))
              .map((e) => ({ ...e, approved: true })),
          ],
        });
      }

      this.setState({
        numberReconciliated: reconciliationCount,
        doubleGroupArray,
        doubleGroupProgress: 0,
      });

      if (approvedEntryIds.size > 0 || approvedPostingIds.size > 0) {
        let reconOp = new ReconOperation(approvedEntryIds, approvedPostingIds);
        this.addOperation(reconOp);
      }
    } catch {
      this.fetchBankBalancingData();
      return;
    }

    // this.clearSelectedPostings();
    // this.clearSelectedEntries();
  };

  findApproximateMatches = async () => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let filteredPostings = bankPostings.filter((p) => !p.approved);
    let filteredEntries = bookKeepingDraftEntries.filter((p) => !p.approved);

    let { postingToEntryMap } = await this.getMatches(
      filteredPostings,
      filteredEntries,
      5
    );

    let postingApproxMatchIds = new Set();
    let entryApproxMatchIds = new Set();

    Object.keys(postingToEntryMap).forEach((idString) => {
      let postingId = parseInt(idString, 10);
      let entryMatches = postingToEntryMap[postingId];
      postingApproxMatchIds.add(postingId);
      entryMatches.forEach((match) => {
        entryApproxMatchIds.add(match.id);
      });
    });

    this.setState({
      postingApproxMatchIds,
      entryApproxMatchIds,
    });
  };

  getMatches = async (postings = [], entries = [], slack = 0) => {
    try {
      let simplePostings = postings.map((p) => ({
        id: p.id,
        amount: p.amount,
        date: p.date,
      }));
      let simpleEntries = entries.map((e) => ({
        id: e.id,
        amount: this.getAmountForEntry(e),
        date: e.creationDate,
      }));
      this.setState({ loadingMatches: true });
      let result = await ApiService.findMatches({
        entries: simpleEntries,
        bankPostings: simplePostings,
        approximateSlack: slack,
      });
      this.setState({ loadingMatches: false });
      return result;
    } catch (reason) {
      console.log(reason);
      return {
        postingToEntryMap: {},
        entryToPostingMap: {},
      };
    }
  };

  findMultiMatches = async () => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let filteredPostings = bankPostings.filter((p) => !p.approved);
    let filteredEntries = bookKeepingDraftEntries.filter((p) => !p.approved);

    let simplePostings = filteredPostings.map((p) => ({
      id: p.id,
      amount: p.amount,
      date: p.date,
    }));
    let simpleEntries = filteredEntries.map((e) => ({
      id: e.id,
      amount: e.isIncome ? e.amount : -e.amount,
      date: e.creationDate,
    }));

    this.setState({ loadingMatches: true });

    try {
      let result = await ApiService.findMultiMatches({
        entries: simpleEntries,
        bankPostings: simplePostings,
      });
      this.setState({
        entryMultiMatches: result.entryMultiMatches,
        postingMultiMatches: result.postingMultiMatches,
        loadingMatches: false,
      });

      let { currentStep } = this.props;
      let continueIfNoResults =
        currentStep === StepValues.ALGORITM ||
        currentStep === StepValues.ALGORITM - 1;

      if (
        Object.keys(result.entryMultiMatches).length === 0 &&
        continueIfNoResults
      ) {
        this.onForward();
      } else {
        this.selectMultiMatches(result.entryGroups, result.postingGroups);
      }
    } catch (reason) {
      console.log(reason);
      this.setState({ loadingMatches: false });
    }
  };

  selectMultiMatches = (entryGroups, postingGroups) => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let selectedEntries = new Set();
    let selectedPostings = new Set();
    Object.keys(entryGroups).forEach((key) => {
      let groupEntryIds = entryGroups[key];
      let groupEntries = bookKeepingDraftEntries.filter(
        (e) => !!groupEntryIds.find((id) => id === e.id)
      );
      let groupPostingIds = postingGroups[key];
      let groupPostings = bankPostings.filter((p) =>
        groupPostingIds.find((id) => id === p.id)
      );

      let entrySum = groupEntries.reduce((acc, entry) => {
        return acc + this.getAmountForEntry(entry);
      }, 0);

      let bankSum = groupPostings.reduce((acc, posting) => {
        return acc + posting.amount;
      }, 0);

      if (entrySum.toFixed(2) === bankSum.toFixed(2)) {
        selectedEntries = new Set([...selectedEntries, ...groupEntryIds]);
        selectedPostings = new Set([...selectedPostings, ...groupPostingIds]);
        return;
      }

      groupEntries.forEach((entry) => {
        if (!entry.description) {
          return;
        }

        let entryText = entry.description.toLowerCase();
        let matchPostings = groupPostings.filter((p) => {
          if (!p.text) {
            return false;
          }
          let postingText = p.text.toLowerCase();
          return (
            entryText.includes(postingText) || postingText.includes(entryText)
          );
        });
        if (matchPostings.length > 0) {
          let sum = matchPostings.reduce((acc, posting) => {
            return acc + posting.amount;
          }, 0);
          let entryAmount = this.getAmountForEntry(entry);
          if (entryAmount.toFixed(2) !== sum.toFixed(2)) {
            return;
          }
          selectedEntries = new Set([...selectedEntries, entry.id]);
          selectedPostings = new Set([
            ...selectedPostings,
            ...matchPostings.map((p) => p.id),
          ]);
        }
      });

      groupPostings.forEach((posting) => {
        if (!posting.text) {
          return;
        }

        let postingText = posting.text.toLowerCase();
        let matchEntries = groupEntries.filter((e) => {
          if (!e.description) {
            return false;
          }
          let entryText = e.description.toLowerCase();
          return (
            entryText.includes(postingText) || postingText.includes(entryText)
          );
        });
        if (matchEntries.length > 0) {
          let sum = matchEntries.reduce((acc, entry) => {
            return acc + this.getAmountForEntry(entry);
          }, 0);
          let postingAmount = posting.amount;
          if (postingAmount.toFixed(2) !== sum.toFixed(2)) {
            return;
          }
          selectedEntries = new Set([
            ...selectedEntries,
            ...matchEntries.map((e) => e.id),
          ]);
          selectedPostings = new Set([...selectedPostings, posting.id]);
        }
      });
    });
    this.setState({
      selectedEntries,
      selectedPostings,
    });
  };

  onReconciliate = async () => {
    let {
      bookKeepingDraftEntries,
      bankPostings,
      currentStep,
      selectedContact,
      createdData,
    } = this.props;

    let {
      selectedPostings,
      selectedEntries,
      doubleGroupArray,
      creditorEntryIds,
      debitorEntryIds,
    } = this.state;

    let filteredPostings = bankPostings.filter((p) =>
      selectedPostings.has(p.id)
    );
    let filteredEntries = bookKeepingDraftEntries.filter((p) =>
      selectedEntries.has(p.id)
    );

    let goForwardOnCompletion = false;
    if (currentStep === StepValues.DOUBLES) {
      goForwardOnCompletion = doubleGroupArray.length === 1;
    }

    let reconOp = new ReconOperation();

    let bankId = this.getDefaultPaymentAccountId();
    let promises = [];
    filteredPostings.forEach((posting) => {
      promises.push(
        DataActions.updateBankPosting({
          ...posting,
          approved: true,
        })
      );
      reconOp.postingIds.add(posting.id);
    });

    let amountSlack = currentStep === StepValues.APPROX ? 500 : 0;

    let newEntries = [];
    for (let entry of filteredEntries) {
      //Sequential
      let updatedEntry = {
        ...entry,
        approved: true,
      };

      reconOp.entryIds.add(entry.id);
      if (entry.status !== "Posted") {
        updatedEntry.balanceFinanceAccountId = bankId;
      }

      promises.push(DataActions.updateBookkeepingDraftEntry(updatedEntry));

      //Create new entries for creditor/debitor postings
      if (entry.status === "Posted") {
        let creationDate = moment.utc();
        let amount = this.getAmountForEntry(entry);
        let posting = filteredPostings.find(
          (p) => Math.abs(amount - p.amount) <= amountSlack
        );
        if (!!posting) {
          creationDate = moment.utc(posting.date);
        } else if (filteredPostings.length > 0) {
          creationDate = moment.utc(filteredPostings[0].date);
        }

        //Check if the creditor/debitor account is the main or the balance account
        let creditorDebitorData =
          creditorEntryIds[entry.id] || debitorEntryIds[entry.id] || {};
        let isMain = !creditorDebitorData.isBalanceAccountEntry;

        let text = entry.receiptNumber + " - " + (entry.description || "");
        newEntries.push({
          isIncome: isMain ? !entry.isIncome : entry.isIncome,
          description: text,
          amount: entry.amount,
          vat: 0,
          creationDate,
          contactInfoId: selectedContact.id,
          financeAccountId: isMain
            ? entry.financeAccountId
            : entry.balanceFinanceAccountId,
          balanceFinanceAccountId: bankId,
          status: "Approved",
          approved: true,
        });
      }
    }

    this.clearSelectedPostings();
    this.clearSelectedEntries();

    if (currentStep === StepValues.ALGORITM) {
      await Promise.all(promises);
      this.findMultiMatches();
    }

    let { createdEntryIds } = this.props.createdData;

    let newCreatedEntryIds = [...createdEntryIds];
    for (let newEntry of newEntries) {
      //Sequential
      let response = await DataActions.createBookkeepingDraftEntry(newEntry);
      if (response.ok) {
        let createdEntry = await response.json();
        if (!createdEntry.id) {
          throw new Error("Created entry is missing ID");
        }
        newCreatedEntryIds.push(createdEntry.id);
      }
    }
    if (newCreatedEntryIds.length > createdEntryIds.length) {
      DataStore.updateBankBalancingData({
        [selectedContact.id]: {
          ...createdData,
          createdEntryIds: newCreatedEntryIds,
        },
      });
    }

    if (goForwardOnCompletion) {
      this.onForward();
    }

    this.addOperation(reconOp);
  };

  onReconciliateAll = async () => {
    await this.doReconciliateAll();
    this.selectReconciliated();
  };

  onApproveEntries = async () => {
    let { bookKeepingDraftEntries } = this.props;

    let { selectedEntries } = this.state;

    let reconOp = new ReconOperation();
    let filteredEntries = bookKeepingDraftEntries.filter((p) =>
      selectedEntries.has(p.id)
    );

    let promises = [];
    filteredEntries.forEach((entry) => {
      promises.push(
        ApiService.updateBookkeepingDraftEntry({
          ...entry,
          approved: true,
        })
      );
      reconOp.entryIds.add(entry.id);
    });

    this.addOperation(reconOp);

    await DataStore.fetchBankReconEntries();
  };

  onCancelAllReconciliations = async () => {
    let {
      bookKeepingDraftEntries,
      bankPostings,
      selectedContact,
      createdData,
    } = this.props;

    // let promises = [];
    let approvedPostings = bankPostings.filter((p) => p.approved);
    let approvedEntries = bookKeepingDraftEntries.filter((e) => e.approved);

    // approvedPostings.forEach(posting => {
    //   promises.push(ApiService.updateBankPosting({
    //     ...posting,
    //     approved: false
    //   }));
    // });

    // approvedEntries
    //   .forEach(entry => {
    //     promises.push(ApiService.updateBookkeepingDraftEntry({
    //       ...entry,
    //       approved: false
    //     }));
    // });

    let response = null;
    if (approvedEntries.length > 0) {
      response = await ApiService.updateBookkeepingDraftEntryList({
        entryIds: approvedEntries.map((e) => e.id),
        unapprove: true,
      });

      if (!response.ok) {
        this.fetchBankBalancingData();
        this.displayResponseErrorMessage(response);
        return false;
      }
    }

    if (approvedPostings.length > 0) {
      response = await ApiService.updateBankPostingList({
        postingIds: approvedPostings.map((e) => e.id),
        unapprove: true,
      });

      if (!response.ok) {
        this.fetchBankBalancingData();
        this.displayResponseErrorMessage(response);
        return false;
      }
    }

    if (createdData.createdEntryIds.length > 0) {
      response = await ApiService.updateBookkeepingDraftEntryList({
        entryIds: createdData.createdEntryIds,
        delete: true,
      });

      if (!response.ok) {
        this.fetchBankBalancingData();
        this.displayResponseErrorMessage(response);
        return;
      }
    }

    this.clearSelectedPostings();
    this.clearSelectedEntries();

    let store = DataStore.getStore();
    DataStore.setState({
      bankPostings: [
        ...store.bankPostings.map((p) => ({ ...p, approved: false })),
      ],
      bookKeepingDraftEntries: [
        ...store.bookKeepingDraftEntries.map((e) => ({
          ...e,
          approved: false,
        })),
      ],
    });

    this.setState({ numberReconciliated: -1 });

    DataStore.updateBankBalancingData({
      [selectedContact.id]: {
        createdEntryIds: [],
      },
    });

    // createdData.createdEntryIds.forEach(id => {
    //   let entry = bookKeepingDraftEntries.find(e => e.id === id);
    //   if(!entry) {
    //     return;
    //   }
    //   promises.push(ApiService.deleteBookkeepingDraftEntryAndReceipt(id));
    // })
    // if(promises.length > 0) {
    //   let responses = await Promise.all(promises);
    //   if(!!responses.find(r => !r.ok)) {
    //     this.fetchBankBalancingData();

    //     DataStore.updateBankBalancingData({
    //       [selectedContact.id]: {
    //         createdEntryIds: []
    //       }
    //     });
    //   }
    // }

    // await Promise.all(promises);

    this.onRestart();

    this.setState({
      operations: [],
    });
  };

  displayResponseErrorMessage = async (response) => {
    let text = await response.text();

    ModalService.openAlertModal(text);
  };

  onCancelReconciliation = async () => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let { selectedPostings, selectedEntries } = this.state;

    let filteredPostings = bankPostings.filter(
      (p) => selectedPostings.has(p.id) && p.approved
    );
    let filteredEntries = bookKeepingDraftEntries.filter(
      (p) => selectedEntries.has(p.id) && p.approved
    );

    let promises = [];
    let cancelledPostings = new Set();
    let cancelledEntries = new Set();
    filteredPostings.forEach((posting) => {
      promises.push(
        ApiService.updateBankPosting({
          ...posting,
          approved: false,
        })
      );
      cancelledPostings.add(posting.id);
    });

    filteredEntries.forEach((entry) => {
      promises.push(
        ApiService.updateBookkeepingDraftEntry({
          ...entry,
          approved: false,
        })
      );
      cancelledEntries.add(entry.id);
    });

    this.clearSelectedPostings();
    this.clearSelectedEntries();

    if (cancelledPostings.size > 0 || cancelledEntries.size > 0) {
      let store = DataStore.getStore();
      DataStore.setState({
        bankPostings: [
          ...store.bankPostings.filter((p) => !cancelledPostings.has(p.id)),
          ...store.bankPostings
            .filter((p) => cancelledPostings.has(p.id))
            .map((p) => ({ ...p, approved: false })),
        ],
        bookKeepingDraftEntries: [
          ...store.bookKeepingDraftEntries.filter(
            (e) => !cancelledEntries.has(e.id)
          ),
          ...store.bookKeepingDraftEntries
            .filter((e) => cancelledEntries.has(e.id))
            .map((e) => ({ ...e, approved: false })),
        ],
      });
    }

    if (promises.length > 0) {
      let responses = await Promise.all(promises);
      if (responses.find((r) => !r.ok)) {
        this.fetchBankBalancingData();
      }
    }

    this.setState({
      operations: [],
    });
  };

  onApproveAuto = async () => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let { selectedEntries, selectedPostings } = this.state;

    let promises = [];
    //Deapprove deselected entries
    bookKeepingDraftEntries
      .filter((e) => !!e.approved)
      .filter((e) => !selectedEntries.has(e.id))
      .forEach((entry) => {
        promises.push(
          ApiService.updateBookkeepingDraftEntry({
            ...entry,
            approved: false,
          })
        );
      });

    bankPostings
      .filter((p) => !!p.approved)
      .filter((p) => !selectedPostings.has(p.id))
      .forEach((posting) => {
        promises.push(
          ApiService.updateBankPosting({
            ...posting,
            approved: false,
          })
        );
      });

    await Promise.all(promises);

    await this.fetchBankBalancingData();

    this.onForward();

    return true;
  };

  onForward = () => {
    let { currentStep, selectedContact } = this.props;

    let { entryApproxMatchIds, selectedEntryAccountIdMap, hiddenBankPostings } =
      this.state;

    if (currentStep >= Object.keys(StepValues).length - 1) {
      return;
    }

    let nextStep = currentStep + 1;
    if (currentStep === StepValues.AUTO) {
      this.moveApprovedEntriesToBank();
    }
    if (nextStep === StepValues.AUTO) {
      this.selectReconciliated();
    }
    if (nextStep === StepValues.DOUBLES) {
      //Ensure doubles have been calculated
      this.findDoubles();
    }
    if (nextStep === StepValues.ALGORITM) {
      this.findMultiMatches();
    }
    if (nextStep === StepValues.APPROX && entryApproxMatchIds.size === 0) {
      this.findApproximateMatches();
    }
    if (
      currentStep === StepValues.ACCOUNTS &&
      Object.keys(selectedEntryAccountIdMap).length !== 0
    ) {
      this.approveEntryAccountSelection();
    }
    //The user can first select avvounts in the approx step
    if (nextStep === StepValues.APPROX) {
      this.initializeBankPostingAccounts();
    }
    if (currentStep === StepValues.BANKPOSTING && hiddenBankPostings.size > 0) {
      this.setState({
        selectedPostingAccountIdMap: {},
        hiddenBankPostings: new Set(),
      });
    }

    DataStore.updateBankProgress({
      [selectedContact.id]: nextStep,
    });

    this.clearSelectedPostings();
    this.clearSelectedEntries();

    this.setState({
      doubleGroupProgress: 0,
      entryFilterTexts: {},
      bankPostingFilterTexts: {},
      selectedEntryColumn: "",
      selectedBankPostingColumn: "",
      priceFilter: PriceFilter.ALL,
      monthFilter: -1,
    });
  };

  moveApprovedEntriesToBank = async () => {
    let {
      bookKeepingDraftEntries,
      selectedContact,
      bankPostings,
      currentStep,
      createdData,
    } = this.props;

    let {
      creditorEntryIds,
      debitorEntryIds,
      selectedEntries,
      selectedPostings,
    } = this.state;

    let approvedEntries = bookKeepingDraftEntries.filter(
      (e) => e.approved && selectedEntries.has(e.id)
    ); //Deselected entries will be deapproved in onApproveAuto, but the data might not have had time to update yet.

    let draftEntries = approvedEntries.filter((e) => e.status !== "Posted");
    let bankAccountId = this.getDefaultPaymentAccountId();

    let approvedPostings = bankPostings.filter(
      (p) => p.approved && selectedPostings.has(p.id)
    );

    // let promises = [];
    // draftEntries.forEach(entry => {
    //   if(entry.balanceFinanceAccountId !== bankAccountId) {
    //     promises.push(ApiService.updateBookkeepingDraftEntry({
    //       ...entry,
    //       balanceFinanceAccountId: bankAccountId
    //     }))
    //   }
    // });

    // await Promise.all(promises);

    let entriesToBeMoved = draftEntries.filter(
      (e) => e.balanceFinanceAccountId !== bankAccountId
    );
    if (entriesToBeMoved.length > 0) {
      let response = await ApiService.updateBookkeepingDraftEntryList({
        entryIds: entriesToBeMoved.map((e) => e.id),
        newBalanceAccountId: bankAccountId,
      });

      if (!response.ok) {
        alert("Error: Approved entries could not be moved to the bank.");

        return false;
      }
    }

    let entryTexts = new Set();
    bookKeepingDraftEntries.forEach((entry) => {
      entryTexts.add(entry.description);
    });

    let amountSlack = currentStep === StepValues.APPROX ? 500 : 0;
    let creditorDebitorEntries = approvedEntries.filter(
      (e) => e.status === "Posted"
    );

    //Create new entries for creditor/debitor postings
    let bankBalancingDate = DataStore.getStore().bankBalancingData[
      selectedContact.id
    ] || { createdEntryIds: [] };
    let createdEntryIds = [...bankBalancingDate.createdEntryIds];

    for (let entry of creditorDebitorEntries) {
      //Sequential
      let text = entry.receiptNumber + " - " + (entry.description || "");
      if (entryTexts.has(text)) {
        //Do not create the same entry more than once.
        continue;
      }

      let creationDate = moment.utc();
      let amount = this.getAmountForEntry(entry);

      let posting = approvedPostings.find(
        (p) => Math.abs(amount - p.amount) <= amountSlack
      );
      if (!!posting) {
        creationDate = moment.utc(posting.date);
      } else if (approvedPostings.length > 0) {
        creationDate = moment.utc(approvedPostings[0].date);
      }

      //Check if the creditor/debitor account is the main or the balance account
      let creditorDebitorData =
        creditorEntryIds[entry.id] || debitorEntryIds[entry.id] || {};
      let isMain = !creditorDebitorData.isBalanceAccountEntry;

      let newEntry = {
        isIncome: isMain ? !entry.isIncome : entry.isIncome,
        description: text,
        amount: entry.amount,
        vat: 0,
        creationDate,
        contactInfoId: selectedContact.id,
        financeAccountId: isMain
          ? entry.financeAccountId
          : entry.balanceFinanceAccountId,
        balanceFinanceAccountId: bankAccountId,
        status: "Approved",
        approved: true,
      };

      let response = await ApiService.createBookkeepingDraftEntry(newEntry);
      if (response.ok) {
        let newEntry = await response.json();
        createdEntryIds.push(newEntry.id);
      }
    }

    if (createdEntryIds.length !== bankBalancingDate.createdEntryIds.length) {
      DataStore.updateBankBalancingData({
        [selectedContact.id]: {
          ...createdData,
          createdEntryIds,
        },
      });
    }

    await DataStore.fetchBankReconEntries();
  };

  initializeBankPostingAccounts() {
    let { bankPostings, standardReconAccountId, financeAccounts } = this.props;

    if (!standardReconAccountId) {
      this.setState({
        selectedPostingAccountIdMap: {},
        hiddenBankPostings: new Set(),
      });
      return;
    }

    let standardAccount = financeAccounts.find(
      (a) => a.id === standardReconAccountId
    );
    if (!standardAccount) {
      return;
    }

    let newMap = {};
    bankPostings
      .filter((p) => !p.approved)
      .forEach((posting) => {
        newMap[posting.id] = {
          value: standardAccount.id,
          label:
            standardAccount.number.toString() + " - " + standardAccount.name,
        };
      });

    this.setState({
      selectedPostingAccountIdMap: newMap,
      hiddenBankPostings: new Set(),
    });
  }

  onPrevious = () => {
    let { currentStep, selectedContact } = this.props;

    let { doubleGroupArray, entryMultiMatches, entryApproxMatchIds } =
      this.state;

    if (currentStep < 1) {
      return;
    }

    let nextStep = currentStep - 1;
    if (nextStep === StepValues.AUTO) {
      this.selectReconciliated();
    }
    if (nextStep === StepValues.DOUBLES && doubleGroupArray.length === 0) {
      //Ensure doubles have been calculated
      this.findDoubles();
    }
    if (
      nextStep === StepValues.ALGORITM &&
      Object.keys(entryMultiMatches).length === 0
    ) {
      this.findMultiMatches();
    }
    if (nextStep === StepValues.APPROX && entryApproxMatchIds.size === 0) {
      this.findApproximateMatches();
    }

    DataStore.updateBankProgress({
      [selectedContact.id]: nextStep,
    });

    this.clearSelectedPostings();
    this.clearSelectedEntries();

    this.setState({
      doubleGroupProgress: 0,
      entryFilterTexts: {},
      bankPostingFilterTexts: {},
      selectedEntryColumn: "",
      selectedBankPostingColumn: "",
      priceFilter: PriceFilter.ALL,
      monthFilter: -1,
    });
  };

  onRestart = () => {
    let { currentStep, selectedContact } = this.props;

    if (currentStep < 1) {
      return;
    }

    DataStore.updateBankProgress({
      [selectedContact.id]: StepValues.UPLOAD,
    });

    this.setState(this.getStartState());
  };

  emptySet = new Set();

  onUpdateEntry = async (entry, reloadOnUpdate = true) => {
    let { bookKeepingDraftEntries } = this.props;

    let oldAmount = bookKeepingDraftEntries.find(
      (e) => e.id === entry.id
    ).amount;

    if (reloadOnUpdate) {
      await DataActions.updateBookkeepingDraftEntry(entry);
    } else {
      await ApiService.updateBookkeepingDraftEntry(entry);
    }
    this.updateResult();

    if (oldAmount !== entry.amount) {
      this.findSingleMatch(entry.id);
    }
  };

  onUpdateEntryWithoutReload = async (entry) => {
    return this.onUpdateEntry(entry, false);
  };

  selectMapAccount = (postingId, selectedAccount) => {
    this.setState((prevState) => ({
      selectedPostingAccountIdMap: {
        ...prevState.selectedPostingAccountIdMap,
        [postingId]: selectedAccount,
      },
      hiddenBankPostings: new Set([...prevState.hiddenBankPostings, postingId]),
      selectedPostings: new Set(
        [...prevState.selectedPostings].filter((id) => id !== postingId)
      ),
      entryFilterTexts: {},
      bankPostingFilterTexts: {},
      selectedEntryColumn: "",
      selectedBankPostingColumn: "",
    }));
  };

  cancelBankPostingAccountSelection = () => {
    this.initializeBankPostingAccounts();
  };

  selectEntryMapAccount = (entryId, selectedAccount) => {
    this.setState((prevState) => ({
      selectedEntryAccountIdMap: {
        ...prevState.selectedEntryAccountIdMap,
        [entryId]: selectedAccount,
      },
      selectedEntries: new Set(
        [...prevState.selectedEntries].filter((id) => id !== entryId)
      ),
      entryFilterTexts: {},
      bankPostingFilterTexts: {},
      selectedEntryColumn: "",
      selectedBankPostingColumn: "",
    }));
  };

  cancelEntryAccountSelection = () => {
    this.setState({
      selectedEntryAccountIdMap: {},
    });
  };

  approveEntryAccountSelection = async () => {
    let { bookKeepingDraftEntries, selectedPlan } = this.props;

    let { selectedEntryAccountIdMap } = this.state;

    let entryMap = {};
    bookKeepingDraftEntries.forEach((entry) => {
      entryMap[entry.id] = entry;
    });

    let promises = [];
    Object.keys(selectedEntryAccountIdMap).forEach((key) => {
      let id = parseInt(key, 10);
      let entry = entryMap[id];
      if (!entry) {
        console.log("Entry for account selection could not be found");
        return;
      }

      let account = selectedPlan.accounts.find(
        (a) => a.id === parseInt(selectedEntryAccountIdMap[id].value, 10)
      );
      if (!account) {
        console.log("Account for account selection could not be found");
        return;
      }

      promises.push(
        this.onUpdateEntryWithoutReload({
          ...entry,
          balanceFinanceAccountId: account.id,
          approved: true,
        })
      );

      // this.onUpdateEntry({
      //   ...entry,
      //   balanceFinanceAccountId: account.id,
      //   approved: true
      // });
    });

    await Promise.all(promises);

    DataStore.fetchBankReconEntries();

    this.setState({
      selectedEntryAccountIdMap: {},
    });
  };

  getFinanceAccountPlan = () => {
    let { financeAccountPlans, selectedContact } = this.props;

    let plan = financeAccountPlans.find(
      (f) => f.id === selectedContact.financeAccountPlanId
    );
    return plan || { accounts: [] };
  };

  getDefaultPaymentAccountId = () => {
    let { selectedPlan } = this.props;

    let { selectedBankAccountId } = this.state;

    if (!selectedPlan) {
      return 0;
    }

    let standardAccounts = selectedPlan.accounts.filter(
      (a) => a.type === "Standard"
    );

    if (selectedBankAccountId) {
      let standardPaymentAccount = standardAccounts.find(
        (a) => a.id === selectedBankAccountId
      );
      if (standardPaymentAccount) {
        return standardPaymentAccount.id;
      }
    }

    if (selectedPlan.customerBankAccountId) {
      let standardPaymentAccount = standardAccounts.find(
        (a) => a.id === selectedPlan.customerBankAccountId
      );
      if (standardPaymentAccount) {
        return standardPaymentAccount.id;
      }
    }

    let standardPaymentAccounts =
      selectedPlan.bankAccountIntervalStart > 0
        ? standardAccounts.filter(
            (a) =>
              a.number >= selectedPlan.bankAccountIntervalStart &&
              a.number < selectedPlan.bankAccountIntervalEnd
          )
        : [];

    return standardPaymentAccounts.length > 0
      ? standardPaymentAccounts[0].id
      : null;
  };

  createEntriesForBankPostings = async () => {
    let { bankPostings, selectedContact, createdData } = this.props;

    let { selectedPostingAccountIdMap } = this.state;

    try {
      let filteredPostings = bankPostings.filter(
        (p) => !!selectedPostingAccountIdMap[p.id]
      );
      if (filteredPostings.length === 0) {
        this.onForward();
        return true;
      }
      // let promises = [];
      let balanceFinanceAccountId = this.getDefaultPaymentAccountId();
      // let ok = true;
      let newEntries = [];
      for (let posting of filteredPostings) {
        //Sequential
        newEntries.push(
          this.createEntryForBankPosting(posting, balanceFinanceAccountId)
        );
        // let response = await this.createEntryForBankPosting(posting, balanceFinanceAccountId);
        // ok = ok && response.ok;
      }

      let response = await DataActions.createBookkeepingDraftEntryList(
        newEntries
      );

      // let responses = await Promise.all(promises);

      // let ok = !responses.find(r => !r.ok);

      if (response.ok) {
        let newEntries = await response.json();

        DataStore.updateBankBalancingData({
          [selectedContact.id]: {
            ...createdData,
            createdEntryIds: [
              ...createdData.createdEntryIds,
              ...newEntries.map((e) => e.id),
            ],
          },
        });

        this.onForward();
        return response.ok;
      }

      return response.ok;
    } catch (reason) {
      console.log(reason);
      return false;
    }
  };

  deleteAllBankPostings = async () => {
    let { selectedContact } = this.props;

    var response = await DataActions.deleteAllBankPostings(selectedContact.id);
    return response.ok;
  };

  createEntryForBankPosting = (posting, paymentAccount) => {
    let { selectedContact, taxSpecificationMap, selectedPlan } = this.props;

    let { selectedPostingAccountIdMap, standardReconAccountId } = this.state;

    let account = selectedPlan.accounts.find(
      (a) =>
        a.id === parseInt(selectedPostingAccountIdMap[posting.id].value, 10)
    );
    if (!account) {
      account = selectedPlan.accounts.find(
        (a) => a.id === standardReconAccountId
      );
    }
    if (!account) {
      console.log(
        "Account could not be found: " +
          selectedPostingAccountIdMap[posting.id].label
      );
      account = selectedPlan.accounts[0];
    }

    let vat = 0;
    let taxSpec = taxSpecificationMap[account.taxSpecificationId];
    if (!!taxSpec) {
      vat =
        Math.abs(posting.amount) -
        Math.abs(posting.amount) / (1 + taxSpec.taxPercentage / 100);
    }

    return {
      isIncome: posting.amount > 0,
      description: posting.text,
      amount: Math.abs(posting.amount),
      vat,
      creationDate: moment(posting.date),
      contactInfoId: selectedContact.id,
      financeAccountId: account.id,
      balanceFinanceAccountId: paymentAccount,
      status: "Approved",
      approved: true,
    };
  };

  onNextDouble = () => {
    let { doubleGroupArray, doubleGroupProgress } = this.state;

    let newGroupIndex = doubleGroupProgress + 1;
    if (doubleGroupArray.length > newGroupIndex) {
      this.selectDoubleGroup(doubleGroupArray[newGroupIndex]);
    }

    this.setState((prevState) => ({
      doubleGroupProgress: prevState.doubleGroupProgress + 1,
    }));
  };

  selectDoubleGroup = (group) => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let selectedEntries = new Set();
    let selectedPostings = new Set();
    if (group.postingIds.size === group.entryIds.size) {
      selectedEntries = new Set([...group.entryIds]);
      selectedPostings = new Set([...group.postingIds]);
    } else {
      let entries = bookKeepingDraftEntries.filter((e) =>
        group.entryIds.has(e.id)
      );
      let postings = bankPostings.filter((p) => group.postingIds.has(p.id));

      entries.forEach((entry) => {
        let postingMatch = postings.find(
          (p) =>
            !selectedPostings.has(p.id) &&
            moment
              .utc(p.date)
              .startOf("day")
              .isSame(moment.utc(entry.creationDate).startOf("day"))
        );

        if (!!postingMatch) {
          selectedEntries.add(entry.id);
          selectedPostings.add(postingMatch.id);
        }
      });
    }

    this.setState({
      selectedEntries,
      selectedPostings,
    });
  };

  sendMailToClient = () => {
    let { selectedContact, bookKeepingDraftEntries, standardReconAccountId } =
      this.props;

    if (!selectedContact.eMail) {
      return false;
    }

    let messageEntries = bookKeepingDraftEntries.filter(
      (e) =>
        e.financeAccountId === standardReconAccountId ||
        e.balanceFinanceAccountId === standardReconAccountId
    );

    let messageText =
      "<p>Kære " +
      (selectedContact.firstName || "") +
      " " +
      (selectedContact.lastName || "") +
      "</p>";
    messageText +=
      "<p> " +
      getText(
        "axoidcode175",
        "Vi anmoder om yderligere information vedrørende følgende posteringer:"
      ) +
      "</p>";
    messageEntries.forEach((entry) => {
      messageText +=
        "<p>" +
        moment(entry.creationDate).format("LL") +
        " - DKK" +
        entry.amount.toString() +
        " - " +
        entry.description +
        "</p>";
    });

    let message = {
      sender: null,
      receiver: null,
      externalMailReceiver: selectedContact.eMail,
      subject: getText(
        "axoidcode176",
        "Anmodning om oplysninger vedrørende bankposteringer"
      ),
      messageText,
      draft: true,
      attachments: [],
      bccUsers: [],
    };

    DataStore.setCachedMessage(message);
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  updateResult = debounce(() => {
    DataStore.fetchAccountingReportWithDraft();
  }, 500);

  onSelectEntryColumn = (columnName) => {
    this.setState((prevState) => ({
      selectedEntryColumn:
        prevState.selectedEntryColumn !== columnName ? columnName : "",
      entryFilterTexts:
        prevState.selectedEntryColumn === columnName
          ? {}
          : prevState.entryFilterTexts, //Reset filter when deselecting column
    }));
  };

  onSelectBankPostingColumn = (columnName) => {
    this.setState((prevState) => ({
      selectedBankPostingColumn:
        prevState.selectedBankPostingColumn !== columnName ? columnName : "",
      bankPostingFilterTexts:
        prevState.selectedBankPostingColumn === columnName
          ? {}
          : prevState.bankPostingFilterTexts, //Reset filter when deselecting column
    }));
  };

  updateEntryFilterText = (value) => {
    let { selectedEntryColumn } = this.state;

    this.setState((prevState) => ({
      entryFilterTexts: {
        ...prevState.entryFilterTexts,
        [selectedEntryColumn]: value,
      },
    }));
  };

  updateBankPostingFilterText = (value) => {
    let { selectedBankPostingColumn } = this.state;

    this.setState((prevState) => ({
      bankPostingFilterTexts: {
        ...prevState.bankPostingFilterTexts,
        [selectedBankPostingColumn]: value,
      },
    }));
  };

  // filterEntriesByColumn(entries) {
  //   let {
  //     financeAccountMap
  //   } = this.props;

  //   let {
  //     entryFilterTexts,
  //     bankPostingFilterTexts,
  //     priceFilter,
  //     monthFilter
  //   } = this.state;

  //   let filteredEntries = entries;
  //   let dateFilter = entryFilterTexts.creationDate || bankPostingFilterTexts.date;
  //   if(!!dateFilter) {
  //     filteredEntries = filteredEntries.filter(e =>
  //       moment(e.creationDate).format('L').includes(dateFilter.toLowerCase()));
  //   }
  //   if(!!entryFilterTexts.receiptNumber) {
  //     filteredEntries = filteredEntries.filter(e => e.receiptNumber.toString().includes(entryFilterTexts.receiptNumber.toLowerCase()));
  //   }
  //   let textFilter = entryFilterTexts.description || bankPostingFilterTexts.text;
  //   if(!!textFilter) {
  //     filteredEntries = filteredEntries.filter(e => (e.description || '').toLowerCase().includes(textFilter.toLowerCase()));
  //   }
  //   if(!!entryFilterTexts.account) {
  //     filteredEntries = filteredEntries.filter( e => {
  //       let account = financeAccountMap[e.financeAccountId];
  //       if(!account) {
  //         return false;
  //       }
  //       return (account.number.toString() + ' - ' + account.name).toLowerCase().includes(entryFilterTexts.account.toLowerCase());
  //     })
  //   }
  //   if(!!entryFilterTexts.amount) {
  //     let filterText = entryFilterTexts.amount
  //     .toLowerCase()
  //     .replace(/,/g, ".");

  //     filteredEntries = filteredEntries.filter(e => e.amount.toFixed(2).includes(filterText));
  //   }
  //   if(priceFilter > PriceFilter.ALL) {
  //     filteredEntries = filteredEntries.filter(e =>
  //       (e.isIncome ? e.amount : -e.amount) * (priceFilter === PriceFilter.POSITIVE ? 1 : -1) >= 0 )
  //   }
  //   if(monthFilter > -1) {
  //     filteredEntries = filteredEntries.filter(e => (
  //       moment.utc(e.creationDate).month() === monthFilter
  //     ))
  //   }

  //   return filteredEntries;
  // }

  // filterPostingsByColumn(postings) {
  //   let {
  //     financeAccountMap
  //   } = this.props;

  //   let {
  //     entryFilterTexts,
  //     bankPostingFilterTexts,
  //     priceFilter,
  //     monthFilter
  //   } = this.state;

  //   let filteredPostings = postings;
  //   let dateFilter = bankPostingFilterTexts.date || entryFilterTexts.creationDate;
  //   if(!!dateFilter) {
  //     filteredPostings = filteredPostings.filter(e =>
  //       moment(e.date).format('L').includes(dateFilter.toLowerCase()));
  //   }
  //   let textFilter = bankPostingFilterTexts.text || entryFilterTexts.description;
  //   if(!!textFilter) {
  //     filteredPostings = filteredPostings.filter(e => (e.text || '').toLowerCase().includes(textFilter.toLowerCase()))
  //   }
  //   if(!!bankPostingFilterTexts.account) {
  //     filteredPostings = filteredPostings.filter( e => {
  //       let account = financeAccountMap[e.financeAccountId];
  //       if(!account) {
  //         return false;
  //       }
  //       return (account.number.toString() + ' - ' + account.name).toLowerCase().includes(bankPostingFilterTexts.account.toLowerCase());
  //     })
  //   }
  //   if(!!bankPostingFilterTexts.amount) {
  //     let filterText = bankPostingFilterTexts.amount
  //     .toLowerCase()
  //     .replace(/,/g, ".");

  //     filteredPostings = filteredPostings.filter(e => e.amount.toString().includes(filterText));
  //   }
  //   if(!!bankPostingFilterTexts.accountBalance) {
  //     filteredPostings = filteredPostings.filter(e => e.accountBalance.toString().includes(bankPostingFilterTexts.accountBalance.toLowerCase()));
  //   }
  //   if(priceFilter > PriceFilter.ALL) {
  //     filteredPostings = filteredPostings.filter(p =>
  //       p.amount * (priceFilter === PriceFilter.POSITIVE ? 1 : -1) >= 0 )
  //   }
  //   if(monthFilter > -1) {
  //     filteredPostings = filteredPostings.filter(p => (
  //       moment.utc(p.date).month() === monthFilter
  //     ))
  //   }

  //   return filteredPostings;
  // }

  getCreditorDebitorAccountNumberIntervals = () => {
    let { selectedPlan } = this.props;

    if (!selectedPlan) {
      return {
        creditorAccountNumberIntervals: [],
        debitorAccountNumberIntervals: [],
      };
    }

    let creditorAccountNumberIntervals = this.getNumbersFromIntervalString(
      selectedPlan.bankReconAccounts
    );
    let debitorAccountNumberIntervals = this.getNumbersFromIntervalString(
      selectedPlan.bankReconDebitorAccounts
    );

    return { creditorAccountNumberIntervals, debitorAccountNumberIntervals };
  };

  getNumbersFromIntervalString = (intervalString) => {
    let accountNumberIntervals = [];
    if (!intervalString) {
      return [];
    }
    var intervalArray = intervalString.split(";").map((m) => m.trim());
    for (let i = 0; i < intervalArray.length; ++i) {
      let interval = intervalArray[i];
      let intervalNumbers = interval
        .split("-")
        .map((m) => parseInt(m.trim(), 10));
      if (intervalNumbers.find((n) => isNaN(n))) {
        return [];
      }
      accountNumberIntervals.push(intervalNumbers);
    }
    return accountNumberIntervals;
  };

  calculateCreditAndDebitEntryIds = () => {
    let { bookKeepingDraftEntries, financeAccountMap } = this.props;

    let { creditorAccountNumberIntervals, debitorAccountNumberIntervals } =
      this.getCreditorDebitorAccountNumberIntervals();

    let creditorEntryIds = {};
    let debitorEntryIds = {};

    bookKeepingDraftEntries
      .filter((e) => e.status === "Posted")
      .forEach((entry) => {
        let account = financeAccountMap[entry.financeAccountId];
        let balanceAccount = financeAccountMap[entry.balanceFinanceAccountId];

        if (!account && !balanceAccount) {
          return;
        }

        account = account || {};
        balanceAccount = balanceAccount || {};

        for (let i = 0; i < creditorAccountNumberIntervals.length; i++) {
          let interval = creditorAccountNumberIntervals[i];
          if (
            interval.length === 1 &&
            (account.number === interval[0] ||
              balanceAccount.number === interval[0])
          ) {
            creditorEntryIds[entry.id] = {
              isBalanceAccountEntry: balanceAccount.number === interval[0],
            };
          } else if (
            (interval[0] <= account.number && account.number <= interval[1]) ||
            (interval[0] <= balanceAccount.number &&
              balanceAccount.number <= interval[1])
          ) {
            creditorEntryIds[entry.id] = {
              isBalanceAccountEntry:
                interval[0] <= balanceAccount.number &&
                balanceAccount.number <= interval[1],
            };
          }
        }

        for (let j = 0; j < debitorAccountNumberIntervals.length; j++) {
          let interval = debitorAccountNumberIntervals[j];
          if (
            interval.length === 1 &&
            (account.number === interval[0] ||
              balanceAccount.number === interval[0])
          ) {
            debitorEntryIds[entry.id] = {
              isBalanceAccountEntry: balanceAccount.number === interval[0],
            };
          } else if (
            (interval[0] <= account.number && account.number <= interval[1]) ||
            (interval[0] <= balanceAccount.number &&
              balanceAccount.number <= interval[1])
          ) {
            debitorEntryIds[entry.id] = {
              isBalanceAccountEntry:
                interval[0] <= balanceAccount.number &&
                balanceAccount.number <= interval[1],
            };
          }
        }
      });

    this.setState({ creditorEntryIds, debitorEntryIds });
  };

  findSingleMatch = (entryId) => {
    let { bookKeepingDraftEntries, bankPostings } = this.props;

    let entry = bookKeepingDraftEntries.find((e) => e.id === entryId);
    if (!entry) {
      return;
    }

    let amount = this.getAmountForEntry(entry);

    let matches = bankPostings
      .filter((p) => !p.approved)
      .filter((p) => p.amount === amount);

    if (matches.length !== 1) {
      this.setState({
        singlePostingMatch: {},
      });

      return;
    }

    this.setState({
      singlePostingMatch: {
        entry,
        posting: matches[0],
      },
    });
  };

  getAmountForEntry = (entry) => {
    let { creditorEntryIds, debitorEntryIds } = this.state;

    let amount = entry.isIncome ? entry.amount : -entry.amount;

    if (entry.status === "Posted") {
      //If the creditor/debitor account is the main account, the amount should be inverted.
      //Credit postings will withdraw from the main account.
      let creditorDebitorData =
        creditorEntryIds[entry.id] || debitorEntryIds[entry.id] || {};
      if (!creditorDebitorData.isBalanceAccountEntry) {
        amount = -amount;
      }
    }

    return amount;
  };

  reconciliateSingleMatch = () => {
    let { singlePostingMatch } = this.state;

    if (!singlePostingMatch.posting) {
      return;
    }

    DataActions.updateBankPosting({
      ...singlePostingMatch.posting,
      approved: true,
    });

    DataActions.updateBookkeepingDraftEntry({
      ...singlePostingMatch.entry,
      approved: true,
    });

    let reconOp = new ReconOperation(
      new Set([singlePostingMatch.posting.id]),
      new Set([singlePostingMatch.entry.id])
    );
    this.addOperation(reconOp);

    this.setState({
      singlePostingMatch: {},
    });
  };

  cancelSingleMatch = () => {
    this.setState({
      singlePostingMatch: {},
    });
  };

  deleteSelectedEntries = async () => {
    // let { bookKeepingDraftEntries } = this.props;
    let { selectedEntries } = this.state;

    // let deleteOp = new DeleteOperation();

    // let filteredEntries = bookKeepingDraftEntries.filter(p => selectedEntries.has(p.id));
    // let promises = [];
    // filteredEntries.forEach(entry => {
    //   promises.push(ApiService.updateBookkeepingDraftEntry({...entry, trashed: true }));
    //   // promises.push(DataActions.deleteBookkeepingDraftEntryAndReceipt(entry));
    //   // deleteOp.objectList.push({...entry, type: ObjectType.ENTRY });
    // });

    // // this.addOperation(deleteOp);

    // let responses = await Promise.all(promises);

    let response = await ApiService.updateBookkeepingDraftEntryList({
      entryIds: [...selectedEntries],
      trash: true,
    });

    console.log("Test delete 2");
    await DataStore.fetchBankReconEntries();

    this.setState({
      selectedEntries: new Set(),
    });

    return response.ok;
  };

  defaultObject = {};

  onFinalizeEntries = async (entries) => {
    let { calculateNumbers, selectedContact } = this.props;

    if (calculateNumbers) {
      await this.updateReceiptNumbers(entries);
    }

    let entryIds = entries.map((e) => e.id);
    let response = await DataActions.finalizeDraftEntries(
      entryIds,
      selectedContact.id
    );
    if (!response.ok) {
      return false;
    }

    this.onGotoDraft();
  };

  updateReceiptNumbers = async (entries) => {
    let { selectedContact } = this.props;

    if (entries.length === 0) {
      return false;
    }

    let sortedEntries = entries.sort((l, r) => {
      if (l.creationDate === r.creationDate) {
        return 0;
      }
      return moment.utc(l.creationDate).isBefore(moment.utc(r.creationDate))
        ? -1
        : 1;
    });

    return ApiService.getNextReceiptNumber({
      contactId: selectedContact.id,
      date: moment.utc(sortedEntries[0].creationDate).format(),
    })
      .then((nextNumber) => {
        if (!nextNumber) {
          return;
        }
        nextNumber = parseInt(nextNumber, 10);
        let promises = [];
        sortedEntries.forEach((entry) => {
          if (nextNumber === entry.receiptNumber) {
            nextNumber++;
            return;
          }
          promises.push(
            ApiService.updateBookkeepingDraftEntry({
              ...entry,
              receiptNumber: nextNumber,
            })
          );
          nextNumber++;
        });

        return Promise.all(promises);
      })
      .then(() => {
        DataStore.fetchBookkeepingDraftEntries();
        return true;
      })
      .catch((reason) => {
        console.error(reason);
        return false;
      });
  };

  onGotoDraft = async () => {
    let { selectedContact } = this.props;

    // let response = await DataActions.updateContact({
    //   ...selectedContact,
    //   editor: null,
    //   editorId: null
    // })

    // if(!response.ok) {
    //   return false;
    // }

    let response = await DataActions.deleteAllBankPostings(selectedContact.id);
    if (!response.ok) {
      return false;
    }

    DataStore.updateBankProgress({
      [selectedContact.id]: StepValues.UPLOAD,
    });

    DataStore.updateBankBalancingData({
      [selectedContact.id]: {
        createdEntryIds: [],
      },
    });

    this.props.history.push("Drafts");
  };

  onHideStartValueWarning = () => {
    this.setState({ hideStartValueWarning: true });
  };

  onUpdatePriceFilter = (priceFilter) => {
    this.setState({ priceFilter: parseInt(priceFilter, 10) });
  };

  onUpdateMonthFilter = (monthFilter) => {
    this.setState({ monthFilter: parseInt(monthFilter, 10) });
  };

  applyStepFilters = (filteredEntries, filteredPostings) => {
    let { currentStep } = this.props;

    let {
      doubleGroupArray,
      doubleGroupProgress,
      entryApproxMatchIds,
      postingApproxMatchIds,
      // entryFilterTexts,
      // bankPostingFilterTexts,
      entryMultiMatches,
      postingMultiMatches,
      hiddenBankPostings,
      selectedEntryAccountIdMap,
      // priceFilter,
      // monthFilter,
    } = this.state;

    if (currentStep === StepValues.AUTO) {
      filteredEntries = filteredEntries.sort(
        (l, r) => this.getAmountForEntry(l) - this.getAmountForEntry(r)
      );
      filteredPostings = filteredPostings.sort((l, r) => l.amount - r.amount);
      //Only show reconciliated entries, if any
      let approvedEntries = filteredEntries.filter((e) => !!e.approved);
      let approvedPostings = filteredPostings.filter((e) => !!e.approved);
      if (approvedEntries.length > 0 && approvedPostings.length > 0) {
        filteredEntries = approvedEntries;
        filteredPostings = approvedPostings;
      }
    }

    if (currentStep > StepValues.AUTO) {
      //Hide reconciliated values after the initital step.
      filteredEntries = filteredEntries.filter((e) => !e.approved);
      filteredPostings = filteredPostings.filter((e) => !e.approved);
    }

    //Only show doubles
    if (currentStep === StepValues.DOUBLES) {
      let currentGroup = doubleGroupArray[doubleGroupProgress];
      filteredEntries = filteredEntries.filter(
        (e) => !!currentGroup && currentGroup.entryIds.has(e.id)
      );
      filteredPostings = filteredPostings.filter(
        (e) => !!currentGroup && currentGroup.postingIds.has(e.id)
      );
    }

    if (currentStep === StepValues.ALGORITM) {
      filteredEntries = filteredEntries.filter(
        (e) => !!entryMultiMatches[e.id]
      );
      filteredPostings = filteredPostings.filter(
        (p) => !!postingMultiMatches[p.id]
      );

      filteredEntries = filteredEntries.sort(
        (l, r) => entryMultiMatches[l.id] - entryMultiMatches[r.id]
      );
      filteredPostings = filteredPostings.sort(
        (l, r) => postingMultiMatches[l.id] - postingMultiMatches[r.id]
      );
    }

    if (currentStep === StepValues.APPROX) {
      filteredEntries = filteredEntries.filter(
        (e) => !e.approved && entryApproxMatchIds.has(e.id)
      );
      filteredPostings = filteredPostings.filter(
        (p) => !p.approved && postingApproxMatchIds.has(p.id)
      );
    }

    if (currentStep === StepValues.ACCOUNTS) {
      filteredEntries = filteredEntries.filter(
        (e) => e.status !== "Posted" && !selectedEntryAccountIdMap[e.id]
      );
    }

    if (currentStep === StepValues.BANKPOSTING) {
      filteredPostings = filteredPostings.filter(
        (p) => !hiddenBankPostings.has(p.id)
      );
    }

    if (currentStep === StepValues.FINALPOSTING) {
      filteredEntries = filteredEntries.filter((e) => e.status !== "Posted");
    }

    return { filteredEntries, filteredPostings };
  };

  applyAllFilters = (entries, postings, skipFilterName = "") => {
    let { columnFilters } = this.state;

    let { filteredEntries, filteredPostings } = this.applyStepFilters(
      entries,
      postings
    );

    let filteredResults = this.applyColumnFilters(
      filteredEntries,
      filteredPostings,
      columnFilters,
      skipFilterName
    );
    filteredEntries = filteredResults.filteredEntries;
    filteredPostings = filteredResults.filteredPostings;

    return { filteredEntries, filteredPostings };
  };

  render() {
    let {
      bookKeepingDraftEntries,
      bankPostings,
      currentStep,
      accountingReport,
    } = this.props;

    let {
      entryMultiMatches,
      postingMultiMatches,
      // priceFilter,
      // monthFilter,
      columnFilters,
    } = this.state;

    let filteredEntries = bookKeepingDraftEntries.sort(
      (l, r) =>
        moment(l.creationDate).valueOf() - moment(r.creationDate).valueOf()
    );

    let filteredPostings = bankPostings.sort(
      (l, r) => moment(l.date).valueOf() - moment(r.date).valueOf()
    );

    // if(Object.keys(entryFilterTexts).length > 0
    //   || Object.keys(bankPostingFilterTexts).length > 0
    //   || priceFilter > PriceFilter.ALL
    //   || monthFilter > -1
    //   ) {
    //   filteredEntries = this.filterEntriesByColumn(filteredEntries);
    //   filteredPostings = this.filterPostingsByColumn(filteredPostings);
    // }

    //ToDo: Cache filtering in state
    let filteredResults = this.applyAllFilters(
      filteredEntries,
      filteredPostings,
      columnFilters
    );
    filteredEntries = filteredResults.filteredEntries;
    filteredPostings = filteredResults.filteredPostings;

    let activeBankAccountId = this.getDefaultPaymentAccountId();
    let bankResult = accountingReport[activeBankAccountId] || 0;
    let draftEntries = bookKeepingDraftEntries.filter(
      (e) => e.status !== "Posted"
    );

    let startBankResult =
      bankResult -
      draftEntries
        .filter((e) => e.balanceFinanceAccountId === activeBankAccountId)
        .reduce((acc, entry) => {
          return acc + (entry.isIncome ? entry.amount : -entry.amount);
        }, 0);

    let timeSortedBankPostings = [...bankPostings].sort((l, r) => {
      //Copy the array, to prevent mutation
      return moment(l.date).valueOf() - moment(r.date).valueOf();
    });

    let startBankBalance = 0;
    let finalBankBalance = 0;
    if (timeSortedBankPostings.length > 0) {
      startBankBalance =
        timeSortedBankPostings[0].accountBalance -
        timeSortedBankPostings[0].amount;
      finalBankBalance =
        timeSortedBankPostings[timeSortedBankPostings.length - 1]
          .accountBalance;
    }

    let bankBalanceDifference = finalBankBalance - bankResult;

    let previewDifference = bankBalanceDifference;
    if (currentStep === StepValues.BANKPOSTING) {
      let activePostings = bankPostings.filter((p) => !p.approved);
      previewDifference += activePostings.reduce((acc, posting) => {
        return acc + posting.amount;
      }, 0);
    }

    let displayedEntryMultiMatches = this.defaultObject;
    let displayedPostingMultiMatches = this.defaultObject;
    if (currentStep === StepValues.ALGORITM) {
      displayedEntryMultiMatches = entryMultiMatches;
      displayedPostingMultiMatches = postingMultiMatches;
    }

    return (
      <>
        <BankBalancingView
          {...this.props}
          {...this.state}
          stepValues={StepValues}
          bookKeepingDraftEntries={filteredEntries}
          entriesWithoutFilter={bookKeepingDraftEntries}
          bankPostings={filteredPostings}
          selectedBankAccountId={this.state.selectedBankAccountId} //Selected bank account is used for filtering
          activeBankAccountId={activeBankAccountId}
          bankResult={bankResult}
          startBankResult={startBankResult}
          startBankBalance={startBankBalance}
          finalBankBalance={finalBankBalance}
          bankBalanceDifference={bankBalanceDifference}
          previewDifference={previewDifference}
          loadBankStatement={this.loadBankStatement}
          handleSelectedClient={this.handleSelectedClient}
          onSelectFiscalYear={this.onSelectFiscalYear}
          onSelectStartDate={this.onSelectStartDate}
          onSelectEndDate={this.onSelectEndDate}
          updateDateColumnIndex={this.updateDateColumnIndex}
          updateTextColumnIndex={this.updateTextColumnIndex}
          updateAmountColumnIndex={this.updateAmountColumnIndex}
          updateAccountBalanceColumnIndex={this.updateAccountBalanceColumnIndex}
          onSelectBankAccount={this.onSelectBankAccount}
          onSelectEntry={this.onSelectEntry}
          onSelectAllEntries={this.onSelectAllEntries}
          clearSelectedEntries={this.clearSelectedEntries}
          onSelectPosting={this.onSelectPosting}
          onSelectAllPostings={this.onSelectAllPostings}
          clearSelectedPostings={this.clearSelectedPostings}
          onDeletePostings={this.onDeletePostings}
          onReconciliate={this.onReconciliate}
          onReconciliateAll={this.onReconciliateAll}
          onCancelReconciliation={this.onCancelReconciliation}
          onCancelAllReconciliations={this.onCancelAllReconciliations}
          onForward={this.onForward}
          onPrevious={this.onPrevious}
          onRestart={this.onRestart}
          onUpdateEntry={this.onUpdateEntry}
          onApproveEntries={this.onApproveEntries}
          onApproveAuto={this.onApproveAuto}
          selectMapAccount={this.selectMapAccount}
          createEntriesForBankPostings={this.createEntriesForBankPostings}
          onNextDouble={this.onNextDouble}
          sendMailToClient={this.sendMailToClient}
          onSelectEntryColumn={this.onSelectEntryColumn}
          onSelectBankPostingColumn={this.onSelectBankPostingColumn}
          updateEntryFilterText={this.updateEntryFilterText}
          updateBankPostingFilterText={this.updateBankPostingFilterText}
          reconciliateSingleMatch={this.reconciliateSingleMatch}
          cancelSingleMatch={this.cancelSingleMatch}
          entryMultiMatches={displayedEntryMultiMatches}
          postingMultiMatches={displayedPostingMultiMatches}
          deleteSelectedEntries={this.deleteSelectedEntries}
          cancelBankPostingAccountSelection={
            this.cancelBankPostingAccountSelection
          }
          selectEntryMapAccount={this.selectEntryMapAccount}
          cancelEntryAccountSelection={this.cancelEntryAccountSelection}
          deleteAllBankPostings={this.deleteAllBankPostings}
          onGotoDraft={this.onGotoDraft}
          onFinalizeEntries={this.onFinalizeEntries}
          onCancelOperation={this.onCancelOperation}
          onHideStartValueWarning={this.onHideStartValueWarning}
          onUpdatePriceFilter={this.onUpdatePriceFilter}
          onUpdateMonthFilter={this.onUpdateMonthFilter}
          editTextFilter={this.editTextFilter}
          editNumberFilter={this.editNumberFilter}
          editDateFilter={this.editDateFilter}
          editAccountFilter={this.editAccountFilter}
          removeColumnFilter={this.removeColumnFilter}
          getAmountForEntry={this.getAmountForEntry}
        />
        <TextFilterModal ref={this.textFilterModal} />
        <NumberFilterModal ref={this.numberFilterModal} />
        <DateFilterModal ref={this.dateFilterModal} />
      </>
    );
  }
}

class BankBalancingConsumer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loadedPostings: false };
  }

  componentDidMount() {
    DataStore.fetchFiscalYears().then(() => {
      DataStore.fetchBankReconEntries();
      DataStore.fetchBankPostings().then(() =>
        this.setState({ loadedPostings: true })
      );
      DataStore.fetchAccountingReportWithDraft();
      DataStore.fetchClientPlan();
    });
  }

  defaultArray = [];

  mapStoreToProps = (store) => {
    let bankAccounts = [];
    let selectedPlan = store.clientPlan;
    if (!!selectedPlan) {
      let standardAccounts = selectedPlan.accounts.filter(
        (a) => a.type === "Standard"
      );
      bankAccounts = standardAccounts.filter(
        (a) =>
          a.number >= selectedPlan.bankAccountIntervalStart &&
          a.number <= selectedPlan.bankAccountIntervalEnd
      );
    }

    let financeAccountMap = {};
    store.clientPlan.accounts.forEach((element) => {
      financeAccountMap[element.id] = element;
    });

    let taxSpecificationMap = {};
    store.clientPlan.taxSpecifications.forEach((element) => {
      taxSpecificationMap[element.id] = element;
    });

    let selectedFiscalYear = !!store.fiscalYears
      ? store.fiscalYears.find((f) => f.id === store.selectedFiscalYearId) || {}
      : {};

    let selectedStartDay = moment
      .utc(store.selectedFiscalStartDate || selectedFiscalYear.startDate)
      .startOf("day");
    let selectedEndDay = moment
      .utc(store.selectedFiscalEndDate || selectedFiscalYear.endDate)
      .startOf("day");

    let filteredEntries = store.bookKeepingDraftEntries
      .filter((e) => !e.trashed)
      .filter((e) => e.status !== "Created") //Entries must be approved or posted
      .filter((e) => {
        let entryDay = moment.utc(e.creationDate).startOf("day");
        return entryDay >= selectedStartDay && entryDay <= selectedEndDay;
      });

    let globalContactSharings = store.sharingGlobals.filter(
      (s) => s.type === 0
    ); //Clients

    let sharedWithMe =
      !!store.selectedContact.id &&
      store.userProfile.userName !== store.selectedContact.userName;
    let sharedWithOthers =
      !sharedWithMe &&
      (globalContactSharings.length > 0 ||
        (!!store.selectedContact.singleSharings &&
          !!store.selectedContact.singleSharings.find(
            (s) => s.userProfileId !== store.userProfile.id
          )));

    let sharedClient = sharedWithMe || sharedWithOthers;

    return {
      selectedContact: store.selectedContact,
      fiscalYears: store.fiscalYears,
      selectedFiscalYearId: store.selectedFiscalYearId,
      selectedStartDate: store.selectedFiscalStartDate,
      selectedEndDate: store.selectedFiscalEndDate,
      bookKeepingDraftEntries: filteredEntries,
      bankPostings: store.bankPostings,
      bankImportSettings: store.bankImportSettings,
      userProfile: store.userProfile,
      selectedPlan,
      financeAccounts: !!selectedPlan
        ? selectedPlan.accounts
        : this.defaultArray,
      financeAccountMap,
      standardReconAccountId: !!selectedPlan
        ? selectedPlan.standardReconAccountId
        : 0,
      bankAccounts,
      taxSpecificationMap,
      currentStep: store.bankProgress[store.selectedContact.id] || 0,
      createdData: store.bankBalancingData[store.selectedContact.id] || {
        createdEntryIds: [],
        createdPostingIds: [],
      },
      accountingReport: store.accountingReport,
      draftSimulationResult: store.draftSimulationResult,
      userSettings: store.userSettings,
      sharedClient,
      calculateNumbers: store.calculateNumbers,
      actions: store.actions,
      storeFunctions: store.storeFunctions,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => (
          <BankBalancingContainer
            {...this.mapStoreToProps(store)}
            {...this.props}
            {...this.state}
          />
        )}
      </Consumer>
    );
  }
}

export default withRTKData(withRouter(BankBalancingConsumer));
