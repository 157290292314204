import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import DataStore from "../../services/DataAccess/DataStore";
import ClientDocumentUploadView from "./ClientDocumentUploadView";
// import RoutingService from "../../services/RoutingService";

export default class ClientDocumentContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      userProfile: {},
      userSettings: {},
    };
    this.name = "ClientDocumentContainer";
  }

  componentDidMount() {
    //RoutingService.setRootPath("/ClientDashboard");

    DataStore.subscribe(this.name, (store) => {
      this.setState({
        userProfile: store.userProfile,
        userSettings: store.userSettings,
        documents: store.documentMap.documents,
      });
    });
    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.initializeDocuments();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  onToggleCards = () => {
    let userSettings = { ...this.state.userSettings };
    userSettings.showDocumentCards = !userSettings.showDocumentCards;
    DataActions.updateUserSettings(userSettings);
  };

  render() {
    return (
      <div>
        <ClientDocumentUploadView
          documents={this.state.documents}
          userProfile={this.state.userProfile}
          userSettings={this.state.userSettings}
          forceShowCards={this.state.userSettings.showDocumentCards}
          onToggleCards={this.onToggleCards}
        />
      </div>
    );
  }
}
