import React, { useState } from "react";
import moment from "moment";

import {
  AsyncButton,
  FlexElement,
  Icon,
  DownloadButton,
  UploadButton,
} from "../../utilities/LexUtilities";

import {
  ApiService,
  // DataActions,
} from "../../services/AxoServices";

import { FormGroup, FormControl, ButtonToolbar } from "react-bootstrap";

// import CasesView from '../../clientDashboard/Cases/CasesView'

let accessCode, setAccessCode;
let currentCase, setCurrentCase;
let showError, setShowError;

const Document = React.memo(({ doc, date }) => {
  let className =
    "flexbox-standard CaseVersionListItem leftPadding rightPadding";
  className += " Caselistdoc";
  return (
    <div>
      <div>
        <div className="text-center">{moment(date).format("LLL")}</div>
      </div>
      <div key={"document-" + doc.id} className={className}>
        <FlexElement>
          <div>
            {!!doc.fileName && (
              <>
                &nbsp;{doc.fileName}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <Icon glyph="icon-fontello-attach-outline" />
              </>
            )}
          </div>
        </FlexElement>
      </div>
    </div>
  );
});

const Version = React.memo(({ version, date }) => {
  let className =
    "flexbox-standard CaseVersionListItem leftPadding rightPadding";

  return (
    <div key={version.id}>
      <div className="text-center">{moment(date).format("LLL")}</div>
      <div className={className}>
        <FlexElement>
          <div>
            <strong>{!!version.title && <>{version.title}</>}</strong>
          </div>
        </FlexElement>
      </div>
      {!!version.description && (
        <div>
          <div
            className="leftPadding"
            dangerouslySetInnerHTML={{ __html: version.description }}
          />
        </div>
      )}
    </div>
  );
});

export default function CaseLinkContainer(props) {
  [accessCode, setAccessCode] = useState("");
  [currentCase, setCurrentCase] = useState("");
  [showError, setShowError] = useState(false);

  let { guid } = props.match.params;

  let fetchCase = async () => {
    let response = await ApiService.getCaseModelFromLink(guid, accessCode);
    if (!response.ok) {
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
      return false;
    }
    let caseModel = await response.json();
    setCurrentCase(caseModel);
    return true;
  };

  let uploadDocuments = async (documents) => {
    let uploadResponse = await ApiService.uploadDocuments(documents, {
      targetUserName: currentCase.userName,
      caseNumber: currentCase.caseNumber,
    });
    if (!uploadResponse.ok) {
      return false;
    }

    fetchCase();
    return uploadResponse.ok;
  };

  const Banner = () => (
    <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center">
      {!!currentCase ? <>{currentCase.caseNumber}</> : <>Indtast kode</>}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        alt=""
        className="hidden-lg hidden-sm hidden-md"
        src="/imgs/app/Axologo3-30x30.png"
      />
      <img
        alt=""
        className="hidden-lg hidden-md hidden-xs"
        src="/imgs/app/Axologo3-35x35.png"
      />
      <img
        alt=""
        className="hidden-lg hidden-sm hidden-xs"
        src="/imgs/app/Axologo3-40x40.png"
      />
      <img
        alt=""
        className="hidden-md hidden-sm hidden-xs"
        src="/imgs/app/Axologo3-40x40.png"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
  );

  if (showError) {
    return (
      <>
        <Banner />
        <div className="leftPadding topPadding">
          <h3>Sagen kunne ikke findes</h3>
        </div>
      </>
    );
  }

  if (!currentCase) {
    return (
      <>
        <Banner />
        <div className="standardMaxWidth center-block topPadding">
          <FormGroup controlId="accessCode">
            <FormControl
              name="fiscalYearStart" //Name must correspond to the name of the property
              type="text"
              className="borderFormControlfocus"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
            />
          </FormGroup>
          <AsyncButton onClick={fetchCase}>Hent sag</AsyncButton>
        </div>
      </>
    );
  }

  let items = currentCase.caseVersions.map((v) => ({
    date: v.date,
    renderItem: (date) => <Version key={v.id} version={v} date={date} />,
  }));

  items = items.concat(
    currentCase.documents.map((d) => ({
      date: moment.utc(d.assignmentDate).isAfter(moment.utc(0))
        ? d.assignmentDate
        : d.uploadDate,
      renderItem: (date) => (
        <Document key={"document-" + d.id} doc={d} date={date} />
      ),
    }))
  );

  return (
    <>
      <Banner />
      <div className="standardMaxWidth center-block">
        <div>{items.map((i) => i.renderItem())}</div>
        <div className="topPadding">
          <ButtonToolbar>
            <DownloadButton
              title="Download alle bilag som Zip fil"
              href={ApiService.getCaseLinkDocumentsDownloadPath(
                guid,
                accessCode
              )}
              disabled={currentCase.documents.length < 1}
            >
              <Icon glyph="icon-fontello-download" />
              &nbsp; Download dokumenter
            </DownloadButton>
            <UploadButton allowMultiple onSelected={uploadDocuments}>
              <Icon glyph="icon-fontello-upload-4" />
              &nbsp; Upload dokumenter
            </UploadButton>
          </ButtonToolbar>
        </div>
      </div>
    </>
  );
}
