import React from "react";
import QueryString from "query-string";
import CarraLogo from "../resources/images/CarraLogo.png";

import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import {
  RoutingService,
  ApiService,
  DataActions,
  // DataStore,
  LocalizationService,
  // StripeAuthenticationService,
  // VatService,
  // ApiKeys
} from "../services/AxoServices";

import {
  AxoCheckbox,
  Icon,
  LoadingIcon,
  AxoLocal,
  getText,
  ctx,
  // Link,
  withRouter,
  LoadingIndicator,
  // FlagMenu
} from "../utilities/LexUtilities";

class CarraLogin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginViewModel: {
        userName: "",
        password: "",
        rememberMe: false,
        cacheLogin: true,
      },
      showAccountError: false,
      showTwoFactorCodeError: false,
      userRole: "",
      checkTwoFactor: false,
      awaitTwoFactorCode: false,
      twoFactorProvider: "",
      twoFactorCode: "",
      rememberTwoFactor: false,
      attemptingLogin: false,
      showServerError: false,
      showConfirmMessage: false,
      verifyingTwoFactorCode: false,
      sendingCode: "",
      subscriptionErrorType: "",
    };
    this.userProfile = {};
    this.role = "";
    this.timeOut = null;
    this.tokenSubmitted = false;
  }

  async componentDidMount() {
    let query = QueryString.parse(this.props.location.search);
    let { userCode } = query;

    if (!userCode) {
      return;
    }

    let response = await ApiService.loginAuto(userCode);
    if (!response.ok) {
      this.redirectToOrigin();
    }

    let body = await response.json();
    if (body.result !== "Success") {
      this.redirectToOrigin();
    }

    this.redirectByRole(body.role);
  }

  redirectToOrigin = () => {
    window.top.location.href = "https://carra.dk";
  };

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale("Login");
    if (!!this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  getPath = (path) => {
    return RoutingService.getPath(path);
  };

  onModelPropertyChange = (propertyName, event) => {
    this.setState({
      loginViewModel: {
        ...this.state.loginViewModel,
        [propertyName]: event.target.value,
      },
    });
  };

  onLogin = () => {
    var model = Object.assign({}, this.state.loginViewModel);
    if (!model.userName || !model.password) {
      return;
    }
    this.setState({ attemptingLogin: true });

    model.registerEmailSubject = ctx.getSync("SignupMailSubject");
    model.registerEmailMessage = ctx.getSync("SignupMailMessage");
    model.messageUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      RoutingService.getAccountPath("ConfirmEmail");

    DataActions.login(model)
      .then((response) => {
        this.setState({ attemptingLogin: false });
        if (response.ok) {
          this.setState({ showAccountError: false });
          response.json().then((body) => {
            if (body.result === "Success") {
              this.handleSuccess(body);
            } else if (body.result === "TwoFactor") {
              this.setState({
                checkTwoFactor: true,
                twoFactorOptions: body.options,
                userRole: body.role,
              });
            } else if (body.result === "RequireConfirmed") {
              this.setState({
                showConfirmMessage: true,
              });
            }
          });
        } else {
          this.setState({ showAccountError: true });
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ showServerError: true });
      });
  };

  handleSuccess = (result) => {
    this.setState({ verifyingTwoFactorCode: false });
    // this.redirectByRole(role);
    this.redirectToAxolex(result);
  };

  redirectToAxolex = (result) => {
    // let { loginViewModel } = this.state;

    window.top.location.href =
      window.location.protocol +
      "//" +
      window.location.host +
      "/Carra?userCode=" +
      result.userCode;
  };

  redirectToForgotPassword = () => {
    window.top.location.href =
      window.location.protocol +
      "//" +
      window.location.host +
      "/Users/ForgotPassword";
  };

  onRemember = (event) => {
    var model = Object.assign({}, this.state.loginViewModel);
    model.rememberMe = event.target.checked;
    this.setState({ loginViewModel: model });
  };

  onRememberTwoFactor = (event) => {
    this.setState({ rememberTwoFactor: event.target.checked });
  };

  onChangeCode = (event) => {
    this.setState({ twoFactorCode: event.target.value });
  };

  sendCode = (option) => {
    this.setState({
      twoFactorProvider: option,
      sendingCode: option,
    });
    ApiService.sendTwoFactor({
      selectedProvider: option,
      providers: [],
      returnUrl: "",
      rememberMe: this.state.loginViewModel.rememberMe,
      messageSubject: getText("loginTwoFactorSubject", "Nem kode"),
      messageText: getText("loginTwoFactorMessage", "Din kode er:"),
    })
      .then((response) => {
        this.setState({ sendingCode: "" });
        if (response.ok) {
          this.setState({
            checkTwoFactor: false,
            awaitTwoFactorCode: true,
          });
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ sendingCode: "" });
      });
  };

  onVerifyCode = () => {
    this.setState({ verifyingTwoFactorCode: true });
    ApiService.verifyCode({
      provider: this.state.twoFactorProvider,
      code: this.state.twoFactorCode,
      rememberBrowser: this.state.rememberTwoFactor,
      rememberMe: this.state.rememberMe,
    }).then((response) => {
      if (response.ok) {
        this.handleSuccess(this.state.userRole);
      } else {
        this.setState({
          showTwoFactorCodeError: true,
          verifyingTwoFactorCode: false,
        });
      }
    });
  };

  redirectByRole = (role) => {
    var { history, redirect } = this.props;

    if (redirect) {
      history.push(this.props.redirect);
    } else if (role === "Lawyer" || role === "Accountant" || role === "Admin") {
      history.push("/Dashboard");
    } else if (
      role === "Client" ||
      role === "AccountingClient" ||
      role === "SocietyMember" ||
      role === "EasyID"
    ) {
      history.push("/ClientDashboard");
    } else if (role === "XLink") {
      history.push("/Xlink");
    } else if (role === "Society") {
      history.push("/Dashboard");
    }
  };

  renderFooter = () => {
    let {
      checkTwoFactor,
      twoFactorOptions,
      sendingCode,
      awaitTwoFactorCode,
      showTwoFactorCodeError,
      subscriptionErrorType,
    } = this.state;
    if (checkTwoFactor && twoFactorOptions.length > 0) {
      let emailOption = twoFactorOptions.includes("Email");
      let phoneOption = twoFactorOptions.includes("Phone");
      return (
        <div>
          <FormGroup>
            <Grid fluid>
              <Row>
                <AxoLocal
                  entity="LogintwoFactorOptions"
                  defaultValue={
                    "To-faktor autentificering er slået til. Vælg hvordan koden skal sendes."
                  }
                />
              </Row>
              <Row>
                <Col
                  sm={2}
                  className="nopadding"
                  style={{ paddingTop: 10 }}
                ></Col>
                <Col sm={4} className="nopadding" style={{ paddingTop: 10 }}>
                  {emailOption ? (
                    <Button
                      disabled={!!sendingCode}
                      className="Lex-button-2 center"
                      style={{
                        height: "65px",
                        width: "250px",
                        fontSize: "17px",
                        fontWeight: "normal",
                      }}
                      onClick={this.sendCode.bind(this, "Email")}
                    >
                      <AxoLocal
                        entity="LoginsendCodeEmail"
                        defaultValue={"Send email"}
                      />
                      <LoadingIcon show={sendingCode === "Email"} />
                    </Button>
                  ) : null}
                </Col>
                <Col sm={4} className="nopadding" style={{ paddingTop: 10 }}>
                  {phoneOption ? (
                    <Button
                      disabled={!!sendingCode}
                      className="Lex-button-2 center"
                      style={{
                        height: "65px",
                        width: "250px",
                        fontSize: "17px",
                        fontWeight: "normal",
                      }}
                      onClick={this.sendCode.bind(this, "Phone")}
                    >
                      <AxoLocal
                        entity="LoginsendCodePhone"
                        defaultValue={"Send sms"}
                      />
                      <LoadingIcon show={sendingCode === "Phone"} />
                    </Button>
                  ) : null}
                </Col>
                <Col
                  sm={2}
                  className="nopadding"
                  style={{ paddingTop: 10 }}
                ></Col>
              </Row>
            </Grid>
          </FormGroup>
        </div>
      );
    }
    if (awaitTwoFactorCode) {
      return (
        <span>
          <FormGroup>
            {showTwoFactorCodeError ? (
              <div className="axored">
                <AxoLocal
                  entity="LoginshowTwoFactorCodeError"
                  defaultValue={"Koden kunne ikke verificeres"}
                />
              </div>
            ) : null}
            <InputGroup bsSize="large">
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-lock-5" />
              </InputGroup.Addon>
              <AxoLocal
                componentClass={FormControl}
                autoFocus
                type="text"
                className="borderFormControlfocus"
                value={this.state.twoFactorCode}
                onChange={this.onChangeCode}
                componentAttribute="placeholder"
                entity="LoginEntercodehere"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <AxoCheckbox
              checked={this.state.rememberTwoFactor}
              onChange={this.onRememberTwoFactor}
            />
            <AxoLocal
              entity="LoginonRememberTwoFactor"
              defaultValue={"Husk denne browser"}
            />
          </FormGroup>
          <FormGroup>
            <Grid fluid>
              <Row>
                <Col
                  xs={6}
                  className="nopadding"
                  style={{ paddingTop: 10 }}
                ></Col>
                <Col xs={6} className="text-center nopadding">
                  <Button
                    className="Lex-button-2"
                    style={{
                      height: "65px",
                      width: "250px",
                      fontSize: "17px",
                      fontWeight: "normal",
                    }}
                    disabled={
                      this.state.twoFactorCode.length === 0 ||
                      this.state.verifyingTwoFactorCode
                    }
                    onClick={this.onVerifyCode}
                  >
                    {this.state.verifyingTwoFactorCode ? (
                      <span>
                        <img
                          alt=""
                          src="/imgs/app/loading.gif"
                          className="img-circle"
                          width="20"
                          height="20"
                        />
                        &nbsp;
                      </span>
                    ) : null}
                    <AxoLocal
                      entity="LoginonVerifyCode"
                      defaultValue={"Indsend koden"}
                    />
                  </Button>
                </Col>
              </Row>
            </Grid>
          </FormGroup>
        </span>
      );
    }
    if (!!subscriptionErrorType) {
      if (subscriptionErrorType.includes("CountryMismatch")) {
        return (
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <div className="axored">
                  <AxoLocal
                    entity="axoAccounting61"
                    defaultValue={
                      "Dit kreditkort skal være udstedt i samme land som du valgte da du udfyldte din brugerprofil."
                    }
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        );
      }
      if (subscriptionErrorType.includes("MissingVatNumber")) {
        return (
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <div className="axored">
                  <AxoLocal
                    entity="axoAccounting65"
                    defaultValue={"CVR nummeret kunne ikke valideres."}
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        );
      }
      return (
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <div className="axored">
                <AxoLocal
                  entity="axoAccounting66"
                  defaultValue={
                    "Der skete en fejl under aktiveringen af dit abonnement. Prøv igen senere."
                  }
                />
              </div>
            </Col>
          </Row>
        </Grid>
      );
    }
    return (
      <Grid fluid>
        <Row>
          <Col sm={6} className="nopadding"></Col>
          <Col sm={6} className="text-center nopadding">
            <Button
              className="Lex-button-2"
              style={{
                height: "65px",
                width: "250px",
                fontSize: "17px",
                fontWeight: "normal",
              }}
              disabled={this.state.attemptingLogin}
              onClick={this.onLogin}
            >
              <span>
                {this.state.attemptingLogin ? (
                  <img
                    alt=""
                    src="/imgs/app/loading.gif"
                    className="img-circle"
                    width="20"
                    height="20"
                  />
                ) : (
                  <Icon glyph="icon-fontello-login" />
                )}
                &nbsp;&nbsp;
                <AxoLocal entity="LoginonLogin" defaultValue={"Log ind"} />
              </span>
            </Button>
          </Col>
        </Row>
      </Grid>
    );
  };

  render() {
    let query = QueryString.parse(this.props.location.search);
    let { user } = query;
    if (!!user) {
      return <LoadingIndicator />;
    }

    var model = this.state.loginViewModel;
    return (
      <div
        className="login SideHeight"
        style={{ paddingTop: "10px", background: "#f9f9f9" }}
      >
        {/* <Grid fluid className='nopadding'>
          <Row>
            <Col xs={12} style={{maxWidth: '100%'}}>
              <div className="frontPageaxolexdiv Bannecolor flexbox-center" >
                <AxoLocal entity='LoginonLogin'defaultValue={'Log ind'}/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <img alt=''  className='hidden-lg hidden-sm hidden-md' src='/imgs/app/Axologo3-30x30.png'/>   
                      <img alt=''  className='hidden-lg hidden-md hidden-xs' src='/imgs/app/Axologo3-35x35.png'/>  
                      <img alt=''  className='hidden-lg hidden-sm hidden-xs' src='/imgs/app/Axologo3-40x40.png'/> 
                      <img alt=''  className='hidden-md hidden-sm hidden-xs' src='/imgs/app/Axologo3-40x40.png'/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FlagMenu
                    // className='Lex-button-2'
                    userSettings={{}}
                  />
              </div>
            </Col>
          </Row>
        </Grid> */}
        <div style={{ maxWidth: "990px", margin: "auto" }}>
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <div>
                  <div>
                    <div style={{ padding: 0 }}>
                      <div
                        className="text-center axoblue"
                        style={{ borderRadius: "0px" }}
                      ></div>
                      <div className="text-center" style={{ padding: 12.5 }}>
                        {/* <div style={{marginTop: 12.5, marginBottom: 12.5}}>
                          <Button id='facebook-btn' lg bsStyle='darkblue' type='submit' onClick={this.back}>
                            <Icon glyph='icon-fontello-facebook' />
                            <span>Sign in <span className='hidden-xs'>with facebook</span></span>
                          </Button>
                        </div> */}
                        {/* <div>
                          <a id='twitter-link' href='#' onClick={this.back}><Icon glyph='icon-fontello-twitter' /><span> or with twitter</span></a>
                        </div> */}
                      </div>
                      <div>
                        {/* <div className='text-center' style={{padding: 12.5}}>
                          <AxoLocal entity='LoginUseAxolexlog'defaultValue={'Anvend dit Axolex login'}/>
                          
                        </div> */}
                        <div
                          style={{
                            padding: 25,
                            paddingTop: 0,
                            paddingBottom: 0,
                            margin: "auto",
                            marginBottom: 25,
                            marginTop: 25,
                          }}
                        >
                          <Form>
                            <FormGroup controlId="emailaddress">
                              {this.state.showAccountError ? (
                                <div className="axored">
                                  <Icon glyph="icon-fontello-info-3" />
                                  &nbsp;&nbsp;
                                  <AxoLocal
                                    entity="LoginshowAccountError"
                                    defaultValue={
                                      "Forkert brugernavn eller password"
                                    }
                                  />
                                </div>
                              ) : null}
                              {this.state.showServerError ? (
                                <div className="axored">
                                  <Icon glyph="icon-fontello-info-3" />
                                  &nbsp;&nbsp;
                                  <AxoLocal
                                    entity="LoginshowServerError"
                                    defaultValue={
                                      "Der skete en fejl. Prøv igen senere."
                                    }
                                  />
                                </div>
                              ) : null}
                              {this.state.showConfirmMessage ? (
                                <div className="axored">
                                  <Icon glyph="icon-fontello-info-3" />
                                  &nbsp;&nbsp;
                                  <div>
                                    <AxoLocal
                                      entity="LoginshowAccountError1a"
                                      defaultValue={
                                        "Din email skal bekræftes før du kan logge ind."
                                      }
                                    />
                                  </div>
                                  <div>
                                    {" "}
                                    <AxoLocal
                                      entity="LoginshowAccountError2a"
                                      defaultValue={
                                        "Et link til bekræftelse er blevet sendt til din email."
                                      }
                                    />
                                  </div>
                                </div>
                              ) : null}
                              <div className="leftPadding">
                                <img alt="" src={CarraLogo} height="75" />
                                &nbsp;&nbsp;
                                <span style={{ color: "grey" }}>Log ind</span>
                              </div>
                              <InputGroup bsSize="large">
                                <InputGroup.Addon>
                                  {/* <span className='axolexunicode'>&#9993;</span> 
                                &nbsp;/&nbsp;
                                <span className='axolexunicode'> &#x260f; </span> &nbsp;/&nbsp; */}
                                  <span>
                                    <Icon glyph="icon-simple-line-icons-users" />
                                  </span>
                                </InputGroup.Addon>
                                <AxoLocal
                                  componentClass={FormControl}
                                  autoFocus
                                  type="text"
                                  className="borderFormControlfocus"
                                  value={model.userName}
                                  onChange={this.onModelPropertyChange.bind(
                                    this,
                                    "userName"
                                  )}
                                  componentAttribute="placeholder"
                                  entity="bccMailplaceholderBrugernavn"
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup controlId="password">
                              <InputGroup bsSize="large">
                                <InputGroup.Addon>
                                  <Icon glyph="icon-fontello-key" />
                                </InputGroup.Addon>
                                <AxoLocal
                                  componentClass={FormControl}
                                  type="password"
                                  className="borderFormControlfocus"
                                  value={model.password}
                                  onChange={this.onModelPropertyChange.bind(
                                    this,
                                    "password"
                                  )}
                                  onKeyUp={(event) => {
                                    //Log in when pressing return button
                                    if (event.keyCode === 13) {
                                      this.onLogin();
                                    }
                                  }}
                                  componentAttribute="placeholder"
                                  entity="bccMailplaceholderpassword"
                                />
                              </InputGroup>
                              <br />
                              {/* <Link to={RoutingService.getAccountPath('Signup')}>
                                <AxoLocal entity='LoginsSignup'defaultValue={'Opret ny konto'}/>  
                              </Link>&nbsp;&nbsp;&nbsp;&nbsp; */}
                              <span
                                style={{
                                  color: "grey",
                                  textDecoration: "underline",
                                }}
                                role="button"
                                onClick={this.redirectToForgotPassword}
                              >
                                <AxoLocal
                                  entity="LoginForgotUsersPassword"
                                  defaultValue={"Glemt dit password?"}
                                />
                              </span>
                            </FormGroup>
                            <FormGroup controlId="password">
                              <AxoCheckbox
                                checked={model.rememberMe}
                                onChange={this.onRemember}
                              />
                              <span style={{ color: "grey" }}>
                                <AxoLocal
                                  entity="LoginrememberMe"
                                  defaultValue={"Husk mig på denne computer"}
                                />
                              </span>
                            </FormGroup>
                            {this.renderFooter()}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(CarraLogin);
