import UserInfoService from "./UserInfoService";
import RoutingService from "./RoutingService";
import { ctx, getText } from "../utilities/L20n/L20n";
import moment from "moment";
import linkifyHtml from "linkify-html";

let imageLinkRoute = RoutingService.getHostPath() + "/api/ImageLink?url=";

const  linkifyOptions = { defaultProtocol: 'https' };

const convertLinkTextIntoAnchors = (messageText) => {
  // Linkify sometimes crashes on plain text emails with error "this.token.attributes is undefined"
  if (
    !messageText.toLowerCase().includes("<html") &&
    !messageText.toLowerCase().includes("<a")
  ) {
    //Only linkify plain text
    try {
      let linkified = linkifyHtml(messageText, linkifyOptions);
      messageText = linkified || messageText;
    }
    catch(error) {
      console.log(error);
    }

  }
  return messageText;
};

const addBreaksToRawText = (messageText) => {
  if (!hasHtmlLines(messageText)) {
    messageText = messageText.replace(/(?:\r\n|\r|\n)/g, "<br />");
  }
  return messageText;
};

//If we use html elements for line breaks, adding breaks is not necessary.
const hasHtmlLines = (text) => {
  return (
    text.includes("<html") ||
    text.includes("<div") ||
    text.includes("<p") ||
    text.includes("<span") ||
    text.includes("<table")
  );
};

//Ensures that email links are opened in a new tab
const addLinkTargetToHTML = (messageText) => {
  if (messageText.indexOf("<head>") > -1) {
    return messageText.replace("<head>", '<head><base target="_blank">');
  }
  return (
    '<html><head><base target="_blank"></head><body>' +
    messageText +
    "</body></html>"
  );
};

const addAltAttributeToImages = (messageText) => {
  return messageText.replace(/<img/g, '<img alt=""');
};

const secureImageLinks = (messageText) => {
  let newText = messageText.replace(
    /src="http:/g,
    'src="' + imageLinkRoute + "http:"
  );
  newText = newText.replace(/src='http:/g, "src='" + imageLinkRoute + "http:");
  return newText;
};

const setInlineStyles = (messageText) => {
  let newText = messageText.replace("<head>", "<head><style>img { max-width: 750px !important }</style>");
  return newText;
}

// const convertMailLinks = (text) => {
//   let newText = text.replace(/href="mailto:/g, ' target="_top" href="..\\..\\sendExternal\\');
//   newText = newText.replace(/href='mailto:/g, " target='_top' href='..\\..\\sendExternal\\");
//   return newText;
// }

export default {
  makeDefaultMessage() {
    return {
      sender: null,
      receiver: null,
      externalMailReceiver: "",
      subject: "",
      messageText: "",
      draft: true,
      attachments: [],
      bccUsers: [],
    };
  },

  makeReplyMessage({ message, replyAll }) {
    var replyMessage = this.makeDefaultMessage();
    replyMessage.replyToId = message.id; //Used to set the reply flag on the original message.
    replyMessage.externalAccountId = message.externalAccountId;
    replyMessage.replyToUniqueId = message.externalUniqueId;
    var replyHeader = "";
    if (message.externalUniqueId) {
      replyMessage.externalMailReceiver = !!message.externalSender
        ? message.externalSender.trim()
        : "";
      replyMessage.externalReceiverDisplayName =
        message.externalSenderDisplayName;

      if (replyAll) {
        replyMessage.externalMailCCReceiver = !!message.externalMailReceiver
          ? message.externalMailReceiver.replace(/ /g, "")
          : "";
        if (!!message.externalMailCCReceiver) {
          replyMessage.externalMailCCReceiver += `;${message.externalMailCCReceiver.replace(
            / /g,
            ""
          )}`;
        }
      }
      replyHeader =
        '<p>&nbsp;</p><p>-----"' +
        message.externalSenderDisplayName +
        '" ' +
        message.externalSender +
        " " +
        moment(message.date).format("L LT") +
        "-----</p>";
    } else {
      replyMessage.receiver = message.sender;
      replyHeader =
        "<p>&nbsp;</p><p>-----" +
        UserInfoService.getDisplayName(replyMessage.sender) +
        " " +
        moment(message.date).format("L LT") +
        "-----</p>";
    }
    replyMessage.subject = "Re: " + message.subject;

    //Insert header into message body
    var bodyPos = message.messageText.indexOf("<body");
    if (bodyPos > -1) {
      var insertPos = message.messageText.indexOf(">", bodyPos) + 1;
      replyMessage.messageText =
        message.messageText.substr(0, insertPos) +
        replyHeader +
        message.messageText.substr(insertPos);
    } else {
      replyMessage.messageText = replyHeader + message.messageText;
    }

    return replyMessage;
  },

  makeForwardMessage(message) {
    let forwardMessage = this.makeDefaultMessage();
    forwardMessage.subject =
      ctx.getSync("DocumentinvoiceFileattachmentparamsforwardId") +
      " " +
      message.subject;

    let senderName = message.sender
      ? UserInfoService.getDisplayName(message.sender)
      : '"' + message.externalSenderDisplayName + '" ' + message.externalSender;
    // let receiverName = message.receiver ? UserInfoService.getDisplayName(message.receiver) : message.external;
    let receiverName = message.externalMailReceiver
      ? message.externalMailReceiver
      : UserInfoService.getDisplayName(message.receiver);

    let forwardHeader = `<p>&nbsp;</p><p>-----${ctx.getSync(
      "DocumentinvoiceFileattachmentparamsforwardmessageText"
    )}
    ------</p><p>${ctx.getSync(
      "MailContainerComposeFrom"
    )}: ${senderName}</p><p>
    ${getText("axoidcode25", "Dato")}: ${moment(message.date).format(
      "LLL"
    )}</p><p>
    ${ctx.getSync("MailContainerComposeTo")}: ${receiverName}</p><p>
    ${ctx.getSync("CaseEditFormcasesubject")}: ${message.subject}</p>`;

    //Insert header into message body
    let bodyPos = message.messageText.indexOf("<body");
    if (bodyPos > -1) {
      let insertPos = message.messageText.indexOf(">", bodyPos) + 1;
      forwardMessage.messageText =
        message.messageText.substr(0, insertPos) +
        forwardHeader +
        message.messageText.substr(insertPos);
    } else {
      forwardMessage.messageText = forwardHeader + message.messageText;
    }

    forwardMessage.attachments = message.attachments;

    return forwardMessage;
  },

  makeAttachmentMessage(attachmentList) {
    var message = this.makeDefaultMessage();

    attachmentList.forEach((attachment) => {
      message.attachments.push(attachment);
    });

    return message;
  },

  makeInvoiceMessage(invoicePDF) {
    var invoice = invoicePDF.invoice;
    var message = this.makeDefaultMessage();
    message.attachments.push(invoicePDF);
    message.subject = invoice.invoiceType + invoice.number.toString();
    if (invoice && invoice.client) {
      message.receiver = invoice.client.clientUserName;
      message.externalMailReceiver = invoice.client.eMail;
    }
    return message;
  },

  makeExternalMessage(email) {
    var message = this.makeDefaultMessage();
    message.externalMailReceiver = email;
    return message;
  },

  //Adds links and breaks and secures image links behind link proxy
  processMessageText(messageText) {
    if (!messageText) {
      return " "; //Return string to prevent recursion in MailContainer
    }

    messageText = convertLinkTextIntoAnchors(messageText);
    messageText = addBreaksToRawText(messageText);

    messageText = addLinkTargetToHTML(messageText);
    messageText = addAltAttributeToImages(messageText);
    messageText = secureImageLinks(messageText);
    messageText = setInlineStyles(messageText)
    // messageText = convertMailLinks(messageText);

    return messageText;
  },

  removeExternalImages(messageText) {
    if (!messageText) {
      return messageText;
    }

    // messageText = messageText.replace(/img[^>]*>/g,""); //Remove all images
    messageText = messageText.replace(/src="http/g, ""); //Remove all external images
    messageText = messageText.replace(/src='http/g, "");
    return messageText;
  },

  secureImageSource(src) {
    if (!src) {
      return src;
    }

    return src.replace("http:", imageLinkRoute + "http:");
  },
};
