import React from "react";
import MediaCapturer from "./MediaCapture";
import ApiService from "../services/DataAccess/ApiService";
import DataStore from "../services/DataAccess/DataStore";
import Oscilloscope from "../utilities/Oscilloscope";
import Counter from "./Counter";

import { Grid, Row, Col, Button, ButtonToolbar } from "react-bootstrap";
import { Icon, AxoLocal } from "../utilities/LexUtilities";

var isChrome = false;
if (typeof window !== "undefined") {
  navigator.getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;

  isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}

class AudioRecorder extends React.PureComponent {
  //onFileRecorded
  constructor(props) {
    super(props);
    this.state = {
      granted: false,
      rejectedReason: "",
      recording: false,
      paused: false,
      audioSource: "",
      file: null,
      counterSeconds: 0,
      mikeEnabled: true,
      showSaveButton: false,
    };

    this.handleGranted = this.handleGranted.bind(this);
    this.handleDenied = this.handleDenied.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleResume = this.handleResume.bind(this);
    this.saveAudio = this.saveAudio.bind(this);

    this.scope = null;
    this.backgroundStream = "";
  }
  handleGranted() {
    this.setState({ granted: true });
  }
  handleDenied(err) {
    this.setState({ rejectedReason: err.name });
  }
  handleStart(stream) {
    this.setState({
      recording: true,
    });
    this.startCounter();
  }

  startCounter = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.setState({
        counterSeconds: this.state.counterSeconds + 1,
      });
    }, 1000);
    this.setState({ running: true });
  };

  handleStop(blob) {
    this.setState({
      recording: false,
    });

    this.saveAudio(blob);

    this.stopCounter();
    this.resetCounter();
    // this.scope.resetSignal();
  }

  resetCounter = () => {
    this.setState({ counterSeconds: 0 });
  };

  stopCounter = () => {
    clearInterval(this.timer);
    this.setState({ counterRunning: false });
  };

  handlePause() {
    this.setState({
      paused: true,
    });
    this.stopCounter();
  }

  handleResume(stream) {
    this.setState({
      paused: false,
    });
    this.startCounter();
  }

  handleError(err) {
    console.log(err);
  }

  saveAudio(blob) {
    var file = new File([blob], "NewAudio.mp3", { type: "audio/mpeg" });
    let url = URL.createObjectURL(blob);
    this.setState(
      {
        audioSource: url,
        file: file,
      },
      () => {
        this.onSaveFile();
      }
    );
  }

  componentDidMount() {
    if (!isChrome) {
      return;
    }

    // setup canvas
    var canvas = document.querySelector(".visualizer");
    // canvas.width = window.innerWidth
    // canvas.height = window.innerHeight

    var options = {
      stroke: 7, // size of the wave
      glow: 0.3, // glowing effect
      buffer: 1024, // buffer size ranging from 32 to 2048
    };

    // attach oscilloscope
    this.scope = new Oscilloscope(canvas, options);

    //get user microphone
    var constraints = { video: false, audio: true };

    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.handleStream)
        .catch(this.handleStreamError);
    } else if (navigator.getUserMedia) {
      navigator.getUserMedia(
        constraints,
        this.handleStream,
        this.handleStreamError
      );
    }
  }

  handleStream = (stream) => {
    this.backgroundStream = stream;

    this.context = new (window.AudioContext || window.webkitAudioContext)();

    var source = this.context.createMediaStreamSource(stream);

    this.scope.addSignal(source, "#4CAF50");
    this.setState({ mikeEnabled: true });
  };

  componentWillUnmount() {
    this.stopCounter();
    if (this.scope) {
      this.scope.stop();
    }
    if (this.backgroundStream) {
      this.backgroundStream.stop();
      this.backgroundStream = null;
    }
    if (this.context && this.context.close) {
      this.context.close();
    }
  }

  handleStreamError = (error) => {
    this.setState({ mikeEnabled: false });
  };

  onSaveFile = () => {
    if (!this.state.file) {
      return;
    }

    ApiService.uploadFileObjects([this.state.file])
      .then((response) => {
        if (response.status === 200) {
          response.json().then((newFiles) => {
            DataStore.fetchDocuments();
            this.props.onFileRecorded(newFiles[0]);
          });
          this.setState({ showSaveButton: false });
        } else {
          this.setState({ showSaveButton: true });
        }
      })
      .catch((error) => {
        this.setState({ showSaveButton: true });
      });
  };

  render() {
    const recording = this.state.recording;
    const paused = this.state.paused;

    if (
      !isChrome ||
      (!navigator.mediaDevices && !navigator.getUserMedia) ||
      !window.MediaRecorder
    ) {
      return (
        <div style={{ paddingLeft: "10px" }}>
          <h4>
            {" "}
            <AxoLocal
              entity="AudioRecordernavigator"
              defaultValue={"Din browser understøtter ikke lydoptagelse."}
            />{" "}
          </h4>
          <h4>
            {" "}
            <AxoLocal
              entity="AudioRecordergetUserMedia"
              defaultValue={
                "Skift til Google Chrome hvis du ønsker at anvende denne funktionalitet."
              }
            />
          </h4>
        </div>
      );
    }
    if (!this.state.mikeEnabled) {
      return (
        <div style={{ paddingLeft: "10px" }}>
          <h4>
            {" "}
            <AxoLocal
              entity="AudioRecordermikeEnabled"
              defaultValue={"Mikrofonen kunne ikke findes."}
            />
          </h4>
          <h4>
            {" "}
            <AxoLocal
              entity="AudioRecordermicrophone"
              defaultValue={"Tilslut en mikrofon og genindlæs siden."}
            />{" "}
          </h4>
        </div>
      );
    }
    return (
      <div ref="app">
        <MediaCapturer
          constraints={{ audio: true }}
          mimeType="audio/webm"
          timeSlice={10}
          onGranted={this.handleGranted}
          onDenied={this.handleDenied}
          onStart={this.handleStart}
          onStop={this.handleStop}
          onPause={this.handlePause}
          onResume={this.handleResume}
          onError={this.handleError}
          render={({ start, stop, pause, resume }) => (
            <Grid fluid>
              <Row>
                <Col xs={12} className="text-center">
                  <ButtonToolbar style={{ display: "inline-block" }}>
                    {!recording ? (
                      <Button className="Lex-button-2" onClick={start}>
                        <Icon glyph="icon-fontello-play-1" />
                        &nbsp;&nbsp;&nbsp;
                        <AxoLocal
                          entity="AudioRecorderStartnyoptagelse"
                          defaultValue={"Start ny optagelse"}
                        />
                      </Button>
                    ) : null}
                    {recording ? (
                      <Button className="Lex-button-2" onClick={stop}>
                        <Icon glyph="icon-fontello-stop-outline" />
                        &nbsp;&nbsp;&nbsp;
                        <AxoLocal
                          entity="AudioRecorderStop"
                          defaultValue={"Stop"}
                        />
                      </Button>
                    ) : null}
                    {recording && !paused ? (
                      <Button className="Lex-button-2" onClick={pause}>
                        <Icon glyph="icon-fontello-pause-3" />
                        &nbsp;&nbsp;&nbsp;
                        <AxoLocal
                          entity="AudioRecorderPause"
                          defaultValue={"Pause"}
                        />
                      </Button>
                    ) : null}
                    {recording && paused ? (
                      <Button className="Lex-button-2" onClick={resume}>
                        <Icon glyph="icon-fontello-play-1" />
                        &nbsp;&nbsp;&nbsp;
                        <AxoLocal
                          entity="axoidcode108"
                          defaultValue={"Genoptag"}
                        />
                      </Button>
                    ) : null}
                    {this.state.showSaveButton &&
                    !recording &&
                    this.state.file ? (
                      <Button
                        className="Lex-button-2"
                        onClick={this.onSaveFile}
                      >
                        <Icon glyph="icon-fontello-floppy-1" />
                        &nbsp;&nbsp;&nbsp;
                        <AxoLocal
                          entity="AudioRecorderSaverecording"
                          defaultValue={"Gem optagelse"}
                        />
                      </Button>
                    ) : null}
                    {recording ? (
                      <Button className="Lex-button-2">
                        <Counter seconds={this.state.counterSeconds} />
                      </Button>
                    ) : null}
                  </ButtonToolbar>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="text-center">
                    <canvas
                      style={{
                        height: "100px",
                        width: "75%",
                        display: "inline-block",
                      }}
                      className="visualizer"
                    ></canvas>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="text-center">
                  {this.state.audioSource ? (
                    <div style={{ display: "inline-block" }}>
                      <audio src={this.state.audioSource} controls />
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Grid>
          )}
        />
      </div>
    );
  }
}

export default AudioRecorder;
