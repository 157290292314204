import React from "react";
import { withRouter } from "react-router-dom";
import {
  // ApiService,
  DataActions,
  DataStore,
  LocalizationService,
  StorageService,
} from "../../services/AxoServices";

import {
  withRTKMessageData,
  withRTKCaseData,
} from "../../utilities/LexUtilities";

import InboxView from "./InboxView";

class InboxContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    let store = DataStore.getStore();
    this.state = {
      userProfile: {},
      userSettings: {},
      labels: [],
      isUpdating: false,
      emailAccounts: [],
      accountStatuses: {},
      confirmDeletionAccountId: "",
      accountDeletionInProgress: false,
      accountOrder: this.loadAccountOrder(),
      labelOrder: this.loadLabelOrder(),
      isAddingLabel: false,
      query: store.messageQuery,
      newLabelName: "",
      locale: "enUS",
    };
    this.name = "InboxContainer";
  }

  loadAccountOrder = () => {
    return StorageService.loadItem("emailAccountOrder", []);
  };

  saveAccountOrder = (accountOrder) => {
    StorageService.saveItem("emailAccountOrder", accountOrder);
  };

  updateAccountOrder = (accountOrder) => {
    this.setState({ accountOrder });
    this.saveAccountOrder(accountOrder);
  };

  loadLabelOrder = () => {
    return StorageService.loadItem("emailLabelOrder", []);
  };

  saveLabelOrder = (labelOrder) => {
    StorageService.saveItem("emailLabelOrder", labelOrder);
  };

  updateLabelOrder = (labelOrder) => {
    this.setState({ labelOrder });
    this.saveLabelOrder(labelOrder);
  };

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      let messageLabels = store.labels.filter((l) => l.type === 0).reverse();

      let labelOrder = this.loadLabelOrder();
      if (labelOrder.length !== messageLabels.length) {
        labelOrder = this.generateDefaultOrdering(messageLabels.length);
      }

      var accountOrder = this.loadAccountOrder();
      if (accountOrder.length !== store.emailAccounts.length) {
        accountOrder = this.generateDefaultOrdering(store.emailAccounts.length);
      }

      this.setState({
        accountStatuses: store.accountStatuses,
        userProfile: store.userProfile,
        userSettings: store.userSettings,
        caseMap: store.caseMap,
        labels: messageLabels,
        labelOrder,
        emailAccounts: store.emailAccounts,
        accountOrder,
        queryParams: store.messageQuery,
        pageSize: store.messagePageSize,
        locale: store.locale,
      });
    });
    DataStore.initializeUserProfile();
    DataStore.initializeLabels();
    DataStore.fetchEmailAccounts();
    DataStore.initializeCases();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
    LocalizationService.unsubscribeFromLocale(this.name);
  }

  setAccountStatus = (accountId, success) => {
    var accountStatuses = Object.assign({}, this.state.accountStatuses);
    accountStatuses[accountId] = success;
    this.setState({ accountStatuses });
  };

  generateDefaultOrdering = (length) => {
    var ordering = [];
    for (var i = 0; i < length; i++) {
      ordering.push(i);
    }
    return ordering;
  };

  toggleImportance = async (messageId) => {
    let { updateMessageFromHeader } = this.props;

    var message = this.props.messageMap.messages.find((message) => {
      return message.id === messageId;
    });
    if (!message) {
      message = this.state.externalMails.find((message) => {
        return message.id === messageId;
      });
    }
    var newMessage = Object.assign({}, message);
    newMessage.important = !newMessage.important;

    try {
      await updateMessageFromHeader(newMessage).unwrap();
      if (newMessage.externalUniqueId) {
        //Update IMAP message on mail server
        DataActions.updateExternalMails({
          accountId: newMessage.externalAccountId,
          messageIds: [newMessage.externalUniqueId],
          updateImportant: true,
          important: newMessage.important,
          isSent: !!(
            message.sender && message.sender.id === this.state.userProfile.id
          ),
          isSpam: message.spam,
        });
      }
    } catch {}
  };

  toggleRead = async (messageId) => {
    let { updateMessageFromHeader } = this.props;

    var message = this.props.messageMap.messages.find((message) => {
      return message.id === messageId;
    });
    if (!message) {
      message = this.state.externalMails.find((message) => {
        return message.id === messageId;
      });
    }
    let newMessage = {
      ...message,
      read: !message.read,
    };

    try {
      await updateMessageFromHeader(newMessage).unwrap();
      if (newMessage.externalUniqueId) {
        //Update IMAP message on mail server
        DataActions.updateExternalMails({
          accountId: newMessage.externalAccountId,
          messageIds: [newMessage.externalUniqueId],
          markAsRead: newMessage.read,
          markAsUnread: !newMessage.read,
          important: newMessage.important,
          isSent: !!(
            message.sender && message.sender.id === this.state.userProfile.id
          ),
          isSpam: message.spam,
        });
      }
    } catch {}
  };

  deleteExternalMessages = (messages) => {
    DataActions.deleteExternalMessages(messages, this.state.userProfile.id);
  };

  sortByDate = (m1, m2) => {
    return new Date(m1.date) - new Date(m2.date);
  };

  onDeleteAccount = (accountId) => {
    this.setState({ confirmDeletionAccountId: accountId });
  };

  onConfirmAccountDeletion = () => {
    this.setState({ accountDeletionInProgress: true });
    return DataActions.deleteEmailAccount(this.state.confirmDeletionAccountId)
      .then(() => {
        this.setState({
          accountDeletionInProgress: false,
          confirmDeletionAccountId: 0,
        });
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ accountDeletionInProgress: false });
      });
  };

  onCancelAccountDeletion = () => {
    this.setState({ confirmDeletionAccountId: 0 });
  };

  onShowMore = (query) => {
    DataStore.increaseMessagePageSize(query);
  };

  onToggleLabelCreation = () => {
    this.setState({ isAddingLabel: !this.state.isAddingLabel });
  };

  onUpdateNewLabelName = (event) => {
    this.setState({ newLabelName: event.target.value });
  };

  onCreateLabel = () => {
    var { newLabelName } = this.state;

    if (!newLabelName) {
      return;
    }
    DataActions.createLabel({ name: newLabelName, type: 0 }).then(
      (response) => {
        if (response.ok) {
          this.setState({
            isAddingLabel: false,
            newLabelName: "",
          });
        }
      }
    );
  };

  render() {
    var { messageMap } = this.props;
    if (!messageMap) {
      return null;
    }

    return (
      <InboxView
        {...this.props}
        {...this.state}
        onShowMore={this.onShowMore}
        includeLabels
        onToggleLabelCreation={this.onToggleLabelCreation}
        onUpdateNewLabelName={this.onUpdateNewLabelName}
        onCreateLabel={this.onCreateLabel}
        includeAccounts
        updateAccountOrder={this.updateAccountOrder}
        updateLabelOrder={this.updateLabelOrder}
        toggleImportance={this.toggleImportance}
        toggleRead={this.toggleRead}
        deleteExternalMessages={this.deleteExternalMessages}
        // onUpdate={this.onUpdate}
        onDeleteAccount={this.onDeleteAccount}
        onConfirmAccountDeletion={this.onConfirmAccountDeletion}
        onCancelAccountDeletion={this.onCancelAccountDeletion}
      />
    );
  }
}

export default withRTKCaseData(withRTKMessageData(withRouter(InboxContainer)));
