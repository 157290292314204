// @ts-check
import React from "react";
import InputBox from "./InputBox";
import SocialPost from "./SocialPost";
import SocialBanner from "./SocialBanner";
import PrivateConversationBanner from "./PrivateConversationBanner";
import ChatRoom from "./ChatRoom";
import * as $ from "jquery";

import { Row, Button, Panel, PanelGroup } from "react-bootstrap";

import { DataActions } from "../../services/AxoServices";

import {
  LexButton,
  AxoLocal,
  AccordionUtils,
  Icon,
} from "../../utilities/LexUtilities";

export default class SocialView extends React.PureComponent {
  //Props
  //userProfile
  //socialPosts
  //documentMap
  getShownPosts = () => {
    return this.props.socialPosts.slice(0, this.props.shownPosts);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.socialPosts.length > this.props.socialPosts.length) {
      this.markPostsRead(nextProps.socialPosts.slice(0, this.props.shownPosts));
    }
  }

  componentDidMount() {
    $("#body, html").addClass("social");
    this.markPostsRead(this.getShownPosts());
  }

  markPostsRead = (socialPosts) => {
    //Mark all posts read
    socialPosts.forEach((p) => {
      //Check for id to avoid selecting the system info posts
      if (!p.read && p.id > 0) {
        DataActions.addPostReading({
          socialPostId: p.id,
          userProfileId: this.props.userProfile.id,
        });
      }
    });
  };

  componentWillUnmount() {
    $("#body, html").removeClass("social");
  }

  renderSocialPosts = () => {
    return this.getShownPosts().map((post) => {
      return (
        <SocialPost
          key={post.id}
          socialPost={post}
          locale={this.props.userSettings.locale}
          friends={this.props.friends}
          documentMap={this.props.documentMap}
          readOnly={this.props.readOnly}
          userProfile={this.props.userProfile}
          onDeletePost={this.props.onDeletePost}
          onAddComment={this.props.onAddComment}
          onDeleteComment={this.props.onDeleteComment}
        />
      );
    });
  };

  renderInputBox = () => {
    if (this.props.readOnly) {
      return null;
    }
    if (!this.getIsActive()) {
      return null;
    }
    return (
      <InputBox
        locale={this.props.userSettings.locale}
        onCreatePost={this.props.onCreatePost}
        documentMap={this.props.documentMap}
        onUpdate={this.props.onUpdate}
      />
    );
  };

  getIsActive = () => {
    var userProfile = this.props.userProfile;
    switch (this.props.type) {
      case 0:
        return userProfile.publicRoomActive;
      case 1:
        return userProfile.internalRoomActive;
      case 2:
        return userProfile.privateRoomActive;
      default:
        return false;
    }
  };

  onRemoveUser = (userProfileId) => {
    if (userProfileId === this.props.userProfile.id) {
      this.props.onExitConvo(this.props.privateConversation);
    } else {
      DataActions.deletePrivateConversationUser(
        this.props.privateConversation,
        userProfileId
      );
    }
  };

  renderBanner = () => {
    if (!this.props.userProfile.id) {
      return null;
    }
    let conversationUsers = this.props.conversationUsers;
    if (
      (this.props.type === 1 || this.props.type === 2) &&
      conversationUsers.length > 0
    ) {
      //Internal and private room
      let privateConvo = this.props.privateConversation;
      let invitableUsers = this.props.userProfileArray.filter(
        (p) => !this.props.conversationUsers.find((c) => c.id === p.id)
      );
      let isOwner =
        privateConvo && privateConvo.ownerId === this.props.userProfile.id;
      return (
        <div style={{ position: "relative", zIndex: 9 }}>
          <PrivateConversationBanner
            isOwner={isOwner}
            isLocked={privateConvo && privateConvo.locked}
            privateConversation={privateConvo}
            currentUser={this.props.userProfile}
            conversationUsers={conversationUsers}
            invitableUsers={invitableUsers}
            onRemoveUser={this.onRemoveUser}
          />
          {!!privateConvo ? (
            <PanelGroup
              id="ITsubscriptionb"
              accordion
              className="Lex-Accordion"
              defaultActiveKey="0"
              style={{
                borderRadius: "0px",
                paddingTop: "0px",
                borderBottom: "1px solid white",
              }}
            >
              <Panel className="backgroundlineargradient3" eventKey="1">
                <Panel.Heading>
                  <Panel.Title toggle>
                    {AccordionUtils.renderAccordionHeader(
                      <span>
                        <Icon glyph="icon-fontello-cog-7" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="axoAccounting49"
                          defaultValue={"Samtale"}
                        />
                      </span>
                    )}
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible className="LexAccordionPanelody">
                  <ChatRoom
                    roomName={privateConvo.id}
                    userProfile={this.props.userProfile}
                    allowReset={isOwner}
                  />
                </Panel.Body>
              </Panel>
            </PanelGroup>
          ) : // <Accordion style={{ width: '90%', margin: 'auto', marginBottom: '10px' }} className='Lex-Accordion caseeditinline ' defaultActiveKey='0'>
          //   <Panel className='backgroundlineargradient3'
          //     header={AccordionUtils.renderAccordionHeader(<span>
          //       <Icon glyph="icon-fontello-cog-7"/>&nbsp;&nbsp;
          //       <AxoLocal entity='axoAccounting49' defaultValue={'Samtale'}/>
          //       </span>)} eventKey="1">
          //       <ChatRoom
          //         roomName={privateConvo.id}
          //         userProfile={this.props.userProfile}
          //         allowReset={isOwner}
          //       />
          //   </Panel>
          // </Accordion>
          null}
        </div>
      );
    }
    return (
      <SocialBanner
        active={this.getIsActive()}
        type={this.props.type}
        userProfile={this.props.bannerUser}
        currentUser={this.props.userProfile}
      />
    );
  };

  //We retrieve one more post from the database than needed, so we can tell if more posts are available
  morePostsAvailable = () => {
    return this.props.socialPosts.length > this.props.shownPosts;
  };

  renderContent = () => {
    return (
      <div>
        {this.renderSocialPosts()}
        {this.morePostsAvailable() ? (
          <div className="text-center" style={{ paddingBottom: "50px" }}>
            <Button
              onClick={this.props.onShowMore}
              className="Lex-button-2"
              style={{ width: "75%" }}
            >
              <AxoLocal entity="ViewShowmoreinfo" defaultValue={"Vis flere"} />
              &nbsp;
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  PrivateConversationButton = (props) => {
    let { privateConversationArray, goToConversation } = this.props;
    var { bannerUser, currentUser, onAddPrivateConversation } = props;
    if (bannerUser.id === currentUser.id) {
      return null;
    }

    const startPrivateConversation = () => {
      let currentConversation = privateConversationArray.find(
        (convo) =>
          convo.conversationUsers.length < 3 &&
          !!convo.conversationUsers.find((user) => user.id === bannerUser.id)
      );

      if (!!currentConversation) {
        goToConversation(currentConversation.id);
        return;
      }

      onAddPrivateConversation({
        onGoing: true,
        locked: true,
        conversationUsers: [bannerUser],
      });
    };

    return (
      <LexButton onClick={startPrivateConversation}>
        <AxoLocal entity="socialView1a" defaultValue={"Start privat samtale"} />
      </LexButton>
    );
  };

  render() {
    var { bannerUser, userProfile, onAddPrivateConversation } = this.props;
    var shownPosts = this.getShownPosts();

    let PrivateConversationButton = this.PrivateConversationButton;
    return (
      <Row className="social">
        {this.renderBanner()}
        {this.renderInputBox()}
        {bannerUser.id !== userProfile.id ? (
          <div>
            &nbsp;&nbsp;&nbsp;
            <AxoLocal
              entity="socialView1b"
              defaultValue={"Denne brugers offentlige indlæg"}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <PrivateConversationButton
              bannerUser={bannerUser}
              currentUser={userProfile}
              onAddPrivateConversation={onAddPrivateConversation}
            />
          </div>
        ) : null}
        {shownPosts.length < 3 ? (
          <div
            style={{
              minHeight: "400px",
              background: "white",
              paddingTop: "10px",
            }}
          >
            {this.renderContent()}
          </div>
        ) : (
          this.renderContent()
        )}
      </Row>
    );
  }
}
