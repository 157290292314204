import React from "react";
import TinyMCEEditor from "./TinyMCEEditor";

import { AxoFrame } from "../LexUtilities";

export default class InlineTinyMCEEditor extends React.PureComponent {
  renderText = () => {
    return (
      <div onDoubleClick={this.props.enableEdit}>
        <AxoFrame bottomPadding={35} content={this.props.text} />
      </div>
    );
  };

  render() {
    return this.props.editEnabled ? (
      <TinyMCEEditor
        text={this.props.text}
        locale={this.props.locale}
        onTextChange={this.props.onTextChange}
        documentMap={this.props.documentMap}
        allowImageImport={this.props.allowImageImport}
      />
    ) : (
      this.renderText()
    );
  }
}
