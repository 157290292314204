import React, { useEffect } from "react";

import {
  withData,
  // LoadingIndicator,
  // AsyncButton
} from "../../utilities/LexUtilities";

import {
  // ApiService,
  // DataActions,
  DataStore,
} from "../../services/AxoServices";

import CasesList from "./CasesList";

const mapStoreToProps = (store) => {
  return {
    caseModels: store.currentClientCases,
    // userSettings: store.userSettings,
  };
};

export default withData(
  React.memo(function ClientCasesContainer(props) {
    let {
      caseModels,
      // userSettings,
      // selectedContact
    } = props;

    useEffect(() => {
      // DataStore.fetchUserProfile();
      // DataStore.fetchUserSettings();
      DataStore.fetchCurrentClientCases();
    }, []);

    if (caseModels.length === 0) {
      return <div></div>;
    }

    // if(!userSettings.id || !userProfile.id) {
    //   return <LoadingIndicator />;
    // }

    // if(userSettings.accountingClientId !== selectedContact.id) {
    //   return <LoadingIndicator />
    // }

    return <CasesList caseModels={caseModels} />;
  }),
  mapStoreToProps
);
