import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import EmploymentContractTabView from "./EmploymentContractTabView";

import { Consumer } from "../utilities/LexUtilities";

export default class EmploymentContractContainer extends React.PureComponent {
  componentDidMount() {
    //Fetch data on page load
    DataStore.fetchEmploymentContracts();
  }

  componentWillUnmount() {
    //Do stuff when navigating away
  }

  //Filter out needed data from DataStore.js
  mapStoreToProps = (store) => {
    return {
      employmentContracts: store.employmentContracts,
      showDropdowns: store.showDropdowns,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => {
          let props = this.mapStoreToProps(store);
          return <EmploymentContractTabView {...props} />;
        }}
      </Consumer>
    );
  }
}
