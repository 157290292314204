import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import ContactTableView from "./ContactTableView";
import ContactCreateView from "./ContactCreateView";
import Conditional from "../../utilities/Conditional";
import LexNavButton from "../../utilities/LexNavButton";
import { SmallOrSmaller, MediumOrLarger } from "../../utilities/Responsive";
import { withRouter } from "react-router-dom";
import { DataStore, AuthorizationService } from "../../services/AxoServices";
import { AxoLocal, Icon } from "../../utilities/LexUtilities";
import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";

class ContactTabView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false, //Show all tabs on small screens,
    };
  }

  handleActiveState = (eventKey) => {
    this.props.handleNavigation(eventKey);
  };

  onToggleClientCards = () => {
    var userSettings = Object.assign({}, this.props.userSettings);
    userSettings.showClientCards = !userSettings.showClientCards;
    DataActions.updateUserSettings(userSettings);
  };

  renderLargeScreenNavigationBar = () => {
    let { showDropdowns, activeTab, userProfile } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <MediumOrLarger>
        <Grid fluid className="axo-nav-lg" id="noprint">
          {!showDropdowns ? (
            <Row>
              <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{
                    borderRadius: "0px",
                    paddingTop: "0px",
                    paddingLeft: "2px",
                  }}
                >
                  <LexNavButton
                    className="axonavTab axonavTabcolora"
                    ls
                    glyph="icon-fontello-th-2"
                    eventKey="List"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="CalendarTabViewagenda"
                      defaultValue={"Oversigt"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorb"
                    ls
                    glyph="icon-fontello-user-add"
                    eventKey="Create"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal entity="ADDViewCreate" defaultValue={"Opret"} />
                  </LexNavButton>
                  {userType === "Society" ? (
                    <LexNavButton
                      className="axonavTab axonavTabcolorc"
                      ls
                      glyph="icon-fontello-user-7"
                      eventKey="Waiting"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="axoidcode185"
                        defaultValue={"Venteliste"}
                      />
                    </LexNavButton>
                  ) : null}
                  {userType === "Society" ? (
                    <LexNavButton
                      className="axonavTab axonavTabcolord"
                      ls
                      glyph="icon-fontello-user-7"
                      eventKey="Former"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="axoidcode186"
                        defaultValue={"Udmeldte"}
                      />
                    </LexNavButton>
                  ) : null}
                  {/* <LexNavButton className='axonavTab'ls glyph='icon-fontello-user-7' eventKey='Private' activeTab={activeTab} onClick={this.handleActiveState}>
                    <AxoLocal entity='ContactTabViewprivateCustomers' defaultValue={'Klienter'}/>
                  </LexNavButton>
                <LexNavButton className='axonavTab'ls glyph='icon-fontello-building' eventKey='Business' activeTab={activeTab} onClick={this.handleActiveState}>
                  <AxoLocal entity='ContactTabViewBusinessCustomers' defaultValue={'Erhvervskunder'}/>
                </LexNavButton>
                <LexNavButton className='axonavTab'ls glyph='icon-fontello-warehouse' eventKey='Public' activeTab={activeTab} onClick={this.handleActiveState}>
                  <AxoLocal entity='ContactTabViewpublic' defaultValue={'Offentlige'}/>
                </LexNavButton>
                <LexNavButton className='axonavTab'ls glyph='icon-fontello-eq-outline' eventKey='Misc' activeTab={activeTab} onClick={this.handleActiveState}>
                  <AxoLocal entity='ContactTabViewMiscellaneous'defaultValue={'Diverse'}/>
                </LexNavButton> */}
                  <LexNavButton
                    ls
                    listButton
                    className="axonavTab showListButton"
                    onClick={() => {
                      DataStore.setShowDropDowns(true);
                    }}
                  >
                    <div className="flexbox-center">
                      <Icon
                        glyph="icon-fontello-align-justify"
                        style={{ fontSize: "30px" }}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity66"
                        defaultValue={"Liste Menu"}
                      />
                    </div>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab axonavTabcolora AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("List")}
                >
                  <span className="icon-fontello-th-2" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CalendarTabViewagenda"
                    defaultValue={"Oversigt"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Create")}
                >
                  <span className="icon-fontello-user-add" /> &nbsp;&nbsp;
                  <AxoLocal entity="ADDViewCreate" defaultValue={"Opret"} />
                </MenuItem>
                {userType === "Society" ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Waiting")}
                  >
                    <span className="icon-fontello-user-7" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="axoidcode185"
                      defaultValue={"Venteliste"}
                    />
                  </MenuItem>
                ) : null}
                {userType === "Society" ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Former")}
                  >
                    <span className="icon-fontello-building" /> &nbsp;&nbsp;
                    <AxoLocal entity="axoidcode186" defaultValue={"Udmeldte"} />
                  </MenuItem>
                ) : null}

                {/* <MenuItem className='axonavTab AxotabDropdownmenu' onClick={() => this.handleActiveState('Private')}>
                <span className='icon-fontello-user-7'/>  &nbsp;&nbsp;<AxoLocal entity='ContactTabViewprivateCustomers' defaultValue={'Klienter'}/>
                </MenuItem>
                <MenuItem className='axonavTab AxotabDropdownmenu' onClick={() => this.handleActiveState('Business')}>
                <span className='icon-fontello-building'/>  &nbsp;&nbsp;<AxoLocal entity='ContactTabViewBusinessCustomers' defaultValue={'Erhvervskunder'}/>  
                </MenuItem>
                <MenuItem className='axonavTab AxotabDropdownmenu' onClick={() => this.handleActiveState('Public')}>
                <span className='icon-fontello-warehouse'/>  &nbsp;&nbsp;<AxoLocal entity='ContactTabViewpublic' defaultValue={'Offentlige'}/>
                </MenuItem>
                <MenuItem className='axonavTab AxotabDropdownmenu
                ' onClick={() => this.handleActiveState('misc')}>
                <span className='icon-fontello-eq-outline'/>  &nbsp;&nbsp;<AxoLocal entity='ContactTabViewMiscellaneous'defaultValue={'Diverse'}/>
                </MenuItem> */}
              </DropdownButton>
            </Row>
          )}
        </Grid>
      </MediumOrLarger>
    );
  };

  expandTabs = () => {
    this.setState({ expanded: true });
  };

  renderSmallScreenNavigationBar = () => {
    let { userProfile } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <SmallOrSmaller>
        <Grid fluid className="axo-nav" id="noprint">
          <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab axonavTabcolora AxotabDropdownmenu"
                onClick={() => this.handleActiveState("List")}
              >
                <span className="icon-fontello-th-2" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="CalendarTabViewagenda"
                  defaultValue={"Oversigt"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("Create")}
              >
                <span className="icon-fontello-user-add" /> &nbsp;&nbsp;
                <AxoLocal entity="ADDViewCreate" defaultValue={"Opret"} />
              </MenuItem>
              {userType === "Society" ? (
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Waiting")}
                >
                  <span className="icon-fontello-user-7" /> &nbsp;&nbsp;
                  <AxoLocal entity="axoidcode185" defaultValue={"Venteliste"} />
                </MenuItem>
              ) : null}
              {userType === "Society" ? (
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Former")}
                >
                  <span className="icon-fontello-building" /> &nbsp;&nbsp;
                  <AxoLocal entity="axoidcode186" defaultValue={"Udmeldte"} />
                </MenuItem>
              ) : null}
              {/* <MenuItem className='axonavTab AxotabDropdownmenu' onClick={() => this.handleActiveState('Private')}>
                <span className='icon-fontello-user-7'/>  &nbsp;&nbsp;<AxoLocal entity='ContactTabViewprivateCustomers' defaultValue={'Klienter'}/>
                </MenuItem>
                <MenuItem className='axonavTab AxotabDropdownmenu' onClick={() => this.handleActiveState('Business')}>
                <span className='icon-fontello-building'/>  &nbsp;&nbsp;<AxoLocal entity='ContactTabViewBusinessCustomers' defaultValue={'Erhvervskunder'}/>  
                </MenuItem>
                <MenuItem className='axonavTab AxotabDropdownmenu' onClick={() => this.handleActiveState('Public')}>
                <span className='icon-fontello-warehouse'/>  &nbsp;&nbsp;<AxoLocal entity='ContactTabViewpublic' defaultValue={'Offentlige'}/>
                </MenuItem>
                <MenuItem className='axonavTab AxotabDropdownmenu' onClick={() => this.handleActiveState('Misc')}>
                <span className='icon-fontello-eq-outline'/>  &nbsp;&nbsp;<AxoLocal entity='ContactTabViewMiscellaneous'defaultValue={'Diverse'}/>
                </MenuItem> */}
            </DropdownButton>
          </Row>
        </Grid>
      </SmallOrSmaller>
    );
  };

  renderNavigationBar = () => {
    return (
      <div className="Tabbanne" style={{ paddingTop: "0px" }}>
        {this.renderLargeScreenNavigationBar()}
        {this.renderSmallScreenNavigationBar()}
      </div>
    );
  };

  onClientCreated = () => {
    this.handleActiveState("List");
  };

  renderNavigationContent = () => {
    let {
      contactMap,
      labels,
      userGroup,
      friends,
      userProfile,
      userSettings,
      sharingGlobals,
      queryContacts,
      refetchContacts,
      resetContactQuery,
      adminCodeValidated,
      activeTab,
      updateContact,
      createContact,
      createClientAccount,
      updateNewEntries,
      setSelectedContact,
      incrementContactPageSize,
      locale,
    } = this.props;

    let { contacts } = contactMap;

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div>
        <Conditional show={activeTab === "List"}>
          <ContactTableView
            // contacts={contacts.filter(c => c.clientType !== 'Waiting' && c.clientType !== 'Former')}
            contacts={contacts}
            isSociety={userType === "Society"} //Society table filters out waiting list and former members groups by default
            count={contactMap.count}
            updateContact={updateContact}
            createContact={createContact}
            createClientAccount={createClientAccount}
            refetchContacts={refetchContacts}
            incrementContactPageSize={incrementContactPageSize}
            updateNewEntries={updateNewEntries}
            labels={labels}
            userGroup={userGroup}
            friends={friends}
            userProfile={userProfile}
            userSettings={userSettings}
            sharingGlobals={sharingGlobals}
            forceShowCards={this.props.userSettings.showClientCards}
            onToggleCards={this.onToggleClientCards}
            queryContacts={queryContacts}
            resetContactQuery={resetContactQuery}
            setSelectedContact={setSelectedContact}
            adminCodeValidated={adminCodeValidated}
            locale={locale}
          />
        </Conditional>
        <Conditional show={activeTab === "Create"}>
          <ContactCreateView
            contactMap={contactMap}
            userProfile={userProfile}
            createContact={createContact}
            updateContact={updateContact}
            onClientCreated={this.onClientCreated}
            createClientAccount={createClientAccount}
          />
        </Conditional>
        <Conditional show={activeTab === "Waiting"}>
          <ContactTableView
            tableLabelId={userSettings.waitingMembersLabelId}
            // contacts={contacts.filter( c => c.clientType === 'Waiting')}
            contacts={contacts.filter(
              (c) =>
                !!c.labels &&
                c.labels.find(
                  (l) => l.id === userSettings.waitingMembersLabelId
                )
            )}
            updateContact={updateContact}
            createContact={createContact}
            createClientAccount={createClientAccount}
            refetchContacts={refetchContacts}
            setSelectedContact={setSelectedContact}
            incrementContactPageSize={incrementContactPageSize}
            updateNewEntries={updateNewEntries}
            labels={labels}
            userGroup={userGroup}
            friends={friends}
            userProfile={userProfile}
            sharingGlobals={sharingGlobals}
            forceShowCards={this.props.userSettings.showClientCards}
            onToggleCards={this.onToggleClientCards}
            queryContacts={queryContacts}
            resetContactQuery={resetContactQuery}
            adminCodeValidated={adminCodeValidated}
            locale={locale}
          />
        </Conditional>
        <Conditional show={activeTab === "Former"}>
          <ContactTableView
            tableLabelId={userSettings.formerMembersLabelId}
            // contacts={contacts.filter( c => c.clientType === 'Former')}
            contacts={contacts.filter(
              (c) =>
                !!c.labels &&
                c.labels.find((l) => l.id === userSettings.formerMembersLabelId)
            )}
            updateContact={updateContact}
            createContact={createContact}
            createClientAccount={createClientAccount}
            refetchContacts={refetchContacts}
            setSelectedContact={setSelectedContact}
            incrementContactPageSize={incrementContactPageSize}
            updateNewEntries={updateNewEntries}
            labels={labels}
            userGroup={userGroup}
            friends={friends}
            userProfile={userProfile}
            sharingGlobals={sharingGlobals}
            forceShowCards={this.props.userSettings.showClientCards}
            onToggleCards={this.onToggleClientCards}
            queryContacts={queryContacts}
            resetContactQuery={resetContactQuery}
            adminCodeValidated={adminCodeValidated}
            locale={locale}
          />
        </Conditional>
        <Conditional show={activeTab === "Private"}>
          <ContactTableView
            contacts={contacts.filter(
              (c) => c.type === 0 && c.clientType === 0
            )}
            updateContact={updateContact}
            createContact={createContact}
            createClientAccount={createClientAccount}
            refetchContacts={refetchContacts}
            setSelectedContact={setSelectedContact}
            incrementContactPageSize={incrementContactPageSize}
            updateNewEntries={updateNewEntries}
            labels={labels}
            userGroup={userGroup}
            friends={friends}
            userProfile={userProfile}
            sharingGlobals={sharingGlobals}
            forceShowCards={this.props.userSettings.showClientCards}
            onToggleCards={this.onToggleClientCards}
            queryContacts={queryContacts}
            resetContactQuery={resetContactQuery}
            adminCodeValidated={adminCodeValidated}
            locale={locale}
          />
        </Conditional>
        <Conditional show={activeTab === "Business"}>
          <ContactTableView
            contacts={contacts.filter(
              (c) => c.type === 0 && c.clientType === 1
            )}
            updateContact={updateContact}
            createContact={createContact}
            createClientAccount={createClientAccount}
            refetchContacts={refetchContacts}
            setSelectedContact={setSelectedContact}
            incrementContactPageSize={incrementContactPageSize}
            updateNewEntries={updateNewEntries}
            labels={labels}
            userGroup={userGroup}
            friends={friends}
            userProfile={userProfile}
            sharingGlobals={sharingGlobals}
            forceShowCards={this.props.userSettings.showClientCards}
            onToggleCards={this.onToggleClientCards}
            queryContacts={queryContacts}
            resetContactQuery={resetContactQuery}
            adminCodeValidated={adminCodeValidated}
            locale={locale}
          />
        </Conditional>
        <Conditional show={activeTab === "Public"}>
          <ContactTableView
            contacts={contacts.filter(
              (c) => c.type === 0 && c.clientType === 2
            )}
            updateContact={updateContact}
            createContact={createContact}
            createClientAccount={createClientAccount}
            refetchContacts={refetchContacts}
            setSelectedContact={setSelectedContact}
            incrementContactPageSize={incrementContactPageSize}
            updateNewEntries={updateNewEntries}
            labels={labels}
            userGroup={userGroup}
            friends={friends}
            userProfile={userProfile}
            sharingGlobals={sharingGlobals}
            forceShowCards={this.props.userSettings.showClientCards}
            onToggleCards={this.onToggleClientCards}
            queryContacts={queryContacts}
            resetContactQuery={resetContactQuery}
            adminCodeValidated={adminCodeValidated}
            locale={locale}
          />
        </Conditional>
        <Conditional show={activeTab === "Misc"}>
          <ContactTableView
            contacts={contacts.filter((c) => c.type === 0 && c.clientType > 2)}
            updateContact={updateContact}
            createContact={createContact}
            createClientAccount={createClientAccount}
            refetchContacts={refetchContacts}
            setSelectedContact={setSelectedContact}
            incrementContactPageSize={incrementContactPageSize}
            updateNewEntries={updateNewEntries}
            labels={labels}
            userGroup={userGroup}
            friends={friends}
            userProfile={userProfile}
            sharingGlobals={sharingGlobals}
            forceShowCards={this.props.userSettings.showClientCards}
            onToggleCards={this.onToggleClientCards}
            queryContacts={queryContacts}
            resetContactQuery={resetContactQuery}
            adminCodeValidated={adminCodeValidated}
            locale={locale}
          />
        </Conditional>
      </div>
    );
  };
  //Props
  //Contacts
  render() {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} className="nopadding">
            {this.renderNavigationBar()}
            {this.renderNavigationContent()}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(ContactTabView);
