import React from "react";
import Autosuggest from "react-autosuggest";
import UserInfoService from "../../services/UserInfoService";
import { AxoLocal } from "../LexUtilities";

var theme = {
  container: {
    position: "relative",
  },
  input: {
    maxWidth: 240,
    height: 30,
    padding: "10px 20px",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 12,
    border: "1px solid #aaa",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 30,
    width: 280,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
    maxHeight: "500px",
    overflowY: "auto",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#e6e6e6",
  },
};

//Props
//userProfiles
//handleSelectedUser
//disabled
export default class UserSearchBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: props.startValue || "",
      suggestions: [],
    };
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? this.props.userProfiles
      : this.props.userProfiles.filter((userProfile) => {
          var nameString =
            userProfile.firstName +
            " " +
            userProfile.lastName +
            " " +
            userProfile.userName;
          return nameString.toLowerCase().includes(inputValue);
        });
  };

  // When suggestion is clicked, Autosuggest needs to populate the input element
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) =>
    UserInfoService.getDisplayName(suggestion);

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => {
    var displayName = UserInfoService.getDisplayName(suggestion);
    return (
      <div>
        <img
          alt=""
          src={UserInfoService.getProfileImageSource(suggestion)}
          width="30"
          height="30"
        />{" "}
        &nbsp;&nbsp;
        {displayName}
        {/*Add label if the user is a client  */}
        {suggestion.userType === "Client" ? (
          <div>
            <AxoLocal
              entity="UserSearchClientLabel"
              defaultValue={"(Klient)"}
            />
          </div>
        ) : null}
        {suggestion.userType === "Accounting" ? <div>(Kunde)</div> : null}
      </div>
    );
  };

  onChange = (event, { newValue }) => {
    this.setState({
      searchValue: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.handleSelectedUser(suggestion);
    if (this.props.clearOnSelect) {
      this.setState(
        {
          searchValue: "",
          suggestions: [],
        },
        () => {
          document.activeElement.blur();
        }
      );
    }
  };

  shouldRenderSuggestions(value) {
    return true;
  }

  //Props
  //placeholder
  //clearOnSelect
  render() {
    var searchValue = this.state.searchValue;
    const inputProps = {
      className: "searchbox",
      placeholder: this.props.placeholder,
      value: searchValue,
      disabled: this.props.disabled,
      onChange: this.onChange,
    };
    if (this.props.inputBorder) {
      theme.input.border = this.props.inputBorder;
    }
    if (this.props.maxWidth) {
      theme.input.maxWidth = this.props.maxWidth;
      theme.input.display = "block";
      theme.input.width = "100%";
    }
    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        theme={theme}
      />
    );
  }
}
