import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import { CompactPicker } from "react-color";
import * as $ from "jquery";

import { LexButton, QRCode, AxoLocal } from "./LexUtilities";

import { FormControl, Row, Col } from "react-bootstrap";

export default class QRCodeGenerator extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      bgColor: "#FFFFFF",
      fgColor: "#428bca",
      logo: "/svg/AxolextmA-01-bg.png",
      value: "https://axolex.com",
    };
    if (props.value) {
      this.state.value = props.value;
    }
  }

  handleBgColorChange = (color) => {
    this.setState({ bgColor: color.hex });
  };

  handleFgColorChange = (color) => {
    this.setState({ fgColor: color.hex });
  };

  handleFormChange = (event) => {
    var input = event.target;
    this.setState({ [input.name]: input.value });
  };

  onDownload = () => {
    var myCanvas = document.getElementsByTagName("canvas")[0];
    var a = document.createElement("a");
    a.href = myCanvas.toDataURL();
    a.download = "AxolexQRCode" + Math.floor(Math.random() * 100000).toString();
    a.click();

    DataStore.incrementQRCount();
  };

  uploadLogo = () => {
    $("#uploadQRImage").trigger("click");
  };

  onChooseImage = (event) => {
    var file = event.target.files[0];
    if (!file.type.includes("image")) {
      return;
    }
    var reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        this.setState({ logo: reader.result });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  render() {
    var { bgColor, fgColor, logo, value } = this.state;
    var { header } = this.props;
    return (
      <div>
        <Row>
          <Col xs={12} className="text-center" style={{ paddingBottom: "5px" }}>
            <div
              className="frontPageaxolexdiv Bannecolor"
              style={{ paddingBottom: "5px" }}
            >
              {header}
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="flexbox-center text-center"
            style={{ paddingBottom: "5px" }}
          >
            <div className="axolexQRcodebanne BannecolorQR text-center ">
              <AxoLocal entity="axolexQRcode3" defaultValue={"Forgrund"} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            <div style={{ paddingBottom: "5px" }}>
              <CompactPicker
                color={fgColor}
                onChangeComplete={this.handleFgColorChange}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="flexbox-center"
            style={{ paddingBottom: "5px" }}
          >
            <div
              className="axolexQRcodebanne BannecolorQR text-center"
              style={{ paddingBottom: "5px" }}
            >
              <AxoLocal entity="axolexQRcode2" defaultValue={"Baggrund"} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            <div style={{ paddingBottom: "5px" }}>
              <CompactPicker
                color={bgColor}
                onChangeComplete={this.handleBgColorChange}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-center" style={{ paddingBottom: "5px" }}>
            <div className="text-center" style={{ paddingBottom: "5px" }}>
              <div
                className="axolexQRcodebanne BannecolorQR text-center"
                style={{ paddingBottom: "5px" }}
              >
                <AxoLocal
                  entity="CalendarEventFormbookingLink"
                  defaultValue={" Link:"}
                />
                :
              </div>
              <FormControl
                style={{ maxWidth: "800px", margin: "auto", marginTop: "5px" }}
                name="value"
                type="text"
                value={value}
                onChange={this.handleFormChange}
              />
            </div>
          </Col>
        </Row>
        <Row
          style={{ maxWidth: "800px", margin: "auto", paddingBottom: "5px" }}
        >
          <Col sm={7} xs={12} className="flexbox-center">
            <div
              className="text-right"
              style={{
                border: "3px solid white",
                height: "262px",
                width: "262px",
              }}
            >
              <QRCode
                bgColor={bgColor}
                fgColor={fgColor}
                logo={logo}
                value={value}
                size={256}
                logoWidth={80}
                logoHeight={80}
              />
            </div>
          </Col>
          <Col sm={5} xs={12}>
            <div
              className="flexbox-center axolexQRcodeupload"
              style={{ paddingBottom: "25px" }}
            >
              <div className="text-center">
                <LexButton
                  onClick={this.uploadLogo}
                  style={{
                    height: "55px",
                    width: "250px",
                    marginBottom: "5px",
                  }}
                >
                  <AxoLocal
                    entity="axolexQRcode5"
                    defaultValue={"Upload Logo"}
                  />
                </LexButton>
                <LexButton
                  onClick={() => {
                    this.setState({ logo: "" });
                  }}
                  style={{ height: "55px", width: "250px" }}
                >
                  <AxoLocal
                    entity="axolexQRcode5a"
                    defaultValue={"Uden logo"}
                  />
                </LexButton>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="flexbox-center">
            <div style={{ paddingBottom: "25px" }}>
              <LexButton
                onClick={this.onDownload}
                style={{ height: "55px", width: "250px" }}
              >
                <AxoLocal entity="axolexDownload6" defaultValue={"Download"} />
              </LexButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="flexbox-center">
            <div style={{ paddingBottom: "25px" }}>
              <input
                name="image"
                type="file"
                id="uploadQRImage"
                style={{ display: "none" }}
                accept="image/*"
                onChange={this.onChooseImage}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
