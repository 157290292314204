import React from "react";
import { Row, Col } from "react-bootstrap";
import { Icon } from "../../utilities/LexUtilities";

export default class AttachmentFooter extends React.PureComponent {
  //Props
  //attachment
  //onClickFile
  //onRemoveAttachment
  render() {
    var attachment = this.props.attachment;
    if (!attachment) {
      return null;
    }
    return (
      <Row>
        <Col xs={12}>
          <strong
            role="button"
            onClick={this.props.onClickFile.bind(this, attachment)}
            className="editable"
          >
            {attachment.fileName}
          </strong>
          &ensp;
          <span>({attachment.sizeString})</span>
          &ensp;
          <Icon
            role="button"
            onClick={this.props.onRemoveAttachment}
            glyph="icon-fontello-scissors axored"
            className="axored"
          />
        </Col>
      </Row>
    );
  }
}
