import React from "react";
import classNames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  Large,
  SmallOrSmaller,
  MediumOrSmaller,
  MediumOrLarger,
} from "../../utilities/Responsive";

import {
  RoutingService,
  UserInfoService,
  LocalizationService,
  StringService,
  // MessageService
} from "../../services/AxoServices";

import {
  AxoCheckbox,
  Icon,
  ctx,
  AxoLocal,
  Flexbox,
  getText,
} from "../../utilities/LexUtilities";

import { Grid, Row, Col } from "react-bootstrap";

class InboxItem extends React.PureComponent {
  getPath = (path) => {
    return RoutingService.getPath(path);
  };
  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick();
    } else if (this.props.message.draft) {
      this.props.history.push(
        this.getPath("mailbox/composeDraft/" + this.props.message.id)
      );
    } else {
      var location = this.props.shown ? this.props.shown : this.getType();
      this.props.history.push(
        this.getPath("mailbox/mail/" + location + "/" + this.props.message.id)
      );
    }
  };

  //Props
  //onDelete
  //onRemove
  getType = () => {
    var message = this.props.message;
    var userProfile = this.props.userProfile;
    var senderId = message.sender ? message.sender.id : 0;
    var receiverId = message.receiver ? message.receiver.id : 0;
    if (receiverId === userProfile.id && !message.draft && !message.trashed) {
      return "inbox";
    } else if (message.spam) {
      return "spam";
    } else if (message.important && !message.trashed) {
      return "important";
    } else if (
      senderId === userProfile.id &&
      !message.draft &&
      !message.trashed
    ) {
      return "sent";
    } else if (
      senderId === userProfile.id &&
      message.draft &&
      !message.trashed
    ) {
      return "drafts";
    } else if (receiverId === userProfile.id && message.trashed) {
      return "trashed";
    }
    return "inbox";
  };

  onImportantClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.toggleImportance(this.props.message.id);
  };

  onToggleRead = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.toggleRead(this.props.message.id);
  };

  onCheckBoxClicked = () => {
    if (!this.props.onSelect) {
      return;
    }
    this.props.onSelect(this.props.message, !this.props.selected);
  };

  getReceiverName = () => {
    var message = this.props.message;
    //For BCC messages, the primary receiver is shown, instead of the current receiver
    if (message.primaryReceiver) {
      return UserInfoService.getDisplayName(message.primaryReceiver);
    }
    return UserInfoService.getDisplayName(message.receiver);
  };

  getInfoText = (type) => {
    var infoText = null;
    var { message } = this.props;
    if (type === "sent") {
      //Sent
      infoText = message.receiver
        ? ctx.getSync("DocumentmessagepropsmessagereceiverTo") +
          this.getReceiverName()
        : "";
    } else if (!!message.externalSenderDisplayName) {
      infoText = message.externalSenderDisplayName;
    } else if (!!message.externalSender) {
      infoText = message.externalSender;
    } else {
      infoText = UserInfoService.getDisplayName(message.sender);
    }
    if (message.draft) {
      infoText +=
        "\xa0\xa0\xa0\xa0" + ctx.getSync("Documentmessagepropsmessagedraft");
    }
    if (!!message.caseModel) {
      infoText += " Sag: " + message.caseModel.caseNumber;
    }
    if (!!message.note) {
      infoText += " (" + message.note + ")";
    }
    return infoText;
  };

  renderExternalMailInfo = (type) => {
    var { message, unread } = this.props;
    if (type !== "sent" || !message.externalMailReceiver) {
      return null;
    }

    let infoText =
      message.externalReceiverDisplayName || message.externalMailReceiver;
    infoText = StringService.enforceMaxLength(infoText, 50);
    // var infoText = <span> <AxoLocal entity='placeholderSearchExternalmail' defaultValue={'Ekstern mail:'}/> {this.props.message.externalMailReceiver}</span>
    return (
      <div className="axoblack">{unread ? <b>{infoText}</b> : infoText}</div>
    );
  };

  renderBCCInfo = () => {
    if (!this.props.message.primaryReceiver) {
      return null;
    }
    // var infoText = 'Ekstern mail: ' + this.props.message.externalMailReceiver;
    var infoText = (
      <AxoLocal entity="MailViewBCCLabel" defaultValue={"BCC til mig"} />
    );
    return (
      <div className="axoblack">
        {this.props.unread ? <b>{infoText}</b> : infoText}
      </div>
    );
  };

  PreviewImage = (props) => {
    let { message, userProfile } = props;
    let src = "";
    // let src = MessageService.secureImageSource(message.previewImageSource);
    if (!src && message.sender && userProfile.id !== message.sender.id) {
      //Do not show the users own profile picture
      src = UserInfoService.getProfileImageSource(message.sender);
    }
    if (!src) {
      return <div style={{ display: "inline-block", width: "50px" }}></div>;
    }
    return (
      //External div ensures broken images take up the correct amount of space
      <div style={{ width: "45px", height: "45px", display: "inline-block" }}>
        <img
          alt=""
          src={src}
          width="45px"
          height="45px"
          className={this.props.imgClass}
        />
      </div>
    );
  };

  //Used on medium or large screens to enforce text max length
  renderInfoText = (type) => {
    let { icon } = this.props;

    let StatusIcons = this.StatusIcons;
    return (
      <div>
        <MediumOrSmaller>
          {StringService.enforceMaxLength(this.getInfoText(type), 20)}
          &nbsp; {icon || null}
          <StatusIcons />
        </MediumOrSmaller>
        <Large>
          {StringService.enforceMaxLength(this.getInfoText(type), 50)}
          &nbsp; {icon || null}
          <StatusIcons />
        </Large>
      </div>
    );
  };

  //Used on medium or large screens to enforce text max length
  renderSubject = (subject) => {
    return (
      <div className="axoInboxItemSubjectText">
        <MediumOrSmaller>
          {StringService.enforceMaxLength(subject, 25)}
        </MediumOrSmaller>
        <Large>{StringService.enforceMaxLength(subject, 50)}</Large>
      </div>
    );
  };

  StatusIcons = () => {
    let { message } = this.props;
    // let unread = !message.draft && !message.read;

    return (
      <>
        {/* { unread ? <Icon className='axoblue bgTransparent'  glyph='icon-fontello-circle' 
        title= {getText('axoEntityidcode258', 'Ulæst')}
        /> : null } */}
        {message.replied ? (
          <Icon
            className="axoblue bgTransparent"
            glyph="icon-fontello-reply-1"
            title={getText("axoEntityidcode259", "Besvaret")}
          />
        ) : null}
      </>
    );
  };

  render() {
    var {
      message,
      userProfile,
      onRemove,
      onDelete,
      selected,
      date,
      shown,
      showImportant,
      onSelect,
    } = this.props;

    let unread = !message.draft && !message.read;

    var classes = classNames({
      "inbox-item": true,
      unread: unread,
    });

    var linkProps = {
      href: this.getPath("mailbox/mail"),
      onClick: this.handleClick,
      className: classes,
    };

    var type = shown || this.getType();

    var actionStyle =
      type === "sent" && !message.externalMailReceiver
        ? {
            visibility: "hidden",
            display: "inline-block",
            height: "30px",
            lineHeight: "30px",
            paddingLeft: "5px",
            paddingRight: "5px",
          }
        : {
            display: "inline-block",
            height: "30px",
            lineHeight: "30px",
            paddingLeft: "5px",
            paddingRight: "5px",
          };

    var importantStar = showImportant && (
      <>
        <Icon
          onClick={this.onImportantClicked}
          // MissingEntity
          title={message.important ? "Fjern markering" : "Marker som vigtig"}
          glyph={
            message.important
              ? "icon-fontello-star-1"
              : "icon-fontello-star-empty-1"
          }
        />
        &nbsp;&nbsp;
      </>
    );

    let readIcon = showImportant && !message.draft && (
      <>
        <Icon
          onClick={this.onToggleRead}
          // MissingEntity
          title={unread ? "Marker som læst" : "Marker som ulæst"}
          glyph={unread ? "icon-fontello-circle" : "icon-fontello-circle-empty"}
        />
      </>
    );

    var attachmentIcon = message.hasAttachments ? (
      <span>
        <Icon glyph="icon-fontello-attach" />
        &nbsp;&nbsp;&nbsp;
      </span>
    ) : null;
    var removeIcon = onRemove ? (
      <Icon
        title={getText("axoEntityidcode265", "Fjern fra mappe")}
        onClick={onRemove}
        glyph="icon-fontello-scissors axored"
      />
    ) : null;
    var deleteIcon = onDelete ? (
      <Icon
        title={getText("axoEntityidcode269", "Slet besked")}
        onClick={onDelete}
        glyph="icon-fontello-trash-1"
      />
    ) : null;

    var subject = LocalizationService.getMessageSubject(message);
    subject = StringService.enforceMaxLength(subject, 50);

    let PreviewImage = this.PreviewImage;
    let StatusIcons = this.StatusIcons;
    return (
      <div className="axoinboxitem" style={{ height: "90px", padding: "5px" }}>
        <ContextMenuTrigger id={"message-context-menu-" + message.id}>
          <a {...linkProps}>
            <Grid
              fluid
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                margin: "auto",
              }}
            >
              <Row className="nopaddingmailrow">
                <MediumOrLarger>
                  <Col
                    md={10}
                    sm={12}
                    className="nopadding flexbox-center-vertical"
                  >
                    <div
                      style={actionStyle}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <div
                        style={{ display: "inline-block" }}
                        onClick={this.onCheckBoxClicked}
                      >
                        {!!onSelect ? (
                          <AxoCheckbox checked={selected} readOnly />
                        ) : null}
                      </div>
                      &nbsp;
                      {importantStar}
                      {readIcon}
                    </div>
                    <PreviewImage
                      message={message}
                      userProfile={userProfile}
                    />
                    <div
                      className="axoinboxitemSubject"
                      style={{ display: "inline-block" }}
                    >
                      <div
                        className="axoblack"
                        style={unread ? { fontWeight: "bold" } : {}}
                      >
                        {this.renderInfoText(type)}
                      </div>
                      <div
                        className="axoblack"
                        style={unread ? { fontWeight: "bold" } : {}}
                      >
                        {this.renderSubject(subject)}
                      </div>
                      {this.renderExternalMailInfo(type)}
                      {this.renderBCCInfo()}
                    </div>
                  </Col>
                </MediumOrLarger>
                <SmallOrSmaller>
                  <Col
                    sm={12}
                    className="nopadding"
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      margin: "auto",
                    }}
                  >
                    <div
                      style={actionStyle}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {}
                      <div
                        style={{ display: "inline-block" }}
                        onClick={this.onCheckBoxClicked}
                      >
                        {!!onSelect ? <AxoCheckbox checked={selected} /> : null}
                      </div>
                      &nbsp;
                      {importantStar}
                      {readIcon}
                    </div>
                    <PreviewImage
                      message={message}
                      userProfile={userProfile}
                    />
                  </Col>
                  <Col
                    sm={12}
                    className="nopadding hidden-lg hidden-md"
                    style={{ paddingLeft: "10px", paddingRight: "5px" }}
                  >
                    <div
                      className="axoinboxitemSubject"
                      style={{ paddingLeft: "5px" }}
                    >
                      <div
                        className="axoblack"
                        style={unread ? { fontWeight: "bold" } : {}}
                      >
                        {this.getInfoText(type)}&nbsp;
                        <StatusIcons />
                      </div>
                      <Flexbox>
                        <div className="axoblack">
                          {unread ? (
                            <div style={{ fontWeight: "bold" }}>
                              {subject}&nbsp;
                              {/* <Icon className='axoblue bgTransparent' glyph='icon-fontello-circle'/> */}
                            </div>
                          ) : (
                            <>
                              {subject}
                              {message.replied && (
                                <>
                                  &nbsp;
                                  <Icon
                                    className="axoblue bgTransparent"
                                    glyph="icon-fontello-reply-1"
                                    title={getText(
                                      "axoEntityidcode259",
                                      "Besvaret"
                                    )}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div style={{ marginRight: "15px" }}></div>
                        {this.renderExternalMailInfo(type)}
                        {this.renderBCCInfo()}
                      </Flexbox>
                    </div>
                  </Col>
                </SmallOrSmaller>
                <Col md={2} xs={12} className="nopadding">
                  <div className="inbox-date text-right axoblack rightPadding">
                    {!!removeIcon || !!deleteIcon ? (
                      <div className="text-right">
                        {removeIcon}&ensp;&ensp;{deleteIcon}
                      </div>
                    ) : null}
                    <div
                      style={{
                        position: "relative",
                        top: 5,
                        paddingRight: "5px",
                      }}
                    >
                      {attachmentIcon}
                      {moment(date).format("L LT")}
                    </div>
                  </div>
                </Col>
              </Row>
            </Grid>
          </a>
        </ContextMenuTrigger>
      </div>
    );
  }
}

export default withRouter(InboxItem);
