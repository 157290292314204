import React from "react";
import PropTypes from "prop-types";
import AccountPlansTabView from "./AccountPlansTabView";

import {
  DataActions,
  RoutingService,
  ApiService,
  DataStore,
} from "../../services/AxoServices";

export default class AccountPlansContainer extends React.PureComponent {
  static propTypes = {
    financeAccountPlans: PropTypes.array.isRequired,
    standardFinanceAccountPlans: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedPlanId: 0,
      selectedStandardPlanId: 0,
      newPlanName: "",
      newAccountNumber: 0,
      newAccountName: "",
      creatingHeader: false,

      editingPlan: false,
      creatingSumAccount: false,
      sumIntervalStart: 0,
      sumIntervalEnd: 0,
      summedAccounts: [],

      confirmPlanDeletion: false,
      copyPlanInProgress: false,
      copyStandardPlanInProgress: false,
      editingAccountPlanSetup: false,
    };
    this.addingAccountPlan = false;
    this.createdAccountNumbers = new Set(); //Account numbers that have been created, but not yet loaded from the server
  }

  componentDidUpdate() {
    this.createdAccountNumbers = new Set();
  }

  onSelectPlan = (id) => {
    this.setState({ selectedPlanId: id || 0 });
  };

  onSelectStandardPlan = (id) => {
    this.setState({ selectedStandardPlanId: id || 0 });
  };

  onUpdateProperty = (propertyName, newValue) => {
    this.setState({ [propertyName]: newValue });
  };

  onCreatePlan = async () => {
    let { newPlanName } = this.state;
    if (!newPlanName) {
      return;
    }

    try {
      let response = await DataActions.createFinanceAccountPlan({
        name: newPlanName,
      });

      if (response.ok) {
        let newPlan = await response.json();
        this.setState({
          newPlanName: "",
          selectedPlanId: newPlan.id,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  onDeletePlan = async () => {
    this.setState({
      confirmPlanDeletion: true,
    });
  };

  doDeletePlan = async () => {
    let { selectedPlanId } = this.state;
    try {
      let response = await ApiService.deleteFinanceAccountPlan(selectedPlanId);
      if (!response.ok) {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
    DataStore.removeArrayObject(selectedPlanId, "financeAccountPlans");
    this.setState({
      editingPlan: false,
      confirmPlanDeletion: false,
    });
    return true;
  };

  cancelDeletePlan = () => {
    this.setState({
      confirmPlanDeletion: false,
    });
  };

  onCreateHeader = () => {
    this.setState({ creatingHeader: true });
  };

  onCancelCreate = () => {
    this.setState({
      creatingHeader: false,
      creatingSumAccount: false,
      newAccountNumber: 0,
      newAccountName: "",
      sumIntervalStart: 0,
      sumIntervalEnd: 0,
      summedAccounts: [],
    });
  };

  doCreateAccount = async (type) => {
    let {
      newAccountName,
      newAccountNumber,
      selectedPlanId,
      sumIntervalStart,
      sumIntervalEnd,
      summedAccounts,
    } = this.state;
    if (!newAccountName || !newAccountNumber || !selectedPlanId) {
      return;
    }
    if (
      type === "Sum" &&
      sumIntervalEnd > 0 &&
      sumIntervalEnd <= sumIntervalStart
    ) {
      return;
    }

    try {
      let response = await DataActions.createFinanceAccount({
        type: type,
        number: newAccountNumber,
        name: newAccountName,
        financeAccountPlanId: selectedPlanId,
        sumIntervalStart,
        sumIntervalEnd,
        summedAccounts,
      });
      if (response.ok) {
        this.setState({
          creatingHeader: false,
          creatingSumAccount: false,
          newAccountName: "",
          newAccountNumber: 0,
          sumIntervalStart: 0,
          sumIntervalEnd: 0,
          summedAccounts: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getSelectedPlan = () => {
    let { financeAccountPlans } = this.props;
    let { selectedPlanId } = this.state;
    let selectedPlan = financeAccountPlans.find((f) => f.id === selectedPlanId);
    return selectedPlan;
  };

  getSelectedAccounts = () => {
    let selectedPlan = this.getSelectedPlan();
    if (!selectedPlan) {
      return;
    }
    return selectedPlan.accounts;
  };

  getAccountFromId = (accountId) => {
    return this.getSelectedAccounts().find((a) => a.id === accountId);
  };

  updateAccountProperty = (accountId, propertyName, value) => {
    let account = this.getAccountFromId(accountId);
    if (!account) {
      return;
    }

    let newAccount = {
      ...account,
      [propertyName]: value,
    };

    DataActions.updateFinanceAccount(newAccount);
  };

  addAccountToHeader = async (minNumber) => {
    //Can only add one account at a time
    if (this.addingAccountPlan) {
      return;
    }
    this.addingAccountPlan = true;
    let { selectedPlanId } = this.state;

    let accounts = this.getSelectedAccounts();
    let number = minNumber + 1;

    let numberAvailable = (numberVar) => {
      return (
        !this.createdAccountNumbers.has(numberVar) &&
        !accounts.find((a) => a.number === numberVar)
      );
    };

    while (!numberAvailable(number)) {
      number = number + 1;
    }

    this.createdAccountNumbers.add(number);

    try {
      await DataActions.createFinanceAccount({
        type: "Standard",
        financeAccountPlanId: selectedPlanId,
        number,
      });
      this.addingAccountPlan = false;
    } catch (error) {
      console.log(error.message);
      this.addingAccountPlan = false;
    }
  };

  onDeleteAccount = (account) => {
    return DataActions.deleteFinanceAccount(account);
  };

  onStopEditing = () => {
    this.setState({
      editingPlan: false,
      creatingHeader: false,
      creatingSumAccount: false,
      confirmPlanDeletion: false,
    });
  };

  updateSelectedPlanProperty = (propertyName, value) => {
    let selectedPlan = this.getSelectedPlan();
    if (!selectedPlan) {
      return;
    }
    DataActions.updateFinanceAccountPlan({
      ...selectedPlan,
      [propertyName]: value,
    });
  };

  updateSelectedPlanPropertyMap = async (propertyMap) => {
    let selectedPlan = this.getSelectedPlan();
    if (!selectedPlan) {
      return Promise.reject("No plan selected");
    }
    let response = await DataActions.updateFinanceAccountPlan({
      ...selectedPlan,
      ...propertyMap,
    });
    return response.ok;
  };

  onCopyPlan = async () => {
    let { selectedPlanId } = this.state;
    try {
      this.setState({ copyPlanInProgress: true });
      let response = await DataActions.copyFinanceAccountPlan(selectedPlanId);
      if (!response.ok) {
        return;
      }
      let newPlan = await response.json();
      this.setState({ selectedPlanId: newPlan.id });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ copyPlanInProgress: false });
    }
  };

  onCopyStandardPlan = async () => {
    let { selectedStandardPlanId } = this.state;
    try {
      this.setState({ copyStandardPlanInProgress: true });
      let response = await DataActions.copyFinanceAccountPlan(
        selectedStandardPlanId
      );
      if (!response.ok) {
        return;
      }
      let newPlan = await response.json();
      this.setState({
        selectedPlanId: newPlan.id,
        selectedStandardPlanId: 0,
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ copyStandardPlanInProgress: false });
    }
  };

  getActiveTab = (props) => {
    let path = props.location.pathname.toLowerCase();
    if (path.includes("accounts")) {
      return "Accounts";
    }
    if (path.includes("setup")) {
      return "Setup";
    }
    return "Accounts";
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(RoutingService.getPath("AccountPlans/" + eventKey));
  };

  render() {
    return (
      <AccountPlansTabView
        {...this.props}
        {...this.state}
        activeTab={this.getActiveTab(this.props)}
        handleNavigation={this.handleNavigation}
        onSelectPlan={this.onSelectPlan}
        onUpdateProperty={this.onUpdateProperty}
        onCreatePlan={this.onCreatePlan}
        onCopyPlan={this.onCopyPlan}
        onDeletePlan={this.onDeletePlan}
        cancelDeletePlan={this.cancelDeletePlan}
        doDeletePlan={this.doDeletePlan}
        onCreateHeader={this.onCreateHeader}
        onCancelCreate={this.onCancelCreate}
        doCreateAccount={this.doCreateAccount}
        updateAccountProperty={this.updateAccountProperty}
        addAccountToHeader={this.addAccountToHeader}
        onDeleteAccount={this.onDeleteAccount}
        onStopEditing={this.onStopEditing}
        updateSelectedPlanProperty={this.updateSelectedPlanProperty}
        updateSelectedPlanPropertyMap={this.updateSelectedPlanPropertyMap}
        onSelectStandardPlan={this.onSelectStandardPlan}
        onCopyStandardPlan={this.onCopyStandardPlan}
      />
    );
  }
}
