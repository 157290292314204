import CountryRegionData from "../utilities/CountrySelect/source-data.js";

const C = {
  REGION_LIST_DELIMITER: "|",
  SINGLE_REGION_DELIMITER: "~",
};

export default {
  getRegionName(country, regionShortCode) {
    if (!country || !regionShortCode) {
      return "";
    }
    const searchIndex = 1;
    const regions = CountryRegionData.find((i) => {
      return i[searchIndex] === country;
    });

    var regionNames = regions[2]
      .split(C.REGION_LIST_DELIMITER)
      .map((regionPair) => {
        let [regionName, regionShortCode = null] = regionPair.split(
          C.SINGLE_REGION_DELIMITER
        );
        return { regionName, regionShortCode };
      });

    var region = regionNames.find(
      (region) => region.regionShortCode === regionShortCode
    );
    return region ? region.regionName : "";
  },
};
