import React from "react";

import ApiService from "../../services/DataAccess/ApiService";
import DataActions from "../../services/DataAccess/DataActions";
import ImageService from "../../services/ImageService";
import MicroTinyMCE from "../../utilities/Editors/MicroTinyMCE";
import * as $ from "jquery";

import {
  Row,
  Col,
  Grid,
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class PresentationCreateView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploadingImage: false,
      uploadingVideo: false,
      processedImages: {},
      editingImages: new Set(),
    };
  }
  //Props
  //presentation
  renderPresentationForm() {
    return (
      <div style={{ maxWidth: "700px", margin: "auto" }}>
        <Form>{this.renderItems()}</Form>
      </div>
    );
  }

  getItemType = (item) => {
    switch (item.type) {
      case 0:
        return "title";
      case 1:
        return "description";
      case 2:
        return "image";
      case 3:
        return "video";
      case 4:
        return "emoji";
      default:
        return "";
    }
  };

  renderItemFormContent = (item, index) => {
    if (item.locked || item.type > 1) {
      return this.renderItemPreview(item, index);
    }
    return this.renderItemEditForm(item, index);
  };

  onEffect = (imageId, effect) => {
    var selector = "#img-" + imageId;
    var canvas = ImageService.photoJShop($(selector), {
      effect: effect,
      replace: false,
    });
    this.saveProcessedImage(imageId, canvas);
  };

  onColorEffect = (imageId, colorEffect) => {
    var selector = "#img-" + imageId;
    var canvas = ImageService.photoJShop($(selector), {
      color: colorEffect,
      replace: false,
    });
    this.saveProcessedImage(imageId, canvas);
  };

  saveProcessedImage = (imageId, canvas) => {
    var results = Object.assign({}, this.state.processedImages);
    results[imageId] = canvas;
    this.setState({ processedImages: results });
  };

  renderItemPreview = (item, index) => {
    var images = this.state.processedImages;
    switch (item.type) {
      case 0: //Title
        return (
          <div className="text-center frontPageaxolexdiv Bannecolor">
            <b>{item.title}</b>
          </div>
        );
      case 1: //Description
        return (
          <div
            style={{ paddingLeft: "5px", paddingRight: "2px" }}
            className="text-left"
          >
            <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
            {/* <AxoFrame
              bottomPadding={20}
              content={item.description}
            /> */}
          </div>
        );
      //PhotoJShop only works on images that are allowed to use their natural size, so a hidden natural sized image
      //is added below the visible one.
      case 2: //Image
        //Handle cases where the image is missing for some reason
        if (!item.attachment) {
          return (
            <div
              className="text-center"
              style={{
                maxWidth: "650px",
                margin: "auto",
                paddingBottom: "10px",
              }}
            >
              <img alt="" src="default" style={{ maxWidth: "100%" }} />
            </div>
          );
        }

        var url = images[item.attachment.id]
          ? images[item.attachment.id].toDataURL()
          : "";
        return (
          <div
            className="text-center"
            style={{ maxWidth: "650px", margin: "auto", paddingBottom: "10px" }}
          >
            {this.renderImageEditingToolbar(index, item.attachment.id)}
            <img
              alt=""
              src={url || ApiService.getFileInlinePath(item.attachment.id)}
              style={{ maxWidth: "100%" }}
            />
            <img
              alt=""
              id={"img-" + item.attachment.id}
              src={url || ApiService.getFileInlinePath(item.attachment.id)}
              style={{ display: "none" }}
            />
          </div>
        );
      case 3: //Video
        return (
          <div
            className="text-center"
            style={{ maxWidth: "750px", margin: "auto", paddingBottom: "10px" }}
          >
            <video
              key={item.attachment.id}
              id="viewport"
              ref={(c) => (this.video = c)}
              width="100%"
              height="auto"
              controls
            >
              <source
                src={ApiService.getFileInlinePath(item.attachment.id)}
                type="video/mp4"
              />
              <AxoLocal
                entity="Yourbrowserdoesnotsupportvideoplayback"
                defaultValue={" Din browser understøtter ikke videoafspilning"}
              />
            </video>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  renderImageEditingToolbar = (index, imageId) => {
    if (!this.state.editingImages.has(imageId)) {
      return null;
    }
    return (
      <div style={{ paddingTop: "0px", paddingBottom: "10px" }}>
        <div>
          <ButtonGroup>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onEffect.bind(this, imageId, "blur")}
            >
              <img alt="" src="/imgs/app/Blur.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onEffect.bind(this, imageId, "sharpen")}
            >
              {" "}
              <img alt="" src="/imgs/app/Sharpen.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onEffect.bind(this, imageId, "emboss")}
            >
              <img alt="" src="/imgs/app/Emboss.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onEffect.bind(this, imageId, "lighten")}
            >
              <img alt="" src="/imgs/app/Lighten.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onEffect.bind(this, imageId, "darken")}
            >
              <img alt="" src="/imgs/app/Darken.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onEffect.bind(this, imageId, "blur")}
            >
              <img alt="" src="/imgs/app/Blur1.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onEffect.bind(this, imageId, "hard-edge")}
            >
              <img alt="" src="/imgs/app/Hardedge.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onColorEffect.bind(this, imageId, "b&w")}
            >
              <img alt="" src="/imgs/app/BW.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onColorEffect.bind(this, imageId, "sepia")}
            >
              <img alt="" src="/imgs/app/Sepia.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onColorEffect.bind(this, imageId, "vintage")}
            >
              <img alt="" src="/imgs/app/Vintage.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onColorEffect.bind(this, imageId, "recolor")}
            >
              <img alt="" src="/imgs/app/Recolor.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onColorEffect.bind(this, imageId, "red")}
            >
              {" "}
              <img alt="" src="/imgs/app/Red.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onColorEffect.bind(this, imageId, "blue")}
            >
              {" "}
              <img alt="" src="/imgs/app/Blue.png" />
            </Button>
            <Button
              className="Lex-button-1xlink"
              onClick={this.onColorEffect.bind(this, imageId, "green")}
            >
              {" "}
              <img alt="" src="/imgs/app/Green.png" />
            </Button>
          </ButtonGroup>
        </div>
        <div className="text-right" style={{ paddingBottom: "10px" }}>
          <ButtonGroup>
            <Button
              style={{ marginTop: "15px", marginBottom: "15px" }}
              className="Lex-button-1xlink"
              onClick={this.onSaveEditedImage.bind(this, index)}
            >
              <AxoLocal
                entity="CalendarEventBoxFormonSave"
                defaultValue={"Gem"}
              />
            </Button>
            <Button
              style={{ marginTop: "15px", marginBottom: "15px" }}
              className="Lex-button-1xlink"
              onClick={this.onStopImageEditing.bind(this, imageId)}
            >
              <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
            </Button>
          </ButtonGroup>
        </div>

        {/* Blur
          Sharpen(&#10024;)
          Emboss (&#9860;)
          Lighten (&#9967;)
          Darken (&#9728;)
          Edge-enhance

          Edge-detect
          Hard-edge
          Laplace
          B&W
          Sepia
          Vintage

          Recolor
          Red
          Blue
          Green */}
      </div>
    );
  };

  renderItemEditForm = (item, index) => {
    switch (item.type) {
      case 0: //Title
        return (
          <FormGroup controlId="title" className="text-center">
            <ControlLabel className="text-center">
              <AxoLocal
                entity="CalendarEventBoxFormtitle"
                defaultValue="Titel"
              />
            </ControlLabel>
            <InputGroup>
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-window" />
              </InputGroup.Addon>
              <FormControl
                type="text"
                value={item.title}
                onChange={this.props.onChangeItemValue.bind(
                  this,
                  index,
                  "title"
                )}
              />
            </InputGroup>
          </FormGroup>
        );
      case 1: //Description
        return (
          <FormGroup controlId="description" className="text-center">
            <div style={{ marginLeft: "30px", marginRight: "30px" }}>
              <MicroTinyMCE
                text={item.description}
                locale={this.props.locale}
                height={200}
                onTextChange={this.props.onItemDescriptionChange.bind(
                  this,
                  index
                )}
              />
            </div>
          </FormGroup>
        );
      default:
        return <div></div>;
    }
  };

  onEditImage = (imageId) => {
    var editingImages = new Set(this.state.editingImages);
    editingImages.add(imageId);
    this.setState({ editingImages });
  };

  renderItemEditButton = (item, index) => {
    if (item.type < 2) {
      if (!item.locked) {
        return (
          <Button
            onClick={this.props.onSetItemLockStatus.bind(this, index, true)}
            className="Lex-button-1xlink"
          >
            <AxoLocal
              key="save"
              entity="presentationMarketing5"
              defaultValue="Gem"
            />
            &nbsp;&nbsp;
            <Icon glyph="icon-fontello-floppy-1" />
            &nbsp;&nbsp;
          </Button>
        );
      }
      return (
        <Button
          onClick={this.props.onSetItemLockStatus.bind(this, index, false)}
          className="Lex-button-1xlink"
        >
          <Icon glyph="icon-fontello-edit-3" />
          &nbsp;&nbsp;
          <AxoLocal
            key="Rediger"
            entity="presentationMarketing6"
            defaultValue="Rediger"
          />
          &nbsp;&nbsp;
          <Icon glyph="icon-fontello-edit-3" />
          &nbsp;&nbsp;
        </Button>
      );
    }

    if (item.type === 2) {
      //Image editing does not work on iOS
      var iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      return (
        <span>
          {!iOS &&
          !!item.attachment &&
          !this.state.editingImages.has(item.attachment.id) ? (
            <Button
              onClick={this.onEditImage.bind(this, item.attachment.id)}
              className="Lex-button-1xlink"
            >
              &nbsp;&nbsp;&nbsp;
              <Icon glyph="icon-fontello-edit-3" />
              &nbsp;&nbsp;
              <AxoLocal
                entity="presentationMarketing30"
                defaultValue="Rediger"
              />
              &nbsp;&nbsp;&nbsp;
            </Button>
          ) : null}
          <Button
            onClick={this.onChangeImage.bind(this, index)}
            className="Lex-button-1xlink"
          >
            &nbsp;&nbsp;&nbsp;
            <AxoLocal
              key="AchangeImage"
              entity="presentationMarketing14"
              defaultValue="Skift"
            />
            &nbsp;&nbsp;&nbsp;
            <input
              type="file"
              accept="image/*"
              id={"changeImage-" + index}
              onChange={this.onSelectChangeImage.bind(this, index)}
              style={{ display: "none" }}
            />
          </Button>
        </span>
      );
    }
    return (
      <Button
        onClick={this.onChangeVideo.bind(this, index)}
        className="Lex-button-1xlink"
      >
        &nbsp;&nbsp;&nbsp;
        <AxoLocal
          key="BchangeVideo"
          entity="presentationMarketing15"
          defaultValue="Skift"
        />
        &nbsp;&nbsp;&nbsp;
        <input
          type="file"
          accept="video/*"
          id={"changeVideo-" + index}
          onChange={this.onSelectChangeVideo.bind(this, index)}
          style={{ display: "none" }}
        />
      </Button>
    );
  };

  onStopImageEditing = (imageId) => {
    var editingImages = new Set(this.state.editingImages);
    editingImages.delete(imageId);

    var processedImages = Object.assign({}, this.state.processedImages);
    delete processedImages[imageId];

    this.setState({
      editingImages,
      processedImages,
    });
  };

  onSaveEditedImage = (index) => {
    var item = this.props.presentation.items[index];
    var canvas = this.state.processedImages[item.attachment.id];
    if (!canvas) {
      this.onStopImageEditing(item.attachment.id);
      return;
    }
    var currentFile = item.attachment;
    canvas.toBlob((blob) => {
      var newFile = new File([blob], currentFile.name, { type: "image/png" });
      ApiService.uploadFileObjects([newFile])
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject("Image could not be uploaded");
        })
        .then((fileResult) => {
          this.props.onChangeAttachment(index, fileResult[0]);
          this.onStopImageEditing(currentFile.id);
        })
        .catch((reason) => {
          console.log(reason);
          this.onStopImageEditing(currentFile.id);
        });
    });
  };

  onChangeImage = (index) => {
    $("#changeImage-" + index).trigger("click");
  };

  onChangeVideo = (index) => {
    $("#changeVideo-" + index).trigger("click");
  };

  onSelectChangeImage = (index, event) => {
    var control = event.target;
    var file = event.target.files[0];
    if (!file.type.includes("image")) {
      return;
    }
    if (file.size / Math.pow(1024, 2) > 10) {
      this.props.onShowImageFileSizeWarning();
      return;
    }
    this.setState({ uploadingImage: true });
    ImageService.enforceImageMaxSize(file, 750, 750).then((fileResult) => {
      ApiService.uploadFileObjects([fileResult]).then((response) => {
        this.setState({ uploadingImage: false });
        control.value = ""; //Allows for selecting the same file twice
        if (response.status === 200) {
          response.json().then((addedFiles) => {
            this.props.onChangeAttachment(index, addedFiles[0]);
          });
        }
      });
    });
  };

  onSelectChangeVideo = (index, event) => {
    var file = event.target.files[0];
    if (!file.type.includes("video")) {
      return;
    }
    if (file.size / Math.pow(1024, 2) > 50) {
      this.props.onShowVideoFileSizeWarning();
      return;
    }
    this.changeAttachment(index, event.target.files, "video");
  };

  changeAttachment = (index, files, type) => {
    this.setState(
      type === "image" ? { uploadingImage: true } : { uploadingVideo: true }
    );
    DataActions.uploadDocuments(files).then((response) => {
      this.setState(
        type === "image" ? { uploadingImage: false } : { uploadingVideo: false }
      );
      if (response.status === 200) {
        response.json().then((addedFiles) => {
          this.props.onChangeAttachment(index, addedFiles[0]);
        });
      }
    });
  };

  renderItems = () => {
    let { items } = this.props.presentation;
    let { insertingAt, onInsertAt } = this.props;

    let itemNodes = [];
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      itemNodes.push(
        <div key={i}>
          {insertingAt === i ? this.renderButtonToolbar(insertingAt) : null}
          <div
            className="text-center"
            style={{ marginTop: "15px", marginBottom: "15px" }}
          >
            <ButtonGroup>
              {this.renderItemEditButton(item, i)}
              <Button
                role="button"
                onClick={this.props.onRemoveItem.bind(this, i)}
                className="Lex-button-1xlink"
              >
                &nbsp;&nbsp;
                <Icon
                  className="AxoNavigationheightIcon1"
                  glyph="icon-fontello-trash-1"
                />
              </Button>
              {insertingAt === -1 ? (
                <Button
                  role="button"
                  onClick={onInsertAt.bind(this, i)}
                  className="Lex-button-1xlink"
                >
                  <AxoLocal entity="onInsertAta" defaultValue="Indsæt" />
                </Button>
              ) : null}
            </ButtonGroup>
          </div>
          {this.renderItemFormContent(item, i)}
        </div>
      );
    }
    return itemNodes;
  };

  onUploadImage = () => {
    $("#uploadImage").trigger("click");
  };

  onSelectImage = (event) => {
    var control = event.target;
    var file = event.target.files[0];
    if (!file.type.includes("image")) {
      return;
    }
    if (file.size / Math.pow(1024, 2) > 10) {
      this.props.onShowImageFileSizeWarning();
      return;
    }
    this.setState({ uploadingImage: true });
    ImageService.enforceImageMaxSize(file, 750, 750).then((fileResult) => {
      ApiService.uploadFileObjects([fileResult]).then((response) => {
        this.setState({ uploadingImage: false });
        control.value = ""; //Allows for selecting the same file twice
        if (response.status === 200) {
          response.json().then((addedFiles) => {
            this.props.onAddImageItem(addedFiles[0], this.props.insertingAt);
          });
        }
      });
    });
  };

  onUploadVideo = () => {
    $("#uploadVideo").trigger("click");
  };

  onSelectVideo = (event) => {
    var file = event.target.files[0];
    if (!file.type.includes("video")) {
      return;
    }
    if (file.size / Math.pow(1024, 2) > 50) {
      this.props.onShowVideoFileSizeWarning();
      return;
    }
    this.setState({ uploadingVideo: true });
    DataActions.uploadDocuments(event.target.files).then((response) => {
      this.setState({ uploadingVideo: false });
      if (response.status === 200) {
        response.json().then((addedFiles) => {
          this.props.onAddVideoItem(addedFiles[0], this.props.insertingAt);
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    var newItems = this.props.presentation.items;
    var items = prevProps.presentation.items;

    if (newItems.length > items.length) {
      //Only scroll if the bottommost item has changed
      if (items.length > 0) {
        if (newItems[newItems.length - 1].id === items[items.length - 1].id) {
          return;
        }
      }

      var scrollElement = document.getElementById("buttonToolbar");
      scrollElement.scrollIntoView(false);
      window.scrollBy(0, 30);
    }
  }

  addItem = (type, index) => {
    this.props.onAddItem(type, index);
  };

  uploadDisabled = () => {
    return this.state.uploadingImage || this.state.uploadingVideo;
  };

  renderButtonToolbar = (insertingAt) => {
    return (
      <div
        id="buttonToolbar"
        style={{ paddingTop: "10px", paddingBottom: "55px" }}
        className="btn-group btn-group-justified"
        role="group"
      >
        <div className="btn-group" role="group">
          <Button
            onClick={this.addItem.bind(this, 0, insertingAt)}
            className="Lex-button-1xlink AxoNavigationButtonheight1 AxoNavigationfonttaxt"
          >
            <AxoLocal
              entity="CalendarEventBoxFormtitle"
              defaultValue={"Titel"}
            />
          </Button>
        </div>
        <div className="btn-group" role="group">
          <Button
            onClick={this.onUploadImage}
            disabled={this.uploadDisabled()}
            className="Lex-button-1xlink AxoNavigationButtonheight1"
          >
            {this.state.uploadingImage ? (
              <img
                alt=""
                src="/imgs/app/loading.gif"
                className="img-circle"
                width="20"
                height="20"
              />
            ) : (
              <span className="AxoNavigationheightIcon icon-fontello-camera-6" />
            )}
          </Button>
        </div>
        <div className="btn-group" role="group">
          <Button
            onClick={this.onUploadVideo}
            disabled={this.uploadDisabled()}
            className="Lex-button-1xlink AxoNavigationButtonheight1"
          >
            {this.state.uploadingVideo ? (
              <img
                alt=""
                src="/imgs/app/loading.gif"
                className="img-circle"
                width="20"
                height="20"
              />
            ) : (
              <span className="AxoNavigationheightIcon icon-fontello-videocam-2" />
            )}
          </Button>
        </div>
        <div className="btn-group" role="group">
          <Button
            onClick={this.addItem.bind(this, 1, insertingAt)}
            className="Lex-button-1xlink AxoNavigationButtonheight1"
          >
            <span className="AxoNavigationheightIcon icon-fontello-edit-3" />
          </Button>
        </div>
        {/* Show cancel button if inserting at a valid numeric location */}
        {insertingAt > -1 ? (
          <div className="btn-group" role="group">
            <Button
              onClick={this.props.onCancelInsertion}
              className="Lex-button-1xlink AxoNavigationButtonheight1 AxoNavigationfonttaxt"
            >
              <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
            </Button>
          </div>
        ) : (
          <div className="btn-group" role="group">
            <Button
              onClick={this.props.onSave}
              className="Lex-button-1xlink AxoNavigationButtonheight1 AxoNavigationfonttaxt"
            >
              <AxoLocal entity="ADDViewCreate" defaultValue="Opret" />
            </Button>
          </div>
        )}
      </div>
    );
  };

  renderWarningMessages = () => {
    if (this.props.showImageFileSizeWarning) {
      return (
        <span className="axored">
          <AxoLocal
            key="BchangeVideo"
            entity="presentationMarketing17"
            defaultValue="Billeder må ikke fylde mere end"
          />
          10 mb
        </span>
      );
    }
    if (this.props.showVideoFileSizeWarning) {
      return (
        <span className="axored">
          <AxoLocal
            key="BchangeVideo"
            entity="presentationMarketing18"
            defaultValue="Videoer må ikke fylde mere end"
          />{" "}
          50 mb
        </span>
      );
    }
    return null;
  };

  render() {
    return (
      <div id="testid">
        <Grid fluid style={{ paddingTop: "25px", paddingButtom: "25px" }}>
          <Row style={{ minHeight: "700px" }}>
            <Col
              className="col-xs-12 nopadding"
              style={{ height: "100%" }}
              xs={12}
              sm={8}
              smOffset={2}
            >
              {this.renderPresentationForm()}
              {this.renderWarningMessages()}
              {this.renderButtonToolbar(-1)}
            </Col>
          </Row>
          <input
            type="file"
            accept="image/*"
            id={"uploadImage"}
            onChange={this.onSelectImage}
            style={{ display: "none" }}
          />
          <input
            type="file"
            accept="video/*"
            id={"uploadVideo"}
            onChange={this.onSelectVideo}
            style={{ display: "none" }}
          />
        </Grid>
      </div>
    );
  }
}
