import LocalizationService from "./LocalizationService";
// var Globalize = require( "globalize" );
// Globalize.load( require( "cldr-data" ).entireSupplemental() );
// Globalize.load( require( "cldr-data" ).entireMainFor( "en", "da", 'zh' ));
import numeral from "numeral";
import "numeral/locales/da-dk";
import "numeral/locales/chs";

let locale = "da-DK";

const getParserLocale = (serviceLocale) => {
  switch (serviceLocale.toLowerCase()) {
    case "enus":
      return "en-US";
    case "da":
      return "da-DK";
    case "ch":
      return "zh-CN";
    default:
      return "da-DK";
  }
};

const getNumeralLocale = (serviceLocale) => {
  switch (serviceLocale.toLowerCase()) {
    case "enus":
      return "en";
    case "da":
      return "da-dk";
    case "ch":
      return "chs";
    default:
      return "da-dk";
  }
};

const toLocaleSupported = !!(
  typeof Intl == "object" &&
  Intl &&
  typeof Intl.NumberFormat == "function"
);

LocalizationService.subscribeToLocale("NumberService", (serviceLocale) => {
  locale = toLocaleSupported
    ? getParserLocale(serviceLocale)
    : getNumeralLocale(serviceLocale);
});

export default {
  formatDecimal(value) {
    if (isNaN(value)) {
      return "NaN";
    }

    // let formatter = Globalize(locale).numberFormatter({
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2
    // });
    // return formatter(value || 0);

    let number = value || "0";
    if (toLocaleSupported) {
      return parseFloat(number).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      numeral.locale(locale);
      return numeral(number).format("0,0.00");
    }
  },

  formatWithoutDecimals(value) {
    if (isNaN(value)) {
      return "NaN";
    }

    let number = value || "0";
    if (toLocaleSupported) {
      return parseFloat(number).toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      numeral.locale(locale);
      return numeral(number).format("0,0");
    }
  },

  formatMonth(value) {
    if (isNaN(value)) {
      return "NaN";
    }

    let number = value || "0";
    if (toLocaleSupported) {
      return parseFloat(number).toLocaleString(locale, {
        minimumIntegerDigits: 2, 
      });
    } else {
      return value;
    }
  }
};
