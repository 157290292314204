import React from "react";
import {
  SmallOrSmaller,
  MediumOrLarger,
  Large,
  MediumOrSmaller,
} from "../../utilities/Responsive";
import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";

import {
  DataStore,
  // AuthorizationService,
} from "../../services/AxoServices";

import {
  LexNavButton,
  withRouter,
  AxoLocal,
  Icon,
} from "../../utilities/LexUtilities";

class AccountingLayoutView extends React.PureComponent {
  handleNavigation = (tabKey) => {
    let { match } = this.props;
    let routeKey = tabKey.charAt(0).toUpperCase() + tabKey.slice(1);
    this.props.history.push(match.path + "/" + routeKey);
  };

  getEventKeyFromRoute = () => {
    let path = this.props.location.pathname.toLowerCase();

    if (path.includes("drafts")) {
      return "Drafts";
    }
    if (path.includes("result")) {
      return "Result";
    }
    if (path.includes("incoming")) {
      return "Incoming";
    }
    if (path.includes("bookkeeping")) {
      return "Bookkeeping";
    }
    if (path.includes("postings")) {
      return "Postings";
    }
    if (path.includes("moms")) {
      return "Moms";
    }
    if (path.includes("vat")) {
      return "Vat";
    }
    if (path.includes("setup")) {
      return "Setup";
    }
    if (path.includes("bank")) {
      return "Bank";
    }
    if (path.includes("report")) {
      return "Report";
    }
    if (path.includes("accountplan")) {
      return "AccountPlan";
    }
    return "Result";
  };

  renderLargeScreenNavigationBar = () => {
    let {
      showDropdowns,
      // userProfile
    } = this.props;

    let activeTab = this.getEventKeyFromRoute();

    // let userType = AuthorizationService.getUserType(userProfile);

    return (
      <MediumOrLarger>
        <Grid fluid className="axo-nav-lg" id="noprint">
          {!showDropdowns ? (
            <Row>
              <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{ borderRadius: "0px", paddingTop: "0px" }}
                >
                  <LexNavButton
                    className="axonavTab axonavTabcolora borderright"
                    ls
                    glyph="icon-fontello-download-2"
                    eventKey="Result"
                    activeTab={activeTab}
                    onClick={this.handleNavigation}
                  >
                    <AxoLocal
                      entity="axoidcode171"
                      defaultValue={"Rapporter"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab  axonavTabcolorb borderright Addicon"
                    ls
                    glyph="icon-fontello-plus-3"
                    eventKey="Incoming"
                    activeTab={activeTab}
                    onClick={this.handleNavigation}
                  >
                    <Large>
                      <AxoLocal
                        entity="axoEntityidcode176"
                        defaultValue={"Ubehandlede bilag"}
                      />
                    </Large>
                    <MediumOrSmaller>
                      <AxoLocal
                        entity="CaseDataTableFixedlabelAddedToCasedocuments"
                        defaultValue={"Bilag"}
                      />
                    </MediumOrSmaller>
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorc borderright Addicon"
                    ls
                    glyph="icon-fontello-plus-3"
                    eventKey="Drafts"
                    activeTab={activeTab}
                    onClick={this.handleNavigation}
                  >
                    <Large>
                      <AxoLocal
                        entity="updategetAccountName12"
                        defaultValue={"Bogføringskladde"}
                      />
                    </Large>
                    <MediumOrSmaller>
                      <AxoLocal
                        entity="CaseEditInlineprioritydraft"
                        defaultValue={"Kladde"}
                      />
                    </MediumOrSmaller>
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolord borderright"
                    ls
                    glyph="icon-fontello-cog-5"
                    eventKey="Bank"
                    activeTab={activeTab}
                    onClick={this.handleNavigation}
                  >
                    <Large>
                      <AxoLocal
                        entity="axoidcode13"
                        defaultValue={"Bankafstemning"}
                      />
                    </Large>
                    <MediumOrSmaller>
                      <AxoLocal
                        entity="AccountingTabViewEntity24"
                        defaultValue={"Bank"}
                      />
                    </MediumOrSmaller>
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolore  borderright"
                    ls
                    glyph="icon-fontello-list-alt"
                    eventKey="Bookkeeping"
                    activeTab={activeTab}
                    onClick={this.handleNavigation}
                  >
                    <AxoLocal entity="axoidcode81" defaultValue={"Journal"} />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorf borderright"
                    ls
                    glyph="icon-fontello-list-1"
                    eventKey="Postings"
                    activeTab={activeTab}
                    onClick={this.handleNavigation}
                  >
                    <AxoLocal
                      entity="axoidcode82"
                      defaultValue={"Kontooversigt"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorg borderright"
                    ls
                    glyph="icon-fontello-chart-pie-3"
                    eventKey="Vat"
                    activeTab={activeTab}
                    onClick={this.handleNavigation}
                  >
                    <AxoLocal
                      entity="invoiPaymentattheSubtotalVAT"
                      defaultValue={"Moms"}
                    />
                  </LexNavButton>
                  {/* <LexNavButton className='axonavTab borderright' ls glyph='icon-fontello-chart-pie-3' eventKey='Moms' activeTab={activeTab} onClick={this.handleNavigation}>
                  <AxoLocal entity='invoiPaymentattheSubtotalVAT' defaultValue={'Moms'}/>(Old)
                </LexNavButton> */}
                  {/* { userProfile.userType === 'Admin' && (
                  <LexNavButton className='axonavTab borderright' ls glyph='icon-fontello-chart-pie-3' eventKey='VatV2' activeTab={activeTab} onClick={this.handleNavigation}>
                    <AxoLocal entity='invoiPaymentattheSubtotalVAT' defaultValue={'Moms'}/> V2
                  </LexNavButton>
                )} */}
                  <LexNavButton
                    className="axonavTab axonavTabcolorh borderright"
                    ls
                    glyph="icon-fontello-cog-5"
                    eventKey="Setup"
                    activeTab={activeTab}
                    onClick={this.handleNavigation}
                  >
                    <AxoLocal
                      entity="ClientSetupViewSetup"
                      defaultValue={"Opsætning"}
                    />
                  </LexNavButton>
                  {/* <LexNavButton className='axonavTab borderright' ls glyph='icon-fontello-cog-5' eventKey='Setup' activeTab={activeTab} onClick={this.handleNavigation}>
                  Regnskabsår
                </LexNavButton>
                <LexNavButton className='axonavTab borderright' ls glyph='icon-fontello-cog-5' eventKey='AccountPlan' activeTab={activeTab} onClick={this.handleNavigation}>
                  <AxoLocal entity='AccountingTabViewEntity27' defaultValue={'Kontoplan'}/> 
                </LexNavButton> */}

                  {/* { userType === 'Admin' && ( */}
                  <LexNavButton
                    className="axonavTab axonavTabcolori borderright"
                    ls
                    glyph="icon-fontello-cog-5"
                    eventKey="Report"
                    activeTab={activeTab}
                    onClick={this.handleNavigation}
                  >
                    <Large>
                      <AxoLocal
                        entity="axoidcode204"
                        defaultValue={"Årsrapport"}
                      />
                    </Large>
                    <MediumOrSmaller>
                      {/* missing */}
                      År
                    </MediumOrSmaller>
                  </LexNavButton>
                  {/* )} */}

                  <LexNavButton
                    ls
                    listButton
                    className="axonavTab borderright showListButton"
                    onClick={() => {
                      DataStore.setShowDropDowns(true);
                    }}
                  >
                    <div className="flexbox-center">
                      <Icon
                        glyph="icon-fontello-align-justify"
                        style={{ fontSize: "30px" }}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity66"
                        defaultValue={"Liste Menu"}
                      />
                    </div>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonav TabaxonavTabcolora AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Result")}
                >
                  <span className="icon-fontello-download-2" />
                  &nbsp;&nbsp;
                  <AxoLocal entity="axoidcode171" defaultValue={"Rapporter"} />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab  axonavTabcolora AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Drafts")}
                >
                  <span className="icon-fontello-download-2" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="updategetAccountName12"
                    defaultValue={"Bogføringskladde"}
                  />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Bookkeeping")}
                >
                  <span className="icon-fontello-list-alt" />
                  &nbsp;&nbsp;
                  <AxoLocal entity="axoidcode81" defaultValue={"Journal"} />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Postings")}
                >
                  <span className="icon-fontello-list-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="axoidcode82"
                    defaultValue={"Kontooversigt"}
                  />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Vat")}
                >
                  <span className="icon-fontello-chart-pie-3" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="AccountingNavOperatingbudget"
                    defaultValue={"Moms"}
                  />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Setup")}
                >
                  <span className="icon-fontello-cog-5" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="ClientSetupViewSetup"
                    defaultValue={"Opsætning"}
                  />
                </MenuItem>
                {/* <MenuItem style={{ paddingBottom: '10px'}} className='axonavTab AxotabDropdownmenu' onClick={() => this.handleNavigation('AccountPlan')}>
                  <span className='icon-fontello-cog-5'/>&nbsp;&nbsp;Kontoplan
                </MenuItem> */}
                {/* <MenuItem style={{ paddingBottom: '10px'}} className='axonavTab AxotabDropdownmenu' onClick={() => this.handleNavigation('Bank')}>
                  <span className='icon-fontello-cog-5'/>&nbsp;&nbsp;
                  <AxoLocal entity='axoidcode13'defaultValue={'Bankafstemning'}/>
                </MenuItem> */}
                {/* <MenuItem style={{ paddingBottom: '10px'}} className='axonavTab AxotabDropdownmenu' onClick={() => this.handleNavigation('Report')}>
                  <span className='icon-fontello-cog-5'/>&nbsp;&nbsp;

                   <AxoLocal entity='axoidcode204' defaultValue={'Årsrapport'}/>
                </MenuItem> */}
              </DropdownButton>
            </Row>
          )}
        </Grid>
      </MediumOrLarger>
    );
  };

  renderSmallScreenNavigationBar = () => {
    return (
      <SmallOrSmaller>
        <Grid fluid className="axo-nav" id="noprint">
          <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Result")}
              >
                <span className="icon-fontello-download-2" />
                &nbsp;&nbsp;
                <AxoLocal entity="axoidcode171" defaultValue={"Rapporter"} />
              </MenuItem>
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Drafts")}
              >
                <span className="icon-fontello-download-2" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="updategetAccountName12"
                  defaultValue={"Bogføringskladde"}
                />
              </MenuItem>
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Bookkeeping")}
              >
                <span className="icon-fontello-list-alt" />
                &nbsp;&nbsp;{" "}
                <AxoLocal entity="axoidcode81" defaultValue={"Journal"} />
              </MenuItem>
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Postings")}
              >
                <span className="icon-fontello-list-1" /> &nbsp;&nbsp;
                <AxoLocal entity="axoidcode82" defaultValue={"Kontooversigt"} />
              </MenuItem>
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Vat")}
              >
                <span className="icon-fontello-chart-pie-3" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="AccountingNavOperatingbudget"
                  defaultValue={"Moms"}
                />
              </MenuItem>
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Setup")}
              >
                <span className="icon-fontello-cog-5" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="ClientSetupViewSetup"
                  defaultValue={"Opsætning"}
                />
              </MenuItem>
              {/* <MenuItem style={{ paddingBottom: '10px'}} className='axonavTab AxotabDropdownmenu' onClick={() => this.handleNavigation('AccountPlan')}>
                  <span className='icon-fontello-cog-5'/> &nbsp;&nbsp;Kontoplan
                </MenuItem> */}
              {/* <MenuItem style={{ paddingBottom: '10px'}} className='axonavTab AxotabDropdownmenu' onClick={() => this.handleNavigation('Bank')}>
                  <span className='icon-fontello-cog-5'/> &nbsp;&nbsp;
                  <AxoLocal entity='axoidcode13'defaultValue={'Bankafstemning'}/>
                </MenuItem> */}
              {/* <MenuItem style={{ paddingBottom: '10px'}} className='axonavTab AxotabDropdownmenu' onClick={() => this.handleNavigation('Report')}>
                  <span className='icon-fontello-cog-5'/> &nbsp;&nbsp;
                   <AxoLocal entity='axoidcode204' defaultValue={'Årsrapport'}/>
                </MenuItem> */}
            </DropdownButton>
          </Row>
        </Grid>
      </SmallOrSmaller>
    );
  };

  renderNavigationBar = () => {
    return (
      <div className="Tabbanne">
        {this.renderLargeScreenNavigationBar()}
        {this.renderSmallScreenNavigationBar()}
      </div>
    );
  };

  render() {
    return (
      <Grid fluid>
        <Row className="dropdown-form-styling">
          <Col xs={12} className="nopadding">
            {this.renderNavigationBar()}
            {this.props.children}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(AccountingLayoutView);
