import React from "react";
import DragDropCalendar from "./DragDropCalendar";
import CalendarEventBoxForm from "./CalendarEventBoxForm";
import BookingEventBoxForm from "./BookingEventBoxForm";
import { ctx } from "../../utilities/L20n/L20n";
import Conditional from "../../utilities/Conditional";
import LexNavButton from "../../utilities/LexNavButton";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";

import {
  AxoLocal,
  Icon,
  // Flexbox,
  // FlexElement
} from "../../utilities/LexUtilities";

import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";

import { DataStore } from "../../services/AxoServices";

export default class CalendarTabView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      smallScreenTabsExpanded: false,
      calendarEvent: this.makeCalendarEvent(),
      bookingEvent: this.makeBookingEvent(),
    };
  }

  makeCalendarEvent = () => {
    return {
      title: "",
      allDay: false,
      bookingTime: false,
      bookingCapacity: 1,
      start: new Date(),
      end: new Date(new Date().getTime() + 30 * 60 * 1000),
      description: "",
      isDeadline: false,
      color: "",
    };
  };

  makeBookingEvent = () => {
    return {
      title: ctx.getSync("makeBookingEventBookingtime"),
      allDay: false,
      bookingTime: true,
      bookingCapacity: 1,
      start: new Date(),
      end: new Date(new Date().getTime() + 30 * 60 * 1000),
      description: "",
      isDeadline: false,
    };
  };

  handleActiveState = (eventKey) => {
    this.props.handleNavigation(eventKey);
  };

  onUpdateCalendarEvent = (calendarEvent) => {
    this.setState({ calendarEvent: calendarEvent });
  };

  onResetCalendarEvent = () => {
    this.setState({ calendarEvent: this.makeCalendarEvent() });
  };

  onSaveCalendarEvent = () => {
    return this.props.onCreateCalendarEvent(this.state.calendarEvent);
  };

  onUpdateBookingEvent = (calendarEvent) => {
    this.setState({ bookingEvent: calendarEvent });
  };

  onResetBookingEvent = () => {
    this.setState({ bookingEvent: this.makeCalendarEvent() });
  };

  onSaveBookingEvent = () => {
    return this.props.onCreateCalendarEvent(this.state.bookingEvent);
  };

  expandTabs = () => {
    this.setState({ smallScreenTabsExpanded: true });
  };

  renderNavBar = () => {
    let { showDropdowns, activeTab } = this.props;

    return (
      <div>
        <Large>
          <Grid fluid className="axo-nav-lg" id="noprint">
            {!showDropdowns ? (
              <Row>
                <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                  <div
                    className="btn-group btn-group-justified"
                    role="group"
                    style={{
                      borderRadius: "0px",
                      paddingTop: "0px",
                      paddingLeft: "2px",
                    }}
                  >
                    <LexNavButton
                      className="axonavTab axonavTabcolora"
                      ls
                      glyph="icon-fontello-calendar-6"
                      eventKey="Month"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="CalendarTabViewmonth"
                        defaultValue={"Måned"}
                      />
                    </LexNavButton>
                    <LexNavButton
                      className="axonavTab axonavTabcolorb"
                      ls
                      glyph="icon-fontello-calendar-empty"
                      eventKey="Week"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="CalendarTabViewweek"
                        defaultValue={"Uge"}
                      />
                    </LexNavButton>
                    <LexNavButton
                      className="axonavTab axonavTabcolorc"
                      ls
                      glyph="icon-fontello-flag-2"
                      eventKey="Day"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="CalendarTabViewday"
                        defaultValue={"Dag"}
                      />
                    </LexNavButton>
                    <LexNavButton
                      className="axonavTab axonavTabcolord"
                      ls
                      glyph="icon-fontello-calendar-8"
                      eventKey="Agenda"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="CalendarTabViewagenda"
                        defaultValue={"Oversigt"}
                      />
                    </LexNavButton>
                    <LexNavButton
                      className="axonavTab axonavTabcolore"
                      ls
                      glyph="icon-fontello-plus-3"
                      eventKey="Create"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="CalendarTabViewcreate"
                        defaultValue={"Opret begivenhed"}
                      />
                    </LexNavButton>
                    <LexNavButton
                      className="axonavTab axonavTabcolorf"
                      ls
                      glyph="icon-fontello-plus-1"
                      eventKey="CreateBooking"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="CalendarTabViewcreateCreatebookingtime"
                        defaultValue={"Create booking time"}
                      />
                    </LexNavButton>
                    <LexNavButton
                      ls
                      listButton
                      className="axonavTab showListButton"
                      onClick={() => {
                        DataStore.setShowDropDowns(true);
                      }}
                    >
                      <div className="flexbox-center">
                        <Icon
                          glyph="icon-fontello-align-justify"
                          style={{ fontSize: "30px" }}
                        />
                        &nbsp;
                        <AxoLocal
                          entity="AccountingTabViewEntity66"
                          defaultValue={"Liste Menu"}
                        />
                      </div>
                    </LexNavButton>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row style={{ paddingLeft: "3%", paddingTop: "15px" }}>
                <DropdownButton
                  style={{ width: "100%" }}
                  id="IT"
                  noCaret
                  title={
                    <div style={{ display: "inline-block" }}>
                      <div className="flexbox-center">
                        <Icon
                          style={{ fontSize: "25px" }}
                          glyph="icon-fontello-align-justify"
                        />
                        &nbsp;&nbsp;
                        <AxoLocal
                          style={{ fontSize: "20px" }}
                          entity="Enhedspris33"
                          defaultValue={"Menu"}
                        />
                      </div>
                    </div>
                  }
                >
                  <MenuItem
                    className="axonavTab axonavTabcolora AxotabDropdownmenu"
                    style={{ marginTop: "10px" }}
                    onClick={() => {
                      DataStore.setShowDropDowns(false);
                    }}
                  >
                    <AxoLocal
                      entity="AccountingTabViewEntity67"
                      defaultValue={"Vis menu bar"}
                    />{" "}
                    &nbsp;
                    <span className="icon-fontello-stop" />
                    &nbsp;
                    <span className="icon-fontello-stop" />
                    &nbsp;
                    <span className="icon-fontello-stop" />
                  </MenuItem>
                  <MenuItem
                    style={{ paddingBottom: "10px" }}
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Month")}
                  >
                    <span className="icon-fontello-calendar-6" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="CalendarTabViewmonth"
                      defaultValue={"Måned"}
                    />
                  </MenuItem>
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Week")}
                  >
                    <span className="icon-fontello-calendar-empty" />{" "}
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="CalendarTabViewweek"
                      defaultValue={"Uge"}
                    />
                  </MenuItem>
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Day")}
                  >
                    <span className="icon-fontello-flag-2" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="CalendarTabViewday"
                      defaultValue={"Dag"}
                    />
                  </MenuItem>
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Agenda")}
                  >
                    <span className="icon-fontello-calendar-8" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="CalendarTabViewagenda"
                      defaultValue={"Oversigt"}
                    />
                  </MenuItem>
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu "
                    onClick={() => this.handleActiveState("Create")}
                  >
                    <span className="icon-fontello-plus-3 Addicon" />{" "}
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="CalendarTabViewcreate"
                      defaultValue={"Opret begivenhed"}
                    />
                  </MenuItem>
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("CreateBooking")}
                  >
                    <span className="icon-fontello-plus-1" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="CalendarTabViewcreateCreatebookingtime"
                      defaultValue={"Create booking time"}
                    />
                  </MenuItem>
                </DropdownButton>
              </Row>
            )}
          </Grid>
        </Large>
        <MediumOrSmaller>
          <Grid fluid className="axo-nav" id="noprint">
            <Row style={{ paddingLeft: "20%", paddingTop: "15px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab axonavTabcolora AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Month")}
                >
                  <span className="icon-fontello-calendar-6" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CalendarTabViewmonth"
                    defaultValue={"Måned"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Week")}
                >
                  <span className="icon-fontello-calendar-empty" /> &nbsp;&nbsp;
                  <AxoLocal entity="CalendarTabViewweek" defaultValue={"Uge"} />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Day")}
                >
                  <span className="icon-fontello-flag-2" /> &nbsp;&nbsp;
                  <AxoLocal entity="CalendarTabViewday" defaultValue={"Dag"} />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Agenda")}
                >
                  <span className="icon-fontello-calendar-8" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CalendarTabViewagenda"
                    defaultValue={"Oversigt"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Create")}
                >
                  <span className="icon-fontello-plus-3 Addicon" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CalendarTabViewcreate"
                    defaultValue={"Opret begivenhed"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("CreateBooking")}
                >
                  <span className="icon-fontello-plus-1" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CalendarTabViewcreateCreatebookingtime"
                    defaultValue={"Create booking time"}
                  />
                </MenuItem>
              </DropdownButton>
            </Row>

            {/* <Row>
            <Col xs={12} className="nopadding">
              <div className="btn-group btn-group-justified" role="group" style={{ borderRadius: '0px' }}>
                <LexNavButton className='axonavTab' glyph='icon-fontello-calendar-6' eventKey='month' activeTab={activeTab} onClick={this.handleActiveState}>
                  <AxoLocal entity='CalendarTabViewmonth'defaultValue={'Måned'}/>
                </LexNavButton>
                <LexNavButton className='axonavTab' glyph='icon-fontello-calendar-empty' eventKey='week' activeTab={activeTab} onClick={this.handleActiveState}>
                  <AxoLocal entity='CalendarTabViewweek'defaultValue={'Uge'}/>
                </LexNavButton>
                { smallScreenTabsExpanded ? (
                  <LexNavButton className='axonavTab' glyph='icon-fontello-calendar-1' eventKey='day' activeTab={activeTab} onClick={this.handleActiveState}>
                    <AxoLocal entity='CalendarTabViewday'defaultValue={'Dag'}/>
                  </LexNavButton>
                ) : null }
                { !smallScreenTabsExpanded ? (
                  <LexNavButton className='axonavTab iconxlSiza' glyph='icon-fontello-align-justify-1' activeTab={activeTab} onClick={this.expandTabs}>
                  </LexNavButton>
                ) : null }
              </div>
            </Col>
          </Row> */}
            {/* { smallScreenTabsExpanded ? (
            <Row>
              <Col xs={12} className="nopadding">
                <div className="btn-group btn-group-justified" role="group" style={{ borderRadius: '0px' }}>
                  <LexNavButton className='axonavTab' glyph='icon-fontello-calendar-8' eventKey='agenda' activeTab={activeTab} onClick={this.handleActiveState}>
                    <AxoLocal entity='CalendarTabViewagenda'defaultValue={'Oversigt'}/>
                  </LexNavButton>
                  <LexNavButton className='axonavTab' glyph='icon-fontello-plus-3 Addicon' eventKey='create' activeTab={activeTab} onClick={this.handleActiveState}>
                    <AxoLocal entity='CalendarTabViewcreate'defaultValue={'Opret begivenhed'}/>
                  </LexNavButton>
                  <LexNavButton className='axonavTab' glyph='icon-fontello-plus-1' eventKey='createBooking' activeTab={activeTab} onClick={this.handleActiveState}>
                    <AxoLocal entity='CalendarTabViewcreateCreatebookingtime'defaultValue={'Create booking time'}/>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : null } */}
          </Grid>
        </MediumOrSmaller>
      </div>
    );
  };

  render() {
    var { sharingGlobals, activeTab } = this.props;

    return (
      <div>
        <div>
          <Grid fluid>
            <Row>
              <Col xs={12} className="nopadding">
                {this.renderNavBar()}
                <div>
                  <Conditional show={activeTab === "Month"}>
                    <div
                      style={{
                        background: "white",
                        paddingTop: "18px",
                        paddingLeft: "2px",
                      }}
                    >
                      <DragDropCalendar
                        view="month"
                        events={this.props.events}
                        onEventDrop={this.props.onEventDrop}
                        onSelectEvent={this.props.onSelectEvent}
                        onSelectSlot={this.props.onSelectSlot}
                        locale={this.props.userSettings.locale}
                        userProfile={this.props.userProfile}
                        sharingGlobals={sharingGlobals}
                      />
                    </div>
                  </Conditional>
                  <Conditional show={activeTab === "Week"}>
                    <div
                      style={{
                        background: "white",
                        paddingTop: "18px",
                        paddingLeft: "2px",
                      }}
                    >
                      <DragDropCalendar
                        view="week"
                        events={this.props.events}
                        onEventDrop={this.props.onEventDrop}
                        onSelectEvent={this.props.onSelectEvent}
                        onSelectSlot={this.props.onSelectSlot}
                        locale={this.props.userSettings.locale}
                        userProfile={this.props.userProfile}
                        sharingGlobals={sharingGlobals}
                      />
                    </div>
                  </Conditional>
                  <Conditional show={activeTab === "Day"}>
                    <div
                      style={{
                        background: "white",
                        paddingTop: "18px",
                        paddingLeft: "2px",
                      }}
                    >
                      {/* <Flexbox justified collapseXS>
                    <FlexElement> */}
                      <DragDropCalendar
                        view="day"
                        events={this.props.events}
                        onEventDrop={this.props.onEventDrop}
                        onSelectEvent={this.props.onSelectEvent}
                        onSelectSlot={this.props.onSelectSlot}
                        locale={this.props.userSettings.locale}
                        userProfile={this.props.userProfile}
                        sharingGlobals={sharingGlobals}
                      />
                      {/* </FlexElement>
                    <FlexElement>
                      Hejsa
                    </FlexElement>
                  </Flexbox> */}
                    </div>
                  </Conditional>
                  <Conditional show={activeTab === "Agenda"}>
                    <div
                      style={{
                        background: "white",
                        paddingTop: "18px",
                        paddingLeft: "2px",
                      }}
                    >
                      <DragDropCalendar
                        view="agenda"
                        events={this.props.events}
                        onEventDrop={this.props.onEventDrop}
                        onSelectEvent={this.props.onSelectEvent}
                        onSelectSlot={this.props.onSelectSlot}
                        locale={this.props.userSettings.locale}
                        userProfile={this.props.userProfile}
                        sharingGlobals={sharingGlobals}
                      />
                    </div>
                  </Conditional>
                  <Conditional show={activeTab === "Create"}>
                    <div style={{ minHeight: "800px" }}>
                      <CalendarEventBoxForm
                        calendarEvent={this.state.calendarEvent}
                        onCalendarEventUpdate={this.onUpdateCalendarEvent}
                        onSave={this.onSaveCalendarEvent}
                        onReset={this.onResetCalendarEvent}
                        userProfile={this.props.userProfile}
                        showButtons={true}
                      />
                    </div>
                  </Conditional>
                  <Conditional show={activeTab === "CreateBooking"}>
                    <div style={{ background: "white" }}>
                      <BookingEventBoxForm
                        calendarEvent={this.state.bookingEvent}
                        onCalendarEventUpdate={this.onUpdateBookingEvent}
                        onSave={this.onSaveBookingEvent}
                        onReset={this.onResetBookingEvent}
                        userProfile={this.props.userProfile}
                        showButtons={true}
                      />
                    </div>
                  </Conditional>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}
