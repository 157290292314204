import React from "react";
import TinyMCEEditor from "../../utilities/Editors/TinyMCEEditor";
import DataActions from "../../services/DataAccess/DataActions";
import ModalService from "../../services/ModalService";
import InlineEdit from "../../utilities/InlineEdit";
import { debounce } from "lodash";
import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class DocumentTemplateEditView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onContentChange = debounce((content) => {
    var newTemplate = Object.assign({}, this.props.documentTemplate);
    newTemplate.content = content;
    this.props.onTemplateChange(newTemplate);
  }, 1000);

  onTemplatePropertyChange = (propertyName, input) => {
    var newTemplate = Object.assign({}, this.props.documentTemplate);
    newTemplate[propertyName] = input.value;
    this.props.onTemplateChange(newTemplate);
  };

  onGeneratePdf = () => {
    if (!this.props.documentTemplate) {
      return;
    }
    this.setState({ isGeneratingPDF: true });
    DataActions.generateTemplatePdf(this.props.documentTemplate.id)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((newPDF) => {
            this.props.onCreatePDF(newPDF);
            this.setState({ isGeneratingPDF: false });
          });
        } else {
          this.showPDFGenerationError();
        }
      })
      .catch((reason) => {
        this.showPDFGenerationError();
      });
  };

  showPDFGenerationError = () => {
    this.setState({ isGeneratingPDF: false });
    ModalService.openAlertModal(
      <AxoLocal
        entity="DocumentTemplateCreateViewisGeneratingPDF"
        defaultValue={"PDF`en kunne ikke genereres. Prøv igen senere."}
      />
    );
  };

  renderGenerateButtonContent = () => {
    if (this.state.isGeneratingPDF) {
      return (
        <span>
          Genererer...{" "}
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </span>
      );
    } else {
      return (
        <span>
          <Icon glyph="icon-fontello-acrobat" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="DocumentTemplateEditView"
            defaultValue={"Generer til PDF"}
          />
        </span>
      );
    }
  };

  onSaveTemplate = () => {
    var newTemplate = Object.assign({}, this.props.documentTemplate);
    newTemplate.draft = false;
    this.props.onTemplateChange(newTemplate);
    this.props.onSaveTemplate(newTemplate);
  };

  renderButtons = () => {
    return (
      <Row style={{ textAlign: "right", align: "right" }}>
        <ButtonGroup>
          <Button className="Lex-button-2" onClick={this.onSaveTemplate}>
            <Icon glyph="icon-fontello-layers" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="DocumentTemplateEditonSaveTemplate"
              defaultValue={"Gem skabelon"}
            />
          </Button>
          <Button
            className="Lex-button-2"
            disabled={this.state.isGeneratingPDF}
            onClick={this.onGeneratePdf}
          >
            {this.renderGenerateButtonContent()}
          </Button>
        </ButtonGroup>
      </Row>
    );
  };

  //Props
  //documents
  render() {
    return (
      <Grid fluid style={{ paddingTop: "10px" }}>
        <p>
          <b>
            <AxoLocal
              entity="socialPrivateConversationBannerConferenceName"
              defaultValue={"Navn"}
            />
            :&nbsp;
          </b>
          <InlineEdit
            value={this.props.documentTemplate.title || "---"}
            change={this.onTemplatePropertyChange.bind(this, "title")}
          />
        </p>
        <p>
          <b>
            <AxoLocal entity="DocumentGridViewType" defaultValue={"Type"} />
            :&nbsp;
          </b>
          <InlineEdit
            value={this.props.documentTemplate.type || "---"}
            change={this.onTemplatePropertyChange.bind(this, "type")}
          />
        </p>
        {this.renderButtons()}
        <Row>
          <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <TinyMCEEditor
              text={this.props.documentTemplate.content}
              locale={this.props.locale}
              onTextChange={this.onContentChange}
              documentMap={this.props.documentMap}
              allowImageImport
            />
          </Col>
        </Row>
        {this.renderButtons()}
      </Grid>
    );
  }
}
