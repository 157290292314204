import React from "react";
import PropTypes from "prop-types";
import Conditional from "../utilities/Conditional";
import { Form } from "react-bootstrap";

import { ApiService, DataStore, DataActions } from "../services/AxoServices";

import { AxoLocal, Icon } from "../utilities/LexUtilities";

import Dropzone from "dropzone";

export default class Dropzonejs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showStorageWarning: false,
    };
  }

  //Props
  //onFileUploaded
  //onFileRemoved
  //onStorageExceeded
  componentDidMount() {
    this.fileMap = {};
    var component = this;
    new Dropzone("#dropzone-form", {
      url: ApiService.getUploadPath(),
      paramName: "files", // The name that will be used to transfer the file
      dictRemoveFile: "X",
      maxFilesize: 500, // MB
      timeout: 300000,
      addRemoveLinks: true,
      init() {
        this.on("success", function (file, response) {
          var fileInfo = response[0];
          component.fileMap[fileInfo.fileName] = fileInfo;
          if (component.props.onFileUploaded) {
            component.props.onFileUploaded(response[0]);
          }
        });
        this.on("error", function (file, message, xhr) {
          if (message.value === "Storage") {
            component.setState({ showStorageWarning: true });
            setTimeout(() => {
              component.setState({ showStorageWarning: false });
            }, 5000);
          }
        });
        this.on("removedfile", function (file) {
          if (component.fileMap[file.name]) {
            var fileInfo = component.fileMap[file.name];

            //Document must be trashed before it can be deleted
            DataStore.updateDocumentState({
              ...fileInfo,
              trashed: true,
            });
            DataActions.deleteDocument(fileInfo.id).then((response) => {
              if (response.ok && component.props.onFileRemoved) {
                component.props.onFileRemoved(fileInfo.id);
              }
            });
          }
        });
      },
      accept: (file, done) => {
        done();
      },
      sending: (file, xhr, formData) => {
        xhr.withCredentials = true;
      },
    });
  }

  static propTypes = {
    onFileUploaded: PropTypes.func,
    dropzoneId: PropTypes.string,
  };

  render() {
    return (
      <div>
        <Conditional show={this.state.showStorageWarning}>
          <h4 className="axored">
            <AxoLocal
              entity="dropzoneourdropzone1"
              defaultValue={
                "Du har ikke mere lagerplads. Slet filer eller opgrader dit abonnement."
              }
            />
          </h4>
        </Conditional>
        <Form
          id="dropzone-form"
          className="dropzone our-dropzone small-margin"
          ref={(c) => (this.dropzoneElement = c)}
        >
          <div className="dz-message our-dz-message" data-dz-message>
            <h4>
              <Icon glyph="icon-fontello-up-hand" />
              &nbsp;&nbsp;
              <AxoLocal
                entity="dropzoneourdropzone"
                defaultValue={
                  "Træk dine filer her, eller klik for at vælge en fil "
                }
              />
            </h4>
          </div>
        </Form>
      </div>
    );
  }
}
