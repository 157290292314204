import React from "react";
import PresentationActions from "../services/PresentationActions";
import moment from "moment";
import PresentationLink from "../Common/PresentationLink";
import ReportSection from "./ReportSection";
import InlineMicroTinyMCE from "../../utilities/Editors/InlineMicroTinyMCE";

import { Row, Col, Grid, Button } from "react-bootstrap";
import { ApiService } from "../../services/AxoServices";

import {
  QRCode,
  Icon,
  InlineEdit,
  withRouter,
  AxoLocal,
} from "../../utilities/LexUtilities";

class PresentationView extends React.PureComponent {
  //Props
  //presentation
  //userProfile
  renderAttachment = (item) => {
    if (!item.attachment || !item.attachment.id) {
      return null;
    }
    if (item.type === 2) {
      //Image
      return (
        <div
          className="text-center"
          style={{ maxWidth: "700px", margin: "auto" }}
        >
          <img
            alt=""
            src={ApiService.getFileInlinePath(item.attachment.id)}
            style={{ maxWidth: "100%" }}
          />
        </div>
      );
    }
    if (item.type === 3) {
      //Video
      return (
        <div style={{ maxWidth: "700px", margin: "auto" }}>
          <video
            id="viewport"
            ref={(c) => (this.video = c)}
            width="100%"
            height="auto"
            controls
          >
            <source
              src={ApiService.getFileInlinePath(item.attachment.id)}
              type="video/mp4"
            />
            <AxoLocal
              entity="Yourbrowserdoesnotsupportvideoplayback"
              defaultValue={" Din browser understøtter ikke videoafspilning"}
            />
          </video>
        </div>
      );
    }
  };

  renderItems = (presentation) => {
    return presentation.items.map((item) => {
      return (
        <div key={item.id} style={{ paddingBottom: "15px" }}>
          {this.renderAttachment(item)}
          {this.renderTitleField(item)}
          {this.renderDescriptionField(item)}
        </div>
      );
    });
  };

  renderTitleField = (item) => {
    if (!item.title) {
      return null;
    }
    if (this.props.allowEdit) {
      return (
        <Grid fluid>
          <Row className="AxolexNavigationBars">
            <Col className="nopadding" xs={12}>
              <div className="text-center frontPageaxolexdiv Bannecolor">
                <b>
                  <InlineEdit
                    value={item.title || "---"}
                    change={this.onChangeItemTitle.bind(this, item.id)}
                    style={{}}
                    editStyle={{ width: "100%", fontWeight: "normal" }}
                  />
                </b>
              </div>
            </Col>
          </Row>
        </Grid>
      );
    }
    return (
      <Grid fluid>
        <Row className="AxolexNavigationBars">
          <Col className="nopadding" xs={12}>
            <div className="text-center frontPageaxolexdiv Bannecolor">
              <b>{item.title}</b>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  };

  renderDescriptionField = (item) => {
    if (!item.description) {
      return null;
    }
    if (this.props.allowEdit) {
      return (
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "2px",
            maxWidth: "700px",
            margin: "auto",
          }}
        >
          <InlineMicroTinyMCE
            text={item.description}
            locale={"da"}
            height={300}
            onSave={this.onChangeItemDescription.bind(this, item.id)}
          />
        </div>
      );
    }
    return (
      <div
        className="text-left"
        style={{
          paddingLeft: "10px",
          paddingRight: "2px",
          maxWidth: "700px",
          margin: "auto",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
        {/* <AxoFrame
          bottomPadding={35}
          content={item.description}
        />   */}
      </div>
    );
  };

  onChangeItemTitle = (itemId, input) => {
    var presentation = Object.assign({}, this.props.presentation);
    var item = presentation.items.find((item) => item.id === itemId);
    item.title = input.value;
    PresentationActions.updatePresentationItem(item);
  };

  onChangeItemDescription = (itemId, description) => {
    var presentation = Object.assign({}, this.props.presentation);
    var item = presentation.items.find((item) => item.id === itemId);
    item.description = description;
    PresentationActions.updatePresentationItem(item);
  };

  renderPresentationLink = (presentation, includeLinkBox) => {
    if (!presentation.public || presentation.legal) {
      return null;
    }
    var userId = this.props.userProfile
      ? this.props.userProfile.userId
      : this.props.match.params.userId;
    var link =
      window.location.protocol +
      "//" +
      window.location.host +
      "/Presentation/post/" +
      userId +
      "/" +
      presentation.id;
    return <PresentationLink url={link} includeLinkBox={includeLinkBox} />;
  };

  renderBackButton() {
    let { onBackButton } = this.props;

    if (!onBackButton) {
      return null;
    }

    return (
      <div
        role="button"
        onClick={onBackButton}
        className="flexbox-center"
        style={{ paddingTop: "10px", paddingBottom: "0px", lineHeight: "35px" }}
      >
        <Icon
          glyph="icon-fontello-left-2"
          style={{ fontSize: "45px", width: "100px" }}
        />
        {/* <Button onClick={this.props.onBackButton} style={{width:'300px', height:'45px',paddingBottom: '10px', }} className='Lex-button-1xlink'>
      <span className='icon-fontello-left-1 AxoNavigationheightIcon1' role='button' />
      </Button> */}
      </div>
    );
  }

  renderPublicButton = () => {
    let presentation = this.props.presentation;
    if (!this.props.onTogglePublic || presentation.public) {
      return null;
    }
    return (
      <div
        className="flexbox-center"
        style={{ paddingTop: "10px", paddingBottom: "0px", lineHeight: "35px" }}
      >
        <Button
          onClick={this.props.onTogglePublic.bind(this, presentation.id)}
          style={{
            width: "300px",
            paddingTop: "6px",
            height: "45px",
            paddingBottom: "10px",
          }}
          className="Lex-button-1xlink"
        >
          <AxoLocal
            entity="presentationMarketing8"
            defaultValue="Offentliggør"
          />
        </Button>
      </div>
    );
  };

  EditButton = (props) => {
    let { allowEdit, presentation, onEdit } = props;
    if (!allowEdit) {
      return null;
    }
    return (
      <div
        className="flexbox-center"
        style={{ paddingTop: "10px", paddingBottom: "0px", lineHeight: "35px" }}
      >
        <Button
          onClick={() => onEdit(presentation.id)}
          style={{
            width: "300px",
            paddingTop: "6px",
            height: "45px",
            paddingBottom: "10px",
          }}
          className="Lex-button-1xlink"
        >
          <Icon glyph="icon-fontello-edit-3" />
          &nbsp;&nbsp;{" "}
          <AxoLocal entity="presentationMarketing6" defaultValue="Rediger" />
        </Button>
      </div>
    );
  };

  renderViewCount = () => {
    if (!this.props.includeLink) {
      return null;
    }
    return (
      <div
        style={{
          paddingBottom: "10px",
          paddingTop: "15px",
          maxWidth: "800px",
          margin: "auto",
        }}
      >
        <b>
          <AxoLocal entity="presentationMarketing19" defaultValue="Visninger" />
          : {this.props.presentation.viewCount}
        </b>
      </div>
    );
  };

  renderReportSection = () => {
    if (!this.props.includeReportButton) {
      return null;
    }
    return <ReportSection presentation={this.props.presentation} />;
  };

  renderListButton = () => {
    if (!this.props.includeListLinkButton) {
      return null;
    }
    return (
      <div className="text-center">
        <Button onClick={this.goToList} className="Lex-button-1xlink">
          <AxoLocal
            key="save"
            entity="presentationMarketing23"
            defaultValue="Se andre indlæg"
          />
        </Button>
      </div>
    );
  };

  goToList = () => {
    let link = "/Presentation/postList/" + this.props.match.params.userId;
    this.props.history.push(link);
  };

  onEdit = (presentationId) => {
    let { onEdit } = this.props;
    if (onEdit) {
      onEdit(presentationId);
      return;
    }

    let link = "/xlink?id=" + presentationId;
    this.props.history.push(link);
  };

  render() {
    let { presentation, allowEdit, includeLinks, includeLinkBox } = this.props;

    let link =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;

    let EditButton = this.EditButton;
    return (
      <div style={{ padding: "0px", maxWidth: "100%", margin: "auto" }}>
        <div className="text-center">
          {this.renderBackButton()}
          {this.renderPublicButton()}
          <EditButton
            allowEdit={allowEdit}
            presentation={presentation}
            onEdit={this.onEdit}
          />
          {includeLinks && includeLinkBox
            ? this.renderPresentationLink(presentation, true)
            : null}
          <div
            className="text-right"
            style={{ paddingRight: "20px", maxWidth: "800px", margin: "auto" }}
          >
            <b>
              {!!presentation.authorName ? (
                <span>{presentation.authorName} -</span>
              ) : null}{" "}
              {moment(presentation.creationDate).format("L")}
            </b>
          </div>
        </div>
        {this.renderItems(presentation)}
        {includeLinks && !includeLinkBox
          ? this.renderPresentationLink(presentation, false)
          : null}
        {this.renderViewCount()}
        {this.renderListButton()}
        {this.renderReportSection()}
        {includeLinks ? (
          <div className="text-center">
            <QRCode
              bgColor={"#FFFFFF"}
              fgColor={"#000000"}
              // logo={logo}
              value={link}
              size={150}
              // logoWidth={80}
              // logoHeight={80}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(PresentationView);
