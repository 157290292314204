import React from "react";
import AccountPlansView from "./AccountPlansView";
import AccountPlansSetupView from "./AccountPlansSetupView";

import { SmallOrSmaller, MediumOrLarger } from "../../utilities/Responsive";

import { DataStore } from "../../services/AxoServices";

import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";

import {
  LexNavButton,
  withRouter,
  AxoLocal,
  Icon,
} from "../../utilities/LexUtilities";
class AdminTabView extends React.PureComponent {
  handleActiveState = (eventKey) => {
    this.props.handleNavigation(eventKey);
  };

  renderLargeScreenNavigationBar = () => {
    let { activeTab, showDropdowns } = this.props;

    return (
      <MediumOrLarger>
        <Grid fluid className="axo-nav-lg" id="noprint">
          {!showDropdowns ? (
            <Row>
              <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{ borderRadius: "0px", paddingTop: "0px" }}
                >
                  <LexNavButton
                    className="axonavTab axonavTabcolora"
                    ls
                    glyph="icon-fontello-cog-7"
                    eventKey="Accounts"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="axoidcode153"
                      defaultValue={"Kontoplaner"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorb"
                    ls
                    glyph="icon-fontello-cog-5"
                    eventKey="Setup"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="ClientSetupViewSetup"
                      defaultValue={"Opsætning"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    ls
                    listButton
                    className="axonavTab showListButton"
                    onClick={() => {
                      DataStore.setShowDropDowns(true);
                    }}
                  >
                    <div className="flexbox-center">
                      <Icon
                        glyph="icon-fontello-align-justify"
                        style={{ fontSize: "30px" }}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity66"
                        defaultValue={"Liste Menu"}
                      />
                    </div>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab"
                  onClick={() => this.handleActiveState("Accounts")}
                >
                  <span className="icon-fontello-user-female" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="axoidcode153"
                    defaultValue={"Kontoplaner"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu "
                  onClick={() => this.handleActiveState("Setup")}
                >
                  <span className="icon-fontello-cog-5" />
                  &nbsp;{" "}
                  <AxoLocal
                    entity="ClientSetupViewSetup"
                    defaultValue={"Opsætning"}
                  />
                </MenuItem>
              </DropdownButton>
            </Row>
          )}
        </Grid>
      </MediumOrLarger>
    );
  };
  renderSmallScreenNavigationBar = () => {
    return (
      <SmallOrSmaller>
        <Grid fluid className="axo-nav" id="noprint">
          <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab"
                onClick={() => this.handleActiveState("Accounts")}
              >
                <span className="icon-fontello-user-female" />
                <AxoLocal entity="axoidcode153" defaultValue={"Kontoplaner"} />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu "
                onClick={() => this.handleActiveState("Setup")}
              >
                <span className="icon-fontello-cog-5" />{" "}
                <AxoLocal
                  entity="ClientSetupViewSetup"
                  defaultValue={"Opsætning"}
                />
              </MenuItem>
            </DropdownButton>
          </Row>
        </Grid>
      </SmallOrSmaller>
    );
  };

  renderNavigationBar = () => {
    return (
      <div className="Tabbanne" style={{ paddingTop: "0px" }}>
        {this.renderLargeScreenNavigationBar()}
        {this.renderSmallScreenNavigationBar()}
      </div>
    );
  };

  renderNavigationContent = () => {
    let { activeTab, financeAccountPlans, selectedPlanId } = this.props;

    let selectedPlan = financeAccountPlans.find((f) => f.id === selectedPlanId);

    let balanceAccounts = selectedPlan
      ? selectedPlan.accounts.filter(
          (acc) =>
            acc.type === "Standard" &&
            acc.number >= selectedPlan.balanceStart &&
            acc.number <= selectedPlan.balanceEnd
        )
      : [];

    return (
      <div>
        {activeTab === "Accounts" ? (
          <AccountPlansView {...this.props} selectedPlan={selectedPlan} />
        ) : null}
        {activeTab === "Setup" ? (
          <AccountPlansSetupView
            {...this.props}
            selectedPlan={selectedPlan}
            balanceAccounts={balanceAccounts}
          />
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Grid fluid>
          <Row className="dropdown-form-styling">
            <Col xs={12} className="nopadding">
              {this.renderNavigationBar()}
              {this.renderNavigationContent()}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(AdminTabView);
