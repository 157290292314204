import React from "react";
import PropTypes from "prop-types";

import MultiPaymentView from "../../routes/TimeEntries/MultiPaymentView";

import LicenseConsentForm from "../../Login/Licenses/LicenseConsentForm";
import DataConsentForm from "../../Login/Licenses/DataConsentForm";

export default class SocietyHomeView extends React.PureComponent {
  static propTypes = {
    invoices: PropTypes.array.isRequired,
    clientLabels: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
  };

  render() {
    let { invoices, clientLabels, locale } = this.props;

    return (
      <div>
        <MultiPaymentView invoices={invoices} clientLabels={clientLabels} />
        <LicenseConsentForm locale={locale} hideControls />
        <DataConsentForm locale={locale} hideControls />
      </div>
    );
  }
}
