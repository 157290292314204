import React from "react";

import EmbeddedUploadView from "./EmbeddedUploadView";

function UploadView(props) {
  return (
    <div>
      <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center">
        Upload dokumenter &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-md"
          src="/imgs/app/Axologo3-30x30.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-md hidden-xs"
          src="/imgs/app/Axologo3-35x35.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        <img
          alt=""
          className="hidden-md hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div className="standardMaxWidth center-block">
        <EmbeddedUploadView {...props} />
      </div>
    </div>
  );
}

export default UploadView;
