import React from "react";
import PropTypes from "prop-types";

class Icon extends React.PureComponent {
  render() {
    var { glyph, className, ...other } = this.props;
    var classes = glyph + " axo-icon" + (!!className ? " " + className : "");
    return <span {...other} className={classes} />;
  }
}

Icon.propTypes = {
  glyph: PropTypes.string,
};

export default Icon;
