import React from "react";
import PropTypes from "prop-types";
import PowerOfAttorneyFormView from "./PowerOfAttorneyFormView";
import PowerOfAttorneyAlcoholList from "./List/PowerOfAttorneyAlcoholList";
import PowerOfAttorneyConsentList from "./List/PowerOfAttorneyConsentList";
import PowerOfAttorneyCourtList from "./List/PowerOfAttorneyCourtList";
import PowerOfAttorneyEstablishCompanyList from "./List/PowerOfAttorneyEstablishCompanyList";
import PowerOfAttorneyVisaList from "./List/PowerOfAttorneyVisaList";
import PowerOfAttorneyGenericList from "./List/PowerOfAttorneyGenericList";

import { DataActions } from "../services/AxoServices";

import { withRouter, LoadingIndicator } from "../utilities/LexUtilities";

class PowerOfAttorneyDetailsView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };
  }

  static propTypes = {
    applicationUDs: PropTypes.array.isRequired,
  };

  onSubmit = async (model) => {
    let response = await DataActions.updatePowerOfAttorney(model);

    if (!response.ok) {
      return false;
    }

    this.setState({ editing: false });
  };

  onCancel = () => {
    this.setState({ editing: false });
  };

  render() {
    let { match, models, userProfile } = this.props;

    let { editing } = this.state;

    let id = parseInt(match.params.id, 10);
    let model = models.find((a) => a.id === id);

    if (!model) {
      return <LoadingIndicator />;
    }

    if (editing) {
      return (
        <PowerOfAttorneyFormView
          key={model.id}
          startValue={model}
          userProfile={userProfile}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      );
    }
    switch (model.type) {
      case "Alcohol":
        return (
          <PowerOfAttorneyAlcoholList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
      case "Visa":
        return (
          <PowerOfAttorneyVisaList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
      case "Court":
        return (
          <PowerOfAttorneyCourtList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
      case "Consent":
        return (
          <PowerOfAttorneyConsentList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
      case "EstablishCompany":
        return (
          <PowerOfAttorneyEstablishCompanyList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
      default:
        return (
          <PowerOfAttorneyGenericList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
    }
  }
}

export default withRouter(PowerOfAttorneyDetailsView);
