import React from "react";
import ClientInvoicesView from "./ClientInvoicesView";

import {
  DataStore,
  DataActions,
  // StripeAuthenticationService,
  TimeEntryService,
} from "../../services/AxoServices";

import {
  // ctx,
  LoadingIndicator,
} from "../../utilities/LexUtilities";

export default class ClientInvoicesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      userProfile: {},
      userSettings: {},
      cardInformation: {},
    };
    this.resolvePayment = () => {};
    this.rejectPayment = (reason) => {};
    this.invoice = {}; //Invoice currently being paid

    this.name = "ClientInvoicesContainer";
  }

  // getStripeConfiguration = (locale) => {
  //   return {
  //     key: StripeAuthenticationService.getPublicKey(),
  //     image: '/imgs/app/Axologo3-90x90.png',
  //     locale: this.getStripeLocale(locale),
  //     token: (token) => {
  //       if(!this.invoice.id) {
  //         return Promise.reject('No invoice selected');
  //       }
  //       this.doTransferPayment(this.invoice, token.id);
  //     },
  //     closed: () => {
  //       this.rejectPayment('closed');
  //     }
  //   }
  // }

  // getStripeLocale = (locale) => {
  //   switch(locale) {
  //     case 'da':
  //       return 'da';
  //     case 'enUS':
  //       return 'en';
  //     case 'ch':
  //       return 'zh';
  //     default:
  //       return 'en';
  //   }
  // }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      let invoices = this.extractInvoices(store.lawyerProfiles);
      this.setState({
        invoices,
        userProfile: store.userProfile,
        userSettings: store.userSettings,
        cardInformation: store.cardInformation,
      });
      // this.stripeHandler = window.StripeCheckout.configure(this.getStripeConfiguration(store.userSettings.locale));
    });
    DataStore.initializeUserProfile();
    DataStore.fetchLawyerProfiles();
    DataStore.initializeUserSettings();
    // DataStore.fetchCardInformation();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  extractInvoices = (lawyers) => {
    var invoices = [];
    lawyers.forEach((lawyer) => {
      invoices = invoices.concat(this.getInvoicesWithLawyer(lawyer));
    });
    let sortedInvoices = invoices.sort((l, r) => {
      return r.number - l.number;
    });
    return sortedInvoices;
  };

  getInvoicesWithLawyer = (lawyer) => {
    return lawyer.invoices.map((invoice) => {
      var newInvoice = Object.assign({}, invoice);
      newInvoice.userProfile = lawyer;
      return newInvoice;
    });
  };

  onToggleCards = () => {
    let userSettings = Object.assign({}, this.state.userSettings);
    userSettings.showDocumentCards = !userSettings.showDocumentCards;
    DataActions.updateUserSettings(userSettings);
  };

  onPayInvoice = (invoice) => {
    this.invoice = invoice;

    // this.stripeHandler.open({
    //   name:  ctx.getSync('changingSubscriptionTypeonUpdateCard'),
    //   description: ctx.getSync('InvoiceRegisterCard') + ' ' + invoice.invoiceType + invoice.number,
    //   email: this.state.userProfile.eMail,
    //   zipCode: false,
    //   allowRememberMe: false,
    //   currency: invoice.currency,
    //   panelLabel: ctx.getSync('InvoicePayInvoice'),
    // });
    return new Promise((resolve, reject) => {
      this.resolvePayment = resolve;
      this.rejectPayment = reject;
    });
  };

  onTransferPaymentFromCard = (invoice) => {
    return this.doTransferPayment(invoice, null);
  };

  doTransferPayment = (invoice, token) => {
    return DataActions.stripePayment({
      receiverId: invoice.userProfile.id,
      invoiceId: invoice.id,
      token: token,
      amount: TimeEntryService.calculateInvoiceTotal(invoice) * 100,
      currency: invoice.currency,
      description: "# " + invoice.number.toString(),
    }).then((response) => {
      if (response.ok) {
        DataActions.createNotification({
          entity: "ClientSetupViewInvoicePaid",
          variable: "# " + invoice.number.toString(),
          type: 1,
          receiverId: invoice.userProfile.id,
          success: true,
        });
        this.resolvePayment();
        return true;
      }
      this.rejectPayment("Invoice payment could not be transferred");
      return Promise.reject("Invoice payment could not be transferred");
    });
  };

  render() {
    if (!this.state.userProfile.id) {
      return <LoadingIndicator />;
    }
    return (
      <ClientInvoicesView
        invoices={this.state.invoices}
        userProfile={this.state.userProfile}
        locale={this.state.userSettings.locale}
        onPayInvoice={this.onPayInvoice}
        onTransferPaymentFromCard={this.onTransferPaymentFromCard}
        cardInformation={this.state.cardInformation}
        forceShowCards={this.state.userSettings.showDocumentCards}
        onToggleCards={this.onToggleCards}
      />
    );
  }
}
