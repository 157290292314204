import React from "react";

export default class LoadingIndicator extends React.PureComponent {
  render() {
    return (
      <div style={{ minHeight: "400px" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
          }}
        >
          <img
            alt=""
            src="/imgs/app/loading.gif"
            width="250px"
            className="img-circle"
          />
        </div>
      </div>
    );
  }
}
