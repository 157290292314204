import React from "react";
import PropTypes from "prop-types";

import { PrintService, ApiService } from "../services/AxoServices";

import { LexButton, AxoLocal } from "../utilities/LexUtilities";

import { ButtonToolbar } from "react-bootstrap";

const ListItem = (props) => {
  return <div style={{ paddingBottom: "5px" }}>{props.children}</div>;
};

export default class ModelTemplateDetailsList extends React.PureComponent {
  static propTypes = {
    model: PropTypes.object.isRequired,
  };

  onPrint = () => {
    if (typeof window !== "undefined") {
      PrintService.printElement(document.getElementById("printList"));
    }
  };

  render() {
    let { model, onEdit } = this.props;

    return (
      <div className="leftPadding topPadding standardMaxWidth">
        <ButtonToolbar>
          <LexButton onClick={onEdit}>
            <AxoLocal entity="presentationMarketing30" defaultValue="Rediger" />
          </LexButton>
          <LexButton onClick={this.onPrint}>
            <AxoLocal
              entity="PrintCaseClientadressePrint"
              defaultValue={"Print"}
            />
          </LexButton>
        </ButtonToolbar>
        <div id="printList">
          <ListItem>
            <strong>Text:</strong> {model.text}
          </ListItem>
          <ListItem>
            <strong>Long text:</strong> {model.longText}
          </ListItem>
          <ListItem>
            <strong>Number:</strong> {model.number}
          </ListItem>
          <ListItem>
            <strong>Active:</strong> {model.isActive ? "Yes" : "No"}
          </ListItem>
          {model.imageId && !!model.image ? (
            <ListItem>
              <div style={{ maxWidth: "250px" }}>
                {model.image.fileName}
                <img
                  width="100%"
                  alt=""
                  src={ApiService.getFileInlinePath(model.imageId)}
                />
              </div>
            </ListItem>
          ) : (
            <ListItem>Intet billede</ListItem>
          )}
          {!!model.thingies ? (
            <div>
              <ListItem>
                <strong>Ting:</strong>
              </ListItem>
              {model.thingies.map((thingy, index) => (
                <div>
                  <div>
                    <strong>Ting #{index + 1}</strong>
                  </div>
                  <div>
                    <strong>Navn: </strong>
                    {thingy.name}
                  </div>
                  <div>
                    <strong>Beskrivelse: </strong>
                    {thingy.description}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
