import React from "react";
import PresentationActions from "../../../marketing/services/PresentationActions";
import PresentationCreateView from "../../../marketing/Presentation/PresentationCreateView";
import QueryString from "query-string";

import {
  DataStore,
  DataActions,
  LocalizationService,
  RoutingService,
} from "../../../services/AxoServices";

import {
  withRouter,
  LexButton,
  AxoLocal,
} from "../../../utilities/LexUtilities";

class LegalPresentationCreateContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      presentation: this.makeNewPresentation(),
      userProfile: {},
      locale: "enUS",
      showImageFileSizeWarning: false,
      showVideoFileSizeWarning: false,
      insertingAt: -1,
    };
    this.addedIndex = -1;
    this.name = "LegalPresentationCreateContainer";
  }

  makeNewPresentation = (index) => {
    return {
      creationDate: new Date(),
      title: "",
      items: [],
      index: index,
    };
  };

  componentDidMount() {
    let query = QueryString.parse(this.props.location.search);

    if (!!query.id) {
      this.subscribeToPresentation(query.id);
    }

    DataStore.subscribe(this.name, (store) => {
      this.setState({ userProfile: store.userProfile });
    });
    DataStore.initializeUserProfile();

    LocalizationService.subscribeToLocale(this.name, (locale) => {
      this.setState({ locale });
    });
  }

  subscribeToPresentation = (id) => {
    DataStore.subscribe(this.name + "-presentation", (store) => {
      let presentation = store.legalPresentations.find(
        (p) => p.id === parseInt(id, 10)
      );
      if (presentation) {
        var viewModel = Object.assign({}, presentation);
        viewModel = this.lockItems(viewModel);

        //The newly added item should be unlocked
        if (this.addedIndex > -1 && viewModel.items.length > this.addedIndex) {
          viewModel.items[this.addedIndex].locked = false;
          this.addedIndex = -1;
        }

        this.setState({ presentation: viewModel });
      }
    });
    DataStore.fetchLegalPresentations();
  };

  lockItems = (presentation) => {
    presentation.items.forEach((item) => {
      item.locked = true;
    });
    return presentation;
  };

  componentWillUnmount() {
    DataStore.unsubscribe(this.name + "-presentation");
    DataStore.unsubscribe(this.name);
    LocalizationService.unsubscribeFromLocale(this.name);

    let presentation = this.state.presentation;
    if (!presentation.id) {
      presentation.items.forEach((item) => {
        if (item.attachment) {
          DataActions.deleteDocument(item.attachment.id);
        }
      });
    }
  }

  onPresentationPropertyChange = (propertyName, event) => {
    var presentation = Object.assign({}, this.state.presentation);
    presentation[propertyName] = event.target.value;
    this.setState({ presentation });
  };

  onAddItem = (type, index) => {
    var presentation = JSON.parse(JSON.stringify(this.state.presentation)); //Deep clone
    if (presentation.items.length > 0) {
      presentation = this.lockItems(presentation);
    }
    index = index > -1 ? index : presentation.items.length;
    var newItem = this.makeNewItem(type, index);
    presentation = this.addItemAtIndex(presentation, newItem, index);
    this.setState({
      presentation,
      insertingAt: -1,
    });
    this.addedIndex = index;

    this.saveItem(newItem);
  };

  onAddImageItem = (file, index) => {
    var presentation = Object.assign({}, this.state.presentation);
    if (presentation.items.length > 0) {
      presentation = this.lockItems(presentation);
    }
    index = index > -1 ? index : presentation.items.length;
    var newItem = this.makeNewItem(2, index);
    newItem.attachment = file;
    presentation = this.addItemAtIndex(presentation, newItem, index);
    this.setState({
      presentation,
      insertingAt: -1,
    });
    this.addedIndex = index;

    this.saveItem(newItem);
  };

  onAddVideoItem = (file, index) => {
    var presentation = Object.assign({}, this.state.presentation);
    if (presentation.items.length > 0) {
      presentation.items[presentation.items.length - 1].locked = true;
    }
    index = index > -1 ? index : presentation.items.length;
    var newItem = this.makeNewItem(3, index);
    newItem.attachment = file;
    presentation = this.addItemAtIndex(presentation, newItem, index);

    this.setState({
      presentation,
      insertingAt: -1,
    });
    this.addedIndex = index;

    this.saveItem(newItem);
  };

  addItemAtIndex = (presentation, item, index) => {
    presentation.items.splice(index, 0, item);
    return presentation;
  };

  saveItem = (item) => {
    if (this.state.presentation.id) {
      item.presentationId = this.state.presentation.id;
      if (item.id) {
        return PresentationActions.updatePresentationItem(item).then(() =>
          DataStore.fetchLegalPresentations()
        );
      } else {
        return PresentationActions.createPresentationItem(item).then(() =>
          DataStore.fetchLegalPresentations()
        );
      }
    }
  };

  onChangeAttachment = (index, file) => {
    var presentation = Object.assign({}, this.state.presentation);
    var item = presentation.items[index];
    DataActions.deleteDocument(item.attachment.id).then((response) => {
      if (response.ok) {
        item.attachment = file;
        this.setState({ presentation });
      }
    });
  };

  onRemoveItem = (index) => {
    var item = this.state.presentation.items[index];
    if (item.attachment) {
      this.removeAttachmentItem(item, index);
    } else {
      this.doRemoveItem(index);
    }
  };

  removeAttachmentItem = (item, index) => {
    DataActions.deleteDocument(item.attachment.id).then((response) => {
      if (response.ok) {
        this.doRemoveItem(index);
      }
    });
  };

  doRemoveItem = (index) => {
    var presentation = Object.assign({}, this.state.presentation);
    var item = presentation.items.splice(index, 1)[0];
    this.setState({ presentation });

    PresentationActions.deletePresentationItem(item.id);
  };

  makeNewItem = (type, index) => {
    return {
      title: "",
      description: "",
      type: type,
      index: index,
    };
  };

  onChangeItemValue = (index, propertyName, event) => {
    var presentation = Object.assign({}, this.state.presentation);
    presentation.items[index][propertyName] = event.target.value;
    this.setState({ presentation });
  };

  onItemDescriptionChange = (index, content) => {
    var presentation = Object.assign({}, this.state.presentation);
    presentation.items[index].description = content;
    this.setState({ presentation });
  };

  onSetItemLockStatus = (index, locked) => {
    var presentation = Object.assign({}, this.state.presentation);
    var item = presentation.items[index];
    item.locked = locked;
    this.setState({ presentation });

    //Save existing items when the user clicks 'Save'
    if (item.locked && item.id) {
      PresentationActions.updatePresentationItem(item);
    }
  };

  onSave = () => {
    let presentation = Object.assign({}, this.state.presentation);
    if (presentation.items.length === 0) {
      return;
    }

    presentation.legal = true; //Show on legal advice page

    //Create new presentation
    if (!presentation.id) {
      PresentationActions.createPresentation(presentation)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject("Presentation could not be created.");
        })
        .then(this.handlePresentationResponse)
        .catch((reason) => {
          console.log(reason);
        });
    }
    //Save existing presentation
    else {
      presentation.items.forEach((item) => {
        this.saveItem(item);
      });
      PresentationActions.updatePresentation(presentation)
        .then((response) => {
          if (response.ok) {
            this.handlePresentationResponse(presentation);
            return true;
          }
          return Promise.reject("Presentation could not be updated.");
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  };

  handlePresentationResponse = (presentation) => {
    this.setState({
      presentation,
      editing: false,
    });
    let link = RoutingService.getPath("Admin/Presentation/" + presentation.id);
    this.props.history.push(link);
  };

  onTogglePublic = (presentationId) => {
    let newPresentation = Object.assign({}, this.state.presentation);
    newPresentation.public = !newPresentation.public;
    PresentationActions.updatePresentation(newPresentation)
      .then((response) => {
        if (response.ok) {
          this.setState({ presentation: newPresentation });
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  onShowImageFileSizeWarning = () => {
    this.setState({ showImageFileSizeWarning: true });
    setTimeout(() => {
      this.setState({ showImageFileSizeWarning: false });
    }, 3000);
  };

  onShowVideoFileSizeWarning = () => {
    this.setState({ showVideoFileSizeWarning: true });
    setTimeout(() => {
      this.setState({ showVideoFileSizeWarning: false });
    }, 3000);
  };

  onInsertAt = (index) => {
    this.setState({ insertingAt: index });
  };

  onCancelInsertion = () => {
    this.setState({ insertingAt: -1 });
  };

  ListButton = (props) => {
    return (
      <div style={{ width: "250px", margin: "auto", paddingTop: "25px" }}>
        <LexButton
          onClick={() =>
            this.props.history.push(
              RoutingService.getPath("Admin/PresentationList")
            )
          }
          style={{ width: "250px" }}
        >
          <AxoLocal
            entity="presentationMarketing35"
            defaultValue="Gå til liste"
          />
        </LexButton>
      </div>
    );
  };

  render() {
    let ListButton = this.ListButton;
    
    return (
      <div>
        <ListButton />
        <PresentationCreateView
          presentation={this.state.presentation}
          userProfile={this.state.userProfile}
          locale={this.state.locale}
          onPresentationPropertyChange={this.onPresentationPropertyChange}
          onAddItem={this.onAddItem}
          onRemoveItem={this.onRemoveItem}
          onChangeItemValue={this.onChangeItemValue}
          onItemDescriptionChange={this.onItemDescriptionChange}
          onAddImageItem={this.onAddImageItem}
          onAddVideoItem={this.onAddVideoItem}
          onSetItemLockStatus={this.onSetItemLockStatus}
          onChangeAttachment={this.onChangeAttachment}
          onSave={this.onSave}
          insertingAt={this.state.insertingAt}
          onInsertAt={this.onInsertAt}
          onCancelInsertion={this.onCancelInsertion}
          showImageFileSizeWarning={this.state.showImageFileSizeWarning}
          showVideoFileSizeWarning={this.state.showVideoFileSizeWarning}
          onShowImageFileSizeWarning={this.onShowImageFileSizeWarning}
          onShowVideoFileSizeWarning={this.onShowVideoFileSizeWarning}
        />
      </div>
    );
  }
}

export default withRouter(LegalPresentationCreateContainer);
