import React from "react";

class AxoCheckbox extends React.PureComponent {
  render() {
    return (
      <div className="AxolexcheckboxContainer">
        <label className="Axolexcheckbox">
          <input type="checkbox" {...this.props} />
          <span className="Axolexcheckmark"></span>
        </label>
      </div>
    );
  }
}

export default AxoCheckbox;
