import React from "react";
import PropTypes from "prop-types";

import MultiPaymentView from "../../routes/TimeEntries/MultiPaymentView";

export default class CollectionView extends React.PureComponent {
  static propTypes = {
    invoices: PropTypes.array.isRequired,
    clientLabels: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
  };

  render() {
    let { invoices, clientLabels } = this.props;

    return (
      <div className="standardMaxWidth center-block">
        <MultiPaymentView invoices={invoices} clientLabels={clientLabels} />
      </div>
    );
  }
}
