import React from "react";

import { AxoLocal } from "../../utilities/LexUtilities";
import { Table } from "react-bootstrap";

//Balance
export default class BalanceView extends React.PureComponent {
  render() {
    var { postings } = this.props;

    // var accountNames = {
    //   Nettomsætning: 'Likviditet',
    //   Vareforbrug: ''
    // };

    var active = {
      bank: 0,
      debit: 0,
      capitalAccount: 0,
      interest: 0,
      receivable: 0,
      liquidity: 0,
    };
    var passive = {
      ownCapital: 0,
      creditIncome: 0,
      debt: 0,
      interest: 0,
    };

    var netIncome = postings.reduce((acc, posting) => {
      return acc + (posting.income - posting.expenses);
    }, 0);

    if (netIncome > 0) {
      active.bank = netIncome;
    }
    if (netIncome < 0) {
      passive.creditIncome = netIncome;
    }

    var loan = postings.reduce((acc, posting) => {
      return acc + posting.debt;
    }, 0);
    if (loan > 0) {
      active.debit = loan;
    }
    if (loan < 0) {
      passive.creditLoan = netIncome;
    }

    var misc = postings.reduce((acc, posting) => {
      return acc + posting.misc;
    }, 0);

    if (misc > 0) {
      active.bank += misc;
    }
    if (misc < 0) {
      passive.creditIncome += misc;
    }

    var capitalAccount = postings.reduce((acc, posting) => {
      return acc + posting.capitalAccount;
    }, 0);

    if (capitalAccount > 0) {
      active.capitalAccount = capitalAccount;
    }
    if (capitalAccount < 0) {
      passive.debt += capitalAccount;
    }

    var interest = postings.reduce((acc, posting) => {
      return acc + posting.interest;
    }, 0);

    if (interest > 0) {
      active.interest = interest;
    }
    if (interest < 0) {
      passive.interest = interest;
    }

    var vat = postings.reduce((acc, posting) => {
      return acc + posting.ingoingVat - posting.outgoingVat;
    }, 0);

    if (vat > 0) {
      active.receivable = vat;
    }
    if (vat < 0) {
      passive.debt += vat;
    }

    var bank = postings.reduce((acc, posting) => {
      return acc + posting.bank;
    }, 0);

    if (bank > 0) {
      active.liquidity = bank;
    }
    if (bank < 0) {
      active.debt += bank;
    }

    return (
      <div
        className=" "
        style={{ maxWidth: "900px", margin: "auto", padding: "25px" }}
      >
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            paddingLeft: "10px",
            paddingBottom: "25px",
          }}
        >
          <h3>
            <AxoLocal
              entity="AccountingTabViewEntity4"
              defaultValue={"Balance"}
            />
          </h3>
        </div>

        <div
          className="Axoaccountsbannebg text-center"
          style={{ height: "40px", lineHeight: "40px" }}
        >
          <AxoLocal
            entity="AccountingTabViewEntity34"
            defaultValue={"Aktiver"}
          />
        </div>
        <Table responsive>
          <tbody>
            <tr>
              <th>
                <AxoLocal entity="DocumentGridViewType" defaultValue={"Type"} />
              </th>
              <th>
                <AxoLocal
                  entity="TimeEntriesViewValue"
                  defaultValue={"Værdi"}
                />
              </th>
            </tr>
            {active.bank > 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity24"
                    defaultValue={"Bank"}
                  />
                </td>
                <td>{active.bank}</td>
              </tr>
            ) : null}
            {active.debit > 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity35"
                    defaultValue={"Debit"}
                  />
                </td>
                <td>{active.debit}</td>
              </tr>
            ) : null}
            {active.capitalAccount > 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity36"
                    defaultValue={"Kapitalkonto"}
                  />
                </td>
                <td>{active.capitalAccount}</td>
              </tr>
            ) : null}
            {active.interest > 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity20"
                    defaultValue={"Renter"}
                  />
                </td>
                <td>{active.interest}</td>
              </tr>
            ) : null}
            {active.receivable > 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity37"
                    defaultValue={"Tilgodehavender"}
                  />
                </td>
                <td>{active.receivable}</td>
              </tr>
            ) : null}
            {active.liquidity > 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity38"
                    defaultValue={"Likviditet"}
                  />
                </td>
                <td>{active.liquidity}</td>
              </tr>
            ) : null}
          </tbody>
        </Table>
        <div
          className="Axoaccountsbannebg text-center"
          style={{ height: "40px", lineHeight: "40px" }}
        >
          <AxoLocal
            entity="AccountingTabViewEntity39"
            defaultValue={"Passiver"}
          />
        </div>
        <Table responsive>
          <tbody>
            <tr>
              <th>
                <AxoLocal entity="DocumentGridViewType" defaultValue={"Type"} />
              </th>
              <th>
                <AxoLocal
                  entity="TimeEntriesViewValue"
                  defaultValue={"Værdi"}
                />
              </th>
            </tr>
            {passive.ownCapital < 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity40"
                    defaultValue={"Egenkapital"}
                  />
                </td>
                <td>{active.ownCapital}</td>
              </tr>
            ) : null}
            {passive.creditIncome < 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity41"
                    defaultValue={"Kredit"}
                  />
                </td>
                <td>{active.debit}</td>
              </tr>
            ) : null}
            {passive.debt < 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity42"
                    defaultValue={"Debet"}
                  />
                </td>
                <td>{passive.debit}</td>
              </tr>
            ) : null}
            {passive.interest < 0 ? (
              <tr>
                <td>
                  <AxoLocal
                    entity="AccountingTabViewEntity20"
                    defaultValue={"Renter"}
                  />
                </td>
                <td>{passive.interest}</td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </div>
    );
  }
}
