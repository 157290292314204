import React from "react";
import ApiService from "../services/DataAccess/ApiService";
import { ctx } from "../utilities/L20n/L20n";
import RoutingService from "../services/RoutingService";

import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  Icon,
  AlertModal,
  AxoLocal,
  Link,
  FlagMenu,
  AxoFooter,
} from "../utilities/LexUtilities";

export default class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      confirmSend: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // $('html').addClass('authentication');
  }

  componentWillUnmount() {
    // $('html').removeClass('authentication');
  }

  onSend = () => {
    if (!this.state.userName) {
      return;
    }
    ApiService.forgotPassword({
      userName: this.state.userName,
      resetEmailSubject: ctx.getSync("ForgotPasswordMailSubject"),
      resetEmailMessage: ctx.getSync("ForgotPasswordMailMessage"),
      messageUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        RoutingService.getAccountPath("ResetPassword"),
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ confirmSend: true });
      } else {
        this.alertModal.open(
          <AxoLocal
            entity="SidebarRightConfirmaconfirmSend"
            defaultValue={"Mailen kunne ikke sendes. Prøv igen senere."}
          />
        );
      }
    });
  };

  renderBody = () => {
    if (this.state.confirmSend) {
      return (
        <div style={{ padding: 0 }}>
          <div>
            <h4 className="text-center" style={{ padding: 12.5 }}>
              <AxoLocal
                entity="ForgotPasswordCheckemailtoresetpassword"
                defaultValue={"Tjek din email for at nulstille dit password."}
              />
            </h4>
            <h4 className="text-center" style={{ padding: 12.5 }}>
              <Link to="/">
                <AxoLocal
                  entity="ForgotPasswordBacktofrontpage"
                  defaultValue={"Tilbage til forsiden"}
                />
              </Link>
            </h4>
          </div>
        </div>
      );
    }
    return (
      <div style={{ padding: 0 }}>
        <div>
          <div className="text-center" style={{ padding: 12.5 }}>
            <h4>
              <AxoLocal
                entity="ForgotPasswordBla"
                defaultValue={"Indtast brugernavn eller email."}
              />
            </h4>
          </div>
          <div
            style={{
              padding: 25,
              paddingTop: 0,
              paddingBottom: 0,
              margin: "auto",
              marginBottom: 25,
              marginTop: 25,
            }}
          >
            <Form className="text-center">
              <FormGroup controlId="UserName">
                <InputGroup bsSize="large">
                  <InputGroup.Addon>
                    <Icon glyph="icon-simple-line-icons-users" />
                  </InputGroup.Addon>
                  <AxoLocal
                    componentClass={FormControl}
                    autoFocus
                    type="text"
                    className="borderFormControlfocus"
                    value={this.state.userName}
                    onChange={(event) => {
                      this.setState({ userName: event.target.value });
                    }}
                    componentAttribute="placeholder"
                    entity="bccMailplaceholderBrugernavn"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Grid fluid>
                  <Row>
                    <Col
                      xs={6}
                      className="nopadding"
                      style={{ paddingTop: 10 }}
                    ></Col>
                    <Col xs={6} className="text-center nopadding">
                      <Button
                        className="Lex-button-2"
                        style={{
                          height: "65px",
                          width: "250px",
                          fontSize: "17px",
                          fontWeight: "normal",
                        }}
                        onClick={this.onSend}
                      >
                        <span>
                          {" "}
                          <Icon glyph="icon-fontello-forward-1" />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <AxoLocal
                            entity="ForgotPasswordSend"
                            defaultValue={"Send"}
                          />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Grid>
                <div className="text-right">
                  <Link
                    style={{ color: "cblue" }}
                    to={RoutingService.getAccountPath("Login")}
                  >
                    <Icon glyph="icon-fontello-login" />
                    &nbsp;&nbsp;
                    <AxoLocal entity="LoginonLogin" defaultValue={"Log ind"} />
                  </Link>
                </div>
              </FormGroup>
            </Form>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="login SideHeight" style={{ paddingTop: "25px" }}>
        <div>
          <div>
            <Grid fluid>
              <Row>
                <Col xs={12} className="nopadding" style={{ maxWidth: "100%" }}>
                  <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center">
                    <AxoLocal
                      entity="ForgotPassword"
                      defaultValue={"Glemt dit kodeord?"}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img
                      alt=""
                      className="hidden-lg hidden-sm hidden-md"
                      src="/imgs/app/Axologo3-30x30.png"
                    />
                    <img
                      alt=""
                      className="hidden-lg hidden-md hidden-xs"
                      src="/imgs/app/Axologo3-35x35.png"
                    />
                    <img
                      alt=""
                      className="hidden-lg hidden-sm hidden-xs"
                      src="/imgs/app/Axologo3-40x40.png"
                    />
                    <img
                      alt=""
                      className="hidden-md hidden-sm hidden-xs"
                      src="/imgs/app/Axologo3-40x40.png"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FlagMenu
                      // className='Lex-button-2'
                      userSettings={{}}
                    />
                  </div>
                </Col>
                <Col
                  md={8}
                  mdOffset={2}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%px", marginTop: "25px" }}
                >
                  <div className="text-center">
                    <div className="text-center">{this.renderBody()}</div>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <AlertModal ref={(c) => (this.alertModal = c)} />
        <AxoFooter />
      </div>
    );
  }
}
