import React from "react";

import { AxoLocal } from "../utilities/LexUtilities";

export default class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      info: {},
      error: {},
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error,
      info,
    });

    console.log(error.toString());
    console.log(info.componentStack);
  }

  render() {
    let { hasError, error, info } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h3>
            <AxoLocal
              entity="axoAccounting54"
              defaultValue={
                "An error occurred. Refresh the page and try again."
              }
            />
          </h3>
          <div>{error.toString()}</div>
          <div>{info.componentStack}</div>
        </div>
      );
    }
    return this.props.children;
  }
}
