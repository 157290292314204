import RoutingService from "../../services/RoutingService";

const endPoint = RoutingService.getHostPath();

const querySettings = {
  accept: "application/json",
  credentials: "include",
};

var postSettings = {
  method: "POST",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
var putSettings = {
  method: "PUT",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
var deleteSettings = {
  method: "DELETE",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

function parseJSON(response) {
  if (response.status === 200) {
    return response.json();
  } else if (response.status === 401) {
    return Promise.reject(
      "401 - Unauthorized. Ensure that user is logged in with an authorized account."
    );
  }
  return Promise.reject(response.status);
}

export default {
  //Presentation
  getPresentations() {
    return fetch(endPoint + "/api/Presentations", querySettings).then(
      parseJSON
    );
  },

  getPresentationsForUser(userId) {
    return fetch(
      endPoint + "/api/Presentations/user/" + userId,
      querySettings
    ).then(parseJSON);
  },

  queryPresentations(shown, query) {
    return fetch(
      endPoint + "/api/Presentations/public?shown=" + shown + "&query=" + query,
      querySettings
    ).then(parseJSON);
  },

  getSinglePresentation(presentationId) {
    return fetch(
      endPoint + "/api/Presentations/single/" + presentationId,
      querySettings
    ).then(parseJSON);
  },

  getSpecificPublicPresentation(userId, presentationId) {
    return fetch(
      endPoint + "/api/Presentations/specific/" + userId + "/" + presentationId,
      querySettings
    ).then(parseJSON);
  },

  getPublicPresentationInformation(userId) {
    return fetch(
      endPoint + "/api/Presentations/publicInfo/" + userId,
      querySettings
    ).then(parseJSON);
  },

  createPresentation(presentation) {
    postSettings.body = JSON.stringify(presentation);
    return fetch(endPoint + "/api/Presentations", postSettings);
  },
  updatePresentation(presentation) {
    putSettings.body = JSON.stringify(presentation);
    return fetch(
      endPoint + "/api/Presentations/" + presentation.id,
      putSettings
    );
  },
  incrementViews(presentation) {
    putSettings.body = JSON.stringify(presentation);
    return fetch(
      endPoint + "/api/Presentations/incrementViews/" + presentation.id,
      putSettings
    );
  },
  deletePresentation(presentationId) {
    return fetch(
      endPoint + "/api/Presentations/" + presentationId,
      deleteSettings
    );
  },
  reportPresentation(reportViewModel) {
    postSettings.body = JSON.stringify(reportViewModel);
    return fetch(endPoint + "/api/Presentations/report", postSettings);
  },
  //Presentation items
  getPresentationItems() {
    return fetch(endPoint + "/api/PresentationItems", querySettings).then(
      parseJSON
    );
  },
  createPresentationItem(presentationItem) {
    postSettings.body = JSON.stringify(presentationItem);
    return fetch(endPoint + "/api/PresentationItems", postSettings);
  },
  updatePresentationItem(presentationItem) {
    putSettings.body = JSON.stringify(presentationItem);
    return fetch(
      endPoint + "/api/PresentationItems/" + presentationItem.id,
      putSettings
    );
  },
  deletePresentationItem(presentationItemId) {
    return fetch(
      endPoint + "/api/PresentationItems/" + presentationItemId,
      deleteSettings
    );
  },
};
