import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
  // ApiService,
  DataActions,
} from "../services/AxoServices";

import {
  LexButton,
  LoadingIcon,
  FileViewerModal,
  AxoLocal,
} from "../utilities/LexUtilities";

import { Grid, Row, Col, ButtonToolbar } from "react-bootstrap";

// const ListItem = (props) => {
//   return <div style={{ paddingBottom: '5px'}}>{props.children}</div>
// }

export default class BusinessOperatingDetailsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPrinting: false,
      isFetchingHTML: false,
    };

    this.containerRef = React.createRef();
    this.fileModal = React.createRef();
  }

  static propTypes = {
    model: PropTypes.object.isRequired,
  };

  onPrint = () => {
    window.print();
    // if (typeof window !== 'undefined') {
    //   PrintService.printElement(document.getElementById('printList'));
    // }
  };

  onPrintPdf = () => {
    this.setState(
      {
        isPrinting: true,
        isFetchingHTML: true,
      },
      async () => {
        this.setState({ isFetchingHTML: false });
        let response = await DataActions.printHtmlToPDF({
          html: this.containerRef.current.innerHTML,
          fileName: "Vedtægter",
          marginTop: 35,
          marginRight: 35,
          marginBottom: 35,
          marginLeft: 35,
          includePageNumbers: true,
        });
        if (!response.ok) {
          this.setState({ isPrinting: false });
          return;
        }
        let newFile = await response.json();
        this.fileModal.current.open([newFile]);
        this.setState({ isPrinting: false });
      }
    );
  };

  render() {
    let { model, onEdit } = this.props;

    let { isPrinting, isFetchingHTML } = this.state;

    return (
      <div className="leftPadding topPadding">
        <ButtonToolbar className="noprint">
          <LexButton onClick={onEdit}>
            <AxoLocal entity="presentationMarketing30" defaultValue="Rediger" />
          </LexButton>
          <LexButton onClick={this.onPrint}>
            <AxoLocal
              entity="PrintCaseClientadressePrint"
              defaultValue={"Print"}
            />
          </LexButton>
          <LexButton disabled={isPrinting} onClick={this.onPrintPdf}>
            <AxoLocal
              entity="DocumentTemplateGenerateButtonContent"
              defaultValue={"Generer PDF"}
            />
            <LoadingIcon show={isPrinting} />
          </LexButton>
        </ButtonToolbar>
        <div
          ref={this.containerRef}
          className="leftPadding topPadding standardMaxWidth"
        >
          <div
            className={
              isFetchingHTML ? "padidingprint" : "linepadding padidingprint"
            }
            style={{ fontSize: "22px", lineHeight: "2" }}
          >
            <div>
              {/* { model.pictureID && !!model.picture ? (
            <ListItem>
              <div style={{ maxWidth: '250px'}}>  
                <img width='100%' alt='' src={ApiService.getFileInlinePath( model.pictureID )} />
              </div>
            </ListItem>
          ) : (
            null
          ) } */}

              {/* { !!model.owners ? (
            <div>
              <ListItem><strong>Ejer:</strong></ListItem>
              { model.owners.map((owner, index) => (
                <div key={owner.id}>
                  <div><strong>Ejer #{index + 1}</strong></div>
                  <div><strong>Navn: </strong>{owner.name}</div>
                  <div><strong>Adresse: </strong>{owner.address}</div>
                  <div><strong>CPR/CVR: </strong>{owner.identityCode}</div>
                  <div><strong>Ejerandel: </strong>{owner.shareholding}</div>
                  <div><strong>Stemmerettigheder: </strong>{owner.votingRights}</div>
                </div>
              )) }
            </div>
          ) : null } */}

              <h3>
                <div>
                  <strong>{model.companyName}</strong>
                </div>
              </h3>
              <div>
                <h1>Stiftelsesdokument</h1>
              </div>
              <div
                className="pagebreakprint"
                style={{ pageBreakAfter: "always" }}
              ></div>
              <div className="printpagepadding">
                <div>
                  <strong>Undertegnede</strong>
                </div>
                <Grid>
                  <Row>
                    {model.owners.map((owner) => (
                      <Col xs={6} key={owner.id}>
                        {/* <div><strong>Stifter #{index + 1}</strong></div> */}
                        <div>
                          <strong></strong>
                          {owner.name}
                        </div>
                        <div>
                          <strong></strong>
                          {owner.address}
                        </div>
                        {!!owner.identityCode && (
                          <div>
                            <strong>CPR/CVR: </strong>
                            {owner.identityCode}
                          </div>
                        )}
                        <div>
                          <strong>Ejerandel: </strong>
                          {owner.shareholding}
                        </div>
                        <div>
                          <strong>Stemmerettigheder: </strong>
                          {owner.votingRights}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Grid>

                <div>stifter herved et selskab under navnet</div>
                <div>{model.companyName}</div>
                <div>
                  <strong>1. Vedtægter</strong>
                </div>
                <div>1.1 Selskabets vedtægter er vedhæftet som Bilag A</div>
                <div>
                  <strong>
                    2. Selskabskapital og tegningskurs for anparter
                  </strong>
                </div>
                <div>
                  2.1 Selskabskapitalen udgør nominelt {model.companyCapital}{" "}
                  kr., der er opdelt i anparter på nominelt {model.shareValue}
                  kr.
                </div>
                <div>
                  2.2 Tegningskursen for anparterne er {model.shareCourse}.
                </div>
                <div>
                  <strong>3. Tegning og indbetaling af anparter</strong>
                </div>
                <div>
                  3.1 Alle anparter skal være tegnet senest den{" "}
                  {moment.utc(model.latestSignedDate).format("L")}
                </div>
                <div>
                  3.2 Alle anparter skal være fuldt indbetalt senest den{" "}
                  {moment.utc(model.latestPaidDate).format("L")}.
                </div>
                <div>
                  <strong>4. Retsvirkning</strong>
                </div>
                <div>
                  4.1 Stiftelsen af selskabet skal have retsvirkning pr.{" "}
                  {moment.utc(model.companyActiveDate).format("L")}
                </div>
                <div>
                  <strong>5. Regnskabsår og revision</strong>
                </div>
                <div>
                  5.1 Stiftelsen af selskabet skal have regnskabsmæssig virkning
                  fra {moment.utc(model.companyAccountingDate).format("L")}.
                </div>
                <div>
                  {!!model.accountingRequired ? (
                    <div>
                      5.2 Selskabets årsrapporter skal revideres af{" "}
                      {model.accountingFirmName}
                      med CVRnr. {model.accountingFirmCode}{" "}
                    </div>
                  ) : (
                    <div> 5.2 Selskabets årsrapporter skal ikke revideres</div>
                  )}
                </div>
              </div>

              <div className="printpagepadding">
                <div>
                  <strong>6. Ledelse</strong>
                </div>
                <div>6.1. Selskabet ledes af en direktion.</div>
                <div>
                  <strong>7.0 Tegningsregel</strong>
                </div>
                <div>7.1. Direktion</div>
                <div>
                  Indtil selskabets første ordinære generalforsamling valgtes{" "}
                  {model.directorName} til direktionen.
                </div>
                <div>7.2. Stiftelsesomkostninger</div>
                <div>
                  Omkostningerne ved stiftelsen afholdes af stifterne.
                  Omkostningerne forventes at beløbe sig til{" "}
                  {model.foundingCosts} kr. ekskl moms.
                </div>

                <div>Undertegnede stifter herved selskabet:</div>
                {model.owners.map((owner) => (
                  <div key={owner.id}>
                    {/* <div><strong>Stifter #{index + 1}</strong></div> */}
                    <div>Dato: {moment.utc(model.signingDate).format("L")}</div>
                    <div>{owner.name}</div>
                    <div>Underskrift: _________________________</div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div
                className="pagebreakprint"
                style={{ pageBreakAfter: "always" }}
              ></div>
              <div className="printpagepadding">
                <div>
                  <h4>Bilag A</h4>
                </div>
                <h1>Vedtægter til {model.companyName}</h1>{" "}
              </div>

              <div
                className="pagebreakprint"
                style={{ pageBreakAfter: "always" }}
              ></div>
              <div className="printpagepadding">
                <div>
                  <h3>Vedtægter</h3>
                </div>
                <div>
                  <strong>1.0 Selskabets navn og formål</strong>
                </div>
                <div></div>
                <div>1.1 Selskabets navn er {model.companyName}. </div>
                <div>1.2 {model.companyPurpose}</div>
                <div>
                  <strong>2.0 Selskabskapital, anparter og ejerbog</strong>
                </div>
                <div></div>
                <div>
                  2.1 Selskabskapitalen er på nominelt {model.companyCapital}{" "}
                  kr.{" "}
                </div>
                <div>
                  2.2 Selskabskapitalen er opdelt i anparter á nominelt{" "}
                  {model.shareValue} kr.
                </div>
                <div>
                  2.3 Hver anpart giver {model.shareVotes}{" "}
                  {model.shareVotes > 1 ? <>stemmer</> : <>stemme</>}. Ingen
                  anparter har særlige rettigheder.{" "}
                </div>
                <div>
                  2.4 Selskabets ejerbog skal være tilgængelig elektronisk for
                  selskabets anpartshavere.
                </div>
                <div>
                  <strong>3.0 Overgang af anparter</strong>
                </div>
                <div> </div>
                <div>
                  3.1 Overgang af anparter kan kun ske med samtykke af
                  selskabets generalforsamling. Overgang omfatter enhver form
                  for ejerskifte, hvad enten det sker ved salg, gave, bytte,
                  arv, opløsning af ægteskab, sikkerhedsstillelse,
                  kreditorforfølgning, retsforfølgning eller på anden måde.{" "}
                </div>
                <div>
                  3.2 Anpartshaveres fortegningsret ved kontant forhøjelse af
                  selskabskapitalen kan ikke overdrages til tredjemand.{" "}
                </div>
                <div>
                  {!!model.includeAlcoholRules ? (
                    <div>
                      {" "}
                      <div>
                        3.3 Anparter kan ikke udstedes eller overdrages til
                        nogen, der fremstiller eller engrosforhandler
                        drikkevarer.
                      </div>
                      <div>
                        3.4 Alle andelsbeviser og anparter skal lyde på og
                        noteres på navn.
                      </div>
                      <div>
                        3.5 Overdragelse af aktier, anparter eller andelsbeviser
                        kræver samtykke fra direktionen.
                      </div>
                      <div>
                        3.6 Selskabet skal til alle tider følge alle
                        bestemmelser i Restaurationslovens § 16.
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="pagebreakprint"></div>
              <div className="printpagepadding">
                <div>
                  <strong> 4.0 Generalforsamling</strong>
                </div>
                <div></div>
                <div>
                  {" "}
                  4.1 Generalforsamling indkaldes af direktionen tidligst fire
                  uger og senest to uger før generalforsamlingen. Indkaldelse
                  sker på selskabets hjemmeside og elektronisk til alle
                  anpartshavere, der er noteret i ejerbogen og har bedt om
                  skriftlig indkaldelse.
                </div>
                <div>
                  4.2 En anpartshaver, der ønsker at deltage i en
                  generalforsamling, skal meddele det til selskabet senest tre
                  dage før generalforsamlingen.{" "}
                </div>
                <div>
                  <strong>5.0 Ledelse</strong>
                </div>
                <div></div>
                <div> 5.1 Selskabets ledelse består af en direktion. </div>
                <div>
                  <strong>6.0 Regnskabsår</strong>
                </div>
                <div></div>
                <div>
                  6.1 Selskabets regnskabsår går fra {model.fiscalYearStart} til{" "}
                  {model.fiscalYearEnd}. Selskabets første regnskabsår går
                  fra&nbsp;
                  {moment.utc(model.firstFiscalYearStart).format("L")} til{" "}
                  {moment.utc(model.firstFiscalYearEnd).format("L")}.
                </div>
                <div>
                  <strong>7.0 Tegningsregel</strong>
                </div>
                <div>7.1 {model.signingRule}</div>
                <div>
                  <strong>8.0 Elektronisk kommunikation</strong>
                </div>
                <div></div>
                <div>
                  8.1 Selskabet og anpartshaverne kan anvende elektronisk
                  dokumentudveksling og elektronisk post i deres kommunikation
                  med hinanden.
                </div>
                <div>
                  8.2 Indkaldelse til generalforsamling, herunder dagsorden,
                  beslutningsforslag, årsrapport og andre dokumenter; tilmelding
                  til generalforsamling; referat af generalforsamling; ejerbog
                  og andre generelle og individuelle oplysninger kan
                  kommunikeres elektronisk.
                </div>
              </div>

              <div className="pagebreakprint"></div>
              <div className="printpagepadding">
                <div>
                  8.3 Kommunikation mellem selskab og anpartshavere foregår via
                  de elektroniske adresser, som de har oplyst over for hinanden.
                </div>
                <div>
                  8.4 Oplysning om kravene til de anvendte systemer og om
                  fremgangsmåden i forbindelse med elektronisk kommunikation kan
                  fås ved henvendelse til selskabet.
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div>{model.text}</div>
                <div>
                  Således vedtaget den{" "}
                  {moment.utc(model.approvedDate).format("L")}
                </div>
              </div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <FileViewerModal ref={this.fileModal} />
      </div>
    );
  }
}
