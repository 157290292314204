import React from "react";
import { withRouter } from "react-router-dom";

import { DataActions, DataStore } from "../../services/AxoServices";

import { withRTKMessageData } from "../../utilities/LexUtilities";

import InboxView from "../../routes/Email/InboxView";

class ClientInboxContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    let store = DataStore.getStore();
    this.state = {
      emailAccounts: [],
      userProfile: {},
      isUpdating: false,
      loadingMore: false,
      query: store.messageQuery,
    };

    this.name = "ClientInboxContainer";
  }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      this.setState({
        userProfile: store.userProfile,
        query: store.messageQuery,
      });
    });
    DataStore.initializeUserProfile();
    DataStore.initializeLabels();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  toggleImportance = async (messageId) => {
    let { updateMessageFromHeader } = this.props;

    var message = this.props.messageMap.messages.find((message) => {
      return message.id === messageId;
    });
    if (!message) {
      message = this.state.externalMails.find((message) => {
        return message.id === messageId;
      });
    }
    var newMessage = Object.assign({}, message);
    newMessage.important = !newMessage.important;

    try {
      await updateMessageFromHeader(newMessage).unwrap();
      if (newMessage.externalUniqueId) {
        //Update IMAP message on mail server
        DataActions.updateExternalMails({
          accountId: newMessage.externalAccountId,
          messageIds: [newMessage.externalUniqueId],
          updateImportant: true,
          important: newMessage.important,
          isSent: !!(
            message.sender && message.sender.id === this.state.userProfile.id
          ),
        });
      }
    } catch {}

    // updateMessageFromHeader( newMessage ).then(response => {
    //   if(response.ok && newMessage.externalUniqueId) {
    //     //Update IMAP message on mail server
    //     DataActions.updateExternalMails({
    //       accountId: newMessage.externalAccountId,
    //       messageIds: [newMessage.externalUniqueId],
    //       updateImportant: true,
    //       important: newMessage.important,
    //       isSent: !!(message.sender && message.sender.id === this.state.userProfile.id)
    //     });
    //   }
    // });
  };

  toggleRead = async (messageId) => {
    let { updateMessageFromHeader } = this.props;

    var message = this.props.messageMap.messages.find((message) => {
      return message.id === messageId;
    });
    if (!message) {
      message = this.state.externalMails.find((message) => {
        return message.id === messageId;
      });
    }
    let newMessage = {
      ...message,
      read: !message.read,
    };

    try {
      await updateMessageFromHeader(newMessage).unwrap();
      if (newMessage.externalUniqueId) {
        //Update IMAP message on mail server
        DataActions.updateExternalMails({
          accountId: newMessage.externalAccountId,
          messageIds: [newMessage.externalUniqueId],
          markAsRead: newMessage.read,
          markAsUnread: !newMessage.read,
          important: newMessage.important,
          isSent: !!(
            message.sender && message.sender.id === this.state.userProfile.id
          ),
          isSpam: message.spam,
        });
      }
    } catch {}

    // if(response.ok && newMessage.externalUniqueId) {
    //   //Update IMAP message on mail server
    //   DataActions.updateExternalMails({
    //     accountId: newMessage.externalAccountId,
    //     messageIds: [newMessage.externalUniqueId],
    //     markAsRead: newMessage.read,
    //     markAsUnread: !newMessage.read,
    //     important: newMessage.important,
    //     isSent: !!(message.sender && message.sender.id === this.state.userProfile.id),
    //     isSpam: message.spam
    //   });
    // }
  };

  deleteExternalMessages = (messages) => {
    DataActions.deleteExternalMessages(messages, this.state.userProfile.id);
  };

  sortByDate = (m1, m2) => {
    return new Date(m1.date) - new Date(m2.date);
  };

  onDeleteAccount = (accountId) => {
    this.setState({ confirmDeletionAccountId: accountId });
  };

  onConfirmAccountDeletion = () => {
    this.setState({ accountDeletionInProgress: true });
    DataActions.deleteEmailAccount(this.state.confirmDeletionAccountId)
      .then((response) => {
        this.setState({
          accountDeletionInProgress: false,
          confirmDeletionAccountId: 0,
        });
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ accountDeletionInProgress: false });
      });
  };

  onCancelAccountDeletion = () => {
    this.setState({ confirmDeletionAccountId: 0 });
  };

  onShowMore = () => {
    this.setState({ loadingMore: true });
    DataStore.increaseMessagePageSize()
      .then((response) => {
        this.setState({ loadingMore: false });
      })
      .catch((reason) => {
        this.setState({ loadingMore: false });
      });
    // this.setState({
    //   pageSize: this.state.pageSize + 20
    // });
  };

  render() {
    var { messageMap } = this.props;
    if (!messageMap) {
      return null;
    }

    return (
      <InboxView
        {...this.props}
        {...this.state}
        onShowMore={this.onShowMore}
        loadingMore={this.state.loadingMore}
        labels={[]}
        userProfile={this.state.userProfile}
        emailAccounts={this.state.emailAccounts}
        accountStatuses={this.state.accountStatuses}
        accountOrder={this.state.accountOrder}
        updateAccountOrder={() => {}}
        toggleImportance={this.toggleImportance}
        toggleRead={this.toggleRead}
        deleteExternalMessages={this.deleteExternalMessages}
        isUpdating={this.state.isUpdating}
        onDeleteAccount={this.onDeleteAccount}
        confirmDeletionAccountId={this.state.confirmDeletionAccountId}
        onConfirmAccountDeletion={this.onConfirmAccountDeletion}
        onCancelAccountDeletion={this.onCancelAccountDeletion}
        accountDeletionInProgress={this.state.accountDeletionInProgress}
      />
    );
  }
}

export default withRTKMessageData(withRouter(ClientInboxContainer));
