import React from "react";
import CalendarTabView from "./Calendar/CalendarTabView";
import CreateCalendarEventModal from "./Calendar/CreateCalendarEventModal";
import { withRouter } from "react-router-dom";
import { ctx } from "../utilities/L20n/L20n";

import {
  DataStore,
  DataActions,
  RoutingService,
} from "../services/AxoServices";

import { withRTKCaseData } from "../utilities/LexUtilities";

import { Row, Col, Grid } from "react-bootstrap";

var ctrlKeyIsDown = false;

class CalendarContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cases: [],
      caseDeadlines: [],
      documentDeadlines: [],
      eventViewModels: [],
      userProfile: {},
      userSettings: {},
      userGroup: {},
      friends: [],
      sharingGlobals: [],
    };
    this.name = "CalendarContainer";
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.onkeydown = function (event) {
        if (event.keyCode === 17 && !ctrlKeyIsDown) {
          ctrlKeyIsDown = true;
        }
      };
      window.onkeyup = function (event) {
        if (event.keyCode === 17) {
          ctrlKeyIsDown = false;
        }
      };
    }
    DataStore.subscribe(this.name, (store) => {
      let eventSharings = store.sharingGlobals.filter((s) => s.type === 1); //Calendar event sharings

      let events = store.calendarEvents.map((c) => {
        var viewModel = Object.assign({}, c);
        // viewModel.title = <span><Icon glyph='icon-fontello-user'/> {viewModel.title}</span>;
        viewModel.color = this.getEventColor(viewModel);
        viewModel.start = new Date(viewModel.start);
        viewModel.end = new Date(viewModel.end);
        return viewModel;
      });

      this.setState({
        userProfile: store.userProfile,
        userSettings: store.userSettings,
        cases: store.caseMap.saved,
        caseDeadlines: this.extractEventsFromCases(store.caseMap.saved),
        documents: store.documentMap.documents,
        documentDeadlines: this.extractEventsFromDocuments(
          store.documentMap.documents
        ),
        userGroup: store.userGroup,
        friends: store.friends,
        sharingGlobals: eventSharings,
        eventViewModels: events,
        showDropdowns: store.showDropdowns,
      });
    });

    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.initializeCases();
    DataStore.initializeDocuments();
    DataStore.fetchCalendarEvents();
    DataStore.initializeUserGroup();
    DataStore.initializeFriends();
    DataStore.fetchSharingGlobals();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  getEventColor = (viewModel) => {
    if (viewModel.color) {
      return viewModel.color;
    }
    if (viewModel.bookingTime) {
      if (viewModel.eventBooking && viewModel.eventBooking.confirmedBooking) {
        return "#55A69F";
      } else {
        return "#78756D";
      }
    }
    return "";
  };

  extractEventsFromCases = (cases) => {
    return cases.map((caseModel) => {
      return {
        title:
          ctx.getSync("extractEventsFromCasesDeadlineforcase") +
          caseModel.caseNumber,
        allDay: true,
        start: new Date(caseModel.actionDate),
        end: new Date(caseModel.actionDate),
        description: "",
        deadlineCaseId: caseModel.id,
      };
    });
  };

  extractEventsFromDocuments = (documents) => {
    var documentEvents = [];
    documents.forEach((document) => {
      var deadLineDate = new Date(document.deadline);
      if (deadLineDate.getFullYear() < 1971) {
        return;
      }
      documentEvents.push({
        title:
          ctx.getSync("extractEventsFromCasesDeadlineforDocument") +
          document.fileName,
        allDay: true,
        start: new Date(document.deadline),
        end: new Date(document.deadline),
        description: "",
        deadlineDocumentId: document.id,
      });
    });
    return documentEvents;
  };

  getAllEventsFromArrays = (caseEvents, documentEvents, eventViewModels) => {
    return caseEvents.concat(documentEvents).concat(eventViewModels);
  };

  findOverlappingEvent = (eventId, start, end) => {
    return this.state.eventViewModels.find((e) => {
      return e.id !== eventId && start < e.end && end > e.start;
    });
  };

  //(StartA <= EndB)  and  (EndA >= StartB)
  findAvailableStartTime = (eventId, start, end) => {
    var intervalLength = end.getTime() - start.getTime();
    var newStart = new Date(start.getTime());
    var newEnd = new Date(end.getTime());

    var overlappingEvent = this.findOverlappingEvent(eventId, start, end);
    while (overlappingEvent) {
      newStart = new Date(overlappingEvent.end.getTime());
      newEnd = new Date(newStart.getTime() + intervalLength);

      overlappingEvent = this.findOverlappingEvent(eventId, newStart, newEnd);
    }
    return newStart;
  };

  onMoveEvent = ({ event, start, end }) => {
    let newStart = this.findAvailableStartTime(
      !ctrlKeyIsDown ? event.id : -1,
      start,
      end
    );
    let newEnd = new Date(
      newStart.getTime() + (end.getTime() - start.getTime())
    );
    if (ctrlKeyIsDown) {
      //Copy event
      if (event.deadlineCaseId || event.deadlineDocumentId) {
        return;
      }
      let currentEvent = this.state.eventViewModels.find(
        (c) => c.id === event.id
      );
      let calendarEvent = Object.assign({}, currentEvent);
      delete calendarEvent.id;
      calendarEvent.start = newStart;
      calendarEvent.end = newEnd;
      DataActions.createCalendarEvent(calendarEvent);
      return;
    }
    if (event.deadlineCaseId) {
      this.updateDeadlineState(event.deadlineCaseId, newStart, newEnd); //Optimistic updating
      this.updateDeadlineInDatabase(event.deadlineCaseId, newStart, newEnd); //Server updating
    } else if (event.deadlineDocumentId) {
      this.updateDocumentDeadlineState(
        event.deadlineDocumentId,
        newStart,
        newEnd
      ); //Optimistic updating
      this.updateDocumentDeadlineInDatabase(
        event.deadlineDocumentId,
        newStart,
        newEnd
      ); //Server updating
    } else {
      //Optimistic updating
      this.setState((prevState) => ({
        eventViewModels: prevState.eventViewModels.map((e) => {
          if (e.id !== event.id) {
            return e;
          }
          return {
            ...e,
            start: newStart,
            end: newEnd,
          };
        }),
      }));
      let currentEvent = this.state.eventViewModels.find(
        (c) => c.id === event.id
      );
      DataActions.updateCalendarEvent({
        ...currentEvent,
        start: newStart,
        end: newEnd,
      });
    }
  };

  updateDeadlineState = (caseId, start, end) => {
    this.setState((prevState) => ({
      caseDeadlines: prevState.caseDeadlines.map((cd) => {
        if (cd.id !== caseId) {
          return cd;
        }
        return {
          ...cd,
          start,
          end,
        };
      }),
    }));
  };

  updateDeadlineInDatabase = (caseId, start, end) => {
    var currentCase = { ...this.state.cases.find((c) => c.id === caseId) };

    currentCase.actionDate = start;
    currentCase.reminded = false;
    DataActions.updateCase(currentCase);
  };

  updateDocumentDeadlineState = (documentId, start, end) => {
    this.setState((prevState) => ({
      documentDeadlines: prevState.documentDeadlines.map((dd) => {
        if (dd.id !== documentId) {
          return dd;
        }
        return {
          ...dd,
          start,
          end,
        };
      }),
    }));
  };

  updateDocumentDeadlineInDatabase = (documentId, start, end) => {
    var currentDocument = {
      ...this.state.documents.find((d) => d.id === documentId),
    };

    currentDocument.deadline = start;
    currentDocument.reminded = false;
    DataActions.updateDocument(currentDocument);
  };

  onSelectSlot = (slotInfo) => {
    this.calendarEventModal.open(this.makeCalendarEvent(slotInfo));
  };

  makeCalendarEvent = (slotInfo) => {
    let start = new Date(slotInfo.start.getTime() + 12 * 60 * 60 * 1000);
    let end = new Date(start.getTime() + 2 * 60 * 60 * 1000);

    return {
      title: "",
      start,
      end,
      allDay: false,
      bookingCapacity: 1,
      description: "",
      color: "",
    };
  }

  onSelectEvent = (event) => {
    this.calendarEventModal.open(event);
  };

  onUpdateEvent = (calendarEvent) => {
    return DataActions.updateCalendarEvent(calendarEvent);
  };

  onDeleteEvent = (eventId) => {
    return DataActions.deleteCalendarEvent(eventId);
  };

  onUpdateCaseDeadline = (caseId, dateTime) => {
    var currentCase = this.state.cases.find((c) => c.id === caseId);
    currentCase.actionDate = dateTime;
    currentCase.reminded = false;
    return DataActions.updateCase(currentCase);
  };

  onUpdateDocumentDeadline = (documentId, dateTime) => {
    var currentDocument = this.state.documents.find((d) => d.id === documentId);
    currentDocument.deadline = dateTime;
    currentDocument.reminded = false;
    return DataActions.updateDocument(currentDocument);
  };

  onCreateCalendarEvent = (calendarEvent) => {
    return DataActions.createCalendarEvent(calendarEvent);
  };

  getActiveTab = (props) => {
    let path = props.location.pathname.toLowerCase();
    if (path.includes("month")) {
      return "Month";
    }
    if (path.includes("week")) {
      return "Week";
    }
    if (path.includes("day")) {
      return "Day";
    }
    if (path.includes("agenda")) {
      return "Agenda";
    }
    if (path.includes("createbooking")) {
      return "CreateBooking";
    }
    if (path.includes("create")) {
      return "Create";
    }
    return "Month";
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(RoutingService.getPath("Calendar/" + eventKey));
  };

  render() {
    var events = this.getAllEventsFromArrays(
      this.state.caseDeadlines,
      this.state.documentDeadlines,
      this.state.eventViewModels
    );
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            <CalendarTabView
              activeTab={this.getActiveTab(this.props)}
              events={events}
              onUpdateEvent={this.onUpdateEvent}
              onCreateCalendarEvent={this.onCreateCalendarEvent}
              onEventDrop={this.onMoveEvent}
              onSelectEvent={this.onSelectEvent}
              onSelectSlot={this.onSelectSlot}
              handleNavigation={this.handleNavigation}
              {...this.state}
            />
          </Col>
          <CreateCalendarEventModal
            onUpdateEvent={this.onUpdateEvent}
            onUpdateCaseDeadline={this.onUpdateCaseDeadline}
            onUpdateDocumentDeadline={this.onUpdateDocumentDeadline}
            onDelete={this.onDeleteEvent}
            onCreateCalendarEvent={this.onCreateCalendarEvent}
            userProfile={this.state.userProfile}
            userGroup={this.state.userGroup}
            friends={this.state.friends}
            sharingGlobals={this.state.sharingGlobals}
            router={this.props.router}
            ref={(c) => (this.calendarEventModal = c)}
          />
        </Row>
      </Grid>
    );
  }
}

export default withRTKCaseData(withRouter(CalendarContainer));
