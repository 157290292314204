import React from "react";
import PropTypes from "prop-types";
import { Table, Column, Cell } from "fixed-data-table-2";
import Select from "react-select";

// import {
//   AuthorizationService
// } from '../../../services/AxoServices'

import {
  Icon,
  LexButton,
  AxoCheckbox,
  TableBase,
  Dimensions,
  DataListWrapper,
  SortHeaderCell,
  AxoLocal,
  InlineEdit,
  InlineNumberEdit,
  getText,
  Flexbox,
  FlexElement,
  AsyncButton,
} from "../../../utilities/LexUtilities";

import {
  FormControl,
  FormGroup,
  InputGroup,
  DropdownButton,
  MenuItem,
  ButtonToolbar,
} from "react-bootstrap";

const defaultColumnDefs = {
  number: {
    width: 150,
    shown: true,
  },
  name: {
    width: 250,
    shown: true,
  },
  type: {
    width: 100,
    shown: true,
  },
  isCredit: {
    width: 200,
    shown: true,
  },
  vat: {
    width: 150,
    shown: true,
  },
  interval: {
    width: 400,
    shown: true,
  },
  actions: {
    width: 300,
    shown: true,
  },
};

class AccountPlanTable extends TableBase {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
    onToggleCards: PropTypes.func.isRequired,
    selectedPlan: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    updateAccountProperty: PropTypes.func.isRequired,
    addAccountToHeader: PropTypes.func.isRequired,
    onDeleteAccount: PropTypes.func.isRequired,
    updateSelectedPlanProperty: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.name = "AccountPlansTable";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = TableBase.generateDefaultSortIndices(
      props.entries
    );
    let tableEntries = props.entries;
    this.state = {
      tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        columnName: 110,
      },
      maxColumnWidth: 500,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      filter: "all",
      csv: [[]],
    };

    this.toggleColumns = ["vat", "interval"];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { sortedDataList, searchText, filter } = prevState;
    let { selectedPlan } = nextProps;

    let tableEntries = nextProps.entries;
    let filteredEntries = AccountPlanTable.getFilteredEntries(
      tableEntries,
      searchText,
      filter,
      selectedPlan
    );

    return TableBase.getNewEntries(
      tableEntries,
      filteredEntries,
      sortedDataList
    );
  }

  onSearch = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText }, this.onUpdateFilteredEntries);
  };

  onFilter = (filter) => {
    this.setState({ filter }, this.onUpdateFilteredEntries);
  };

  onUpdateFilteredEntries = () => {
    let { tableEntries, searchText, filter } = this.state;
    let { selectedPlan } = this.props;
    let entries = AccountPlanTable.getFilteredEntries(
      tableEntries,
      searchText,
      filter,
      selectedPlan
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  static getFilteredEntries = (entries, searchText, filter, selectedPlan) => {
    if (!searchText && filter === "all") {
      return entries;
    }

    let filteredEntries = entries.filter((c) => {
      let target = c.number.toString() + c.name;
      if (!target.toLowerCase().includes(searchText.toLowerCase())) {
        return false;
      }
      if (filter !== "all") {
        if (filter === "result") {
          return (
            c.number >= selectedPlan.resultStart &&
            c.number <= selectedPlan.resultEnd
          );
        }
        if (filter === "balance") {
          return (
            c.number >= selectedPlan.balanceStart &&
            c.number <= selectedPlan.balanceEnd
          );
        }
      }
      return true;
    });

    return filteredEntries;
  };

  renderColumnDropdown = () => {
    let columns = this.toggleColumns;
    let { columnDefs } = this.state;
    let allEnabled = !columns.find((col) => !columnDefs[col].shown);

    return (
      <DropdownButton
        id="ColumnDropdown"
        className=" visButtoncolor axoDropdownButton VisButton"
        title={
          <span style={{ fontSize: "15px" }}>
            <AxoLocal entity="axoAccounting70" defaultValue={"Vis"} />
          </span>
        }
      >
        <MenuItem eventKey="all">
          <div
            className="text-center"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <AxoCheckbox
              checked={allEnabled}
              onChange={this.onEnableAll.bind(this, !allEnabled)}
            />
            <AxoLocal entity="axoidcode124" defaultValue={"Vis alle"} />
          </div>
        </MenuItem>
        {columns.map((column) => {
          return (
            <MenuItem key={column} eventKey={column}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <AxoCheckbox
                  checked={columnDefs[column].shown}
                  onChange={this.onSelectColumn.bind(this, column)}
                />
                &nbsp;{this.getColumnTitle(column)}
              </div>
            </MenuItem>
          );
        })}
      </DropdownButton>
    );
  };

  getColumnTitle = (columnName) => {
    switch (columnName) {
      case "number":
        return (
          <span>
            <AxoLocal entity="axoAccounting6" defaultValue={"Kontonummer"} />
          </span>
        );
      case "name":
        return (
          <span>
            <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />
          </span>
        );
      case "vat":
        return (
          <span>
            <AxoLocal entity="axoAccounting8" defaultValue={"Moms"} />
          </span>
        );
      case "interval":
        return (
          <span>
            <AxoLocal entity="axoAccounting9" defaultValue={"Suminterval"} />
          </span>
        );
      default:
        return <span></span>;
    }
  };

  getPlanCSV = () => {
    let { selectedPlan, financeAccounts } = this.props;
    let headerEntry = [selectedPlan.name, "", "", "", "", ""];

    let headerEntry2 = [
      getText("axoAccounting6", "Kontonummer"),
      getText("axoAccounting7", "Navn"),
      getText("axoAccounting7", "Navn"),
      getText("AccountingTabViewEntity41", "Kredit") +
        "/" +
        getText("AccountingTabViewEntity42", "Debet"),
      getText("axoAccounting14", "Moms"),
      getText("axoAccounting15", "Suminterval"),
    ];

    let rows = selectedPlan.accounts.map((a) => {
      let vat = "";
      if (!this.isHeaderType(a.type) || !!a.taxSpecificationId) {
        let spec = selectedPlan.taxSpecifications.find(
          (t) => t.id === a.taxSpecificationId
        );
        vat = spec ? spec.id : "";
      }
      let sumText = "";
      if (a.sumIntervalEnd > 0) {
        sumText += a.sumIntervalStart + "-" + a.sumIntervalEnd;
      } else if (!!a.summedAccounts && a.summedAccounts.length > 0) {
        a.summedAccounts.forEach((id) => {
          let acc = financeAccounts[id];
          sumText += " " + acc.number;
        });
      }

      return [a.number, a.name, a.type, a.isCredit ? "C" : "D", vat, sumText];
    });

    return [headerEntry, headerEntry2, ...rows];
  };

  exportJSON = () => {
    let { selectedPlan } = this.props;

    let contect = JSON.stringify(selectedPlan);
    const element = document.createElement("a");
    const file = new Blob([contect], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "plan.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  render() {
    let { filter } = this.state;

    // let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div>
        <div
          className="axobg"
          style={{ paddingTop: "10px", marginBottom: "5px" }}
        >
          <Flexbox
            spaceBetween
            alignCenter
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            {/* <FlexElement onClick={this.exportJSON}>
              <LexButton>Eksporter JSON</LexButton>
            </FlexElement> */}
            <FlexElement>
              <FormControl
                componentClass="select"
                value={filter}
                onChange={(event) => this.onFilter(event.target.value)}
              >
                <option value="all">
                  {getText("axoidcode124", "Vis alle")}
                </option>
                <option value="result">
                  {getText("axoidcode125", "Kun resultatopgørelse")}
                </option>
                <option value="balance">
                  {getText("axoidcode126", "Kun balancekonti")}
                </option>
              </FormControl>
            </FlexElement>
            <FlexElement>
              <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                <FormGroup controlId="inputWithIcon">
                  <InputGroup>
                    <InputGroup.Addon>
                      <Icon glyph="icon-fontello-search" />
                    </InputGroup.Addon>
                    {/* Søg...  */}
                    <AxoLocal
                      componentClass={FormControl}
                      type="text"
                      value={this.state.searchText}
                      onChange={this.onSearch}
                      componentAttribute="placeholder"
                      entity="ContactTableViewplaceholderonSearch"
                    />
                    <FormControl.Feedback>
                      <Icon glyph="icon-fontello-search" />
                    </FormControl.Feedback>
                  </InputGroup>
                </FormGroup>
              </div>
            </FlexElement>
            {this.renderColumnDropdown()}
          </Flexbox>
        </div>
        {this.renderTable()}
      </div>
    );
  }

  isHeaderType = (accountType) => {
    return accountType === "Header" || accountType === "Sum";
  };

  getTypeLabel = (type) => {
    switch (type) {
      case "Header":
        return (
          <span>
            <AxoLocal entity="axoAccounting10" defaultValue={"Overskrift"} />
          </span>
        );
      case "Standard":
        return (
          <span>
            <AxoLocal entity="axoAccounting11" defaultValue={"Drift"} />
          </span>
        );
      case "Sum":
        return (
          <span>
            <AxoLocal entity="axoAccounting12" defaultValue={"Sumkonto"} />
          </span>
        );
      default:
        return <span></span>;
    }
  };

  getAccountTextElement = (type, text) => {
    if (this.isHeaderType(type)) {
      return (
        <h5>
          <b>{text}</b>
        </h5>
      );
    }
    return <span>{text}</span>;
  };

  renderTable = () => {
    let { sortedDataList, colSortDirs, columnDefs } = this.state;

    let {
      selectedPlan,
      financeAccounts,
      containerHeight,
      containerWidth,
      editing,
      updateAccountProperty,
      addAccountToHeader,
      onDeleteAccount,
    } = this.props;

    let tableWidth = containerWidth;
    let taxOptions = selectedPlan.taxSpecifications.map((f) => {
      return { value: f.id, label: f.code + " - " + f.name };
    });

    let accountOptions = selectedPlan.accounts.map((acc) => {
      return {
        value: acc.id.toString(),
        label: acc.number + " - " + (acc.name || ""),
      };
    });

    return (
      <Table
        rowHeight={40}
        rowsCount={sortedDataList.getSize()}
        width={tableWidth}
        height={containerHeight}
        isColumnResizing={false}
        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
        headerHeight={40}
      >
        {columnDefs.number.shown ? (
          <Column
            columnKey="number"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.number}
              >
                <AxoLocal
                  entity="axoAccounting6"
                  defaultValue={"Kontonummer"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {editing ? (
                    <InlineNumberEdit
                      value={entry.number}
                      change={(input) =>
                        updateAccountProperty(
                          entry.id,
                          "number",
                          parseInt(input.value)
                        )
                      }
                    />
                  ) : (
                    <div>
                      {entry.number}
                      {/* {!this.isHeaderType(entry.type) ? entry.number : ''} */}
                    </div>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.number.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.name.shown ? (
          <Column
            columnKey="name"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.name}
              >
                <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {editing ? (
                    <InlineEdit
                      value={entry.name || ""}
                      change={(input) =>
                        updateAccountProperty(entry.id, "name", input.value)
                      }
                    />
                  ) : (
                    <div>
                      {this.getAccountTextElement(entry.type, entry.name)}
                    </div>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.name.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.type.shown ? (
          <Column
            columnKey="type"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.type}
              >
                <AxoLocal entity="axoAccounting13" defaultValue={"Type"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {this.getAccountTextElement(
                    entry.type,
                    this.getTypeLabel(entry.type)
                  )}
                </Cell>
              );
            }}
            width={columnDefs.type.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.isCredit.shown ? (
          <Column
            columnKey="isCredit"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.isCredit}
              >
                <AxoLocal
                  entity="AccountingTabViewEntity41"
                  defaultValue={"Kredit"}
                />
                /
                <AxoLocal
                  entity="AccountingTabViewEntity42"
                  defaultValue={"Debet"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              if (entry.type !== "Standard") {
                return <span></span>;
              }
              return (
                <Cell key={entry.id} {...props}>
                  {editing ? (
                    <select
                      value={entry.isCredit ? "true" : ""}
                      onChange={(event) =>
                        updateAccountProperty(
                          entry.id,
                          "isCredit",
                          !!event.target.value
                        )
                      }
                    >
                      <option value="true">
                        {getText("AccountingTabViewEntity41", "Kredit")}
                      </option>
                      <option value="">
                        {getText("AccountingTabViewEntity42", "Debet")}
                      </option>
                    </select>
                  ) : (
                    <div>
                      {entry.isCredit ? (
                        <span>
                          {" "}
                          <AxoLocal
                            entity="AccountingTabViewEntity41"
                            defaultValue={"Kredit"}
                          />
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <AxoLocal
                            entity="AccountingTabViewEntity42"
                            defaultValue={"Debet"}
                          />
                        </span>
                      )}
                    </div>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.isCredit.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.vat.shown ? (
          <Column
            columnKey="vat"
            header={
              <Cell>
                <AxoLocal entity="axoAccounting14" defaultValue={"Moms"} />
              </Cell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {(() => {
                    if (this.isHeaderType(entry.type)) {
                      return <span></span>;
                    }
                    if (editing) {
                      return (
                        <div>
                          <Select
                            name="tax-select"
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            // menuContainerStyle={props.rowIndex > 5 ? {top: 'auto', bottom: '100%'}: null}
                            value={
                              taxOptions.find(
                                (o) => o.value === entry.taxSpecificationId
                              ) || { value: "", label: "..." }
                            }
                            onChange={(selectedTax) => {
                              if (!!selectedTax) {
                                updateAccountProperty(
                                  entry.id,
                                  "taxSpecificationId",
                                  selectedTax.value
                                );
                              }
                            }}
                            noOptionsMessage={() => ""}
                            options={[
                              { value: null, label: "..." },
                              ...taxOptions,
                            ]}
                          />
                        </div>
                      );
                    }
                    if (!entry.taxSpecificationId) {
                      return <span></span>;
                    }
                    var spec = selectedPlan.taxSpecifications.find(
                      (t) => t.id === entry.taxSpecificationId
                    );
                    return (
                      <span>{spec ? spec.code + " - " + spec.name : ""}</span>
                    );
                  })()}
                </Cell>
              );
            }}
            width={columnDefs.vat.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.interval.shown ? (
          <Column
            columnKey="interval"
            header={
              <Cell>
                <AxoLocal
                  entity="axoAccounting15"
                  defaultValue={"Suminterval"}
                />
              </Cell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {(() => {
                    if (entry.type !== "Sum") {
                      return <span></span>;
                    }
                    if (editing) {
                      return (
                        <div>
                          {!entry.summedAccounts ||
                          entry.summedAccounts.length === 0 ? (
                            <React.Fragment>
                              <InlineNumberEdit
                                value={entry.sumIntervalStart}
                                change={(input) =>
                                  updateAccountProperty(
                                    entry.id,
                                    "sumIntervalStart",
                                    input.value
                                  )
                                }
                              />
                              &nbsp;-&nbsp;
                              <InlineNumberEdit
                                value={entry.sumIntervalEnd}
                                change={(input) =>
                                  updateAccountProperty(
                                    entry.id,
                                    "sumIntervalEnd",
                                    input.value
                                  )
                                }
                              />
                            </React.Fragment>
                          ) : null}
                          {!!entry.summedAccounts &&
                          entry.summedAccounts.length > 0 ? (
                            <React.Fragment>
                              &nbsp;&nbsp;&nbsp; ({" "}
                              {entry.summedAccounts.map((id) => {
                                var acc = financeAccounts[id];
                                return (
                                  <React.Fragment key={acc.id}>
                                    <strong>{acc.number} </strong>
                                    <Icon
                                      role="button"
                                      glyph="icon-fontello-trash-1"
                                      onClick={() => {
                                        updateAccountProperty(
                                          entry.id,
                                          "summedAccounts",
                                          entry.summedAccounts.filter(
                                            (accId) => accId !== id
                                          )
                                        );
                                      }}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                  </React.Fragment>
                                );
                              })}
                              )
                            </React.Fragment>
                          ) : null}
                          <div
                            className="inlineBlock"
                            style={{ paddingLeft: "15px", minWidth: "150px" }}
                          >
                            <Select
                              name="summedAccounts"
                              menuPlacement="auto"
                              menuPortalTarget={document.body}
                              value={{
                                value: "0",
                                label: getText("axoAccounting6f", "Vælg konto"),
                              }}
                              onChange={(selection, arg) => {
                                let id =
                                  !!selection && selection.value !== "0"
                                    ? parseInt(selection.value, 10)
                                    : null;
                                if (id && !entry.summedAccounts.includes(id)) {
                                  updateAccountProperty(
                                    entry.id,
                                    "summedAccounts",
                                    entry.summedAccounts.concat([id])
                                  );
                                }
                              }}
                              noOptionsMessage={() => ""}
                              options={[
                                {
                                  value: "0",
                                  label: getText(
                                    "axoAccounting6f",
                                    "Vælg konto"
                                  ),
                                },
                                ...accountOptions,
                              ]}
                            />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <Flexbox>
                        {entry.sumIntervalEnd > 0 ? (
                          <div>
                            {entry.sumIntervalStart} - {entry.sumIntervalEnd}
                          </div>
                        ) : null}

                        {!!entry.summedAccounts &&
                        entry.summedAccounts.length > 0 ? (
                          <div>
                            &nbsp; ({" "}
                            {entry.summedAccounts.map((id) => {
                              var acc = financeAccounts[id];
                              return <span key={acc.id}>{acc.number}; </span>;
                            })}
                            )
                          </div>
                        ) : null}
                      </Flexbox>
                    );
                  })()}
                </Cell>
              );
            }}
            width={columnDefs.interval.width}
            isResizable={true}
          />
        ) : null}
        <Column
          columnKey="actions"
          header={<Cell></Cell>}
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {(() => {
                  if (!editing) {
                    return <span></span>;
                  }
                  if (entry.type === "Header") {
                    return (
                      <ButtonToolbar>
                        <LexButton
                          onClick={() => addAccountToHeader(entry.number)}
                        >
                          <AxoLocal
                            entity="axoAccounting16"
                            defaultValue={"Tiføj underkonto"}
                          />
                        </LexButton>
                        <AsyncButton onClick={() => onDeleteAccount(entry)}>
                          <Icon glyph="icon-fontello-trash-1" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            entity="axoAccounting17"
                            defaultValue={"Slet overskrift"}
                          />
                        </AsyncButton>
                      </ButtonToolbar>
                    );
                  }
                  return (
                    <ButtonToolbar>
                      <AsyncButton onClick={() => onDeleteAccount(entry)}>
                        <Icon glyph="icon-fontello-trash-1" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="axoAccounting18"
                          defaultValue={"Slet konto"}
                        />
                      </AsyncButton>
                    </ButtonToolbar>
                  );
                })()}
              </Cell>
            );
          }}
          width={columnDefs.actions.width}
          isResizable={true}
        />
      </Table>
    );
  };
}

export default Dimensions({
  elementResize: true,
  getHeight: function () {
    return window.innerHeight - 300;
  },
})(AccountPlanTable);
