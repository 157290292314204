import React from "react";
import PropTypes from "prop-types";
import NotificationsComponent from "../common/notifications";
import TimelineComponent from "../common/timeline";
import FriendRequestTimeline from "../common/friendRequestTimeline";
import NetworkList from "../common/networkList";

import {
  DataActions,
  RoutingService,
  AuthorizationService,
  StringService,
} from "../services/AxoServices";

import { Row, Col, Grid } from "react-bootstrap";

import { Icon, AxoLocal, getText } from "../utilities/LexUtilities";

const AxoSideBarButton = (props) => {
  //Props
  //route
  //activeRoute
  //label
  var buttonClass = "Lex-button-1 axoSidebarbutton";

  if (props.activeExactMatch) {
    //Remove '/' before comparison, so that /Dashboard/ matches /Dashboard
    if (
      StringService.equalsWithoutCase(
        props.activeRoute.replace(/\//g, ""),
        props.route.replace(/\//g, "")
      )
    ) {
      buttonClass += " active";
    }
  } else if (
    props.activeRoute.toLowerCase().includes(props.route.toLowerCase())
  ) {
    buttonClass += " active";
  }

  if (props.smallSidebar) {
    return (
      <button
        title={getText(props.entity)}
        className={buttonClass}
        onClick={props.onRoute.bind(this, props.route)}
      >
        {props.Icon}
      </button>
    );
  }
  return (
    <button
      className={buttonClass}
      onClick={props.onRoute.bind(this, props.route)}
    >
      <Col xs={3} className="nopadding">
        {props.Icon}
      </Col>
      <Col xs={8} className="nopadding">
        {props.label}
      </Col>
    </button>
  );
};

export default class SidebarSimple extends React.PureComponent {
  static propTypes = {
    fixed: PropTypes.bool,
    onSidebarSelect: PropTypes.func.isRequired,
    activeRoute: PropTypes.string.isRequired,
    onRoute: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
    newMessageCount: PropTypes.number.isRequired,
    friends: PropTypes.array.isRequired,
    onSocialClick: PropTypes.func,
    friendRequests: PropTypes.array.isRequired,
    socialNotifications: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
    bookings: PropTypes.array.isRequired,
    deadlineNotifications: PropTypes.array.isRequired,
  };

  onOpenDeadlineNotes = () => {
    this.props.deadlineNotifications.forEach(this.handleNotification);
  };

  onOpenSocialTab = () => {
    this.props.socialNotifications.forEach(this.handleNotification);
  };

  handleNotification = (note) => {
    if (!note.handled) {
      var newNote = Object.assign({}, note);
      newNote.handled = true;
      DataActions.updateNotification(newNote);
    }
  };

  getBookingIcon = () => {
    if (this.props.bookings.length === 0) {
      return "icon-fontello-shareable";
    }
    return "icon-fontello-down-7 axored";
  };

  getNotificationIcon = () => {
    if (
      this.props.deadlineNotifications.filter((n) => !n.handled).length === 0
    ) {
      return "icon-fontello-bell-5";
    }
    return "icon-fontello-info-3 axored";
  };

  getFriendRequestIcon = () => {
    var activeRequests = this.props.friendRequests.filter(
      (f) => f.status === 0
    );
    var unhandledNotes = this.props.socialNotifications.filter(
      (f) => !f.handled
    );
    if (activeRequests.length === 0 && unhandledNotes.length === 0) {
      return "icon-fontello-retweet-3";
    }
    return "icon-fontello-chart-pie-2 axored";
  };

  getSocialEvents = () => {
    var events = this.props.friendRequests.map((f) => {
      var friendRequestEvent = Object.assign({}, f);
      friendRequestEvent.eventType = "friendRequest";
      friendRequestEvent.eventTime = f.requestTime;
      return friendRequestEvent;
    });
    events = events.concat(
      this.props.socialNotifications.map((n) => {
        var noteEvent = Object.assign({}, n);
        noteEvent.eventType = "notification";
        noteEvent.eventTime = n.creationTime;
        return noteEvent;
      })
    );
    var sortedEvents = events
      .sort((e1, e2) => {
        return new Date(e1.eventTime) - new Date(e2.eventTime);
      })
      .reverse();
    return sortedEvents;
  };

  getPath(path) {
    return RoutingService.getPath(path);
  }
  getPublicLabel = (hasUnread) => {
    return hasUnread ? (
      <span>
        <AxoLocal entity="socialTabViewNavPublic" defaultValue={"Fælles"} />{" "}
        &nbsp;{" "}
        <Icon
          style={{ fontSize: "10px", color: "#306C67" }}
          glyph="icon-fontello-circle"
        />
      </span>
    ) : (
      <span>
        <AxoLocal entity="socialTabViewNavPublic" defaultValue={"Fælles"} />{" "}
      </span>
    );
  };

  getInternalLabel = (hasUnread) => {
    return hasUnread ? (
      <span>
        <AxoLocal entity="socialTabViewNavInternal" defaultValue={"Intern"} />{" "}
        &nbsp;{" "}
        <Icon
          style={{ fontSize: "10px", color: "#306C67" }}
          glyph="icon-fontello-circle"
        />
      </span>
    ) : (
      <span>
        <AxoLocal entity="socialTabViewNavInternal" defaultValue={"Intern"} />
      </span>
    );
  };

  render() {
    let {
      fixed,
      activeRoute,
      onRoute,
      handleBackClick,
      newMessageCount,
      friends,
      onSocialClick,
      userProfile,
      userGroup,
      bookings,
      deadlineNotifications,
      sidebarSelection,
      onSidebarSelect,
      smallSidebar,
      onToggleSidebar,
      friendRequests,
      socialNotifications,
      locale,
    } = this.props;

    let className = "Axolexbg main-sidebar noprint" + (fixed ? " fixed" : "");
    className += smallSidebar ? " mini-sidebar" : " ";

    let userType = AuthorizationService.getUserType(userProfile);
    let isNormalGroupMember =
      !!userGroup.id && userGroup.ownerId !== userProfile.id;

    let showNotificationIcon = false;
    if (smallSidebar) {
      showNotificationIcon =
        bookings.length > 0 ||
        deadlineNotifications.filter((n) => !n.handled).length > 0 ||
        friendRequests.filter((f) => f.status === 0).length > 0 ||
        socialNotifications.filter((f) => !f.handled).length > 0;
    }

    return (
      <div className={className} style={{ marginTop: "1px" }}>
        <div style={{ height: "45px" }}>
          {userType !== "Society" && !smallSidebar ? (
            <div className="btn-group btn-group-justified">
              <div className="btn-group">
                <button
                  type="button"
                  className="btnSidebarSelect"
                  onClick={() => onSidebarSelect(0)}
                >
                  <Icon glyph="icon-fontello-cog-7" />
                </button>
              </div>
              {userType === "Admin" ? (
                <div className="btn-group">
                  <button
                    type="button"
                    className="btnSidebarSelect"
                    onClick={() => onSidebarSelect(1)}
                  >
                    <Icon glyph="icon-fontello-chat" />
                  </button>
                </div>
              ) : null}
              <div className="btn-group">
                <button
                  type="button"
                  className="btnSidebarSelect"
                  onClick={() => onSidebarSelect(2)}
                  onMouseUp={this.onOpenSocialTab}
                >
                  <Icon glyph={this.getFriendRequestIcon()} />
                </button>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btnSidebarSelect"
                  onClick={() => onSidebarSelect(3)}
                >
                  <Icon glyph={this.getBookingIcon()} />{" "}
                </button>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btnSidebarSelect"
                  onClick={() => onSidebarSelect(4)}
                  onMouseUp={this.onOpenDeadlineNotes}
                >
                  <Icon glyph={this.getNotificationIcon()} />{" "}
                </button>
              </div>
            </div>
          ) : null}

          {showNotificationIcon ? (
            <button
              className="Lex-button-1 axoSidebarbutton axored"
              onClick={onToggleSidebar}
            >
              <Icon
                className="frontnavbarimg axored"
                glyph="icon-fontello-circle"
              />
            </button>
          ) : null}
          <div className="Axolexbg sidebar-body" style={{ paddingTop: "10px" }}>
            {sidebarSelection === 0 ? (
              <Grid fluid>
                <Row>
                  <Col xs={12} className="nopadding">
                    <div className="backiconcolor1">
                      <button
                        title={
                          smallSidebar ? getText("backButton", "Tilbage") : ""
                        }
                        className="Lex-button-1 axoSidebarbutton"
                        onClick={handleBackClick}
                      >
                        {smallSidebar ? (
                          <Icon
                            className="frontnavbarimg backiconcolor"
                            glyph="icon-fontello-back"
                          />
                        ) : (
                          <React.Fragment>
                            <Col xs={3} className="nopadding">
                              <Icon
                                className="frontnavbarimg backiconcolor"
                                glyph="icon-fontello-back"
                              />
                            </Col>
                            <Col xs={8} className="nopadding">
                              <AxoLocal
                                entity="backButton"
                                defaultValue={"Tilbage"}
                              />
                            </Col>
                          </React.Fragment>
                        )}
                      </button>
                    </div>
                    {/* <button type="button" className="Lex-button-1 axoSidebarbutton" onClick={onToggleSidebar}>
                          <Col xs={3} className="nopadding">
                            <Icon className="frontnavbarimg Axolexgul" glyph='icon-fontello-left-4'/>
                          </Col>
                          <Col xs={8} className="nopadding">
                          <AxoLocal entity='Enhedspris9' defaultValue='Skjult'/>
                          </Col>
                      </button> */}
                  </Col>
                  <div className="Mailiconcolor1">
                    <AxoSideBarButton
                      route={this.getPath("")}
                      activeRoute={activeRoute}
                      activeExactMatch
                      smallSidebar={smallSidebar}
                      onRoute={onRoute}
                      Icon={
                        <Icon
                          className="frontnavbarimg Mailiconcolor"
                          glyph="icon-fontello-mail-1"
                        />
                      }
                      entity="mailBoxNav"
                      label={
                        <span>
                          {" "}
                          <AxoLocal
                            entity="mailBoxNav"
                            defaultValue={"E-boks"}
                          />
                          &nbsp;&nbsp;&nbsp;
                          {newMessageCount > 0 ? newMessageCount : ""}
                        </span>
                      }
                    />
                  </div>

                  <div className="Clienticoncolor1">
                    <AxoSideBarButton
                      route={this.getPath("Clients")}
                      activeRoute={activeRoute}
                      smallSidebar={smallSidebar}
                      onRoute={onRoute}
                      entity={
                        userType === "Society" ? "axoidcode12" : "clientMenuNav"
                      }
                      Icon={
                        <Icon
                          className="frontnavbarimg Clienticoncolor"
                          glyph="icon-fontello-users-outline"
                        />
                      }
                      label={
                        userType === "Society" ? (
                          <span>
                            {" "}
                            <AxoLocal
                              entity="axoidcode12"
                              defaultValue={"Medlemmer"}
                            />
                          </span>
                        ) : (
                          <AxoLocal
                            entity="clientMenuNav"
                            defaultValue={"Klienter"}
                          />
                        )
                      }
                    />
                  </div>
                  {(userType === "Admin" ||
                    userType === "Accountant" ||
                    userType === "Lawyer") && (
                    <div className="Caseiconcolor1">
                      <AxoSideBarButton
                        route={this.getPath("Cases")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        entity={"caseMenuNav"}
                        Icon={
                          <Icon
                            className="frontnavbarimg  Caseiconcolor"
                            glyph="icon-fontello-book-open"
                          />
                        }
                        label={
                          <AxoLocal
                            entity="caseMenuNav"
                            defaultValue={"Sager"}
                          />
                        }
                      />
                    </div>
                  )}

                  {/* { userType === 'Accountant'  || userType === 'Admin' ? (
                    <div>
                      <AxoSideBarButton
                        route={this.getPath("AccountPlans")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        entity={'axoidcode153'}
                        Icon={<Icon className="frontnavbarimg" glyph='icon-fontello-list-numbered' /> }
                        label={ <span><AxoLocal entity='axoidcode153' defaultValue={'Kontoplaner'}/></span>}
                      />
                    </div>
                  ) : null } */}

                  {smallSidebar ? (
                    <button
                      title={getText("axoidcode170", "Ekspander")}
                      className="Lex-button-1 axoSidebarbutton"
                      onClick={onToggleSidebar}
                    >
                      <Icon
                        className="Axolexgul frontnavbarimg"
                        glyph="icon-fontello-right-4"
                      />
                      {/* <Icon className="frontnavbarimg" glyph='icon-fontello-back'/> */}
                    </button>
                  ) : null}

                  {/* { userType === 'Accountant'  || userType === 'Admin' ? (
                    <div>
                      <AxoSideBarButton
                        route={this.getPath("Bookkeeping")}
                        activeRoute={activeRoute}
                        onRoute={onRoute}
                        Icon={<Icon className="frontnavbarimg" glyph='icon-fontello-cogs' /> }
                        label={ <span><AxoLocal entity='updategetAccountName12' defaultValue={'Bogføringskladde'}/></span>}
                      />
                    </div>
                  ) : null }  */}

                  {userType === "Admin" ? (
                    <div className="Accountingiconcolor1">
                      <AxoSideBarButton
                        route={this.getPath("Accounting")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        entity={"labelMenuNavAccounting"}
                        Icon={
                          <Icon
                            className="frontnavbarimg Accountingiconcolor"
                            glyph="icon-fontello-cogs"
                          />
                        }
                        label={
                          <span>
                            <AxoLocal
                              entity="labelMenuNavAccounting"
                              defaultValue={"Regnskab"}
                            />
                          </span>
                        }
                      />
                    </div>
                  ) : null}

                  {/* { (userType === 'Admin' || userType === 'Accountant') && */}
                  {(userType === "Admin" || userType === "Accountant") &&
                    !isNormalGroupMember && (
                      <div className="Invoicesiconcolor1">
                        <AxoSideBarButton
                          route={this.getPath("Invoices")}
                          activeRoute={activeRoute}
                          smallSidebar={smallSidebar}
                          onRoute={onRoute}
                          entity={"labelMenuNavAccounting"}
                          Icon={
                            <Icon
                              className="frontnavbarimg Invoicesiconcolor"
                              glyph="icon-fontello-cogs"
                            />
                          }
                          label={
                            <span>
                              <AxoLocal
                                entity="axoidcode210"
                                defaultValue={"Faktura"}
                              />
                            </span>
                          }
                        />
                      </div>
                    )}

                  <div className="documenticoncolor1">
                    <AxoSideBarButton
                      route={this.getPath("Documents")}
                      activeRoute={activeRoute}
                      smallSidebar={smallSidebar}
                      onRoute={onRoute}
                      entity={"documentMenuNav"}
                      Icon={
                        <Icon
                          className="frontnavbarimg documenticoncolor"
                          glyph="icon-fontello-doc-7"
                        />
                      }
                      label={
                        <AxoLocal
                          entity="documentMenuNav"
                          defaultValue={"Dokumenter"}
                        />
                      }
                    />
                  </div>

                  { userType == "Admin" && (
                    <div className="documenticoncolor1">
                      <AxoSideBarButton
                        route={this.getPath("Signing")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        entity={"documentMenuNav"}
                        Icon={
                          <Icon
                            className="frontnavbarimg documenticoncolor"
                            glyph="icon-fontello-doc-7"
                          />
                        }
                        label="Signering"
                      />
                    </div>
                  )}

                  {userType !== "Society" ? (
                    <div className="calendariconcolor1">
                      <AxoSideBarButton
                        route={this.getPath("Calendar")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        entity={"calendarMenuNav"}
                        Icon={
                          <Icon
                            className="frontnavbarimg calendariconcolor"
                            glyph="icon-fontello-calendar-6"
                          />
                        }
                        label={
                          <AxoLocal
                            entity="calendarMenuNav"
                            defaultValue={"Kalender"}
                          />
                        }
                      />
                    </div>
                  ) : null}

                  {userType === "Lawyer" || userType === "Admin" ? (
                    <div className="financeiconcolor1">
                      <AxoSideBarButton
                        route={this.getPath("Finance")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        entity={"financeMenuNav"}
                        Icon={
                          <Icon
                            className="frontnavbarimg financeiconcolor"
                            glyph="icon-fontello-database-2"
                          />
                        }
                        label={
                          <AxoLocal
                            entity="financeMenuNav"
                            defaultValue={"Økonomi"}
                          />
                        }
                      />
                    </div>
                  ) : null}

                  <div className="Labelsiconcolor1">
                    <AxoSideBarButton
                      route={this.getPath("Labels")}
                      activeRoute={activeRoute}
                      smallSidebar={smallSidebar}
                      onRoute={onRoute}
                      entity={"labelMenuNav"}
                      Icon={
                        <Icon
                          className="frontnavbarimg Labelsiconcolor"
                          glyph="icon-fontello-folder-open-1"
                        />
                      }
                      label={
                        userType !== "Society" ? (
                          <AxoLocal
                            entity="labelMenuNav"
                            defaultValue={"Axo-mapper"}
                          />
                        ) : (
                          <span>
                            <AxoLocal
                              entity="clientselectedLabelb"
                              defaultValue={"Grupper"}
                            />
                          </span>
                        )
                      }
                    />
                  </div>

                  {userType !== "Society" && userType !== "Accountant" ? (
                    <div className="socialiconcolor1">
                      <AxoSideBarButton
                        route={this.getPath("Social")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        entity={"socialMenuNav"}
                        Icon={
                          <Icon
                            className="frontnavbarimg socialiconcolor "
                            glyph="icon-fontello-data-science"
                          />
                        }
                        label={
                          <AxoLocal
                            entity="socialMenuNav"
                            defaultValue={"Socialt netværk"}
                          />
                        }
                      />
                    </div>
                  ) : null}

                  {/* { this.props.userType === 'Admin' ? (
                    <AxoSideBarButton
                      route={this.getPath('AccountingOld')} 
                      activeRoute={activeRoute}
                      onRoute={onRoute}
                      Icon={<Icon className="frontnavbarimg" glyph='icon-fontello-params' />
                    }
                    label={<span>
                      <AxoLocal entity='labelMenuNavAccounting' defaultValue={'Regnskab'} />(Old)
                      </span>}
                    /> ) : <span></span> } */}

                  {userType === "Admin" ? (
                    <div className="Adminiconcolor1">
                      <AxoSideBarButton
                        route={this.getPath("Admin")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        entity={"SidebarMenuAdmin"}
                        Icon={
                          <Icon
                            className="frontnavbarimg Adminiconcolor "
                            glyph="icon-fontello-user-add-outline"
                          />
                        }
                        label={
                          <AxoLocal
                            entity="SidebarMenuAdmin"
                            defaultValue={"Admin"}
                          />
                        }
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}

                  {userType === "Admin" ? (
                    <div className="Applicationiconcolor1">
                      <AxoSideBarButton
                        route={this.getPath("ApplicationUD")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        Icon={
                          <Icon
                            className="frontnavbarimg Applicationiconcolor"
                            glyph="icon-fontello-user-add-outline"
                          />
                        }
                        label="ApplicationUD"
                      />{" "}
                    </div>
                  ) : (
                    <span></span>
                  )}

                  {(userType === "Admin" || userType === "Accountant") &&
                  locale === "da" ? (
                    <div className="BusinessOperatiniconcolor1">
                      <AxoSideBarButton
                        route={this.getPath("BusinessOperating")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        Icon={
                          <Icon
                            className="frontnavbarimg BusinessOperatiniconcolor"
                            glyph="icon-fontello-user-add-outline"
                          />
                        }
                        label="Selskabsdokumenter"
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}

                  {userType === "Society" || userType === "Admin" ? (
                    <div className="Finance1iconcolor11">
                      <AxoSideBarButton
                        route={this.getPath("Finance")}
                        activeRoute={activeRoute}
                        smallSidebar={smallSidebar}
                        onRoute={onRoute}
                        entity={"axoAccounting43"}
                        Icon={
                          <Icon
                            className="frontnavbarimg Finance1iconcolor1"
                            glyph="icon-fontello-money-2"
                          />
                        }
                        label={
                          <AxoLocal
                            entity="axoAccounting43"
                            defaultValue={"Betaling"}
                          />
                        }
                      />
                    </div>
                  ) : null}
                  {/* <AxoSideBarButton
                        route={this.getPath("Setup")}
                        activeRoute={activeRoute}
                        onRoute={onRoute}
                        Icon={<Icon className="frontnavbarimg" glyph='icon-fontello-cog-7' /> }
                        label={ <span><AxoLocal entity='ClientSetupViewSetup' defaultValue={'Setup'}/></span>}
                    />
                     <AxoSideBarButton
                    route={this.getPath('Trash')} 
                    activeRoute={activeRoute}
                    onRoute={onRoute}
                    Icon={<Icon className="frontnavbarimg" glyph='icon-fontello-trash-1' />}
                    label={ <AxoLocal entity='trashMenuNav' defaultValue={'Skraldespand'} />}
                    /> */}

                  {userType === "Admin" ? (
                    <AxoSideBarButton
                      route={this.getPath("PowerOfAttorney")}
                      activeRoute={activeRoute}
                      smallSidebar={smallSidebar}
                      onRoute={onRoute}
                      Icon={
                        <Icon
                          className="frontnavbarimg"
                          glyph="icon-fontello-user-add-outline"
                        />
                      }
                      label="Fuldmagt"
                    />
                  ) : (
                    <span></span>
                  )}

                  {userType === "Admin" ? (
                    <AxoSideBarButton
                      route={this.getPath("EmploymentContract")}
                      activeRoute={activeRoute}
                      smallSidebar={smallSidebar}
                      onRoute={onRoute}
                      Icon={
                        <Icon
                          className="frontnavbarimg"
                          glyph="icon-fontello-user-add-outline"
                        />
                      }
                      label="Kontrakter"
                    />
                  ) : (
                    <span></span>
                  )}
                </Row>
              </Grid>
            ) : null}
            {sidebarSelection === 1 ? (
              <NetworkList friends={friends} onNavigate={onSocialClick} />
            ) : null}
            {sidebarSelection === 2 ? (
              <FriendRequestTimeline
                socialEvents={this.getSocialEvents()}
                userName={userProfile.userName}
              />
            ) : null}
            {sidebarSelection === 3 ? (
              <TimelineComponent events={bookings} userProfile={userProfile} />
            ) : null}
            {sidebarSelection === 4 ? (
              <NotificationsComponent
                notifications={deadlineNotifications.filter(
                  (n) => n.type === 0
                )}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
