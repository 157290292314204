import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import {
  ApiService,
  DataStore,

} from "../services/AxoServices";

import {
  LoadingIndicator,
  withRouter,
  Consumer,
  withRTKMessageData,
} from "../utilities/LexUtilities";

// import InvoicesContainer from '../routes/Invoices/InvoicesContainer'
import EasyIDDashboardView from "../clientDashboard/EasyIDDashboardView";
import SigningView from "../Signing/SigningView";
import SigningRequestContainer from "../clientDashboard/SigningRequests/SigningRequestContainer";
import ClientDocumentContainer from "../clientDashboard/Documents/ClientDocumentContainer";

class SigningContainer extends React.PureComponent {
  componentDidMount() {
    ApiService.checkLoginStatus().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.props.history.push("/Users/Login");
      } else {
        this.InitializeData();
        this.checkSettings();
      }
    });
  }

  InitializeData = () => {
    // RoutingService.setRootPath("/ClientDashboard");

    DataStore.initializeDocuments();
    DataStore.initializeFriendRequests();
    DataStore.initializeUserSettings();
    DataStore.initializeUserProfile();
    DataStore.fetchGlobalSettings();
    DataStore.fetchMyContactInfo();
  };

  render() {
    let { loaded, globalSettings, userProfile } = this.props;

    if (!loaded || !globalSettings.theme || !userProfile.id) {
      return <LoadingIndicator />;
    }

    return (
        <EasyIDDashboardView {...this.props}>
            {this.props.children}
        </EasyIDDashboardView>
    );
  }
}

class SigningConsumer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserProfile();
  }

  render() {
    let { match } = this.props;

    return (
      <Consumer>
        {(store) => {
          return (
            <SigningContainer
              loaded={store.loaded}
              {...this.props}
              friendRequests={store.friendRequests}
              globalSettings={store.globalSettings}
              userSettings={store.userSettings}
              userProfile={store.userProfile}
              myContactInfo={store.myContactInfo}
              userType={"EasyID"}
              embedded
              history={this.props.history}
              showDropdowns={store.showDropdowns}
            >
              <Switch>
                <Route
                    exact
                    path={match.path}
                    component={() => (
                        <Redirect to={match.path + "/Documents"} />
                    )}
                />
                <Route
                  path={match.path + "/documents"}
                  component={ClientDocumentContainer}
                />
                <Route
                    path={match.path + "/Signing/:guid"}
                    render={(props) => <SigningView {...props} isOwner />}
                />
                <Route
                    path={match.path + "/SigningRequest/:id"}
                    render={(props) => (
                        <SigningRequestContainer {...props} isOwner />
                    )}
                />
              </Switch>
            </SigningContainer>
          );
        }}
      </Consumer>
    );
  }
}

export default withRTKMessageData(withRouter(SigningConsumer));
