import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { ApiService, DataActions } from "../services/AxoServices";

import { AxoDateTime, ctx, AxoLocal } from "../utilities/LexUtilities";

import { Row, Col, Grid, Button, FormControl } from "react-bootstrap";

export default class BookingView extends React.PureComponent {
  static propTypes = {
    receiverUserName: PropTypes.string,
    bookingUserName: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(),
      bookings: [],
      selectedBookings: [],
      bookingComplete: false,
      eventBooking: this.makeEventBooking(),
      showErrorMessage: false,
    };
  }

  makeEventBooking() {
    return {
      calendarEventId: 0,
      reason: "",
      bookingEmail: "",
      confirmedBooking: true, //Bookings do not currently require confirmation
    };
  }

  componentDidMount() {
    this.loadBookingCalendarEvents(this.props.receiverUserName);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.receiverUserName !== this.props.receiverUserName) {
      this.loadBookingCalendarEvents(nextProps.receiverUserName);
    }
  }

  loadBookingCalendarEvents = (receiverUserName) => {
    if (!receiverUserName) {
      return;
    }
    ApiService.getAvailableBookingTimes(receiverUserName).then((bookings) => {
      bookings.forEach((b) => {
        console.log(b.description);
        console.log((b.description || "").split(",").length);
      });
      let filteredBookings = bookings.filter(
        (b) =>
          b.eventBooking === null ||
          (b.description || "").split(",").length <= b.bookingCapacity
      ); //Maximum of two bookings allowed

      let sortedBookings = filteredBookings.sort((e1, e2) => {
        return new Date(e1.start) - new Date(e2.start);
      });

      this.setState({
        bookings: sortedBookings,
        selectedBookings: [],
        eventBooking: this.makeEventBooking(),
      });
      this.setSelectedDate(this.state.selectedDate);
    });
  };

  onDateChange = (date) => {
    this.setSelectedDate(date);
  };

  setSelectedDate = (selectedDate) => {
    this.setState({
      selectedDate: selectedDate,
      selectedBookings: this.state.bookings.filter((b) => {
        return (
          new Date(b.start).setHours(0, 0, 0, 0) ===
          new Date(selectedDate).setHours(0, 0, 0, 0)
        );
      }),
    });
  };

  onSelectBooking = (id) => {
    var eventBooking = Object.assign({}, this.state.eventBooking);
    eventBooking.calendarEventId = id;
    this.setState({ eventBooking });
  };

  renderBookingTimes = () => {
    return this.state.selectedBookings.map((b) => {
      var colorClass = this.state.selectedTime === b.id ? "axoblue" : "axoblue";
      return (
        <p
          key={b.id}
          role="button"
          onClick={this.onSelectBooking.bind(this, b.id)}
          className={colorClass}
        >
          {moment(b.start).format("HH:mm") +
            " - " +
            new moment(b.end).format("HH:mm")}
          {this.state.eventBooking.calendarEventId === b.id ? (
            <span className="axoblue">
              &nbsp; &#10004;
              {/* Checkmark symbol */}
            </span>
          ) : null}
        </p>
      );
    });
  };

  renderBookingUI = () => {
    if (!this.state.eventBooking.calendarEventId) {
      return null;
    }
    return (
      <div>
        <Row>
          <Col xs={12}>
            <FormControl
              type="text"
              value={this.state.eventBooking.reason}
              onChange={this.handleReasonChange}
              style={{ width: "250px" }}
              placeholder="Navn"
              // entity='CaseEditFormcasesubject'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>{this.renderEmailBox()}</Col>
        </Row>
        <br />
        <Row>
          <Col xs={12}>{this.renderBookingButton()}</Col>
        </Row>
      </div>
    );
  };

  renderEmailBox = () => {
    if (this.props.bookingUserName) {
      return;
    }

    return (
      <div>
        <br />
        <AxoLocal
          componentClass={FormControl}
          type="email"
          value={this.state.eventBooking.bookingEmail}
          onChange={this.handleEmailChange}
          componentAttribute="placeholder"
          style={{ width: "250px" }}
          entity="eventBookingB"
        />
        <div>
          <small>{this.renderEmailWarningMessage()}</small>
        </div>
      </div>
    );
  };

  renderEmailWarningMessage = () => {
    var bookingEmail = this.state.eventBooking.bookingEmail;
    if (bookingEmail && !this.validateEmail(bookingEmail)) {
      return ctx.getSync("BookingContainerPleaseenteravalidemailaddress");
    }
  };

  renderBookingButton = () => {
    return (
      <div style={{ paddingBottom: "15px" }}>
        <Button className="Lex-button-2" onClick={this.addBookingEvent}>
          Tilmeld
          {/* <AxoLocal entity='BookingContainerBookingEventButton' defaultValue={'Book denne tid'}/>  */}
        </Button>
      </div>
    );
  };

  addBookingEvent = () => {
    if (!this.state.eventBooking.calendarEventId) {
      return;
    }
    if (
      !this.props.bookingUserName &&
      !this.validateEmail(this.state.eventBooking.bookingEmail)
    ) {
      return null;
    }

    DataActions.createEventBooking(this.state.eventBooking)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            bookingComplete: true,
            selectedBookings: [],
            eventBooking: this.makeEventBooking(),
          });
          this.loadBookingCalendarEvents(this.props.receiverUserName);
        } else {
          this.showErrorMessage();
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.showErrorMessage();
      });
  };

  showErrorMessage = () => {
    this.setState({
      showErrorMessage: true,
    });
    setTimeout(() => {
      this.setState({ showErrorMessage: false });
    }, 5000);
  };

  handleReasonChange = (event) => {
    var eventBooking = Object.assign({}, this.state.eventBooking);
    eventBooking.reason = event.target.value;
    this.setState({ eventBooking });
  };

  handleEmailChange = (event) => {
    var eventBooking = Object.assign({}, this.state.eventBooking);
    eventBooking.bookingEmail = event.target.value;
    this.setState({ eventBooking });
  };

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  renderErrorMessage = () => {
    if (!this.state.showErrorMessage) {
      return null;
    }
    return (
      <div className="axored">
        <b>
          <AxoLocal
            entity="BookingContainerBookingEventresponse"
            defaultValue={"Dit valg kunne ikke gemmes. Prøv igen senere."}
          />
        </b>
      </div>
    );
  };

  renderBody = () => {
    var yesterday = moment().subtract(1, "day");
    if (this.state.bookingComplete) {
      return (
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              Du er nu tilmeldt holdet.
              {/* <AxoLocal entity='BookingContainerBookingEventbookingComplete' defaultValue={'Din booking er gennemført. Du vil få en besked når bookingen er bekræftet.'}/> */}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="nopadding">
              <Button
                className="Lex-button-2"
                onClick={() => {
                  this.setState({ bookingComplete: false });
                }}
              >
                Tilbage til tilmelding.
                {/* <AxoLocal entity='BookingContainerbookingCompleteButton' defaultValue={'Tilbage til booking'}/> */}
              </Button>
            </Col>
          </Row>
        </Grid>
      );
    }
    return (
      <Grid fluid>
        <Row>
          <Col sm={12}>
            <AxoDateTime
              value={this.state.selectedDate}
              input={false}
              dateFormat={true}
              timeFormat={false}
              onChange={this.onDateChange}
              isValidDate={(currentDate) => {
                if (!currentDate.isAfter(yesterday)) {
                  return false;
                }
                return !!this.state.bookings.find((booking) => {
                  return (
                    new Date(booking.start).toDateString() ===
                    new Date(currentDate).toDateString()
                  );
                });
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12}>{this.renderBookingTimes()}</Col>
        </Row>
        {this.renderBookingUI()}
        {this.renderErrorMessage()}
      </Grid>
    );
  };

  render() {
    return <div>{this.renderBody()}</div>;
  }
}
