import React from "react";
import { RIETextArea } from "./Riek";

export default class InlineTextArea extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    //Ensures that the input retains focus when receiving new props, as long as the value has not changed
    if (nextProps.value === this.props.value) {
      return false;
    }
    return true;
  }

  render() {
    let { value, change, rows } = this.props;
    let className = this.props.className || "editable";
    let editStyle = this.props.editStyle || { width: "100%" };

    return (
      <RIETextArea
        value={value || "---"}
        change={change}
        rows={rows || 3}
        propName="value"
        className={className}
        editProps={{ style: editStyle }}
      />
    );
  }
}
