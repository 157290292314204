import RoutingService from "./../RoutingService";
import jQuery from "jquery";

require("es6-promise").polyfill();
require("isomorphic-fetch");

const endPoint = RoutingService.getHostPath();

const querySettings = {
  method: "GET",
  accept: "application/json",
  credentials: "include",
};

var formPostSettings = {
  method: "POST",
  credentials: "include",
};
var postSettings = {
  method: "POST",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
var putSettings = {
  method: "PUT",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
var deleteSettings = {
  method: "DELETE",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

async function parseJSON(response) {
  if (response.ok) {
    try {
      let text = await response.text();
      if (!!text) {
        return JSON.parse(text);
      }
    } catch (error) {
      console.error(response.url + ": " + error.message);
      return Promise.reject("JSON parsing failed for route " + response.url);
    }

    return Promise.reject("Server returned empty response.");
  } else if (response.status === 401) {
    return Promise.reject(
      "401 - Unauthorized. Ensure that user is logged in with an authorized account."
    );
  }
  return Promise.reject(response.status);
}

function parseString(response) {
  return response.text();
}

function getDocXAttachmentDownloadPath(fileId, messageId) {
  return (
    endPoint +
    "/documents/downloadDocXAttachmentAsHTML?fileId=" +
    fileId +
    "&messageId=" +
    messageId
  );
}

function getDocXDownloadPath(fileId) {
  return endPoint + "/documents/downloadDocXAsHTML?id=" + fileId;
}

function getRawTextDownloadPath(fileId) {
  return endPoint + "/documents/downloadRawText?id=" + fileId;
}

export default {
  checkLoginStatus() {
    return fetch(endPoint + "/api/UserSettings/checkLogin", querySettings).then(
      (response) => {
        return response.status !== 401;
      }
    );
  },
  getUserName() {
    return fetch(endPoint + "/api/user/userName", querySettings).then(
      parseJSON
    );
  },
  getUserProfile() {
    return fetch(endPoint + "/api/UserProfiles/profile", querySettings).then(
      parseJSON
    );
  },
  getUserProfileArray() {
    return fetch(endPoint + "/api/UserProfiles/all", querySettings).then(
      parseJSON
    );
  },
  getUserNameFromId(userId) {
    return fetch(
      endPoint + "/api/user/userNameFromId/" + userId,
      querySettings
    ).then(parseJSON);
  },
  getSharedDocuments() {
    return fetch(endPoint + "/documents/shared", querySettings).then(parseJSON);
  },
  getCalendarEvents() {
    return fetch(endPoint + "/api/CalendarEvents/all", querySettings).then(
      parseJSON
    );
  },
  getAvailableBookingTimes(userName) {
    return fetch(
      endPoint + "/api/CalendarEvents/booking/" + userName,
      querySettings
    ).then(parseJSON);
  },
  getNotifications() {
    return fetch(endPoint + "/api/Notifications/all", querySettings).then(
      parseJSON
    );
  },
  getTemplates() {
    return fetch(endPoint + "/api/DocumentTemplates/all", querySettings).then(
      parseJSON
    );
  },
  getFriends() {
    return fetch(endPoint + "/api/Friends/all", querySettings).then(parseJSON);
  },
  getFriendRequests() {
    return fetch(endPoint + "/api/FriendRequests/all", querySettings).then(
      parseJSON
    );
  },

  //Messages
  getMessages(pageSize, query) {
    console.log(query);
    var queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }

    return fetch(
      endPoint + `/api/message/headers/${pageSize}${queryParams}`,
      querySettings
    ).then(parseJSON);
  },

  getMessage(id) {
    return fetch(endPoint + "/api/message/" + id, querySettings).then(
      parseJSON
    );
  },
  getMessageHeader(id) {
    return fetch(endPoint + "/api/message/header/" + id, querySettings).then(
      parseJSON
    );
  },
  getMessageCounter() {
    return fetch(endPoint + "/api/message/count/", querySettings).then(
      parseJSON
    );
  },
  createMessage(message) {
    postSettings.body = JSON.stringify(message);
    return fetch(endPoint + "/api/message/createMessage", postSettings);
  },
  updateMessage(message) {
    postSettings.body = JSON.stringify(message);
    return fetch(endPoint + "/api/message/updateMessage", postSettings);
  },
  updateMessageFromHeader(messageHeader) {
    postSettings.body = JSON.stringify(messageHeader);
    return fetch(endPoint + "/api/message/updateFromHeader", postSettings);
  },
  deleteMessage(messageId) {
    postSettings.body = JSON.stringify(messageId);
    return fetch(endPoint + "/api/message/deleteMessage", postSettings);
  },

  //BCC relation
  addBCCRelation(bccRelation) {
    postSettings.body = JSON.stringify(bccRelation);
    return fetch(endPoint + "/api/BCCRelations/", postSettings);
  },

  removeBCCRelation(bccRelation) {
    return fetch(
      endPoint +
        "/api/BCCRelations/message/" +
        bccRelation.messageModelId +
        "/user/" +
        bccRelation.userProfileId,
      deleteSettings
    );
  },

  //Upload
  getUploadPath() {
    return endPoint + "/documents/upload";
  },

  //Query: { clientId: Id }
  uploadDocuments(files, params) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files[i].name, files[i]);
    }
    formPostSettings.body = data;
    let query = jQuery.param(params || {});
    if (!!query) {
      query = "?" + query;
    }
    return fetch(
      endPoint + "/documents/uploadMultiple/" + query,
      formPostSettings
    );
  },

  uploadCompositeImage(files, params) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files[i].name, files[i]);
    }
    formPostSettings.body = data;
    let query = jQuery.param(params || {});
    if (!!query) {
      query = "?" + query;
    }
    return fetch(
      endPoint + "/documents/uploadCompositeImage/" + query,
      formPostSettings
    );
  },

  uploadToCustomPath(files, path) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files[i].name, files[i]);
    }
    formPostSettings.body = data;
    return fetch(endPoint + path, formPostSettings);
  },

  splitDocument(id) {
    return fetch(endPoint + "/documents/split/" + id, postSettings);
  },

  mergeDocuments(idList) {
    postSettings.body = JSON.stringify(idList);
    return fetch(endPoint + "/documents/merge/", postSettings);
  },

  addImagesToExisting(files, params = { fileId: 0, clientId: 0 }) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files[i].name, files[i]);
    }
    formPostSettings.body = data;
    let query = jQuery.param(params || {});
    if (!!query) {
      query = "?" + query;
    }
    return fetch(
      endPoint + "/documents/addImagesToExisting/" + query,
      formPostSettings
    );
  },

  uploadFileObjects(files) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files[i].name, files[i]);
    }
    formPostSettings.body = data;
    return fetch(endPoint + "/documents/uploadMultiple", formPostSettings);
  },

  //File functions
  getDownloadPath(fileId) {
    return endPoint + "/documents/download?id=" + fileId;
  },
  getInvoicePDFDownloadPath(guid) {
    return endPoint + "/documents/downloadInvoiceAttachment?guid=" + guid;
  },
  getCaseDocumentsDownloadPath(id) {
    return endPoint + `/documents/case/${id}`;
  },
  getCaseLinkDocumentsDownloadPath(guid, code) {
    return endPoint + `/documents/caselink/guid/${guid}/code/${code}`;
  },
  getDocXAsHTML(fileId) {
    return fetch(getDocXDownloadPath(fileId), querySettings).then(parseString);
  },
  getRawText(fileId) {
    return fetch(getRawTextDownloadPath(fileId), querySettings).then(
      parseString
    );
  },
  getFileInlinePath(fileId) {
    return endPoint + "/documents/downloadInline?id=" + fileId;
  },
  getSubscriptionPlanPicturePath(planId) {
    return endPoint + "/documents/subscriptionPlan/" + planId;
  },
  getItemPricePicturePath(itemId) {
    return endPoint + "/documents/itemPrice/" + itemId;
  },
  getItemPriceTypePicturePath(itemId) {
    return endPoint + "/documents/itemPriceType/" + itemId;
  },
  getProfileImageInlinePath(userProfileId, fileId) {
    return (
      endPoint + "/documents/users/" + userProfileId + "/profileImage/" + fileId
    );
  },
  getBannerImageInlinePath(userProfileId, fileId) {
    return endPoint + "/documents/users/" + userProfileId + "/banner/" + fileId;
  },
  getLibraryFileNames() {
    return fetch(endPoint + "/documents/library", querySettings).then(
      parseJSON
    );
  },
  scanText(id) {
    return fetch(endPoint + "/documents/scan/" + id, querySettings).then(
      parseString
    );
  },
  summarizeFileContent(id) {
    return fetch(endPoint + "/documents/summarize/" + id, querySettings).then(
      parseString
    );
  },
  summarizeFileContentAsList(id) {
    return fetch(endPoint + "/documents/summarizeAsList/" + id, querySettings).then(
      parseString
    );
  },
  evaluateFileContentAsContract(id) {
    return fetch(endPoint + "/documents/evaluateAsContract/" + id, querySettings).then(
      parseString
    );
  },


  //Attachments
  getAttachment(fileId, messageId) {
    return fetch(
      this.getAttachmentDownloadPath(fileId, messageId),
      querySettings
    );
  },
  getAttachmentInlinePath(fileId, messageId) {
    return (
      endPoint +
      "/documents/downloadAttachmentInline?fileId=" +
      fileId +
      "&messageId=" +
      messageId
    );
  },
  getAttachmentDownloadPath(fileId, messageId) {
    return (
      endPoint +
      "/documents/downloadAttachment?fileId=" +
      fileId +
      "&messageId=" +
      messageId
    );
  },
  getAttachmentPDFDownloadPath(messageId) {
    return endPoint + "/api/message/pdf/" + messageId;
  },
  getAttachmentZipDownloadPath(messageId) {
    return endPoint + "/api/message/zip/" + messageId;
  },

  getDocXAttachmentAsHTML(fileId, messageId) {
    return fetch(
      getDocXAttachmentDownloadPath(fileId, messageId),
      querySettings
    ).then(parseString);
  },
  //Storage information
  getStorageInformation() {
    return fetch(endPoint + "/documents/storage", querySettings).then(
      parseJSON
    );
  },

  //Labels
  getLabels() {
    return fetch(endPoint + "/api/Labels/all", querySettings).then(parseJSON);
  },
  getSingleLabel(id) {
    return fetch(endPoint + "/api/Labels/single/" + id, querySettings).then(
      parseJSON
    );
  },
  createLabel(label) {
    postSettings.body = JSON.stringify(label);
    return fetch(endPoint + "/api/Labels/createLabel", postSettings);
  },
  updateLabel(label) {
    postSettings.body = JSON.stringify(label);
    return fetch(endPoint + "/api/Labels/updateLabel", postSettings);
  },
  deleteLabel(labelId) {
    var data = new FormData();
    data.append("id", labelId);
    formPostSettings.body = data;
    return fetch(endPoint + "/api/Labels/deleteLabel", formPostSettings);
  },
  addLabelToMessage(messageId, labelId) {
    var data = new FormData();
    data.append("messageId", messageId);
    data.append("labelId", labelId);
    formPostSettings.body = data;
    return fetch(endPoint + "/api/Labels/addLabelToMessage", formPostSettings);
  },
  removeLabelFromMessage(messageId, labelId) {
    var data = new FormData();
    data.append("messageId", messageId);
    data.append("labelId", labelId);
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/Labels/removeLabelFromMessage",
      formPostSettings
    );
  },
  addLabelToCase(caseId, labelId) {
    var data = new FormData();
    data.append("caseId", caseId);
    data.append("labelId", labelId);
    formPostSettings.body = data;
    return fetch(endPoint + "/api/Labels/addLabelToCase", formPostSettings);
  },
  removeLabelFromCase(caseId, labelId) {
    var data = new FormData();
    data.append("caseId", caseId);
    data.append("labelId", labelId);
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/Labels/removeLabelFromCase",
      formPostSettings
    );
  },
  addLabelToFile(fileId, labelId) {
    var data = new FormData();
    data.append("fileId", fileId);
    data.append("labelId", labelId);
    formPostSettings.body = data;
    return fetch(endPoint + "/api/Labels/addLabelToFile", formPostSettings);
  },
  removeLabelFromFile(fileId, labelId) {
    var data = new FormData();
    data.append("fileId", fileId);
    data.append("labelId", labelId);
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/Labels/removeLabelFromFile",
      formPostSettings
    );
  },
  addLabelToClient(clientId, labelId) {
    var data = new FormData();
    data.append("clientId", clientId);
    data.append("labelId", labelId);
    formPostSettings.body = data;
    return fetch(endPoint + "/api/Labels/addLabelToClient", formPostSettings);
  },
  removeLabelFromClient(clientId, labelId) {
    var data = new FormData();
    data.append("clientId", clientId);
    data.append("labelId", labelId);
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/Labels/removeLabelFromClient",
      formPostSettings
    );
  },

  //Cases
  // getCases(pageSize, query) {
  //   query = query || '';
  //   return fetch(endPoint + '/api/cases/headers/' + pageSize + '?query=' + query, querySettings).then(parseJSON);
  // },
  getSharedCases() {
    return fetch(endPoint + "/api/cases/shared/headers", querySettings).then(
      parseJSON
    );
  },
  getCaseHeader(id) {
    return fetch(
      endPoint + "/api/cases/header/" + id.toString(),
      querySettings
    ).then(parseJSON);
  },
  getCaseModel(id) {
    return fetch(
      endPoint + "/api/cases/single/" + id.toString(),
      querySettings
    ).then(parseJSON);
  },
  getCaseModelFromLink(guid, code) {
    return fetch(
      endPoint + `/api/cases/guid/${guid}/code/${code}`,
      querySettings
    );
  },
  getCurrentClientCases() {
    return fetch(endPoint + "/api/cases/currentClient/", querySettings).then(
      parseJSON
    );
  },
  createCase(caseModel) {
    postSettings.body = JSON.stringify(caseModel);
    return fetch(endPoint + "/api/cases/create", postSettings);
  },
  updateCase(caseModel) {
    postSettings.body = JSON.stringify(caseModel);
    return fetch(endPoint + "/api/cases/updateCase", postSettings);
  },
  updateCaseFromHeader(header) {
    postSettings.body = JSON.stringify(header);
    return fetch(endPoint + "/api/cases/updateHeader", postSettings);
  },
  updateCaseReminder(id) {
    return fetch(
      endPoint + "/api/cases/updateCaseReminder/" + id,
      postSettings
    );
  },
  deleteCase(deleteCaseViewModel) {
    postSettings.body = JSON.stringify(deleteCaseViewModel);
    return fetch(endPoint + "/api/cases/deleteCase", postSettings);
  },
  generateCasePdf(caseModelId) {
    return fetch(
      endPoint + "/api/print/casemodel/" + caseModelId,
      postSettings
    );
  },

  //Document updates
  getDocuments(pageSize, query) {
    var queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/documents/all/" + pageSize + queryParams,
      querySettings
    ).then(parseJSON);
  },

  getSingleDocument(id) {
    return fetch(endPoint + "/documents/single/" + id, querySettings).then(
      parseJSON
    );
  },

  deleteDocument(documentId) {
    var data = new FormData();
    data.append("id", documentId);
    formPostSettings.body = data;
    return fetch(endPoint + "/documents/delete", formPostSettings);
  },
  updateDocumentName(document) {
    postSettings.body = JSON.stringify(document);
    return fetch(endPoint + "/documents/updateName", postSettings);
  },
  updateDocument(document) {
    postSettings.body = JSON.stringify(document);
    return fetch(endPoint + "/documents/update", postSettings);
  },
  copyDocument(documentId) {
    return fetch(endPoint + "/documents/copy/" + documentId, postSettings);
  },

  //Calendar events
  createCalendarEvent(event) {
    postSettings.body = JSON.stringify(event);
    return fetch(endPoint + "/api/CalendarEvents/create", postSettings);
  },

  updateCalendarEvent(event) {
    putSettings.body = JSON.stringify(event);
    return fetch(
      endPoint + "/api/CalendarEvents/update/" + event.id,
      putSettings
    );
  },

  deleteCalendarEvent(eventId) {
    return fetch(
      endPoint + "/api/CalendarEvents/delete/" + eventId,
      deleteSettings
    );
  },

  //Notifications
  createNotification(notification) {
    postSettings.body = JSON.stringify(notification);
    return fetch(endPoint + "/api/Notifications/add", postSettings);
  },
  updateNotification(notification) {
    putSettings.body = JSON.stringify(notification);
    return fetch(
      endPoint + "/api/Notifications/update/" + notification.id,
      putSettings
    );
  },
  deleteNotification(notificationId) {
    return fetch(
      endPoint + "/api/Notifications/delete/" + notificationId,
      deleteSettings
    );
  },

  //Event bookings
  createEventBooking(eventBooking) {
    postSettings.body = JSON.stringify(eventBooking);
    return fetch(endPoint + "/api/EventBookings/add", postSettings);
  },
  updateEventBooking(eventBooking) {
    putSettings.body = JSON.stringify(eventBooking);
    return fetch(
      endPoint + "/api/EventBookings/update/" + eventBooking.id,
      putSettings
    );
  },
  deleteEventBooking(eventBookingId) {
    return fetch(
      endPoint + "/api/EventBookings/delete/" + eventBookingId,
      deleteSettings
    );
  },

  //External Email service
  sendCompanyEmail(emailViewModel) {
    postSettings.body = JSON.stringify(emailViewModel);
    return fetch(endPoint + "/api/Emails/sendCompany", postSettings);
  },

  //Allows for sending system mails without being logged in.
  //Only accpets a specific list of destination mails
  sendContactMail(emailViewModel) {
    postSettings.body = JSON.stringify(emailViewModel);
    return fetch(endPoint + "/api/Emails/sendContact", postSettings);
  },

  sendEmail(emailViewModel) {
    postSettings.body = JSON.stringify(emailViewModel);
    return fetch(endPoint + "/api/Emails/send", postSettings);
  },

  getEmailsFromAccount(accountId) {
    return fetch(endPoint + "/api/Emails/account/" + accountId, querySettings);
  },

  updateExternalMails(updateViewModel) {
    postSettings.body = JSON.stringify(updateViewModel);
    return fetch(endPoint + "/api/Emails/update", postSettings);
  },

  //Time entries
  getTimeEntries(pageSize) {
    return fetch(
      endPoint + "/api/TimeEntries/all/" + pageSize,
      querySettings
    ).then(parseJSON);
  },
  createTimeEntry(timeEntry) {
    postSettings.body = JSON.stringify(timeEntry);
    return fetch(endPoint + "/api/TimeEntries/add", postSettings);
  },
  updateTimeEntry(timeEntry) {
    putSettings.body = JSON.stringify(timeEntry);
    return fetch(
      endPoint + "/api/TimeEntries/update/" + timeEntry.id,
      putSettings
    );
  },
  deleteTimeEntry(timeEntryId) {
    return fetch(
      endPoint + "/api/TimeEntries/delete/" + timeEntryId,
      deleteSettings
    );
  },

  //Contacts
  getContacts(pageSize, query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/ContactInfoes/all/" + pageSize + queryParams,
      querySettings
    ).then(parseJSON);
  },
  getContactExportPath(query = { labelId: null, clientType: "Private" }) {
    let queryParams = jQuery.param(query);
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return endPoint + "/api/ContactInfoes/export" + queryParams;
  },
  getSingleContact(id) {
    return fetch(
      endPoint + "/api/ContactInfoes/single/" + id,
      querySettings
    ).then(parseJSON);
  },
  //Used by client accounts connected to a lawyer client
  getMyContact() {
    return fetch(endPoint + "/api/ContactInfoes/mine/", querySettings).then(
      parseJSON
    );
  },
  createContact(contactInfo) {
    postSettings.body = JSON.stringify(contactInfo);
    return fetch(endPoint + "/api/ContactInfoes/add", postSettings);
  },
  createWaitingContact(contactInfo) {
    postSettings.body = JSON.stringify(contactInfo);
    return fetch(endPoint + "/api/ContactInfoes/addWaiting", postSettings);
  },
  createClientAccount(clientAccount) {
    postSettings.body = JSON.stringify(clientAccount);
    return fetch(
      endPoint + "/api/ContactInfoes/createClientAccount",
      postSettings
    );
  },
  updateContact(contactInfo) {
    putSettings.body = JSON.stringify(contactInfo);
    return fetch(
      endPoint + "/api/ContactInfoes/update/" + contactInfo.id,
      putSettings
    );
  },

  checkoutContact(id) {
    postSettings.body = "";
    return fetch(endPoint + "/api/ContactInfoes/checkout/" + id, postSettings);
  },

  updateNewEntries() {
    postSettings.body = "";
    return fetch(
      endPoint + "/api/ContactInfoes/updateNewEntries/",
      postSettings
    );
  },

  resendContactMail(contactInfo, message) {
    postSettings.body = JSON.stringify(message);
    return fetch(
      endPoint + "/api/ContactInfoes/" + contactInfo.id + "/sendAccountMail",
      postSettings
    );
  },
  getClientProfileFromEmail(email) {
    return fetch(
      endPoint + "/api/ContactInfoes/" + email + "/userProfile",
      querySettings
    );
  },
  deleteContact(contactInfoId) {
    return fetch(
      endPoint + "/api/ContactInfoes/delete/" + contactInfoId,
      deleteSettings
    );
  },

  //Invoice
  getInvoices(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/Invoices/all" + queryParams,
      querySettings
    ).then(parseJSON);
  },

  getSingleInvoice(id) {
    return fetch(endPoint + "/api/Invoices/single/" + id, querySettings).then(
      parseJSON
    );
  },
  getNextInvoiceNumber() {
    return fetch(endPoint + "/api/Invoices/nextNumber", querySettings).then(
      parseJSON
    );
  },
  createInvoice(invoice) {
    postSettings.body = JSON.stringify(invoice);
    return fetch(endPoint + "/api/Invoices/add", postSettings);
  },
  updateInvoice(invoice) {
    putSettings.body = JSON.stringify(invoice);
    return fetch(endPoint + "/api/Invoices/update/" + invoice.id, putSettings);
  },
  deleteInvoice(invoiceId) {
    return fetch(
      endPoint + "/api/Invoices/delete/" + invoiceId,
      deleteSettings
    );
  },
  generateInvoicePdf(invoiceId) {
    return fetch(endPoint + "/api/print/invoice/" + invoiceId, postSettings);
  },
  registerInvoicePayment(invoiceId) {
    return fetch(
      endPoint + "/api/Invoices/registerPayment/" + invoiceId,
      postSettings
    );
  },
  markInvoiceAsRead(invoiceId) {
    return fetch(
      endPoint + "/api/Invoices/markAsRead/" + invoiceId,
      postSettings
    );
  },
  generateInvoicePostings(invoiceId = 0, receiptId = 0) {
    return fetch(
      endPoint +
        "/api/Invoices/approveInvoice/" +
        invoiceId +
        "/receiptId/" +
        receiptId,
      postSettings
    );
  },
  generateInvoicePaymentPosting(invoiceId, paymentDate) {
    return fetch(
      endPoint +
        "/api/Invoices/generatePaymentPosting/" +
        invoiceId +
        "/paymentDate/" +
        paymentDate,
      postSettings
    );
  },

  checkSubscriptions() {
    return fetch(endPoint + "/api/Invoices/checkSubscriptions/", postSettings);
  },

  //Creates an invoice for the client associated with the current user, send from the current users lawyer
  //Also creates the associated time entries.
  createInvoiceForClient(invoice) {
    postSettings.body = JSON.stringify(invoice);
    return fetch(endPoint + "/api/Invoices/postClientInvoice", postSettings);
  },

  //Document template
  createTemplate(template) {
    postSettings.body = JSON.stringify(template);
    return fetch(endPoint + "/api/DocumentTemplates/add", postSettings);
  },
  updateTemplate(template) {
    putSettings.body = JSON.stringify(template);
    return fetch(
      endPoint + "/api/DocumentTemplates/update/" + template.id,
      putSettings
    );
  },
  deleteTemplate(templateId) {
    return fetch(
      endPoint + "/api/DocumentTemplates/delete/" + templateId,
      deleteSettings
    );
  },
  generateTemplatePdf(templateId) {
    return fetch(
      endPoint + "/api/print/documentTemplate/" + templateId,
      postSettings
    );
  },

  //User profile
  updateUserProfile(profile) {
    putSettings.body = JSON.stringify(profile);
    return fetch(
      endPoint + "/api/UserProfiles/update/" + profile.id,
      putSettings
    );
  },

  updateUserProfileAdmin(profile) {
    putSettings.body = JSON.stringify(profile);
    return fetch(
      endPoint + "/api/UserProfiles/updateAdmin/" + profile.id,
      putSettings
    );
  },

  confirmMail(viewModel) {
    postSettings.body = JSON.stringify(viewModel);
    return fetch(endPoint + "/api/UserProfiles/confirmMail", postSettings);
  },

  confirmPhone(message) {
    return fetch(
      endPoint + "/api/UserProfiles/confirmPhone/" + message,
      postSettings
    );
  },

  confirmPhoneCode(code) {
    return fetch(
      endPoint + "/api/UserProfiles/confirmPhoneCode/" + code,
      postSettings
    );
  },

  changePassword(changePasswordViewModel) {
    postSettings.body = JSON.stringify(changePasswordViewModel);
    return fetch(endPoint + "/api/UserProfiles/changePassword/", postSettings);
  },

  enableTwoFactor() {
    return fetch(endPoint + "/api/UserProfiles/enableTwoFactor/", postSettings);
  },

  disableTwoFactor() {
    return fetch(
      endPoint + "/api/UserProfiles/disableTwoFactor/",
      postSettings
    );
  },

  //User profiles for clients who have an account
  getClientProfiles() {
    return fetch(endPoint + "/api/UserProfiles/clients", querySettings).then(
      parseJSON
    );
  },

  //User profiles for lawyers
  //Only applicable if the current user is a client
  getLawyerProfiles() {
    return fetch(endPoint + "/api/UserProfiles/lawyers", querySettings).then(
      parseJSON
    );
  },

  //Friends
  removeFriend(userProfile) {
    postSettings.body = JSON.stringify(userProfile);
    return fetch(endPoint + "/api/Friends/remove", postSettings);
  },

  //Friend requests
  createFriendRequest(friendRequest) {
    postSettings.body = JSON.stringify(friendRequest);
    return fetch(endPoint + "/api/FriendRequests/add", postSettings);
  },
  acceptFriendRequest(friendRequest) {
    postSettings.body = JSON.stringify(friendRequest);
    return fetch(endPoint + "/api/FriendRequests/accept", postSettings);
  },
  updateFriendRequest(friendRequest) {
    putSettings.body = JSON.stringify(friendRequest);
    return fetch(
      endPoint + "/api/FriendRequests/update/" + friendRequest.id,
      putSettings
    );
  },
  deleteFriendRequest(friendRequestId) {
    return fetch(
      endPoint + "/api/FriendRequests/delete/" + friendRequestId,
      deleteSettings
    );
  },

  //File sharings
  shareFile(fileSharing) {
    postSettings.body = JSON.stringify(fileSharing);
    return fetch(endPoint + "/api/FileSharings/add", postSettings);
  },

  removeFileSharing(fileSharing) {
    postSettings.body = JSON.stringify(fileSharing);
    return fetch(endPoint + "/api/FileSharings/remove", postSettings);
  },

  //Template sharings
  shareTemplate(templateSharing) {
    postSettings.body = JSON.stringify(templateSharing);
    return fetch(endPoint + "/api/TemplateSharings/add", postSettings);
  },

  removeTemplateSharing(templateSharing) {
    postSettings.body = JSON.stringify(templateSharing);
    return fetch(endPoint + "/api/TemplateSharings/remove", postSettings);
  },

  //Social posts
  getPublicPosts(amount) {
    return fetch(
      endPoint + "/api/SocialPosts/public/" + amount,
      querySettings
    ).then(parseJSON);
  },
  getInternalPosts(amount) {
    return fetch(
      endPoint + "/api/SocialPosts/internal/" + amount,
      querySettings
    ).then(parseJSON);
  },
  getIndividualPosts(userProfileId, amount) {
    return fetch(
      endPoint +
        "/api/SocialPosts/profile/" +
        userProfileId +
        "/amount/" +
        amount,
      querySettings
    ).then(parseJSON);
  },
  getPostHTMLPath(socialPostId) {
    return endPoint + "/api/SocialPosts/" + socialPostId + "/rawHTML";
  },
  createSocialPost(socialPost) {
    postSettings.body = JSON.stringify(socialPost);
    return fetch(endPoint + "/api/SocialPosts/", postSettings);
  },
  updateSocialPost(socialPost) {
    putSettings.body = JSON.stringify(socialPost);
    return fetch(endPoint + "/api/SocialPosts/" + socialPost.id, putSettings);
  },
  addCommentToSocialPost(socialPostId, comment) {
    postSettings.body = JSON.stringify(comment);
    return fetch(
      endPoint + "/api/SocialPosts/" + socialPostId + "/addComment",
      postSettings
    );
  },
  deleteComment(commentId) {
    return fetch(
      endPoint + "/api/SocialPosts/deleteComment/" + commentId,
      deleteSettings
    );
  },
  deleteSocialPost(socialPostId) {
    return fetch(endPoint + "/api/SocialPosts/" + socialPostId, deleteSettings);
  },

  //Post likers
  addPostLiker(postLiker) {
    postSettings.body = JSON.stringify(postLiker);
    return fetch(endPoint + "/api/PostLikers/", postSettings);
  },

  removePostLiker(postLiker) {
    return fetch(
      endPoint +
        "/api/PostLikers/post/" +
        postLiker.socialPostId +
        "/user/" +
        postLiker.userProfileId,
      deleteSettings
    );
  },

  //Post readings
  addPostReading(postReading) {
    postSettings.body = JSON.stringify(postReading);
    return fetch(endPoint + "/api/PostReadings/", postSettings);
  },

  removePostReading(postReading) {
    return fetch(
      endPoint +
        "/api/PostReadings/post/" +
        postReading.socialPostId +
        "/user/" +
        postReading.userProfileId,
      deleteSettings
    );
  },

  //Private conversations
  getPrivateConversations() {
    return fetch(endPoint + "/api/PrivateConversations/", querySettings).then(
      parseJSON
    );
  },
  createPrivateConversation(privateConversation) {
    postSettings.body = JSON.stringify(privateConversation);
    return fetch(endPoint + "/api/PrivateConversations", postSettings);
  },
  updatePrivateConversation(privateConversation) {
    putSettings.body = JSON.stringify(privateConversation);
    return fetch(
      endPoint + "/api/PrivateConversations/" + privateConversation.id,
      putSettings
    );
  },
  deletePrivateConversation(privateConversationId) {
    return fetch(
      endPoint + "/api/PrivateConversations/" + privateConversationId,
      deleteSettings
    );
  },

  //Private conversation user
  //Creation is handled by the friend requests controller
  deletePrivateConversationUser(convoId, userId) {
    return fetch(
      endPoint +
        "/api/PrivateConversationUsers/conversation/" +
        convoId +
        "/user/" +
        userId,
      deleteSettings
    );
  },

  //User settings
  getUserSettings() {
    return fetch(endPoint + "/api/UserSettings/", querySettings).then(
      parseJSON
    );
  },

  updateUserSettings(settings) {
    putSettings.body = JSON.stringify(settings);
    return fetch(endPoint + "/api/UserSettings/" + settings.id, putSettings);
  },

  //Account handling
  login(loginViewModel) {
    postSettings.body = JSON.stringify(loginViewModel);
    return fetch(endPoint + "/api/AccountAPI/login", postSettings);
  },

  logout() {
    return fetch(endPoint + "/api/AccountAPI/logout", postSettings);
  },

  loginAuto(userCode) {
    postSettings.body = JSON.stringify(userCode);
    return fetch(endPoint + "/api/AccountAPI/loginAuto/", postSettings);
  },

  register(registerViewModel) {
    postSettings.body = JSON.stringify(registerViewModel);
    return fetch(endPoint + "/api/AccountAPI/register", postSettings);
  },

  sendTwoFactor(sendCodeViewModel) {
    postSettings.body = JSON.stringify(sendCodeViewModel);
    return fetch(endPoint + "/api/AccountAPI/sendTwoFactor", postSettings);
  },

  verifyCode(verifyCodeViewModel) {
    postSettings.body = JSON.stringify(verifyCodeViewModel);
    return fetch(endPoint + "/api/AccountAPI/verifyCode", postSettings);
  },

  forgotPassword(forgotPasswordViewModel) {
    postSettings.body = JSON.stringify(forgotPasswordViewModel);
    return fetch(endPoint + "/api/AccountAPI/forgotPassword", postSettings);
  },

  resetPassword(resetPasswordViewModel) {
    postSettings.body = JSON.stringify(resetPasswordViewModel);
    return fetch(endPoint + "/api/AccountAPI/resetPassword", postSettings);
  },

  validatePassword(userName, password) {
    return fetch(
      endPoint +
        "/api/AccountAPI/validatePassword/user/" +
        userName +
        "/password/" +
        password,
      querySettings
    );
  },

  finishConfirmEmail(confirmEmailViewModel) {
    postSettings.body = JSON.stringify(confirmEmailViewModel);
    return fetch(endPoint + "/api/AccountAPI/confirmEmail", postSettings);
  },

  //Weather
  getWeatherCurrent(city) {
    return fetch(endPoint + "/api/weather/current/" + city, querySettings).then(
      parseJSON
    );
  },

  getWeatherForecast(city) {
    return fetch(
      endPoint + "/api/weather/forecast/" + city,
      querySettings
    ).then(parseJSON);
  },

  getWeatherCurrentCoords(lat, lon) {
    return fetch(
      endPoint + "/api/weather/current/coords/" + lat + "/" + lon,
      querySettings
    ).then(parseJSON);
  },

  getWeatherForecastCoords(lat, lon) {
    return fetch(
      endPoint + "/api/weather/forecast/coords/" + lat + "/" + lon,
      querySettings
    ).then(parseJSON);
  },

  //Subscriptions
  getUserSubscription() {
    return fetch(endPoint + "/api/Subscription/", querySettings).then(
      parseJSON
    );
  },

  activateSubscription(subscribeViewModel) {
    postSettings.body = JSON.stringify(subscribeViewModel);
    return fetch(endPoint + "/api/Subscription/subscribeV2", postSettings);
  },

  cancelSubscription(userProfile) {
    postSettings.body = JSON.stringify(userProfile);
    return fetch(endPoint + "/api/Subscription/unsubscribe", postSettings);
  },

  getSubscriptionPaymentLink() {
    return fetch(endPoint + "/api/Subscription/subscriptionLink", querySettings);
  },

  handleCardTransaction() {
    return fetch(endPoint + "/api/Subscription/handleCardTransaction", postSettings);
  },

  updateCard(subscribeViewModel) {
    postSettings.body = JSON.stringify(subscribeViewModel);
    return fetch(endPoint + "/api/Subscription/updateCard", postSettings);
  },

  removeCard() {
    return fetch(endPoint + "/api/Subscription/removeCard/", postSettings);
  },

  getStripeUserCredentials(code) {
    return fetch(
      endPoint + "/api/StripeSubscription/validateAccount/" + code,
      querySettings
    );
  },

  //Generic Stripe payment function
  stripePayment(paymentViewModel) {
    postSettings.body = JSON.stringify(paymentViewModel);
    return fetch(
      endPoint + "/api/StripeSubscription/stripePayment",
      postSettings
    );
  },

  //Deprecated. Use stripePayment instead.
  // transferPayment(paymentViewModel) {
  //   postSettings.body = JSON.stringify(paymentViewModel);
  //   return fetch(endPoint + '/api/UserSubscription/transferPayment', postSettings);
  // },

  //Deprecated. Use stripePayment instead
  payInvoice(paymentViewModel) {
    postSettings.body = JSON.stringify(paymentViewModel);
    return fetch(endPoint + "/api/StripeSubscription/payInvoice", postSettings);
  },

  registerCard(token) {
    return fetch(
      endPoint + "/api/StripeSubscription/registerCard/" + token,
      postSettings
    );
  },

  removeCustomer() {
    return fetch(
      endPoint + "/api/StripeSubscription/removeCustomer/",
      postSettings
    );
  },

  getCardInformation() {
    return fetch(
      endPoint + "/api/StripeSubscription/cardInfo/",
      querySettings
    ).then(parseJSON);
  },

  createSourceCharge(viewModel) {
    postSettings.body = JSON.stringify(viewModel);
    return fetch(
      endPoint + "/api/StripeSubscription/createSourceCharge",
      postSettings
    );
  },

  //Admin section
  //System users
  getSystemUsers() {
    return fetch(endPoint + "/api/SystemUsers", querySettings).then(parseJSON);
  },

  updateUserType(profile) {
    putSettings.body = JSON.stringify(profile);
    return fetch(
      endPoint + "/api/UserProfiles/updateUserType/" + profile.id,
      putSettings
    );
  },

  updatePaidUntil(id, paidUntil) {
    return fetch(
      endPoint + "/api/UserProfiles/" + id + "/paidUntil/" + paidUntil,
      putSettings
    );
  },

  //Subscription plans
  getSubscriptionPlans() {
    return fetch(endPoint + "/api/SubscriptionPlans/", querySettings).then(
      parseJSON
    );
    // return fetch(endPoint + '/api/StripeSubscriptionPlans/', querySettings).then(parseJSON);
  },

  //Fetches all plans
  getSubscriptionPlansForAdmin() {
    return fetch(endPoint + "/api/SubscriptionPlans/admin", querySettings).then(
      parseJSON
    );
    // return fetch(endPoint + '/api/StripeSubscriptionPlans/', querySettings).then(parseJSON);
  },

  createSubscriptionPlan(plan) {
    postSettings.body = JSON.stringify(plan);
    return fetch(endPoint + "/api/SubscriptionPlans/", postSettings);
    // return fetch(endPoint + '/api/StripeSubscriptionPlans/', postSettings);
  },

  createSubscriptionPlanFromFormula(viewModel) {
    postSettings.body = JSON.stringify(viewModel);
    return fetch(
      endPoint + "/api/SubscriptionPlans/createFromFormula",
      postSettings
    );
    // return fetch(endPoint + '/api/StripeSubscriptionPlans/createFromFormula', postSettings);
  },

  updateSubscriptionPlan(plan) {
    putSettings.body = JSON.stringify(plan);
    return fetch(endPoint + "/api/SubscriptionPlans/" + plan.id, putSettings);
    // return fetch(endPoint + '/api/StripeSubscriptionPlans/' + plan.planId, putSettings);
  },

  deleteSubscriptionPlan(id) {
    return fetch(endPoint + "/api/SubscriptionPlans/" + id, deleteSettings);
    // return fetch(endPoint + '/api/StripeSubscriptionPlans/' + planId, deleteSettings);
  },

  //User account management
  deleteUserAccount(userName) {
    return fetch(
      endPoint + "/api/AccountAPI/deleteUser/" + userName,
      deleteSettings
    );
  },

  //Global settings
  getGlobalSettings() {
    return fetch(endPoint + "/api/GlobalSettings/all", querySettings).then(
      parseJSON
    );
  },

  createGlobalSetting(setting) {
    postSettings.body = JSON.stringify(setting);
    return fetch(endPoint + "/api/GlobalSettings/", postSettings);
  },

  updateGlobalSetting(setting) {
    putSettings.body = JSON.stringify(setting);
    return fetch(endPoint + "/api/GlobalSettings/" + setting.key, putSettings);
  },

  deleteGlobalSetting(setting) {
    return fetch(
      endPoint + "/api/GlobalSettings/" + setting.key,
      deleteSettings
    );
  },

  //User groups
  getUserGroup() {
    return fetch(endPoint + "/api/UserGroups", querySettings).then(parseJSON);
  },

  joinUserGroup(code) {
    return fetch(endPoint + "/api/UserGroups/join/" + code, postSettings);
  },

  //Only available for admins
  getGroupMembers() {
    return fetch(endPoint + "/api/UserGroups/members/", querySettings).then(
      parseJSON
    );
  },

  removeGroupMember(id) {
    return fetch(endPoint + "/api/UserGroups/removeMember/" + id, postSettings);
  },

  generateGroupCode() {
    return fetch(endPoint + "/api/UserGroups/generateCode/", postSettings);
  },

  //Case groups
  createEditHistoryItem(item) {
    postSettings.body = JSON.stringify(item);
    return fetch(endPoint + "/api/EditHistoryItems/", postSettings);
  },

  deleteEditHistoryItem(itemId) {
    return fetch(endPoint + "/api/EditHistoryItems/" + itemId, deleteSettings);
  },

  shareCaseWithGroup(caseModelId) {
    return fetch(
      endPoint + "/api/CaseSharings/shareWithGroup/" + caseModelId,
      postSettings
    );
  },

  shareCaseWithList(caseModelId, sharingUsers) {
    postSettings.body = JSON.stringify(sharingUsers);
    return fetch(
      endPoint + "/api/CaseSharings/shareWithList/" + caseModelId,
      postSettings
    );
  },

  shareCase(caseSharing) {
    postSettings.body = JSON.stringify(caseSharing);
    return fetch(endPoint + "/api/CaseSharings/add", postSettings);
  },

  removeCaseSharing(caseSharing) {
    postSettings.body = JSON.stringify(caseSharing);
    return fetch(endPoint + "/api/CaseSharings/remove", postSettings);
  },

  checkoutCase(caseModelId) {
    postSettings.body = "";
    return fetch(endPoint + "/api/cases/checkout/" + caseModelId, postSettings);
  },

  checkinCase(caseModelId) {
    postSettings.body = "";
    return fetch(endPoint + "/api/cases/checkin/" + caseModelId, postSettings);
  },

  //External mail settings
  getEmailAccounts() {
    return fetch(endPoint + "/api/EmailAccounts", querySettings).then(
      parseJSON
    );
  },

  createEmailAccount(account) {
    postSettings.body = JSON.stringify(account);
    return fetch(endPoint + "/api/EmailAccounts/", postSettings);
  },

  updateEmailAccount(account) {
    putSettings.body = JSON.stringify(account);
    return fetch(endPoint + "/api/EmailAccounts/" + account.id, putSettings);
  },

  deleteEmailAccount(accountId) {
    return fetch(endPoint + "/api/EmailAccounts/" + accountId, deleteSettings);
  },

  //Case versions
  createCaseVersion(caseVersion) {
    postSettings.body = JSON.stringify(caseVersion);
    return fetch(endPoint + "/api/CaseVersions/", postSettings);
  },

  updateCaseVersion(caseVersion) {
    putSettings.body = JSON.stringify(caseVersion);
    return fetch(endPoint + "/api/CaseVersions/" + caseVersion.id, putSettings);
  },

  deleteCaseVersion(caseVersionId) {
    return fetch(
      endPoint + "/api/CaseVersions/" + caseVersionId,
      deleteSettings
    );
  },

  //Subscription formulas
  getSubscriptionFormulas() {
    return fetch(endPoint + "/api/SubscriptionFormulas", querySettings).then(
      parseJSON
    );
  },

  createSubscriptionFormula(formula) {
    postSettings.body = JSON.stringify(formula);
    return fetch(endPoint + "/api/SubscriptionFormulas/", postSettings);
  },

  updateSubscriptionFormula(formula) {
    putSettings.body = JSON.stringify(formula);
    return fetch(
      endPoint + "/api/SubscriptionFormulas/" + formula.id,
      putSettings
    );
  },

  deleteSubscriptionFormula(formulaId) {
    return fetch(
      endPoint + "/api/SubscriptionFormulas/" + formulaId,
      deleteSettings
    );
  },

  //CSV parsing
  readClientsFromCSV(files) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files.item(i).name, files.item(i));
    }
    formPostSettings.body = data;
    return fetch(endPoint + "/api/ContactInfoes/readCSV", formPostSettings);
  },

  readBifrostClientsFromCSV(files) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files.item(i).name, files.item(i));
    }
    formPostSettings.body = data;
    return fetch(endPoint + "/api/ContactInfoes/readVLCSV", formPostSettings);
  },

  readPolarClientsFromCSV(files) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files.item(i).name, files.item(i));
    }
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/ContactInfoes/readPolarCSV",
      formPostSettings
    );
  },

  readPolarWaitClientsFromCSV(files) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files.item(i).name, files.item(i));
    }
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/ContactInfoes/readPolarWaitCSV",
      formPostSettings
    );
  },

  readGreveClientsFromCSV(files) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files.item(i).name, files.item(i));
    }
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/ContactInfoes/readGreveCSV",
      formPostSettings
    );
  },

  readBroendbyClientsFromCSV(files) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files.item(i).name, files.item(i));
    }
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/ContactInfoes/readBroendbyCSV",
      formPostSettings
    );
  },

  readSaunaClientsFromCSV(files) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files.item(i).name, files.item(i));
    }
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/ContactInfoes/readSaunaCSV",
      formPostSettings
    );
  },

  //Invoice sending
  sendInvoiceToClients(viewModel) {
    postSettings.body = JSON.stringify(viewModel);
    return fetch(endPoint + "/api/Invoices/sendToClients", postSettings);
  },

  //Accounting
  //Account types
  getPostingAccountTypes() {
    return fetch(endPoint + "/api/PostingAccountTypes", querySettings).then(
      parseJSON
    );
  },

  createPostingAccountType(type) {
    postSettings.body = JSON.stringify(type);
    return fetch(endPoint + "/api/PostingAccountTypes/", postSettings);
  },

  updatePostingAccountType(type) {
    putSettings.body = JSON.stringify(type);
    return fetch(endPoint + "/api/PostingAccountTypes/" + type.id, putSettings);
  },

  deletePostingAccountType(id) {
    return fetch(endPoint + "/api/PostingAccountTypes/" + id, deleteSettings);
  },

  //Account
  getPostingAccounts() {
    return fetch(endPoint + "/api/PostingAccounts", querySettings).then(
      parseJSON
    );
  },

  createPostingAccount(account) {
    postSettings.body = JSON.stringify(account);
    return fetch(endPoint + "/api/PostingAccounts/", postSettings);
  },

  updatePostingAccount(account) {
    putSettings.body = JSON.stringify(account);
    return fetch(endPoint + "/api/PostingAccounts/" + account.id, putSettings);
  },

  deletePostingAccount(id) {
    return fetch(endPoint + "/api/PostingAccounts/" + id, deleteSettings);
  },

  //Postings
  getPostings(pageSize) {
    return fetch(endPoint + "/api/Postings/" + pageSize, querySettings).then(
      parseJSON
    );
  },

  getPostingsForClient(pageSize, clientId) {
    return fetch(
      endPoint + "/api/Postings/client/" + clientId + "/pageSize/" + pageSize,
      querySettings
    ).then(parseJSON);
  },

  getMyPostings(pageSize) {
    return fetch(
      endPoint + "/api/Postings/clientUser/pageSize/" + pageSize,
      querySettings
    ).then(parseJSON);
  },

  createPosting(posting) {
    postSettings.body = JSON.stringify(posting);
    return fetch(endPoint + "/api/Postings/", postSettings);
  },

  updatePosting(posting) {
    putSettings.body = JSON.stringify(posting);
    return fetch(endPoint + "/api/Postings/" + posting.id, putSettings);
  },

  deletePosting(id) {
    return fetch(endPoint + "/api/Postings/" + id, deleteSettings);
  },

  getNextAttachmentNumber(clientId) {
    return fetch(
      endPoint + "/api/Postings/nextAttachmentNumber/" + clientId,
      querySettings
    ).then(parseString);
  },

  //Payment requests
  getPaymentRequest(code) {
    return fetch(endPoint + "/api/PaymentRequests/" + code, querySettings).then(
      parseJSON
    );
  },

  getPaymentRequestsForInvoice(invoiceId) {
    return fetch(
      endPoint + "/api/PaymentRequests/invoice/" + invoiceId,
      querySettings
    ).then(parseJSON);
  },

  //Payment requests targeting this user. Used for subscription invoices
  getPaymentRequestsForCurrentUser() {
    return fetch(
      endPoint + "/api/PaymentRequests/currentUser/",
      querySettings
    ).then(parseJSON);
  },

  cancelRegistrationRequest(guid) {
    return fetch(
      endPoint + "/api/PaymentRequests/cancelRegistration/" + guid,
      postSettings
    );
  },

  deletePaymentRequest(contactId, invoiceId) {
    return fetch(
      endPoint +
        "/api/PaymentRequests/contact/" +
        contactId +
        "/invoice/" +
        invoiceId,
      deleteSettings
    );
  },

  //CVR Api
  //Uses the simple CVR Api endpoint. Limit of 50 requests per day.
  getCVRInformation(cvr, country) {
    return fetch(
      endPoint + "/api/CompanyInfo/cvr/" + cvr + "/country/" + country,
      querySettings
    ).then(parseJSON);
    // return fetch('http://cvrapi.dk/api?search=' + cvr + '&country=' + country, querySettings).then(parseJSON);
  },

  //Uses the much more complex and detailed virk.dk api
  getDetailedCVRInformation(cvr) {
    return fetch(
      endPoint + "/api/CVRDetails/cvr/" + cvr + "/all",
      querySettings
    ).then(parseJSON);
  },

  //Returning only the company name from virk.dk
  getCVRName(cvr) {
    return fetch(
      endPoint + "/api/CVRDetails/cvr/" + cvr + "/name",
      querySettings
    ).then(parseJSON);
  },

  //Uses https://www.isvat.eu/. Limit of 100 requests per month
  getCompanyInfo(countryCode, vatNumber) {
    return fetch(
      endPoint +
        "/api/EUCompanyInfo/country/" +
        countryCode +
        "/vat/" +
        vatNumber,
      querySettings
    ).then(parseJSON);
  },

  //Global sharing
  getSharingGlobals() {
    return fetch(endPoint + "/api/SharingGlobals/all/", querySettings).then(
      parseJSON
    );
  },

  createSharingGlobal(sharingGlobal) {
    postSettings.body = JSON.stringify(sharingGlobal);
    return fetch(endPoint + "/api/SharingGlobals/create/", postSettings);
  },

  deleteSharingGlobal(id) {
    return fetch(endPoint + "/api/SharingGlobals/delete/" + id, deleteSettings);
  },

  //Single sharing
  createSingleSharing(singleSharing) {
    postSettings.body = JSON.stringify(singleSharing);
    return fetch(endPoint + "/api/SingleSharings/create/", postSettings);
  },

  deleteSingleSharing(id) {
    return fetch(endPoint + "/api/SingleSharings/delete/" + id, deleteSettings);
  },

  //Collaboration
  getCollaborationString() {
    return fetch(endPoint + "/api/Collaboration/", querySettings).then(
      parseString
    );
  },

  updateCollaborationString(contentString) {
    postSettings.body = JSON.stringify(contentString);
    return fetch(endPoint + "/api/Collaboration/", postSettings);
  },

  summarizeCollaborationString(contentString) {
    postSettings.body = JSON.stringify(contentString);
    return fetch(endPoint + "/api/Collaboration/summarize", postSettings);
  },

  //Case document assignments
  createCaseDocumentAssignment(caseDocumentAssignment) {
    postSettings.body = JSON.stringify(caseDocumentAssignment);
    return fetch(endPoint + "/api/CaseDocumentAssignments", postSettings);
  },

  deleteCaseDocumentAssignment(caseId, documentId) {
    return fetch(
      endPoint +
        "/api/CaseDocumentAssignments/case/" +
        caseId +
        "/document/" +
        documentId,
      deleteSettings
    );
  },

  //Item prices
  getItemPrices() {
    return fetch(endPoint + "/api/ItemPrices/", querySettings).then(parseJSON);
  },

  createItemPrice(itemPrice) {
    postSettings.body = JSON.stringify(itemPrice);
    return fetch(endPoint + "/api/ItemPrices/", postSettings);
  },

  updateItemPrice(itemPrice) {
    putSettings.body = JSON.stringify(itemPrice);
    return fetch(endPoint + "/api/ItemPrices/" + itemPrice.id, putSettings);
  },

  deleteItemPrice(id) {
    return fetch(endPoint + "/api/ItemPrices/" + id, deleteSettings);
  },

  //Item price types
  getItemPriceTypes() {
    return fetch(endPoint + "/api/ItemPriceTypes/", querySettings).then(
      parseJSON
    );
  },

  createItemPriceType(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/ItemPriceTypes/", postSettings);
  },

  updateItemPriceType(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/ItemPriceTypes/" + model.id, putSettings);
  },

  deleteItemPriceType(id) {
    return fetch(endPoint + "/api/ItemPriceTypes/" + id, deleteSettings);
  },

  //Presentations
  getLegalPresentations() {
    return fetch(endPoint + "/api/Presentations/legal/", querySettings).then(
      parseJSON
    );
  },

  //Bookkeeping entries
  getBookkeepingEntries(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries" + queryParams,
      querySettings
    ).then(parseJSON);
  },

  getNextReceiptNumber(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/nextReceiptNumber" + queryParams,
      querySettings
    ).then(parseString);
  },

  getStoredAccount(query = { clientId: 0, key: "" }) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/storedAccount" + queryParams,
      querySettings
    ).then(parseString);
  },

  // getBookkeepingDraftEntriesForUser() {
  //   return fetch(endPoint + '/api/BookkeepingDraftEntries?filter=drafts', querySettings).then(parseJSON);
  // },

  getBookkeepingDraftEntriesForClient(clientId) {
    return fetch(
      endPoint +
        "/api/BookkeepingDraftEntries?filter=drafts&clientId=" +
        clientId,
      querySettings
    ).then(parseJSON);
  },

  createBookkeepingDraftEntry(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/BookkeepingDraftEntries/", postSettings);
  },

  createBookkeepingDraftEntryList(list = []) {
    postSettings.body = JSON.stringify(list);
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/createList",
      postSettings
    );
  },

  updateBookkeepingDraftEntry(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/" + model.id,
      putSettings
    );
  },

  updateBookkeepingDraftEntryList(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/batchUpdate",
      postSettings
    );
  },

  finalizeEntries(entryIds, clientId) {
    postSettings.body = JSON.stringify(entryIds);
    return fetch(
      endPoint +
        "/api/BookkeepingDraftEntries/FinalizeRange?clientId=" +
        clientId,
      postSettings
    );
  },

  sendBookkeepingDraftEntryToAccountant(id) {
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/send/" + id,
      postSettings
    );
  },

  getEntryExportPath(contactId, incoming = false) {
    let queryParams = jQuery.param({ contactId, incoming });
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }

    return endPoint + "/api/BookkeepingDraftEntries/export" + queryParams;
  },

  printReceipts({ contactId, label }) {
    postSettings.body = JSON.stringify({ contactId, label });
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/printReceipts/",
      postSettings
    );
  },

  printFilteredReceipts(printSettings) {
    postSettings.body = JSON.stringify(printSettings);
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/printFilteredReceipts/",
      postSettings
    );
  },

  //CSV parsing
  readEntriesFromCSV(files, contactId) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files.item(i).name, files.item(i));
    }
    formPostSettings.body = data;
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/import/" + contactId,
      formPostSettings
    );
  },

  // deleteBookkeepingDraftEntry(id) {
  //   return fetch(endPoint + '/api/BookkeepingDraftEntries/' + id, deleteSettings);
  // },

  deleteBookkeepingDraftEntryAndReceipt(id) {
    return fetch(
      endPoint + "/api/BookkeepingDraftEntries/" + id + "?deleteReceipt=true",
      deleteSettings
    );
  },

  // deleteBookkeepingDraftEntryIds(idList = []) {
  //   postSettings.body = JSON.stringify(idList);
  //   return fetch(endPoint + '/api/BookkeepingDraftEntries/deleteByIds', postSettings);
  // },

  //Finance account plans
  getFinanceAccountPlans() {
    return fetch(endPoint + "/api/FinanceAccountPlans", querySettings).then(
      parseJSON
    );
  },

  getStandardFinanceAccountPlans() {
    return fetch(
      endPoint + "/api/FinanceAccountPlans/standard",
      querySettings
    ).then(parseJSON);
  },

  getClientPlan(clientId) {
    return fetch(
      endPoint + "/api/FinanceAccountPlans/client/" + clientId,
      querySettings
    ).then(parseJSON);
  },

  createFinanceAccountPlan(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/FinanceAccountPlans/", postSettings);
  },

  copyFinanceAccountPlan(id) {
    return fetch(
      endPoint + "/api/FinanceAccountPlans/copy/" + id,
      postSettings
    );
  },

  copyPlanToClient(params = { planId: 0, clientId: 0 }) {
    return fetch(
      endPoint +
        "/api/FinanceAccountPlans/copyToClient/source/" +
        params.planId +
        "/client/" +
        params.clientId,
      postSettings
    );
  },

  importFinanceAccountPlan(planData) {
    postSettings.body = planData;
    return fetch(endPoint + "/api/FinanceAccountPlans/import/", postSettings);
  },

  updateFinanceAccountPlan(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(
      endPoint + "/api/FinanceAccountPlans/" + model.id,
      putSettings
    );
  },

  deleteFinanceAccountPlan(id) {
    return fetch(endPoint + "/api/FinanceAccountPlans/" + id, deleteSettings);
  },

  //Finance account
  getFinanceAccount() {
    return fetch(endPoint + "/api/FinanceAccounts", querySettings).then(
      parseJSON
    );
  },

  createFinanceAccount(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/FinanceAccounts/", postSettings);
  },

  updateFinanceAccount(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/FinanceAccounts/" + model.id, putSettings);
  },

  deleteFinanceAccount(id) {
    return fetch(endPoint + "/api/FinanceAccounts/" + id, deleteSettings);
  },

  //Tax specifications
  createTaxSpecification(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/TaxSpecifications/", postSettings);
  },

  updateTaxSpecification(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/TaxSpecifications/" + model.id, putSettings);
  },

  deleteTaxSpecification(id) {
    return fetch(endPoint + "/api/TaxSpecifications/" + id, deleteSettings);
  },

  //Fiscal Years
  //Fetches fiscal years for the selected client
  getFiscalYears(clientId) {
    return fetch(
      endPoint + "/api/FiscalYears?clientId=" + clientId,
      querySettings
    ).then(parseJSON);
  },

  createFiscalYear(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/FiscalYears/", postSettings);
  },

  updateFiscalYear(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/FiscalYears/" + model.id, putSettings);
  },

  deleteFiscalYear(id) {
    return fetch(endPoint + "/api/FiscalYears/" + id, deleteSettings);
  },

  //Account journals
  getPostingJournals(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/PostingJournals" + queryParams,
      querySettings
    ).then(parseJSON);
  },

  rollbackPostingJournal(id) {
    return fetch(
      endPoint + `/api/PostingJournals/rollback/${id}`,
      postSettings
    );
  },

  //Financial report
  getAccountingReport(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/AccountingReports" + queryParams,
      querySettings
    ).then(parseJSON);
  },

  getVatReport(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/AccountingReports/vat" + queryParams,
      querySettings
    ).then(parseJSON);
  },

  getDraftSimulation(query, entryIds = []) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }

    postSettings.body = JSON.stringify(entryIds);
    return fetch(
      endPoint + "/api/AccountingReports/draftSimulation" + queryParams,
      postSettings
    ).then(parseJSON);
  },

  //Accounting automation
  settleVat(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/AccountingAutomation/settleVat" + queryParams,
      postSettings
    );
  },

  payVat(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/AccountingAutomation/payVat" + queryParams,
      postSettings
    );
  },

  //Query: { clientId, fiscalYearId }
  transferBalance(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/AccountingAutomation/transferBalance" + queryParams,
      postSettings
    );
  },

  //Bank statements
  importBankStatement(files, query) {
    var data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files.item(i).name, files.item(i));
    }
    formPostSettings.body = data;

    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }

    return fetch(
      endPoint + "/api/BankBalancing/parseCSV" + queryParams,
      formPostSettings
    );
  },

  //query = { clientId, startDate, endDate }
  getBankPostings(query) {
    let queryParams = jQuery.param(query || {});
    if (!!queryParams) {
      queryParams = "?" + queryParams;
    }
    return fetch(
      endPoint + "/api/BankPostings" + queryParams,
      querySettings
    ).then(parseJSON);
  },

  createBankPosting(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/BankPostings/", postSettings);
  },

  updateBankPosting(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/BankPostings/" + model.id, putSettings);
  },

  updateBankPostingList(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/BankPostings/batchUpdate", postSettings);
  },

  deleteBankPosting(id) {
    return fetch(endPoint + "/api/BankPostings/" + id, deleteSettings);
  },

  deleteAllBankPostings(clientId) {
    return fetch(
      endPoint + "/api/BankPostings/client/" + clientId,
      deleteSettings
    );
  },

  //Reconciliation
  addReconciliation(reconciliation) {
    postSettings.body = JSON.stringify(reconciliation);
    return fetch(endPoint + "/api/Reconciliations/", postSettings);
  },

  removeReconciliation(reconciliation) {
    return fetch(
      endPoint +
        "/api/Reconciliations/entry/" +
        reconciliation.entryId +
        "/posting/" +
        reconciliation.bankPostingId,
      deleteSettings
    );
  },

  getAnnualReportDownloadPath(clientId, fiscalYearId) {
    return (
      endPoint +
      "/api/AccountingPrint/printReport?clientId=" +
      clientId +
      "&fiscalYearId=" +
      fiscalYearId
    );
  },

  generateAnnualReport(data) {
    postSettings.body = JSON.stringify(data);
    return fetch(
      endPoint + "/api/AccountingPrint/generateAnnualReport",
      postSettings
    );
  },

  //Scanpay
  makePaymentLink(customerInfo) {
    postSettings.body = JSON.stringify(customerInfo);
    return fetch(endPoint + "/api/ScanPay/makePaymentLink", postSettings);
  },

  makePaymentLinkForRequest(requestInfo) {
    postSettings.body = JSON.stringify(requestInfo);
    return fetch(
      endPoint + "/api/ScanPay/makePaymentLinkForRequest",
      postSettings
    );
  },

  makeRegistrationLinkForRequest(requestInfo) {
    postSettings.body = JSON.stringify(requestInfo);
    return fetch(
      endPoint + "/api/ScanPay/makeRegistrationLinkForRequest",
      postSettings
    );
  },

  registerPayment(registerParams) {
    postSettings.body = JSON.stringify(registerParams);
    return fetch(endPoint + "/api/ScanPay/registerPayment", postSettings);
  },

  chargeRequestSubscriber(requestInfo) {
    postSettings.body = JSON.stringify(requestInfo);
    return fetch(
      endPoint + "/api/ScanPay/chargeRequestSubscriber",
      postSettings
    );
  },

  chargeRequestSubscriberList(requestInfo) {
    postSettings.body = JSON.stringify(requestInfo);
    return fetch(
      endPoint + "/api/ScanPay/chargeRequestSubscriberList",
      postSettings
    );
  },

  sendReceiptToRequestSubscriber(requestInfo) {
    postSettings.body = JSON.stringify(requestInfo);
    return fetch(
      endPoint + "/api/ScanPay/sendReceiptToRequestSubscriber",
      postSettings
    );
  },

  //ApplicationUD functions
  getApplicationUD() {
    return fetch(endPoint + "/api/ApplicationUD", querySettings).then(
      parseJSON
    );
  },

  createApplicationUD(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/ApplicationUD/", postSettings);
  },

  updateApplicationUD(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/ApplicationUD/" + model.id, putSettings);
  },

  deleteApplicationUD(id) {
    return fetch(endPoint + "/api/ApplicationUD/" + id, deleteSettings);
  },

  //BusinessOperating functions
  getBusinessOperating() {
    return fetch(endPoint + "/api/BusinessOperatings", querySettings).then(
      parseJSON
    );
  },

  createBusinessOperating(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/BusinessOperatings/", postSettings);
  },

  updateBusinessOperating(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/BusinessOperatings/" + model.id, putSettings);
  },

  deleteBusinessOperating(id) {
    return fetch(endPoint + "/api/BusinessOperatings/" + id, deleteSettings);
  },

  //Accounting algorithms
  findMatches(input = { entries: [], bankPostings: [] }) {
    postSettings.body = JSON.stringify(input);
    return fetch(endPoint + "/api/Algorithm/findMatches", postSettings).then(
      parseJSON
    );
  },

  findMultiMatches(input = { entries: [], bankPostings: [] }) {
    postSettings.body = JSON.stringify(input);
    return fetch(
      endPoint + "/api/Algorithm/findMultiMatches",
      postSettings
    ).then(parseJSON);
  },

  //BusinessOperating functions
  getPowerOfAttorney() {
    return fetch(endPoint + "/api/PowerOfAttorneys", querySettings).then(
      parseJSON
    );
  },

  createPowerOfAttorney(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/PowerOfAttorneys/", postSettings);
  },

  updatePowerOfAttorney(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/PowerOfAttorneys/" + model.id, putSettings);
  },

  deletePowerOfAttorney(id) {
    return fetch(endPoint + "/api/PowerOfAttorneys/" + id, deleteSettings);
  },
  //EmploymentContract
  getEmploymentContract() {
    return fetch(endPoint + "/api/EmploymentContracts", querySettings).then(
      parseJSON
    );
  },

  createEmploymentContract(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/EmploymentContracts/", postSettings);
  },

  updateEmploymentContract(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(
      endPoint + "/api/EmploymentContracts/" + model.id,
      putSettings
    );
  },

  deleteEmploymentContract(id) {
    return fetch(endPoint + "/api/EmploymentContracts/" + id, deleteSettings);
  },

  //Template functions
  getProducts() {
    return fetch(endPoint + "/api/Products", querySettings).then(parseJSON);
  },

  createProduct(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/Products/", postSettings);
  },

  updateProduct(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/Products/" + model.id, putSettings);
  },

  deleteProduct(id) {
    return fetch(endPoint + "/api/Products/" + id, deleteSettings);
  },

  getTurnCredentials() {
    return fetch(endPoint + "/api/WebRTC/turnCredentials", querySettings).then(
      parseJSON
    );
  },

  printHtmlToPDF(printSettings) {
    postSettings.body = JSON.stringify(printSettings);
    return fetch(endPoint + "/api/print/html/", postSettings);
  },

  getEasyIDSettings() {
    return fetch(endPoint + "/api/EasyID/settings", querySettings).then(
      parseJSON
    );
  },

  getEasyIDSignatureSettings(fileId) {
    return fetch(
      endPoint + "/api/EasyID/signatureSettings/" + fileId,
      querySettings
    ).then(parseJSON);
  },

  handleEasyIDLogon(logonParams) {
    postSettings.body = JSON.stringify(logonParams);
    return fetch(endPoint + "/api/EasyID/logon", postSettings);
  },

  handleEasyIDAccountLogon(logonParams) {
    postSettings.body = JSON.stringify(logonParams);
    return fetch(endPoint + "/api/EasyID/logonUser", postSettings);
  },

  handleMitIDAccountLogon(logonParams) {
    postSettings.body = JSON.stringify(logonParams);
    return fetch(endPoint + "/api/MitID/logonUser", postSettings);
  },

  handleEasyIDSignature(logonParams) {
    postSettings.body = JSON.stringify(logonParams);
    return fetch(endPoint + "/api/EasyID/signDocument", postSettings);
  },

  getSigningRequest(guid) {
    return fetch(endPoint + "/api/SigningRequest/" + guid, querySettings).then(
      parseJSON
    );
  },

  createSigningRequest(request) {
    postSettings.body = JSON.stringify(request);
    return fetch(endPoint + "/api/SigningRequest/", postSettings);
  },

  updateSigningRequest(request) {
    putSettings.body = JSON.stringify(request);
    return fetch(endPoint + "/api/SigningRequest/" + request.id, putSettings);
  },

  deleteSigningRequest(id) {
    return fetch(endPoint + "/api/SigningRequest/" + id, deleteSettings);
  },

  getRequestFileDownloadPath(guid, signerGuid, code) {
    let queryString = "/api/SigningRequest/file/" + guid;
    if (!!signerGuid) {
      queryString = queryString + "?signerGuid=" + signerGuid + "&code=" + code;
    }
    return endPoint + queryString;
  },

  getRequestSignedFileDownloadPath(guid, email, code) {
    let queryString = "/api/SigningRequest/signedFile/" + guid;
    if (!!email) {
      queryString = queryString + "?email=" + email + "&code=" + code;
    }

    return endPoint + queryString;
  },

  sendSignedFileToSigners(guid) {
    return fetch(
      `${endPoint}/api/SigningRequest/sendSignedFile/${guid}`,
      postSettings
    );
  },

  getRequestSignDataDownloadPath(signatureId) {
    let queryString = "/api/SigningRequest/signData/" + signatureId;
    return endPoint + queryString;
  },

  sendSigningRequestTwoFactor(settings) {
    postSettings.body = JSON.stringify(settings);
    return fetch(endPoint + "/api/SigningRequest/twoFactor/send", postSettings);
  },

  validateSigningRequestTwoFactor(settings) {
    postSettings.body = JSON.stringify(settings);
    return fetch(
      endPoint + "/api/SigningRequest/twoFactor/validate",
      postSettings
    );
  },

  createSignature(signature) {
    postSettings.body = JSON.stringify(signature);
    return fetch(endPoint + "/api/Signature/", postSettings);
  },

  deleteSignature(id) {
    return fetch(endPoint + "/api/Signature/" + id, deleteSettings);
  },

  getEasyIDSignatureRequestSettings(guid) {
    return fetch(
      endPoint + "/api/EasyID/signatureRequestSettings/" + guid,
      querySettings
    ).then(parseJSON);
  },

  generateSignature(params) {
    postSettings.body = JSON.stringify(params);
    return fetch(endPoint + "/api/EasyID/generateSignature/", postSettings);
  },

  getMitIDSignatureSettings(guid, query) {
    var queryParams = jQuery.param(query || {});
    
    return fetch(
      `${endPoint}/api/MitID/mitIDSignatureSettings/${guid}/?${queryParams}`,
      querySettings
    ).then(parseJSON);
  },

  generateSignedDocument(guid) {
    return fetch(
      endPoint + "/api/EasyID/generateSignedDocument/" + guid,
      postSettings
    );
  },

  //Dashboard info
  getTrashCount() {
    return fetch(
      endPoint + "/api/DashboardData/trashCount",
      querySettings
    ).then(parseString);
  },

  lightOn() {
    return fetch(endPoint + "/api/IoT/On", postSettings);
  },

  lightOff() {
    return fetch(endPoint + "/api/IoT/Off", postSettings);
  },

  //Template functions
  getModelTemplate() {
    return fetch(endPoint + "/api/ModelTemplates", querySettings).then(
      parseJSON
    );
  },

  createModelTemplate(model) {
    postSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/ModelTemplates/", postSettings);
  },

  updateModelTemplate(model) {
    putSettings.body = JSON.stringify(model);
    return fetch(endPoint + "/api/ModelTemplates/" + model.id, putSettings);
  },

  deleteModelTemplate(id) {
    return fetch(endPoint + "/api/ModelTemplates/" + id, deleteSettings);
  },

  //Template thingies
  getThingy() {
    return fetch(endPoint + "/api/Thingy", querySettings).then(parseJSON);
  },
};
