import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { AsyncButton, TextAreaWrapper } from "../../utilities/LexUtilities";

export default class CollaborationView extends React.PureComponent {
  render() {
    var {
      contentString,
      // summaryString,
      onUpdate,
      onSummarize,
      disabled,
    } = this.props;

    return (
      <Grid fluid>
        <Row>
          <Col
            className="standardMaxWidth"
            xs={12}
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              margin: "auto",
            }}
          >
            <TextAreaWrapper
              onChange={(event) => onUpdate(event.target.value)}
              value={contentString}
              rows={35}
              disabled={disabled}
            />
          </Col>
        </Row>
        {/* { !!summaryString && (
          <Row>
            <Col xs={12}>
              <div style={{ whiteSpace: 'pre-wrap'}}>
                { summaryString }
              </div>
            </Col>
          </Row>
        )} */}
        <Row>
          <Col xs={12}>
            <AsyncButton onClick={() => onSummarize(contentString)}>
              Generer
            </AsyncButton>
          </Col>
        </Row>
      </Grid>
    );
  }
}
