import React from "react";
import PropTypes from "prop-types";
// import { ExtraSmall, SmallOrLarger } from '../utilities/Responsive';
import { Table, Column, Cell } from "fixed-data-table-2";

import moment from "moment";
import { debounce } from "lodash";

import { NumberService, DataActions } from "../../../services/AxoServices";

import ReactSelect from "react-select";

import {
  AxoCheckbox,
  TableBaseSimple,
  Dimensions,
  DataListWrapper,
  SortHeaderCell,
  AxoLocal,
  getText,
  Flexbox,
  FlexElement,
  InlineNumberEdit,
  Icon,
  InlineEdit,
  FilterTypes,
  AxoDateTimeEdit,
} from "../../../utilities/LexUtilities";

class BalancingEntryTable extends TableBaseSimple {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    selectedEntries: PropTypes.object.isRequired,
    onSelectEntry: PropTypes.func.isRequired,
    onSelectAllEntries: PropTypes.func.isRequired,
    clearSelectedEntries: PropTypes.func.isRequired,
    selectedBankAccountId: PropTypes.number,
    showSelectors: PropTypes.bool.isRequired,
    onUpdateEntry: PropTypes.func.isRequired,
    financeAccountMap: PropTypes.object.isRequired,
    taxSpecificationMap: PropTypes.object.isRequired,
    onShowReceipt: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this._defaultSortIndexes = TableBaseSimple.generateDefaultSortIndices(
      props.entries
    );
    let tableEntries = props.entries;
    this.state = {
      tableEntries,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
    };

    this.colorIndices = [];
    for (let i = 0; i < 15; i++) {
      this.colorIndices.push(i);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.entries) === JSON.stringify(prevProps.entries)
    ) {
      return;
    }

    let tableEntries = this.props.entries;
    this.updateEntries(tableEntries, tableEntries);
  }

  updateAmountAndVat = (entryId, amount) => {
    let entry = this.props.entries.find((e) => e.id === entryId);
    if (!entry) {
      return;
    }

    let newEntry = { ...entry };
    newEntry.isIncome = amount >= 0;
    newEntry.amount = Math.abs(amount);
    newEntry = this.updateVat(newEntry);

    this.props.onUpdateEntry(newEntry);
  };

  updateVat = (entry) => {
    let { financeAccountMap, taxSpecificationMap } = this.props;
    var account = financeAccountMap[entry.financeAccountId];
    if (!account) {
      return entry;
    }

    if (entry.amount <= 0) {
      entry.vat = 0;
      return entry;
    }

    var taxSpec = taxSpecificationMap[account.taxSpecificationId];
    if (!taxSpec) {
      entry.vat = 0;
      return entry;
    }

    let isReverseVat =
      taxSpec.taxType === "ForeignGoods" ||
      taxSpec.taxType === "ServiceReverseCharge";

    if (isReverseVat) {
      //Amount is without vat
      entry.vat = (entry.amount * taxSpec.taxPercentage) / 100;
    } else {
      entry.vat =
        entry.amount - entry.amount / (1 + taxSpec.taxPercentage / 100);
    }

    return entry;
  };

  _rowClassNameGetter = (rowIndex) => {
    let {
      entryWarnings,
      showDoublesHighlight,
      creditorEntryIds,
      debitorEntryIds,
      entryMultiMatches,
      displayedEntryId,
    } = this.props;

    let { sortedDataList } = this.state;

    let entry = sortedDataList.getObjectAt(rowIndex);
    if (displayedEntryId === entry.id) {
      return "highlight-row";
    }
    if (!!entryMultiMatches[entry.id]) {
      return (
        "highlight-row-" +
        this.colorIndices[
          (entryMultiMatches[entry.id] - 1) % this.colorIndices.length
        ]
      );
    }
    if (entryWarnings[entry.id]) {
      return "highlight-row-rejected";
    }
    if (entry.approved) {
      return "highlight-row-approved";
    }
    if (showDoublesHighlight) {
      return "highlight-row-warning";
    }
    if (!!creditorEntryIds[entry.id]) {
      return "highlight-row-creditor";
    }
    if (!!debitorEntryIds[entry.id]) {
      return "highlight-row-debitor";
    }
  };

  filterValues = ({ label }, search) => {
    return label.toLowerCase().includes(search.toLowerCase());
  };

  updateDate = (entry, inputDate) => {
    if (!inputDate) {
      return;
    }

    let newDate = moment.utc(inputDate);
    if (newDate.year() > 9999) {
      return;
    }

    this.onChangePropertyValueDebounced(
      entry.id,
      "creationDate",
      newDate.format()
    );
  };

  onChangePropertyValue = (id, propertyName, value) => {
    let { entries } = this.props;
    let entry = entries.find((e) => e.id === id);
    if (!entry) {
      return;
    }

    return DataActions.updateBookkeepingDraftEntry({
      ...entry,
      [propertyName]: value,
    });
  };

  onChangePropertyValueDebounced = (id, propertyName, value) => {
    let { entries } = this.props;
    let entry = entries.find((e) => e.id === id);
    if (!entry) {
      return;
    }

    let newValue = {
      ...entry,
      [propertyName]: value,
    };

    DataActions.updateBookkeepingDraftEntryState(newValue);
    this.debouncedUpdate(newValue);
  };

  debouncedUpdate = debounce((model) => {
    DataActions.updateBookkeepingDraftEntry(model);
  }, 1000);

  colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let { financeAccountMap, selectedPlan } = this.props;

      let accountPlan = selectedPlan;
      let account = financeAccountMap[data.value];
      let isBalance =
        !!account &&
        account.number >= accountPlan.balanceStart &&
        account.number <= accountPlan.balanceEnd;

      let background = styles.backgroundColor;
      if (isBalance && !isDisabled && !isFocused && !isSelected) {
        background = "lightblue";
      }
      return {
        ...styles,
        backgroundColor: background,
      };
    },
  };

  ColumnFilterButtons = ({ propertyName, filterType = FilterTypes.TEXT }) => {
    let {
      columnFilters,
      removeColumnFilter,
      editTextFilter,
      editAccountFilter,
      editNumberFilter,
      editDateFilter,
    } = this.props;

    let func = () => {
      switch (filterType) {
        case FilterTypes.TEXT:
          return editTextFilter();
        case FilterTypes.ACCOUNT:
          return editAccountFilter();
        case FilterTypes.NUMBER:
          return editNumberFilter(propertyName);
        case FilterTypes.DATE:
          return editDateFilter();
        default:
          return;
      }
    };

    return (
      <span onClick={(event) => event.stopPropagation()} role="button">
        <span title={getText("axoEntityidcode117", "Filtrer")} onClick={func}>
          &nbsp;
          <Icon className="editable" glyph="icon-fontello-filter" />
          &nbsp;
        </span>
        &nbsp;
        {!!columnFilters[propertyName] && (
          <span
            title={getText("axoEntityidcode207", "Fjern filter")}
            onClick={() => removeColumnFilter(propertyName)}
          >
            &nbsp;
            <Icon className="axored" glyph="icon-fontello-trash" />
            &nbsp;
          </span>
        )}
      </span>
    );
  };

  render() {
    let { sortedDataList, colSortDirs } = this.state;

    let {
      entries,
      containerHeight,
      containerWidth,
      selectedEntries,
      onSelectEntry,
      onSelectAllEntries,
      showSelectors,
      showAccounts,
      financeAccounts,
      selectedPlan,
      // onUpdateEntry,
      // showDoublesHighlight,
      showColumnSelectors,
      // selectedEntryColumn,
      // onSelectEntryColumn,
      hasFilter,
      creditorEntryIds,
      debitorEntryIds,
      onShowReceipt,
      entryMultiMatches,
      selectedEntryAccountIdMap,
      selectEntryMapAccount,
      // priceFilter,
      // onUpdatePriceFilter,
      // monthFilter,
      // onUpdateMonthFilter,
      onDisplayEntry,
      activeBankAccountId,
      getAmountForEntry,
      locale,
      // clearSelectedEntries
    } = this.props;

    let tableWidth = containerWidth;

    let colNumber = showAccounts ? 6 : 5;
    let selectWidth = showSelectors ? 50 : 0;
    let widthUnit = (tableWidth - selectWidth) / colNumber;

    let selectAccountLabel = getText("axoAccounting6f", "Vælg konto");

    let ColumnFilterButtons = this.ColumnFilterButtons;
    return (
      <Table
        rowHeight={35}
        rowsCount={sortedDataList.getSize()}
        onRowDoubleClick={(event, index) =>
          onDisplayEntry(sortedDataList.getObjectAt(index).id)
        }
        rowClassNameGetter={this._rowClassNameGetter}
        width={tableWidth}
        height={Math.max(containerHeight, 100)}
        headerHeight={40}
      >
        {showSelectors ? (
          <Column
            columnKey="select"
            header={
              <Cell className="text-center">
                <AxoCheckbox
                  checked={
                    selectedEntries.size > 0 &&
                    selectedEntries.size === sortedDataList.getSize()
                  }
                  onChange={() => onSelectAllEntries(sortedDataList)}
                />
              </Cell>
            }
            cell={(props) => {
              var entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} className="text-center" {...props}>
                  <AxoCheckbox
                    checked={selectedEntries.has(entry.id)}
                    onChange={onSelectEntry.bind(this, entry.id)}
                  />
                </Cell>
              );
            }}
            width={selectWidth}
          />
        ) : null}
        <Column
          columnKey="creationDate"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.creationDate}
            >
              {/* <Flexbox>
                  <FlexElement>
                    <AxoLocal entity='TimeEntryFormntimeEntry'defaultValue={'Dato'}/>
                  </FlexElement>
                  { showColumnSelectors ? (
                    <React.Fragment>
                      <FlexElement flexGrow={1} className='text-right'>
                        <span onClick={(event) => { event.stopPropagation() }}>
                          <AxoCheckbox
                            checked={selectedEntryColumn === 'creationDate'}
                            onChange={() => onSelectEntryColumn('creationDate')}
                          />
                        </span>
                      </FlexElement>
                      <FlexElement 
                        flexGrow={1} 
                        className='text-right'
                        style={{ paddingRight: '5px' }}
                        onClick={(event) => { event.stopPropagation() }}>
                        <select
                          value={monthFilter}
                          onChange={event => onUpdateMonthFilter(event.target.value)}
                        >
                          <option value={-1}>...</option>
                          <option value={0}>{getText('getMonthNameJan', 'Jan')}</option>
                          <option value={1}>{getText('getMonthNameFeb', 'Feb')}</option>
                          <option value={2}>{getText('getMonthNameMar', 'Mar')}</option>
                          <option value={3}>{getText('getMonthNameApr', 'Apr')}</option>
                          <option value={4}>{getText('getMonthNameMay', 'Maj')}</option>
                          <option value={5}>{getText('getMonthNameJun', 'Jun')}</option>
                          <option value={6}>{getText('getMonthNameJul', 'Jul')}</option>
                          <option value={7}>{getText('getMonthNameAug', 'Aug')}</option>
                          <option value={8}>{getText('getMonthNameSep', 'Sep')}</option>
                          <option value={9}>{getText('getMonthNameOct', 'Okt')}</option>
                          <option value={10}>{getText('getMonthNameNov', 'Nov')}</option>
                          <option value={11}>{getText('getMonthNameDec', 'Dec')}</option>
                        </select>
                      </FlexElement>
                    </React.Fragment>
                  ) : null }
                </Flexbox> */}
              <Flexbox>
                <FlexElement>
                  <AxoLocal
                    entity="TimeEntryFormntimeEntry"
                    defaultValue={"Dato"}
                  />
                </FlexElement>
                {showColumnSelectors && (
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="creationDate"
                      filterType={FilterTypes.DATE}
                    />
                  </FlexElement>
                )}
              </Flexbox>
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {/* {moment(entry.creationDate).format('L')} */}
                <AxoDateTimeEdit
                  locale={locale}
                  date={moment.utc(entry.creationDate)}
                  onChange={(newMoment) =>
                    this.onChangePropertyValue(
                      entry.id,
                      "creationDate",
                      newMoment.format()
                    )
                  }
                />
                {/* <input type="date" style={{ border: 'none' }} 
                  onChange={event => this.updateDate(entry, event.target.valueAsDate)} 
                  value={moment.utc(entry.creationDate).format('YYYY-MM-DD')}
                /> */}
              </Cell>
            );
          }}
          width={widthUnit}
        />
        <Column
          columnKey="receiptNumber"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.receiptNumber}
            >
              {/* <Flexbox>
                  <FlexElement>
                    <AxoLocal entity='axoidcode64' defaultValue={'Nummer'}/>    
                  </FlexElement>
                  { showColumnSelectors ? (
                    <FlexElement flexGrow={1} className='text-right'>
                      <span onClick={(event) => { event.stopPropagation() }}>
                        <AxoCheckbox
                          checked={selectedEntryColumn === 'receiptNumber'}
                          onChange={() => onSelectEntryColumn('receiptNumber')}
                        />
                      </span>
                    </FlexElement>
                  ) : null }
                </Flexbox> */}
              <Flexbox>
                <FlexElement>
                  <AxoLocal entity="axoidcode64" defaultValue={"Nummer"} />
                </FlexElement>
                {showColumnSelectors && (
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="receiptNumber"
                      filterType={FilterTypes.NUMBER}
                    />
                  </FlexElement>
                )}
              </Flexbox>
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {entry.receiptNumber}
                {!!entry.receipt ? (
                  <span>
                    &nbsp;
                    <Icon
                      className="editable"
                      role="button"
                      onClick={() => onShowReceipt(entry.receipt)}
                      glyph="icon-fontello-attach-7"
                    />
                    &nbsp;
                  </span>
                ) : null}
              </Cell>
            );
          }}
          width={widthUnit}
        />
        <Column
          columnKey="description"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.description}
            >
              <Flexbox>
                <FlexElement>
                  <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />
                </FlexElement>
                {showColumnSelectors && (
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="description"
                      filterType={FilterTypes.TEXT}
                    />
                  </FlexElement>
                )}
              </Flexbox>
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {!!creditorEntryIds[entry.id] ? (
                  <span>
                    {" "}
                    <AxoLocal
                      entity="axoidcode172"
                      defaultValue={"(Kreditor)"}
                    />{" "}
                    &nbsp;
                  </span>
                ) : null}
                {!!debitorEntryIds[entry.id] ? (
                  <span>
                    <AxoLocal
                      entity="axoidcode173"
                      defaultValue={"(Debitor)"}
                    />{" "}
                    &nbsp;
                  </span>
                ) : null}
                {!!entryMultiMatches[entry.id] ? (
                  <span>
                    (<AxoLocal entity="axoidcode174" defaultValue={"Match"} />#
                    {entryMultiMatches[entry.id]})&nbsp;
                  </span>
                ) : null}
                {!!entry.invoiceNumber && <> (#{entry.invoiceNumber})</>}
                <InlineEdit
                  value={entry.description || "---"}
                  change={(input) =>
                    this.onChangePropertyValue(
                      entry.id,
                      "description",
                      input.value
                    )
                  }
                />
              </Cell>
            );
          }}
          width={widthUnit * 2}
        />
        {showAccounts ? (
          <Column
            columnKey="account"
            header={
              <Cell>
                <Flexbox>
                  <FlexElement>
                    <AxoLocal
                      entity="updategetAccountName1"
                      defaultValue={"Modkonto"}
                    />
                  </FlexElement>
                  {showColumnSelectors && (
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons
                        propertyName="balanceFinanceAccountId"
                        filterType={FilterTypes.ACCOUNT}
                      />
                    </FlexElement>
                  )}
                </Flexbox>
              </Cell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);

              let accountOptions = financeAccounts
                .filter((a) => a.type === "Standard")
                .filter((a) => a.id !== activeBankAccountId)
                //Only show balance accounts
                .filter(
                  (acc) =>
                    acc.number >= selectedPlan.balanceStart &&
                    acc.number <= selectedPlan.balanceEnd
                );

              let defaultOption = { value: 0, label: selectAccountLabel };
              let selectOptions = [defaultOption].concat(
                accountOptions.map((f) => {
                  return {
                    value: f.id,
                    label: f.number.toString() + " - " + f.name,
                  };
                })
              );

              let selectedOption = defaultOption;
              let mapSelection = selectedEntryAccountIdMap[entry.id];
              if (!!mapSelection) {
                selectedOption = mapSelection;
              } else if (!!entry.balanceFinanceAccountId) {
                selectedOption =
                  selectOptions.find(
                    (o) => o.value === entry.balanceFinanceAccountId
                  ) || defaultOption;
              }

              return (
                <Cell key={entry.id} {...props}>
                  <ReactSelect
                    name="select"
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    closeMenuOnScroll
                    styles={this.colourStyles}
                    // menuPlacement='top'
                    className={
                      !entry.balanceFinanceAccountId ? "selectBorder" : ""
                    }
                    value={selectedOption}
                    onChange={(selectedAccount) => {
                      if (!!selectedAccount) {
                        if (
                          selectedEntries.size > 0 &&
                          selectedEntries.has(entry.id)
                        ) {
                          selectedEntries.forEach((id) => {
                            selectEntryMapAccount(id, selectedAccount);
                          });
                        } else if (hasFilter) {
                          entries.forEach((e) => {
                            selectEntryMapAccount(e.id, selectedAccount);
                          });
                        } else {
                          selectEntryMapAccount(entry.id, selectedAccount);
                        }
                      }
                    }}
                    noOptionsMessage={() => ""}
                    options={[
                      { value: "", label: selectAccountLabel },
                      ...selectOptions,
                    ]}
                    filterOption={this.filterValues}
                  />
                </Cell>
              );
            }}
            width={widthUnit}
          />
        ) : null}
        <Column
          columnKey="amount"
          header={
            <SortHeaderCell
              onSortChange={(columnKey, sortDir) =>
                this._onSortChange(
                  columnKey,
                  sortDir,
                  (l, r) => getAmountForEntry(l) - getAmountForEntry(r)
                  // (l.isIncome ? l.amount : -l.amount) - (r.isIncome? r.amount : -r.amount)
                )
              }
              sortDir={colSortDirs.amount}
            >
              {/* <Flexbox>
                  <FlexElement>
                    <AxoLocal entity='InvoiceInvoicesum' defaultValue='Beløb'/>   
                  </FlexElement>
                  { showColumnSelectors ? (
                    <React.Fragment>
                      <FlexElement flexGrow={1} className='text-right'>
                        <span onClick={(event) => { event.stopPropagation() }}>
                          <AxoCheckbox
                            checked={selectedEntryColumn === 'amount'}
                            onChange={() => onSelectEntryColumn('amount')}
                          />
                        </span>
                      </FlexElement>
                      <FlexElement 
                        flexGrow={1} 
                        className='text-right'
                        style={{ paddingRight: '5px' }}
                        onClick={(event) => { event.stopPropagation() }}>
                        <select
                          value={priceFilter}
                          onChange={event => onUpdatePriceFilter(event.target.value)}
                        >
                          <option value={0}>...</option>
                          <option value={1}>+</option>
                          <option value={2}>-</option>
                        </select>
                      </FlexElement>
                    </React.Fragment>
                  ) : null }
                </Flexbox> */}
              <Flexbox>
                <FlexElement>
                  <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />
                </FlexElement>
                {showColumnSelectors && (
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="amount"
                      filterType={FilterTypes.NUMBER}
                    />
                  </FlexElement>
                )}
              </Flexbox>
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            let amount = entry.isIncome ? entry.amount : -entry.amount;
            return (
              <Cell
                key={entry.id}
                {...props}
                className="text-right rightPadding"
              >
                {entry.status === "Posted" ? (
                  <div>{this.formatAmount(entry)}</div>
                ) : (
                  <InlineNumberEdit
                    // decimal
                    value={amount || 0}
                    format={() => (
                      <span>{NumberService.formatDecimal(amount)}</span>
                    )}
                    change={(input) =>
                      this.updateAmountAndVat(entry.id, parseFloat(input.value))
                    }
                  />
                )}
              </Cell>
            );
          }}
          width={widthUnit}
        />
      </Table>
    );
  }

  formatAmount(entry) {
    let { creditorEntryIds, debitorEntryIds } = this.props;

    let amount = entry.amount;

    if (!entry.isIncome) {
      //Expense (debit) postings are withdrawn from the bank account
      amount = -amount;
    }

    if (entry.status === "Posted") {
      //If the creditor/debitor account is the main account, the amount should be inverted.
      //Credit postings will withdraw from the main account.
      let creditorDebitorData =
        creditorEntryIds[entry.id] || debitorEntryIds[entry.id] || {};
      if (!creditorDebitorData.isBalanceAccountEntry) {
        amount = -amount;
      }
    }

    if (!amount) {
      return "0.00";
    }
    return NumberService.formatDecimal(amount);
  }
}

export default Dimensions({
  elementResize: true,
})(BalancingEntryTable);
