import React from "react";

import { Picky } from "react-picky";
import "react-picky/dist/picky.css";

import ReactSelect from "react-select";

import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  FormGroup,
  ControlLabel,
  // InputGroup,
  // FormControl
} from "react-bootstrap";

import {
  Icon,
  DateFilter,
  DateFilterTypes,
  AxoDateTime,
  getText,
  AxoLocal,
} from "../../utilities/LexUtilities";

export default class DateFilterModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      filter: new DateFilter(),
      selectedOptionsArray: [],
      callback: (filter) => {},
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = (filter, callback) => {
    let selectedOptionsSet = new Set(filter.selectedOptions);

    this.setState({
      showModal: true,
      filter,
      selectedOptionsArray: filter.options.filter((o) =>
        selectedOptionsSet.has(o.id)
      ),
      callback,
    });
  };

  onOK = () => {
    let { filter, selectedOptionsArray } = this.state;

    let newFilter = new DateFilter(filter);
    newFilter.selectedOptions = selectedOptionsArray.map((o) => o.id);

    this.setState({ showModal: false }, () => {
      this.state.callback(newFilter);
    });
  };

  onCancel = () => {
    this.setState({ showModal: false });
  };

  selectMultipleOption = (value) => {
    this.setState({ selectedOptionsArray: value });
  };

  updateFilterProperty = (propertyName, value) => {
    let newFilter = new DateFilter(this.state.filter);
    newFilter[propertyName] = value;

    this.setState({
      filter: newFilter,
    });
  };

  render() {
    let { filter, selectedOptionsArray } = this.state;

    let filterTypeOptions = [
      {
        value: DateFilterTypes.NONE,
        label: getText("axoEntityidcode149", "Intet filter"),
      },
      {
        value: DateFilterTypes.EQUALS,
        label: getText("axoEntityidcode150", "Lig med"),
      },
      { value: DateFilterTypes.BEFORE, label: getText("axoidcode133", "Før") },
      {
        value: DateFilterTypes.BEFOREORSAME,
        label: getText("axoEntityidcode164", "Før eller samtidig"),
      },
      { value: DateFilterTypes.AFTER, label: getText("axoidcode141", "Efter") },
      {
        value: DateFilterTypes.AFTERORSAME,
        label: getText("axoEntityidcode165", "Efter eller samtidig"),
      },
      // { value: DateFilterTypes.BETWEEN, label: 'Mellem'},
    ];

    let selectedOption = filterTypeOptions.find(
      (f) => f.value === filter.filterDateType
    );

    let monthOptions = [
      // { value: -1, label: 'Alle måneder'},
      { value: 0, label: getText("AccountingTabViewEntity45", "Januar") },
      { value: 1, label: getText("AccountingTabViewEntity46", "Februar") },
      { value: 2, label: getText("AccountingTabViewEntity47", "Marts") },
      { value: 3, label: getText("AccountingTabViewEntity48", "April") },
      { value: 4, label: getText("AccountingTabViewEntity49", "Maj") },
      { value: 5, label: getText("AccountingTabViewEntity50", "Juni") },
      { value: 6, label: getText("AccountingTabViewEntity51", "Juli") },
      { value: 7, label: getText("AccountingTabViewEntity52", "August") },
      { value: 8, label: getText("AccountingTabViewEntity53", "September") },
      { value: 9, label: getText("AccountingTabViewEntity54", "Oktober") },
      { value: 10, label: getText("AccountingTabViewEntity55", "November") },
      { value: 11, label: getText("AccountingTabViewEntity56", "December") },
      // { value: DateFilterTypes.BETWEEN, label: 'Mellem'},
    ];

    let filterMonthMap = new Set(filter.filterMonthArray);
    let selectedMonthArray = monthOptions.filter((m) =>
      filterMonthMap.has(m.value)
    );
    // let selectedMonth = monthOptions.find(f => f.value === filter.filterMonth);

    return (
      <Modal show={this.state.showModal} onHide={this.close}>
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
          closeButton
        >
          <Modal.Title>
            <AxoLocal entity="axoEntityidcode117" defaultValue={"Filtrer"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          {/* <h4>Datofilter</h4> */}
          <FormGroup
            controlId="filterType"
            bsSize="large"
            style={{ position: "relative", zIndex: 101 }} //Must be higher than the dropdown below
          >
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="axoEntityidcode118"
                defaultValue={"Datofilter"}
              />
            </ControlLabel>
            <ReactSelect
              name="filterTypeSelect"
              menuPlacement="auto"
              value={selectedOption}
              onChange={(selection) => {
                if (!selection) {
                  return;
                }

                this.updateFilterProperty("filterDateType", selection.value);
              }}
              noOptionsMessage={() => ""}
              options={filterTypeOptions}
            />
          </FormGroup>
          <FormGroup controlId="filterNumber" bsSize="large">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="TimeEntryFormntimeEntry"
                defaultValue={"Dato"}
              />
            </ControlLabel>
            <AxoDateTime
              utc
              inputProps={{
                disabled: selectedOption.value === DateFilterTypes.NONE,
              }}
              value={filter.filterDate}
              dateFormat={true}
              timeFormat={false}
              onChange={(date) =>
                this.updateFilterProperty("filterDate", date.startOf("day"))
              }
            />
          </FormGroup>
          <FormGroup className="onToppest">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="axoEntityidcode116" defaultValue={"Måneder"} />
            </ControlLabel>
            <ReactSelect
              name="monthSelect"
              menuPlacement="auto"
              value={selectedMonthArray}
              placeholder={getText("axoEntityidcode148", "Vælg måneder...")}
              isMulti
              onChange={(selections) => {
                if (!selections) {
                  return;
                }

                // this.updateFilterProperty('filterMonth', selection.value);
                this.updateFilterProperty(
                  "filterMonthArray",
                  selections.map((s) => s.value)
                );
              }}
              noOptionsMessage={() => ""}
              options={monthOptions}
            />
          </FormGroup>
          <div style={{ height: "275px" }}>
            <Picky
              value={selectedOptionsArray}
              options={filter.options}
              onChange={this.selectMultipleOption}
              open={true}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={250}
              selectAllMode="filtered"
              filterPlaceholder="Søg..."
              placeholder="Ingen valgt"
              selectAllText="Vælg alle"
              allSelectedPlaceholder="%s valgt"
              manySelectedPlaceholder="%s valgt"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.onOK}>
                <Icon glyph="icon-fontello-ok-3" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="friendRequestTimelineonApproveRequest"
                  defaultValue={"Godkend"}
                />
              </Button>
              <Button className="Lex-button-2" onClick={this.onCancel}>
                <Icon glyph="icon-fontello-cancel" />
                &nbsp;&nbsp;
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
