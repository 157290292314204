import React from "react";
import DocumentSearchBox from "./DocumentSearchBox";

export default class ImageSearchBox extends React.PureComponent {
  //Props
  //documents
  //handleSelectedImage
  //placeholder

  render() {
    return <DocumentSearchBox {...this.props} onlyImages />;
  }
}
