import React from "react";
import { Button, ButtonGroup, ButtonToolbar, Modal } from "react-bootstrap";
import {
  Icon,
  AsyncButton,
  // AxoLocal
} from "../../utilities/LexUtilities";

import { Picky } from "react-picky";

export default class ContactSelectModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedOptionsArray: [],
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  onOK = async () => {
    let { callback } = this.props;
    let { selectedOptionsArray } = this.state;

    if (!callback || selectedOptionsArray.length === 0) {
      return false;
    }

    let success = await callback(selectedOptionsArray.map((o) => o.id));

    if (!success) {
      return false;
    }

    this.setState({
      selectedOptionsArray: [],
      showModal: false,
    });

    return true;
  };

  onCancel = () => {
    this.setState({
      selectedOptionsArray: [],
      showModal: false,
    });
  };

  selectMultipleOption = (value) => {
    this.setState({ selectedOptionsArray: value });
  };

  render() {
    let { options, confirmLabel, callback } = this.props;
    let { selectedOptionsArray } = this.state;

    return (
      <Modal
        className="center-dialog"
        show={this.state.showModal}
        onHide={this.close}
      >
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
          closeButton
        >
          <Modal.Title>Vælg medlemmer</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          <div style={{ height: "650px" }}>
            <Picky
              value={selectedOptionsArray}
              options={options}
              onChange={this.selectMultipleOption}
              open={true}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={600}
              selectAllMode="filtered"
              filterPlaceholder="Søg..."
              placeholder="Ingen valgt"
              selectAllText="Vælg alle"
              allSelectedPlaceholder="%s valgt"
              manySelectedPlaceholder="%s valgt"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.onCancel}>
                <span>
                  {" "}
                  <Icon glyph="icon-fontello-cancel" />
                  &nbsp;&nbsp; Fortryd
                </span>
              </Button>
              <AsyncButton
                disabled={!callback || selectedOptionsArray.length === 0}
                onClick={this.onOK}
              >
                {" "}
                <span>
                  {" "}
                  <Icon glyph="icon-fontello-ok-3" />
                  &nbsp;&nbsp;
                  {confirmLabel || "Vælg"}
                </span>
              </AsyncButton>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
