import React from "react";
import FlagMenu from "../utilities/FlagMenu";
import { SmallOrSmaller, MediumOrLarger } from "../utilities/Responsive";
import { Row, Col, Grid, MenuItem, DropdownButton } from "react-bootstrap";

import { Icon, AxoLocal, Link, withRouter } from "../utilities/LexUtilities";

const CompanyHeader = (props) => {
  var { onRoute } = props;
  return (
    <div>
      <MediumOrLarger>
        <div
          className="testbackground"
          style={{ position: "relative", zIndex: 100 }}
        >
          <div className="testtransbox text-center">
            <div
              className="flexbox-layout TopMenuLinknav "
              style={{
                maxWidth: "1200px",
                margin: "auto",
                border: "0px",
                paddingLeft: "1%",
                paddingRight: "1%",
              }}
            >
              <div>
                {" "}
                <Link to="/freeware">
                  <span>
                    <img
                      alt=""
                      className="hidden-lg hidden-sm hidden-xs"
                      src="/imgs/app/AxologoA-70x70.png"
                    />
                    <img
                      alt=""
                      className="hidden-md hidden-sm hidden-xs"
                      src="/imgs/app/AxologoA-80x80.png"
                    />
                  </span>{" "}
                </Link>
              </div>
              <div className="axolexwebsideicon1">
                <span className="axolexwebsideicon">
                  <Link to="/freeware/xlink">
                    <Icon
                      glyph="icon-fontello-link-outline"
                      className="frontnavbarimg"
                    />{" "}
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="axolexQRcode14"
                      defaultValue={"X-Link presentationer"}
                    />
                  </Link>{" "}
                </span>
              </div>
              <div className="axolexwebsideicon">
                <span className="axolexwebsideicon1">
                  <Link to="/freeware/xlink">
                    <Icon
                      role="button"
                      glyph="icon-fontello-plus-3"
                      className="Addicon"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="presentationMarketing32"
                      defaultValue="Opret konto"
                    />
                  </Link>
                </span>
              </div>
              <div>
                <FlagMenu
                  className="Lex-button-2 AxoNavigationButtonheight"
                  style={{ bottom: 0, left: 0, right: 0, zIndex: 1000 }}
                  userSettings={{
                    locale: props.locale,
                  }}
                />
              </div>
              <div className="axolexwebsideicon">
                <Link to="/xlink/account/Login" style={{ color: "#202020" }}>
                  <span>
                    <Icon
                      className="frontnavbarimg"
                      bundle="fontello"
                      glyph="off-1"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      className="hidden-sm hidden-xs"
                      key="login"
                      entity="LoginonLogin"
                      defaultValue={"Log ind"}
                    />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </MediumOrLarger>
      <SmallOrSmaller>
        <div
          style={{
            backgroundImage: "linear-gradient(#fff, #DCDCDC)",
            marginBottom: "5px",
          }}
        >
          <div className="flexbox-standard">
            <div style={{ flexGrow: 0 }}>
              <Link
                to="/freeware"
                className="axolexwebsideicon"
                style={{ paddingLeft: "30px", paddingRight: "30px" }}
              >
                <span>
                  <img
                    alt=""
                    className="hidden-lg hidden-sm hidden-md"
                    src="/imgs/app/AxologoA-50x50.png"
                  />
                  <img
                    alt=""
                    className="hidden-lg hidden-md hidden-xs"
                    src="/imgs/app/AxologoA-50x50.png"
                  />
                </span>{" "}
              </Link>
            </div>
            <div style={{ height: "60px" }}>
              <FlagMenu
                className="Lex-button-2 AxoNavigationButtonheightX"
                style={{
                  borderRadius: "0px",
                  height: 60,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                }}
                userSettings={{
                  locale: props.locale,
                }}
              />
            </div>
            <div
              style={{ flexGrow: 1 }}
              className="flexbox-justified freeware-dropdown"
            >
              <DropdownButton
                noCaret
                style={{ width: "100%", height: "60px", borderRadius: "0px" }}
                id="menu"
                title={
                  <span
                    className="flexbox-center"
                    style={{
                      fontSize: "15px",
                      textAligne: "right",
                      lineHeight: "30px",
                    }}
                  >
                    <AxoLocal entity="" defaultValue={"Menu"} />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ paddingTop: "9px" }}>
                      <Icon
                        glyph="icon-fontello-align-justify"
                        className="frontnavbarimg"
                        style={{ textAligne: "right" }}
                      />
                    </span>
                    &nbsp;
                  </span>
                }
                className="Lex-button-2"
              >
                <MenuItem
                  onClick={() => {
                    onRoute("/freeware/xlink");
                  }}
                  className="Lex-DropdownButton-menuX flexbox-center-vertical"
                >
                  <div style={{ fontSize: "15px" }}>
                    <Icon
                      glyph="icon-fontello-link-outline"
                      className="frontnavbarimg"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="axolexQRcode14"
                      defaultValue={"X-Link presentationer"}
                    />
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onRoute("/freeware/xlink");
                  }}
                  className="Lex-DropdownButton-menuX flexbox-center-vertical"
                >
                  <div style={{ fontSize: "15px" }}>
                    <span>
                      <Icon
                        role="button"
                        glyph="icon-fontello-plus-3"
                        className="Addicon"
                      />
                      <AxoLocal
                        entity="presentationMarketing32"
                        defaultValue="Opret konto"
                      />
                    </span>
                  </div>
                </MenuItem>
                <MenuItem
                  style={{ width: "100%" }}
                  onClick={() => {
                    onRoute("/xlink/account/Login");
                  }}
                  className="Lex-DropdownButton-menuX flexbox-center-vertical"
                >
                  <div style={{ fontSize: "15px" }}>
                    <span>
                      <Icon
                        className="frontnavbarimg"
                        bundle="fontello"
                        glyph="off-1"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        key="login"
                        entity="LoginonLogin"
                        defaultValue={"Log ind"}
                      />
                    </span>
                  </div>
                </MenuItem>
              </DropdownButton>
            </div>
          </div>
        </div>
      </SmallOrSmaller>
    </div>
  );
};

class FreewareLayoutView extends React.PureComponent {
  onRoute = (route) => {
    this.props.history.push(route);
  };
  render() {
    return (
      <Grid fluid style={{ paddingTop: "0px" }}>
        <Row>
          <Col xs={12} className="nopadding" style={{ paddingTop: "0px" }}>
            <CompanyHeader
              header={this.props.header}
              locale={this.props.locale}
              onRoute={this.onRoute}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="nopadding"
            style={{ padding: "0px", minHeight: "500px" }}
          >
            <div className="bodyxlink text-center">{this.props.children}</div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="nopadding">
            <div
              className="testbackground"
              style={{ position: "relative", zIndex: 100 }}
            >
              <div
                className="testtransbox text-center"
                style={{ bottom: 0, left: 0, right: 0, zIndex: 1000 }}
              >
                <img
                  alt=""
                  height="120px"
                  width="120px"
                  src="/svg/Axolexfree.png"
                />{" "}
                &nbsp; &#169; &nbsp;&nbsp;
                <span className="Axooverskrifttext">
                  <Link to="/freeware/xlink" style={{ color: "#202020" }}>
                    <span>
                      {" "}
                      &#9755;
                      <AxoLocal
                        entity="presentationMarketing29"
                        defaultValue="Lav dine egne præsentationer"
                      />
                      &nbsp;&nbsp;
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-md"
                        src="/imgs/app/Axologo3-30x30.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-md hidden-xs"
                        src="/imgs/app/Axologo3-35x35.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-xs"
                        src="/imgs/app/Axologo3-50x50.png"
                      />
                      <img
                        alt=""
                        className="hidden-md hidden-sm hidden-xs"
                        src="/imgs/app/Axologo3-60x60.png"
                      />
                    </span>{" "}
                    &nbsp; &#9754;
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(FreewareLayoutView);
