import React from "react";
import DashboardLayout from "./DashboardLayout";
import RoutingService from "../services/RoutingService";
import ReminderService from "../services/ReminderService";
import ThemeService from "../services/ThemeService";
import LocalizationService from "../services/LocalizationService";
import moment from "moment";
import "moment/locale/da";
import { withRouter } from "react-router-dom";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import { ctx } from "../utilities/L20n/L20n";
import ApiService from "../services/DataAccess/ApiService";
import DataStore from "../services/DataAccess/DataStore";
import { Route, Switch, Link } from "react-router-dom";
import { toast } from "react-toastify";

import HomeContainer from "../routes/HomeContainer";

import InboxContainer from "../routes/Email/InboxContainer";
import MailContainer from "../routes/Email/MailContainer";
import ComposeContainer from "../routes/Email/ComposeContainer";
import MailConfigurationContainer from "../routes/Email/MailConfigurationContainer";
import ExternalMailSetupContainer from "../routes/Email/ExternalMailSetupContainer";
import ContactContainer from "../routes/ContactContainer";
import DocumentTableContainer from "../routes/DocumentTableContainer";
import LabelContainer from "../routes/LabelContainer";
import CaseContainer from "../routes/CaseContainer";

import CalendarContainer from "../routes/CalendarContainer";
import TimeEntriesContainer from "../routes/TimeEntriesContainer";
import TrashContainer from "../routes/TrashContainer";
import SocialContainer from "../routes/SocialContainer";
import SubscribeContainer from "../Login/SubscribeContainer";
import StripeAccountValidator from "../routes/StripeAccountValidator";
import AliPayReceiver from "../routes/AliPayReceiver";
import AccountPlansConsumer from "../routes/AccountPlans/AccountPlansConsumer";
import AccountingLayoutContainer from "../routes/Accounting/AccountingLayoutContainer";
import MyAccountingContainer from "../routes/MyAccounting/MyAccountingContainer";
import AdminContainer from "../routes/Admin/AdminContainer";
import AccountingContainer from "../routes/AccountingContainer";
import SetupContainer from "../routes/Setup/SetupContainer";
import CollectionStatusContainer from "../routes/CollectionStatus/CollectionStatusContainer";
import InvoicesContainer from "../routes/Invoices/InvoicesContainer";

import ApplicationUDContainer from "../routes/ApplicationUD/ApplicationUDContainer";
import BusinessOperatingContainer from "../BusinessOperating/BusinessOperatingContainer";
import PowerOfAttorneyContainer from "../PowerOfAttorney/PowerOfAttorneyContainer";
import EmploymentContractContainer from "../EmploymentContract/EmploymentContractContainer";
import SigningContainer from "../routes/SigningContainer";

import ModelTemplateContainer from "../Templates/ModelTemplateContainer";

import TestPage from "../routes/Test/TestPage";

import {
  SocialPostStore,
  DataActions,
  AuthorizationService,
} from "../services/AxoServices";

import {
  Dispatcher,
  withRTKMessageData,
  withRTKCaseData,
} from "../utilities/LexUtilities";

class DashboardLayoutContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarSelection: 0,
      casesWithDeadlines: {},
      sharedCasesWithDeadlines: [],
      documentMap: {},
      userSettings: {},
      userProfile: {},
      loaded: false,
      globalSettings: {},
      isEditorFullScreen: false,
      userName: "",
      notifications: [],
      deadlineNotifications: [],
      socialNotifications: [],
      bookings: [],
      friendRequests: [],
      friends: [],
      publicPosts: [],
      internalPosts: [],
      selectedUserProfile: {},
      selectedCase: null,
      activeRoute: props.location.pathname,
      theme: "",
      openDropdown: false,
      hideSidebar: false,
      trashCount: 0,
    };
    this.fullScreenSubscription = "";
    this.name = "DashboardLayoutContainer";
    RoutingService.setRootPath("/Dashboard");
  }

  getNotificationBody = (note) => {
    if (note.entity) {
      if (note.entity === "DocumentaddCaseReminderRememberdeadlineforcase") {
        return (
          ctx.getSync("DocumentaddCaseReminderRememberdeadlineforcase") +
          " " +
          note.variable +
          " " +
          moment(note.messageDate).format("L")
        );
      }
      if (note.entity === "DocumentaddCaseRememberdeadlinefordocument") {
        return (
          ctx.getSync("DocumentaddCaseRememberdeadlinefordocument") +
          " " +
          note.variable +
          " " +
          moment(note.messageDate).format("L")
        );
      }
      if (note.entity === "DocumentaddCaseInvoicewithinvoicenumber") {
        return (
          ctx.getSync("DocumentaddCaseInvoicewithinvoicenumber") +
          " " +
          note.variable +
          " " +
          ctx.getSync("DocumentaddMustbepaidatthelatest") +
          " " +
          moment(note.messageDate).format("L")
        );
      }
    }
    return note.text;
  };

  componentDidMount() {
    this.fullScreenSubscription = Dispatcher.subscribe(
      "editorFullScreen",
      (isEditorFullScreen) => {
        this.setState({ isEditorFullScreen });
      }
    );

    ApiService.checkLoginStatus().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.props.history.push("/Users/Login");
      } else {
        this.InitializeData();
      }
    });
    this.loginTimer = setInterval(() => {
      ApiService.checkLoginStatus().then((isLoggedIn) => {
        if (!isLoggedIn) {
          this.props.history.push("/Users/Login");
        }
      });
    }, 60000);
  }

  InitializeData = () => {
    ReminderService.initializeTimer((notifications) => {
      notifications.forEach((note) => {
        toast(this.getNotificationBody(note), {
          position: "top-left",
          autoClose: 300000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    });

    DataStore.initializeSignalRHub((message) => {
      toast(
        <div>
          <Link to="/Dashboard/Documents">{message}</Link>
        </div>,
        {
          position: "top-left",
          autoClose: 300000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    });
    DataStore.onLoad((loaded) => {
      this.setState({ loaded: loaded });
    });
    SocialPostStore.subscribe(this.name, (store) => {
      this.setState({
        publicPosts: store.publicPosts,
        internalPosts: store.internalPosts,
      });
    });
    DataStore.subscribe(this.name, (store) => {
      let userType = AuthorizationService.getUserType(store.userProfile);
      ThemeService.updateThemeStyle(
        store.theme,
        store.globalSettings,
        userType
      );

      var sortedNotifications = store.notifications
        .sort((e1, e2) => {
          return new Date(e1.creationTime) - new Date(e2.creationTime);
        })
        .reverse();

      if (
        store.userProfile.id &&
        (!store.userProfile.paidUntil ||
          new Date(store.userProfile.paidUntil) < new Date()) &&
        userType !== "Admin"
      ) {
        this.unsubscribe();
        if (store.globalSettings.theme) {
          ThemeService.setThemeStyle(store.globalSettings.theme);
        }
        this.props.history.push("/Users/Subscribe");
        return;
      }

      if (store.userSettings.id && store.userSettings.locked) {
        this.unsubscribe();
        this.props.history.push("/Lock");
        return;
      }

      ReminderService.updateDocumentDeadlines(
        store.documentMap.documents.filter(
          (d) => new Date(d.deadline).getFullYear() > 1970
        )
      );
      ReminderService.updateInvoicesWithReminders(
        store.invoices.filter((i) => i.sendReminder && !i.reminderSent)
      );
      ReminderService.updateEvents(store.calendarEvents);

      let eventsWithBookings = store.calendarEvents.filter((event) => {
        return event.eventBooking && !event.eventBooking.confirmedBooking;
      });
      let sortedEvents = eventsWithBookings
        .sort((e1, e2) => {
          return new Date(e1.start) - new Date(e2.start);
        })
        .reverse();

      let sortedRequests = store.friendRequests
        .sort((f1, f2) => {
          return new Date(f1.requestTime) - new Date(f2.requestTime);
        })
        .reverse();

      let sortedFriends = store.friends.sort((f1, f2) => {
        let firstName = f1.firstName || "";
        return firstName.localeCompare(f2.firstName || "");
      });

      let { messageCounter } = this.props;
      window.document.title =
        "Axolex" +
        (messageCounter.unreadCount > 0
          ? " (" + messageCounter.unreadCount.toString() + ")"
          : "");

      let selectedCase =
        store.caseMap.cases.find((c) => c.id === store.selectedCaseId) ||
        store.sharedCases.find((c) => c.id === store.selectedCaseId);

      let selectedUserProfile =
        store.userProfileArray.find(
          (c) => c.id === store.selectedUserProfileId
        ) || {};

      this.setState(
        {
          globalSettings: store.globalSettings,
          notifications: sortedNotifications,
          deadlineNotifications: sortedNotifications.filter(
            (n) => n.type === 0
          ),
          socialNotifications: sortedNotifications.filter((n) => n.type === 1),
          userProfile: store.userProfile,
          userSettings: store.userSettings,
          userGroup: store.userGroup,
          selectedCase: selectedCase,
          casesWithDeadlines: store.caseMap.saved.filter(
            (c) => new Date(c.actionDate).getFullYear() > 1970
          ),
          sharedCasesWithDeadlines: store.sharedCases.filter(
            (c) => new Date(c.actionDate).getFullYear() > 1970
          ),
          documentMap: store.documentMap,
          bookings: sortedEvents,
          friendRequests: sortedRequests,
          friends: sortedFriends,
          selectedUserProfile,
          theme: store.theme,
          hideSidebar: store.hideSidebar,
          trashCount: store.trashCount,
        },
        () => {
          this.updateCaseDeadlines();
        }
      );
    });

    DataStore.fetchEmailAccounts().then((accounts) => {
      DataStore.fetchEmailsFromAllAccounts();
    });

    DataStore.initializeNotification();
    DataStore.initializeCalendarEvents();
    DataStore.initializeFriendRequests();
    DataStore.initializeFriends();
    DataStore.fetchGlobalSettings();
    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.initializeUserGroup();
    DataStore.initializeCases();
    DataStore.initializeSharedCases();
    DataStore.initializeDocuments();
    DataStore.fetchTrashCount();
    // DataStore.fetchInvoices();
  };

  componentDidUpdate(prevProps, prevState) {
    let { userSettings } = this.state;
    if (
      !!userSettings.locale &&
      userSettings.locale !== prevState.userSettings.locale
    ) {
      //In case the context isn't ready, the language will be loaded later
      try {
        LocalizationService.changeLocale(userSettings.locale);
      } catch (error) {
        console.log(error);
      }
    }
  }

  updateCaseDeadlines = () => {
    let { casesWithDeadlines, sharedCasesWithDeadlines } = this.state;
    let allCasesWithDeadlines = casesWithDeadlines.concat(
      sharedCasesWithDeadlines
    );
    ReminderService.updateCaseDeadlines(allCasesWithDeadlines);
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  unsubscribe = () => {
    ReminderService.stopTimer();
    DataStore.unsubscribe(this.name);

    clearInterval(this.loginTimer);
    Dispatcher.unsubscribe(this.fullScreenSubscription);
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({ activeRoute: nextProps.location.pathname });
    }
  }

  onRoute = (route) => {
    this.setState(
      {
        activeRoute: route,
        sidebarSelection: 0,
        openDropdown: false,
      },
      () => {
        //Allow the page time to update the active button,
        //so the button color changes smoothly
        setTimeout(() => {
          this.props.history.push(route);
        }, 50);
      }
    );
  };

  onToggleDropdown = (open) => {
    this.setState({ openDropdown: open });
  };

  onCloseDropdown = () => {
    this.setState({ openDropdown: false });
  };

  onSidebarSelect = (sidebarSelection) => {
    this.setState({ sidebarSelection });
  };

  getConvoKey = (convoId) => {
    return "Private/" + convoId.toString();
  };

  addPrivateConversation = async (privateConvo) => {
    try {
      let response = await DataActions.createPrivateConversation(privateConvo);
      if (response.ok) {
        let newConvo = await response.json();
        this.props.history.push(
          RoutingService.getPath("Social/Private/" + newConvo.id)
        );
        return;
      }
      throw new Error("Conversation could not be created.");
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    let { match } = this.props;

    return (
      <DashboardLayout
        {...this.props}
        {...this.state}
        addPrivateConversation={this.addPrivateConversation}
        onSidebarSelect={this.onSidebarSelect}
        onRoute={this.onRoute}
        onToggleDropdown={this.onToggleDropdown}
        onCloseDropdown={this.onCloseDropdown}
      >
        <Route
          render={({ location }) => {
            return (
              <Switch location={location}>
                <Route exact path={match.path} component={InboxContainer} />
                <Route path={match.path + "/Home"} component={HomeContainer} />
                <Route
                  path={match.path + "/Subscribe"}
                  component={SubscribeContainer}
                />
                <Route
                  path={match.path + "/mailbox/inbox"}
                  component={InboxContainer}
                />
                <Route
                  path={match.path + "/mailbox/mail/:shown/:id"}
                  component={MailContainer}
                />
                <Route
                  path={match.path + "/mailbox/compose"}
                  component={ComposeContainer}
                />
                <Route
                  path={match.path + "/mailbox/composeDraft/:draftId"}
                  component={ComposeContainer}
                />
                <Route
                  path={match.path + "/mailbox/sendExternal/:email"}
                  component={ComposeContainer}
                />
                <Route
                  path={match.path + "/mailbox/internalSetup"}
                  component={MailConfigurationContainer}
                />
                <Route
                  path={match.path + "/mailbox/setup/edit/:id"}
                  component={ExternalMailSetupContainer}
                />
                <Route
                  path={match.path + "/mailbox/setup"}
                  component={ExternalMailSetupContainer}
                />
                <Route
                  exact
                  path={match.path + "/Social"}
                  component={SocialContainer}
                />
                <Route
                  path={match.path + "/Social/Public"}
                  component={SocialContainer}
                />
                <Route
                  path={match.path + "/Social/Internal"}
                  component={SocialContainer}
                />
                <Route
                  path={match.path + "/Social/Files"}
                  component={SocialContainer}
                />
                <Route
                  path={match.path + "/Social/MyProfile"}
                  component={SocialContainer}
                />
                <Route
                  path={match.path + "/Social/Profile"}
                  component={SocialContainer}
                />
                <Route
                  path={match.path + "/Social/Private/:id"}
                  component={SocialContainer}
                />
                <Route
                  path={match.path + "/Cases/Single/:id"}
                  component={CaseContainer}
                />
                <Route
                  path={match.path + "/Cases/case/:id"}
                  component={CaseContainer}
                />
                <Route path={match.path + "/Cases"} component={CaseContainer} />

                <Route
                  path={match.path + "/Clients"}
                  component={ContactContainer}
                />
                <Route
                  path={match.path + "/Documents"}
                  component={DocumentTableContainer}
                />
                <Route
                  path={match.path + "/Calendar"}
                  component={CalendarContainer}
                />
                <Route
                  path={match.path + "/Labels"}
                  component={LabelContainer}
                />
                <Route
                  path={match.path + "/Finance"}
                  component={TimeEntriesContainer}
                />
                <Route
                  path={match.path + "/Trash"}
                  component={TrashContainer}
                />
                <Route
                  path={match.path + "/ValidateAccount"}
                  component={StripeAccountValidator}
                />
                <Route
                  path={match.path + "/AliPay"}
                  component={AliPayReceiver}
                />
                <Route
                  path={match.path + "/AccountPlans"}
                  component={AccountPlansConsumer}
                />

                <Route
                  path={match.path + "/Accounting"}
                  component={AccountingLayoutContainer}
                />
                <Route
                  path={match.path + "/MyAccounting"}
                  component={MyAccountingContainer}
                />
                <Route
                  path={match.path + "/Invoices"}
                  component={InvoicesContainer}
                />
                <Route
                  path={match.path + "/Invoices/Products"}
                  component={InvoicesContainer}
                />
                <Route
                  path={match.path + "/Invoices/Create"}
                  component={InvoicesContainer}
                />
                <Route
                  path={match.path + "/Invoices/Single/:id"}
                  component={InvoicesContainer}
                />

                <Route
                  path={match.path + "/Admin/Presentation/:id"}
                  component={AdminContainer}
                />

                <Route
                  path={match.path + "/Admin"}
                  component={AdminContainer}
                />

                <Route
                  path={match.path + "/Setup"}
                  component={SetupContainer}
                />

                <Route
                  path={match.path + "/Collection"}
                  component={CollectionStatusContainer}
                />

                <Route
                  path={match.path + "AccountingOld"}
                  component={AccountingContainer}
                />

                <Route
                  path={match.path + "/ApplicationUD"}
                  component={ApplicationUDContainer}
                />

                <Route
                  path={match.path + "/BusinessOperating"}
                  component={BusinessOperatingContainer}
                />

                <Route
                  path={match.path + "/PowerOfAttorney"}
                  component={PowerOfAttorneyContainer}
                />

                <Route
                  path={match.path + "/EmploymentContract"}
                  component={EmploymentContractContainer}
                />

                <Route
                  path={match.path + "/Templates"}
                  component={ModelTemplateContainer}
                />

                <Route
                  path={match.path + "/Signing"}
                  component={SigningContainer}
                />

                <Route path={match.path + "/Test"} component={TestPage} />
              </Switch>
            );
          }}
        />
      </DashboardLayout>
    );
  }
}

export default withRTKCaseData(
  withRTKMessageData(withRouter(DashboardLayoutContainer))
);
