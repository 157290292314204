import React from "react";

import { Button, ButtonGroup, ButtonToolbar, Modal } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class ConfirmModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      message: "",
      callback: (result) => {},
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = (message, callback) => {
    this.setState({
      showModal: true,
      message: message,
      callback: callback || (() => {}),
    });
  };

  onOK = () => {
    this.setState({ showModal: false }, () => {
      this.state.callback(true);
    });
  };

  onCancel = () => {
    this.setState({ showModal: false }, () => {
      this.state.callback(false);
    });
  };

  render() {
    return (
      <Modal
        className="center-dialog"
        show={this.state.showModal}
        onHide={this.close}
      >
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
          closeButton
        >
          <Modal.Title>
            <AxoLocal entity="ConfirmModalConfirm" defaultValue={"Bekræft"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          <h4 style={{ whiteSpace: "pre-wrap" }}>{this.state.message}</h4>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.onOK}>
                {" "}
                <span>
                  {" "}
                  <Icon glyph="icon-fontello-ok-3" />
                  &nbsp;&nbsp;
                  <AxoLocal entity="ConfirmModalonOK" defaultValue={"Ja"} />
                </span>
              </Button>
              <Button className="Lex-button-2" onClick={this.onCancel}>
                <span>
                  {" "}
                  <Icon glyph="icon-fontello-cancel" />
                  &nbsp;&nbsp;
                  <AxoLocal entity="ConfirmModalCancel" defaultValue={"Nej"} />
                </span>
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
