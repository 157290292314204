import React, { useState, useEffect } from "react";

import { ApiService, DataStore } from "../services/AxoServices";

import {
  withRouter,
  EasyIDView,
  Flexbox,
  // AxoLocal,
  LoadingIndicator,
} from "../utilities/LexUtilities";

import QueryString from "query-string";

let originString, setOriginString;
function EasyIDExternalLoginView(props) {
  [originString, setOriginString] = useState("");

  let query = QueryString.parse(props.location.search);

  let { userCode, origin, background } = query;

  const handleMount = async () => {
    setOriginString(origin);

    if (!userCode) {
      return;
    }

    let response = await ApiService.loginAuto(userCode);
    if (!response.ok) {
      redirectToOrigin();
    }

    let body = await response.json();
    if (body.result !== "Success") {
      redirectToOrigin();
    }

    redirectByRole(body.role);
  };

  useEffect(() => {
    handleMount();
  }, []);

  const redirectToOrigin = () => {
    window.top.location.href = originString || "https://axolex.com/EasyIDLogin";
  };

  const redirectByRole = (role) => {
    var { history } = props;

    DataStore.resetStore();

    if (role === "Lawyer" || role === "Accountant" || role === "Admin") {
      history.push("/Dashboard");
    } else if (
      role === "Client" ||
      role === "AccountingClient" ||
      role === "SocietyMember" ||
      role === "EasyID"
    ) {
      history.push("/ClientDashboard");
    } else if (role === "XLink") {
      history.push("/Xlink");
    } else if (role === "Society") {
      history.push("/Dashboard");
    }
  };

  const handleExternalLogin = (result) => {
    window.top.location.href =
      window.location.protocol +
      "//" +
      window.location.host +
      "/EasyIDLogin?userCode=" +
      result.userCode;
  };

  if (!!userCode) {
    return <LoadingIndicator />;
  }

  return (
    <Flexbox justifyCenter style={{ minHeight: "600px", background }}>
      <div>
        <EasyIDView loginUserAccount cacheLogin onLogin={handleExternalLogin} />
        {/* <div className='leftPadding'>
          Powered by Axolex ©
        </div> */}
      </div>
    </Flexbox>
  );
}

export default withRouter(EasyIDExternalLoginView);
