import React from "react";
import { Table, Column, Cell } from "fixed-data-table-2";
import { withRouter } from "react-router-dom";
import { RIENumber } from "../../utilities/Riek";
import moment from "moment";
import PropTypes from "prop-types";
import Select from "react-select";
import * as $ from "jquery";

import { DataActions, ModalService } from "../../services/AxoServices";

import {
  Icon,
  FileViewerModal,
  LexButton,
  AxoCheckbox,
  SortHeaderCell,
  DataListWrapper,
  InlineEdit,
  ctx,
  AxoLocal,
  InlineTextArea,
  TableBase,
  Dimensions,
} from "../../utilities/LexUtilities";

import { FormControl, FormGroup, InputGroup } from "react-bootstrap";

const defaultColumnDefs = {
  name: {
    width: 150,
    shown: true,
  },
  type: {
    width: 250,
    shown: true,
  },
  description: {
    width: 250,
    shown: true,
  },
  price: {
    width: 250,
    shown: true,
  },
  currency: {
    width: 200,
    shown: true,
  },
  picture: {
    width: 200,
    shown: true,
  },
  buyable: {
    width: 250,
    shown: true,
  },
  creationDate: {
    width: 200,
    shown: true,
  },
  actions: {
    width: 200,
    shown: true,
  },
};

class ItemPricesTable extends TableBase {
  static propTypes = {
    itemPrices: PropTypes.array.isRequired,
    itemPriceTypes: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.name = "ItemPricesTable";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = this.generateDefaultSortIndexes(
      props.itemPrices
    );
    let tableEntries = this.copyItems(props.itemPrices);
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      showCurrency: "",
    };
  }

  copyItems = (entries) => {
    return entries.map((c) => Object.assign({}, c));
  };

  generateDefaultSortIndexes = (entries) => {
    var defaultSortIndexes = [];
    var size = entries.length;
    for (var index = 0; index < size; index++) {
      defaultSortIndexes.push(index);
    }
    return defaultSortIndexes;
  };

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.copyItems(nextProps.itemPrices);
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  onSearch = (event) => {
    this.setState(
      {
        searchText: event.target.value,
      },
      () => {
        this.updateFilteredEntries();
      }
    );
  };

  updateFilteredEntries = () => {
    let { tableEntries } = this.state;

    var entries = this.getFilteredEntries(tableEntries, this.state.searchText);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText && !this.state.showCurrency) {
      return entries;
    }
    let filteredEntries = entries;
    if (this.state.showCurrency) {
      filteredEntries = filteredEntries.filter(
        (entry) =>
          entry.currency.toLowerCase() === this.state.showCurrency.toLowerCase()
      );
    }
    return filteredEntries.filter((c) => {
      return c.description.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  onChangePropertyValue = (id, propertyName, value) => {
    var { sortedDataList } = this.state;

    var index = sortedDataList._data.findIndex((c) => c.id === id);
    if (index === -1) {
      return;
    }

    var price = { ...sortedDataList._data[index] };
    price[propertyName] = value;
    var newList = new DataListWrapper(
      sortedDataList._indexMap,
      sortedDataList._data
    );
    var newArray = newList._data.slice();
    newArray[index] = price;
    newList._data = newArray;
    this.setState({ sortedDataList: newList }); //Optimistic updating

    DataActions.updateItemPrice(price);
  };

  onDeletePrice = (id) => {
    var confirmMessage = (
      <div>
        <div>
          <AxoLocal
            entity="axoAccounting46"
            defaultValue="Dette vil slette prisen permanent."
          />{" "}
        </div>
        <div>
          <AxoLocal
            entity="AdminTabView21"
            defaultValue="Er du sikker på at du ønsker at fortsætte?"
          />
        </div>
      </div>
    );
    ModalService.openConfirmModal(confirmMessage, (value) => {
      if (value) {
        DataActions.deleteItemPrice(id);
      }
    });
  };

  onShowPicture = (picture) => {
    this.fileModal.open([picture]);
  };

  onCurrencySelect = (selectedCurrency) => {
    if (!selectedCurrency) {
      return;
    }
    this.setState({ showCurrency: selectedCurrency.value }, () => {
      this.updateFilteredEntries();
    });
  };

  renderCurrencySelect = (userProfile) => {
    let currency = this.state.showCurrency || "";

    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "150px",
          zIndex: 1000,
        }}
      >
        <Select
          name="form-field-name"
          menuPlacement="auto"
          value={{
            value: currency.toUpperCase(),
            label: currency.toUpperCase(),
          }}
          onChange={this.onCurrencySelect}
          noOptionsMessage={() => ""}
          options={[
            { value: "", label: ctx.getSync("AdminTabView24") }, //<AxoLocal entity='AdminTabView24' defaultValue='Valuta'/> },
            { value: "DKK", label: "DKK" },
            { value: "USD", label: "USD" },
            { value: "GBP", label: "GBP" },
            { value: "EUR", label: "EUR" },
            { value: "CNY", label: "CNY" },
            { value: "SEK", label: "SEK" },
            { value: "NKK", label: "NKK" },
            { value: "ISK", label: "ISK" },
          ]}
        />
      </div>
    );
  };

  uploadPicture = (price) => {
    let newPrice = { ...price };
    $("#uploadImage").trigger("click");
    $("#uploadImage").on("change", function () {
      let file = this.files[0];
      if (!file.type.includes("image")) {
        return;
      }
      DataActions.uploadDocuments(this.files).then((response) => {
        if (response.status === 200) {
          response.json().then((addedFiles) => {
            newPrice.pictureId = addedFiles[0].id;
            newPrice.picture = addedFiles[0];
            DataActions.updateItemPrice(newPrice);
          });
        }
      });
    });
  };

  render() {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth, itemPriceTypes } = this.props;
    var tableWidth = containerWidth - 30;

    return (
      <div>
        <div
          className="flexbox-layout"
          style={{ paddingLeft: "25px", paddingRight: "25px" }}
        >
          <h4>
            <Icon glyph="icon-fontello-align-justify" />
            &nbsp;&nbsp;
            <AxoLocal entity="Enhedspris14" defaultValue="Priser" /> (
            {sortedDataList._data.length})
          </h4>
          <div>{this.renderCurrencySelect()}</div>
          <div style={{ maxWidth: "250px", paddingTop: "15px" }}>
            <FormGroup controlId="inputWithIcon">
              <InputGroup>
                <InputGroup.Addon>
                  <Icon glyph="icon-fontello-search" />
                </InputGroup.Addon>
                {/* Søg...  */}
                <AxoLocal
                  componentClass={FormControl}
                  type="text"
                  value={this.state.searchText}
                  onChange={this.onSearch}
                  componentAttribute="placeholder"
                  entity="ContactTableViewplaceholderonSearch"
                />
                <FormControl.Feedback>
                  <Icon glyph="icon-fontello-search" />
                </FormControl.Feedback>
              </InputGroup>
            </FormGroup>
          </div>
        </div>
        <div style={{ paddingTop: "0px", paddingLeft: "20px" }}>
          <Table
            rowHeight={100}
            rowsCount={sortedDataList.getSize()}
            width={tableWidth}
            height={containerHeight}
            isColumnResizing={false}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            headerHeight={40}
          >
            <Column
              columnKey="name"
              header={
                <SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.name}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  <AxoLocal entity="Enhedspris5" defaultValue="Navn" />
                </SortHeaderCell>
              }
              cell={(props) => {
                let entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} {...props}>
                    <div>
                      <InlineEdit
                        value={entry.name || "---"}
                        change={(input) =>
                          this.onChangePropertyValue(
                            entry.id,
                            "name",
                            input.value
                          )
                        }
                      />
                    </div>
                  </Cell>
                );
              }}
              width={columnDefs.name.width}
              isResizable={true}
            />
            <Column
              columnKey="type"
              header={
                <SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.type}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  <AxoLocal
                    entity="DocumentGridViewType"
                    defaultValue={"Type"}
                  />
                </SortHeaderCell>
              }
              cell={(props) => {
                let entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} {...props}>
                    <select
                      className="axoblue selectbg"
                      value={entry.itemPriceTypeId || 0}
                      style={{ width: "100%" }}
                      onChange={(event) => {
                        let value = event.target.value;
                        value = value !== "0" ? value : null;
                        this.onChangePropertyValue(
                          entry.id,
                          "itemPriceTypeId",
                          value
                        );
                      }}
                    >
                      <option value={0}>...</option>
                      {itemPriceTypes.map((t) => {
                        return (
                          <option key={t.id} value={t.id}>
                            {t.name}
                          </option>
                        );
                      })}
                    </select>
                  </Cell>
                );
              }}
              width={columnDefs.type.width}
              isResizable={true}
            />
            <Column
              columnKey="description"
              header={
                <SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.description}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  <AxoLocal entity="Enhedspris6" defaultValue="Beskrivelse" />
                </SortHeaderCell>
              }
              cell={(props) => {
                let entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} {...props}>
                    <div>
                      <InlineTextArea
                        value={entry.description || "---"}
                        change={(input) =>
                          this.onChangePropertyValue(
                            entry.id,
                            "description",
                            input.value
                          )
                        }
                      />
                    </div>
                  </Cell>
                );
              }}
              width={columnDefs.description.width}
              isResizable={true}
            />
            <Column
              columnKey="price"
              header={
                <SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.price}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  <AxoLocal entity="Enhedspris8" defaultValue="Pris" />
                </SortHeaderCell>
              }
              cell={(props) => {
                let entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} {...props}>
                    <div>
                      <RIENumber
                        value={entry.price}
                        format={(value) => {
                          return <span>{value.toString()}</span>;
                        }}
                        change={(input) =>
                          this.onChangePropertyValue(
                            entry.id,
                            "price",
                            input.value
                          )
                        }
                        propName="value"
                        className="editable"
                        editProps={{ style: { width: "100%" } }}
                      />
                    </div>
                  </Cell>
                );
              }}
              width={columnDefs.price.width}
              isResizable={true}
            />
            <Column
              columnKey="currency"
              header={
                <SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.currency}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  <AxoLocal entity="AdminTabView28" defaultValue="Valuta" />
                </SortHeaderCell>
              }
              cell={(props) => {
                let entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} {...props}>
                    <div>{entry.currency}</div>
                  </Cell>
                );
              }}
              width={columnDefs.currency.width}
              isResizable={true}
            />
            <Column
              columnKey="picture"
              header={
                <SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.picture}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal entity="Enhedspris2" defaultValue="Billede" />
                </SortHeaderCell>
              }
              cell={(props) => {
                let entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} {...props}>
                    <div>
                      {!!entry.picture ? (
                        <span>
                          <Icon
                            className="editable"
                            role="button"
                            onClick={() => this.onShowPicture(entry.picture)}
                            glyph="icon-fontello-attach-7"
                          />
                          &nbsp;
                        </span>
                      ) : null}
                      <LexButton onClick={() => this.uploadPicture(entry)}>
                        <AxoLocal
                          entity="DocumentTabViewUpload"
                          defaultValue="Upload"
                        />
                      </LexButton>
                    </div>
                  </Cell>
                );
              }}
              width={columnDefs.picture.width}
              isResizable={true}
            />

            <Column
              columnKey="buyable"
              header={
                <SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.buyable}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  <AxoLocal entity="axoidcode48" defaultValue={"Køb"} />
                </SortHeaderCell>
              }
              cell={(props) => {
                let entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} {...props}>
                    <div>
                      <AxoCheckbox
                        checked={entry.buyable}
                        onChange={(event) =>
                          this.onChangePropertyValue(
                            entry.id,
                            "buyable",
                            event.target.checked
                          )
                        }
                      />
                    </div>
                  </Cell>
                );
              }}
              width={columnDefs.buyable.width}
              isResizable={true}
            />
            <Column
              columnKey="creationDate"
              header={
                <SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.creationDate}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  <AxoLocal
                    entity="TimeEntryFormntimeEntry"
                    defaultValue="Dato"
                  />
                </SortHeaderCell>
              }
              cell={(props) => {
                let entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} {...props}>
                    <div>{moment(entry.creationDate).format("L")}</div>
                  </Cell>
                );
              }}
              width={columnDefs.creationDate.width}
              isResizable={true}
            />
            <Column
              columnKey="actions"
              header={<Cell></Cell>}
              cell={(props) => {
                let entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} {...props}>
                    <Icon
                      role="button"
                      onClick={this.onDeletePrice.bind(this, entry.id)}
                      glyph="icon-fontello-trash-1"
                    />
                  </Cell>
                );
              }}
              width={columnDefs.actions.width}
              isResizable={true}
            />
          </Table>
        </div>
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
        <input
          name="image"
          type="file"
          id="uploadImage"
          accept="image/*"
          style={{ display: "none" }}
        />
      </div>
    );
  }
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight - 350;
    },
  })(ItemPricesTable)
);
