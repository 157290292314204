import React from "react";
import { groupBy } from "lodash";
import { AxoLocal } from "../../utilities/LexUtilities";
import { Table } from "react-bootstrap";

function makeMonth(name) {
  return {
    name: name,
    totalResult: 0,
  };
}

//Saldobalance
export default class AccountsView extends React.PureComponent {
  generateMonthSums() {
    return [
      makeMonth(
        <span>
          {" "}
          <AxoLocal
            entity="AccountingTabViewEntity45"
            defaultValue={"Januar"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity46"
            defaultValue={"Februar"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity47" defaultValue={"Marts"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity48" defaultValue={"April"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity49" defaultValue={"Maj"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity50" defaultValue={"Juni"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity51" defaultValue={"Juli"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity51"
            defaultValue={"August"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity53"
            defaultValue={"September"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity54"
            defaultValue={"Oktober"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity55"
            defaultValue={"November"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity56"
            defaultValue={"December"}
          />
        </span>
      ),
    ];
  }

  generateAccountTypeRows = (
    accountType,
    accounts,
    monthSums,
    postings,
    primoResult
  ) => {
    var rows = [];
    rows.push(
      <tr className="Axoaccountsevenlist">
        <th>{accountType.name}</th>
        <td>{primoResult[accountType.name + accountType.id]}</td>
        {monthSums.map((ms) => {
          var total = ms[accountType.name + accountType.id];
          return <td>{total || 0}</td>;
        })}
        {/* Ultimo result */}
        <td className="doubleboder">
          {monthSums[monthSums.length - 1][accountType.name + accountType.id]}
          {/* {monthSums.reduce((acc, ms)  => {
            return acc + ms[accountType.name + accountType.id];
          }, 0)} */}
        </td>
      </tr>
    );
    return rows;
  };

  getPrimoResult(postings, accounts, accountTypes) {
    var primoResult = {
      totalResult: 0,
    };
    accountTypes.forEach((type) => {
      var accountsForType = accounts.filter(
        (a) => a.postingAccountTypeId === type.id
      );
      var postingsForType = postings.filter(
        (p) => !!accountsForType.find((a) => a.id === p.postingAccountId)
      );

      var income = postingsForType.reduce((acc, posting) => {
        return acc + posting.income;
      }, 0);
      var expenses = postingsForType.reduce((acc, posting) => {
        return acc + posting.expenses;
      }, 0);

      var net = income - expenses;
      //monthSum[type.name + type.id] = net;
      primoResult[type.name + type.id] = net;
      primoResult.totalResult += net;
    });
    return primoResult;
  }

  render() {
    let { postings, accounts, accountTypes } = this.props;

    let currentYear = new Date().getFullYear();
    let postingsForYear = postings.filter(
      (p) => new Date(p.date).getFullYear() === currentYear
    );

    let lastYear = postings.filter(
      (p) => new Date(p.date).getFullYear() === currentYear - 1
    );

    let primoResult = this.getPrimoResult(lastYear, accounts, accountTypes);

    let monthSums = this.generateMonthSums();

    let months = groupBy(postingsForYear, (p) => {
      return new Date(p.date).getMonth();
    });

    for (let i = 0; i < monthSums.length; i++) {
      let postingsForMonth = months[i.toString()];
      if (!postingsForMonth) {
        continue;
      }

      let monthSum = monthSums[i];
      let previousSum = {};
      if (i > 0) {
        previousSum = monthSums[i - 1];
      }

      accountTypes.forEach((type) => {
        let accountsForType = accounts.filter(
          (a) => a.postingAccountTypeId === type.id
        );
        let postingsForType = postingsForMonth.filter(
          (p) => !!accountsForType.find((a) => a.id === p.postingAccountId)
        );

        let income = postingsForType.reduce((acc, posting) => {
          return acc + posting.income;
        }, 0);
        let expenses = postingsForType.reduce((acc, posting) => {
          return acc + posting.expenses;
        }, 0);

        let net = income - expenses;
        //monthSum[type.name + type.id] = net;
        monthSum[type.name + type.id] =
          (previousSum[type.name + type.id] || 0) + net;
        if (i === 0) {
          monthSum[type.name + type.id] += primoResult[type.name + type.id];
        }
        monthSum.totalResult += net;
      });
      monthSum.totalResult =
        (previousSum.totalResult || 0) + monthSum.totalResult;
      if (i === 0) {
        monthSum.totalResult += primoResult.totalResult;
      }
    }

    return (
      <div className="AccountingpaddingTB">
        <h3>
          {" "}
          <AxoLocal
            entity="AccountingTabViewEntity2"
            defaultValue={"Saldobalance"}
          />
        </h3>
        <Table responsive>
          <tbody>
            <tr className="Axoaccountsbannebg">
              <th></th>
              <td>
                {" "}
                <AxoLocal
                  entity="AccountingTabViewEntity61"
                  defaultValue={"Primo"}
                />
              </td>
              {monthSums.map((m) => (
                <th>{m.name}</th>
              ))}
              <th>
                {" "}
                <AxoLocal
                  entity="AccountingTabViewEntity60"
                  defaultValue={"Ultimo"}
                />{" "}
              </th>
            </tr>
            {accountTypes.map((t) =>
              this.generateAccountTypeRows(
                t,
                accounts,
                monthSums,
                postings,
                primoResult
              )
            )}
            <tr className="doubleboder">
              <th>
                <AxoLocal entity="InvoiceInvoicetotal" defaultValue={"Total"} />
              </th>
              <td>{primoResult.totalResult}</td>
              {monthSums.map((m) => (
                <td>{m.totalResult}</td>
              ))}
              <td className="doubleboder">
                {monthSums[monthSums.length - 1].totalResult}
                {/* { monthSums.reduce((acc, ms)  => {
                  return acc + ms.totalResult;
                }, 0)} */}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
