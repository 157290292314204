import React from "react";
import DataStore from "../services/DataAccess/DataStore";

// import {
//   ExtraSmall,
//   Small,
//   Medium,
//   Large,
//   MediumOrSmaller,
// } from "../utilities/Responsive";

import { Row, Col, Grid } from "react-bootstrap";

import { LocalizationService } from "../services/AxoServices";

import {
  Icon,
  // AxoLocal
} from "../utilities/LexUtilities";

import FrontPageImage from "../resources/images/FrontPageImage.jpg";

import ContactForm from "./ContactForm";

// import AxolexDataBehandlerAftale from "../resources/files/AxolexDatabehandlerAftale.pdf";
// import AxolexDataProcessingAgreement from "../resources/files/AxolexDataProcessingAgreement.pdf";

export default class InfoPageContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      locale: "enUS",
    };
    this.name = "InfoPageContent";
  }

  componentDidMount() {
    LocalizationService.subscribeToLocale(this.name, (locale) => {
      this.setState({ locale });
    });

    let scrollId = DataStore.getScrollId();
    if (scrollId) {
      setTimeout(() => {
        document.getElementById(scrollId).scrollIntoView();
        DataStore.setScrollId("");
      }, 500);
    }
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale(this.name);
  }

  onScrollToSolution = (element, event) => {
    event.preventDefault();
    var here = this.props.location.pathname.toLowerCase().includes("solutions");
    var scrollElement = document.getElementById(element);
    if (here && scrollElement) {
      scrollElement.scrollIntoView();
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Solutions");
    }
  };

  onScrollToPackages = (element, event) => {
    event.preventDefault();
    var here = this.props.location.pathname.toLowerCase().includes("packages");
    var scrollElement = document.getElementById(element);
    if (here && scrollElement) {
      scrollElement.scrollIntoView();
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Packages");
    }
  };

  render() {
    return (
      <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Grid fluid>
          {/* <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Large>
              <Col md={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div
                      className="banner-item"
                      style={{ height: "600px", maxHeight: "600px" }}
                    >
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ height: "100%" }}
                        src="/svg/Axolex1-frontside.jpg"
                      />
                      <div className="text_wrapper_no_hover chbannetaxt">
                        <div className="frontbannetaxt1">
                          <div className="frontbannetaxt">
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />
                          </div>
                          <br />
                          <p className="item--text">
                            Softwareudvikling og juridisk rådgivning
                          </p>
                        </div>
                        <br />
                        <div
                          className="item--text"
                          style={{ paddingLeft: "0px" }}
                        >
                          <Grid
                            fluid
                            className="hidden-xs"
                            style={{
                              fontSize: "17px",
                              paddingTop: "10px",
                              background: "rgba(0,0,0,0.3)",
                            }}
                          >
                            <Row>
                              <Col
                                sm={10}
                                smOffset={1}
                                xs={12}
                                className="text-center nopadding"
                                style={{ fontSize: "17px" }}
                              >
                                <Row className="flexbox-center-vertical">
                                  <Col xs={3}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-cogs"
                                        style={{ fontSize: "70px" }}
                                      />
                                    </div>
                                    <div className="hiddenxsfontSize">
                                      Softwareudvikling
                                    </div>
                                  </Col>
                                  <Col xs={1}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-plus-6"
                                        style={{ fontSize: "40px" }}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-network"
                                        style={{ fontSize: "70px" }}
                                      />
                                    </div>
                                    <div className="hiddenxsfontSize">
                                      Juridisk rådgivning.
                                    </div>
                                  </Col>
                                  <Col xs={1}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-plus-6"
                                        style={{ fontSize: "40px" }}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-laptop-circled"
                                        style={{ fontSize: "70px" }}
                                      />
                                    </div>
                                    <div className="hiddenxsfontSize">
                                      Konsulentydelser
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Large>

            <Medium>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex1-frontside-md.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h3 className="item--title">
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />
                          </h3>
                          <p className="item--text">
                            Softwareudvikling og juridisk rådgivning
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Medium>
            <Small>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex1-frontside-md.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h3 className="item--title">
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />
                          </h3>
                          <p className="item--text">
                            <AxoLocal
                              entity="FrontPageindextaxt2"
                              defaultValue={"Juridisk management software"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Small>
            <ExtraSmall>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex1-frontside-xs.jpg"
                      />
                      <div className="text_wrapper">
                        <div className="text_position chbannetaxt">
                          <h5
                            className="item--title"
                            style={{ paddingLeft: "10px" }}
                          >
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />
                          </h5>
                          <p className="item--text">
                            <AxoLocal
                              entity="FrontPageindextaxt2"
                              defaultValue={"Juridisk management software"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </ExtraSmall>
          </Row> */}
        </Grid>

        {/* <MediumOrSmaller>
          <Grid
            fluid
            className="front-navbar-xs"
            style={{ paddingTop: "25px" }}
          >
            <Row style={{ paddingLeft: "5px", paddingRight: "0px" }}>
              <Col
                xs={12}
                className="text-center nopadding"
                style={{ fontSize: "12px" }}
              >
                <Col xs={3} className="nopadding">
                  <div style={{ paddingLeft: "px", paddingRight: "0px" }}>
                    <Icon
                      className="axolexwebsideicon"
                      glyph="icon-fontello-desktop-circled"
                      style={{ fontSize: "50px" }}
                    />
                  </div>
                  <div style={{ paddingLeft: "5px", paddingRight: "0px" }}>
                    Softwareudvikling
                  </div>
                </Col>
                <Col xs={1} className="nopadding">
                  <div
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingTop: "25px",
                    }}
                  >
                    <Icon
                      glyph="icon-fontello-plus-6"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                </Col>
                <Col xs={3} className="nopadding">
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Icon
                      className="axolexwebsideicon"
                      glyph="icon-fontello-network"
                      style={{ fontSize: "50px" }}
                    />
                  </div>
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    Juridisk rådgivning
                  </div>
                </Col>
                <Col xs={1} className="nopadding">
                  <div
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingTop: "25px",
                    }}
                  >
                    <Icon
                      glyph="icon-fontello-plus-6"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                </Col>
                <Col xs={3} className="nopadding">
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Icon
                      className="axolexwebsideicon"
                      glyph="icon-fontello-laptop-circled"
                      style={{ fontSize: "50px" }}
                    />
                  </div>
                  <div style={{ paddingLeft: "0px", paddingRight: "2px" }}>
                    Konsulentydelser
                  </div>
                </Col>
              </Col>
            </Row>
          </Grid>
        </MediumOrSmaller> */}

        <div id="completePackage" className="axosmAnchorOffSet">
          .
        </div>
        <Grid fluid>
          <Row>
            <img
              alt=""
              className="img-responsive bg_img "
              style={{ height: "400px", width: "100%" }}
              src={FrontPageImage}
            />
          </Row>
          <Row className="topPadding">
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                {/* <AxoLocal entity='FrontPageindextaxt7' defaultValue={'Komplet pakkeløsning'}/> */}
                Softwareudvikling
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid className="Axolex-Menu-lg">
          <Row>
            <Col xs={12}>
              <p>
                Vi leverer IT-systemer til regnskab, administration, digital
                signering, samt it- og datasikkerhed. Selskabet kan også levere
                IT-konsulentydelser efter aftale. Du er velkommen til at
                kontakte os for nærmere oplysninger.
              </p>
              <p>
                Vi arbejder med alle former for udviklingsopgaver inden for IT,
                både front-end og back-end. Vi arbejder blandt andet inden for
                .NET, React, C#, Azure, SQL og objektorienteret programmering
                generelt. Vores faglige udviklere kan arbejde med næsten alle
                populære sprog.
              </p>
              <p>
                Inden for disse områder er vores fokus på solide overordnede
                design patterns, og vi lægger vægt på håndtering af både den
                tekniske del og software delen. Vi tager hensyn til både
                national og international lovgivning i vores løsninger.
              </p>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                {/* <AxoLocal entity='FrontPageindexintegration' defaultValue={'Dataressourcer og sikkerhed'}/> */}
                Software produkter
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row className="nopadding text-center" style={{ fontSize: "15px" }}>
            <Col
              md={4}
              sm={12}
              className="nopadding"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div>
                <Icon
                  className="axolexwebsideicon"
                  glyph="icon-fontello-lifebuoy-1"
                  style={{ fontSize: "60px" }}
                />
              </div>
              <div className="editable">
                <h3>Digitalt signatursystem</h3>
              </div>
              <div>
                Vi har udviklet et digitalt signatursystem, som tillader
                underskrifter med både MitID og visuelle underskrifter. Det
                underskrevne dokument forsegles med et certifikat fra vores
                globale partner, Globalsign, således at dokumentet ikke kan
                redigeres efter underskriften. Underskriftens gyldighed kan
                valideres online.
              </div>
            </Col>
            <Col
              md={4}
              sm={12}
              className="nopadding"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div className="">
                <Icon
                  className="axolexwebsideicon"
                  glyph="icon-fontello-lock-circled"
                  style={{ fontSize: "60px" }}
                />
              </div>
              <div className="editable">
                <h3>
                  {/* <AxoLocal
                    entity="FrontPageindexintegration2a"
                    defaultValue={"Datasikkerhed"}
                  /> */}
                  Regnskab
                </h3>
              </div>
              <div>
                I samarbejde med dygtige revisorer, har vi udviklet et
                regnskabssystem til brug for professionelle revisorer. Udover
                sædvanlig bogføringsfunktionalitet, har dette system omfattende
                afstemningsfunktionalitet der minimerer og effektiviserer
                revisorens arbejde.
              </div>
            </Col>
            <Col
              md={4}
              sm={12}
              className="nopadding"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div className="">
                <Icon
                  className="axolexwebsideicon"
                  glyph="icon-fontello-resize-full-circle"
                  style={{ fontSize: "60px" }}
                />
              </div>
              <div className="editable">
                <h3>
                  {/* <AxoLocal
                    entity="FrontPageindexintegration3a"
                    defaultValue={"Systemsikkerhed"}
                  /> */}
                  Administrationssystem
                </h3>
              </div>
              <div>
                Vores største system er til almindelig administration for enhver
                virksomhed. Virksomheden kan holde styr på dokumenter, sager,
                kunder, faktura, emails, kalender, regnskab, og klienter kan få
                deres eget login til klientplatformen.
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                Juridisk rådgivning
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid className="Axolex-Menu-lg">
          <Row>
            <Col xs={12} className="nopadding">
              <p>
                Axolex kan tilbyde juridisk rådgivning inden for særlige
                fagområder, blandt andet IT-ret, databehandling og
                datasikkerhed. Rådgivningen håndteres af ansvarsforsikrede
                jurister. Kontakt os for at høre mere.
                {/* Vi hjælper dig med at løse dine problemer professionelt, hurtigt og sagligt begrundet. 
                Axolex' jurister tilbyder professionel juridisk rådgivning inden for alle juridiske områder, blandt udlændingeret, it-ret, arbejdsret, selskabsret, 
                EU-ret, persondataret, osv. */}
              </p>

              {/* <p>I erhvervsområdet kan vi assistere med alt fra starten af selskabet til selskabets administration. Fra selskabets 
                stiftelse til selskabets afvikling kan vi rådgive om den mest professionelle måde at håndtere sagerne på.</p>

              <p>I privat regi arbejder vi blandt andet med familiesammenføring, skilsmisse, arvesager, opholdstilladelser, mv.</p> */}

              {/* <p>Hvis du har nogen form for juridiske spørgsmål, er du velkommen til at kontakte os. Hvis du har et problem, så lad os give dig en løsning.</p> */}
            </Col>
          </Row>
          <div></div>
        </Grid>
        {/* <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                Rådgivningsområder
              </div>
            </Col>
          </Row>
        </Grid> */}
        {/* <Grid>
          <Row className="nopadding text-center" style={{ fontSize: "15px" }}>
            <Col
              md={4}
              sm={12}
              className="nopadding"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div>
                <Icon
                  className="axolexwebsideicon"
                  glyph="icon-fontello-lifebuoy-1"
                  style={{ fontSize: "60px" }}
                />
              </div>
              <div className="editable">
                <h3>Privat område</h3>
              </div>
              <div>
                Aftaleret. Obligationsret. Tingsret. Personret. Familieret. Arveret. Persondata.
              </div>
            </Col>
            <Col
              md={4}
              sm={12}
              className="nopadding"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div className="">
                <Icon
                  className="axolexwebsideicon"
                  glyph="icon-fontello-lock-circled"
                  style={{ fontSize: "60px" }}
                />
              </div>
              <div className="editable">
                <h3>
                  <AxoLocal
                    entity="FrontPageindexintegration2a"
                    defaultValue={"Datasikkerhed"}
                  />
                  Erhvervsjura
                </h3>
              </div>
              <div>
                Selskabsret. Datahåndtering. Virksomhedsrådgivning. Køb, salg og overdragelse af virksomhed. Generationsskifte.
              </div>
            </Col>
            <Col
              md={4}
              sm={12}
              className="nopadding"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div className="">
                <Icon
                  className="axolexwebsideicon"
                  glyph="icon-fontello-resize-full-circle"
                  style={{ fontSize: "60px" }}
                />
              </div>
              <div className="editable">
                <h3>
                  <AxoLocal
                    entity="FrontPageindexintegration3a"
                    defaultValue={"Systemsikkerhed"}
                  />
                  International
                </h3>
              </div>
              <div>
                EU-ret. Persondataret. International handelsret. Udbudsret. Investering. Kreditret. Immaterialret.
              </div>
            </Col>
          </Row>
        </Grid> */}
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">Kontakt</div>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col xs={12}>
              <ContactForm attachFiles />
            </Col>
          </Row>
        </Grid>
        {/* <div  className='text-center'>
          <object type="image/svg+xml" data="/svg/GL-icons_01-26.svg" className="axosvg">
          test svg
          </object>
        </div> */}
      </div>
    );
  }
}
