import React from "react";

import {
  DataStore,
  DataActions,
  // DataActions
} from "../../../services/AxoServices";

import { withData, withRTKData } from "../../../utilities/LexUtilities";

import AnnualReportView from "./AnnualReportView";

class AnnualReportContainer extends React.PureComponent {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    DataStore.fetchFiscalYears().then(() => {
      DataStore.fetchAccountingReport();
    });

    DataStore.fetchClientPlan();
  }

  handleSelectedClient = (selectedClient) => {
    DataStore.setState({
      fiscalYears: null,
    });

    DataStore.setSelectedContact(selectedClient);

    DataStore.fetchFiscalYears().then(() => {
      DataStore.fetchAccountingReport();
    });

    DataStore.fetchClientPlan();
  };

  onSelectFiscalYear = (fiscalYearId) => {
    DataStore.selectFiscalYear(fiscalYearId);
    DataStore.fetchAccountingReport();
  };

  // onSelectStartDate = (selectedStartDate) => {
  //   DataStore.updateProperty('selectedPostingStartDate', selectedStartDate);
  // }

  // onSelectEndDate = (selectedEndDate) => {
  //   DataStore.updateProperty('selectedPostingEndDate', selectedEndDate);
  // }

  updatePlan = (propertyName, value) => {
    let { selectedPlan } = this.props;

    DataActions.updateFinanceAccountPlan({
      ...selectedPlan,
      [propertyName]: value,
    });
  };

  onChangeIncludeDraft = (includeDraft) => {
    DataStore.setState({ includeDraft });
    DataStore.fetchAccountingReport();
  };

  render() {
    return (
      <AnnualReportView
        {...this.props}
        {...this.state}
        handleSelectedClient={this.handleSelectedClient}
        onSelectFiscalYear={this.onSelectFiscalYear}
        updatePlan={this.updatePlan}
        onChangeIncludeDraft={this.onChangeIncludeDraft}
      />
    );
  }
}

export default withRTKData(
  withData(AnnualReportContainer, (store) => {
    let selectedPlan = store.clientPlan || { accounts: [] };
    let accountResultsMap = {};
    selectedPlan.accounts.forEach((acc) => {
      accountResultsMap[acc.number] = store.accountingReport[acc.id] || 0;
    });

    return {
      selectedPlan: selectedPlan,
      financeAccountMap: store.financeAccounts,
      accountingReport: store.accountingReport,
      accountResultsMap,
      fiscalYears: store.fiscalYears,
      selectedFiscalYearId: store.selectedFiscalYearId,
      userSettings: store.userSettings,
      userProfile: store.userProfile,
      includeDraft: store.includeDraft,
    };
  })
);
