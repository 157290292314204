import React from "react";
import DailyForecast from "./DailyForecast";
import ApiService from "../../services/DataAccess/ApiService";

export default class FutureForecast extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      weatherData: null,
    };
  }
  componentDidMount() {
    this.updateWeatherData(this.props);
  }

  updateWeatherData = (props) => {
    var coords = this.props.coords;
    if (coords) {
      var { latitude, longitude } = coords;
      ApiService.getWeatherForecastCoords(latitude, longitude).then(
        (weatherData) => {
          this.setState({ weatherData: weatherData });
        }
      );
    } else {
      ApiService.getWeatherForecast(this.props.city).then((weatherData) => {
        this.setState({ weatherData: weatherData });
      });
    }
  };

  render() {
    var data = this.state.weatherData;
    var days = [];

    if (data) {
      days = data.list.slice(1, 5).map(function (item) {
        return (
          <DailyForecast
            key={item.dt}
            date={item.dt}
            icon={item.weather[0].icon}
            conditions={item.weather[0].main}
            min={item.temp.min}
            max={item.temp.max}
          />
        );
      });
    }

    return <div>{days}</div>;
  }
}
