import React from "react";
import { Cell } from "fixed-data-table-2";

var SortTypes = {
  ASC: "ASC",
  DESC: "DESC",
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

export default class SortHeaderCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, altIcon, showAltIcon, children, ...props } =
      this.props;
    return (
      <Cell {...props}>
        <div role="button" onClick={this._onSortChange}>
          {showAltIcon ? altIcon : children}
          {/*{sortDir ? (sortDir === SortTypes.DESC ? '↓' : '↑') : ''}*/}
        </div>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir
          ? reverseSortDirection(this.props.sortDir)
          : SortTypes.DESC
      );
    }
  }
}
