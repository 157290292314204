import React from "react";
import PropTypes from "prop-types";
import BusinessOperatingFormView from "./BusinessOperatingFormView";
import BusinessOperatingDetailsList from "./BusinessOperatingDetailsList";

import { DataActions } from "../services/AxoServices";

import { withRouter, LoadingIndicator } from "../utilities/LexUtilities";

class BusinessOperatingDetailsView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };
  }

  static propTypes = {
    models: PropTypes.array.isRequired,
  };

  onSubmit = async (model) => {
    let response = await DataActions.updateBusinessOperating(model);

    if (!response.ok) {
      return false;
    }

    this.setState({ editing: false });
  };

  onCancel = () => {
    this.setState({ editing: false });
  };

  render() {
    let { match, models, documentMap } = this.props;

    let { editing } = this.state;

    let id = parseInt(match.params.id, 10);
    let model = models.find((a) => a.id === id);

    if (!model) {
      return <LoadingIndicator />;
    }

    if (!editing) {
      return (
        <BusinessOperatingDetailsList
          model={model}
          onEdit={() => this.setState({ editing: true })}
        />
      );
    }

    return (
      <BusinessOperatingFormView
        key={model.id}
        startValue={model}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
        documentMap={documentMap}
      />
    );
  }
}

export default withRouter(BusinessOperatingDetailsView);
