import React from "react";
import PropTypes from "prop-types";

class AxoFrame extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      height: props.defaultHeight,
    };
  }

  static propTypes = {
    defaultHeight: PropTypes.string,
    content: PropTypes.string,
    onRender: PropTypes.func,
    bottomPadding: PropTypes.number,
  };

  static defaultProps = {
    defaultHeight: "100px",
    content: "<div></div>",
    bottomPadding: 0,
  };

  supportsSrcDocProperty = () => {
    var iframe = document.createElement("iframe");
    return iframe.srcdoc !== undefined;
  };

  componentDidMount() {
    if (!this.supportsSrcDocProperty()) {
      this.renderContent();
    }
  }

  componentDidUpdate(nextProps) {
    if (
      !this.supportsSrcDocProperty() &&
      nextProps.content !== this.props.content
    ) {
      this.renderContent();
    }
  }

  renderContent = () => {
    var { onRender, bottomPadding } = this.props;

    var iframedoc =
      this.frame.contentDocument || this.frame.contentWindow.document;

    iframedoc.body.innerHTML = this.props.content;

    var contentHeight = iframedoc.body.scrollHeight;
    if (contentHeight === 0 && iframedoc.childNodes.length > 0) {
      contentHeight = iframedoc.childNodes[0].scrollHeight;
    }
    if (contentHeight > 0) {
      contentHeight += bottomPadding;
    }

    this.setState({
      height: contentHeight + "px",
    });

    if (onRender) {
      onRender(contentHeight);
    }
  };

  onLoadFrame = (event) => {
    var { onRender, bottomPadding } = this.props;

    var frame = event.target;
    var contentHeight = frame.contentWindow.document.body.scrollHeight;
    if (
      contentHeight === 0 &&
      frame.contentWindow.document.childNodes.length > 0
    ) {
      contentHeight = frame.contentWindow.document.childNodes[0].scrollHeight;
    }
    if (contentHeight > 0) {
      contentHeight += bottomPadding;
    }
    this.setState({
      height: contentHeight + "px",
    });

    if (onRender) {
      onRender(contentHeight);
    }
  };

  render() {
    var { content } = this.props;
    return (
      <iframe
        title="axoframe"
        srcDoc={content}
        onLoad={this.onLoadFrame}
        className="seamless"
        sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-top-navigation allow-forms"
        height={this.state.height}
        ref={(c) => (this.frame = c)}
      />
    );
  }
}

export default AxoFrame;
