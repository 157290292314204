import React from "react";
import { AxoLocal } from "../../utilities/LexUtilities";
import { Carousel } from "react-bootstrap";

class MailCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexM1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext185"
                defaultValue={"Unik løsning til virksomhedens mailsystem."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext186"
                defaultValue={
                  "Kollaborativ integration med hele kontrolsystemet."
                }
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexM2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext185"
                defaultValue={"Unik løsning til virksomhedens mailsystem."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext186"
                defaultValue={
                  "Kollaborativ integration med hele kontrolsystemet."
                }
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexM3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext185"
                defaultValue={"Unik løsning til virksomhedens mailsystem."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext186"
                defaultValue={
                  "Kollaborativ integration med hele kontrolsystemet."
                }
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class NetworkCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexS1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexS3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexS4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexS5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexS6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexS2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class FinanceCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexEC1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexEC2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexEC3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexEC4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexEC5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class CasesCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA7.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA2-1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA3-1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA8.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexA9.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class ClientsCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexK1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexK2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexK3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexK4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class SelfserviceCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexSB1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexSB2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexSB3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexSB4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class DocumentsCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexD1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexD2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexD3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexD4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexD5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexD6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexD7.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}
class BookingCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/AxolexCK1-1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/AxolexCK2-1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class CalendarCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/AxolexCK2-1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexCK6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/AxolexCK1-1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class OnlinepaymentCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
      >
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexEC11.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="FrontPageindextaxt14"
                defaultValue={"Online betalingssystem"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class LabelsCarouselCh extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/AxolexMP1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext200"
                defaultValue={"Digital arkivering og administration"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}
export {
  OnlinepaymentCarouselCh,
  MailCarouselCh,
  NetworkCarouselCh,
  FinanceCarouselCh,
  CasesCarouselCh,
  ClientsCarouselCh,
  SelfserviceCarouselCh,
  DocumentsCarouselCh,
  BookingCarouselCh,
  CalendarCarouselCh,
  LabelsCarouselCh,
};
