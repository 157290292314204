import React from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import { Row, Col, Grid } from "react-bootstrap";
import { Icon } from "../../utilities/LexUtilities";

export default class LabelNavItem extends React.PureComponent {
  //Props
  //label
  //glyph
  render() {
    var label = this.props.label;
    var count =
      label.messageIds.length +
      label.caseIds.length +
      label.documentIds.length +
      label.clientIds.length;
    return (
      <Grid fluid>
        <ContextMenuTrigger id={"label-context-menu-" + this.props.label.id}>
          <Row id="clickable-area">
            <Col
              xs={12}
              className="nopadding"
              style={{
                textAlign: "left",
                fontSize: "15px",
                fontWeight: "normal",
              }}
            >
              &nbsp; &nbsp;
              <span>
                <Icon glyph={this.props.glyph} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.label.name}
              </span>
              {count ? <span>&nbsp;&nbsp;&nbsp;({count})</span> : null}
            </Col>
          </Row>
        </ContextMenuTrigger>
      </Grid>
    );
  }
}
