import React from "react";

import ReactSelect from "react-select";

export default class AxoSelect extends React.PureComponent {
  filterValues = ({ label }, search) => {
    return label.toString().toLowerCase().includes(search.toLowerCase());
  };

  render() {
    return <ReactSelect filterOption={this.filterValues} {...this.props} />;
  }
}
