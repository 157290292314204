import React from "react";
import { AxoLocal } from "./LexUtilities";

import moment from "moment";

export default class Timer extends React.PureComponent {
  //Props
  //onStop
  //style
  constructor(props) {
    super(props);
    this.state = {
      running: false,
      seconds: 0,
    };
  }

  getTimeString = () => {
    let { seconds } = this.state;

    if (Math.floor(seconds / 3600) > 0) {
      return moment.utc(seconds * 1000).format("HH:mm:ss");
    }
    return moment.utc(seconds * 1000).format("mm:ss");
  };

  onStart = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.setState({
        seconds: this.state.seconds + 1,
      });
    }, 1000);
    this.setState({ running: true });
  };

  onStop = () => {
    clearInterval(this.timer);
    this.setState({ running: false });
    if (this.props.onStop) {
      this.props.onStop(this.state.seconds);
    }
  };

  onReset = () => {
    this.setState({ seconds: 0 });
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  renderSecondButton = () => {
    if (this.state.running) {
      return (
        <span key="stop" role="button" onClick={this.onStop}>
          <AxoLocal entity="timerStopButton" />
        </span>
      );
    }
    return (
      <span key="start" role="button" onClick={this.onReset}>
        <AxoLocal entity="timerResetButton" />
      </span>
    );
  };

  render() {
    return (
      <div>
        <div className="text-center">
          <span role="button" onClick={this.onStart}>
            <AxoLocal entity="timerStartButton" />
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span style={{ fontSize: "18px" }}>
            {this.getTimeString()}&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <div style={{ display: "inline-block", width: "50px" }}>
            {this.renderSecondButton()}
          </div>
        </div>
      </div>
    );
  }
}
