import React from "react";
import TinyMCE from "react-tinymce";

import { ErrorBoundary } from "../../utilities/LexUtilities";

import ImageService from "../../services/ImageService";

export default class SimpleTinyMCEEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    this.editor = null;

    this.imageUpload = React.createRef();

    this.imageLoadCallback = null;
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  fileUploadCallback = async (callback, value, meta) => {
    if (meta.filetype === "image") {
      this.imageLoadCallback = callback;
      this.imageUpload.current.click();
    }
  };

  onSelectImage = async (event) => {
    if (!this.imageLoadCallback) {
      return;
    }

    let files = event.target.files;
    if (files.length === 0) {
      return;
    }

    let file = files[0];
    let newFile = await ImageService.enforceImageMaxSize(file, 750);

    let base64Url = await this.toBase64(newFile);

    this.imageLoadCallback(base64Url, { alt: newFile.name });

    this.imageUpload.current.value = ""; //onChange handler should be triggered when uploading the same file twice.
  };

  //Props
  //text
  //onTextChange
  //height
  //setupEditor
  setupEditor = (editor) => {
    let { onTextChange, fontName } = this.props;

    this.editor = editor;

    editor.on("keyup", () => {
      onTextChange(editor.getContent());
    });
    editor.on("init", () => {
      if (!!fontName) {
        editor.execCommand("fontName", false, fontName);
      }
      // this.initializeLineHeight(editor);
    });
    if (!!this.props.setupEditor) {
      this.props.setupEditor(editor);
    }
  };

  initializeLineHeight(editor) {
    var content = editor.getContent();
    var newContent = content.replace(
      "<head>",
      "<head><style>body { line-height: 18pt; }</style>"
    );
    editor.setContent(newContent);
  }

  convertLocale = () => {
    var locale = this.props.locale;
    switch (locale) {
      case "da":
        return "da";
      case "enUS":
        return "en_GB";
      case "ch":
        return "zh_CN";
      default:
        return "en_GB";
    }
  };

  onChange = (event) => {
    this.props.onTextChange(event.target.getContent());
  };

  setContent = (newContent) => {
    this.editor.setContent(newContent);
  };

  render() {
    let { hideFontControls } = this.props;

    var language = this.convertLocale();
    return (
      <div className="mce-no-border">
        <ErrorBoundary>
          <TinyMCE
            key={language}
            content={this.props.text}
            config={{
              height: this.props.height,
              branding: false,
              language: language,
              paste_data_images: true,
              image_advtab: true,
              valid_elements: "*[*]",
              valid_children: "*[*]",
              cleanup_on_startup: false,
              // forced_root_block: false,
              trim_span_elements: false,
              verify_html: false,
              cleanup: false,
              convert_urls: false,
              statusbar: true,
              fontsize_formats:
                "8pt 10pt 11pt 12pt 14pt 18pt 20pt 22pt 24pt 32pt 36pt 38pt 40pt 72pt",
              lineheight_formats:
                "18pt 20pt 22pt 24pt 26pt 28pt 30pt 36pt 40pt 50pt 60pt 70pt 80pt",
              browser_spellcheck: true,
              file_picker_callback: this.fileUploadCallback,
              plugins: [
                "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                "searchreplace visualblocks visualchars code fullscreen lineheight",
                "insertdatetime media nonbreaking save table contextmenu directionality",
                "emoticons template paste textcolor colorpicker textpattern imagetools codesample fullpage",
              ],
              menubar: "file edit insert format table tools",
              toolbar1:
                "undo redo " +
                (!hideFontControls ? "| fontselect | fontsizeselect " : "") +
                "| forecolor |insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image addAttachment addImage | emoticons",
              // toolbar1: ' undo redo | fontselect | fontsizeselect | lineheightselect | forecolor |insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image addAttachment addImage | emoticons',
              setup: this.setupEditor,
            }}
            onChange={this.onChange}
          />
        </ErrorBoundary>
        <input
          ref={this.imageUpload}
          onChange={this.onSelectImage}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
        />
      </div>
    );
  }
}
