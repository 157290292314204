import React from "react";

export default class Counter extends React.PureComponent {
  //Props
  //seconds
  getTimeString = () => {
    var seconds = this.props.seconds;
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds - hours * 3600) / 60);
    var remainingSeconds = seconds - hours * 3600 - minutes * 60;

    var timeString = "";
    if (hours > 0) {
      timeString +=
        (hours >= 10 ? hours.toString() : "0" + hours.toString()) + ":";
    }
    timeString +=
      (minutes >= 10 ? minutes.toString() : "0" + minutes.toString()) + ":";
    timeString +=
      remainingSeconds >= 10
        ? seconds.toString()
        : "0" + remainingSeconds.toString();
    return timeString;
  };

  render() {
    return <span>{this.getTimeString()}</span>;
  }
}
