// @ts-check
import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import DataActions from "../../services/DataAccess/DataActions";
import UserInfoService from "../../services/UserInfoService";

import { AuthorizationService } from "../../services/AxoServices";

import SocialComment from "./SocialComment";
import CommentBox from "./CommentBox";
import moment from "moment";
import { Row, Col, Grid, Image } from "react-bootstrap";

import {
  Icon,
  ConfirmModal,
  FileViewerModal,
  UserListModal,
  AxoLocal,
} from "../../utilities/LexUtilities";

export default class SocialPost extends React.PureComponent {
  //Props
  //socialPost
  //userProfile
  //friends
  //documents
  //readOnly
  onDeleteComment = (commentId) => {
    this.confirmModal.open(
      <AxoLocal
        entity="ComponentDoyouwanttodeletethiscomment"
        defaultValue={"Vil du slette denne kommentar?"}
      />,
      (reply) => {
        if (reply) {
          var socialPost = Object.assign({}, this.props.socialPost);
          socialPost.comments = socialPost.comments.filter(
            (c) => c.id !== commentId
          );
          this.props.onDeleteComment(commentId);
        }
      }
    );
  };

  renderComments = () => {
    var socialPost = this.props.socialPost;
    return socialPost.comments.map((comment) => {
      return (
        <SocialComment
          key={comment.id}
          comment={comment}
          userProfile={this.props.userProfile}
          onDeleteComment={this.onDeleteComment}
        />
      );
    });
  };

  onAddComment = (comment) => {
    var socialPost = Object.assign({}, this.props.socialPost);
    return this.props.onAddComment(socialPost, comment);
  };

  onDeletePost = () => {
    this.confirmModal.open(
      <AxoLocal
        entity="socialPostDeletePost"
        defaultValue={"Vil du slette indlægget?"}
      />,
      (reply) => {
        if (reply) {
          this.props.onDeletePost(this.props.socialPost.id);
        }
      }
    );
  };

  renderDeleteIcon = () => {
    let { readOnly, socialPost, userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);
    if (readOnly) {
      return null;
    }
    //Admin or current user
    if (userType !== "Admin" && socialPost.userProfile.id !== userProfile.id) {
      return null;
    }
    return (
      <div style={{ position: "relative", top: 0, paddingBottom: "10px" }}>
        <span onClick={this.onDeletePost} role="button">
          <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
          &nbsp;&nbsp;&nbsp;
          <Icon glyph="icon-fontello-scissors axored" className="axored" />
        </span>
      </div>
    );
  };

  onShowFile = (document) => {
    this.fileModal.open([document]);
  };

  renderImageFooter = () => {
    var socialPost = this.props.socialPost;
    if (!socialPost.image) {
      return null;
    }
    return (
      <div style={{ padding: "5px 0px 5px 0px" }}>
        <Image
          className="social-image"
          src={ApiService.getFileInlinePath(socialPost.image.id)}
        />
      </div>
    );
  };

  renderAttachmentFooter = () => {
    var attachment = this.props.socialPost.attachment;
    if (!attachment) {
      return null;
    }
    return (
      <div>
        <span>
          <strong
            role="button"
            onClick={this.onShowFile.bind(this, attachment)}
            className="editable axopaddingLeftSocial"
          >
            {attachment.fileName}
          </strong>
          &ensp;
          <span>({attachment.sizeString})</span>
        </span>
      </div>
    );
  };

  likePost = (event) => {
    event.preventDefault();
    DataActions.addPostLiker({
      socialPostId: this.props.socialPost.id,
      userProfileId: this.props.userProfile.id,
    });
  };

  unLikePost = (event) => {
    event.preventDefault();
    DataActions.removePostLiker({
      socialPostId: this.props.socialPost.id,
      userProfileId: this.props.userProfile.id,
    });
  };

  renderLikeButton = () => {
    if (
      this.props.socialPost.likers.find(
        (l) => l.id === this.props.userProfile.id
      )
    ) {
      return (
        <div
          role="button"
          onClick={this.unLikePost}
          style={{ border: "none", marginRight: 25 }}
        >
          <Icon glyph="icon-fontello-thumbs-up-1" />
          <span style={{ position: "relative", top: -2, left: 3 }}>
            <AxoLocal
              key="remove"
              entity="socialPostRemoveLike"
              defaultValue={"Fjern synes om"}
            />
          </span>
        </div>
      );
    }
    return (
      <div
        role="button"
        onClick={this.likePost}
        style={{ border: "none", marginRight: 25 }}
      >
        <Icon glyph="icon-fontello-thumbs-up-1" />
        <span style={{ position: "relative", top: -2, left: 3 }}>
          <AxoLocal
            key="like"
            entity="socialPostLike"
            defaultValue={"Synes om"}
          />
        </span>
      </div>
    );
  };

  showLikers = () => {
    this.userListModal.open(this.props.socialPost.likers);
  };

  renderLikes = () => {
    var likers = this.props.socialPost.likers;
    if (likers.length === 0) {
      return null;
    }
    return (
      <span className="editable" onClick={this.showLikers} role="button">
        <strong>{likers.length} </strong>
        <AxoLocal
          entity="socialPostLikeCount"
          defaultValue={"synes om dette opslag"}
        />
      </span>
    );
  };

  //Props
  //socialPost
  render() {
    var socialPost = this.props.socialPost;
    var userProfile = socialPost.userProfile;
    return (
      <div>
        <div>
          <div className="post-header">
            <img
              alt=""
              className="img-circle"
              src={UserInfoService.getProfileImageSource(userProfile)}
              width="40"
              height="40"
            />
            &nbsp; &nbsp;
            <div className="poster-info">
              <div>{UserInfoService.getDisplayName(userProfile)}</div>
              <div>
                <small>
                  {userProfile.city}
                  {userProfile.country ? (
                    <span>
                      {userProfile.city ? ", " : ""}
                      <AxoLocal
                        entity="CountryNames"
                        data={{ countrySlug: userProfile.country }}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </div>
            <div className="post-date hidden-sm hidden-xs fg-text text-right">
              {this.renderDeleteIcon()}
              <div style={{ position: "relative", top: -10 }}>
                <small>
                  <strong>{moment(socialPost.dateTime).format("L LT")}</strong>
                </small>
              </div>
            </div>
          </div>
          <div>
            {socialPost.infoImageUrl ? (
              <img
                alt=""
                src={socialPost.infoImageUrl}
                width="100%"
                height="auto"
              />
            ) : (
              <div className="axopaddingLeftSocial">
                <span
                  dangerouslySetInnerHTML={{ __html: socialPost.content }}
                ></span>
              </div>
            )}
          </div>
          <div>
            {this.renderImageFooter()}
            {this.renderAttachmentFooter()}{" "}
          </div>
        </div>
        {socialPost.id ? (
          <div style={{ padding: "5px" }}>
            <CommentBox
              locale={this.props.locale}
              onAddComment={this.onAddComment}
              documentMap={this.props.documentMap}
            />
          </div>
        ) : null}
        <div
          className="Axolike"
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
            height: "50px",
            lineHeight: "50px",
          }}
        >
          <Grid fluid>
            <Row>
              <Col xs={6} className="nopadding ">
                {socialPost.id ? this.renderLikeButton() : null}
              </Col>
              <Col xs={6} className="text-right nopadding">
                {this.renderLikes()}
                {socialPost.comments.length > 0 ? (
                  <span>
                    &nbsp; ({socialPost.comments.length})&nbsp;
                    <AxoLocal
                      entity="socialPostcomments"
                      defaultValue={"kommentarer"}
                    />
                  </span>
                ) : null}
              </Col>
            </Row>
          </Grid>
        </div>
        <div
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
            backgroundColor: "#ffffff",
          }}
        >
          {this.renderComments()}
        </div>
        {socialPost.id && socialPost.comments.length > 0 ? (
          <div style={{ padding: "7px" }}>
            <CommentBox
              locale={this.props.locale}
              onAddComment={this.onAddComment}
              documentMap={this.props.documentMap}
            />
          </div>
        ) : null}
        <ConfirmModal ref={(c) => (this.confirmModal = c)} />
        <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
        <UserListModal
          userProfile={this.props.userProfile}
          friends={this.props.friends}
          ref={(c) => (this.userListModal = c)}
        />
      </div>
    );
  }
}
