import React from "react";
import ReactDOM from "react-dom";
import Invoice from "../../routes/TimeEntries/Invoice";
import { Table, Column, Cell } from "fixed-data-table-2";
import moment from "moment";
import ClientInvoiceGridView from "./ClientInvoiceGridView";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";

import {
  Row,
  Col,
  Grid,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import {
  DataActions,
  ApiService,
  TimeEntryService,
  RoutingService,
} from "../../services/AxoServices";

import {
  Icon,
  SortHeaderCell,
  DataListWrapper,
  AxoLocal,
  withRouter,
  TableBase,
  Dimensions,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  number: {
    width: 150,
    shown: true,
  },
  dueDate: {
    width: 250,
    shown: true,
  },
  status: {
    width: 250,
    shown: true,
  },
  lawyerString: {
    width: 250,
    shown: true,
  },
  outstandingAmount: {
    width: 200,
    shown: true,
  },
  actions: {
    width: 200,
    shown: true,
  },
};

class ClientInvoicesView extends TableBase {
  constructor(props) {
    super(props);
    this.name = "ClientInvoicesView";
    this.defaultColumnDefs = defaultColumnDefs;

    var tableEntries = this.addDataStringsToInvoices(props.invoices);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(tableEntries);
    this.state = {
      selectedInvoices: [],
      selectedInvoiceId: 0,
      tableEntries: tableEntries,
      invoiceSendingId: 0,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        dueDate: 180,
        status: 110,
        actions: 75,
      },
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      isGeneratingPDF: false,
      isGeneratingAndSending: false,
      showGeneratePDFError: false,
      generatedPDF: false,
      isFetchingHTML: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.addDataStringsToInvoices(nextProps.invoices);
    let filteredEntries = this.getFilteredInvoices(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  addDataStringsToInvoices = (invoices) => {
    return invoices.map((invoice) => {
      //client string is used for sorting
      invoice.lawyerString = invoice.userProfile
        ? invoice.userProfile.lastName + ", " + invoice.userProfile.firstName
        : "No client";

      return invoice;
    });
  };

  onSelectInvoice = (invoiceId, event) => {
    event.preventDefault();
    this.setState({ selectedInvoiceId: invoiceId });
  };

  getStatusText = (status) => {
    switch (status) {
      case "Created":
        return (
          <AxoLocal
            key="created"
            entity="InvoiceGridViewcreated"
            defaultValue={"Created"}
          />
        );
      case "Sent":
        return (
          <AxoLocal
            key="submitted"
            entity="InvoiceGridViewSubmitted"
            defaultValue={"Submitted"}
          />
        );
      case "Paid":
        return (
          <AxoLocal
            key="paid"
            entity="InvoiceInvoicePaid"
            defaultValue={"Paid"}
          />
        );
      case "Approved":
        return (
          <AxoLocal
            key="approved"
            entity="InvoiceGridViewapproved"
            defaultValue={"Approved"}
          />
        );
      case "Overdue":
        return (
          <AxoLocal
            key="overdue"
            entity="InvoiceGridViewoverdue"
            defaultValue={"Overdue"}
          />
        );
      default:
        return "";
    }
  };

  getStatusIcon = (status) => {
    switch (status) {
      case "Created":
        return (
          <div>
            <Icon
              style={{ color: "#428bca" }}
              glyph="icon-fontello-check-empty"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Sent":
        return (
          <div>
            <Icon style={{ color: "#306C67" }} glyph="icon-fontello-export-3" />
            &nbsp;&nbsp;
          </div>
        );
      case "Paid":
        return (
          <div>
            <Icon style={{ color: "#518F41" }} glyph="icon-fontello-check-1" />
            &nbsp;&nbsp;
          </div>
        );
      case "Approved":
        return (
          <div>
            <Icon
              style={{ color: "#003BFF" }}
              glyph="icon-fontello-ok-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Overdue":
        return (
          <div>
            <Icon
              style={{ color: "#D71F4B" }}
              glyph="icon-fontello-cancel-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      default:
        return <div></div>;
    }
  };

  onSearch = (event) => {
    let { tableEntries } = this.state;

    var invoices = this.getFilteredInvoices(tableEntries, event.target.value);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(invoices);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, invoices),
    });
  };

  getFilteredInvoices = (invoices, searchText) => {
    if (!searchText) {
      return invoices;
    }
    return invoices.filter((c) => {
      return c.number
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  };

  calculateInvoiceTotal = (invoice) => {
    return Math.round(
      invoice.timeEntries.reduce((sum, timeEntry) => {
        return sum + TimeEntryService.getTotalValue(timeEntry);
      }, 0)
    );
  };

  _rowClassNameGetter = (rowIndex) => {
    var activeInvoice = this.state.sortedDataList.getObjectAt(rowIndex);
    if (activeInvoice && activeInvoice.id === this.state.highlightedInvoiceId) {
      return "highlight-row";
    }
  };

  renderInvoicesTable = () => {
    const { forceShowCards } = this.props;
    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <Col sm={4}>
                  <h4>
                    {" "}
                    <Icon glyph="icon-fontello-align-justify" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="InvoicePDFsViewinvoices"
                      defaultValue={"Fakturaer"}
                    />{" "}
                  </h4>
                </Col>
                <Col sm={4}>
                  <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-search" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
                <Col
                  sm={4}
                  className="hidden-xs text-right"
                  style={{ paddingTop: "10px" }}
                >
                  <Button
                    className="Lex-button-2"
                    onClick={this.props.onToggleCards}
                  >
                    {forceShowCards ? (
                      <span>
                        <Icon glyph="icon-fontello-grid" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showTable"
                          entity="CaseDataTableFixedShowastable"
                          defaultValue={"Vis som tabel"}
                        />
                      </span>
                    ) : (
                      <span>
                        <Icon glyph="icon-fontello-th-thumb-empty" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showCards"
                          entity="CaseDataTableFixedShowCards"
                          defaultValue={"Vis som kartotekskort"}
                        />
                      </span>
                    )}
                  </Button>
                </Col>
              </Row>
            </Grid>
          </div>
          {forceShowCards ? (
            this.renderGridView()
          ) : (
            <div>
              <ExtraSmall>{this.renderGridView()}</ExtraSmall>
              <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderGridView = () => {
    return (
      <div style={{ paddingTop: "0px" }}>
        <ClientInvoiceGridView
          invoices={this.state.sortedDataList._data}
          onSelectInvoice={this.onSelectInvoice}
        />
      </div>
    );
  };

  renderTable = () => {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth } = this.props;
    var tableWidth = containerWidth - 25;

    return (
      <div style={{ paddingLeft: "20px" }}>
        <Table
          rowHeight={40}
          rowsCount={sortedDataList.getSize()}
          rowClassNameGetter={this._rowClassNameGetter}
          height={containerHeight}
          width={tableWidth}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          headerHeight={40}
        >
          <Column
            columnKey="number"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-sort-name-up" />}
                showAltIcon={columnDefs.number.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.number}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;#
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  <a
                    href="SelectInvoice"
                    onClick={this.onSelectInvoice.bind(this, invoice.id)}
                  >
                    {invoice.number.toString() || "0"}
                  </a>
                </Cell>
              );
            }}
            width={columnDefs.number.width}
            isResizable={true}
          />
          <Column
            columnKey="dueDate"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph=" icon-fontello-calendar-6" />}
                showAltIcon={columnDefs.dueDate.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.dueDate}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="InvoiceGridViewpaymentDate"
                  defaultValue={"Betalingsdato"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {moment(invoice.dueDate).format("L")}
                </Cell>
              );
            }}
            width={columnDefs.dueDate.width}
            isResizable={true}
          />
          <Column
            columnKey="status"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-check-1" />}
                showAltIcon={columnDefs.status.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.status}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  <div className="flexbox-standard">
                    {this.getStatusIcon(invoice.status)}
                    &nbsp;
                    {this.getStatusText(invoice.status)}
                  </div>
                </Cell>
              );
            }}
            width={columnDefs.status.width}
            isResizable={true}
          />
          <Column
            columnKey="lawyerString"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-user" />}
                showAltIcon={columnDefs.lawyerString.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.lawyerString}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView35A" defaultValue={"Afsender"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {invoice.client ? (
                    <span>
                      {invoice.userProfile.lastName},{" "}
                      {invoice.userProfile.firstName}
                    </span>
                  ) : (
                    <span>
                      <AxoLocal
                        entity="getStatusStringinvoicestatus"
                        defaultValue="Ingen klient"
                      />
                    </span>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.lawyerString.width}
            isResizable={true}
          />
          <Column
            columnKey="outstandingAmount"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-database-2" />}
                showAltIcon={columnDefs.outstandingAmount.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.outstandingAmount}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="InvoiceGridViewRemainderAmount"
                  defaultValue={"Restbeløb"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {invoice.currency} {this.calculateInvoiceTotal(invoice)}
                </Cell>
              );
            }}
            width={columnDefs.outstandingAmount.width}
            isResizable={true}
          />
          <Column
            columnKey="actions"
            header={<Cell></Cell>}
            cell={(props) => {
              return <Cell {...props}></Cell>;
            }}
            width={columnDefs.actions.width}
            isResizable={true}
          />
        </Table>
      </div>
    );
  };

  onCloseInvoice = () => {
    this.setState({ selectedInvoiceId: 0 });
  };

  onPayInvoice = (invoiceId) => {
    var { invoices, onPayInvoice } = this.props;
    var invoice = invoices.find((i) => i.id === this.state.selectedInvoiceId);
    if (!invoice) {
      return Promise.reject("Invoice could not be found");
    }
    return onPayInvoice(invoice, this.calculateInvoiceTotal(invoice));

    // else {
    //   return onRegisterCard()
    //   .then(() => {
    //     return this.transferPayment(invoice);
    //   })
    // }
  };

  onPayInvoiceWithCard = (invoiceId) => {
    var { invoices, onTransferPaymentFromCard } = this.props;
    var invoice = invoices.find((i) => i.id === this.state.selectedInvoiceId);
    if (!invoice) {
      return Promise.reject("Invoice could not be found");
    }
    return onTransferPaymentFromCard(
      invoice,
      this.calculateInvoiceTotal(invoice)
    );
  };

  onGeneratePdf = () => {
    var currentInvoice = this.props.invoices.find(
      (i) => i.id === this.state.selectedInvoiceId
    );

    this.setState({ isFetchingHTML: true }, () => {
      var invoice = Object.assign({}, currentInvoice);
      invoice.innerHTML = this.getInvoiceHtml();
      this.setState({ isFetchingHTML: false }, () => {
        this.doGeneratePdf(invoice);
      });
    });
  };

  doGeneratePdf = (invoice) => {
    this.setState({ isGeneratingPDF: true });
    return ApiService.updateInvoice(invoice)
      .then((response) => {
        if (response.ok) {
          return DataActions.generateInvoicePdf(invoice.id);
        }
        return Promise.reject("Invoice could not be updated.");
      })
      .then((response) => {
        this.setState({ isGeneratingPDF: false });
        if (response.ok) {
          return response.json();
        }
        return Promise.reject("Invoice could not be printed.");
      })
      .then((newPDF) => {
        // this.props.onCreatePDF(newPDF);
        this.props.history.push(RoutingService.getPath("Documents"));
        // this.setState( { generatedPDF: true } );
        // setTimeout(() => {
        //   this.setState( { generatedPDF: false } );
        // }, 1500);
      })
      .catch((reason) => {
        console.log(reason);
        this.showGeneratePDFError();
      });
  };

  getInvoiceHtml = () => {
    var invoiceNode = ReactDOM.findDOMNode(this.invoiceComponent).innerHTML;
    return invoiceNode;
  };

  showGeneratePDFError = () => {
    this.setState({
      isGeneratingPDF: false,
      isGeneratingAndSending: false,
      showGeneratePDFError: true,
    });
    setTimeout(() => {
      this.setState({ showGeneratePDFError: false });
    }, 5000);
  };

  renderBody = () => {
    if (this.state.selectedInvoiceId) {
      return (
        <Invoice
          invoice={this.props.invoices.find(
            (i) => i.id === this.state.selectedInvoiceId
          )}
          cardInformation={this.props.cardInformation}
          readOnly
          renderButtons={false}
          renderClientButtons={!this.state.isFetchingHTML}
          onPayInvoice={this.onPayInvoice}
          onPayInvoiceWithCard={this.onPayInvoiceWithCard}
          onClose={this.onCloseInvoice}
          onGeneratePdf={this.onGeneratePdf}
          isGeneratingPDF={this.state.isGeneratingPDF}
          isGeneratingAndSending={this.state.isGeneratingAndSending}
          showGeneratePDFError={this.state.showGeneratePDFError}
          generatedPDF={this.state.generatedPDF}
          ref={(r) => (this.invoiceComponent = r)}
        />
      );
    }
    return this.renderInvoicesTable();
  };

  render() {
    return <div style={{ paddingTop: "10px" }}>{this.renderBody()}</div>;
  }
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight - 225;
    },
  })(ClientInvoicesView)
);
