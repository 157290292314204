import React from "react";
import moment from "moment";
import NumericInput from "react-numeric-input";

// import Select from 'react-select';

import {
  UserInfoService,
  TimeEntryService,
  ApiService,
  // AuthorizationService
} from "../../services/AxoServices";

import {
  Icon,
  AxoLocal,
  AsyncButton,
  getText,
} from "../../utilities/LexUtilities";

import {
  Form,
  ControlLabel,
  FormGroup,
  FormControl,
  InputGroup,
} from "react-bootstrap";

//Creates an invoice from a single time entry
export default class SimpleInvoiceForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      series: 1,
      title: "",
      subject: "",
      message: "",
      timeEntry: {
        description: "",
        timeSpent: 0,
        units: 1,
        unitType: "Units",
        rate: 100.0,
        tax: 0.0,
        attachment: "",
        expenses: 0,
        aConto: 0,
      },
      showErrorMessage: false,
      showServerErrorMessage: false,
    };
  }

  componentDidMount() {
    ApiService.getNextInvoiceNumber().then((numberInfo) => {
      this.setState({ series: numberInfo.next });
    });
  }

  componentWillReceiveProps(nextProps) {}

  onChangeTimeEntry = (timeEntry) => {
    this.setState({ timeEntry });
    // this.props.onTimeEntryUpdate(timeEntry);
  };

  onChangeWorkDate = (dateTime) => {
    this.onChangeProperty("workDate", dateTime.format());
  };

  onDescChange = (event) => {
    this.onChangeProperty("description", event.target.value);
  };

  onChangeRate = (valueAsNumber) => {
    this.onChangeProperty("rate", valueAsNumber);
  };

  onChangeTax = (valueAsNumber) => {
    this.onChangeProperty("tax", valueAsNumber / 100);
  };

  onChangeProperty = (propertyName, value) => {
    this.onChangeTimeEntry({
      ...this.state.timeEntry,
      [propertyName]: value,
    });
  };

  getTimeEntriesTotal = () => {
    let { timeEntry } = this.state;

    var timeEntryTotal = TimeEntryService.getTotalValue(timeEntry);
    return timeEntryTotal;
  };

  validateForm = () => {
    let { series, subject, timeEntry } = this.state;

    var timeEntryTotal = TimeEntryService.getTotalValue(timeEntry);
    if (!series || !subject || !timeEntry.description || !timeEntryTotal) {
      return false;
    }
    return true;
  };

  onSubmit = async () => {
    let { createSimpleInvoice } = this.props;

    if (!this.validateForm()) {
      this.setState({ showErrorMessage: true });
      return false;
    }

    let result = await createSimpleInvoice(this.state);
    if (!result) {
      this.setState({ showServerErrorMessage: true });
      setTimeout(() => {
        this.setState({ showServerErrorMessage: true });
      }, 5000);
    }
    return result;
  };

  render() {
    let { userProfile } = this.props;

    let {
      timeEntry,
      title,
      series,
      subject,
      message,
      showErrorMessage,
      showServerErrorMessage,
    } = this.state;

    return (
      <div className="standardMaxWidth leftPadding topPadding">
        <Form>
          <FormGroup>
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
                paddingTop: "10px",
              }}
            >
              <AxoLocal
                entity="CalendarEventBoxFormtitle"
                defaultValue={"Titel"}
              />
            </ControlLabel>
            <FormControl
              type="text"
              placeholder={"Intern titel"}
              value={title || series}
              onChange={(event) => this.setState({ title: event.target.value })}
            />
          </FormGroup>
          {/* <ControlLabel style={{textAlign: 'left', fontSize:'16px', fontWeight:'normal', paddingTop:'10px'}}>
            Serie
          </ControlLabel>
          <NumericInput 
            className="form-control" 
            onChange={value => this.setState({ series: value })}
            step={1} 
            value={series}
          /> */}

          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            {timeEntry.unitType === "Hours" ? (
              <AxoLocal
                key="hours"
                entity="unitHoursFullName"
                defaultValue={"Timer"}
              />
            ) : (
              <AxoLocal
                key="units"
                entity="unitUnitsFullName"
                defaultValue={"Antal enheder"}
              />
            )}
          </ControlLabel>
          <NumericInput
            className="form-control"
            onChange={(value) => {
              this.onChangeProperty("units", value);
            }}
            step={1}
            precision={2}
            value={timeEntry.units}
          />

          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
              paddingTop: "10px",
            }}
          >
            <Icon glyph="icon-fontello-money-1" />
            &nbsp;
            <span>
              <AxoLocal
                key="units"
                entity="CalendarEventFormRate"
                defaultValue={"Pris / enhed"}
              />
              &nbsp;({UserInfoService.getCurrency(userProfile)}/
              <AxoLocal
                key="unit"
                entity="TimeEntriesViewTimeUnit"
                defaultValue={"unit"}
              />
              )
            </span>
          </ControlLabel>
          <NumericInput
            className="form-control"
            onChange={this.onChangeRate}
            step={1}
            precision={2}
            value={timeEntry.rate}
          />

          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
              paddingTop: "10px",
            }}
          >
            <Icon glyph="icon-simple-line-icons-pie-chart" />
            &nbsp;
            <AxoLocal entity="CalendarEventFormVAT" defaultValue={"Moms i %"} />
          </ControlLabel>
          <NumericInput
            className="form-control"
            onChange={this.onChangeTax}
            step={1}
            precision={2}
            value={timeEntry.tax * 100}
          />
          <br />
          <FormGroup controlId="inputWithIcon" bsSize="large">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="CalendarEventFormTotal"
                defaultValue={"Samlet værdi i"}
              />
              &nbsp;{UserInfoService.getCurrency(userProfile)}.
            </ControlLabel>
            <InputGroup>
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-math" />
              </InputGroup.Addon>
              <FormControl
                type="number"
                disabled
                step="1"
                value={this.getTimeEntriesTotal().toFixed(2)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="axoidcode235"
                defaultValue={"Beskrivelse på faktura"}
              />
            </ControlLabel>
            <FormControl
              type="text"
              placeholder={`F.eks. "Medlemskontingent for ${moment()
                .year()
                .toString()}"`}
              value={timeEntry.description}
              onChange={this.onDescChange}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="axoidcode236"
                defaultValue={"Emnefelt På Email"}
              />
            </ControlLabel>
            <FormControl
              type="text"
              placeholder={`F.eks. KONTINGENT ${moment().year().toString()}`}
              value={subject}
              onChange={(event) =>
                this.setState({ subject: event.target.value })
              }
            />
          </FormGroup>
          <FormGroup controlId="description">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              {getText(
                "axoEntityidcode193",
                "Besked (skriv <PAYMENTLINK> hvor betalingslinket skal indsættes)."
              )}
            </ControlLabel>
            <FormControl
              componentClass="textarea"
              placeholder="Klik her for at betale medlemskontingent: <PAYMENTLINK>"
              rows={3}
              value={message}
              onChange={(event) =>
                this.setState({ message: event.target.value })
              }
              // onChange={this.onDescChange}
            />
          </FormGroup>
          <div className="text-center">
            <AsyncButton onClick={this.onSubmit}>
              <AxoLocal
                entity="axoEntityidcode189"
                defaultValue={"Opret opkrævning"}
              />
            </AsyncButton>
          </div>
          {showErrorMessage ? (
            <div className="axored largeText text-center">
              <AxoLocal
                entity="axoEntityidcode190"
                defaultValue={
                  "Der skal indtastes titel, overskrift, besked og beløb."
                }
              />
            </div>
          ) : null}
          {showServerErrorMessage ? (
            <div className="axored largeText text-center">
              <AxoLocal
                entity="axoEntityidcode191"
                defaultValue={"Der skete en fejl. Prøv igen senere."}
              />
            </div>
          ) : null}
        </Form>
      </div>
    );
  }
}
