const euCountryCodes = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "EL",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
  "GB",
];

export default {
  getVatRate(countryCode) {
    if (!!countryCode && countryCode.toLowerCase() === "dk") {
      return 0.25;
    }
    return 0.0;
  },

  isEUCountry(countryCode) {
    return !!countryCode && euCountryCodes.includes(countryCode.toUpperCase());
  },
};
