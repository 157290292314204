//HOC for using RTK Query hooks in class componennts
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setContactQueryParams,
  incrementContactPageSize,
  resetContactPageSize,
  setSelectedContact,
} from "../../services/DataAccess/ReduxData/contactData";

import {
  useGetContactsQuery,
  useRefetchContactsMutation,
  useUpdateContactMutation,
  useCreateContactMutation,
  useDeleteContactMutation,
  useCheckoutContactMutation,
  useCreateClientAccountMutation,
  useUpdateNewEntriesMutation,
} from "../../services/DataAccess/ApiSlice";

export default function withRTKData(WrappedComponent) {
  return function (props) {
    const dispatch = useDispatch();
    const contactQueryParams = useSelector(
      (state) => state.contactData.queryParams
    );
    const setParamsDispatch = (newParams) =>
      dispatch(setContactQueryParams(newParams));

    const contactPageSize = useSelector((state) => state.contactData.pageSize);
    const incrementContactPageSizeDispatch = () =>
      dispatch(incrementContactPageSize());
    const resetContactPageSizeDispatch = () => dispatch(resetContactPageSize());

    const selectedContact = useSelector(
      (state) => state.contactData.selectedContact
    );
    const setSelectedContactdispatch = (newParams) =>
      dispatch(setSelectedContact(newParams));

    // const { data, error, isLoading } = useGetContactsQuery(DataStore.getStore().contactQuery);
    const contactQuery = useGetContactsQuery({
      ...contactQueryParams,
      pageSize: contactPageSize,
    });

    const [refetchContacts, { isLoading: isRefetchingContacts }] =
      useRefetchContactsMutation();

    const [
      updateContact, // This is the mutation trigger
      { isLoading: isUpdatingContact }, // This is the destructured mutation result
    ] = useUpdateContactMutation();

    const [
      createContact, // This is the mutation trigger
      { isLoading: isCreatingContact }, // This is the destructured mutation result
    ] = useCreateContactMutation();

    const [
      deleteContact, // This is the mutation trigger
      { isLoading: isDeletingContact }, // This is the destructured mutation result
    ] = useDeleteContactMutation();

    const [
      checkoutContact, // This is the mutation trigger
      { isLoading: isCheckingOut }, // This is the destructured mutation result
    ] = useCheckoutContactMutation();

    const [
      createClientAccount, // This is the mutation trigger
      { isLoading: isCreatingClientAccount }, // This is the destructured mutation result
    ] = useCreateClientAccountMutation();

    const [
      updateNewEntries, // This is the mutation trigger
      { isLoading: isUpdatingNewEntries }, // This is the destructured mutation result
    ] = useUpdateNewEntriesMutation();

    let updateContactFunc = (newContact) => {
      if (newContact.id === selectedContact.id) {
        setSelectedContactdispatch(newContact);
      }
      return updateContact(newContact);
    };

    return (
      <WrappedComponent
        contactQuery={contactQuery}
        updateContact={updateContactFunc}
        refetchContacts={refetchContacts}
        isRefetchingContacts={isRefetchingContacts}
        contactMap={contactQuery.data || { contacts: [], trashed: [] }}
        isUpdatingContact={isUpdatingContact}
        createContact={createContact}
        isCreatingContact={isCreatingContact}
        deleteContact={deleteContact}
        isDeletingContact={isDeletingContact}
        checkoutContact={checkoutContact}
        isCheckingOut={isCheckingOut}
        createClientAccount={createClientAccount}
        isCreatingClientAccount={isCreatingClientAccount}
        updateNewEntries={updateNewEntries}
        isUpdatingNewEntries={isUpdatingNewEntries}
        contactQueryParams={contactQueryParams}
        setContactQueryParams={setParamsDispatch}
        contactPageSize={contactPageSize}
        incrementContactPageSize={incrementContactPageSizeDispatch}
        resetContactPageSize={resetContactPageSizeDispatch}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContactdispatch}
        {...props}
      />
    );
  };
}
