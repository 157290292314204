//Service for interacting with local storage

const loadFromStorage = (itemName) => {
  if (typeof Storage === "undefined") {
    return null;
  }
  let value = localStorage.getItem(itemName);
  return value;
};

const saveToSession = (itemName, value, stringify) => {
  if (typeof Storage === "undefined") {
    return;
  }
  if (!value) {
    sessionStorage.removeItem(itemName);
  } else {
    if (stringify) {
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(itemName, value);
  }
};

const loadFromSession = (itemName) => {
  if (typeof Storage === "undefined") {
    return null;
  }
  let value = sessionStorage.getItem(itemName);
  return value;
};

const saveToStorage = (itemName, value, stringify) => {
  if (typeof Storage === "undefined") {
    return;
  }
  if (!value) {
    localStorage.removeItem(itemName);
  } else {
    if (stringify) {
      value = JSON.stringify(value);
    }
    localStorage.setItem(itemName, value);
  }
};

export default {
  loadItem(itemName, defaultValue) {
    let value = loadFromStorage(itemName);
    if (!value) {
      return defaultValue;
    }
    return JSON.parse(value);
  },

  saveItem(itemName, value) {
    saveToStorage(itemName, value, true);
  },

  loadValue(itemName, defaultValue) {
    let value = loadFromStorage(itemName);
    return value || defaultValue;
  },

  saveValue(itemName, value) {
    saveToStorage(itemName, value, false);
  },

  loadSessionItem(itemName, defaultValue) {
    let value = loadFromSession(itemName);
    if (!value) {
      return defaultValue;
    }
    return JSON.parse(value);
  },

  saveSessionItem(itemName, value) {
    saveToSession(itemName, value, true);
  },

  loadSessionValue(itemName, defaultValue) {
    let value = loadFromSession(itemName);
    return value || defaultValue;
  },

  saveSessionValue(itemName, value) {
    saveToSession(itemName, value, false);
  },
};
