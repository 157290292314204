import React from "react";

import {
  ButtonGroup,
  ButtonToolbar,
  FormGroup,
  FormControl,
  ControlLabel,
  Table as BTable,
} from "react-bootstrap";

import { ApiService, DataActions } from "../../services/AxoServices";

import {
  AsyncButton,
  Flexbox,
  Icon,
  LexButton,
  AxoCheckbox,
  FlexElement,
  getText,
  AxoLocal,
  // FlexElement,
  // AxoLocal
} from "../../utilities/LexUtilities";

export default class SigningRequestView extends React.PureComponent {
  constructor(props) {
    super(props);

    let request =
      props.file.signingRequests.length > 0
        ? props.file.signingRequests[0]
        : null;

    this.state = {
      file: props.file,

      validatePhone: !!request && request.validatePhone,
      allowImageSignatures: !!request && request.allowImageSignatures,
      sendEmailConfirmations: !!request && request.sendEmailConfirmations,
      sendFinalDocument: !!request && request.sendFinalDocument,

      signers: !!request
        ? []
        : [
            {
              guid: "",
              email: "",
              phone: "",
            },
          ],

      subject: `Du har modtaget ${props.file.fileName} til signering.`,
      message: ``,

      callback: (result) => {},
    };
  }

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  addSigner = () => {
    this.setState((prevState) => ({
      signers: [
        ...prevState.signers,
        {
          email: "",
          phone: "",
        },
      ],
    }));
  };

  removeSigner = (index) => {
    this.setState((prevState) => ({
      signers: prevState.signers
        .slice(0, index)
        .concat(prevState.signers.slice(index + 1)),
    }));
  };

  updateSigner = (newSigner, newIndex) => {
    this.setState((prevState) => ({
      signers: prevState.signers.map((signer, index) => {
        if (index === newIndex) {
          return newSigner;
        }

        return signer;
      }),
    }));
  };

  createSigningRequest = async () => {
    let {
      file,
      signers,
      subject,
      validatePhone,
      allowImageSignatures,
      sendEmailConfirmations,
      sendFinalDocument,
    } = this.state;

    if (!this.checkValidData()) {
      return;
    }

    let newSignerMails = signers.map((s) => s.email);

    let request;
    if (file.signingRequests.length === 0) {
      let response = await DataActions.createSigningRequest({
        fileInfoModelId: file.id,
        signers,
        validatePhone,
        allowImageSignatures,
        sendEmailConfirmations,
        sendFinalDocument,
      });

      request = await response.json();
    } else {
      request = file.signingRequests[0];

      request = {
        ...request,
        signers: request.signers.concat(signers),
      };

      let response = await DataActions.updateSigningRequest(request);
      if (!response.ok) {
        return false;
      }

      request = await response.json();
    }

    let link =
      window.location.protocol +
      "//" +
      window.location.host +
      "/Signing/" +
      request.guid;
    //Needed because the guid is set on the server
    let newSigners = request.signers.filter(
      (s) => !!newSignerMails.find((e) => s.email === e)
    );
    let promises = newSigners.map((signer) => {
      let currentLink = link + "?signer=" + signer.guid;

      let fullMessage = this.generateFullMessage(currentLink);

      return ApiService.sendCompanyEmail({
        fromName: "Axolex EasySign System",
        to: signer.email,
        subject,
        body: fullMessage,
      });
    });

    let responses = await Promise.all(promises);

    this.updateSigningRequestState(request);

    return !responses.find((r) => !r.ok);
  };

  updateSigningRequestState = (newRequest) => {
    let { file } = this.state;

    if (file.signingRequests.length === 0) {
      this.setState((prevState) => ({
        file: {
          ...prevState.file,
          signingRequests: prevState.file.signingRequests.concat([newRequest]),
        },
        signers: [],
      }));
    }
    //Update existing request
    else {
      this.setState((prevState) => ({
        file: {
          ...prevState.file,
          signingRequests: prevState.file.signingRequests.map((r) => {
            if (r.id === newRequest.id) {
              return newRequest;
            }
            return r;
          }),
        },
        signers: [],
      }));
    }
  };

  onChangeRequestFlag = (event, flagName) => {
    let { file } = this.state;

    if (file.signingRequests.length > 0) {
      return;
    }

    this.setState({ [flagName]: event.target.checked });
  };

  checkValidData = () => {
    let { subject, signers, validatePhone } = this.state;

    if (!subject || signers.length === 0) {
      return false;
    }

    for (let i = 0; i < signers.length; i++) {
      let signer = signers[i];

      if (!signer.email || !this.validateEmail(signer.email)) {
        return false;
      }
      if (validatePhone && !signer.phone) {
        return false;
      }
    }

    return true;
  };

  sendSigningLink = async (signer) => {
    let { file, subject } = this.state;

    let signingRequest =
      file.signingRequests.length > 0 ? file.signingRequests[0] : null;
    if (!signingRequest) {
      return false;
    }

    let link =
      window.location.protocol +
      "//" +
      window.location.host +
      "/Signing/" +
      signingRequest.guid;
    let currentLink = link + "?signer=" + signer.guid;

    let fullMessage = this.generateFullMessage(currentLink);

    let response = await ApiService.sendCompanyEmail({
      fromName: "Axolex EasySign System",
      to: signer.email,
      subject,
      body: fullMessage,
    });

    return response.ok;
  };

  generateFullMessage = (currentLink) => {
    let { file, message, allowImageSignatures } = this.state;
    //  <div style='padding: 25px; line-height: 50px; background: white; font-size: 22px; color: #005380; max-width: 500px;'>
    //   Kære ${name},
    // </div>
    // ${getText('dasdasdas')}

    // MissingEntity
    let fullMessage = `
        <div style='padding: 25px; line-height: 20px; background: #eaecec; font-size: 17px; color: #000000; max-width: 500px;'>
          Du har modtaget ${file.fileName} til signatur.
        </div>
        ${
          !!message
            ? `
          <div style='padding: 25px; line-height: 20px; margin-bottom:5px; background: #eaecec; font-size: 17px; color: #000000; max-width: 500px;'>
            ${message}
          </div>
        `
            : ``
        }
        ${
          allowImageSignatures
            ? `
          <div style='padding: 25px; line-height: 50px; background: white; font-size: 22px; color: #005380; max-width: 500px;'>
            Du behøves ikke at have MitID. Du kan også tegne din signatur direkte på skærmen. 
          </div>
        `
            : ``
        }
        <div style='padding: 25px; line-height: 20px; margin-bottom:5px; background: #005380; font-size: 17px; color: #ffffff; max-width: 300px;'>
        <a style='color: white;' href=${currentLink}>Klik her for at underskrive dokumentet</a>
        </div>
        <div style='padding: 25px; line-height: 50px; background: white; font-size: 22px; color: #005380; max-width: 500px;'>
          Axolex Digitalt Signatursystem
        </div>
        <div style='padding: 25px; margin-bottom:5px; line-height: 20px; background:#eaecec; font-size: 17px; color: #000000; max-width: 500px;'>
          <div>Axolex Signatursystemet er en digital løsning baseret på MitID.</div>
          <div>Axolex tilbyder MitID signatur og anvender Globalsigns certifikater til forsegling af PAdES dokumentet.</div>
          <div>© Powered By Axolex</div>
      	</div>`;

    return fullMessage;
  };

  deleteSigner = async (signer) => {
    let { file } = this.state;

    if (signer.signed) {
      return false;
    }

    let signingRequest =
      file.signingRequests.length > 0 ? file.signingRequests[0] : null;
    if (!signingRequest) {
      return false;
    }

    let response = await DataActions.updateSigningRequest({
      ...signingRequest,
      signers: signingRequest.signers.filter((s) => s.id !== signer.id),
    });

    if (response.ok) {
      let newRequest = await response.json();
      this.updateSigningRequestState(newRequest);
    }

    return response.ok;
  };

  render() {
    let { showSending, showCardWarning, showSubscriptionWarning } = this.props;

    let {
      file,
      subject,
      message,
      signers,
      validatePhone,
      allowImageSignatures,
      sendEmailConfirmations,
      sendFinalDocument,
    } = this.state;

    let request =
      file.signingRequests.length > 0 ? file.signingRequests[0] : null;

    let ready = this.checkValidData();

    let completedSignings =
      !!request && !!request.signers
        ? request.signers.filter((s) => s.signed)
        : [];
    let linkTarget =
      window.location.protocol +
      "//" +
      window.location.host +
      "/Signing/" +
      (!!request ? request.guid : "");

    return (
      <div className="standardMaxWidth center-block">
        <h4>
          <strong>Signer dokumentet:</strong> {file.fileName}
        </h4>
        <div>
          {signers.map((signer, index) => {
            let validMail = this.validateEmail(signer.email);
            return (
              <div
                className="topPadding bottomPadding"
                key={signer.id || `New-${index}`}
              >
                <Flexbox>
                  <FlexElement flexGrow={1}>
                    <FormGroup controlId="subject" className="flexbox">
                      <ControlLabel
                        className="rightPadding"
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <b>
                          <AxoLocal
                            entity="CaseEditFormeMail"
                            defaultValue={"Email"}
                          />
                        </b>
                      </ControlLabel>
                      <FormControl
                        autoFocus
                        type="email"
                        // className='borderFormControlfocus'
                        style={
                          !!signer.email && !validMail
                            ? { border: "1px solid red" }
                            : {}
                        }
                        value={signer.email}
                        onChange={(event) =>
                          this.updateSigner(
                            { ...signer, email: event.target.value },
                            index
                          )
                        }
                        // entity={ singleName ? 'axoAccounting7' : 'placeholderfirstName' }
                      />
                    </FormGroup>
                  </FlexElement>
                  <FlexElement flexGrow={1}>
                    <FormGroup controlId="phone" className="flexbox">
                      <ControlLabel
                        className="leftPadding rightPadding"
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <b>
                          <AxoLocal
                            entity="ContainerPhone"
                            defaultValue={"Telefon"}
                          />
                        </b>
                      </ControlLabel>
                      <FormControl
                        type="text"
                        disabled={!validatePhone}
                        style={
                          validatePhone && !signer.phone
                            ? { border: "1px solid red" }
                            : {}
                        }
                        className="borderFormControlfocus"
                        value={signer.phone}
                        onChange={(event) =>
                          this.updateSigner(
                            { ...signer, phone: event.target.value },
                            index
                          )
                        }
                        // entity={ singleName ? 'axoAccounting7' : 'placeholderfirstName' }
                      />
                      <Icon
                        className="leftPadding"
                        glyph="icon-fontello-trash"
                        role="button"
                        onClick={() => this.removeSigner(index)}
                      />
                    </FormGroup>
                  </FlexElement>
                </Flexbox>
                {!!signer.email && !validMail && (
                  <div className="axored">
                    <AxoLocal
                      entity="axoEntityidcode225"
                      defaultValue={"Indtast gyldig mail."}
                    />
                  </div>
                )}
              </div>
            );
          })}
          {showSending && (
            <>
              <Flexbox>
                <FlexElement flexGrow={1}></FlexElement>
                <FlexElement
                  title={getText(
                    "axoEntityidcode226",
                    "Når denne mulighed er slået til, har brugerne mulighed for at underskrive ved at tegne en underskrift, i stedet for at anvende MitID."
                  )}
                  className="rightPadding"
                >
                  <AxoCheckbox
                    checked={allowImageSignatures}
                    onChange={(event) =>
                      this.onChangeRequestFlag(event, "allowImageSignatures")
                    }
                  />
                  &nbsp;
                  <span style={{ color: "#497589" }}>
                    <AxoLocal
                      entity="axoEntityidcode224"
                      defaultValue={"Tegnet underskrift"}
                    />
                    &nbsp;
                    <Icon glyph="icon-fontello-question" />
                  </span>
                </FlexElement>
                <FlexElement
                  title={getText(
                    "axoEntityidcode227",
                    "Når telefonvalidering er slået til vil brugerne få tilsendt en to-faktor kode før de får mulighed for at se dokumentet."
                  )}
                >
                  <AxoCheckbox
                    checked={validatePhone}
                    onChange={(event) =>
                      this.onChangeRequestFlag(event, "validatePhone")
                    }
                  />
                  &nbsp;
                  <span style={{ color: "#497589" }}>
                    <AxoLocal
                      entity="axoEntityidcode223"
                      defaultValue={"Telefonvalidering"}
                    />
                    &nbsp;
                    <Icon glyph="icon-fontello-question" />
                  </span>
                </FlexElement>
              </Flexbox>
              <FlexElement>
                <LexButton onClick={this.addSigner}>
                  <AxoLocal
                    entity="axoEntityidcode222"
                    defaultValue={"Tilføj flere modtagere"}
                  />
                </LexButton>
              </FlexElement>
              <FormGroup controlId="subject">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <b>
                    <AxoLocal
                      entity="axoEntityidcode200a"
                      defaultValue={"Emnefelt på email"}
                    />
                  </b>
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder={"Du har modtaget et dokument til signering"}
                  value={subject}
                  onChange={(event) =>
                    this.setState({ subject: event.target.value })
                  }
                />
              </FormGroup>
              <FormGroup controlId="description">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <b>{getText("axoEntityidcode198", "Besked")}</b>
                </ControlLabel>
                <FormControl
                  componentClass="textarea"
                  placeholder="Valgfri besked"
                  rows={3}
                  value={message}
                  onChange={(event) =>
                    this.setState({ message: event.target.value })
                  }
                />
              </FormGroup>
              <div
                className="bottomPadding"
                title={getText(
                  "axoEntityidcode228",
                  'Når denne mulighed er slået til, vil der blive sendt en mail til din registrerede email adresse ved hver ny underskrift. Vælg "Opsætning" i rullegardinet for at registrere din email.'
                )}
              >
                <AxoCheckbox
                  checked={sendEmailConfirmations}
                  onChange={(event) =>
                    this.onChangeRequestFlag(event, "sendEmailConfirmations")
                  }
                />
                &nbsp;
                <span style={{ color: "#497589" }}>
                  <AxoLocal
                    entity="axoEntityidcode221"
                    defaultValue={" Send email ved signering"}
                  />
                  &nbsp;
                  <Icon glyph="icon-fontello-question" />
                </span>
              </div>
              <div
                className="bottomPadding"
                title={
                  "Når alle har underskrevet, vil alle underskriverne automatisk modtage det underskrevne dokument via mail."
                }
              >
                <AxoCheckbox
                  checked={sendFinalDocument}
                  onChange={(event) =>
                    this.onChangeRequestFlag(event, "sendFinalDocument")
                  }
                />
                &nbsp;
                <span style={{ color: "#497589" }}>
                  Send underskrevet dokument til alle. &nbsp;
                  <Icon glyph="icon-fontello-question" />
                </span>
              </div>
            </>
          )}
          {!!request && (
            <>
              {!!request.signers && request.signers.length > 0 && (
                <>
                  <div>
                    <span></span>
                    <AxoLocal
                      entity="axoEntityidcode220"
                      defaultValue={"Anmodningen er sendt til signering hos:"}
                    />
                    <BTable striped>
                      <thead>
                        <tr>
                          <td>
                            <AxoLocal
                              entity="CaseEditFormeMail"
                              defaultValue={"Email"}
                            />
                          </td>
                          <td>
                            <AxoLocal
                              entity="ContainerPhone"
                              defaultValue={"Telefon"}
                            />
                          </td>
                          <td>
                            <AxoLocal
                              entity="CalendarEventFormbookingLink"
                              defaultValue={" Link:"}
                            />
                            :
                          </td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody className="axoblue">
                        {request.signers.map((signer) => (
                          <tr key={signer.email + signer.phone}>
                            <td>{signer.email}</td>
                            <td>{signer.phone}</td>
                            <td>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={linkTarget + "?signer=" + signer.guid}
                              >
                                Link
                              </a>
                            </td>
                            <td>
                              <AsyncButton
                                title={getText(
                                  "axoEntityidcode229",
                                  "Gensend signeringslink"
                                )}
                                onClick={() => this.sendSigningLink(signer)}
                              >
                                <Icon glyph="icon-fontello-mail" />
                              </AsyncButton>
                              {!signer.signed && (
                                <>
                                  &nbsp;
                                  <AsyncButton
                                    title={getText(
                                      "axoEntityidcode230",
                                      "Fjern signeringsanmdoning"
                                    )}
                                    onClick={() => this.deleteSigner(signer)}
                                  >
                                    <Icon glyph="icon-fontello-trash" />
                                  </AsyncButton>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </BTable>
                  </div>
                  <div>
                    <AxoLocal
                      entity="axoEntityidcode219"
                      defaultValue={"Anmodningen er signeret af"}
                    />
                    &nbsp;{completedSignings.length} / {request.signers.length}
                    <BTable striped>
                      <thead>
                        <tr>
                          <td>
                            <AxoLocal
                              entity="CaseEditFormeMail"
                              defaultValue={"Email"}
                            />
                          </td>
                          <td>
                            <AxoLocal
                              entity="ContainerPhone"
                              defaultValue={"Telefon"}
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody className="axogreen">
                        {completedSignings.map((signer) => (
                          <tr key={signer.email + signer.phone}>
                            <td>{signer.email}</td>
                            <td>{signer.phone}</td>
                          </tr>
                        ))}
                      </tbody>
                    </BTable>
                  </div>
                </>
              )}
              {/* { !validatePhone && (
                <div className='text-left'>
                  Signeringslink:&nbsp;
                  <FormControl 
                    value={linkTarget}
                  />
                </div>
              )} */}
            </>
          )}
        </div>
        <div>
          <ButtonToolbar>
            <ButtonGroup>
              {showSending && (
                <AsyncButton
                  onClick={this.createSigningRequest}
                  disabled={
                    !ready || showCardWarning || showSubscriptionWarning
                  }
                  title={
                    !ready
                      ? getText(
                          "axoEntityidcode231",
                          "Du skal tilføje flere modtagere før du kan sende igen."
                        )
                      : ""
                  }
                >
                  <AxoLocal
                    entity="axoEntityidcode218"
                    defaultValue={"Send til signering"}
                  />
                </AsyncButton>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        </div>
        {showSubscriptionWarning && (
          <div className="mediumText axored">
            Du skal vælge et abonnement på opsætningssiden.
          </div>
        )}
        {showCardWarning && (
          <div className="mediumText axored">
            Du skal vælge et betalingskort på opsætningssiden.
          </div>
        )}
      </div>
    );
  }
}
