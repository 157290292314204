import React from "react";
import { debounce } from "lodash";
import Dropzonejs from "../../utilities/Dropzonejs";
import TinyMCEEditor from "../../utilities/Editors/TinyMCEEditor";
import ContactCreateView from "../Contacts/ContactCreateView";
import { ctx } from "../../utilities/L20n/L20n";
import ReactOption from "../../utilities/ReactOption";
import CaseSharing from "./CaseSharing";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { CountryDropdown } from "../../utilities/CountrySelect/Dropdowns";

import {
  ApiService,
  DataStore,
  DataActions,
  ModalService,
  AuthorizationService,
} from "../../services/AxoServices";

import {
  ClientSearchBox,
  Icon,
  AxoLocal,
  FlexElement,
  getText,
} from "../../utilities/LexUtilities";

import {
  Row,
  Col,
  Grid,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  InputGroup,
} from "react-bootstrap";

class CaseEditForm extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.openCase) {
      this.state = {
        caseViewModel: props.openCase,
        caseInfoEnabled: false,
        clientsEnabled: false,
        counterPartiesEnabled: false,
      };
    } else {
      this.state = {
        caseViewModel: {
          caseNumber: "",
          time: 0,
          type: "",
          subject: "",
          description: "<html></html>", //Valid HTML description is required
          internalNumber: "",
          field: "",
          reference: "",
          notes: "",
          statusMessage: ctx.getSync("CaseEditInlinestatusMessage"),
          documents: [],
          status: "Draft",
          closed: false,
          actionDate: new Date(0),
          clients: [],
          counterParties: [],
          caseVersions: [],
        },
        caseInfoEnabled: true,
        clientsEnabled: true,
        counterPartiesEnabled: true,
        showMissingNameWarning: false,
        versionTitle: "",
        creatingVersion: false,
      };
    }
    this.state.creatingClientIndex = -1;
    this.state.showSharingUI = false;
    this.state.showEditor = false;
    this.inProgress = true;
  }

  componentDidMount() {
    //Delay rendering of editor
    this.setState({
      showEditor: true,
    });
  }

  onCasePropertyChange = (propertyName, value) => {
    let caseViewModel = { ...this.state.caseViewModel };
    caseViewModel[propertyName] = value;
    this.handleModelChanges(caseViewModel);
  };

  onCounterPropertyChange = (counterPartyIndex, propertyName, value) => {
    var caseViewModel = { ...this.state.caseViewModel };
    var counterParties = caseViewModel.counterParties.slice();
    var counterParty = { ...counterParties[counterPartyIndex] };
    counterParty[propertyName] = value;
    counterParties[counterPartyIndex] = counterParty;
    caseViewModel.counterParties = counterParties;
    this.handleModelChanges(caseViewModel);
  };

  //Counterparty information
  // onCounterPropertyChange( counterPartyIndex, propertyName, event ) {
  //   var counterParty = this.state.caseViewModel.counterParties[counterPartyIndex];
  //   counterParty[propertyName] = event.target.value;
  //   this.handleModelChanges();
  // }

  handleModelChanges = (caseViewModel) => {
    this.setState({ caseViewModel }, () => {
      this.props.onEditCase();
      this.debouncedSave();
    });
  };

  onSaveClicked = () => {
    this.saveChanges(false);
  };

  onSaveAndCloseClicked = () => {
    this.saveChanges(true);
  };

  onFinishDraft = () => {
    if (!this.state.caseViewModel.caseNumber) {
      this.setState({
        showMissingNameWarning: true,
      });
      return;
    }
    var caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.status = "Active";
    this.setState({ caseViewModel }, () => {
      this.saveChanges(false, (newCaseId) => this.onFinishedDraft(newCaseId));
    });
  };

  onFinishedDraft = (newCaseId) => {
    DataStore.fetchCases();
    if (!!this.props.onFinishDraft) {
      this.props.onFinishDraft(newCaseId);
    }
  };

  onToggleClose = () => {
    if (!this.state.caseViewModel.caseNumber) {
      this.setState({ showMissingNameWarning: true });
      return;
    }
    var caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.closed = !this.state.caseViewModel.closed;
    this.setState({ caseViewModel }, () => {
      this.saveChanges(true);
    });
  };

  onToggleDelete = () => {
    if (!this.state.caseViewModel.caseNumber) {
      this.setState({ showMissingNameWarning: true });
      return;
    }
    var caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.trashed = !this.state.caseViewModel.trashed;
    this.setState({ caseViewModel }, () => {
      this.saveChanges(true);
    });
  };

  onPermanentDelete = () => {
    var viewModel = this.state.caseViewModel;
    if (viewModel.documents.length > 0) {
      ModalService.openConfirmModal(
        <AxoLocal
          entity="CaseEditFormConfirm"
          defaultValue={"Vil du også slette sagens bilag?"}
        />,
        (value) => {
          if (value) {
            this.deleteCasePermanently({ id: viewModel.id, deleteFiles: true });
          } else {
            this.deleteCasePermanently({
              id: viewModel.id,
              deleteFiles: false,
            });
          }
        }
      );
    } else {
      this.deleteCasePermanently({ id: viewModel.id, deleteFiles: false });
    }
  };

  deleteCasePermanently = (options) => {
    DataActions.deleteCase(options)
      .then((response) => {
        if (response.status === 200) {
          this.props.onClose();
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="CaseEditFormnodeleteCaseoptions"
              defaultValue={"Sagen kunne ikke slettes. Prøv igen."}
            />
          );
        }
      })
      .catch(this.handleServerError);
  };

  debouncedSave = debounce(() => {
    this.saveChanges(false);
  }, 500);

  saveChanges = (closeOnSuccess, callback) => {
    if (!this.state.caseViewModel.caseNumber) {
      this.setState({ showMissingNameWarning: true });
      return;
    }
    this.inProgress = false;
    if (!this.state.caseViewModel.id) {
      this.createNewCase(closeOnSuccess, callback);
    } else {
      this.updateCase(closeOnSuccess, callback);
    }
  };

  createNewCase = (closeOnSuccess, callback) => {
    var newCase = this.state.caseViewModel;
    DataActions.createCase(newCase)
      .then(this.handleCaseResponse.bind(this, closeOnSuccess, callback))
      .catch(this.handleServerError);
  };

  updateCase = (closeOnSuccess, callback) => {
    var currentCase = this.state.caseViewModel;
    DataActions.updateCase(currentCase)
      .then(this.handleCaseResponse.bind(this, closeOnSuccess, callback))
      .catch(this.handleServerError);
  };

  handleCaseResponse = (closeOnSuccess, callback, response) => {
    if (response.status === 200) {
      response.json().then((caseModel) => {
        this.setState({
          caseViewModel: caseModel,
          showMissingNameWarning: false,
        });
        if (closeOnSuccess) {
          this.props.onClose();
        }
        if (callback) {
          callback(this.state.caseViewModel.id);
        } else {
          // Messenger().post({
          //   id: 'success',
          //   type: 'success',
          //   singleton: false,
          //   message: l20n.ctx.getSync('CaseEditInlineuploadResponse'),
          //   hideAfter: 1
          // });
        }
      });
    } else {
      ModalService.openAlertModal(
        <AxoLocal
          entity="CaseEditFormThecasecouldnotbesaved"
          defaultValue={"Sagen kunne ikke gemmes. Prøv igen."}
        />
      );
      this.setState({ creatingVersion: false });
    }
  };

  handleServerError = (reason) => {
    ModalService.openAlertModal(
      <AxoLocal
        entity="CaseEditFormThecasecouldnotbesaved"
        defaultValue={"Sagen kunne ikke gemmes. Prøv igen senere"}
      />
    );
    this.setState({ creatingVersion: false });
  };

  onDiscardCase = () => {
    this.inProgress = false;
    this.props.onClose();
  };

  onBackClick = () => {
    this.props.history.goBack();
  };

  onSave = () => {
    var draftCase = this.state.caseViewModel;
    draftCase.status = "Draft";
    ApiService.createCase(draftCase);
    this.inProgress = false;
    this.props.onClose();
  };

  attachFiles = (e) => {
    this.setState({ files: e.target.files });
  };

  onAddClient = () => {
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        clients: [...prevState.caseViewModel.clients, {}],
      },
    }));
  };

  onAddCounterParty = () => {
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        counterParties: [
          ...prevState.caseViewModel.counterParties,
          {
            firstName: "",
            lastName: "",
            address: "",
            eMail: "",
            phone: "",
            type: 1,
          },
        ],
      },
    }));
  };

  onRemoveClient = (clientIndex) => {
    let { caseViewModel } = this.state;
    let clients = this.state.caseViewModel.clients.slice();
    clients.splice(clientIndex, 1);
    this.handleModelChanges({
      ...caseViewModel,
      clients,
    });
  };

  onRemoveCounterParty = (counterPartyIndex) => {
    let { caseViewModel } = this.state;
    let counterParties = this.state.caseViewModel.counterParties.slice();
    counterParties.splice(counterPartyIndex, 1);
    this.handleModelChanges({
      ...caseViewModel,
      counterParties,
    });
  };

  onPrint = () => {
    window.print();
  };

  renderCounterPartyNodes = () => {
    let counterPartyNodes = [];
    for (let i = 0; i < this.state.caseViewModel.counterParties.length; i++) {
      counterPartyNodes.push(
        <Row key={i}>
          <div style={{ paddingBottom: "10px" }}>
            <span
              className="axolextextbanne Bannecolor"
              role="button"
              onClick={this.onRemoveCounterParty.bind(this, i)}
            >
              <Icon
                role="button"
                glyph="icon-fontello-scissors axored"
                style={{ textAlign: "right" }}
              />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <AxoLocal
                entity="CaseEditFormRemoveCounterParty"
                defaultValue={"Slet modpart"}
              />
            </span>
          </div>
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="RditInlinecounterPartyInfoHeader"
              defaultValue="Modparternes kontaktinformation"
            />
          </ControlLabel>
          <Col md={6}>
            <Form horizontal style={{ marginBottom: 25 }} autoComplete="on">
              <FormGroup bsSize="large">
                <Col
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={2}
                >
                  <AxoLocal
                    entity="CaseEditFormCounterPartyfirstName"
                    defaultValue={"Fornavn"}
                  />
                </Col>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    value={this.state.caseViewModel.counterParties[i].firstName}
                    onChange={(event) =>
                      this.onCounterPropertyChange(
                        i,
                        "firstName",
                        event.target.value
                      )
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup bsSize="large">
                <Col
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={2}
                >
                  <AxoLocal
                    entity="CaseEditFormlastName"
                    defaultValue={"Efternavn"}
                  />
                </Col>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    value={this.state.caseViewModel.counterParties[i].lastName}
                    onChange={(event) =>
                      this.onCounterPropertyChange(
                        i,
                        "lastName",
                        event.target.value
                      )
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup bsSize="large">
                <Col
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={2}
                >
                  <span>
                    <Icon glyph="icon-fontello-location-4" />
                    &nbsp;
                    <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
                  </span>
                </Col>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    value={this.state.caseViewModel.counterParties[i].address}
                    onChange={(event) =>
                      this.onCounterPropertyChange(
                        i,
                        "address",
                        event.target.value
                      )
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup bsSize="large">
                <Col
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={2}
                >
                  <span>
                    <Icon glyph="icon-fontello-direction-1" />
                    &nbsp;
                    <AxoLocal
                      entity="CaseEditFormpostalCode"
                      defaultValue={"Postnummer"}
                    />
                  </span>
                </Col>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    value={
                      this.state.caseViewModel.counterParties[i].postalCode
                    }
                    onChange={(event) =>
                      this.onCounterPropertyChange(
                        i,
                        "postalCode",
                        event.target.value
                      )
                    }
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <Col md={6}>
            <Form horizontal style={{ marginBottom: 25 }} autoComplete="on">
              <FormGroup bsSize="large">
                <Col
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={2}
                >
                  <span>
                    <Icon glyph="icon-fontello-warehouse" />
                    &nbsp;
                    <AxoLocal entity="CaseEditFormcity" defaultValue={"By"} />
                  </span>
                </Col>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    value={this.state.caseViewModel.counterParties[i].city}
                    onChange={(event) =>
                      this.onCounterPropertyChange(
                        i,
                        "city",
                        event.target.value
                      )
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup bsSize="large">
                <Col
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={2}
                >
                  <span>
                    <Icon glyph="icon-fontello-globe-6" />
                    &nbsp;
                    <AxoLocal
                      entity="CaseEditFormcountry"
                      defaultValue={"Land"}
                    />
                  </span>
                </Col>
                <Col sm={10}>
                  <CountryDropdown
                    value={
                      this.state.caseViewModel.counterParties[i].country || ""
                    }
                    valueType="short"
                    customSelect
                    large
                    onChange={(country) =>
                      this.onCounterPropertyChange(i, "country", country)
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup bsSize="large">
                <Col
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={2}
                >
                  <span>
                    <Icon glyph="icon-fontello-at-2" />
                    &nbsp;
                    <AxoLocal
                      entity="CaseEditFormeMail"
                      defaultValue={"Email"}
                    />
                  </span>
                </Col>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    value={this.state.caseViewModel.counterParties[i].eMail}
                    onChange={(event) =>
                      this.onCounterPropertyChange(
                        i,
                        "eMail",
                        event.target.value
                      )
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup bsSize="large">
                <Col
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={2}
                >
                  <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />
                </Col>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    value={this.state.caseViewModel.counterParties[i].phone}
                    onChange={(event) =>
                      this.onCounterPropertyChange(
                        i,
                        "phone",
                        event.target.value
                      )
                    }
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      );
    }
    return counterPartyNodes;
  };

  handleSelectedClient = (index, client) => {
    let caseViewModel = { ...this.state.caseViewModel };
    var clients = caseViewModel.clients.slice();
    clients[index] = client;
    caseViewModel.clients = clients;
    this.setState({ caseViewModel }, () => {
      this.saveChanges(false);
    });
  };

  onCaseDateChange = (event) => {
    let caseViewModel = { ...this.state.caseViewModel };
    caseViewModel.actionDate = moment(event.target.valueAsDate).format();
    caseViewModel.reminded = false;

    this.handleModelChanges(caseViewModel);
  };

  removeDeadlineFromCase = () => {
    let caseViewModel = { ...this.state.caseViewModel };
    caseViewModel.actionDate = new Date(0);
    this.handleModelChanges(caseViewModel);
  };

  addDeadlineToCase = () => {
    let caseViewModel = { ...this.state.caseViewModel };
    var newDate = new Date();
    newDate.setTime(newDate.getTime() + 86400000);
    caseViewModel.actionDate = newDate;
    caseViewModel.reminded = false;
    this.handleModelChanges(caseViewModel);
  };

  renderDeadlineField = (caseModel) => {
    var deadlineMoment = moment(caseModel.actionDate);
    if (deadlineMoment.year() > 1970) {
      return (
        <div style={{ display: "inline-block" }}>
          <input
            style={{ borderStyle: "none" }}
            type="date"
            onChange={this.onCaseDateChange}
            value={deadlineMoment.format("YYYY-MM-DD")}
          />
          &nbsp;&nbsp;
          <Icon
            onClick={this.removeDeadlineFromCase}
            glyph="icon-fontello-scissors axored"
          />
        </div>
      );
    }
    return (
      <Icon onClick={this.addDeadlineToCase} glyph="icon-fontello-calendar-6" />
    );
  };

  onCreateClient = (index) => {
    this.setState({ creatingClientIndex: index });
  };

  onChangeClient = (index) => {
    let caseViewModel = { ...this.state.caseViewModel };
    var clients = caseViewModel.clients.slice();
    clients[index] = {};
    caseViewModel.clients = clients;
    this.setState({ caseViewModel });
  };

  renderClientField = (index) => {
    var { contactMap } = this.props;

    var client = this.state.caseViewModel.clients[index];
    return (
      <div key={index}>
        <FormGroup>
          <Col
            componentClass={ControlLabel}
            style={{ textAlign: "left", paddingLeft: "25px" }}
            sm={3}
          >
            <span>
              <Icon glyph="icon-fontello-user-7" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="CaseEditFormclientsAB"
                  defaultValue={"Klient"}
                />
              </b>{" "}
            </span>{" "}
          </Col>
          {!client || !client.id ? (
            <div>
              <Col sm={4}>
                <ClientSearchBox
                  clients={contactMap.contacts}
                  count={contactMap.count}
                  handleSelectedClient={this.handleSelectedClient.bind(
                    this,
                    index
                  )}
                  disabled={false}
                />
              </Col>
              <Col xs={5} className="text-right">
                <span>
                  <Button
                    className="Lex-button-2"
                    onClick={this.onCreateClient.bind(this, index)}
                  >
                    <AxoLocal
                      entity="CaseEditFormaddnewclients"
                      defaultValue={"Opret ny klient"}
                    />
                  </Button>
                </span>
              </Col>
            </div>
          ) : (
            <div>
              <Col sm={4}>{client.firstName + " " + client.lastName}</Col>
              <Col sm={4}>
                <Button
                  className="Lex-button-2"
                  onClick={this.onChangeClient.bind(this, index)}
                >
                  <AxoLocal
                    entity="CaseEditFormchangeclients"
                    defaultValue={"Skift klient"}
                  />
                </Button>
              </Col>
            </div>
          )}
          {index > 0 ? (
            <Col xs={2}>
              <span
                role="button"
                onClick={this.onRemoveClient.bind(this, index)}
              >
                <Icon
                  role="button"
                  glyph="icon-fontello-scissors axored"
                  style={{ textAlign: "left" }}
                />{" "}
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseEditFormRemoveClient"
                  defaultValue={"Slet klient"}
                />
              </span>
            </Col>
          ) : null}
        </FormGroup>
      </div>
    );
  };

  renderClientFields = () => {
    var clientNodes = [];
    var clients = this.state.caseViewModel.clients;
    if (clients.length === 0) {
      clientNodes.push(this.renderClientField(0));
      return clientNodes;
    }
    for (var i = 0; i < clients.length; i++) {
      clientNodes.push(this.renderClientField(i));
    }
    return clientNodes;
  };

  onGenerateInternalNumber = () => {
    // let { caseViewModel } = this.state;
    // let internalNumber = moment.utc().year();

    // if(caseViewModel.clients.length > 0) {

    // }

    var caseViewModel = Object.assign({}, this.state.caseViewModel);

    var today = new Date();
    caseViewModel.internalNumber =
      today.getDate().toString() + (today.getMonth() + 1).toString();
    caseViewModel.internalNumber += Math.floor(Math.random() * 10000);
    caseViewModel.internalNumber += today.getFullYear();

    this.setState({ caseViewModel });
  };

  onCaseFieldChange = (event) => {
    var caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.field = event.target.value;
    this.setState({ caseViewModel });
  };

  renderFieldSelect = () => {
    let { userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);

    if (userType === "Accountant") {
      return (
        <FormControl
          value={this.state.caseViewModel.field}
          onChange={this.onCaseFieldChange}
          componentClass="select"
        >
          <option value="Skatte- og momssag enkl.">
            Skatte- og momssag enkl.
          </option>
          <option value="Skatte- og momssag selskaber">
            Skatte- og momssag selskaber
          </option>
          <option value="Skattesag privatpersoner">
            Skattesag privatpersoner
          </option>
          <option value="Kontraktsager">Kontraktsager</option>
          <option value="Konkurssager">Konkurssager</option>
          <option value="Klage">Klage</option>
          <option value="Gæld">Gæld</option>
        </FormControl>
      );
    }

    return (
      <FormControl
        value={this.state.caseViewModel.field}
        onChange={this.onCaseFieldChange}
        componentClass="select"
      >
        <option value="Vælg sagsområde">Vælg sagsområde</option>
        <option value="Aftaleret">Aftaleret</option>
        <option value="Arbejdsret">Arbejdsret</option>
        <option value="Adoption">Adoption</option>
        <option value="Bioret">Bioret</option>
        <option value="Børskriminalitet">Børskriminalitet</option>
        <option value="Civilmægling">Civilmægling</option>
        <option value="Civilretlige sager">Civilretlige sager</option>
        <option value="E-handel">E-handel</option>
        <option value="EU-ret">EU-ret</option>
        <option value="EU-udbudsret">EU-udbudsret</option>
        <option value="Entrepriseret">Entrepriseret</option>
        <option value="Erhvervsstrafferet">Erhvervsstrafferet</option>
        <option value="Familie og arveret">Familie og arveret</option>
        <option value="Fast ejendom">Fast ejendom</option>
        <option value="Finansieringsret">Finansieringsret</option>
        <option value="Folkeret">Folkeret</option>
        <option value="Forbrugeret">Forbrugeret</option>
        <option value="Forsikringsret">Forsikringsret</option>
        <option value="Gæld">Gæld</option>
        <option value="Handelsret">Handelsret</option>
        <option value="Håndhævelse">Håndhævelse</option>
        <option value="IT ret">IT ret</option>
        <option value="Immaterialret">Immaterialret</option>
        <option value="Ingeniørkonflikter">Ingeniørkonflikter</option>
        <option value="International">International</option>
        <option value="Kapitalmarkedsret">Kapitalmarkedsret</option>
        <option value="Klage">Klage</option>
        <option value="Kollektiv arbejdsret">Kollektiv arbejdsret</option>
        <option value="Kommunalret">Kommunalret</option>
        <option value="Konfliktmægling">Konfliktmægling</option>
        <option value="Konkursret">Konkursret</option>
        <option value="Kontrakt">Kontrakt</option>
        <option value="Kreditundersøgelse">Kreditundersøgelse</option>
        <option value="Lejeret">Lejeret</option>
        <option value="Lovforslag">Lovforslag</option>
        <option value="Markedsføringsret">Markedsføringsret</option>
        <option value="Medieret">Medieret</option>
        <option value="Miljølovgivning">Miljølovgivning</option>
        <option value="Miljøret">Miljøret</option>
        <option value="Momsret">Momsret</option>
        <option value="Obligationsret">Obligationsret</option>
        <option value="Offentlig ret">Offentlig ret</option>
        <option value="Ophavsret">Ophavsret</option>
        <option value="Patentret">Patentret</option>
        <option value="Persondataret">Persondataret</option>
        <option value="Politiret">Politiret</option>
        <option value="Privatiseringsret">Privatiseringsret</option>
        <option value="Psykiatriret">Psykiatriret</option>
        <option value="Rådgiver">Rådgiver</option>
        <option value="Selskabsret">Selskabsret</option>
        <option value="Selskabsskatteret">Selskabsskatteret</option>
        <option value="Skader">Skader</option>
        <option value="Skatteret">Skatteret</option>
        <option value="Skilsmisseret">Skilsmisseret</option>
        <option value="Specialstrafferet">Specialstrafferet</option>
        <option value="Strafferet">Strafferet</option>
        <option value="Strafferetspleje">Strafferetspleje</option>
        <option value="Sundhedsret">Sundhedsret</option>
        <option value="Søfartslovgivning">Søfartslovgivning</option>
        <option value="Søret">Søret</option>
        <option value="Tingsret">Tingsret</option>
        <option value="Toldkontrol">Toldkontrol</option>
        <option value="Trafikulykke">Trafikulykke</option>
        <option value="Transportret">Transportret</option>
        <option value="Udbudsret">Udbudsret</option>
        <option value="Udlændingeret">Udlændingeret</option>
        <option value="Voldgift">Voldgift</option>
      </FormControl>
    );
  };

  renderUSFieldSelect = () => {
    return (
      <FormControl
        value={this.state.caseViewModel.field}
        onChange={this.onCaseFieldChange}
        componentClass="select"
      >
        <option value="Vælg sagsområde">Vælg engelsk (US)</option>
        <option value="Agreement">Agreement</option>
        <option value="Administrative law">Administrative law</option>
        <option value="Admiralty law">Admiralty law</option>
        <option value="Advertising law">Advertising law</option>
        <option value="Agency law">Agency law</option>
        <option value="Alcohol law">Alcohol law</option>
        <option value="Alternative dispute resolution">
          Alternative dispute resolution
        </option>
        <option value="Animal law">Animal law</option>
        <option value="Antitrust law">Antitrust law</option>
        <option value="Appellate practice">Appellate practice</option>
        <option value="Art law">Art law</option>
        <option value="Aviation law">Aviation law</option>
        <option value="Banking law">Banking law</option>
        <option value="Bankruptcy law">Bankruptcy law</option>
        <option value="Bioethics">Bioethics</option>
        <option value="Business organizations law">
          Business organizations law
        </option>
        <option value="Capital markets law">Capital markets law</option>
        <option value="Civil law or common law">Civil law or common law</option>
        <option value="Commercial law">Commercial law</option>
        <option value="Common Interest Development law">
          Common Interest Development law
        </option>
        <option value="Communications law">Communications law</option>
        <option value="Company law">Company law</option>
        <option value="Computer law">Computer law</option>
        <option value="Constitutional law">Constitutional law</option>
        <option value="Construction law">Construction law</option>
        <option value="Consumer law">Consumer law</option>
        <option value="Contract law">Contract law</option>
        <option value="Copyright law">Copyright law</option>
        <option value="Criminal law">Criminal law</option>
        <option value="Cryptography law">Cryptography law</option>
        <option value="Cultural property law">Cultural property law</option>
        <option value="Custom (law)">Custom</option>
        <option value="Cyber law">Cyber law</option>
        <option value="Defamation">Defamation</option>
        <option value="Derivatives and futures law">
          Derivatives and futures law
        </option>
        <option value="Drug control law">Drug control law</option>
        <option value="Elder law">Elder law</option>
        <option value="Employee benefits law">Employee benefits law</option>
        <option value="Employment law">Employment law</option>
        <option value="Energy law">Energy law</option>
        <option value="Entertainment law">Entertainment law</option>
        <option value="Environmental law">Environmental law</option>
        <option value="Equipment finance law">Equipment finance law</option>
        <option value="Evidence">Evidence</option>
        <option value="FDA law">FDA law</option>
        <option value="Family law">Family law</option>
        <option value="Financial services regulation law">
          Financial services regulation law
        </option>
        <option value="Firearm law">Firearm law</option>
        <option value="Food law">Food law</option>
        <option value="Franchise law">Franchise law</option>
        <option value="Gaming law">Gaming law</option>
        <option value="HOA law">HOA law</option>
        <option value="Health and safety law">Health and safety law</option>
        <option value="Health law">Health law</option>
        <option value="Immigration law">Immigration law</option>
        <option value="Insurance law">Insurance law</option>
        <option value="Intellectual property law">
          Intellectual property law
        </option>
        <option value="International human rights law">
          International human rights law
        </option>
        <option value="International law">International law</option>
        <option value="International trade and finance law">
          International trade and finance law
        </option>
        <option value="Internet law">Internet law</option>
        <option value="Juvenile law">Juvenile law</option>
        <option value="Labour law">Labour law</option>
        <option value="Litigation">Litigation</option>
        <option value="Martial law">Martial law</option>
        <option value="Media law">Media law</option>
        <option value="Mediation & Collaborative Law">
          Mediation & Collaborative Law
        </option>
        <option value="Medical law">Medical law</option>
        <option value="Military law">Military law</option>
        <option value="Mining law">Mining law</option>
        <option value="Mutual funds law">Mutual funds law</option>
        <option value="Nationality law">Nationality law</option>
        <option value="Native American law">Native American law</option>
        <option value="Obscenity law">Obscenity law</option>
        <option value="Oil and gas law">Oil and gas law</option>
        <option value="Parliamentary law">Parliamentary law</option>
        <option value="Patent law">Patent law</option>
        <option value="Poverty law">Poverty law</option>
        <option value="Privacy law">Privacy law</option>
        <option value="Private equity law">Private equity law</option>
        <option value="Private funds law">Private funds law</option>
        <option value="Private international law">
          Private international law
        </option>
        <option value="Procedural law">Procedural law</option>
        <option value="Product liability litigation">
          Product liability litigation
        </option>
        <option value="Property law">Property law</option>
        <option value="Public International Law">
          Public International Law
        </option>
        <option value="Public health law">Public health law</option>
        <option value="Railway law">Railway law</option>
        <option value="Real estate law">Real estate law</option>
        <option value="Sexual Law">Sexual Law</option>
        <option value="Social Security disability law">
          Social Security disability law
        </option>
        <option value="Space law">Space law</option>
        <option value="Sports law">Sports law</option>
        <option value="Statutory law">Statutory law</option>
        <option value="Tax law">Tax law</option>
        <option value="Technology law">Technology law</option>
        <option value="Timber law">Timber law</option>
        <option value="Trademark law">Trademark law</option>
        <option value="Transportation law">Transportation law</option>
        <option value="Trusts and estates law">Trusts and estates law</option>
        <option value="Utilities Regulation">Utilities Regulation</option>
        <option value="Venture capital law">Venture capital law</option>
        <option value="Water law">Water law</option>
      </FormControl>
    );
  };

  renderChineseFieldSelect = () => {
    return (
      <FormControl
        value={this.state.caseViewModel.field}
        onChange={this.onCaseFieldChange}
        componentClass="select"
      >
        <option value="选择">选择</option>
        <option value="保险法">保险法</option>
        <option value="拆迁安置">拆迁安置</option>
        <option value="产品责任">产品责任</option>
        <option value="担保法">担保法</option>
        <option value="动物法">动物法</option>
        <option value="电子商务法">电子商务法</option>
        <option value="担保法">担保法</option>
        <option value="房地产法">房地产法</option>
        <option value="房屋租赁">房屋租赁</option>
        <option value="反不正当竞争">反不正当竞争</option>
        <option value="反倾销">反倾销</option>
        <option value="妇女儿童权益">妇女儿童权益</option>
        <option value="国际法">国际法</option>
        <option value="国际外交法">国际外交法</option>
        <option value="国际税法">国际税法</option>
        <option value="公司法">公司法</option>
        <option value="公民权利">公民权利</option>
        <option value="国家赔偿">国家赔偿</option>
        <option value="国际法类">国际法类</option>
        <option value="国际贸易法">国际贸易法</option>
        <option value="国际仲裁">国际仲裁</option>
        <option value="国际公法">国际公法</option>
        <option value="环境法">环境法</option>
        <option value="海事立法">海事立法</option>
        <option value="婚姻法">婚姻法</option>
        <option value="合同法">合同法</option>
        <option value="互联网金融">互联网金融</option>
        <option value="环境法">环境法</option>
        <option value="交换犯罪">交换犯罪</option>
        <option value="教育法">教育法</option>
        <option value="建筑法">建筑法</option>
        <option value="家庭和继承">家庭和继承</option>
        <option value="集体劳动法">集体劳动法</option>
        <option value="继承法">继承法</option>
        <option value="劳动法">劳动法</option>
        <option value="律师法">律师法</option>
        <option value="媒体法">媒体法</option>
        <option value="民法">民法</option>
        <option value="民事诉讼法">民事诉讼法</option>
        <option value="欧盟法律">欧盟法律</option>
        <option value="欧盟公共采购法">欧盟公共采购法</option>
        <option value="赔偿法">赔偿法</option>
        <option value="破产法">破产法</option>
        <option value="票据法">票据法</option>
        <option value="拍卖法">拍卖法</option>
        <option value="契约法">契约法</option>
        <option value="商业刑法">商业刑法</option>
        <option value="商务法">商务法</option>
        <option value="收养法">收养法</option>
        <option value="损害赔偿">损害赔偿</option>
        <option value="商标法">商标法</option>
        <option value="税法">税法</option>
        <option value="食品安全法">食品安全法</option>
        <option value="司法鉴定">司法鉴定</option>
        <option value="私人基金法">私人基金法</option>
        <option value="特别刑事">特别刑事</option>
        <option value="土地管理法">土地管理法</option>
        <option value="物权法">物权法</option>
        <option value="卫生法">卫生法</option>
        <option value="刑法">刑法</option>
        <option value="刑事司法">刑事司法</option>
        <option value="刑法">刑法</option>
        <option value="宪法">宪法</option>
        <option value="信托法">信托法</option>
        <option value="消费者权益保护法">消费者权益保护法</option>
        <option value="行政法律">行政法律</option>
        <option value="刑事诉讼法">刑事诉讼法</option>
        <option value="行政诉讼法">行政诉讼法</option>
        <option value="行政复议法">行政复议法</option>
        <option value="移民法">移民法</option>
        <option value="银行法">银行法</option>
        <option value="运输法">运输法</option>
        <option value="债务">债务</option>
        <option value="知识产权法">知识产权法</option>
        <option value="招投标法">招投标法</option>
        <option value="专利法">专利法</option>
        <option value="著作权法">著作权法</option>
        <option value="证券法">券法</option>
        <option value="仲裁法">仲裁法</option>
        <option value="执行法 ">执行法</option>
      </FormControl>
    );
  };

  renderSharingSection = () => {
    if (!this.state.showSharingUI) {
      return (
        <div style={{ marginRight: "10px", padding: "10px" }}>
          <Button className="Lex-button-2" onClick={this.onShareCase}>
            <AxoLocal entity="Casesharing5" defaultValue={"Delegering"} />
            &nbsp;&nbsp;
            <Icon glyph="icon-fontello-slideshare" />
          </Button>
        </div>
      );
    }
    var { userGroup, friends, userProfile } = this.props;
    var groupMembers = userGroup.id
      ? userGroup.members.filter((m) => m.id !== userProfile.id)
      : [];
    return (
      <CaseSharing
        sharingUsers={[]}
        groupUsers={groupMembers}
        friends={friends}
        onShare={this.onShareWithUsers}
        onCancel={() => {
          this.setState({ showSharingUI: false });
        }}
        creating
      />
    );
  };

  onShareWithUsers = (sharingUsers) => {
    var { caseViewModel } = this.state;
    if (!caseViewModel.id) {
      return;
    }
    DataActions.shareCaseWithList(
      this.state.caseViewModel.id,
      sharingUsers.map((user) => user.id)
    ).then((response) => {
      if (response.ok) {
        this.onFinishDraft();
      }
    });
  };

  onShareCase = () => {
    this.setState({ showSharingUI: true });
  };

  getCaseNumberValidationState = (showMissingNameWarning) => {
    if (showMissingNameWarning) {
      return "error";
    }
    return null;
  };

  renderCaseInformation = () => {
    var { caseInfoEnabled, showMissingNameWarning } = this.state;
    if (!caseInfoEnabled) {
      return null;
    }

    let { userProfile } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <Row>
        <ControlLabel
          style={{
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "normal",
            paddingLeft: "25px",
          }}
        >
          <AxoLocal
            entity="CaseEditFormCaseInformation"
            defaultValue={"Sagsinformation"}
          />
        </ControlLabel>
        <Col md={6}>
          <Form horizontal style={{ marginBottom: 25 }} autoComplete="on">
            <FormGroup
              bsSize="large"
              validationState={this.getCaseNumberValidationState(
                showMissingNameWarning
              )}
            >
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <Col sm={3}>
                  <Icon glyph="icon-fontello-barcode-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="ContainercaseNumber"
                    defaultValue={"Sagsnummer"}
                  />
                  :
                </Col>
              </div>
              <Col sm={9}>
                <AxoLocal
                  componentClass={FormControl}
                  type="text"
                  value={this.state.caseViewModel.caseNumber}
                  onChange={(event) =>
                    this.onCasePropertyChange("caseNumber", event.target.value)
                  }
                  componentAttribute="placeholder"
                  entity="placeholderEnterthefilenumberhere"
                />
              </Col>
            </FormGroup>
            <FormGroup bsSize="large">
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <Icon glyph="icon-fontello-qrcode" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledF"
                  defaultValue={"Internt sagsnummer:"}
                />
                :
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <AxoLocal
                    componentClass={FormControl}
                    type="text"
                    value={this.state.caseViewModel.internalNumber}
                    onChange={(event) =>
                      this.onCasePropertyChange(
                        "internalNumber",
                        event.target.value
                      )
                    }
                    componentAttribute="placeholder"
                    entity="placeholderSelectthetypeofthecaseA"
                  />
                  <InputGroup.Addon>
                    <Button
                      className="Lex-button-2"
                      disabled={false}
                      onClick={this.onGenerateInternalNumber}
                    >
                      <AxoLocal
                        entity="ContactCreateViewAuto"
                        defaultValue={"Auto"}
                      />
                    </Button>
                  </InputGroup.Addon>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup bsSize="large">
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <Icon glyph="icon-fontello-tasks" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseEditFormcasecourt"
                  defaultValue={"Myndighed"}
                />
                :
              </Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  value={this.state.caseViewModel.type}
                  onChange={(event) =>
                    this.onCasePropertyChange("type", event.target.value)
                  }
                  placeholder={
                    userType === "Accountant"
                      ? "Skattestyrelsen"
                      : getText("placeholderSelectthetypeofthecase", "Byret")
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup bsSize="large">
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <Icon glyph=" icon-fontello-pencil-alt-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedsubject"
                  defaultValue={"Emneord"}
                />
                :
              </Col>
              <Col sm={9}>
                <AxoLocal
                  componentClass={FormControl}
                  type="text"
                  value={this.state.caseViewModel.subject}
                  onChange={(event) =>
                    this.onCasePropertyChange("subject", event.target.value)
                  }
                  componentAttribute="placeholder"
                  entity="placeholderThesubjectmatter"
                />
              </Col>
            </FormGroup>
            {this.renderClientFields()}
            <FormGroup bsSize="large">
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              ></Col>
              <Col sm={9}>{this.renderSharingSection()}</Col>
            </FormGroup>
          </Form>
        </Col>
        <Col md={6}>
          <Form horizontal style={{ marginBottom: 25 }} autoComplete="on">
            <FormGroup bsSize="large" controlId="dropdownselect">
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <Icon glyph="icon-fontello-statusnet" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
                :&nbsp;
              </Col>
              <Col sm={3}>
                <FormControl
                  disabled={this.state.caseViewModel.status === "Draft"}
                  value={this.state.caseViewModel.status}
                  onChange={(event) =>
                    this.onCasePropertyChange("status", event.target.value)
                  }
                  componentClass="select"
                >
                  <AxoLocal
                    componentClass={ReactOption}
                    value="Draft"
                    componentAttribute="text"
                    entity="CaseEditFormcasedraft"
                    defaultValue={"Draft"}
                  />
                  <AxoLocal
                    componentClass={ReactOption}
                    value="Active"
                    componentAttribute="text"
                    entity="CaseEditFormcaseActive"
                    defaultValue={"Active"}
                  />
                  <AxoLocal
                    componentClass={ReactOption}
                    value="Standby"
                    componentAttribute="text"
                    entity="CaseEditFormcaseStandby"
                    defaultValue={"Standby"}
                  />
                </FormControl>
              </Col>
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={6}
              >
                <AxoLocal
                  entity="CaseEditFormcaseDeadlineField"
                  defaultValue={"Deadline"}
                />
                &nbsp;&nbsp;{this.renderDeadlineField(this.state.caseViewModel)}
              </Col>
            </FormGroup>
            <FormGroup bsSize="large" controlId="dropdownselect">
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <Icon glyph="icon-fontello-record-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseEditFormcasepriority"
                  defaultValue={"Prioritet"}
                />
                :
              </Col>
              <Col sm={9}>
                <FormControl
                  value={this.state.caseViewModel.priority}
                  onChange={(event) =>
                    this.onCasePropertyChange(
                      "priority",
                      parseInt(event.target.value, 10)
                    )
                  }
                  componentClass="select"
                >
                  <AxoLocal
                    componentClass={ReactOption}
                    value="0"
                    componentAttribute="text"
                    entity="CaseEditFormcaseprioritylow"
                    defaultValue={"low"}
                  />
                  <AxoLocal
                    componentClass={ReactOption}
                    value="1"
                    componentAttribute="text"
                    entity="CaseEditFormcasepriorityNormal"
                    defaultValue={"Normal"}
                  />
                  <AxoLocal
                    componentClass={ReactOption}
                    value="2"
                    componentAttribute="text"
                    entity="CaseEditFormcasepriorityHigh"
                    defaultValue={"High"}
                  />
                  <AxoLocal
                    componentClass={ReactOption}
                    value="3"
                    componentAttribute="text"
                    entity="CaseEditFormcasepriorityUrgent"
                    defaultValue={"Urgent"}
                  />
                </FormControl>
              </Col>
            </FormGroup>
            <FormGroup
              className="visible-danish"
              bsSize="large"
              controlId="selectField"
            >
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <Icon glyph="icon-fontello-up-hand" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledC"
                  defaultValue={"Sagsområde:"}
                />
                :
              </Col>
              <Col sm={9}>{this.renderFieldSelect()}</Col>
            </FormGroup>
            <FormGroup
              className="visible-englishUS"
              bsSize="large"
              controlId="selectField"
            >
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <Icon glyph="icon-fontello-up-hand" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledC"
                  defaultValue={"Sagsområde:"}
                />
                :
              </Col>
              <Col sm={9}>{this.renderUSFieldSelect()}</Col>
            </FormGroup>
            <FormGroup
              className="visible-chinese"
              bsSize="large"
              controlId="selectField"
            >
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <Icon glyph="icon-fontello-up-hand" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledC"
                  defaultValue={"Sagsområde:"}
                />
                :
              </Col>
              <Col sm={9}>{this.renderChineseFieldSelect()}</Col>
            </FormGroup>
            <FormGroup bsSize="large">
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <span>
                  <Icon glyph="icon-fontello-hammer" />
                  &nbsp;&nbsp;
                  {/* <AxoLocal entity='CaseDataTableFixedcaseEnabledC' defaultValue={'Sagsområde:'}/>&nbsp;</span></Col> */}
                  Sagsnavn:&nbsp;
                </span>
              </Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  value={this.state.caseViewModel.field}
                  onChange={(event) =>
                    this.onCasePropertyChange("field", event.target.value)
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup bsSize="large">
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <span>
                  <Icon glyph="icon-fontello-target-4" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseDataTableFixedcaseEnabledD"
                    defaultValue={"Reference"}
                  />
                  &nbsp;
                </span>
              </Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  value={this.state.caseViewModel.reference}
                  onChange={(event) =>
                    this.onCasePropertyChange("reference", event.target.value)
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup bsSize="large">
              <Col
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <Icon glyph=" icon-fontello-pencil-alt-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledG"
                  defaultValue={"Notat"}
                />
                :
              </Col>
              <Col sm={9}>
                <FormControl
                  type="text"
                  value={this.state.caseViewModel.notes}
                  onChange={(event) =>
                    this.onCasePropertyChange("notes", event.target.value)
                  }
                />
                {/* <AxoLocal componentClass={FormControl} type='text' value={this.state.caseViewModel.notes} 
                  onChange={event => this.onCasePropertyChange('notes', event.target.value )} 
                  componentAttribute="placeholder"
                  entity='placeholderThesubjectmatter'
                /> */}
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    );
  };

  renderContactInfoForms = () => {
    return (
      <div>
        {this.state.counterPartiesEnabled ? this.renderCounterPartyUI() : null}
      </div>
    );
  };

  renderAddClientButton = () => {
    var clients = this.state.caseViewModel.clients;
    if (clients.length === 0 || !clients[clients.length - 1].id) {
      return null;
    }
    return (
      <Button className="Lex-button-2" onClick={this.onAddClient}>
        <Icon role="button" glyph="icon-fontello-plus-3" className="Addicon" />{" "}
        &nbsp;&nbsp;
        <AxoLocal
          entity="CaseEditFormonAddClients"
          defaultValue={"Tilføj klient"}
        />
      </Button>
    );
  };

  renderCounterPartyUI = () => {
    var title =
      this.state.caseViewModel.counterParties.length === 0 ? (
        <span>
          <Icon role="button" glyph="icon-fontello-user-add-1" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="CaseEditFormonAddCounterParty"
            defaultValue={"Tilføj modpart"}
          />
        </span>
      ) : (
        <span>
          {" "}
          <Icon
            role="button"
            glyph="icon-fontello-plus-3"
            className="Addicon"
          />
          &nbsp;&nbsp;
          <AxoLocal
            entity="CaseEditFormoAddCounterParty"
            defaultValue={"Tilføj flere modparter"}
          />
        </span>
      );
    return (
      <div>
        {this.renderAddClientButton()}&nbsp;
        {this.renderCounterPartyNodes()}
        <Button className="Lex-button-2" onClick={this.onAddCounterParty}>
          {title}
        </Button>
      </div>
    );
  };

  onToggleCaseInfo = () => {
    this.setState({ caseInfoEnabled: !this.state.caseInfoEnabled });
  };

  onToggleClients = () => {
    this.setState({ clientsEnabled: !this.state.clientsEnabled });
  };

  onToggleCounterParties = () => {
    this.setState({ counterPartiesEnabled: !this.state.counterPartiesEnabled });
  };

  onFileAdded = (file) => {
    DataActions.createCaseDocumentAssignment({
      caseId: this.state.caseViewModel.id,
      fileInfoModelId: file.id,
    });
  };

  isDraft = () => {
    return true;
    // return this.state.caseViewModel.status === 0;
  };

  isClosed = () => {
    return this.state.caseViewModel.closed;
  };

  isTrashed = () => {
    return this.state.caseViewModel.trashed;
  };

  renderSaveButtons = () => {
    var { caseViewModel, creatingVersion } = this.state;

    let VersionCreateButton = this.VersionCreateButton;
    return (
      <ButtonGroup>
        {this.isDraft() ? (
          <Button className="Lex-button-2" onClick={this.onSaveAndCloseClicked}>
            {" "}
            <Icon glyph="icon-fontello-floppy-1" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="CaseEditFormFinishDraft"
              defaultValue={"Gem som udkast"}
            />
          </Button>
        ) : null}
        {this.isDraft() ? (
          <VersionCreateButton
            show={!!caseViewModel.id}
            onCreateCaseVersion={this.onCreateCaseVersion}
            creatingVersion={creatingVersion}
          />
        ) : (
          <Button className="Lex-button-2" onClick={this.onSaveClicked}>
            {" "}
            <Icon glyph="icon-fontello-floppy-1" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="CaseEditFormonSave"
              defaultValue={"Gem ændringer"}
            />
          </Button>
        )}
      </ButtonGroup>
    );
  };

  renderCloseButton = () => {
    return (
      <span>
        {!this.isDraft() ? (
          <Button className="Lex-button-2" onClick={this.onToggleClose}>
            {this.isClosed() ? (
              <span>
                {" "}
                <Icon glyph="icon-fontello-ccw-2" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseEditFormonToggleClose"
                  defaultValue={"Gendan sag"}
                />
              </span>
            ) : (
              <span>
                <Icon glyph="icon-fontello-lock-filled" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseEditFormClose"
                  defaultValue={"Afslut sag"}
                />
              </span>
            )}
          </Button>
        ) : null}
      </span>
    );
  };

  onClientCreated = (index, newClient) => {
    let caseViewModel = { ...this.state.caseViewModel };
    let clients = caseViewModel.clients.slice();
    clients[index] = newClient;
    caseViewModel.clients = clients;
    this.setState(
      {
        caseViewModel,
        creatingClientIndex: -1,
      },
      () => {
        this.saveChanges(false);
      }
    );
  };

  onCancelCreateClient = () => {
    this.setState({ creatingClientIndex: -1 });
  };

  onCreateCaseVersion = () => {
    var { caseViewModel, versionTitle } = this.state;
    if (!caseViewModel.caseNumber) {
      this.setState({ showMissingNameWarning: true });
      return;
    }

    this.setState({ creatingVersion: true });

    var caseVersion = {
      title: versionTitle,
      description: caseViewModel.description,
      caseModelId: caseViewModel.id,
    };

    DataActions.createCaseVersion(caseVersion)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject("Case version could not be created");
      })
      .then((caseVersion) => {
        var caseViewModel = Object.assign({}, this.state.caseViewModel);
        caseViewModel.description = "<html></html>";
        this.setState({ caseViewModel }, () => {
          this.onFinishDraft();
        });
      })
      .catch((reason) => {
        this.setState({ creatingVersion: false });
        console.log(reason);
      });
  };

  onVersionTitleChange = (event) => {
    this.setState({ versionTitle: event.target.value });
  };

  VersionCreateButton = (props) => {
    var { creatingVersion, onCreateCaseVersion } = props;

    return (
      <Button
        disabled={creatingVersion}
        onClick={onCreateCaseVersion}
        className="Lex-button-2"
      >
        <Icon glyph="icon-fontello-plus-squared-alt" />
        &nbsp;&nbsp;
        <AxoLocal
          entity="CaseEditFormonSaveClicked"
          defaultValue={"Opret sag"}
        />
        {creatingVersion ? (
          <span>
            &nbsp;
            <img
              alt=""
              src="/imgs/app/loading.gif"
              className="img-circle"
              width="20"
              height="20"
            />
          </span>
        ) : null}
      </Button>
    );
  };

  render() {
    let {
      userProfile,
      contactMap,
      // financeAccountPlans
    } = this.props;

    let { caseViewModel, creatingClientIndex, showEditor } = this.state;

    if (creatingClientIndex > -1) {
      return (
        <ContactCreateView
          title={
            <AxoLocal
              entity="TitleCreateanewclientforthecase"
              defaultValue={"Opret ny klient til sagen"}
            />
          }
          userProfile={userProfile}
          contactMap={contactMap}
          // financeAccountPlans={financeAccountPlans}
          onClientCreated={this.onClientCreated.bind(
            this,
            this.state.creatingClientIndex
          )}
          onCancel={this.onCancelCreateClient}
        />
      );
    }
    var tinyMCE = (
      <TinyMCEEditor
        text={this.props.openCase ? this.props.openCase.description : ""}
        onTextChange={(content) =>
          this.onCasePropertyChange("description", content)
        }
        documentMap={this.props.documentMap}
        locale={this.props.userSettings.locale}
        allowImageImport
      />
    );
    return (
      <div className="case-form">
        <Grid fluid>
          <Row className="hidden-print">
            <Col xs={12} className="text-right">
              <ButtonToolbar
                style={{ display: "inline-block" }}
                className="axoinboxtoolbar"
              >
                <ButtonGroup>{this.renderSaveButtons()}</ButtonGroup>
                <ButtonGroup>{this.renderCloseButton()}</ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          {this.renderCaseInformation()}
          {this.renderContactInfoForms()}
          <br />
          <Row>
            <Col
              sm={12}
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                margin: "auto",
              }}
            >
              <div
                style={{
                  height: "40px",
                  color: "#4CAF50",
                  paddingBottom: "8px",
                }}
              >
                <ControlLabel style={{ textAlign: "left", fontSize: "100%" }}>
                  {" "}
                  <Icon glyph=" icon-fontello-pencil-alt-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditFormCaseFacts1"
                    defaultValue={"Sagsfremstilling"}
                  />
                </ControlLabel>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormGroup className="flexbox-responsive">
                <FlexElement
                  className="rightPadding"
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal
                    entity="CalendarEventBoxFormtitle"
                    defaultValue={"Titel"}
                  />
                </FlexElement>
                <FlexElement>
                  <FormControl
                    type="text"
                    value={this.state.versionTitle}
                    onChange={this.onVersionTitleChange}
                  />
                </FlexElement>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              className="nopadding"
              style={{ paddingBottom: "25px" }}
            >
              {showEditor && tinyMCE}
            </Col>
          </Row>
          <Row>
            <Col xs={4} className="text-left">
              {/* <h6><Icon glyph="icon-fontello-upload-4" />&nbsp;&nbsp;
                <AxoLocal  entity='CaseEditInlineUploaddocuments'defaultValue={'Upload dokumenter til sagen her'}/> <Icon glyph='icon-fontello-down-1'/></h6>  */}
            </Col>
            <Col xs={8} className="text-right" style={{ marginBottom: 16 }}>
              <ButtonToolbar style={{ display: "inline-block" }}>
                <ButtonGroup>
                  {this.isTrashed() ? (
                    <Button
                      className="Lex-button-2"
                      onClick={this.onPermanentDelete}
                    >
                      {" "}
                      <Icon
                        style={{ fontSize: "11px" }}
                        glyph="icon-fontello-trash-1"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="CaseCaseVersion2"
                        defaultValue={"Slet sag permanent"}
                      />
                    </Button>
                  ) : null}
                  <Button
                    className="Lex-button-2"
                    onClick={this.onToggleDelete}
                  >
                    {this.isTrashed() ? (
                      <span>
                        {" "}
                        <Icon glyph="icon-fontello-ccw-2" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="CaseEditFormonToggleClose"
                          defaultValue={"Gendan sag"}
                        />
                      </span>
                    ) : this.isDraft() ? (
                      <span>
                        {" "}
                        <Icon glyph="icon-fontello-doc-remove" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="CaseEditFormBoxingdraft"
                          defaultValue={"Kasser udkast"}
                        />
                      </span>
                    ) : (
                      <span>
                        {" "}
                        <Icon glyph="icon-fontello-trash-2" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="CaseEditFormremoveCase"
                          defaultValue={"Slet sag"}
                        />
                      </span>
                    )}
                  </Button>
                  {this.renderSaveButtons()}
                  {this.renderCloseButton()}
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {caseViewModel.id ? (
                <Dropzonejs onFileUploaded={this.onFileAdded} />
              ) : null}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(CaseEditForm);
