import React from "react";

import { Col } from "react-bootstrap";

import { Icon } from "./LexUtilities";

export default {
  getDefaultEventKey() {
    if (window.innerWidth < 768) {
      return "0";
    }
    return "1";
  },

  //This only works as a function, and not as a component
  renderAccordionHeader(title) {
    return (
      <div>
        <Col xs={11} style={{ padding: "0px" }}>
          <span>{title}</span>
        </Col>
        <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
          <span style={{ textAlign: "right" }}>
            <Icon glyph="icon-fontello-arrow-combo" />
          </span>
        </Col>
      </div>
    );
  },
};
