import React from "react";
import Config from "./config";
import moment from "moment";
// import 'moment/locale/da'
// moment.locale('da')
import ApiService from "../../services/DataAccess/ApiService";
import { AxoLocal } from "../LexUtilities";
import Conditional from "../Conditional";
import { Button } from "react-bootstrap";

export default class CurrentForecast extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      weatherData: null,
    };
  }

  componentDidMount() {
    this.updateWeatherData(this.props);
  }

  updateWeatherData = (props) => {
    var coords = props.coords;
    if (coords) {
      var { latitude, longitude } = coords;
      ApiService.getWeatherCurrentCoords(latitude, longitude).then(
        (weatherData) => {
          this.setState({ weatherData: weatherData });
        }
      );
    } else {
      ApiService.getWeatherCurrent(this.props.city).then((weatherData) => {
        this.setState({ weatherData: weatherData });
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.coords !== !!this.props.coords) {
      this.updateWeatherData(nextProps);
    }
  }

  translateConditions(conditions) {
    if (!conditions) {
      return conditions;
    }
    //First word to lowercase
    var firstWord = conditions.split(" ")[0];
    var cond = firstWord.toLowerCase();
    return (
      <AxoLocal
        key={cond + "test"}
        entity="weatherConditions"
        data={{ cond }}
        defaultValue={"Clear"}
      />
    );
  }

  translateCity(city) {
    if (city !== "Copenhagen") {
      return city;
    }
    var cityName = city.toLowerCase();
    return (
      <AxoLocal
        key={cityName}
        entity="weatherCity"
        data={{ cityName }}
        defaultValue={"København"}
      />
    );
  }
  // aqua
  // case 'rain':
  // return 'linear-gradient(#fff,#ffe6cc)';

  // case 'rain':
  // return 'linear-gradient(#fff,#ccf5ff)';

  getConditionBackground(conditions) {
    var cond = conditions.toLowerCase();
    switch (cond) {
      case "clear":
        return "url(/svg/weather1.jpg)";
      case "clouds":
        return "url(/svg/linktop.jpg)";
      case "drizzle":
        return "url(/svg/weather3.jpg)";
      case "thunderstorm":
        return "url(/svg/weather2.jpg)";
      case "rain":
        return "linear-gradient(#fff,#d7e6f4)";
      case "snow":
        return "url(/svg/weather6.jpg)";
      case "atmosphere":
        return "url(/svg/weather7.jpg)";
      case "mist":
        return "url(/svg/weather8.jpg)";
      case "extreme":
        return "url(/svg/weather9.jpg)";
      default:
        return "url(/svg/weather7.jpg)";
    }
  }
  // getConditionBackground(conditions) {
  //   var cond = conditions.toLowerCase();
  //   switch(cond) {
  //     case 'clear':
  //       return 'url(/svg/weather1.jpg)';
  //     case 'clouds':
  //       return 'url(/svg/linktop.jpg)';
  //     case 'drizzle':
  //       return 'url(/svg/weather3.jpg)';
  //     case 'thunderstorm':
  //       return 'url(/svg/weather2.jpg)';
  //     case 'rain':
  //       return 'url(/svg/weather3.jpg)';
  //     case 'snow':
  //       return 'url(/svg/weather6.jpg)';
  //     case 'atmosphere':
  //       return 'url(/svg/weather7.jpg)';
  //     case 'mist':
  //       return 'url(/svg/weather8.jpg)'
  //     case 'extreme':
  //       return 'url(/svg/weather9.jpg)';
  //   }
  //     return 'url(/svg/weather7.jpg)';
  // }

  render() {
    var data = this.state.weatherData;

    var city = data ? data.name : "";
    var temp = data ? Math.round(data.main.temp) : "";
    var conditions = data ? data.weather[0].main : "";
    var icon = data ? Config.imgPath + data.weather[0].icon + ".png" : "";
    var date = data ? moment.unix(data.dt).format("ddd, HH:mm") : "";

    return (
      <div
        className="blurred-background flexbox-center axoweathertextbg"
        style={{
          border: "1px solid #e6e6e6",
          padding: "10px 10px",
          background: "url(/svg/axoweather32.jpg)",
        }}
      >
        {/* this.getConditionBackground(conditions), */}
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            padding: "0px",
            width: "100%",
            height: "100%",
          }}
        >
          {this.props.refusedGeoCoords ? (
            <div className="text-center">
              <AxoLocal
                entity="CurrentForecastAllowAccess"
                defaultValue={
                  "Tillad adgang til din lokalitet for at se det lokale vejr."
                }
              />
            </div>
          ) : null}
          <div className="flexbox-layout text-center axoweathertext">
            <div style={{ paddingLeft: "10px" }}>
              <div className="city axoweathertext">
                {" "}
                <AxoLocal
                  entity="DragDropCalendarToday"
                  defaultValue={"I dag"}
                />
              </div>
              <div className="date">
                <span>
                  <AxoLocal
                    entity="weatherDataconditions"
                    defaultValue={"Målt"}
                  />
                </span>
                :{date}
              </div>
            </div>
            <div className="city axoweathertext">
              {this.translateCity(city)}
            </div>
            <div style={{ paddingRight: "10px" }}>
              <Button
                style={{ minWidth: "150px" }}
                onClick={this.props.toggleExpanded}
                className="Lex-button-2"
              >
                <Conditional inline show={this.props.expanded}>
                  <AxoLocal
                    entity="weatherDataconditionstoggleExpanded"
                    defaultValue={"Skjul udsigt"}
                  />
                  &nbsp;
                </Conditional>
                <Conditional inline show={!this.props.expanded}>
                  <AxoLocal
                    entity="weatherDataconditionsexpanded"
                    defaultValue={"Se ugens vejr"}
                  />
                </Conditional>
              </Button>
            </div>
          </div>
          <div
            className="flexbox-layout axoweathertext"
            style={{ paddingLeft: "10px" }}
          >
            <div
              style={{ paddingLeft: "10px" }}
              className="icon axoweathertext"
            >
              <img alt="" src={icon} />
            </div>
            <div className="temp axoweathertext">{temp}&deg;c</div>
            <div
              style={{ paddingRight: "10px" }}
              className="conditions axoweathertext"
            >
              {this.translateConditions(conditions)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
