//HOC for using RTK Query hooks in class componennts
import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import DataStore from "../../services/DataAccess/DataStore";

import {
  useGetMessagesQuery,
  useRefetchMessagesMutation,
  useGetMessageQuery,
  useGetMessageHeaderQuery,
  useGetMessageCounterQuery,
  useCreateMessageMutation,
  useUpdateMessageMutation,
  useUpdateMessageFromHeaderMutation,
  useDeleteMessageMutation,
} from "../../services/DataAccess/ApiSlice";

let messageId, setMessageId;
let messageHeaderId, setMessageHeaderId;

export default function withRTKMessageData(WrappedComponent) {
  return function (props) {
    let store = DataStore.getStore();
    // const { data, error, isLoading } = useGetContactsQuery(DataStore.getStore().contactQuery);
    const messageQuery = useGetMessagesQuery({
      ...store.messageQuery,
      pageSize: store.messagePageSize,
    });

    let { isFetching, isSuccess } = messageQuery;

    useEffect(() => {
      if (!isFetching && isSuccess) {
        DataStore.calculateEmailSuggestionsV2(messageQuery.data);
      }
    }, [isFetching]);

    const messageCountQuery = useGetMessageCounterQuery();

    [messageId, setMessageId] = useState(0);
    let singleMessageQuery = useGetMessageQuery(messageId, {
      skip: messageId === 0,
    });

    [messageHeaderId, setMessageHeaderId] = useState(0);
    let singleMessageHeaderQuery = useGetMessageHeaderQuery(messageHeaderId, {
      skip: messageHeaderId === 0,
    });

    const [refetchMessages, { isLoading: isRefetchingMessages }] =
      useRefetchMessagesMutation();

    const [
      createMessage, // This is the mutation trigger
      { isLoading: isCreatingMessage }, // This is the destructured mutation result
    ] = useCreateMessageMutation();

    const [
      updateMessage, // This is the mutation trigger
      { isLoading: isUpdatingMessage }, // This is the destructured mutation result
    ] = useUpdateMessageMutation();

    const [
      updateMessageFromHeader, // This is the mutation trigger
      { isLoading: isUpdatingMessageFromHeader }, // This is the destructured mutation result
    ] = useUpdateMessageFromHeaderMutation();

    const [
      deleteMessage, // This is the mutation trigger
      { isLoading: isDeletingMessage }, // This is the destructured mutation result
    ] = useDeleteMessageMutation();

    return (
      <WrappedComponent
        messageQuery={messageQuery}
        messageMap={
          messageQuery.data || {
            messages: [],
            received: [],
            important: [],
            sent: [],
            drafts: [],
            spam: [],
            trashed: [],
          }
        }
        messageCountQuery={messageCountQuery}
        messageCounter={messageCountQuery.data || { unreadCount: 0 }}
        singleMessageQuery={singleMessageQuery}
        singleMessage={singleMessageQuery.data || {}}
        singleMessageHeaderQuery={singleMessageHeaderQuery}
        singleMessageHeader={singleMessageHeaderQuery.data || {}}
        setMessageId={setMessageId}
        setMessageHeaderId={setMessageHeaderId}
        refetchMessages={refetchMessages}
        isRefetchingMessages={isRefetchingMessages}
        createMessage={createMessage}
        isCreatingMessage={isCreatingMessage}
        updateMessage={updateMessage}
        isUpdatingMessage={isUpdatingMessage}
        updateMessageFromHeader={updateMessageFromHeader}
        isUpdatingMessageFromHeader={isUpdatingMessageFromHeader}
        deleteMessage={deleteMessage}
        isDeletingMessage={isDeletingMessage}
        {...props}
      />
    );
  };
}
