import React from "react";
import DataStore from "../../services/DataAccess/DataStore";
import ClientDocumentRecordView from "./ClientDocumentRecordView";

import { RoutingService } from "../../services/AxoServices";

import { Consumer } from "../../utilities/LexUtilities";

export default class ClientRecordingContainer extends React.PureComponent {
  componentDidMount() {
    RoutingService.setRootPath("/ClientDashboard");
    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.initializeDocuments();
  }

  render() {
    return (
      <Consumer>
        {(store) => (
          <ClientDocumentRecordView
            documents={store.documentMap.documents}
            userProfile={store.userProfile}
            userSettings={store.userSettings}
            forceShowCards={store.userSettings.showDocumentCards}
          />
        )}
      </Consumer>
    );
  }
}
