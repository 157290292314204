import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import {
  LexButton,
  AsyncButton,
  AxoDateTime,
  CountryDropdown,
  AxoLocal,
} from "../../utilities/LexUtilities";

//This form receives starting state and submit function from outside.
export default class ApplicationUDFormView extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    startValue: PropTypes.object,
  };

  constructor(props) {
    super(props);

    if (props.startValue) {
      this.state = { ...props.startValue };
    } else {
      this.state = this.getStartState();
    }
  }

  getStartState = () => {
    return {
      name: "",
      address: "",
      dateOfBirth: moment.utc().format(),
      countryOfBirth: "",
      eMailAddress: "",
      eelephoneNumber: "",

      showValidationError: false,
    };
  };

  handleChange = (event) => {
    let { type, name } = event.target;

    let value = null;
    switch (type) {
      case "number":
        value = event.target.valueAsNumber;
        break;
      case "checkbox":
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
    }

    this.onUpdateProperty(name, value);
  };

  onUpdateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  onSubmit = async () => {
    if (!this.validateInput()) {
      this.setState({ showValidationError: true });
      setTimeout(() => {
        this.setState({ showValidationError: false });
      }, 3000);

      return false;
    }

    let { onSubmit } = this.props;

    let result = await onSubmit(this.state);
    return result;
  };

  validateInput = () => {
    //Validation here
    let { name } = this.state;

    //Only text and long text is required in this case. If
    if (!name) {
      return false;
    }

    return true;
  };

  onCancel = () => {
    let { onCancel, startValue } = this.props;

    if (onCancel) {
      onCancel();
      return;
    }

    if (startValue) {
      this.setState(this.props.startValue);
    } else {
      this.setState(this.getStartState());
    }
  };

  //Skabelon. Der skal ikke laves entities
  render() {
    let {
      name,
      address,
      dateOfBirth,
      countryOfBirth,
      eMailAddress,
      telephoneNumber,

      showValidationError,
    } = this.state;

    return (
      <Form className="leftPadding topPadding standardMaxWidth">
        <FormGroup controlId="name">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Name
          </ControlLabel>
          <FormControl
            name="name" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={name}
            placeholder="..."
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="address">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Address
          </ControlLabel>
          <FormControl
            name="address" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={address}
            placeholder="..."
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="dateOfBirth">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal entity="axoidcode161" defaultValue={"Fødselsdag"} />
          </ControlLabel>
          <AxoDateTime
            value={moment(dateOfBirth)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("dateOfBirth", dateTime.format())
            }
          />
        </FormGroup>
        <FormGroup controlId="name" className="onTop">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Fødeland
          </ControlLabel>
          <CountryDropdown
            value={countryOfBirth || ""}
            valueType="short"
            customSelect
            onChange={(countryOfBirth) =>
              this.onUpdateProperty("countryOfBirth", countryOfBirth)
            }
          />
        </FormGroup>

        <FormGroup controlId="eMailAddress">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            E-Mail Adresse:
          </ControlLabel>
          <FormControl
            name="eMailAddress" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={eMailAddress}
            placeholder="..."
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="telephoneNumber">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Telefonnr.:
          </ControlLabel>
          <FormControl
            name="telephoneNumber" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={telephoneNumber}
            placeholder="..."
            onChange={this.handleChange}
          />
        </FormGroup>

        {showValidationError ? (
          <div className="axored mediumText">Der skal indtastes et navn.</div>
        ) : null}
        <ButtonToolbar>
          <AsyncButton hideOkIcon onClick={this.onSubmit}>
            <AxoLocal
              entity="friendRequestTimelineonApproveRequest"
              defaultValue={"Godkend"}
            />
          </AsyncButton>
          <LexButton onClick={this.onCancel}>Fortryd</LexButton>
        </ButtonToolbar>
      </Form>
    );
  }
}
