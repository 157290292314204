import React from "react";
import PresentationApiService from "../services/PresentationApiService";
import AdminList from "./../AdminList/AdminList";

export default class PublicPostListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      presentations: [],
      query: "",
      shown: 10,
    };
    this.userId = "";
  }

  componentDidMount() {
    var { userId } = this.props.match.params;
    this.userId = userId;

    PresentationApiService.getPresentationsForUser(userId).then(
      (presentations) => {
        var sortedPresentations = presentations.sort((c1, c2) => {
          return new Date(c2.creationDate) - new Date(c1.creationDate);
        });
        this.setState({ presentations: sortedPresentations });
      }
    );
  }

  selectPresentation = (presentationId) => {
    this.props.history.push(
      "/Presentation/post/" + this.userId + "/" + presentationId
    );
  };

  onSearch = (event) => {
    this.setState({ query: event.target.value });
  };

  getFilteredPresentations = () => {
    var query = this.state.query.toLocaleLowerCase();
    if (!query) {
      return this.state.presentations;
    }
    return this.state.presentations.filter((p) => {
      return p.items.find(
        (i) =>
          i.title.toLowerCase().includes(query) ||
          i.description.toLowerCase().includes(query)
      );
    });
  };

  onShowMore = () => {
    this.setState({
      shown: this.state.shown + 10,
    });
  };

  render() {
    var presentations = this.getFilteredPresentations();
    return (
      <AdminList
        presentations={presentations}
        userProfile={this.state.userProfile}
        selectPresentation={this.selectPresentation}
        query={this.state.query}
        onSearch={this.onSearch}
        shown={this.state.shown}
        onShowMore={this.onShowMore}
        hideCount
      />
    );
  }
}
