import React from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from "moment";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Icon, AxoLocal, getText } from "../../utilities/LexUtilities";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

class DragDropCalendar extends React.PureComponent {
  //Props
  //view
  //locale
  eventStyleGetter = (event, start, end, isSelected) => {
    if (!event.color) {
      return {};
    }
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
    };
    return {
      style: style,
    };
  };

  convertLocale = () => {
    var locale = this.props.locale;
    switch (locale) {
      case "da":
        return "da-DK";
      case "enUS":
        return "en-US";
      case "ch":
        return "zh-CN";
      default:
        return "en-US";
    }
  };

  renderEvent = ({ event }) => {
    let { userProfile, sharingGlobals } = this.props;
    let sharedWithMe = false;
    let sharedFromMe = false;

    //Document and case deadlines cannot be shared
    if (event.userName) {
      sharedWithMe = event.userName !== userProfile.userName;
      sharedFromMe =
        !sharedWithMe &&
        (sharingGlobals.length > 0 ||
          (event.singleSharings &&
            !!event.singleSharings.find(
              (s) => s.userProfileId !== userProfile.id
            )));
    }

    return (
      <span>
        {sharedWithMe ? (
          <span>
            <Icon
              role="button"
              title={getText("axoEntityidcode248", "Delt med dig")}
              glyph="icon-fontello-share-1"
            />
            &nbsp;
          </span>
        ) : null}
        {sharedFromMe ? (
          <span>
            <Icon
              role="button"
              title={getText("axoEntityidcode255", "Delt med andre")}
              glyph="icon-fontello-share-1"
            />
            &nbsp;
          </span>
        ) : null}
        <span>{event.title}</span>
      </span>
    );
  };

  render() {
    return (
      <DragAndDropCalendar
        events={this.props.events}
        culture={this.convertLocale()}
        localizer={localizer}
        style={{ height: 800, marginLeft: "5px" }}
        selectable
        popup
        defaultView={this.props.view || "month"}
        views={
          this.props.view
            ? [this.props.view]
            : ["month", "week", "day", "agenda"]
        }
        onSelectEvent={this.props.onSelectEvent}
        onSelectSlot={this.props.onSelectSlot}
        onEventDrop={this.props.onEventDrop}
        eventPropGetter={this.eventStyleGetter}
        components={{
          event: this.renderEvent,
        }}
        messages={{
          allDay: (
            <span>
              <Icon glyph="icon-fontello-clock-4" /> &nbsp;&nbsp;&nbsp;{" "}
              <AxoLocal
                entity="DragDropCalendarallday"
                defaultValue={"Hele dagen"}
              />
            </span>
          ),
          previous: (
            <span>
              <Icon glyph="icon-fontello-left-3" />
              &nbsp;&nbsp;&nbsp;{" "}
              <AxoLocal
                entity="DragDropCalendarPrevious"
                defaultValue={"Forrige"}
              />
            </span>
          ),
          next: (
            <span>
              {" "}
              <AxoLocal entity="DragDropCalendarNext" defaultValue={"Næste"} />
              &nbsp;&nbsp;&nbsp;
              <Icon glyph="icon-fontello-right-bold" /> &nbsp;&nbsp;&nbsp;{" "}
            </span>
          ),
          today: (
            <span>
              <AxoLocal entity="DragDropCalendarToday" defaultValue={"I dag"} />
              &nbsp;&nbsp;&nbsp; <Icon glyph="icon-fontello-calendar-1" />
            </span>
          ),
          month: (
            <span>
              <Icon glyph="icon-fontello-calendar-1" /> &nbsp;&nbsp;&nbsp;{" "}
              <AxoLocal entity="DragDropCalendarMonth" defaultValue={"Måned"} />{" "}
            </span>
          ),
          week: (
            <span>
              <Icon glyph="icon-fontello-calendar-outlilne" />
              &nbsp;&nbsp;&nbsp;{" "}
              <AxoLocal entity="DragDropCalendarWeek" defaultValue={"Uge"} />
            </span>
          ),
          day: (
            <span>
              <Icon glyph="icon-fontello-target-3" />
              <AxoLocal entity="DragDropCalendarDay" defaultValue={"I dag"} />
            </span>
          ),
          agenda: (
            <span>
              <Icon glyph="icon-fontello-target-4" />
              &nbsp;&nbsp;&nbsp;{" "}
              <AxoLocal
                entity="DragDropCalendarAgenda"
                defaultValue={"Dagsorden"}
              />
            </span>
          ),
          date: (
            <AxoLocal entity="TimeEntryFormntimeEntry" defaultValue={"Date"} />
          ),
          time: (
            <AxoLocal
              entity="DragDropCalendarAgendaTime"
              defaultValue={"Time"}
            />
          ),
          event: (
            <AxoLocal
              entity="DragDropCalendarAgendaEvent"
              defaultValue={"Event"}
            />
          ),
        }}
      />
    );
  }
}

export default DragDropContext(HTML5Backend)(DragDropCalendar);
