import React from "react";
import PresentationActions from "../services/PresentationActions";
import PresentationStore from "../services/PresentationStore";
import DataStore from "../../services/DataAccess/DataStore";
import DataActions from "../../services/DataAccess/DataActions";
import PresentationCreateView from "./PresentationCreateView";
import LocalizationService from "../../services/LocalizationService";
import { withRouter } from "react-router-dom";
import QueryString from "query-string";

class PresentationCreateContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      presentation: this.makeNewPresentation(),
      userProfile: {},
      locale: "enUS",
      showImageFileSizeWarning: false,
      showVideoFileSizeWarning: false,
      insertingAt: -1,
    };
    this.addedIndex = -1;
    this.name = "PresentationCreateContainer";
  }

  makeNewPresentation = (index) => {
    return {
      creationDate: new Date(),
      title: "",
      items: [],
      index: index,
    };
  };

  componentDidMount() {
    var query = QueryString.parse(this.props.location.search);

    if (!!query.id) {
      this.subscribeToPresentation(query.id);
    }

    DataStore.subscribe(this.name, (store) => {
      this.setState({ userProfile: store.userProfile });
    });
    DataStore.initializeUserProfile();

    LocalizationService.subscribeToLocale(this.name, (locale) => {
      this.setState({ locale });
    });
  }

  subscribeToPresentation = (id) => {
    PresentationStore.subscribe(this.name, (store) => {
      let presentation = store.presentations.find(
        (p) => p.id === parseInt(id, 10)
      );
      if (presentation) {
        let viewModel = Object.assign({}, presentation);
        viewModel = this.lockItems(viewModel);

        //The newly added item should be unlocked
        if (this.addedIndex > -1 && viewModel.items.length > this.addedIndex) {
          viewModel.items[this.addedIndex].locked = false;
          this.addedIndex = -1;
        }

        this.setState({ presentation: viewModel });
      }
    });
    PresentationStore.fetchPresentations();
  };

  lockItems = (presentation) => {
    presentation.items.forEach((item) => {
      item.locked = true;
    });
    return presentation;
  };

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
    PresentationStore.unsubscribe(this.name);
    LocalizationService.unsubscribeFromLocale(this.name);

    let presentation = this.state.presentation;
    if (!presentation.id) {
      presentation.items.forEach((item) => {
        if (item.attachment) {
          DataActions.deleteDocument(item.attachment.id);
        }
      });
    }
  }

  onPresentationPropertyChange = (propertyName, event) => {
    let presentation = Object.assign({}, this.state.presentation);
    presentation[propertyName] = event.target.value;
    this.setState({ presentation });
  };

  onAddItem = (type, index) => {
    let presentation = JSON.parse(JSON.stringify(this.state.presentation)); //Deep clone
    if (presentation.items.length > 0) {
      presentation = this.lockItems(presentation);
    }
    index = index > -1 ? index : presentation.items.length;
    let newItem = this.makeNewItem(type, index);
    presentation = this.addItemAtIndex(presentation, newItem, index);
    this.setState({
      presentation,
      insertingAt: -1,
    });
    this.addedIndex = index;

    this.saveItem(newItem);
  };

  onAddImageItem = (file, index) => {
    var presentation = Object.assign({}, this.state.presentation);
    if (presentation.items.length > 0) {
      presentation = this.lockItems(presentation);
    }
    index = index > -1 ? index : presentation.items.length;
    var newItem = this.makeNewItem(2, index);
    newItem.attachment = file;
    presentation = this.addItemAtIndex(presentation, newItem, index);
    this.setState({
      presentation,
      insertingAt: -1,
    });
    this.addedIndex = index;

    this.saveItem(newItem);
  };

  onAddVideoItem = (file, index) => {
    var presentation = Object.assign({}, this.state.presentation);
    if (presentation.items.length > 0) {
      presentation.items[presentation.items.length - 1].locked = true;
    }
    index = index > -1 ? index : presentation.items.length;
    var newItem = this.makeNewItem(3, index);
    newItem.attachment = file;
    presentation = this.addItemAtIndex(presentation, newItem, index);

    this.setState({
      presentation,
      insertingAt: -1,
    });
    this.addedIndex = index;

    this.saveItem(newItem);
  };

  addItemAtIndex = (presentation, item, index) => {
    presentation.items.splice(index, 0, item);
    return presentation;
  };

  saveItem = (item) => {
    if (this.state.presentation.id) {
      item.presentationId = this.state.presentation.id;
      if (item.id) {
        return PresentationActions.updatePresentationItem(item);
      } else {
        return PresentationActions.createPresentationItem(item);
      }
    }
  };

  onChangeAttachment = (index, file) => {
    var presentation = Object.assign({}, this.state.presentation);
    var item = presentation.items[index];
    DataActions.deleteDocument(item.attachment.id).then((response) => {
      if (response.ok) {
        item.attachment = file;
        this.setState({ presentation });
      }
    });
  };

  onRemoveItem = (index) => {
    var item = this.state.presentation.items[index];
    if (item.attachment) {
      this.removeAttachmentItem(item, index);
    } else {
      this.doRemoveItem(index);
    }
  };

  removeAttachmentItem = (item, index) => {
    DataActions.deleteDocument(item.attachment.id).then((response) => {
      if (response.ok) {
        this.doRemoveItem(index);
      }
    });
  };

  doRemoveItem = (index) => {
    var presentation = Object.assign({}, this.state.presentation);
    var item = presentation.items.splice(index, 1)[0];
    this.setState({ presentation });

    PresentationActions.deletePresentationItem(item.id);
  };

  makeNewItem = (type, index) => {
    return {
      title: "",
      description: "",
      type: type,
      index: index,
    };
  };

  onChangeItemValue = (index, propertyName, event) => {
    var presentation = Object.assign({}, this.state.presentation);
    presentation.items[index][propertyName] = event.target.value;
    this.setState({ presentation });
  };

  onItemDescriptionChange = (index, content) => {
    var presentation = Object.assign({}, this.state.presentation);
    presentation.items[index].description = content;
    this.setState({ presentation });
  };

  onSetItemLockStatus = (index, locked) => {
    var presentation = Object.assign({}, this.state.presentation);
    var item = presentation.items[index];
    item.locked = locked;
    this.setState({ presentation });

    //Save existing items when the user clicks 'Save'
    if (item.locked && item.id) {
      PresentationActions.updatePresentationItem(item);
    }
  };

  onSave = () => {
    let presentation = Object.assign({}, this.state.presentation);

    //Create new presentation
    if (!presentation.id) {
      PresentationActions.createPresentation(presentation)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject("Presentation could not be created.");
        })
        .then(this.handlePresentationResponse)
        .catch((reason) => {
          console.log(reason);
        });
    }
    //Save existing presentation
    else {
      presentation.items.forEach((item) => {
        this.saveItem(item);
      });
      PresentationActions.updatePresentation(presentation)
        .then((response) => {
          if (response.ok) {
            this.handlePresentationResponse(presentation);
            return true;
          }
          return Promise.reject("Presentation could not be updated.");
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  };

  handlePresentationResponse = (presentation) => {
    this.setState({
      presentation,
    });
    let link = "/xlink/Presentation/" + presentation.id;
    this.props.history.push(link);
  };

  onShowImageFileSizeWarning = () => {
    this.setState({ showImageFileSizeWarning: true });
    setTimeout(() => {
      this.setState({ showImageFileSizeWarning: false });
    }, 3000);
  };

  onShowVideoFileSizeWarning = () => {
    this.setState({ showVideoFileSizeWarning: true });
    setTimeout(() => {
      this.setState({ showVideoFileSizeWarning: false });
    }, 3000);
  };

  onInsertAt = (index) => {
    this.setState({ insertingAt: index });
  };

  onCancelInsertion = () => {
    this.setState({ insertingAt: -1 });
  };

  render() {
    return (
      <PresentationCreateView
        presentation={this.state.presentation}
        userProfile={this.state.userProfile}
        locale={this.state.locale}
        onPresentationPropertyChange={this.onPresentationPropertyChange}
        onAddItem={this.onAddItem}
        onRemoveItem={this.onRemoveItem}
        onChangeItemValue={this.onChangeItemValue}
        onItemDescriptionChange={this.onItemDescriptionChange}
        onAddImageItem={this.onAddImageItem}
        onAddVideoItem={this.onAddVideoItem}
        onSetItemLockStatus={this.onSetItemLockStatus}
        onChangeAttachment={this.onChangeAttachment}
        onSave={this.onSave}
        insertingAt={this.state.insertingAt}
        onInsertAt={this.onInsertAt}
        onCancelInsertion={this.onCancelInsertion}
        showImageFileSizeWarning={this.state.showImageFileSizeWarning}
        showVideoFileSizeWarning={this.state.showVideoFileSizeWarning}
        onShowImageFileSizeWarning={this.onShowImageFileSizeWarning}
        onShowVideoFileSizeWarning={this.onShowVideoFileSizeWarning}
      />
    );
  }
}

export default withRouter(PresentationCreateContainer);
