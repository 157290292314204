import React, { useState } from "react";

import {
  FormGroup,
  // InputGroup,
  FormControl,
  ButtonToolbar,
  ControlLabel,
} from "react-bootstrap";

import {
  // Icon,
  Flexbox,
  getText,
  AsyncButton,
  LexButton,
  AxoLocal,
} from "../../utilities/LexUtilities";

import ReactSelect from "react-select";

export default function NewProductForm(props) {
  let { onSubmit, onCancel, clientPlan, userType } = props;

  const [name, setName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [units, setUnits] = useState(0);
  const [unitType, setUnitType] = useState("Hours");
  const [price, setPrice] = useState(0.0);
  const [vat, setVat] = useState(25.0);
  const [financeAccountId, setFinanceAccountId] = useState(
    clientPlan.standardSalesAccountId
  );
  const [description, setDescription] = useState("");

  async function onSubmitForm() {
    return onSubmit({
      name,
      productCode,
      units,
      unitType,
      price,
      financeAccountId,
      vat,
      description,
    });
  }

  const getAccountOptions = () => {
    if (!clientPlan.id) {
      return [];
    }
    let standardAccounts = clientPlan.accounts.filter(
      (a) => a.type === "Standard" && a.isCredit
    );
    let accountOptions = standardAccounts.map((f) => {
      return { value: f.id, label: f.number.toString() + " - " + f.name };
    });

    return accountOptions;
  };

  const filterValues = ({ label }, search) => {
    return label.toLowerCase().includes(search.toLowerCase());
  };

  let hourLabel = getText("unitHoursFullName", "Timer");
  let unitLabel = getText("unitUnitsFullName", "Antal");

  let accountOptions = getAccountOptions();

  let selectedOption = accountOptions.find(
    (a) => a.value === financeAccountId
  ) || { value: "", label: getText("axoAccounting6f", "Vælg konto") };

  return (
    <div>
      <Flexbox>
        <FormGroup controlId="name" className="rightPadding">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="SidebarRightContaineruseruserName"
              defaultValue={"Navn"}
            />
          </ControlLabel>
          <FormControl
            autoFocus
            type="text"
            className="borderFormControlfocus"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="productCode" className="rightPadding">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal entity="axoidcode241" defaultValue={"Varekode"} />
          </ControlLabel>
          <FormControl
            type="text"
            className="borderFormControlfocus"
            value={productCode}
            onChange={(event) => setProductCode(event.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="units" className="rightPadding">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal entity="InvoiceInvoiceunit" defaultValue={"Antal"} />
          </ControlLabel>
          <FormControl
            type="text"
            className="borderFormControlfocus"
            value={units}
            onChange={(event) => setUnits(event.target.value)}
            placeholder="0"
          />
        </FormGroup>
        <FormGroup controlId="unitType" className="rightPadding">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal entity="unitUnitType" defaultValue={"Enhedstype"} />
          </ControlLabel>
          <FormControl
            componentClass="select"
            value={unitType}
            onChange={(event) => setUnitType(event.target.value)}
          >
            <option value="Hours">{hourLabel}</option>
            <option value="Units">{unitLabel}</option>
          </FormControl>
        </FormGroup>
        <FormGroup controlId="price" className="rightPadding">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal entity="AdminTabView29" defaultValue="Pris" />
          </ControlLabel>
          <FormControl
            type="number"
            className="borderFormControlfocus"
            value={isNaN(price) ? "" : price}
            onChange={(event) => setPrice(event.target.valueAsNumber)}
            placeholder="0.00"
          />
        </FormGroup>
        {userType !== "AccountingClient" && accountOptions.length !== 0 && (
          <FormGroup controlId="account" style={{ flexBasis: "175px" }}>
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="AccountingTabViewEntity13"
                defaultValue={"Konto"}
              />
            </ControlLabel>
            <ReactSelect
              name="select"
              menuPlacement="auto"
              menuPortalTarget={document.body}
              isDisabled={accountOptions.length === 0}
              closeMenuOnScroll
              // menuPlacement='top'
              value={selectedOption}
              onChange={(selectedAccount) => {
                if (!!selectedAccount) {
                  let account = clientPlan.accounts.find(
                    (a) => a.id === selectedAccount.value
                  ) || { taxSpecificationId: 0 };
                  let taxSpec = clientPlan.taxSpecifications.find(
                    (t) => t.id === account.taxSpecificationId
                  ) || { taxPercentage: 0 };
                  let vat = taxSpec.taxPercentage;
                  setVat(vat);
                  setFinanceAccountId(selectedAccount.value);
                }
              }}
              noOptionsMessage={() => ""}
              options={[
                { value: "", label: getText("axoAccounting6f", "Vælg konto") },
                ...accountOptions,
              ]}
              filterOption={filterValues}
            />
          </FormGroup>
        )}
        {accountOptions.length === 0 && (
          <FormGroup controlId="vat">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="invoiPaymentattheSubtotalVAT"
                defaultValue={"Moms"}
              />
            </ControlLabel>
            <FormControl
              type="number"
              className="borderFormControlfocus"
              value={isNaN(vat) ? "" : vat}
              onChange={(event) => setVat(event.target.valueAsNumber)}
            />
          </FormGroup>
        )}
      </Flexbox>
      <FormGroup controlId="description">
        <FormControl
          componentClass="textarea"
          className="borderFormControlfocus"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          placeholder="Kommentarer..."
        />
      </FormGroup>
      <ButtonToolbar>
        <AsyncButton onClick={onSubmitForm}>
          <AxoLocal entity="CaseGridViewReactOption" defaultValue={"Tilføj"} />
        </AsyncButton>
        <LexButton onClick={onCancel}>
          <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
        </LexButton>
      </ButtonToolbar>
    </div>
  );
}
