import React from "react";

import ClientUserProfileForm from "./ClientUserProfileForm";
import Conditional from "../../utilities/Conditional";
// import ClientPaymentView from './ClientPaymentView'

import { Col, Button, ButtonToolbar, Panel, PanelGroup } from "react-bootstrap";

import LicenseConsentForm from "../../Login/Licenses/LicenseConsentForm";
import DataConsentForm from "../../Login/Licenses/DataConsentForm";
import PurchaseTermsSubPanel from "../../Login/Licenses/PurchaseTermsSubPanel";

import SubscriptionView from "../../common/sidebars/SubscriptionView";

import { DataActions, UserInfoService } from "../../services/AxoServices";

import {
  Icon,
  AxoLocal,
  withRouter,
  AxoListGroup,
  AxoListItem,
} from "../../utilities/LexUtilities";

function renderAccordionHeader(title) {
  return (
    <div>
      <Col xs={11} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

class ClientSetupView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessageFriendRequestId: 0,
    };
  }

  onUpdate = (userProfile) => {
    return DataActions.updateUserProfile(userProfile);
  };

  onApproveRequest = (request) => {
    DataActions.acceptFriendRequest(request)
      .then((response) => {
        if (response.status === 200) {
          return DataActions.createNotification({
            entity: "ClientSetupViewContactRequestApproved",
            variable: UserInfoService.getDisplayName(request.receiver),
            type: 1,
            receiverId: request.requestUser.id,
            success: true,
          });
        }
        return Promise.reject("Failed to accept friend request");
      })
      .catch((reason) => {
        console.log(reason);
        this.showErrorMessage(request.id);
      });
  };

  onRejectRequest = (request) => {
    var updatedRequest = Object.assign({}, request);
    updatedRequest.status = 1; //Rejected
    DataActions.updateFriendRequest(updatedRequest)
      .then((response) => {
        if (response.status === 200) {
          return DataActions.createNotification({
            entity: "ClientSetupViewContactRequestRejected",
            avatar: UserInfoService.getDisplayName(request.receiver),
            type: 1,
            receiverId: request.requestUser.id,
            success: false,
          });
        }
        return Promise.reject("Failed to reject friend request");
      })
      .catch((reason) => {
        console.log(reason);
        this.showErrorMessage(request.id);
      });
  };

  showErrorMessage = (requestId) => {
    this.setState({ errorMessageFriendRequestId: requestId });
    setTimeout(() => {
      this.setState({ errorMessageFriendRequestId: 0 });
    }, 5000);
  };

  renderFriendRequests = () => {
    return this.props.friendRequests.map((request) => {
      return (
        <AxoListItem
          key={request.id}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div>
            {UserInfoService.getDisplayName(request.requestUser)}
            &nbsp;
            <AxoLocal
              entity="ClientSetupViewContactRequest"
              defaultValue="Wishes to add you as a client."
            />
          </div>
          <Conditional
            show={this.state.errorMessageFriendRequestId === request.id}
          >
            <AxoLocal
              className="axored"
              entity="friendRequestTimelinerequestreceiver"
              defaultValue={"Handlingen kunne ikke udføres. Prøv igen senere."}
            />
          </Conditional>
          <Conditional show={request.status === 0}>
            <ButtonToolbar>
              <Button
                className="Lex-button-2"
                onClick={this.onApproveRequest.bind(this, request)}
              >
                <AxoLocal
                  entity="friendRequestTimelineonApproveRequest"
                  defaultValue={"Godkend"}
                />
              </Button>
              <Button
                className="Lex-button-2"
                onClick={this.onRejectRequest.bind(this, request)}
              >
                <AxoLocal
                  entity="friendRequestTimelineonRejectRequest"
                  defaultValue={"Afvis"}
                />
              </Button>
            </ButtonToolbar>
          </Conditional>
          <Conditional show={request.status === 1}>
            <Icon glyph="icon-fontello-cancel" />
            &nbsp;&nbsp;
            <AxoLocal entity="FriendRequestRejected" defaultValue={"Afvist"} />
          </Conditional>
          <Conditional show={request.status === 2}>
            <Icon glyph="icon-fontello-check" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="DocumentGridViewapproved"
              defaultValue={"Godkendt"}
            />
          </Conditional>
        </AxoListItem>
      );
    });
  };

  render() {
    let props = this.props;
    let { userProfile, locale, isEasyID } = props;
    let unhandledFriendRequests = props.friendRequests.filter(
      (f) => f.status === 0
    );

    return (
      <div>
        <div>
          <div
            style={{
              paddingTop: "10px",
              minHeight: "750px",
              paddingBottom: "50px",
            }}
          >
            <div
              className="axobg backgroundlineargradient3"
              style={{ marginBottom: "0px", borderRadius: "0px" }}
            >
              <div className="flexbox-standard">
                <div>
                  <h4>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {!!userProfile.profileImage ||
                    !!userProfile.profileImageLibrary ? (
                      <img
                        alt=""
                        className="img-circle"
                        width="50px"
                        height="50px"
                        src={UserInfoService.getMyProfileImageSource(
                          userProfile
                        )}
                      />
                    ) : (
                      <Icon glyph="icon-fontello-doc-7" />
                    )}
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="ClientSetupViewSetup"
                      defaultValue={"Opsætning"}
                    />
                  </h4>
                </div>
              </div>
            </div>
            <div style={{ padding: "0px" }}>
              {isEasyID && (
                <PanelGroup
                  id="Frontpagea"
                  accordion
                  className="Lex-Accordion"
                  defaultActiveKey="0"
                  style={{
                    borderRadius: "0px",
                    paddingTop: "0px",
                    borderBottom: "1px solid white",
                  }}
                >
                  <Panel className="backgroundlineargradient3" eventKey="1">
                    <Panel.Heading>
                      <Panel.Title toggle>
                        {renderAccordionHeader(
                          <span>
                            <Icon glyph="icon-fontello-cog-7" />
                            &nbsp;&nbsp;
                            <AxoLocal
                              entity="SubscriptionViewuserProfileSubscription"
                              defaultValue={"Abonnement"}
                            />
                          </span>
                        )}
                      </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="LexAccordionPanelody">
                      <SubscriptionView
                        userSubscription={this.props.userSubscription}
                        paymentRequests={this.props.paymentRequests}
                        storageInformation={this.props.storageInformation}
                        userProfile={this.props.userProfile}
                        userGroup={this.props.userGroup}
                        userSettings={this.props.userSettings}
                        subscriptionPlans={this.props.subscriptionPlans}
                      />
                    </Panel.Body>
                  </Panel>
                </PanelGroup>
              )}
              <PanelGroup
                id="ClientSetupa"
                accordion
                className="Lex-Accordion"
                defaultActiveKey="0"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  borderBottom: "1px solid white",
                }}
              >
                <Panel className="backgroundlineargradient3" eventKey="1">
                  <Panel.Heading>
                    <Panel.Title toggle>
                      {renderAccordionHeader(
                        <span>
                          <Icon glyph="icon-fontello-cog-7" />
                          &nbsp;
                          <AxoLocal
                            entity="ClientSetupViewuserProfile"
                            defaultValue={"Brugerprofil"}
                          />
                        </span>
                      )}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible className="LexAccordionPanelody">
                    <ClientUserProfileForm
                      userProfile={this.props.userProfile}
                      userSettings={this.props.userSettings}
                      documentMap={this.props.documentMap}
                      libraryFileNames={this.props.libraryFileNames}
                      onUpdate={this.onUpdate}
                    />
                  </Panel.Body>
                </Panel>
              </PanelGroup>
              {/* <PanelGroup id='ClientSetupb' accordion className='Lex-Accordion' defaultActiveKey='0' style={{ borderRadius: '0px',paddingTop:'0px',borderBottom:'1px solid white'}} >
                    <Panel className='backgroundlineargradient3' eventKey="1">         
                      <Panel.Heading>
                        <Panel.Title toggle>
                        {renderAccordionHeader(<span><Icon glyph='icon-fontello-cog-7'/>&nbsp;
                        <AxoLocal entity='clientSetupCreditCard'  defaultValue={'Betalingskort'}/>  </span>)}
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible className='LexAccordionPanelody' > 
                      <ClientPaymentView
                      cardInformation={this.props.cardInformation}
                      userProfile={this.props.userProfile}
                      locale={this.props.userSettings.locale}
                    />
                      </Panel.Body>
                    </Panel>
                </PanelGroup> */}
              <PanelGroup
                id="ClientSetupc"
                accordion
                className="Lex-Accordion"
                defaultActiveKey="0"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  borderBottom: "1px solid white",
                }}
              >
                <Panel className="backgroundlineargradient3" eventKey="1">
                  <Panel.Heading>
                    <Panel.Title toggle>
                      {renderAccordionHeader(
                        <span>
                          <Icon glyph="icon-fontello-cog-7" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            entity="ClientSetupViewunhandledFriendRequests"
                            defaultValue={"Notifikationer"}
                          />
                          <Conditional
                            inline
                            show={unhandledFriendRequests.length > 0}
                          >
                            <span className="axored">
                              &nbsp;({unhandledFriendRequests.length})
                            </span>
                          </Conditional>
                        </span>
                      )}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible className="LexAccordionPanelody">
                    <AxoListGroup>{this.renderFriendRequests()}</AxoListGroup>
                  </Panel.Body>
                </Panel>
              </PanelGroup>

              {/* <Accordion className = 'Lex-Accordion caseeditinline ' defaultActiveKey='0'>
                <Panel className='backgroundlineargradient3'  
                header={renderAccordionHeader(<span> 
                    <Icon glyph="icon-fontello-cog-7"/>&nbsp;&nbsp;
                    <AxoLocal entity='ClientSetupViewuserProfile' defaultValue={'Brugerprofil'}/>
                    </span>)} eventKey="1">
                      <ClientUserProfileForm 
                        userProfile={this.props.userProfile}
                        userSettings={this.props.userSettings}
                        documentMap={this.props.documentMap}
                        libraryFileNames={this.props.libraryFileNames}
                        onUpdate={this.onUpdate}
                      />
                </Panel> */}

              {/* </Accordion>
              <Accordion className = 'Lex-Accordion caseeditinline' defaultActiveKey='0'>
                <Panel className='backgroundlineargradient3'  
                header={renderAccordionHeader(<span> 
                  <Icon glyph="icon-fontello-cog-7"/>&nbsp;&nbsp;
                  <AxoLocal entity='clientSetupCreditCard'  defaultValue={'Betalingskort'}/>  
                  </span>)} eventKey="1">
                    <ClientPaymentView
                      cardInformation={this.props.cardInformation}
                      userProfile={this.props.userProfile}
                      locale={this.props.userSettings.locale}
                    />
                </Panel> */}
              {/* </Accordion>
              <Accordion className='Lex-Accordion caseeditinline' defaultActiveKey='0'>
                <Panel className='backgroundlineargradient3'
                  header={renderAccordionHeader(<span> 
                    <Icon glyph="icon-fontello-cog-7"/>&nbsp;&nbsp;
                     <AxoLocal entity='ClientSetupViewunhandledFriendRequests' defaultValue={'Notifikationer'}/>    
                    <Conditional inline show={unhandledFriendRequests.length > 0}>
                      <span className='axored'>&nbsp;({unhandledFriendRequests.length})</span>
                    </Conditional>
                    </span>)} eventKey="1">
                  <AxoListGroup>
                    {this.renderFriendRequests()}
                  </AxoListGroup>
                </Panel>
              </Accordion> */}
              <PurchaseTermsSubPanel locale={locale} />
              <LicenseConsentForm
                className="Lex-Accordion"
                locale={locale}
                hideControls
                noMargin
              />
              <DataConsentForm
                className="Lex-Accordion"
                locale={locale}
                hideControls
                noMargin
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ClientSetupView);
