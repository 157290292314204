import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import {
  withRTKData,
  withRTKMessageData,
  withRTKCaseData,
} from "../utilities/LexUtilities";
import TrashEntriesView from "./Trash/TrashEntriesView";
import { withRouter } from "react-router-dom";

class TrashContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      cases: [],
      documents: [],
      contacts: [],
      timeEntries: [],
      invoices: [],
      templates: [],
      userProfile: {},
      showCases: true,
      typeShown: "messages",
    };
    this.name = "TrashContainer";
  }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      this.setState({
        userProfile: store.userProfile,
        cases: store.caseMap.trashed,
        documents: store.documentMap.trashed,
        timeEntries: store.timeEntryMap.trashed,
        invoices: store.invoices.filter((m) => m.trashed),
        templates: store.templates.filter((m) => m.trashed),
      });
    });

    DataStore.initializeUserProfile();
    DataStore.initializeTimeEntries();
    DataStore.initializeCases();
    DataStore.initializeDocuments();
    DataStore.fetchInvoices();
    DataStore.fetchTemplates();
    DataStore.fetchTrashCount();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  onSelect = (typeName) => {
    this.setState({
      typeShown: typeName,
    });
  };

  render() {
    return (
      <TrashEntriesView
        {...this.props}
        {...this.state}
        messages={this.props.messageMap.trashed}
        contacts={this.props.contactMap.trashed}
        onSelect={this.onSelect}
      />
    );
  }
}

export default withRTKCaseData(
  withRTKMessageData(withRTKData(withRouter(TrashContainer)))
);
