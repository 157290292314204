import React from "react";
import DocumentTabView from "./Documents/DocumentTabView";
import moment from "moment";

import { DataStore, RoutingService } from "../services/AxoServices";

import {
  Consumer,
  withRouter,
  withRTKData,
  withRTKCaseData,
} from "../utilities/LexUtilities";

class DocumentTableContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    // DataStore.fetchDocuments();
    DataStore.initializeDocuments();
    DataStore.fetchSharedDocuments();
    DataStore.fetchStorageInformation();
    DataStore.fetchTemplates();
    DataStore.initializeLabels();
    DataStore.initializeCases();
    DataStore.initializeFriends();
    DataStore.initializeUserGroup();
  }

  componentWillUnmount() {
    DataStore.resetDocumentPageSize();
  }

  queryDocuments = (query) => {
    let {
      searchText,
      selectedCase,
      selectedLabelId,
      selectedContact,
      selectedFromDate,
      selectedToDate,
      debounced,
    } = query;
    //The spread operator only adds the property if the conditional is true.
    DataStore.updatePropertyWithoutDispatch("documentQuery", {
      ...(!!searchText ? { searchText } : {}),
      ...(!!selectedCase && !!selectedCase.id
        ? { caseId: selectedCase.id }
        : {}),
      ...(!!selectedLabelId ? { labelId: selectedLabelId } : {}),
      ...(!!selectedContact && !!selectedContact.id
        ? { clientId: selectedContact.id }
        : {}),
      ...(!!selectedFromDate ? { fromDate: selectedFromDate.format() } : {}),
      ...(!!selectedToDate ? { toDate: selectedToDate.format() } : {}),
    });
    if (debounced) {
      DataStore.fetchDocumentsDebounced();
    } else {
      DataStore.fetchDocuments();
    }
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(RoutingService.getPath("Documents/" + eventKey));
  };

  mapStoreToProps = (store) => {
    let sharedDocuments = store.sharedDocuments.filter((d) => !d.invoice);

    let sortedTemplates = store.templates.sort((l, r) => {
      return (
        moment(r.creationDate).valueOf() - moment(l.creationDate).valueOf()
      );
    });

    let filteredLabels = store.labels.filter((l) => l.type === 2);
    let sortedLabels = filteredLabels.sort((l1, l2) =>
      l1.name.localeCompare(l2.name)
    );

    let sortedFriends = store.friends.sort((f1, f2) => {
      let firstName = f1.firstName || "";
      return firstName.localeCompare(f2.firstName || "");
    });

    return {
      userProfile: store.userProfile,
      userSettings: store.userSettings,
      documentMap: store.documentMap,
      sharedDocuments,
      templates: sortedTemplates,
      labels: sortedLabels,
      caseMap: store.caseMap,
      friends: sortedFriends,
      userGroup: store.userGroup,
      showDropdowns: store.showDropdowns,
      locale: store.locale,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => {
          let props = this.mapStoreToProps(store);
          return (
            <DocumentTabView
              {...this.props}
              {...props}
              key={store.hideSidebar.toString()}
              handleNavigation={this.handleNavigation}
              queryDocuments={this.queryDocuments}
            />
          );
        }}
      </Consumer>
    );
  }
}

export default withRTKCaseData(
  withRTKCaseData(withRTKData(withRouter(DocumentTableContainer)))
);
