import React from "react";
import MultiPaymentSection from "./MultiPaymentSection";
import PropTypes from "prop-types";

import Select from "react-select";

import {
  ApiService,
  DataActions,
  ModalService,
} from "../../services/AxoServices";

import {
  Flexbox,
  FlexElement,
  LexButton,
  AsyncButton,
  AxoLocal,
  LoadingIndicator,
} from "../../utilities/LexUtilities";

import { ButtonToolbar } from "react-bootstrap";

export default class MultiPaymentView extends React.Component {
  static propTypes = {
    invoices: PropTypes.array.isRequired,
    clientLabels: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedInvoiceId: 0,
      paymentRequests: [],
      confirmDeletion: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    let { invoices } = this.props;
    let selectedInvoiceId = invoices.length > 0 ? invoices[0].id : 0;
    this.setState({ selectedInvoiceId });
    if (!!selectedInvoiceId) {
      this.fetchPaymentRequests(selectedInvoiceId);
    }

    this.timer = setInterval(() => this.fetchPaymentRequests(), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps) {
    let { invoices } = this.props;

    if (invoices.length === prevProps.invoices.length) {
      return;
    }

    let selectedInvoiceId = invoices.length > 0 ? invoices[0].id : 0;
    this.setState({ selectedInvoiceId });
    if (!selectedInvoiceId) {
      return;
    }
    this.fetchPaymentRequests(selectedInvoiceId);
  }

  fetchPaymentRequests = (invoiceId) => {
    let id = invoiceId || this.state.selectedInvoiceId;

    ApiService.getPaymentRequestsForInvoice(id).then((paymentRequests) => {
      this.setState({ paymentRequests });
    });
  };

  deletePaymentRequest = (contactId, invoiceId) => {
    this.setState((prevState) => ({
      paymentRequests: prevState.paymentRequests.filter(
        (p) => p.contactInfoId !== contactId || p.invoiceId !== invoiceId
      ),
    }));
    ApiService.deletePaymentRequest(contactId, invoiceId)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Request could not be deleted.");
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.fetchPaymentRequests();
      });
  };

  cancelRegistrationRequest = (guid) => {
    ModalService.openConfirmModal("Afmeld tilmelding?", async (result) => {
      if (!result) {
        return;
      }
      await ApiService.cancelRegistrationRequest(guid);
      this.fetchPaymentRequests();
    });
  };

  onSelectInvoice = (selection) => {
    if (!selection) {
      return;
    }

    let selectedInvoiceId = parseInt(selection.value, 10);
    this.setState({ selectedInvoiceId });
    this.fetchPaymentRequests(selectedInvoiceId);
  };

  onDelete = async () => {
    let { invoices } = this.props;
    let { selectedInvoiceId } = this.state;

    let invoice = invoices.find((i) => i.id === selectedInvoiceId);

    let response = await DataActions.deleteInvoice(invoice.id);
    this.setState({ confirmDeletion: false });

    return response.ok;
  };

  getIsRegistration = (invoice) => {
    return invoice.isRegistration;

    // if(!invoice.timeEntries || invoice.timeEntries.length === 0) {
    //   return true;
    // }

    // return invoice.timeEntries[0].rate === 0.00;
  };

  EmptyPage = () => {
    let { isSubscription, initializedInvoices } = this.props;

    if (!initializedInvoices) {
      return (
        <div className="standardMaxWidth" style={{ position: "relative" }}>
          <LoadingIndicator />
        </div>
      );
    }

    if (isSubscription) {
      return (
        <div className="leftPadding">
          <h4>Du har ikke oprettet nogen tilmeldinger.</h4>
          <h4>
            Tryk på fanen "Abonnementstilmelding" for at oprette en tilmelding.
          </h4>
        </div>
      );
    }
    return (
      <div className="leftPadding">
        <h4>Du har ikke oprettet nogen opkrævninger.</h4>
        <h4>Tryk på fanen "Opret" for at oprette en opkrævning.</h4>
      </div>
    );
  };

  render() {
    let {
      invoices,
      clientLabels,
      contacts,
      userProfile,
      sendMailToList,
      adminCodeValidated,
    } = this.props;

    let { selectedInvoiceId, paymentRequests, confirmDeletion } = this.state;

    let EmptyPage = this.EmptyPage;
    if (invoices.length === 0) {
      return <EmptyPage />;
    }

    let invoice = invoices.find((i) => i.id === selectedInvoiceId);
    if (!invoice) {
      return <EmptyPage />;
    }

    let invoiceOptions = invoices.map((i) => ({
      value: i.id,
      label: i.invoiceNo || i.number,
    }));
    let selection = invoiceOptions.find((s) => s.value === invoice.id);

    return (
      <div className="leftPadding">
        <Flexbox alignCenter className="onTop">
          <FlexElement flexGrow={1} className="rightPadding dropdown-yellow">
            <Select
              name="invoices"
              menuPlacement="auto"
              value={selection || { value: "", label: "" }}
              onChange={this.onSelectInvoice}
              noOptionsMessage={() => ""}
              options={invoiceOptions}
            />
          </FlexElement>
          <FlexElement>
            <LexButton
              disabled={confirmDeletion}
              onClick={() => this.setState({ confirmDeletion: true })}
            >
              Slet
            </LexButton>
          </FlexElement>
        </Flexbox>
        {confirmDeletion ? (
          <Flexbox className="topPadding bottomPadding">
            <FlexElement className="rightPadding">
              <div className="axored mediumText">
                <AxoLocal
                  entity="axoidcode222"
                  defaultValue={
                    "Dette vil slette opkrævningen og alle betalingslinks. Er du sikker?"
                  }
                />
              </div>
            </FlexElement>
            <FlexElement>
              <ButtonToolbar>
                <AsyncButton onClick={this.onDelete}>
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </AsyncButton>
                <LexButton
                  onClick={() => this.setState({ confirmDeletion: false })}
                >
                  <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
                </LexButton>
              </ButtonToolbar>
            </FlexElement>
          </Flexbox>
        ) : null}
        <MultiPaymentSection
          invoice={invoice}
          startOpen
          clientLabels={clientLabels}
          contacts={(contacts || []).filter((c) => !!c.eMail)}
          paymentRequests={paymentRequests}
          updateRequests={this.fetchPaymentRequests}
          deletePaymentRequest={this.deletePaymentRequest}
          cancelRegistrationRequest={this.cancelRegistrationRequest}
          userProfile={userProfile}
          sendMailToList={sendMailToList}
          isRegistration={this.getIsRegistration(invoice)}
          adminCodeValidated={adminCodeValidated}
        />
      </div>
    );
  }
}
