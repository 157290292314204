import React from "react";
import AccountingEntriesView from "./AccountingEntriesView";
import { DataStore, StorageService } from "../../services/AxoServices";
import { Consumer } from "../../utilities/LexUtilities";
export default class AccountingEntriesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      forceShowCards: StorageService.loadItem(
        "showClientBookkeepingCards",
        false
      ),
    };
  }

  componentDidMount() {
    DataStore.fetchBookkeepingDraftEntries();
    DataStore.initializeUserProfile();
  }

  onToggleCards = () => {
    let forceShowCards = !this.state.forceShowCards;
    this.setState({ forceShowCards });
    StorageService.saveItem("showClientBookkeepingCards", forceShowCards);
  };

  render() {
    return (
      <Consumer>
        {(store) => {
          let uploadedEntries = store.bookKeepingDraftEntries.filter(
            (e) => e.status !== "Approved"
          );
          console.log(store.myContactInfo);
          return (
            <AccountingEntriesView
              showControls
              entries={uploadedEntries}
              userProfile={store.userProfile}
              myContactInfo={store.myContactInfo}
              forceShowCards={this.state.forceShowCards}
              onToggleCards={this.onToggleCards}
              scanReceipts={!store.myContactInfo.skipReceiptScan}
              convertToPDF={!store.myContactInfo.skipPDFConversion}
            />
          );
        }}
      </Consumer>
    );
  }
}
