import React from "react";
import { MediumOrLarger, SmallOrSmaller } from "../utilities/Responsive";
import { Route, Switch, Redirect } from "react-router-dom";

import { DropdownButton, MenuItem } from "react-bootstrap";

import { DataStore, DataActions, ApiService } from "../services/AxoServices";

import {
  Icon,
  AxoLocal,
  LexNavButton,
  withRouter,
} from "../utilities/LexUtilities";

import PowerOfAttorneyFormView from "./PowerOfAttorneyFormView";
import PowerOfAttorneyTableView from "./PowerOfAttorneyTableView";
import PowerOfAttorneyDetailsView from "./PowerOfAttorneyDetailsView";

class PowerOfAttorneyTabView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      //Internal state here
    };
  }

  handleNavigation = (path) => {
    let { match } = this.props;

    this.props.history.push(match.path + "/" + path);
  };

  renderLargeScreenNavigationBar = () => {
    let { showDropdowns, location } = this.props;

    let path = location.pathname;

    return (
      <MediumOrLarger>
        <div className="axo-nav-lg" id="noprint">
          {!showDropdowns ? (
            <div className="nopadding paddingToppx tabBannerRow">
              <div
                className="btn-group btn-group-justified"
                role="group"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  paddingLeft: "2px",
                }}
              >
                <LexNavButton
                  className="axonavTab axonavTabcolora"
                  ls
                  glyph="icon-fontello-plus-3"
                  eventKey="Create"
                  path={path}
                  onClick={this.handleNavigation}
                >
                  Opret Fuldmagt
                </LexNavButton>
                <LexNavButton
                  className="axonavTab axonavTabcolorb"
                  ls
                  glyph="icon-fontello-user-add"
                  eventKey="List"
                  path={path}
                  onClick={this.handleNavigation}
                >
                  <AxoLocal
                    entity="CalendarTabViewagenda"
                    defaultValue={"Oversigt"}
                  />
                  (Fuldmagt)
                </LexNavButton>
                <LexNavButton
                  ls
                  listButton
                  className="axonavTab showListButton"
                  onClick={() => {
                    DataStore.setShowDropDowns(true);
                  }}
                >
                  <div className="flexbox-center">
                    <Icon
                      glyph="icon-fontello-align-justify"
                      style={{ fontSize: "30px" }}
                    />
                    &nbsp;
                    <AxoLocal
                      entity="AccountingTabViewEntity66"
                      defaultValue={"Liste Menu"}
                    />
                  </div>
                </LexNavButton>
              </div>
            </div>
          ) : (
            <div style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab axonavTabcolora AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("Create")}
                >
                  Opret Fuldmagt
                </MenuItem>
                <MenuItem
                  className="axonavTab axonavTabcolorb AxotabDropdownmenu"
                  onClick={() => this.handleNavigation("List")}
                >
                  <AxoLocal
                    entity="CalendarTabViewagenda"
                    defaultValue={"Oversigt"}
                  />
                </MenuItem>
              </DropdownButton>
            </div>
          )}
        </div>
      </MediumOrLarger>
    );
  };

  renderSmallScreenNavigationBar = () => {
    return (
      <SmallOrSmaller>
        <div className="axo-nav" id="noprint">
          <div style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("Create")}
              >
                Opret Fuldmagt
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleNavigation("List")}
              >
                <AxoLocal entity="axoEntityidcode267" defaultValue={"Liste"} />
              </MenuItem>
            </DropdownButton>
          </div>
        </div>
      </SmallOrSmaller>
    );
  };
  onSubmit = async (template) => {
    let response = await DataActions.createPowerOfAttorney(template);

    if (!response.ok) {
      return false;
    }

    this.handleNavigation("List");
  };

  onSendMail = async (template) => {
    //Sends a mail from admin@axolex.com
    let response = await ApiService.sendContactMail({
      to: "info@axolex.com",
      subject: template.text,
      body: template.longText,
    });

    return response.ok;
  };

  onGotoDetails = (id) => {
    this.handleNavigation("Details/" + id);
  };

  render() {
    let { match, powerOfAttorneys, userProfile } = this.props;

    return (
      <div>
        {this.renderSmallScreenNavigationBar()}
        {this.renderLargeScreenNavigationBar()}
        <Switch>
          <Route
            path={match.path + "/Create"}
            render={(props) => (
              <PowerOfAttorneyFormView
                {...props}
                userProfile={userProfile}
                onSubmit={this.onSubmit}
              />
            )}
          />
          <Route
            path={match.path + "/List"}
            render={(props) => (
              <PowerOfAttorneyTableView
                {...props}
                powerOfAttorneys={powerOfAttorneys}
                onGotoDetails={this.onGotoDetails}
              />
            )}
          />
          <Route
            path={match.path + "/Details/:id"}
            render={(props) => (
              <PowerOfAttorneyDetailsView
                {...props}
                userProfile={userProfile}
                models={powerOfAttorneys}
              />
            )}
          />
          <Route
            path={match.path}
            render={(props) => (
              <Redirect {...props} to={match.path + "/Create"} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(PowerOfAttorneyTabView);
