import React from "react";

import { ApiService } from "../services/AxoServices";

import { withRouter, LoadingIndicator } from "../utilities/LexUtilities";

import QueryString from "query-string";

class PaymentConfirmationView extends React.PureComponent {
  componentDidMount() {
    let query = QueryString.parse(this.props.location.search);
    let code = query.code;

    //The ScanPay webhook, should register payment, but we need to ensure
    //that payment has been registered before redirecting.
    ApiService.registerPayment({ guid: code }).then((response) => {
      if (response.ok) {
        this.props.history.push("/InvoicePayment?code=" + query.code);
      }
    });
  }

  render() {
    return <LoadingIndicator />;
  }
}

export default withRouter(PaymentConfirmationView);
