import React from "react";
import CountryRegionDataEN from "./source-data-en.js";
import CountryRegionDataDA from "./source-data-da.js";
import CountryRegionDataCH from "./source-data-ch.js";
import PropTypes from "prop-types";
import ReactOption from "../ReactOption";
import ReactSelect from "react-select";

import { LocalizationService } from "../../services/AxoServices";

import { AxoLocal } from "../../utilities/LexUtilities";
import { getText } from "../L20n/L20n.js";

const C = {
  DISPLAY_TYPE_FULL: "full",
  DISPLAY_TYPE_SHORT: "short",
  REGION_LIST_DELIMITER: "|",
  SINGLE_REGION_DELIMITER: "~",
};

class CountryDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    var data = this.getLocaleData("enUS");
    this.state = {
      countries: _filterCountries(data, props.whitelist, props.blacklist),
    };
  }

  componentDidMount() {
    LocalizationService.subscribeToLocale("CountryDropdown", (locale) => {
      var data = this.getLocaleData(locale);
      this.setState({
        countries: _filterCountries(
          data,
          this.props.whitelist,
          this.props.blacklist
        ),
      });
    });
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale("CountryDropdown");
  }

  getLocaleData = (locale) => {
    switch (locale) {
      case "enUS":
        return CountryRegionDataEN;
      case "da":
        return CountryRegionDataDA;
      case "ch":
        return CountryRegionDataCH;
      default:
        return CountryRegionDataEN;
    }
  };

  getCountries() {
    const { valueType, labelType } = this.props;

    return this.state.countries.map(([countryName, countrySlug]) => {
      if (labelType === C.DISPLAY_TYPE_SHORT) {
        return (
          <option
            value={
              valueType === C.DISPLAY_TYPE_SHORT ? countrySlug : countryName
            }
            key={countrySlug}
          >
            {countrySlug}
          </option>
        );
      }
      return (
        <option
          value={valueType === C.DISPLAY_TYPE_SHORT ? countrySlug : countryName}
          key={countrySlug}
        >
          {countryName}
        </option>
      );
    });
  }

  getCountryOptions() {
    const { valueType, labelType } = this.props;

    return this.state.countries.map(([countryName, countrySlug]) => {
      let value =
        valueType === C.DISPLAY_TYPE_SHORT ? countrySlug : countryName;
      let label =
        labelType === C.DISPLAY_TYPE_SHORT ? countrySlug : countryName;
      return { value, label };
    });
  }

  getDefaultCountryOption() {
    const { showDefaultOption } = this.props;
    if (!showDefaultOption) {
      return null;
    }
    return { value: "", label: getText("CountrySelectDefaultOption") };
  }

  getDefaultOption() {
    const { showDefaultOption } = this.props;
    if (!showDefaultOption) {
      return null;
    }
    return (
      <AxoLocal
        componentClass={ReactOption}
        value=""
        componentAttribute="text"
        entity="CountrySelectDefaultOption"
      />
    );
  }

  render() {
    const {
      name,
      id,
      classes,
      value,
      onChange,
      disabled,
      large,
      showDefaultOption,
      customSelect,
    } = this.props;

    if (customSelect) {
      let options = this.getCountryOptions();
      if (showDefaultOption) {
        options = [this.getDefaultCountryOption()].concat(options);
      }

      return (
        <ReactSelect
          menuPlacement="auto"
          value={options.find((o) => o.value === value)}
          onChange={(selection) => {
            if (!selection) {
              return;
            }
            onChange(selection.value);
          }}
          noOptionsMessage={() => ""}
          options={options}
        />
      );
    }

    const attrs = {
      name,
      value,
      onChange: (e) => onChange(e.target.value),
      disabled,
    };
    if (id) {
      attrs.id = id;
    }
    if (classes) {
      attrs.className = classes;
    }

    attrs.style = large
      ? { height: "50px", width: "100%", fontSize: "18px" }
      : { width: "100%" };
    return (
      <select className="axoblue selectbg" {...attrs}>
        {this.getDefaultOption()}
        {this.getCountries()}
      </select>
    );
  }
}

CountryDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  id: PropTypes.string,
  classes: PropTypes.string,
  showDefaultOption: PropTypes.bool,
  defaultOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  labelType: PropTypes.oneOf([C.DISPLAY_TYPE_FULL, C.DISPLAY_TYPE_SHORT]),
  valueType: PropTypes.oneOf([C.DISPLAY_TYPE_FULL, C.DISPLAY_TYPE_SHORT]),
  whitelist: PropTypes.array,
  blacklist: PropTypes.array,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
};
CountryDropdown.defaultProps = {
  value: "",
  name: "rcrs-country",
  id: "",
  classes: "",
  showDefaultOption: true,
  defaultOptionLabel: "Select Country",
  onChange: () => {},
  labelType: C.DISPLAY_TYPE_FULL,
  valueType: C.DISPLAY_TYPE_FULL,
  whitelist: [],
  blacklist: [],
  disabled: false,
};

class RegionDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { regions: this.getRegions(props.country) };
    this.getRegions = this.getRegions.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.country !== this.props.country ||
      nextProps.value !== this.props.value
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.country === this.props.country) {
      return;
    }
    this.setState({ regions: this.getRegions(nextProps.country) });
  }

  getRegions(country) {
    if (!country) {
      return [];
    }

    const { countryValueType } = this.props;
    const searchIndex = countryValueType === C.DISPLAY_TYPE_FULL ? 0 : 1;
    const regions = CountryRegionDataEN.find((i) => {
      return i[searchIndex] === country;
    });

    // this could happen if the user is managing the state of the region/country themselves and screws up passing
    // in a valid country
    if (!regions) {
      console.error(
        "Error. Unknown country passed: " +
          country +
          '. If you\'re passing a country shortcode, be sure to include countryValueType="short" on the RegionDropdown'
      );
      return [];
    }
    return regions[2].split(C.REGION_LIST_DELIMITER).map((regionPair) => {
      let [regionName, regionShortCode = null] = regionPair.split(
        C.SINGLE_REGION_DELIMITER
      );
      return { regionName, regionShortCode };
    });
  }

  getRegionList() {
    const { labelType, valueType } = this.props;
    return this.state.regions.map(({ regionName, regionShortCode }) => {
      const label =
        labelType === C.DISPLAY_TYPE_FULL ? regionName : regionShortCode;
      const value =
        valueType === C.DISPLAY_TYPE_FULL ? regionName : regionShortCode;
      return (
        <option value={value} key={regionName}>
          {label}
        </option>
      );
    });
  }

  getRegionOptions() {
    const { labelType, valueType } = this.props;
    return this.state.regions.map(({ regionName, regionShortCode }) => {
      const label =
        labelType === C.DISPLAY_TYPE_FULL ? regionName : regionShortCode;
      const value =
        valueType === C.DISPLAY_TYPE_FULL ? regionName : regionShortCode;
      return { value, label };
    });
  }

  getDefaultRegionOption() {
    const { blankOptionLabel, showDefaultOption, country } = this.props;
    if (!country) {
      return { value: "", label: blankOptionLabel };
    }
    if (showDefaultOption) {
      return { value: "", label: getText("RegionSelectDefaultOption") };
    }
    return null;
  }

  // there are two default options. The "blank" option which shows up when the user hasn't selected a country yet, and
  // a "default" option which shows
  getDefaultOption() {
    const { blankOptionLabel, showDefaultOption, country } = this.props;
    if (!country) {
      return <option value="">{blankOptionLabel}</option>;
    }
    if (showDefaultOption) {
      return (
        <AxoLocal
          componentClass={ReactOption}
          value=""
          componentAttribute="text"
          entity="RegionSelectDefaultOption"
        />
      );
    }
    return null;
  }

  render() {
    const {
      value,
      country,
      onChange,
      id,
      name,
      classes,
      disabled,
      disableWhenEmpty,
      large,
      customSelect,
    } = this.props;
    const isDisabled = disabled || (disableWhenEmpty && country === "");

    if (customSelect) {
      let options = this.getRegionOptions();
      let defaultOption = this.getDefaultRegionOption();
      if (!!defaultOption) {
        options = [defaultOption].concat(options);
      }

      return (
        <ReactSelect
          menuPlacement="auto"
          value={options.find((o) => o.value === value)}
          onChange={(selection) => {
            if (!selection) {
              return;
            }
            onChange(selection.value);
          }}
          noOptionsMessage={() => ""}
          options={options}
        />
      );
    }

    const attrs = {
      name,
      value,
      onChange: (e) => onChange(e.target.value),
      disabled: isDisabled,
    };
    if (id) {
      attrs.id = id;
    }
    if (classes) {
      attrs.className = classes;
    }

    attrs.style = large
      ? { height: "50px", width: "100%", fontSize: "18px" }
      : { width: "100%" };

    return (
      <select className="axoblue selectbg" {...attrs}>
        {this.getDefaultOption()}
        {this.getRegionList()}
      </select>
    );
  }
}
RegionDropdown.propTypes = {
  country: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  id: PropTypes.string,
  classes: PropTypes.string,
  blankOptionLabel: PropTypes.string,
  showDefaultOption: PropTypes.bool,
  defaultOptionLabel: PropTypes.string,
  onChange: PropTypes.func,
  labelType: PropTypes.string,
  valueType: PropTypes.string,
  disabled: PropTypes.bool,
  disableWhenEmpty: PropTypes.bool,
  large: PropTypes.bool,
};
RegionDropdown.defaultProps = {
  country: "",
  value: "",
  name: "rcrs-region",
  id: "",
  classes: "",
  blankOptionLabel: "-",
  showDefaultOption: true,
  defaultOptionLabel: "Select Region",
  onChange: () => {},
  countryValueType: C.DISPLAY_TYPE_FULL,
  labelType: C.DISPLAY_TYPE_FULL,
  valueType: C.DISPLAY_TYPE_FULL,
  disabled: false,
  disableWhenEmpty: false,
};

// ------------------------- helpers --------------------------------

// called on country field initialization. It reduces the subset of countries depending on whether the user
// specified a white/blacklist
function _filterCountries(countries, whitelist, blacklist) {
  var filteredCountries = countries;

  // N.B. I'd rather use ES6 array.includes() but it requires a polyfill on various browsers. Bit surprising that
  // babel doesn't automatically convert it to ES5-friendly code, like the new syntax additions, but that requires
  // a separate polyfill which is a total kludge
  if (whitelist.length > 0) {
    filteredCountries = countries.filter(([, countrySlug]) => {
      return whitelist.indexOf(countrySlug) > -1;
    });
  } else if (blacklist.length > 0) {
    filteredCountries = countries.filter(([, countrySlug]) => {
      return blacklist.indexOf(countrySlug) === -1;
    });
  }

  return filteredCountries;
}

export { CountryDropdown, RegionDropdown, CountryRegionDataEN };
