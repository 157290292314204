import React from "react";
import Autosuggest from "react-autosuggest";

let theme = {
  container: {
    position: "relative",
  },
  input: {
    width: 240,
    height: 30,
    padding: "10px 20px",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 12,
    border: "1px solid #aaa",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 30,
    width: 280,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
    maxHeight: "500px",
    overflowY: "auto",
    marginBottom: "5px",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#e6e6e6",
  },
};

//Props
//labels
//handleSelectedLabel
//disabled
export default class LabelSearchBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      suggestions: [],
    };
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? this.props.labels
      : this.props.labels.filter((label) => {
          return label.name.toLowerCase().includes(inputValue);
        });
  };

  // When suggestion is clicked, Autosuggest needs to populate the input element
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.name;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => (
    <div>
      {suggestion.name}&nbsp;
      {!!suggestion.id && suggestion.id > 0 ? ( //Necessary since we sometimes use dummy labels for special effects
        <span>({this.getLabelCount(suggestion)})</span>
      ) : null}
    </div>
  );

  getLabelCount = (label) => {
    return (
      label.messageIds.length +
      label.caseIds.length +
      label.documentIds.length +
      label.clientIds.length
    );
  };

  onChange = (event, { newValue }) => {
    this.setState({
      searchValue: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.handleSelectedLabel(suggestion);
    if (this.props.clearOnSelect) {
      this.setState(
        {
          searchValue: "",
          suggestions: [],
        },
        () => {
          document.activeElement.blur();
        }
      );
    }
  };

  shouldRenderSuggestions(value) {
    return true;
  }

  //Props
  //placeholder
  render() {
    let { placeholder, disabled, inputBorder, maxWidth } = this.props;
    let { searchValue } = this.state;

    const inputProps = {
      className: "searchbox",
      placeholder: placeholder,
      value: searchValue,
      disabled: disabled,
      onChange: this.onChange,
    };
    if (inputBorder) {
      theme.input.border = inputBorder;
    }
    if (maxWidth) {
      theme.input.maxWidth = maxWidth;
      theme.input.display = "block";
      theme.input.width = "100%";
    }

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        theme={theme}
      />
    );
  }
}
