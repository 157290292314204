import React from "react";
import Lock from "./Lock";

import { DataStore } from "../services/AxoServices";

import { LoadingIndicator, Consumer } from "../utilities/LexUtilities";

export default class LockContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserSettings();
    DataStore.initializeUserProfile();
  }

  render() {
    return (
      <Consumer>
        {(store) => {
          if (!store.loaded || !store.userProfile.id) {
            return <LoadingIndicator />;
          }
          return (
            <Lock
              userSettings={store.userSettings}
              userProfile={store.userProfile}
            />
          );
        }}
      </Consumer>
    );
  }
}
