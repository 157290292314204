import React from "react";

import { Row, Col, Grid } from "react-bootstrap";

import { AxoLocal, AxoFooter } from "../utilities/LexUtilities";

export default class RequestConfirmEmail extends React.PureComponent {
  render() {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} className="nopadding">
            <div className="frontPageaxolexdiv Bannecolorlogin">
              <AxoLocal
                entity="signupViewConfirmEmail"
                defaultValue="Bekræft email"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            <div style={{ paddingTop: "80px", paddingBottom: "10px" }}>
              <div className="largeText">
                <AxoLocal
                  entity="confirmEmailMessage"
                  defaultValue="Der er blevet sendt et bekræftelses link til din valgte email."
                />
              </div>
              <div className="largeText">
                <AxoLocal
                  entity="confirmEmailMessageResend"
                  defaultValue="Der er blevet sendt et bekræftelses link til din valgte email."
                />
              </div>
            </div>
          </Col>
        </Row>
        <AxoFooter />
      </Grid>
    );
  }
}
