import React from "react";
import Autosuggest from "react-autosuggest";
import ApiService from "../../services/DataAccess/ApiService";
import FileInfoService from "../../services/FileInfoService";
import { debounce } from "lodash";

var theme = {
  container: {
    position: "relative",
  },
  input: {
    maxWidth: 240,
    height: 30,
    padding: "10px 20px",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 12,
    border: "1px solid #aaa",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 30,
    width: 280,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
    maxHeight: "500px",
    overflowY: "auto",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#e6e6e6",
  },
};

//Props
//documents
//handleSelectedDocument
//placeholder
//disabled
export default class DocumentSearchBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      suggestions: [],
      documents: props.documentMap.documents,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      documents: nextProps.documentMap.documents,
    });
  }

  isDocX = (document) => {
    var fileType = document.fileType.toLowerCase();
    return fileType === ".docx";
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    var { documents } = this.state;
    var { documentMap, onlyImages, onlyDocX } = this.props;

    if (!documents) {
      return [];
    }

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    //If not all documents have been loaded into memory, perform a server-side search
    if (
      !!documentMap.count &&
      documentMap.documents.length < documentMap.count
    ) {
      this.getDocuments(inputValue);
    }

    return documents.filter((document) => {
      if (onlyImages && !FileInfoService.isImage(document)) {
        return false;
      }
      if (onlyDocX && !this.isDocX(document)) {
        return false;
      }
      if (inputLength === 0) {
        return true;
      }
      return document.fileName.toLowerCase().includes(inputValue);
    });
  };

  getDocuments = debounce((searchText) => {
    var { onlyImages, onlyDocX, queryParams } = this.props;

    let query = { searchText };
    if (!!queryParams) {
      query = {
        ...query,
        ...queryParams,
      };
    }

    ApiService.getDocuments(25, query).then((documentMap) => {
      var documents = documentMap.documents;
      if (onlyImages) {
        documents = documents.filter((d) => FileInfoService.isImage(d));
      }
      if (onlyDocX) {
        documents = documents.filter((d) => this.isDocX(d));
      }

      this.setState({
        documents,
        suggestions: documents,
      });
    });
  }, 350);

  // When suggestion is clicked, Autosuggest needs to populate the input element
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.fileName;

  // getThumbnail = (document) => {
  //   if(FileInfoService.isImage(document)) {
  //       return <span><img alt=''  width='35px' src={ApiService.getFileInlinePath(document.id)}/>&nbsp;</span>
  //     }
  //     return null;
  // }

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => (
    <div>
      {/* {this.getThumbnail(suggestion)} */}
      {suggestion.fileName}
    </div>
  );

  onChange = (event, { newValue }) => {
    this.setState({
      searchValue: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.handleSelectedDocument(suggestion);
    if (this.props.clearOnSelect) {
      this.setState(
        {
          searchValue: "",
          suggestions: [],
        },
        () => {
          document.activeElement.blur();
        }
      );
    }
  };

  shouldRenderSuggestions(value) {
    return true;
  }

  render() {
    var searchValue = this.state.searchValue;
    const inputProps = {
      className: "searchbox",
      placeholder: this.props.placeholder,
      value: searchValue,
      disabled: !!this.props.disabled,
      onChange: this.onChange,
    };
    if (this.props.inputBorder) {
      theme.input.border = this.props.inputBorder;
    }
    if (this.props.maxWidth) {
      theme.input.maxWidth = this.props.maxWidth;
      theme.input.display = "block";
      theme.input.width = "100%";
    }
    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        theme={theme}
      />
    );
  }
}
