import React from "react";

import { InputGroup, FormControl, Button, ButtonGroup } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

//Offentligt link og deleknapper til Facebook, Twitter og Linkedin
export default class PresentationLink extends React.PureComponent {
  //Props
  //url
  onOpenUrl = (url, event) => {
    event.preventDefault();
    window.open(url);
  };

  onCopyLink = () => {
    var copyText = document.querySelector("#link");
    copyText.select();
    document.execCommand("Copy");
  };

  render() {
    let { url, includeLinkBox } = this.props;
    let facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=" + url;
    let twitterUrl = "https://twitter.com/home?status=" + url;
    let linkedInUrl =
      "https://www.linkedin.com/shareArticle?mini=true&url=" + url;

    return (
      <div id="PresentationLink" style={{ maxWidth: "600px", margin: "auto" }}>
        {includeLinkBox ? (
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <ButtonGroup justified>
              <ButtonGroup
                style={{
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <Button
                  onClick={this.onOpenUrl.bind(this, url)}
                  className="Lex-button-1xlink"
                >
                  <AxoLocal
                    entity="presentationMarketing28"
                    defaultValue="Åben offentlig side"
                  />
                </Button>
              </ButtonGroup>
              <ButtonGroup
                style={{
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <Button onClick={this.onCopyLink} className="Lex-button-1xlink">
                  <AxoLocal
                    entity="presentationMarketing16"
                    defaultValue="Kopier link"
                  />
                </Button>
              </ButtonGroup>
            </ButtonGroup>
            <br />
            <InputGroup>
              <InputGroup.Addon>
                <Icon
                  className="editable text-center"
                  glyph="icon-fontello-link-1"
                />
              </InputGroup.Addon>
              <FormControl id="link" type="text" readOnly value={url} />
            </InputGroup>
            {/* <input readOnly type='text' value={link} style={{ width: '90%' }}/> */}
          </div>
        ) : null}
        <div
          className="flexbox-layout"
          style={{
            paddingLeft: "7px",
            paddingRight: "7px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <span>
            <span className="delButtoncolor editable text-center icon-fontello-share-1" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <AxoLocal entity="presentationMarketing3" defaultValue="Del" />:
          </span>
          <span>
            <a
              onClick={this.onOpenUrl.bind(this, facebookUrl)}
              href={facebookUrl}
            >
              <span className="AxoNavigationheightIcon icon-fontello-facebook-squared-1" />
            </a>
          </span>
          <span>
            <a
              onClick={this.onOpenUrl.bind(this, twitterUrl)}
              href={twitterUrl}
            >
              <span className="AxoNavigationheightIcon icon-fontello-twitter" />
            </a>
          </span>
          <span>
            <a
              onClick={this.onOpenUrl.bind(this, linkedInUrl)}
              href={linkedInUrl}
            >
              <span className="AxoNavigationheightIcon icon-fontello-linkedin-6" />
            </a>
          </span>
        </div>
      </div>
    );
  }
}
