import React from "react";
import { AxoListGroup } from "./AxoListGroup";
import * as $ from "jquery";

export default class DragDropList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.placeholder = document.createElement("div");
    this.placeholder.className = "drag-drop-placeholder";
    this.placeholder.id = "drag-drop-placeholder";
  }

  dragStart(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", this.dragged);
  }

  dragEnd(e) {
    this.dragged.style.display = "block";
    this.placeholder.parentNode.removeChild(this.placeholder);
    if (!this.over) {
      return;
    }
    // update state
    var { order, updateOrder } = this.props;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over.dataset.id);
    if (from < to) {
      to--;
    }
    order.splice(to, 0, order.splice(from, 1)[0]);
    updateOrder(order);
  }

  dragOver(e) {
    e.preventDefault();
    this.dragged.style.display = "none";
    if (e.target.className === "placeholder") return;
    this.over = $(e.target).closest(".drop-target")[0];
    if (!this.over) {
      return;
    }
    this.over.parentNode.insertBefore(this.placeholder, this.over);
  }

  render() {
    var { items, order } = this.props;
    if (order.length > items.length) {
      return null;
    }
    var listItems = order.map((index, i) => {
      var item = items[index];
      return (
        <div
          data-id={i}
          key={i}
          className="drop-target"
          draggable
          onDragEnd={this.dragEnd.bind(this)}
          onDragStart={this.dragStart.bind(this)}
        >
          {item}
        </div>
      );
    });
    // var listItems = this.state.items.map((item, i) => {
    //   return (
    //     <div
    //       data-id={i}
    //       key={i}
    //       className='drop-target'
    //       draggable='true'
    //       onDragEnd={this.dragEnd.bind(this)}
    //       onDragStart={this.dragStart.bind(this)}>
    //       {item}
    //     </div>
    //   )
    //  });
    return (
      <AxoListGroup onDragOver={this.dragOver.bind(this)}>
        {listItems}
      </AxoListGroup>
    );
  }
}
