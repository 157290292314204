import React from "react";
import { withRouter } from "react-router-dom";
import Conditional from "../utilities/Conditional";
import QueryString from "query-string";

import {
  ApiService,
  // RoutingService
} from "../services/AxoServices";

// import {
//   AxoLocal,
// } from '../utilities/LexUtilities'

class AliPayReceiver extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
    };
  }

  componentDidMount() {
    // this.getStripeUserCredentials();
    let query = QueryString.parse(this.props.location.search);
    let source = query.source;

    ApiService.createSourceCharge({
      paymentRequestGuid: this.props.match.params.request,
      token: source,
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Payment could not be completed");
        }
        this.redirectToOrigin();
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ showErrorMessage: true });
        setTimeout(() => {
          this.setState({ showErrorMessage: false }, () => {
            this.redirectToOrigin();
          });
        }, 3000);
      });
  }

  redirectToOrigin = () => {
    this.props.history.push(
      "/InvoicePayment?code=" + this.props.match.params.request
    );
  };

  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
        }}
      >
        <Conditional show={this.state.showErrorMessage}>
          <h3>
            Payment could not be completed. Try again later.
            {/* <AxoLocal entity='SubscriptionViewPleasetryagainlater' defaultValue='Der skete en fejl. Prøv igen senere.'/> */}
          </h3>
        </Conditional>
        <Conditional show={!this.state.showErrorMessage}>
          <img
            alt=""
            src="/imgs/app/loading.gif"
            width="250px"
            className="img-circle"
          />
        </Conditional>
      </div>
    );
  }
}

export default withRouter(AliPayReceiver);
