import React from "react";
import moment from "moment";
import DataStore from "../../services/DataAccess/DataStore";
import RoutingService from "../../services/RoutingService";
import { ctx } from "../../utilities/L20n/L20n";
import Conditional from "../../utilities/Conditional";
import AxoDateTime from "../../utilities/AxoDateTime";
import ColorPicker from "./ColorPicker";
import { withRouter } from "react-router-dom";
import { AxoCheckbox, AxoLocal } from "../../utilities/LexUtilities";

import {
  Grid,
  Row,
  Col,
  Form,
  Button,
  ButtonToolbar,
  ControlLabel,
  FormGroup,
  FormControl,
  // InputGroup
} from "react-bootstrap";

import { Icon, SharingDropDown } from "../../utilities/LexUtilities";
import LexButton from "../../utilities/LexButton";

class CalendarEventBoxForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      //Interval time in minutes
      reminders: [
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventFormAtthetime"
                defaultValue={"På tidspunktet"}
              />
            </span>
          ),
          interval: 0,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm5m"
                defaultValue={"5 minutter før"}
              />
            </span>
          ),
          interval: 5,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm15m"
                defaultValue={"15 minutter før"}
              />
            </span>
          ),
          interval: 15,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm30m"
                defaultValue={"30 minutter før"}
              />
            </span>
          ),
          interval: 30,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm1t"
                defaultValue={"1 time før"}
              />
            </span>
          ),
          interval: 60,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm2t"
                defaultValue={"2 timer før"}
              />
            </span>
          ),
          interval: 120,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm1d"
                defaultValue={"1 dag før"}
              />{" "}
            </span>
          ),
          interval: 1440,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm2d"
                defaultValue={"2 dage før"}
              />
            </span>
          ),
          interval: 2880,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm1w"
                defaultValue={"1 uge før"}
              />
            </span>
          ),
          interval: 10080,
          selected: false,
        },
      ],
    };
    this.state = {
      //Interval time in minutes
      reminders: this.extractRemindersState(this.props.calendarEvent),
    };
    this.state.showBookingLink = false;
    this.state.eventSaved = false;
    this.state.eventSaveFailed = false;
    this.state.showTitleWarning = false;
  }

  //Props
  //calendarEvent
  //onCalendarEventUpdate
  //userProfile
  componentWillReceiveProps(nextProps) {
    this.setState({
      reminders: this.extractRemindersState(nextProps.calendarEvent),
    });
  }

  isValidInteger = (value) => {
    return Math.round(value) === value;
  };

  extractRemindersState = (calendarEvent) => {
    let reminders = this.state.reminders;
    reminders.forEach((r) => (r.selected = false));
    if (this.isValidInteger(calendarEvent.firstReminder)) {
      let first = reminders.find(
        (r) => r.interval === calendarEvent.firstReminder
      );
      first.selected = true;
    }
    if (this.isValidInteger(calendarEvent.secondReminder)) {
      let second = reminders.find(
        (r) => r.interval === calendarEvent.secondReminder
      );
      second.selected = true;
    }
    return reminders;
  };

  setEventRemindersFromRemindersState = (reminders) => {
    let calendarEvent = { ...this.props.calendarEvent };
    calendarEvent.firstReminder = null;
    calendarEvent.secondReminder = null;
    reminders.forEach((r) => {
      if (!r.selected) {
        return;
      }
      if (!this.isValidInteger(calendarEvent.firstReminder)) {
        calendarEvent.firstReminder = r.interval;
      } else if (!this.isValidInteger(calendarEvent.secondReminder)) {
        calendarEvent.secondReminder = r.interval;
      }
    });
    this.onChangeCalendarEvent(calendarEvent);
  };

  onEventPropertyChange = (propertyName, event) => {
    this.onChangeCalendarEvent({
      ...this.props.calendarEvent,
      [propertyName]: event.target.value,
    });
  };

  onStartDateChange = (startMoment) => {
    var event = { ...this.props.calendarEvent };
    var endDate = new Date(event.end);
    var startDate = new Date(event.start);
    var diff = endDate - startDate;

    event.start = new Date(startMoment);
    event.end = new Date(event.start.getTime() + diff);
    this.onChangeCalendarEvent(event);
  };

  onEndDateChange = (endMoment) => {
    this.onChangeCalendarEvent({
      ...this.props.calendarEvent,
      end: new Date(endMoment),
    });
  };

  onCheckAllDay = (event) => {
    this.onChangeCalendarEvent({
      ...this.props.calendarEvent,
      allDay: event.target.checked,
    });
  };

  onCheckBooking = (event) => {
    this.onChangeCalendarEvent({
      ...this.props.calendarEvent,
      bookingTime: event.target.checked,
    });
  };

  onSelectReminder = (reminder) => {
    if (this.props.readOnly) {
      return;
    }

    var reminders = [...this.state.reminders];
    var selectedReminder = reminders.find(
      (r) => r.interval === reminder.interval
    );
    if (!selectedReminder.selected && this.countSelected() > 1) {
      var allSelected = reminders.filter((r) => r.selected);
      allSelected[allSelected.length - 1].selected = false;
    }
    selectedReminder.selected = !selectedReminder.selected;
    this.setEventRemindersFromRemindersState(reminders);
    this.setState({ reminders });
  };

  onSelectColor = (color) => {
    if (this.props.readOnly) {
      return;
    }
    var event = Object.assign({}, this.props.calendarEvent);
    event.color = color;
    this.onChangeCalendarEvent(event);
  };

  onChangeCalendarEvent = (calendarEvent) => {
    this.props.onCalendarEventUpdate(calendarEvent);
  };

  countSelected = () => {
    var selectedCount = 0;
    for (var i = 0; i < this.state.reminders.length; ++i) {
      if (this.state.reminders[i].selected) {
        selectedCount++;
      }
    }
    return selectedCount;
  };

  renderReminder = (reminder) => {
    var selectedIcon = (
      <Icon
        className="editable"
        glyph="icon-fontello-ok-3"
        style={{ visibility: reminder.selected ? "visible" : "hidden" }}
      />
    );
    return (
      <div
        key={reminder.interval}
        onClick={this.onSelectReminder.bind(this, reminder)}
      >
        <small>
          {reminder.title}&nbsp;{selectedIcon}&nbsp;
        </small>
      </div>
    );
  };

  renderReminders = () => {
    var reminders = this.state.reminders;
    var reminderRows = [];
    for (var i = 0; i < reminders.length; i = i + 3) {
      reminderRows.push(
        <tr key={i}>
          <td>{this.renderReminder(reminders[i])}</td>
          {i <= reminders.length - 2 ? (
            <td>{this.renderReminder(reminders[i + 1])}</td>
          ) : null}
          {i <= reminders.length - 3 ? (
            <td>{this.renderReminder(reminders[i + 2])}</td>
          ) : null}
        </tr>
      );
    }
    return reminderRows;
  };

  renderRemindersTable = () => {
    return (
      <table style={{ display: "inline-block" }}>
        <tbody>{this.renderReminders()}</tbody>
      </table>
    );
  };

  renderRequiredStar = (requiredValue) => {
    var color = !!requiredValue ? "green" : "red";
    return <span style={{ color: color }}>* </span>;
  };

  toggleShowBookingLink = () => {
    this.setState({ showBookingLink: !this.state.showBookingLink });
  };

  getBookingUrl = () => {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/Booking/" +
      this.props.userProfile.userId
    );
  };

  makeEmbedLink = () => {
    var embed =
      '<div style="position:relative;height:0;padding-bottom:56.25%"><iframe src="';
    embed += this.getBookingUrl();
    embed +=
      '" width="640" height="360" frameborder="0" style="position:absolute;width:100%;height:100%;left:0" allowfullscreen></iframe></div>';
    return embed;
  };

  renderBookingLinks = (showBookingLink) => {
    if (!this.state.showBookingLink) {
      return null;
    }
    var bookingLinks = [];
    bookingLinks.push(
      <div key="link">
        <b>
          <AxoLocal
            entity="CalendarEventFormbookingLink"
            defaultValue={"Link"}
          />
          :{" "}
        </b>
        <span>{this.getBookingUrl()}</span>
      </div>
    );
    bookingLinks.push(
      <div key="embed">
        <b>
          <AxoLocal
            entity="CalendarEventFormbookingLinks"
            defaultValue={"Indlejring"}
          />
          :{" "}
        </b>
        <span>
          <input
            style={{ width: "75%" }}
            type="text"
            value={this.makeEmbedLink()}
          />
        </span>
      </div>
    );
    return bookingLinks;
  };

  onSave = () => {
    if (!this.props.calendarEvent.title) {
      this.setState({ showTitleWarning: true });
      return;
    }
    this.props
      .onSave()
      .then((response) => {
        if (response.ok) {
          this.setState({
            eventSaved: true,
            eventSaveFailed: false,
            showTitleWarning: false,
          });
          this.props.onReset();
          setTimeout(() => {
            this.setState({ eventSaved: false });
          }, 3000);
        } else {
          this.setState({
            eventSaveFailed: true,
          });
        }
      })
      .catch(() => {
        this.setState({
          eventSaveFailed: true,
        });
      });
  };

  onSendBookingLink = () => {
    this.props
      .onSave()
      .then((response) => {
        if (response.status === 200) {
          DataStore.setCachedMessage({
            sender: "",
            receiver: "",
            externalMailReceiver: "",
            subject: "",
            messageText:
              ctx.getSync("BookingContainersetCachedMessage") +
              this.getBookingUrl() +
              "</p>",
            draft: true,
            attachments: [],
          });
          this.props.history.push(RoutingService.getPath("mailbox/compose"));
        } else {
          this.setState({
            eventSaveFailed: true,
          });
        }
      })
      .catch(() => {
        this.setState({
          eventSaveFailed: true,
        });
      });
  };

  render() {
    var {
      calendarEvent,
      userGroup,
      userProfile,
      friends,
      onShare,
      onStopSharing,
      readOnly,
      showButtons,
      shared,
    } = this.props;
    var { showBookingLink } = this.state;

    console.log(calendarEvent);

    var yesterday = moment().subtract(1, "day");
    return (
      <Grid fluid style={{ paddingTop: "20px" }}>
        <Row>
          <Col xs={12} sm={10} smOffset={1}>
            <Form>
              {!readOnly && !!calendarEvent.id && onShare ? (
                <div
                  className="flexbox-responsive"
                  style={{ paddingBottom: "10px" }}
                >
                  <div style={{ paddingRight: "10px" }}>
                    <SharingDropDown
                      className="delButtoncolor"
                      title={
                        <span>
                          {" "}
                          <AxoLocal
                            entity="notificationseventsa"
                            defaultValue={"Del denne begivenhed"}
                          />
                        </span>
                      }
                      objectName={
                        <span>
                          {" "}
                          <AxoLocal
                            entity="notificationsevents"
                            defaultValue={"Begivenheder"}
                          />
                        </span>
                      }
                      userGroup={userGroup}
                      userProfile={userProfile}
                      friends={friends}
                      onShare={onShare}
                      hasSelectedEntries={true}
                    />
                  </div>
                  {shared ? (
                    <LexButton onClick={() => onStopSharing(calendarEvent)}>
                      <AxoLocal
                        entity="notificationseventsb"
                        defaultValue={"Fjern deling"}
                      />
                    </LexButton>
                  ) : null}
                </div>
              ) : null}
              <FormGroup controlId="title" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  {this.renderRequiredStar(calendarEvent.title)}
                  <AxoLocal
                    entity="CalendarEventBoxFormtitle"
                    defaultValue={"Titel"}
                  />
                </ControlLabel>
                <FormControl
                  type="text"
                  value={calendarEvent.title}
                  disabled={readOnly || calendarEvent.isDeadline}
                  onChange={this.onEventPropertyChange.bind(this, "title")}
                />
                {this.state.showTitleWarning ? (
                  <span className="axored">
                    <b>
                      <AxoLocal
                        entity="CalendarEventBoxFormshowTitleWarning"
                        defaultValue={"Begivenheden skal have en titel"}
                      />
                    </b>{" "}
                  </span>
                ) : null}
              </FormGroup>
              <FormGroup controlId="isDeadline" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  {!calendarEvent.isDeadline ? (
                    <AxoLocal
                      entity="CalendarEventBoxFormStart"
                      defaultValue={"Start"}
                    />
                  ) : (
                    <AxoLocal
                      entity="EventBoxFormDeadline"
                      defaultValue={"Deadline"}
                    />
                  )}
                </ControlLabel>
                {/* <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph='icon-fontello-calendar-6' />
                  </InputGroup.Addon>
                  <AxoDateTime
                    id='start'
                    inputProps={{ disabled: readOnly }}
                    value={moment(calendarEvent.start) } 
                    dateFormat={true} timeFormat={true} 
                    onChange={this.onStartDateChange} 
                    isValidDate={ (currentDate) => {
                      return currentDate.isAfter(yesterday);
                    }}
                  />
                </InputGroup> */}
                <AxoDateTime
                  id="start"
                  inputProps={{ disabled: readOnly }}
                  value={moment(calendarEvent.start)}
                  dateFormat={true}
                  timeFormat={true}
                  onChange={this.onStartDateChange}
                  isValidDate={(currentDate) => {
                    return currentDate.isAfter(yesterday);
                  }}
                />
              </FormGroup>
              {!calendarEvent.isDeadline ? (
                <span>
                  <FormGroup controlId="end" bsSize="large">
                    <ControlLabel
                      style={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                    >
                      <AxoLocal
                        entity="CalendarEventBoxFormSlut"
                        defaultValue={"Slut"}
                      />
                    </ControlLabel>
                    {/* <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph='icon-fontello-calendar-6' />
                      </InputGroup.Addon>
                        <AxoDateTime
                          id='end'
                          value={moment(calendarEvent.end) } 
                          dateFormat={true} timeFormat={true} 
                          onChange={this.onEndDateChange} 
                          inputProps={ {disabled: readOnly || calendarEvent.allDay }}
                          isValidDate={ (currentDate) => {
                            return currentDate.isAfter(moment(calendarEvent.start));
                          }}
                        />
                    </InputGroup> */}
                    <AxoDateTime
                      id="end"
                      value={moment(calendarEvent.end)}
                      dateFormat={true}
                      timeFormat={true}
                      onChange={this.onEndDateChange}
                      inputProps={{
                        disabled: readOnly || calendarEvent.allDay,
                      }}
                      isValidDate={(currentDate) => {
                        return !moment(calendarEvent.start).isAfter(currentDate);
                      }}
                    />
                  </FormGroup>
                  <div className="flexbox-standard">
                    <FormGroup
                      style={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                      bsSize="large"
                    >
                      <AxoCheckbox
                        disabled={readOnly}
                        checked={calendarEvent.allDay}
                        onChange={this.onCheckAllDay}
                      />
                      <AxoLocal
                        entity="CalendarEventBoxFormallDay"
                        defaultValue={"Hele dagen"}
                      />
                    </FormGroup>
                    &nbsp;&nbsp;&nbsp;
                    <FormGroup
                      style={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                      bsSize="large"
                    >
                      <AxoCheckbox
                        disabled={readOnly}
                        checked={calendarEvent.bookingTime}
                        onChange={this.onCheckBooking}
                      />
                      Tilmelding
                      {/* <AxoLocal entity='CalendarEventShowbookingTime' defaultValue={'Booking tid'}/> */}
                    </FormGroup>
                  </div>
                  <Conditional show={calendarEvent.bookingTime}>
                    <FormGroup
                      className="flexbox rightPadding"
                      controlId="capacity"
                    >
                      <ControlLabel
                        className="rightPadding"
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        Antal pladser
                      </ControlLabel>
                      <FormControl
                        type="number"
                        style={{ maxWidth: "200px" }}
                        className="borderFormControlfocus"
                        value={
                          isNaN(calendarEvent.bookingCapacity)
                            ? ""
                            : calendarEvent.bookingCapacity
                        }
                        onChange={(event) =>
                          this.onChangeCalendarEvent({
                            ...calendarEvent,
                            bookingCapacity: event.target.valueAsNumber,
                          })
                        }
                      />
                    </FormGroup>
                    <FormGroup
                      style={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                      bsSize="large"
                    >
                      <span>
                        &nbsp;&nbsp;
                        <Button
                          className="Lex-button-2"
                          onClick={this.toggleShowBookingLink}
                        >
                          {/* {showBookingLink ?  <AxoLocal key='Hide'entity='CalendarEventHidebookinglink' defaultValue={'Skjul booking link'}/>:
                          <AxoLocal key='Show'entity='CalendarEventShowbookinglink' defaultValue={'Vis booking link'}/>}  */}
                          {showBookingLink
                            ? "Skjul tilmeldingslink"
                            : "Vis tilmeldingslink"}
                        </Button>
                      </span>
                    </FormGroup>
                    <FormGroup
                      style={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                    >
                      {this.renderBookingLinks(
                        calendarEvent.bookingTime && showBookingLink
                      )}
                    </FormGroup>
                  </Conditional>
                  {!calendarEvent.bookingTime ? (
                    <ColorPicker
                      activeColor={this.props.calendarEvent.color}
                      onSelect={this.onSelectColor}
                    />
                  ) : null}
                  <FormGroup controlId="description" bsSize="large">
                    <ControlLabel
                      style={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                    >
                      {!!calendarEvent.bookingTime ? (
                        "Tilmeldinger"
                      ) : (
                        <AxoLocal
                          entity="Enhedspris6"
                          defaultValue={"Beskrivelse"}
                        />
                      )}
                    </ControlLabel>
                    <FormControl
                      componentClass="textarea"
                      disabled={readOnly}
                      rows={4}
                      value={calendarEvent.description}
                      onChange={this.onEventPropertyChange.bind(
                        this,
                        "description"
                      )}
                    />
                  </FormGroup>
                  <FormGroup controlId="reminders" bsSize="large">
                    <ControlLabel
                      style={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                    >
                      <AxoLocal
                        entity="CalendarEventBoxFormreminders"
                        defaultValue={"Påmindelser"}
                      />
                    </ControlLabel>
                    <div className="text-center" style={{ fontSize: "20px" }}>
                      {this.renderRemindersTable()}
                    </div>
                  </FormGroup>
                </span>
              ) : null}
            </Form>
            {!readOnly && showButtons ? (
              <Row style={{ textAlign: "center", paddingBottom: "10px" }}>
                <Col xs={12}>
                  <ButtonToolbar style={{ display: "inline-block" }}>
                    <Button className="Lex-button-2" onClick={this.onSave}>
                      <Icon glyph="icon-fontello-floppy-1" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="CalendarEventBoxFormonSave"
                        defaultValue={"Gem"}
                      />
                    </Button>
                    {/* {calendarEvent.bookingTime ? <Button className='Lex-button-2' onClick={this.onSendBookingLink}>
                      <Icon glyph="icon-fontello-link-3"/>&nbsp; &nbsp;
                      Gem og send tilmeldingslink
                      <AxoLocal entity='CalendarEventBoxFormcalendarEventbookingTime'defaultValue={'Gem og send booking link'}/> 
                    </Button> : null } */}
                    <Button
                      className="Lex-button-2"
                      onClick={this.props.onReset}
                    >
                      &#x21bb; &nbsp;&nbsp;
                      <AxoLocal
                        entity="CalendarEventBoxFormResetTime"
                        defaultValue={"Nulstil"}
                      />
                    </Button>
                  </ButtonToolbar>
                </Col>
                <div className="text-center">
                  {this.state.eventSaved ? (
                    <span className="axoblue">
                      <b>
                        <AxoLocal
                          entity="CalendarEventBoxFormeventSave"
                          defaultValue={"Begivenheden blev oprettet"}
                        />
                      </b>{" "}
                    </span>
                  ) : null}
                  {this.state.eventSaveFailed ? (
                    <span className="axoblue">
                      <b>
                        <AxoLocal
                          entity="CalendarEventBoxFormeeventSaveFailed"
                          defaultValue={
                            "Begivenheden kunne ikke gemmes. Prøv igen senere."
                          }
                        />
                      </b>{" "}
                    </span>
                  ) : null}
                </div>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(CalendarEventBoxForm);
