import React from "react";
import DocumentTableFixedView from "./DocumentTableFixedView";
import AudioRecorder from "../../utilities/AudioRecorder";
import VideoRecorder from "../../utilities/VideoRecorder";

import { Grid, Row, Col } from "react-bootstrap";

import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class DocumentRecordView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      recordedDocuments: [],
    };
  }

  onFileRecorded = (file) => {
    this.state.recordedDocuments.push(file.id);
    this.setState({ recordedDocuments: this.state.recordedDocuments });
    this.props.onFileUploaded(file);
  };

  getRecordedDocuments = () => {
    return this.props.documents.filter((f) =>
      this.state.recordedDocuments.find((id) => id === f.id)
    );
  };

  //Props
  //documents
  //labels
  //cases
  //onFileUploaded
  render() {
    var recordedDocuments = this.getRecordedDocuments();
    //Adds padding before the first panel when the table is not shown.
    var paddingStyle =
      recordedDocuments.length === 0 ? { paddingTop: "10px" } : null;
    return (
      <Grid fluid style={{ minHeight: "750px" }}>
        <Row>
          <Col xs={12} style={{ paddingLeft: "0px", paddingRight: "5px" }}>
            {recordedDocuments.length > 0 ? (
              <DocumentTableFixedView
                documents={this.getRecordedDocuments()}
                count={recordedDocuments.length}
                userProfile={this.props.userProfile}
                selectedContact={{}}
                labels={this.props.labels}
                caseMap={this.props.caseMap}
                friends={this.props.friends}
                height={150}
                forceShowCards={this.props.forceShowCards}
                onToggleCards={this.props.onToggleCards}
                includeControls={false}
                queryDocuments={() => {}}
                onFilesSubmitted={() => {}}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ paddingLeft: "0px", paddingRight: "5px" }}>
            <div style={paddingStyle}>
              <div>
                <div className="BanneDashboard">
                  <Grid fluid>
                    <Row>
                      <Col
                        xs={12}
                        style={{ paddingLeft: "0px", paddingRight: "5px" }}
                      >
                        <div
                          style={{ paddingLeft: "25px", paddingRight: "5px" }}
                        >
                          <h4>
                            <Icon glyph="icon-fontello-mic" />
                            &nbsp;&nbsp;&nbsp;
                            <AxoLocal
                              entity="DocumentRecordViewRecordsound"
                              defaultValue={"Optag lyd"}
                            />
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                </div>
                <br />
                <div>
                  <Grid fluid>
                    <Row>
                      <Col
                        xs={12}
                        style={{ paddingLeft: "0px", paddingRight: "5px" }}
                      >
                        <AudioRecorder onFileRecorded={this.onFileRecorded} />
                      </Col>
                    </Row>
                  </Grid>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ paddingLeft: "0px", paddingRight: "5px" }}>
            <div>
              <div style={{ minHeight: "500px" }}>
                <div className="BanneDashboard">
                  <Grid fluid>
                    <Row>
                      <Col
                        xs={12}
                        style={{ paddingLeft: "0px", paddingRight: "5px" }}
                      >
                        <div
                          style={{ paddingLeft: "25px", paddingRight: "5px" }}
                        >
                          <h4>
                            <Icon glyph="icon-fontello-videocam-2" />
                            &nbsp;&nbsp;&nbsp;
                            <AxoLocal
                              entity="DocumentRecordViewRecordVideo"
                              defaultValue={"Optag Video"}
                            />
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                </div>
                <br />
                <div>
                  <Grid fluid>
                    <Row>
                      <Col
                        xs={12}
                        style={{ paddingLeft: "0px", paddingRight: "5px" }}
                      >
                        <VideoRecorder onFileRecorded={this.onFileRecorded} />
                      </Col>
                    </Row>
                  </Grid>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
