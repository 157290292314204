import React, { useState, useEffect } from "react";

import moment from "moment";

import {
  ApiService,
  // DataActions
} from "../../services/AxoServices";

import {
  // UploadButton,
  LexButton,
  LoadingIndicator,
  AxoLocal,
} from "../../utilities/LexUtilities";

let settings, setSettings;
let loginMessage, setLoginMessage;
let useKeyFile, setUseKeyFile;
// let fileId, setFileId;

let signatureChunk = "";

function EasyIDSignatureViewV2(props) {
  [settings, setSettings] = useState(null);
  [loginMessage, setLoginMessage] = useState(null);
  [useKeyFile, setUseKeyFile] = useState(false);

  let { onSubmit, guid } = props;

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("message", onNemIDMessage);
      window.addEventListener("message", onCodeFileMessage);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", onNemIDMessage);
      window.attachEvent("onmessage", onCodeFileMessage);
    }

    console.log("Initial update");

    updateEasyIDSettings();
  }, []);

  const updateEasyIDSettings = async () => {
    try {
      let newSettings = await ApiService.getEasyIDSignatureRequestSettings(
        guid
      );

      setSettings(newSettings);
    } catch {
      setFailureMessage("Dokumentet kunne ikke indlæses. Prøv igen senere.");
    }
  };

  const onNemIDMessage = async (e) => {
    let event = e;

    if (event.origin !== "https://applet.danid.dk") {
      //window.alert(""Received message from unexpected origin : "" + event.origin + ""Expected origin: {0}"" );
      return;
    }

    let frame = document.getElementById("nemid_iframe");
    if (!frame) {
      console.log("Frame not found.");
      return;
    }

    let win = frame.contentWindow;
    let postMessage = {};
    let message = JSON.parse(event.data);
    console.log(message.command);

    if (message.command === "SendParameters") {
      postMessage.command = "parameters";
      console.log(settings);

      postMessage.content = settings.cardParameters;
      win.postMessage(JSON.stringify(postMessage), "https://applet.danid.dk");
    }

    if (message.command === "changeResponseAndSubmit") {
      console.log(message.content);
      if (message.content.length > 20) {
        onSubmit({
          signature: message.content,
          challenge: settings.challenge,
          textToBeSigned: settings.textToBeSigned,
        });
      } else {
        setFailureMessage(
          "Signaturen kunne ikke gennemføres. Prøv igen senere."
        );
      }
    }
  };

  const onCodeFileMessage = async (e) => {
    let event = e;
    var iframeOrigin = "https://codefileclient.danid.dk";
    if (iframeOrigin !== event.origin) {
      return;
    }

    let frame = document.getElementById("codefile_iframe");
    if (!frame) {
      return;
    }

    let win = frame.contentWindow;
    let postMessage = {};
    let message = JSON.parse(event.data);

    if (message.command === "SendParameters") {
      postMessage.command = "parameters";
      postMessage.content = settings.keyFileParameters;
      win.postMessage(
        JSON.stringify(postMessage),
        "https://codefileclient.danid.dk"
      );
    }

    console.log(message.command);
    if (message.command === "addChunk") {
      console.log(message);
    }
    if (message.command === "changeResponseAndSubmit") {
      if (message.content.length > 20) {
        onSubmit({
          signature: message.content,
          challenge: settings.challenge,
          textToBeSigned: settings.textToBeSigned,
        });
      } else {
        setFailureMessage("Login kunne ikke gennemføres. Prøv igen senere.");
        console.log(message.content);
      }
    }
    if (message.command === "signCancel") {
      setFailureMessage("Signering blev afbrudt");
    }
    if (message.command === "signError") {
      setFailureMessage("Fejl ved signering: " + message.content);
    }
    console.log(message.command);
    if (message.command === "addChunk") {
      signatureChunk = signatureChunk + message.content;
    }
    if (message.command === "allChunk") {
      onSubmit({
        signature: signatureChunk,
        challenge: settings.challenge,
        textToBeSigned: settings.textToBeSigned,
      });
      signatureChunk = "";
    }
  };

  const setFailureMessage = async (message) => {
    setLoginMessage(message);
    // await updateEasyIDSettings();

    // setTimeout(() => setLoginMessage(''), 3000);
    // setTimeout(() => {
    //   setLoginMessage("");
    // }, 3000);
  };

  if (!!loginMessage) {
    return <h4 className="leftPadding">{loginMessage}</h4>;
  }

  if (!settings) {
    return (
      <div>
        <h3>
          Dokumentet klargøres til underskrift. Dette kan tage op til et par
          minutter.
        </h3>
        <div style={{ position: "relative" }}>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  let { small } = props;

  if (useKeyFile) {
    return (
      <div className="leftPadding standardMaxWidth">
        <LexButton
          style={{ width: "100%" }}
          onClick={() => setUseKeyFile(false)}
        >
          <h4>
            <AxoLocal
              entity="axoEntityidcode252"
              defaultValue={"Brug nøglekort"}
            />
          </h4>
        </LexButton>
        <div id="frameHolder">
          <iframe
            id="codefile_iframe"
            key="card"
            name="target_iframe"
            title="NemID CodeFile client"
            scrolling="no"
            style={{
              width: small ? "320px" : "500px",
              height: small ? "350px" : "460px",
              border: "0px",
              margin: "0px",
              padding: "0px",
              overflow: "hidden",
            }}
            src={"https://codefileclient.danid.dk?t=" + moment().valueOf()}
          ></iframe>
        </div>
      </div>
    );
  }

  return (
    <div className="leftPadding standardMaxWidth">
      <LexButton style={{ width: "100%" }} onClick={() => setUseKeyFile(true)}>
        <h4>
          <AxoLocal
            entity="axoEntityidcode253"
            defaultValue={"Brug nøglefil"}
          />
        </h4>
      </LexButton>
      <div id="frameHolder">
        <iframe
          id="nemid_iframe"
          name="nemid_iframe"
          key={"file"}
          title="nemid_iframe"
          scrolling="no"
          style={{
            width: small ? "320px" : "700px",
            height: small ? "320px" : "700px",
            border: "0px",
            margin: "0px",
            padding: "0px",
            overflow: "hidden",
          }}
          // style=""width:{0}px;height:{1}px;border:0px;margin:0px;padding:0px;overflow:hidden;""
          src={"https://applet.danid.dk/launcher/lmt/" + moment().valueOf()}
        ></iframe>
      </div>
    </div>
  );
}

export default EasyIDSignatureViewV2;
