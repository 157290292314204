import React from "react";
import PropTypes from "prop-types";

import { DataActions } from "../services/AxoServices";

import { Icon, InlineEdit } from "../utilities/LexUtilities";

import { Table } from "react-bootstrap";

export default class ModelTemplateTableView extends React.PureComponent {
  static propTypes = {
    modelTemplates: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  onDelete = (id) => {
    DataActions.deleteModelTemplate(id);
  };

  onUpdate = (model) => {
    //Replaces the object with the same id with this object
    DataActions.updateModelTemplate(model);
  };

  render() {
    let { modelTemplates, onGotoDetails } = this.props;

    return (
      <div className="standardMaxWidth leftPadding center-block">
        <Table bordered condensed hover>
          <thead>
            <tr>
              <th>Text</th>
              <th>Long text</th>
              <th>Number</th>
              <th>Type</th>
              <th>Active</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {modelTemplates.map((model) => (
              <tr
                role="button"
                key={model.id}
                onDoubleClick={() => onGotoDetails(model.id)}
              >
                <td className="editable">
                  <InlineEdit
                    value={model.text || "---"}
                    change={(input) =>
                      this.onUpdate({
                        ...model, //Create a new object containing all the field of 'model', with the text field replaced by the input text.
                        text: input.value,
                      })
                    }
                  />
                </td>
                <td>{model.longText}</td>
                <td>{model.number}</td>
                <td>{model.type}</td>
                <td>{model.isActive ? "Aktiv" : "Ikke aktiv"}</td>
                <td>
                  <Icon
                    role="button"
                    onClick={() => this.onDelete(model.id)}
                    glyph="icon-fontello-trash-1"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
