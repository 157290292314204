import React from "react";

import { Row, Col, Grid } from "react-bootstrap";
import { Icon } from "../../utilities/LexUtilities";

export default class OptionNavItem extends React.PureComponent {
  //Props
  //glyph
  render() {
    return (
      <Grid fluid>
        {/*<ContextMenuTrigger id={"case-context-menu-" + this.props.case.id}>*/}
        <Row id="clickable-area">
          <Col
            xs={12}
            className="nopadding"
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            &nbsp; &nbsp;
            <span>
              <Icon glyph={this.props.glyph} />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.option.text}
            </span>
          </Col>
        </Row>
        {/*</ContextMenuTrigger>*/}
      </Grid>
    );
  }
}
