// // @ts-check
import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import UserInfoService from "../../services/UserInfoService";
import { Grid, Row, Col, Image, Button } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class SocialBanner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderLocationInfo = () => {
    var userProfile = this.props.userProfile;
    var showPostalCode = userProfile.postalCode && userProfile.showPostalCode;
    var showCity = userProfile.city && userProfile.showCity;
    var showCountry = userProfile.country && userProfile.showCountry;

    var countryEntity = showCountry ? (
      <AxoLocal
        entity="CountryNames"
        data={{ countrySlug: userProfile.country }}
      />
    ) : (
      ""
    );

    return (
      <span>
        {showPostalCode ? <span>{userProfile.postalCode} </span> : null}
        {showCity && showCountry ? (
          <span>
            {userProfile.city}, {countryEntity}
          </span>
        ) : null}
        {showCity && !showCountry ? <span>{userProfile.city}</span> : null}
        {showCountry && !showCity ? <span>{countryEntity}</span> : null}
      </span>
    );
  };

  renderUserInfo = () => {
    var userProfile = this.props.userProfile;
    var showPosition = userProfile.position && userProfile.showPosition;
    var showCity = userProfile.city && userProfile.showCity;
    if (showPosition && showCity) {
      return userProfile.position + ", " + userProfile.city;
    } else if (showPosition) {
      return userProfile.position;
    } else if (showCity) {
      return userProfile.city;
    }
    return "";
  };

  onSetActivate = (value) => {
    var userProfile = Object.assign({}, this.props.userProfile);
    switch (this.props.type) {
      case 0:
        userProfile.publicRoomActive = value;
        break;
      case 1:
        userProfile.internalRoomActive = value;
        break;
      case 2:
        userProfile.privateRoomActive = value;
        break;
      default:
        break;
    }
    DataActions.updateUserProfile(userProfile);
  };

  renderEnableButton = (enabled) => {
    if (enabled) {
      return (
        <span className="axowhite">
          <AxoLocal
            entity="SidebarRightContainerTurnedon"
            defaultValue={"Enabled"}
          />
        </span>
      );
    }
    return (
      <Button className="Lex-button-2" onClick={this.activateRoom}>
        <AxoLocal
          entity="SidebarRightContainerTurnedon"
          defaultValue={"Enable"}
        />
      </Button>
    );
  };

  renderDisableButton = (enabled) => {
    if (enabled) {
      return (
        <Button className="Lex-button-2" onClick={this.disableRoom}>
          <AxoLocal
            entity="SidebarRightContainerTurnoff"
            defaultValue={"Disable"}
          />
        </Button>
      );
    }
    return (
      <span className="axowhite">
        <AxoLocal
          entity="SidebarRightContainerTurnoff"
          defaultValue={"Disabled"}
        />
      </span>
    );
  };

  activateRoom = () => {
    this.onSetActivate(true);
  };

  disableRoom = () => {
    this.onSetActivate(false);
  };

  renderStatusButtons = () => {
    if (this.props.type > 2) {
      return null;
    }
    return (
      <div className="text-center">
        {this.renderEnableButton(this.props.active)}
        &nbsp;&nbsp;
        {this.renderDisableButton(this.props.active)}
      </div>
    );
  };

  getBackgroundImageUrl = () => {
    var userProfile = this.props.userProfile;
    return "url(" + UserInfoService.getBannerImageSource(userProfile) + ")";
  };

  generateUserInfoArray = (userProfile) => {
    var userInfoArray = [];
    if (userProfile.showCompany) {
      userInfoArray.push(userProfile.company);
    }
    if (userProfile.showAddress) {
      userInfoArray.push(userProfile.address);
    }
    if (userProfile.showPhone) {
      userInfoArray.push("Tlf: " + userProfile.phone);
    }
    if (userProfile.showMobile) {
      userInfoArray.push("Mobil: " + userProfile.mobile);
    }
    if (userProfile.showEMail) {
      userInfoArray.push(userProfile.eMail);
    }
    return userInfoArray;
  };

  generateUserInfoRows = (userProfile) => {
    var userInfoArray = this.generateUserInfoArray(userProfile);
    var userInfoRows = [];
    for (var i = 0; i < userInfoArray.length; i = i + 2) {
      var fontSize = 20 - 2 * i;
      var className = "hidden-md hidden-sm ";
      if (i > 0) {
        className += "hidden-xs";
      }
      userInfoRows.push(
        <Row key={i} className={className}>
          <Col md={6} style={{ paddingLeft: "0px" }}>
            <div
              className="axowhite"
              style={{ fontSize: fontSize, opacity: 1 }}
            >
              {userInfoArray[i]}
            </div>
          </Col>
          <Col md={6} style={{ paddingLeft: "0px" }}>
            {i + 1 < userInfoArray.length ? (
              <div
                className="axowhite"
                style={{ fontSize: fontSize, opacity: 1 }}
              >
                {userInfoArray[i + 1]}
              </div>
            ) : null}
          </Col>
        </Row>
      );
    }
    return userInfoRows;
  };

  render() {
    var { userProfile } = this.props;
    return (
      <div
        style={{
          height: 250,
          marginTop: 10,
          backgroundImage: this.getBackgroundImageUrl(),
          backgroundSize: "cover",
          position: "relative",
          marginBottom: 7,
          backgroundPosition: "center",
        }}
      >
        <div
          className="social-cover"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
        ></div>
        <div className="Axosocial-desc">
          <div>
            <Grid fluid>
              <Row>
                <Col md={12} style={{ paddingLeft: "0px" }}>
                  <div style={{ fontSize: "24px" }} className="axowhite">
                    {this.renderLocationInfo()}
                  </div>
                </Col>
              </Row>
              {this.generateUserInfoRows(userProfile)}
            </Grid>
            <div style={{ marginTop: 10 }}>
              <div style={{ display: "inline-block" }}>
                <Icon
                  glyph="icon-fontello-share-1"
                  style={{ fontSize: "50px", color: `white` }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="socialAxoimg">
          <Image
            src={UserInfoService.getProfileImageSource(userProfile)}
            className="img-circle"
            height="65"
            width="50"
            style={{
              display: "block",
              border: "2px solid #fff",
              margin: "auto",
              marginTop: 25,
            }}
          />
          <h4 className="axowhite text-center">
            {UserInfoService.getDisplayName(userProfile)}
          </h4>
          <h5 className="axowhite text-center" style={{ opacity: 0.8 }}>
            {this.renderUserInfo()}
          </h5>
          <hr className="border-black75" style={{ borderWidth: 2 }} />
          {this.renderStatusButtons()}
        </div>
      </div>
    );
  }
}
