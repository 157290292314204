import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import DataActions from "../../services/DataAccess/DataActions";
import InlineEdit from "../../utilities/InlineEdit";
import ReactOption from "../../utilities/ReactOption";
import UserInfoService from "../../services/UserInfoService";
import FileInfoService from "../../services/FileInfoService";
import { withRouter } from "react-router-dom";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import moment from "moment";

import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";

import {
  Icon,
  AxoLocal,
  AxoCheckbox,
  Flexbox,
  FlexElement,
} from "../../utilities/LexUtilities";

class DocumentCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      labelAdded: false,
      caseAdded: false,
      sharedWithFriend: false,
      showDuplicateNameError: false,
    };
  }

  //Props
  //document
  //onDocumentChange
  // onDocumentPropertyChange = (propertyName, input) => {
  //   var document = Object.assign({}, this.props.document);
  //   document[propertyName] = input.value;
  //   this.props.onDocumentChange(document);
  // }

  onDocumentNameChange = (input) => {
    var document = Object.assign({}, this.props.document);
    document.fileName = input.value + document.fileType;
    this.setState({ showDuplicateNameError: false });
    this.props.onDocumentNameChange(document);
  };

  onEditButton = () => {
    this.setState({ editing: !this.state.editing });
  };

  shortenString(input, maxChars) {
    if (input.length > maxChars) {
      return input.substring(0, maxChars - 3) + "...";
    }
    return input;
  }

  onLabelAdd = (documentId, event) => {
    var labelId = parseInt(event.target.value, 10);
    DataActions.addDocumentsToLabel([documentId], labelId).then((responses) => {
      if (responses.find((r) => !r.ok)) {
        return;
      }

      this.setState({ labelAdded: true });
      setTimeout(() => {
        this.setState({ labelAdded: false });
      }, 1500);
    });
  };

  onCaseAdd = (documentId, event) => {
    var document = this.props.document;
    var caseId = parseInt(event.target.value, 10);
    DataActions.createCaseDocumentAssignment({
      caseId: caseId,
      fileInfoModelId: document.id,
    }).then((response) => {
      if (response.status !== 200) {
        return;
      }
      this.setState({ caseAdded: true });
      setTimeout(() => {
        this.setState({ caseAdded: false });
      }, 1500);
    });
  };

  onSelectFriend = (event) => {
    var friendId = parseInt(event.target.value, 10);
    var friend = this.props.friends.find((f) => f.id === friendId);
    if (!friend) {
      return;
    }
    DataActions.shareFile({
      document: this.props.document,
      userProfile: friend,
    }).then((response) => {
      if (!response.ok) {
        return;
      }
      this.setState({ sharedWithFriend: true });
      setTimeout(() => {
        this.setState({ sharedWithFriend: false });
      }, 1500);
    });
  };

  renderDateCell = (document) => {
    var deadlineMoment = moment(document.deadline);
    if (deadlineMoment.year() > 1970) {
      return (
        <div>
          <input
            style={{ borderStyle: "none" }}
            type="date"
            onChange={this.props.onDeadlineChange.bind(this, document.id)}
            value={deadlineMoment.format("YYYY-MM-DD")}
          />
          &nbsp;&nbsp;&nbsp;
          <Icon
            onClick={this.props.removeDeadlineFromDocument.bind(
              this,
              document.id
            )}
            glyph="icon-fontello-scissors axored"
          />
        </div>
      );
    }
    return (
      <Icon
        onClick={this.props.addDeadlineToDocument.bind(this, document.id)}
        glyph="icon-fontello-calendar-6"
      />
    );
  };

  renderFriendSelect = (document) => {
    var friends = this.props.friends;
    var friendSelectNodes = friends.map((friend) => {
      return (
        <option key={friend.id} value={friend.id}>
          {this.shortenString(UserInfoService.getDisplayName(friend), 20)}
        </option>
      );
    });
    var confirmFriendIcon = this.state.sharedWithFriend ? (
      <Icon style={{ color: "#4CAF50" }} glyph="icon-fontello-ok-3" />
    ) : null;
    return (
      <span>
        {confirmFriendIcon}
        <select
          className="axoblue selectbg"
          style={{ border: "none" }}
          value="labels"
          onChange={this.onSelectFriend}
        >
          {/* Vælg ven  */}
          <AxoLocal
            key="Labes"
            componentClass={ReactOption}
            value="labels"
            componentAttribute="text"
            entity="DocumentGridViewisNameAvailableSelectFriend"
          />
          {friendSelectNodes}
        </select>
      </span>
    );
  };

  isNameAvailable = (value) => {
    var document = this.props.document;
    var newName = value + document.fileType;
    var documentWithName = this.props.allDocuments.find(
      (d) => d.fileName === newName
    );

    if (documentWithName && documentWithName.id !== document.id) {
      this.setState({ showDuplicateNameError: true });
      return false;
    }
    if (this.state.showDuplicateNameError === true) {
      this.setState({ showDuplicateNameError: false });
    }
    return true;
  };

  renderEditForm = () => {
    var document = this.props.document;
    var confirmIcon = this.state.labelAdded ? (
      <Icon style={{ color: "#4CAF50" }} glyph="iicon-fontello-ok-3" />
    ) : null;
    var addLabels = this.props.labels.filter(
      (l) => !l.caseIds.includes(document.id)
    );
    var addLabelSelectNodes = addLabels.map((label) => {
      return (
        <option key={label.id} value={label.id}>
          {this.shortenString(label.name, 10)}
        </option>
      );
    });
    var addLabelSelect = (
      <span>
        {confirmIcon}
        <select
          className="axoblue selectbg"
          style={{ border: "none" }}
          value="labels"
          onChange={this.onLabelAdd.bind(this, document.id)}
        >
          <AxoLocal
            key="None"
            componentClass={ReactOption}
            disabled
            value="labels"
            componentAttribute="text"
            entity="DocumentGridViewisNameAvailableonCaseAdd"
            defaultValue={"Add"}
          />
          {addLabelSelectNodes}
        </select>
      </span>
    );

    var confirmCaseIcon = this.state.caseAdded ? (
      <Icon style={{ color: "#4CAF50" }} glyph="icon-fontello-ok-3" />
    ) : null;
    var addCases = this.props.cases.filter(
      (c) => !c.documents.find((d) => d.id === document.id)
    );
    var addCaseSelectNodes = addCases.map((caseModel) => {
      return (
        <option key={caseModel.id} value={caseModel.id}>
          {this.shortenString(caseModel.caseNumber, 10)}
        </option>
      );
    });
    var addCaseSelect = (
      <span>
        {confirmCaseIcon}
        <select
          className="axoblue selectbg"
          style={{ border: "none" }}
          value="labels"
          onChange={this.onCaseAdd.bind(this, document.id)}
        >
          <AxoLocal
            key="Caseadd"
            componentClass={ReactOption}
            disabled
            value="labels"
            componentAttribute="text"
            entity="DocumentGridViewisNameAvailableonCaseAdd"
            defaultValue={"Add"}
          />
          {addCaseSelectNodes}
        </select>
      </span>
    );

    return (
      <div>
        <table className="table">
          <tbody>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  key="Align"
                  entity="DocumentGridViewisNameAvailablefileName"
                  defaultValue={"Filnavn"}
                />{" "}
                :
              </th>
              <td style={{ color: "#4CAF50" }}>
                <InlineEdit
                  value={
                    document.fileName.replace(document.fileType, "") || "---"
                  }
                  change={this.onDocumentNameChange}
                  validate={this.isNameAvailable.bind(this, document.id)}
                />
                {this.state.showDuplicateNameError ? (
                  <div className="axored">
                    <AxoLocal
                      key="Fgred"
                      entity="DocumentGridViewisNameAvailable"
                      defaultValue={"Navnet er optaget."}
                    />
                  </div>
                ) : null}
              </td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  key="priority"
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
              </th>
              <td>
                <span className="pull-left">
                  {this.getStatusIcon(document.status)}
                </span>
                <span>{this.renderStatusSelect(document)}</span>
              </td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  key="Alignleft"
                  entity="CaseEditFormcasepriority"
                  defaultValue={"Prioritet"}
                />
                :
              </th>
              <td>
                <span className="pull-left">
                  {this.getPriorityIcon(document.priority)}
                </span>
                <span>{this.renderPrioritySelect(document)}</span>
              </td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-bell-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="Bell"
                  entity="DocumentGridViewDateCell"
                  defaultValue={"Påmindelse"}
                />
                :
              </th>
              <td style={{ color: "#4CAF50" }}>
                {this.renderDateCell(document)}
              </td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-simple-line-icons-folder-alt" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="Mapper"
                  entity="CaseDataTableFixedThefolders"
                  defaultValue={"Mapper"}
                />
                :
              </th>
              <td>{this.renderLabelNodes(document)}</td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-folder-open-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="Outlined"
                  entity="DocumentGridViewLabelNodes"
                  defaultValue={"Tilføj mappe"}
                />
              </th>
              <td>{addLabelSelect}</td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-simple-line-icons-briefcase" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="Simple"
                  entity="CaseDataTableFixedCase"
                  defaultValue={"Sager"}
                />{" "}
                :
              </th>
              <td>{this.renderCaseNodes(document)}</td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon className="Doccolor1" glyph="icon-fontello-plus-1" />
                &nbsp;&nbsp;{" "}
                <AxoLocal
                  className="Doccolor1"
                  key="Weight"
                  entity="DocumentGridViewCaseNodes"
                  defaultValue={"Tilføj til sag"}
                />
              </th>
              <td>{addCaseSelect}</td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-share-1 " />
                &nbsp;&nbsp;&nbsp;
                <AxoLocal
                  key="View"
                  entity="DocumentGridViewshare"
                  defaultValue={"Delt med"}
                />
                :
              </th>
              <td>{this.renderSharedUsersString(document)}</td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-share" />
                &nbsp;&nbsp;{" "}
                <AxoLocal
                  key="Sharewith"
                  entity="DocumentGridViewsharewith"
                  defaultValue={"Del med ven"}
                />
              </th>
              <td>{this.renderFriendSelect(document)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  onShowFiles = (documents) => {
    this.props.onShowFiles(documents);
  };

  getPriorityIcon = (priorityString) => {
    var priority = parseInt(priorityString, 10);
    switch (priority) {
      case 0:
        return (
          <div>
            <Icon style={{ color: "blue" }} glyph="icon-fontello-circle" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      case 1:
        return (
          <div>
            <Icon style={{ color: "green" }} glyph="icon-fontello-circle" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      case 2:
        return (
          <div>
            <Icon style={{ color: "darkred" }} glyph="icon-fontello-circle" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      case 3:
        return (
          <div>
            <Icon style={{ color: "red" }} glyph="icon-fontello-circle" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      default:
        return <div></div>;
    }
  };

  renderDocumentTypeField = (document) => {
    var fileType = document.fileType.substring(1, document.fileType.length);
    if (fileType === "pdf") {
      return <Icon glyph="icon-fontello-acrobat" />;
    }
    if (fileType === "doc" || fileType === "docx") {
      return (
        <span>
          <Icon glyph="icon-fontello-doc-7" />
        </span>
      );
    }
    if (FileInfoService.isImage(document)) {
      return (
        <span>
          <Icon glyph="icon-fontello-picture-1" /> {fileType}
        </span>
      );
    }
    return fileType;
  };

  getStatusIcon = (status) => {
    switch (status) {
      case 0:
        return (
          <div>
            <Icon style={{ color: "#428bca" }} glyph="icon-fontello-stop-3" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      case 1:
        return (
          <div>
            <Icon style={{ color: "#B8C3C8" }} glyph="icon-fontello-stop-3" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      case 2:
        return (
          <div>
            <Icon style={{ color: "#64584C" }} glyph="icon-fontello-stop-3" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      case 3:
        return (
          <div>
            <Icon style={{ color: "orange" }} glyph="icon-fontello-stop-3" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      case 4:
        return (
          <div>
            <Icon style={{ color: "#66A655" }} glyph="icon-fontello-stop-3" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      case 5:
        return (
          <div>
            <Icon style={{ color: "#D71F4B" }} glyph="icon-fontello-stop-3" />
            &nbsp;&nbsp; &nbsp;
          </div>
        );
      default:
        return <div></div>;
    }
  };

  renderStatusSelect = (document) => {
    return (
      <select
        className="axoblue selectbg"
        value={document.status.toString()}
        style={{ border: "none" }}
        onChange={this.props.onStatusSelect.bind(this, document.id)}
      >
        <AxoLocal
          componentClass={ReactOption}
          value="0"
          disabled
          componentAttribute="text"
          entity="CaseEditFormcasepriorityNormal"
          defaultValue={"Normal"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="1"
          disabled
          componentAttribute="text"
          entity="DocumentGridViewshareddraft"
          defaultValue={"Draft"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="2"
          disabled
          componentAttribute="text"
          entity="DocumentGridViewSubmitted"
          defaultValue={"Submitted"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="3"
          disabled
          componentAttribute="text"
          entity="DocumentGridViewTreat"
          defaultValue={"Treat"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="4"
          disabled
          componentAttribute="text"
          entity="DocumentGridViewapproved"
          defaultValue={"Approved"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="5"
          disabled
          componentAttribute="text"
          entity="DocumentGridViewarchived"
          defaultValue={"Archived"}
        />
      </select>
    );
  };

  getStatusString = (status) => {
    switch (status) {
      case 0:
        return (
          <AxoLocal
            entity="CaseEditFormcasepriorityNormal"
            defaultValue={"Normal"}
          />
        );
      case 1:
        return (
          <AxoLocal
            entity="DocumentGridViewshareddraft"
            defaultValue={"Udkast"}
          />
        );
      case 2:
        return (
          <AxoLocal
            entity="DocumentGridViewSubmitted"
            defaultValue={"Afsendt"}
          />
        );
      case 3:
        return (
          <AxoLocal entity="DocumentGridViewTreat" defaultValue={"Behandles"} />
        );
      case 4:
        return (
          <AxoLocal
            entity="DocumentGridViewapproved"
            defaultValue={"Godkendt"}
          />
        );
      case 5:
        return (
          <AxoLocal
            entity="DocumentGridViewarchived"
            defaultValue={"Arkiveret"}
          />
        );
      default:
        return <div></div>;
    }
  };

  renderPrioritySelect = (document) => {
    return (
      <select
        className="axoblue selectbg"
        value={document.priority.toString()}
        style={{ border: "none" }}
        onChange={this.props.onPrioritySelect.bind(this, document.id)}
      >
        <AxoLocal
          componentClass={ReactOption}
          value="0"
          componentAttribute="text"
          entity="CaseEditFormcaseprioritylow"
          defaultValue={"low"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="1"
          componentAttribute="text"
          entity="CaseEditFormcasepriorityNormal"
          defaultValue={"Normal"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="2"
          componentAttribute="text"
          entity="CaseEditFormcasepriorityHigh"
          defaultValue={"High"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="3"
          componentAttribute="text"
          entity="CaseEditFormcasepriorityUrgent"
          defaultValue={"Urgent"}
        />
      </select>
    );
  };

  getPriorityString = (priority) => {
    switch (priority) {
      case 0:
        return (
          <AxoLocal entity="CaseEditInlinepriorityLow" defaultValue={"Lav"} />
        );
      case 1:
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityNormal"
            defaultValue={"Normal"}
          />
        );
      case 2:
        return (
          <AxoLocal entity="CaseEditInlinepriorityHigt" defaultValue={"Høj"} />
        );
      case 3:
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityurgent"
            defaultValue={"Haster"}
          />
        );
      default:
        return <div></div>;
    }
  };

  getCaseString = (cases) => {
    if (cases.length === 0) {
      return "";
    }
    var caseString = "";
    for (var i = 0; i < cases.length; i++) {
      var caseModel = cases[i];
      if (i > 0) {
        caseString += ", ";
      }
      caseString += caseModel.caseNumber;
    }
    return caseString;
  };

  onRemoveCase = (caseId, documentId) => {
    var currentCase = this.props.cases.find((c) => c.id === caseId);
    currentCase.documents = currentCase.documents.filter(
      (d) => d.id !== documentId
    );
    DataActions.updateCase(currentCase);
  };

  renderCaseNodes = (document) => {
    return document.cases
      .filter((c) => !c.trashed)
      .map((c) => {
        return (
          <span
            key={c.id}
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "white",
            }}
          >
            {c.caseNumber}&nbsp;&nbsp;&nbsp;
            <AxoLocal
              key="class"
              componentClass={Icon}
              role="button"
              onClick={this.onRemoveCase.bind(this, c.id, document.id)}
              glyph="icon-fontello-scissors axored"
              componentAttribute="title"
              entity="CaseEditRemovedocumentfromcase"
            />
            &ensp;
          </span>
        );
      });
  };

  onRemoveLabel = (labelId, documentId) => {
    DataActions.removeLabelFromFile(documentId, labelId);
  };

  renderLabelNodes = (document) => {
    return document.labels.map((label) => {
      return (
        <span
          key={label.id}
          style={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "white",
          }}
        >
          {label.name}&nbsp;&nbsp;&nbsp;
          <AxoLocal
            key="White"
            componentClass={Icon}
            role="button"
            onClick={this.onRemoveLabel.bind(this, label.id, document.id)}
            glyph="icon-fontello-scissors axored"
            componentAttribute="title"
            entity="CaseEditRemovedocumentfromfolder"
          />
          &ensp;
        </span>
      );
    });
  };

  getLabelString = (labels) => {
    if (labels.length === 0) {
      return "";
    }
    var labelString = "";
    for (var i = 0; i < labels.length; i++) {
      var label = labels[i];
      if (i > 0) {
        labelString += ", ";
      }
      labelString += label.name;
    }
    return labelString;
  };

  renderSharedUsersString = (document) => {
    if (document.sharedUsers.length === 0) {
      return;
    }
    var userString = "";
    for (var i = 0; i < document.sharedUsers.length; i++) {
      if (i > 0) {
        userString += ", ";
      }
      userString += UserInfoService.getDisplayName(document.sharedUsers[i]);
    }
    return userString;
  };

  renderInformation = () => {
    var document = this.props.document;
    var deadlineMoment = moment(document.deadline);
    return (
      <div>
        <table className="table Lex-Grid-tabl">
          <tbody>
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-acrobat" />
                &nbsp;
                <AxoLocal
                  key="PDF"
                  entity="DocumentGridViewType"
                  defaultValue={"Type"}
                />
                :
              </th>
              <td>{this.renderDocumentTypeField(document)}</td>
            </tr>
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-calendar-8" />
                &nbsp;{" "}
                <AxoLocal
                  key="Dato"
                  entity="DocumentGridViewuploadDate"
                  defaultValue={"Upload dato"}
                />
                :{" "}
              </th>
              <td>{moment(document.uploadDate).format("L")} </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  key="status"
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
                :{" "}
              </th>
              <td>
                <span className="pull-left">
                  {this.getStatusIcon(document.status)}
                </span>
                <span>&nbsp;{this.getStatusString(document.status)}</span>
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  key="casePriority"
                  entity="CaseDataTableFixedCasepriority"
                  defaultValue={"Prioritet"}
                />
                :{" "}
              </th>
              <td>
                <span className="pull-left">
                  {this.getPriorityIcon(document.priority)}
                </span>
                <span>&nbsp;{this.getPriorityString(document.priority)}</span>
              </td>
            </tr>
            {deadlineMoment.year() > 1970 ? (
              <tr>
                <th className="col-md-4">
                  <Icon glyph="icon-fontello-bell-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="datecell"
                    entity="DocumentGridViewDateCell"
                    defaultValue={"Påmindelse"}
                  />
                  :{" "}
                </th>
                <td>{moment(document.deadline).format("L")}</td>
              </tr>
            ) : null}
            {document.cases.length > 0 ? (
              <tr>
                <th className="col-md-4">
                  <Icon glyph="icon-simple-line-icons-briefcase" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="Casestring"
                    entity="DocumentGridViewCaseString"
                    defaultValue={"Tilhører sagerne"}
                  />
                  :
                </th>
                <td>{this.getCaseString(document.cases)}</td>
              </tr>
            ) : null}
            {document.labels.length > 0 ? (
              <tr>
                <th className="col-md-4">
                  <Icon glyph="icon-simple-line-icons-folder-alt" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="folders"
                    entity="CaseDataTableFixedThefolders"
                    defaultValue={"Mapper"}
                  />
                </th>
                <td>{this.getLabelString(document.labels)}</td>
              </tr>
            ) : null}
            {document.sharedUsers.length > 0 ? (
              <tr>
                <th className="col-md-4">
                  <Icon glyph="icon-fontello-share" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="delmed"
                    entity="DocumentGridViewshare"
                    defaultValue={"Delt med"}
                  />
                  :{" "}
                </th>
                <td>{this.renderSharedUsersString(document)}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  };

  renderContent = () => {
    if (this.state.editing) {
      return this.renderEditForm();
    }
    return this.renderInformation();
  };

  openLink = (url) => {
    window.open(url);
  };

  renderLargeDocumentIcon = (document) => {
    var fileType = document.fileType.substring(1, document.fileType.length);
    var iconSize = "40px";
    if (fileType === "pdf") {
      return (
        <Icon
          style={{ color: "#4CAF50", fontSize: iconSize }}
          glyph="icon-fontello-acrobat"
        />
      );
    }
    if (fileType === "doc" || fileType === "docx") {
      return (
        <span>
          <Icon
            style={{ color: "#4CAF50", fontSize: iconSize }}
            glyph="icon-fontello-doc-7"
          />
        </span>
      );
    }
    if (FileInfoService.isImage(document)) {
      return (
        <span>
          <Icon
            style={{ color: "#4CAF50", fontSize: iconSize }}
            glyph="icon-fontello-picture-1"
          />
        </span>
      );
    }
    return fileType;
  };

  renderPreview = (document) => {
    if (FileInfoService.isImage(document)) {
      return (
        <span
          role="button"
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
          onClick={this.props.onDocumentClick.bind(this, document.id)}
        >
          <img
            alt=""
            src={ApiService.getFileInlinePath(document.id)}
            className="img-circle"
            width="40"
            height="40"
          />
        </span>
      );
    }
    return (
      <span style={{ lineHeight: "50px" }}>
        {this.renderLargeDocumentIcon(document)}
      </span>
    );
  };

  renderShareIcon = (document) => {
    if (document.sharedUsers.length === 0) {
      return null;
    }
    return (
      <span>
        &ensp;
        <Icon className="editable" glyph="icon-fontello-share" />
      </span>
    );
  };

  render() {
    let { document, getDisplayName, selectedDocuments, onSelectDocument } =
      this.props;

    return (
      <div>
        <div className="data-card">
          <div>
            <div className="gradient-baggrund">
              <Grid fluid>
                <Row>
                  <Col xs={12} className="editable">
                    <Flexbox spaceBetween alignCenter>
                      <FlexElement>
                        <AxoCheckbox
                          checked={selectedDocuments.has(document.id)}
                          onChange={onSelectDocument.bind(this, document.id)}
                        />
                      </FlexElement>
                      <FlexElement className="text-center">
                        <div
                          role="button"
                          onClick={this.props.onDocumentClick.bind(
                            this,
                            document.id
                          )}
                          style={{ lineHeight: "50px" }}
                        >
                          {getDisplayName(document)}{" "}
                          {this.renderShareIcon(document)}
                        </div>
                      </FlexElement>
                      {this.renderPreview(document)}
                    </Flexbox>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div>
              <Grid fluid>
                <Row>
                  <Col xs={12}>{this.renderContent()}</Col>
                </Row>
              </Grid>
            </div>
            <div className="Lex-CardFooter">
              <Grid fluid>
                <Row>
                  <Col xs={12} style={{ padding: "0px" }}>
                    <ButtonGroup justified>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="visButtoncolor btn Lex-button"
                          onClick={this.props.onDocumentClick.bind(
                            this,
                            document.id
                          )}
                        >
                          <Icon glyph="icon-fontello-eye-1" />
                          &nbsp; &nbsp;
                          <AxoLocal
                            entity="axoAccounting70"
                            defaultValue={"Vis"}
                          />
                          &nbsp;
                        </button>
                      </ButtonGroup>
                      <ButtonGroup>
                        {this.state.editing ? (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon
                              role="button"
                              className="cblue"
                              glyph="icon-fontello-floppy-1"
                            />{" "}
                            &nbsp;&nbsp;
                            <AxoLocal
                              key="Save"
                              entity="CaseGridViewcaseSave"
                              defaultValue={"Gem"}
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon role="button" glyph="icon-fontello-edit-3" />{" "}
                            &nbsp;&nbsp;
                            <AxoLocal
                              key="Edit"
                              entity="CaseGridViewedit"
                              defaultValue={"Rediger"}
                            />
                          </button>
                        )}
                      </ButtonGroup>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.props.onSendFile.bind(
                            this,
                            document.id
                          )}
                        >
                          <Icon role="button" glyph="icon-fontello-mail-1" />{" "}
                          &nbsp;&nbsp;
                          <AxoLocal
                            entity="DocumentGridViewsendto"
                            defaultValue={"Send til"}
                          />
                        </button>
                      </ButtonGroup>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.props.onTrashFile.bind(
                            this,
                            document.id
                          )}
                        >
                          <Icon role="button" glyph="icon-fontello-trash-1" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            key="delet"
                            entity="axoidcode179"
                            defaultValue={"Slet"}
                          />
                        </button>
                      </ButtonGroup>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class DocumentGridView extends React.PureComponent {
  //Props
  //cases
  //activeDocumentId
  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateDocCols = (documents) => {
    var cols = [[], [], []];
    var smallCols = [[], []];
    for (var i = 0; i < documents.length; i++) {
      var document = documents[i];
      //var docLabels = this.props.labels.filter( l => l.documentIds.includes(document.id));

      var docCard = (
        <DocumentCard
          key={document.id}
          document={document}
          allDocuments={this.props.documents}
          active={this.props.activeDocumentId === document.id}
          labels={this.props.labels}
          cases={this.props.cases}
          friends={this.props.friends}
          onShowFiles={this.props.onShowFiles}
          onDocumentNameChange={this.onDocumentNameChange}
          onStatusSelect={this.props.onStatusSelect}
          onPrioritySelect={this.props.onPrioritySelect}
          onCaseDateChange={this.props.onCaseDateChange}
          onDocumentClick={this.props.onDocumentClick}
          onDeadlineChange={this.props.onDeadlineChange}
          removeDeadlineFromDocument={this.props.removeDeadlineFromDocument}
          addDeadlineToDocument={this.props.addDeadlineToDocument}
          onTrashFile={this.props.onTrashFile}
          onSendFile={this.props.onSendFile}
          getDisplayName={this.props.getDisplayName}
          selectedDocuments={this.props.selectedDocuments}
          onSelectDocument={this.props.onSelectDocument}
        />
      );

      cols[i % 3].push(docCard);
      smallCols[i % 2].push(docCard);
    }
    return [cols, smallCols];
  };

  renderDocumentGrid = (documents) => {
    var cols = this.generateDocCols(documents);
    var largeCols = cols[0];
    var smallCols = cols[1];
    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[0]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[1]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  onDocumentNameChange = (document) => {
    DataActions.updateDocumentName(document);
  };

  getShownDocuments = () => {
    return this.props.documents.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    // var height = this.props.height || '800px';
    return (
      <Grid fluid style={{ minHeight: "50px", background: "#ffffff" }}>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            {this.renderDocumentGrid(this.getShownDocuments())}
          </Col>
        </Row>
        {this.state.numberShown < this.props.documents.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col
              xs={12}
              className="text-center"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2"
                style={{ width: "75%" }}
              >
                <AxoLocal
                  key="Viewdocuments"
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(DocumentGridView);
