import React from "react";
import Autosuggest from "react-autosuggest";
import { debounce } from "lodash";

import { ApiService, ContactService } from "../../services/AxoServices";

const theme = {
  container: {
    position: "relative",
  },
  input: {
    width: 240,
    height: 30,
    padding: "10px 20px",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    border: "1px solid #aaa",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 30,
    width: 280,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
    maxHeight: "300px",
    overflowY: "auto",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#e6e6e6",
  },
};

//Props
//clients
//handleSelectedClient
//disabled
export default class ClientSearchBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: props.startValue || "",
      suggestions: [],
      clients: props.clients,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clients === this.props.clients) {
      return;
    }

    this.setState({
      clients: nextProps.clients,
    });
  }

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test((email || "").trim());
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    var { clients } = this.state;
    var { count, onlyEmails, customFilter } = this.props;

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    //If not all contacts have been loaded into memory, perform a server-side search
    if (inputLength > 0 && !!count && this.props.clients.length < count) {
      this.getClients(inputValue);
    }

    return clients.filter((client) => {
      if (onlyEmails && !this.validateEmail(client.eMail)) {
        return false;
      }
      if (!!customFilter && !customFilter(client)) {
        return false;
      }
      if (inputLength === 0) {
        return true;
      }
      var clientString = `${ContactService.getContactFullName(client)} ${
        client.eMail
      }`;
      return clientString.toLowerCase().includes(inputValue);
    });
  };

  getClients = debounce((query) => {
    var { onlyEmails, customFilter } = this.props;
    ApiService.getContacts(25, { searchText: query }).then((contactMap) => {
      var clients = contactMap.contacts;
      if (onlyEmails) {
        clients = clients.filter((c) => this.validateEmail(c.eMail));
      }
      if (!!customFilter) {
        clients = clients.filter(customFilter);
      }
      this.setState({
        clients,
        suggestions: clients,
      });
    });
  }, 350);

  // When suggestion is clicked, Autosuggest needs to populate the input element
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) =>
    ContactService.getContactFullName(suggestion);

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => (
    <div>{ContactService.getContactFullName(suggestion)}</div>
  );

  onChange = (event, { newValue }) => {
    this.setState({
      searchValue: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.handleSelectedClient(suggestion);
    if (this.props.clearOnSelect) {
      this.setState(
        {
          searchValue: "",
          suggestions: [],
        },
        () => {
          document.activeElement.blur();
        }
      );
    }
  };

  shouldRenderSuggestions(value) {
    return true;
  }

  //Props
  //placeholder
  //clearOnSelect
  render() {
    var searchValue = this.state.searchValue;
    const inputProps = {
      className: "searchbox",
      placeholder: this.props.placeholder,
      value: searchValue,
      disabled: this.props.disabled,
      onChange: this.onChange,
    };
    if (this.props.inputBorder) {
      theme.input.border = this.props.inputBorder;
    }
    if (this.props.maxWidth) {
      theme.input.maxWidth = this.props.maxWidth;
      theme.input.display = "block";
      theme.input.width = "100%";
    }
    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        theme={theme}
      />
    );
  }
}
