import React from "react";

import CollaborationView from "./CollaborationView";

import { ApiService, RoutingService } from "../../services/AxoServices";

const signalR = require("@aspnet/signalr/dist/browser/signalr.js");

export default class CollaborationContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contentString: "",
      summaryString: "",
      initialized: false,
    };
    this.hubConnection = null;
    this.hubConnectionTimer = null;
    this.hubConnectionAttemptInProgress = false;
  }

  componentDidMount() {
    let hubRoute = RoutingService.getHostPath() + "/collaborationhub";

    // this.hubConnection = new signalR.HubConnection(hubRoute);
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubRoute)
      // .configureLogging(signalR.LogLevel.Trace)
      .build();

    this.hubConnection.on("ReceiveContent", async (content) => {
      this.setState({
        contentString: content,
      });
    });

    this.hubConnection
      .start()
      .then(() => {
        this.forceUpdate();
      })
      .catch((err) => console.error(err.toString()));

    this.hubConnection.onclose((error) => {
      this.forceUpdate();

      if (!!error) {
        console.log(error.toString());
      }
      console.log(
        new Date().toLocaleTimeString() +
          " - SignalR collaboration hub connection lost."
      );
      if (!this.hubConnection || !!this.hubConnectionTimer) {
        return;
      }

      this.hubConnectionTimer = setInterval(() => {
        if (this.hubConnectionAttemptInProgress) {
          return;
        }

        console.log(
          new Date().toLocaleTimeString() +
            " - Attempting to reconnect with SignalR collaboration hub."
        );
        this.hubConnectionAttemptInProgress = true;
        this.hubConnection
          .start()
          .then(() => {
            console.log(
              new Date().toLocaleTimeString() +
                " - SignalR collaboration hub connection reestablished."
            );
            clearInterval(this.hubConnectionTimer);
            this.hubConnectionTimer = null;
            this.hubConnectionAttemptInProgress = false;
            this.forceUpdate();
          })
          .catch((err) => {
            this.logConnectionError(
              err,
              "Error reestablishing collaboration hub connection."
            );
            this.hubConnectionAttemptInProgress = false;
          });
      }, 3000);
    });
  }

  logConnectionError(error, defaultMessage) {
    let dateString = new Date().toLocaleTimeString();

    if (!!error) {
      return dateString + " - " + error.toString();
    }
    return dateString + " - " + defaultMessage;
  }

  onUpdate = (contentString) => {
    this.setState({ contentString });

    //Check if connection is active
    if (this.hubConnection.connection.connectionState !== 1) {
      return;
    }

    this.hubConnection.invoke("SendContent", contentString).catch((reason) => {
      console.error(reason);
    });
  };

  onSummarize = async (contentString) => {
    let response = await ApiService.summarizeCollaborationString(contentString);
    if (!response.ok) {
      return false;
    }

    let summaryString = await response.text();
    this.onUpdate(`${contentString}${summaryString}`);
    this.setState({ summaryString });
    return true;
  };

  componentWillUnmount() {
    this.hubConnection.stop();
    this.hubConnection = null;
    if (!!this.hubConnectionTimer) {
      clearInterval(this.hubConnectionTimer);
      this.hubConnectionTimer = null;
      this.hubConnectionAttemptInProgress = false;
    }
  }

  render() {
    return (
      <CollaborationView
        {...this.state}
        onUpdate={this.onUpdate}
        onSummarize={this.onSummarize}
        disabled={
          !this.hubConnection ||
          this.hubConnection.connection.connectionState !== 1
        }
      />
    );
  }
}

// import React from 'react'
// import ApiService from '../../services/DataAccess/ApiService'
// import { debounce } from 'lodash'

// import CollaborationView from './CollaborationView'

// export default class CollaborationContainer extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       contentString: '',
//     }
//     this.editing = false;
//     this.timer = null;
//   }

//   componentDidMount() {
//     this.fetchContent();
//     this.timer = setInterval(this.fetchContent, 250);
//   }

//   componentWillUnmount() {
//     clearInterval(this.timer);
//   }

//   fetchContent = () => {
//     if(this.editing) {
//       return;
//     }
//     ApiService.getCollaborationString()
//     .then(contentString => {
//       if(this.editing) {
//         return;
//       }
//       this.setState({
//         contentString
//       });
//     });
//   }

//   onUpdate = (contentString) => {
//     this.editing = true;
//     this.setState({
//       contentString,
//     }, () => {
//       this.updateContent();
//     });
//   }

//   updateContent = debounce(() => {
//     ApiService.updateCollaborationString(this.state.contentString)
//     .then( response => {
//       this.finishEditing();
//     });
//   }, 1);

//   finishEditing = debounce(() => {
//     this.editing = false;
//   }, 1000);

//   render() {
//     return (
//       <CollaborationView
//         contentString={this.state.contentString}
//         onUpdate={this.onUpdate}
//       />
//     )
//   }
// }
