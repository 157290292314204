import React from "react";
import { Link } from "react-router-dom";
import { AxoLocal } from "../utilities/LexUtilities";

import { Grid, Row, Col } from "react-bootstrap";

export default class FreewareFrontPage extends React.PureComponent {
  render() {
    return (
      <Grid fluid>
        <Row style={{ paddingTop: "15px" }}>
          <Col sm={12} className="flexbox-center">
            <div className="Axolexpanneldivcircle flexbox-center">
              <div className="Axolexpanneldivcircle1 flexbox-center">
                <div className="flexbox-center">
                  <div className="text-center">
                    <Link className="AxoLink" to="/freeware/xlink">
                      <AxoLocal
                        entity="presentationMarketing29"
                        defaultValue="Lav dine egne præsentationer"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
