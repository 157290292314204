import React from "react";
import Signup from "./Signup";

import { AxoLocal } from "../utilities/LexUtilities";

export default class SignupAccountantContainer extends React.PureComponent {
  render() {
    return (
      <Signup
        userType="Accountant"
        header={
          <span>
            <AxoLocal
              entity="axoAccounting38"
              defaultValue="Opret revisorkonto"
            />
          </span>
        }
      />
    );
  }
}
