import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import MarketingUserProfileForm from "./MarketingUserProfileForm";
import UserInfoService from "../../services/UserInfoService";
import { Col, Panel, PanelGroup } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { Icon, AxoLocal } from "../../utilities/LexUtilities";

function renderAccordionHeader(title) {
  return (
    <div>
      <Col xs={11} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

class MarketingSetupView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessageFriendRequestId: 0,
    };
  }
  onUpdate = (userProfile) => {
    DataActions.updateUserProfile(userProfile);
  };

  onApproveRequest = (request) => {
    DataActions.acceptFriendRequest(request)
      .then((response) => {
        if (response.status === 200) {
          return DataActions.createNotification({
            entity: "ClientSetupViewContactRequestApproved",
            variable: UserInfoService.getDisplayName(request.receiver),
            type: 1,
            receiverId: request.requestUser.id,
            success: true,
          });
        }
        return Promise.reject("Failed to accept friend request");
      })
      .catch((reason) => {
        console.log(reason);
        this.showErrorMessage(request.id);
      });
  };

  onRejectRequest = (request) => {
    var updatedRequest = Object.assign({}, request);
    updatedRequest.status = 1; //Rejected
    DataActions.updateFriendRequest(updatedRequest)
      .then((response) => {
        if (response.status === 200) {
          return DataActions.createNotification({
            entity: "ClientSetupViewContactRequestRejected",
            avatar: UserInfoService.getDisplayName(request.receiver),
            type: 1,
            receiverId: request.requestUser.id,
            success: false,
          });
        }
        return Promise.reject("Failed to reject friend request");
      })
      .catch((reason) => {
        console.log(reason);
        this.showErrorMessage(request.id);
      });
  };

  showErrorMessage = (requestId) => {
    this.setState({ errorMessageFriendRequestId: requestId });
    setTimeout(() => {
      this.setState({ errorMessageFriendRequestId: 0 });
    }, 5000);
  };

  render() {
    return (
      <div
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        <div>
          <div
            className="backgroundlineargradient3"
            style={{ paddingTop: "10px", minHeight: "750px" }}
          >
            <div
              className="axobg backgroundlineargradient3"
              style={{ marginBottom: "0px", borderRadius: "0px" }}
            >
              <div className="flexbox-standard">
                <div>
                  <h4>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon glyph="icon-fontello-doc-7" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="ClientSetupViewSetup"
                      defaultValue={"Opsætning"}
                    />
                  </h4>
                </div>
              </div>
            </div>
            <div style={{ paddingLeft: "10px" }}>
              <PanelGroup
                id="ITsubscriptionb"
                accordion
                className="Lex-Accordion"
                defaultActiveKey="0"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  borderBottom: "1px solid white",
                }}
              >
                <Panel className="backgroundlineargradient3" eventKey="1">
                  <Panel.Heading>
                    <Panel.Title toggle>
                      {renderAccordionHeader(
                        <span>
                          <Icon glyph="icon-fontello-cog-7" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            entity="ClientSetupViewuserProfile"
                            defaultValue={"Brugerprofil"}
                          />
                        </span>
                      )}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible className="LexAccordionPanelody">
                    <MarketingUserProfileForm
                      userProfile={this.props.userProfile}
                      userSettings={this.props.userSettings}
                      onUpdate={this.onUpdate}
                    />
                  </Panel.Body>
                </Panel>
              </PanelGroup>

              {/* <Accordion className = 'Lex-Accordion ' defaultActiveKey='0' >
                <Panel  
                header={renderAccordionHeader(<span> 
                    <Icon glyph="icon-fontello-cog-7"/>&nbsp;&nbsp;
                    <AxoLocal entity='ClientSetupViewuserProfile' defaultValue={'Brugerprofil'}/>
                    </span>)} eventKey="1">
                      <ClientUserProfileForm
                        userProfile={this.props.userProfile}
                        userSettings={this.props.userSettings}
                        onUpdate={this.onUpdate}
                      />
                </Panel>
              </Accordion> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MarketingSetupView);
