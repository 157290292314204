import React from "react";
import PresentationView from "../Presentation/PresentationView";
import PresentationApiService from "../services/PresentationApiService";
import PresentationActions from "../services/PresentationActions";

import { LoadingIndicator, withRouter } from "../../utilities/LexUtilities";

class PublicPresentationContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      presentation: {},
    };
  }

  componentDidMount() {
    var { userId, id } = this.props.match.params;
    PresentationApiService.getSpecificPublicPresentation(userId, id)
      .then((presentation) => {
        var copy = Object.assign({}, presentation);
        copy.viewCount++;
        this.setState({ presentation: copy });
        PresentationActions.incrementViews(copy);
      })
      .catch((reason) => {
        console.log(reason);
      });
  }

  onBackButton = () => {
    this.props.history.goBack();
  };

  render() {
    var presentation = this.state.presentation;
    if (!presentation.id) {
      return <LoadingIndicator />;
    }

    return (
      <PresentationView
        presentation={presentation}
        onBackButton={this.onBackButton}
        includeReportButton
        includeListLinkButton
        includeLinks
      />
    );
  }
}

export default withRouter(PublicPresentationContainer);
