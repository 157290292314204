import React from "react";
import PrintService from "../services/PrintService";
import Conditional from "../utilities/Conditional";
import moment from "moment";
import TimeEntryService from "../services/TimeEntryService";

import {
  Row,
  Col,
  Grid,
  Table,
  Button,
  ButtonToolbar,
  PanelGroup,
  Panel,
} from "react-bootstrap";

// import CardLogos from '../resources/images/dankort.png';
import Dankort from "../resources/images/onlyDankort.png";
import MasterCard from "../resources/images/MasterCard.jpg";
import Visa from "../resources/images/Visa.png";
// import AxolexDataBehandlerAftale from '../resources/files/AxolexDatabehandlerAftale.pdf'

import {
  Icon,
  AxoLocal,
  AccordionUtils,
  AxoCheckbox,
} from "../utilities/LexUtilities";

export default class VLCollectionView extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.invoice) {
      this.state = {
        invoice: Object.assign({}, props.invoice),
        acceptedConditions: false,
        acceptedPrivacyPolicy: false,
      };
    }

    this.state.isGeneratingPayment = false;
    this.state.showPaymentErrorMessage = false;
    this.state.showPaymentCompletedMessage = false;
  }

  onPrint = () => {
    if (typeof window !== "undefined") {
      PrintService.printElement(document.getElementById("print-invoice"));
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.invoice &&
      JSON.stringify(nextProps.invoice) !== JSON.stringify(this.props.invoice)
    ) {
      this.setState({ invoice: Object.assign({}, nextProps.invoice) });
    }
  }

  hasUnits = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.units > 0);
  };

  hasAConto = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.aConto > 0);
  };

  hasExpenses = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.expenses > 0);
  };

  //Props
  //timeEntries
  //onCreatePDF
  renderTimeEntriesTable = (subTotal) => {
    var hasUnits = this.hasUnits();
    var hasAConto = this.hasAConto();
    var hasExpenses = this.hasExpenses();
    var footerOffset = 1;
    if (hasUnits) {
      footerOffset += 2;
    }
    if (hasAConto) {
      footerOffset += 1;
    }
    if (hasExpenses) {
      footerOffset += 1;
    }
    var currency = this.state.invoice.currency;
    return (
      <Table striped>
        <thead className="axocblueabanne">
          <tr>
            <th>#</th>
            <th>
              <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
            </th>
            {hasUnits ? (
              <th>
                <AxoLocal entity="InvoiceInvoiceunit" defaultValue={"Antal"} />
              </th>
            ) : null}
            {hasUnits ? (
              <th>
                <AxoLocal
                  entity="InvoiceInvoicePrisunit"
                  defaultValue={"Pris/enhed"}
                />
              </th>
            ) : null}
            {hasExpenses ? (
              <th>
                <AxoLocal
                  entity="TimeEntriesViewExpensesLabel"
                  defaultValue={"Udgifter"}
                />
              </th>
            ) : null}
            {hasAConto ? (
              <th>
                <AxoLocal
                  entity="InvoiceInvoiceAConto"
                  defaultValue={"A Conto"}
                />
              </th>
            ) : null}
            <th>
              <AxoLocal entity="InvoiceInvoicesum" defaultValue={"Beløb"} />
            </th>
          </tr>
        </thead>
        <tbody>{this.renderTimeEntryRows()}</tbody>
        <tfoot className="axocblueabanne">
          <tr>
            <th colSpan={footerOffset}></th>
            <th>
              <AxoLocal entity="InvoiceInvoicetotal" defaultValue={"Total"} />
            </th>
            <th>
              {currency} {subTotal.toFixed(2)}
            </th>
          </tr>
        </tfoot>
      </Table>
    );
  };

  renderTimeEntryRows = () => {
    var index = 0;
    var hasUnits = this.hasUnits();
    var hasAConto = this.hasAConto();
    var hasExpenses = this.hasExpenses();
    var currency = this.state.invoice.currency;
    return this.state.invoice.timeEntries.map((timeEntry) => {
      index++;
      return (
        <tr key={timeEntry.id}>
          <td>{index}</td>
          <td>{timeEntry.description}</td>
          {hasUnits ? (
            <td>
              {timeEntry.units > 0 ? (
                <span>
                  {timeEntry.units}{" "}
                  {TimeEntryService.getUnitTypeName(timeEntry.unitType)}
                </span>
              ) : (
                ""
              )}
            </td>
          ) : null}
          {hasUnits ? (
            <td>
              {timeEntry.units > 0 ? (
                <span>
                  {currency} {timeEntry.rate}
                </span>
              ) : (
                ""
              )}
            </td>
          ) : null}
          {hasExpenses ? (
            <td>
              {timeEntry.units > 0 ? (
                <span>
                  {currency} {timeEntry.expenses}
                </span>
              ) : (
                ""
              )}
            </td>
          ) : null}
          {hasAConto ? (
            <td>
              {timeEntry.aConto > 0 ? (
                <span>
                  {currency} {timeEntry.aConto}
                </span>
              ) : null}
            </td>
          ) : null}
          <td>
            {currency}{" "}
            {(timeEntry.units * timeEntry.rate + timeEntry.expenses).toFixed(2)}
          </td>
        </tr>
      );
    });
  };

  getTotal = (timeEntries) => {
    return timeEntries
      .reduce((acc, timeEntry) => {
        return acc + TimeEntryService.getTotalValue(timeEntry);
      }, 0)
      .toFixed(2);
  };

  renderDueDateField = (invoice) => {
    return moment(invoice.dueDate).format("L");
  };

  onPayInvoice = (invoiceId) => {
    this.setState({ isGeneratingPayment: true });
    this.props
      .onPayInvoice(invoiceId)
      .then((result) => {
        if (!result) {
          this.showPaymentErrorMessage();
          return;
        }
        this.setState({
          isGeneratingPayment: false,
          showPaymentCompletedMessage: true,
        });
      })
      .catch(() => {
        this.showPaymentErrorMessage();
      });
  };

  showPaymentErrorMessage = () => {
    this.setState({
      isGeneratingPayment: false,
      showPaymentErrorMessage: true,
    });
    setTimeout(() => {
      this.setState({ showPaymentErrorMessage: false });
    }, 5000);
  };

  renderPaymentButton = () => {
    let { acceptedConditions, acceptedPrivacyPolicy } = this.state;

    var { invoice, paymentDate } = this.props;

    var isGenerating = this.state.isGeneratingPayment;
    if (invoice.status === "Paid" || !!paymentDate) {
      //Paid or approved
      return (
        <React.Fragment>
          <Button disabled className="Lex-button-2 axoblue">
            <Icon glyph="icon-fontello-check-1" style={{ fontSize: "17px" }} />{" "}
            &nbsp;
            <AxoLocal
              key="paid"
              entity="InvoiceInvoicePaid"
              defaultValue={"Betalt"}
            />
            {!!paymentDate ? (
              <span>&nbsp;{moment(paymentDate).format("L")}</span>
            ) : null}
          </Button>
          <Button
            className="Lex-button-2 axoblue hidden-print"
            onClick={() => window.print()}
          >
            <AxoLocal entity="axoidcode212" defaultValue={"Udskriv"} />
          </Button>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button
          disabled={
            !acceptedConditions || !acceptedPrivacyPolicy || isGenerating
          }
          className="Lex-button-2"
          onClick={this.onPayInvoice.bind(this, invoice.id)}
          style={{ fontSize: "17px" }}
        >
          {/* <img alt=''  src={CardLogos} width='80' height='20' />&nbsp;&nbsp; */}
          <img alt="" src={Dankort} width="40" height="20" />
          &nbsp;&nbsp;
          <img alt="" src={Visa} width="40" height="20" />
          &nbsp;&nbsp;
          <img alt="" src={MasterCard} width="40" height="20" />
          &nbsp;&nbsp;
          <AxoLocal entity="InvoiceRegisterCard" defaultValue={"Betal"} />
          <Conditional inline show={isGenerating}>
            &nbsp;
            <img
              alt=""
              src="/imgs/app/loading.gif"
              className="img-circle"
              width="20"
              height="20"
            />
          </Conditional>
        </Button>
      </React.Fragment>
    );
  };

  renderPaymentButtonGroup = () => {
    if (!this.props.onPayInvoice) {
      return null;
    }
    return <React.Fragment>{this.renderPaymentButton()}</React.Fragment>;
  };

  calculateInvoiceSubTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.units * timeEntry.rate;
    }, 0);
  };

  calculateExpensesSubTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.expenses;
    }, 0);
  };

  calculateTaxTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.units * timeEntry.rate * timeEntry.tax;
    }, 0);
  };

  calculateAContoTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.aConto;
    }, 0);
  };

  render() {
    let { acceptedConditions, acceptedPrivacyPolicy } = this.state;

    var invoice = this.state.invoice;
    var subTotal = this.calculateInvoiceSubTotal(invoice);
    var taxTotal = this.calculateTaxTotal(invoice);
    var expensesTotal = this.calculateExpensesSubTotal(invoice);
    // var aConto = this.calculateAContoTotal(invoice);
    var currency = invoice.currency;

    var { paymentDate, paymentTerms, paymentPrivacyPolicy } = this.props;

    let { userProfile } = invoice;

    let paid = invoice.status === "Paid" || !!paymentDate;

    return (
      <Row
        id="print-invoice"
        ref={(r) => (this.invoiceNode = r)}
        style={{ margin: "auto", border: "2px solid #f2f2f2" }}
      >
        <Col xs={12}>
          <div>
            <div>
              <div>
                <Grid fluid></Grid>
                <hr style={{ marginTop: 0 }} />
              </div>
              <div>
                <Grid fluid>
                  <Row>
                    <Col xs={12} style={{ padding: "0px", margin: "0px" }}>
                      {this.renderTimeEntriesTable(subTotal + expensesTotal)}
                    </Col>
                  </Row>
                </Grid>
                <hr style={{ marginTop: 0 }} />
                <Grid fluid>
                  <Row>
                    <Col xs={8}>
                      {/* <div>
                         <AxoLocal entity='invoiPaymentatthelatest'defaultValue={'Betaling senest'}/>:&nbsp;{this.renderDueDateField(invoice)} &nbsp;
                        </div> */}
                      <div>
                        Udstedt:&nbsp;{moment(invoice.creationDate).format("L")}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="axocblueabanne text-uppercase text-centered">
                        <h5
                          className="subheader axowhite"
                          style={{ margin: 0, padding: 12.5 }}
                        >
                          <AxoLocal
                            entity="invoiPaymentattheTotal"
                            defaultValue={"i alt"}
                          />{" "}
                        </h5>
                      </div>
                      <div>
                        <Table>
                          <tbody>
                            {this.hasUnits() && taxTotal > 0 ? (
                              <tr>
                                <td>
                                  <AxoLocal
                                    entity="invoiPaymentattheSubtotalVAT"
                                    defaultValue={"Moms"}
                                  />
                                  ({((taxTotal * 100) / subTotal).toFixed(2)}%)
                                </td>
                                <td>
                                  {currency} {taxTotal.toFixed(2)}
                                </td>
                              </tr>
                            ) : null}
                            <tr>
                              <td>
                                <AxoLocal
                                  entity="InvoiceInvoicetotal"
                                  defaultValue={"Total"}
                                />
                              </td>
                              <td>
                                <span style={{ borderBottom: "4px double" }}>
                                  {currency}{" "}
                                  {(
                                    subTotal +
                                    taxTotal +
                                    expensesTotal
                                  ).toFixed(2)}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Grid>
                <hr style={{ marginTop: 0 }} />
                <Grid fluid>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <div>
                          {!paid ? (
                            <h4>Betaling sker til</h4>
                          ) : (
                            <h4>Betalingen er gennemført til</h4>
                          )}
                          <div>
                            <div>
                              {invoice.userCompanyOverride ||
                                userProfile.company}
                              &nbsp;-{" "}
                              {invoice.userAddressOverride ||
                                userProfile.address}
                              &nbsp;
                              {invoice.userPostalCodeOverride ||
                                userProfile.postalCode}
                              &nbsp;
                              {invoice.userCityOverride || userProfile.city}
                            </div>
                            {userProfile.companyCode ? (
                              <span>
                                <AxoLocal
                                  entity="SidebarRightContainerCompanyCode"
                                  defaultValue={"CVR"}
                                />
                                :&nbsp;
                                {invoice.userCompanyCodeOverride ||
                                  userProfile.companyCode}
                              </span>
                            ) : null}
                            {!!invoice.userPhoneOverride ||
                            !!userProfile.phone ? (
                              <div>
                                Tlf:{" "}
                                {invoice.userPhoneOverride || userProfile.phone}
                              </div>
                            ) : null}
                            <div>
                              Mail:{" "}
                              {invoice.userMailOverride || userProfile.eMail}
                            </div>
                          </div>
                        </div>
                        {!paid ? (
                          <React.Fragment>
                            <PanelGroup
                              id="ITsubscriptionb"
                              accordion
                              className="Lex-Accordion bottomPadding"
                              defaultActiveKey="0"
                              style={{
                                borderRadius: "0px",
                                paddingTop: "0px",
                                borderBottom: "1px solid white",
                              }}
                            >
                              <Panel
                                className="backgroundlineargradient3"
                                eventKey="0"
                              >
                                <Panel.Heading>
                                  <Panel.Title toggle>
                                    {AccordionUtils.renderAccordionHeader(
                                      <span>
                                        <AxoLocal
                                          entity="updategetAccountName8"
                                          defaultValue="Privatlivspolitik"
                                        />
                                      </span>
                                    )}
                                  </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body
                                  collapsible
                                  className="LexAccordionPanelody"
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: paymentPrivacyPolicy,
                                    }}
                                  />
                                  {/* <div>Kortoplysninger behandles sikkert af Scanpay og Nets.</div>
                                  <div>Vinterbadeforeningen har ikke adgang til medlemmernes kortoplysninger.</div>
                                  <div>Vinterbadeforeningen behandler medlemmernes personoplysninger i overensstemmelse med persondataloven.</div>
                                  <div>Medlemmernes data behandles sikkert af Axolex ApS, som følger persondataloven og opbevarer persondata krypteret på 
                                    Microsofts Azure netværk, på servere inden for EU. <a href={AxolexDataBehandlerAftale} target='_blank' rel='noopener noreferrer'>Se Axolex databehandleraftale her.</a></div>
                                  <div>Data vil ikke blive delt med tredjeparter eller andre virksomheder. </div>
                                  <div>Hjemlen for databehandlingen er GDPR art. 6, stk. 1, b), da databehandlingen er nødvendig for at leve op til foreningens aftale med medlemmerne.</div> */}
                                  <div>
                                    <AxoCheckbox
                                      checked={acceptedPrivacyPolicy}
                                      onChange={() =>
                                        this.setState((prevState) => ({
                                          acceptedPrivacyPolicy:
                                            !prevState.acceptedPrivacyPolicy,
                                        }))
                                      }
                                    />
                                    Jeg accepterer privatlivspolitikken.
                                  </div>
                                </Panel.Body>
                              </Panel>
                            </PanelGroup>
                            <PanelGroup
                              id="ITsubscriptionb"
                              accordion
                              className="Lex-Accordion bottomPadding"
                              defaultActiveKey="0"
                              style={{
                                borderRadius: "0px",
                                paddingTop: "0px",
                                borderBottom: "1px solid white",
                              }}
                            >
                              <Panel
                                className="backgroundlineargradient3"
                                eventKey="0"
                              >
                                <Panel.Heading>
                                  <Panel.Title toggle>
                                    {AccordionUtils.renderAccordionHeader(
                                      <span>Handelsbetingelser</span>
                                    )}
                                  </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body
                                  collapsible
                                  className="LexAccordionPanelody"
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: paymentTerms,
                                    }}
                                  />
                                  {/* <div>Betaling af kontingent kan som udgangspunkt ikke refunderes.</div>
                                  <div>Beløb kan kun refunderes i særlige tilfælde og efter aftale med den ansvarlige leder.</div> */}
                                  <div>
                                    <AxoCheckbox
                                      checked={acceptedConditions}
                                      onChange={() =>
                                        this.setState((prevState) => ({
                                          acceptedConditions:
                                            !prevState.acceptedConditions,
                                        }))
                                      }
                                    />
                                    Jeg accepterer handelsbetingelserne.
                                  </div>
                                </Panel.Body>
                              </Panel>
                            </PanelGroup>
                          </React.Fragment>
                        ) : null}
                        <div className="axoFrontPage text-right">
                          <ButtonToolbar className="topPadding bottomPadding inlineBlock">
                            {this.renderPaymentButtonGroup()}
                          </ButtonToolbar>
                        </div>
                        {!paid &&
                        (!acceptedConditions || !acceptedPrivacyPolicy) ? (
                          <div className="axored">
                            Du skal klikke på Privatlivspolitik og
                            Handelsbetingelser for at acceptere, før du kan
                            komme videre med betalingen.
                          </div>
                        ) : null}
                        {/* <Conditional show={this.state.showPaymentCompletedMessage}>
                            <div style={{marginTop: '15px'}} >
                              <AxoLocal entity='InvoicePaymentCompleted' defaultValue={'Betalingen er gennemført'}/>.&nbsp;
                              {invoice.currency} {(subTotal + taxTotal + expensesTotal - aConto).toFixed(2)}
                              { cardInformation ? (
                                <span>&nbsp;<AxoLocal entity='InvoicePaymentInformation' defaultValue={'vil blive trukket på kortnummer'}/> ************{this.props.cardInformation ? this.props.cardInformation.last4 : ''}</span>
                              ) : null }
                              .
                            </div>
                          </Conditional>
                          <Conditional show={this.state.showPaymentErrorMessage}>
                            <div className='axored'>
                              <AxoLocal entity='InvoicePaymentFailed' defaultValue={'Betalingen kunne ikke gennemføres. Prøv igen senere.'}/>
                            </div>
                          </Conditional> */}
                      </div>
                      <br />
                    </Col>
                  </Row>
                </Grid>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
