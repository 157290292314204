import React from "react";
import PropTypes from "prop-types";

import { PrintService } from "../../services/AxoServices";

import { LexButton, AxoLocal } from "../../utilities/LexUtilities";

import { ButtonToolbar } from "react-bootstrap";

import moment from "moment";

const ListItem = (props) => {
  return <div style={{ paddingBottom: "5px" }}>{props.children}</div>;
};

export default class ApplicationUDDetailsList extends React.PureComponent {
  static propTypes = {
    model: PropTypes.object.isRequired,
  };

  onPrint = () => {
    if (typeof window !== "undefined") {
      PrintService.printElement(document.getElementById("printList"));
    }
  };

  render() {
    let { model, onEdit } = this.props;

    return (
      <div className="leftPadding topPadding standardMaxWidth">
        <ButtonToolbar>
          <LexButton onClick={onEdit}>
            <AxoLocal entity="presentationMarketing30" defaultValue="Rediger" />
          </LexButton>
          <LexButton onClick={this.onPrint}>
            <AxoLocal
              entity="PrintCaseClientadressePrint"
              defaultValue={"Print"}
            />
          </LexButton>
        </ButtonToolbar>
        <div id="printList">
          <ListItem>
            <strong>Navn:</strong> {model.name}
          </ListItem>
          <ListItem>
            <strong>Adresse:</strong> {model.address}
          </ListItem>
          <ListItem>
            <strong>Fødselsdato:</strong>{" "}
            {moment(model.dateOfBirth).format("L")}
          </ListItem>
          <ListItem>
            <strong>Fødeland:</strong> {model.countryOfBirth}
          </ListItem>
          <ListItem>
            <strong>E-Mail Adresse:</strong> {model.eMailAddress}
          </ListItem>
          <ListItem>
            <strong>Telefonnr.:</strong> {model.telephoneNumber}
          </ListItem>
        </div>
      </div>
    );
  }
}
