import React from "react";

import { Picky } from "react-picky";
import "react-picky/dist/picky.css";

// import ReactSelect from 'react-select'

import {
  ButtonToolbar,
  Modal,
  FormGroup,
  ControlLabel,
  // InputGroup,
  FormControl,
} from "react-bootstrap";

import {
  Icon,
  AsyncButton,
  AxoCheckbox,
  AxoLocal,
  // AxoLocal
} from "../../utilities/LexUtilities";
import { ApiService } from "../../services/AxoServices";
import LexButton from "../LexButton";

const PaymentProgress = {
  SELECT: 0,
  AMOUNT: 1,
  INVOICE: 2,
  CONFIRM: 3,
  RESULT: 4,
};

const CollectionTypes = {
  NONE: 0,
  FIXED: 1,
  VARIABLE: 2,
};

export default class SinglePaymentCollectionModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      ...this.getStartState(props.amount),
    };
  }

  getStartState = (amount) => {
    return {
      selectedOptionsArray: [],
      amount,
      collectionType: CollectionTypes.NONE,
      amountMap: {},
      description: "",
      sendCopy: false,
      results: null,
      paymentProgress: PaymentProgress.SELECT,
    };
  };

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({
      showModal: true,
    });
  };

  onOK = () => {
    this.setState({ showModal: false });
  };

  onCancel = () => {
    this.setState({ showModal: false });
  };

  selectMultipleOption = (value) => {
    this.setState({ selectedOptionsArray: value });
  };

  onCharge = async () => {
    let { updateRequests } = this.props;

    let { amount, amountMap, description, selectedOptionsArray, sendCopy } =
      this.state;

    let response = await ApiService.chargeRequestSubscriberList({
      requestGuidList: selectedOptionsArray.map((o) => o.id),
      amount,
      description,
      requestAmountMap: amountMap,
      sendCopyToOwner: sendCopy,
    });

    if (response.ok) {
      updateRequests();

      let results = await response.json();
      this.setState({
        description: "",
        sendCopy: false,
        results,
      });
    }

    return response.ok;
  };

  SelectView = () => {
    let { options } = this.props;

    let {
      // amount,
      // description,
      selectedOptionsArray,
      // amountMap,
      // sendCopy,
      // results
    } = this.state;

    return (
      <div style={{ height: "650px" }}>
        <Picky
          value={selectedOptionsArray}
          options={options}
          onChange={this.selectMultipleOption}
          open={true}
          valueKey="id"
          labelKey="name"
          multiple={true}
          includeSelectAll={true}
          includeFilter={true}
          dropdownHeight={600}
          selectAllMode="filtered"
          filterPlaceholder="Søg..."
          placeholder="Ingen valgt"
          selectAllText="Vælg alle"
          allSelectedPlaceholder="%s valgt"
          manySelectedPlaceholder="%s valgt"
        />
      </div>
    );
  };

  AmountView = () => {
    let { amount, selectedOptionsArray, collectionType, amountMap } =
      this.state;

    return (
      <>
        <ButtonToolbar>
          <LexButton
            disabled={collectionType === CollectionTypes.FIXED}
            onClick={() =>
              this.setState({ collectionType: CollectionTypes.FIXED })
            }
          >
            Fast beløb
          </LexButton>
          <LexButton
            disabled={collectionType === CollectionTypes.VARIABLE}
            onClick={() =>
              this.setState({ collectionType: CollectionTypes.VARIABLE })
            }
          >
            Variable beløb
          </LexButton>
        </ButtonToolbar>
        {collectionType === CollectionTypes.FIXED && (
          <FormGroup controlId="amount" bsSize="large">
            <ControlLabel
              className="rightPadding"
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" /> (DKK)
            </ControlLabel>
            <FormControl
              type="number"
              disabled={selectedOptionsArray.length === 0}
              className="borderFormControlfocus"
              value={isNaN(amount) ? "" : amount}
              onChange={(event) =>
                this.setState({ amount: event.target.valueAsNumber })
              }
            />
          </FormGroup>
        )}
        {collectionType === CollectionTypes.VARIABLE && (
          <>
            <ControlLabel
              className="rightPadding"
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" /> (DKK)
            </ControlLabel>
            {selectedOptionsArray.map((o) => {
              let amount = amountMap[o.id];
              return (
                <FormGroup controlId={`amount-${o.id}`} key={o.id}>
                  <ControlLabel
                    className="rightPadding"
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    {o.name}
                  </ControlLabel>
                  <FormControl
                    type="number"
                    className="borderFormControlfocus"
                    value={isNaN(amount) ? "" : amount}
                    onChange={(event) => {
                      let newAmount = event.target.valueAsNumber;
                      this.setState((prevState) => {
                        let newMap = { ...prevState.amountMap };
                        newMap[o.id] = newAmount;
                        return { amountMap: newMap };
                      });
                    }}
                  />
                </FormGroup>
              );
            })}
          </>
        )}
      </>
    );
  };

  InvoiceView = () => {
    let { description, selectedOptionsArray, sendCopy } = this.state;

    return (
      <>
        <FormGroup controlId="description" bsSize="large">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
          </ControlLabel>
          <FormControl
            name="text"
            componentClass="textarea"
            disabled={selectedOptionsArray.length === 0}
            value={description}
            rows={3}
            className="borderFormControlfocus"
            // value={textFilter.filterString}
            onChange={(event) =>
              this.setState({ description: event.target.value })
            }
          />
        </FormGroup>
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <AxoCheckbox
            checked={sendCopy}
            onChange={(event) =>
              this.setState({ sendCopy: event.target.checked })
            }
          />
          <AxoLocal
            entity="axoEntityidcode199"
            defaultValue={"Send kopi af faktura mail til afsender"}
          />
        </div>
        <div className="axored topPadding">
          <AxoLocal
            entity="axoEntityidcode200"
            defaultValue={
              "Husk at sikre at alle valgte brugere har givet samtykke til at hæve beløbet."
            }
          />
        </div>
      </>
    );
  };

  ConfirmView = () => {
    let { amount, selectedOptionsArray, collectionType, amountMap, results } =
      this.state;

    if (!!results) {
      let ResultsBody = this.ResultsBody;
      return <ResultsBody />;
    }

    return (
      <>
        {selectedOptionsArray.length > 0 && (
          <>
            {collectionType === CollectionTypes.FIXED ? (
              <h4>DKK {amount} opkræves fra</h4>
            ) : (
              <h4>Følgende beløb opkræves:</h4>
            )}
            {selectedOptionsArray.map((r) => (
              <div key={r.id}>
                <strong>{r.name}</strong>&nbsp;
                {collectionType === CollectionTypes.VARIABLE && (
                  <> {amountMap[r.id]} DKK&nbsp;</>
                )}
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  StepBody = () => {
    let { paymentProgress } = this.state;

    let SelectView = this.SelectView;
    let AmountView = this.AmountView;
    let InvoiceView = this.InvoiceView;
    let ConfirmView = this.ConfirmView;

    return (
      <Modal.Body
        style={{
          minHeight: "300px",
          borderStyle: "none none solid none",
          borderWidth: "0px 0px 3px 0px",
          borderColor: "#e6e6e6",
        }}
      >
        {paymentProgress === PaymentProgress.SELECT && <SelectView />}
        {paymentProgress === PaymentProgress.AMOUNT && <AmountView />}
        {paymentProgress === PaymentProgress.INVOICE && <InvoiceView />}
        {paymentProgress === PaymentProgress.CONFIRM && <ConfirmView />}
      </Modal.Body>
    );
  };

  ResultsBody = () => {
    let { onSendPaymentFailureMessage } = this.props;

    let { results, selectedOptionsArray, amount, collectionType, amountMap } =
      this.state;

    let resultTexts = selectedOptionsArray
      .filter((o) => !!results[o.id])
      .map((o) => ({ id: o.id, name: o.name, result: results[o.id] }));

    let paidResults = resultTexts.filter((r) => r.result === "Paid");
    let failedResults = resultTexts.filter((r) => r.result !== "Paid");

    return (
      <>
        {paidResults.length > 0 && (
          <>
            {collectionType === CollectionTypes.FIXED && (
              <h4>
                DKK {amount}&nbsp;
                <AxoLocal
                  entity="axoEntityidcode201"
                  defaultValue={"opkrævet fra"}
                />
              </h4>
            )}
            {paidResults.map((r) => (
              <div key={r.id}>
                <strong>{r.name}</strong>&nbsp;
                {collectionType === CollectionTypes.VARIABLE && (
                  <>{amountMap[r.id]} DKK&nbsp;</>
                )}
                <Icon className="axogreen" glyph="icon-fontello-check" />
              </div>
            ))}
          </>
        )}
        {failedResults && (
          <>
            {failedResults.map((r) => (
              <div key={r.id}>
                <h4>
                  <AxoLocal
                    entity="axoEntityidcode202"
                    defaultValue={"Betaling fejlede for"}
                  />
                </h4>
                <div>
                  <strong>{r.name}</strong>
                  &nbsp;&nbsp;&nbsp;
                  <AsyncButton
                    onClick={() => onSendPaymentFailureMessage(r.id)}
                  >
                    <AxoLocal
                      entity="axoEntityidcode203"
                      defaultValue={"Send tilmeldingslink"}
                    />
                  </AsyncButton>
                </div>
                <div className="axored">{r.result}</div>
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  resetState = () => {
    let { amount } = this.props;
    this.setState(this.getStartState(amount));
  };

  render() {
    let {
      amount,
      description,
      selectedOptionsArray,
      results,
      collectionType,
      amountMap,
      paymentProgress,
    } = this.state;

    let validAmounts = false;
    switch (collectionType) {
      case CollectionTypes.FIXED:
        validAmounts = !!amount && amount > 0;
        break;
      case CollectionTypes.VARIABLE:
        validAmounts = !selectedOptionsArray.find(
          (o) => !amountMap[o.id] || amountMap[o.id] < 0
        );
        break;
      default:
        break;
    }

    let allowNext = false;
    switch (paymentProgress) {
      case PaymentProgress.SELECT:
        allowNext = selectedOptionsArray.length > 0;
        break;
      case PaymentProgress.AMOUNT:
        allowNext = validAmounts;
        break;
      case PaymentProgress.INVOICE:
        allowNext = !!description;
        break;
      default:
        break;
    }

    let StepBody = this.StepBody;
    return (
      <Modal bsSize="large" show={this.state.showModal} onHide={this.close}>
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
          closeButton
        >
          <Modal.Title>
            {paymentProgress === PaymentProgress.SELECT && (
              <AxoLocal
                entity="axoEntityidcode204"
                defaultValue={"Vælg medlemmer"}
              />
            )}
            {paymentProgress === PaymentProgress.AMOUNT && <>Angiv beløb</>}
            {paymentProgress === PaymentProgress.INVOICE && (
              <>Faktura beskrivelse</>
            )}
            {paymentProgress === PaymentProgress.CONFIRM && (
              <>{!!results ? <>Resultat</> : <>Bekræft detaljer</>}</>
            )}
          </Modal.Title>
        </Modal.Header>

        <StepBody />

        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <LexButton
              disabled={paymentProgress <= PaymentProgress.SELECT}
              onClick={() =>
                this.setState((prevState) => ({
                  paymentProgress: prevState.paymentProgress - 1,
                }))
              }
            >
              {"<-- Forrige"}
            </LexButton>
            {paymentProgress < PaymentProgress.CONFIRM ? (
              <LexButton
                disabled={!allowNext}
                onClick={() =>
                  this.setState((prevState) => ({
                    paymentProgress: prevState.paymentProgress + 1,
                  }))
                }
              >
                {"Næste -->"}
              </LexButton>
            ) : (
              <AsyncButton
                className="Lex-button-2"
                disabled={
                  selectedOptionsArray.length === 0 ||
                  !validAmounts ||
                  !description
                }
                onClick={this.onCharge}
              >
                {/* <Icon glyph="icon-fontello-ok-3"/>&nbsp;&nbsp; */}
                <AxoLocal
                  entity="axoEntityidcode205"
                  defaultValue={"Hæv beløb"}
                />
              </AsyncButton>
            )}

            {/* <Button className='Lex-button-2' onClick={this.onCancel}>
              <Icon glyph="icon-fontello-cancel"/>&nbsp;&nbsp; 
              <AxoLocal entity='axoAccounting22'defaultValue={'Fortryd'}/>
            </Button> */}
            {!!results && (
              <LexButton onClick={this.resetState}>
                <AxoLocal
                  entity="axoEntityidcode206"
                  defaultValue={"Ny opkrævning"}
                />
              </LexButton>
            )}
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
