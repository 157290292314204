import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import DataStore from "../../services/DataAccess/DataStore";
import RoutingService from "../../services/RoutingService";
import InlineEdit from "../../utilities/InlineEdit";
import Conditional from "../../utilities/Conditional";
import { CountryDropdown } from "../../utilities/CountrySelect/Dropdowns";
import moment from "moment";
import ModalService from "../../services/ModalService";
import MessageService from "../../services/MessageService";
import UserInfoService from "../../services/UserInfoService";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import { withRouter } from "react-router-dom";

// import Select from 'react-select';

import {
  AuthorizationService,
  ContactService,
} from "../../services/AxoServices";

import {
  Row,
  Col,
  Grid,
  Button,
  ButtonGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  FormGroup,
  Form,
} from "react-bootstrap";

import {
  Icon,
  AxoLocal,
  InlineTextArea,
  Flexbox,
  FlexElement,
  AxoCheckbox,
  AsyncButton,
  AxoDateTimeEdit,
} from "../../utilities/LexUtilities";

class ClientAccountForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showinvalidUserName: false,
      accountMail: "",
      accountConfirmMail: "",
      accountUserName: "",
      accountMessage: "",
      accountSendToOwner: false,
      validationState: {
        existingClientAccount: false,
        existingForeignClientAccount: false,
        duplicateEmail: false,
        invalidFirstName: false,
        invalidUserName: false,
      },
    };
    this.existingAccountName = "";
  }

  makeValidationState = () => {
    return {
      existingClientAccount: false,
      existingForeignClientAccount: false,
      duplicateEmail: false,
      invalidFirstName: false,
      invalidUserName: false,
    };
  };

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  getEmailValidationState = (email) => {
    if (email.length === 0) {
      return null;
    }
    return this.validateEmail(email) ? "success" : "error";
  };

  getConfirmEmailValidationState = (email) => {
    let { accountMail } = this.state;
    if (accountMail.length === 0) {
      return null;
    }
    return accountMail === email ? "success" : "error";
  };

  onClearMail = () => {
    let { validationState } = this.state;
    this.setState({
      accountMail: "",
      accountConfirmMail: "",
      validationState: {
        ...validationState,
        existingForeignClientAccount: false,
      },
    });
  };

  // onAddExistingClientAccount = () => {
  //   ApiService.getClientProfileFromEmail(this.state.accountMail)
  //     .then(this.parseClientProfile)
  //     .then(this.createFriendRequest)
  //     .then(this.updateClient)
  //     .catch(reason => {
  //       console.log(reason);
  //     })
  // }

  // parseClientProfile = (response) => {
  //   if(response.ok) {
  //     return response.json();
  //   }
  //   return Promise.reject("Could not find client profile");
  // }

  // createFriendRequest = (clientProfile) => {
  //   var friendRequest = {
  //     receiver: clientProfile
  //   };
  //   this.existingAccountName = clientProfile.userName;
  //   return ApiService.createFriendRequest(friendRequest);
  // }

  // updateClient = (response) => {
  //   if(response.ok) {
  //     var contact = Object.assign({}, this.props.contact);
  //     contact.clientUserName = this.existingAccountName;
  //     this.updateContact(contact);
  //   }
  //   else {
  //     return Promise.reject("Could not send friend request");
  //   }
  // }

  updateContact = (contact) => {
    this.props.onContactChange(contact);
  };

  validateClientAccountInfo = () => {
    let { accountMail, accountConfirmMail } = this.state;
    if (accountMail !== accountConfirmMail) {
      return false;
    }

    if (!this.validateEmail(accountMail)) {
      return false;
    }
    if (!this.state.accountUserName) {
      return;
    }
    return true;
  };

  onSendLoginMail = async () => {
    if (!this.validateClientAccountInfo()) {
      return false;
    }

    let contact = { ...this.props.contact };
    contact.clientUserName = this.state.accountUserName;
    contact.clientMessage = this.state.accountMessage;
    contact.skipReceiptScan = true;

    let validationState = this.makeValidationState();

    try {
      await this.createClientAccount(contact);
      this.props.onContactChange(contact);
      return true;
    } catch (response) {
      let message = response.data;
      if (!message.error) {
        ModalService.openAlertModal(
          <AxoLocal
            entity="ContactCreateViewmessageerror"
            defaultValue={
              "Selvbetjeningskontoen kunne ikke oprettes. Prøv et andet password."
            }
          />
        );
        // this.props.onContactChange(contact); //In case the account was created,
      } else if (message.error === "ExistingClient") {
        validationState.existingClientAccount = true;
      } else if (message.error === "ExistingForeignClient") {
        validationState.existingForeignClientAccount = true;
      } else if (message.error === "DuplicateEmail") {
        validationState.duplicateEmail = true;
      } else if (message.error === "DuplicateUserName") {
        validationState.invalidUserName = true;
      } else if (message.error === "InvalidPassword") {
        validationState.invalidPassword = true;
      } else if (message.error === "EmailNotSend") {
        ModalService.openAlertModal(
          <span>Kontoen blev oprettet, men mailen kunne ikke sendes.</span>
        );
        this.props.onContactChange(contact); //Save the account name.
      }
      this.setState({ validationState: validationState });

      return false;
    }
  };

  createClientAccount = (contact) => {
    let { createClientAccount } = this.props;

    return createClientAccount({
      userName: contact.clientUserName,
      contactInfo: contact,
      email: this.state.accountMail,
      messageUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        RoutingService.getAccountPath("ResetPassword"),
      subject: ContactService.getContactMailSubject(),
      body: ContactService.getContactMailBody(contact),
      sendMailToOwner: this.state.accountSendToOwner,
    }).unwrap();
  };

  render() {
    let { userProfile } = this.props;

    let { validationState } = this.state;

    return (
      <Form>
        <ControlLabel style={{ textAlign: "left" }}>
          <Icon glyph="icon-fontello-plus-2" />
          &nbsp;
          <AxoLocal
            entity="ContactCreateViewAddselfserviceclient"
            defaultValue={"Tilføj selvbetjening til klient"}
          />
        </ControlLabel>
        <FormGroup>
          <Grid fluid>
            <Row>
              <Col
                componentClass={ControlLabel}
                style={{ textAlign: "left", fontWeight: "normal" }}
                xs={3}
              >
                <AxoLocal
                  entity="SidebarRightContaineruserProfileuserName"
                  defaultValue={"Brugernavn"}
                />
              </Col>
              <Col xs={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-lock-2" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={this.state.accountUserName || ""}
                    onChange={(event) => {
                      this.setState({ accountUserName: event.target.value });
                    }}
                  />
                </InputGroup>
                {this.state.validationState.invalidUserName ? (
                  <div style={{ color: "red" }}>
                    <AxoLocal
                      entity="SignupvalidationStateinvalidUserName"
                      defaultValue={"Brugernavnet er optaget."}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </Grid>
        </FormGroup>
        <FormGroup
          validationState={this.getEmailValidationState(this.state.accountMail)}
        >
          <Grid fluid>
            <Row>
              <Col
                componentClass={ControlLabel}
                style={{ textAlign: "left", fontWeight: "normal" }}
                xs={3}
              >
                <AxoLocal entity="CaseEditFormeMail" defaultValue={"Email"} />
              </Col>
              <Col xs={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph=" icon-fontello-lock-open-2" />
                  </InputGroup.Addon>
                  <AxoLocal
                    componentClass={FormControl}
                    type="email"
                    value={this.state.accountMail}
                    onChange={(event) => {
                      this.setState({ accountMail: event.target.value });
                    }}
                    componentAttribute="placeholder"
                    entity="placeholderLoginwillbesenthereTest"
                  />
                </InputGroup>
                <Conditional show={validationState.existingClientAccount}>
                  <div style={{ color: "red" }}>
                    <AxoLocal
                      entity="ContactCreateExistingClientAccount"
                      defaultValue={
                        "Du har allerede en klient med den pågældende email adresse."
                      }
                    />
                  </div>
                </Conditional>
                <Conditional
                  show={validationState.existingForeignClientAccount}
                >
                  <div>
                    <AxoLocal
                      entity="ContactCreateDuplicateEmail"
                      defaultValue={
                        "Der eksisterer allerede en klientkonto med den pågældende email."
                      }
                    />
                  </div>
                  {/* <ButtonToolbar> */}
                  {/* <Button className='Lex-button-2' onClick={this.onAddExistingClientAccount}>
                      <AxoLocal entity='ContactCreateClientAccountA' defaultValue={'Tilføj eksisterende konto.'}/>
                    </Button> */}
                  <Button className="Lex-button-2" onClick={this.onClearMail}>
                    <AxoLocal
                      entity="ContactCreateClientAccountB"
                      defaultValue={"Ryd mail."}
                    />
                  </Button>
                  {/* </ButtonToolbar> */}
                </Conditional>
                {validationState.duplicateEmail ? (
                  <div style={{ color: "red" }}>
                    <AxoLocal
                      entity="SignupEmailrequired1a"
                      defaultValue={"Email adressen anvendes allerede."}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </Grid>
        </FormGroup>

        <FormGroup
          validationState={this.getConfirmEmailValidationState(
            this.state.accountConfirmMail
          )}
        >
          <Grid fluid>
            <Row>
              <Col
                componentClass={ControlLabel}
                style={{ textAlign: "left", fontWeight: "normal" }}
                xs={3}
              >
                <AxoLocal
                  entity="signupViewConfirmEmail"
                  defaultValue="Bekræft email"
                />
              </Col>
              <Col xs={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph=" icon-fontello-lock-open-2" />
                  </InputGroup.Addon>
                  <AxoLocal
                    componentClass={FormControl}
                    type="email"
                    value={this.state.accountConfirmMail}
                    onChange={(event) => {
                      this.setState({ accountConfirmMail: event.target.value });
                    }}
                    componentAttribute="placeholder"
                    entity="signupViewConfirmEmail"
                  />
                </InputGroup>
              </Col>
            </Row>
          </Grid>
        </FormGroup>
        {!!userProfile && userProfile.userType === "Admin" && (
          <FormGroup>
            <Grid fluid>
              <Row>
                <Col
                  componentClass={ControlLabel}
                  style={{ textAlign: "left", fontWeight: "normal" }}
                  xs={3}
                >
                  Send til mig
                </Col>
                <Col xs={9}>
                  <AxoCheckbox
                    checked={this.state.accountSendToOwner}
                    onChange={(event) =>
                      this.setState({
                        accountSendToOwner: event.target.checked,
                      })
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </FormGroup>
        )}
        <FormGroup>
          <Grid fluid>
            <Row>
              <Col
                componentClass={ControlLabel}
                style={{ textAlign: "left", fontWeight: "normal" }}
                xs={3}
              >
                <AxoLocal
                  entity="friendRequestTimelineNotification"
                  defaultValue={"Besked"}
                />
              </Col>
              <Col xs={9}>
                <textarea
                  className="form-control"
                  value={this.state.accountMessage || ""}
                  onChange={(event) => {
                    this.setState({ accountMessage: event.target.value });
                  }}
                ></textarea>
              </Col>
            </Row>
          </Grid>
        </FormGroup>
        <Grid fluid>
          <Row>
            <Col smOffset={1} sm={10} xs={12} style={{ marginBottom: "15px" }}>
              <div className="text-center">
                <AsyncButton
                  onClick={this.onSendLoginMail}
                  className="Lex-button-2"
                >
                  <AxoLocal
                    entity="ContactCreateClientAccountC"
                    defaultValue={" Send login mail."}
                  />
                </AsyncButton>
              </div>
            </Col>
          </Row>
        </Grid>
      </Form>
    );
  }
}

class ContactCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      sendingLoginMail: false,
      sentLoginMail: false,
      showLoginMailFailureMessage: false,
      showDuplicateEmailMessage: false,
      showCopyCompletedMessage: false,
    };
  }

  //Props contact
  onContactPropertyChange = (propertyName, input) => {
    this.onContactPropertyValueChange(propertyName, input.value);
  };

  onContactPropertyValueChange = (propertyName, value) => {
    let { contact, onContactChange } = this.props;

    onContactChange({
      ...contact,
      [propertyName]: value,
    });
  };

  onTrashContact = () => {
    let { contact, onContactChange } = this.props;

    onContactChange({
      ...contact,
      trashed: true,
    });
  };

  onEditButton = () => {
    this.setState({ editing: !this.state.editing });
  };

  onResendMail = async () => {
    let contact = this.props.contact;

    this.setState({ sendingLoginMail: true });
    return ApiService.resendContactMail(contact, {
      messageUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        RoutingService.getAccountPath("ResetPassword"),
      subject: ContactService.getContactMailSubject(),
      body: ContactService.getContactMailBody(contact),
    })
      .then((response) => {
        this.setState({ sendingLoginMail: false });
        if (response.ok) {
          this.setState({ sentLoginMail: true });
        } else {
          response.text().then((errorText) => {
            console.log(errorText);
            this.showWarningMessage(errorText);
          });
        }
      })
      .catch(() => {
        this.setState({ sendingLoginMail: false });
        this.showWarningMessage();
      });
  };

  showWarningMessage = (errorText) => {
    console.log(errorText);
    if (errorText.includes("DuplicateEmail")) {
      console.log("Show duplicate");
      this.setState({ showDuplicateEmailMessage: true });
      setTimeout(() => {
        this.setState({ showDuplicateEmailMessage: false });
      }, 5000);

      return;
    }

    this.setState({ showLoginMailFailureMessage: true });
    setTimeout(() => {
      this.setState({ showLoginMailFailureMessage: false });
    }, 5000);
  };

  renderSentLoginMailSection = () => {
    let {
      showDuplicateEmailMessage,
      showLoginMailFailureMessage,
      sentLoginMail,
    } = this.state;

    if (showDuplicateEmailMessage) {
      return (
        <div className="axored">
          <AxoLocal
            entity="SignupEmailrequired1a"
            defaultValue={"Email adressen anvendes allerede."}
          />
        </div>
      );
    }
    if (showLoginMailFailureMessage) {
      return (
        <div className="axored">
          <AxoLocal
            key="Message"
            entity="ContactGridViewSentMailFailureMessage"
            defaultValue={"Mailen kunne ikke sendes. Prøv igen senere."}
          />
        </div>
      );
    }
    if (sentLoginMail) {
      return (
        <div>
          <AxoLocal
            key="Sendt"
            entity="ContactGridViewResentMailConfirmation"
            defaultValue={"Mailen blev sendt"}
          />
        </div>
      );
    }
    return (
      <AsyncButton
        type="button"
        className="btn Lex-button"
        disabled={this.state.sendingLoginMail}
        onClick={this.onResendMail}
      >
        {this.state.sendingLoginMail ? (
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        ) : (
          <Icon glyph="icon-fontello-mail-1" />
        )}{" "}
        &nbsp;&nbsp;
        <AxoLocal
          key="Mail"
          entity="ContactGridViewResendMail"
          defaultValue={"Gensend login mail"}
        />
      </AsyncButton>
    );
  };

  selectCountry(val) {
    var contact = Object.assign({}, this.props.contact);
    contact.country = val;
    this.props.onContactChange(contact);
  }

  onClientTypeChange = (event) => {
    var contact = Object.assign({}, this.props.contact);
    contact.clientType = parseInt(event.target.value, 10);
    this.props.onContactChange(contact);
  };

  renderEditForm = () => {
    let { contact, userProfile, locale, createClientAccount } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div
        style={{
          textAlign: "left",
          fontSize: "15px",
          fontWeight: "normal",
          padding: "3px",
        }}
      >
        <table
          className="Lex-Grid-tabl-1"
          style={{ padding: "0px", marginBottom: "0px" }}
        >
          <tbody style={{ padding: "0px" }}>
            <tr>
              <th
                className="col-sm-4 col-xs-5 TextSizegridView"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-qrcode" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="Customernumber"
                  entity="ContactCreateViewcustomernumber"
                  defaultValue={"ID-nummer"}
                />
                :
              </th>
              <td>
                <InlineEdit
                  value={contact.clientNumber || "---"}
                  change={this.onContactPropertyChange.bind(
                    this,
                    "clientNumber"
                  )}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-sm-4 col-xs-3"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-user-7" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="name"
                  entity="ContactGridViewName"
                  defaultValue={"Navn"}
                />
                :
              </th>
              <td>
                <InlineEdit
                  value={contact.firstName || "---"}
                  change={this.onContactPropertyChange.bind(this, "firstName")}
                />
                &nbsp;
                <InlineEdit
                  value={contact.lastName || "---"}
                  change={this.onContactPropertyChange.bind(this, "lastName")}
                />
              </td>
            </tr>
            {!!userProfile && userProfile.userType === "Admin" && (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-desktop" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="CompanynameA"
                    entity="SidebarRightContainercompanyNameA"
                    defaultValue={"Login"}
                  />
                  :{" "}
                </th>
                <td>
                  <InlineEdit
                    value={contact.clientUserName || "---"}
                    change={this.onContactPropertyChange.bind(
                      this,
                      "clientUserName"
                    )}
                  />
                </td>
              </tr>
            )}
            {userType === "Society" && (
              <>
                <tr>
                  <th
                    className="col-sm-4 col-xs-5 TextSizegridView"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "normal",
                    }}
                  >
                    <Icon glyph="icon-fontello-calendar" />
                    &nbsp;&nbsp; Fødselsdag:
                  </th>
                  <td>
                    <AxoDateTimeEdit
                      locale={locale}
                      date={moment.utc(contact.birthDate)}
                      onChange={(newMoment) =>
                        this.onContactPropertyValueChange(
                          "birthDate",
                          newMoment
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    className="col-sm-4 col-xs-5 TextSizegridView"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "normal",
                    }}
                  >
                    <Icon glyph="icon-fontello-home" />
                    &nbsp;&nbsp; Husstand:
                  </th>
                  <td>
                    <InlineEdit
                      value={contact.customFieldOne || "---"}
                      change={this.onContactPropertyChange.bind(
                        this,
                        "customFieldOne"
                      )}
                    />
                  </td>
                </tr>
              </>
            )}
            {userType !== "Society" ? (
              <React.Fragment>
                <tr>
                  <th
                    className=" col-sm-4 col-xs-3"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "normal",
                    }}
                  >
                    <Icon glyph="icon-fontello-building" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      key="company"
                      entity="SidebarRightContainercompanyName"
                      defaultValue={"Virksomhed"}
                    />
                    :{" "}
                  </th>
                  <td>
                    <InlineEdit
                      value={contact.companyName || "---"}
                      change={this.onContactPropertyChange.bind(
                        this,
                        "companyName"
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    className=" col-sm-4 col-xs-3"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: "normal",
                    }}
                  >
                    <Icon glyph="icon-fontello-building" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="vatCountryCode31"
                      defaultValue={"CPR / CVR"}
                    />{" "}
                    :{" "}
                  </th>
                  <td>
                    <InlineEdit
                      value={contact.identityCode || "---"}
                      change={this.onContactPropertyChange.bind(
                        this,
                        "identityCode"
                      )}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ) : null}
            <tr>
              <th
                className=" col-sm-4 col-xs-3"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-location-4" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="address"
                  entity="SignupAdresse"
                  defaultValue={"Adresse"}
                />
                :{" "}
              </th>
              <td>
                <InlineEdit
                  value={contact.address || "---"}
                  change={this.onContactPropertyChange.bind(this, "address")}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-xs-4"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-direction-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="postalcode"
                  entity="CaseEditFormpostalCode"
                  defaultValue={"Postnummer"}
                />
                :{" "}
              </th>
              <td>
                <InlineEdit
                  value={contact.postalCode || "---"}
                  change={this.onContactPropertyChange.bind(this, "postalCode")}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-sm-4 col-xs-3"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-warehouse" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="city"
                  entity="CaseEditFormcity"
                  defaultValue={"By"}
                />
                :{" "}
              </th>
              <td>
                <InlineEdit
                  value={contact.city || "---"}
                  change={this.onContactPropertyChange.bind(this, "city")}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-sm-4 col-xs-3"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-globe-6" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="country"
                  entity="CaseEditFormcountry"
                  defaultValue={"Land"}
                />
                :{" "}
              </th>
              <td>
                <CountryDropdown
                  value={contact.country || ""}
                  valueType="short"
                  customSelect
                  onChange={(val) => this.selectCountry(val)}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-sm-4 col-xs-3"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-phone" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="phone"
                  entity="ContainerPhone"
                  defaultValue={"Telefon"}
                />
                :{" "}
              </th>
              <td>
                <InlineEdit
                  value={contact.phone || "---"}
                  change={this.onContactPropertyChange.bind(this, "phone")}
                />
              </td>
            </tr>
            <tr>
              <th
                className="col-xs-4"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-mobile-4" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="mobile"
                  entity="socialSidebarContainerMobile"
                  defaultValue={"Mobile"}
                />
                :{" "}
              </th>
              <td>
                <InlineEdit
                  value={contact.mobile || "---"}
                  change={this.onContactPropertyChange.bind(this, "mobile")}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-sm-4 col-xs-3"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-at-2" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="mail"
                  entity="CaseEditFormeMail"
                  defaultValue={"Email"}
                />
                :{" "}
              </th>
              <td>
                <InlineEdit
                  value={contact.eMail || "---"}
                  change={this.onContactPropertyChange.bind(this, "eMail")}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-sm-3 col-xs-2"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                &nbsp;&nbsp;
                <AxoLocal
                  key="description"
                  entity="Enhedspris6"
                  defaultValue={"Beskrivelse"}
                />
                :{" "}
              </th>
              <td>
                <InlineTextArea
                  value={contact.description || "---"}
                  change={this.onContactPropertyChange.bind(
                    this,
                    "description"
                  )}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-sm-2 col-xs-1"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-facebook" />
                &nbsp;&nbsp;
                <AxoLocal
                  className="hidden-sm hidden-xs"
                  key="facebook"
                  entity="ContactCreateViewFacebook"
                  defaultValue={"Facebook"}
                />
                :{" "}
              </th>
              <td>
                <InlineEdit
                  value={contact.facebook || "---"}
                  change={this.onContactPropertyChange.bind(this, "facebook")}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-sm-2 col-xs-1"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-twitter" />
                &nbsp;&nbsp;
                <AxoLocal
                  className="hidden-sm hidden-xs"
                  key="twitter"
                  entity="ContactCreateViewTwitter"
                  defaultValue={"Twitter"}
                />
                :{" "}
              </th>
              <td>
                <InlineEdit
                  value={contact.twitter || "---"}
                  change={this.onContactPropertyChange.bind(this, "twitter")}
                />
              </td>
            </tr>
            <tr>
              <th
                className=" col-sm-2 col-xs-1"
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-linkedin" />
                &nbsp;&nbsp;
                <AxoLocal
                  className="hidden-sm hidden-xs"
                  key="linkedin"
                  entity="ContactCreateViewLinkedIn"
                  defaultValue={"LinkedIn"}
                />
                :{" "}
              </th>
              <td>
                <InlineEdit
                  value={contact.linkedIn || "---"}
                  change={this.onContactPropertyChange.bind(this, "linkedIn")}
                />
              </td>
            </tr>
            {userType !== "Society" ? (
              <tr>
                <th
                  className=" col-sm-4 col-xs-3"
                  style={{
                    textAlign: "left",
                    fontSize: "15px",
                    fontWeight: "normal",
                  }}
                >
                  <Icon glyph="icon-fontello-chat-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="WeChat"
                    entity="ContactWeChat"
                    defaultValue={"WeChat"}
                  />
                  :{" "}
                </th>
                <td>
                  <InlineEdit
                    value={contact.weChat || "---"}
                    change={this.onContactPropertyChange.bind(this, "weChat")}
                  />
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        <React.Fragment>
          {contact.clientUserName ? this.renderSentLoginMailSection() : null}
          {!contact.clientUserName ? (
            <ClientAccountForm
              contact={this.props.contact}
              userProfile={userProfile}
              onContactChange={this.props.onContactChange}
              createClientAccount={createClientAccount}
            />
          ) : null}
        </React.Fragment>
      </div>
    );
  };

  renderInformation = () => {
    let { contact, userProfile } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div style={{ padding: "0px" }}>
        <table
          className="table Lex-Grid-tabl"
          style={{ padding: "0px", marginBottom: "0px" }}
        >
          <tbody style={{ padding: "0px" }}>
            <tr>
              <th className="col-md-5 col-xs-5">
                <Icon glyph="icon-fontello-qrcode" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="barcode"
                  entity="ContactCreateViewcustomernumber"
                  defaultValue={"ID-nummer"}
                />
                :{" "}
              </th>
              <td>{contact.clientNumber} </td>
            </tr>
            {userType === "Society" && (
              <>
                {!!contact.birthDate && (
                  <tr>
                    <th className=" col-md-4 col-xs-3">
                      <Icon glyph="icon-fontello-calendar" />
                      &nbsp;&nbsp; Fødselsdag:{" "}
                    </th>
                    <td>{moment.utc(contact.birthDate).format("L")}</td>
                  </tr>
                )}
                {!!contact.customFieldOne && (
                  <tr>
                    <th className=" col-md-4 col-xs-3">
                      <Icon glyph="icon-fontello-home" />
                      &nbsp;&nbsp; Husstandsmedlem:{" "}
                    </th>
                    <td>{contact.customFieldOne}</td>
                  </tr>
                )}
              </>
            )}
            {contact.clientUserName ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-desktop" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="CompanynameA"
                    entity="SidebarRightContainercompanyNameA"
                    defaultValue={"Login"}
                  />
                  :{" "}
                </th>
                <td>{contact.clientUserName}</td>
              </tr>
            ) : null}
            {contact.companyName ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-building" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="Companyname"
                    entity="SidebarRightContainercompanyName"
                    defaultValue={"Virksomhed"}
                  />
                  :{" "}
                </th>
                <td>{contact.companyName}</td>
              </tr>
            ) : null}
            {contact.identityCode ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-building" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="vatCountryCode31"
                    defaultValue={"CPR / CVR"}
                  />{" "}
                  :{" "}
                </th>
                <td>{contact.identityCode}</td>
              </tr>
            ) : null}
            {contact.address ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-location-4" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="Adresse"
                    entity="SignupAdresse"
                    defaultValue={"Adresse"}
                  />
                  :{" "}
                </th>
                <td>{contact.address}</td>
              </tr>
            ) : null}
            {contact.postalCode ? (
              <tr>
                <th className=" col-md-4 col-xs-4">
                  <Icon glyph="icon-fontello-direction-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="direction"
                    entity="CaseEditFormpostalCode"
                    defaultValue={"Postnummer"}
                  />
                  :{" "}
                </th>
                <td>{contact.postalCode}</td>
              </tr>
            ) : null}
            {contact.city ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-warehouse" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="Cityby"
                    entity="CaseEditFormcity"
                    defaultValue={"By"}
                  />
                  :{" "}
                </th>
                <td>{contact.city}</td>
              </tr>
            ) : null}
            {contact.country ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-globe-6" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="Comtryland"
                    entity="CaseEditFormcountry"
                    defaultValue={"Land"}
                  />
                  :{" "}
                </th>
                <td>
                  <AxoLocal
                    entity="CountryNames"
                    data={{ countrySlug: contact.country }}
                  />
                </td>
              </tr>
            ) : null}
            {contact.phone ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-phone-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="Phonenr"
                    entity="CaseEditFormphone"
                    defaultValue={"Tlf"}
                  />
                  :
                </th>
                <td>
                  <a href={"tel:" + contact.phone}> {contact.phone}</a>
                </td>
              </tr>
            ) : null}
            {contact.mobile ? (
              <tr>
                <th className=" col-md-4 col-xs-5">
                  <Icon glyph="icon-fontello-mobile-4" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="mobile"
                    entity="socialSidebarContainerMobile"
                    defaultValue={"Mobile"}
                  />
                  :
                </th>
                <td>
                  <a href={"tel:" + contact.mobile}> {contact.mobile}</a>
                </td>
              </tr>
            ) : null}
            {contact.eMail ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-mail-7" /> &nbsp;&nbsp;
                  <AxoLocal
                    key="Emiladresse"
                    entity="CaseEditFormeMail"
                    defaultValue={"Email"}
                  />
                  :
                </th>
                <td>
                  <a
                    href="link"
                    onClick={this.props.onSendMail.bind(this, contact.eMail)}
                  >
                    {" "}
                    {contact.eMail}
                  </a>
                </td>
              </tr>
            ) : null}
            {contact.description ? (
              <tr>
                <th className="col-md-3 col-xs-2">
                  <AxoLocal
                    key="Noter"
                    entity="Enhedspris6"
                    defaultValue={"Beskrivelse"}
                  />
                  :
                </th>
                <td>{contact.description}</td>
              </tr>
            ) : null}
            <tr>
              <th className=" col-md-4 col-xs-3">
                <Icon glyph="icon-fontello-calendar-8" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="creationDate"
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
                :{" "}
              </th>
              <td>{moment(contact.creationDate).format("L")} </td>
            </tr>
          </tbody>
        </table>
        {contact.clientUserName ? this.renderSentLoginMailSection() : null}
      </div>
    );
  };

  renderContent = () => {
    if (this.state.editing) {
      return this.renderEditForm();
    }
    return this.renderInformation();
  };

  openLink = (url) => {
    window.open(url);
  };

  renderSocialButtons = () => {
    if (this.state.editing) {
      return null;
    }
    var contact = this.props.contact;
    return (
      <span>
        {contact.facebook ? (
          <span>
            <Icon
              onClick={this.openLink.bind(this, contact.facebook)}
              role="button"
              glyph="icon-fontello-facebook"
            ></Icon>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        ) : null}
        {contact.twitter ? (
          <span>
            <Icon
              onClick={this.openLink.bind(this, contact.twitter)}
              role="button"
              glyph="icon-fontello-twitter"
            ></Icon>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        ) : null}
        {contact.linkedIn ? (
          <span>
            <Icon
              onClick={this.openLink.bind(this, contact.linkedIn)}
              role="button"
              glyph="icon-fontello-linkedin"
            ></Icon>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        ) : null}
        {contact.weChat ? (
          <span>
            <Icon
              onClick={this.openLink.bind(this, contact.weChat)}
              role="button"
              glyph="icon-fontello-chat-1"
            ></Icon>
          </span>
        ) : null}
      </span>
    );
  };

  renderCopyButton = () => {
    if (!this.state.editing) {
      return null;
    }
    return (
      <button
        disabled={this.state.showCopyCompletedMessage}
        className="btn Lex-button"
        onClick={this.onCopyContact}
      >
        <Icon glyph="icon-fontello-floppy-1" />
        &nbsp;&nbsp;
        <Conditional inline show={!this.state.showCopyCompletedMessage}>
          <AxoLocal entity="CopyContact1" defaultValue={"Kopier"} />
        </Conditional>
        <Conditional inline show={this.state.showCopyCompletedMessage}>
          <span className="axoblue">
            {" "}
            <AxoLocal entity="CopyContact1" defaultValue={"Kopier"} />
            &nbsp;&nbsp;&nbsp;
            <Icon glyph="icon-fontello-ok-3" />
          </span>
        </Conditional>
      </button>
    );
  };

  onCopyContact = async () => {
    let { createContact } = this.props;

    var newContact = Object.assign({}, this.props.contact);
    delete newContact.id;
    delete newContact.clientUserName;

    try {
      await createContact(newContact);
      document.activeElement.blur();
      this.setState({ showCopyCompletedMessage: true });
      this.timer = setTimeout(() => {
        this.setState({ showCopyCompletedMessage: false });
      }, 3000);
    } catch {}
  };

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  render() {
    let { contact, selectedEntries, onSelectEntry } = this.props;

    return (
      <div>
        <div className="data-card">
          <div style={{ padding: "0px" }}>
            <div
              className="gradient-baggrund"
              style={{ paddingLeft: "2px", paddingRight: "5px" }}
            >
              <Grid fluid style={{ paddingLeft: "15px" }}>
                <Row>
                  <Col
                    xs={12}
                    className="text-center"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <Flexbox justified alignCenter>
                      <FlexElement className="text-left">
                        <AxoCheckbox
                          checked={selectedEntries.has(contact.id)}
                          onChange={onSelectEntry.bind(this, contact.id)}
                        />
                      </FlexElement>
                      <FlexElement className="text-center">
                        {!!contact.userProfile ? (
                          <span
                            style={{
                              float: "left",
                              paddingTop: "5px",
                              paddingBottom: "2px",
                              paddingLeft: "25px",
                            }}
                          >
                            <img
                              alt=""
                              src={UserInfoService.getProfileImageSource(
                                contact.userProfile
                              )}
                              className="img-circle"
                              width="45"
                              height="45"
                            />
                          </span>
                        ) : null}
                        <div style={{ lineHeight: "50px" }}>
                          {contact.firstName} {contact.lastName}{" "}
                        </div>
                      </FlexElement>
                      <FlexElement>{/* Adds spacing */}</FlexElement>
                    </Flexbox>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div style={{ paddingLeft: "5px", paddingRight: "0px" }}>
              <Grid fluid style={{ padding: "5px" }}>
                <Row>
                  <Col xs={12}>{this.renderContent()}</Col>
                </Row>
              </Grid>
            </div>
            <div
              className="Lex-CardFooter"
              style={{ paddingLeft: "5px", paddingRight: "15px" }}
            >
              <Grid fluid>
                <Row>
                  <Col
                    xs={12}
                    style={{ paddingLeft: "0px", paddingRight: "9px" }}
                  >
                    <ButtonGroup justified>
                      <ButtonGroup className="text-center">
                        {this.renderSocialButtons()}
                        {this.renderCopyButton()}
                      </ButtonGroup>
                      <ButtonGroup>
                        {this.state.editing ? (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon glyph="icon-fontello-floppy-1" />
                            &nbsp;&nbsp;{" "}
                            <AxoLocal
                              key="Save"
                              entity="CaseGridViewcaseSave"
                              defaultValue={"Gem"}
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon glyph="icon-fontello-edit-3" />
                            &nbsp;&nbsp;
                            <AxoLocal
                              key="Edit"
                              entity="CaseGridViewedit"
                              defaultValue={"Rediger"}
                            />
                          </button>
                        )}
                      </ButtonGroup>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.onTrashContact}
                        >
                          <Icon glyph="icon-fontello-trash-1" />
                          &nbsp;&nbsp;{" "}
                          <AxoLocal
                            entity="CaseEditFormRemoveClient"
                            defaultValue={"Slet klient"}
                          />
                        </button>
                      </ButtonGroup>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ContactGridView extends React.PureComponent {
  //Props
  //contacts
  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateContactCols = (contacts) => {
    var cols = [[], [], []];
    var smallCols = [[], []];

    let {
      selectedEntries,
      onSelectEntry,
      userProfile,
      locale,
      createClientAccount,
      createContact,
    } = this.props;

    for (var i = 0; i < contacts.length; i++) {
      var contactCard = (
        <ContactCard
          key={contacts[i].id}
          contact={contacts[i]}
          onContactChange={this.onContactChange}
          createContact={createContact}
          createClientAccount={createClientAccount}
          onSendMail={this.onSendMail}
          selectedEntries={selectedEntries}
          onSelectEntry={onSelectEntry}
          userProfile={userProfile}
          locale={locale}
        />
      );

      cols[i % 3].push(contactCard);
      smallCols[i % 2].push(contactCard);
    }
    return [cols, smallCols];
  };

  renderContactGrid = (contacts) => {
    var cols = this.generateContactCols(contacts);
    var largeCols = cols[0];
    var smallCols = cols[1];
    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[0]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[1]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  onContactChange = (contact) => {
    let { updateContact } = this.props;

    updateContact(contact);
  };

  onSendMail = (email, event) => {
    event.preventDefault();
    event.stopPropagation();
    DataStore.setCachedMessage(MessageService.makeExternalMessage(email));
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
    // this.props.history.push(RoutingService.getPath('mailbox/sendExternal/' + email));
  };

  getShownItems = () => {
    return this.props.contacts.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    return (
      <Grid fluid style={{ minHeight: "800px", background: "#ffffff" }}>
        <Row>
          <Col xs={12} className="nopadding">
            {this.renderContactGrid(this.getShownItems())}
          </Col>
        </Row>
        {this.state.numberShown < this.props.contacts.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col
              xs={12}
              className="text-center"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2"
                style={{ width: "75%" }}
              >
                <AxoLocal
                  key="Contacts"
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(ContactGridView);
