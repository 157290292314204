import React from "react";
import Company from "./Company";

import { DataStore } from "../services/AxoServices";

import { Consumer } from "../utilities/LexUtilities";

export default class CompanyContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserProfile();
    DataStore.fetchGlobalSettings();
  }

  render() {
    return (
      <Consumer>
        {(store) => (
          <Company
            userProfile={store.userProfile}
            globalSettings={store.globalSettings}
          />
        )}
      </Consumer>
    );
  }
}
