import React from "react";
import PrintService from "../services/PrintService";
import Conditional from "../utilities/Conditional";
import moment from "moment";
import TimeEntryService from "../services/TimeEntryService";

import {
  Row,
  Col,
  Grid,
  Button,
  ButtonToolbar,
  PanelGroup,
  Panel,
} from "react-bootstrap";

import { NumberService } from "../services/AxoServices";

// import CardLogos from '../resources/images/dankort.png';
import Dankort from "../resources/images/onlyDankort.png";
import MasterCard from "../resources/images/MasterCard.jpg";
import Visa from "../resources/images/Visa.png";
// import AxolexDataBehandlerAftale from '../resources/files/AxolexDatabehandlerAftale.pdf'

import {
  AxoLocal,
  AccordionUtils,
  AxoCheckbox,
  LexButton,
  ConfirmModal,
} from "../utilities/LexUtilities";

export default class SubscriptionRegistrationView extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.invoice) {
      this.state = {
        invoice: Object.assign({}, props.invoice),
        acceptedConditions: false,
        acceptedPrivacyPolicy: false,
      };
    }

    this.state.isGeneratingPayment = false;
    this.state.showPaymentErrorMessage = false;
    this.state.showPaymentCompletedMessage = false;

    this.confirmModal = React.createRef();
  }

  onPrint = () => {
    if (typeof window !== "undefined") {
      PrintService.printElement(document.getElementById("print-invoice"));
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.invoice &&
      JSON.stringify(nextProps.invoice) !== JSON.stringify(this.props.invoice)
    ) {
      this.setState({ invoice: Object.assign({}, nextProps.invoice) });
    }
  }

  hasUnits = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.units > 0);
  };

  hasAConto = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.aConto > 0);
  };

  hasExpenses = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.expenses > 0);
  };

  getTotal = (timeEntries) => {
    return timeEntries
      .reduce((acc, timeEntry) => {
        return acc + TimeEntryService.getTotalValue(timeEntry);
      }, 0)
      .toFixed(2);
  };

  renderDueDateField = (invoice) => {
    return moment(invoice.dueDate).format("L");
  };

  onRegister = (invoiceId) => {
    this.setState({ isGeneratingPayment: true });
    this.props
      .onRegister(invoiceId)
      .then((result) => {
        if (!result) {
          this.showPaymentErrorMessage();
          return;
        }
        this.setState({
          isGeneratingPayment: false,
          showPaymentCompletedMessage: true,
        });
      })
      .catch(() => {
        this.showPaymentErrorMessage();
      });
  };

  showPaymentErrorMessage = () => {
    this.setState({
      isGeneratingPayment: false,
      showPaymentErrorMessage: true,
    });
    setTimeout(() => {
      this.setState({ showPaymentErrorMessage: false });
    }, 5000);
  };

  onCancel = () => {
    let { onCancel } = this.props;

    this.confirmModal.current.open(
      `Er du sikker på at du ønsker at afbryde abonnementet?
Foreningen vil ikke længere kunne opkræve betaling fra dit kort.
Hvis du tilmelder dig igen senere, vil systemet ikke kunne se hvad du tidligere har betalt.
Hvis du ønsker at opdatere dine betalingsoplysninger, så tryk på "Opdater betalingsoplysninger" i stedet.`,
      (result) => {
        if (result) {
          onCancel();
        }
      }
    );
  };

  renderPaymentButton = () => {
    let { acceptedConditions, acceptedPrivacyPolicy } = this.state;

    var { invoice, paymentDate } = this.props;

    var isGenerating = this.state.isGeneratingPayment;
    if (invoice.status === "Paid" || !!paymentDate) {
      //Paid or approved
      return (
        <React.Fragment>
          <LexButton
            // disabled={isGenerating}
            // disabled={true}
            style={{ fontSize: "17px" }}
            onClick={this.onRegister}
          >
            {/* <img alt=''  src={CardLogos} width='80' height='20' />&nbsp;&nbsp; */}
            <img alt="" src={Dankort} width="40" height="20" />
            &nbsp;&nbsp;
            <img alt="" src={Visa} width="40" height="20" />
            &nbsp;&nbsp;
            <img alt="" src={MasterCard} width="40" height="20" />
            &nbsp;&nbsp; Opdater betalingsoplysninger
            <Conditional inline show={isGenerating}>
              &nbsp;
              <img
                alt=""
                src="/imgs/app/loading.gif"
                className="img-circle"
                width="20"
                height="20"
              />
            </Conditional>
          </LexButton>
          <LexButton onClick={this.onCancel} style={{ fontSize: "17px" }}>
            Afmeld betalingsaftale
          </LexButton>
          <LexButton
            onClick={() => (window.location.href = "https://afdeling7.dk/")}
            style={{ fontSize: "17px" }}
          >
            Abonnement er oprettet >> Afslut
          </LexButton>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button
          disabled={
            !acceptedConditions || !acceptedPrivacyPolicy || isGenerating
          }
          className="Lex-button-2"
          onClick={this.onRegister}
          style={{ fontSize: "17px" }}
        >
          {/* <img alt=''  src={CardLogos} width='80' height='20' />&nbsp;&nbsp; */}
          <img alt="" src={Dankort} width="40" height="20" />
          &nbsp;&nbsp;
          <img alt="" src={Visa} width="40" height="20" />
          &nbsp;&nbsp;
          <img alt="" src={MasterCard} width="40" height="20" />
          &nbsp;&nbsp; Tilmeld abonnement
          <Conditional inline show={isGenerating}>
            &nbsp;
            <img
              alt=""
              src="/imgs/app/loading.gif"
              className="img-circle"
              width="20"
              height="20"
            />
          </Conditional>
        </Button>
      </React.Fragment>
    );
  };

  renderPaymentButtonGroup = () => {
    if (!this.props.onRegister) {
      return null;
    }
    return <>{this.renderPaymentButton()}</>;
  };

  calculateInvoiceSubTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.units * timeEntry.rate;
    }, 0);
  };

  calculateExpensesSubTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.expenses;
    }, 0);
  };

  calculateTaxTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.units * timeEntry.rate * timeEntry.tax;
    }, 0);
  };

  calculateAContoTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.aConto;
    }, 0);
  };

  render() {
    let {
      invoice,
      acceptedConditions,
      acceptedPrivacyPolicy,
      showPaymentErrorMessage,
    } = this.state;

    var {
      paymentDate,
      paymentSubscriptionTerms,
      paymentPrivacyPolicy,
      client,
    } = this.props;

    let { userProfile } = invoice;

    let paid = invoice.status === "Paid" || !!paymentDate;

    let description =
      invoice.timeEntries && invoice.timeEntries.length > 0
        ? invoice.timeEntries[0].description
        : "";

    return (
      <Row
        id="print-invoice"
        ref={(r) => (this.invoiceNode = r)}
        style={{ margin: "auto", border: "2px solid #f2f2f2" }}
      >
        <h4 className="text-center">
          {client.firstName} {client.lastName}
        </h4>
        {!paid && (
          <h4 className="text-center">
            {client.address} {client.postalCode} {client.city}
          </h4>
        )}
        <Col xs={12}>
          <div>
            <div>
              <div>
                <Grid fluid></Grid>
              </div>
              <div>
                <hr style={{ marginTop: 0 }} />
                <Grid fluid>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <div>
                          <div>
                            <strong>Beløb:</strong> DKK{" "}
                            {NumberService.formatDecimal(
                              invoice.outstandingAmount
                            )}
                          </div>
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            {description}
                          </div>
                          {!paid ? (
                            <h4>Tilmelding og betaling sker til</h4>
                          ) : (
                            <h4>Du er tilmeldt automatisk betaling til</h4>
                          )}
                          <div>
                            <div>
                              {invoice.userCompanyOverride ||
                                userProfile.company}
                              &nbsp;-{" "}
                              {invoice.userAddressOverride ||
                                userProfile.address}
                              &nbsp;
                              {invoice.userPostalCodeOverride ||
                                userProfile.postalCode}
                              &nbsp;
                              {invoice.userCityOverride || userProfile.city}
                            </div>
                            {userProfile.companyCode ? (
                              <span>
                                <AxoLocal
                                  entity="SidebarRightContainerCompanyCode"
                                  defaultValue={"CVR"}
                                />
                                :&nbsp;
                                {invoice.userCompanyCodeOverride ||
                                  userProfile.companyCode}
                              </span>
                            ) : null}
                            {!!invoice.userPhoneOverride ||
                            !!userProfile.phone ? (
                              <div>
                                Tlf:{" "}
                                {invoice.userPhoneOverride || userProfile.phone}
                              </div>
                            ) : null}
                            <div>
                              Mail:{" "}
                              {invoice.userMailOverride || userProfile.eMail}
                            </div>
                          </div>
                          <h4>
                            Dette link tilhører {client.firstName}{" "}
                            {client.lastName}.
                          </h4>
                          {!!paid && (
                            <h4>
                              Gem dette link, da det skal anvendes til at
                              opdatere dine betalingsoplysninger senere.
                            </h4>
                          )}
                        </div>
                        {!paid ? (
                          <React.Fragment>
                            <PanelGroup
                              id="ITsubscriptionb"
                              accordion
                              className="Lex-Accordion bottomPadding"
                              defaultActiveKey="0"
                              style={{
                                borderRadius: "0px",
                                paddingTop: "0px",
                                borderBottom: "1px solid white",
                              }}
                            >
                              <Panel
                                className="backgroundlineargradient3"
                                eventKey="0"
                              >
                                <Panel.Heading>
                                  <Panel.Title toggle>
                                    {AccordionUtils.renderAccordionHeader(
                                      <span>
                                        <AxoLocal
                                          entity="updategetAccountName8"
                                          defaultValue="Privatlivspolitik"
                                        />
                                      </span>
                                    )}
                                  </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body
                                  collapsible
                                  className="LexAccordionPanelody"
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: paymentPrivacyPolicy,
                                    }}
                                  />
                                  {!paid && (
                                    <div>
                                      <AxoCheckbox
                                        checked={acceptedPrivacyPolicy}
                                        onChange={() =>
                                          this.setState((prevState) => ({
                                            acceptedPrivacyPolicy:
                                              !prevState.acceptedPrivacyPolicy,
                                          }))
                                        }
                                      />
                                      Jeg accepterer privatlivspolitikken.
                                    </div>
                                  )}
                                </Panel.Body>
                              </Panel>
                            </PanelGroup>
                            <PanelGroup
                              id="ITsubscriptionb"
                              accordion
                              className="Lex-Accordion bottomPadding"
                              defaultActiveKey="0"
                              style={{
                                borderRadius: "0px",
                                paddingTop: "0px",
                                borderBottom: "1px solid white",
                              }}
                            >
                              <Panel
                                className="backgroundlineargradient3"
                                eventKey="0"
                              >
                                <Panel.Heading>
                                  <Panel.Title toggle>
                                    {AccordionUtils.renderAccordionHeader(
                                      <span>Handelsbetingelser</span>
                                    )}
                                  </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body
                                  collapsible
                                  className="LexAccordionPanelody"
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: paymentSubscriptionTerms,
                                    }}
                                  />
                                  {!paid && (
                                    <div>
                                      <AxoCheckbox
                                        checked={acceptedConditions}
                                        onChange={() =>
                                          this.setState((prevState) => ({
                                            acceptedConditions:
                                              !prevState.acceptedConditions,
                                          }))
                                        }
                                      />
                                      Jeg accepterer handelsbetingelserne.
                                    </div>
                                  )}
                                </Panel.Body>
                              </Panel>
                            </PanelGroup>
                          </React.Fragment>
                        ) : null}
                        <div className="axoFrontPage text-center">
                          <ButtonToolbar className="topPadding bottomPadding inlineBlock">
                            {this.renderPaymentButtonGroup()}
                          </ButtonToolbar>
                        </div>
                        {!paid &&
                          (!acceptedConditions || !acceptedPrivacyPolicy) && (
                            <div className="axored">
                              Du skal klikke på Privatlivspolitik og
                              Handelsbetingelser for at acceptere, før du kan
                              komme videre med tilmeldingen.
                            </div>
                          )}
                        <div>
                          Support:{" "}
                          <a href="mailto:support@axolex.com">
                            support@axolex.com
                          </a>
                        </div>
                        {showPaymentErrorMessage && (
                          <div className="axored">
                            Tilmeldingen kunne ikke gennemføres. Prøv igen
                            senere.
                          </div>
                        )}
                      </div>
                      <ConfirmModal ref={this.confirmModal} />
                      <br />
                    </Col>
                  </Row>
                </Grid>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
