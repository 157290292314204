import React from "react";
import SignupForm from "../marketing/Account/SignupForm";
import { AxoLocal } from "../utilities/LexUtilities";
export default class XLinkView extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="text-center" style={{ paddingTop: "25px" }}>
          <AxoLocal
            entity="axolexQRcode1c"
            defaultValue={
              "Du skal have en konto for at oprette AxoLink presentationer."
            }
          />
        </div>
        <SignupForm />
        {/* <AxoLocal entity='axolexQRcode15' defaultValue={'Axo-Link er Axolex eget presentationssystem.'}/>
          <Link to='/xlink'>
        <AxoLocal entity='axolexQRcode1' defaultValue={'Lav dine egne præsentationer her.'}/> </Link> */}
      </div>
    );
  }
}
