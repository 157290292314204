import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import DataActions from "../services/DataAccess/DataActions";
import BookingView from "../Booking/BookingView";
import ContactForm from "./ContactForm";
import getScrollOffSet from "./getScrollOffSet";
import { SmallOrSmaller, MediumOrLarger } from "../utilities/Responsive";

import { Row, Col, Grid, Button, Panel, PanelGroup } from "react-bootstrap";
import { AuthorizationService } from "../services/AxoServices";
import { Icon, AxoLocal } from "../utilities/LexUtilities";

function renderAccordionHeader(title, onClick) {
  return (
    <div onClick={onClick}>
      <Col xs={11} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

export default class Company extends React.PureComponent {
  componentDidMount() {
    var scrollId = DataStore.getScrollId();
    if (scrollId) {
      setTimeout(() => {
        var element = document.getElementById(scrollId);
        this.onScrollToElement(element);
        DataStore.setScrollId("");
      }, 500);
    }
  }

  onScrollToElement(element) {
    if (!element) {
      //Can happen if the user navigates away before the scroll is initiated
      return;
    }
    element.scrollIntoView();
    window.scrollBy(0, getScrollOffSet());
  }

  onAssignBookingUser = () => {
    let { globalSettings, userProfile } = this.props;
    var adminUser = globalSettings.mainAdminUser;
    let userName = userProfile.userName;

    if (adminUser === userName) {
      return;
    }
    return DataActions.createOrUpdateGlobalSetting({
      key: "mainAdminUser",
      value: userName,
    });
  };

  renderAssignBookingUserButton = () => {
    var userProfile = this.props.userProfile;
    var bookingUserName = this.props.globalSettings.mainAdminUser;
    let userType = AuthorizationService.getUserType(userProfile);

    if (userType !== "Admin") {
      //Admin
      return null;
    }
    var isCurrent = userProfile.userName === bookingUserName;
    return (
      <div>
        <Button
          onClick={this.onAssignBookingUser}
          disabled={isCurrent}
          className="Lex-button-2"
        >
          {isCurrent ? (
            <span>
              <AxoLocal
                entity="Companyaboutostaxt1A"
                defaultValue={"Tilknyttet denne konto"}
              />
            </span>
          ) : (
            <span>
              <AxoLocal
                entity="Companyaboutostaxt1A"
                defaultValue={"Tilknyt denne konto"}
              />
            </span>
          )}
        </Button>
        <br />
        <br />
      </div>
    );
  };

  render() {
    return (
      <div
        className="text-center"
        style={{ minHeight: "800px", paddingTop: "10px" }}
      >
        <Col
          id="about"
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", padding: "0px", marginTop: "25px" }}
        >
          <div className="frontPageaxolexdiv Bannecolor">
            <AxoLocal entity="Companyaboutostaxt0" defaultValue={"Om os"} />
          </div>
        </Col>

        <Col
          md={8}
          mdOffset={2}
          sm={10}
          smOffset={1}
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
        >
          <div className="Axolextextbox">
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt1"
                defaultValue={
                  "Axolex udvikler og sælger innovative juridiske management systemer og IT komponenter."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt2"
                defaultValue={
                  "Systemet indeholder mailsystem, internt socialt medieprogram, netværk, dokumenthåndtering, økonomi- og tidshåndtering, juridisk sagsbehandling, klient databehandling, bookingsystem, kalendersystem. online betalingssystem og meget mere."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt2A"
                defaultValue={
                  "Virksomheden kan trække  på 10 års udviklingserfaring, blandt inden for transportbranchen, forsikringsindustrien og med konfigurationssystemet. Nu vil vi gerne tage vores erfaring på på et nyt plan. Derfor præsenterer vi juridisk software udviklet i et samarbejde mellem softwareudviklere og jurister. Vi håber at I tager godt imod vores nye produkter."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt3"
                defaultValue={
                  "Vi leverer også IT-komponenter: Registreringssystem, betalingssystem, log ind system, påmindelsessystemer, kontrolpaneler, database systemer osv."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt4"
                defaultValue={
                  "Vi lægger vægt på at integrere både applikationer, sagsbehandlingsprocesser, optimering af serviceydelser, materialer, håndtering af vigtige data, udveksling af informationer og benyttelse af fælles ressourcer. Dette gælder ikke mindst etablering af faglig og tæt kontakt med kunderne. Derfor arbejder vi imod at etablere en one-stop-service hvor systemet både opfylder de grundlæggende behov og har mulighed for at opbygge et tæt fagligt samarbejde med kunderne."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt5"
                defaultValue={
                  "Vores mål er at tilbyde vores kunder tilfredsstillende IT-løsninger, både hvad angår normale faglige og specielle individuelle behov."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt6"
                defaultValue={
                  "Vi håber at du har samme interesser i forbindelse med udviklingen af din forretning, og vil vælge os som IT-udbyder."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt7"
                defaultValue={"Hilsen fra hele Axolex teamet."}
              />
            </div>
          </div>
        </Col>
        <Grid fluid>
          <Row>
            <MediumOrLarger>
              <Col
                md={12}
                className="nopadding"
                style={{ maxWidth: "100%", marginTop: "25px" }}
              >
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex5-1.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h2 className="item--title">
                            <AxoLocal
                              entity="Companyaboutostaxt11"
                              defaultValue={"Axolex"}
                            />
                          </h2>
                          <p className="item--text">
                            <AxoLocal
                              entity="Companyaboutostaxt12"
                              defaultValue={"Komplet juridisk IT-løsning"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </MediumOrLarger>

            <SmallOrSmaller>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex5-1-xs.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h5
                            className="item--title"
                            style={{ marginTop: "25px" }}
                          >
                            <AxoLocal
                              entity="Companyaboutostaxt11"
                              defaultValue={"Axolex"}
                            />
                          </h5>
                          <p className="item--text">
                            <AxoLocal
                              entity="Companyaboutostaxt12"
                              defaultValue={"Komplet juridisk IT-løsning"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </SmallOrSmaller>
          </Row>
        </Grid>

        <Col
          id="team"
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padong: "0px" }}
        >
          <div className="frontPageaxolexdiv Bannecolor">
            <AxoLocal entity="Companyaboutostaxt13" defaultValue={"Team"} />
          </div>
        </Col>

        <Col
          md={8}
          mdOffset={2}
          sm={10}
          smOffset={1}
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padong: "0px" }}
        >
          <div className="Axolextextbox marginRighttextbox">
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt14"
                defaultValue={
                  "Axolex består af jurister og systemudviklere, som i fællesskab udvikler juridisk software."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt15"
                defaultValue={
                  "I fremtiden planlægger vores juridiske team at opbygge fælles ressourcer med fagligt som kan benyttes af alle systemets brugere, både nationale og internationale."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt16"
                defaultValue={
                  "Vores IT-afdeling arbejder på højtryk med dagligt at forbedre vores systemer og udvikle nye funktioner i henhold til feedback fra vores kunder. "
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt17"
                defaultValue={
                  "Vi vil udvikle vores systemer i så mange sprog som muligt, og løbende tilføje nye sprog, sålede at både kunderne og de professionelle nemt og enkelt kan samarbejde på tværs af sprogbarrierer."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt18"
                defaultValue={
                  "Hvis du har lyst og interesse til at bidrage til enten det juridiske eller IT relaterede perspektiv, vil vi sætte høj pris på enhver feedback."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt18A"
                defaultValue={
                  "Venlig hilsen fra både IT-afdelingen og det juridiske hold."
                }
              />
            </div>
          </div>
          <div>
            <br />

            <PanelGroup
              id="Companyb"
              accordion
              className="Lex-Accordion"
              defaultActiveKey="0"
              style={{
                borderRadius: "0px",
                paddingTop: "0px",
                borderBottom: "1px solid white",
              }}
            >
              <Panel className="backgroundlineargradient3" eventKey="1">
                <Panel.Heading>
                  <Panel.Title toggle>
                    {renderAccordionHeader(
                      <span>
                        <AxoLocal
                          entity="Companyaboutostaxt25A"
                          defaultValue={"Kontakt Os"}
                        />
                      </span>
                    )}
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible className="LexAccordionPanelody">
                  <ContactForm
                    messageLabel={
                      <AxoLocal
                        key="Besked"
                        entity="ContactFormMessage"
                        defaultValue={"Besked"}
                      />
                    }
                    sendLabel={
                      <AxoLocal
                        entity="IndividualPackagetext180"
                        defaultValue={"Send besked"}
                      />
                    }
                  />
                </Panel.Body>
              </Panel>
            </PanelGroup>

            {/* <Accordion defaultActiveKey='0' className = 'Lex-Accordion '>
              <Panel 
                className='compact backgroundlineargradient3'
                header={renderAccordionHeader(<span><AxoLocal entity='Companyaboutostaxt25A' defaultValue={'Kontakt Os'}/></span>)} 
                eventKey="1">
                  <ContactForm
                    messageLabel={<AxoLocal key='Besked' entity='ContactFormMessage' defaultValue={'Besked'}/>}
                    sendLabel={<AxoLocal entity='IndividualPackagetext180' defaultValue={'Send besked'}/>} 
                  />
              </Panel>
            </Accordion> */}
            <br />
            <br />
          </div>
        </Col>

        <Grid fluid>
          <Row>
            <MediumOrLarger>
              <Col md={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex12-1.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h2 className="item--title">
                            <AxoLocal
                              entity="Companyaboutostaxt11"
                              defaultValue={"Axolex"}
                            />
                          </h2>
                          <p className="item--text">
                            <AxoLocal
                              entity="Companyaboutostaxt12"
                              defaultValue={" Komplet juridisk IT-løsning"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </MediumOrLarger>

            <SmallOrSmaller>
              {" "}
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex12-xs.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h5 className="item--title">
                            <AxoLocal
                              entity="Companyaboutostaxt11"
                              defaultValue={"Axolex"}
                            />
                          </h5>
                          <p className="item--text">
                            <AxoLocal
                              entity="Companyaboutostaxt12"
                              defaultValue={" Komplet juridisk IT-løsning"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </SmallOrSmaller>
          </Row>
        </Grid>

        {/* <Col xs={12} className="nopadding" style={{maxWidth: '100%', marginTop: '25px', padong:'0px'}}>
         <a id='Sikkerhed' className="axosmAnchorOffSet2">.</a>
          <div className="frontPageaxolexdiv" >
            <AxoLocal entity='Companyaboutostaxt13' defaultValue={'Sikkerhed'}/>
          </div> 
        </Col>

        <Col md={8} mdOffset={2} sm={10} smOffset={1} xs={12} 
          className="nopadding" style={{maxWidth: '100%', marginTop: '25px',padong:'0px'}}>
        <div className='Axolextextbox marginRighttextbox'>
              <div className='Axolextextbox'>
                <AxoLocal entity='Companyaboutsystem' defaultValue={'Sikkerhed'}/>
              </div>
        </div>
        </Col> */}

        <Col
          id="career"
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padong: "0px" }}
        >
          <div className="frontPageaxolexdiv Bannecolor">
            <AxoLocal entity="Companyaboutostaxt19" defaultValue={"Karriere"} />
          </div>
        </Col>
        <Col
          md={8}
          mdOffset={2}
          sm={10}
          smOffset={1}
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padong: "0px" }}
        >
          <div className="Axolextextbox marginLefttextbox">
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt20"
                defaultValue={
                  "Vi leder løbende efter studerende inden for IT- jura og markedsføring i alle lande, som gerne vil udvikle deres faglige kompetencer og har lyst til at arbejde med fuldstændig frie hænder."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt21"
                defaultValue={
                  " Du skal som minimum være 2. års studerende på universitetet og have lyst til at etablere et fagligt netværk, samt arbejde på tværs af internationale grænser. Hvis du synes godt om vores perspektiv, ser vi frem til at høre fra dig."
                }
              />
            </div>
            <div className="Axolextextbox">
              <AxoLocal
                entity="Companyaboutostaxt22"
                defaultValue={
                  "Send en uforpligtende ansøgning. Vi forsøger at svare hurtigst muligt. Løn vil være efter studenteroverenskomst."
                }
              />
            </div>
          </div>
          <br />
          <div>
            <PanelGroup
              id="Companya"
              accordion
              className="Lex-Accordion"
              defaultActiveKey="0"
              style={{
                borderRadius: "0px",
                paddingTop: "0px",
                borderBottom: "1px solid white",
              }}
            >
              <Panel className="backgroundlineargradient3" eventKey="1">
                <Panel.Heading>
                  <Panel.Title toggle>
                    {renderAccordionHeader(
                      <span>
                        <AxoLocal
                          entity="ContactFormApply"
                          defaultValue={"Ansøg"}
                        />
                      </span>
                    )}
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible className="LexAccordionPanelody">
                  <ContactForm
                    messageLabel={
                      <AxoLocal
                        entity="ContactFormApplyMessage"
                        defaultValue={"Skriv ansøgning"}
                      />
                    }
                    sendLabel={
                      <AxoLocal
                        entity="ContactFormApply"
                        defaultValue={"Ansøg"}
                      />
                    }
                    attachFiles
                  />
                </Panel.Body>
              </Panel>
            </PanelGroup>

            {/* <Accordion defaultActiveKey='0' className = 'Lex-Accordion '>
            <Panel
              className='compact backgroundlineargradient3'
              header={renderAccordionHeader(<span><AxoLocal entity='ContactFormApply' defaultValue={'Ansøg'}/></span>)} 
              eventKey="1">
                <ContactForm
                  messageLabel={<AxoLocal entity='ContactFormApplyMessage' defaultValue={'Skriv ansøgning'}/>}
                  sendLabel={<AxoLocal entity='ContactFormApply' defaultValue={'Ansøg'}/>}
                  attachFiles
                />
            </Panel>
          </Accordion> */}
          </div>
          <br />
          <br />
        </Col>
        <Grid fluid>
          <Row>
            <MediumOrLarger>
              <Col md={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex3-lg.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h2 className="item--title">
                            <AxoLocal
                              entity="Companyaboutostaxt11"
                              defaultValue={"Axolex"}
                            />
                          </h2>
                          <p className="item--text">
                            <AxoLocal
                              entity="Companyaboutostaxt12"
                              defaultValue={" Komplet juridisk IT-løsning"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </MediumOrLarger>

            <SmallOrSmaller>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex3-xs.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h5 className="item--title">
                            <AxoLocal
                              entity="Companyaboutostaxt11"
                              defaultValue={"Axolex"}
                            />
                          </h5>
                          <p className="item--text">
                            <AxoLocal
                              entity="Companyaboutostaxt12"
                              defaultValue={" Komplet juridisk IT-løsning"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </SmallOrSmaller>
          </Row>
        </Grid>

        <Col
          id="aboutBooking"
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padong: "0px" }}
        >
          <div className="frontPageaxolexdiv Bannecolor">
            <AxoLocal entity="Companyaboutostaxt23" defaultValue={"Booking"} />
          </div>
        </Col>

        <Col
          md={8}
          mdOffset={2}
          sm={10}
          smOffset={1}
          xs={12}
          className="nopadding text-center"
          style={{ maxWidth: "100%", marginTop: "25px", padong: "0px" }}
        >
          <div className="text-center" style={{ fontSize: "15px" }}>
            <AxoLocal
              entity="Companyaboutostaxt24"
              defaultValue={
                "Kontakt os via digital post, eller book en tid til aftale hvis du ønsker at diskutere ting personligt. Vælg en dato for at se tider på denne dag."
              }
            />
          </div>
        </Col>
        <Col
          md={8}
          mdOffset={2}
          sm={10}
          smOffset={1}
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px" }}
        >
          <div className="flexbox-center">
            <BookingView
              receiverUserName={this.props.globalSettings.mainAdminUser || ""}
              bookingUserName=""
            />
          </div>
          {this.renderAssignBookingUserButton()}
        </Col>

        <Grid fluid>
          <Row>
            <MediumOrLarger>
              <Col md={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/12-1.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h2 className="item--title">
                            <AxoLocal
                              entity="Companyaboutostaxt11"
                              defaultValue={"Axolex"}
                            />
                          </h2>
                          <p className="item--text">
                            <AxoLocal
                              entity="Companyaboutostaxt12"
                              defaultValue={" Komplet juridisk IT-løsning"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </MediumOrLarger>

            <SmallOrSmaller>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/12-1-xs.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h5 className="item--title">
                            <AxoLocal
                              entity="Companyaboutostaxt11"
                              defaultValue={"Axolex"}
                            />
                          </h5>
                          <p className="item--text">
                            <AxoLocal
                              entity="Companyaboutostaxt12"
                              defaultValue={" Komplet juridisk IT-løsning"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </SmallOrSmaller>
          </Row>
        </Grid>

        <Col
          id="contact"
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px" }}
        >
          <div className="frontPageaxolexdiv Bannecolor">
            <AxoLocal entity="Companyaboutostaxt25" defaultValue={"Kontakt"} />
          </div>
        </Col>
        <Col
          md={8}
          mdOffset={2}
          sm={10}
          smOffset={1}
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px" }}
        >
          <div
            style={{
              maxWidth: "100%",
              margin: "auto",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <ContactForm />
            {/* Kontaktformular */}
          </div>
        </Col>
      </div>
    );
  }
}
