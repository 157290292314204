import React, {
  useState,
  // useEffect
} from "react";

import { SmallOrLarger, ExtraSmall } from "../utilities/Responsive";

import singimg1 from "../resources/images/signupload.jpg";
import singimg2 from "../resources/images/noeglekortsbrev3.jpg";
import singimg3 from "../resources/images/Sign3.jpg";

import { DataStore, ApiService, ApiKeys } from "../services/AxoServices";

import {
  withRouter,
  MitIDView,
  Flexbox,
  AxoLocal,
  FlexElement,
  AxoFooter,
  Icon,
  Link,
  LexButton,
  getText,
} from "../utilities/LexUtilities";

import { SmallOrSmaller, MediumOrLarger } from "../utilities/Responsive";

import { PanelGroup, Panel, Col, Grid, Row } from "react-bootstrap";

function renderAccordionHeader(title) {
  return (
    <div className="text-center">
      <Col xs={11} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

const redirectByRole = (role) => {
  DataStore.resetStore();

  let subPath = "";
  if (role === "Lawyer" || role === "Accountant" || role === "Admin") {
    subPath = "/Dashboard";
  } else if (
    role === "Client" ||
    role === "AccountingClient" ||
    role === "SocietyMember" ||
    role === "EasyID"
  ) {
    subPath = "/ClientDashboard";
  } else if (role === "XLink") {
    subPath = "/Xlink";
  } else if (role === "Society") {
    subPath = "/Dashboard";
  }

  //Necessary to reset the Easy ID session
  window.location.href =
    window.location.protocol + "//" + window.location.host + subPath;
};

let selectedPlan, setSelectedPlan;

const handleLogin = (result) => {
  var planId = selectedPlan.planId;
  if (!!planId) {
    ApiService.getUserProfile().then((currentUserProfile) => {
      if (!currentUserProfile.planId) {
        currentUserProfile.planId = planId;
        activateSubscription(currentUserProfile, result.role);
      } else {
        redirectByRole(result.role);
      }
    });
  } else {
    redirectByRole(result.role);
  }
};

const activateSubscription = (userProfile, role) => {
    doActivateSubscription({
      paylikeTransactionId: null,
      paylikeCardId: null,
      userProfile,
      role,
    });

  // let plan = selectedPlan;
  // //Do not require credit card when signing up for free or trial plans
  // if (
  //   (plan.amount === 0 || plan.trialPeriod > 0) &&
  //   (plan.costPerUnit === 0 || plan.freeUnitsPerInterval > 0)
  // ) {
  //   doActivateSubscription({
  //     paylikeTransactionId: null,
  //     paylikeCardId: null,
  //     userProfile,
  //     role,
  //   });
  // } else {
  //   let paylike = window.Paylike(ApiKeys.payLikeApiKey);

  //   paylike.popup(
  //     {
  //       currency: plan.amount > 0 ? plan.currency.toUpperCase() : "",
  //       amount: plan.amount,
  //       title: plan.name,
  //       fields: [
  //         {
  //           name: "name",
  //           label: getText("axoAccounting7", "Navn"),
  //           type: "string",
  //           required: true,
  //           value: !!userProfile.firstName
  //             ? userProfile.firstName + " " + userProfile.lastName
  //             : "",
  //         },
  //         {
  //           name: "email",
  //           label: getText("socialSidebarContainerEmail", "Email"),
  //           type: "email",
  //           required: true,
  //           value: userProfile.eMail,
  //         },
  //       ],
  //       custom: {
  //         userName: userProfile.userName,
  //         subscriptionPlan: plan.planId + " - " + plan.name,
  //       },
  //     },
  //     (err, res) => {
  //       if (err) {
  //         redirectByRole(role);
  //         console.log(err);
  //         return;
  //       }

  //       doActivateSubscription({
  //         paylikeTransactionId: !!res.transaction ? res.transaction.id : null,
  //         paylikeCardId: !!res.card ? res.card.id : null,
  //         userProfile,
  //         role,
  //       });
  //     }
  //   );
  // }
};

const doActivateSubscription = async ({
  paylikeTransactionId,
  paylikeCardId,
  userProfile,
  role,
}) => {
  try {
    let response = await ApiService.activateSubscription({
      userProfile,
      paylikeTransactionId,
      paylikeCardId,
    });
    if(!!response.ok) {
      var responseData = await response.json();
      if(!!responseData.url) {
        location.href = responseData.url;
        return;
      }
    }

    redirectByRole(role);
  } catch (error) {
    console.log(error);
    redirectByRole(role);
  }
};

function EasyIdFrontPageView(props) {
  [selectedPlan, setSelectedPlan] = useState({ id: 0 });

  const InfoTexts = () => {
    return (
      <FlexElement flex={5} className="center-block  text-center">
        <div className="Padding50">
          <img
            className="img-circle topPadding Axoshadow"
            alt=""
            src={singimg1}
            width="120px"
            height="120px"
          />
        </div>
        <div className="">
          <h4>
            <AxoLocal
              entity="axoEntityidcode273"
              defaultValue="Log ind med MitID"
            />
          </h4>
        </div>
        <div className="Padding50">
          <img
            className="img-circle topPadding Axoshadow"
            alt=""
            src={singimg2}
            width="120px"
            height="120px"
          />
        </div>
        <div className="">
          <h4>
            {/* <Icon glyph='icon-fontello-direction'  fontSize='25px' height='25px' /> &nbsp; */}
            <AxoLocal
              entity="axoEntityidcode274"
              defaultValue="Upload dine dokumenter >> Send dokumenter til underskrift."
            />
          </h4>
        </div>
        <div className="Padding50">
          <img
            className="img-circle topPadding Axoshadow"
            alt=""
            src={singimg3}
            width="120px"
            height="120px"
          />
        </div>
        <div className="  ">
          <h4>
            <AxoLocal
              entity="axoEntityidcode275"
              defaultValue="Download det underskrivne dokument."
            />
          </h4>
        </div>
      </FlexElement>
    );
  };

  let { plan1, plan2, plan3 } = props;

  return (
    <div className="login SideHeight bgSignSide" style={{ paddingTop: "10px" }}>
      <div
        className="frontPageaxolexdiv Bannecolorlogin flexbox-center"
        style={{ height: "130px" }}
      >
        Axolex Digitalt Signatursystem &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-md"
          src="/imgs/app/Axologo3-30x30.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-md hidden-xs"
          src="/imgs/app/Axologo3-35x35.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        <img
          alt=""
          className="hidden-md hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <FlagMenu
              // className='Lex-button-2'
              userSettings={{}}
            /> */}
      </div>
      <div
        className="flexbox-right center-block"
        style={{ maxWidth: "700px", fontSize: "16px", height: "50px" }}
      >
        <div className="MenueasyID leftPadding rightPadding" role="button">
          <b>
            <Link to="/EasyID">Forside</Link>
          </b>
        </div>
        <div className="MenueasyID leftPadding rightPadding" role="button">
          <b>
            <Link to="/ValidateSignature">Validering</Link>
          </b>
        </div>
        <div className="MenueasyID leftPadding rightPadding" role="button">
          <b>
            <Link to="/Users/EasyID">Log ind</Link>
          </b>
        </div>
      </div>
      <div className="standardMaxWidth center-block bgSignCenter">
        <Flexbox
          collapseXS
          justifyCenter
          className="topPadding standardMaxWidth center-block"
        >
          <SmallOrLarger>
            <InfoTexts />
          </SmallOrLarger>
          {!!selectedPlan.id && (
            <FlexElement flex={5}>
              <div className="flexbox-center">
                <MitIDView loginUserAccount onLogin={handleLogin} />
              </div>
            </FlexElement>
          )}
          <ExtraSmall>
            <InfoTexts />
          </ExtraSmall>
        </Flexbox>
        <Flexbox collapseXS className="standardMaxWidth center-block">
          <FlexElement
            className="InfoTextsEasyIDBG1 flexbox flex-column"
            flex={1}
          >
            <Flexbox column>
              <div className="AxoPriseElement1 InfoTextsEasyID">
                <h3>0 kr. / måned</h3>
              </div>
              <div className="leftPadding InfoTextsEasyID">
                {" "}
                5,99 kr. / MitID signatur
              </div>
              <div className="leftPadding InfoTextsEasyID">
                {" "}
                5 gb opbevaringsplads
              </div>
              <div className="leftPadding InfoTextsEasyID">
                {" "}
                Fri email support
              </div>
              <div className="leftPadding InfoTextsEasyID">
                {" "}
                Fri SMS kode validering før dokumentet vises
              </div>
              <div className="leftPadding InfoTextsEasyID">
                {" "}
                Dokumentarkiveringssystem
              </div>
              <b>
                <div className="leftPadding">Tilvalg:</div>
              </b>
              <div className="leftPadding InfoTextsEasyID">
                {" "}
                Online mailsystem. 19 kr. / måned
              </div>
              <div className="leftPadding InfoTextsEasyID">
                {" "}
                Betalingssystem med opkrævninger. 49 kr. / måned.
              </div>
              <div className="leftPadding InfoTextsEasyID">
                {" "}
                Automatisk abonnementsbetalinger. 49 kr. / måned.
              </div>
              <div className="leftPadding InfoTextsEasyID">
                {" "}
                Integration med e-boks, Google Drive, Dropbox osv sker efter
                individuel aftale.
              </div>
              {!!plan1 && (
                <>
                  <FlexElement flexGrow={1}></FlexElement>
                  <div className="leftPadding rightPadding  InfoTextsEasyID">
                    <LexButton
                      style={{ width: "100%" }}
                      onClick={() => setSelectedPlan(plan1)}
                      disabled={selectedPlan.id === plan1.id}
                    >
                      {selectedPlan.id === plan1.id ? <>Valgt</> : <>Vælg</>}
                    </LexButton>
                  </div>
                </>
              )}
            </Flexbox>
          </FlexElement>
          <FlexElement
            className="InfoTextsEasyIDBG1 flexbox flex-column"
            flex={1}
          >
            <div className="AxoPriseElement1 InfoTextsEasyID">
              {" "}
              <h3>89 kr. / måned</h3>
            </div>
            <div className="leftPadding InfoTextsEasyID">
              10 gratis underskrifter / måned
            </div>
            <div className="leftPadding InfoTextsEasyID">
              3,99 kr. / MitID signatur
            </div>
            <div className="leftPadding InfoTextsEasyID">
              50 gb opbevaringsplads
            </div>
            <div className="leftPadding InfoTextsEasyID">Fri email support</div>
            <div className="leftPadding InfoTextsEasyID">
              Fri SMS kode validering før dokumentet vises
            </div>
            <div className="leftPadding InfoTextsEasyID">
              Integration med e-boks, Google Drive, Dropbox osv sker efter
              individuel aftale.
            </div>
            {!!plan2 && (
              <>
                <FlexElement flexGrow={1}></FlexElement>
                <div className="leftPadding rightPadding  InfoTextsEasyID">
                  <LexButton
                    style={{ width: "100%" }}
                    onClick={() => setSelectedPlan(plan2)}
                    disabled={selectedPlan.id === plan2.id}
                  >
                    {selectedPlan.id === plan2.id ? <>Valgt</> : <>Vælg</>}
                  </LexButton>
                </div>
              </>
            )}
          </FlexElement>
          <FlexElement
            className="InfoTextsEasyIDBG1 flexbox flex-column"
            flex={1}
          >
            <div className="AxoPriseElement1 InfoTextsEasyID">
              {" "}
              <h3>2995 kr. / måned</h3>
            </div>
            <div className="leftPadding InfoTextsEasyID">
              3 kr. / MitID signatur
            </div>
            <div className="leftPadding InfoTextsEasyID">
              100 gb opbevaringsplads
            </div>
            <div className="leftPadding InfoTextsEasyID">Fri email support</div>
            <div className="leftPadding InfoTextsEasyID">
              Fri SMS kode validering før dokumentet vises
            </div>
            <div className="leftPadding InfoTextsEasyID">
              Dokumentarkiveringssystem
            </div>
            <div className="leftPadding InfoTextsEasyID">
              Online mailsystem.
            </div>
            <div className="leftPadding InfoTextsEasyID">
              Betalingssystem med opkrævninger. 49 kr. / måned.
            </div>
            <div className="leftPadding InfoTextsEasyID">
              Automatisk abonnementsbetalinger. 49 kr. / måned.
            </div>
            <div className="leftPadding InfoTextsEasyID">
              Integration med e-boks, Google Drive, Dropbox osv sker efter
              individuel aftale.
            </div>
            {!!plan3 && (
              <>
                <FlexElement flexGrow={1}></FlexElement>
                <div className="leftPadding rightPadding  InfoTextsEasyID">
                  <LexButton
                    style={{ width: "100%" }}
                    onClick={() => setSelectedPlan(plan3)}
                    disabled={selectedPlan.id === plan3.id}
                  >
                    {selectedPlan.id === plan3.id ? <>Valgt</> : <>Vælg</>}
                  </LexButton>
                </div>
              </>
            )}
          </FlexElement>
        </Flexbox>
        <div className="standardMaxWidth paddingBottom center-block">
          <PanelGroup
            accordion
            className="Lex-Accordion Toggletitel"
            defaultActiveKey="0"
            // style={{ borderRadius: '0px',paddingTop:'0px',borderBottom:'1px solid white'}}
          >
            <Panel className="bottomPadding" eventKey="1">
              <Panel.Heading>
                <Panel.Title toggle>
                  {renderAccordionHeader(
                    <>Gyldighed ved Axolex digital signatur</>
                  )}
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible className="leftPadding">
                <div className="Axolextextbox">
                  Axolex digitale signatur lever op alle lovkrav hvilket gør den
                  sikre at anvende.
                </div>
                <div className="Axolextextbox">
                  Signerede dokumenter er sikre, effektive og i elektronisk
                  form. Dokumenterne opfylder alle krav som stilles til
                  gyldigheden af digitalt signerede dokumenter.
                </div>
                <div className="Axolextextbox">
                  Den elektroniske signatur er entydigt knyttet til
                  underskriveren, gør det muligt at identificere underskriveren,
                  og skabes med midler som kun underskriveren har kontrol over.
                  Dokumentet forsegles således at enhver efterfølgende ændring
                  kan opdages.
                </div>
                <div className="Axolextextbox">
                  Signeringscertifikatet er udstedt af Globalsign, som er en
                  globalt anerkendt certifikatudsteder.
                </div>
                <div className="Axolextextbox">
                  Behandlingen af persondataoplysninger opfylder alle de krav
                  der stilles af persondataloven.
                </div>
                <div className="Axolextextbox">
                  Det signerede Axolex dokument er forsynet med en avancerede
                  elektronisk signatur, der er baseret på et kvalificeret
                  certifikat, og som er fremstillet ved brug af et sikkert
                  signaturgenereringssystem.
                </div>
                <div className="Axolextextbox">
                  Axolex garanterer gyldigheden af de certifikater der anvendes
                  til at beskytte dokumentet og de indeholdte data.
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </Panel.Body>
            </Panel>
            <Panel className="bottomPadding" eventKey="2">
              <Panel.Heading>
                <Panel.Title toggle>
                  {renderAccordionHeader(
                    <>Hvordan foregår elektronisk signatur?</>
                  )}
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible className="leftPadding">
                <div className="Axolextextbox">
                  Elektronisk signering via Axolex systemet foregår ved at
                  dokumentet først uploades af brugeren. Herefter kan der
                  tilføjes underskrivere, som hver modtager et link til en
                  underskriftsside. Underskriften kan foretages med MitID eller
                  med en tegnet underskrift, alt efter brugerens valg.
                </div>
                <div className="Axolextextbox">
                  Brugeren kan valgfrit vælge at modtage en mail hver gang en
                  bruger har underskrevet dokumentet.
                </div>
                <div className="Axolextextbox">
                  Efter at alle underskrivere har underskrevet dokumentet
                  forsegles dette af Axolex med et godkendt elektronisk
                  certifikat, således at der ikke kan ændres i dokumentet
                  efterfølgende. Den kryptografiske information om MitID
                  underskrifterne indlejres i dokumentet før forseglingen.
                  Ydermere, gemmes information om underskrivernes navn og IP
                  adresse, for at gøre det nemmere at validere underskrifternes
                  gyldighed.
                </div>
                <div className="Axolextextbox">
                  <Link to="/ValidateSignature">
                    Det færdige dokument kan valideres via Axolex systemet
                  </Link>
                  . Den indlejrede MitID signaturdata er juridisk gyldig I sig
                  selv og kan valideres af en hvilken som helst MitID
                  tjenesteudbyder.
                </div>
              </Panel.Body>
            </Panel>
          </PanelGroup>
          {/* <PanelGroup accordion className='Lex-Accordion Toggletitel' defaultActiveKey='0' >
          <Panel className='bottomPadding' eventKey="1">         
            <Panel.Heading>
              <Panel.Title toggle>
                {renderAccordionHeader(<>Titel.</>)}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className='leftPadding' > 
              Indhold
            </Panel.Body>
          </Panel>
        </PanelGroup>  */}
        </div>
        <div>
          <div className="marginonly"></div>
          <div className="marginonly"></div>
          <div className="marginonly"></div>
          <div className="marginonly"></div>
          <div></div>
          <AxoFooter />
        </div>
      </div>

      <div>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row>
            <Col sm={10} smOffset={1} xs={12} className="text-center nopadding">
              <Col sm={4} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px", marginLeft: "10px" }}
                >
                  <div>
                    <h3>
                      <AxoLocal
                        entity="FrontPageindextaxt11"
                        defaultValue={"Find os på"}
                      />
                    </h3>
                  </div>
                  <div>
                    <a
                      href="https://www.facebook.com/Axolex.dk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-facebook-squared"
                        style={{ fontSize: " 30px" }}
                      />{" "}
                      <AxoLocal
                        entity="ContactCreateViewFacebook"
                        defaultValue={"Facebook"}
                      />
                    </a>
                  </div>
                  {/* <div><span><Icon className='axolexwebsideicon' glyph='icon-fontello-linkedin-squared' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactCreateViewLinkedIn'defaultValue={'LinkedIn'}/></span></div> */}
                  <div>
                    {" "}
                    <a
                      href="https://twitter.com/AxolexApS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-twitter-squared"
                        style={{ fontSize: " 30px" }}
                      />{" "}
                      <AxoLocal
                        entity="ContactCreateViewTwitter"
                        defaultValue={"Twitter"}
                      />{" "}
                    </a>
                  </div>
                  {/* <div> <span><Icon className='axolexwebsideicon' glyph='icon-fontello-chat-1' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactWeChat'defaultValue={'WeChat'}/> </span></div> */}
                  <div style={{ fontSize: "22px" }}>© &ensp;&ensp;&ensp;</div>
                  <div className="text-left " role="button">
                    <div>
                      <img
                        alt=""
                        height="200px"
                        width="200px"
                        src="/svg/Axologo1-014.png"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={3} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="FrontPageviewtext11"
                        defaultValue={"Vi tilbyder"}
                      />{" "}
                    </h3>
                  </div>
                  <div>MitID signering</div>
                  <div>Systemintegration</div>
                  <div>PAdES PDF generering</div>
                  <div>Tegnet signatur</div>
                  <div>
                    <Link to="/ValidateSignature">Validering</Link>
                  </div>
                </div>
              </Col>
              <Col sm={3} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="FrontPageviewtext12"
                        defaultValue={"Du kan vælge"}
                      />
                    </h3>
                  </div>
                  <div>Konto hos Axolex</div>
                  <div>Systemintegration med jeres system.</div>
                  <div>Fleksible priser.</div>
                  <div>Abonnementsløsning.</div>
                </div>
              </Col>
              <Col sm={2} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="Companyaboutostaxt11"
                        defaultValue={"Axolex ApS"}
                      />
                    </h3>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
                    :
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    Strandvejen 60
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    2900 Hellerup
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    Denmark
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    CVR: 39076969
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />
                    :53780153
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <AxoLocal
                      entity="CaseEditFormeMail"
                      defaultValue={"Email"}
                    />
                    :{" "}
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:info@axolex.com">info@axolex.com</a>{" "}
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:it@axolex.com">it@axolex.com</a>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:legal@axolex.com">legal@axolex.com</a>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:support@axolex.com">support@axolex.com</a>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:job@axolex.com">job@axolex.com</a>{" "}
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>

        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="image-banner">
                <div className="banner-container">
                  <div className="banner-item">
                    <MediumOrLarger>
                      <img
                        alt=""
                        className="img-responsive bg_img"
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex3-lg.jpg"
                      />
                    </MediumOrLarger>
                    <SmallOrSmaller>
                      <img
                        alt=""
                        className="img-responsive bg_img"
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex3-xs.jpg"
                      />
                    </SmallOrSmaller>
                    <div className="text_wrapper">
                      <div
                        className="text_position chbannetaxt"
                        style={{ Color: "white" }}
                      >
                        <h2 className="item--title">
                          <AxoLocal
                            entity="Companyaboutostaxt11"
                            defaultValue={"Axolex"}
                          />
                        </h2>
                        <p className="item--text">
                          <AxoLocal
                            entity="Companyaboutostaxt12"
                            defaultValue={" Komplet juridisk IT-løsning"}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
}

export default withRouter(EasyIdFrontPageView);
