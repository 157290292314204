import React from "react";
import ExternalMailSetupView from "./ExternalMailSetupView";
import RoutingService from "../../services/RoutingService";
import { withRouter } from "react-router-dom";
import { ApiService, DataStore, DataActions } from "../../services/AxoServices";

class ExternalMailSetupContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userProfile: {},
      attemptingConnection: false,
      connectionError: "",
    };
    if (!props.match.params.id) {
      this.state.account = this.makeEmailAccount();
    }
    this.name = "ExternalMailSetupContainer";
  }

  makeEmailAccount = () => {
    return {
      accountType: "Outlook",
      account: "",
      password: "",
      displayName: "",
      serverType: "IMAP",
      ingoingServer: "imap.gmail.com",
      ingoingConnectionType: "SSL",
      ingoingPort: 993,
      outgoingServer: "smtp.gmail.com",
      outgoingPort: 587,
      outgoingConnectionType: "TLS",
      outgoingRequireAuthentication: true,
      outgoingDifferentAuthentication: false,
      outgoingAccount: "",
      outgoingPassword: "",
    };
  };

  componentDidMount() {
    let { match } = this.props;
    DataStore.subscribe(this.name, (store) => {
      if (match.params.id) {
        var account = store.emailAccounts.find(
          (a) => a.id === parseInt(match.params.id, 10)
        );
        this.setState({ account });
      }
      this.setState({
        userProfile: store.userProfile,
      });
    });

    if (match.params.id) {
      DataStore.fetchEmailAccounts();
    }
    DataStore.initializeUserProfile();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  onUpdateAccount = (account) => {
    this.setState({ account });
  };

  applyAccountTypeSettings = (account) => {
    switch (account.accountType) {
      case "Gmail":
        account = this.applyGmailSettings(account);
        break;
      case "Outlook":
        account = this.applyOutlookSettings(account);
        break;
      case "Yahoo":
        account = this.applyYahooSettings(account);
        break;
      case "OneCom":
        account = this.applyOneComSettings(account);
        break;
      default:
    }
    return account;
  };

  applyGmailSettings = (account) => {
    account.serverType = "IMAP";
    account.ingoingServer = "imap.gmail.com";
    account.ingoingConnectionType = "SSL";
    account.ingoingPort = 993;

    account.outgoingServer = "smtp.gmail.com";
    account.outgoingPort = 587;
    account.outgoingConnectionType = "TLS";
    account.outgoingRequireAuthentication = true;

    return account;
  };

  applyOutlookSettings = (account) => {
    account.serverType = "IMAP";
    account.ingoingServer = "imap-mail.outlook.com";
    account.ingoingConnectionType = "SSL";
    account.ingoingPort = 993;

    account.outgoingServer = "smtp-mail.outlook.com";
    account.outgoingPort = 587;
    account.outgoingConnectionType = "TLS";
    account.outgoingRequireAuthentication = true;

    return account;
  };

  applyYahooSettings = (account) => {
    account.serverType = "IMAP";
    account.ingoingServer = "imap.mail.yahoo.com";
    account.ingoingConnectionType = "SSL";
    account.ingoingPort = 993;

    account.outgoingServer = "smtp.mail.yahoo.com";
    account.outgoingPort = 587;
    account.outgoingConnectionType = "TLS";
    account.outgoingRequireAuthentication = true;

    return account;
  };

  applyOneComSettings = (account) => {
    account.serverType = "IMAP";
    account.ingoingServer = "imap.one.com";
    account.ingoingConnectionType = "SSL";
    account.ingoingPort = 993;

    account.outgoingServer = "send.one.com";
    account.outgoingPort = 465;
    account.outgoingConnectionType = "SSL";
    account.outgoingRequireAuthentication = true;

    return account;
  };

  onConnect = () => {
    var account = Object.assign({}, this.state.account);
    account = this.applyAccountTypeSettings(account);

    this.setState({ attemptingConnection: true });

    if (account.id) {
      DataActions.updateEmailAccount(account)
        .then(this.handleUpdateResponse)
        .then(this.handleMailResponse)
        .catch(this.handleError);
    } else {
      DataActions.createEmailAccount(account)
        .then(this.handleCreateResponse)
        .then(this.handleCreatedAccount)
        .then(this.handleMailResponse)
        .catch(this.handleError);
    }
  };

  onCancel = () => {
    this.props.history.goBack();
  };

  handleCreateResponse = (response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject("Account settings could not be created.");
  };

  handleCreatedAccount = (account) => {
    var promise = new Promise((resolve, reject) => {
      this.setState({ account }, () => {
        resolve(ApiService.getEmailsFromAccount(account.id));
      });
    });
    return promise;
  };

  handleUpdateResponse = (response) => {
    if (response.ok) {
      return ApiService.getEmailsFromAccount(this.state.account.id);
    }
    return Promise.reject("Account settings could not be updated.");
  };

  handleMailResponse = (result) => {
    if (!result.ok) {
      result.text().then((reason) => {
        this.handleError(reason);
      });
      return;
    }

    DataStore.updateEmailListeners();
    DataStore.fetchEmailsFromAllAccounts();

    this.props.history.push(RoutingService.getPath("mailbox/inbox"));
  };

  handleError = (reason) => {
    console.log(reason);
    this.setState({
      attemptingConnection: false,
      connectionError: reason.toString(),
    });
    setTimeout(() => {
      this.setState({ connectionError: "" });
    }, 10000);
  };

  render() {
    if (!this.state.account) {
      return null;
    }
    return (
      <ExternalMailSetupView
        account={this.state.account}
        userProfile={this.state.userProfile}
        onUpdateAccount={this.onUpdateAccount}
        onConnect={this.onConnect}
        onCancel={this.onCancel}
        attemptingConnection={this.state.attemptingConnection}
        connectionError={this.state.connectionError}
      />
    );
  }
}

export default withRouter(ExternalMailSetupContainer);
