import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import FileInfoService from "../../services/FileInfoService";

import { Large, MediumOrSmaller } from "../../utilities/Responsive";

import {
  Row,
  Col,
  Grid,
  FormControl,
  ControlLabel,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { Icon, FileViewerModal, AxoLocal } from "../../utilities/LexUtilities";

class FileCard extends React.PureComponent {
  onDeleteFile = () => {
    this.props.onDelete(this.props.file);
  };

  onClickFile = () => {
    let { caseModel, file } = this.props;

    let index = caseModel.documents.findIndex((f) => f.id === file.id);
    if (index === -1) {
      return;
    }

    this.fileModal.open(caseModel.documents, {
      messageId: null,
      fileIndex: index,
    });
  };

  renderFileName = () => {
    var file = this.props.file;
    var userProfile = this.props.userProfile;

    var nameString = file.fileName;
    nameString =
      nameString.length > 50 ? nameString.substring(0, 50) + "..." : nameString;

    if (userProfile.profileImage && userProfile.profileImage.id === file.id) {
      return (
        <span>
          {nameString}{" "}
          <AxoLocal
            entity="socialFilesViewProfileImage"
            defaultValue={"(Profilbillede)"}
          />
        </span>
      );
    }
    if (userProfile.banner && userProfile.banner.id === file.id) {
      return (
        <span>
          {nameString}{" "}
          <AxoLocal entity="socialFilesViewBanner" defaultValue={"(Banner)"} />
        </span>
      );
    }
    return <span>{nameString}</span>;
  };

  renderContent = () => {
    var file = this.props.file;
    if (FileInfoService.isImage(file)) {
      return (
        <div style={{ height: "200px" }}>
          <div className="editable" role="button" onClick={this.onClickFile}>
            <strong>{this.renderFileName()}</strong>
          </div>
          <div>
            <img
              alt=""
              role="button"
              onClick={this.onClickFile}
              style={{
                maxWidth: "100%",
                maxHeight: "150px",
                display: "block",
                margin: "auto",
              }}
              src={ApiService.getFileInlinePath(file.id)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="editable" role="button" onClick={this.onClickFile}>
          <strong>{this.renderFileName()}</strong>
        </div>
      );
    }
  };

  render() {
    let { caseModel, removeDocument, onRemoveFromCase, onEditFile } =
      this.props;

    return (
      <div
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "lightgrey",
          marginBottom: "2px",
          background: "white",
          boxShadow: "2px 2px 1px #E5EFFB",
        }}
      >
        <Grid fluid>
          <Row>
            <Col xs={12}>
              {this.renderContent()}
              <div className="text-right topPadding">
                <span role="button" onClick={removeDocument}>
                  <Icon
                    className="editable"
                    glyph="icon-fontello-trash-1 axored"
                  ></Icon>
                  &nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    className="axored"
                    entity="DocfileRemovefromthiscase"
                    defaultValue={"Fjern fra denne sag"}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                <span role="button" onClick={this.onDeleteFile}>
                  <Icon
                    className="editable"
                    glyph="icon-fontello-scissors axored"
                  ></Icon>
                  &nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    className="editable"
                    entity="socialFilesViewDeleteFile"
                    defaultValue={"Slet fil"}
                  />
                </span>
              </div>
            </Col>
          </Row>
        </Grid>
        <FileViewerModal
          size="large"
          ref={(c) => (this.fileModal = c)}
          selectedCase={caseModel}
          onEditFile={onEditFile}
          onRemoveFromCase={onRemoveFromCase}
        />
      </div>
    );
  }
}

export default class CaseFilesView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
  }

  generateContactCols = (files) => {
    let {
      caseModel,
      userProfile,
      onDelete,
      onRemoveFromCase,
      removeDocument,
      onEditFile,
    } = this.props;

    let cols = [[], [], []];
    let smallCols = [[], []];

    for (let i = 0; i < files.length; i++) {
      let fileCard = (
        <FileCard
          key={files[i].id}
          file={files[i]}
          caseModel={caseModel}
          userProfile={userProfile}
          onEditFile={onEditFile}
          onDelete={onDelete}
          onRemoveFromCase={onRemoveFromCase}
          removeDocument={() => removeDocument(files[i].id)}
        />
      );

      cols[i % 3].push(fileCard);
      smallCols[i % 2].push(fileCard);
    }
    return [cols, smallCols];
  };

  renderFilesGrid = (files) => {
    var cols = this.generateContactCols(files);
    var largeCols = cols[0];
    var smallCols = cols[1];
    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col lg={4} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {largeCols[0]}
            </Col>
            <Col lg={4} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {largeCols[1]}
            </Col>
            <Col lg={4} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col md={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col md={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  onSearch = (event) => {
    this.setState({ searchText: event.target.value });
  };

  getFiles = () => {
    var searchText = this.state.searchText;
    if (!searchText) {
      return this.props.files;
    }
    return this.props.files.filter((c) => {
      return c.fileName.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  render() {
    return (
      <Row>
        <Col xs={12}>
          <Grid fluid style={{ minHeight: "800px" }}>
            <Row style={{ paddingLeft: "20px" }}>
              <Col xs={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <div style={{ maxWidth: "600px", paddingBottom: "3px" }}>
                  <FormGroup controlId="inputWithIcon">
                    <ControlLabel>
                      <AxoLocal
                        entity="axoidcode260"
                        defaultValue={"Find dokument"}
                      />
                    </ControlLabel>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-picture-1" />
                      </InputGroup.Addon>
                      <AxoLocal
                        componentClass={FormControl}
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                        componentAttribute="placeholder"
                        entity="ContactTableViewplaceholderonSearch"
                      />

                      <FormControl.Feedback>
                        <Icon glyph="icon-fontello-search" />
                      </FormControl.Feedback>
                    </InputGroup>
                  </FormGroup>
                </div>
                {this.renderFilesGrid(this.getFiles())}
              </Col>
            </Row>
          </Grid>
        </Col>
      </Row>
    );
  }
}
