import React from "react";
import PropTypes from "prop-types";
import {
  DanishDataConsentTerms,
  EnglishDataConsentTerms,
  ChineseDataConsentTerms,
} from "./DataConsentTerms";
import { Panel, PanelGroup } from "react-bootstrap";

import {
  AccordionUtils,
  AxoCheckbox,
  Icon,
  AxoLocal,
} from "../../utilities/LexUtilities";

export default class DataConsentForm extends React.PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onCheckChanged: PropTypes.func,
    showWarning: PropTypes.bool,
    hideControls: PropTypes.bool,
    noMargin: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    let { locale, checked, onCheckChanged, showWarning, hideControls } =
      this.props;
    return (
      <div>
        <PanelGroup
          id="ITsubscriptionc"
          accordion
          className="Lex-Accordion"
          defaultActiveKey="0"
          style={{
            borderRadius: "0px",
            paddingTop: "0px",
            borderBottom: "1px solid white",
          }}
        >
          <Panel className="backgroundlineargradient3" eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                {AccordionUtils.renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-fontello-info-circled" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="updategetAccountName8"
                      defaultValue="Privatlivspolitik"
                    />
                    &nbsp;&nbsp;
                    <Icon glyph="icon-fontello-doc-text-2" />
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <div className="text-left">
                {locale === "enUS" ? <EnglishDataConsentTerms /> : null}
                {locale === "da" ? <DanishDataConsentTerms /> : null}
                {locale === "ch" ? <ChineseDataConsentTerms /> : null}
              </div>
            </Panel.Body>
          </Panel>
        </PanelGroup>
        {/* <Accordion className={ className || 'Lex-Accordion' } defaultActiveKey='0' style={{ marginTop: noMargin ? '0px' : '5px'}}>
          <Panel header={AccordionUtils.renderAccordionHeader(<span>
            <Icon  glyph="icon-fontello-info-circled" />&nbsp;&nbsp; 
            <AxoLocal entity='updategetAccountName8' defaultValue='Privatlivspolitik'/>
            &nbsp;&nbsp; 
            <Icon  glyph="icon-fontello-doc-text-2" />
            </span>)} eventKey="1">
            <div className='text-left'>
              { locale === 'enUS' ? (
                <EnglishDataConsentTerms />
              ) : null }
              { locale === 'da' ? (
                <DanishDataConsentTerms />
              ) : null }
              { locale === 'ch' ? (
                <ChineseDataConsentTerms />
              ) : null }
            </div>
          </Panel>
        </Accordion> */}
        {!hideControls ? (
          <React.Fragment>
            <div style={{ marginTop: "10px" }}>
              <AxoCheckbox checked={checked} onChange={onCheckChanged} />
              <AxoLocal
                entity="updategetAccountName9"
                defaultValue="Jeg har læst og forstået privatlivspolitikken og giver hermed samtykke til at Axolex ApS kan opbevare
              og behandle mine persondata i henhold til denne aftale."
              />
            </div>
            {showWarning ? (
              <div style={{ color: "red" }}>
                <AxoLocal
                  entity="updategetAccountName10"
                  defaultValue="Du skal acceptere privatlivspolitikken."
                />
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
