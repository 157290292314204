import React from "react";
import Weather from "./Weather";
import LocalWeather from "./LocalWeather";

export default class WeatherContainer extends React.PureComponent {
  weatherEnabled() {
    if (typeof Storage === "undefined") {
      return true;
    }
    return localStorage.getItem("weatherEnabled") === "true";
  }

  onEnableWeather = () => {
    localStorage.setItem("weatherEnabled", "true");
    this.setState({});
  };

  render() {
    if (this.weatherEnabled()) {
      return <Weather city={this.props.city} />;
    }
    return (
      <LocalWeather
        city={this.props.city}
        onEnableWeather={this.onEnableWeather}
      />
    );
  }
}
