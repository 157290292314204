import React from "react";
import moment from "moment";

export default class AxoTimelineElement extends React.PureComponent {
  render() {
    var { left, date, children } = this.props;

    var className = "timelinecontainer " + (left ? "left" : "right");
    return (
      <div className={className}>
        <div
          className="timelinecontent"
          style={{ fontWeight: "normal", fontSize: "15px" }}
        >
          {!!date ? (
            <div>
              <h4>
                {moment(date).format("dddd") + " - " + moment(date).format("L")}
              </h4>
              <div>
                <strong>{moment(date).format("HH:mm")}</strong>
              </div>
            </div>
          ) : null}
          <div>{children}</div>
        </div>
      </div>
    );
  }
}
