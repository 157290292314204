import React from "react";
import DataActions from "../services/DataAccess/DataActions";
import ApiService from "../services/DataAccess/ApiService";
import UserInfoService from "../services/UserInfoService";
import { ctx } from "../utilities/L20n/L20n";
import moment from "moment";

import { Grid, Row, Col, Button } from "react-bootstrap";

import {
  Icon,
  AxoTimeline,
  AxoTimelineElement,
  AxoLocal,
  withRTKMessageData,
} from "../utilities/LexUtilities";

class TimelineComponent extends React.PureComponent {
  onApproveBooking = (event) => {
    var bookingUser = event.eventBooking.bookingUserProfile;
    var bookingEmail = event.eventBooking.bookingEmail;
    event.eventBooking.confirmedBooking = true;
    event.title =
      ctx.getSync("BookingContainerconfirmedBookingMail") +
      " " +
      (bookingUser
        ? UserInfoService.getDisplayName(bookingUser)
        : bookingEmail);
    event.description = event.eventBooking.reason || event.description;

    DataActions.updateEventBooking(event.eventBooking).then((response) => {
      if (response.status !== 200) {
        return;
      }
      DataActions.updateCalendarEvent(event).then((response) => {
        if (response.status !== 200) {
          return;
        }
        if (bookingUser) {
          this.props.createMessage({
            sender: this.props.userProfile,
            receiver: bookingUser,
            subjectEntity: "BookingContainerconfirmedMailmessageapproved",
            subjectEntityDate: moment(event.start).format(),
            messageEntity: "BookingYourorderhasbeenapproved",
            messageEntityVariable: UserInfoService.getDisplayName(
              this.props.userProfile
            ),
            messageEntityDate: moment(event.start).format(),
            draft: false,
            attachments: [],
          });
        } else if (bookingEmail) {
          var subject =
            ctx.getSync("BookingContainerconfirmedMailsubject") +
            " " +
            moment(event.start).format("L LT") +
            " " +
            ctx.getSync("BookingContainerconfirmedMailmessageapproved");

          var messageText =
            "<p>" +
            ctx.getSync("BookingContainerconfirmedMailmessageText") +
            " " +
            UserInfoService.getDisplayName(this.props.userProfile) +
            " " +
            moment(event.start).format("L LT") +
            ". </p>" +
            "<p>" +
            ctx.getSync("BookingYourorderhasbeenapproved") +
            "</p>";

          ApiService.sendCompanyEmail({
            fromName: "Axolex ApS",
            to: bookingEmail,
            subject: subject,
            body: messageText,
          });
        }
      });
    });
  };

  onRejectBooking = (event) => {
    DataActions.deleteEventBooking(event.eventBooking.id).then((response) => {
      if (response.status === 200) {
        var bookingUser = event.eventBooking.bookingUserProfile;
        var bookingEmail = event.eventBooking.bookingEmail;
        if (bookingUser) {
          this.props.createMessage({
            sender: this.props.userProfile,
            receiver: bookingUser,
            subjectEntity: "BookingContainerconfirmedMailmessagerejected",
            subjectEntityDate: moment(event.start).format(),
            messageEntity: "BookingYourorderhasbeenrejected",
            messageEntityVariable: UserInfoService.getDisplayName(
              this.props.userProfile
            ),
            messageEntityDate: moment(event.start).format(),
            draft: false,
            attachments: [],
          });
        } else if (bookingEmail) {
          var subject =
            ctx.getSync("BookingContainerconfirmedMailsubject") +
            " " +
            moment(event.start).format("L LT") +
            " " +
            ctx.getSync("BookingContainerconfirmedMailmessagerejected");

          var messageText =
            "<p>" +
            ctx.getSync("BookingContainerconfirmedMailmessageText") +
            " " +
            UserInfoService.getDisplayName(this.props.userProfile) +
            " " +
            moment(event.start).format("L LT") +
            ". </p>" +
            "<p>" +
            ctx.getSync("BookingYourorderhasbeenrejected") +
            "</p>";

          ApiService.sendCompanyEmail({
            fromName: "Axolex ApS",
            to: bookingEmail,
            subject: subject,
            body: messageText,
          });
        }
      }
    });
  };

  renderBookingRequests = () => {
    var requests = this.props.events.map((event) => {
      return (
        <AxoTimelineElement
          key={event.id}
          date={event.eventBooking.bookingDate}
        >
          <div>
            <div>
              {event.eventBooking.bookingUserProfile
                ? UserInfoService.getDisplayName(
                    event.eventBooking.bookingUserProfile
                  )
                : event.eventBooking.bookingEmail}
            </div>
            <div>
              <small>
                <AxoLocal
                  entity="TimelineWillbooktime"
                  defaultValue={"Vil booke tid"}
                />
                &nbsp;&nbsp;
                {moment(event.start).format("L LT")}
              </small>
            </div>
            <div>
              <small>
                <Icon glyph=" icon-fontello-pencil-alt-1" />
                &nbsp;&nbsp;
                <b>
                  <AxoLocal
                    entity="CaseEditFormcasesubject"
                    defaultValue={"Emne"}
                  />
                  :
                </b>
                {event.eventBooking.reason}
              </small>
            </div>
          </div>
          <br />
          <div className="text-center">
            <Button
              className="Lex-button-2 "
              onClick={this.onApproveBooking.bind(this, event)}
            >
              <AxoLocal
                entity="friendRequestTimelineonApproveRequest"
                defaultValue={"Godkend"}
              />
            </Button>{" "}
            <Button
              className="Lex-button-2 "
              onClick={this.onRejectBooking.bind(this, event)}
            >
              <AxoLocal
                entity="friendRequestTimelineonRejectRequest"
                defaultValue={"Afvis"}
              />
            </Button>
          </div>
        </AxoTimelineElement>
      );
    });
    if (requests.length === 0) {
      requests.push(
        <AxoTimelineElement key={0} date={new Date()}>
          <AxoLocal
            entity="friendRequestTimelinerequests"
            defaultValue={"Ingen booking anmodninger"}
          />
        </AxoTimelineElement>
      );
    }
    return <AxoTimeline singleColumn>{requests}</AxoTimeline>;
  };
  render() {
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              {this.renderBookingRequests()}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRTKMessageData(TimelineComponent);
