import React from "react";
import MultiPaymentSection from "../routes/TimeEntries/MultiPaymentSection";
import PropTypes from "prop-types";

import {
  UserInfoService,
  DataStore,
  ApiService,
} from "../services/AxoServices";

import { LoadingIndicator, Flexbox, Consumer } from "../utilities/LexUtilities";

// import PolarHeader from '../resources/images/PolarHeader.jpg';
// import Polar from '../resources/images/Polar.jpg';

class PaymentStatusContainer extends React.Component {
  static propTypes = {
    invoices: PropTypes.array.isRequired,
    clientLabels: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      paymentRequests: [],
    };
    this.timer = null;
  }

  componentDidMount() {
    this.fetchPaymentRequests();

    this.timer = setInterval(this.fetchPaymentRequests, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps) {
    let { invoices } = this.props;

    if (invoices.length !== prevProps.invoices.length) {
      this.fetchPaymentRequests();
    }
  }

  fetchPaymentRequests = () => {
    let { invoices } = this.props;

    if (invoices.length > 0) {
      ApiService.getPaymentRequestsForInvoice(invoices[0].id).then(
        (paymentRequests) => {
          this.setState({ paymentRequests });
        }
      );
    }
  };

  deletePaymentRequest = (contactId, invoiceId) => {
    this.setState((prevState) => ({
      paymentRequests: prevState.paymentRequests.filter(
        (p) => p.contactInfoId !== contactId || p.invoiceId !== invoiceId
      ),
    }));
    ApiService.deletePaymentRequest(contactId, invoiceId)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Request could not be deleted.");
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.fetchPaymentRequests();
      });
  };

  render() {
    let { invoices, clientLabels, userProfile } = this.props;

    let { paymentRequests } = this.state;

    if (!userProfile.id || invoices.length === 0) {
      return <LoadingIndicator />;
    }
    return (
      <div style={{ minHeight: window.innerHeight, background: "#eaecec" }}>
        <div className="standardMaxWidth ">
          <Flexbox
            justified
            alignCenter
            className="text-center"
            style={{ fontSize: "18px", background: "#005380", color: "#fff" }}
          >
            <div className="text-left hidden-sm hidden-xs">
              {/* <img alt=''  src={Polar} /> */}
              <img
                height="90px"
                alt=""
                src={UserInfoService.getLogoImageSource(userProfile)}
              />
            </div>
            <div>{userProfile.company}</div>
            <div className="text-right hidden-sm hidden-xs">
              {/* <img  alt=''  src={Polar} /> */}
              <img
                height="90px"
                className="hidden-md"
                alt=""
                src={UserInfoService.getLogoImageSource(userProfile)}
              />
            </div>
            {/* <img className='hidden-xs' alt=''  src={Polar} />&nbsp;&nbsp;&nbsp; */}
            {/* <img className='hidden-xs' alt=''  src={Polar} /> */}
            {/* <img alt=''  src={PolarHeader} /> */}
          </Flexbox>
          <MultiPaymentSection
            invoice={invoices[0]}
            startOpen
            clientLabels={clientLabels}
            paymentRequests={paymentRequests}
            hideSendPanel
            deletePaymentRequest={this.deletePaymentRequest}
          />
        </div>
      </div>
    );
  }
}

export default class PaymentStatusConsumer extends React.Component {
  componentDidMount() {
    DataStore.fetchInvoices();
    DataStore.initializeLabels();
    DataStore.initializeUserProfile();
  }

  render() {
    return (
      <Consumer>
        {(store) => {
          let filteredLabels = store.labels.filter((l) => l.type === 3); //Clients
          let sortedLabels = filteredLabels.sort((l1, l2) =>
            l1.name.localeCompare(l2.name)
          );
          return (
            <PaymentStatusContainer
              invoices={store.invoices}
              clientLabels={sortedLabels}
              userProfile={store.userProfile}
            />
          );
        }}
      </Consumer>
    );
  }
}
