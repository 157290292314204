import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import { Row, Col, Image } from "react-bootstrap";
import { Icon } from "../../utilities/LexUtilities";

export default class ImageFooter extends React.PureComponent {
  //Props
  //image
  //onRemoveImage
  render() {
    var image = this.props.image;
    if (!image) {
      return null;
    }
    return (
      <Row>
        <Col sm={11}>
          <Image
            className="social-image"
            src={ApiService.getFileInlinePath(image.id)}
          />
        </Col>
        <Col sm={1}>
          <Icon
            role="button"
            onClick={this.props.onRemoveImage}
            glyph="icon-fontello-scissors axored"
            className="axored"
          />
        </Col>
      </Row>
    );
  }
}
