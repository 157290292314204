import React from "react";
import WeatherContainer from "../../utilities/Weather/WeatherContainer";
import { groupBy } from "lodash";
import moment from "moment";
import ScreenSharing from "./ScreenSharing";
import {
  QRCodeGenerator,
  AxoTimeline,
  AxoTimelineElement,
  AxoLocal,
} from "../../utilities/LexUtilities";

import LicenseConsentForm from "../../Login/Licenses/LicenseConsentForm";
import DataConsentForm from "../../Login/Licenses/DataConsentForm";

import { Grid, Row, Col } from "react-bootstrap";

export default class HomeView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      companyInfo: {
        vat: {},
      },
    };
  }

  renderTimeLineContent = (events) => {
    return events.map((event) => {
      return (
        <li key={event.id || event.deadlineCaseId || event.deadlineDocumentId}>
          <div>
            <strong>{moment(event.start).format("HH:mm")}</strong>
          </div>
          <div>{event.title}</div>
        </li>
      );
    });
  };

  renderTimeLineItems = (events) => {
    if (events.length === 0) {
      return (
        <AxoTimeline>
          <AxoTimelineElement left date={new Date()}>
            <AxoLocal
              entity="FrontpageTimeline"
              defaultValue="Du har ingen kommende aftaler"
            />
          </AxoTimelineElement>
          <AxoTimelineElement date={new Date()}>
            Du har ingen kommende frister.
          </AxoTimelineElement>
        </AxoTimeline>
      );
    }
    return (
      <AxoTimeline>
        {events.map((event, index) => {
          return (
            <AxoTimelineElement
              key={index}
              left={index % 2 === 0}
              date={event.start}
            >
              {event.title}
            </AxoTimelineElement>
          );
        })}
      </AxoTimeline>
    );
  };

  groupEventsByDay = () => {
    var events = this.props.events;
    var dayGroups = groupBy(events, (event) =>
      event.start.toLocaleDateString()
    );
    return dayGroups;
  };

  render() {
    var {
      userProfile,
      globalSettings,
      events,
      initializedEvents,
      initializedCases,
      initializedDocuments,
      locale,
    } = this.props;
    // var dayGroups = this.groupEventsByDay();
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            <WeatherContainer city="copenhagen" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            <ScreenSharing
              userProfile={userProfile}
              globalSettings={globalSettings}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            <div
              className="text-center"
              style={{
                fontWeight: "normal",
                fontSize: "21px",
                height: "50px",
                lineHeight: "50px",
              }}
            >
              <AxoLocal
                entity="GraphTimefontTimelineTitle"
                defaultValue="Kommende opgaver"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="nopadding">
            {events.length > 0 ||
            (initializedEvents && initializedCases && initializedDocuments) ? (
              <div style={{ borderBottom: "1px solid #1C4E80" }}>
                {this.renderTimeLineItems(events)}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="nopadding">
            {/* <Chart id='line-area-chart' /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <QRCodeGenerator
              header={
                <AxoLocal
                  entity="axolexQRcode12"
                  defaultValue={"QR generator"}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <LicenseConsentForm locale={locale} hideControls />
          </Col>
        </Row>
        <Row style={{ marginBottom: "50px" }}>
          <Col xs={12}>
            <DataConsentForm locale={locale} hideControls />
          </Col>
        </Row>
      </Grid>
    );
  }
}
