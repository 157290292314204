import React from "react";
import { AxoLocal, AxoFooter } from "../utilities/LexUtilities";
export default class AccessDenied extends React.PureComponent {
  render() {
    return (
      <div className="text-center SideHeight" style={{ paddingTop: "10px" }}>
        <h1>
          <AxoLocal entity="AccessDenied" defaultValue={"Adgang nægtet."} />
        </h1>
        <p>
          <AxoLocal
            entity="AccessDeniedExplanation"
            defaultValue={"Du har ikke tilladelse til at tilgå denne side."}
          />
        </p>
        <AxoFooter />
      </div>
    );
  }
}
