import React from "react";
// import ReactDOM from 'react-dom';
// import InvoiceV2 from './InvoiceV2'
import { Table, Column, Cell } from "fixed-data-table-2";
import InvoiceGridViewV2 from "./InvoiceGridViewV2";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";
import moment from "moment";

import ReactSelect from "react-select";

import { Grid, FormGroup, InputGroup, FormControl } from "react-bootstrap";

import { Table as BootstrapTable } from "react-bootstrap";

import {
  ApiService,
  DataActions,
  // ModalService,
  TimeEntryService,
  RoutingService,
  ModalService,
  ContactService,
  // AuthorizationService
} from "../../services/AxoServices";

import {
  Icon,
  SortHeaderCell,
  DataListWrapper,
  TableBase,
  Dimensions,
  AxoLocal,
  getText,
  LoadingIcon,
  Flexbox,
  FlexElement,
  ClientSearchBox,
  AxoDateTime,
  DateTimeModal,
  LexButton,
  FileViewerModal,
  AsyncButton,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  select: {
    width: 250,
    shown: true,
  },
  number: {
    width: 150,
    shown: true,
  },
  creationDate: {
    width: 150,
    shown: true,
  },
  dueDate: {
    width: 290,
    shown: true,
  },
  description: {
    width: 200,
    shown: true,
  },
  status: {
    width: 140,
    shown: true,
  },
  clientString: {
    width: 250,
    shown: true,
  },
  outstandingAmount: {
    width: 110,
    shown: true,
  },
  actions: {
    width: 125,
    shown: true,
  },
};

class InvoicesViewV2 extends TableBase {
  constructor(props) {
    super(props);
    this.name = "InvoicesView";
    this.defaultColumnDefs = defaultColumnDefs;

    let tableEntries = this.addDataStringsToInvoices(props.invoices);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(tableEntries);
    this.state = {
      selectedInvoices: [],
      tableEntries: tableEntries,
      invoiceSendingId: 0,
      sentInvoiceId: 0,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        dueDate: 180,
        status: 110,
        actions: 75,
      },
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      isGeneratingPDF: false,
      isGeneratingAndSending: false,
      showGeneratePDFError: false,
      generatedPDF: false,
      isFetchingHTML: false,
      creatingCreditNoteForId: 0,
      payingInvoiceId: 0,
      showAccountStatement: false,
    };

    this.dateTimeModal = React.createRef();
    this.fileModal = React.createRef();

    if (props.newInvoice.id) {
      this.state.highlightedInvoiceId = props.newInvoice.id;
      this.timeOut = setTimeout(() => {
        this.setState({ highlightedInvoiceId: 0 });
      }, 10000);
    }
  }

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.addDataStringsToInvoices(nextProps.invoices);
    let filteredEntries = this.getFilteredInvoices(
      tableEntries,
      this.state.searchText
    );

    //Sort handling
    this.updateEntries(tableEntries, filteredEntries);

    //Highlight new invoices for 10 seconds
    if (nextProps.newInvoice.id !== this.props.newInvoice.id) {
      this.setState({
        highlightedInvoiceId: nextProps.newInvoice.id,
      });
      this.timeOut = setTimeout(() => {
        this.setState({ highlightedInvoiceId: 0 });
      }, 10000);
    }
  }

  componentWillUnmount() {
    //TimeEntryOptionsStore.resetOptions();
    clearTimeout(this.timeOut);
  }

  addDataStringsToInvoices = (invoices) => {
    invoices.forEach((invoice) => {
      //client string is used for sorting
      invoice.clientString = invoice.client
        ? invoice.client.firstName
        : "No client";
    });
    return invoices;
  };

  onTrashInvoice = (invoiceId) => {
    var invoice = Object.assign(
      {},
      this.props.invoices.find((inv) => inv.id === invoiceId)
    );
    invoice.trashed = true;
    DataActions.updateInvoice(invoice);
  };

  onChangeDueDate = (invoiceId, event) => {
    var invoice = Object.assign(
      {},
      this.props.invoices.find((inv) => inv.id === invoiceId)
    );
    invoice.dueDate = moment(event.target.valueAsDate).format();
    invoice.sendReminder = true;
    invoice.reminderSent = false;
    DataActions.updateInvoice(invoice);
  };

  onSelectInvoice = (invoiceId, event) => {
    event.preventDefault();
    this.props.history.push(
      RoutingService.getPath("Invoices/Single/" + invoiceId)
    );
  };

  onStatusSelect = (invoiceId, event) => {
    let newStatus = event.target.value;

    let invoice = this.props.invoices.find((inv) => inv.id === invoiceId);
    if (newStatus === "Paid") {
      this.registerInvoicePayment(invoice);
    } else {
      DataActions.updateInvoice({
        ...invoice,
        status: newStatus,
      });
    }
  };

  registerInvoicePayment = (invoice) => {
    this.dateTimeModal.current.open(async (selectedPaymentDate) => {
      if (!selectedPaymentDate) {
        return;
      }

      this.setState({ payingInvoiceId: invoice.id})
      try {
        let response = await ApiService.generateInvoicePaymentPosting(
          invoice.id,
          selectedPaymentDate.format()
        );
        if (!response.ok) {
          ModalService.openAlertModal("Betaling kunne ikke registreres. Prøv igen senere.")
          return;
        }

        DataActions.updateInvoice({
          ...invoice,
          status: "Paid",
        });
      }
      catch {
        ModalService.openAlertModal("Betaling kunne ikke registreres. Prøv igen senere.")
      }
      finally {
        this.setState({ payingInvoiceId: 0})
      }
    });
  };

  renderStatusSelect = (invoice, statusLabels) => {
    return (
      <select
        className="axoblue selectbg"
        value={invoice.status}
        style={{ border: "none" }}
        onChange={this.onStatusSelect.bind(this, invoice.id)}
      >
        <option value="Created">{statusLabels.created}</option>
        <option value="Approved">{statusLabels.approved}</option>
        <option value="Sent">{statusLabels.submitted}</option>
        <option value="Seen">{statusLabels.seen}</option>
        <option value="Paid">{statusLabels.paid}</option>
        {/* <option value='Overdue'>{statusLabels.overdue}</option> */}
      </select>
    );
  };

  renderStatusText = (invoice, statusLabels) => {
    switch (invoice.status) {
      case "Created":
        return statusLabels.created;
      case "Approved":
        return statusLabels.approved;
      case "Sent":
        return statusLabels.submitted;
      case "Seen":
        return statusLabels.seen;
      case "Paid":
        return statusLabels.paid;
      // case 'Overdue':
      //   return statusLabels.overdue;
      default:
        return <span></span>;
    }
  };

  getStatusIcon = (status) => {
    switch (status) {
      case "Created":
        return (
          <div>
            <Icon
              style={{ color: "#428bca" }}
              glyph="icon-fontello-check-empty"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Approved":
        return (
          <div>
            <Icon style={{ color: "#306C67" }} glyph="icon-fontello-export-3" />
            &nbsp;&nbsp;
          </div>
        );
      case "Sent":
        return (
          <div>
            <Icon style={{ color: "#518F41" }} glyph="icon-fontello-check-1" />
            &nbsp;&nbsp;
          </div>
        );
      case "Seen":
        return (
          <div>
            <Icon
              style={{ color: "#D71F4B" }}
              glyph="icon-fontello-cancel-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Paid":
        return (
          <div>
            <Icon
              style={{ color: "#003BFF" }}
              glyph="icon-fontello-ok-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      default:
        return <div></div>;
    }
  };

  onSearch = (event) => {
    var invoices = this.getFilteredInvoices(
      this.state.tableEntries,
      event.target.value
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(invoices);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, invoices),
    });
  };

  getFilteredInvoices = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }
    return entries.filter((c) => {
      let total = this.calculateInvoiceTotal(c);
      let entryText = `${c.number} ${total} ${
        !!c.client ? `${c.client.firstName} ${c.client.lastName || ""}` : ""
      }`.toLocaleUpperCase();
      return entryText.includes(searchText.toLocaleUpperCase());
    });
    // return shownInvoices.filter( c => {
    //   return (c.invoiceNo).toLowerCase().includes(searchText.toLowerCase());
    // });
  };

  calculateInvoiceTotal = (invoice) => {
    return Math.round(
      invoice.timeEntries.reduce((sum, timeEntry) => {
        return sum + TimeEntryService.getTotalValue(timeEntry);
      }, 0)
    );
  };

  _rowClassNameGetter = (rowIndex) => {
    var activeInvoice = this.state.sortedDataList.getObjectAt(rowIndex);
    if (activeInvoice && activeInvoice.id === this.state.highlightedInvoiceId) {
      return "highlight-row";
    }
  };

  renderClientName = (client) => {
    return ContactService.getContactAccountingName(client);
  };

  checkSubscriptions = async () => {
    let response = await ApiService.checkSubscriptions();

    return response.ok;
  };

  renderInvoicesTable = () => {
    const {
      forceShowCards,
      selectedContact,
      myContactInfo, //Used on the client dashboard
      contactMap,
      handleSelectedClient,
      fiscalYears,
      selectedStartDate,
      onSelectStartDate,
      selectedEndDate,
      onSelectEndDate,
      selectedFiscalYear,
      onSelectFiscalYear,
      userSettings,
      invoices,
      clientPlan,
      userProfile,
    } = this.props;

    let { showAccountStatement } = this.state;

    let startDateSelection = selectedStartDate;
    let endDateSelection = selectedEndDate;

    if (!!selectedFiscalYear) {
      if (!startDateSelection) {
        startDateSelection = moment.utc(selectedFiscalYear.startDate);
      }

      if (!endDateSelection) {
        endDateSelection = moment.utc(selectedFiscalYear.endDate);
      }
    }

    let accountingClient = userSettings.accountingClient || myContactInfo;
    let AccountStatement = this.AccountStatement;
    
    return (
      <div>
        <div className="axobg" style={{ marginBottom: "5px" }}>
          <Grid fluid>
            <Flexbox
              responsive
              style={{ marginTop: "15px", paddingBottom: "10px" }}
            >
              <FlexElement className="onToppest rightPadding">
                <AxoLocal
                  componentClass={ClientSearchBox}
                  clearOnSelect
                  // startValue={!!selectedContact.id ? selectedContact.firstName + ' ' + (selectedContact.lastName || '') : '' }
                  startValue={ContactService.getContactAccountingName(
                    selectedContact
                  )}
                  clients={contactMap.contacts}
                  count={contactMap.count}
                  handleSelectedClient={handleSelectedClient}
                  disabled={false}
                  componentAttribute="placeholder"
                  entity="composeSelectClientPlaceholder1"
                />
              </FlexElement>
              {!!fiscalYears && !!accountingClient && !!selectedFiscalYear && (
                <FlexElement
                  flexBasis="150px"
                  className="onToppest rightPadding"
                >
                  <ReactSelect
                    name="fiscalYear"
                    menuPlacement="auto"
                    value={{
                      value: selectedFiscalYear.id.toString(),
                      label: selectedFiscalYear.name,
                    }}
                    onChange={(selection, arg) => {
                      onSelectFiscalYear(
                        !!selection && selection.value !== "0"
                          ? parseInt(selection.value, 10)
                          : null
                      );
                    }}
                    noOptionsMessage={() => ""}
                    options={[
                      {
                        value: "0",
                        label: getText("axoidcode100", "Vælg regnskabsår"),
                      },
                      ...fiscalYears.map((year) => {
                        return { value: year.id.toString(), label: year.name };
                      }),
                    ]}
                  />
                </FlexElement>
              )}
              <FlexElement flexBasis="175px" className="rightPadding">
                <AxoDateTime
                  utc
                  value={startDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectStartDate}
                  isValidDate={(currentDate) => {
                    return !currentDate.isAfter(endDateSelection);
                  }}
                />
              </FlexElement>
              <FlexElement flexBasis="175px" className="rightPadding">
                <AxoDateTime
                  utc
                  value={endDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectEndDate}
                  isValidDate={(currentDate) => {
                    return !currentDate.isBefore(startDateSelection);
                  }}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <div style={{ minWidth: "200px", maxWidth: "600px" }}>
                  <FormGroup controlId="inputWithIcon">
                    <InputGroup>
                      {/* Søg...  */}
                      <AxoLocal
                        componentClass={FormControl}
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                        componentAttribute="placeholder"
                        entity="ContactTableViewplaceholderonSearch"
                      />
                      <FormControl.Feedback>
                        <Icon glyph="icon-fontello-search" />
                      </FormControl.Feedback>
                    </InputGroup>
                  </FormGroup>
                </div>
              </FlexElement>
              {userProfile.userType === "Admin" && (
                <FlexElement>
                  <AsyncButton onClick={this.checkSubscriptions}>
                    Tjeck abonnementer
                  </AsyncButton>
                </FlexElement>
              )}
            </Flexbox>
            {/* <Row>
              <Col sm={4}>
                <h4><Icon glyph="icon-fontello-align-justify" />&nbsp;&nbsp; 
                <AxoLocal entity='InvoicePDFsViewinvoices'defaultValue={'Fakturaer'}/> </h4>
              </Col>
              <Col sm={4}>
                <div style={{maxWidth: '600px', paddingTop: '10px'}}>
                  <FormGroup controlId='inputWithIcon'>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph='icon-fontello-search' />
                      </InputGroup.Addon>
                      <AxoLocal componentClass={FormControl}  type='text' value={this.state.searchText} 
                        onChange={this.onSearch} 
                        componentAttribute="placeholder"
                        entity='ContactTableViewplaceholderonSearch'/>
                      <FormControl.Feedback>
                      <Icon glyph='icon-fontello-search' />
                      </FormControl.Feedback>
                    </InputGroup>
                  </FormGroup>
                </div>
              </Col>
              <Col sm={4} className='hidden-xs text-right' style={{ paddingTop: '10px'}}>
                {!!this.props.forceShowCards && (
                  <Button className='Lex-button-2' onClick={this.props.onToggleCards}>
                    { !!this.props.forceShowCards 
                      ? <span> 
                          <Icon glyph="icon-fontello-grid"/>&nbsp;&nbsp;
                          <AxoLocal key='showTable' entity='CaseDataTableFixedShowastable'defaultValue={'Vis som tabel'}/>
                        </span> 
                      : <span>
                          <Icon glyph="icon-fontello-th-thumb-empty"/>&nbsp;&nbsp;
                          <AxoLocal key='showCards' entity='CaseDataTableFixedShowCards'defaultValue={'Vis som kartotekskort'}/>
                        </span> 
                      }
                  </Button>
                )}
              </Col>
            </Row> */}
          </Grid>
          {!!selectedContact.id && (
            <Flexbox className="leftPadding">
              <FlexElement className="rightPadding largeText">
                {selectedContact.firstName +
                  " " +
                  (selectedContact.lastName || "")}
              </FlexElement>
              {!showAccountStatement && (
                <>
                  <FlexElement className="rightPadding largeText">
                    <AxoLocal entity="axoidcode157" defaultValue={"Saldo"} />:{" "}
                    {invoices
                      .filter((i) => i.status !== "Paid")
                      .reduce((acc, invoice) => {
                        return acc + this.calculateInvoiceTotal(invoice);
                      }, 0)}
                  </FlexElement>
                  <FlexElement className="rightPadding largeText">
                    <AxoLocal
                      key="paid"
                      entity="InvoiceInvoicePaid"
                      defaultValue={"Betalt"}
                    />
                    :{" "}
                    {invoices
                      .filter((i) => i.status === "Paid")
                      .reduce((acc, invoice) => {
                        return acc + this.calculateInvoiceTotal(invoice);
                      }, 0)}
                  </FlexElement>
                  <FlexElement className="rightPadding largeText">
                    <AxoLocal
                      entity="axoEntityidcode83"
                      defaultValue={"Forfalden"}
                    />
                    :{" "}
                    {invoices
                      .filter(
                        (i) =>
                          i.status !== "Paid" &&
                          moment.utc(i.paymentDate).isSameOrAfter(moment.utc())
                      )
                      .reduce((acc, invoice) => {
                        return acc + this.calculateInvoiceTotal(invoice);
                      }, 0)}
                  </FlexElement>
                </>
              )}
              {/* <FlexElement className='rightPadding largeText'>
              Faktureret: { invoices
                .reduce((acc, invoice)  => {
                return acc + this.calculateInvoiceTotal(invoice);
              }, 0) }
            </FlexElement> */}

              {!!clientPlan && (
                <FlexElement className="rightPadding">
                  <LexButton
                    onClick={() =>
                      this.setState((prevState) => ({
                        showAccountStatement: !prevState.showAccountStatement,
                      }))
                    }
                  >
                    {!showAccountStatement ? (
                      <>
                        <AxoLocal
                          entity="axoEntityidcode84"
                          defaultValue={"Vis kontoudtog"}
                        />
                      </>
                    ) : (
                      <>
                        <AxoLocal
                          entity="axoEntityidcode85"
                          defaultValue={"Vis fakturaer"}
                        />
                      </>
                    )}
                  </LexButton>
                </FlexElement>
              )}
              {!showAccountStatement && (
                <FlexElement className="rightPadding">
                  <LexButton onClick={() => handleSelectedClient({})}>
                    <AxoLocal
                      entity="axoEntityidcode75"
                      defaultValue={"Vis alle kunder"}
                    />
                  </LexButton>
                </FlexElement>
              )}
            </Flexbox>
          )}
        </div>
        {!showAccountStatement || !selectedContact.id ? (
          <>
            {forceShowCards ? (
              this.renderGridView()
            ) : (
              <div>
                <ExtraSmall>{this.renderGridView()}</ExtraSmall>
                <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
              </div>
            )}
          </>
        ) : (
          <AccountStatement />
        )}
      </div>
    );
  };

  AccountStatement = (props) => {
    let { invoices, clientPlan } = this.props;

    let postings = [];
    invoices.forEach((invoice) => {
      postings = postings.concat(
        invoice.postings.map((p) => ({
          ...p,
          invoice,
        }))
      );
    });

    postings = postings.sort((l, r) => {
      if (l.creationDate === r.creationDate) {
        return 0;
      }
      return moment.utc(l.creationDate).isBefore(moment.utc(r.creationDate))
        ? -1
        : 1;
    });

    let accountBalance = 0;
    postings = postings.map((posting) => {
      accountBalance +=
        posting.balanceFinanceAccountId !== clientPlan.customerBankAccountId
          ? posting.amount
          : -posting.amount;
      return {
        ...posting,
        accountBalance,
      };
    });

    return (
      <div className="leftPadding standardMaxWidth">
        <BootstrapTable bordered hover>
          <tbody>
            <tr>
              <th>
                <AxoLocal entity="axoidcode64" defaultValue={"Nummer"} />{" "}
              </th>
              <th>
                <AxoLocal
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
              </th>
              <th>
                <AxoLocal entity="axoEntityidcode86" defaultValue={"Forfald"} />
              </th>
              <th>
                <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
              </th>
              <th>
                <AxoLocal entity="InvoiceInvoicesum" defaultValue={"Beløb"} />
              </th>
              <th>
                <AxoLocal entity="axoidcode157" defaultValue={"Saldo"} />
              </th>
              <th>
                <AxoLocal
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
              </th>
            </tr>
            {postings.reverse().map((posting) => (
              <tr
                key={posting.id}
                role="button"
                onClick={this.onSelectInvoice.bind(this, posting.invoice.id)}
              >
                <td>{posting.receiptNumber}</td>
                <td>{moment(posting.creationDate).format("L")}</td>
                <td>{moment.utc(posting.invoice.paymentDate).format("L")}</td>
                <td>{posting.description}</td>
                <td>
                  {posting.balanceFinanceAccountId !==
                  clientPlan.customerBankAccountId
                    ? posting.amount
                    : -posting.amount}
                </td>
                <td>{posting.accountBalance}</td>
                <td>{posting.invoice.status === "Paid" ? "Betalt" : "Åben"}</td>
              </tr>
            ))}
          </tbody>
        </BootstrapTable>
      </div>
    );
  };

  renderGridView = () => {
    let { sortedDataList, highlightedInvoiceId } = this.state;

    return (
      <div>
        <InvoiceGridViewV2
          invoices={sortedDataList._data}
          highlightedInvoiceId={highlightedInvoiceId}
          onSelectInvoice={this.onSelectInvoice}
          onStatusSelect={this.onStatusSelect}
          onCreateAndSendPDF={this.props.onCreateAndSendPDF}
        />
      </div>
    );
  };

  onSendInvoiceLinkFromTable = (invoice) => {
    if (!invoice.client || !invoice.client.eMail) {
      return;
    }

    ModalService.openConfirmModal(
      `Send faktura til ${invoice.client.eMail}?`,
      (value) => {
        if (!value) {
          return;
        }

        this.setState({ invoiceSendingId: invoice.id });

        let messageSubject = !invoice.invoiceCredited
          ? getText("axoidcode43", "Faktura til betaling") +
            " #" +
            invoice.number
          : getText("axoidcode247", "Kreditnota for") +
            invoice.invoiceCredited.invoiceType +
            " " +
            invoice.invoiceCredited.number;
        let messageText = !invoice.invoiceCredited
          ? getText(
              "axoidcode44",
              "Du har modtaget en faktura. Klik her for at betale:"
            ) + " <PAYMENTLINK>"
          : getText(
              "axoidcode248",
              "Du har modtaget en kreditnota. Klik her for at se den :"
            ) + "<PAYMENTLINK>";

        ApiService.sendInvoiceToClients({
          clientIds: [invoice.client.id],
          invoiceId: invoice.id,
          showFullInvoice: true,
          resetPaymentRequest: invoice.status !== "Paid", //If the invoice is not marked as paid, reset the payment request
          messageSubject,
          messageText,
          // messageSubject: 'Medlemskontingent for Vinterbadelauget Bifrost',
          // messageText: 'Det er tid til at betale medlemskontingent for Vinterbadelauget Bifrost. Klik her for at betale:',
          messageUrl:
            window.location.protocol +
            "//" +
            window.location.host +
            "/InvoicePayment",
        })
          .then(() => {
            this.onEmailSuccess(invoice);
          })
          .catch((reason) => {
            console.log(reason);
            this.setState({ invoiceSendingId: 0 });
          });
      }
    );
  };

  onEmailSuccess = (invoice) => {
    DataActions.updateInvoice({
      ...invoice,
      status: "Sent",
    });

    this.setState({
      invoiceSendingId: 0,
      sentInvoiceId: invoice.id,
    });
    setTimeout(() => {
      this.setState({ sentInvoiceId: false });
    }, 3000);
  };

  onCreateCreditNote = async (invoice) => {
    let { id, number, creationDate, paymentDate, timeEntries, ...creditNote } =
      invoice;
    this.setState({ creatingCreditNoteForId: invoice.id });

    try {
      let numberInfo = await ApiService.getNextInvoiceNumber();

      creditNote.creationDate = moment().format();
      creditNote.timeEntries = timeEntries.map((t) => {
        let { id, invoiceId, ...newEntry } = t;
        return newEntry;
      });
      creditNote.paymentDate = creditNote.creationDate;
      creditNote.invoiceType = getText("axoidcode246", "Kreditnota") + "#";
      creditNote.number = numberInfo.next;
      creditNote.invoiceCreditedId = invoice.id;
      creditNote.invoiceCredited = invoice;
      creditNote.status = "Created";
      creditNote.message = "";

      let result = await this.props.onCreateInvoice(creditNote);
      if (!result) {
        this.setState({ creatingCreditNoteForId: 0 });
      }
    } catch (reason) {
      console.log(reason);
      this.setState({ creatingCreditNoteForId: 0 });
    }
  };

  renderTable = () => {
    var {
      sortedDataList,
      colSortDirs,
      columnDefs,
      invoiceSendingId,
      sentInvoiceId,
      creatingCreditNoteForId,
      payingInvoiceId
    } = this.state;

    const { containerHeight, containerWidth, userProfile } = this.props;
    var tableWidth = containerWidth - 25;

    let statusLabels = {
      created: getText("InvoiceGridViewcreated", "Created"),
      approved: getText("InvoiceGridViewapproved", "Approved"),
      submitted: getText("InvoiceGridViewSubmitted", "Sent"),

      seen: getText("axoidcode233", "Sendt og set"),
      paid: getText("InvoiceInvoicePaid", "Paid"),
      overdue: getText("InvoiceGridViewoverdue", "Overdue"),
    };

    return (
      <div style={{ paddingTop: "0px", paddingLeft: "20px" }}>
        <Table
          rowHeight={40}
          rowsCount={sortedDataList.getSize()}
          rowClassNameGetter={this._rowClassNameGetter}
          height={containerHeight}
          width={tableWidth}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          headerHeight={40}
        >
          <Column
            columnKey="number"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-sort-name-up" />}
                showAltIcon={columnDefs.number.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.number}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;#
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  <a
                    href="SelectInvoice"
                    onClick={this.onSelectInvoice.bind(this, invoice.id)}
                  >
                    {invoice.number.toString() || "0"}
                  </a>
                  {invoice.sharedWithClient > 0 && (
                    <span>
                      &ensp;
                      <AxoLocal
                        componentClass={Icon}
                        className="Axoiconlink"
                        style={{ fontSize: "20px" }}
                        glyph="icon-simple-line-icons-user-following"
                        componentAttribute="title"
                        entity="InvoicesViewSharedIcon"
                      />
                    </span>
                  )}
                  {!!invoice.files && invoice.files.length > 0 && (
                    <>
                      &nbsp;
                      <Icon
                        glyph="icon-fontello-attach"
                        className="editable"
                        role="button"
                        onClick={() =>
                          this.fileModal.current.open(invoice.files)
                        }
                      />
                    </>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.number.width}
            isResizable={true}
          />
          <Column
            columnKey="creationDate"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-calendar-8" />}
                showAltIcon={columnDefs.creationDate.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.creationDate}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="DocumentTabViewcreationDate"
                  defaultValue={"Oprettelsesdato"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {moment(invoice.creationDate).format("L")}
                </Cell>
              );
            }}
            width={columnDefs.creationDate.width}
            isResizable={true}
          />
          <Column
            columnKey="dueDate"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph=" icon-fontello-calendar-6" />}
                showAltIcon={columnDefs.dueDate.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.dueDate}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="InvoiceGridViewpaymentDate"
                  defaultValue={"Forfaldsdato"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);

              if (!!invoice.invoiceCreditedId) {
                return <Cell></Cell>;
              }

              let daysOverTime =
                invoice.status !== "Paid"
                  ? moment(invoice.creationDate).diff(
                      moment(invoice.dueDate),
                      "days"
                    )
                  : 0;

              let daylabel =
                daysOverTime === 1
                  ? getText("DayLabel", "dag")
                  : getText("AdminTabView32", "dage");

              return (
                <Cell key={invoice.id} {...props}>
                  {userProfile.userName === invoice.userName ? (
                    <input
                      type="date"
                      style={{ border: "none" }}
                      onChange={this.onChangeDueDate.bind(this, invoice.id)}
                      value={moment(invoice.dueDate).format("YYYY-MM-DD")}
                    />
                  ) : (
                    <div>{moment(invoice.dueDate).format("L")}</div>
                  )}
                  {daysOverTime > 0 && (
                    <span className="axored">
                      {" "}
                      {daysOverTime} {daylabel}
                      <AxoLocal
                        entity="axoidcode234"
                        defaultValue={"over tid."}
                      />
                    </span>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.dueDate.width}
            isResizable={true}
          />
          <Column
            columnKey="description"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph=" icon-fontello-calendar-6" />}
                showAltIcon={columnDefs.description.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.description}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              let invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {!invoice.invoiceCreditedId ? (
                    <>
                      <AxoLocal
                        entity="axoidcode210"
                        defaultValue={"Faktura"}
                      />{" "}
                      #{invoice.number}
                    </>
                  ) : (
                    <>
                      <AxoLocal
                        entity="axoidcode211"
                        defaultValue={"Kreditering af"}
                      />{" "}
                      {invoice.invoiceCredited.invoiceType}{" "}
                      {invoice.invoiceCredited.number}
                    </>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.description.width}
            isResizable={true}
          />
          <Column
            columnKey="status"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-check" />}
                showAltIcon={columnDefs.status.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.status}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  <div className="flexbox-standard">
                    {this.getStatusIcon(invoice.status)}
                    {/* {this.renderStatusText(invoice, statusLabels)} */}
                    {userProfile.userName === invoice.userName && userProfile.userType === "Admin"
                      ? this.renderStatusSelect(invoice, statusLabels)
                      : this.renderStatusText(invoice, statusLabels)}
                  </div>
                </Cell>
              );
            }}
            width={columnDefs.status.width}
            isResizable={true}
          />
          <Column
            columnKey="clientString"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-user" />}
                showAltIcon={columnDefs.clientString.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.clientString}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="CaseEditFormclients"
                  defaultValue={"Klient"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {invoice.client ? (
                    <span>{this.renderClientName(invoice.client)}</span>
                  ) : (
                    <span>
                      <AxoLocal
                        entity="getStatusStringinvoicestatus"
                        defaultValue="Ingen klient"
                      />
                    </span>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.clientString.width}
            isResizable={true}
          />
          <Column
            columnKey="outstandingAmount"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-database-2" />}
                showAltIcon={columnDefs.outstandingAmount.width < 75}
                onSortChange={(columnKey, sortDir) =>
                  this._onSortChange(columnKey, sortDir, (l, r) => {
                    let lTotal = this.calculateInvoiceTotal(l);
                    let rTotal = this.calculateInvoiceTotal(r);

                    return lTotal - rTotal;
                  })
                }
                sortDir={colSortDirs.outstandingAmount}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="InvoiceInvoicetotal" defaultValue={"Total"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {invoice.currency} {this.calculateInvoiceTotal(invoice)}
                </Cell>
              );
            }}
            width={columnDefs.outstandingAmount.width}
            isResizable={true}
          />
          <Column
            columnKey="actions"
            header={<Cell></Cell>}
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {invoiceSendingId === invoice.id ? (
                    <LoadingIcon show={true} />
                  ) : (
                    <>
                      {sentInvoiceId === invoice.id ? (
                        <Icon glyph="icon-fontello-check-1" />
                      ) : (
                        <AxoLocal
                          componentClass={Icon}
                          className="Axoiconlink editable"
                          role="button"
                          onClick={this.onSendInvoiceLinkFromTable.bind(
                            this,
                            invoice
                          )}
                          glyph="icon-fontello-mail-1"
                          componentAttribute="title"
                          entity="BCCTitleSendinvoicetocustomer" //Send faktura til kunde
                        />
                      )}
                    </>
                  )}
                  &ensp;&ensp;
                  {creatingCreditNoteForId === invoice.id ? (
                    <LoadingIcon show={true} />
                  ) : (
                    <Icon
                      className="editable"
                      role="button"
                      onClick={this.onCreateCreditNote.bind(this, invoice)}
                      glyph="icon-fontello-table"
                      title={getText("axoidcode237", "Opret kreditnota")}
                    />
                  )}
                  {invoice.status !== "Paid" && !invoice.invoiceCreditedId && (
                    <>
                      &ensp;&ensp;
                      {payingInvoiceId === invoice.id ? (
                        <LoadingIcon show={true} />
                      ) : (
                        <Icon
                          className="editable"
                          role="button"
                          onClick={this.registerInvoicePayment.bind(this, invoice)}
                          glyph="icon-fontello-money-2"
                          title={getText("InvoicePayInvoice", "Betal faktura")}
                        />
                      )}
                    </>
                  )}
                  {userProfile.userName === invoice.userName ? (
                    <>
                      &ensp;&ensp;
                      <Icon
                        className="editable"
                        role="button"
                        onClick={this.onTrashInvoice.bind(this, invoice.id)}
                        glyph="icon-fontello-trash-1"
                        title={getText("axoidcode240", "Slet faktura")}
                      />
                    </>
                  ) : null}
                </Cell>
              );
            }}
            width={columnDefs.actions.width}
            isResizable={true}
          />
        </Table>
        <FileViewerModal ref={this.fileModal} />
      </div>
    );
  };

  // onGeneratePdf = () => {
  //   var currentInvoice = this.props.invoices.find( i => i.id === this.state.selectedInvoiceId);

  //   this.setState({ isFetchingHTML: true }, () => {
  //     var invoice = Object.assign({}, currentInvoice);
  //     invoice.innerHTML = this.getInvoiceHtml();
  //     this.setState({ isFetchingHTML: false }, () => {
  //       this.doGeneratePdf(invoice);
  //     });
  //   });
  // }

  // doGeneratePdf = (invoice) => {
  //   this.setState({ isGeneratingPDF: true });
  //   return ApiService.updateInvoice(invoice)
  //   .then(response => {
  //     if(response.ok) {
  //       return DataActions.generateInvoicePdf(invoice.id);
  //     }
  //     return Promise.reject("Invoice could not be updated.");
  //   })
  //   .then( response => {
  //     this.setState({ isGeneratingPDF: false});
  //     if(response.ok) {
  //       return response.json();
  //     }
  //     return Promise.reject("Invoice could not be printed.");
  //   })
  //   .then( newPDF => {
  //     this.props.onCreatePDF(newPDF);
  //     this.setState( { generatedPDF: true } );
  //     setTimeout(() => {
  //       this.setState( { generatedPDF: false } );
  //     }, 1500);
  //   })
  //   .catch( reason => {
  //     this.showGeneratePDFError();
  //   });
  // }

  // getInvoiceHtml = () => {
  //   var invoiceNode = ReactDOM.findDOMNode(this.invoiceComponent).innerHTML;
  //   return invoiceNode;
  // }

  // showGeneratePDFError = () => {
  //   this.setState({
  //     isGeneratingPDF: false,
  //     isGeneratingAndSending: false,
  //     showGeneratePDFError: true,
  //   });
  //   setTimeout( () => {
  //     this.setState({ showGeneratePDFError: false });
  //   }, 5000 )
  // }

  // onGenerateAndSend = () => {
  //   var currentInvoice = this.props.invoices.find( i => i.id === this.state.selectedInvoiceId);
  //   this.setState({ isFetchingHTML: true }, () => {
  //     var invoice = Object.assign({}, currentInvoice);
  //     invoice.innerHTML = this.getInvoiceHtml();
  //     this.setState({ isFetchingHTML: false }, () => {
  //       this.doGenerateAndSend(invoice);
  //     });
  //   });
  // }

  // doGenerateAndSend = (invoice) => {
  //   this.setState({ isGeneratingAndSending: true });
  //   return ApiService.updateInvoice(invoice)
  //   .then(response => {
  //     if(response.ok) {
  //       return DataActions.generateInvoicePdf(invoice.id);
  //     }
  //     return Promise.reject("Invoice could not be updated.");
  //   })
  //   .then( response => {
  //     this.setState({ isGeneratingAndSending: false});
  //     if(response.ok) {
  //       return response.json()
  //     }
  //     return Promise.reject("Invoice could not be printed.");
  //   })
  //   .then( newPDF => {
  //     this.setState({ isGeneratingAndSending: false});
  //     this.props.onCreateAndSendPDF(newPDF);
  //   })
  //   .catch( reason => {
  //     console.log(reason);
  //     this.showGeneratePDFError();
  //   });
  // }

  // onSendInvoiceFromTable = (currentInvoice) => {
  //   this.setState({ selectedInvoiceId: currentInvoice.id, isFetchingHTML: true }, () => {
  //     var invoice = Object.assign({}, currentInvoice);
  //     invoice.innerHTML = this.getInvoiceHtml();
  //     this.setState({ selectedInvoiceId: 0, isFetchingHTML: false }, () => {
  //       this.doGenerateAndSendFromTable(invoice);
  //     });
  //   });
  // }

  // doGenerateAndSendFromTable = (invoice) => {
  //   this.setState({ invoiceSendingId: invoice.id });
  //   return ApiService.updateInvoice(invoice)
  //   .then(response => {
  //     if(response.ok) {
  //       return DataActions.generateInvoicePdf(invoice.id);
  //     }
  //     return Promise.reject("Invoice could not be updated.");
  //   })
  //   .then( response => {
  //     this.setState({ invoiceSendingId: false});
  //     if(response.ok) {
  //       return response.json()
  //     }
  //     return Promise.reject("Invoice could not be printed.");
  //   })
  //   .then( newPDF => {
  //     this.setState({ invoiceSendingId: false});
  //     this.props.onCreateAndSendPDF(newPDF);
  //   })
  //   .catch( reason => {
  //     this.showPDFGenerationErrorModal();
  //   });
  // }

  // showPDFGenerationErrorModal = () => {
  //   this.setState({ invoiceSendingId: 0 });
  //   ModalService.openAlertModal(<AxoLocal entity='CaseDataTableFixedClient'defaultValue={'PDF´en kunne ikke genereres. Prøv igen senere.'}/>);
  // }

  renderBody = () => {
    return this.renderInvoicesTable();
  };

  render() {
    return (
      <div style={{ paddingTop: "10px" }}>
        {this.renderBody()}
        <DateTimeModal ref={this.dateTimeModal} />
      </div>
    );
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function () {
    return window.innerHeight - 200;
  },
})(InvoicesViewV2);
