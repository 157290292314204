import React from "react";
import PresentationView from "../Presentation/PresentationView";
import { Row, Col, Grid } from "react-bootstrap";
export default class AdminMain extends React.PureComponent {
  //Props
  //Presentation
  renderNewestPresentation = () => {
    var presentations = this.props.presentations;
    if (presentations.length === 0) {
      return null;
    }
    return (
      <PresentationView
        presentation={presentations[0]}
        userProfile={this.props.userProfile}
        includeLinks
        includeLinkBox
        onTogglePublic={this.props.onTogglePublic}
        allowEdit
      />
    );
  };

  render() {
    return (
      <div className="text-center">
        <Grid fluid>
          <Row>
            <Col
              xs={12}
              className="nopadding"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              {this.renderNewestPresentation()}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
