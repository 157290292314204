import React from "react";
import UserInfoService from "../../services/UserInfoService";
import {
  AxoCheckbox,
  Icon,
  LexButton,
  AxoLocal,
} from "../../utilities/LexUtilities";

import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";

const OptionBox = (props) => {
  var { label, active, onSelect } = props;
  var className = active ? "option-box active" : "option-box";

  return (
    <div
      role="button"
      className={className}
      onClick={onSelect.bind(this, label)}
    >
      {label}
    </div>
  );
};

const OptionSelect = (props) => {
  var { currentOption, options, onSelect } = props;

  return (
    <div className="flexbox-responsive">
      {options.map((option) => {
        return (
          <OptionBox
            key={option}
            label={option}
            active={currentOption === option}
            onSelect={onSelect}
          />
        );
      })}
    </div>
  );
};

const AccountTypeBox = (props) => {
  var { optionKey, imageSrc, title, active, onSelect, style, className } =
    props;
  var allClasses = active
    ? className + " accountType-box active"
    : className + " accountType-box";

  return (
    <LexButton
      style={style}
      role="button"
      className={allClasses}
      onClick={onSelect.bind(this, optionKey)}
    >
      <img alt="" src={imageSrc} className="img-circle" height="100%" />
      {title}
    </LexButton>
  );
};

const AccountTypeSelect = (props) => {
  var { currentOption, onSelect } = props;

  return (
    <div>
      <Col lg={12} className="nopadding">
        {/* <ButtonToolbar style={{ paddingTop:'15px', paddingBottom:'5px'}}> */}
        <Col
          sm={6}
          xs={12}
          className="nopadding xs-center sm-right"
          style={{ paddingBottom: "10px" }}
        >
          {/* <AccountTypeBox
        className='noradius'
        optionKey='Gmail'
        disabled
        title={<span><Icon glyph='icon-fontello-gplus'/>&nbsp;&nbsp; <AxoLocal entity='ExternalMailsetup27'defaultValue={'Gmail'}/></span>}
        active={currentOption === 'Gmail'}
        onSelect={onSelect}
      /> */}
          <AccountTypeBox
            optionKey="Outlook"
            className="noradius"
            title={
              <span>
                {" "}
                <Icon glyph="icon-fontello-windows" />
                &nbsp;&nbsp;{" "}
                <AxoLocal
                  entity="ExternalMailsetup26"
                  defaultValue={"Outlook"}
                />{" "}
              </span>
            }
            active={currentOption === "Outlook"}
            onSelect={onSelect}
          />
          <AccountTypeBox
            optionKey="OneCom"
            className="noradius"
            title={<span>One.com</span>}
            active={currentOption === "OneCom"}
            onSelect={onSelect}
          />
        </Col>
        <Col sm={6} xs={12} className="nopadding xs-center sm-left">
          <AccountTypeBox
            optionKey="Yahoo"
            className="noradius"
            title={
              <span>
                {" "}
                <Icon glyph="icon-fontello-yahoo" />
                &nbsp;&nbsp;{" "}
                <AxoLocal
                  entity="ExternalMailsetup25"
                  defaultValue={"Yahoo Mail"}
                />{" "}
              </span>
            }
            active={currentOption === "Yahoo"}
            onSelect={onSelect}
          />
          <AccountTypeBox
            optionKey="Other"
            className="noradius"
            title={
              <span>
                {" "}
                <Icon glyph="icon-fontello-right-2" />
                &nbsp;&nbsp;{" "}
                <AxoLocal
                  entity="ExternalMailsetup16"
                  defaultValue={"Anden"}
                />{" "}
              </span>
            }
            active={currentOption === "Other"}
            onSelect={onSelect}
          />
        </Col>
        {/* </ButtonToolbar>       */}
      </Col>
    </div>
  );
};

const GmailInstructions = (props) => {
  return (
    <div>
      <h4>
        {" "}
        <span>
          <AxoLocal
            entity="ExternalMailsetup17"
            defaultValue={" Forberedelse af Gmail konto"}
          />
        </span>{" "}
      </h4>
      <h5>
        {" "}
        <span>
          1){" "}
          <AxoLocal
            entity="ExternalMailsetup18"
            defaultValue={"Tillad IMAP adgang til din Gmail konto. "}
          />{" "}
        </span>
        <span>
          <a href="https://support.google.com/mail/answer/7126229">
            <AxoLocal
              entity="ExternalMailsetup19"
              defaultValue={"Klik her for instrukser."}
            />{" "}
          </a>
        </span>
      </h5>
      <h5>
        2){" "}
        <span>
          <AxoLocal
            entity="ExternalMailsetup20"
            defaultValue={
              " Sørg for at to-faktor autentificering er slået fra."
            }
          />
        </span>
      </h5>
      <h5>
        3){" "}
        <span>
          <AxoLocal
            entity="ExternalMailsetup21"
            defaultValue={
              "Tillad mindre sikre apps at logge på din Gmail konto."
            }
          />{" "}
        </span>
        <span>
          <a href="https://myaccount.google.com/lesssecureapps">
            <AxoLocal
              entity="ExternalMailsetup22"
              defaultValue={"Klik her for at aktivere adgang."}
            />
          </a>
        </span>
      </h5>
      <h5>
        4){" "}
        <AxoLocal
          entity="ExternalMailSetupGmailAccess"
          defaultValue={
            "Hvis forbindelsen stadig ikke kan oprettes, så log på din Gmail konto i browseren, og gå til det følgende link for at aktivere adgang:"
          }
        />
        <a href="https://accounts.google.com/DisplayUnlockCaptcha">
          {" "}
          <AxoLocal
            entity="ExternalMailsetup22"
            defaultValue={"Klik her for at aktivere adgang."}
          />
        </a>
      </h5>
    </div>
  );
};

const YahooInstructions = (props) => {
  return (
    <div>
      <h4>
        {" "}
        <span>
          <AxoLocal
            entity="ExternalMailsetup23"
            defaultValue={"Forberedelse af Yahoo Mail konto"}
          />{" "}
        </span>
      </h4>
      <h5>
        <span>
          1){" "}
          <AxoLocal
            entity="ExternalMailsetup24"
            defaultValue={
              "Tillad mindre sikre apps at logge på din Yahoo konto."
            }
          />{" "}
        </span>
        <span>
          {" "}
          <a href="https://login.yahoo.com/account/security#other-apps">
            <AxoLocal
              entity="ExternalMailsetup22"
              defaultValue={"Klik her for at aktivere adgang."}
            />{" "}
          </a>
        </span>{" "}
      </h5>
    </div>
  );
};

const ExternalMailSetupView = (props) => {
  const onUpdateProperty = (propertyName, event) => {
    var account = Object.assign({}, props.account);
    account[propertyName] = event.target.value;
    props.onUpdateAccount(account);
  };

  const onUpdatePropertyValue = (propertyName, value) => {
    var account = Object.assign({}, props.account);
    account[propertyName] = value;
    props.onUpdateAccount(account);
  };

  const onCheckPropertyValue = (propertyName, event) => {
    var account = Object.assign({}, props.account);
    account[propertyName] = event.target.checked;
    props.onUpdateAccount(account);
  };

  var {
    account,
    userProfile,
    onConnect,
    onCancel,
    attemptingConnection,
    connectionError,
  } = props;
  return (
    <div className="standardMaxWidth">
      <Grid fluid>
        <Row
          className="backgroundlineargradient3"
          style={{ paddingTop: "25px" }}
        >
          <Col xs={12}>
            <div style={{ minHeight: "800px" }}>
              <Form>
                <div className="frontPageaxolexdiv Bannecolor ">
                  <AxoLocal
                    entity="ExternalMailsetup1"
                    defaultValue={"Din interne mail adresse er:"}
                  />
                </div>
                <div className="text-center" style={{ padding: "25px" }}>
                  {" "}
                  {UserInfoService.getDisplayName(userProfile)}
                </div>
                <div className="frontPageaxolexdiv Bannecolor">
                  {" "}
                  <AxoLocal
                    entity="ExternalMailsetup2"
                    defaultValue={"Ekstern Email opsætning"}
                  />
                </div>
                <FormGroup
                  style={{ paddingTop: "25px", paddingBottom: "20px" }}
                >
                  <AccountTypeSelect
                    currentOption={account.accountType}
                    onSelect={onUpdatePropertyValue.bind(this, "accountType")}
                  />
                </FormGroup>
                {account.accountType === "Gmail" ? <GmailInstructions /> : null}
                {account.accountType === "Yahoo" ? <YahooInstructions /> : null}
                <FormGroup controlId="displayName" bsSize="large">
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal
                      entity="Enhedspris6"
                      defaultValue={"Beskrivelse"}
                    />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    value={account.displayName}
                    onChange={onUpdateProperty.bind(this, "displayName")}
                  />
                </FormGroup>
                <FormGroup controlId="account" bsSize="large">
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal entity="LoginonLogin" defaultValue={"Log ind"} />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    value={account.account}
                    onChange={onUpdateProperty.bind(this, "account")}
                  />
                </FormGroup>
                <FormGroup controlId="password" bsSize="large">
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal
                      entity="ExternalMailsetup4"
                      defaultValue={"Kodeord"}
                    />
                  </ControlLabel>

                  <FormControl
                    type="password"
                    value={account.password}
                    onChange={onUpdateProperty.bind(this, "password")}
                  />
                </FormGroup>
                {account.accountType === "Other" ? (
                  <div>
                    <h4>
                      <AxoLocal
                        entity="ExternalMailsetup5"
                        defaultValue={"Indgående mail"}
                      />{" "}
                    </h4>
                    <FormGroup>
                      <ControlLabel
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <AxoLocal
                          entity="ExternalMailsetup6"
                          defaultValue={"Server type"}
                        />
                      </ControlLabel>
                      <OptionSelect
                        options={["POP3", "IMAP"]}
                        currentOption={account.serverType}
                        onSelect={onUpdatePropertyValue.bind(
                          this,
                          "serverType"
                        )}
                      />
                    </FormGroup>
                    <FormGroup controlId="server" bsSize="large">
                      <ControlLabel
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <AxoLocal
                          entity="ExternalMailsetup7"
                          defaultValue={"Server"}
                        />
                      </ControlLabel>

                      <FormControl
                        type="text"
                        value={account.ingoingServer}
                        onChange={onUpdateProperty.bind(this, "ingoingServer")}
                      />
                    </FormGroup>
                    <FormGroup controlId="ingoingPort" bsSize="large">
                      <ControlLabel
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <AxoLocal
                          entity="ExternalMailsetup8"
                          defaultValue={"Port"}
                        />
                      </ControlLabel>

                      <FormControl
                        type="number"
                        value={account.ingoingPort}
                        onChange={onUpdateProperty.bind(this, "ingoingPort")}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <AxoLocal
                          entity="ExternalMailsetup9"
                          defaultValue={"Protokol"}
                        />
                      </ControlLabel>
                      <OptionSelect
                        options={["SSL", "TLS"]}
                        currentOption={account.ingoingConnectionType}
                        onSelect={onUpdatePropertyValue.bind(
                          this,
                          "ingoingConnectionType"
                        )}
                      />
                    </FormGroup>
                    <h4>
                      {" "}
                      <AxoLocal
                        entity="ExternalMailsetup10"
                        defaultValue={"Udgående mail"}
                      />{" "}
                    </h4>
                    <FormGroup controlId="smtp" bsSize="large">
                      <ControlLabel
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <AxoLocal
                          entity="ExternalMailsetup11"
                          defaultValue={"Server (SMTP)"}
                        />
                      </ControlLabel>

                      <FormControl
                        type="text"
                        value={account.outgoingServer}
                        onChange={onUpdateProperty.bind(this, "outgoingServer")}
                      />
                    </FormGroup>
                    <FormGroup controlId="outgoingPort" bsSize="large">
                      <ControlLabel
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <AxoLocal
                          entity="ExternalMailsetup8"
                          defaultValue={"Port"}
                        />
                      </ControlLabel>

                      <FormControl
                        type="number"
                        value={account.outgoingPort}
                        onChange={onUpdateProperty.bind(this, "outgoingPort")}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <AxoLocal
                          entity="ExternalMailsetup9"
                          defaultValue={"Protokol"}
                        />
                      </ControlLabel>
                      <OptionSelect
                        options={["SSL", "TLS"]}
                        currentOption={account.outgoingConnectionType}
                        onSelect={onUpdatePropertyValue.bind(
                          this,
                          "outgoingConnectionType"
                        )}
                      />
                    </FormGroup>
                    <div className="flexbox-standard">
                      <FormGroup
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                        bsSize="large"
                      >
                        <AxoCheckbox
                          checked={account.outgoingRequireAuthentication}
                          onChange={onCheckPropertyValue.bind(
                            this,
                            "outgoingRequireAuthentication"
                          )}
                        />
                        <AxoLocal
                          entity="ExternalMailsetup12"
                          defaultValue={"Kræver autentificering"}
                        />
                      </FormGroup>
                      &nbsp;&nbsp;&nbsp;
                      {account.outgoingRequireAuthentication ? (
                        <FormGroup
                          style={{
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: "normal",
                          }}
                          bsSize="large"
                        >
                          <AxoCheckbox
                            checked={account.outgoingDifferentAuthentication}
                            onChange={onCheckPropertyValue.bind(
                              this,
                              "outgoingDifferentAuthentication"
                            )}
                          />
                          <AxoLocal
                            entity="ExternalMailsetup13"
                            defaultValue={"Brug andet login til udgående post"}
                          />
                        </FormGroup>
                      ) : null}
                    </div>
                    {account.outgoingDifferentAuthentication ? (
                      <div>
                        <FormGroup controlId="login" bsSize="large">
                          <ControlLabel
                            style={{
                              textAlign: "left",
                              fontSize: "16px",
                              fontWeight: "normal",
                            }}
                          >
                            <AxoLocal
                              entity="LoginonLogin"
                              defaultValue={"Log ind"}
                            />
                          </ControlLabel>

                          <FormControl
                            type="text"
                            value={account.outgoingAccount}
                            onChange={onUpdateProperty.bind(
                              this,
                              "outgoingAccount"
                            )}
                          />
                        </FormGroup>
                        <FormGroup controlId="outgoingPassword" bsSize="large">
                          <ControlLabel
                            style={{
                              textAlign: "left",
                              fontSize: "16px",
                              fontWeight: "normal",
                            }}
                          >
                            <AxoLocal
                              entity="ExternalMailsetup4"
                              defaultValue={"Kodeord"}
                            />
                          </ControlLabel>

                          <FormControl
                            type="password"
                            value={account.outgoingPassword}
                            onChange={onUpdateProperty.bind(
                              this,
                              "outgoingPassword"
                            )}
                          />
                        </FormGroup>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <FormGroup className="text-center">
                  <Button
                    onClick={onConnect}
                    disabled={attemptingConnection}
                    style={{ width: "75%", height: "45px" }}
                    className="Lex-button-2"
                  >
                    {attemptingConnection ? (
                      <span>
                        <AxoLocal
                          entity="axoidcode35"
                          defaultValue={
                            "Opretter forbindelse og henter mails. Vent venligst."
                          }
                        />
                        &nbsp;
                        <img
                          alt=""
                          src="/imgs/app/loading.gif"
                          className="img-circle"
                          width="20"
                          height="20"
                        />
                      </span>
                    ) : (
                      <AxoLocal
                        entity="ExternalMailsetup14"
                        defaultValue={"Opret forbindelse"}
                      />
                    )}
                  </Button>
                </FormGroup>
                {!!connectionError ? (
                  <div className="fg-red text-center">
                    <div>
                      <AxoLocal
                        entity="ExternalMailsetup15"
                        defaultValue={
                          "Forbindelsen kunne ikke oprettes. Tjek at opsætningen er korrekt."
                        }
                      />
                    </div>
                    <div>{connectionError}</div>
                  </div>
                ) : null}
                <FormGroup className="text-center">
                  <Button
                    onClick={onCancel}
                    style={{ width: "75%", height: "45px" }}
                    className="Lex-button-2"
                  >
                    <AxoLocal
                      entity="socialInputBoxNavCancel"
                      defaultValue={"Annuller"}
                    />
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default ExternalMailSetupView;
