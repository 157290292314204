import React from "react";
import { Table, Column, Cell } from "fixed-data-table-2";
import ApiService from "../../services/DataAccess/ApiService";
import DataActions from "../../services/DataAccess/DataActions";
import AccountingStore from "../../services/AccountingStore";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash";
import PostingsGridView from "./PostingsGridView";
import RoutingService from "../../services/RoutingService";
import * as $ from "jquery";

import {
  Icon,
  LexButton,
  InlineEdit,
  AxoCheckbox,
  ClientSearchBox,
  InlineNumberEdit,
  FileViewerModal,
  Dimensions,
  AxoLocal,
} from "../../utilities/LexUtilities";

import { Panel, FormControl, Button, PanelGroup } from "react-bootstrap";

import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";

//Driftsregnskab
class PostingsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnWidths: {
        date: 175,
        accountType: 175,
        account: 175,
        description: 125,
        capitalAccount: 120,
        bank: 100,
        income: 100,
        expenses: 100,
        debt: 100,
        misc: 100,
        interest: 100,
        incomingVat: 100,
        outgoingVat: 100,
        withinEU: 100,
        attachment: 100,
        actions: 75,
        remove: 75,
      },
      minColWidths: {},
      maxColumnWidth: 250,
      newAccountTypeName: "",
      newAccountNumber: 0,
      newAccountName: "",
      selectedAccountType: 0,
      showAccountView: false,
      showCards: this.loadShowCards(),
    };
    if (props.accountTypes.length > 0) {
      this.state.selectedAccountType = props.accountTypes[0].id;
    }
  }

  loadShowCards = () => {
    if (typeof Storage === "undefined") {
      return false;
    }
    var showCards = localStorage.getItem("showPostingCards");
    return JSON.parse(showCards);
  };

  saveShowCard(showCards) {
    if (typeof Storage === "undefined") {
      return;
    }
    localStorage.setItem("showPostingCards", JSON.stringify(showCards));
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.accountTypes.length > 0 &&
      nextProps.accountTypes.length !== this.props.accountTypes.length
    ) {
      this.setState({
        selectedAccountType: nextProps.accountTypes[0].id,
      });
    }
  }

  _onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
    var minWidth = this.state.minColWidths[columnKey] || 50;
    var newWidth = Math.max(newColumnWidth, minWidth);
    newWidth = Math.min(newWidth, this.state.maxColumnWidth);

    this.setState(({ columnWidths }) => ({
      columnWidths: {
        ...columnWidths,
        [columnKey]: newWidth,
      },
    }));
  };

  getFilteredEntries = (entries, searchText) => {
    return entries;
    // if(!searchText) {
    //   return entries;
    // }
    // var filteredEntries = entries;
    // if(this.state.showCurrency) {
    //   filteredEntries = filteredEntries.filter( entry => entry.currency.toLowerCase() === this.state.showCurrency.toLowerCase());
    // }
    // return filteredEntries.filter( c => {
    //   return (c.planId + ' ' + c.name).toLowerCase().includes(searchText.toLowerCase());
    // });
  };

  onAddPosting = (rowIndex) => {
    DataActions.createPosting({
      contactInfoId: this.props.selectedClient.id,
    });
    // var postings = this.state.postings.slice();
    // postings.splice(rowIndex + 1, 0, this.makeNewPosting());
    // this.setState({ postings });
  };

  onRemovePosting = (id) => {
    DataActions.deletePosting(id);
    // var postings = this.state.postings.slice();
    // postings.splice(rowIndex, 1);
    // this.setState({ postings });
  };

  onAddAccountType = (event) => {
    var { newAccountTypeName } = this.state;
    if (!newAccountTypeName) {
      return;
    }
    DataActions.createPostingAccountType({
      name: newAccountTypeName,
    });
    this.setState({
      newAccountTypeName: "",
    });
  };

  onAddAccount = (event) => {
    var { newAccountNumber, newAccountName } = this.state;
    if (!newAccountNumber || !newAccountName) {
      return;
    }
    DataActions.createPostingAccount({
      PostingAccountTypeId: this.state.selectedAccountType,
      number: newAccountNumber,
      name: newAccountName,
    }).then((response) => {
      this.setState({
        newAccountNumber: "",
        newAccountName: "",
      });
    });
  };

  onUpdateProperty = (event) => {
    const target = event.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  onDateChange = (id, event) => {
    var posting = Object.assign(
      {},
      this.props.postings.find((p) => p.id === id)
    );
    posting.date = moment(event.target.valueAsDate).format();
    AccountingStore.updateArrayObject(posting, "postings"); //Optimistic updating
    this.debouncedUpdate(posting);
  };

  debouncedUpdate = debounce((posting) => {
    DataActions.updatePosting(posting);
  }, 1000);

  onUpdatePostingProperty(event, id) {
    const target = event.target;
    const name = target.name;

    var posting = Object.assign(
      {},
      this.props.postings.find((p) => p.id === id)
    );
    posting[name] = target.value;
    DataActions.updatePosting(posting);
  }

  onUpdateInlinePostingProperty(propertyName, value, id) {
    var posting = Object.assign(
      {},
      this.props.postings.find((p) => p.id === id)
    );
    posting[propertyName] = value;
    DataActions.updatePosting(posting);
  }

  onUploadAttachment = (postingId) => {
    $("#attachmentUpload").trigger("click");
    this.uploadingId = postingId;
  };

  onUploadedAttachment = (event) => {
    var uploader = event.target;
    if (uploader.files.length === 0) {
      return;
    }
    ApiService.uploadDocuments(uploader.files)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        return Promise.reject(new Error("Upload failed"));
      })
      .then((fileModels) => {
        let fileModel = fileModels[0];
        let posting = Object.assign(
          {},
          this.props.postings.find((p) => p.id === this.uploadingId)
        );
        if (!posting) {
          return Promise.reject("Posting could not be found.");
        }
        ApiService.getNextAttachmentNumber(posting.contactInfoId).then(
          (attachmentNumber) => {
            posting.attachmentId = fileModel.id;
            posting.attachmentNumber = attachmentNumber;

            let { selectedClient } = this.props;
            var clientId =
              selectedClient.clientNumber || selectedClient.firstName;

            fileModel.fileName =
              "(" +
              clientId +
              ") Bilag-" +
              attachmentNumber +
              fileModel.fileType;
            fileModel.lockedName = true;
            DataActions.updateDocumentName(fileModel).then((response) => {
              return DataActions.updatePosting(posting);
            });
          }
        );
      });
    uploader.value = "";
  };

  onUpdateAttachmentNumber = (attachmentNumber, postingId) => {
    let posting = { ...this.props.postings.find((p) => p.id === postingId) };
    posting.attachmentNumber = attachmentNumber;
    let attachment = { ...posting.attachment };

    let { selectedClient } = this.props;
    var clientId = selectedClient.clientNumber || selectedClient.firstName;

    attachment.fileName =
      "(" + clientId + ") Bilag-" + attachmentNumber + attachment.fileType;
    attachment.lockedName = true;

    DataActions.updateDocumentName(attachment).then((response) => {
      DataActions.updatePosting(posting);
    });
  };

  onShowAttachment = (postingId) => {
    var posting = this.props.postings.find((p) => p.id === postingId);
    this.fileModal.open([posting.attachment]);
  };

  onToggleAccountView = () => {
    this.setState({ showAccountView: !this.state.showAccountView });
  };

  onDeleteAccount = (id) => {
    DataActions.deletePostingAccount(id);
  };

  onDeleteAccountType = (id) => {
    DataActions.deletePostingAccountType(id);
  };

  onToggleCards = () => {
    var showCards = !this.state.showCards;
    this.setState({ showCards });
    this.saveShowCard(showCards);
  };

  onCreateClient = (event) => {
    event.preventDefault();
    this.props.history.push(RoutingService.getPath("Clients"));
  };

  render() {
    var {
      columnWidths,
      newAccountTypeName,
      newAccountNumber,
      newAccountName,
      showAccountView,
      showCards,
    } = this.state;
    const {
      containerHeight,
      containerWidth,
      accountTypes,
      accounts,
      postings,
      contactMap,
      selectedClient,
      handleSelectedClient,
    } = this.props;

    var tableWidth = containerWidth - 20;

    let AccountView = this.AccountView;
    let PostingsTable = this.PostingsTable;
    return (
      <div className="AccountingpaddingTB">
        <div className="flexbox-standard">
          <h3 className="flexbox-center">
            <AxoLocal entity="axoidcode152" defaultValue={"Posteringer"} />
          </h3>
          <h3
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            className="flexbox-center"
          >
            <AxoLocal
              entity="CaseDataTableFixedClient"
              defaultValue={"Klient"}
            />
            :
            {selectedClient.id ? (
              <span>
                &nbsp;{selectedClient.firstName} {selectedClient.lastName}
              </span>
            ) : (
              <span className="editable">
                &nbsp;
                <AxoLocal
                  entity="SubscriptionViewPricingfreetryN"
                  defaultValue={"Ingen"}
                />{" "}
              </span>
            )}
          </h3>
        </div>
        <div className="flexbox-responsive" style={{ paddingBottom: "10px" }}>
          <div style={{ paddingLeft: "15px", paddingRight: "10px" }}>
            <AxoLocal
              componentClass={ClientSearchBox}
              clients={contactMap.contacts}
              count={contactMap.count}
              handleSelectedClient={handleSelectedClient}
              clearOnSelect
              disabled={false}
              componentAttribute="placeholder"
              entity="AccountingTabViewEntity33"
            />
          </div>
          <div style={{ fontSize: "16px" }}>
            <AxoLocal
              entity="AccountingTabViewEntity10"
              defaultValue={"Vælg en klient eller"}
            />
            &nbsp;
            <a
              onClick={this.onCreateClient}
              href={RoutingService.getPath("Clients")}
            >
              <Icon className="editable" glyph="icon-fontello-plus-3" />
              &nbsp;
              <AxoLocal
                entity="AccountingTabViewEntity10a"
                defaultValue={"opret en klient"}
              />
            </a>
          </div>
          <div
            style={{ flexGrow: 1, paddingLeft: "10px", paddingRight: "10px" }}
          >
            <div className="text-right" style={{ maxWidth: "500px" }}>
              <LexButton onClick={this.onToggleAccountView}>
                {showAccountView ? (
                  <span>
                    <AxoLocal
                      entity="AccountingTabViewEntity28"
                      key="hide"
                      defaultValue={"Skjul kontoplan"}
                    />
                  </span>
                ) : (
                  <span>
                    <AxoLocal
                      entity="AccountingTabViewEntity29"
                      key="show"
                      defaultValue={"Administrer kontoplan"}
                    />
                  </span>
                )}
              </LexButton>
            </div>
          </div>
        </div>

        {showAccountView ? (
          <AccountView
            accountTypes={accountTypes}
            accounts={accounts}
            newAccountTypeName={newAccountTypeName}
            newAccountNumber={newAccountNumber}
            newAccountName={newAccountName}
            onAddAccountType={this.onAddAccountType}
            onUpdateProperty={this.onUpdateProperty}
            onDeleteAccount={this.onDeleteAccount}
            onDeleteAccountType={this.onDeleteAccountType}
          />
        ) : null}
        {postings.length > 0 ? (
          <div
            className="hidden-xs, text-right"
            style={{ paddingBottom: "15px" }}
          >
            <LexButton onClick={this.onToggleCards}>
              {showCards ? (
                <span>
                  {" "}
                  <AxoLocal
                    entity="CaseDataTableFixedShowastable"
                    key="show"
                    defaultValue={"Vis som tabel"}
                  />
                </span>
              ) : (
                <span>
                  <AxoLocal
                    entity="CaseDataTableFixedShowCards"
                    key="showCards"
                    defaultValue={"Vis som kartotekskort"}
                  />
                </span>
              )}
            </LexButton>{" "}
          </div>
        ) : null}
        {showCards && postings.length > 0 ? (
          <PostingsGridView
            postings={postings}
            accounts={accounts}
            accountTypes={accountTypes}
          />
        ) : (
          <div>
            <ExtraSmall>
              {postings.length > 0 ? (
                <PostingsGridView
                  postings={postings}
                  accounts={accounts}
                  accountTypes={accountTypes}
                />
              ) : null}
            </ExtraSmall>
            <SmallOrLarger>
              <PostingsTable
                postings={postings}
                accounts={accounts}
                accountTypes={accountTypes}
                tableWidth={tableWidth}
                containerHeight={containerHeight}
                columnWidths={columnWidths}
                selectedClient={selectedClient}
              />
            </SmallOrLarger>
          </div>
        )}

        <FileViewerModal ref={(c) => (this.fileModal = c)} />
        <input
          name="attachment"
          onChange={this.onUploadedAttachment}
          type="file"
          id="attachmentUpload"
          style={{ display: "none" }}
        />
      </div>
    );
  }

  AccountView = (props) => {
    var {
      accountTypes,
      accounts,
      newAccountTypeName,
      newAccountNumber,
      newAccountName,
      onAddAccountType,
      onUpdateProperty,
      onDeleteAccount,
    } = props;

    return (
      <div className="AccountingpaddingTB accounting">
        <div
          style={{ paddingRight: "10px", paddingBottom: "10px" }}
          className="flexbox-responsive"
        >
          <AxoLocal
            componentClass={FormControl}
            type="text"
            name="newAccountTypeName"
            value={newAccountTypeName}
            onChange={onUpdateProperty}
            style={{ maxWidth: "600px", paddingRight: "10px" }}
            componentAttribute="placeholder"
            entity="AccountingTabViewEntity32"
          />
          <span style={{ paddingLeft: "10px" }}>
            <LexButton onClick={onAddAccountType}>
              <Icon glyph="icon-fontello-plus-2" />
              &nbsp;
              <AxoLocal
                entity="AccountingTabViewEntity8"
                defaultValue={"Tilføj kontotype"}
              />
            </LexButton>
          </span>
        </div>
        <div
          style={{
            paddingRight: "10px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <select
            className="axoblue selectbg"
            style={{ height: "30px", minWidth: "500px" }}
            name="selectedAccountType"
            onChange={this.onUpdateProperty}
          >
            {accountTypes.map((t) => {
              return (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              );
            })}
          </select>
        </div>
        <div style={{ paddingBottom: "10px" }}>
          <AxoLocal
            componentClass={FormControl}
            type="number"
            name="newAccountNumber"
            value={newAccountNumber}
            onChange={this.onUpdateProperty}
            style={{
              display: "inline-block",
              maxWidth: "600px",
              paddingBottom: "10px",
            }}
            componentAttribute="placeholder"
            entity="AccountingTabViewEntity31"
          />
        </div>
        <div className="flexbox-standard">
          <div style={{ paddingBottom: "10px", paddingRight: "10px" }}>
            <AxoLocal
              componentClass={FormControl}
              type="text"
              name="newAccountName"
              value={newAccountName}
              onChange={this.onUpdateProperty}
              style={{
                display: "inline-block",
                minWidth: "600px",
                paddingBottom: "10px",
              }}
              componentAttribute="placeholder"
              entity="AccountingTabViewEntity30"
            />
          </div>
          <div style={{ paddingBottom: "10px" }}>
            <LexButton onClick={this.onAddAccount}>
              <Icon glyph="icon-fontello-plus-2" />
              &nbsp;
              <AxoLocal
                entity="AccountingTabViewEntity9"
                defaultValue={"Tilføj Kontonavn"}
              />
            </LexButton>
          </div>
        </div>

        <div style={{ maxWidth: "700px" }}>
          <h3>
            <AxoLocal
              entity="AccountingTabViewEntity27"
              defaultValue={"Kontoplan"}
            />
          </h3>
          {accountTypes.map((t) => {
            var accountsForType = accounts.filter(
              (a) => a.postingAccountTypeId === t.id
            );
            return (
              <div key={t.id} className="flexbox-standard">
                {/* <div className='flexbox-standard'> */}
                {/* <div className='axogrouplist'>
                  <div style={{ minWidth: '200px'}}>{t.name}</div></div>
                  <div style={{ marginLeft: '10px' }}>
                    <LexButton onClick={() => { onDeleteAccountType(t.id)} }>
                      <AxoLocal entity='AccountingTabViewEntity25' defaultValue={'Slet kontotype'}/> 
                    </LexButton>
                  </div>
                </div> */}
                <PanelGroup
                  id="ITsubscriptione"
                  accordion
                  className="Lex-Accordion"
                  defaultActiveKey="0"
                  style={{
                    borderRadius: "0px",
                    paddingTop: "0px",
                    borderBottom: "1px solid white",
                  }}
                >
                  <Panel className="backgroundlineargradient3" eventKey="1">
                    <Panel.Heading>
                      <Panel.Title toggle>
                        {this.generateTypeAccordionHeader(
                          t,
                          accountsForType.length > 0
                        )}
                      </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="LexAccordionPanelody">
                      {accountsForType.map((a) => {
                        return (
                          <div key={a.id} className="flexbox-standard">
                            <div className="axogrouplist">
                              {" "}
                              <div style={{ minWidth: "200px" }}>
                                {a.number} {a.name}
                              </div>
                            </div>
                            <div style={{ marginLeft: "10px" }}>
                              <Button
                                onClick={() => {
                                  onDeleteAccount(a.id);
                                }}
                              >
                                &nbsp;&nbsp;
                                <AxoLocal
                                  entity="AccountingTabViewEntity26"
                                  defaultValue={" Slet konto"}
                                />
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </Panel.Body>
                  </Panel>
                </PanelGroup>

                {/* <Accordion defaultActiveKey='0' style={{ flex: 4 }}>
                  <Panel header={this.generateTypeAccordionHeader(t, accountsForType.length > 0)} eventKey='1' >
                    { accountsForType.map(a => {
                      return (
                        <div key={a.id} className='flexbox-standard'>
                        <div className='axogrouplist'> <div style={{ minWidth: '200px' }} >{a.number} {a.name}</div></div>
                          <div style={{ marginLeft: '10px' }} >
                            <Button onClick={() => { onDeleteAccount(a.id)} }>&nbsp;&nbsp;
                            <AxoLocal entity='AccountingTabViewEntity26' defaultValue={' Slet konto'}/>        
                            </Button> 
                          </div>
                        </div>
                      )
                    })}
                  </Panel>
                </Accordion> &nbsp;&nbsp; */}
                <div style={{ flex: 1 }}>
                  {" "}
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      this.onDeleteAccountType(t.id);
                    }}
                  >
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="AccountingTabViewEntity25"
                      defaultValue={"Slet kontotype"}
                    />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  generateTypeAccordionHeader = (accountType, hasAccounts) => {
    return (
      <div>
        <div>
          {hasAccounts ? (
            <Icon
              className="editable"
              key="hasAccounts"
              glyph="icon-fontello-arrow-combo"
            />
          ) : (
            <span>
              <Icon
                className="editable"
                key="hasnotAccounts"
                glyph="icon-fontello-angle-right"
              />
            </span>
          )}
          &nbsp;&nbsp;
          {accountType.name}
        </div>
      </div>
    );
  };

  PostingsTable = (props) => {
    var {
      postings,
      accountTypes,
      accounts,
      tableWidth,
      containerHeight,
      columnWidths,
      selectedClient,
    } = props;

    if (postings.length === 0) {
      return (
        <Table
          rowHeight={40}
          rowsCount={1}
          width={tableWidth}
          height={containerHeight}
          isColumnResizing={false}
          headerHeight={40}
        >
          <Column
            columnKey="date"
            header={
              <Cell>
                <AxoLocal entity="ADDViewCreate" defaultValue={"Opret"} />
              </Cell>
            }
            cell={(props) => {
              return (
                <Cell {...props}>
                  <div>
                    <LexButton
                      disabled={!selectedClient.id}
                      onClick={this.onAddPosting}
                    >
                      <Icon glyph="icon-fontello-plus-2" />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity11"
                        defaultValue={"Tilføj postering"}
                      />
                    </LexButton>
                  </div>
                </Cell>
              );
            }}
            width={200}
            isResizable={false}
          />
        </Table>
      );
    }

    return (
      <div>
        <Table
          rowHeight={40}
          rowsCount={postings.length}
          width={tableWidth}
          height={containerHeight}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          headerHeight={40}
        >
          <Column
            columnKey="date"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-calendar-1" />
                &nbsp;
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <div>
                    <input
                      style={{ borderStyle: "none" }}
                      type="date"
                      onChange={this.onDateChange.bind(this, posting.id)}
                      value={moment(posting.date).format("YYYY-MM-DD")}
                    />
                  </div>
                </Cell>
              );
            }}
            width={columnWidths.date}
            isResizable={true}
          />
          <Column
            columnKey="accountType"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <span>
                  <AxoLocal
                    entity="AccountingTabViewEntity41"
                    defaultValue={"Kredit"}
                  />
                </span>{" "}
                :{" "}
                <span>
                  <AxoLocal
                    entity="AccountingTabViewEntity42"
                    defaultValue={"Debet"}
                  />
                </span>
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <select
                    className="axoblue selectbg"
                    style={{ height: "30px", width: "150px" }}
                    value={posting.postingAccountTypeId || 0}
                    name="postingAccountTypeId"
                    onChange={(event) =>
                      this.onUpdatePostingProperty(event, posting.id)
                    }
                  >
                    <option value={0}>
                      <AxoLocal
                        entity="SubscriptionViewPricingfreetryN"
                        defaultValue={"Ingen"}
                      />
                    </option>
                    {accountTypes.map((t) => {
                      return (
                        <option key={t.id} value={t.id}>
                          {t.name}
                        </option>
                      );
                    })}
                  </select>
                </Cell>
              );
            }}
            width={columnWidths.accountType}
            isResizable={true}
          />
          <Column
            columnKey="account"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity13"
                  defaultValue={"Konto"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <select
                    className="axoblue selectbg"
                    style={{ height: "30px", width: "150px" }}
                    value={posting.postingAccountId || 0}
                    name="postingAccountId"
                    onChange={(event) =>
                      this.onUpdatePostingProperty(event, posting.id)
                    }
                  >
                    <option value={0}>
                      <AxoLocal
                        entity="SubscriptionViewPricingfreetryN"
                        defaultValue={"Ingen"}
                      />
                    </option>
                    {accounts
                      .filter(
                        (a) =>
                          a.postingAccountTypeId ===
                          posting.postingAccountTypeId
                      )
                      .map((t) => {
                        return (
                          <option key={t.id} value={t.id}>
                            {t.number} - {t.name}
                          </option>
                        );
                      })}
                  </select>
                </Cell>
              );
            }}
            width={columnWidths.account}
            isResizable={true}
          />
          <Column
            columnKey="description"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <InlineEdit
                    value={posting.description}
                    change={(input) =>
                      this.onUpdateInlinePostingProperty(
                        "description",
                        input.value,
                        posting.id
                      )
                    }
                    propName="value"
                    className="editable"
                    editProps={{ style: { width: "100%" } }}
                  />
                </Cell>
              );
            }}
            width={columnWidths.description}
            isResizable={true}
          />
          {/* <Column
              columnKey="capitalAccount"
              header={<Cell>
                  <Icon className='editable' glyph="icon-fontello-down-dir" />&nbsp;
                  <AxoLocal entity='AccountingTabViewEntity15' defaultValue={'Kapitalkonto'}/>               
                </Cell>}
              cell={props => {
                var posting = postings[props.rowIndex];
                return (
                <Cell {...props}>
                  <InlineNumberEdit
                    value={posting.capitalAccount}
                    format={ ( value ) => {
                      return (
                        <span>
                          {value}
                        </span>
                      )
                    }}
                    change={(input) => this.onUpdateInlinePostingProperty('capitalAccount', input.value, posting.id)}
                  />
                </Cell> )}}
              width={columnWidths.capitalAccount}
              isResizable={true}
            /> */}
          <Column
            columnKey="bank"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity24"
                  defaultValue={"Bank"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <InlineNumberEdit
                    value={posting.bankAccount}
                    format={(value) => {
                      return <span>{value}</span>;
                    }}
                    change={(input) =>
                      this.onUpdateInlinePostingProperty(
                        "bankAccount",
                        input.value,
                        posting.id
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnWidths.bank}
            isResizable={true}
          />
          <Column
            columnKey="income"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity16"
                  defaultValue={"Indtægt"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <InlineNumberEdit
                    value={posting.income}
                    format={(value) => {
                      return <span>{value}</span>;
                    }}
                    change={(input) =>
                      this.onUpdateInlinePostingProperty(
                        "income",
                        input.value,
                        posting.id
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnWidths.income}
            isResizable={true}
          />
          <Column
            columnKey="expenses"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity17"
                  defaultValue={"Udgifter"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <InlineNumberEdit
                    value={posting.expenses}
                    format={(value) => {
                      return <span>{value}</span>;
                    }}
                    change={(input) =>
                      this.onUpdateInlinePostingProperty(
                        "expenses",
                        input.value,
                        posting.id
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnWidths.expenses}
            isResizable={true}
          />
          <Column
            columnKey="debt"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity18"
                  defaultValue={"Lån"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <InlineNumberEdit
                    value={posting.debt}
                    format={(value) => {
                      return <span>{value}</span>;
                    }}
                    change={(input) =>
                      this.onUpdateInlinePostingProperty(
                        "debt",
                        input.value,
                        posting.id
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnWidths.debt}
            isResizable={true}
          />
          <Column
            columnKey="misc"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity19"
                  defaultValue={"Diverse"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <InlineNumberEdit
                    value={posting.misc}
                    format={(value) => {
                      return <span>{value}</span>;
                    }}
                    change={(input) =>
                      this.onUpdateInlinePostingProperty(
                        "misc",
                        input.value,
                        posting.id
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnWidths.misc}
            isResizable={true}
          />
          <Column
            columnKey="interest"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity20"
                  defaultValue={"Renter"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <InlineNumberEdit
                    value={posting.interest}
                    format={(value) => {
                      return <span>{value}</span>;
                    }}
                    change={(input) =>
                      this.onUpdateInlinePostingProperty(
                        "interest",
                        input.value,
                        posting.id
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnWidths.interest}
            isResizable={true}
          />
          <Column
            columnKey="incomingVat"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity21"
                  defaultValue={"Indgående moms"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <InlineNumberEdit
                    value={posting.incomingVat}
                    format={(value) => {
                      return <span>{value}</span>;
                    }}
                    change={(input) =>
                      this.onUpdateInlinePostingProperty(
                        "incomingVat",
                        input.value,
                        posting.id
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnWidths.incomingVat}
            isResizable={true}
          />
          <Column
            columnKey="outgoingVat"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity22"
                  defaultValue={"Udgående moms"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <InlineNumberEdit
                    value={posting.outgoingVat}
                    format={(value) => {
                      return <span>{value}</span>;
                    }}
                    change={(input) =>
                      this.onUpdateInlinePostingProperty(
                        "outgoingVat",
                        input.value,
                        posting.id
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnWidths.outgoingVat}
            isResizable={true}
          />
          <Column
            columnKey="withinEU"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity23"
                  defaultValue={"Udenfor EU"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  <AxoCheckbox
                    checked={posting.withinEU}
                    onChange={(event) =>
                      this.onUpdateInlinePostingProperty(
                        "withinEU",
                        event.target.checked,
                        posting.id
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnWidths.withinEU}
            isResizable={true}
          />
          <Column
            columnKey="attachment"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedlabelAddedToCasedocuments"
                  defaultValue={"Bilag"}
                />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell key={posting.id} {...props}>
                  {!!posting.attachment ? (
                    <div>
                      <Icon
                        className="editable"
                        role="button"
                        onClick={() => this.onShowAttachment(posting.id)}
                        glyph="icon-fontello-attach-7"
                      />
                      {!!posting.attachmentNumber ? (
                        <span>
                          &nbsp;
                          <InlineNumberEdit
                            value={posting.attachmentNumber}
                            format={(value) => {
                              return (
                                <span>
                                  <AxoLocal
                                    entity="axoEntityidcode177"
                                    defaultValue={"Nr"}
                                  />
                                  : {value}
                                </span>
                              );
                            }}
                            change={(input) =>
                              this.onUpdateAttachmentNumber(
                                input.value,
                                posting.id
                              )
                            }
                          />
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <LexButton
                      onClick={() => this.onUploadAttachment(posting.id)}
                    >
                      <AxoLocal
                        entity="DocumentTabViewUpload"
                        defaultValue={"Upload"}
                      />
                    </LexButton>
                  )}
                </Cell>
              );
            }}
            width={columnWidths.attachment}
            isResizable={true}
          />
          <Column
            columnKey="actions"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal
                  entity="CaseGridViewReactOption"
                  defaultValue={"Tilføj"}
                />
              </Cell>
            }
            cell={(props) => {
              return (
                <Cell
                  {...props}
                  onClick={() => this.onAddPosting(props.rowIndex)}
                >
                  <div role="button" className="text-center">
                    +
                  </div>
                </Cell>
              );
            }}
            width={columnWidths.actions}
            isResizable={true}
          />
          <Column
            columnKey="remove"
            header={
              <Cell>
                <Icon className="editable" glyph="icon-fontello-down-dir" />
                &nbsp;
                <AxoLocal entity="Gruppeinformation11" defaultValue={"Fjern"} />
              </Cell>
            }
            cell={(props) => {
              let posting = postings[props.rowIndex];
              return (
                <Cell
                  key={posting.id}
                  {...props}
                  onClick={() => this.onRemovePosting(posting.id)}
                >
                  <div role="button" className="text-center">
                    -
                  </div>
                </Cell>
              );
            }}
            width={columnWidths.remove}
            isResizable={true}
          />
        </Table>
      </div>
    );
  };
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight - 315;
    },
  })(PostingsView)
);
