import React from "react";
import BookingView from "../../Booking/BookingView";

import {
  ApiService,
  RoutingService,
  DataStore,
} from "../../services/AxoServices";

import { Consumer } from "../../utilities/LexUtilities";

export default class BookingContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      receiverUserName: "",
      bookingUserName: "",
    };
  }

  componentDidMount() {
    ApiService.getUserNameFromId(this.props.match.params.id).then(
      (userName) => {
        this.setState({ receiverUserName: userName });
      }
    );
    DataStore.fetchUserName();

    RoutingService.setRootPath("/ClientDashboard");
  }

  render() {
    if (!this.state.receiverUserName) {
      return null;
    }
    return (
      <Consumer>
        {(store) => (
          <BookingView
            receiverUserName={this.state.receiverUserName}
            // bookingUserName={store.userName} //All bookers must provide emails.
          />
        )}
      </Consumer>
    );
  }
}
