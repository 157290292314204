import { getText } from "../utilities/LexUtilities";

export default {
  getContactAccountingName(contact) {
    if (!contact.id) {
      return "";
    }

    if (!!contact.companyName) {
      return `${contact.companyName} ${contact.identityCode || ""}`;
    }

    return contact.firstName + " " + (contact.lastName || "");
  },

  getContactFullName(contact) {
    return (
      (!!contact.clientNumber ? contact.clientNumber + " - " : "") +
      contact.firstName +
      " " +
      (contact.lastName || "") +
      " " +
      (contact.companyName || "")
    );
  },

  getContactMailSubject() {
    return `${getText("ClientAccountMailSubject")} - ${Math.random()
      .toString(36)
      .substr(5)}`; //Random string to prevent Gmail grouping
  },

  getContactMailBody(contact) {
    let fullMessage = `
        <div style='padding: 25px; line-height: 20px; background: #eaecec; font-size: 17px; color: #000000; max-width: 500px;'> 
          Kære ${contact.firstName} ${contact.lastName}
        </div>
        <div style='padding: 25px; margin-bottom:5px; line-height: 20px; background:#eaecec; font-size: 17px; color: #000000; max-width: 500px;'>
          Du har fået oprettet en klientkonto i Carra systemet.
        </div>
        <div style='padding: 25px; margin-bottom:5px; line-height: 20px; background:#eaecec; font-size: 17px; color: #000000; max-width: 500px;'>
          Dit brugernavn er: <strong>${contact.clientUserName}</strong>.
        </div>
        <div style='padding: 25px; line-height: 20px; margin-bottom:5px; background: #005380; font-size: 17px; color: #ffffff; max-width: 300px;'>
          <a style='color: white' href='<LINK>'>Klik her</a>
        </div>
        ${
          !!contact.clientMessage
            ? `<div style='padding: 25px; line-height: 50px; background: white; font-size: 22px; color: #005380; max-width: 500px;'>
            ${contact.clientMessage}
           </div>`
            : ""
        }>`;

    return fullMessage;
  },
};
