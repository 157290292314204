import React from "react";

import { Picky } from "react-picky";
import "react-picky/dist/picky.css";

import ReactSelect from "react-select";

import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  FormGroup,
  ControlLabel,
  // InputGroup,
  FormControl,
} from "react-bootstrap";

import {
  Icon,
  TextFilter,
  TextFilterTypes,
  AxoLocal,
  getText,
} from "../../utilities/LexUtilities";

export default class TextFilterModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      textFilter: new TextFilter(),
      selectedOptionsArray: [],
      callback: (textFilter) => {},
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = (textFilter, callback) => {
    let selectedOptionsSet = new Set(textFilter.selectedOptions);

    this.setState({
      showModal: true,
      textFilter,
      selectedOptionsArray: textFilter.options.filter((o) =>
        selectedOptionsSet.has(o.id)
      ),
      callback,
    });
  };

  onOK = () => {
    let { textFilter, selectedOptionsArray } = this.state;

    let newFilter = new TextFilter(textFilter);
    newFilter.selectedOptions = selectedOptionsArray.map((o) => o.id);

    this.setState({ showModal: false }, () => {
      this.state.callback(newFilter);
    });
  };

  onCancel = () => {
    this.setState({ showModal: false });
  };

  selectMultipleOption = (value) => {
    this.setState({ selectedOptionsArray: value });
  };

  updateFilterProperty = (propertyName, value) => {
    let newFilter = new TextFilter(this.state.textFilter);
    newFilter[propertyName] = value;

    this.setState({
      textFilter: newFilter,
    });
  };

  render() {
    let { textFilter } = this.state;

    let filterTypeOptions = [
      {
        value: TextFilterTypes.EQUALS,
        label: getText("axoEntityidcode150", "Lig med"),
      },
      {
        value: TextFilterTypes.NOTEQUEALS,
        label: getText("axoEntityidcode151", "Ikke lig med"),
      },
      {
        value: TextFilterTypes.STARTSWITH,
        label: getText("axoEntityidcode158", "Starter med"),
      },
      {
        value: TextFilterTypes.ENDSWITH,
        label: getText("axoEntityidcode159", "Slutter med"),
      },
      {
        value: TextFilterTypes.CONTAINS,
        label: getText("axoEntityidcode160", "Indeholder"),
      },
      {
        value: TextFilterTypes.NOTCONTAINS,
        label: getText("axoEntityidcode161", "Indeholder ikke"),
      },
    ];

    let selectedOption = filterTypeOptions.find(
      (f) => f.value === textFilter.filterStringType
    );

    return (
      <Modal show={this.state.showModal} onHide={this.close}>
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
          closeButton
        >
          <Modal.Title>
            <AxoLocal entity="axoEntityidcode117" defaultValue={"Filtrer"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          <div style={{ height: "350px" }}>
            <Picky
              value={this.state.selectedOptionsArray}
              options={this.state.textFilter.options}
              onChange={this.selectMultipleOption}
              open={true}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={350}
              selectAllMode="filtered"
              filterPlaceholder="Søg..."
              placeholder="Ingen valgt"
              selectAllText="Vælg alle"
              allSelectedPlaceholder="%s valgt"
              manySelectedPlaceholder="%s valgt"
            />
          </div>
          <h4>
            <AxoLocal
              entity="axoEntityidcode162"
              defaultValue={"Tekstfilter"}
            />
          </h4>
          <FormGroup
            controlId="filterType"
            bsSize="large"
            className="onToppest"
          >
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="axoEntityidcode156"
                defaultValue={"Filtertype"}
              />
            </ControlLabel>
            <ReactSelect
              name="filterTypeSelect"
              menuPlacement="auto"
              value={selectedOption}
              onChange={(selection) => {
                if (!selection) {
                  return;
                }

                this.updateFilterProperty("filterStringType", selection.value);
              }}
              noOptionsMessage={() => ""}
              options={filterTypeOptions}
            />
          </FormGroup>
          <FormGroup controlId="filterText" bsSize="large">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="axoEntityidcode163"
                defaultValue={"Filter tekst"}
              />
            </ControlLabel>
            <FormControl
              name="text"
              type="text"
              className="borderFormControlfocus"
              value={textFilter.filterString}
              onChange={(event) =>
                this.updateFilterProperty("filterString", event.target.value)
              }
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.onOK}>
                <Icon glyph="icon-fontello-ok-3" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="friendRequestTimelineonApproveRequest"
                  defaultValue={"Godkend"}
                />
              </Button>
              <Button className="Lex-button-2" onClick={this.onCancel}>
                <Icon glyph="icon-fontello-cancel" />
                &nbsp;&nbsp;
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
