import React from "react";
import PresentationApiService from "../services/PresentationApiService";
import { AxoLocal } from "../../utilities/LexUtilities";
import { FormControl, Button } from "react-bootstrap";

export default class ReportSection extends React.PureComponent {
  //Props
  //presentation
  constructor(props) {
    super(props);

    this.state = {
      reporting: false,
      reported: false,
      reason: "",
    };
  }

  onReportPresentation = () => {
    this.setState({ reporting: true });
  };

  onChangeReason = (event) => {
    this.setState({ reason: event.target.value });
  };

  onSubmitReport = () => {
    PresentationApiService.reportPresentation({
      presentationId: this.props.presentation.id,
      subject: "Presentation: " + window.location.href,
      message: this.state.reason,
    }).then((response) => {
      if (response.ok) {
        this.setState({
          reporting: false,
          reported: true,
        });
        setTimeout(() => {
          this.setState({ reported: false });
        }, 5000);
      }
    });
  };

  render() {
    return (
      <div style={{ maxWidth: "300px", margin: "auto", marginBottom: "5px" }}>
        {this.renderBody()}
      </div>
    );
  }

  renderBody = () => {
    var { reporting, reported } = this.state;
    if (reporting) {
      return (
        <div className="text-center">
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <b>
              {" "}
              <AxoLocal entity="presentationMarketing27" defaultValue="Årsag" />
            </b>
          </div>
          <div>
            <FormControl
              type="text"
              value={this.state.reason}
              onChange={this.onChangeReason}
            />
          </div>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Button onClick={this.onSubmitReport} className="Lex-button-1xlink">
              <AxoLocal
                entity="presentationMarketing24"
                defaultValue=" Send anmeldelse"
              />
            </Button>
          </div>
        </div>
      );
    }
    if (reported) {
      return (
        <div
          className="text-center"
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
        >
          <AxoLocal
            key="report2"
            entity="presentationReport25"
            defaultValue="Tak for din anmeldelse. Vores support team vil undersøge sagen."
          />
        </div>
      );
    }
    return (
      <div
        className="text-center"
        style={{ paddingTop: "5px", paddingBottom: "5px" }}
      >
        <Button
          onClick={this.onReportPresentation}
          className="Lex-button-1xlink"
        >
          <AxoLocal
            key="report1"
            entity="presentationReport29"
            defaultValue="Anmeld indlæg."
          />
        </Button>
      </div>
    );
  };
}
