// @ts-check
import React from "react";
import SocialTabView from "./Social/SocialTabView";
import moment from "moment";

import {
  DataStore,
  SocialPostStore,
  DataActions,
  ModalService,
  RoutingService,
} from "../services/AxoServices";

import { AxoLocal, withRouter } from "../utilities/LexUtilities";

class SocialContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      userSettings: {},
      selectedUserProfile: {},
      userProfileArray: [],
      friends: [],
      publicPosts: [],
      internalPosts: [],
      currentUserPosts: [],
      individualPosts: [],
      documentMap: {},
      socialFiles: [],
      privateConversations: [],
      activeTab: SocialContainer.getActiveTab(props),
    };
    this.shownPosts = 10;
    this.timer = 0;
    this.name = "SocialContainer";
  }

  static getActiveTab = (props) => {
    let path = props.location.pathname.toLowerCase();
    if (path.includes("internal")) {
      return "Internal";
    }
    if (path.includes("files")) {
      return "Files";
    }
    if (path.includes("myprofile")) {
      return "MyProfile";
    }
    if (path.includes("profile")) {
      return "Profile";
    }
    if (path.includes("private")) {
      return "Private/" + props.match.params.id;
    }
    return "Public";
  };

  //Reimplement after updating react-router
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     activeTab: SocialContainer.getActiveTab(nextProps)
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    this.setState({
      activeTab: SocialContainer.getActiveTab(nextProps),
    });
  }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      if (store.userProfile.publicRoomActive) {
        SocialPostStore.subscribeToPublicPosts(this.name, (publicPosts) => {
          this.setState({ publicPosts });
        });
      } else {
        this.setState({ publicPosts: [] });
      }
      if (store.userProfile.internalRoomActive) {
        SocialPostStore.subscribeToInternalPosts(this.name, (internalPosts) => {
          this.setState({ internalPosts });
        });
      } else {
        this.setState({ internalPosts: [] });
      }

      SocialPostStore.subscribeToCurrentUserPosts(
        this.name,
        store.userProfile.id,
        (currentUserPosts) => {
          this.setState({ currentUserPosts });
        }
      );

      let selectedUserProfile = store.userProfileArray.find(
        (c) => c.id === store.selectedUserProfileId
      );

      this.setState({
        userProfile: store.userProfile,
        userSettings: store.userSettings,
        userProfileArray: store.userProfileArray,
        selectedUserProfile: selectedUserProfile || {},
        friends: store.friends,
        documentMap: store.documentMap,
        socialFiles: store.documentMap.social,
        showDropdowns: store.showDropdowns,
      });
    });

    SocialPostStore.subscribe(this.name, (store) => {
      this.setState({
        individualPosts: store.individualPosts,
        privateConversations: store.privateConversations.filter(
          (p) => p.onGoing
        ),
      });
    });

    SocialPostStore.setNumberOfShownPosts(this.shownPosts);

    DataStore.initializeUserProfile();
    DataStore.initializeDocuments();
    this.fetchSocialData();
    // this.timer = setInterval(this.fetchSocialData, 30 * 1000);
  }

  fetchSocialData = () => {
    var promises = [];

    promises.push(DataStore.fetchFriends());
    promises.push(SocialPostStore.fetchSocialPosts());
    promises.push(SocialPostStore.fetchPrivateConversations());

    return Promise.all(promises);
  };

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
    SocialPostStore.unsubscribe(this.name);
    SocialPostStore.unsubscribeFromPublicPosts(this.name);
    SocialPostStore.unsubscribeFromInternalPosts(this.name);
    SocialPostStore.unsubscribeFromCurrentUserPosts(this.name);

    // clearInterval(this.timer);
  }

  onCreatePost = (type, privateConversationId, socialPost) => {
    socialPost.type = type;
    socialPost.userProfile = this.state.userProfile;
    if (type === 2) {
      //Private post
      if (this.state.privateConversations.length === 0) {
        return;
      }
      socialPost.privateConversationId = privateConversationId;
    }
    return DataActions.createSocialPost(socialPost);
  };

  onAddComment = (socialPost, comment) => {
    comment.userProfile = this.state.userProfile;
    comment.dateTime = moment().format();
    return DataActions.addCommentToSocialPost(socialPost.id, comment);
  };

  onDeletePost = (socialPostId) => {
    return DataActions.deleteSocialPost(socialPostId);
  };

  onDeleteComment = (commentId) => {
    return DataActions.deleteComment(commentId);
  };

  onAddPrivateConversation = (privateConversation) => {
    return DataActions.createPrivateConversation(privateConversation);
  };

  onDeleteSocialFile = (socialFile) => {
    var userProfile = Object.assign({}, this.state.userProfile);

    if (
      (userProfile.profileImage &&
        userProfile.profileImage.id === socialFile.id) ||
      (userProfile.banner && userProfile.banner.id === socialFile.id)
    ) {
      ModalService.openConfirmModal(
        <AxoLocal
          entity="SocialContainerConfirmuserProfile"
          defaultValue={
            "Billedet anvendes som logo, profilbillede eller banner. Er du sikker på at du vil slette det?"
          }
        />,
        (reply) => {
          if (reply) {
            this.removeImageReference(userProfile, socialFile.id).then(
              (response) => {
                if (response.status === 200) {
                  DataActions.deleteDocument(socialFile.id);
                }
              }
            );
          }
        }
      );
    } else if (socialFile.socialPostId > 0) {
      ModalService.openConfirmModal(
        <AxoLocal
          entity="SocialContainerConfirmdeleteSocialPost"
          defaultValue={
            "Hvis du sletter filen, sletter du også indlægget. Vil du slette fil og indlæg?"
          }
        />,
        (reply) => {
          if (reply) {
            DataActions.deleteSocialPost(socialFile.socialPostId);
          }
        }
      );
    } else if (socialFile.commentId > 0) {
      ModalService.openConfirmModal(
        <AxoLocal
          entity="SocialContainerConfirmdeleteComment"
          defaultValue={
            "Hvis du sletter filen, sletter du også kommentaren. Vil du slette fil og kommentar?"
          }
        />,
        (reply) => {
          if (reply) {
            DataActions.deleteComment(socialFile.commentId);
          }
        }
      );
    } else {
      DataActions.deleteDocument(socialFile.id);
    }
  };

  removeImageReference = (userProfile, documentId) => {
    if (userProfile.banner && userProfile.banner.id === documentId) {
      userProfile.banner = null;
    }
    if (
      userProfile.profileImage &&
      userProfile.profileImage.id === documentId
    ) {
      userProfile.profileImage = null;
    }
    return DataActions.updateUserProfile(userProfile);
  };

  onShowMore = () => {
    this.shownPosts += 10;
    SocialPostStore.setNumberOfShownPosts(this.shownPosts);
    SocialPostStore.fetchSocialPosts();
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(RoutingService.getPath("Social/" + eventKey));
  };

  render() {
    return (
      <div>
        <SocialTabView
          {...this.state}
          params={this.props.match.params}
          onCreatePost={this.onCreatePost}
          onDeletePost={this.onDeletePost}
          onAddComment={this.onAddComment}
          onDeleteComment={this.onDeleteComment}
          onDeleteSocialFile={this.onDeleteSocialFile}
          onAddPrivateConversation={this.onAddPrivateConversation}
          shownPosts={this.shownPosts}
          onShowMore={this.onShowMore}
          onUpdate={this.fetchSocialData}
          handleNavigation={this.handleNavigation}
        />
      </div>
    );
  }
}

export default withRouter(SocialContainer);
