import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import { withRouter } from "react-router-dom";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import moment from "moment";
import TimeEntryService from "../../services/TimeEntryService";
import UserInfoService from "../../services/UserInfoService";
import {
  AxoCheckbox,
  Icon,
  AxoLocal,
  InlineNumberEdit,
  InlineTextArea,
} from "../../utilities/LexUtilities";

import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";

class TimeEntryCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      invoiceSending: false,
    };
  }

  //Props
  //timeEntry
  //onTimeEntryChange
  onTimeEntryPropertyChange = (propertyName, input) => {
    var invoice = Object.assign({}, this.props.invoice);
    invoice[propertyName] = input.value;
    this.props.onInvoiceChange(invoice);
  };

  onTrashTimeEntry = () => {
    var timeEntry = Object.assign({}, this.props.timeEntry);
    timeEntry.trashed = true;
    DataActions.updateTimeEntry(timeEntry);
  };

  onEditButton = () => {
    this.setState({ editing: !this.state.editing });
  };

  shortenString(input, maxChars) {
    if (input.length > maxChars) {
      return input.substring(0, maxChars - 3) + "...";
    }
    return input;
  }

  onChangeAttachment = (input) => {
    var timeEntry = Object.assign({}, this.props.timeEntry);
    timeEntry.attachment = input.value;
    DataActions.updateTimeEntry(timeEntry);
  };

  renderEditForm = () => {
    var timeEntry = this.props.timeEntry;

    return (
      <div>
        <table className="table Lex-Grid-tabl-1">
          <tbody>
            <tr>
              <th
                className="col-md-4"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-calendar-8" />
                &nbsp;&nbsp;
                <AxoLocal entity="axoidcode25" defaultValue={"Dato"} /> :{" "}
              </th>
              <td>
                <input
                  type="date"
                  style={{ borderStyle: "none" }}
                  onChange={this.props.onChangeWorkDate.bind(
                    this,
                    timeEntry.id
                  )}
                  value={moment(timeEntry.workDate).format("YYYY-MM-DD")}
                />
              </td>
            </tr>
            <tr>
              <th
                className="col-md-5"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-edit-2" />
                &nbsp;
                <AxoLocal
                  entity="Enhedspris6"
                  defaultValue={"Beskrivelse"}
                />:{" "}
              </th>
              <td>
                <InlineTextArea
                  value={timeEntry.description || "---"}
                  change={this.props.onDescChange.bind(this, timeEntry.id)}
                />
              </td>
            </tr>
            {timeEntry.units > 0 ? (
              <tr>
                <th
                  className="col-md-4"
                  style={{
                    textAlign: "left",
                    fontSize: "17px",
                    fontWeight: "normal",
                  }}
                >
                  <Icon glyph="icon-fontello-stopwatch-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="InvoiceInvoiceunit"
                    defaultValue={"Antal"}
                  />{" "}
                  :{" "}
                </th>
                <td>
                  <InlineNumberEdit
                    value={timeEntry.units}
                    format={(value) => {
                      return (
                        <div>
                          {value}{" "}
                          {TimeEntryService.getUnitTypeName(timeEntry.unitType)}
                        </div>
                      );
                    }}
                    change={(input) => {
                      if (input.value === "0") {
                        return;
                      }

                      this.props.onChangeProperty(
                        timeEntry.id,
                        "units",
                        input.value
                      );
                    }}
                  />
                </td>
              </tr>
            ) : null}
            {timeEntry.units > 0 ? (
              <tr>
                <th
                  className="col-md-4"
                  style={{
                    textAlign: "left",
                    fontSize: "17px",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  <Icon glyph="icon-fontello-money-2" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="InvoiceInvoicePrisunit"
                    defaultValue={"Pris/enhed"}
                  />{" "}
                  :
                </th>
                <td>
                  <InlineNumberEdit
                    value={timeEntry.rate || 0}
                    format={(value) => {
                      return (
                        <span>
                          {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                          {value.toString()}
                        </span>
                      );
                    }}
                    change={this.props.onChangeRate.bind(this, timeEntry.id)}
                  />
                </td>
              </tr>
            ) : null}
            {timeEntry.units > 0 ? (
              <tr>
                <th
                  className="col-md-4"
                  style={{
                    textAlign: "left",
                    fontSize: "17px",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  <Icon glyph="icon-simple-line-icons-pie-chart" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="invoiPaymentattheSubtotalVAT"
                    defaultValue={"Moms"}
                  />
                  :
                </th>
                <td>
                  <InlineNumberEdit
                    value={timeEntry.tax * 100}
                    format={(value) => {
                      return value.toString() + " %";
                    }}
                    change={this.props.onChangeTax.bind(this, timeEntry.id)}
                  />
                </td>
              </tr>
            ) : null}
            {timeEntry.expenses > 0 ? (
              <tr>
                <th
                  className="col-md-4"
                  style={{
                    textAlign: "left",
                    fontSize: "17px",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  <Icon glyph="icon-simple-line-icons-frame" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="TimeEntryFormExpensesLabel"
                    defaultValue={"Andre udgifter"}
                  />
                  :
                </th>
                <td>
                  <InlineNumberEdit
                    value={timeEntry.expenses}
                    format={(value) => {
                      return (
                        <span>
                          {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                          {value.toString()}
                        </span>
                      );
                    }}
                    change={(input) => {
                      if (input.value === "0") {
                        return;
                      }

                      this.props.onChangeExpenses(timeEntry.id, input);
                    }}
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <th
                className="col-md-4"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                {" "}
                <Icon glyph="icon-fontello-database-2" />
                &nbsp;&nbsp;
                <AxoLocal entity="InvoiceInvoicetotal" defaultValue={"Total"} />
                :
              </th>
              <td>
                {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                {TimeEntryService.getTotalValue(timeEntry)}
              </td>
            </tr>
            <tr>
              <th
                className="col-md-4"
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph="icon-fontello-credit-card" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="InvoiceInvoiceAConto"
                  defaultValue={"A Conto"}
                />
                :
              </th>
              <td>
                <InlineNumberEdit
                  value={timeEntry.aConto}
                  format={(value) => {
                    return (
                      <span>
                        {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                        {value.toString()}
                      </span>
                    );
                  }}
                  change={this.props.onChangeAConto.bind(this, timeEntry.id)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderInformation = () => {
    var timeEntry = this.props.timeEntry;
    var total = TimeEntryService.getTotalValue(timeEntry);

    return (
      <div>
        <table className="table Lex-Grid-tabl">
          <tbody>
            {/* <tr>
                    <th><Icon glyph="icon-fontello-barcode"/>&nbsp;&nbsp;ID-nummer</th>
                    <td>{contact.clientNumber} </td>
                  </tr> */}
            {timeEntry.ownerName ? (
              <tr>
                <th className="col-xs-4">
                  <Icon glyph=" icon-fontello-pencil-alt-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditInlinecaseowner"
                    defaultValue={"Ejer"}
                  />
                  :
                </th>
                <td>{timeEntry.ownerName}</td>
              </tr>
            ) : null}
            {timeEntry.client ? (
              <tr>
                <th className="col-md-4">
                  <Icon glyph="icon-fontello-barcode-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="caseedit"
                    entity="CaseEditFormclients"
                    defaultValue={"Klient"}
                  />
                  :{" "}
                </th>
                <td>{timeEntry.clientString}</td>
              </tr>
            ) : null}
            {timeEntry.caseString ? (
              <tr>
                <th className="col-md-8">
                  <Icon glyph="icon-simple-line-icons-briefcase" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="inboxtable"
                    entity="LabelInboxdocTableCase"
                    defaultValue={"Sag"}
                  />
                  :{" "}
                </th>
                <td>{timeEntry.caseString}</td>
              </tr>
            ) : null}
            <tr>
              <th className="col-md-6">
                <Icon glyph="icon-fontello-calendar-8" />
                &nbsp;&nbsp;{" "}
                <AxoLocal
                  key="timeentries"
                  entity="axoidcode25"
                  defaultValue={"Dato"}
                />
                :
              </th>
              <td>
                <span>&nbsp; {moment(timeEntry.workDate).format("L")}</span>
              </td>
            </tr>
            {timeEntry.description ? (
              <tr>
                <th className="col-md-6">
                  <Icon glyph="icon-fontello-edit-2" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="calendarevent"
                    entity="Enhedspris6"
                    defaultValue={"Beskrivelse"}
                  />
                  :
                </th>
                <td>{timeEntry.description}</td>
              </tr>
            ) : null}
            {timeEntry.units > 0 ? (
              <tr>
                <th className="col-md-4">
                  <Icon glyph="icon-fontello-stopwatch-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="InvoiceInvoiceunit"
                    defaultValue={"Antal"}
                  />{" "}
                  :
                </th>
                <td>
                  <div>
                    {timeEntry.units}{" "}
                    {TimeEntryService.getUnitTypeName(timeEntry.unitType)}
                  </div>
                </td>
              </tr>
            ) : null}
            {timeEntry.units > 0 ? (
              <tr>
                <th className="col-md-4">
                  <Icon glyph="icon-fontello-money-2" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="InvoiceInvoicePrisunit"
                    defaultValue={"Pris/enhed"}
                  />{" "}
                  :
                </th>
                <td>
                  {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                  {timeEntry.rate.toString()}
                </td>
              </tr>
            ) : null}
            {timeEntry.units > 0 ? (
              <tr>
                <th className="col-md-4">
                  <Icon glyph="icon-simple-line-icons-pie-chart" />
                  &nbsp;&nbsp;{" "}
                  <AxoLocal
                    key="vatnr"
                    entity="invoiPaymentattheSubtotalVAT"
                    defaultValue={"Moms"}
                  />
                  :{" "}
                </th>
                <td>{timeEntry.tax * 100 + "%"}</td>
              </tr>
            ) : null}
            {timeEntry.expenses > 0 ? (
              <tr>
                <th className="col-md-4">
                  <Icon glyph="icon-simple-line-icons-frame" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="timeentity"
                    entity="TimeEntryFormExpensesLabel"
                    defaultValue={"Andre udgifter"}
                  />
                  :
                </th>
                <td>
                  {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                  {timeEntry.expenses}
                </td>
              </tr>
            ) : null}
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-database-2" />
                &nbsp;&nbsp;{" "}
                <AxoLocal
                  key="typeentity"
                  entity="TimeEntriesViewValue"
                  defaultValue={"Værdi"}
                />{" "}
                :{" "}
              </th>
              <td>
                {UserInfoService.getCurrency(this.props.userProfile)} {total}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-credit-card" />
                &nbsp;&nbsp;{" "}
                <AxoLocal
                  key="aconto"
                  entity="InvoiceInvoiceAConto"
                  defaultValue={"A Conto"}
                />
                :{" "}
              </th>
              <td>
                {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                {timeEntry.aConto}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-circle-empty" />
                &nbsp;&nbsp;{" "}
                <AxoLocal
                  key="fapiao"
                  entity="TimeEntriesViewbilled"
                  defaultValue={"Faktureret"}
                />
                :{" "}
              </th>
              <td>{timeEntry.invoiceId ? "Ja" : "Nej"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderContent = () => {
    if (this.state.editing) {
      return this.renderEditForm();
    }
    return this.renderInformation();
  };

  getTimeEntryTitle = (timeEntry) => {
    var titleString = "";
    if (timeEntry.clientString) {
      titleString += timeEntry.clientString;
    }
    if (timeEntry.caseString) {
      if (titleString) {
        titleString += " - ";
      }
      titleString += timeEntry.caseString;
    }
    if (!titleString) {
      titleString = moment(timeEntry.workDate).format("L");
    }
    return titleString;
  };

  render() {
    var timeEntry = this.props.timeEntry;
    return (
      <div>
        <div
          style={{
            marginBottom: "25px",
            borderRadius: "0px",
            borderWidth: "1px 1px 1px1px",
            borderStyle: "solid solid solid solid",
            borderColor: "#DCDCDC #DCDCDC #DCDCDC #DCDCDC",
            boxShadow: "10px 10px 9px #ececec",
          }}
        >
          <div>
            <div className="gradient-baggrund">
              <Grid fluid>
                <Row>
                  <Col xs={12} className="editable text-center">
                    <AxoCheckbox
                      onChange={this.props.onTimeEntrySelected.bind(
                        this,
                        timeEntry.id
                      )}
                      className="hidden-print"
                    />{" "}
                    &nbsp;&nbsp;
                    <span role="button" style={{ lineHeight: "50px" }}>
                      {this.shortenString(
                        this.getTimeEntryTitle(timeEntry),
                        30
                      )}
                    </span>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div>
              <Grid fluid>
                <Row>
                  <Col xs={12}>{this.renderContent()}</Col>
                </Row>
              </Grid>
            </div>
            <div className="Lex-CardFooter">
              <Grid fluid>
                <Row>
                  <Col xs={12} style={{ padding: "0px" }}>
                    <ButtonGroup justified>
                      <ButtonGroup>
                        {this.state.editing ? (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon
                              role="button"
                              glyph="icon-fontello-floppy-1"
                            />
                            &nbsp;&nbsp;{" "}
                            <AxoLocal
                              key="Save"
                              entity="CaseGridViewcaseSave"
                              defaultValue={"Gem"}
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon role="button" glyph="icon-fontello-edit-3" />
                            &nbsp;&nbsp;{" "}
                            <AxoLocal
                              key="Edit"
                              entity="CaseGridViewedit"
                              defaultValue={"Rediger"}
                            />
                          </button>
                        )}
                      </ButtonGroup>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.onTrashTimeEntry}
                        >
                          <Icon
                            role="button"
                            glyph="icon-fontello-trash-1"
                          ></Icon>
                          &nbsp;&nbsp;{" "}
                          <AxoLocal
                            entity="axoidcode179"
                            defaultValue={"Slet"}
                          />
                        </button>
                      </ButtonGroup>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class TimeEntryGridView extends React.PureComponent {
  //Props
  //timeEntries
  //onSelectTimeEntry
  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateTimeEntryCols = (timeEntries) => {
    var cols = [[], [], []];
    var smallCols = [[], []];
    for (var i = 0; i < timeEntries.length; i++) {
      var timeEntry = timeEntries[i];

      var timeEntryCard = (
        <TimeEntryCard
          key={timeEntry.id}
          timeEntry={timeEntry}
          userProfile={this.props.userProfile}
          active={this.props.newTimeEntryIds.includes(timeEntry.id)}
          onTimeEntrySelected={this.props.onTimeEntrySelected}
          onTimeEntryChange={this.onTimeEntryChange}
          onChangeWorkDate={this.props.onChangeWorkDate}
          onDescChange={this.props.onDescChange}
          onChangeProperty={this.props.onChangeProperty}
          onChangeTimeInline={this.props.onChangeTimeInline}
          onChangeRate={this.props.onChangeRate}
          onChangeTax={this.props.onChangeTax}
          onChangeExpenses={this.props.onChangeExpenses}
          onChangeAConto={this.props.onChangeAConto}
        />
      );

      cols[i % 3].push(timeEntryCard);
      smallCols[i % 2].push(timeEntryCard);
    }
    return [cols, smallCols];
  };

  renderTimeEntryGrid = (timeEntries) => {
    var cols = this.generateTimeEntryCols(timeEntries);
    var largeCols = cols[0];
    var smallCols = cols[1];
    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col lg={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[0]}
            </Col>
            <Col lg={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[1]}
            </Col>
            <Col lg={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row className="hidden-lg">
            <Col md={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col md={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  onTimeEntryChange = (timeEntry) => {
    DataActions.updateTimeEntry(timeEntry);
  };

  getShownItems = () => {
    return this.props.timeEntries.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    return (
      <Grid fluid style={{ background: "#ffffff" }}>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            {this.renderTimeEntryGrid(this.getShownItems())}
          </Col>
        </Row>
        {this.state.numberShown < this.props.timeEntries.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col
              xs={12}
              className="text-center"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2"
                style={{ width: "75%" }}
              >
                <AxoLocal
                  key="timeregistration"
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />{" "}
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(TimeEntryGridView);
