import React from "react";
import DataListWrapper from "./DataListWrapper";

var SortTypes = {
  ASC: "ASC",
  DESC: "DESC",
};

export default class TableBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.name = "TableBase"; //Name must be defined before fetching columndefs.
    //Do not use the name of the class, since this will be minimized when building for release.

    this.state = {
      tableEntries: [],
      sortedDataList: new DataListWrapper([], []),
      minColWidths: {},
      maxColumnWidth: 250,
      columnDefs: {},
    };
    this.toggleColumns = [];
    this.defaultColumnDefs = {};
  }

  _onSortChange = (columnKey, sortDir, customFunc) => {
    let sortIndexes = this.state.sortedDataList.getIndexMap().slice();
    sortIndexes.sort((indexA, indexB) => {
      let entryA = this.state.sortedDataList._data[indexA];
      let entryB = this.state.sortedDataList._data[indexB];
      let sortVal = 0;
      if (customFunc) {
        sortVal = customFunc(entryA, entryB);
      } else {
        let valueA = entryA[columnKey] || "";
        let valueB = entryB[columnKey] || "";

        //Ensure that numeric string values are sorted numerically
        let isBothIntegers = this.isInteger(valueA) && this.isInteger(valueB);
        if (isBothIntegers) {
          valueA = parseInt(valueA, 10);
          valueB = parseInt(valueB, 10);
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
          sortVal = valueA.localeCompare(valueB, "en", { sensitivity: "base" }); //Case insensitive compare
        } else if (valueA > valueB) {
          sortVal = 1;
        } else if (valueA < valueB) {
          sortVal = -1;
        }
      }
      if (sortVal !== 0 && sortDir === SortTypes.ASC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      sortedDataList: new DataListWrapper(
        sortIndexes,
        this.state.sortedDataList._data
      ),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  };

  isInteger = (value) => {
    return /^\d+$/.test(value);
  };

  //Deprecated
  generateDefaultSortIndexes = (entries) => {
    return TableBase.generateDefaultSortIndices(entries);
  };

  static generateDefaultSortIndices = (entries) => {
    let defaultSortIndexes = [];
    let size = entries.length;
    for (var index = 0; index < size; index++) {
      defaultSortIndexes.push(index);
    }
    return defaultSortIndexes;
  };

  _onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
    var minWidth = this.state.minColWidths[columnKey] || 50;
    var newWidth = Math.max(newColumnWidth, minWidth);
    newWidth = Math.min(newWidth, this.state.maxColumnWidth);

    let { columnDefs } = this.state;
    if (!columnDefs[columnKey]) {
      console.log("Column key: " + columnKey + " could not be found.");
    }

    this.saveColumnDefs({
      ...columnDefs,
      [columnKey]: {
        ...columnDefs[columnKey],
        width: newWidth,
      },
    });
  };

  updateEntries(tableEntries, filteredEntries) {
    let { sortedDataList } = this.state;
    if (filteredEntries.length === sortedDataList.getSize()) {
      this.setState({
        tableEntries: tableEntries,
        sortedDataList: new DataListWrapper(
          sortedDataList._indexMap,
          filteredEntries
        ), //Maintain sort order, but update the entries
      });
      return;
    }
    //Update the sort order when the number of entries changes
    // let defaultSortIndexes = TableBaseSimple.generateDefaultSortIndices(filteredEntries);
    this.setState({
      tableEntries: tableEntries,
      sortedDataList:
        sortedDataList.generateNewWrapperFromData(filteredEntries),
      // sortedDataList: new DataListWrapper(defaultSortIndexes, filteredEntries),
    });
  }

  static getNewEntries(tableEntries, filteredEntries, sortedDataList) {
    if (filteredEntries.length === sortedDataList.getSize()) {
      return {
        tableEntries: tableEntries,
        sortedDataList: new DataListWrapper(
          sortedDataList._indexMap,
          filteredEntries
        ), //Maintain sort order, but update the entries
      };
    }
    //Reset the sort order when the number of entries changes
    let defaultSortIndexes =
      TableBase.generateDefaultSortIndices(filteredEntries);
    return {
      tableEntries: tableEntries,
      sortedDataList: new DataListWrapper(defaultSortIndexes, filteredEntries),
    };
  }

  retrieveColumnDefs() {
    let defaults = { ...this.defaultColumnDefs };

    if (typeof Storage === "undefined") {
      return defaults;
    }

    let columnDefs =
      JSON.parse(localStorage.getItem("columns-" + this.name)) || {};
    let mergedDefs = { ...defaults, ...columnDefs };
    let keys = Object.keys(mergedDefs);
    keys.forEach((key) => {
      if (defaults[key] && columnDefs[key]) {
        mergedDefs[key] = {
          ...defaults[key],
          ...columnDefs[key],
        };
      }
    });
    return mergedDefs;
  }

  saveColumnDefs(columnDefs) {
    this.setState({ columnDefs });

    if (typeof Storage === "undefined") {
      return;
    }

    localStorage.setItem("columns-" + this.name, JSON.stringify(columnDefs));
  }

  onSelectColumn = (name, event) => {
    event.stopPropagation();
    var columnDefs = Object.assign({}, this.state.columnDefs);
    columnDefs[name].shown = !columnDefs[name].shown;
    this.saveColumnDefs(columnDefs);
  };

  getCustomFieldFullName = (fieldName) => {
    return `custom-${fieldName}`;
  };

  onEnableAll = (enable, event) => {
    let { userSettings } = this.props;

    event.stopPropagation();

    let columnNames = this.toggleColumns.concat(
      ((userSettings || {}).customContactFields || []).map((fieldName) => {
        return this.getCustomFieldFullName(fieldName);
      })
    );

    var columnDefs = Object.assign({}, this.state.columnDefs);
    columnNames.forEach((column) => {
      columnDefs[column].shown = enable;
    });

    this.saveColumnDefs(columnDefs);
  };

  render() {
    return <div>Table base must have a subclass.</div>;
  }
}
