import React from "react";
import DataActions from "../services/DataAccess/DataActions";
import DataStore from "../services/DataAccess/DataStore";
import screenfull from "screenfull";
import FlagMenu from "../utilities/FlagMenu";
import getScrollOffSet from "./getScrollOffSet";
import {
  SmallOrSmaller,
  SmallOrLarger,
  MediumOrLarger,
} from "../utilities/Responsive";
import { AuthorizationService } from "../services/AxoServices";

import {
  Row,
  Col,
  Grid,
  MenuItem,
  Button,
  ButtonGroup,
  DropdownButton,
} from "react-bootstrap";

import { Icon, AxoLocal, Link, withRouter } from "../utilities/LexUtilities";

class FrontPageView extends React.PureComponent {
  onClickRoute = (route) => {
    this.props.history.push(route);
    window.scrollTo(0, 0);
  };

  onLogin = () => {
    var userSettings = this.props.userSettings;
    if (userSettings.id) {
      DataActions.logout().then((response) => {
        if (response.ok) {
          DataStore.initializeUserProfile();
          DataStore.initializeUserSettings();
          DataStore.fetchGlobalSettings();
        }
      });
    } else {
      this.props.history.push("/Users/Login");
    }
  };

  onDashboard = () => {
    let { userProfile, history } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);
    if (!userProfile.id) {
      return;
    }
    let role = userType;

    if (role === "Lawyer" || role === "Accountant" || role === "Admin") {
      history.push("/Dashboard");
    } else if (
      role === "Client" ||
      role === "AccountingClient" ||
      role === "SocietyMember" ||
      role === "EasyID"
    ) {
      history.push("/ClientDashboard");
    } else if (role === "XLink") {
      history.push("/Xlink");
    } else if (role === "Society") {
      history.push("/Dashboard");
    }
  };

  onScrollToSolution = (element, event) => {
    event.preventDefault();
    event.stopPropagation();
    var here = this.props.location.pathname.toLowerCase().includes("solutions");
    var scrollElement = document.getElementById(element);
    if (here && scrollElement) {
      //ToDo: Animated scrolling to the top.
      // window.scrollTo(0, 0);
      // $('#' + element).scrollintoview({
      //   duration: 'slow',
      //   // direction: "vertical",
      //   viewPadding: { y: 90 },
      //   complete: function() {
      //       // highlight the element so user's focus gets where it needs to be
      //   }
      // });
      this.onScrollToElement(scrollElement);
    } else {
      let { match } = this.props;

      DataStore.setScrollId(element);
      this.props.history.push(`${match.path}/Solutions`);
    }
  };

  onScrollToPackage = (element, event) => {
    event.preventDefault();
    var scrollElement = document.getElementById(element);
    if (scrollElement) {
      this.onScrollToElement(scrollElement);
    } else {
      let { match } = this.props;

      DataStore.setScrollId(element);
      this.props.history.push(`${match.path}/Packages`);
    }
  };

  onScrollToAbout = (element, event) => {
    event.preventDefault();
    var scrollElement = document.getElementById(element);
    if (scrollElement) {
      this.onScrollToElement(scrollElement);
    } else {
      let { match } = this.props;

      DataStore.setScrollId(element);
      this.props.history.push(`${match.path}/Company`);
    }
  };

  onScrollToElement(element) {
    if (!element) {
      //Can happen if the user navigates away before the scroll is initiated
      return;
    }
    element.scrollIntoView();
    window.scrollBy(0, getScrollOffSet());
  }

  onGoToFront = () => {
    this.props.history.push("/");
    window.scrollTo(0, 0);
  };

  onFullScreenToggle = () => {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
    this.setState({}); //Trigger a rerender to update the full screen button
  };

  renderFullScreenButtonContent = () => {
    if (screenfull.isFullscreen) {
      return (
        <Icon glyph="icon-fontello-resize-small-3" className="Topmenuimg" />
      );
    }
    return <Icon glyph="icon-fontello-resize-full-4" className="Topmenuimg" />;
  };

  renderNavBar = () => {
    var { userSettings, userProfile } = this.props;

    return (
      <div
        className="lex-front-navbar"
        style={{ position: "fixed", zIndex: 100, top: 0, left: 0, right: 0 }}
      >
        <Grid fluid className="front-navbar-ls">
          <SmallOrSmaller>
            <Row>
              <div
                className="btn-group front-navbar btn-group-justified"
                role="group"
              >
                <div className="btn-group" role="group">
                  <Button
                    onClick={this.onGoToFront}
                    className="Lex-button-1 FontPageViewNavButton"
                  >
                    <img
                      alt=""
                      className="Logosize"
                      src="/svg/Axologo1-012.png"
                    />
                    &ensp;
                  </Button>
                </div>
                <div className="btn-group FontPageViewNavButton" role="group">
                  <FlagMenu
                    className="Lex-button-1 FontPageViewNavButton"
                    userSettings={userSettings}
                  />
                </div>
                {userSettings.id ? (
                  <div className="btn-group FontPageViewNavButton" role="group">
                    <Button
                      onClick={this.onDashboard}
                      className="Lex-button-1 FontPageViewNavButton"
                    >
                      <Icon
                        className="frontnavbarimg"
                        glyph="icon-fontello-cog-7"
                      />
                    </Button>
                  </div>
                ) : null}
                <SmallOrLarger>
                  <div
                    className="btn-group FontPageViewNavButton hidden-xs hidden-sm "
                    role="group"
                  >
                    <Button
                      onClick={this.onFullScreenToggle}
                      className="Lex-button-1 FontPageViewNavButton"
                    >
                      {this.renderFullScreenButtonContent()}
                    </Button>
                  </div>
                </SmallOrLarger>
                <div
                  className="btn-group front-navbar"
                  role="group"
                  style={{ borderRadius: "0px" }}
                >
                  <Button
                    onClick={this.onLogin}
                    className="Lex-button-1 FontPageViewNavButton"
                  >
                    {userSettings.id ? (
                      <span>
                        <Icon
                          glyph="icon-fontello-logout-3"
                          className="frontnavbarimg"
                        />
                        &ensp;&ensp;
                        <span className="Loginout hidden-xs hidden-sm hidden-md">
                          <AxoLocal
                            key="logout"
                            entity="FrontPageMenubarAxolexonLogout"
                            defaultValue={"Log ud "}
                          />
                        </span>
                      </span>
                    ) : (
                      <span>
                        <Icon
                          glyph="icon-fontello-off-1"
                          className="frontnavbarimg"
                        />
                        &ensp;&ensp;
                        <span className="Loginout hidden-xs hidden-sm hidden-md">
                          <AxoLocal
                            key="login"
                            entity="LoginonLogin"
                            defaultValue={"Log ind"}
                          />
                        </span>
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </Row>
          </SmallOrSmaller>
          <Row>
            <Col xs={12} sm={12} className="nopadding">
              <ButtonGroup>
                <div className="btn-group btn-group-justified" role="group">
                  <MediumOrLarger>
                    <div className="btn-group" role="group">
                      <Button
                        onClick={this.onGoToFront}
                        className="Lex-button-1 FontPageViewNavButton"
                      >
                        <img
                          alt=""
                          className="Logosize"
                          src="/svg/Axologo1-012.png"
                        />
                        &ensp;{" "}
                        <span>
                          <AxoLocal
                            entity="FrontPageMenubarAxolex"
                            defaultValue={"Axolex"}
                            style={{ fontSize: " 19px" }}
                          />
                        </span>
                      </Button>
                    </div>
                  </MediumOrLarger>
                  <div className="btn-group FontPageViewNavButton" role="group">
                    <DropdownButton
                      id="IT-løsninger"
                      title={
                        <AxoLocal
                          entity="FrontPageviewtext10F"
                          defaultValue={"IT-løsninger"}
                        />
                      }
                      className="Lex-button-1 FontPageViewNavButton"
                    >
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(
                          this,
                          "completePackage"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageviewtext1"
                          defaultValue={"Komplet pakkeløsning"}
                        />{" "}
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(
                          this,
                          "mailsystem"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt9"
                          defaultValue={"Mailsystem"}
                        />{" "}
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(this, "network")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt10"
                          defaultValue={"Fagligt netværk"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(this, "cases")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt12"
                          defaultValue={"Sagsstyringssystem"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(this, "finance")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt13"
                          defaultValue={"Økonomistyring"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(this, "clients")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt15"
                          defaultValue={"Kundedatabehandling"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(
                          this,
                          "selfservice"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt16"
                          defaultValue={"Klient selvbetjeningspanel"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(this, "labels")}
                        className="Lex-DropdownButton-menu"
                      >
                        {" "}
                        <AxoLocal
                          entity="FrontPageviewtext2"
                          defaultValue={"Mappesystem"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(
                          this,
                          "documents"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt18"
                          defaultValue={"Dokumenthåndtering"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(this, "booking")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt19"
                          defaultValue={"Booking system"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(this, "calendar")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt20"
                          defaultValue={"Kalender"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToSolution.bind(this, "payment")}
                        className="Lex-DropdownButton-menu"
                      >
                        {" "}
                        <AxoLocal
                          entity="FrontPageindextaxt14"
                          defaultValue={"Online betalingssystem"}
                        />
                      </MenuItem>
                    </DropdownButton>
                  </div>
                  <div className="btn-group FontPageViewNavButton" role="group">
                    <DropdownButton
                      id="Priser"
                      title={
                        <AxoLocal
                          entity="FrontPageviewtext10D"
                          defaultValue={"Priser"}
                        />
                      }
                      className="Lex-button-1 FontPageViewNavButton"
                    >
                      <MenuItem
                        onClick={this.onScrollToPackage.bind(
                          this,
                          "totalPackage"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageviewtext1"
                          defaultValue={"Komplet pakkeløsning (Multi-sprog)"}
                        />
                      </MenuItem>

                      <MenuItem
                        onClick={this.onScrollToPackage.bind(
                          this,
                          "tailoredPackage"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageviewtext4"
                          defaultValue={"Skræddersyet løsning"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToPackage.bind(
                          this,
                          "individualPackage"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageviewtext5"
                          defaultValue={"Individuel løsning"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToPackage.bind(
                          this,
                          "componentPackage"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageviewtext6"
                          defaultValue={"Komponent"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToPackage.bind(this, "ITSupport")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageviewtext7"
                          defaultValue={"IT-support"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToPackage.bind(
                          this,
                          "partnerPackage"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageviewtext3"
                          defaultValue={"Partner virksomheder"}
                        />
                      </MenuItem>
                    </DropdownButton>
                  </div>
                  <div
                    className="btn-group FontPageViewNavButton"
                    role="group"
                    style={{ marginRight: "8px" }}
                  >
                    <DropdownButton
                      id="Virksomheden"
                      title={
                        <AxoLocal
                          entity="FrontPageviewtext10E"
                          defaultValue={"Virksomheden"}
                        />
                      }
                      className="Lex-button-1 FontPageViewNavButton"
                    >
                      <MenuItem
                        onClick={this.onScrollToAbout.bind(this, "about")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="Companyaboutostaxt0"
                          defaultValue={"Om os"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToAbout.bind(this, "team")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageviewtext9"
                          defaultValue={"Team"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToAbout.bind(
                          this,
                          "aboutBooking"
                        )}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="Companyaboutostaxt23"
                          defaultValue={"Booking"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onClickRoute.bind(this, "/Users/Signup")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageviewtext10C"
                          defaultValue={"Registrering"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToAbout.bind(this, "career")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="FrontPageindextaxt10A"
                          defaultValue={"Karriere"}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={this.onScrollToAbout.bind(this, "contact")}
                        className="Lex-DropdownButton-menu"
                      >
                        <AxoLocal
                          entity="Companyaboutostaxt25"
                          defaultValue={"Kontakt"}
                        />
                      </MenuItem>
                      {userProfile.id ? (
                        <MenuItem
                          onClick={this.onDashboard}
                          className="Lex-DropdownButton-menu"
                        >
                          <AxoLocal
                            entity="FrontPageviewtext10G"
                            defaultValue={"Kontrolpanel"}
                          />
                        </MenuItem>
                      ) : null}
                    </DropdownButton>
                  </div>
                  <MediumOrLarger>
                    <div
                      className="btn-group front-navbar btn-group-justified"
                      role="group"
                    >
                      <div
                        className="btn-group FontPageViewNavButton"
                        role="group"
                      >
                        <FlagMenu
                          className="Lex-button-1 FontPageViewNavButton"
                          userSettings={this.props.userSettings}
                        />
                      </div>
                      {userSettings.id ? (
                        <div
                          className="btn-group FontPageViewNavButton"
                          role="group"
                        >
                          <Button
                            onClick={this.onDashboard}
                            className="Lex-button-1 FontPageViewNavButton "
                          >
                            <Icon
                              glyph="icon-fontello-cog-7"
                              style={{ fontSize: "25px" }}
                            />
                          </Button>
                        </div>
                      ) : null}
                      <SmallOrLarger>
                        <div
                          className="btn-group FontPageViewNavButton hidden-xs hidden-sm"
                          role="group"
                        >
                          <Button
                            onClick={this.onFullScreenToggle}
                            className="Lex-button-1 FontPageViewNavButton"
                          >
                            {this.renderFullScreenButtonContent()}
                          </Button>
                        </div>
                      </SmallOrLarger>
                      <div
                        className="btn-group front-navbar"
                        role="group"
                        style={{ borderRadius: "0px" }}
                      >
                        <Button
                          onClick={this.onLogin}
                          className="Lex-button-1 FontPageViewNavButton"
                        >
                          {userSettings.id ? (
                            <span>
                              <Icon
                                glyph="icon-fontello-logout-3"
                                style={{ fontSize: " 25px" }}
                              />
                              &ensp;&ensp;
                              <span className="Loginout hidden-xs hidden-sm hidden-md">
                                <AxoLocal
                                  key="logout"
                                  entity="FrontPageMenubarAxolexonLogout"
                                  defaultValue={"Log ud"}
                                />
                              </span>
                            </span>
                          ) : (
                            <span>
                              <Icon
                                glyph="icon-fontello-off-1"
                                style={{ fontSize: " 25px" }}
                              />
                              &ensp;&ensp;
                              <span className="Loginout hidden-xs hidden-sm hidden-md">
                                <AxoLocal
                                  key="login"
                                  entity="LoginonLogin"
                                  defaultValue={"Log ind "}
                                />
                              </span>
                            </span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </MediumOrLarger>
                </div>
              </ButtonGroup>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindextaxt10B"
                  defaultValue={"Axolex --- One stop Service"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row>
            <Col sm={10} smOffset={1} xs={12} className="text-center nopadding">
              <Col sm={4} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px", marginLeft: "10px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="FrontPageindextaxt11"
                        defaultValue={"Find os på"}
                      />{" "}
                    </h3>
                  </div>
                  <div>
                    <a
                      href="https://www.facebook.com/Axolex.dk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-facebook-squared"
                        style={{ fontSize: " 30px" }}
                      />{" "}
                      <AxoLocal
                        entity="ContactCreateViewFacebook"
                        defaultValue={"Facebook"}
                      />
                    </a>
                  </div>
                  {/* <div><span><Icon className='axolexwebsideicon' glyph='icon-fontello-linkedin-squared' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactCreateViewLinkedIn'defaultValue={'LinkedIn'}/></span></div> */}
                  <div>
                    {" "}
                    <a
                      href="https://twitter.com/AxolexApS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-twitter-squared"
                        style={{ fontSize: " 30px" }}
                      />{" "}
                      <AxoLocal
                        entity="ContactCreateViewTwitter"
                        defaultValue={"Twitter"}
                      />{" "}
                    </a>
                  </div>
                  {/* <div> <span><Icon className='axolexwebsideicon' glyph='icon-fontello-chat-1' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactWeChat'defaultValue={'WeChat'}/> </span></div> */}
                  <div style={{ fontSize: "22px" }}>© &ensp;&ensp;&ensp; </div>
                  <div className="text-left " role="button">
                    <div onClick={this.onGoToFront}>
                      <img
                        alt=""
                        height="200px"
                        width="200px"
                        src="/svg/Axologo1-014.png"
                      />
                    </div>
                  </div>
                  <div>
                    {/* <LexButton onClick={() => { this.props.history.push('freeware')}}>
                    <AxoLocal entity='axolexQRcode17' defaultValue={'Benyt vores freeware produkter'}/>
                    </LexButton> */}
                  </div>
                </div>
              </Col>
              <Col sm={3} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="FrontPageviewtext11"
                        defaultValue={"Vi tilbyder"}
                      />{" "}
                    </h3>
                  </div>
                  <div>
                    <a
                      href="completePackage"
                      onClick={this.onScrollToSolution.bind(
                        this,
                        "completePackage"
                      )}
                    >
                      <AxoLocal
                        entity="FrontPageviewtext1"
                        defaultValue={"Komplet pakkeløsning"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="mailsystem"
                      onClick={this.onScrollToSolution.bind(this, "mailsystem")}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt9"
                        defaultValue={"Mailsystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="network"
                      onClick={this.onScrollToSolution.bind(this, "network")}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt10"
                        defaultValue={"Socialt medie system"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="cases"
                      onClick={this.onScrollToSolution.bind(this, "cases")}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt12"
                        defaultValue={"Sagsstyringssystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="finance"
                      onClick={this.onScrollToSolution.bind(this, "finance")}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt13"
                        defaultValue={"Økonomistyring"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="clients"
                      onClick={this.onScrollToSolution.bind(this, "clients")}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt15"
                        defaultValue={"Kundedatabehandling"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="selfservice"
                      onClick={this.onScrollToSolution.bind(
                        this,
                        "selfservice"
                      )}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt16"
                        defaultValue={"Klient selvbetjeningspanel"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="labels"
                      onClick={this.onScrollToSolution.bind(this, "labels")}
                    >
                      <AxoLocal
                        entity="FrontPageviewtext2"
                        defaultValue={"Mappesystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="documents"
                      onClick={this.onScrollToSolution.bind(this, "documents")}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt18"
                        defaultValue={"Dokumenthåndtering"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="booking"
                      onClick={this.onScrollToSolution.bind(this, "booking")}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt19"
                        defaultValue={"Booking system"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="calendar"
                      onClick={this.onScrollToSolution.bind(this, "calendar")}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt20"
                        defaultValue={"Kalender"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="payment"
                      onClick={this.onScrollToSolution.bind(this, "payment")}
                    >
                      <AxoLocal
                        entity="FrontPageindextaxt14"
                        defaultValue={"Online betalingssystem"}
                      />
                    </a>
                  </div>
                </div>
              </Col>
              <Col sm={3} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="FrontPageviewtext12"
                        defaultValue={"Du kan vælge"}
                      />
                    </h3>
                  </div>
                  <div>
                    <a
                      href="totalPackage"
                      onClick={this.onScrollToPackage.bind(
                        this,
                        "totalPackage"
                      )}
                    >
                      <AxoLocal
                        entity="FrontPageviewtext1"
                        defaultValue={"Komplet pakkeløsning"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="tailoredPackage"
                      onClick={this.onScrollToPackage.bind(
                        this,
                        "tailoredPackage"
                      )}
                    >
                      <AxoLocal
                        entity="FrontPageviewtext4"
                        defaultValue={"Skræddersyet løsning"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="individualPackage"
                      onClick={this.onScrollToPackage.bind(
                        this,
                        "individualPackage"
                      )}
                    >
                      <AxoLocal
                        entity="FrontPageviewtext5"
                        defaultValue={"Individuel løsning"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="componentPackage"
                      onClick={this.onScrollToPackage.bind(
                        this,
                        "componentPackage"
                      )}
                    >
                      <AxoLocal
                        entity="FrontPageviewtext13"
                        defaultValue={"IT komponent"}
                      />{" "}
                    </a>
                  </div>
                  <div>
                    <a
                      href="partnerPackage"
                      onClick={this.onScrollToPackage.bind(
                        this,
                        "partnerPackage"
                      )}
                    >
                      <AxoLocal
                        entity="FrontPageviewtext3"
                        defaultValue={"Partner virksomheder"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="ITSupport"
                      onClick={this.onScrollToPackage.bind(this, "ITSupport")}
                    >
                      <AxoLocal
                        entity="IndividualPackagetext183F"
                        defaultValue={"IT Support"}
                      />
                    </a>
                  </div>
                  <div style={{ paddingBottom: "6px" }}>
                    <a
                      href="Booking"
                      onClick={this.onScrollToAbout.bind(this, "aboutBooking")}
                    >
                      <AxoLocal
                        entity="BookingContainerconfirmedMailsubject"
                        defaultValue={"Booking"}
                      />
                    </a>
                  </div>
                  <div onClick={this.onGoToFront}>
                    <img alt="" className="QRcodesize" src="/svg/Axolex.png" />
                    &ensp;
                  </div>
                </div>
              </Col>
              <Col sm={2} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      <AxoLocal
                        entity="FrontPageviewtext12A"
                        defaultValue={"Axolex"}
                      />
                    </h3>
                  </div>
                  <div>
                    <a
                      href="about"
                      onClick={this.onScrollToAbout.bind(this, "about")}
                    >
                      {" "}
                      <AxoLocal
                        entity="Companyaboutostaxt0"
                        defaultValue={"Om os"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="team"
                      onClick={this.onScrollToAbout.bind(this, "team")}
                    >
                      <AxoLocal
                        entity="FrontPageviewtext9"
                        defaultValue={"Team"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="Signup"
                      onClick={this.onClickRoute.bind(this, "/Users/Signup")}
                    >
                      <AxoLocal
                        entity="FrontPageviewtext10"
                        defaultValue={"Registrering"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="career"
                      onClick={this.onScrollToAbout.bind(this, "career")}
                    >
                      <AxoLocal
                        entity="Companyaboutostaxt19"
                        defaultValue={"Karriere"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="contact"
                      onClick={this.onScrollToAbout.bind(this, "contact")}
                    >
                      <AxoLocal
                        entity="Companyaboutostaxt25"
                        defaultValue={"Kontakt"}
                      />
                    </a>
                    &ensp;:&ensp;
                    <div>
                      <a
                        href="Users"
                        onClick={this.onClickRoute.bind(this, "/Users/Login")}
                      >
                        <AxoLocal
                          entity="LoginonLogin"
                          defaultValue={"Log ind"}
                        />
                      </a>
                    </div>
                    <AxoLocal
                      entity="Companyaboutostaxt11"
                      defaultValue={"Axolex ApS"}
                    />
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
                    :
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    Strandvejen 60
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    2900 Hellerup
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    Denmark
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    CVR: 39076969
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />
                    :53780153
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <AxoLocal
                      entity="CaseEditFormeMail"
                      defaultValue={"Email"}
                    />
                    :{" "}
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:info@axolex.com">info@axolex.com</a>{" "}
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:it@axolex.com">it@axolex.com</a>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:legal@axolex.com">legal@axolex.com</a>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:support@axolex.com">support@axolex.com</a>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:job@axolex.com">job@axolex.com</a>{" "}
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>

        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="image-banner">
                <div className="banner-container">
                  <div className="banner-item">
                    <MediumOrLarger>
                      <img
                        alt=""
                        className="img-responsive bg_img"
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex3-lg.jpg"
                      />
                    </MediumOrLarger>
                    <SmallOrSmaller>
                      <img
                        alt=""
                        className="img-responsive bg_img"
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex3-xs.jpg"
                      />
                    </SmallOrSmaller>
                    <div className="text_wrapper">
                      <div
                        className="text_position chbannetaxt"
                        style={{ Color: "white" }}
                      >
                        <h2 className="item--title">
                          <AxoLocal
                            entity="Companyaboutostaxt11"
                            defaultValue={"Axolex"}
                          />
                        </h2>
                        <p className="item--text">
                          <AxoLocal
                            entity="Companyaboutostaxt12"
                            defaultValue={" Komplet juridisk IT-løsning"}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };

  goToFront = () => {
    this.props.history.push("/");
  };

  renderBottomFooter = () => {
    return (
      <SmallOrSmaller>
        <Grid
          fluid
          className="axolextextbanne Bannecolor"
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <Row
            className="text-left"
            style={{ height: "40px", lineHeight: "40px" }}
          >
            <div
              style={{
                Color: "black",
                fontSize: "13px",
                fontWeight: "normal",
                paddingLeft: "10px",
              }}
            >
              <Link to="/" onClick={this.goToFront}>
                <AxoLocal
                  entity="Companyaboutostaxt11"
                  defaultValue={"Axolex ApS"}
                />
                &nbsp; &#9658;&nbsp;
              </Link>
              <AxoLocal
                entity="FrontPageindextaxt1"
                defaultValue={"Creating new standards"}
              />
              &nbsp;&#9658;&nbsp;
              <AxoLocal
                onClick={this.goToFront}
                entity="FrontPageindextaxt2"
                defaultValue={"Juridisk management software"}
              />
            </div>
          </Row>
        </Grid>
      </SmallOrSmaller>
    );
  };

  render() {
    return (
      <div className="axoFrontPage" style={{ paddingBottom: "13px" }}>
        {this.renderNavBar()}
        <div className="top-margin-box"></div>
        {this.props.children}
        {this.renderFooter()}
        {this.renderBottomFooter()}
      </div>
    );
  }
}

export default withRouter(FrontPageView);
