// @ts-check
import React from "react";

import UserProfileForm from "../../common/sidebars/UserProfileForm";
import SubscriptionView from "../../common/sidebars/SubscriptionView";
import LicenseConsentForm from "../../Login/Licenses/LicenseConsentForm";
import DataConsentForm from "../../Login/Licenses/DataConsentForm";
import PurchaseTermsSubPanel from "../../Login/Licenses/PurchaseTermsSubPanel";

import { Col, Panel, PanelGroup } from "react-bootstrap";

import { Icon, AxoLocal } from "../../utilities/LexUtilities";

function renderAccordionHeader(title) {
  return (
    <div>
      <Col xs={11} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

export default class SetupView extends React.PureComponent {
  render() {
    let {
      userSubscription,
      storageInformation,
      userProfile,
      userGroup,
      userSettings,
      subscriptionPlans,
      groupMembers,
      getGroupMembers,
      clearGroupMembers,
      removeGroupMember,
      documentMap,
      libraryFileNames,
      globalSettings,
      theme,
      locale,
      paymentRequests,
      fetchRequests,
    } = this.props;

    return (
      <div className="standardMaxWidth leftPadding">
        <PanelGroup
          id="Frontpagea"
          accordion
          className="Lex-Accordion"
          defaultActiveKey="0"
          style={{
            borderRadius: "0px",
            paddingTop: "0px",
            borderBottom: "1px solid white",
          }}
        >
          <Panel className="backgroundlineargradient3" eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                {renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-fontello-cog-7" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="SubscriptionViewuserProfileSubscription"
                      defaultValue={"Abonnement"}
                    />
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <SubscriptionView
                userSubscription={userSubscription}
                paymentRequests={paymentRequests}
                storageInformation={storageInformation}
                userProfile={userProfile}
                userGroup={userGroup}
                userSettings={userSettings}
                subscriptionPlans={subscriptionPlans}
                groupMembers={groupMembers}
                getGroupMembers={getGroupMembers}
                clearGroupMembers={clearGroupMembers}
                removeGroupMember={removeGroupMember}
                fetchRequests={fetchRequests}
              />
            </Panel.Body>
          </Panel>
        </PanelGroup>
        <PanelGroup
          id="Frontpagec"
          accordion
          className="Lex-Accordion"
          defaultActiveKey="0"
          style={{
            borderRadius: "0px",
            paddingTop: "0px",
            borderBottom: "1px solid white",
          }}
        >
          <Panel className="backgroundlineargradient3" eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                {renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-fontello-cog-7" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="SidebarRightContainerUserProfileEditing"
                      defaultValue={"Brugerprofil - redigering"}
                    />
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <UserProfileForm
                userProfile={userProfile}
                userSettings={userSettings}
                documentMap={documentMap}
                libraryFileNames={libraryFileNames}
                globalSettings={globalSettings}
                theme={theme}
              />
            </Panel.Body>
          </Panel>
        </PanelGroup>
        <PurchaseTermsSubPanel locale={locale} />
        <LicenseConsentForm locale={locale} hideControls />
        <DataConsentForm locale={locale} hideControls />
      </div>
    );
  }
}
