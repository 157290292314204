import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// import {
//   PrintService
// } from '../../services/AxoServices'

import { LexButton, AxoLocal } from "../../utilities/LexUtilities";

import { ButtonToolbar } from "react-bootstrap";

const ListItem = (props) => {
  return <div style={{ paddingBottom: "5px" }}>{props.children}</div>;
};

export default class ManagementContractlList extends React.PureComponent {
  static propTypes = {
    model: PropTypes.object.isRequired,
  };

  onPrint = () => {
    window.print();
    // if (typeof window !== 'undefined') {
    //   PrintService.printElement(document.getElementById('printList'));
    // }
  };

  render() {
    let { model, onEdit } = this.props;

    return (
      <div className="leftPadding topPadding standardMaxWidth">
        <div className="hidden-print">
          <ButtonToolbar>
            <LexButton onClick={onEdit}>
              <AxoLocal
                entity="presentationMarketing30"
                defaultValue="Rediger"
              />{" "}
            </LexButton>
            <LexButton onClick={this.onPrint}>
              <AxoLocal
                entity="PrintCaseClientadressePrint"
                defaultValue={"Print"}
              />
            </LexButton>
          </ButtonToolbar>
        </div>
        <div id="printList">
          <div>
            <h2>Direktørkontrakt</h2>
          </div>
          <ListItem>
            <h4>Mellem undertegnede</h4>
          </ListItem>
          <div>
            <strong>{model.employerName}</strong>
          </div>
          <div>{model.employerAddress}</div>
          <div>
            {model.employerPostalCode} {model.employerCity}
          </div>
          <ListItem>herefter kaldet ”Virksomheden”</ListItem>
          <ListItem>
            <h4>Og:</h4>
          </ListItem>
          <div>
            <strong>{model.workerName}</strong>
          </div>
          <div>{model.workerAddress}</div>
          <div>
            {model.workerPostalCode} {model.workerCity}
          </div>
          <ListItem>herefter kaldet ”Direktøren”</ListItem>

          <ListItem>
            <strong>1. Indledning</strong>
          </ListItem>
          <ListItem>
            Direktøren er forpligtet til altid at holde Virksomheden underrettet
            om ændringer i ovenstående personlige oplysninger.
          </ListItem>
          <ListItem>
            Der er indgået aftale om ansættelse på følgende vilkår:
          </ListItem>
          <ListItem>
            <strong>2. Arbejdssted</strong>
          </ListItem>
          <ListItem>Direktørens arbejdssted er på selskabets adresse:</ListItem>
          <ListItem>
            Direktørens kan udføre arbejdet hjemmefra, i den udstrækning det er
            foreneligt med virksomhedens drift og Direktørens ønske.
          </ListItem>
          <ListItem>{model.workPlaceAddress}</ListItem>
          <ListItem>
            <strong>2. Ansættelses- og ansvarsområdet</strong>
          </ListItem>
          <ListItem>
            Direktøren har under iagttagelse af lovgivningens regler og under
            ansvar overfor bestyrelsen til opgave at varetage Selskabets samlede
            virksomhed. Direktøren har den daglige ledelse og refererer i
            forbindelse hermed til bestyrelsen for Selskabet.
          </ListItem>
          <ListItem>
            Direktøren ansætter og afskediger Selskabets personale og fastsætter
            personalets arbejdsområder og beføjelser.
          </ListItem>
          <ListItem>
            Direktøren anmeldes til Erhvervs- og Selskabsstyrelsen.
          </ListItem>
          <ListItem>
            Direktørens deltagelse og honorering som bestyrelsesmedlem og/eller
            direktør i de af Selskabet ejede nuværende som fremtidige
            datterselskaber aftales særskilt.
          </ListItem>
          <ListItem>
            <strong>3. Direktørens funktion mv.</strong>
          </ListItem>
          <ListItem>
            Direktøren er forpligtet til at anvende sin fulde arbejdskraft og
            alle sine kundskaber i Selskabets tjeneste og varetage Selskabets
            tarv på bedste måde. Ansættelsen sker på fuld tid, og Direktøren er
            gjort opmærksom på, at stillingen i perioder kan kræve en væsentlig
            arbejdsindsats uden for sædvanlig arbejdstid, og at arbejdet i
            øvrigt må indrettes efter Selskabets behov.
          </ListItem>
          <div className="footerpagebreak"></div>
          <div className="printTopPadding"></div>
          <ListItem>
            Direktøren må ikke uden Selskabets skriftlige samtykke have andet
            lønnet arbejde/lønnet hverv. Ved ulønnede hverv, der kan være
            uforenelige med Direktørens stilling, skal bestyrelsens skriftlige
            samtykke ligeledes indhentes.
          </ListItem>
          <ListItem>
            <strong>4. Forholdet til bestyrelsen</strong>
          </ListItem>
          <ListItem>
            Såfremt selskabet ansætter en bestyrelse, gælder følgende
            forpligtelser for direktøren:
          </ListItem>
          <ListItem>
            Direktøren er over for bestyrelsen ansvarlig for, at Selskabets
            virksomhed foregår i overensstemmelse med dettes forskrifter og i
            overensstemmelse med Selskabets vedtægter og lovgivningen.
          </ListItem>
          <ListItem>
            Alle spørgsmål af ekstraordinær karakter eller af stor betydning
            skal forelægges bestyrelsen, eventuelt dennes formand.
          </ListItem>
          <ListItem>
            Det påhviler i øvrigt Direktøren at holde Selskabets bestyrelse
            underrettet om alle forhold vedrørende Selskabet, som må antages at
            være af interesse for bestyrelsen.
          </ListItem>
          <ListItem>
            Direktøren har ret til at være til stede og udtale sig ved
            bestyrelsens møder, medmindre bestyrelsen i det enkelte tilfælde
            træffer anden beslutning.
          </ListItem>

          <ListItem>
            <strong>
              5. Erhvervshemmeligheder, tavshedspligt og opfindelser
            </strong>
          </ListItem>
          <ListItem>
            Direktøren er særligt gjort bekendt med markedsføringslovens §19,
            der omhandler erhvervshemmeligheder og tekniske tegninger.
          </ListItem>
          <ListItem>
            Direktøren har tavshedspligt med hensyn til alt, hvad han erfarer i
            forbindelse med udførelsen af sit arbejde som direktør, medmindre
            der er tale om forhold, der ifølge sagens natur skal bringes til
            tredjemands kundskab. Denne tavshedspligt er også gældende efter, at
            Direktøren er fratrådt sin stilling i Selskabet.
          </ListItem>
          <ListItem>
            Når Direktøren fratræder sin stilling – uanset årsag – skal alt
            materiale, der tilhører Selskabet eller noget datterselskab, og som
            er i Direktørens besiddelse, afleveres til Selskabet.{" "}
          </ListItem>
          <ListItem>
            Med hensyn til opfindelser, som Direktøren måtte gøre som led i sin
            ansættelse, er det aftalt, at Lov om arbejdstageres opfindelser
            finder anvendelse.
          </ListItem>

          <ListItem>
            <strong>6. Tiltrædelsestidspunkt</strong>
          </ListItem>
          <ListItem>Ansættelsesforholdet påbegyndes den :</ListItem>
          <ListItem>{moment.utc(model.startDate).format("L")}</ListItem>
          <ListItem>
            <strong>7. Vederlag</strong>
          </ListItem>
          <ListItem>
            Lønnen er fastsat til {model.salary}kr. per måned, som udbetales
            bagud den sidste bankdag i måneden.
          </ListItem>
          <ListItem>
            Direktørens vederlag forhandles hvert år i januar måned, første gang
            i januar i ansættelsesåret.
          </ListItem>
          <ListItem>
            Der er ved fastsættelsen af nævnte løn taget højde for forekommende
            overarbejde.
          </ListItem>
          <ListItem>Overarbejde kan også afspadseres efter aftale.</ListItem>
          <ListItem>
            Direktøren må forvente, at der i periode kan forekomme overarbejde
            som ikke honoreres særskilt.
          </ListItem>

          <div className="footerpagebreak"></div>
          <div className="printTopPadding"></div>
          <ListItem>
            <strong>8. Sygdom</strong>
          </ListItem>
          <ListItem>
            Direktørens sygdom er ikke at regne som misligholdelse af denne
            kontrakt, og Direktøren er berettiget til fuld løn under sygdom.
          </ListItem>
          <ListItem>
            <strong>9. Rejser og repræsentation</strong>
          </ListItem>
          <ListItem>
            Direktørens rejseudgifter i forbindelse med rejser og repræsentation
            i Selskabets interesse refunderes af Selskabet efter regning eller i
            henhold til aftale.
          </ListItem>
          <ListItem>
            Direktøren er berettiget til at få udbetalt acontobeløb til dækning
            af udgifter til rejser og repræsentation.
          </ListItem>
          <ListItem>
            Deltager Direktørens ægtefælle efter bestyrelsens anmodning i
            rejser, refunderes også udgifter hertil.
          </ListItem>
          <ListItem>
            <strong>10. Efteruddannelse</strong>
          </ListItem>
          <ListItem>
            Direktøren er berettiget til en, i forhold til sin stilling,
            passende efteruddannelse, der betales af Selskabet. Direktøren
            planlægger selv sin efteruddannelse og underretter bestyrelsens
            formand herom.
          </ListItem>
          <ListItem>
            <strong>11. Ferie</strong>
          </ListItem>
          <ListItem>
            Direktøren er berettiget til ferie med løn i 5 uger pr kalenderår.
            Selskabet betaler et ferietillæg på {model.holidayBonusPercent} pct.
            af den løn, der er indtjent i Selskabet i det foregående
            optjeningsår.{" "}
          </ListItem>
          <ListItem>
            Direktøren bestemmer selv, under hensyntagen til Selskabets tarv,
            tidspunktet for ferieafholdelse og underretter bestyrelsesformanden
            herom.
          </ListItem>
          <ListItem>
            Ved fratræden tilkommer der Direktøren 12.5 % feriegodtgørelse.
            Feriegodtgørelsen udbetales kontant ved fratræden sammen med den
            sidste lønudbetaling.
          </ListItem>
          <ListItem>
            <strong>12. Opsigelse</strong>
          </ListItem>
          <ListItem>
            Ansættelsesforholdet kan af Selskabet opsiges skriftligt med 3
            måneders varsel og fra Direktørens side skriftligt med 3 måneders
            varsel til udgangen af en måned. Fritstilles direktøren er denne
            berettiget til sit fulde vederlag uden modregning.
          </ListItem>
          <ListItem>
            Opsiger Selskabet uden dette skyldes misligholdelse af kontrakten,
            eller opsiger Direktøren kontrakten grundet Selskabets
            misligholdelse, har Direktøren ret til en kontant
            fratrædelsesgodtgørelse svarende til 12 måneders løn inkl. pension.
            Godtgørelsen udbetales som en engangssum i forbindelse med sidste
            lønudbetaling.
          </ListItem>
          <div className="footerpagebreak"></div>
          <div className="printTopPadding"></div>
          <ListItem>
            <strong>13. Misligholdelse</strong>
          </ListItem>
          <ListItem>
            Hvis en af parterne groft misligholder sine forpligtelser efter
            nærværende kontrakt, kan den anden part ophæve kontrakten uden
            varsel eller opsige den til et vilkårligt fastsat tidspunkt.
          </ListItem>
          <ListItem>
            Direktøren har, hvis ophævelsen eller opsigelsen skyldes Direktørens
            grove misligholdelse, kun krav på vederlag indtil
            fratrædelsestidspunktet.
          </ListItem>

          <ListItem>
            <strong>14. Øvrige bestemmelser</strong>
          </ListItem>
          <ListItem>
            Hvor intet andet er aftalt gælder Funktionærlovens bestemmelser.
          </ListItem>
          <ListItem>
            <strong>15. Underskrift og godkendelse</strong>
          </ListItem>
          <ListItem>
            Ansættelseskontrakten er udarbejdet i 2 ens eksemplarer. Hver af
            parterne modtager et originalt dokument.{" "}
          </ListItem>
          <ListItem>
            Begge parter er indforstået med de anførte ansættelsesvilkår.
          </ListItem>
          <ListItem>{model.other}</ListItem>
          <br />
          {/* <ListItem>{moment.utc(model.contractDate).format('L')}</ListItem>
        <br/> */}
          <ListItem></ListItem>
          <ListItem>Dato og sted:</ListItem>
          <ListItem></ListItem>
          <ListItem>___________________________</ListItem>
          <ListItem>{model.employerName}</ListItem>
          <br />
          <ListItem>Dato og sted:</ListItem>
          <ListItem></ListItem>
          <ListItem>___________________________</ListItem>
          <ListItem>{model.workerName} </ListItem>
        </div>
      </div>
    );
  }
}
