import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// import {
//   PrintService
// } from '../../services/AxoServices'

import { LexButton, AxoLocal } from "../../utilities/LexUtilities";

import { ButtonToolbar, Col } from "react-bootstrap";

export default class PowerOfAttorneyAlcoholList extends React.PureComponent {
  static propTypes = {
    model: PropTypes.object.isRequired,
  };
  onPrint = () => {
    window.print();
    // if (typeof window !== 'undefined') {
    //   PrintService.printElement(document.getElementById('printList'));
    // }
  };
  // onPrint = () => {
  //   if (typeof window !== 'undefined') {
  //     PrintService.printElement(document.getElementById('printList'));
  //   }
  // }

  render() {
    let { model, onEdit } = this.props;

    return (
      <div id="printList">
        <div className="hidden-print">
          <ButtonToolbar>
            <LexButton onClick={onEdit}>
              <AxoLocal
                entity="presentationMarketing30"
                defaultValue="Rediger"
              />{" "}
            </LexButton>
            <LexButton onClick={this.onPrint}>
              <AxoLocal
                entity="PrintCaseClientadressePrint"
                defaultValue={"Print"}
              />
            </LexButton>
          </ButtonToolbar>
        </div>

        <div className="container pageSection" id="printList">
          <div className="row">
            <Col xs={9}>
              <div>
                <div className="text-center">
                  <h1>Fuldmagt</h1>
                </div>
                <div>
                  <strong>
                    <h3>Undertegnede</h3>
                  </strong>
                </div>
                <div style={{ paddingLeft: "10%" }}>
                  <strong> {model.applicantsName} </strong>
                </div>
                <div style={{ paddingLeft: "10%" }}>
                  <strong>{moment(model.dateOfBirth).format("L")}</strong>
                </div>
                <div style={{ paddingLeft: "10%" }}>
                  <strong>{model.applicantsAddress} </strong>
                </div>
                <div style={{ paddingLeft: "10%" }}>
                  <strong>ID:{model.personalIDorCPR} </strong>
                </div>
                <div>
                  <strong>
                    <h3>giver hermed</h3>
                  </strong>
                </div>
                <div style={{ paddingLeft: "10%" }}>
                  <strong> {model.attorneyName} </strong>
                </div>
                <div style={{ paddingLeft: "10%" }}>
                  <strong> {model.companyName} </strong>
                </div>
                <div style={{ paddingLeft: "10%" }}>
                  <strong> {model.addressAttorney} </strong>
                </div>
                <div style={{ paddingLeft: "10%" }}>
                  <strong>CVR/CPR:{model.cVRorCPRAttorney} </strong>
                </div>
                <div>
                  fuldmagt til at behandle alle relevante forhold i forbindelse
                  med sagens forløb og varetage mine interesser. {model.text}{" "}
                </div>
                <div>
                  Det betyder, at fuldmagtshaveren blandt andet får ret til:
                </div>
                <div style={{ paddingLeft: "5%" }}>
                  til at behandle alle relevante doumenter.
                </div>
                <div style={{ paddingLeft: "5%" }}>
                  at få aktindsigt i min sag.
                </div>
                <div style={{ paddingLeft: "5%" }}>
                  at afgive udtalelse til brug for behandling af min sag, og{" "}
                </div>
                <div style={{ paddingLeft: "5%" }}>
                  at modtage fortrolige oplysninger om mine forhold, herunder
                  mine private forhold.
                </div>
                <div>
                  <strong> </strong>
                </div>
                <div> </div>
                <div>
                  Fuldmagten falder bort når den pågældende sag er afsluttet.
                  Jeg kan når som helst trække fuldmagten tilbage ved at give
                  besked til den ansvarlige for behandlingen af sagen.
                </div>
                <div></div>
                <br />
                <div>
                  <strong>Sted: __________________________</strong>
                </div>
                <div>
                  <strong> {moment(model.placeAndDate).format("L")} </strong>
                </div>
                <br />
                <div>
                  <strong>
                    {model.applicantsName} __________________________{" "}
                  </strong>
                </div>
              </div>
            </Col>

            <Col xs={3} style={{ paddingTop: "60px" }}>
              <div>
                <br />
                <h6>
                  <div>{model.companyName}</div>
                  <div>Juridisk rådgivning</div>
                  <div>{model.addressAttorney}</div>
                  <div>{model.postalCode}</div>
                  <div>{model.city}</div>
                  <div>{model.country}</div>
                  <div>{model.phone}</div>
                  <div>{model.eMail}</div>
                </h6>
              </div>
            </Col>
          </div>
        </div>

        <div>
          <div className="footerpagebreak"></div>
          <div className="pageSection">
            <div className="text-center">
              <h2> Aftale om levering af juridisk rådgivning</h2>
            </div>
            <div>
              Aftalen er indgået mellem Malene Pedersen fra {model.companyName}{" "}
              og {model.applicantsName}{" "}
            </div>
            <div>
              Der vil blive leveret juridisk rådgivningsbistand vedrørende de
              forhold der nævnes i den tilhørende fuldmagt, og den involverede
              opgave.
            </div>
            <div>
              Prisen på rådgivningen vil blive en fast pris på{" "}
              {model.servicePrice} kr. inkl. moms.
            </div>
            <br />
            <div>
              <strong>Sted: __________________________</strong>
            </div>
            <div>
              Underskrevet af {model.applicantsName} __________________________
            </div>
            <br />
            <div>
              <h3>{model.companyName}</h3>
            </div>
            <div>
              <h3>Juridisk rådgivning</h3>
            </div>
            <div>{model.companyName} </div>
            <div>
              {model.postalCode} {model.city}
            </div>
            <div>{model.country}</div>
            <div>{model.phone}</div>
            <div>{model.eMail}</div>
            <div> {model.attorneyName}</div>
            <div> Jurist</div>
            <div>Cand. Jur fra KU.</div>
            <br />
            <div>
              <h4>Oplysninger til kunden om forsikringsforhold.</h4>
            </div>
            <div>
              {" "}
              Undertegnede skal herved oplyse, at Malene Pedersen er
              ansvarsforsikret, hvilket som udgangspunkt indebærer, at et
              eventuelt erstatningsansvar, der måtte opstå som følge af fejl
              eller mangler ved hendes rådgivning, kan dækkes af HDI for et
              beløb af indtil 2.000.000 kr. pr. år, og at der i dette
              forsikringsår ikke er rejst krav mod mig, så den fulde
              forsikringssum er til rådighed. De skal dog som kunde være
              opmærksom på, at forsikringsselskabet ikke hæfter i de tilfælde,
              hvor rådgivningen har involveret patentrettigheder og
              erhvervsskatteret.
            </div>
            <div>
              Med underskriften giver jeg også samtykke til at sagbehandleren
              kan behandle mine persondata i det omfang det er nødvendigt for
              sagsbehandlingen.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
