import { createSlice } from "@reduxjs/toolkit";
import StorageService from "../../StorageService";

const initialState = {
  queryParams: {
    // searchText: '',
    // selectedFromDate: null,
    // selectedToDate: null
  },
  pageSize: 1000,
  selectedContact: StorageService.loadSessionItem("selectedContact", {}), //Need entire contact object due to pagination
};

const pageSizeIncrement = 1000;

const contactDataSlice = createSlice({
  name: "contactData",
  initialState,
  reducers: {
    setContactQueryParams(state, action) {
      state.queryParams = action.payload;
    },
    incrementContactPageSize(state) {
      state.pageSize += pageSizeIncrement;
    },
    resetContactPageSize(state) {
      state.pageSize = initialState.pageSize;
    },
    setSelectedContact(state, action) {
      state.selectedContact = action.payload;
    },
  },
});

export const {
  setContactQueryParams,
  incrementContactPageSize,
  resetContactPageSize,
  setSelectedContact,
} = contactDataSlice.actions;
export default contactDataSlice.reducer;
