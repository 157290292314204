import React, { useEffect, useState } from "react";

import InvoiceV2 from "./InvoiceV2";

import { ApiService, DataActions } from "../../services/AxoServices";

import {
  LoadingIndicator,
  // FileViewerModal
} from "../../utilities/LexUtilities";

import QueryString from "query-string";

const PostingGenerationProgress = {
  NONE: 0,
  FETCHINGHTML: 1,
  GENERATING: 2,
  ERROR: 3,
};

const InvoiceSingleView = (props) => {
  let {
    id,
    invoices,
    contactMap,
    userProfile,
    products,
    onShowPDF,
    onCreateInvoice,
    clientPlan,
    fiscalYears,
    createContact,
    updateContact,
  } = props;

  const [currentInvoice, setCurrentInvoice] = useState({ id: 0 });
  const [generationState, setGenerationState] = useState({
    isFetchingHTML: false,
    isGeneratingPDF: false,
    generatedPDF: false,
    showGeneratePDFError: false,
  });

  const [postingGenerationProgress, setPostingGenerationProgress] = useState(
    PostingGenerationProgress.NONE
  );

  const invoiceRef = React.createRef();
  // let fileModal =  React.createRef();

  const getCurrentInvoice = (id) => {
    ApiService.getSingleInvoice(id).then((invoice) =>
      setCurrentInvoice(invoice)
    );
  };

  useEffect(() => {
    let foundInvoice = invoices.find((i) => i.id === id);
    if (!!foundInvoice) {
      setCurrentInvoice(foundInvoice);
    } else {
      getCurrentInvoice(id);
    }
  }, [id]); //Fetch invoice whenever id changes

  useEffect(() => {
    if (generationState.isFetchingHTML) {
      doGeneratePdf({
        ...currentInvoice,
        innerHTML: getInvoiceHtml(),
      });
      setGenerationState((oldState) => ({
        ...oldState,
        isFetchingHTML: false,
      }));
    }
  }, [generationState.isFetchingHTML]);

  useEffect(() => {
    if (postingGenerationProgress === PostingGenerationProgress.FETCHINGHTML) {
      generatePostingsWithReceipt({
        ...currentInvoice,
        innerHTML: getInvoiceHtml(),
      });
    }
  }, [postingGenerationProgress]);

  const onSave = async (invoice) => {
    let response = await DataActions.updateInvoice(invoice);
    return response.ok;
  };

  const onApprove = async (invoice) => {
    let newInvoice = {
      ...invoice,
      status: "Approved",
    };

    // return onSave(newInvoice);

    let result = await onSave(newInvoice);
    if (!result) {
      return false;
    }

    setCurrentInvoice(newInvoice);

    if (!!clientPlan.id) {
      onGeneratePostings();
    }

    return true;
  };

  useEffect(() => {
    if (!window) {
      return;
    }

    if (!currentInvoice.id) {
      return;
    }

    let query = QueryString.parse(window.location.search);
    if (!!query.approve && !!clientPlan.id) {
      onGeneratePostings();
    }
  }, [currentInvoice.id]);

  const generatePostingsWithReceipt = async (invoice) => {
    setPostingGenerationProgress(PostingGenerationProgress.GENERATING);

    try {
      let response = await ApiService.updateInvoice(invoice);
      if (!response.ok) {
        showPostingGenerationError();
        return;
      }
      response = await DataActions.generateInvoicePdf(invoice.id);
      if (!response.ok) {
        showPostingGenerationError();
        return;
      }

      let pdf = await response.json();
      response = await ApiService.generateInvoicePostings(invoice.id, pdf.id);
      if (!response.ok) {
        showPostingGenerationError();
        return;
      }

      getCurrentInvoice(invoice.id);
      setPostingGenerationProgress(PostingGenerationProgress.NONE);
    } catch (reason) {
      console.log(reason);
      setPostingGenerationProgress(PostingGenerationProgress.ERROR);
      setTimeout(
        () => setPostingGenerationProgress(PostingGenerationProgress.NONE),
        3000
      );
    }
  };

  const showPostingGenerationError = () => {
    setPostingGenerationProgress(PostingGenerationProgress.ERROR);
    setTimeout(
      () => setPostingGenerationProgress(PostingGenerationProgress.NONE),
      3000
    );
  };

  const onGeneratePostings = async () => {
    let { clientPlan } = props;
    if (!clientPlan.id) {
      return false;
    }

    setPostingGenerationProgress(PostingGenerationProgress.FETCHINGHTML);
  };

  const onGeneratePdf = () => {
    setGenerationState((oldState) => ({
      ...oldState,
      isFetchingHTML: true,
    }));
  };

  const doGeneratePdf = (invoice) => {
    setGenerationState((oldState) => ({
      ...oldState,
      isGeneratingPDF: true,
    }));
    return ApiService.updateInvoice(invoice)
      .then((response) => {
        if (response.ok) {
          return DataActions.generateInvoicePdf(invoice.id);
        }
        return Promise.reject("Invoice could not be updated.");
      })
      .then((response) => {
        setGenerationState((oldState) => ({
          ...oldState,
          isGeneratingPDF: false,
        }));
        if (response.ok) {
          getCurrentInvoice(invoice.id);
          return response.json();
        }
        return Promise.reject("Invoice could not be printed.");
      })
      .then((newPDF) => {
        setGenerationState((oldState) => ({
          ...oldState,
          generatedPDF: true,
        }));
        onShowPDF(newPDF);
        // fileModal.current.open([ newPDF ]);
        setTimeout(() => {
          setGenerationState((oldState) => ({
            ...oldState,
            generatedPDF: false,
          }));
        }, 1500);
      })
      .catch((reason) => {
        console.log(reason);
        showGeneratePDFError();
      });
  };

  const getInvoiceHtml = () => {
    var invoiceNode = invoiceRef.current.innerHTML;
    return invoiceNode;
  };

  const showGeneratePDFError = () => {
    setGenerationState((oldState) => ({
      ...oldState,
      isGeneratingPDF: false,
      // isGeneratingAndSending: false,
      showGeneratePDFError: true,
    }));

    setTimeout(() => {
      setGenerationState((oldState) => ({
        ...oldState,
        showGeneratePDFError: false,
      }));
    }, 5000);
  };

  // const onGenerateAndSend = () => {
  //   this.setState({ isFetchingHTML: true }, () => {
  //     var invoice = Object.assign({}, currentInvoice);
  //     invoice.innerHTML = this.getInvoiceHtml();
  //     this.setState({ isFetchingHTML: false }, () => {
  //       this.doGenerateAndSend(invoice);
  //     });
  //   });
  // }

  // const doGenerateAndSend = (invoice) => {
  //   this.setState({ isGeneratingAndSending: true });
  //   return ApiService.updateInvoice(invoice)
  //   .then(response => {
  //     if(response.ok) {
  //       return DataActions.generateInvoicePdf(invoice.id);
  //     }
  //     return Promise.reject("Invoice could not be updated.");
  //   })
  //   .then( response => {
  //     this.setState({ isGeneratingAndSending: false});
  //     if(response.ok) {
  //       return response.json()
  //     }
  //     return Promise.reject("Invoice could not be printed.");
  //   })
  //   .then( newPDF => {
  //     this.setState({ isGeneratingAndSending: false});
  //     this.props.onCreateAndSendPDF(newPDF);
  //   })
  //   .catch( reason => {
  //     console.log(reason);
  //     this.showGeneratePDFError();
  //   });
  // }

  if (!currentInvoice.id) {
    return <LoadingIndicator />;
  }
  return (
    <div ref={invoiceRef} className="standardMaxWidth">
      <InvoiceV2
        invoice={currentInvoice}
        contactMap={contactMap}
        userProfile={userProfile}
        products={products}
        renderButtons={true}
        onSave={onSave}
        onApprove={onApprove}
        onGeneratePostings={onGeneratePostings}
        onClose={() => {}}
        onCreate={onCreateInvoice}
        onGeneratePdf={onGeneratePdf}
        renderPaymentLinkButton
        {...generationState}
        postingGenerationProgress={postingGenerationProgress}
        isFetchingHTML={
          generationState.isFetchingHTML ||
          postingGenerationProgress === PostingGenerationProgress.FETCHINGHTML
        }
        clientPlan={clientPlan}
        fiscalYears={fiscalYears}
        createContact={createContact}
        updateContact={updateContact}
      />
      {/* <FileViewerModal size='large' ref={fileModal} /> */}
    </div>
  );
};

export default React.memo(InvoiceSingleView);
