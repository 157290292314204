import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import ContactTabView from "./Contacts/ContactTabView";
import QueryString from "query-string";
import { debounce } from "lodash";

import { Consumer, withRouter, withRTKData } from "../utilities/LexUtilities";

import { RoutingService } from "../services/AxoServices";

class ContactContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.initializeLabels();
    DataStore.initializeUserGroup();
    DataStore.initializeFriends();
    DataStore.fetchSharingGlobals();
  }

  componentWillUnmount() {
    this.props.resetContactPageSize();
  }

  mapStoreToProps = (store) => {
    let filteredLabels = store.labels.filter((l) => l.type === 3); //Clients
    let sortedLabels = filteredLabels.sort((l1, l2) =>
      l1.name.localeCompare(l2.name)
    );
    let sortedFriends = store.friends.sort((f1, f2) => {
      let firstName = f1.firstName || "";
      return firstName.localeCompare(f2.firstName || "");
    });
    let contactSharings = store.sharingGlobals.filter((s) => s.type === 0); //Clients

    return {
      userProfile: store.userProfile,
      userSettings: store.userSettings,
      labels: sortedLabels,
      userGroup: store.userGroup,
      friends: sortedFriends,
      sharingGlobals: contactSharings,
      showDropdowns: store.showDropdowns,
      locale: store.locale,
    };
  };

  resetContactQuery = () => {
    let { contactQueryParams } = this.props;
    if (Object.keys(contactQueryParams).length > 0) {
      this.queryContacts({});
    }
  };

  queryContacts = debounce((query) => {
    let { setContactQueryParams } = this.props;

    let { searchText, selectedFromDate, selectedToDate } = query;
    //The spread operator only adds the property if the conditional is truthy.
    setContactQueryParams({
      ...(!!searchText ? { searchText } : {}),
      ...(!!selectedFromDate ? { fromDate: selectedFromDate.format() } : {}),
      ...(!!selectedToDate ? { toDate: selectedToDate.format() } : {}),
    });
  }, 350);

  getActiveTab = (props) => {
    let path = props.location.pathname.toLowerCase();
    if (path.includes("list")) {
      return "List";
    }
    if (path.includes("create")) {
      return "Create";
    }
    if (path.includes("waiting")) {
      return "Waiting";
    }
    if (path.includes("former")) {
      return "Former";
    }
    if (path.includes("private")) {
      return "Private";
    }
    if (path.includes("business")) {
      return "Business";
    }
    if (path.includes("public")) {
      return "Public";
    }
    if (path.includes("misc")) {
      return "Misc";
    }
    return "List";
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(RoutingService.getPath("Clients/" + eventKey));
  };

  render() {
    let query = QueryString.parse(this.props.location.search);
    let adminCodeValidated = query.adminCode === "AxolexAdmin";

    return (
      <Consumer>
        {(store) => {
          let props = this.mapStoreToProps(store);
          return (
            <ContactTabView
              {...props}
              {...this.props}
              key={store.hideSidebar.toString()}
              activeTab={this.getActiveTab(this.props)}
              handleNavigation={this.handleNavigation}
              queryContacts={this.queryContacts}
              resetContactQuery={this.resetContactQuery}
              adminCodeValidated={adminCodeValidated}
            />
          );
        }}
      </Consumer>
    );
  }
}

export default withRTKData(withRouter(ContactContainer));
