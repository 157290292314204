import React from "react";
import { Table, Column, Cell } from "fixed-data-table-2";
import SortHeaderCell from "../../utilities/FixedDataTable/SortHeaderCell";
import DataListWrapper from "../../utilities/FixedDataTable/DataListWrapper";
import DocumentGridView from "./DocumentGridView";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";
import moment from "moment";
import { withRouter } from "react-router-dom";

import {
  DataStore,
  DataActions,
  RoutingService,
  ModalService,
  FileInfoService,
  StringService,
  MessageService,
  AuthorizationService,
  ApiService,
} from "../../services/AxoServices";

import {
  CaseSearchBox,
  LabelSearchBox,
  UserSearchBox,
  ClientSearchBox,
  FileViewerModal,
  AlertModal,
  ConfirmModal,
  Uniqueomat,
  LoadingIcon,
  Dimensions,
  AxoLocal,
  Flexbox,
  FlexElement,
  AxoDateTime,
  getText,
  AxoDropzone,
  LexButton,
  AxoCheckbox,
  PaginationControls,
  Icon,
  TableBase,
  FileViewer,
} from "../../utilities/LexUtilities";

import {
  Row,
  Col,
  Grid,
  FormControl,
  FormGroup,
  Button,
  InputGroup,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";

const defaultColumnDefs = {
  select: {
    width: 50,
    shown: true,
  },
  fileName: {
    width: 200,
    shown: true,
  },
  fileType: {
    width: 110,
    shown: true,
  },
  uploadDate: {
    width: 120,
    shown: true,
  },
  size: {
    width: 100,
    shown: false,
  },
  status: {
    width: 130,
    shown: true,
  },
  priority: {
    width: 130,
    shown: true,
  },
  deadline: {
    width: 210,
    shown: true,
  },
  labelCol: {
    width: 130,
    shown: true,
  },
  casesCol: {
    width: 170,
    shown: true,
  },
  clientName: {
    width: 150,
    shown: true,
  },
  actions: {
    width: 110,
    shown: true,
  },
};

class DocumentTableFixedComponent extends TableBase {
  constructor(props) {
    super(props);
    this.name = "DocumentTableFixedComponent";

    let filteredEntries = this.getFilteredDocuments(props.documents, {
      searchText: "",
      selectedCase: {},
      selectedLabelId: 0,
      selectedContact: props.selectedContact,
    });

    this._defaultSortIndexes = this.generateDefaultSortIndexes(filteredEntries);
    this.defaultColumnDefs = defaultColumnDefs;

    this.state = {
      tableEntries: props.documents,
      selectedDocuments: new Set(),
      displayedEntryId: 0,
      newLabelName: "",
      minColWidths: {
        status: 110,
        priority: 100,
        deadline: 190,
        labelCol: 110,
        casesCol: 110,
        actions: 75,
      },
      maxColumnWidth: 300,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        filteredEntries
      ),
      colSortDirs: {},
      searchText: "",
      showDisabledWarning: false,
      showCreatedLabelIcon: false,

      selectedLabelId: 0,
      selectedCase: {},
      selectedFromDate: null,
      selectedToDate: null,

      showControls: false,

      deletingSelected: false,

      showSharingSuccess: false,
      showSharingFailure: false,
      columnDefs: this.retrieveColumnDefs(),

      ...this.getTranslatedTexts(),
    };

    let userType = AuthorizationService.getUserType(props.userProfile);
    this.updateToggleColumns(userType);

    this.getTranslatedTexts();
  }

  onDisplayEntry = (entryId) => {
    this.setState((prevState) => ({
      displayedEntryId: prevState.displayedEntryId !== entryId ? entryId : 0,
    }));
  };

  clearSelectedDocuments = () => {
    this.setState({ selectedDocuments: new Set() });
  };

  componentDidMount() {
    let { selectedContact } = this.props;

    if (!!selectedContact.id) {
      this.updateShownEntries({ debounced: false });
    }
  }

  componentDidUpdate(prevProps) {
    let { userProfile, selectedContact, locale } = this.props;

    let oldType = AuthorizationService.getUserType(prevProps.userProfile);
    let userType = AuthorizationService.getUserType(userProfile);

    if (!!userType && oldType !== userType) {
      this.updateToggleColumns(userProfile.userType);
    }

    if (prevProps.selectedContact.id !== selectedContact.id) {
      this.updateShownEntries({ debounced: true });
    }

    if (prevProps.locale !== locale) {
      this.setState(this.getTranslatedTexts());
    }
  }

  updateToggleColumns = (userType) => {
    this.toggleColumns = [
      "fileName",
      "size",
      "status",
      "priority",
      "deadline",
      "casesCol",
      "clientName",
      "labelCol",
      "uploadDate",
      "fileType",
    ];
    if (userType !== "Lawyer" && userType !== "Admin") {
      this.toggleColumns = this.toggleColumns.filter((c) => c !== "casesCol");
    }
    if (userType === "Society") {
      this.toggleColumns = this.toggleColumns.filter((c) => c !== "deadline");
    }
  };

  getTranslatedTexts = () => {
    return {
      sendToClientLabel: getText("BCCTitleSendfiletoclient"), //Send fil til klient
      statusNormalLabel: getText("CaseEditFormcasepriorityNormal"),
      statusDraftLabel: getText("DocumentGridViewshareddraft"),
      statusSubmittedLabel: getText("DocumentGridViewSubmitted"),
      statusTreatedLabel: getText("DocumentGridViewTreat"),
      statusApprovedLabel: getText("DocumentGridViewapproved"),
      statusArchivedLabel: getText("DocumentGridViewarchived"),
      priorityLowLabel: getText("CaseEditInlinepriorityLow"),
      priorityNormalLabel: getText("CaseEditInlinepriorityNormal"),
      priorityHighLabel: getText("CaseEditInlinepriorityHigt"),
      priorityUrgentLabel: getText("CaseEditInlinepriorityurgent"),
    };
  };

  componentWillReceiveProps(nextProps) {
    let { selectedContact } = nextProps;
    let {
      searchText,
      selectedCase,
      selectedLabelId,
      selectedFromDate,
      selectedToDate,
    } = this.state;

    let tableEntries = nextProps.documents;
    let filteredEntries = this.getFilteredDocuments(tableEntries, {
      searchText,
      selectedCase,
      selectedLabelId,
      selectedContact,
      selectedFromDate,
      selectedToDate,
    });

    this.updateEntries(tableEntries, filteredEntries);
  }

  getDisplayName = (document) => {
    let { userProfile } = this.props;

    let nameString = document.fileName;
    var lastPeriod = nameString.lastIndexOf(".");
    if (lastPeriod > -1) {
      nameString = nameString.substring(0, nameString.lastIndexOf("."));
    }
    nameString = StringService.enforceMaxLength(nameString, 30);

    if (userProfile.logo && userProfile.logo.id === document.id) {
      nameString += " (logo)";
    }
    if (
      userProfile.profileImage &&
      userProfile.profileImage.id === document.id
    ) {
      nameString += " (profilbillede)";
    }
    if (!!document.receiptNumber) {
      nameString = "(#" + document.receiptNumber.toString() + ") " + nameString;
    }
    return nameString;
  };

  onTrashFile = (documentId) => {
    this.props.onTrashFile(documentId);
  };

  onRestoreFile = (documentId) => {
    this.props.onRestoreFile(documentId);
  };

  onSendFile = (documentId) => {
    let { selectedDocuments } = this.state;

    this.props.onSendFile(documentId, selectedDocuments);
  };

  renderButtons = (document) => {
    var { deletingDocumentId } = this.props;
    let { sendToClientLabel } = this.state;

    return (
      <>
        &ensp;
        <a href={ApiService.getDownloadPath(document.id)}>
          <Icon
            role="button"
            glyph="icon-fontello-download-5"
            className="editable"
            title={getText("axolexDownload6", "Download")}
          />
        </a>
        &ensp;&ensp;
        <Icon
          role="button"
          glyph="icon-fontello-mail-1"
          onClick={this.onSendFile.bind(this, document.id)}
          className="editable"
          title={sendToClientLabel}
        />
        &ensp;&ensp;
        {deletingDocumentId === document.id ? (
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        ) : (
          <>
            {/* Receipts cannot be deleted unless the postings are deleted */}
            {!document.receiptNumber ? (
              <Icon
                role="button"
                onClick={this.onTrashFile.bind(this, document.id)}
                className="editable"
                glyph="icon-fontello-trash-1"
              />
            ) : null}
          </>
        )}
      </>
    );
  };

  onClickFileName = (documentId, event) => {
    event.preventDefault();
    this.props.onActivate(documentId);
  };

  addSelectedDocumentsToCase = (caseViewModel) => {
    let promises = [];
    this.state.selectedDocuments.forEach((documentId) => {
      if (caseViewModel.documents.find((d) => d.id === documentId)) {
        return;
      }
      let document = this.props.documents.find((d) => d.id === documentId);
      promises.push(
        DataActions.createCaseDocumentAssignment({
          caseId: caseViewModel.id,
          fileInfoModelId: document.id,
        })
      );
    });
    Promise.all(promises).then((response) => {
      this.clearSelectedDocuments();
    });
  };

  addSelectedDocumentsToLabel = (label) => {
    DataActions.addDocumentsToLabel(
      Array.from(this.state.selectedDocuments),
      label.id
    );
    this.clearSelectedDocuments();
  };

  addSelectedDocumentsToClient = (selectedContact) => {
    let { documents } = this.props;
    let { selectedDocuments } = this.state;

    selectedDocuments.forEach((documentId) => {
      let document = documents.find((d) => d.id === documentId);
      DataActions.updateDocument({
        ...document,
        clientId: selectedContact.id,
        clientName:
          selectedContact.firstName + " " + (selectedContact.lastName || ""),
      }).then((response) => {
        if (response.ok) {
          this.clearSelectedDocuments();
        }
      });
    });
  };

  onSelectDocument = (documentId, event) => {
    let selectedDocuments = new Set(this.state.selectedDocuments);
    if (event.target.checked) {
      selectedDocuments.add(documentId);
    } else {
      selectedDocuments.delete(documentId);
    }
    this.setState({ selectedDocuments });
  };

  onRemoveCase = (caseModel, documentId) => {
    caseModel.documents = caseModel.documents.filter(
      (d) => d.id !== documentId
    );
    DataActions.updateCaseFromHeader(caseModel);
  };

  onRemoveLabel = (labelId, documentId) => {
    DataActions.removeLabelFromFile(documentId, labelId);
  };

  onCreateLabel = () => {
    if (!this.state.newLabelName) {
      ModalService.openAlertModal(
        <AxoLocal
          entity="DocumentTableFixedViewopenAlertModal"
          defaultValue={"Indtast et navn for at oprette en ny mappe."}
        />
      );
      return;
    }
    DataActions.createLabel({ name: this.state.newLabelName, type: 2 }).then(
      (response) => {
        if (response.ok) {
          this.setState({ showCreatedLabelIcon: true });
          setTimeout(() => {
            this.setState({ showCreatedLabelIcon: false });
          }, 3000);
          response.json().then((newLabel) => {
            DataActions.addDocumentsToLabel(
              Array.from(this.state.selectedDocuments),
              newLabel.id
            );
            this.setState({ newLabelName: "" });
            this.clearSelectedDocuments();
          });
        }
      }
    );
  };

  onStatusSelect = (documentId, event) => {
    var document = Object.assign(
      {},
      this.props.documents.find((d) => d.id === documentId)
    );
    document.status = parseInt(event.target.value, 10);
    DataActions.updateDocument(document);
  };

  renderStatusSelect = (document) => {
    let {
      statusNormalLabel,
      statusDraftLabel,
      statusSubmittedLabel,
      statusTreatedLabel,
      statusApprovedLabel,
      statusArchivedLabel,
    } = this.state;

    return (
      <select
        className="axoblue selectbg"
        value={document.status.toString()}
        style={{ border: "none" }}
        onChange={this.onStatusSelect.bind(this, document.id)}
      >
        <option value="0">{statusNormalLabel}</option>
        <option value="1">{statusDraftLabel}</option>
        <option value="2">{statusSubmittedLabel}</option>
        <option value="3">{statusTreatedLabel}</option>
        <option value="4">{statusApprovedLabel}</option>
        <option value="5">{statusArchivedLabel}</option>
      </select>
    );
  };

  getStatusIcon = (status) => {
    switch (status) {
      case 0:
        return (
          <div>
            <Icon style={{ color: "#428bca" }} glyph="icon-fontello-stop-3" />
          </div>
        );
      case 1:
        return (
          <div>
            <Icon style={{ color: "#B8C3C8" }} glyph="icon-fontello-stop-3" />
          </div>
        );
      case 2:
        return (
          <div>
            <Icon style={{ color: "#64584C" }} glyph="icon-fontello-stop-3" />
          </div>
        );
      case 3:
        return (
          <div>
            <Icon style={{ color: "orange" }} glyph="icon-fontello-stop-3" />
          </div>
        );
      case 4:
        return (
          <div>
            <Icon style={{ color: "#66A655" }} glyph="icon-fontello-stop-3" />
          </div>
        );
      case 5:
        return (
          <div>
            <Icon style={{ color: "#D71F4B" }} glyph="icon-fontello-stop-3" />
          </div>
        );
      default:
        return <div></div>;
    }
  };

  onPrioritySelect = (documentId, event) => {
    var document = Object.assign(
      {},
      this.props.documents.find((d) => d.id === documentId)
    );
    document.priority = parseInt(event.target.value, 10);
    DataActions.updateDocument(document);
  };

  renderPrioritySelect = (document) => {
    let {
      priorityLowLabel,
      priorityNormalLabel,
      priorityHighLabel,
      priorityUrgentLabel,
    } = this.state;

    return (
      <select
        className="axoblue selectbg"
        value={document.priority.toString()}
        style={{ border: "none" }}
        onChange={this.onPrioritySelect.bind(this, document.id)}
      >
        <option value="0">{priorityLowLabel}</option>
        <option value="1">{priorityNormalLabel}</option>
        <option value="2">{priorityHighLabel}</option>
        <option value="3">{priorityUrgentLabel}</option>
      </select>
    );
  };

  getPriorityIcon = (priority) => {
    switch (priority) {
      case 0:
        return (
          <div>
            <Icon style={{ color: "blue" }} glyph="icon-fontello-circle" />
          </div>
        );
      case 1:
        return (
          <div>
            <Icon style={{ color: "green" }} glyph="icon-fontello-circle" />
          </div>
        );
      case 2:
        return (
          <div>
            <Icon style={{ color: "darkred" }} glyph="icon-fontello-circle" />
          </div>
        );
      case 3:
        return (
          <div>
            <Icon style={{ color: "red" }} glyph="icon-fontello-circle" />
          </div>
        );
      default:
        return <div></div>;
    }
  };

  onDeadlineChange = (documentId, event) => {
    var document = Object.assign(
      {},
      this.props.documents.find((d) => d.id === documentId)
    );
    document.deadline = moment(event.target.valueAsDate).format();
    DataActions.updateDocument(document);
  };

  addDeadlineToDocument = (documentId) => {
    var document = Object.assign(
      {},
      this.props.documents.find((d) => d.id === documentId)
    );
    document.deadline = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    DataActions.updateDocument(document);
  };

  removeDeadlineFromDocument = (documentId) => {
    var document = Object.assign(
      {},
      this.props.documents.find((d) => d.id === documentId)
    );
    document.deadline = new Date(0);
    DataActions.updateDocument(document);
  };

  renderDateCell = (document) => {
    var deadlineMoment = moment(document.deadline);
    if (deadlineMoment.year() > 1970) {
      return (
        <div>
          <input
            style={{ borderStyle: "none" }}
            type="date"
            onChange={this.onDeadlineChange.bind(this, document.id)}
            value={deadlineMoment.format("YYYY-MM-DD")}
          />
          &nbsp;&nbsp;&nbsp;
          <AxoLocal
            componentClass={Icon}
            role="button"
            onClick={this.removeDeadlineFromDocument.bind(this, document.id)}
            glyph="icon-fontello-scissors axored"
            componentAttribute="title"
            entity="RemoveTitledeadlineMomentRemovereminder"
          />
        </div>
      );
    }
    return (
      <Icon
        className="editable"
        role="button"
        onClick={this.addDeadlineToDocument.bind(this, document.id)}
        glyph="icon-fontello-calendar-6"
      />
    );
  };

  handleCaseClick = (caseId, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onCaseClick(caseId);
  };

  shareDocumentsWithSelectedUser = (userProfile) => {
    let promises = [];
    this.state.selectedDocuments.forEach((documentId) => {
      let document = this.state.tableEntries.find((d) => d.id === documentId);
      promises.push(DataActions.shareFile({ document, userProfile }));
    });
    this.clearSelectedDocuments();

    Promise.all(promises).then((responses) => {
      if (!!responses.find((r) => !r.ok)) {
        this.setState({ showSharingFailure: true });
        setTimeout(() => {
          this.setState({ showSharingFailure: false });
        }, 3000);
      } else {
        this.setState({ showSharingSuccess: true });
        setTimeout(() => {
          this.setState({ showSharingSuccess: false });
        }, 3000);
      }
    });
  };

  checkForDisabledInput = () => {
    if (this.state.selectedDocuments.size === 0) {
      this.setState({ showDisabledWarning: true });
      setTimeout(() => {
        this.setState({ showDisabledWarning: false });
      }, 3000);
    }
  };

  renderInputBoxes = () => {
    let {
      friends,
      userGroup,
      userProfile,
      isUpload,
      contactMap,
      selectedContact,
      labels,
    } = this.props;

    let {
      showControls,
      selectedLabelId,
      selectedCase,
      selectedFromDate,
      selectedToDate,
      showSharingFailure,
      showSharingSuccess,
    } = this.state;

    if (!showControls) {
      return null;
    }

    let groupMembers =
      !!userGroup && userGroup.id
        ? userGroup.members.filter((m) => m.id !== userProfile.id)
        : [];
    let allFriends = friends.concat(groupMembers);
    allFriends = Uniqueomat.uniqueById(allFriends);
    let userType = AuthorizationService.getUserType(userProfile);

    let selectedLabel = labels.find((l) => l.id === selectedLabelId) || {};

    return (
      <React.Fragment>
        <Row style={{ paddingLeft: "25px" }}>
          <Flexbox responsive>
            {userType !== "Accountant" && userType !== "Society" ? (
              <FlexElement
                className="Doccolor1"
                style={{ paddingRight: "15px" }}
              >
                <Icon glyph="icon-simple-line-icons-briefcase" />
                &ensp;&ensp;
                <AxoLocal
                  entity="DocumentGridViewCaseNodes"
                  defaultValue={"Tilføj til sag"}
                />
                <span onClick={this.checkForDisabledInput}>
                  <AxoLocal
                    componentClass={CaseSearchBox}
                    cases={this.props.caseMap.saved}
                    count={this.props.caseMap.savedCount}
                    showDocumentCount
                    handleSelectedCase={this.addSelectedDocumentsToCase}
                    disabled={this.state.selectedDocuments.size === 0}
                    inputBorder="1px solid #e1465b"
                    clearOnSelect
                    componentAttribute="placeholder"
                    entity="CaseSearchBoxPlaceholdercase"
                  />
                </span>
              </FlexElement>
            ) : null}
            <FlexElement
              className="Doccolor2 onToppest"
              style={{ paddingRight: "15px" }}
            >
              <Icon glyph="icon-fontello-folder-open-1" />
              &ensp;&ensp;
              <AxoLocal
                entity="DocumentAddtoexistingfolder"
                defaultValue={"Tilføj til eksisterende mappe"}
              />
              <span onClick={this.checkForDisabledInput}>
                <AxoLocal
                  componentClass={LabelSearchBox}
                  labels={this.props.labels}
                  handleSelectedLabel={this.addSelectedDocumentsToLabel}
                  disabled={this.state.selectedDocuments.size === 0}
                  inputBorder="1px solid #86A8E7"
                  clearOnSelect
                  componentAttribute="placeholder"
                  entity="CaseSearchBoxPlaceholderfolder"
                />
              </span>
            </FlexElement>
            {this.renderCreateLabelInput()}
            <FlexElement className="Doccolor4" style={{ paddingRight: "15px" }}>
              <Icon glyph="icon-fontello-user-add" />
              &ensp;&ensp;
              {userType === "Society" ? (
                <span>
                  <AxoLocal
                    entity="axoidcode225b"
                    defaultValue={"Tilføj til medlem"}
                  />
                </span>
              ) : (
                <AxoLocal
                  entity="axoAccounting74"
                  defaultValue={"Tilføj til klient"}
                />
              )}
              <span onClick={this.checkForDisabledInput}>
                <ClientSearchBox
                  clients={contactMap.contacts}
                  count={contactMap.count}
                  handleSelectedClient={this.addSelectedDocumentsToClient}
                  disabled={this.state.selectedDocuments.size === 0}
                  inputBorder="1px solid #00e600"
                  clearOnSelect
                  placeholder={
                    userType === "Society"
                      ? getText("axoidcode224", "Vælg medlem")
                      : getText("axoAccounting75", "Vælg klient")
                  }
                />
              </span>
            </FlexElement>
            {userType !== "Society" ? (
              <FlexElement
                className="Doccolor5"
                style={{ paddingRight: "15px" }}
              >
                <Icon glyph="icon-fontello-share-1" />
                &ensp;&ensp;
                <AxoLocal
                  entity="DocumentSharedocumentswithuser"
                  defaultValue={"Del dokumenter med bruger"}
                />
                <Flexbox>
                  <div onClick={this.checkForDisabledInput}>
                    <AxoLocal
                      componentClass={UserSearchBox}
                      userProfiles={allFriends}
                      handleSelectedUser={this.shareDocumentsWithSelectedUser}
                      disabled={this.state.selectedDocuments.size === 0}
                      inputBorder="1px solid #668cff"
                      clearOnSelect
                      componentAttribute="placeholder"
                      entity="CaseSearchBoxPlaceholderUser"
                    />
                  </div>
                  {showSharingSuccess ? (
                    <div className="axogreen">
                      &nbsp;
                      <Icon glyph="icon-fontello-ok-3" />
                    </div>
                  ) : null}
                  {showSharingFailure ? (
                    <div className="axored">
                      &nbsp;
                      <Icon glyph="icon-fontello-error" />
                    </div>
                  ) : null}
                </Flexbox>
              </FlexElement>
            ) : null}
          </Flexbox>
        </Row>
        {!isUpload ? (
          <Row style={{ paddingLeft: "25px" }}>
            <Flexbox responsive>
              {userType !== "Accountant" && userType !== "Society" ? (
                <FlexElement style={{ paddingRight: "15px" }}>
                  <Icon glyph="icon-simple-line-icons-briefcase" />
                  &ensp;&ensp;
                  {selectedCase.id ? (
                    <span>&nbsp;({selectedCase.caseNumber})</span>
                  ) : null}
                  <CaseSearchBox
                    cases={this.props.caseMap.saved}
                    count={this.props.caseMap.savedCount}
                    showDocumentCount
                    handleSelectedCase={(selectedCase) => {
                      this.setState({ selectedCase }, () =>
                        this.updateShownEntries({ debounced: false })
                      );
                    }}
                    disabled={this.props.caseMap.saved.size === 0}
                    inputBorder="1px solid #4CAF50"
                    clearOnSelect
                    placeholder={getText("axoAccounting76", "Vælg sag")}
                  />
                </FlexElement>
              ) : null}
              <FlexElement style={{ paddingRight: "15px" }}>
                <Icon glyph="icon-fontello-braille" />
                &ensp;&ensp;
                <span>
                  {selectedLabel.id ? (
                    <span>&nbsp;({selectedLabel.name})</span>
                  ) : null}
                </span>
                <div className="flexbox-responsive">
                  <AxoLocal
                    componentClass={LabelSearchBox}
                    labels={[
                      {
                        id: 0,
                        name: getText("axoidcode228", "Alle mapper"),
                      },
                      {
                        id: -1,
                        name: getText("axoidcode229", "Udenfor mapper"),
                      },
                    ].concat(this.props.labels)}
                    handleSelectedLabel={(label) =>
                      this.onShowSelectedLabelDocuments(label)
                    }
                    disabled={this.props.labels.length === 0}
                    inputBorder="1px solid #4CAF50"
                    clearOnSelect
                    componentAttribute="placeholder"
                    entity="DocumentLabelShowPlaceholder"
                  />
                </div>
              </FlexElement>
              <FlexElement style={{ paddingRight: "15px" }}>
                <Icon glyph="icon-fontello-right-hand-1" />
                &ensp;&ensp;
                {selectedContact.id ? (
                  <span>
                    &nbsp;(
                    {selectedContact.firstName +
                      " " +
                      (selectedContact.lastName || "")}
                    )
                  </span>
                ) : null}
                <ClientSearchBox
                  clients={contactMap.contacts}
                  count={contactMap.count}
                  handleSelectedClient={this.onSelectClient}
                  disabled={contactMap.contacts.length === 0}
                  inputBorder="1px solid #4CAF50"
                  clearOnSelect
                  placeholder={
                    userType === "Society"
                      ? getText("axoidcode224", "Vælg medlem")
                      : getText("axoAccounting75", "Vælg klient")
                  }
                />
              </FlexElement>
              <FlexElement style={{ paddingRight: "15px" }}>
                <AxoLocal entity="axoAccounting73" defaultValue={"Dato fra"} />
                <AxoDateTime
                  value={selectedFromDate}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={(selectedFromDate) => {
                    this.setState({ selectedFromDate }, () =>
                      this.updateShownEntries({ debounced: false })
                    );
                  }}
                />
              </FlexElement>
              <FlexElement style={{ paddingRight: "15px" }}>
                <AxoLocal entity="axoAccounting72" defaultValue={"Dato til"} />
                <AxoDateTime
                  value={selectedToDate}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={(selectedToDate) => {
                    this.setState({ selectedToDate }, () =>
                      this.updateShownEntries({ debounced: false })
                    );
                  }}
                  isValidDate={(currentDate) => {
                    return (
                      !selectedFromDate || currentDate.isAfter(selectedFromDate)
                    );
                  }}
                />
              </FlexElement>
              <FlexElement>
                <div style={{ visibility: "hidden" }}>
                  {/* Adds space on top of the button */}
                  <AxoLocal entity="paymentDate13a" defaultValue={"Hidden"} />
                </div>
                {selectedCase.id ||
                selectedLabel.id ||
                selectedContact.id ||
                selectedFromDate ||
                selectedToDate ? (
                  <LexButton
                    style={{ height: "30px" }}
                    onClick={this.onClearFilters}
                  >
                    <AxoLocal
                      entity="axoAccounting71"
                      defaultValue={"Ryd filtre"}
                    />
                  </LexButton>
                ) : null}
              </FlexElement>
            </Flexbox>
          </Row>
        ) : null}
      </React.Fragment>
    );
  };

  onClearFilters = () => {
    let { setSelectedContact } = this.props;

    setSelectedContact({});
    this.setState(
      {
        selectedCase: {},
        selectedLabelId: 0,
        selectedFromDate: null,
        selectedToDate: null,
      },
      () => this.updateShownEntries({ debounced: true })
    );
  };

  renderCreateLabelInput = () => {
    return (
      <FlexElement
        onClick={this.checkForDisabledInput}
        style={{ paddingRight: "15px" }}
      >
        <div>
          <Icon className="Doccolor3" glyph="icon-fontello-plus-4" />
          &ensp;&ensp;
          <AxoLocal
            className="Doccolor3"
            entity="DocumentAddtonewfolder"
            defaultValue={"Tilføj til ny mappe"}
          />{" "}
        </div>
        <InputGroup style={{ maxWidth: "240px" }}>
          <AxoLocal
            componentClass={FormControl}
            type="text"
            style={{
              borderColor: "#009900",
              borderWidth: "1px",
              borderRadius: "4px",
            }}
            disabled={this.state.selectedDocuments.size === 0}
            value={this.state.newLabelName}
            onChange={(event) => {
              this.setState({ newLabelName: event.target.value });
            }}
            componentAttribute="placeholder"
            entity="placeholderEnterthefoldername"
          />
          <InputGroup.Addon className="plain">
            <Button
              className="Lex-button-2"
              style={{ width: "100% " }}
              onClick={this.onCreateLabel}
            >
              <AxoLocal entity="ADDViewCreate" defaultValue={"Opret"} />
              {this.state.showCreatedLabelIcon ? (
                <span>
                  &nbsp;
                  <Icon className="axoblue" glyph="icon-fontello-ok-3" /> &nbsp;
                  &nbsp;
                </span>
              ) : null}
            </Button>
          </InputGroup.Addon>
        </InputGroup>
      </FlexElement>
    );
  };

  renderDocumentTypeField = (document) => {
    var fileType = document.fileType.substring(1, document.fileType.length);
    if (fileType === "pdf") {
      return <Icon glyph="icon-fontello-acrobat" />;
    }
    if (fileType === "doc" || fileType === "docx") {
      return (
        <span>
          <Icon glyph="icon-fontello-doc-7" />
        </span>
      );
    }
    if (FileInfoService.isImage(document)) {
      return (
        <span>
          <Icon glyph="icon-fontello-picture-1" /> {fileType}
        </span>
      );
    }
    return fileType;
  };

  onSearch = (event) => {
    this.setState({ searchText: event.target.value }, () =>
      this.updateShownEntries({ debounced: true })
    );
  };

  onShowSelectedLabelDocuments = (selectedLabel) => {
    this.setState({ selectedLabelId: selectedLabel.id }, () =>
      this.updateShownEntries({ debounced: false })
    );
  };

  onSelectClient = (selectedContact) => {
    let { setSelectedContact } = this.props;
    setSelectedContact(selectedContact);
  };

  updateShownEntries = ({ debounced }) => {
    let { queryDocuments, selectedContact } = this.props;
    let {
      tableEntries,
      selectedCase,
      selectedLabelId,
      searchText,
      selectedFromDate,
      selectedToDate,
    } = this.state;
    let query = {
      searchText,
      selectedCase,
      selectedLabelId,
      selectedContact,
      selectedFromDate,
      selectedToDate,
      debounced,
    };
    let documents = this.getFilteredDocuments(tableEntries, query);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(documents);
    this.setState({
      searchText,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, documents),
    });

    let { count } = this.props;
    if (tableEntries.length < count) {
      queryDocuments(query);
    }
  };

  getFilteredDocuments = (entries, query) => {
    let {
      searchText,
      selectedCase,
      selectedLabelId,
      selectedContact,
      selectedFromDate,
      selectedToDate,
    } = query;

    if (
      !searchText &&
      !selectedCase.id &&
      !selectedLabelId &&
      !selectedContact.id &&
      !selectedFromDate &&
      !selectedToDate
    ) {
      return entries;
    }
    var filteredEntries = entries.filter((d) => {
      return d.fileName.toLowerCase().includes(searchText.toLowerCase());
    });
    if (selectedCase.id) {
      filteredEntries = filteredEntries.filter((entry) => {
        return !!entry.cases.find(
          (caseModel) => caseModel.id === selectedCase.id
        );
      });
    }
    if (!!selectedLabelId) {
      if (selectedLabelId === -1) {
        //Documents not in any label
        let idsInLabels = new Set();
        this.props.labels.forEach((label) => {
          label.documentIds.forEach((id) => {
            idsInLabels.add(id);
          });
        });
        filteredEntries = filteredEntries.filter(
          (entry) => !idsInLabels.has(entry.id)
        );
      } else {
        let selectedLabel = this.props.labels.find(
          (l) => l.id === selectedLabelId
        ) || { documentIds: [] };
        filteredEntries = filteredEntries.filter((entry) => {
          return !!selectedLabel.documentIds.find((id) => id === entry.id);
        });
      }
    }
    if (selectedContact.id) {
      filteredEntries = filteredEntries.filter((entry) => {
        return !!entry.client && entry.client.id === selectedContact.id;
      });
    }
    if (selectedFromDate) {
      filteredEntries = filteredEntries.filter((entry) => {
        return moment(entry.uploadDate) >= selectedFromDate;
      });
    }
    if (selectedToDate) {
      filteredEntries = filteredEntries.filter((entry) => {
        return moment(entry.uploadDate) <= selectedToDate;
      });
    }

    return filteredEntries;
  };

  _rowClassNameGetter = (rowIndex) => {
    let { displayedEntryId, sortedDataList } = this.state;

    var activeDocument = sortedDataList.getObjectAt(rowIndex);
    if (displayedEntryId === activeDocument.id) {
      return "highlight-row";
    }
    if (activeDocument && activeDocument.id === this.props.activeDocumentId) {
      return "highlight-row";
    }
  };

  renderLabelSelect = (document) => {
    if (document.labels.length === 0) {
      return null;
    }
    var selectLabelNodes = document.labels.map((label) => {
      return (
        <option key={label.id} value={label.id}>
          {label.name}
        </option>
      );
    });
    return (
      <Flexbox>
        <FlexElement flexBasis="25px" flexGrow={1}>
          <select
            className="axoblue selectbg"
            style={{ border: "none", width: "100%" }}
            value="cases"
            onChange={() => {}}
          >
            {selectLabelNodes}
          </select>
        </FlexElement>
        <FlexElement flexBasis="25px">
          &nbsp; ({document.labels.length})
        </FlexElement>
      </Flexbox>
    );
  };

  renderCaseSelect = (document) => {
    if (document.cases.length === 0) {
      return null;
    }
    var selectCasesNodes = document.cases.map((caseModel) => {
      return (
        <option key={caseModel.id} value={caseModel.id}>
          {caseModel.caseNumber}
        </option>
      );
    });
    return (
      <Flexbox>
        <FlexElement flexBasis="25px" flexGrow={1}>
          <select
            className="axoblue selectbg"
            style={{ border: "none", width: "100%" }}
            value="cases"
            onChange={() => {}}
          >
            {selectCasesNodes}
          </select>
        </FlexElement>
        <FlexElement flexBasis="25px">
          &nbsp; ({document.cases.length})
        </FlexElement>
      </Flexbox>
    );
  };

  renderColumnDropdown = (userType) => {
    let { columnDefs } = this.state;
    var allEnabled = !this.toggleColumns.find((col) => !columnDefs[col].shown);

    return (
      <DropdownButton
        id="ColumnDropdown"
        className="visButtoncolor axoDropdownButton VisButton"
        title={
          <span style={{ fontSize: "15px" }}>
            <AxoLocal entity="axoAccounting70" defaultValue={"Vis"} />
          </span>
        }
      >
        <MenuItem eventKey="all">
          <div
            className="text-center"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <AxoCheckbox
              checked={allEnabled}
              onChange={this.onEnableAll.bind(this, !allEnabled)}
            />
            <AxoLocal entity="axoidcode124" defaultValue={"Vis alle"} />
          </div>
        </MenuItem>
        {this.toggleColumns.map((column) => {
          return (
            <MenuItem key={column} eventKey={column}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <AxoCheckbox
                  checked={columnDefs[column].shown}
                  onChange={this.onSelectColumn.bind(this, column)}
                />
                &nbsp;{this.getColumnTitle(column, userType)}
              </div>
            </MenuItem>
          );
        })}
      </DropdownButton>
    );
  };

  getColumnTitle = (columnName, userType) => {
    switch (columnName) {
      case "fileName":
        return (
          <AxoLocal
            entity="CaseDataTableFixedFilename"
            defaultValue={"Filnavn"}
          />
        );
      case "fileType":
        return <AxoLocal entity="DocumentGridViewType" defaultValue={"Type"} />;
      case "uploadDate":
        return (
          <AxoLocal
            entity="DocumentGridViewuploadDate"
            defaultValue={"Upload dato"}
          />
        );
      case "status":
        return (
          <AxoLocal
            entity="CaseEditInlineStatusSelect"
            defaultValue={"Status"}
          />
        );
      case "size":
        return <>Størrelse</>;
      case "priority":
        return (
          <AxoLocal
            entity="CaseEditFormcasepriority"
            defaultValue={"Prioritet"}
          />
        );
      case "deadline":
        return (
          <AxoLocal
            entity="DocumentGridViewDateCell"
            defaultValue={"Påmindelse"}
          />
        );
      case "labelCol":
        return (
          <AxoLocal
            entity="CaseDataTableFixedThefolders"
            defaultValue={"Mapper"}
          />
        );
      case "casesCol":
        return (
          <AxoLocal
            entity="DocumentGridViewCaseString"
            defaultValue={"Tilhører sagerne"}
          />
        );
      case "clientName":
        return userType === "Society" ? (
          <>
            <AxoLocal entity="axoidcode230" defaultValue={"Medlem"} />
          </>
        ) : (
          <AxoLocal entity="CaseDataTableFixedClient" defaultValue={"Klient"} />
        );
      default:
        return <span></span>;
    }
  };

  onDeleteSelected = () => {
    this.setState({
      deletingSelected: true,
    });

    let deletionPromises = [];
    this.state.selectedDocuments.forEach((id) => {
      let doc = this.state.tableEntries.find((d) => d.id === id);
      doc.trashed = true;
      deletionPromises.push(DataActions.updateDocument(doc));
    });
    Promise.all(deletionPromises)
      .then((responses) => {
        this.setState({
          selectedDocuments: new Set(),
          deletingSelected: false,
        });
      })
      .catch((reason) => {
        this.setState({
          selectedDocuments: new Set(),
          deletingSelected: false,
        });
      });
  };

  render() {
    let {
      forceShowCards,
      documents,
      count,
      selectedContact,
      onFilesSubmitted,
      userProfile,
    } = this.props;
    let {
      selectedDocuments,
      selectedCase,
      selectedLabelId,
      selectedFromDate,
      selectedToDate,
      deletingSelected,
      showControls,
      displayedEntryId,
    } = this.state;

    let selectedEntry = documents.find((e) => displayedEntryId === e.id);

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <AxoDropzone onDrop={(files) => onFilesSubmitted(files, selectedLabelId)}>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <Flexbox
                  spaceBetween
                  alignCenter
                  responsive
                  className="rightPadding leftPadding"
                >
                  <Flexbox alignCenter responsive>
                    <h4>
                      <div>
                        <Icon glyph="icon-fontello-doc-7" />
                        &nbsp;&nbsp;
                        {!!selectedContact.id ? (
                          <span>
                            {selectedContact.firstName}{" "}
                            {selectedContact.lastName}
                          </span>
                        ) : (
                          <AxoLocal
                            entity="DocumentTableFixdViewdocuments"
                            defaultValue={"Dokumenter"}
                          />
                        )}
                      </div>
                    </h4>
                    {!!selectedCase.id ||
                    !!selectedLabelId ||
                    !!selectedContact.id ||
                    !!selectedFromDate ||
                    !!selectedToDate ? (
                      <FlexElement className="leftPadding">
                        <LexButton
                          style={{ height: "30px" }}
                          onClick={this.onClearFilters}
                        >
                          <AxoLocal
                            entity="axoAccounting71"
                            defaultValue={"Ryd filtre"}
                          />
                        </LexButton>
                      </FlexElement>
                    ) : null}
                    <FlexElement className="rightPadding">
                      <PaginationControls
                        length={documents.length}
                        count={count}
                        onShowMore={() => DataStore.increaseDocumentPageSize()}
                      />
                    </FlexElement>
                    <FlexElement className="rightPadding">
                      <LexButton
                        disabled={selectedDocuments.size === 0}
                        onClick={this.onDeleteSelected}
                      >
                        <Icon glyph="icon-fontello-trash-1" />
                        &nbsp;
                        <AxoLocal
                          entity="updategetAccountName7a"
                          defaultValue={"Slet valgte"}
                        />
                        <LoadingIcon show={deletingSelected} />
                      </LexButton>
                    </FlexElement>
                    {this.state.showDisabledWarning ? (
                      <FlexElement className="axored rightPadding">
                        &nbsp;
                        <AxoLocal
                          style={{ fontSize: "14px" }}
                          entity="DocumentTableFixdViewshowDisabledWarning"
                          defaultValue={"Select a Document"}
                        />
                        &#8594; <AxoCheckbox />
                      </FlexElement>
                    ) : null}
                    <FlexElement className="axocolorbutton Colorbutton documenticoncolor">
                      <LexButton
                        style={{ width: "160px" }}
                        onClick={() =>
                          this.setState({ showControls: !showControls })
                        }
                      >
                        {showControls ? (
                          <span>
                            <Icon
                              className="Colorbuttonicon"
                              glyph="icon-fontello-angle-double-up"
                            />
                            &nbsp;&nbsp;
                            <AxoLocal
                              key="hide"
                              entity="axoAccounting77"
                              defaultValue={"Skjul kontrolpanel"}
                            />
                          </span>
                        ) : (
                          <span>
                            <Icon
                              className="Colorbuttonicon"
                              glyph="icon-fontello-angle-double-down"
                            />
                            &nbsp;&nbsp;
                            <AxoLocal
                              key="show"
                              entity="axoAccounting79"
                              defaultValue={"Vis kontrolpanel"}
                            />
                          </span>
                        )}
                      </LexButton>
                    </FlexElement>
                  </Flexbox>
                  <div>
                    <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                      <FormGroup controlId="inputWithIcon">
                        <InputGroup>
                          <InputGroup.Addon>
                            <Icon glyph="icon-fontello-search" />
                          </InputGroup.Addon>
                          {/* Søg...  */}
                          <AxoLocal
                            componentClass={FormControl}
                            type="text"
                            value={this.state.searchText}
                            onChange={this.onSearch}
                            componentAttribute="placeholder"
                            entity="ContactTableViewplaceholderonSearch"
                          />
                          <FormControl.Feedback>
                            <Icon glyph="icon-fontello-search" />
                          </FormControl.Feedback>
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                  {this.renderColumnDropdown(userType)}
                  <div
                    className="text-right hidden-xs"
                    style={{ paddingTop: "10px" }}
                  >
                    <Button
                      className="Lex-button-2"
                      onClick={this.props.onToggleCards}
                    >
                      {this.props.forceShowCards ? (
                        <span>
                          {" "}
                          <Icon glyph="icon-fontello-grid" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            key="showTable"
                            entity="CaseDataTableFixedShowastable"
                            defaultValue={"Vis som tabel"}
                          />{" "}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <Icon glyph="icon-fontello-th-thumb-empty" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            key="showCards"
                            entity="CaseDataTableFixedShowCards"
                            defaultValue={"Vis som kartotekskort"}
                          />
                        </span>
                      )}
                    </Button>
                  </div>
                </Flexbox>
              </Row>
            </Grid>
          </div>
          {forceShowCards ? (
            this.renderGridView()
          ) : (
            <div>
              <ExtraSmall>{this.renderGridView()}</ExtraSmall>
              <SmallOrLarger>
                <Flexbox>
                  <FlexElement>{this.renderTable(!!selectedEntry)}</FlexElement>
                  {!!selectedEntry ? (
                    <FlexElement flexGrow={1} className="leftPadding">
                      <FileViewer key={selectedEntry.id} file={selectedEntry} />
                    </FlexElement>
                  ) : null}
                </Flexbox>
              </SmallOrLarger>
            </div>
          )}
        </div>
      </AxoDropzone>
    );
  }

  renderGridView = () => {
    let { selectedDocuments } = this.state;

    return (
      <div>
        {this.renderInputBoxes()}
        <div style={{ paddingTop: "10px" }}>
          <DocumentGridView
            documents={this.state.sortedDataList._data}
            activeDocumentId={this.props.activeDocumentId}
            labels={this.props.labels}
            cases={this.props.caseMap.saved}
            friends={this.props.friends}
            height={this.props.height}
            onDocumentClick={this.onClickFileName}
            l
            onStatusSelect={this.onStatusSelect}
            onPrioritySelect={this.onPrioritySelect}
            onDeadlineChange={this.onDeadlineChange}
            removeDeadlineFromDocument={this.removeDeadlineFromDocument}
            addDeadlineToDocument={this.addDeadlineToDocument}
            onTrashFile={this.props.onTrashFile}
            onSendFile={this.props.onSendFile}
            getDisplayName={this.getDisplayName}
            selectedDocuments={selectedDocuments}
            onSelectDocument={this.onSelectDocument}
          />
        </div>
      </div>
    );
  };

  onRemoveClient = (document) => {
    DataActions.updateDocument({
      ...document,
      client: null,
      clientName: "",
      clientId: null,
    });
  };

  onSelectAll = () => {
    let { selectedDocuments, sortedDataList } = this.state;
    let allSelected = selectedDocuments.size === sortedDataList.getSize();

    if (allSelected) {
      this.setState({ selectedDocuments: new Set() });
    } else {
      this.setState({
        selectedDocuments: new Set(sortedDataList._data.map((d) => d.id)),
      });
    }
  };

  renderTable = (allowSpaceForPicture) => {
    var { sortedDataList, colSortDirs, columnDefs, showControls } = this.state;
    const { containerHeight, containerWidth, userProfile } = this.props;
    var tableHeight =
      this.props.height ||
      (showControls ? containerHeight - 325 : containerHeight - 200);
    var tableWidth = containerWidth - 15;
    let userType = AuthorizationService.getUserType(userProfile);
    if (allowSpaceForPicture) {
      tableWidth = containerWidth * 0.5;
    }

    return (
      <div style={{ paddingTop: "0px" }}>
        <Grid fluid>
          {this.renderInputBoxes()}
          <Row style={{ paddingTop: "5px", paddingLeft: "10px" }}>
            <Col xs={12} style={{ padding: "0px" }}>
              <Table
                rowHeight={40}
                onRowDoubleClick={(event, index) =>
                  this.onDisplayEntry(sortedDataList.getObjectAt(index).id)
                }
                rowsCount={sortedDataList.getSize()}
                rowClassNameGetter={this._rowClassNameGetter}
                height={tableHeight}
                width={tableWidth}
                isColumnResizing={false}
                onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                touchScrollEnabled={true}
                headerHeight={40}
              >
                <Column
                  columnKey="select"
                  header={
                    <Cell className="text-center">
                      <AxoCheckbox
                        checked={
                          this.state.selectedDocuments.size > 0 &&
                          this.state.selectedDocuments.size ===
                            sortedDataList.getSize()
                        }
                        onChange={this.onSelectAll}
                      />
                    </Cell>
                  }
                  cell={(props) => {
                    var document = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell
                        key={document.id}
                        className="text-center"
                        {...props}
                      >
                        <AxoCheckbox
                          checked={this.state.selectedDocuments.has(
                            document.id
                          )}
                          onChange={this.onSelectDocument.bind(
                            this,
                            document.id
                          )}
                        />
                      </Cell>
                    );
                  }}
                  width={columnDefs.select.width}
                  isResizable={true}
                />
                {columnDefs.fileName.shown ? (
                  <Column
                    columnKey="fileName"
                    header={
                      <SortHeaderCell
                        altIcon={<Icon glyph="icon-fontello-docs-1" />}
                        showAltIcon={columnDefs.fileName.width < 75}
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.fileName}
                      >
                        <Icon
                          className="editable"
                          glyph="icon-fontello-arrow-combo"
                        />
                        &nbsp;
                        <AxoLocal
                          entity="CaseDataTableFixedFilename"
                          defaultValue={"Filnavn"}
                        />
                      </SortHeaderCell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          <a
                            href="document"
                            onClick={this.onClickFileName.bind(
                              this,
                              document.id
                            )}
                          >
                            {this.getDisplayName(document)}
                          </a>
                          {document.sharedUsers.length > 0 ? (
                            <span>
                              &ensp;
                              <Icon
                                role="button"
                                onClick={this.props.onClickShared.bind(
                                  this,
                                  document.id
                                )}
                                className="editable"
                                glyph="icon-fontello-share-1"
                              />
                            </span>
                          ) : null}
                        </Cell>
                      );
                    }}
                    width={columnDefs.fileName.width}
                    isResizable={true}
                  />
                ) : null}
                {columnDefs.size.shown ? (
                  <Column
                    columnKey="size"
                    header={
                      <SortHeaderCell
                        altIcon={<Icon glyph="icon-fontello-stop-1" />}
                        showAltIcon={columnDefs.size.width < 75}
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.size}
                      >
                        <Icon
                          className="editable"
                          glyph="icon-fontello-arrow-combo"
                        />
                        &nbsp; Størrelse
                      </SortHeaderCell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell
                          key={document.id}
                          {...props}
                          className="text-right rightPadding"
                        >
                          {document.sizeString}
                        </Cell>
                      );
                    }}
                    width={columnDefs.size.width}
                    isResizable={true}
                  />
                ) : null}
                {columnDefs.status.shown ? (
                  <Column
                    columnKey="status"
                    header={
                      <SortHeaderCell
                        altIcon={<Icon glyph="icon-fontello-stop-1" />}
                        showAltIcon={columnDefs.status.width < 75}
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.status}
                      >
                        <Icon
                          className="editable"
                          glyph="icon-fontello-arrow-combo"
                        />
                        &nbsp;
                        <AxoLocal
                          entity="CaseEditInlineStatusSelect"
                          defaultValue={"Status"}
                        />
                      </SortHeaderCell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          <div className="flexbox-standard">
                            {this.getStatusIcon(document.status)}
                            {this.renderStatusSelect(document)}
                          </div>
                        </Cell>
                      );
                    }}
                    width={columnDefs.status.width}
                    isResizable={true}
                  />
                ) : null}
                {columnDefs.priority.shown ? (
                  <Column
                    columnKey="priority"
                    header={
                      <SortHeaderCell
                        altIcon={<Icon glyph="icon-fontello-circle" />}
                        showAltIcon={columnDefs.priority.width < 75}
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.priority}
                      >
                        <Icon
                          className="editable"
                          glyph="icon-fontello-arrow-combo"
                        />
                        &nbsp;
                        <AxoLocal
                          entity="CaseEditFormcasepriority"
                          defaultValue={"Prioritet"}
                        />
                      </SortHeaderCell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          <div className="flexbox-standard">
                            {this.getPriorityIcon(document.priority)}
                            {this.renderPrioritySelect(document)}
                          </div>
                        </Cell>
                      );
                    }}
                    width={columnDefs.priority.width}
                    isResizable={true}
                  />
                ) : null}
                {userType !== "Society" && columnDefs.deadline.shown ? (
                  <Column
                    columnKey="deadline"
                    header={
                      <SortHeaderCell
                        altIcon={<Icon glyph="icon-fontello-bell-alt" />}
                        showAltIcon={columnDefs.deadline.width < 75}
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.deadline}
                      >
                        <Icon
                          className="editable"
                          glyph="icon-fontello-arrow-combo"
                        />
                        &nbsp;
                        <AxoLocal
                          entity="DocumentGridViewDateCell"
                          defaultValue={"Påmindelse"}
                        />
                      </SortHeaderCell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          {this.renderDateCell(document)}
                        </Cell>
                      );
                    }}
                    width={columnDefs.deadline.width}
                    isResizable={true}
                  />
                ) : null}
                {columnDefs.clientName.shown ? (
                  <Column
                    columnKey="clientName"
                    header={
                      <SortHeaderCell
                        altIcon={<Icon glyph="icon-fontello-bell-alt" />}
                        showAltIcon={columnDefs.clientName.width < 75}
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.clientName}
                      >
                        <Icon
                          className="editable"
                          glyph="icon-fontello-arrow-combo"
                        />
                        &nbsp;
                        {userType === "Society" ? (
                          <>
                            {" "}
                            <AxoLocal
                              entity="axoidcode230"
                              defaultValue={"Medlem"}
                            />
                          </>
                        ) : (
                          <AxoLocal
                            entity="CaseDataTableFixedClient"
                            defaultValue={"Klient"}
                          />
                        )}
                      </SortHeaderCell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          {!!document.clientName ? (
                            <Flexbox>
                              <FlexElement flexGrow={1}>
                                {document.clientName}
                              </FlexElement>
                              {!document.receiptNumber ? (
                                <FlexElement>
                                  <Icon
                                    glyph="icon-fontello-scissors"
                                    title={
                                      userType === "Society"
                                        ? getText(
                                            "axoidcode231",
                                            "Fjern medlem"
                                          )
                                        : getText("axoidcode10", "Fjern klient")
                                    }
                                    role="button"
                                    onClick={() =>
                                      this.onRemoveClient(document)
                                    }
                                  />
                                  &nbsp;
                                </FlexElement>
                              ) : null}
                            </Flexbox>
                          ) : null}
                        </Cell>
                      );
                    }}
                    width={columnDefs.clientName.width}
                    isResizable={true}
                  />
                ) : null}
                {userType !== "Accountant" && columnDefs.casesCol.shown ? (
                  <Column
                    columnKey="casesCol"
                    header={
                      <Cell>
                        <AxoLocal
                          entity="DocumentGridViewCaseString"
                          defaultValue={"Tihører sagerne"}
                        />
                      </Cell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          {this.renderCaseSelect(document)}
                        </Cell>
                      );
                    }}
                    width={columnDefs.casesCol.width}
                    isResizable={true}
                  />
                ) : null}
                {columnDefs.labelCol.shown ? (
                  <Column
                    columnKey="labelCol"
                    header={
                      <Cell>
                        <AxoLocal
                          entity="CaseDataTableFixedThefolders"
                          defaultValue={"Mapper"}
                        />
                      </Cell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          {this.renderLabelSelect(document)}
                        </Cell>
                      );
                    }}
                    width={columnDefs.labelCol.width}
                    isResizable={true}
                  />
                ) : null}
                <Column
                  columnKey="actions"
                  header={<Cell></Cell>}
                  cell={(props) => {
                    var document = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={document.id} {...props}>
                        {this.renderButtons(document)}
                      </Cell>
                    );
                  }}
                  width={columnDefs.actions.width}
                  isResizable={true}
                />
                {columnDefs.uploadDate.shown ? (
                  <Column
                    columnKey="uploadDate"
                    header={
                      <SortHeaderCell
                        altIcon={<Icon glyph="icon-fontello-calendar-8" />}
                        showAltIcon={columnDefs.uploadDate.width}
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.uploadDate}
                      >
                        <Icon
                          className="editable"
                          glyph="icon-fontello-arrow-combo"
                        />
                        &nbsp;
                        <AxoLocal
                          entity="DocumentGridViewuploadDate"
                          defaultValue={"Upload dato"}
                        />
                      </SortHeaderCell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          {moment(document.uploadDate).format("L")}
                        </Cell>
                      );
                    }}
                    width={columnDefs.uploadDate.width}
                    isResizable={true}
                  />
                ) : null}
                {columnDefs.fileType.shown ? (
                  <Column
                    columnKey="fileType"
                    header={
                      <SortHeaderCell
                        altIcon={<Icon glyph="icon-fontello-acrobat" />}
                        showAltIcon={columnDefs.fileType.width < 75}
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.fileType}
                      >
                        <Icon
                          className="editable"
                          glyph="icon-fontello-arrow-combo"
                        />
                        &nbsp;
                        <AxoLocal
                          entity="DocumentGridViewType"
                          defaultValue={"Type"}
                        />
                      </SortHeaderCell>
                    }
                    cell={(props) => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          {this.renderDocumentTypeField(document)}
                        </Cell>
                      );
                    }}
                    width={columnDefs.fileType.width}
                    isResizable={true}
                  />
                ) : null}
              </Table>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

class DocumentTableFixedView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deletingDocumentId: 0,
    };
  }

  componentWillReceiveProps() {
    if (this.state.deletingDocumentId > 0) {
      this.setState({ deletingDocumentId: 0 });
    }
  }

  componentWillUnmount() {
    //Reset document filters when leaving the page
    let query = DataStore.getStore().documentQuery;
    if (Object.keys(query).length > 0) {
      this.props.queryDocuments({});
    }
  }

  onGetFile = (id) => {
    let { documents } = this.props;

    var selectedFileIndex = documents.findIndex(
      (file) => file.id === parseInt(id, 10)
    );
    this.fileModal.open(documents, {
      messageId: null,
      fileIndex: selectedFileIndex,
    });
  };

  onTrashFile = (documentId) => {
    var userProfile = this.props.userProfile;
    if (
      (userProfile.profileImage &&
        userProfile.profileImage.id === documentId) ||
      (userProfile.logo && userProfile.logo.id === documentId)
    ) {
      this.confirmModal.open(
        <AxoLocal
          entity="CaseDataTableFixeduserProfile"
          defaultValue={
            "Billedet anvendes som logo eller profilbillede. Er du sikker på at du vil slette det?"
          }
        />,
        (reply) => {
          if (reply) {
            this.removeImageReference(userProfile, documentId);
            this.doTrashFile(documentId);
          }
        }
      );
    } else {
      this.doTrashFile(documentId);
    }
  };

  removeImageReference = (userProfile, documentId) => {
    if (userProfile.logo && userProfile.logo.id === documentId) {
      userProfile.logo = null;
    }
    if (
      userProfile.profileImage &&
      userProfile.profileImage.id === documentId
    ) {
      userProfile.profileImage = null;
    }
    DataActions.updateUserProfile(userProfile);
  };

  doTrashFile = (documentId) => {
    let document = this.props.documents.find(
      (d) => d.id === parseInt(documentId, 10)
    );
    if (!document) {
      //Can happen if the user click the button rapidly.
      return;
    }
    if (document.trashed) {
      this.deleteFile(document.id);
    }
    this.setState({ deletingDocumentId: documentId });
    let newDoc = { ...document };
    newDoc.trashed = true;
    DataActions.updateDocument(newDoc).then((response) => {
      if (!response.ok) {
        this.setState({ deletingDocumentId: 0 });
      }
    });
  };

  deleteFile = (documentId) => {
    ModalService.openConfirmModal(
      <AxoLocal
        entity="CaseDataTableFixedopenConfirmModal"
        defaultValue={"Vil du slette dokumentet permanent?"}
      />,
      (value) => {
        if (value) {
          DataActions.deleteDocument(documentId);
        }
      }
    );
  };

  onRestoreFile = (documentId) => {
    let document = {
      ...this.props.documents.find((d) => d.id === parseInt(documentId, 10)),
    };
    document.trashed = false;
    DataActions.updateDocument(document);
  };

  onGoToCase = (caseId) => {
    this.props.history.push(RoutingService.getPath("Cases/case/" + caseId));
  };

  onSendFile = (documentId, selectedDocuments) => {
    let { documents } = this.props;

    var document = documents.find((d) => d.id === documentId);
    if (!document) {
      return;
    }

    let attachments = [document];
    if (selectedDocuments.size > 0) {
      attachments = attachments.concat(
        documents.filter(
          (d) => selectedDocuments.has(d.id) && d.id !== documentId
        )
      );
    }

    DataStore.setCachedMessage(
      MessageService.makeAttachmentMessage(attachments)
    );
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  renderDocumentTableComponent = () => {
    return (
      <DocumentTableFixedComponent
        {...this.props}
        onActivate={this.onGetFile}
        onTrashFile={this.onTrashFile}
        onRestoreFile={this.onRestoreFile}
        onCaseClick={this.onGoToCase}
        onSendFile={this.onSendFile}
        deletingDocumentId={this.state.deletingDocumentId}
      />
    );
  };

  render() {
    return (
      <div style={{ paddingTop: "10px" }}>
        {this.renderDocumentTableComponent()}
        <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
        <AlertModal ref={(c) => (this.alertModal = c)} />
        <ConfirmModal ref={(c) => (this.confirmModal = c)} />
      </div>
    );
  }
}

export default withRouter(
  Dimensions({
    elementResize: true,
    getHeight: function (element) {
      return window.innerHeight;
    },
  })(DocumentTableFixedView)
);
