import React from "react";
import Packages from "./Packages";

import { DataStore } from "../services/AxoServices";

import { Consumer } from "../utilities/LexUtilities";

export default class PackagesContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserProfile();
    DataStore.fetchSubscriptionPlans();
    DataStore.fetchSubscriptionFormulas();
    DataStore.fetchGlobalSettings();
  }

  getStandardPlans(subscriptionPlans) {
    let standardPlans = subscriptionPlans.filter(
      (sub) => sub.type === "Standard" || sub.type === "Custom"
    );

    let sortedPlans = standardPlans.sort((p1, p2) => {
      if (p1.userGroupSize !== p2.userGroupSize) {
        return p1.userGroupSize - p2.userGroupSize;
      }
      if (p1.amount !== p2.amount) {
        return p1.amount - p2.amount;
      }
      return 0;
    });

    return sortedPlans;
  }

  render() {
    return (
      <Consumer>
        {(store) => (
          <Packages
            userProfile={store.userProfile}
            subscriptionPlans={this.getStandardPlans(store.subscriptionPlans)}
            subscriptionFormulas={store.subscriptionFormulas}
            globalSettings={store.globalSettings}
            locale={store.locale}
          />
        )}
      </Consumer>
    );
  }
}
