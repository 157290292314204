import React from "react";
import { Col } from "react-bootstrap";
import { Icon, AxoLocal } from "../utilities/LexUtilities";

export default class IndividualPackage extends React.PureComponent {
  render() {
    return (
      <div className="text-center" style={{ minHeight: "800px" }}>
        <Col sm={8} smOffset={2} xs={12} className="text-center nopadding">
          <div
            className="table-responsive"
            style={{ padding: "0px 10px 0px 10px" }}
          >
            <table className="table" style={{ MaxWidth: "700px" }}>
              <thead>
                <tr>
                  <th className=" text-left">
                    <h4>
                      <AxoLocal
                        entity="FrontPageindextaxt8"
                        defaultValue={"Komplet pakkeløsning (Multi-sprog)"}
                      />
                    </h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className=" text-left AxoPackagetd">
                    <AxoLocal
                      entity="FrontPageindextaxt9"
                      defaultValue={"Mailsystem"}
                    />{" "}
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>
                        &#9724;
                        <AxoLocal
                          entity="IndividualPackagetext1"
                          defaultValue={"Multi-sprog"}
                        />{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&radic;{" "}
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext2"
                          defaultValue={"Integrer dokumenter til sager"}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&radic;{" "}
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext3"
                          defaultValue={"Intern post"}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&radic;{" "}
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext4"
                          defaultValue={"Kryptering"}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&radic;{" "}
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext5"
                          defaultValue={"Ekstern mailhåndtering"}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&radic;{" "}
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext6"
                          defaultValue={"Del filer"}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&radic;{" "}
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext7"
                          defaultValue={"Mailhåndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext8"
                          defaultValue={"Mailmapper"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext9"
                          defaultValue={"Sofistikeret tekst editor"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext10"
                          defaultValue={"Importer word dokumenter"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext11"
                          defaultValue={"Online backup"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext12"
                          defaultValue={"Auto klientliste"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext13"
                          defaultValue={"Auto brugerliste"}
                        />
                      </div>
                    </div>
                  </td>
                  <td className=" text-left">
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className=" text-left AxoPackagetd">
                    <AxoLocal
                      entity="FrontPageindextaxt11"
                      defaultValue={"Fagligt netværk"}
                    />
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext14"
                          defaultValue={"Internationalt fagligt netværk"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext15"
                          defaultValue={"Internt fagligt netværk"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext16"
                          defaultValue={"Kontakthåndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext17"
                          defaultValue={"Offentlige indlæg"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext18"
                          defaultValue={"Interne indlæg"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext19"
                          defaultValue={"Importer .docx dokumenter"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext20"
                          defaultValue={"Særskilt social kontaktliste"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext21"
                          defaultValue={"Multi-sprog"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext22"
                          defaultValue={"Ubegrænset private konferencerum"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext23"
                          defaultValue={"Sofistikeret tekstbehandling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext24"
                          defaultValue={"Publicer fagligt materiale"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext25"
                          defaultValue={"Fildeling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext26"
                          defaultValue={"Filhåndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext28"
                          defaultValue={"Integrer filer med dokumenthåndtering"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>&#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className=" text-left AxoPackagetd">
                    <AxoLocal
                      entity="FrontPageindextaxt12"
                      defaultValue={"Sagsstyringssystem"}
                    />
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div
                      style={{ width: "280px" }}
                      className="text-left frontPageaxolexdivtextbox"
                    >
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext29"
                          defaultValue={"Multi-sprog"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext30"
                          defaultValue={"Ubegrænset sagsfremstilling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext31"
                          defaultValue={"Sagsopsætning"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext32"
                          defaultValue={"Centralisering af sagsmateriale"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext33"
                          defaultValue={"Prioritet og status håndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext34"
                          defaultValue={"Tilknyt klient"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext35"
                          defaultValue={"Beskriv modparter"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext36"
                          defaultValue={"Sagsfremstilling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext37"
                          defaultValue={"Importer sagsfremstilling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext38"
                          defaultValue={"Printhåndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext39"
                          defaultValue={"Avanceret word processing"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext40"
                          defaultValue={"Central sagsoversigt"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext41"
                          defaultValue={"Kartotekskort oversigt"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext42"
                          defaultValue={"Filtrering og søgning"}
                        />{" "}
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext44"
                          defaultValue={"Inline redigering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext45"
                          defaultValue={"Sagsdokumenthåndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext46"
                          defaultValue={"Tilknyt dokumenter til sag"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext47"
                          defaultValue={"Administrer sagsdokumenter"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext48"
                          defaultValue={"Registrer sagsbehandlingstid"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext49"
                          defaultValue={"Påmindelse"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext50"
                          defaultValue={"Integreret med økonomihåndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext51"
                          defaultValue={"Integreret med kalendersystem"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>&#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className=" text-left AxoPackagetd">
                    {" "}
                    <AxoLocal
                      entity="IndividualPackagetext167"
                      defaultValue={"Klient databehandling"}
                    />{" "}
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext168"
                          defaultValue={"Ubegrænset klientregistrering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext169"
                          defaultValue={"Klient type oversigt"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext171"
                          defaultValue={"Central klientoversigt"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="CaseDataTableFixedShowCards"
                          defaultValue={"Vis som kartotekskort"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext173"
                          defaultValue={"Søgning og sortering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext174"
                          defaultValue={"Fuld inline redigering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext175"
                          defaultValue={"Tilføj selvbetjening"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext176"
                          defaultValue={"Klient selvbetjeningspanel"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext177"
                          defaultValue={"Multi-sprog"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext178"
                          defaultValue={"Integreret med sagsbehandling"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>&#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className=" text-left AxoPackagetd">
                    <AxoLocal
                      entity="FrontPageindextaxt16"
                      defaultValue={"Klient selvbetjeningspanel"}
                    />
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext53"
                          defaultValue={"Internt mailprogram"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext54"
                          defaultValue={"Send krypterede beskeder"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext55"
                          defaultValue={"Booking system"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext56"
                          defaultValue={"Klient dokument håndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext57"
                          defaultValue={"Dokumentsøgning og sortering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext58"
                          defaultValue={"Optag video og lyd"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext59"
                          defaultValue={"Send dokumenter"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext60"
                          defaultValue={"Personoplysningshåndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext61"
                          defaultValue={"Online betaling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext1"
                          defaultValue={"Multi-sprog"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext81"
                          defaultValue={"Integrering på tværs af systemer"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>&#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className=" text-left AxoPackagetd">
                    <AxoLocal
                      entity="FrontPageindextaxt18"
                      defaultValue={"Dokumenthåndtering"}
                    />
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext1"
                          defaultValue={"Multi-sprog"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext63"
                          defaultValue={"Upload dokumenter"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext64"
                          defaultValue={"Drag-drop dokumenter"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext65"
                          defaultValue={"Central dokumentoversigt"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext66"
                          defaultValue={"Kartotekskort oversigt"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext67"
                          defaultValue={"Søgning og sortering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext68"
                          defaultValue={"Inline redigering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext69"
                          defaultValue={"Status og prioritet"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext70"
                          defaultValue={"Tilføj påmindelser"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext71"
                          defaultValue={"Contract Management"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext72"
                          defaultValue={"Mappetilknytning"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext25"
                          defaultValue={"Fildeling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext73"
                          defaultValue={"Mailhåndtering af dokumenter"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext74"
                          defaultValue={"Optag video og lyd"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext77"
                          defaultValue={"Skabelonsfremstilling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext78"
                          defaultValue={"Importer skabelon"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext79"
                          defaultValue={"PDF generering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext80"
                          defaultValue={"Skabelondeling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext81"
                          defaultValue={"Integrering på tværs af systemer"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>&#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div>&#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className=" text-left AxoPackagetd">
                    <AxoLocal
                      entity="FrontPageindextaxt13"
                      defaultValue={"Økonomistyring"}
                    />
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox ">
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext1"
                          defaultValue={"Multi-sprog"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="getHeaderTitletimeEntryAddedA"
                          defaultValue={"Tidsregistrering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext82"
                          defaultValue={"Betalingstatus"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext83"
                          defaultValue={"Udgiftshåndtering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext84"
                          defaultValue={"Tilknyt klient eller sag"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext85"
                          defaultValue={"Fakturafremstilling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext86"
                          defaultValue={"Fakturakonfigurering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext79"
                          defaultValue={"PDF generering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext87"
                          defaultValue={"Klient- og sagsfiltrering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext88"
                          defaultValue={"Søgefunktionalitet"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext89"
                          defaultValue={"Digital fakturering til klient"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext90"
                          defaultValue={"Mailhåndtering af faktura"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext61"
                          defaultValue={"Online betaling"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext91"
                          defaultValue={"Påmindelse af betaling"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>&#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className=" text-left AxoPackagetd">
                    <AxoLocal
                      entity="FrontPageindextaxt19"
                      defaultValue={"Booking system"}
                    />
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext1"
                          defaultValue={"Multi-sprog"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext92"
                          defaultValue={"Opsæt online booking"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext93"
                          defaultValue={"Drag-drop funktionalitet"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext94"
                          defaultValue={"Fritekst beskrivelse"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext95"
                          defaultValue={"Send booking link"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext96"
                          defaultValue={"Plugin til egen hjemmeside"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext97"
                          defaultValue={
                            "Integreret med klient selvbetjeningspanel"
                          }
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext98"
                          defaultValue={"Godkend booking anmodninger"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext99"
                          defaultValue={"Automatisk bekræftelse"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="CalendarEventBoxFormreminders"
                          defaultValue={"Påmindelser"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext81"
                          defaultValue={"Integrering på tværs af systemer"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>&#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className=" text-left AxoPackagetd">
                    <AxoLocal
                      entity="FrontPageindextaxt20"
                      defaultValue={"Kalender"}
                    />
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext1"
                          defaultValue={"Multi-sprog"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext100"
                          defaultValue={"Aftaleoversigt"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext102"
                          defaultValue={"Deadline påmindelser"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext103"
                          defaultValue={"Påmindelser for dokumenter"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext104"
                          defaultValue={"Aftaleoprettelse"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext105"
                          defaultValue={"Booking tid oprettelse"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext106"
                          defaultValue={"Tids- og aftaleredigering"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext107"
                          defaultValue={"Drag-drop af aftaler og tid"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext108"
                          defaultValue={"Oversigt over måned, uge og dagsorden"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="CalendarEventBoxFormreminders"
                          defaultValue={"Påmindelser"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext81"
                          defaultValue={"Integrering på tværs af systemer"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                      <div> &#10004;</div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className=" text-left AxoPackagetd">
                    <AxoLocal
                      entity="FrontPageviewtext2"
                      defaultValue={"Mappesystem"}
                    />
                  </td>
                  <td className=" text-left AxoPackagetd"></td>
                </tr>
                <tr>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext8"
                          defaultValue={"Mailmapper"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="LabelTabViewCaseFolders"
                          defaultValue={"Sagsmapper"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="LabelTabViewDocumentfolders"
                          defaultValue={"Dokumentmapper"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext109"
                          defaultValue={"Ubegrænset mappeoprettelse"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext110"
                          defaultValue={"Integration i hele systemet"}
                        />
                      </div>
                      <div>
                        &#9724;{" "}
                        <AxoLocal
                          entity="IndividualPackagetext111"
                          defaultValue={"Og mere"}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text-left frontPageaxolexdivtextbox">
                      <div>
                        {" "}
                        <Icon
                          glyph="icon-fontello-ok-6"
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                      <div>
                        {" "}
                        <Icon
                          glyph="icon-fontello-ok-6"
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                      <div>
                        {" "}
                        <Icon
                          glyph="icon-fontello-ok-6"
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                      <div>
                        {" "}
                        <Icon
                          glyph="icon-fontello-ok-6"
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                      <div>
                        {" "}
                        <Icon
                          glyph="icon-fontello-ok-6"
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </div>
    );
  }
}
