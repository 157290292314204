import React from "react";
import ApiService from "./../../services/DataAccess/ApiService";
import DataActions from "./../../services/DataAccess/DataActions";
import DataStore from "./../../services/DataAccess/DataStore";
import RoutingService from "./../../services/RoutingService";
import { Table, Column, Cell } from "fixed-data-table-2";
import InlineEdit from "./../../utilities/InlineEdit";
import CaseGridView from "./CaseGridView";
import SortHeaderCell from "../../utilities/FixedDataTable/SortHeaderCell";
import DataListWrapper from "../../utilities/FixedDataTable/DataListWrapper";
import ModalService from "./../../services/ModalService";
import { ctx } from "../../utilities/L20n/L20n";
import ReactOption from "../../utilities/ReactOption";
import Conditional from "../../utilities/Conditional";
import StringService from "../../services/StringService";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";
import moment from "moment";
import TimeEntryService from "../../services/TimeEntryService";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash";

import {
  Row,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";

import {
  AxoCheckbox,
  Icon,
  LexButton,
  PaginationControls,
  LabelSearchBox,
  FileViewerModal,
  TableBase,
  Dimensions,
  AxoLocal,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  select: {
    width: 50,
    shown: true,
  },
  caseNumber: {
    width: 130,
    shown: true,
  },
  internalNumber: {
    width: 100,
    shown: true,
  },
  subject: {
    width: 125,
    shown: true,
  },
  type: {
    width: 125,
    shown: true,
  },
  field: {
    width: 120,
    shown: true,
  },
  reference: {
    width: 100,
    shown: true,
  },
  timeSpent: {
    width: 105,
    shown: true,
  },
  clientString: {
    width: 130,
    shown: true,
  },
  counterPartyString: {
    width: 120,
    shown: true,
  },
  labels: {
    width: 130,
    shown: true,
  },
  addLabels: {
    width: 135,
    shown: true,
  },
  documents: {
    width: 60,
    shown: true,
  },
  priority: {
    width: 65,
    shown: true,
  },
  creationDate: {
    width: 125,
    shown: true,
  },
  latestActivityDate: {
    width: 125,
    shown: true,
  },
  actionDate: {
    width: 190,
    shown: true,
  },
  actions: {
    width: 60,
    shown: true,
  },
};

class CaseDataTableFixed extends TableBase {
  constructor(props) {
    super(props);
    this.name = "CaseDataTableFixed";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = this.generateDefaultSortIndexes(props.cases);
    let tableEntries = this.addDataStringsToCases(props.cases);
    this.state = {
      tableEntries,
      selectedEntries: new Set(),
      sortOrders: {},
      labelAddedToCaseId: 0,
      showFolders: {},
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        actionDate: 180,
        labels: 90,
        type: 120,
        addLabels: 90,
        priority: 85,
        creationDate: 100,
      },
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: props.caseQueryText,
      deletingcaseModelId: 0,
      selectedLabel: {},
    };
    this.toggleColumns = [
      "caseNumber",
      "internalNumber",
      "subject",
      "type",
      "field",
      "reference",
      "timeSpent",
      "clientString",
      "counterPartyString",
      "labels",
      "addLabels",
      "documents",
      "priority",
      "creationDate",
      "latestActivityDate",
      "actionDate",
    ];
  }

  clearSelectedEntries = () => {
    this.setState({ selectedEntries: new Set() });
  };

  addSelectedEntriesToLabel = (label) => {
    this.state.selectedEntries.forEach((caseModelId) => {
      if (label.caseIds.includes(caseModelId)) {
        return;
      }
      DataActions.addLabelToCase(caseModelId, label.id).then((response) => {
        if (response.status === 200) {
          this.clearSelectedEntries();
        }
      });
    });
  };

  onSelectEntry = (id, event) => {
    var selectedEntries = new Set(this.state.selectedEntries);
    if (event.target.checked) {
      selectedEntries.add(id);
    } else {
      selectedEntries.delete(id);
    }
    this.setState({ selectedEntries });
  };

  onRemoveLabel = (labelId, clientId) => {
    DataActions.removeLabelFromFile(clientId, labelId);
  };

  onCreateLabel = () => {
    var { newLabelName, selectedEntries } = this.state;

    if (!newLabelName) {
      ModalService.openAlertModal(
        <AxoLocal
          entity="DocumentTableFixedViewopenAlertModal"
          defaultValue={"Indtast et navn for at oprette en ny mappe."}
        />
      );
      return;
    }
    DataActions.createLabel({ name: newLabelName, type: 1 }).then(
      (response) => {
        if (response.status === 200) {
          this.setState({ showCreatedLabelIcon: true });
          setTimeout(() => {
            this.setState({ showCreatedLabelIcon: false });
          }, 3000);
          response.json().then((newLabel) => {
            selectedEntries.forEach((caseModelId) => {
              DataActions.addLabelToCase(caseModelId, newLabel.id);
            });
            this.setState({ newLabelName: "" });
            this.clearSelectedEntries();
          });
        }
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    let { searchText, selectedLabel } = this.state;

    let tableEntries = this.addDataStringsToCases(nextProps.cases);
    let filteredCases = this.getFilteredCases(
      tableEntries,
      searchText,
      selectedLabel
    );
    this.updateEntries(tableEntries, filteredCases);
  }

  componentWillUnmount() {
    var { count, cases } = this.props;
    var { searchText, selectedLabel } = this.state;

    //Reset server-side search
    if ((!!searchText && count > cases.length) || !!selectedLabel.id) {
      this.setCaseQueryTextDebounced(searchText);
    }
  }

  setCaseQueryTextDebounced = debounce((searchText) => {
    var { setCaseQueryText } = this.props;

    setCaseQueryText(searchText);
  }, 350);

  getTotalTimeSpent = (caseModel) => {
    let timeEntriesWithTime = caseModel.timeEntries.filter(
      (t) => t.unitType === "Hours"
    );
    return timeEntriesWithTime.reduce((acc, timeEntry) => {
      return acc + timeEntry.units;
    }, 0);
  };

  addDataStringsToCases = (cases) => {
    var firstNameCounts = {};
    this.props.clients.forEach((c) => {
      if (firstNameCounts[c.firstName]) {
        firstNameCounts[c.firstName] += 1;
      } else {
        firstNameCounts[c.firstName] = 1;
      }
    });

    var tableCases = cases.map((caseModel) => {
      let c = { ...caseModel };

      c.clientString = "";
      if (c.clients.length > 0) {
        var firstName = c.clients[0].firstName;
        c.clientString = firstName;
        if (firstNameCounts[firstName] > 1) {
          c.clientString += " " + c.clients[0].lastName.substring(0, 1);
        }
      }
      c.counterPartyString = "";
      if (c.counterParties.length > 0) {
        c.counterPartyString = c.counterParties[0].firstName;
      }
      c.priorityString = this.getPriorityString(c.priority);

      c.timeSpent = this.getTotalTimeSpent(c);

      if (
        c.sharingUsers.length > 0 &&
        (!c.editor || c.editor.id !== this.props.userProfile.id)
      ) {
        c.readOnly = true;
      }

      return c;
    });

    return tableCases;
  };

  onLabelAdd = (caseId, event) => {
    var labelId = parseInt(event.target.value, 10);
    //Optimistic updating
    var label = Object.assign(
      {},
      this.props.labels.find((l) => l.id === labelId)
    );
    label.caseIds.push(caseId);
    DataStore.updateArrayObject(label, "labels");

    DataActions.addLabelToCase(caseId, labelId).then(() => {
      this.setState({ labelAddedToCaseId: caseId });
      setTimeout(() => {
        this.setState({ labelAddedToCaseId: 0 });
      }, 1500);
    });
  };

  onGoToLabels = () => {
    this.props.history.push(RoutingService.getPath("CaseLabels"));
  };

  getPriorityIcon = (priorityString) => {
    var priority = parseInt(priorityString, 10);
    switch (priority) {
      case 0:
        return (
          <div>
            <Icon style={{ color: "blue" }} glyph="icon-fontello-circle" />
          </div>
        );
      case 1:
        return (
          <div>
            <Icon style={{ color: "green" }} glyph="icon-fontello-circle" />
          </div>
        );
      case 2:
        return (
          <div>
            <Icon style={{ color: "darkred" }} glyph="icon-fontello-circle" />
          </div>
        );
      case 3:
        return (
          <div>
            <Icon style={{ color: "red" }} glyph="icon-fontello-circle" />
          </div>
        );
      default:
        return <div></div>;
    }
  };

  getPriorityString = (priority) => {
    switch (priority) {
      case 0:
        return "D";
      case 1:
        return "C";
      case 2:
        return "B";
      case 3:
        return "A";
      default:
        return "";
    }
  };

  onCaseClick = (caseId, event) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onCaseClick(caseId);
  };

  onChangeSubject = (caseModelId, input) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    caseModel.subject = input.value;
    DataActions.updateCaseFromHeader(caseModel);
  };

  onChangeType = (caseModelId, input) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    caseModel.type = input.value;
    DataActions.updateCaseFromHeader(caseModel);
  };

  onPrioritySelect = (caseModelId, event) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    caseModel.priority = this.getPriorityValueFromString(event.target.value);
    DataActions.updateCaseFromHeader(caseModel);
  };

  onChangeProperty = (caseModelId, propertyName, input) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    caseModel[propertyName] = input.value;
    DataActions.updateCaseFromHeader(caseModel);
  };

  getPriorityValueFromString = (priorityString) => {
    switch (priorityString) {
      case "D":
        return 0;
      case "C":
        return 1;
      case "B":
        return 2;
      case "A":
        return 3;
      default:
        return 0;
    }
  };

  onTrashCase = (caseModelId) => {
    this.setState({ deletingcaseModelId: caseModelId });
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    if (!caseModel.trashed) {
      caseModel.trashed = true;
      DataActions.updateCaseFromHeader(caseModel).then((response) => {
        this.setState({ deletingcaseModelId: 0 });
      });
    } else {
      ModalService.openConfirmModal(
        ctx.getSync("documentsDoyouwanttodeletethecaseConfirm") +
          caseModel.caseNumber +
          ctx.getSync("sletpermanent1"),
        (value) => {
          if (value) {
            if (caseModel.documents.length > 0) {
              ModalService.openConfirmModal(
                <AxoLocal
                  entity="CaseDataTableFixedcaseModeldeleteFiles"
                  defaultValue={
                    "Vil du også slette bilag tilknyttet til sagen?"
                  }
                />,
                (value) => {
                  if (value) {
                    DataActions.deleteCase({
                      id: caseModel.id,
                      deleteFiles: true,
                    });
                  } else {
                    DataActions.deleteCase({
                      id: caseModel.id,
                      deleteFiles: false,
                    });
                  }
                }
              );
            } else {
              DataActions.deleteCase({ id: caseModel.id, deleteFiles: false });
            }
          }
        }
      );
    }
  };

  onRestoreCase = (caseModelId) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    if (caseModel.trashed) {
      caseModel.trashed = false;
      DataActions.updateCaseFromHeader(caseModel);
    }
  };

  onCaseDateChange = (caseModelId, dateTime) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    caseModel.actionDate = dateTime.format();
    DataActions.updateCaseFromHeader(caseModel);
  };

  onCaseDateChangeHTML5 = (caseModelId, event) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    caseModel.actionDate = moment(event.target.valueAsDate).format();
    DataActions.updateCaseFromHeader(caseModel);
  };

  onShowFiles = (documents) => {
    this.fileModal.open(documents);
  };

  onSearch = (event) => {
    var searchText = event.target.value;
    var { selectedLabel, tableEntries } = this.state;
    var cases = this.getFilteredCases(
      tableEntries,
      searchText,
      this.state.selectedLabel
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(cases);
    this.setState({
      searchText,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, cases),
    });

    var { count } = this.props;

    if (!selectedLabel.id && tableEntries.length < count) {
      this.setCaseQueryTextDebounced(searchText);
    }
  };

  onShowSelectedLabelCases = (selectedLabel) => {
    var { tableEntries } = this.state;

    var docsForLabel = tableEntries.filter(
      (d) =>
        !selectedLabel.caseIds ||
        selectedLabel.caseIds.find((id) => id === d.id)
    );
    if (
      selectedLabel.caseIds &&
      docsForLabel.length < selectedLabel.caseIds.length
    ) {
      this.setState({ selectedLabel });
      //Perform server-side search. Not all documents have been loaded
      ApiService.getSingleLabel(selectedLabel.id).then((fullLabel) => {
        DataStore.setActiveCases(fullLabel.cases);
      });
    } else {
      this.doShowLabelCases(selectedLabel);
    }
  };

  doShowLabelCases = (selectedLabel) => {
    var { tableEntries, searchText } = this.state;
    var entries = this.getFilteredCases(
      tableEntries,
      searchText,
      selectedLabel
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      selectedLabel,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredCases = (cases, searchText, selectedLabel) => {
    if (!searchText && !selectedLabel.id) {
      return cases;
    }

    var filteredEntries = cases.filter((c) => {
      return c.caseNumber.toLowerCase().includes(searchText.toLowerCase());
    });
    if (selectedLabel.id) {
      filteredEntries = filteredEntries.filter((c) => {
        return !!selectedLabel.caseIds.find((id) => id === c.id);
      });
    }

    return filteredEntries;
  };

  addDeadlineToCase = (caseModelId) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    caseModel.actionDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    DataActions.updateCaseFromHeader(caseModel);
  };

  removeDeadlineFromCase = (caseModelId) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((c) => c.id === caseModelId)
    );
    caseModel.actionDate = new Date(0);
    DataActions.updateCaseFromHeader(caseModel);
  };

  renderDateCell = (caseModel) => {
    var deadlineMoment = moment(caseModel.actionDate);
    if (deadlineMoment.year() > 1970) {
      return (
        <div>
          <input
            type="date"
            style={{ borderStyle: "none" }}
            onChange={this.onCaseDateChangeHTML5.bind(this, caseModel.id)}
            value={deadlineMoment.format("YYYY-MM-DD")}
          />
          &nbsp;&nbsp;&nbsp;
          <AxoLocal
            className="editable"
            componentClass={Icon}
            role="button"
            onClick={this.removeDeadlineFromCase.bind(this, caseModel.id)}
            glyph="icon-fontello-scissors axored"
            componentAttribute="title"
            entity="RemoveTitledeadlineMomentRemovereminder"
          />
        </div>
      );
    }
    return (
      <Icon
        className="editable"
        role="button"
        onClick={this.addDeadlineToCase.bind(this, caseModel.id)}
        glyph="icon-fontello-calendar-6"
      />
    );
  };

  onExpandSubject = () => {
    this.setState({ expandedSubject: true });
  };

  renderColumnDropdown = () => {
    var columns = this.toggleColumns;
    var columnDefs = this.state.columnDefs;
    var allEnabled = !columns.find((col) => !columnDefs[col].shown);

    return (
      <DropdownButton
        id="ColumnDropdown"
        className="visButtoncolor axoDropdownButton VisButton"
        title={
          <span style={{ fontSize: "15px" }}>
            <AxoLocal entity="axoAccounting70" defaultValue={"Vis"} />
          </span>
        }
      >
        <MenuItem eventKey="all">
          <div
            className="text-center"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <AxoCheckbox
              checked={allEnabled}
              onChange={this.onEnableAll.bind(this, !allEnabled)}
            />
            <AxoLocal entity="axoidcode124" defaultValue={"Vis alle"} />
          </div>
        </MenuItem>
        {columns.map((column) => {
          return (
            <MenuItem key={column} eventKey={column}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <AxoCheckbox
                  checked={columnDefs[column].shown}
                  onChange={this.onSelectColumn.bind(this, column)}
                />
                &nbsp;{this.getColumnTitle(column)}
              </div>
            </MenuItem>
          );
        })}
      </DropdownButton>
    );
  };

  getColumnTitle = (columnName) => {
    switch (columnName) {
      case "caseNumber":
        return (
          <AxoLocal entity="ContainercaseNumber" defaultValue={"Sagsnummer"} />
        );
      case "internalNumber":
        return (
          <span>
            {" "}
            <AxoLocal
              entity="CaseDataTableFixedcaseEnabledB"
              defaultValue={"Internt"}
            />
          </span>
        );
      case "subject":
        return (
          <AxoLocal
            entity="CaseDataTableFixedsubject"
            defaultValue={"Emneord"}
          />
        );
      case "type":
        return (
          <AxoLocal
            entity="CaseDataTableFixedfileInstance"
            defaultValue={"Sagsinstans"}
          />
        );
      case "field":
        return (
          <span>
            <AxoLocal
              entity="CaseDataTableFixedcaseEnabledC"
              defaultValue={"Sagsområde"}
            />
          </span>
        );
      case "reference":
        return (
          <span>
            {" "}
            <AxoLocal
              entity="CaseDataTableFixedcaseEnabledD"
              defaultValue={"Reference"}
            />{" "}
          </span>
        );
      case "timeSpent":
        return (
          <AxoLocal
            entity="CaseDataTableFixedfileTime"
            defaultValue={"Tidsforbrug"}
          />
        );
      case "clientString":
        return (
          <AxoLocal entity="CaseDataTableFixedClient" defaultValue={"Klient"} />
        );
      case "counterPartyString":
        return (
          <AxoLocal
            entity="CaseDataTableFixedcounterParty"
            defaultValue={"Modpart"}
          />
        );
      case "labels":
        return (
          <AxoLocal
            entity="CaseDataTableFixedThecasefolders"
            defaultValue={"Sagens mapper"}
          />
        );
      case "addLabels":
        return (
          <AxoLocal
            entity="CaseDataTableFixedlabelAddedToCase"
            defaultValue={"Tilføj mapper"}
          />
        );
      case "documents":
        return (
          <AxoLocal
            entity="CaseDataTableFixedlabelAddedToCasedocuments"
            defaultValue={"Bilag"}
          />
        );
      case "priority":
        return (
          <AxoLocal
            entity="CaseDataTableFixedCasepriority"
            defaultValue={"Prioritet"}
          />
        );
      case "creationDate":
        return (
          <AxoLocal
            entity="CaseDataTableFixedcreationDate"
            defaultValue={"Oprettelse"}
          />
        );
      case "latestActivityDate":
        return "Aktivitet";
      case "actionDate":
        return (
          <AxoLocal entity="EventBoxFormDeadline" defaultValue={"Deadline"} />
        );
      default:
        return "";
    }
  };

  renderLabelSelect = (caseModel) => {
    var currentLabels = this.props.labels.filter((l) =>
      l.caseIds.includes(caseModel.id)
    );
    if (currentLabels.length === 0) {
      return;
    }
    var selectLabelNodes = currentLabels.map((label) => {
      return (
        <option key={label.id} value={label.id}>
          {StringService.enforceMaxLength(label.name, 10)}
        </option>
      );
    });
    return (
      <div>
        <select
          className="axoblue selectbg"
          style={{ border: "none" }}
          value="labels"
          onChange={() => {}}
        >
          {selectLabelNodes}
        </select>
        &nbsp; ({currentLabels.length})
      </div>
    );
  };

  renderAddLabelSelect = (caseModel) => {
    var confirmIcon =
      this.state.labelAddedToCaseId === caseModel.id ? (
        <Icon style={{ color: "#36845E" }} glyph="icon-fontello-ok-3" />
      ) : null;
    var addLabels = this.props.labels.filter(
      (l) => !l.caseIds.includes(caseModel.id)
    );
    var addLabelSelectNodes = addLabels.map((label) => {
      return (
        <option key={label.id} value={label.id}>
          {StringService.enforceMaxLength(label.name, 10)}
        </option>
      );
    });
    return (
      <span>
        {confirmIcon}
        <select
          className="axoblue selectbg"
          style={{ border: "none" }}
          value="labels"
          onChange={this.onLabelAdd.bind(this, caseModel.id)}
        >
          <AxoLocal
            componentClass={ReactOption}
            value="labels"
            disabled
            componentAttribute="text"
            entity="CaseDataTableFixedlabelAddedToCaselabel"
            defaultValue={"Folders"}
          />
          {addLabelSelectNodes}
        </select>
      </span>
    );
  };

  checkForDisabledInput = (event) => {
    if (this.state.selectedEntries.size === 0) {
      this.setState({ showDisabledWarning: true });
      setTimeout(() => {
        this.setState({ showDisabledWarning: false });
      }, 3000);
    }
  };

  renderInputBoxes = () => {
    var { selectedLabel } = this.state;

    return (
      <Row
        style={{ maxWidth: "900px", paddingLeft: "25px", marginBottom: "5px" }}
      >
        <div className="flexbox-layout ">
          <div>
            <Icon className="Doccolor2" glyph="icon-fontello-folder-open-1" />
            &ensp;&ensp;
            <AxoLocal
              className="Doccolor2"
              entity="DocumentAddtoexistingfolder"
              defaultValue={"Tilføj til eksisterende mappe"}
            />
            <span onClick={this.checkForDisabledInput}>
              <AxoLocal
                componentClass={LabelSearchBox}
                labels={this.props.labels}
                handleSelectedLabel={this.addSelectedEntriesToLabel}
                disabled={this.state.selectedEntries.size === 0}
                inputBorder="1px solid #86A8E7"
                clearOnSelect
                componentAttribute="placeholder"
                entity="CaseSearchBoxPlaceholderfolder"
              />
            </span>
          </div>
          {this.renderCreateLabelInput()}
          <div>
            <Icon glyph="icon-fontello-braille" />
            &ensp;&ensp;
            <span>
              <AxoLocal
                entity="DocumentLabelShowPlaceholder"
                defaultValue={"Vis mappe"}
              />
              {selectedLabel.id ? (
                <span>&nbsp;({selectedLabel.name})</span>
              ) : null}
            </span>
            <div className="flexbox-responsive">
              <AxoLocal
                componentClass={LabelSearchBox}
                labels={this.props.labels}
                handleSelectedLabel={this.onShowSelectedLabelCases}
                disabled={this.props.labels.length === 0}
                inputBorder="1px solid #4CAF50"
                clearOnSelect
                componentAttribute="placeholder"
                entity="DocumentLabelShowPlaceholder"
              />
              {selectedLabel.id ? (
                <LexButton style={{ height: "30px" }} onClick={this.onShowAll}>
                  <AxoLocal entity="axoidcode124" defaultValue={"Vis alle"} />
                </LexButton>
              ) : null}
            </div>
          </div>
        </div>
      </Row>
    );
  };

  renderCreateLabelInput = () => {
    return (
      <div>
        <div>
          <span onClick={this.checkForDisabledInput}>
            <div>
              <Icon glyph="icon-fontello-plus-4" className="Doccolor3" />
              &ensp;&ensp;
              <AxoLocal
                className="Doccolor3"
                entity="DocumentAddtonewfolder"
                defaultValue={"Tilføj til ny mappe"}
              />
            </div>
            <InputGroup style={{ maxWidth: "250px" }}>
              <AxoLocal
                componentClass={FormControl}
                type="text"
                style={{
                  borderColor: "#1b7b34",
                  borderWidth: "1px",
                  borderRadius: "4px",
                }}
                disabled={this.state.selectedEntries.size === 0}
                value={this.state.newLabelName}
                onChange={(event) => {
                  this.setState({ newLabelName: event.target.value });
                }}
                componentAttribute="placeholder"
                entity="placeholderEnterthefoldername"
              />
              <InputGroup.Addon className="plain">
                <Button className="Lex-button-2" onClick={this.onCreateLabel}>
                  <AxoLocal entity="ADDViewCreate" defaultValue={"Opret"} />
                  {this.state.showCreatedLabelIcon ? (
                    <span>
                      &nbsp;
                      <Icon className="axoblue" glyph="icon-fontello-ok-3" />
                      &nbsp; &nbsp;
                    </span>
                  ) : null}
                </Button>
              </InputGroup.Addon>
            </InputGroup>
          </span>
        </div>
      </div>
    );
  };

  render() {
    let { cases, count, forceShowCards, onToggleSharedCasesDetails } =
      this.props;
    let { selectedLabel } = this.state;

    return (
      <div>
        <div style={{ paddingTop: "15px" }}>
          <div>
            <div className="axobg" style={{ marginBottom: "5px" }}>
              <div
                className="flexbox-layout"
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
              >
                <div className="flexbox-standard">
                  <h4>
                    <Icon glyph="icon-simple-line-icons-briefcase" />
                    &nbsp;
                    <AxoLocal
                      entity="CaseDataTableFixedCase"
                      defaultValue={"Sager"}
                    />
                  </h4>
                  {selectedLabel.id ? (
                    <LexButton
                      style={{ height: "30px", marginLeft: "5px" }}
                      onClick={this.onShowAll}
                    >
                      <AxoLocal
                        entity="axoidcode124"
                        defaultValue={"Vis alle"}
                      />
                    </LexButton>
                  ) : (
                    <PaginationControls
                      length={cases.length}
                      count={count}
                      onShowMore={() => DataStore.increaseCasePageSize()}
                    />
                  )}
                </div>
                {onToggleSharedCasesDetails ? (
                  <div>
                    <Button
                      onClick={onToggleSharedCasesDetails}
                      className="Lex-button-2"
                    >
                      <AxoLocal
                        entity="Gruppeinformation22"
                        defaultValue={"Vis delingsinformation"}
                      />
                    </Button>
                  </div>
                ) : null}
                <div>
                  <div style={{ maxWidth: "500px" }}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-search" />
                      </InputGroup.Addon>
                      <AxoLocal
                        componentClass={FormControl}
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                        componentAttribute="placeholder"
                        entity="placeholderSearch"
                      />

                      <FormControl.Feedback>
                        <Icon glyph="icon-fontello-search" />
                      </FormControl.Feedback>
                    </InputGroup>
                  </div>
                </div>
                {this.renderColumnDropdown()}
                <div className="hidden-xs text-right">
                  <Button
                    className="Lex-button-2"
                    onClick={this.props.onToggleCards}
                  >
                    {this.props.forceShowCards ? (
                      <span>
                        {" "}
                        <Icon glyph="icon-fontello-grid" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showTable"
                          entity="CaseDataTableFixedShowastable"
                          defaultValue={"Vis som tabel"}
                        />
                      </span>
                    ) : (
                      <span>
                        {" "}
                        <Icon glyph="icon-fontello-th-thumb-empty" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showCards"
                          entity="CaseDataTableFixedShowCards"
                          defaultValue={"Vis som kartotekskort"}
                        />{" "}
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
            {forceShowCards ? (
              this.renderCaseGridView()
            ) : (
              <div>
                <ExtraSmall>{this.renderCaseGridView()}</ExtraSmall>
                <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
              </div>
            )}
          </div>
          <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
        </div>
      </div>
    );
  }

  onShowAll = () => {
    this.onShowSelectedLabelCases({});
    if (this.state.tableEntries.length < (this.props.count || 0)) {
      DataStore.fetchCases();
    }
  };

  renderCaseGridView = () => {
    var { sortedDataList } = this.state;
    return (
      <div style={{ paddingTop: "0px" }}>
        <CaseGridView
          cases={sortedDataList._data}
          labels={this.props.labels}
          onCaseClick={this.props.onCaseClick}
          onPrioritySelect={this.onPrioritySelect}
          onCaseDateChange={this.onCaseDateChangeHTML5}
          onShowFiles={this.onShowFiles}
          removeDeadlineFromCase={this.removeDeadlineFromCase}
          addDeadlineToCase={this.addDeadlineToCase}
        />
      </div>
    );
  };

  renderTable = () => {
    var { sortedDataList, colSortDirs } = this.state;
    const { containerHeight, containerWidth } = this.props;
    var tableWidth = containerWidth - 20;
    var columnDefs = this.state.columnDefs;

    return (
      <div style={{ paddingTop: "0px", paddingLeft: "10px" }}>
        {this.renderInputBoxes()}
        <Table
          rowHeight={50}
          rowsCount={sortedDataList.getSize()}
          width={tableWidth}
          height={containerHeight}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          headerHeight={50}
        >
          <Column
            columnKey="select"
            header={<Cell></Cell>}
            cell={(props) => {
              var caseModel = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={caseModel.id} className="text-center" {...props}>
                  <AxoCheckbox
                    checked={this.state.selectedEntries.has(caseModel.id)}
                    onChange={this.onSelectEntry.bind(this, caseModel.id)}
                  />
                </Cell>
              );
            }}
            width={columnDefs.select.width}
            isResizable={true}
          />
          {columnDefs.caseNumber.shown ? (
            <Column
              columnKey="caseNumber"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-barcode-1" />}
                  showAltIcon={columnDefs.caseNumber.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.caseNumber}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("caseNumber")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell
                    key={caseModel.id}
                    onClick={this.onCaseClick.bind(this, caseModel.id)}
                    {...props}
                  >
                    <a href={caseModel.caseNumber}>{caseModel.caseNumber}</a>
                  </Cell>
                );
              }}
              width={columnDefs.caseNumber.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.internalNumber.shown ? (
            <Column
              columnKey="internalNumber"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-qrcode" />}
                  showAltIcon={columnDefs.internalNumber.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.internalNumber}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("internalNumber")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell
                    key={caseModel.id}
                    onClick={this.onCaseClick.bind(this, caseModel.id)}
                    {...props}
                  >
                    <a href={caseModel.internalNumber}>
                      {caseModel.internalNumber}
                    </a>
                  </Cell>
                );
              }}
              width={columnDefs.internalNumber.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.subject.shown ? (
            <Column
              columnKey="subject"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph=" icon-fontello-pencil-alt-1" />}
                  showAltIcon={columnDefs.subject.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.subject}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("subject")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell
                    key={caseModel.id}
                    {...props}
                    onClick={this.onExpandSubject}
                  >
                    {caseModel.readOnly ? (
                      <span>{caseModel.subject}</span>
                    ) : (
                      <InlineEdit
                        value={caseModel.subject || "---"}
                        change={this.onChangeSubject.bind(this, caseModel.id)}
                      />
                    )}
                  </Cell>
                );
              }}
              width={columnDefs.subject.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.type.shown ? (
            <Column
              columnKey="type"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-tasks" />}
                  showAltIcon={columnDefs.type.width < 110}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.type}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("type")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    {caseModel.readOnly ? (
                      <span>{caseModel.type}</span>
                    ) : (
                      <InlineEdit
                        value={caseModel.type || "---"}
                        change={this.onChangeType.bind(this, caseModel.id)}
                      />
                    )}
                  </Cell>
                );
              }}
              width={columnDefs.type.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.field.shown ? (
            <Column
              columnKey="field"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-hammer" />}
                  showAltIcon={columnDefs.field.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.field}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("field")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    {caseModel.readOnly ? (
                      <span>{caseModel.field}</span>
                    ) : (
                      <InlineEdit
                        value={caseModel.field || "---"}
                        change={this.onChangeProperty.bind(
                          this,
                          caseModel.id,
                          "field"
                        )}
                      />
                    )}
                  </Cell>
                );
              }}
              width={columnDefs.field.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.reference.shown ? (
            <Column
              columnKey="reference"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-tasks" />}
                  showAltIcon={columnDefs.reference.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.reference}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("reference")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    {caseModel.readOnly ? (
                      <span>{caseModel.reference}</span>
                    ) : (
                      <InlineEdit
                        value={caseModel.reference || "---"}
                        change={this.onChangeProperty.bind(
                          this,
                          caseModel.id,
                          "reference"
                        )}
                      />
                    )}
                  </Cell>
                );
              }}
              width={columnDefs.reference.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.timeSpent.shown ? (
            <Column
              columnKey="timeSpent"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-stopwatch" />}
                  showAltIcon={columnDefs.timeSpent.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.timeSpent}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("timeSpent")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    {TimeEntryService.getTimeString(caseModel.timeSpent)}
                  </Cell>
                );
              }}
              width={columnDefs.timeSpent.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.clientString.shown ? (
            <Column
              columnKey="clientString"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-user" />}
                  showAltIcon={columnDefs.clientString.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.clientString}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("clientString")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    <div style={{ fontWeight: "normal" }}>
                      {caseModel.clientString}
                    </div>
                  </Cell>
                );
              }}
              width={columnDefs.clientString.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.counterPartyString.shown ? (
            <Column
              columnKey="counterPartyString"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-user" />}
                  showAltIcon={columnDefs.counterPartyString.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.counterPartyString}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("counterPartyString")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    {caseModel.counterPartyString}
                  </Cell>
                );
              }}
              width={columnDefs.counterPartyString.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.labels.shown ? (
            <Column
              columnKey="labels"
              header={<Cell>{this.getColumnTitle("labels")}</Cell>}
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    <div style={{ fontWeight: "normal" }}>
                      {this.renderLabelSelect(caseModel)}
                    </div>
                  </Cell>
                );
              }}
              width={columnDefs.labels.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.addLabels.shown ? (
            <Column
              columnKey="addLabels"
              header={<Cell>{this.getColumnTitle("addLabels")}</Cell>}
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    <div style={{ fontWeight: "normal" }}>
                      {caseModel.readOnly ? (
                        <span></span>
                      ) : (
                        <div>{this.renderAddLabelSelect(caseModel)}</div>
                      )}
                    </div>
                  </Cell>
                );
              }}
              width={columnDefs.addLabels.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.documents.shown ? (
            <Column
              columnKey="documents"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-attach" />}
                  showAltIcon={columnDefs.documents.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.documents}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("documents")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    {caseModel.documents.length > 0 ? (
                      <span>
                        <Icon
                          className="editable"
                          role="button"
                          onClick={this.onShowFiles.bind(
                            this,
                            caseModel.documents
                          )}
                          glyph="icon-fontello-attach"
                        />
                        ({caseModel.documents.length})
                      </span>
                    ) : (
                      ""
                    )}
                  </Cell>
                );
              }}
              width={columnDefs.documents.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.priority.shown ? (
            <Column
              columnKey="priority"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-record-1" />}
                  showAltIcon={columnDefs.priority.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.priority}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("priority")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                var prioritySelect = (
                  <select
                    className="axoblue selectbg"
                    value={this.getPriorityString(caseModel.priority)}
                    style={{ border: "none" }}
                    onChange={this.onPrioritySelect.bind(this, caseModel.id)}
                  >
                    <AxoLocal
                      componentClass={ReactOption}
                      value="A"
                      componentAttribute="text"
                      entity="CaseDataTableFixedselectoptionA"
                      defaultValue={"A"}
                    />
                    <AxoLocal
                      componentClass={ReactOption}
                      value="B"
                      componentAttribute="text"
                      entity="CaseDataTableFixedselectoptionB"
                      defaultValue={"B"}
                    />
                    <AxoLocal
                      componentClass={ReactOption}
                      value="C"
                      componentAttribute="text"
                      entity="CaseDataTableFixedselectoptionC"
                      defaultValue={"C"}
                    />
                    <AxoLocal
                      componentClass={ReactOption}
                      value="D"
                      componentAttribute="text"
                      entity="CaseDataTableFixedselectoptionD"
                      defaultValue={"D"}
                    />
                  </select>
                );
                return (
                  <Cell key={caseModel.id} {...props}>
                    <div className="flexbox-standard">
                      {this.getPriorityIcon(caseModel.priority)}
                      {caseModel.readOnly ? (
                        <span>
                          {this.getPriorityString(caseModel.priority)}
                        </span>
                      ) : (
                        <div>{prioritySelect}</div>
                      )}
                    </div>
                  </Cell>
                );
              }}
              width={columnDefs.priority.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.creationDate.shown ? (
            <Column
              columnKey="creationDate"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-calendar-8" />}
                  showAltIcon={columnDefs.creationDate.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.creationDate}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("creationDate")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                var date = moment(caseModel.creationDate);
                return (
                  <Cell key={caseModel.id} {...props}>
                    {date.format("L")}
                  </Cell>
                );
              }}
              width={columnDefs.creationDate.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.latestActivityDate.shown ? (
            <Column
              columnKey="latestActivityDate"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-calendar-8" />}
                  showAltIcon={columnDefs.latestActivityDate.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.latestActivityDate}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("latestActivityDate")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                var date = moment(caseModel.latestActivityDate);
                return (
                  <Cell key={caseModel.id} {...props}>
                    {date.format("L")}
                  </Cell>
                );
              }}
              width={columnDefs.latestActivityDate.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.actionDate.shown ? (
            <Column
              columnKey="actionDate"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-hourglass" />}
                  showAltIcon={columnDefs.actionDate.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.actionDate}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  {this.getColumnTitle("actionDate")}
                </SortHeaderCell>
              }
              cell={(props) => {
                var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={caseModel.id} {...props}>
                    {caseModel.readOnly ? (
                      <span>{moment(caseModel.actionDate).format("L")}</span>
                    ) : (
                      <div>{this.renderDateCell(caseModel)}</div>
                    )}
                  </Cell>
                );
              }}
              width={columnDefs.actionDate.width}
              isResizable={true}
            />
          ) : null}
          <Column
            columnKey="actions"
            header={<Cell></Cell>}
            cell={(props) => {
              var caseModel = sortedDataList.getObjectAt(props.rowIndex);
              var showActions =
                !caseModel.readOnly &&
                caseModel.userName === this.props.userProfile.userName;
              return (
                <Cell key={caseModel.id} {...props}>
                  {showActions ? (
                    <div>
                      {this.state.deletingcaseModelId === caseModel.id ? (
                        <img
                          alt=""
                          src="/imgs/app/loading.gif"
                          className="img-circle"
                          width="20"
                          height="20"
                        />
                      ) : (
                        <div>
                          <Icon
                            className="editable"
                            glyph="icon-fontello-trash-1"
                            role="button"
                            onClick={this.onTrashCase.bind(this, caseModel.id)}
                          />
                          <Conditional inline show={caseModel.trashed}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Icon
                              glyph="icon-fontello-loop"
                              role="button"
                              onClick={this.onRestoreCase.bind(
                                this,
                                caseModel.id
                              )}
                            />
                          </Conditional>
                        </div>
                      )}
                    </div>
                  ) : (
                    <span></span>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.actions.width}
            isResizable={true}
          />
        </Table>
      </div>
    );
  };
}

export default withRouter(
  Dimensions({
    elementResize: true,
    getHeight: function (element) {
      return window.innerHeight - 250;
    },
  })(CaseDataTableFixed)
);
