import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import {
  MailCarouselEnUS,
  NetworkCarouselEnUS,
  FinanceCarouselEnUS,
  CasesCarouselEnUS,
  ClientsCarouselEnUS,
  SelfserviceCarouselEnUS,
  DocumentsCarouselEnUS,
  BookingCarouselEnUS,
  CalendarCarouselEnUS,
  LabelsCarouselEnUS,
  OnlinepaymentCarouselEnUS,
} from "./Carousels/CarouselsEnUS";
import {
  MailCarouselDa,
  NetworkCarouselDa,
  FinanceCarouselDa,
  CasesCarouselDa,
  ClientsCarouselDa,
  SelfserviceCarouselDa,
  DocumentsCarouselDa,
  BookingCarouselDa,
  CalendarCarouselDa,
  LabelsCarouselDa,
  OnlinepaymentCarouselDa,
} from "./Carousels/CarouselsDa";
import {
  MailCarouselCh,
  NetworkCarouselCh,
  FinanceCarouselCh,
  CasesCarouselCh,
  ClientsCarouselCh,
  SelfserviceCarouselCh,
  DocumentsCarouselCh,
  BookingCarouselCh,
  CalendarCarouselCh,
  LabelsCarouselCh,
  OnlinepaymentCarouselCh,
} from "./Carousels/CarouselsCh";
import {
  ExtraSmall,
  SmallOrSmaller,
  SmallOrLarger,
  MediumOrLarger,
} from "../utilities/Responsive";
import getScrollOffSet from "./getScrollOffSet";

import { Row, Col, Grid } from "react-bootstrap";
import { Icon, AxoLocal, withRouter } from "../utilities/LexUtilities";

import AxolexDataBehandlerAftale from "../resources/files/AxolexDatabehandlerAftale.pdf";
import AxolexDataProcessingAgreement from "../resources/files/AxolexDataProcessingAgreement.pdf";

class Solutions extends React.PureComponent {
  componentDidMount() {
    var scrollId = DataStore.getScrollId();
    if (scrollId) {
      setTimeout(() => {
        var element = document.getElementById(scrollId);
        this.onScrollToElement(element);
        DataStore.setScrollId("");
      }, 500);
    }
  }

  onScrollToSolution = (element, event) => {
    event.preventDefault();
    var scrollElement = document.getElementById(element);
    if (scrollElement) {
      scrollElement.scrollIntoView();
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Solutions");
    }
  };

  onScrollToElement(element) {
    if (!element) {
      //Can happen if the user navigates away before the scroll is initiated
      return;
    }
    element.scrollIntoView();
    window.scrollBy(0, getScrollOffSet());
  }

  render() {
    var locale = this.props.locale;
    return (
      <div>
        <Grid fluid>
          <Row>
            <MediumOrLarger>
              <Col md={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex1-1.jpg"
                      />
                      <div className="text_wrapper">
                        <div className="text_position chbannetaxt">
                          <h2
                            className="item--title"
                            style={{ paddingLeft: "10px" }}
                          >
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />{" "}
                          </h2>
                          <p className="item--text">
                            <AxoLocal
                              entity="FrontPageindextaxt2"
                              defaultValue={"Juridisk management software"}
                            />{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </MediumOrLarger>

            <SmallOrSmaller>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex1-xs.jpg"
                      />
                      <div className="text_wrapper">
                        <div className="text_position chbannetaxt">
                          <h5
                            className="item--title"
                            style={{ paddingLeft: "10px" }}
                          >
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />{" "}
                          </h5>
                          <p className="item--text">
                            <AxoLocal
                              entity="FrontPageindextaxt2"
                              defaultValue={"Juridisk management software"}
                            />{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </SmallOrSmaller>
          </Row>
        </Grid>
        <SmallOrLarger>
          <Grid fluid style={{ paddingTop: "10px" }}>
            <Row>
              <Col
                sm={8}
                smOffset={2}
                xs={12}
                className="text-center nopadding"
              >
                <Row className="flexbox-center-vertical">
                  <Col xs={3}>
                    <div>
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-cogs"
                        style={{ fontSize: "90px" }}
                      />
                    </div>
                    <div className="hiddenxsfontSize">
                      <AxoLocal
                        entity="FrontPageindextaxt3"
                        defaultValue={"Komplet IT-løsning"}
                      />
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div>
                      <Icon
                        glyph="icon-fontello-plus-6"
                        style={{ fontSize: "40px" }}
                      />
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div>
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-network"
                        style={{ fontSize: "90px" }}
                      />
                    </div>
                    <div className="hiddenxsfontSize">
                      <AxoLocal
                        entity="FrontPageindextaxt4"
                        defaultValue={"Fagligt netværk"}
                      />
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div>
                      <Icon
                        glyph="icon-fontello-plus-6"
                        style={{ fontSize: "40px" }}
                      />
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div>
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-laptop-circled"
                        style={{ fontSize: "90px" }}
                      />
                    </div>
                    <div className="hiddenxsfontSize">
                      <AxoLocal
                        entity="FrontPageindextaxt5"
                        defaultValue={"Kundeselvbetjeningspanel"}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>{" "}
        </SmallOrLarger>
        <ExtraSmall>
          <Grid
            fluid
            className="front-navbar-xs"
            style={{
              paddingLeft: "0px",
              paddingRight: "0px",
              paddingTop: "25px",
            }}
          >
            <Row style={{ paddingLeft: "5px", paddingRight: "0px" }}>
              <Col
                xs={12}
                className="text-center nopadding"
                style={{ fontSize: "12px" }}
              >
                <Col xs={3}>
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Icon
                      className="axolexwebsideicon"
                      glyph="icon-fontello-cogs"
                      style={{ fontSize: "60px" }}
                    />
                  </div>
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <AxoLocal
                      entity="FrontPageindextaxt3"
                      defaultValue={"Komplet IT-løsning"}
                    />
                  </div>
                </Col>
                <Col xs={1} className="nopadding">
                  <div
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingTop: "25px",
                    }}
                  >
                    <Icon
                      glyph="icon-fontello-plus-6"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                </Col>
                <Col xs={3} className="nopadding">
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Icon
                      className="axolexwebsideicon"
                      glyph="icon-fontello-network"
                      style={{ fontSize: "60px" }}
                    />
                  </div>
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <AxoLocal
                      entity="FrontPageindextaxt4"
                      defaultValue={"Fagligt netværk"}
                    />
                  </div>
                </Col>
                <Col xs={1} className="nopadding">
                  <div
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingTop: "25px",
                    }}
                  >
                    <Icon
                      glyph="icon-fontello-plus-6"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                </Col>
                <Col xs={3} className="nopadding">
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Icon
                      className="axolexwebsideicon"
                      glyph="icon-fontello-laptop-circled"
                      style={{ fontSize: "60px" }}
                    />
                  </div>
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <AxoLocal
                      entity="FrontPageindextaxt5"
                      defaultValue={"Kundeselvbetjeningspanel"}
                    />
                  </div>
                </Col>
              </Col>
            </Row>
          </Grid>
        </ExtraSmall>

        <Grid id="completePackage" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindextaxt7"
                  defaultValue={"IT-løsninger"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="text-center nopadding">
              <Col md={6} sm={12} xs={12} className="nopadding">
                <div>
                  <img
                    alt=""
                    src="/svg/FrontPage1-01.svg"
                    className="axoimg-responsive"
                  />
                </div>
                <h3>
                  <AxoLocal
                    entity="FrontPageindextaxt8"
                    defaultValue={"Komplet pakkeløsning (Multi-sprog)"}
                  />
                </h3>
              </Col>
              <Col md={6} sm={12} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <a
                      href="mailsystem"
                      onClick={this.onScrollToSolution.bind(this, "mailsystem")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt9"
                        defaultValue={"Mailsystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="network"
                      onClick={this.onScrollToSolution.bind(this, "network")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt10"
                        defaultValue={"Socialt medie system"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="network"
                      onClick={this.onScrollToSolution.bind(this, "network")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt11A"
                        defaultValue={"Internt social medie"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="cases"
                      onClick={this.onScrollToSolution.bind(this, "cases")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt12"
                        defaultValue={"Sagsstyringssystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="finance"
                      onClick={this.onScrollToSolution.bind(this, "finance")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt13"
                        defaultValue={"Økonomistyring"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="payment"
                      onClick={this.onScrollToSolution.bind(this, "payment")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt14"
                        defaultValue={"Online betalingssystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="client"
                      onClick={this.onScrollToSolution.bind(this, "client")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt15"
                        defaultValue={"Kundedatabehandling"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="selfservice"
                      onClick={this.onScrollToSolution.bind(
                        this,
                        "selfservice"
                      )}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt16"
                        defaultValue={"Klient selvbetjeningspanel"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="labels"
                      onClick={this.onScrollToSolution.bind(this, "labels")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt17"
                        defaultValue={"Arkivsystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="documents"
                      onClick={this.onScrollToSolution.bind(this, "documents")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt18"
                        defaultValue={"Dokumenthåndtering"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="booking"
                      onClick={this.onScrollToSolution.bind(this, "booking")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt19"
                        defaultValue={"Booking system"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="calendar"
                      onClick={this.onScrollToSolution.bind(this, "calendar")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt20"
                        defaultValue={"Kalender"}
                      />
                    </a>
                  </div>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="FrontPageindextaxt22"
                      defaultValue={"Fuld integration på tværs af programmer"}
                    />
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindexintegration"
                  defaultValue={"Dataressourcer og sikkerhed"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Col
          xs={12}
          className="nopadding text-center"
          style={{ fontSize: "15px" }}
        >
          <Col
            md={4}
            sm={12}
            className="nopadding"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <div className="">
              <Icon
                className="axolexwebsideicon"
                glyph="icon-fontello-lifebuoy-1"
                style={{ fontSize: "60px" }}
              />
            </div>
            <div className="editable">
              <h3>
                <AxoLocal
                  entity="FrontPageindexintegration1a"
                  defaultValue={"APIs"}
                />
              </h3>
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration1b"
                defaultValue={
                  "Integration med api services for offentlige åbne data inden for EU. "
                }
              />
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration1c"
                defaultValue={
                  "og giver dig en stærk databaseapplikation til at forøge produktivitet og effektivitet."
                }
              />
            </div>
          </Col>
          <Col
            md={4}
            sm={12}
            className="nopadding"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <div className="">
              <Icon
                className="axolexwebsideicon"
                glyph="icon-fontello-lock-circled"
                style={{ fontSize: "60px" }}
              />
            </div>
            <div className="editable">
              <h3>
                <AxoLocal
                  entity="FrontPageindexintegration2a"
                  defaultValue={"Datasikkerhed"}
                />
              </h3>
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration2b"
                defaultValue={
                  "Kryptering og sikker opbevaring på Microsofts Azure netværk."
                }
              />
            </div>
            <div>
              {locale.toLowerCase() === "enus" ? (
                <a
                  href={AxolexDataProcessingAgreement}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AxoLocal
                    entity="FrontPageindexintegration2c"
                    defaultValue={
                      "Nyeste foranstaltninger for at leve op til den nye EU persondataforordning (GDPR)."
                    }
                  />
                </a>
              ) : null}
              {locale === "da" ? (
                <a
                  href={AxolexDataBehandlerAftale}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AxoLocal
                    entity="FrontPageindexintegration2c"
                    defaultValue={
                      "Nyeste foranstaltninger for at leve op til den nye EU persondataforordning (GDPR)."
                    }
                  />
                </a>
              ) : null}
              {locale === "ch" ? (
                <a
                  href={AxolexDataProcessingAgreement}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AxoLocal
                    entity="FrontPageindexintegration2c"
                    defaultValue={
                      "Nyeste foranstaltninger for at leve op til den nye EU persondataforordning (GDPR)."
                    }
                  />
                </a>
              ) : null}
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration2d"
                defaultValue={
                  "Op til 1 million US dollar i ansvarsdækning, såfremt data opfanges af hackere."
                }
              />
            </div>
          </Col>
          <Col
            md={4}
            sm={12}
            className="nopadding"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <div className="">
              <Icon
                className="axolexwebsideicon"
                glyph="icon-fontello-resize-full-circle"
                style={{ fontSize: "60px" }}
              />
            </div>
            <div className="editable">
              <h3>
                <AxoLocal
                  entity="FrontPageindexintegration3a"
                  defaultValue={"Systemsikkerhed"}
                />
              </h3>
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration3b"
                defaultValue={
                  "Systemet anvender de nyeste teknologier til udvikling."
                }
              />
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration3c"
                defaultValue={
                  "To-faktor autentificering for at sikre brugerne."
                }
              />
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration3d"
                defaultValue={"Stærk faglig kommunikationsplatform."}
              />
            </div>
          </Col>
        </Col>

        <Grid id="mailsystem" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor ">
                <AxoLocal
                  entity="FrontPageindextaxt9"
                  defaultValue={"Mailsystem"}
                />{" "}
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="text-center nopadding">
              <Col sm={6} xs={12} className="flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <h3>
                    {" "}
                    <AxoLocal
                      entity="IndividualPackagetext165"
                      defaultValue={"Virksomhedens eget postsystem"}
                    />
                  </h3>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext1"
                      defaultValue={"Multi-sprog"}
                    />{" "}
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext2"
                      defaultValue={"Integrer dokumenter til sager"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext3"
                      defaultValue={"Intern post"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext4"
                      defaultValue={"Kryptering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext5"
                      defaultValue={"Ekstern mailhåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext6"
                      defaultValue={"Del filer"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext7"
                      defaultValue={"Mailhåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext8"
                      defaultValue={"Mailmapper"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext9"
                      defaultValue={"Sofistikeret tekst editor"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext10"
                      defaultValue={"Importer word dokumenter"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext11"
                      defaultValue={"Online backup"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext12"
                      defaultValue={"Auto klientliste"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext13"
                      defaultValue={"Auto brugerliste"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext181"
                      defaultValue={"Regelmæssig opdatering og support"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext182"
                      defaultValue={"Ubegrænset plads til beskeder"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext183A"
                      defaultValue={"Integration med eksterne mail servere"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext183B"
                      defaultValue={"Håndtering af multiple mail konti"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext183"
                      defaultValue={"Individuel mailadgang mv."}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={6} xs={12} className="flexbox-center  nopadding">
                <div style={{ paddingTop: "20px" }}>
                  {locale === "enus" ? <MailCarouselEnUS /> : null}
                  {locale === "da" ? <MailCarouselDa /> : null}
                  {locale === "ch" ? <MailCarouselCh /> : null}
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>

        <Grid id="network" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindextaxt10"
                  defaultValue={"Socialt medie system"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="text-center nopadding">
              <MediumOrLarger>
                <Col sm={6} xs={12} className="flexbox-center  nopadding">
                  <div style={{ paddingTop: "20px" }}>
                    {locale === "enus" ? <NetworkCarouselEnUS /> : null}
                    {locale === "da" ? <NetworkCarouselDa /> : null}
                    {locale === "ch" ? <NetworkCarouselCh /> : null}
                  </div>
                </Col>
              </MediumOrLarger>
              <Col sm={6} xs={12} className="flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <h3>
                    <AxoLocal
                      entity="IndividualPackagetext166A"
                      defaultValue={"Virksomhedens eget social medie program"}
                    />
                  </h3>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext14"
                      defaultValue={"Internationalt fagligt system (valgfrit)"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext15"
                      defaultValue={"Internt fagligt system"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext22"
                      defaultValue={"Ubegrænset private konferencerum"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext16"
                      defaultValue={"Kontakthåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext17"
                      defaultValue={"Offentlige indlæg"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext18"
                      defaultValue={"Interne indlæg"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext19"
                      defaultValue={"Importer .docx dokumenter"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext20"
                      defaultValue={"Særskilt social kontaktliste"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext21"
                      defaultValue={"Multi-sprog"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext23"
                      defaultValue={"Sofistikeret tekstbehandling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext24"
                      defaultValue={"Publicer fagligt materiale"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext25"
                      defaultValue={"Fildeling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext26"
                      defaultValue={"Filhåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext28"
                      defaultValue={"Integrer filer med dokumenthåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext184"
                      defaultValue={"Inddragelse af eksternt netværk mv."}
                    />
                  </div>
                </div>
              </Col>
              <SmallOrSmaller>
                <Col sm={6} xs={12} className="flexbox-center nopadding">
                  <div style={{ paddingTop: "20px" }}>
                    {locale === "enus" ? <NetworkCarouselEnUS /> : null}
                    {locale === "da" ? <NetworkCarouselDa /> : null}
                    {locale === "ch" ? <NetworkCarouselCh /> : null}
                  </div>
                </Col>
              </SmallOrSmaller>
            </Col>
          </Row>
        </Grid>

        <Grid id="finance" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindextaxt13"
                  defaultValue={"Økonomistyring"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="text-center nopadding">
              <Col sm={6} xs={12} className="flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox "
                  style={{ width: "400px" }}
                >
                  <h3>
                    {" "}
                    <AxoLocal
                      entity="IndividualPackagetext166"
                      defaultValue={"Funktioner"}
                    />{" "}
                  </h3>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext1"
                      defaultValue={"Multi-sprog"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="getHeaderTitletimeEntryAdded"
                      defaultValue={"Tidsregistrering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext82"
                      defaultValue={"Betalingstatus"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext83"
                      defaultValue={"Udgiftshåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext84"
                      defaultValue={"Tilknyt klient eller sag"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext85"
                      defaultValue={"Fakturafremstilling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext86"
                      defaultValue={"Fakturakonfigurering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext79"
                      defaultValue={"PDF generering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext87"
                      defaultValue={"Klient- og sagsfiltrering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext88"
                      defaultValue={"Søgefunktionalitet"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext89"
                      defaultValue={"Digital fakturering til klient"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext90"
                      defaultValue={"Mailhåndtering af faktura"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext61"
                      defaultValue={"Online betaling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext91"
                      defaultValue={"Påmindelse af betaling"}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={6} xs={12} className="flexbox-center nopadding">
                <div style={{ paddingTop: "20px" }}>
                  {locale === "enus" ? <FinanceCarouselEnUS /> : null}
                  {locale === "da" ? <FinanceCarouselDa /> : null}
                  {locale === "ch" ? <FinanceCarouselCh /> : null}
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>

        <Grid id="cases" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindextaxt12"
                  defaultValue={"SagSagsstyringssystem"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="text-center nopadding">
              <SmallOrLarger>
                <Col sm={6} className="flexbox-center nopadding">
                  <div style={{ paddingTop: "20px" }}>
                    {locale === "enus" ? <CasesCarouselEnUS /> : null}
                    {locale === "da" ? <CasesCarouselDa /> : null}
                    {locale === "ch" ? <CasesCarouselCh /> : null}
                  </div>
                </Col>{" "}
              </SmallOrLarger>
              <Col sm={6} className="flexbox-center  nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox "
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="IndividualPackagetext166"
                        defaultValue={"Funktioner"}
                      />
                    </h3>
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext29"
                      defaultValue={"Multi-sprog"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext30"
                      defaultValue={"Ubegrænset sagsfremstilling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext31"
                      defaultValue={"Sagsopsætning"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext32"
                      defaultValue={"Centralisering af sagsmateriale"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext33"
                      defaultValue={"Prioritet og status håndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext34"
                      defaultValue={"Tilknyt klient"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext35"
                      defaultValue={"Beskriv modparter"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext36"
                      defaultValue={"Sagsfremstilling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext37"
                      defaultValue={"Importer sagsfremstilling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext38"
                      defaultValue={"Printhåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext39"
                      defaultValue={"Avanceret word processing"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext40"
                      defaultValue={"Central sagsoversigt"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext41"
                      defaultValue={"Kartotekskort oversigt"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext42"
                      defaultValue={"Filtrering og søgning"}
                    />{" "}
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext44"
                      defaultValue={"Inline redigering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext45"
                      defaultValue={"Sagsdokumenthåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext46"
                      defaultValue={"Tilknyt dokumenter til sag"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext47"
                      defaultValue={"Administrer sagsdokumenter"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext48"
                      defaultValue={"Registrer sagsbehandlingstid"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext49"
                      defaultValue={"Påmindelse"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext50"
                      defaultValue={"Integreret med økonomihåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext51"
                      defaultValue={"Integreret med kalendersystem"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext183C"
                      defaultValue={"Central sagsfordeling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext183D"
                      defaultValue={"Gruppe sagsbehandling"}
                    />
                  </div>
                </div>
              </Col>
              <ExtraSmall>
                <Col xs={12} className="text-center nopadding">
                  <div style={{ paddingTop: "20px" }}>
                    {locale === "enus" ? <CasesCarouselEnUS /> : null}
                    {locale === "da" ? <CasesCarouselDa /> : null}
                    {locale === "ch" ? <CasesCarouselCh /> : null}
                  </div>
                </Col>{" "}
              </ExtraSmall>
            </Col>
          </Row>
        </Grid>

        <Grid id="clients" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="IndividualPackagetext167"
                  defaultValue={"Klient databehandling"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="text-center nopadding">
              <Col sm={6} xs={12} className="flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <h3>
                    {" "}
                    <AxoLocal
                      entity="IndividualPackagetext166"
                      defaultValue={"Funktioner"}
                    />
                  </h3>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext168"
                      defaultValue={"Ubegrænset klientregistrering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext169"
                      defaultValue={"Klient type oversigt"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext171"
                      defaultValue={"Central klientoversigt"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="CaseDataTableFixedShowCards"
                      defaultValue={"Vis som kartotekskort"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext173"
                      defaultValue={"Søgning og sortering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext174"
                      defaultValue={"Fuld inline redigering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext175"
                      defaultValue={"Tilføj selvbetjening"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext176"
                      defaultValue={"Klient selvbetjeningspanel"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext177"
                      defaultValue={"Multi-sprog"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="FrontPageindextaxt22"
                      defaultValue={"Fuld integration på tværs af programmer"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81A"
                      defaultValue={"Ubegrænsede kundeselvbetjeningskonti"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81B"
                      defaultValue={"Kundens egen login og kontrolpanel"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81C"
                      defaultValue={"Både intern og ekstern mail konto"}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={6} xs={12} className="flexbox-center  nopadding">
                <div style={{ paddingTop: "20px" }} className="flexbox-center">
                  {locale === "enus" ? <ClientsCarouselEnUS /> : null}
                  {locale === "da" ? <ClientsCarouselDa /> : null}
                  {locale === "ch" ? <ClientsCarouselCh /> : null}
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>

        <Grid id="selfservice" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindextaxt16"
                  defaultValue={"Klient selvbetjeningspanel"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="nopadding">
              <MediumOrLarger>
                <Col sm={6} xs={12} className="text-center nopadding">
                  <div style={{ paddingTop: "20px" }}>
                    {locale === "enus" ? <SelfserviceCarouselEnUS /> : null}
                    {locale === "da" ? <SelfserviceCarouselDa /> : null}
                    {locale === "ch" ? <SelfserviceCarouselCh /> : null}
                  </div>
                </Col>
              </MediumOrLarger>
              <Col sm={6} xs={12} className="flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <h3>
                    <AxoLocal
                      entity="IndividualPackagetext52"
                      defaultValue={"Funktioner"}
                    />
                  </h3>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext53"
                      defaultValue={"Internt mailprogram"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81C"
                      defaultValue={"Både intern og ekstern mail konto"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext54"
                      defaultValue={"Send krypterede beskeder"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext55"
                      defaultValue={"Booking system"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext56"
                      defaultValue={"Klient dokument håndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext57"
                      defaultValue={"Dokumentsøgning og sortering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext58"
                      defaultValue={"Optag video og lyd"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext59"
                      defaultValue={"Send dokumenter"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext60"
                      defaultValue={"Personoplysningshåndtering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext61"
                      defaultValue={"Online betaling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext1"
                      defaultValue={"Multi-sprog"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81"
                      defaultValue={"Integrering på tværs af systemer"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81A"
                      defaultValue={"Ubegrænsede kundeselvbetjeningskonti"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81B"
                      defaultValue={"Kundens egen login og kontrolpanel osv."}
                    />
                  </div>
                </div>
              </Col>
              <SmallOrSmaller>
                <Col sm={6} xs={12} className="text-center nopadding">
                  <div
                    style={{ paddingTop: "20px" }}
                    className="flexbox-center"
                  >
                    {locale === "enus" ? <SelfserviceCarouselEnUS /> : null}
                    {locale === "da" ? <SelfserviceCarouselDa /> : null}
                    {locale === "ch" ? <SelfserviceCarouselCh /> : null}
                  </div>
                </Col>
              </SmallOrSmaller>
            </Col>
          </Row>
        </Grid>
        <Grid fluid id="documents">
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindextaxt18"
                  defaultValue={"Dokumenthåndtering"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="text-center nopadding">
              <Col sm={6} xs={12} className="flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <h3>
                    {" "}
                    <AxoLocal
                      entity="IndividualPackagetext166"
                      defaultValue={"Funktioner"}
                    />
                  </h3>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext1"
                      defaultValue={"Multi-sprog"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext63"
                      defaultValue={"Upload dokumenter"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext64"
                      defaultValue={"Drag-drop dokumenter"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext65"
                      defaultValue={"Central dokumentoversigt"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext66"
                      defaultValue={"Kartotekskort oversigt"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext67"
                      defaultValue={"Søgning og sortering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext68"
                      defaultValue={"Inline redigering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext69"
                      defaultValue={"Status og prioritet"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext70"
                      defaultValue={"Tilføj påmindelser"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext71"
                      defaultValue={"Contract Management"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext72"
                      defaultValue={"Mappetilknytning"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext25"
                      defaultValue={"Fildeling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext73"
                      defaultValue={"Mailhåndtering af dokumenter"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext74"
                      defaultValue={"Optag video og lyd"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext77"
                      defaultValue={"Skabelonsfremstilling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext78"
                      defaultValue={"Importer skabelon"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext79"
                      defaultValue={"PDF generering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext80"
                      defaultValue={"Skabelondeling"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81"
                      defaultValue={"Integrering på tværs af systemer"}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={6} xs={12} className="flexbox-center  nopadding">
                <div style={{ paddingTop: "20px" }} className="flexbox-center">
                  {locale === "enus" ? <DocumentsCarouselEnUS /> : null}
                  {locale === "da" ? <DocumentsCarouselDa /> : null}
                  {locale === "ch" ? <DocumentsCarouselCh /> : null}
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>
        <Grid id="booking" fluid>
          <Row>
            <Col xs={12} className="nopadding text-center">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindextaxt19"
                  defaultValue={"Booking system"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="nopadding">
              <MediumOrLarger>
                <Col sm={6} xs={12} className="text-center nopadding">
                  <div style={{ paddingTop: "20px" }}>
                    {locale === "enus" ? <BookingCarouselEnUS /> : null}
                    {locale === "da" ? <BookingCarouselDa /> : null}
                    {locale === "ch" ? <BookingCarouselCh /> : null}
                  </div>
                </Col>
              </MediumOrLarger>
              <Col sm={6} xs={12} className="flexbox-center  nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <h3>
                    {" "}
                    <AxoLocal
                      entity="IndividualPackagetext166"
                      defaultValue={"Funktioner"}
                    />
                  </h3>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext1"
                      defaultValue={"Multi-sprog"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext92"
                      defaultValue={"Opsæt online booking"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext93"
                      defaultValue={"Drag-drop funktionalitet"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext94"
                      defaultValue={"Fritekst beskrivelse"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext95"
                      defaultValue={"Send booking link"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext96"
                      defaultValue={"Plugin til egen hjemmeside"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext97"
                      defaultValue={"Integreret med klient selvbetjeningspanel"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext98"
                      defaultValue={"Godkend booking anmodninger"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext99"
                      defaultValue={"Automatisk bekræftelse"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="CalendarEventBoxFormreminders"
                      defaultValue={"Påmindelser"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81"
                      defaultValue={"Integrering på tværs af systemer"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81A"
                      defaultValue={
                        "Indsæt booking modul på din egen hjemmeside"
                      }
                    />
                  </div>
                </div>
              </Col>
              <SmallOrSmaller>
                <Col sm={6} xs={12} className="text-center nopadding">
                  <div
                    style={{ paddingTop: "20px" }}
                    className="flexbox-center"
                  >
                    {locale === "enus" ? <BookingCarouselEnUS /> : null}
                    {locale === "da" ? <BookingCarouselDa /> : null}
                    {locale === "ch" ? <BookingCarouselCh /> : null}
                  </div>
                </Col>
              </SmallOrSmaller>
            </Col>
          </Row>
        </Grid>
        <Grid id="calendar" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                {" "}
                <AxoLocal
                  entity="FrontPageindextaxt20"
                  defaultValue={"Kalender"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="text-center nopadding">
              <Col sm={6} xs={12} className="flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <h3>
                    {" "}
                    <AxoLocal
                      entity="IndividualPackagetext166"
                      defaultValue={"Funktioner"}
                    />
                  </h3>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext1"
                      defaultValue={"Multi-sprog"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext100"
                      defaultValue={"Aftaleoversigt"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext102"
                      defaultValue={"Deadline påmindelser"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext103"
                      defaultValue={"Påmindelser for dokumenter"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext104"
                      defaultValue={"Aftaleoprettelse"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext105"
                      defaultValue={"Booking tid oprettelse"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext106"
                      defaultValue={"Tids- og aftaleredigering"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext107"
                      defaultValue={"Drag-drop af aftaler og tid"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext108"
                      defaultValue={"Oversigt over måned, uge..."}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="CalendarEventBoxFormreminders"
                      defaultValue={"Påmindelser"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext81"
                      defaultValue={"Integrering på tværs af systemer"}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={6} xs={12} className="flexbox-center nopadding">
                <div style={{ paddingTop: "20px" }}>
                  {locale === "enus" ? <CalendarCarouselEnUS /> : null}
                  {locale === "da" ? <CalendarCarouselDa /> : null}
                  {locale === "ch" ? <CalendarCarouselCh /> : null}
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>
        <Grid id="labels" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor ">
                {" "}
                <AxoLocal
                  entity="FrontPageviewtext2"
                  defaultValue={"Arkivsystem"}
                />{" "}
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="nopadding">
              <Col sm={6} xs={12} className="flexbox-center  nopadding">
                <div style={{ paddingTop: "20px" }}>
                  {locale === "enus" ? <LabelsCarouselEnUS /> : null}
                  {locale === "da" ? <LabelsCarouselDa /> : null}
                  {locale === "ch" ? <LabelsCarouselCh /> : null}
                </div>
              </Col>
              <Col sm={6} xs={12} className="flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <h3>
                    {" "}
                    <AxoLocal
                      entity="IndividualPackagetext166"
                      defaultValue={"Funktioner"}
                    />
                  </h3>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext8"
                      defaultValue={"Mailmapper"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="LabelTabViewCaseFolders"
                      defaultValue={"Sagsmapper"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="LabelTabViewDocumentfolders"
                      defaultValue={"Dokumentmapper"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext109"
                      defaultValue={"Ubegrænset mappeoprettelse"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext110"
                      defaultValue={"Integration i hele systemet"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext111"
                      defaultValue={"Og meget mere"}
                    />
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>
        <Grid id="payment" fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor ">
                <AxoLocal
                  entity="FrontPageindextaxt14"
                  defaultValue={"Online betalingssystem"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row className="text-center">
            <Col md={10} mdOffset={1} xs={12} className="nopadding">
              <Col sm={6} xs={12} className="flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px", paddingTop: "25px" }}
                >
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="FrontPageindextaxt14B"
                      defaultValue={"Fuldt online betalingssystem"}
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="FrontPageindextaxt14C"
                      defaultValue={
                        "Integreret med kundeselvbetjening og fakturering"
                      }
                    />
                  </div>
                  <div>
                    &#9632;{" "}
                    <AxoLocal
                      entity="FrontPageindextaxt14D"
                      defaultValue={
                        "Automatisk registrering af faktura betaling"
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col sm={6} xs={12} className="flexbox-center  nopadding">
                <div style={{ paddingTop: "20px" }}>
                  {locale === "enus" ? <OnlinepaymentCarouselEnUS /> : null}
                  {locale === "da" ? <OnlinepaymentCarouselDa /> : null}
                  {locale === "ch" ? <OnlinepaymentCarouselCh /> : null}
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Solutions);
