import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import {
  ExtraSmall,
  Small,
  Medium,
  Large,
  MediumOrSmaller,
} from "../utilities/Responsive";
import { Row, Col, Grid, Button } from "react-bootstrap";

import { LocalizationService } from "../services/AxoServices";

import { Icon, AxoLocal } from "../utilities/LexUtilities";

import AxolexDataBehandlerAftale from "../resources/files/AxolexDatabehandlerAftale.pdf";
import AxolexDataProcessingAgreement from "../resources/files/AxolexDataProcessingAgreement.pdf";

export default class FrontPageIndex extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      locale: "enUS",
    };
    this.name = "FrontPageIndex";
  }

  componentDidMount() {
    LocalizationService.subscribeToLocale(this.name, (locale) => {
      this.setState({ locale });
    });

    let scrollId = DataStore.getScrollId();
    if (scrollId) {
      setTimeout(() => {
        document.getElementById(scrollId).scrollIntoView();
        DataStore.setScrollId("");
      }, 500);
    }
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale(this.name);
  }

  onScrollToSolution = (element, event) => {
    event.preventDefault();
    var here = this.props.location.pathname.toLowerCase().includes("solutions");
    var scrollElement = document.getElementById(element);
    if (here && scrollElement) {
      scrollElement.scrollIntoView();
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Solutions");
    }
  };

  onScrollToPackages = (element, event) => {
    event.preventDefault();
    var here = this.props.location.pathname.toLowerCase().includes("packages");
    var scrollElement = document.getElementById(element);
    if (here && scrollElement) {
      scrollElement.scrollIntoView();
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Packages");
    }
  };

  render() {
    let { locale } = this.state;

    return (
      <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Grid fluid>
          <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Large>
              <Col md={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div
                      className="banner-item"
                      style={{ height: "600px", maxHeight: "600px" }}
                    >
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ height: "100%" }}
                        src="/svg/Axolex1-frontside.jpg"
                      />
                      <div className="text_wrapper_no_hover chbannetaxt">
                        <div className="frontbannetaxt1">
                          <div className="frontbannetaxt">
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />
                          </div>
                          <br />
                          <p className="item--text">
                            <AxoLocal
                              entity="FrontPageindextaxt2"
                              defaultValue={"Juridisk management software"}
                            />
                          </p>
                        </div>
                        <br />
                        <div
                          className="item--text"
                          style={{ paddingLeft: "0px" }}
                        >
                          <Grid
                            fluid
                            className="hidden-xs"
                            style={{
                              fontSize: "17px",
                              paddingTop: "10px",
                              background: "rgba(0,0,0,0.3)",
                            }}
                          >
                            <Row>
                              <Col
                                sm={10}
                                smOffset={1}
                                xs={12}
                                className="text-center nopadding"
                                style={{ fontSize: "17px" }}
                              >
                                <Row className="flexbox-center-vertical">
                                  <Col xs={3}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-cogs"
                                        style={{ fontSize: "70px" }}
                                      />
                                    </div>
                                    <div className="hiddenxsfontSize">
                                      <AxoLocal
                                        entity="FrontPageindextaxt3"
                                        defaultValue={"Komplet IT-løsning"}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={1}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-plus-6"
                                        style={{ fontSize: "40px" }}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-network"
                                        style={{ fontSize: "70px" }}
                                      />
                                    </div>
                                    <div className="hiddenxsfontSize">
                                      <AxoLocal
                                        entity="FrontPageindextaxt4"
                                        defaultValue={"Fagligt netværk"}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={1}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-plus-6"
                                        style={{ fontSize: "40px" }}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div>
                                      <Icon
                                        glyph="icon-fontello-laptop-circled"
                                        style={{ fontSize: "70px" }}
                                      />
                                    </div>
                                    <div className="hiddenxsfontSize">
                                      <AxoLocal
                                        entity="FrontPageindextaxt5"
                                        defaultValue={
                                          "Kundes elvbetjeningspanel"
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Grid>
                        </div>
                        <br />
                        <p
                          className="text-center"
                          style={{ paddingTop: "10px" }}
                        >
                          <Button
                            onClick={(event) => {
                              this.onScrollToPackages("trial", event);
                            }}
                            className="Lex-button-2"
                            style={{
                              height: "75px",
                              width: "250px",
                              fontSize: "17px",
                              fontWeight: "normal",
                            }}
                          >
                            <AxoLocal
                              entity="FrontPageindextaxt6"
                              defaultValue={"Prøv en gratis prøvekonto"}
                            />
                          </Button>
                        </p>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Large>

            <Medium>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex1-frontside-md.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h3 className="item--title">
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />
                          </h3>
                          <p className="item--text">
                            <AxoLocal
                              entity="FrontPageindextaxt2"
                              defaultValue={"Juridisk management software"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Medium>
            <Small>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex1-frontside-md.jpg"
                      />
                      <div className="text_wrapper">
                        <div
                          className="text_position chbannetaxt"
                          style={{ Color: "white" }}
                        >
                          <h3 className="item--title">
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />
                          </h3>
                          <p className="item--text">
                            <AxoLocal
                              entity="FrontPageindextaxt2"
                              defaultValue={"Juridisk management software"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Small>
            <ExtraSmall>
              <Col xs={12} className="nopadding">
                <div className="image-banner">
                  <div className="banner-container">
                    <div className="banner-item">
                      <img
                        alt=""
                        className="img-responsive bg_img "
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex1-frontside-xs.jpg"
                      />
                      <div className="text_wrapper">
                        <div className="text_position chbannetaxt">
                          <h5
                            className="item--title"
                            style={{ paddingLeft: "10px" }}
                          >
                            <AxoLocal
                              entity="FrontPageindextaxt1"
                              defaultValue={"Creating new standards"}
                            />
                          </h5>
                          <p className="item--text">
                            <AxoLocal
                              entity="FrontPageindextaxt2"
                              defaultValue={"Juridisk management software"}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </ExtraSmall>
          </Row>
        </Grid>

        <MediumOrSmaller>
          <Grid
            fluid
            className="front-navbar-xs"
            style={{ paddingTop: "25px" }}
          >
            <Row style={{ paddingLeft: "5px", paddingRight: "0px" }}>
              <Col
                xs={12}
                className="text-center nopadding"
                style={{ fontSize: "12px" }}
              >
                <Col xs={3} className="nopadding">
                  <div style={{ paddingLeft: "px", paddingRight: "0px" }}>
                    <Icon
                      className="axolexwebsideicon"
                      glyph="icon-fontello-desktop-circled"
                      style={{ fontSize: "50px" }}
                    />
                  </div>
                  <div style={{ paddingLeft: "5px", paddingRight: "0px" }}>
                    <AxoLocal
                      entity="FrontPageindextaxt3"
                      defaultValue={"Komplet IT-løsning"}
                    />
                  </div>
                </Col>
                <Col xs={1} className="nopadding">
                  <div
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingTop: "25px",
                    }}
                  >
                    <Icon
                      glyph="icon-fontello-plus-6"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                </Col>
                <Col xs={3} className="nopadding">
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Icon
                      className="axolexwebsideicon"
                      glyph="icon-fontello-network"
                      style={{ fontSize: "50px" }}
                    />
                  </div>
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <AxoLocal
                      entity="FrontPageindextaxt4"
                      defaultValue={"Fagligt netværk"}
                    />
                  </div>
                </Col>
                <Col xs={1} className="nopadding">
                  <div
                    style={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      paddingTop: "25px",
                    }}
                  >
                    <Icon
                      glyph="icon-fontello-plus-6"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                </Col>
                <Col xs={3} className="nopadding">
                  <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Icon
                      className="axolexwebsideicon"
                      glyph="icon-fontello-laptop-circled"
                      style={{ fontSize: "50px" }}
                    />
                  </div>
                  <div style={{ paddingLeft: "0px", paddingRight: "2px" }}>
                    <AxoLocal
                      entity="FrontPageindextaxt5"
                      defaultValue={"Kunde selvbetjeningspanel"}
                    />
                  </div>
                </Col>
              </Col>
            </Row>
          </Grid>
        </MediumOrSmaller>

        <div id="completePackage" className="axosmAnchorOffSet">
          .
        </div>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindextaxt7"
                  defaultValue={"Komplet pakkeløsning"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row>
            <Col sm={10} smOffset={1} xs={12} className="text-center nopadding">
              <Col md={6} sm={12} xs={12} className="nopadding">
                <div>
                  <img
                    alt=""
                    src="/svg/FrontPage1-01.svg"
                    className="axoimg-responsive"
                  />
                </div>
                <h3>
                  <AxoLocal
                    entity="FrontPageindextaxt8A"
                    defaultValue={"Komplet pakkeløsning (Multi-sprog)"}
                  />
                </h3>
              </Col>
              <Col md={6} sm={12} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <a
                      href="mailsystem"
                      onClick={this.onScrollToSolution.bind(this, "mailsystem")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt9"
                        defaultValue={"Mailsystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="network"
                      onClick={this.onScrollToSolution.bind(this, "network")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt10"
                        defaultValue={"Socialt medie system"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="network"
                      onClick={this.onScrollToSolution.bind(this, "network")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt11A"
                        defaultValue={"Internt social medie"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="cases"
                      onClick={this.onScrollToSolution.bind(this, "cases")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt12"
                        defaultValue={"Sagsstyringssystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="finance"
                      onClick={this.onScrollToSolution.bind(this, "finance")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt13"
                        defaultValue={"Økonomistyring"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="payment"
                      onClick={this.onScrollToSolution.bind(this, "payment")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt14"
                        defaultValue={"Online betalingssystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="client"
                      onClick={this.onScrollToSolution.bind(this, "client")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt15"
                        defaultValue={"Kundedatabehandling"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="selfservice"
                      onClick={this.onScrollToSolution.bind(
                        this,
                        "selfservice"
                      )}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt16"
                        defaultValue={"Klient selvbetjeningspanel"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="labels"
                      onClick={this.onScrollToSolution.bind(this, "labels")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt17"
                        defaultValue={"Arkivsystem"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="documents"
                      onClick={this.onScrollToSolution.bind(this, "documents")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt18"
                        defaultValue={"Dokumenthåndtering"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="booking"
                      onClick={this.onScrollToSolution.bind(this, "booking")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt19"
                        defaultValue={"Booking system"}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="calendar"
                      onClick={this.onScrollToSolution.bind(this, "calendar")}
                    >
                      &#9658;{" "}
                      <AxoLocal
                        entity="FrontPageindextaxt20"
                        defaultValue={"Kalender"}
                      />
                    </a>
                  </div>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="IndividualPackagetext183E"
                      defaultValue={"Live IT-support for alle systemer."}
                    />
                  </div>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="FrontPageindextaxt22"
                      defaultValue={"Fuld integration på tværs af programmer"}
                    />
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
          <div></div>
        </Grid>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindexintegration"
                  defaultValue={"Dataressourcer og sikkerhed"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Col
          xs={12}
          className="nopadding text-center"
          style={{ fontSize: "15px" }}
        >
          <Col
            md={4}
            sm={12}
            className="nopadding"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <div className="">
              <Icon
                className="axolexwebsideicon"
                glyph="icon-fontello-lifebuoy-1"
                style={{ fontSize: "60px" }}
              />
            </div>
            <div className="editable">
              <h3>
                <AxoLocal
                  entity="FrontPageindexintegration1a"
                  defaultValue={"APIs"}
                />
              </h3>
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration1b"
                defaultValue={
                  "Integration med api services for offentlige åbne data inden for EU. "
                }
              />
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration1c"
                defaultValue={
                  "og giver dig en stærk databaseapplikation til at forøge produktivitet og effektivitet."
                }
              />
            </div>
          </Col>
          <Col
            md={4}
            sm={12}
            className="nopadding"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <div className="">
              <Icon
                className="axolexwebsideicon"
                glyph="icon-fontello-lock-circled"
                style={{ fontSize: "60px" }}
              />
            </div>
            <div className="editable">
              <h3>
                <AxoLocal
                  entity="FrontPageindexintegration2a"
                  defaultValue={"Datasikkerhed"}
                />
              </h3>
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration2b"
                defaultValue={
                  "Kryptering og sikker opbevaring på Microsofts Azure netværk."
                }
              />
            </div>
            <div>
              {locale.toLowerCase() === "enus" ? (
                <a
                  href={AxolexDataProcessingAgreement}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AxoLocal
                    entity="FrontPageindexintegration2c"
                    defaultValue={
                      "Nyeste foranstaltninger for at leve op til den nye EU persondataforordning (GDPR)."
                    }
                  />
                </a>
              ) : null}
              {locale === "da" ? (
                <a
                  href={AxolexDataBehandlerAftale}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AxoLocal
                    entity="FrontPageindexintegration2c"
                    defaultValue={
                      "Nyeste foranstaltninger for at leve op til den nye EU persondataforordning (GDPR)."
                    }
                  />
                </a>
              ) : null}
              {locale === "ch" ? (
                <a
                  href={AxolexDataProcessingAgreement}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AxoLocal
                    entity="FrontPageindexintegration2c"
                    defaultValue={
                      "Nyeste foranstaltninger for at leve op til den nye EU persondataforordning (GDPR)."
                    }
                  />
                </a>
              ) : null}
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration2d"
                defaultValue={
                  "Op til 1 million US dollar i ansvarsdækning, såfremt data opfanges af hackere."
                }
              />
            </div>
          </Col>
          <Col
            md={4}
            sm={12}
            className="nopadding"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <div className="">
              <Icon
                className="axolexwebsideicon"
                glyph="icon-fontello-resize-full-circle"
                style={{ fontSize: "60px" }}
              />
            </div>
            <div className="editable">
              <h3>
                <AxoLocal
                  entity="FrontPageindexintegration3a"
                  defaultValue={"Systemsikkerhed"}
                />
              </h3>
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration3b"
                defaultValue={
                  "Systemet anvender de nyeste teknologier til udvikling."
                }
              />
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration3c"
                defaultValue={
                  "To-faktor autentificering for at sikre brugerne."
                }
              />
            </div>
            <div className="">
              <AxoLocal
                entity="FrontPageindexintegration3d"
                defaultValue={"Stærk faglig kommunikationsplatform."}
              />
            </div>
          </Col>
        </Col>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                <AxoLocal
                  entity="FrontPageindexintegration8"
                  defaultValue={"System Collaboration Solutions"}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row>
            <Col sm={10} smOffset={1} xs={12} className="text-center nopadding">
              <Col md={6} sm={12} xs={12} className="nopadding flexbox-center">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <h3>
                    <AxoLocal
                      entity="FrontPageindexintegration1"
                      defaultValue={"Intelligent integration"}
                    />
                  </h3>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="FrontPageindexintegration2"
                      defaultValue={"Applikations integration"}
                    />
                  </div>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="FrontPageindexintegration3"
                      defaultValue={"Content integration"}
                    />
                  </div>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="FrontPageindexintegration4"
                      defaultValue={"Process integration"}
                    />
                  </div>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="FrontPageindexintegration5"
                      defaultValue={"Forretningsintegration"}
                    />
                  </div>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="FrontPageindexintegration9"
                      defaultValue={"Service integration"}
                    />
                  </div>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="FrontPageindexintegration10"
                      defaultValue={"Dataintegration"}
                    />
                  </div>
                  <div>
                    &#9658;{" "}
                    <AxoLocal
                      entity="FrontPageindexintegration7"
                      defaultValue={"Information og ressource integration"}
                    />
                  </div>
                  <div>
                    <AxoLocal
                      entity="FrontPageindexintegration7AB"
                      defaultValue={
                        " Den perfekte IT-løsning til advokater, juridiske konsulenter og rådgivere."
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} sm={12} xs={12} className="nopadding">
                <div style={{ paddingTop: "20px" }}>
                  <img
                    alt=""
                    src="/imgs/app/1AxolexEC9.png"
                    className="axoimg-responsive"
                  />
                </div>
              </Col>
            </Col>
            <Col xs={12} className="nopadding">
              <div style={{ paddingTop: "20px" }}>
                <AxoLocal
                  entity="FrontPageindexintegration7ABC"
                  defaultValue={
                    " Vi går efter 100% tilfredshed. Så det er din mening der tæller. Prøv systemet i dag og få udfordret din digitale intelligens. Vi udfører arbejdet. Du skal bare komme med din mening og dine behov."
                  }
                />
              </div>
            </Col>
          </Row>
          <Col sm={10} smOffset={1} xs={12} className="text-center nopadding">
            <p className="text-center" style={{ paddingTop: "10px" }}>
              <Button
                onClick={(event) => {
                  this.onScrollToPackages("trial", event);
                }}
                className="Lex-button-2"
                style={{
                  height: "75px",
                  width: "250px",
                  fontSize: "17px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  entity="FrontPageindextaxt6"
                  defaultValue={"Prøv en gratis prøvekonto"}
                />
              </Button>
            </p>
          </Col>
          <div></div>
        </Grid>
        {/* <div  className='text-center'>
          <object type="image/svg+xml" data="/svg/GL-icons_01-26.svg" className="axosvg">
          test svg
          </object>
        </div> */}
      </div>
    );
  }
}
