import React from "react";
import PropTypes from "prop-types";
// import { ExtraSmall, SmallOrLarger } from '../utilities/Responsive';
import { Table, Column, Cell } from "fixed-data-table-2";
import ReactSelect from "react-select";

import {
  TableBaseSimple,
  Dimensions,
  DataListWrapper,
  SortHeaderCell,
  Icon,
  AxoLocal,
  Flexbox,
  FlexElement,
  getText,
} from "../../utilities/LexUtilities";

import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { DataActions } from "../../services/AxoServices";

class ClientLabelTable extends TableBaseSimple {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    selectedLabelId: PropTypes.number,
    onRemoveClient: PropTypes.func.isRequired,
    onDeleteClient: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this._defaultSortIndexes = TableBaseSimple.generateDefaultSortIndices(
      props.entries
    );
    this.state = {
      tableEntries: [], //Legacy
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        props.entries
      ),
      colSortDirs: {},
      searchText: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entries !== this.props.entries) {
      this.doSearch(this.state.searchText);
    }
  }

  onSearch = (event) => {
    this.doSearch(event.target.value);
  };

  doSearch = (searchText) => {
    let { entries } = this.props;

    let filteredEntries = entries.filter((e) =>
      (e.firstName + " " + e.lastName + " " + e.eMail)
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );

    this._defaultSortIndexes = this.generateDefaultSortIndexes(filteredEntries);
    let dataList = new DataListWrapper(
      this._defaultSortIndexes,
      filteredEntries
    );
    this.setState({
      searchText,
      sortedDataList: dataList,
    });
  };

  render() {
    let { sortedDataList, colSortDirs, searchText } = this.state;

    let {
      labels,
      containerHeight,
      containerWidth,
      selectedLabelId,
      onRemoveClient,
      onDeleteClient,
      userType,
      userSettings,
      adminCodeValidated,
    } = this.props;

    let tableWidth = containerWidth;
    let widthUnit = tableWidth / 4;

    let labelOptions = labels.map((e) => ({ value: e.id, label: e.name }));
    var formerOption = labelOptions.find(
      (l) => l.value === userSettings.formerMembersLabelId
    ) || { value: 0, label: getText("axoidcode186", "Udmeldte") };
    var waitingOption = labelOptions.find(
      (l) => l.value === userSettings.waitingMembersLabelId
    ) || { value: 0, label: getText("axoidcode185", "Venteliste") };

    return (
      <div>
        <Flexbox responsive collapseXS className="onTop">
          <FlexElement className="rightPadding">
            <FormGroup
              controlId="inputWithIcon"
              style={{ minWidth: "150px", maxWidth: "600px" }}
            >
              <InputGroup>
                <InputGroup.Addon>
                  <Icon glyph="icon-fontello-search" />
                </InputGroup.Addon>
                {/* Søg...  */}
                <AxoLocal
                  componentClass={FormControl}
                  type="text"
                  value={searchText}
                  onChange={this.onSearch}
                  componentAttribute="placeholder"
                  entity="ContactTableViewplaceholderonSearch"
                />
                <FormControl.Feedback>
                  <Icon glyph="icon-fontello-search" />
                </FormControl.Feedback>
              </InputGroup>
            </FormGroup>
          </FlexElement>
          {userType === "Society" && adminCodeValidated ? (
            <React.Fragment>
              <FlexElement className="rightPadding">
                <Flexbox responsive collapseXS alignCenter>
                  <FlexElement
                    title={getText(
                      "axoidcode242",
                      "Denne gruppe anvendes som venteliste."
                    )}
                  >
                    <AxoLocal
                      entity="axoidcode185"
                      defaultValue={"Venteliste"}
                    />
                    :&nbsp;
                  </FlexElement>
                  <FlexElement
                    title={getText(
                      "axoidcode242",
                      "Denne gruppe anvendes som venteliste."
                    )}
                  >
                    <div style={{ minWidth: "150px" }}>
                      <ReactSelect
                        name="waitingSelect"
                        menuPlacement="auto"
                        value={waitingOption}
                        onChange={(selection) => {
                          if (!selection) {
                            return;
                          }
                          DataActions.updateUserSettings({
                            ...userSettings,
                            waitingMembersLabelId: selection.value,
                          });
                        }}
                        noOptionsMessage={() => ""}
                        options={labelOptions}
                      />
                    </div>
                  </FlexElement>
                </Flexbox>
              </FlexElement>
              <FlexElement className="rightPadding">
                <Flexbox responsive collapseXS alignCenter>
                  <FlexElement
                    title={getText(
                      "axoEntityidcode268",
                      "Denne gruppe anvendes til udmeldte medlemmer."
                    )}
                  >
                    <AxoLocal entity="axoidcode186" defaultValue={"Udmeldte"} />{" "}
                    :&nbsp;
                  </FlexElement>
                  <FlexElement
                    title={getText(
                      "axoEntityidcode268",
                      "Denne gruppe anvendes til udmeldte medlemmer."
                    )}
                  >
                    <div style={{ minWidth: "150px" }}>
                      <ReactSelect
                        name="formerSelect"
                        menuPlacement="auto"
                        value={formerOption}
                        onChange={(selection) => {
                          if (!selection) {
                            return;
                          }
                          DataActions.updateUserSettings({
                            ...userSettings,
                            formerMembersLabelId: selection.value,
                          });
                        }}
                        noOptionsMessage={() => ""}
                        options={labelOptions}
                      />
                    </div>
                  </FlexElement>
                </Flexbox>
              </FlexElement>
            </React.Fragment>
          ) : null}
        </Flexbox>

        <Table
          rowHeight={35}
          rowsCount={sortedDataList.getSize()}
          width={tableWidth}
          height={Math.max(containerHeight, 100)}
          headerHeight={40}
        >
          <Column
            columnKey="firstName"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.firstName}
              >
                <AxoLocal
                  entity="placeholderfirstName"
                  defaultValue={"Fornavn"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {entry.firstName}
                </Cell>
              );
            }}
            width={widthUnit}
          />
          <Column
            columnKey="lastName"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.lastName}
              >
                <AxoLocal
                  entity="placeholderlastName"
                  defaultValue={"Efternavn"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {entry.lastName}
                </Cell>
              );
            }}
            width={widthUnit}
          />
          <Column
            columnKey="eMail"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.amount}
              >
                <AxoLocal entity="CaseEditFormeMail" defaultValue={"Email"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {entry.eMail}
                </Cell>
              );
            }}
            width={widthUnit}
          />
          <Column
            columnKey="actions"
            header={<Cell></Cell>}
            cell={(props) => {
              var entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} className="text-center" {...props}>
                  <Icon
                    className="editable"
                    role="button"
                    title={getText("axoEntityidcode265", "Fjern fra mappe")}
                    onClick={onRemoveClient.bind(
                      this,
                      entry.id,
                      selectedLabelId
                    )}
                    glyph="icon-fontello-scissors"
                  />
                  &ensp;&ensp;&ensp; &ensp;&ensp;&ensp;&ensp;
                  <Icon
                    className="editable"
                    role="button"
                    title={getText(
                      "axoEntityidcode266",
                      "Flyt til skraldespanden"
                    )}
                    onClick={onDeleteClient.bind(this, entry.id)}
                    glyph="icon-fontello-trash-1"
                  />
                </Cell>
              );
            }}
            width={widthUnit}
          />
        </Table>
      </div>
    );
  }
}

export default Dimensions({
  elementResize: true,
})(ClientLabelTable);
