import React, { useState, useEffect } from "react";

import moment from "moment";
import { ApiService } from "../../services/AxoServices";

import LexButton from "../LexButton";

import { AxoLocal } from "../../utilities/LexUtilities";
let settings, setSettings;
let loginMessage, setLoginMessage;
let useKeyFile, setUseKeyFile;

function EasyIDView(props) {
  [settings, setSettings] = useState(null);
  [loginMessage, setLoginMessage] = useState(null);
  [useKeyFile, setUseKeyFile] = useState(false);

  let { onLogin, loginUserAccount, cacheLogin } = props;

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("message", onNemIDMessage);
      window.addEventListener("message", onCodeFileMessage);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", onNemIDMessage);
      window.attachEvent("onmessage", onCodeFileMessage);
    }

    updateEasyIDSettings();
  }, []);

  const updateEasyIDSettings = async () => {
    await ApiService.getEasyIDSettings().then((newSettings) => {
      setSettings(newSettings);
    });
  };

  const onNemIDMessage = async (e) => {
    let event = e;

    if (event.origin !== "https://applet.danid.dk") {
      //window.alert(""Received message from unexpected origin : "" + event.origin + ""Expected origin: {0}"" );
      return;
    }

    let frame = document.getElementById("nemid_iframe");
    if (!frame) {
      console.log("Frame not found.");
      return;
    }

    let win = frame.contentWindow;
    let postMessage = {};
    let message = JSON.parse(event.data);

    if (message.command === "SendParameters") {
      postMessage.command = "parameters";
      postMessage.content = settings.cardParameters;
      win.postMessage(JSON.stringify(postMessage), "https://applet.danid.dk");
    }

    if (message.command === "changeResponseAndSubmit") {
      if (message.content.length > 20) {
        handleLogon(message.content);
      } else {
        setFailureMessage("Login kunne ikke gennemføres. Prøv igen senere.");
        console.log(message.content);
      }
    }
  };

  const onCodeFileMessage = async (e) => {
    let event = e;
    var iframeOrigin = "https://codefileclient.danid.dk";
    if (iframeOrigin !== event.origin) {
      return;
    }

    let frame = document.getElementById("codefile_iframe");
    if (!frame) {
      return;
    }

    let win = frame.contentWindow;
    let postMessage = {};
    let message = JSON.parse(event.data);

    if (message.command === "SendParameters") {
      postMessage.command = "parameters";
      postMessage.content = settings.keyFileParameters;
      win.postMessage(
        JSON.stringify(postMessage),
        "https://codefileclient.danid.dk"
      );
    }

    if (message.command === "changeResponseAndSubmit") {
      if (message.content.length > 20) {
        handleLogon(message.content);
      } else {
        console.log(message.content);
      }
    }
    if (message.command === "logonCancel") {
      setFailureMessage("Login blev aflyst.");
    }
    if (message.command === "logonError") {
      setFailureMessage("Login kunne ikke gennemføres. Prøv igen senere.");
    }
  };

  const handleLogon = async (content) => {
    //Succesful login
    if (loginUserAccount) {
      let response = await ApiService.handleEasyIDAccountLogon({
        signature: content,
        challenge: settings.challenge,
        cacheLogin,
      });

      if (!response.ok) {
        setFailureMessage("Login kunne ikke gennemføres. Prøv igen senere.");
        return;
      }

      let loginResult = await response.json();
      if (!!onLogin) {
        onLogin(loginResult);
        return;
      }

      setLoginMessage("Login handler required.");
    } else {
      let response = await ApiService.handleEasyIDLogon({
        signature: content,
        challenge: settings.challenge,
      });

      if (!response.ok) {
        setFailureMessage("Login kunne ikke gennemføres. Prøv igen senere.");
        return;
      }

      let loginMessage = await response.text();
      if (!!onLogin) {
        onLogin(loginMessage);
        return;
      }

      setLoginMessage(loginMessage);
    }
  };

  const setFailureMessage = async (message) => {
    setLoginMessage(message);
    await updateEasyIDSettings();

    setTimeout(() => setLoginMessage(""), 3000);
  };

  if (!settings) {
    return <div></div>;
  }

  if (!!loginMessage) {
    return <h4>{loginMessage}</h4>;
  }

  if (useKeyFile) {
    return (
      <div>
        <div>
          <LexButton
            style={{ width: "100%" }}
            onClick={() => setUseKeyFile(false)}
          >
            <h4>
              <AxoLocal
                entity="axoEntityidcode252"
                defaultValue={"Brug nøglekort"}
              />
            </h4>
          </LexButton>
        </div>
        <div id="frameHolder">
          <iframe
            id="codefile_iframe"
            key="card"
            name="target_iframe"
            title="NemID CodeFile client"
            scrolling="no"
            style={{
              width: "500px",
              height: "460px",
              border: "0px",
              margin: "0px",
              padding: "0px",
              overflow: "hidden",
            }}
            src={"https://codefileclient.danid.dk?t=" + moment().valueOf()}
          ></iframe>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <LexButton
          style={{ width: "100%" }}
          onClick={() => setUseKeyFile(true)}
        >
          <h4>
            <AxoLocal
              entity="axoEntityidcode253"
              defaultValue={"Brug nøglefil"}
            />
          </h4>
        </LexButton>
      </div>
      <div id="frameHolder">
        <iframe
          id="nemid_iframe"
          name="nemid_iframe"
          key="file"
          title="nemid_iframe"
          scrolling="no"
          style={{
            width: "320px",
            height: "460px",
            border: "0px",
            margin: "0px",
            padding: "0px",
            overflow: "hidden",
          }}
          // style=""width:{0}px;height:{1}px;border:0px;margin:0px;padding:0px;overflow:hidden;""
          src={"https://applet.danid.dk/launcher/lmt/" + moment().valueOf()}
        ></iframe>
      </div>
    </div>
  );
}

export default React.memo(EasyIDView);
