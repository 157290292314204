import React from "react";
import moment from "moment";
import AxoDateTime from "../../utilities/AxoDateTime";
import ColorPicker from "./ColorPicker";
import { AxoCheckbox } from "../../utilities/LexUtilities";

import { Grid, Row, Col, Button } from "react-bootstrap";
import {
  Icon,
  AxoLocal,
  InlineEdit,
  InlineTextArea,
} from "../../utilities/LexUtilities";

export default class CalendarEventForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      //Interval time in minutes
      reminders: [
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventFormAtthetime"
                defaultValue={"På tidspunktet"}
              />
            </span>
          ),
          interval: 0,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm5m"
                defaultValue={"5 minutter før"}
              />
            </span>
          ),
          interval: 5,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm15m"
                defaultValue={"15 minutter før"}
              />
            </span>
          ),
          interval: 15,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm30m"
                defaultValue={"30 minutter før"}
              />
            </span>
          ),
          interval: 30,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm1t"
                defaultValue={"1 time før"}
              />
            </span>
          ),
          interval: 60,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm2t"
                defaultValue={"2 timer før"}
              />
            </span>
          ),
          interval: 120,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm1d"
                defaultValue={"1 dag før"}
              />{" "}
            </span>
          ),
          interval: 1440,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm2d"
                defaultValue={"2 dage før"}
              />
            </span>
          ),
          interval: 2880,
          selected: false,
        },
        {
          title: (
            <span>
              <AxoLocal
                entity="CalendarEventForm1w"
                defaultValue={"1 uge før"}
              />
            </span>
          ),
          interval: 10080,
          selected: false,
        },
      ],
    };
    this.state = {
      //Interval time in minutes
      reminders: this.extractRemindersState(this.props.calendarEvent),
    };
    this.state.showBookingLink = false;
  }

  //Props
  //calendarEvent
  //onCalendarEventUpdate
  //userProfile
  componentWillReceiveProps(nextProps) {
    this.setState({
      reminders: this.extractRemindersState(nextProps.calendarEvent),
    });
  }

  isValidInteger = (value) => {
    return Math.round(value) === value;
  };

  extractRemindersState = (calendarEvent) => {
    var reminders = this.state.reminders;
    reminders.forEach((r) => (r.selected = false));
    if (this.isValidInteger(calendarEvent.firstReminder)) {
      var first = reminders.find(
        (r) => r.interval === calendarEvent.firstReminder
      );
      first.selected = true;
    }
    if (this.isValidInteger(calendarEvent.secondReminder)) {
      var second = reminders.find(
        (r) => r.interval === calendarEvent.secondReminder
      );
      second.selected = true;
    }
    return reminders;
  };

  setEventRemindersFromRemindersState = (reminders) => {
    var calendarEvent = { ...this.props.calendarEvent };
    calendarEvent.firstReminder = null;
    calendarEvent.secondReminder = null;
    reminders.forEach((r) => {
      if (!r.selected) {
        return;
      }
      if (!this.isValidInteger(calendarEvent.firstReminder)) {
        calendarEvent.firstReminder = r.interval;
      } else if (!this.isValidInteger(calendarEvent.secondReminder)) {
        calendarEvent.secondReminder = r.interval;
      }
    });
    this.onChangeCalendarEvent(calendarEvent);
  };

  ontTitleChange = (input) => {
    this.onChangeCalendarEvent({
      ...this.props.calendarEvent,
      title: input.value,
    });
  };

  onStartDateChange = (startMoment) => {
    var event = { ...this.props.calendarEvent };
    var endDate = new Date(event.end);
    var startDate = new Date(event.start);
    var diff = endDate - startDate;

    event.start = new Date(startMoment);
    event.end = new Date(event.start.getTime() + diff);
    this.onChangeCalendarEvent(event);
  };

  onEndDateChange = (endMoment) => {
    this.onChangeCalendarEvent({
      ...this.props.calendarEvent,
      end: new Date(endMoment),
    });
  };

  onDescChange = (input) => {
    this.onChangeCalendarEvent({
      ...this.props.calendarEvent,
      description: input.value,
    });
  };

  onCheckAllDay = (event) => {
    this.onChangeCalendarEvent({
      ...this.props.calendarEvent,
      allDay: event.target.checked,
    });
  };

  onCheckBooking = (event) => {
    this.onChangeCalendarEvent({
      ...this.props.calendarEvent,
      bookingTime: event.target.checked,
    });
  };

  onSelectReminder = (reminder) => {
    var reminders = [...this.state.reminders];
    var selectedReminder = reminders.find(
      (r) => r.interval === reminder.interval
    );
    if (!selectedReminder.selected && this.countSelected() > 1) {
      var allSelected = reminders.filter((r) => r.selected);
      allSelected[allSelected.length - 1].selected = false;
    }
    selectedReminder.selected = !selectedReminder.selected;
    this.setEventRemindersFromRemindersState(reminders);
    this.setState({ reminders });
  };

  onChangeCalendarEvent = (calendarEvent) => {
    this.props.onCalendarEventUpdate(calendarEvent);
  };

  countSelected = () => {
    var selectedCount = 0;
    for (var i = 0; i < this.state.reminders.length; ++i) {
      if (this.state.reminders[i].selected) {
        selectedCount++;
      }
    }
    return selectedCount;
  };

  renderReminder = (reminder) => {
    var selectedIcon = (
      <Icon
        className="editable"
        glyph="icon-fontello-ok-3"
        style={{ visibility: reminder.selected ? "visible" : "hidden" }}
      />
    );
    return (
      <div
        key={reminder.interval}
        onClick={this.onSelectReminder.bind(this, reminder)}
      >
        <small>
          {reminder.title}&nbsp;{selectedIcon}&nbsp;
        </small>
      </div>
    );
  };

  renderReminders = () => {
    var reminders = this.state.reminders;
    var reminderRows = [];
    for (var i = 0; i < reminders.length; i = i + 2) {
      reminderRows.push(
        <tr key={i}>
          <td>{this.renderReminder(reminders[i])}</td>
          {i <= reminders.length - 2 ? (
            <td>{this.renderReminder(reminders[i + 1])}</td>
          ) : null}
        </tr>
      );
    }
    return reminderRows;
  };

  renderRemindersTable = () => {
    return (
      <table>
        <tbody>{this.renderReminders()}</tbody>
      </table>
    );
  };

  renderRequiredStar = (requiredValue) => {
    var color = !!requiredValue ? "green" : "red";
    return <span style={{ color: color }}>* </span>;
  };

  toggleShowBookingLink = () => {
    this.setState({ showBookingLink: !this.state.showBookingLink });
  };

  getBookingUrl = () => {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/Booking/" +
      this.props.userProfile.userId
    );
  };

  makeEmbedLink = () => {
    var embed =
      '<div style="position:relative;height:0;padding-bottom:56.25%"><iframe src="';
    embed += this.getBookingUrl();
    embed +=
      '" width="640" height="360" frameborder="0" style="position:absolute;width:100%;height:100%;left:0" allowfullscreen></iframe></div>';
    return embed;
  };

  renderBookingLinks = (showBookingLink) => {
    if (!this.state.showBookingLink) {
      return null;
    }
    var bookingLinks = [];
    bookingLinks.push(
      <Row>
        <div>
          <b>
            <AxoLocal
              entity="CalendarEventFormbookingLink"
              defaultValue={"Link"}
            />
            :
          </b>
          <span>{this.getBookingUrl()}</span>
        </div>
      </Row>
    );
    bookingLinks.push(
      <Row>
        <div>
          <b>
            <AxoLocal
              entity="CalendarEventFormbookingLinks"
              defaultValue={"Indlejring"}
            />
            :
          </b>
          <span>
            <input
              style={{ width: "75%" }}
              type="text"
              value={this.makeEmbedLink()}
            />
          </span>
        </div>
      </Row>
    );
    return bookingLinks;
  };

  onSelectColor = (color) => {
    var event = Object.assign({}, this.props.calendarEvent);
    event.color = color;
    this.onChangeCalendarEvent(event);
  };

  render() {
    var calendarEvent = this.props.calendarEvent;
    var showBookingLink = this.state.showBookingLink;
    var yesterday = moment().subtract(1, "day");
    return (
      <div className="text-m ">
        <Grid fluid>
          <Row className="text-m">
            <Col xs={12}>
              <Row className="text-m">
                <div>
                  <b>
                    {this.renderRequiredStar(calendarEvent.title)}
                    <AxoLocal
                      entity="CalendarEventBoxFormtitle"
                      defaultValue={"Titel"}
                    />
                    :&nbsp;
                  </b>
                  <InlineEdit
                    value={calendarEvent.title || "---"}
                    change={this.ontTitleChange}
                    editProps={{
                      disabled: calendarEvent.isDeadline,
                      style: { width: "100%" },
                    }}
                  />
                </div>
              </Row>
              <Row className="text-m">
                <div className="flexbox-standard">
                  <div>
                    <b>
                      {!calendarEvent.isDeadline ? (
                        <span>
                          <AxoLocal
                            entity="CalendarEventFormcalendarEventisStart"
                            defaultValue={"Start"}
                          />
                          :
                        </span>
                      ) : (
                        <span>
                          <AxoLocal
                            entity="EventBoxFormDeadline"
                            defaultValue={"Deadline"}
                          />
                          :
                        </span>
                      )}
                      &nbsp;
                    </b>
                  </div>
                  <AxoDateTime
                    id="start"
                    value={moment(calendarEvent.start)}
                    dateFormat={true}
                    timeFormat={true}
                    onChange={this.onStartDateChange}
                    inputProps={{
                      style: { border: "none", padding: "0px 0px 0px 0px" },
                    }}
                    isValidDate={(currentDate) => {
                      return currentDate.isAfter(yesterday);
                    }}
                  />
                </div>
              </Row>
              {!calendarEvent.isDeadline ? (
                <div>
                  <Row className="text-m">
                    <div className="flexbox-standard">
                      <div>
                        <b>
                          <AxoLocal
                            entity="CalendarEventFormcalendarEventend"
                            defaultValue={"Slut"}
                          />
                          :&nbsp;
                        </b>
                      </div>
                      <div>
                        <AxoDateTime
                          id="end"
                          value={moment(calendarEvent.end)}
                          dateFormat={true}
                          timeFormat={true}
                          onChange={this.onEndDateChange}
                          inputProps={{
                            style: {
                              border: "none",
                              padding: "0px 0px 0px 0px",
                            },
                            disabled: calendarEvent.allDay,
                          }}
                          isValidDate={(currentDate) => {
                            return currentDate.isAfter(
                              moment(calendarEvent.start)
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row className="text-m">
                    <AxoCheckbox
                      checked={calendarEvent.allDay}
                      onChange={this.onCheckAllDay}
                    />
                    <AxoLocal
                      entity="CalendarEventFormcalendarEventallday"
                      defaultValue={"Hele dagen"}
                    />
                  </Row>
                  <Row>
                    <AxoCheckbox
                      checked={calendarEvent.bookingTime}
                      onChange={this.onCheckBooking}
                    />
                    <AxoLocal
                      entity="CalendarEventFormcalendarEventbookingTime"
                      defaultValue={"Tid til booking for kunder"}
                    />
                    {calendarEvent.bookingTime ? (
                      <span>
                        &nbsp;&nbsp;
                        <Button
                          className="Lex-button-2"
                          onClick={this.toggleShowBookingLink}
                        >
                          {showBookingLink ? (
                            <AxoLocal
                              key="Hide"
                              entity="CalendarEventHidebookinglink"
                              defaultValue={"Skjul booking link"}
                            />
                          ) : (
                            <AxoLocal
                              key="Show"
                              entity="CalendarEventShowbookinglink"
                              defaultValue={"Vis booking link"}
                            />
                          )}
                        </Button>
                      </span>
                    ) : null}
                  </Row>
                  {this.renderBookingLinks(
                    calendarEvent.bookingTime && showBookingLink
                  )}
                  <Row style={{ paddingTop: "10px" }}>
                    {!calendarEvent.bookingTime ? (
                      <ColorPicker
                        activeColor={calendarEvent.color}
                        onSelect={this.onSelectColor}
                      />
                    ) : null}
                  </Row>
                  <Row className="text-m">
                    <div>
                      <b>
                        <AxoLocal
                          entity="Enhedspris6"
                          defaultValue={"Beskrivelse"}
                        />
                      </b>
                    </div>
                    <div>
                      <InlineTextArea
                        value={calendarEvent.description || "---"}
                        change={this.onDescChange}
                      />
                    </div>
                  </Row>
                  <Row className="text-m">
                    <div style={{ display: "inline-block" }}>
                      <b>
                        <AxoLocal
                          entity="CalendarEventFormcalendarEventrenderReminder"
                          defaultValue={"Påmindelser"}
                        />
                        <small>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <AxoLocal
                            entity="CalendarEventFormcalendarEventrenderReminderMax"
                            defaultValue={"(max.2)"}
                          />
                        </small>
                        &nbsp;
                      </b>
                      <div>{this.renderRemindersTable()}</div>
                    </div>
                  </Row>
                </div>
              ) : null}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
