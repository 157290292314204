let userTypeOverride = "";

export default {
  setUserTypeOverride(override) {
    userTypeOverride = override;
  },

  getUserType(userProfile) {
    if (!userProfile) {
      return "";
    }

    if (userProfile.userType === "Admin") {
      return userTypeOverride || userProfile.userType;
    }

    return userProfile.userType;
  },
};
