import React from "react";
// import ApiService from '../../services/DataAccess/ApiService'
// import DataStore from '../../services/DataAccess/DataStore'
import ClientSetupView from "./ClientSetupView";

import { ApiService, DataStore } from "../../services/AxoServices";

import { withData } from "../../utilities/LexUtilities";

class ClientSetupContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      paymentRequests: [],
    };

    this.name = "ClientSetupContainer";
  }

  componentDidMount() {
    ApiService.getPaymentRequestsForCurrentUser().then((paymentRequests) => {
      this.setState({ paymentRequests });
    });

    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.initializeFriendRequests();
    DataStore.initializeDocuments();
    DataStore.fetchUserSubscription();
    DataStore.fetchStorageInformation();
    DataStore.fetchSubscriptionPlans();
    DataStore.initializeUserGroup();
    DataStore.fetchGlobalSettings();
  }

  render() {
    let { userProfile, userSettings } = this.props;

    if (!userProfile.id || !userSettings.id) {
      return (
        <div className="text-center">
          <img
            alt=""
            src="/imgs/app/loading.gif"
            width="250px"
            className="img-circle"
          />
        </div>
      );
    }
    return <ClientSetupView {...this.props} {...this.state} />;
  }
}

export default withData(ClientSetupContainer, (store) => {
  let sortedRequests = store.friendRequests
    .sort((f1, f2) => {
      return new Date(f1.requestTime) - new Date(f2.requestTime);
    })
    .reverse();

  return {
    userProfile: store.userProfile,
    userSettings: store.userSettings,
    cardInformation: store.cardInformation,
    userSubscription: store.userSubscription,
    storageInformation: store.storageInformation,
    subscriptionPlans: store.subscriptionPlans.filter((sub) => {
      return sub.type === "EasyID";
    }),
    friendRequests: sortedRequests,
    documentMap: store.documentMap,
    globalSettings: store.globalSettings,
    locale: store.locale,
  };
});
