import React from "react";
import DataActions from "../services/DataAccess/DataActions";
import UserInfoService from "../services/UserInfoService";
import ModalService from "../services/ModalService";

import { Grid, Row, Col, Button } from "react-bootstrap";

import {
  Icon,
  AxoTimeline,
  AxoTimelineElement,
  AxoLocal,
} from "../utilities/LexUtilities";

export default class FriendRequestTimeline extends React.PureComponent {
  //Props
  //socialEvents
  onApproveRequest = (request) => {
    DataActions.acceptFriendRequest(request).then((response) => {
      if (response.status === 200) {
        DataActions.createNotification({
          entity: "NotificationAcceptedFriendRequest",
          variable: UserInfoService.getDisplayName(request.receiver),
          type: 1,
          receiverId: request.requestUser.id,
          success: true,
        });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="friendRequestTimelinerequestreceiver"
            defaultValue={"Handlingen kunne ikke udføres. Prøv igen senere."}
          />
        );
      }
    });
  };

  onRejectRequest = (request) => {
    var updatedRequest = Object.assign({}, request);
    updatedRequest.status = 1; //Rejected
    DataActions.updateFriendRequest(updatedRequest).then((response) => {
      if (response.status === 200) {
        DataActions.createNotification({
          entity: "NotificationRejectedFriendRequest",
          variable: UserInfoService.getDisplayName(request.receiver),
          type: 1,
          receiverId: request.requestUser.id,
          success: false,
        });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="friendRequestTimelinerequestreceiver"
            defaultValue={"Handlingen kunne ikke udføres. Prøv igen senere."}
          />
        );
      }
    });
  };

  getInviteBody = (request) => {
    if (request.privateConversation) {
      return (
        <span>
          <span>
            <AxoLocal
              entity="friendRequestTimelineprivateConversationrequest"
              defaultValue={"Har inviteret dig til en"}
            />
            &nbsp;
          </span>
          <span style={{ color: "red" }}>
            <span>
              <AxoLocal
                entity="friendRequestTimelinePrivateconference"
                defaultValue={"privat konference"}
              />
            </span>
            {!!request.privateConversation.name ? (
              <span>
                &nbsp;
                <AxoLocal
                  entity="friendRequestTimelineprivateConversationname"
                  defaultValue={"med navnet"}
                />
                &nbsp;
              </span>
            ) : null}
          </span>
          <b>{request.privateConversation.name}</b>
        </span>
      );
    } else {
      return (
        <AxoLocal
          entity="updatedRequestprivateConversation"
          defaultValue={"Har sendt en kontaktanmodning"}
        />
      );
    }
  };

  getBorderClass = (request) => {
    if (request.privateConversation) {
      return "border-red";
    }
    return "border-green";
  };

  renderFriendRequestFooter = (request) => {
    if (request.status === 0) {
      return (
        <div>
          <Button
            className="Lex-button-2"
            onClick={this.onApproveRequest.bind(this, request)}
          >
            <AxoLocal
              entity="friendRequestTimelineonApproveRequest"
              defaultValue={"Godkend"}
            />
          </Button>
          <Button
            className="Lex-button-2"
            onClick={this.onRejectRequest.bind(this, request)}
          >
            <AxoLocal
              entity="friendRequestTimelineonRejectRequest"
              defaultValue={"Afvis"}
            />
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <b>
            {request.status === 1 ? (
              <span>
                <Icon glyph="icon-fontello-cancel" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="FriendRequestRejected"
                  defaultValue={"Afvist"}
                />
              </span>
            ) : (
              <span>
                <Icon glyph="icon-fontello-check" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="DocumentGridViewapproved"
                  defaultValue={"Godkendt"}
                />
              </span>
            )}
          </b>
        </div>
      );
    }
  };

  renderFriendRequest = (request) => {
    return (
      <AxoTimelineElement
        key={"request-" + request.id}
        date={request.requestTime}
      >
        <img
          alt=""
          width="80px"
          height="auto"
          src={UserInfoService.getProfileImageSource(request.requestUser)}
          className={this.getBorderClass(request)}
        />
        <div>{UserInfoService.getDisplayName(request.requestUser)}</div>
        <div>
          {request.requestUser.country ? (
            <AxoLocal
              entity="CountryNames"
              data={{ countrySlug: request.requestUser.country }}
            />
          ) : (
            ""
          )}
        </div>
        <div>{this.getInviteBody(request)}</div>
        <br />
        <div className="text-center">
          {this.renderFriendRequestFooter(request)}
        </div>
      </AxoTimelineElement>
    );
  };

  getNoteAvatar = (note) => {
    if (note.success) {
      return (
        <img
          alt=""
          width={30}
          height={30}
          src="/svg/GL-icons_01-46.svg"
          className="border-cblue note-icon"
          style={{ borderStyle: "none", padding: 2 }}
        />
      );
    }
    return (
      <img
        alt=""
        width={30}
        height={30}
        src="/svg/GL-icons_01-44.svg"
        className="border-cblue note-icon"
        style={{ borderStyle: "none", padding: 2 }}
      />
    );
  };

  getNotificationBody = (note) => {
    if (note.entity) {
      if (note.entity === "NotificationAcceptedFriendRequest") {
        return (
          <span>
            {note.variable}{" "}
            <AxoLocal
              entity="NotificationAcceptedFriendRequest"
              defaultValue="has accepted your friend request"
            />
          </span>
        );
      }
      if (note.entity === "NotificationRejectedFriendRequest") {
        return (
          <span>
            {note.variable}{" "}
            <AxoLocal
              entity="NotificationRejectedFriendRequest"
              defaultValue="has rejected your friend request"
            />
          </span>
        );
      }
      if (note.entity === "ClientSetupViewContactRequestApproved") {
        return (
          <span>
            {note.variable}{" "}
            <AxoLocal
              entity="ClientSetupViewContactRequestApproved"
              defaultValue="has added you"
            />
          </span>
        );
      }
      if (note.entity === "ClientSetupViewContactRequestRejected") {
        return (
          <span>
            {note.variable}{" "}
            <AxoLocal
              entity="ClientSetupViewContactRequestRejected"
              defaultValue="has declined to add you"
            />
          </span>
        );
      }
      if (note.entity === "ClientSetupViewInvoicePaid") {
        return (
          <span>
            {note.variable}&nbsp;
            <AxoLocal
              entity="ClientSetupViewInvoicePaid"
              defaultValue="er blevet betalt."
            />
          </span>
        );
      }
    }
    return note.text;
  };

  renderNotification = (note) => {
    return (
      <AxoTimelineElement key={"note-" + note.id} date={note.creationTime}>
        {this.getNoteAvatar(note)}
        {this.getNotificationBody(note)}
      </AxoTimelineElement>
    );
  };

  renderSocialEvents = () => {
    var eventNodes = this.props.socialEvents.map((event) => {
      if (event.eventType === "friendRequest") {
        return this.renderFriendRequest(event);
      } else if (event.eventType === "notification") {
        return this.renderNotification(event);
      }
      return <span></span>;
    });
    if (eventNodes.length === 0) {
      eventNodes.push(
        <AxoTimelineElement key={0} date={new Date()}>
          <AxoLocal
            entity="friendRequestTimelineNocontactrequests"
            defaultValue={"Ingen kontaktanmodninger"}
          />
        </AxoTimelineElement>
      );
      eventNodes.push(
        <AxoTimelineElement key={1} date={new Date()}>
          <AxoLocal
            entity="friendRequestTimelineNosocialmessages"
            defaultValue={"Ingen sociale beskeder"}
          />
        </AxoTimelineElement>
      );
    }
    return eventNodes;
  };

  render() {
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <AxoTimeline singleColumn>
                {this.renderSocialEvents()}
              </AxoTimeline>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
