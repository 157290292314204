import React from "react";

import { PanelGroup } from "react-bootstrap";

const AxoPanelGroup = (props) => {
  let { className } = props;

  let allClasses = `Lex-Accordion Toggletitel ${
    !!className ? ` ${className}` : ""
  }`;
  // let allClasses = 'Lex-Accordion Toggletitel' + (!!className ? ( ' ' + className ) : '');

  return (
    <PanelGroup
      accordion
      {...props}
      className={allClasses}
      defaultActiveKey="0"
    >
      {props.children}
    </PanelGroup>
  );
};

export default AxoPanelGroup;
