import React, { useState } from "react";
import LexButton from "./LexButton";
import LoadingIcon from "./LoadingIcon";

let downloading, setDownloading;
function DownloadButton(props) {
  [downloading, setDownloading] = useState(false);

  const onClick = () => {
    setDownloading(true);

    setTimeout(() => setDownloading(false), props.loadTime || 3000);
  };

  let { loadTime, ...buttonProps } = props;

  return (
    <>
      <LexButton
        {...buttonProps}
        onClick={onClick}
        disabled={downloading || props.disabled}
      >
        {props.children}
        <LoadingIcon show={downloading} />
      </LexButton>
    </>
  );
}

export default DownloadButton;
