import React from "react";
import { Table, Column, Cell } from "fixed-data-table-2";
import Select from "react-select";
import { Row, Grid, FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { DataActions } from "../../services/AxoServices";

import {
  Icon,
  AxoCheckbox,
  TableBase,
  SortHeaderCell,
  DataListWrapper,
  ctx,
  AxoLocal,
  Dimensions,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  minUsers: {
    width: 100,
    shown: true,
  },
  maxUsers: {
    width: 125,
    shown: true,
  },
  monthlyPrice: {
    width: 200,
    shown: true,
  },
  currency: {
    width: 125,
    shown: true,
  },
  yearDiscount: {
    width: 100,
    shown: true,
  },
  actions: {
    width: 90,
    shown: true,
  },
};

class SubscriptionFormulasTable extends TableBase {
  constructor(props) {
    super(props);
    this.name = "SubscriptionFormulasTable";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = this.generateDefaultSortIndexes(
      props.subscriptionFormulas
    );
    let tableEntries = this.copyItems(props.subscriptionFormulas);
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      showCurrency: "",
    };
  }

  copyItems = (entries) => {
    return entries.map((c) => Object.assign({}, c));
  };

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.copyItems(nextProps.subscriptionFormulas);
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  onSearch = (event) => {
    this.setState(
      {
        searchText: event.target.value,
      },
      () => {
        this.updateFilteredEntries();
      }
    );
  };

  updateFilteredEntries = () => {
    let { tableEntries, searchText } = this.state;

    var entries = this.getFilteredEntries(tableEntries, searchText);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText && !this.state.showCurrency) {
      return entries;
    }
    var filteredEntries = entries;
    if (this.state.showCurrency) {
      filteredEntries = filteredEntries.filter(
        (entry) =>
          entry.currency.toLowerCase() === this.state.showCurrency.toLowerCase()
      );
    }
    return filteredEntries.filter((c) => {
      return (c.id + " " + c.minUser)
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  };

  onChangeFormulaProperty = (entryId, propertyName, input) => {
    var entry = Object.assign(
      {},
      this.state.sortedDataList._data.find((c) => c.id === entryId)
    );
    entry[propertyName] = input.value;
    DataActions.updateSubscriptionFormula(entry);
  };

  onCurrencySelect = (selectedCurrency) => {
    if (!selectedCurrency) {
      return;
    }
    this.setState({ showCurrency: selectedCurrency.value }, () => {
      this.updateFilteredEntries();
    });
  };

  renderCurrencySelect = (userProfile) => {
    let currency = this.state.showCurrency || "";

    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "150px",
          zIndex: 1000,
        }}
      >
        <Select
          name="form-field-name"
          menuPlacement="auto"
          value={{
            value: currency.toUpperCase(),
            label: currency.toUpperCase(),
          }}
          onChange={this.onCurrencySelect}
          noOptionsMessage={() => ""}
          options={[
            { value: "", label: ctx.getSync("AdminTabView24") }, //<AxoLocal entity='AdminTabView24' defaultValue='Valuta'/> },
            { value: "DKK", label: "DKK" },
            { value: "USD", label: "USD" },
            { value: "GBP", label: "GBP" },
            { value: "EUR", label: "EUR" },
            { value: "CNY", label: "CNY" },
            { value: "SEK", label: "SEK" },
            { value: "NKK", label: "NKK" },
            { value: "ISK", label: "ISK" },
          ]}
        />
      </div>
    );
  };

  onDeleteFormula(formulaId) {
    DataActions.deleteSubscriptionFormula(formulaId);
  }

  render() {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth, globalSettings } = this.props;
    var tableWidth = containerWidth - 30;
    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <div
                  className="flexbox-layout"
                  style={{ paddingLeft: "25px", paddingRight: "25px" }}
                >
                  <h4>
                    <Icon glyph="icon-fontello-align-justify" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="AdminTabViewtable2a"
                      defaultValue=" Formler"
                    />
                    ({sortedDataList._data.length})
                  </h4>
                  <div>{this.renderCurrencySelect()}</div>
                  <div style={{ maxWidth: "250px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-search" />
                        </InputGroup.Addon>
                        {/* Søg...  */}
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <div>
                    <span>
                      <AxoLocal
                        entity="AdminTabViewtable2a"
                        defaultValue="Skjul formler"
                      />
                      :&nbsp;
                    </span>
                    <AxoCheckbox
                      checked={
                        globalSettings.hideSubscriptionFormulas === "true"
                      }
                      onChange={(event) =>
                        DataActions.createOrUpdateGlobalSetting({
                          key: "hideSubscriptionFormulas",
                          value: event.target.checked,
                        })
                      }
                    />
                  </div>
                </div>
              </Row>
            </Grid>
          </div>
          <div>
            <div style={{ paddingTop: "0px", paddingLeft: "20px" }}>
              <Table
                rowHeight={40}
                rowsCount={sortedDataList.getSize()}
                width={tableWidth}
                height={containerHeight}
                isColumnResizing={false}
                onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                headerHeight={40}
              >
                <Column
                  columnKey="minUsers"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.minUsers.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.minUsers}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabViewtable1"
                        defaultValue="Min brugere"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var entry = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={entry.id} {...props}>
                        <div>{entry.minUsers}</div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.minUsers.width}
                  isResizable={true}
                />
                <Column
                  columnKey="maxUsers"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.maxUsers.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.maxUsers}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabViewtable2"
                        defaultValue="Maks brugere"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var entry = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={entry.id} {...props}>
                        <div>
                          <div>{entry.maxUsers}</div>
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.maxUsers.width}
                  isResizable={true}
                />
                <Column
                  columnKey="monthlyPrice"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.monthlyPrice.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.monthlyPrice}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabViewtable3"
                        defaultValue="Månedspris"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var entry = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={entry.id} {...props}>
                        <div>
                          {entry.monthlyPrice / 100} {entry.currency}
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.monthlyPrice.width}
                  isResizable={true}
                />
                <Column
                  columnKey="yearDiscount"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.yearDiscount.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.yearDiscount}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabViewtable4"
                        defaultValue="Årsrabat"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var formula = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={formula.id} {...props}>
                        <div>{formula.yearDiscount}</div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.yearDiscount.width}
                  isResizable={true}
                />
                <Column
                  columnKey="actions"
                  header={<Cell></Cell>}
                  cell={(props) => {
                    var formula = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={formula.id} {...props}>
                        <Icon
                          role="button"
                          onClick={this.onDeleteFormula.bind(this, formula.id)}
                          glyph="icon-fontello-trash-1"
                        />
                      </Cell>
                    );
                  }}
                  width={columnDefs.actions.width}
                  isResizable={true}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 240;
  },
})(SubscriptionFormulasTable);
