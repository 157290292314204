import React from "react";
import { groupBy } from "lodash";
import { AxoLocal } from "../../utilities/LexUtilities";
import { Table } from "react-bootstrap";

//Årsregnskab
export default class AnnualView extends React.PureComponent {
  generateAccountTypeRows = (accountType, annualSums, postings) => {
    var rows = [];
    rows.push(
      <tr className="Axoaccountsevenlist">
        <th>{accountType.name}</th>
        {annualSums.map((a) => {
          return <td>{a[accountType.name + accountType.id] || 0}</td>;
        })}
      </tr>
    );
    return rows;
  };

  render() {
    let { postings, accounts, accountTypes } = this.props;

    let years = groupBy(postings, (p) => {
      return new Date(p.date).getFullYear();
    });

    //Add earlier years, if there are no postings
    let yearNames = Object.keys(years).sort();
    let yearNo = yearNames.length;
    if (yearNo < 5) {
      let yearsToAdd = 5 - yearNo;
      let earliestYear = new Date().getFullYear();
      if (yearNo > 0) {
        earliestYear = parseInt(yearNames[0], 10);
      }

      for (let i = 1; i < yearsToAdd + 1; i++) {
        years[earliestYear - i] = [];
      }
    }

    let annualSums = [];

    for (var key in years) {
      let postingsForYear = years[key];

      annualSums.push({
        name: key,
        totalSum: 0,
      });

      accountTypes.forEach((type) => {
        let accountsForType = accounts.filter(
          (a) => a.postingAccountTypeId === type.id
        );
        let postingsForType = postingsForYear.filter(
          (p) => !!accountsForType.find((a) => a.id === p.postingAccountId)
        );

        let income = postingsForType.reduce((acc, posting) => {
          return acc + posting.income;
        }, 0);
        let expenses = postingsForType.reduce((acc, posting) => {
          return acc + posting.expenses;
        }, 0);

        let net = income - expenses;
        let annualSum = annualSums[annualSums.length - 1];
        annualSum[type.name + type.id] = net;
        annualSum.totalSum += net;
      });
    }

    return (
      <div className="AccountingpaddingTB">
        <div
          style={{ height: "40px", lineHeight: "40px", paddingLeft: "10px" }}
        >
          <h3>
            <AxoLocal
              entity="AccountingTabViewEntity57"
              defaultValue={"Årsopgørelse"}
            />
          </h3>
        </div>
        <Table responsive>
          <tbody>
            <tr className="Axoaccountsbannebg">
              <th></th>
              {annualSums.map((a) => {
                return <td>{a.name}</td>;
              })}
            </tr>
            {accountTypes.map((t) =>
              this.generateAccountTypeRows(t, annualSums, postings)
            )}
            <tr className="doubleboder">
              <th>
                <AxoLocal
                  entity="AccountingTabViewEntity43"
                  defaultValue={"Årsresultat"}
                />
              </th>
              {annualSums.map((a) => {
                return <td>{a.totalSum}</td>;
              })}
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
