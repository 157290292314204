import React from "react";

import { Button } from "react-bootstrap";

class LexButton2 extends React.PureComponent {
  render() {
    var { className } = this.props;
    var allClasses = "Lex-button-2" + (!!className ? " " + className : "");

    return (
      <Button {...this.props} className={allClasses}>
        {this.props.children}
      </Button>
    );
  }
}

export default LexButton2;
