import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import PresentationLink from "../Common/PresentationLink";
import moment from "moment";

import { Icon, AxoCheckbox, AxoLocal } from "../../utilities/LexUtilities";

import {
  Row,
  Col,
  Grid,
  Button,
  InputGroup,
  FormControl,
  FormGroup,
  ListGroup,
} from "react-bootstrap";

export default class AdminList extends React.PureComponent {
  getShownPresentations = () => {
    return this.props.presentations.slice(0, this.props.shown);
  };

  morePresentationsAvailable = () => {
    return this.props.presentations.length > this.props.shown;
  };

  renderPresentationList = () => {
    var presentations = this.getShownPresentations();
    return (
      <div>
        <ListGroup>
          {presentations.map(this.renderPresentationListItem)}
        </ListGroup>
        {this.props.onShowMore && this.morePresentationsAvailable() ? (
          <div className="text-center" style={{ paddingBottom: "50px" }}>
            <Button
              onClick={this.props.onShowMore}
              className="Lex-button-1xlink "
              style={{ width: "75%" }}
            >
              <AxoLocal entity="ViewShowmoreinfo" defaultValue={"Vis flere"} />
              &nbsp;
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  renderPresentationListLink = (presentation) => {
    if (!this.props.includeLink) {
      return null;
    }
    var link =
      window.location.protocol +
      "//" +
      window.location.host +
      "/presentation/postList/" +
      this.props.userProfile.userId;
    return (
      <div>
        {/* <h4 className='text-center'>
          <AxoLocal entity='paymentDate4a' defaultValue='Axodrev'/> 
        </h4> */}
        <PresentationLink url={link} includeLinkBox />
      </div>
    );
  };

  onTogglePublic = (presentationId, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onTogglePublic(presentationId);
  };

  onClickDelete = (presentationId, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.deletingPresentation === presentationId) {
      return;
    }
    this.props.onDeletePresentation(presentationId);
  };

  renderPresentationListItem = (presentation) => {
    let { selectPresentation, includeControls, hideCount } = this.props;

    let attachmentItem = presentation.items.find(
      (i) => i.type === 2 && !!i.attachment
    ); //Image
    let videoItem = presentation.items.find(
      (i) => i.type === 3 && !!i.attachment
    ); //Video
    let titleItem = presentation.items.find((i) => i.type < 2); //Title or description
    let deleting = this.props.deletingPresentation === presentation.id;

    return (
      <div
        className="list-group-item"
        role="button"
        key={presentation.id}
        onClick={selectPresentation.bind(this, presentation.id)}
        style={{ padding: "0px" }}
      >
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding" style={{ padding: "2px" }}>
              <div className="flexbox-layout" style={{ maxWidth: "100%" }}>
                {includeControls ? (
                  <div>
                    <div
                      onClick={this.onTogglePublic.bind(this, presentation.id)}
                      style={{ paddingLeft: "10px" }}
                    >
                      <AxoCheckbox readOnly checked={presentation.public} />
                      &nbsp;{" "}
                      <span>
                        {" "}
                        <AxoLocal
                          entity="presentationMarketing1"
                          defaultValue="Offentlig"
                        />{" "}
                        &nbsp;
                        <Icon glyph="icon-fontello-globe-4" />
                      </span>
                    </div>
                  </div>
                ) : null}
                {/* { this.props.includeControls ? (
                  <div onClick={this.onTogglePublic.bind(this, presentation.id)} style={{ paddingLeft:'10px'}}>
                    <AxoCheckbox
                      readOnly checked={presentation.legal}
                    />
                    &nbsp; <span> Legal  &nbsp;<Icon glyph="icon-fontello-globe-4"/>
                    </span> 
                  </div>
                ) : null } */}
                {includeControls ? (
                  <div className="text-right" style={{ maxWidth: "100%" }}>
                    <Button
                      onClick={this.onClickDelete.bind(this, presentation.id)}
                      className="Lex-button-1xlink "
                    >
                      {deleting ? (
                        <img
                          alt=""
                          src="/imgs/app/loading.gif"
                          className="img-circle"
                          width="20"
                          height="20"
                        />
                      ) : (
                        <span>
                          <Icon
                            className="AxoNavigationheightIcon1"
                            glyph="icon-fontello-trash-1"
                          />
                          &nbsp;
                        </span>
                      )}
                    </Button>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="Listimgboxheight" style={{ overflow: "hidden" }}>
            <Col xs={5} sm={3} className="nopadding" style={{ padding: "2px" }}>
              {attachmentItem && !!attachmentItem.attachment ? (
                <img
                  alt=""
                  className="Listimgbox"
                  src={ApiService.getFileInlinePath(
                    attachmentItem.attachment.id
                  )}
                />
              ) : null}
              {!attachmentItem && videoItem && !!videoItem.attachment ? (
                <video
                  className="Listimgbox"
                  src={ApiService.getFileInlinePath(videoItem.attachment.id)}
                  preload="metadata"
                />
              ) : null}
            </Col>
            <Col
              xs={7}
              sm={9}
              className="nopadding"
              style={{ padding: "2px", height: "100%" }}
            >
              {titleItem ? (
                <div className="text-left">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: titleItem.title || titleItem.description || null,
                    }}
                  ></div>
                  {/* <AxoFrame
                    bottomPadding={35}
                    content={titleItem.title || titleItem.description || null }
                  /> */}
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <div className="text-right" style={{ paddingRight: "8px" }}>
              {!!presentation.authorName ? (
                <span>{presentation.authorName} - </span>
              ) : null}
              {moment(presentation.creationDate).format("L")}
              {!hideCount ? (
                <span>
                  &nbsp; - &nbsp;
                  <AxoLocal
                    entity="presentationMarketing20"
                    defaultValue="vist"
                  />
                  : {presentation.viewCount}
                </span>
              ) : null}
            </div>
          </Row>
        </Grid>
      </div>
    );
  };

  renderSearchBox() {
    if (!this.props.onSearch) {
      return null;
    }
    return (
      <div style={{ maxWidth: "600px", margin: "auto", paddingTop: "10px" }}>
        <FormGroup controlId="inputWithIcon">
          <InputGroup>
            <InputGroup.Addon>
              <Icon glyph="icon-fontello-search" />
            </InputGroup.Addon>
            {/* Søg...  */}
            <AxoLocal
              componentClass={FormControl}
              type="text"
              value={this.props.query}
              onChange={this.props.onSearch}
              componentAttribute="placeholder"
              entity="ContactTableViewplaceholderonSearch"
            />
            <FormControl.Feedback>
              <Icon glyph="icon-fontello-search" />
            </FormControl.Feedback>
          </InputGroup>
        </FormGroup>
      </div>
    );
  }

  render() {
    return (
      <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Grid fluid>
          <Row>
            <Col
              md={8}
              mdOffset={2}
              sm={10}
              smOffset={1}
              xs={12}
              className="nopadding"
              style={{ paddingLeft: "5px", paddingRight: "5px" }}
            >
              {this.renderPresentationListLink()}
              {this.renderSearchBox()}
              {this.renderPresentationList()}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
