import React from "react";
import { withRouter } from "react-router-dom";
import { Table, Column, Cell } from "fixed-data-table-2";
import ContactGridView from "./ContactGridView";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";
import moment from "moment";
import * as $ from "jquery";
// import Select from'react-select'
import { debounce } from "lodash";
import { CSVLink } from "react-csv";
import checkdigit from "checkdigit";

import {
  ApiService,
  DataStore,
  DataActions,
  LocalizationService,
  ModalService,
  RoutingService,
  AuthorizationService,
} from "../../services/AxoServices";

import {
  Icon,
  LexButton,
  AxoCheckbox,
  PaginationControls,
  LabelSearchBox,
  SharingDropDown,
  TableBase,
  Dimensions,
  InlineEdit,
  SortHeaderCell,
  DataListWrapper,
  InlineCountrySelect,
  AxoLocal,
  Flexbox,
  FlexElement,
  AxoDateTime,
  AsyncButton,
  LoadingIcon,
  getText,
  ctx,
  AxoDateTimeEdit,
  TextFilterModal,
  TextFilterTypes,
  TextFilter,
  TextModal,
  NumberFilter,
  NumberFilterTypes,
  NumberFilterModal,
  DateFilter,
  DateFilterTypes,
  DateFilterModal,
  FilterTypes,
  FileViewerModal,
} from "../../utilities/LexUtilities";

import {
  FormControl,
  Button,
  FormGroup,
  InputGroup,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";

const defaultColumnDefs = {
  rowNumber: {
    width: 50,
    shown: false,
  },
  select: {
    width: 50,
    shown: true,
  },
  newEntries: {
    width: 100,
    shown: true,
  },
  firstName: {
    width: 130,
    shown: true,
  },
  lastName: {
    width: 135,
    shown: true,
  },
  clientNumber: {
    width: 135,
    shown: true,
  },
  clientUserName: {
    width: 100,
    shown: true,
  },
  // financeAccountPlan: {
  //   width: 250,
  //   shown: false
  // },
  companyName: {
    width: 187,
    shown: true,
  },
  description: {
    width: 150,
    shown: false,
  },
  identityCode: {
    width: 110,
    shown: true,
  },
  clientType: {
    width: 100,
    shown: false,
  },
  address: {
    width: 290,
    shown: true,
  },
  postalCode: {
    width: 120,
    shown: true,
  },
  city: {
    width: 125,
    shown: true,
  },
  country: {
    width: 135,
    shown: true,
  },
  phone: {
    width: 110,
    shown: true,
  },
  mobile: {
    width: 100,
    shown: true,
  },
  eMail: {
    width: 145,
    shown: true,
  },
  birthDate: {
    width: 165,
    shown: false,
  },
  labelCol: {
    width: 130,
    shown: true,
  },
  creationDate: {
    width: 165,
    shown: true,
  },
  customFieldOne: {
    width: 150,
    shown: false,
  },
  actions: {
    width: 50,
    shown: true,
  },

  identityAttachment: {
    width: 120,
    shown: true,
  },
  contractAttachment: {
    width: 120,
    shown: true,
  },
  companyAttachment: {
    width: 120,
    shown: true,
  },
};

class ContactTableView extends TableBase {
  constructor(props) {
    super(props);
    this.name = "ContactTableComponent";

    let filteredEntries = this.getFilteredEntries(props.contacts);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(filteredEntries);
    let userType = AuthorizationService.getUserType(props.userProfile);
    this.defaultColumnDefs = defaultColumnDefs;
    if (!!props.userSettings && !!props.userSettings.customContactFields) {
      props.userSettings.customContactFields.forEach((fieldName) => {
        this.defaultColumnDefs[this.getCustomFieldFullName(fieldName)] = {
          width: 125,
          shown: true,
        };
      });
    }

    this.state = {
      tableEntries: props.contacts,
      selectedEntries: new Set(),
      newLabelName: "",
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        labelCol: 110,
      },
      maxColumnWidth: 750,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        filteredEntries
      ),
      colSortDirs: {},

      searchText: "",
      selectedFromDate: null,
      selectedToDate: null,

      locale: "",
      deletingContactId: 0,
      readingCSV: false,
      showCSVSuccess: false,
      showCSVError: false,
      showCreatedLabelIcon: false,
      selectedLabel: { id: null, clientIds: null },
      showDisabledWarning: false,
      importingCSV: false,
      showControls: false,
      deletingSelected: false,

      cachedCreationDates: {}, //Ensures that date picker is updated immediately
      cachedBirthDates: {}, //Ensures that date picker is updated immediately,
      csv: [],
      columnFilters: this.retrieveColumnFilters(), //Map from column names to filters,
    };

    this.toggleColumns = [
      "rowNumber",
      "firstName",
      "lastName",
      "clientNumber",
      "clientUserName",
      "companyName",
      "description",
      "identityCode",
      "address",
      "postalCode",
      "city",
      "country",
      "phone",
      "mobile",
      "eMail",
      "birthDate",
      "labelCol",
      "creationDate",
    ];

    if (userType === "Society") {
      this.toggleColumns = this.toggleColumns.filter(
        (c) =>
          c !== "clientUserName" && c !== "companyName" && c !== "identityCode"
      );
      this.toggleColumns.push("customFieldOne");
    }
    if (userType === "Accountant") {
      this.toggleColumns.push("customFieldOne");
      this.toggleColumns.push("newEntries");
    }
    if (userType === "AccountingClient") {
      this.toggleColumns = this.toggleColumns.filter(
        (c) => c !== "clientUserName"
      );
    }

    this.textFilterModal = React.createRef();
    this.dateFilterModal = React.createRef();
    this.numberFilterModal = React.createRef();
    this.textModal = React.createRef();
    this.fileModal = React.createRef();
    this.uploadAttachment = React.createRef();

    this.selectedEntryId = 0;
    this.selectedAttachmentName = "";

    if (userType === "Accountant" || userType === "Admin") {
      this.toggleColumns.push("identityAttachment");
      this.toggleColumns.push("contractAttachment");
      this.toggleColumns.push("companyAttachment");
    }
  }

  retrieveColumnFilters() {
    if (typeof Storage === "undefined") {
      return {};
    }

    let columnFilters =
      JSON.parse(localStorage.getItem("filters-" + this.name)) || {};

    return columnFilters;
  }

  saveColumnFilters(columnFilters) {
    this.setState({ columnFilters });

    if (typeof Storage === "undefined") {
      return;
    }

    localStorage.setItem("filters-" + this.name, JSON.stringify(columnFilters));
  }

  componentDidMount() {
    LocalizationService.subscribeToLocale(this.name, (locale) => {
      this.setState({ locale });
    });

    this.updateShownEntries({ debounced: false });
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale(this.name);

    //Reset contact filters when leaving the page
    this.props.resetContactQuery({});
  }

  editTextFilter = (propertyName) => {
    let {
      tableEntries,
      columnFilters,
      searchText,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
    } = this.state;

    let query = {
      searchText,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
      columnFilters,
      skipFilterName: propertyName,
    };
    let availableEntries = this.getFilteredEntries(tableEntries, query);

    let extractProperty = (entry) => {
      return entry[propertyName] || "";
    };

    if (propertyName.includes("custom-")) {
      let propValues = propertyName.split("-");
      if (propValues.length > 1) {
        extractProperty = this.makeExtractFunction(propValues);
      }
    }

    let textOptions = new Set(
      availableEntries.map((e) => extractProperty(e) || "")
    );
    let arrayOptions = [...textOptions]
      .map((t) => ({ id: t, name: t }))
      .sort((l, r) => (l.name || "").localeCompare(r.name || ""));

    let existingFilter = columnFilters[propertyName];
    let textFilter;
    if (!!existingFilter) {
      textFilter = new TextFilter(existingFilter);
      textFilter.options = arrayOptions;
    } else {
      textFilter = new TextFilter({
        options: arrayOptions,
        selectedOptions: [],
      });
    }

    this.textFilterModal.current.open(textFilter, (textFilter) => {
      this.updateColumnFilter(propertyName, textFilter);
    });
  };

  editDateFilter = (propertyName) => {
    let { tableEntries, columnFilters } = this.state;

    let availableEntries = this.applyColumnFilters(
      tableEntries,
      columnFilters,
      propertyName
    );
    let options = new Set(
      availableEntries.map((e) => moment(e[propertyName]).format("L"))
    );

    let arrayOptions = [...options]
      .map((t) => ({ id: t, name: t }))
      .sort((l, r) => l.name - r.name);

    let existingFilter = columnFilters[propertyName];
    let filter;
    if (!!existingFilter) {
      filter = new DateFilter(existingFilter);
      filter.options = arrayOptions;
    } else {
      filter = new DateFilter({
        options: arrayOptions,
        selectedOptions: [],
      });
    }

    this.dateFilterModal.current.open(filter, (filter) => {
      this.updateColumnFilter(propertyName, filter);
    });
  };

  editNumberFilter = (propertyName) => {
    let { tableEntries, columnFilters } = this.state;

    let availableEntries = this.applyColumnFilters(
      tableEntries,
      columnFilters,
      propertyName
    );
    let options = new Set(availableEntries.map((e) => e[propertyName]));
    let arrayOptions = [...options]
      .map((t) => ({ id: t, name: t }))
      .sort((l, r) => l.name - r.name);

    let existingFilter = columnFilters[propertyName];
    let numberFilter;
    if (!!existingFilter) {
      numberFilter = new NumberFilter(existingFilter);
      numberFilter.options = arrayOptions;
    } else {
      numberFilter = new NumberFilter({
        options: arrayOptions,
        selectedOptions: [],
      });
    }

    this.numberFilterModal.current.open(numberFilter, (numberFilter) => {
      this.updateColumnFilter(propertyName, numberFilter);
    });
  };

  updateColumnFilter = (propertyName, filter) => {
    let { columnFilters } = this.state;

    delete filter.options; //Options should not be saved, as they are recalculated when opening the modal.

    let newColumnFilters = {
      ...columnFilters,
      [propertyName]: filter,
    };

    this.saveColumnFilters(newColumnFilters);
    // this.setState({ columnFilters: newColumnFilters });

    this.updateEntryFilter(newColumnFilters);
  };

  updateEntryFilter = (columnFilters) => {
    let {
      tableEntries,
      searchText,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
    } = this.state;
    let query = {
      searchText,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
      columnFilters,
    };

    let entries = this.getFilteredEntries(tableEntries, query);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);

    this.setState({
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
      selectedEntries: new Set(),
    });
  };

  applyColumnFilters = (entries, columnFilters, skipFilterName = "") => {
    let skipFilter = columnFilters[skipFilterName]; //Apply only other filters, to determine available options for this filter
    let filteredEntries = entries;
    if (!!columnFilters.firstName && columnFilters.firstName !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "firstName"
      );
    }
    if (!!columnFilters.newEntries && columnFilters.newEntries !== skipFilter) {
      filteredEntries = this.applyNumberFilter(
        filteredEntries,
        columnFilters,
        "newEntries"
      );
    }
    if (!!columnFilters.lastName && columnFilters.lastName !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "lastName"
      );
    }
    if (
      !!columnFilters.clientNumber &&
      columnFilters.clientNumber !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "clientNumber"
      );
    }
    if (
      !!columnFilters.customFieldOne &&
      columnFilters.customFieldOne !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "customFieldOne"
      );
    }
    if (
      !!columnFilters.clientUserName &&
      columnFilters.clientUserName !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "clientUserName"
      );
    }
    if (
      !!columnFilters.companyName &&
      columnFilters.companyName !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "companyName"
      );
    }
    if (
      !!columnFilters.description &&
      columnFilters.description !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "description"
      );
    }
    if (
      !!columnFilters.identityCode &&
      columnFilters.identityCode !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "identityCode"
      );
    }
    if (!!columnFilters.address && columnFilters.address !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "address"
      );
    }
    if (!!columnFilters.postalCode && columnFilters.postalCode !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "postalCode"
      );
    }
    if (!!columnFilters.city && columnFilters.city !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "city"
      );
    }
    if (!!columnFilters.country && columnFilters.country !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "country"
      );
    }
    if (!!columnFilters.phone && columnFilters.phone !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "phone"
      );
    }
    if (!!columnFilters.mobile && columnFilters.mobile !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "mobile"
      );
    }
    if (!!columnFilters.eMail && columnFilters.eMail !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "eMail"
      );
    }
    if (
      !!columnFilters.creationDate &&
      columnFilters.creationDate !== skipFilter
    ) {
      filteredEntries = this.applyDateFilter(
        filteredEntries,
        columnFilters,
        "creationDate"
      );
    }
    if (!!columnFilters.birthDate && columnFilters.birthDate !== skipFilter) {
      filteredEntries = this.applyDateFilter(
        filteredEntries,
        columnFilters,
        "birthDate"
      );
    }

    let { userSettings } = this.props;
    ((userSettings || {}).customContactFields || []).forEach((fieldName) => {
      let fullFieldName = this.getCustomFieldFullName(fieldName);
      if (
        !!columnFilters[fullFieldName] &&
        columnFilters[fullFieldName] !== skipFilter
      ) {
        filteredEntries = this.applyTextFilter(
          filteredEntries,
          columnFilters,
          fullFieldName
        );
      }
    });

    return filteredEntries;
  };

  makeExtractFunction = (propValues) => {
    let propName = propValues.slice(1).join("-"); //Everything after the first '-'
    return (entry) => {
      return (entry.customFieldValues || {})[propName] || "";
    };
  };

  applyTextFilter = (entries, columnFilters, propertyName) => {
    let filteredEntries = entries;

    let extractProperty = (entry) => {
      return entry[propertyName] || "";
    };

    if (propertyName.includes("custom-")) {
      let propValues = propertyName.split("-");
      if (propValues.length > 1) {
        extractProperty = this.makeExtractFunction(propValues);
      }
    }

    let extractPropertyLowerCase = (entry) =>
      extractProperty(entry).toLowerCase();

    if (columnFilters[propertyName].selectedOptions.length > 0) {
      let selectedOptionSet = new Set(
        columnFilters[propertyName].selectedOptions
      );

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(extractProperty(e))
      );
    }

    if (!!columnFilters[propertyName].filterString) {
      let search = columnFilters[propertyName].filterString.toLowerCase();
      switch (columnFilters[propertyName].filterStringType) {
        case TextFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => extractPropertyLowerCase(f) === search
          );
          break;
        case TextFilterTypes.NOTEQUEALS:
          filteredEntries = filteredEntries.filter(
            (f) => extractPropertyLowerCase(f) !== search
          );
          break;
        case TextFilterTypes.STARTSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            extractPropertyLowerCase(f).startsWith(search)
          );
          break;
        case TextFilterTypes.ENDSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            extractPropertyLowerCase(f).endsWith(search)
          );
          break;
        case TextFilterTypes.CONTAINS:
          filteredEntries = filteredEntries.filter((f) =>
            extractPropertyLowerCase(f).includes(search)
          );
          break;
        case TextFilterTypes.NOTCONTAINS:
          filteredEntries = filteredEntries.filter(
            (f) => !extractPropertyLowerCase(f).includes(search)
          );
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  applyNumberFilter = (entries, columnFilters, propertyName) => {
    let filteredEntries = entries;
    if (columnFilters[propertyName].selectedOptions.length > 0) {
      let selectedOptionSet = new Set(
        columnFilters[propertyName].selectedOptions
      );

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(e[propertyName])
      );
    }
    if (
      columnFilters[propertyName].filterNumberType !== NumberFilterTypes.NONE
    ) {
      let search = columnFilters[propertyName].filterNumber;
      switch (columnFilters[propertyName].filterNumberType) {
        case NumberFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] === search
          );
          break;
        case NumberFilterTypes.NOTEQUEALS:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] !== search
          );
          break;
        case NumberFilterTypes.GREATERTHAN:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] > search
          );
          break;
        case NumberFilterTypes.GREATERTHANEQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] >= search
          );
          break;
        case NumberFilterTypes.LESSTHAN:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] < search
          );
          break;
        case NumberFilterTypes.LESSTHANEQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] <= search
          );
          break;
        case NumberFilterTypes.BETWEEN:
          // filteredEntries = filteredEntries.filter(f => !(f[propertyName] || '').toLowerCase().includes(search))
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  applyDateFilter = (entries, columnFilters, propertyName) => {
    let filteredEntries = entries;
    if (columnFilters[propertyName].selectedOptions.length > 0) {
      let selectedOptionSet = new Set(
        columnFilters[propertyName].selectedOptions
      );

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(moment.utc(e[propertyName]).format("L"))
      );
    }
    if (columnFilters[propertyName].filterMonth > -1) {
      filteredEntries = filteredEntries.filter(
        (e) =>
          moment.utc(e.creationDate).month() ===
          columnFilters[propertyName].filterMonth
      );
    }
    if (columnFilters[propertyName].filterMonthArray.length > 0) {
      let filterMonthMap = new Set(
        columnFilters[propertyName].filterMonthArray
      );
      filteredEntries = filteredEntries.filter((e) =>
        filterMonthMap.has(moment.utc(e.creationDate).month())
      );
    }
    if (columnFilters[propertyName].filterDateType !== DateFilterTypes.NONE) {
      let search = moment.utc(columnFilters[propertyName].filterDate);
      switch (columnFilters[propertyName].filterDateType) {
        case DateFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => moment.utc(f[propertyName]).valueOf() === search.valueOf()
          );
          break;
        case DateFilterTypes.BEFORE:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isBefore(search)
          );
          break;
        case DateFilterTypes.BEFOREORSAME:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isSameOrBefore(search)
          );
          break;
        case DateFilterTypes.AFTER:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isAfter(search)
          );
          break;
        case DateFilterTypes.AFTERORSAME:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isSameOrAfter(search)
          );
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  clearSelectedEntries = () => {
    this.setState({ selectedEntries: new Set() });
  };

  addSelectedEntriesToLabel = async (label) => {
    if (!label.id) {
      this.onTypeChange(label);
      return;
    }

    let { refetchContacts } = this.props;
    await DataActions.addLabelToClientArray(
      Array.from(this.state.selectedEntries),
      label.id
    );
    this.clearSelectedEntries();

    refetchContacts();
  };

  moveSelectedEntriesToLabel = (label) => {
    if (!label.id) {
      this.onTypeChange(label);
      return;
    }

    ModalService.openConfirmModal(
      `De valgte personer vil blive fjernet fra alle andre
grupper og flyttet til gruppen "${label.name}". Hvis personerne skal være i flere grupper, skal du
i stedet anvende "Tilføj til Gruppe".`,
      async (value) => {
        if (!value) {
          return;
        }

        let entryArray = Array.from(this.state.selectedEntries);
        let fullEntries = this.state.tableEntries.filter((e) =>
          this.state.selectedEntries.has(e.id)
        );

        fullEntries.forEach((entry) => {
          entry.labels.forEach((currentLabel) => {
            DataActions.removeLabelFromClient(entry.id, currentLabel.id);
          });
        });

        let { refetchContacts } = this.props;
        await DataActions.addLabelToClientArray(entryArray, label.id);
        this.clearSelectedEntries();

        refetchContacts();
      }
    );
  };

  onActivateSelectedEntries = async () => {
    let { updateContact } = this.props;

    let { selectedEntries, tableEntries } = this.state;

    let newEntries = tableEntries
      .filter((e) => selectedEntries.has(e.id))
      .map((e) => ({ ...e, finalizedPeriod: false }));

    for (const newEntry of newEntries) {
      updateContact(newEntry);
    }

    return true;
  };

  onSelectEntry = (id, event) => {
    let selectedEntries = new Set(this.state.selectedEntries);
    if (event.target.checked) {
      selectedEntries.add(id);
    } else {
      selectedEntries.delete(id);
    }
    this.setState({ selectedEntries });
  };

  onSelectAll = () => {
    let { selectedEntries, sortedDataList } = this.state;
    let allSelected = selectedEntries.size === sortedDataList.getSize();

    if (allSelected) {
      this.setState({ selectedEntries: new Set() });
    } else {
      this.setState({
        selectedEntries: new Set(sortedDataList._data.map((d) => d.id)),
      });
    }
  };

  onRemoveLabel = (labelId, clientId) => {
    DataActions.removeLabelFromFile(clientId, labelId);
  };

  onCreateLabel = () => {
    var { newLabelName, selectedEntries } = this.state;

    if (!newLabelName) {
      ModalService.openAlertModal(
        <AxoLocal
          entity="DocumentTableFixedViewopenAlertModal"
          defaultValue={"Indtast et navn for at oprette en ny mappe."}
        />
      );
      return;
    }
    DataActions.createLabel({ name: newLabelName, type: 3 }).then(
      (response) => {
        if (response.ok) {
          this.setState({ showCreatedLabelIcon: true });
          setTimeout(() => {
            this.setState({ showCreatedLabelIcon: false });
          }, 3000);
          response.json().then(async (newLabel) => {
            let { refetchContacts } = this.props;

            await DataActions.addLabelToClientArray(
              Array.from(selectedEntries),
              newLabel.id
            );
            this.setState({ newLabelName: "" });
            this.clearSelectedEntries();

            refetchContacts();
          });
        }
      }
    );
  };

  renderLabelSelect = (contact) => {
    if (!contact.labels || contact.labels.length === 0) {
      return null;
    }
    var selectLabelNodes = contact.labels.map((label) => {
      return (
        <option key={label.id} value={label.id}>
          {label.name}
        </option>
      );
    });
    return (
      <Flexbox>
        <FlexElement flexBasis="25px" flexGrow={1}>
          <select
            className="axoblue selectbg"
            style={{ border: "none", width: "100%" }}
            value="cases"
            onChange={() => {}}
          >
            {selectLabelNodes}
          </select>
        </FlexElement>
        <FlexElement flexBasis="25px">
          &nbsp; ({contact.labels.length})
        </FlexElement>
      </Flexbox>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    let { contacts } = this.props;
    if (prevProps.contacts === this.props.contacts) {
      return;
    }
    let {
      searchText,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
      columnFilters,
    } = prevState;

    let query = {
      searchText,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
      columnFilters,
    };

    let tableEntries = contacts;
    let filteredEntries = this.getFilteredEntries(tableEntries, query);

    this.updateEntries(tableEntries, filteredEntries);
  }

  onChangeContactProperty = (contactId, propertyName, input) => {
    this.onChangeContactPropertyValue(contactId, propertyName, input.value);
  };

  onChangeContactPropertyValue = (contactId, propertyName, value) => {
    let { updateContact } = this.props;

    updateContact({
      ...this.state.sortedDataList._data.find((c) => c.id === contactId),
      [propertyName]: value,
    });
  };

  onChangeContactCustomPropertyValue = (contactId, propertyName, value) => {
    let { updateContact } = this.props;
    let oldContact = this.state.sortedDataList._data.find(
      (c) => c.id === contactId
    );
    updateContact({
      ...oldContact,
      customFieldValues: {
        ...(oldContact.customFieldValues || {}),
        [propertyName]: value,
      },
    });
  };

  updateCreationDate = (contactId, inputDate) => {
    let newDate = moment.utc(inputDate);
    if (newDate.year() > 9999) {
      return;
    }

    this.setState({ cachedCreationDates: { [contactId]: newDate.format() } });
    this.debouncedUpdate({
      ...this.state.sortedDataList._data.find((c) => c.id === contactId),
      creationDate: newDate.format(),
    });
  };

  updateBirthDate = (contactId, inputDate) => {
    let newDate = moment.utc(inputDate);
    if (newDate.year() > 9999) {
      return;
    }

    this.setState({ cachedBirthDates: { [contactId]: newDate.format() } });
    this.debouncedUpdate({
      ...this.state.sortedDataList._data.find((c) => c.id === contactId),
      birthDate: newDate.format(),
    });
  };

  debouncedUpdate = debounce((contact) => {
    let { updateContact } = this.props;
    let { cachedCreationDates, cachedBirthDates } = this.state;

    if (Object.keys(cachedCreationDates).length > 0) {
      this.setState({ cachedCreationDates: {} });
    }

    if (Object.keys(cachedBirthDates).length > 0) {
      this.setState({ cachedBirthDates: {} });
    }

    updateContact(contact);
  }, 1000);

  onTrashContact = (contactId) => {
    ModalService.openConfirmModal(
      "Flyt valgte til skraldespanden?",
      (value) => {
        if (!value) {
          return;
        }

        this.doTrashContact(contactId);
      }
    );
  };

  doTrashContact = (contactId) => {
    var { userProfile, updateContact } = this.props;
    var contact = Object.assign(
      {},
      this.state.sortedDataList._data.find((c) => c.id === contactId)
    );

    //If shared by another, remove the sharing if possible
    if (contact.userName !== userProfile.userName) {
      var sharing = contact.singleSharings.find(
        (s) => s.userProfileId === userProfile.id
      );
      if (!sharing) {
        return;
      }
      this.setState({ deletingContactId: contactId });
      ApiService.deleteSingleSharing(sharing.id).then(() => {
        this.setState({ deletingContactId: 0 });
      });
      return;
    }

    this.setState({ deletingContactId: contactId });
    updateContact({
      ...contact,
      trashed: true,
    });

    this.setState({ deletingContactId: 0 });
  };

  onSearch = (event) => {
    this.setState({ searchText: event.target.value }, () =>
      this.updateShownEntries({ debounced: true })
    );
  };

  updateShownEntries = ({ debounced }) => {
    let { queryContacts } = this.props;
    let {
      tableEntries,
      selectedLabel,
      searchText,
      selectedFromDate,
      selectedToDate,
      columnFilters,
    } = this.state;
    let query = {
      searchText,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
      debounced,
      columnFilters,
    };

    var entries = this.getFilteredEntries(tableEntries, query);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });

    //Fetch from server, if we are not showing all clients
    var { count } = this.props;
    if (tableEntries.length < (count || 0)) {
      queryContacts(query);
    }
  };

  onShowSelectedLabelClients = (selectedLabel) => {
    if (!selectedLabel.id) {
      var route =
        selectedLabel.value === "Private" ? "List" : selectedLabel.value;
      this.props.history.push(RoutingService.getPath("Clients/" + route));
    } else {
      this.setState({ selectedLabel }, () =>
        this.updateShownEntries({ debounced: false })
      );
    }
  };

  getFilteredEntries = (
    entries,
    {
      searchText,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
      columnFilters,
      skipFilterName,
    } = {
      searchText: "",
      selectedLabel: { id: 0, clientIds: null },
      selectedFromDate: null,
      selectedToDate: null,
      columnFilters: {},
    }
  ) => {
    let { isSociety, userSettings } = this.props;

    if (
      !searchText &&
      !selectedLabel.id &&
      !selectedFromDate &&
      !selectedToDate &&
      !isSociety &&
      Object.keys(columnFilters).length === 0
    ) {
      return entries;
    }

    let filteredEntries = entries;

    if (!!searchText) {
      filteredEntries = entries.filter((c) => {
        return `${c.firstName} ${c.lastName} ${c.clientNumber} ${c.eMail} ${c.companyName} ${c.city} ${c.postalCode}`
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    }
    if (selectedLabel.id) {
      filteredEntries = filteredEntries.filter((c) => {
        return !!selectedLabel.clientIds.find((id) => id === c.id);
      });
    }
    if (selectedFromDate) {
      filteredEntries = filteredEntries.filter((entry) => {
        return moment(entry.creationDate) >= selectedFromDate;
      });
    }
    if (selectedToDate) {
      filteredEntries = filteredEntries.filter((entry) => {
        return moment(entry.creationDate) <= selectedToDate;
      });
    }

    if (isSociety && !selectedLabel.id) {
      filteredEntries = filteredEntries.filter(
        (c) =>
          !c.labels.find(
            (l) =>
              l.id === userSettings.formerMembersLabelId ||
              l.id === userSettings.waitingMembersLabelId
          )
      );
    }

    filteredEntries = this.applyColumnFilters(
      filteredEntries,
      columnFilters,
      skipFilterName
    );

    return filteredEntries;
  };

  selectCountry(contactId, val) {
    let { updateContact } = this.props;
    var contact = Object.assign(
      {},
      this.state.sortedDataList._data.find((c) => c.id === contactId)
    );
    contact.country = val;
    updateContact(contact);
  }

  renderColumnDropdown = () => {
    let { userProfile, userSettings } = this.props;

    var columns = this.toggleColumns;
    var columnDefs = this.state.columnDefs;
    var allEnabled = !columns.find((col) => !columnDefs[col].shown);

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <DropdownButton
        id="ColumnDropdown"
        className="visButtoncolor axoDropdownButton VisButton"
        title={
          <span style={{ fontSize: "15px" }}>
            <AxoLocal entity="axoAccounting70" defaultValue={"Vis"} />
          </span>
        }
      >
        <MenuItem eventKey="all">
          <div
            className="text-center"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <AxoCheckbox
              checked={allEnabled}
              onChange={this.onEnableAll.bind(this, !allEnabled)}
            />
            <AxoLocal entity="axoidcode124" defaultValue={"Vis alle"} />
          </div>
        </MenuItem>
        {columns.map((column) => {
          return (
            <MenuItem key={column} eventKey={column}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <AxoCheckbox
                  checked={columnDefs[column].shown}
                  onChange={this.onSelectColumn.bind(this, column)}
                />
                &nbsp;{this.getColumnTitle(column, userType)}
              </div>
            </MenuItem>
          );
        })}
        {((userSettings || {}).customContactFields || []).map((fieldName) => {
          let fullFieldName = this.getCustomFieldFullName(fieldName);
          return (
            <MenuItem key={fullFieldName} eventKey={fullFieldName}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <AxoCheckbox
                  checked={(columnDefs[fullFieldName] || {}).shown}
                  onChange={this.onSelectColumn.bind(this, fullFieldName)}
                />
                &nbsp;{fieldName}
              </div>
            </MenuItem>
          );
        })}
        {/* <MenuItem>
          <LexButton onClick={this.onAddCustomColumn}>
            Tilføj
          </LexButton>
        </MenuItem> */}
      </DropdownButton>
    );
  };

  onAddCustomColumn = () => {
    let { userSettings } = this.props;

    this.textModal.current.open("", async (newTitle) => {
      let response = await DataActions.updateUserSettings({
        ...userSettings,
        customContactFields: [
          ...(userSettings.customContactFields || []),
          newTitle,
        ],
      });
      if (response.ok) {
        this.setState({
          columnDefs: {
            ...this.state.columnDefs,
            [this.getCustomFieldFullName(newTitle)]: {
              width: 125,
              shown: true,
            },
          },
        });
      }
    });
  };

  onRemoveCustomColumn = (fieldName) => {
    let { userSettings } = this.props;

    ModalService.openConfirmModal(
      `Vil du fjerne kolonnen '${fieldName}'?`,
      async (value) => {
        if (!value) {
          return;
        }
        let response = await DataActions.updateUserSettings({
          ...userSettings,
          customContactFields: userSettings.customContactFields.filter(
            (name) => name !== fieldName
          ),
        });
        if (response.ok) {
          this.setState({
            columnDefs: {
              ...this.state.columnDefs,
            },
          });
        }
      }
    );
  };

  getColumnTitle = (columnName, userType) => {
    switch (columnName) {
      case "rowNumber":
        return getText("axoEntityidcode144", "Rækkenummer");
      case "creationDate":
        return getText("TimeEntryFormntimeEntry", "Dato");
      case "birthDate":
        return getText("axoidcode161", "Fødselsdag");
      case "customFieldOne": //Should be customizable in the future
        switch (userType) {
          case "Society":
            return "Husstandsmedlem";
          case "Accountant":
            return getText("axoidcode106", "Momsperiode");
          default:
            return "Data";
        }
      case "firstName":
        return getText("CaseEditFormClientfirstName", "Fornavn");
      case "lastName":
        return getText("CaseEditFormClentlastName", "Efternavn");
      case "clientNumber":
        // if(userType === 'Accountant') {
        //   return getText('axoEntityidcode145', 'App / Manuel');
        // }
        return getText("ContactCreateViewcustomernumber", "ID-nummer");
      case "clientUserName":
        return getText("SidebarRightContainercompanyNameA", "Login");
      case "companyName":
        return getText("SidebarRightContainercompanyName", "Virksomhed");
      case "description":
        return getText("Enhedspris6", "Beskrivelse");
      case "identityCode":
        return getText("EnhedsprvatCountryCode31is6", "CPR / CVR");
      case "address":
        // if(userType === 'Accountant') {
        //   return 'Adgang Skat';
        // }
        return getText("SignupAdresse", "Adresse");
      case "postalCode":
        // if(userType === 'Accountant') {
        //   return 'Adgang Bank';
        // }
        return getText("CaseEditFormpostalCode", "Postnummer");
      case "city":
        // if(userType === 'Accountant') {
        //   return 'Bank';
        // }
        return getText("CaseEditFormcity", "By");
      case "country":
        // if(userType === 'Accountant') {
        //   return getText('axoEntityidcode146', 'Løn')
        // }
        return getText("CaseEditFormcountry", "Land");
      case "phone":
        // if(userType === 'Accountant') {
        //   return getText('AccountingTabViewEntity6', 'Årsregnskab');
        // }
        return getText("ContainerPhone", "Telefon");
      case "mobile":
        return getText("socialSidebarContainerMobile", "Mobile");
      case "eMail":
        return getText("CaseEditFormeMail", "Email");
      case "labelCol":
        return getText("clientselectedLabelb", "Grupper");
      case "newEntries":
        return "Upload";
      case "identityAttachment":
        return "ID";
      case "contractAttachment":
        return "Samarbejdskontrakt";
      case "companyAttachment":
        return "CVR-udskrift";
      default:
        return "";
    }
  };

  onShare = (shareAll, shareWithGroup, userId) => {
    var { selectedEntries } = this.state;
    var { userGroup, userProfile } = this.props;
    if (!shareWithGroup && !userId) {
      return Promise.reject("No selected receiver");
    }

    if (!shareAll && selectedEntries.size === 0) {
      return Promise.reject("No selected clients");
    }

    var promises = [];
    if (shareAll) {
      if (shareWithGroup) {
        promises.push(
          ApiService.createSharingGlobal({
            senderId: userProfile.id,
            userGroupId: userGroup.id,
            type: 0, // Clients
          })
        );
      }
      if (userId) {
        promises.push(
          ApiService.createSharingGlobal({
            senderId: userProfile.id,
            receiverId: userId,
            type: 0, // Clients
          })
        );
      }
    } else {
      if (shareWithGroup) {
        selectedEntries.forEach((clientId) => {
          promises.push(
            ApiService.createSingleSharing({
              contactInfoId: clientId,
              userGroupId: userGroup.id,
            })
          );
        });
      }
      if (userId) {
        selectedEntries.forEach((clientId) => {
          promises.push(
            ApiService.createSingleSharing({
              contactInfoId: clientId,
              userProfileId: userId,
            })
          );
        });
      }
    }
    return Promise.all(promises).then((responses) => {
      DataStore.fetchSharingGlobals();

      return responses;
    });
  };

  onImport = () => {
    this.setState({ importingCSV: true });
  };

  onCancelImport = () => {
    this.setState({ importingCSV: false });
  };

  renderImportButtons = () => {
    var { readingCSV, showCSVSuccess, showCSVError } = this.state;

    var buttons = [];
    buttons.push(
      <Button
        key="download"
        className="Lex-button-2"
        href={this.getSpreadsheetSourceFromLocale()}
      >
        <AxoLocal
          entity="SpreadsheetSourceFromLocale"
          defaultValue={"Download xls"}
        />
      </Button>
    );
    buttons.push(
      <LexButton
        key="import"
        disabled={showCSVError}
        onClick={this.onUploadCSV}
      >
        {(function () {
          if (showCSVSuccess) {
            return (
              <span className="axoblue">
                <AxoLocal
                  entity="paymentDate5a"
                  defaultValue={"Filen blev indlæst"}
                />
                <Icon glyph="icon-fontello-ok-3" />
              </span>
            );
          }
          if (showCSVError) {
            return (
              <span>
                <AxoLocal
                  entity="paymentDate6a"
                  defaultValue={"Filen kunne ikke indlæses"}
                />
              </span>
            );
          }
          return (
            <span>
              <AxoLocal entity="paymentDate7a" defaultValue={"Importer CSV"} />
              {readingCSV ? (
                <span>
                  &nbsp;
                  <img
                    alt=""
                    src="/imgs/app/loading.gif"
                    className="img-circle"
                    width="20"
                    height="20"
                  />
                </span>
              ) : null}
            </span>
          );
        })()}
      </LexButton>
    );
    buttons.push(
      <LexButton key="cancel" onClick={this.onCancelImport}>
        <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
      </LexButton>
    );
    return buttons;
  };

  onAddClient = async () => {
    let { tableLabelId, userProfile, createContact, refetchContacts } =
      this.props;

    try {
      let newContact = await createContact({
        firstName: "---",
        // clientType: clientType || 'Private'
        clientType: "Private",
        country: userProfile.country,
        regions: userProfile.region,
      }).unwrap();

      if (tableLabelId) {
        await DataActions.addLabelToClientArray([newContact.id], tableLabelId);
        refetchContacts();
      }
    } catch {
      return false;
    }

    return true;
  };

  onDeleteSelected = () => {
    let { updateContact } = this.props;

    this.setState({
      deletingSelected: true,
    });

    let deletionPromises = [];
    this.state.selectedEntries.forEach((id) => {
      let contact = this.state.tableEntries.find((d) => d.id === id);
      deletionPromises.push(
        updateContact({
          ...contact,
          trashed: true,
        }).unwrap()
      );
    });
    Promise.all(deletionPromises)
      .then(() => {
        this.setState({
          selectedEntries: new Set(),
          deletingSelected: false,
        });
      })
      .catch((reason) => {
        this.setState({
          selectedEntries: new Set(),
          deletingSelected: false,
        });
      });
  };

  getCSVData = () => {
    let { userProfile, userSettings } = this.props;

    let { sortedDataList, columnDefs } = this.state;

    if (sortedDataList.getSize() === 0) {
      return;
    }

    let userType = AuthorizationService.getUserType(userProfile);

    let headerEntry = [];

    let columnKeys = this.toggleColumns.filter((key) => columnDefs[key].shown);

    columnKeys.forEach((key) => {
      headerEntry.push(this.getColumnTitle(key, userType));
    });

    let customContactFields = (
      (userSettings || {}).customContactFields || []
    ).filter(
      (fieldName) =>
        (columnDefs[this.getCustomFieldFullName(fieldName)] || {}).shown
    );

    headerEntry = headerEntry.concat(customContactFields);

    let rows = [];
    for (let i = 0; i < sortedDataList.getSize(); i++) {
      let contact = sortedDataList.getObjectAt(i);
      let newRow = columnKeys.map((key) => {
        if (key === "birthDate" || key === "creationDate") {
          return moment.utc(contact[key]).format("L");
        }

        return contact[key];
      });

      newRow = newRow.concat(
        customContactFields.map(
          (fieldName) => (contact.customFieldValues || [])[fieldName] || ""
        )
      );

      rows.push(newRow);
    }

    return [headerEntry, ...rows];
  };

  getClientLabel = (userType) => {
    if (userType === "Society") {
      return <AxoLocal entity="axoidcode12" defaultValue={"Medlemmer"} />;
    }
    if (userType === "AccountingClient") {
      return (
        <span>
          <AxoLocal entity="axoEntityidcode62" defaultValue={"Kunder"} />
        </span>
      );
    }
    return <AxoLocal entity="clientMenuNav" defaultValue={"Klienter"} />;
  };

  isValidCVR = (cvr) => {
    //Danish CVR has 8 characters and Norwegian has 9 characters
    if (!cvr || (cvr.length !== 8 && cvr.length !== 9)) {
      return;
    }
    //Check that the CVR number fullfills the modulus 11 rule
    var isValid = checkdigit.mod11.isValid(cvr);
    return isValid;
  };

  onFetchCVR = async (currentContact) => {
    let { updateContact } = this.props;

    let contact = { ...currentContact };
    if (!this.isValidCVR(contact.identityCode)) {
      return false;
    }

    let companyInfo = await ApiService.getCVRInformation(
      contact.identityCode,
      currentContact.country || "dk"
    );
    console.log(companyInfo);
    contact.clientType = 1; //Business
    contact.firstName = companyInfo.name;
    contact.companyName = companyInfo.name;
    contact.description = companyInfo.companydesc;
    contact.address = companyInfo.address;
    contact.city = companyInfo.city;
    contact.postalCode = companyInfo.zipcode;
    contact.eMail = companyInfo.email || "";
    contact.phone = companyInfo.phone || "";
    contact.lastName = "";
    if (companyInfo.owners && companyInfo.owners.length) {
      contact.lastName = "";
      companyInfo.owners.forEach((owner) => {
        if (contact.lastName) {
          contact.lastName += " & ";
        }
        contact.lastName += owner.name;
      });
    }

    await updateContact(contact);

    return true;
  };

  render() {
    let {
      forceShowCards,
      contacts,
      count,
      userGroup,
      friends,
      userProfile,
      adminCodeValidated,
      incrementContactPageSize,
      // clientType
    } = this.props;

    let {
      importingCSV,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
      showControls,
      selectedEntries,
      deletingSelected,
      sortedDataList,
      columnFilters,
    } = this.state;

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div>
        <div
          className="axobg"
          style={{ paddingTop: "10px", marginBottom: "5px" }}
        >
          <Flexbox
            spaceBetween
            alignCenter
            responsive
            className="rightPadding leftPadding"
          >
            <Flexbox alignCenter responsive>
              <h4>{this.getClientLabel(userType)}</h4>
              {!!selectedLabel.id ||
              !!selectedFromDate ||
              !!selectedToDate ||
              Object.keys(columnFilters).length > 0 ? (
                <FlexElement className="rightPadding">
                  <LexButton
                    style={{
                      height: "30px",
                      lineHeight: "1",
                      marginLeft: "5px",
                    }}
                    onClick={this.onClearFilters}
                  >
                    <AxoLocal
                      entity="axoAccounting71"
                      defaultValue={"Ryd filtre"}
                    />
                  </LexButton>
                </FlexElement>
              ) : null}
              <FlexElement className="rightPadding">
                <PaginationControls
                  length={contacts.length}
                  displayLength={sortedDataList.getSize()}
                  count={count}
                  onShowMore={() => incrementContactPageSize()}
                />
              </FlexElement>
              {this.state.showDisabledWarning ? (
                <FlexElement className="rightPadding">
                  <div className="axored">
                    &nbsp;
                    <AxoLocal
                      entity="CaseEditFormclientsAB"
                      defaultValue={"Vælg en klient"}
                    />
                    &#8594; <AxoCheckbox />
                  </div>
                </FlexElement>
              ) : null}
              <FlexElement className="rightPadding">
                <AsyncButton onClick={this.onAddClient} hideOkIcon>
                  <Icon
                    role="button"
                    glyph="icon-fontello-plus-3"
                    className="Addicon"
                  />
                </AsyncButton>
              </FlexElement>
              <FlexElement className="rightPadding">
                <LexButton
                  disabled={selectedEntries.size === 0}
                  onClick={this.onDeleteSelected}
                >
                  <Icon glyph="icon-fontello-trash-1" />
                  &nbsp;
                  <AxoLocal
                    entity="updategetAccountName7a"
                    defaultValue={"Slet valgte"}
                  />
                  <LoadingIcon show={deletingSelected} />
                </LexButton>
              </FlexElement>
              {userType !== "AccountingClient" && (
                <FlexElement className="rightPadding axocolorbutton Colorbutton">
                  <LexButton
                    style={{ width: "160px", lineHeight: "1" }}
                    onClick={() =>
                      this.setState({ showControls: !showControls })
                    }
                  >
                    {showControls ? (
                      <span>
                        <Icon
                          className="Colorbuttonicon"
                          glyph="icon-fontello-angle-double-up"
                        />
                        &nbsp;&nbsp;{" "}
                        <AxoLocal
                          key="hide"
                          entity="axoAccounting77"
                          defaultValue={"Skjul kontrolpanel"}
                        />
                      </span>
                    ) : (
                      <span>
                        <Icon
                          className="Colorbuttonicon"
                          glyph="icon-fontello-angle-double-down"
                        />
                        &nbsp;&nbsp;{" "}
                        <AxoLocal
                          key="show"
                          entity="axoAccounting79"
                          defaultValue={"Vis kontrolpanel"}
                        />
                      </span>
                    )}
                  </LexButton>
                </FlexElement>
              )}
            </Flexbox>
            <div>
              <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                <FormGroup controlId="inputWithIcon">
                  <InputGroup>
                    <InputGroup.Addon>
                      <Icon glyph="icon-fontello-search" />
                    </InputGroup.Addon>
                    {/* Søg...  */}
                    <AxoLocal
                      componentClass={FormControl}
                      type="text"
                      value={this.state.searchText}
                      onChange={this.onSearch}
                      componentAttribute="placeholder"
                      entity="ContactTableViewplaceholderonSearch"
                    />
                    <FormControl.Feedback>
                      <Icon glyph="icon-fontello-search" />
                    </FormControl.Feedback>
                  </InputGroup>
                </FormGroup>
              </div>
            </div>
            {this.renderColumnDropdown()}
            {userType !== "AccountingClient" && (
              <SharingDropDown
                objectName={
                  <span>
                    {userType === "Society" ? (
                      <span>
                        {" "}
                        <AxoLocal
                          entity="axoidcode12"
                          defaultValue={"Medlemmer"}
                        />
                      </span>
                    ) : (
                      <AxoLocal
                        entity="clientMenuNav"
                        defaultValue={"Klienter"}
                      />
                    )}
                  </span>
                }
                userGroup={userGroup}
                friends={friends}
                userProfile={userProfile}
                onShare={this.onShare}
                hasSelectedEntries={this.state.selectedEntries.size > 0}
              />
            )}
            <CSVLink
              data={this.state.csv}
              separator={";"}
              filename={"Contacts.csv"}
              asyncOnClick={true}
              onClick={(event, done) => {
                this.setState({ csv: this.getCSVData() }, () => done());
              }}
            >
              <LexButton>
                <AxoLocal entity="axoAccounting4" defaultValue={"Eksporter"} />{" "}
              </LexButton>
            </CSVLink>
            {/* <LexButton href={ApiService.getContactExportPath({
                labelId: !!selectedLabel ? selectedLabel.id : null,
                clientType: clientType || 'Private'
              })}>
                  <AxoLocal entity='axoAccounting4' defaultValue={'Eksporter'}/>  
              </LexButton> */}
            {adminCodeValidated && (
              <>
                {importingCSV ? (
                  this.renderImportButtons()
                ) : (
                  <LexButton onClick={this.onImport}>
                    <AxoLocal
                      entity="updategetAccountName11"
                      defaultValue={"Importer"}
                    />
                  </LexButton>
                )}
              </>
            )}

            <div className="hidden-xs text-right">
              <Button
                className="Lex-button-2"
                onClick={this.props.onToggleCards}
              >
                {this.props.forceShowCards ? (
                  <span>
                    {" "}
                    <Icon glyph="icon-fontello-grid" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      key="showTable"
                      entity="CaseDataTableFixedShowastable"
                      defaultValue={"Vis som tabel"}
                    />
                  </span>
                ) : (
                  <span>
                    {" "}
                    <Icon glyph="icon-fontello-th-thumb-empty" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      key="showCards"
                      entity="CaseDataTableFixedShowCards"
                      defaultValue={"Vis som kartotekskort"}
                    />{" "}
                  </span>
                )}
              </Button>
            </div>
          </Flexbox>
        </div>
        {forceShowCards ? (
          this.renderGridView()
        ) : (
          <div>
            <ExtraSmall>{this.renderGridView()}</ExtraSmall>
            <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
          </div>
        )}
        <input
          name="csv"
          onChange={this.onCSVChange}
          type="file"
          accept=".csv"
          id="csvUpload"
          style={{ display: "none" }}
        />
      </div>
    );
  }

  getSpreadsheetSourceFromLocale = () => {
    var { locale } = this.state;

    if (locale === "da") {
      return "/xls/Contacts(DK).xls";
    } else if (locale === "ch") {
      return "/xls/Contacts(CH).xls";
    }
    return "/xls/Contacts(EN).xls";
  };

  renderGridView = () => {
    let { userProfile, createContact, updateContact, createClientAccount } =
      this.props;

    let { sortedDataList, selectedEntries, locale } = this.state;

    return (
      <div style={{ paddingTop: "10px" }}>
        {this.renderInputBoxes()}
        <ContactGridView
          contacts={sortedDataList.getSortedList()}
          createContact={createContact}
          updateContact={updateContact}
          createClientAccount={createClientAccount}
          selectedEntries={selectedEntries}
          onSelectEntry={this.onSelectEntry}
          userProfile={userProfile}
          locale={locale}
        />
      </div>
    );
  };

  onUploadCSV() {
    $("#csvUpload").trigger("click");
  }

  onCSVChange = (event) => {
    var uploader = event.target;
    if (uploader.files.length === 0) {
      return;
    }
    try {
      this.setState({ readingCSV: true });
      // ApiService.readClientsFromCSV(uploader.files)
      ApiService.readSaunaClientsFromCSV(uploader.files)
        .then((response) => {
          this.setState({ readingCSV: false });
          if (response.ok) {
            this.setState({ showCSVSuccess: true });
            setTimeout(() => {
              this.setState({
                showCSVSuccess: false,
              });
            }, 4000);
          } else {
            return Promise.reject("Could not read CSV");
          }
        })
        .catch((reason) => {
          this.setState({
            showCSVError: true,
            readingCSV: false,
          });
          setTimeout(() => {
            this.setState({
              showCSVError: false,
            });
          }, 4000);
        });
    } finally {
      uploader.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  checkForDisabledInput = () => {
    if (this.state.selectedEntries.size === 0) {
      this.setState({ showDisabledWarning: true });
      setTimeout(() => {
        this.setState({ showDisabledWarning: false });
      }, 3000);
    }
  };

  onTypeChange = (selectedType) => {
    if (!selectedType) {
      return;
    }

    let { contacts, updateContact } = this.props;
    let { selectedEntries } = this.state;

    let selectedContacts = contacts.filter((c) => selectedEntries.has(c.id));
    selectedContacts.forEach((contact) => {
      updateContact({
        ...contact,
        clientType: selectedType.value,
      });
    });
  };

  //label bruges af Select og name bruges af LabelSearchBox
  // clientTypes = [
  //   { value: 'Private', label: getText('CaseEditFormcaseActive', 'Aktiv'), name: getText('CaseEditFormcaseActive', 'Aktiv') },
  //   { value: 'Waiting', label: getText('axoidcode185', 'Venteliste'), name:  getText('axoidcode185', 'Venteliste') },
  //   { value: 'Former', label:  getText('axoidcode187', 'Udmeldt'), name:getText('axoidcode187', 'Udmeldt') }
  // ]

  renderInputBoxes = () => {
    let {
      selectedEntries,
      showControls,
      selectedLabel,
      selectedFromDate,
      selectedToDate,
      columnFilters,
    } = this.state;

    if (!showControls) {
      return null;
    }

    let {
      userProfile,
      updateNewEntries,
      // clientType
    } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);
    let isAccountant = userType === "Accountant" || userType === "Admin";
    // let currentClientType = this.clientTypes.find(t => t.value === clientType) || this.clientTypes[0];

    // let standardLabels = [];
    // if(userType === 'Society') {
    //   standardLabels = this.clientTypes;
    // }

    return (
      <React.Fragment>
        <Flexbox responsive>
          <FlexElement
            className="rightPadding"
            // MissingEntity
            title="Personerne vil forblive i deres eksisterende grupper."
          >
            <Icon className="Axocolor1" glyph="icon-fontello-user-add" />
            &ensp;&ensp;
            <AxoLocal
              className="Axocolor1"
              entity="clientselectedLabel"
              defaultValue={"Tilføj til gruppe "}
            />
            <span onClick={this.checkForDisabledInput}>
              <AxoLocal
                componentClass={LabelSearchBox}
                // labels={this.props.labels.concat(standardLabels)}
                labels={this.props.labels}
                handleSelectedLabel={this.addSelectedEntriesToLabel}
                disabled={selectedEntries.size === 0}
                inputBorder="1px solid #cc00cc"
                clearOnSelect
                componentAttribute="placeholder"
                entity="ContactLabelPlaceholder"
              />
            </span>
          </FlexElement>
          <FlexElement
            className="rightPadding Axocolor2 onTop"
            // MissingEntity
            title="Dette vil fjerne personerne fra alle grupper undtagen den valgte."
          >
            <Icon glyph="icon-fontello-user-add" />
            &ensp;&ensp;
            <AxoLocal entity="axoidcode164" defaultValue=" Flyt til gruppe" />
            <span onClick={this.checkForDisabledInput}>
              <AxoLocal
                componentClass={LabelSearchBox}
                // labels={this.props.labels.concat(standardLabels)}
                labels={this.props.labels}
                handleSelectedLabel={this.moveSelectedEntriesToLabel}
                disabled={selectedEntries.size === 0}
                inputBorder="1px solid #00cc00"
                clearOnSelect
                componentAttribute="placeholder"
                entity="ContactLabelPlaceholder"
              />
            </span>
          </FlexElement>
          <FlexElement style={{ paddingRight: "15px" }}>
            {this.renderCreateLabelInput()}
          </FlexElement>
          {/* { userType === 'Society' ? (
            <FlexElement flexBasis='150px' className='onTop'>
              <div> <AxoLocal entity='axoidcode182'defaultValue={'Skift type'}/></div>
              <Select
                name="form-field-name"
                menuPlacement='auto'
                isDisabled={this.state.selectedEntries.size === 0}
                value={currentClientType}
                onChange={this.onTypeChange}
                noOptionsMessage={() => ''}
                options={this.clientTypes}
              />
            </FlexElement>
          ) : null } */}
        </Flexbox>
        <Flexbox responsive style={{ marginBottom: "5px" }}>
          <FlexElement className="Axocolor4" style={{ paddingRight: "15px" }}>
            <Icon glyph="icon-fontello-braille" />
            &ensp;&ensp;
            <span>
              <AxoLocal
                entity="ContactLabelShowPlaceholder"
                defaultValue={"Vis gruppe"}
              />
              {selectedLabel.id ? (
                <span>&nbsp;({selectedLabel.name})</span>
              ) : null}
            </span>
            <div className="flexbox-responsive">
              <AxoLocal
                componentClass={LabelSearchBox}
                // labels={this.props.labels.concat(standardLabels)}
                labels={this.props.labels}
                handleSelectedLabel={this.onShowSelectedLabelClients}
                disabled={this.props.labels.length === 0}
                inputBorder="1px solid #AB3910"
                clearOnSelect
                componentAttribute="placeholder"
                entity="ContactLabelShowPlaceholder"
              />
            </div>
          </FlexElement>
          <FlexElement style={{ paddingRight: "15px" }}>
            <AxoLocal entity="axoAccounting73" defaultValue={"Dato fra"} />
            <AxoDateTime
              value={selectedFromDate}
              dateFormat={true}
              timeFormat={false}
              onChange={(selectedFromDate) => {
                this.setState({ selectedFromDate }, () =>
                  this.updateShownEntries({ debounced: false })
                );
              }}
            />
          </FlexElement>
          <FlexElement style={{ paddingRight: "15px" }}>
            <AxoLocal entity="axoAccounting72" defaultValue={"Dato til"} />
            <AxoDateTime
              value={selectedToDate}
              dateFormat={true}
              timeFormat={false}
              onChange={(selectedToDate) => {
                this.setState({ selectedToDate }, () =>
                  this.updateShownEntries({ debounced: false })
                );
              }}
              isValidDate={(currentDate) => {
                return (
                  !selectedFromDate || currentDate.isAfter(selectedFromDate)
                );
              }}
            />
          </FlexElement>
          {(!!selectedLabel.id ||
            !!selectedFromDate ||
            !!selectedToDate ||
            Object.keys(columnFilters).length > 0) && (
            <FlexElement style={{ paddingRight: "15px" }}>
              <div style={{ visibility: "hidden" }}>
                <AxoLocal entity="paymentDate13a" defaultValue={"Hidden"} />
              </div>
              <LexButton
                style={{ height: "30px", lineHeight: "1" }}
                onClick={this.onClearFilters}
              >
                <AxoLocal
                  entity="axoAccounting71"
                  defaultValue={"Ryd filtre"}
                />
              </LexButton>
            </FlexElement>
          )}
          {isAccountant && (
            <>
              <FlexElement style={{ paddingRight: "15px" }}>
                <div style={{ visibility: "hidden" }}>
                  <AxoLocal entity="paymentDate13a" defaultValue={"Hidden"} />
                </div>
                <AsyncButton
                  title="Opdater antal posteringer"
                  onClick={async () => {
                    try {
                      await updateNewEntries();
                    } catch {
                      return false;
                    }
                    return true;
                  }}
                >
                  <Icon glyph="icon-fontello-upload-4" />
                </AsyncButton>
              </FlexElement>
              {selectedEntries.size > 0 && (
                <FlexElement style={{ paddingRight: "15px" }}>
                  <div style={{ visibility: "hidden" }}>
                    <AxoLocal entity="paymentDate13a" defaultValue={"Hidden"} />
                  </div>
                  <AsyncButton
                    title="Åben perioderegnskaber"
                    onClick={this.onActivateSelectedEntries}
                  >
                    <Icon glyph="icon-fontello-lock-open-1" />
                  </AsyncButton>
                </FlexElement>
              )}
            </>
          )}
        </Flexbox>
      </React.Fragment>
    );
  };

  onClearFilters = () => {
    this.setState(
      {
        selectedLabel: {},
        selectedFromDate: null,
        selectedToDate: null,
        columnFilters: {},
      },
      () => this.updateShownEntries({ debounced: false })
    );

    this.saveColumnFilters({});
  };

  renderCreateLabelInput = () => {
    return (
      <div>
        <span className="Axocolor3" onClick={this.checkForDisabledInput}>
          <div>
            <Icon
              role="button"
              glyph="icon-fontello-plus-3"
              className="Addicon"
            />
            &ensp;&ensp;
            <AxoLocal
              entity="clientselectedLabela"
              defaultValue={"Tilføj til ny gruppe"}
            />
          </div>
          <InputGroup style={{ maxWidth: "250px" }}>
            <AxoLocal
              componentClass={FormControl}
              type="text"
              style={{
                borderColor: "#ff9933",
                borderWidth: "1px",
                borderRadius: "4px",
              }}
              disabled={this.state.selectedEntries.size === 0}
              value={this.state.newLabelName}
              onChange={(event) => {
                this.setState({ newLabelName: event.target.value });
              }}
              componentAttribute="placeholder"
              entity="placeholderEnterthefoldername"
            />
            <InputGroup.Addon className="plain">
              <Button className="Lex-button-2" onClick={this.onCreateLabel}>
                {/*   <AxoLocal entity='ADDViewCreate'defaultValue={'Opret'}/> */}
                <Icon
                  role="button"
                  glyph="icon-fontello-plus-3"
                  className="Addicon"
                />
                {this.state.showCreatedLabelIcon ? (
                  <span>
                    &nbsp;
                    <Icon className="axoblue" glyph="icon-fontello-ok-3" />{" "}
                    &nbsp;&nbsp;
                  </span>
                ) : null}
              </Button>
            </InputGroup.Addon>
          </InputGroup>
        </span>
      </div>
    );
  };

  onStopSharing = (contact) => {
    var { sharingGlobals } = this.props;

    var promises = [];
    sharingGlobals.forEach((s) => {
      promises.push(ApiService.deleteSharingGlobal(s.id));
    });
    contact.singleSharings.forEach((s) => {
      promises.push(ApiService.deleteSingleSharing(s.id));
    });
    Promise.all(promises).then((responses) => {
      DataStore.fetchSharingGlobals();
    });
  };

  onDeleteAll = () => {
    let { contacts, updateContact } = this.props;

    contacts.forEach((c) => {
      updateContact({
        ...c,
        trashed: true,
      });
    });
  };

  ColumnFilterButtons = ({ propertyName, filterType = FilterTypes.TEXT }) => {
    let { columnFilters } = this.state;

    let func = () => {
      switch (filterType) {
        case FilterTypes.TEXT:
          return this.editTextFilter(propertyName);
        case FilterTypes.DATE:
          return this.editDateFilter(propertyName);
        case FilterTypes.NUMBER:
          return this.editNumberFilter(propertyName);
        default:
          return;
      }
    };

    return (
      <span onClick={(event) => event.stopPropagation()}>
        <span title={getText("axoEntityidcode117", "Filtrer")} onClick={func}>
          &nbsp;
          <Icon className="editable" glyph="icon-fontello-filter" />
          &nbsp;
        </span>
        &nbsp;
        {!!columnFilters[propertyName] && (
          <span
            title={getText("axoEntityidcode207", "Fjern filter")}
            onClick={() => this.removeColumnFilter(propertyName)}
          >
            &nbsp;
            <Icon className="axored" glyph="icon-fontello-trash" />
            &nbsp;
          </span>
        )}
      </span>
    );
  };

  removeColumnFilter = (propertyName) => {
    let { columnFilters } = this.state;

    let { [propertyName]: property, ...newFilter } = columnFilters;

    // this.setState({ columnFilters: newFilter });
    this.saveColumnFilters(newFilter);

    this.updateEntryFilter(newFilter);
  };

  onShowAttachment = (attachmentName, entryId) => {
    let entry = this.state.sortedDataList._data.find((p) => p.id === entryId);
    let attachment = entry.attachmentMap[attachmentName];
    if (!attachment) {
      return;
    }

    this.doShowAttachment(attachment);
  };

  doShowAttachment = (attachment) => {
    this.fileModal.open([attachment]);
  };

  onUploadAttachment = (attachmentName, entryId) => {
    this.selectedEntryId = entryId;
    this.selectedAttachmentName = attachmentName;
    this.uploadAttachment.current.click();
  };

  onAttachmentSelected = async (event) => {
    let entry = this.state.sortedDataList._data.find(
      (e) => e.id === this.selectedEntryId
    );
    if (!entry) {
      return;
    }

    let files = event.target.files;
    if (files.length === 0) {
      return;
    }

    let file = files[0];
    if (file.size / Math.pow(1024, 2) > 20) {
      ModalService.openAlertModal("Filen må ikke fylde over 20 mb");
      return;
    }

    let uploadResponse = await DataActions.uploadDocuments(files, {
      attachmentClientId: this.selectedEntryId,
      notes: this.selectedAttachmentName,
      convertToPdf: true,
      skipOptimisticUpdate: true,
    });

    let addedFiles = [];
    if (uploadResponse.ok) {
      addedFiles = await uploadResponse.json();
      if (addedFiles.length === 0) {
        return;
      }

      let { updateContact } = this.props;

      updateContact({
        ...entry,
        attachmentMap: {
          ...entry.attachmentMap,
          [this.selectedAttachmentName]: addedFiles[0],
        },
      });
    } else {
      return this.displayResponseWarnings(uploadResponse);
    }
  };

  displayResponseWarnings = async (response) => {
    if (response.status === 400) {
      let text = await response.text();
      if (text === "Storage") {
        ModalService.openAlertModal(
          "Du har ikke mere ledig lagerplads. Slet filer for at skaffe mere plads."
        );
        return;
      } else {
        ModalService.openAlertModal(
          "Der skete en fejl ved upload af filen. Prøv igen senere."
        );
        return;
      }
    } else {
      ModalService.openAlertModal(
        "Der skete en fejl ved upload af filen. Prøv igen senere."
      );
      return;
    }
  };

  onDeleteAttachment = (attachmentName, entry) => {
    ModalService.openConfirmModal(
      <>
        <AxoLocal
          entity="axoEntityidcode26"
          defaultValue={"Vil du slette bilagsfilen permanent?"}
        />
      </>,
      async (value) => {
        if (!value) {
          return;
        }

        let attachment = entry.attachmentMap[attachmentName];
        if (!attachment) {
          return;
        }

        let { updateContact } = this.props;

        let { [attachmentName]: oldAttachment, ...newMap } =
          entry.attachmentMap;

        await updateContact({
          ...entry,
          attachmentMap: newMap,
        }).unwrap();

        await DataActions.deleteDocument(attachment.id);
      }
    );
  };

  renderTable = () => {
    let {
      sortedDataList,
      colSortDirs,
      columnDefs,
      deletingContactId,
      showControls,
      selectedEntries,
    } = this.state;

    const {
      containerHeight,
      containerWidth,
      userProfile,
      sharingGlobals,
      // financeAccountPlans,
      locale,
      userSettings,
    } = this.props;

    let tableWidth = containerWidth - 70;
    let tableHeight = showControls
      ? containerHeight - 315
      : containerHeight - 220;
    let userType = AuthorizationService.getUserType(userProfile);
    let isAccountant = userType === "Accountant";
    let showAccounting = isAccountant || userType === "Admin";
    let showAccountantHeaders = false; //userType === 'Accountant';

    // let accountPlanOptions = financeAccountPlans.map(f => {
    //   return { value: f.id, label: f.name };
    // });

    // let getPlanLabel = getText("axoAccounting32", 'Vælg kontoplan');

    var countryNamesMap = {};
    sortedDataList._data.forEach((entry) => {
      if (!entry.country || countryNamesMap[entry.country]) {
        return;
      }
      countryNamesMap[entry.country] = ctx.getSync("CountryNames", {
        countrySlug: entry.country,
      });
    });

    //Cannot use component since child type must be <FixedDataTableColumn />
    let renderAttachmentColumn = (props) => {
      let { header, columnName } = props;

      return (
        <Column
          columnKey={columnName}
          header={
            <Cell>
              <Icon className="editable" glyph="icon-fontello-attach-7" />
              &nbsp;
              {header}
            </Cell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            let attachmentFile = (entry.attachmentMap || {})[columnName];

            return (
              <Cell key={entry.id} {...props}>
                {!!attachmentFile ? (
                  <div className="inlineBlock">
                    <Icon
                      className="editable"
                      role="button"
                      onClick={() =>
                        this.onShowAttachment(columnName, entry.id)
                      }
                      glyph="icon-fontello-attach-7"
                    />
                    &nbsp;
                    <Icon
                      title={getText("axoidcode9", "Fjern bilagsfil")}
                      className="editable"
                      role="button"
                      onClick={() => this.onDeleteAttachment(columnName, entry)}
                      glyph="icon-fontello-trash-1"
                    />
                  </div>
                ) : (
                  <div className="inlineBlock">
                    &nbsp;
                    <Icon
                      title="Upload bilagsfil"
                      className="editable"
                      role="button"
                      onClick={() =>
                        this.onUploadAttachment(columnName, entry.id)
                      }
                      glyph="icon-fontello-upload-4"
                    />
                  </div>
                )}
              </Cell>
            );
          }}
          width={columnDefs[columnName].width}
          isResizable={true}
        />
      );
    };

    let ColumnFilterButtons = this.ColumnFilterButtons;
    return (
      <div style={{ paddingTop: "0px", paddingLeft: "20px" }}>
        {this.renderInputBoxes()}
        <Table
          rowHeight={40}
          rowsCount={sortedDataList.getSize()}
          width={tableWidth}
          height={tableHeight}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          touchScrollEnabled={true}
          headerHeight={40}
        >
          {columnDefs.rowNumber.shown ? (
            <Column
              columnKey="rowNumber"
              header={<Cell className="text-center">#</Cell>}
              cell={(props) => {
                return (
                  <Cell key={props.rowIndex} className="text-center" {...props}>
                    {props.rowIndex + 1}
                  </Cell>
                );
              }}
              width={columnDefs.rowNumber.width}
              isResizable={true}
            />
          ) : null}
          <Column
            columnKey="select"
            header={
              <Cell className="text-center">
                <AxoCheckbox
                  checked={
                    selectedEntries.size > 0 &&
                    selectedEntries.size === sortedDataList.getSize()
                  }
                  onChange={this.onSelectAll}
                />
              </Cell>
            }
            cell={(props) => {
              var contact = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={contact.id} className="text-center" {...props}>
                  {/* { props.rowIndex  + 1} */}
                  <AxoCheckbox
                    checked={this.state.selectedEntries.has(contact.id)}
                    onChange={this.onSelectEntry.bind(this, contact.id)}
                  />
                </Cell>
              );
            }}
            width={columnDefs.select.width}
            isResizable={true}
          />
          {showAccounting && columnDefs.newEntries.shown && (
            <Column
              columnKey="newEntries"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-qrcode" />}
                  showAltIcon={columnDefs.newEntries.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.newEntries}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp; Upload
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons
                        filterType={FilterTypes.NUMBER}
                        propertyName="newEntries"
                      />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    {contact.newEntries > 0 && (
                      <>
                        &ensp; ({contact.newEntries - contact.unreadEntries}/
                        {contact.newEntries})
                      </>
                    )}
                    {contact.finalizedPeriod && <>&ensp; (AFSLUTTET)</>}
                  </Cell>
                );
              }}
              width={columnDefs.newEntries.width}
              isResizable={true}
            />
          )}
          {isAccountant && columnDefs.companyName.shown && (
            <Column
              columnKey="companyName"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-building" />}
                  showAltIcon={columnDefs.companyName.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.companyName}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="SidebarRightContainercompanyName"
                        defaultValue={"Virksomhed"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="companyName" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <Flexbox>
                      <FlexElement>
                        <span>
                          <Icon
                            glyph="icon-fontello-user-7"
                            title={getText("axoidcode39", "Gå til regnskab")}
                            role="button"
                            onClick={() => {
                              DataStore.setSelectedContact(contact || {});
                              this.props.history.push(
                                RoutingService.getPath("Accounting/Drafts")
                              );
                            }}
                          />
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </FlexElement>
                      <FlexElement flexGrow={1}>
                        <InlineEdit
                          value={contact.companyName || "---"}
                          change={this.onChangeContactProperty.bind(
                            this,
                            contact.id,
                            "companyName"
                          )}
                        />
                      </FlexElement>
                    </Flexbox>
                  </Cell>
                );
              }}
              width={columnDefs.companyName.width}
              isResizable={true}
            />
          )}
          {columnDefs.firstName.shown ? (
            <Column
              columnKey="firstName"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-user-outline" />}
                  showAltIcon={columnDefs.firstName.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.firstName}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="CaseEditFormClientfirstName"
                        defaultValue={"Fornavn*"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="firstName" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                var sharedWithMe = userProfile.userName !== contact.userName;
                var sharedWithOthers =
                  !sharedWithMe &&
                  (sharingGlobals.length > 0 ||
                    (contact.singleSharings &&
                      !!contact.singleSharings.find(
                        (s) => s.userProfileId !== userProfile.id
                      )));

                return (
                  <Cell key={contact.id} {...props}>
                    <Flexbox>
                      {userType === "Admin" && (
                        <FlexElement>
                          <span>
                            <Icon
                              glyph="icon-fontello-user-7"
                              title={getText("axoidcode39", "Gå til regnskab")}
                              role="button"
                              onClick={() => {
                                DataStore.setSelectedContact(contact || {});
                                this.props.history.push(
                                  RoutingService.getPath("Accounting/Drafts")
                                );
                              }}
                            />
                            &nbsp;&nbsp;&nbsp;
                          </span>
                        </FlexElement>
                      )}
                      <FlexElement flexGrow={1}>
                        <InlineEdit
                          value={contact.firstName || "---"}
                          change={this.onChangeContactProperty.bind(
                            this,
                            contact.id,
                            "firstName"
                          )}
                        />
                        {sharedWithMe && (
                          <span>
                            &ensp;
                            <Icon
                              role="button"
                              title={`${getText(
                                "axoEntityidcode248",
                                "Delt med dig"
                              )} (${contact.userName})`}
                              className="editable"
                              glyph="icon-fontello-share-1"
                            />
                          </span>
                        )}
                        {sharedWithOthers && (
                          <span>
                            &ensp;
                            <Icon
                              role="button"
                              title={getText(
                                "axoEntityidcode255",
                                "Delt med andre"
                              )}
                              className="editable"
                              glyph="icon-fontello-share-1"
                            />
                            &nbsp;
                            <Icon
                              onClick={() => {
                                this.onStopSharing(contact);
                              }}
                              role="button"
                              title={getText(
                                "axoEntityidcode256",
                                "Stop deling"
                              )}
                              glyph="icon-fontello-scissors axored"
                            />
                          </span>
                        )}
                      </FlexElement>
                    </Flexbox>
                  </Cell>
                );
              }}
              width={columnDefs.firstName.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.lastName.shown && this.state.locale !== "ch" ? (
            <Column
              columnKey="lastName"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-user-7" />}
                  showAltIcon={columnDefs.lastName.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.lastName}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="CaseEditFormClentlastName"
                        defaultValue={"Efternavn"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="lastName" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.lastName || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "lastName"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.lastName.width}
              isResizable={true}
            />
          ) : null}
          {showAccounting &&
            columnDefs.identityAttachment.shown &&
            renderAttachmentColumn({
              header: "ID",
              columnName: "identityAttachment",
            })}
          {showAccounting &&
            columnDefs.contractAttachment.shown &&
            renderAttachmentColumn({
              header: "Kontrakt",
              columnName: "contractAttachment",
            })}
          {showAccounting &&
            columnDefs.companyAttachment.shown &&
            renderAttachmentColumn({
              header: "CVR-udskrift",
              columnName: "companyAttachment",
            })}
          {columnDefs.clientNumber.shown && this.state.locale !== "ch" ? (
            <Column
              columnKey="clientNumber"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-qrcode" />}
                  showAltIcon={columnDefs.clientNumber.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.clientNumber}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      {showAccountantHeaders ? (
                        <span>
                          <AxoLocal
                            entity="axoEntityidcode145"
                            defaultValue={"App / Manuel"}
                          />
                        </span>
                      ) : (
                        <AxoLocal
                          key="Customernumber"
                          entity="ContactCreateViewcustomernumber"
                          defaultValue={"ID-nummer"}
                        />
                      )}
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="clientNumber" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.clientNumber || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "clientNumber"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.clientNumber.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.customFieldOne.shown && userType === "Society" ? (
            <Column
              columnKey="customFieldOne"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-at-2" />}
                  showAltIcon={columnDefs.customFieldOne.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.customFieldOne}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp; Husstandsmedlem
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="customFieldOne" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.customFieldOne || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "customFieldOne"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.customFieldOne.width}
              isResizable={true}
            />
          ) : null}
          {userType !== "Society" && columnDefs.clientUserName.shown ? (
            <Column
              columnKey="clientUserName"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-barcode-1" />}
                  showAltIcon={columnDefs.clientUserName.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.clientUserName}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="SidebarRightContainercompanyNameA"
                        defaultValue={"Login"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="clientUserName" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    {contact.clientUserName}
                  </Cell>
                );
              }}
              width={columnDefs.clientUserName.width}
              isResizable={true}
            />
          ) : null}

          {/* { ( userType === 'Accountant' || userType === 'Admin' ) && columnDefs.financeAccountPlan.shown ? (
            <Column
              columnKey="financeAccountPlan"
              header={<SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-barcode-1" />}
                  showAltIcon={columnDefs.financeAccountPlan.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.financeAccountPlan}>
                  <Icon className='editable' glyph="icon-fontello-arrow-combo" />&nbsp;
                  <AxoLocal entity='AccountingTabViewEntity27' defaultValue={'Kontoplan'}/>
                </SortHeaderCell>}
              cell={props => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                <Cell key={contact.id} {...props}>
                    <Select
                      name="form-field-name"
                      menuPlacement='auto'
                      menuPortalTarget={document.body}
                      // menuContainerStyle={props.rowIndex > 5 ? {top: 'auto', bottom: '100%'}: null}
                      value={accountPlanOptions.find(o => o.value === contact.financeAccountPlanId)
                        || { value: '', label: getPlanLabel }}
                      onChange={(selectedPlan) => {
                        if(!!selectedPlan) {
                          this.onChangeContactPropertyValue(contact.id, 'financeAccountPlanId', selectedPlan.value );
                        }
                      }}
                      noOptionsMessage={() => ''}
                      options={[
                        { value: '', label: getPlanLabel },
                        ...accountPlanOptions
                      ]}
                    />
                </Cell> )}}
              width={columnDefs.financeAccountPlan.width}
              isResizable={true}
            />
          ) : null } */}
          {userType !== "Society" &&
          !isAccountant &&
          columnDefs.companyName.shown ? (
            <Column
              columnKey="companyName"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-building" />}
                  showAltIcon={columnDefs.companyName.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.companyName}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="SidebarRightContainercompanyName"
                        defaultValue={"Virksomhed"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="companyName" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.companyName || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "companyName"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.companyName.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.description.shown ? (
            <Column
              columnKey="description"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-building" />}
                  showAltIcon={columnDefs.description.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.description}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        key="description"
                        entity="Enhedspris6"
                        defaultValue={"Beskrivelse"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="description" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.description || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "description"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.description.width}
              isResizable={true}
            />
          ) : null}
          {/* { columnDefs.clientType.shown ? (
            <Column
              columnKey="clientType"
              header={<SortHeaderCell
                  altIcon={<Icon  glyph="icon-fontello-building" />}
                  showAltIcon={columnDefs.clientType.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.clientType}>
                  <Icon className='editable' glyph="icon-fontello-arrow-combo" />&nbsp;
                  <AxoLocal entity='ContactCreateViewCustomer'defaultValue={'Kunde'}/>
                </SortHeaderCell>}
              cell={props => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                <Cell key={contact.id} {...props}>
                  <select className='axoblue selectbg' value={contact.clientType} onChange={(event) => { this.onChangeContactPropertyValue(contact.id, 'clientType', event.target.value)} }>
                    <AxoLocal componentClass={ReactOption} value={0} disabled componentAttribute='text' entity='ContactCreateViewPrivateCustomer' defaultValue={'Private'}/>
                    <AxoLocal componentClass={ReactOption} value={1} disabled componentAttribute='text' entity='ContactCreateViewCommerceCustomer' defaultValue={'Commerce Customer'}/>
                    <AxoLocal componentClass={ReactOption} value={2} disabled componentAttribute='text' entity='ContactCreateViewAuthorityCustomer' defaultValue={'Authority Customer'}/>
                    <AxoLocal componentClass={ReactOption} value={3} disabled componentAttribute='text' entity='ContactCreateViewOtherCustomer' defaultValue={'Other'}/>
                  </select>
                </Cell> )}}
              width={columnDefs.clientType.width}
              isResizable={true}
            />
          ) : null } */}
          {userType !== "Society" && columnDefs.identityCode.shown ? (
            <Column
              columnKey="identityCode"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-building" />}
                  showAltIcon={columnDefs.identityCode.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.identityCode}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="vatCountryCode31"
                        defaultValue={"CPR / CVR"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="identityCode" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.identityCode || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "identityCode"
                      )}
                    />
                    {this.isValidCVR(contact.identityCode) && (
                      <>
                        &nbsp;
                        <AsyncButton
                          title={getText("axoEntityidcode257", "Hent CVR Data")}
                          onClick={() => this.onFetchCVR(contact)}
                        >
                          <Icon glyph="icon-fontello-download-alt" />
                        </AsyncButton>
                      </>
                    )}
                  </Cell>
                );
              }}
              width={columnDefs.identityCode.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.address.shown ? (
            <Column
              columnKey="address"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-location-4" />}
                  showAltIcon={columnDefs.address.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.address}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      {showAccountantHeaders ? (
                        <span>Adgang Skat</span>
                      ) : (
                        <AxoLocal
                          entity="SignupAdresse"
                          defaultValue={"Adresse"}
                        />
                      )}
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="address" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.address || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "address"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.address.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.postalCode.shown ? (
            <Column
              columnKey="postalCode"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-direction-1" />}
                  showAltIcon={columnDefs.postalCode.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.postalCode}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      {showAccountantHeaders ? (
                        <span>Adgang Bank</span>
                      ) : (
                        <AxoLocal
                          entity="CaseEditFormpostalCode"
                          defaultValue={"Postnummer"}
                        />
                      )}
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="postalCode" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.postalCode || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "postalCode"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.postalCode.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.city.shown ? (
            <Column
              columnKey="city"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-warehouse" />}
                  showAltIcon={columnDefs.city.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.city}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      {showAccountantHeaders ? (
                        <span>Bank</span>
                      ) : (
                        <AxoLocal
                          entity="CaseEditFormcity"
                          defaultValue={"By"}
                        />
                      )}
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="city" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.city || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "city"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.city.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.country.shown ? (
            <Column
              columnKey="country"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-globe-4" />}
                  showAltIcon={columnDefs.country.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.country}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      {showAccountantHeaders ? (
                        <span>
                          <AxoLocal
                            entity="axoEntityidcode146"
                            defaultValue={"Løn"}
                          />
                        </span>
                      ) : (
                        <AxoLocal
                          entity="CaseEditFormcountry"
                          defaultValue={"Land"}
                        />
                      )}
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="country" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    {showAccountantHeaders ? (
                      <InlineEdit
                        value={contact.country || "---"}
                        change={this.onChangeContactProperty.bind(
                          this,
                          contact.id,
                          "country"
                        )}
                      />
                    ) : (
                      <InlineCountrySelect
                        className="editable"
                        key={contact.id}
                        countryName={countryNamesMap[contact.country] || "---"}
                        valueType="short"
                        onChange={(val) => this.selectCountry(contact.id, val)}
                      />
                    )}
                  </Cell>
                );
              }}
              width={columnDefs.country.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.phone.shown ? (
            <Column
              columnKey="phone"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-phone" />}
                  showAltIcon={columnDefs.phone.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.phone}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      {showAccountantHeaders ? (
                        <span>
                          <AxoLocal
                            entity="AccountingTabViewEntity6"
                            defaultValue={"Årsregnskab"}
                          />{" "}
                        </span>
                      ) : (
                        <AxoLocal
                          entity="ContainerPhone"
                          defaultValue={"Telefon"}
                        />
                      )}
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="phone" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.phone || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "phone"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.phone.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.mobile.shown ? (
            <Column
              columnKey="mobile"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-mobile-4" />}
                  showAltIcon={columnDefs.mobile.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.mobile}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="socialSidebarContainerMobile"
                        defaultValue={"Mobile"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="mobile" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.mobile || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "mobile"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.mobile.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.eMail.shown ? (
            <Column
              columnKey="eMail"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-at-2" />}
                  showAltIcon={columnDefs.eMail.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.eMail}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="CaseEditFormeMail"
                        defaultValue={"Email"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="eMail" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.eMail || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "eMail"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.eMail.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.birthDate.shown ? (
            <Column
              columnKey="birthDate"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-user-outline" />}
                  showAltIcon={columnDefs.birthDate.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.birthDate}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="axoidcode161"
                        defaultValue={"Fødselsdag"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons
                        propertyName="birthDate"
                        filterType={FilterTypes.DATE}
                      />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                let birthDate =
                  this.state.cachedBirthDates[contact.id] || contact.birthDate;
                return (
                  <Cell {...props}>
                    <AxoDateTimeEdit
                      locale={locale}
                      date={moment.utc(birthDate)}
                      onChange={(newMoment) =>
                        this.updateBirthDate(contact.id, newMoment)
                      }
                    />
                    {/* <input type="date" style={{ border: 'none'}} 
                    onChange={event => {
                      let inputDate = event.target.valueAsDate;
                      if(!inputDate) {
                        return;
                      }
                      this.updateBirthDate(contact.id, inputDate);
                      // this.onChangeContactPropertyValue( contact.id, 'birthDate', inputDate);
                    }} 
                    value={moment(birthDate).format('YYYY-MM-DD')}
                  /> */}
                    {/* { moment(contact.creationDate).format('L') } */}
                  </Cell>
                );
              }}
              width={columnDefs.birthDate.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.customFieldOne.shown && userType === "Accountant" ? (
            <Column
              columnKey="customFieldOne"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-at-2" />}
                  showAltIcon={columnDefs.customFieldOne.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.customFieldOne}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="axoidcode106"
                        defaultValue={"Momsperiode"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons propertyName="customFieldOne" />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    <InlineEdit
                      value={contact.customFieldOne || "---"}
                      change={this.onChangeContactProperty.bind(
                        this,
                        contact.id,
                        "customFieldOne"
                      )}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.customFieldOne.width}
              isResizable={true}
            />
          ) : null}
          {columnDefs.creationDate.shown ? (
            <Column
              columnKey="creationDate"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-user-outline" />}
                  showAltIcon={columnDefs.creationDate.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.creationDate}
                >
                  <Flexbox>
                    <FlexElement>
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        key="creationDate"
                        entity="TimeEntryFormntimeEntry"
                        defaultValue={"Dato"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1} className="text-right">
                      <ColumnFilterButtons
                        propertyName="creationDate"
                        filterType={FilterTypes.DATE}
                      />
                    </FlexElement>
                  </Flexbox>
                </SortHeaderCell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                let creationDate =
                  this.state.cachedCreationDates[contact.id] ||
                  contact.creationDate;

                return (
                  <Cell {...props}>
                    <AxoDateTimeEdit
                      locale={locale}
                      date={moment.utc(creationDate)}
                      onChange={(newMoment) =>
                        this.updateCreationDate(contact.id, newMoment)
                      }
                    />
                    {/* <input type="date" style={{ border: 'none'}} 
                    onChange={event => {
                      let inputDate = event.target.valueAsDate;
                      if(!inputDate) {
                        return;
                      }
                      this.updateCreationDate( contact.id, inputDate);
                    }} 
                    value={moment(creationDate).format('YYYY-MM-DD')}
                  /> */}
                    {/* { moment(contact.creationDate).format('L') } */}
                  </Cell>
                );
              }}
              width={columnDefs.creationDate.width}
              isResizable={true}
            />
          ) : null}
          {!!userSettings &&
            !!userSettings.customContactFields &&
            userSettings.customContactFields
              .filter(
                (fieldName) =>
                  !!columnDefs[this.getCustomFieldFullName(fieldName)] &&
                  columnDefs[this.getCustomFieldFullName(fieldName)].shown
              )
              .map((fieldName) => {
                let fullFieldName = this.getCustomFieldFullName(fieldName);
                return (
                  <Column
                    key={fullFieldName}
                    columnKey={fullFieldName}
                    header={
                      <SortHeaderCell
                        // onSortChange={this._onSortChange}
                        onSortChange={(columnKey, sortDir) =>
                          this._onSortChange(
                            columnKey,
                            sortDir,
                            (left, right) => {
                              let lValue =
                                (left.customFieldValues || [])[fieldName] || "";
                              let rValue =
                                (right.customFieldValues || [])[fieldName] ||
                                "";

                              return lValue.localeCompare(rValue, "en", {
                                sensitivity: "base",
                              }); //Case insensitive compare
                            }
                          )
                        }
                        sortDir={colSortDirs[fullFieldName]}
                      >
                        <Flexbox>
                          <FlexElement>
                            <Icon
                              className="editable"
                              glyph="icon-fontello-arrow-combo"
                            />
                            &nbsp;
                          </FlexElement>
                          <FlexElement flexGrow={1}>{fieldName}</FlexElement>
                          <FlexElement flexGrow={1} className="text-right">
                            <ColumnFilterButtons
                              propertyName={fullFieldName}
                            />
                          </FlexElement>
                          <FlexElement>
                            <Icon
                              className="editable"
                              role="button"
                              onClick={() =>
                                this.onRemoveCustomColumn(fieldName)
                              }
                              glyph="icon-fontello-trash-1"
                            />
                            &nbsp;
                          </FlexElement>
                        </Flexbox>
                      </SortHeaderCell>
                    }
                    // header={<Cell>
                    //   <Flexbox>
                    //     <FlexElement flexGrow={1}>{fieldName}</FlexElement>
                    //     <FlexElement><Icon className='editable' role='button' onClick={() => this.onRemoveCustomColumn(fieldName)} glyph='icon-fontello-trash-1'/>&nbsp;</FlexElement>
                    //   </Flexbox>
                    // </Cell>}
                    cell={(props) => {
                      var contact = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={contact.id} {...props}>
                          <InlineEdit
                            value={
                              (contact.customFieldValues || {})[fieldName] ||
                              "---"
                            }
                            change={(event) =>
                              this.onChangeContactCustomPropertyValue(
                                contact.id,
                                fieldName,
                                event.value
                              )
                            }
                          />
                        </Cell>
                      );
                    }}
                    width={columnDefs[fullFieldName].width}
                    isResizable={true}
                  />
                );
              })}
          {columnDefs.labelCol.shown ? (
            <Column
              columnKey="labelCol"
              header={
                <Cell>
                  <AxoLocal
                    entity="clientselectedLabelb"
                    defaultValue={"Grupper"}
                  />
                </Cell>
              }
              cell={(props) => {
                var contact = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={contact.id} {...props}>
                    {this.renderLabelSelect(contact)}
                  </Cell>
                );
              }}
              width={columnDefs.labelCol.width}
              isResizable={true}
            />
          ) : null}
          <Column
            columnKey="actions"
            header={
              <Cell>
                <LexButton
                  title="Tilføj kolonne"
                  onClick={this.onAddCustomColumn}
                >
                  <Icon glyph="icon-fontello-plus" />
                </LexButton>
              </Cell>
            }
            cell={(props) => {
              var contact = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={contact.id} {...props}>
                  {deletingContactId === contact.id ? (
                    <img
                      alt=""
                      src="/imgs/app/loading.gif"
                      className="img-circle"
                      width="20"
                      height="20"
                    />
                  ) : (
                    <Icon
                      className="editable"
                      role="button"
                      onClick={this.onTrashContact.bind(this, contact.id)}
                      glyph="icon-fontello-trash-1"
                    />
                  )}
                  {/* <LexButton onClick={this.onDeleteAll}>Slet alle</LexButton> */}
                </Cell>
              );
            }}
            width={columnDefs.actions.width}
            isResizable={true}
          />
        </Table>
        <TextFilterModal ref={this.textFilterModal} />
        <DateFilterModal ref={this.dateFilterModal} />
        <NumberFilterModal ref={this.numberFilterModal} />
        <TextModal ref={this.textModal} title="Skriv overskrift" />
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
        <input
          ref={this.uploadAttachment}
          onChange={this.onAttachmentSelected}
          type="file"
          accept="image/*,.pdf"
          id="attachmentUpload"
          style={{ display: "none" }}
        />
      </div>
    );
  };
}

export default withRouter(
  Dimensions({
    elementResize: true,
    getHeight: function (element) {
      return window.innerHeight;
    },
  })(ContactTableView)
);
