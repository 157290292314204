import PresentationApiService from "./PresentationApiService";

let store = {
  presentations: [],
  presentationItems: [],
};

var subscribers = [];
function dispatch() {
  subscribers.forEach((subscription) => subscription.callback(store));
}

export default {
  subscribe(subscriberName, callback) {
    this.unsubscribe(subscriberName); //Clear existing
    subscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatch();
  },

  unsubscribe(subscriberName) {
    subscribers = subscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },

  //Presentations
  fetchPresentations() {
    return PresentationApiService.getPresentations().then(
      (presentationResponse) => {
        store.presentations = presentationResponse;
        dispatch();
      }
    );
  },

  fetchSinglePresentation(id) {
    return PresentationApiService.getSinglePresentation(id).then(
      (presentationResponse) => {
        this.updatePresentationList(presentationResponse);
      }
    );
  },

  updatePresentationList(presentation) {
    var current = store.presentations.find((c) => c.id === presentation.id);
    if (!!current) {
      store = {
        ...store,
        presentations: store.presentations.map((p) => {
          if (p.id !== presentation.id) {
            return p;
          }
          return presentation;
        }),
      };
    } else {
      store = {
        ...store,
        presentations: store.presentations.concat([presentation]),
      };
    }

    dispatch();
  },

  //Presentations
  fetchPresentationItems() {
    return PresentationApiService.getPresentationItems().then(
      (presentationItemResponse) => {
        store.presentationItems = presentationItemResponse;
        dispatch();
      }
    );
  },
};
