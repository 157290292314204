import React from "react";
import FlagMenu from "../utilities/FlagMenu";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { AxoLocal } from "../utilities/LexUtilities";

class QRCodeLayoutView extends React.PureComponent {
  //props
  //children
  renderNavBar = () => {
    return (
      <div>
        <div
          className="testbackground"
          style={{ position: "relative", zIndex: 100 }}
        >
          <div className="testtransbox text-center">
            <div style={{ height: "100%", display: "inline-block" }}>
              <FlagMenu
                className="Lex-button-2 AxoNavigationButtonheight"
                userSettings={{}}
              />{" "}
              &nbsp;&nbsp;&nbsp;
            </div>
            <span style={{ fontSize: "25px", color: " black" }}>
              <AxoLocal
                entity="axoAccounting45"
                defaultValue="Freeware QR kode generator"
              />
            </span>
          </div>
        </div>
      </div>
    );
  };
  onGotoFrontPage = () => {
    this.props.history.push("/freeware");
  };

  render() {
    return (
      <div>
        {this.renderNavBar()}

        {this.props.children}

        <div
          className="testbackground"
          style={{ position: "relative", zIndex: 100 }}
        >
          <div className="testtransbox text-center">
            <img alt="" height="120px" width="120px" src="/svg/AxolexQR.png" />{" "}
            &nbsp; &#169; &nbsp;&nbsp;
            <div style={{ height: "100%", display: "inline-block" }}>
              <FlagMenu
                className="Lex-button-2 AxoNavigationButtonfooter"
                dropup
                userSettings={{}}
              />{" "}
              &nbsp;&nbsp;&nbsp;
            </div>
            <Link to="/freeware" className="Axooverskrifttext">
              <AxoLocal
                entity="presentationMarketing34"
                defaultValue="Axolex IT-system"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(QRCodeLayoutView);
