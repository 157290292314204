import React, { useState } from "react";

import CanvasDraw from "react-canvas-draw";

import {
  // EasyIDView,
  Flexbox,
  LexButton,
  Icon,
  LoadingIcon,
  AxoLocal,
  getText,
} from "../utilities/LexUtilities";

import {
  Form,
  FormGroup,
  FormControl,
  InputGroup,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

// import NemIDLogo from "../resources/images/NemID.png";
import MitIDLogo from "../resources/images/MitID.png";
// import NemLogInLogo from "../resources/images/NemLogIn.png";

let signerName, setName;
let signerTitle, setTitle;
let onBehalfOf, setOnBehalfOf;
let isDrawing, setIsDrawing;
let signatureCanvas;
let signatureURL, setSignatureURL;

function SignatureForm(props) {
  [signerName, setName] = useState("");
  [signerTitle, setTitle] = useState("");
  [onBehalfOf, setOnBehalfOf] = useState("");
  [isDrawing, setIsDrawing] = useState("");
  [signatureURL, setSignatureURL] = useState("");

  let { onSubmit, onCancel, creatingSignature, allowImageSignature } = props;

  signatureCanvas = React.createRef();

  const onSubmitEasyID = () => {
    onClickSubmit("EasyID");
  };

  const onSubmitMitID = () => {
    onClickSubmit("MitID");
  };

  const onSubmitNemLogIn = () => {
    onClickSubmit("NemLogIn");
  };

  const onSubmitImage = () => {
    onClickSubmit("Image");
  };

  const onClickSubmit = (signatureType) => {
    if (!signerName) {
      return;
    }

    onSubmit({
      signerName,
      signerTitle,
      onBehalfOf,
      signatureType,
      signatureImageData: signatureURL.replace("data:image/png;base64,", ""),
    });
  };

  const onSaveImage = () => {
    // let data = signatureCanvas.current.getSaveData();

    setSignatureURL(
      signatureCanvas.current.canvasContainer.children[1].toDataURL()
    );
    setIsDrawing(false);
  };

  let hasWrittenSignature = signatureURL.length > 1250;

  return (
    <Form>
      <div className="mediumText">
        <AxoLocal
          entity="axoEntityidcode209"
          defaultValue={
            "Underskriften vil blive registreret i Axolex systemet, såfremt denne skal valideres i fremtiden."
          }
        />
      </div>
      <div className="mediumText">
        <AxoLocal
          entity="axoEntityidcode210"
          defaultValue={
            "Der vil blive gemt den nedenfor indtastede information, NemID signaturdata, samt IP adresse for underskriveren. Disse data vil være synlige for alle der besidder det underskrevne materiale."
          }
        />
      </div>
      <div className="mediumText">
        <AxoLocal
          entity="axoEntityidcode211"
          defaultValue={
            "Ved at skrive under giver du samtykke til at disse data kan gemmes."
          }
        />
      </div>
      <FormGroup controlId="name">
        <ControlLabel>
          <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />{" "}
          <span className="axored">*</span>{" "}
        </ControlLabel>
        <InputGroup>
          <InputGroup.Addon>
            <Icon glyph="icon-fontello-user" />
          </InputGroup.Addon>
          <FormControl
            autoFocus
            type="text"
            value={signerName}
            onChange={(event) => setName(event.target.value)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup controlId="title" title="Valgfri (f.eks. Direktør)">
        <ControlLabel>
          <AxoLocal entity="CalendarEventBoxFormtitle" defaultValue={"Titel"} />
        </ControlLabel>
        <InputGroup>
          <InputGroup.Addon>
            <Icon glyph="icon-fontello-user" />
          </InputGroup.Addon>
          <FormControl
            type="text"
            value={signerTitle}
            onChange={(event) => setTitle(event.target.value)}
            placeholder="Valgfri (f.eks. Direktør)"
          />
        </InputGroup>
      </FormGroup>
      <FormGroup controlId="behalf" title="Valgfri (f.eks. xxxx ApS)">
        <ControlLabel>
          <AxoLocal entity="axoEntityidcode212" defaultValue={"På vegne af"} />
        </ControlLabel>
        <InputGroup>
          <InputGroup.Addon>
            <Icon glyph="icon-fontello-user" />
          </InputGroup.Addon>
          <FormControl
            type="text"
            value={onBehalfOf}
            onChange={(event) => setOnBehalfOf(event.target.value)}
            placeholder="Valgfri (f.eks. xxxx ApS)"
          />
        </InputGroup>
      </FormGroup>
      <Flexbox justifyCenter>
        <ButtonToolbar>
          {/* <LexButton
            disabled={creatingSignature || !signerName}
            onClick={onSubmitEasyID}
          >
            <img alt="" src={NemIDLogo} width="90" height="20" />
            <LoadingIcon show={creatingSignature} />
          </LexButton> */}
          <LexButton
            disabled={creatingSignature || !signerName}
            onClick={onSubmitMitID}
          >
            <img alt="" src={MitIDLogo} width="90" height="20" />
            <LoadingIcon show={creatingSignature} />
          </LexButton>
          {/* <LexButton
            disabled={creatingSignature || !signerName}
            onClick={onSubmitNemLogIn}
          >
            <img alt="" src={NemLogInLogo} width="90" height="20" />
            <LoadingIcon show={creatingSignature} />
          </LexButton> */}
          {allowImageSignature && (
            <LexButton
              disabled={!signerName}
              onClick={() => setIsDrawing(true)}
              title={getText(
                "axoEntityidcode261",
                "Hvis du ikke har NemID, kan du tegne din underskrift i stedet"
              )}
            >
              <AxoLocal
                entity="axoEntityidcode213"
                defaultValue={"Tegn underskrift"}
              />
              <LoadingIcon show={creatingSignature} />
            </LexButton>
          )}
          <LexButton disabled={creatingSignature} onClick={onCancel}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </Flexbox>
      {hasWrittenSignature && !!signerName && (
        <div className="topPadding">
          <img src={signatureURL} alt="Signature" />
          <div className="topPadding leftPadding rightPadding">
            <LexButton
              title={getText(
                "axoEntityidcode217",
                "Dette vil gemme signaturen uden NemID signering"
              )}
              onClick={onSubmitImage}
              disabled={creatingSignature}
              style={{ width: "100%" }}
            >
              <AxoLocal
                entity="axoEntityidcode214"
                defaultValue={"Gem signatur endeligt"}
              />
              <LoadingIcon show={creatingSignature} />
            </LexButton>
          </div>
        </div>
      )}
      {isDrawing && (
        <div className="topPadding bottomPadding">
          <div style={{ border: "1px solid black" }}>
            <CanvasDraw
              ref={signatureCanvas}
              lazyRadius={1}
              brushRadius={1}
              canvasWidth={500}
              canvasHeight={100}
            />
          </div>
          <Flexbox justifyCenter className="topPadding">
            <ButtonToolbar>
              <LexButton onClick={onSaveImage}>
                <AxoLocal
                  entity="friendRequestTimelineonApproveRequest"
                  defaultValue={"Godkend"}
                />
              </LexButton>
              <LexButton onClick={() => signatureCanvas.current.undo()}>
                <AxoLocal entity="axoEntityidcode215" defaultValue={"Undo"} />
              </LexButton>
              <LexButton onClick={() => signatureCanvas.current.clear()}>
                <AxoLocal entity="axoEntityidcode216" defaultValue={"Ryd"} />
              </LexButton>
              <LexButton
                onClick={() => {
                  signatureCanvas.current.clear();
                  setIsDrawing(false);
                }}
              >
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </LexButton>
            </ButtonToolbar>
          </Flexbox>
        </div>
      )}
    </Form>
  );
}

export default SignatureForm;
