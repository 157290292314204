import React, { useEffect } from "react";
import AccountingLayoutContainer from "../Accounting/AccountingLayoutContainer";
import AccountingSimpleLayoutContainer from "../AccountingSimple/AccountingSimpleLayoutContainer";

import {
  withData,
  withRTKData,
  LoadingIndicator,
  AsyncButton,
  AxoLocal,
} from "../../utilities/LexUtilities";

import {
  ApiService,
  DataActions,
  DataStore,
  AuthorizationService,
} from "../../services/AxoServices";

const mapStoreToProps = (store) => {
  return {
    userProfile: store.userProfile,
    userSettings: store.userSettings,
  };
};

export default withRTKData(
  withData(
    React.memo(function MyAccountingContainer(props) {
      let { userProfile, userSettings, selectedContact, setSelectedContact } =
        props;

      useEffect(() => {
        DataStore.initializeUserProfile();
        DataStore.initializeUserSettings();
      }, []);

      let oldSelectedContact = null;
      useEffect(() => {
        if (
          userSettings.accountingClientId &&
          !!userSettings.accountingClient
        ) {
          oldSelectedContact = selectedContact;
          setSelectedContact(userSettings.accountingClient);
        }
        return () => {
          if (!!oldSelectedContact) {
            setSelectedContact(oldSelectedContact);
          }
        };
      }, [userSettings.accountingClientId]);

      const onCreateMyAccounting = async () => {
        let response = await ApiService.createContact({
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          country: userProfile.country,
          region: userProfile.region,
        });
        if (!response.ok) {
          return false;
        }
        let userClient = await response.json();
        response = await DataActions.updateUserSettings({
          ...userSettings,
          accountingClient: userClient,
          accountingClientId: userClient.id,
        });

        return response.ok;
      };

      if (!userSettings.id || !userProfile.id) {
        return <LoadingIndicator />;
      }

      if (!userSettings.accountingClientId) {
        return (
          <div className="leftPadding topPadding">
            <AsyncButton onClick={onCreateMyAccounting}>
              <AxoLocal
                entity="axoEntityidcode87"
                defaultValue={"Opret mit regnskab"}
              />
            </AsyncButton>
          </div>
        );
      }

      if (userSettings.accountingClientId !== selectedContact.id) {
        return <LoadingIndicator />;
      }

      let userType = AuthorizationService.getUserType(userProfile);

      if (userType === "Society") {
        return (
          <div>
            <AccountingSimpleLayoutContainer {...props} fixedClient />
          </div>
        );
      }

      return (
        <div>
          <AccountingLayoutContainer {...props} fixedClient />
        </div>
      );
    }),
    mapStoreToProps
  )
);
