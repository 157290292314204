import React from "react";
import moment from "moment";
import NumericInput from "react-numeric-input";

import Select from "react-select";

import {
  UserInfoService,
  TimeEntryService,
  AuthorizationService,
} from "../../services/AxoServices";

import {
  DocumentSearchBox,
  Icon,
  ctx,
  AxoLocal,
  ReactOption,
  AxoDateTime,
} from "../../utilities/LexUtilities";

import {
  Grid,
  Row,
  Col,
  Form,
  Button,
  ControlLabel,
  FormGroup,
  FormControl,
  InputGroup,
} from "react-bootstrap";

export default class TimeEntryBoxForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availableCases: props.cases || [],
      availableClients: props.clients || [],
    };
    this.timeEntryId = props.timeEntry.id;
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.timeEntryId !== nextProps.timeEntry.id ||
      (this.props.cases &&
        this.props.cases.length !== nextProps.cases.length) ||
      (this.props.clients &&
        this.props.clients.length !== nextProps.clients.length)
    ) {
      this.setState({
        availableCases: nextProps.cases,
        availableClients: nextProps.clients,
      });
      this.timeEntryId = nextProps.timeEntry.id;
    }
  }

  onChangeTimeEntry = (timeEntry) => {
    this.props.onTimeEntryUpdate(timeEntry);
  };

  onChangeWorkDate = (dateTime) => {
    this.onChangeProperty("workDate", dateTime.format());
  };

  onDescChange = (event) => {
    this.onChangeProperty("description", event.target.value);
  };

  onChangeRate = (valueAsNumber) => {
    this.onChangeProperty("rate", valueAsNumber);
  };

  onChangeTax = (valueAsNumber) => {
    this.onChangeProperty("tax", valueAsNumber / 100);
  };

  onChangeProperty = (propertyName, value) => {
    this.onChangeTimeEntry({
      ...this.props.timeEntry,
      [propertyName]: value,
    });
  };

  onChangeCase = (value) => {
    let { timeEntry, cases, clients } = this.props;
    let { availableClients } = this.state;

    let caseId = null;
    if (value !== "empty") {
      caseId = parseInt(value, 10);
      let selectedCase = cases.find((c) => c.id === caseId);
      availableClients = clients.filter((c) =>
        selectedCase.clients.find((cl) => cl.id === c.id)
      );
    } else {
      availableClients = clients;
    }
    timeEntry.caseModelId = caseId;
    this.onChangeTimeEntry(timeEntry);
    this.setState({
      availableClients: availableClients,
    });
  };

  onChangeClient = (value) => {
    let { timeEntry, cases } = this.props;
    let { availableCases } = this.state;
    var clientId = null;

    if (value !== "empty") {
      clientId = parseInt(value, 10);
      availableCases = cases.filter((c) =>
        c.clients.find((cl) => cl.id === clientId)
      );
    } else {
      availableCases = cases;
    }
    timeEntry.clientId = clientId;
    this.onChangeTimeEntry(timeEntry);
    this.setState({
      availableCases: availableCases,
    });
  };

  renderClientSelect = () => {
    let { clients, timeEntry } = this.props;
    let { availableClients } = this.state;
    if (!clients) {
      return null;
    }

    let clientOptions = availableClients.map((client) => {
      return {
        value: client.id,
        label: client.firstName + " " + client.lastName,
      };
    });

    return (
      <FormGroup controlId="clientSelect" bsSize="large">
        <ControlLabel
          style={{ textAlign: "left", fontSize: "16px", fontWeight: "normal" }}
        >
          <AxoLocal
            entity="TimeEntryBoxFormSelectclient"
            defaultValue={"Vælg en klient"}
          />
        </ControlLabel>
        <InputGroup>
          <InputGroup.Addon>
            <Icon glyph="icon-simple-line-icons-users" />
          </InputGroup.Addon>
          <div style={{ position: "relative", zIndex: 9 }}>
            <Select
              name="form-field-name"
              menuPlacement="auto"
              value={
                clientOptions.find((o) => o.value === timeEntry.clientId) || {
                  value: "",
                  label: ctx.getSync("TimeEntryBoxFormnoclientId"),
                }
              }
              onChange={(selectedClient) => {
                if (!!selectedClient) {
                  this.onChangeClient(selectedClient.value);
                }
              }}
              noOptionsMessage={() => ""}
              options={[
                { value: "", label: ctx.getSync("TimeEntryBoxFormnoclientId") },
                ...clientOptions,
              ]}
            />
          </div>
        </InputGroup>
      </FormGroup>
    );
  };

  renderCaseSelect = () => {
    let { timeEntry, cases, userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);
    let { availableCases } = this.state;

    if (userType === "Accountant" || !cases) {
      return null;
    }

    let caseOptions = availableCases.map((c) => {
      return { value: c.id, label: c.caseNumber };
    });

    return (
      <FormGroup controlId="clientSelect" bsSize="large">
        <ControlLabel
          style={{ textAlign: "left", fontSize: "16px", fontWeight: "normal" }}
        >
          <AxoLocal
            entity="TimeEntryBoxFormclientSelectCase"
            defaultValue={"og/eller vælg en sag"}
          />
        </ControlLabel>
        <InputGroup>
          <InputGroup.Addon>
            <Icon glyph="icon-simple-line-icons-briefcase" />
          </InputGroup.Addon>
          <div style={{ position: "relative", zIndex: 8 }}>
            <Select
              name="form-field-name"
              menuPlacement="auto"
              value={
                caseOptions.find((o) => o.value === timeEntry.caseModelId) || {
                  value: "",
                  label: ctx.getSync("TimeEntryBoxFormnoclientId"),
                }
              }
              onChange={(selectedEntry) => {
                if (!!selectedEntry) {
                  this.onChangeCase(selectedEntry.value);
                }
              }}
              noOptionsMessage={() => ""}
              options={[
                { value: "", label: ctx.getSync("TimeEntryBoxFormnoclientId") },
                ...caseOptions,
              ]}
            />
          </div>
        </InputGroup>
      </FormGroup>
    );
  };

  onChangeExpenseValue = (index, valueAsNumber) => {
    var timeEntry = Object.assign({}, this.props.expenses[index]);
    timeEntry.expenses = valueAsNumber;
    this.props.onExpenseUpdate(index, timeEntry);
  };

  onChangeAttachmentText = (index, event) => {
    var timeEntry = Object.assign({}, this.props.expenses[index]);
    timeEntry.description = event.target.value;
    this.props.onExpenseUpdate(index, timeEntry);
  };

  onAttachDocument = (index, document) => {
    var timeEntry = Object.assign({}, this.props.expenses[index]);
    timeEntry.expenseAttachment = document;
    this.props.onExpenseUpdate(index, timeEntry);
  };

  renderExpenses = () => {
    var expenses = this.props.expenses;
    var expenseNodes = [];
    for (var i = 0; i < expenses.length; i++) {
      var expense = expenses[i];
      expenseNodes.push(
        <div key={i}>
          {i === 0 ? (
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="TimeEntryFormExpensesLabel"
                defaultValue="Andre udgifter"
              />
            </ControlLabel>
          ) : null}
          <Grid fluid style={{ position: "relative", zIndex: 100 }}>
            {/* Fixes problem with dropdown being hidden behind other elements */}

            <Row>
              <Col md={3} lg={4} className="flexbox-standard nopadding">
                <div className="axoblack" style={{ marginRight: "10px" }}>
                  <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />
                </div>
                <NumericInput
                  className="form-control"
                  onChange={this.onChangeExpenseValue.bind(this, i)}
                  step={1}
                  precision={2}
                  value={expense.expenses}
                />
              </Col>
              <Col md={6} lg={4} className="nopadding">
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Addon>
                      <span className="axoblack">
                        <Icon glyph="icon-fontello-edit-3" />
                        &nbsp;&nbsp;{" "}
                        <AxoLocal
                          entity="Enhedspris6"
                          defaultValue={"Beskrivelse"}
                        />
                      </span>
                    </InputGroup.Addon>
                    <FormControl
                      type="text"
                      value={expense.description}
                      onChange={this.onChangeAttachmentText.bind(this, i)}
                    />
                    {i > 0 ? (
                      <InputGroup.Addon>
                        <span
                          className="Lex-button-2"
                          role="button"
                          onClick={this.props.onRemoveExpense.bind(this, i)}
                        >
                          &nbsp;&nbsp;&nbsp;
                          <Icon
                            className="axored"
                            role="button"
                            glyph="icon-fontello-scissors"
                          />
                        </span>
                      </InputGroup.Addon>
                    ) : null}
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={3} lg={4} className="nopaddingRight">
                <FormGroup>
                  <Icon glyph="icon-fontello-attach-7" />
                  &nbsp;
                  <div style={{ display: "inline-block" }}>
                    <AxoLocal
                      componentClass={DocumentSearchBox}
                      documentMap={this.props.documentMap}
                      handleSelectedDocument={this.onAttachDocument.bind(
                        this,
                        i
                      )}
                      maxWidth="100%"
                      componentAttribute="placeholder"
                      entity="TimeEntryFormAttachmentPlaceholder"
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    return expenseNodes;
  };

  getTimeEntriesTotal = () => {
    var timeEntryTotal = TimeEntryService.getTotalValue(this.props.timeEntry);
    var total =
      timeEntryTotal +
      this.props.expenses.reduce((sum, timeEntry) => {
        return sum + TimeEntryService.getTotalValue(timeEntry);
      }, 0);
    return total;
  };

  render() {
    var timeEntry = this.props.timeEntry;
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} sm={10} style={{ paddingTop: "20px" }}>
            <Form>
              <FormGroup controlId="workDate" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  <AxoLocal entity="axoidcode25" defaultValue={"Dato"} />:
                </ControlLabel>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-calendar-6" />
                  </InputGroup.Addon>
                  <AxoDateTime
                    id="workDate"
                    value={moment(timeEntry.workDate)}
                    dateFormat={true}
                    timeFormat={true}
                    onChange={this.onChangeWorkDate}
                  />
                </InputGroup>
              </FormGroup>
              {this.renderClientSelect()}
              {this.renderCaseSelect()}

              <FormGroup controlId="clientSelect" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="unitUnitType" defaultValue={"Enhedstype"} />
                </ControlLabel>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-simple-line-icons-users" />
                  </InputGroup.Addon>
                  <FormControl
                    componentClass="select"
                    value={timeEntry.unitType}
                    onChange={(event) =>
                      this.onChangeProperty("unitType", event.target.value)
                    }
                  >
                    <AxoLocal
                      componentClass={ReactOption}
                      value="Hours"
                      componentAttribute="text"
                      entity="unitHoursFullName"
                      defaultValue={"Timer"}
                    />
                    <AxoLocal
                      componentClass={ReactOption}
                      value="Units"
                      componentAttribute="text"
                      entity="unitUnitsFullName"
                      defaultValue={"Enheder"}
                    />
                  </FormControl>
                </InputGroup>
              </FormGroup>
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                {timeEntry.unitType === "Hours" ? (
                  <AxoLocal
                    key="hours"
                    entity="unitHoursFullName"
                    defaultValue={"Timer"}
                  />
                ) : (
                  <AxoLocal
                    key="units"
                    entity="unitUnitsFullName"
                    defaultValue={"Antal enheder"}
                  />
                )}
              </ControlLabel>
              <NumericInput
                className="form-control"
                onChange={(value) => {
                  this.onChangeProperty("units", value);
                }}
                step={1}
                precision={2}
                value={timeEntry.units}
              />

              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                  paddingTop: "10px",
                }}
              >
                <Icon glyph="icon-fontello-money-1" />
                &nbsp;
                {timeEntry.unitType === "Hours" ? (
                  <span>
                    <AxoLocal
                      key="hours"
                      entity="unitHoursRate"
                      defaultValue={"Timepris"}
                    />
                    &nbsp;({UserInfoService.getCurrency(this.props.userProfile)}
                    /
                    <AxoLocal
                      key="hour"
                      entity="unitHoursSingular"
                      defaultValue={"time"}
                    />
                    )
                  </span>
                ) : (
                  <span>
                    <AxoLocal
                      key="units"
                      entity="CalendarEventFormRate"
                      defaultValue={"Pris / enhed"}
                    />
                    &nbsp;({UserInfoService.getCurrency(this.props.userProfile)}
                    /
                    <AxoLocal
                      key="unit"
                      entity="TimeEntriesViewTimeUnit"
                      defaultValue={"unit"}
                    />
                    )
                  </span>
                )}
              </ControlLabel>
              <NumericInput
                className="form-control"
                onChange={this.onChangeRate}
                step={1}
                precision={2}
                value={timeEntry.rate}
              />

              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                  paddingTop: "10px",
                }}
              >
                <Icon glyph="icon-simple-line-icons-pie-chart" />
                &nbsp;
                <AxoLocal
                  entity="CalendarEventFormVAT"
                  defaultValue={"Moms i %"}
                />
              </ControlLabel>
              <NumericInput
                className="form-control"
                onChange={this.onChangeTax}
                step={1}
                precision={2}
                value={timeEntry.tax * 100}
              />
              <br />
              {this.renderExpenses()}
              <Button
                onClick={this.props.onAddExpense}
                className="Lex-button-2 lg"
              >
                <Icon
                  role="button"
                  glyph="icon-fontello-plus-3"
                  className="Addicon"
                />
                &nbsp;
                <AxoLocal
                  entity="TimeEntryFormAddExpense"
                  defaultValue="Tilføj udgift"
                />
              </Button>
              <br />
              <br />
              <FormGroup controlId="inputWithIcon" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal
                    entity="CalendarEventFormTotal"
                    defaultValue={"Samlet værdi i"}
                  />
                  &nbsp;{UserInfoService.getCurrency(this.props.userProfile)}.
                </ControlLabel>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-math" />
                  </InputGroup.Addon>
                  <FormControl
                    type="number"
                    disabled
                    step="1"
                    value={this.getTimeEntriesTotal().toFixed(2)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
                </ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={timeEntry.description}
                  onChange={this.onDescChange}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Grid>
    );
  }
}
