import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import NumericInput from "react-numeric-input";
import { AxoLocal, Icon } from "../../utilities/LexUtilities";

import {
  Grid,
  Row,
  Col,
  Form,
  Button,
  ButtonToolbar,
  ControlLabel,
  FormGroup,
  FormControl,
} from "react-bootstrap";
export default class CreateSubscriptionFormulaView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formula: this.makeNewFormula(),
      showSuccessMessage: false,
      showFailureMessage: false,
    };
  }

  makeNewFormula = () => {
    return {
      minUsers: 0,
      maxUsers: 5,
      monthlyPrice: 10000,
      currency: "USD",
      yearDiscount: 0,
    };
  };

  onReset = () => {
    this.setState({
      formula: this.makeNewFormula(),
    });
  };

  onCreateFormula = () => {
    var { formula } = this.state;

    DataActions.createSubscriptionFormula(formula)
      .then((response) => {
        if (response.ok) {
          this.setState({
            formula: this.makeNewFormula(),
            showSuccessMessage: true,
          });
          setTimeout(() => {
            this.setState({ showSuccessMessage: false });
          }, 3000);
        } else {
          return Promise.reject("Could not create formula");
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ showFailureMessage: true });
        setTimeout(() => {
          this.setState({ showFailureMessage: false });
        }, 3000);
      });
  };

  onPropertyChange = (propertyName, event) => {
    var formula = Object.assign({}, this.state.formula);
    formula[propertyName] = event.target.value;
    this.setState({ formula });
  };

  onChangeAmount = (valueAsNumber) => {
    var formula = Object.assign({}, this.state.formula);
    formula.monthlyPrice = valueAsNumber * 100;
    this.setState({ formula });
  };

  onChangeNumberProperty = (propertyName, valueAsNumber) => {
    var formula = Object.assign({}, this.state.formula);
    formula[propertyName] = valueAsNumber;
    this.setState({ formula });
  };

  getCurrencyOptions = () => {
    var currencies = ["DKK", "USD", "GBP", "EUR", "CNY", "SEK", "NKK", "ISK"];
    return currencies.map((currency) => {
      return (
        <option key={currency} value={currency}>
          {currency}
        </option>
      );
    });
  };

  getTypeOptions = () => {
    var types = ["Standard", "IT", "Custom"];
    return types.map((type) => {
      return (
        <option key={type} value={type}>
          {type}
        </option>
      );
    });
  };

  render() {
    var { formula } = this.state;
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} smOffset={1} sm={10} style={{ paddingTop: "20px" }}>
            <div className="text-center">
              <h3>
                <AxoLocal
                  entity="AdminTabViewabonnementsformel1"
                  defaultValue="Opret ny abonnementsformel"
                />
              </h3>
            </div>
            <Form>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal
                    entity="AdminTabViewabonnementsformel2"
                    defaultValue="Min. antal brugere"
                  />
                </ControlLabel>
                <NumericInput
                  className="form-control"
                  onChange={this.onChangeNumberProperty.bind(this, "minUsers")}
                  step={1}
                  precision={0}
                  value={formula.minUsers}
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal
                    entity="AdminTabViewabonnementsformel3"
                    defaultValue=" Max. antal brugere"
                  />
                </ControlLabel>
                <NumericInput
                  className="form-control"
                  onChange={this.onChangeNumberProperty.bind(this, "maxUsers")}
                  step={1}
                  precision={0}
                  value={formula.maxUsers}
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal
                    entity="AdminTabViewabonnementsformel4"
                    defaultValue="Månedspris"
                  />
                </ControlLabel>
                <NumericInput
                  className="form-control"
                  onChange={this.onChangeAmount}
                  step={1}
                  precision={2}
                  value={formula.monthlyPrice / 100}
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="AdminTabView7" defaultValue="Valuta" />
                </ControlLabel>
                <FormControl
                  componentClass="select"
                  value={formula.currency}
                  onChange={this.onPropertyChange.bind(this, "currency")}
                >
                  {this.getCurrencyOptions()}
                </FormControl>
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal
                    entity="AdminTabViewabonnementsformel5"
                    defaultValue=" Årsrabat (%)"
                  />
                </ControlLabel>
                <NumericInput
                  className="form-control"
                  onChange={this.onChangeNumberProperty.bind(
                    this,
                    "yearDiscount"
                  )}
                  step={1}
                  precision={0}
                  value={formula.yearDiscount}
                />
              </FormGroup>
            </Form>
            <br />
            <div
              className="text-center"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <ButtonToolbar style={{ display: "inline-block" }}>
                <Button
                  className="Lex-button-2 "
                  onClick={this.onCreateFormula}
                >
                  <AxoLocal entity="ADDViewCreate" defaultValue="Opret" />
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button className="Lex-button-2 " onClick={this.onReset}>
                  <AxoLocal entity="AdminTabView16" defaultValue="Nulstil" />
                </Button>
              </ButtonToolbar>
            </div>
            {this.state.showSuccessMessage ? (
              <div className="text-center axoblue">
                <h3>
                  {" "}
                  <AxoLocal
                    entity="AdminTabViewabonnementsformel6"
                    defaultValue="Formlen blev oprettet"
                  />{" "}
                </h3>
              </div>
            ) : null}
            {this.state.showFailureMessage ? (
              <div className="text-center axored">
                <Icon glyph="icon-fontello-info-3" />
                &nbsp;&nbsp;
                <h3>
                  <AxoLocal
                    entity="AdminTabView18"
                    defaultValue="Der skete en fejl. Prøv igen senere."
                  />
                </h3>
              </div>
            ) : null}
          </Col>
        </Row>
      </Grid>
    );
  }
}
