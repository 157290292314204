import React from "react";
import ReactDOM from "react-dom";
import ApiService from "../../services/DataAccess/ApiService";
import DataActions from "../../services/DataAccess/DataActions";
import PrintService from "../../services/PrintService";
import UserInfoService from "../../services/UserInfoService";
import { ctx } from "../../utilities/L20n/L20n";

import { Button, ButtonGroup, ButtonToolbar, Modal } from "react-bootstrap";

import { Icon, PrintLayout, AxoLocal } from "../../utilities/LexUtilities";

export default class PrintCaseModal extends React.PureComponent {
  //Props
  //caseModel
  constructor(props) {
    super(props);
    this.state = {
      activeContactIndex: 0,
      isGeneratingPDF: false,
      showGeneratePDFError: false,
      generatedPDF: false,
      isFetchingHTML: false,
    };
    this.selectedLabels = [];
  }

  //Props
  //onSelectLabel
  //labelType
  //onCreateLabel
  //onGoToLabels
  close = () => {
    this.setState({ showModal: false });
  };

  open = (caseModel, userProfile) => {
    this.setState({
      caseModel: caseModel,
      caseContacts: caseModel.clients.concat(caseModel.counterParties),
      userProfile: userProfile,
      showModal: true,
    });
  };

  onOK = () => {
    this.close();
  };

  onPrint = () => {
    if (typeof window !== "undefined") {
      PrintService.printElement(document.getElementById("print-modal"));
    }
  };

  getActiveContact = () => {
    if (this.state.caseContacts.length > this.state.activeContactIndex) {
      return this.state.caseContacts[this.state.activeContactIndex];
    }
    return null;
  };

  getClientAddress = (client) => {
    return client
      ? client.firstName +
          " " +
          client.lastName +
          "\n" +
          client.address +
          "\n" +
          client.postalCode +
          "\n" +
          client.city
      : "";
  };

  getInfoWithBreak = (info) => {
    if (!info) {
      return "";
    }
    return info + "\n";
  };

  getUserAddress = (userProfile) => {
    const getInfoWithBreak = (info) => {
      if (!info) {
        return "";
      }
      return info + "\n";
    };

    const getInfoWithComma = (info) => {
      if (!info) {
        return "";
      }
      return info + ", ";
    };

    return (
      getInfoWithBreak(userProfile.company) +
      getInfoWithBreak(userProfile.address) +
      getInfoWithComma(userProfile.city) +
      getInfoWithBreak(
        ctx.getSync("CountryNames", { countrySlug: userProfile.country })
      ) +
      getInfoWithBreak(userProfile.phone) +
      getInfoWithBreak(userProfile.email)
    );
  };

  renderGenerateButtonContent = () => {
    if (this.state.isGeneratingPDF) {
      return (
        <span>
          <AxoLocal
            entity="DocumentTemplateGeneraisGenerating"
            defaultValue={"Genererer..."}
          />{" "}
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </span>
      );
    } else {
      return (
        <span>
          <Icon glyph="icon-fontello-acrobat" style={{ fontSize: "20px" }} />
          &nbsp;&nbsp;
          <AxoLocal
            entity="DocumentTemplateEditView"
            defaultValue={"Generer til PDF"}
          />{" "}
          {this.state.generatedPDF ? <Icon glyph="icon-fontello-ok-3" /> : ""}
        </span>
      );
    }
  };

  onGeneratePdf = () => {
    this.setState({ isFetchingHTML: true }, () => {
      var caseModel = Object.assign({}, this.state.caseModel);
      caseModel.innerHTML = this.getCaseModelHtml();
      this.setState({ isFetchingHTML: false }, () => {
        this.doGeneratePdf(caseModel);
      });
    });
  };

  getCaseModelHtml = () => {
    var caseModelNode = ReactDOM.findDOMNode(this.printLayout).innerHTML;
    return (
      `<!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <meta http-equiv="X-UA-Compatible" content="IE=Edge,chrome=1">
          <title>AxoLex</title>
          <link rel='stylesheet' href='/css/main.css' />
        </head>
        <body data-version="4.0.0">` +
      caseModelNode +
      `</body>
      </html>`
    );
  };

  doGeneratePdf = (caseModel) => {
    this.setState({ isGeneratingPDF: true });
    return ApiService.updateCase(caseModel)
      .then((response) => {
        if (response.ok) {
          return DataActions.generateCasePdf(caseModel.id);
        }
        return Promise.reject("Invoice could not be updated.");
      })
      .then((response) => {
        this.setState({ isGeneratingPDF: false });
        if (response.ok) {
          return response.json();
        }
        return Promise.reject("Invoice could not be printed.");
      })
      .then((newPDF) => {
        this.setState({ generatedPDF: true });
        // setTimeout(() => {
        //   this.setState( { generatedPDF: false } );
        // }, 1500);
        this.props.onGeneratedPdf(newPDF);
      })
      .catch((reason) => {
        this.showGeneratePDFError();
      });
  };

  showGeneratePDFError = () => {
    this.setState({
      isGeneratingPDF: false,
      isGeneratingAndSending: false,
      showGeneratePDFError: true,
    });
    setTimeout(() => {
      this.setState({ showGeneratePDFError: false });
    }, 5000);
  };

  render() {
    var { caseModel, userProfile } = this.state;
    var { contactMap } = this.props;

    if (!caseModel || !userProfile) {
      return null;
    }

    var caseClient = this.getActiveContact();
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.close}
        bsSize={this.props.size}
      >
        <Modal.Header
          className="backgroundModalHeaderFooter"
          closeButton
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          <Modal.Title>
            <div className="flexbox-layout">
              <div>
                <Icon glyph="icon-fontello-print-3" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseEditInlinePrintCaseB1"
                  defaultValue={"PDF Layout"}
                />
              </div>
              {/* <div style={{ paddingRight: '15px' }}>
                <Button className='Lex-button-2' onClick={this.print}>
                  <Icon glyph="icon-fontello-print-3"/>&nbsp;&nbsp;
                  <AxoLocal entity='PrintCaseClientadressePrint'defaultValue={'Print'}/>
                </Button>
              </div> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          <PrintLayout
            ref={(r) => (this.printLayout = r)}
            headline={
              ctx.getSync("ContainercaseNumber") + " " + caseModel.caseNumber
            }
            leftAddress={this.getClientAddress(caseClient)}
            rightAddress={this.getUserAddress(userProfile)}
            imageSrc={UserInfoService.getLogoImageSource(userProfile)}
            contentHeader={ctx.getSync("CaseEditFormCaseFacts")}
            content={caseModel.description}
            contactMap={contactMap}
            readOnly={this.state.isFetchingHTML}
          />
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.onPrint}>
                <span>
                  <Icon glyph="icon-fontello-print-3" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="PrintCaseClientadressePrint"
                    defaultValue={"Print"}
                  />
                </span>
              </Button>
              <Button className="Lex-button-2" onClick={this.onGeneratePdf}>
                {this.renderGenerateButtonContent()}
              </Button>
              <Button className="Lex-button-2" onClick={this.close}>
                <span>
                  {" "}
                  <Icon glyph="icon-fontello-cancel" />
                  &nbsp;&nbsp;
                  <AxoLocal entity="Docfileclose" defaultValue={"Luk"} />{" "}
                </span>
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
