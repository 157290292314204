import ApiService from "./DataAccess/ApiService";

let store = {
  publicPosts: [],
  internalPosts: [],
  individualPosts: [],
  currentUserPosts: [],
  privateConversations: [],
  currentUserId: 0,
  socialPostsShown: 10,
};

var publicPostSubscribers = [];
function dispatchPublicPosts() {
  publicPostSubscribers.forEach((subscription) =>
    subscription.callback(store.publicPosts)
  );
}

var internalPostSubscribers = [];
function dispatchInternalPosts() {
  internalPostSubscribers.forEach((subscription) =>
    subscription.callback(store.internalPosts)
  );
}

var currentUserPostSubscribers = [];
function dispatchCurrentUserPosts() {
  currentUserPostSubscribers.forEach((subscription) =>
    subscription.callback(store.currentUserPosts)
  );
}

var storeSubscribers = [];
function dispatch() {
  storeSubscribers.forEach((subscription) => subscription.callback(store));
}

export default {
  setNumberOfShownPosts(amount) {
    store.socialPostsShown = amount;
    this.fetchSocialPosts();
  },

  removeArrayObject(id, typeName) {
    this.setState({
      [typeName]: store[typeName].filter((o) => o.id !== id),
    });
  },

  updateArrayObject(newObject, arrayName) {
    let currentObject = store[arrayName].find((o) => o.id === newObject.id);

    //Update or insert
    if (!!currentObject) {
      this.setState({
        [arrayName]: store[arrayName].map((entry) => {
          if (entry.id !== newObject.id) {
            return entry;
          }
          return newObject;
        }),
      });
    } else {
      this.setState({
        [arrayName]: [newObject, ...store[arrayName]],
      });
    }
  },

  setState(newProperties) {
    store = {
      ...store,
      ...newProperties,
    };
    dispatch();
  },

  fetchSocialPosts() {
    var promises = [];

    promises.push(this.fetchPublicPosts());
    promises.push(this.fetchInternalPosts());
    promises.push(this.fetchPrivateConversations());
    promises.push(this.fetchCurrentUserPosts());

    return Promise.all(promises);
  },

  //Public posts
  fetchPublicPosts() {
    return ApiService.getPublicPosts(store.socialPostsShown).then(
      (postResponse) => {
        store.publicPosts = postResponse;
        dispatchPublicPosts();
        return postResponse;
      }
    );
  },

  subscribeToPublicPosts(subscriberName, callback) {
    publicPostSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatchPublicPosts();
  },

  unsubscribeFromPublicPosts(subscriberName) {
    publicPostSubscribers = publicPostSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },

  //Internal posts
  fetchInternalPosts() {
    return ApiService.getInternalPosts(store.socialPostsShown).then(
      (postResponse) => {
        store.internalPosts = postResponse;
        dispatchInternalPosts();
        return postResponse;
      }
    );
  },

  subscribeToInternalPosts(subscriberName, callback) {
    internalPostSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatchInternalPosts();
  },

  unsubscribeFromInternalPosts(subscriberName) {
    internalPostSubscribers = internalPostSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },

  //Individual posts
  fetchIndividualPosts(userProfileId) {
    return ApiService.getIndividualPosts(
      userProfileId,
      store.socialPostsShown
    ).then((postResponse) => {
      store.individualPosts = postResponse;
      dispatch();
      return postResponse;
    });
  },

  //Current user posts
  fetchCurrentUserPosts() {
    return ApiService.getIndividualPosts(
      store.currentUserId,
      store.socialPostsShown
    ).then((postResponse) => {
      store.currentUserPosts = postResponse;
      dispatchCurrentUserPosts();
      return postResponse;
    });
  },

  subscribeToCurrentUserPosts(subscriberName, userId, callback) {
    store.currentUserId = userId;
    currentUserPostSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatchCurrentUserPosts();
  },

  unsubscribeFromCurrentUserPosts(subscriberName) {
    currentUserPostSubscribers = currentUserPostSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },

  //Private conversations
  fetchPrivateConversations() {
    return ApiService.getPrivateConversations().then((postResponse) => {
      store.privateConversations = postResponse;
      dispatch();
      return postResponse;
    });
  },

  subscribe(subscriberName, callback) {
    storeSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatch();
  },

  unsubscribe(subscriberName) {
    storeSubscribers = storeSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },
};
