import React, { useState } from "react";

import {
  FormGroup,
  // InputGroup,
  FormControl,
  ButtonToolbar,
  ControlLabel,
} from "react-bootstrap";

import {
  // Icon,
  Flexbox,
  // getText,
  AsyncButton,
  LexButton,
  AxoLocal,
  AxoDateTime,
} from "../../utilities/LexUtilities";

import moment from "moment";

export default function CaseTimeEntryForm(props) {
  let { onSubmit, onCancel } = props;

  const [units, setUnits] = useState(0);
  const [workDate, setWorkDate] = useState(moment.utc().format());
  const [description, setDescription] = useState("");

  async function onSubmitForm() {
    return onSubmit({
      workDate,
      units,
      description,
    });
  }

  // let hourLabel = getText('unitHoursShortName', 't');

  return (
    <div>
      <Flexbox>
        <FormGroup controlId="workTime" className="rightPadding">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Arbejdstidspunkt
          </ControlLabel>
          <AxoDateTime
            value={moment.utc(workDate)}
            dateFormat={true}
            timeFormat={true}
            utc
            onChange={(dateTime) => setWorkDate(moment.utc(dateTime).format())}
          />
        </FormGroup>
        <FormGroup controlId="units" className="rightPadding">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Timer (01:30 => 1,5)
          </ControlLabel>
          <FormControl
            type="number"
            className="borderFormControlfocus"
            value={isNaN(units) ? "" : units}
            onChange={(event) => setUnits(event.target.valueAsNumber)}
            placeholder="0.00"
          />
        </FormGroup>
      </Flexbox>
      <FormGroup controlId="description">
        <FormControl
          componentClass="textarea"
          className="borderFormControlfocus"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          placeholder="Noter..."
        />
      </FormGroup>
      <ButtonToolbar>
        <AsyncButton onClick={onSubmitForm}>
          <AxoLocal entity="CaseGridViewReactOption" defaultValue={"Tilføj"} />
        </AsyncButton>
        <LexButton onClick={onCancel}>
          <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
        </LexButton>
      </ButtonToolbar>
    </div>
  );
}
