import React from "react";
import QRCodeLayoutView from "./QRCodeLayoutView";
import QRCodeView from "./QRCodeView";

import { DataStore } from "../services/AxoServices";

import { LoadingIndicator, Consumer } from "../utilities/LexUtilities";

export default class QRCodeContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.fetchGlobalSettings();
  }

  render() {
    return (
      <Consumer>
        {(store) => {
          if (!store.loaded || !store.globalSettings.theme) {
            return <LoadingIndicator />;
          }
          return (
            <QRCodeLayoutView>
              <QRCodeView />
            </QRCodeLayoutView>
          );
        }}
      </Consumer>
    );
  }
}
