import React, { useState } from "react";

import { ApiService } from "../services/AxoServices";

import {
  // EasyIDView,
  AxoLocal,
  // LexButton,
  UploadButton,
  Link,
  Icon,
} from "../utilities/LexUtilities";
import { Col, Grid, Row } from "react-bootstrap";

import { SmallOrSmaller, MediumOrLarger } from "../utilities/Responsive";

import moment from "moment";

import NemIDLogo from "../resources/images/NemID.png";
import MitIDLogo from "../resources/images/MitID.png";

let documentStatus, setDocumentStatus;
let showWarning, setShowWarning;

function ValidationContent(props) {
  let { documentStatus, showWarning, validateFile } = props;

  if (showWarning) {
    return (
      <div className="standardMaxWidth center-block">
        <h3>
          <AxoLocal
            entity="CaseDataTableFixedFilename"
            defaultValue={"Filnavn"}
          />
        </h3>
        <div className="axored">
          <AxoLocal
            entity="axoEntityidcode102"
            defaultValue={"Dokumentet kunne ikke indlæses."}
          />
        </div>
        <div className="axored">
          <AxoLocal
            entity="axoEntityidcode103"
            defaultValue={
              "Der er ikke blevet foretaget nogen validering af dokumentet."
            }
          />
        </div>
        <div className="topPadding">
          <UploadButton onSelected={validateFile}>
            <AxoLocal
              entity="axoEntityidcode100"
              defaultValue={"Upload nyt dokument til validering"}
            />
          </UploadButton>
        </div>
      </div>
    );
  }

  if (!!documentStatus) {
    if (!documentStatus.valid) {
      return (
        <div className="standardMaxWidth center-block">
          <h3 className="signaturesover3">
            <AxoLocal
              entity="axoEntityidcode101"
              defaultValue={"Status på dokument"}
            />
          </h3>
          <div className="signaturesover3">
            <AxoLocal
              entity="CaseDataTableFixedFilename"
              defaultValue={"Filnavn"}
            />
            : {documentStatus.fileName}
          </div>
          <div className="axored">
            <AxoLocal
              entity="axoEntityidcode103b"
              defaultValue={
                "Filen er ikke et gyldigt Axolex signaturdokument. Den indlejrede signaturdata kunne ikke findes."
              }
            />
          </div>
          <div className="topPadding">
            <UploadButton onSelected={validateFile}>
              <AxoLocal
                entity="axoEntityidcode104"
                defaultValue={"Upload nyt dokument til validering"}
              />
            </UploadButton>
          </div>
        </div>
      );
    }
    return (
      <div className="standardMaxWidth center-block">
        <h3 className="signaturesover3">
          <AxoLocal
            entity="axoEntityidcode101"
            defaultValue={"Status på dokument"}
          />
        </h3>
        <div className="signaturesover3">
          <AxoLocal
            entity="CaseDataTableFixedFilename"
            defaultValue={"Filnavn"}
          />
          : {documentStatus.fileName}
        </div>
        <div className="signaturesover3">
          <AxoLocal
            key="fileKey"
            entity="axoEntityidcode105"
            defaultValue={"Filnøgle"}
          />
          : {documentStatus.documentKey}
        </div>
        <div className="signaturesover4">
          <AxoLocal
            entity="axoEntityidcode106a"
            defaultValue={"Filen er et gyldigt Axolex signaturdokument."}
          />
        </div>
        <div className="topPadding">
          <h3>
            <AxoLocal entity="axoEntityidcode106" defaultValue={"Signaturer"} />
          </h3>
          {documentStatus.signatures.map((signature, index) => (
            <div key={signature.signerName + index} className="bottomPadding">
              { signature.type === "EasyID" && (
                <div className="signaturesover3 bottomPadding">
                  <img alt="" src={NemIDLogo} width="90" height="20" />
                </div>           
              )}
              { signature.type === "MitID" && (
                <div className="signaturesover3 bottomPadding">
                  <img alt="" src={MitIDLogo} width="90" height="20" />
                </div>           
              )}
              <div className="signaturesover3">
                <strong>{signature.signerName}</strong>
              </div>
              {!!signature.signerTitle && (
                <div className="signaturesover3">{signature.signerTitle}</div>
              )}
              {!!signature.onBehalfOf && (
                <div className="signaturesover3">{signature.onBehalfOf}</div>
              )}
              {!!signature.commonName && (
                <div className="signaturesover3">
                  <AxoLocal
                    entity="axoEntityidcode107"
                    defaultValue={"NemId navn"}
                  />
                  : {signature.commonName}
                </div>
              )}
              {!!signature.serialNumber && (
                <div className="signaturesover3">
                  {signature.type === "EasyID" ? (
                    <AxoLocal
                      entity="axoEntityidcode108"
                      defaultValue={"NemId serienummer"}
                    />
                  ): "MitID UUID"}
                  : {signature.serialNumber}
                </div>
              )}
              {!!signature.ipAddress && (
                <div className="signaturesover3">
                  <AxoLocal
                    entity="axoEntityidcode109"
                    defaultValue={"IP Adresse"}
                  />
                  : {signature.ipAddress}
                </div>
              )}
              <div className="signaturesover3">
                {moment.utc(signature.date).format("LLL")} UTC
              </div>
              {signature.valid ? (
                <>
                  {signature.type === "EasyID" && (
                    <>
                      {signature.certificateValid ? (
                        <div className="signaturesover4">
                          <AxoLocal
                            entity="axoEntityidcode110"
                            defaultValue={"NemID certifikatet er valideret."}
                          />
                        </div>
                      ) : (
                        <div className="axored">
                          <AxoLocal
                            entity="axoEntityidcode111"
                            defaultValue={
                              "Certifikatet var udløbet på signeringsdatoen."
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                  {signature.type === "MitID" && (
                    <>
                      {signature.certificateValid ? (
                        <div className="signaturesover4">
                          MitID signatur valideret.
                        </div>
                      ) : (
                        <div className="axored">
                          MitID signaturen er ikke gyldig.
                        </div>
                      )}
                    </>
                  )}
                  {signature.type === "Image" && (
                    <div>
                      <img
                        src={
                          "data:image/png;base64," +
                          signature.signatureImageData
                        }
                        alt="Signature"
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="axored">
                  <AxoLocal
                    entity="axoEntityidcode112"
                    defaultValue={"Signaturen kunne ikke valideres."}
                  />
                </div>
              )}
              {!!signature.signatureByteData && (
                <div>
                  { signature.type === "EasyID" && (
                    <a
                      href={`data:application/text;base64,${signature.signatureByteData}`}
                      download={`${signature.commonName}.xml`}
                    >
                      <AxoLocal
                        entity="axoEntityidcode244"
                        defaultValue={"Download signaturdata"}
                      />
                    </a>
                  )}
                  { (signature.type === "MitID" || signature.type === "NemLogIn") && (
                    <a
                      href={`data:application/text;base64,${signature.signatureByteData}`}
                      download={`${signature.serialNumber}.pdf`}
                    >
                      <AxoLocal
                        entity="axoEntityidcode244"
                        defaultValue={"Download signaturdata"}
                      />
                    </a>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="topPadding">
          <UploadButton onSelected={validateFile}>
            <AxoLocal
              entity="axoEntityidcode113"
              defaultValue={"Upload nyt dokument til validering."}
            />
          </UploadButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <h3>
        <AxoLocal
          entity="axoEntityidcode114"
          defaultValue={"Valider dit Axolex dokument her"}
        />
      </h3>
      <div>
        <UploadButton onSelected={validateFile}>
          <AxoLocal
            entity="axoEntityidcode115"
            defaultValue={"Upload dokument til validering"}
          />
        </UploadButton>
      </div>
    </>
  );
}

function ValidateSignedDocumentView(props) {
  [documentStatus, setDocumentStatus] = useState(null);
  [showWarning, setShowWarning] = useState(false);

  const validateFile = async (file) => {
    let response = await ApiService.uploadToCustomPath(
      [file],
      "/api/EasyID/validateSignature"
    );
    if (!response.ok) {
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 5000);
      return false;
    }

    let statusData = await response.json();
    setDocumentStatus(statusData);
  };

  return (
    <div style={{ paddingTop: "10px" }}>
      <div
        className="frontPageaxolexdiv Bannecolorlogin flexbox-center"
        style={{ height: "130px" }}
      >
        Axolex Digitalt Signatursystem &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-md"
          src="/imgs/app/Axologo3-30x30.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-md hidden-xs"
          src="/imgs/app/Axologo3-35x35.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        <img
          alt=""
          className="hidden-md hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <FlagMenu
                  // className='Lex-button-2'
                  userSettings={{}}
                /> */}
      </div>
      <div
        className="flexbox-right center-block"
        style={{ maxWidth: "700px", fontSize: "16px", height: "50px" }}
      >
        <b>
          <div className="MenueasyID" role="button">
            <Link to="/EasyID">&nbsp;&nbsp;Forside</Link>
          </div>
        </b>
        &nbsp;&nbsp;
        <b>
          <div className="MenueasyID" role="button">
            <Link to="/ValidateSignature"> &nbsp;&nbsp;Validering</Link>
          </div>{" "}
        </b>
        &nbsp;&nbsp;
      </div>
      <div className="standardMaxWidth center-block minHeightside">
        <ValidationContent
          documentStatus={documentStatus}
          showWarning={showWarning}
          validateFile={validateFile}
        />
      </div>
      <div>
        <Grid fluid className="Axolex-Menu-lg text-center">
          <Row>
            <Col sm={10} smOffset={1} xs={12} className="text-center nopadding">
              <Col sm={4} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px", marginLeft: "10px" }}
                >
                  <div>
                    <h3>
                      <AxoLocal
                        entity="FrontPageindextaxt11"
                        defaultValue={"Find os på"}
                      />
                    </h3>
                  </div>
                  <div>
                    <a
                      href="https://www.facebook.com/Axolex.dk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-facebook-squared"
                        style={{ fontSize: " 30px" }}
                      />{" "}
                      <AxoLocal
                        entity="ContactCreateViewFacebook"
                        defaultValue={"Facebook"}
                      />
                    </a>
                  </div>
                  {/* <div><span><Icon className='axolexwebsideicon' glyph='icon-fontello-linkedin-squared' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactCreateViewLinkedIn'defaultValue={'LinkedIn'}/></span></div> */}
                  <div>
                    {" "}
                    <a
                      href="https://twitter.com/AxolexApS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-twitter-squared"
                        style={{ fontSize: " 30px" }}
                      />{" "}
                      <AxoLocal
                        entity="ContactCreateViewTwitter"
                        defaultValue={"Twitter"}
                      />{" "}
                    </a>
                  </div>
                  {/* <div> <span><Icon className='axolexwebsideicon' glyph='icon-fontello-chat-1' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactWeChat'defaultValue={'WeChat'}/> </span></div> */}
                  <div style={{ fontSize: "22px" }}>© &ensp;&ensp;&ensp;</div>
                  <div className="text-left " role="button">
                    <div>
                      <img
                        alt=""
                        height="200px"
                        width="200px"
                        src="/svg/Axologo1-014.png"
                      />
                    </div>
                  </div>
                  <div>
                    {/* <LexButton onClick={() => { this.props.history.push('freeware')}}>
                        <AxoLocal entity='axolexQRcode17' defaultValue={'Benyt vores freeware produkter'}/>
                        </LexButton> */}
                  </div>
                </div>
              </Col>
              <Col sm={3} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="FrontPageviewtext11"
                        defaultValue={"Vi tilbyder"}
                      />{" "}
                    </h3>
                  </div>
                  <div>MitID signering</div>
                  <div>Systemintegration</div>
                  <div>PAdES PDF generering</div>
                  <div>Tegnet signatur</div>
                  <div>
                    <Link to="/ValidateSignature">Validering</Link>
                  </div>
                </div>
              </Col>
              <Col sm={3} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="FrontPageviewtext12"
                        defaultValue={"Du kan vælge"}
                      />
                    </h3>
                  </div>
                  <div>Konto hos Axolex</div>
                  <div>Systemintegration med jeres system.</div>
                  <div>Fleksible priser.</div>
                  <div>Abonnementsløsning.</div>
                </div>
              </Col>
              <Col sm={2} xs={12} className="flexbox-center nopadding">
                <div
                  className="text-left frontPageaxolexdivtextbox"
                  style={{ width: "400px" }}
                >
                  <div>
                    <h3>
                      {" "}
                      <AxoLocal
                        entity="Companyaboutostaxt11"
                        defaultValue={"Axolex ApS"}
                      />
                    </h3>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
                    :
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    Strandvejen 60
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    2900 Hellerup
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    Denmark
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />
                    :53780153
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <AxoLocal
                      entity="CaseEditFormeMail"
                      defaultValue={"Email"}
                    />
                    :{" "}
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:info@axolex.com">info@axolex.com</a>{" "}
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:it@axolex.com">it@axolex.com</a>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:legal@axolex.com">legal@axolex.com</a>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:support@axolex.com">support@axolex.com</a>
                  </div>
                  <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                    <a href="mailto:job@axolex.com">job@axolex.com</a>{" "}
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Grid>

        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="image-banner">
                <div className="banner-container">
                  <div className="banner-item">
                    <MediumOrLarger>
                      <img
                        alt=""
                        className="img-responsive bg_img"
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex3-lg.jpg"
                      />
                    </MediumOrLarger>
                    <SmallOrSmaller>
                      <img
                        alt=""
                        className="img-responsive bg_img"
                        style={{ maxWidth: "100%" }}
                        src="/svg/Axolex3-xs.jpg"
                      />
                    </SmallOrSmaller>
                    <div className="text_wrapper">
                      <div className="text_position chbannetaxt">
                        <h2 className="item--title">
                          <AxoLocal
                            entity="Companyaboutostaxt11"
                            defaultValue={"Axolex"}
                          />
                        </h2>
                        <p className="item--text">
                          <AxoLocal
                            entity="Companyaboutostaxt12"
                            defaultValue={" Komplet juridisk IT-løsning"}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
}

export default ValidateSignedDocumentView;
