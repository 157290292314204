import React, { useEffect } from 'react';
import { ApiService } from '../services/AxoServices';
import LexButton from '../utilities/LexButton';
import { Grid } from 'react-bootstrap';

const HandleCardTransactionView = (props) => {
  const handleUpdate = async () => {
    let response = await ApiService.handleCardTransaction();
    if(!response.ok) {
      return;
    }
    let responseData = await response.json();
    if(!responseData.role) {
      return;
    }

    redirectByRole(responseData.role);
  }

  const redirectByRole = (role) => {
    var { history } = props;

    if (role === "Lawyer" || role === "Accountant" || role === "Admin") {
      history.push("/Dashboard/Setup");
    } else if (
      role === "Client" ||
      role === "AccountingClient" ||
      role === "SocietyMember" ||
      role === "EasyID"
    ) {
      history.push("/ClientDashboard/Setup");
    } else if (role === "XLink") {
      history.push("/Xlink");
    } else if (role === "Society") {
      history.push("/Dashboard/Setup");
    }

    history.push("/Dashboard/Setup");
  };

  const handleButtonClick = () =>
    handleUpdate()

  useEffect(() => {
    handleUpdate();
  }, []);

  return (
    <Grid fluid>
      <h1>Transaction  approved. Please wait</h1>
      <LexButton onClick={handleButtonClick}>
        Click here if you are not redirected
      </LexButton>
    </Grid>
  );
};

export default HandleCardTransactionView;