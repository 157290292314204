import React from "react";
import Solutions from "./Solutions";
import LocalizationService from "../services/LocalizationService";

export default class SolutionsContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      locale: "enus",
    };
  }

  componentDidMount() {
    LocalizationService.subscribeToLocale("SolutionsContainer", (locale) => {
      this.setState({ locale: locale.toLowerCase() });
    });
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale("SolutionsContainer");
  }

  render() {
    var { locale } = this.state;
    return <Solutions locale={locale} />;
  }
}
