import React from "react";

import { Button, ButtonGroup, ButtonToolbar, Modal } from "react-bootstrap";

import { AxoLocal, Flexbox } from "../../utilities/LexUtilities";

import EasyIDSignatureViewV2 from "../EasyID/EasyIDSignatureViewV2";

export default class EasyIDSigningModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      onSubmit: null,
    };
  }

  close = () => {
    this.setState({
      showModal: false,
      onSubmit: null,
    });

    //Necessary to reset the Easy ID session
    window.location.reload();
  };

  open = (onSubmit) => {
    this.setState({
      showModal: true,
      onSubmit,
    });
  };

  onSubmit = (signature) => {
    let { onSubmit } = this.state;

    if (!!onSubmit) {
      onSubmit(signature);
    }

    this.setState({
      showModal: false,
      onSubmit: null,
    });
  };

  render() {
    let { guid, onSubmit, small } = this.props;
    let { showModal } = this.state;

    return (
      <Modal
        show={this.state.showModal}
        onHide={this.close}
        bsSize={small ? "small" : "large"}
      >
        <Modal.Header className="backgroundModalHeaderFooter" closeButton>
          <Modal.Title className="text-center">Signér dokument med NemID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flexbox justifyCenter>
            {showModal && (
              <EasyIDSignatureViewV2
                key={onSubmit}
                onSubmit={this.onSubmit}
                guid={guid}
                small={small}
              />
            )}
          </Flexbox>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.close}>
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
