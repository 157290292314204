import React from "react";
import PropTypes from "prop-types";

import LexButton from "./LexButton";
import LoadingIcon from "./LoadingIcon";
import Icon from "./Icon";

let ProcessingStatus = {
  Idle: 0,
  Processing: 1,
  Error: 2,
  Success: 3,
};

export default class AsyncButton extends React.PureComponent {
  //onClick must return a boolean value
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    hideOkIcon: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      status: ProcessingStatus.Idle,
    };
    this.timeout = null;
    this.isCancelled = false;
  }

  processFunction = async () => {
    let { onClick, hideOkIcon } = this.props;

    this.setState({ status: ProcessingStatus.Processing });

    try {
      let result = await onClick();
      if (this.isCancelled) {
        return;
      }

      if (!result) {
        throw new Error("Function failed to execute successfully.");
      }

      this.setState({
        status: hideOkIcon ? ProcessingStatus.Idle : ProcessingStatus.Success,
      });
      if (!hideOkIcon) {
        this.timeout = setTimeout(() => {
          this.setState({ status: ProcessingStatus.Idle });
        }, 3000);
      }
    } catch (error) {
      if (this.isCancelled) {
        return;
      }

      console.log(error.message);
      this.setState({ status: ProcessingStatus.Error });
      this.timeout = setTimeout(() => {
        this.setState({ status: ProcessingStatus.Idle });
      }, 3000);
    }
  };

  componentWillUnmount() {
    this.isCancelled = true;
    if (!!this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    let { hideOkIcon } = this.props;
    let { status } = this.state;

    let normalProps = {
      ...this.props,
    };

    delete normalProps.hideOkIcon;

    return (
      <LexButton
        {...normalProps}
        onClick={this.processFunction}
        disabled={status !== ProcessingStatus.Idle || this.props.disabled}
      >
        {this.props.children}
        <LoadingIcon show={status === ProcessingStatus.Processing} />
        {!hideOkIcon && status === ProcessingStatus.Success ? (
          <span>
            &nbsp;
            <Icon glyph="icon-fontello-ok-3" />
          </span>
        ) : null}
        {status === ProcessingStatus.Error ? (
          <span>
            &nbsp;
            <Icon glyph="icon-fontello-error" />
          </span>
        ) : null}
      </LexButton>
    );
  }
}
