import React from "react";
import { withRouter } from "react-router-dom";

import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import {
  ApiService,
  DataStore,
  // RoutingService
} from "../services/AxoServices";

import {
  Icon,
  AlertModal,
  AxoLocal,
  LoadingIndicator,
  FlagMenu,
  AxoFooter,
} from "../utilities/LexUtilities";

import QueryString from "query-string";

const EmailConfirmationProgress = {
  pending: 0,
  confirmed: 1,
  failed: 2,
};

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);

    let query = QueryString.parse(props.location.search);
    this.state = {
      resetPasswordViewModel: {
        userName: query.userName || "",
        password: "",
        confirmPassword: "",
        userId: query.userId,
        code: query.code,
        confirmEmailCode: query.confirmEmailCode,
        emailConfirmationProgress: EmailConfirmationProgress.pending,
      },
      invalidPassword: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    let { userId, confirmEmailCode } = this.state.resetPasswordViewModel;
    if (!!confirmEmailCode) {
      ApiService.finishConfirmEmail({ userId, code: confirmEmailCode })
        .then((response) => {
          if (response.ok) {
            this.setState({
              resetPasswordViewModel: {
                ...this.state.resetPasswordViewModel,
                emailConfirmationProgress: EmailConfirmationProgress.confirmed,
              },
            });
          } else {
            return Promise.reject("Email could not be confirmed.");
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.setState({
            resetPasswordViewModel: {
              ...this.state.resetPasswordViewModel,
              emailConfirmationProgress: EmailConfirmationProgress.failed,
            },
          });
        });
    }
  }

  onModelPropertyChange = (propertyName, event) => {
    let { resetPasswordViewModel } = this.state;
    this.setState({
      resetPasswordViewModel: {
        ...resetPasswordViewModel,
        [propertyName]: event.target.value,
      },
    });
  };

  redirectByRole = (role) => {
    var { history } = this.props;

    DataStore.resetStore();

    if (role === "Lawyer" || role === "Accountant" || role === "Admin") {
      history.push("/Dashboard");
    } else if (
      role === "Client" ||
      role === "AccountingClient" ||
      role === "SocietyMember" ||
      role === "EasyID"
    ) {
      history.push("/ClientDashboard");
    } else if (role === "XLink") {
      history.push("/Xlink");
    } else if (role === "Society") {
      history.push("/Dashboard");
    } else {
      history.push("/Dashboard");
    }
  };

  onUpdate = () => {
    var model = this.state.resetPasswordViewModel;
    if (!model.userName || model.confirmPassword !== model.password) {
      return;
    }
    ApiService.resetPassword(model).then((response) => {
      if (response.ok) {
        response.json().then((body) => {
          this.redirectByRole(body.role);
        });
        // this.props.history.push(RoutingService.getAccountPath('ResetPasswordConfirmation'));
      } else {
        response
          .json()
          .then((message) => {
            if (message.error === "InvalidPassword") {
              this.setState({ invalidPassword: true });
            } else {
              this.alertModal.open(
                <AxoLocal
                  entity="ResetPasswordConfirmationInvalidPassword"
                  defaultValue={"Der skete en fejl. Prøv igen senere."}
                />
              );
            }
          })
          .catch((reason) => {
            this.alertModal.open(
              <AxoLocal
                entity="ResetPasswordConfirmationInvalidPassword"
                defaultValue={"Der skete en fejl. Prøv igen senere."}
              />
            );
            console.log(reason);
          });
      }
    });
  };

  getPasswordValidationState = () => {
    var password = this.state.resetPasswordViewModel.password;
    if (!password) {
      return null;
    }
    return password.length >= 4 ? "success" : "error";
  };

  getConfirmPasswordValidationState = () => {
    let confirmPassword = this.state.resetPasswordViewModel.confirmPassword;
    if (!confirmPassword) {
      return null;
    }
    return confirmPassword === this.state.resetPasswordViewModel.password
      ? "success"
      : "error";
  };

  render() {
    let { confirmEmailCode, emailConfirmationProgress } =
      this.state.resetPasswordViewModel;

    if (!!confirmEmailCode) {
      if (emailConfirmationProgress === EmailConfirmationProgress.pending) {
        return (
          <div style={{ position: "relative" }}>
            <LoadingIndicator />
          </div>
        );
      } else if (
        emailConfirmationProgress === EmailConfirmationProgress.failed
      ) {
        return (
          <div className="text-center" style={{ paddingTop: "10px" }}>
            <div>
              <p>
                <h4>
                  <AxoLocal
                    entity="ConfirmedEmailError"
                    defaultValue={
                      "Din email kunne ikke bekræftes. Tjeck at linket er korrekt."
                    }
                  />
                </h4>
              </p>
            </div>
          </div>
        );
      }
    }

    var model = this.state.resetPasswordViewModel;
    return (
      <div className="login SideHeight" style={{ paddingTop: "25px" }}>
        <div id="auth-row">
          <div id="auth-cell">
            <Grid fluid>
              <Row>
                <Col xs={12} className="nopadding" style={{ maxWidth: "100%" }}>
                  <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center">
                    <AxoLocal
                      entity="ResetPasswordresetPassword"
                      defaultValue={"Opdater kodeord."}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img
                      alt=""
                      className="hidden-lg hidden-sm hidden-md"
                      src="/imgs/app/Axologo3-30x30.png"
                    />
                    <img
                      alt=""
                      className="hidden-lg hidden-md hidden-xs"
                      src="/imgs/app/Axologo3-35x35.png"
                    />
                    <img
                      alt=""
                      className="hidden-lg hidden-sm hidden-xs"
                      src="/imgs/app/Axologo3-40x40.png"
                    />
                    <img
                      alt=""
                      className="hidden-md hidden-sm hidden-xs"
                      src="/imgs/app/Axologo3-40x40.png"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FlagMenu
                      // className='Lex-button-2'
                      userSettings={{}}
                    />
                  </div>
                </Col>

                <Col
                  md={6}
                  mdOffset={3}
                  sm={8}
                  smOffset={2}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%px", marginTop: "25px" }}
                >
                  <div>
                    <div>
                      <div style={{ padding: 0 }}>
                        <div>
                          <div
                            className="text-center"
                            style={{ padding: 12.5 }}
                          >
                            <AxoLocal
                              entity="ResetPasswordUpdatepassword"
                              defaultValue={"Opdater dit kodeord."}
                            />
                          </div>
                          <div
                            style={{
                              padding: 25,
                              paddingTop: 0,
                              paddingBottom: 0,
                              margin: "auto",
                              marginBottom: 25,
                              marginTop: 25,
                            }}
                          >
                            <Form>
                              <FormGroup controlId="UserName">
                                <InputGroup bsSize="large">
                                  <InputGroup.Addon>
                                    <Icon glyph="icon-fontello-mail" />
                                  </InputGroup.Addon>
                                  <AxoLocal
                                    componentClass={FormControl}
                                    autoFocus
                                    type="text"
                                    className="icon-fontello-user"
                                    value={model.userName}
                                    onChange={this.onModelPropertyChange.bind(
                                      this,
                                      "userName"
                                    )}
                                    componentAttribute="placeholder"
                                    entity="bccMailplaceholderBrugernavn"
                                  />
                                </InputGroup>
                              </FormGroup>
                              <FormGroup
                                validationState={this.getPasswordValidationState()}
                                controlId="Password"
                              >
                                <InputGroup bsSize="large">
                                  <InputGroup.Addon>
                                    <Icon glyph="icon-fontello-key" />
                                  </InputGroup.Addon>
                                  <AxoLocal
                                    componentClass={FormControl}
                                    type="password"
                                    className="borderFormControlfocus"
                                    value={model.password}
                                    onChange={this.onModelPropertyChange.bind(
                                      this,
                                      "password"
                                    )}
                                    componentAttribute="placeholder"
                                    entity="bccMailplaceholderpassword"
                                  />
                                </InputGroup>
                                {this.state.invalidPassword ? (
                                  <div style={{ color: "red" }}>
                                    <AxoLocal
                                      entity="ResetPasswordinvalidPassword"
                                      defaultValue={
                                        "Kodeordet skal indeholde mindst fire tegn."
                                      }
                                    />
                                  </div>
                                ) : null}
                              </FormGroup>

                              <FormGroup
                                validationState={this.getConfirmPasswordValidationState()}
                                controlId="ConfirmPassword"
                              >
                                <InputGroup bsSize="large">
                                  <InputGroup.Addon>
                                    <Icon glyph="icon-fontello-key" />
                                  </InputGroup.Addon>
                                  <AxoLocal
                                    componentClass={FormControl}
                                    type="password"
                                    className="borderFormControlfocus"
                                    value={model.confirmPassword}
                                    onChange={this.onModelPropertyChange.bind(
                                      this,
                                      "confirmPassword"
                                    )}
                                    componentAttribute="placeholder"
                                    entity="placeholderConfirmpassword"
                                  />
                                </InputGroup>
                              </FormGroup>

                              <FormGroup>
                                <Grid fluid>
                                  <Row>
                                    <Col
                                      xs={6}
                                      className="nopadding"
                                      style={{ paddingTop: 10 }}
                                    ></Col>
                                    <Col
                                      xs={6}
                                      className="text-center nopadding"
                                    >
                                      <Button
                                        className="Lex-button-2"
                                        style={{
                                          height: "65px",
                                          width: "250px",
                                          fontSize: "17px",
                                          fontWeight: "normal",
                                        }}
                                        onClick={this.onUpdate}
                                      >
                                        <span>
                                          {" "}
                                          <Icon glyph="icon-fontello-ccw-1" />
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          {/* MissingEntity */}
                                          <AxoLocal
                                            entity="ResetPasswordRefresh"
                                            defaultValue={"Opdater"}
                                          />{" "}
                                          og log ind
                                        </span>{" "}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Grid>
                              </FormGroup>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <AlertModal ref={(c) => (this.alertModal = c)} />
        <AxoFooter />
      </div>
    );
  }
}

export default withRouter(ResetPassword);
