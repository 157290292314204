import React from "react";
import CaseEditForm from "./CaseEditForm";
// import CaseEditInline from './CaseEditInline'
import CaseListPage from "./CaseListPage";
import CaseDataTableFixed from "./CaseDataTableFixed";
import Conditional from "../../utilities/Conditional";
import CaseSharingTable from "./CaseSharingTable";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import { DataStore } from "../../services/AxoServices";
import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";

import {
  LexNavButton,
  AxoNavButton,
  Icon,
  AxoLocal,
} from "../../utilities/LexUtilities";

export default class CaseTabView extends React.PureComponent {
  renderLargeScreenNavigationBar = () => {
    let { showDropdowns, activeTab } = this.props;

    return (
      <Large>
        <Grid fluid className="axo-nav-lg" id="noprint">
          {!showDropdowns ? (
            <Row>
              <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{
                    borderRadius: "0px",
                    paddingTop: "0px",
                    paddingLeft: "2px",
                  }}
                >
                  <LexNavButton
                    className="axonavTab axonavTabcolora"
                    ls
                    glyph="icon-fontello-th-thumb-empty"
                    eventKey="List"
                    activeTab={activeTab}
                    onClick={this.props.handleActiveState}
                  >
                    <AxoLocal
                      entity="CaseContainercaseSummary"
                      defaultValue={"Sagsoversigt"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorb"
                    ls
                    glyph="icon-fontello-lifebuoy-1"
                    eventKey="Sharings"
                    activeTab={activeTab}
                    onClick={this.props.handleActiveState}
                  >
                    <AxoLocal
                      entity="Gruppeinformation18"
                      defaultValue={"Delinger"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorc "
                    ls
                    glyph="icon-fontello-plus-3"
                    eventKey="Create"
                    activeTab={activeTab}
                    onClick={this.props.handleActiveState}
                  >
                    <AxoLocal
                      entity="CaseContainercaseCreateacase"
                      defaultValue={"Opret sag"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolord"
                    ls
                    glyph="icon-fontello-doc-text"
                    eventKey="Draft"
                    activeTab={activeTab}
                    onClick={this.props.handleActiveState}
                  >
                    <AxoLocal
                      entity="DocumentGridViewshareddraft"
                      defaultValue={"Udkast"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolore"
                    ls
                    glyph="icon-fontello-calendar-empty"
                    eventKey="Week"
                    activeTab={activeTab}
                    onClick={this.props.handleActiveState}
                  >
                    <AxoLocal
                      entity="CaseContainerThisweek"
                      defaultValue={"Denne uge"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorf"
                    ls
                    glyph="icon-fontello-calendar-6"
                    eventKey="Month"
                    activeTab={activeTab}
                    onClick={this.props.handleActiveState}
                  >
                    <AxoLocal
                      entity="CaseContainerThismonth"
                      defaultValue={"Denne måned"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorg"
                    ls
                    glyph="icon-fontello-lock-2"
                    eventKey="Closed"
                    activeTab={activeTab}
                    onClick={this.props.handleActiveState}
                  >
                    <AxoLocal
                      entity="CaseContainercompleted"
                      defaultValue={"Afsluttede"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorh"
                    ls
                    glyph="icon-fontello-trash-1"
                    eventKey="Trash"
                    activeTab={activeTab}
                    onClick={this.props.handleActiveState}
                  >
                    <AxoLocal
                      entity="DocumentTabViewBin"
                      defaultValue={"Skraldespand"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    ls
                    listButton
                    className="axonavTab showListButton"
                    onClick={() => {
                      DataStore.setShowDropDowns(true);
                    }}
                  >
                    <div className="flexbox-center">
                      <Icon
                        glyph="icon-fontello-align-justify"
                        style={{ fontSize: "30px" }}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity66"
                        defaultValue={"Liste Menu"}
                      />
                    </div>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab axonavTabcolora AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.props.handleActiveState("List")}
                >
                  <span className="icon-fontello-th-thumb-empty" /> &nbsp;&nbsp;{" "}
                  <AxoLocal
                    entity="CaseContainercaseSummary"
                    defaultValue={"Sagsoversigt"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.props.handleActiveState("Sharings")}
                >
                  <span className="icon-fontello-lifebuoy-1" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="Gruppeinformation18"
                    defaultValue={"Delinger"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.props.handleActiveState("Create")}
                >
                  <span className="icon-fontello-plus-3 Addicon" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseContainercaseCreateacase"
                    defaultValue={"Opret sag"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.props.handleActiveState("Draft")}
                >
                  <span className="icon-fontello-doc-text" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="DocumentGridViewshareddraft"
                    defaultValue={"Udkast"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.props.handleActiveState("Week")}
                >
                  <span className="icon-fontello-calendar-empty" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseContainerThisweek"
                    defaultValue={"Denne uge"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.props.handleActiveState("Month")}
                >
                  <span className="icon-fontello-calendar-6" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseContainerThismonth"
                    defaultValue={"Denne måned"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.props.handleActiveState("Closed")}
                >
                  <span className="icon-fontello-lock-2" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseContainercompleted"
                    defaultValue={"Afsluttede"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.props.handleActiveState("Trash")}
                >
                  <span className="icon-fontello-trash-1" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="DocumentTabViewBin"
                    defaultValue={"Skraldespand"}
                  />
                </MenuItem>
              </DropdownButton>
            </Row>
          )}
          {this.renderCaseNavNodes(true)}
        </Grid>
      </Large>
    );
  };

  renderCaseNavNodes = (largeScreen) => {
    var { activeTab, caseMap, openCases, sharedCases } = this.props;
    var number = openCases.length;
    var lexNavButtons = openCases.map((caseId) => {
      var currentCase =
        caseMap.cases.find((c) => c.id === caseId) ||
        sharedCases.find((c) => c.id === caseId);

      if(!currentCase) {
        return null;
      }

      return (
        <AxoNavButton
          className="axonavTab"
          btnWidth={number === 1 ? "50%" : null}
          key={caseId}
          ls={largeScreen}
          eventKey={this.props.getCaseKey(caseId)}
          activeTab={activeTab}
          onClick={this.props.handleActiveState}
        >
          <div
            style={{
              display: "inline-block",
              width: "50%",
              textAlign: "right",
            }}
          >
            {currentCase.caseNumber}&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div
            style={{
              display: "inline-block",
              width: "50%",
              textAlign: "right",
            }}
          >
            <Icon
              onClick={this.props.onRemoveCaseTab.bind(this, caseId)}
              glyph="icon-fontello-cancel"
              style={{ textAlign: "right" }}
            />
            &nbsp;&nbsp;&nbsp;
          </div>
        </AxoNavButton>
      );
    });
    var rows = [];
    var columnNo = largeScreen ? 6 : 3;
    for (var i = 0; i < lexNavButtons.length; i = i + columnNo) {
      rows.push(
        <Row key={i}>
          <Col xs={12} className="nopadding flexbutton">
            <div
              className="btn-group btn-group-justified"
              role="group"
              style={{ borderRadius: "0px", paddingTop: "0px" }}
            >
              {this.arrangeLexNavButtons(lexNavButtons, i, columnNo)}
            </div>
          </Col>
        </Row>
      );
    }
    return rows;
  };

  arrangeLexNavButtons = (lexNavButtons, currentIndex, columnNo) => {
    var columns = [];
    for (
      var i = 0;
      i < columnNo && currentIndex + i < lexNavButtons.length;
      i++
    ) {
      columns.push(lexNavButtons[currentIndex + i]);
    }
    return columns;
  };

  renderSmallScreenNavigationBar = () => {
    var { expanded } = this.props;

    return (
      <MediumOrSmaller>
        <Grid fluid className="axo-nav" id="noprint">
          <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab axonavTabcolora AxotabDropdownmenu"
                onClick={() => this.props.handleActiveState("List")}
              >
                <span className="icon-fontello-th-thumb-empty" /> &nbsp;&nbsp;{" "}
                <AxoLocal
                  entity="CaseContainercaseSummary"
                  defaultValue={"Sagsoversigt"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.props.handleActiveState("Sharings")}
              >
                <span className="icon-fontello-lifebuoy-1" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="Gruppeinformation18"
                  defaultValue={"Delinger"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.props.handleActiveState("Create")}
              >
                <span className="icon-fontello-plus-3 Addicon" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseContainercaseCreateacase"
                  defaultValue={"Opret sag"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.props.handleActiveState("Draft")}
              >
                <span className="icon-fontello-doc-text" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="DocumentGridViewshareddraft"
                  defaultValue={"Udkast"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.props.handleActiveState("Week")}
              >
                <span className="icon-fontello-calendar-empty" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseContainerThisweek"
                  defaultValue={"Denne uge"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.props.handleActiveState("Month")}
              >
                <span className="icon-fontello-calendar-6" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseContainerThismonth"
                  defaultValue={"Denne måned"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.props.handleActiveState("Closed")}
              >
                <span className="icon-fontello-lock-2" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseContainercompleted"
                  defaultValue={"Afsluttede"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.props.handleActiveState("Trash")}
              >
                <span className="icon-fontello-trash-1" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="DocumentTabViewBin"
                  defaultValue={"Skraldespand"}
                />
              </MenuItem>
            </DropdownButton>
          </Row>
          {expanded ? this.renderCaseNavNodes(false) : null}
        </Grid>
      </MediumOrSmaller>
    );
  };

  renderNavigationBar = () => {
    return (
      <div className="Tabbanne">
        {this.renderLargeScreenNavigationBar()}
        {this.renderSmallScreenNavigationBar()}
      </div>
    );
  };

  CaseTableWrapper = (props) => {
    var { cases, count, onToggleSharedCasesDetails } = props;

    let { setCaseQueryText, caseQueryText } = this.props;

    return (
      <CaseDataTableFixed
        cases={cases}
        count={count}
        clients={this.props.contactMap.contacts}
        forceShowCards={this.props.userSettings.showCaseCards}
        onToggleCards={this.props.onToggleCards}
        labels={this.props.labels}
        onCaseClick={this.props.onOpenCase}
        userProfile={this.props.userProfile}
        userGroup={this.props.userGroup}
        onToggleSharedCasesDetails={onToggleSharedCasesDetails}
        caseQueryText={caseQueryText}
        setCaseQueryText={setCaseQueryText}
      />
    );
  };

  renderContent = () => {
    var {
      activeTab,
      caseMap,
      thisWeek,
      thisMonth,
      sharedCases,
      onToggleSharedCasesDetails,
      // financeAccountPlans
    } = this.props;

    let CaseTableWrapper = this.CaseTableWrapper;
    return (
      <div>
        <Conditional show={activeTab === "Create"}>
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <CaseEditForm
              contactMap={this.props.contactMap}
              onClose={this.props.onCloseCreate}
              onFinishDraft={this.props.onFinishDraftFromCreate}
              onEditCase={() => {}}
              userName={this.props.userProfile.userName}
              documentMap={this.props.documentMap}
              userProfile={this.props.userProfile}
              userSettings={this.props.userSettings}
              userGroup={this.props.userGroup}
              friends={this.props.friends}
              // financeAccountPlans={financeAccountPlans}
            />
          </div>
        </Conditional>
        <Conditional show={activeTab === "List"}>
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <CaseTableWrapper
              cases={caseMap.saved}
              count={caseMap.savedCount}
            />
          </div>
        </Conditional>
        {activeTab === "Sharings" ? (
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            {this.props.showSharedCasesDetails ? (
              <CaseTableWrapper
                cases={sharedCases}
                count={sharedCases.length}
                onToggleSharedCasesDetails={onToggleSharedCasesDetails}
              />
            ) : (
              <CaseSharingTable
                sharedCases={this.props.sharedCases}
                onCaseClick={this.props.onOpenCase}
                userProfile={this.props.userProfile}
                onToggleSharedCasesDetails={
                  this.props.onToggleSharedCasesDetails
                }
              />
            )}
          </div>
        ) : null}
        <Conditional show={activeTab === "Draft"}>
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <CaseTableWrapper
              cases={caseMap.drafts}
              count={caseMap.draftCount}
            />
          </div>
        </Conditional>
        <Conditional show={activeTab === "Week"}>
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <CaseTableWrapper cases={thisWeek} count={thisWeek.length} />
          </div>
        </Conditional>
        <Conditional show={activeTab === "Month"}>
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <CaseTableWrapper cases={thisMonth} count={thisMonth.length} />
          </div>
        </Conditional>
        <Conditional show={activeTab === "Closed"}>
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <CaseTableWrapper
              cases={caseMap.closed}
              count={caseMap.closedCount}
            />
          </div>
        </Conditional>
        <Conditional show={activeTab === "Trash"}>
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <CaseTableWrapper
              cases={caseMap.trashed}
              count={caseMap.trashedCount}
            />
          </div>
        </Conditional>
        {this.renderCaseEditViews()}
      </div>
    );
  };

  renderCaseEditViews = () => {
    var {
      activeTab,
      caseMap,
      sharedCases,
      userProfile,
      updateMessageFromHeader,
      singleCaseModel, //The full case with all data loaded
      setCaseModelId,
      refetchCaseModel,
    } = this.props;

    return this.props.openCases.map((caseId) => {
      let currentCase =
        caseMap.cases.find((c) => c.id === caseId) ||
        sharedCases.find((c) => c.id === caseId);

      if(!currentCase) {
        return null;
      }

      //shared cases can only be edited by the current editor
      let readOnly =
        currentCase.sharingUsers.length > 0 &&
        (!currentCase.editor || currentCase.editor.id !== userProfile.id);

      let openCase =
        singleCaseModel.id === currentCase.id ? singleCaseModel : currentCase;

      return (
        <Conditional
          key={caseId}
          show={activeTab === this.props.getCaseKey(caseId)}
        >
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <CaseListPage
              key={currentCase.id}
              contactMap={this.props.contactMap}
              onClose={this.props.onCloseEdit.bind(this, caseId)}
              openCase={openCase}
              singleCaseModel={singleCaseModel}
              refetchCaseModel={refetchCaseModel}
              userProfile={userProfile}
              updateMessageFromHeader={updateMessageFromHeader}
              documentMap={this.props.documentMap}
              userSettings={this.props.userSettings}
              userGroup={this.props.userGroup}
              friends={this.props.friends}
              readOnly={readOnly}
              setCaseModelId={setCaseModelId}
            />
          </div>
        </Conditional>
      );
    });
  };

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} className="nopadding">
            {this.renderNavigationBar()}
            {this.renderContent()}
          </Col>
        </Row>
      </Grid>
    );
  }
}
