import React from "react";
import AccountLayoutView from "./AccountLayoutView";
import { Route, Switch } from "react-router-dom";
import SignupForm from "./SignupForm";
import XLinkLogin from "./XLinkLogin";
import ForgotPassword from "../../Login/ForgotPassword";
import ResetPassword from "../../Login/ResetPassword";
import ResetPasswordConfirmation from "../../Login/ResetPasswordConfirmation";
import SubscribeContainer from "../../Login/SubscribeContainer";
import AccessDenied from "../../Login/AccessDenied";
import RequestConfirmEmail from "../../Login/RequestConfirmEmail";
import ConfirmEmail from "../../Login/ConfirmEmail";

import { RoutingService, DataStore } from "../../services/AxoServices";

import { LoadingIndicator, Consumer } from "../../utilities/LexUtilities";

export default class AccountContainer extends React.PureComponent {
  componentDidMount() {
    RoutingService.setAccountRootPath("/XLink/Account");
    DataStore.fetchGlobalSettings();
  }

  render() {
    let { match } = this.props;

    return (
      <Consumer>
        {(store) => {
          if (!store.loaded || !store.globalSettings.theme) {
            return <LoadingIndicator />;
          }
          return (
            <AccountLayoutView userSettings={store.userSettings}>
              <Switch>
                <Route path={match.path + "/Signup"} component={SignupForm} />
                <Route path={match.path + "/Login"} component={XLinkLogin} />
                <Route
                  path={match.path + "/ForgotPassword"}
                  component={ForgotPassword}
                />
                <Route
                  path={match.path + "/ResetPassword"}
                  component={ResetPassword}
                />
                <Route
                  path={match.path + "/ResetPasswordConfirmation"}
                  component={ResetPasswordConfirmation}
                />
                <Route
                  path={match.path + "/Subscribe"}
                  component={SubscribeContainer}
                />
                <Route
                  path={match.path + "/AccessDenied"}
                  component={AccessDenied}
                />
                <Route
                  path={match.path + "/RequestConfirmEmail"}
                  component={RequestConfirmEmail}
                />
                <Route
                  path={match.path + "/ConfirmEmail"}
                  component={ConfirmEmail}
                />
              </Switch>
            </AccountLayoutView>
          );
        }}
      </Consumer>
    );
  }
}
