import React from "react";

import { DataStore, DataActions } from "../../../services/AxoServices";

import { Consumer, withRTKData } from "../../../utilities/LexUtilities";

import AccountingAccountPostingsView from "./AccountingAccountPostingsView";

class AccountingAccountPostingsContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showControls: true,
      confirmDeleteId: 0,
      confirmCopyId: 0,
      confirmEditId: 0,
      showConfirmationMessage: false,
    };
  }

  componentDidMount() {
    DataStore.fetchClientPlan();
    DataStore.fetchFiscalYears().then(() => {
      if (!!DataStore.getStore().selectedFiscalYearId) {
        DataStore.fetchPostingEntries();
      }
    });
  }

  mapStoreToProps = (store) => {
    let financeAccountMap = {};
    store.clientPlan.accounts.forEach((element) => {
      financeAccountMap[element.id] = element;
    });

    let taxSpecificationMap = {};
    store.clientPlan.taxSpecifications.forEach((element) => {
      taxSpecificationMap[element.id] = element;
    });

    let entries = store.postingEntries.filter((e) => !e.trashed);
    if (store.includeDraft) {
      entries = entries.filter((e) => e.status !== "Created"); //Include all approved and posted entries
    } else {
      entries = entries.filter((e) => e.status === "Posted"); //Include only posted entries
    }

    return {
      selectedPlan: store.clientPlan || { accounts: [] },
      entries,
      financeAccountMap,
      taxSpecificationArray: store.clientPlan.taxSpecifications,
      taxSpecificationMap,
      fiscalYears: store.fiscalYears,
      selectedFiscalYearId: store.selectedFiscalYearId,
      selectedStartDate: store.selectedPostingStartDate,
      selectedEndDate: store.selectedPostingEndDate,
      accountStartNumber: store.accountStartNumber,
      accountEndNumber: store.accountEndNumber,
      receiptStartNumber: store.receiptStartNumber,
      receiptEndNumber: store.receiptEndNumber,
      includeDraft: store.includeDraft,
    };
  };

  handleSelectedClient = (selectedClient) => {
    DataStore.setState({
      fiscalYears: null,
    });

    DataStore.setSelectedContact(selectedClient);

    DataStore.fetchClientPlan();
    DataStore.fetchFiscalYears().then(() => {
      if (!!DataStore.getStore().selectedFiscalYearId) {
        DataStore.fetchPostingEntries();
      }
    });
  };

  onSelectFiscalYear = (fiscalYearId) => {
    DataStore.selectFiscalYear(fiscalYearId);
    DataStore.fetchPostingEntries();
  };

  onSelectStartDate = (selectedStartDate) => {
    DataStore.updateProperty("selectedPostingStartDate", selectedStartDate);
    DataStore.fetchPostingEntries();
  };

  onSelectEndDate = (selectedEndDate) => {
    DataStore.updateProperty("selectedPostingEndDate", selectedEndDate);
    DataStore.fetchPostingEntries();
  };

  onUpdateAccountStartNumber = (number) => {
    DataStore.updateProperty("accountStartNumber", number);
  };

  onUpdateAccountEndNumber = (number) => {
    DataStore.updateProperty("accountEndNumber", number);
  };

  onUpdateReceiptStartNumber = (number) => {
    DataStore.updateProperty("receiptStartNumber", number);
  };

  onUpdateReceiptEndNumber = (number) => {
    DataStore.updateProperty("receiptEndNumber", number);
  };

  onToggleControls = () => {
    this.setState((prevState) => ({ showControls: !prevState.showControls }));
    // this.setState( { showControls: !this.state.showControls });
  };

  queryEntries = async () => {
    try {
      let response = await DataStore.fetchPostingEntries();
      return !!response;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  onDelete = (id) => {
    this.setState({ confirmDeleteId: id });
  };

  confirmDelete = async (entry) => {
    let newEntry = {
      ...entry,
      taxAccountId: entry.taxAccountId || 0, //0 Means to not assign a tax account to the new account
      isIncome: !entry.isIncome,
      status: "Approved",
      postingDate: null,
    };
    delete newEntry.id;

    let result = await this.doCreateEntry(newEntry);
    if (result) {
      this.showConfirmationMessage();
    }
    return result;
  };

  onCopy = (id) => {
    this.setState({ confirmCopyId: id });
  };

  onConfirmCopy = async (entry) => {
    let result = await this.doCopy({
      entry,
      resetNumber: true,
      resetTaxAccount: false,
    });
    return result;
  };

  doCopy = async ({ entry, resetNumber, resetTaxAccount }) => {
    let newEntry = {
      ...entry,
      taxAccountId: resetTaxAccount ? null : entry.taxAccountId || 0, //0 Means to not assign a tax account to the new account
      status: "Approved",
      postingDate: null,
    };
    delete newEntry.id;
    if (resetNumber) {
      delete newEntry.receiptNumber;
    }

    let result = await this.doCreateEntry(newEntry);
    if (result) {
      this.showConfirmationMessage();
    }
    return result;
  };

  onEdit = (id) => {
    this.setState({ confirmEditId: id });
  };

  onConfirmEdit = async (oldEntry, newEntry) => {
    let result = await this.confirmDelete(oldEntry);
    if (!result) {
      return false;
    }

    result = await this.doCopy({
      entry: newEntry,
      resetNumber: false,
      resetTaxAccount: oldEntry.financeAccountId !== newEntry.financeAccountId, //Recalculate taxes when account changes
    });
    if (result) {
      this.showConfirmationMessage();
    }
    return result;
  };

  doCreateEntry = async (entry) => {
    try {
      let response = await DataActions.createBookkeepingDraftEntry(entry);
      let newEntry = await response.json();
      DataStore.setState({
        importantEntryIds: new Set([
          ...DataStore.getStore().importantEntryIds,
          newEntry.id,
        ]),
      });
      return response.ok;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  showConfirmationMessage = () => {
    console.log("Showing confirmation message.");
    this.setState({
      confirmDeleteId: 0,
      confirmCopyId: 0,
      confirmEditId: 0,
      showConfirmationMessage: true,
    });
    setTimeout(() => this.setState({ showConfirmationMessage: false }), 3000);
  };

  onChangeIncludeDraft = (includeDraft) => {
    DataStore.setState({ includeDraft });
    DataStore.fetchPostingEntries();
  };

  render() {
    return (
      <Consumer>
        {(store) => (
          <AccountingAccountPostingsView
            {...this.mapStoreToProps(store)}
            {...this.props}
            {...this.state}
            handleSelectedClient={this.handleSelectedClient}
            onSelectFiscalYear={this.onSelectFiscalYear}
            onSelectStartDate={this.onSelectStartDate}
            onSelectEndDate={this.onSelectEndDate}
            onUpdateAccountStartNumber={this.onUpdateAccountStartNumber}
            onUpdateAccountEndNumber={this.onUpdateAccountEndNumber}
            onUpdateReceiptStartNumber={this.onUpdateReceiptStartNumber}
            onUpdateReceiptEndNumber={this.onUpdateReceiptEndNumber}
            onToggleControls={this.onToggleControls}
            onDelete={this.onDelete}
            confirmDelete={this.confirmDelete}
            onCopy={this.onCopy}
            onConfirmCopy={this.onConfirmCopy}
            onEdit={this.onEdit}
            onConfirmEdit={this.onConfirmEdit}
            onChangeIncludeDraft={this.onChangeIncludeDraft}
          />
        )}
      </Consumer>
    );
  }
}

export default withRTKData(AccountingAccountPostingsContainer);
