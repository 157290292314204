import React from "react";
import PropTypes from "prop-types";
import loader from "../resources/images/loadingGreen.gif";

export default class LoadingIcon extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool.isRequired,
  };

  render() {
    let { show } = this.props;

    if (!show) {
      return null;
    }

    return (
      <span>
        &nbsp;
        <img
          alt=""
          src={loader}
          className="img-circle"
          width="18"
          height="18"
        />
      </span>
    );
  }
}
