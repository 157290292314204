import React from "react";

import { LexButton, AxoLocal, Flexbox } from "./LexUtilities";

class PaginationControls extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  showMore = () => {
    this.setState({ loading: true });
    this.props.onShowMore();
    setTimeout(() => {
      //Makes the button feel more asyncy
      this.setState({
        loading: false,
      });
    }, 500);
  };

  render() {
    var { length, displayLength, count } = this.props;

    if (length >= (count || 0)) {
      return (
        <h5 style={{ paddingLeft: "10px" }}>
          ({!isNaN(displayLength) ? displayLength : length})
        </h5>
      );
    }

    var { loading } = this.state;

    return (
      <Flexbox>
        <h5 style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          ({!isNaN(displayLength) ? displayLength : length}
          {!!count ? <span> / {count}</span> : null})
        </h5>
        <LexButton disabled={loading} onClick={this.showMore}>
          <AxoLocal
            key="showmore"
            entity="ViewShowmoreinfo"
            defaultValue={"Vis flere"}
          />
          {loading ? (
            <span>
              &nbsp;
              <img
                alt=""
                src="/imgs/app/loading.gif"
                className="img-circle"
                width="20"
                height="20"
              />
            </span>
          ) : null}
        </LexButton>
      </Flexbox>
    );
  }
}

export default PaginationControls;
