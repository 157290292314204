import React from "react";

import DataStore from "../../services/DataAccess/DataStore";
import CollectionStatusView from "./CollectionStatusView";

export default class CollectionContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      labels: [],
      locale: "enUS",
    };
    this.name = "CollectionContainer";
  }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      this.setState({
        invoices: store.invoices,
        labels: store.labels,
      });
    });

    DataStore.fetchInvoices();
    DataStore.initializeLabels();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  render() {
    let { invoices, labels } = this.state;

    let filteredLabels = labels.filter((l) => l.type === 3); //Clients
    let sortedLabels = filteredLabels.sort((l1, l2) =>
      l1.name.localeCompare(l2.name)
    );

    return (
      <CollectionStatusView invoices={invoices} clientLabels={sortedLabels} />
    );
  }
}
