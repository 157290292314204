import React from "react";
import { withRouter } from "react-router-dom";
import { Table, Column, Cell } from "fixed-data-table-2";
import moment from "moment";
import { DataActions, ModalService } from "../../services/AxoServices";

import {
  Icon,
  TableBase,
  Dimensions,
  SortHeaderCell,
  DataListWrapper,
  AxoLocal,
  LexButton,
  LoadingIcon,
} from "../../utilities/LexUtilities";

import {
  Row,
  Col,
  Grid,
  FormControl,
  InputGroup,
  FormGroup,
  ButtonToolbar,
} from "react-bootstrap";

const defaultColumnDefs = {
  title: {
    width: 400,
    shown: true,
  },
  type: {
    width: 400,
    shown: true,
  },
  creationDate: {
    width: 300,
    shown: true,
  },
  actions: {
    width: 200,
    shown: true,
  },
};

const DeletionProgress = {
  Idle: 0,
  Confirming: 1,
  InProgress: 2,
};

class DocumentTrashTableComponent extends TableBase {
  constructor(props) {
    super(props);
    this.name = "DocumentTrashTableComponent";
    this.defaultColumnDefs = defaultColumnDefs;

    let tableEntries = props.templates;
    if (props.documents) {
      tableEntries = tableEntries.concat(
        props.documents.map(this.convertDocumentToDummyTemplate)
      );
    }
    this._defaultSortIndexes = this.generateDefaultSortIndexes(tableEntries);
    this.state = {
      tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        actions: 150,
      },
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      restoringDocument: 0,
      restoringTemplate: 0,
      deletionProgress: DeletionProgress.Idle,
    };
  }

  componentWillReceiveProps(nextProps) {
    let tableEntries = nextProps.templates;
    if (nextProps.documents) {
      tableEntries = tableEntries.concat(
        nextProps.documents.map(this.convertDocumentToDummyTemplate)
      );
    }
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  convertDocumentToDummyTemplate = (doc) => {
    return {
      id: doc.id,
      title: doc.fileName,
      type: (
        <AxoLocal
          entity="DocumentTrashTableViewToDummyTemplate"
          defaultValue={"Dokument"}
        />
      ),
      creationDate: doc.uploadDate,
      isDocument: true,
    };
  };

  onSelectTemplate = (templateId) => {
    this.props.onSelectTemplate(templateId);
  };

  onTrashTemplate = (templateId) => {
    var template = this.props.templates.find(
      (t) => t.id === parseInt(templateId, 10)
    );
    if (template.trashed) {
      this.deleteTemplate(template.id);
    }
    template.trashed = true;
    DataActions.updateTemplate(template);
  };

  deleteTemplate = (templateId) => {
    ModalService.openConfirmModal(
      <AxoLocal
        entity="DocumentTrashTableViewConfirmModal"
        defaultValue={"Vil du slette skabelonen permanent?"}
      />,
      (value) => {
        if (value) {
          DataActions.deleteTemplate(templateId);
        }
      }
    );
  };

  onRestoreTemplate = (templateId) => {
    this.setState({ restoringTemplate: templateId });
    var template = this.props.templates.find(
      (t) => t.id === parseInt(templateId, 10)
    );
    template.trashed = false;
    DataActions.updateTemplate(template).then((response) => {
      this.setState({ restoringTemplate: 0 });
    });
  };

  onDeleteDocument = (documentId) => {
    ModalService.openConfirmModal(
      <AxoLocal
        entity="DocumentTrashTableViewslettepermanent"
        defaultValue={"Vil du slette dokuemnetet permanent?"}
      />,
      (value) => {
        if (value) {
          DataActions.deleteDocument(documentId);
        }
      }
    );
  };

  onRestoreDocument = (documentId) => {
    this.setState({ restoringDocument: documentId });
    let doc = { ...this.props.documents.find((d) => d.id === documentId) };
    doc.trashed = false;
    DataActions.updateDocument(doc).then((response) => {
      this.setState({ restoringDocument: 0 });
    });
  };

  renderButtons = (objectId, isDocument) => {
    var { restoringDocument, restoringTemplate } = this.state;
    var isRestoring = isDocument
      ? restoringDocument === objectId
      : restoringTemplate === objectId;
    return (
      <div>
        <span role="button">
          {isRestoring ? (
            <img
              alt=""
              src="/imgs/app/loading.gif"
              className="img-circle"
              width="20"
              height="20"
            />
          ) : (
            <span
              onClick={
                isDocument
                  ? this.onRestoreDocument.bind(this, objectId)
                  : this.onRestoreTemplate.bind(this, objectId)
              }
            >
              <Icon glyph="icon-fontello-ccw-2" />
              &nbsp; &nbsp;
              <AxoLocal
                entity="DocumentTrashTableViewrestore"
                defaultValue={"Gendan"}
              />
            </span>
          )}
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp; &ensp;&ensp;&ensp;&ensp;
        <Icon
          id="trashFile"
          role="button"
          onClick={
            isDocument
              ? this.onDeleteDocument.bind(this, objectId)
              : this.onTrashTemplate.bind(this, objectId)
          }
          className="editable"
          glyph="icon-fontello-trash-1"
        />
      </div>
    );
  };

  onTemplateTypeChange = (template, input) => {
    template.type = input.value;
    DataActions.updateTemplate(template);
  };

  onSearch = (event) => {
    var entries = this.getFilteredEntries(
      this.state.tableEntries,
      event.target.value
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }
    return entries.filter((t) => {
      return t.title.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  doDeleteAll = async () => {
    let { tableEntries } = this.state;
    this.setState({ deletionProgress: DeletionProgress.InProgress });
    try {
      let promises = tableEntries.map((entry) => {
        if (entry.isDocument) {
          return DataActions.deleteDocument(entry.id);
        } else {
          return DataActions.deleteTemplate(entry.id);
        }
      });
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }

    this.setState({ deletionProgress: DeletionProgress.Idle });
  };

  render() {
    var { sortedDataList, colSortDirs, columnDefs, deletionProgress } =
      this.state;
    const { containerHeight, containerWidth } = this.props;
    var tableWidth = containerWidth - 30;
    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <Col sm={4} className="flexbox-standard">
                  <h4>
                    {" "}
                    <Icon glyph="icon-fontello-hourglass" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTrashTableViewDocumentsandtemplates"
                      defaultValue={"Dokumenter og skabeloner"}
                    />
                  </h4>
                  {deletionProgress === DeletionProgress.Idle ? (
                    <div>
                      &nbsp;
                      <LexButton
                        disabled={sortedDataList._data.length === 0}
                        onClick={() =>
                          this.setState({
                            deletionProgress: DeletionProgress.Confirming,
                          })
                        }
                      >
                        <AxoLocal
                          entity="TimeEntryFormntimeEntryEmptygarbagebin"
                          defaultValue="Tøm skraldespand"
                        />
                      </LexButton>
                    </div>
                  ) : null}
                  {deletionProgress === DeletionProgress.Confirming ||
                  deletionProgress === DeletionProgress.InProgress ? (
                    <div className="flexbox-center">
                      <div>
                        &nbsp;&nbsp;
                        <strong>
                          {" "}
                          <AxoLocal
                            entity="axoAccounting36"
                            defaultValue="Slet alt"
                          />{" "}
                        </strong>
                        &nbsp;&nbsp;
                      </div>
                      <ButtonToolbar>
                        <LexButton
                          disabled={
                            deletionProgress === DeletionProgress.InProgress
                          }
                          onClick={() =>
                            this.setState({
                              deletionProgress: DeletionProgress.Idle,
                            })
                          }
                        >
                          <AxoLocal
                            entity="axoAccounting22"
                            defaultValue={"Fortryd"}
                          />
                        </LexButton>
                        <LexButton
                          disabled={
                            deletionProgress === DeletionProgress.InProgress
                          }
                          onClick={this.doDeleteAll}
                        >
                          <AxoLocal
                            entity="SidebarRightContainerConfirmPassword"
                            defaultValue={"Bekræft"}
                          />
                          <LoadingIcon
                            show={
                              deletionProgress === DeletionProgress.InProgress
                            }
                          />
                        </LexButton>
                      </ButtonToolbar>
                    </div>
                  ) : null}
                </Col>
                <Col sm={4}>
                  <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon
                            className="editable"
                            glyph="icon-fontello-search"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
                <Col
                  sm={4}
                  className="hidden-xs text-right"
                  style={{ paddingTop: "10px" }}
                ></Col>
              </Row>
            </Grid>
          </div>
          <div>
            <Grid fluid>
              <Row style={{ paddingLeft: "20px" }}>
                <Col xs={12} style={{ padding: 0 }}>
                  <Table
                    rowHeight={40}
                    rowsCount={sortedDataList.getSize()}
                    height={containerHeight}
                    width={tableWidth}
                    isColumnResizing={false}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    headerHeight={40}
                  >
                    <Column
                      columnKey="title"
                      header={
                        <SortHeaderCell
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.title}
                        >
                          <Icon
                            style={{ color: "#4CAF50" }}
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="socialPrivateConversationBannerConferenceName"
                            defaultValue={"Navn"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var template = sortedDataList.getObjectAt(
                          props.rowIndex
                        );
                        return (
                          <Cell key={template.id} {...props}>
                            {template.title}
                          </Cell>
                        );
                      }}
                      width={columnDefs.title.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="type"
                      header={
                        <SortHeaderCell
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.type}
                        >
                          <Icon
                            style={{ color: "#4CAF50" }}
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="DocumentGridViewType"
                            defaultValue={"Type"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var template = sortedDataList.getObjectAt(
                          props.rowIndex
                        );
                        return (
                          <Cell key={template.id} {...props}>
                            {template.type}
                          </Cell>
                        );
                      }}
                      width={columnDefs.type.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="creationDate"
                      header={
                        <SortHeaderCell
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.creationDate}
                        >
                          <Icon
                            style={{ color: "#4CAF50" }}
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="DocumentTabViewcreationDate"
                            defaultValue={"Oprettelsesdato"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var template = sortedDataList.getObjectAt(
                          props.rowIndex
                        );
                        return (
                          <Cell key={template.id} {...props}>
                            {moment(template.creationDate).format("L")}
                          </Cell>
                        );
                      }}
                      width={columnDefs.creationDate.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="actions"
                      header={<Cell></Cell>}
                      cell={(props) => {
                        var template = sortedDataList.getObjectAt(
                          props.rowIndex
                        );
                        return (
                          <Cell key={template.id} {...props}>
                            {this.renderButtons(
                              template.id,
                              !!template.isDocument
                            )}
                          </Cell>
                        );
                      }}
                      width={columnDefs.actions.width}
                      isResizable={true}
                    />
                  </Table>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

class DocumentTrashTableView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderDocumentTrashTableComponent = () => {
    return <DocumentTrashTableComponent {...this.props} />;
  };

  render() {
    return (
      <div style={{ paddingTop: "10px" }}>
        {this.renderDocumentTrashTableComponent()}
      </div>
    );
  }
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight - 200;
    },
  })(DocumentTrashTableView)
);
