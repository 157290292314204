import React from "react";
import { ctx, changeLocale, Entity, AxoLocal } from "../utilities/L20n/L20n";
import AxoFrame from "../utilities/AxoFrame";
import moment from "moment";
import * as $ from "jquery";

function getMomentLocale(locale) {
  switch (locale) {
    case "da":
      return "da";
    case "enUS":
      return "en-US";
    case "ch":
      return "zh-cn";
    default:
      return "en";
  }
}

function updateHTMLLocaleClass(locale) {
  $("html").addClass("language-" + locale);
  if (locale !== "da") {
    $("html").removeClass("language-da");
  }
  if (locale !== "enUS") {
    $("html").removeClass("language-enUS");
  }
  if (locale !== "ch") {
    $("html").removeClass("language-ch");
  }
}

var localeSubscribers = [];
function dispatchLocale() {
  if (!ctx || !ctx.supportedLocales[0]) {
    return;
  }
  localeSubscribers.forEach((subscription) =>
    subscription.callback(ctx.supportedLocales[0])
  );
}

if (ctx) {
  ctx.ready(() => {
    dispatchLocale();
  });
}

var newLocale = "";

function changeLocaleInternal() {
  if (newLocale) {
    var localeToSet = newLocale;
    newLocale = "";
    changeLocale(localeToSet);
  }
}

export default {
  changeLocale(locale) {
    moment.locale(getMomentLocale(locale));
    updateHTMLLocaleClass(locale);

    newLocale = locale;
    ctx.ready(changeLocaleInternal);
  },

  subscribeToLocale(subscriberName, callback) {
    localeSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatchLocale();
  },

  unsubscribeFromLocale(subscriberName) {
    localeSubscribers = localeSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },

  getStripeLocale(locale) {
    switch (locale.toLowerCase()) {
      case "da":
        return "da";
      case "enus":
        return "en";
      case "ch":
        return "zh";
      default:
        return "en";
    }
  },

  getMessageSubject(message) {
    if (!message.subjectEntity) {
      return (
        message.subject || (
          <Entity entity="InboxItemNoSubject" defaultValue="(Intet emne)" />
        )
      );
    }
    if (
      message.subjectEntity === "BookingContainerconfirmedMailmessageapproved"
    ) {
      return (
        <span>
          <AxoLocal entity="BookingContainerconfirmedMailsubject" />
          &nbsp;
          {moment(message.subjectEntityDate).format("L LT")}&nbsp;
          <AxoLocal entity="BookingContainerconfirmedMailmessageapproved" />
        </span>
      );
    }
    if (
      message.subjectEntity === "BookingContainerconfirmedMailmessagerejected"
    ) {
      return (
        <span>
          <AxoLocal entity="BookingContainerconfirmedMailsubject" />
          &nbsp;
          {moment(message.subjectEntityDate).format("L LT")}&nbsp;
          <AxoLocal entity="BookingContainerconfirmedMailmessagerejected" />
        </span>
      );
    }
    return message.subject;
  },

  onLoadFrame(event) {
    var frame = event.target;
    frame.style.height =
      frame.contentWindow.document.body.scrollHeight + 50 + "px";
  },

  getMessageText(message, noFrame) {
    if (!message.messageEntity && !noFrame) {
      return (
        <AxoFrame
          defaultHeight="500px"
          bottomPadding={25}
          content={message.messageText}
        />
      );
    }
    if (message.messageEntity === "BookingYourorderhasbeenapproved") {
      return (
        <div>
          <p>
            <AxoLocal entity="BookingContainerconfirmedMailmessageText" />
            &nbsp;
            {message.messageEntityVariable}&nbsp;
            {moment(message.messageEntityDate).format("L LT")}.&nbsp;
          </p>
          <p>
            <AxoLocal entity="BookingYourorderhasbeenapproved" />
          </p>
        </div>
      );
    }
    if (message.messageEntity === "BookingYourorderhasbeenrejected") {
      return (
        <div>
          <p>
            <AxoLocal entity="BookingContainerconfirmedMailmessageText" />
            &nbsp;
            {message.messageEntityVariable}&nbsp;
            {moment(message.messageEntityDate).format("L LT")}.&nbsp;
          </p>
          <p>
            <AxoLocal entity="BookingYourorderhasbeenrejected" />
          </p>
        </div>
      );
    }
    return <span dangerouslySetInnerHTML={{ __html: message.messageText }} />;
  },
};
