import React from "react";
import DataActions from "../services/DataAccess/DataActions";
import moment from "moment";
import { Grid, Row, Col } from "react-bootstrap";

import {
  Icon,
  AxoTimeline,
  AxoTimelineElement,
  AxoLocal,
} from "../utilities/LexUtilities";

export default class NotificationComponent extends React.PureComponent {
  onDeleteNote = (noteId) => {
    DataActions.deleteNotification(noteId);
  };

  getNotificationBody = (note) => {
    if (note.entity) {
      if (note.entity === "DocumentaddCaseReminderRememberdeadlineforcase") {
        return (
          <div>
            <div>
              <AxoLocal
                entity="DocumentaddCaseReminderRememberdeadlineforcase"
                defaultValue="Remember deadline for case"
              />
            </div>
            <div>{note.variable}</div>
            <div>{moment(note.messageDate).format("L")}</div>
          </div>
        );
      }
      if (note.entity === "DocumentaddCaseRememberdeadlinefordocument") {
        return (
          <div>
            <div>
              <AxoLocal
                entity="DocumentaddCaseRememberdeadlinefordocument"
                defaultValue="Remember deadline for document"
              />
            </div>
            <div>{note.variable}</div>
            <div>{moment(note.messageDate).format("L")}</div>
          </div>
        );
      }
      if (note.entity === "DocumentaddCaseInvoicewithinvoicenumber") {
        return (
          <div>
            <div>
              <AxoLocal
                entity="DocumentaddCaseInvoicewithinvoicenumber"
                defaultValue="The invoice with invoice number"
              />
            </div>
            <div>{note.variable}</div>
            <div>
              <AxoLocal
                entity="DocumentaddMustbepaidatthelatest"
                defaultValue="Must be paid no later than"
              />
            </div>
            <div>{moment(note.messageDate).format("L")}</div>
          </div>
        );
      }
    }
    return note.text;
  };

  render() {
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <h4 className="text-center">
                <Icon glyph="icon-fontello-down-dir" />
                &nbsp; &nbsp;
                <AxoLocal
                  entity="notificationsevents"
                  defaultValue={"Begivenheder"}
                />
              </h4>
              <AxoTimeline singleColumn>
                {this.props.notifications.map((note) => {
                  return (
                    <AxoTimelineElement
                      key={note.id}
                      left={false}
                      date={note.creationTime}
                    >
                      <div className="noteText">
                        {this.getNotificationBody(note)}
                      </div>
                      <div>
                        <Icon
                          className="editable"
                          role="button"
                          onClick={this.onDeleteNote.bind(this, note.id)}
                          glyph="icon-fontello-trash-1"
                        />
                      </div>
                    </AxoTimelineElement>
                  );
                })}
              </AxoTimeline>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
