import React from "react";
import SubscriptionSelect from "../FrontPage/SubscriptionSelect";

import {
  DataStore,
  ApiService,
  // ApiKeys,
  AuthorizationService,
  // StripeAuthenticationService,
  // VatService
} from "../services/AxoServices";

import {
  Consumer,
  withRouter,
  // getText,
  AxoFooter,
  FlagMenu,
  // AxoLocal
} from "../utilities/LexUtilities";

class SubscribeContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSubscriptionErrorMessage: "",
      groupCode: "",
      showGroupCodeErrorMessage: false,
      userProfile: props.userProfile,
    };
    this.selectedPlanId = 0;
    // this.paylike = window.Paylike(ApiKeys.payLikeApiKey);

    // this.configureStripeCheckout(props.locale);
  }

  componentDidMount() {
    DataStore.initializeUserProfile();
    DataStore.fetchSubscriptionPlans();
    DataStore.fetchSubscriptionFormulas();
    DataStore.fetchUserSubscription();
    DataStore.fetchGlobalSettings();
  }

  onSelectPlan = (plan) => {
    let { userSubscription } = this.props;

    // let { userProfile } = this.state;

    this.selectedPlanId = plan.planId;

    let sub = userSubscription;
    let amount = plan.amount;
    if (!!sub.purchasedUnits) {
      let netPurchasedUnits = Math.max(
        sub.purchasedUnits - sub.freeUnitsPerInterval,
        0
      );
      amount += netPurchasedUnits * sub.costPerUnit;
    }

    this.activateSubscription({
      paylikeTransactionId: null,
      paylikeCardId: null,
    });

    //Do not require credit card when signing up for free or trial plans, unless you need to pay for signatures right away
    // if (
    //   (amount === 0 || plan.trialPeriod > 0) &&
    //   (plan.costPerUnit === 0 || plan.freeUnitsPerInterval > 0)
    // ) {
    //   this.activateSubscription({
    //     paylikeTransactionId: null,
    //     paylikeCardId: null,
    //   });
    // } else {
    //   this.paylike.popup(
    //     {
    //       currency: amount > 0 ? plan.currency.toUpperCase() : "",
    //       amount,
    //       title: plan.name,
    //       fields: [
    //         {
    //           name: "name",
    //           label: getText("axoAccounting7", "Navn"),
    //           type: "string",
    //           required: true,
    //           value:
    //             (userProfile.firstName || "") +
    //             " " +
    //             (userProfile.lastName || ""),
    //         },
    //         {
    //           name: "email",
    //           label: getText("socialSidebarContainerEmail", "Email"),
    //           type: "email",
    //           required: true,
    //           value: userProfile.eMail,
    //         },
    //       ],
    //       custom: {
    //         userName: userProfile.userName,
    //         subscriptionPlan: plan.planId + " - " + plan.name,
    //       },
    //     },
    //     (err, res) => {
    //       if (err) {
    //         console.log(err);
    //         return;
    //       }

    //       this.activateSubscription({
    //         paylikeTransactionId: !!res.transaction ? res.transaction.id : null,
    //         paylikeCardId: !!res.card ? res.card.id : null,
    //       });
    //     }
    //   );
    // }
  };

  activateSubscription = async ({ paylikeTransactionId, paylikeCardId }) => {
    this.setState({ attemptingSignup: true });
    let userProfile = { ...this.state.userProfile };
    userProfile.planId = this.selectedPlanId;

    try {
      let response = await ApiService.activateSubscription({
        userProfile,
        paylikeTransactionId,
        paylikeCardId,
      });

      if (response.ok) {
        var responseData = await response.json();
        if(!!responseData.url) {
          location.href = responseData.url;
          return;
        }

        let updatedUserProfile = await DataStore.fetchUserProfile();
        if (!!updatedUserProfile.id) {
          if (userProfile.userType === "EasyID") {
            this.props.history.push("/ClientDashboard");
          } else {
            this.props.history.push("/Dashboard");
          }
          return;
        } else {
          this.handleSubscriptionError("Failed to fetch user profile");
          return;
        }
      } else if (response.status === 400) {
        let message = await response.text();
        this.handleSubscriptionError(message);
        return;
      }
    } catch (error) {
      this.handleSubscriptionError(error.message);
    }
  };

  handleSubscriptionError = (reason) => {
    let subscriptionErrorType = "";
    if (
      reason.includes("CountryMismatch") ||
      reason.includes("MissingVatNumber")
    ) {
      subscriptionErrorType = reason;
    }
    this.setState({
      attemptingSignup: false,
      showSubscriptionErrorMessage: this.selectedPlanId,
      subscriptionErrorType,
    });
    setTimeout(() => {
      this.setState({
        showSubscriptionErrorMessage: "",
        subscriptionErrorType,
      });
    }, 5000);
    console.log(reason);
  };

  onSubmitCode = () => {
    var groupCode = this.state.groupCode;
    ApiService.joinUserGroup(groupCode)
      .then((response) => {
        if (response.ok) {
          return DataStore.fetchUserProfile();
        }
        return Promise.reject("User group could not be joined.");
      })
      .then((userProfile) => {
        this.props.history.push("/Dashboard");
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ showGroupCodeErrorMessage: true });
        setTimeout(() => {
          this.setState({ showGroupCodeErrorMessage: false });
        }, 3000);
      });
  };

  onChangeCode = (event) => {
    this.setState({ groupCode: event.target.value });
  };

  onSelectFormula = (viewModel) => {
    return ApiService.createSubscriptionPlanFromFormula(viewModel)
      .then((response) => {
        if (response.ok) {
          DataStore.fetchSubscriptionPlans();
          return response.json();
        }
        return Promise.reject("Could not create plan");
      })
      .then((newPlan) => {
        this.onSelectPlan(newPlan);
      });
  };

  render() {
    if (!this.state.userProfile.id) {
      return <div></div>;
    }

    return (
      <div className="center-block" style={{ paddingTop: "10px" }}>
        <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center">
          {/* MissingEntity */}
          Vælg abonnement &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img
            alt=""
            className="hidden-lg hidden-sm hidden-md"
            src="/imgs/app/Axologo3-30x30.png"
          />
          <img
            alt=""
            className="hidden-lg hidden-md hidden-xs"
            src="/imgs/app/Axologo3-35x35.png"
          />
          <img
            alt=""
            className="hidden-lg hidden-sm hidden-xs"
            src="/imgs/app/Axologo3-40x40.png"
          />
          <img
            alt=""
            className="hidden-md hidden-sm hidden-xs"
            src="/imgs/app/Axologo3-40x40.png"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FlagMenu
            // className='Lex-button-2'
            userSettings={{}}
          />
          &nbsp;&nbsp;&nbsp;
        </div>
        <div className="standardMaxWidth center-block">
          <SubscriptionSelect
            {...this.props}
            {...this.state}
            onSelectPlan={this.onSelectPlan}
            onChangeCode={this.onChangeCode}
            onSubmitCode={this.onSubmitCode}
            onSelectFormula={this.onSelectFormula}
          />
        </div>
        <AxoFooter />
      </div>
    );
  }
}

class SubScribeConsumer extends React.PureComponent {
  getPlans(subscriptionPlans, userProfile) {
    let userType = AuthorizationService.getUserType(userProfile);

    let activePlans = subscriptionPlans;
    if (userType === "EasyID") {
      activePlans = subscriptionPlans.filter((sub) => sub.type === "EasyID");
    } else {
      activePlans = subscriptionPlans.filter(
        (sub) => sub.type === "Standard" || sub.type === "Custom"
      );
    }

    let sortedPlans = activePlans.sort((p1, p2) => {
      if (p1.userGroupSize !== p2.userGroupSize) {
        return p1.userGroupSize - p2.userGroupSize;
      }
      if (p1.amount !== p2.amount) {
        return p1.amount - p2.amount;
      }
      return 0;
    });

    return sortedPlans;
  }

  render() {
    return (
      <Consumer>
        {(store) => (
          <SubscribeContainer
            key={store.userProfile.id}
            history={this.props.history}
            userProfile={store.userProfile}
            globalSettings={store.globalSettings}
            subscriptionPlans={this.getPlans(
              store.subscriptionPlans,
              store.userProfile
            )}
            subscriptionFormulas={store.subscriptionFormulas}
            userSubscription={store.userSubscription}
            locale={store.locale}
          />
        )}
      </Consumer>
    );
  }
}

export default withRouter(SubScribeConsumer);
