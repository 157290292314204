import React from "react";
// import MinyTinyMCE from '../../utilities/Editors/MinyTinyMCE'
import { debounce } from "lodash";

import { Grid, Row, Col } from "react-bootstrap";

import {
  AxoCheckbox,
  AxoLocal,
  SimpleTinyMCEEditor,
  Flexbox,
  FlexElement,
  getText,
} from "../../utilities/LexUtilities";

export default class MailConfigurationView extends React.PureComponent {
  updatePropertyDebounced = debounce((propertyName, value) => {
    this.props.onUpdateProperty(propertyName, value);
  }, 500);

  dummyFunction = () => {};

  render() {
    var { userSettings, onUpdateProperty, emailAccounts } = this.props;

    return (
      <div className="largeMaxWidth">
        <Grid fluid>
          {emailAccounts.length > 1 && (
            <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
              <Col xs={12}>
                <div
                  className="Axobt BanneDashboard"
                  style={{ paddingLeft: "10px" }}
                >
                  <AxoLocal
                    entity="emailAccounts1a"
                    defaultValue={"Standard email konto"}
                  />
                </div>
              </Col>
            </Row>
          )}
          {emailAccounts.length > 1 && (
            <Row>
              <Col xs={12}>
                <Flexbox>
                  <FlexElement className="rightPadding">
                    <div style={{ maxWidth: "500px" }}>
                      <select
                        className="axoblue selectbg"
                        style={{ width: "100%" }}
                        value={userSettings.emailAccountId || 0}
                        onChange={(event) =>
                          onUpdateProperty(
                            "emailAccountId",
                            !!event.target.value
                              ? parseInt(event.target.value, 10)
                              : null
                          )
                        }
                      >
                        <option key={0} value="">
                          ...
                        </option>
                        {emailAccounts.map((account) => {
                          return (
                            <option key={account.id} value={account.id}>
                              {account.displayName || account.account}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </FlexElement>
                  <FlexElement className="rightPadding">
                    <AxoCheckbox
                      checked={userSettings.useStandardEmailAsInbox || false}
                      onChange={(event) =>
                        onUpdateProperty(
                          "useStandardEmailAsInbox",
                          event.target.checked
                        )
                      }
                    />
                    <AxoLocal
                      entity="axoEntityidcode72"
                      defaultValue={"Anvend som indbakke"}
                    />
                  </FlexElement>
                  <FlexElement
                    title={getText(
                      "axoEntityidcode270",
                      "Email opsætningen vil blive gemt lokalt i browseren, så der kan anvendes forskellige emails i forskellige browsere."
                    )}
                  >
                    <AxoCheckbox
                      checked={userSettings.useLocalSettings || false}
                      onChange={(event) =>
                        onUpdateProperty(
                          "useLocalSettings",
                          event.target.checked
                        )
                      }
                    />
                    <AxoLocal
                      entity="axoEntityidcode73"
                      defaultValue={"Gem lokalt"}
                    />
                  </FlexElement>
                </Flexbox>
              </Col>
            </Row>
          )}
          <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
            <Col xs={12}>
              <div
                className="Axobt BanneDashboard"
                style={{ paddingLeft: "10px" }}
              >
                <AxoLocal
                  entity="axoEntityidcode74"
                  defaultValue={"Skabelon"}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SimpleTinyMCEEditor
                text={userSettings.mailTemplate || ""}
                height={250}
                locale={userSettings.locale}
                setupEditor={this.dummyFunction}
                onTextChange={(content) =>
                  this.updatePropertyDebounced("mailTemplate", content)
                }
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
            <Col xs={12}>
              <div
                className="Axobt BanneDashboard"
                style={{ paddingLeft: "10px" }}
              >
                <AxoLocal
                  entity="inboxSelectafolder11"
                  defaultValue={"Signatur"}
                />{" "}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SimpleTinyMCEEditor
                text={userSettings.mailSignature || ""}
                height={250}
                locale={userSettings.locale}
                setupEditor={this.dummyFunction}
                onTextChange={(content) =>
                  this.updatePropertyDebounced("mailSignature", content)
                }
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
            <Col xs={12}>
              <div
                className="flexbox-standard Axobt BanneDashboard"
                style={{ lineHeight: "40px" }}
              >
                <div style={{ paddingLeft: "10px", lineHeight: "40px" }}>
                  <AxoLocal
                    entity="inboxSelectafolder12"
                    defaultValue={"Autosvar"}
                  />
                </div>
                &nbsp;&nbsp;&nbsp;
                <div
                  className="flexbox-center-vertical"
                  style={{ paddingBottom: "9px" }}
                >
                  <AxoCheckbox
                    checked={userSettings.autoReplyEnabled || false}
                    onChange={(event) =>
                      onUpdateProperty("autoReplyEnabled", event.target.checked)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SimpleTinyMCEEditor
                text={userSettings.mailAutoReply || ""}
                height={250}
                locale={userSettings.locale}
                setupEditor={this.dummyFunction}
                onTextChange={(content) =>
                  this.updatePropertyDebounced("mailAutoReply", content)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ paddingBottom: "35px" }}>
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  height: "40px",
                  lineHeight: "40px",
                  paddingLeft: "10px",
                  paddingBottom: "25px",
                }}
              >
                <span className="axored" style={{ fontSize: "28px" }}>
                  *
                </span>
                <AxoLocal
                  entity="AccountingTabViewEntity64"
                  defaultValue={
                    "Bemærk at autosvar kun gælder for din interne Axolex email adresse."
                  }
                />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
