import React, { useEffect } from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import { DataStore } from "../services/AxoServices";

import { withData, LoadingIndicator } from "../utilities/LexUtilities";

import Login from "../Login/Login";
// import SignupForm from '../marketing/Account/SignupForm';
import ForgotPassword from "../Login/ForgotPassword";
import ResetPassword from "../Login/ResetPassword";
import ResetPasswordConfirmation from "../Login/ResetPasswordConfirmation";
import SubscribeContainer from "../Login/SubscribeContainer";
import AccessDenied from "../Login/AccessDenied";
import RequestConfirmEmail from "../Login/RequestConfirmEmail";
import ConfirmEmail from "../Login/ConfirmEmail";
import Signup from "../Login/Signup";
import SignupAccountantContainer from "../Login/SignupAccountantContainer";
import EasyIDLogin from "../Login/EasyIDLogin";
import GroupLogin from "../Login/GroupLogin";
import MitIDLogin from "../Login/MitIDLogin";

const mapStoreToProps = (store) => {
  return {
    globalSettings: store.globalSettings,
    locale: store.locale,
    loaded: store.loaded,
  };
};

export default withData(function SimpleFrontPageContainer(props) {
  let {
    globalSettings,
    // locale,
    loaded,
    match,
  } = props;

  useEffect(() => {
    DataStore.fetchGlobalSettings();
  }, []);

  if (!loaded || !globalSettings.theme) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <Switch>
        <Route
          exact
          path={match.path}
          component={() => <Redirect to={match.path + "/Login"} />}
        />
        <Route path={match.path + "/Signup"} component={Signup} />
        <Route
          path={match.path + "/SignupAccountant"}
          component={SignupAccountantContainer}
        />

        {/* <Route path={match.path + '/Signup'} component={SignupForm} /> */}
        <Route path={match.path + "/Login"} component={Login} />
        <Route
          path={match.path + "/ForgotPassword"}
          component={ForgotPassword}
        />
        <Route path={match.path + "/ResetPassword"} component={ResetPassword} />
        <Route
          path={match.path + "/ResetPasswordConfirmation"}
          component={ResetPasswordConfirmation}
        />
        <Route
          path={match.path + "/Subscribe"}
          component={SubscribeContainer}
        />
        <Route path={match.path + "/AccessDenied"} component={AccessDenied} />
        <Route
          path={match.path + "/RequestConfirmEmail"}
          component={RequestConfirmEmail}
        />
        <Route path={match.path + "/ConfirmEmail"} component={ConfirmEmail} />
        <Route path={match.path + "/GroupLogin"} component={GroupLogin} />
        <Route path={match.path + "/EasyID"} component={EasyIDLogin} />
        <Route path={match.path + "/MitID"} component={MitIDLogin} />
      </Switch>
    </div>
  );
}, mapStoreToProps);
