import checkdigit from "checkdigit";
import validator from "validator";
import moment from "moment";

const findCVR = (numbers, userCVR) => {
  let potentials = numbers.filter((n) => n.toString().length === 8);

  let CVR = checkCVRPotentials(potentials, userCVR);
  if (CVR) {
    return CVR;
  }

  return checkCVRPotentials(getPotentialCVRsWithSpaces(numbers), userCVR);
};

const checkCVRPotentials = (potentials, userCVR) => {
  if (potentials.length === 0) {
    return 0;
  }

  let CVR = potentials.find((p) => isOtherCVR(p, userCVR));
  if (CVR) {
    return CVR;
  }

  //6 is commonly confused with 8. Try to replace 8 with 6
  let correctedPotentials = potentials.map((p) =>
    parseInt(p.toString().replace(/8/g, "6"), 10)
  );
  CVR = correctedPotentials.find((p) => isOtherCVR(p, userCVR));
  if (CVR) {
    return CVR;
  }
  //5 can be confused with 6
  correctedPotentials = potentials.map((p) =>
    parseInt(p.toString().replace(/6/g, "5"), 10)
  );
  CVR = correctedPotentials.find((p) => isOtherCVR(p, userCVR));
  if (CVR) {
    return CVR;
  }

  return 0;
};

//Find CVRs written as four doubles or two quadruples
const getPotentialCVRsWithSpaces = (numbers) => {
  var potentials = [];
  var segments = [];

  //Four doubles
  numbers.forEach((n) => {
    var nString = n.toString();
    if (nString.length === 2) {
      segments.push(nString);
      if (segments.length === 4) {
        var newPotential =
          segments[0] + segments[1] + segments[2] + segments[3];
        potentials.push(parseInt(newPotential, 10));
      }
    } else {
      segments = [];
    }
  });

  //Two quadruples
  numbers.forEach((n) => {
    var nString = n.toString();
    if (nString.length === 4) {
      segments.push(nString);
      if (segments.length === 2) {
        var newPotential = segments[0] + segments[1];
        potentials.push(parseInt(newPotential, 10));
      }
    } else {
      segments = [];
    }
  });

  return potentials;
};

const isOtherCVR = (number, ownCVR) => {
  return number !== ownCVR && isCVR(number);
};

const isCVR = (number) => {
  var numberString = number.toString();
  if (numberString.length !== 8) {
    return false;
  }
  //Check that the CVR number fullfills the modulus 11 rule
  var isValid = checkdigit.mod11.isValid(number.toString());
  return isValid;
};

const findTotalWithVat = (numbers) => {
    if (numbers.length === 0) {
      return {
        total: 0,
        withoutVat: 0,
        vat: 0,
      };
    }

    let potentials = getTotalAndVatPotentials(numbers);
    if (potentials.length > 0) {
      return potentials[0];
    }
    // 6 is commonly confused with 8. Try to replace 8 with 6
    potentials = getTotalAndVatPotentials(
      numbers.map((p) => parseFloat(p.toString().replace(/8/g, "6")))
    );
    if (potentials.length > 0) {
      return potentials[0];
    }
    //Try to replace 6 with 8
    potentials = getTotalAndVatPotentials(
      numbers.map((p) => parseFloat(p.toString().replace(/6/g, "8")))
    );
    if (potentials.length > 0) {
      return potentials[0];
    }
    //Try to replace 0 with 8
    potentials = getTotalAndVatPotentials(
      numbers.map((p) => parseFloat(p.toString().replace(/0/g, "8")))
    );
    if (potentials.length > 0) {
      return potentials[0];
    }
    //Try to replace 6 with 5
    potentials = getTotalAndVatPotentials(
      numbers.map((p) => parseFloat(p.toString().replace(/6/g, "5")))
    );
    if (potentials.length > 0) {
      return potentials[0];
    }

    //Select largest price as total
    let total = Math.max(...numbers) || 0;
    return {
      total: total,
      withoutVat: total * 0.8,
      vat: total * 0.2,
    };
  },
  //O(N^2). Looks for price + vat pairs
  getTotalAndVatPotentials = (numbers) => {
    let potentials = [];
    numbers.forEach((n1) => {
      numbers.forEach((n2) => {
        let totalWithVat =
          n1 > n2 ? getVatAndTotal(n1, n2) : getVatAndTotal(n2, n1);
        //The total cannot be 25, because that's propably the VAT rate
        if (totalWithVat && totalWithVat.total !== 25) {
          potentials.push(totalWithVat);
        }
      });
    });
    potentials.sort((p1, p2) => {
      if (p1.total === p2.total) {
        return 0;
      }
      return p1.total > p2.total ? -1 : 1;
    });
    return potentials;
  };

const getVatAndTotal = (large, small) => {
  if (large === small) {
    return false;
  }
  var ratio = small / large;
  // if(ratio > 0.249 && ratio < 0.251) {
  //   return {
  //     total: large + small,
  //     withoutVat: large,
  //     vat: small
  //   }
  // }
  if (ratio > 0.19999 && ratio < 0.20001) {
    return {
      total: large,
      withoutVat: large - small,
      vat: small,
    };
  } else if (ratio > 0.24999 && ratio < 0.25001) {
    return {
      total: large + small,
      withoutVat: large,
      vat: small,
    };
  }
  return false;
};

const parsePriceOrNumber = (priceString) => {
  let numberWord = priceString.replace(/,/g, "."); //Replace commas with periods
  numberWord = numberWord.replace(/[.](?=.*[.])/g, ""); //Remove all periods except the last one
  let number = parseFloat(numberWord);
  return number;
};

let standardCurrencyOptions = {
  symbol: "$",
  require_symbol: false,
  allow_space_after_symbol: false,
  symbol_after_digits: false,
  allow_negatives: true,
  parens_for_negatives: false,
  negative_sign_before_digits: false,
  negative_sign_after_digits: false,
  allow_negative_sign_placeholder: false,
  allow_decimal: true,
  require_decimal: true,
  digits_after_decimal: [2],
  allow_space_after_digits: false,
};

let USCurrencyOptions = {
  ...standardCurrencyOptions,
  thousands_separator: ",",
  decimal_separator: ".",
};

let EUCurrencyOptions = {
  ...standardCurrencyOptions,
  thousands_separator: ".",
  decimal_separator: ",",
};

let PeriodCurrencyOptions = {
  ...standardCurrencyOptions,
  thousands_separator: ".",
  decimal_separator: ".",
};

let CommaCurrencyOptions = {
  ...standardCurrencyOptions,
  thousands_separator: ",",
  decimal_separator: ",",
};

let dateFormats = [
  "DD/MM/YYYY",
  "DD/MM-YYYY",
  "DD-MM-YYYY",
  "DD.MM.YYYY",
  "DD.MM-YYYY",
  "MM/DD/YYYY",
  "MM/DD-YYYY",
  "MM-DD-YYYY",
  "MM.DD.YYYY",
  "MM.DD-YYYY",
  "DD/MM/YY",
  "DD/MM-YY",
  "DD-MM-YY",
  "DD.MM.YY",
  "DD.MM-YY",
  "MM/DD/YY",
  "MM/DD-YY",
  "MM-DD-YY",
  "MM.DD.YY",
  "MM.DD-YY",
];

const convertToDate = (word) => {
  let date = moment.utc(word, dateFormats, true);
  if (date.isValid()) {
    return date;
  }
  return null;
};

const getDates = (text) => {
  let dates = [];
  let words = text
    .replace(/\n/g, " ")
    .replace(/:/g, " ")
    .replace(/'/g, " ")
    .replace(/l/g, "1")
    .replace(/I/g, "1")
    .replace(/ -/g, "-")
    .replace(/- /g, "-")
    .replace(/\u2014/g, "-")
    // .replace("u2014", "-")
    .split(" ")
    .filter((word) => word.length > 0)
    .map((word) => word.trim());

  words.forEach((word) => {
    let date = convertToDate(word);
    if (!!date) {
      dates.push(date);
    }
  });

  dates.sort((l, r) => {
    return l - r;
  });

  return dates;
};

export default {
  isValidCVR(number) {
    return isCVR(number);
  },
  scanReceiptText(text, userCVR = 0) {
    let newText = text.replace(/'/g, ".");
    newText = newText.replace(/\.\./g, ".");
    newText = newText.replace(/ \./g, ".");
    newText = newText.replace(/\. /g, ".");
    newText = newText.replace(/ ,/g, ",");
    newText = newText.replace(/, /g, ",");

    let regex = /(\d+(?:[.,](?![.,])\d+)*)/g;
    let decimals = newText.match(regex);
    if (!decimals) {
      return {
        scannedText: text,
        cvr: 0,
        total: 0,
        withoutVat: 0,
        vat: 0,
        scanning: false,
      };
    }

    // let prices = decimals.filter(d => d.length > 2
    //   && (d.lastIndexOf(',') === d.length - 3 || d.lastIndexOf('.') === d.length - 3));

    let prices = decimals.filter(
      (d) =>
        validator.isCurrency(d, USCurrencyOptions) ||
        validator.isCurrency(d, EUCurrencyOptions) ||
        validator.isCurrency(d, PeriodCurrencyOptions) ||
        validator.isCurrency(d, CommaCurrencyOptions)
    );

    let priceNumbers = prices.map(parsePriceOrNumber).filter((n) => n >= 1);
    let numbers = decimals.map(parsePriceOrNumber).filter((n) => n >= 1);

    let CVR = findCVR(numbers, userCVR);
    let totalAndVat = findTotalWithVat(priceNumbers);

    let dates = getDates(text);

    return {
      scannedText: text,
      cvr: CVR,
      total: totalAndVat.total,
      withoutVat: totalAndVat.withoutVat,
      vat: totalAndVat.vat,
      date: dates.length > 0 ? dates[0] : null,
      scanning: false,
    };
  },
};
