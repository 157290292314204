var rootPath = ""; //process.env.NODE_ENV === 'production' ? '/Dashboard' : '/Dashboard';
var accountRootPath = process.env.NODE_ENV === "Users" ? "/Users" : "/Users";
const endPoint =
  process.env.NODE_ENV === "production" ? "" : "https://localhost:44335";

export default {
  getHostPath() {
    return endPoint;
  },
  setRootPath(newRootPath) {
    rootPath = newRootPath;
  },
  getRootPath() {
    return rootPath || "/";
  },
  getPath(route) {
    return rootPath + "/" + route;
  },
  setAccountRootPath(newRootPath) {
    accountRootPath = newRootPath;
  },
  getAccountRootPath() {
    return accountRootPath;
  },
  getAccountPath(route) {
    return accountRootPath + "/" + route;
  },
};
