import React from "react";

import { Picky } from "react-picky";
import "react-picky/dist/picky.css";

import ReactSelect from "react-select";

import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  FormGroup,
  ControlLabel,
  // InputGroup,
  FormControl,
} from "react-bootstrap";

import {
  Icon,
  NumberFilter,
  NumberFilterTypes,
  AxoLocal,
  getText,
} from "../../utilities/LexUtilities";

export default class NumberFilterModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      filter: new NumberFilter(),
      selectedOptionsArray: [],
      callback: (filter) => {},
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = (filter, callback) => {
    let selectedOptionsSet = new Set(filter.selectedOptions);

    this.setState({
      showModal: true,
      filter,
      selectedOptionsArray: filter.options.filter((o) =>
        selectedOptionsSet.has(o.id)
      ),
      callback,
    });
  };

  onOK = () => {
    let { filter, selectedOptionsArray } = this.state;

    let newFilter = new NumberFilter(filter);
    newFilter.selectedOptions = selectedOptionsArray.map((o) => o.id);

    this.setState({ showModal: false }, () => {
      this.state.callback(newFilter);
    });
  };

  onCancel = () => {
    this.setState({ showModal: false });
  };

  selectMultipleOption = (value) => {
    this.setState({ selectedOptionsArray: value });
  };

  updateFilterProperty = (propertyName, value) => {
    let newFilter = new NumberFilter(this.state.filter);
    newFilter[propertyName] = value;

    this.setState({
      filter: newFilter,
    });
  };

  render() {
    let { filter } = this.state;

    let filterTypeOptions = [
      {
        value: NumberFilterTypes.NONE,
        label: getText("axoEntityidcode149", "Intet filter"),
      },
      {
        value: NumberFilterTypes.EQUALS,
        label: getText("axoEntityidcode150", "Lig med"),
      },
      {
        value: NumberFilterTypes.NOTEQUEALS,
        label: getText("axoEntityidcode151", "Ikke lig med"),
      },
      {
        value: NumberFilterTypes.GREATERTHAN,
        label: getText("axoEntityidcode152", "Større end"),
      },
      {
        value: NumberFilterTypes.GREATERTHANEQUALS,
        label: getText("axoEntityidcode153", "Større end eller lig med"),
      },
      {
        value: NumberFilterTypes.LESSTHAN,
        label: getText("axoEntityidcode154", "Mindre end"),
      },
      {
        value: NumberFilterTypes.LESSTHANEQUALS,
        label: getText("axoEntityidcode154a", "Mindre end eller lig med"),
      },
      // { value: NumberFilterTypes.BETWEEN, label: 'Mellem'},
    ];

    let selectedOption = filterTypeOptions.find(
      (f) => f.value === filter.filterNumberType
    );

    return (
      <Modal show={this.state.showModal} onHide={this.close}>
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
          closeButton
        >
          <Modal.Title>
            <AxoLocal entity="axoEntityidcode117" defaultValue={"Filtrer"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          <div style={{ height: "350px" }}>
            <Picky
              value={this.state.selectedOptionsArray}
              options={this.state.filter.options}
              onChange={this.selectMultipleOption}
              open={true}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={350}
              selectAllMode="filtered"
              filterPlaceholder="Søg..."
              placeholder="Ingen valgt"
              selectAllText="Vælg alle"
              allSelectedPlaceholder="%s valgt"
              manySelectedPlaceholder="%s valgt"
            />
          </div>
          <h4>
            <AxoLocal entity="axoEntityidcode155" defaultValue={"Talfilter"} />
          </h4>
          <FormGroup
            controlId="filterType"
            bsSize="large"
            className="onToppest"
          >
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="axoEntityidcode156"
                defaultValue={"Filtertype"}
              />
            </ControlLabel>
            <ReactSelect
              name="filterTypeSelect"
              menuPlacement="auto"
              value={selectedOption}
              onChange={(selection) => {
                if (!selection) {
                  return;
                }

                this.updateFilterProperty("filterNumberType", selection.value);
              }}
              noOptionsMessage={() => ""}
              options={filterTypeOptions}
            />
          </FormGroup>
          <FormGroup controlId="filterNumber" bsSize="large">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="axoEntityidcode157" defaultValue={"Tal"} />
            </ControlLabel>
            <FormControl
              name="text"
              type="number"
              disabled={filter.filterNumberType === NumberFilterTypes.NONE}
              className="borderFormControlfocus"
              value={isNaN(filter.filterNumber) ? "" : filter.filterNumber}
              onChange={(event) =>
                this.updateFilterProperty("filterNumber", event.target.value)
              }
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.onOK}>
                <Icon glyph="icon-fontello-ok-3" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="friendRequestTimelineonApproveRequest"
                  defaultValue={"Godkend"}
                />
              </Button>
              <Button className="Lex-button-2" onClick={this.onCancel}>
                <Icon glyph="icon-fontello-cancel" />
                &nbsp;&nbsp;
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
