import React from "react";

import {
  ApiService,
  DataActions,
  ScannerService,
  DataStore,
} from "../../services/AxoServices";

import {
  withData,
  LexButton,
  AxoLocal,
  LoadingIcon,
} from "../../utilities/LexUtilities";

import moment from "moment";

import AccountingEntriesView from "../Accounting/AccountingEntriesView";

class AccountingMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadingIncome: false,
      uploadingExpense: false,
      scanningIncome: false,
      scanningExpense: false,

      showStorageWarning: false,
      showFileUploadError: false,
      showFileMaxSizeWarning: false,
    };

    this.incomeUpload = React.createRef();
    this.expenseUpload = React.createRef();
  }

  componentDidMount() {
    DataStore.fetchBookkeepingDraftEntries();
    DataStore.initializeUserProfile();
  }

  onUploadIncome = () => {
    this.incomeUpload.current.click();
    // $('#incomeUpload').trigger('click');
  };

  onUploadExpense = () => {
    this.expenseUpload.current.click();
    // $('#expenseUpload').trigger('click');
  };

  onIncomeSelected = (event) => {
    let files = event.target.files;
    try {
      this.processAllReceipts(files, true);
    } finally {
      event.target.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  onExpenseSelected = (event) => {
    let files = event.target.files;
    try {
      this.processAllReceipts(files, false);
    } finally {
      event.target.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  async processAllReceipts(files, isIncome) {
    let { scanReceipts } = this.props;

    if (files.length === 0) {
      return;
    }

    let file = files[0];
    if (file.size / Math.pow(1024, 2) > 50) {
      this.showWarning("showFileMaxSizeWarning");
      return;
    }

    if (isIncome) {
      this.setState({ uploadingIncome: true });
    } else {
      this.setState({ uploadingExpense: true });
    }

    let response = await DataActions.uploadDocuments(files, {
      convertToPdf: true,
    });

    let addedFiles = [];
    if (response.ok) {
      addedFiles = await response.json();
    } else {
      return this.displayResponseWarnings(response);
    }

    this.setState({
      uploadingIncome: false,
      uploadingExpense: false,
    });

    if (scanReceipts) {
      this.setState({
        scanningIncome: isIncome,
        scanningExpense: !isIncome,
      });
    }
    for (let file of addedFiles) {
      //Process files in sequence
      if (scanReceipts) {
        await this.processReceipt(file, isIncome);
      } else {
        await this.processReceiptWithoutScan(file, isIncome);
      }
    }

    this.setState({
      uploadingIncome: false,
      uploadingExpense: false,
      scanningIncome: false,
      scanningExpense: false,
    });
  }

  displayResponseWarnings = async (response) => {
    if (response.status === 400) {
      let text = await response.text();
      if (text === "Storage") {
        this.showWarning("showStorageWarning");
        return;
      } else {
        this.showWarning("showFileUploadError");
        return;
      }
    } else {
      this.showWarning("showFileUploadError");
      return;
    }
  };

  showWarning = (warningName) => {
    this.setState({ [warningName]: true });
    setTimeout(() => {
      this.setState({ [warningName]: false });
    }, 3000);
  };

  processReceipt = (file, isIncome) => {
    let { userProfile } = this.props;
    return ApiService.scanText(file.id)
      .then((scannedText) => {
        return {
          fileId: file.id,
          scannedText,
        };
      })
      .then((scanResult) => {
        let scanProcessingResult = ScannerService.scanReceiptText(
          scanResult.scannedText,
          parseInt(userProfile.companyCode, 10)
        );
        let entry = {
          isIncome,
          description:
            scanProcessingResult.cvr || file.fileName.replace(/\.[^/.]+$/, ""),
          amount: scanProcessingResult.total,
          vat: scanProcessingResult.vat,
          creationDate: !!scanProcessingResult.date
            ? scanProcessingResult.date.format()
            : moment.utc(),
          receipt: file,
          receiptId: scanResult.fileId,
        };
        // if(!scanProcessingResult.date) {
        //   this.setState(prevState => ({ receiptsWhoseDatesCouldNotBeScanned: [...prevState.receiptsWhoseDatesCouldNotBeScanned, entry.receiptId] }));
        // }
        if (!!scanProcessingResult.cvr) {
          return ApiService.getCVRName(scanProcessingResult.cvr)
            .then((companyInfo) => {
              entry.vatNumber = scanProcessingResult.cvr || "";
              entry.description = companyInfo.name;
              return DataActions.createBookkeepingDraftEntry(entry);
            })
            .catch((reason) => {
              console.log(reason);
              entry.description = "";
              return DataActions.createBookkeepingDraftEntry(entry);
            });
        } else {
          return DataActions.createBookkeepingDraftEntry(entry);
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({
          uploadingIncome: false,
          uploadingExpense: false,
          scanningIncome: false,
          scanningExpense: false,
        });
      });
  };

  processReceiptWithoutScan = (file, isIncome) => {
    let entry = {
      isIncome,
      receipt: file,
      receiptId: file.id,
    };

    return DataActions.createBookkeepingDraftEntry(entry);
  };

  getFileErrorMessage = () => {
    let { showFileMaxSizeWarning, showStorageWarning, showFileUploadError } =
      this.state;
    if (showFileMaxSizeWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarning"
            defaultValue={"Max 50 mb"}
          />
        </div>
      );
    }
    if (showStorageWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarningA"
            defaultValue={"Ikke mere plads. Slet filer."}
          />
        </div>
      );
    }
    if (showFileUploadError) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesError"
            defaultValue={"Upload fejlede"}
          />
        </div>
      );
    }
    return <span></span>;
  };

  getButtonLabel = (defaultLabel, uploading, scanning) => {
    if (uploading) {
      return (
        <span>
          <AxoLocal
            key="upload"
            entity="updategetAccountName3"
            defaultValue={"Uploader"}
          />
          &nbsp;
          <LoadingIcon show={true} />{" "}
        </span>
      );
    }
    if (scanning) {
      return (
        <span>
          <AxoLocal
            key="scan"
            entity="updategetAccountName4"
            defaultValue={"Scanner"}
          />
          &nbsp;
          <LoadingIcon show={true} />
        </span>
      );
    }
    return <span>{defaultLabel}</span>;
  };

  render() {
    let { bookKeepingDraftEntries, userProfile } = this.props;

    let { uploadingIncome, uploadingExpense, scanningIncome, scanningExpense } =
      this.state;

    let uploadedEntries = bookKeepingDraftEntries.filter(
      (e) => e.status !== "Approved"
    );
    if (uploadedEntries.length > 0) {
      return (
        <AccountingEntriesView
          entries={uploadedEntries}
          userProfile={userProfile}
          forceShowCards={true}
          onToggleCards={() => {}}
        />
      );
    }

    return (
      <div className="standardMaxWidth text-center" style={{ margin: "auto" }}>
        <div className="topPadding bottomPadding">
          <LexButton
            disabled={uploadingIncome || scanningIncome}
            onClick={this.onUploadIncome}
            style={{ width: "250px", fontSize: "25px" }}
          >
            {this.getButtonLabel(
              <AxoLocal
                entity="updategetAccountName6"
                defaultValue={"Upload indtægt"}
              />,
              uploadingIncome,
              scanningIncome
            )}
          </LexButton>
        </div>
        <div>
          <LexButton
            disabled={uploadingExpense || scanningExpense}
            onClick={this.onUploadExpense}
            style={{ width: "250px", fontSize: "25px" }}
          >
            {this.getButtonLabel(
              <AxoLocal
                entity="updategetAccountName7"
                defaultValue={"Upload udgift"}
              />,
              uploadingExpense,
              scanningExpense
            )}
          </LexButton>
        </div>
        <div className="text-center">{this.getFileErrorMessage()}</div>
        <input
          name="income"
          ref={this.incomeUpload}
          onChange={this.onIncomeSelected}
          type="file"
          accept="image/*,.pdf"
          multiple
          id="incomeUpload"
          style={{ display: "none" }}
        />
        <input
          name="expense"
          ref={this.expenseUpload}
          onChange={this.onExpenseSelected}
          type="file"
          accept="image/*,.pdf"
          multiple
          id="expenseUpload"
          style={{ display: "none" }}
        />
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    userProfile: store.userProfile,
    bookKeepingDraftEntries: store.bookKeepingDraftEntries,
    scanReceipts: !store.myContactInfo.skipReceiptScan,
    convertToPdf: !store.myContactInfo.skipPDFConversion,
  };
};

export default withData(AccountingMenu, mapStoreToProps);
