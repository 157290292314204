import PresentationApiService from "./PresentationApiService";
import PresentationStore from "./PresentationStore";

import { DataStore } from "../../services/AxoServices";

export default {
  //Presentations
  createPresentation(presentation) {
    return PresentationApiService.createPresentation(presentation).then(
      (response) => {
        this.updatePresentations(presentation.legal);
        return response;
      }
    );
  },
  updatePresentation(presentation) {
    return PresentationApiService.updatePresentation(presentation).then(
      (response) => {
        this.updatePresentations(presentation.legal);
        return response;
      }
    );
  },
  incrementViews(presentation) {
    return PresentationApiService.incrementViews(presentation).then(
      (response) => {
        return response;
      }
    );
  },

  deletePresentation(presentationId) {
    return PresentationApiService.deletePresentation(presentationId).then(
      (response) => {
        DataStore.fetchLegalPresentations();
        PresentationStore.fetchPresentations();
        return response;
      }
    );
  },

  updatePresentations(legal) {
    if (legal) {
      DataStore.fetchLegalPresentations();
    } else {
      PresentationStore.fetchPresentations();
    }
  },

  //Presentations
  createPresentationItem(presentationItem) {
    return PresentationApiService.createPresentationItem(presentationItem).then(
      (response) => {
        PresentationStore.fetchSinglePresentation(
          presentationItem.presentationId
        );
        return response;
      }
    );
  },

  updatePresentationItem(presentationItem) {
    return PresentationApiService.updatePresentationItem(presentationItem).then(
      (response) => {
        PresentationStore.fetchSinglePresentation(
          presentationItem.presentationId
        );
        return response;
      }
    );
  },

  deletePresentationItem(presentationItemId) {
    return PresentationApiService.deletePresentationItem(
      presentationItemId
    ).then((response) => {
      PresentationStore.fetchPresentations();
      return response;
    });
  },
};
