import React from "react";
import InvoiceV2 from "./InvoiceV2";

import {} from "../../services/AxoServices";

const InvoiceCreateView = (props) => {
  let {
    userProfile,
    contactMap,
    products,
    onCreateInvoice,
    clientPlan,
    isSubscription,
    createContact,
    updateContact,
  } = props;

  if (!userProfile.id) {
    return null;
  }

  const onApprove = async (invoice) => {
    // let { clientPlan } = props;

    let newInvoice = {
      ...invoice,
      status: "Approved",
    };

    onCreateInvoice(newInvoice);
    return true;

    // if(!!clientPlan.id) {
    //   let response = await DataActions.createInvoice(newInvoice);
    //   if(!response.ok) {
    //     return false;
    //   }

    //   let createdInvoice = await response.json();

    //   response = await ApiService.generateInvoicePostings(createdInvoice.id);
    //   if(!response.ok) {
    //     return false;
    //   }

    //   onCreatedInvoice(createdInvoice);

    //   return true;
    // }
    // else {
    //   onCreateInvoice(newInvoice);
    //   return true;
    // }
  };

  return (
    <div className="standardMaxWidth">
      <InvoiceV2
        timeEntries={[]}
        isSubscription={isSubscription}
        key={isSubscription} //Reload when changing between Invoice and Subscription tabs
        contactMap={contactMap}
        userProfile={userProfile}
        products={products}
        renderButtons={true}
        onSave={onCreateInvoice}
        onApprove={onApprove}
        clientPlan={clientPlan}
        createContact={createContact}
        updateContact={updateContact}
        onClose={() => {}}
        onCreate={() => {}}
      />
    </div>
  );
};

export default React.memo(InvoiceCreateView);
