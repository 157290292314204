import React from "react";
import TinyMCE from "react-tinymce";
import ApiService from "../../services/DataAccess/ApiService";
import DataActions from "../../services/DataAccess/DataActions";

import { debounce } from "lodash";
import * as $ from "jquery";

import {
  DocumentSearchBox,
  ImageSearchBox,
  Icon,
  Dispatcher,
  AlertModal,
  ErrorBoundary,
  AxoLocal,
} from "../../utilities/LexUtilities";

import { Row, Col, Grid, InputGroup, Button } from "react-bootstrap";

function fileUploadCallback(callback, value, meta) {
  if (meta.filetype === "image") {
    $("#imageUpload").trigger("click");
    $("#imageUpload").on("change", function () {
      if (this.files.length === 0) {
        return;
      }
      try {
        ApiService.uploadDocuments(this.files).then((response) => {
          if (response.status === 200) {
            response.json().then((fileModels) => {
              var path = ApiService.getFileInlinePath(fileModels[0].id);
              callback(path, { alt: fileModels[0].fileName });
            });
          } else {
            callback("", { alt: "" });
          }
        });
      } finally {
        $("#imageUpload").off("change");
        this.value = ""; //onChange handler should be triggered when uploading the same file twice.
      }
    });
  }
}

export default class TinyMCEEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imageSelectCallback: () => {},
      documentSelectCallback: () => {},
    };
    this.editor = null;
  }

  //Props
  //text
  //onTextChange
  //documents
  //allowImageImport
  setupEditor = (editor) => {
    editor.on("keyup", () => {
      this.onContentChange(editor.getContent());
    });
    editor.on("init", () => {
      this.initializeLineHeight(editor);
    });
    editor.on("FullscreenStateChanged", (event) => {
      Dispatcher.publish("editorFullScreen", event.state);
    });
    this.editor = editor;
    this.setState({
      imageSelectCallback: this.onSelectImage.bind(this, editor),
      documentSelectCallback: this.onSelectDocument.bind(this, editor),
      externalDocumentSelectCallback: this.onSelectExternalDocument.bind(
        this,
        editor
      ),
    });
  };

  initializeLineHeight(editor) {
    var content = editor.getContent();
    var newContent = content.replace(
      "<head>",
      "<head><style>body { line-height: 18pt; }</style>"
    );
    editor.setContent(newContent);
  }

  onSelectImage = (editor, document) => {
    var filePath = ApiService.getFileInlinePath(document.id);
    var htmlString =
      '<p><img src="' + filePath + '" alt="' + document.fileName + '" /></p>';
    editor.insertContent(htmlString);
  };

  onSelectDocument = (editor, document) => {
    ApiService.getDocXAsHTML(document.id).then((documentHtml) => {
      editor.setContent(documentHtml);
    });
  };

  onSelectExternalDocument = (editor) => {
    var component = this;
    $("#documentUpload").trigger("click");
    $("#documentUpload").on("change", function () {
      if (this.files.length === 0) {
        return;
      }
      try {
        var file = this.files[0];
        if (!file.name.toLowerCase().includes(".docx")) {
          component.alertModal.open(
            <AxoLocal
              entity="TinyMCEEditormyresponse"
              defaultValue={
                "Kun .docx filer er understøttet. Vælg en .docx fil i stedet."
              }
            />
          );
          return;
        }
        DataActions.uploadDocuments(this.files).then((response) => {
          if (response.status === 200) {
            response.json().then((fileModels) => {
              ApiService.getDocXAsHTML(fileModels[0].id).then(
                (documentHtml) => {
                  editor.insertContent(documentHtml);
                  DataActions.deleteDocument(fileModels[0].id);
                }
              );
            });
          }
        });
      } finally {
        $("#documentUpload").off("change");
        this.value = ""; //onChange handler should be triggered when uploading the same file twice.
      }
    });
  };

  convertLocale = () => {
    var locale = this.props.locale;
    switch (locale) {
      case "da":
        return "da";
      case "enUS":
        return "en_GB";
      case "ch":
        return "zh_CN";
      default:
        return "en_GB";
    }
  };

  ensureLineHeightEqualsFontSize = (content) => {
    var styleSpanPos = content.indexOf('<span style="');
    var newContent = content;
    while (styleSpanPos !== -1) {
      var endSpan = content.indexOf(">", styleSpanPos);
      var lineHeightPos = content.indexOf("line-height:", styleSpanPos);
      var fontSizePos = content.indexOf("font-size:", styleSpanPos);
      if (lineHeightPos < endSpan && fontSizePos < endSpan) {
        var lineHeight = content.substring(
          lineHeightPos + 12,
          content.indexOf("pt", lineHeightPos)
        );
        var fontSize = content.substring(
          fontSizePos + 10,
          content.indexOf("pt", fontSizePos)
        );
        lineHeight = parseInt(lineHeight, 10);
        fontSize = parseInt(fontSize, 10);
        if (!isNaN(lineHeight) && !isNaN(fontSize) && fontSize > lineHeight) {
          var newLineHeight = fontSize;
          var spanString = content.substring(styleSpanPos, endSpan);
          var newSpan = spanString.replace(
            "line-height: " + lineHeight.toString() + "pt",
            "line-height: " + newLineHeight.toString() + "pt"
          );
          newContent = newContent.replace(spanString, newSpan);
        }
      }
      styleSpanPos = content.indexOf('<span style="', styleSpanPos + 1);
    }
    return newContent;
  };

  // replaceDivsWithPs = (content) => {
  //   var newContent = content.replace('<div>', '<p>');
  //   newContent = content.replace('</div>', '</p>');
  //   return newContent;
  // }

  onChange = (event) => {
    this.onContentChange(event.target.getContent());
  };

  onContentChange = debounce((content) => {
    var updatedContent = this.ensureLineHeightEqualsFontSize(content);
    // var updatedContent = this.replaceDivsWithPs(content);

    if (updatedContent !== content) {
      this.editor.setContent(updatedContent);
    }
    this.props.onTextChange(updatedContent);
  }, 500);

  render() {
    var language = this.convertLocale();
    var { allowImageImport, onCreateCaseVersion } = this.props;
    var imagePicker = allowImageImport ? fileUploadCallback : null;
    return (
      <div>
        <div className="axobg no-margin" style={{ margin: "0px" }}>
          <Grid fluid>
            <Row>
              {allowImageImport ? (
                <Col lg={3} className="nopadding">
                  <InputGroup>
                    <InputGroup.Addon>
                      <Icon glyph="icon-fontello-picture-1" />
                    </InputGroup.Addon>
                    <AxoLocal
                      componentClass={ImageSearchBox}
                      maxWidth="500px"
                      className="editable"
                      documentMap={this.props.documentMap}
                      handleSelectedImage={this.state.imageSelectCallback}
                      componentAttribute="placeholder"
                      entity="TinyMCEEditormyImage"
                    />
                  </InputGroup>
                </Col>
              ) : null}
              <Col lg={3} className="nopadding">
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-doc-new" />
                  </InputGroup.Addon>
                  <AxoLocal
                    componentClass={DocumentSearchBox}
                    maxWidth="500px"
                    className="editable"
                    documentMap={this.props.documentMap}
                    onlyDocX
                    handleSelectedDocument={this.state.documentSelectCallback}
                    componentAttribute="placeholder"
                    entity="TinyMCEEditorInternalDoc"
                  />
                </InputGroup>
              </Col>
              <Col lg={3} className="text-left">
                <Button
                  className="Lex-button-2"
                  onClick={this.state.externalDocumentSelectCallback}
                >
                  <AxoLocal
                    entity="TinyMCEEditorIntegrateDoc"
                    defaultValue={"Integrer Word dokument i editor"}
                  />
                  &nbsp; <Icon role="button" glyph="icon-fontello-docs-1" />
                </Button>
                &nbsp;&nbsp;
              </Col>
              {onCreateCaseVersion ? (
                <Col lg={3} className="text-left">
                  <Button
                    className="Lex-button-2"
                    onClick={onCreateCaseVersion}
                  >
                    <AxoLocal
                      entity="CaseCaseVersion1"
                      defaultValue={"Opret fremstillingsversion"}
                    />
                    &nbsp;
                    <Icon role="button" glyph="icon-fontello-docs-1" />
                  </Button>
                  &nbsp;&nbsp;
                </Col>
              ) : null}
            </Row>
          </Grid>
        </div>
        <div className="mce-no-border">
          <ErrorBoundary>
            <TinyMCE
              key={language}
              content={this.props.text}
              config={{
                height: 500,
                branding: false,
                language: language,
                paste_data_images: true,
                image_advtab: true,
                valid_elements: "*[*]",
                valid_children: "+body[style]",
                cleanup_on_startup: false,
                forced_root_block: false,
                trim_span_elements: false,
                verify_html: false,
                cleanup: false,
                convert_urls: false,
                // forced_root_block : 'div',
                // content_style: "p {  margin-top: 0.7em; margin-bottom: 0.7em; }",
                paste_as_text: true,
                nonbreaking_force_tab: true,
                fontsize_formats:
                  "8pt 10pt 11pt 12pt 14pt 18pt 20pt 22pt 24pt 32pt 36pt 38pt 40pt 72pt",
                lineheight_formats:
                  "18pt 20pt 22pt 24pt 26pt 28pt 30pt 36pt 40pt 50pt 60pt 70pt 80pt",
                browser_spellcheck: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview hr anchor pagebreak lineheight",
                  "searchreplace wordcount visualblocks visualchars code fullscreen",
                  "insertdatetime media nonbreaking save table contextmenu directionality",
                  "emoticonsSimple template paste textcolor colorpicker textpattern imagetools codesample fullpage",
                ],
                menubar: "file edit insert format table tools ",
                toolbar1:
                  "fullpage | fullscreen | contextmenu | textpattern | wordcount | directionality | print preview | searchreplace | insertdatetime | hr | anchor | pagebreak | wordcount | visualblocks | table | advlist | autolink | lists | link | image  charmap  media  | backcolor |",
                toolbar2:
                  "undo redo | insert | styleselect | fontselect | lineheightselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ",
                toolbar3:
                  "rotateleft rotateright | flipv fliph | crop editimage imageoptions forecolor | emoticons codesample",
                file_picker_callback: imagePicker,
                setup: this.setupEditor,
              }}
              onChange={this.onChange}
            />
          </ErrorBoundary>
          <input
            name="image"
            type="file"
            id="imageUpload"
            accept="image/*"
            style={{ display: "none" }}
          />
          <input
            name="document"
            type="file"
            id="documentUpload"
            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            style={{ display: "none" }}
          />
          <AlertModal ref={(c) => (this.alertModal = c)} />
        </div>
      </div>
    );
  }
}
