import React from "react";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";

import {
  ApiService,
  DataActions,
  FileInfoService,
} from "../../services/AxoServices";

import { Icon, InlineEdit, AxoLocal } from "../../utilities/LexUtilities";

class ClientDocumentCard extends React.PureComponent {
  isNameAvailable = (document, value) => {
    var newName = value + document.fileType;
    var documentWithName = this.props.allDocuments.find(
      (d) => d.fileName === newName
    );

    if (documentWithName && documentWithName.id !== document.id) {
      this.setState({ showDuplicateNameError: true });
      return false;
    }
    if (this.state.showDuplicateNameError === true) {
      this.setState({ showDuplicateNameError: false });
    }
    return true;
  };

  onShowFiles = (documents) => {
    this.props.onShowFiles(documents);
  };

  renderDocumentTypeField = (document) => {
    var fileType = document.fileType.substring(1, document.fileType.length);
    if (fileType === "pdf") {
      return <Icon glyph="icon-fontello-acrobat" />;
    }
    if (fileType === "doc" || fileType === "docx") {
      return (
        <span>
          <Icon glyph="icon-fontello-doc-7" />
        </span>
      );
    }
    if (FileInfoService.isImage(document)) {
      return (
        <span>
          <Icon glyph="icon-fontello-picture-1" /> {fileType}
        </span>
      );
    }
    return fileType;
  };

  onChangeNotes = (input) => {
    var doc = this.props.document;
    doc.notes = input.value;
    DataActions.updateDocument(doc);
  };

  renderInformation = () => {
    var document = this.props.document;
    return (
      <div>
        <table className="table Lex-Grid-tabl">
          <tbody>
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-acrobat" />
                &nbsp;
                <AxoLocal entity="DocumentGridViewType" defaultValue={"Type"} />
                :
              </th>
              <td>{this.renderDocumentTypeField(document)}</td>
            </tr>
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-calendar-8" />
                &nbsp;{" "}
                <AxoLocal
                  entity="DocumentGridViewuploadDate"
                  defaultValue={"Upload dato"}
                />
                :
              </th>
              <td>{moment(document.uploadDate).format("L")} </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-clipboard-1" />
                &nbsp;
                <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />:
              </th>
              <td>
                <InlineEdit
                  value={document.notes || "---"}
                  change={this.onChangeNotes}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderContent = () => {
    return this.renderInformation();
  };

  openLink = (url) => {
    window.open(url);
  };

  renderLargeDocumentIcon = (document) => {
    var fileType = document.fileType.substring(1, document.fileType.length);
    var iconSize = "40px";
    if (fileType === "pdf") {
      return (
        <Icon
          className="axoblue"
          style={{ fontSize: iconSize }}
          glyph="icon-fontello-acrobat"
        />
      );
    }
    if (fileType === "doc" || fileType === "docx") {
      return (
        <span>
          <Icon
            className="axoblue"
            style={{ fontSize: iconSize }}
            glyph="icon-fontello-doc-7"
          />
        </span>
      );
    }
    if (FileInfoService.isImage(document)) {
      return (
        <span>
          <Icon
            className="axoblue"
            style={{ fontSize: iconSize }}
            glyph="icon-fontello-picture-1"
          />
        </span>
      );
    }
    return fileType;
  };

  renderPreview = (document) => {
    if (FileInfoService.isImage(document)) {
      return (
        <span
          role="button"
          className="pull-right"
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
          onClick={this.props.onDocumentClick.bind(this, document.id)}
        >
          <img
            alt=""
            src={ApiService.getFileInlinePath(document.id)}
            className="img-circle"
            width="40"
            height="40"
          />
        </span>
      );
    }
    return (
      <span className="pull-right" style={{ lineHeight: "50px" }}>
        {this.renderLargeDocumentIcon(document)}
      </span>
    );
  };

  render() {
    var document = this.props.document;
    return (
      <div>
        <div className="data-card">
          <div>
            <div className="gradient-baggrund">
              <Grid fluid>
                <Row>
                  <Col xs={12} className="editable text-center">
                    <span
                      role="button"
                      onClick={this.props.onDocumentClick.bind(
                        this,
                        document.id
                      )}
                      style={{ lineHeight: "50px" }}
                    >
                      {document.nameString}
                    </span>
                    {this.renderPreview(document)}
                  </Col>
                </Row>
              </Grid>
            </div>
            <div>
              <Grid fluid>
                <Row>
                  <Col xs={12}>{this.renderContent()}</Col>
                </Row>
              </Grid>
            </div>
            <div className="Lex-CardFooter">
              <Grid fluid>
                <Row>
                  <Col xs={12} style={{ padding: "0px" }}>
                    <ButtonGroup justified>
                      <ButtonGroup>
                        <button
                          type="button"
                          className=" visButtoncolor btn Lex-button"
                          onClick={this.props.onDocumentClick.bind(
                            this,
                            document.id
                          )}
                        >
                          <Icon glyph="icon-fontello-eye-1" />
                          &nbsp; &nbsp;
                          <AxoLocal
                            entity="axoAccounting70"
                            defaultValue={"Vis"}
                          />
                          &nbsp;
                        </button>
                      </ButtonGroup>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.props.onSendFile.bind(
                            this,
                            document.id
                          )}
                        >
                          <Icon role="button" glyph="icon-fontello-mail-1" />{" "}
                          &nbsp;&nbsp;
                          <AxoLocal
                            entity="DocumentGridViewsendto"
                            defaultValue={"Send til"}
                          />
                        </button>
                      </ButtonGroup>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.props.onTrashFile.bind(
                            this,
                            document.id
                          )}
                        >
                          <Icon role="button" glyph="icon-fontello-trash-1" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            entity="axoidcode179"
                            defaultValue={"Slet"}
                          />
                        </button>
                      </ButtonGroup>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ClientDocumentGridView extends React.PureComponent {
  //Props
  //cases
  //activeDocumentId
  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateDocCols = (documents) => {
    var cols = [[], [], []];
    var smallCols = [[], []];
    for (var i = 0; i < documents.length; i++) {
      var document = documents[i];
      //var docLabels = this.props.labels.filter( l => l.documentIds.includes(document.id));

      var docCard = (
        <ClientDocumentCard
          key={document.id}
          document={document}
          allDocuments={this.props.documents}
          active={this.props.activeDocumentId === document.id}
          labels={this.props.labels}
          cases={this.props.cases}
          friends={this.props.friends}
          onShowFiles={this.props.onShowFiles}
          onDocumentChange={this.onDocumentChange}
          onStatusSelect={this.props.onStatusSelect}
          onPrioritySelect={this.props.onPrioritySelect}
          onCaseDateChange={this.props.onCaseDateChange}
          onDocumentClick={this.props.onDocumentClick}
          onDeadlineChange={this.props.onDeadlineChange}
          removeDeadlineFromDocument={this.props.removeDeadlineFromDocument}
          addDeadlineToDocument={this.props.addDeadlineToDocument}
          onTrashFile={this.props.onTrashFile}
          onSendFile={this.props.onSendFile}
        />
      );

      cols[i % 3].push(docCard);
      smallCols[i % 2].push(docCard);
    }
    return [cols, smallCols];
  };

  renderDocumentGrid = (documents) => {
    var cols = this.generateDocCols(documents);
    var largeCols = cols[0];
    var smallCols = cols[1];
    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[0]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[1]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  onDocumentChange = (document) => {
    DataActions.updateDocument(document);
  };

  getShownDocuments = () => {
    return this.props.documents.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    return (
      <Grid fluid style={{ background: "#ffffff" }}>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            {this.renderDocumentGrid(this.getShownDocuments())}
          </Col>
        </Row>
        {this.state.numberShown < this.props.documents.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col xs={12} className="text-center" style={{ paddingTop: "10px" }}>
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2 "
                style={{ width: "75%" }}
              >
                <AxoLocal
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(ClientDocumentGridView);
