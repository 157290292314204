// @ts-check
import React from "react";
import PropTypes from "prop-types";
import * as $ from "jquery";
import AxolexDataBehandlerAftale from "../../resources/files/AxolexDatabehandlerAftale.pdf";
import AxolexDataProcessingAgreement from "../../resources/files/AxolexDataProcessingAgreement.pdf";
import AxolexForeningsManual from "../../resources/files/AxolexForeningsManual.pdf";

import {
  Grid,
  Row,
  Col,
  Button,
  FormControl,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";

import {
  ApiService,
  DataActions,
  DataStore,
  ModalService,
  UserInfoService,
  VatService,
  ThemeService,
  AuthorizationService,
} from "../../services/AxoServices";

import {
  DocumentSearchBox,
  LibraryFileSearchBox,
  Icon,
  AxoCheckbox,
  LexButton,
  AxoLocal,
  InlineEdit,
  ctx,
  InfoText,
  CountryDropdown,
  RegionDropdown,
  LoadingIcon,
  MitIDModal,
} from "../../utilities/LexUtilities";

import { LocalizationService } from "../../services/AxoServices";

class ThemeDropdown extends React.PureComponent {
  static propTypes = {
    global: PropTypes.string,
    globalSettings: PropTypes.object,
    theme: PropTypes.string,
  };

  handleSelect = (theme) => {
    let { global } = this.props;
    if (!!global) {
      switch (global) {
        case "FrontPage":
          DataActions.createOrUpdateGlobalSetting({
            key: "themeFront",
            value: theme,
          });
          break;
        case "Lawyer":
          DataActions.createOrUpdateGlobalSetting({
            key: "theme",
            value: theme,
          });
          break;
        case "Client":
          DataActions.createOrUpdateGlobalSetting({
            key: "themeClient",
            value: theme,
          });
          break;
        case "Accountant":
          DataActions.createOrUpdateGlobalSetting({
            key: "themeAccountant",
            value: theme,
          });
          break;
        case "Society":
          DataActions.createOrUpdateGlobalSetting({
            key: "themeSociety",
            value: theme,
          });
          break;
        default:
          DataActions.createOrUpdateGlobalSetting({
            key: "theme",
            value: theme,
          });
          break;
      }
    } else {
      DataStore.setTheme(theme);
    }
  };

  getThemeName = () => {
    let { global, globalSettings, theme } = this.props;

    if (!!global) {
      switch (global) {
        case "FrontPage":
          return globalSettings.themeFront;
        case "Lawyer":
          return globalSettings.theme;
        case "Client":
          return globalSettings.themeClient;
        case "Accountant":
          return globalSettings.themeAccountant;
        case "Society":
          return globalSettings.themeSociety;
        default:
          return globalSettings.theme;
      }
    } else {
      return theme;
    }
  };

  getBackground = (theme) => {
    switch (theme) {
      case "theme-redaxo15":
        return "linear-gradient(#1C4E80, #1C4E80)";
      case "theme-redaxo14":
        return "linear-gradient(#5f5f5f, #5f5f5f)";
      case "theme-aquaA":
        return "linear-gradient(#f4f4f4, #f4f4f4)";
      case "theme-aqua":
        return "linear-gradient(#375197, #375197 )";
      case "theme-darkViolet":
        return "linear-gradient(#00897b, #00897b)";
      case "theme-gold":
        return "linear-gradient(#6B7A8F,#6B7A8F)";
      case "theme-green":
        return "linear-gradient(#34675C,#34675C)";
      case "theme-mediumSpringGreen":
        return "linear-gradient( #1995AD, #1995AD)";
      case "theme-olive":
        return "linear-gradient(#912F1A, #912F1A)";
      case "theme-peru":
        return "linear-gradient(#00293C, #00293C)";
      case "theme-pink":
        return "linear-gradient(#428bca, #428bca)";
      case "theme-red":
        return "linear-gradient(#d97490, #d97490)";
      case "theme-redaxo":
        return "linear-gradient(#75a0bd, #75a0bd)";
      case "theme-redaxo1":
        return "linear-gradient(#426e8a, #426e8a)";
      case "theme-redaxo2":
        return "linear-gradient(#003755, #003755)";
      case "theme-redaxo3":
        return "linear-gradient(#a97070,#a97070)";
      default:
        return "linear-gradient(#e6e7e9, #e6e7e9)";
    }
  };

  getTitleColor = (theme) => {
    if (theme === "theme-Standard" || theme === "theme-aquaA") {
      return "black";
    }
    return "white";
  };

  render() {
    let themeName = this.getThemeName();

    return (
      <div className="axo-dropdown">
        <DropdownButton
          id="theme-dropdown"
          className="axoDropdownButton"
          style={{ width: "100%", background: this.getBackground(themeName) }}
          title={
            <span style={{ color: this.getTitleColor(themeName) }}>
              {themeName}
            </span>
          }
          onSelect={this.handleSelect}
        >
          <MenuItem eventKey={ThemeService.getStandard()}>
            <div
              className="text-center"
              style={{
                height: "37px",
                lineHeight: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground(),
                color: "#000",
                borderBottom: "1px solid rgb(85, 85, 85)",
              }}
            >
              {" "}
              <AxoLocal entity="AxoThemesheader1" defaultValue="Standard" />
            </div>
          </MenuItem>
          <MenuItem eventKey="theme-redaxo15">
            <div
              className="text-center"
              style={{
                height: "37px",
                fontSize: "15px",
                lineHeight: "37px",
                backgroundImage: this.getBackground("theme-redaxo15"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-redaxo14">
            <div
              className="text-center"
              style={{
                height: "37px",
                lineHeight: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-redaxo14"),
                borderBottom: "1px solid rgb(85, 85, 85)",
              }}
            ></div>
          </MenuItem>
          {/* <MenuItem eventKey='theme-redaxo15'>
          <div className='text-center' style={{ height:'37px',lineHeight:'37px', fontSize: '15px', backgroundImage: 'linear-gradient(#1C4E80, #1C4E80)', 
          borderBottom: '1px solid rgb(85, 85, 85)' }}>
          </div>
          </MenuItem>  */}
          <MenuItem eventKey="theme-aquaA">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-aquaA"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-aqua">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-aqua"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-darkViolet">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-darkViolet"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-gold">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-gold"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-green">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-green"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-mediumSpringGreen">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-mediumSpringGreen"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-olive">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-olive"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-peru">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-peru"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-pink">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-pink"),
              }}
            ></div>
          </MenuItem>

          <MenuItem eventKey="theme-red">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-red"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-redaxo">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-redaxo"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-redaxo1">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-redaxo1"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-redaxo2">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-redaxo2"),
              }}
            ></div>
          </MenuItem>
          <MenuItem eventKey="theme-redaxo3">
            <div
              style={{
                height: "37px",
                fontSize: "15px",
                backgroundImage: this.getBackground("theme-redaxo3"),
              }}
            ></div>
          </MenuItem>
        </DropdownButton>
      </div>
    );
  }
}

export default class UserProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmMailSent: false,
      confirmCodeSent: false,
      confirmationCode: "",
      changePassword: false,
      newPasswordViewModel: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      locale: "enUS",
      changePasswordSuccess: false,
      showDuplicateMailWarning: false,
      showDuplicatePhoneWarning: false,
      validatingVATNumber: false,
      showInvalidVATNumberWarning: false,
    };
    this.mitIDModal = React.createRef();

    this.name = "UserProfileForm";
  }

  componentDidMount() {
    LocalizationService.subscribeToLocale(this.name, (locale) => {
      locale = locale.toLowerCase();
      if (locale === "enus") {
        locale = "enUS";
      }
      this.setState({ locale });
    });
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale(this.name);
  }

  onUpdate = (userProfile) => {
    DataStore.setState({ userProfile });
    return ApiService.updateUserProfile(userProfile)
      .then((response) => {
        DataStore.fetchUserProfile();
        return response.json();
      })
      .then((parsedResponse) => {
        if (parsedResponse.error === "DuplicateEmail") {
          this.setBooleanStateTransient("showDuplicateMailWarning", true);
        } else if (parsedResponse.error === "DuplicatePhone") {
          this.setBooleanStateTransient("showDuplicatePhoneWarning", true);
        }
      });
  };

  setBooleanStateTransient = (propertyName, value) => {
    this.setState({ [propertyName]: value });
    setTimeout(() => {
      this.setState({ [propertyName]: !value });
    }, 5000);
  };

  onProfilePropertyChange(propertyName, input) {
    var profile = Object.assign({}, this.props.userProfile);
    profile[propertyName] = input.value;
    this.onUpdate(profile);
  }
  onCheckAccept = (event) => {
    this.props.userProfile.acceptRequests = event.target.checked;
    this.onUpdate(this.props.userProfile);
  };
  handleSelectedLogo = (document) => {
    this.props.userProfile.logo = document;
    this.props.userProfile.logoLibrary = "";
    this.onUpdate(this.props.userProfile);
  };
  handleSelectedLibraryLogo = (fileName) => {
    this.props.userProfile.logo = null;
    this.props.userProfile.logoLibrary = fileName;
    this.onUpdate(this.props.userProfile);
  };
  uploadLogo = () => {
    this.uploadFile();
  };

  uploadFile = () => {
    var component = this;
    $("#uploadImage").trigger("click");
    $("#uploadImage").on("change", function () {
      var file = this.files[0];
      if (!file.type.includes("image")) {
        return;
      }
      DataActions.uploadDocuments(this.files).then((response) => {
        if (response.ok) {
          response.json().then((addedFiles) => {
            component.onUpdate({
              ...component.props.userProfile,
              logo: addedFiles[0],
              logoLibrary: "",
            });
          });
        }
      });
    });
  };

  onSendConfirmationMail = () => {
    DataActions.confirmMail({
      subject: ctx.getSync("SignupMailSubject"),
      body: ctx.getSync("SignupMailMessage"),
    }).then((response) => {
      if (response.ok) {
        this.setState({ confirmMailSent: true });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="SidebarRightContainerTheaddresscorrect"
            defaultValue={
              "Beskeden kunne ikke sendes. Tjek at adressen er korrekt."
            }
          />
        );
      }
    });
  };

  renderEmailConfirmationField = () => {
    var userProfile = this.props.userProfile;
    if (userProfile.eMailConfirmed) {
      return null;
    }
    if (this.state.confirmMailSent) {
      return (
        <div>
          {" "}
          <AxoLocal
            entity="SidebarRightContainerconfirmMailSent"
            defaultValue={"Bekræftelsesmail er afsendt"}
          />{" "}
        </div>
      );
    }
    return (
      <div>
        <div className="axored">
          <b>
            {" "}
            <AxoLocal
              entity="SidebarRightContainerEmailnotconfirmed"
              defaultValue={"Email ikke bekræftet"}
            />
          </b>
        </div>
        <Button className="Lex-button-2 " onClick={this.onSendConfirmationMail}>
          <span>
            {" "}
            <Icon glyph="icon-fontello-forward-outline" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="SidebarRightContainerSendconfirmationemail"
              defaultValue={"Send bekræftelsesmail"}
            />
          </span>
        </Button>
      </div>
    );
  };

  onSendConfirmationCode = () => {
    DataActions.confirmPhone(ctx.getSync("ConfirmPhoneMessage")).then(
      (response) => {
        if (response.ok) {
          this.setState({ confirmCodeSent: true });
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="SidebarRightContainerThemessagecorrect"
              defaultValue={
                "Beskeden kunne ikke sendes. Tjek at nummeret er korrekt."
              }
            />
          );
        }
      }
    );
  };

  onChangeCode = (event) => {
    this.setState({ confirmationCode: event.target.value });
  };

  onEnterConfirmationCode = () => {
    DataActions.confirmPhoneCode(this.state.confirmationCode).then(
      (response) => {
        if (response.ok) {
          this.setState({ confirmCodeSent: false });
          //Optimistic updating
          DataStore.updateProperty("userProfile", {
            ...this.props.userProfile,
            internalPhoneConfirmed: true,
          });
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="SidebarRightContainerThenumbercorrect"
              defaultValue={
                "Nummeret kunne ikke godkendes. Tjek at koden er korrekt."
              }
            />
          );
        }
      }
    );
  };

  renderPhoneConfirmationField = () => {
    var userProfile = this.props.userProfile;
    if (!userProfile.internalPhone || userProfile.internalPhoneConfirmed) {
      return null;
    }
    if (this.state.confirmCodeSent) {
      return (
        <div>
          <div>
            <b>
              {" "}
              <AxoLocal
                entity="SidebarRightContainerEntertheverificationcode"
                defaultValue={"Indtast bekræftelseskode"}
              />
            </b>
          </div>
          <div>
            <input
              type="text"
              value={this.state.confirmationCode}
              onChange={this.onChangeCode}
            />
            <Button
              className="Lex-button-2 "
              onClick={this.onEnterConfirmationCode}
            >
              <AxoLocal
                entity="SidebarRightContainerSendButton"
                defaultValue={"Send"}
              />
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="axored">
          <b>
            {" "}
            <AxoLocal
              entity="SidebarRightSendConfirmationCode"
              defaultValue={"Telefon ikke bekræftet"}
            />
          </b>
        </div>
        <Button className="Lex-button-2 " onClick={this.onSendConfirmationCode}>
          <span>
            {" "}
            <Icon glyph="icon-fontello-forward-outline" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="SidebarRightConfirmaSendConfirmationCode"
              defaultValue={"Send bekræftelseskode"}
            />
          </span>
        </Button>
      </div>
    );
  };

  onChangePassword = () => {
    this.setState({ changePassword: true });
  };

  onSubmitPassword = () => {
    DataActions.changePassword(this.state.newPasswordViewModel).then(
      (response) => {
        if (response.ok) {
          this.setState({
            changePassword: false,
            newPasswordViewModel: {
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            },
            changePasswordSuccess: true,
          });
          setTimeout(() => {
            this.setState({ changePasswordSuccess: false });
          }, 3000);
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="SidebarRightContainerThepasswordleastlength"
              defaultValue={"Kodeordet skal indeholde mindst fire tegn."}
            />
          );
        }
      }
    );
  };

  onNewPasswordPropertyChange = (propertyName, event) => {
    var viewModel = Object.assign({}, this.state.newPasswordViewModel);
    viewModel[propertyName] = event.target.value;
    this.setState({ newPasswordViewModel: viewModel });
  };

  renderPasswordField = () => {
    var viewModel = this.state.newPasswordViewModel;
    if (this.state.changePassword) {
      return (
        <div>
          <Grid fluid>
            <Row>
              <Col style={{ padding: "0px" }}>
                <div>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContainerchangePassword"
                      defaultValue={"Skift kodeord"}
                    />
                  </b>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "0px" }} sm={4}>
                <div>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContainerPresentPassword"
                      defaultValue={"Nuværende"}
                    />
                    :
                  </b>
                </div>
              </Col>
              <Col style={{ padding: "0px" }} sm={8}>
                <FormControl
                  type="password"
                  value={viewModel.oldPassword}
                  onChange={this.onNewPasswordPropertyChange.bind(
                    this,
                    "oldPassword"
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "0px" }} sm={4}>
                <div>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContainerNewpassword"
                      defaultValue={"Nyt kodeord"}
                    />
                    :
                  </b>
                </div>
              </Col>
              <Col style={{ padding: "0px" }} sm={8}>
                <AxoLocal
                  componentClass={FormControl}
                  type="password"
                  value={viewModel.newPassword}
                  onChange={this.onNewPasswordPropertyChange.bind(
                    this,
                    "newPassword"
                  )}
                  componentAttribute="placeholder"
                  entity="SidebarRightonNewPasswordPropertyChange"
                />
              </Col>
              {viewModel.newPassword && viewModel.newPassword.length < 4 ? (
                <div className="axored">
                  <AxoLocal
                    entity="SidebarRightContainernewPasswordlength"
                    defaultValue={"Kodeordet skal være på mindst 4 tegn."}
                  />
                </div>
              ) : null}
            </Row>
            <Row>
              <Col style={{ padding: "0px" }} sm={4}>
                <div>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContainerConfirmPassword"
                      defaultValue={"Bekræft"}
                    />
                    :
                  </b>
                </div>
              </Col>
              <Col style={{ padding: "0px" }} sm={8}>
                <FormControl
                  type="password"
                  value={viewModel.confirmPassword}
                  onChange={this.onNewPasswordPropertyChange.bind(
                    this,
                    "confirmPassword"
                  )}
                />
                {viewModel.confirmPassword &&
                viewModel.newPassword !== viewModel.confirmPassword ? (
                  <div className="axored">
                    <AxoLocal
                      entity="SidebarRightConfirmatwonewpasswordarenotthesame"
                      defaultValue={"De to nye kodeord er ikke ens."}
                    />{" "}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "5px 0px" }} xs={12} className="text-left">
                <Button
                  className="Lex-button-2 "
                  onClick={this.onSubmitPassword}
                >
                  <AxoLocal
                    entity="SidebarRightContainerchangePasswordSubmit"
                    defaultValue={"Skift"}
                  />
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  className="Lex-button-2 "
                  onClick={() => {
                    this.setState({ changePassword: false });
                  }}
                >
                  <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
                </Button>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        <div>
          <Icon glyph="icon-fontello-qrcode" />
          &nbsp;
          <b>
            <AxoLocal
              entity="SidebarRightContainerPassword"
              defaultValue={"Kodeord"}
            />
            :
          </b>
          ********* &nbsp;&nbsp;&nbsp;&nbsp;
          <Button className="Lex-button-2 " onClick={this.onChangePassword}>
            <AxoLocal
              entity="SidebarRightContaineronChangePassword"
              defaultValue={"Skift"}
            />
          </Button>
        </div>
        {this.state.changePasswordSuccess ? (
          <div style={{ color: "#4CAF50" }}>
            <Icon glyph="icon-fontello-ok-3" />
            &nbsp;
            <AxoLocal
              entity="SidebarRightContainerchangePasswordSucces"
              defaultValue={"Kodeordet blev skiftet."}
            />
          </div>
        ) : null}
      </div>
    );
  };

  renderTwoFactorOn = (enabled) => {
    if (enabled) {
      return (
        <b>
          <AxoLocal
            entity="SidebarRightContainerTurnedon"
            defaultValue={"Enabled"}
          />
        </b>
      );
    }
    return (
      <Button className="Lex-button-2" onClick={this.enableTwoFactor}>
        <AxoLocal
          entity="SidebarRightContainerTurnOn"
          defaultValue={"Enable"}
        />
      </Button>
    );
  };

  renderTwoFactorOff = (enabled) => {
    if (enabled) {
      return (
        <Button className="Lex-button-2" onClick={this.disableTwoFactor}>
          <AxoLocal
            entity="SidebarRightContainerTurnoff"
            defaultValue={"Disable"}
          />
        </Button>
      );
    }
    return (
      <b>
        <AxoLocal
          entity="SidebarRightConfirmaDisabled"
          defaultValue={"Disabled"}
        />
      </b>
    );
  };

  enableTwoFactor = () => {
    DataActions.enableTwoFactor();
  };

  disableTwoFactor = () => {
    DataActions.disableTwoFactor();
  };

  renderTwoFactorField = () => {
    var userProfile = this.props.userProfile;
    return (
      <div>
        <span>
          <Icon glyph="icon-fontello-barcode-1" />
          &nbsp;
          <b>
            <AxoLocal
              entity="SidebarRightContainerTofaktor"
              defaultValue={"To-faktor"}
            />
            :
          </b>{" "}
        </span>
        &nbsp;&nbsp;
        {this.renderTwoFactorOn(userProfile.twoFactorEnabled)}
        &nbsp;&nbsp;
        {this.renderTwoFactorOff(userProfile.twoFactorEnabled)}
      </div>
    );
  };

  onChangeShowInfoTexts = (event) => {
    var userSettings = Object.assign({}, this.props.userSettings);
    userSettings.showInfoTexts = event.target.checked;
    DataActions.updateUserSettings(userSettings);
  };

  renderInfoTextField = () => {
    var userSettings = this.props.userSettings;
    return (
      <span>
        <div>
          <AxoCheckbox
            checked={userSettings.showInfoTexts}
            onChange={this.onChangeShowInfoTexts}
          />
          <b>
            <AxoLocal
              entity="SidebarRightShowInfoTexts"
              defaultValue="Vis infotekster"
            />
          </b>
        </div>
        <InfoText name="sample">
          <div>
            <AxoLocal
              entity="SidebarRightSampleInfoText"
              defaultValue="Når infotekster er slået til, vil der blive vist hjælpsomme tips på siden."
            />
          </div>
        </InfoText>
      </span>
    );
  };

  onRemoveLogo = () => {
    var userProfile = Object.assign({}, this.props.userProfile);
    userProfile.logo = null;
    userProfile.logoLibrary = "";
    this.onUpdate(userProfile);
  };

  onCurrencySelect = (event) => {
    var userProfile = Object.assign({}, this.props.userProfile);
    userProfile.currency = event.target.value;
    this.onUpdate(userProfile);
  };

  renderCurrencyField = (userProfile) => {
    return (
      <div>
        <b>
          <Icon glyph="icon-fontello-database" />
          &nbsp;
          <AxoLocal entity="SidebarRightCurrency" defaultValue={"Valuta"} />
          :&nbsp;
        </b>
        {this.renderCurrencySelect(userProfile)}
      </div>
    );
  };

  renderCurrencySelect = (userProfile) => {
    return (
      <select
        className="axoblue selectbg "
        value={userProfile.currency || "USD"}
        onChange={this.onCurrencySelect}
      >
        <option value="DKK">DKK</option>
        <option value="USD">USD</option>
        <option value="GBP">GBP</option>
        <option value="EUR">EUR</option>
        <option value="CNY">CNY</option>
        <option value="SEK">SEK</option>
        <option value="NKK">NKK</option>
        <option value="ISK">ISK</option>
      </select>
    );
  };

  selectCountry(val) {
    this.onUpdate({
      ...this.props.userProfile,
      country: val,
      vatNumberConfirmed: false,
    });
  }

  selectRegion(val) {
    this.onUpdate({
      ...this.props.userProfile,
      region: val,
    });
  }

  renderInputField = (label, propertyName) => {
    var userProfile = this.props.userProfile;
    return (
      <div>
        <b>{label}:</b>{" "}
        <InlineEdit
          value={userProfile[propertyName] || "---"}
          change={this.onProfilePropertyChange.bind(this, propertyName)}
        />
      </div>
    );
  };

  onConfirmVatNumber = async () => {
    let userProfile = { ...this.props.userProfile };

    try {
      this.setState({ validatingVATNumber: true });

      let companyInfo = await ApiService.getCompanyInfo(
        userProfile.country || "",
        userProfile.companyCode
      );
      if (!companyInfo.valid) {
        this.handleVATNumberConfirmationFailure();
      }

      this.setState({ validatingVATNumber: false });

      userProfile.company = companyInfo.name[0];
      userProfile.vatNumberConfirmed = true;
      this.onUpdate(userProfile);
    } catch (error) {
      this.handleVATNumberConfirmationFailure();
    }
  };

  handleVATNumberConfirmationFailure = () => {
    this.setState({
      validatingVATNumber: false,
      showInvalidVATNumberWarning: true,
    });
    setTimeout(() => {
      this.setState({
        showInvalidVATNumberWarning: false,
      });
    }, 3000);
  };

  onChangeVatNumber = (value) => {
    this.onUpdate({
      ...this.props.userProfile,
      companyCode: value,
      vatNumberConfirmed: false,
    });
  };

  onUpdatePaymentApiKey = (input) => {
    DataActions.updateUserSettings({
      ...this.props.userSettings,
      paymentApiKey: input.value,
    });
  };

  // assignEasyID = () => {
  //   this.easyIDModal.current.open();
  // };

  // onEasyIdLogin = async (userId) => {
  //   let response = await DataActions.updateUserSettings({
  //     ...this.props.userSettings,
  //     easyIDCode: userId,
  //   });

  //   if (response.ok) {
  //     //Necessary to reset the Easy ID session
  //     window.location.reload();
  //   } else {
  //     ModalService.openAlertModal(
  //       "NemID identiteten kunne ikke tilknyttes kontoen. Tjeck at NemID ikke er i brug hos en anden konto.",
  //       () => window.location.reload()
  //     );
  //   }
  // };

  assignMitID = () => {
    this.mitIDModal.current.open();
  };

  onMitIdLogin = async (userId) => {
    if(!userId) {
      return;
    }
    
    let response = await DataActions.updateUserSettings({
      ...this.props.userSettings,
      easyIDCode: userId,
    });

    if (response.ok) {
      //Necessary to reset the Easy ID session
      //window.location.reload();
    } else {
      ModalService.openAlertModal(
        "MitID identiteten kunne ikke tilknyttes kontoen. Tjeck at MitID ikke er i brug hos en anden konto.",
        () => window.location.reload()
      );
    }
  };

  render() {
    let { userProfile, userSettings, globalSettings, theme } = this.props;

    let { locale, validatingVATNumber, showInvalidVATNumberWarning } =
      this.state;

    if (!userProfile) {
      return null;
    }

    let country = userProfile.country || "";
    let isEUCountry = VatService.isEUCountry(country);
    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div className="text-m">
        <div>
          <Icon glyph="icon-fontello-user" />
          &nbsp;{" "}
          <span>
            <b>
              <AxoLocal
                entity="SidebarRightContaineruserProfileuserName"
                defaultValue={"Brugernavn"}
              />
              :
            </b>
          </span>
          &nbsp;
          {userProfile.userName}
        </div>
        {this.renderPasswordField()}
        {this.renderTwoFactorField()}
        {userType === "Admin" ? (
          <div style={{ maxWidth: "400px" }}>
            <ThemeDropdown
              globalSettings={globalSettings}
              userType={userType}
              theme={theme}
            />
          </div>
        ) : null}
        {userType === "Admin" ? (
          <div style={{ maxWidth: "400px" }}>
            <div>
              <AxoLocal
                entity="sidebaravatarFrontpage"
                defaultValue="Forside"
              />
            </div>
            <ThemeDropdown
              global="FrontPage"
              globalSettings={globalSettings}
              theme={theme}
            />
          </div>
        ) : null}
        {userType === "Admin" ? (
          <div style={{ maxWidth: "400px" }}>
            <div>
              <AxoLocal entity="Axolegal1a" defaultValue={"Global"} />
            </div>
            <ThemeDropdown
              global="Lawyer"
              globalSettings={globalSettings}
              theme={theme}
            />
          </div>
        ) : null}
        {userType === "Admin" ? (
          <div style={{ maxWidth: "400px" }}>
            <div>
              {" "}
              <AxoLocal
                entity="axoidcode29"
                defaultValue={"Global - revisor"}
              />
            </div>
            <ThemeDropdown
              global="Accountant"
              globalSettings={globalSettings}
              theme={theme}
            />
          </div>
        ) : null}
        {userType === "Admin" ? (
          <div style={{ maxWidth: "400px" }}>
            <div>
              <AxoLocal entity="axoidcode30" defaultValue={"Global - klient"} />
            </div>
            <ThemeDropdown
              global="Client"
              globalSettings={globalSettings}
              theme={theme}
            />
          </div>
        ) : null}
        {userType === "Admin" ? (
          <div style={{ maxWidth: "400px" }}>
            <div>
              <AxoLocal
                entity="axoidcode31"
                defaultValue={"Global - forening"}
              />
            </div>
            <ThemeDropdown
              global="Society"
              globalSettings={globalSettings}
              theme={theme}
            />
          </div>
        ) : null}

        {/* {this.renderInfoTextField()} */}
        <div>
          <Icon glyph="icon-fontello-user-7" />
          &nbsp;
          <span>
            <b>
              <AxoLocal
                entity="SidebarRightContaineruseruserName"
                defaultValue={"Navn"}
              />
              :
            </b>
          </span>{" "}
          <InlineEdit
            value={userProfile.firstName || "---"}
            change={this.onProfilePropertyChange.bind(this, "firstName")}
          />{" "}
          <InlineEdit
            value={userProfile.lastName || "---"}
            change={this.onProfilePropertyChange.bind(this, "lastName")}
          />
        </div>
        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-building" />
            &nbsp;
            <AxoLocal
              entity="SidebarRightContainercompanyName"
              defaultValue={"Virksomhed"}
            />
          </span>,
          "company"
        )}

        <div>
          <b>
            <span>
              <Icon glyph="icon-fontello-target-4" />
              &nbsp;
              <AxoLocal
                entity="SidebarRightContainerCompanyCode"
                defaultValue={"CVR"}
              />
            </span>
            :
          </b>
          {country.toUpperCase() !== "DK" && isEUCountry ? (
            <span>&nbsp;{country}</span>
          ) : null}
          <InlineEdit
            value={userProfile.companyCode || "---"}
            change={(input) => this.onChangeVatNumber(input.value)}
          />
          {isEUCountry ? (
            <React.Fragment>
              &nbsp;
              <LexButton
                disabled={
                  userProfile.vatNumberConfirmed ||
                  validatingVATNumber ||
                  showInvalidVATNumberWarning
                }
                onClick={this.onConfirmVatNumber}
              >
                {userProfile.vatNumberConfirmed ? (
                  <span>
                    <AxoLocal
                      key="validated"
                      entity="axoAccounting62a"
                      defaultValue={"Valideret"}
                    />
                    &nbsp;
                    <Icon glyph="icon-fontello-ok-3" />
                  </span>
                ) : (
                  <span>
                    <AxoLocal
                      key="validate"
                      entity="axoAccounting63"
                      defaultValue={"Valider"}
                    />{" "}
                  </span>
                )}

                <LoadingIcon show={validatingVATNumber} />
              </LexButton>
              {showInvalidVATNumberWarning ? (
                <div className="axored">
                  <AxoLocal
                    entity="axoAccounting65"
                    defaultValue={"CVR nummeret kunne ikke valideres."}
                  />
                </div>
              ) : null}
              {country.toUpperCase() !== "DK" &&
              !userProfile.vatNumberConfirmed ? (
                <div className="axored">
                  <AxoLocal
                    entity="axoAccounting64"
                    defaultValue={"Du skal validere dit CVR nummer."}
                  />
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-feather" />
            &nbsp;
            <AxoLocal
              entity="SidebarRightContainerStilling"
              defaultValue={"Stilling"}
            />
          </span>,
          "position"
        )}
        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-location-4" />
            &nbsp;
            <AxoLocal entity="SignupAdresse" defaultValue={"Adresse "} />
          </span>,
          "address"
        )}
        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-direction-1" />
            &nbsp;
            <AxoLocal
              entity="SidebarRightContainerpostalCode"
              defaultValue={"Postnummer"}
            />
          </span>,
          "postalCode"
        )}
        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-warehouse" />
            &nbsp;
            <AxoLocal entity="SidebarRightContainerCity" defaultValue={"By"} />
          </span>,
          "city"
        )}

        <div>
          <b>
            <span>
              <Icon glyph="icon-fontello-globe-6" />
              &nbsp;
              <AxoLocal
                entity="SidebarRightContainerCountry"
                defaultValue={"Land"}
              />
            </span>
            :
          </b>{" "}
          <div style={{ display: "inline-block", width: "75%" }}>
            <CountryDropdown
              value={country || ""}
              valueType="short"
              customSelect
              onChange={(val) => this.selectCountry(val)}
            />
          </div>
        </div>
        <div>
          <b>
            <span>
              <Icon glyph="icon-fontello-globe-outline" />
              &nbsp;
              <AxoLocal
                entity="SidebarRightContainerRegion"
                defaultValue={"Region"}
              />
            </span>
            :
          </b>{" "}
          <div style={{ display: "inline-block", width: "75%" }}>
            <RegionDropdown
              country={country}
              countryValueType="short"
              value={userProfile.region || ""}
              valueType="short"
              customSelect
              onChange={(val) => this.selectRegion(val)}
            />
          </div>
        </div>

        {this.renderCurrencyField(userProfile)}

        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-phone" />
            &nbsp;
            <AxoLocal
              entity="SidebarRightContainerinternalPhone"
              defaultValue={"Intern tlf"}
            />
          </span>,
          "internalPhone"
        )}
        {this.state.showDuplicatePhoneWarning ? (
          <div style={{ color: "red" }}>
            <AxoLocal
              entity="SignupEmailrequired1b"
              defaultValue={"Telefonnummeret anvendes allerede."}
            />
          </div>
        ) : null}
        {this.renderPhoneConfirmationField()}
        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-phone" />
            &nbsp;
            <AxoLocal entity="ContainerPhone" defaultValue={"Telefon"} />
          </span>,
          "phone"
        )}
        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-mobile" />
            &nbsp;
            <AxoLocal
              entity="SidebarRightContainermobile"
              defaultValue={"Mobil"}
            />
          </span>,
          "mobile"
        )}
        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-at-2" />
            &nbsp;
            <AxoLocal
              entity="SidebarRightContainerEmail"
              defaultValue={"Email"}
            />{" "}
          </span>,
          "eMail"
        )}
        {this.state.showDuplicateMailWarning ? (
          <div style={{ color: "red" }}>
            <AxoLocal
              entity="SignupEmailrequired1a"
              defaultValue={"Email adressen anvendes allerede."}
            />
          </div>
        ) : null}
        {this.renderEmailConfirmationField()}
        {this.renderInputField(
          <span>
            <Icon glyph="icon-fontello-database" />
            &nbsp;
            <AxoLocal
              entity="SidebarRightContainerAccountNumber"
              defaultValue={"Kontonummer"}
            />
          </span>,
          "accountNo"
        )}
        <div>
          <b>
            <AxoLocal entity="axoidcode11" defaultValue={"API nøgle:"} />
          </b>
          &nbsp;
          <InlineEdit
            key={userSettings.paymentApiKey} //Rerender when updating API key.
            value={userSettings.paymentApiKey || "---------------"}
            change={this.onUpdatePaymentApiKey}
          />
        </div>
        {(userType === "Admin" || userType === "Society") && (
          <div>
            <b>
              <AxoLocal
                entity="axoEntityidcode3"
                defaultValue={"MitID bruger:"}
              />
            </b>
            &nbsp;
            {userSettings.easyIDCode || "Ingen"}
            &nbsp;&nbsp;&nbsp;
            <LexButton onClick={this.assignMitID}>
              <AxoLocal
                entity="axoEntityidcode4"
                defaultValue={"Tilknyt MitID"}
              />
            </LexButton>
          </div>
        )}

        <div>
          <b>
            <AxoLocal
              entity="SidebarRightContainerlogo"
              defaultValue={"Logo"}
            />
          </b>
        </div>
        {userProfile.logo || userProfile.logoLibrary ? (
          <p>
            <span
              className="axolextextbanne Bannecolor"
              role="button"
              onClick={this.onRemoveLogo}
            >
              <Icon role="button" glyph="icon-fontello-scissors axored" />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <AxoLocal
                entity="SidebarRightContaineronRemoveLogo"
                defaultValue={"Fjern logo"}
              />
            </span>
            <img
              alt=""
              style={{ maxWidth: "150px", height: "auto", display: "block" }}
              src={UserInfoService.getLogoImageSource(userProfile)}
            />
          </p>
        ) : null}
        <div>
          <AxoLocal
            componentClass={LibraryFileSearchBox}
            fileNames={this.props.libraryFileNames}
            handleSelectedFileName={this.handleSelectedLibraryLogo}
            clearOnSelect
            componentAttribute="placeholder"
            entity="placeholderSelectLibraryFile"
          />
        </div>
        <br />
        <div>
          <AxoLocal
            componentClass={DocumentSearchBox}
            documentMap={this.props.documentMap}
            handleSelectedDocument={this.handleSelectedLogo}
            onlyImages
            clearOnSelect
            componentAttribute="placeholder"
            entity="placeholderSelectexistinglogo"
          />
        </div>
        <br />
        <p>
          <Button className="Lex-button-2 " onClick={this.uploadLogo}>
            {" "}
            <span>
              <Icon glyph="icon-fontello-desktop" />
              &nbsp;
              <AxoLocal
                entity="SidebarRightContaineruploadImage"
                defaultValue={"Upload logo fra pc "}
              />
            </span>{" "}
          </Button>
        </p>

        <input
          name="image"
          type="file"
          accept="image/*"
          id="uploadImage"
          style={{ display: "none" }}
        />
        {userType === "Society" && (
          <div>
            <LexButton href={AxolexForeningsManual} target="_blank">
              Download manual
            </LexButton>
          </div>
        )}
        <div>
          {locale === "enUS" ? (
            <LexButton href={AxolexDataProcessingAgreement} target="_blank">
              Download data processing agreement
            </LexButton>
          ) : null}
          {locale === "da" ? (
            <LexButton href={AxolexDataBehandlerAftale} target="_blank">
              Download databehandleraftale
            </LexButton>
          ) : null}
          {locale === "ch" ? (
            <LexButton href={AxolexDataProcessingAgreement} target="_blank">
              下载数据处理协议
            </LexButton>
          ) : null}
        </div>
        {/* <EasyIDModal ref={this.easyIDModal} onLogin={this.onEasyIdLogin} /> */}
        <MitIDModal ref={this.mitIDModal} onLogin={this.onMitIdLogin} />
      </div>
    );
  }
}
