import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// import {
//   PrintService
// } from '../../services/AxoServices'

import { LexButton, AxoLocal } from "../../utilities/LexUtilities";

import { ButtonToolbar } from "react-bootstrap";

const ListItem = (props) => {
  return <div style={{ paddingBottom: "5px" }}>{props.children}</div>;
};

export default class FlexibleContractList extends React.PureComponent {
  static propTypes = {
    model: PropTypes.object.isRequired,
  };

  onPrint = () => {
    window.print();
    // if (typeof window !== 'undefined') {
    //   PrintService.printElement(document.getElementById('printList'));
    // }
  };

  render() {
    let { model, onEdit } = this.props;

    return (
      <div className="leftPadding topPadding standardMaxWidth">
        <div className="hidden-print">
          <ButtonToolbar>
            <LexButton onClick={onEdit}>
              <AxoLocal
                entity="presentationMarketing30"
                defaultValue="Rediger"
              />{" "}
            </LexButton>
            <LexButton onClick={this.onPrint}>
              <AxoLocal
                entity="PrintCaseClientadressePrint"
                defaultValue={"Print"}
              />
            </LexButton>
          </ButtonToolbar>
        </div>
        <div id="printList">
          <div>
            <h2>Ansættelseskontrakt</h2>
          </div>
          <ListItem>
            <h4>Mellem undertegnede</h4>
          </ListItem>
          <div>
            <strong>{model.employerName}</strong>
          </div>
          <div>{model.employerAddress}</div>
          <div>
            {model.employerPostalCode} {model.employerCity}
          </div>
          <ListItem>herefter kaldet ”Virksomheden”</ListItem>
          <ListItem>
            <h4>og</h4>
          </ListItem>
          <div>
            <strong>{model.workerName}</strong>
          </div>
          <div>{model.workerAddress}</div>
          <div>
            {model.workerPostalCode} {model.workerCity}
          </div>
          <ListItem>herefter kaldet ”Medarbejderen”</ListItem>

          <ListItem>
            <strong>1. Indledning</strong>
          </ListItem>
          <ListItem>
            Medarbejderen er forpligtet til altid at holde Virksomheden
            underrettet om ændringer i ovenstående personlige oplysninger.
          </ListItem>
          <ListItem>
            Der bekræftes hermed, at medarbejderen tiltræder hos arbejdsgiver
            med følgende aftalte vilkår:
          </ListItem>
          <ListItem>
            <strong>2. Arbejdssted</strong>
          </ListItem>
          <ListItem>
            Medarbejderens arbejdssted er på selskabets adresse:
          </ListItem>
          <ListItem>{model.workPlaceAddress}</ListItem>
          <ListItem>
            <strong>3. Arbejdsbeskrivelse</strong>
          </ListItem>
          <ListItem>Medarbejderen ansættes som:</ListItem>
          <ListItem>
            <strong>{model.jobTitle}</strong>
          </ListItem>
          <ListItem>
            Medarbejderens primære arbejdsopgaver er følgende:{" "}
          </ListItem>
          <ListItem>{model.jobDescription}</ListItem>
          <ListItem>
            <strong>4. Tiltrædelsestidspunkt</strong>
          </ListItem>
          <ListItem>Ansættelsesforholdet påbegyndes den :</ListItem>
          <ListItem>{moment.utc(model.startDate).format("L")}</ListItem>
          <ListItem>
            <strong>5. Løn</strong>
          </ListItem>
          <ListItem>
            Lønnen er fastsat til {model.salary}kr. per måned, som udbetales
            bagud den sidste bankdag i måneden. Overarbejde skal afspadseres.
          </ListItem>
          <div className="footerpagebreak"></div>
          <div className="printTopPadding"></div>
          <ListItem>
            Der er ved fastsættelsen af nævnte løn taget højde for forekommende
            overarbejde. Der ydes derfor ikke tillæg til timelønnen ved
            overarbejde.
          </ListItem>
          <ListItem>Overarbejde kan også afspadseres.</ListItem>
          <ListItem>
            <strong>6. Arbejdstid</strong>
          </ListItem>
          <ListItem>
            Den normale ugentlige arbejdstid er aftalt til {model.workTime}{" "}
            timer.
          </ListItem>
          <ListItem>
            Med hensyn til den ugentlige arbejdstid tilstræbes det, at arbejdet
            i videst muligt omfang udføres indenfor den normale arbejdstid,{" "}
          </ListItem>
          <ListItem>
            Arbejdstiden er eksklusiv ½ times daglig frokostpause.
          </ListItem>
          <ListItem>
            <strong>7. Ferie</strong>
          </ListItem>
          <ListItem>
            Medarbejderen er berettiget til ferie efter den til enhver tid
            gældende ferielov.
          </ListItem>
          {!!model.holidayBonusPercent && (
            <ListItem>
              Det særlige ferietillæg i henhold til Ferieloven er aftalt til{" "}
              {model.holidayBonusPercent}%
            </ListItem>
          )}
          <ListItem>
            <strong>8. Opsigelse</strong>
          </ListItem>
          <ListItem>
            Opsigelsesvarsel følger alle reglerne i §9 i
            ”Restaurantoverenskomst” indgået mellem HORESTA Arbejdsgiver og 3F
            Privat Service, Hotel og Restauration 2020.
          </ListItem>
          <ListItem>
            <strong>9. Tavshedspligt</strong>
          </ListItem>
          <ListItem>
            Medarbejderen har tavshedspligt med hensyn til alt, hvad
            medarbejderen erfarer i forbindelse
          </ListItem>
          <ListItem>
            med udførelsen af sit arbejde, med mindre der er tale om forhold,
            der efter sagens natur må
          </ListItem>
          <ListItem>
            bringes til tredjemands kundskab. Denne tavshedspligt er også
            gældende efter
          </ListItem>
          <ListItem>ansættelsesforholdet ophør.</ListItem>
          <ListItem>
            Når medarbejderen fratræder sin stilling – uanset af hvilken årsag –
            skal alt materiale, der tilhører
          </ListItem>
          <ListItem>
            arbejdsgiveren, og som er i medarbejderens besiddelse, afleveres til
            arbejdsgiveren.
          </ListItem>
          <ListItem>
            <strong>10. Øvrige bestemmelser</strong>
          </ListItem>
          <ListItem>
            Ansættelsesforholdet er ikke omfattet af en overenskomst og
            funktionærlovens bestemmelser finder ikke anvendelse.
          </ListItem>
          <div className="footerpagebreak"></div>
          <div className="printTopPadding"></div>
          <ListItem>
            <strong>11. Underskrift og godkendelse</strong>
          </ListItem>
          <ListItem>
            Ansættelseskontrakten er udarbejdet i 2 ens eksemplarer. Hver af
            parterne modtager et originalt dokument.{" "}
          </ListItem>
          <ListItem>
            Begge parter er indforstået med de anførte ansættelsesvilkår.
          </ListItem>
          <ListItem>{model.other}</ListItem>
          <br />
          {/* <ListItem>{moment.utc(model.contractDate).format('L')}</ListItem>
        <br/> */}
          <ListItem></ListItem>
          <ListItem>Dato og sted:</ListItem>
          <ListItem></ListItem>
          <ListItem>___________________________</ListItem>
          <ListItem>{model.employerName}</ListItem>
          <br />
          <ListItem>Dato og sted:</ListItem>
          <ListItem></ListItem>
          <ListItem>___________________________</ListItem>
          <ListItem>{model.workerName} </ListItem>
        </div>
      </div>
    );
  }
}
