import React from "react";
import AdminTabView from "./AdminTabView";
import DataActions from "../../services/DataAccess/DataActions";
import DataStore from "../../services/DataAccess/DataStore";
import LocalizationService from "../../services/LocalizationService";
import { RoutingService, StorageService } from "../../services/AxoServices";

import { withRouter } from "../../utilities/LexUtilities";

class AdminContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userProfiles: [],
      userSettings: {},
      subscriptionPlans: [],
      subscriptionFormulas: [],
      itemPrices: [],
      itemPriceTypes: [],
      legalPresentations: [],
      globalSettings: {},
      documentMap: {},
      locale: "enUS",
      forceShowUserCards: StorageService.loadItem("showSystemUsercards", false),
    };
    this.name = "AdminContainer";
  }

  onToggleCards = () => {
    let forceShowUserCards = !this.state.forceShowUserCards;
    this.setState({ forceShowUserCards });
    StorageService.saveItem("showSystemUsercards", forceShowUserCards);
  };

  getActiveTab = (props) => {
    let path = props.location.pathname.toLowerCase();
    if (path.includes("systemusers")) {
      return "SystemUsers";
    }
    if (path.includes("subscriptions")) {
      return "Subscriptions";
    }
    if (path.includes("createplan")) {
      return "CreatePlan";
    }
    if (path.includes("formulas")) {
      return "Formulas";
    }
    if (path.includes("createformula")) {
      return "CreateFormula";
    }
    if (path.includes("itemprices")) {
      return "ItemPrices";
    }
    if (path.includes("createitemprice")) {
      return "CreateItemPrice";
    }
    if (path.includes("createpresentation")) {
      return "CreatePresentation";
    }
    if (path.includes("presentationlist")) {
      return "PresentationList";
    }
    if (path.includes("presentation")) {
      return "Presentation";
    }
    return "Collaboration";
  };

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      this.setState({
        userSettings: store.userSettings,
        subscriptionPlans: store.subscriptionPlans,
        globalSettings: store.globalSettings,
        subscriptionFormulas: store.subscriptionFormulas,
        documentMap: store.documentMap,
        userProfiles: store.systemUsers,
        itemPrices: store.itemPrices,
        legalPresentations: store.legalPresentations,
        itemPriceTypes: store.itemPriceTypes,
        showDropdowns: store.showDropdowns,
      });
    });
    DataStore.fetchSystemUsers();
    DataStore.initializeUserSettings();
    DataStore.fetchSubscriptionPlansForAdmin();
    DataStore.fetchGlobalSettings();
    DataStore.fetchSubscriptionFormulas();
    DataStore.fetchItemPrices();
    DataStore.initializeDocuments();
    DataStore.fetchItemPriceTypes();
    DataStore.fetchLegalPresentations();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
    LocalizationService.unsubscribeFromLocale(this.name);
  }

  onSetMainAdmin = (userName) => {
    let adminUser = this.state.globalSettings.mainAdminUser;
    if (adminUser === userName) {
      return;
    }
    return DataActions.createOrUpdateGlobalSetting({
      key: "mainAdminUser",
      value: userName,
    });
  };

  onSetGlobalUserSetting = (settingName, userName) => {
    let { globalSettings } = this.state;
    return DataActions.createOrUpdateGlobalSetting({
      key: settingName,
      value: globalSettings[settingName] === userName ? "" : userName,
    });
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(RoutingService.getPath("Admin/" + eventKey));
  };

  render() {
    let activeTab = this.getActiveTab(this.props);

    return (
      <AdminTabView
        {...this.state}
        activeTab={activeTab}
        handleNavigation={this.handleNavigation}
        onToggleCards={this.onToggleCards}
        onSetMainAdmin={this.onSetMainAdmin}
        onSetGlobalUserSetting={this.onSetGlobalUserSetting}
      />
    );
  }
}

export default withRouter(AdminContainer);
