import React from "react";
import PropTypes from "prop-types";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";
import { Table, Column, Cell } from "fixed-data-table-2";
// import GridViewTemplate from './GridViewTemplate';

import {
  TimeEntryService,
  NumberService,
  DataActions,
  AuthorizationService,
} from "../../services/AxoServices";

import {
  Icon,
  AxoCheckbox,
  TableBase,
  Dimensions,
  DataListWrapper,
  SortHeaderCell,
  AxoLocal,
  InlineEdit,
  InlineNumberEdit,
  getText,
  Flexbox,
  FlexElement,
  AsyncButton,
} from "../../utilities/LexUtilities";

import ReactSelect from "react-select";

import {
  FormControl,
  Button,
  FormGroup,
  InputGroup,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";

const defaultColumnDefs = {
  name: {
    width: 150,
    shown: true,
  },
  productCode: {
    width: 100,
    shown: true,
  },
  units: {
    width: 100,
    shown: true,
  },
  unitType: {
    width: 75,
    shown: true,
  },
  price: {
    width: 100,
    shown: true,
  },
  vat: {
    width: 100,
    shown: true,
  },
  account: {
    width: 200,
    shown: true,
  },
  description: {
    width: 250,
    shown: true,
  },
  actions: {
    width: 50,
    shown: true,
  },
};

class ProductsView extends TableBase {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    // forceShowCards: PropTypes.bool.isRequired,
    // onToggleCards: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.name = "ProductsView";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = TableBase.generateDefaultSortIndices(
      props.entries
    );
    this.state = {
      tableEntries: [], //Legacy field.
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        columnName: 110,
      },
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        props.entries
      ),
      colSortDirs: {},
      searchText: "",
    };
    this.toggleColumns = ["columnName"];
  }

  componentDidUpdate(nextProps) {
    if (nextProps === this.props) {
      return;
    }

    let { entries } = this.props;
    let { searchText } = this.state;

    let filteredEntries = ProductsView.getFilteredEntries(entries, searchText);

    this.updateEntries(entries, filteredEntries);
  }

  onChangeEntryProperty = (entryId, propertyName, value) => {
    let entry = {
      ...this.state.sortedDataList._data.find((c) => c.id === entryId),
    };
    entry[propertyName] = value;
    //Update database entry here
    DataActions.updateProduct(entry);
  };

  onSearch = (event) => {
    let searchText = event.target.value;
    let { entries } = this.props;
    let filteredEntries = ProductsView.getFilteredEntries(entries, searchText);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(filteredEntries);
    this.setState({
      searchText,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        filteredEntries
      ),
    });
  };

  static getFilteredEntries = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }

    let search = searchText.toLowerCase();
    let filteredEntries = entries.filter((p) => {
      let text = (p.name + p.productCode).toLowerCase();
      return text.includes(search);
    });

    return filteredEntries;
  };

  renderColumnDropdown = () => {
    let columns = this.toggleColumns;
    let { columnDefs } = this.state;
    let allEnabled = !columns.find((col) => !columnDefs[col].shown);

    return (
      <DropdownButton
        id="ColumnDropdown"
        className="visButtoncolor axoDropdownButton VisButton"
        title={
          <span style={{ fontSize: "15px" }}>
            <AxoLocal entity="axoAccounting70" defaultValue={"Vis"} />
          </span>
        }
      >
        <MenuItem eventKey="all">
          <div
            className="text-center"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <AxoCheckbox
              checked={allEnabled}
              onChange={this.onEnableAll.bind(this, !allEnabled)}
            />
            <AxoLocal entity="axoidcode124" defaultValue={"Vis alle"} />
          </div>
        </MenuItem>
        {columns.map((column) => {
          return (
            <MenuItem key={column} eventKey={column}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <AxoCheckbox
                  checked={columnDefs[column].shown}
                  onChange={this.onSelectColumn.bind(this, column)}
                />
                &nbsp;{this.getColumnTitle(column)}
              </div>
            </MenuItem>
          );
        })}
      </DropdownButton>
    );
  };

  getColumnTitle = (columnName) => {
    switch (columnName) {
      case "name":
        return "Column name";
      default:
        return <span></span>;
    }
  };

  onAddProduct = async () => {
    let { clientPlan } = this.props;

    console.log(clientPlan);
    let response = await DataActions.createProduct({
      name: "---",
      vat: 25,
      financeAccountId: clientPlan.standardSalesAccountId,
    });

    return response.ok;
  };

  render() {
    let { forceShowCards, onToggleCards } = this.props;

    return (
      <div className="leftPadding">
        <Flexbox
          spaceBetween
          className="axobg flexbox-layout"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <Flexbox>
            <FlexElement className="largeText rightPadding">
              <AxoLocal
                entity="presentationMarketingA"
                defaultValue="Produkter"
              />
            </FlexElement>
            <FlexElement className="rightPadding">
              <AsyncButton onClick={this.onAddProduct} hideOkIcon>
                <Icon role="button" glyph="icon-fontello-plus-3" className="" />
              </AsyncButton>
            </FlexElement>
          </Flexbox>
          <FlexElement>
            <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
              <FormGroup controlId="inputWithIcon">
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-search" />
                  </InputGroup.Addon>
                  {/* Søg...  */}
                  <AxoLocal
                    componentClass={FormControl}
                    type="text"
                    value={this.state.searchText}
                    onChange={this.onSearch}
                    componentAttribute="placeholder"
                    entity="ContactTableViewplaceholderonSearch"
                  />
                  <FormControl.Feedback>
                    <Icon glyph="icon-fontello-search" />
                  </FormControl.Feedback>
                </InputGroup>
              </FormGroup>
            </div>
          </FlexElement>
          {/* {this.renderColumnDropdown()} */}
          <div className="hidden-xs text-right">
            <Button className="Lex-button-2" onClick={onToggleCards}>
              {forceShowCards ? (
                <span>
                  {" "}
                  <Icon glyph="icon-fontello-grid" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="showTable"
                    entity="CaseDataTableFixedShowastable"
                    defaultValue={"Vis som tabel"}
                  />
                </span>
              ) : (
                <span>
                  {" "}
                  <Icon glyph="icon-fontello-th-thumb-empty" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="showCards"
                    entity="CaseDataTableFixedShowCards"
                    defaultValue={"Vis som kartotekskort"}
                  />{" "}
                </span>
              )}
            </Button>
          </div>
        </Flexbox>
        {forceShowCards ? (
          this.renderGridView()
        ) : (
          <div>
            <ExtraSmall>{this.renderGridView()}</ExtraSmall>
            <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
          </div>
        )}
      </div>
    );
  }

  renderGridView = () => {
    return (
      <div className="topPadding">
        {/* <GridViewTemplate
          entries={this.state.sortedDataList._data}
        /> */}
      </div>
    );
  };

  onDeleteProduct = (productId) => {
    DataActions.deleteProduct(productId);
  };

  filterValues = ({ label }, search) => {
    return label.toLowerCase().includes(search.toLowerCase());
  };

  getAccountOptions = () => {
    let { clientPlan } = this.props;
    if (!clientPlan.id) {
      return [];
    }
    let standardAccounts = clientPlan.accounts.filter(
      (a) => a.type === "Standard"
    );
    let accountOptions = standardAccounts.map((f) => {
      return { value: f.id, label: f.number.toString() + " - " + f.name };
    });

    return accountOptions;
  };

  renderTable = () => {
    let { sortedDataList, colSortDirs, columnDefs } = this.state;
    let { containerHeight, containerWidth, clientPlan, userProfile } =
      this.props;
    let tableWidth = containerWidth;

    var hourLabel = getText("unitHoursShortName", "t");
    var unitLabel = getText("unitUnitsShortName", "stk.");

    let accountOptions = this.getAccountOptions();

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <Table
        rowHeight={40}
        rowsCount={sortedDataList.getSize()}
        width={tableWidth}
        height={containerHeight}
        isColumnResizing={false}
        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
        headerHeight={40}
      >
        {columnDefs.name.shown ? (
          <Column
            columnKey="name"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.name}
              >
                <AxoLocal
                  entity="SidebarRightContaineruseruserName"
                  defaultValue={"Navn"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  <InlineEdit
                    value={entry.name || "---"}
                    change={(input) =>
                      this.onChangeEntryProperty(entry.id, "name", input.value)
                    }
                  />
                </Cell>
              );
            }}
            width={columnDefs.name.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.productCode.shown ? (
          <Column
            columnKey="productCode"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.productCode}
              >
                <AxoLocal entity="axoidcode241" defaultValue={"Varekode"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  <InlineEdit
                    value={entry.productCode || "---"}
                    change={(input) =>
                      this.onChangeEntryProperty(
                        entry.id,
                        "productCode",
                        input.value
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnDefs.productCode.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.units.shown ? (
          <Column
            columnKey="units"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.units}
              >
                Antal
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  <InlineNumberEdit
                    value={entry.units}
                    format={(value) => {
                      return (
                        <div>
                          {value}{" "}
                          {TimeEntryService.getUnitTypeName(entry.unitType)}
                        </div>
                      );
                    }}
                    change={(input) => {
                      this.onChangeEntryProperty(
                        entry.id,
                        "units",
                        input.value
                      );
                    }}
                  />
                </Cell>
              );
            }}
            width={columnDefs.units.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.unitType.shown ? (
          <Column
            columnKey="unitType"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.unitType}
              >
                <AxoLocal entity="unitUnitType" defaultValue={"Enhed"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  <select
                    value={entry.unitType}
                    onChange={(event) =>
                      this.onChangeEntryProperty(
                        entry.id,
                        "unitType",
                        event.target.value
                      )
                    }
                  >
                    <option value="Hours">{hourLabel}</option>
                    <option value="Units">{unitLabel}</option>
                  </select>
                </Cell>
              );
            }}
            width={columnDefs.unitType.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.price.shown ? (
          <Column
            columnKey="price"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.price}
              >
                <AxoLocal
                  entity="axoEntityidcode66"
                  defaultValue={"Enhedspris "}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  <InlineNumberEdit
                    value={entry.price}
                    format={(value) => {
                      return NumberService.formatDecimal(value);
                    }}
                    change={(input) => {
                      this.onChangeEntryProperty(
                        entry.id,
                        "price",
                        input.value
                      );
                    }}
                  />
                </Cell>
              );
            }}
            width={columnDefs.price.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.vat.shown ? (
          <Column
            columnKey="vat"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.vat}
              >
                <AxoLocal
                  entity="invoiPaymentattheSubtotalVAT"
                  defaultValue={"Moms"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  <InlineNumberEdit
                    value={entry.vat}
                    format={(value) => {
                      return NumberService.formatDecimal(value);
                    }}
                    change={(input) => {
                      this.onChangeEntryProperty(entry.id, "vat", input.value);
                    }}
                  />
                </Cell>
              );
            }}
            width={columnDefs.vat.width}
            isResizable={true}
          />
        ) : null}
        {userType !== "AccountingClient" && columnDefs.account.shown ? (
          <Column
            columnKey="account"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.account}
              >
                <AxoLocal
                  entity="AccountingTabViewEntity13"
                  defaultValue={"Konto"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              let selectedOption = accountOptions.find(
                (a) => a.value === entry.financeAccountId
              ) || {
                value: "",
                label: getText("axoAccounting6f", "Vælg konto"),
              };

              return (
                <Cell key={entry.id} {...props}>
                  <ReactSelect
                    name="select"
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    isDisabled={accountOptions.length === 0}
                    closeMenuOnScroll
                    // menuPlacement='top'
                    value={selectedOption}
                    onChange={(selectedAccount) => {
                      if (!!selectedAccount) {
                        let account = clientPlan.accounts.find(
                          (a) => a.id === entry.financeAccountId
                        ) || { taxSpecificationId: 0 };
                        let taxSpec = clientPlan.taxSpecifications.find(
                          (t) => t.id === account.taxSpecificationId
                        ) || { taxPercentage: 0 };
                        let vat = taxSpec.taxPercentage;

                        DataActions.updateProduct({
                          ...entry,
                          financeAccountId: parseInt(selectedAccount.value, 10),
                          vat,
                        });
                      }
                    }}
                    noOptionsMessage={() => ""}
                    options={[
                      {
                        value: "",
                        label: getText("axoAccounting6f", "Vælg konto"),
                      },
                      ...accountOptions,
                    ]}
                    filterOption={this.filterValues}
                  />
                </Cell>
              );
            }}
            width={columnDefs.account.width}
            isResizable={true}
          />
        ) : null}
        {columnDefs.description.shown ? (
          <Column
            columnKey="description"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.description}
              >
                <AxoLocal entity="TimeEntryFormnotesA" defaultValue={"Noter"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  <InlineEdit
                    value={entry.description || "---"}
                    change={(input) =>
                      this.onChangeEntryProperty(
                        entry.id,
                        "description",
                        input.value
                      )
                    }
                  />
                </Cell>
              );
            }}
            width={columnDefs.description.width}
            isResizable={true}
          />
        ) : null}
        <Column
          columnKey="actions"
          header={<Cell></Cell>}
          cell={(props) => {
            var entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                <Icon
                  className="editable"
                  role="button"
                  onClick={this.onDeleteProduct.bind(this, entry.id)}
                  glyph="icon-fontello-trash-1"
                />
              </Cell>
            );
          }}
          width={columnDefs.actions.width}
          isResizable={true}
        />
      </Table>
    );
  };
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 260;
  },
})(ProductsView);
