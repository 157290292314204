import React from "react";

import DataStore from "../services/DataAccess/DataStore";
import { groupBy } from "lodash";
import { ctx } from "../utilities/L20n/L20n";
import HomeView from "./Home/HomeView";
import SocietyHomeView from "./Home/SocietyHomeView";
import TimeEntryService from "../services/TimeEntryService";
import { AuthorizationService } from "../services/AxoServices";

import { AxoLocal, withRTKCaseData } from "../utilities/LexUtilities";

var monthNameDispatcher = {
  monthNames: [],
  callBack: (monthNames) => {},

  dispatchMonthNames() {
    if (this.monthNames.length === 0) {
      return;
    }
    this.callBack(this.monthNames);
  },

  setMonthNames(monthNames) {
    this.monthNames = monthNames;
    this.dispatchMonthNames();
  },

  subscribe(callBack) {
    this.callBack = callBack;
    this.dispatchMonthNames();
  },
  unsubscribe(callBack) {
    this.callBack = (monthNames) => {};
  },
};

if (ctx) {
  ctx.localize(
    [
      "getMonthNameJan",
      "getMonthNameFeb",
      "getMonthNameMar",
      "getMonthNameApr",
      "getMonthNameMay",
      "getMonthNameJun",
      "getMonthNameJul",
      "getMonthNameAug",
      "getMonthNameSep",
      "getMonthNameOct",
      "getMonthNameNov",
      "getMonthNameDec",
    ],
    (l10n) => {
      var monthNames = [];
      monthNames.push(l10n.entities.getMonthNameJan.value);
      monthNames.push(l10n.entities.getMonthNameFeb.value);
      monthNames.push(l10n.entities.getMonthNameMar.value);
      monthNames.push(l10n.entities.getMonthNameApr.value);
      monthNames.push(l10n.entities.getMonthNameMay.value);
      monthNames.push(l10n.entities.getMonthNameJun.value);
      monthNames.push(l10n.entities.getMonthNameJul.value);
      monthNames.push(l10n.entities.getMonthNameAug.value);
      monthNames.push(l10n.entities.getMonthNameSep.value);
      monthNames.push(l10n.entities.getMonthNameOct.value);
      monthNames.push(l10n.entities.getMonthNameNov.value);
      monthNames.push(l10n.entities.getMonthNameDec.value);
      monthNameDispatcher.setMonthNames(monthNames);
    }
  );
}

class HomeContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Maj",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dec",
      ],
      invoices: [],
      timeEntries: [],
      invoiceSums: [],
      timeEntrySums: [],
      caseDeadlines: [],
      documentDeadlines: [],
      eventViewModels: [],
      labels: [],
      userProfile: {},
      globalSettings: {},
      locale: "enUS",
      initializedEvents: false,
      initializedCases: false,
      initializedDocuments: false,
    };
    this.chart = null;
    this.invoiceLine = null;
    this.timeEntryLine = null;
    this.name = "HomeContainer";
  }

  calculateInvoiceSum(invoices) {
    var totalAmount = invoices.reduce((sum, invoice) => {
      return sum + this.calculateTimeEntrySums(invoice.timeEntries);
    }, 0);
    return totalAmount;
  }

  generateDemoData = () => {
    var invoiceSums = this.generateMonths();
    var timeEntrySums = this.generateMonths();
    for (var i = 0; i < invoiceSums.length; i++) {
      invoiceSums[i].y = Math.random() * (75000 - 25000) + 25000;
      timeEntrySums[i].y = Math.random() * (75000 - 25000) + 25000;
    }
    return [invoiceSums, timeEntrySums];
  };

  getMonthName = (index) => {
    return this.state.monthNames[index];
  };

  generateMonths = () => {
    var months = [];
    for (var i = 0; i < 12; i++) {
      months.push({ x: this.getMonthName(i), y: 0 });
    }
    return months;
  };

  generateInvoiceStats(invoices) {
    var groupedInvoices = groupBy(invoices, (invoice) => {
      return new Date(invoice.dueDate).getMonth();
    });
    var invoiceSums = this.generateMonths();
    for (var key in groupedInvoices) {
      var index = parseInt(key, 10);
      var invoicesForMonth = groupedInvoices[key];
      invoiceSums[index].y = this.calculateInvoiceSum(invoicesForMonth);
    }
    return invoiceSums;
  }

  generateTimeEntryStats(timeEntries) {
    var groupedTimeEntries = groupBy(timeEntries, (timeEntry) => {
      return new Date(timeEntry.workDate).getMonth();
    });
    var timeEntrySums = this.generateMonths();
    for (var key in groupedTimeEntries) {
      var index = parseInt(key, 10);
      var entriesForMonth = groupedTimeEntries[key];
      timeEntrySums[index].y = this.calculateTimeEntrySums(entriesForMonth);
    }
    return timeEntrySums;
  }

  calculateTimeEntrySums(timeEntries) {
    var totalAmount = timeEntries.reduce((sum, entry) => {
      return sum + TimeEntryService.getTotalValue(entry);
    }, 0);
    return totalAmount;
  }

  componentDidMount() {
    window.onbeforeunload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to exit?";
    };

    DataStore.subscribe(this.name, (store) => {
      let events = store.calendarEvents.map((c) => {
        var viewModel = Object.assign({}, c);
        viewModel.start = new Date(viewModel.start);
        viewModel.end = new Date(viewModel.end);
        return viewModel;
      });

      this.setState({
        invoices: store.invoices,
        labels: store.labels,
        // invoiceSums: this.generateInvoiceStats(invoices)
        timeEntries: store.timeEntryMap.timeEntries,
        // timeEntrySums: this.generateTimeEntryStats(timeEntryMap.timeEntries)
        cases: store.caseMap.saved,
        caseDeadlines: this.extractEventsFromCases(store.caseMap.saved),
        documents: store.documentMap.documents,
        documentDeadlines: this.extractEventsFromDocuments(
          store.documentMap.documents
        ),
        eventViewModels: events,
        userProfile: store.userProfile,
        globalSettings: store.globalSettings,
        locale: store.locale,
      });
    });

    DataStore.fetchInvoices();
    DataStore.initializeTimeEntries();
    DataStore.fetchGlobalSettings();
    DataStore.initializeCases().then((response) => {
      this.setState({ initializedCases: true });
    });
    DataStore.fetchDocuments().then((response) => {
      this.setState({ initializedDocuments: true });
    });
    DataStore.initializeCalendarEvents().then((response) => {
      this.setState({ initializedEvents: true });
    });
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  extractEventsFromCases = (cases) => {
    return cases.map((caseModel) => {
      return {
        title: (
          <span>
            <AxoLocal entity="extractEventsFromCasesDeadlineforcase" />{" "}
            {caseModel.caseNumber}
          </span>
        ),
        start: new Date(caseModel.actionDate),
        deadlineCaseId: caseModel.id,
      };
    });
  };

  extractEventsFromDocuments = (documents) => {
    var documentEvents = [];
    documents.forEach((document) => {
      var deadLineDate = new Date(document.deadline);
      if (deadLineDate.getFullYear() < 1971) {
        return;
      }
      documentEvents.push({
        title: (
          <span>
            <AxoLocal entity="extractEventsFromCasesDeadlineforDocument" />{" "}
            {document.fileName}
          </span>
        ),
        start: new Date(document.deadline),
        deadlineDocumentId: document.id,
      });
    });
    return documentEvents;
  };

  getAllEventsFromArrays = (caseEvents, documentEvents, eventViewModels) => {
    return caseEvents.concat(documentEvents).concat(eventViewModels);
  };

  getFirstEventsTodayOrLater = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let events = this.getAllEventsFromArrays(
      this.state.caseDeadlines,
      this.state.documentDeadlines,
      this.state.eventViewModels
    );
    let sortedEvents = events.sort((e1, e2) => {
      return new Date(e1.start) - new Date(e2.start);
    });

    let comingEvents = sortedEvents.filter((event) => event.start >= today);
    if (comingEvents.length < 4) {
      comingEvents = sortedEvents.slice(sortedEvents.length - 4);
    }

    // var sortedEvents = todaysEvents.sort( (e1, e2) => {
    //   return new Date(e1.start) - new Date(e2.start);
    // });
    let eventSubset = comingEvents.slice(0, 10);
    return eventSubset;
  };

  render() {
    let { userProfile, invoices, labels, locale } = this.state;
    let userType = AuthorizationService.getUserType(userProfile);
    if (userType === "Society") {
      let filteredLabels = labels.filter((l) => l.type === 3); //Clients
      let sortedLabels = filteredLabels.sort((l1, l2) =>
        l1.name.localeCompare(l2.name)
      );

      return (
        <SocietyHomeView
          invoices={invoices}
          clientLabels={sortedLabels}
          locale={locale}
        />
      );
    }

    return (
      <HomeView {...this.state} events={this.getFirstEventsTodayOrLater()} />
    );
  }
}

export default withRTKCaseData(HomeContainer);
