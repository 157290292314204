import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import InlineEdit from "../../utilities/InlineEdit";
import ReactOption from "../../utilities/ReactOption";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

class CaseCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      labelAdded: false,
    };
  }

  //Props
  //caseModel
  //onCaseChange
  onCasePropertyChange = (propertyName, input) => {
    var caseModel = Object.assign({}, this.props.caseModel);
    caseModel[propertyName] = input.value;
    this.props.onCaseChange(caseModel);
  };

  onTrashCase = () => {
    var caseModel = Object.assign({}, this.props.caseModel);
    caseModel.trashed = true;
    DataActions.updateCase(caseModel);
  };

  onEditButton = () => {
    this.setState({ editing: !this.state.editing });
  };

  shortenString(input, maxChars) {
    if (input.length > maxChars) {
      return input.substring(0, maxChars - 3) + "...";
    }
    return input;
  }

  onLabelAdd = (caseId, event) => {
    var labelId = parseInt(event.target.value, 10);
    DataActions.addLabelToCase(caseId, labelId).then(() => {
      this.setState({ labelAdded: true });
      setTimeout(() => {
        this.setState({ labelAdded: false });
      }, 1500);
    });
  };

  renderDateCell = (caseModel) => {
    var deadlineMoment = moment(caseModel.actionDate);
    if (deadlineMoment.year() > 1970) {
      return (
        <div>
          <input
            type="date"
            style={{ borderStyle: "none" }}
            onChange={this.props.onCaseDateChange.bind(this, caseModel.id)}
            value={deadlineMoment.format("YYYY-MM-DD")}
          />
          &nbsp;&nbsp;
          <AxoLocal
            key="Removereminder"
            componentClass={Icon}
            role="button"
            onClick={this.props.removeDeadlineFromCase.bind(this, caseModel.id)}
            glyph="icon-fontello-scissors axored"
            componentAttribute="title"
            entity="RemoveTitledeadlineMomentRemovereminder"
          />
        </div>
      );
    }
    return (
      <Icon
        role="button"
        onClick={this.props.addDeadlineToCase.bind(this, caseModel.id)}
        glyph="icon-fontello-calendar-6"
        className="editable"
      />
    );
  };

  renderEditForm = () => {
    var caseModel = this.props.caseModel;
    var confirmIcon = this.state.labelAdded ? (
      <Icon style={{ color: "#4CAF50" }} glyph="icon-fontello-ok-3" />
    ) : null;
    var addLabels = this.props.labels.filter(
      (l) => !l.caseIds.includes(caseModel.id)
    );
    var addLabelSelectNodes = addLabels.map((label) => {
      return (
        <option key={label.id} value={label.id}>
          {this.shortenString(label.name, 10)}
        </option>
      );
    });
    var addLabelSelect = (
      <span>
        {confirmIcon}
        <select
          className="axoblue selectbg"
          style={{ border: "none" }}
          value="labels"
          onChange={this.onLabelAdd.bind(this, caseModel.id)}
        >
          <AxoLocal
            componentClass={ReactOption}
            value="labels"
            disabled
            componentAttribute="text"
            entity="CaseGridViewReactOption"
            defaultValue={"Add"}
          />
          {addLabelSelectNodes}
        </select>
      </span>
    );
    var prioritySelect = (
      <select
        className="axoblue selectbg"
        value={this.getPriorityString(caseModel.priority)}
        style={{ border: "none" }}
        onChange={this.props.onPrioritySelect.bind(this, caseModel.id)}
      >
        <AxoLocal
          componentClass={ReactOption}
          value="A"
          componentAttribute="text"
          entity="CaseDataTableFixedselectoptionA"
          defaultValue={"A"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="B"
          componentAttribute="text"
          entity="CaseDataTableFixedselectoptionB"
          defaultValue={"B"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="C"
          componentAttribute="text"
          entity="CaseDataTableFixedselectoptionC"
          defaultValue={"C"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="D"
          componentAttribute="text"
          entity="CaseDataTableFixedselectoptionD"
          defaultValue={"D"}
        />
      </select>
    );
    return (
      <div>
        <table className="table">
          <tbody>
            <tr>
              <th className="col-xs-4 tableCaseInlineEdit ">
                <Icon glyph="icon-fontello-barcode-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="casenumber"
                  entity="ContainercaseNumber"
                  defaultValue={"Sagsnummer"}
                />
                :
              </th>
              <td>
                <InlineEdit
                  value={caseModel.caseNumber || "---"}
                  change={this.onCasePropertyChange.bind(this, "caseNumber")}
                />
              </td>
            </tr>
            <tr>
              <th className="col-xs-4 tableCaseInlineEdit">
                <Icon glyph="icon-fontello-qrcode" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledF"
                  defaultValue={"Internt sagsnummer:"}
                />
              </th>
              <td>
                <InlineEdit
                  value={caseModel.internalNumber || "---"}
                  change={this.onCasePropertyChange.bind(
                    this,
                    "internalNumber"
                  )}
                />
              </td>
            </tr>
            <tr>
              <th className="col-xs-4 col-xs-3 tableCaseInlineEdit">
                <Icon glyph=" icon-fontello-pencil-alt-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="subject"
                  entity="CaseDataTableFixedsubject"
                  defaultValue={"Emneord"}
                />
                :
              </th>
              <td>
                <InlineEdit
                  value={caseModel.subject || "---"}
                  change={this.onCasePropertyChange.bind(this, "subject")}
                />
              </td>
            </tr>
            <tr>
              <th className="col-xs-4 tableCaseInlineEdit">
                <Icon glyph="icon-fontello-tasks" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="type"
                  entity="CaseGridViewCasePropertyChange"
                  defaultValue={"Sagsinstans"}
                />
                :
              </th>
              <td>
                <InlineEdit
                  value={caseModel.type || "---"}
                  change={this.onCasePropertyChange.bind(this, "type")}
                />
              </td>
            </tr>
            <tr>
              <th className="col-xs-4 tableCaseInlineEdit">
                <Icon glyph="icon-fontello-hammer" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledC"
                  defaultValue={"Sagsområde:"}
                />
              </th>
              <td>
                <InlineEdit
                  value={caseModel.field || "---"}
                  change={this.onCasePropertyChange.bind(this, "field")}
                />
              </td>
            </tr>
            <tr>
              <th className="col-xs-4 tableCaseInlineEdit">
                <Icon glyph="icon-fontello-target-4" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledD"
                  defaultValue={"Reference"}
                />
                :
              </th>
              <td>
                <InlineEdit
                  value={caseModel.reference || "---"}
                  change={this.onCasePropertyChange.bind(this, "reference")}
                />
              </td>
            </tr>
            <tr>
              <th className="col-xs-4 col-xs-5 tableCaseInlineEdit">
                <Icon glyph="icon-simple-line-icons-folder-alt" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="folders"
                  entity="CaseDataTableFixedThecasefolders"
                  defaultValue={"Sagens mapper"}
                />
                :
              </th>
              <td>{this.getLabelString(this.props.caseLabels)}</td>
            </tr>
            <tr>
              <th className="col-xs-4 tableCaseInlineEdit">
                <Icon glyph="icon-fontello-folder-open-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="addfolders"
                  entity="CaseDataTableFixedlabelAddedToCase"
                  defaultValue={"Tilføj mapper"}
                />
                :
              </th>
              <td>{addLabelSelect}</td>
            </tr>
            <tr>
              <th className="col-xs-4 tableCaseInlineEdit">
                <Icon glyph="icon-fontello-record-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="priority"
                  entity="CaseDataTableFixedCasepriority"
                  defaultValue={"Prioritet"}
                />
                :
              </th>
              <td>
                <span className="pull-left">
                  {this.getPriorityIcon(caseModel.priority)}
                </span>
                <span>{prioritySelect}</span>
              </td>
            </tr>
            <tr>
              <th className="col-xs-4">
                <Icon glyph="icon-fontello-hourglass" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="actionDate"
                  entity="EventBoxFormDeadline"
                  defaultValue={"Deadline"}
                />
                :
              </th>
              <td>{this.renderDateCell(caseModel)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  onShowFiles = (documents) => {
    this.props.onShowFiles(documents);
  };

  getLabelString = (labels) => {
    if (labels.length === 0) {
      return "";
    }
    var labelString = "";
    for (var i = 0; i < labels.length; i++) {
      var label = labels[i];
      if (i > 0) {
        labelString += ", ";
      }
      labelString += label.name;
    }
    return labelString;
  };

  getPriorityIcon = (priorityString) => {
    var priority = parseInt(priorityString, 10);
    switch (priority) {
      case 0:
        return (
          <div>
            <Icon style={{ color: "blue" }} glyph="icon-fontello-circle" />
            &nbsp;&nbsp;
          </div>
        );
      case 1:
        return (
          <div>
            <Icon style={{ color: "green" }} glyph="icon-fontello-circle" />
            &nbsp;&nbsp;
          </div>
        );
      case 2:
        return (
          <div>
            <Icon style={{ color: "darkred" }} glyph="icon-fontello-circle" />
            &nbsp;&nbsp;
          </div>
        );
      case 3:
        return (
          <div>
            <Icon style={{ color: "red" }} glyph="icon-fontello-circle" />
            &nbsp;&nbsp;
          </div>
        );
      default:
        return <div></div>;
    }
  };

  getPriorityString = (priority) => {
    switch (priority) {
      case 0:
        return "D";
      case 1:
        return "C";
      case 2:
        return "B";
      case 3:
        return "A";
      default:
        return "";
    }
  };

  renderInformation = () => {
    var caseModel = this.props.caseModel;
    var deadlineMoment = new moment(caseModel.actionDate);

    return (
      <div>
        <table className="table Lex-Grid-tabl">
          <tbody>
            {caseModel.internalNumber ? (
              <tr>
                <th className="col-xs-4">
                  <Icon glyph="icon-fontello-qrcode" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseDataTableFixedcaseEnabledB"
                    defaultValue={"Internt"}
                  />
                  :
                </th>
                <td>{caseModel.internalNumber}</td>
              </tr>
            ) : null}
            {caseModel.subject ? (
              <tr>
                <th className="col-xs-4">
                  <Icon glyph=" icon-fontello-pencil-alt-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="subject"
                    entity="CaseDataTableFixedsubject"
                    defaultValue={"Emneord"}
                  />
                  :
                </th>
                <td>{caseModel.subject}</td>
              </tr>
            ) : null}
            {caseModel.type ? (
              <tr>
                <th className="col-xs-4">
                  <Icon glyph="icon-fontello-tasks" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="type"
                    entity="CaseGridViewCasePropertyChange"
                    defaultValue={"Sagsinstans"}
                  />
                  :
                </th>
                <td>{caseModel.type}</td>
              </tr>
            ) : null}
            {caseModel.field ? (
              <tr>
                <th className="col-xs-3">
                  <Icon glyph="icon-fontello-hammer" />
                  &nbsp;&nbsp;{" "}
                  <AxoLocal
                    entity="CaseDataTableFixedcaseEnabledC"
                    defaultValue={"Sagsområde:"}
                  />
                </th>
                <td>{caseModel.field}</td>
              </tr>
            ) : null}
            {caseModel.reference ? (
              <tr>
                <th className="col-xs-4">
                  <Icon glyph="icon-fontello-target-4" />
                  &nbsp;&nbsp;{" "}
                  <AxoLocal
                    entity="CaseDataTableFixedcaseEnabledD"
                    defaultValue={"Reference"}
                  />
                  :
                </th>
                <td>{caseModel.reference}</td>
              </tr>
            ) : null}
            <tr>
              <th className="col-xs-4">
                <Icon glyph="icon-fontello-clock-4" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="timeSpent"
                  entity="CaseDataTableFixedfileTime"
                  defaultValue={"Tidsforbrug"}
                />
                :
              </th>
              <td>{caseModel.time}</td>
            </tr>
            {caseModel.clients.length > 0 ? (
              <tr>
                <th className="col-xs-4">
                  <Icon glyph="icon-simple-line-icons-users" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="client"
                    entity="CaseDataTableFixedClient"
                    defaultValue={"Klient"}
                  />
                  :
                </th>
                <td>{caseModel.clientString}</td>
              </tr>
            ) : null}
            {caseModel.counterParties.length > 0 ? (
              <tr>
                <th className="col-xs-4">
                  <Icon glyph="icon-fontello-user" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="counterParty"
                    entity="CaseDataTableFixedcounterParty"
                    defaultValue={"Modpart"}
                  />
                  :
                </th>
                <td>{caseModel.counterPartyString}</td>
              </tr>
            ) : null}
            {this.props.caseLabels.length > 0 ? (
              <tr>
                <th className="col-xs-5">
                  <Icon glyph="icon-simple-line-icons-folder-alt" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="folders"
                    entity="CaseDataTableFixedThefolders"
                    defaultValue={"Mapper"}
                  />
                  :
                </th>
                <td>{this.getLabelString(this.props.caseLabels)}</td>
              </tr>
            ) : null}
            {caseModel.documents.length > 0 ? (
              <tr>
                <th className="col-xs-4">
                  <Icon glyph="icon-fontello-attach" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="attachment"
                    entity="CaseDataTableFixedlabelAddedToCasedocuments"
                    defaultValue={"Bilag"}
                  />
                  :
                </th>
                <td>
                  <Icon
                    className="editable"
                    role="button"
                    onClick={this.onShowFiles.bind(this, caseModel.documents)}
                    glyph="icon-fontello-attach"
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <th className="col-xs-4">
                <Icon glyph="icon-fontello-record-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="priority"
                  entity="CaseDataTableFixedCasepriority"
                  defaultValue={"Prioritet"}
                />
                :
              </th>
              <td>
                <span className="pull-left">
                  {this.getPriorityIcon(caseModel.priority)}
                </span>
                {this.getPriorityString(caseModel.priority)}
              </td>
            </tr>
            <tr>
              <th className="col-xs-4">
                <Icon glyph="icon-fontello-calendar-8" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="creationDate"
                  entity="CaseDataTableFixedcreationDate"
                  defaultValue={"Oprettelse"}
                />
                :
              </th>
              <td>{moment(caseModel.creationDate).format("L")}</td>
            </tr>
            {deadlineMoment.year() > 1970 ? (
              <tr>
                <th className="col-xs-4">
                  <Icon glyph="icon-fontello-hourglass" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    key="deadline"
                    entity="EventBoxFormDeadline"
                    defaultValue={"Deadline"}
                  />
                </th>
                <td>{moment(caseModel.actionDate).format("L")}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  };

  renderContent = () => {
    if (this.state.editing) {
      return this.renderEditForm();
    }
    return this.renderInformation();
  };

  openLink = (url) => {
    window.open(url);
  };

  render() {
    var caseModel = this.props.caseModel;
    return (
      <div>
        <div
          style={{
            marginBottom: "25px",
            borderRadius: "0px",
            borderWidth: "1px 1px 1px 1px",
            borderStyle: "solid solid solid solid",
            borderColor: "#DCDCDC #DCDCDC #DCDCDC #DCDCDC",
            boxShadow: "10px 10px 9px #ececec",
          }}
        >
          <div>
            <div className="gradient-baggrund">
              <Grid fluid>
                <Row>
                  <Col xs={12} className="editable text-center">
                    {/* <span style={{ float: 'left', paddingTop: '5px', paddingBottom: '5px'}}>
                    <img alt=''  src='/imgs/app/avatars/avatar.jpg' className='img-circle' width='40' height='40' />
                  </span> */}
                    <div
                      role="button"
                      onClick={this.props.onCaseClick.bind(this, caseModel.id)}
                      style={{ lineHeight: "50px" }}
                    >
                      <Icon glyph="icon-fontello-barcode-1" />
                      &nbsp;&nbsp;
                      {caseModel.caseNumber}
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div>
              <Grid fluid>
                <Row>
                  <Col xs={12}>{this.renderContent()}</Col>
                </Row>
              </Grid>
            </div>
            <div className="Lex-CardFooter">
              <Grid fluid>
                <Row>
                  <Col xs={12} style={{ padding: "0px" }}>
                    <ButtonGroup justified>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.props.onCaseClick.bind(
                            this,
                            caseModel.id
                          )}
                        >
                          <Icon glyph="icon-fontello-login" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            key="goto"
                            entity="CaseGridViewcaseModel"
                            defaultValue={"Gå til sag"}
                          />
                        </button>
                      </ButtonGroup>
                      <ButtonGroup>
                        {this.state.editing ? (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon glyph="icon-fontello-floppy-1" />
                            &nbsp;&nbsp;{" "}
                            <AxoLocal
                              key="Save"
                              entity="CaseGridViewcaseSave"
                              defaultValue={"Gem"}
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon glyph="icon-fontello-edit-3" />
                            &nbsp;&nbsp;
                            <AxoLocal
                              key="Edit"
                              entity="CaseGridViewedit"
                              defaultValue={"Rediger"}
                            />
                          </button>
                        )}
                      </ButtonGroup>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.onTrashCase}
                        >
                          <Icon glyph="icon-fontello-trash-1" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            key="trash"
                            entity="axoidcode179"
                            defaultValue={"Slet"}
                          />
                        </button>
                      </ButtonGroup>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CaseGridView extends React.PureComponent {
  //Props
  //cases
  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateCaseCols = (cases) => {
    let cols = [[], [], []];
    let smallCols = [[], []];
    for (let i = 0; i < cases.length; i++) {
      let caseModel = cases[i];
      let caseLabels = this.props.labels.filter((l) =>
        l.caseIds.includes(caseModel.id)
      );

      let caseCard = (
        <CaseCard
          key={caseModel.id}
          caseModel={caseModel}
          caseLabels={caseLabels}
          labels={this.props.labels}
          onShowFiles={this.props.onShowFiles}
          onCaseChange={this.onCaseChange}
          onPrioritySelect={this.props.onPrioritySelect}
          onCaseDateChange={this.props.onCaseDateChange}
          onCaseClick={this.props.onCaseClick}
          removeDeadlineFromCase={this.props.removeDeadlineFromCase}
          addDeadlineToCase={this.props.addDeadlineToCase}
        />
      );

      cols[i % 3].push(caseCard);
      smallCols[i % 2].push(caseCard);
    }
    return [cols, smallCols];
  };

  renderCaseGrid = (cases) => {
    var caseCols = this.generateCaseCols(cases);
    var smallCols = caseCols[1];
    return (
      <Grid fluid>
        {/* <Row className='hidden-sm'>
          <Col md = {4} style={{ paddingLeft: '2px', paddingRight: '2px'}}>
            { largeCols[0] }
          </Col>
          <Col md = {4} style={{ paddingLeft: '2px', paddingRight: '2px'}}>
            { largeCols[1] }
          </Col>
          <Col md = {4} style={{ paddingLeft: '2px', paddingRight: '2px'}}>
            { largeCols[2] }
          </Col>
        </Row> */}
        <Row>
          <Col md={6} style={{ paddingLeft: "2px", paddingRight: "20px" }}>
            {smallCols[0]}
          </Col>
          <Col md={6} style={{ paddingLeft: "2px", paddingRight: "20px" }}>
            {smallCols[1]}
          </Col>
        </Row>
      </Grid>
    );
  };

  onCaseChange = (caseModel) => {
    DataActions.updateCase(caseModel);
  };

  getShownItems = () => {
    return this.props.cases.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    return (
      <Grid fluid style={{ background: "#ffffff" }}>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            {this.renderCaseGrid(this.getShownItems())}
          </Col>
        </Row>
        {this.state.numberShown < this.props.cases.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col
              xs={12}
              className="text-center"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2"
                style={{ width: "75%" }}
              >
                <AxoLocal
                  key="showmore"
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(CaseGridView);
