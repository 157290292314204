import React, { useState, useEffect } from "react";

import moment from "moment";
import { ApiService } from "../../services/AxoServices";

import LexButton from "../LexButton";

import { AxoLocal } from "../LexUtilities";

import MitIDLogo from "../../resources/images/MitID.png";

let loginMessage, setLoginMessage;
let wref;

function MitIDView(props) {
  [loginMessage, setLoginMessage] = useState(null);

  let { onLogin, loginUserAccount, cacheLogin } = props;

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("message", onMitIDMessage);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", onMitIDMessage);
    }
  }, []);

  const onMitIDMessage = async (e) => {
    let event = e;
    if (event.origin !== "https://axolex-mitid.azurewebsites.net") {
      //window.alert(""Received message from unexpected origin : "" + event.origin + ""Expected origin: {0}"" );
      return;
    }

    if (loginUserAccount) {
      //Handle login
      let response = await ApiService.handleMitIDAccountLogon({
        uuid: event.data.uuid
      });

      console.log(response);
      if (!response.ok) {
        setLoginMessage("Login kunne ikke gennemføres. Prøv igen senere.");
        return;
      }

      let loginResult = await response.json();
      if (!!onLogin) {
        onLogin(loginResult);
        return;
      }

      setLoginMessage("Login handler required.");
    } else {
      //Handle saving of code
      if (!!onLogin) {
        onLogin(event.data.uuid);
        return;
      }

      setLoginMessage(loginMessage);
    }
  }

  if (!!loginMessage) {
    return <h4>{loginMessage}</h4>;
  }

  const onLoginClick = () => {
    if (!!wref && typeof wref.window === 'object') {
      wref.focus();
      return;
    }

    let h = 640;
    let w = 452;
    let y = window.outerHeight / 2 + window.screenY - (h / 2);
    let x = window.outerWidth / 2 + window.screenX - (w / 2);
    wref = window.open("https://axolex-mitid.azurewebsites.net", "Log ind på Axolex", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);

    // window.location.href = "https://axolex-mitid.azurewebsites.net";
  }

  return (
    <div>
      <LexButton
        onClick={onLoginClick}
      >
        Log ind med <img alt="" src={MitIDLogo} width="90" height="20" />
      </LexButton>
      {/* <div id="mitIDFrameHolder">
        <iframe
          id="mitidiframe"
          name="mitidiframe"
          key="mitIDFrame"
          title="mitidiframe"
          scrolling="no"
          style={{
            width: "320px",
            height: "460px",
            border: "0px",
            margin: "0px",
            padding: "0px",
            overflow: "hidden",
          }}
          // style=""width:{0}px;height:{1}px;border:0px;margin:0px;padding:0px;overflow:hidden;""
          src={"https://axolex-mitid.azurewebsites.net"}
        ></iframe>
      </div> */}
    </div>
  );
}

export default React.memo(MitIDView);
