import React from "react";
import { withRouter } from "react-router-dom";
import RoutingService from "../services/RoutingService";
import ModalService from "../services/ModalService";
import DataActions from "../services/DataAccess/DataActions";
import screenfull from "screenfull";
import FlagMenu from "../utilities/FlagMenu";

import { Row, Col, Grid, Button } from "react-bootstrap";
import {
  Icon,
  AlertModal,
  ConfirmModal,
  AxoLocal,
} from "../utilities/LexUtilities";

class MarketingDashboardView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickRoute = (route) => {
    this.props.history.push(RoutingService.getPath(route));
  };

  onLogout = async () => {
    let response = await DataActions.logout().then((response) => {
      if (response.ok) {
        this.props.history.push("/Xlink/Account/Login");
      }
    });

    return response.ok;
    // location.reload();
  };

  onFullScreenToggle = () => {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
    this.setState({}); //Trigger a rerender to update the full screen button
  };

  renderFullScreenButtonContent = () => {
    if (screenfull.isFullscreen) {
      return (
        <Icon glyph="icon-fontello-resize-small-3" className="Topmenuimg" />
      );
    }
    return <Icon glyph="icon-fontello-resize-full-4" className="Topmenuimg" />;
  };

  onLock = () => {
    var settings = Object.assign({}, this.props.userSettings);
    settings.locked = true;
    DataActions.updateUserSettings(settings).then((response) => {
      if (response.ok) {
        this.props.history.push("/Lock");
      }
    });
  };

  renderNavBar = () => {
    return (
      <div>
        <Grid fluid>
          <Row className="AxolexNavigationBars">
            <Col className="nopadding" xs={12}>
              <div
                className=" btn-group-justified "
                role="group"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  className="btn-group Lex-button-1xlink Topmenuxlink"
                  role="group"
                >
                  <Button
                    onClick={this.onGotoFrontPage}
                    className="Lex-button-1xlink Topmenuxlink linklogoheight"
                    style={{ boder: "0px" }}
                  >
                    <div style={{ padding: "0px", boder: "0px" }}>
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-md"
                        src="/imgs/app/AxologoA-30x30.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-md hidden-xs"
                        src="/imgs/app/AxologoA-40x40.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-xs"
                        src="/imgs/app/AxologoA-40x40.png"
                      />
                      <img
                        alt=""
                        className="hidden-md hidden-sm hidden-xs"
                        src="/imgs/app/AxologoA-70x70.png"
                      />
                    </div>
                  </Button>
                </div>
                <div className="btn-group" role="group">
                  <Button
                    className="Lex-button-1xlink Topmenuxlink AxoNavigationButtonheight"
                    onClick={this.onClickRoute.bind(this, "adminmain")}
                  >
                    <div>
                      <span className="icon-fontello-eye AxoNavigationheightIcon" />
                    </div>
                    <div className="AxoNavigationfonttaxt">
                      <AxoLocal
                        entity="presentationMarketing11"
                        defaultValue="Seneste"
                      />{" "}
                    </div>
                  </Button>
                </div>
                <div className="btn-group" role="group">
                  <Button
                    className="Lex-button-1xlink Topmenuxlink AxoNavigationButtonheight"
                    onClick={this.onClickRoute.bind(this, "adminlist")}
                  >
                    <div>
                      <span className="icon-fontello-th-list-2 AxoNavigationheightIcon" />
                    </div>
                    <div className="AxoNavigationfonttaxt">
                      <AxoLocal
                        entity="presentationMarketing12"
                        defaultValue="Historik"
                      />
                    </div>
                  </Button>
                </div>
                <div className="btn-group" role="group">
                  <Button
                    className="Lex-button-1xlink Topmenuxlink AxoNavigationButtonheight"
                    onClick={this.onClickRoute.bind(this, "")}
                  >
                    <div>
                      <span className=" icon-fontello-pencil-alt-1 AxoNavigationheightIcon" />
                    </div>
                    <div className="AxoNavigationfonttaxt">
                      <AxoLocal entity="ADDViewCreate" defaultValue="Opret" />
                    </div>
                  </Button>
                </div>
                <div className="btn-group" role="group">
                  <Button
                    className="Lex-button-1xlink Topmenuxlink AxoNavigationButtonheight"
                    onClick={this.onClickRoute.bind(this, "setup")}
                  >
                    <div>
                      {" "}
                      <span className="icon-fontello-cog-7 AxoNavigationheightIcon" />
                    </div>
                    <div className="AxoNavigationfonttaxt">
                      <AxoLocal
                        entity="ClientSetupViewSetup"
                        defaultValue={"Setup"}
                      />
                    </div>
                  </Button>
                </div>
                <div className="btn-group hidden-xs hidden-sm" role="group">
                  <div className="btn-group btn-group-justified" role="group">
                    <div
                      className="btn-group Lex-button-1xlink Topmenuxlink hidden-xs hidden-sm"
                      role="group"
                      style={{ with: "100%" }}
                    >
                      <FlagMenu
                        style={{ with: "100%" }}
                        className="Lex-button-1xlink Topmenuxlink AxoNavigationButtonheight"
                        userSettings={this.props.userSettings}
                      />
                    </div>
                    <div className="btn-group hidden-xs hidden-sm" role="group">
                      <Button
                        className="Lex-button-1xlink Topmenuxlink AxoNavigationButtonheight"
                        onClick={this.onFullScreenToggle}
                      >
                        {this.renderFullScreenButtonContent()}
                      </Button>
                    </div>
                    <div className="btn-group hidden-xs hidden-sm" role="group">
                      <Button
                        className="Lex-button-1xlink Topmenuxlink AxoNavigationButtonheight"
                        onClick={this.onLock}
                      >
                        <div>
                          <Icon
                            glyph="icon-fontello-lock-2"
                            className="AxoNavigationheightIcon"
                          />
                        </div>
                      </Button>
                    </div>
                    <div className="btn-group hidden-xs hidden-sm" role="group">
                      <Button
                        className="Lex-button-1xlink Topmenuxlink AxoNavigationButtonheight"
                        onClick={this.onLogout}
                      >
                        <div>
                          <Icon
                            glyph="icon-fontello-logout-3"
                            className="AxoNavigationheightIcon"
                          />
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };

  onGotoFrontPage = () => {
    this.props.history.push("/freeware");
  };

  renderFooter = () => {
    return (
      <Grid
        fluid
        className="frontPageaxolexdiv Bannecolor"
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <Row className="text-center axo-footer">
          <div className="flexbox-justified frontPageaxolexdiv Bannecolor">
            <div>
              <div
                className="btn-group-justified flexbox-justified"
                role="group"
              >
                <div className="btn-group">
                  <Button
                    onClick={this.onGotoFrontPage}
                    className="Lex-button-1xlink AxoNavigationButtonfooter AxoNavigationfonttaxt"
                  >
                    <div>
                      © &nbsp;
                      <span className="hidden-xs">
                        <AxoLocal
                          entity="AxolexLink"
                          defaultValue="Axolex-link"
                        />
                      </span>{" "}
                      &nbsp;
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-md"
                        src="/imgs/app/AxologoA-30x30.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-md hidden-xs"
                        src="/imgs/app/AxologoA-35x35.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-xs"
                        src="/imgs/app/AxologoA-40x40.png"
                      />
                      <img
                        alt=""
                        className="hidden-md hidden-sm hidden-xs"
                        src="/imgs/app/AxologoA-40x40.png"
                      />
                      {/* <AxoLocal entity='Axolex Link' defaultValue={'Axolex-link'}/>  */}
                    </div>
                  </Button>
                </div>
                <div
                  className="btn-group hidden-md hidden-lg justified-flag"
                  role="group"
                >
                  <FlagMenu
                    className="btn-group Lex-button-1xlink AxoNavigationButtonfooter"
                    dropup
                    userSettings={this.props.userSettings}
                  />
                </div>
                <div className="btn-group  hidden-md hidden-lg" role="group">
                  <Button
                    className="Lex-button-1xlink AxoNavigationButtonfooter"
                    onClick={this.onLock}
                  >
                    <div>
                      <Icon
                        glyph="icon-fontello-lock-2"
                        className="AxoNavigationheightIcon"
                      />
                    </div>
                  </Button>
                </div>
                <div className="btn-group hidden-md hidden-lg " role="group">
                  <Button
                    className="Lex-button-1xlink AxoNavigationButtonfooter"
                    onClick={this.onLogout}
                  >
                    <div>
                      <Icon
                        glyph="icon-fontello-logout-3"
                        className="AxoNavigationheightIcon"
                      />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Grid>
    );
  };

  //style={{ minHeight: '800px'}}
  renderBody = () => {
    return (
      <Grid fluid style={{ marginBottom: "45px" }}>
        <Row>
          <Col xs={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            {this.props.children}
          </Col>
        </Row>
      </Grid>
    );
  };

  render() {
    return (
      <div
        className="backgroundlineargradient3"
        style={{ minHeight: window.innerHeight }}
      >
        {this.renderNavBar()}
        {/* {this.renderButtonBar()} */}
        {this.renderBody()}
        {this.renderFooter()}
        <AlertModal ref={(c) => ModalService.registerAlertModal(c)} />
        <ConfirmModal ref={(c) => ModalService.registerConfirmModal(c)} />
      </div>
    );
  }
}

export default withRouter(MarketingDashboardView);
