import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import PowerOfAttorneyTabView from "./PowerOfAttorneyTabView";

import { withData } from "../utilities/LexUtilities";

//Filter out needed data from DataStore.js
const mapStoreToProps = (store) => {
  return {
    powerOfAttorneys: store.powerOfAttorneys,
    showDropdowns: store.showDropdowns,
    userProfile: store.userProfile,
  };
};

export default withData(
  class PowerOfAttorneyContainer extends React.PureComponent {
    componentDidMount() {
      //Fetch data on page load
      DataStore.fetchPowerOfAttorneys();
      DataStore.initializeUserProfile();
    }

    componentWillUnmount() {
      //Do stuff when navigating away
    }

    render() {
      return <PowerOfAttorneyTabView {...this.props} />;
    }
  },
  mapStoreToProps
);
