import React from "react";
import Autosuggest from "react-autosuggest";
import ApiService from "../../services/DataAccess/ApiService";
import { debounce } from "lodash";

var theme = {
  container: {
    position: "relative",
  },
  input: {
    width: 240,
    height: 30,
    padding: "10px 20px",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 12,
    border: "1px solid #aaa",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 30,
    width: 280,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
    maxHeight: "500px",
    overflowY: "auto",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#e6e6e6",
  },
};

//Props
//cases
//handleSelectedCase
//disabled
export default class CaseSearchBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      suggestions: [],
      cases: props.cases,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cases.length === this.props.cases.length) {
      return;
    }

    this.setState({
      cases: nextProps.cases,
    });
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    var { count } = this.props;

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    //If not all cases have been loaded into memory, perform a server-side search
    if (!!count && this.props.cases.length < count) {
      this.getCases(inputValue);
    }

    return inputLength === 0
      ? this.state.cases
      : this.state.cases.filter((caseModel) => {
          return caseModel.caseNumber.toLowerCase().includes(inputValue);
        });
  };

  getCases = debounce((query) => {
    ApiService.getCases(25, query).then((caseMap) => {
      this.setState({
        cases: caseMap.saved,
        suggestions: caseMap.saved,
      });
    });
  }, 350);

  // When suggestion is clicked, Autosuggest needs to populate the input element
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.caseNumber;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => (
    <div>
      {suggestion.caseNumber}{" "}
      {!!this.props.showDocumentCount ? (
        <span>({suggestion.documents.length})</span>
      ) : null}
    </div>
  );

  onChange = (event, { newValue }) => {
    this.setState({
      searchValue: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.handleSelectedCase(suggestion);
    if (this.props.clearOnSelect) {
      this.setState(
        {
          searchValue: "",
          suggestions: [],
        },
        () => {
          document.activeElement.blur();
        }
      );
    }
  };

  shouldRenderSuggestions(value) {
    return true;
  }

  //Props
  //inputBorder
  //Placeholder
  render() {
    var searchValue = this.state.searchValue;
    const inputProps = {
      className: "searchbox",
      placeholder: this.props.placeholder,
      value: searchValue,
      disabled: this.props.disabled,
      onChange: this.onChange,
    };
    if (this.props.inputBorder) {
      theme.input.border = this.props.inputBorder;
    }
    if (this.props.maxWidth) {
      theme.input.maxWidth = this.props.maxWidth;
      theme.input.display = "block";
      theme.input.width = "100%";
    }
    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        theme={theme}
      />
    );
  }
}
