import React from "react";
// import TinyMCEEditor from '../../utilities/Editors/TinyMCEEditor'
import SimpleTinyMCEEditor from "../../utilities/Editors/SimpleTinyMCEEditor";
import { withRouter } from "react-router-dom";

import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  FormControl,
  ButtonGroup,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import {
  ApiService,
  RoutingService,
  DataActions,
  ModalService,
  UserInfoService,
  AuthorizationService,
} from "../../services/AxoServices";

import {
  DocumentSearchBox,
  CaseSearchBox,
  UserSearchBox,
  ClientSearchBox,
  LabelSearchBox,
  Icon,
  FileViewerModal,
  Uniqueomat,
  Dropzonejs,
  AxoLocal,
  AxoDropzone,
  LexButton,
  Flexbox,
  EmailAutoComplete,
  FlexElement,
  getText,
  AsyncButton,
} from "../../utilities/LexUtilities";

class ComposeView extends React.PureComponent {
  constructor(props) {
    super(props);
    var openMessage = props.message;
    let ccMailListString = openMessage.externalMailCCReceiver || "";
    let receiverMailListString = openMessage.externalMailReceiver || "";

    //Reply All should not include sender email
    if (!!ccMailListString) {
      let { selectedEmailAccount, emailAccounts } = props;

      let account = emailAccounts.find((a) => a.id === selectedEmailAccount);
      if (!!account) {
        let currentEmail = account.account;
        let regEx = new RegExp(`${currentEmail};`, "ig"); //Case insensitive replace
        let regExWithoutSemicolon = new RegExp(`${currentEmail}`, "ig");

        ccMailListString = ccMailListString.replace(regEx, "");
        ccMailListString = ccMailListString.replace(regExWithoutSemicolon, "");
      }
    }

    this.state = {
      messageState: Object.assign({}, openMessage),
      isSending: false,
      showWarning: false,
      initialText: openMessage.messageText,
      bccUsers: [],
      receiverMailListString,
      receiverMailListStringFull: receiverMailListString,
      bccMailListString: props.cachedBCCList || "",
      bccMailListStringFull: props.cachedBCCList || "",
      ccMailListString,
      ccMailListStringFull: ccMailListString,
      validationState: {
        missingSubject: false,
      },
      receiverStartNumber: 1, //Used to limit receivers for bulk emails
      receiverEndNumber: 10000,
    };

    if (!!this.state.bccMailListString && !!props.userProfile) {
      this.state.messageState.externalMailReceiver = props.userProfile.eMail;
    }

    this.inProgress = false;
  }

  onMessagePropertyChange = (propertyName, event) => {
    this.onMessagePropertyValueChange(propertyName, event.target.value);
  };

  onMessagePropertyValueChange = (propertyName, value) => {
    let { messageState } = this.state;

    this.inProgress = true;
    this.setState({
      messageState: {
        ...messageState,
        [propertyName]: value,
      },
    });
  };

  onMessageChangeMCE = (content) => {
    var messageState = Object.assign({}, this.state.messageState);
    this.inProgress = true;
    messageState.messageText = content;
    this.setState({ messageState });
  };

  getPath = (path) => {
    return RoutingService.getPath(path);
  };

  createEmailViewModel = (messageState) => {
    let { selectedEmailAccount, emailAccounts } = this.props;

    let { receiverMailListString, bccMailListString, ccMailListString } =
      this.state;

    let account = emailAccounts.find((a) => a.id === selectedEmailAccount);
    if (!account) {
      return;
    }

    let replyId =
      selectedEmailAccount === messageState.externalAccountId
        ? messageState.replyToUniqueId
        : null; //Do not set reply headers if account has changed

    return {
      accountId: selectedEmailAccount,
      to: receiverMailListString,
      bcc: bccMailListString,
      cc: ccMailListString,
      fromName: account.displayName,
      toName: messageState.externalReceiverDisplayName,
      replyToUniqueId: replyId,
      subject: messageState.subject,
      body: messageState.messageText,
      attachments: messageState.attachments,
      caseModelId: messageState.caseModelId,
    };
  };

  onSendClicked = () => {
    let { deleteMessage } = this.props;

    var { messageState, receiverMailListString } = this.state;
    var validationState = Object.assign({}, this.state.validationState);

    if (
      !messageState.receiver &&
      !messageState.externalMailReceiver &&
      !receiverMailListString
    ) {
      this.setState({ showWarning: true });
      return;
    }
    if (!messageState.subject) {
      validationState.missingSubject = true;
      this.setState({ validationState });
      return;
    }
    if (!!this.findInvalidMailFromBCCList()) {
      return;
    }
    if (!!this.findInvalidMailFromCCList()) {
      return;
    }

    this.setState({ isSending: true });
    if (messageState.externalMailReceiver || receiverMailListString) {
      ApiService.sendEmail(this.createEmailViewModel(messageState))
        .then((response) => {
          if (response.ok) {
            this.inProgress = false;
            this.props.history.push(this.getPath("mailbox/inbox"));
            //Delete the draft when sending an external mail
            if (messageState.id) {
              deleteMessage(messageState.id);
            }
            return this.updateExternalEmails();
          } else {
            console.log(response.status);
            response.text().then((message) => {
              console.log(message);
              ModalService.openAlertModal(
                <div>
                  <div>
                    <AxoLocal
                      entity="ThemailcouldnotbesentPleasetryagainlater"
                      defaultValue={
                        "Mailen kunne ikke sendes. Prøv igen senere."
                      }
                    />
                  </div>
                  <AxoLocal
                    entity="axoEntityidcode60"
                    defaultValue={"Fejlbesked"}
                  />
                  : {message}
                </div>
              );
            });
            this.setState({ isSending: false });
          }
        })
        .then(() => {
          // this.inProgress = false;
          // this.props.history.goBack();
        })
        .catch(this.handleServerError);
    }
    if (messageState.receiver) {
      this.sendMessage(messageState);
    }
  };

  updateExternalEmails = () => {
    let { refetchMessages } = this.props;

    return ApiService.getEmailsFromAccount(
      this.props.selectedEmailAccount
    ).then(() => {
      return refetchMessages();
    });
  };

  sendMessage = (messageState) => {
    if (!messageState.receiver && !messageState.externalMailReceiver) {
      return;
    }
    this.inProgress = false;

    let sentMessage = {
      ...messageState,
      draft: false,
    };

    if (!messageState.id) {
      this.sendNewMessage(sentMessage);
    } else {
      this.sendMessageFromDrafts(sentMessage);
    }
  };

  onSave = async () => {
    try {
      let result = await this.trySave();
      console.log(result);
      this.inProgress = false;
      return result;
    } catch (result) {
      return false;
    }
  };

  trySave = () => {
    let { createMessage, updateMessage } = this.props;
    let { messageState } = this.state;

    if (!messageState.id) {
      return createMessage(messageState).unwrap();
    } else {
      return updateMessage(messageState).unwrap();
    }
  };

  sendNewMessage = async (messageState) => {
    let { createMessage } = this.props;

    try {
      await createMessage(messageState).unwrap();
      this.addCaseInfoToAttachments();
      this.addBCCUsers();
      this.inProgress = false;
      this.goBack();
    } catch {
      this.setState({ isSending: false });
      ModalService.openAlertModal(
        <AxoLocal
          entity="ThemailcouldnotbesentPleasetryagainlater"
          defaultValue={"Mailen kunne ikke sendes. Prøv igen senere."}
        />
      );
    }
  };

  addCaseInfoToAttachments = () => {
    if (!this.state.messageState.caseModel) {
      return;
    }
    this.state.messageState.attachments.forEach((doc) => {
      doc.notes = this.state.messageState.caseModel.caseNumber;
      DataActions.updateDocument(doc);
    });
    return;
  };

  addBCCUsers = () => {
    let { createMessage } = this.props;

    this.state.bccUsers.forEach((bccUser) => {
      let bccMessage = Object.assign({}, this.state.messageState);
      delete bccMessage.id;
      bccMessage.primaryReceiver = this.state.messageState.receiver;
      bccMessage.receiver = bccUser;
      bccMessage.subject = "BCC: " + bccMessage.subject;
      createMessage(bccMessage);
    });
  };

  sendMessageFromDrafts = async (messageState) => {
    let { updateMessage } = this.props;

    try {
      await updateMessage(messageState).unwrap();
      this.addCaseInfoToAttachments();
      this.addBCCUsers();
      this.inProgress = false;
      this.goBack();
    } catch {
      this.setState({ isSending: false });
      ModalService.openAlertModal(
        <AxoLocal
          entity="ThemailcouldnotbesentPleasetryagainlater"
          defaultValue={"Mailen kunne ikke sendes. Prøv igen senere."}
        />
      );
    }
  };

  onDiscardMail = () => {
    let { updateMessage, deleteMessage } = this.props;

    var message = this.state.messageState;
    if (message.id && message.draft) {
      if (!message.trashed) {
        var newMessage = Object.assign({}, message);
        newMessage.trashed = true;
        updateMessage(newMessage);
        this.goBack();
      } else {
        ModalService.openConfirmModal(
          <AxoLocal
            key="trash"
            entity="DocumentmessageDataActions"
            defaultValue={"Vil du slette denne mail permanent?"}
          />,
          (value) => {
            if (value) {
              deleteMessage(message.id);
              this.goBack();
            }
          }
        );
      }
    } else {
      this.goBack();
    }
  };

  goBack = () => {
    this.inProgress = false;
    this.props.history.push(RoutingService.getPath("mailbox/inbox/"));
  };

  onBackClick = () => {
    this.goBack();
  };

  onAttachFile = (file) => {
    let { messageState } = this.state;

    this.setState({
      messageState: {
        ...messageState,
        attachments: [...messageState.attachments, file],
      },
    });
    this.inProgress = true;
  };

  onFileRemoved = (fileId) => {
    let { messageState } = this.state;

    this.setState({
      messageState: {
        ...messageState,
        attachments: messageState.attachments.filter((f) => f.id !== fileId),
      },
    });
    this.inProgress = true;
  };

  componentWillUnmount() {
    if (this.inProgress) {
      this.onSave();
    }
  }

  onFileClick(file, messageId) {
    this.fileModal.open([file], { messageId, fileIndex: 0 });
  }

  renderAttachmentSummary = (currentMessage) => {
    if (currentMessage.attachments.length === 0) {
      return null;
    }
    var totalSize = currentMessage.attachments.reduce((acc, attachment) => {
      return acc + attachment.size;
    }, 0);
    var fileString =
      currentMessage.attachments.length > 1 ? (
        <AxoLocal
          key="Files"
          entity="Searchattachmentsfiles"
          defaultValue={"filer"}
        />
      ) : (
        <AxoLocal
          key="File"
          entity="Searchattachmentsfile"
          defaultValue={"fil"}
        />
      );

    return (
      <p>
        <strong>
          <AxoLocal
            entity="currentMessageAttachedfiles"
            defaultValue={"Vedhæftede filer"}
          />
        </strong>
        &nbsp;
        <span className="editable">
          {currentMessage.attachments.length} {fileString}, (
          {Math.floor(totalSize / 1024) + " KB"})
        </span>
      </p>
    );
  };

  onRemoveAttachment = (attachmentId, event) => {
    event.stopPropagation();
    var currentMessage = Object.assign({}, this.state.messageState);
    currentMessage.attachments = currentMessage.attachments.filter(
      (f) => f.id !== attachmentId
    );
    this.setState({ messageState: currentMessage });
  };

  renderAttachmentNodes = (currentMessage) => {
    return currentMessage.attachments.map((attachment) => {
      return (
        <p key={attachment.id}>
          <span
            className="attachment-link"
            role="button"
            onClick={this.onFileClick.bind(
              this,
              attachment,
              !currentMessage.draft ? currentMessage.id : null
            )}
          >
            <Icon glyph="icon-fontello-picture-1" />
            <span>
              <strong>{attachment.fileName}</strong>&nbsp;
              <span className="editable">({attachment.sizeString})</span>
            </span>
            &nbsp;&nbsp;&nbsp;
            <AxoLocal
              componentClass={Icon}
              className="axored"
              role="button"
              onClick={this.onRemoveAttachment.bind(this, attachment.id)}
              glyph="icon-fontello-scissors"
              componentAttribute="title"
              entity="ComposeViewRemoveAttachment"
            />
          </span>
        </p>
      );
    });
  };

  renderAttachmentSection = (currentMessage) => {
    return (
      <div>
        {this.renderAttachmentSummary(currentMessage)}
        {this.renderAttachmentNodes(currentMessage)}
      </div>
    );
  };

  renderSendButtonContent = () => {
    if (this.state.isSending) {
      return (
        <span>
          {" "}
          <AxoLocal
            entity="currentMessageisSending"
            defaultValue={"Sender... "}
          />
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </span>
      );
    } else {
      return (
        <span>
          <Icon glyph="icon-fontello-share-2" />
          &nbsp;&nbsp;{" "}
          <AxoLocal
            entity="currentMessageisSendButtonContent"
            defaultValue={"Send"}
          />
        </span>
      );
    }
  };

  getReceiverValidationState = () => {
    var { showWarning, messageState } = this.state;
    if (
      showWarning &&
      !messageState.receiver &&
      !messageState.externalMailReceiver
    ) {
      return "error";
    }
    return null;
  };

  getExternalReceiverValidationState = () => {
    var { messageState } = this.state;
    if (messageState.externalMailReceiver) {
      return this.validateEmail(messageState.externalMailReceiver)
        ? "success"
        : "error";
    }
    return null;
  };

  handleSelectedUser = (userProfile) => {
    var message = Object.assign({}, this.state.messageState);
    message.receiver = userProfile;
    this.setState({ messageState: message });
  };

  handleSelectedBBCUser = (userProfile) => {
    this.state.bccUsers.push(userProfile);
    this.setState({ bccUsers: this.state.bccUsers });
  };

  onRemoveBCCUser = (userProfileId) => {
    var bccUsers = this.state.bccUsers.filter((b) => b.id !== userProfileId);
    this.setState({ bccUsers: bccUsers });
  };

  renderBCCNodes = () => {
    return this.state.bccUsers.map((profile) => {
      return (
        <span
          key={profile.id}
          style={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "white",
          }}
        >
          <span>{UserInfoService.getDisplayName(profile)}</span>
          &nbsp;&nbsp;&nbsp;
          <AxoLocal
            componentClass={Icon}
            className="axored"
            role="button"
            onClick={this.onRemoveBCCUser.bind(this, profile.id)}
            glyph="icon-fontello-scissors"
            componentAttribute="title"
            entity="RemoveBCCTitle"
          />
        </span>
      );
    });
  };

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  handleSelectedContact = (contact) => {
    var message = Object.assign({}, this.state.messageState);
    message.externalMailReceiver = contact.eMail;
    this.setState({ messageState: message });
  };

  onChangeMailList = (event) => {
    this.setState({
      receiverMailListString: event.target.value,
    });
  };

  onChangeMailListValue = (value) => {
    this.setState({
      receiverMailListString: value,
    });
  };

  resetMailList = () => {
    this.setState({
      receiverMailListString: "",
      receiverMailListStringFull: "",
    });
  };

  handleSelectedMailContact = (contact) => {
    var mailList = this.state.receiverMailListString;
    if (!mailList) {
      mailList = (contact.eMail || "").trim();
    } else {
      mailList += "; " + (contact.eMail || "").trim();
    }
    this.setState({ receiverMailListString: mailList });
  };

  findInvalidMailFromMailList = () => {
    var mailArray = this.state.receiverMailListString
      .split(";")
      .map((m) => m.trim());
    return mailArray.find((m) => !this.validateEmail(m));
  };

  onChangeBccList = (event) => {
    this.setState({
      bccMailListString: event.target.value,
    });
  };

  onChangeBccListValue = (value) => {
    this.setState({
      bccMailListString: value,
    });
  };

  resetBccList = () => {
    this.setState({
      bccMailListString: "",
      bccMailListStringFull: "",
    });
  };

  handleSelectedBCCContact = (contact) => {
    var mailList = this.state.bccMailListString;
    if (!mailList) {
      mailList = (contact.eMail || "").trim();
    } else {
      mailList += "; " + (contact.eMail || "").trim();
    }
    this.setState({ bccMailListString: mailList });
  };

  findInvalidMailFromBCCList = () => {
    var mailArray = this.state.bccMailListString
      .split(";")
      .map((m) => m.trim());
    return mailArray.find((m) => !this.validateEmail(m));
  };

  onChangeCcList = (event) => {
    this.setState({
      ccMailListString: event.target.value,
    });
  };

  onChangeCcListValue = (value) => {
    this.setState({
      ccMailListString: value,
    });
  };

  resetCcList = () => {
    this.setState({
      ccMailListString: "",
      ccMailListStringFull: "",
    });
  };

  handleSelectedCCContact = (contact) => {
    var mailList = this.state.ccMailListString;
    if (!mailList) {
      mailList = (contact.eMail || "").trim();
    } else {
      mailList += "; " + (contact.eMail || "").trim();
    }
    this.setState({ ccMailListString: mailList });
  };

  findInvalidMailFromCCList = () => {
    var mailArray = this.state.ccMailListString.split(";").map((m) => m.trim());
    return mailArray.find((m) => !this.validateEmail(m));
  };

  getTotalMemberLabel = () => {
    let { contactMap, userProfile, userSettings } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);
    if (userType !== "Society") {
      return null;
    }

    let activeMembers = contactMap.contacts.filter(
      (c) =>
        !c.labels.find(
          (l) =>
            l.id === userSettings.formerMembersLabelId ||
            l.id === userSettings.waitingMembersLabelId
        )
    );

    return {
      // MissingEntity
      id: "All",
      name: `Alle medlemmer`,
      clientIds: activeMembers.map((c) => c.id),
    };
  };

  renderAdvancedControls = (allProfiles) => {
    let {
      clientCompose,
      emailAccounts,
      selectedEmailAccount,
      onSelectEmailAccount,
      contactMap,
      labels,
      emailSuggestions,
    } = this.props;

    let {
      receiverStartNumber,
      receiverEndNumber,
      receiverMailListString,
      receiverMailListStringFull,
      bccMailListString,
      bccMailListStringFull,
      ccMailListString,
      ccMailListStringFull,
      messageState,
    } = this.state;

    var externalReceiverValidationState =
      this.getExternalReceiverValidationState();
    let invalidReceiverMailList = this.findInvalidMailFromMailList();
    let invalidBCCListMail = this.findInvalidMailFromBCCList();
    let invalidCCListMail = this.findInvalidMailFromCCList();

    let totalMemberLabel = this.getTotalMemberLabel();
    let activeLabels = labels;
    if (!!totalMemberLabel) {
      activeLabels = [totalMemberLabel].concat(activeLabels);
    }

    return (
      <div style={{ textAlign: "left" }}>
        {!!messageState.receiver && (
          <FormGroup controlId="email-cc">
            <Col
              componentClass={ControlLabel}
              sm={2}
              style={{ textAlign: "left" }}
            >
              {" "}
              <AxoLocal entity="currentMessageBCCNode" defaultValue={"BCC"} />
            </Col>
            <Col sm={10} style={{ textAlign: "left" }}>
              {this.renderBCCNodes()}
              <div>
                <UserSearchBox
                  maxWidth="500px"
                  userProfiles={allProfiles}
                  handleSelectedUser={this.handleSelectedBBCUser}
                  clearOnSelect
                  placeholder={
                    clientCompose
                      ? getText("ComposeSelectLawyerPlaceholder", "Find...")
                      : getText(
                          "ComposeSelectUserPlaceholder",
                          "Find intern modtager fra vennelisten..."
                        )
                  }
                />
              </div>
            </Col>
          </FormGroup>
        )}
        {/* {!clientCompose && emailAccounts.length > 0 && (
          <FormGroup controlId='email' validationState={this.getReceiverValidationState() || externalReceiverValidationState}>
            <Col componentClass={ControlLabel} sm={2} style={{ textAlign: 'left'}} >
              <AxoLocal entity='CaseEditFormeMail'defaultValue={'Email'}/>
            </Col>
            <Col sm={10} style={{ textAlign: 'left'}}>
              <Flexbox responsive>
                <FlexElement flexBasis='500px'>
                  <EmailAutoComplete
                    value={messageState.externalMailReceiver || ''}
                    emailSuggestions={emailSuggestions}
                    maxWidth='500px'
                    onChange={event => {
                      if(event.target.value === undefined) {
                        return; //Happens when selecting from the dropdown
                      }
                      this.onMessagePropertyValueChange('externalMailReceiver', event.target.value.trim() );
                      if(!!messageState.externalReceiverDisplayName) {
                        //Clear receiver name when the address changes
                        this.onMessagePropertyValueChange('externalReceiverDisplayName', '');
                      }
                    }}
                    handleSelectedOption={option => {
                      this.setState(prevState => ({
                        messageState: {
                          ...prevState.messageState,
                          externalMailReceiver: option.email.trim(),
                          externalReceiverDisplayName: option.displayName.trim()
                        }
                      }));
                    }}
                  />
                </FlexElement>
                <FlexElement className='leftPadding'>
                  <AxoLocal 
                    componentClass={ClientSearchBox}
                    maxWidth='200px'
                    clients={contactMap.contacts}
                    count={contactMap.count}
                    onlyEmails
                    handleSelectedClient={this.handleSelectedContact}
                    componentAttribute="placeholder"
                    entity='composeSelectClientPlaceholder'
                  />
                </FlexElement>
                { labels.length > 0 && (
                  <FlexElement className='leftPadding'>
                    <AxoLocal 
                      componentClass={LabelSearchBox}
                      labels={labels} 
                      handleSelectedLabel={this.selectGroup}
                      disabled={labels.length === 0}
                      // inputBorder='1px solid #4CAF50'
                      clearOnSelect
                      componentAttribute="placeholder"
                      entity='ContactLabelPlaceholder'
                    />
                  </FlexElement>
                ) }
              </Flexbox>
            </Col>
          </FormGroup>
        ) } */}
        {!clientCompose && emailAccounts.length > 0 && (
          <FormGroup controlId="email-list">
            <Col
              componentClass={ControlLabel}
              sm={2}
              style={{ textAlign: "left" }}
            >
              {" "}
              <AxoLocal
                entity="CaseEditFormeMail"
                defaultValue={"Email"}
              />{" "}
            </Col>
            <Col sm={10} style={{ textAlign: "left" }}>
              {!!receiverMailListStringFull &&
              receiverMailListStringFull.length > 150 ? (
                <div className="standardMaxWidth">
                  <Flexbox alignCenter>
                    <FlexElement flexBasis="500px">
                      <FormControl
                        style={{
                          maxWidth: "500px",
                          display: "inline-block",
                        }}
                        componentClass="textarea"
                        rows={5}
                        value={receiverMailListString}
                        onChange={this.onChangeMailList}
                      />
                    </FlexElement>
                    <FlexElement className="leftPadding">
                      <AxoLocal
                        componentClass={ClientSearchBox}
                        maxWidth="200px"
                        clients={contactMap.contacts}
                        count={contactMap.count}
                        onlyEmails
                        handleSelectedClient={this.handleSelectedMailContact}
                        clearOnSelect
                        componentAttribute="placeholder"
                        entity="composeSelectClientPlaceholder"
                      />
                    </FlexElement>
                  </Flexbox>
                  <Flexbox collapseXS>
                    <FormGroup
                      controlId="from"
                      style={{ margin: "0px" }}
                      className="rightPadding flexbox-standard"
                    >
                      <ControlLabel
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <AxoLocal entity="axoidcode138" defaultValue={"Fra"} />
                        :&nbsp;&nbsp;&nbsp;
                      </ControlLabel>
                      <FormControl
                        type="number"
                        className="borderFormControlfocus"
                        value={
                          isNaN(receiverStartNumber) ? "" : receiverStartNumber
                        }
                        onChange={(event) =>
                          this.onUpdateReceiverStartNumber(
                            parseInt(event.target.value, 10)
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="to"
                      style={{ margin: "0px" }}
                      className="flexbox-standard"
                    >
                      <ControlLabel
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "normal",
                        }}
                      >
                        <AxoLocal entity="axoidcode139" defaultValue={"Til"} />
                        :&nbsp;&nbsp;&nbsp;
                      </ControlLabel>
                      <FormControl
                        type="number"
                        className="borderFormControlfocus"
                        value={
                          isNaN(receiverEndNumber) ? "" : receiverEndNumber
                        }
                        onChange={(event) =>
                          this.onUpdateReceiverEndNumber(
                            parseInt(event.target.value, 10)
                          )
                        }
                      />
                    </FormGroup>
                  </Flexbox>
                  <div className="axored">
                    {/* MissingEntity */}
                    Da du sender fra modtagerfeltet, vil alle modtagere kunne se
                    hinandens email adresser.
                  </div>
                  <div className="axored">
                    {/* MissingEntity */}
                    Anvend BCC feltet hvis dette ikke er ønskværdigt.
                  </div>
                  <div>
                    <LexButton onClick={this.resetMailList}>
                      Nulstil modtagere
                    </LexButton>
                  </div>
                </div>
              ) : (
                <Flexbox>
                  <FlexElement flexBasis="515px" className="rightPadding">
                    <EmailAutoComplete
                      value={receiverMailListString || ""}
                      emailSuggestions={emailSuggestions}
                      maxWidth="500px"
                      onChange={(event) => {
                        if (
                          event.target.value === undefined ||
                          !event.target.value.trim
                        ) {
                          return; //Happens when selecting from the dropdown
                        }

                        this.onChangeMailListValue(event.target.value.trim());
                        if (!!messageState.externalReceiverDisplayName) {
                          //Clear receiver name when the address changes
                          this.onMessagePropertyValueChange(
                            "externalReceiverDisplayName",
                            ""
                          );
                        }
                      }}
                      handleSelectedOption={(option) => {
                        this.onChangeMailListValue(option.email.trim());
                        this.onMessagePropertyValueChange(
                          "externalReceiverDisplayName",
                          option.displayName.trim()
                        );
                      }}
                    />
                  </FlexElement>
                  <FlexElement>
                    <AxoLocal
                      componentClass={ClientSearchBox}
                      maxWidth="200px"
                      clients={contactMap.contacts}
                      count={contactMap.count}
                      onlyEmails
                      handleSelectedClient={this.handleSelectedMailContact}
                      clearOnSelect
                      componentAttribute="placeholder"
                      entity="composeSelectClientPlaceholder"
                    />
                  </FlexElement>
                  {labels.length > 0 && (
                    <FlexElement className="leftPadding">
                      <AxoLocal
                        componentClass={LabelSearchBox}
                        labels={activeLabels}
                        handleSelectedLabel={this.selectReceiverGroup}
                        disabled={activeLabels.length === 0}
                        // inputBorder='1px solid #4CAF50'
                        clearOnSelect
                        componentAttribute="placeholder"
                        entity="ContactLabelPlaceholder"
                      />
                    </FlexElement>
                  )}
                </Flexbox>
              )}
              {!!invalidReceiverMailList ? (
                <div className="axored">
                  <div>
                    <AxoLocal
                      entity="ComposeInvalidMailList"
                      defaultValue={'Indtast en liste af mails adskilt med ";"'}
                    />
                  </div>
                  <div>
                    {" "}
                    <AxoLocal
                      entity="axoidcode183"
                      defaultValue={"Denne mail er invalid:"}
                    />{" "}
                    {invalidReceiverMailList}
                  </div>
                </div>
              ) : null}
            </Col>
          </FormGroup>
        )}

        {(externalReceiverValidationState === "success" ||
          (receiverMailListString && !invalidReceiverMailList)) &&
        emailAccounts.length > 1 ? (
          <FormGroup controlId="account-select">
            <Col
              componentClass={ControlLabel}
              sm={2}
              style={{ textAlign: "left" }}
            >
              <AxoLocal
                entity="currentMessageExternalBCC1"
                defaultValue={"Email konto"}
              />
            </Col>
            <Col sm={10} style={{ textAlign: "left" }}>
              <div
                style={{
                  maxWidth: "500px",
                  border: !selectedEmailAccount ? "1px solid red" : "",
                }}
              >
                <select
                  className="axoblue selectbg"
                  style={{ width: "100%" }}
                  value={selectedEmailAccount}
                  onChange={onSelectEmailAccount}
                >
                  {[
                    <option key={0} value={0}>
                      ...
                    </option>,
                  ].concat(
                    emailAccounts.map((account) => {
                      return (
                        <option key={account.id} value={account.id}>
                          {account.displayName || account.account}
                        </option>
                      );
                    })
                  )}
                </select>
              </div>
            </Col>
          </FormGroup>
        ) : null}
        {!clientCompose &&
          emailAccounts.length > 0 &&
          (externalReceiverValidationState === "success" ||
            (receiverMailListString && !invalidReceiverMailList)) && (
            <>
              <FormGroup controlId="email-bcc">
                <Col
                  componentClass={ControlLabel}
                  sm={2}
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  <AxoLocal
                    entity="currentMessageExternalBCC"
                    defaultValue={"Mail BCC"}
                  />{" "}
                </Col>
                <Col sm={10} style={{ textAlign: "left" }}>
                  {!!bccMailListStringFull &&
                  bccMailListStringFull.length > 150 ? (
                    <div className="standardMaxWidth">
                      <Flexbox alignCenter>
                        <FlexElement flexBasis="500px">
                          <FormControl
                            style={{
                              maxWidth: "500px",
                              display: "inline-block",
                            }}
                            componentClass="textarea"
                            rows={5}
                            value={this.state.bccMailListString}
                            onChange={this.onChangeBccList}
                          />
                        </FlexElement>
                        <FlexElement className="leftPadding">
                          <AxoLocal
                            componentClass={ClientSearchBox}
                            maxWidth="200px"
                            clients={contactMap.contacts}
                            count={contactMap.count}
                            onlyEmails
                            handleSelectedClient={this.handleSelectedBCCContact}
                            clearOnSelect
                            componentAttribute="placeholder"
                            entity="composeSelectClientPlaceholder"
                          />
                        </FlexElement>
                      </Flexbox>
                      <Flexbox collapseXS>
                        <FormGroup
                          controlId="from"
                          style={{ margin: "0px" }}
                          className="rightPadding flexbox-standard"
                        >
                          <ControlLabel
                            style={{
                              textAlign: "left",
                              fontSize: "16px",
                              fontWeight: "normal",
                            }}
                          >
                            <AxoLocal
                              entity="axoidcode138"
                              defaultValue={"Fra"}
                            />
                            :&nbsp;&nbsp;&nbsp;
                          </ControlLabel>
                          <FormControl
                            type="number"
                            className="borderFormControlfocus"
                            value={
                              isNaN(receiverStartNumber)
                                ? ""
                                : receiverStartNumber
                            }
                            onChange={(event) =>
                              this.onUpdateReceiverStartNumber(
                                parseInt(event.target.value, 10)
                              )
                            }
                          />
                        </FormGroup>
                        <FormGroup
                          controlId="to"
                          style={{ margin: "0px" }}
                          className="flexbox-standard"
                        >
                          <ControlLabel
                            style={{
                              textAlign: "left",
                              fontSize: "16px",
                              fontWeight: "normal",
                            }}
                          >
                            <AxoLocal
                              entity="axoidcode139"
                              defaultValue={"Til"}
                            />
                            :&nbsp;&nbsp;&nbsp;
                          </ControlLabel>
                          <FormControl
                            type="number"
                            className="borderFormControlfocus"
                            value={
                              isNaN(receiverEndNumber) ? "" : receiverEndNumber
                            }
                            onChange={(event) =>
                              this.onUpdateReceiverEndNumber(
                                parseInt(event.target.value, 10)
                              )
                            }
                          />
                        </FormGroup>
                      </Flexbox>
                      <div>
                        <LexButton onClick={this.resetBccList}>
                          <AxoLocal
                            entity="axoEntityidcode61"
                            defaultValue={"Nulstil BCC"}
                          />
                        </LexButton>
                      </div>
                    </div>
                  ) : (
                    <Flexbox>
                      <FlexElement flexBasis="515px" className="rightPadding">
                        <EmailAutoComplete
                          value={bccMailListString || ""}
                          emailSuggestions={emailSuggestions}
                          onChange={(event) => {
                            if (
                              event.target.value === undefined ||
                              !event.target.value.trim
                            ) {
                              return; //Happens when selecting from the dropdown
                            }
                            this.onChangeBccListValue(
                              event.target.value.trim()
                            );
                          }}
                          handleSelectedOption={(option) => {
                            this.onChangeBccListValue(option.email.trim());
                          }}
                        />
                      </FlexElement>
                      <FlexElement>
                        <AxoLocal
                          componentClass={ClientSearchBox}
                          maxWidth="200px"
                          clients={contactMap.contacts}
                          count={contactMap.count}
                          onlyEmails
                          handleSelectedClient={this.handleSelectedBCCContact}
                          clearOnSelect
                          componentAttribute="placeholder"
                          entity="composeSelectClientPlaceholder"
                        />
                      </FlexElement>
                      {labels.length > 0 && (
                        <FlexElement className="leftPadding">
                          <AxoLocal
                            componentClass={LabelSearchBox}
                            labels={activeLabels}
                            handleSelectedLabel={this.selectBCCGroup}
                            disabled={activeLabels.length === 0}
                            // inputBorder='1px solid #4CAF50'
                            clearOnSelect
                            componentAttribute="placeholder"
                            entity="ContactLabelPlaceholder"
                          />
                        </FlexElement>
                      )}
                    </Flexbox>
                  )}
                  {!!invalidBCCListMail ? (
                    <div className="axored">
                      <div>
                        <AxoLocal
                          entity="ComposeInvalidMailList"
                          defaultValue={
                            'Indtast en liste af mails adskilt med ";"'
                          }
                        />
                      </div>
                      <div>
                        {" "}
                        <AxoLocal
                          entity="axoidcode183"
                          defaultValue={"Denne mail er invalid:"}
                        />{" "}
                        {invalidBCCListMail}
                      </div>
                    </div>
                  ) : null}
                </Col>
              </FormGroup>

              <FormGroup controlId="email-cc">
                {/* MissingEntity */}
                <Col
                  componentClass={ControlLabel}
                  sm={2}
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  Mail CC{" "}
                </Col>
                <Col sm={10} style={{ textAlign: "left" }}>
                  {!!ccMailListStringFull &&
                  ccMailListStringFull.length > 150 ? (
                    <div className="standardMaxWidth">
                      <Flexbox alignCenter>
                        <FlexElement flexBasis="500px">
                          <FormControl
                            style={{
                              maxWidth: "500px",
                              display: "inline-block",
                            }}
                            componentClass="textarea"
                            rows={5}
                            value={this.state.ccMailListString}
                            onChange={this.onChangeCcList}
                          />
                        </FlexElement>
                        <FlexElement className="leftPadding">
                          <AxoLocal
                            componentClass={ClientSearchBox}
                            maxWidth="200px"
                            clients={contactMap.contacts}
                            count={contactMap.count}
                            onlyEmails
                            handleSelectedClient={this.handleSelectedCCContact}
                            clearOnSelect
                            componentAttribute="placeholder"
                            entity="composeSelectClientPlaceholder"
                          />
                        </FlexElement>
                      </Flexbox>
                      <Flexbox collapseXS>
                        <FormGroup
                          controlId="from"
                          style={{ margin: "0px" }}
                          className="rightPadding flexbox-standard"
                        >
                          <ControlLabel
                            style={{
                              textAlign: "left",
                              fontSize: "16px",
                              fontWeight: "normal",
                            }}
                          >
                            <AxoLocal
                              entity="axoidcode138"
                              defaultValue={"Fra"}
                            />
                            :&nbsp;&nbsp;&nbsp;
                          </ControlLabel>
                          <FormControl
                            type="number"
                            className="borderFormControlfocus"
                            value={
                              isNaN(receiverStartNumber)
                                ? ""
                                : receiverStartNumber
                            }
                            onChange={(event) =>
                              this.onUpdateReceiverStartNumber(
                                parseInt(event.target.value, 10)
                              )
                            }
                          />
                        </FormGroup>
                        <FormGroup
                          controlId="to"
                          style={{ margin: "0px" }}
                          className="flexbox-standard"
                        >
                          <ControlLabel
                            style={{
                              textAlign: "left",
                              fontSize: "16px",
                              fontWeight: "normal",
                            }}
                          >
                            <AxoLocal
                              entity="axoidcode139"
                              defaultValue={"Til"}
                            />
                            :&nbsp;&nbsp;&nbsp;
                          </ControlLabel>
                          <FormControl
                            type="number"
                            className="borderFormControlfocus"
                            value={
                              isNaN(receiverEndNumber) ? "" : receiverEndNumber
                            }
                            onChange={(event) =>
                              this.onUpdateReceiverEndNumber(
                                parseInt(event.target.value, 10)
                              )
                            }
                          />
                        </FormGroup>
                      </Flexbox>
                      <div className="axored">
                        {/* MissingEntity */}
                        Da du sender CC, vil alle modtagere kunne se hinandens
                        email adresser.
                      </div>
                      <div className="axored">
                        {/* MissingEntity */}
                        Anvend BCC feltet hvis dette ikke er ønskværdigt.
                      </div>
                      <div>
                        <LexButton onClick={this.resetCcList}>
                          Nulstil CC
                        </LexButton>
                      </div>
                    </div>
                  ) : (
                    <Flexbox>
                      <FlexElement flexBasis="515px" className="rightPadding">
                        <EmailAutoComplete
                          value={ccMailListString || ""}
                          emailSuggestions={emailSuggestions}
                          onChange={(event) => {
                            if (
                              event.target.value === undefined ||
                              !event.target.value.trim
                            ) {
                              return; //Happens when selecting from the dropdown
                            }
                            this.onChangeCcListValue(event.target.value.trim());
                          }}
                          handleSelectedOption={(option) => {
                            this.onChangeCcListValue(option.email.trim());
                          }}
                        />
                      </FlexElement>
                      <FlexElement>
                        <AxoLocal
                          componentClass={ClientSearchBox}
                          maxWidth="200px"
                          clients={contactMap.contacts}
                          count={contactMap.count}
                          onlyEmails
                          handleSelectedClient={this.handleSelectedCCContact}
                          clearOnSelect
                          componentAttribute="placeholder"
                          entity="composeSelectClientPlaceholder"
                        />
                      </FlexElement>
                      {labels.length > 0 && (
                        <FlexElement className="leftPadding">
                          <AxoLocal
                            componentClass={LabelSearchBox}
                            labels={activeLabels}
                            handleSelectedLabel={this.selectCCGroup}
                            disabled={activeLabels.length === 0}
                            // inputBorder='1px solid #4CAF50'
                            clearOnSelect
                            componentAttribute="placeholder"
                            entity="ContactLabelPlaceholder"
                          />
                        </FlexElement>
                      )}
                    </Flexbox>
                  )}
                  {!!invalidCCListMail ? (
                    <div className="axored">
                      <div>
                        <AxoLocal
                          entity="ComposeInvalidMailList"
                          defaultValue={
                            'Indtast en liste af mails adskilt med ";"'
                          }
                        />
                      </div>
                      <div>
                        {" "}
                        <AxoLocal
                          entity="axoidcode183"
                          defaultValue={"Denne mail er invalid:"}
                        />{" "}
                        {invalidCCListMail}
                      </div>
                    </div>
                  ) : null}
                </Col>
              </FormGroup>
            </>
          )}
      </div>
    );
  };

  onSaveDraft = async () => {
    let result = await this.onSave();
    if (!!result) {
      this.props.history.push(RoutingService.getPath("mailbox/inbox/"));
    }
    return false;
  };

  onAttachCase = (caseModel) => {
    this.setState((prevState) => ({
      messageState: {
        ...prevState.messageState,
        caseModelId: caseModel.id,
        caseModel,
      },
    }));
  };

  selectGroup = (label) => {
    let { userProfile } = this.props;

    ApiService.getSingleLabel(label.id).then((fullLabel) => {
      let emails = fullLabel.clients
        .filter((c) => !!c.eMail)
        .map((c) => c.eMail.trim().toLowerCase())
        .sort((c1, c2) => c1.localeCompare(c2));

      if (emails.length > 0) {
        let messageState = Object.assign({}, this.state.messageState);
        messageState.externalMailReceiver = userProfile.eMail;
        let bccMailListString = "";
        for (let i = 0; i < emails.length; i++) {
          bccMailListString += emails[i] + ";";
        }
        this.setState({
          messageState,
          bccMailListString,
          bccMailListStringFull: bccMailListString, //In case the string is filtered later
          receiverStartNumber: 1,
          receiverEndNumber: emails.length,
        });
      } else {
        this.setState({
          bccMailListString: "",
          bccMailListStringFull: "",
          receiverStartNumber: 1,
          receiverEndNumber: 10000,
        });
      }
    });
  };

  selectReceiverGroup = async (label) => {
    let emailResult = await this.getLabelEmailString(label.id);
    this.setState({
      receiverMailListString: emailResult.emailString,
      receiverMailListStringFull: emailResult.emailString,
      receiverStartNumber: 1,
      receiverEndNumber: emailResult.length || 1000,
    });
  };

  selectBCCGroup = async (label) => {
    let emailResult = await this.getLabelEmailString(label.id);
    this.setState({
      bccMailListString: emailResult.emailString,
      bccMailListStringFull: emailResult.emailString,
      receiverStartNumber: 1,
      receiverEndNumber: emailResult.length || 1000,
    });
  };

  selectCCGroup = async (label) => {
    let emailResult = await this.getLabelEmailString(label.id);
    this.setState({
      ccMailListString: emailResult.emailString,
      ccMailListStringFull: emailResult.emailString,
      receiverStartNumber: 1,
      receiverEndNumber: emailResult.length || 1000,
    });
  };

  getLabelEmailString = async (id) => {
    let { contactMap, userSettings } = this.props;

    let contacts = [];
    if (id === "All") {
      contacts = contactMap.contacts.filter(
        (c) =>
          !c.labels.find(
            (l) =>
              l.id === userSettings.formerMembersLabelId ||
              l.id === userSettings.waitingMembersLabelId
          )
      );
    } else {
      let fullLabel = await ApiService.getSingleLabel(id);
      contacts = fullLabel.clients;
    }

    let emailString = "";
    let emails = contacts
      .filter((c) => !!c.eMail)
      .map((c) => c.eMail.trim().toLowerCase())
      .sort((c1, c2) => c1.localeCompare(c2));

    if (emails.length > 0) {
      for (let i = 0; i < emails.length; i++) {
        emailString += emails[i] + ";";
      }
    }

    return { emailString, length: emails.length };
  };

  onUpdateReceiverStartNumber = (value) => {
    let { receiverEndNumber } = this.state;

    this.filterReceiverList(value, receiverEndNumber);
    this.filterCCList(value, receiverEndNumber);
    this.filterBCCList(value, receiverEndNumber);

    this.setState({ receiverStartNumber: value });
  };

  onUpdateReceiverEndNumber = (value) => {
    let { receiverStartNumber } = this.state;

    this.filterReceiverList(receiverStartNumber, value);
    this.filterCCList(receiverStartNumber, value);
    this.filterBCCList(receiverStartNumber, value);

    this.setState({ receiverEndNumber: value });
  };

  filterReceiverList = (start, end) => {
    let { receiverMailListStringFull } = this.state;

    if (!receiverMailListStringFull) {
      return;
    }

    this.setState({
      receiverMailListString: this.filterMailList(
        receiverMailListStringFull,
        start,
        end
      ),
    });
  };

  filterCCList = (start, end) => {
    let { ccMailListStringFull } = this.state;

    if (!ccMailListStringFull) {
      return;
    }

    this.setState({
      ccMailListString: this.filterMailList(ccMailListStringFull, start, end),
    });
  };

  filterBCCList = (start, end) => {
    let { bccMailListStringFull } = this.state;

    if (!bccMailListStringFull) {
      return;
    }

    this.setState({
      bccMailListString: this.filterMailList(bccMailListStringFull, start, end),
    });
  };

  filterMailList = (fullList, start, end) => {
    let mails = fullList.split(";");
    let newList = "";

    for (let i = start - 1; i < end && i < mails.length; i++) {
      if (!mails[i]) {
        break;
      }

      newList += mails[i] + ";";
    }

    return newList;
  };

  onFileDrop = (files) => {
    DataActions.uploadDocuments(files)
      .then((response) => {
        if (!response.ok) {
          return [];
        }
        return response.json();
      })
      .then((newFiles) => {
        if (newFiles.length === 0) {
          return;
        }

        let { messageState } = this.state;
        this.setState({
          messageState: {
            ...messageState,
            attachments: [...messageState.attachments, ...newFiles],
          },
        });
        this.inProgress = true;
      });
  };

  render() {
    let {
      userGroup,
      clientCompose,
      lawyerProfiles,
      clientProfiles,
      friends,
      userSettings,
      userProfile,
      caseMap,
      selectedEmailAccount,
    } = this.props;

    let { messageState } = this.state;

    let tinyMCE = (
      <SimpleTinyMCEEditor
        text={this.state.initialText}
        fontName={
          userProfile.userName === "GreveSejlklub" ? "Comic Sans MS" : ""
        }
        locale={userSettings.locale}
        onTextChange={this.onMessageChangeMCE}
        height={450}
        setupEditor={() => {}}
      />
    );

    //Clients can only contact their lawyers.
    //Lawyers can contact both friends and clients
    let allProfiles = clientCompose
      ? lawyerProfiles
      : friends.concat(clientProfiles);

    //Lawyers can also contact their user group
    let groupMembers =
      !!userGroup && userGroup.id
        ? userGroup.members.filter((m) => m.id !== userProfile.id)
        : [];
    allProfiles = allProfiles.concat(groupMembers);
    allProfiles = Uniqueomat.uniqueById(allProfiles);

    //Clients can assign messages to a case handled by one of their lawyers
    let cases = [];
    if (!!lawyerProfiles && lawyerProfiles.length > 0) {
      lawyerProfiles.forEach((lawyerProfile) => {
        cases = cases.concat(lawyerProfile.cases);
      });
    } else if (!!caseMap.saved) {
      cases = caseMap.saved;
    }

    return (
      <div className="mailControlmenu">
        <AxoDropzone onDrop={this.onFileDrop}>
          <Grid fluid>
            <Row>
              <Flexbox alignCenter className="leftPadding bottomPadding">
                <ButtonToolbar className="axoinboxtoolbar rightPadding">
                  <ButtonGroup>
                    <Button
                      style={{ width: "100px" }}
                      className="Lex-button-2"
                      onClick={this.onBackClick}
                    >
                      <Icon glyph="icon-fontello-left-bold-1" />
                    </Button>
                    {/* <Button className='Lex-button-2' onClick={this.onDiscardMail}><Icon glyph='icon-feather-cross'/></Button> */}
                    <Button
                      className="Lex-button-2"
                      onClick={this.onDiscardMail}
                    >
                      {" "}
                      <Icon glyph="icon-fontello-scissors axored"> </Icon>
                      <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                    </Button>
                    <AsyncButton
                      className="Lex-button-2"
                      onClick={this.onSaveDraft}
                    >
                      <Icon glyph="icon-fontello-floppy-1" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="currentMessageSavedraft"
                        defaultValue={"Gem udkast"}
                      />
                    </AsyncButton>
                    <Button
                      className="Lex-button-2"
                      disabled={!selectedEmailAccount}
                      onClick={this.onSendClicked}
                    >
                      {this.renderSendButtonContent()}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
                {cases.length > 0 ? (
                  <FlexElement className="topPadding">
                    <CaseSearchBox
                      maxWidth="500px"
                      cases={cases}
                      handleSelectedCase={this.onAttachCase}
                      placeholder="Tilknyt sag..."
                    />
                  </FlexElement>
                ) : null}
              </Flexbox>
            </Row>
            <Row className="axopaddingLeft" style={{ paddingLeft: "5px" }}>
              <Col xs={12} className="mailboxpadding">
                <Form horizontal autoComplete="on">
                  <FormGroup
                    controlId="email-to"
                    validationState={this.getReceiverValidationState()}
                  >
                    <Col
                      componentClass={ControlLabel}
                      sm={2}
                      style={{ textAlign: "left" }}
                    >
                      <AxoLocal
                        entity="axoidcode232"
                        defaultValue={"Intern modtager"}
                      />
                    </Col>
                    <Col sm={10} style={{ textAlign: "left" }}>
                      <AxoLocal
                        componentClass={UserSearchBox}
                        maxWidth="500px"
                        startValue={
                          !!messageState.receiver
                            ? UserInfoService.getDisplayName(
                                messageState.receiver
                              )
                            : ""
                        }
                        userProfiles={allProfiles}
                        handleSelectedUser={this.handleSelectedUser}
                        componentAttribute="placeholder"
                        entity={
                          this.props.clientCompose
                            ? "ComposeSelectLawyerPlaceholder"
                            : "ComposeSelectUserPlaceholder"
                        }
                      />
                      {/* { labels.length > 0 ? (
                      <AxoLocal componentClass={LabelSearchBox}
                        labels={labels} 
                        handleSelectedLabel={this.selectGroup}
                        disabled={labels.length === 0}
                        // inputBorder='1px solid #4CAF50'
                        clearOnSelect
                        componentAttribute="placeholder"
                        entity='ContactLabelPlaceholder'
                      />
                    ) : null } */}
                    </Col>
                    {/*{this.state.showWarning ? <Col xs={1} style={{ textAlign: 'left'}} componentClass={ControlLabel}>Eller</Col> : null}*/}
                  </FormGroup>

                  {this.renderAdvancedControls(allProfiles)}

                  <FormGroup controlId="subject">
                    <Col
                      componentClass={ControlLabel}
                      sm={2}
                      style={{ textAlign: "left" }}
                    >
                      <AxoLocal
                        entity="CaseEditFormcasesubject"
                        defaultValue={"Emne"}
                      />
                    </Col>
                    <Col
                      sm={10}
                      style={{ maxWidth: "550px", textAlign: "left" }}
                    >
                      <FormControl
                        style={{ maxWidth: "500px" }}
                        type="text"
                        value={this.state.messageState.subject}
                        onChange={this.onMessagePropertyChange.bind(
                          this,
                          "subject"
                        )}
                      />
                      {this.state.validationState.missingSubject ? (
                        <div className="axored">
                          <AxoLocal
                            entity="ComposeMissingSubject"
                            defaultValue={"Mailen skal have et emne"}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </FormGroup>
                  {/* <FormGroup>
                  <Col componentClass={ControlLabel} sm={2} style={{ textAlign: 'left'}}><AxoLocal entity='ComposAttachfiles'defaultValue={'Vedhæft filer'}/></Col>
                  <Col sm={10} style={{ textAlign: 'left'}}>
                    <DocumentSearchBox 
                      maxWidth='500px'
                      documentMap={this.props.documentMap}            
                      handleSelectedDocument={this.onAttachFile}
                      clearOnSelect
                    />
                  </Col>
                </FormGroup> */}
                  {/* { cases.length > 0 ? (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={2} style={{ textAlign: 'left'}}>
                      <AxoLocal entity='ComposeViewAddCase' defaultValue={'Tilknyt sag'}/>
                    </Col>
                    <Col sm={10}>
                      <CaseSearchBox 
                        maxWidth='500px'
                        cases={cases}
                        handleSelectedCase={this.onAttachCase}/>
                    </Col>
                  </FormGroup>
                ) : null } */}
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mailboxpadding">
                {this.renderAttachmentSection(this.state.messageState)}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mailboxpadding nopadding editorMaxWidth">
                {tinyMCE}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="text-center">
                <ButtonToolbar style={{ display: "inline-block" }}>
                  <ButtonGroup>
                    <Button
                      className="Lex-button-2"
                      onClick={this.onDiscardMail}
                    >
                      <Icon glyph="icon-fontello-scissors axored"> </Icon>{" "}
                      <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                    </Button>
                    <AsyncButton
                      className="Lex-button-2"
                      onClick={this.onSaveDraft}
                    >
                      <Icon glyph="icon-fontello-floppy-1" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="currentMessageSavedraft"
                        defaultValue={"Gem udkast"}
                      />
                    </AsyncButton>
                    <Button
                      className="Lex-button-2"
                      onClick={this.onSendClicked}
                    >
                      {this.renderSendButtonContent()}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </Row>
            <Row style={{ paddingLeft: "5px" }}>
              <Col xs={12} className="mailboxpadding">
                {this.renderAttachmentSection(this.state.messageState)}
                <FileViewerModal
                  size="large"
                  ref={(c) => (this.fileModal = c)}
                />
                <h4>
                  {" "}
                  <AxoLocal
                    entity="ComposAttachfiles"
                    defaultValue={"Vedhæft filer"}
                  />{" "}
                </h4>
                <ControlLabel>
                  {" "}
                  <AxoLocal
                    entity="ComposSelectexistingfile"
                    defaultValue={"Vælg eksisterende fil"}
                  />
                </ControlLabel>
                <DocumentSearchBox
                  documentMap={this.props.documentMap}
                  handleSelectedDocument={this.onAttachFile}
                  clearOnSelect
                />
                <ControlLabel>
                  {" "}
                  <Icon
                    style={{ color: "cblue" }}
                    glyph="icon-fontello-down-6"
                  />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="ComposUploadfilesfromPC"
                    defaultValue={"Upload filer fra PC"}
                  />
                </ControlLabel>
              </Col>
            </Row>
            {/* AxoDropzone */}
          </Grid>
        </AxoDropzone>
        <div className="mailboxpadding rightPadding">
          <Dropzonejs
            onFileUploaded={this.onAttachFile}
            onFileRemoved={this.onFileRemoved}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ComposeView);
