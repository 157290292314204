import React from "react";
import PropTypes from "prop-types";
import screenfull from "screenfull";

import { Button, ButtonGroup, DropdownButton } from "react-bootstrap";

import {
  // UserInfoService,
  // StringService,
  AuthorizationService,
} from "../services/AxoServices";

import {
  Icon,
  FlagMenu,
  AxoLocal,
  LexButton,
  Flexbox,
  FlexElement,
  AsyncButton,
} from "../utilities/LexUtilities";

import { SmallOrLarger, ExtraSmall } from "../utilities/Responsive";

export default class Header extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    dropdownContent: PropTypes.element.isRequired,
    openDropdown: PropTypes.bool.isRequired,
    onToggleDropdown: PropTypes.func.isRequired,
    userProfile: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    onGotoSocial: PropTypes.func.isRequired,
    onGoToHome: PropTypes.func.isRequired,
    onRelativeRoute: PropTypes.func.isRequired,
    // onToggleSidebar: PropTypes.func.isRequired,
    onLock: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      fullScreen: false,
    };
  }

  onFullScreenToggle = () => {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
    this.setState((prevState) => ({ fullScreen: !prevState.fullScreen })); //Trigger a rerender to update the full screen button
  };

  renderFullScreenButtonContent = () => {
    let { fullScreen } = this.state;

    if (!!fullScreen) {
      return (
        <Icon
          glyph="icon-fontello-resize-small-3"
          key="full"
          style={{ fontSize: "28px" }}
        />
      );
    }
    return (
      <Icon
        glyph="icon-fontello-resize-full-4"
        key="normal"
        style={{ fontSize: "28px" }}
      />
    );
  };

  render() {
    var {
      show,
      dropdownContent,
      openDropdown,
      onToggleDropdown,
      userProfile,
      userSettings,
      // onGotoSocial,
      onGoToHome,
      onLock,
      onLogout,
      onRelativeRoute,
      trashCount,
      // handleBackClick
    } = this.props;

    if (!show) {
      return null;
    }

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div className="noprint">
        <div className="flexbox-layout flex-start header-position Axolexheader Topheight">
          <div className="Topheight" style={{ textAlign: "left" }}>
            <ButtonGroup>
              <DropdownButton
                style={{ width: "100%" }}
                className="axolextopmenuButton Axolextopbtwidth Topheight hidden-lg hidden-md"
                open={openDropdown}
                onToggle={onToggleDropdown}
                title={
                  <span style={{ fontSize: "25px" }}>
                    <Icon
                      style={{ fontSize: "30px" }}
                      className="navminimenu"
                      glyph="icon-fontello-th-list-5"
                    />
                  </span>
                }
                id="menuDropdown1"
              >
                <div
                  style={{
                    overflowY: "scroll",
                    height: window.innerHeight - 110,
                  }}
                >
                  {dropdownContent}
                </div>
              </DropdownButton>
              {/* <Button className='axolextopmenuButton Axolextopbtwidth Topheight Topleftbutton hidden-xs hidden-sm' onClick={onGotoSocial}>
                  <img alt='' role='button' className='img-circle' src={UserInfoService.getMyProfileImageSource(userProfile)} 
                    style={{ maxHeight: '100%' }}
                  />
                </Button> */}
              <SmallOrLarger>
                <Button
                  className="Axolexheader Axolextopbtwidth Topheight Topleftbutton"
                  onClick={onGoToHome}
                >
                  <div>
                    <div>
                      <Icon
                        style={{ fontSize: "30px" }}
                        glyph="icon-fontello-home-1"
                      />
                    </div>
                    <div>
                      <AxoLocal
                        entity="sidebaravatarFrontpage"
                        defaultValue="Forside"
                      />
                    </div>
                  </div>
                </Button>
              </SmallOrLarger>
              {(userType === "Admin" || userProfile.accountingEnabled) && (
                <Button
                  className="Axolexheader Axolextopbtwidth Topheight Topleftbutton"
                  onClick={() => onRelativeRoute("MyAccounting")}
                >
                  <div>
                    <div>
                      <Icon
                        style={{ fontSize: "30px" }}
                        glyph="icon-fontello-chart-bar-1"
                      />
                    </div>
                    <div>
                      <SmallOrLarger>
                        {userType === "Society" ? (
                          <>Regnskab</>
                        ) : (
                          <>Mit Regnskab</>
                        )}
                      </SmallOrLarger>
                      <ExtraSmall>
                        <span style={{ fontSize: "12px" }}>Regnskab</span>
                      </ExtraSmall>
                    </div>
                  </div>
                </Button>
              )}
              {/* <Button  className='axolextopmenuButton Axolextopbtwidth Topheight Topleftbutton' 
                  onClick={handleBackClick}>
                    <div>
                      <div>
                      <Icon className="frontnavbarimg" glyph='icon-fontello-back'/>
                      </div>
                      <div>
                        <AxoLocal entity='backButton'  defaultValue={'Tilbage'}/>
                      </div>
                    </div>
                </Button> */}
              {/* {!!onToggleSidebar ? (
                  <MediumOrLarger>
                    <Button onClick={onToggleSidebar} className='axolextopmenuButton Axolextopbtwidth Topheight Topleftbutton '>
                      { hideSidebar ? (
                        <Icon style={{ fontSize: '30px'}} glyph='icon-fontello-left'/>

                      ) : (
                        <Icon style={{ fontSize: '30px'}} glyph='icon-fontello-right'/>
                      ) }
                    </Button>
                  </MediumOrLarger>
                ) : null } */}
              {/* { userProfile.logo || userProfile.logoLibrary ? (
                <Button className='axolextopmenuButton Axolextopbtwidth Topheight hidden-xs hidden-sm' onClick={onGoToHome}>
                      <img alt='' src={UserInfoService.getLogoImageSource(userProfile)}
                        style={{ maxHeight: '100%' }}
                      />
                </Button>
                      ) : null } */}
              {/* { userProfile.company ? (
                <Button className='axolextopmenuButton Topheight hidden-xs hidden-sm' style={{ maxWidth:'500px',fontSize:'21px'}} onClick={onGoToHome}>
                      {StringService.enforceMaxLength(userProfile.company, 35)}
                </Button>
                      ) : null } */}
            </ButtonGroup>
          </div>
          <div
            className="Topheight flexbox-center headerbuttonDropdown buttonDropdown"
            style={{ textAlign: "right", paddingRight: "150px" }}
          >
            <DropdownButton
              id="menuDropdown"
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;&nbsp;
                    {/* <AxoLocal style={{fontSize: '20px'}} entity='Enhedspris33' defaultValue={'Menu'}/> */}
                  </div>
                </div>
              }
            >
              <div className="dropDownExpand">
                <FlagMenu
                  className="axolextopmenuButton noboder Axolextopbtwidth Topheight"
                  userSettings={userSettings}
                  hideLanguage={userType === "Society" ? "ch" : ""}
                />
              </div>
              {userType === "Accountant" || userType === "Admin" ? (
                <div>
                  <LexButton
                    className="axolextopmenuButton"
                    onClick={() => onRelativeRoute("AccountPlans")}
                    style={{ height: "45px" }}
                  >
                    <Flexbox alignCenter>
                      <FlexElement
                        flex={1}
                        className="text-center rightPadding"
                      >
                        <Icon
                          className="frontnavbarimg"
                          glyph="icon-fontello-list-numbered"
                        />
                      </FlexElement>
                      <FlexElement flex={3} className="text-left">
                        <AxoLocal
                          entity="axoidcode153"
                          defaultValue={"Kontoplaner"}
                        />
                      </FlexElement>
                    </Flexbox>
                  </LexButton>
                </div>
              ) : null}
              <div>
                <LexButton
                  className="axolextopmenuButton"
                  onClick={() => onRelativeRoute("Setup")}
                  style={{ height: "45px" }}
                >
                  <Flexbox alignCenter>
                    <FlexElement flex={1} className="text-center rightPadding">
                      <Icon
                        className="frontnavbarimg"
                        glyph="icon-fontello-cog-7"
                      />
                    </FlexElement>
                    <FlexElement flex={3} className="text-left">
                      <AxoLocal
                        entity="ClientSetupViewSetup"
                        defaultValue={"Setup"}
                      />
                    </FlexElement>
                  </Flexbox>
                </LexButton>
              </div>
              <div>
                <LexButton
                  className="axolextopmenuButton"
                  onClick={() => onRelativeRoute("Trash")}
                  style={{ height: "45px" }}
                >
                  <Flexbox alignCenter>
                    <FlexElement flex={1} className="text-center rightPadding">
                      <Icon
                        className="frontnavbarimg"
                        glyph="icon-fontello-trash-1"
                      />
                      {trashCount > 0 && <> ({trashCount})</>}
                    </FlexElement>
                    <FlexElement flex={3} className="text-left">
                      <AxoLocal
                        entity="trashMenuNav"
                        defaultValue={"Skraldespand"}
                      />
                    </FlexElement>
                  </Flexbox>
                </LexButton>
              </div>
              <div>
                <LexButton
                  className="axolextopmenuButton hidden-xs hidden-sm"
                  style={{ height: "45px" }}
                  onClick={this.onFullScreenToggle}
                >
                  {this.renderFullScreenButtonContent()}
                </LexButton>
              </div>
              <div>
                <LexButton
                  className="axolextopmenuButton"
                  onClick={onLock}
                  style={{ height: "45px" }}
                >
                  <Icon
                    glyph="icon-fontello-lock-1"
                    style={{ fontSize: "28px" }}
                  />
                </LexButton>
              </div>
              <div>
                <AsyncButton
                  className="axolextopmenuButton"
                  onClick={onLogout}
                  style={{ height: "45px" }}
                >
                  <Icon
                    glyph="icon-fontello-logout-3"
                    style={{ fontSize: "28px" }}
                  />
                </AsyncButton>
              </div>
            </DropdownButton>
            {/* <ButtonGroup>
              <FlagMenu className='axolextopmenuButton noboder Axolextopbtwidth Topheight'
                userSettings={userSettings}
                hideLanguage={userType === 'Society' ? 'ch' : ''}
              />
              <Button className='axolextopmenuButton hidden-xs hidden-sm Axolextopbtwidth1 Topheight' 
              onClick={this.onFullScreenToggle}> {this.renderFullScreenButtonContent()}
              </Button>
              <Button className='axolextopmenuButton Axolextopbtwidth1 Topheight' onClick={onLock}>
                <Icon glyph='icon-fontello-lock-1' style={{ fontSize: '38px'}} />
              </Button>
              <Button className='axolextopmenuButton Axolextopbtwidth1 Topheight' onClick={onLogout}>
                <Icon glyph='icon-fontello-logout-3' style={{ fontSize: '28px'}}  />
              </Button>
            </ButtonGroup> */}
          </div>
        </div>
        <div className="Topheight"></div>
      </div>
    );
  }
}
