import React from "react";
import BetalingA from "../resources/images/BetalingA.png";
import BetalingB from "../resources/images/BetalingB.png";
import BetalingC from "../resources/images/BetalingC.png";

import TilmeldA from "../resources/images/TilmeldA.png";
import TilmeldB from "../resources/images/TilmeldB.png";
import TilmeldC from "../resources/images/TilmeldC.png";

const SocietyPaymentDemoView = () => {
  return (
    <div
      className="standardMaxWidth center-block"
      style={{ paddingTop: "15px", paddingBottom: "15px", margin: "auto" }}
    >
      <h2>Betalingsflow for Foreningsbrugere</h2>
      <div className="bottomPadding">
        Axolex ApS udvikler et betalingssystem til foreninger.
        Abonnementsbetaling sker ved at medlemmerne får tilsendt en mail fra
        foreningens mail adresse, med et link til en tilmeldingsside hvor
        medlemmet kan tilmelde sig automatisk opkrævning. Hvert medlem får
        tilsendt sit eget unikke tilmeldingslink.
      </div>
      <div className="bottomPadding">
        <img src={TilmeldA} alt="Betaling" width="100%" />
      </div>
      <div className="bottomPadding">
        For at tilmelde sig abonnementet, skal medlemmet først acceptere
        Privatlivspolitik og Handelsbetingelser. Herefter aktiveres
        tilmeldingsknappen. Ved tryk på tilmeldingsknappen omdirigeres medlemmet
        til en Scanpay formular hvor kortdata kan indtastes og gemmes sikkert.
        Foreningen får ikke adgang til medlemmernes kortdata, som gemmes hos
        Scanpay.
      </div>
      <div className="bottomPadding">
        <img src={TilmeldB} alt="Betaling" width="50%" />
      </div>
      <div className="bottomPadding">
        Efter at medlemmet har indtastet og godkendt kortdata, dirigeres
        medlemmet tilbage til tilmeldingssiden, som nu indeholder knapper til at
        opdatere kortoplysninger og afbryde abonnementet.
      </div>
      <div className="bottomPadding">
        Medlemmet kan fremover anvende dette link til at opdatere deres
        abonnementsoplysninger.
      </div>
      <div className="bottomPadding">
        <img src={TilmeldC} alt="Betaling" width="100%" />
      </div>
      <div className="bottomPadding">
        Såfremt det bliver nødvendigt at opkræve individuel betaling fra et
        medlem, vil der blive udsendt en mail med et link til en opkrævning.
      </div>
      <div className="bottomPadding">
        <img src={BetalingA} alt="Betaling" width="100%" />
      </div>
      <div className="bottomPadding">
        For at aktivere betalingsknappen skal medlemmet klikke på
        Privatlivspolitik og Handelsbetingelser og sætte flueben i boksen for at
        acceptere disse:
      </div>
      <div className="bottomPadding">
        Ved tryk på betalingsknappen omdirigeres medlemmet til en Scanpay
        betalingsformular hvor kortoplysninger kan indtastes sikkert.
      </div>
      <div className="bottomPadding">
        <img src={BetalingB} alt="Betaling" width="50%" />
      </div>
      <div className="bottomPadding">
        Når betalingen er gennemført, bliver medlemmet dirigeret tilbage til
        betalingssiden, hvor der nu er mulighed for at udskrive en kvittering
        for betalingen.
      </div>
      <div className="bottomPadding">
        <img src={BetalingC} alt="Betaling" width="100%" />
      </div>
    </div>
  );
};

export default SocietyPaymentDemoView;
