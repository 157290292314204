import React, { useEffect } from "react";

import { DataStore } from "../../services/AxoServices";

import {
  withData,
  withRouter,
  LoadingIndicator,
} from "../../utilities/LexUtilities";

import { useParams } from "react-router-dom";

import SigningRequestView from "./SigningRequestView";

import QueryString from "query-string";

const SigningRequestContainer = function (props) {
  useEffect(() => {
    DataStore.initializeDocuments();
    DataStore.fetchUserSubscription();
  }, []);

  let { id } = useParams();
  let { showSending } = QueryString.parse(props.location.search);

  let file = props.documents.find((f) => f.id.toString() === id);

  if (!file) {
    return <LoadingIndicator />;
  }

  let sub = props.userSubscription || {};
  let requirePayment = sub.purchasedUnits >= sub.freeUnitsPerInterval;
  let card = sub.card;
  let showCardWarning = requirePayment && !card;
  let showSubscriptionWarning = sub.status !== "Active";

  return (
    <SigningRequestView
      key={file.id}
      file={file}
      showSending={showSending.toLowerCase() === "true"}
      showCardWarning={showCardWarning}
      showSubscriptionWarning={showSubscriptionWarning}
      {...props}
    />
  );
};

const mapStoreToProps = (store) => {
  return {
    documents: store.documentMap.documents,
    userSubscription: store.userSubscription,
  };
};

export default withRouter(
  withData(React.memo(SigningRequestContainer), mapStoreToProps)
);
