import React from "react";
import RoutingService from "../../services/RoutingService";
import DataActions from "../../services/DataAccess/DataActions";
import DataStore from "../../services/DataAccess/DataStore";
import MessageService from "../../services/MessageService";
import TimeEntriesView from "./TimeEntriesView";
import InvoicesView from "./InvoicesView";
import InvoicePDFsView from "./InvoicePDFsView";
import PaymentConfigurationView from "./PaymentConfigurationView";
import TimeEntryFormContainer from "./TimeEntryFormContainer";
import Conditional from "../../utilities/Conditional";
import LexNavButton from "../../utilities/LexNavButton";
import MultiPaymentView from "./MultiPaymentView";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import { withRouter } from "react-router-dom";
import SimpleInvoiceForm from "./SimpleInvoiceForm";

import { AuthorizationService } from "../../services/AxoServices";

import { AxoLocal, Icon } from "../../utilities/LexUtilities";

import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";
import SimpleSubscriptionForm from "./SimpleSubscriptionForm";

class TimeEntriesTabView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      smallScreenTabsExpanded: false,
      newInvoice: {},
      newTimeEntryIds: [],
      newPDF: {},
      selectedClientId: -1,
      selectedCaseId: -1,
    };
  }

  handleActiveState = (eventKey) => {
    this.props.handleNavigation(eventKey);
  };

  onCreateInvoice = (newInvoice) => {
    this.setState({
      newInvoice: newInvoice,
      selectedClientId: newInvoice.client ? newInvoice.client.id : 0,
    });
    this.handleActiveState("Invoices");
  };

  onCreateInvoicePDF = (newPDF) => {
    this.setState({
      newPDF: newPDF,
      selectedClientId: newPDF.invoice.client ? newPDF.invoice.client.id : 0,
    });
    this.handleActiveState("PDFs");
  };

  onCreateAndSendInvoicePDF = (newPDF) => {
    DataStore.setCachedMessage(MessageService.makeInvoiceMessage(newPDF));
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  onSelectTimeEntryClient = (optionId) => {
    this.handleActiveState("Clients");
    this.setState({ selectedClientId: optionId });
  };

  onSelectTimeEntryCase = (optionId) => {
    this.handleActiveState("Cases");
    this.setState({ selectedCaseId: optionId });
  };

  onSelectInvoiceClient = (optionId) => {
    this.handleActiveState("Invoices");
    this.setState({ selectedClientId: optionId });
  };

  onSelectPDFClient = (optionId) => {
    this.handleActiveState("PDFs");
    this.setState({ selectedClientId: optionId });
  };

  onTimeEntriesAdded = (newTimeEntries) => {
    if (newTimeEntries.length === 0) {
      return;
    }
    var selectedClientId = this.state.selectedClientId;
    if (newTimeEntries[0].clientId) {
      selectedClientId = newTimeEntries[0].clientId;
    }
    this.setState({
      newTimeEntryIds: newTimeEntries.map((entry) => entry.id),
      selectedClientId: selectedClientId,
    });
    setTimeout(() => {
      this.setState({ newTimeEntryIds: [] });
    }, 10000);
    this.handleActiveState("Clients");
  };

  onToggleInvoiceCards = () => {
    var userSettings = Object.assign({}, this.props.userSettings);
    userSettings.showInvoiceCards = !userSettings.showInvoiceCards;
    DataActions.updateUserSettings(userSettings);
  };

  onToggleTimeEntryCards = () => {
    var userSettings = Object.assign({}, this.props.userSettings);
    userSettings.showTimeEntryCards = !userSettings.showTimeEntryCards;
    DataActions.updateUserSettings(userSettings);
  };

  onUpdateUserSettingsProperty = (propertyName, value) => {
    DataActions.updateUserSettings({
      ...this.props.userSettings,
      [propertyName]: value,
    });
  };

  showAdvancedControls = () => {
    let { userProfile, adminCodeValidated } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    return userType !== "Society" || adminCodeValidated;
  };

  renderLargeScreenNavigationBar = () => {
    let {
      userProfile,
      showDropdowns,
      activeTab,
      userSettings,
      // adminCodeValidated
    } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    let showAdvancedControls = this.showAdvancedControls();

    let showSubscriptions =
      userType === "Admin" || userProfile.subscriptionPaymentEnabled;

    return (
      <Large>
        <Grid fluid className="axo-nav-lg" id="noprint">
          {!showDropdowns ? (
            <Row>
              <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{
                    borderRadius: "0px",
                    paddingTop: "0px",
                    paddingLeft: "2px",
                  }}
                >
                  {userSettings.paymentApiKey &&
                    userProfile.singlePaymentEnabled && (
                      <LexNavButton
                        className="axonavTab axonavTabcolora"
                        ls
                        glyph="icon-fontello-arrows-cw"
                        eventKey="Collection"
                        activeTab={activeTab}
                        onClick={this.handleActiveState}
                      >
                        <AxoLocal
                          entity="axoidcode165"
                          defaultValue="Opkrævning"
                        />
                      </LexNavButton>
                    )}
                  {userProfile.singlePaymentEnabled && (
                    <LexNavButton
                      className="axonavTab axonavTabcolorb Addicon"
                      ls
                      glyph="icon-fontello-plus-3"
                      eventKey="Create"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal entity="ADDViewCreate" defaultValue={"Opret"} />
                    </LexNavButton>
                  )}
                  {userSettings.paymentApiKey && showSubscriptions && (
                    <LexNavButton
                      className="axonavTab axonavTabcolorc"
                      ls
                      glyph="icon-fontello-arrows-cw"
                      eventKey="Subscriptions"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="AdminTabView2"
                        defaultValue="Abonnementer"
                      />
                    </LexNavButton>
                  )}
                  {showSubscriptions && (
                    <LexNavButton
                      className="axonavTab axonavTabcolord Addicon"
                      ls
                      glyph="icon-fontello-plus-3"
                      eventKey="AddSubscription"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="axoEntityidcode64"
                        defaultValue={"Abonnementstilmelding"}
                      />
                    </LexNavButton>
                  )}
                  {showAdvancedControls ? (
                    <React.Fragment>
                      <LexNavButton
                        className="axonavTab axonavTabcolore"
                        ls
                        glyph="icon-fontello-hourglass"
                        eventKey="TimeEntryForm"
                        activeTab={activeTab}
                        onClick={this.handleActiveState}
                      >
                        <AxoLocal
                          entity="TimeEntriesTabViewTimeRecording"
                          defaultValue={"Tidsregistrering"}
                        />
                      </LexNavButton>
                      <LexNavButton
                        className="axonavTab axonavTabcolorf"
                        ls
                        glyph="icon-simple-line-icons-users"
                        eventKey="Clients"
                        activeTab={activeTab}
                        onClick={this.handleActiveState}
                      >
                        <AxoLocal
                          entity="TimeEntriesTabViewTimeregistrationsforclients"
                          defaultValue={"Tidsregistreringer for klienter"}
                        />
                      </LexNavButton>
                      {userType !== "Accountant" ? (
                        <LexNavButton
                          className="axonavTab axonavTabcolorg"
                          ls
                          glyph="icon-simple-line-icons-briefcase"
                          eventKey="Cases"
                          activeTab={activeTab}
                          onClick={this.handleActiveState}
                        >
                          <AxoLocal
                            entity="TimeEntriesTabViewTimeregistrationsforcases"
                            defaultValue={"Tidsregistreringer for sager"}
                          />
                        </LexNavButton>
                      ) : null}
                      <LexNavButton
                        className="axonavTab axonavTabcolorh"
                        ls
                        glyph="icon-fontello-list-bullet"
                        eventKey="Invoices"
                        activeTab={activeTab}
                        onClick={this.handleActiveState}
                      >
                        <AxoLocal
                          entity="CaseEditInlineStatusSelect"
                          defaultValue={"Klienters fakturaer"}
                        />
                      </LexNavButton>
                      <LexNavButton
                        className="axonavTab axonavTabcolori"
                        ls
                        glyph="icon-fontello-acrobat"
                        eventKey="PDFs"
                        activeTab={activeTab}
                        onClick={this.handleActiveState}
                      >
                        <AxoLocal
                          entity="TimeEntriesTabViewPDFinvoices"
                          defaultValue={"Klienters fakturaer"}
                        />
                      </LexNavButton>
                    </React.Fragment>
                  ) : null}
                  <LexNavButton
                    className="axonavTab axonavTabcolorj"
                    ls
                    glyph="icon-fontello-cog-7"
                    eventKey="Setup"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="ClientSetupViewSetup"
                      defaultValue={"Opsætning"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    ls
                    listButton
                    className="axonavTab showListButton"
                    onClick={() => {
                      DataStore.setShowDropDowns(true);
                    }}
                  >
                    <div className="flexbox-center">
                      <Icon
                        glyph="icon-fontello-align-justify"
                        style={{ fontSize: "30px" }}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity66"
                        defaultValue={"Liste Menu"}
                      />
                    </div>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                {!!userSettings.paymentApiKey ? (
                  <MenuItem
                    style={{ paddingBottom: "10px" }}
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Collection")}
                  >
                    <AxoLocal entity="axoidcode165" defaultValue="Opkrævning" />
                  </MenuItem>
                ) : null}
                {userType === "Society" ? (
                  <MenuItem
                    style={{ paddingBottom: "10px" }}
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Create")}
                  >
                    <AxoLocal entity="ADDViewCreate" defaultValue={"Opret"} />
                  </MenuItem>
                ) : null}
                {showAdvancedControls ? (
                  <MenuItem
                    style={{ paddingBottom: "10px" }}
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("TimeEntryForm")}
                  >
                    <span className="icon-fontello-hourglass" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="TimeEntriesTabViewTimeRecording"
                      defaultValue={"Tidsregistrering"}
                    />
                  </MenuItem>
                ) : null}
                {showAdvancedControls ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Clients")}
                  >
                    <span className="icon-simple-line-icons-users" />{" "}
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="TimeEntriesTabViewTimeregistrationsforclients"
                      defaultValue={"Tidsregistreringer for klienter"}
                    />
                  </MenuItem>
                ) : null}
                {showAdvancedControls && userType !== "Accountant" ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Cases")}
                  >
                    <span className="icon-simple-line-icons-briefcase" />{" "}
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="TimeEntriesTabViewTimeregistrationsforcases"
                      defaultValue={"Tidsregistreringer for sager"}
                    />
                  </MenuItem>
                ) : null}
                {showAdvancedControls ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Invoices")}
                  >
                    <span className="icon-fontello-list-bullet" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditInlineStatusSelect"
                      defaultValue={"Klienters fakturaer"}
                    />
                  </MenuItem>
                ) : null}
                {showAdvancedControls ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("PDFs")}
                  >
                    <span className="icon-fontello-acrobat" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="TimeEntriesTabViewPDFinvoices"
                      defaultValue={"Klienters fakturaer"}
                    />
                  </MenuItem>
                ) : null}
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Setup")}
                >
                  <span className="icon-fontello-acrobat" /> &nbsp;&nbsp;{" "}
                  <AxoLocal
                    entity="ClientSetupViewSetup"
                    defaultValue={"Opsætning"}
                  />
                </MenuItem>
              </DropdownButton>
            </Row>
          )}
        </Grid>
      </Large>
    );
  };

  expandTabs = () => {
    this.setState({ smallScreenTabsExpanded: true });
  };

  renderSmallScreenNavigationBar = () => {
    let { userSettings, userProfile } = this.props;

    let showAdvancedControls = this.showAdvancedControls();

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <MediumOrSmaller>
        <Grid fluid className="axo-nav" id="noprint">
          <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              {!!userSettings.paymentApiKey ? (
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Collection")}
                >
                  <AxoLocal entity="axoidcode165" defaultValue="Opkrævning" />
                </MenuItem>
              ) : null}
              {userType === "Society" ? (
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Create")}
                >
                  <AxoLocal entity="ADDViewCreate" defaultValue={"Opret"} />
                </MenuItem>
              ) : null}
              {showAdvancedControls ? (
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("TimeEntryForm")}
                >
                  <span className="icon-fontello-hourglass" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="TimeEntriesTabViewTimeRecording"
                    defaultValue={"Tidsregistrering"}
                  />
                </MenuItem>
              ) : null}
              {showAdvancedControls ? (
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Clients")}
                >
                  <span className="icon-simple-line-icons-users" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="TimeEntriesTabViewTimeregistrationsforclients"
                    defaultValue={"Tidsregistreringer for klienter"}
                  />
                </MenuItem>
              ) : null}
              {showAdvancedControls && userType !== "Accountant" ? (
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Cases")}
                >
                  <span className="icon-simple-line-icons-briefcase" />{" "}
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="TimeEntriesTabViewTimeregistrationsforcases"
                    defaultValue={"Tidsregistreringer for sager"}
                  />
                </MenuItem>
              ) : null}
              {showAdvancedControls ? (
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Invoices")}
                >
                  <span className="icon-fontello-list-bullet" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditInlineStatusSelect"
                    defaultValue={"Klienters fakturaer"}
                  />
                </MenuItem>
              ) : null}
              {showAdvancedControls ? (
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("PDFs")}
                >
                  <span className="icon-fontello-acrobat" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="TimeEntriesTabViewPDFinvoices"
                    defaultValue={"Klienters fakturaer"}
                  />
                </MenuItem>
              ) : null}
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("Setup")}
              >
                <span className="icon-fontello-acrobat" /> &nbsp;&nbsp;{" "}
                <AxoLocal
                  entity="ClientSetupViewSetup"
                  defaultValue={"Opsætning"}
                />
              </MenuItem>
            </DropdownButton>
          </Row>
        </Grid>
      </MediumOrSmaller>
    );
  };

  renderNavigationBar = () => {
    return (
      <div className="Tabbanne">
        {this.renderLargeScreenNavigationBar()}
        {this.renderSmallScreenNavigationBar()}
      </div>
    );
  };

  sendMailToList = (list) => {
    DataStore.setBCCList(list);

    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  getIsRegistration = (invoice) => {
    return invoice.isRegistration;

    // if(!invoice.timeEntries || invoice.timeEntries.length === 0) {
    //   return true;
    // }

    // return invoice.timeEntries[0].rate === 0.00;
  };

  renderContent = () => {
    let {
      invoices,
      userProfile,
      activeTab,
      createSimpleInvoice,
      initializedInvoices,
      adminCodeValidated,
    } = this.props;

    return (
      <div>
        {activeTab === "Collection" ? (
          <div className="largeMaxWidth">
            <MultiPaymentView
              contacts={this.props.contactMap.contacts}
              invoices={invoices.filter((i) => !this.getIsRegistration(i))}
              initializedInvoices={initializedInvoices}
              clientLabels={this.props.clientLabels}
              userProfile={userProfile}
              sendMailToList={this.sendMailToList}
              adminCodeValidated={adminCodeValidated}
            />
          </div>
        ) : null}
        {activeTab === "Create" && (
          <div className="standardMaxWidth">
            <SimpleInvoiceForm
              userProfile={userProfile}
              createSimpleInvoice={createSimpleInvoice}
            />
          </div>
        )}
        {activeTab === "Subscriptions" && (
          <div style={{ maxWidth: "1400px" }}>
            <MultiPaymentView
              isSubscription
              contacts={this.props.contactMap.contacts}
              invoices={invoices.filter((i) => this.getIsRegistration(i))}
              initializedInvoices={initializedInvoices}
              clientLabels={this.props.clientLabels}
              userProfile={userProfile}
              sendMailToList={this.sendMailToList}
              adminCodeValidated={adminCodeValidated}
            />
          </div>
        )}
        {activeTab === "AddSubscription" && (
          <div className="largeMaxWidth">
            <SimpleSubscriptionForm
              userProfile={userProfile}
              createSimpleInvoice={createSimpleInvoice}
            />
          </div>
        )}
        <Conditional show={activeTab === "TimeEntryForm"}>
          <div style={{ paddingBottom: "35px" }}>
            <TimeEntryFormContainer
              clients={this.props.allClients}
              cases={this.props.allCases}
              documentMap={this.props.documentMap}
              userProfile={userProfile}
              hideAccordion={true}
              useBoxForm={true}
              onTimeEntriesAdded={this.onTimeEntriesAdded}
            />
          </div>
        </Conditional>
        <Conditional show={activeTab === "Clients"}>
          <TimeEntriesView
            timeEntries={this.props.timeEntries}
            timeEntryMap={this.props.timeEntryMap}
            clientsWithEntries={this.props.clientsWithEntries}
            casesWithEntries={this.props.casesWithEntries}
            contactMap={this.props.contactMap}
            userProfile={userProfile}
            showCases={false}
            onCreateInvoice={this.onCreateInvoice}
            selectedOptionId={this.state.selectedClientId}
            onSelectOption={this.onSelectTimeEntryClient}
            newTimeEntryIds={this.state.newTimeEntryIds}
            forceShowCards={this.props.userSettings.showTimeEntryCards}
            onToggleCards={this.onToggleTimeEntryCards}
          />
        </Conditional>
        <Conditional show={activeTab === "Cases"}>
          <TimeEntriesView
            timeEntries={this.props.timeEntries}
            timeEntryMap={this.props.timeEntryMap}
            casesWithEntries={this.props.casesWithEntries}
            contactMap={this.props.contactMap}
            userProfile={userProfile}
            showCases={true}
            onCreateInvoice={this.onCreateInvoice}
            selectedOptionId={this.state.selectedCaseId}
            onSelectOption={this.onSelectTimeEntryCase}
            newTimeEntryIds={this.state.newTimeEntryIds}
            forceShowCards={this.props.userSettings.showTimeEntryCards}
            onToggleCards={this.onToggleTimeEntryCards}
          />
        </Conditional>
        <Conditional show={activeTab === "Invoices"}>
          <InvoicesView
            invoices={invoices}
            userProfile={userProfile}
            userSettings={this.props.userSettings}
            clientsWithInvoices={this.props.clientsWithInvoices}
            contactMap={this.props.contactMap}
            newInvoice={this.state.newInvoice}
            onCreatePDF={this.onCreateInvoicePDF}
            onCreateAndSendPDF={this.onCreateAndSendInvoicePDF}
            selectedOptionId={this.state.selectedClientId}
            onSelectOption={this.onSelectInvoiceClient}
            forceShowCards={this.props.userSettings.showInvoiceCards}
            onToggleCards={this.onToggleInvoiceCards}
            clientLabels={this.props.clientLabels}
          />
        </Conditional>
        <Conditional show={activeTab === "PDFs"}>
          <InvoicePDFsView
            PDFs={this.props.PDFs}
            documentMap={this.props.documentMap}
            newPDF={this.state.newPDF}
            friends={this.props.friends}
            clientProfiles={this.props.clientProfiles}
            clientsWithPDFs={this.props.clientsWithPDFs}
            allClients={this.props.allClients}
            selectedOptionId={this.state.selectedClientId}
            onSelectOption={this.onSelectPDFClient}
          />
        </Conditional>
        {activeTab === "Setup" && (
          <PaymentConfigurationView
            userSettings={this.props.userSettings}
            showSinglePayment={userProfile.singlePaymentEnabled}
            showSubscriptionTerms={userProfile.subscriptionPaymentEnabled}
            onUpdateProperty={this.onUpdateUserSettingsProperty}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} className="nopadding">
            {this.renderNavigationBar()}
            {this.renderContent()}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(TimeEntriesTabView);
