import React from "react";
import AccountingTabView from "./AccountingOld/AccountingTabView";
import DataStore from "../services/DataAccess/DataStore";
import AccountingStore from "../services/AccountingStore";

export default class AccountingContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      accountTypes: [],
      accounts: [],
      postings: [],
      contactMap: {
        contacts: [],
        count: 0,
        trashed: [],
        trashedCount: 0,
      },
      selectedClient: {},
    };
    this.name = "AccountingContainer";
  }

  componentDidMount() {
    AccountingStore.subscribeToAccountTypes(this.name, (accountTypes) => {
      this.setState({ accountTypes });
    });
    AccountingStore.subscribeToAccounts(this.name, (accounts) => {
      this.setState({ accounts });
    });
    AccountingStore.subscribeToPostings(this.name, (postings) => {
      this.setState({ postings });
    });
    DataStore.subscribe(this.name, (store) => {
      this.setState({
        showDropdowns: store.showDropdowns,
      });
    });
    this.setState({
      selectedClient: AccountingStore.getSelectedClient(),
    });

    AccountingStore.fetchAccountTypes();
    AccountingStore.fetchAccounts();
    AccountingStore.fetchPostings();
  }

  componentWillUnmount() {
    AccountingStore.unsubscribeFromAccountTypes(this.name);
    AccountingStore.unsubscribeFromAccounts(this.name);
    AccountingStore.unsubscribeFromPostings(this.name);
    DataStore.unsubscribe(this.name);
  }

  handleSelectedClient = (client) => {
    AccountingStore.selectClient(client);
    this.setState({ selectedClient: client });
  };

  render() {
    return (
      <AccountingTabView
        {...this.state}
        handleSelectedClient={this.handleSelectedClient}
      />
    );
  }
}
