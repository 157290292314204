import React from "react";
import { Col, Panel, PanelGroup } from "react-bootstrap";

import {
  TimeEntryOptionsStore,
  StringService,
} from "../../services/AxoServices";

import {
  Icon,
  AxoListGroup,
  AxoListItem,
  AxoLocal,
} from "../../utilities/LexUtilities";

class OptionsBox extends React.PureComponent {
  renderAccordionHeader = (title) => {
    return (
      <div>
        <Col xs={11} style={{ padding: "0px" }}>
          <span>{title}</span>
        </Col>
        <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
          <span style={{ textAlign: "right" }}>
            <Icon glyph="icon-fontello-arrow-combo" />
          </span>
        </Col>
        <div style={{ marginBottom: "2px" }}></div>
      </div>
    );
  };

  getOptionsTitle = () => {
    if (this.props.showCases) {
      return (
        <span>
          <Icon
            glyph="icon-simple-line-icons-briefcase"
            style={{ textAlign: "left" }}
          />
          &nbsp;&nbsp;
          <AxoLocal
            key="cases"
            entity="TimeEntriesSidebarContainerCase"
            defaultValue={"Sager"}
          />
        </span>
      );
    }
    return (
      <span>
        <Icon
          glyph="icon-simple-line-icons-users"
          style={{ textAlign: "left" }}
        />
        &nbsp;&nbsp;
        <AxoLocal
          key="clients"
          entity="clientMenuNav"
          defaultValue={"Klienter"}
        />
      </span>
    );
  };

  getCaseOptions = () => {
    var sortedCases = this.props.casesWithEntries.sort((l, r) => {
      return l.name > r.name;
    });
    var allItem = [
      <AxoListItem
        style={{ paddingLeft: "10px" }}
        className=""
        key={-1}
        active={-1 === this.props.selectedOptionId}
        onClick={this.props.onSelectOption.bind(this, -1)}
      >
        <span>
          <Icon glyph="icon-simple-line-icons-briefcase" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="TimeEntriesSideBarAllOption"
            defaultValue="Klienter/Sager"
          />
        </span>
      </AxoListItem>,
    ];
    var variousItem = [
      <AxoListItem
        style={{ paddingLeft: "10px" }}
        className=""
        key={0}
        active={0 === this.props.selectedOptionId}
        onClick={this.props.onSelectOption.bind(this, 0)}
      >
        <span>
          <Icon glyph="icon-simple-line-icons-briefcase" />
          &nbsp;&nbsp;
          <AxoLocal
            key="nocase"
            entity="TimeEntriesSideBarNoCase"
            defaultValue="No case"
          />
        </span>
      </AxoListItem>,
    ];
    var optionItems = sortedCases.map((caseModel) => {
      return (
        <AxoListItem
          style={{ paddingLeft: "10px" }}
          className=""
          key={caseModel.id}
          active={caseModel.id === this.props.selectedOptionId}
          onClick={this.props.onSelectOption.bind(this, caseModel.id)}
        >
          <span>
            <Icon glyph="icon-simple-line-icons-briefcase" />
            &nbsp;&nbsp;
            {StringService.enforceMaxLength(caseModel.caseNumber, 25)}
          </span>
        </AxoListItem>
      );
    });
    return allItem.concat(optionItems).concat(variousItem);
  };

  getClientOptions = () => {
    let { showInvoices, clientsWithEntries, onSelectOption, selectedOptionId } =
      this.props;

    let sortedClients = clientsWithEntries.sort((l, r) => {
      return l.lastName > r.lastName;
    });
    let customItems = [
      <AxoListItem
        style={{ paddingLeft: "10px" }}
        className=" "
        key={-1}
        active={-1 === selectedOptionId}
        onClick={onSelectOption.bind(this, -1)}
      >
        <span>
          <Icon glyph="icon-simple-line-icons-briefcase" />
          &nbsp; &nbsp;
          <AxoLocal entity="TimeEntriesSideBarAllOption" defaultValue="All" />
        </span>
      </AxoListItem>,
    ];
    if (showInvoices) {
      customItems.push(
        <AxoListItem
          style={{ paddingLeft: "10px" }}
          className=" "
          key={"Axolex"}
          active={"Axolex" === selectedOptionId}
          onClick={onSelectOption.bind(this, "Axolex")}
        >
          <span>
            <Icon glyph="icon-simple-line-icons-briefcase" />
            &nbsp; &nbsp;Axolex
          </span>
        </AxoListItem>
      );
    }

    let variousItem = [
      <AxoListItem
        style={{ paddingLeft: "10px" }}
        className=""
        key={0}
        active={0 === selectedOptionId}
        onClick={onSelectOption.bind(this, 0)}
      >
        <span>
          <Icon glyph="icon-fontello-folder-open-1" />
          &nbsp; &nbsp;
          <AxoLocal
            key="noclient"
            entity="TimeEntriesSideBarNoClientnoclient"
            defaultValue="Ingen klient"
          />
        </span>
      </AxoListItem>,
    ];
    let optionItems = sortedClients.map((client) => {
      return (
        <AxoListItem
          style={{ paddingLeft: "10px" }}
          className=""
          key={client.id}
          active={client.id === selectedOptionId}
          onClick={onSelectOption.bind(this, client.id)}
        >
          <span>
            <img alt="" src="/svg/avatar111-12.svg" width="30" height="30" />
            &nbsp; &nbsp;
            {StringService.enforceMaxLength(
              client.firstName + " " + client.lastName,
              25
            )}
          </span>
        </AxoListItem>
      );
    });
    return customItems.concat(optionItems).concat(variousItem);
  };

  getOptionItems() {
    if (this.props.showCases) {
      return this.getCaseOptions();
    }
    return this.getClientOptions();
  }

  render() {
    return (
      <PanelGroup
        id="TimeEntriesa"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {
                <span>
                  {this.renderAccordionHeader(this.getOptionsTitle())}
                </span>
              }
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <AxoListGroup className="axolexlist">
              {this.getOptionItems()}
            </AxoListGroup>
          </Panel.Body>
        </Panel>
      </PanelGroup>

      // <Accordion defaultActiveKey='0' className='Lex-Accordion '>
      //   <Panel
      //     className='panel-folder-list '
      //     style={{ padding: '0px'}}
      //     header={<span >{this.renderAccordionHeader(this.getOptionsTitle())}</span>}
      //     eventKey="1">
      //     <AxoListGroup className='axolexlist' >
      //       {this.getOptionItems()}
      //     </AxoListGroup>
      //   </Panel>
      // </Accordion>
    );
  }
}

export default class TimeEntriesSidebarContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      timeEntryOptions: {
        optionItems: [],
        casesWithEntries: [],
        clientsWithEntries: [],
        showCases: false,
        selectedOptionId: 0,
        onSelectOption: (optionId) => {},
      },
    };
  }
  //Props
  //TimeEntryOptions
  componentDidMount() {
    TimeEntryOptionsStore.subscribeToTimeEntryOptions(
      "TimeEntriesSidebarContainer",
      (options) => {
        this.setState({
          timeEntryOptions: options,
        });
      }
    );
  }

  componentWillUnmount() {
    TimeEntryOptionsStore.unsubscribeFromTimeEntryOptions(
      "TimeEntriesSidebarContainer"
    );
  }

  onSelectOption = (optionId) => {
    this.state.timeEntryOptions.onSelectOption(optionId);
  };

  render() {
    return (
      <OptionsBox
        casesWithEntries={this.state.timeEntryOptions.casesWithEntries}
        clientsWithEntries={this.state.timeEntryOptions.clientsWithEntries}
        onSelectOption={this.onSelectOption}
        selectedOptionId={this.state.timeEntryOptions.selectedOptionId}
        showCases={this.state.timeEntryOptions.showCases}
        showInvoices={this.state.timeEntryOptions.showInvoices}
      />
    );
  }
}
