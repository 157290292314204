import React from "react";
import DocumentTableFixedView from "./DocumentTableFixedView";
import Dropzonejs from "../../utilities/Dropzonejs";

import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class DocumentUploadView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploadedDocuments: [],
    };
  }

  onFileUploaded = (file) => {
    this.setState({
      uploadedDocuments: [...this.state.uploadedDocuments, file.id],
    });

    this.setState({ fileUploaded: true });
    setTimeout(() => {
      this.setState({ fileUploaded: false });
    }, 4000);
    this.props.onFileUploaded(file);
  };

  onFilesSubmitted = (files) => {
    this.props
      .onFilesSubmitted(files)
      .then((response) => {
        if (!response.ok) {
          return [];
        }
        return response.json();
      })
      .then((newFiles) => {
        if (newFiles.length === 0) {
          return;
        }
        this.setState({
          uploadedDocuments: [
            ...this.state.uploadedDocuments,
            ...newFiles.map((f) => f.id),
          ],
        });

        this.setState({ fileUploaded: true });
        setTimeout(() => {
          this.setState({ fileUploaded: false });
        }, 4000);
      });
  };

  getUploadedDocuments = () => {
    return this.props.documents.filter((f) =>
      this.state.uploadedDocuments.find((id) => id === f.id)
    );
  };

  //Props
  //documents
  //labels
  //cases
  //onFileUploaded
  render() {
    var uploadedDocuments = this.getUploadedDocuments();
    return (
      <div>
        <DocumentTableFixedView
          isUpload
          documents={uploadedDocuments}
          count={uploadedDocuments.length}
          userProfile={this.props.userProfile}
          contactMap={this.props.contactMap}
          selectedContact={this.props.selectedContact}
          labels={this.props.labels}
          caseMap={this.props.caseMap}
          friends={this.props.friends}
          onClickShared={this.props.onClickShared}
          height={300}
          forceShowCards={this.props.forceShowCards}
          onToggleCards={this.props.onToggleCards}
          queryDocuments={() => {}}
          onFilesSubmitted={this.onFilesSubmitted}
          locale={this.props.locale}
        />
        <div style={{ paddingLeft: "15px", paddingTop: "20px" }}>
          <h4>
            <Icon glyph="icon-fontello-upload-4" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="DocumentTabUploadyourdocumentshere"
              defaultValue={"Upload dine dokumenter her"}
            />
            &ensp;&ensp;
          </h4>
        </div>
        <Dropzonejs onFileUploaded={this.onFileUploaded} />
      </div>
    );
  }
}
