import React from "react";
import LocalizationService from "../services/LocalizationService";
import DataActions from "../services/DataAccess/DataActions";
import { AxoLocal } from "../utilities/LexUtilities";
import { MenuItem, DropdownButton } from "react-bootstrap";

const flagMenuItems = [
  { name: "Dansk", flag: "Denmark", lang: "da" },
  { name: "English - U.S.", flag: "United-States", lang: "enUS" },
  // { name: 'Chinese', flag: 'China', lang: 'ch' }
];

export default class FlagMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    var settingsLocale = this.props.userSettings.locale;
    if (settingsLocale) {
      this.setLocalLocale(settingsLocale);
    }
    var locale = this.getLocalLocale();
    if (locale) {
      try {
        LocalizationService.changeLocale(locale);
      } catch (error) {
        console.log(error);
      }
    }
    var selectedFlagItem = flagMenuItems.find((f) => f.lang === locale);
    this.state = {
      selectedFlag: selectedFlagItem ? selectedFlagItem.flag : "Denmark",
    };
  }

  getLocalLocale = () => {
    if (typeof Storage !== "undefined") {
      return localStorage.getItem("locale");
    } else {
      return "";
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.userSettings.locale) {
      return;
    }
    var selectedFlagItem = flagMenuItems.find(
      (f) => f.lang === nextProps.userSettings.locale
    );
    this.setState({
      selectedFlag: selectedFlagItem ? selectedFlagItem.flag : "Denmark",
    });
    if (nextProps.userSettings.locale !== this.props.userSettings.locale) {
      LocalizationService.changeLocale(nextProps.userSettings.locale);
      this.setLocalLocale(nextProps.userSettings.locale);
    }
  }

  handleSelect = (flag) => {
    this.setState({ selectedFlag: flag }, () => {
      var locale = flagMenuItems.find((item) => {
        return item.flag === this.state.selectedFlag;
      }).lang;

      LocalizationService.changeLocale(locale);
      this.setLocalLocale(locale);
      if (this.props.userSettings.id) {
        var userSettings = Object.assign({}, this.props.userSettings);
        userSettings.locale = locale;
        DataActions.updateUserSettings(userSettings);
      }
    });
  };

  setLocalLocale = (locale) => {
    if (typeof Storage !== "undefined") {
      localStorage.setItem("locale", locale);
    }
  };

  render() {
    const flagIcon = (
      <img
        alt=""
        src={`/imgs/app/flags/flags/flat/32/${this.state.selectedFlag}.png`}
      />
    );
    let { hideLanguage } = this.props;

    let menuItems = flagMenuItems
      .filter((f) => f.lang !== hideLanguage)
      .map(({ name, flag, lang }, i) => {
        return (
          <MenuItem
            key={name}
            eventKey={flag}
            active={this.state.selectedFlag === flag}
            className="Lex-DropdownButton-menu"
          >
            <img
              src={`/imgs/app/flags/flags/flat/32/${flag}.png`}
              alt={name}
              width="32"
              height="32"
            />
            &nbsp;
            <AxoLocal
              className="lang-menu-text"
              entity="languageMenu"
              data={{ lang }}
              defaultValue={name}
            />
          </MenuItem>
        );
      });

    //Rubix checks whether the dropup prop exists, rather than checking the value, so we have to do it like this.
    if (this.props.dropup) {
      return (
        <DropdownButton
          id="Flag"
          dropup
          title={flagIcon}
          onSelect={this.handleSelect}
          className={this.props.className}
        >
          {menuItems}
        </DropdownButton>
      );
    }
    return (
      <DropdownButton
        id="Flag"
        title={flagIcon}
        onSelect={this.handleSelect}
        className={this.props.className}
      >
        {menuItems}
      </DropdownButton>
    );
  }
}
