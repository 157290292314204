import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import ModalService from "../../services/ModalService";

import { withRouter } from "react-router-dom";
import moment from "moment";

import {
  Row,
  Col,
  Grid,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Table,
} from "react-bootstrap";

import { Icon, AxoLocal } from "../../utilities/LexUtilities";

class ClientTrashEntriesView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  confirmDelete = (confirmMessage, deleteFunc) => {
    ModalService.openConfirmModal(confirmMessage, (value) => {
      if (value) {
        deleteFunc();
      }
    });
  };

  onDeleteMessage = (messageId) => {
    this.confirmDelete(
      <AxoLocal
        entity="confirmMessagedeletethismessagepermanently"
        defaultValue={"Vil du slette denne besked permanent?"}
      />,
      () => DataActions.deleteMessage(messageId)
    );
  };

  onRestoreMessage = (message) => {
    let { updateMessage } = this.props;

    updateMessage({
      ...message,
      trashed: false,
    });
  };

  onDeleteDocument = (documentId) => {
    this.confirmDelete(
      <AxoLocal
        entity="confirmMessagedocumentpermanently"
        defaultValue={"Vil du slette dette dokument permanent?"}
      />,
      () => DataActions.deleteDocument(documentId)
    );
  };

  onRestoreDocument = (document) => {
    let newDoc = { ...document };
    newDoc.trashed = false;
    DataActions.updateDocument(newDoc);
  };

  onDeleteContact = (id) => {
    let { deleteContact } = this.props;

    this.confirmDelete(
      <span>
        {" "}
        <AxoLocal
          entity="axoEntityidcode1"
          defaultValue={"Vil du slette kunden permanent?"}
        />
      </span>,
      () => deleteContact(id)
    );
  };

  onDeleteInvoice = (id) => {
    this.confirmDelete(
      <span>
        <AxoLocal
          entity="axoEntityidcode2"
          defaultValue={"Vil du slette fakturaen permanent?"}
        />
      </span>,
      () => DataActions.deleteInvoice(id)
    );
  };

  onDeleteEntry = (entry) => {
    if (entry.subject) {
      this.onDeleteMessage(entry.id);
    } else if (!!entry.fileName) {
      this.onDeleteDocument(entry.id);
    } else if (!!entry.firstName) {
      this.onDeleteContact(entry.id);
    } else if (!!entry.number) {
      this.onDeleteInvoice(entry.id);
    }
  };

  onRestoreEntry = (entry) => {
    let { updateContact } = this.props;

    if (!!entry.subject) {
      this.onRestoreMessage(entry);
    } else if (!!entry.fileName) {
      this.onRestoreDocument(entry);
    } else if (!!entry.firstName) {
      updateContact({ ...entry, trashed: false });
    } else if (!!entry.number) {
      DataActions.updateInvoice({ ...entry, trashed: false });
    }
  };

  getEntryNodes = () => {
    let { messages, documents, contacts, invoices } = this.props;

    let entries = messages.concat(documents).concat(contacts).concat(invoices);

    return entries.map((entry) => {
      return (
        <tr key={entry.id}>
          <td>
            {!!entry.subject && <>{entry.subject}</>}
            {!!entry.fileName && <>{entry.fileName}</>}
            {!!entry.firstName && (
              <>
                {entry.firstName} {entry.lastName}
              </>
            )}
            {!!entry.number && (
              <>
                {entry.invoiceType} {entry.number}
              </>
            )}
          </td>
          <td>
            {moment(
              entry.date || entry.uploadDate || entry.creationDate
            ).format("L LT")}
          </td>
          <td>
            <AxoLocal
              componentClass={Icon}
              role="button"
              onClick={this.onDeleteEntry.bind(this, entry)}
              glyph="icon-fontello-trash-1"
              componentAttribute="title"
              entity="axoidcode179"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span role="button" onClick={this.onRestoreEntry.bind(this, entry)}>
              <AxoLocal
                componentClass={Icon}
                glyph="icon-fontello-loop"
                componentAttribute="title"
                entity="DocumentTrashTableViewrestore"
              />
              &nbsp;
              <AxoLocal
                entity="DocumentTrashTableViewrestore"
                defaultValue={"Gendan"}
              />
            </span>
          </td>
        </tr>
      );
    });
  };

  renderEntriesTable = () => {
    var entryNodes = this.getEntryNodes();
    if (entryNodes.length === 0) {
      entryNodes.push(
        <tr key={0}>
          <td>
            <AxoLocal
              entity="DocumentTherubbishbinisempty"
              defaultValue={"Skraldespanden er tom"}
            />
          </td>
          <td></td>
          <td></td>
        </tr>
      );
    }
    return (
      <Table
        ref={(c) => (this.example = c)}
        responsive
        id="timeTable"
        className="display"
        cellSpacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th>
              <AxoLocal
                entity="DocumentTherubbishbinisemptytimeTable"
                defaultValue={"Navn/Emne"}
              />
            </th>
            <th>
              <AxoLocal
                entity="TimeEntryFormntimeEntry"
                defaultValue={"Dato"}
              />
            </th>
            <th></th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>
              <AxoLocal
                entity="DocumentTherubbishbinisemptytimeTable"
                defaultValue={"Navn/Emne"}
              />
            </th>
            <th>
              <AxoLocal
                entity="TimeEntryFormntimeEntry"
                defaultValue={"Dato"}
              />
            </th>
            <th></th>
          </tr>
        </tfoot>
        <tbody>{entryNodes}</tbody>
      </Table>
    );
  };

  getEmptyTrashLabel = () => {
    return (
      <AxoLocal
        entity="TimeEntryFormntimeEntryEmptygarbagebin"
        defaultValue={"Empty trashcan"}
      />
    );
  };

  onEmptyTrash = () => {
    ModalService.openConfirmModal(this.getEmptyTrashMessage(), (value) => {
      if (value) {
        this.emptyCurrentTrashcan();
      }
    });
  };

  getEmptyTrashMessage = () => {
    let { messages, documents, contacts, invoices } = this.props;

    let amount =
      messages.length + documents.length + contacts.length + invoices.length;
    return (
      <span>
        ({amount}){" "}
        <AxoLocal
          entity="ThrashEntriesViewDeletePermanently"
          defaultValue={"Slet permanent?"}
        />
      </span>
    );
  };

  emptyCurrentTrashcan = () => {
    let { messages, documents, contacts, invoices, deleteContact } = this.props;

    var promises = [];
    messages.forEach((message) => {
      promises.push(DataActions.deleteMessage(message.id));
    });
    documents.forEach((document) => {
      promises.push(DataActions.deleteDocument(document.id));
    });
    contacts.forEach((contact) => {
      promises.push(deleteContact(contact.id).unwrap());
    });
    invoices.forEach((invoice) => {
      promises.push(DataActions.deleteInvoice(invoice.id));
    });
    this.resolveDeletePromises(promises);
  };

  resolveDeletePromises = (promises) => {
    Promise.all(promises)
      .then((responses) => {
        var errorCount = responses.filter((r) => r.status !== 200).length;
        if (errorCount > 0) {
          this.setErrorMessage(
            <span>
              {errorCount.toString()}&nbsp;
              <AxoLocal
                entity="deleteAllFromAllTrashcansSetCountErrorMessage"
                defaultValue={
                  "elementer kunne ikke slettes. Tjek at disse elementer ikke er i brug andre steder i systemet."
                }
              />
            </span>
          );
        }
      })
      .catch((error) => {
        this.setErrorMessage(
          <AxoLocal
            entity="deleteAllFromAllTrashcanssetErrorMessage"
            defaultValue={
              "Der skete en fejl under sletningen. Tjek at ingen af elementerne er i brug andre steder i systemet."
            }
          />
        );
      });
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 10000);
  };

  render() {
    return (
      <div style={{ paddingTop: 0, minHeight: "800px" }}>
        <Grid fluid>
          <Row>
            <Col xs={8} style={{ paddingBottom: 5 }}>
              <ButtonToolbar className="axoinboxtoolbar">
                <ButtonGroup>
                  <Button className="Lex-button-2" onClick={this.onEmptyTrash}>
                    <Icon glyph="icon-fontello-trash-4" />{" "}
                    {this.getEmptyTrashLabel()}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
        </Grid>
        <div style={{ paddingLeft: "25px" }}>
          <Grid fluid>
            <Row>
              <Col xs={12}>
                {this.state.errorMessage ? (
                  <div className="axored">
                    <b>{this.state.errorMessage}</b>
                  </div>
                ) : null}
                {this.renderEntriesTable()}
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(ClientTrashEntriesView);
