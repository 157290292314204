var alertModal = null;
var confirmModal = null;

export default {
  registerAlertModal(modal) {
    alertModal = modal;
  },
  registerConfirmModal(modal) {
    confirmModal = modal;
  },

  openAlertModal(message, callback) {
    if (!alertModal) {
      return;
    }
    alertModal.open(message, callback);
  },

  openConfirmModal(message, callback) {
    confirmModal.open(message, callback);
  },
};
