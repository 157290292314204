import React from "react";
import moment from "moment";

import { Link } from "../../utilities/LexUtilities";

const CasesList = (props) => {
  let { caseModels } = props;

  return (
    <div className="leftPadding topPadding standardMaxWidth">
      {caseModels.map((cm) => (
        <div
          key={cm.id}
          className="largeText leftPadding caseBox"
          style={{
            margin: "25px",
            border: "1px solid #dbdbdb",
            height: "50px",
            marginBottom: "3px",
          }}
        >
          <Link to={"SingleCase/" + cm.id}>
            {cm.caseNumber}
            {!!cm.subject && <> - {cm.subject}</>}
            &nbsp;- {moment.utc(cm.creationDate).format("L")}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default React.memo(CasesList);
