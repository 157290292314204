import React from "react";
import { ctx } from "../utilities/L20n/L20n";
import ReactOption from "../utilities/ReactOption";
import NumericInput from "react-numeric-input";
import Select from "react-select";
import ChromeLogo from "../resources/images/ChromeLogo.png";
import EdgeLogo from "../resources/images/EdgeLogo.png";
import VisaLogo from "../resources/images/Visa.png";
import MasterCardLogo from "../resources/images/MasterCard.jpg";
import PurchaseTermsSubPanel from "../Login/Licenses/PurchaseTermsSubPanel";
import LicenseConsentForm from "../Login/Licenses/LicenseConsentForm";
import DataConsentForm from "../Login/Licenses/DataConsentForm";

import {
  Row,
  Col,
  Grid,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
  // PanelGroup,
  // Panel
} from "react-bootstrap";

import {
  LexButton,
  Icon,
  AxoLocal,
  // AccordionUtils,
  FlexElement,
  Flexbox,
} from "../utilities/LexUtilities";

import {
  ApiService,
  NumberService,
  // VatService
} from "../services/AxoServices";

class CustomSubscriptionControls extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userNumber: 0,
      period: 0,
    };
  }

  onChangeUserNumber = (value) => {
    this.setState({ userNumber: value });
  };

  onSelectPeriod = (event) => {
    this.setState({ period: parseInt(event.target.value, 10) });
  };

  onSubmit = () => {
    this.props.onSubmitCustomize(this.state);
  };

  render() {
    var { userNumber, period } = this.state;
    var { showQuotingError } = this.props;
    return (
      <div style={{ maxWidth: "500px", margin: "auto", paddingBottom: "15px" }}>
        <div style={{ marginBottom: "10px" }}>
          <div style={{ height: "60px", lineHeight: "60px" }}>
            <AxoLocal
              entity="SubscriptionViewPricingFreetrialAB7"
              defaultValue="Antal brugere"
            />{" "}
          </div>
          <NumericInput
            className="form-control"
            onChange={this.onChangeUserNumber}
            step={1}
            precision={0}
            value={userNumber}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <div
            style={{
              height: "60px",
              lineHeight: "60px",
              paddingBottom: "25px",
            }}
          >
            <AxoLocal
              entity="SubscriptionViewPricingFreetrialAB4"
              defaultValue="Betalingsperiode"
            />{" "}
          </div>
          <select
            className="axoblue selectbg"
            value={period}
            onChange={this.onSelectPeriod}
            style={{
              width: "70%",
              height: "50px",
              borderRadius: "0px",
              marginLeft: "0px",
              marginRight: "0px",
              fontSize: "14px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              componentClass={ReactOption}
              value={0}
              componentAttribute="text"
              entity="SubscriptionViewPricingFreetrialAB5"
              defaultValue={"Månedsbasis"}
            />
            <AxoLocal
              componentClass={ReactOption}
              value={1}
              componentAttribute="text"
              entity="SubscriptionViewPricingFreetrialAB6"
              defaultValue={"Årsbasis"}
            />
          </select>
        </div>
        {showQuotingError ? (
          <div className="fg-red">
            <div>
              <AxoLocal
                entity="SubscriptionViewPricingFreetrialAB81"
                defaultValue="Abonnementet kunne ikke findes."
              />
            </div>
            <div>
              <AxoLocal
                entity="SubscriptionViewPricingFreetrialAB82"
                defaultValue="Kontakt Axolex support for en personlig aftale."
              />
            </div>
          </div>
        ) : null}
        <div style={{ height: "60px", lineHeight: "60px" }}>
          <LexButton
            onClick={this.onSubmit}
            style={{
              width: "70%",
              height: "70px",
              borderRadius: "0px",
              marginLeft: "0px",
              marginRight: "0px",
              fontSize: "17px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="SubscriptionViewPricingFreetrialAB8"
              defaultValue="Find abonnement"
            />
          </LexButton>
        </div>
      </div>
    );
  }
}

export default class SubscriptionSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    var currency = "DKK";
    if (!!props.userSubscription && !!props.userSubscription.planId) {
      var subCurrency = props.userSubscription.currency;
      currency = subCurrency ? subCurrency.toUpperCase() : "";
    } else if (props.locale) {
      currency = this.getCurrencyFromLocale(props.locale);
    }

    this.state = {
      currency: currency,
      showQuote: null,
      showCustomizeForm: false,
      showQuotingError: false,
      loading: false,
      showFormulaError: false,
    };
    this.name = "SubscriptionSelect";
  }

  getCountryCodeFromLocale = () => {
    let { locale } = this.props;
    switch (locale.toLowerCase()) {
      case "da":
        return "dk";
      case "enus":
        return "us";
      case "cn":
        return "cn";
      default:
        return "dk";
    }
  };

  componentDidMount() {
    var { userSubscription } = this.props;

    if (userSubscription && userSubscription.planId) {
      return;
    }
  }

  componentWillReceiveProps(nextProps) {
    var currency = null;
    if (!!nextProps.userSubscription && !!nextProps.userSubscription.planId) {
      var subCurrency = nextProps.userSubscription.currency;
      currency = subCurrency ? subCurrency.toUpperCase() : "";
    } else if (nextProps.locale !== this.props.locale) {
      currency = this.getCurrencyFromLocale(nextProps.locale);
    }

    if (!!currency) {
      this.setState({
        currency,
      });
    }
  }

  getCurrencyFromLocale = (locale) => {
    switch (locale.toLowerCase()) {
      case "enus":
        return "USD";
      case "da":
        return "DKK";
      case "ch":
        return "CNY";
      default:
        return "USD";
    }
  };

  getIntervalEntity = (interval) => {
    switch (interval) {
      case 0:
        return <AxoLocal entity="DayLabel" defaultValue="dag" />;
      case 1:
        return <AxoLocal entity="WeekLabel" defaultValue="uge" />;
      case 2:
        return <AxoLocal entity="MonthLabel" defaultValue="måned" />;
      case 3:
        return <AxoLocal entity="YearLabel" defaultValue="år" />;
      default:
        return "";
    }
  };

  onSelectPlan = (plan) => {
    var { onSelectPlan, onSelectFormula } = this.props;
    if (plan.planId) {
      onSelectPlan(plan);
    } else if (onSelectFormula && plan.formula) {
      this.setState({ loading: true });
      onSelectFormula({
        users: plan.userGroupSize,
        interval: plan.intervalLength > 2 ? "year" : "month",
        formula: plan.formula,
      })
        .then((response) => {
          this.setState({ loading: false });
        })
        .catch((reason) => {
          this.setState({
            loading: false,
            showFormulaError: true,
          });
          setTimeout(() => {
            this.setState({ showFormulaError: false });
          }, 5000);
        });
    }
  };

  renderPlan = (plan) => {
    let { attemptingSignup, userProfile } = this.props;
    let { loading } = this.state; //Loading is used for plan formulas
    let interval = plan.interval;
    let hasPicture = !!plan.picture;
    return (
      <div
        key={plan.id}
        style={{
          border: "1px solid #e6e6e6",
          width: "300px",
          paddingBottom: "25px",
          margin: "auto",
        }}
      >
        <div>
          <Col
            xs={hasPicture ? 7 : 12}
            className="nopadding"
            style={{ minHeight: "275px" }}
          >
            <div style={{ paddingBottom: "50px" }}>
              <div
                style={{
                  padding: "5px",
                  fontSize: "19px",
                  height: "100px",
                  borderWidth: "2px",
                  borderColor: "white",
                }}
                className="editable axocircletop"
              >
                <div>
                  {plan.name} ({plan.currency.toUpperCase()}) /{" "}
                  {this.getIntervalEntity(interval)}
                </div>
                <div className="axocircle" style={{ display: "inline-block" }}>
                  <div
                    className="axocircle1"
                    style={{ display: "inline-block" }}
                  >
                    {(plan.amount / 100).toFixed(0)}
                  </div>
                </div>
              </div>
            </div>
            {plan.userGroupSize && plan.userGroupSize > 1 ? (
              <div style={{ paddingTop: "5px", paddingBottom: "10px" }}>
                <AxoLocal
                  entity="SubscriptionViewPricingFreetrialAB"
                  defaultValue="Max antal brugere"
                />
                : {plan.userGroupSize}
              </div>
            ) : (
              <div style={{ paddingTop: "5px", paddingBottom: "10px" }}>
                <AxoLocal
                  entity="subscriptionSelectIndividual"
                  defaultValue="Individuelt abonnement."
                />
              </div>
            )}
            {!!plan.trialPeriod && (
              <div style={{ paddingTop: "5px", paddingBottom: "10px" }}>
                <span>
                  {plan.trialPeriod}{" "}
                  <AxoLocal entity="DayPluralLabel" defaultValue="dages" />
                  &nbsp;
                  <AxoLocal
                    entity="SubscriptionViewPricingFreetrial"
                    defaultValue="gratis prøvetid"
                  />
                </span>
              </div>
            )}
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              {plan.storage} GB &nbsp;
              <AxoLocal
                entity="SubscriptionViewPricingfreetryGBstorage"
                defaultValue="lagerplads"
              />
            </div>
            {plan.type === "EasyID" && (
              <>
                <div className="topPadding bottomPadding">
                  {plan.currency}{" "}
                  {NumberService.formatDecimal(plan.costPerUnit / 100)} per
                  underskrift inkl. moms
                </div>
                {!!plan.freeUnitsPerInterval && (
                  <div className="topPadding bottomPadding">
                    {plan.freeUnitsPerInterval} gratis underskrift
                    {plan.freeUnitsPerInterval > 1 ? "er" : ""} hver periode
                  </div>
                )}
              </>
            )}
          </Col>
          {hasPicture ? (
            <Col
              xs={5}
              className="nopadding flexbox-center"
              style={{ height: "275px" }}
            >
              <img
                alt=""
                width="100%"
                src={ApiService.getSubscriptionPlanPicturePath(plan.planId)}
              />
            </Col>
          ) : null}
        </div>
        <div>
          <div style={{ borderWidth: "2px", borderColor: "white" }}>
            <Button
              disabled={attemptingSignup || loading}
              onClick={this.onSelectPlan.bind(this, plan)}
              className="Lex-button-2"
              style={{
                width: "70%",
                height: "70px",
                borderRadius: "0px",
                marginLeft: "0px",
                marginRight: "0px",
                fontSize: "17px",
                fontWeight: "normal",
              }}
            >
              {plan.planId === userProfile.planId ? (
                <span>
                  <AxoLocal
                    entity="SidebarRightContainerPresentPassword"
                    defaultValue={"Nuværende"}
                  />
                </span>
              ) : (
                <AxoLocal
                  entity="SubscriptionViewPricingSelectsubscription"
                  defaultValue="Vælg abonnement"
                />
              )}

              {loading ? (
                <img
                  alt=""
                  src="/imgs/app/loading.gif"
                  className="img-circle"
                  width="20"
                  height="20"
                />
              ) : null}
            </Button>
            {this.getSubscriptionErrorMessage(plan.planId)}
          </div>
        </div>
      </div>
    );
  };

  getSubscriptionErrorMessage = (planId) => {
    let { subscriptionErrorType } = this.props;
    let { showFormulaError } = this.state;
    let showSubscriptionErrorMessage =
      this.props.showSubscriptionErrorMessage || -1;
    if (
      (showSubscriptionErrorMessage === planId && !subscriptionErrorType) ||
      showFormulaError
    ) {
      return (
        <div className="axored">
          <Icon glyph="icon-fontello-info-3" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="SubscriptionViewPleasetryagainlater"
            defaultValue="Der skete en fejl. Prøv igen senere."
          />
        </div>
      );
    }
    if (
      showSubscriptionErrorMessage === planId &&
      subscriptionErrorType.includes("CountryMismatch")
    ) {
      return (
        <div className="axored">
          <AxoLocal
            entity="axoAccounting61"
            defaultValue={
              "Dit kreditkort skal være udstedt i samme land som du valgte da du udfyldte din brugerprofil."
            }
          />
        </div>
      );
    }
    if (
      showSubscriptionErrorMessage === planId &&
      subscriptionErrorType.includes("MissingVatNumber")
    ) {
      return (
        <div className="axored">
          <AxoLocal
            entity="axoAccounting65"
            defaultValue={"CVR nummeret kunne ikke valideres."}
          />
        </div>
      );
    }
    return <span></span>;
  };

  renderSubscriptionPlans = () => {
    // let { userProfile } = this.props;
    // let vatRate = VatService.getVatRate(userProfile.country || this.getCountryCodeFromLocale())

    let plans = this.getFilteredPlans();
    plans = plans.sort((a, b) => {
      return a.amount - b.amount;
    });
    var containers = [];
    for (var i = 0; i < plans.length; i = i + 3) {
      containers.push(
        <Flexbox key={i} collapseSM>
          <FlexElement flexGrow={1}></FlexElement>
          <div className="rightPadding">{this.renderPlan(plans[i])}</div>
          {plans.length > i + 1 ? (
            <div className="rightPadding">{this.renderPlan(plans[i + 1])}</div>
          ) : null}
          {plans.length > i + 2 ? (
            <div>{this.renderPlan(plans[i + 2])}</div>
          ) : null}
          <FlexElement flexGrow={1}></FlexElement>
        </Flexbox>
      );
    }
    return containers;
  };

  getFilteredPlans = () => {
    var { showQuote } = this.state;
    if (showQuote) {
      return [showQuote];
    }
    return this.props.subscriptionPlans.filter(
      (plan) =>
        plan.type !== "Custom" &&
        plan.currency.toUpperCase() === this.state.currency
    );
  };

  onCurrencySelect = (selectedCurrency) => {
    if (!selectedCurrency) {
      return;
    }
    this.setState({ currency: selectedCurrency.value });
  };

  getCurrencyArray = () => {
    var currencies = new Set();
    this.props.subscriptionPlans.forEach((plan) => {
      currencies.add(plan.currency.toUpperCase());
    });
    currencies.add("DKK");
    currencies.add("USD");
    currencies.add("CNY");
    var array = Array.from(currencies);
    return array;
  };

  renderCurrencySelect = () => {
    let { currency } = this.state;

    return (
      <div>
        <div style={{ width: "150px", display: "inline-block" }}>
          <Select
            name="form-field-name"
            menuPlacement="auto"
            value={
              !!currency
                ? { value: currency, label: currency.toUpperCase() }
                : { value: "", label: "" }
            }
            onChange={this.onCurrencySelect}
            noOptionsMessage={() => ""}
            options={[
              { value: "", label: ctx.getSync("SubscriptioncurrencyA") },
            ].concat(
              this.getCurrencyArray().map((currency) => {
                return { value: currency, label: currency.toUpperCase() };
              })
            )}
          />
        </div>
      </div>
    );
  };

  renderGroupCodeInput = () => {
    return (
      <div>
        <FormGroup style={{ maxWidth: "400px" }}>
          <InputGroup bsSize="large">
            <InputGroup.Addon>
              <Icon glyph="icon-fontello-mail" />
            </InputGroup.Addon>
            <AxoLocal
              componentClass={FormControl}
              type="text"
              value={this.props.groupCode}
              onChange={this.props.onChangeCode}
              componentAttribute="placeholder"
              entity="GroupCodePlaceHolder"
            />
            <InputGroup.Addon>
              <Button
                onClick={this.props.onSubmitCode}
                className="Lex-button-2"
              >
                <AxoLocal
                  entity="SubscriptionViewPricingFreetrialAB3"
                  defaultValue="Tilmeld"
                />
              </Button>
            </InputGroup.Addon>
          </InputGroup>
          {this.props.showGroupCodeErrorMessage ? (
            <div className="text-left axored">
              <AxoLocal
                entity="SubscriptionViewPricingFreetrialAB2"
                defaultValue="Koden kunne ikke anvendes"
              />
            </div>
          ) : null}
        </FormGroup>
      </div>
    );
  };

  onShowCustomize = () => {
    this.setState({ showCustomizeForm: !this.state.showCustomizeForm });
  };

  onSubmitCustomize = (result) => {
    var { subscriptionFormulas, subscriptionPlans } = this.props;
    var { userNumber, period } = result;
    var existing = subscriptionPlans.find((p) => {
      return (
        p.currency.toUpperCase() === this.state.currency &&
        p.userGroupSize === userNumber &&
        p.interval === period + 2
      );
    });

    if (existing) {
      this.setState({ showQuote: existing });
      return;
    }
    if (!existing) {
      var formula = subscriptionFormulas.find(
        (f) =>
          f.minUsers <= userNumber &&
          f.maxUsers >= userNumber &&
          f.currency.toUpperCase() === this.state.currency
      );
      if (formula) {
        var monthlyPrice = formula.monthlyPrice * userNumber;
        var annualPrice = monthlyPrice * 12 * (1 - formula.yearDiscount / 100);
        this.setState({
          showQuote: {
            name: "",
            amount: period === 0 ? monthlyPrice : annualPrice,
            userGroupSize: userNumber,
            trialPeriod: 30,
            storage: 50 * userNumber,
            currency: formula.currency.toUpperCase(),
            interval: period + 2,
            formula,
          },
        });
        return;
      }
    }
    //Show error message
    this.setState({
      showQuote: null,
      showQuotingError: true,
    });
    setTimeout(() => {
      this.setState({ showQuotingError: false });
    }, 5000);
  };

  renderControls = () => {
    var { showCustomizeForm, showQuotingError, showFormulaError } = this.state;
    var { onSubmitCode, onSelectFormula, globalSettings, userSubscription } =
      this.props;
    return (
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <div className="flexbox-justified">
          {onSubmitCode ? this.renderGroupCodeInput() : null}
          {!userSubscription || !userSubscription.planId
            ? this.renderCurrencySelect()
            : null}
          {globalSettings.hideSubscriptionFormulas !== "true" &&
          !!onSelectFormula ? (
            <div style={{ paddingBottom: "15px" }}>
              <LexButton
                onClick={this.onShowCustomize}
                style={{
                  width: "80%",
                  height: "60px",
                  borderRadius: "0px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                {showCustomizeForm ? (
                  <span key="close">
                    <AxoLocal
                      entity="SubscriptionViewPricingFreetrialAB9"
                      defaultValue="Luk søgeformular"
                    />
                  </span>
                ) : (
                  <span key="show">
                    <AxoLocal
                      entity="SubscriptionViewPricingFreetrialAB10"
                      defaultValue="Kustomiser abonnement"
                    />
                  </span>
                )}
              </LexButton>
            </div>
          ) : null}
        </div>
        {showCustomizeForm ? (
          <CustomSubscriptionControls
            onSubmitCustomize={this.onSubmitCustomize}
            showQuotingError={showQuotingError}
            showFormulaError={showFormulaError}
          />
        ) : null}
      </div>
    );
  };

  render() {
    var { globalSettings, locale, userSubscription } = this.props;

    let sub = userSubscription;
    let amount = 0;
    if (!!sub && !!sub.purchasedUnits) {
      let netPurchasedUnits = Math.max(
        sub.purchasedUnits - sub.freeUnitsPerInterval,
        0
      );
      amount = (netPurchasedUnits * sub.costPerUnit) / 100;
    }

    return (
      <Grid fluid>
        <Row>
          {/* <Col md={10} mdOffset={1} sm={12} className="text-center nopadding" style={{padding:'0px'}}> */}
          <Col
            sm={12}
            className="text-center nopadding"
            style={{ padding: "0px" }}
          >
            {globalSettings.subscriptionPlansStatus === "testing" ? (
              <div style={{ marginBottom: "10px" }}>
                <div>
                  {/* <div><AxoLocal entity='SubscriptionPlansnu1a' defaultValue='Axolex frigiver vores software til fri benyttelse i den kommende periode, for blandt andet advokater, jurister, rådgivere og konsulenter. '/> </div> */}
                  {/* <div><AxoLocal entity='SubscriptionPlansnu1b' defaultValue='Gå ikke glip af denne mulighed.'/> </div> */}
                  {/* <div><AxoLocal entity='SubscriptionPlansnu1c' defaultValue='Vi vil sætte pris på at brugerne kommer med feedback på oplevelsen af vores system, så vi kan samarbejde om at skabe de bedst mulige funktionaliteter.'/> </div> */}
                </div>
                <div>
                  {/* <AxoLocal entity='subscriptionSelectTestMessage1' defaultValue='Axolex systemet er nu åbent for offentlig testning.'/>&nbsp;
                <AxoLocal entity='subscriptionSelectTestMessage2' defaultValue='Vælg et test abonnement og prøv systemet helt gratis.'/>&nbsp;
                <AxoLocal entity='subscriptionSelectTestMessage3' defaultValue='Axolex ApS tager forbehold for fejl og mangler i testperioden.'/> */}
                </div>
              </div>
            ) : null}

            {/* <div>
            <AxoLocal entity='axoAccounting44'defaultValue={'Priser er inkl. moms'}/>
          </div> */}
            {globalSettings.subscriptionPlansStatus === "disabled" ? (
              <div>
                <div>
                  <AxoLocal
                    entity="SubscriptionPlansnu"
                    defaultValue="På nuværende tidspunkt implementerer vi kun løsninger efter særaftale."
                  />
                </div>
                <div>
                  <AxoLocal
                    entity="SubscriptionPlansnu1"
                    defaultValue="Offentlige abonnementer vil blive tilgængelige i starten af 2018."
                  />
                </div>
              </div>
            ) : (
              <div>
                {this.renderControls()}
                {!!amount && (
                  <div className="axored mediumText">
                    Skyldigt beløb: {NumberService.formatDecimal(amount)} kr.
                    inkl. moms. ({NumberService.formatDecimal(amount / 1.25)}{" "}
                    kr. ekskl. moms.)
                  </div>
                )}
                {this.renderSubscriptionPlans()}
              </div>
            )}
          </Col>
          <Row>
            <Col xs={12} className="text-center">
              <div>
                <img alt="" src={VisaLogo} width="25px" />
                &nbsp;&nbsp;
                <img alt="" src={MasterCardLogo} width="25px" />
                &nbsp;&nbsp;
              </div>
              <h5>
                Betaling sker til Axolex ApS - 2665 Vallensbæk Strand Denmark
                Tlf: 53780153 Email: info@axolex.com
              </h5>
              <h4>
                Ved at vælge et abonnement giver du samtykke til Købsbetingelser
                og Brugerbetingelser.
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="standardMaxWidth center-block">
                <PurchaseTermsSubPanel locale={locale} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="standardMaxWidth center-block">
                <LicenseConsentForm locale={locale} hideControls />
                <DataConsentForm locale={locale} hideControls />
              </div>
            </Col>
          </Row>
        </Row>
        <div className="bottomPadding">
          <img alt="" src={ChromeLogo} width="35px" />
          &nbsp;&nbsp;
          <img alt="" src={EdgeLogo} width="35px" />
          &nbsp;&nbsp; &nbsp;
          <AxoLocal
            entity="subscriptionSelectBrowserSupport"
            defaultValue="Axolex systemet understøtter Google Chrome og Microsoft Edge browserne. Vi anbefaler Google Chrome for den optimale oplevelse."
          />
          &nbsp;
        </div>
      </Grid>
    );
  }
}
