import React from "react";
import DateTime from "react-datetime";
import { Consumer } from "../utilities/Context";

function getMomentLocale(locale) {
  switch (locale) {
    case "da":
      return "da";
    case "enUS":
      return "en-US";
    case "ch":
      return "zh-cn";
    default:
      return "en-US";
  }
}

export default class AxoDateTime extends React.PureComponent {
  onChange = (newValue) => {
    let { onChange } = this.props;

    if (!onChange) {
      return;
    }

    //Incomplete dates are stored as strings while the user is typing
    if (typeof newValue === "string") {
      return;
    }

    onChange(newValue);
  };

  render() {
    return (
      <Consumer>
        {(store) => {
          return (
            <DateTime
              {...this.props}
              onChange={this.onChange}
              locale={getMomentLocale(store.locale)}
            />
          );
        }}
      </Consumer>
    );
  }
}
