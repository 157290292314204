import React from "react";
import { Carousel } from "react-bootstrap";
import { AxoLocal } from "../../utilities/LexUtilities";
class MailCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEM1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext185"
                defaultValue={"Unik løsning til mailsystem."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext186"
                defaultValue={
                  "Kollaborativ integration med hele kontrolsystemet."
                }
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEM2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext185"
                defaultValue={"Unik løsning til mailsystem."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext186"
                defaultValue={
                  "Kollaborativ integration med hele kontrolsystemet."
                }
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEM3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext185"
                defaultValue={"Unik løsning til mailsystem."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext186"
                defaultValue={
                  "Kollaborativ integration med hele kontrolsystemet."
                }
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class NetworkCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexES1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexES2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexES3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexES1-1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext187"
                defaultValue={"Virksomhedens kommunikationsværktøj"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext188"
                defaultValue={"Effektiv og privat kommunikation."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class FinanceCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEE1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEE2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEE3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEE4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEE5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext189"
                defaultValue={"Digital økonomistyringsprocess"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext190"
                defaultValue={"Elektronisk opbevaring."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class CasesCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexEC1-1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEC1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEC8.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEC3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEC4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexEC1-2.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEC5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEC6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEC7.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexEC2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexEC8-1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext191"
                defaultValue={"Central sagsstyring."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext192"
                defaultValue={"Optimering af arbejdsprocessen."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class ClientsCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECK1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECK2.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECK3.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext193"
                defaultValue={"Fleksibel optimal klientdatahåndtering."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class SelfserviceCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexESS1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexESS2.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexESS3.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexESS4.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC5.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext194"
                defaultValue={"Øget serviceniveau for kunderne."}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext195"
                defaultValue={"Enkelt, sikkert og hurtigt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}
class DocumentsCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexED1.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexED2.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexED3.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexED4.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexED5.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="" width={800} height={445} src="/imgs/app/1AxolexED6.png" />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext196"
                defaultValue={"Digital dokumenthåndtering"}
              />
            </div>
            <p className="hidden-xs">
              <AxoLocal
                entity="IndividualPackagetext197"
                defaultValue={"Simpelt og effektivt."}
              />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class BookingCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC5.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC3.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC4.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC2.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext198"
                defaultValue={"Tidsbesparelse og produktivitet."}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class CalendarCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big"
          ></span>
        }
      >
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC4.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC3.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC1.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC5.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC2.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC6.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexECC7.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext199"
                defaultValue={"Planlægningsværktøj"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

class OnlinepaymentCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
      >
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexEC11.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="FrontPageindextaxt14"
                defaultValue={"Online betalingssystem"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}
class LabelsCarouselEnUS extends React.PureComponent {
  render() {
    return (
      <Carousel
        prevIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-left-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
        nextIcon={
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
              fontSize: "30px",
            }}
            className="icon-fontello-right-open-big hidden-xs hidden-sm hidden-md hidden-lg"
          ></span>
        }
      >
        <Carousel.Item>
          <img
            alt=""
            width={800}
            height={445}
            src="/imgs/app/1AxolexEMP2.png"
          />
          <Carousel.Caption>
            <div className="axolexCarouselItem">
              <AxoLocal
                entity="IndividualPackagetext200"
                defaultValue={"Digital arkivering og administration"}
              />
            </div>
            <p className="hidden-xs"></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export {
  MailCarouselEnUS,
  NetworkCarouselEnUS,
  FinanceCarouselEnUS,
  CasesCarouselEnUS,
  ClientsCarouselEnUS,
  SelfserviceCarouselEnUS,
  DocumentsCarouselEnUS,
  BookingCarouselEnUS,
  CalendarCarouselEnUS,
  LabelsCarouselEnUS,
  OnlinepaymentCarouselEnUS,
};
