import React from "react";
import { Link } from "react-router-dom";
import RoutingService from "../../services/RoutingService";
import ApiService from "../../services/DataAccess/ApiService";
import LocalizationService from "../../services/LocalizationService";
import { ctx } from "../../utilities/L20n/L20n";
import Conditional from "../../utilities/Conditional";
import { withRouter } from "react-router-dom";

import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

class SignupForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      signupViewModel: {
        userName: "",
        password: "",
        confirmPassword: "",
        email: "",
        phone: "",
        userType: "XLink",
      },
      attemptingSignup: false,
      validationState: {
        invalidUserName: false,
        invalidPassword: false,
        missingEmail: false,
        duplicateEmail: false,
        genericError: false,
      },
    };
    this.locale = "enUS";
  }

  onUpdateViewModelProperty = (propertyName, event) => {
    var viewModel = Object.assign({}, this.state.signupViewModel);
    viewModel[propertyName] = event.target.value;
    this.setState({ signupViewModel: viewModel });
  };

  back(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.goBack();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    LocalizationService.subscribeToLocale("SignupForm", (locale) => {
      var currentLocale = locale.toLowerCase();
      this.locale = currentLocale;
      if (this.locale === "enus") {
        this.locale = "enUS";
      }
      var country = this.getCountryFromLocale(currentLocale);
      var viewModel = Object.assign({}, this.state.signupViewModel);
      viewModel.country = country;
      this.setState({ signupViewModel: viewModel });
    });
  }

  getCountryFromLocale(locale) {
    switch (locale) {
      case "da":
        return "DK";
      case "enus":
        return "US";
      case "ch":
        return "CN";
      default:
        return "";
    }
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale("SignupForm");
  }

  getPath(path) {
    return path;
  }

  onLogin = (event) => {
    event.preventDefault();
    this.props.history.push(RoutingService.getAccountPath("Login"));
  };

  onCreateUser = () => {
    var validationState = Object.assign({}, this.state.validationState);
    var email = this.state.signupViewModel.email;
    if (!email || !this.validateEmail(email)) {
      validationState.missingEmail = true;
      this.setState({ validationState: validationState });
      return;
    }
    this.setState({ attemptingSignup: true });
    var signupViewModel = Object.assign({}, this.state.signupViewModel);
    signupViewModel.registerEmailSubject = ctx.getSync("SignupMailSubject");
    signupViewModel.registerEmailMessage = ctx.getSync("SignupMailMessage");
    signupViewModel.messageUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      RoutingService.getAccountPath("ConfirmEmail");

    signupViewModel.locale = this.locale;

    ApiService.register(signupViewModel).then((response) => {
      validationState.invalidPassword = false;
      validationState.invalidUserName = false;
      if (response.status === 200) {
        this.props.history.push("/XLink");
      } else {
        response
          .json()
          .then((message) => {
            if (message.error === "DuplicateUserName") {
              validationState.invalidUserName = true;
            } else if (message.error === "InvalidPassword") {
              validationState.invalidPassword = true;
            } else if (message.error === "DuplicateEmail") {
              validationState.duplicateEmail = true;
            }
            this.setState({
              validationState: validationState,
              attemptingSignup: false,
            });
          })
          .catch((reason) => {
            validationState.genericError = true;
            this.setState({
              validationState: validationState,
              attemptingSignup: false,
            });
            setTimeout(() => {
              validationState.genericError = false;
              this.setState({ validationState: validationState });
            }, 5000);
          });
      }
    });
  };

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  getEmailValidationState = (email) => {
    if (email.length === 0) {
      return null;
    }
    return this.validateEmail(email) ? "success" : "error";
  };

  getPasswordValidationState = () => {
    var password = this.state.signupViewModel.password;
    if (!password) {
      return null;
    }
    return password.length >= 4 ? "success" : "error";
  };

  getConfirmPasswordValidationState = () => {
    var confirmPassword = this.state.signupViewModel.confirmPassword;
    if (!confirmPassword) {
      return null;
    }
    return confirmPassword === this.state.signupViewModel.password
      ? "success"
      : "error";
  };

  selectCountry(val) {
    var viewModel = Object.assign({}, this.state.signupViewModel);
    viewModel.country = val;
    this.setState({ signupViewModel: viewModel });
  }

  selectRegion(val) {
    var viewModel = Object.assign({}, this.state.signupViewModel);
    viewModel.region = val;
    this.setState({ signupViewModel: viewModel });
  }

  render() {
    var viewModel = this.state.signupViewModel;
    var attemptingSignup = this.state.attemptingSignup;
    return (
      <div style={{ paddingTop: "25px" }}>
        <div>
          <div>
            <Grid fluid>
              <Row style={{ marginTop: "0px" }}>
                <Col xs={12} className="nopadding" style={{ maxWidth: "100%" }}>
                  <div className="frontPageaxolexdiv Bannecolorlogin">
                    <span>
                      <Icon glyph="icon-fontello-cog-7" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="SignupCreateAccount"
                        defaultValue={"Opret konto"}
                      />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-md"
                        src="/imgs/app/Axologo3-30x30.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-md hidden-xs"
                        src="/imgs/app/Axologo3-35x35.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-xs"
                        src="/imgs/app/Axologo3-40x40.png"
                      />
                      <img
                        alt=""
                        className="hidden-md hidden-sm hidden-xs"
                        src="/imgs/app/Axologo3-40x40.png"
                      />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="nopadding">
                  <div
                    className="text-center"
                    style={{
                      margin: "0px",
                      fontSize: " 15px",
                      paddingBottom: "20px",
                      paddingTop: "15px",
                    }}
                  >
                    <AxoLocal
                      entity="SignupAlreadyhaveaccount"
                      defaultValue={"Har du allerede en konto?"}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Link
                      style={{ color: "cblue" }}
                      to={RoutingService.getAccountPath("Login")}
                    >
                      <Icon glyph="icon-fontello-login" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="LoginonLogin"
                        defaultValue={"Log ind"}
                      />
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={8}
                  mdOffset={2}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <Form>
                    <FormGroup controlId="UserName">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-user" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          autoFocus
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.userName}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "userName"
                          )}
                          componentAttribute="placeholder"
                          entity="bccMailplaceholderBrugernavn"
                        />
                      </InputGroup>
                      {this.state.validationState.invalidUserName ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupvalidationStateinvalidUserName"
                            defaultValue={"Brugernavnet er optaget."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      validationState={this.getEmailValidationState(
                        viewModel.email
                      )}
                      controlId="Email"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-mail" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="email"
                          className="borderFormControlfocus"
                          value={viewModel.email}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "email"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholdersupportAxolex"
                        />
                      </InputGroup>
                      {this.state.validationState.missingEmail ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupEmailrequired"
                            defaultValue={"Email adressen er påkrævet."}
                          />
                        </div>
                      ) : null}
                      {this.state.validationState.duplicateEmail ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupEmailrequired1a"
                            defaultValue={"Email adressen anvendes allerede."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup controlId="Phone">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-phone-3" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.phone}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "phone"
                          )}
                          componentAttribute="placeholder"
                          entity="ContainerPhone"
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup
                      validationState={this.getPasswordValidationState()}
                      controlId="Password"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-key" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="password"
                          className="borderFormControlfocus"
                          value={viewModel.password}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "password"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholderviewModelpassword"
                        />
                      </InputGroup>
                      {this.state.validationState.invalidPassword ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupvalidationStateinvalidPassword"
                            defaultValue={
                              "Kodeordet skal indeholde mindst fire tegn."
                            }
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      validationState={this.getConfirmPasswordValidationState()}
                      controlId="ConfirmPassword"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-key" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="password"
                          className="borderFormControlfocus"
                          value={viewModel.confirmPassword}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "confirmPassword"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholderConfirmpassword"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={8}
                  mdOffset={2}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <br />
                  <Grid fluid>
                    <Row>
                      <Col xs={12} className="nopadding">
                        <Button
                          disabled={this.state.attemptingSignup}
                          className="Lex-button-1xlink"
                          block
                          onClick={this.onCreateUser}
                          style={{
                            borderRadius: "0px",
                            marginLeft: "0px",
                            marginRight: "0px",
                            fontSize: "17px",
                            fontWeight: "normal",
                            height: "65px",
                          }}
                        >
                          <span>
                            {" "}
                            <Icon
                              className="axogryicon"
                              glyph="icon-fontello-user-add"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <AxoLocal
                              entity="Signupcreateanaccount"
                              defaultValue={"Opret bruger"}
                            />
                            <Conditional inline show={attemptingSignup}>
                              &nbsp;
                              <img
                                alt=""
                                src="/imgs/app/loading.gif"
                                className="img-circle"
                                width="20"
                                height="20"
                              />
                            </Conditional>
                          </span>{" "}
                        </Button>
                        <Conditional
                          show={this.state.validationState.genericError}
                        >
                          <div className="text-center axored">
                            <AxoLocal
                              entity="Signupcreateanaccounterror"
                              defaultValue={
                                "An error occurred. Try again later."
                              }
                            />
                          </div>
                        </Conditional>
                      </Col>
                    </Row>
                  </Grid>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={8}
                  mdOffset={2}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <div
                    className="text-center"
                    style={{ padding: "25px", paddingTop: "12.5px" }}
                  >
                    <div style={{ marginTop: "25px", fontSize: " 15px" }}>
                      <AxoLocal
                        entity="SignupAlreadyhaveaccount"
                        defaultValue={"Har du allerede en konto?"}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Link to={RoutingService.getAccountPath("Login")}>
                        <Icon glyph="icon-fontello-login" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="LoginonLogin"
                          defaultValue={"Log ind"}
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignupForm);
