import React from "react";
import MarketingSetupView from "./MarketingSetupView";

import { DataStore } from "../../services/AxoServices";

import { Consumer, LoadingIndicator } from "../../utilities/LexUtilities";

export default class MarketingSetupContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    // DataStore.fetchCardInformation();
    DataStore.initializeFriendRequests();
  }

  render() {
    return (
      <Consumer>
        {(store) => {
          if (!store.userProfile.id || !store.userSettings.id) {
            return <LoadingIndicator />;
          }
          return (
            <MarketingSetupView
              userProfile={store.userProfile}
              userSettings={store.userSettings}
              cardInformation={store.cardInformation}
              friendRequests={store.friendRequests
                .sort((f1, f2) => {
                  return new Date(f1.requestTime) - new Date(f2.requestTime);
                })
                .reverse()}
            />
          );
        }}
      </Consumer>
    );
  }
}
