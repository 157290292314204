import React from "react";
import { withRouter } from "react-router-dom";
import ComposeContainer from "../../routes/Email/ComposeContainer";

class ClientComposeContainer extends React.PureComponent {
  render() {
    return <ComposeContainer clientCompose />;
  }
}

export default withRouter(ClientComposeContainer);
