import React from "react";
import PresentationActions from "../services/PresentationActions";
import PresentationStore from "../services/PresentationStore";
import DataStore from "../../services/DataAccess/DataStore";
import AdminMain from "./AdminMain";

export default class AdminMainContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      presentations: [],
      userProfile: {},
    };
    this.name = "AdminMainContainer";
  }

  componentDidMount() {
    PresentationStore.subscribe(this.name, (store) => {
      var sortedPresentations = store.presentations.sort((c1, c2) => {
        return new Date(c2.creationDate) - new Date(c1.creationDate);
      });
      this.setState({ presentations: sortedPresentations });
    });
    DataStore.subscribe(this.name, (store) => {
      this.setState({ userProfile: store.userProfile });
    });
    PresentationStore.fetchPresentations();
    DataStore.initializeUserProfile();
  }

  componentWillUnmount() {
    PresentationStore.unsubscribe(this.name);
    DataStore.unsubscribe(this.name);
  }

  onTogglePublic = (presentationId) => {
    var presentation = this.state.presentations.find(
      (p) => p.id === presentationId
    );
    if (!presentation) {
      return;
    }
    var newPresentation = Object.assign({}, presentation);
    newPresentation.public = !presentation.public;
    PresentationActions.updatePresentation(newPresentation);
  };

  render() {
    return (
      <AdminMain
        presentations={this.state.presentations}
        userProfile={this.state.userProfile}
        onTogglePublic={this.onTogglePublic}
      />
    );
  }
}
