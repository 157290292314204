import React from // useEffect // useState,
"react";

import { DataStore } from "../services/AxoServices";

import {
  withRouter,
  Flexbox,
  AxoLocal,
  FlagMenu,
  AxoFooter,
} from "../utilities/LexUtilities";
import MitIDView from "../utilities/MitID/MitIDView";

function MitIDLoginView(props) {
  const redirectByRole = (role) => {
    DataStore.resetStore();

    let subPath = "";
    if (role === "Lawyer" || role === "Accountant" || role === "Admin") {
      subPath = "/Dashboard";
    } else if (
      role === "Client" ||
      role === "AccountingClient" ||
      role === "SocietyMember" ||
      role === "EasyID"
    ) {
      subPath = "/ClientDashboard";
    } else if (role === "XLink") {
      subPath = "/Xlink";
    } else if (role === "Society") {
      subPath = "/Dashboard";
    }

    //Necessary to reset the Easy ID session
    window.location.href =
      window.location.protocol + "//" + window.location.host + subPath;
  };

  return (
    <div className="login SideHeight" style={{ paddingTop: "10px" }}>
      <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center">
        <AxoLocal
          entity="axoEntityidcode5"
          defaultValue={"Log ind med MitID"}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-md"
          src="/imgs/app/Axologo3-30x30.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-md hidden-xs"
          src="/imgs/app/Axologo3-35x35.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        <img
          alt=""
          className="hidden-md hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FlagMenu
          // className='Lex-button-2'
          userSettings={{}}
        />
      </div>
      <Flexbox justifyCenter className="topPadding">
        <MitIDView
          loginUserAccount
          onLogin={(result) => redirectByRole(result.role)}
        />
      </Flexbox>
      <AxoFooter />
    </div>
  );
}

export default withRouter(MitIDLoginView);
