import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import DataActions from "../../services/DataAccess/DataActions";
import moment from "moment";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import AccountingStore from "../../services/AccountingStore";
import { debounce } from "lodash";
import * as $ from "jquery";
import { withRouter } from "react-router-dom";

import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";

import {
  Icon,
  InlineEdit,
  InlineNumberEdit,
  AxoCheckbox,
  LexButton,
  FileViewerModal,
  AxoLocal,
} from "../../utilities/LexUtilities";

class PostingCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  renderEditForm = () => {
    var { posting, accountTypes, accounts } = this.props;

    return (
      <div
        style={{
          textAlign: "left",
          fontSize: "15px",
          fontWeight: "normal",
          padding: "3px",
        }}
      >
        <table
          className="Lex-Grid-tabl-1"
          style={{ padding: "0px", marginBottom: "0px" }}
        >
          <tbody style={{ padding: "0px" }}>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  key="creationDate"
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
                :{" "}
              </th>
              <td>
                <input
                  style={{ borderStyle: "none" }}
                  type="date"
                  onChange={this.onDateChange.bind(this, posting.id)}
                  value={moment(posting.date).format("YYYY-MM-DD")}
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity12"
                  defaultValue={"Kontotype"}
                />
                :{" "}
              </th>
              <td>
                <select
                  className="axoblue selectbg"
                  style={{ height: "30px", width: "150px" }}
                  value={posting.postingAccountTypeId || 0}
                  name="postingAccountTypeId"
                  onChange={(event) =>
                    this.onUpdatePostingProperty(event, posting.id)
                  }
                >
                  <option value={0}>
                    <AxoLocal
                      entity="SubscriptionViewPricingfreetryN"
                      defaultValue={"Ingen"}
                    />
                  </option>
                  {accountTypes.map((t) => {
                    return (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity13"
                  defaultValue={"Konto"}
                />
                :{" "}
              </th>
              <td>
                <select
                  className="axoblue selectbg"
                  style={{ height: "30px", width: "150px" }}
                  value={posting.postingAccountId || 0}
                  name="postingAccountId"
                  onChange={(event) =>
                    this.onUpdatePostingProperty(event, posting.id)
                  }
                >
                  <option value={0}>
                    <AxoLocal
                      entity="SubscriptionViewPricingfreetryN"
                      defaultValue={"Ingen"}
                    />
                  </option>
                  {accounts
                    .filter(
                      (a) =>
                        a.postingAccountTypeId === posting.postingAccountTypeId
                    )
                    .map((t) => {
                      return (
                        <option key={t.id} value={t.id}>
                          {t.number} - {t.name}
                        </option>
                      );
                    })}
                </select>
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />:{" "}
              </th>
              <td>
                <InlineEdit
                  value={posting.description}
                  change={(input) =>
                    this.onUpdateInlinePostingProperty(
                      "description",
                      input.value,
                      posting.id
                    )
                  }
                  propName="value"
                  className="editable"
                  editProps={{ style: { width: "100%" } }}
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity24"
                  defaultValue={"Bank"}
                />
                :{" "}
              </th>
              <td>
                <InlineNumberEdit
                  value={posting.bankAccount}
                  format={(value) => {
                    return <span>{value}</span>;
                  }}
                  change={(input) =>
                    this.onUpdateInlinePostingProperty(
                      "bankAccount",
                      input.value,
                      posting.id
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity16"
                  defaultValue={"Indtægt"}
                />
                :{" "}
              </th>
              <td>
                <InlineNumberEdit
                  value={posting.income}
                  format={(value) => {
                    return <span>{value}</span>;
                  }}
                  change={(input) =>
                    this.onUpdateInlinePostingProperty(
                      "income",
                      input.value,
                      posting.id
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity17"
                  defaultValue={"Udgifter"}
                />
                :{" "}
              </th>
              <td>
                <InlineNumberEdit
                  value={posting.expenses}
                  format={(value) => {
                    return <span>{value}</span>;
                  }}
                  change={(input) =>
                    this.onUpdateInlinePostingProperty(
                      "expenses",
                      input.value,
                      posting.id
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity18"
                  defaultValue={"Lån"}
                />
                :{" "}
              </th>
              <td>
                <InlineNumberEdit
                  value={posting.debt}
                  format={(value) => {
                    return <span>{value}</span>;
                  }}
                  change={(input) =>
                    this.onUpdateInlinePostingProperty(
                      "debt",
                      input.value,
                      posting.id
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity19"
                  defaultValue={"Diverse"}
                />{" "}
                :
              </th>
              <td>
                <InlineNumberEdit
                  value={posting.misc}
                  format={(value) => {
                    return <span>{value}</span>;
                  }}
                  change={(input) =>
                    this.onUpdateInlinePostingProperty(
                      "misc",
                      input.value,
                      posting.id
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity20"
                  defaultValue={"Renter"}
                />
                :{" "}
              </th>
              <td>
                <InlineNumberEdit
                  value={posting.interest}
                  format={(value) => {
                    return <span>{value}</span>;
                  }}
                  change={(input) =>
                    this.onUpdateInlinePostingProperty(
                      "interest",
                      input.value,
                      posting.id
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity21"
                  defaultValue={"Indgående moms"}
                />
                :{" "}
              </th>
              <td>
                <InlineNumberEdit
                  value={posting.incomingVat}
                  format={(value) => {
                    return <span>{value}</span>;
                  }}
                  change={(input) =>
                    this.onUpdateInlinePostingProperty(
                      "incomingVat",
                      input.value,
                      posting.id
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity22"
                  defaultValue={"Udgående moms"}
                />
                :{" "}
              </th>
              <td>
                <InlineNumberEdit
                  value={posting.outgoingVat}
                  format={(value) => {
                    return <span>{value}</span>;
                  }}
                  change={(input) =>
                    this.onUpdateInlinePostingProperty(
                      "outgoingVat",
                      input.value,
                      posting.id
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                &nbsp;&nbsp;
                <AxoLocal
                  entity="AccountingTabViewEntity23A"
                  defaultValue={"Udenfor EU"}
                />
                :{" "}
              </th>
              <td>
                <AxoCheckbox
                  checked={posting.withinEU}
                  onChange={(event) =>
                    this.onUpdateInlinePostingProperty(
                      "withinEU",
                      event.target.checked,
                      posting.id
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <th className=" col-md-4 col-xs-3">
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedlabelAddedToCasedocuments"
                  defaultValue={"Bilag"}
                />
                :{" "}
              </th>
              <td>
                {!!posting.attachment ? (
                  <Icon
                    className="editable"
                    role="button"
                    onClick={() => this.onShowAttachment(posting.attachment)}
                    glyph="icon-fontello-attach-7"
                  />
                ) : (
                  <LexButton
                    onClick={() => this.props.onUploadAttachment(posting.id)}
                  >
                    <AxoLocal
                      entity="DocumentTabViewUpload"
                      defaultValue={"Upload"}
                    />
                  </LexButton>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  onUpdatePostingProperty(event, id) {
    const target = event.target;
    const name = target.name;

    var posting = Object.assign({}, this.props.posting);
    posting[name] = target.value;
    DataActions.updatePosting(posting);
  }

  onUpdateInlinePostingProperty(propertyName, value, id) {
    var posting = Object.assign({}, this.props.posting);
    posting[propertyName] = value;
    DataActions.updatePosting(posting);
  }

  onDateChange = (id, event) => {
    var posting = Object.assign({}, this.props.posting);
    posting.date = new moment(event.target.valueAsDate).format();
    AccountingStore.updateArrayObject(posting, "postings"); //Optimistic updating
    this.debouncedUpdate(posting);
  };

  debouncedUpdate = debounce((posting) => {
    DataActions.updatePosting(posting);
  }, 1000);

  renderInformation = () => {
    var { posting } = this.props;

    return (
      <div style={{ padding: "0px" }}>
        <table
          className="table Lex-Grid-tabl"
          style={{ padding: "0px", marginBottom: "0px" }}
        >
          <tbody style={{ padding: "0px" }}>
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  key="creationDate"
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
                :{" "}
              </th>
              <td>{moment(posting.date).format("L")}</td>
            </tr>
            {!!posting.accountType ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="AccountingTabViewEntity12"
                    defaultValue={"Kontotype"}
                  />
                  :{" "}
                </th>
                <td>{posting.accountType.name}</td>
              </tr>
            ) : null}
            {!!posting.account ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="AccountingTabViewEntity13"
                    defaultValue={"Konto"}
                  />
                  :{" "}
                </th>
                <td>
                  {posting.account.number} - {posting.account.name}
                </td>
              </tr>
            ) : null}
            {!!posting.description ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />:{" "}
                </th>
                <td>{posting.description}</td>
              </tr>
            ) : null}
            {!!posting.bankAccount ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="AccountingTabViewEntity24"
                    defaultValue={"Bank"}
                  />{" "}
                  :{" "}
                </th>
                <td>{posting.bankAccount}</td>
              </tr>
            ) : null}
            {!!posting.income ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="AccountingTabViewEntity16"
                    defaultValue={"Indtægt"}
                  />
                  :{" "}
                </th>
                <td>{posting.income}</td>
              </tr>
            ) : null}
            {!!posting.income ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="AccountingTabViewEntity17"
                    defaultValue={"Udgifter"}
                  />
                  :{" "}
                </th>
                <td>{posting.expenses}</td>
              </tr>
            ) : null}
            {!!posting.debt ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="AccountingTabViewEntity18"
                    defaultValue={"Lån"}
                  />
                  :{" "}
                </th>
                <td>{posting.debt}</td>
              </tr>
            ) : null}
            {!!posting.misc ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <Icon glyph="icon-fontello-eq-outline" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="AccountingTabViewEntity19"
                    defaultValue={"Diverse"}
                  />{" "}
                  :{" "}
                </th>
                <td>{posting.misc}</td>
              </tr>
            ) : null}
            {!!posting.interest ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="AccountingTabViewEntity20"
                    defaultValue={"Renter"}
                  />
                  :{" "}
                </th>
                <td>{posting.interest}</td>
              </tr>
            ) : null}
            {!!posting.incomingVat ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="AccountingTabViewEntity21"
                    defaultValue={"Indgående moms"}
                  />
                  :{" "}
                </th>
                <td>{posting.incomingVat}</td>
              </tr>
            ) : null}
            {!!posting.outgoingVat ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="AccountingTabViewEntity22"
                    defaultValue={"Udgående moms"}
                  />
                  :{" "}
                </th>
                <td>{posting.outgoingVat}</td>
              </tr>
            ) : null}
            <tr>
              <th className=" col-md-4 col-xs-3">
                <AxoLocal
                  entity="AccountingTabViewEntity23B"
                  defaultValue={"EU"}
                />
                :{" "}
              </th>
              <td>
                {posting.withinEU ? (
                  <span>
                    {" "}
                    <AxoLocal
                      key="without"
                      entity="AccountingTabViewEntity23"
                      defaultValue={" Idenfor EU"}
                    />
                  </span>
                ) : (
                  <span>
                    {" "}
                    <AxoLocal
                      key="within"
                      entity="AccountingTabViewEntity23A"
                      defaultValue={"Udenfor EU"}
                    />
                  </span>
                )}
              </td>
            </tr>
            {!!posting.attachment ? (
              <tr>
                <th className=" col-md-4 col-xs-3">
                  <AxoLocal
                    entity="CaseDataTableFixedlabelAddedToCasedocuments"
                    defaultValue={"Bilag"}
                  />{" "}
                  :{" "}
                </th>
                <td>
                  <Icon
                    className="editable"
                    role="button"
                    onClick={() => this.onShowAttachment(posting.attachment)}
                    glyph="icon-fontello-attach-7"
                  />
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  };

  onShowAttachment = (attachment) => {
    this.fileModal.open([attachment]);
  };

  renderContent = () => {
    if (this.state.editing) {
      return this.renderEditForm();
    }
    return this.renderInformation();
  };

  onEditButton = () => {
    this.setState({ editing: !this.state.editing });
  };

  onRemovePosting = (id) => {
    DataActions.deletePosting(id);
  };

  render() {
    var posting = this.props.posting;
    return (
      <div>
        <div
          style={{
            marginBottom: "25px",
            borderRadius: "0px",
            borderWidth: "1px 1px 1px 1px",
            borderStyle: "solid solid solid solid",
            borderColor: "#DCDCDC #DCDCDC #DCDCDC #DCDCDC",
            boxShadow: "10px 10px 9px #ececec",
          }}
        >
          <div style={{ padding: "0px" }}>
            <div
              className="gradient-baggrund"
              style={{ paddingLeft: "2px", paddingRight: "5px" }}
            >
              <Grid fluid style={{ paddingLeft: "15px" }}>
                <Row>
                  <Col
                    xs={12}
                    className="text-center"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <span
                      style={{
                        float: "left",
                        paddingTop: "5px",
                        paddingBottom: "2px",
                        paddingLeft: "25px",
                      }}
                    >
                      <img
                        alt=""
                        src="/imgs/app/avatars/avatar.jpg"
                        className="img-circle"
                        width="45"
                        height="45"
                      />
                    </span>
                    <div style={{ lineHeight: "50px" }}>
                      {moment(posting.date).format("LL")}
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div style={{ paddingLeft: "5px", paddingRight: "0px" }}>
              <Grid fluid style={{ padding: "5px" }}>
                <Row>
                  <Col xs={12}>{this.renderContent()}</Col>
                </Row>
              </Grid>
            </div>
            <div
              className="Lex-CardFooter"
              style={{ paddingLeft: "5px", paddingRight: "15px" }}
            >
              <Grid fluid style={{ paddingLeft: "3px", paddingRight: "0px" }}>
                <Row>
                  <Col xs={12} style={{ paddingRight: "9px" }}>
                    <ButtonGroup justified>
                      <ButtonGroup>
                        {this.state.editing ? (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon glyph="icon-fontello-floppy-1" />
                            &nbsp;&nbsp;{" "}
                            <AxoLocal
                              key="Save"
                              entity="CaseGridViewcaseSave"
                              defaultValue={"Gem"}
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onEditButton}
                          >
                            <Icon glyph="icon-fontello-edit-3" />
                            &nbsp;&nbsp;
                            <AxoLocal
                              key="Edit"
                              entity="CaseGridViewedit"
                              defaultValue={"Rediger"}
                            />
                          </button>
                        )}
                      </ButtonGroup>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.onRemovePosting}
                        >
                          <AxoLocal
                            entity="AccountingTabViewEntity62"
                            defaultValue={" Slet postering"}
                          />
                        </button>
                      </ButtonGroup>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
      </div>
    );
  }
}

class PostingsGridView extends React.PureComponent {
  //Props
  //postings
  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateCols = (entries) => {
    var { accounts, accountTypes } = this.props;

    var cols = [[], [], []];
    var smallCols = [[], []];
    for (var i = 0; i < entries.length; i++) {
      var contactCard = (
        <PostingCard
          key={entries[i].id}
          posting={entries[i]}
          accountTypes={accountTypes}
          accounts={accounts}
          onUploadAttachment={this.onUploadAttachment}
        />
      );

      cols[i % 3].push(contactCard);
      smallCols[i % 2].push(contactCard);
    }
    return [cols, smallCols];
  };

  onUploadAttachment = (postingId) => {
    $("#attachmentUpload").trigger("click");
    this.uploadingId = postingId;
  };

  onUploadedAttachment = (event) => {
    var uploader = event.target;
    if (uploader.files.length === 0) {
      return;
    }
    ApiService.uploadDocuments(uploader.files)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        return Promise.reject(new Error("Upload failed"));
      })
      .then((fileModels) => {
        var fileModel = fileModels[0];
        var posting = Object.assign(
          {},
          this.props.postings.find((p) => p.id === this.uploadingId)
        );
        if (!posting) {
          return Promise.reject("Posting could not be found.");
        }
        posting.attachmentId = fileModel.id;
        return DataActions.updatePosting(posting);
      });
    uploader.value = "";
  };

  renderGrid = (entries) => {
    var cols = this.generateCols(entries);
    var largeCols = cols[0];
    var smallCols = cols[1];

    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[0]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[1]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  getShownItems = () => {
    return this.props.postings.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    return (
      <Grid fluid style={{ minHeight: "800px" }}>
        <Row>
          <Col xs={12} className="nopadding">
            {this.renderGrid(this.getShownItems())}
          </Col>
        </Row>
        {this.state.numberShown < this.props.postings.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col xs={12} className="text-center" style={{ paddingTop: "10px" }}>
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2"
                style={{ width: "75%" }}
              >
                <AxoLocal
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </Button>
            </Col>
          </Row>
        ) : null}
        <input
          name="attachment"
          onChange={this.onUploadedAttachment}
          type="file"
          id="attachmentUpload"
          style={{ display: "none" }}
        />
      </Grid>
    );
  }
}

export default withRouter(PostingsGridView);
