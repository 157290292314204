import React from "react";
import DataStore from "../../services/DataAccess/DataStore";
import ApplicationUDTabView from "./ApplicationUDTabView";

import { Consumer } from "../../utilities/LexUtilities";

export default class ApplicationUDContainer extends React.PureComponent {
  componentDidMount() {
    //Fetch data on page load
    DataStore.fetchApplicationUDs();
  }

  componentWillUnmount() {
    //Do stuff when navigating away
  }

  //Filter out needed data from DataStore.js
  mapStoreToProps = (store) => {
    return {
      applicationUDs: store.applicationUDs,
      showDropdowns: store.showDropdowns,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => {
          let props = this.mapStoreToProps(store);
          return <ApplicationUDTabView {...props} />;
        }}
      </Consumer>
    );
  }
}
