import ApiService from "./DataAccess/ApiService";

export default {
  getLogoImageSource(userProfile) {
    if (userProfile.logoLibrary) {
      return "/imgs/app/library/" + userProfile.logoLibrary;
    }
    if (userProfile.logo) {
      return ApiService.getFileInlinePath(userProfile.logo.id);
    }
    return "/svg/avatar111-12.svg";
  },
  getMyProfileImageSource(userProfile) {
    if (!userProfile) {
      return "";
    }
    if (userProfile.profileImageLibrary) {
      return "/imgs/app/library/" + userProfile.profileImageLibrary;
    }
    if (userProfile.profileImage) {
      return ApiService.getFileInlinePath(userProfile.profileImage.id);
    }
    return "/svg/avatar111-12.svg";
  },
  getProfileImageSource(userProfile) {
    if (!userProfile) {
      return "";
    }
    if (userProfile.profileImageLibrary) {
      return "/imgs/app/library/" + userProfile.profileImageLibrary;
    }
    if (userProfile.profileImage) {
      return ApiService.getProfileImageInlinePath(
        userProfile.id,
        userProfile.profileImage.id
      );
    }
    return "/svg/avatar111-12.svg";
  },
  getBannerImageSource(userProfile) {
    if (userProfile.bannerLibrary) {
      return "/imgs/app/library/" + userProfile.bannerLibrary;
    }
    if (userProfile.banner) {
      return ApiService.getBannerImageInlinePath(
        userProfile.id,
        userProfile.banner.id
      );
    }
    return "/imgs/app/shots/banner.jpg";
  },

  getDisplayName(userProfile) {
    if (!userProfile) {
      return "";
    }
    if (userProfile.displayName) {
      return userProfile.displayName;
    }
    if (userProfile.showRealName) {
      return userProfile.firstName + " " + userProfile.lastName;
    }
    return userProfile.userName;
  },

  getShortDisplayName(userProfile) {
    if (!userProfile) {
      return "";
    }
    if (userProfile.displayName) {
      return userProfile.displayName;
    }
    if (userProfile.showRealName) {
      return userProfile.firstName;
    }
    return userProfile.userName;
  },

  getCurrency(userProfile) {
    return userProfile.currency || "USD";
  },
};
