import React, {
  useState,
  // , useReducer
} from "react";
import NumericInput from "react-numeric-input";
import ReactSelect from "react-select";
import moment from "moment";

import {
  Form,
  ControlLabel,
  FormGroup,
  FormControl,
  ButtonGroup,
  // InputGroup,
} from "react-bootstrap";

import {
  // Icon,
  AxoLocal,
  // AsyncButton,
  getText,
  AxoDateTime,
  AxoCheckbox,
  AsyncButton,
  LexButton,
} from "../../utilities/LexUtilities";

// function reducer(state, newValues) {
//   return {
//     ...state,
//     ...newValues
//   }
//   // if(action.propertyName === 'All') {
//   //   return action.value;
//   // }
//   // return {
//   //   [action.propertyName]: action.value
//   // }
//   // switch (action.type) {
//   //   case 'increment':
//   //     return {count: state.count + 1};
//   //   case 'decrement':
//   //     return {count: state.count - 1};
//   //   default:
//   //     throw new Error();
//   // }
// }

let outstandingAmount, setAmount;
let description, setDescription;
let activeSubscription, setActiveSubscription;
let startDate, setStartDate;
let subscriptionInterval, setInterval;
let chargeFirst, setChargeFirst;

const SubscriptionInfoForm = function ({ startState, onSubmit }) {
  // const [invoice, dispatch] = useReducer(reducer, startState);

  [outstandingAmount, setAmount] = useState(startState.outstandingAmount);
  [description, setDescription] = useState(startState.description);
  [activeSubscription, setActiveSubscription] = useState(
    startState.activeSubscription
  );
  [startDate, setStartDate] = useState(startState.startDate);
  [subscriptionInterval, setInterval] = useState(
    startState.subscriptionInterval
  );
  [chargeFirst, setChargeFirst] = useState(startState.chargeFirst);

  const getPeriodOptions = () => {
    return [
      { value: "Day", label: getText("AdminTabView9") }, //Dag
      { value: "Week", label: getText("AdminTabView10") }, //Uge
      { value: "Month", label: getText("AdminTabView11") }, //Måned
      { value: "Year", label: getText("AdminTabView12") }, //År
    ];
  };

  const onCancel = () => {
    setAmount(startState.outstandingAmount);
    setDescription(startState.description);
    setActiveSubscription(startState.activeSubscription);
    setStartDate(startState.startDate);
    setInterval(startState.subscriptionInterval);
    setChargeFirst(startState.chargeFirst);
  };

  let periodOptions = getPeriodOptions();
  let startOfToday = moment.utc().startOf("day");

  return (
    <Form>
      <ControlLabel
        style={{
          textAlign: "left",
          fontSize: "16px",
          fontWeight: "normal",
          paddingTop: "10px",
        }}
      >
        <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />
      </ControlLabel>
      <NumericInput
        className="form-control"
        onChange={(value) => setAmount(value)}
        step={1}
        precision={2}
        value={outstandingAmount}
      />
      <FormGroup>
        <ControlLabel
          style={{ textAlign: "left", fontSize: "16px", fontWeight: "normal" }}
        >
          <AxoLocal
            entity="axoEntityidcode194"
            defaultValue="Beskrivelse af abonnement"
          />
        </ControlLabel>
        <FormControl
          componentClass="textarea"
          rows={3}
          placeholder={"Månedlig betaling af medlemskontingent"}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <AxoCheckbox
          checked={activeSubscription}
          onChange={(event) => setActiveSubscription(event.target.checked)}
        />
        &nbsp; Automatisk opkrævning
      </FormGroup>
      {activeSubscription && (
        <>
          <FormGroup controlId="startDate">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              Næste opkrævning
            </ControlLabel>
            <AxoDateTime
              utc
              value={startDate}
              dateFormat={true}
              timeFormat={false}
              onChange={(selectedDate) =>
                setStartDate(selectedDate.startOf("day"))
              }
              isValidDate={(currentDate) => {
                if (!activeSubscription) {
                  return false;
                }

                return !currentDate.isBefore(startOfToday);
              }}
            />
          </FormGroup>
          <FormGroup controlId="interval" bsSize="large">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="AdminTabView8" defaultValue="Interval" />
            </ControlLabel>
            <ReactSelect
              name="form-field-name"
              menuPlacement="auto"
              isDisabled={!activeSubscription}
              value={periodOptions.find(
                (p) => p.value === subscriptionInterval
              )}
              onChange={(selection, arg) => {
                if (!selection) {
                  return;
                }
                setInterval(selection.value);
              }}
              noOptionsMessage={() => ""}
              options={periodOptions}
            />
          </FormGroup>
          <FormGroup title="Der vil blive opkrævet betaling når medlemmet tilmelder sig og igen ved den kommende opkrævningsdato.">
            <AxoCheckbox
              disabled={!activeSubscription}
              checked={chargeFirst}
              onChange={(event) => setChargeFirst(event.target.checked)}
            />
            &nbsp; Opkræv ved tilmelding
          </FormGroup>
        </>
      )}
      <ButtonGroup>
        <AsyncButton
          onClick={() =>
            onSubmit({
              outstandingAmount,
              description,
              activeSubscription,
              startDate,
              subscriptionInterval,
              chargeFirst,
            })
          }
        >
          Godkend
        </AsyncButton>
        <LexButton onClick={onCancel}>Fortryd</LexButton>
      </ButtonGroup>
    </Form>
  );
};

export default SubscriptionInfoForm;
