import React, { 
  useState, 
  // useEffect
} from 'react';

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
  Grid,
} from "react-bootstrap";

import {
  LexButton,
  AsyncButton,
  AxoLocal,
} from "../utilities/LexUtilities";

import {
  useGetRealEstateDataFromAddressQuery,
  useGetRealEstateDetailsQuery,
  useGetCoopDataFromAddressQuery,
  useGetCoopDetailsQuery,
  useGetCompanyDocumentsQuery,
  useGetCVRParticipantByNameQuery,
  useGetVehicleByChassisQuery,
  useGetDocumentByIdQuery
} from "../services/DataAccess/ApiSlice";

const startQueryParams = { floor: "", streetNumber: "", suiteNumber: "", postalCode: "", streetName: "", bestemtFastNummer: "" }

const TinglysningsView = () => {
  const [floor, setFloor] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [suiteNumber, setSuiteNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [bestemtFastNummer, setBestemtFastNummer] = useState("");

  const [addressQueryParams, setAddressQueryParams] = useState(startQueryParams);
  const [uuid, setUuid] = useState("");

  const [coopAddressQueryParams, setCoopAddressQueryParams] = useState(startQueryParams);
  const [coopUuid, setCoopUuid] = useState("");

  const [cvr, setCvr] = useState("");
  const [cvrQuery, setCvrQuery] = useState("");

  const [cvrParticipantName, setCvrParticipantName] = useState("");
  const [cvrParticipantNameQueryString, setCvrParticipantNameQueryString] = useState("");

  const [chassisNumber, setChassisNumber] = useState("");
  const [chassisQuery, setChassisQuery] = useState("");

  const [documentId, setDocumentId] = useState("");
  const [documentQueryText, setDocumentQueryText] = useState("");

  // const [queryResult, setQueryResult] = useState({ items: [] });
  // const [detailsResult, setDetailsResult] = useState("");

  const addressQuery = useGetRealEstateDataFromAddressQuery(addressQueryParams);
  const detailsQuery = useGetRealEstateDetailsQuery(uuid);
  const coopAddressQuery = useGetCoopDataFromAddressQuery(coopAddressQueryParams);
  const coopDetailsQuery = useGetCoopDetailsQuery(coopUuid);
  const companyQuery = useGetCompanyDocumentsQuery(cvrQuery);
  const cvrParticipantQuery = useGetCVRParticipantByNameQuery(cvrParticipantNameQueryString, { skip: !cvrParticipantNameQueryString });
  const vehicleQuery = useGetVehicleByChassisQuery(chassisQuery, { skip: !chassisQuery });
  const documentQuery = useGetDocumentByIdQuery(documentQueryText, { skip: !documentQueryText });

  const onSubmit = () => {
    setAddressQueryParams({
      floor,
      streetNumber,
      postalCode,
      suiteNumber,
      streetName,
      bestemtFastNummer
    });
    setCoopAddressQueryParams(startQueryParams);

    setCvrQuery("");
    setUuid("");
    setCoopUuid("");
  }

  const onGetDetails = (uuid) => {
    setUuid(uuid);
    setCvrQuery("");
    setAddressQueryParams(startQueryParams);
  }

  const onSubmitCoop = () => {
    setCoopAddressQueryParams({
      floor,
      streetNumber,
      postalCode,
      suiteNumber,
      streetName,
      bestemtFastNummer
    });
    setAddressQueryParams(startQueryParams);

    setCvrQuery("");
    setCoopUuid("");
    setUuid("");
  }

  const onGetCoopDetails = (uuid) => {
    setCoopUuid(uuid);
    setUuid("");
    setCvrQuery("");
    setCoopAddressQueryParams(startQueryParams);
  }

  const onGetCompanyDocuments = () => {
    setCvrQuery(cvr);
    setUuid("");
    setCoopUuid("");
    setAddressQueryParams(startQueryParams);
    setCoopAddressQueryParams(startQueryParams);
  }

  const onGetCVRParticipant = () => {
    setCvrQuery(cvr);
    setUuid("");
    setCoopUuid("");
    setAddressQueryParams(startQueryParams);
    setCoopAddressQueryParams(startQueryParams);

    setCvrParticipantNameQueryString(cvrParticipantName);
  }

  const onQueryVehicle = () => {
    setChassisQuery(chassisNumber);
  }

  const onQueryDocument = () => {
    setDocumentQueryText(documentId);
  }

  let queryResult = addressQuery.data || { item: [] };
  let detailsResult = detailsQuery.data || "";

  let coopQueryResult = coopAddressQuery.data || { item: [] };
  let coopDetailsResult = coopDetailsQuery.data || "";

  let companyResults = companyQuery.data || "";

  let cvrParticipantResults = cvrParticipantQuery.data || "";

  let vehicleResults = vehicleQuery.data ||  { item: [] };

  let documentResults = documentQuery.data || "";

  return (
    <Grid>
      <h2>Tinglysning</h2>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
        className="leftPadding standardMaxWidth">
        <FormGroup controlId="text">
          <ControlLabel>
            Vejnavn*
          </ControlLabel>
          <FormControl
            type="text"
            value={streetName}
            onChange={e => setStreetName(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="text">
          <ControlLabel>
            Postnummer*
          </ControlLabel>
          <FormControl
            type="text"
            value={postalCode}
            onChange={e => setPostalCode(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="text">
          <ControlLabel>
            Husnummer (krævet for andelsbolig)
          </ControlLabel>
          <FormControl
            type="text"
            value={streetNumber}
            onChange={e => setStreetNumber(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="text">
          <ControlLabel>
            Etage
          </ControlLabel>
          <FormControl
            type="text"
            value={floor}
            onChange={e => setFloor(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="text">
          <ControlLabel>
            Sidedør
          </ControlLabel>
          <FormControl
            type="text"
            value={suiteNumber}
            onChange={e => setSuiteNumber(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="text">
          <ControlLabel>
            Eller
          </ControlLabel>
        </FormGroup>
        <FormGroup controlId="text">
          <ControlLabel>
            Bestemt Fast Ejendomsnummer (BFE) (Eks: 10051129)
          </ControlLabel>
          <FormControl
            type="text"
            value={bestemtFastNummer}
            onChange={e => setBestemtFastNummer(e.target.value)}
          />
        </FormGroup>
        <ButtonToolbar>
          <LexButton 
            onClick={onSubmit}
            disabled={addressQuery.isFetching}
          >
            Søg ejendom
          </LexButton>
          <LexButton 
            onClick={onSubmitCoop}
            disabled={coopAddressQuery.isFetching}
          >
            Søg andelsbolig
          </LexButton>
          <LexButton onClick={() => {
            setFloor("");
            setStreetNumber("");
            setPostalCode("");
            setSuiteNumber("");
            setStreetName("");
          }}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </Form>
      { !!queryResult.items && queryResult.items.length > 0 && (
        <>
          <h3 className="bold">{queryResult.items.length} resultater</h3>
          {queryResult.items.map((item, index) => (
            <div key={item.uuid} className="topPadding">
              <h4 className="bold">{index + 1}</h4>
              <div className="bold">Adresse</div>
              <div>{item.adresse}</div>
              <div>{item.vedroerende}</div>
              <div className="bold">Vurdering</div>
              <div>{item.ejendomsVurdering} kr.</div>
              <div className="bold">Grundværdi</div>
              <div>{item.grundVaerdi} kr.</div>
              <div className="bold">Vurderingsdato</div>
              <div>{item.vurderingsDato}</div>
              <div className="bold">Ejendomsnummer</div>
              <div>{item.ejendomsnummer}</div>
              <div className="bold">Kommunenummer</div>
              <div>{item.kommuneNummer}</div>
              <div>
              <AsyncButton hideOkIcon onClick={() => onGetDetails(item.uuid)}>
                Se detaljer
              </AsyncButton>
              </div>
            </div>
          ))}
        </>
      )}
      {!!detailsResult && (
        <>
          <h3>Ejendomsdetaljer</h3>
          <pre>{detailsResult}</pre>
        </>
      )}

      { !!coopQueryResult.items && coopQueryResult.items.length > 0 && (
        <>
          <h3 className="bold">{coopQueryResult.items.length} resultater</h3>
          {coopQueryResult.items.map((item, index) => (
            <div key={item.uuid} className="topPadding">
              <h4 className="bold">{index + 1}</h4>
              <div className="bold">Adresse</div>
              <div>{item.vejnavn} {item.husnummer} {item.etage} {item.side}</div>
              <div>{item.postnummer} {item.postdistrikt}</div>
              <div className="bold">Kommune</div>
              <div>{item.kommunenavn}</div>
              <div className="bold">Kommunenummer</div>
              <div>{item.kommunenummer}</div>
              <div>
              <AsyncButton hideOkIcon onClick={() => onGetCoopDetails(item.uuid)}>
                Se detaljer
              </AsyncButton>
              </div>
            </div>
          ))}
        </>
      )}
      {!!coopDetailsResult && (
        <>
          <h3>Andelsbolig detaljer</h3>
          <pre>{coopDetailsResult}</pre>
        </>
      )}

      <h2>Selskab</h2>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          onGetCompanyDocuments();
        }}
        className="leftPadding standardMaxWidth">
        <FormGroup controlId="text">
          <ControlLabel>
            CVR*
          </ControlLabel>
          <FormControl
            type="text"
            value={cvr}
            onChange={e => setCvr(e.target.value)}
          />
        </FormGroup>
        <ButtonToolbar>
          <LexButton 
            onClick={onGetCompanyDocuments}
            disabled={companyQuery.isFetching}
          >
            Søg
          </LexButton>
          <LexButton onClick={() => {
            setCvr("");
          }}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </Form>
      {!!companyResults && (
        <>
          <h3>Selskabsdokumenter</h3>
          <pre>{JSON.stringify(JSON.parse(companyResults), null, 2)}</pre>
        </>
      )}

      <h2>Person</h2>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          onGetCVRParticipant();
        }}
        className="leftPadding standardMaxWidth">
        <FormGroup controlId="text">
          <ControlLabel>
            Navn*
          </ControlLabel>
          <FormControl
            type="text"
            value={cvrParticipantName}
            onChange={e => setCvrParticipantName(e.target.value)}
          />
        </FormGroup>
        <ButtonToolbar>
          <LexButton 
            onClick={onGetCVRParticipant}
            disabled={cvrParticipantQuery.isFetching}
          >
            Søg
          </LexButton>
          <LexButton onClick={() => {
            setCvrParticipantName("");
            setCvrParticipantNameQueryString("");
          }}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </Form>
      { !!cvrParticipantResults && cvrParticipantResults.length > 0 && (
        <>
          <h3 className="bold">{cvrParticipantResults.length} resultater</h3>
          {cvrParticipantResults.map((item, index) => (
            <div key={index} className="topPadding">
              <h4 className="bold">{index + 1}</h4>
              <div className="bold">{item.name} </div>
              <div>{item.address1}</div>
              <div>{item.address2}</div>
              <div>{(item.companyResults || []).map(companyName => (
                <div className="bold">{companyName}</div>
              ))}
              {/* <AsyncButton hideOkIcon onClick={() => onGetCoopDetails(item.uuid)}>
                Se detaljer
              </AsyncButton> */}
              </div>
            </div>
          ))}
        </>
      )}

      <h2>Køretøj</h2>
      <Form 
        onSubmit={(event) => {
          event.preventDefault();
          onQueryVehicle();
        }}
        className="leftPadding standardMaxWidth">
        <FormGroup controlId="text">
          <ControlLabel>
            Stelnummer* (Eks: WBAUM71020VH75609)
          </ControlLabel>
          <FormControl
            type="text"
            value={chassisNumber}
            onChange={e => setChassisNumber(e.target.value)}
          />
        </FormGroup>
        <ButtonToolbar>
          <LexButton 
            onClick={onQueryVehicle}
            disabled={vehicleQuery.isFetching}
          >
            Søg
          </LexButton>
          <LexButton onClick={() => {
            setChassisNumber("");
            setChassisQuery("");
          }}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </Form>
      { !!vehicleResults.items && vehicleResults.items.length > 0 && (
        <>
          <h3 className="bold">{vehicleResults.items.length} resultater</h3>
          {vehicleResults.items.map((item, index) => (
            <div key={item.uuid} className="topPadding">
              <h4 className="bold">{index + 1}</h4>
              <div className="bold">Stelnummer</div>
              <div>{item.stelnummer}</div>
              <div className="bold">Registreringsnummer</div>
              <div>{item.registreringsnummer}</div>
              <div className="bold">Fabrikat</div>
              <div>{item.fabrikat}</div>
              <div className="bold">Model</div>
              <div>{item.model}</div>
              <div className="bold">Årgang</div>
              <div>{item.aargang}</div>
              <div className="bold">Variant</div>
              <div>{item.variant}</div>
              <div>
              </div>
            </div>
          ))}
        </>
      )}
      {/* {!!cvrParticipantResults && (
        <>
          <h3>Persondata</h3>
          <pre>{JSON.stringify(JSON.parse(cvrParticipantResults), null, 2)}</pre>
        </>
      )} */}

      <h2>Tinglysningdokument</h2>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          onQueryDocument();
        }}
        className="leftPadding standardMaxWidth">
        <FormGroup controlId="text">
          <ControlLabel>
            ID* (Eks: d9ac8b71-c7a3-4aad-a5d8-494eaf338237)
          </ControlLabel>
          <FormControl
            type="text"
            value={documentId}
            onChange={e => setDocumentId(e.target.value)}
          />
        </FormGroup>
        <ButtonToolbar>
          <LexButton 
            onClick={onQueryDocument}
            disabled={documentQuery.isFetching}
          >
            Søg
          </LexButton>
          <LexButton onClick={() => {
            setDocumentId("");
          }}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </Form>
      {!!documentResults && (
        <>
          <h3>Dokumentdata</h3>
          <pre>{documentResults}</pre>
        </>
      )}
      <div style={{paddingTop: '50px'}}></div>
    </Grid>
  );
};

export default TinglysningsView;