import React from "react";

import Invoice1 from "../resources/images/Invoice1.png";
import Invoice2 from "../resources/images/Invoice2.png";
import Invoice3 from "../resources/images/Invoice3.png";
import Subscription from "../resources/images/Subscription.png";
import PaymentTerms from "../resources/images/PaymentTerms.png";
import SubControls from "../resources/images/SubControls.png";
import PurchaseTermsSubPanel from "../Login/Licenses/PurchaseTermsSubPanel";
import LicenseConsentForm from "../Login/Licenses/LicenseConsentForm";
import DataConsentForm from "../Login/Licenses/DataConsentForm";

import { Link, Consumer } from "../utilities/LexUtilities";

class PaymentDemoView extends React.Component {
  render() {
    let { locale } = this.props;

    console.log(locale);
    if (locale === "da") {
      return (
        <div
          className="standardMaxWidth center-block"
          style={{ paddingTop: "15px", paddingBottom: "15px", margin: "auto" }}
        >
          <h2>Betalingsflow for Axolex.com</h2>
          <h3 style={{ color: "black" }}>
            (Dette betalingsflow er godkendt af Clearhaus A/S)
          </h3>
          <div>
            Axolex ApS driver virksomhed med salg af software abonnementer, samt
            levering af juridisk rådgivning og it-konsulentydelser.
          </div>
          <div>
            Nedenfor beskrives betalingsflowet for abonnementsbetalinger og
            online fakturaer.
          </div>
          <h2>Abonnementsbetalinger</h2>
          <div>
            For at købe abonnement til Axolex systemet skal der først oprettes
            en brugerprofil.
          </div>
          <div>
            Dette sker på <Link to="/Users/Signup">brugeroprettelsessiden</Link>
          </div>
          <div>
            På oprettelsessiden indtastes brugeroplysninger, og der gives
            samtykke til brugerbetingelser og privatlivspolitik.
            Brugerbetingelser og privatlivspolitik er reproduceret nedenfor.
          </div>
          <div>
            <LicenseConsentForm locale={locale} hideControls />
            <DataConsentForm locale={locale} hideControls />
          </div>
          <div>
            Efter oprettelse af brugerprofilen omdirigeres brugeren til
            nedenstående betalingsside.
          </div>
          <div>
            <img src={Subscription} alt="Betalingsside" width="100%" />
          </div>
          <div>
            Her vælges der abonnement og gives samtykke til købsbetingelserne,
            som ses nedenfor. Der er også mulighed for at genlæse
            brugerbetingelser og privatlivspolitik.
          </div>
          <div>
            <img src={PaymentTerms} alt="Handelsbetingelser" width="100%" />
          </div>
          <div>
            <PurchaseTermsSubPanel locale={locale} />
          </div>
          <div>
            Efter valg af abonnement omdirigeres brugeren til en
            betalingsformular. Efter indtastning af kortoplysninger, trækkes den
            første abonnementsbetaling og brugeren omdirigeres til Axolex
            systemets kontrolpanel.
          </div>
          <div>
            Brugeren modtager en faktura for betalingen via mail, og denne kan
            også ses i brugerens kontrolpanel.
          </div>
          <div>
            Brugeren kan på et hvilket som helst tidspunkt afbryde eller skifte
            abonnementet fra sit kontrolpanel.
          </div>
          <div>
            <img
              className="center-block"
              src={SubControls}
              alt="Abonnementskontrol"
              width="50%"
            />
          </div>
          <h2>Faktura links</h2>
          <div>
            Udover salg af software abonnementer, driver Axolex ApS også
            virksomhed med salg af juridisk rådgivning og it-konsulentydelser.
          </div>
          <div>
            Opkrævning af disse ydelser foretages via udsendelse af
            betalingslinks, som guider kunden til en online faktura.
          </div>
          <div>
            <img
              className="center-block"
              src={Invoice1}
              alt="Faktura"
              width="75%"
            />
          </div>
          <div>
            Før betalingen kan gennemføres skal kunden godkende
            handelsbetingelser og privatlivspolitik.
          </div>
          <div>
            <img
              className="center-block"
              src={Invoice2}
              alt="Godkendelse"
              width="75%"
            />
          </div>
          <div>
            Efter godkendelse, aktiveres betalingsknappen. Ved tryk på denne
            omdirigeres brugeren til en betalingsformular. Efter gennemført
            betaling, dirigeres brugeren tilbage til fakturaen, som nu er
            markeret som betalt. Brugeren har mulighed for at udskrive en kopi
            af den betalte faktura.
          </div>
          <div>
            <img
              className="center-block"
              src={Invoice3}
              alt="Godkendelse"
              width="75%"
            />
          </div>
        </div>
      );
    }
    return (
      <div
        className="standardMaxWidth center-block"
        style={{ paddingTop: "15px", paddingBottom: "15px" }}
      >
        <h2>Payment flow for Axolex.com</h2>
        <h3 style={{ color: "black" }}>
          (This payment flow has been approved by Clearhaus A/S)
        </h3>
        <div>
          Axolex ApS provides SAAS Software subscriptions, and delivers legal
          advice and IT-consultancy services.
        </div>
        <div>
          Below, the payment flow for subscription payment and online invoicing
          is described.
        </div>
        <h2>Subscription payments</h2>
        <div>
          To purchase a subscription to the Axolex system, the user must first
          create a user account.
        </div>
        <div>
          User account creation happens at{" "}
          <Link to="/Users/Signup">the signup page</Link>
        </div>
        <div>
          At the signup page, the user enters their user information, and
          consents to license terms and privacy policy. License terms and
          Privacy Policy are reproduced below.
        </div>
        <div>
          <LicenseConsentForm locale={locale} hideControls />
          <DataConsentForm locale={locale} hideControls />
        </div>
        <div>
          After creating their user account, the user is redirected to the
          subscription payment page.
        </div>
        <div>
          <img
            src={Subscription}
            alt="Subscription payment page."
            width="100%"
          />
        </div>
        <div>
          The user selects a subscription and consents to the payment terms,
          which are reproduced below. The user can also re-read license terms
          and privacy policy.
        </div>
        <div>
          <img src={PaymentTerms} alt="Payment terms" width="100%" />
        </div>
        <div>
          <PurchaseTermsSubPanel locale={locale} />
        </div>
        <div>
          After selecting the subscription, the user is shown a payment form, on
          which they enter their payment. After approving payment, the first
          subscription payment is charged, and the user is redirected to the
          Axolex system control panel.
        </div>
        <div>
          The user receives an invoice at the email address they entered during
          account creation. This invoice can also be found at the user's control
          panel.
        </div>
        <div>
          The user can cancel or change their subscription at any time from
          their control panel.
        </div>
        <div>
          <img
            className="center-block"
            src={SubControls}
            alt="Subscription control"
            width="50%"
          />
        </div>
        <h2>Invoice payment links</h2>
        <div>
          In addition to SAAS software subscription, Axolex ApS also sells legal
          advice services and IT-consultancy services.
        </div>
        <div>
          Payment for such services is collected by sending payment links, which
          guide the user to an online invoice.
        </div>
        <div>
          <img
            className="center-block"
            src={Invoice1}
            alt="Invoice"
            width="75%"
          />
        </div>
        <div>
          Before paying, the user must consent to the payment terms and privacy
          policy.
        </div>
        <div>
          <img
            className="center-block"
            src={Invoice2}
            alt="Godkendelse"
            width="75%"
          />
        </div>
        <div>
          After approving the terms, the payment button is activated. By pushing
          the payment button, the user is shown a payment form. After completing
          payment, the user is shown the invoice again, which is now marked as
          paid. The user can print a copy of the paid invoice.
        </div>
        <div>
          <img
            className="center-block"
            src={Invoice3}
            alt="Approval"
            width="75%"
          />
        </div>
      </div>
    );
  }
}

export default class PaymentDemoContainer extends React.PureComponent {
  //Filter out needed data from DataStore.js
  mapStoreToProps = (store) => {
    return {
      locale: store.locale,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => {
          let props = this.mapStoreToProps(store);
          return <PaymentDemoView {...props} />;
        }}
      </Consumer>
    );
  }
}
