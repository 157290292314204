import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import {
  Grid,
  Row,
  Col,
  FormControl,
  ButtonToolbar,
  Form,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import {
  LexButton,
  Flexbox,
  FlexElement,
  LoadingIcon,
  AxoLocal,
  ctx,
  getText,
  Icon,
  Link,
  ErrorBoundary,
  AsyncButton,
  UploadButton,
} from "../../utilities/LexUtilities";

// import AccountPlansBasicSetupForm from './AccountPlansBasicSetupForm'
import SystemAccountsSetupForm from "./SystemAccountsSetupForm";

import AccountPlansTable from "./AccountPlansTable";
import { RoutingService, DataActions } from "../../services/AxoServices";

export default class AccountPlansView extends React.PureComponent {
  static propTypes = {
    financeAccountPlans: PropTypes.array.isRequired,
    standardFinanceAccountPlans: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
    selectedPlanId: PropTypes.number.isRequired,
    selectedStandardPlanId: PropTypes.number.isRequired,
    onSelectPlan: PropTypes.func.isRequired,
    onSelectStandardPlan: PropTypes.func.isRequired,
    newPlanName: PropTypes.string.isRequired,
    onCreatePlan: PropTypes.func.isRequired,
    onCopyPlan: PropTypes.func.isRequired,
    onCopyStandardPlan: PropTypes.func.isRequired,
    copyPlanInProgress: PropTypes.bool.isRequired,
    copyStandardPlanInProgress: PropTypes.bool.isRequired,
    onUpdateProperty: PropTypes.func.isRequired,
    creatingHeader: PropTypes.bool.isRequired,
    onCreateHeader: PropTypes.func.isRequired,
    onCancelCreate: PropTypes.func.isRequired,
    newAccountNumber: PropTypes.number.isRequired,
    newAccountName: PropTypes.string.isRequired,
    doCreateAccount: PropTypes.func.isRequired,
    editingPlan: PropTypes.bool.isRequired,
    updateAccountProperty: PropTypes.func.isRequired,
    addAccountToHeader: PropTypes.func.isRequired,
    onDeleteAccount: PropTypes.func.isRequired,
    creatingSumAccount: PropTypes.bool.isRequired,
    sumIntervalStart: PropTypes.number.isRequired,
    sumIntervalEnd: PropTypes.number.isRequired,
    onStopEditing: PropTypes.func.isRequired,
    updateSelectedPlanProperty: PropTypes.func.isRequired,
    onDeletePlan: PropTypes.func.isRequired,
    confirmPlanDeletion: PropTypes.bool.isRequired,
    cancelDeletePlan: PropTypes.func.isRequired,
    doDeletePlan: PropTypes.func.isRequired,
    editingAccountPlanSetup: PropTypes.bool.isRequired,
  };

  onImportJSON = async (file) => {
    if (!file) {
      return false;
    }

    let textType = /text.*/;
    if (!file.type.match(textType)) {
      return false;
    }

    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.onload = async () => {
        let response = await DataActions.importFinanceAccountPlan(
          reader.result
        );
        if (!response.ok) {
          resolve(false);
          return;
        }

        let { onSelectPlan } = this.props;
        let newPlan = await response.json();
        onSelectPlan(newPlan.id);

        resolve(true);
      };

      reader.readAsText(file);
    });
  };

  render() {
    let {
      financeAccountPlans,
      standardFinanceAccountPlans,
      selectedPlanId,
      selectedPlan,
      onSelectPlan,
      selectedStandardPlanId,
      onSelectStandardPlan,
      newPlanName,
      onUpdateProperty,
      onCreatePlan,
      onCopyPlan,
      copyPlanInProgress,
      onCopyStandardPlan,
      copyStandardPlanInProgress,
      editingPlan,
      onStopEditing,
      updateSelectedPlanPropertyMap,
      editingAccountPlanSetup,
      confirmPlanDeletion,
      onDeletePlan,
      doDeletePlan,
      cancelDeletePlan,
    } = this.props;

    var hasStandards = standardFinanceAccountPlans.length > 0;
    let planOptions = financeAccountPlans.map((plan) => {
      return { value: plan.id.toString(), label: plan.name };
    });

    let standardPlanOptions = standardFinanceAccountPlans.map((plan) => {
      return { value: plan.id.toString(), label: plan.name };
    });

    let AccountTable = this.AccountTable;
    return (
      <Grid fluid style={{ marginTop: "15px" }}>
        <Row>
          <Col xs={12} lg={hasStandards ? 6 : 6}>
            <Flexbox style={{ position: "relative", zIndex: 9 }}>
              <FlexElement flexGrow={1}>
                <div style={{ minWidth: "150px" }}>
                  <Select
                    name="accountPlan"
                    menuPlacement="auto"
                    value={
                      planOptions.find(
                        (o) => o.value === selectedPlanId.toString()
                      ) || { value: "0", label: ctx.getSync("axoAccounting32") }
                    }
                    onChange={(selection, arg) => {
                      onSelectPlan(
                        !!selection && selection.value !== "0"
                          ? parseInt(selection.value, 10)
                          : null
                      );
                    }}
                    noOptionsMessage={() => ""}
                    options={[
                      { value: "0", label: ctx.getSync("axoAccounting32") },
                      ...planOptions,
                    ]}
                  />
                </div>
              </FlexElement>
              <FlexElement style={{ paddingLeft: "15px" }}>
                {!!selectedPlan ? (
                  <>
                    {editingPlan ? (
                      <ButtonToolbar>
                        <LexButton
                          key="save"
                          disabled={!selectedPlanId}
                          onClick={() => onStopEditing()}
                        >
                          <Icon glyph="icon-fontello-stop-outline" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            entity="axoAccounting19"
                            defaultValue={"Stop redigering"}
                          />
                        </LexButton>
                        {!editingAccountPlanSetup &&
                        !!selectedPlan &&
                        selectedPlan.balanceEnd <= 0 ? (
                          <React.Fragment>
                            <AsyncButton
                              key="delete"
                              disabled={!selectedPlanId || confirmPlanDeletion}
                              onClick={onDeletePlan}
                            >
                              <Icon glyph="icon-fontello-trash-1" />
                              &nbsp;&nbsp;{" "}
                              <AxoLocal
                                entity="axoAccounting20"
                                defaultValue={"Slet kontoplan"}
                              />
                              &nbsp;- {selectedPlan.name}
                            </AsyncButton>
                            {confirmPlanDeletion ? (
                              <LexButton onClick={doDeletePlan}>
                                <Icon glyph="icon-fontello-check" />
                                &nbsp;&nbsp;
                                <AxoLocal
                                  key="confirm"
                                  entity="axoAccounting21"
                                  defaultValue={"Bekræft sletning"}
                                />
                              </LexButton>
                            ) : null}
                            {confirmPlanDeletion ? (
                              <LexButton onClick={cancelDeletePlan}>
                                <Icon glyph="icon-fontello-ccw" />
                                &nbsp;&nbsp;
                                <AxoLocal
                                  key="cancel"
                                  entity="axoAccounting22"
                                  defaultValue={"Fortryd"}
                                />
                              </LexButton>
                            ) : null}
                          </React.Fragment>
                        ) : null}
                      </ButtonToolbar>
                    ) : (
                      <ButtonToolbar>
                        <LexButton
                          disabled={!selectedPlan}
                          onClick={() => onUpdateProperty("editingPlan", true)}
                        >
                          <Icon glyph="icon-fontello-edit-3" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            key="edit"
                            entity="axoAccounting23"
                            defaultValue={"Rediger kontoplan"}
                          />
                        </LexButton>
                        {/* <LexButton 
                        onClick={() => onUpdateProperty('editingAccountPlanSetup', !editingAccountPlanSetup)}
                      >
                        { !editingAccountPlanSetup ? (
                          <span><AxoLocal key='standard' entity='axoidcode58'defaultValue={'Standardkonti'}/></span>
                        ) : (
                          <span>
                            <AxoLocal key='finish' entity='axoidcode59'defaultValue={'Luk formular'}/>
                            </span>
                        ) }
                        
                      </LexButton> */}
                        <LexButton
                          key="copy"
                          disabled={!selectedPlan || copyPlanInProgress}
                          onClick={onCopyPlan}
                        >
                          <Icon glyph="icon-fontello-docs-1" />
                          &nbsp;&nbsp;{" "}
                          <AxoLocal
                            entity="axoAccounting24a"
                            defaultValue={"Kopier plan"}
                          />
                          <LoadingIcon show={copyPlanInProgress} />
                        </LexButton>
                      </ButtonToolbar>
                    )}
                  </>
                ) : (
                  <UploadButton onSelected={this.onImportJSON}>
                    Importer JSON
                  </UploadButton>
                )}
              </FlexElement>
            </Flexbox>
          </Col>
          <Col xs={12} lg={hasStandards ? 3 : 3}>
            {hasStandards ? (
              <Flexbox>
                <FlexElement flexGrow={1}>
                  <div
                    style={{
                      position: "relative",
                      zIndex: 9,
                      minWidth: "150px",
                    }}
                  >
                    <Select
                      name="standardPlans"
                      menuPlacement="auto"
                      value={
                        standardPlanOptions.find(
                          (o) => o.value === selectedStandardPlanId.toString()
                        ) || {
                          value: "0",
                          label: getText("axoAccounting6e", "Vælg standard"),
                        }
                      }
                      onChange={(selection, arg) => {
                        onSelectStandardPlan(
                          !!selection && selection.value !== "0"
                            ? parseInt(selection.value, 10)
                            : null
                        );
                      }}
                      noOptionsMessage={() => ""}
                      options={[
                        {
                          value: "0",
                          label: getText("axoAccounting6e", "Vælg standard"),
                        },
                        ...standardPlanOptions,
                      ]}
                    />
                  </div>
                </FlexElement>
                <FlexElement style={{ paddingLeft: "15px" }}>
                  <LexButton
                    disabled={
                      !selectedStandardPlanId || copyStandardPlanInProgress
                    }
                    onClick={onCopyStandardPlan}
                  >
                    <Icon glyph="icon-fontello-list-alt" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="axoAccounting33"
                      defaultValue={"Indlæs"}
                    />
                    <LoadingIcon show={copyStandardPlanInProgress} />
                  </LexButton>
                </FlexElement>
              </Flexbox>
            ) : null}
          </Col>
          <Col xs={12} lg={3}>
            <Flexbox>
              <FlexElement flexGrow={1}>
                <div style={{ marginRight: "10px" }}>
                  <FormControl
                    type="text"
                    value={newPlanName}
                    onChange={(event) =>
                      onUpdateProperty("newPlanName", event.target.value)
                    }
                    placeholder={getText("axoAccounting6d", "Ny kontoplan...")}
                    // style={{ maxWidth: '250px' }}
                  />
                </div>
              </FlexElement>
              <FlexElement>
                <LexButton onClick={onCreatePlan} disabled={!newPlanName}>
                  <Icon glyph="icon-fontello-plus-2" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="axoAccounting25"
                    defaultValue={"Opret kontoplan"}
                  />
                </LexButton>
              </FlexElement>
            </Flexbox>
          </Col>
        </Row>
        {!!selectedPlan && selectedPlan.balanceEnd === 0 ? (
          <div
            style={{ maxWidth: "900px", paddingTop: "50px" }}
            className="axored"
          >
            <Icon glyph="icon-fontello-cog-alt" />
            &nbsp;&nbsp;
            <Link to={RoutingService.getPath("AccountPlans/Setup")}>
              <AxoLocal
                entity="axoidcode38"
                defaultValue={
                  "Gå til opsætning og definér resultatopgørelse og balanceintervaller."
                }
              />
            </Link>
            {/* <AccountPlansBasicSetupForm
              onSubmit={properties => updateSelectedPlanPropertyMap(properties)}
            /> */}
          </div>
        ) : null}
        {editingAccountPlanSetup ? (
          <div style={{ maxWidth: "900px" }}>
            <SystemAccountsSetupForm
              key={selectedPlanId}
              onSubmit={(properties) => {
                updateSelectedPlanPropertyMap(properties);
                onUpdateProperty("editingAccountPlanSetup", false);
              }}
              accounts={selectedPlan.accounts.filter(
                (a) => a.type === "Standard"
              )}
              financeAccountPlan={selectedPlan}
              onCancel={() =>
                onUpdateProperty("editingAccountPlanSetup", false)
              }
            />
          </div>
        ) : null}
        {!editingAccountPlanSetup &&
        !!selectedPlan &&
        selectedPlan.balanceEnd > 0 ? (
          <AccountTable selectedPlan={selectedPlan} />
        ) : null}
      </Grid>
    );
  }

  AccountTable = (props) => {
    let {
      creatingHeader,
      onCreateHeader,
      onCancelCreate,
      newAccountNumber,
      onUpdateProperty,
      newAccountName,
      doCreateAccount,
      editingPlan,
      creatingSumAccount,
      sumIntervalStart,
      sumIntervalEnd,
      summedAccounts,
      updateAccountProperty,
      addAccountToHeader,
      onDeleteAccount,
      updateSelectedPlanProperty,
      userProfile,
      financeAccounts,
      selectedPlan,
      selectedPlanId,
      onDeletePlan,
      confirmPlanDeletion,
      cancelDeletePlan,
      doDeletePlan,
      globalSettings,
    } = this.props;

    let accounts = selectedPlan.accounts.sort(
      (a1, a2) => a1.number - a2.number
    );
    let accountOptions = accounts.map((acc) => {
      return {
        value: acc.id.toString(),
        label: acc.number + " - " + (acc.name || ""),
      };
    });

    return (
      <ErrorBoundary>
        <Row>
          <Col xs={12} style={{ marginTop: "10px" }}>
            {creatingHeader ? (
              <Form>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal
                      entity="axoAccounting6"
                      defaultValue={"Kontonummer"}
                    />
                  </ControlLabel>
                  <FormControl
                    type="number"
                    value={isNaN(newAccountNumber) ? "" : newAccountNumber}
                    onChange={(event) =>
                      onUpdateProperty(
                        "newAccountNumber",
                        event.target.valueAsNumber
                      )
                    }
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                    placeholder={getText("axoAccounting6", "Kontonummer")}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    value={newAccountName}
                    onChange={(event) =>
                      onUpdateProperty("newAccountName", event.target.value)
                    }
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                    placeholder={getText("axoAccounting6a", "Omsætning")}
                  />
                </FormGroup>
                <FormGroup>
                  <ButtonToolbar>
                    <LexButton
                      disabled={!newAccountNumber || !newAccountName}
                      onClick={() => doCreateAccount("Header")}
                    >
                      <AxoLocal
                        entity="axoAccounting26"
                        defaultValue={"Opret kontoplan"}
                      />
                    </LexButton>
                    <LexButton onClick={onCancelCreate}>
                      <Icon glyph="icon-fontello-ccw" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            ) : null}
            {creatingSumAccount ? (
              <Form>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal
                      entity="axoAccounting6"
                      defaultValue={"Kontonummer"}
                    />
                  </ControlLabel>
                  <FormControl
                    type="number"
                    value={isNaN(newAccountNumber) ? "" : newAccountNumber}
                    onChange={(event) =>
                      onUpdateProperty(
                        "newAccountNumber",
                        event.target.valueAsNumber
                      )
                    }
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                    placeholder={getText("axoAccounting6", "Kontonummer")}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    value={newAccountName}
                    onChange={(event) =>
                      onUpdateProperty("newAccountName", event.target.value)
                    }
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                    placeholder={getText("axoAccounting6c", "Omsætning i alt")}
                  />
                </FormGroup>
                <FormGroup style={{ margin: "0px" }}>
                  <ControlLabel>
                    <AxoLocal entity="AdminTabView8" defaultValue="Interval" />
                  </ControlLabel>
                  <Flexbox responsive>
                    <FormGroup style={{ paddingRight: "15px" }}>
                      <ControlLabel>
                        <AxoLocal
                          entity="axoAccounting27"
                          defaultValue={"Sum fra"}
                        />
                      </ControlLabel>
                      <FormControl
                        type="number"
                        value={isNaN(sumIntervalStart) ? "" : sumIntervalStart}
                        onChange={(event) =>
                          onUpdateProperty(
                            "sumIntervalStart",
                            event.target.valueAsNumber
                          )
                        }
                        style={{ maxWidth: "250px", marginRight: "10px" }}
                        placeholder={getText("axoAccounting6", "Kontonummer")}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>
                        <AxoLocal
                          entity="axoAccounting28"
                          defaultValue={"Sum til"}
                        />
                      </ControlLabel>
                      <FormControl
                        type="number"
                        value={isNaN(sumIntervalEnd) ? "" : sumIntervalEnd}
                        onChange={(event) =>
                          onUpdateProperty(
                            "sumIntervalEnd",
                            event.target.valueAsNumber
                          )
                        }
                        style={{ maxWidth: "250px", marginRight: "10px" }}
                        placeholder={getText("axoAccounting6", "Kontonummer")}
                      />
                    </FormGroup>
                  </Flexbox>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal
                      entity="axoidcode62"
                      defaultValue="Summer valgte konti"
                    />
                  </ControlLabel>
                  <div
                    style={{
                      maxWidth: "250px",
                      position: "relative",
                      zIndex: 9,
                    }}
                  >
                    <Select
                      name="summedAccounts"
                      menuPlacement="auto"
                      value={{
                        value: "0",
                        label: getText("axoAccounting6f", "Vælg konto"),
                      }}
                      onChange={(selection, arg) => {
                        let id =
                          !!selection && selection.value !== "0"
                            ? parseInt(selection.value, 10)
                            : null;
                        if (id) {
                          onUpdateProperty(
                            "summedAccounts",
                            summedAccounts.concat([id])
                          );
                        }
                      }}
                      noOptionsMessage={() => ""}
                      options={[
                        {
                          value: "0",
                          label: getText("axoAccounting6f", "Vælg konto"),
                        },
                        ...accountOptions,
                      ]}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    {summedAccounts.map((id) => (
                      <div style={{ marginRight: "15px" }}>
                        <strong>
                          {financeAccounts[id].number} -{" "}
                          {financeAccounts[id].name || ""}
                        </strong>{" "}
                        <Icon
                          role="button"
                          glyph="icon-fontello-trash-1"
                          onClick={() => {
                            onUpdateProperty(
                              "summedAccounts",
                              summedAccounts.filter((accId) => accId !== id)
                            );
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </FormGroup>
                <FormGroup>
                  <ButtonToolbar>
                    <LexButton
                      disabled={
                        !newAccountNumber ||
                        !newAccountName ||
                        (sumIntervalEnd > 0 &&
                          sumIntervalEnd <= sumIntervalStart)
                      }
                      onClick={() => doCreateAccount("Sum")}
                    >
                      <Icon glyph="icon-fontello-plus-1" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="axoAccounting29"
                        defaultValue={"Opret sumkonto"}
                      />
                    </LexButton>
                    <LexButton onClick={onCancelCreate}>
                      <Icon glyph="icon-fontello-ccw" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            ) : null}
            {editingPlan && !creatingHeader && !creatingSumAccount ? (
              <ButtonToolbar>
                <LexButton onClick={onCreateHeader}>
                  <Icon glyph="icon-fontello-plus-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="axoAccounting30"
                    defaultValue={"Tilføj overskrift"}
                  />
                </LexButton>
                <LexButton
                  onClick={() => onUpdateProperty("creatingSumAccount", true)}
                >
                  <Icon glyph="icon-fontello-plus-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="axoAccounting31"
                    defaultValue={"Tilføj Sumkonto"}
                  />
                </LexButton>
                <LexButton
                  key="delete"
                  disabled={!selectedPlanId || confirmPlanDeletion}
                  onClick={onDeletePlan}
                >
                  <Icon glyph="icon-fontello-trash-1" />
                  &nbsp;&nbsp;{" "}
                  <AxoLocal
                    entity="axoAccounting20"
                    defaultValue={"Slet kontoplan"}
                  />
                  &nbsp;- {selectedPlan.name}
                </LexButton>
                {confirmPlanDeletion ? (
                  <AsyncButton onClick={doDeletePlan}>
                    <Icon glyph="icon-fontello-check" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      key="confirm"
                      entity="axoAccounting21"
                      defaultValue={"Bekræft sletning"}
                    />
                  </AsyncButton>
                ) : null}
                {confirmPlanDeletion ? (
                  <LexButton onClick={cancelDeletePlan}>
                    <Icon glyph="icon-fontello-ccw" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      key="cancel"
                      entity="axoAccounting22"
                      defaultValue={"Fortryd"}
                    />
                  </LexButton>
                ) : null}
              </ButtonToolbar>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ErrorBoundary>
              <AccountPlansTable
                selectedPlan={selectedPlan}
                financeAccounts={financeAccounts}
                userProfile={userProfile}
                entries={accounts}
                globalSettings={globalSettings}
                forceShowCards={false}
                onToggleCards={() => {}}
                editing={editingPlan}
                updateAccountProperty={updateAccountProperty}
                addAccountToHeader={addAccountToHeader}
                onDeleteAccount={onDeleteAccount}
                updateSelectedPlanProperty={updateSelectedPlanProperty}
              />
            </ErrorBoundary>
          </Col>
        </Row>
      </ErrorBoundary>
    );
  };
}
