import React from "react";
import moment from "moment";

import { DataStore, DataActions } from "../../../services/AxoServices";

import { Consumer, withRTKData } from "../../../utilities/LexUtilities";

import AccountingVatView from "./AccountingVatView";
import AccountingVatViewV2 from "./AccountingVatViewV2";

class AccountingVatContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    // Dates are moments
    this.state = {
      settlingVat: false,
      showConfirmationMessage: false,
      settlingStartDate: null,
      settlingEndDate: null,
      settlingAccountId: 0,
      payingVat: false,
      paymentDate: moment(),
      selectedPaymentAccountId: null,
    };

    this.defaultPlan = { accounts: [] };
    this.timeout = null;
  }

  componentDidMount() {
    DataStore.fetchClientPlan();
    DataStore.fetchFiscalYears().then(() => {
      DataStore.fetchVatReport();
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  mapStoreToProps = (store) => {
    return {
      selectedPlan: store.clientPlan || this.defaultPlan,
      fiscalYears: store.fiscalYears,
      selectedFiscalYearId: store.selectedFiscalYearId,
      vatReportArray: store.vatReportArray,
      vatReport: store.vatReport,
      includeDraft: store.includeDraft,
    };
  };

  handleSelectedClient = (selectedClient) => {
    DataStore.setState({
      fiscalYears: null,
    });

    DataStore.setSelectedContact(selectedClient);

    DataStore.fetchClientPlan();
    DataStore.fetchFiscalYears().then(() => {
      DataStore.fetchVatReport();
    });
  };

  onSelectFiscalYear = (fiscalYearId) => {
    DataStore.selectFiscalYear(fiscalYearId);
    DataStore.fetchVatReport();
  };

  onSettleVat = (startDate, endDate) => {
    this.setState({
      settlingVat: true,
      settlingStartDate: startDate || moment().utc(),
      settlingEndDate: endDate || moment().utc(),
    });
  };

  onCancelSettling = () => {
    this.setState({
      settlingVat: false,
      settlingStartDate: null,
      settlingEndDate: null,
    });
  };

  doSettleVat = async (clientId, includeDraft) => {
    let { settlingStartDate, settlingEndDate } = this.state;

    let response = await DataActions.settleVat({
      clientId,
      startDate: settlingStartDate.format(),
      endDate: settlingEndDate.format(),
      filter: includeDraft ? "All" : "Posted",
    });

    if (!!response && !!response.ok) {
      this.setState({
        showConfirmationMessage: true,
        settlingVat: false,
        settlingStartDate: null,
        settlingEndDate: null,
      });
      this.timeout = setTimeout(
        () => this.setState({ showConfirmationMessage: false }),
        3000
      );
      DataStore.fetchVatReport();

      return true;
    }
    return false;
  };

  onPayVat = (startDate, endDate) => {
    this.setState({
      payingVat: true,
      settlingStartDate: startDate || moment(),
      settlingEndDate: endDate || moment(),
      paymentDate: endDate || moment(),
    });
  };

  doPayVat = async (clientId, paymentAccountId) => {
    let { settlingEndDate, paymentDate } = this.state;

    let response = await DataActions.payVat({
      clientId,
      paymentAccountId,
      settlingDate: settlingEndDate.format(),
      paymentDate: paymentDate.format(),
    });

    if (!!response && !!response.ok) {
      this.setState({
        showConfirmationMessage: true,
        payingVat: false,
        paymentDate: null,
        settlingStartDate: null,
        settlingEndDate: null,
      });
      this.timeout = setTimeout(
        () => this.setState({ showConfirmationMessage: false }),
        3000
      );
      DataStore.fetchVatReport();

      return true;
    }
    return false;
  };

  onCancelPayment = () => {
    this.setState({ payingVat: false });
  };

  onSelectPaymentAccount = (accountId) => {
    this.setState({ selectedPaymentAccountId: accountId });
  };

  onSelectPaymentDate = (selectedDate) => {
    this.setState({ paymentDate: selectedDate });
  };

  onChangeIncludeDraft = (includeDraft) => {
    DataStore.setState({ includeDraft });
    DataStore.fetchVatReport();
  };

  render() {
    let { useDetailedVatView } = this.props;

    if (useDetailedVatView) {
      return (
        <Consumer>
          {(store) => (
            <AccountingVatViewV2
              {...this.mapStoreToProps(store)}
              {...this.props}
              {...this.state}
              handleSelectedClient={this.handleSelectedClient}
              onSelectFiscalYear={this.onSelectFiscalYear}
              onSettleVat={this.onSettleVat}
              onCancelSettling={this.onCancelSettling}
              doSettleVat={this.doSettleVat}
              onPayVat={this.onPayVat}
              onCancelPayment={this.onCancelPayment}
              doPayVat={this.doPayVat}
              onSelectPaymentAccount={this.onSelectPaymentAccount}
              onSelectPaymentDate={this.onSelectPaymentDate}
              onChangeIncludeDraft={this.onChangeIncludeDraft}
            />
          )}
        </Consumer>
      );
    }

    return (
      <Consumer>
        {(store) => (
          <AccountingVatView
            {...this.mapStoreToProps(store)}
            {...this.props}
            {...this.state}
            handleSelectedClient={this.handleSelectedClient}
            onSelectFiscalYear={this.onSelectFiscalYear}
            onSettleVat={this.onSettleVat}
            onCancelSettling={this.onCancelSettling}
            doSettleVat={this.doSettleVat}
            onPayVat={this.onPayVat}
            onCancelPayment={this.onCancelPayment}
            doPayVat={this.doPayVat}
            onSelectPaymentAccount={this.onSelectPaymentAccount}
            onSelectPaymentDate={this.onSelectPaymentDate}
            onChangeIncludeDraft={this.onChangeIncludeDraft}
          />
        )}
      </Consumer>
    );
  }
}

export default withRTKData(AccountingVatContainer);
