import React from "react";

import { Button, ButtonGroup, ButtonToolbar, Modal } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class AlertModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      alert: "",
      callback: () => {},
    };
  }

  close = () => {
    let { callback } = this.state;

    callback();

    this.setState({
      showModal: false,
      alert: "",
      callback: () => {},
    });
  };

  open = (alertText, callback) => {
    this.setState({
      showModal: true,
      alert: alertText,
      callback: callback || (() => {}),
    });
  };

  render() {
    let { alert } = this.state;


    return (
      <Modal
        className="center-dialog"
        show={this.state.showModal}
        onHide={this.close}
      >
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
          }}
          closeButton
        >
          <Modal.Title>
            <AxoLocal
              entity="AlertshowModaldialog"
              defaultValue={"Meddelelse"}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="editable"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
          }}
        >
          {alert.length > 50 ? (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {alert}
            </div>
          ) : (
            <h4>{alert}</h4>
          )}
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.close}>
                <Icon glyph="icon-fontello-ok" />
                &nbsp;
                <AxoLocal entity="AlertshowModaldialogOk" defaultValue={"Ok"} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
