import React from "react";
import Conditional from "../../utilities/Conditional";
import MultiPaymentSection from "./MultiPaymentSection";
import moment from "moment";
import CardLogos1 from "../../resources/images/Visa.png";
import CardLogos2 from "../../resources/images/MasterCard.jpg";
import CardLogos3 from "../../resources/images/gold2.png";

import {
  Row,
  Col,
  Grid,
  Table,
  Button,
  ButtonGroup,
  ButtonToolbar,
} from "react-bootstrap";

import {
  ApiService,
  DataActions,
  PrintService,
  ModalService,
  UserInfoService,
  TimeEntryService,
  DataStore,
} from "../../services/AxoServices";

import {
  AxoCheckbox,
  Icon,
  InlineEdit,
  ctx,
  AxoLocal,
  InlineTextArea,
  InlineNumberEdit,
  ClientSearchBox,
  getText,
} from "../../utilities/LexUtilities";

export default class Invoice extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.invoice) {
      this.state = {
        invoice: { ...props.invoice },
        paymentRequests: [],
      };
    } else {
      this.state = {
        invoice: {
          number: 0,
          invoiceType: ctx.getSync("InvoiceInvoicenr"), // <AxoLocal entity='InvoiceInvoicenr'defaultValue={'Faktura #'}/>,
          invoiceNo: "",
          currency: props.userProfile.currency,
          creationDate: new Date(),
          dueDate: new Date(),
          sendReminder: false,
          status: 0,
          outstandingAmount: this.getTotal(props.timeEntries),
          message: ctx.getSync("InvoiceInvoicemessage"), //'Tak for samarbejdet. Husk at oplyse dit ID-nummer ved betalingen. Betaling kan ske via online betalingsservice.
          // message: ctx.getSync('InvoiceInvoicemessage') + ' ' + (props.userProfile.accountNo || ''),//'Tak for samarbejdet. Husk at oplyse dit ID-nummer ved betalingen. Betaling kan ske via online betalingsservice.
          thankYou: ctx.getSync("InvoiceInvoicethankYou"), //'Tak for at vælge vores firma. Det har været en fornøjelse at samarbejde med dig.'}/>,
          client: this.getClient(props.timeEntries),
          userProfile: props.userProfile,
          timeEntries: props.timeEntries,
          paymentRequests: [],
          reverseVAT: false,
        },
      };
    }

    this.state.showImageRemoveButton = false;
    this.state.showSharedMessage = false;
    this.state.isGeneratingPayment = false;
    this.state.showPaymentErrorMessage = false;
    this.state.showPaymentCompletedMessage = false;
    this.state.sendingEmail = false;
    this.state.sentEmail = false;
  }

  componentDidMount() {
    this.initializeInvoice();
  }

  onPrint = () => {
    if (typeof window !== "undefined") {
      PrintService.printElement(document.getElementById("print-invoice"));
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.invoice &&
      JSON.stringify(nextProps.invoice) !== JSON.stringify(this.props.invoice)
    ) {
      this.setState({ invoice: Object.assign({}, nextProps.invoice) }, () => {
        this.initializeInvoice();
      });
    }
  }

  initializeInvoice = () => {
    let { showPaymentRequests } = this.props;
    let { invoice } = this.state;

    if (!invoice.number) {
      ApiService.getNextInvoiceNumber().then((numberInfo) => {
        var newInvoice = Object.assign({}, this.state.invoice);
        newInvoice.number = numberInfo.next;
        this.setState({ invoice: newInvoice });
      });
    }
    //Fetch the full client
    if (invoice.client && invoice.client.id) {
      ApiService.getSingleContact(this.state.invoice.client.id).then(
        (fullClient) => {
          var newInvoice = Object.assign({}, this.state.invoice);
          newInvoice.client = fullClient;
          this.setState({ invoice: newInvoice });
        }
      );
    }
    if (invoice.id && showPaymentRequests) {
      ApiService.getPaymentRequestsForInvoice(invoice.id).then(
        (paymentRequests) => {
          this.setState({ paymentRequests });
        }
      );
    }
  };

  hasUnits = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.units > 0);
  };

  hasAConto = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.aConto > 0);
  };

  hasExpenses = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.expenses > 0);
  };

  //Props
  //timeEntries
  //onCreatePDF
  renderTimeEntriesTable = (subTotal) => {
    var hasUnits = this.hasUnits();
    var hasAConto = this.hasAConto();
    var hasExpenses = this.hasExpenses();
    var footerOffset = 1;
    if (hasUnits) {
      footerOffset += 2;
    }
    if (hasAConto) {
      footerOffset += 1;
    }
    if (hasExpenses) {
      footerOffset += 1;
    }
    var currency = this.state.invoice.currency.toUpperCase();
    return (
      <Table striped>
        <thead className="axocblueabanne">
          <tr>
            <th>#</th>
            <th>
              <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
            </th>
            {hasUnits ? (
              <th>
                <AxoLocal entity="InvoiceInvoiceunit" defaultValue={"Antal"} />
              </th>
            ) : null}
            {hasUnits ? (
              <th>
                <AxoLocal
                  entity="InvoiceInvoicePrisunit"
                  defaultValue={"Pris/enhed"}
                />
              </th>
            ) : null}
            {hasExpenses ? (
              <th>
                <AxoLocal
                  entity="TimeEntriesViewExpensesLabel"
                  defaultValue={"Udgifter"}
                />
              </th>
            ) : null}
            {hasAConto ? (
              <th>
                <AxoLocal
                  entity="InvoiceInvoiceAConto"
                  defaultValue={"A Conto"}
                />
              </th>
            ) : null}
            <th>
              <AxoLocal entity="InvoiceInvoicesum" defaultValue={"Beløb"} />
            </th>
          </tr>
        </thead>
        <tbody>{this.renderTimeEntryRows()}</tbody>
        <tfoot className="axocblueabanne">
          <tr>
            <th colSpan={footerOffset}></th>
            <th>
              <AxoLocal entity="InvoiceInvoicetotal" defaultValue={"Total"} />
            </th>
            <th>
              {currency} {subTotal.toFixed(2)}
            </th>
          </tr>
        </tfoot>
      </Table>
    );
  };

  renderTimeEntryRows = () => {
    var index = 0;
    var hasUnits = this.hasUnits();
    var hasAConto = this.hasAConto();
    var hasExpenses = this.hasExpenses();
    var currency = this.state.invoice.currency.toUpperCase();
    return this.state.invoice.timeEntries.map((timeEntry) => {
      index++;
      return (
        <tr key={timeEntry.id} className="axoinvoicebanne">
          <td>{index}</td>
          <td>{timeEntry.description}</td>
          {hasUnits ? (
            <td>
              {timeEntry.units > 0 ? (
                <span>
                  {timeEntry.units}{" "}
                  {TimeEntryService.getUnitTypeName(timeEntry.unitType)}
                </span>
              ) : (
                ""
              )}
            </td>
          ) : null}
          {hasUnits ? (
            <td>
              {timeEntry.units > 0 ? (
                <span>
                  {currency} {timeEntry.rate}
                </span>
              ) : (
                ""
              )}
            </td>
          ) : null}
          {hasExpenses ? (
            <td>
              {timeEntry.units > 0 ? (
                <span>
                  {currency} {timeEntry.expenses}
                </span>
              ) : (
                ""
              )}
            </td>
          ) : null}
          {hasAConto ? (
            <td>
              {timeEntry.aConto > 0 ? (
                <span>
                  {currency} {timeEntry.aConto}
                </span>
              ) : null}
            </td>
          ) : null}
          <td>
            {currency}{" "}
            {(timeEntry.units * timeEntry.rate + timeEntry.expenses).toFixed(2)}
          </td>
        </tr>
      );
    });
  };

  getTotal = (timeEntries) => {
    return timeEntries
      .reduce((acc, timeEntry) => {
        return acc + TimeEntryService.getTotalValue(timeEntry);
      }, 0)
      .toFixed(2);
  };

  getClient = (timeEntries) => {
    if (timeEntries.length === 0) {
      return {};
    }
    return timeEntries[0].client;
  };

  onSave = () => {
    //For existing invoices, the results are saved automatically
    if (this.props.invoice) {
      this.props.onClose();
      return;
    }
    DataActions.createInvoice(this.state.invoice).then((response) => {
      if (response.ok) {
        response.json().then((newInvoice) => {
          if (this.props.onCreate) {
            this.props.onCreate(newInvoice);
          } else {
            this.props.onClose();
          }
        });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="InvoiceInvoiceresponse"
            defaultValue={
              "Fakturaen kunne ikke gemmes. Tjek at der er forbindelse til serveren."
            }
          />
        );
      }
    });
  };

  onPropertyChangeDate = (propertyName, event) => {
    var invoice = Object.assign({}, this.state.invoice);
    invoice[propertyName] = event.target.valueAsDate;
    invoice.sendReminder = true;
    invoice.reminderSent = false;
    this.setState({ invoice: invoice });
    if (this.props.invoice) {
      DataActions.updateInvoice(invoice);
    }
  };

  onPropertyChangeInline = (propertyName, input) => {
    var invoice = Object.assign({}, this.state.invoice);
    invoice[propertyName] = input.value;
    this.setState({ invoice: invoice });
    if (this.props.invoice) {
      DataActions.updateInvoice(invoice);
    }
  };

  onChangeSendReminder = (event) => {
    var invoice = Object.assign({}, this.state.invoice);
    invoice.sendReminder = event.target.checked;
    invoice.reminderSent = false;
    this.setState({ invoice: invoice });
    if (this.props.invoice) {
      DataActions.updateInvoice(invoice);
    }
  };

  isReadOnly = () => {
    return !!this.props.readOnly;
  };

  renderInvoiceTypeField = (invoice) => {
    if (this.isReadOnly()) {
      return invoice.invoiceType;
    }
    return (
      <InlineEdit
        value={invoice.invoiceType || "---"}
        change={this.onPropertyChangeInline.bind(this, "invoiceType")}
      />
    );
  };

  renderNumberField = (invoice) => {
    if (this.isReadOnly()) {
      return invoice.number.toString();
    }
    return (
      <InlineNumberEdit
        value={invoice.number || 0}
        change={this.onPropertyChangeInline.bind(this, "number")}
      />
    );
  };

  renderMessageField = (invoice) => {
    if (this.isReadOnly()) {
      return <div style={{ whiteSpace: "pre-wrap" }}>{invoice.message}</div>;
    }
    return (
      <InlineTextArea
        value={invoice.message || "---"}
        change={this.onPropertyChangeInline.bind(this, "message")}
      />
    );
  };

  renderThankYouField = (invoice) => {
    if (this.isReadOnly()) {
      return invoice.thankYou;
    }
    return (
      <InlineEdit
        value={invoice.thankYou || "---"}
        change={this.onPropertyChangeInline.bind(this, "thankYou")}
      />
    );
  };

  renderCreationDateField = (invoice) => {
    if (this.isReadOnly()) {
      return moment(invoice.creationDate).format("L");
    }
    return (
      <input
        type="date"
        style={{ border: "none", padding: "0px 0px 0px 0px" }}
        onChange={this.onPropertyChangeDate.bind(this, "creationDate")}
        value={moment(this.state.invoice.creationDate).format("YYYY-MM-DD")}
      />
    );
  };

  renderDueDateField = (invoice) => {
    if (this.isReadOnly()) {
      return moment(invoice.dueDate).format("L");
    }
    return (
      <input
        style={{ border: "none", padding: "0px 0px 0px 0px" }}
        type="date"
        onChange={this.onPropertyChangeDate.bind(this, "dueDate")}
        value={moment(this.state.invoice.dueDate).format("YYYY-MM-DD")}
      />
    );
  };

  renderGenerateButtonContent = () => {
    if (this.props.isGeneratingPDF) {
      return (
        <span>
          <AxoLocal
            entity="DocumentTemplateGeneraisGenerating"
            defaultValue={"Genererer..."}
          />{" "}
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </span>
      );
    } else {
      return (
        <span>
          <Icon glyph="icon-fontello-acrobat" style={{ fontSize: "17px" }} />
          &nbsp;&nbsp;
          <AxoLocal
            entity="DocumentTemplateEditView"
            defaultValue={"Generer til PDF"}
          />{" "}
          {this.props.generatedPDF ? <Icon glyph="icon-fontello-ok-3" /> : ""}
        </span>
      );
    }
  };

  renderGenerateAndSendButtonContent = () => {
    if (this.props.isGeneratingAndSending) {
      return (
        <span>
          <AxoLocal
            entity="DocumentTemplateGeneraisGenerating"
            defaultValue={"Genererer..."}
          />
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </span>
      );
    } else {
      return (
        <span>
          <Icon glyph="icon-fontello-mail-1" style={{ fontSize: "17px" }} />
          &nbsp;&nbsp;
          <AxoLocal
            entity="DocumentGenerateandsendtoclient"
            defaultValue={"Generer og send  PDF"}
          />
        </span>
      );
    }
  };

  onShareWithClient = () => {
    var invoice = this.props.invoice;
    invoice.sharedWithClient = true;
    invoice.status = "Sent";
    DataActions.updateInvoice(invoice)
      .then((response) => {
        if (response.ok) {
          this.setState({ showSharedMessage: true });
          setTimeout(() => {
            this.setState({ showSharedMessage: false });
          }, 5000);
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="InvoiceInvoiceresponse"
              defaultValue={
                "Fakturaen kunne ikke gemmes. Tjek at der er forbindelse til serveren."
              }
            />
          );
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  renderShareButton = () => {
    var invoice = this.props.invoice;
    if (!invoice.client || !invoice.client.clientUserName) {
      return null;
    }
    if (invoice.sharedWithClient) {
      return (
        <Button disabled className="Lex-button-2">
          <Icon glyph="icon-fontello-mail-1" style={{ fontSize: "17px" }} />
          &nbsp;&nbsp;
          <AxoLocal
            entity="InvoiceSentToClienta1"
            defaultValue={"Sendt til klientselvbetjening"}
          />
        </Button>
      );
    }
    return (
      <Button className="Lex-button-2" onClick={this.onShareWithClient}>
        <Icon glyph="icon-fontello-mail-1" style={{ fontSize: "17px" }} />
        &nbsp;&nbsp;
        <AxoLocal
          entity="InvoiceSentToClienta2"
          defaultValue={"Send til klientselvbetjening"}
        />
      </Button>
    );
  };

  SendButton = (props) => {
    var { invoice, sendingEmail, sentEmail } = props;
    if (!invoice.client || !invoice.client.eMail) {
      return null;
    }

    const onSendToClient = () => {
      this.setState({ sendingEmail: true });
      ApiService.sendInvoiceToClients({
        clientIds: [invoice.client.id],
        invoiceId: invoice.id,
        showFullInvoice: true,
        resetPaymentRequest: invoice.status !== "Paid", //If the invoice is not marked as paid, reset the payment request
        messageSubject: getText("axoidcode43", "Faktura til betaling"),
        messageText: getText(
          "axoidcode44",
          "Du har modtaget en faktura. Klik her for at betale:"
        ),
        // messageSubject: 'Medlemskontingent for Vinterbadelauget Bifrost',
        // messageText: 'Det er tid til at betale medlemskontingent for Vinterbadelauget Bifrost. Klik her for at betale:',
        messageUrl:
          window.location.protocol +
          "//" +
          window.location.host +
          "/InvoicePayment",
      })
        .then(() => {
          onEmailSuccess();
        })
        .catch((reason) => {
          console.log(reason);
          this.setState({ sendingEmail: false });
        });
    };

    const onEmailSuccess = () => {
      DataActions.updateInvoice({
        ...this.props.invoice,
        status: 1,
      });

      this.setState({
        sendingEmail: false,
        sentEmail: true,
      });
      setTimeout(() => {
        this.setState({ sentEmail: false });
      }, 3000);
    };

    return (
      <Button
        disabled={sendingEmail || sentEmail}
        className="Lex-button-2"
        onClick={onSendToClient}
      >
        <Icon glyph="icon-fontello-mail-1" style={{ fontSize: "17px" }} />
        &nbsp;&nbsp;
        <AxoLocal
          entity="ContactTableViewclients1ab"
          defaultValue={"Send til klient email"}
        />
        {sendingEmail ? (
          <span>
            &nbsp;
            <img
              alt=""
              src="/imgs/app/loading.gif"
              className="img-circle"
              width="20"
              height="20"
            />
          </span>
        ) : null}
        {sentEmail ? (
          <span>
            &nbsp;
            <Icon glyph="icon-fontello-check-1" />
          </span>
        ) : null}
        {/* <AxoLocal entity='InvoiceSendToClient' defaultValue={'Send til klient'}/> */}
      </Button>
    );
  };

  renderButtons = () => {
    var { invoice, renderButtons, renderPaymentLinkButton } = this.props;

    var { sendingEmail, sentEmail } = this.state;

    if (!renderButtons) {
      return null;
    }
    if (invoice) {

      let SendButton = this.SendButton;
      return (
        <div>
          <ButtonGroup>
            <Button className="Lex-button-2" onClick={this.props.onClose}>
              {" "}
              <span>
                {" "}
                <Icon
                  glyph="icon-fontello-cancel"
                  style={{ fontSize: "17px" }}
                />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="DocumentCloseinvoice"
                  defaultValue={"Luk faktura"}
                />
              </span>
            </Button>
            {this.renderShareButton()}
            {renderPaymentLinkButton ? (
              <SendButton
                invoice={invoice}
                sendingEmail={sendingEmail}
                sentEmail={sentEmail}
              />
            ) : null}
            <Button
              className="Lex-button-2"
              disabled={this.props.isGeneratingPDF}
              onClick={this.props.onGeneratePdf}
            >
              {this.renderGenerateButtonContent()}
            </Button>
            <Button
              className="Lex-button-2"
              disabled={this.props.isGeneratingAndSending}
              onClick={this.props.onGenerateAndSend}
            >
              {this.renderGenerateAndSendButtonContent()}
            </Button>
          </ButtonGroup>
        </div>
      );
    }
    return (
      <ButtonGroup>
        <Button className="Lex-button-2" onClick={this.props.onClose}>
          <Icon glyph="icon-fontello-cancel" style={{ fontSize: "17px" }} />
          &nbsp;{" "}
          <AxoLocal
            entity="socialInputBoxNavCancel"
            defaultValue={"Annuller"}
          />
        </Button>
        <Button className="Lex-button-2" onClick={this.onSave}>
          <span>
            {" "}
            <Icon glyph="icon-fontello-floppy-1" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="Documentinvoicesaveandclose"
              defaultValue={"Gem og luk"}
            />
          </span>
        </Button>
        <Button className="Lex-button-2" onClick={this.onPrint}>
          <span>
            {" "}
            <Icon glyph="icon-fontello-print-3" style={{ fontSize: "17px" }} />
            &nbsp;&nbsp;
            <AxoLocal
              entity="DocumentPrintinvoice"
              defaultValue={"Print faktura"}
            />
          </span>
        </Button>
      </ButtonGroup>
    );
  };

  onPayInvoice = (invoiceId) => {
    this.setState({ isGeneratingPayment: true });
    this.props
      .onPayInvoice(invoiceId)
      .then(() => {
        this.setState({
          isGeneratingPayment: false,
          showPaymentCompletedMessage: true,
        });
      })
      .catch((reason) => {
        if (reason === "closed") {
          this.setState({ isGeneratingPayment: false });
        } else {
          this.showPaymentErrorMessage();
        }
      });
  };

  onPayInvoiceWithCard = (invoiceId) => {
    this.setState({ isGeneratingPayment: true });
    this.props
      .onPayInvoiceWithCard(invoiceId)
      .then(() => {
        this.setState({
          isGeneratingPayment: false,
          showPaymentCompletedMessage: true,
        });
      })
      .catch((reason) => {
        if (reason === "closed") {
          this.setState({ isGeneratingPayment: false });
        } else {
          this.showPaymentErrorMessage();
        }
      });
  };

  showPaymentErrorMessage = () => {
    this.setState({
      isGeneratingPayment: false,
      showPaymentErrorMessage: true,
    });
    setTimeout(() => {
      this.setState({ showPaymentErrorMessage: false });
    }, 5000);
  };

  renderPaymentButtons = () => {
    let { invoice, cardInformation, paymentDate } = this.props;
    let { isGeneratingPayment } = this.state;

    // if(!this.props.cardInformation.brand) {
    //   return (
    //     <Button disabled className='Lex-button-2' style={{fontSize:'17px'}}>
    //       <Icon glyph='icon-fontello-credit-card' style={{fontSize:'17px'}}/>&nbsp; &#128179; &#x1f4b3; &nbsp; &nbsp;
    //       <AxoLocal entity='InvoiceRegisterCard' defaultValue={'Betal'}/>
    //     </Button>
    //   )
    // }
    if (invoice.status === "Paid" || !!paymentDate) {
      //Paid
      return (
        <Button disabled className="Lex-button-2 axoblue">
          <Icon glyph="icon-fontello-check-1" style={{ fontSize: "17px" }} />{" "}
          &nbsp;
          <AxoLocal
            key="paid"
            entity="InvoiceInvoicePaid"
            defaultValue={"Betalt"}
          />
          {!!paymentDate ? (
            <span>&nbsp;{moment(paymentDate).format("L")}</span>
          ) : null}
        </Button>
      );
    }

    if (!!cardInformation && !!cardInformation.brand) {
      return (
        <Button
          key="payWithCard"
          className="Lex-button-2"
          disabled={isGeneratingPayment}
          onClick={this.onPayInvoiceWithCard.bind(this, invoice.id)}
        >
          <img alt="" src={CardLogos1} width="30" height="20" />
          &nbsp;&nbsp;
          <img alt="" src={CardLogos2} width="30" height="20" />
          &nbsp;&nbsp;
          <img alt="" src={CardLogos3} width="30" height="20" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="axoAccounting50"
            defaultValue={" Betal med kort ************"}
          />
          {cardInformation.last4}
          <Conditional inline show={isGeneratingPayment}>
            &nbsp;
            <img
              alt=""
              src={CardLogos2}
              className="img-circle"
              width="20"
              height="20"
            />
          </Conditional>
        </Button>
      );
    }
    return (
      <Button
        key="pay"
        className="Lex-button-2"
        disabled={isGeneratingPayment}
        onClick={this.onPayInvoice.bind(this, invoice.id)}
      >
        <img alt="" src={CardLogos1} width="20" height="20" />
        &nbsp;&nbsp;
        <img alt="" src={CardLogos2} width="20" height="20" />
        &nbsp;&nbsp;
        <img alt="" src={CardLogos3} width="20" height="20" />
        &nbsp;&nbsp;
        <AxoLocal
          key="payment"
          entity="InvoicePayInvoice"
          defaultValue={"Betal faktura"}
        />
        <Conditional inline show={isGeneratingPayment}>
          &nbsp;
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </Conditional>
      </Button>
    );
  };

  renderClientButtons = () => {
    let { renderClientButtons, onClose, isGeneratingPDF, onGeneratePdf } =
      this.props;

    if (!renderClientButtons) {
      return null;
    }
    return (
      <ButtonGroup>
        {!!onClose ? (
          <Button className="Lex-button-2" onClick={onClose}>
            {" "}
            <span>
              {" "}
              <Icon glyph="icon-fontello-cancel" style={{ fontSize: "17px" }} />
              &nbsp;&nbsp;
              <AxoLocal
                entity="DocumentCloseinvoice"
                defaultValue={"Luk faktura"}
              />
            </span>
          </Button>
        ) : null}
        {this.renderPaymentButtons()}
        {!!onGeneratePdf ? (
          <Button
            className="Lex-button-2"
            disabled={isGeneratingPDF}
            onClick={onGeneratePdf}
          >
            {this.renderGenerateButtonContent()}
          </Button>
        ) : null}
      </ButtonGroup>
    );
  };

  getClientName = () => {
    var invoice = this.state.invoice;
    if (invoice.clientNameOverride) {
      return invoice.clientNameOverride;
    }
    return invoice.client
      ? invoice.client.firstName + " " + invoice.client.lastName
      : !this.isReadOnly()
      ? ctx.getSync("DocumentPrintinvoiceinvoiceclientName")
      : "";
  };

  getClientAddress = () => {
    var invoice = this.state.invoice;
    if (invoice.clientAddressOverride) {
      return invoice.clientAddressOverride;
    }
    return invoice.client
      ? (invoice.client.address || "") +
          "\n" +
          (invoice.client.postalCode || "") +
          "\n" +
          (invoice.client.city || "")
      : !this.isReadOnly()
      ? ctx.getSync("DocumentPrintinvoiceinvoiceclientaddress")
      : "";
  };

  renderClientNameField = () => {
    if (this.isReadOnly()) {
      return this.getClientName();
    }
    return (
      <InlineEdit
        value={this.getClientName()}
        change={this.onPropertyChangeInline.bind(this, "clientNameOverride")}
      />
    );
  };

  renderClientAddressField = () => {
    if (this.isReadOnly()) {
      return (
        <div style={{ whiteSpace: "pre-wrap" }}>{this.getClientAddress()}</div>
      );
    }
    return (
      <InlineTextArea
        value={this.getClientAddress()}
        change={this.onPropertyChangeInline.bind(this, "clientAddressOverride")}
      />
    );
  };

  calculateInvoiceSubTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.units * timeEntry.rate;
    }, 0);
  };

  calculateExpensesSubTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.expenses;
    }, 0);
  };

  calculateTaxTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.units * timeEntry.rate * timeEntry.tax;
    }, 0);
  };

  calculateAContoTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + timeEntry.aConto;
    }, 0);
  };

  renderInlineEdit = (propertyName, defaultValue) => {
    var invoice = this.state.invoice;
    if (this.isReadOnly()) {
      return invoice[propertyName] || defaultValue;
    }
    return (
      <InlineEdit
        value={invoice[propertyName] || defaultValue}
        change={this.onPropertyChangeInline.bind(this, propertyName)}
      />
    );
  };

  toggleImageRemovalButton = () => {
    if (this.isReadOnly()) {
      return;
    }

    this.setState({ showImageRemoveButton: !this.state.showImageRemoveButton });
  };

  onRemoveImage = () => {
    var invoice = Object.assign({}, this.state.invoice);
    invoice.hideLogo = true;
    DataActions.updateInvoice(invoice);
    this.setState({ invoice: invoice });
  };

  renderLogo = () => {
    var invoice = this.state.invoice;
    if (invoice.hideLogo) {
      return;
    }
    return (
      <div className="text-right">
        {this.state.showImageRemoveButton ? (
          <AxoLocal
            componentClass={Icon}
            role="button"
            onClick={this.onRemoveImage}
            className="pull-right axored"
            glyph="icon-fontello-scissors axored"
            componentAttribute="title"
            entity="RemoveBCCTitleRemovelogo"
          />
        ) : null}
        <img
          alt=""
          onClick={this.toggleImageRemovalButton}
          height="100px"
          src={UserInfoService.getLogoImageSource(invoice.userProfile)}
        />
      </div>
    );
  };

  handleSelectedClient = (selectedClient) => {
    var invoice = Object.assign({}, this.state.invoice);

    invoice.client = selectedClient;
    invoice.clientNameOverride = "";
    invoice.clientAddressOverride = "";

    this.setState({ invoice: invoice });
    if (this.props.invoice) {
      DataActions.updateInvoice(invoice).then(() => DataStore.fetchContacts());
    }
  };

  render() {
    var {
      clientLabels,
      contactMap,
      cardInformation,
      showPaymentRequests,
      renderButtons,
      renderClientButtons,
    } = this.props;

    var { paymentRequests, invoice } = this.state;

    var client = invoice.client;
    var userProfile = invoice.userProfile;
    var subTotal = this.calculateInvoiceSubTotal(invoice);
    var taxTotal = this.calculateTaxTotal(invoice);
    var expensesTotal = this.calculateExpensesSubTotal(invoice);
    var aConto = this.calculateAContoTotal(invoice);
    var payment = subTotal + taxTotal + expensesTotal - aConto;
    var currency = invoice.currency.toUpperCase();

    return (
      <Row
        id="print-invoice"
        ref={(r) => (this.invoiceNode = r)}
        style={{ margin: "auto" }}
      >
        <Col xs={12}>
          {/* <div>
            <hr style={{marginTop: 0}}/>
          </div> */}
          <Grid fluid>
            <Row>
              <Col xs={6} style={{ paddingTop: 25 }}>
                <span className="pull-left">
                  <h3
                    className="editable"
                    style={{ margin: 0, marginBottom: 12.5 }}
                  >
                    {this.renderInvoiceTypeField(invoice)}{" "}
                    {this.renderNumberField(invoice)}
                  </h3>
                  {!this.isReadOnly() && !!contactMap ? (
                    <AxoLocal
                      componentClass={ClientSearchBox}
                      clients={contactMap.contacts}
                      count={contactMap.count}
                      handleSelectedClient={this.handleSelectedClient}
                      clearOnSelect
                      disabled={false}
                      componentAttribute="placeholder"
                      entity="PrintLayoutSelectClient"
                    />
                  ) : null}
                  <address>
                    <strong className="editable">
                      {this.renderClientNameField()}
                    </strong>
                    <br />
                    {this.renderClientAddressField()}
                    <br />
                  </address>
                </span>
              </Col>
              <Col xs={6} className="text-right" style={{ paddingTop: 25 }}>
                <span className="pull-right">
                  {this.renderLogo()}
                  <br />
                  <address>
                    <strong className="editable">
                      {this.renderInlineEdit(
                        "userCompanyOverride",
                        userProfile.company
                      )}
                    </strong>
                    <br />
                    {this.renderInlineEdit(
                      "userAddressOverride",
                      userProfile.address
                    )}
                    <br />
                    {this.renderInlineEdit(
                      "userCityOverride",
                      userProfile.city
                    )}
                    ,&nbsp;
                    {this.renderInlineEdit(
                      "userPostalCodeOverride",
                      userProfile.postalCode
                    )}
                    <br />
                    {this.renderInlineEdit(
                      "userCountryOverride",
                      userProfile.country
                    )}
                    <br />
                    {userProfile.companyCode ? (
                      <span>
                        <AxoLocal
                          entity="SidebarRightContainerCompanyCode"
                          defaultValue={"CVR"}
                        />
                        :&nbsp;
                        {this.renderInlineEdit(
                          "userCompanyCodeOverride",
                          userProfile.companyCode
                        )}
                        <br />
                      </span>
                    ) : null}
                    <abbr>
                      <AxoLocal
                        entity="CaseEditFormphone"
                        defaultValue={"Tlf"}
                      />
                      :
                    </abbr>{" "}
                    {this.renderInlineEdit(
                      "userPhoneOverride",
                      userProfile.phone
                    )}
                    <br />
                    <div className="hidden-print">
                      <abbr>
                        <AxoLocal
                          entity="CaseEditFormeMail"
                          defaultValue={"Email"}
                        />
                        :
                      </abbr>{" "}
                      <span>
                        {this.renderInlineEdit(
                          "userMailOverride",
                          userProfile.eMail || ""
                        )}
                      </span>
                    </div>
                  </address>
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={12} style={{ padding: "0px", margin: "0px" }}>
                {this.renderTimeEntriesTable(subTotal + expensesTotal)}
              </Col>
            </Row>
          </Grid>
          <hr className="hidden-print" style={{ marginTop: 0 }} />
          <Grid fluid>
            <Row>
              <Col xs={8}>
                <div>{this.renderMessageField(invoice)}</div>
                <br />
                <div>
                  <strong>{this.renderThankYouField(invoice)}</strong>
                </div>
                <div>
                  <AxoLocal
                    entity="invoiceEditFormeMailissued"
                    defaultValue={"Udstedt"}
                  />
                  :&nbsp;{this.renderCreationDateField(invoice)}
                </div>
                <div>
                  {/* { invoice.status !== 2 && invoice.status !== 3 ? ( */}
                  <div>
                    <AxoLocal
                      entity="invoiPaymentatthelatest"
                      defaultValue={"Forfaldsdato"}
                    />
                    :&nbsp;{this.renderDueDateField(invoice)} &nbsp;
                    {!this.isReadOnly() ? (
                      <span>
                        <AxoCheckbox
                          checked={this.state.invoice.sendReminder}
                          onChange={this.onChangeSendReminder}
                          className="hidden-print"
                        />
                        <AxoLocal
                          entity="DocumentGridViewDateCell"
                          defaultValue={"Påmindelse"}
                        />
                      </span>
                    ) : null}
                  </div>
                  {/* ) : null } */}
                </div>
              </Col>
              <Col xs={4}>
                <div className="axocblueabanne text-uppercase text-centered">
                  <h5
                    className="subheader"
                    style={{ margin: 0, padding: 12.5 }}
                  >
                    <AxoLocal
                      entity="invoiPaymentattheTotal"
                      defaultValue={"i alt"}
                    />{" "}
                  </h5>
                </div>
                <div>
                  <Table>
                    <tbody>
                      {this.hasUnits() ? (
                        <tr>
                          <td>
                            {" "}
                            <AxoLocal
                              entity="invoiPaymentattheSubtotal"
                              defaultValue={"Subtotal"}
                            />
                          </td>
                          <td>
                            {currency} {subTotal.toFixed(2)}
                          </td>
                        </tr>
                      ) : null}
                      {this.hasUnits() && taxTotal > 0 ? (
                        <tr>
                          <td>
                            <AxoLocal
                              entity="invoiPaymentattheSubtotalVAT"
                              defaultValue={"Moms"}
                            />
                            ({((taxTotal * 100) / subTotal).toFixed(2)}%)
                          </td>
                          <td>
                            {currency} {taxTotal.toFixed(2)}
                          </td>
                        </tr>
                      ) : null}
                      {taxTotal === 0 && invoice.reverseVAT ? (
                        <tr>
                          <td>
                            <AxoLocal
                              entity="invoiPaymentattheSubtotalVAT"
                              defaultValue={"Moms"}
                            />
                          </td>
                          <td>
                            <AxoLocal
                              entity="axoAccounting67"
                              defaultValue={"Omvendt betalingspligt"}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {this.hasExpenses() ? (
                        <tr>
                          <td>
                            <AxoLocal
                              entity="TimeEntriesViewExpensesLabel"
                              defaultValue={"Udgifter"}
                            />
                          </td>
                          <td>
                            {currency} {expensesTotal.toFixed(2)}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <AxoLocal
                            entity="InvoiceInvoicetotal"
                            defaultValue={"Total"}
                          />
                        </td>
                        <td>
                          <span style={{ borderBottom: "4px double" }}>
                            {currency}{" "}
                            {(subTotal + taxTotal + expensesTotal).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                      {this.hasAConto() ? (
                        <tr>
                          <td>
                            <AxoLocal
                              entity="InvoiceInvoiceAConto"
                              defaultValue={"A Conto"}
                            />
                          </td>
                          <td>
                            {currency} {aConto.toFixed(2)}
                          </td>
                        </tr>
                      ) : null}
                      {this.hasAConto() ? (
                        <tr>
                          <td>
                            {payment >= 0 ? (
                              <span>
                                <AxoLocal
                                  entity="InvoiceInvoiceAConto1"
                                  defaultValue={"Mangler"}
                                />
                              </span>
                            ) : (
                              <span>
                                <AxoLocal
                                  entity="InvoiceInvoiceAConto2"
                                  defaultValue={"Til gode"}
                                />
                              </span>
                            )}
                          </td>
                          <td>
                            {currency}&nbsp;
                            {payment >= 0 ? (
                              <span>{Math.abs(payment).toFixed(2)}</span>
                            ) : (
                              <span>{Math.abs(payment).toFixed(2)}</span>
                            )}
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Grid>
          {/* <hr className='hidden-print' style={{marginTop: 0}}/> */}
          <Grid fluid className="hidden-print">
            <Row>
              <Col xs={12}>
                <div>
                  <Conditional show={this.state.showSharedMessage}>
                    <div className="axoblue">
                      <AxoLocal
                        entity="InvoiceClientCanPay"
                        defaultValue={
                          "Klienten kan nu se og betale fakturaen på selvbetjeningspanelet"
                        }
                      />
                    </div>
                  </Conditional>
                  {renderButtons || renderClientButtons ? (
                    <ButtonToolbar>
                      {this.renderButtons()}
                      {this.renderClientButtons()}
                    </ButtonToolbar>
                  ) : null}
                  <div>
                    {(!!userProfile.stripeAccountId || showPaymentRequests) &&
                    !this.isReadOnly() &&
                    !client &&
                    clientLabels ? (
                      <MultiPaymentSection
                        invoice={invoice}
                        clientLabels={clientLabels}
                        paymentRequests={paymentRequests}
                        userProfile={userProfile}
                      />
                    ) : null}
                  </div>
                  <Conditional show={this.state.showPaymentCompletedMessage}>
                    <div style={{ marginTop: "15px" }}>
                      <AxoLocal
                        entity="InvoicePaymentCompleted"
                        defaultValue={"Betalingen er gennemført"}
                      />
                      .&nbsp;
                      {invoice.currency}{" "}
                      {(subTotal + taxTotal + expensesTotal - aConto).toFixed(
                        2
                      )}
                      &nbsp;
                      {!!cardInformation ? (
                        <React.Fragment>
                          <AxoLocal
                            entity="InvoicePaymentInformation"
                            defaultValue={"vil blive trukket på kortnummer"}
                          />{" "}
                          ************
                          {cardInformation ? cardInformation.last4 : ""}.
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <AxoLocal
                            entity="axoidcode258"
                            defaultValue={"Vil blive trukket på kortet."}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </Conditional>
                  {this.props.showGeneratePDFError ? (
                    <span className="axored">
                      <AxoLocal
                        entity="DocumentTemplateCreateViewisGeneratingPDF"
                        defaultValue={
                          "PDF`en kunne ikke genereres. Prøv igen senere."
                        }
                      />
                    </span>
                  ) : null}
                  <Conditional show={this.state.showPaymentErrorMessage}>
                    <div className="axored">
                      <AxoLocal
                        entity="InvoicePaymentFailed"
                        defaultValue={
                          "Betalingen kunne ikke gennemføres. Prøv igen senere."
                        }
                      />
                    </div>
                  </Conditional>
                </div>
                <br />
              </Col>
            </Row>
          </Grid>
        </Col>
      </Row>
    );
  }
}
