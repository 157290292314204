var timeEntryOptions = {
  optionItems: [],
  casesWithEntries: [],
  clientsWithEntries: [],
  showCases: false,
  selectedOptionId: 0,
  onSelectOption: () => {},
};

var timeEntryOptionSubscribers = [];
function dispatchTimeEntryOptions() {
  timeEntryOptionSubscribers.forEach((subscription) =>
    subscription.callback(timeEntryOptions)
  );
}

export default {
  setTimeEntryOptions(timeEntryOptionsInput) {
    timeEntryOptions = timeEntryOptionsInput;
    dispatchTimeEntryOptions();
  },

  resetOptions() {
    timeEntryOptions = {
      optionItems: [],
      casesWithEntries: [],
      clientsWithEntries: [],
      showCases: false,
      selectedOptionId: 0,
      onSelectOption: () => {},
    };
  },

  subscribeToTimeEntryOptions(subscriberName, callback) {
    timeEntryOptionSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatchTimeEntryOptions();
  },

  unsubscribeFromTimeEntryOptions(subscriberName) {
    timeEntryOptionSubscribers = timeEntryOptionSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },
};
