import React from "react";
import PropTypes from "prop-types";

import { Button, ButtonGroup, ButtonToolbar, Modal } from "react-bootstrap";

import {
  ApiService,
  DataStore,
  FileInfoService,
} from "../../services/AxoServices";

import {
  Icon,
  LoadingIcon,
  getText,
  AxoLocal,
} from "../../utilities/LexUtilities";

import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import "react-virtualized/styles.css";

const cache = new CellMeasurerCache({
  minHeight: 200,
  defaultHeight: 400,
  fixedWidth: true,
});

export default class PrintReceiptsModal extends React.PureComponent {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    selectedContact: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      printingStatus: "Idle",
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({
      showModal: true,
    });
  };

  onOK = () => {
    this.close();
  };

  printEntries = () => {
    let { selectedContact } = this.props;
    this.setState({ printingStatus: "InProgress" });
    ApiService.printReceipts({
      contactId: selectedContact.id,
      label: getText("printReceiptsLabel", "Bilag"),
    })
      .then((response) => {
        if (response.ok) {
          DataStore.fetchDocuments();
          this.setState({ printingStatus: "Success" });
          setTimeout(() => {
            this.setState({ printingStatus: "Idle" });
          }, 3000);
        } else {
          return Promise.reject("Printing failed");
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ printingStatus: "Failed" });
        setTimeout(() => {
          this.setState({ printingStatus: "Idle" });
        }, 3000);
      });
  };

  itemRenderer(entries, { index, key, parent, style }) {

    let EntryNode = this.EntryNode;
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          // 'style' attribute required to position cell (within parent List)
          <div style={style}>
            <EntryNode entry={entries[index]} measure={measure} />
          </div>
        )}
      </CellMeasurer>
    );
  }

  EntryNode = (props) => {
    let { entry, measure } = props;

    return (
      <div key={entry.id}>
        <div
          className="text-center"
          style={{
            fontSize: "18px",
            color: "red",
            position: "relative",
            zIndex: 9,
          }}
        >
          <AxoLocal entity="printReceiptsLabel" defaultValue="Bilag" />{" "}
          {entry.receiptNumber}
        </div>
        {!!entry.receipt ? (
          <div
            className="text-center"
            style={{
              height: "100%",
              width: "90%",
              margin: "auto",
              position: "relative",
              bottom: "35px",
            }}
          >
            {FileInfoService.isImage(entry.receipt) ? (
              <img
                width="100%"
                alt={
                  getText("printReceiptsLabel", "Bilag") +
                  " " +
                  entry.receiptNumber.toString()
                }
                src={ApiService.getFileInlinePath(entry.receiptId)}
                onLoad={measure}
              />
            ) : (
              <div>
                <object 
                  data={ApiService.getFileInlinePath(entry.receiptId)} 
                  type="application/pdf" 
                  style={{ flexGrow: 1, border: "none", minHeight: "600px" }}
                  width="100%" 
                  height="100%">
                  <div>Your browser does not support PDF viewing.</div>
                </object>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  PrintButton = ({ printingStatus, onPrint }) => {
    return (
      <Button
        className="Lex-button-2"
        disabled={printingStatus !== "Idle"}
        onClick={onPrint}
      >
        <Icon glyph="icon-fontello-print-3" />
        &nbsp;&nbsp;
        <AxoLocal entity="PrintCaseClientadressePrint" defaultValue={"Print"} />
        <LoadingIcon show={printingStatus === "InProgress"} />
        {printingStatus === "Success" ? (
          <span>
            &nbsp;
            <Icon glyph="icon-fontello-ok-3" />
          </span>
        ) : null}
        {printingStatus === "Failed" ? (
          <span>
            &nbsp;
            <AxoLocal
              entity="LoginshowServerError"
              defaultValue={"Der skete en fejl. Prøv igen senere."}
            />
          </span>
        ) : null}
      </Button>
    );
  };

  render() {
    let { showModal, printingStatus } = this.state;
    let { entries } = this.props;

    let PrintButton = this.PrintButton;
    return (
      <Modal show={showModal} onHide={this.close} bsSize={this.props.size}>
        <Modal.Header
          className="backgroundModalHeaderFooter"
          closeButton
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          <Modal.Title>
            <div className="flexbox-layout">
              <div>
                <PrintButton
                  printingStatus={printingStatus}
                  onPrint={this.printEntries}
                />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            height: "700px",
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                rowCount={entries.length}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={(args) => this.itemRenderer(entries, args)}
                width={width}
              />
            )}
          </AutoSizer>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <PrintButton
                printingStatus={printingStatus}
                onPrint={this.printEntries}
              />
              <Button className="Lex-button-2" onClick={this.close}>
                <span>
                  {" "}
                  <Icon glyph="icon-fontello-cancel" />
                  &nbsp;&nbsp;
                  <AxoLocal entity="Docfileclose" defaultValue={"Luk"} />{" "}
                </span>
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
