import React, { useEffect } from "react";
import EasyIDFrontPage from "./EasyIDFrontPage";

import { DataStore } from "../services/AxoServices";

import { withData } from "../utilities/LexUtilities";

function EasyIdFrontPageContainer(props) {
  useEffect(() => {
    DataStore.fetchSubscriptionPlans();
  }, []);

  let { subscriptionPlans } = props;

  let plan1 = subscriptionPlans.find((p) => p.planId === "EasyID1");
  let plan2 = subscriptionPlans.find((p) => p.planId === "EasyID2");
  let plan3 = subscriptionPlans.find((p) => p.planId === "EasyID3");

  return (
    <EasyIDFrontPage plan1={plan1} plan2={plan2} plan3={plan3} {...props} />
  );
}

export default withData(EasyIdFrontPageContainer, (store) => {
  return {
    subscriptionPlans: store.subscriptionPlans.filter((sub) => {
      return sub.type === "EasyID";
    }),
  };
});
