import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import DataActions from "../../services/DataAccess/DataActions";
import LabelInbox from "./LabelInbox";
import ModalService from "../../services/ModalService";

import { AxoLocal, Icon } from "../../utilities/LexUtilities";
import { DataStore, AuthorizationService } from "../../services/AxoServices";
import Conditional from "../../utilities/Conditional";
import LexNavButton from "../../utilities/LexNavButton";
import { withRouter } from "react-router-dom";
import { SmallOrSmaller, MediumOrLarger } from "../../utilities/Responsive";

import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";

class LabelTabView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      messages: props.messages,
      cases: props.cases,
      documents: props.documents,
      newLabelName: "",
      labelCreated: "",
      selectedLabelId: 0,
      showDuplicateNameWarning: false,
    };
  }

  handleActiveState = (eventKey) => {
    if (!eventKey) {
      return;
    }
    this.props.handleNavigation(eventKey);
  };

  componentWillReceiveProps(nextProps) {
    //Reselect label to update state
    if (this.state.selectedLabelId) {
      this.onSelectLabel(this.state.selectedLabelId, nextProps);
    }
  }

  onSelectLabel = (labelId, props) => {
    var label = props.labels.find((l) => l.id === labelId);
    if (!label) {
      return;
    }

    var messages = props.messages.filter((m) =>
      label.messageIds.includes(parseInt(m.id, 10))
    );
    var cases = props.cases.filter((m) => label.caseIds.includes(m.id));
    var documents = props.documents.filter((d) =>
      label.documentIds.includes(d.id)
    );
    var clients = props.clients.filter((c) => label.clientIds.includes(c.id));

    //Load contents from server if not all content is available in the browser cache
    if (
      messages.length !== label.messageIds.length ||
      cases.length !== label.caseIds.length ||
      documents.length !== label.documentIds.length ||
      clients.length !== label.clientIds.length
    ) {
      ApiService.getSingleLabel(label.id).then((fullLabel) => {
        this.setState({
          selectedLabelId: label.id,
          messages: fullLabel.messages,
          cases: fullLabel.cases,
          documents: fullLabel.documents,
          clients: fullLabel.clients,
        });
      });
    } else {
      this.setState({
        selectedLabelId: label.id,
        messages,
        cases,
        documents,
        clients,
      });
    }
  };

  updateNewLabelName = (event) => {
    this.setState({ newLabelName: event.target.value });
  };

  getLabelTypeFromTab(activeTab) {
    switch (activeTab.toLowerCase()) {
      case "mails":
        return 0;
      case "cases":
        return 1;
      case "documents":
        return 2;
      case "clients":
        return 3;
      default:
        return 0;
    }
  }

  onLabelCreate = (labelType) => {
    var { newLabelName } = this.state;

    if (!newLabelName) {
      return;
    }

    var label = {
      name: newLabelName,
      type: labelType,
    };

    this.setState({ newLabelName: "" });

    DataActions.createLabel(label).then((response) => {
      if (response.status === 200) {
        response.json().then((createdLabel) => {
          this.setState({
            labelCreated: true,
            selectedLabelId: createdLabel.id,
          });
        });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="CaseThefoldercouldnotbecreated"
            defaultValue={"Mappen kunne ikke oprettes. Prøv igen senere."}
          />
        );
      }
    });
  };

  getIconColor = (eventKey) => {
    if (this.props.activeTab === eventKey) {
      return "editable";
    }
    return "axoblack";
  };

  getDropDownIcon = () => {
    let { activeTab } = this.props;

    if (activeTab === "mails") {
      return "icon-fontello-plus-1";
    }
    if (activeTab === "cases") {
      return "icon-fontello-plus-1";
    }
    if (activeTab === "documents") {
      return "icon-fontello-plus-1";
    }
    return "";
  };

  getButtonClass = (eventKey) => {
    if (this.props.activeTab === eventKey) {
      return "Lex-button-2 active";
    }
    return "Lex-button-2";
  };

  renderLargeScreenNavigationBar = () => {
    let { userProfile, showDropdowns, activeTab } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);
    return (
      <MediumOrLarger>
        <Grid fluid className="axo-nav-lg" id="noprint">
          {!showDropdowns ? (
            <Row>
              <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{
                    borderRadius: "0px",
                    paddingTop: "0px",
                    paddingLeft: "2px",
                    borderRight: "0px",
                  }}
                >
                  {userType === "Society" ? (
                    <LexNavButton
                      className="axonavTab axonavTabcolora"
                      ls
                      glyph="icon-simple-line-icons-folder-alt"
                      eventKey="Clients"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      &nbsp;&nbsp;&nbsp;
                      <span>
                        {" "}
                        <AxoLocal
                          entity="axoidcode12"
                          defaultValue={"Medlemmer"}
                        />
                      </span>
                    </LexNavButton>
                  ) : null}

                  <LexNavButton
                    className="axonavTab axonavTabcolorb"
                    ls
                    glyph="icon-fontello-docs-1"
                    eventKey="Documents"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="LabelTabViewDocumentfolders"
                      defaultValue={"Dokumentmapper"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorc"
                    ls
                    glyph="icon-fontello-mail-1"
                    eventKey="Mails"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="LabelTabViewMailFolders"
                      defaultValue={"Mailmapper"}
                    />
                  </LexNavButton>
                  {userType !== "Accountant" && userType !== "Society" ? (
                    <LexNavButton
                      className="axonavTab axonavTabcolord"
                      ls
                      glyph="icon-simple-line-icons-briefcase"
                      eventKey="Cases"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      &nbsp;&nbsp;&nbsp;
                      <AxoLocal
                        entity="LabelTabViewCaseFolders"
                        defaultValue={"Sagsmapper"}
                      />
                    </LexNavButton>
                  ) : null}
                  {userType !== "Society" ? (
                    <LexNavButton
                      className="axonavTab axonavTabcolore"
                      ls
                      glyph="icon-simple-line-icons-folder-alt"
                      eventKey="Clients"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      &nbsp;&nbsp;&nbsp;
                      <span>
                        <AxoLocal
                          entity="clientMenuNav"
                          defaultValue={"Klienter"}
                        />{" "}
                      </span>
                    </LexNavButton>
                  ) : null}
                  <LexNavButton
                    ls
                    listButton
                    className="axonavTab showListButton"
                    onClick={() => {
                      DataStore.setShowDropDowns(true);
                    }}
                  >
                    <div className="flexbox-center">
                      <Icon
                        glyph="icon-fontello-align-justify"
                        style={{ fontSize: "30px" }}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity66"
                        defaultValue={"Liste Menu"}
                      />
                    </div>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Documents")}
                >
                  <span className="icon-fontello-docs-1" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="LabelTabViewDocumentfolders"
                    defaultValue={"Dokumentmapper"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Mails")}
                >
                  <span className="icon-fontello-mail-1" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="LabelTabViewMailFolders"
                    defaultValue={"Mailmapper"}
                  />
                </MenuItem>
                {userType !== "Accountant" && userType !== "Society" ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Cases")}
                  >
                    <span className="icon-simple-line-icons-briefcase" />{" "}
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="LabelTabViewCaseFolders"
                      defaultValue={"Sagsmapper"}
                    />
                  </MenuItem>
                ) : null}
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Clients")}
                >
                  {userType === "Society" ? (
                    <span>
                      {" "}
                      <AxoLocal
                        entity="axoidcode12"
                        defaultValue={"Medlemmer"}
                      />
                    </span>
                  ) : (
                    <AxoLocal
                      entity="clientMenuNav"
                      defaultValue={"Klienter"}
                    />
                  )}
                </MenuItem>
              </DropdownButton>
            </Row>
          )}
        </Grid>
      </MediumOrLarger>
    );
  };

  renderSmallScreenNavigationBar = () => {
    let { userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <SmallOrSmaller>
        <Grid fluid className="axo-nav" id="noprint">
          <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("Documents")}
              >
                <span className="icon-fontello-docs-1" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="LabelTabViewDocumentfolders"
                  defaultValue={"Dokumentmapper"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("Mails")}
              >
                <span className="icon-fontello-mail-1" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="LabelTabViewMailFolders"
                  defaultValue={"Mailmapper"}
                />
              </MenuItem>

              {userType !== "Accountant" && userType !== "Society" ? (
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Cases")}
                >
                  <span className="icon-simple-line-icons-briefcase" />{" "}
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="LabelTabViewCaseFolders"
                    defaultValue={"Sagsmapper"}
                  />
                </MenuItem>
              ) : null}
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("Clients")}
              >
                <span className="icon-simple-line-icons-folder-alt" />{" "}
                &nbsp;&nbsp;
                <AxoLocal
                  entity="ContactTableViewclients"
                  defaultValue={"Klienter"}
                />
              </MenuItem>
            </DropdownButton>
          </Row>
        </Grid>
      </SmallOrSmaller>
    );
  };

  renderNavigationBar = () => {
    return (
      <div className="Tabbanne" style={{ paddingTop: "0px" }}>
        {this.renderLargeScreenNavigationBar()}
        {this.renderSmallScreenNavigationBar()}
      </div>
    );
  };

  renderNavigationContent = () => {
    var {
      labels,
      userProfile,
      activeTab,
      userSettings,
      adminCodeValidated,
      updateContact,
      refetchContacts,
    } = this.props;
    var { newLabelName } = this.state;

    var messageLabels = labels.filter((l) => l.type === 0);
    var caseLabels = labels.filter((l) => l.type === 1);
    var documentLabels = labels.filter((l) => l.type === 2);
    var clientLabels = labels.filter((l) => l.type === 3);
    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div>
        <Conditional show={activeTab === "Documents"}>
          <LabelInbox
            documents={this.state.documents}
            labelType={2}
            labels={documentLabels}
            userProfile={userProfile}
            userSettings={userSettings}
            userName={this.props.userName}
            selectedLabelId={this.state.selectedLabelId}
            onSelectLabel={(id) => this.onSelectLabel(id, this.props)}
            newLabelName={newLabelName}
            adminCodeValidated={adminCodeValidated}
            updateNewLabelName={this.updateNewLabelName}
            onLabelCreate={this.onLabelCreate}
            updateContact={updateContact}
            refetchContacts={refetchContacts}
          />
        </Conditional>
        <Conditional show={activeTab === "Mails"}>
          <LabelInbox
            messages={this.state.messages}
            labelType={0}
            labels={messageLabels}
            userProfile={userProfile}
            userSettings={userSettings}
            userName={this.props.userName}
            selectedLabelId={this.state.selectedLabelId}
            onSelectLabel={(id) => this.onSelectLabel(id, this.props)}
            newLabelName={newLabelName}
            adminCodeValidated={adminCodeValidated}
            updateNewLabelName={this.updateNewLabelName}
            onLabelCreate={this.onLabelCreate}
            updateContact={updateContact}
            refetchContacts={refetchContacts}
          />
        </Conditional>
        <Conditional show={userType !== "Accountant" && activeTab === "Cases"}>
          <LabelInbox
            cases={this.state.cases}
            labelType={1}
            labels={caseLabels}
            userProfile={userProfile}
            userSettings={userSettings}
            userName={this.props.userName}
            selectedLabelId={this.state.selectedLabelId}
            onSelectLabel={(id) => this.onSelectLabel(id, this.props)}
            newLabelName={newLabelName}
            adminCodeValidated={adminCodeValidated}
            updateNewLabelName={this.updateNewLabelName}
            onLabelCreate={this.onLabelCreate}
            updateContact={updateContact}
            refetchContacts={refetchContacts}
          />
        </Conditional>
        {activeTab === "Clients" ? (
          <LabelInbox
            clients={this.state.clients}
            labelType={3}
            labels={clientLabels}
            userProfile={userProfile}
            userSettings={userSettings}
            userName={this.props.userName}
            selectedLabelId={this.state.selectedLabelId}
            onSelectLabel={(id) => this.onSelectLabel(id, this.props)}
            newLabelName={newLabelName}
            adminCodeValidated={adminCodeValidated}
            updateNewLabelName={this.updateNewLabelName}
            onLabelCreate={this.onLabelCreate}
            updateContact={updateContact}
            refetchContacts={refetchContacts}
          />
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>
          <Grid fluid>
            <Row className="dropdown-form-styling">
              <Col xs={12} className="nopadding">
                {this.renderNavigationBar()}
                {this.renderNavigationContent()}
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(LabelTabView);
