import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import QueryString from "query-string";

import {
  ApiService,
  StorageService,
  ModalService,
} from "../services/AxoServices";

import {
  withData,
  // EasyIDView,
  Flexbox,
  AxoLocal,
  FlagMenu,
  FlexElement,
  FileViewer,
  LoadingIndicator,
  LexButton,
  EasyIDSigningModal,
  AsyncButton,
  getText,
  AlertModal,
  MitIDSigningModal,
} from "../utilities/LexUtilities";

import { FormControl, ControlLabel, ButtonToolbar } from "react-bootstrap";

import { SmallOrSmaller, MediumOrLarger } from "../utilities/Responsive";

import SignatureForm from "./SignatureForm";
import moment from "moment";

const ValidationProgress = {
  NONE: 1,
  SENT: 2,
  VALIDATED: 3,
};

function SigningHeader(props) {
  if (props.hide) {
    return <>{props.children}</>;
  }
  return (
    <div
      className="login SideHeight standardMaxWidth center-block"
      style={{ paddingTop: "10px" }}
    >
      <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center">
        Signér dokument &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-md"
          src="/imgs/app/Axologo3-30x30.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-md hidden-xs"
          src="/imgs/app/Axologo3-35x35.png"
        />
        <img
          alt=""
          className="hidden-lg hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        <img
          alt=""
          className="hidden-md hidden-sm hidden-xs"
          src="/imgs/app/Axologo3-40x40.png"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FlagMenu userSettings={props.userSettings || {}} />
      </div>
      {props.children}
    </div>
  );
}

let signingRequest, setSigningRequest;
let currentSigner, setCurrentSigner;
let showError, setShowError;
let addingSignature, setAddingSignature;
let creatingSignature, setCreatingSignature;
let validationProgress, setValidationProgress;
let twoFactorCode, setTwoFactorCode;
let showLoadingIndicator, setShowLoadingIndicator;
let downloadButton;

function SigningView(props) {
  [signingRequest, setSigningRequest] = useState(null);
  [currentSigner, setCurrentSigner] = useState(null);
  [showError, setShowError] = useState(false);
  [addingSignature, setAddingSignature] = useState(false);
  [creatingSignature, setCreatingSignature] = useState(false);
  [validationProgress, setValidationProgress] = useState(
    ValidationProgress.NONE
  );
  [twoFactorCode, setTwoFactorCode] = useState(
    StorageService.loadSessionValue("signingTwoFactor", "")
  );
  [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  let signingModal = React.createRef();
  let mitIDSigningModal = React.createRef();
  downloadButton = React.createRef();

  let { guid } = useParams();

  let { isOwner } = props;

  let query = QueryString.parse(props.location.search) || {};
  let signerGuid = query.signer;

  const fetchSigningRequest = async ({
    generateSignedDocumentOnCompletion,
  }) => {
    let newRequest = await ApiService.getSigningRequest(guid);
    if (!newRequest.id) {
      setShowError(true);
    }

    setSigningRequest(newRequest);
    setCurrentSigner(newRequest.signers.find((s) => s.guid === signerGuid));

    if (
      !isOwner &&
      signingRequest.validatePhone &&
      validationProgress !== ValidationProgress.VALIDATED &&
      !!signerGuid &&
      !!twoFactorCode
    ) {
      setShowLoadingIndicator(true);
      await submitTwoFactor(newRequest);
      setShowLoadingIndicator(false);
    }

    if (
      generateSignedDocumentOnCompletion &&
      !newRequest.signers.find((s) => !s.signed)
    ) {
      //Generate signed document when all signatures are completed
      generateSignedDocument();
    }
  };

  const createSignature = async (signer) => {
    if (signer.signatureType === "EasyID") {
      signingModal.current.open(async (signature) => {
        setCreatingSignature(true);
        await ApiService.generateSignature({
          ...signer,
          signerGuid,
          ...signature,
          signingRequestGuid: guid,
        });

        await fetchSigningRequest({ generateSignedDocumentOnCompletion: true });

        setAddingSignature(false);
        setCreatingSignature(false);

        // Necessary to reset the Easy ID session
        // window.location.reload();
      });
    } else if (signer.signatureType === "MitID" || signer.signatureType === "NemLogIn" ) {
      mitIDSigningModal.current.open(signer);
    }
    else {
      setCreatingSignature(true);

      await ApiService.createSignature({
        ...signer,
        signerGuid,
        signingRequestId: signingRequest.id,
      });

      await fetchSigningRequest({ generateSignedDocumentOnCompletion: true });

      setAddingSignature(false);
      setCreatingSignature(false);
    }
  };

  const generateSignedDocument = async () => {
    let response = await ApiService.generateSignedDocument(guid);

    await fetchSigningRequest({ generateSignedDocumentOnCompletion: false });

    return response.ok;
  };

  useEffect(() => {
    if (
      !!signingRequest &&
      !!signingRequest.signedDocumentId &&
      downloadButton.current
    ) {
      downloadButton.current.click();
    }
  }, !!signingRequest && signingRequest.signedDocumentId);

  const sendTwoFactor = async () => {
    let response = await ApiService.sendSigningRequestTwoFactor({
      signingRequestId: signingRequest.id,
      email: currentSigner.email,
    });

    if (response.ok) {
      setValidationProgress(ValidationProgress.SENT);
    }

    return response.ok;
  };

  const submitTwoFactor = async (currentRequest) => {
    let response = await ApiService.validateSigningRequestTwoFactor({
      signingRequestId: currentRequest.id,
      email: currentSigner.email,
      code: twoFactorCode,
    });

    if (!response.ok) {
      return false;
    }

    setValidationProgress(ValidationProgress.VALIDATED);
    StorageService.saveSessionValue("signingTwoFactor", twoFactorCode);

    return response.ok;
  };

  useEffect(() => {
    if (!guid) {
      setShowError(true);
      return;
    }

    fetchSigningRequest({ generateSignedDocumentOnCompletion: false });
  }, []);

  const onSubmitKeyDown = (e) => {
    if (e.keyCode === 13) {
      submitTwoFactor(signingRequest);
    }
  };

  const sendSignedDocument = async () => {
    let response = await ApiService.sendSignedFileToSigners(guid);
    return response.ok;
  };

  if (showLoadingIndicator || !signingRequest) {
    return (
      <SigningHeader userSettings={props.userSettings} hide={props.isOwner}>
        <LoadingIndicator />
      </SigningHeader>
    );
  }

  if (showError) {
    return (
      <SigningHeader userSettings={props.userSettings} hide={props.isOwner}>
        <div className="largeText">
          <AxoLocal
            entity="axoEntityidcode232"
            defaultValue={"Dokumentet kunne ikke findes."}
          />
        </div>
      </SigningHeader>
    );
  }

  if (!!signingRequest && !isOwner) {
    if (!currentSigner) {
      return (
        <SigningHeader userSettings={props.userSettings} hide={isOwner}>
          <div className="topPadding leftPadding rightPadding">
            <h4>
              <AxoLocal
                entity="axoEntityidcode233"
                defaultValue={"Du har ikke adgang til denne side."}
              />
            </h4>
          </div>
        </SigningHeader>
      );
    }
    if (currentSigner.signed) {
      return (
        <SigningHeader userSettings={props.userSettings} hide={isOwner}>
          <div className="topPadding leftPadding rightPadding">
            <h4>
              {/* MissingEntity */}
              Tak. Du har signeret dette dokument. Du kan nu lukke browseren.
            </h4>
          </div>
        </SigningHeader>
      );
    }
    if (signingRequest.validatePhone) {
      if (validationProgress === ValidationProgress.NONE) {
        return (
          <SigningHeader userSettings={props.userSettings} hide={isOwner}>
            <div className="topPadding leftPadding rightPadding">
              <ControlLabel
                className="rightPadding"
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  entity="axoEntityidcode234"
                  defaultValue={
                    "Din adgang skal valideres. Tryk på knappen for at sende en valideringskode til det telefonnummer som afsenderen har angivet."
                  }
                />
              </ControlLabel>
              <div className="topPadding">
                <ButtonToolbar>
                  <AsyncButton
                    title={getText(
                      "axoEntityidcode236",
                      "Koden vil blive sendt til det telefonnummer som afsenderen har angivet. Hvis du ikke modtager koden, så kontakt afsenderen."
                    )}
                    onClick={sendTwoFactor}
                  >
                    <AxoLocal
                      entity="axoEntityidcode235"
                      defaultValue={"Send to-faktor kode til telefon."}
                    />
                  </AsyncButton>
                </ButtonToolbar>
              </div>
            </div>
          </SigningHeader>
        );
      }
      if (validationProgress === ValidationProgress.SENT) {
        return (
          <SigningHeader userSettings={props.userSettings} hide={isOwner}>
            <div className="topPadding leftPadding rightPadding">
              <ControlLabel
                className="rightPadding"
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  entity="axoEntityidcode237"
                  defaultValue={
                    "Der er blevet sendt en to-faktor kode til din telefon. Indtast koden herunder, eller tryk gensend kode uden at indtaste noget hvis du ikke har modtaget den."
                  }
                />
              </ControlLabel>
              <FormControl
                autoFocus
                type="text"
                value={twoFactorCode}
                onKeyDown={onSubmitKeyDown}
                onChange={(event) => setTwoFactorCode(event.target.value)}
              />
              <div className="topPadding">
                <ButtonToolbar>
                  <AsyncButton
                    key="submit"
                    disabled={!twoFactorCode}
                    onClick={() => submitTwoFactor(signingRequest)}
                  >
                    <AxoLocal
                      entity="axoEntityidcode238"
                      defaultValue={"Indsend kode"}
                    />
                  </AsyncButton>
                  <AsyncButton
                    title={getText(
                      "axoEntityidcode249",
                      "Koden vil blive sendt til det telefonnummer som afsenderen har angivet. Hvis du ikke modtager koden, så kontakt afsenderen."
                    )}
                    onClick={sendTwoFactor}
                  >
                    <AxoLocal
                      entity="axoEntityidcode239"
                      defaultValue={"Gensend to-faktor kode til telefon"}
                    />
                  </AsyncButton>
                </ButtonToolbar>
              </div>
            </div>
          </SigningHeader>
        );
      }
    }
  }

  let isSigned = signingRequest.signatures.length > 0;

  return (
    <SigningHeader userSettings={props.userSettings} hide={isOwner}>
      <Flexbox collapseSM className="topPadding leftPadding rightPadding">
        <FlexElement flex={1} style={{ position: "relative" }}>
          <FileViewer
            file={signingRequest.fileInfoModel}
            maxHeight={600}
            getter={() =>
              ApiService.getRequestFileDownloadPath(
                signingRequest.guid,
                signerGuid,
                twoFactorCode
              )
            }
          />
        </FlexElement>
        <FlexElement flex={1}>
          <div className="largeText text-center signaturesover1">
            <AxoLocal
              entity="axoEntityidcode240"
              defaultValue={"Underskrifter"}
            />
          </div>
          {signingRequest.signatures.length > 0 && isOwner && (
            <div className="leftPadding">
              <div className="bottomPadding signaturesover3">
                <b>
                  <AxoLocal
                    entity="axoEntityidcode241"
                    defaultValue={"Dokumentet er signeret af"}
                  />
                </b>
                :
              </div>
              {signingRequest.signatures.map((s) => {
                let idLabel = "";
                switch(s.signatureType) {
                  case "EasyID":
                    idLabel = "NemID Serienummer: ";
                    break;
                  case "MitID":
                    idLabel = "MitID UUID: ";
                    break;
                  case "NemLogIn":
                    idLabel = "NL3UUID: ";
                    break;
                }
                return (
                  <div className="bottomPadding">
                    <div>
                      <strong>{s.signerName}</strong>{" "}
                    </div>
                    {!!s.signerTitle && <div>{s.signerTitle}</div>}
                    {!!s.onBehalfOf && (
                      <div className="signaturesover3">
                        <AxoLocal
                          entity="axoEntityidcode242"
                          defaultValue={"På vegne af"}
                        />
                        : {s.onBehalfOf}
                      </div>
                    )}
                    <div>{moment.utc(s.date).format("LLL")}</div>
                    {!!s.commonName && (
                      <div className="signaturesover3">
                        <AxoLocal
                          entity="axoEntityidcode243"
                          defaultValue={"NemID Navn"}
                        />
                        : {s.commonName}
                      </div>
                    )}
                    {!!s.serialNumber && (
                      <>
                        <div>{idLabel}{s.serialNumber}</div>
                        {isOwner && (
                          <div>
                            <a
                              href={ApiService.getRequestSignDataDownloadPath(
                                s.id
                              )}
                              download
                            >
                              <AxoLocal
                                entity="axoEntityidcode244"
                                defaultValue={"Download signaturdata"}
                              />
                            </a>
                          </div>
                        )}
                      </>
                    )}
                    {!!s.signatureImageData && (
                      <div>
                        <img
                          src={"data:image/png;base64," + s.signatureImageData}
                          alt="Signature"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {/* No more signatures can be added when the document has been generated */}
          {!signingRequest.signedDocumentId && !!currentSigner && (
            <>
              {addingSignature ? (
                <div className="leftPadding">
                  <SignatureForm
                    onSubmit={createSignature}
                    creatingSignature={creatingSignature}
                    allowImageSignature={signingRequest.allowImageSignatures}
                    onCancel={() => setAddingSignature(false)}
                  />
                </div>
              ) : (
                <div className="text-center signaturesover1">
                  <LexButton onClick={() => setAddingSignature(true)}>
                    <AxoLocal
                      entity="axoEntityidcode245"
                      defaultValue={"Underskriv her"}
                    />
                  </LexButton>
                </div>
              )}
            </>
          )}
          {isSigned && (
            <div className="text-center topPadding">
              {!!signingRequest.signedDocumentId ? (
                <>
                  <div>
                    <a
                      ref={downloadButton}
                      key={signerGuid + twoFactorCode}
                      href={ApiService.getRequestSignedFileDownloadPath(
                        guid,
                        signerGuid,
                        twoFactorCode
                      )}
                      download
                    >
                      <LexButton>
                        <AxoLocal
                          entity="axoEntityidcode246"
                          defaultValue={"Download signeret dokument"}
                        />
                      </LexButton>
                    </a>
                  </div>
                  {isOwner && (
                    <div className="topPadding">
                      <AsyncButton onClick={sendSignedDocument}>
                        Send signeret dokument til underskriverne
                      </AsyncButton>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isOwner && (
                    <AsyncButton hideOKButton onClick={generateSignedDocument}>
                      <AxoLocal
                        entity="axoEntityidcode247"
                        defaultValue={" Generer signeret dokument"}
                      />
                    </AsyncButton>
                  )}
                </>
              )}
            </div>
          )}
          <div style={{ paddingTop: "50px" }}></div>
        </FlexElement>
      </Flexbox>
      <SmallOrSmaller>
        <EasyIDSigningModal ref={signingModal} small guid={guid} />
      </SmallOrSmaller>
      <MediumOrLarger>
        <EasyIDSigningModal ref={signingModal} guid={guid} />
      </MediumOrLarger>
      <MitIDSigningModal ref={mitIDSigningModal} guid={guid} signerGuid={signerGuid} />
      <AlertModal ref={(c) => ModalService.registerAlertModal(c)} />
    </SigningHeader>
  );
}

export default withData(SigningView, (store) => {
  return {
    userSettings: store.userSettings,
  };
});
