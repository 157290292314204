import React from "react";
import PropTypes from "prop-types";

import { DataActions } from "../services/AxoServices";

import { Icon } from "../utilities/LexUtilities";

import { Table } from "react-bootstrap";

export default class BusinessOperatingTableView extends React.PureComponent {
  static propTypes = {
    businessOperatings: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  onDelete = (id) => {
    DataActions.deleteBusinessOperating(id);
  };

  onUpdate = (model) => {
    //Replaces the object with the same id with this object
    DataActions.updateBusinessOperating(model);
  };

  render() {
    let { businessOperatings, onGotoDetails } = this.props;

    return (
      <div className="standardMaxWidth leftPadding center-block">
        <Table bordered condensed hover>
          <thead>
            <tr>
              <th>Firmanavn</th>
              <th>Stifter</th>
              <th>CPR/CVR</th>
              <th>Stifter adresse</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {businessOperatings.map((model) => (
              <tr
                role="button"
                key={model.id}
                onClick={() => onGotoDetails(model.id)}
              >
                <td className="editable">{model.companyName}</td>
                {!!model.owners && model.owners.length > 0 ? (
                  <>
                    <td>{model.owners[0].name}</td>
                    <td>{model.owners[0].identityCode}</td>
                    <td>{model.owners[0].address}</td>
                  </>
                ) : (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}

                <td>
                  <Icon
                    role="button"
                    onClick={() => this.onDelete(model.id)}
                    glyph="icon-fontello-trash-1"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
