import React from "react";
import { Consumer, withRouter } from "../../utilities/LexUtilities";

import { DataStore } from "../../services/AxoServices";

import AccountPlansContainer from "./AccountPlansContainer";

class AccountPlansConsumer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeFinanceAccountPlans();
    DataStore.fetchStandardFinanceAccountPlans();
    DataStore.initializeUserProfile();
    DataStore.fetchGlobalSettings();
  }

  mapStoreToProps = (store) => {
    return {
      financeAccountPlans: store.financeAccountPlans.map((fa) => {
        return {
          ...fa,
          accounts: fa.accounts.map((id) => store.financeAccounts[id]),
          taxSpecifications: fa.taxSpecifications.map(
            (id) => store.taxSpecifications[id]
          ),
        };
      }),
      financeAccounts: store.financeAccounts,
      standardFinanceAccountPlans: store.standardFinanceAccountPlans,
      userProfile: store.userProfile,
      globalSettings: store.globalSettings,
      showDropdowns: store.showDropdowns,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => (
          <AccountPlansContainer
            {...this.mapStoreToProps(store)}
            {...this.props}
          />
        )}
      </Consumer>
    );
  }
}

export default withRouter(AccountPlansConsumer);
