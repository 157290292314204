import React from "react";
import DataStore from "../../services/DataAccess/DataStore";
import DataActions from "../../services/DataAccess/DataActions";
import ContactTableView from "../../routes/Contacts/ContactTableView";
import { debounce } from "lodash";

import {
  withRouter,
  withData,
  withRTKData,
} from "../../utilities/LexUtilities";

class ClientCustomerContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.initializeLabels();
    DataStore.initializeUserGroup();
  }

  componentWillUnmount() {
    this.props.resetContactPageSize();
  }

  resetContactQuery = () => {
    let { contactQueryParams } = this.props;
    if (Object.keys(contactQueryParams).length > 0) {
      this.queryContacts({});
    }
  };

  queryContacts = debounce((query) => {
    let { setContactQueryParams } = this.props;

    let { searchText, selectedFromDate, selectedToDate } = query;
    //The spread operator only adds the property if the conditional is truthy.
    setContactQueryParams({
      ...(!!searchText ? { searchText } : {}),
      ...(!!selectedFromDate ? { fromDate: selectedFromDate.format() } : {}),
      ...(!!selectedToDate ? { toDate: selectedToDate.format() } : {}),
    });
  }, 350);

  onToggleCards = () => {
    let { userSettings } = this.props;

    DataActions.updateUserSettings({
      ...userSettings,
      showClientCards: !userSettings.showClientCards,
    });
  };

  render() {
    let { contactMap } = this.props;

    return (
      <ContactTableView
        {...this.props}
        contacts={contactMap.contacts}
        count={contactMap.count}
        queryContacts={this.queryContacts}
        resetContactQuery={this.resetContactQuery}
        sharingGlobals={[]}
        onToggleCards={this.onToggleCards}
      />
    );
  }
}

const mapStoreToProps = (store) => {
  let filteredLabels = store.labels.filter((l) => l.type === 3); //Clients
  let sortedLabels = filteredLabels.sort((l1, l2) =>
    l1.name.localeCompare(l2.name)
  );

  return {
    userProfile: store.userProfile,
    userSettings: store.userSettings,
    forceShowCards: store.userSettings.showClientCards,
    labels: sortedLabels,
    showDropdowns: store.showDropdowns,
    locale: store.locale,
  };
};

export default withRTKData(
  withRouter(withData(ClientCustomerContainer, mapStoreToProps))
);
