import React from "react";
import SystemUsersView from "./SystemUsersView";
import SubscriptionPlanView from "./SubscriptionPlanView";
import CreatePlanView from "./CreatePlanView";
import SubscriptionFormulasTable from "./SubscriptionFormulasTable";
import CreateSubscriptionFormulaView from "./CreateSubscriptionFormulaView";
import CollaborationContainer from "./CollaborationContainer";
import PriceTableSelector from "./PriceTableSelector";
import ItemPriceFormSelector from "./ItemPriceFormSelector";
import LegalPresentationContainer from "./LegalPresentation/LegalPresentationContainer";
import LegalPresentationListContainer from "./LegalPresentation/LegalPresentationListContainer";
import LegalPresentationSingleContainer from "./LegalPresentation/LegalPresentationSingleContainer";

import { SmallOrSmaller, MediumOrLarger } from "../../utilities/Responsive";
import { DataStore } from "../../services/AxoServices";
import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";

import {
  LexNavButton,
  withRouter,
  AxoLocal,
  Icon,
} from "../../utilities/LexUtilities";

class AdminTabView extends React.PureComponent {
  handleActiveState = (eventKey) => {
    this.props.handleNavigation(eventKey);
  };

  renderLargeScreenNavigationBar = () => {
    let { activeTab, showDropdowns } = this.props;

    return (
      <MediumOrLarger>
        <Grid fluid className="axo-nav-lg" id="noprint">
          {!showDropdowns ? (
            <Row>
              <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{ borderRadius: "0px", paddingTop: "0px" }}
                >
                  <LexNavButton
                    className="axonavTab axonavTabcolora"
                    ls
                    glyph="icon-fontello-cog-7"
                    eventKey="Collaboration"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal entity="paymentDate4a" defaultValue={"Axodrev"} />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorb"
                    ls
                    glyph="icon-fontello-user-female"
                    eventKey="SystemUsers"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="AdminTabView1"
                      defaultValue="Systembrugere"
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorc"
                    ls
                    glyph="icon-fontello-th-thumb-empty"
                    eventKey="Subscriptions"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="AdminTabView2"
                      defaultValue="Abonnementer"
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolord"
                    ls
                    glyph="icon-fontello-th-list-3"
                    eventKey="CreatePlan"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="AdminTabView3"
                      defaultValue="Opret abonnement"
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolore"
                    ls
                    glyph="icon-fontello-th-thumb"
                    eventKey="Formulas"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="paymentDate2a"
                      defaultValue={"Abonnementsformler"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorf"
                    ls
                    glyph="icon-fontello-plus-5"
                    eventKey="CreateFormula"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="paymentDate3a"
                      defaultValue={"Opret formel"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorg"
                    ls
                    glyph="icon-fontello-spread"
                    eventKey="ItemPrices"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="Enhedspris12"
                      defaultValue="Enhedspriser"
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorh"
                    ls
                    glyph="icon-fontello-plus-5"
                    eventKey="CreateItemPrice"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="Enhedspris13A"
                      defaultValue="Opret enhedspris"
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolori"
                    ls
                    glyph="icon-fontello-th-list-3"
                    eventKey="CreatePresentation"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal entity="Enhedspris34" defaultValue="Opre sider" />
                  </LexNavButton>
                  <LexNavButton
                    ls
                    listButton
                    className="axonavTab showListButton"
                    onClick={() => {
                      DataStore.setShowDropDowns(true);
                    }}
                  >
                    <div className="flexbox-center">
                      <Icon
                        glyph="icon-fontello-align-justify"
                        style={{ fontSize: "30px" }}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity66"
                        defaultValue={"Liste Menu"}
                      />
                    </div>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab  AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab"
                  onClick={() => this.handleActiveState("Collaboration")}
                >
                  <span className="icon-fontello-cog-7" /> &nbsp;&nbsp;
                  <AxoLocal entity="paymentDate4a" defaultValue={"Axodrev"} />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu "
                  onClick={() => this.handleActiveState("SystemUsers")}
                >
                  <span className="icon-fontello-user-female" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="AdminTabView1"
                    defaultValue="Systembrugere"
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Subscriptions")}
                >
                  <span className="icon-fontello-th-thumb-empty" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="AdminTabView2"
                    defaultValue="Abonnementer"
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("CreatePlan")}
                >
                  <span className="icon-fontello-th-list-3" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="AdminTabView3"
                    defaultValue="Opret abonnement"
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Formulas")}
                >
                  <span className="icon-fontello-th-thumb" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="paymentDate2a"
                    defaultValue={"Abonnementsformler"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("CreateFormula")}
                >
                  <span className="icon-fontello-plus-5" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="paymentDate3a"
                    defaultValue={"Opret formel"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("ItemPrices")}
                >
                  <span className="icon-fontello-spread" /> &nbsp;&nbsp;
                  <AxoLocal entity="Enhedspris12" defaultValue="Enhedspriser" />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("CreateItemPrice")}
                >
                  <span className="icon-fontello-plus-5" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="Enhedspris13A"
                    defaultValue="Opret enhedspris"
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("CreatePresentation")}
                >
                  <span className="icon-fontello-th-list-3" /> &nbsp;&nbsp;
                  <AxoLocal entity="Enhedspris34" defaultValue="Opre sider" />
                </MenuItem>
              </DropdownButton>
            </Row>
          )}
        </Grid>
      </MediumOrLarger>
    );
  };

  renderSmallScreenNavigationBar = () => {
    return (
      <SmallOrSmaller>
        <Grid fluid className="axo-nav" id="noprint">
          <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              <MenuItem
                style={{ paddingBottom: "10px", paddingTop: "10px" }}
                className="axonavTab axonavTabcolora"
                onClick={() => this.handleActiveState("Collaboration")}
              >
                <AxoLocal entity="paymentDate4a" defaultValue={"Axodrev"} />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("SystemUsers")}
              >
                <span className="icon-fontello-user-female" /> &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView1" defaultValue="Systembrugere" />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("Subscriptions")}
              >
                <span className="Seicon-fontello-th-thumb-emptyHer" />{" "}
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView2" defaultValue="Abonnementer" />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("CreatePlan")}
              >
                <span className="icon-fontello-th-list-3" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="AdminTabView3"
                  defaultValue="Opret abonnement"
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("Formulas")}
              >
                <span className="icon-fontello-th-thumb" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="paymentDate2a"
                  defaultValue={"Abonnementsformler"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("CreateFormula")}
              >
                <span className="icon-fontello-plus-5" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="paymentDate3a"
                  defaultValue={"Opret formel"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("ItemPrices")}
              >
                <span className="icon-fontello-spread" /> &nbsp;&nbsp;
                <AxoLocal entity="Enhedspris12" defaultValue="Enhedspriser" />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("CreateItemPrice")}
              >
                <span className="icon-fontello-plus-5" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="Enhedspris13A"
                  defaultValue="Opret enhedspris"
                />
              </MenuItem>
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={() => this.handleActiveState("CreatePresentation")}
              >
                <span className="icon-fontello-th-list-3" /> &nbsp;&nbsp;
                <AxoLocal entity="Enhedspris34" defaultValue="Opre sider" />
              </MenuItem>
            </DropdownButton>
          </Row>
        </Grid>
      </SmallOrSmaller>
    );
  };

  renderNavigationBar = () => {
    return (
      <div className="Tabbanne" style={{ paddingTop: "0px" }}>
        {this.renderLargeScreenNavigationBar()}
        {this.renderSmallScreenNavigationBar()}
      </div>
    );
  };

  renderNavigationContent = () => {
    let { activeTab } = this.props;

    return (
      <div>
        {activeTab === "SystemUsers" ? (
          <SystemUsersView
            userProfiles={this.props.userProfiles}
            forceShowCards={this.props.forceShowUserCards}
            onToggleCards={this.props.onToggleCards}
            globalSettings={this.props.globalSettings}
            onSetMainAdmin={this.props.onSetMainAdmin}
            onSetGlobalUserSetting={this.props.onSetGlobalUserSetting}
          />
        ) : null}
        {activeTab === "Subscriptions" ? (
          <SubscriptionPlanView
            subscriptionPlans={this.props.subscriptionPlans}
            globalSettings={this.props.globalSettings}
          />
        ) : null}
        {activeTab === "CreatePlan" ? (
          <div style={{ background: "linear-gradient(#f9f9f9, #f9f9f9)" }}>
            <CreatePlanView documentMap={this.props.documentMap} />
          </div>
        ) : null}
        {activeTab === "Formulas" ? (
          <SubscriptionFormulasTable
            subscriptionFormulas={this.props.subscriptionFormulas}
            globalSettings={this.props.globalSettings}
          />
        ) : null}
        {activeTab === "CreateFormula" ? (
          <div style={{ background: "linear-gradient(#f9f9f9, #f9f9f9)" }}>
            <CreateSubscriptionFormulaView />
          </div>
        ) : null}
        {activeTab === "Collaboration" ? (
          <div style={{ background: "linear-gradient(#f9f9f9, #f9f9f9)" }}>
            <CollaborationContainer />
          </div>
        ) : null}
        {activeTab === "ItemPrices" ? (
          <div style={{ background: "linear-gradient(#f9f9f9, #f9f9f9)" }}>
            <PriceTableSelector
              itemPrices={this.props.itemPrices}
              itemPriceTypes={this.props.itemPriceTypes}
            />
          </div>
        ) : null}
        {activeTab === "CreateItemPrice" ? (
          <div style={{ background: "linear-gradient(#f9f9f9, #f9f9f9)" }}>
            <ItemPriceFormSelector
              itemPriceTypes={this.props.itemPriceTypes}
              documentMap={this.props.documentMap}
            />
          </div>
        ) : null}
        {activeTab === "CreatePresentation" ? (
          <div style={{ background: "linear-gradient(#f9f9f9, #f9f9f9)" }}>
            <LegalPresentationContainer />
          </div>
        ) : null}
        {activeTab === "PresentationList" ? (
          <div style={{ background: "linear-gradient(#f9f9f9, #f9f9f9)" }}>
            <LegalPresentationListContainer />
          </div>
        ) : null}
        {activeTab === "Presentation" ? (
          <div style={{ background: "linear-gradient(#f9f9f9, #f9f9f9)" }}>
            <LegalPresentationSingleContainer />
          </div>
        ) : null}
      </div>
    );
  };
  render() {
    return (
      <div>
        <Grid fluid>
          <Row className="dropdown-form-styling">
            <Col xs={12} className="nopadding">
              {this.renderNavigationBar()}
              {this.renderNavigationContent()}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(AdminTabView);
