import React from "react";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Row, Col, Grid, Button } from "react-bootstrap";

import { UserInfoService } from "../../services/AxoServices";

import { Icon, AxoLocal } from "../../utilities/LexUtilities";

class UserCard extends React.PureComponent {
  getUserTypeElememt = (userType) => {
    switch (userType) {
      case "Lawyer":
        return (
          <span>
            <AxoLocal entity="AdminTabView35" defaultValue="Advokat" />
          </span>
        );
      case "Client":
        return (
          <span>
            {" "}
            <AxoLocal entity="AdminTabView36" defaultValue="Klient" />
          </span>
        );
      case "XLink":
        return (
          <span>
            {" "}
            <AxoLocal entity="AdminTabView37" defaultValue="XLink" />
          </span>
        );
      case "Admin":
        return (
          <span>
            {" "}
            <AxoLocal entity="AdminTabView38" defaultValue="Admin" />
          </span>
        );
      case "Accountant":
        return (
          <span>
            {" "}
            <AxoLocal entity="axoAccounting34" defaultValue="Revisor" />
          </span>
        );
      case "AccountingClient":
        return (
          <span>
            {" "}
            <AxoLocal entity="axoAccounting35" defaultValue="Revisorkunde" />
          </span>
        );
      case "SocietyMember":
        return (
          <span>
            <AxoLocal entity="axoidcode163" defaultValue="Foreningsmedlem" />
          </span>
        );
      case "EasyID":
        return <span>Signering</span>;
      default:
        return <span></span>;
    }
  };

  getStorageString = (userProfile) => {
    var used = userProfile.storageUsed;
    if (used === 0) {
      return "";
    }
    if (used > Math.pow(1024, 3)) {
      return (used / Math.pow(1024, 3)).toFixed(2) + " GB";
    } else if (used > Math.pow(1024, 2)) {
      return (used / Math.pow(1024, 2)).toFixed(2) + " MB";
    } else if (used > 1024) {
      return (used / 1024).toFixed(2) + " KB";
    } else {
      return used.toFixed(2) + " bytes";
    }
  };

  getSubscriptionElement = (userProfile) => {
    switch (userProfile.subscription) {
      case 0:
        return (
          <span>
            {" "}
            <AxoLocal entity="AdminTabView39" defaultValue="Intet" />{" "}
          </span>
        );
      case 1:
        return (
          <span>
            <AxoLocal entity="AdminTabView40" defaultValue="Basic" />{" "}
          </span>
        );
      case 2:
        return (
          <span>
            <AxoLocal entity="AdminTabView41" defaultValue="Advanced" />{" "}
          </span>
        );
      case 3:
        return (
          <span>
            <AxoLocal entity="AdminTabView42" defaultValue="Enterprise" />{" "}
          </span>
        );
      default:
        return <span></span>;
    }
  };

  renderInformation = () => {
    var profile = this.props.userProfile;
    return (
      <div>
        <table className="table Lex-Grid-tabl">
          <tbody>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="AdminTabView43"
                  defaultValue="Brugernavn"
                />{" "}
                :&nbsp;
              </th>
              <td>{profile.userName}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="TimeEntryFormntimeEntry"
                  defaultValue="Dato"
                />{" "}
                :&nbsp;
              </th>
              <td>{moment(profile.creationDate).format("L")}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView45" defaultValue="Kundetype" />
                :&nbsp;
              </th>
              <td>{this.getUserTypeElememt(profile.userType)}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <span>
                  <AxoLocal
                    entity="axoAccounting68"
                    defaultValue={"Betalt indtil"}
                  />
                  :
                </span>
                &nbsp;
              </th>
              <td>{moment(profile.paidUntil).format("L")}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="AdminTabView46"
                  defaultValue="Abonnement"
                />{" "}
                :&nbsp;
              </th>
              <td>{this.getSubscriptionElement(profile)}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView47" defaultValue="Land" />
                :&nbsp;
              </th>
              <td>
                {profile.country ? (
                  <AxoLocal
                    key={profile.id}
                    entity="CountryNames"
                    data={{ countrySlug: profile.country }}
                  />
                ) : null}
              </td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView48" defaultValue="Fornavn" />
                :&nbsp;
              </th>
              <td>{profile.firstName}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView49" defaultValue="Efternavn" />
                :&nbsp;
              </th>
              <td>{profile.lastName}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView50" defaultValue="Stilling" />
                :&nbsp;
              </th>
              <td>{profile.position}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="AdminTabView51"
                  defaultValue="Virksomhed"
                />{" "}
                :&nbsp;
              </th>
              <td>{profile.company}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="SignupAdresse" defaultValue="Adresse" />{" "}
                :&nbsp;
              </th>
              <td>{profile.address}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView53" defaultValue="By" />
                :&nbsp;
              </th>
              <td>{profile.city}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView75" defaultValue="Postnr" />
                :&nbsp;
              </th>
              <td>{profile.postalCode}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView55" defaultValue="Email" />{" "}
                :&nbsp;
              </th>
              <td>{profile.eMail}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView56" defaultValue="Tlf" />
                :&nbsp;
              </th>
              <td>{profile.phone}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView57" defaultValue="Mobil" />
                :&nbsp;
              </th>
              <td>{profile.mobile}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView58" defaultValue="Valuta" />
                :&nbsp;
              </th>
              <td>{profile.currency}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="" />
                &nbsp;&nbsp;
                <AxoLocal entity="AdminTabView59" defaultValue="Lagerforbrug" />
                :&nbsp;
              </th>
              <td>{this.getStorageString(profile)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderContent = () => {
    return this.renderInformation();
  };

  render() {
    var userProfile = this.props.userProfile;
    return (
      <div>
        <div
          style={{
            marginBottom: "25px",
            borderRadius: "0px",
            borderWidth: "1px 1px 1px 1px",
            borderStyle: "solid solid solid solid",
            borderColor: "#DCDCDC #DCDCDC #DCDCDC #DCDCDC",
            boxShadow: "10px 10px 9px #ececec",
          }}
        >
          <div>
            <div className="gradient-baggrund">
              <Grid fluid>
                <Row>
                  <Col xs={12} className="editable text-center">
                    <span
                      style={{
                        float: "left",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      <img
                        alt=""
                        src={UserInfoService.getProfileImageSource(userProfile)}
                        className="img-circle"
                        width="40"
                        height="40"
                      />
                    </span>
                    <div style={{ lineHeight: "50px" }}>
                      {userProfile.userName} - {userProfile.firstName}{" "}
                      {userProfile.lastName}
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div>
              <Grid fluid>
                <Row>
                  <Col xs={12}>{this.renderContent()}</Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class SystemUsersGridView extends React.PureComponent {
  //Props
  //userProfiles
  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateCardCols = (userProfiles) => {
    var cols = [[], [], []];
    var smallCols = [[], []];
    for (var i = 0; i < userProfiles.length; i++) {
      var userCard = (
        <UserCard key={userProfiles[i].id} userProfile={userProfiles[i]} />
      );

      cols[i % 3].push(userCard);
      smallCols[i % 2].push(userCard);
    }
    return [cols, smallCols];
  };

  renderCardGrid = (contacts) => {
    var cols = this.generateCardCols(contacts);
    var largeCols = cols[0];
    var smallCols = cols[1];
    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[0]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[1]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  getShownItems = () => {
    return this.props.userProfiles.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    return (
      <Grid fluid style={{ minHeight: "800px" }}>
        <Row>
          <Col xs={12} className="nopadding">
            {this.renderCardGrid(this.getShownItems())}
          </Col>
        </Row>
        {this.state.numberShown < this.props.userProfiles.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col
              xs={12}
              className="text-center"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2"
                style={{ width: "75%" }}
              >
                <AxoLocal entity="ViewShowmoreinfo" defaultValue="Vis flere" />
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(SystemUsersGridView);
