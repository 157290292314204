import React from "react";
import DataStore from "../services/DataAccess/DataStore";

// import {
//   ExtraSmall,
//   Small,
//   Medium,
//   Large,
//   MediumOrSmaller,
// } from "../utilities/Responsive";

import { Row, Col, Grid } from "react-bootstrap";

import { LocalizationService } from "../services/AxoServices";

import {
  Icon,
  // AxoLocal
} from "../utilities/LexUtilities";

import FrontPageImage from "../resources/images/FrontPageImage.jpg";

import ContactForm from "./ContactForm";

// import AxolexDataBehandlerAftale from "../resources/files/AxolexDatabehandlerAftale.pdf";
// import AxolexDataProcessingAgreement from "../resources/files/AxolexDataProcessingAgreement.pdf";

export default class OppanoPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      locale: "enUS",
    };
    this.name = "InfoPageContent";
  }

  componentDidMount() {
    LocalizationService.subscribeToLocale(this.name, (locale) => {
      this.setState({ locale });
    });

    let scrollId = DataStore.getScrollId();
    if (scrollId) {
      setTimeout(() => {
        document.getElementById(scrollId).scrollIntoView();
        DataStore.setScrollId("");
      }, 500);
    }
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale(this.name);
  }

  onScrollToSolution = (element, event) => {
    event.preventDefault();
    var here = this.props.location.pathname.toLowerCase().includes("solutions");
    var scrollElement = document.getElementById(element);
    if (here && scrollElement) {
      scrollElement.scrollIntoView();
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Solutions");
    }
  };

  onScrollToPackages = (element, event) => {
    event.preventDefault();
    var here = this.props.location.pathname.toLowerCase().includes("packages");
    var scrollElement = document.getElementById(element);
    if (here && scrollElement) {
      scrollElement.scrollIntoView();
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Packages");
    }
  };

  render() {
    return (
      <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Grid fluid>
          <Row>
            <img
              alt=""
              className="img-responsive bg_img "
              style={{ height: "400px", width: "100%" }}
              src={FrontPageImage}
            />
          </Row>
          <Row className="topPadding">
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                {/* <AxoLocal entity='FrontPageindextaxt7' defaultValue={'Komplet pakkeløsning'}/> */}
                Oppano ApS - Software development and IT consultancy
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid className="Axolex-Menu-lg">
          <Row>
            <Col xs={12}>
              <p>
                Oppano delivers services in the fields of software development and IT consultancy.
              </p>
              <p>
                We can deliver customized software systems tailor-made for your needs.
              </p>
              <p>
                Our IT consulting services help clients assess different technology strategies and, in doing so, align their technology strategies with their business or process strategies. These services support customers’ IT initiatives by providing strategic, architectural, operational and implementation planning. Strategic planning includes advisory services that help clients assess their IT needs and formulate system implementation plans. Architecture planning includes advisory services that combine strategic plans and knowledge of emerging technologies to create the logical design of the system and the supporting infrastructure to meet customer requirements. Operational assessment/benchmarking include services that assess the operating efficiency and capacity of a client’s IT environment. Implementation planning includes services aimed at advising customers on the rollout and testing of new solution deployments.
              </p>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                {/* <AxoLocal entity='FrontPageindexintegration' defaultValue={'Dataressourcer og sikkerhed'}/> */}
                Services
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row className="nopadding text-center" style={{ fontSize: "15px" }}>
            <Col
              md={4}
              sm={12}
              className="nopadding"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div>
                <Icon
                  className="axolexwebsideicon"
                  glyph="icon-fontello-lifebuoy-1"
                  style={{ fontSize: "60px" }}
                />
              </div>
              <div className="editable">
                <h3>IoT device control</h3>
              </div>
              <div>
                Oppano is developing software to control IoT devices, such as smart cameras.
              </div>
            </Col>
            <Col
              md={4}
              sm={12}
              className="nopadding"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div className="">
                <Icon
                  className="axolexwebsideicon"
                  glyph="icon-fontello-lock-circled"
                  style={{ fontSize: "60px" }}
                />
              </div>
              <div className="editable">
                <h3>
                  {/* <AxoLocal
                    entity="FrontPageindexintegration2a"
                    defaultValue={"Datasikkerhed"}
                  /> */}
                  Consulting
                </h3>
              </div>
              <div>
                Consulting services within IT- and software consultancy.
              </div>
            </Col>
            <Col
              md={4}
              sm={12}
              className="nopadding"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <div className="">
                <Icon
                  className="axolexwebsideicon"
                  glyph="icon-fontello-resize-full-circle"
                  style={{ fontSize: "60px" }}
                />
              </div>
              <div className="editable">
                <h3>
                  {/* <AxoLocal
                    entity="FrontPageindexintegration3a"
                    defaultValue={"Systemsikkerhed"}
                  /> */}
                  Software development
                </h3>
              </div>
              <div>
                Development of customized software using cutting-edge technologies.
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">Contact</div>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col xs={12}>
              Contact us at info@oppano.com
            </Col>
          </Row>
        </Grid>
        {/* <div  className='text-center'>
          <object type="image/svg+xml" data="/svg/GL-icons_01-26.svg" className="axosvg">
          test svg
          </object>
        </div> */}
      </div>
    );
  }
}
