//O(N^2). Consider replacing with more efficient algorithm.
export default {
  unique(array) {
    return array.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });
  },

  //For arrays containing elements with Id properties
  uniqueById(array) {
    return array.filter((elem, index, self) => {
      return index === self.findIndex((c) => c.id === elem.id);
    });
  },
};
