//Polyfill
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/es/map";
import "core-js/es/set";
import "core-js/es/symbol";
import "core-js/es/symbol/iterator";
import "core-js/features/string/repeat";

import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
// import SushiRoutes from './sushiRoutes';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";

import DataStore from "./services/DataAccess/DataStore";
import { initializeLocales } from "./utilities/L20n/L20n";

import "./resources/css/bootstrapv3/bootstrap.css";
import "./resources/css/custom.css";
import "./sass/cssbuild/main.css";
import "./resources/css/flexbox.css";
import "./resources/css/forms.css";
import "./resources/css/cards.css";
import "./resources/css/dataTable.css";
import "./resources/css/dashboard.css";
import "./resources/css/messenger/messenger.css";
import "./resources/css/messenger/messenger-theme-flat.css";
import "react-big-calendar/lib/css/react-big-calendar.css"

import * as $ from "jquery";
window.jQuery = window.$ = $;

initializeLocales({
  locales: ["da", "en-US", "enUS", "fr", "it", "ge", "ar", "ch"],
  default: "da",
});

if (window.Offline) {
  window.Offline.options = {
    requests: false,
    checks: {
      xhr: {
        url: function () {
          return "/favicon.ico?_=" + new Date().getTime();
        },
        type: "HEAD",
      },
      image: {
        url: function () {
          return (
            "https://www.google.com/images/srpr/logo11w.png?_=" +
            new Date().getTime()
          );
        },
      },
      active: "image",
    },
  };
}

var checkConnection = function () {
  if (window.Offline.state === "up") {
    window.Offline.check();
  }
};

window.onload = function (e) {
  DataStore.setLoaded();
  if (window.Offline) {
    setInterval(checkConnection, 30000);
  }
};

//Main app
ReactDOM.render(<App />, document.getElementById("root"));

unregister();
// registerServiceWorker();
