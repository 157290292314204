import React from "react";
import {
  Consumer,
  withRouter,
  withRTKData,
} from "../../../utilities/LexUtilities";

import { DataStore } from "../../../services/AxoServices";

import AccountPlanContainer from "./AccountPlanContainer";

class AccountPlanConsumer extends React.PureComponent {
  componentDidMount() {
    // DataStore.initializeFinanceAccountPlans();
    DataStore.fetchStandardFinanceAccountPlans();
    DataStore.fetchClientPlan();
    DataStore.initializeUserProfile();
  }

  mapStoreToProps = (store) => {
    let financeAccountMap = {};
    store.clientPlan.accounts.forEach((element) => {
      financeAccountMap[element.id] = element;
    });

    return {
      financeAccountPlans: store.financeAccountPlans.map((fa) => {
        return {
          ...fa,
          accounts: fa.accounts.map((id) => store.financeAccounts[id]),
          taxSpecifications: fa.taxSpecifications.map(
            (id) => store.taxSpecifications[id]
          ),
        };
      }),
      clientPlan: store.clientPlan,
      financeAccounts: { ...store.financeAccounts, ...financeAccountMap },
      standardFinanceAccountPlans: store.standardFinanceAccountPlans,
      userProfile: store.userProfile,
      showDropdowns: store.showDropdowns,
      actions: store.actions,
      storeFunctions: store.storeFunctions,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => (
          <AccountPlanContainer
            {...this.mapStoreToProps(store)}
            {...this.props}
          />
        )}
      </Consumer>
    );
  }
}

export default withRTKData(withRouter(AccountPlanConsumer));
