// @ts-check
import React from "react";
import SidebarRight from "./sidebars/SidebarRight";

import { ApiService, DataStore } from "../services/AxoServices";

import { withRouter, Consumer } from "../utilities/LexUtilities";

class SideBarRightContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      libraryFileNames: [],
      groupMembers: [],
      paymentRequests: [],
    };
  }

  componentDidMount() {
    ApiService.getLibraryFileNames().then((libraryFileNames) => {
      this.setState({ libraryFileNames });
    });

    ApiService.getPaymentRequestsForCurrentUser().then((paymentRequests) => {
      this.setState({ paymentRequests });
    });

    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.fetchUserSubscription();
    DataStore.fetchStorageInformation();
    DataStore.fetchSubscriptionPlans();
    DataStore.initializeUserGroup();
    DataStore.fetchGlobalSettings();
  }

  getGroupMembers = () => {
    ApiService.getGroupMembers().then((groupMembers) => {
      this.setState({ groupMembers });
    });
  };

  clearGroupMembers = () => {
    this.setState({ groupMembers: [] });
  };

  removeGroupMember = (userProfileId) => {
    ApiService.removeGroupMember(userProfileId).then((response) => {
      if (response.ok) {
        this.getGroupMembers();
        DataStore.fetchUserGroup();
      }
    });
  };

  render() {
    return (
      <Consumer>
        {(store) => {
          return (
            <SidebarRight
              {...this.props}
              {...this.state}
              userProfile={store.userProfile}
              userGroup={store.userGroup}
              userSettings={store.userSettings}
              userSubscription={store.userSubscription}
              storageInformation={store.storageInformation}
              subscriptionPlans={store.subscriptionPlans.filter((sub) => {
                return sub.type === "Standard";
              })}
              globalSettings={store.globalSettings}
              theme={store.theme}
              getGroupMembers={this.getGroupMembers}
              clearGroupMembers={this.clearGroupMembers}
              removeGroupMember={this.removeGroupMember}
            />
          );
        }}
      </Consumer>
    );
  }
}

export default withRouter(SideBarRightContainer);
