import React, { useEffect, useState } from "react";
import QueryString from "query-string";

import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { ApiService } from "../../services/AxoServices";
import { LexButton, UploadButton, Icon } from "../../utilities/LexUtilities";

let uploaded, setUploaded;
let referenceText, setReferenceText;

function EmbeddedUploadView(props) {
  [uploaded, setUploaded] = useState(false);
  [referenceText, setReferenceText] = useState("");

  let { targetUserName } = props.match.params;

  useEffect(() => {
    let queryParams = QueryString.parse(window.location.search);
    setReferenceText(queryParams.referenceText);
  }, []);

  let uploadDocuments = async (documents) => {
    let uploadResponse = await ApiService.uploadDocuments(documents, {
      targetUserName,
      caseNumber: referenceText,
    });
    setUploaded(uploadResponse.ok);
    return uploadResponse.ok;
  };

  if (uploaded) {
    return (
      <div>
        <h3>Dokumenterne blev uploadet</h3>
        <LexButton onClick={() => setUploaded(false)}>
          Upload flere dokumenter
        </LexButton>
      </div>
    );
  }

  return (
    <div className="standardMaxWidth">
      <FormGroup>
        <ControlLabel
          style={{ textAlign: "left", fontSize: "16px", fontWeight: "normal" }}
        >
          Reference:
        </ControlLabel>
        <FormControl
          name="referenceText"
          type="text"
          className="borderFormControlfocus"
          value={referenceText}
          onChange={(e) => setReferenceText(e.target.value)}
        />
      </FormGroup>
      <UploadButton allowMultiple onSelected={uploadDocuments}>
        <Icon glyph="icon-fontello-upload-4" />
        &nbsp; Upload dokumenter
      </UploadButton>
    </div>
  );
}

export default EmbeddedUploadView;
