import React from "react";

import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  FormGroup,
  FormControl,
  ControlLabel,
  Table as BTable,
} from "react-bootstrap";

import { ApiService, DataActions } from "../../services/AxoServices";

import {
  AsyncButton,
  Flexbox,
  Icon,
  LexButton,
  AxoCheckbox,
  FlexElement,
  getText,
  AxoLocal,
  // FlexElement,
  // AxoLocal
} from "../../utilities/LexUtilities";

export default class SigningRequestModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showSending: true,
      file: {
        signingRequests: [],
      },

      validatePhone: false,
      allowImageSignatures: false,
      sendEmailConfirmations: false,

      signers: [
        {
          guid: "",
          email: "",
          phone: "",
        },
      ],
      subject: "Du har modtaget et dokument til signering.",
      message: ``,

      callback: (result) => {},
    };
  }

  close = () => {
    this.setState({
      showModal: false,
      showSending: true,
      file: {
        signingRequests: [],
      },
      validatePhone: false,
      allowImageSignatures: false,
      sendEmailConfirmations: false,

      signers: [
        {
          guid: "",
          email: "",
          phone: "",
        },
      ],
    });
  };

  hideSending = () => {
    this.setState({ showSending: false });
    return this;
  };

  open = (file) => {
    let request =
      file.signingRequests.length > 0 ? file.signingRequests[0] : null;

    this.setState({
      showModal: true,
      file,
      validatePhone: !!request && request.validatePhone,
      signers: !!request
        ? []
        : [
            {
              guid: "",
              email: "",
              phone: "",
            },
          ],
    });
  };

  onOK = () => {
    this.setState(
      {
        showModal: false,
        showSending: true,
      },
      () => {
        this.state.callback(true);
      }
    );
  };

  onCancel = () => {
    this.setState(
      {
        showModal: false,
        showSending: true,
      },
      () => {
        this.state.callback(false);
      }
    );
  };

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  addSigner = () => {
    this.setState((prevState) => ({
      signers: [
        ...prevState.signers,
        {
          email: "",
          phone: "",
        },
      ],
    }));
  };

  removeSigner = (index) => {
    this.setState((prevState) => ({
      signers: prevState.signers
        .slice(0, index)
        .concat(prevState.signers.slice(index + 1)),
      // signers: prevState.signers.filter(s => s.id !== id)
    }));
  };

  updateSigner = (newSigner, newIndex) => {
    this.setState((prevState) => ({
      signers: prevState.signers.map((signer, index) => {
        if (index === newIndex) {
          return newSigner;
        }

        return signer;
      }),
    }));
  };

  createSigningRequest = async () => {
    let {
      file,
      signers,
      subject,
      validatePhone,
      allowImageSignatures,
      sendEmailConfirmations,
    } = this.state;

    if (!this.checkValidData()) {
      return;
    }

    let newSignerMails = signers.map((s) => s.email);

    let request;
    if (file.signingRequests.length === 0) {
      let response = await DataActions.createSigningRequest({
        fileInfoModelId: file.id,
        signers,
        validatePhone,
        allowImageSignatures,
        sendEmailConfirmations,
      });

      request = await response.json();
    } else {
      request = file.signingRequests[0];

      request = {
        ...request,
        signers: request.signers.concat(signers),
      };

      let response = await DataActions.updateSigningRequest(request);
      if (!response.ok) {
        return false;
      }

      request = await response.json();
    }

    let link =
      window.location.protocol +
      "//" +
      window.location.host +
      "/Signing/" +
      request.guid;
    //Needed because the guid is set on the server
    let newSigners = request.signers.filter(
      (s) => !!newSignerMails.find((e) => s.email === e)
    );
    let promises = newSigners.map((signer) => {
      let currentLink = link + "?signer=" + signer.guid;

      let fullMessage = this.generateFullMessage(currentLink);

      return ApiService.sendCompanyEmail({
        fromName: "Axolex EasySign System",
        to: signer.email,
        subject,
        body: fullMessage,
      });
    });

    let responses = await Promise.all(promises);

    this.updateSigningRequestState(request);

    return !responses.find((r) => !r.ok);
  };

  updateSigningRequestState = (newRequest) => {
    let { file } = this.state;

    if (file.signingRequests.length === 0) {
      this.setState((prevState) => ({
        file: {
          ...prevState.file,
          signingRequests: prevState.file.signingRequests.concat([newRequest]),
        },
        signers: [],
      }));
    }
    //Update existing request
    else {
      this.setState((prevState) => ({
        file: {
          ...prevState.file,
          signingRequests: prevState.file.signingRequests.map((r) => {
            if (r.id === newRequest.id) {
              return newRequest;
            }
            return r;
          }),
        },
        signers: [],
      }));
    }
  };

  onChangeRequestFlag = (event, flagName) => {
    let { file } = this.state;

    if (file.signingRequests.length > 0) {
      return;
    }

    this.setState({ [flagName]: event.target.checked });
  };

  checkValidData = () => {
    let { subject, signers, validatePhone } = this.state;

    if (!subject || signers.length === 0) {
      return false;
    }

    for (let i = 0; i < signers.length; i++) {
      let signer = signers[i];

      if (!signer.email || !this.validateEmail(signer.email)) {
        return false;
      }
      if (validatePhone && !signer.phone) {
        return false;
      }
    }

    return true;
  };

  sendSigningLink = async (signer) => {
    let { file, subject } = this.state;

    let signingRequest =
      file.signingRequests.length > 0 ? file.signingRequests[0] : null;
    if (!signingRequest) {
      return false;
    }

    let link =
      window.location.protocol +
      "//" +
      window.location.host +
      "/Signing/" +
      signingRequest.guid;
    let currentLink = link + "?signer=" + signer.guid;

    let fullMessage = this.generateFullMessage(currentLink);

    let response = await ApiService.sendCompanyEmail({
      fromName: "Axolex EasySign System",
      to: signer.email,
      subject,
      body: fullMessage,
    });

    return response.ok;
  };

  generateFullMessage = (currentLink) => {
    let { message, allowImageSignatures } = this.state;
    //  <div style='padding: 25px; line-height: 50px; background: white; font-size: 22px; color: #005380; max-width: 500px;'>
    //   Kære ${name},
    // </div>
    // ${getText('dasdasdas')}

    // MissingEntity
    let fullMessage = `
        <div style='padding: 25px; line-height: 20px; background: #eaecec; font-size: 17px; color: #000000; max-width: 500px;'> 
          ${getText(
            "axoEntityidcode277",
            "Du har modtaget et dokument til signatur."
          )}
        </div>
        ${
          !!message
            ? `
          <div style='padding: 25px; line-height: 20px; margin-bottom:5px; background: #eaecec; font-size: 17px; color: #000000; max-width: 500px;'>
            ${message}
          </div>
        `
            : ``
        }
        ${
          allowImageSignatures
            ? `
          <div style='padding: 25px; line-height: 50px; background: white; font-size: 22px; color: #005380; max-width: 500px;'> 
            ${getText(
              "axoEntityidcode278",
              " Du behøves ikke at have NemID eller MitID. Du kan også tegne din signatur direkte på skærmen."
            )}
          </div>
        `
            : ``
        }
        <div style='padding: 25px;z line-height: 20px; margin-bottom:5px; background: #005380; font-size: 17px; color: #ffffff; max-width: 300px;'>
        <a style='color: white;' href=${currentLink}>Klik her for at underskrive dokumentet</a>
        </div>
        <div style='padding: 25px; line-height: 50px; background: white; font-size: 22px; color: #005380; max-width: 500px;'>
          ${getText("axoEntityidcode279", "Axolex Digitalt Signatursystem")}
        </div>
        <div style='padding: 25px; margin-bottom:5px; line-height: 20px; background:#eaecec; font-size: 17px; color: #000000; max-width: 500px;'>
          <div>
          ${getText(
            "axoEntityidcode280",
            "Axolex Signatursystemet er en digital løsning baseret på NemID."
          )}
         </div>
          <div>
          ${getText(
            "axoEntityidcode281",
            "Axolex er en godkendt NemID Tjenesteudbyder og anvender Globalsigns certifikater."
          )}
         </div>
          <div> © Powered By Axolex</div>
      	</div>`;

    return fullMessage;
  };

  deleteSigner = async (signer) => {
    let { file } = this.state;

    if (signer.signed) {
      return false;
    }

    let signingRequest =
      file.signingRequests.length > 0 ? file.signingRequests[0] : null;
    if (!signingRequest) {
      return false;
    }

    let response = await DataActions.updateSigningRequest({
      ...signingRequest,
      signers: signingRequest.signers.filter((s) => s.id !== signer.id),
    });

    if (response.ok) {
      let newRequest = await response.json();
      this.updateSigningRequestState(newRequest);
    }

    return response.ok;
  };

  render() {
    let {
      file,
      subject,
      message,
      signers,
      validatePhone,
      allowImageSignatures,
      sendEmailConfirmations,
      showSending,
    } = this.state;

    let request =
      file.signingRequests.length > 0 ? file.signingRequests[0] : null;

    let ready = this.checkValidData();

    let completedSignings =
      !!request && !!request.signers
        ? request.signers.filter((s) => s.signed)
        : [];
    let linkTarget =
      window.location.protocol +
      "//" +
      window.location.host +
      "/Signing/" +
      (!!request ? request.guid : "");

    return (
      <Modal
        className="center-dialog"
        bsSize="large"
        show={this.state.showModal}
        onHide={this.close}
      >
        <Modal.Header
          className="backgroundModalHeaderFooter"
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
          closeButton
        >
          <Modal.Title>
            <strong>
              <AxoLocal
                entity="axoEntityidcode282"
                defaultValue="Signer dokumentet"
              />
              :
            </strong>{" "}
            {file.fileName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
          }}
        >
          {signers.map((signer, index) => {
            let validMail = this.validateEmail(signer.email);
            return (
              <div
                className="topPadding bottomPadding"
                key={signer.id || `New-${index}`}
              >
                <Flexbox>
                  <ControlLabel
                    className="rightPadding"
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <b>
                      <AxoLocal
                        entity="CaseEditFormeMail"
                        defaultValue={"Email"}
                      />
                    </b>
                  </ControlLabel>
                  <FormControl
                    autoFocus
                    type="email"
                    // className='borderFormControlfocus'
                    style={
                      !!signer.email && !validMail
                        ? { border: "1px solid red" }
                        : {}
                    }
                    value={signer.email}
                    onChange={(event) =>
                      this.updateSigner(
                        { ...signer, email: event.target.value },
                        index
                      )
                    }
                    // entity={ singleName ? 'axoAccounting7' : 'placeholderfirstName' }
                  />
                  <ControlLabel
                    className="leftPadding rightPadding"
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <b>
                      <AxoLocal
                        entity="ContainerPhone"
                        defaultValue={"Telefon"}
                      />
                    </b>
                  </ControlLabel>
                  <FormControl
                    type="text"
                    disabled={!validatePhone}
                    style={
                      validatePhone && !signer.phone
                        ? { border: "1px solid red" }
                        : {}
                    }
                    className="borderFormControlfocus"
                    value={signer.phone}
                    onChange={(event) =>
                      this.updateSigner(
                        { ...signer, phone: event.target.value },
                        index
                      )
                    }
                    // entity={ singleName ? 'axoAccounting7' : 'placeholderfirstName' }
                  />
                  <Icon
                    className="leftPadding"
                    glyph="icon-fontello-trash"
                    role="button"
                    onClick={() => this.removeSigner(index)}
                  />
                </Flexbox>
                {!!signer.email && !validMail && (
                  <div className="axored">
                    <AxoLocal
                      entity="axoEntityidcode225"
                      defaultValue={"Indtast gyldig mail."}
                    />
                  </div>
                )}
              </div>
            );
          })}
          {showSending && (
            <>
              <Flexbox>
                <FlexElement flexGrow={1}></FlexElement>
                <FlexElement
                  title={getText(
                    "axoEntityidcode226",
                    "Når denne mulighed er slået til, har brugerne mulighed for at underskrive ved at tegne en underskrift, i stedet for at anvende NemID."
                  )}
                  className="rightPadding"
                >
                  <AxoCheckbox
                    checked={allowImageSignatures}
                    onChange={(event) =>
                      this.onChangeRequestFlag(event, "allowImageSignatures")
                    }
                  />
                  &nbsp;
                  <span style={{ color: "#497589" }}>
                    <AxoLocal
                      entity="axoEntityidcode224"
                      defaultValue={"Tegnet underskrift"}
                    />
                    &nbsp;
                    <Icon glyph="icon-fontello-question" />
                  </span>
                </FlexElement>
                <FlexElement
                  title={getText(
                    "axoEntityidcode227",
                    "Når telefonvalidering er slået til vil brugerne få tilsendt en to-faktor kode før de får mulighed for at se dokumentet."
                  )}
                >
                  <AxoCheckbox
                    checked={validatePhone}
                    onChange={(event) =>
                      this.onChangeRequestFlag(event, "validatePhone")
                    }
                  />
                  &nbsp;
                  <span style={{ color: "#497589" }}>
                    <AxoLocal
                      entity="axoEntityidcode223"
                      defaultValue={"Telefonvalidering"}
                    />
                    &nbsp;
                    <Icon glyph="icon-fontello-question" />
                  </span>
                </FlexElement>
              </Flexbox>
              <FlexElement>
                <LexButton onClick={this.addSigner}>
                  <AxoLocal
                    entity="axoEntityidcode222"
                    defaultValue={"Tilføj flere modtagere"}
                  />
                </LexButton>
              </FlexElement>
              <FormGroup>
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <b>
                    <AxoLocal
                      entity="axoEntityidcode200a"
                      defaultValue={"Emnefelt på email"}
                    />
                  </b>
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder={"Du har modtaget et dokument til signering"}
                  value={subject}
                  onChange={(event) =>
                    this.setState({ subject: event.target.value })
                  }
                />
              </FormGroup>
              <FormGroup controlId="description">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <b>{getText("axoEntityidcode198", "Besked")}</b>
                </ControlLabel>
                <FormControl
                  componentClass="textarea"
                  placeholder="Valgfri besked"
                  rows={3}
                  value={message}
                  onChange={(event) =>
                    this.setState({ message: event.target.value })
                  }
                />
              </FormGroup>
              <div
                title={getText(
                  "axoEntityidcode228",
                  'Når denne mulighed er slået til, vil der blive sendt en mail til din registrerede email adresse ved hver ny underskrift. Vælg "Opsætning" i rullegardinet for at registrere din email.'
                )}
              >
                <AxoCheckbox
                  checked={sendEmailConfirmations}
                  onChange={(event) =>
                    this.onChangeRequestFlag(event, "sendEmailConfirmations")
                  }
                />
                &nbsp;
                <span style={{ color: "#497589" }}>
                  <AxoLocal
                    entity="axoEntityidcode221"
                    defaultValue={" Send email ved signering"}
                  />
                  &nbsp;
                  <Icon glyph="icon-fontello-question" />
                </span>
              </div>
            </>
          )}
          {!!request && (
            <>
              {!!request.signers && request.signers.length > 0 && (
                <>
                  <div>
                    <span></span>
                    <AxoLocal
                      entity="axoEntityidcode220"
                      defaultValue={"Anmodningen er sendt til signering hos:"}
                    />
                    <BTable striped>
                      <thead>
                        <tr>
                          <td>
                            <AxoLocal
                              entity="CaseEditFormeMail"
                              defaultValue={"Email"}
                            />
                          </td>
                          <td>
                            <AxoLocal
                              entity="ContainerPhone"
                              defaultValue={"Telefon"}
                            />
                          </td>
                          <td>
                            <AxoLocal
                              entity="CalendarEventFormbookingLink"
                              defaultValue={" Link:"}
                            />
                            :
                          </td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody className="axoblue">
                        {request.signers.map((signer) => (
                          <tr key={signer.email + signer.phone}>
                            <td>{signer.email}</td>
                            <td>{signer.phone}</td>
                            <td>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={linkTarget + "?signer=" + signer.guid}
                              >
                                Link
                              </a>
                            </td>
                            <td>
                              <AsyncButton
                                title={getText(
                                  "axoEntityidcode229",
                                  "Gensend signeringslink"
                                )}
                                onClick={() => this.sendSigningLink(signer)}
                              >
                                <Icon glyph="icon-fontello-mail" />
                              </AsyncButton>
                              {!signer.signed && (
                                <>
                                  &nbsp;
                                  <AsyncButton
                                    title={getText(
                                      "axoEntityidcode230",
                                      "Fjern signeringsanmdoning"
                                    )}
                                    onClick={() => this.deleteSigner(signer)}
                                  >
                                    <Icon glyph="icon-fontello-trash" />
                                  </AsyncButton>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </BTable>
                  </div>
                  <div>
                    <AxoLocal
                      entity="axoEntityidcode219"
                      defaultValue={"Anmodningen er signeret af"}
                    />
                    &nbsp;{completedSignings.length} / {request.signers.length}
                    <BTable striped>
                      <thead>
                        <tr>
                          <td>
                            <AxoLocal
                              entity="CaseEditFormeMail"
                              defaultValue={"Email"}
                            />
                          </td>
                          <td>
                            <AxoLocal
                              entity="ContainerPhone"
                              defaultValue={"Telefon"}
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody className="axogreen">
                        {completedSignings.map((signer) => (
                          <tr key={signer.email + signer.phone}>
                            <td>{signer.email}</td>
                            <td>{signer.phone}</td>
                          </tr>
                        ))}
                      </tbody>
                    </BTable>
                  </div>
                </>
              )}
              {/* { !validatePhone && (
                <div className='text-left'>
                  Signeringslink:&nbsp;
                  <FormControl 
                    value={linkTarget}
                  />
                </div>
              )} */}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              {showSending && (
                <AsyncButton
                  onClick={this.createSigningRequest}
                  disabled={!ready}
                  title={
                    !ready
                      ? getText(
                          "axoEntityidcode231",
                          "Du skal tilføje flere modtagere før du kan sende igen."
                        )
                      : ""
                  }
                >
                  <AxoLocal
                    entity="axoEntityidcode218"
                    defaultValue={"Send til signering"}
                  />
                </AsyncButton>
              )}
              <Button className="Lex-button-2" onClick={this.onCancel}>
                {/* MissingEntity */}
                <AxoLocal entity="Docfileclose" defaultValue={"Luk"} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
