// @ts-check
import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import CreateCalendarEventModal from "../../routes/Calendar/CreateCalendarEventModal";
import TimeEntryFormContainer from "../../routes/TimeEntries/TimeEntryFormContainer";
import CalendarEventForm from "../../routes/Calendar/CalendarEventForm";
import moment from "moment";
import ModalService from "../../services/ModalService";
import FrontPageSidebar from "./FrontPageSidebar";
import { AxoListGroup, AxoListItem } from "../../utilities/AxoListGroup";

import {
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Panel,
  PanelGroup,
} from "react-bootstrap";

import { Icon, AxoLocal, FileViewerModal } from "../../utilities/LexUtilities";

import TimeEntryService from "../../services/TimeEntryService";

function renderAccordionHeader(title) {
  return (
    <div>
      <Col xs={11} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

const reminderTexts = [
  {
    title: (
      <span>
        <AxoLocal
          entity="SidebarRightContainerAtthetime"
          defaultValue={"På tidspunktet"}
        />
      </span>
    ),
    interval: 0,
    selected: false,
  },
  {
    title: (
      <span>
        <AxoLocal
          entity="SidebarRightContainer5m"
          defaultValue={"5 minutter før"}
        />
      </span>
    ),
    interval: 5,
    selected: false,
  },
  {
    title: (
      <span>
        <AxoLocal
          entity="SidebarRightContainer15m"
          defaultValue={"15 minutter før"}
        />
      </span>
    ),
    interval: 15,
    selected: false,
  },
  {
    title: (
      <span>
        <AxoLocal
          entity="SidebarRightContainer30m"
          defaultValue={"30 minutter før"}
        />
      </span>
    ),
    interval: 30,
    selected: false,
  },
  {
    title: (
      <span>
        <AxoLocal
          entity="SidebarRightContainer1t"
          defaultValue={"1 time før"}
        />
      </span>
    ),
    interval: 60,
    selected: false,
  },
  {
    title: (
      <span>
        <AxoLocal
          entity="SidebarRightContainer2t"
          defaultValue={"2 timer før"}
        />
      </span>
    ),
    interval: 120,
    selected: false,
  },
  {
    title: (
      <span>
        <AxoLocal entity="SidebarRightContainer1d" defaultValue={"1 dag før"} />{" "}
      </span>
    ),
    interval: 1440,
    selected: false,
  },
  {
    title: (
      <span>
        <AxoLocal
          entity="SidebarRightContainer2d"
          defaultValue={"2 dage før"}
        />
      </span>
    ),
    interval: 2880,
    selected: false,
  },
  {
    title: (
      <span>
        <AxoLocal entity="SidebarRightContainer1w" defaultValue={"1 uge før"} />
      </span>
    ),
    interval: 10080,
    selected: false,
  },
];

export default class SidebarRight extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      calendarEvent: this.makeNewCalendarEventModel(),
    };
  }

  makeNewCalendarEventModel = () => {
    return {
      title: "",
      allDay: false,
      bookingTime: false,
      start: new Date(),
      end: moment(Date.now()).add(30, "m"),
      description: "",
      bookingCapacity: 1,
      isDeadline: false,
      color: "",
    };
  };

  onClickDocumentNode = (document) => {
    this.fileModal.open([document]);
  };

  groupDocumentsByLabels = (documents) => {
    var documentsByLabels = { noLabels: [] };
    documents.forEach((doc) => {
      if (doc.trashed) {
        return;
      }
      if (!doc.labels || doc.labels.length === 0) {
        documentsByLabels.noLabels.push(doc);
      } else {
        doc.labels.forEach((label) => {
          if (!documentsByLabels[label.name]) {
            documentsByLabels[label.name] = [doc];
          } else {
            documentsByLabels[label.name].push(doc);
          }
        });
      }
    });
    return documentsByLabels;
  };

  renderDocumentsWithLabel = (documentsWithLabel) => {
    return documentsWithLabel.map((document) => {
      return (
        <AxoListItem
          key={document.id}
          onClick={this.onClickDocumentNode.bind(this, document)}
        >
          {document.fileName}
        </AxoListItem>
      );
    });
  };

  renderDocumentsWithLabels = (groupedDocuments) => {
    return Object.keys(groupedDocuments).map((key) => {
      if (key !== "noLabels") {
        return (
          <Panel className="backgroundlineargradient3" eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                {renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-simple-line-icons-folder-alt" /> {key}
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <AxoListGroup
                style={{
                  width: "100%",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                }}
              >
                {this.renderDocumentsWithLabel(groupedDocuments[key])}
              </AxoListGroup>
            </Panel.Body>
          </Panel>
          // <Panel  className='' key={key} header={renderAccordionHeader(<span>
          //   <Icon glyph='icon-simple-line-icons-folder-alt'/> {key}</span>)} eventKey={key}>
          //   <AxoListGroup style={{width:'100%', paddingLeft: '25px', paddingRight:'25px'}} >
          //     {this.renderDocumentsWithLabel(groupedDocuments[key])}
          //   </AxoListGroup>
          // </Panel>
        );
      } else {
        return null;
      }
    });
  };

  renderDocumentInformation = () => {
    var currentCase = this.props.selectedCase;
    if (!currentCase) {
      return null;
    }
    var groupedDocuments = this.groupDocumentsByLabels(currentCase.documents);
    return (
      <PanelGroup
        id="noLabels"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {renderAccordionHeader(
                <span>
                  <Icon glyph="icon-fontello-folder-open-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="SidebarRightContainerCasedocuments"
                    defaultValue={"Sagsdokumenter"}
                  />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <AxoListGroup>
              {this.renderDocumentsWithLabel(groupedDocuments.noLabels)}
            </AxoListGroup>
            <PanelGroup id="docLabels" accordion className="Lex-Accordion ">
              {this.renderDocumentsWithLabels(groupedDocuments)}
            </PanelGroup>
          </Panel.Body>
        </Panel>
      </PanelGroup>
    );
  };

  onClickEventNode = (event) => {
    this.calendarEventModal.open(event);
  };

  onUpdateEvent = (calendarEvent) => {
    DataActions.updateCalendarEvent(calendarEvent);
  };

  onDeleteEvent = (eventId) => {
    DataActions.deleteCalendarEvent(eventId);
  };

  onCalendarEventUpdate = (calendarEvent) => {
    this.setState({ calendarEvent: calendarEvent });
  };

  onSaveCalendarEvent = () => {
    var calendarEvent = this.state.calendarEvent;
    if (!calendarEvent.title || this.state.calendarEventAdded) {
      return;
    }
    calendarEvent.caseModelId = this.props.selectedCase.id;
    //if(this.state.calendarEvent.title)
    DataActions.createCalendarEvent(this.state.calendarEvent).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            calendarEventAdded: true,
          });
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="SidebarRightContainerThereminder"
              defaultValue={"Påmindelsen kunne ikke gemmes. Prøv igen senere."}
            />
          );
        }
      }
    );
  };

  onResetCalendarEvent = () => {
    this.setState({ calendarEvent: this.makeNewCalendarEventModel() });
  };

  renderCalendarEventForm = () => {
    var confirmIcon = this.state.calendarEventAdded ? (
      <Icon glyph="icon-fontello-ok-3" />
    ) : null;
    return (
      <div>
        <PanelGroup
          id="calendarEvent"
          accordion
          className="Lex-Accordion"
          defaultActiveKey="0"
          style={{
            borderRadius: "0px",
            paddingTop: "0px",
            borderBottom: "1px solid white",
          }}
        >
          <Panel className="backgroundlineargradient3" eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                {renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-fontello-bell-1" />
                    &nbsp;
                    <AxoLocal
                      entity="SidebarRightContainerCreateremindercase"
                      defaultValue={"Opret påmindelse for denne sag"}
                    />
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <div>
                <CalendarEventForm
                  calendarEvent={this.state.calendarEvent}
                  onCalendarEventUpdate={this.onCalendarEventUpdate}
                  userProfile={this.props.userProfile}
                />
                <div className="text-center">
                  <ButtonToolbar style={{ display: "inline-block" }}>
                    <ButtonGroup>
                      <Button
                        className="Lex-button-2 "
                        onClick={this.onSaveCalendarEvent}
                      >
                        {confirmIcon}
                        <span>
                          <Icon glyph="icon-fontello-floppy-1" />
                          &nbsp;&nbsp;
                          <AxoLocal
                            entity="SidebarRightContainerSaveCalendarEvent"
                            defaultValue={"Gem"}
                          />
                        </span>
                      </Button>
                      <Button
                        className="Lex-button-2 "
                        onClick={this.onResetCalendarEvent}
                      >
                        {" "}
                        <span>
                          &#x21bb; &nbsp;&nbsp;
                          <AxoLocal
                            entity="SidebarRightContainerResetCalendarEvent"
                            defaultValue={"Nulstil"}
                          />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </PanelGroup>
      </div>
    );
  };

  onBackToCalendarEvent = () => {
    this.setState({
      calendarEvent: this.makeNewCalendarEventModel(),
      calendarEventAdded: false,
    });
  };

  getReminderText = (interval) => {
    return reminderTexts.find((r) => r.interval === interval).title;
  };

  getReminderTextArray = (calendarEvent) => {
    var texts = [];
    if (calendarEvent.firstReminder) {
      texts.push(this.getReminderText(calendarEvent.firstReminder));
    }
    if (calendarEvent.secondReminder) {
      texts.push(this.getReminderText(calendarEvent.secondReminder));
    }
    return texts;
  };

  renderReminderTexts = (calendarEvent) => {
    //Checks if the first reminder is a valid integer
    if (
      Math.round(calendarEvent.firstReminder) !== calendarEvent.firstReminder
    ) {
      return null;
    }
    return (
      <div>
        <div>
          <b>
            <AxoLocal
              entity="SidebarRightContainerReminder"
              defaultValue={"Påmindelser"}
            />
          </b>
        </div>
        {this.getReminderTextArray(calendarEvent).map((rText) => {
          return <div key={rText}>{rText}</div>;
        })}
      </div>
    );
  };

  renderCalendarEventView = () => {
    var calendarEvent = this.state.calendarEvent;
    return (
      <PanelGroup
        id="reminder"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {renderAccordionHeader("Oprettede påmindelse")}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <div className="text-m">
              <b>
                <AxoLocal
                  entity="CalendarEventBoxFormtitle"
                  defaultValue={"Titel"}
                />
                :
              </b>{" "}
              <small>{calendarEvent.title}</small>
              <br />
              <b>
                <AxoLocal
                  entity="SidebarRightContainercalendarEventstart"
                  defaultValue={"Start"}
                />
                :
              </b>{" "}
              <small> {moment(calendarEvent.start).format("L LT")}</small>
              <br />
              <b>
                <AxoLocal
                  entity="SidebarRightContainercalendarEventend"
                  defaultValue={"Slut"}
                />
                :
              </b>{" "}
              <small>{moment(calendarEvent.end).format("L LT")}</small>
              <br />
              <b>
                <AxoLocal
                  entity="SidebarRightContainercalendarEventallDay"
                  defaultValue={"Hele dagen"}
                />
                :
              </b>{" "}
              <small>{calendarEvent.allDay ? "Ja" : "Nej"}</small>
              <br />
              <b>
                <AxoLocal
                  entity="SidebarRightContainercalendarEventbookingTime"
                  defaultValue={"Tid til booking for kunder"}
                />
                :
              </b>{" "}
              <small>{calendarEvent.bookingTime ? "Ja" : "Nej"}</small>
              <br />
              <b>
                <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />:
              </b>{" "}
              <small>{calendarEvent.description}</small>
              <br />
              {this.renderReminderTexts(calendarEvent)}
            </div>
            <div>
              <Button
                style={{ width: "100px" }}
                className="Lex-Accordion "
                onClick={this.onBackToCalendarEvent}
              >
                <Icon glyph="icon-fontello-left-bold-1" />
              </Button>
            </div>
          </Panel.Body>
        </Panel>
      </PanelGroup>
    );
  };

  renderCalendarEventSection = () => {
    if (this.state.calendarEventAdded) {
      return this.renderCalendarEventView();
    }
    return this.renderCalendarEventForm();
  };

  getTotalTimeSpent = () => {
    if (!this.props.selectedCase.timeEntries) {
      return 0;
    }
    var timeEntriesWithTime = this.props.selectedCase.timeEntries.filter(
      (t) => t.unitType === "Hours"
    );
    return timeEntriesWithTime.reduce((acc, timeEntry) => {
      return acc + timeEntry.units;
    }, 0);
  };

  getTimeSpentString = () => {
    var hours = this.getTotalTimeSpent();
    return TimeEntryService.getTimeString(hours);
  };

  getExpenses = () => {
    return this.props.selectedCase.timeEntries.reduce((acc, timeEntry) => {
      return acc + timeEntry.expenses;
    }, 0);
  };

  getPriorityTitle = (priority) => {
    switch (priority) {
      case 0:
        return (
          <span>
            <AxoLocal
              entity="SidebarRightContainerpriorityLow"
              defaultValue={"Lav"}
            />
            &nbsp;
            <Icon style={{ color: "blue" }} glyph="icon-fontello-circle" />
          </span>
        );
      case 1:
        return (
          <span>
            <AxoLocal
              entity="SidebarRightContainerpriorityNormal"
              defaultValue={"Normal"}
            />
            &nbsp;
            <Icon style={{ color: "green" }} glyph="icon-fontello-circle" />
          </span>
        );
      case 2:
        return (
          <span>
            <AxoLocal
              entity="SidebarRightContainerpriorityHigh"
              defaultValue={"Høj"}
            />
            &nbsp;{" "}
            <Icon style={{ color: "darkred" }} glyph="icon-fontello-circle" />
          </span>
        );
      case 3:
        return (
          <span>
            <AxoLocal
              entity="SidebarRightContainerpriorityUrgent"
              defaultValue={"Haster"}
            />
            &nbsp;
            <Icon style={{ color: "red" }} glyph="icon-fontello-circle" />
          </span>
        );
      default:
        return <span></span>;
    }
  };

  renderCalendarEvents = (calendarEvents) => {
    return calendarEvents.map((event) => {
      return (
        <div
          className="axogrouplist"
          key={event.id}
          role="button"
          onClick={this.onClickEventNode.bind(this, event)}
        >
          <div className="axogrouplist">
            {" "}
            {moment(event.start).format("L LT") + " - " + event.title}
          </div>
        </div>
      );
    });
  };

  renderCalendarEventListSection = () => {
    var currentCase = this.props.selectedCase;
    if (currentCase.events.length === 0) {
      return null;
    }
    var sortedEvents = currentCase.events.sort((e1, e2) => {
      return new Date(e1.start) - new Date(e2.start);
    });
    var expiredEvents = sortedEvents.filter(
      (event) => new Date(event.start) < new Date()
    );
    var currentEvents = sortedEvents.filter(
      (event) => new Date(event.start) >= new Date()
    );
    return (
      <PanelGroup
        id="Trash"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {renderAccordionHeader(
                <span>
                  <Icon glyph="icon-fontello-clock" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="SidebarRightContainerCalendarEventscurrentEvents"
                    defaultValue={"Påmindelser"}
                  />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            {this.renderCalendarEvents(currentEvents)}
            {expiredEvents.length > 0 ? (
              <PanelGroup
                id="reminder"
                accordion
                className="Lex-Accordion "
                defaultActiveKey="0"
              >
                <Panel
                  className="backgroundlineargradient3"
                  header={renderAccordionHeader(
                    <AxoLocal
                      entity="SidebarRightContainerCalendarEventsexpiredEvents"
                      defaultValue={"Tidligere påmindelser"}
                    />
                  )}
                  eventKey="1"
                >
                  {this.renderCalendarEvents(expiredEvents)}
                </Panel>
              </PanelGroup>
            ) : null}
          </Panel.Body>
        </Panel>
      </PanelGroup>
    );
  };

  renderCaseInformationPanel = () => {
    var selectedCase = this.props.selectedCase;
    return (
      <div style={{ paddingTop: "5px" }}>
        <div className="no-list-margin text-m">
          <div
            className="backgroundlineargradient3"
            style={{ paddingLeft: "15px" }}
          >
            <div>
              <Icon glyph="icon-fontello-th-3" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="SidebarRightContainerfileInformation"
                  defaultValue={"Sagsinformation"}
                />
              </b>
            </div>
            <div>
              <Icon glyph="icon-fontello-barcode-1" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="ContainercaseNumber"
                  defaultValue={"Sagsnummer"}
                />
                :
              </b>{" "}
              <small>{selectedCase.caseNumber}</small>
            </div>

            <div>
              <Icon glyph="icon-fontello-qrcode" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledF"
                  defaultValue={"Internt sagsnummer:"}
                />
              </b>{" "}
              <small>{selectedCase.internalNumber}</small>
            </div>

            <div>
              <Icon glyph="icon-fontello-tasks" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="SidebarRightContainerCasetype"
                  defaultValue={"Retsinstans"}
                />
                :
              </b>{" "}
              <small>{selectedCase.type}</small>
            </div>

            <div>
              <Icon glyph=" icon-fontello-pencil-alt-1" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="CaseEditFormcasesubject"
                  defaultValue={"Emne"}
                />
                :
              </b>{" "}
              <small>{selectedCase.subject}</small>
            </div>

            <div>
              <Icon glyph="icon-fontello-hammer" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledC"
                  defaultValue={"Sagsområde:"}
                />
              </b>{" "}
              <small>{selectedCase.field}</small>
            </div>

            <div>
              <Icon glyph="icon-fontello-target-4" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="CaseDataTableFixedcaseEnabledD"
                  defaultValue={"Reference"}
                />
                :
              </b>{" "}
              <small>{selectedCase.reference}</small>
            </div>

            <div>
              <Icon glyph="icon-fontello-record-1" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="SidebarRightContainerCasepriority"
                  defaultValue={"Prioritet"}
                />
                :
              </b>{" "}
              <small>
                &nbsp;&nbsp;{this.getPriorityTitle(selectedCase.priority)}
              </small>
            </div>

            <div>
              <Icon glyph="icon-fontello-statusnet" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
                :
              </b>{" "}
              &nbsp;&nbsp; <small>{selectedCase.statusMessage}</small>
            </div>

            <div>
              <Icon glyph="icon-fontello-stopwatch" />
              &nbsp;&nbsp;
              <b>
                <AxoLocal
                  entity="SidebarRightContainerCaseTime"
                  defaultValue={"Tidsforbrug"}
                />
                :
              </b>{" "}
              <small> &nbsp;&nbsp;{this.getTimeSpentString()}</small>
            </div>

            <div>
              <b>
                <AxoLocal
                  entity="TimeEntryFormExpensesLabel"
                  defaultValue="Andre udgifter"
                />
                :
              </b>{" "}
              <small> &nbsp;&nbsp;{this.getExpenses()}</small>
            </div>
          </div>
        </div>
        <TimeEntryFormContainer
          timeEntryCase={selectedCase}
          documentMap={this.props.documentMap}
          userProfile={this.props.userProfile}
        />
        {this.renderCalendarEventSection()}
        {this.renderDocumentInformation()}
        {this.renderCalendarEventListSection()}
      </div>
    );
  };

  renderBody = () => {
    if (this.props.selectedCase) {
      return this.renderCaseInformationPanel();
    } else if (this.props.userProfile.id) {
      return (
        <FrontPageSidebar
          userProfile={this.props.userProfile}
          userGroup={this.props.userGroup}
          userSettings={this.props.userSettings}
          documentMap={this.props.documentMap}
          libraryFileNames={this.props.libraryFileNames}
          userSubscription={this.props.userSubscription}
          storageInformation={this.props.storageInformation}
          subscriptionPlans={this.props.subscriptionPlans}
          groupMembers={this.props.groupMembers}
          getGroupMembers={this.props.getGroupMembers}
          clearGroupMembers={this.props.clearGroupMembers}
          removeGroupMember={this.props.removeGroupMember}
          globalSettings={this.props.globalSettings}
          theme={this.props.theme}
          paymentRequests={this.props.paymentRequests}
        />
      );
    }
  };

  render() {
    return (
      <div>
        {this.renderBody()}
        <FileViewerModal
          size="large"
          ref={(c) => (this.fileModal = c)}
          selectedCase={this.props.selectedCase}
        />
        <CreateCalendarEventModal
          onUpdateEvent={this.onUpdateEvent}
          onDelete={this.onDeleteEvent}
          ref={(c) => (this.calendarEventModal = c)}
          userProfile={this.props.userProfile}
          router={this.props.router}
        />
      </div>
    );
  }
}
