import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { LexButton, AxoLocal } from "../../utilities/LexUtilities";

import { ButtonToolbar } from "react-bootstrap";

const div = (props) => {
  return <div style={{ paddingBottom: "5px" }}>{props.children}</div>;
};

export default class ManagementContractlList extends React.PureComponent {
  static propTypes = {
    model: PropTypes.object.isRequired,
  };

  onPrint = () => {
    window.print();
  };

  render() {
    let { model, onEdit } = this.props;

    return (
      <div id="printList">
        <div className="hidden-print">
          <ButtonToolbar>
            <LexButton onClick={onEdit}>
              <AxoLocal
                entity="presentationMarketing30"
                defaultValue="Rediger"
              />{" "}
            </LexButton>
            <LexButton onClick={this.onPrint}>
              <AxoLocal
                entity="PrintCaseClientadressePrint"
                defaultValue={"Print"}
              />
            </LexButton>
          </ButtonToolbar>
        </div>
        <div className="pageSection">
          <div>
            <div>
              <h1>Ansættelseskontrakt</h1>
            </div>
            <div>
              <h3>Mellem undertegnede</h3>{" "}
            </div>
            <div>
              <strong>{model.employerName}</strong>
            </div>
            <div>{model.employerAddress}</div>
            <div>
              {model.employerPostalCode} {model.employerCity}
            </div>
            <div>herefter kaldet ”Virksomheden”</div>
            <div>
              <h4>Og:</h4>
            </div>
            <div>
              <strong>{model.workerName}</strong>
            </div>
            <div>{model.workerAddress}</div>
            <div>
              {model.workerPostalCode} {model.workerCity}
            </div>
            <div>herefter kaldet ”Medarbejderen”</div>
            <div>
              <strong>1. Indledning</strong>
            </div>
            <div>
              Medarbejderen er forpligtet til altid at holde Virksomheden
              underrettet om ændringer i ovenstående personlige oplysninger.
            </div>
            <div>Der er indgået aftale om ansættelse på følgende vilkår:</div>
            <div>
              <strong>2. Arbejdssted</strong>
            </div>
            <div>Medarbejderens arbejdssted er på selskabets adresse:</div>
            <div>
              <strong>{model.workPlaceAddress}</strong>
            </div>
            <div>
              <strong>3. Arbejdsbeskrivelse</strong>
            </div>
            <div>Medarbejderen ansættes som:</div>
            <div>
              <strong>{model.jobTitle}</strong>
            </div>
            <div>Medarbejderens primære arbejdsopgaver er følgende: </div>
            <div>{model.jobDescription}</div>
            <div>
              <strong>{moment.utc(model.startDate).format("L")}</strong>
            </div>
            <p className="text-right editable ">sider 1/3</p>
            <br />
          </div>

          <div className="footerpagebreak"></div>
          <div className="pageSection">
            <div>
              <strong>4. Tiltrædelsestidspunkt</strong>
            </div>
            <div>Ansættelsesforholdet påbegyndes den :</div>
            <div>{moment.utc(model.startDate).format("L")}</div>
            <div>
              <strong>5. Løn</strong>
            </div>
            <div>
              Lønnen er fastsat til <strong> {model.salary}</strong> kr. per
              måned, som udbetales bagud den sidste bankdag i måneden.
              Overarbejde skal afspadseres.
            </div>
            <div>
              Der er ved fastsættelsen af nævnte løn taget højde for
              forekommende overarbejde. Der ydes derfor ikke tillæg til
              timelønnen ved overarbejde.
            </div>
            <div>Overarbejde kan også afspadseres.</div>
            <div>
              <strong>6. Arbejdstid</strong>
            </div>
            <div>
              Den normale ugentlige arbejdstid er aftalt til {model.workTime}{" "}
              timer.
            </div>
            <div>
              Med hensyn til den ugentlige arbejdstid tilstræbes det, at
              arbejdet i videst muligt omfang udføres indenfor den normale
              arbejdstid,{" "}
            </div>
            <div>Arbejdstiden er eksklusiv ½ times daglig frokostpause.</div>
            <div>
              <strong>7. Ferie</strong>
            </div>
            <div>
              Medarbejderen er berettiget til ferie efter den altid gældende
              Ferielov.
            </div>
            <div>
              <strong>8. Opsigelse</strong>
            </div>
            <div>
              <strong>Prøvetid</strong>
            </div>
            <div>
              De første 3 måneders af ansættelsen anses som prøvetid, i denne
              periode kan opsigelses fra såvel arbejdsgiver som medarbejderens
              side finde sted med 14 dages varsel til fratræden en hvilken som
              helst dag i måneden.
            </div>
            <div>
              <strong>Efter 3 måneder</strong>
            </div>
            <div>
              Ansættelsesforholdet kan efter prøvetiden opsiges af medarbejderen
              og arbejdsgiveren med
            </div>
            <div>
              {model.terminationPeriod} måneds varsel til ophørt ved udløbet af
              en kalendermåned.
            </div>
            <div>
              <strong>9. Tavshedspligt</strong>
            </div>
            <div>
              Medarbejderen har tavshedspligt med hensyn til alt, hvad
              medarbejderen erfarer i forbindelse
            </div>
            <div>
              med udførelsen af sit arbejde, med mindre der er tale om forhold,
              der efter sagens natur må
            </div>

            <p className="text-right editable">sider 2/3</p>
          </div>

          <div className="footerpagebreak"></div>
          <div className="pageSection">
            <div>
              bringes til tredjemands kundskab. Denne tavshedspligt er også
              gældende efter
            </div>
            <div>ansættelsesforholdet ophør.</div>
            <div>
              Når medarbejderen fratræder sin stilling – uanset af hvilken årsag
              – skal alt materiale, der tilhører
            </div>
            <div>
              arbejdsgiveren, og som er i medarbejderens besiddelse, afleveres
              til arbejdsgiveren.
            </div>
            <div>
              <strong>10. Øvrige bestemmelser</strong>
            </div>
            <div>
              Ansættelsesforholdet er ikke omfattet af en overenskomst og
              funktionærlovens bestemmelser finder ikke anvendelse.
            </div>
            <div>
              <strong>11. Underskrift og godkendelse</strong>
            </div>
            <div>
              Ansættelseskontrakten er udarbejdet i 2 ens eksemplarer. Hver af
              parterne modtager et originalt dokument.{" "}
            </div>
            <div>
              Begge parter er indforstået med de anførte ansættelsesvilkår.
            </div>
            <div>{model.other}</div>
            <br />
            <div>{moment.utc(model.contractDate).format("L")}</div>
            <br />
            <div></div>
            <div>{model.employerName}___________________</div>
            <br />
            <div>{model.workerName}_____________________</div>

            <p className="text-right editable">sider 3/3</p>
          </div>
        </div>
      </div>
    );
  }
}
