import React from "react";
import { RIENumber } from "./Riek";
import PropTypes from "prop-types";

import NumberService from "../services/NumberService";

export default class InlineNumberEdit extends React.PureComponent {
  static propTypes = {
    value: PropTypes.any.isRequired,
    change: PropTypes.func.isRequired,
    className: PropTypes.string,
    editStyle: PropTypes.object,
    validate: PropTypes.func,
    decimal: PropTypes.bool,
  };

  decimalFormat = (value) => {
    return <span>{NumberService.formatDecimal(value)}</span>;
  };

  render() {
    var {
      className,
      editStyle,
      value,
      format,
      change,
      validate,
      decimal,
      onEdit,
      onFinish,
    } = this.props;

    className = className || "editable";
    editStyle = editStyle || { width: "100%" };
    return (
      <RIENumber
        value={value}
        format={decimal ? this.decimalFormat : format}
        change={change}
        propName="value"
        className={className}
        editProps={{ style: editStyle }}
        validate={validate}
        afterStart={onEdit}
        beforeFinish={onFinish}
      />
    );
  }
}
