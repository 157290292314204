import React from "react";

import { Consumer } from "./../Context";

export default function withData(WrappedComponent, mapStoreToProps) {
  return class extends React.Component {
    render() {
      return (
        <Consumer>
          {(store) => (
            <WrappedComponent {...this.props} {...mapStoreToProps(store)} />
          )}
        </Consumer>
      );
    }
  };
}
