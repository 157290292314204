import React from "react";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Row, Col, Grid, Button } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

class ClientInvoiceCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shortenString(input, maxChars) {
    if (input.length > maxChars) {
      return input.substring(0, maxChars - 3) + "...";
    }
    return input;
  }

  getStatusText = (status) => {
    switch (status) {
      case "Created":
        return (
          <AxoLocal
            key="created"
            entity="InvoiceGridViewcreated"
            defaultValue={"Created"}
          />
        );
      case "Sent":
        return (
          <AxoLocal
            key="submitted"
            entity="InvoiceGridViewSubmitted"
            defaultValue={"Submitted"}
          />
        );
      case "Paid":
        return (
          <AxoLocal
            key="paid"
            entity="InvoiceInvoicePaid"
            defaultValue={"Paid"}
          />
        );
      case "Approved":
        return (
          <AxoLocal
            key="approved"
            entity="InvoiceGridViewapproved"
            defaultValue={"Approved"}
          />
        );
      case "Overdue":
        return (
          <AxoLocal
            key="overdue"
            entity="InvoiceGridViewoverdue"
            defaultValue={"Overdue"}
          />
        );
      default:
        return "";
    }
  };

  getStatusIcon = (status) => {
    switch (status) {
      case "Created":
        return (
          <div>
            <Icon
              style={{ color: "#428bca" }}
              glyph="icon-fontello-check-empty"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Sent":
        return (
          <div>
            <Icon style={{ color: "#306C67" }} glyph="icon-fontello-export-3" />
            &nbsp;&nbsp;
          </div>
        );
      case "Paid":
        return (
          <div>
            <Icon style={{ color: "#518F41" }} glyph="icon-fontello-check-1" />
            &nbsp;&nbsp;
          </div>
        );
      case "Approved":
        return (
          <div>
            <Icon
              style={{ color: "#003BFF" }}
              glyph="icon-fontello-ok-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Overdue":
        return (
          <div>
            <Icon
              style={{ color: "#D71F4B" }}
              glyph="icon-fontello-cancel-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      default:
        return <div></div>;
    }
  };

  renderInformation = () => {
    var invoice = this.props.invoice;
    return (
      <div>
        <table className="table Lex-Grid-tabl">
          <tbody>
            <tr>
              <th>
                <Icon glyph="icon-fontello-calendar-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="paymentdate"
                  entity="InvoiceGridViewpaymentDate"
                  defaultValue={"Betalingsdato"}
                />
                :{" "}
              </th>
              <td>{moment(invoice.dueDate).format("L")}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="icon-fontello-statusnet" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="statusleft"
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
                :{" "}
              </th>
              <td className="flexbox-standard">
                {this.getStatusIcon(invoice.status)}
                &nbsp;
                {this.getStatusText(invoice.status)}
              </td>
            </tr>
            <tr>
              <th>
                <Icon glyph="icon-simple-line-icons-users" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="AdminTabView35A"
                  defaultValue={"Afsender"}
                />:{" "}
              </th>
              <td>
                {invoice.userProfile.lastName}, {invoice.userProfile.firstName}
              </td>
            </tr>
            <tr>
              <th>
                <Icon glyph="icon-fontello-database-2" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="Amonts"
                  entity="InvoiceGridViewRemainderAmount"
                  defaultValue={"Restbeløb"}
                />
                :{" "}
              </th>
              <td>
                {invoice.currency} {invoice.outstandingAmount}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderContent = () => {
    return this.renderInformation();
  };

  render() {
    var invoice = this.props.invoice;
    return (
      <div>
        <div
          style={{
            marginBottom: "20px",
            border: "1px solid #DCDCDC",
          }}
        >
          <div>
            <div className="gradient-baggrund">
              <Grid fluid>
                <Row>
                  <Col xs={12} className="editable text-center">
                    <span
                      role="button"
                      onClick={this.props.onSelectInvoice.bind(
                        this,
                        invoice.id
                      )}
                      style={{ lineHeight: "50px" }}
                    >
                      # {invoice.number ? invoice.number.toString() : "0"}
                    </span>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div>
              <Grid fluid>
                <Row>
                  <Col xs={12}>{this.renderContent()}</Col>
                </Row>
              </Grid>
            </div>
            <div className="Lex-CardFooter">
              <Grid fluid>
                <Row>
                  <Col xs={12} style={{ padding: "0px" }}></Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ClientInvoiceGridView extends React.PureComponent {
  //Props
  //invoices
  //onSelectInvoice
  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateInvoiceCols = (invoices) => {
    var cols = [[], [], []];
    var smallCols = [[], []];
    for (var i = 0; i < invoices.length; i++) {
      var invoice = invoices[i];

      var invoiceCard = (
        <ClientInvoiceCard
          key={invoice.id}
          invoice={invoice}
          active={this.props.highlightedInvoiceId === invoice.id}
          onInvoiceChange={this.onInvoiceChange}
          onSelectInvoice={this.props.onSelectInvoice}
          onStatusSelect={this.props.onStatusSelect}
          onCreateAndSendPDF={this.props.onCreateAndSendPDF}
        />
      );

      cols[i % 3].push(invoiceCard);
      smallCols[i % 2].push(invoiceCard);
    }
    return [cols, smallCols];
  };

  renderInvoiceGrid = (invoices) => {
    var cols = this.generateInvoiceCols(invoices);
    var largeCols = cols[0];
    var smallCols = cols[1];
    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[0]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[1]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  getShownItems = () => {
    return this.props.invoices.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    return (
      <Grid fluid style={{ background: "#ffffff" }}>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            {this.renderInvoiceGrid(this.getShownItems())}
          </Col>
        </Row>
        {this.state.numberShown < this.props.invoices.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col xs={12} className="text-center" style={{ paddingTop: "10px" }}>
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2 "
                style={{ width: "75%" }}
              >
                <AxoLocal
                  key="stylewitha"
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(ClientInvoiceGridView);
