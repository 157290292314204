import React from "react";
import {
  CountryDropdown,
  RegionDropdown,
} from "../../utilities/CountrySelect/Dropdowns";
import { AxoListGroup, AxoListItem } from "../../utilities/AxoListGroup";
import * as $ from "jquery";
import { Col, Button, InputGroup, Panel, PanelGroup } from "react-bootstrap";

import {
  DataActions,
  ApiService,
  DataStore,
  SocialPostStore,
  UserInfoService,
  ModalService,
  RoutingService,
} from "../../services/AxoServices";

import {
  AxoCheckbox,
  // AccordionUtils,
  UserSearchBox,
  ImageSearchBox,
  LibraryFileSearchBox,
  Icon,
  AxoLocal,
  InlineEdit,
  Consumer,
  withRouter,
} from "../../utilities/LexUtilities";

function renderAccordionHeader(title) {
  return (
    <div>
      <Col xs={9} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={3} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

class FriendRequestForm extends React.PureComponent {
  //Props
  //potentialFriends
  //handleSelectedUser
  //selectedUser
  //onSend
  render() {
    return (
      <PanelGroup
        id="socialSidebara"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {renderAccordionHeader(
                <span>
                  <Icon glyph="icon-fontello-search-1" />
                  &nbsp;
                  <AxoLocal
                    entity="FriendRequestFormAccordionHeader"
                    defaultValue={"Send anmodning"}
                  />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <div>
              <AxoCheckbox
                onChange={this.props.onCheckAccept}
                checked={this.props.acceptRequests}
              />
              <span>
                <AxoLocal
                  entity="socialSidebarContainerReceiveRequests"
                  defaultValue={"Modtag venneanmodninger"}
                />
              </span>
            </div>
            <br />
            <InputGroup>
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-user" />
              </InputGroup.Addon>
              <UserSearchBox
                userProfiles={this.props.potentialFriends}
                handleSelectedUser={this.props.handleSelectedUser}
              />
            </InputGroup>
            <br />
            <Button
              className="Lex-button-2 "
              disabled={!this.props.selectedUser.id}
              onClick={this.props.onSend}
            >
              <Icon glyph="icon-fontello-user-add" />
              &nbsp;
              <AxoLocal
                entity="socialSidebarContainerSendRequest"
                defaultValue={"Send kontaktanmodning"}
              />
            </Button>
          </Panel.Body>
        </Panel>
      </PanelGroup>

      // <Accordion className='Lex-Accordion ' defaultActiveKey='0' style={{ borderRadius: '0px',paddingTop:'0px',borderBottom:'1px solid white'}} >
      //   <Panel className=' backgroundlineargradient3' header={renderAccordionHeader(<span><Icon glyph='icon-fontello-search-1'/>&nbsp;
      //   <AxoLocal entity='FriendRequestFormAccordionHeader' defaultValue={'Send anmodning'}/></span>)} eventKey="1">
      //     <div>
      //           <AxoCheckbox
      //           onChange={this.props.onCheckAccept}
      //           checked={this.props.acceptRequests}
      //           />
      //         <span>
      //           <AxoLocal entity='socialSidebarContainerReceiveRequests' defaultValue={'Modtag venneanmodninger'}/>
      //         </span>
      //     </div>
      //     <br/>
      //     <InputGroup>
      //       <InputGroup.Addon>
      //         <Icon glyph='icon-fontello-user' />
      //       </InputGroup.Addon>
      //       <UserSearchBox
      //         userProfiles={this.props.potentialFriends}
      //         handleSelectedUser={this.props.handleSelectedUser}
      //       />
      //     </InputGroup>
      //     <br/>
      //     <Button className='Lex-button-2 '
      //       disabled={!this.props.selectedUser.id}
      //       onClick={this.props.onSend}>
      //       <Icon glyph='icon-fontello-user-add' />&nbsp;
      //       <AxoLocal entity='socialSidebarContainerSendRequest' defaultValue={'Send kontaktanmodning'}/>
      //     </Button>
      //   </Panel>
      // </Accordion>
    );
  }
}

class FriendRequestConfirmationView extends React.PureComponent {
  render() {
    return (
      <PanelGroup
        id="socialSidebarb"
        accordion
        className="Lex-Accordion "
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {renderAccordionHeader(
                <AxoLocal
                  entity="socialSidebarContainerRequestSent"
                  defaultValue={"Anmodningen blev afsendt."}
                />
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <h4>
              <AxoLocal
                entity="socialSidebarContainerRequestSent"
                defaultValue={"Anmodningen blev afsendt."}
              />
            </h4>
            <div>
              <Button
                className="Lex-Accordion "
                onClick={this.props.onBackToForm}
              >
                <Icon
                  style={{ width: "70px" }}
                  glyph="icon-fontello-left-bold-1"
                />
              </Button>
            </div>
          </Panel.Body>
        </Panel>
      </PanelGroup>

      // <Accordion className='Lex-Accordion ' defaultActiveKey='0'>
      //   <Panel className=' backgroundlineargradient3' header={renderAccordionHeader(<AxoLocal entity='socialSidebarContainerRequestSent' defaultValue={'Anmodningen blev afsendt.'}/>)} eventKey="1">
      //     <h4><AxoLocal entity='socialSidebarContainerRequestSent' defaultValue={'Anmodningen blev afsendt.'}/>
      //       </h4>
      //     <div>
      //       <Button className='Lex-Accordion ' onClick={this.props.onBackToForm}>
      //         <Icon glyph='icon-fontello-left-bold-1'/>
      //       </Button>
      //     </div>
      //   </Panel>
      // </Accordion>
    );
  }
}

class FriendListBox extends React.PureComponent {
  //Props
  //friends
  onSelectFriend = (friend) => {
    DataActions.selectUserProfile(friend.id);
    this.props.history.push(RoutingService.getPath("Social/Profile"));
  };

  renderFriendNodes = () => {
    return this.props.friends.map((friend) => {
      var friendString = UserInfoService.getDisplayName(friend);
      return (
        <AxoListItem key={friend.id}>
          <p
            className=""
            role="button"
            onClick={this.onSelectFriend.bind(this, friend)}
          >
            <img
              alt=""
              className="img-circle"
              src={UserInfoService.getProfileImageSource(friend)}
              width="30"
              height="30"
            />
            &nbsp;{friendString}
          </p>
        </AxoListItem>
      );
    });
  };

  render() {
    return (
      <PanelGroup
        id="socialSidebarc"
        accordion
        className="Lex-Accordion "
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {renderAccordionHeader(
                <span>
                  <Icon glyph="icon-simple-line-icons-users" />
                  &nbsp;
                  <AxoLocal
                    entity="socialSidebarContainerFriendList"
                    defaultValue={"Intern venneliste"}
                  />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <AxoListGroup>{this.renderFriendNodes()}</AxoListGroup>
          </Panel.Body>
        </Panel>
      </PanelGroup>

      // <Accordion className='Lex-Accordion ' defaultActiveKey='0' style={{ borderRadius: '0px',paddingTop:'0px',borderBottom:'1px solid white'}}>
      //   <Panel className='' header={renderAccordionHeader(<span><Icon glyph='icon-simple-line-icons-users'/>&nbsp;
      //   <AxoLocal entity='socialSidebarContainerFriendList' defaultValue={'Intern venneliste'}/>
      //   </span>)} eventKey="1">
      //     <AxoListGroup>
      //       {this.renderFriendNodes()}
      //     </AxoListGroup>
      //   </Panel>
      // </Accordion>
    );
  }
}

class SocialProfileForm extends React.PureComponent {
  onProfilePropertyChange(propertyName, input) {
    var userProfile = Object.assign({}, this.props.userProfile);
    userProfile[propertyName] = input.value;
    this.props.onUpdate(userProfile);
  }

  handleSelectedProfileImage = (document) => {
    var userProfile = Object.assign({}, this.props.userProfile);
    var existingImageId = userProfile.profileImage
      ? userProfile.profileImage.id
      : -1;

    userProfile.profileImage = document;
    userProfile.profileImageLibrary = "";

    this.props.onUpdate(userProfile).then((response) => {
      if (response.ok && existingImageId !== -1) {
        DataActions.deleteDocument(existingImageId);
      }
    });
  };

  handleSelectedProfileImageLibrary = (fileName) => {
    var userProfile = Object.assign({}, this.props.userProfile);

    userProfile.profileImage = null;
    userProfile.profileImageLibrary = fileName;

    this.props.onUpdate(userProfile);
  };

  onRemoveProfileImage = () => {
    var userProfile = Object.assign({}, this.props.userProfile);
    userProfile.profileImage = null;
    userProfile.profileImageLibrary = "";
    this.props.onUpdate(userProfile);
  };

  uploadProfileImage = () => {
    $("#uploadProfileImage").trigger("click");
  };

  handleSelectedBanner = (document) => {
    var userProfile = Object.assign({}, this.props.userProfile);
    var existingBannerId = userProfile.banner ? userProfile.banner.id : -1;

    userProfile.banner = document;
    userProfile.bannerLibrary = "";

    this.props.onUpdate(userProfile).then((response) => {
      if (response.status === 200 && existingBannerId !== -1) {
        DataActions.deleteDocument(existingBannerId);
      }
    });
  };

  handleSelectedBannerLibrary = (fileName) => {
    var userProfile = Object.assign({}, this.props.userProfile);

    userProfile.banner = null;
    userProfile.bannerLibrary = fileName;

    this.props.onUpdate(userProfile);
  };

  onRemoveBanner = () => {
    var userProfile = Object.assign({}, this.props.userProfile);
    userProfile.banner = null;
    userProfile.bannerLibrary = "";
    this.props.onUpdate(userProfile);
  };

  uploadBanner = () => {
    $("#uploadBanner").trigger("click");
  };

  onImageUploaded = (event) => {
    var uploader = event.target;
    this.uploadFile(uploader, this.handleSelectedProfileImage);
  };

  onBannerUploaded = (event) => {
    var uploader = event.target;
    this.uploadFile(uploader, this.handleSelectedBanner);
  };

  uploadFile = (uploader, callback) => {
    var file = uploader.files[0];
    if (!file.type.includes("image")) {
      return;
    }
    DataActions.uploadDocuments(uploader.files, { isSocial: true })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(new Error("Upload failed"));
      })
      .then((fileModels) => {
        var fileModel = fileModels[0];
        callback(fileModel);
      })
      .catch((reason) => {
        console.error(reason);
      });
    uploader.value = "";
  };

  onCheckShowProperty = (propertyName, event) => {
    let { userProfile } = this.props;

    this.props.onUpdate({
      ...userProfile,
      [propertyName]: event.target.checked,
    });
  };

  renderFieldTable = () => {
    return null;
  };

  renderInputField = (label, propertyName, showPropertyName) => {
    var userProfile = this.props.userProfile;
    return (
      <div>
        <AxoCheckbox
          checked={userProfile[showPropertyName]}
          onChange={this.onCheckShowProperty.bind(this, showPropertyName)}
        />{" "}
        &nbsp;
        <b>{label}:</b>{" "}
        <InlineEdit
          value={userProfile[propertyName] || "---"}
          change={this.onProfilePropertyChange.bind(this, propertyName)}
        />
      </div>
    );
  };

  selectCountry(val) {
    var userProfile = Object.assign({}, this.props.userProfile);
    userProfile.country = val;
    this.props.onUpdate(userProfile);
  }

  selectRegion(val) {
    var userProfile = Object.assign({}, this.props.userProfile);
    userProfile.region = val;
    this.props.onUpdate(userProfile);
  }

  //userProfile
  render() {
    var userProfile = this.props.userProfile;
    return (
      <div className="text-m ">
        <div>
          <b>
            <AxoLocal
              entity="socialSidebarContainerChooseInfo"
              defaultValue={"Vælg de informationer der skal vises på profilen."}
            />
          </b>
        </div>
        <div>
          <b>
            <AxoLocal
              entity="socialSidebarContainerDisplayName"
              defaultValue={"Display navn:"}
            />
          </b>{" "}
          <InlineEdit
            value={userProfile.displayName || "---"}
            change={this.onProfilePropertyChange.bind(this, "displayName")}
          />
        </div>

        <div>
          <AxoCheckbox
            checked={userProfile.showRealName}
            onChange={this.onCheckShowProperty.bind(this, "showRealName")}
          />{" "}
          &nbsp;
          <b>
            <AxoLocal
              entity="socialSidebarContainerNameLabel"
              defaultValue={"Navn"}
            />
            :
          </b>{" "}
          <Icon glyph="icon-fontello-user-7" />
          &nbsp;{" "}
          <InlineEdit
            value={userProfile.firstName || "---"}
            change={this.onProfilePropertyChange.bind(this, "firstName")}
          />{" "}
          <InlineEdit
            value={userProfile.lastName || "---"}
            change={this.onProfilePropertyChange.bind(this, "lastName")}
          />
        </div>
        {this.renderInputField(
          <span>
            <AxoLocal
              entity="socialSidebarContainerCompany"
              defaultValue={"Virksomhed"}
            />
            &nbsp;
            <Icon glyph="icon-fontello-building" />
          </span>,
          "company",
          "showCompany"
        )}
        {this.renderInputField(
          <span>
            <AxoLocal
              entity="socialSidebarContainerPosition"
              defaultValue={"Stilling"}
            />
            &nbsp;
            <Icon glyph="icon-fontello-feather" />
          </span>,
          "position",
          "showPosition"
        )}
        {this.renderInputField(
          <span>
            <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
            &nbsp;
            <Icon glyph="icon-fontello-location-4" />
          </span>,
          "address",
          "showAddress"
        )}
        {this.renderInputField(
          <span>
            <AxoLocal
              entity="socialSideBarContainerPostalCode"
              defaultValue={"Postnummer"}
            />
            &nbsp;
            <Icon glyph="icon-fontello-direction-1" />
          </span>,
          "postalCode",
          "showPostalCode"
        )}
        {this.renderInputField(
          <span>
            <AxoLocal entity="socialSideBarContainerCity" defaultValue={"By"} />{" "}
            &nbsp;
            <Icon glyph="icon-fontello-warehouse" />
          </span>,
          "city",
          "showCity"
        )}
        {/* {this.renderInputField(<span><AxoLocal entity='socialSidebarContainerCountry' defaultValue={'Land'}/>&nbsp;<Icon glyph="icon-fontello-globe-6"/></span>, 'country', 'showCountry')} */}
        <div>
          <AxoCheckbox
            checked={userProfile.showCountry}
            onChange={this.onCheckShowProperty.bind(this, "showCountry")}
          />
          &nbsp;
          <b>
            <span>
              <AxoLocal
                entity="socialSidebarContainerCountry"
                defaultValue={"Land"}
              />
              &nbsp;
              <Icon glyph="icon-fontello-globe-6" />
            </span>
            :
          </b>{" "}
          <div style={{ display: "inline-block", width: "70%" }}>
            <CountryDropdown
              value={userProfile.country || ""}
              valueType="short"
              customSelect
              onChange={(val) => this.selectCountry(val)}
            />
          </div>
        </div>
        <div>
          <AxoCheckbox
            checked={userProfile.showRegion}
            onChange={this.onCheckShowProperty.bind(this, "showRegion")}
          />{" "}
          &nbsp;
          <b>
            <span>
              <AxoLocal
                key="region"
                entity="SidebarRightContainerRegion"
                defaultValue={"Region"}
              />
              &nbsp;
              <Icon glyph="icon-fontello-globe-outline" />
            </span>
            :
          </b>{" "}
          <div style={{ display: "inline-block", width: "70%" }}>
            <RegionDropdown
              country={userProfile.country || ""}
              countryValueType="short"
              value={userProfile.region || ""}
              valueType="short"
              onChange={(val) => this.selectRegion(val)}
            />
          </div>
        </div>

        {this.renderInputField(
          <span>
            <AxoLocal entity="ContainerPhone" defaultValue={"Telefon"} />
            &nbsp;
            <Icon glyph="icon-fontello-phone" />
          </span>,
          "phone",
          "showPhone"
        )}
        {this.renderInputField(
          <span>
            <AxoLocal
              entity="socialSidebarContainerMobile"
              defaultValue={"Mobil"}
            />
            &nbsp; <Icon glyph="icon-fontello-mobile-4" />
          </span>,
          "mobile",
          "showMobile"
        )}
        {this.renderInputField(
          <span>
            <AxoLocal
              entity="socialSidebarContainerEmail"
              defaultValue={"Email"}
            />
            &nbsp; <Icon glyph="icon-fontello-at-2" />
          </span>,
          "eMail",
          "showEMail"
        )}

        {/* Profile picture handling */}
        <div>
          <span>
            <Icon glyph="icon-fontello-user-male" />
            &nbsp;
            <b>
              <AxoLocal
                entity="socialSidebarContainerProfileImage"
                defaultValue={"Profilbillede"}
              />
            </b>
          </span>
        </div>

        {userProfile.profileImage || userProfile.profileImageLibrary ? (
          <p>
            <span
              className="backgroundlineargradient3"
              role="button"
              onClick={this.onRemoveProfileImage}
            >
              <Icon role="button" glyph="icon-fontello-scissors axored" />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <AxoLocal
                entity="socialSidebarContainerRemoveProfileImage"
                defaultValue={"Fjern profilbillede"}
              />
            </span>
            <img
              alt=""
              className="img-circle"
              style={{ maxWidth: "150px", height: "auto", display: "block" }}
              src={UserInfoService.getMyProfileImageSource(userProfile)}
            />
          </p>
        ) : null}
        <div>
          <InputGroup>
            <InputGroup.Addon>
              <Icon glyph="icon-fontello-picture-1" />
            </InputGroup.Addon>
            <AxoLocal
              componentClass={LibraryFileSearchBox}
              fileNames={this.props.libraryFileNames}
              handleSelectedFileName={this.handleSelectedProfileImageLibrary}
              clearOnSelect
              componentAttribute="placeholder"
              entity="placeholderSelectLibraryFile"
            />
          </InputGroup>
        </div>
        <div>
          <InputGroup>
            <InputGroup.Addon>
              <Icon glyph="icon-fontello-picture-1" />
            </InputGroup.Addon>
            <AxoLocal
              componentClass={ImageSearchBox}
              documentMap={this.props.documentMap}
              handleSelectedImage={this.handleSelectedProfileImage}
              clearOnSelect
              componentAttribute="placeholder"
              entity="socialSidebarContainerInternalImage"
            />
          </InputGroup>
        </div>
        <br />
        <p>
          <Button className="Lex-button-2 " onClick={this.uploadProfileImage}>
            <Icon glyph="icon-fontello-camera-outline" />
            &nbsp;
            <AxoLocal
              entity="socialSidebarContainerUploadProfileImage"
              defaultValue={"Upload profilbillede fra pc"}
            />
          </Button>
        </p>

        {/* Banner picture handling */}
        <div>
          <span>
            <Icon glyph="icon-fontello-credit-card-1" />
            &nbsp;
            <b>
              <AxoLocal
                entity="socialSidebarContainerBanner"
                defaultValue={"Banner"}
              />
            </b>
          </span>
        </div>

        {userProfile.banner || userProfile.bannerLibrary ? (
          <div>
            <span
              className="backgroundlineargradient3"
              role="button"
              onClick={this.onRemoveBanner}
            >
              <Icon role="button" glyph="icon-fontello-scissors axored" />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <AxoLocal
                entity="socialSidebarContainerRemoveBanner"
                defaultValue={"Fjern banner"}
              />
            </span>
            <div
              key={
                userProfile.banner
                  ? userProfile.banner.id
                  : userProfile.bannerLibrary
              }
              style={{
                height: "150px",
                backgroundImage: this.getBackgroundImageUrl(),
                backgroundSize: "cover",
                backgroundPosition: "center",
                marginBottom: "10px",
              }}
            ></div>
          </div>
        ) : null}
        <div>
          <InputGroup>
            <InputGroup.Addon>
              <Icon glyph="icon-fontello-picture-1" />
            </InputGroup.Addon>
            <AxoLocal
              componentClass={LibraryFileSearchBox}
              fileNames={this.props.libraryFileNames}
              handleSelectedFileName={this.handleSelectedBannerLibrary}
              clearOnSelect
              componentAttribute="placeholder"
              entity="placeholderSelectLibraryFile"
            />
          </InputGroup>
        </div>
        <div>
          <InputGroup>
            <InputGroup.Addon>
              <Icon glyph="icon-fontello-picture-1 " />
            </InputGroup.Addon>
            <AxoLocal
              componentClass={ImageSearchBox}
              documentMap={this.props.documentMap}
              handleSelectedImage={this.handleSelectedBanner}
              clearOnSelect
              componentAttribute="placeholder"
              entity="socialSidebarContainerInternalImage"
            />
          </InputGroup>
        </div>
        <br />
        <p>
          <Button className="Lex-button-2" onClick={this.uploadBanner}>
            <Icon glyph="icon-fontello-desktop" />
            &nbsp;
            <AxoLocal
              entity="socialSidebarContainerUploadBanner"
              defaultValue={"Upload banner fra pc"}
            />
          </Button>
        </p>
        <input
          onChange={this.onImageUploaded}
          name="image"
          type="file"
          accept="image/*"
          id="uploadProfileImage"
          style={{ display: "none" }}
        />
        <input
          onChange={this.onBannerUploaded}
          name="image"
          type="file"
          accept="image/*"
          id="uploadBanner"
          style={{ display: "none" }}
        />
      </div>
    );
  }

  getBackgroundImageUrl = () => {
    var userProfile = this.props.userProfile;
    return (
      "url(" +
      (userProfile.banner || userProfile.bannerLibrary
        ? UserInfoService.getBannerImageSource(userProfile)
        : "") +
      ")"
    );
  };
}
// <div style={{height: 250, marginTop: 10, backgroundImage: this.getBackgroundImageUrl(), backgroundSize: 'cover', position: 'relative', marginBottom: 25, backgroundPosition: 'center'}}>

class SocialProfileContainer extends React.PureComponent {
  //Props
  //userProfile
  render() {
    return (
      <PanelGroup
        id="socialSidebard"
        accordion
        className="Lex-Accordion "
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {renderAccordionHeader(
                <span>
                  <Icon glyph="icon-fontello-network" />
                  &nbsp;
                  <AxoLocal
                    entity="socialSidebarContainerNetworkProfile"
                    defaultValue={"Netværksprofil"}
                  />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <SocialProfileForm
              userProfile={this.props.userProfile}
              onUpdate={this.props.onUpdate}
              documentMap={this.props.documentMap}
              libraryFileNames={this.props.libraryFileNames}
            />
          </Panel.Body>
        </Panel>
      </PanelGroup>

      // <Accordion className='Lex-Accordion'style={{ borderRadius: '0px',paddingTop:'0px',borderBottom:'1px solid white'}}
      // defaultActiveKey='0' >
      //   <Panel className=' backgroundlineargradient3' header={renderAccordionHeader(<span><Icon glyph='icon-fontello-network'/>&nbsp;
      //   <AxoLocal entity='socialSidebarContainerNetworkProfile' defaultValue={'Netværksprofil'}/>
      //  </span>)} eventKey="1">
      //     <SocialProfileForm
      //       userProfile={this.props.userProfile}
      //       onUpdate={this.props.onUpdate}
      //       documentMap={this.props.documentMap}
      //       libraryFileNames={this.props.libraryFileNames}
      //     />
      //   </Panel>
      // </Accordion>
    );
  }
}

class SocialSidebarView extends React.PureComponent {
  //Props
  //userProfiles
  //libraryFileNames
  //handleSelectedUser
  //selectedUser
  //onSend
  //sentRequest
  onCheckAccept = (event) => {
    this.props.userProfile.acceptRequests = event.target.checked;
    this.props.onUpdate(this.props.userProfile);
  };
  getPotentialFriends = () => {
    return this.props.userProfiles.filter(
      (p) => p.acceptRequests && !this.props.friends.find((f) => f.id === p.id)
    );
  };
  renderFriendRequestSection = () => {
    if (this.props.sentRequest) {
      return (
        <FriendRequestConfirmationView onBackToForm={this.props.onBackToForm} />
      );
    }
    return (
      <FriendRequestForm
        potentialFriends={this.getPotentialFriends()}
        acceptRequests={this.props.userProfile.acceptRequests}
        onCheckAccept={this.onCheckAccept}
        handleSelectedUser={this.props.handleSelectedUser}
        selectedUser={this.props.selectedUser}
        onSend={this.props.onSend}
      />
    );
  };
  render() {
    return (
      <div>
        {this.renderFriendRequestSection()}
        <SocialProfileContainer
          userProfile={this.props.userProfile}
          onUpdate={this.props.onUpdate}
          documentMap={this.props.documentMap}
          libraryFileNames={this.props.libraryFileNames}
        />
        <FriendListBox
          friends={this.props.friends}
          router={this.props.router}
        />
      </div>
    );
  }
}

class SocialSidebarContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: {},
      sentRequest: false,
      privateConversations: [],
      libraryFileNames: [],
    };
  }

  handleSelectedUser = (userProfile) => {
    this.setState({ selectedUser: userProfile });
  };

  onSend = () => {
    if (!this.state.selectedUser.id) {
      return;
    }
    DataActions.createFriendRequest({
      requestUser: this.state.userProfile,
      receiver: this.state.selectedUser,
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ sentRequest: true });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="socialSidebarContainerRejectRequest"
            defaultValue={
              "Kontaktanmodningen kunne ikke sendes. Prøv igen senere."
            }
          />
        );
      }
    });
  };

  onUpdate = (userProfile) => {
    return DataActions.updateUserProfile(userProfile);
  };

  onBackToForm = () => {
    this.setState({
      sentRequest: false,
      selectedUser: {},
    });
  };

  componentDidMount() {
    ApiService.getLibraryFileNames().then((libraryFileNames) => {
      this.setState({ libraryFileNames });
    });

    SocialPostStore.subscribe("SocialSidebarContainer", (store) => {
      this.setState({ privateConversations: store.privateConversations });
    });

    SocialPostStore.fetchPrivateConversations();
  }

  componentWillUnmount() {
    SocialPostStore.unsubscribe("SocialSidebarContainer");
  }

  getFilteredUserProfiles() {
    return this.props.userProfileArray.filter(
      (p) => p.id !== this.props.userProfile.id
    );
  }

  render() {
    if (!this.props.userProfile.id) {
      return null;
    }
    return (
      <SocialSidebarView
        {...this.props}
        {...this.state}
        userProfiles={this.getFilteredUserProfiles()}
        onUpdate={this.onUpdate}
        handleSelectedUser={this.handleSelectedUser}
        onSend={this.onSend}
        onBackToForm={this.onBackToForm}
      />
    );
  }
}

class SocialSidebarConsumer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserProfile();
    DataStore.fetchUserProfileArray();
    DataStore.initializeDocuments();
  }

  render() {
    return (
      <Consumer>
        {(store) => (
          <SocialSidebarContainer
            userProfile={store.userProfile}
            userProfileArray={store.userProfileArray}
            friends={store.friends.sort((f1, f2) => {
              let firstName = f1.firstName || "";
              return firstName.localeCompare(f2.firstName || "");
            })}
            documentMap={store.documentMap}
            router={this.props.router}
          />
        )}
      </Consumer>
    );
  }
}

export default withRouter(SocialSidebarConsumer);
