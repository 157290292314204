import React from "react";
import Conditional from "../utilities/Conditional";
import screenfull from "screenfull";
import { withRouter } from "react-router-dom";
import {
  ExtraSmall,
  SmallOrLarger,
  MediumOrLarger,
  SmallOrSmaller,
} from "../utilities/Responsive";

import {
  Row,
  Col,
  Grid,
  Button,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";

import {
  DataStore,
  RoutingService,
  ModalService,
  DataActions,
  StringService,
  AuthorizationService,
} from "../services/AxoServices";

import {
  Icon,
  AlertModal,
  ConfirmModal,
  AxoLocal,
  FlagMenu,
  AsyncButton,
} from "../utilities/LexUtilities";

class ClientDashboardView extends React.PureComponent {
  onClickRoute = (route) => {
    this.props.history.push(RoutingService.getPath(route));
  };

  onLogout = async () => {
    let { userProfile } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    let response = await DataActions.logout().then((response) => {
      if (response.ok) {
        if (userType === "AccountingClient") {
          window.location.href = "https://carra.dk";
        } else {
          this.props.history.push("/");
        }
      }
    });

    return response.ok;
  };

  onFullScreenToggle = () => {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
    this.setState({}); //Trigger a rerender to update the full screen button
  };

  renderFullScreenButtonContent = () => {
    if (screenfull.isFullscreen) {
      return (
        <Icon glyph="icon-fontello-resize-small-3" className="Topmenuimg" />
      );
    }
    return <span className="icon-fontello-resize-full-4 Topmenuimg" />;
  };

  onLock = () => {
    var settings = Object.assign({}, this.props.userSettings);
    settings.locked = true;
    DataActions.updateUserSettings(settings).then((response) => {
      if (response.ok) {
        this.props.history.push("/Lock");
      }
    });
  };

  renderNavBar = () => {
    let { showDropdowns } = this.props;
    let { userProfile, myContactInfo } = this.props;
    let newMessageCount = this.props.messageMap.received.filter(
      (m) => !m.read
    ).length;
    let newFriendRequestCount = this.props.unhandledFriendRequests.length;
    let path = this.props.location.pathname;

    let equalsWithoutCase = StringService.equalsWithoutCase;
    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div>
        <div>
          <Grid fluid className="axo-nav-lg">
            <MediumOrLarger>
              {!showDropdowns ? (
                <Row className="AxolexNavigationBars">
                  <Col xs={12} className="nopadding">
                    <div
                      className="btn-group-justified"
                      role="group"
                      style={{
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        paddingBottom: "10px",
                      }}
                    >
                      {userType !== "AccountingClient" &&
                      userType !== "SocietyMember" ? (
                        <React.Fragment>
                          <div
                            className="btn-group"
                            role="group"
                            style={{ marginBottom: "10px" }}
                          >
                            <Button
                              active={path.toLowerCase().includes("mailbox")}
                              className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                              onClick={this.onClickRoute.bind(this, "")}
                            >
                              <div>
                                <span className="icon-fontello-mail-3 Mailiconcolor AxoNavigationheightIcon" />
                                &nbsp;&nbsp;{" "}
                                {newMessageCount > 0 ? newMessageCount : ""}
                              </div>
                              <MediumOrLarger>
                                <div className="AxoNavigationfonttaxt">
                                  <AxoLocal
                                    entity="mailBoxNav"
                                    defaultValue={"E-boks"}
                                  />
                                </div>{" "}
                              </MediumOrLarger>
                            </Button>
                          </div>
                          {userType !== "Client" && (
                            <>
                              <div className="btn-group" role="group">
                                <Button
                                  active={equalsWithoutCase(
                                    path,
                                    RoutingService.getPath("booking")
                                  )}
                                  className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                                  onClick={this.onClickRoute.bind(
                                    this,
                                    "Booking"
                                  )}
                                >
                                  <div>
                                    <span className="icon-fontello-stopwatch AxoNavigationheightIcon" />
                                  </div>
                                  <MediumOrLarger>
                                    <div className="AxoNavigationfonttaxt">
                                      <AxoLocal
                                        entity="ClientDashboardBookingLabel"
                                        defaultValue={"Booking"}
                                      />
                                    </div>
                                  </MediumOrLarger>
                                </Button>
                              </div>
                              <div className="btn-group" role="group">
                                <Button
                                  active={equalsWithoutCase(
                                    path,
                                    RoutingService.getPath("documents")
                                  )}
                                  className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                                  onClick={this.onClickRoute.bind(
                                    this,
                                    "Documents"
                                  )}
                                >
                                  <div>
                                    <span className="icon-fontello-doc-7 AxoNavigationheightIcon" />
                                  </div>
                                  <MediumOrLarger>
                                    <div className="AxoNavigationfonttaxt ">
                                      <AxoLocal
                                        entity="documentMenuNav"
                                        defaultValue={"Dokumenter"}
                                      />
                                    </div>
                                  </MediumOrLarger>
                                </Button>
                              </div>
                              <div className="btn-group" role="group">
                                <Button
                                  active={equalsWithoutCase(
                                    path,
                                    RoutingService.getPath("invoices")
                                  )}
                                  className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                                  onClick={this.onClickRoute.bind(
                                    this,
                                    "Invoices"
                                  )}
                                >
                                  <div>
                                    {" "}
                                    <span className="icon-fontello-database-2 AxoNavigationheightIcon" />
                                  </div>
                                  <MediumOrLarger>
                                    <div className="AxoNavigationfonttaxt">
                                      <AxoLocal
                                        entity="documentMenuNavF"
                                        defaultValue={"Fakturaer"}
                                      />
                                    </div>
                                  </MediumOrLarger>
                                </Button>
                              </div>
                              <div className="btn-group" role="group">
                                <Button
                                  active={equalsWithoutCase(
                                    path,
                                    RoutingService.getPath("recording")
                                  )}
                                  className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                                  onClick={this.onClickRoute.bind(
                                    this,
                                    "Recording"
                                  )}
                                >
                                  <div>
                                    <span className="icon-fontello-video-alt-1 AxoNavigationheightIcon" />
                                  </div>
                                  <MediumOrLarger>
                                    <div className="AxoNavigationfonttaxt">
                                      <AxoLocal
                                        entity="documentMenuNavRecord"
                                        defaultValue={"Optag"}
                                      />
                                    </div>
                                  </MediumOrLarger>
                                </Button>
                              </div>
                            </>
                          )}
                        </React.Fragment>
                      ) : null}
                      {userType === "AccountingClient" ||
                      userType === "Admin" ? (
                        <React.Fragment>
                          <div className="btn-group" role="group">
                            <Button
                              active={equalsWithoutCase(
                                path,
                                RoutingService.getPath("upload")
                              )}
                              className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                              onClick={this.onClickRoute.bind(this, "Upload")}
                            >
                              <div>
                                <span className="icon-fontello-doc-text-inv AxoNavigationheightIcon" />
                              </div>
                              <MediumOrLarger>
                                <div className="AxoNavigationfonttaxt">
                                  <AxoLocal
                                    entity="DocumentTabViewUpload"
                                    defaultValue={"Upload"}
                                  />
                                </div>
                              </MediumOrLarger>
                            </Button>
                          </div>
                          <div className="btn-group" role="group">
                            <Button
                              active={equalsWithoutCase(
                                path,
                                RoutingService.getPath("accounting")
                              )}
                              className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                              onClick={this.onClickRoute.bind(
                                this,
                                "Accounting"
                              )}
                            >
                              <div>
                                <span className="icon-fontello-doc-text-inv AxoNavigationheightIcon" />
                              </div>
                              <MediumOrLarger>
                                <div className="AxoNavigationfonttaxt">
                                  <AxoLocal
                                    entity="CaseDataTableFixedlabelAddedToCasedocuments"
                                    defaultValue={"Bilag"}
                                  />
                                </div>
                              </MediumOrLarger>
                            </Button>
                          </div>
                          {myContactInfo.enableInvoiceDashboard && (
                            <>
                              <div className="btn-group" role="group">
                                <Button
                                  active={equalsWithoutCase(
                                    path,
                                    RoutingService.getPath("clients")
                                  )}
                                  className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                                  onClick={this.onClickRoute.bind(
                                    this,
                                    "Clients"
                                  )}
                                >
                                  <div>
                                    <span className="icon-fontello-users-outline AxoNavigationheightIcon" />
                                  </div>
                                  <MediumOrLarger>
                                    <div className="AxoNavigationfonttaxt">
                                      <AxoLocal
                                        entity="axoEntityidcode62"
                                        defaultValue={"Kunder"}
                                      />
                                    </div>
                                  </MediumOrLarger>
                                </Button>
                              </div>
                              <div className="btn-group" role="group">
                                <Button
                                  active={path
                                    .toLowerCase()
                                    .includes("invoices")}
                                  className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                                  onClick={this.onClickRoute.bind(
                                    this,
                                    "Invoices"
                                  )}
                                >
                                  <div>
                                    <span className="icon-fontello-cogs AxoNavigationheightIcon" />
                                  </div>
                                  <MediumOrLarger>
                                    <div className="AxoNavigationfonttaxt">
                                      <AxoLocal
                                        entity="InvoicePDFsViewinvoices"
                                        defaultValue={"Fakturaer"}
                                      />
                                    </div>
                                  </MediumOrLarger>
                                </Button>
                              </div>
                              <div className="btn-group" role="group">
                                <Button
                                  active={equalsWithoutCase(
                                    path,
                                    RoutingService.getPath("trash")
                                  )}
                                  className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                                  onClick={this.onClickRoute.bind(
                                    this,
                                    "Trash"
                                  )}
                                >
                                  <div>
                                    <span className="icon-fontello-trash AxoNavigationheightIcon" />
                                  </div>
                                  <MediumOrLarger>
                                    <div className="AxoNavigationfonttaxt">
                                      <AxoLocal
                                        entity="DocumentTabViewBin"
                                        defaultValue={"Skrald"}
                                      />
                                    </div>
                                  </MediumOrLarger>
                                </Button>
                              </div>
                            </>
                          )}
                        </React.Fragment>
                      ) : null}
                      <div className="btn-group" role="group">
                        <Button
                          active={equalsWithoutCase(
                            path,
                            RoutingService.getPath("setup")
                          )}
                          className="Lex-button-2 AxoNavnoborder  AxoNavigationButtonheight"
                          onClick={this.onClickRoute.bind(this, "Setup")}
                        >
                          <div>
                            {" "}
                            <span className="icon-fontello-cog-7 AxoNavigationheightIcon" />
                          </div>
                          <MediumOrLarger>
                            <div className="AxoNavigationfonttaxt">
                              <AxoLocal
                                entity="ClientSetupViewSetup"
                                defaultValue={"Setup"}
                              />
                              <Conditional
                                inline
                                show={newFriendRequestCount > 0}
                              >
                                <span className="axored">
                                  &nbsp;({newFriendRequestCount})
                                </span>
                              </Conditional>
                            </div>
                          </MediumOrLarger>
                        </Button>
                      </div>
                      {userType !== "AccountingClient" &&
                      userType !== "SocietyMember" ? (
                        <div className="btn-group" role="group">
                          <Button
                            active={false}
                            className="Lex-button-2 AxoNavnoborder  AxoNavigationButtonheight"
                            onClick={() => {
                              DataStore.setShowDropDowns(true);
                            }}
                          >
                            <div>
                              <span className="icon-fontello-align-justify AxoNavigationheightIcon" />
                            </div>
                            <MediumOrLarger>
                              <div className=" AxoNavigationfonttaxt">
                                <AxoLocal
                                  entity="AccountingTabViewEntity66"
                                  defaultValue={"Liste Menu"}
                                />
                              </div>
                            </MediumOrLarger>
                          </Button>
                        </div>
                      ) : null}
                      <div
                        className="btn-group hidden-xs hidden-sm"
                        role="group"
                      >
                        <div
                          className="btn-group btn-group-justified"
                          role="group"
                        >
                          <div
                            className="btn-group hidden-xs hidden-sm"
                            role="group"
                          >
                            <FlagMenu
                              className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                              userSettings={this.props.userSettings}
                            />
                          </div>
                          <div
                            className="btn-group  hidden-xs hidden-sm hidden-md"
                            role="group"
                          >
                            <Button
                              className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                              onClick={this.onFullScreenToggle}
                            >
                              {this.renderFullScreenButtonContent()}
                            </Button>
                          </div>
                          <div
                            className="btn-group hidden-xs hidden-sm"
                            role="group"
                          >
                            <Button
                              className="Lex-button-2 AxoNavnoborder  AxoNavigationButtonheight"
                              onClick={this.onLock}
                            >
                              <div>
                                <Icon
                                  style={{ fontSize: "25px" }}
                                  glyph="icon-fontello-lock-2"
                                  className="AxoNavigationheightIcon"
                                />
                              </div>
                            </Button>
                          </div>
                          <div
                            className="btn-group hidden-xs hidden-sm"
                            role="group"
                          >
                            <AsyncButton
                              className="Lex-button-2 AxoNavnoborder AxoNavigationButtonheight"
                              onClick={this.onLogout}
                            >
                              <div>
                                <Icon
                                  style={{ fontSize: "25px" }}
                                  glyph="icon-fontello-logout-3"
                                  className="AxoNavigationheightIcon"
                                />
                              </div>
                            </AsyncButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                this.renderDropDown(true)
              )}
            </MediumOrLarger>
            <SmallOrSmaller>{this.renderDropDown(false)}</SmallOrSmaller>
          </Grid>
        </div>
      </div>
    );
  };

  renderDropDown = (includeSwitch) => {
    let { userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);
    return (
      <div style={{ paddingLeft: "5%" }}>
        <Row style={{ paddingTop: "15px" }}>
          <DropdownButton
            style={{ width: "100%", paddingTop: "10px" }}
            id="IT"
            noCaret
            title={
              <div style={{ display: "inline-block" }}>
                <div className="flexbox-center">
                  <Icon
                    style={{ fontSize: "25px" }}
                    glyph="icon-fontello-align-justify"
                  />
                  &nbsp;&nbsp;
                  <AxoLocal
                    style={{ fontSize: "20px" }}
                    entity="Enhedspris33"
                    defaultValue={"Menu"}
                  />
                </div>
              </div>
            }
          >
            {includeSwitch ? (
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                style={{ marginTop: "10px" }}
                onClick={() => {
                  DataStore.setShowDropDowns(false);
                }}
              >
                <AxoLocal
                  entity="AccountingTabViewEntity67"
                  defaultValue={"Vis menu bar"}
                />{" "}
                &nbsp;
                <span className="icon-fontello-stop" />
                &nbsp;
                <span className="icon-fontello-stop" />
                &nbsp;
                <span className="icon-fontello-stop" />
              </MenuItem>
            ) : (
              <div style={{ marginBottom: "10px" }}></div>
            )}
            {userType !== "AccountingClient" && userType !== "SocietyMember" ? (
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={this.onClickRoute.bind(this, "")}
              >
                <span className="icon-fontello-mail-3 Mailiconcolor" />{" "}
                &nbsp;&nbsp;
                <AxoLocal entity="mailBoxNav" defaultValue={"E-boks"} />
              </MenuItem>
            ) : null}
            {userType !== "Client" &&
            userType !== "AccountingClient" &&
            userType !== "SocietyMember" ? (
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={this.onClickRoute.bind(this, "booking")}
              >
                <span className="icon-fontello-stopwatch" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="ClientDashboardBookingLabel"
                  defaultValue={"Booking"}
                />
              </MenuItem>
            ) : null}
            {userType !== "Client" &&
            userType !== "AccountingClient" &&
            userType !== "SocietyMember" ? (
              <MenuItem
                className="axonavTab axonavTabcolord AxotabDropdownmenu"
                onClick={this.onClickRoute.bind(this, "documents")}
              >
                <span className="icon-fontello-doc-7" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="documentMenuNav"
                  defaultValue={"Dokumenter"}
                />
              </MenuItem>
            ) : null}
            {userType !== "Client" &&
            userType !== "AccountingClient" &&
            userType !== "SocietyMember" ? (
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={this.onClickRoute.bind(this, "invoices")}
              >
                <span className="icon-fontello-database-2" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="documentMenuNavF"
                  defaultValue={"Fakturaer"}
                />
              </MenuItem>
            ) : null}
            {userType !== "Client" &&
            userType !== "AccountingClient" &&
            userType !== "SocietyMember" ? (
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={this.onClickRoute.bind(this, "recording")}
              >
                <span className="icon-fontello-video-alt-1" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="documentMenuNavRecord"
                  defaultValue={"Optag"}
                />
              </MenuItem>
            ) : null}
            {userType === "AccountingClient" || userType === "Admin" ? (
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={this.onClickRoute.bind(this, "Menu")}
              >
                <span className="icon-fontello-doc-text-inv" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="sidebaravatarFrontpage"
                  defaultValue="Forside"
                />
              </MenuItem>
            ) : null}
            {userType === "AccountingClient" || userType === "Admin" ? (
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={this.onClickRoute.bind(this, "Upload")}
              >
                <span className="icon-fontello-doc-text-inv" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="DocumentTabViewUpload"
                  defaultValue={"Upload"}
                />
              </MenuItem>
            ) : null}
            {userType === "AccountingClient" || userType === "Admin" ? (
              <MenuItem
                className="axonavTab AxotabDropdownmenu"
                onClick={this.onClickRoute.bind(this, "Accounting")}
              >
                <span className="icon-fontello-doc-text-inv" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseDataTableFixedlabelAddedToCasedocuments"
                  defaultValue={"Bilag"}
                />
              </MenuItem>
            ) : null}
            <MenuItem
              className="axonavTab AxotabDropdownmenu"
              onClick={this.onClickRoute.bind(this, "Setup")}
            >
              <span className="icon-fontello-cog-7" />
              &nbsp;&nbsp;
              <AxoLocal entity="ClientSetupViewSetup" defaultValue={"Setup"} />
            </MenuItem>
            <MenuItem
              className="axonavTab AxotabDropdownmenu"
              onClick={this.onLogout}
            >
              <span
                className="icon-fontello-logout-3"
                style={{ fontSize: "25px" }}
              />
            </MenuItem>
          </DropdownButton>
          &nbsp;&nbsp;&nbsp;
          <FlagMenu
            className="Lex-button-2 AxoNavigationButtonfooter1 hidden-xs hidden-sm"
            userSettings={this.props.userSettings}
          />
        </Row>
      </div>
    );
  };

  renderFooter = () => {
    let { userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div>
        <Grid
          fluid
          className="axolextextbanne"
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <Row className="text-center axo-footer">
            <div className="flexbox-justified">
              <div className="Lex-button-2">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="btn-group">
                    <Button className="Lex-button-2 AxoNavigationButtonfooter AxoNavigationfonttaxt">
                      <div>
                        © &nbsp;
                        <AxoLocal
                          entity="Axolex ApS"
                          defaultValue={"Lexå ApS"}
                        />
                      </div>
                    </Button>
                  </div>
                  <div
                    className="btn-group Lex-button-2 hidden-md hidden-lg"
                    role="group"
                  >
                    <FlagMenu
                      className="Lex-button-2 AxoNavigationButtonfooter"
                      dropup
                      userSettings={this.props.userSettings}
                    />
                  </div>
                  <div className="btn-group hidden-md hidden-lg" role="group">
                    <Button
                      className="Lex-button-2 AxoNavigationButtonfooter"
                      onClick={this.onLock}
                    >
                      <div>
                        <span
                          className="icon-fontello-lock-2 AxoNavigationheightIcon"
                          style={{ fontSize: "20px" }}
                        />
                      </div>
                    </Button>
                  </div>
                  {userType !== "AccountingClient" &&
                  userType !== "SocietyMember" ? (
                    <div className="btn-group" role="group">
                      <Button
                        className="Lex-button-2 AxoNavigationButtonfooter"
                        onClick={this.onClickRoute.bind(this, "trash")}
                      >
                        <div>
                          <span
                            className="icon-fontello-trash-1 AxoNavigationheightIcon"
                            style={{ fontSize: "20px" }}
                          />
                        </div>
                      </Button>
                    </div>
                  ) : null}
                  <div className="btn-group hidden-md hidden-lg" role="group">
                    <Button
                      className="Lex-button-2 AxoNavigationButtonfooter"
                      onClick={this.onLogout}
                    >
                      <div>
                        <span
                          className="icon-fontello-logout-3 AxoNavigationheightIcon"
                          style={{ fontSize: "20px" }}
                        />
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Grid>
      </div>
    );
  };

  renderBody = () => {
    return (
      <Grid fluid style={{ marginBottom: "45px" }}>
        <Row>
          <SmallOrLarger>
            <Col mdOffset={1} md={10}>
              {this.props.children}
            </Col>
          </SmallOrLarger>
          <ExtraSmall>
            <Col xs={12} className="nopaddingRight nopaddingLeft">
              {this.props.children}
            </Col>
          </ExtraSmall>
        </Row>
      </Grid>
    );
  };

  render() {
    return (
      <div>
        {this.renderNavBar()}
        {this.renderBody()}
        {this.renderFooter()}
        <AlertModal ref={(c) => ModalService.registerAlertModal(c)} />
        <ConfirmModal ref={(c) => ModalService.registerConfirmModal(c)} />
      </div>
    );
  }
}

export default withRouter(ClientDashboardView);
