// @ts-check
import React from "react";
import UserProfileForm from "./UserProfileForm";
import SubscriptionView from "./SubscriptionView";
// import AccountView from './AccountView'

import { Col, Panel, PanelGroup } from "react-bootstrap";

import { Icon, AxoLocal } from "../../utilities/LexUtilities";

function renderAccordionHeader(title) {
  return (
    <div>
      <Col xs={11} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

export default class FrontPageSidebar extends React.PureComponent {
  render() {
    return (
      <div>
        <PanelGroup
          id="Frontpagea"
          accordion
          className="Lex-Accordion"
          defaultActiveKey="0"
          style={{
            borderRadius: "0px",
            paddingTop: "0px",
            borderBottom: "1px solid white",
          }}
        >
          <Panel className="backgroundlineargradient3" eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                {renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-fontello-cog-7" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="SubscriptionViewuserProfileSubscription"
                      defaultValue={"Abonnement"}
                    />
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <SubscriptionView
                userSubscription={this.props.userSubscription}
                paymentRequests={this.props.paymentRequests}
                storageInformation={this.props.storageInformation}
                userProfile={this.props.userProfile}
                userGroup={this.props.userGroup}
                userSettings={this.props.userSettings}
                subscriptionPlans={this.props.subscriptionPlans}
                groupMembers={this.props.groupMembers}
                getGroupMembers={this.props.getGroupMembers}
                clearGroupMembers={this.props.clearGroupMembers}
                removeGroupMember={this.props.removeGroupMember}
              />
            </Panel.Body>
          </Panel>
        </PanelGroup>

        {/* <PanelGroup id='Frontpageb' accordion className='Lex-Accordion' defaultActiveKey='0' style={{ borderRadius: '0px',paddingTop:'0px',borderBottom:'1px solid white'}} >
                    <Panel className='backgroundlineargradient3' eventKey="1">         
                      <Panel.Heading>
                        <Panel.Title toggle>
                        {renderAccordionHeader(<span> 
                          <Icon glyph="icon-fontello-cog-7"/>&nbsp;&nbsp;
                          <AxoLocal entity='AccountView0'defaultValue={'Betalingskonto'}/> 
                          </span>)} 
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible className='LexAccordionPanelody' > 
                        <AccountView userProfile={this.props.userProfile} />
                 
                        </Panel.Body>
                    </Panel>
                </PanelGroup> */}

        <PanelGroup
          id="Frontpagec"
          accordion
          className="Lex-Accordion"
          defaultActiveKey="0"
          style={{
            borderRadius: "0px",
            paddingTop: "0px",
            borderBottom: "1px solid white",
          }}
        >
          <Panel className="backgroundlineargradient3" eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                {renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-fontello-cog-7" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="SidebarRightContainerUserProfileEditing"
                      defaultValue={"Brugerprofil - redigering"}
                    />
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <UserProfileForm
                userProfile={this.props.userProfile}
                userSettings={this.props.userSettings}
                documentMap={this.props.documentMap}
                libraryFileNames={this.props.libraryFileNames}
                globalSettings={this.props.globalSettings}
                theme={this.props.theme}
              />
            </Panel.Body>
          </Panel>
        </PanelGroup>
      </div>
    );
  }
}
