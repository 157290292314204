import React from "react";

const DanishTerms = (props) => {
  return (
    <div>
      <div>
        <div>Handelsbetingelser for køb af abonnement</div>
      </div>
      <div>
        <div>
          <b>Parter</b>
        </div>
        <div>
          Handelsaftalen indgås mellem Axolex Aps (Sælger, CVR: 39076969) og
          køber af abonnementet.
        </div>
      </div>
      <div>
        <div>
          <b>Abonnement</b>
        </div>
        <div>
          Ved køb af et software abonnement hos Axolex ApS, skal der oprettes en
          brugerprofil før betalingen kan gennemføres. Betaling for den første
          måned trækkes umiddelbart efter kunden har afgivet sine
          betalingsoplysninger. Derefter trækkes betaling hver efterfølgende
          måned.
        </div>
        <div>
          Hvis der anvendes digital signering, vil der blive opkrævet betaling for
          antal forbrugte signaturer ved udgangen af perioden. Betalingen
          tillægges den faste abonnementsbetaling.
        </div>
        <div>
          Hvis der købes et nyt abonnement inden udløbet af det nuværende
          abonnement, vil resterende tid ikke blive refunderet.
        </div>
        <div>
          Kunden får adgang til Axolex systemet via sin brugerprofil, så længe
          abonnementet består. Se Brugerbetingelser for detaljer.
        </div>
        <div>
          Kunden kan til enhver tid afbryde abonnement via sit kontrolpanel,
          eller ved at skrive til Axolex ApS på info@axolex.com.
          Opsigelse skal fra Licenstagers side ske med tre måneders varsel til udgangen af en måned.
        </div>
      </div>
      <div>
        <div>
          <b>Betaling</b>
        </div>
        <div>
          Alle priser er i DKK inklusiv moms. Betaling foregår via Axolex'
          online betalingssystem.
        </div>
        <div>
          Ved første betaling skal der oprettes en brugerkonto hos Axolex. I
          forbindelse med oprettelsen, skal kunden oplyse navn, adresse og email
          adresse, samt vælge brugernavn og kodeord.
        </div>
        <div>
          Ved betalingen skal kunden oplyse kreditkortinformation. Betalingen
          kan ske via Visa eller MasterCard. Gebyr for anvendelse af kreditkort
          afholdes af Axolex ApS.
        </div>
        <div>
          Hvis kunden ønsker at anvende andre betalingsmetoder, så kontakt
          Axolex på info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Fortrydelsesret</b>
        </div>
        <div>
          Abonnementsbetalinger kan som udgangspunkt ikke refunderes.
          Abonnementet kan afbrydes på et hvilket som helst tidspunkt fra
          kundens kontrolpanel.
          Afbrydelse skal ske med tre måneders varsel til udgangen af en måned.
        </div>
      </div>
      <div>
        <div>
          <b>Ansvar</b>
        </div>
        <div>
          Axolex ApS er ansvarlig for systemets driftssikkerhed. Axolex er ikke
          ansvarlig for fejl der skyldes fejlagtig brug af systemet.
        </div>
      </div>
      <div>
        <div>
          <b>Klagemuligheder</b>
        </div>
        <div>
          Klager over produkter eller tjenesteydelser købt hos Axolex ApS kan
          indgives til Konkurrence- og Forbrugerstyrelsens Center for
          Klageløsning, via forbrug.dk.
        </div>
      </div>
      <div>
        <div>
          <b>Spørgsmål</b>
        </div>
        <div>
          Ethvert spørgsmål vedrørende køb af produkter eller tjenesteydelser
          kan indsendes skriftligt til info@axolex.com.
        </div>
      </div>
    </div>
  );
};

const EnglishTerms = (props) => {
  return (
    <div>
      <div>
        <div>Subscription purchase terms</div>
      </div>
      <div>
        <div>
          <b>Parties</b>
        </div>
        <div>
          This purchase agreement is made between Axolex ApS (Seller, VAT:
          DK39076969) and the customer.
        </div>
      </div>
      <div>
        <div>
          <b>Subscription</b>
        </div>
        <div>
          When purchasing an Axolex subscription, the user must create an Axolex
          user account before payment can be made. Payment for the first
          subscription will be charged after the user has entered their payment
          information, and approved the payment. Afterwards, payment will be
          charged automatically at the end of every subscription period.
        </div>
        <div>
          If NemID signatures are used, payment for signature usage will be
          charged at the end of the subscription period. The charge will be
          added to the fixed signature payment.
        </div>
        <div>
          If a new subscription is purchased before the expiry of the current
          subscription, remaining time on the current subscription will not be
          refunded.
        </div>
        <div>
          The customer can access the Axolex system through their user account,
          as long as the subscription is active. See the License Terms for
          details.
        </div>
        <div>
          The customer can cancel or change their subscription at any time,
          through their control panel or by contacting Axolex at
          info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Payment</b>
        </div>
        <div>
          All prices include VAT. Payment is made through the Axolex online
          payment system.
        </div>
        <div>
          Before making the first payment, the user must create a user account
          with Axolex. During account creation, the user must enter name,
          address and email address, as well as select username and password.
        </div>
        <div>
          During payment, the user must enter their payment information. Payment
          can currently be made by Visa or MasterCard. Credit card fees are
          included in the price.
        </div>
        <div>
          If you want to use a different payment method, please contact Axolex
          at info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Right of cancellation</b>
        </div>
        <div>
          Subscription payments can only be refunded in exceptional cases.
          However, the user can cancel their subscription at any time from their
          control panel.
        </div>
      </div>
      <div>
        <div>
          <b>Responsibility</b>
        </div>
        <div>
          Axolex ApS is responsible for overall system stability. Axolex is not
          responsible for errors that result from incorrect usage of the system.
        </div>
      </div>
      <div>
        <div>
          <b>Complaints</b>
        </div>
        <div>
          Complaints with regards to products or services purchased from Axolex
          ApS can be sent to the Danish Competition and Consumer authority, at
          www.forbrug.dk.
        </div>
      </div>
      <div>
        <div>
          <b>Questions</b>
        </div>
        <div>
          Any questions regarding purchase of products or services from Axolex
          ApS can be sent to info@axolex.com.
        </div>
      </div>
    </div>
  );
};

//MissingEntity
const ChineseTerms = (props) => {
  return (
    <div>
      <div>
        <div>Subscription purchase terms</div>
      </div>
      <div>
        <div>
          <b>Parties</b>
        </div>
        <div>
          This purchase agreement is made between Axolex ApS (Seller, VAT:
          DK39076969) and the customer.
        </div>
      </div>
      <div>
        <div>
          <b>Subscription</b>
        </div>
        <div>
          When purchasing an Axolex subscription, the user must create an Axolex
          user account before payment can be made. Payment for the first
          subscription will be charged after the user has entered their payment
          information, and approved the payment. Afterwards, payment will be
          charged automatically at the end of every subscription period.
        </div>
        <div>
          If a new subscription is purchased before the expiry of the current
          subscription, remaining time on the current subscription will not be
          refunded.
        </div>
        <div>
          The customer can access the Axolex system through their user account,
          as long as the subscription is active. See the License Terms for
          details.
        </div>
        <div>
          The customer can cancel or change their subscription at any time,
          through their control panel or by contacting Axolex at
          info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Payment</b>
        </div>
        <div>
          All prices include VAT. Payment is made through the Axolex online
          payment system.
        </div>
        <div>
          Before making the first payment, the user must create a user account
          with Axolex. During account creation, the user must enter name,
          address and email address, as well as select username and password.
        </div>
        <div>
          During payment, the user must enter their payment information. Payment
          can currently be made by Visa or MasterCard. Credit card fees are
          included in the price.
        </div>
        <div>
          If you want to use a different payment method, please contact Axolex
          at info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Right of cancellation</b>
        </div>
        <div>
          Subscription payments can only be refunded in exceptional cases.
          However, the user can cancel their subscription at any time from their
          control panel.
        </div>
      </div>
      <div>
        <div>
          <b>Responsibility</b>
        </div>
        <div>
          Axolex ApS is responsible for overall system stability. Axolex is not
          responsible for errors that result from incorrect usage of the system.
        </div>
      </div>
      <div>
        <div>
          <b>Complaints</b>
        </div>
        <div>
          Complaints with regards to products or services purchased from Axolex
          ApS can be sent to the Danish Competition and Consumer authority, at
          www.forbrug.dk.
        </div>
      </div>
      <div>
        <div>
          <b>Questions</b>
        </div>
        <div>
          Any questions regarding purchase of products or services from Axolex
          ApS can be sent to info@axolex.com.
        </div>
      </div>
    </div>
  );
};

export { EnglishTerms, DanishTerms, ChineseTerms };
