import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import { Table, Column, Cell } from "fixed-data-table-2";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";
import AccountingGridView from "./AccountingGridView";
import { debounce } from "lodash";

import moment from "moment";

import {
  ButtonGroup,
  FormGroup,
  InputGroup,
  FormControl,
  ButtonToolbar,
} from "react-bootstrap";

import {
  ScannerService,
  ApiService,
  DataActions,
  FileInfoService,
  ModalService,
  DataStore,
} from "../../services/AxoServices";

import {
  LexButton,
  TableBase,
  SortHeaderCell,
  DataListWrapper,
  AxoLocal,
  Dimensions,
  Icon,
  FileViewerModal,
  InlineEdit,
  InlineNumberEdit,
  LoadingIcon,
  ReactOption,
  Flexbox,
  FlexElement,
  AxoDropzone,
  AsyncButton,
  AxoCheckbox,
  getText,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  isIncome: {
    width: 100,
    shown: true,
  },
  description: {
    width: 300,
    shown: true,
  },
  account: {
    width: 100,
    shown: true,
  },
  amount: {
    width: 100,
    shown: true,
  },
  vat: {
    width: 100,
    shown: true,
  },
  balanceAccount: {
    width: 100,
    shown: true,
  },
  creationDate: {
    width: 175,
    shown: true,
  },
  receipt: {
    width: 75,
    shown: true,
  },
  actions: {
    width: 280,
    shown: true,
  },
};

class AccountingEntriesView extends TableBase {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
    showControls: PropTypes.bool,
    scanReceipts: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.name = "AccountingEntriesView";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = this.generateDefaultSortIndexes(props.entries);
    let tableEntries = this.addDataStringsToEntries(props.entries);
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        actions: 200,
      },
      maxColumnWidth: 400,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      deletingId: 0,
      uploadingExpense: false,
      scanningExpense: false,
      uploadingIncome: false,
      scanningIncome: false,
      documentsScanned: 0,
      documentsToBeScanned: 0,
      showStorageWarning: false,
      showFileUploadError: false,
      showFileMaxSizeWarning: false,
      receiptsWhoseDatesCouldNotBeScanned: [], //Ids of receipts whose dates could not be scanned
      multiPageUpload: false,
      cachedPageFiles: [],
      multiPageIsIncome: false,

      addingPageToId: 0,
      addedPageToId: 0,
    };

    this.selectedEntryId = 0;
    this.finishingComposite = false;
    this.addPagesControl = React.createRef();
  }

  addDataStringsToEntries(entries) {
    return entries;
  }

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.addDataStringsToEntries(nextProps.entries);
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  onSearch = (event) => {
    let { tableEntries } = this.state;

    let entries = this.getFilteredEntries(tableEntries, event.target.value);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }
    return entries.filter((d) => {
      return (d.description || "")
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  };

  onUploadIncome = (event) => {
    $("#incomeUpload").trigger("click");
  };

  onUploadExpense = (event) => {
    $("#expenseUpload").trigger("click");
  };

  onUploadReceiptForExistingEntry = (entryId) => {
    this.selectedEntryId = entryId;
    $("#receiptUpload").trigger("click");
  };

  onIncomeSelected = (event) => {
    let files = event.target.files;
    try {
      this.processAllReceipts(files, true);
    } finally {
      event.target.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  onExpenseSelected = (event) => {
    let files = event.target.files;
    try {
      this.processAllReceipts(files, false);
    } finally {
      event.target.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  finishMultiPageUpload = async () => {
    let { cachedPageFiles, multiPageIsIncome } = this.state;

    this.finishingComposite = true;
    await this.processAllReceipts(cachedPageFiles, multiPageIsIncome);
    this.setState({
      cachedPageFiles: [],
    });
    this.finishingComposite = false;
    return true;
  };

  uploadDocuments = async (files) => {
    let { convertToPDF } = this.props;

    return await DataActions.uploadDocuments(files, { convertToPDF });
  };

  onReceiptSelected = async (event) => {
    let entry = this.props.entries.find((e) => e.id === this.selectedEntryId);
    if (!entry) {
      return;
    }

    let files = event.target.files;
    if (files.length === 0) {
      return;
    }

    let file = files[0];
    if (file.size / Math.pow(1024, 2) > 50) {
      this.showWarning("showFileMaxSizeWarning");
      return;
    }

    let uploadResponse = await this.uploadDocuments(files);
    let addedFiles = [];
    if (uploadResponse.ok) {
      addedFiles = await uploadResponse.json();
      if (addedFiles.length === 0) {
        return;
      }
    } else {
      return this.displayResponseWarnings(uploadResponse);
    }

    let addedFile = addedFiles[0];
    entry.receiptId = addedFile.id;
    entry.receipt = addedFile;
    return DataActions.updateBookkeepingDraftEntry(entry);
  };

  async processAllReceipts(files, isIncome) {
    let { scanReceipts } = this.props;
    let { multiPageUpload } = this.state;

    if (files.length === 0) {
      return;
    }
    let file = files[0];
    if (file.size / Math.pow(1024, 2) > 50) {
      this.showWarning("showFileMaxSizeWarning");
      return;
    }

    if (multiPageUpload && !this.finishingComposite) {
      let fileArray = Array.from(files);
      this.setState((prevState) => ({
        multiPageIsIncome: isIncome,
        cachedPageFiles: prevState.cachedPageFiles.concat(fileArray),
      }));
      return;
    }

    if (isIncome) {
      this.setState({ uploadingIncome: true });
    } else {
      this.setState({ uploadingExpense: true });
    }

    let response = null;
    if (this.finishingComposite) {
      response = await DataActions.uploadCompositeImage(files);
    } else {
      response = await this.uploadDocuments(files);
    }

    let addedFiles = [];
    if (response.ok) {
      addedFiles = await response.json();
    } else {
      return this.displayResponseWarnings(response);
    }

    this.setState({
      uploadingIncome: false,
      uploadingExpense: false,
    });

    if (scanReceipts) {
      this.setState({
        scanningIncome: isIncome,
        scanningExpense: !isIncome,
        documentsScanned: 0,
        documentsToBeScanned: addedFiles.length,
      });
    }
    for (let file of addedFiles) {
      //Process files in sequence
      if (scanReceipts) {
        await this.processReceipt(file, isIncome);
        this.setState((oldState) => ({
          documentsScanned: oldState.documentsScanned + 1,
        }));
      } else {
        await this.processReceiptWithoutScan(file, isIncome);
      }
    }

    this.setState({
      uploadingIncome: false,
      uploadingExpense: false,
      scanningIncome: false,
      scanningExpense: false,
    });
  }

  displayResponseWarnings = async (response) => {
    if (response.status === 400) {
      let text = await response.text();
      if (text === "Storage") {
        this.showWarning("showStorageWarning");
        return;
      } else {
        this.showWarning("showFileUploadError");
        return;
      }
    } else {
      this.showWarning("showFileUploadError");
      return;
    }
  };

  processReceipt = (file, isIncome) => {
    let { userProfile } = this.props;
    return ApiService.scanText(file.id)
      .then((scannedText) => {
        return {
          fileId: file.id,
          scannedText,
        };
      })
      .then((scanResult) => {
        let scanProcessingResult = ScannerService.scanReceiptText(
          scanResult.scannedText,
          parseInt(userProfile.companyCode, 10)
        );
        let entry = {
          isIncome,
          description:
            scanProcessingResult.cvr || file.fileName.replace(/\.[^/.]+$/, ""),
          amount: scanProcessingResult.total,
          vat: scanProcessingResult.vat,
          creationDate: !!scanProcessingResult.date
            ? scanProcessingResult.date.format()
            : moment.utc(),
          receipt: file,
          receiptId: scanResult.fileId,
        };
        if (!scanProcessingResult.date) {
          this.setState((prevState) => ({
            receiptsWhoseDatesCouldNotBeScanned: [
              ...prevState.receiptsWhoseDatesCouldNotBeScanned,
              entry.receiptId,
            ],
          }));
        }
        if (!!scanProcessingResult.cvr) {
          return ApiService.getCVRName(scanProcessingResult.cvr)
            .then((companyInfo) => {
              entry.vatNumber = scanProcessingResult.cvr || "";
              entry.description = companyInfo.name;
              return DataActions.createBookkeepingDraftEntry(entry);
            })
            .catch((reason) => {
              console.log(reason);
              entry.description = "";
              return DataActions.createBookkeepingDraftEntry(entry);
            });
        } else {
          return DataActions.createBookkeepingDraftEntry(entry);
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({
          uploadingIncome: false,
          uploadingExpense: false,
          scanningIncome: false,
          scanningExpense: false,
        });
      });
  };

  processReceiptWithoutScan = (file, isIncome) => {
    let entry = {
      description: file.fileName.replace(/\.[^/.]+$/, ""), //Regex removes file extension,
      isIncome,
      receipt: file,
      receiptId: file.id,
    };

    return DataActions.createBookkeepingDraftEntry(entry);
  };

  showWarning = (warningName) => {
    this.setState({ [warningName]: true });
    setTimeout(() => {
      this.setState({ [warningName]: false });
    }, 3000);
  };

  onDeleteEntry = (entry) => {
    this.setState({ deletingId: entry.entryId });
    DataActions.deleteBookkeepingDraftEntryAndReceipt(entry).then(
      (response) => {
        this.setState({ deletingId: 0 });
      }
    );
  };

  onShowReceipt = (entryId) => {
    let entry = this.props.entries.find((p) => p.id === entryId);
    this.fileModal.open([entry.receipt]);
  };

  onChangePropertyValue = (id, propertyName, value) => {
    let { tableEntries } = this.state;
    let entry = tableEntries.find((e) => e.id === id);
    if (!entry) {
      return;
    }

    DataActions.updateBookkeepingDraftEntry({
      ...entry,
      [propertyName]: value,
    });
  };

  onChangePropertyValueDebounced = (id, propertyName, value) => {
    let { tableEntries } = this.state;
    let entry = tableEntries.find((e) => e.id === id);
    if (!entry) {
      return;
    }

    let newValue = {
      ...entry,
      [propertyName]: value,
    };

    DataActions.updateBookkeepingDraftEntryState(newValue);
    this.debouncedUpdate(newValue);
  };

  debouncedUpdate = debounce((model) => {
    DataActions.updateBookkeepingDraftEntry(model);
  }, 1000);

  getAccountName = (account) => {
    switch (account) {
      case "Bank":
        return (
          <span>
            <AxoLocal
              entity="AccountingTabViewEntity24"
              defaultValue={"Bank"}
            />
          </span>
        );
      case "Cash":
        return (
          <span>
            <AxoLocal entity="axoidcode154" defaultValue={"Kontant"} />
          </span>
        );
      case "Private":
        return (
          <span>
            <AxoLocal
              entity="ContactCreateViewPrivateCustomer"
              defaultValue={"Privat"}
            />
          </span>
        );
      default:
        return (
          <span>
            <AxoLocal
              entity="AccountingTabViewEntity24"
              defaultValue={"Bank"}
            />
          </span>
        );
    }
  };

  onApproveEntry = async (entry) => {
    let response = await DataActions.sendBookkeepingDraftEntryToAccountant(
      entry
    );
    return response.ok;
  };

  updateDate = (entry, inputDate) => {
    if (!inputDate) {
      return;
    }

    let newDate = moment.utc(inputDate);
    if (newDate.year() > 9999) {
      return;
    }

    this.onChangePropertyValueDebounced(
      entry.id,
      "creationDate",
      newDate.format()
    );
    this.setState((prevState) => ({
      receiptsWhoseDatesCouldNotBeScanned:
        prevState.receiptsWhoseDatesCouldNotBeScanned.filter(
          (id) => id !== entry.receiptId
        ),
    }));
  };

  updateAmountAndVat = (entryId, amount) => {
    let entry = this.props.entries.find((e) => e.id === entryId);
    if (!entry) {
      return;
    }

    let newEntry = { ...entry };
    newEntry.amount = amount;

    //ToDo: Read standard VAT from finance account
    newEntry.vat = newEntry.amount - newEntry.amount / (1 + 25 / 100);
    DataActions.updateBookkeepingDraftEntry(newEntry);
  };

  renderTable = () => {
    let {
      sortedDataList,
      colSortDirs,
      columnDefs,
      receiptsWhoseDatesCouldNotBeScanned,
      addingPageToId,
      addedPageToId,
    } = this.state;

    const { containerHeight, containerWidth } = this.props;

    let tableHeight = containerHeight;
    let tableWidth = Math.max(750, containerWidth - 25);

    return (
      <Table
        rowHeight={40}
        rowsCount={sortedDataList.getSize()}
        // rowClassNameGetter={this._rowClassNameGetter}
        height={tableHeight}
        width={tableWidth}
        isColumnResizing={false}
        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
        headerHeight={40}
      >
        <Column
          columnKey="isIncome"
          header={
            <SortHeaderCell
              altIcon={<Icon glyph="icon-fontello-exchange" />}
              showAltIcon={columnDefs.isIncome.width < 75}
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.isIncome}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal entity="DocumentGridViewType" defaultValue={"Type"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {entry.isIncome ? (
                  <span>
                    <AxoLocal
                      entity="AccountingTabViewEntity16"
                      defaultValue={"Indtægt"}
                    />
                  </span>
                ) : (
                  <span>
                    <AxoLocal
                      entity="AccountingTabViewEntity161a"
                      defaultValue={"Udgift"}
                    />
                  </span>
                )}
              </Cell>
            );
          }}
          width={columnDefs.isIncome.width}
          isResizable={true}
        />
        <Column
          columnKey="description"
          header={
            <SortHeaderCell
              altIcon={<Icon glyph="icon-fontello-ellipsis" />}
              showAltIcon={columnDefs.description.width < 75}
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.description}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {entry.status === "Approved" ? (
                  <div>{entry.description}</div>
                ) : (
                  <InlineEdit
                    value={entry.description || "---"}
                    change={(input) =>
                      this.onChangePropertyValue(
                        entry.id,
                        "description",
                        input.value
                      )
                    }
                  />
                )}
              </Cell>
            );
          }}
          width={columnDefs.description.width}
          isResizable={true}
        />
        <Column
          columnKey="amount"
          header={
            <SortHeaderCell
              altIcon={<Icon glyph="icon-fontello-sort-number-up" />}
              showAltIcon={columnDefs.amount.width < 75}
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.amount}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {entry.status === "Approved" ? (
                  <div>{entry.amount.toFixed(2)}</div>
                ) : (
                  <InlineNumberEdit
                    decimal
                    value={entry.amount || 0}
                    change={(input) =>
                      this.updateAmountAndVat(entry.id, parseFloat(input.value))
                    }
                  />
                )}
              </Cell>
            );
          }}
          width={columnDefs.amount.width}
          isResizable={true}
        />
        <Column
          columnKey="vat"
          header={
            <SortHeaderCell
              altIcon={<Icon glyph="icon-fontello-chart-pie" />}
              showAltIcon={columnDefs.vat.width < 75}
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.vat}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal
                entity="invoiPaymentattheSubtotalVAT"
                defaultValue={"Moms"}
              />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {entry.status === "Approved" ? (
                  <div>{entry.vat.toFixed(2)}</div>
                ) : (
                  <InlineNumberEdit
                    decimal
                    value={entry.vat || 0}
                    change={(input) =>
                      this.onChangePropertyValue(
                        entry.id,
                        "vat",
                        parseFloat(input.value)
                      )
                    }
                  />
                )}
              </Cell>
            );
          }}
          width={columnDefs.vat.width}
          isResizable={true}
        />
        <Column
          columnKey="balanceAccount"
          header={
            <SortHeaderCell
              altIcon={<Icon glyph="icon-fontello-air" />}
              showAltIcon={columnDefs.balanceAccount.width < 75}
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.balanceAccount}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal entity="axoAccounting43" defaultValue={"Betaling"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {entry.isIncome || entry.status === "Approved" ? (
                  <span>{this.getAccountName(entry.balanceAccount)}</span>
                ) : (
                  <select
                    value={entry.balanceAccount}
                    onChange={(event) =>
                      this.onChangePropertyValue(
                        entry.id,
                        "balanceAccount",
                        event.target.value
                      )
                    }
                  >
                    <AxoLocal
                      componentClass={ReactOption}
                      value="Bank"
                      componentAttribute="text"
                      entity="AccountingTabViewEntity24"
                      defaultValue={"Bank"}
                    />
                    <AxoLocal
                      componentClass={ReactOption}
                      value="Cash"
                      componentAttribute="text"
                      entity="axoidcode154"
                      defaultValue={"Kontant"}
                    />
                    <AxoLocal
                      componentClass={ReactOption}
                      value="Private"
                      componentAttribute="text"
                      entity="ContactCreateViewPrivateCustomer"
                      defaultValue={"Private"}
                    />
                  </select>
                )}
              </Cell>
            );
          }}
          width={columnDefs.balanceAccount.width}
          isResizable={true}
        />
        <Column
          columnKey="receipt"
          header={
            <SortHeaderCell
              altIcon={<Icon glyph="icon-fontello-air" />}
              showAltIcon={columnDefs.receipt.width < 75}
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.receipt}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal
                entity="CaseDataTableFixedlabelAddedToCasedocuments"
                defaultValue={"Bilag"}
              />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {!!entry.receipt ? (
                  <div>
                    <Icon
                      className="editable"
                      role="button"
                      onClick={() => this.onShowReceipt(entry.id)}
                      glyph="icon-fontello-attach-7"
                    />
                    &nbsp;
                    {FileInfoService.isImage(entry.receipt) ? (
                      <LexButton
                        disabled={!!addingPageToId}
                        title={getText("axoidcode209", "Tilføj side")}
                        onClick={() => this.onAddImagePage(entry.id)}
                      >
                        {(() => {
                          if (addingPageToId === entry.id) {
                            return (
                              <LoadingIcon show={addingPageToId === entry.id} />
                            );
                          } else if (addedPageToId === entry.id) {
                            return <Icon glyph="icon-fontello-check" />;
                          }
                          return <Icon glyph="icon-fontello-plus-2" />;
                        })()}
                      </LexButton>
                    ) : null}
                  </div>
                ) : entry.status !== "Approved" ? (
                  <LexButton
                    onClick={() =>
                      this.onUploadReceiptForExistingEntry(entry.id)
                    }
                  >
                    <AxoLocal
                      entity="DocumentTabViewUpload"
                      defaultValue={"Upload"}
                    />
                  </LexButton>
                ) : null}
              </Cell>
            );
          }}
          width={columnDefs.receipt.width}
          isResizable={true}
        />
        <Column
          columnKey="creationDate"
          header={
            <SortHeaderCell
              altIcon={<Icon glyph="icon-fontello-calendar-outlilne" />}
              showAltIcon={columnDefs.creationDate.width < 75}
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.creationDate}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal
                entity="TimeEntryFormntimeEntry"
                defaultValue={"Dato"}
              />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {entry.status === "Approved" ? (
                  <div>{moment.utc(entry.creationDate).format("L")}</div>
                ) : (
                  <input
                    type="date"
                    style={{
                      border: receiptsWhoseDatesCouldNotBeScanned.includes(
                        entry.receiptId
                      )
                        ? "1px solid red"
                        : "none",
                    }}
                    onChange={(event) =>
                      this.updateDate(entry, event.target.valueAsDate)
                    }
                    value={moment.utc(entry.creationDate).format("YYYY-MM-DD")}
                  />
                )}
              </Cell>
            );
          }}
          width={columnDefs.creationDate.width}
          isResizable={true}
        />
        <Column
          columnKey="actions"
          header={<Cell></Cell>}
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            let deleting = this.state.deletingId === entry.id;
            return (
              <Cell key={entry.id} {...props}>
                {entry.status !== "Approved" ? (
                  <ButtonToolbar>
                    <AsyncButton
                      hideOkIcon
                      onClick={() => this.onApproveEntry(entry)}
                    >
                      <Icon glyph="icon-fontello-check" /> &nbsp;&nbsp;
                      <AxoLocal
                        key="send"
                        entity="friendRequestTimelineonApproveRequest"
                        defaultValue={"Godkend"}
                      />
                    </AsyncButton>
                    <LexButton
                      disabled={deleting}
                      onClick={this.onDeleteEntry.bind(this, entry)}
                    >
                      <Icon glyph="icon-fontello-trash-1" />
                      &nbsp;&nbsp;
                      <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                      <LoadingIcon show={deleting} />
                    </LexButton>
                  </ButtonToolbar>
                ) : (
                  <ButtonToolbar>
                    <LexButton disabled>
                      <Icon glyph="icon-fontello-check" /> &nbsp;&nbsp;
                      <AxoLocal
                        key="approved"
                        entity="inbosViewsent"
                        defaultValue={"Sendt"}
                      />
                    </LexButton>
                    <LexButton
                      disabled={deleting}
                      onClick={this.onDeleteEntry.bind(this, entry)}
                    >
                      <Icon glyph="icon-fontello-trash-1" />
                      &nbsp;&nbsp;
                      <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                      <LoadingIcon show={deleting} />
                    </LexButton>
                    <LexButton
                      onClick={() =>
                        DataActions.updateBookkeepingDraftEntry({
                          ...entry,
                          status: 0,
                        })
                      }
                    >
                      <Icon glyph="icon-fontello-upload-4" />
                      &nbsp;&nbsp; Lås op
                      <LoadingIcon show={deleting} />
                    </LexButton>
                  </ButtonToolbar>
                )}
              </Cell>
            );
          }}
          width={columnDefs.actions.width}
          isResizable={true}
        />
      </Table>
    );
  };

  getButtonLabel = (defaultLabel, uploading, scanning) => {
    if (uploading) {
      return (
        <span>
          <AxoLocal
            key="upload"
            entity="updategetAccountName3"
            defaultValue={"Uploader"}
          />{" "}
        </span>
      );
    }
    if (scanning) {
      let { documentsScanned, documentsToBeScanned } = this.state;

      return (
        <span>
          <AxoLocal
            key="scan"
            entity="updategetAccountName4"
            defaultValue={"Scanner"}
          />
          &nbsp;({documentsScanned + 1} / {documentsToBeScanned})
        </span>
      );
    }
    return <span>{defaultLabel}</span>;
  };

  getFileErrorMessage = () => {
    let { showFileMaxSizeWarning, showStorageWarning, showFileUploadError } =
      this.state;
    if (showFileMaxSizeWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarning"
            defaultValue={"Max 50 mb"}
          />
        </div>
      );
    }
    if (showStorageWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarningA"
            defaultValue={"Ikke mere plads. Slet filer."}
          />
        </div>
      );
    }
    if (showFileUploadError) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesError"
            defaultValue={"Upload fejlede"}
          />
        </div>
      );
    }
    return <span></span>;
  };

  renderGridView = () => {
    let {
      sortedDataList,
      receiptsWhoseDatesCouldNotBeScanned,
      addingPageToId,
      addedPageToId,
    } = this.state;

    return (
      <div>
        <AccountingGridView
          entries={sortedDataList._data}
          getAccountName={this.getAccountName}
          onDeleteEntry={this.onDeleteEntry}
          onShowReceipt={this.onShowReceipt}
          onUploadReceiptForExistingEntry={this.onUploadReceiptForExistingEntry}
          onChangePropertyValue={this.onChangePropertyValue}
          receiptsWhoseDatesCouldNotBeScanned={
            receiptsWhoseDatesCouldNotBeScanned
          }
          updateDate={this.updateDate}
          updateAmountAndVat={this.updateAmountAndVat}
          onApproveEntry={this.onApproveEntry}
          onAddImagePage={this.onAddImagePage}
          addingPageToId={addingPageToId}
          addedPageToId={addedPageToId}
        />
      </div>
    );
  };

  onAddIncome = async () => {
    let response = await DataActions.createBookkeepingDraftEntry({
      isIncome: true,
    });
    return response.ok;
  };

  onAddExpense = async () => {
    let response = await DataActions.createBookkeepingDraftEntry({});
    return response.ok;
  };

  handleFileDrop = (files) => {
    this.processAllReceipts(files, false);
  };

  onAddImagePage = async (entryId) => {
    this.selectedEntryId = entryId;
    this.addPagesControl.current.click();
  };

  onPageSelected = async (event) => {
    let control = event.target;
    try {
      let entry = this.props.entries.find((e) => e.id === this.selectedEntryId);
      if (!entry) {
        return;
      }

      let files = control.files;
      if (files.length === 0) {
        return;
      }

      let file = files[0];
      if (file.size / Math.pow(1024, 2) > 50) {
        this.showWarning("showFileMaxSizeWarning");
        return;
      }

      this.setState({ addingPageToId: this.selectedEntryId });

      let uploadResponse = await DataActions.addImagesToExisting(files, {
        fileId: entry.receiptId,
        clientId: null,
      });
      this.setState({ addingPageToId: 0 });
      if (!uploadResponse.ok) {
        return this.displayResponseWarnings(uploadResponse);
      }
      this.setState({ addedPageToId: this.selectedEntryId });
      setTimeout(() => this.setState({ addedPageToId: 0 }), 3000);

      return true;
    } finally {
      this.selectedEntryId = null;
      control.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  onFinalizePeriod = () => {
    let { myContactInfo } = this.props;

    let message = myContactInfo.finalizedPeriod
      ? "Vil du åbne op for perioderegnskabet?"
      : "Vil du afslette perioderegnskabet?";

    ModalService.openConfirmModal(message, async () => {
      let response = await ApiService.updateContact({
        ...myContactInfo,
        finalizedPeriod: !myContactInfo.finalizedPeriod,
      });
      DataStore.fetchMyContactInfo();

      if (!response.ok) {
        ModalService.openAlertModal("Der skete en fejl. Prøv igen senere.");
      }
    });
  };

  render() {
    let { forceShowCards, showControls, myContactInfo } = this.props;

    let {
      uploadingIncome,
      scanningIncome,
      uploadingExpense,
      scanningExpense,
      multiPageUpload,
      cachedPageFiles,
    } = this.state;

    return (
      <AxoDropzone
        onDrop={this.handleFileDrop}
        className="leftPadding rightPadding"
      >
        {showControls ? (
          <Flexbox
            collapseXS
            className="standardMaxWidth"
            style={{ paddingTop: "20px", paddingBottom: "10px" }}
          >
            <FlexElement flexGrow={1} className="rightPadding">
              <ButtonGroup bsSize="large" className="flexbox-justified">
                <LexButton
                  disabled={
                    uploadingIncome ||
                    scanningIncome ||
                    myContactInfo.finalizedPeriod
                  }
                  onClick={this.onUploadIncome}
                >
                  {this.getButtonLabel(
                    <AxoLocal
                      entity="updategetAccountName6"
                      defaultValue={"Upload indtægt"}
                    />,
                    uploadingIncome,
                    scanningIncome
                  )}
                  <LoadingIcon show={uploadingIncome || scanningIncome} />
                </LexButton>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <LexButton
                  disabled={
                    uploadingExpense ||
                    scanningExpense ||
                    myContactInfo.finalizedPeriod
                  }
                  onClick={this.onUploadExpense}
                >
                  {this.getButtonLabel(
                    <AxoLocal
                      entity="updategetAccountName7"
                      defaultValue={"Upload udgift"}
                    />,
                    uploadingExpense,
                    scanningExpense
                  )}
                  <LoadingIcon show={uploadingExpense || scanningExpense} />
                </LexButton>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <LexButton
                  disabled={uploadingExpense || scanningExpense}
                  onClick={this.onFinalizePeriod}
                >
                  {myContactInfo.finalizedPeriod ? (
                    <>Åben periode</>
                  ) : (
                    <>Afslut periode</>
                  )}
                  <LoadingIcon show={uploadingExpense || scanningExpense} />
                </LexButton>
              </ButtonGroup>
            </FlexElement>
            <FlexElement className="topPadding">
              <AxoCheckbox
                onChange={(event) =>
                  this.setState({ multiPageUpload: event.target.checked })
                }
                checked={multiPageUpload}
              />
              <AxoLocal entity="axoidcode137" defaultValue={"Flere sider"} />
            </FlexElement>
          </Flexbox>
        ) : null}
        <div className="text-center">{this.getFileErrorMessage()}</div>
        <div>
          {multiPageUpload ? (
            <div className="leftPadding standardMaxWidth">
              <span style={{ fontSize: "18px" }}>
                {cachedPageFiles.length}
                <AxoLocal
                  entity="axoidcode135"
                  defaultValue={"sider uploadet."}
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <AsyncButton
                disabled={cachedPageFiles.length === 0}
                onClick={this.finishMultiPageUpload}
                hideOkIcon
              >
                <AxoLocal
                  entity="axoidcode136"
                  defaultValue={"Kombiner sider"}
                />
              </AsyncButton>
            </div>
          ) : null}
          <div>
            <div className="axobg" style={{ marginBottom: "5px" }}>
              <Flexbox spaceBetween alignCenter>
                {showControls ? (
                  <React.Fragment>
                    <SmallOrLarger>
                      <FlexElement>
                        <ButtonToolbar>
                          <LexButton onClick={this.onAddIncome}>
                            <Icon glyph="icon-fontello-plus-2" />
                            &nbsp;
                            <AxoLocal
                              entity="axoidcode1"
                              defaultValue={"Tilføj indtægt"}
                            />
                          </LexButton>
                          <LexButton onClick={this.onAddExpense}>
                            <Icon glyph="icon-fontello-plus-2" />
                            &nbsp;
                            <AxoLocal
                              entity="axoidcode2"
                              defaultValue={"Tilføj udgift"}
                            />
                          </LexButton>
                        </ButtonToolbar>
                      </FlexElement>
                    </SmallOrLarger>
                    <ExtraSmall>
                      <FlexElement>
                        <AsyncButton onClick={this.onAddExpense} hideOkIcon>
                          <Icon role="button" glyph="icon-fontello-plus-3" />
                        </AsyncButton>
                      </FlexElement>
                    </ExtraSmall>
                  </React.Fragment>
                ) : null}
                <FlexElement>
                  <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-search" />
                        </InputGroup.Addon>
                        {/* Søg...  */}
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </FlexElement>
                <FlexElement
                  className="text-right hidden-xs"
                  style={{ paddingTop: "10px" }}
                >
                  <LexButton onClick={this.props.onToggleCards}>
                    {this.props.forceShowCards ? (
                      <span>
                        {" "}
                        <Icon glyph="icon-fontello-grid" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showTable"
                          entity="CaseDataTableFixedShowastable"
                          defaultValue={"Vis som tabel"}
                        />{" "}
                      </span>
                    ) : (
                      <span>
                        {" "}
                        <Icon glyph="icon-fontello-th-thumb-empty" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showCards"
                          entity="CaseDataTableFixedShowCards"
                          defaultValue={"Vis som kartotekskort"}
                        />
                      </span>
                    )}
                  </LexButton>
                </FlexElement>
              </Flexbox>
            </div>
            {forceShowCards ? (
              this.renderGridView()
            ) : (
              <div>
                <ExtraSmall>{this.renderGridView()}</ExtraSmall>
                <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
              </div>
            )}
          </div>
        </div>
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
        <input
          name="income"
          onChange={this.onIncomeSelected}
          type="file"
          accept="image/*,.pdf"
          multiple
          id="incomeUpload"
          style={{ display: "none" }}
        />
        <input
          name="expense"
          onChange={this.onExpenseSelected}
          type="file"
          accept="image/*,.pdf"
          multiple
          id="expenseUpload"
          style={{ display: "none" }}
        />
        <input
          name="add"
          ref={this.addPagesControl}
          onChange={this.onPageSelected}
          type="file"
          accept="image/*,.pdf"
          multiple
          id="addPages"
          style={{ display: "none" }}
        />
        <input
          name="receipt"
          onChange={this.onReceiptSelected}
          type="file"
          accept="image/*,.pdf"
          id="receiptUpload"
          style={{ display: "none" }}
        />
      </AxoDropzone>
    );
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 285;
  },
})(AccountingEntriesView);
