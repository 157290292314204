import React from "react";

var colors = [
  "#428bca", //cblue
  "#A8553A", //brown
  "#55C9A6", //green
  "#FADD7F", //yellow
  "#E299B7", //pink
  "#000000 ", //black
  "#0b476c",
  "#306C67",
  "#EE682F",
  "#A0C7F2",
  "#003BFF",
  "#cc00cc",
  "#3366ff",
  "#99ff99",
  "#33cc33",
  "#ff5500",
  "#ff1a1a",
  "#cc6600",
];

const ColorBox = (props) => {
  var className = props.active ? "color-box active" : "color-box";

  return (
    <div
      className={className}
      onClick={props.onSelect.bind(this, props.color)}
      style={{ background: props.color }}
    ></div>
  );
};

const ColorPicker = (props) => {
  var listItems = colors.map((color) => {
    return (
      <ColorBox
        key={color}
        color={color}
        active={color === props.activeColor}
        onSelect={props.onSelect}
      />
    );
  });

  return (
    <div style={{ paddingBottom: "10px" }} className="flexbox-responsive">
      {listItems}
    </div>
  );
};

export default ColorPicker;
