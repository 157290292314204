import React from "react";

import AxolexDataBehandlerAftale from "../../resources/files/AxolexDatabehandlerAftale.pdf";
import AxolexDataProcessingAgreement from "../../resources/files/AxolexDataProcessingAgreement.pdf";

import { LexButton } from "../../utilities/LexUtilities";

const DanishDataConsentTerms = () => {
  return (
    <div>
      <div>
        <div>
          <b>Axolex ApS privatlivspolitik</b>
        </div>
      </div>
      <div>
        <div>
          <b>Parter</b>
        </div>
        <div>
          Denne privatlivspolitik finder anvendelse mellem Axolex Aps
          (Leverandøren, CVR: 39076969) og brugeren af systemet.
        </div>
      </div>
      <div>
        <div>
          <b>Databehandleraftale</b>
        </div>
        <div>
          Brugeren af Axolex systemet har mulighed for at gemme
          personoplysninger vedrørende brugerens klienter, kunder og/eller
          medlemmer.
        </div>
        <div>
          Håndteringen af disse data er underlagt Axolex databehandleraftalen.
        </div>
        <div>
          <LexButton
            href={AxolexDataBehandlerAftale}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download databehandleraftale
          </LexButton>
        </div>
      </div>
      <div>
        <div>
          <b>Indsamling af persondata</b>
        </div>
        <div>
          Ved oprettelse af en brugerkonto hos Axolex, skal der som minimum
          indtastes navn, adresse og email adresse, samt vælges brugernavn og
          password. For EU brugere uden for Danmark kræves der yderligere et
          gyldigt momsregistreringsnummer.
        </div>
        <div>
          Brugeren kan valgfrit indtaste yderligere personlige oplysninger,
          såsom telefonnummer osv.
        </div>
        <div>
          Axolex ApS indsamler kun persondata som brugeren selv frivilligt
          indtaster i systemet.
        </div>
        <div>
          Når brugeren logger ind, gemmes der en autentificerings cookie på
          brugerens system, som identificerer brugeren over for Axolex systemet.
          Tilsvarende cookies gemmes hvis brugeren vælger at hjemmesiden skal
          huske vedkommendes login information.
        </div>
        <div>
          Axolex systemet anvender udelukkende rent tekniske cookies, som er
          nødvendige for at systemet kan fungere.
        </div>
        <div>
          Ved anvendelse af systemet gemmes der information om brugerens
          opsætning af kontrolpanelet, således at denne ikke skal gentages ved
          næste login.
        </div>
      </div>
      <div>
        <div>
          <b>Behandling af persondata</b>
        </div>
        <div>
          De indtastede data anvendes til kommunikation og fakturering mellem
          Axolex ApS og brugeren, samt til kustomisering af kontrolpanelet.
        </div>
        <div>
          Brugeren kan valgfrit vælge at få vist dele af sine personlige data på
          Axolex' sociale netværk. Dette kræver særskilt samtykke, som både kan
          gives og tilbagetrækkes fra brugerens sociale kontrolpanel.
        </div>
        <div>
          Acolex medarbejdere har mulighed for at logge ind på brugerens konto
          når dette er nødvendigt for teknisk vedligeholdelse af systemet.
          Medarbejderne har tavshedspligt, og data vil ikke blive delt med
          tredjeparter.
        </div>
        <div>
          Persondata opbevares krypteret på Microsofts Azure Cloud netværk, som
          følger GDPR reglerne. Data udleveres ikke til tredjemand uden særskilt
          samtykke fra brugeren.
        </div>
        <div>
          <a href="http://www.microsoftvolumelicensing.com/Downloader.aspx?DocumentId=13865">
            Læs Microsofts databehandleraftale her.
          </a>
        </div>
      </div>
      <div>
        <div>
          <b>Sletning og retning af data</b>
        </div>
        <div>
          Brugeren kan til enhver tid rette eller slette sine personlige data
          fra sit kontrolpanel.
        </div>
        <div>
          Brugeren kan trække sit samtykke til databehandling tilbage ved
          skriftlig meddelelse til info@axolex.com. Dette vil medføre sletning
          af brugerens konto hos Axolex.
        </div>
      </div>
      <div>
        <div>
          <b>Dataportabilitet</b>
        </div>
        <div>
          Såfremt brugeren ønsker at få overført sine personlige data til en
          anden databehandler, kan dette arrangeres ved at kontakte Axolex ApS
          på info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Behandling af kreditkortoplysninger</b>
        </div>
        <div>
          Kortoplysninger behandles sikkert af Scanpay og Clearhaus, eller af
          Paylike ApS. Axolex ApS har ikke adgang til brugerens kortoplysninger.
        </div>
        {/* <div>
          Ved indtastning af oplysninger om betalingskort via Axolex systemet overføres disse
          krypteret til Stripe Payments Europe Limited.
        </div>
        <div>
          Brugerens email adresse deles også med Stripe, med det formål at sende besked til brugeren såfremt der er problemer
          med at overføre betalingen.
        </div>
        <div>
          Stripe Payments Europe Limited overfører betalingskortdata til det amerikanske moderselskab, Stripe Inc., i det 
          omfang det er nødvendigt for at behandle oplysningerne.
        </div>
        <div>
          Stripe Inc. er tilknyttet EU Privacy Shield ordningen.
          Læs mere på <a href="https://support.stripe.com/questions/stripe-and-european-data-transfers">
          https://support.stripe.com/questions/stripe-and-european-data-transfers</a>.
        </div>
        <div>
          <a href="https://stripe.com/dpa/legal" target='_blank' rel='noopener noreferrer'>
              Læs Stripes databehandleraftale her.</a>
        </div>
        <div>
          Axolex ApS har ikke adgang til brugerens kortnummer. Selskabet kan via Stripe
          tilgå oplysninger om korttypen, udstedelseslandet samt de sidste fire cifre af kortnummeret.
        </div>
        <div>
          Axolex ApS kan via Stripe overføre penge fra brugerens betalingskort.
        </div> */}
      </div>
      <div>
        <div>
          <b>Juridisk rådgivning</b>
        </div>
        <div>
          Såfremt en kunde modtager juridisk rådgivning fra Axolex, vil kundens
          kontaktinformationer, samt eventuelle relevante juridiske dokumenter,
          blive gemt hos Axolex, med det formål at behandle sagen. I dette
          tilfælde handler Axolex både som dataansvarlig og databehandler.
        </div>
        <div>
          Disse data gemmes I Axolex systemet med samme
          sikkerhedsforanstaltninger som beskrevet i databehandleraftalen. Data
          behandles kun i det omfang det er nødvendigt for at behandle sagen og
          slettes når sagsbehandlingen er afsluttet.
        </div>
        <div>
          Den juridiske hjemmel for behandlingen af disse data er hensynet til
          opfyldelse af en kontrakt, som den registrerede er part i, cf. Art. 6
          b) GDPR og/eller at et retskrav kan fastlægges, gøres gældende eller
          forsvares, jf. Art. 9 f) GDPR. Såfremt ingen af disse artikler finder
          anvendelse, vil der blive indhentet samtykke fra kunden.
        </div>
        <div>
          Hvis kunden ønsker indsigt i hvilke informationer Axolex opbevarer om
          vedgående og/eller adgang til at rette eller slette disse data, så kan
          dette opnås ved at skrive til info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Brud på datasikkerheden</b>
        </div>
        <div>
          Såfremt der sker brud på datasikkerheden, vil de pågældende brugere,
          samt Datatilsynet, blive underrettet om bruddet indenfor 72 timer.
        </div>
      </div>
      <div>
        <div>
          <b>Kontaktoplysninger</b>
        </div>
        <div>
          Spørgsmål vedrørende databehandlingen kan stilles til info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Klagemulighed</b>
        </div>
        <div>
          Brugeren har mulighed for at klage over databehandlingen til
          Datatilsynet på www.datatilsynet.dk.
        </div>
      </div>
      <div>
        <div>
          <b>Samtykke</b>
        </div>
        <div>
          Ved aktivt at indtaste sine data i Axolex systemet, giver brugeren
          samtykke til at vedkommendes data kan behandles som beskrevet i denne
          aftale. Disse data kan slettes på et hvilket som helst tidspunkt fra
          brugerens kontrolpanel.
        </div>
      </div>
    </div>
  );
};

const EnglishDataConsentTerms = () => {
  return (
    <div>
      <div>
        <div>
          <b>Axolex ApS privacy policy</b>
        </div>
      </div>
      <div>
        <div>
          <b>Parties</b>
        </div>
        <div>
          This privacy policy applies between Axolex ApS (VAT number:
          DK39076969) and the user of the system.
        </div>
      </div>
      <div>
        <div>
          <b>Data processing agreement</b>
        </div>
        <div>
          The user can use the Axolex system to store personal data concerning
          the user's clients, customers and/or members.
        </div>
        <div>
          Axolex handles such data in accordance with the Axolex data processing
          agreement.
        </div>
        <div>
          <LexButton
            href={AxolexDataProcessingAgreement}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download data processing agreement
          </LexButton>
        </div>
      </div>
      <div>
        <div>
          <b>Personal data collections</b>
        </div>
        <div>
          When creating a user account with Axolex, the user must enter at least
          name, address and email address, as well as select a username and
          password. For EU customers outside Denmark, a valid EU VAT number is
          also required.
        </div>
        <div>
          The user can voluntarily choose to enter further information, such as
          phone number, etc.
        </div>
        <div>
          Axolex ApS only collects data which is actively entered by the user.
        </div>
        <div>
          When the user logs in to the system, an authentication cookie is
          stored on the users computer. This cookie is used by the Axolex system
          to identify the log ged in user account. Similar cookies are stored,
          if the user to chooses to allow the system to remember their login
          credentials
        </div>
        <div>
          Axolex only uses technical cookies that are necessary for the system
          to function.
        </div>
        <div>
          When using the control panel, the system stores information about the
          users setup, so this will be persisted between logins.
        </div>
      </div>
      <div>
        <div>
          <b>Personal data processing</b>
        </div>
        <div>
          The entered data is used for communication and invoicing between
          Axolex ApS and the user, and for persisting control panel setup.
        </div>
        <div>
          The user can voluntarily choose to display personal data on the Axolex
          social network. This requires separate consent, which can be both
          given and withdrawn from the user dashboard.
        </div>
        <div>
          Axolex employees can log in to the user's account when this is
          necessary for technical system maintenance. Employees are bound by
          confidentiality, and data will not be shared with third parties.
        </div>
        <div>
          Personal data is stored in encrypted form on the Microsoft Azure Cloud
          network, which is fully GDPR compliant. Data will not be shared with
          third parties without consent from the user.
        </div>
        <div>
          <a href="http://www.microsoftvolumelicensing.com/Downloader.aspx?DocumentId=13867">
            Read the Microsoft data processing agreement here.
          </a>
        </div>
      </div>
      <div>
        <div>
          <b>Deletion and correction of personal data</b>
        </div>
        <div>
          The user can at any time edit or delete their personal data from the
          user dashboard.
        </div>
        <div>
          The user's data processing consent can be withdrawn by written request
          to info@axolex.com. Withdrawing data storage consent will cause
          termination of the user account.
        </div>
      </div>
      <div>
        <div>
          <b>Data portability</b>
        </div>
        <div>
          If the users wishes to have their data transferred to another data
          processor, this can be arranged by contacting Axolex ApS at
          info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Credit/Debit card information processing</b>
        </div>
        <div>
          When entering credit/debit card information in the Axolex system, the
          information is transfered securely to Stripe Payments Europe Limited.
        </div>
        <div>
          The user's email address is also shared with Stripe, for the purposes
          of informing the user of any potential problems with their payment
          transfer.
        </div>
        <div>
          In providing Stripe Services, Stripe Payments Europe Limited transfers
          personal data to Stripe, Inc. in the US.
        </div>
        <div>
          Stripe Inc. is certified through the EU Privacy Shield Framework. Read
          more at{" "}
          <a href="https://support.stripe.com/questions/stripe-and-european-data-transfers">
            https://support.stripe.com/questions/stripe-and-european-data-transfers
          </a>
          .
        </div>
        <div>
          <a
            href="https://stripe.com/dpa/legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read the Stripe Data Processing Agreement here.
          </a>
        </div>
        <div>
          Axolex ApS do not have access to the full credit/debit card number of
          the users. Through Stripe, the company can access information about
          the type of the card, the country of issuance and the last four digits
          of the card number.
        </div>
        <div>
          Axolex ApS can through Stripe transfer funds from the user's
          credit/debit card.
        </div>
      </div>
      <div>
        <div>
          <b>Legal advice</b>
        </div>
        <div>
          If a client receives legal advice from Axolex ApS, the client's
          contact information, as well as relevant legal documents, will be
          stored by Axolex, for the purpose of handling the case. In this case
          Axolex acts as both Data Controller and Data Processor.
        </div>
        <div>
          The data will be stored in the Axolex system, using the same security
          procedures as described in the Axolex data processing agreement. The
          data will only be processed to the extent necessary to handle the
          case, and will be deleted when the case has been finalized.
        </div>
        <div>
          The legal basis for the processing of this data is the performance of
          a contract to which the data subject is party, cf. Art. 6 b) GDPR
          and/or the establishment, exercise or defence of legal claims, cf.
          Art. 9 f) GDPR. In case neither of these bases apply, consent will be
          sought from the Data Subject.
        </div>
        <div>
          If the client wishes to know what information is being stored about
          them by Axolex, or wishes to correct or delete this data, this can be
          arranged by writing to info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Data security breaches</b>
        </div>
        <div>
          If data security is breached for any reason, the affected users, as
          well as the Danish data protection authorities (Datatilsynet), will be
          notified within 72 hours.
        </div>
      </div>
      <div>
        <div>
          <b>Contact information</b>
        </div>
        <div>
          Questions regarding data storage can be sent to info@axolex.com.
        </div>
      </div>
      <div>
        <div>
          <b>Complaints</b>
        </div>
        <div>
          Complaints about Axolex data processing can be sent to the local
          supervisory authority. In Denmark, this is Datatilsynet at
          www.datatilsynet.dk.
        </div>
      </div>
      <div>
        <div>
          <b>Consent</b>
        </div>
        <div>
          By actively entering their personal data into the Axolex system, the
          user consents to have data stored and processed in accordance with
          this agreement. These data can be deleted at any time from the user
          dashboard.
        </div>
      </div>
    </div>
  );
};

const ChineseDataConsentTerms = () => {
  return (
    <div>
      <div>
        <div>
          <b>Axolex ApS 存储和处理个人数据的协议</b>
        </div>
      </div>
      <div>
        <div>
          <b>协议方</b>
        </div>
        <div>
          此数据处理协议是在 Axolex ApS（CVR：39076969）和系统用户之间的制定
        </div>
      </div>
      <div>
        <div>
          <b>数据处理协议</b>
        </div>
        <div>
          用户可以使用Axolex系统存储有关用户的个人数据           
          客户，客户和/或会员.
        </div>
        <div>Axolex根据Axolex数据处理协议处理这些数据.</div>
        <div>
          <LexButton
            href={AxolexDataProcessingAgreement}
            target="_blank"
            rel="noopener noreferrer"
          >
            Axolex根据Axolex数据处理协议处理这些数据。
          </LexButton>
        </div>
      </div>
      <div>
        <div>
          <b>个人数据收藏</b>
        </div>
        <div>
          在使用Axolex创建用户帐户时，用户必须至少输入姓名，地址和电子邮件地址
                    以及选择用户名和密码.
        </div>
        <div>用户可以自愿选择输入更多信息，例如电话号码等.</div>
        <div> Axolex ApS仅收集用户主动输入的数据.</div>
        <div>
          当用户登录到系统时，身份验证cookie将存储在用户计算机上。
                    Axolex系统使用此cookie来识别登录的用户帐户.
                    Axolex系统不存储其他cookie.
        </div>
      </div>
      <div>
        <div>
          <b>个人资料处理</b>
        </div>
        <div>输入的数据用于Axolex ApS和用户之间的通信和开发票.</div>
        <div>
          用户可以自愿选择在Axolex社交网络上显示个人数据。
                  这需要单独的同意，这可以从用户仪表板中给出和撤消.
        </div>
        <div>
          个人数据以加密形式存储在完全符合GDPR的Microsoft Azure云网络中。
                    未经用户同意，数据不会与任何第三方共享.
        </div>
      </div>
      <div>
        <div>
          <b>删除和更正个人资料</b>
        </div>
        <div>用户可以随时编辑或删除用户仪表板中的个人数据.</div>
        <div>
          用户数据处理同意书可以通过发送至info@axolex.com的书面申请撤消。
                  提取数据存储同意将导致用户帐户终止.
        </div>
      </div>
      <div>
        <div>
          <b>数据可移植性</b>
        </div>
        <div>
          如果用户希望将他们的数据传输到另一个数据处理器，则可以进行安排
                    通过info@axolex.com联系Axolex ApS.
        </div>
      </div>
      <div>
        <div>
          <b>用户数据上传</b>
        </div>
        <div>
          用户可以将客户信息输入Axolex系统，并上传文件。数据和文件
                    以加密形式存储在Microsoft Azure云网络上.
        </div>
        <div>
          如果用户输入或上传个人数据，用户有责任确保其知情同意
                    受影响的各方，并且向那些当事方提供关于正在存储什么数据以及数据如何的信息
                    正在被使用，以及数据如何被用户纠正和/删除.
        </div>
        <div>
          用户输入的关于他人的个人数据不被Axolex ApS使用，不会与第三方共享.
        </div>
      </div>
      <div>
        <div>
          <b>处理信用卡信息</b>
        </div>
        <div>
          通过Axolex系统输入关于支付卡的信息后，这些信息将被传送           
          加密到总部位于爱尔兰的支付公司Stripe Payments Europe Limited。
        </div>
        <div>
          Stripe Payments Europe将支付卡数据转移给美国母公司Stripe Inc.
                     处理信息所需的程度。
        </div>
        <div>
          Stripe Inc. 隶属于欧盟隐私保护计划. 阅读更多信息
          <a href="https://support.stripe.com/questions/stripe-and-european-data-transfers">
            https://support.stripe.com/questions/stripe-and-european-data-transfers
          </a>
          .
        </div>
        <div>
          Axolex ApS无法访问用户的支付卡信息。 该公司可以通过Stripe           
          添加有关卡类型和卡号的最后四位数字的信息。
        </div>
        <div>Axolex ApS可以通过用户借记卡上的Stripe资金转帐。</div>
      </div>
      <div>
        <div>
          <b>联系信息</b>
        </div>
        <div>有关数据存储的问题可以发送到info@axolex.com。</div>
      </div>
      <div>
        <div>
          <b>投诉</b>
        </div>
        <div>
          关于Axolex数据处理的投诉可以发送给当地的监管机构。
                    在丹麦，这是Datatilsynet，网址为www.datatilsynet.dk.
        </div>
      </div>
      <div>
        <div>
          <b>同意</b>
        </div>
        <div>
          通过选中同意复选框并创建用户帐户，用户同意拥有他们的个人信息
                    根据本协议存储和处理数据.
        </div>
      </div>
    </div>
  );
};

export {
  DanishDataConsentTerms,
  EnglishDataConsentTerms,
  ChineseDataConsentTerms,
};
