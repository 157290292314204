import React from "react";
import PropTypes from "prop-types";
// import { SmallOrSmaller, MediumOrLarger } from '../utilities/Responsive';

import "../resources/css/fileViewer.css";

import { ApiService, FileInfoService } from "../services/AxoServices";

// import {
//   ButtonToolbar,
// } from 'react-bootstrap';

import {
  // LexButton,
  AxoLocal,
} from "../utilities/LexUtilities";

import Zoom from "react-img-zoom";

export default class FileViewer extends React.PureComponent {
  static propTypes = {
    file: PropTypes.object.isRequired,
    messageId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      innerHtml: "",
      imageWidth: null,
      imageHeight: null,
    };

    this.imageRef = React.createRef();
  }

  componentDidMount() {
    this.fetchFileContents();
  }

  fetchFileContents = () => {
    let { file, messageId } = this.props;

    let docXGetter = messageId
      ? ApiService.getDocXAttachmentAsHTML.bind(this, file.id, messageId)
      : ApiService.getDocXAsHTML.bind(this, file.id);

    if (file.fileName.toLowerCase().includes(".docx")) {
      docXGetter().then((response) => {
        this.setState({ innerHtml: response });
      });
    } else if (
      file.fileName.toLowerCase().includes(".txt") ||
      file.fileName.toLowerCase().includes(".htm")
    ) {
      ApiService.getRawText(file.id).then((response) => {
        this.setState({ innerHtml: response });
      });
    }
  };

  getFileType = (file) => {
    var fileName = file.fileName.toLowerCase();
    if (fileName.includes(".pdf")) {
      return "pdf";
    } else if (FileInfoService.isImage(file)) {
      return "image";
    } else if (
      fileName.includes(".docx") ||
      fileName.includes(".txt") ||
      fileName.includes(".htm")
    ) {
      return "word";
    } else if (
      fileName.includes(".mp3") ||
      fileName.includes(".m4a") ||
      fileName.includes(".wav")
    ) {
      return "audio";
    } else if (
      fileName.includes(".mp4") ||
      fileName.includes(".avi") ||
      fileName.includes(".ogg") ||
      fileName.includes(".mov")
    ) {
      return "video";
    } else {
      return "other";
    }
  };

  increasePDFScale = () => {
    if (!this.state.PDFScale) {
      this.setState({ PDFScale: 1 });
      return;
    }
    if (this.state.PDFScale < 1) {
      this.setState({ PDFScale: Math.max(0.1, this.state.PDFScale + 0.1) });
      return;
    }
    this.setState({ PDFScale: this.state.PDFScale + 0.5 });
  };

  decreasePDFScale = () => {
    if (!this.state.PDFScale) {
      this.setState({ PDFScale: 1 });
      return;
    }
    if (this.state.PDFScale < 1) {
      this.setState({ PDFScale: Math.max(0.1, this.state.PDFScale - 0.1) });
      return;
    }
    this.setState({ PDFScale: this.state.PDFScale - 0.5 });
  };

  onImageLoad = () => {
    document.getElementById("loader").style.display = "none";

    if (!this.imageRef.current) {
      return;
    }

    this.setState({
      imageWidth: this.imageRef.current.clientWidth,
      imageHeight: this.imageRef.current.clientHeight,
    });
  };

  getBody = (file, fileType) => {
    let { messageId, maxHeight, getter } = this.props;

    let inlineFileGetter;
    if (!!getter) {
      inlineFileGetter = getter;
    } else {
      inlineFileGetter = messageId
        ? ApiService.getAttachmentInlinePath.bind(this, file.id, messageId)
        : ApiService.getFileInlinePath.bind(this, file.id);
    }

    if (fileType === "pdf") {
      return (
        <div key={file.id} style={{ flexGrow: 1 }} className="flexbox-column">
          <div className="frame-loader">
            <iframe
              title="viewport"
              id="viewport"
              style={{
                flexGrow: 1,
                border: "none",
                minHeight: !!maxHeight ? maxHeight - 50 : "800px",
              }}
              src={inlineFileGetter()}
              width="100%"
              height="100%"
            />
          </div>
          {/* </MediumOrLarger> */}
        </div>
      );
    } else if (fileType === "image") {
      let { imageWidth, imageHeight } = this.state;

      if (!imageHeight || !imageWidth) {
        return (
          <div key={file.id}>
            <img
              id="loader"
              alt=""
              src="/imgs/app/loading.gif"
              style={{ maxWidth: "250px", margin: "auto", display: "block" }}
              className="img-circle"
            />
            <img
              ref={this.imageRef}
              alt=""
              id="viewport"
              src={inlineFileGetter()}
              onLoad={this.onImageLoad}
              style={{ maxWidth: "100%" }}
            />
          </div>
        );
      }

      return (
        <Zoom
          img={inlineFileGetter()}
          zoomScale={3}
          width={imageWidth}
          height={imageHeight}
        />
      );
    } else if (fileType === "word") {
      return (
        <div
          key={file.id}
          style={{ flexGrow: 1, height: "500px", overflowY: "scroll" }}
          id="viewport"
          dangerouslySetInnerHTML={{ __html: this.state.innerHtml }}
        />
      );
    } else if (fileType === "audio") {
      return (
        <div key={file.id}>
          <audio controls>
            <source src={inlineFileGetter()} type="audio/mpeg" />
            <AxoLocal
              entity="Yourbrowserdoesnotsupporttheaudioelement"
              defaultValue={"Din browser støtter ikke lydelementet."}
            />
          </audio>
        </div>
      );
    } else if (fileType === "video") {
      return (
        <div key={file.id} className="text-center">
          <video
            id="viewport"
            ref={(c) => (this.video = c)}
            width="90%%"
            height="auto"
            controls
            style={{ display: "inline-block" }}
          >
            <source src={inlineFileGetter()} type="video/mp4" />
            <AxoLocal
              entity="Yourbrowserdoesnotsupportvideoplayback"
              defaultValue={" Din browser understøtter ikke videoafspilning"}
            />
          </video>
        </div>
      );
    } else if (file.fileName.includes(".doc")) {
      return (
        <div key={file.id}>
          <div>
            <b>
              <AxoLocal
                entity="Docfilescannotbedisplayed"
                defaultValue={".doc filer kan desværre ikke vises."}
              />
            </b>{" "}
          </div>
          <div>
            <AxoLocal
              entity="DocfileNameincludesdoc"
              defaultValue={
                "Upload dokumenter i .docx format hvis du ønsker at læse dem i systemet."
              }
            />{" "}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <AxoLocal
            entity="DocfileThefilecannotbedisplayed"
            defaultValue={
              "Filen kan ikke vises. Download filen hvis du ønsker at se den."
            }
          />
        </div>
      );
    }
  };

  render() {
    let { file } = this.props;
    if (!file.fileName) {
      return null;
    }
    var fileType = this.getFileType(file);

    return (
      <div>
        {this.getBody(file, fileType)}
        <div className="text-left">
          <span>{file.fileName}</span>
          &nbsp;&nbsp;&nbsp;
          <span>{file.sizeString}</span>
        </div>
      </div>
    );
  }
}
