import React from "react";
import DataStore from "../../services/DataAccess/DataStore";
import DataActions from "../../services/DataAccess/DataActions";
import DocumentTableFixedView from "./DocumentTableFixedView";
import DocumentTemplateTableView from "./DocumentTemplateTableView";
import DocumentTemplateSharedTableView from "./DocumentTemplateSharedTableView";
import DocumentUploadView from "./DocumentUploadView";
import DocumentRecordView from "./DocumentRecordView";
import DocumentTemplateCreateView from "./DocumentTemplateCreateView";
import DocumentTemplateEditView from "./DocumentTemplateEditView";
import DocumentSharedTableView from "./DocumentSharedTableView";
import DocumentTrashTableView from "./DocumentTrashTableView";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import { Route, Switch, Redirect } from "react-router-dom";

import { AuthorizationService } from "../../services/AxoServices";

import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";

import {
  Icon,
  AxoLocal,
  LexNavButton,
  withRouter,
} from "../../utilities/LexUtilities";

class DocumentTabView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileUploaded: false,
      openTemplates: [],
      activeTemplateId: 0,
      activeDocumentId: DataStore.getActiveDocumentId(),
      smallScreenTabsExpanded: false,
      forceShowCards: false,
    };
    this.selectedTemplateId = null; //Used to reopen template tabs on reload
  }

  componentDidUpdate() {
    if (!!this.selectedTemplateId && this.props.templates.length > 0) {
      var routeTemplate = this.props.templates.find(
        (c) => c.id === this.selectedTemplateId
      );
      this.selectedTemplateId = 0;
      if (!routeTemplate) {
        this.handleActiveState("List");
        return;
      }
      this.onSelectTemplate(routeTemplate.id);
    }
  }

  //Using Dropzone.js
  onFileUploaded = (file) => {
    let { selectedContact } = this.props;

    DataStore.addDocuments([file]);
    if (selectedContact.id) {
      DataActions.updateDocument({
        ...file,
        clientId: selectedContact.id,
        client: selectedContact,
        clientName: selectedContact.firstName + " " + selectedContact.lastName,
      });
    }
    // DataStore.fetchDocuments();
    DataStore.fetchStorageInformation();
  };

  //Using normal file upload
  onFilesSubmitted = (files, selectedLabelId) => {
    let { selectedContact } = this.props;

    return DataActions.uploadDocuments(files, {
      clientId: selectedContact.id,
      labelId: selectedLabelId,
    });
  };

  onRemoveTemplateTab = (templateId, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.removeOpenTemplate(templateId);
  };

  removeOpenTemplate = (templateId) => {
    var openTemplates = this.state.openTemplates;
    var templateIndex = openTemplates.indexOf(templateId);
    if (templateIndex > -1) {
      //Can occur if the template has been deleted
      openTemplates.splice(templateIndex, 1);
      this.setState({ openTemplates: openTemplates });
    }

    this.handleActiveState("List");
  };

  handleActiveState = (eventKey) => {
    this.props.handleNavigation(eventKey);
  };

  renderTemplateNavNodes = (largeScreen) => {
    let { location } = this.props;

    let path = location.pathname;
    var number = this.state.openTemplates.length;
    var lexNavButtons = this.state.openTemplates.map((templateId) => {
      var currentTemplate = this.props.templates.find(
        (c) => c.id === templateId
      );
      return (
        <LexNavButton
          btnWidth={number === 1 ? "50%" : null}
          key={templateId}
          ls={largeScreen}
          eventKey={this.getTemplateKey(templateId)}
          path={path}
          onClick={this.handleActiveState}
        >
          <div
            style={{
              display: "inline-block",
              width: "50%",
              textAlign: "right",
            }}
          >
            {currentTemplate.title}&nbsp;&nbsp;
          </div>
          <div
            style={{
              display: "inline-block",
              width: "50%",
              textAlign: "right",
            }}
          >
            <Icon
              onClick={this.onRemoveTemplateTab.bind(this, templateId)}
              glyph="icon-fontello-cancel"
              style={{ textAlign: "right" }}
            />
            &nbsp;&nbsp;&nbsp;
          </div>
        </LexNavButton>
      );
    });
    var rows = [];
    var columnNo = largeScreen ? 6 : 3;
    for (var i = 0; i < lexNavButtons.length; i = i + columnNo) {
      rows.push(
        <Row key={i} style={{ marginTop: "3px" }}>
          <Col xs={12} className="nopadding">
            <div
              className="btn-group btn-group-justified"
              role="group"
              style={{ borderRadius: "0px", paddingTop: "0px" }}
            >
              {this.arrangeLexNavButtons(lexNavButtons, i, columnNo)}
            </div>
          </Col>
        </Row>
      );
    }
    return rows;
  };

  arrangeLexNavButtons = (lexNavButtons, currentIndex, columnNo) => {
    var columns = [];
    for (
      var i = 0;
      i < columnNo && currentIndex + i < lexNavButtons.length;
      i++
    ) {
      columns.push(lexNavButtons[currentIndex + i]);
    }
    return columns;
  };

  onTemplateChange = (documentTemplate) => {
    DataActions.updateTemplate(documentTemplate);
  };

  onCreatePDF = (newPDF) => {
    this.handleActiveState("List");
    this.activateDocument(newPDF.id);
  };

  activateDocument = (documentId) => {
    this.setState({
      activeDocumentId: documentId,
    });
    setTimeout(() => {
      this.setState({
        activeDocumentId: 0,
      });
    }, 10000);
  };

  getTemplateKey = (templateId) => {
    return "SingleTemplate/" + templateId;
  };

  onSaveTemplate = (newTemplate) => {
    this.setState({
      activeTemplateId: newTemplate.id,
    });
    this.handleActiveState("Templates");
  };

  onSaveEditTemplate = (newTemplate) => {
    this.setState({
      activeTemplateId: newTemplate.id,
    });
    this.removeOpenTemplate(newTemplate.id);
    if (newTemplate.sharedUsers.length > 0) {
      this.handleActiveState("SharedTemplates");
    } else {
      this.handleActiveState("Templates");
    }
  };

  onSelectTemplate = (templateId) => {
    if (this.state.openTemplates.indexOf(templateId) === -1) {
      this.state.openTemplates.push(templateId);
      this.setState({ openTemplates: this.state.openTemplates });
    }
    this.handleActiveState(this.getTemplateKey(templateId));
  };

  onClickShared = (documentId) => {
    this.activateDocument(documentId);
    this.handleActiveState("SharedDocs");
  };

  onToggleCards = () => {
    var userSettings = Object.assign({}, this.props.userSettings);
    userSettings.showDocumentCards = !userSettings.showDocumentCards;
    DataActions.updateUserSettings(userSettings);
  };

  expandTabs = () => {
    this.setState({ smallScreenTabsExpanded: true });
  };

  renderNavBar = () => {
    let { showDropdowns, userProfile, location } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    var { smallScreenTabsExpanded } = this.state;

    let path = location.pathname;

    return (
      <div>
        <Large>
          <Grid fluid className="axo-nav-lg" id="noprint">
            {!showDropdowns ? (
              <Row>
                <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                  <div
                    className="btn-group btn-group-justified"
                    role="group"
                    style={{
                      borderRadius: "0px",
                      paddingTop: "0px",
                      paddingLeft: "2px",
                    }}
                  >
                    <LexNavButton
                      className="axonavTab axonavTabcolora"
                      ls
                      glyph="icon-fontello-grid"
                      eventKey="List"
                      path={path}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="CalendarTabViewagenda"
                        defaultValue={"Oversigt"}
                      />
                    </LexNavButton>
                    <LexNavButton
                      className="axonavTab axonavTabcolorb"
                      ls
                      glyph="icon-fontello-upload-4"
                      eventKey="Upload"
                      path={path}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="DocumentTabViewUpload"
                        defaultValue={"Upload"}
                      />
                    </LexNavButton>
                    {userType !== "Society" ? (
                      <React.Fragment>
                        <LexNavButton
                          className="axonavTab axonavTabcolorc"
                          ls
                          glyph="icon-fontello-video-5"
                          eventKey="Record"
                          path={path}
                          onClick={this.handleActiveState}
                        >
                          <AxoLocal
                            entity="DocumentTabViewRecord"
                            defaultValue={"Optag"}
                          />
                        </LexNavButton>
                        <LexNavButton
                          className="axonavTab axonavTabcolord"
                          ls
                          glyph="icon-fontello-edit-3"
                          eventKey="Create"
                          path={path}
                          onClick={this.handleActiveState}
                        >
                          <AxoLocal
                            entity="DocumentTabViewCreateTemplate"
                            defaultValue={"Opret Skabelon"}
                          />
                        </LexNavButton>
                        <LexNavButton
                          className="axonavTab axonavTabcolore"
                          ls
                          glyph="icon-fontello-list-alt"
                          eventKey="Templates"
                          path={path}
                          onClick={this.handleActiveState}
                        >
                          <AxoLocal
                            entity="DocumentTabViewTemplate"
                            defaultValue={"Skabeloner"}
                          />
                        </LexNavButton>
                        <LexNavButton
                          className="axonavTab axonavTabcolorf"
                          ls
                          glyph="icon-fontello-share-1"
                          eventKey="SharedTemps"
                          path={path}
                          onClick={this.handleActiveState}
                        >
                          <AxoLocal
                            entity="DocumentTabViewSharedtemplates"
                            defaultValue={"Delte skabeloner"}
                          />
                        </LexNavButton>
                        <LexNavButton
                          className="axonavTab axonavTabcolorg"
                          ls
                          glyph="icon-fontello-shuffle"
                          eventKey="SharedDocs"
                          path={path}
                          onClick={this.handleActiveState}
                        >
                          <AxoLocal
                            entity="DocumentTabViewShareddocuments"
                            defaultValue={"Delte dokumenter"}
                          />
                        </LexNavButton>
                      </React.Fragment>
                    ) : null}
                    <LexNavButton
                      className="axonavTab axonavTabcolorh"
                      ls
                      glyph="icon-fontello-trash-1"
                      eventKey="Trash"
                      path={path}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="DocumentTabViewBin"
                        defaultValue={"Skraldespand"}
                      />
                    </LexNavButton>
                    <LexNavButton
                      ls
                      listButton
                      className="axonavTab showListButton"
                      onClick={() => {
                        DataStore.setShowDropDowns(true);
                      }}
                    >
                      <div className="flexbox-center">
                        <Icon
                          glyph="icon-fontello-align-justify"
                          style={{ fontSize: "30px" }}
                        />
                        &nbsp;
                        <AxoLocal
                          entity="AccountingTabViewEntity66"
                          defaultValue={"Liste Menu"}
                        />
                      </div>
                    </LexNavButton>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
                <DropdownButton
                  style={{ width: "100%" }}
                  id="IT"
                  noCaret
                  title={
                    <div style={{ display: "inline-block" }}>
                      <div className="flexbox-center">
                        <Icon
                          style={{ fontSize: "25px" }}
                          glyph="icon-fontello-align-justify"
                        />
                        &nbsp;&nbsp;
                        <AxoLocal
                          style={{ fontSize: "20px" }}
                          entity="Enhedspris33"
                          defaultValue={"Menu"}
                        />
                      </div>
                    </div>
                  }
                >
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    style={{ marginTop: "10px" }}
                    onClick={() => {
                      DataStore.setShowDropDowns(false);
                    }}
                  >
                    <AxoLocal
                      entity="AccountingTabViewEntity67"
                      defaultValue={"Vis menu bar"}
                    />{" "}
                    &nbsp;
                    <span className="icon-fontello-stop" />
                    &nbsp;
                    <span className="icon-fontello-stop" />
                    &nbsp;
                    <span className="icon-fontello-stop" />
                  </MenuItem>
                  <MenuItem
                    style={{ paddingBottom: "10px" }}
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("List")}
                  >
                    <span className="icon-fontello-grid" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="CalendarTabViewagenda"
                      defaultValue={"Oversigt"}
                    />
                  </MenuItem>
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Upload")}
                  >
                    <span className="icon-fontello-upload-4" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTabViewUpload"
                      defaultValue={"Upload"}
                    />
                  </MenuItem>
                  {userType !== "Society" ? (
                    <MenuItem
                      className="axonavTab AxotabDropdownmenu"
                      onClick={() => this.handleActiveState("Record")}
                    >
                      <span className="icon-fontello-video-5" /> &nbsp;&nbsp;
                      <AxoLocal
                        entity="DocumentTabViewRecord"
                        defaultValue={"Optag"}
                      />
                    </MenuItem>
                  ) : null}
                  {userType !== "Society" ? (
                    <MenuItem
                      className="axonavTab AxotabDropdownmenu"
                      onClick={() => this.handleActiveState("Create")}
                    >
                      <span className="icon-fontello-edit-3" /> &nbsp;&nbsp;
                      <AxoLocal
                        entity="DocumentTabViewCreateTemplate"
                        defaultValue={"Opret Skabelon"}
                      />
                    </MenuItem>
                  ) : null}
                  {userType !== "Society" ? (
                    <MenuItem
                      className="axonavTab AxotabDropdownmenu"
                      onClick={() => this.handleActiveState("Templates")}
                    >
                      <span className="icon-fontello-list-alt" /> &nbsp;&nbsp;
                      <AxoLocal
                        entity="DocumentTabViewTemplate"
                        defaultValue={"Skabeloner"}
                      />
                    </MenuItem>
                  ) : null}
                  {userType !== "Society" ? (
                    <MenuItem
                      className="axonavTab AxotabDropdownmenu"
                      onClick={() => this.handleActiveState("SharedTemps")}
                    >
                      <span className="icon-fontello-share-1" /> &nbsp;&nbsp;
                      <AxoLocal
                        entity="DocumentTabViewSharedtemplates"
                        defaultValue={"Delte skabeloner"}
                      />
                    </MenuItem>
                  ) : null}
                  {userType !== "Society" ? (
                    <MenuItem
                      className="axonavTab AxotabDropdownmenu"
                      onClick={() => this.handleActiveState("SharedDocs")}
                    >
                      <span className="icon-fontello-shuffle" /> &nbsp;&nbsp;
                      <AxoLocal
                        entity="DocumentTabViewShareddocuments"
                        defaultValue={"Delte dokumenter"}
                      />
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Trash")}
                  >
                    <span className="icon-fontello-trash-1" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTabViewBin"
                      defaultValue={"Skraldespand"}
                    />
                  </MenuItem>
                </DropdownButton>
              </Row>
            )}
            {this.renderTemplateNavNodes(true)}
          </Grid>
        </Large>
        <MediumOrSmaller>
          <Grid fluid className="axo-nav" id="noprint">
            <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("List")}
                >
                  <span className="icon-fontello-grid" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="CalendarTabViewagenda"
                    defaultValue={"Oversigt"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Upload")}
                >
                  <span className="icon-fontello-upload-4" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="DocumentTabViewUpload"
                    defaultValue={"upload"}
                  />
                </MenuItem>
                {userType !== "Society" ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Record")}
                  >
                    <span className="icon-fontello-video-5" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTabViewRecord"
                      defaultValue={"Optag"}
                    />
                  </MenuItem>
                ) : null}
                {userType !== "Society" ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Create")}
                  >
                    <span className="icon-fontello-edit-3" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTabViewCreateTemplate"
                      defaultValue={"Opret Skabelon"}
                    />
                  </MenuItem>
                ) : null}
                {userType !== "Society" ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Templates")}
                  >
                    <span className="icon-fontello-list-alt" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTabViewTemplate"
                      defaultValue={"Skabeloner"}
                    />
                  </MenuItem>
                ) : null}
                {userType !== "Society" ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("SharedTemps")}
                  >
                    <span className="icon-fontello-share-1" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTabViewSharedtemplates"
                      defaultValue={"Delte skabeloner"}
                    />
                  </MenuItem>
                ) : null}
                {userType !== "Society" ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("SharedDocs")}
                  >
                    <span className="icon-fontello-shuffle" /> &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTabViewShareddocuments"
                      defaultValue={"Delte dokumenter"}
                    />
                  </MenuItem>
                ) : null}
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Trash")}
                >
                  <span className="icon-fontello-trash-1" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="DocumentTabViewBin"
                    defaultValue={"Skraldespand"}
                  />
                </MenuItem>
              </DropdownButton>
            </Row>
            {smallScreenTabsExpanded ? (
              <Row>
                <Col xs={12}>{this.renderTemplateNavNodes(false)}</Col>
              </Row>
            ) : null}
          </Grid>
        </MediumOrSmaller>
      </div>
    );
  };

  renderTemplateViews = (match) => {
    return this.state.openTemplates.map((templateId) => {
      var currentTemplate = this.props.templates.find(
        (c) => c.id === templateId
      );
      return (
        <Route
          path={match.path + "/" + this.getTemplateKey(templateId)}
          render={(props) => (
            <DocumentTemplateEditView
              {...props}
              key={templateId}
              documentTemplate={currentTemplate}
              locale={this.props.userSettings.locale}
              onTemplateChange={this.onTemplateChange}
              onSaveTemplate={this.onSaveEditTemplate}
              documentMap={this.props.documentMap}
              onCreatePDF={this.onCreatePDF}
            />
          )}
        />
      );
    });
  };

  render() {
    var {
      documentMap,
      caseMap,
      userGroup,
      selectedContact,
      setSelectedContact,
      locale,
      match,
    } = this.props;

    var activeDocuments = documentMap.documents;
    var trashedDocuments = documentMap.trashed;

    var sharedWithDocuments = activeDocuments.filter(
      (doc) => doc.sharedUsers.length > 0
    );

    var activeTemplates = this.props.templates.filter((t) => !t.trashed);
    var trashedTemplates = this.props.templates.filter((t) => t.trashed);

    var originalTemplates = activeTemplates.filter(
      (t) => t.sharedUsers.length === 0
    );
    var sharedTemplateCopies = activeTemplates.filter(
      (t) => t.sharedUsers.length > 0
    );

    return (
      <div>
        <div>
          <Grid fluid>
            <Row>
              <Col xs={12} className="nopadding">
                {this.renderNavBar()}
                <Switch>
                  <Route
                    path={match.path + "/List"}
                    render={(props) => (
                      <DocumentTableFixedView
                        {...props}
                        documents={activeDocuments}
                        count={documentMap.count}
                        userProfile={this.props.userProfile}
                        contactMap={this.props.contactMap}
                        selectedContact={selectedContact}
                        setSelectedContact={setSelectedContact}
                        labels={this.props.labels}
                        caseMap={caseMap}
                        friends={this.props.friends}
                        userGroup={userGroup}
                        activeDocumentId={this.state.activeDocumentId}
                        onClickShared={this.onClickShared}
                        forceShowCards={
                          this.props.userSettings.showDocumentCards
                        }
                        onToggleCards={this.onToggleCards}
                        queryDocuments={this.props.queryDocuments}
                        onFilesSubmitted={this.onFilesSubmitted}
                        locale={locale}
                      />
                    )}
                  />
                  <Route
                    path={match.path + "/Upload"}
                    render={(props) => (
                      <DocumentUploadView
                        {...props}
                        documents={activeDocuments}
                        userProfile={this.props.userProfile}
                        labels={this.props.labels}
                        caseMap={caseMap}
                        contactMap={this.props.contactMap}
                        selectedContact={selectedContact}
                        setSelectedContact={setSelectedContact}
                        friends={this.props.friends}
                        onClickShared={this.onClickShared}
                        onFileUploaded={this.onFileUploaded}
                        forceShowCards={
                          this.props.userSettings.showDocumentCards
                        }
                        onToggleCards={this.onToggleCards}
                        onFilesSubmitted={this.onFilesSubmitted}
                        locale={locale}
                      />
                    )}
                  />
                  <Route
                    path={match.path + "/Record"}
                    render={(props) => (
                      <DocumentRecordView
                        {...props}
                        documents={activeDocuments}
                        userProfile={this.props.userProfile}
                        labels={this.props.labels}
                        caseMap={caseMap}
                        friends={this.props.friends}
                        onFileUploaded={this.onFileUploaded}
                        forceShowCards={
                          this.props.userSettings.showDocumentCards
                        }
                        onToggleCards={this.onToggleCards}
                      />
                    )}
                  />
                  <Route
                    path={match.path + "/Create"}
                    render={(props) => (
                      <DocumentTemplateCreateView
                        {...props}
                        documentMap={documentMap}
                        locale={this.props.userSettings.locale}
                        onCreateTemplate={this.onSaveTemplate}
                        onCreatePDF={this.onCreatePDF}
                      />
                    )}
                  />
                  <Route
                    path={match.path + "/Templates"}
                    render={(props) => (
                      <DocumentTemplateTableView
                        {...props}
                        templates={originalTemplates}
                        friends={this.props.friends}
                        activeTemplateId={this.state.activeTemplateId}
                        onClickTemplate={this.onSelectTemplate}
                      />
                    )}
                  />
                  <Route
                    path={match.path + "/SharedTemps"}
                    render={(props) => (
                      <DocumentTemplateSharedTableView
                        {...props}
                        templates={sharedTemplateCopies}
                        userProfile={this.props.userProfile}
                        friends={this.props.friends}
                        activeTemplateId={this.state.activeTemplateId}
                        onClickTemplate={this.onSelectTemplate}
                      />
                    )}
                  />
                  <Route
                    path={match.path + "/SharedDocs"}
                    render={(props) => (
                      <DocumentSharedTableView
                        {...props}
                        documents={sharedWithDocuments}
                        userProfile={this.props.userProfile}
                        sharedDocuments={this.props.sharedDocuments}
                      />
                    )}
                  />
                  <Route
                    path={match.path + "/Trash"}
                    render={(props) => (
                      <DocumentTrashTableView
                        {...props}
                        templates={trashedTemplates}
                        documents={trashedDocuments}
                        activeTemplateId={this.state.activeTemplateId}
                        onClickTemplate={this.onSelectTemplate}
                      />
                    )}
                  />
                  {/* Reopens selected template on page reload */}
                  {this.selectedTemplateId === null ? (
                    <Route
                      path={match.path + "/SingleTemplate/:id"}
                      render={(props) => {
                        if (this.selectedTemplateId === null) {
                          this.selectedTemplateId = parseInt(
                            props.match.params.id,
                            10
                          );
                        }
                        return null;
                      }}
                    />
                  ) : null}
                  {this.renderTemplateViews(match)}
                  <Route
                    exact
                    path={match.path}
                    render={(props) => (
                      <Redirect {...props} to={match.path + "/List"} />
                    )}
                  />
                </Switch>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(DocumentTabView);
