export default class DataListWrapper {
  constructor(indexMap, data) {
    if (indexMap.length !== data.length) {
      console.log(indexMap);
      console.log(data);
      let errorMessage =
        "DataListWrapper error: indexMap=" +
        indexMap.length +
        ". data: " +
        data.length +
        ".";
      throw errorMessage;
    }

    //Array from index in sort order to index in data array
    this._indexMap = indexMap;
    this._data = data;
  }

  getSize() {
    return this._indexMap.length;
  }

  getIndexMap() {
    return this._indexMap;
  }

  getObjectAt(index) {
    return this._data[this._indexMap[index]];
  }

  getSortedList() {
    return this._data.map((entry, index) => this.getObjectAt(index));
  }

  //Attempts to apply the existing sort order to a new list
  //Adds new data on top, existing data on bottom
  generateNewWrapperFromData(newDataList = []) {
    let existingIds = new Set(this._data.map((d) => d.id));

    let addedData = newDataList.filter((d) => !existingIds.has(d.id));

    //Map indices in the new array
    let idToIndexMap = {};
    newDataList.forEach((entry, index) => {
      idToIndexMap[entry.id] = index;
    });

    let newIndexMap = [];
    //Added elements are added on top
    for (let i = 0; i < addedData.length; i++) {
      newIndexMap.push(idToIndexMap[addedData[i].id]);
    }

    //Remove the indices corresponding to removed elements, and
    //update the remaining indices to point into the new array
    let indexMapExisting = this._indexMap
      .filter((value) => idToIndexMap[this._data[value].id] !== undefined)
      .map((value) => idToIndexMap[this._data[value].id]);

    //Add the remaining, using the existing sort order;
    newIndexMap = newIndexMap.concat(indexMapExisting);

    //This can happen if the same object id is on the list twice.
    //This should only happen if the same document has somehow been shared between two users in both directions.
    //Can happen if the document has been shared indirectly, and is later shared with the original user directly.
    //Since this is a special case that should be fixed later, we simply add some extra sorting space.
    if (newIndexMap.length < newDataList.length) {
      let addNo = newDataList.length - newIndexMap.length;
      for (let i = 0; i < addNo; ++i) {
        newIndexMap.push(0);
      }
    }

    let newWrapper = new DataListWrapper(newIndexMap, newDataList);
    return newWrapper;
  }
}
