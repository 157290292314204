import React from "react";
import UserInfoService from "../services/UserInfoService";
import DataActions from "../services/DataAccess/DataActions";
import RoutingService from "../services/RoutingService";
import { withRouter } from "react-router-dom";
import { AxoListGroup, AxoListItem } from "../utilities/AxoListGroup";
import { AxoLocal } from "../utilities/LexUtilities";
import { Grid, Row, Col } from "react-bootstrap";

class ChatItem extends React.PureComponent {
  render() {
    var { name, avatar, onClick } = this.props;

    return (
      <AxoListItem onClick={onClick} tabIndex="-1">
        <img
          alt=""
          src={avatar}
          width="30"
          height="30"
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderRadius: 100,
            padding: 2,
            position: "relative",
            top: -7,
            opacity: 1,
          }}
        />
        &nbsp;&nbsp;
        <span
          className="name"
          style={{ position: "relative", top: -2, opacity: 1 }}
        >
          {name}
        </span>
      </AxoListItem>
    );
  }
}

class NetworkList extends React.PureComponent {
  //Props
  //friends
  renderFriendNodes = () => {
    return this.props.friends.map((friend) => {
      return (
        <ChatItem
          key={friend.id}
          onClick={this.onSelectUser.bind(this, friend.id)}
          name={UserInfoService.getDisplayName(friend)}
          avatar={UserInfoService.getProfileImageSource(friend)}
          online
        />
      );
    });
  };

  onSelectUser = (userId, event) => {
    event.preventDefault();
    event.stopPropagation();
    DataActions.selectUserProfile(userId);
    this.props.history.push(RoutingService.getPath("Social/Profile"));
    if (this.props.onNavigate) {
      this.props.onNavigate();
    }
  };

  render() {
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div
                className="sidebar-header"
                style={{ paddingLeft: "25px", paddingRight: "25px" }}
              >
                <AxoLocal
                  entity="networkListNetwork"
                  defaultValue={"Netværk"}
                />
                ({this.props.friends.length})
              </div>
              <div className="sidebar-nav-container" style={{ padding: "5px" }}>
                <AxoListGroup style={{ marginBottom: 0 }}>
                  {this.renderFriendNodes()}
                </AxoListGroup>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(NetworkList);
