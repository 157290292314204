import React from "react";
import { DanishTerms, EnglishTerms, ChineseTerms } from "./PurchaseTermsSub";

import { Panel, PanelGroup } from "react-bootstrap";

import { AccordionUtils, Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class PurchaseTermsSubPanel extends React.Component {
  render() {
    let { locale } = this.props;
    return (
      <PanelGroup
        id="ITsubscriptionb"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {AccordionUtils.renderAccordionHeader(
                <span>
                  <Icon glyph="icon-fontello-info-circled" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="Enhedspris35"
                    defaultValue={"Købsbetingelser"}
                  />
                  &nbsp;&nbsp;
                  <Icon glyph="icon-fontello-doc-text-2" />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <div className="text-left">
              {locale === "enUS" ? <EnglishTerms /> : null}
              {locale === "da" ? <DanishTerms /> : null}
              {locale === "ch" ? <ChineseTerms /> : null}
            </div>
          </Panel.Body>
        </Panel>
      </PanelGroup>
    );
  }
}
