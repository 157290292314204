import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import moment from "moment";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import {
  LexButton,
  AsyncButton,
  AxoDateTime,
  AxoLocal,
} from "../utilities/LexUtilities";

//This form receives starting state and submit function from outside.
export default class PowerOfAttorneyFormView extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    startValue: PropTypes.object,
  };

  constructor(props) {
    super(props);

    if (props.startValue) {
      this.state = { ...props.startValue };
    } else {
      this.state = this.getStartState();
    }
  }

  getStartState = () => {
    return {
      applicantsName: "",
      dateOfBirth: "",
      applicantsAddress: "",
      personalIDorCPR: "",
      attorneyName: "",
      companyName: "",
      addressAttorney: "",
      postalCode: "",
      city: "",
      country: "",
      eMail: "",
      phone: "",
      cVRorCPRAttorney: "",
      text: "",
      placeAndDate: "",
      nameSignature: "",
      type: "Generic",
      servicePrice: "0",
    };
  };

  handleChange = (event) => {
    let { type, name } = event.target;

    let value = null;
    switch (type) {
      case "number":
        value = event.target.valueAsNumber;
        break;
      case "checkbox":
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
    }

    this.onUpdateProperty(name, value);
  };

  onUpdateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  onSubmit = async () => {
    let { onSubmit } = this.props;

    let result = await onSubmit(this.state);
    return result;
  };

  validateInput = () => {
    //Validation here
    let { text, longText } = this.state;

    //Only text and long text is required in this case. If
    if (!text || !longText) {
      return false;
    }

    return true;
  };

  onCancel = () => {
    let { onCancel, startValue } = this.props;

    if (onCancel) {
      onCancel();
      return;
    }

    if (startValue) {
      this.setState(this.props.startValue);
    } else {
      this.setState(this.getStartState());
    }
  };

  //Skabelon. Der skal ikke laves entities
  render() {
    let { userProfile } = this.props;

    let {
      applicantsName,
      dateOfBirth,
      applicantsAddress,
      personalIDorCPR,
      attorneyName,
      companyName,
      addressAttorney,
      postalCode,
      city,
      country,
      eMail,
      phone,
      cVRorCPRAttorney,
      text,
      placeAndDate,
      nameSignature,
      type,
      servicePrice,
    } = this.state;

    let selectOptions = [
      { value: "Alcohol", label: "Alkoholbevilling" },
      { value: "Visa", label: "Opholdstilladelse" },
      { value: "Consent", label: "Samtykkeerklæring" },
      { value: "Court", label: "Retssag" },
      { value: "EstablishCompany", label: "Stiftelse af selskab" },
      { value: "Generic", label: "Generelt" },
    ];

    let typeOption =
      selectOptions.find((o) => o.value === type) || selectOptions[0];

    return (
      <Form className="leftPadding standardMaxWidth">
        <FormGroup controlId="type">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Fuldmagstype
          </ControlLabel>
          <Select
            name="select"
            menuPlacement="auto"
            value={typeOption}
            onChange={(selection) => {
              if (!!selection) {
                this.onUpdateProperty("type", selection.value);
              }
            }}
            noOptionsMessage={() => ""}
            options={selectOptions}
          />
        </FormGroup>
        <FormGroup controlId="applicantsName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Ansøgers navn
          </ControlLabel>
          <FormControl
            name="applicantsName" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={applicantsName}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="dateOfBirth">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Ansøgers fødselsdato
          </ControlLabel>
          <AxoDateTime
            value={moment(dateOfBirth)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("dateOfBirth", dateTime.format())
            }
          />
        </FormGroup>

        <FormGroup controlId="applicantsAddress">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Ansøgers adresse
          </ControlLabel>
          <FormControl
            name="applicantsAddress"
            type="text"
            className="borderFormControlfocus"
            value={applicantsAddress}
            onChange={this.handleChange}
            rows={3}
          />
        </FormGroup>

        <FormGroup controlId="personalIDorCPR">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Evt. ansøgers personID eller CPR-nr.
          </ControlLabel>
          <FormControl
            name="personalIDorCPR"
            type="text"
            className="borderFormControlfocus"
            value={personalIDorCPR}
            onChange={this.handleChange}
          />
        </FormGroup>

        {!!userProfile && !!userProfile.id && (
          <LexButton
            onClick={() => {
              this.setState({
                attorneyName:
                  userProfile.firstName + " " + userProfile.lastName,
                companyName: userProfile.company || "",
                addressAttorney:
                  userProfile.address +
                  " " +
                  userProfile.postalCode +
                  " " +
                  userProfile.city,
                cVRorCPRAttorney: userProfile.companyCode || "",
                postalCode: userProfile.postalCode || "",
                city: userProfile.city || "",
                country: userProfile.country || "",
                eMail: userProfile.eMail || "",
                phone: userProfile.phone || "",
              });
            }}
          >
            Hent min
          </LexButton>
        )}
        <FormGroup controlId="attorneyName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Fuldmagtshavers navn
          </ControlLabel>
          <FormControl
            name="attorneyName" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={attorneyName}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="companyName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Evt. firma
          </ControlLabel>
          <FormControl
            name="companyName" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={companyName}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="addressAttorney">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Fuldmagtshavers adresse
          </ControlLabel>
          <FormControl
            name="addressAttorney" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={addressAttorney}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="cVRorCPRAttorney">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal entity="vatCountryCode31" defaultValue={"CPR / CVR"} />
          </ControlLabel>
          <FormControl
            name="cVRorCPRAttorney" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={cVRorCPRAttorney}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="postalCode">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Post nr.
          </ControlLabel>
          <FormControl
            name="postalCode" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={postalCode}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="city">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            By
          </ControlLabel>
          <FormControl
            name="city" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={city}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="country">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Land
          </ControlLabel>
          <FormControl
            name="country" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={country}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="phone">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Telefon
          </ControlLabel>
          <FormControl
            name="phone" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={phone}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="eMail">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Fuldmagtshavers email
          </ControlLabel>
          <FormControl
            name="eMail" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={eMail}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="text">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            fuldmagt til at
          </ControlLabel>
          <FormControl
            name="text"
            componentClass="textarea"
            className="borderFormControlfocus"
            value={text}
            placeholder="Skriv lang tekst her..."
            onChange={this.handleChange}
            rows={3}
          />
        </FormGroup>
        <FormGroup controlId="placeAndDate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Underskriftsdato
          </ControlLabel>
          <AxoDateTime
            value={moment(placeAndDate)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("placeAndDate", dateTime.format())
            }
          />
        </FormGroup>

        <FormGroup controlId="nameSignature">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Ansøgers underskrift
          </ControlLabel>
          <FormControl
            name="nameSignature" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={nameSignature}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="servicePrice">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Prise
          </ControlLabel>
          <FormControl
            name="servicePrice"
            type="number"
            className="borderFormControlfocus"
            value={servicePrice}
            onChange={this.handleChange}
          />
        </FormGroup>
        <ButtonToolbar>
          <AsyncButton hideOkIcon onClick={this.onSubmit}>
            <AxoLocal
              entity="friendRequestTimelineonApproveRequest"
              defaultValue={"Godkend"}
            />
          </AsyncButton>

          <LexButton onClick={this.onCancel}>Fortryd</LexButton>
        </ButtonToolbar>
        <p> </p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </Form>
    );
  }
}
