import React from "react";
import PropTypes from "prop-types";
import { Table, Column, Cell } from "fixed-data-table-2";
import $ from "jquery";

import moment from "moment";

import {
  DataActions,
  PrintService,
  NumberService,
  ModalService,
  ContactService,
} from "../../../services/AxoServices";

import {
  FormGroup,
  InputGroup,
  FormControl,
  ButtonToolbar,
  Table as BootstrapTable,
} from "react-bootstrap";

import {
  LexButton,
  TableBase,
  SortHeaderCell,
  DataListWrapper,
  Dimensions,
  Icon,
  FileViewerModal,
  ClientSearchBox,
  Flexbox,
  FlexElement,
  AxoLocal,
  AxoDateTime,
  AsyncButton,
  Link,
  getText,
  AxoCheckbox,
  AxoSelect,
} from "../../../utilities/LexUtilities";

import AccountingEntryEditForm from "../AccountingEntryEditForm";

const defaultColumnDefs = {
  select: {
    width: 50,
    shown: true,
  },
  receiptNumber: {
    width: 85,
    shown: true,
  },
  postingDate: {
    width: 150,
    shown: true,
  },
  creationDate: {
    width: 125,
    shown: true,
  },
  isIncome: {
    width: 125,
    shown: true,
  },
  description: {
    width: 150,
    shown: true,
  },
  vatNumber: {
    width: 100,
    shown: true,
  },
  account: {
    width: 175,
    shown: true,
  },
  amount: {
    width: 100,
    shown: true,
  },
  vat: {
    width: 100,
    shown: true,
  },
  balanceAccount: {
    width: 100,
    shown: true,
  },
  receipt: {
    width: 65,
    shown: true,
  },
  actions: {
    width: 250,
    shown: true,
  },
};

class AccountingJournalView extends TableBase {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    selectedPlan: PropTypes.object.isRequired,
    financeAccountMap: PropTypes.object.isRequired,
    selectedContact: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
    onToggleCards: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.name = "AccountingJournalView";

    this._defaultSortIndexes = this.generateDefaultSortIndexes(props.entries);
    let tableEntries = this.addDataStringsToEntries(props.entries);
    this.defaultColumnDefs = defaultColumnDefs;

    this.state = {
      tableEntries: tableEntries,
      selectedEntries: new Set(),
      minColWidths: {
        actions: 200,
      },
      maxColumnWidth: 400,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      printingInProgress: false,
      columnDefs: this.retrieveColumnDefs(),
      showStorageWarning: false,
      showFileUploadError: false,
      showFileMaxSizeWarning: false,
      ...this.getTranslatedTexts(),
    };

    this.selectedEntryId = 0;
  }

  clearSelectedEntries = () => {
    this.setState({ selectedEntries: new Set() });
  };

  onSelectEntry = (entryId, event) => {
    let selectedEntries = new Set(this.state.selectedEntries);
    if (event.target.checked) {
      selectedEntries.add(entryId);
    } else {
      selectedEntries.delete(entryId);
    }
    this.setState({ selectedEntries });
  };

  onSelectAll = () => {
    let { selectedEntries, sortedDataList } = this.state;
    let allSelected = selectedEntries.size === sortedDataList.getSize();

    if (allSelected) {
      this.setState({ selectedEntries: new Set() });
    } else {
      this.setState({
        selectedEntries: new Set(sortedDataList._data.map((d) => d.id)),
      });
    }
  };

  addDataStringsToEntries(entries) {
    return entries;
  }

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.addDataStringsToEntries(nextProps.entries);
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  componentDidUpdate() {
    let { printingInProgress } = this.state;

    if (printingInProgress) {
      PrintService.printElement(document.getElementById("printTable"));
      this.setState({ printingInProgress: false });
    }
  }

  onSearch = (event) => {
    let { tableEntries } = this.state;

    let entries = this.getFilteredEntries(tableEntries, event.target.value);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }

    return entries.filter((d) => {
      let filterText = `${d.description || ""} ${d.amount}`;
      return filterText.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  onShowReceipt = (entryId) => {
    let entry = this.props.entries.find((p) => p.id === entryId);
    this.fileModal.open([entry.receipt]);
  };

  // onRemoveReceipt = (id) => {
  //   let { selectedContact } = this.props;
  //   let { tableEntries } = this.state;
  //   let entry = tableEntries.find(e => e.id === id);
  //   if(!entry) {
  //     return;
  //   }

  //   if(!!entry.receipt) {
  //     DataStore.updateDocumentState({
  //       ...entry.receipt,
  //       client: selectedContact,
  //       receiptNumber: null
  //     });
  //   }

  //   DataActions.updatePostingEntryReceipt(entry, null);
  // }

  onDeleteReceiptForEntry = (entry) => {
    ModalService.openConfirmModal(
      <>
        <AxoLocal
          entity="axoEntityidcode26"
          defaultValue={"Vil du slette bilagsfilen permanent?"}
        />
      </>,
      async (value) => {
        if (!value) {
          return;
        }
        let receiptId = entry.receiptId;
        let response = await DataActions.updatePostingEntryReceipt(entry, null);
        if (!response.ok) {
          return;
        }
        DataActions.deleteDocument(receiptId);
      }
    );
  };

  getTranslatedTexts = () => {
    return {
      creditLabel: getText("AccountingTabViewEntity41"),
      debitLabel: getText("AccountingTabViewEntity42"),
      uploadLabel: getText("DocumentTabViewUpload"),
      deleteLabel: getText("axoidcode179"),
      copyLabel: getText("axoAccounting24"),
      editLabel: getText("presentationMarketing6"),
      chooseLabel: getText("ClientBookingSelectLawyer"),
    };
  };

  sortByAccount = (l, r) => {
    let { financeAccountMap } = this.props;

    let acc1 = financeAccountMap[l.financeAccountId];
    let acc2 = financeAccountMap[r.financeAccountId];

    return (!!acc1 ? acc1.number : 0) - (!!acc2 ? acc2.number : 0);
  };

  sortByBalanceAccount = (l, r) => {
    let { financeAccountMap } = this.props;

    let acc1 = financeAccountMap[l.balanceFinanceAccountId];
    let acc2 = financeAccountMap[r.balanceFinanceAccountId];

    return (!!acc1 ? acc1.number : 0) - (!!acc2 ? acc2.number : 0);
  };

  renderTable = () => {
    let {
      sortedDataList,
      colSortDirs,
      columnDefs,
      selectedEntries,
      creditLabel,
      debitLabel,
      // uploadLabel,
      deleteLabel,
      copyLabel,
      editLabel,
    } = this.state;

    const {
      containerHeight,
      containerWidth,
      financeAccountMap,
      onDelete,
      // confirmDeleteId,
      onCopy,
      // confirmCopyId,
      onEdit,
      // confirmEditId,
    } = this.props;

    let tableHeight = containerHeight;
    let tableWidth = Math.max(750, containerWidth - 25);

    // let disabledButtons = confirmDeleteId > 0 || confirmCopyId > 0 || confirmEditId > 0;

    return (
      <Table
        rowHeight={40}
        rowsCount={sortedDataList.getSize()}
        height={tableHeight}
        width={tableWidth}
        isColumnResizing={false}
        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
        headerHeight={40}
      >
        <Column
          columnKey="select"
          header={
            <Cell className="text-center">
              <AxoCheckbox
                checked={
                  selectedEntries.size > 0 &&
                  selectedEntries.size === sortedDataList.getSize()
                }
                onChange={this.onSelectAll}
              />
            </Cell>
          }
          cell={(props) => {
            var entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} className="text-center" {...props}>
                <AxoCheckbox
                  checked={selectedEntries.has(entry.id)}
                  onChange={this.onSelectEntry.bind(this, entry.id)}
                />
              </Cell>
            );
          }}
          width={columnDefs.select.width}
          isResizable={true}
        />
        <Column
          columnKey="receiptNumber"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.receiptNumber}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal entity="axoidcode64" defaultValue={"Nummer"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props} className="text-center">
                <div>#{entry.receiptNumber}</div>
              </Cell>
            );
          }}
          width={columnDefs.receiptNumber.width}
          isResizable={true}
        />
        <Column
          columnKey="postingDate"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.postingDate}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal entity="axoidcode96" defaultValue={"Bogføringsdato"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                <div>{moment.utc(entry.postingDate).format("L")}</div>
              </Cell>
            );
          }}
          width={columnDefs.postingDate.width}
          isResizable={true}
        />
        <Column
          columnKey="creationDate"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.creationDate}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal entity="axoidcode97" defaultValue={"Bilagsdato"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                <div>{moment.utc(entry.creationDate).format("L")}</div>
              </Cell>
            );
          }}
          width={columnDefs.creationDate.width}
          isResizable={true}
        />
        <Column
          columnKey="isIncome"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.isIncome}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <span>{creditLabel}</span> / <span>{debitLabel}</span>
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {entry.isIncome ? (
                  <span>{creditLabel}</span>
                ) : (
                  <span>{debitLabel}</span>
                )}
              </Cell>
            );
          }}
          width={columnDefs.isIncome.width}
          isResizable={true}
        />
        <Column
          columnKey="description"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.description}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                <div>{entry.description}</div>
              </Cell>
            );
          }}
          width={columnDefs.description.width}
          isResizable={true}
        />
        <Column
          columnKey="account"
          header={
            <SortHeaderCell
              onSortChange={(columnKey, sortDir) =>
                this._onSortChange(columnKey, sortDir, this.sortByAccount)
              }
              sortDir={colSortDirs.account}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal
                entity="AccountingTabViewEntity13"
                defaultValue={"Konto"}
              />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            let account = financeAccountMap[entry.financeAccountId];
            return (
              <Cell key={entry.id} {...props}>
                <div>
                  {!!account
                    ? account.number.toString() + " - " + account.name
                    : ""}
                </div>
              </Cell>
            );
          }}
          width={columnDefs.account.width}
          isResizable={true}
        />
        <Column
          columnKey="amount"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.amount}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props} className="text-right">
                <div>{NumberService.formatDecimal(entry.amount)}</div>
              </Cell>
            );
          }}
          width={columnDefs.amount.width}
          isResizable={true}
        />
        <Column
          columnKey="vat"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.vat}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal
                entity="invoiPaymentattheSubtotalVAT"
                defaultValue={"Moms"}
              />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props} className="text-right">
                <div>{NumberService.formatDecimal(entry.vat)}</div>
              </Cell>
            );
          }}
          width={columnDefs.vat.width}
          isResizable={true}
        />
        <Column
          columnKey="balanceAccount"
          header={
            <SortHeaderCell
              onSortChange={(columnKey, sortDir) =>
                this._onSortChange(
                  columnKey,
                  sortDir,
                  this.sortByBalanceAccount
                )
              }
              sortDir={colSortDirs.balanceAccount}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal
                entity="updategetAccountName1"
                defaultValue={"Modkonto"}
              />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            let account = financeAccountMap[entry.balanceFinanceAccountId];
            return (
              <Cell key={entry.id} {...props}>
                <div>
                  {!!account
                    ? account.number.toString() + " - " + account.name
                    : ""}
                </div>
              </Cell>
            );
          }}
          width={columnDefs.balanceAccount.width}
          isResizable={true}
        />
        <Column
          columnKey="receipt"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.receipt}
            >
              <Icon className="editable" glyph="icon-fontello-arrow-combo" />
              &nbsp;
              <AxoLocal
                entity="CaseDataTableFixedlabelAddedToCasedocuments1"
                defaultValue={"Bilag"}
              />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {!!entry.receipt ? (
                  <div>
                    <Icon
                      className="editable"
                      role="button"
                      onClick={() => this.onShowReceipt(entry.id)}
                      glyph="icon-fontello-attach-7"
                    />
                    &nbsp;
                    <Icon
                      title={getText("axoidcode9", "Fjern bilagsfil")}
                      className="editable"
                      role="button"
                      onClick={() => this.onDeleteReceiptForEntry(entry)}
                      glyph="icon-fontello-trash-1"
                    />
                  </div>
                ) : (
                  <div>
                    &nbsp;
                    <Icon
                      title="Upload bilagsfil"
                      className="editable"
                      role="button"
                      onClick={() =>
                        this.onUploadReceiptForExistingEntry(entry.id)
                      }
                      glyph="icon-fontello-upload-4"
                    />
                    {/* <LexButton onClick={() => this.onUploadReceiptForExistingEntry(entry.id)}>
                    {uploadLabel}  
                  </LexButton> */}
                  </div>
                )}
              </Cell>
            );
          }}
          width={columnDefs.receipt.width}
          isResizable={true}
        />
        <Column
          columnKey="actions"
          header={<Cell></Cell>}
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                &nbsp;
                <Icon
                  title={deleteLabel}
                  className="editable"
                  role="button"
                  onClick={() => onDelete(entry.id)}
                  glyph="icon-fontello-trash-1"
                />
                &nbsp;&nbsp;&nbsp;
                <Icon
                  title={editLabel}
                  className="editable"
                  role="button"
                  onClick={() => onEdit(entry.id)}
                  glyph="icon-fontello-edit"
                />
                &nbsp;&nbsp;&nbsp;
                <Icon
                  title={copyLabel}
                  className="editable"
                  role="button"
                  onClick={() => onCopy(entry.id)}
                  glyph="icon-fontello-docs-1"
                />
                {/* <ButtonToolbar>
                <LexButton disabled={disabledButtons} onClick={() => onDelete(entry.id)}>
                  <Icon glyph='icon-fontello-trash-1' title={deleteLabel}/>
                </LexButton>
                <LexButton disabled={disabledButtons} onClick={() => onEdit(entry.id)}>
                  {editLabel}
                </LexButton>
                <LexButton disabled={disabledButtons} onClick={() => onCopy(entry.id)}>
                  {copyLabel} 
                </LexButton>
              </ButtonToolbar> */}
              </Cell>
            );
          }}
          width={columnDefs.actions.width}
          isResizable={true}
        />
      </Table>
    );
  };

  renderGridView = () => {
    return (
      <div style={{ margin: "auto" }}>
        {/* <BookkeepingGridView 
          entries={this.state.sortedDataList._data}
          getAccountName={this.getAccountName}
          onDeleteEntry={this.onDeleteEntry}
          onShowReceipt={this.onShowReceipt}
          onUploadReceiptForExistingEntry={this.onUploadReceiptForExistingEntry}
          onChangePropertyValue={this.onChangePropertyValue}
          /> */}
      </div>
    );
  };

  onPrintEntries = () => {
    //Renders the print table, which is printed in componentDidUpdate
    this.setState({ printingInProgress: true });
  };

  renderPrintTable = () => {
    let {
      financeAccountMap,
      selectedContact,
      selectedFiscalYear,
      selectedStartDate,
      selectedEndDate,
      fixedClient,
    } = this.props;

    let { sortedDataList } = this.state;

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    let indices = Array.from(Array(sortedDataList.getSize()).keys());

    return (
      <div id="printTable">
        <div className="leftPadding text-center">
          <span style={{ fontSize: "18px" }}>
            <AxoLocal entity="axoidcode99" defaultValue={"Bogføringsjournal"} />
            {!fixedClient && (
              <>
                &nbsp;-&nbsp;{selectedContact.firstName}{" "}
                {selectedContact.lastName}
              </>
            )}
          </span>
        </div>
        <div className="leftPadding text-center" style={{ fontSize: "18px" }}>
          {startDateSelection.format("L")} - {endDateSelection.format("L")}
        </div>
        <BootstrapTable>
          <tbody>
            <tr>
              <th>
                <AxoLocal entity="axoidcode64" defaultValue={"Nummer"} />
              </th>
              <th>
                <AxoLocal
                  entity="axoidcode96"
                  defaultValue={"Bogføringsdato"}
                />
              </th>
              <th>
                <AxoLocal entity="axoidcode97" defaultValue={"Bilagsdato"} />
              </th>
              <th>
                <AxoLocal
                  entity="AccountingTabViewEntity41"
                  defaultValue={"Kredit"}
                />{" "}
                /{" "}
                <AxoLocal
                  entity="AccountingTabViewEntity42"
                  defaultValue={"Debet"}
                />
              </th>
              <th>
                <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />
              </th>
              {/* <th><AxoLocal entity='SidebarRightContainerCompanyCode'defaultValue={'CVR'}/></th> */}
              <th>
                <AxoLocal
                  entity="AccountingTabViewEntity13"
                  defaultValue={"Konto"}
                />
              </th>
              <th>
                <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />
              </th>
              <th>
                <AxoLocal
                  entity="invoiPaymentattheSubtotalVAT"
                  defaultValue={"Moms"}
                />
              </th>
              <th>
                <AxoLocal
                  entity="updategetAccountName1"
                  defaultValue={"Modkonto"}
                />
              </th>
            </tr>
            {indices.map((index) => {
              let entry = sortedDataList.getObjectAt(index);
              let account = financeAccountMap[entry.financeAccountId];
              let balanceAccount =
                financeAccountMap[entry.balanceFinanceAccountId];

              return (
                <tr>
                  <td>{entry.receiptNumber}</td>
                  <td>{moment.utc(entry.postingDate).format("L")}</td>
                  <td>{moment.utc(entry.creationDate).format("L")}</td>
                  <td>
                    {entry.isIncome ? (
                      <span>
                        <AxoLocal
                          entity="AccountingTabViewEntity41"
                          defaultValue={"Kredit"}
                        />
                      </span>
                    ) : (
                      <span>
                        <AxoLocal
                          entity="AccountingTabViewEntity42"
                          defaultValue={"Debet"}
                        />
                      </span>
                    )}
                  </td>
                  <td>{entry.description}</td>
                  {/* <td>{entry.vatNumber}</td> */}
                  <td>
                    {!!account
                      ? account.number.toString() + " - " + account.name
                      : ""}
                  </td>
                  <td>{entry.amount.toFixed(2)}</td>
                  <td>{entry.vat.toFixed(2)}</td>
                  <td>
                    {!!balanceAccount
                      ? balanceAccount.number.toString() +
                        " - " +
                        balanceAccount.name
                      : ""}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </BootstrapTable>
      </div>
    );
  };

  onPrintReceiptsToZipArchive = async () => {
    return await this.onPrintReceipts(true);
  };

  onPrintReceipts = async (createZipArchive = false) => {
    let {
      selectedContact,
      selectedFiscalYearId,
      selectedStartDate,
      selectedEndDate,
      includeDraft,
    } = this.props;

    try {
      let result = await DataActions.printFilteredReceipts({
        contactId: selectedContact.id,
        label: getText("printReceiptsLabel", "Bilag"),
        filter: includeDraft ? "ApprovedOrPosted" : "Posted",
        fiscalYearId: selectedFiscalYearId,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        usePostingDates: false,
        createZipArchive,
      });

      // if(result.ok) {
      //   let newFile = await result.json();
      //   this.fileModal.open([newFile]);
      // }

      if (result.ok) {
        ModalService.openAlertModal(
          <>
            <div>
              Bilagsdokumentet er under udarbejdelse og vil kunne findes under
              Dokumenter.
            </div>
            <div>Der vil blive vist en besked når dokumentet er færdigt.</div>
          </>
        );
      }

      return result.ok;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  onUploadReceiptForExistingEntry = (entryId) => {
    this.selectedEntryId = entryId;
    $("#receiptUpload").trigger("click");
  };

  onReceiptSelected = async (event) => {
    let { selectedContact } = this.props;

    let entry = this.props.entries.find((e) => e.id === this.selectedEntryId);
    if (!entry) {
      return;
    }

    let files = event.target.files;
    if (files.length === 0) {
      return;
    }

    let file = files[0];
    if (file.size / Math.pow(1024, 2) > 50) {
      this.showWarning("showFileMaxSizeWarning");
      return;
    }

    let uploadResponse = await DataActions.uploadDocuments(files, {
      clientId: selectedContact.id,
      convertToPdf: true,
    });
    let addedFiles = [];
    if (uploadResponse.ok) {
      addedFiles = await uploadResponse.json();
      if (addedFiles.length === 0) {
        return;
      }
    } else {
      return this.displayResponseWarnings(uploadResponse);
    }

    let addedFile = addedFiles[0];
    return DataActions.updatePostingEntryReceipt(entry, addedFile);
  };

  showWarning = (warningName) => {
    this.setState({ [warningName]: true });
    setTimeout(() => {
      this.setState({ [warningName]: false });
    }, 3000);
  };

  displayResponseWarnings = async (response) => {
    if (response.status === 400) {
      let text = await response.text();
      if (text === "Storage") {
        this.showWarning("showStorageWarning");
        return;
      } else {
        this.showWarning("showFileUploadError");
        return;
      }
    } else {
      this.showWarning("showFileUploadError");
      return;
    }
  };

  FileErrorMessage = () => {
    let { showFileMaxSizeWarning, showStorageWarning, showFileUploadError } =
      this.state;
    if (showFileMaxSizeWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarning"
            defaultValue={"Max 50 mb"}
          />
        </div>
      );
    }
    if (showStorageWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarningA"
            defaultValue={"Ikke mere plads. Slet filer."}
          />
        </div>
      );
    }
    if (showFileUploadError) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesError"
            defaultValue={"Upload fejlede"}
          />
        </div>
      );
    }
    return <span></span>;
  };

  render() {
    let {
      entries,
      // forceShowCards,
      contactMap,
      handleSelectedClient,
      selectedContact,
      fiscalYears,
      selectedFiscalYearId,
      onSelectFiscalYear,
      selectedStartDate,
      selectedEndDate,
      onSelectStartDate,
      onSelectEndDate,
      // queryEntries,
      onDelete,
      confirmDeleteId,
      confirmDelete,
      onCopy,
      confirmCopyId,
      onConfirmCopy,
      onEdit,
      confirmEditId,
      onConfirmEdit,
      showConfirmationMessage,
      selectedPlan,
      confirmDeleteSelected,
      onConfirmDeleteSelected,
      onCancelDeleteSelected,
      onDeleteSelected,
      postingJournals,
      onSelectJournal,
      selectedJournalId,

      confirmDeleteJournal,
      onDeleteSelectedJournal,
      onCancelDeleteSelectedJournal,
      onConfirmDeleteSelectedJournal,

      confirmCopyJournal,
      onCopyJournal,
      onCancelCopyJournal,
      onConfirmCopyJournal,
      fixedClient,
      financeAccountMap,
      taxSpecificationMap,
    } = this.props;

    let { printingInProgress, selectedEntries } = this.state;

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    let activeEntry = {};
    if (confirmDeleteId > 0 || confirmCopyId > 0 || confirmEditId > 0) {
      activeEntry =
        entries.find(
          (e) =>
            e.id === confirmDeleteId ||
            e.id === confirmCopyId ||
            e.id === confirmEditId
        ) || {};
    }

    let postingJournalOptions = postingJournals.map((j) => ({
      value: j.id,
      label: j.number + " - " + moment.utc(j.date).format("L"),
    }));
    let defaultOption = { value: 0, label: "Alle journaler" };
    postingJournalOptions = [defaultOption].concat(postingJournalOptions);
    let selectedJournalOption = postingJournalOptions.find(
      (o) => o.value === selectedJournalId
    );

    let FileErrorMessage = this.FileErrorMessage;
    return (
      <div style={{ marginLeft: "15px", marginRight: "15px" }}>
        <Flexbox
          responsive
          style={{ marginTop: "15px", paddingBottom: "10px" }}
        >
          {!fixedClient && (
            <FlexElement className="onTop rightPadding" style={{ zIndex: 10 }}>
              <AxoLocal
                componentClass={ClientSearchBox}
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                disabled={false}
                componentAttribute="placeholder"
                entity="composeSelectClientPlaceholder1"
              />
            </FlexElement>
          )}
          {selectedContact.id && !!fiscalYears ? (
            <React.Fragment>
              <FlexElement flexBasis="150px" className="onToppest rightPadding">
                <AxoSelect
                  name="fiscalYear"
                  menuPlacement="auto"
                  value={{
                    value: selectedFiscalYear.id.toString(),
                    label: selectedFiscalYear.name,
                  }}
                  onChange={(selection, arg) => {
                    onSelectFiscalYear(
                      !!selection && selection.value !== "0"
                        ? parseInt(selection.value, 10)
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "0",
                      label: getText("axoidcode100", "Vælg regnskabsår"),
                    },
                    ...fiscalYears.map((year) => {
                      return { value: year.id.toString(), label: year.name };
                    }),
                  ]}
                />
              </FlexElement>
              <FlexElement flexBasis="125px" className="rightPadding">
                <AxoDateTime
                  utc
                  value={startDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectStartDate}
                  isValidDate={(currentDate) => {
                    return !currentDate.isAfter(endDateSelection);
                  }}
                />
              </FlexElement>
              <FlexElement flexBasis="125px" className="rightPadding">
                <AxoDateTime
                  utc
                  value={endDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectEndDate}
                  isValidDate={(currentDate) => {
                    return !currentDate.isBefore(startDateSelection);
                  }}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <LexButton
                  title={getText("axoEntityidcode142", "Print journal")}
                  disabled={!selectedContact.id || entries.length === 0}
                  onClick={this.onPrintEntries}
                >
                  <Icon glyph="icon-fontello-print-3" />
                </LexButton>
              </FlexElement>
              <FlexElement className="rightPadding">
                <AsyncButton
                  title={getText(
                    "axoEntityidcode143",
                    "Print bilagsfiler til PDF"
                  )}
                  disabled={!selectedContact.id || entries.length === 0}
                  onClick={this.onPrintReceipts}
                >
                  <Icon glyph="icon-fontello-print-3" /> PDF
                </AsyncButton>
              </FlexElement>
              <FlexElement className="rightPadding">
                <AsyncButton
                  title={getText(
                    "axoEntityidcode143",
                    "Print bilagsfiler til Zip fil"
                  )}
                  disabled={!selectedContact.id || entries.length === 0}
                  onClick={this.onPrintReceiptsToZipArchive}
                >
                  <Icon glyph="icon-fontello-print-3" /> Zip
                </AsyncButton>
              </FlexElement>
              <FlexElement>
                <div style={{ minWidth: "200px", maxWidth: "600px" }}>
                  <FormGroup controlId="inputWithIcon">
                    <InputGroup>
                      {/* Søg...  */}
                      <AxoLocal
                        componentClass={FormControl}
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                        componentAttribute="placeholder"
                        entity="ContactTableViewplaceholderonSearch"
                      />
                      <FormControl.Feedback>
                        <Icon glyph="icon-fontello-search" />
                      </FormControl.Feedback>
                    </InputGroup>
                  </FormGroup>
                </div>
              </FlexElement>
              {/* <FlexElement style={{ paddingLeft: '15px' }}>
                <AsyncButton onClick={queryEntries}>Indlæs posteringer</AsyncButton>
              </FlexElement> */}
            </React.Fragment>
          ) : null}
        </Flexbox>
        <div>
          <div>
            {selectedContact.id && !!selectedFiscalYearId ? (
              <div className="axobg" style={{ marginBottom: "5px" }}>
                <Flexbox>
                  <FlexElement className="rightPadding">
                    <Icon glyph="icon-fontello-doc-7" />
                    &nbsp;&nbsp;
                    <LexButton
                      disabled={selectedEntries.size === 0}
                      onClick={onDeleteSelected}
                    >
                      <AxoLocal
                        entity="updategetAccountName7a"
                        defaultValue={"Slet valgte"}
                      />
                    </LexButton>
                  </FlexElement>
                  {/* <FlexElement className='text-right hidden-xs' style={{ paddingTop: '10px'}}>
                <LexButton onClick={this.props.onToggleCards}>
                  { this.props.forceShowCards ?  <span> <Icon glyph="icon-fontello-grid"/>&nbsp;&nbsp;
                  <AxoLocal key='showTable' entity='CaseDataTableFixedShowastable'defaultValue={'Vis som tabel'}/> </span> 
                        :  <span> <Icon glyph="icon-fontello-th-thumb-empty"/>&nbsp;&nbsp;
                        <AxoLocal key='showCards' entity='CaseDataTableFixedShowCards'defaultValue={'Vis som kartotekskort'}/></span> }
                </LexButton>
              </FlexElement> */}
                  <FlexElement flexBasis="250px" className="onTop rightPadding">
                    <AxoSelect
                      name="Journal"
                      menuPlacement="auto"
                      value={selectedJournalOption}
                      onChange={(selection, arg) => {
                        onSelectJournal(
                          !!selection && selection.value !== "0"
                            ? parseInt(selection.value, 10)
                            : null
                        );
                        this.clearSelectedEntries();
                      }}
                      noOptionsMessage={() => ""}
                      options={postingJournalOptions}
                    />
                  </FlexElement>
                  <FlexElement className="rightPadding">
                    <LexButton
                      disabled={!selectedJournalId}
                      onClick={onDeleteSelectedJournal}
                    >
                      <AxoLocal
                        entity="axoidcode225"
                        defaultValue={"Slet valgt journal."}
                      />
                    </LexButton>
                  </FlexElement>
                  <FlexElement>
                    <LexButton
                      disabled={!selectedJournalId}
                      onClick={onCopyJournal}
                    >
                      <AxoLocal
                        entity="axoidcode226"
                        defaultValue={"Kopier journal."}
                      />
                    </LexButton>
                  </FlexElement>
                </Flexbox>
              </div>
            ) : null}
            {confirmDeleteId > 0 && !!activeEntry.id ? (
              <Flexbox responsive style={{ paddingBottom: "10px" }}>
                <FlexElement className="rightPadding">
                  <h4>
                    (#{activeEntry.receiptNumber})&nbsp;
                    <AxoLocal
                      entity="axoidcode101"
                      defaultValue={
                        " Der vil blive oprettet en modpostering i bogføringskladden."
                      }
                    />
                  </h4>
                </FlexElement>
                <FlexElement>
                  <ButtonToolbar>
                    <AsyncButton onClick={() => confirmDelete(activeEntry)}>
                      <AxoLocal
                        key="confirm"
                        entity="axoAccounting21"
                        defaultValue={"Bekræft sletning"}
                      />
                    </AsyncButton>
                    <LexButton onClick={() => onDelete(0)}>
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </ButtonToolbar>
                </FlexElement>
              </Flexbox>
            ) : null}
            {confirmDeleteSelected ? (
              <Flexbox responsive style={{ paddingBottom: "10px" }}>
                <FlexElement className="rightPadding">
                  <h4>
                    <AxoLocal
                      entity="axoidcode47"
                      defaultValue={
                        "Der vil blive oprettet modposteringer i bogføringskladden"
                      }
                    />
                  </h4>
                </FlexElement>
                <FlexElement>
                  <ButtonToolbar>
                    <AsyncButton
                      onClick={() => onConfirmDeleteSelected(selectedEntries)}
                    >
                      <AxoLocal
                        key="confirm1"
                        entity="axoAccounting21"
                        defaultValue={"Bekræft sletning"}
                      />
                    </AsyncButton>
                    <LexButton
                      onClick={() => {
                        this.clearSelectedEntries();
                        onCancelDeleteSelected();
                      }}
                    >
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </ButtonToolbar>
                </FlexElement>
              </Flexbox>
            ) : null}
            {confirmDeleteJournal ? (
              <Flexbox responsive style={{ paddingBottom: "10px" }}>
                <FlexElement className="rightPadding">
                  <h4>
                    <AxoLocal
                      entity="axoidcode47"
                      defaultValue={
                        "Der vil blive oprettet modposteringer i bogføringskladden"
                      }
                    />
                  </h4>
                </FlexElement>
                <FlexElement>
                  <ButtonToolbar>
                    <AsyncButton
                      onClick={() => onConfirmDeleteSelectedJournal(entries)}
                    >
                      <AxoLocal
                        key="confirm1"
                        entity="axoAccounting21"
                        defaultValue={"Bekræft sletning"}
                      />
                    </AsyncButton>
                    <LexButton onClick={onCancelDeleteSelectedJournal}>
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </ButtonToolbar>
                </FlexElement>
              </Flexbox>
            ) : null}
            {confirmCopyJournal ? (
              <Flexbox responsive style={{ paddingBottom: "10px" }}>
                <FlexElement className="rightPadding">
                  <h4>
                    <AxoLocal
                      entity="axoidcode47"
                      defaultValue={
                        "Der vil blive oprettet modposteringer i bogføringskladden"
                      }
                    />
                  </h4>
                </FlexElement>
                <FlexElement>
                  <ButtonToolbar>
                    <AsyncButton onClick={() => onConfirmCopyJournal(entries)}>
                      <AxoLocal
                        entity="axoidcode75"
                        defaultValue={"Bekræft kopiering"}
                      />
                    </AsyncButton>
                    <LexButton onClick={() => onCancelCopyJournal()}>
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </ButtonToolbar>
                </FlexElement>
              </Flexbox>
            ) : null}
            {confirmCopyId > 0 && !!activeEntry.id ? (
              <Flexbox responsive style={{ paddingBottom: "10px" }}>
                <FlexElement className="rightPadding">
                  <h4>
                    (#{activeEntry.receiptNumber}) &nbsp;
                    <AxoLocal
                      entity="axoidcode102"
                      defaultValue={
                        "Der vil blive oprettet en kopi i bogføringskladden."
                      }
                    />
                  </h4>
                </FlexElement>
                <FlexElement>
                  <ButtonToolbar>
                    <AsyncButton onClick={() => onConfirmCopy(activeEntry)}>
                      <AxoLocal
                        entity="axoidcode75"
                        defaultValue={"Bekræft kopiering"}
                      />
                    </AsyncButton>
                    <LexButton onClick={() => onCopy(0)}>
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </ButtonToolbar>
                </FlexElement>
              </Flexbox>
            ) : null}
            {confirmEditId > 0 && !!activeEntry.id ? (
              <div className="onTop" style={{ paddingBottom: "10px" }}>
                <AccountingEntryEditForm
                  key={activeEntry.id}
                  entry={activeEntry}
                  financeAccountArray={selectedPlan.accounts}
                  financeAccountMap={financeAccountMap}
                  taxSpecificationMap={taxSpecificationMap}
                  onSubmit={(newEntry) => onConfirmEdit(activeEntry, newEntry)}
                  onCancel={() => onEdit(0)}
                />
              </div>
            ) : null}
            {confirmDeleteId === 0 &&
            confirmCopyId === 0 &&
            showConfirmationMessage ? (
              <div style={{ paddingBottom: "10px" }}>
                <h4>
                  <Link to="Drafts" style={{ textDecoration: "underline" }}>
                    <AxoLocal
                      entity="axoidcode79"
                      defaultValue={
                        "Posteringen blev oprettet i bogføringskladden."
                      }
                    />
                  </Link>
                </h4>
              </div>
            ) : null}
            <div className="text-center">
              <FileErrorMessage />
            </div>
            {selectedContact.id && !!selectedFiscalYearId
              ? this.renderTable()
              : null}
            {printingInProgress ? this.renderPrintTable() : null}
          </div>
        </div>
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
        <input
          name="receipt"
          onChange={this.onReceiptSelected}
          type="file"
          accept="image/*,.pdf"
          id="receiptUpload"
          style={{ display: "none" }}
        />
      </div>
    );
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 250;
  },
})(AccountingJournalView);
