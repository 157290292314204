import React from "react";
// import Invoice from '../routes/TimeEntries/Invoice'
// import CollectionView from './CollectionView'
import VLCollectionView from "./VLCollectionView";
// import PolarCollectionView from './PolarCollectionView'
import { withRouter } from "react-router-dom";
import QueryString from "query-string";

import {
  DataStore,
  // StripeAuthenticationService,
  // LocalizationService,
  ApiService,
  TimeEntryService,
  // DataActions
  ApiKeys,
} from "../services/AxoServices";

import {
  LoadingIndicator,
  AxoLocal,
  getText,
  Consumer,
} from "../utilities/LexUtilities";
import FullInvoiceCollectionView from "./FullInvoiceCollectionView";
import SubscriptionRegistrationView from "./SubscriptionRegistrationView";

class InvoicePaymentContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      invoice: null,
      client: null,
      paymentDate: null,
      error: false,
      genericError: false,
      showFullInvoice: false,
      stripeAccountId: "",
      paymentTerms: "",
      paymentPrivacyPolicy: "",
      locale: "da",
      subNumber: 0,
    };

    // this.paylike = window.Paylike(ApiKeys.payLikeApiKey);
  }

  componentDidMount() {
    let query = QueryString.parse(this.props.location.search);
    DataStore.fetchGlobalSettings();

    ApiService.getPaymentRequest(query.code)
      .then((paymentRequest) => {
        this.setState({
          client: paymentRequest.contactInfo,
          invoice: paymentRequest.invoice,
          paymentDate: paymentRequest.paymentDate,
          showFullInvoice: paymentRequest.showFullInvoice,
          stripeAccountId: paymentRequest.stripeAccountId,
          paymentTerms: paymentRequest.paymentTerms,
          paymentSubscriptionTerms: paymentRequest.paymentSubscriptionTerms,
          paymentPrivacyPolicy: paymentRequest.paymentPrivacyPolicy,
          subNumber: paymentRequest.subNumber,
          guid: paymentRequest.guid,
        });

        ApiService.markInvoiceAsRead(paymentRequest.invoiceId);
      })
      .catch((reason) => {
        if (reason === 404) {
          this.setState({ error: true });
        } else {
          this.setState({ genericError: true });
        }
        console.log(reason);
      });
  }

  calculateInvoiceTotal = (invoice) => {
    return invoice.timeEntries.reduce((sum, timeEntry) => {
      return sum + TimeEntryService.getTotalValue(timeEntry);
    }, 0);
  };

  onPayVLInvoice = async () => {
    var { invoice } = this.state;
    let query = QueryString.parse(this.props.location.search);

    try {
      let response = await ApiService.makePaymentLinkForRequest({
        requestGuid: query.code,
        amount: this.calculateInvoiceTotal(invoice),
        baseUrl:
          window.location.protocol +
          "//" +
          window.location.host +
          "/RegisterPayment?code=" +
          query.code,
      });
      if (!response.ok) {
        return false;
      }
      let link = await response.text();
      window.location.href = link;
      // window.open(link);
      return true;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  onRegisterCard = async () => {
    // var { invoice } = this.state;
    let query = QueryString.parse(this.props.location.search);

    try {
      let response = await ApiService.makeRegistrationLinkForRequest({
        requestGuid: query.code,
        baseUrl:
          window.location.protocol +
          "//" +
          window.location.host +
          "/RegisterPayment?code=" +
          query.code,
      });
      if (!response.ok) {
        return false;
      }
      let link = await response.text();
      window.location.href = link;
      // window.open(link);
      return true;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  onCancelRegistration = async () => {
    let query = QueryString.parse(this.props.location.search);

    let response = await ApiService.cancelRegistrationRequest(query.code);

    if (!response.ok) {
      return false;
    }

    this.setState({ paymentDate: null });

    return true;
  };

  // onPayPaylike = async () => {
  //   var { invoice, client } = this.state;

  //   let amount = this.calculateInvoiceTotal(invoice) * 100;

  //   this.paylike.popup(
  //     {
  //       currency: "DKK",
  //       amount: amount,
  //       title: invoice.invoiceType + invoice.number,
  //       fields: [
  //         {
  //           name: "name",
  //           label: getText("axoAccounting7", "Navn"),
  //           type: "string",
  //           required: true,
  //           value:
  //             client.firstName +
  //             (!!client.lastName ? " " + client.lastName : ""),
  //         },
  //         {
  //           name: "email",
  //           label: getText("socialSidebarContainerEmail", "Email"),
  //           type: "email",
  //           required: true,
  //           value: client.eMail,
  //         },
  //         {
  //           name: "address",
  //           label: getText("SignupAdresse", "Adresse"),
  //           type: "string",
  //           required: false,
  //           value: client.address || "",
  //         },
  //         {
  //           name: "postalCode",
  //           label: getText("axoidcode213", "Postnummer og by"),
  //           type: "string",
  //           required: false,
  //           value:
  //             (client.postalCode || "") +
  //             (!!client.city ? " " + client.city : ""),
  //         },
  //       ],
  //       custom: {
  //         invoice: invoice.invoiceType + invoice.number,
  //         lines: invoice.timeEntries.map((t) => t.description),
  //       },
  //     },
  //     async (err, res) => {
  //       if (err) {
  //         console.log(err);
  //         this.resolveRegistration(false);
  //         return;
  //       }

  //       let newInvoice = {
  //         ...invoice,
  //         status: 2, //Paid
  //       };

  //       let response = await ApiService.registerInvoicePayment(newInvoice.id);
  //       this.setState({
  //         invoice: newInvoice,
  //       });

  //       this.resolveRegistration(response.ok);
  //     }
  //   );

  //   return new Promise((resolve, reject) => {
  //     this.resolveRegistration = resolve;
  //     this.rejectRegistration = reject;
  //   });
  // };

  getIsRegistration = (invoice) => {
    return invoice.isRegistration;
    // if(!invoice.timeEntries || invoice.timeEntries.length === 0) {
    //   return true;
    // }

    // return invoice.timeEntries[0].rate === 0.00;
  };

  render() {
    var {
      client,
      invoice,
      showFullInvoice,
      subNumber,
      paymentDate,
      error,
      genericError,
      paymentTerms,
      paymentPrivacyPolicy,
      paymentSubscriptionTerms,
      guid,
    } = this.state;

    if (error) {
      return (
        <h1 className="text-center">
          <AxoLocal
            entity="paymentDate1a"
            defaultValue={"Opkrævningen kunne ikke findes."}
          />
        </h1>
      );
    } else if (genericError) {
      return (
        <h1 className="text-center">
          <AxoLocal
            entity="LoginshowServerError"
            defaultValue={"Der skete en fejl. Prøv igen senere."}
          />
        </h1>
      );
    }

    if (!client || !invoice) {
      return <LoadingIndicator />;
    }

    let query = QueryString.parse(this.props.location.search);

    if (this.getIsRegistration(invoice)) {
      return (
        <div className="standardMaxWidth center-block">
          <SubscriptionRegistrationView
            invoice={invoice}
            client={client}
            paymentDate={paymentDate}
            paymentSubscriptionTerms={paymentSubscriptionTerms}
            paymentPrivacyPolicy={paymentPrivacyPolicy}
            // cardInformation={this.props.cardInformation}
            onRegister={this.onRegisterCard}
            onCancel={this.onCancelRegistration}
            // onClose={this.onCloseInvoice}
          />
        </div>
      );
    }

    let style = { maxWidth: "990px", margin: "auto", position: "relative" };
    let isPaid = invoice.status === "Paid" || !!paymentDate;
    if (isPaid) {
      style.height = "100%";
    }

    return (
      <div style={style}>
        {showFullInvoice ? (
          <FullInvoiceCollectionView
            invoice={invoice}
            paymentDate={paymentDate}
            paymentTerms={paymentTerms}
            paymentPrivacyPolicy={paymentPrivacyPolicy}
            requestGuid={query.code}
            // cardInformation={this.props.cardInformation}
            // onPayInvoice={this.onPayPaylike}
            // onClose={this.onCloseInvoice}
          />
        ) : (
          <div>
            {!!subNumber ? (
              <h4 className="text-center">
                <AxoLocal entity="axoidcode64" defaultValue={"Nummer"} />:{" "}
                {invoice.invoiceNo || invoice.number} - {subNumber}
              </h4>
            ) : null}
            <h4 className="text-center">Ordrenummer: {guid}</h4>
            <h4 className="text-center">
              {client.firstName} {client.lastName}
            </h4>
            <h4 className="text-center">
              {client.address} {client.postalCode} {client.city}
            </h4>
            {this.renderPaymentView()}
          </div>
        )}
        {isPaid ? (
          <div style={{ position: "absolute", bottom: 10, fontSize: "12px" }}>
            © Axolex ApS
          </div>
        ) : (
          <div style={{ fontSize: "12px" }}>© Axolex ApS</div>
        )}
      </div>
    );
  }

  renderPaymentView = () => {
    var {
      // client,
      invoice,
      paymentDate,
      paymentTerms,
      paymentPrivacyPolicy,
    } = this.state;

    return (
      <VLCollectionView
        invoice={invoice}
        paymentDate={paymentDate}
        paymentTerms={paymentTerms}
        paymentPrivacyPolicy={paymentPrivacyPolicy}
        // cardInformation={this.props.cardInformation}
        onPayInvoice={this.onPayVLInvoice}
        // onClose={this.onCloseInvoice}
      />
    );
  };
}

class InvoicePaymentConsumer extends React.PureComponent {
  render() {
    return (
      <Consumer>
        {(store) => {
          if (!store.loaded) {
            return <LoadingIndicator />;
          }
          return (
            <InvoicePaymentContainer
              location={this.props.location}
              locale={store.locale}
            />
          );
        }}
      </Consumer>
    );
  }
}

export default withRouter(InvoicePaymentConsumer);
