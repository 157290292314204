import React from "react";
import ClientDocumentTableView from "../Documents/ClientDocumentTableView";
import AudioRecorder from "../../utilities/AudioRecorder";
import VideoRecorder from "../../utilities/VideoRecorder";
import { Grid, Row, Col } from "react-bootstrap";

import { DataActions } from "../../services/AxoServices";

import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class ClientDocumentRecordView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      recordedDocuments: [],
    };
  }

  onFileRecorded = (file) => {
    this.state.recordedDocuments.push(file.id);
    this.setState({ recordedDocuments: this.state.recordedDocuments });
  };

  getRecordedDocuments = () => {
    return this.props.documents.filter((f) =>
      this.state.recordedDocuments.find((id) => id === f.id)
    );
  };

  onToggleCards = () => {
    let { userSettings } = this.props;
    DataActions.updateUserSettings({
      ...userSettings,
      showDocumentCards: !userSettings.showDocumentCards,
    });
  };

  //Props
  //documents
  //labels
  //cases
  render() {
    var recordedDocuments = this.getRecordedDocuments();
    //Adds padding before the first panel when the table is not shown.
    var paddingStyle =
      recordedDocuments.length === 0 ? { paddingTop: "10px" } : null;
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} style={{ paddingLeft: "0px", paddingRight: "5px" }}>
            {recordedDocuments.length > 0 ? (
              <ClientDocumentTableView
                documents={this.getRecordedDocuments()}
                userProfile={this.props.userProfile}
                labels={this.props.labels}
                cases={this.props.cases}
                friends={this.props.friends}
                height={300}
                forceShowCards={this.props.forceShowCards}
                onToggleCards={this.onToggleCards}
                showUploadButton={false}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ paddingLeft: "0px", paddingRight: "5px" }}>
            <div style={paddingStyle}>
              <div>
                <div className="BanneDashboard">
                  <Grid fluid>
                    <Row>
                      <Col
                        xs={12}
                        style={{ paddingLeft: "0px", paddingRight: "5px" }}
                      >
                        <div
                          style={{ paddingLeft: "25px", paddingRight: "5px" }}
                        >
                          <h4>
                            <Icon glyph="icon-fontello-mic" />
                            &nbsp;&nbsp;&nbsp;
                            <AxoLocal
                              entity="DocumentRecordViewRecordsound"
                              defaultValue={"Optag lyd"}
                            />
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                </div>
                <br />
                <div>
                  <Grid fluid>
                    <Row>
                      <Col
                        xs={12}
                        style={{ paddingLeft: "0px", paddingRight: "5px" }}
                      >
                        <AudioRecorder onFileRecorded={this.onFileRecorded} />
                      </Col>
                    </Row>
                  </Grid>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ paddingLeft: "0px", paddingRight: "5px" }}>
            <div style={{ minHeight: "500px" }}>
              <div>
                <div className="BanneDashboard">
                  <Grid fluid>
                    <Row>
                      <Col
                        xs={12}
                        style={{ paddingLeft: "0px", paddingRight: "5px" }}
                      >
                        <div
                          style={{ paddingLeft: "23px", paddingRight: "0px" }}
                        >
                          <h4>
                            <Icon glyph="icon-fontello-videocam-2" />
                            &nbsp;&nbsp;&nbsp;
                            <AxoLocal
                              entity="DocumentRecordViewRecordVideo"
                              defaultValue={"Optag Video"}
                            />
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                </div>
                <br />
                <div style={{ minHeight: "500px" }}>
                  <Grid fluid>
                    <Row>
                      <Col
                        xs={12}
                        style={{ paddingLeft: "0px", paddingRight: "5px" }}
                      >
                        <VideoRecorder onFileRecorded={this.onFileRecorded} />
                      </Col>
                    </Row>
                  </Grid>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
