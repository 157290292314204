import React from "react";
import DataStore from "../../services/DataAccess/DataStore";
import DataActions from "../../services/DataAccess/DataActions";
import SortHeaderCell from "../../utilities/FixedDataTable/SortHeaderCell";
import DataListWrapper from "../../utilities/FixedDataTable/DataListWrapper";
import { Table, Column, Cell } from "fixed-data-table-2";
import TimeEntryOptionsStore from "../../services/TimeEntryOptionsStore";
import UserInfoService from "../../services/UserInfoService";

import moment from "moment";

import {
  Row,
  Col,
  Grid,
  FormGroup,
  FormControl,
  InputGroup,
} from "react-bootstrap";

import { ContactService } from "../../services/AxoServices";

import {
  AxoCheckbox,
  FileViewerModal,
  Icon,
  PaginationControls,
  UserSearchBox,
  TableBase,
  Dimensions,
  AxoLocal,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  select: {
    width: 100,
    shown: true,
  },
  fileName: {
    width: 250,
    shown: true,
  },
  uploadDate: {
    width: 220,
    shown: true,
  },
  clientString: {
    width: 220,
    shown: true,
  },
  sharedString: {
    width: 220,
    shown: true,
  },
  ownerString: {
    width: 220,
    shown: true,
  },
  actions: {
    width: 50,
    shown: true,
  },
};

class InvoicePDFsView extends TableBase {
  //Props
  //PDFs
  constructor(props) {
    super(props);
    this.name = "InvoicePDFsView";
    this.defaultColumnDefs = defaultColumnDefs;

    var tableEntries = this.addDataStringsToPDFs(props.PDFs);
    var shownPDFs = this.getShownPDFs(props.selectedOptionId, tableEntries);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(shownPDFs);
    this.state = {
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 250,
      selectedPDFs: new Set(),
      tableEntries: tableEntries,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, shownPDFs),
      colSortDirs: {},
      deletingPDFId: 0,
    };

    if (props.newPDF.id) {
      this.state.highlightedPDFId = props.newPDF.id;
      setTimeout(() => {
        this.setState({ highlightedPDFId: 0 });
      }, 10000);
    }
  }

  addDataStringsToPDFs = (PDFs) => {
    return PDFs.map((pdf) => {
      var sortDocument = Object.assign({}, pdf);
      sortDocument.sharedString =
        !sortDocument.owner &&
        sortDocument.sharedUsers &&
        sortDocument.sharedUsers.length > 0
          ? UserInfoService.getDisplayName(sortDocument.sharedUsers[0])
          : "";
      sortDocument.ownerString = sortDocument.owner
        ? sortDocument.owner.toString()
        : "";

      //client string is used for sorting
      sortDocument.clientString = sortDocument.invoice.client
        ? sortDocument.invoice.client.lastName +
          ", " +
          sortDocument.invoice.client.firstName
        : "No client";

      return sortDocument;
    });
  };

  componentDidMount() {
    TimeEntryOptionsStore.setTimeEntryOptions({
      clientsWithEntries: this.props.clientsWithPDFs,
      showCases: false,
      selectedOptionId: this.props.selectedOptionId,
      onSelectOption: this.onSelectOption,
    });
  }

  onSelectOption = (optionId) => {
    this.props.onSelectOption(optionId);
  };

  getClientPDFs = (selectedOptionId, PDFs) => {
    if (selectedOptionId === -1) {
      return PDFs;
    }
    var client = this.props.clientsWithPDFs.find(
      (cl) => cl.id === selectedOptionId
    );
    return this.getShownPDFsForClient(PDFs, client);
  };

  getShownPDFsForClient = (PDFs, client) => {
    if (!client) {
      return PDFs.filter((t) => !t.invoice.client || !t.invoice.client.id);
    }
    return PDFs.filter(
      (t) => t.invoice.client && t.invoice.client.id === client.id
    );
  };

  getShownPDFs = (selectedOptionId, PDFs) => {
    return this.getClientPDFs(selectedOptionId, PDFs);
  };

  componentWillReceiveProps(nextProps) {
    //Sort handling
    let tableEntries = this.addDataStringsToPDFs(nextProps.PDFs);
    let shownPDFs = this.getShownPDFs(nextProps.selectedOptionId, tableEntries);
    let filteredEntries = this.getFilteredPDFs(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);

    if (nextProps.selectedOptionId !== this.props.selectedOptionId) {
      //Reset the sort order when a new client is selected
      this.setState({
        sortedDataList: new DataListWrapper(
          this.generateDefaultSortIndexes(shownPDFs),
          shownPDFs
        ),
      });
    }
    //Highlight new PDF for 10 seconds
    if (nextProps.newPDF.id !== this.props.newPDF.id) {
      this.setState({
        highlightedPDFId: nextProps.newPDF.id,
      });
      setTimeout(() => {
        this.setState({ highlightedPDFId: 0 });
      }, 10000);
    }
    //Synchronize data with the sidebar
    TimeEntryOptionsStore.setTimeEntryOptions({
      clientsWithEntries: nextProps.clientsWithPDFs,
      showCases: false,
      selectedOptionId: nextProps.selectedOptionId,
      onSelectOption: this.onSelectOption,
    });
  }

  onTrashPDF = (PDFId) => {
    this.setState({ deletingPDFId: PDFId });
    let PDF = Object.assign(
      {},
      this.props.PDFs.find((pdf) => pdf.id === PDFId)
    );
    PDF.trashed = true;
    DataActions.updateDocument(PDF).then((response) => {
      if (!response.ok) {
        this.setState({ deletingPDFId: 0 });
      }
    });
  };

  onShowPDF = (PDF, event) => {
    event.preventDefault();
    event.stopPropagation();

    this.fileModal.open([PDF]);
  };

  _rowClassNameGetter = (rowIndex) => {
    var activePDF = this.state.sortedDataList.getObjectAt(rowIndex);
    if (activePDF && activePDF.id === this.state.highlightedPDFId) {
      return "highlight-row";
    }
  };

  onSearch = (event) => {
    let { tableEntries } = this.state;

    let PDFs = this.getFilteredPDFs(tableEntries, event.target.value);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(PDFs);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, PDFs),
    });
  };

  getFilteredPDFs = (entries, searchText) => {
    var shownPDFs = this.getShownPDFs(this.props.selectedOptionId, entries);
    if (!searchText) {
      return shownPDFs;
    }
    return shownPDFs.filter((f) => {
      return f.fileName.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  onSelectPDF = (documentId, event) => {
    let selectedPDFs = new Set(this.state.selectedPDFs);
    if (event.target.checked) {
      selectedPDFs.add(documentId);
    } else {
      selectedPDFs.delete(documentId);
    }
    this.setState({ selectedPDFs: selectedPDFs });
  };

  sharePDFsWithSelectedUser = (userProfile) => {
    this.state.selectedPDFs.forEach((documentId) => {
      let document = this.state.tableEntries.find((p) => p.id === documentId);
      if (!document) {
        return;
      }
      DataActions.shareFile({ document, userProfile });
    });
  };

  onRemoveUser = (userProfile, document) => {
    DataActions.removeFileSharing({ document, userProfile });
  };

  renderUserNodes = (document) => {
    if (!document.sharedUsers || document.owner) {
      return null;
    }
    return document.sharedUsers.map((p) => {
      return (
        <span
          key={p.id}
          style={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "white",
          }}
        >
          {UserInfoService.getDisplayName(p)}&nbsp;&nbsp;
          <Icon
            role="button"
            onClick={this.onRemoveUser.bind(this, p, document)}
            glyph="icon-fontello-scissors axored"
          />
        </span>
      );
    });
  };

  renderOwnerInfo = (document) => {
    if (!document.owner) {
      return null;
    }
    var owner = document.owner;
    return (
      <span
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "white",
        }}
      >
        <Icon
          className="editable"
          role="button"
          glyph="icon-fontello-share-1"
        />
        &ensp;&nbsp;&nbsp;
        {UserInfoService.getDisplayName(owner)}
      </span>
    );
  };

  renderClientName = (client) => {
    return ContactService.getContactAccountingName(client);
  };

  renderPDFsTable = () => {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth, documentMap } = this.props;
    var tableWidth = containerWidth - 25;
    var userProfiles = this.props.friends.concat(this.props.clientProfiles);

    let ActionButtons = this.ActionButtons;
    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <Col sm={4} className="flexbox-standard">
                  <h4>
                    <Icon glyph="icon-fontello-align-justify" />
                    &nbsp;&nbsp;{" "}
                    <AxoLocal
                      entity="InvoicePDFsViewInvoicePDFs"
                      defaultValue={"Faktura PDFer"}
                    />
                  </h4>
                  <PaginationControls
                    displayLength={sortedDataList._data.length}
                    length={documentMap.invoices.length}
                    count={documentMap.invoiceCount}
                    onShowMore={() => DataStore.increaseDocumentPageSize()}
                  />
                </Col>
                <Col sm={4}>
                  <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-search" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
          <div>
            <Grid fluid>
              <Row style={{ paddingLeft: "20px" }}>
                <Col
                  xs={12}
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "25px",
                    paddingBottom: "15px",
                  }}
                >
                  <Icon glyph="icon-fontello-spread" /> &nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="InvoicePDFsViewShareinvoicewithuser"
                    defaultValue={"Del faktura med bruger"}
                  />
                  &nbsp;&nbsp;
                  <div style={{ display: "inline-block" }}>
                    <UserSearchBox
                      userProfiles={userProfiles}
                      handleSelectedUser={this.sharePDFsWithSelectedUser}
                      disabled={this.state.selectedPDFs.size === 0}
                      inputBorder="1px solid #4CAF50"
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingLeft: "20px" }}>
                <Col xs={12} style={{ padding: "0px " }}>
                  <Table
                    rowHeight={40}
                    rowsCount={sortedDataList.getSize()}
                    rowClassNameGetter={this._rowClassNameGetter}
                    height={containerHeight}
                    width={tableWidth}
                    isColumnResizing={false}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    headerHeight={40}
                  >
                    <Column
                      columnKey="select"
                      header={<Cell></Cell>}
                      cell={(props) => {
                        var PDF = sortedDataList.getObjectAt(props.rowIndex);
                        return (
                          <Cell key={PDF.id} className="text-center" {...props}>
                            <AxoCheckbox
                              checked={this.state.selectedPDFs.has(PDF.id)}
                              onChange={this.onSelectPDF.bind(this, PDF.id)}
                            />
                          </Cell>
                        );
                      }}
                      width={columnDefs.select.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="fileName"
                      header={
                        <SortHeaderCell
                          altIcon={<Icon glyph="icon-fontello-sort-name-up" />}
                          showAltIcon={columnDefs.fileName.width < 75}
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.fileName}
                        >
                          <Icon
                            className="editable"
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="socialPrivateConversationBannerConferenceName"
                            defaultValue={"Navn"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var PDF = sortedDataList.getObjectAt(props.rowIndex);
                        return (
                          <Cell key={PDF.id} {...props}>
                            <a
                              href="ShowPDF"
                              onClick={this.onShowPDF.bind(this, PDF)}
                            >
                              {PDF.fileName}
                            </a>
                            {PDF.sharedUsers && PDF.sharedUsers.length > 0 ? (
                              <span>
                                &ensp;
                                <Icon
                                  className="editable"
                                  glyph="icon-fontello-share"
                                />
                              </span>
                            ) : null}
                          </Cell>
                        );
                      }}
                      width={columnDefs.fileName.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="uploadDate"
                      header={
                        <SortHeaderCell
                          altIcon={<Icon glyph="icon-fontello-calendar-8" />}
                          showAltIcon={columnDefs.uploadDate.width < 75}
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.uploadDate}
                        >
                          <Icon
                            className="editable"
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;{" "}
                          <AxoLocal
                            entity="DocumentTabViewcreationDate"
                            defaultValue={"Oprettelsesdato"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var PDF = sortedDataList.getObjectAt(props.rowIndex);
                        return (
                          <Cell key={PDF.id} {...props}>
                            {moment(PDF.uploadDate).format("L")}
                          </Cell>
                        );
                      }}
                      width={columnDefs.uploadDate.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="clientString"
                      header={
                        <SortHeaderCell
                          altIcon={<Icon glyph="icon-fontello-user" />}
                          showAltIcon={columnDefs.clientString.width < 75}
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.clientString}
                        >
                          <Icon
                            className="editable"
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="CaseEditFormclients"
                            defaultValue={"Klient"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var PDF = sortedDataList.getObjectAt(props.rowIndex);
                        return (
                          <Cell key={PDF.id} {...props}>
                            {PDF.invoice.client ? (
                              <span>
                                {this.renderClientName(PDF.invoice.client)}
                              </span>
                            ) : (
                              <span>
                                <AxoLocal
                                  entity="getStatusStringinvoicestatus"
                                  defaultValue="Ingen klient"
                                />
                              </span>
                            )}
                          </Cell>
                        );
                      }}
                      width={columnDefs.clientString.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="sharedString"
                      header={
                        <SortHeaderCell
                          altIcon={<Icon glyph="icon-fontello-share" />}
                          showAltIcon={columnDefs.sharedString.width < 75}
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.sharedString}
                        >
                          <Icon
                            className="editable"
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="DocumentTabViewSharedwiththeusers"
                            defaultValue={"Delt med brugerne"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var PDF = sortedDataList.getObjectAt(props.rowIndex);
                        return (
                          <Cell key={PDF.id} {...props}>
                            {this.renderUserNodes(PDF)}
                          </Cell>
                        );
                      }}
                      width={columnDefs.sharedString.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="ownerString"
                      header={
                        <SortHeaderCell
                          altIcon={<Icon glyph="icon-fontello-share" />}
                          showAltIcon={columnDefs.ownerString.width < 75}
                          onSortChange={this._onSortChange}
                          sortDir={colSortDirs.ownerString}
                        >
                          <Icon
                            className="editable"
                            glyph="icon-fontello-arrow-combo"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="DocumentTabViewSharedfromtheuser"
                            defaultValue={"Delt fra brugerne"}
                          />
                        </SortHeaderCell>
                      }
                      cell={(props) => {
                        var PDF = sortedDataList.getObjectAt(props.rowIndex);
                        return (
                          <Cell key={PDF.id} {...props}>
                            {this.renderOwnerInfo(PDF)}
                          </Cell>
                        );
                      }}
                      width={columnDefs.ownerString.width}
                      isResizable={true}
                    />
                    <Column
                      columnKey="actions"
                      header={<Cell></Cell>}
                      cell={(props) => {
                        var PDF = sortedDataList.getObjectAt(props.rowIndex);
                        return (
                          <Cell key={PDF.id} {...props}>
                            <ActionButtons
                              PDF={PDF}
                              deletingPDFId={this.state.deletingPDFId}
                              onTrashPDF={this.onTrashPDF}
                            />
                          </Cell>
                        );
                      }}
                      width={columnDefs.actions.width}
                      isResizable={true}
                    />
                  </Table>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  };

  ActionButtons = (props) => {
    var { PDF, deletingPDFId, onTrashPDF } = props;
    if (PDF.owner) {
      return null;
    }
    if (deletingPDFId === PDF.id) {
      return (
        <img
          alt=""
          src="/imgs/app/loading.gif"
          className="img-circle"
          width="20"
          height="20"
        />
      );
    }
    return (
      <Icon
        className="editable"
        role="button"
        onClick={onTrashPDF.bind(this, PDF.id)}
        glyph="icon-fontello-trash-1"
      />
    );
  };

  render() {
    return (
      <div style={{ paddingTop: "10px" }}>
        {this.renderPDFsTable()}
        <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
      </div>
    );
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 275;
  },
})(InvoicePDFsView);
