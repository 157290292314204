import React from "react";
import MediaCapturer from "./MediaCapture";
import ApiService from "../services/DataAccess/ApiService";
import DataStore from "../services/DataAccess/DataStore";
import Counter from "./Counter";
import { AxoLocal } from "./LexUtilities";

import { Grid, Row, Col, Button, ButtonToolbar } from "react-bootstrap";

import { Icon } from "../utilities/LexUtilities";

var isChrome = false;
if (typeof window !== "undefined") {
  navigator.getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;

  isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}

class VideoRecorder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      granted: false,
      rejectedReason: "",
      recording: false,
      paused: false,
      videoSource: "",
      file: null,
      counterSeconds: 0,
      cameraEnabled: true,
      showSaveButton: false,
    };

    this.handleGranted = this.handleGranted.bind(this);
    this.handleDenied = this.handleDenied.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleResume = this.handleResume.bind(this);
    this.setStreamToVideo = this.setStreamToVideo.bind(this);
    this.releaseStreamFromVideo = this.releaseStreamFromVideo.bind(this);
    this.saveVideo = this.saveVideo.bind(this);
  }

  componentDidMount() {
    if (!isChrome) {
      return;
    }
    //get user camera
    var constraints = { video: true, audio: false };

    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.handleStream)
        .catch(this.handleStreamError);
    } else if (navigator.getUserMedia) {
      navigator.getUserMedia(
        constraints,
        this.handleStream,
        this.handleStreamError
      );
    }
  }

  componentWillUnmount() {
    this.stopCounter();
  }

  handleStream = (stream) => {
    stream.stop();
    this.setState({ cameraEnabled: true });
  };

  handleStreamError = (error) => {
    this.setState({ cameraEnabled: false });
  };

  handleGranted() {
    this.setState({ granted: true });
  }

  handleDenied(err) {
    this.setState({ rejectedReason: err.name });
  }

  handleStart(stream) {
    this.setState({
      recording: true,
    });
    this.setStreamToVideo(stream);
    this.startCounter();
  }

  startCounter = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.setState({
        counterSeconds: this.state.counterSeconds + 1,
      });
    }, 1000);
    this.setState({ running: true });
  };

  handleStop(blob) {
    this.setState({
      recording: false,
    });

    this.releaseStreamFromVideo();
    this.saveVideo(blob);

    this.stopCounter();
    this.resetCounter();
  }

  resetCounter = () => {
    this.setState({ counterSeconds: 0 });
  };

  stopCounter = () => {
    clearInterval(this.timer);
  };

  handlePause() {
    this.setState({
      paused: true,
    });
    this.stopCounter();
  }

  handleResume(stream) {
    this.setState({
      paused: false,
    });
    this.startCounter();
  }

  handleError(err) {
    console.log(err);
  }

  setStreamToVideo(stream) {
    this.videoStreamPlayer.srcObject = stream;
  }

  releaseStreamFromVideo() {
    this.videoStreamPlayer.srcObject = null;
  }

  saveVideo(blob) {
    let url = URL.createObjectURL(blob);
    var file = new File([blob], "NewVideo.mp4", { type: "video/webm" });
    this.setState(
      {
        videoSource: url,
        file: file,
      },
      () => {
        this.onSaveFile();
      }
    );
  }

  onSaveFile = () => {
    if (!this.state.file) {
      return;
    }

    ApiService.uploadFileObjects([this.state.file])
      .then((response) => {
        if (response.status === 200) {
          response.json().then((newFiles) => {
            DataStore.fetchDocuments();
            this.props.onFileRecorded(newFiles[0]);
          });
          this.setState({ showSaveButton: false });
        } else {
          this.setState({ showSaveButton: true });
        }
      })
      .catch((error) => {
        this.setState({ showSaveButton: true });
      });
  };

  render() {
    const recording = this.state.recording;
    const paused = this.state.paused;
    if (
      !isChrome ||
      (!navigator.mediaDevices && !navigator.getUserMedia) ||
      !window.MediaRecorder
    ) {
      return (
        <div style={{ paddingLeft: "10px" }}>
          <h4>
            <AxoLocal
              entity="AudioRecordervideoavigator"
              defaultValue={"Din browser understøtter ikke videooptagelse."}
            />
          </h4>
          <h4>
            <AxoLocal
              entity="AudioRecorderSwitchtoGoogleChrome"
              defaultValue={
                "Skift til Google Chrome hvis du ønsker at anvende denne funktionalitet."
              }
            />{" "}
          </h4>
        </div>
      );
    }
    if (!this.state.cameraEnabled) {
      return (
        <div style={{ paddingLeft: "10px" }}>
          <h4>
            <AxoLocal
              entity="AudioRecordercameraEnabled"
              defaultValue={"Kameraet kunne ikke findes."}
            />
          </h4>
          <h4>
            <AxoLocal
              entity="AudioRecorderConnectacameraandreload"
              defaultValue={"Tilslut et kamera og genindlæs siden."}
            />
          </h4>
        </div>
      );
    }
    return (
      <div ref="app">
        <MediaCapturer
          constraints={{ audio: true, video: true }}
          timeSlice={10}
          onGranted={this.handleGranted}
          onDenied={this.handleDenied}
          onStart={this.handleStart}
          onStop={this.handleStop}
          onPause={this.handlePause}
          onResume={this.handleResume}
          onError={this.handleError}
          render={({ start, stop, pause, resume }) => (
            <Grid fluid>
              <Row>
                <Col xs={12} className="text-center">
                  <ButtonToolbar style={{ display: "inline-block" }}>
                    {!recording ? (
                      <Button className="Lex-button-2" onClick={start}>
                        <Icon glyph="icon-fontello-play-1" />
                        &nbsp;&nbsp;&nbsp;
                        <AxoLocal
                          entity="AudioRecorderStartnyoptagelse"
                          defaultValue={"Start ny optagelse"}
                        />
                      </Button>
                    ) : null}
                    {recording ? (
                      <Button className="Lex-button-2" onClick={stop}>
                        <Icon glyph="icon-fontello-stop-3" />
                        &nbsp;&nbsp;&nbsp;{" "}
                        <AxoLocal
                          entity="AudioRecorderStop"
                          defaultValue={"Stop"}
                        />
                      </Button>
                    ) : null}
                    {recording && !paused ? (
                      <Button className="Lex-button-2" onClick={pause}>
                        <Icon glyph="icon-fontello-pause-3" />
                        &nbsp;&nbsp;&nbsp;{" "}
                        <AxoLocal
                          entity="AudioRecorderPause"
                          defaultValue={"Pause"}
                        />
                      </Button>
                    ) : null}
                    {recording && paused ? (
                      <Button className="Lex-button-2" onClick={resume}>
                        <Icon glyph="icon-fontello-play-3" />
                        &nbsp;&nbsp;&nbsp;{" "}
                        <AxoLocal
                          entity="axoidcode108"
                          defaultValue={"Genoptag"}
                        />
                      </Button>
                    ) : null}
                    {this.state.showSaveButton &&
                    !recording &&
                    this.state.file ? (
                      <Button
                        className="Lex-button-2"
                        onClick={this.onSaveFile}
                      >
                        <Icon glyph="icon-fontello-floppy-1" />
                        &nbsp;&nbsp;&nbsp;{" "}
                        <AxoLocal
                          entity="AudioRecorderSaverecording"
                          defaultValue={"Gem optagelse"}
                        />
                      </Button>
                    ) : null}
                    {recording ? (
                      <Button className="Lex-button-2">
                        <Counter seconds={this.state.counterSeconds} />
                      </Button>
                    ) : null}
                  </ButtonToolbar>
                </Col>
              </Row>

              <Row style={{ paddingTop: "15px" }}>
                <Col xs={12} className="text-center">
                  <div style={{ display: "inline-block" }}>
                    <video
                      style={{
                        display: recording || paused ? "block" : "none",
                      }}
                      muted
                      autoPlay
                      ref={(c) => (this.videoStreamPlayer = c)}
                    ></video>
                    {!recording && this.state.videoSource ? (
                      <video
                        controls
                        ref={(c) => (this.videoPlayer = c)}
                        src={this.state.videoSource}
                      ></video>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Grid>
          )}
        />
      </div>
    );
  }
}

export default VideoRecorder;
