import React from "react";
import TinyMCEEditor from "../../utilities/Editors/TinyMCEEditor";
import DataActions from "../../services/DataAccess/DataActions";
import ModalService from "../../services/ModalService";
import Conditional from "../../utilities/Conditional";
import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  FormControl,
  ButtonGroup,
  ControlLabel,
} from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class DocumentTemplateCreateView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTemplate: {
        title: "",
        type: "",
        content: "",
        draft: true,
      },
      showTitleValidationWarning: false,
      isGeneratingPDF: false,
    };
    this.inProgress = true;
  }

  componentWillUnmount() {
    if (!this.inProgress) {
      return;
    }
    this.saveTemplate(() => {});
  }

  onContentChange = (content) => {
    var documentTemplate = Object.assign({}, this.state.documentTemplate);
    documentTemplate.content = content;
    this.setState({ documentTemplate });
  };

  onTemplatePropertyChange = (propertyName, event) => {
    var documentTemplate = Object.assign({}, this.state.documentTemplate);
    documentTemplate[propertyName] = event.target.value;
    this.setState({ documentTemplate });
  };

  onSave = () => {
    var documentTemplate = Object.assign({}, this.state.documentTemplate);
    documentTemplate.draft = false;
    this.setState({ documentTemplate }, () => {
      this.saveTemplate(this.props.onCreateTemplate);
    });
  };

  onSaveAndGeneratePDF = () => {
    var documentTemplate = Object.assign({}, this.state.documentTemplate);
    documentTemplate.draft = false;
    this.setState(
      {
        documentTemplate,
        isGeneratingPDF: true,
      },
      () => {
        this.saveTemplate((newTemplate) => {
          this.generatePDF(newTemplate);
        });
      }
    );
  };

  generatePDF = (documentTemplate) => {
    DataActions.generateTemplatePdf(documentTemplate.id)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((newPDF) => {
            this.props.onCreatePDF(newPDF);
            this.setState({ isGeneratingPDF: false });
          });
        } else {
          this.showPDFGenerationError();
        }
      })
      .catch((reason) => {
        this.showPDFGenerationError();
      });
  };

  showPDFGenerationError = () => {
    this.setState({ isGeneratingPDF: false });
    ModalService.openAlertModal(
      <AxoLocal
        entity="DocumentTemplateCreateViewisGeneratingPDF"
        defaultValue={"PDF`en kunne ikke genereres. Prøv igen senere."}
      />
    );
  };

  saveTemplate = (callback) => {
    if (!this.state.documentTemplate.title) {
      this.setState({ showTitleValidationWarning: true });
      return;
    }
    DataActions.createTemplate(this.state.documentTemplate).then((response) => {
      if (response.ok) {
        this.inProgress = false;
        return response.json().then((newTemplate) => {
          callback(newTemplate);
        });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="DocumentTemplateresponse"
            defaultValue={"Skabelonen kunne ikke gemmes. Prøv igen senere."}
          />
        );
      }
    });
  };

  renderButtons = () => {
    var { isGeneratingPDF } = this.state;

    return (
      <Row style={{ textAlign: "right" }}>
        <ButtonGroup>
          <Button className="Lex-button-2" onClick={this.onSave}>
            <Icon glyph="icon-fontello-layers" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="DocumentTemplateSaveastemplate"
              defaultValue={"Gem som skabelon"}
            />
          </Button>
          <Button
            disabled={isGeneratingPDF}
            className="Lex-button-2"
            onClick={this.onSaveAndGeneratePDF}
          >
            {this.renderGenerateButtonContent()}
          </Button>
        </ButtonGroup>
      </Row>
    );
  };

  renderGenerateButtonContent = () => {
    if (this.state.isGeneratingPDF) {
      return (
        <span>
          <Icon glyph="icon-fontello-acrobat" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="DocumentTemplateGeneraisGenerating"
            defaultValue={"Genererer..."}
          />{" "}
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        </span>
      );
    } else {
      return (
        <span>
          <Icon glyph="icon-fontello-acrobat" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="DocumentTemplateGenerateButtonContent"
            defaultValue={"Gem og generer PDF"}
          />
        </span>
      );
    }
  };

  onTemplateTitleChange = (event) => {
    if (this.state.showTitleValidationWarning) {
      this.setState({ showTitleValidationWarning: false });
    }
    this.onTemplatePropertyChange("title", event);
  };

  //Props
  render() {
    return (
      <Grid fluid style={{ paddingTop: "25px" }}>
        <Row>
          <Col md={6}>
            <Form horizontal>
              <FormGroup>
                <Col componentClass={ControlLabel} sm={1}>
                  <AxoLocal
                    entity="socialSidebarContainerNameLabel"
                    defaultValue="Navn"
                  />
                  :
                </Col>
                <Col sm={11}>
                  <FormControl
                    style={{ maxWidth: "500px" }}
                    type="text"
                    value={this.state.documentTemplate.title}
                    onChange={this.onTemplateTitleChange}
                  />
                  <Conditional show={this.state.showTitleValidationWarning}>
                    <AxoLocal
                      className="axored"
                      entity="DocumentTemplateCreateTitleValidationWarning"
                      defaultValue={"The template must have a name"}
                    />
                  </Conditional>
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <Col md={6}>
            <Form horizontal>
              <FormGroup>
                <Col componentClass={ControlLabel} sm={1}>
                  <AxoLocal
                    entity="DocumentGridViewType"
                    defaultValue={"Type"}
                  />
                </Col>
                <Col sm={11}>
                  <FormControl
                    style={{ maxWidth: "500px" }}
                    type="text"
                    value={this.state.documentTemplate.type}
                    onChange={this.onTemplatePropertyChange.bind(this, "type")}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this.renderButtons()}
        <Row>
          <Col xs={12} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <TinyMCEEditor
              text={""}
              locale={this.props.locale}
              onTextChange={this.onContentChange}
              documentMap={this.props.documentMap}
              allowImageImport
            />
          </Col>
        </Row>
        {this.renderButtons()}
      </Grid>
    );
  }
}
