import React from "react";
import PresentationActions from "../../../marketing/services/PresentationActions";
import AdminList from "../../../marketing/AdminList/AdminList";
import { withRouter } from "react-router-dom";

import { DataStore, RoutingService } from "../../../services/AxoServices";

class LegalPresentationListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      presentations: [],
      userProfile: {},
      deletingPresentation: 0,
      query: "",
      shown: 10,
    };
    this.name = "LegalPresentationListContainer";
  }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      let sortedPresentations = store.legalPresentations.sort((c1, c2) => {
        return (
          new Date(c2.creationDate).getTime() -
          new Date(c1.creationDate).getTime()
        );
      });
      this.setState({
        presentations: sortedPresentations,
        userProfile: store.userProfile,
      });
    });
    DataStore.fetchLegalPresentations();
    DataStore.initializeUserProfile();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  selectPresentation = (presentationId) => {
    this.props.history.push(
      RoutingService.getPath("Admin/Presentation/") + presentationId
    );
  };

  onTogglePublic = (presentationId) => {
    var presentation = this.state.presentations.find(
      (p) => p.id === presentationId
    );
    if (!presentation) {
      return;
    }
    var newPresentation = Object.assign({}, presentation);
    newPresentation.public = !presentation.public;
    PresentationActions.updatePresentation(newPresentation);
  };

  onDeletePresentation = (presentationId) => {
    this.setState({ deletingPresentation: presentationId });
    PresentationActions.deletePresentation(presentationId)
      .then((response) => {
        if (response.ok) {
          this.setState({ deletingPresentation: 0 });
        } else {
          return Promise.reject("The presentation could not be deleted.");
        }
      })
      .catch((reason) => {
        this.setState({ deletingPresentation: 0 });
        console.log(reason);
      });
  };

  onSearch = (event) => {
    this.setState({ query: event.target.value });
  };

  getFilteredPresentations = () => {
    var query = this.state.query.toLocaleLowerCase();
    if (!query) {
      return this.state.presentations;
    }
    return this.state.presentations.filter((p) => {
      return p.items.find(
        (i) =>
          i.title.toLowerCase().includes(query) ||
          i.description.toLowerCase().includes(query)
      );
    });
  };

  onShowMore = () => {
    this.setState({
      shown: this.state.shown + 10,
    });
  };

  render() {
    var presentations = this.getFilteredPresentations();
    return (
      <AdminList
        presentations={presentations}
        userProfile={this.state.userProfile}
        selectPresentation={this.selectPresentation}
        onTogglePublic={this.onTogglePublic}
        onDeletePresentation={this.onDeletePresentation}
        deletingPresentation={this.state.deletingPresentation}
        query={this.props.query}
        onSearch={this.onSearch}
        shown={this.state.shown}
        onShowMore={this.onShowMore}
        includeControls
      />
    );
  }
}

export default withRouter(LegalPresentationListContainer);
