import React from "react";
import { withRouter } from "react-router-dom";
import MailView from "./MailView";

import {
  RoutingService,
  ApiService,
  DataStore,
  DataActions,
  MessageService,
} from "../../services/AxoServices";

import {
  LoadingIndicator,
  withRTKMessageData,
} from "../../utilities/LexUtilities";

const ImageDisplayStatus = {
  NONE: 0,
  CONFIRM: 1,
  SHOW: 2,
};

class MailContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      messages: null,
      externalMails: [],
      labelMessages: [],
      labels: [],
      message: null,
      selectedAttachmentId: 0,
      imageDisplayStatus: ImageDisplayStatus.NONE,
      completeMessageBody: "",
      trustedEmails: [],
    };
    this.fetchedMessageContainer = false;
    this.messageId = parseInt(this.props.match.params.id, 10);
    this.name = "MailContainer";
  }

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      let messageLabels = store.labels.filter((l) => l.type === 0);

      this.setState({
        userName: store.userProfile.userName,
        userProfile: store.userProfile,

        labels: messageLabels,
        trustedEmails: store.trustedEmails,
      });
    });

    let { messageMap } = this.props;
    this.setState({
      messages: this.getCurrentMessages(messageMap),
      // count: this.getCurrentMessageCount(messageCounter),
    });

    DataStore.initializeUserProfile();
    DataStore.initializeLabels();
  }

  componentDidUpdate(prevProps, prevState) {
    let { message, messages } = this.state;
    if (
      !!messages &&
      (!prevState.messages || messages.length !== prevState.messages.length)
    ) {
      this.loadMessage();
    }

    let newId = parseInt(this.props.match.params.id, 10);
    if (newId !== this.messageId) {
      this.messageId = newId;
      this.loadMessage();
    }

    let { messageMap } = this.props;
    let newMessages = this.getCurrentMessages(messageMap);
    if (messages.length !== newMessages.length) {
      this.setState({
        messages: newMessages,
      });
    }

    let { singleMessage } = this.props;
    if (
      (!message || !message.messageText) &&
      singleMessage.id === this.messageId
    ) {
      let fullMessage = { ...singleMessage };
      fullMessage.messageText = MessageService.processMessageText(
        fullMessage.messageText
      );
      if (this.state.trustedEmails.includes(fullMessage.externalSender)) {
        this.setState({ message: fullMessage });
        return;
      }

      let bodyWithoutImages = MessageService.removeExternalImages(
        fullMessage.messageText
      );
      if (bodyWithoutImages.length !== fullMessage.messageText.length) {
        this.setState({
          imageDisplayStatus: ImageDisplayStatus.CONFIRM,
          completeMessageBody: fullMessage.messageText,
        });
        fullMessage.messageText = bodyWithoutImages;
      }

      this.setState({ message: fullMessage });
    }
  }

  loadMessage = () => {
    let { messageQuery } = this.props;

    var {
      messages,
      // count,
    } = this.state;

    if (!messages) {
      return;
    }
    //If the full message has been loaded, do not show the preview
    if (
      this.state.message &&
      this.state.message.id === this.messageId &&
      this.state.message.messageText
    ) {
      return;
    }
    let message = messages.find((m) => m.id === this.messageId);

    if (messageQuery.isFetching && !message) {
      return;
    }
    this.setState({
      message,
    });

    // let messageIndex = this.findMessageIndex();
    //If we are at the end of the vector and not all messages have been loaded, load another batch of messages.
    //Return since the full message will be fetched again later.
    // if(messageIndex === -1 || (!searchText && !!count && count > messages.length && messageIndex >= messages.length - 1)) {
    //   DataStore.increaseMessagePageSize();
    //   return;
    // }
    let { setMessageId } = this.props;
    setMessageId(this.messageId);
    //Retrieve the full message
    // ApiService.getMessage(this.messageId)
    // .then(fullMessage => {
    //   fullMessage.messageText = MessageService.processMessageText(fullMessage.messageText);
    //   if(this.state.trustedEmails.includes(fullMessage.externalSender)) {
    //     this.setState({ message: fullMessage });
    //     return;
    //   }

    //   let bodyWithoutImages = MessageService.removeExternalImages(fullMessage.messageText);
    //   if(bodyWithoutImages.length !== fullMessage.messageText.length) {
    //     this.setState({
    //       imageDisplayStatus: ImageDisplayStatus.CONFIRM,
    //       completeMessageBody: fullMessage.messageText
    //     });
    //     fullMessage.messageText = bodyWithoutImages;
    //   }

    //   this.setState({ message: fullMessage });
    // })
  };

  getPath = (path) => {
    return RoutingService.getPath(path);
  };

  getCurrentMessages = (messageMap) => {
    let shown = this.props.match.params.shown;
    if (shown === "inbox") {
      return messageMap.received;
    }
    if (shown === "spam") {
      return messageMap.spam;
    }
    if (shown === "important") {
      return messageMap.important;
    }
    if (shown === "sent") {
      return messageMap.sent;
    }
    if (shown === "drafts") {
      return messageMap.drafts;
    }
    if (shown === "trash") {
      return messageMap.trashed;
    } else if (shown.includes("account")) {
      let accountIds = shown.match("\\d+");
      return messageMap.received.filter(
        (m) => m.externalAccountId === parseInt(accountIds[0], 10)
      );
    } else if (shown.includes("label")) {
      let { labels } = this.state;
      if (!labels) {
        return;
      }
      let labelIds = shown.match("\\d+");
      let label = this.state.labels.find(
        (l) => l.id === parseInt(labelIds[0], 10)
      );
      if (!label) {
        return [];
      }
      var labelMessages = messageMap.received.filter(
        (m) => !!label.messageIds.includes(m.id)
      );
      if (
        labelMessages.length < label.messageIds.length &&
        !this.fetchedMessageContainer
      ) {
        this.fetchedMessageContainer = true;
        ApiService.getSingleLabel(label.id).then((fullLabel) => {
          this.setState(
            {
              messages: fullLabel.messages,
            },
            () => {
              this.loadMessage();
            }
          );
        });
      }
      return messageMap.received.filter(
        (m) => !!label.messageIds.includes(m.id)
      );
    } else if (shown.includes("caseModel-")) {
      console.log("test");
      let caseModelIds = shown.match("\\d+");
      if (caseModelIds.length === 0) {
        return;
      }
      let caseModelId = caseModelIds[0];
      let messages = messageMap.messages.filter(
        (m) => m.caseModelId === caseModelId
      );
      if (!this.fetchedMessageContainer) {
        //Fetch the case, to make sure we have all the messages
        this.fetchedMessageContainer = true;
        ApiService.getCaseModel(caseModelId).then((fullCaseModel) => {
          this.setState(
            {
              messages: fullCaseModel.messages,
            },
            () => {
              this.loadMessage();
            }
          );
        });
      }

      return messages;
    }
  };

  // getCurrentMessageCount = (messageMap) => {
  //   let shown = this.props.match.params.shown;
  //   if(shown === "inbox") {
  //     return messageMap.receivedCount;
  //   }
  //   if(shown === "spam") {
  //     return messageMap.spamCount;
  //   }
  //   if(shown === "important") {
  //     return messageMap.importantCount;
  //   }
  //   if(shown === "sent") {
  //     return messageMap.sentCount;
  //   }
  //   if(shown === "drafts") {
  //     return 0;
  //   }
  //   if(shown === "trash") {
  //     return messageMap.trashedCount;
  //   }
  //   else {
  //     return 0;
  //   }
  // }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  markExternalAsRead = (message) => {
    DataActions.updateExternalMails({
      accountId: message.externalAccountId,
      messageIds: [message.externalUniqueId],
      markAsRead: true,
      isSent: !!(
        message.sender && message.sender.id === this.state.userProfile.id
      ),
      isSpam: message.spam,
    });
  };

  deleteExternal = (message) => {
    return DataActions.updateExternalMails({
      accountId: message.externalAccountId,
      messageIds: [message.externalUniqueId],
      delete: true,
      isSent: !!(
        message.sender && message.sender.id === this.state.userProfile.id
      ),
      isSpam: message.spam,
    });
  };

  sortByDate = (m1, m2) => {
    return new Date(m1.date) - new Date(m2.date);
  };

  findMessageIndex = () => {
    var messageIndex = this.state.messages.findIndex((message) => {
      return message.id === this.messageId;
    });
    return messageIndex;
  };

  onListBack = () => {
    let messageIndex = this.findMessageIndex();
    if (messageIndex < 1) {
      return;
    }
    var newMessage = this.state.messages[messageIndex - 1];
    this.goToMessage(newMessage.id);
  };

  onListFront = () => {
    if (this.state.messages.length === 0) {
      return;
    }
    var newMessage = this.state.messages[0];
    this.goToMessage(newMessage.id);
  };

  onListForward = () => {
    let messageIndex = this.findMessageIndex();
    if (messageIndex < 0) {
      return;
    }
    if (messageIndex > this.state.messages.length - 2) {
      return;
    }
    var newMessage = this.state.messages[messageIndex + 1];
    this.goToMessage(newMessage.id);
  };

  goToMessage = (messageId) => {
    this.setState({
      selectedAttachmentId: 0,
      imageDisplayStatus: ImageDisplayStatus.NONE,
      completeMessageBody: "",
    });
    this.props.history.push(
      this.getPath(
        "mailbox/mail/" + this.props.match.params.shown + "/" + messageId
      )
    );
  };

  onSelectAttachment = (id) => {
    this.setState((prevState) => ({
      selectedAttachmentId: prevState.selectedAttachmentId !== id ? id : 0,
    }));
  };

  onShowExternalImages = () => {
    this.setState((prevState) => ({
      imageDisplayStatus: ImageDisplayStatus.SHOW,
      message: {
        ...prevState.message,
        messageText: prevState.completeMessageBody,
      },
    }));
  };

  onTrustSender = () => {
    this.onShowExternalImages();
    DataStore.setTrustedEmails([
      ...this.state.trustedEmails,
      this.state.message.externalSender,
    ]);
  };

  render() {
    var { userProfile, messages, message } = this.state;

    if (!messages || !message) {
      return <LoadingIndicator />;
    }

    if (!userProfile || !messages) {
      return null;
    }

    let shown = this.props.match.params.shown;

    return (
      <div>
        <MailView
          {...this.props}
          {...this.state}
          messages={messages}
          messageIndex={this.findMessageIndex()}
          shown={shown}
          deleteExternal={this.deleteExternal}
          markExternalAsRead={this.markExternalAsRead}
          onListBack={this.onListBack}
          onListFront={this.onListFront}
          onListForward={this.onListForward}
          onSelectAttachment={this.onSelectAttachment}
          onShowExternalImages={this.onShowExternalImages}
          onTrustSender={this.onTrustSender}
        />
      </div>
    );
  }
}

export default withRTKMessageData(withRouter(MailContainer));
