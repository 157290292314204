import React from "react";

import { Consumer } from "../utilities/LexUtilities";

export default class InfoText extends React.PureComponent {
  render() {
    return (
      <Consumer>
        {(store) => (
          <span>
            {store.userSettings.showInfoTexts ? (
              <span>{this.props.children}</span>
            ) : null}
          </span>
        )}
      </Consumer>
    );
  }
}
