import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import FileInfoService from "../../services/FileInfoService";

import { Large, MediumOrSmaller } from "../../utilities/Responsive";

import {
  Row,
  Col,
  Grid,
  FormControl,
  ControlLabel,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { Icon, FileViewerModal, AxoLocal } from "../../utilities/LexUtilities";

class FileCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //Props
  //socialFile
  //userProfile
  onDeleteFile = () => {
    this.props.onDeleteSocialFile(this.props.socialFile);
  };

  onClickFile = () => {
    this.fileModal.open([this.props.socialFile]);
  };

  renderFileName = () => {
    var socialFile = this.props.socialFile;
    var userProfile = this.props.userProfile;

    var nameString = socialFile.fileName;
    nameString =
      nameString.length > 20 ? nameString.substring(0, 20) + "..." : nameString;

    if (
      userProfile.profileImage &&
      userProfile.profileImage.id === socialFile.id
    ) {
      return (
        <span>
          {nameString}{" "}
          <AxoLocal
            entity="socialFilesViewProfileImage"
            defaultValue={"(Profilbillede)"}
          />
        </span>
      );
    }
    if (userProfile.banner && userProfile.banner.id === socialFile.id) {
      return (
        <span>
          {nameString}{" "}
          <AxoLocal entity="socialFilesViewBanner" defaultValue={"(Banner)"} />
        </span>
      );
    }
    return <span>{nameString}</span>;
  };

  renderContent = () => {
    var socialFile = this.props.socialFile;
    if (FileInfoService.isImage(socialFile)) {
      return (
        <div style={{ height: "200px" }}>
          <div className="editable" role="button" onClick={this.onClickFile}>
            <strong>{this.renderFileName()}</strong>
          </div>
          <div>
            <img
              alt=""
              role="button"
              onClick={this.onClickFile}
              style={{
                maxWidth: "100%",
                maxHeight: "150px",
                display: "block",
                margin: "auto",
              }}
              src={ApiService.getFileInlinePath(socialFile.id)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="editable" role="button" onClick={this.onClickFile}>
          <strong>{this.renderFileName()}</strong>
        </div>
      );
    }
  };

  render() {
    var contact = this.props.contact;
    return (
      <div
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "lightgrey",
          marginBottom: "2px",
          background: "white",
          boxShadow: "2px 2px 1px #E5EFFB",
        }}
      >
        <Grid fluid>
          <Row>
            <Col xs={12}>
              {this.renderContent()}
              <span className="pull-right">
                <Icon
                  role="button"
                  className="editable"
                  onClick={this.onDeleteFile.bind(this, contact)}
                  glyph="icon-fontello-scissors axored"
                ></Icon>
                &nbsp;&nbsp;&nbsp;
                <AxoLocal
                  className="editable"
                  entity="socialFilesViewDeleteFile"
                  defaultValue={"Slet fil"}
                />
              </span>
            </Col>
          </Row>
        </Grid>
        <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
      </div>
    );
  }
}

export default class SocialFilesView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
  }

  generateContactCols = (socialFiles) => {
    var cols = [[], [], []];
    var smallCols = [[], []];

    for (var i = 0; i < socialFiles.length; i++) {
      var fileCard = (
        <FileCard
          key={socialFiles[i].id}
          socialFile={socialFiles[i]}
          userProfile={this.props.userProfile}
          onDeleteSocialFile={this.props.onDeleteSocialFile}
        />
      );

      cols[i % 3].push(fileCard);
      smallCols[i % 2].push(fileCard);
    }
    return [cols, smallCols];
  };

  renderFilesGrid = (socialFiles) => {
    var cols = this.generateContactCols(socialFiles);
    var largeCols = cols[0];
    var smallCols = cols[1];
    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col lg={4} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {largeCols[0]}
            </Col>
            <Col lg={4} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {largeCols[1]}
            </Col>
            <Col lg={4} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col md={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col md={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  onSearch = (event) => {
    this.setState({ searchText: event.target.value });
  };

  getFiles = () => {
    var searchText = this.state.searchText;
    if (!searchText) {
      return this.props.socialFiles;
    }
    return this.props.socialFiles.filter((c) => {
      return c.fileName.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  render() {
    return (
      <Row>
        <Col xs={12}>
          <div>
            <div>
              <div>
                <Grid fluid style={{ minHeight: "800px" }}>
                  <Row style={{ paddingLeft: "20px" }}>
                    <Col
                      xs={12}
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <div style={{ maxWidth: "600px", paddingBottom: "3px" }}>
                        <FormGroup controlId="inputWithIcon">
                          <ControlLabel>
                            <AxoLocal
                              entity="socialFilesViewFindImage"
                              defaultValue={"Find billede"}
                            />
                          </ControlLabel>
                          <InputGroup>
                            <InputGroup.Addon>
                              <Icon glyph="icon-fontello-picture-1" />
                            </InputGroup.Addon>
                            <AxoLocal
                              componentClass={FormControl}
                              type="text"
                              value={this.state.searchText}
                              onChange={this.onSearch}
                              componentAttribute="placeholder"
                              entity="ContactTableViewplaceholderonSearch"
                            />

                            <FormControl.Feedback>
                              <Icon glyph="icon-fontello-search" />
                            </FormControl.Feedback>
                          </InputGroup>
                        </FormGroup>
                      </div>
                      {this.renderFilesGrid(this.getFiles())}
                    </Col>
                  </Row>
                </Grid>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
