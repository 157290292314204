import React from "react";
import PropTypes from "prop-types";

export default class FlexElement extends React.PureComponent {
  static propTypes = {
    flex: PropTypes.number,
    flexGrow: PropTypes.number,
    flexBasis: PropTypes.string,
  };

  render() {
    let { flex, flexGrow, flexBasis, ...partialProps } = this.props;

    let style = { ...this.props.style };

    if (!!flex) {
      style.flex = flex;
    }
    if (!!flexGrow) {
      style.flexGrow = flexGrow;
    }
    if (!!flexBasis) {
      style.flexBasis = flexBasis;
    }

    return (
      <div {...partialProps} style={style}>
        {this.props.children}
      </div>
    );
  }
}
