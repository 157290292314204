import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import DataStore from "../../services/DataAccess/DataStore";
import { withRouter } from "react-router-dom";
import UserInfoService from "../../services/UserInfoService";
import { Table, Column, Cell } from "fixed-data-table-2";
import SortHeaderCell from "../../utilities/FixedDataTable/SortHeaderCell";
import DataListWrapper from "../../utilities/FixedDataTable/DataListWrapper";
import ModalService from "../../services/ModalService";
import moment from "moment";

import {
  Row,
  Col,
  Grid,
  FormControl,
  InputGroup,
  FormGroup,
} from "react-bootstrap";

import {
  Icon,
  // LexButton,
  // LoadingIcon,
  TableBase,
  Dimensions,
  AxoLocal,
  InlineEdit,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  select: {
    width: 50,
    shown: true,
  },
  title: {
    width: 300,
    shown: true,
  },
  type: {
    width: 300,
    shown: true,
  },
  creationDate: {
    width: 200,
    shown: true,
  },
  sharedString: {
    width: 350,
    shown: true,
  },
  ownerString: {
    width: 350,
    shown: true,
  },
  actions: {
    width: 50,
    shown: true,
  },
};

class DocumentTemplateSharedTableComponent extends TableBase {
  constructor(props) {
    super(props);
    this.name = "DocumentTemplateSharedTableComponent";
    this.defaultColumnDefs = defaultColumnDefs;

    var tableEntries = this.addDataStringsToTemplates(props.templates);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(tableEntries);
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      deletingtemplateId: 0,
      updating: false,
    };
  }

  addDataStringsToTemplates = (entries) => {
    return entries.map((template) => {
      var sortTemplate = Object.assign({}, template);
      sortTemplate.sharedString =
        !sortTemplate.owner && sortTemplate.sharedUsers.length > 0
          ? UserInfoService.getDisplayName(sortTemplate.sharedUsers[0])
          : "";
      sortTemplate.ownerString = sortTemplate.owner
        ? sortTemplate.owner.toString()
        : "";
      return sortTemplate;
    });
  };

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.addDataStringsToTemplates(nextProps.templates);
    var filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  onClickTemplate = (templateId, event) => {
    event.preventDefault();
    this.props.onClickTemplate(templateId);
  };

  onTrashTemplate = (templateId) => {
    this.setState({ deletingtemplateId: templateId });
    var template = Object.assign(
      {},
      this.props.templates.find((t) => t.id === parseInt(templateId, 10))
    );
    if (template.trashed) {
      this.deleteTemplate(template.id);
    }
    template.trashed = true;
    DataActions.updateTemplate(template).then((response) => {
      this.setState({ deletingtemplateId: 0 });
    });
  };

  deleteTemplate = (templateId) => {
    ModalService.openConfirmModal(
      <AxoLocal
        entity="DocumentGridViewdeleteTemplate"
        defaultValue={"Vil du slette skabelonen permanent?"}
      />,
      (value) => {
        if (value) {
          DataActions.deleteTemplate(templateId);
        }
      }
    );
  };

  renderButtons = (objectId, isDocument) => {
    return (
      <div>
        {this.state.deletingtemplateId === objectId ? (
          <img
            alt=""
            src="/imgs/app/loading.gif"
            className="img-circle"
            width="20"
            height="20"
          />
        ) : (
          <Icon
            role="button"
            onClick={this.onTrashTemplate.bind(this, objectId)}
            className="editable"
            glyph="icon-fontello-trash-1"
          />
        )}
      </div>
    );
  };

  onTemplateTypeChange = (templateId, input) => {
    var template = Object.assign(
      {},
      this.props.templates.find((t) => t.id === templateId)
    );
    template.type = input.value;
    DataActions.updateTemplate(template);
  };

  onRemoveUser = (userId, templateId) => {
    DataActions.removeTemplateSharing({
      userProfileId: userId,
      templateId: templateId,
    });
  };

  renderUserNodes = (template) => {
    if (template.owner) {
      return null;
    }
    return template.sharedUsers.map((p) => {
      return (
        <span
          key={p.id}
          style={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "white",
          }}
        >
          {UserInfoService.getDisplayName(p)}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Icon
            role="button"
            onClick={this.onRemoveUser.bind(this, p.id, template.id)}
            glyph="icon-fontello-scissors axored"
          />
        </span>
      );
    });
  };

  renderOwnerInfo = (template) => {
    if (!template.owner) {
      return null;
    }

    let { userProfile } = this.props;
    let owner = template.owner;
    return (
      <span
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "white",
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Icon
          className="editable"
          role="button"
          glyph="icon-fontello-share"
        />{" "}
        &nbsp;&nbsp;&nbsp;
        {UserInfoService.getDisplayName(owner)}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Icon
          role="button"
          onClick={this.onRemoveUser.bind(this, userProfile.id, template.id)}
          glyph="icon-fontello-scissors axored"
        />
      </span>
    );
  };

  onSearch = (event) => {
    var entries = this.getFilteredEntries(
      this.state.tableEntries,
      event.target.value
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }
    return entries.filter((t) => {
      return t.title.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  onUpdate = () => {
    this.setState({ updating: true });
    DataStore.fetchTemplates().then(() => {
      this.setState({ updating: false });
    });
  };

  render() {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth } = this.props;
    var tableWidth = containerWidth - 30;
    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <Col sm={4}>
                  <h4>
                    {" "}
                    <Icon glyph="icon-fontello-hourglass" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTabViewSharedtemplates"
                      defaultValue={"Delte skabeloner"}
                    />
                  </h4>
                </Col>
                <Col sm={4}>
                  <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon
                            className="editable"
                            glyph="icon-fontello-search"
                          />
                        </InputGroup.Addon>
                        {/* Søg */}
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
                <Col
                  sm={4}
                  className="hidden-xs text-right"
                  style={{ paddingTop: "10px" }}
                >
                  {/* <LexButton onClick={this.onUpdate}>
                    <AxoLocal entity='ResetPasswordRefresh' defaultValue={'Opdater'}/>
                    <LoadingIcon
                      show={updating}
                    />
                  </LexButton> */}
                </Col>
              </Row>
            </Grid>
          </div>
          <div style={{ paddingLeft: "25px" }}>
            <Table
              rowHeight={40}
              rowsCount={sortedDataList.getSize()}
              height={containerHeight}
              width={tableWidth}
              isColumnResizing={false}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              headerHeight={40}
            >
              <Column
                columnKey="title"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.title}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="socialPrivateConversationBannerConferenceName"
                      defaultValue={"Navn"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var template = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={template.id} {...props}>
                      <a
                        href="document"
                        onClick={this.onClickTemplate.bind(this, template.id)}
                      >
                        {template.title}
                      </a>
                      {template.sharedUsers.length > 0 ? (
                        <span>
                          &ensp;
                          <Icon
                            className="editable"
                            glyph="icon-fontello-share"
                          />
                        </span>
                      ) : null}
                    </Cell>
                  );
                }}
                width={columnDefs.title.width}
                isResizable={true}
              />
              <Column
                columnKey="type"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.type}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="DocumentGridViewType"
                      defaultValue={"Type"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var template = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={template.id} {...props}>
                      <InlineEdit
                        value={template.type || "---"}
                        change={this.onTemplateTypeChange.bind(
                          this,
                          template.id
                        )}
                      />
                    </Cell>
                  );
                }}
                width={columnDefs.type.width}
                isResizable={true}
              />
              <Column
                columnKey="creationDate"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.creationDate}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal entity="axoidcode25" defaultValue={"Dato"} />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var template = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={template.id} {...props}>
                      {moment(template.creationDate).format("L")}
                    </Cell>
                  );
                }}
                width={columnDefs.creationDate.width}
                isResizable={true}
              />
              <Column
                columnKey="sharedString"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.sharedString}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="DocumentTabViewSharedwiththeusers"
                      defaultValue={"Delt med brugerne"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var template = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={template.id} {...props}>
                      {this.renderUserNodes(template)}
                    </Cell>
                  );
                }}
                width={columnDefs.sharedString.width}
                isResizable={true}
              />
              <Column
                columnKey="ownerString"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.ownerString}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="DocumentTabViewSharedfromtheuser"
                      defaultValue={"Delt fra brugerne"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var template = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={template.id} {...props}>
                      {this.renderOwnerInfo(template)}
                    </Cell>
                  );
                }}
                width={columnDefs.ownerString.width}
                isResizable={true}
              />
              <Column
                columnKey="actions"
                header={<Cell></Cell>}
                cell={(props) => {
                  var template = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={template.id} {...props}>
                      {!template.owner ? this.renderButtons(template.id) : null}
                    </Cell>
                  );
                }}
                width={columnDefs.actions.width}
                isResizable={true}
              />
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

class DocumentTemplateSharedTableView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplates: new Set(),
    };
  }

  renderDocumentTemplateTableComponent = () => {
    return <DocumentTemplateSharedTableComponent {...this.props} />;
  };

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} style={{ paddingTop: "10px", paddingLeft: "0px" }}>
            {this.renderDocumentTemplateTableComponent()}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight - 200;
    },
  })(DocumentTemplateSharedTableView)
);
