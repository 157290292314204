import React from "react";
// import ReactDom from 'react-dom';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Table, Column, Cell } from "fixed-data-table-2";
import SystemUsersGridView from "./SystemUsersGridView";
import moment from "moment";
import { debounce } from "lodash";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";

import {
  DataStore,
  DataActions,
  CountryService,
  ModalService,
  AuthorizationService,
} from "../../services/AxoServices";

import {
  AxoCheckbox,
  Icon,
  TableBase,
  SortHeaderCell,
  DataListWrapper,
  AxoLocal,
  ReactOption,
  CountryDropdown,
  RegionDropdown,
  Dimensions,
  getText,
  // AxoDateTime
} from "../../utilities/LexUtilities";

import {
  Row,
  Grid,
  FormControl,
  Button,
  FormGroup,
  InputGroup,
} from "react-bootstrap";

const defaultColumnDefs = {
  userName: {
    width: 150,
    shown: true,
  },
  creationDate: {
    width: 100,
    shown: true,
  },
  userType: {
    width: 125,
    shown: true,
  },
  planId: {
    width: 125,
    shown: true,
  },
  paidUntil: {
    width: 175,
    shown: true,
  },
  firstName: {
    width: 100,
    shown: true,
  },
  lastName: {
    width: 135,
    shown: true,
  },
  position: {
    width: 100,
    shown: true,
  },
  company: {
    width: 120,
    shown: true,
  },
  address: {
    width: 150,
    shown: true,
  },
  city: {
    width: 100,
    shown: true,
  },
  postalCode: {
    width: 90,
    shown: true,
  },
  country: {
    width: 90,
    shown: true,
  },
  region: {
    width: 90,
    shown: true,
  },
  eMail: {
    width: 80,
    shown: true,
  },
  phone: {
    width: 80,
    shown: true,
  },
  mobile: {
    width: 80,
    shown: true,
  },
  currency: {
    width: 80,
    shown: true,
  },
  storage: {
    width: 100,
    shown: true,
  },
  actions: {
    width: 500,
    shown: true,
  },
};

class SystemUsersView extends TableBase {
  static propTypes = {
    userProfiles: PropTypes.array.isRequired,
    forceShowCards: PropTypes.bool.isRequired,
    onToggleCards: PropTypes.func.isRequired,
    globalSettings: PropTypes.object.isRequired,
    onSetMainAdmin: PropTypes.func.isRequired,
    onSetGlobalUserSetting: PropTypes.func.isRequired,
    containerHeight: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.name = "SystemUsersTable";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = this.generateDefaultSortIndexes(
      props.userProfiles
    );
    let tableEntries = this.copyItems(props.userProfiles);
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 500,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      country: "",
      region: "",
    };
  }

  copyItems = (profiles) => {
    return profiles.map((c) => Object.assign({}, c));
  };

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.copyItems(nextProps.userProfiles);
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  onSearch = (event) => {
    this.setState(
      {
        searchText: event.target.value,
      },
      () => {
        this.updateFilteredEntries();
      }
    );
  };

  updateFilteredEntries = () => {
    let { tableEntries } = this.state;

    var entries = this.getFilteredEntries(tableEntries, this.state.searchText);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText && !this.state.country && !this.state.region) {
      return entries;
    }

    let filteredEntries = entries;
    if (this.state.country) {
      filteredEntries = filteredEntries.filter(
        (c) => c.country === this.state.country
      );
    }
    if (this.state.region) {
      filteredEntries = filteredEntries.filter(
        (c) => c.region === this.state.region
      );
    }
    return filteredEntries.filter((c) => {
      let text =
        c.userName +
        " " +
        c.firstName +
        " " +
        c.lastName +
        " " +
        (c.eMail || "");
      return text.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  getStorageString = (userProfile) => {
    var used = userProfile.storageUsed;
    if (used === 0) {
      return "";
    }
    if (used > Math.pow(1024, 3)) {
      return (used / Math.pow(1024, 3)).toFixed(2) + " GB";
    } else if (used > Math.pow(1024, 2)) {
      return (used / Math.pow(1024, 2)).toFixed(2) + " MB";
    } else if (used > 1024) {
      return (used / 1024).toFixed(2) + " KB";
    } else {
      return used.toFixed(2) + " bytes";
    }
  };

  selectCountry(val) {
    this.setState({ country: val }, () => {
      this.updateFilteredEntries();
    });
  }

  selectRegion(val) {
    this.setState({ region: val }, () => {
      this.updateFilteredEntries();
    });
  }

  onUserTypeChange = (userProfileId, event) => {
    let userProfile = this.state.sortedDataList._data.find(
      (p) => p.id === userProfileId
    );

    DataActions.updateUserType({
      ...userProfile,
      userType: event.target.value,
    });
  };

  //ToDo: Optimer lokalisering
  renderUserTypeSelect = (userProfile) => {
    let userType = AuthorizationService.getUserType(userProfile);
    return (
      <select
        className="axoblue selectbg"
        value={userType}
        style={{ border: "none" }}
        onChange={this.onUserTypeChange.bind(this, userProfile.id)}
      >
        <AxoLocal
          componentClass={ReactOption}
          value="Lawyer"
          componentAttribute="text"
          entity="AdminTabView35"
          defaultValue={"Advokat"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="Client"
          componentAttribute="text"
          entity="SystemUserTypeClient"
          defaultValue={"Klient"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="XLink"
          componentAttribute="text"
          entity="SystemUserTypeXLink"
          defaultValue={"XLink"}
        />
        <AxoLocal
          componentClass={ReactOption}
          value="Admin"
          componentAttribute="text"
          entity="SystemUserTypeAdmin"
          defaultValue={"Admin"}
        />
        <option value="Accountant">
          {getText("axoAccounting34", "Revisor")}
        </option>
        <option value="AccountingClient">
          {getText("axoAccounting35", "Revisorkunde")}
        </option>
        <option value="Society">
          {getText("ContactCreateViewassociationCustomer", "Forening")}
        </option>
        <option value="SocietyMember">
          {getText("axoidcode163", "Foreningsmedlem")}
        </option>
        <option value="EasyID">
          {/* MissingEntity */}
          Signering
        </option>
      </select>
    );
  };

  onDeleteUser = (userName) => {
    var confirmMessage = (
      <div>
        <div>
          <AxoLocal entity="AdminTabView61" defaultValue="Brugeren" />{" "}
          {userName}{" "}
          <AxoLocal
            entity="AdminTabView62"
            defaultValue="og dennes data vil blive slettet permanent."
          />{" "}
        </div>
        <div>
          <AxoLocal
            entity="AdminTabView63"
            defaultValue="Er du sikker på at du ønsker at fortsætte?"
          />
        </div>
      </div>
    );
    ModalService.openConfirmModal(confirmMessage, (value) => {
      if (value) {
        DataActions.deleteUserAccount(userName);
      }
    });
  };

  render() {
    const { sortedDataList } = this.state;
    const { forceShowCards, globalSettings } = this.props;

    return (
      <div style={{ paddingTop: "10px" }}>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <div
                  className="flexbox-layout"
                  style={{ paddingLeft: "25px", paddingRight: "25px" }}
                >
                  <h4>
                    <Icon glyph="icon-fontello-align-justify" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="AdminTabView64"
                      defaultValue="Brugere"
                    />{" "}
                    ({sortedDataList._data.length})
                  </h4>
                  <div>
                    QR:{" "}
                    {globalSettings.QRCount
                      ? parseInt(globalSettings.QRCount, 10)
                      : "0"}
                  </div>
                  <div>
                    <CountryDropdown
                      value={this.state.country}
                      valueType="short"
                      customSelect
                      onChange={(val) => this.selectCountry(val)}
                    />
                  </div>
                  <div>
                    <RegionDropdown
                      country={this.state.country}
                      countryValueType="short"
                      value={this.state.region || ""}
                      valueType="short"
                      onChange={(val) => this.selectRegion(val)}
                    />
                  </div>
                  <div style={{ maxWidth: "250px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-search" />
                        </InputGroup.Addon>
                        {/* Søg...  */}
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <Button
                    className="Lex-button-2 "
                    onClick={this.props.onToggleCards}
                  >
                    {this.props.forceShowCards ? (
                      <span>
                        {" "}
                        <Icon glyph="icon-fontello-grid" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showTable"
                          entity="CaseDataTableFixedShowastable"
                          defaultValue={"Vis som tabel"}
                        />
                      </span>
                    ) : (
                      <span>
                        {" "}
                        <Icon glyph="icon-fontello-th-thumb-empty" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showCards"
                          entity="CaseDataTableFixedShowCards"
                          defaultValue={"Vis som kartotekskort"}
                        />{" "}
                      </span>
                    )}
                  </Button>
                </div>
              </Row>
            </Grid>
          </div>
          {forceShowCards ? (
            this.renderGridView()
          ) : (
            <div>
              <ExtraSmall>{this.renderGridView()}</ExtraSmall>
              <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderGridView = () => {
    return (
      <div style={{ paddingTop: "10px" }}>
        <SystemUsersGridView userProfiles={this.state.sortedDataList._data} />
      </div>
    );
  };

  updatePaidUntil = debounce((profile) => {
    DataActions.updatePaidUntil(profile);
  }, 500);

  renderTable = () => {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const {
      containerHeight,
      containerWidth,
      globalSettings,
      onSetMainAdmin,
      onSetGlobalUserSetting,
    } = this.props;
    var tableWidth = containerWidth - 30;

    return (
      <div style={{ paddingTop: "0px", paddingLeft: "20px" }}>
        <Table
          rowHeight={40}
          rowsCount={sortedDataList.getSize()}
          width={tableWidth}
          height={containerHeight}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          headerHeight={40}
        >
          <Column
            columnKey="userName"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.userName}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView65" defaultValue="Brugernavn" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.userName}</div>
                </Cell>
              );
            }}
            width={columnDefs.userName.width}
            isResizable={true}
          />
          <Column
            columnKey="creationDate"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.creationDate}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="TimeEntryFormntimeEntry"
                  defaultValue="Dato"
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{moment(profile.creationDate).format("L")}</div>
                </Cell>
              );
            }}
            width={columnDefs.creationDate.width}
            isResizable={true}
          />
          <Column
            columnKey="userType"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.userType}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView67" defaultValue="Type" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{this.renderUserTypeSelect(profile)}</div>
                </Cell>
              );
            }}
            width={columnDefs.userType.width}
            isResizable={true}
          />
          <Column
            columnKey="planId"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.planId}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView68" defaultValue="Abonnement" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.planId}</div>
                </Cell>
              );
            }}
            width={columnDefs.planId.width}
            isResizable={true}
          />
          <Column
            columnKey="paidUntil"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.paidUntil}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="axoAccounting68"
                  defaultValue={"Betalt indtil"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  {/* { ReactDom.createPortal(
                  <div style={{ position: 'fixed', bottom: 100, zIndex: 9999}}>
                  <AxoDateTime 
                    className='rdtPickerOpenUpwards'
                    value={moment(profile.paidUntil)}
                    dateFormat={true} 
                    timeFormat={false} 
                  /></div>,
                  document.body
                ) } */}
                  <input
                    type="date"
                    style={{ borderStyle: "none" }}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                    onChange={(event) => {
                      let newDate = event.target.valueAsDate;
                      if (!newDate) {
                        return;
                      }

                      let momentDate = moment
                        .utc(event.target.valueAsDate)
                        .format();
                      let newProfile = {
                        ...profile,
                        paidUntil: momentDate,
                      };
                      DataStore.updateArrayObject(newProfile, "systemUsers"); //Optimistic updating
                      this.updatePaidUntil(newProfile);
                    }}
                    value={moment(profile.paidUntil).format("YYYY-MM-DD")}
                  />
                </Cell>
              );
            }}
            width={columnDefs.paidUntil.width}
            isResizable={true}
          />
          <Column
            columnKey="country"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.country}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="socialSidebarContainerCountry"
                  defaultValue={"Land"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>
                    {profile.country ? (
                      <span>
                        <AxoLocal
                          key={profile.id}
                          entity="CountryNames"
                          data={{ countrySlug: profile.country }}
                        />
                        &nbsp;({profile.country})
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </Cell>
              );
            }}
            width={columnDefs.country.width}
            isResizable={true}
          />
          <Column
            columnKey="region"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.region}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="SidebarRightContainerRegion"
                  defaultValue="Region"
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>
                    {profile.region ? (
                      <span>
                        {CountryService.getRegionName(
                          profile.country,
                          profile.region
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </Cell>
              );
            }}
            width={columnDefs.region.width}
            isResizable={true}
          />
          <Column
            columnKey="firstName"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.firstName}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView69" defaultValue="Fornavn" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.firstName}</div>
                </Cell>
              );
            }}
            width={columnDefs.firstName.width}
            isResizable={true}
          />
          <Column
            columnKey="lastName"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.lastName}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView70" defaultValue="Efternavn" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.lastName}</div>
                </Cell>
              );
            }}
            width={columnDefs.lastName.width}
            isResizable={true}
          />
          <Column
            columnKey="position"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.position}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView71" defaultValue="Stilling" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.position}</div>
                </Cell>
              );
            }}
            width={columnDefs.position.width}
            isResizable={true}
          />
          <Column
            columnKey="company"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.company}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView72" defaultValue="Virksomhed" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.company}</div>
                </Cell>
              );
            }}
            width={columnDefs.company.width}
            isResizable={true}
          />
          <Column
            columnKey="address"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.address}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="SignupAdresse" defaultValue="Adresse" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.address}</div>
                </Cell>
              );
            }}
            width={columnDefs.address.width}
            isResizable={true}
          />
          <Column
            columnKey="city"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.city}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView74" defaultValue="By" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.city}</div>
                </Cell>
              );
            }}
            width={columnDefs.city.width}
            isResizable={true}
          />
          <Column
            columnKey="postalCode"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.postalCode}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView75" defaultValue="Postnr" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.postalCode}</div>
                </Cell>
              );
            }}
            width={columnDefs.postalCode.width}
            isResizable={true}
          />
          <Column
            columnKey="eMail"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.eMail}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView76" defaultValue="Email" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.eMail}</div>
                </Cell>
              );
            }}
            width={columnDefs.eMail.width}
            isResizable={true}
          />
          <Column
            columnKey="phone"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.phone}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView77" defaultValue="Tlf" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.phone}</div>
                </Cell>
              );
            }}
            width={columnDefs.phone.width}
            isResizable={true}
          />
          <Column
            columnKey="mobile"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.mobile}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView78" defaultValue="Mobil" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.mobile}</div>
                </Cell>
              );
            }}
            width={columnDefs.mobile.width}
            isResizable={true}
          />
          <Column
            columnKey="currency"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.currency}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView79" defaultValue="Valuta" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{profile.currency}</div>
                </Cell>
              );
            }}
            width={columnDefs.currency.width}
            isResizable={true}
          />
          <Column
            columnKey="storage"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.storage}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="AdminTabView80" defaultValue="Lager" />
              </SortHeaderCell>
            }
            cell={(props) => {
              var profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  <div>{this.getStorageString(profile)}</div>
                </Cell>
              );
            }}
            width={columnDefs.storage.width}
            isResizable={true}
          />
          <Column
            columnKey="actions"
            header={<Cell></Cell>}
            cell={(props) => {
              let profile = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={profile.id} {...props}>
                  {profile.userType === "Admin" ? (
                    <span>
                      <AxoCheckbox
                        checked={
                          globalSettings.mainAdminUser === profile.userName
                        }
                        onChange={onSetMainAdmin.bind(this, profile.userName)}
                      />
                      &nbsp;
                      <AxoLocal entity="AdminTabView81" defaultValue="Main" />
                      &nbsp;
                      <AxoCheckbox
                        checked={
                          globalSettings.supportUser === profile.userName
                        }
                        onChange={onSetGlobalUserSetting.bind(
                          this,
                          "supportUser",
                          profile.userName
                        )}
                      />
                      &nbsp;
                      <AxoLocal entity="Enhedspris10" defaultValue="Support" />
                    </span>
                  ) : (
                    <span>
                      <AxoCheckbox
                        checked={
                          globalSettings.sharingUser === profile.userName
                        }
                        onChange={onSetGlobalUserSetting.bind(
                          this,
                          "sharingUser",
                          profile.userName
                        )}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="axoAccounting47"
                        defaultValue="Deling"
                      />
                      {profile.userType === "Accountant" && (
                        <>
                          &nbsp;
                          <AxoCheckbox
                            checked={profile.accountingEnabled}
                            onChange={(event) =>
                              DataActions.updateSystemUser({
                                ...profile,
                                accountingEnabled: event.target.checked,
                              })
                            }
                          />
                          &nbsp; Regnskab
                        </>
                      )}
                      {profile.userType === "Society" && (
                        <>
                          &nbsp;
                          <AxoCheckbox
                            checked={profile.accountingEnabled}
                            onChange={(event) =>
                              DataActions.updateSystemUser({
                                ...profile,
                                accountingEnabled: event.target.checked,
                              })
                            }
                          />
                          &nbsp; Regnskab &nbsp;
                          <AxoCheckbox
                            checked={profile.singlePaymentEnabled}
                            onChange={(event) =>
                              DataActions.updateSystemUser({
                                ...profile,
                                singlePaymentEnabled: event.target.checked,
                              })
                            }
                          />
                          &nbsp; Opkrævning &nbsp;
                          <AxoCheckbox
                            checked={profile.subscriptionPaymentEnabled}
                            onChange={(event) =>
                              DataActions.updateSystemUser({
                                ...profile,
                                subscriptionPaymentEnabled:
                                  event.target.checked,
                              })
                            }
                          />
                          &nbsp; Abonnement
                        </>
                      )}
                      &nbsp; &nbsp; &nbsp;
                      <Icon
                        className="editable"
                        role="button"
                        onClick={this.onDeleteUser.bind(this, profile.userName)}
                        glyph="icon-fontello-trash-1"
                      />
                    </span>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.actions.width}
            isResizable={true}
          />
        </Table>
      </div>
    );
  };
}

export default withRouter(
  Dimensions({
    getHeight: function () {
      return window.innerHeight - 200;
    },
  })(SystemUsersView)
);
