import React, { useEffect } from "react";

import {
  withData,
  withRTKMessageData,
  LoadingIndicator,
  // AsyncButton
} from "../../utilities/LexUtilities";

import {
  // ApiService,
  // DataActions,
  DataStore,
} from "../../services/AxoServices";

import CasesView from "./CasesView";

const mapStoreToProps = (store) => {
  return {
    caseModels: store.currentClientCases,
    userProfile: store.userProfile,
    userSettings: store.userSettings,
    // userSettings: store.userSettings,
  };
};

export default withRTKMessageData(
  withData(
    React.memo(function SingleCaseContainer(props) {
      let {
        caseModels,
        userProfile,
        userSettings,
        match,
        // userSettings,
        // selectedContact
      } = props;

      useEffect(() => {
        // DataStore.fetchUserProfile();
        // DataStore.fetchUserSettings();
        if (!caseModels || caseModels.length === 0) {
          DataStore.fetchCurrentClientCases();
        }
        DataStore.initializeUserProfile();
        DataStore.initializeUserSettings();
      }, []);

      if (caseModels.length === 0) {
        return <div></div>;
      }

      if (!userSettings.id || !userProfile.id) {
        return <LoadingIndicator />;
      }

      let currentCase = caseModels.find(
        (cm) => cm.id === parseInt(match.params.id)
      );

      return (
        <div className="topPadding">
          <CasesView
            key={currentCase.id}
            onClose={() => {}}
            {...props}
            openCase={currentCase}
            caseModel={currentCase}
            // caseModelArray={caseModels}
            // setActiveCaseIndex={setActiveCaseIndex}
            readOnly={false}
          />
        </div>
      );
    }),
    mapStoreToProps
  )
);
