import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

export default class AxoDateTimeEdit extends React.Component {
  static propTypes = {
    date: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      value: moment.utc(props.date).format(this.getFormat(props.locale)),
    };

    this.input = React.createRef();
  }

  getFormat = (locale) => {
    return locale.toLowerCase() === "da" ? "DD-MM-YYYY" : "MM-DD-YYYY";
  };

  onSubmit = () => {
    let { locale, onChange } = this.props;

    let { value } = this.state;

    let newMoment = moment.utc(value, this.getFormat(locale));
    if (!newMoment.isValid()) {
      return;
    }

    onChange(newMoment);
    this.setState({ editing: false });
  };

  onCancel = () => {
    let { date, locale } = this.props;

    this.setState({
      value: moment.utc(date).format(this.getFormat(locale)),
      editing: false,
    });
  };

  onEdit = () => {
    let { date, locale } = this.props;

    this.setState(
      {
        value: moment.utc(date).format(this.getFormat(locale)),
        editing: true,
      },
      () => {
        this.input.current.setSelectionRange(
          0,
          this.input.current.value.length
        );
      }
    );
  };

  onKeyDown = (event) => {
    switch (event.keyCode) {
      case 13: //Enter
        this.onSubmit();
        break;
      case 27: //Escape
        this.onCancel();
        break;
      default:
        break;
    }
  };

  render() {
    let { date, locale } = this.props;

    let { editing, value } = this.state;

    let format = this.getFormat(locale);

    if (editing) {
      return (
        <input
          ref={this.input}
          style={{ maxWidth: "125px" }}
          type="text"
          value={value}
          onChange={(event) => this.setState({ value: event.target.value })}
          onBlur={this.onSubmit}
          onKeyDown={this.onKeyDown}
          autoFocus
        />
      );
    }

    return (
      <span className="editable" onFocus={this.onEdit} tabIndex={0}>
        {moment(date).format(format)}
      </span>
    );
  }
}
