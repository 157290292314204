import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import {
  Row,
  Col,
  FormControl,
  ButtonToolbar,
  Form,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { RoutingService, ContactService } from "../../../services/AxoServices";

import {
  LexButton,
  Flexbox,
  FlexElement,
  AxoLocal,
  ctx,
  getText,
  Icon,
  Link,
  ErrorBoundary,
  ClientSearchBox,
  AsyncButton,
} from "../../../utilities/LexUtilities";

import AccountPlansBasicSetupForm from "../../AccountPlans/AccountPlansBasicSetupForm";
import SystemAccountsSetupForm from "./SystemAccountsSetupForm";

import AccountPlanTable from "./AccountPlanTable";

export default class AccountPlanView extends React.PureComponent {
  static propTypes = {
    financeAccountPlans: PropTypes.array.isRequired,
    standardFinanceAccountPlans: PropTypes.array.isRequired,
    userProfile: PropTypes.object.isRequired,
    selectedPlanId: PropTypes.number.isRequired,
    onSelectPlan: PropTypes.func.isRequired,
    onUpdateProperty: PropTypes.func.isRequired,
    creatingHeader: PropTypes.bool.isRequired,
    onCreateHeader: PropTypes.func.isRequired,
    onCancelCreate: PropTypes.func.isRequired,
    newAccountNumber: PropTypes.number.isRequired,
    newAccountName: PropTypes.string.isRequired,
    doCreateAccount: PropTypes.func.isRequired,
    editingPlan: PropTypes.bool.isRequired,
    updateAccountProperty: PropTypes.func.isRequired,
    addAccountToHeader: PropTypes.func.isRequired,
    onDeleteAccount: PropTypes.func.isRequired,
    creatingSumAccount: PropTypes.bool.isRequired,
    sumIntervalStart: PropTypes.number.isRequired,
    sumIntervalEnd: PropTypes.number.isRequired,
    onStopEditing: PropTypes.func.isRequired,
    updateSelectedPlanProperty: PropTypes.func.isRequired,
    editingAccountPlanSetup: PropTypes.bool.isRequired,
  };

  //ToDo: Merge view and container. Local state should be stored in the view.
  constructor(props) {
    super(props);

    this.state = {
      confirmPlanSelection: false,
    };
  }

  onFinalizePlanSelection = async () => {
    let { onFinalizePlanSelection } = this.props;

    let result = await onFinalizePlanSelection();
    if (!result) {
      return false;
    }

    this.setState({ confirmPlanSelection: false });
    return true;
  };

  isPlanLocked = () => {
    let { selectedContact } = this.props;
    return selectedContact.planLocked || !!selectedContact.departmentOwnerId;
  };

  render() {
    let {
      financeAccountPlans,
      selectedPlanId,
      selectedPlan,
      onSelectPlan,
      onUpdateProperty,
      editingPlan,
      onStopEditing,
      updateSelectedPlanPropertyMap,
      editingAccountPlanSetup,
      selectedContact,
      contactMap,
      handleSelectedClient,
      clientPlan,
      fixedClient,
      toggleView,
    } = this.props;

    let { confirmPlanSelection } = this.state;

    if (!selectedContact.id && !fixedClient) {
      return (
        <div className="leftPadding" style={{ marginTop: "15px" }}>
          <Flexbox
            responsive
            collapseXS
            style={{ position: "relative", zIndex: 9 }}
          >
            <FlexElement
              className="onTop rightPadding"
              style={{ paddingRight: "5px", zIndex: 10 }}
            >
              <AxoLocal
                componentClass={ClientSearchBox}
                // startValue={!!selectedContact.id ? selectedContact.firstName + ' ' + (selectedContact.lastName || '') : '' }
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                disabled={false}
                componentAttribute="placeholder"
                entity="composeSelectClientPlaceholder1"
              />
            </FlexElement>
          </Flexbox>
        </div>
      );
    }

    // var hasStandards = standardFinanceAccountPlans.length > 0;
    let planOptions = financeAccountPlans.map((plan) => {
      return { value: plan.id.toString(), label: plan.name };
    });

    if (!!clientPlan && clientPlan.id) {
      planOptions = planOptions.concat([
        { value: clientPlan.id.toString(), label: clientPlan.name },
      ]);
    }

    let AccountTable = this.AccountTable;
    return (
      <div className="leftPadding" style={{ marginTop: "15px" }}>
        <Flexbox
          responsive
          collapseXS
          style={{ position: "relative", zIndex: 9 }}
        >
          {!fixedClient && (
            <FlexElement className="onTop rightPadding" style={{ zIndex: 10 }}>
              <AxoLocal
                componentClass={ClientSearchBox}
                startValue={
                  !!selectedContact.id
                    ? selectedContact.firstName +
                      " " +
                      (selectedContact.lastName || "")
                    : ""
                }
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                disabled={false}
                componentAttribute="placeholder"
                entity="composeSelectClientPlaceholder1"
              />
            </FlexElement>
          )}
          {toggleView && (
            <FlexElement className="rightPadding">
              <LexButton onClick={toggleView}>Vis regnskabsår</LexButton>
            </FlexElement>
          )}
          {(!fixedClient || !this.isPlanLocked()) && (
            <FlexElement flexBasis="300px" className="rightPadding">
              <div style={{ minWidth: "150px" }}>
                <Select
                  name="accountPlan"
                  menuPlacement="auto"
                  isDisabled={this.isPlanLocked()}
                  value={
                    planOptions.find(
                      (o) => o.value === selectedPlanId.toString()
                    ) || { value: "0", label: ctx.getSync("axoAccounting32") }
                  }
                  onChange={(selection, arg) => {
                    onSelectPlan(
                      !!selection && selection.value !== "0"
                        ? parseInt(selection.value, 10)
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    { value: "0", label: ctx.getSync("axoAccounting32") },
                    ...planOptions,
                  ]}
                />
              </div>
            </FlexElement>
          )}
          {this.isPlanLocked() ? (
            <FlexElement>
              {editingPlan ? (
                <ButtonToolbar>
                  <LexButton
                    key="save"
                    disabled={!selectedPlanId}
                    onClick={() => onStopEditing()}
                  >
                    <Icon glyph="icon-fontello-stop-outline" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="axoAccounting19"
                      defaultValue={"Stop redigering"}
                    />
                  </LexButton>
                </ButtonToolbar>
              ) : (
                <ButtonToolbar>
                  <LexButton
                    disabled={!selectedPlan}
                    onClick={() => onUpdateProperty("editingPlan", true)}
                  >
                    <Icon glyph="icon-fontello-edit-3" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      key="edit"
                      entity="axoAccounting23"
                      defaultValue={"Rediger kontoplan"}
                    />
                  </LexButton>
                  <LexButton
                    disabled={!selectedPlan}
                    onClick={() =>
                      onUpdateProperty(
                        "editingAccountPlanSetup",
                        !editingAccountPlanSetup
                      )
                    }
                  >
                    {!editingAccountPlanSetup ? (
                      <span>
                        <AxoLocal
                          key="standard"
                          entity="axoidcode58"
                          defaultValue={"Standardkonti"}
                        />
                      </span>
                    ) : (
                      <span>
                        <AxoLocal
                          key="finish"
                          entity="axoidcode59"
                          defaultValue={"Luk formular"}
                        />
                      </span>
                    )}
                  </LexButton>
                  {/* <LexButton onClick={() => {
                    DataActions.updateContact({
                      ...selectedContact,
                      planLocked: false
                    })
                  }}>
                    Fortryd kontovalg
                  </LexButton> */}
                </ButtonToolbar>
              )}
            </FlexElement>
          ) : (
            <FlexElement>
              <LexButton
                disabled={!selectedPlan || confirmPlanSelection}
                onClick={() => this.setState({ confirmPlanSelection: true })}
              >
                <AxoLocal entity="axoidcode202" defaultValue={"Vælg plan"} />
              </LexButton>
            </FlexElement>
          )}
        </Flexbox>
        {confirmPlanSelection ? (
          <Flexbox collapseXS>
            <FlexElement className="rightPadding largeText">
              <AxoLocal
                entity="axoidcode203"
                defaultValue={
                  "Når planen er valgt, vil der ikke kunne skiftes kontoplan for klienten."
                }
              />
            </FlexElement>
            <FlexElement className="rightPadding">
              <AsyncButton onClick={this.onFinalizePlanSelection}>
                <AxoLocal
                  entity="friendRequestTimelineonApproveRequest"
                  defaultValue={"Godkend"}
                />
              </AsyncButton>
            </FlexElement>
            <FlexElement className="rightPadding">
              <LexButton
                onClick={() => this.setState({ confirmPlanSelection: false })}
              >
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </LexButton>
            </FlexElement>
          </Flexbox>
        ) : null}
        {!!selectedPlan && selectedPlan.balanceEnd === 0 ? (
          <div
            style={{ maxWidth: "900px", paddingTop: "50px" }}
            className="axored"
          >
            <Icon glyph="icon-fontello-cog-alt" />
            &nbsp;&nbsp;
            <Link to={RoutingService.getPath("AccountPlans/Setup")}>
              <AxoLocal
                entity="axoidcode38"
                defaultValue={
                  "Gå til opsætning og definér resultatopgørelse og balanceintervaller."
                }
              />
            </Link>
            {/* <AccountPlansBasicSetupForm
              onSubmit={properties => updateSelectedPlanPropertyMap(properties)}
            /> */}
          </div>
        ) : null}
        {editingAccountPlanSetup ? (
          <div style={{ maxWidth: "900px" }}>
            <AccountPlansBasicSetupForm
              onSubmit={updateSelectedPlanPropertyMap}
              financeAccountPlan={selectedPlan}
            />
            <SystemAccountsSetupForm
              key={selectedPlanId}
              onSubmit={async (properties) => {
                let result = await updateSelectedPlanPropertyMap(properties);
                onUpdateProperty("editingAccountPlanSetup", false);
                return result;
              }}
              accounts={clientPlan.accounts.filter(
                (a) => a.type === "Standard"
              )}
              financeAccountPlan={selectedPlan}
              onCancel={() =>
                onUpdateProperty("editingAccountPlanSetup", false)
              }
            />
          </div>
        ) : null}
        {!editingAccountPlanSetup &&
        !!selectedPlan &&
        selectedPlan.balanceEnd > 0 ? (
          <AccountTable />
        ) : null}
      </div>
    );
  }

  AccountTable = () => {
    let {
      creatingHeader,
      onCreateHeader,
      onCancelCreate,
      newAccountNumber,
      onUpdateProperty,
      newAccountName,
      doCreateAccount,
      editingPlan,
      creatingSumAccount,
      sumIntervalStart,
      sumIntervalEnd,
      summedAccounts,
      updateAccountProperty,
      addAccountToHeader,
      onDeleteAccount,
      updateSelectedPlanProperty,
      userProfile,
      financeAccounts,
      selectedPlan,
    } = this.props;

    let accounts = selectedPlan.accounts.sort(
      (a1, a2) => a1.number - a2.number
    );
    let accountOptions = accounts.map((acc) => {
      return {
        value: acc.id.toString(),
        label: acc.number + " - " + (acc.name || ""),
      };
    });

    return (
      <ErrorBoundary>
        <Row>
          <Col xs={12} style={{ marginTop: "10px" }}>
            {creatingHeader ? (
              <Form>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal
                      entity="axoAccounting6"
                      defaultValue={"Kontonummer"}
                    />
                  </ControlLabel>
                  <FormControl
                    type="number"
                    value={isNaN(newAccountNumber) ? "" : newAccountNumber}
                    onChange={(event) =>
                      onUpdateProperty(
                        "newAccountNumber",
                        event.target.valueAsNumber
                      )
                    }
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                    placeholder={getText("axoAccounting6", "Kontonummer")}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    value={newAccountName}
                    onChange={(event) =>
                      onUpdateProperty("newAccountName", event.target.value)
                    }
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                    placeholder={getText("axoAccounting6a", "Omsætning")}
                  />
                </FormGroup>
                <FormGroup>
                  <ButtonToolbar>
                    <LexButton
                      disabled={!newAccountNumber || !newAccountName}
                      onClick={() => doCreateAccount("Header")}
                    >
                      <AxoLocal
                        entity="axoAccounting26"
                        defaultValue={"Opret overskrift"}
                      />
                    </LexButton>
                    <LexButton onClick={onCancelCreate}>
                      <Icon glyph="icon-fontello-ccw" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            ) : null}
            {creatingSumAccount ? (
              <Form>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal
                      entity="axoAccounting6"
                      defaultValue={"Kontonummer"}
                    />
                  </ControlLabel>
                  <FormControl
                    type="number"
                    value={isNaN(newAccountNumber) ? "" : newAccountNumber}
                    onChange={(event) =>
                      onUpdateProperty(
                        "newAccountNumber",
                        event.target.valueAsNumber
                      )
                    }
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                    placeholder={getText("axoAccounting6", "Kontonummer")}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    value={newAccountName}
                    onChange={(event) =>
                      onUpdateProperty("newAccountName", event.target.value)
                    }
                    style={{ maxWidth: "250px", marginRight: "10px" }}
                    placeholder={getText("axoAccounting6c", "Omsætning i alt")}
                  />
                </FormGroup>
                <FormGroup style={{ margin: "0px" }}>
                  <ControlLabel>
                    <AxoLocal entity="AdminTabView8" defaultValue="Interval" />
                  </ControlLabel>
                  <Flexbox responsive>
                    <FormGroup style={{ paddingRight: "15px" }}>
                      <ControlLabel>
                        <AxoLocal
                          entity="axoAccounting27"
                          defaultValue={"Sum fra"}
                        />
                      </ControlLabel>
                      <FormControl
                        type="number"
                        value={isNaN(sumIntervalStart) ? "" : sumIntervalStart}
                        onChange={(event) =>
                          onUpdateProperty(
                            "sumIntervalStart",
                            event.target.valueAsNumber
                          )
                        }
                        style={{ maxWidth: "250px", marginRight: "10px" }}
                        placeholder={getText("axoAccounting6", "Kontonummer")}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>
                        <AxoLocal
                          entity="axoAccounting28"
                          defaultValue={"Sum til"}
                        />
                      </ControlLabel>
                      <FormControl
                        type="number"
                        value={isNaN(sumIntervalEnd) ? "" : sumIntervalEnd}
                        onChange={(event) =>
                          onUpdateProperty(
                            "sumIntervalEnd",
                            event.target.valueAsNumber
                          )
                        }
                        style={{ maxWidth: "250px", marginRight: "10px" }}
                        placeholder={getText("axoAccounting6", "Kontonummer")}
                      />
                    </FormGroup>
                  </Flexbox>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    <AxoLocal
                      entity="axoidcode62"
                      defaultValue="Summer valgte konti"
                    />
                  </ControlLabel>
                  <div
                    style={{
                      maxWidth: "250px",
                      position: "relative",
                      zIndex: 9,
                    }}
                  >
                    <Select
                      name="summedAccounts"
                      menuPlacement="auto"
                      value={{
                        value: "0",
                        label: getText("axoAccounting6f", "Vælg konto"),
                      }}
                      onChange={(selection, arg) => {
                        let id =
                          !!selection && selection.value !== "0"
                            ? parseInt(selection.value, 10)
                            : null;
                        if (id) {
                          onUpdateProperty(
                            "summedAccounts",
                            summedAccounts.concat([id])
                          );
                        }
                      }}
                      noOptionsMessage={() => ""}
                      options={[
                        {
                          value: "0",
                          label: getText("axoAccounting6f", "Vælg konto"),
                        },
                        ...accountOptions,
                      ]}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    {summedAccounts.map((id) => (
                      <div style={{ marginRight: "15px" }}>
                        <strong>
                          {financeAccounts[id].number} -{" "}
                          {financeAccounts[id].name || ""}
                        </strong>{" "}
                        <Icon
                          role="button"
                          glyph="icon-fontello-trash-1"
                          onClick={() => {
                            onUpdateProperty(
                              "summedAccounts",
                              summedAccounts.filter((accId) => accId !== id)
                            );
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </FormGroup>
                <FormGroup>
                  <ButtonToolbar>
                    <LexButton
                      disabled={
                        !newAccountNumber ||
                        !newAccountName ||
                        (sumIntervalEnd > 0 &&
                          sumIntervalEnd <= sumIntervalStart)
                      }
                      onClick={() => doCreateAccount("Sum")}
                    >
                      <Icon glyph="icon-fontello-plus-1" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="axoAccounting29"
                        defaultValue={"Opret sumkonto"}
                      />
                    </LexButton>
                    <LexButton onClick={onCancelCreate}>
                      <Icon glyph="icon-fontello-ccw" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            ) : null}
            {editingPlan && !creatingHeader && !creatingSumAccount ? (
              <ButtonToolbar>
                <LexButton onClick={onCreateHeader}>
                  <Icon glyph="icon-fontello-plus-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="axoAccounting30"
                    defaultValue={"Tilføj overskrift"}
                  />
                </LexButton>
                <LexButton
                  onClick={() => onUpdateProperty("creatingSumAccount", true)}
                >
                  <Icon glyph="icon-fontello-plus-1" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="axoAccounting31"
                    defaultValue={"Tilføj Sumkonto"}
                  />
                </LexButton>
              </ButtonToolbar>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ErrorBoundary>
              <AccountPlanTable
                selectedPlan={selectedPlan}
                financeAccounts={financeAccounts}
                userProfile={userProfile}
                entries={accounts}
                onToggleCards={() => {}}
                editing={editingPlan}
                updateAccountProperty={updateAccountProperty}
                addAccountToHeader={addAccountToHeader}
                onDeleteAccount={onDeleteAccount}
                updateSelectedPlanProperty={updateSelectedPlanProperty}
              />
            </ErrorBoundary>
          </Col>
        </Row>
      </ErrorBoundary>
    );
  };
}
