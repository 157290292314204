import React from "react";
import { groupBy } from "lodash";
import { AxoLocal } from "../../utilities/LexUtilities";
import { Table } from "react-bootstrap";

function makeMonth(name) {
  return {
    name: name,
    incomingVat: 0,
    outgoingVat: 0,
  };
}

//Moms
export default class VatView extends React.PureComponent {
  generateMonthSums() {
    return [
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity45"
            defaultValue={"Januar"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity46"
            defaultValue={"Februar"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity47" defaultValue={"Marts"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity48" defaultValue={"April"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity49" defaultValue={"Maj"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity50" defaultValue={"Juni"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity51" defaultValue={"Juli"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity51"
            defaultValue={"August"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity53"
            defaultValue={"September"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity54"
            defaultValue={"Oktober"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity55"
            defaultValue={"November"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity56"
            defaultValue={"December"}
          />
        </span>
      ),
    ];
  }

  render() {
    var { postings } = this.props;

    var monthSums = this.generateMonthSums();

    var months = groupBy(postings, (p) => {
      return new Date(p.date).getMonth();
    });

    for (var key in months) {
      var postingsForMonth = months[key];
      var index = parseInt(key, 10);

      monthSums[index].incomingVat = postingsForMonth.reduce((acc, posting) => {
        return acc + posting.incomingVat;
      }, 0);
      monthSums[index].outgoingVat = postingsForMonth.reduce((acc, posting) => {
        return acc + posting.outgoingVat;
      }, 0);
    }

    var totalIncoming = postings.reduce((acc, posting) => {
      return acc + posting.incomingVat;
    }, 0);

    var totalOutgoing = postings.reduce((acc, posting) => {
      return acc + posting.outgoingVat;
    }, 0);

    return (
      <div className="AccountingpaddingTB">
        <h3>
          {" "}
          <AxoLocal
            entity="AccountingTabViewEntity44"
            defaultValue={"Momsoversigt"}
          />
        </h3>
        <Table responsive>
          <tbody>
            <tr className="Axoaccountsbannebg">
              <th>
                <AxoLocal
                  entity="CalendarTabViewmonth"
                  defaultValue={"Måned"}
                />
              </th>
              <th>
                {" "}
                <AxoLocal
                  entity="AccountingTabViewEntity21"
                  defaultValue={"Indgående moms"}
                />
              </th>
              <th>
                {" "}
                <AxoLocal
                  entity="AccountingTabViewEntity22"
                  defaultValue={"Udgående moms"}
                />{" "}
              </th>
              <th>
                {" "}
                <AxoLocal
                  entity="invoiPaymentattheTotal"
                  defaultValue={"I alt"}
                />{" "}
              </th>
            </tr>
            {monthSums.map((m, index) => {
              var rows = [];
              rows.push(
                <tr>
                  <th>{m.name}</th>
                  <td>{m.incomingVat}</td>
                  <td>{m.outgoingVat}</td>
                  <td>{m.incomingVat - m.outgoingVat}</td>
                </tr>
              );
              var quarterIndex = index + 1;
              if (index > 0 && quarterIndex % 3 === 0) {
                var incoming = monthSums
                  .slice(quarterIndex - 3, quarterIndex)
                  .reduce((acc, monthSum) => {
                    return acc + monthSum.incomingVat;
                  }, 0);

                var outGoing = monthSums
                  .slice(quarterIndex - 3, quarterIndex)
                  .reduce((acc, monthSum) => {
                    return acc + monthSum.outgoingVat;
                  }, 0);

                rows.push(
                  <tr>
                    <th className="Axoaccountsbannebg">
                      {(index + 1) / 3}.
                      <AxoLocal
                        entity="AccountingTabViewEntity59"
                        defaultValue={"kvartal"}
                      />
                    </th>
                    <td className="Axoaccountsbannebg">{incoming}</td>
                    <td className="Axoaccountsbannebg">{outGoing}</td>
                    <td className="Axoaccountstotalbanne">
                      {incoming - outGoing}
                    </td>
                  </tr>
                );
              }
              return rows;
            })}
            <tr className="doubleboder">
              <th>
                {" "}
                <AxoLocal
                  entity="AccountingTabViewEntity43"
                  defaultValue={"Årsresultat"}
                />
              </th>
              <td>{totalIncoming}</td>
              <td>{totalOutgoing}</td>
              <td>{totalIncoming - totalOutgoing}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
