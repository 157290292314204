import React from "react";
import DataStore from "../../services/DataAccess/DataStore";
import PresentationStore from "../services/PresentationStore";
import PresentationActions from "../services/PresentationActions";
import PresentationView from "./PresentationView";
import { withRouter } from "react-router-dom";

class SinglePresentationContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      presentation: {
        items: [],
      },
      userProfile: {},
    };
    this.name = "SinglePresentationContainer";
  }

  componentDidMount() {
    var { id } = this.props.match.params;

    PresentationStore.subscribe(this.name, (store) => {
      var presentation = store.presentations.find(
        (p) => p.id === parseInt(id, 10)
      );
      if (presentation) {
        this.setState({ presentation });
      }
    });
    DataStore.subscribe(this.name, (store) => {
      this.setState({ userProfile: store.userProfile });
    });
    PresentationStore.fetchPresentations();
    DataStore.initializeUserProfile();
  }

  componentWillUnmount() {
    PresentationStore.unsubscribe(this.name);
    DataStore.unsubscribe(this.name);
  }

  onTogglePublic = (presentationId) => {
    var newPresentation = Object.assign({}, this.state.presentation);
    newPresentation.public = !newPresentation.public;
    PresentationActions.updatePresentation(newPresentation);
  };

  render() {
    return (
      <PresentationView
        presentation={this.state.presentation}
        userProfile={this.state.userProfile}
        includeLinks
        includeLinkBox
        onTogglePublic={this.onTogglePublic}
        allowEdit
      />
    );
  }
}

export default withRouter(SinglePresentationContainer);
