import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { DataActions } from "../../services/AxoServices";

import {
  Icon,
  InlineEdit,
  InlineCountrySelect,
  AxoLocal,
} from "../../utilities/LexUtilities";

import { Table } from "react-bootstrap";

export default class ApplicationUDTableView extends React.PureComponent {
  static propTypes = {
    applicationUDs: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  onDelete = (id) => {
    DataActions.deleteApplicationUD(id);
  };

  onUpdate = (model) => {
    //Replaces the object with the same id with this object
    DataActions.updateApplicationUD(model);
  };

  render() {
    let { applicationUDs, onGotoDetails } = this.props;

    return (
      <div className="standardMaxWidth leftPadding center-block">
        <Table bordered condensed hover>
          <thead>
            <tr>
              <th>
                <AxoLocal
                  entity="SidebarRightContaineruseruserName"
                  defaultValue={"Navn"}
                />
              </th>
              <th>
                {" "}
                <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
              </th>
              <th>
                {" "}
                <AxoLocal
                  entity="axoidcode161"
                  defaultValue={"Fødselsdag"}
                />{" "}
              </th>
              <th>Fødland</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {applicationUDs.map((model) => (
              <tr
                role="button"
                key={model.id}
                onClick={() => onGotoDetails(model.id)}
              >
                <td className="editable">{model.name}</td>
                <td>
                  <InlineEdit
                    value={model.address || "---"}
                    change={(input) =>
                      this.onUpdate({
                        ...model, //Create a new object containing all the field of 'model', with the text field replaced by the input text.
                        address: input.value,
                      })
                    }
                  />
                </td>
                <td>{moment.utc(model.dateOfBirth).format("L")}</td>
                <td>
                  <InlineCountrySelect
                    value={model.countryOfBirth || ""}
                    valueType="short"
                    onChange={(countryOfBirth) =>
                      this.onUpdate({ ...model, countryOfBirth })
                    }
                  />
                </td>
                <td>
                  <Icon
                    role="button"
                    onClick={() => this.onDelete(model.id)}
                    glyph="icon-fontello-trash-1"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
