import React from "react";
import PropTypes from "prop-types";
import ItemPricesTable from "./ItemPricesTable";
import ItemPriceTypesTable from "./ItemPriceTypesTable";

import { PanelGroup, Panel } from "react-bootstrap";

import { AccordionUtils, AxoLocal } from "../../utilities/LexUtilities";

export default class PriceTableSelector extends React.PureComponent {
  static propTypes = {
    itemPrices: PropTypes.array.isRequired,
    itemPriceTypes: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeKey: "0",
      tableUpdater: false, //Ensures tables are rerendered when the panels are opened
    };
  }

  onSelectTable = (eventKey) => {
    let { activeKey, tableUpdater } = this.state;
    let newKey = activeKey === eventKey ? "0" : eventKey;
    this.setState({ activeKey: newKey });
    setTimeout(() => this.setState({ tableUpdater: !tableUpdater }), 1);
  };

  render() {
    let { tableUpdater } = this.state;

    return (
      <PanelGroup
        id="ITsubscriptiong"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {AccordionUtils.renderAccordionHeader(
                <span>
                  <AxoLocal entity="Enhedspris18" defaultValue="Typer" />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <ItemPriceTypesTable
              key={tableUpdater}
              itemPriceTypes={this.props.itemPriceTypes}
            />
          </Panel.Body>
        </Panel>

        <Panel className="backgroundlineargradient3" eventKey="0">
          <Panel.Heading>
            <Panel.Title toggle>
              {AccordionUtils.renderAccordionHeader(
                <span>
                  <AxoLocal entity="Enhedspris14" defaultValue="Priser" />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <ItemPricesTable key={tableUpdater} {...this.props} />
          </Panel.Body>
        </Panel>
      </PanelGroup>

      //   <Accordion
      //     className='Lex-Accordion caseeditinline'
      //     activeKey={activeKey}
      //     onSelect={this.onSelectTable}
      //     style={{ marginTop: '10px' }}>
      //   <Panel header={AccordionUtils.renderAccordionHeader(<span>
      //     <AxoLocal entity='Enhedspris18' defaultValue='Typer'/>
      //     </span>)} eventKey="1">
      //       <ItemPriceTypesTable
      //         key={tableUpdater}
      //         itemPriceTypes={this.props.itemPriceTypes}
      //       />
      //   </Panel>
      //   <Panel header={AccordionUtils.renderAccordionHeader(<span>
      //     <AxoLocal entity='Enhedspris14' defaultValue='Priser'/>
      //     </span>)} eventKey="2">
      //       <ItemPricesTable
      //         key={tableUpdater}
      //         {...this.props}
      //       />
      //   </Panel>
      // </Accordion>
    );
  }
}
