import ApiService from "./ApiService";
import DataStore from "./DataStore";
import SocialPostStore from "../SocialPostStore";
import AccountingStore from "../AccountingStore";
import { groupBy } from "lodash";
import moment from "moment";

export default {
  fetchOnSuccess(updateFunc, fetchFunc) {
    return updateFunc()
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Object could not be created.");
        }
        fetchFunc();
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        return { ok: false };
      });
  },

  fetchOnFailure(updateFunc, fetchFunc) {
    return updateFunc()
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Update was rejected by the server.");
        }
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        fetchFunc();
        return { ok: false };
      });
  },

  fetchOnCompletion(updateFunc, fetchFunc) {
    return updateFunc()
      .then((response) => {
        fetchFunc();
        if (!response.ok) {
          return Promise.reject("Update was rejected by the server.");
        }
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        fetchFunc();
        return { ok: false };
      });
  },

  async addResultOnSuccess(creationFunc, typeName, startValue = {}) {
    try {
      let response = await creationFunc();
      if (!response.ok) {
        return Promise.reject(
          "Object of type " + typeName + " could not be created."
        );
      }

      let newResponse = response.clone(); //Allows outside rerunning of response.json()

      let newObject = await response.json();
      DataStore.updateArrayObject(
        {
          ...newObject,
          ...startValue, //Keep old values, so navigation properties are not overwritten
        },
        typeName
      );
      return newResponse;
    } catch (error) {
      console.log(error.message);
      return { ok: false };
    }
  },

  async handleReturnedObject(creationFunc, handler) {
    try {
      let response = await creationFunc();
      if (!response.ok) {
        return Promise.reject("Object of could not be created.");
      }
      let newObject = await response.json();
      handler(newObject);
      return response;
    } catch (error) {
      console.log(error.message);
      return { ok: false };
    }
  },

  async login(params) {
    let response = await ApiService.login(params);
    return response;
  },

  logout() {
    return ApiService.logout().then((response) => {
      if (response.ok) {
        DataStore.resetStore();
      }
      return response;
    });
  },

  //Messages
  // createMessage(message) {
  //   return ApiService.createMessage(message)
  //   .then(( response ) => {
  //     DataStore.fetchMessages();
  //     return response;
  //   });
  // },

  // updateMessage(message) {
  //   var id = message.id;
  //   DataStore.updateMessageState(message);
  //   return ApiService.updateMessage(message)
  //   .then(( response ) => {
  //     DataStore.fetchMessageHeader(id);
  //     DataStore.fetchMessageCounter();
  //     DataStore.fetchLabels();
  //     return response;
  //   })
  //   .catch(reason => {
  //     console.log(reason);
  //     DataStore.fetchMessageHeader(id);
  //     DataStore.fetchMessageCounter();
  //     return reason;
  //   })
  // },

  // updateMessageFromHeader(message) {
  //   var id = message.id;
  //   DataStore.updateMessageState(message);
  //   return ApiService.updateMessageFromHeader(message)
  //   .then(( response ) => {
  //     DataStore.fetchMessageHeader(id);
  //     DataStore.fetchMessageCounter();
  //     DataStore.fetchLabels();
  //     return response;
  //   })
  //   .catch(reason => {
  //     console.log(reason);
  //     DataStore.fetchMessageHeader(id);
  //     DataStore.fetchMessageCounter();
  //     return reason;
  //   })
  // },

  // deleteMessage(messageId) {
  //   DataStore.deleteMessageFromState(messageId);
  //   return ApiService.deleteMessage(messageId)
  //   .then( response => {
  //     if(!response.ok) {
  //       return Promise.reject("Could not delete message");
  //     }
  //     DataStore.fetchMessageCounter();
  //     return response;
  //   })
  //   .catch(reason => {
  //     console.log(reason);
  //     DataStore.fetchMessageCounter();
  //     DataStore.fetchMessageHeader(messageId);
  //   })
  // },

  deleteExternalMessages(messages, userId) {
    var externalMessages = messages.filter((m) => !!m.externalAccountId);
    if (externalMessages.length === 0) {
      return;
    }

    let receivedMessages = externalMessages.filter(
      (m) => m.receiver && m.receiver.id === userId && !m.spam
    );
    let spamMessages = externalMessages.filter((m) => m.spam);
    let sentMessages = externalMessages.filter(
      (m) => m.sender && m.sender.id === userId
    );

    this.doDeleteExternalMessages(receivedMessages, false, false);
    this.doDeleteExternalMessages(spamMessages, false, true);
    this.doDeleteExternalMessages(sentMessages, true, false);
  },

  doDeleteExternalMessages(messages, isSent, isSpam) {
    var deletedGroups = groupBy(messages, (email) => email.externalAccountId);
    for (var key in deletedGroups) {
      var messageIds = deletedGroups[key].map((m) => m.externalUniqueId);
      this.updateExternalMails({
        accountId: key,
        messageIds: messageIds,
        delete: true,
        isSent,
        isSpam,
      });
    }
  },

  moveExternalMessagesToInbox(messages) {
    let spamMessages = messages.filter((m) => m.spam);

    if (spamMessages.length === 0) {
      return;
    }

    return this.doMoveExternalMessages(messages, true);
  },

  moveExternalMessagesToSpam(messages) {
    let spamMessages = messages.filter((m) => !m.spam);

    if (spamMessages.length === 0) {
      return;
    }

    return this.doMoveExternalMessages(messages, false);
  },

  async doMoveExternalMessages(messages, toInbox) {
    let moveGroups = groupBy(messages, (email) => email.externalAccountId);
    for (let key in moveGroups) {
      var messageIds = moveGroups[key].map((m) => m.externalUniqueId);
      await this.updateExternalMails({
        accountId: key,
        messageIds: messageIds,
        isSpam: toInbox,
        moveTo: toInbox ? "Inbox" : "Spam",
      });
    }
  },

  //BCC Relations
  addBCCRelation(bccRelation) {
    return ApiService.addBCCRelation(bccRelation).then((response) => {
      DataStore.fetchMessages();
      return response;
    });
  },

  removeBCCRelation(bccRelation) {
    return ApiService.removeBCCRelation(bccRelation).then((response) => {
      DataStore.fetchMessages();
      return response;
    });
  },

  //Labels
  createLabel(model) {
    return this.addResultOnSuccess(
      () => ApiService.createLabel(model),
      "labels"
    );
  },

  updateLabel(model) {
    DataStore.updateArrayObject(model, "labels");
    return this.fetchOnFailure(
      () => ApiService.updateLabel(model),
      () => DataStore.fetchLabels()
    );
  },

  deleteLabel(id) {
    DataStore.removeLabelAndReferences(id);
    return this.fetchOnFailure(
      () => ApiService.deleteLabel(id),
      () => DataStore.fetchLabels()
    );
  },

  async addLabelToMessage(messageId, labelId) {
    DataStore.addLabelToMessage(messageId, labelId);
    await this.fetchOnFailure(
      () => ApiService.addLabelToMessage(messageId, labelId),
      () => {
        DataStore.fetchMessages();
        DataStore.fetchLabels();
      }
    );
    DataStore.fetchMessagesDebounced();
  },

  removeLabelFromMessage(messageId, labelId) {
    return ApiService.removeLabelFromMessage(messageId, labelId).then(
      (response) => {
        DataStore.fetchLabels();
        DataStore.fetchMessagesDebounced();
        return response;
      }
    );
  },

  //Cases
  createCase(caseModel) {
    return ApiService.createCase(caseModel).then((response) => {
      DataStore.fetchCases();
      return response;
    });
  },

  updateCase(caseModel) {
    var id = caseModel.id;
    DataStore.updateCaseModelState(caseModel);
    return ApiService.updateCase(caseModel)
      .then((response) => {
        DataStore.fetchCaseHeader(id);
        DataStore.fetchSharedCases();
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        DataStore.fetchCases();
        DataStore.fetchSharedCases();
        DataStore.fetchDocuments();
        return Promise.reject(reason);
      });
  },

  updateCaseFromHeader(caseModel) {
    var id = caseModel.id;
    DataStore.updateCaseModelState(caseModel);
    return ApiService.updateCaseFromHeader(caseModel)
      .then((response) => {
        DataStore.fetchCaseHeader(id);
        DataStore.fetchSharedCases();
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        DataStore.fetchCaseHeader(id);
        DataStore.fetchSharedCases();
        return Promise.reject(reason);
      });
  },

  //All case users are allowed to update the reminder.
  updateCaseReminder(id) {
    ApiService.updateCaseReminder(id)
      .then((response) => {
        DataStore.fetchCaseHeader(id);
        DataStore.fetchSharedCases();
      })
      .catch((reason) => {
        DataStore.fetchCaseHeader(id);
        DataStore.fetchSharedCases();
      });
  },

  deleteCase(deleteCaseViewModel) {
    DataStore.removeCaseFromMap(deleteCaseViewModel.id);
    return ApiService.deleteCase(deleteCaseViewModel)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Case could not be deleted.");
        }
        return response;
      })
      .catch((reason) => {
        DataStore.fetchCases();
        DataStore.fetchSharedCases();
        return Promise.reject(reason);
      });
  },

  generateCasePdf(caseModelId) {
    return ApiService.generateCasePdf(caseModelId).then((response) => {
      DataStore.fetchCases();
      return response;
    });
  },

  selectCase(caseId) {
    DataStore.selectCase(caseId);
  },

  addLabelToCase(caseId, labelId) {
    return ApiService.addLabelToCase(caseId, labelId)
      .then((response) => {
        DataStore.fetchLabels();
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        DataStore.fetchLabels();
        return reason;
      });
  },

  removeLabelFromCase(caseId, labelId) {
    return ApiService.removeLabelFromCase(caseId, labelId).then((response) => {
      DataStore.fetchLabels();
      return response;
    });
  },

  addDocumentsToLabel(documentIds, labelId) {
    DataStore.addDocumentsToLabel(documentIds, labelId);
    let promises = [];
    documentIds.forEach((id) => {
      promises.push(ApiService.addLabelToFile(id, labelId));
    });

    return Promise.all(promises).then((responses) => {
      if (responses.find((r) => !r.ok)) {
        DataStore.fetchLabels();
        DataStore.fetchDocuments();
      }
      return responses;
    });
  },

  removeLabelFromFile(fileId, labelId) {
    DataStore.removeDocumentFromLabel(fileId, labelId);
    return this.fetchOnFailure(
      () => ApiService.removeLabelFromFile(fileId, labelId),
      () => {
        DataStore.fetchLabels();
        DataStore.fetchDocuments();
      }
    );
  },

  addLabelToClientArray(clientIds, labelId) {
    DataStore.addContactsToLabel(clientIds, labelId);
    let promises = [];
    clientIds.forEach((id) => {
      promises.push(ApiService.addLabelToClient(id, labelId));
    });

    return Promise.all(promises).then((responses) => {
      if (responses.find((r) => !r.ok)) {
        DataStore.fetchLabels();
      }
    });
  },

  removeLabelFromClient(clientId, labelId) {
    DataStore.removeContactFromLabel(clientId, labelId);
    return this.fetchOnFailure(
      () => ApiService.removeLabelFromClient(clientId, labelId),
      () => {
        DataStore.fetchLabels();
      }
    );
  },

  //Documents
  uploadDocuments(files, params = {}) {
    return ApiService.uploadDocuments(files, params).then((response) => {
      if (response.ok) {
        let newResponse = response.clone(); //Allows outside rerunning of response.json()
        if (!!params.skipOptimisticUpdate) {
          return newResponse;
        }

        response.json().then((newFiles) => {
          DataStore.addDocuments(newFiles, params.labelId);
        });
        DataStore.fetchStorageInformation();
        return newResponse;
      }
      return response;
    });
  },

  uploadCompositeImage(files, params) {
    return ApiService.uploadCompositeImage(files, params).then((response) => {
      if (response.ok) {
        let newResponse = response.clone(); //Allows outside rerunning of response.json()
        response.json().then((newFiles) => {
          DataStore.addDocuments(newFiles);
        });
        DataStore.fetchStorageInformation();
        return newResponse;
      }
      return response;
    });
  },

  splitDocument(id) {
    return ApiService.splitDocument(id).then((response) => {
      if (response.ok) {
        let newResponse = response.clone(); //Allows outside rerunning of response.json()
        response.json().then((newFiles) => {
          DataStore.addDocuments(newFiles);
        });
        DataStore.fetchStorageInformation();
        return newResponse;
      }
      return response;
    });
  },

  mergeDocuments(idList) {
    return ApiService.mergeDocuments(idList).then((response) => {
      if (response.ok) {
        let newResponse = response.clone(); //Allows outside rerunning of response.json()
        response.json().then((newFile) => {
          DataStore.addDocuments([newFile]);
        });
        DataStore.fetchStorageInformation();
        return newResponse;
      }
      return response;
    });
  },

  addImagesToExisting(files, params = { fileId: 0, clientId: 0 }) {
    return ApiService.addImagesToExisting(files, params).then((response) => {
      if (response.ok) {
        let newResponse = response.clone(); //Allows outside rerunning of response.json()
        response.json().then((newFile) => {
          DataStore.updateDocumentState(newFile);
        });
        DataStore.fetchStorageInformation();
        return newResponse;
      }
      return response;
    });
  },

  deleteDocument(documentId) {
    DataStore.removeDocument(documentId);
    return this.fetchOnFailure(
      () => ApiService.deleteDocument(documentId),
      () => DataStore.fetchDocuments()
    );
  },

  updateDocumentName(document) {
    DataStore.updateDocumentState(document);
    DataStore.updateCaseDocumentReferences(document);

    return ApiService.updateDocumentName(document)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Failed to update document");
        }
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        DataStore.fetchDocuments();
        DataStore.fetchCases();
        return { ok: false };
      });
  },

  updateDocument(document) {
    DataStore.updateDocumentState(document);
    return ApiService.updateDocument(document)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Failed to update document");
        }
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        DataStore.fetchDocuments();

        return { ok: false };
      });
  },

  copyDocument(documentId) {
    return ApiService.copyDocument(documentId).then((response) => {
      DataStore.fetchDocuments();
      DataStore.fetchStorageInformation();
      return response;
    });
  },

  //Calendar events
  createCalendarEvent(event) {
    return this.addResultOnSuccess(
      () => ApiService.createCalendarEvent(event),
      "calendarEvents",
      event
    );
  },

  updateCalendarEvent(event) {
    return ApiService.updateCalendarEvent(event).then((response) => {
      DataStore.fetchCalendarEvents();
      return response;
    });
  },

  deleteCalendarEvent(eventId) {
    return ApiService.deleteCalendarEvent(eventId).then((response) => {
      DataStore.fetchCalendarEvents();
      DataStore.fetchCases(); //Update document information on cases
      return response;
    });
  },

  //Notifications
  createNotification(notification) {
    return ApiService.createNotification(notification).then((response) => {
      DataStore.fetchNotifications();
      return response;
    });
  },
  updateNotification(notification) {
    return ApiService.updateNotification(notification).then((response) => {
      DataStore.fetchNotifications();
      return response;
    });
  },
  deleteNotification(notificationId) {
    return ApiService.deleteNotification(notificationId).then((response) => {
      DataStore.fetchNotifications();
      return response;
    });
  },

  //Event bookings
  createEventBooking(eventBooking) {
    return ApiService.createEventBooking(eventBooking).then((response) => {
      return response;
    });
  },
  updateEventBooking(eventBooking) {
    return ApiService.updateEventBooking(eventBooking).then((response) => {
      DataStore.fetchCalendarEvents();
      return response;
    });
  },
  deleteEventBooking(eventBooking) {
    return ApiService.deleteEventBooking(eventBooking).then((response) => {
      DataStore.fetchCalendarEvents();
      return response;
    });
  },

  //Time entries
  createTimeEntry(timeEntry) {
    return ApiService.createTimeEntry(timeEntry).then((response) => {
      DataStore.fetchTimeEntries();
      DataStore.fetchInvoices();
      DataStore.fetchCases();
      return response;
    });
  },
  updateTimeEntry(timeEntry) {
    return ApiService.updateTimeEntry(timeEntry).then((response) => {
      DataStore.fetchTimeEntries().then(() => {
        DataStore.fetchInvoices();
        DataStore.fetchCases();
      });
      return response;
    });
  },
  deleteTimeEntry(timeEntryId) {
    return ApiService.deleteTimeEntry(timeEntryId).then((response) => {
      DataStore.fetchTimeEntries().then(() => {
        DataStore.fetchInvoices();
        DataStore.fetchCases();
      });
      return response;
    });
  },

  //Contacts
  // createContact(contactInfo) {
  //   return ApiService.createContact(contactInfo)
  //     .then( (response) => {
  //       if(response.ok) {
  //         let newResponse = response.clone(); //Allows outside rerunning of response.json()
  //         response.json()
  //         .then(newContact => {
  //           DataStore.updateContactState(newContact);
  //         });
  //         return newResponse;
  //       }
  //       return response;
  //     })
  //     .catch(reason => {
  //       console.log(reason);
  //       return { ok: false };
  //     })
  // },

  // createClientAccount(clientAccount) {
  //     return ApiService.createClientAccount(clientAccount)
  //         .then( (response) => {
  //           // DataStore.fetchContacts();
  //           return response;
  //         })
  //         .catch(error => {
  //           console.log(error);
  //         })
  // },

  // updateContact(contact) {
  //   var id = contact.id;
  //   DataStore.updateContactState(contact);
  //   return this.fetchOnFailure(() => ApiService.updateContact(contact), () => DataStore.fetchContact(id));
  // },

  // checkoutContact(contact, userProfile) {
  //   DataStore.updateContactState({
  //     ...contact,
  //     editor: userProfile,
  //     editorId: userProfile.id
  //   });

  //   return this.fetchOnCompletion(() => ApiService.checkoutContact(contact.id),
  //     () => DataStore.fetchContacts()); // Fetch all contacts on completion, since other contacts will be checked in automatically
  // },

  // async updateNewEntriesOnContacts() {
  //   let response = await ApiService.updateNewEntries();
  //   DataStore.fetchContacts();
  //   return response;
  // },

  // deleteContact( contactInfoId ) {
  //   DataStore.removeContact(contactInfoId);
  //   return this.fetchOnFailure(() => ApiService.deleteContact(contactInfoId), () => DataStore.fetchContacts());
  // },

  //Invoices
  createInvoice(invoice) {
    return this.addResultOnSuccess(
      () => ApiService.createInvoice(invoice),
      "invoices",
      invoice
    );
  },
  updateInvoice(invoice) {
    DataStore.updateArrayObject(invoice, "invoices");
    return this.fetchOnFailure(
      () => ApiService.updateInvoice(invoice),
      () => DataStore.fetchInvoices()
    );
  },
  deleteInvoice(invoiceId) {
    DataStore.removeArrayObject(invoiceId, "invoices");
    return this.fetchOnFailure(
      () => ApiService.deleteInvoice(invoiceId),
      () => DataStore.fetchInvoices()
    );
  },
  generateInvoicePdf(invoiceId) {
    return ApiService.generateInvoicePdf(invoiceId).then((response) => {
      DataStore.fetchSingleInvoice(invoiceId);
      return response;
    });
  },

  //Templates
  createTemplate(template) {
    return ApiService.createTemplate(template).then((response) => {
      DataStore.fetchTemplates();
      return response;
    });
  },

  updateTemplate(template) {
    return ApiService.updateTemplate(template).then((response) => {
      DataStore.fetchTemplates();
      return response;
    });
  },

  deleteTemplate(templateId) {
    DataStore.removeArrayObject(templateId, "templates");
    return this.fetchOnFailure(
      () => ApiService.deleteTemplate(templateId),
      () => DataStore.fetchTemplates()
    );
  },

  generateTemplatePdf(templateId) {
    return ApiService.generateTemplatePdf(templateId).then((response) => {
      DataStore.fetchTemplates();
      DataStore.fetchDocuments();
      return response;
    });
  },

  //User profiles
  updateUserProfile(userProfile) {
    DataStore.setState({ userProfile });
    return this.fetchOnFailure(
      () => ApiService.updateUserProfile(userProfile),
      () => DataStore.fetchUserProfile()
    );
  },

  confirmPhone(message) {
    return ApiService.confirmPhone(message).then((response) => {
      return response;
    });
  },

  confirmPhoneCode(code) {
    return ApiService.confirmPhoneCode(code)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Phone code could no be confirmed");
        }

        DataStore.setState({
          userProfile: {
            ...DataStore.getStore().userProfile,
            internalPhoneConfirmed: true,
          },
        });

        return response;
      })
      .catch((reason) => {
        console.log(reason);
        DataStore.fetchUserProfile();
      });
  },

  confirmMail(viewModel) {
    return ApiService.confirmMail(viewModel).then((response) => {
      DataStore.fetchUserProfile();
      return response;
    });
  },

  changePassword(changePasswordViewModel) {
    return ApiService.changePassword(changePasswordViewModel).then(
      (response) => {
        DataStore.fetchUserProfile();
        return response;
      }
    );
  },

  enableTwoFactor() {
    DataStore.setState({
      userProfile: {
        ...DataStore.getStore().userProfile,
        twoFactorEnabled: true,
      },
    });
    return this.fetchOnFailure(
      () => ApiService.enableTwoFactor(),
      () => DataStore.fetchUserProfile()
    );
  },

  disableTwoFactor() {
    DataStore.setState({
      userProfile: {
        ...DataStore.getStore().userProfile,
        twoFactorEnabled: false,
      },
    });
    return this.fetchOnFailure(
      () => ApiService.disableTwoFactor(),
      () => DataStore.fetchUserProfile()
    );
  },

  //Friends
  removeFriend(userProfile) {
    return ApiService.removeFriend(userProfile).then((response) => {
      DataStore.fetchFriends();
      return response;
    });
  },

  //Friend requests
  createFriendRequest(friendRequest) {
    return ApiService.createFriendRequest(friendRequest).then((response) => {
      return response;
    });
  },
  acceptFriendRequest(friendRequest) {
    return ApiService.acceptFriendRequest(friendRequest).then((response) => {
      DataStore.fetchFriendRequests();
      DataStore.fetchFriends();
      SocialPostStore.fetchSocialPosts();
      return response;
    });
  },
  updateFriendRequest(friendRequest) {
    return ApiService.updateFriendRequest(friendRequest).then((response) => {
      DataStore.fetchFriendRequests();
      DataStore.fetchFriends();
      return response;
    });
  },
  deleteFriendRequest(friendRequestId) {
    return ApiService.deleteFriendRequest(friendRequestId).then((response) => {
      return response;
    });
  },

  //Filesharings
  shareFile({ document, userProfile }) {
    DataStore.updateDocumentState({
      ...document,
      sharedUsers: [...document.sharedUsers, userProfile],
      sharingDate: moment().format(),
    });
    return this.fetchOnFailure(
      () =>
        ApiService.shareFile({
          fileId: document.id,
          userProfileId: userProfile.id,
        }),
      () => DataStore.fetchDocuments()
    );
  },

  removeFileSharing({ document, userProfile }) {
    //Only documents shared from other users have the owner set
    if (document.owner) {
      DataStore.removeArrayObject(document.id, "sharedDocuments");
    } else {
      //Document belongs to the current user
      DataStore.updateDocumentState({
        ...document,
        sharedUsers: document.sharedUsers.filter(
          (p) => p.id !== userProfile.id
        ),
      });
    }

    return this.fetchOnFailure(
      () =>
        ApiService.removeFileSharing({
          fileId: document.id,
          userProfileId: userProfile.id,
        }),
      () => DataStore.fetchDocuments()
    );
  },

  //Templatesharings
  //templateSharing = { templateID : '...', userProfileId: '...'}
  shareTemplate(templateSharing) {
    return ApiService.shareTemplate(templateSharing).then((response) => {
      DataStore.fetchTemplates();
      return response;
    });
  },

  removeTemplateSharing(templateSharing) {
    return ApiService.removeTemplateSharing(templateSharing).then(
      (response) => {
        DataStore.fetchTemplates();
        return response;
      }
    );
  },

  //Social posts
  createSocialPost(socialPost) {
    return ApiService.createSocialPost(socialPost).then((response) => {
      SocialPostStore.fetchSocialPosts();
      return response;
    });
  },

  updateSocialPost(socialPost) {
    return ApiService.updateSocialPost(socialPost).then((response) => {
      SocialPostStore.fetchSocialPosts();
      return response;
    });
  },

  addCommentToSocialPost(socialPostId, comment) {
    return ApiService.addCommentToSocialPost(socialPostId, comment).then(
      (response) => {
        SocialPostStore.fetchSocialPosts();
        SocialPostStore.fetchIndividualPosts(
          DataStore.getSelectedUserProfileId()
        );
        return response;
      }
    );
  },

  deleteSocialPost(socialPostId) {
    return ApiService.deleteSocialPost(socialPostId).then((response) => {
      SocialPostStore.fetchSocialPosts();
      DataStore.fetchDocuments();
      return response;
    });
  },

  deleteComment(commentId) {
    return ApiService.deleteComment(commentId).then((response) => {
      SocialPostStore.fetchSocialPosts();
      SocialPostStore.fetchIndividualPosts(
        DataStore.getSelectedUserProfileId()
      );
      DataStore.fetchDocuments();
      return response;
    });
  },

  selectUserProfile(userProfileId) {
    DataStore.selectUserProfile(userProfileId);
    SocialPostStore.fetchIndividualPosts(userProfileId);
  },

  //Postlikers
  addPostLiker(postLiker) {
    return ApiService.addPostLiker(postLiker).then((response) => {
      SocialPostStore.fetchSocialPosts();
      return response;
    });
  },

  removePostLiker(postLiker) {
    return ApiService.removePostLiker(postLiker).then((response) => {
      SocialPostStore.fetchSocialPosts();
      return response;
    });
  },

  //Filesharings
  addPostReading(postReading) {
    return ApiService.addPostReading(postReading).then((response) => {
      SocialPostStore.fetchSocialPosts();
      return response;
    });
  },

  removePostReading(postReading) {
    return ApiService.removePostReading(postReading).then((response) => {
      SocialPostStore.fetchSocialPosts();
      return response;
    });
  },

  //Private conversations
  createPrivateConversation(privateConversation) {
    return ApiService.createPrivateConversation(privateConversation).then(
      (response) => {
        SocialPostStore.fetchPrivateConversations();
        return response;
      }
    );
  },

  updatePrivateConversation(privateConversation) {
    return ApiService.updatePrivateConversation(privateConversation).then(
      (response) => {
        SocialPostStore.fetchPrivateConversations();
        return response;
      }
    );
  },

  // deletePrivateConversation(privateConversationId) {
  //   SocialPostStore.removeArrayObject(privateConversationId, 'privateConversations')
  //   return ApiService.deletePrivateConversation(privateConversationId)
  //     .then( (response) => {
  //       SocialPostStore.fetchPrivateConversations();
  //       return response;
  //     });
  // },

  //Private conversation users
  //Creation is handled by the friend requests controller
  deletePrivateConversationUser(convo, userId) {
    if (convo.ownerId === userId) {
      SocialPostStore.removeArrayObject(convo.id, "privateConversations");
    } else {
      SocialPostStore.updateArrayObject(
        {
          ...convo,
          conversationUsers: convo.conversationUsers.filter(
            (u) => u.id !== userId
          ),
        },
        "privateConversations"
      );
    }

    return this.fetchOnFailure(
      () => ApiService.deletePrivateConversationUser(convo.id, userId),
      () => SocialPostStore.fetchPrivateConversations()
    );
  },

  //User settings
  updateUserSettings(settings) {
    DataStore.updateUserSettingsState(settings);
    return ApiService.updateUserSettings(settings)
      .then((response) => {
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        DataStore.fetchUserSettings();
        return reason;
      });
  },

  //Stripe subscriptions
  activateSubscription(subscribeViewModel) {
    return ApiService.activateSubscription(subscribeViewModel).then(
      (response) => {
        if (response.ok) {
          DataStore.fetchUserProfile();
          DataStore.fetchUserSubscription();
          DataStore.fetchStorageInformation();
          DataStore.fetchUserGroup();
        }
        return response;
      }
    );
  },

  cancelSubscription(userProfile) {
    return ApiService.cancelSubscription(userProfile).then((response) => {
      DataStore.fetchUserProfile();
      DataStore.fetchUserSubscription();
      DataStore.fetchUserGroup();
      return response;
    });
  },

  updateCard(subscribeViewModel) {
    return ApiService.updateCard(subscribeViewModel).then((response) => {
      DataStore.fetchUserProfile();
      // DataStore.fetchCardInformation();
      DataStore.fetchUserSubscription();
      return response;
    });
  },

  registerCard(code) {
    return ApiService.registerCard(code).then((response) => {
      DataStore.fetchUserProfile();
      DataStore.fetchUserSubscription();

      // DataStore.fetchCardInformation();
      return response;
    });
  },

  removeCustomer() {
    return ApiService.removeCustomer().then((response) => {
      DataStore.fetchUserProfile();
      // DataStore.fetchCardInformation();
      DataStore.fetchUserSubscription();
      return response;
    });
  },

  removeCard() {
    return ApiService.removeCard().then((response) => {
      DataStore.fetchUserProfile();
      // DataStore.fetchCardInformation();
      DataStore.fetchUserSubscription();
      return response;
    });
  },

  stripePayment(paymentViewModel) {
    return ApiService.stripePayment(paymentViewModel).then((response) => {
      DataStore.fetchLawyerProfiles();
      return response;
    });
  },

  //Admin
  //System users
  updateSystemUser(profile) {
    DataStore.updateArrayObject(profile, "systemUsers"); //Optimistic updating
    // DataStore.setState({ userProfile });
    return this.fetchOnFailure(
      () => ApiService.updateUserProfileAdmin(profile),
      () => DataStore.fetchSystemUsers()
    );
  },

  updateUserType(profile) {
    return ApiService.updateUserType(profile).then((response) => {
      DataStore.fetchSystemUsers();
      return response;
    });
  },

  updatePaidUntil(profile) {
    DataStore.updateArrayObject(profile, "systemUsers"); //Optimistic updating
    return ApiService.updatePaidUntil(profile.id, profile.paidUntil)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Field could not be updated");
        }
        return response;
      })
      .catch((reason) => {
        console.log(reason);
        DataStore.fetchSystemUsers();
      });
  },

  //Subscription plans
  createSubscriptionPlan(plan) {
    return ApiService.createSubscriptionPlan(plan).then((response) => {
      DataStore.fetchSubscriptionPlansForAdmin();
      return response;
    });
  },

  // viewModel { formula: {...}, users: 5, interval: 'month' }
  createSubscriptionPlanFromFormula(viewModel) {
    return ApiService.createSubscriptionPlanFromFormula(viewModel).then(
      (response) => {
        DataStore.fetchSubscriptionPlans();
        return response;
      }
    );
  },

  updateSubscriptionPlan(plan) {
    return ApiService.updateSubscriptionPlan(plan).then((response) => {
      DataStore.fetchSubscriptionPlansForAdmin();
      return response;
    });
  },

  deleteSubscriptionPlan(id) {
    return ApiService.deleteSubscriptionPlan(id).then((response) => {
      DataStore.fetchSubscriptionPlansForAdmin();
      return response;
    });
  },

  //User account management
  deleteUserAccount(userName) {
    return ApiService.deleteUserAccount(userName).then((response) => {
      DataStore.fetchSystemUsers();
      return response;
    });
  },

  //Globalsettings
  createOrUpdateGlobalSetting(setting) {
    console.log(setting);
    if (DataStore.getGlobalSetting(setting.key) === undefined) {
      return this.createGlobalSetting(setting);
    } else {
      return this.updateGlobalSetting(setting);
    }
  },

  createGlobalSetting(setting) {
    return ApiService.createGlobalSetting(setting).then((response) => {
      DataStore.fetchGlobalSettings();
      return response;
    });
  },

  updateGlobalSetting(setting) {
    return ApiService.updateGlobalSetting(setting).then((response) => {
      DataStore.fetchGlobalSettings();
      return response;
    });
  },

  deleteGlobalSetting(setting) {
    return ApiService.deleteGlobalSetting(setting).then((response) => {
      DataStore.fetchGlobalSettings();
      return response;
    });
  },

  //User groups
  generateGroupCode() {
    return ApiService.generateGroupCode().then((response) => {
      DataStore.fetchUserGroup();
      return response;
    });
  },

  //Case groups
  createEditHistoryItem(item) {
    return ApiService.createEditHistoryItem(item).then((response) => {
      DataStore.fetchCases();
      return response;
    });
  },

  deleteEditHistoryItem(itemId) {
    return ApiService.deleteEditHistoryItem(itemId).then((response) => {
      DataStore.fetchCases();
      return response;
    });
  },

  //Case sharings
  shareCaseWithGroup(caseModelId) {
    return ApiService.shareCaseWithGroup(caseModelId).then((response) => {
      DataStore.fetchCases();
      DataStore.fetchSharedCases();
    });
  },

  shareCaseWithList(caseModelId, sharingUsers) {
    return ApiService.shareCaseWithList(caseModelId, sharingUsers).then(
      (response) => {
        DataStore.fetchCases();
        DataStore.fetchSharedCases();
        return response;
      }
    );
  },

  shareCase(caseSharing) {
    return ApiService.shareCase(caseSharing).then((response) => {
      DataStore.fetchCases();
      DataStore.fetchSharedCases();
      return response;
    });
  },

  removeCaseSharing(caseSharing) {
    return ApiService.removeCaseSharing(caseSharing).then((response) => {
      DataStore.fetchCases();
      DataStore.fetchSharedCases();
      return response;
    });
  },

  //Case version control
  checkoutCase(caseModelId) {
    return ApiService.checkoutCase(caseModelId).then((response) => {
      DataStore.fetchCases();
      DataStore.fetchSharedCases();
      return response;
    });
  },

  checkinCase(caseModelId) {
    return ApiService.checkinCase(caseModelId).then((response) => {
      DataStore.fetchCases();
      DataStore.fetchSharedCases();
      return response;
    });
  },

  //Mail settings
  createEmailAccount(account) {
    return ApiService.createEmailAccount(account).then((response) => {
      DataStore.fetchEmailAccounts();
      return response;
    });
  },

  updateEmailAccount(account) {
    return ApiService.updateEmailAccount(account).then((response) => {
      DataStore.fetchEmailAccounts();
      return response;
    });
  },

  deleteEmailAccount(accountId) {
    return ApiService.deleteEmailAccount(accountId).then((response) => {
      DataStore.updateEmailListeners();
      DataStore.fetchEmailAccounts().then(() => {
        return DataStore.fetchMessages();
      });
      return response;
    });
  },

  //External mails
  updateExternalMails(updateViewModel) {
    return ApiService.updateExternalMails(updateViewModel).then((response) => {
      return response;
    });
  },

  createCaseVersion(caseVersion) {
    return ApiService.createCaseVersion(caseVersion).then((response) => {
      return response;
    });
  },

  updateCaseVersion(caseVersion) {
    return ApiService.updateCaseVersion(caseVersion);
  },

  deleteCaseVersion(caseVersionId) {
    return ApiService.deleteCaseVersion(caseVersionId).then((response) => {
      return response;
    });
  },

  //Subscription formula
  updateSubscriptionFormula(subscriptionFormula) {
    return ApiService.updateSubscriptionFormula(subscriptionFormula).then(
      (response) => {
        DataStore.fetchSubscriptionFormulas();
        return response;
      }
    );
  },

  createSubscriptionFormula(subscriptionFormula) {
    return ApiService.createSubscriptionFormula(subscriptionFormula).then(
      (response) => {
        DataStore.fetchSubscriptionFormulas();
        return response;
      }
    );
  },

  deleteSubscriptionFormula(subscriptionFormulaId) {
    return ApiService.deleteSubscriptionFormula(subscriptionFormulaId).then(
      (response) => {
        DataStore.fetchSubscriptionFormulas();
        return response;
      }
    );
  },

  //Accounting
  //Posting account types
  updatePostingAccountType(type) {
    return ApiService.updatePostingAccountType(type).then((response) => {
      AccountingStore.fetchAccountTypes();
      return response;
    });
  },

  createPostingAccountType(type) {
    return ApiService.createPostingAccountType(type).then((response) => {
      AccountingStore.fetchAccountTypes();
      return response;
    });
  },

  deletePostingAccountType(id) {
    return ApiService.deletePostingAccountType(id).then((response) => {
      AccountingStore.fetchAccountTypes();
      return response;
    });
  },

  //Posting accounts
  updatePostingAccount(account) {
    return ApiService.updatePostingAccount(account).then((response) => {
      AccountingStore.fetchAccounts();
      return response;
    });
  },

  createPostingAccount(type) {
    return ApiService.createPostingAccount(type).then((response) => {
      AccountingStore.fetchAccounts();
      return response;
    });
  },

  deletePostingAccount(id) {
    return ApiService.deletePostingAccount(id).then((response) => {
      AccountingStore.fetchAccounts();
      return response;
    });
  },

  //Postings
  createPosting(posting) {
    return ApiService.createPosting(posting).then((response) => {
      AccountingStore.fetchPostings();
    });
  },

  updatePosting(posting) {
    AccountingStore.updateArrayObject(posting, "postings"); //Optimistic updating
    return ApiService.updatePosting(posting).then((response) => {
      AccountingStore.fetchPostings();
      return response;
    });
  },

  deletePosting(id) {
    return ApiService.deletePosting(id).then((response) => {
      AccountingStore.fetchPostings();
      return response;
    });
  },

  //Case document assignments
  createCaseDocumentAssignment(caseDocumentAssignment) {
    return ApiService.createCaseDocumentAssignment(caseDocumentAssignment).then(
      (response) => {
        DataStore.fetchCases();
        DataStore.fetchDocuments();
        return response;
      }
    );
  },

  deleteCaseDocumentAssignment(caseId, documentId) {
    return ApiService.deleteCaseDocumentAssignment(caseId, documentId).then(
      (response) => {
        DataStore.fetchCases();
        DataStore.fetchDocuments();
        return response;
      }
    );
  },

  //Item prices
  createItemPrice(itemPrice) {
    return ApiService.createItemPrice(itemPrice).then((response) => {
      DataStore.fetchItemPrices();
      return response;
    });
  },

  updateItemPrice(itemPrice) {
    DataStore.updateArrayObject(itemPrice, "itemPrices"); //Optimistic updating
    return ApiService.updateItemPrice(itemPrice).then((response) => {
      DataStore.fetchItemPrices();
      return response;
    });
  },

  deleteItemPrice(id) {
    return ApiService.deleteItemPrice(id).then((response) => {
      DataStore.fetchItemPrices();
      return response;
    });
  },

  //Item price types
  createItemPriceType(model) {
    return ApiService.createItemPriceType(model).then((response) => {
      DataStore.fetchItemPriceTypes();
      return response;
    });
  },

  updateItemPriceType(model) {
    DataStore.updateArrayObject(model, "itemPriceTypes"); //Optimistic updating
    return ApiService.updateItemPriceType(model).then((response) => {
      DataStore.fetchItemPriceTypes();
      DataStore.fetchItemPrices();
      return response;
    });
  },

  deleteItemPriceType(id) {
    return ApiService.deleteItemPriceType(id).then((response) => {
      DataStore.fetchItemPriceTypes();
      DataStore.fetchItemPrices();
    });
  },

  //Bookkeeping draft entries
  createBookkeepingDraftEntry(model, insertIndex) {
    return ApiService.createBookkeepingDraftEntry(model)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Entry could not be created");
        }
        let newResponse = response.clone();
        response.json().then((newEntry) => {
          DataStore.updateArrayObject(
            newEntry,
            "bookKeepingDraftEntries",
            insertIndex
          );
          if (model.receipt) {
            DataStore.updateDocumentState({
              ...model.receipt,
              receiptNumber: newEntry.receiptNumber,
            });
          }
        });
        return newResponse;
      })
      .catch((reason) => {
        console.log(reason);
        return { ok: false };
      });
    // return this.addResultOnSuccess(() => ApiService.createBookkeepingDraftEntry(model), 'bookKeepingDraftEntries');
  },

  async createBookkeepingDraftEntryList(list = []) {
    let response = await ApiService.createBookkeepingDraftEntryList(list);
    if (!response.ok) {
      throw new Error("Entries could not be created");
    }
    let newResponse = response.clone();
    let newEntries = await response.json();
    DataStore.setState({
      bookKeepingDraftEntries: [
        ...newEntries,
        ...DataStore.getStore().bookKeepingDraftEntries,
      ],
    });
    return newResponse;
    // postSettings.body = JSON.stringify(list);
    // return fetch(endPoint + '/api/BookkeepingDraftEntries/createList', postSettings);
  },

  updateBookkeepingDraftEntry(model) {
    this.updateBookkeepingDraftEntryState(model);
    return this.fetchOnFailure(
      () => ApiService.updateBookkeepingDraftEntry(model),
      () => DataStore.fetchBookkeepingDraftEntries()
    );
  },

  updateBookkeepingDraftEntryState(model) {
    if (
      !!model.receipt &&
      model.receipt.receiptNumber !== model.receiptNumber
    ) {
      model.receipt.receiptNumber = model.receiptNumber;
      DataStore.updateDocumentState({
        ...model.receipt,
        receiptNumber: model.receiptNumber,
      });
    }
    DataStore.updateArrayObject(model, "bookKeepingDraftEntries"); //Optimistic updating
  },

  updatePostingEntryReceipt(entry, receipt) {
    let newEntry = {
      ...entry,
      receipt,
      receiptId: !!receipt ? receipt.id : null,
    };
    DataStore.updateArrayObject(newEntry, "postingEntries"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.updateBookkeepingDraftEntry(newEntry),
      () => DataStore.fetchPostingEntries()
    );
  },

  sendBookkeepingDraftEntryToAccountant(model) {
    // DataStore.updateArrayObject({
    //   ...model,
    //   status: 'Approved'
    // }, 'bookKeepingDraftEntries'); //Optimistic updating
    // return this.fetchOnFailure(() => ApiService.sendBookkeepingDraftEntryToAccountant(model.id), () => DataStore.fetchBookkeepingDraftEntries());
    return this.addResultOnSuccess(
      () => ApiService.sendBookkeepingDraftEntryToAccountant(model.id),
      "bookKeepingDraftEntries"
    );
  },

  finalizeDraftEntries(entryIds, clientId) {
    // entryIds.forEach(id => {
    //   DataStore.removeArrayObject(id, 'bookKeepingDraftEntries'); //Optimistic
    // });
    return this.fetchOnSuccess(
      () => ApiService.finalizeEntries(entryIds, clientId),
      () => DataStore.fetchBookkeepingDraftEntries()
    );
  },

  // deleteBookkeepingDraftEntry(id) {
  //   DataStore.removeArrayObject(id, 'bookKeepingDraftEntries'); //Optimistic
  //   return this.fetchOnFailure(() => ApiService.deleteBookkeepingDraftEntry(id), () => DataStore.fetchBookkeepingDraftEntries());
  // },

  deleteBookkeepingDraftEntryAndReceipt(entry) {
    DataStore.removeArrayObject(entry.id, "bookKeepingDraftEntries"); //Optimistic
    if (!!entry.receipt) {
      DataStore.removeDocument(entry.receipt.id);
    }
    return this.fetchOnFailure(
      () => ApiService.deleteBookkeepingDraftEntryAndReceipt(entry.id),
      () => DataStore.fetchBookkeepingDraftEntries()
    );
  },

  printFilteredReceipts(printSettings) {
    return ApiService.printFilteredReceipts(printSettings);
    // .then( response => {
    //   if(response.ok) {
    //     let newResponse = response.clone(); //Allows outside rerunning of response.json()
    //     response.json(newFiles => {
    //       DataStore.addDocuments(newFiles);
    //     });
    //     DataStore.fetchStorageInformation();
    //     return newResponse;
    //   }
    //   return response;
    // })
  },

  //Finance account plans
  createFinanceAccountPlan(model) {
    // DataStore.updateArrayObject(model, 'financeAccountPlans'); //Optimistic updating
    return this.fetchOnSuccess(
      () => ApiService.createFinanceAccountPlan(model),
      () => DataStore.fetchFinanceAccountPlans()
    );
  },

  copyFinanceAccountPlan(id) {
    return ApiService.copyFinanceAccountPlan(id).then((response) => {
      if (response.ok) {
        DataStore.fetchFinanceAccountPlans();
      }
      return response;
    });
  },

  async copyPlanToClient(params = { planId: 0, clientId: 0 }) {
    let response = await ApiService.copyPlanToClient(params);
    if (response.ok) {
      DataStore.fetchClientPlan();
      DataStore.fetchBookkeepingDraftEntries();
    }
    return response;
  },

  importFinanceAccountPlan(planData) {
    return ApiService.importFinanceAccountPlan(planData).then((response) => {
      if (response.ok) {
        DataStore.fetchFinanceAccountPlans();
      }
      return response;
    });
  },

  updateFinanceAccountPlan(model) {
    DataStore.updateFinanceAccountPlan(model);
    // DataStore.updateArrayObject(model, 'financeAccountPlans'); //Optimistic updating
    return this.fetchOnCompletion(
      () => ApiService.updateFinanceAccountPlan(model),
      () => {
        if (model.id === DataStore.getStore().clientPlan.id) {
          DataStore.fetchClientPlan();
        } else {
          DataStore.fetchFinanceAccountPlans();
        }
      }
    );
  },

  deleteFinanceAccountPlan(id) {
    DataStore.removeArrayObject(id, "financeAccountPlans"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.deleteFinanceAccountPlan(id),
      () => DataStore.fetchFinanceAccountPlans()
    );
  },

  //Finance accounts
  createFinanceAccount(model) {
    return this.handleReturnedObject(
      () => ApiService.createFinanceAccount(model),
      (newAccount) => {
        DataStore.createFinanceAccount(newAccount);
      }
    );
  },

  updateFinanceAccount(model) {
    DataStore.updateFinanceAccount(model);
    return this.fetchOnFailure(
      () => ApiService.updateFinanceAccount(model),
      () => DataStore.fetchFinanceAccountPlans()
    );
  },

  async deleteFinanceAccount(account) {
    let response = await ApiService.deleteFinanceAccount(account.id);
    if (!response.ok) {
      return false;
    }
    DataStore.removeFinanceAccount(account.financeAccountPlanId, account.id);
  },

  //Tax specifications
  //Updating is handled by the SignalR server
  createTaxSpecification(model) {
    return ApiService.createTaxSpecification(model);
  },

  updateTaxSpecification(model) {
    return ApiService.updateTaxSpecification(model);
  },

  deleteTaxSpecification(id) {
    return ApiService.deleteTaxSpecification(id);
  },

  //Fiscal years
  createFiscalYear(model) {
    return this.addResultOnSuccess(
      () => ApiService.createFiscalYear(model),
      "fiscalYears"
    );
  },

  updateFiscalYear(model) {
    DataStore.updateArrayObject(model, "fiscalYears");
    return this.fetchOnFailure(
      () => ApiService.updateFiscalYear(model),
      () => DataStore.fetchFiscalYears()
    );
  },

  deleteFiscalYear(id) {
    DataStore.removeArrayObject(id, "fiscalYears"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.deleteFiscalYear(id),
      () => DataStore.fetchFiscalYears()
    );
  },

  //Posting journals
  async rollbackPostingJournal(id) {
    return this.fetchOnSuccess(
      () => ApiService.rollbackPostingJournal(id),
      () => {
        DataStore.fetchBookkeepingDraftEntries();
        DataStore.fetchPostingJournals();
        DataStore.fetchPostingEntries();
      }
    );
  },

  //Accounting automation
  settleVat(query) {
    return this.fetchOnSuccess(
      () => ApiService.settleVat(query),
      () => DataStore.fetchBookkeepingDraftEntries()
    );
  },

  payVat(query) {
    return this.fetchOnSuccess(
      () => ApiService.payVat(query),
      () => DataStore.fetchBookkeepingDraftEntries()
    );
  },

  transferBalance(query) {
    return this.fetchOnSuccess(
      () => ApiService.transferBalance(query),
      () => DataStore.fetchBookkeepingDraftEntries()
    );
  },

  //Bank postings
  updateBankPosting(model) {
    DataStore.updateArrayObject(model, "bankPostings");
    return this.fetchOnFailure(
      () => ApiService.updateBankPosting(model),
      () => DataStore.fetchBankPostings()
    );
  },

  deleteBankPosting(id) {
    DataStore.removeArrayObject(id, "bankPostings"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.deleteBankPosting(id),
      () => DataStore.fetchBankPostings()
    );
  },

  deleteAllBankPostings(clientId) {
    DataStore.setState({
      bankPostings: [],
    });
    return this.fetchOnFailure(
      () => ApiService.deleteAllBankPostings(clientId),
      () => DataStore.fetchBankPostings()
    );
  },

  addReconciliation(reconciliation = { entryId: 0, bankPostingId: 0 }) {
    DataStore.addReconciliation(reconciliation);
    return this.fetchOnFailure(
      () => ApiService.addReconciliation(reconciliation),
      () => {
        DataStore.fetchBookkeepingDraftEntries();
        DataStore.fetchBankPostings();
      }
    );
  },

  removeReconciliation(reconciliation = { entryId: 0, bankPostingId: 0 }) {
    DataStore.removeReconciliation(reconciliation);
    return this.fetchOnFailure(
      () => ApiService.removeReconciliation(reconciliation),
      () => {
        DataStore.fetchPostingEntries();
        DataStore.fetchBankPostings();
      }
    );
  },

  printHtmlToPDF(printSettings) {
    return ApiService.printHtmlToPDF(printSettings).then((response) => {
      DataStore.fetchDocuments();
      return response;
    });
  },

  createSigningRequest(request) {
    return this.fetchOnSuccess(
      () => ApiService.createSigningRequest(request),
      () => DataStore.fetchSingleDocument(request.fileInfoModelId)
    );
  },

  updateSigningRequest(request) {
    console.log(request);
    return this.fetchOnSuccess(
      () => ApiService.updateSigningRequest(request),
      () => DataStore.fetchSingleDocument(request.fileInfoModelId)
    );
  },

  //ApplicationUD
  createApplicationUD(model) {
    return this.addResultOnSuccess(
      () => ApiService.createApplicationUD(model),
      "applicationUDs"
    );
  },

  updateApplicationUD(model) {
    DataStore.updateArrayObject(model, "applicationUDs");
    return this.fetchOnFailure(
      () => ApiService.updateApplicationUD(model),
      () => DataStore.fetchApplicationUDs()
    );
  },

  deleteApplicationUD(id) {
    DataStore.removeArrayObject(id, "applicationUDs"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.deleteApplicationUD(id),
      () => DataStore.fetchApplicationUDs()
    );
  },
  //BusinessOperating
  createBusinessOperating(model) {
    return this.addResultOnSuccess(
      () => ApiService.createBusinessOperating(model),
      "businessOperatings",
      model
    );
  },

  updateBusinessOperating(model) {
    DataStore.updateArrayObject(model, "businessOperatings");
    return this.fetchOnFailure(
      () => ApiService.updateBusinessOperating(model),
      () => DataStore.fetchBusinessOperatings()
    );
  },

  deleteBusinessOperating(id) {
    DataStore.removeArrayObject(id, "businessOperatings"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.deleteBusinessOperating(id),
      () => DataStore.fetchBusinessOperatings()
    );
  },

  //PowerOfAttorney
  createPowerOfAttorney(model) {
    return this.addResultOnSuccess(
      () => ApiService.createPowerOfAttorney(model),
      "powerOfAttorneys"
    );
  },

  updatePowerOfAttorney(model) {
    DataStore.updateArrayObject(model, "powerOfAttorneys");
    return this.fetchOnFailure(
      () => ApiService.updatePowerOfAttorney(model),
      () => DataStore.fetchPowerOfAttorneys()
    );
  },

  deletePowerOfAttorney(id) {
    DataStore.removeArrayObject(id, "powerOfAttorneys"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.deletePowerOfAttorney(id),
      () => DataStore.fetchPowerOfAttorneys()
    );
  },

  // EmploymentContract
  createEmploymentContract(model) {
    return this.addResultOnSuccess(
      () => ApiService.createEmploymentContract(model),
      "employmentContracts"
    );
  },

  updateEmploymentContract(model) {
    DataStore.updateArrayObject(model, "employmentContracts");
    return this.fetchOnFailure(
      () => ApiService.updateEmploymentContract(model),
      () => DataStore.fetchEmploymentContracts()
    );
  },

  deleteEmploymentContract(id) {
    DataStore.removeArrayObject(id, "employmentContracts"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.deleteEmploymentContract(id),
      () => DataStore.fetchEmploymentContracts()
    );
  },

  //Template
  createProduct(model) {
    return this.addResultOnSuccess(
      () => ApiService.createProduct(model),
      "products",
      model
    );
  },

  updateProduct(model) {
    DataStore.updateArrayObject(model, "products");
    return this.fetchOnFailure(
      () => ApiService.updateProduct(model),
      () => DataStore.fetchProducts()
    );
  },

  deleteProduct(id) {
    DataStore.removeArrayObject(id, "products"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.deleteProduct(id),
      () => DataStore.fetchProducts()
    );
  },

  //Template
  createModelTemplate(model) {
    return this.addResultOnSuccess(
      () => ApiService.createModelTemplate(model),
      "modelTemplates",
      model
    );
  },

  updateModelTemplate(model) {
    DataStore.updateArrayObject(model, "modelTemplates");
    return this.fetchOnFailure(
      () => ApiService.updateModelTemplate(model),
      () => DataStore.fetchModelTemplates()
    );
  },

  deleteModelTemplate(id) {
    DataStore.removeArrayObject(id, "modelTemplates"); //Optimistic updating
    return this.fetchOnFailure(
      () => ApiService.deleteModelTemplate(id),
      () => DataStore.fetchModelTemplates()
    );
  },
};
