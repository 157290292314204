// @ts-check
import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import UserInfoService from "../../services/UserInfoService";
import moment from "moment";
import { Image } from "react-bootstrap";
import { AuthorizationService } from "../../services/AxoServices";

import { Icon, FileViewerModal, AxoLocal } from "../../utilities/LexUtilities";

export default class SocialComment extends React.PureComponent {
  //Props
  //comment
  //userProfile
  renderDeleteIcon = () => {
    let { comment, userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);

    //Admin or current user
    if (userType !== "Admin" && userProfile.id !== comment.userProfile.id) {
      return null;
    }
    return (
      <span
        role="button"
        onClick={this.props.onDeleteComment.bind(this, comment.id)}
      >
        <AxoLocal entity="axoidcode179" defaultValue={"Slet"} /> &nbsp;
        <Icon glyph="icon-fontello-scissors" className="axored" />
      </span>
    );
  };

  renderImageFooter = () => {
    var comment = this.props.comment;
    if (!comment.image) {
      return null;
    }
    return (
      <div style={{ margin: 10, marginTop: 25 }}>
        <Image
          className="social-image"
          src={ApiService.getFileInlinePath(comment.image.id)}
        />
      </div>
    );
  };

  onShowFile = (document) => {
    this.fileModal.open([document]);
  };

  renderAttachmentFooter = () => {
    var attachment = this.props.comment.attachment;
    if (!attachment) {
      return null;
    }
    return (
      <div>
        <span>
          <strong
            role="button"
            onClick={this.onShowFile.bind(this, attachment)}
            className="editable axopaddingLeftSocial"
          >
            {attachment.fileName}
          </strong>
          &ensp;
          <span>({attachment.sizeString})</span>
        </span>
      </div>
    );
  };

  render() {
    var { comment, userProfile } = this.props;
    var commentUser = comment.userProfile;
    return (
      <div
        className="comment-box"
        style={{ borderBottom: "1px solid #EAEDF1" }}
      >
        <img
          alt=""
          className="img-circle"
          src={UserInfoService.getProfileImageSource(commentUser)}
          width="30"
          height="30"
          style={{ verticalAlign: "top", top: 10, position: "relative" }}
        />
        <div className="poster-info">
          <div>{UserInfoService.getDisplayName(commentUser)}</div>
          <div className="axopaddingLeftSocial">
            <br />
            <small>
              <span
                dangerouslySetInnerHTML={{ __html: comment.content }}
              ></span>
            </small>
          </div>
        </div>
        <div className="post-date hidden-sm hidden-xs fg-text text-right">
          <div>{this.renderDeleteIcon()}</div>
          <div>
            <small>
              <strong>{moment(comment.dateTime).format("L LT")}</strong>
            </small>
          </div>
        </div>
        <div className="axopaddingLeftSocial">
          {this.renderImageFooter()}
          {this.renderAttachmentFooter()}
        </div>
        <FileViewerModal
          size="large"
          userProfile={userProfile}
          ref={(c) => (this.fileModal = c)}
        />
      </div>
    );
  }
}
