import React from "react";
import ApiService from "../services/DataAccess/ApiService";
import RoutingService from "../services/RoutingService";
import LoadingIndicator from "../utilities/LoadingIndicator";
import QueryString from "query-string";

import { AxoLocal, Link, AxoFooter } from "../utilities/LexUtilities";

export default class ConfimEmail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
    };
  }

  componentDidMount() {
    let query = QueryString.parse(this.props.location.search);
    let { userId, code } = query;

    ApiService.finishConfirmEmail({ userId, code })
      .then((response) => {
        if (response.ok) {
          this.setState({ status: 1 });
        } else {
          this.setState({ status: 2 });
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ status: 2 });
      });
  }

  onLogin = () => {
    this.props.history.push(RoutingService.getAccountPath("Login"));
  };

  render() {
    let { status } = this.state;
    if (status === 1) {
      return (
        <div className="text-center SideHeight" style={{ paddingTop: "10px" }}>
          <h2>
            <AxoLocal
              entity="ConfirmedEmail"
              defaultValue={"Email bekræftet"}
            />
          </h2>
          <div>
            <p>
              <span className="Unicodesize" style={{ color: "#4CAF50" }}>
                &#8730;
              </span>{" "}
              &nbsp;&nbsp;
              <AxoLocal
                entity="ConfirmedEmailLong"
                defaultValue={"Din email adresse er blevet bekræftet."}
              />
              <Link to={RoutingService.getAccountPath("Login")}>
                &nbsp;&nbsp;
                <span className="Unicodesize">&#9755;</span>&nbsp;&nbsp;
                <AxoLocal
                  entity="ConfirmedEmailClickHere"
                  defaultValue={"Klik her for at logge ind."}
                />
              </Link>
            </p>
          </div>
          <AxoFooter />
        </div>
      );
    } else if (status === 2) {
      return (
        <div className="text-center" style={{ paddingTop: "10px" }}>
          <div>
            <p>
              <h4>
                <AxoLocal
                  entity="ConfirmedEmailError"
                  defaultValue={
                    "Din email kunne ikke bekræftes. Tjeck at linket er korrekt."
                  }
                />
              </h4>
            </p>
          </div>
          <AxoFooter />
        </div>
      );
    }
    return (
      <div style={{ position: "relative" }}>
        <LoadingIndicator />
        <AxoFooter />
      </div>
    );
  }
}
