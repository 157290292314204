import React from "react";
import PropTypes from "prop-types";
import BookkeepingView from "./BookkeepingView";
import moment from "moment";

import { StorageService, ApiService } from "../../../services/AxoServices";

import { withRouter } from "../../../utilities/LexUtilities";

const ViewType = {
  NORMAL: 0,
  SCAN: 1,
  TRASH: 2,
};

class BookkeepingContainer extends React.PureComponent {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    contactMap: PropTypes.object.isRequired,
    selectedContact: PropTypes.object.isRequired,
    onlyTable: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedContactString: "",
      forceShowCards: StorageService.loadItem("showBookkeepingCards", false),
      readingCSV: false,
      showCSVSuccess: false,
      showCSVError: false,
      postedAccountEntries: [],
      showOnlyImportant: true,
      highlightEntryId: 0,
      viewType: props.viewType,
    };
  }

  onToggleType = () => {
    let { viewType } = this.state;

    if (viewType !== ViewType.TRASH) {
      this.setState({ viewType: ViewType.TRASH });
    } else {
      this.setState({ viewType: this.props.viewType });
    }
  };

  fetchPostedAccountEntries = async (accountId) => {
    let { selectedContact, entries, fiscalYears, financeAccountMap } =
      this.props;

    if (entries.length === 0) {
      return false;
    }

    let account = financeAccountMap[accountId];
    if (!account) {
      return;
    }

    let entry = entries[0]; //All entries should use the same fiscal year
    let entryDate = moment.utc(entry.creationDate).startOf("day");
    let fiscalYear = fiscalYears.find((f) => {
      //Fiscal year dates are utc, but we pretend they are local dates to avoid time zone problems.
      var fStart = moment
        .utc(moment.utc(f.startDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
        .startOf("day");
      var fEnd = moment
        .utc(moment.utc(f.endDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
        .startOf("day");

      return !entryDate.isBefore(fStart) && !entryDate.isAfter(fEnd);
    });

    if (!fiscalYear) {
      return false;
    }

    let query = {
      clientId: selectedContact.id || "",
      filter: "Posted",
      fiscalYearId: fiscalYear.id,
      accountNumber: account.number,
    };

    try {
      let postedAccountEntries = await ApiService.getBookkeepingEntries(query);

      this.setState({
        postedAccountEntries,
      });

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  onToggleCards = () => {
    let forceShowCards = !this.state.forceShowCards;
    this.setState({ forceShowCards });
    StorageService.saveItem("showBookkeepingCards", forceShowCards);
  };

  // componentDidMount() {
  //   let { storeFunctions } = this.props;

  //   storeFunctions.fetchFiscalYears();
  //   storeFunctions.fetchBookkeepingDraftEntries();
  // }

  handleSelectedClient = (selectedClient) => {
    let { storeFunctions, selectedContact } = this.props;

    if (selectedClient.id === selectedContact.id) {
      return;
    }

    storeFunctions.setState({
      fiscalYears: null,
      bookKeepingDraftEntries: [],
      selectedContact: selectedClient, //Avoiding race conditions. To be refactored later.
    });

    storeFunctions.setSelectedContact(selectedClient);

    storeFunctions.fetchFiscalYears();
    storeFunctions.fetchBookkeepingDraftEntries();
    storeFunctions.fetchClientPlan();
  };

  onCSVChange = async (event) => {
    let { selectedContact, storeFunctions } = this.props;
    let uploader = event.target;
    if (uploader.files.length === 0) {
      return;
    }
    try {
      this.setState({ readingCSV: true });
      let response = await ApiService.readEntriesFromCSV(
        uploader.files,
        selectedContact.id
      );
      this.setState({ readingCSV: false });
      if (response.ok) {
        storeFunctions.fetchBookkeepingDraftEntries();
        this.setState({ showCSVSuccess: true });
        setTimeout(() => {
          this.setState({
            showCSVSuccess: false,
          });
        }, 4000);
      } else {
        throw new Error("Could not read CSV");
      }
    } catch (exception) {
      console.log(exception);
      this.setState({
        showCSVError: true,
        readingCSV: false,
      });
      setTimeout(() => {
        this.setState({
          showCSVError: false,
        });
      }, 4000);
    } finally {
      uploader.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  onCopy = async (entry) => {
    let { viewType } = this.state;

    let newEntry = {
      ...entry,
      status: viewType === ViewType.NORMAL ? "Approved" : "Created",
      balanceFinanceAccountId: null,
      postingDate: null,
    };
    delete newEntry.id;
    delete newEntry.receiptNumber;

    let { entries } = this.props;

    let index = entries.findIndex((e) => e.id === entry.id);

    let result = await this.doCreateEntry(newEntry, index);
    return result;
  };

  doCreateEntry = async (entry, index) => {
    try {
      let response = await this.props.actions.createBookkeepingDraftEntry(
        entry,
        index
      );
      if (response.ok) {
        let createdEntry = await response.json();
        this.setState({ highlightEntryId: createdEntry.id });
        setTimeout(() => {
          this.setState({ highlightEntryId: 0 });
        }, 5000);
      }
      return response.ok;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  onSelectFiscalYear = (fiscalYearId) => {
    let { storeFunctions, draftSimulationResult } = this.props;

    storeFunctions.selectFiscalYear(fiscalYearId);
    storeFunctions.fetchAccountingReport();

    if (
      !!draftSimulationResult &&
      Object.keys(draftSimulationResult.accountResults).length > 0
    ) {
      storeFunctions.fetchDraftSimulation();
    }
  };

  onSelectStartDate = (selectedStartDate) => {
    let { storeFunctions, draftSimulationResult } = this.props;

    storeFunctions.updateProperty("selectedFiscalStartDate", selectedStartDate);
    storeFunctions.fetchAccountingReport();

    if (
      !!draftSimulationResult &&
      Object.keys(draftSimulationResult.accountResults).length > 0
    ) {
      storeFunctions.fetchDraftSimulation();
    }
  };

  onSelectEndDate = (selectedEndDate) => {
    let { storeFunctions, draftSimulationResult } = this.props;

    storeFunctions.updateProperty("selectedFiscalEndDate", selectedEndDate);
    storeFunctions.fetchAccountingReport();

    if (
      !!draftSimulationResult &&
      Object.keys(draftSimulationResult.accountResults).length > 0
    ) {
      storeFunctions.fetchDraftSimulation();
    }
  };

  onCheckShowOnlyImportant = (event) => {
    this.setState({ showOnlyImportant: event.target.checked });
  };

  render() {
    let { fiscalYears, selectedFiscalYearId, entries, importantEntryIds } =
      this.props;

    let { showOnlyImportant, viewType } = this.state;

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let filteredEntries = entries;

    if (viewType === ViewType.NORMAL) {
      filteredEntries = filteredEntries.filter(
        (e) => !e.trashed && e.status === "Approved"
      );
    } else if (viewType === ViewType.SCAN) {
      filteredEntries = filteredEntries.filter(
        (e) => !e.trashed && e.status === "Created"
      );
    } else if (viewType === ViewType.TRASH) {
      filteredEntries = filteredEntries.filter((e) => e.trashed);
    }

    if (
      !!importantEntryIds &&
      importantEntryIds.size > 0 &&
      showOnlyImportant
    ) {
      let importantEntries = filteredEntries.filter((e) =>
        importantEntryIds.has(e.id)
      );

      //Show all if no important entries are found
      filteredEntries =
        importantEntries.length > 0 ? importantEntries : filteredEntries;
    }

    return (
      <BookkeepingView
        {...this.props}
        {...this.state}
        entries={filteredEntries}
        selectedFiscalYear={selectedFiscalYear}
        handleSelectedClient={this.handleSelectedClient}
        onToggleCards={this.onToggleCards}
        onCSVChange={this.onCSVChange}
        onCopy={this.onCopy}
        fetchPostedAccountEntries={this.fetchPostedAccountEntries}
        onSelectFiscalYear={this.onSelectFiscalYear}
        onSelectStartDate={this.onSelectStartDate}
        onSelectEndDate={this.onSelectEndDate}
        onCheckShowOnlyImportant={this.onCheckShowOnlyImportant}
        onToggleType={this.onToggleType}
      />
    );
  }
}

export default withRouter(BookkeepingContainer);
