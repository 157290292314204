import React from "react";
import { RIEInput } from "./Riek";
import PropTypes from "prop-types";

export default class InlineEdit extends React.Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    change: PropTypes.func.isRequired,
    className: PropTypes.string,
    editStyle: PropTypes.object,
    validate: PropTypes.func,
  };

  shouldComponentUpdate(nextProps) {
    return this.props.value !== nextProps.value; //ToDo: Make input edit state a prop.
  }

  render() {
    let { value, change, validate } = this.props;
    let className = this.props.className || "editable";
    let editStyle = this.props.editStyle || { width: "100%" };
    return (
      <RIEInput
        value={value || "---"}
        change={change}
        propName="value"
        className={className}
        editProps={{ style: editStyle }}
        validate={validate}
      />
    );
  }
}
