import React from "react";

import Conditional from "../utilities/Conditional";

import { Button, ButtonGroup } from "react-bootstrap";

import { Icon } from "../utilities/LexUtilities";

export default class LexNavButton extends React.PureComponent {
  //Props
  //glyph
  //children
  //ls
  //onClick
  //eventKey
  //activeTab
  //btnWidth
  static defaultProps = {
    eventKey: "",
    activeTab: "",
    path: "",
  };
  getButtonClass = (className) => {
    let { eventKey, activeTab, path } = this.props;

    var classes = "";
    if (
      eventKey === activeTab ||
      path.toLowerCase().includes(eventKey.toLowerCase())
    ) {
      classes = "Lex-button-2 active";
    } else {
      classes = "Lex-button-2";
    }
    return className ? className + " " + classes : classes;
  };

  renderContent = () => {
    var { className, iconSize } = this.props;
    var bsStyle = this.props.btnWidth
      ? { width: this.props.btnWidth }
      : this.props.style;
    if (this.props.ls) {
      return (
        <Button
          style={bsStyle}
          className={this.getButtonClass(className)}
          onClick={this.props.onClick.bind(this, this.props.eventKey)}
        >
          <Conditional inline show={!!this.props.glyph}>
            <Icon
              style={iconSize ? { fontSize: iconSize } : null}
              glyph={this.props.glyph || ""}
            />
            &nbsp;
          </Conditional>
          {this.props.children}
        </Button>
      );
    } else {
      return (
        <Button
          style={bsStyle}
          className={this.getButtonClass(className)}
          onClick={this.props.onClick.bind(this, this.props.eventKey)}
        >
          {!!this.props.glyph ? (
            <div>
              <Icon
                style={iconSize ? { fontSize: iconSize } : null}
                glyph={this.props.glyph || ""}
              />
              &nbsp;
            </div>
          ) : null}
          <div>{this.props.children}</div>
        </Button>
      );
    }
  };

  render() {
    let { listButton } = this.props;

    return (
      <ButtonGroup className={listButton ? "showListButton" : ""}>
        {this.renderContent()}
      </ButtonGroup>
    );
  }
}
