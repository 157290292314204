import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import UserInfoService from "../../services/UserInfoService";
import { Table, Column, Cell } from "fixed-data-table-2";
import SortHeaderCell from "../../utilities/FixedDataTable/SortHeaderCell";
import DataListWrapper from "../../utilities/FixedDataTable/DataListWrapper";
import moment from "moment";

import {
  Row,
  Col,
  Grid,
  FormControl,
  FormGroup,
  InputGroup,
} from "react-bootstrap";

import {
  Icon,
  FileViewerModal,
  TableBase,
  Dimensions,
  AxoLocal,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  fileName: {
    width: 300,
    shown: true,
  },
  sharingDate: {
    width: 200,
    shown: true,
  },
  sharedString: {
    width: 350,
    shown: true,
  },
  ownerString: {
    width: 350,
    shown: true,
  },
  actions: {
    width: 50,
    shown: true,
  },
};

class DocumentSharedTableView extends TableBase {
  //Props
  //documents
  constructor(props) {
    super(props);
    this.name = "DocumentSharedTableView";
    this.defaultColumnDefs = defaultColumnDefs;

    let tableEntries = props.sharedDocuments.concat(props.documents);
    tableEntries = tableEntries.sort((l, r) => {
      return moment(r.sharingDate).valueOf() - moment(l.sharingDate).valueOf();
    });
    tableEntries = this.addDataStringsToDocuments(tableEntries);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(tableEntries);
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 350,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      deletingdocumentId: 0,
    };
  }

  addDataStringsToDocuments = (entries) => {
    return entries.map((document) => {
      var sortDocument = Object.assign({}, document);
      sortDocument.sharedString =
        !sortDocument.owner &&
        sortDocument.sharedUsers &&
        sortDocument.sharedUsers.length > 0
          ? UserInfoService.getDisplayName(sortDocument.sharedUsers[0])
          : "";
      sortDocument.ownerString = sortDocument.owner
        ? sortDocument.owner.toString()
        : "";
      return sortDocument;
    });
  };

  componentWillReceiveProps(nextProps) {
    var tableEntries = nextProps.sharedDocuments.concat(nextProps.documents);
    tableEntries = tableEntries.sort((l, r) => {
      return moment(r.sharingDate).valueOf() - moment(l.sharingDate).valueOf();
    });
    tableEntries = this.addDataStringsToDocuments(tableEntries);
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  onClickFileName = (documentId, event) => {
    event.preventDefault();
    var selectedFile = this.state.tableEntries.find(
      (file) => file.id === parseInt(documentId, 10)
    );
    this.fileModal.open([selectedFile]);
  };

  onRemoveUser = (userProfile, document) => {
    DataActions.removeFileSharing({ document, userProfile });
  };

  renderUserNodes = (document) => {
    if (document.owner) {
      return null;
    }
    return document.sharedUsers.map((p) => {
      return (
        <span
          key={p.id}
          style={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "white",
          }}
        >
          {UserInfoService.getDisplayName(p)}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Icon
            role="button"
            onClick={this.onRemoveUser.bind(this, p, document)}
            glyph="icon-fontello-scissors axored"
          />
        </span>
      );
    });
  };

  renderOwnerInfo = (document) => {
    if (!document.owner) {
      return null;
    }
    let { userProfile } = this.props;
    let owner = document.owner;
    return (
      <span
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "white",
        }}
      >
        <Icon
          className="editable"
          role="button"
          glyph="icon-fontello-share-1"
        />{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {UserInfoService.getDisplayName(owner)}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Icon
          role="button"
          onClick={this.onRemoveUser.bind(this, userProfile, document)}
          glyph="icon-fontello-scissors axored"
        />
      </span>
    );
  };

  onSearch = (event) => {
    var documents = this.getFilteredEntries(
      this.state.tableEntries,
      event.target.value
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(documents);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, documents),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }
    return entries.filter((f) => {
      return f.fileName.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  onTrashDocument = (documentId) => {
    this.setState({ deletingdocumentId: documentId });
    let doc = { ...this.props.documents.find((d) => d.id === documentId) };
    doc.trashed = true;
    DataActions.updateDocument(doc).then((response) => {
      this.setState({ deletingdocumentId: 0 });
    });
  };

  renderTable = () => {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth } = this.props;
    var tableWidth = containerWidth - 30;

    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <Col sm={4}>
                  <h4>
                    {" "}
                    <Icon glyph="icon-fontello-hourglass" />
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontWeight: "bold" }}
                      entity="DocumentTabViewShareddocuments"
                      defaultValue={"Delte dokumenter"}
                    />
                  </h4>
                </Col>
                <Col sm={4}>
                  <div style={{ paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-search" />
                        </InputGroup.Addon>
                        {/* Søg...  */}
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
                <Col
                  sm={4}
                  className="hidden-xs text-right"
                  style={{ paddingTop: "10px" }}
                ></Col>
              </Row>
            </Grid>
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <Table
              rowHeight={40}
              rowsCount={sortedDataList.getSize()}
              height={containerHeight}
              width={tableWidth}
              isColumnResizing={false}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              headerHeight={40}
            >
              <Column
                columnKey="fileName"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.fileName}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseDataTableFixedFilename"
                      defaultValue={"Filnavn"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var document = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={document.id} {...props}>
                      <a
                        href="document"
                        onClick={this.onClickFileName.bind(this, document.id)}
                      >
                        {document.fileName}
                      </a>
                    </Cell>
                  );
                }}
                width={columnDefs.fileName.width}
                isResizable={true}
              />
              <Column
                columnKey="sharingDate"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.sharingDate}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="axoidcode25" defaultValue={"Dato"} />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  let document = sortedDataList.getObjectAt(props.rowIndex);
                  let sharingDate = moment(document.sharingDate);
                  return (
                    <Cell key={document.id} {...props}>
                      {sharingDate.year() > 1970 ? sharingDate.format("L") : ""}
                    </Cell>
                  );
                }}
                width={columnDefs.sharingDate.width}
                isResizable={true}
              />
              <Column
                columnKey="sharedString"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.sharedString}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;&nbsp;&nbsp;{" "}
                    <AxoLocal
                      entity="DocumentTabViewSharedwiththeusers"
                      defaultValue={"Delt med brugerne"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var document = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={document.id} {...props}>
                      {this.renderUserNodes(document)}
                    </Cell>
                  );
                }}
                width={columnDefs.sharedString.width}
                isResizable={true}
              />
              <Column
                columnKey="ownerString"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.ownerString}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTabViewSharedfromtheuser"
                      defaultValue={"Delt fra brugerne"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var document = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={document.id} {...props}>
                      {this.renderOwnerInfo(document)}
                    </Cell>
                  );
                }}
                width={columnDefs.ownerString.width}
                isResizable={true}
              />
              <Column
                columnKey="actions"
                header={<Cell></Cell>}
                cell={(props) => {
                  var document = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={document.id} {...props}>
                      {!document.owner ? (
                        <div>
                          {this.state.deletingdocumentId === document.id ? (
                            <img
                              alt=""
                              src="/imgs/app/loading.gif"
                              className="img-circle"
                              width="20"
                              height="20"
                            />
                          ) : (
                            <Icon
                              role="button"
                              onClick={this.onTrashDocument.bind(
                                this,
                                document.id
                              )}
                              className="editable"
                              glyph="icon-fontello-trash-1"
                            />
                          )}
                        </div>
                      ) : null}
                    </Cell>
                  );
                }}
                width={columnDefs.actions.width}
                isResizable={true}
              />
            </Table>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} style={{ paddingTop: "10px", paddingLeft: "0px" }}>
            {this.renderTable()}
            <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 215;
  },
})(DocumentSharedTableView);
