import React from "react";
import Conditional from "../utilities/Conditional";

import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import {
  ApiService,
  LocalizationService,
  RoutingService,
} from "../services/AxoServices";

import {
  Icon,
  Link,
  ctx,
  AxoLocal,
  withRouter,
  AxoFooter,
} from "../utilities/LexUtilities";

import LicenseConsentForm from "./Licenses/LicenseConsentForm";
import DataConsentForm from "./Licenses/DataConsentForm";

class GroupLogin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      signupViewModel: {
        userName: "",
        email: "",
        confirmedEmail: "",
        password: "",
        confirmPassword: "",
        userGroupCode: "",
        country: "",
        dataProcessingConsentGiven: false,
      },
      attemptingSignup: false,
      validationState: {
        invalidUserName: false,
        invalidPassword: false,
        invalidCode: false,
        fullGroup: false,
        missingEmail: false,
        genericError: false,
      },
      acceptedLicenseConditions: false,
      showLicenseAcceptanceWarning: false,
      showDataConsentAcceptanceWarning: false,
      locale: "enUS",
    };
    this.userProfile = {};

    this.name = "GroupLogin";
  }

  onUpdateViewModelProperty = (propertyName, event) => {
    this.onUpdateViewModelPropertyValue(propertyName, event.target.value);
  };

  onUpdateViewModelPropertyValue = (propertyName, value) => {
    var viewModel = { ...this.state.signupViewModel };
    viewModel[propertyName] = value;
    this.setState({ signupViewModel: viewModel });
  };

  back(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.goBack();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    LocalizationService.subscribeToLocale(this.name, (locale) => {
      let currentLocale = locale.toLowerCase();

      if (currentLocale === "enus") {
        currentLocale = "enUS";
      }
      this.setState({ locale: currentLocale });
    });
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale(this.name);
  }

  onLogin = (event) => {
    event.preventDefault();
    this.props.history.push(RoutingService.getAccountPath("Login"));
  };

  onCreateUser = () => {
    if (!this.state.acceptedLicenseConditions) {
      this.setState({ showLicenseAcceptanceWarning: true });
      return;
    }

    if (!this.state.signupViewModel.dataProcessingConsentGiven) {
      this.setState({ showDataConsentAcceptanceWarning: true });
      return;
    }

    var validationState = Object.assign({}, this.state.validationState);
    var email = this.state.signupViewModel.email;
    if (!email || !this.validateEmail(email)) {
      validationState.missingEmail = true;
      this.setState({ validationState: validationState });
      return;
    }

    this.setState({ attemptingSignup: true });
    var signupViewModel = Object.assign({}, this.state.signupViewModel);
    signupViewModel.registerEmailSubject = ctx.getSync("SignupMailSubject");
    signupViewModel.registerEmailMessage = ctx.getSync("SignupMailMessage");
    signupViewModel.messageUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      RoutingService.getAccountPath("ConfirmEmail");
    signupViewModel.locale = this.state.locale;
    signupViewModel.userGroupCode =
      signupViewModel.userGroupCode || "InvalidCode";

    ApiService.register(signupViewModel)
      .then((response) => {
        validationState.invalidPassword = false;
        validationState.invalidUserName = false;
        if (response.status === 200) {
          this.props.history.push(
            RoutingService.getAccountPath("RequestConfirmEmail")
          );
          return Promise.resolve({});
        } else {
          return response.json();
        }
      })
      .then((message) => {
        if (message.error === "DuplicateUserName") {
          validationState.invalidUserName = true;
        } else if (message.error === "InvalidPassword") {
          validationState.invalidPassword = true;
        } else if (message.error === "InvalidGroupCode") {
          validationState.invalidCode = true;
        } else if (message.error === "FullGroup") {
          validationState.fullGroup = true;
        }
        this.setState({
          validationState: validationState,
          attemptingSignup: false,
        });
      })
      .catch((reason) => {
        validationState.genericError = true;
        this.setState({
          validationState: validationState,
          attemptingSignup: false,
        });
        setTimeout(() => {
          validationState.genericError = false;
          this.setState({ validationState: validationState });
        }, 5000);
      });
  };

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  getEmailValidationState = (email) => {
    if (email.length === 0) {
      return null;
    }
    return this.validateEmail(email) ? "success" : "error";
  };

  getConfirmedEmailValidationState = (confirmedEmail) => {
    if (confirmedEmail.length === 0) {
      return null;
    }
    return confirmedEmail === this.state.signupViewModel.email
      ? "success"
      : "error";
  };

  getPasswordValidationState = () => {
    var password = this.state.signupViewModel.password;
    if (!password) {
      return null;
    }
    return password.length >= 4 ? "success" : "error";
  };

  getConfirmPasswordValidationState = () => {
    var confirmPassword = this.state.signupViewModel.confirmPassword;
    if (!confirmPassword) {
      return null;
    }
    return confirmPassword === this.state.signupViewModel.password
      ? "success"
      : "error";
  };

  render() {
    var viewModel = this.state.signupViewModel;
    let {
      attemptingSignup,
      acceptedLicenseConditions,
      showLicenseAcceptanceWarning,
      showDataConsentAcceptanceWarning,
      locale,
    } = this.state;

    return (
      <div className="login" style={{ paddingTop: "25px" }}>
        <div>
          <div>
            <Grid fluid>
              <Row style={{ marginTop: "0px" }}>
                <Col xs={12} className="nopadding" style={{ maxWidth: "100%" }}>
                  <div className="frontPageaxolexdiv axolextopmenuButton">
                    <span>
                      <Icon glyph="icon-fontello-cog-7" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="Gruppeinformation5"
                        defaultValue={"Gruppelogin"}
                      />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="nopadding">
                  <div className="text-center">
                    <h5 style={{ margin: 0, padding: 15 }}>
                      <AxoLocal
                        entity="Gruppeinformation11A"
                        defaultValue={
                          "Vælg brugernavn og password. Har du tidligere gjort dette, så"
                        }
                      />
                      &nbsp;
                      <Link
                        style={{ color: "cblue" }}
                        to={RoutingService.getAccountPath("Login")}
                      >
                        <Icon glyph="icon-fontello-login" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="LoginonLogin"
                          defaultValue={"Log ind"}
                        />
                      </Link>
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={6}
                  mdOffset={3}
                  sm={8}
                  smOffset={2}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "15px" }}
                >
                  <Form>
                    <FormGroup controlId="UserGroupCode">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-key" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          autoFocus
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.userGroupCode}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "userGroupCode"
                          )}
                          componentAttribute="placeholder"
                          entity="GroupCodePlaceHolder"
                        />
                      </InputGroup>
                      {this.state.validationState.invalidCode ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="Gruppeinformation12"
                            defaultValue={"Koden er ugyldig"}
                          />
                        </div>
                      ) : null}
                      {this.state.validationState.fullGroup ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="Gruppeinformation13"
                            defaultValue={
                              "Gruppen er fuld. Kontakt din administrator."
                            }
                          />
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup controlId="UserName">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-user" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          autoFocus
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.userName}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "userName"
                          )}
                          componentAttribute="placeholder"
                          entity="bccMailplaceholderBrugernavn"
                        />
                      </InputGroup>
                      {this.state.validationState.invalidUserName ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupvalidationStateinvalidUserName"
                            defaultValue={"Brugernavnet er optaget."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      validationState={this.getEmailValidationState(
                        viewModel.email
                      )}
                      controlId="Email"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-mail" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="email"
                          className="borderFormControlfocus"
                          value={viewModel.email}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "email"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholdersupportAxolex"
                        />
                      </InputGroup>
                      {this.state.validationState.missingEmail ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupEmailrequired"
                            defaultValue={"Email adressen er påkrævet."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      validationState={this.getConfirmedEmailValidationState(
                        viewModel.confirmedEmail
                      )}
                      controlId="ConfirmedEmail"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-mail" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.confirmedEmail}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "confirmedEmail"
                          )}
                          componentAttribute="placeholder"
                          entity="signupViewConfirmEmail"
                        />
                      </InputGroup>
                      {!!viewModel.confirmedEmail &&
                      viewModel.confirmedEmail !== viewModel.email ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="signupViewConfirmEmailNoMatch"
                            defaultValue={"Email adressen matcher ikke."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      validationState={this.getPasswordValidationState()}
                      controlId="Password"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-key" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="password"
                          className="borderFormControlfocus"
                          value={viewModel.password}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "password"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholderviewModelpassword"
                        />
                      </InputGroup>
                      {this.state.validationState.invalidPassword ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupvalidationStateinvalidPassword"
                            defaultValue={
                              "Kodeordet skal indeholde mindst fire tegn."
                            }
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      validationState={this.getConfirmPasswordValidationState()}
                      controlId="ConfirmPassword"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-key" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="password"
                          className="borderFormControlfocus"
                          value={viewModel.confirmPassword}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "confirmPassword"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholderConfirmpassword"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Form>
                  <LicenseConsentForm
                    locale={locale}
                    checked={acceptedLicenseConditions}
                    onCheckChanged={(event) => {
                      this.setState({
                        acceptedLicenseConditions: event.target.checked,
                        showLicenseAcceptanceWarning: false,
                      });
                    }}
                    showWarning={showLicenseAcceptanceWarning}
                  />
                  <DataConsentForm
                    locale={locale}
                    checked={viewModel.dataProcessingConsentGiven}
                    onCheckChanged={(event) => {
                      this.onUpdateViewModelPropertyValue(
                        "dataProcessingConsentGiven",
                        event.target.checked
                      );
                      this.setState({
                        showDataConsentAcceptanceWarning: false,
                      });
                    }}
                    showWarning={showDataConsentAcceptanceWarning}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={6}
                  mdOffset={3}
                  sm={8}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <Form>
                    <FormGroup>
                      <Grid fluid>
                        <Row>
                          <Col xs={12} className="nopadding">
                            <Button
                              disabled={this.state.attemptingSignup}
                              className="Lex-button-2"
                              block
                              onClick={this.onCreateUser}
                              style={{
                                borderRadius: "0px",
                                marginLeft: "0px",
                                marginRight: "0px",
                                fontSize: "17px",
                                fontWeight: "normal",
                                height: "65px",
                              }}
                            >
                              <span>
                                {" "}
                                <Icon glyph="icon-fontello-user-add" />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <AxoLocal
                                  entity="LoginonLogin"
                                  defaultValue={"Log ind"}
                                />
                                <Conditional inline show={attemptingSignup}>
                                  &nbsp;
                                  <img
                                    alt=""
                                    src="/imgs/app/loading.gif"
                                    className="img-circle"
                                    width="20"
                                    height="20"
                                  />
                                </Conditional>
                              </span>{" "}
                            </Button>
                            <Conditional
                              show={this.state.validationState.genericError}
                            >
                              <div className="text-center axored">
                                <AxoLocal
                                  entity="Signupcreateanaccounterror"
                                  defaultValue={
                                    "An error occurred. Try again later."
                                  }
                                />
                              </div>
                            </Conditional>
                          </Col>
                        </Row>
                      </Grid>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <AxoFooter />
      </div>
    );
  }
}

export default withRouter(GroupLogin);
