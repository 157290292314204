import React from "react";

import { DataStore } from "../../../services/AxoServices";

import {
  Consumer,
  withRouter,
  withRTKData,
} from "../../../utilities/LexUtilities";

import AccountingResultsView from "./AccountingResultsView";

const DisplayStatus = {
  Both: 0,
  Result: 1,
  Balance: 2,
};

class AccountingResultsContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      displayStatus: DisplayStatus.Both,
    };
  }

  componentDidMount() {
    DataStore.fetchClientPlan();
    DataStore.fetchFiscalYears().then(() => {
      DataStore.fetchAccountingReport();
    });
  }

  mapStoreToProps = (store) => {
    let financeAccountMap = {};
    store.clientPlan.accounts.forEach((element) => {
      financeAccountMap[element.id] = element;
    });

    return {
      selectedPlan: store.clientPlan || { accounts: [] },
      financeAccounts: financeAccountMap,
      fiscalYears: store.fiscalYears,
      accountingReport: store.accountingReport,
      selectedFiscalYearId: store.selectedFiscalYearId,
      selectedStartDate: store.selectedFiscalStartDate,
      selectedEndDate: store.selectedFiscalEndDate,
      includeDraft: store.includeDraft,
      hideEmpty: store.hideEmpty,
    };
  };

  handleSelectedClient = (selectedClient) => {
    DataStore.setState({
      fiscalYears: null,
    });

    DataStore.setSelectedContact(selectedClient);

    DataStore.fetchClientPlan();
    DataStore.fetchFiscalYears().then(() => {
      DataStore.fetchAccountingReport();
    });
  };

  onSelectFiscalYear = (fiscalYearId) => {
    DataStore.selectFiscalYear(fiscalYearId);
    DataStore.fetchAccountingReport();
  };

  onSelectStartDate = (selectedStartDate) => {
    DataStore.updateProperty("selectedFiscalStartDate", selectedStartDate);
    DataStore.fetchAccountingReport();
  };

  onSelectEndDate = (selectedEndDate) => {
    DataStore.updateProperty("selectedFiscalEndDate", selectedEndDate);
    DataStore.fetchAccountingReport();
  };

  fetchAccountingReport = async () => {
    try {
      let response = await DataStore.fetchAccountingReport();
      return !!response;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  setDisplayStatus = (newStatus) => {
    this.setState({ displayStatus: parseInt(newStatus, 10) });
  };

  onChangeIncludeDraft = (includeDraft) => {
    DataStore.setState({ includeDraft });
    DataStore.fetchAccountingReport();
  };

  onChangeHideEmpty = (hideEmpty) => {
    DataStore.setState({ hideEmpty });
  };

  onNavigateToAccount = (number, event) => {
    event.preventDefault();

    let store = DataStore.getStore();

    DataStore.setState({
      accountStartNumber: number,
      accountEndNumber: number,
      selectedPostingStartDate: store.selectedFiscalStartDate,
      selectedPostingEndDate: store.selectedFiscalEndDate,
    });

    //ToDo: Set posting interval to the full fiscal year
    this.props.history.push("Postings");
  };

  render() {
    return (
      <Consumer>
        {(store) => (
          <AccountingResultsView
            {...this.mapStoreToProps(store)}
            {...this.props}
            {...this.state}
            handleSelectedClient={this.handleSelectedClient}
            onSelectFiscalYear={this.onSelectFiscalYear}
            onSelectStartDate={this.onSelectStartDate}
            onSelectEndDate={this.onSelectEndDate}
            fetchAccountingReport={this.fetchAccountingReport}
            setDisplayStatus={this.setDisplayStatus}
            onChangeIncludeDraft={this.onChangeIncludeDraft}
            onChangeHideEmpty={this.onChangeHideEmpty}
            onNavigateToAccount={this.onNavigateToAccount}
          />
        )}
      </Consumer>
    );
  }
}

export default withRTKData(withRouter(AccountingResultsContainer));
