import React from "react";
import TinyMCE from "react-tinymce";

import { ErrorBoundary } from "../../utilities/LexUtilities";

export default class MobileTinyMCE extends React.PureComponent {
  constructor(props) {
    super(props);

    this.editor = null;
  }
  //Props
  //text
  //onTextChange
  //height
  //setupEditor
  //locale
  setupEditor = (editor) => {
    this.editor = editor;

    editor.on("keyup", () => {
      this.props.onTextChange(editor.getContent());
    });
    editor.on("init", () => {
      this.initializeLineHeight(editor);
    });
    if (!this.props.setupEditor) {
      return;
    }
    if (!!this.props.setupEditor) {
      this.props.setupEditor(editor);
    }
  };

  initializeLineHeight(editor) {
    var content = editor.getContent();
    var newContent = content.replace(
      "<head>",
      "<head><style>body { line-height: 18pt; }</style>"
    );
    editor.setContent(newContent);
  }

  convertLocale = () => {
    var locale = this.props.locale.toLowerCase();
    switch (locale) {
      case "da":
        return "da";
      case "enus":
        return "en_GB"; //Changing language requires setting a non-default language
      case "ch":
        return "zh_CN";
      default:
        return "en_GB";
    }
  };

  onChange = (event) => {
    this.props.onTextChange(event.target.getContent());
  };

  setContent = (newContent) => {
    this.editor.setContent(newContent);
  };

  render() {
    var language = this.convertLocale();
    return (
      <div className="mce-no-border">
        <ErrorBoundary>
          <TinyMCE
            key={language}
            content={this.props.text}
            config={{
              height: this.props.height,
              branding: false,
              language: language,
              paste_data_images: true,
              image_advtab: true,
              valid_elements: "*[*]",
              valid_children: "*[*]",
              cleanup_on_startup: false,
              forced_root_block: false,
              trim_span_elements: false,
              verify_html: false,
              cleanup: false,
              convert_urls: false,
              statusbar: true,
              fontsize_formats:
                "8pt 10pt 12pt 14pt 18pt 24pt 32pt 36pt 38pt 40pt 72pt",
              lineheight_formats:
                "18pt 20pt 22pt 24pt 26pt 28pt 30pt 36pt 40pt 50pt 60pt 70pt 80pt",
              browser_spellcheck: true,
              plugins: [
                "advlist autolink lists link image charmap print preview hr anchor pagebreak lineheight",
                "searchreplace visualblocks visualchars code fullscreen",
                "insertdatetime media nonbreaking save table contextmenu directionality",
                "emoticons template paste textcolor colorpicker textpattern imagetools codesample fullpage",
              ],
              menubar: false,
              toolbar1:
                "undo redo | emoticons | bold italic | alignleft aligncenter alignright alignjustify",
              setup: this.setupEditor,
            }}
            onChange={this.onChange}
          />
        </ErrorBoundary>
      </div>
    );
  }
}
