import React from "react";
import LexNavButton from "../../utilities/LexNavButton";
import { SmallOrSmaller, MediumOrLarger } from "../../utilities/Responsive";
import PostingsView from "./PostingsView";
import AccountsView from "./AccountsView";
import AnnualView from "./AnnualView";
import BalanceView from "./BalanceView";
import ResultsView from "./ResultsView";
import VatView from "./VatView";
import { withRouter } from "react-router-dom";

import { DataStore } from "../../services/AxoServices";

import { AxoLocal, Icon } from "../../utilities/LexUtilities";

import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";

//Layout page with tabs
class AccountingTabView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: !props.clientView ? "postings" : "vat",
      smallScreenTabsExpanded: false,
    };
  }

  handleActiveState = (eventKey) => {
    if (!eventKey) {
      return;
    }
    this.handleActiveStateWithoutHistory(eventKey);
  };

  handleActiveStateWithoutHistory = (eventKey) => {
    this.setState({
      activeTab: eventKey,
      smallScreenTabsExpanded: false,
    });
  };

  getButtonClass = (eventKey) => {
    if (this.state.activeTab === eventKey) {
      return "Lex-button-2  active";
    }
    return "Lex-button-2 ";
  };

  renderLargeScreenNavigationBar = () => {
    let { clientView, showDropdowns } = this.props;
    var { activeTab } = this.state;

    return (
      <MediumOrLarger>
        <Grid
          fluid
          className="axo-nav-lg"
          id="noprint"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          {!showDropdowns ? (
            <Row>
              <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{ borderRadius: "0px", paddingTop: "0px" }}
                >
                  {!clientView ? (
                    <LexNavButton
                      className="axonavTab axonavTabcolora"
                      ls
                      glyph="icon-fontello-cog-7"
                      eventKey="postings"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      <AxoLocal
                        entity="AccountingTabViewEntity1"
                        defaultValue={"Driftsregnskab"}
                      />
                    </LexNavButton>
                  ) : null}
                  <LexNavButton
                    className="axonavTab axonavTabcolorb"
                    ls
                    glyph="icon-fontello-target"
                    eventKey="accounts"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="AccountingTabViewEntity2"
                      defaultValue={"Saldobalance"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorc"
                    ls
                    glyph="icon-simple-line-icons-pie-chart"
                    eventKey="vat"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="AccountingNavOperatingbudget"
                      defaultValue={"Moms"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolord"
                    ls
                    glyph="icon-fontello-chart-bar-4"
                    eventKey="results"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="AccountingTabViewEntity3"
                      defaultValue={"Resultatopgørelse"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolore"
                    ls
                    glyph="icon-fontello-squares"
                    eventKey="balance"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="AccountingTabViewEntity4"
                      defaultValue={"Balance"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorf"
                    ls
                    glyph="icon-fontello-aperture-alt"
                    eventKey="capital"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="AccountingTabViewEntity5"
                      defaultValue={"Kapital"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorg"
                    ls
                    glyph="icon-fontello-target-3"
                    eventKey="annual"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      entity="AccountingTabViewEntity6"
                      defaultValue={"Årsregnskab"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    ls
                    listButton
                    className="axonavTab showListButton"
                    onClick={() => {
                      DataStore.setShowDropDowns(true);
                    }}
                  >
                    <div className="flexbox-center">
                      <Icon
                        glyph="icon-fontello-align-justify"
                        style={{ fontSize: "30px" }}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity66"
                        defaultValue={"Liste Menu"}
                      />
                    </div>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />{" "}
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("postings")}
                >
                  <span className="icon-fontello-cog-7" /> &nbsp;&nbsp;{" "}
                  <AxoLocal entity="Enhedspris20" defaultValue={"Dokumenter"} />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("accounts")}
                >
                  <span className="icon-fontello-target" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="AccountingTabViewEntity2"
                    defaultValue={"Saldobalance"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("vat")}
                >
                  <span className="icon-simple-line-icons-pie-chart" />{" "}
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="AccountingNavOperatingbudget"
                    defaultValue={"Moms"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("results")}
                >
                  <span className="icon-fontello-chart-bar-4" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="AccountingTabViewEntity3"
                    defaultValue={"Resultatopgørelse"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("balance")}
                >
                  <span className="icon-fontello-squares" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="AccountingTabViewEntity4"
                    defaultValue={"Balance"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("capital")}
                >
                  <span className="icon-fontello-aperture-alt" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="AccountingTabViewEntity5"
                    defaultValue={"Kapital"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("annual")}
                >
                  <span className="icon-fontello-target-3" /> &nbsp;&nbsp;
                  <AxoLocal
                    entity="AccountingTabViewEntity6"
                    defaultValue={"Årsregnskab"}
                  />
                </MenuItem>
              </DropdownButton>
            </Row>
          )}
        </Grid>
      </MediumOrLarger>
    );
  };

  expandTabs = () => {
    this.setState({ smallScreenTabsExpanded: true });
  };

  renderSmallScreenNavigationBar = () => {
    return (
      <SmallOrSmaller>
        <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
          <DropdownButton
            style={{ width: "100%" }}
            id="IT"
            noCaret
            title={
              <div style={{ display: "inline-block" }}>
                <div className="flexbox-center">
                  <Icon
                    style={{ fontSize: "25px" }}
                    glyph="icon-fontello-align-justify"
                  />
                  &nbsp;&nbsp;
                  <AxoLocal
                    style={{ fontSize: "20px" }}
                    entity="Enhedspris33"
                    defaultValue={"Menu"}
                  />
                </div>
              </div>
            }
          >
            <MenuItem
              style={{ paddingBottom: "10px" }}
              className="axonavTab AxotabDropdownmenu"
              onClick={() => this.handleActiveState("postings")}
            >
              <span className="icon-fontello-cog-7" /> &nbsp;&nbsp;
              <AxoLocal entity="Enhedspris20" defaultValue={"Dokumenter"} />
            </MenuItem>
            <MenuItem
              className="axonavTab AxotabDropdownmenu"
              onClick={() => this.handleActiveState("accounts")}
            >
              <span className="icon-fontello-target" /> &nbsp;&nbsp;
              <AxoLocal
                entity="AccountingTabViewEntity2"
                defaultValue={"Saldobalance"}
              />
            </MenuItem>
            <MenuItem
              className="axonavTab AxotabDropdownmenu"
              onClick={() => this.handleActiveState("vat")}
            >
              <span className="icon-simple-line-icons-pie-chart" /> &nbsp;&nbsp;
              <AxoLocal
                entity="AccountingNavOperatingbudget"
                defaultValue={"Moms"}
              />
            </MenuItem>
            <MenuItem
              className="axonavTab AxotabDropdownmenu"
              onClick={() => this.handleActiveState("results")}
            >
              <span className="icon-fontello-chart-bar-4" /> &nbsp;&nbsp;
              <AxoLocal
                entity="AccountingTabViewEntity3"
                defaultValue={"Resultatopgørelse"}
              />
            </MenuItem>
            <MenuItem
              className="axonavTab AxotabDropdownmenu"
              onClick={() => this.handleActiveState("balance")}
            >
              <span className="icon-fontello-squares" /> &nbsp;&nbsp;
              <AxoLocal
                entity="AccountingTabViewEntity4"
                defaultValue={"Balance"}
              />
            </MenuItem>
            <MenuItem
              className="axonavTab AxotabDropdownmenu"
              onClick={() => this.handleActiveState("capital")}
            >
              <span className="icon-fontello-aperture-alt" /> &nbsp;&nbsp;
              <AxoLocal
                entity="AccountingTabViewEntity5"
                defaultValue={"Kapital"}
              />
            </MenuItem>
            <MenuItem
              className="axonavTab AxotabDropdownmenu"
              onClick={() => this.handleActiveState("annual")}
            >
              <span className="icon-fontello-target-3" /> &nbsp;&nbsp;
              <AxoLocal
                entity="AccountingTabViewEntity6"
                defaultValue={"Årsregnskab"}
              />
            </MenuItem>
          </DropdownButton>
        </Row>
        {/* <Grid fluid className='axo-nav' id="noprint">
          <Row>
            <Col xs={12} style={{ padding: '0px' }}>
              <div className="btn-group btn-group-justified" role="group" style={{ borderRadius: '0px' }}>
                { !clientView ? (
                  <LexNavButton className='axonavTab' glyph='icon-fontello-user-7' eventKey='postings' activeTab={activeTab} onClick={this.handleActiveState}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal entity='AccountingTabViewEntity1' defaultValue={'Driftsregnskab'}/> 
                  </LexNavButton>
                ) : null }
                <LexNavButton className='axonavTab' glyph='icon-fontello-target' eventKey='accounts' activeTab={activeTab} onClick={this.handleActiveState}>
                  &nbsp;&nbsp;&nbsp;
                  <AxoLocal entity='AccountingTabViewEntity2' defaultValue={'Saldobalance'}/> 
                </LexNavButton>
                { smallScreenTabsExpanded  ? (
                  <LexNavButton className='axonavTab' glyph='icon-simple-line-icons-pie-chart' eventKey='vat' activeTab={activeTab} onClick={this.handleActiveState}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal entity='AccountingNavOperatingbudget' defaultValue={'Moms'}/>  
                  </LexNavButton>
                ) : null }
                { !smallScreenTabsExpanded ? (
                  <LexNavButton className='axonavTab iconxlSiza' glyph='icon-fontello-align-justify-1' activeTab={activeTab} onClick={this.expandTabs}>
                     <AxoLocal entity='axonavTabmenuMere' defaultValue={'Mere'}/> 
                  </LexNavButton>
                ) : null }
                </div>
              </Col>
            </Row>
            { smallScreenTabsExpanded ? (
              <Row>
                <Col xs={12}  style={{ padding: '0px' }}>
                  <div className="btn-group btn-group-justified" role="group" style={{ borderRadius: '0px' }}>       
                  <LexNavButton className='axonavTab' glyph='icon-fontello-chart-bar-4' eventKey='results' activeTab={activeTab} onClick={this.handleActiveState}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal entity='AccountingTabViewEntity3' defaultValue={'Resultatopgørelse'}/> 
                  </LexNavButton>
                  <LexNavButton className='axonavTab' glyph='icon-fontello-squares' eventKey='balance' activeTab={activeTab} onClick={this.handleActiveState}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal entity='AccountingTabViewEntity4' defaultValue={'Balance'}/>  
                  </LexNavButton>
                  <LexNavButton className='axonavTab' glyph='icon-fontello-aperture-alt' eventKey='capital' activeTab={activeTab} onClick={this.handleActiveState}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal entity='AccountingTabViewEntity5' defaultValue={'Kapital'}/>  
                  </LexNavButton>
                  <LexNavButton className='axonavTab' glyph='icon-fontello-target-3' eventKey='annual' activeTab={activeTab} onClick={this.handleActiveState}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal entity='AccountingTabViewEntity6' defaultValue={'Årsregnskab'}/>   
                  </LexNavButton>
                </div>
              </Col> 
            </Row>
            ) : null }
        </Grid> */}
      </SmallOrSmaller>
    );
  };

  renderNavigationBar = () => {
    return (
      <div className="Tabbanne" style={{ paddingTop: "0px" }}>
        {this.renderLargeScreenNavigationBar()}
        {this.renderSmallScreenNavigationBar()}
      </div>
    );
  };

  renderNavigationContent = () => {
    var {
      accountTypes,
      accounts,
      postings,
      contactMap,
      selectedClient,
      handleSelectedClient,
    } = this.props;

    var activeTab = this.state.activeTab;
    return (
      <div>
        {activeTab === "postings" ? (
          <PostingsView
            accountTypes={accountTypes}
            accounts={accounts}
            postings={postings}
            contactMap={contactMap}
            selectedClient={selectedClient}
            handleSelectedClient={handleSelectedClient}
          />
        ) : null}
        {activeTab === "accounts" ? (
          <AccountsView
            accountTypes={accountTypes}
            accounts={accounts}
            postings={postings}
          />
        ) : null}
        {activeTab === "vat" ? (
          <VatView
            accountTypes={accountTypes}
            accounts={accounts}
            postings={postings}
          />
        ) : null}
        {activeTab === "results" ? (
          <ResultsView
            accountTypes={accountTypes}
            accounts={accounts}
            postings={postings}
          />
        ) : null}
        {activeTab === "balance" ? (
          <BalanceView
            accountTypes={accountTypes}
            accounts={accounts}
            postings={postings}
          />
        ) : null}
        {/* { activeTab === 'capital' ? (
           <CapitalView 
              accountTypes={accountTypes}
              accounts={accounts}
              postings={postings}
           />
        ) : null } */}
        {activeTab === "annual" ? (
          <AnnualView
            accountTypes={accountTypes}
            accounts={accounts}
            postings={postings}
          />
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <div className="AccountingpaddingTB">
        <Grid fluid>
          <Row className="dropdown-form-styling">
            <Col xs={12} style={{ padding: "0px" }}>
              {this.renderNavigationBar()}
              {this.renderNavigationContent()}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(AccountingTabView);
