import React from "react";
import ReactDOM from "react-dom";
import Invoice from "./Invoice";
import { Table, Column, Cell } from "fixed-data-table-2";
import InvoiceGridView from "./InvoiceGridView";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";
import moment from "moment";

import {
  Row,
  Col,
  Grid,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import {
  ApiService,
  DataActions,
  ModalService,
  TimeEntryOptionsStore,
  TimeEntryService,
  AuthorizationService,
  ContactService,
} from "../../services/AxoServices";

import {
  Icon,
  SortHeaderCell,
  DataListWrapper,
  TableBase,
  Dimensions,
  AxoLocal,
  getText,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  select: {
    width: 250,
    shown: true,
  },
  number: {
    width: 150,
    shown: true,
  },
  creationDate: {
    width: 150,
    shown: true,
  },
  dueDate: {
    width: 290,
    shown: true,
  },
  status: {
    width: 140,
    shown: true,
  },
  clientString: {
    width: 250,
    shown: true,
  },
  outstandingAmount: {
    width: 110,
    shown: true,
  },
  actions: {
    width: 110,
    shown: true,
  },
};

class InvoicesView extends TableBase {
  constructor(props) {
    super(props);
    this.name = "InvoicesView";
    this.defaultColumnDefs = defaultColumnDefs;

    let tableEntries = this.addDataStringsToInvoices(props.invoices);
    let shownInvoices = this.getShownInvoices(
      props.selectedOptionId,
      props.userProfile.userName,
      tableEntries
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(shownInvoices);
    this.state = {
      selectedInvoices: [],
      selectedInvoiceId: 0,
      tableEntries: tableEntries,
      invoiceSendingId: 0,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        dueDate: 180,
        status: 110,
        actions: 75,
      },
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        shownInvoices
      ),
      colSortDirs: {},
      searchText: "",
      isGeneratingPDF: false,
      isGeneratingAndSending: false,
      showGeneratePDFError: false,
      generatedPDF: false,
      isFetchingHTML: false,
    };

    if (props.newInvoice.id) {
      this.state.highlightedInvoiceId = props.newInvoice.id;
      this.timeOut = setTimeout(() => {
        this.setState({ highlightedInvoiceId: 0 });
      }, 10000);
    }
  }

  componentDidMount() {
    TimeEntryOptionsStore.setTimeEntryOptions({
      clientsWithEntries: this.props.clientsWithInvoices,
      showCases: false,
      showInvoices: true,
      selectedOptionId: this.props.selectedOptionId,
      onSelectOption: this.onSelectOption,
    });
  }

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.addDataStringsToInvoices(nextProps.invoices);
    let shownInvoices = this.getShownInvoices(
      nextProps.selectedOptionId,
      nextProps.userProfile.userName,
      tableEntries
    );
    let filteredEntries = this.getFilteredInvoices(
      tableEntries,
      this.state.searchText
    );

    //Sort handling
    this.updateEntries(tableEntries, filteredEntries);

    if (nextProps.selectedOptionId !== this.props.selectedOptionId) {
      //Reset the sort order when a new client is selected
      this._defaultSortIndexes = this.generateDefaultSortIndexes(shownInvoices);
      this.setState({
        sortedDataList: new DataListWrapper(
          this._defaultSortIndexes,
          shownInvoices
        ),
      });
    }
    //Highlight new invoices for 10 seconds
    if (nextProps.newInvoice.id !== this.props.newInvoice.id) {
      this.setState({
        highlightedInvoiceId: nextProps.newInvoice.id,
      });
      this.timeOut = setTimeout(() => {
        this.setState({ highlightedInvoiceId: 0 });
      }, 10000);
    }
    //Synchronize data with the sidebar
    TimeEntryOptionsStore.setTimeEntryOptions({
      clientsWithEntries: nextProps.clientsWithInvoices,
      showCases: false,
      showInvoices: true,
      selectedOptionId: nextProps.selectedOptionId,
      onSelectOption: this.onSelectOption,
    });
  }

  componentWillUnmount() {
    //TimeEntryOptionsStore.resetOptions();
    clearTimeout(this.timeOut);
  }

  addDataStringsToInvoices = (invoices) => {
    invoices.forEach((invoice) => {
      invoice.statusString = invoice.status.toString();

      //client string is used for sorting
      invoice.clientString = invoice.client
        ? invoice.client.firstName
        : "No client";
    });
    return invoices;
  };

  onSelectOption = (optionId) => {
    this.props.onSelectOption(optionId);
  };

  getClientInvoices = (selectedOptionId, userName, invoices) => {
    let ownInvoices = invoices.filter((i) => i.userName === userName);
    let axoInvoices = invoices.filter((i) => i.userName !== userName);

    if (selectedOptionId === "Axolex") {
      //Invoices from Axolex
      return axoInvoices;
    }
    if (selectedOptionId === -1) {
      //All own invoices
      return ownInvoices;
    }
    let client = this.props.clientsWithInvoices.find(
      (cl) => cl.id === selectedOptionId
    );
    return this.getShownInvoicesForClient(ownInvoices, client);
  };

  getShownInvoicesForClient = (invoices, client) => {
    if (!client) {
      //Invoices with no client
      return invoices.filter((t) => !t.client || !t.client.id);
    }
    return invoices.filter((t) => t.client && t.client.id === client.id);
  };

  getShownInvoices = (selectedOptionId, userName, invoices) => {
    return this.getClientInvoices(selectedOptionId, userName, invoices);
  };

  onTrashInvoice = (invoiceId) => {
    var invoice = Object.assign(
      {},
      this.props.invoices.find((inv) => inv.id === invoiceId)
    );
    invoice.trashed = true;
    DataActions.updateInvoice(invoice);
  };

  onChangeDueDate = (invoiceId, event) => {
    var invoice = Object.assign(
      {},
      this.props.invoices.find((inv) => inv.id === invoiceId)
    );
    invoice.dueDate = moment(event.target.valueAsDate).format();
    invoice.sendReminder = true;
    invoice.reminderSent = false;
    DataActions.updateInvoice(invoice);
  };

  onSelectInvoice = (invoiceId, event) => {
    event.preventDefault();
    this.setState({ selectedInvoiceId: invoiceId });
  };

  onStatusSelect = (invoiceId, event) => {
    var invoice = this.props.invoices.find((inv) => inv.id === invoiceId);
    DataActions.updateInvoice({
      ...invoice,
      status: event.target.value,
    });
  };

  renderStatusSelect = (invoice, statusLabels) => {
    return (
      <select
        className="axoblue selectbg"
        value={invoice.status}
        style={{ border: "none" }}
        onChange={this.onStatusSelect.bind(this, invoice.id)}
      >
        <option value="Created">{statusLabels.created}</option>
        <option value="Approved">{statusLabels.approved}</option>
        <option value="Sent">{statusLabels.submitted}</option>
        <option value="Seen">{statusLabels.seen}</option>
        <option value="Paid">{statusLabels.paid}</option>
        <option value="Overdue">{statusLabels.overdue}</option>
      </select>
    );
  };

  renderStatusText = (invoice, statusLabels) => {
    switch (invoice.status) {
      case "Created":
        return statusLabels.created;
      case "Approved":
        return statusLabels.approved;
      case "Sent":
        return statusLabels.submitted;
      case "Seen":
        return statusLabels.seen;
      case "Paid":
        return statusLabels.paid;
      case "Overdue":
        return statusLabels.overdue;
      default:
        return <span></span>;
    }
  };

  getStatusIcon = (status) => {
    switch (status) {
      case "Created":
        return (
          <div>
            <Icon
              style={{ color: "#428bca" }}
              glyph="icon-fontello-check-empty"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Approved":
        return (
          <div>
            <Icon style={{ color: "#306C67" }} glyph="icon-fontello-export-3" />
            &nbsp;&nbsp;
          </div>
        );
      case "Sent":
        return (
          <div>
            <Icon style={{ color: "#518F41" }} glyph="icon-fontello-check-1" />
            &nbsp;&nbsp;
          </div>
        );
      case "Seen":
        return (
          <div>
            <Icon
              style={{ color: "#D71F4B" }}
              glyph="icon-fontello-cancel-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Paid":
        return (
          <div>
            <Icon
              style={{ color: "#003BFF" }}
              glyph="icon-fontello-ok-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      default:
        return <div></div>;
    }
  };

  onSearch = (event) => {
    var invoices = this.getFilteredInvoices(
      this.state.tableEntries,
      event.target.value
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(invoices);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, invoices),
    });
  };

  getFilteredInvoices = (entries, searchText) => {
    var shownInvoices = this.getShownInvoices(
      this.props.selectedOptionId,
      this.props.userProfile.userName,
      entries
    );
    if (!searchText) {
      return shownInvoices;
    }
    return shownInvoices.filter((c) => {
      return c.number.toString().includes(searchText.toLowerCase());
    });
    // return shownInvoices.filter( c => {
    //   return (c.invoiceNo).toLowerCase().includes(searchText.toLowerCase());
    // });
  };

  calculateInvoiceTotal = (invoice) => {
    return Math.round(
      invoice.timeEntries.reduce((sum, timeEntry) => {
        return sum + TimeEntryService.getTotalValue(timeEntry);
      }, 0)
    );
  };

  _rowClassNameGetter = (rowIndex) => {
    var activeInvoice = this.state.sortedDataList.getObjectAt(rowIndex);
    if (activeInvoice && activeInvoice.id === this.state.highlightedInvoiceId) {
      return "highlight-row";
    }
  };

  renderClientName = (client) => {
    return ContactService.getContactAccountingName(client);
  };

  renderInvoicesTable = () => {
    const { forceShowCards } = this.props;

    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <Col sm={4}>
                  <h4>
                    <Icon glyph="icon-fontello-align-justify" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="InvoicePDFsViewinvoices"
                      defaultValue={"Fakturaer"}
                    />{" "}
                  </h4>
                </Col>
                <Col sm={4}>
                  <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-search" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
                <Col
                  sm={4}
                  className="hidden-xs text-right"
                  style={{ paddingTop: "10px" }}
                >
                  <Button
                    className="Lex-button-2"
                    onClick={this.props.onToggleCards}
                  >
                    {this.props.forceShowCards ? (
                      <span>
                        <Icon glyph="icon-fontello-grid" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showTable"
                          entity="CaseDataTableFixedShowastable"
                          defaultValue={"Vis som tabel"}
                        />
                      </span>
                    ) : (
                      <span>
                        <Icon glyph="icon-fontello-th-thumb-empty" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          key="showCards"
                          entity="CaseDataTableFixedShowCards"
                          defaultValue={"Vis som kartotekskort"}
                        />
                      </span>
                    )}
                  </Button>
                </Col>
              </Row>
            </Grid>
          </div>
          {forceShowCards ? (
            this.renderGridView()
          ) : (
            <div>
              <ExtraSmall>{this.renderGridView()}</ExtraSmall>
              <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderGridView = () => {
    let { sortedDataList, highlightedInvoiceId } = this.state;

    return (
      <div>
        <InvoiceGridView
          invoices={sortedDataList._data}
          highlightedInvoiceId={highlightedInvoiceId}
          onSelectInvoice={this.onSelectInvoice}
          onStatusSelect={this.onStatusSelect}
          onCreateAndSendPDF={this.props.onCreateAndSendPDF}
        />
      </div>
    );
  };

  renderTable = () => {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth, userProfile } = this.props;
    var tableWidth = containerWidth - 25;

    let statusLabels = {
      created: getText("InvoiceGridViewcreated", "Created"),
      approved: getText("InvoiceGridViewapproved", "Approved"),
      submitted: getText("InvoiceGridViewSubmitted", "Sent"),
      seen: getText("axoidcode233", "Sendt og set"),
      paid: getText("InvoiceInvoicePaid", "Paid"),
      overdue: getText("InvoiceGridViewoverdue", "Overdue"),
    };

    return (
      <div style={{ paddingTop: "0px", paddingLeft: "20px" }}>
        <Table
          rowHeight={40}
          rowsCount={sortedDataList.getSize()}
          rowClassNameGetter={this._rowClassNameGetter}
          height={containerHeight}
          width={tableWidth}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          headerHeight={40}
        >
          <Column
            columnKey="number"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-sort-name-up" />}
                showAltIcon={columnDefs.number.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.number}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;#
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  <a
                    href="SelectInvoice"
                    onClick={this.onSelectInvoice.bind(this, invoice.id)}
                  >
                    {invoice.number.toString() || "0"}
                  </a>
                  {invoice.sharedWithClient > 0 ? (
                    <span>
                      &ensp;
                      <AxoLocal
                        componentClass={Icon}
                        className="Axoiconlink"
                        style={{ fontSize: "20px" }}
                        glyph="icon-simple-line-icons-user-following"
                        componentAttribute="title"
                        entity="InvoicesViewSharedIcon"
                      />
                    </span>
                  ) : null}
                </Cell>
              );
            }}
            width={columnDefs.number.width}
            isResizable={true}
          />
          <Column
            columnKey="creationDate"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-calendar-8" />}
                showAltIcon={columnDefs.creationDate.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.creationDate}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="DocumentTabViewcreationDate"
                  defaultValue={"Oprettelsesdato"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {moment(invoice.creationDate).format("L")}
                </Cell>
              );
            }}
            width={columnDefs.creationDate.width}
            isResizable={true}
          />
          <Column
            columnKey="dueDate"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph=" icon-fontello-calendar-6" />}
                showAltIcon={columnDefs.dueDate.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.dueDate}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="InvoiceGridViewpaymentDate"
                  defaultValue={"Betalingsdato"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {userProfile.userName === invoice.userName ? (
                    <input
                      type="date"
                      style={{ border: "none" }}
                      onChange={this.onChangeDueDate.bind(this, invoice.id)}
                      value={moment(invoice.dueDate).format("YYYY-MM-DD")}
                    />
                  ) : (
                    <div>{moment(invoice.dueDate).format("L")}</div>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.dueDate.width}
            isResizable={true}
          />
          <Column
            columnKey="status"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-check" />}
                showAltIcon={columnDefs.status.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.status}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  <div className="flexbox-standard">
                    {this.getStatusIcon(invoice.status)}
                    {userProfile.userName === invoice.userName
                      ? this.renderStatusSelect(invoice, statusLabels)
                      : this.renderStatusText(invoice, statusLabels)}
                  </div>
                </Cell>
              );
            }}
            width={columnDefs.status.width}
            isResizable={true}
          />
          <Column
            columnKey="clientString"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-user" />}
                showAltIcon={columnDefs.clientString.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.clientString}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="CaseEditFormclients"
                  defaultValue={"Klient"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {invoice.client ? (
                    <span>{this.renderClientName(invoice.client)}</span>
                  ) : (
                    <span>
                      <AxoLocal
                        entity="getStatusStringinvoicestatus"
                        defaultValue="Ingen klient"
                      />
                    </span>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.clientString.width}
            isResizable={true}
          />
          <Column
            columnKey="outstandingAmount"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-database-2" />}
                showAltIcon={columnDefs.outstandingAmount.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.outstandingAmount}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="InvoiceGridViewRemainderAmount"
                  defaultValue={"Restbeløb"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {invoice.currency} {this.calculateInvoiceTotal(invoice)}
                </Cell>
              );
            }}
            width={columnDefs.outstandingAmount.width}
            isResizable={true}
          />
          <Column
            columnKey="actions"
            header={<Cell></Cell>}
            cell={(props) => {
              var invoice = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={invoice.id} {...props}>
                  {this.state.invoiceSendingId === invoice.id ? (
                    <img
                      alt=""
                      src="/imgs/app/loading.gif"
                      className="img-circle"
                      width="20"
                      height="20"
                    />
                  ) : (
                    <AxoLocal
                      componentClass={Icon}
                      className="Axoiconlink editable"
                      role="button"
                      onClick={this.onSendInvoiceFromTable.bind(this, invoice)}
                      glyph="icon-fontello-mail-1"
                      componentAttribute="title"
                      entity="BCCTitleSendinvoicetocustomer"
                    />
                  )}

                  {userProfile.userName === invoice.userName ? (
                    <React.Fragment>
                      &ensp;&ensp;&ensp;&ensp;
                      <Icon
                        className="editable"
                        role="button"
                        onClick={this.onTrashInvoice.bind(this, invoice.id)}
                        glyph="icon-fontello-trash-1"
                      />
                    </React.Fragment>
                  ) : null}
                </Cell>
              );
            }}
            width={columnDefs.actions.width}
            isResizable={true}
          />
        </Table>
      </div>
    );
  };

  onCloseInvoice = () => {
    this.setState({ selectedInvoiceId: 0 });
  };

  onGeneratePdf = () => {
    var currentInvoice = this.props.invoices.find(
      (i) => i.id === this.state.selectedInvoiceId
    );

    this.setState({ isFetchingHTML: true }, () => {
      var invoice = Object.assign({}, currentInvoice);
      invoice.innerHTML = this.getInvoiceHtml();
      this.setState({ isFetchingHTML: false }, () => {
        this.doGeneratePdf(invoice);
      });
    });
  };

  doGeneratePdf = (invoice) => {
    this.setState({ isGeneratingPDF: true });
    return ApiService.updateInvoice(invoice)
      .then((response) => {
        if (response.ok) {
          return DataActions.generateInvoicePdf(invoice.id);
        }
        return Promise.reject("Invoice could not be updated.");
      })
      .then((response) => {
        this.setState({ isGeneratingPDF: false });
        if (response.ok) {
          return response.json();
        }
        return Promise.reject("Invoice could not be printed.");
      })
      .then((newPDF) => {
        this.props.onCreatePDF(newPDF);
        this.setState({ generatedPDF: true });
        setTimeout(() => {
          this.setState({ generatedPDF: false });
        }, 1500);
      })
      .catch((reason) => {
        this.showGeneratePDFError();
      });
  };

  getInvoiceHtml = () => {
    var invoiceNode = ReactDOM.findDOMNode(this.invoiceComponent).innerHTML;
    return invoiceNode;
  };

  showGeneratePDFError = () => {
    this.setState({
      isGeneratingPDF: false,
      isGeneratingAndSending: false,
      showGeneratePDFError: true,
    });
    setTimeout(() => {
      this.setState({ showGeneratePDFError: false });
    }, 5000);
  };

  onGenerateAndSend = () => {
    var currentInvoice = this.props.invoices.find(
      (i) => i.id === this.state.selectedInvoiceId
    );
    this.setState({ isFetchingHTML: true }, () => {
      var invoice = Object.assign({}, currentInvoice);
      invoice.innerHTML = this.getInvoiceHtml();
      this.setState({ isFetchingHTML: false }, () => {
        this.doGenerateAndSend(invoice);
      });
    });
  };

  doGenerateAndSend = (invoice) => {
    this.setState({ isGeneratingAndSending: true });
    return ApiService.updateInvoice(invoice)
      .then((response) => {
        if (response.ok) {
          return DataActions.generateInvoicePdf(invoice.id);
        }
        return Promise.reject("Invoice could not be updated.");
      })
      .then((response) => {
        this.setState({ isGeneratingAndSending: false });
        if (response.ok) {
          return response.json();
        }
        return Promise.reject("Invoice could not be printed.");
      })
      .then((newPDF) => {
        this.setState({ isGeneratingAndSending: false });
        this.props.onCreateAndSendPDF(newPDF);
      })
      .catch((reason) => {
        console.log(reason);
        this.showGeneratePDFError();
      });
  };

  onSendInvoiceFromTable = (currentInvoice) => {
    this.setState(
      { selectedInvoiceId: currentInvoice.id, isFetchingHTML: true },
      () => {
        var invoice = Object.assign({}, currentInvoice);
        invoice.innerHTML = this.getInvoiceHtml();
        this.setState({ selectedInvoiceId: 0, isFetchingHTML: false }, () => {
          this.doGenerateAndSendFromTable(invoice);
        });
      }
    );
  };

  doGenerateAndSendFromTable = (invoice) => {
    this.setState({ invoiceSendingId: invoice.id });
    return ApiService.updateInvoice(invoice)
      .then((response) => {
        if (response.ok) {
          return DataActions.generateInvoicePdf(invoice.id);
        }
        return Promise.reject("Invoice could not be updated.");
      })
      .then((response) => {
        this.setState({ invoiceSendingId: false });
        if (response.ok) {
          return response.json();
        }
        return Promise.reject("Invoice could not be printed.");
      })
      .then((newPDF) => {
        this.setState({ invoiceSendingId: false });
        this.props.onCreateAndSendPDF(newPDF);
      })
      .catch((reason) => {
        this.showPDFGenerationErrorModal();
      });
  };

  showPDFGenerationErrorModal = () => {
    this.setState({ invoiceSendingId: 0 });
    ModalService.openAlertModal(
      <AxoLocal
        entity="CaseDataTableFixedClient"
        defaultValue={"PDF´en kunne ikke genereres. Prøv igen senere."}
      />
    );
  };

  renderBody = () => {
    let { invoices, clientLabels, contactMap, userProfile, userSettings } =
      this.props;

    let {
      isFetchingHTML,
      selectedInvoiceId,
      isGeneratingPDF,
      isGeneratingAndSending,
      showGeneratePDFError,
      generatedPDF,
    } = this.state;

    if (selectedInvoiceId) {
      let invoice = invoices.find((i) => i.id === selectedInvoiceId);

      let type = AuthorizationService.getUserType(userProfile);
      return (
        <Invoice
          invoice={invoice}
          renderButtons={!isFetchingHTML}
          renderPaymentLinkButton={type === "Admin"}
          readOnly={isFetchingHTML || userProfile.userName !== invoice.userName}
          onClose={this.onCloseInvoice}
          onGeneratePdf={this.onGeneratePdf}
          onGenerateAndSend={this.onGenerateAndSend}
          isGeneratingPDF={isGeneratingPDF}
          isGeneratingAndSending={isGeneratingAndSending}
          showGeneratePDFError={showGeneratePDFError}
          generatedPDF={generatedPDF}
          clientLabels={clientLabels}
          contactMap={contactMap}
          showPaymentRequests={!!userSettings.paymentApiKey}
          ref={(r) => (this.invoiceComponent = r)}
        />
      );
    }
    return this.renderInvoicesTable();
  };

  render() {
    return <div style={{ paddingTop: "10px" }}>{this.renderBody()}</div>;
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 200;
  },
})(InvoicesView);
