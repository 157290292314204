import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { DataActions } from "../services/AxoServices";

import { Icon } from "../utilities/LexUtilities";

import { Table } from "react-bootstrap";

export default class PowerOfAttorneyTableView extends React.PureComponent {
  static propTypes = {
    powerOfAttorneys: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  onDelete = (id) => {
    DataActions.deletePowerOfAttorney(id);
  };

  onUpdate = (model) => {
    //Replaces the object with the same id with this object
    DataActions.updatePowerOfAttorney(model);
  };

  render() {
    let { powerOfAttorneys, onGotoDetails } = this.props;

    return (
      <div className="standardMaxWidth leftPadding">
        <Table bordered condensed hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Ansøgers navn</th>
              <th>personID</th>
              <th>Sted og dato</th>
              <th>Ansøgers fødselsdato</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {powerOfAttorneys.map((model) => (
              <tr
                role="button"
                key={model.id}
                onDoubleClick={() => onGotoDetails(model.id)}
              >
                <td>{model.type}</td>

                <td className="editable">{model.applicantsName}</td>
                <td>{model.personalIDorCPR}</td>

                <td>{moment(model.placeAndDate).format("L")}</td>
                <td>{moment(model.dateOfBirth).format("L")}</td>
                <td>
                  <Icon
                    role="button"
                    onClick={() => this.onDelete(model.id)}
                    glyph="icon-fontello-trash-1"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
