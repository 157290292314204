// Generated by CoffeeScript 1.9.1
(function () {
  var Oscilloscope,
    debug,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  debug = require("debug")("oscilloscope");

  Oscilloscope = (function () {
    function Oscilloscope(canvas, options) {
      this.canvas = canvas;
      if (options === null) {
        options = {
          stroke: 2,
          glow: 0.0,
          buffer: 2048,
        };
      }
      this._drawSignal = bind(this._drawSignal, this);
      if (!(this instanceof Oscilloscope)) {
        return new Oscilloscope(this.canvas, options);
      }
      this.signals = [];
      this.drawRequest = 0;
      this.timeDomain = new Uint8Array(options.buffer);
      this.drawContext = this.canvas.getContext("2d");
      this.drawContext.lineWidth = options.stroke;
      this.drawContext.shadowBlur = options.glow * 100;
      this.drawContext.translate(0, this.canvas.height);
      this.drawContext.scale(1, -1);
    }

    Oscilloscope.prototype.addSignal = function (source, color) {
      var analyser;
      if (!(source instanceof AudioNode)) {
        throw new Error("Signal must be an AudioNode");
      }
      if (source instanceof AnalyserNode) {
        debug("add signal analyser");
        analyser = source;
      } else {
        debug("add signal source");
        analyser = source.context.createAnalyser();
        source.connect(analyser);
      }
      analyser.fftSize = this.timeDomain.length;
      this.signals.push({
        analyser: analyser,
        color: color || "#ffffff",
      });
      if (this.signals.length === 1) {
        return this.start();
      }
    };

    Oscilloscope.prototype.resetSignal = function () {
      this.signals = [];
    };

    Oscilloscope.prototype.removeSignal = function (analyser) {
      debug("remove signal");
      throw new Error("Not implemented.");
    };

    Oscilloscope.prototype.start = function () {
      if (this.drawRequest) {
        return;
      }
      debug("start animation");
      return (this.drawRequest = window.requestAnimationFrame(
        this._drawSignal
      ));
    };

    Oscilloscope.prototype.stop = function () {
      debug("stop animation");
      cancelAnimationFrame(this.drawRequest);
      this.drawRequest = 0;
      return this.drawContext.clearRect(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
    };

    Oscilloscope.prototype._drawSignal = function () {
      var i,
        index,
        j,
        k,
        len,
        len1,
        percent,
        ref,
        ref1,
        scopeHeight,
        signal,
        step,
        value,
        x,
        y;
      this.drawContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
      step = this.canvas.width / this.timeDomain.length;
      scopeHeight = this.canvas.height / this.signals.length;
      ref = this.signals;
      for (index = j = 0, len = ref.length; j < len; index = ++j) {
        signal = ref[index];
        this.drawContext.beginPath();
        signal.analyser.getByteTimeDomainData(this.timeDomain);
        ref1 = this.timeDomain;
        for (i = k = 0, len1 = ref1.length; k < len1; i = k += 2) {
          value = ref1[i];
          percent = value / 255;
          x = i * step;
          y = scopeHeight * percent + scopeHeight * index;
          this.drawContext.lineTo(x, y);
        }
        this.drawContext.strokeStyle = signal.color;
        this.drawContext.shadowColor = signal.color;
        this.drawContext.stroke();
      }
      return (this.drawRequest = window.requestAnimationFrame(
        this._drawSignal
      ));
    };

    return Oscilloscope;
  })();

  module.exports = Oscilloscope;
}.call(this));
