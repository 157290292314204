import React from "react";
import { groupBy } from "lodash";
import { AxoLocal } from "../../utilities/LexUtilities";
import { Table } from "react-bootstrap";

function makeMonth(name) {
  return {
    name: name,
    totalResult: 0,
  };
}

//Resultatopgørelse
export default class ResultsView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  generateMonthSums() {
    return [
      makeMonth(
        <span>
          {" "}
          <AxoLocal
            entity="AccountingTabViewEntity45"
            defaultValue={"Januar"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity46"
            defaultValue={"Februar"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity47" defaultValue={"Marts"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity48" defaultValue={"April"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity49" defaultValue={"Maj"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity50" defaultValue={"Juni"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal entity="AccountingTabViewEntity51" defaultValue={"Juli"} />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity51"
            defaultValue={"August"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity53"
            defaultValue={"September"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity54"
            defaultValue={"Oktober"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity55"
            defaultValue={"November"}
          />
        </span>
      ),
      makeMonth(
        <span>
          <AxoLocal
            entity="AccountingTabViewEntity56"
            defaultValue={"December"}
          />
        </span>
      ),
    ];
  }

  generateAccountTypeRows = (accountType, accounts, monthSums, postings) => {
    var rows = [];
    rows.push(
      <tr className="Axoaccountsbannebg">
        <th>{accountType.name}</th>
        {monthSums.map((m) => (
          <td></td>
        ))}
      </tr>
    );
    var accountsForType = accounts.filter(
      (a) => a.postingAccountTypeId === accountType.id
    );
    var accountRows = accountsForType.map((a) => (
      <tr className="Axoaccountsevenlist">
        <td>
          {a.number} - {a.name}
        </td>
        {monthSums.map((ms) => {
          var accountResult = ms[a.id];
          return <td>{accountResult ? accountResult.result : 0}</td>;
        })}
      </tr>
    ));

    rows = rows.concat(accountRows);
    rows.push(
      <tr className="Axoaccountstotalbanne">
        <td>
          <AxoLocal entity="InvoiceInvoicetotal" defaultValue={"Total"} />
        </td>
        {monthSums.map((ms) => {
          var total = ms[accountType.name + accountType.id];
          return <td>{total || 0}</td>;
        })}
      </tr>
    );

    return rows;
  };

  render() {
    let { postings, accounts, accountTypes } = this.props;

    let monthSums = this.generateMonthSums();

    let months = groupBy(postings, (p) => {
      return new Date(p.date).getMonth();
    });

    for (var key in months) {
      let postingsForMonth = months[key];
      let index = parseInt(key, 10);
      let monthSum = monthSums[index];

      accounts.forEach((acc) => {
        monthSum[acc.id] = {};
        let postingsForAccount = postingsForMonth.filter(
          (p) => p.postingAccountId === acc.id
        );
        let income = postingsForAccount.reduce((acc, posting) => {
          return acc + posting.income;
        }, 0);
        let expenses = postingsForAccount.reduce((acc, posting) => {
          return acc + posting.expenses;
        }, 0);
        let net = income - expenses;
        monthSum[acc.id].result = net;

        monthSum.totalResult = postingsForMonth.reduce((acc, posting) => {
          return acc + (posting.income - posting.expenses);
        }, 0);
      });

      accountTypes.forEach((type) => {
        let accountsForType = accounts.filter(
          (a) => a.postingAccountTypeId === type.id
        );
        let postingsForType = postingsForMonth.filter(
          (p) => !!accountsForType.find((a) => a.id === p.postingAccountId)
        );

        let income = postingsForType.reduce((acc, posting) => {
          return acc + posting.income;
        }, 0);
        let expenses = postingsForType.reduce((acc, posting) => {
          return acc + posting.expenses;
        }, 0);

        let net = income - expenses;
        monthSum[type.name + type.id] = net;
        monthSum.totalResult += net;
      });
    }

    return (
      <div className="AccountingpaddingTB">
        <h3>
          {" "}
          <AxoLocal
            entity="AccountingTabViewEntity3"
            defaultValue={"Resultatopgørelse"}
          />
        </h3>
        <Table responsive>
          <tbody>
            <tr>
              <th></th>
              {monthSums.map((m) => (
                <th>{m.name}</th>
              ))}
            </tr>
            {accountTypes.map((t) =>
              this.generateAccountTypeRows(t, accounts, monthSums, postings)
            )}
            <tr>
              <th>
                <AxoLocal
                  entity="AccountingTabViewEntity58"
                  defaultValue={"Måneds total"}
                />
              </th>
              {monthSums.map((m) => (
                <td>{m.totalResult}</td>
              ))}
            </tr>
            <tr className="doubleboder">
              <th>
                <AxoLocal
                  entity="AccountingTabViewEntity43"
                  defaultValue={"Årsresultat"}
                />
              </th>
              <td>
                {monthSums.reduce((acc, m) => {
                  return acc + m.totalResult;
                }, 0)}
              </td>
              {monthSums.slice(1).map((m) => (
                <td></td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
