import React from "react";
import PropTypes from "prop-types";
import { Large, MediumOrSmaller } from "../../../utilities/Responsive";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Select from "react-select";

import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";

import { StringService, FileInfoService } from "../../../services/AxoServices";

import {
  Icon,
  InlineEdit,
  InlineNumberEdit,
  LexButton,
  AxoLocal,
  getText,
  Flexbox,
  FlexElement,
  AxoCheckbox,
  LoadingIcon,
} from "../../../utilities/LexUtilities";

class BookkeepingEntryCard extends React.PureComponent {
  static propTypes = {
    entry: PropTypes.object.isRequired,
    onChangePropertyValue: PropTypes.func.isRequired,
    updateMainAccountAndVat: PropTypes.func.isRequired,
    standardAccounts: PropTypes.array.isRequired,
    onShowReceipt: PropTypes.func.isRequired,
    onUploadReceiptForExistingEntry: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    markDate: PropTypes.bool.isRequired,
    updateDate: PropTypes.func.isRequired,
    showAllAccounts: PropTypes.bool.isRequired,
    showAllBalanceAccounts: PropTypes.bool.isRequired,
    onShowAllAccounts: PropTypes.func.isRequired,
    onShowAllBalanceAccounts: PropTypes.func.isRequired,
    updateAmountAndVat: PropTypes.func.isRequired,
  };

  updateAmountAndVat = (amount) => {
    let { entry, updateAmountAndVat } = this.props;

    updateAmountAndVat(entry.id, amount);
  };

  renderInformation = () => {
    let {
      entry,
      onChangePropertyValue,
      updateMainAccountAndVat,
      onShowReceipt,
      onUploadReceiptForExistingEntry,
      standardAccounts,
      incomeAccounts,
      expenseAccounts,
      incomePaymentAccounts,
      expensePaymentAccounts,
      markDate,
      updateDate,
      showAllAccounts,
      showAllBalanceAccounts,
      onShowAllAccounts,
      onShowAllBalanceAccounts,
      onAddImagePage,
      addingPageToId,
      addedPageToId,
      readonly,
    } = this.props;

    let account = standardAccounts.find((a) => a.id === entry.financeAccountId);
    let accountOptions = entry.isIncome ? incomeAccounts : expenseAccounts;

    //Ensure current account remains an option
    if (!!account && !accountOptions.find((a) => a.id === account.id)) {
      accountOptions = [...accountOptions, account];
    }

    let balanceAccount = standardAccounts.find(
      (a) => a.id === entry.balanceFinanceAccountId
    );
    let balanceAccountOptions = entry.isIncome
      ? incomePaymentAccounts
      : expensePaymentAccounts;

    //Ensure current account remains an option
    if (
      !!balanceAccount &&
      !balanceAccountOptions.find((a) => a.id === balanceAccount.id)
    ) {
      balanceAccountOptions = [...balanceAccountOptions, balanceAccount];
    }

    let accountSelectOptions = accountOptions.map((f) => {
      return { value: f.id, label: f.number.toString() + " - " + f.name };
    });

    let balanceAccountSelectOptions = balanceAccountOptions
      .filter((a) => a.id !== entry.financeAccountId)
      .map((f) => {
        return { value: f.id, label: f.number.toString() + " - " + f.name };
      });

    return (
      <div>
        <table className="table Lex-Grid-tabl">
          <tbody>
            <tr>
              <th className="col-md-4">
                <Icon glyph="icon-fontello-acrobat" />
                &nbsp;
                <AxoLocal entity="DocumentGridViewType" defaultValue={"Type"} />
                :
              </th>
              <td>
                {readonly && (
                  <>
                    {entry.isIncome ? (
                      <span>
                        {getText("AccountingTabViewEntity41", "Kredit")}
                      </span>
                    ) : (
                      <span>
                        {getText("AccountingTabViewEntity42", "Debet")}
                      </span>
                    )}
                  </>
                )}
                <select
                  value={entry.isIncome ? "credit" : "debit"}
                  onChange={(event) =>
                    onChangePropertyValue(
                      "isIncome",
                      event.target.value === "credit" ? true : false
                    )
                  }
                >
                  <option value="credit">
                    {getText("AccountingTabViewEntity41", "Kredit")}
                  </option>
                  <option value="debit">
                    {getText("AccountingTabViewEntity42", "Debet")}
                  </option>
                </select>
                {/* { entry.isIncome ? <span><AxoLocal entity='AccountingTabViewEntity16' defaultValue={'Indtægt'}/></span> : <span>
                <AxoLocal entity='AccountingTabViewEntity161a' defaultValue={'Udgift'}/> </span> } */}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />:
              </th>
              <td>
                {readonly ? (
                  <div>{entry.description}</div>
                ) : (
                  <InlineEdit
                    value={entry.description || "---"}
                    change={(input) =>
                      onChangePropertyValue("description", input.value)
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  entity="SidebarRightContainerCompanyCode"
                  defaultValue={"CVR"}
                />
                :
              </th>
              <td>
                {readonly ? (
                  <div>{entry.vatNumber}</div>
                ) : (
                  <InlineEdit
                    value={entry.vatNumber || "---"}
                    change={(input) =>
                      onChangePropertyValue("vatNumber", input.value)
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  entity="AccountingTabViewEntity13"
                  defaultValue={"Konto"}
                />
              </th>
              <td>
                {readonly ? (
                  <div>
                    {!!account
                      ? account.number.toString() + " - " + account.name
                      : ""}
                  </div>
                ) : (
                  <Flexbox>
                    <FlexElement className="rightPadding">
                      <AxoCheckbox
                        // checked={showAllAccounts}
                        checked={showAllAccounts}
                        onChange={onShowAllAccounts}
                      />{" "}
                      <AxoLocal
                        entity="axoidcode124"
                        defaultValue={"Vis alle"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1}>
                      <Select
                        name="select"
                        menuPlacement="auto"
                        className={
                          !entry.financeAccountId ? "selectBorder" : ""
                        }
                        value={
                          !!entry.financeAccountId
                            ? accountSelectOptions.find(
                                (o) => o.id === entry.financeAccountId
                              )
                            : { value: "", label: "" }
                        }
                        onChange={(selectedAccount) => {
                          if (!!selectedAccount) {
                            updateMainAccountAndVat(
                              entry,
                              selectedAccount.value
                            );
                          }
                        }}
                        noOptionsMessage={() => ""}
                        options={[
                          {
                            value: "",
                            label: getText("axoAccounting6f", "Vælg konto"),
                          },
                          ...accountSelectOptions,
                        ]}
                      />
                    </FlexElement>
                  </Flexbox>
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />:
              </th>
              <td>
                {readonly ? (
                  <div>{entry.amount}</div>
                ) : (
                  <InlineNumberEdit
                    decimal
                    value={entry.amount || 0}
                    change={(input) =>
                      this.updateAmountAndVat(parseFloat(input.value))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  entity="invoiPaymentattheSubtotalVAT"
                  defaultValue={"Moms"}
                />
                :
              </th>
              <td>
                {readonly ? (
                  <div>{entry.vat}</div>
                ) : (
                  <InlineNumberEdit
                    decimal
                    value={entry.vat || 0}
                    change={(input) =>
                      onChangePropertyValue("vat", parseFloat(input.value))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  entity="updategetAccountName1"
                  defaultValue={"Modkonto"}
                />
              </th>
              <td>
                {readonly ? (
                  <div>
                    {!!account
                      ? account.number.toString() + " - " + account.name
                      : ""}
                  </div>
                ) : (
                  <Flexbox>
                    <FlexElement className="rightPadding">
                      <AxoCheckbox
                        checked={showAllBalanceAccounts}
                        onChange={onShowAllBalanceAccounts}
                      />{" "}
                      <AxoLocal
                        entity="axoidcode124"
                        defaultValue={"Vis alle"}
                      />
                    </FlexElement>
                    <FlexElement flexGrow={1}>
                      <Select
                        name="select"
                        menuPlacement="auto"
                        className={
                          !entry.financeAccountId ? "selectBorder" : ""
                        }
                        value={
                          !!entry.balanceFinanceAccountId
                            ? balanceAccountSelectOptions.find(
                                (o) => o.id === entry.balanceFinanceAccountId
                              )
                            : { value: "", label: "" }
                        }
                        onChange={(selectedAccount) => {
                          if (!!selectedAccount) {
                            onChangePropertyValue(
                              "balanceFinanceAccountId",
                              selectedAccount.value
                            );
                          }
                        }}
                        noOptionsMessage={() => ""}
                        options={[
                          {
                            value: "",
                            label: getText("axoAccounting6f", "Vælg konto"),
                          },
                          ...balanceAccountSelectOptions,
                        ]}
                      />
                    </FlexElement>
                  </Flexbox>
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  entity="CaseDataTableFixedlabelAddedToCasedocuments"
                  defaultValue={"Bilag"}
                />
                :
              </th>
              <td>
                {!!entry.receipt ? (
                  <div>
                    #{entry.receiptNumber}&nbsp;
                    <Icon
                      className="editable"
                      role="button"
                      onClick={onShowReceipt}
                      glyph="icon-fontello-attach-7"
                    />
                    &nbsp;
                    {FileInfoService.isImage(entry.receipt) ? (
                      <LexButton
                        disabled={!!addingPageToId}
                        title={getText("axoidcode209", "Tilføj side")}
                        onClick={() => onAddImagePage(entry.id)}
                      >
                        {(() => {
                          if (addingPageToId === entry.id) {
                            return (
                              <LoadingIcon show={addingPageToId === entry.id} />
                            );
                          } else if (addedPageToId === entry.id) {
                            return <Icon glyph="icon-fontello-check" />;
                          }
                          return <Icon glyph="icon-fontello-plus-2" />;
                        })()}
                      </LexButton>
                    ) : null}
                  </div>
                ) : (
                  <>
                    {!readonly && (
                      <LexButton onClick={onUploadReceiptForExistingEntry}>
                        <AxoLocal
                          entity="DocumentTabViewUpload"
                          defaultValue={"Upload"}
                        />
                      </LexButton>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <th className="col-md-4">
                <AxoLocal
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
                :
              </th>
              <td>
                {readonly ? (
                  <div>{moment.utc(entry.creationDate).format("L")}</div>
                ) : (
                  <input
                    type="date"
                    style={{ border: markDate ? "1px solid red" : "none" }}
                    onChange={(event) =>
                      updateDate(entry, event.target.valueAsDate)
                    }
                    value={moment.utc(entry.creationDate).format("YYYY-MM-DD")}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderContent = () => {
    return this.renderInformation();
  };

  render() {
    let {
      entry,
      onChangePropertyValue,
      onDelete,
      selectedEntries,
      onSelectEntry,
      readOnly,
    } = this.props;

    return (
      <div className="data-card">
        <div className="gradient-baggrund">
          <Grid fluid className="fullHeight">
            <Row className="fullHeight">
              <Col xs={12} className="text-center fullHeight">
                <Flexbox justified alignCenter className="fullHeight">
                  <FlexElement className="text-left">
                    <AxoCheckbox
                      checked={selectedEntries.has(entry.id)}
                      onChange={onSelectEntry.bind(this, entry.id)}
                    />
                  </FlexElement>
                  <FlexElement className="text-center">
                    {StringService.enforceMaxLength(
                      entry.description ||
                        (entry.isIncome ? (
                          <span>
                            <AxoLocal
                              entity="AccountingTabViewEntity16"
                              defaultValue={"Indtægt"}
                            />
                          </span>
                        ) : (
                          <span>
                            <AxoLocal
                              entity="AccountingTabViewEntity161a"
                              defaultValue={"Udgift"}
                            />{" "}
                          </span>
                        )),
                      30
                    )}
                  </FlexElement>
                  <FlexElement>
                    {/* Provides space to the right. */}
                  </FlexElement>
                </Flexbox>
              </Col>
            </Row>
          </Grid>
        </div>
        <div>
          <Grid fluid>
            <Row>
              <Col xs={12}>{this.renderContent()}</Col>
            </Row>
          </Grid>
        </div>
        <div className="Lex-CardFooter">
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ padding: "0px" }}>
                {!readOnly ? (
                  <ButtonGroup justified>
                    <ButtonGroup>
                      <button
                        type="button"
                        className="btn Lex-button"
                        onClick={(event) =>
                          onChangePropertyValue("status", "Approved")
                        }
                      >
                        <Icon role="button" glyph="icon-fontello-check" />{" "}
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="friendRequestTimelineonApproveRequest"
                          defaultValue={"Godkend"}
                        />
                      </button>
                    </ButtonGroup>
                    <ButtonGroup>
                      <button
                        type="button"
                        className="btn Lex-button"
                        onClick={onDelete}
                      >
                        <Icon role="button" glyph="icon-fontello-trash-1" />
                        &nbsp;&nbsp;
                        <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                      </button>
                    </ButtonGroup>
                  </ButtonGroup>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

class BookkeepingGridView extends React.PureComponent {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    standardAccounts: PropTypes.array.isRequired,
    onUploadReceiptForExistingEntry: PropTypes.func.isRequired,
    onChangePropertyValue: PropTypes.func.isRequired,
    updateMainAccountAndVat: PropTypes.func.isRequired,
    onShowReceipt: PropTypes.func.isRequired,
    onDeleteEntry: PropTypes.func.isRequired,
    receiptsWhoseDatesCouldNotBeScanned: PropTypes.array.isRequired,
    updateDate: PropTypes.func.isRequired,
    showAllAccounts: PropTypes.bool.isRequired,
    showAllBalanceAccounts: PropTypes.bool.isRequired,
    updateAmountAndVat: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateDocCols = (entries) => {
    let {
      onDeleteEntry,
      onChangePropertyValue,
      onShowReceipt,
      updateMainAccountAndVat,
      onUploadReceiptForExistingEntry,
      standardAccounts,
      incomeAccounts,
      expenseAccounts,
      incomePaymentAccounts,
      expensePaymentAccounts,
      selectedEntries,
      onSelectEntry,
      receiptsWhoseDatesCouldNotBeScanned,
      updateDate,
      showAllAccounts,
      showAllBalanceAccounts,
      onShowAllAccounts,
      onShowAllBalanceAccounts,
      updateAmountAndVat,
      onAddImagePage,
      addingPageToId,
      addedPageToId,
      readonly,
    } = this.props;

    let cols = [[], [], []];
    let smallCols = [[], []];
    for (var i = 0; i < entries.length; i++) {
      let entry = entries[i];
      let entryCard = (
        <BookkeepingEntryCard
          key={entry.id}
          entry={entry}
          readonly={readonly}
          onChangePropertyValue={(propertyName, value) =>
            onChangePropertyValue(entry.id, propertyName, value)
          }
          updateMainAccountAndVat={updateMainAccountAndVat}
          standardAccounts={standardAccounts}
          incomeAccounts={incomeAccounts}
          expenseAccounts={expenseAccounts}
          incomePaymentAccounts={incomePaymentAccounts}
          expensePaymentAccounts={expensePaymentAccounts}
          selectedEntries={selectedEntries}
          onSelectEntry={onSelectEntry}
          onShowReceipt={() => onShowReceipt(entry.id)}
          onUploadReceiptForExistingEntry={() =>
            onUploadReceiptForExistingEntry(entry.id)
          }
          onDelete={() => onDeleteEntry(entry)}
          markDate={
            !!entry.receiptId &&
            !!receiptsWhoseDatesCouldNotBeScanned.includes(entry.receiptId)
          }
          updateDate={updateDate}
          showAllAccounts={showAllAccounts}
          showAllBalanceAccounts={showAllBalanceAccounts}
          onShowAllAccounts={onShowAllAccounts}
          onShowAllBalanceAccounts={onShowAllBalanceAccounts}
          updateAmountAndVat={updateAmountAndVat}
          onAddImagePage={onAddImagePage}
          addingPageToId={addingPageToId}
          addedPageToId={addedPageToId}
        />
      );

      cols[i % 3].push(entryCard);
      smallCols[i % 2].push(entryCard);
    }
    return [cols, smallCols];
  };

  renderDocumentGrid = (entries) => {
    let cols = this.generateDocCols(entries);
    let largeCols = cols[0];
    let smallCols = cols[1];

    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[0]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[1]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "25px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col sm={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  getShownEntries = () => {
    return this.props.entries.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    let { entries } = this.props;
    let { numberShown } = this.state;

    return (
      <Grid fluid style={{ background: "#ffffff" }}>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            {this.renderDocumentGrid(this.getShownEntries())}
          </Col>
        </Row>
        {numberShown < entries.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col xs={12} className="text-center" style={{ paddingTop: "10px" }}>
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2 "
                style={{ width: "75%" }}
              >
                <AxoLocal
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(BookkeepingGridView);
