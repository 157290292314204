import React from "react";
import CurrentForecast from "./CurrentForecast";
import FutureForecast from "./FutureForecast";
import Conditional from "../Conditional";
import { geolocated } from "react-geolocated";

class Weather extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    return (
      <div>
        {/* { this.props.coords ? (
          <div>
            {this.props.coords.latitude} {this.props.coords.longitude} 
          </div>
        ) : null } */}
        <div className="current-forecast list-group">
          <CurrentForecast
            city={this.props.city}
            coords={this.props.coords}
            refusedGeoCoords={
              this.props.isGeolocationAvailable &&
              !this.props.isGeolocationEnabled
            }
            expanded={this.state.expanded}
            toggleExpanded={this.toggleExpanded}
          />
        </div>
        <Conditional show={this.state.expanded}>
          <div
            className="future-forecast list-group"
            style={{ marginBottom: "0px" }}
          >
            <FutureForecast city={this.props.city} coords={this.props.coords} />
          </div>
        </Conditional>
      </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 10000,
})(Weather);
