import React from "react";

import AutoComplete from "./AutoComplete";

export default class InlineAutoComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      value: props.value,
    };

    this.input = null;
    this.blockSubmit = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value === this.props.value) {
      return;
    }

    this.setState({ value: this.props.value });
  }

  onSubmit = () => {
    let { onChange } = this.props;

    let { value } = this.state;

    onChange(value);
    this.setState({ editing: false });
  };

  onCancel = () => {
    let { value } = this.props;

    this.setState({
      value,
      editing: false,
    });
  };

  onEdit = () => {
    let { value, id } = this.props;

    this.setState(
      {
        value,
        editing: true,
      },
      () => {
        let input = document.getElementById(id);
        if (!!input) {
          input.setSelectionRange(0, input.value.length);
        }
        // this.input.current.setSelectionRange(0, this.input.current.value.length);
      }
    );
  };

  onKeyDown = (event) => {
    if (this.blockSubmit) {
      return;
    }

    switch (event.keyCode) {
      case 13: //Enter
        this.onSubmit();
        break;
      case 27: //Escape
        this.onCancel();
        break;
      default:
        break;
    }
  };

  onChange = (event) => {
    if (!event.target || event.target.value === undefined) {
      return; //Happens when selecting from the dropdown
    }

    this.setValue(event.target.value);
  };

  setValue = (value) => {
    this.setState({ value });
  };

  handleSelection = (value) => {
    this.blockSubmit = true; //In case the user selects the option by pressing enter
    this.setState({ value }, () => {
      this.blockSubmit = false;
      this.onSubmit();
    });
  };

  render() {
    let { editing, value } = this.state;

    if (editing) {
      return (
        <AutoComplete
          {...this.props}
          handleSelectedOption={this.handleSelection}
          onChange={this.onChange}
          inputRef={this.input}
          value={value}
          onBlur={this.onSubmit}
          onKeyDown={this.onKeyDown}
        />
      );
    }

    return (
      <span className="editable" onFocus={this.onEdit} tabIndex={0}>
        {value}
      </span>
    );
  }
}
