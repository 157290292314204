import React from "react";
import PropTypes from "prop-types";
import Conditional from "../utilities/Conditional";
import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import QueryString from "query-string";

import {
  RoutingService,
  ApiService,
  LocalizationService,
  VatService,
} from "../services/AxoServices";

import {
  Icon,
  AxoLocal,
  ctx,
  getText,
  Link,
  CountryDropdown,
  RegionDropdown,
  LexButton,
  withRouter,
  LoadingIcon,
  AxoFooter,
} from "../utilities/LexUtilities";

import LicenseConsentForm from "./Licenses/LicenseConsentForm";
import DataConsentForm from "./Licenses/DataConsentForm";

class Signup extends React.PureComponent {
  static propTypes = {
    userType: PropTypes.string,
    header: PropTypes.element,
  };

  constructor(props) {
    super(props);
    this.state = {
      signupViewModel: {
        userType: props.userType || "Lawyer",
        userName: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        company: "",
        companyCode: "",
        vatNumberConfirmed: false,
        address: "",
        city: "",
        postalCode: "",
        country: "",
        email: "",
        confirmedEmail: "",
        phone: "",
        mobile: "",
        dataProcessingConsentGiven: false,
      },
      attemptingSignup: false,
      validationState: this.getClearValidationState(),
      acceptedLicenseConditions: false,
      showLicenseAcceptanceWarning: false,
      showDataConsentAcceptanceWarning: false,
      locale: "enUS",
      validatingVATNumber: false,
      showInvalidVATNumberWarning: false,
      showMissingVatNumberWarning: false,
      showAddressMissingWarning: false,
      showMissingNameWarning: false,
      showMissingCountryWarning: false,
      adminCodeValidated: false,
    };
  }

  getClearValidationState = () => {
    return {
      //Backend validation
      invalidUserName: false,
      invalidPassword: false,
      missingEmail: false,
      duplicateEmail: false,
      duplicatePhone: false,
      genericError: false,
    };
  };

  onUpdateViewModelProperty = (propertyName, event) => {
    this.onUpdateViewModelPropertyValue(propertyName, event.target.value);
  };

  onUpdateViewModelPropertyValue = (propertyName, value) => {
    this.setState({
      signupViewModel: {
        ...this.state.signupViewModel,
        [propertyName]: value,
      },
    });
  };

  updateVatNumber = (vatNumber) => {
    this.setState({
      signupViewModel: {
        ...this.state.signupViewModel,
        companyCode: vatNumber,
        vatNumberConfirmed: false,
      },
    });
  };

  back(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.goBack();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    LocalizationService.subscribeToLocale("Signup", (locale) => {
      let currentLocale = locale.toLowerCase();

      let viewModel = { ...this.state.signupViewModel };

      if (currentLocale === "enus") {
        currentLocale = "enUS";
      }

      this.setState({
        signupViewModel: viewModel,
        locale: currentLocale,
      });
    });

    let query = QueryString.parse(this.props.location.search);
    let adminCodeValidated = query.adminCode === "AxolexAdmin";
    if (adminCodeValidated) {
      this.setState({ adminCodeValidated: true });
    }
  }

  componentWillUnmount() {
    LocalizationService.unsubscribeFromLocale("Signup");
  }

  getPath(path) {
    return path;
  }

  onLogin = (event) => {
    event.preventDefault();
    this.props.history.push(RoutingService.getAccountPath("Login"));
  };

  onCreateUser = () => {
    let { acceptedLicenseConditions } = this.state;

    var currentModel = this.state.signupViewModel;
    if (!acceptedLicenseConditions) {
      this.setState({ showLicenseAcceptanceWarning: true });
      return;
    }

    if (!currentModel.dataProcessingConsentGiven) {
      this.setState({ showDataConsentAcceptanceWarning: true });
      return;
    }

    var validationState = { ...this.state.validationState };
    var email = currentModel.email;
    if (email !== currentModel.confirmedEmail) {
      return;
    }

    if (!email || !this.validateEmail(email)) {
      validationState.missingEmail = true;
      this.setState({ validationState: validationState });
      return;
    }

    if (!currentModel.address) {
      this.setState({ showAddressMissingWarning: true });
      return;
    }

    if (!currentModel.firstName && !currentModel.company) {
      this.setState({ showMissingNameWarning: true });
      return;
    }

    var country = currentModel.country.toUpperCase();
    if (!country) {
      this.setState({ showMissingCountryWarning: true });
      return;
    }

    if (
      country !== "DK" &&
      VatService.isEUCountry(country) &&
      !currentModel.vatNumberConfirmed
    ) {
      this.setState({ showMissingVatNumberWarning: true });
      return;
    }

    this.setState({ attemptingSignup: true });
    let signupViewModel = { ...currentModel };
    signupViewModel.registerEmailSubject = ctx.getSync("SignupMailSubject");
    signupViewModel.registerEmailMessage = ctx.getSync("SignupMailMessage");
    signupViewModel.messageUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      RoutingService.getAccountPath("ConfirmEmail");

    signupViewModel.locale = this.state.locale;

    ApiService.register(signupViewModel)
      .then((response) => {
        validationState = this.getClearValidationState();
        if (response.status === 200) {
          this.props.history.push(
            RoutingService.getAccountPath("RequestConfirmEmail")
          );
          return Promise.resolve({});
        } else {
          return response.json();
        }
      })
      .then((parsedResponse) => {
        //Error
        if (parsedResponse.error === "DuplicateUserName") {
          validationState.invalidUserName = true;
        } else if (parsedResponse.error === "InvalidPassword") {
          validationState.invalidPassword = true;
        } else if (parsedResponse.error === "DuplicateEmail") {
          validationState.duplicateEmail = true;
        } else if (parsedResponse.error === "DuplicatePhone") {
          validationState.duplicatePhone = true;
        } else {
          validationState.genericError = true;
        }
        this.setState({
          validationState: validationState,
          attemptingSignup: false,
        });
      })
      .catch((reason) => {
        validationState.genericError = true;
        this.setState({
          validationState: validationState,
          attemptingSignup: false,
        });
        setTimeout(() => {
          validationState.genericError = false;
          this.setState({ validationState: validationState });
        }, 5000);
      });
  };

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  getEmailValidationState = (email) => {
    if (email.length === 0) {
      return null;
    }
    return this.validateEmail(email) ? "success" : "error";
  };

  getConfirmedEmailValidationState = (confirmedEmail) => {
    if (confirmedEmail.length === 0) {
      return null;
    }
    return confirmedEmail === this.state.signupViewModel.email
      ? "success"
      : "error";
  };

  getPasswordValidationState = () => {
    var password = this.state.signupViewModel.password;
    if (!password) {
      return null;
    }
    return password.length >= 4 ? "success" : "error";
  };

  getConfirmPasswordValidationState = () => {
    var confirmPassword = this.state.signupViewModel.confirmPassword;
    if (!confirmPassword) {
      return null;
    }
    return confirmPassword === this.state.signupViewModel.password
      ? "success"
      : "error";
  };

  selectCountry(val) {
    var viewModel = Object.assign({}, this.state.signupViewModel);
    viewModel.country = val;
    viewModel.vatNumberConfirmed = false;
    this.setState({
      signupViewModel: viewModel,
    });
  }

  selectRegion(val) {
    var viewModel = Object.assign({}, this.state.signupViewModel);
    viewModel.region = val;
    this.setState({ signupViewModel: viewModel });
  }

  onConfirmVatNumber = async () => {
    let country = this.state.signupViewModel.country.toUpperCase();
    if (country === "DK" || country === "NO") {
      //Use the more detailed Scandinavian API
      this.onConfirmScandinavianVatNumber();
      return;
    }

    let signupViewModel = { ...this.state.signupViewModel };

    try {
      this.setState({ validatingVATNumber: true });
      let companyInfo = await ApiService.getCompanyInfo(
        country,
        signupViewModel.companyCode
      );
      if (!companyInfo.valid) {
        this.handleVATNumberConfirmationFailure();
      }
      this.setState({ validatingVATNumber: false });

      signupViewModel.company = companyInfo.name[0];
      signupViewModel.address = companyInfo.address[0];
      signupViewModel.vatNumberConfirmed = true;
      this.setState({
        signupViewModel,
        showMissingVatNumberWarning: false,
      });
    } catch (error) {
      console.log(error.message);
      this.handleVATNumberConfirmationFailure();
    }
  };

  onConfirmScandinavianVatNumber = async () => {
    let signupViewModel = { ...this.state.signupViewModel };

    try {
      this.setState({ validatingVATNumber: true });
      let companyInfo = await ApiService.getCVRInformation(
        signupViewModel.companyCode,
        signupViewModel.country
      );

      this.setState({ validatingVATNumber: false });

      signupViewModel.company = companyInfo.name;
      signupViewModel.address = companyInfo.address;
      signupViewModel.city = companyInfo.city;
      signupViewModel.postalCode = companyInfo.zipcode;
      signupViewModel.vatNumberConfirmed = true;

      this.setState({
        signupViewModel,
        showMissingVatNumberWarning: false,
      });
    } catch (error) {
      console.log(error.message);
      this.handleVATNumberConfirmationFailure();
    }
  };

  handleVATNumberConfirmationFailure = () => {
    this.setState({
      validatingVATNumber: false,
      showInvalidVATNumberWarning: true,
    });
    setTimeout(() => {
      this.setState({
        showInvalidVATNumberWarning: false,
      });
    }, 3000);
  };

  render() {
    let { header } = this.props;

    let { adminCodeValidated } = this.state;

    let viewModel = this.state.signupViewModel;
    let {
      attemptingSignup,
      acceptedLicenseConditions,
      showLicenseAcceptanceWarning,
      showDataConsentAcceptanceWarning,
      locale,
      validatingVATNumber,
      showInvalidVATNumberWarning,
      showMissingVatNumberWarning,
      showAddressMissingWarning,
      showMissingNameWarning,
      showMissingCountryWarning,
    } = this.state;

    let passwordValidationState = this.getPasswordValidationState();

    let isEUCountry = VatService.isEUCountry(viewModel.country);

    let headerElement = (
      <>
        <Row style={{ marginTop: "0px" }}>
          <Col xs={12} className="nopadding" style={{ maxWidth: "100%" }}>
            <div className="frontPageaxolexdiv Bannecolorlogin">
              <span>
                <Icon glyph="icon-fontello-cog-7" />
                &nbsp;&nbsp;
                {header || (
                  <AxoLocal
                    entity="SignupCreateAccount"
                    defaultValue={"Opret konto"}
                  />
                )}
              </span>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col xs={12} className="nopadding">
            <div className='text-center'>
              <h5 style={{margin: 0, padding: 25}}>
                <AxoLocal entity='SignupCreatnewaccount'defaultValue={'Opret en ny konto'}/>
                </h5>
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col xs={12} className="nopadding">
            <div
              className="text-center"
              style={{
                margin: "0px",
                fontSize: " 15px",
                paddingBottom: "20px",
                paddingTop: "15px",
              }}
            >
              <AxoLocal
                entity="SignupAlreadyhaveaccount"
                defaultValue={"Har du allerede en konto?"}
              />
              &nbsp;&nbsp;&nbsp;
              <Link
                style={{ color: "cblue" }}
                to={RoutingService.getAccountPath("Login")}
              >
                <Icon glyph="icon-fontello-login" />
                &nbsp;&nbsp;
                <AxoLocal entity="LoginonLogin" defaultValue={"Log ind"} />
              </Link>
            </div>
          </Col>
        </Row>
      </>
    );

    if (!adminCodeValidated) {
      return (
        <div className="login" style={{ paddingTop: "25px" }}>
          <Grid fluid>
            {headerElement}
            <Row>
              <Col xs={12} className="text-center">
                {/* MissingEntity */}
                <h4>
                  Kontakt Axolex på{" "}
                  <a href="mailto:it@axolex.com">it@axolex.com</a> for at
                  oprette en konto.
                </h4>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }

    return (
      <div className="login" style={{ paddingTop: "25px" }}>
        <div>
          <div>
            <Grid fluid>
              {headerElement}
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={8}
                  mdOffset={2}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <Form>
                    <FormGroup controlId="UserName">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          {" "}
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon glyph="icon-fontello-user" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          autoFocus
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.userName}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "userName"
                          )}
                          componentAttribute="placeholder"
                          entity="bccMailplaceholderBrugernavn"
                        />
                      </InputGroup>
                      {this.state.validationState.invalidUserName ? (
                        <div className="axored">
                          <AxoLocal
                            entity="SignupvalidationStateinvalidUserName"
                            defaultValue={"Brugernavnet er optaget."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      validationState={this.getEmailValidationState(
                        viewModel.email
                      )}
                      controlId="Email"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          {" "}
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon glyph="icon-fontello-mail" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="email"
                          className="borderFormControlfocus"
                          value={viewModel.email}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "email"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholdersupportAxolex"
                        />
                      </InputGroup>
                      {this.state.validationState.missingEmail ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupEmailrequired"
                            defaultValue={"Email adressen er påkrævet."}
                          />
                        </div>
                      ) : null}
                      {this.state.validationState.duplicateEmail ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupEmailrequired1a"
                            defaultValue={"Email adressen anvendes allerede."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup
                      validationState={this.getConfirmedEmailValidationState(
                        viewModel.confirmedEmail
                      )}
                      controlId="ConfirmedEmail"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          {" "}
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon glyph="icon-fontello-mail" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.confirmedEmail}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "confirmedEmail"
                          )}
                          componentAttribute="placeholder"
                          entity="signupViewConfirmEmail"
                        />
                      </InputGroup>
                      {!!viewModel.confirmedEmail &&
                      viewModel.confirmedEmail !== viewModel.email ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="signupViewConfirmEmailNoMatch"
                            defaultValue={"Email adressen matcher ikke."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      validationState={passwordValidationState}
                      controlId="Password"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          {" "}
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon glyph="icon-fontello-key" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="password"
                          className="borderFormControlfocus"
                          value={viewModel.password}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "password"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholderviewModelpassword"
                        />
                      </InputGroup>
                      {this.state.validationState.invalidPassword ||
                      passwordValidationState === "error" ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupvalidationStateinvalidPassword"
                            defaultValue={
                              "Kodeordet skal indeholde mindst fire tegn."
                            }
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup
                      validationState={this.getConfirmPasswordValidationState()}
                      controlId="ConfirmPassword"
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          {" "}
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon glyph="icon-fontello-key" />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="password"
                          className="borderFormControlfocus"
                          value={viewModel.confirmPassword}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "confirmPassword"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholderConfirmpassword"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col xs={12} className="nopadding" style={{ maxWidth: "100%" }}>
                  <div className="frontPageaxolexdiv Bannecolorlogin">
                    <span>
                      <Icon
                        className="axogryicon"
                        glyph="icon-fontello-info-circled-alt"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="SignupuserInformation"
                        defaultValue={"Brugerinformation"}
                      />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={8}
                  mdOffset={2}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <Form>
                    <FormGroup controlId="country" className="onTopper">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          {" "}
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-globe-6"
                          />
                        </InputGroup.Addon>
                        <CountryDropdown
                          value={viewModel.country}
                          valueType="short"
                          customSelect
                          large
                          onChange={(val) => this.selectCountry(val)}
                        />
                      </InputGroup>
                      {showMissingCountryWarning ? (
                        <div className="axored">
                          <AxoLocal
                            entity="axoAccounting55"
                            defaultValue={" Du skal vælge dit land."}
                          />
                        </div>
                      ) : null}
                      {/* { !this.validateCountry(viewModel.country) ? (
                          <div className='axored'>
                            Axolex systemet er ikke tilgængeligt i dit land på nuværende tidspunkt.
                          </div>
                        ) : null } */}
                    </FormGroup>
                    <FormGroup controlId="region" className="onTop">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-globe-outline"
                          />
                        </InputGroup.Addon>
                        <RegionDropdown
                          country={viewModel.country}
                          countryValueType="short"
                          customSelect
                          value={viewModel.region || ""}
                          valueType="short"
                          large
                          onChange={(val) => this.selectRegion(val)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup controlId="FirstName">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-user-outline"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.firstName}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "firstName"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholderfirstNameA"
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup controlId="LastName">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-user-7"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.lastName}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "lastName"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholderlastNameB"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup controlId="Phone">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-phone-3"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.phone}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "phone"
                          )}
                          componentAttribute="placeholder"
                          entity="ContainerPhone"
                        />
                      </InputGroup>
                      {this.state.validationState.duplicatePhone ? (
                        <div style={{ color: "red" }}>
                          <AxoLocal
                            entity="SignupEmailrequired1b"
                            defaultValue={"Telefonnummeret anvendes allerede."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup controlId="Mobile">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-mobile"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.mobile}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "mobile"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholdermobile"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col xs={12} className="nopadding" style={{ maxWidth: "100%" }}>
                  <div className="frontPageaxolexdiv Bannecolorlogin">
                    <span>
                      <Icon
                        className="axogryicon"
                        glyph="icon-fontello-location-4"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        entity="SignupAdresse"
                        defaultValue={"Adresse"}
                      />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={8}
                  mdOffset={2}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <Form>
                    <FormGroup
                      controlId="companyCode"
                      validationState={
                        viewModel.vatNumberConfirmed ? "success" : null
                      }
                    >
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-feather"
                          />
                          {isEUCountry ||
                          viewModel.country.toUpperCase() === "NO" ? (
                            <React.Fragment>
                              &nbsp;
                              {viewModel.country}
                              {viewModel.companyCode.length >= 8 ? (
                                <React.Fragment>
                                  &nbsp;
                                  <LexButton
                                    disabled={
                                      viewModel.vatNumberConfirmed ||
                                      validatingVATNumber ||
                                      showInvalidVATNumberWarning
                                    }
                                    onClick={this.onConfirmVatNumber}
                                  >
                                    {viewModel.vatNumberConfirmed ? (
                                      <AxoLocal
                                        key="validated"
                                        entity="axoAccounting62a"
                                        defaultValue={"Validated"}
                                      />
                                    ) : (
                                      <AxoLocal
                                        key="validate"
                                        entity="axoAccounting63"
                                        defaultValue={"Valider"}
                                      />
                                    )}
                                    <LoadingIcon show={validatingVATNumber} />
                                  </LexButton>
                                </React.Fragment>
                              ) : null}
                            </React.Fragment>
                          ) : null}
                        </InputGroup.Addon>
                        <FormControl
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.companyCode}
                          onChange={(event) =>
                            this.updateVatNumber(event.target.value)
                          }
                          placeholder={getText(
                            "vatCountryCode31a",
                            "CVR nummer"
                          )}
                        />
                      </InputGroup>
                      {showInvalidVATNumberWarning ? (
                        <div className="axored">
                          <AxoLocal
                            entity="axoAccounting57"
                            defaultValue={
                              "CVR nummeret kunne ikke valideres. Tjeck at du har tastet korrekt."
                            }
                          />
                        </div>
                      ) : null}
                      {showMissingVatNumberWarning ? (
                        <div className="axored">
                          <AxoLocal
                            entity="axoAccounting58"
                            defaultValue={
                              "Du skal indtaste et gyldigt momsregistreringsnummer for at oprette en Axolex konto."
                            }
                          />
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup controlId="Company">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-building"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.company}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "company"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholdercompany"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup controlId="Address">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-feather"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.address}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "address"
                          )}
                          componentAttribute="placeholder"
                          entity="SignupAdresse"
                        />
                      </InputGroup>
                      {showAddressMissingWarning ? (
                        <div className="axored">
                          <AxoLocal
                            entity="axoAccounting59"
                            defaultValue={"Du skal indtaste din adresse."}
                          />
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup controlId="PostalCode">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            glyph="icon-fontello-star"
                            style={{ fontSize: "10px" }}
                          />
                          &nbsp;&nbsp;
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-direction-1"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.postalCode}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "postalCode"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholderpostalCode"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup controlId="City">
                      <InputGroup bsSize="large">
                        <InputGroup.Addon>
                          <Icon
                            className="axogryicon"
                            glyph="icon-fontello-warehouse"
                          />
                        </InputGroup.Addon>
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          className="borderFormControlfocus"
                          value={viewModel.city}
                          onChange={this.onUpdateViewModelProperty.bind(
                            this,
                            "city"
                          )}
                          componentAttribute="placeholder"
                          entity="placeholdercity"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Form>
                  <LicenseConsentForm
                    locale={locale}
                    checked={acceptedLicenseConditions}
                    onCheckChanged={(event) => {
                      this.setState({
                        acceptedLicenseConditions: event.target.checked,
                        showLicenseAcceptanceWarning: false,
                      });
                    }}
                    showWarning={showLicenseAcceptanceWarning}
                  />
                  <DataConsentForm
                    locale={locale}
                    checked={viewModel.dataProcessingConsentGiven}
                    onCheckChanged={(event) => {
                      this.onUpdateViewModelPropertyValue(
                        "dataProcessingConsentGiven",
                        event.target.checked
                      );
                      this.setState({
                        showDataConsentAcceptanceWarning: false,
                      });
                    }}
                    showWarning={showDataConsentAcceptanceWarning}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={8}
                  mdOffset={2}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <Form>
                    <br />
                    <FormGroup>
                      <Grid fluid>
                        <Row>
                          <Col xs={12} className="nopadding">
                            <Button
                              disabled={
                                this.state.attemptingSignup ||
                                !this.validateInput()
                              }
                              className="Lex-button-2"
                              block
                              onClick={this.onCreateUser}
                              style={{
                                borderRadius: "0px",
                                marginLeft: "0px",
                                marginRight: "0px",
                                fontSize: "17px",
                                fontWeight: "normal",
                                height: "65px",
                              }}
                            >
                              <span>
                                <Icon glyph="icon-fontello-user-add" />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <AxoLocal
                                  entity="Signupcreateanaccount"
                                  defaultValue={"Opret bruger"}
                                />
                                <Conditional inline show={attemptingSignup}>
                                  &nbsp;
                                  <img
                                    alt=""
                                    src="/imgs/app/loading.gif"
                                    className="img-circle"
                                    width="20"
                                    height="20"
                                  />
                                </Conditional>
                              </span>
                            </Button>
                            {this.state.validationState.missingEmail ? (
                              <div style={{ color: "red" }}>
                                <AxoLocal
                                  entity="SignupEmailrequired"
                                  defaultValue={"Email adressen er påkrævet."}
                                />
                              </div>
                            ) : null}
                            {this.state.validationState.duplicateEmail ? (
                              <div style={{ color: "red" }}>
                                <AxoLocal
                                  entity="SignupEmailrequired1a"
                                  defaultValue={
                                    "Email adressen anvendes allerede."
                                  }
                                />
                              </div>
                            ) : null}
                            {this.state.validationState.invalidUserName ? (
                              <div className="axored">
                                <AxoLocal
                                  entity="SignupvalidationStateinvalidUserName"
                                  defaultValue={"Brugernavnet er optaget."}
                                />
                              </div>
                            ) : null}
                            {showMissingCountryWarning ? (
                              <div className="axored">
                                <AxoLocal
                                  entity="axoAccounting55"
                                  defaultValue={"Du skal vælge dit land."}
                                />
                              </div>
                            ) : null}
                            {showMissingVatNumberWarning ? (
                              <div className="axored">
                                <AxoLocal
                                  entity="axoAccounting58"
                                  defaultValue={
                                    "Du skal indtaste et gyldigt momsregistreringsnummer for at oprette en Axolex konto."
                                  }
                                />
                              </div>
                            ) : null}
                            {showAddressMissingWarning ? (
                              <div className="axored">
                                <AxoLocal
                                  entity="axoAccounting55"
                                  defaultValue={"Adresse"}
                                />
                                <AxoLocal
                                  entity="axoAccounting59"
                                  defaultValue={"Du skal indtaste din adresse."}
                                />
                              </div>
                            ) : null}
                            {showMissingNameWarning ? (
                              <div className="axored">
                                <AxoLocal
                                  entity="axoAccounting60"
                                  defaultValue={
                                    "Du skal indtaste enten dit eget navn eller navnet på din virksomhed."
                                  }
                                />
                              </div>
                            ) : null}
                            <Conditional
                              show={this.state.validationState.genericError}
                            >
                              <div className="text-center axored">
                                <AxoLocal
                                  entity="Signupcreateanaccounterror"
                                  defaultValue={
                                    "An error occurred. Try again later."
                                  }
                                />
                              </div>
                            </Conditional>
                          </Col>
                        </Row>
                      </Grid>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  md={8}
                  mdOffset={2}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="nopadding"
                  style={{ maxWidth: "100%", marginTop: "25px" }}
                >
                  <div
                    className=" text-center"
                    style={{ padding: "20px", paddingTop: "12.5px" }}
                  >
                    <div style={{ marginTop: "25px", fontSize: " 15px" }}>
                      <AxoLocal
                        entity="SignupAlreadyhaveaccount"
                        defaultValue={"Har du allerede en konto?"}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Link to={RoutingService.getAccountPath("Login")}>
                        <Icon glyph="icon-fontello-login" />
                        &nbsp;&nbsp;
                        <AxoLocal
                          entity="LoginonLogin"
                          defaultValue={"Log ind"}
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
            </Grid>
          </div>
        </div>
        <AxoFooter />
      </div>
    );
  }

  //Basic validation not requiring an error message
  validateInput = () => {
    let model = this.state.signupViewModel;
    if (
      !model.userName ||
      !model.password ||
      model.password !== model.confirmPassword ||
      model.email !== model.confirmedEmail
    ) {
      return false;
    }
    return true;
  };
}

export default withRouter(Signup);
