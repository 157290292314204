import React from "react";
import { QRCodeGenerator, AxoLocal } from "../utilities/LexUtilities";

export default class QRCodeView extends React.PureComponent {
  render() {
    return (
      <QRCodeGenerator
        header={
          <AxoLocal
            entity="axolexQRcode1"
            defaultValue={"Lav din QR kode med logo helt gratis her"}
          />
        }
        value="https://axolex.com/freeware"
      />
    );
  }
}
