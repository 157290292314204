import React from "react";
import PropTypes from "prop-types";

import {
  ScreenSharingService,
  RoutingService,
  AuthorizationService,
  ApiService,
} from "../../services/AxoServices";

import { LexButton, AxoLocal } from "../../utilities/LexUtilities";

const signalR = require("@aspnet/signalr/dist/browser/signalr.js");

let RTCConnection = null;
let hubConnection = null;
let screenSharingStream = null;

function flattenObject(obj) {
  var result = {};
  for (var key in obj) {
    result[key] = obj[key];
  }
  return result;
}

export default class ScreenSharing extends React.PureComponent {
  static propTypes = {
    userProfile: PropTypes.object.isRequired,
    globalSettings: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      screenSharingEnabled: false,
      displaySharingError: false,
    };
    this.checkedEnabled = false;
    this.iceCandidateList = []; //ICE candidates must be cached if the remote connection has not yet been set
  }

  componentDidMount() {
    this.checkEnabled();
  }

  componentDidUpdate() {
    this.checkEnabled();
  }

  checkEnabled = () => {
    if (this.checkedEnabled) {
      return;
    }
    let { userProfile, globalSettings } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);
    if (!userProfile.id) {
      return;
    }

    if (
      userType !== "Admin" &&
      userProfile.userName !== globalSettings.sharingUser
    ) {
      return;
    }

    ScreenSharingService.isChromeExtensionAvailable((result) => {
      this.checkedEnabled = true;
      if (result) {
        this.setState(
          {
            screenSharingEnabled: true,
          },
          () => {
            if (screenSharingStream != null) {
              this.videoStreamPlayer.style.display = "block";
              this.videoStreamPlayer.srcObject = screenSharingStream;
              this.videoStreamPlayer.play();
            }
          }
        );
      }
    });
  };

  onShareScreen = () => {
    ScreenSharingService.getScreenConstraints((error, screen_constraints) => {
      if (error) {
        return alert(error);
      }

      let constraints = {
        video: screen_constraints,
      };

      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(this.handleScreenSharingstream)
          .catch(this.handleScreenSharingError);
      } else if (navigator.getUserMedia) {
        navigator.getUserMedia(
          constraints,
          this.handleScreenSharingstream,
          this.handleScreenSharingError
        );
      }
    });
  };

  handleScreenSharingstream = async (stream) => {
    let hubRoute = RoutingService.getHostPath() + "/webrtchub";

    hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubRoute)
      .build();

    hubConnection.on("ReceiveAnswer", async (message) => {
      try {
        console.log("Receiving answer");
        let sessionDesc = new RTCSessionDescription({
          type: "answer",
          sdp: message,
        });
        await RTCConnection.setRemoteDescription(sessionDesc);
        if (this.iceCandidateList.length > 0) {
          this.iceCandidateList.forEach((candidate) => {
            console.log("Adding cached ICE candidate");
            RTCConnection.addIceCandidate(
              new RTCIceCandidate(JSON.parse(candidate))
            );
          });
          this.iceCandidateList = [];
        }
      } catch (reason) {
        console.log(reason);
      }
    });
    hubConnection.on("ReceiveCandidate", (candidate) => {
      if (
        !RTCConnection ||
        !RTCConnection.remoteDescription ||
        !RTCConnection.remoteDescription.type
      ) {
        //Cache the candidate if the remote connection has not yet been set
        console.log("Caching ICE candidate");
        this.iceCandidateList.push(candidate);
      } else {
        console.log("Adding ICE candidate");
        RTCConnection.addIceCandidate(
          new RTCIceCandidate(JSON.parse(candidate))
        );
      }
    });

    try {
      await hubConnection.start().catch((err) => console.error(err.toString()));

      screenSharingStream = stream;
      this.videoStreamPlayer.style.display = "block";
      this.videoStreamPlayer.srcObject = stream;
      this.videoStreamPlayer.play();

      this.forceUpdate();

      let credentials = await ApiService.getTurnCredentials();
      console.log(credentials);
      console.log(credentials.ice_servers);
      RTCConnection = new RTCPeerConnection({
        iceServers: credentials.ice_servers,
      });
      RTCConnection.onicecandidate = (event) => {
        if (event.candidate) {
          // console.log('ICE candidate found.')
          console.log("Sending ICE candidate.");
          hubConnection.invoke(
            "SendCandidate",
            JSON.stringify(event.candidate)
          );
        }
        // else {
        //   this.sendOffer();
        // }
      };

      RTCConnection.onconnectionstatechange = (ev) => {
        switch (RTCConnection.connectionState) {
          case "disconnected":
            RTCConnection.restartIce();
            alert("Connection disconnected. Attempting reconnection.");
            break;
          case "failed":
            RTCConnection.restartIce();
            alert("Connection failes. Attempting new connection.");
            break;
          default:
            break;
        }
      };

      stream
        .getTracks()
        .forEach((track) => RTCConnection.addTrack(track, stream));

      let offer = await RTCConnection.createOffer();
      await RTCConnection.setLocalDescription(offer);
      this.sendOffer();
    } catch (reason) {
      console.log(reason);
    }
  };

  sendOffer = async () => {
    console.log("Sending offer.");
    let message = RTCConnection.localDescription;
    await hubConnection.invoke("SendOffer", message.sdp).catch((reason) => {
      console.error(reason);
    });
  };

  handleScreenSharingError = (error) => {
    console.log(JSON.stringify(flattenObject(error)));
    this.setState({ displaySharingError: true });
  };

  onStopSharing = () => {
    this.resetConnnections();
    this.videoStreamPlayer.pause();
    this.videoStreamPlayer.srcObject = null;
    this.videoStreamPlayer.style.display = "none";
    this.forceUpdate();
  };

  resetConnnections = () => {
    if (hubConnection) {
      hubConnection.stop();
      hubConnection = null;
    }
    if (screenSharingStream) {
      screenSharingStream.getVideoTracks()[0].stop();
      screenSharingStream = null;
    }
    if (RTCConnection) {
      RTCConnection.close();
      RTCConnection = null;
    }
  };

  render() {
    let { screenSharingEnabled, displaySharingError } = this.state;

    if (!screenSharingEnabled) {
      return null;
    }

    if (displaySharingError) {
      return (
        <h4 className="text-center axored">
          <AxoLocal
            entity="updategetAccountName13"
            defaultValue={
              "Skærmen kunne ikke deles. Genindlæs siden og prøv igen."
            }
          />
        </h4>
      );
    }

    return (
      <div className="text-center" style={{ paddingTop: "10px" }}>
        {screenSharingStream === null ? (
          <LexButton className="delButtoncolor" onClick={this.onShareScreen}>
            <AxoLocal
              key="share"
              entity="updategetAccountName14"
              defaultValue={"Del skærm med support"}
            />
          </LexButton>
        ) : (
          <LexButton onClick={this.onStopSharing}>
            <AxoLocal
              key="stopSharing"
              entity="updategetAccountName15"
              defaultValue={"Stop skærmdeling med support"}
            />
          </LexButton>
        )}

        <video
          ref={(c) => (this.videoStreamPlayer = c)}
          style={{ marginLeft: "25px", maxWidth: "40%", display: "none" }}
        />
      </div>
    );
  }
}
