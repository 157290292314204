import React from "react";
import MailConfigurationView from "./MailConfigurationView";

import { DataStore, DataActions } from "../../services/AxoServices";

import { Consumer } from "../../utilities/LexUtilities";

class MailConfigurationContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmailAccount: 0,
    };
  }

  onUpdateProperty = (propertyName, value) => {
    DataActions.updateUserSettings({
      ...this.props.userSettings,
      [propertyName]: value,
    });
  };

  onSelectEmailAccount = (event) => {
    this.setState({ selectedEmailAccount: parseInt(event.target.value, 10) });
  };

  render() {
    if (!this.props.userSettings.id) {
      return null;
    }
    return (
      <MailConfigurationView
        userSettings={this.props.userSettings}
        emailAccounts={this.props.emailAccounts}
        onUpdateProperty={this.onUpdateProperty}
      />
    );
  }
}

export default class MailConfigurationConsumer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserSettings();
    DataStore.fetchEmailAccounts();
  }

  render() {
    return (
      <Consumer>
        {(store) => (
          <MailConfigurationContainer
            userSettings={store.userSettings}
            emailAccounts={store.emailAccounts}
          />
        )}
      </Consumer>
    );
  }
}
