import React from "react";
import TinyMCE from "react-tinymce";

import { ErrorBoundary } from "../../utilities/LexUtilities";

export default class MinyTinyMCE extends React.PureComponent {
  //Props
  //text
  //onTextChange
  //height
  //setupEditor
  //locale
  setupEditor = (editor) => {
    editor.on("keyup", () => {
      this.props.onTextChange(editor.getContent());
    });
    editor.on("init", () => {
      this.initializeLineHeight(editor);
    });
    this.props.setupEditor(editor);
  };

  initializeLineHeight(editor) {
    var content = editor.getContent();
    var newContent = content.replace(
      "<head>",
      "<head><style>body { line-height: 18pt; }</style>"
    );
    editor.setContent(newContent);
  }

  convertLocale = () => {
    var locale = this.props.locale;
    switch (locale) {
      case "da":
        return "da";
      case "enUS":
        return "en_GB";
      case "ch":
        return "zh_CN";
      default:
        return "en_GB";
    }
  };

  onChange = (event) => {
    this.props.onTextChange(event.target.getContent());
  };

  render() {
    var language = this.convertLocale();
    return (
      <div className="mce-no-border">
        <ErrorBoundary>
          <TinyMCE
            key={language}
            content={this.props.text}
            config={{
              height: this.props.height,
              branding: false,
              language: language,
              paste_data_images: true,
              image_advtab: true,
              valid_elements: "*[*]",
              valid_children: "*[*]",
              forced_root_block: false,
              cleanup_on_startup: false,
              trim_span_elements: false,
              verify_html: false,
              cleanup: false,
              convert_urls: false,
              statusbar: true,
              browser_spellcheck: true,
              plugins: [
                "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                "searchreplace visualblocks visualchars code fullscreen",
                "insertdatetime media nonbreaking save table contextmenu directionality",
                "emoticons template paste textcolor colorpicker textpattern imagetools codesample fullpage",
              ],
              menubar: false,
              toolbar1:
                "undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | forecolor backcolor emoticons | image addAttachment addImage",
              setup: this.setupEditor,
            }}
            onChange={this.onChange}
          />
        </ErrorBoundary>
      </div>
    );
  }
}
