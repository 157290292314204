import React from "react";
import { Table, Column, Cell } from "fixed-data-table-2";
import moment from "moment";
import { FormControl, Button, InputGroup } from "react-bootstrap";

import {
  DataActions,
  UserInfoService,
  StringService,
} from "../../services/AxoServices";

import {
  Icon,
  SortHeaderCell,
  DataListWrapper,
  TableBase,
  Dimensions,
  AxoLocal,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  caseNumber: {
    width: 300,
    shown: true,
  },
  sharingDate: {
    width: 200,
    shown: true,
  },
  sharedString: {
    width: 300,
    shown: true,
  },
  ownerString: {
    width: 300,
    shown: true,
  },
  editor: {
    width: 300,
    shown: true,
  },
  actions: {
    width: 50,
    shown: true,
  },
};

class CaseSharingTable extends TableBase {
  //Props
  //sharedCases
  constructor(props) {
    super(props);
    this.name = "CaseSharingTable";
    this.defaultColumnDefs = defaultColumnDefs;

    let tableEntries = this.addDataStringsToEntries(props.sharedCases);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(tableEntries);
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 350,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
    };
  }

  addDataStringsToEntries = (entries) => {
    return entries.map((entry) => {
      var sortEntry = Object.assign({}, entry);

      sortEntry.sharedString =
        !sortEntry.owner &&
        sortEntry.sharedUsers &&
        sortEntry.sharedUsers.length > 0
          ? UserInfoService.getDisplayName(sortEntry.sharedUsers[0])
          : "";

      sortEntry.ownerString = sortEntry.owner
        ? UserInfoService.getDisplayName(sortEntry.owner)
        : "";
      return sortEntry;
    });
  };

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.addDataStringsToEntries(nextProps.sharedCases);
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  LabelSelect = (props) => {
    var caseModel = props.caseModel;
    if (caseModel.sharingUsers.length === 0) {
      return null;
    }
    var selectUserNodes = caseModel.sharingUsers.map((user) => {
      return (
        <option key={user.id} value={user.id}>
          {StringService.enforceMaxLength(
            UserInfoService.getDisplayName(user),
            25
          )}
        </option>
      );
    });
    return (
      <div>
        <select
          className="axoblue selectbg"
          style={{ border: "none" }}
          value="users"
          onChange={() => {}}
        >
          {selectUserNodes}
        </select>
        &nbsp; ({caseModel.sharingUsers.length})
      </div>
    );
  };

  renderOwnerInfo = (caseModel) => {
    if (!caseModel.owner) {
      return null;
    }
    var owner = caseModel.owner;
    return (
      <span
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "white",
        }}
      >
        <Icon
          className="editable"
          role="button"
          glyph="icon-fontello-share-1"
        />{" "}
        &nbsp;&nbsp;&nbsp;
        {UserInfoService.getDisplayName(owner)}
      </span>
    );
  };

  onSearch = (event) => {
    let { tableEntries } = this.state;

    let entries = this.getFilteredEntries(tableEntries, event.target.value);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }
    return entries.filter((f) => {
      return f.caseNumber.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  onTrashCase = (caseModelId) => {
    var caseModel = Object.assign(
      {},
      this.props.sharedCases.find((c) => c.id === caseModelId)
    );
    caseModel.trashed = true;
    DataActions.updateCase(caseModel);
  };

  onCaseClick = (caseId, event) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onCaseClick(caseId);
  };

  renderTable = () => {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth } = this.props;
    var tableWidth = containerWidth - 20;

    let LabelSelect = this.LabelSelect;
    return (
      <div>
        <div style={{ paddingTop: "15px" }}>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <div
              className="flexbox-layout"
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                maxWidth: "1000px",
              }}
            >
              <div>
                <h4>
                  <Icon glyph="icon-fontello-hourglass" />
                  &nbsp;
                  <AxoLocal
                    entity="Gruppeinformation20"
                    defaultValue={"Delte sager"}
                  />
                </h4>
              </div>
              <div>
                <Button
                  onClick={this.props.onToggleSharedCasesDetails}
                  className="Lex-button-2"
                >
                  <AxoLocal
                    entity="Gruppeinformation21"
                    defaultValue={"Vis detaljer"}
                  />
                </Button>
              </div>
              <div style={{ maxWidth: "500px" }}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-search" />
                  </InputGroup.Addon>
                  {/* Søg...  */}
                  <AxoLocal
                    componentClass={FormControl}
                    type="text"
                    value={this.state.searchText}
                    onChange={this.onSearch}
                    componentAttribute="placeholder"
                    entity="ContactTableViewplaceholderonSearch"
                  />
                  <FormControl.Feedback>
                    <Icon glyph="icon-fontello-search" />
                  </FormControl.Feedback>
                </InputGroup>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "0px", paddingLeft: "10px" }}>
            <Table
              rowHeight={50}
              rowsCount={sortedDataList.getSize()}
              height={containerHeight}
              width={tableWidth}
              isColumnResizing={false}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              headerHeight={50}
            >
              <Column
                columnKey="caseNumber"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.caseNumber}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseDataTableFixedFilename"
                      defaultValue={"Filnavn"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell
                      key={caseModel.id}
                      onClick={this.onCaseClick.bind(this, caseModel.id)}
                      {...props}
                    >
                      <a href={caseModel.caseNumber}>{caseModel.caseNumber}</a>
                    </Cell>
                  );
                }}
                width={columnDefs.caseNumber.width}
                isResizable={true}
              />
              <Column
                columnKey="sharingDate"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.sharingDate}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="axoidcode25" defaultValue={"Dato"} />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  let caseModel = sortedDataList.getObjectAt(props.rowIndex);
                  let sharingDate = moment(caseModel.sharingDate);
                  return (
                    <Cell key={caseModel.id} {...props}>
                      {sharingDate.year() > 1970 ? sharingDate.format("L") : ""}
                    </Cell>
                  );
                }}
                width={columnDefs.sharingDate.width}
                isResizable={true}
              />
              <Column
                columnKey="sharedString"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.sharedString}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;&nbsp;&nbsp;{" "}
                    <AxoLocal
                      entity="DocumentTabViewSharedwiththeusers"
                      defaultValue={"Delt med brugerne"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={caseModel.id} {...props}>
                      <LabelSelect caseModel={caseModel} />
                    </Cell>
                  );
                }}
                width={columnDefs.sharedString.width}
                isResizable={true}
              />
              <Column
                columnKey="editor"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.editor}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="Casesharing7"
                      defaultValue={"Tjekket ud af"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={caseModel.id} {...props}>
                      {caseModel.editor
                        ? UserInfoService.getDisplayName(caseModel.editor)
                        : ""}
                    </Cell>
                  );
                }}
                width={columnDefs.editor.width}
                isResizable={true}
              />
              <Column
                columnKey="ownerString"
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={colSortDirs.ownerString}
                  >
                    <Icon
                      style={{ color: "#4CAF50" }}
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="Gruppeinformation19"
                      defaultValue={"Ejer"}
                    />
                  </SortHeaderCell>
                }
                cell={(props) => {
                  var caseModel = sortedDataList.getObjectAt(props.rowIndex);
                  return (
                    <Cell key={caseModel.id} {...props}>
                      {this.renderOwnerInfo(caseModel)}
                    </Cell>
                  );
                }}
                width={columnDefs.ownerString.width}
                isResizable={true}
              />
              <Column
                columnKey="actions"
                header={<Cell></Cell>}
                cell={(props) => {
                  var { userProfile } = this.props;
                  var caseModel = sortedDataList.getObjectAt(props.rowIndex);

                  //The case can only be deleted by the owner, and only after checking out the case for editing
                  var showActions =
                    caseModel.editor &&
                    caseModel.editor.id === userProfile.id &&
                    caseModel.userName === userProfile.userName;

                  return (
                    <Cell key={caseModel.id} {...props}>
                      {showActions ? (
                        <div>
                          <Icon
                            role="button"
                            onClick={this.onTrashCase.bind(this, caseModel.id)}
                            className="editable"
                            glyph="icon-fontello-trash-1"
                          />
                        </div>
                      ) : null}
                    </Cell>
                  );
                }}
                width={columnDefs.actions.width}
                isResizable={true}
              />
            </Table>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.renderTable()}</div>;
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 200;
  },
})(CaseSharingTable);
