import React from "react";
import PropTypes from "prop-types";

import moment from "moment";

import { NumberService } from "../../../services/AxoServices";

import {
  AxoLocal,
  LexButton,
  Flexbox,
  FlexElement,
} from "../../../utilities/LexUtilities";

import { Table, ButtonToolbar } from "react-bootstrap";

const monthNames = [
  <AxoLocal
    key="january"
    entity="AccountingTabViewEntity45"
    defaultValue={"Januar"}
  />,
  <AxoLocal
    key="february"
    entity="AccountingTabViewEntity46"
    defaultValue={"Februar"}
  />,
  <AxoLocal
    key="march"
    entity="AccountingTabViewEntity47"
    defaultValue={"Marts"}
  />,
  <AxoLocal
    key="april"
    entity="AccountingTabViewEntity48"
    defaultValue={"April"}
  />,
  <AxoLocal
    key="may"
    entity="AccountingTabViewEntity49"
    defaultValue={"Maj"}
  />,
  <AxoLocal
    key="june"
    entity="AccountingTabViewEntity50"
    defaultValue={"Juni"}
  />,
  <AxoLocal
    key="july"
    entity="AccountingTabViewEntity51"
    defaultValue={"Juli"}
  />,
  <AxoLocal
    key="august"
    entity="AccountingTabViewEntity52"
    defaultValue={"August"}
  />,
  <AxoLocal
    key="september"
    entity="AccountingTabViewEntity53"
    defaultValue={"September"}
  />,
  <AxoLocal
    key="october"
    entity="AccountingTabViewEntity54"
    defaultValue={"Oktober"}
  />,
  <AxoLocal
    key="november"
    entity="AccountingTabViewEntity55"
    defaultValue={"November"}
  />,
  <AxoLocal
    key="december"
    entity="AccountingTabViewEntity56"
    defaultValue={"December"}
  />,
];

//Moms
export default class AccountingVatTable extends React.PureComponent {
  static propTypes = {
    vatReportArray: PropTypes.array.isRequired,
    selectedPlan: PropTypes.object.isRequired,
    // entries: PropTypes.array.isRequired,
    selectedContact: PropTypes.object.isRequired,
    selectedFiscalYear: PropTypes.object.isRequired,
  };

  ButtonColumn = ({ amount, startDate, endDate, settled, paid }) => {
    let { selectedPlan, onSettleVat, settlingVat, onPayVat, payingVat } =
      this.props;

    return (
      <Flexbox collapseMD>
        <FlexElement>{NumberService.formatDecimal(amount)}</FlexElement>
        <FlexElement flexGrow={1} className="text-right rightPadding">
          <ButtonToolbar className="inlineBlock">
            <LexButton
              onClick={() => onSettleVat(startDate, endDate)}
              disabled={
                settled || !selectedPlan.vatSettlementAccountId || settlingVat
              }
            >
              <AxoLocal entity="axoidcode113" defaultValue={"Afregn moms"} />
            </LexButton>
            {settled ? (
              <LexButton
                onClick={() => onPayVat(startDate, endDate)}
                disabled={payingVat || paid}
              >
                <AxoLocal entity="axoidcode114" defaultValue={"Betal moms"} />
              </LexButton>
            ) : null}
          </ButtonToolbar>
        </FlexElement>
      </Flexbox>
    );
  };

  render() {
    let { vatReportArray, selectedFiscalYear } = this.props;

    let totalIngoing = vatReportArray.reduce((acc, vat) => {
      return acc + vat.ingoing;
    }, 0);

    let totalOutgoing = vatReportArray.reduce((acc, vat) => {
      return acc + vat.outgoing;
    }, 0);

    let yearStartDate = new Date(selectedFiscalYear.startDate);
    let yearEndDate = new Date(selectedFiscalYear.endDate);

    var showYears = yearStartDate.getFullYear() !== yearEndDate.getFullYear();

    let vatPeriod = selectedFiscalYear.vatPeriod;

    let ButtonColumn = this.ButtonColumn;
    return (
      <div className="AccountingpaddingTB">
        <Table responsive>
          <tbody>
            <tr className="Axoaccountsbannebg">
              <th>
                <AxoLocal
                  entity="CalendarTabViewmonth1"
                  defaultValue={"Måned"}
                />
              </th>
              <th>
                {" "}
                <AxoLocal
                  entity="AccountingTabViewEntity21"
                  defaultValue={"Indgående moms"}
                />
              </th>
              <th>
                {" "}
                <AxoLocal
                  entity="AccountingTabViewEntity22"
                  defaultValue={"Udgående moms"}
                />{" "}
              </th>
              <th>
                {" "}
                <AxoLocal
                  entity="invoiPaymentattheTotal"
                  defaultValue={"I alt"}
                />{" "}
              </th>
            </tr>
            {vatReportArray.map((m, index) => {
              let rows = [];
              rows.push(
                <tr key={index}>
                  <th className="col-xs-2">{monthNames[m.monthIndex]}</th>
                  <td className="col-xs-3 text-right">
                    {NumberService.formatDecimal(m.ingoing)}
                  </td>
                  <td className="col-xs-3 text-right">
                    {NumberService.formatDecimal(m.outgoing)}
                  </td>
                  <td className="col-xs-4">
                    {vatPeriod === "Month" ? (
                      (() => {
                        let startDate = moment(
                          new Date(
                            yearStartDate.getFullYear() +
                              Math.floor(index / 12),
                            index % 12
                          )
                        );
                        let endDate = moment(startDate).endOf("month");
                        return (
                          <ButtonColumn
                            amount={m.ingoing + m.outgoing}
                            startDate={startDate}
                            endDate={endDate}
                            settled={vatReportArray[index].settled}
                            paid={vatReportArray[index].paid}
                          />
                        );
                      })() //Execute inline function
                    ) : (
                      <React.Fragment>
                        {(m.ingoing + m.outgoing).toFixed(2)}
                      </React.Fragment>
                    )}
                  </td>
                </tr>
              );
              let testIndex = m.monthIndex + 1;
              if (vatPeriod === "Quarter" && index > 0 && testIndex % 3 === 0) {
                let monthReports = vatReportArray.slice(index - 2, index + 1);

                let incoming = monthReports.reduce((acc, vat) => {
                  return acc + vat.ingoing;
                }, 0);

                let outGoing = monthReports.reduce((acc, vat) => {
                  return acc + vat.outgoing;
                }, 0);

                let quarterStartDate = moment(yearStartDate)
                  .add(index - 2, "months")
                  .startOf("month");
                let quarterEndDate = moment(yearStartDate)
                  .add(index, "months")
                  .endOf("month");

                let settled = !!monthReports.find((r) => r.settled);
                let paid = !!monthReports.find((r) => r.paid);

                rows.push(
                  <tr key={"quarter" + index}>
                    <th className="Axoaccountsbannebg">
                      {testIndex / 3}.
                      <AxoLocal
                        entity="AccountingTabViewEntity59"
                        defaultValue={"kvartal"}
                      />
                      {showYears ? (
                        <span>&nbsp;{quarterStartDate.year()}</span>
                      ) : null}
                    </th>
                    <td className="Axoaccountsbannebg text-right">
                      {NumberService.formatDecimal(incoming)}
                    </td>
                    <td className="Axoaccountsbannebg text-right">
                      {NumberService.formatDecimal(outGoing)}
                    </td>
                    <td className="Axoaccountstotalbanne">
                      <ButtonColumn
                        amount={incoming + outGoing}
                        startDate={quarterStartDate}
                        endDate={quarterEndDate}
                        settled={settled}
                        paid={paid}
                      />
                    </td>
                  </tr>
                );
              }
              if (
                vatPeriod === "HalfYear" &&
                index > 0 &&
                testIndex % 6 === 0
              ) {
                let halfYearReport = vatReportArray.slice(index - 5, index + 1);

                let incoming = halfYearReport.reduce((acc, vat) => {
                  return acc + vat.ingoing;
                }, 0);

                let outGoing = halfYearReport.reduce((acc, vat) => {
                  return acc + vat.outgoing;
                }, 0);

                let halfYearStartDate = moment(yearStartDate)
                  .add(index - 5, "months")
                  .startOf("month");
                let halfYearEndDate = moment(yearStartDate)
                  .add(index, "months")
                  .endOf("month");

                let settled = !!halfYearReport.find((r) => r.settled);
                let paid = !!halfYearReport.find((r) => r.paid);

                rows.push(
                  <tr key={"halfyear" + index}>
                    <th className="Axoaccountsbannebg">
                      {(index + 1) / 6}.
                      <AxoLocal entity="axoidcode119" defaultValue={"halvår"} />
                      {showYears ? (
                        <span>&nbsp;{halfYearStartDate.year()}</span>
                      ) : null}
                    </th>
                    <td className="Axoaccountsbannebg  text-right">
                      {NumberService.formatDecimal(incoming)}
                    </td>
                    <td className="Axoaccountsbannebg  text-right">
                      {NumberService.formatDecimal(outGoing)}
                    </td>
                    <td className="Axoaccountstotalbanne">
                      <ButtonColumn
                        amount={incoming + outGoing}
                        startDate={halfYearStartDate}
                        endDate={halfYearEndDate}
                        settled={settled}
                        paid={paid}
                      />
                    </td>
                  </tr>
                );
              }
              return rows;
            })}

            <tr className="doubleboder">
              <th>
                {" "}
                <AxoLocal
                  entity="AccountingTabViewEntity43"
                  defaultValue={"Årsresultat"}
                />
              </th>
              <td className="text-right">
                {NumberService.formatDecimal(totalIngoing)}
              </td>
              <td className="text-right">
                {NumberService.formatDecimal(totalOutgoing)}
              </td>
              <td>
                {vatPeriod === "Year" ? (
                  (() => {
                    let settled = !!vatReportArray.find((r) => r.settled);
                    let paid = !!vatReportArray.find((r) => r.paid);

                    return (
                      <ButtonColumn
                        amount={totalIngoing + totalOutgoing}
                        startDate={yearStartDate}
                        endDate={yearEndDate}
                        settled={settled}
                        paid={paid}
                      />
                    );
                  })() //Execute inline function
                ) : (
                  <React.Fragment>
                    {(totalIngoing + totalOutgoing).toFixed(2)}
                  </React.Fragment>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
