export default {
  isImage(document) {
    if (document.contentType && document.contentType.includes("image")) {
      return true;
    }
    //Legacy support.
    var fileType = document.fileType
      .substring(1, document.fileType.length)
      .toLowerCase();
    return (
      fileType === "png" ||
      fileType === "jpg" ||
      fileType === "jpeg" ||
      fileType === "gif" ||
      fileType === "bmp"
    );
  },
};
