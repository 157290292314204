import React from "react";
import DataActions from "../services/DataAccess/DataActions";
import DataStore from "../services/DataAccess/DataStore";
import screenfull from "screenfull";
// import FlagMenu from "../utilities/FlagMenu";
import getScrollOffSet from "./getScrollOffSet";
import {
  SmallOrSmaller,
  // SmallOrLarger,
  MediumOrLarger,
} from "../utilities/Responsive";
import { AuthorizationService } from "../services/AxoServices";

import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";

import {
  Icon,
  AxoLocal,
  // Link,
  withRouter,
  Flexbox,
  FlexElement,
} from "../utilities/LexUtilities";

// import ContactForm from './ContactForm'

class InfoFrontPageView extends React.PureComponent {
  onClickRoute = (route) => {
    this.props.history.push(route);
    window.scrollTo(0, 0);
  };

  onLogin = () => {
    var userSettings = this.props.userSettings;
    if (userSettings.id) {
      DataActions.logout().then((response) => {
        if (response.ok) {
          DataStore.initializeUserProfile();
          DataStore.initializeUserSettings();
          DataStore.fetchGlobalSettings();
        }
      });
    } else {
      this.props.history.push("/Users/Login");
    }
  };

  onDashboard = () => {
    let { userProfile, history } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);
    if (!userProfile.id) {
      return;
    }
    let role = userType;

    if (role === "Lawyer" || role === "Accountant" || role === "Admin") {
      history.push("/Dashboard");
    } else if (
      role === "Client" ||
      role === "AccountingClient" ||
      role === "SocietyMember" ||
      role === "EasyID"
    ) {
      history.push("/ClientDashboard");
    } else if (role === "XLink") {
      history.push("/Xlink");
    } else if (role === "Society") {
      history.push("/Dashboard");
    }
  };

  onScrollToSolution = (element, event) => {
    event.preventDefault();
    event.stopPropagation();
    var here = this.props.location.pathname.toLowerCase().includes("solutions");
    var scrollElement = document.getElementById(element);
    if (here && scrollElement) {
      //ToDo: Animated scrolling to the top.
      // window.scrollTo(0, 0);
      // $('#' + element).scrollintoview({
      //   duration: 'slow',
      //   // direction: "vertical",
      //   viewPadding: { y: 90 },
      //   complete: function() {
      //       // highlight the element so user's focus gets where it needs to be
      //   }
      // });
      this.onScrollToElement(scrollElement);
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Solutions");
    }
  };

  onScrollToPackage = (element, event) => {
    event.preventDefault();
    var scrollElement = document.getElementById(element);
    if (scrollElement) {
      this.onScrollToElement(scrollElement);
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Packages");
    }
  };

  onScrollToAbout = (element, event) => {
    event.preventDefault();
    var scrollElement = document.getElementById(element);
    if (scrollElement) {
      this.onScrollToElement(scrollElement);
    } else {
      DataStore.setScrollId(element);
      this.props.history.push("/Company");
    }
  };

  onScrollToElement(element) {
    if (!element) {
      //Can happen if the user navigates away before the scroll is initiated
      return;
    }
    element.scrollIntoView();
    window.scrollBy(0, getScrollOffSet());
  }

  onGoToFront = () => {
    this.props.history.push("/");
    window.scrollTo(0, 0);
  };

  onFullScreenToggle = () => {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
    this.setState({}); //Trigger a rerender to update the full screen button
  };

  renderFullScreenButtonContent = () => {
    if (screenfull.isFullscreen) {
      return (
        <Icon glyph="icon-fontello-resize-small-3" className="Topmenuimg" />
      );
    }
    return <Icon glyph="icon-fontello-resize-full-4" className="Topmenuimg" />;
  };

  renderNavBar = () => {
    var { userSettings } = this.props;

    return (
      <div
        className="lex-front-navbar"
        style={{ position: "fixed", zIndex: 100, top: 0, left: 0, right: 0 }}
      >
        <Grid fluid className="front-navbar-ls">
          <SmallOrSmaller>
            <Row>
              <div
                className="btn-group front-navbar btn-group-justified"
                role="group"
              >
                <div className="btn-group" role="group">
                  <Button
                    // onClick={this.onGoToFront}
                    className="Lex-button-1 FontPageViewNavButton"
                  >
                    <img
                      alt=""
                      className="Logosize"
                      src="/svg/Axologo1-012.png"
                    />
                    &ensp;{" "}
                    <span>
                      <AxoLocal
                        entity="FrontPageMenubarAxolex"
                        defaultValue={"Axolex"}
                        style={{ fontSize: "19px" }}
                      />
                    </span>
                  </Button>
                </div>
                <div className="btn-group FontPageViewNavButton" role="group">
                  <Button
                    // onClick={this.onGoToFront}
                    className="Lex-button-1 FontPageViewNavButton"
                  ></Button>
                  {/* <FlagMenu
                    className="Lex-button-1 FontPageViewNavButton"
                    userSettings={userSettings}
                  /> */}
                </div>
                {userSettings.id ? (
                  <div className="btn-group FontPageViewNavButton" role="group">
                    <Button
                      onClick={this.onDashboard}
                      className="Lex-button-1 FontPageViewNavButton"
                    >
                      <Icon
                        className="frontnavbarimg"
                        glyph="icon-fontello-cog-7"
                      />
                    </Button>
                  </div>
                ) : null}
                {/* <SmallOrLarger>
                  <div
                    className="btn-group FontPageViewNavButton hidden-xs hidden-sm "
                    role="group"
                  >
                    <Button
                      onClick={this.onFullScreenToggle}
                      className="Lex-button-1 FontPageViewNavButton"
                    >
                      {this.renderFullScreenButtonContent()}
                    </Button>
                  </div>
                </SmallOrLarger> */}
                <div
                  className="btn-group front-navbar websiteLogin"
                  role="group"
                  style={{ borderRadius: "0px" }}
                >
                  <Button
                    onClick={this.onLogin}
                    className="Lex-button-1 FontPageViewNavButton"
                  >
                    {userSettings.id ? (
                      <span>
                        <Icon
                          glyph="icon-fontello-logout-3"
                          className="frontnavbarimg"
                        />
                        &ensp;&ensp;
                        <span className="Loginout">
                          <AxoLocal
                            key="logout"
                            entity="FrontPageMenubarAxolexonLogout"
                            defaultValue={"Log ud "}
                          />
                        </span>
                      </span>
                    ) : (
                      <span>
                        <Icon
                          glyph="icon-fontello-off-1"
                          className="frontnavbarimg"
                        />
                        &ensp;&ensp;
                        <span className="Loginout">
                          <AxoLocal
                            key="login"
                            entity="LoginonLogin"
                            defaultValue={"Log ind"}
                          />
                        </span>
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </Row>
          </SmallOrSmaller>
          <Row>
            <Col xs={12} sm={12} className="nopadding">
              <ButtonGroup>
                <div className="btn-group btn-group-justified" role="group">
                  <MediumOrLarger>
                    <div className="btn-group" role="group">
                      <Button
                        // onClick={this.onGoToFront}
                        className="Lex-button-1 FontPageViewNavButton"
                      >
                        <img
                          alt=""
                          className="Logosize"
                          src="/svg/Axologo1-012.png"
                        />
                        &ensp;{" "}
                        <span>
                          <AxoLocal
                            entity="FrontPageMenubarAxolex"
                            defaultValue={"Axolex"}
                            style={{ fontSize: " 19px" }}
                          />
                        </span>
                      </Button>
                    </div>
                  </MediumOrLarger>
                  <MediumOrLarger>
                    <div
                      className="btn-group front-navbar btn-group-justified"
                      role="group"
                    >
                      <div
                        className="btn-group FontPageViewNavButton"
                        role="group"
                      >
                        <Button
                          // onClick={this.onFullScreenToggle}
                          className="Lex-button-1 FontPageViewNavButton"
                        >
                          {/* {this.renderFullScreenButtonContent()} */}
                        </Button>
                        {/* <FlagMenu
                          className="Lex-button-1 FontPageViewNavButton"
                          userSettings={this.props.userSettings}
                        /> */}
                      </div>
                      {userSettings.id ? (
                        <div
                          className="btn-group FontPageViewNavButton"
                          role="group"
                        >
                          <Button
                            onClick={this.onDashboard}
                            className="Lex-button-1 FontPageViewNavButton "
                          >
                            <Icon
                              glyph="icon-fontello-cog-7"
                              style={{ fontSize: "25px" }}
                            />
                          </Button>
                        </div>
                      ) : null}
                      <div
                        className="btn-group front-navbar websiteLogin"
                        role="group"
                        style={{ borderRadius: "0px" }}
                      >
                        <Button
                          onClick={this.onLogin}
                          className="Lex-button-1 FontPageViewNavButton"
                        >
                          {userSettings.id ? (
                            <span>
                              <Icon
                                glyph="icon-fontello-logout-3"
                                style={{ fontSize: "30px" }}
                              />
                              &ensp;&ensp;
                              <span className="Loginout hidden-xs hidden-sm hidden-md">
                                <AxoLocal
                                  key="logout"
                                  entity="FrontPageMenubarAxolexonLogout"
                                  defaultValue={"Log ud"}
                                />
                              </span>
                            </span>
                          ) : (
                            <span>
                              <Icon
                                glyph="icon-fontello-off-1"
                                style={{ fontSize: "30px" }}
                              />
                              &ensp;&ensp;
                              <span
                                className="Loginout hidden-xs hidden-sm hidden-md"
                                style={{ fontSize: "18px" }}
                              >
                                <AxoLocal
                                  key="login"
                                  entity="LoginonLogin"
                                  defaultValue={"Log ind "}
                                />
                              </span>
                            </span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </MediumOrLarger>
                </div>
              </ButtonGroup>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col xs={12} className="nopadding">
              <div className="frontPageaxolexdiv Bannecolor">
                {/* <AxoLocal
                  entity="FrontPageindextaxt10B"
                  defaultValue={"Axolex --- One stop Service"}
                /> */}
              </div>
            </Col>
          </Row>
        </Grid>
        <Flexbox
          spaceBetween
          collapseXS
          className="standardMaxWidth topPadding"
          style={{ margin: "auto" }}
        >
          <FlexElement>
            <div
              className="text-left frontPageaxolexdivtextbox"
              style={{ marginLeft: "10px" }}
            >
              {/* <div style={{ fontSize: "22px" }}>© &ensp;&ensp;&ensp; </div> */}
              <div className="text-left " role="button">
                <div onClick={this.onGoToFront}>
                  <img
                    alt=""
                    height="200px"
                    width="200px"
                    src="/svg/Axologo1-014.png"
                  />
                </div>
              </div>
            </div>
          </FlexElement>
          <FlexElement>
            <div
              className="text-left frontPageaxolexdivtextbox"
              style={{ marginLeft: "10px" }}
            >
              <div>
                <h3>
                  {" "}
                  <AxoLocal
                    entity="FrontPageindextaxt11"
                    defaultValue={"Find os på"}
                  />{" "}
                </h3>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/Axolex.dk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    className="axolexwebsideicon"
                    glyph="icon-fontello-facebook-squared"
                    style={{ fontSize: " 30px" }}
                  />{" "}
                  <AxoLocal
                    entity="ContactCreateViewFacebook"
                    defaultValue={"Facebook"}
                  />
                </a>
              </div>
              {/* <div><span><Icon className='axolexwebsideicon' glyph='icon-fontello-linkedin-squared' style={{fontSize:' 30px'}}/>  <AxoLocal entity='ContactCreateViewLinkedIn'defaultValue={'LinkedIn'}/></span></div> */}
              <div>
                {" "}
                <a
                  href="https://twitter.com/AxolexApS"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    className="axolexwebsideicon"
                    glyph="icon-fontello-twitter-squared"
                    style={{ fontSize: " 30px" }}
                  />{" "}
                  <AxoLocal
                    entity="ContactCreateViewTwitter"
                    defaultValue={"Twitter"}
                  />{" "}
                </a>
              </div>
            </div>
          </FlexElement>
          <FlexElement>
            <div className="text-left frontPageaxolexdivtextbox">
              <div>
                <h3>
                  <AxoLocal
                    entity="FrontPageviewtext12A"
                    defaultValue={"Axolex"}
                  />
                </h3>
              </div>
              <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />:
              </div>
              <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                Strandvejen 60
              </div>
              <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                2900 Hellerup
              </div>
              <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                Denmark
              </div>
              <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                CVR: 39076969
              </div>
              <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />
                :53780153
              </div>
              <div style={{ fontSize: " 12px", lineHeight: "15px" }}>
                <AxoLocal entity="CaseEditFormeMail" defaultValue={"Email"} />:
                <div>
                  <a href="mailto:info@axolex.com">info@axolex.com</a>{" "}
                </div>
                <div>
                  <a href="mailto:it@axolex.com">it@axolex.com</a>{" "}
                </div>
                <div>
                  <a href="mailto:legal@axolex.com">legal@axolex.com</a>{" "}
                </div>
                <div>
                  <a href="mailto:support@axolex.com">support@axolex.com</a>{" "}
                </div>
              </div>
            </div>
          </FlexElement>
        </Flexbox>
        {/* <Grid fluid>
         <Row>
            <Col  xs={12} className="nopadding">
            <div className='image-banner'>
              <div className="banner-container">
                <div className="banner-item">
                  <MediumOrLarger>
                  <img alt='' className="img-responsive bg_img" style={{maxWidth: '100%'}}  src="/svg/Axolex3-lg.jpg" />
                  </MediumOrLarger>
                  <SmallOrSmaller>
                  <img alt='' className="img-responsive bg_img" style={{maxWidth: '100%'}}  src="/svg/Axolex3-xs.jpg" />
                  </SmallOrSmaller>
                  <div className="text_wrapper">
                    <div className="text_position chbannetaxt" style={{Color:"white"}}>
                      <h2 className="item--title"><AxoLocal entity='Companyaboutostaxt11' defaultValue={'Axolex'}/></h2>
                      <p className="item--text" ><AxoLocal entity='Companyaboutostaxt12' defaultValue={' Komplet juridisk IT-løsning'}/></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
       </Grid>   */}
      </div>
    );
  };

  goToFront = () => {
    this.props.history.push("/");
  };

  // renderBottomFooter = () => {
  //   return (
  //     <SmallOrSmaller>
  //       <Grid
  //         fluid
  //         className="axolextextbanne Bannecolor"
  //         style={{
  //           position: "fixed",
  //           bottom: 0,
  //           left: 0,
  //           right: 0,
  //           zIndex: 1000,
  //         }}
  //       >
  //         <Row
  //           className="text-left"
  //           style={{ height: "40px", lineHeight: "40px" }}
  //         >
  //           <div
  //             style={{
  //               Color: "black",
  //               fontSize: "13px",
  //               fontWeight: "normal",
  //               paddingLeft: "10px",
  //             }}
  //           >
  //             <Link to="/" onClick={this.goToFront}>
  //               <AxoLocal
  //                 entity="Companyaboutostaxt11"
  //                 defaultValue={"Axolex ApS"}
  //               />
  //               &nbsp; &#9658;&nbsp;
  //             </Link>
  //             <AxoLocal
  //               entity="FrontPageindextaxt1"
  //               defaultValue={"Creating new standards"}
  //             />
  //             &nbsp;&#9658;&nbsp;
  //             Softwareudvikling og juridisk rådgivning
  //           </div>
  //         </Row>
  //       </Grid>
  //     </SmallOrSmaller>
  //   );
  // };

  render() {
    return (
      <div className="axoFrontPage" style={{ paddingBottom: "13px" }}>
        {this.renderNavBar()}
        <div className="top-margin-box-simple"></div>
        {this.props.children}
        {this.renderFooter()}
        {/* {this.renderBottomFooter()} */}
      </div>
    );
  }
}

export default withRouter(InfoFrontPageView);
