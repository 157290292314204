// @ts-check
import React from "react";
import {
  CountryDropdown,
  RegionDropdown,
} from "../../utilities/CountrySelect/Dropdowns";
import * as $ from "jquery";

import { Grid, Row, Col, Button, FormControl } from "react-bootstrap";

import {
  DataStore,
  DataActions,
  ModalService,
} from "../../services/AxoServices";

import { Icon, AxoLocal, InlineEdit, ctx } from "../../utilities/LexUtilities";

export default class MarketingUserProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmMailSent: false,
      confirmCodeSent: false,
      confirmationCode: "",
      changePassword: false,
      newPasswordViewModel: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      changePasswordSuccess: false,
    };
  }
  onProfilePropertyChange(propertyName, input) {
    var profile = Object.assign({}, this.props.userProfile);
    profile[propertyName] = input.value;
    this.props.onUpdate(profile);
  }
  onCheckAccept = (event) => {
    this.props.userProfile.acceptRequests = event.target.checked;
    this.props.onUpdate(this.props.userProfile);
  };
  handleSelectedLogo = (document) => {
    this.props.userProfile.logo = document;
    this.props.onUpdate(this.props.userProfile);
  };
  uploadLogo = () => {
    this.uploadFile("logo");
  };
  handleSelectedProfileImage = (document) => {
    this.props.userProfile.profileImage = document;
    this.props.onUpdate(this.props.userProfile);
  };
  uploadProfileImage = () => {
    this.uploadFile("profileImage");
  };

  uploadFile = (propertyToSet) => {
    var component = this;
    $("#uploadImage").trigger("click");
    $("#uploadImage").on("change", function () {
      var file = this.files[0];
      if (!file.type.includes("image")) {
        return;
      }
      DataActions.uploadDocuments(this.files).then((response) => {
        if (response.ok) {
          response.json().then((addedFiles) => {
            component.props.userProfile[propertyToSet] = addedFiles[0];
            component.props.onUpdate(component.props.userProfile);
          });
        }
      });
    });
  };

  renderInputField = (label, propertyName) => {
    var userProfile = this.props.userProfile;
    return (
      <div>
        <b>{label}:</b>{" "}
        <InlineEdit
          value={userProfile[propertyName] || "---"}
          change={this.onProfilePropertyChange.bind(this, propertyName)}
        />
      </div>
    );
  };

  onSendConfirmationMail = () => {
    DataActions.confirmMail().then((response) => {
      if (response.ok) {
        this.setState({ confirmMailSent: true });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="SidebarRightContainerTheaddresscorrect"
            defaultValue={
              "Beskeden kunne ikke sendes. Tjek at adressen er korrekt."
            }
          />
        );
      }
    });
  };

  renderEmailConfirmationField = () => {
    var userProfile = this.props.userProfile;
    if (userProfile.eMailConfirmed) {
      return null;
    }
    if (this.state.confirmMailSent) {
      return (
        <div>
          {" "}
          <AxoLocal
            entity="SidebarRightContainerconfirmMailSent"
            defaultValue={"Bekræftelsesmail er afsendt"}
          />{" "}
        </div>
      );
    }
    return (
      <div>
        <div className="axored">
          <b>
            {" "}
            <AxoLocal
              entity="SidebarRightContainerEmailnotconfirmed"
              defaultValue={"Email ikke bekræftet"}
            />
          </b>
        </div>
        <Button
          className="Lex-button-1xlink"
          onClick={this.onSendConfirmationMail}
        >
          <span>
            {" "}
            <Icon glyph="icon-fontello-forward-outline" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="SidebarRightContainerSendconfirmationemail"
              defaultValue={"Send bekræftelsesmail"}
            />
          </span>
        </Button>
      </div>
    );
  };

  onSendConfirmationCode = () => {
    DataActions.confirmPhone(ctx.getSync("ConfirmPhoneMessage")).then(
      (response) => {
        if (response.ok) {
          this.setState({ confirmCodeSent: true });
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="SidebarRightContainerThemessagecorrect"
              defaultValue={
                "Beskeden kunne ikke sendes. Tjek at nummeret er korrekt."
              }
            />
          );
        }
      }
    );
  };

  onChangeCode = (event) => {
    this.setState({ confirmationCode: event.target.value });
  };

  onEnterConfirmationCode = () => {
    DataActions.confirmPhoneCode(this.state.confirmationCode).then(
      (response) => {
        if (response.ok) {
          this.setState({ confirmCodeSent: false });
          DataStore.updateProperty("userProfile", {
            ...this.props.userProfile,
            internalPhoneConfirmed: true,
          });
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="SidebarRightContainerThenumbercorrect"
              defaultValue={
                "Nummeret kunne ikke godkendes. Tjek at koden er korrekt."
              }
            />
          );
        }
      }
    );
  };

  renderPhoneConfirmationField = () => {
    var userProfile = this.props.userProfile;
    if (userProfile.internalPhoneConfirmed) {
      return null;
    }
    if (this.state.confirmCodeSent) {
      return (
        <div>
          <div>
            <b>
              {" "}
              <AxoLocal
                entity="SidebarRightContainerEntertheverificationcode"
                defaultValue={"Indtast bekræftelseskode"}
              />
            </b>
          </div>
          <div>
            <input
              type="text"
              value={this.state.confirmationCode}
              onChange={this.onChangeCode}
            />
            <Button
              className="Lex-button-1xlink"
              onClick={this.onEnterConfirmationCode}
            >
              <AxoLocal
                entity="SidebarRightContainerSendButton"
                defaultValue={"Send"}
              />
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="axored">
          <b>
            {" "}
            <AxoLocal
              entity="SidebarRightSendConfirmationCode"
              defaultValue={"Telefon ikke bekræftet"}
            />
          </b>
        </div>
        <Button
          className="Lex-button-1xlink"
          onClick={this.onSendConfirmationCode}
        >
          <span>
            {" "}
            <Icon glyph="icon-fontello-forward-outline" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="SidebarRightConfirmaSendConfirmationCode"
              defaultValue={"Send bekræftelseskode"}
            />
          </span>
        </Button>
      </div>
    );
  };

  onChangePassword = () => {
    this.setState({ changePassword: true });
  };

  onSubmitPassword = () => {
    DataActions.changePassword(this.state.newPasswordViewModel).then(
      (response) => {
        if (response.ok) {
          this.setState({
            changePassword: false,
            newPasswordViewModel: {
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            },
            changePasswordSuccess: true,
          });
          setTimeout(() => {
            this.setState({ changePasswordSuccess: false });
          }, 3000);
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="SidebarRightContainerThepasswordleastlength"
              defaultValue={"Kodeordet skal indeholde mindst fire tegn."}
            />
          );
        }
      }
    );
  };

  onNewPasswordPropertyChange = (propertyName, event) => {
    var viewModel = Object.assign({}, this.state.newPasswordViewModel);
    viewModel[propertyName] = event.target.value;
    this.setState({ newPasswordViewModel: viewModel });
  };

  renderPasswordField = () => {
    var viewModel = this.state.newPasswordViewModel;
    if (this.state.changePassword) {
      return (
        <div>
          <Grid fluid>
            <Row>
              <Col style={{ padding: "0px" }}>
                <div>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContainerchangePassword"
                      defaultValue={"Skift kodeord"}
                    />
                  </b>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "0px" }} sm={4}>
                <div>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContainerPresentPassword"
                      defaultValue={"Nuværende"}
                    />
                    :
                  </b>
                </div>
              </Col>
              <Col style={{ padding: "0px" }} sm={8}>
                <FormControl
                  type="password"
                  value={viewModel.oldPassword}
                  onChange={this.onNewPasswordPropertyChange.bind(
                    this,
                    "oldPassword"
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "0px" }} sm={4}>
                <div>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContainerNewpassword"
                      defaultValue={"Nyt kodeord"}
                    />
                    :
                  </b>
                </div>
              </Col>
              <Col style={{ padding: "0px" }} sm={8}>
                <AxoLocal
                  componentClass={FormControl}
                  type="password"
                  value={viewModel.newPassword}
                  onChange={this.onNewPasswordPropertyChange.bind(
                    this,
                    "newPassword"
                  )}
                  componentAttribute="placeholder"
                  entity="SidebarRightonNewPasswordPropertyChange"
                />
              </Col>
              {viewModel.newPassword && viewModel.newPassword.length < 4 ? (
                <div className="axored">
                  <AxoLocal
                    entity="SidebarRightContainernewPasswordlength"
                    defaultValue={"Kodeordet skal være på mindst 4 tegn."}
                  />
                </div>
              ) : null}
            </Row>
            <Row>
              <Col style={{ padding: "0px" }} sm={4}>
                <div>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContainerConfirmPassword"
                      defaultValue={"Bekræft"}
                    />
                    :
                  </b>
                </div>
              </Col>
              <Col style={{ padding: "0px" }} sm={8}>
                <FormControl
                  type="password"
                  value={viewModel.confirmPassword}
                  onChange={this.onNewPasswordPropertyChange.bind(
                    this,
                    "confirmPassword"
                  )}
                />
                {viewModel.confirmPassword &&
                viewModel.newPassword !== viewModel.confirmPassword ? (
                  <div className="axored">
                    <AxoLocal
                      entity="SidebarRightConfirmatwonewpasswordarenotthesame"
                      defaultValue={"De to nye kodeord er ikke ens."}
                    />{" "}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "5px 0px" }} xs={12} className="text-left">
                <Button
                  className="Lex-button-1xlink"
                  onClick={this.onSubmitPassword}
                >
                  <AxoLocal
                    entity="SidebarRightContainerchangePasswordSubmit"
                    defaultValue={"Skift"}
                  />
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  className="Lex-button-1xlink"
                  onClick={() => {
                    this.setState({ changePassword: false });
                  }}
                >
                  <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
                </Button>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        <div>
          <Icon glyph="icon-fontello-qrcode" />
          &nbsp;
          <b>
            <AxoLocal
              entity="SidebarRightContainerPassword"
              defaultValue={"Kodeord"}
            />
            :
          </b>
          ********* &nbsp;&nbsp;&nbsp;&nbsp;
          <Button className="Lex-button-1xlink" onClick={this.onChangePassword}>
            <AxoLocal
              entity="SidebarRightContaineronChangePassword"
              defaultValue={"Skift"}
            />
          </Button>
        </div>
        {this.state.changePasswordSuccess ? (
          <div style={{ color: "#4CAF50" }}>
            <Icon glyph="icon-fontello-ok-3" />
            &nbsp;
            <AxoLocal
              entity="SidebarRightContainerchangePasswordSucces"
              defaultValue={"Kodeordet blev skiftet."}
            />
          </div>
        ) : null}
      </div>
    );
  };

  renderTwoFactorOn = (enabled) => {
    if (enabled) {
      return (
        <b>
          <AxoLocal
            entity="SidebarRightContainerTurnedon"
            defaultValue={"Enabled"}
          />
        </b>
      );
    }
    return (
      <Button className="Lex-button-1xlink" onClick={this.enableTwoFactor}>
        <AxoLocal
          entity="SidebarRightContainerTurnOn"
          defaultValue={"Enable"}
        />
      </Button>
    );
  };

  renderTwoFactorOff = (enabled) => {
    if (enabled) {
      return (
        <Button className="Lex-button-1xlink" onClick={this.disableTwoFactor}>
          <AxoLocal
            entity="SidebarRightContainerTurnoff"
            defaultValue={"Disable"}
          />
        </Button>
      );
    }
    return (
      <b>
        <AxoLocal
          entity="SidebarRightConfirmaDisabled"
          defaultValue={"Disabled"}
        />
      </b>
    );
  };

  enableTwoFactor = () => {
    DataActions.enableTwoFactor();
  };

  disableTwoFactor = () => {
    DataActions.disableTwoFactor();
  };

  renderTwoFactorField = () => {
    var userProfile = this.props.userProfile;
    return (
      <div>
        <span>
          <Icon glyph="icon-fontello-barcode-1" />
          &nbsp;
          <b>
            <AxoLocal
              entity="SidebarRightContainerTofaktor"
              defaultValue={"To-faktor"}
            />
            :
          </b>{" "}
        </span>
        &nbsp;&nbsp;
        {this.renderTwoFactorOn(userProfile.twoFactorEnabled)}
        &nbsp;&nbsp;
        {this.renderTwoFactorOff(userProfile.twoFactorEnabled)}
      </div>
    );
  };

  onChangeShowInfoTexts = (event) => {
    var userSettings = Object.assign({}, this.props.userSettings);
    userSettings.showInfoTexts = event.target.checked;
    DataActions.updateUserSettings(userSettings);
  };

  onSetTwoFactorEnabled = (enabled) => {
    if (enabled) {
      DataActions.enableTwoFactor();
    } else {
      DataActions.disableTwoFactor();
    }
  };

  selectCountry(val) {
    var userProfile = Object.assign({}, this.props.userProfile);
    userProfile.country = val;
    this.props.onUpdate(userProfile);
  }

  selectRegion(val) {
    var userProfile = Object.assign({}, this.props.userProfile);
    userProfile.region = val;
    this.props.onUpdate(userProfile);
  }

  //userProfile
  render() {
    var userProfile = this.props.userProfile;
    return (
      <div
        className="text-L"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        <Grid fluid>
          <Row>
            <Col sm={6} className="nopadding" style={{ padding: "0px" }}>
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-publish" />
                  &nbsp;
                  <AxoLocal
                    entity="presentationMarketing21"
                    defaultValue={"Sideoverskrift"}
                  />
                </span>,
                "header"
              )}
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-user-8" />
                  &nbsp;
                  <AxoLocal
                    entity="presentationMarketing22"
                    defaultValue={"Display navn"}
                  />
                </span>,
                "displayName"
              )}
              <div style={{ padding: "0px" }}>
                <Icon glyph="icon-fontello-user" />
                &nbsp;{" "}
                <span>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContaineruserProfileuserName"
                      defaultValue={"Brugernavn"}
                    />
                    :
                  </b>
                </span>
                {userProfile.userName}
              </div>
              {this.renderPasswordField()}
              {this.renderTwoFactorField()}
              <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <Icon glyph="icon-fontello-user-7" />
                &nbsp;
                <span>
                  <b>
                    <AxoLocal
                      entity="SidebarRightContaineruseruserName"
                      defaultValue={"Navn"}
                    />
                    :
                  </b>
                </span>{" "}
                <InlineEdit
                  value={userProfile.firstName || "---"}
                  change={this.onProfilePropertyChange.bind(this, "firstName")}
                />{" "}
                <InlineEdit
                  value={userProfile.lastName || "---"}
                  change={this.onProfilePropertyChange.bind(this, "lastName")}
                />
              </div>
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-building" />
                  &nbsp;
                  <AxoLocal
                    entity="SidebarRightContainercompanyName"
                    defaultValue={"Virksomhed"}
                  />
                </span>,
                "company"
              )}
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-target-4" />
                  &nbsp;
                  <AxoLocal
                    entity="SidebarRightContainerCompanyCode"
                    defaultValue={"CVR"}
                  />
                </span>,
                "companyCode"
              )}
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-feather" />
                  &nbsp;
                  <AxoLocal
                    entity="SidebarRightContainerStilling"
                    defaultValue={"Stilling"}
                  />
                </span>,
                "position"
              )}
            </Col>
            <Col sm={6} className="nopadding" style={{ padding: "0px" }}>
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-location-4" />
                  &nbsp;
                  <AxoLocal entity="SignupAdresse" defaultValue={"Adresse "} />
                </span>,
                "address"
              )}
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-direction-1" />
                  &nbsp;
                  <AxoLocal
                    entity="SidebarRightContainerpostalCode"
                    defaultValue={"Postnummer"}
                  />
                </span>,
                "postalCode"
              )}
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-warehouse" />
                  &nbsp;
                  <AxoLocal
                    entity="SidebarRightContainerCity"
                    defaultValue={"By"}
                  />
                </span>,
                "city"
              )}

              <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <b>
                  <span>
                    <Icon glyph="icon-fontello-globe-6" />
                    &nbsp;
                    <AxoLocal
                      entity="SidebarRightContainerCountry"
                      defaultValue={"Land"}
                    />
                  </span>
                  :
                </b>{" "}
                <div style={{ display: "inline-block", width: "75%" }}>
                  <CountryDropdown
                    value={userProfile.country || ""}
                    customSelect
                    valueType="short"
                    onChange={(val) => this.selectCountry(val)}
                  />
                </div>
              </div>
              <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <b>
                  <span>
                    <Icon glyph="icon-fontello-globe-outline" />
                    &nbsp;
                    <AxoLocal
                      entity="SidebarRightContainerRegion"
                      defaultValue={"Region"}
                    />
                  </span>
                  :
                </b>{" "}
                <div style={{ display: "inline-block", width: "75%" }}>
                  <RegionDropdown
                    country={userProfile.country}
                    countryValueType="short"
                    value={userProfile.region || ""}
                    valueType="short"
                    onChange={(val) => this.selectRegion(val)}
                  />
                </div>
              </div>

              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-phone" />
                  &nbsp;
                  <AxoLocal
                    entity="SidebarRightContainerinternalPhone"
                    defaultValue={"Intern tlf"}
                  />
                </span>,
                "internalPhone"
              )}
              {this.renderPhoneConfirmationField()}
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-phone" />
                  &nbsp;
                  <AxoLocal entity="ContainerPhone" defaultValue={"Telefon"} />
                </span>,
                "phone"
              )}
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-mobile" />
                  &nbsp;
                  <AxoLocal
                    entity="SidebarRightContainermobile"
                    defaultValue={"Mobil"}
                  />
                </span>,
                "mobile"
              )}
              {this.renderInputField(
                <span>
                  <Icon glyph="icon-fontello-at-2" />
                  &nbsp;
                  <AxoLocal
                    entity="SidebarRightContainerEmail"
                    defaultValue={"Email"}
                  />{" "}
                </span>,
                "eMail"
              )}
              {this.renderEmailConfirmationField()}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
