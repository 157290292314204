import React from "react";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import { ApiService } from "../../services/AxoServices";

import {
  // LexButton,
  AsyncButton,
} from "../../utilities/LexUtilities";

export default class VLPaymentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
    };
  }

  onUpdateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  onPay = async () => {
    let { customerId } = this.state;

    try {
      let response = await ApiService.makePaymentLink({
        customerId,
        amount: 5.0,
      });
      if (!response.ok) {
        return false;
      }
      let link = await response.text();
      console.log(link);
      window.open(link);
      return true;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  render() {
    let { customerId } = this.state;

    return (
      <div className="standardMaxWidth">
        <Form>
          <FormGroup controlId="equityIntervalEnd">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              ID-nummer
            </ControlLabel>
            <FormControl
              type="text"
              className="borderFormControlfocus"
              value={customerId}
              placeholder="12345678"
              onChange={(event) =>
                this.onUpdateProperty("customerId", event.target.value)
              }
            />
          </FormGroup>
          <ButtonToolbar>
            <AsyncButton onClick={this.onPay}>Betal</AsyncButton>
            {/* <LexButton onClick={() => onSubmit(this.state)}><AxoLocal entity='friendRequestTimelineonApproveRequest'defaultValue={'Godkend'}/></LexButton>
            <LexButton onClick={this.onCancel}>Fortryd</LexButton> */}
          </ButtonToolbar>
        </Form>
      </div>
    );
  }
}
