// @ts-check
import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import UserInfoService from "../../services/UserInfoService";
import StringService from "../../services/StringService";
import SocialFilesView from "./SocialFilesView";
import SocialView from "./SocialView";
import { DataStore } from "../../services/AxoServices";
import Conditional from "../../utilities/Conditional";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import { Row, Col, Grid, DropdownButton, MenuItem } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

import { LexNavButton, AxoNavButton } from "../../utilities/LexUtilities";

export default class SocialTabView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  handleActiveState = (eventKey) => {
    this.props.handleNavigation(eventKey);
  };

  handleActiveStateWithoutHistory = (eventKey) => {
    this.setState({
      activeTab: eventKey,
      expanded: false,
    });
  };

  goToConversation = (convoId) => {
    this.handleActiveState(this.getConvoKeyFromId(convoId));
  };

  renderSocialView = (
    bannerUser,
    socialPosts,
    type,
    readOnly,
    conversationUsers,
    privateConversation,
    userProfileArray
  ) => {
    var { userProfile, privateConversations } = this.props;
    return (
      <div style={{ minHeight: "900px" }}>
        <SocialView
          userProfile={userProfile}
          userSettings={this.props.userSettings}
          bannerUser={bannerUser}
          onAddPrivateConversation={this.onAddPrivateConversation}
          socialPosts={socialPosts}
          conversationUsers={conversationUsers}
          userProfileArray={userProfileArray || []}
          privateConversation={privateConversation}
          privateConversationArray={privateConversations}
          friends={this.props.friends}
          readOnly={readOnly}
          documentMap={this.props.documentMap}
          onCreatePost={this.props.onCreatePost.bind(
            this,
            type,
            privateConversation ? privateConversation.id : 0
          )}
          onDeletePost={this.props.onDeletePost}
          onAddComment={this.props.onAddComment}
          onDeleteComment={this.props.onDeleteComment}
          type={type}
          shownPosts={this.props.shownPosts}
          onShowMore={this.props.onShowMore}
          onUpdate={this.props.onUpdate}
          goToConversation={this.goToConversation}
          onExitConvo={this.onExitConvo}
        />
      </div>
    );
  };

  onRemoveSelectedUser = (event) => {
    event.preventDefault();
    event.stopPropagation();

    DataActions.selectUserProfile(0);
    this.handleActiveState("Public");
  };

  getConvoKey = (convo) => {
    return this.getConvoKeyFromId(convo.id);
  };

  getConvoKeyFromId = (convoId) => {
    return "Private/" + convoId.toString();
  };

  onExitConvoButton = (convoId, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.onExitConvo(convoId);
  };

  onExitConvo = (convo) => {
    DataActions.deletePrivateConversationUser(convo, this.props.userProfile.id);
    this.handleActiveState("Public");
  };

  getPrivateTabName = (convo) => {
    return convo.name ? (
      StringService.enforceMaxLength(convo.name, 20)
    ) : (
      <span>
        <AxoLocal
          entity="SocialTabViewgetPrivateTabName"
          defaultValue="Privat"
        />
        -{convo.id.toString()}
      </span>
    );
  };

  onAddPrivateConversation = (privateConvo) => {
    this.props
      .onAddPrivateConversation(privateConvo)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(new Error("Conversation creation failed"));
      })
      .then((newConvo) => {
        this.handleActiveState(this.getConvoKey(newConvo));
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  getPublicLabel = (hasUnread) => {
    return hasUnread ? (
      <span>
        <AxoLocal entity="socialTabViewNavPublic" defaultValue={"Fælles"} />{" "}
        &nbsp;{" "}
        <Icon
          style={{ fontSize: "10px", color: "#306C67" }}
          glyph="icon-fontello-circle"
        />
      </span>
    ) : (
      <span>
        <AxoLocal entity="socialTabViewNavPublic" defaultValue={"Fælles"} />{" "}
      </span>
    );
  };

  getInternalLabel = (hasUnread) => {
    return hasUnread ? (
      <span>
        <AxoLocal entity="socialTabViewNavInternal" defaultValue={"Intern"} />{" "}
        &nbsp;{" "}
        <Icon
          style={{ fontSize: "10px", color: "#306C67" }}
          glyph="icon-fontello-circle"
        />
      </span>
    ) : (
      <span>
        <AxoLocal entity="socialTabViewNavInternal" defaultValue={"Intern"} />
      </span>
    );
  };

  makeInfoPost = (infoImageUrl) => {
    return [
      {
        id: 0,
        userProfile: {
          displayName: "Axolex admin",
          city: "Frederiksberg",
          country: "DK",
        },
        infoImageUrl: infoImageUrl,
        dateTime: new Date(),
        comments: [],
        likers: [],
        type: 0,
        read: false,
      },
    ];
  };

  renderLargeScreenNavigationBar = () => {
    let { activeTab, showDropdowns } = this.props;
    let newPublic = !!this.props.publicPosts.find((p) => !p.read);
    let newInternal = !!this.props.internalPosts.find((p) => !p.read);
    let userProfile = this.props.userProfile;
    let selectedUser = this.props.selectedUserProfile;

    return (
      <Large>
        <Grid
          fluid
          className="axo-nav-lg"
          id="noprint"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          {!showDropdowns ? (
            <Row>
              <Col xs={12} className="nopadding paddingToppx tabBannerRow">
                <div
                  className="btn-group btn-group-justified"
                  role="group"
                  style={{ borderRadius: "0px", paddingTop: "0px" }}
                >
                  <LexNavButton
                    className="axonavTab axonavTabcolora"
                    ls
                    glyph="icon-fontello-cog-7"
                    eventKey="Public"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    {this.getPublicLabel(newPublic)}
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorb"
                    ls
                    glyph="icon-fontello-spin2"
                    eventKey="Internal"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    {this.getInternalLabel(newInternal)}
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolorc"
                    ls
                    glyph="icon-fontello-doc-text"
                    eventKey="Files"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    <AxoLocal
                      key="Files"
                      entity="Searchattachmentsfiles"
                      defaultValue={"filer"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    className="axonavTab axonavTabcolord"
                    ls
                    glyph="icon-fontello-home-1"
                    eventKey="MyProfile"
                    activeTab={activeTab}
                    onClick={this.handleActiveState}
                  >
                    {UserInfoService.getDisplayName(userProfile)}
                  </LexNavButton>
                  {selectedUser.id ? (
                    <LexNavButton
                      className="axonavTab axonavTabcolord"
                      ls
                      glyph="icon-fontello-target"
                      eventKey="Profile"
                      activeTab={activeTab}
                      onClick={this.handleActiveState}
                    >
                      {UserInfoService.getDisplayName(selectedUser)}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Icon
                        onClick={this.onRemoveSelectedUser}
                        glyph="icon-fontello-cancel-alt"
                      />
                    </LexNavButton>
                  ) : null}
                  <LexNavButton
                    className="axonavTab axonavTabcolore"
                    ls
                    glyph="icon-fontello-plus-1"
                    eventKey="AddPrivate"
                    activeTab={activeTab}
                    onClick={this.onAddPrivateConversation.bind(this, {
                      onGoing: true,
                    })}
                  >
                    <AxoLocal
                      entity="socialTabViewNavAddPrivate"
                      defaultValue={"Tilføj Rum"}
                    />
                  </LexNavButton>
                  <LexNavButton
                    ls
                    listButton
                    className="axonavTab showListButton"
                    onClick={() => {
                      DataStore.setShowDropDowns(true);
                    }}
                  >
                    <div className="flexbox-center">
                      <Icon
                        glyph="icon-fontello-align-justify"
                        style={{ fontSize: "30px" }}
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AccountingTabViewEntity66"
                        defaultValue={"Liste Menu"}
                      />
                    </div>
                  </LexNavButton>
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ paddingLeft: "3%", paddingTop: "5px" }}>
              <DropdownButton
                style={{ width: "100%" }}
                id="IT"
                noCaret
                title={
                  <div style={{ display: "inline-block" }}>
                    <div className="flexbox-center">
                      <Icon
                        style={{ fontSize: "25px" }}
                        glyph="icon-fontello-align-justify"
                      />
                      &nbsp;&nbsp;
                      <AxoLocal
                        style={{ fontSize: "20px" }}
                        entity="Enhedspris33"
                        defaultValue={"Menu"}
                      />
                    </div>
                  </div>
                }
              >
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    DataStore.setShowDropDowns(false);
                  }}
                >
                  <AxoLocal
                    entity="AccountingTabViewEntity67"
                    defaultValue={"Vis menu bar"}
                  />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                  &nbsp;
                  <span className="icon-fontello-stop" />
                </MenuItem>
                <MenuItem
                  style={{ paddingBottom: "10px" }}
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Public")}
                >
                  <span className="icon-fontello-cog-7" /> &nbsp;&nbsp;{" "}
                  {this.getPublicLabel(newPublic)}
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Internal")}
                >
                  <span className="icon-fontello-spin2" /> &nbsp;&nbsp;{" "}
                  {this.getInternalLabel(newInternal)}
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("Files")}
                >
                  <span className="icon-fontello-doc-text" /> &nbsp;&nbsp;{" "}
                  <AxoLocal
                    key="Files"
                    entity="Searchattachmentsfiles"
                    defaultValue={"filer"}
                  />
                </MenuItem>
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={() => this.handleActiveState("MyProfile")}
                >
                  &nbsp;&nbsp; {UserInfoService.getDisplayName(userProfile)}
                </MenuItem>
                {selectedUser.id ? (
                  <MenuItem
                    className="axonavTab AxotabDropdownmenu"
                    onClick={() => this.handleActiveState("Profile")}
                  >
                    <span className="icon-fontello-target" /> &nbsp;&nbsp;{" "}
                    {UserInfoService.getDisplayName(selectedUser)}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon
                      onClick={this.onRemoveSelectedUser}
                      glyph="icon-fontello-cancel-alt"
                    />
                  </MenuItem>
                ) : null}
                <MenuItem
                  className="axonavTab AxotabDropdownmenu"
                  onClick={this.onAddPrivateConversation.bind(this, {
                    onGoing: true,
                  })}
                >
                  <span className="icon-fontello-plus-1" /> &nbsp;&nbsp;{" "}
                  <AxoLocal
                    entity="socialTabViewNavAddPrivate"
                    defaultValue={"Tilføj Rum"}
                  />
                </MenuItem>
              </DropdownButton>
            </Row>
          )}
          <Row>
            <Col xs={12} style={{ paddingTop: "2px" }}>
              {this.renderPrivateNavNodes(true)}
            </Col>
          </Row>
        </Grid>
      </Large>
    );
  };

  renderPrivateConvoMenuItems = () => {
    let { privateConversations } = this.props;

    return privateConversations.map((convo) => {
      return (
        <MenuItem
          style={{ paddingBottom: "10px" }}
          className="axonavTab"
          onClick={() => this.handleActiveState(this.getConvoKey(convo))}
        >
          {this.renderPrivateConvoButtonContent(convo)}
        </MenuItem>
      );
    });
  };

  renderPrivateNavNodes = (largeScreen) => {
    let { activeTab } = this.props;
    var number = this.props.privateConversations.length;
    var lexNavButtons = this.props.privateConversations.map((convo) => {
      return (
        <AxoNavButton
          btnWidth={number === 1 ? "50%" : null}
          height="40px"
          key={convo.id}
          ls={largeScreen}
          eventKey={this.getConvoKey(convo)}
          activeTab={activeTab}
          onClick={this.handleActiveState}
        >
          {this.renderPrivateConvoButtonContent(convo)}
        </AxoNavButton>
      );
    });
    var rows = [];
    var columnNo = largeScreen ? 6 : 2;
    for (var i = 0; i < lexNavButtons.length; i = i + columnNo) {
      rows.push(
        <Row key={i}>
          <Col xs={12} className="nopadding flexbutton">
            <div
              className="btn-group btn-group-justified"
              role="group"
              style={{ borderRadius: "0px", paddingTop: "0px" }}
            >
              {this.arrangeLexNavButtons(lexNavButtons, i, columnNo)}
            </div>
          </Col>
        </Row>
      );
    }
    return rows;
  };

  renderPrivateConvoButtonContent = (convo) => {
    var hasUnread = !!convo.socialPosts.find((p) => !p.read);
    var tabName = <span>{this.getPrivateTabName(convo)}&nbsp;&nbsp;</span>;

    return (
      <React.Fragment>
        <div
          style={{ display: "inline-block", width: "50%", textAlign: "right" }}
        >
          {/* <span className='icon-fontello-light-up'/> */}
          &nbsp;
          {hasUnread ? (
            <span>
              {tabName} &nbsp;
              <Icon
                style={{ fontSize: "10px", color: "#306C67" }}
                glyph="icon-fontello-circle"
              />
            </span>
          ) : (
            tabName
          )}
        </div>
        <div
          style={{ display: "inline-block", width: "50%", textAlign: "right" }}
        >
          {!!convo.locked ? (
            <span>
              <span style={{ fontSize: "" }} className="icon-fontello-lock-6" />
              &nbsp;&nbsp;&nbsp;
            </span>
          ) : (
            <span>
              <Icon
                role="button"
                onClick={(event) => this.onExitConvoButton(convo, event)}
                glyph="icon-fontello-cancel-alt"
              />
              &nbsp;&nbsp;&nbsp;
            </span>
          )}
        </div>
      </React.Fragment>
    );
  };

  arrangeLexNavButtons = (lexNavButtons, currentIndex, columnNo) => {
    var columns = [];
    for (
      var i = 0;
      i < columnNo && currentIndex + i < lexNavButtons.length;
      i++
    ) {
      columns.push(lexNavButtons[currentIndex + i]);
    }
    return columns;
  };

  expandTabs = () => {
    this.setState({ expanded: true });
  };

  renderSmallScreenNavigationBar = () => {
    let { expanded } = this.state;
    let newPublic = !!this.props.publicPosts.find((p) => !p.read);
    let newInternal = !!this.props.internalPosts.find((p) => !p.read);
    let userProfile = this.props.userProfile;
    let selectedUser = this.props.selectedUserProfile;
    return (
      <MediumOrSmaller>
        <Grid
          fluid
          className="axo-nav"
          id="noprint"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <Row style={{ paddingLeft: "20%", paddingTop: "5px" }}>
            <DropdownButton
              style={{ width: "100%" }}
              id="IT"
              noCaret
              title={
                <div style={{ display: "inline-block" }}>
                  <div className="flexbox-center">
                    <Icon
                      style={{ fontSize: "25px" }}
                      glyph="icon-fontello-align-justify"
                    />
                    &nbsp;&nbsp;
                    <AxoLocal
                      style={{ fontSize: "20px" }}
                      entity="Enhedspris33"
                      defaultValue={"Menu"}
                    />
                  </div>
                </div>
              }
            >
              <MenuItem
                style={{ paddingBottom: "10px" }}
                className="axonavTab"
                onClick={() => this.handleActiveState("Public")}
              >
                <span className="icon-fontello-cog-7" /> &nbsp;&nbsp;{" "}
                {this.getPublicLabel(newPublic)}
              </MenuItem>
              <MenuItem
                className="axonavTab"
                onClick={() => this.handleActiveState("Internal")}
              >
                <span className="icon-fontello-spin2" /> &nbsp;&nbsp;{" "}
                {this.getInternalLabel(newInternal)}
              </MenuItem>
              <MenuItem
                className="axonavTab"
                onClick={() => this.handleActiveState("Files")}
              >
                <span className="icon-fontello-doc-text" /> &nbsp;&nbsp;{" "}
                <AxoLocal
                  key="Files"
                  entity="Searchattachmentsfiles"
                  defaultValue={"filer"}
                />
              </MenuItem>
              <MenuItem
                className="axonavTab"
                onClick={() => this.handleActiveState("MyProfile")}
              >
                &nbsp;&nbsp; {UserInfoService.getDisplayName(userProfile)}
              </MenuItem>
              {selectedUser.id ? (
                <MenuItem
                  className="axonavTab"
                  onClick={() => this.handleActiveState("Profile")}
                >
                  <span className="icon-fontello-target" /> &nbsp;&nbsp;{" "}
                  {UserInfoService.getDisplayName(selectedUser)}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Icon
                    onClick={this.onRemoveSelectedUser}
                    glyph="icon-fontello-cancel-alt"
                  />
                </MenuItem>
              ) : null}
              <MenuItem
                className="axonavTab"
                onClick={this.onAddPrivateConversation.bind(this, {
                  onGoing: true,
                })}
              >
                <span className="icon-fontello-plus-1" /> &nbsp;&nbsp;{" "}
                <AxoLocal
                  entity="socialTabViewNavAddPrivate"
                  defaultValue={"Tilføj Rum"}
                />
              </MenuItem>
              {this.renderPrivateConvoMenuItems()}
            </DropdownButton>
          </Row>
          {expanded ? (
            <Row>
              <Col xs={12} style={{ paddingTop: "2px" }}>
                {this.renderPrivateNavNodes(false)}
              </Col>
            </Row>
          ) : null}
        </Grid>
      </MediumOrSmaller>
    );
  };

  renderNavigationBar = () => {
    return (
      <div>
        {this.renderLargeScreenNavigationBar()}
        {this.renderSmallScreenNavigationBar()}
      </div>
    );
  };

  renderContent = () => {
    let { activeTab } = this.props;
    var userProfile = this.props.userProfile;
    var selectedUser = this.props.selectedUserProfile;

    var publicPosts =
      this.props.publicPosts.length > 0
        ? this.props.publicPosts
        : this.makeInfoPost("/imgs/app/shots/Blick_auf_Manhattan.JPG");

    var internalPosts =
      this.props.internalPosts.length > 0
        ? this.props.internalPosts
        : this.makeInfoPost("/imgs/app/shots/Blick_auf_Manhattan.JPG");

    var currentUserPosts =
      this.props.currentUserPosts.length > 0
        ? this.props.currentUserPosts
        : this.makeInfoPost("/imgs/app/shots/Blick_auf_Manhattan.JPG");
    return (
      <div>
        <Conditional show={activeTab === "Public"}>
          {this.renderSocialView(userProfile, publicPosts, 0, false)}
        </Conditional>
        <Conditional show={activeTab === "Internal"}>
          {this.renderSocialView(
            userProfile,
            internalPosts,
            1,
            false,
            this.props.friends
          )}
        </Conditional>
        <Conditional show={activeTab === "Files"}>
          <div style={{ minHeight: "900px" }}>
            <SocialFilesView
              socialFiles={this.props.socialFiles}
              userProfile={this.props.userProfile}
              onDeleteSocialFile={this.props.onDeleteSocialFile}
            />
          </div>
        </Conditional>
        <Conditional show={activeTab === "MyProfile"}>
          {this.renderSocialView(userProfile, currentUserPosts, 3, true)}
        </Conditional>
        <Conditional show={activeTab === "Profile"}>
          {this.renderSocialView(
            selectedUser,
            this.props.individualPosts,
            3,
            true
          )}
        </Conditional>
        {this.renderPrivateConversationViews()}
      </div>
    );
  };

  renderPrivateConversationViews = () => {
    let { activeTab } = this.props;
    var userProfile = this.props.userProfile;

    return this.props.privateConversations.map((convo) => {
      return (
        <Conditional
          key={convo.id}
          show={activeTab === this.getConvoKey(convo)}
        >
          {this.renderSocialView(
            userProfile,
            convo.socialPosts,
            2,
            false,
            convo.conversationUsers,
            convo,
            this.props.userProfileArray
          )}
        </Conditional>
      );
    });
  };

  render() {
    return (
      <Grid fluid style={{ minHeight: "900px" }}>
        <Row>
          <Col xs={12}>
            {this.renderNavigationBar()}
            {this.renderContent()}
          </Col>
        </Row>
      </Grid>
    );
  }
}
