import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import DataStore from "../../services/DataAccess/DataStore";
import Invoice from "./Invoice";
import { debounce } from "lodash";
import TimeEntryOptionsStore from "../../services/TimeEntryOptionsStore";
import UserInfoService from "../../services/UserInfoService";
import { Table, Column, Cell } from "fixed-data-table-2";
import TimeEntryGridView from "./TimeEntryGridView";
import SortHeaderCell from "../../utilities/FixedDataTable/SortHeaderCell";
import DataListWrapper from "../../utilities/FixedDataTable/DataListWrapper";

import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";
import moment from "moment";
import TimeEntryService from "../../services/TimeEntryService";

import {
  Icon,
  AxoCheckbox,
  PaginationControls,
  ConfirmModal,
  AlertModal,
  FileViewerModal,
  TableBase,
  Dimensions,
  AxoLocal,
  InlineNumberEdit,
  InlineTextArea,
} from "../../utilities/LexUtilities";

import {
  Col,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";

const defaultColumnDefs = {
  select: {
    width: 50,
    shown: true,
  },
  ownerName: {
    width: 75,
    shown: true,
  },
  caseNumber: {
    width: 130,
    shown: true,
  },
  workDate: {
    width: 170,
    shown: true,
  },
  clientString: {
    width: 120,
    shown: true,
  },
  caseString: {
    width: 130,
    shown: true,
  },
  description: {
    width: 140,
    shown: true,
  },
  units: {
    width: 100,
    shown: true,
  },
  rate: {
    width: 110,
    shown: true,
  },
  tax: {
    width: 75,
    shown: true,
  },
  expenses: {
    width: 100,
    shown: true,
  },
  total: {
    width: 125,
    shown: true,
  },
  deposit: {
    width: 110,
    shown: true,
  },
  hasInvoice: {
    width: 60,
    shown: true,
  },
  actions: {
    width: 50,
    shown: true,
  },
};

class TimeEntriesView extends TableBase {
  constructor(props) {
    super(props);
    this.name = "TimeEntriesView";
    this.defaultColumnDefs = defaultColumnDefs;

    let tableEntries = this.addDataStringsToTimeEntries(props.timeEntries);
    let shownEntries = this.getShownEntries(
      tableEntries,
      props.selectedOptionId
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(shownEntries);
    this.state = {
      selectedEntries: [],
      shownEntries: [],
      tableEntries: tableEntries,
      showTimeEntries: false,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        workDate: 180,
      },
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        shownEntries
      ),
      colSortDirs: {},
      searchText: "",
      totalToShow: null,
    };
  }

  //Updates sort order when the data is updated
  componentWillReceiveProps(nextProps) {
    let tableEntries = this.addDataStringsToTimeEntries(nextProps.timeEntries);
    let shownEntries = this.getShownEntries(
      tableEntries,
      nextProps.selectedOptionId
    );
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);

    if (nextProps.selectedOptionId !== this.props.selectedOptionId) {
      //Reset the sort order when a new client is selected
      this.setState({
        sortedDataList: new DataListWrapper(
          this.generateDefaultSortIndexes(shownEntries),
          shownEntries
        ),
      });
    }
    TimeEntryOptionsStore.setTimeEntryOptions({
      casesWithEntries: nextProps.casesWithEntries,
      clientsWithEntries: this.props.clientsWithEntries,
      showCases: nextProps.showCases,
      selectedOptionId: nextProps.selectedOptionId,
      onSelectOption: this.onSelectOption,
    });
  }

  //Display strings aggregated from time entry data
  addDataStringsToTimeEntries = (sortedEntries) => {
    var { userProfile } = this.props;

    var newEntries = sortedEntries.map((timeEntry) => {
      timeEntry.clientString = "";
      if (timeEntry.client && timeEntry.client.id) {
        timeEntry.clientString = timeEntry.client.firstName;
      }
      timeEntry.caseString = "";
      if (timeEntry.caseModelId) {
        var { caseModel } = timeEntry;
        timeEntry.caseString =
          caseModel && caseModel.id ? caseModel.caseNumber : "";
        // if(caseModel.sharingUsers.length > 0 && caseModel.userName !== this.props.userProfile.userName) {
        //   timeEntry.readOnly = true;
        //   //timeEntry.ownerName = UserInfoService.getDisplayName(caseModel.owner);
        // }
      }
      if (timeEntry.userName !== userProfile.userName) {
        timeEntry.readOnly = true;
        timeEntry.ownerName = timeEntry.userName; //ToDo: Replace with full user name
      }
      return timeEntry;
    });
    return newEntries;
  };

  onSelectOption = (optionId) => {
    this.props.onSelectOption(optionId);
  };

  renderAccordionHeader = (title) => {
    return (
      <div>
        <Col xs={11} style={{ padding: "0px" }}>
          <span>{title}</span>
        </Col>
        <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
          <span style={{ textAlign: "right" }}>
            <Icon glyph="icon-fontello-arrow-combo" />
          </span>
        </Col>
      </div>
    );
  };

  onChangeWorkDate = debounce((timeEntry, dateTime) => {
    timeEntry.workDate = dateTime.format();
    DataActions.updateTimeEntry(timeEntry);
  }, 500);

  onChangeWorkDateHTML5 = (timeEntryId, event) => {
    var timeEntry = Object.assign(
      {},
      this.props.timeEntries.find((entry) => entry.id === timeEntryId)
    );
    timeEntry.workDate = moment(event.target.valueAsDate).format();
    DataActions.updateTimeEntry(timeEntry);
  };

  onDescChange = (timeEntryId, input) => {
    var timeEntry = Object.assign(
      {},
      this.props.timeEntries.find((entry) => entry.id === timeEntryId)
    );
    timeEntry.description = input.value;
    DataActions.updateTimeEntry(timeEntry);
  };

  onChangeRate = (timeEntryId, input) => {
    var timeEntry = Object.assign(
      {},
      this.props.timeEntries.find((entry) => entry.id === timeEntryId)
    );
    timeEntry.rate = input.value;
    DataActions.updateTimeEntry(timeEntry);
  };

  onChangeTax = (timeEntryId, input) => {
    var timeEntry = Object.assign(
      {},
      this.props.timeEntries.find((entry) => entry.id === timeEntryId)
    );
    var taxRate = parseInt(input.value, 10);
    timeEntry.tax = taxRate / 100;
    DataActions.updateTimeEntry(timeEntry);
  };

  onChangeExpenses = (timeEntryId, input) => {
    var timeEntry = Object.assign(
      {},
      this.props.timeEntries.find((entry) => entry.id === timeEntryId)
    );
    timeEntry.expenses = input.value;
    DataActions.updateTimeEntry(timeEntry);
  };

  onChangeAConto = (timeEntryId, input) => {
    var timeEntry = Object.assign(
      {},
      this.props.timeEntries.find((entry) => entry.id === timeEntryId)
    );
    timeEntry.aConto = input.value;
    DataActions.updateTimeEntry(timeEntry);
  };

  onChangeProperty = (timeEntryId, propertyName, value) => {
    var timeEntry = Object.assign(
      {},
      this.props.timeEntries.find((entry) => entry.id === timeEntryId)
    );
    timeEntry[propertyName] = value;
    DataActions.updateTimeEntry(timeEntry);
  };

  onTrashTimeEntry = (timeEntryId) => {
    var timeEntry = Object.assign(
      {},
      this.props.timeEntries.find((entry) => entry.id === timeEntryId)
    );
    timeEntry.trashed = true;
    DataActions.updateTimeEntry(timeEntry);
  };

  onTimeEntrySelected = (selectedEntryId, event) => {
    var select = event.target.checked;
    var selectedEntries = [...this.state.selectedEntries];
    if (select) {
      var selectedEntry = this.props.timeEntries.find(
        (entry) => entry.id === selectedEntryId
      );
      selectedEntries.push(selectedEntry);
    } else {
      selectedEntries = selectedEntries.filter(
        (entry) => entry.id !== selectedEntryId
      );
    }
    this.setState({ selectedEntries });
  };

  onCloseInvoice = () => {
    this.setState({ showInvoice: false });
    this.setState({ selectedEntries: [] });
  };

  getCaseEntries = (entries, selectedOptionId) => {
    if (selectedOptionId === -1) {
      return entries;
    }
    var caseModel = this.props.casesWithEntries.find(
      (c) => c.id === selectedOptionId
    );
    return this.getShownEntriesForCase(entries, caseModel);
  };

  getShownEntriesForCase = (entries, caseModel) => {
    if (!caseModel) {
      return entries.filter((t) => !t.caseModelId);
    }
    return entries.filter((t) => t.caseModelId === caseModel.id);
  };

  getClientEntries = (entries, selectedOptionId) => {
    if (selectedOptionId === -1) {
      return entries;
    }
    var client = this.props.clientsWithEntries.find(
      (cl) => cl.id === selectedOptionId
    );
    return this.getShownEntriesForClient(entries, client);
  };

  getShownEntriesForClient = (entries, client) => {
    if (!client) {
      return entries.filter((t) => !t.client || !t.client.id);
    }
    return entries.filter((t) => t.client && t.client.id === client.id);
  };

  getShownEntries = (entries, selectedOptionId) => {
    if (this.props.showCases) {
      return this.getCaseEntries(entries, selectedOptionId);
    } else {
      return this.getClientEntries(entries, selectedOptionId);
    }
  };

  componentDidMount() {
    TimeEntryOptionsStore.setTimeEntryOptions({
      casesWithEntries: this.props.casesWithEntries,
      clientsWithEntries: this.props.clientsWithEntries,
      showCases: this.props.showCases,
      selectedOptionId: this.props.selectedOptionId,
      onSelectOption: this.onSelectOption,
    });
  }

  onCreateInvoice = (newInvoice) => {
    this.props.onCreateInvoice(newInvoice);
  };

  onSearch = (event) => {
    var entries = this.getFilteredEntries(
      this.state.tableEntries,
      event.target.value
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    var shownEntries = this.getShownEntries(
      entries,
      this.props.selectedOptionId
    );
    if (!searchText) {
      return shownEntries;
    }
    return shownEntries.filter((t) => {
      var entryString = "";
      if (t.client) {
        entryString += t.client.firstName + " " + t.client.lastName + " ";
      }
      if (t.caseModelId) {
        entryString += t.caseString;
      }
      return entryString.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  _rowClassNameGetter = (rowIndex) => {
    var activeTimeEntry = this.state.sortedDataList.getObjectAt(rowIndex);
    if (
      activeTimeEntry &&
      this.props.newTimeEntryIds.includes(activeTimeEntry.id)
    ) {
      return "highlight-row";
    }
  };

  renderClientColumn = () => {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    return (
      <Column
        columnKey="clientString"
        header={
          <SortHeaderCell
            altIcon={<Icon glyph="icon-fontello-user" />}
            showAltIcon={columnDefs.clientString.width < 75}
            onSortChange={this._onSortChange}
            sortDir={colSortDirs.clientString}
          >
            <Icon className="editable" glyph="icon-fontello-arrow-combo" />
            &nbsp;
            <AxoLocal entity="CaseEditFormclients" defaultValue={"Klient"} />
          </SortHeaderCell>
        }
        cell={(props) => {
          var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
          return (
            <Cell key={timeEntry.id} {...props}>
              {timeEntry.clientString}
            </Cell>
          );
        }}
        width={columnDefs.clientString.width}
        isResizable={true}
      />
    );
  };

  renderCaseColumn = () => {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    return (
      <Column
        columnKey="caseString"
        header={
          <SortHeaderCell
            altIcon={<Icon glyph="icon-fontello-barcode-1" />}
            showAltIcon={columnDefs.caseString.width < 75}
            onSortChange={this._onSortChange}
            sortDir={colSortDirs.caseString}
          >
            <Icon className="editable" glyph="icon-fontello-arrow-combo" />
            &nbsp;
            <AxoLocal
              entity="ContainercaseNumber"
              defaultValue={"Sagsnummer"}
            />
          </SortHeaderCell>
        }
        cell={(props) => {
          var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
          return (
            <Cell key={timeEntry.id} {...props}>
              {timeEntry.caseString}
            </Cell>
          );
        }}
        width={columnDefs.caseString.width}
        isResizable={true}
      />
    );
  };

  onShowAttachment = (attachment) => {
    this.fileModal.open([attachment]);
  };

  onShowTotal = () => {
    var totalAmount = this.state.sortedDataList._data.reduce((sum, entry) => {
      return sum + TimeEntryService.getTotalValueWithoutAConto(entry);
    }, 0);
    this.setState({ totalToShow: totalAmount });
    setTimeout(() => {
      this.setState({ totalToShow: null });
    }, 10000);
  };

  formatTime = (time) => {
    var hours = Math.floor(time / 3600);
    var minutes = time - hours * 3600;
    var hourString = hours.toString();
    var minuteString = minutes.toString();
    if (hours < 10) {
      hourString = "0" + hourString;
    }
    if (minutes < 10) {
      minuteString = "0" + minuteString;
    }
    return hourString + ":" + minuteString;
  };

  renderTimeEntriesTable = () => {
    if (this.state.showInvoice) {
      return (
        <Invoice
          timeEntries={this.state.selectedEntries}
          contactMap={this.props.contactMap}
          userProfile={this.props.userProfile}
          renderButtons={true}
          onClose={this.onCloseInvoice}
          onCreate={this.onCreateInvoice}
        />
      );
    }
    var { sortedDataList } = this.state;
    const { containerWidth, forceShowCards, timeEntryMap } = this.props;
    var tableWidth = containerWidth - 25;
    if (tableWidth <= 0) {
      tableWidth = 500;
    }
    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <div
              className="flexbox-layout"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              <div>
                <div className="flexbox-standard">
                  <h4>
                    <Icon glyph="icon-fontello-hourglass" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="TimeEntriesTabViewTimeRecordingHeader"
                      defaultValue={"Tidsregistrering"}
                    />
                  </h4>
                  <PaginationControls
                    displayLength={sortedDataList._data.length}
                    length={timeEntryMap.timeEntries.length}
                    count={timeEntryMap.count}
                    onShowMore={() => DataStore.increaseTimeEntryPageSize()}
                  />
                </div>
              </div>
              <div>
                <div style={{ maxWidth: "250px", paddingTop: "10px" }}>
                  <FormGroup controlId="inputWithIcon">
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon
                          className="axoblack"
                          glyph="icon-fontello-search"
                        />
                      </InputGroup.Addon>
                      {/* Søg...  */}
                      <AxoLocal
                        componentClass={FormControl}
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                        componentAttribute="placeholder"
                        entity="ContactTableViewplaceholderonSearch"
                      />
                      <FormControl.Feedback>
                        <Icon glyph="icon-fontello-search" />
                      </FormControl.Feedback>
                    </InputGroup>
                  </FormGroup>
                </div>
              </div>
              <div>
                {this.state.totalToShow !== null ? (
                  <div>
                    <h4>
                      {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                      {this.state.totalToShow.toFixed(2)}
                    </h4>
                  </div>
                ) : (
                  <div style={{ paddingTop: "10px" }}>
                    <Button onClick={this.onShowTotal} className="Lex-button-2">
                      <AxoLocal
                        entity="timeEntrydefaultValueexchangeonShowTotal"
                        defaultValue={"Vis total"}
                      />
                    </Button>
                  </div>
                )}
              </div>
              <div
                className="hidden-xs text-right"
                style={{ paddingTop: "10px" }}
              >
                <Button
                  className="Lex-button-2"
                  onClick={this.props.onToggleCards}
                >
                  {this.props.forceShowCards ? (
                    <span>
                      {" "}
                      <Icon glyph="icon-fontello-grid" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        key="showTable"
                        entity="CaseDataTableFixedShowastable"
                        defaultValue={"Vis som tabel"}
                      />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon glyph="icon-fontello-th-large-outline" />
                      &nbsp;&nbsp;
                      <AxoLocal
                        key="showCards"
                        entity="CaseDataTableFixedShowCards"
                        defaultValue={"Vis som kartotekskort"}
                      />{" "}
                    </span>
                  )}
                </Button>
              </div>
            </div>
          </div>
          {forceShowCards ? (
            this.renderGridView()
          ) : (
            <div>
              <ExtraSmall>{this.renderGridView()}</ExtraSmall>
              <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
            </div>
          )}
        </div>
        <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
      </div>
    );
  };

  renderGridView = () => {
    return (
      <div style={{ paddingTop: "0px" }}>
        <TimeEntryGridView
          timeEntries={this.state.sortedDataList._data}
          userProfile={this.props.userProfile}
          newTimeEntryIds={this.props.newTimeEntryIds}
          onTimeEntrySelected={this.onTimeEntrySelected}
          onChangeWorkDate={this.onChangeWorkDateHTML5}
          onDescChange={this.onDescChange}
          onChangeProperty={this.onChangeProperty}
          onChangeRate={this.onChangeRate}
          onChangeTax={this.onChangeTax}
          onChangeExpenses={this.onChangeExpenses}
          onChangeAConto={this.onChangeAConto}
        />
      </div>
    );
  };

  renderTable = () => {
    var { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth } = this.props;
    var tableWidth = containerWidth - 25;
    if (tableWidth <= 0) {
      tableWidth = 500;
    }
    var showOwnerColumn = !!sortedDataList._data.find(
      (timeEntry) => timeEntry.ownerName
    );

    return (
      <div style={{ paddingTop: "0px", paddingLeft: "5px" }}>
        <Table
          rowHeight={40}
          rowsCount={sortedDataList.getSize()}
          rowClassNameGetter={this._rowClassNameGetter}
          height={containerHeight}
          width={tableWidth}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          headerHeight={40}
        >
          <Column
            columnKey="select"
            header={
              <Cell>
                <AxoLocal entity="Documentselect" defaultValue={"Valgt"} />
              </Cell>
            }
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} className="text-center" {...props}>
                  <AxoCheckbox
                    onChange={this.onTimeEntrySelected.bind(this, timeEntry.id)}
                  />
                </Cell>
              );
            }}
            width={columnDefs.select.width}
            isResizable={true}
          />
          {showOwnerColumn ? (
            <Column
              columnKey="ownerName"
              header={
                <SortHeaderCell
                  altIcon={<Icon glyph="icon-fontello-calendar-6" />}
                  showAltIcon={columnDefs.ownerName.width < 75}
                  onSortChange={this._onSortChange}
                  sortDir={colSortDirs.ownerName}
                >
                  <Icon
                    className="editable"
                    glyph="icon-fontello-arrow-combo"
                  />
                  &nbsp;
                  <AxoLocal
                    entity="CaseEditInlinecaseowner"
                    defaultValue={"Ejer"}
                  />
                </SortHeaderCell>
              }
              cell={(props) => {
                var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={timeEntry.id} {...props}>
                    {timeEntry.ownerName}
                  </Cell>
                );
              }}
              width={columnDefs.ownerName.width}
              isResizable={true}
            />
          ) : null}
          {this.props.showCases
            ? this.renderCaseColumn()
            : this.renderClientColumn()}
          {this.props.showCases
            ? this.renderClientColumn()
            : this.renderCaseColumn()}
          <Column
            columnKey="workDate"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-calendar-6" />}
                showAltIcon={columnDefs.workDate.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.workDate}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="axoidcode25" defaultValue={"Dato"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {timeEntry.readOnly ? (
                    <span>{moment(timeEntry.workDate).format("L")}</span>
                  ) : (
                    <input
                      type="date"
                      style={{ border: "none" }}
                      onChange={this.onChangeWorkDateHTML5.bind(
                        this,
                        timeEntry.id
                      )}
                      value={new moment(timeEntry.workDate).format(
                        "YYYY-MM-DD"
                      )}
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.workDate.width}
            isResizable={true}
          />
          <Column
            columnKey="description"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-edit-2" />}
                showAltIcon={columnDefs.description.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.description}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {timeEntry.readOnly ? (
                    <div>{timeEntry.description}</div>
                  ) : (
                    <InlineTextArea
                      value={timeEntry.description || "---"}
                      change={this.onDescChange.bind(this, timeEntry.id)}
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.description.width}
            isResizable={true}
          />
          <Column
            columnKey="units"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-clock" />}
                showAltIcon={columnDefs.units.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.units}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal entity="InvoiceInvoiceunit" defaultValue={"Antal"} />
              </SortHeaderCell>
            }
            cell={(props) => {
              let timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {timeEntry.readOnly ? (
                    <div>
                      {timeEntry.units > 0 ? (
                        <div>
                          {timeEntry.units}{" "}
                          {TimeEntryService.getUnitTypeName(timeEntry.unitType)}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : timeEntry.units > 0 ? (
                    <InlineNumberEdit
                      value={timeEntry.units}
                      format={(value) => {
                        return (
                          <div>
                            {value}{" "}
                            {TimeEntryService.getUnitTypeName(
                              timeEntry.unitType
                            )}
                          </div>
                        );
                      }}
                      change={(input) => {
                        if (input.value === "0") {
                          return;
                        }

                        this.onChangeProperty(
                          timeEntry.id,
                          "units",
                          input.value
                        );
                      }}
                    />
                  ) : null}
                </Cell>
              );
            }}
            width={columnDefs.units.width}
            isResizable={true}
          />
          <Column
            columnKey="rate"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-money-2" />}
                showAltIcon={columnDefs.rate.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.rate}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="InvoiceInvoicePrisunit"
                  defaultValue={"Pris/enhed"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {timeEntry.readOnly && !!timeEntry.units ? (
                    <div>
                      {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                      {timeEntry.rate.toString()}
                    </div>
                  ) : !!timeEntry.units ? (
                    <span>
                      <InlineNumberEdit
                        value={timeEntry.rate || 0}
                        format={(value) => {
                          return (
                            <span>
                              {UserInfoService.getCurrency(
                                this.props.userProfile
                              )}{" "}
                              {value.toString()}
                            </span>
                          );
                        }}
                        change={this.onChangeRate.bind(this, timeEntry.id)}
                      />
                    </span>
                  ) : null}
                </Cell>
              );
            }}
            width={columnDefs.rate.width}
            isResizable={true}
          />
          <Column
            columnKey="tax"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-chart-pie-1" />}
                showAltIcon={columnDefs.tax.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.tax}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="invoiPaymentattheSubtotalVAT"
                  defaultValue={"Moms"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {timeEntry.units > 0 ? (
                    timeEntry.readOnly ? (
                      <div>{timeEntry.tax * 100}</div>
                    ) : (
                      <span>
                        <InlineNumberEdit
                          value={timeEntry.tax * 100}
                          format={(value) => {
                            return value.toString();
                          }}
                          change={this.onChangeTax.bind(this, timeEntry.id)}
                        />{" "}
                        %
                      </span>
                    )
                  ) : null}
                </Cell>
              );
            }}
            width={columnDefs.tax.width}
            isResizable={true}
          />
          <Column
            columnKey="expenses"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-chart-pie-1" />}
                showAltIcon={columnDefs.expenses.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.expenses}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="TimeEntriesViewExpensesLabel"
                  defaultValue={"Udgifter"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {timeEntry.expenses > 0 ? (
                    <span>
                      {timeEntry.readOnly ? (
                        <span>
                          {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                          {timeEntry.expenses.toString()}
                        </span>
                      ) : (
                        <InlineNumberEdit
                          value={timeEntry.expenses}
                          format={(value) => {
                            return (
                              <span>
                                {UserInfoService.getCurrency(
                                  this.props.userProfile
                                )}{" "}
                                {value.toString()}
                              </span>
                            );
                          }}
                          change={(input) => {
                            if (input.value === "0") {
                              return;
                            }

                            this.onChangeExpenses(timeEntry.id, input);
                          }}
                        />
                      )}
                      &nbsp;
                      {timeEntry.expenseAttachment ? (
                        <Icon
                          className="editable"
                          role="button"
                          onClick={this.onShowAttachment.bind(
                            this,
                            timeEntry.expenseAttachment
                          )}
                          glyph="icon-fontello-attach"
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  ) : null}
                </Cell>
              );
            }}
            width={columnDefs.expenses.width}
            isResizable={true}
          />
          <Column
            columnKey="total"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-money-1" />}
                showAltIcon={columnDefs.total.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.total}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="TimeEntriesViewValue"
                  defaultValue={"Værdi"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                  {TimeEntryService.getTotalValueWithoutAConto(
                    timeEntry
                  ).toFixed(2)}
                </Cell>
              );
            }}
            width={columnDefs.total.width}
            isResizable={true}
          />
          <Column
            columnKey="deposit"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-list-2" />}
                showAltIcon={columnDefs.deposit.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.deposit}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="InvoiceInvoiceAConto"
                  defaultValue={"A Conto"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {timeEntry.readOnly ? (
                    <div>
                      {UserInfoService.getCurrency(this.props.userProfile)}{" "}
                      {timeEntry.aConto.toString()}
                    </div>
                  ) : (
                    <InlineNumberEdit
                      value={timeEntry.aConto}
                      format={(value) => {
                        return (
                          <span>
                            {UserInfoService.getCurrency(
                              this.props.userProfile
                            )}{" "}
                            {value.toString()}
                          </span>
                        );
                      }}
                      change={this.onChangeAConto.bind(this, timeEntry.id)}
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.deposit.width}
            isResizable={true}
          />
          <Column
            columnKey="hasInvoice"
            header={
              <SortHeaderCell
                altIcon={<Icon glyph="icon-fontello-circle-empty" />}
                showAltIcon={columnDefs.hasInvoice.width < 75}
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.hasInvoice}
              >
                <Icon className="editable" glyph="icon-fontello-arrow-combo" />
                &nbsp;
                <AxoLocal
                  entity="TimeEntriesViewbilled"
                  defaultValue={"Faktureret"}
                />
              </SortHeaderCell>
            }
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {timeEntry.invoiceId ? (
                    <AxoLocal
                      key="Ja"
                      entity="ConfirmModalonOK"
                      defaultValue={"Ja"}
                    />
                  ) : (
                    <AxoLocal
                      key="Nej"
                      entity="ConfirmModalCancel"
                      defaultValue={"Nej"}
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.hasInvoice.width}
            isResizable={true}
          />
          <Column
            columnKey="actions"
            header={<Cell></Cell>}
            cell={(props) => {
              var timeEntry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={timeEntry.id} {...props}>
                  {timeEntry.readOnly ? null : (
                    <Icon
                      className="editable"
                      role="button"
                      onClick={this.onTrashTimeEntry.bind(this, timeEntry.id)}
                      glyph="icon-fontello-trash-1"
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.actions.width}
            isResizable={true}
          />
        </Table>
      </div>
    );
  };

  renderInvoiceButton = () => {
    if (this.state.showInvoice) {
      return null;
    }
    var enabled = this.state.selectedEntries.length > 0;
    return (
      <Button
        style={{
          fontSize: "19px",
          color: enabled ? "#ffffff" : "#bfbfbf",
          background: enabled ? "#4CAF50" : "#ececec",
          fontWeight: "normal",
          height: "40px",
        }}
        block
        disabled={!enabled}
        onClick={this.onGenerateInvoice}
      >
        <AxoLocal
          entity="TimeEntryGenerateInvoice"
          defaultValue={"Generer faktura"}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Icon glyph="icon-simple-line-icons-settings" />
      </Button>
    );
  };

  extractInvoiceIds = (invoices) => {
    var invoiceIds = new Set();
    invoices.forEach((entry) => {
      if (entry.invoiceId) {
        invoiceIds.add(entry.invoiceId);
      }
    });
    return invoiceIds;
  };

  onGenerateInvoice = () => {
    var invoicedSelectedEntries = this.state.selectedEntries.filter(
      (e) => e.invoiceId > 0
    );
    if (invoicedSelectedEntries.length > 0) {
      this.confirmModal.open(
        <AxoLocal
          entity="ContactTableViewselectedEntrieslength"
          defaultValue={
            "Tidsregistreringen er allerede faktureret.Ønsker du at slette den eksisterende faktura og generere en ny?"
          }
        />,
        (reply) => {
          if (reply) {
            var invoiceIds = this.extractInvoiceIds(invoicedSelectedEntries);
            var promises = [];
            invoiceIds.forEach((invoiceId) => {
              promises.push(DataActions.deleteInvoice(invoiceId));
            });
            Promise.all(promises)
              .then((results) => {
                var failure = results.find((result) => result.status !== 200);
                if (failure) {
                  this.alertModal.open(
                    <AxoLocal
                      entity="ContactTableViewdeleteInvoicestatus"
                      defaultValue={
                        "Fakturaen kunne ikke slettes. Prøv igen senere."
                      }
                    />
                  );
                } else {
                  this.setState({ showInvoice: true });
                }
              })
              .catch((errors) => {
                this.alertModal.open(
                  <AxoLocal
                    entity="ContactTableViewTherewaanerrorserver"
                    defaultValue={
                      "Der skete en fejl på serveren. Prøv igen senere."
                    }
                  />
                );
              });
          }
        }
      );
    } else {
      this.setState({ showInvoice: true });
    }
  };

  render() {
    return (
      <div style={{ paddingTop: "20px" }}>
        {this.renderInvoiceButton()}
        {this.renderTimeEntriesTable()}
        <ConfirmModal size="large" ref={(c) => (this.confirmModal = c)} />
        <AlertModal size="large" ref={(c) => (this.alertModal = c)} />
      </div>
    );
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 260;
  },
})(TimeEntriesView);
