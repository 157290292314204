// @ts-check
import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import SimpleTinyMCEEditor from "./../../utilities/Editors/SimpleTinyMCEEditor";
import ModalService from "../../services/ModalService";
import * as $ from "jquery";
import ImageFooter from "./ImageFooter";
import AttachmentFooter from "./AttachmentFooter";
import {
  LexButton,
  DocumentSearchBox,
  ImageSearchBox,
} from "../../utilities/LexUtilities";

import { Row, Col, Grid, InputGroup, Button } from "react-bootstrap";
import {
  Icon,
  FileViewerModal,
  AlertModal,
  AxoLocal,
} from "../../utilities/LexUtilities";

export default class InputBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      socialPost: {
        content: "",
      },
      editing: false,
      updating: false,
    };
    this.editor = {};
  }
  //Props
  //type
  //userProfile
  onCreatePost = () => {
    var socialPost = Object.assign({}, this.state.socialPost);
    this.props.onCreatePost(socialPost).then((response) => {
      if (response.status === 200) {
        this.setState({
          socialPost: {
            content: "",
          },
          editing: false,
        });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="socialInputBoxNavRejectPost"
            defaultValue={"Indlægget kunne ikke oprettes. Prøv igen senere."}
          />
        );
      }
    });
  };

  onCancelPost = () => {
    this.setState({
      socialPost: { content: "" },
      editing: false,
    });
  };

  onTextChange = (event) => {
    var socialPost = Object.assign({}, this.state.socialPost);
    socialPost.content = event.target.value;
    this.setState({ socialPost: socialPost });
  };

  handleSelectedImage = (image) => {
    var socialPost = Object.assign({}, this.state.socialPost);
    this.deleteExistingSocialAttachments(socialPost);
    socialPost.image = image;
    socialPost.attachment = null;
    this.setState({ socialPost: socialPost });
    // var htmlString = '<p><img alt=''  src="' + ApiService.getFileInlinePath(image.id) + '" alt="' + image.fileName + '" /></p>'
    // this.editor.insertContent(htmlString);
  };

  handleSelectedDocument = (document) => {
    var socialPost = Object.assign({}, this.state.socialPost);
    this.deleteExistingSocialAttachments(socialPost);
    socialPost.attachment = document;
    socialPost.image = null;
    this.setState({ socialPost: socialPost });
    // ApiService.getDocXAsHTML(document.id).then( documentHtml => {
    //   this.editor.setContent(documentHtml);
    // })
  };

  deleteExistingSocialAttachments = (socialPost) => {
    if (socialPost.attachment && socialPost.attachment.sectionFileType === 1) {
      //Social type
      DataActions.deleteDocument(socialPost.attachment.id);
    }
    if (socialPost.image && socialPost.image.sectionFileType === 1) {
      //Social type
      DataActions.deleteDocument(socialPost.image.id);
    }
  };

  setupEditor = (editor) => {
    this.editor = editor;
  };

  onExternalImage = () => {
    $("#uploadImage").trigger("click");
  };

  onExternalFile = () => {
    $("#uploadAttachment").trigger("click");
  };

  onFileChosen = (event) => {
    var fileSelect = event.target;
    if (fileSelect.files.length === 0) {
      return;
    }
    this.uploadExternalFile(fileSelect.files, this.handleSelectedDocument);
    fileSelect.value = "";
  };

  onImageChosen = (event) => {
    var fileSelect = event.target;
    if (fileSelect.files.length === 0) {
      return;
    }
    if (!fileSelect.files[0].type.includes("image")) {
      this.alertModal.open(
        <AxoLocal
          entity="socialInputBoxNavPickImageFile"
          defaultValue={"Vælg venligst en billedfil"}
        />
      );
      return;
    }
    this.uploadExternalFile(fileSelect.files, this.handleSelectedImage);
    fileSelect.value = "";
  };

  uploadExternalFile = (files, fileHandlerCallback) => {
    DataActions.uploadDocuments(files, { isSocial: true })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(new Error("Upload failed"));
      })
      .then((fileModels) => {
        var fileModel = fileModels[0];
        fileHandlerCallback(fileModel);
      })
      .catch((reason) => {
        console.error(reason);
      });
  };

  onTextChangeMCE = (content) => {
    var socialPost = Object.assign({}, this.state.socialPost);
    socialPost.content = content;
    this.setState({ socialPost: socialPost });
  };

  onShowFile = (document) => {
    this.fileModal.open([document]);
  };

  onRemoveAttachment = () => {
    var socialPost = Object.assign({}, this.state.socialPost);
    if (socialPost.attachment && socialPost.attachment.sectionFileType === 1) {
      DataActions.deleteDocument(socialPost.attachment.id);
    }
    socialPost.attachment = null;
    this.setState({ socialPost: socialPost });
  };

  onRemoveImage = () => {
    var socialPost = Object.assign({}, this.state.socialPost);
    if (socialPost.image && socialPost.image.sectionFileType === 1) {
      DataActions.deleteDocument(socialPost.image.id);
    }
    socialPost.image = null;
    this.setState({ socialPost: socialPost });
  };

  onUpdate = () => {
    this.setState({ updating: true });
    this.props.onUpdate().then((responses) => {
      this.setState({ updating: false });
    });
  };

  render() {
    var { updating, editing } = this.state;

    return (
      <div>
        {editing ? (
          <div>
            <div className="Axolike">
              <Grid fluid>
                <Row>
                  <Col lg={3} className="nopadding">
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-picture-1" />
                      </InputGroup.Addon>
                      <AxoLocal
                        componentClass={ImageSearchBox}
                        documentMap={this.props.documentMap}
                        handleSelectedImage={this.handleSelectedImage}
                        componentAttribute="placeholder"
                        entity="TinyMCEEditormyImage"
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={3} className="nopadding">
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-doc-new" />
                      </InputGroup.Addon>
                      <AxoLocal
                        componentClass={DocumentSearchBox}
                        documentMap={this.props.documentMap}
                        handleSelectedDocument={this.handleSelectedDocument}
                        componentAttribute="placeholder"
                        entity="placeholderhandleSelectedDocumentAttachinternalfile"
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={6} className="text-right nopadding">
                    <Button
                      className="Lex-button-2"
                      onClick={this.onExternalImage}
                    >
                      <AxoLocal
                        entity="socialInputBoxNavExternalImage"
                        defaultValue={"Eksternt billede"}
                      />{" "}
                      &nbsp;&ensp;{" "}
                      <Icon role="button" glyph="icon-fontello-camera-7" />
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="Lex-button-2"
                      onClick={this.onExternalFile}
                    >
                      <AxoLocal
                        entity="socialInputBoxNavExternalFile"
                        defaultValue={"Ekstern fil"}
                      />
                      &nbsp;&ensp;{" "}
                      <Icon role="button" glyph="icon-fontello-doc-text" />
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="Lex-button-2"
                      onClick={this.onCancelPost}
                    >
                      <Icon role="button" glyph="icon-fontello-cancel" />
                      &nbsp;&ensp;{" "}
                      <AxoLocal
                        entity="socialInputBoxNavCancel"
                        defaultValue={"Annuller"}
                      />
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="Lex-button-2"
                      onClick={this.onCreatePost}
                    >
                      <Icon glyph="icon-fontello-forward-1" />
                      &nbsp;&ensp;
                      <AxoLocal
                        entity="socialInputBoxNavSubmit"
                        defaultValue={"Slå op"}
                      />
                    </Button>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div style={{ padding: 0 }}>
              <SimpleTinyMCEEditor
                text={this.state.socialPost.content}
                locale={this.props.locale}
                height={250}
                onTextChange={this.onTextChangeMCE}
                setupEditor={this.setupEditor}
              />
              <ImageFooter
                image={this.state.socialPost.image}
                onRemoveImage={this.onRemoveImage}
              />
              <AttachmentFooter
                attachment={this.state.socialPost.attachment}
                onClickFile={this.onShowFile}
                onRemoveAttachment={this.onRemoveAttachment}
              />
              <input
                onChange={this.onFileChosen}
                name="attachment"
                type="file"
                id="uploadAttachment"
                style={{ display: "none" }}
              />
              <input
                onChange={this.onImageChosen}
                name="image"
                type="file"
                id="uploadImage"
                style={{ display: "none" }}
              />
            </div>
            <div
              className="axolikeBanne"
              style={{ marginTop: "0px", padding: "0px 25px" }}
            >
              <Grid fluid>
                <Row>
                  <Col sm={5} className="nopadding">
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-picture-1" />
                      </InputGroup.Addon>
                      <AxoLocal
                        componentClass={ImageSearchBox}
                        documentMap={this.props.documentMap}
                        handleSelectedImage={this.handleSelectedImage}
                        componentAttribute="placeholder"
                        entity="TinyMCEEditormyImage"
                      />
                    </InputGroup>
                  </Col>
                  <Col sm={7} className="sm-right nopadding">
                    <Button onClick={this.onUpdate} className="Lex-button-2">
                      <Icon glyph="icon-simple-line-icons-reload" />
                      &nbsp;{" "}
                      <AxoLocal
                        entity="ResetPasswordRefresh"
                        defaultValue={"Opdater"}
                      />
                      {updating ? (
                        <span>
                          &nbsp;
                          <img
                            alt=""
                            src="/imgs/app/loading.gif"
                            className="img-circle"
                            width="20"
                            height="20"
                          />
                        </span>
                      ) : null}
                    </Button>
                    &nbsp;
                    <Button
                      className="Lex-button-2"
                      onClick={this.onCancelPost}
                    >
                      <Icon glyph="icon-fontello-cancel" />
                      &nbsp;
                      <AxoLocal
                        entity="socialInputBoxNavCancel"
                        defaultValue={"Annuller"}
                      />
                    </Button>
                    &ensp;
                    <Button
                      className="Lex-button-2"
                      onClick={this.onCreatePost}
                    >
                      <Icon glyph="icon-fontello-forward-1" />
                      &nbsp;
                      <AxoLocal
                        entity="socialInputBoxNavSubmit"
                        defaultValue={"Slå op"}
                      />
                    </Button>
                  </Col>
                </Row>
              </Grid>
            </div>
            <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
            <AlertModal ref={(c) => (this.alertModal = c)} />
          </div>
        ) : (
          <div className="text-center">
            <LexButton
              style={{ width: "90%", marginBottom: "7px" }}
              onClick={() => this.setState({ editing: true })}
            >
              <AxoLocal entity="Enhedspris17" defaultValue={"Skriv indlæg"} />
            </LexButton>
          </div>
        )}
      </div>
    );
  }
}
