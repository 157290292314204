import React from "react";

export default function TestPage() {
  return (
    <div>
      <iframe
        title="axoframe"
        src="https://outlook.com/"
        // onLoad={this.onLoadFrame}
        className="seamless"
        sandbox="allow-same-origin allow-scripts allow-popups allow-top-navigation allow-forms"
        height="500px"
        // ref={ c => this.frame = c }
      />
    </div>
  );
}
