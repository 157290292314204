import React from "react";
import {
  AxoLocal,
  LexButton,
  // LexButton
} from "../utilities/LexUtilities";
import { RoutingService, ApiService } from "../services/AxoServices";

const signalR = require("@aspnet/signalr/dist/browser/signalr.js");

export default class ScreenSharingWatch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      sharingEnabled: false,
      sharingInProgress: false,
    };
    this.RTCConnection = null;
    this.hubConnection = null;
    this.screenSharingStream = null;
    this.iceCandidateList = []; //ICE candidates must be cached if the remote connection has not yet been set
  }

  componentDidMount() {
    window.onbeforeunload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to exit?";
    };

    let hubRoute = RoutingService.getHostPath() + "/webrtchub";
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubRoute)
      .build();

    this.hubConnection.on("ReceiveOffer", this.handleOffer);
    this.hubConnection.on("ReceiveCandidate", (candidate) => {
      if (
        !this.RTCConnection ||
        !this.RTCConnection.remoteDescription ||
        !this.RTCConnection.remoteDescription.type
      ) {
        //Cache the candidate if the remote connection has not yet been set
        console.log("Caching ICE candidate.");
        this.iceCandidateList.push(candidate);
      } else {
        console.log("Adding ICE candidate.");
        this.RTCConnection.addIceCandidate(
          new RTCIceCandidate(JSON.parse(candidate))
        );
      }
    });

    this.hubConnection.start().catch((err) => console.error(err.toString()));
  }

  handleOffer = async (offer) => {
    try {
      console.log("Receiving offer");

      let credentials = await ApiService.getTurnCredentials();
      console.log(credentials);
      console.log(credentials.ice_servers);
      this.RTCConnection = new RTCPeerConnection({
        iceServers: credentials.ice_servers,
      });
      this.RTCConnection.onicecandidate = (event) => {
        if (event.candidate) {
          console.log("Sending ICE candidate.");
          this.hubConnection
            .invoke("SendCandidate", JSON.stringify(event.candidate))
            .catch((reason) => {
              console.error(reason);
            });
        }
      };

      this.RTCConnection.ontrack = (event) => {
        this.setState(
          {
            sharingInProgress: true,
          },
          () => {
            console.log("Receiving video stream.");
            console.log("Streams: " + event.streams.length);
            this.screenSharingStream = event.streams[0];

            this.videoStreamPlayer.onerror = (evt) => {
              console.log("Error playing video.");
              console.log(evt.target.error);
            };

            // this.videoStreamPlayer.onloadeddata = evt => {
            //   console.log('Starting video player.');
            //   let playPromise = this.videoStreamPlayer.play();
            //   if(!!playPromise) {
            //     playPromise.catch((error) => {
            //       console.log(error.message);
            //       setTimeout(() => {
            //         this.videoStreamPlayer.play();
            //       }, 1000);
            //     })
            //   }

            //   this.forceUpdate();
            // }

            this.videoStreamPlayer.srcObject = event.streams[0];

            console.log("Starting video player.");
            this.videoStreamPlayer.play();
          }
        );
      };

      let sessionDesc = new RTCSessionDescription({
        type: "offer",
        sdp: offer,
      });
      await this.RTCConnection.setRemoteDescription(sessionDesc);

      this.iceCandidateList.forEach((candidate) => {
        console.log("Adding cached ICE candidate.");
        this.RTCConnection.addIceCandidate(
          new RTCIceCandidate(JSON.parse(candidate))
        );
      });
      this.iceCandidateList = [];

      this.sendAnswer();
    } catch (reason) {
      console.log(reason);
    }
  };

  sendAnswer = async () => {
    console.log("Sending answer");
    let answer = await this.RTCConnection.createAnswer();
    await this.RTCConnection.setLocalDescription(answer);
    let answerMessage = this.RTCConnection.localDescription;
    this.hubConnection.invoke("SendAnswer", answerMessage.sdp);
  };

  componentWillUnmount() {
    if (this.hubConnection) {
      this.hubConnection.stop();
      this.hubConnection = null;
    }
    if (this.screenSharingStream) {
      this.screenSharingStream.getVideoTracks()[0].stop();
      this.screenSharingStream = null;
    }
    if (this.RTCConnection) {
      this.RTCConnection.close();
      this.RTCConnection = null;
    }
  }

  enableSharing = () => {
    this.setState({ sharingEnabled: true });
  };

  render() {
    let { sharingEnabled, sharingInProgress } = this.state;

    if (!sharingEnabled) {
      return (
        <div className="leftPadding">
          <h3>
            <AxoLocal
              entity="axoEntityidcode250"
              defaultValue={"Velkommen til Axolex skærmdeling"}
            />
          </h3>
          <div>
            <LexButton onClick={this.enableSharing}>
              <AxoLocal
                entity="axoEntityidcode251"
                defaultValue={"Tryk her for at modtage skærmdeling"}
              />
            </LexButton>
          </div>
        </div>
      );
    }

    return (
      <div>
        {!sharingInProgress ? (
          <h1 className="leftPadding">
            <AxoLocal
              entity="axoAccounting51"
              defaultValue={"Her vil brugerens skærm blive vist."}
            />
          </h1>
        ) : (
          <div>
            <video
              ref={(c) => (this.videoStreamPlayer = c)}
              style={{ width: "100%" }}
            />
          </div>
        )}
      </div>
    );
  }
}
