import React, { useState } from "react";

import { Button } from "react-bootstrap";

let show, setShow;
function AxoToggleButton(props) {
  [show, setShow] = useState(false);

  const onClick = () => {
    setShow(!show);

    if (!!props.onClick) {
      props.onClick();
    }
  };

  return (
    <>
      <Button className="toggleButton" style={props.style} onClick={onClick}>
        {props.children}
      </Button>
      {show && props.content}
    </>
  );
}

export default AxoToggleButton;
