import React from "react";
import ComposeView from "./ComposeView";
import { withRouter } from "react-router-dom";

import { DataStore } from "../../services/AxoServices";

import {
  LoadingIndicator,
  withRTKData,
  withRTKMessageData,
  withRTKCaseData,
} from "../../utilities/LexUtilities";

import QueryString from "query-string";

class ComposeContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      userSettings: {},
      userGroup: {},
      userProfileArray: [],
      clientProfiles: [],
      documentMap: {},
      caseMap: {},
      friends: [],
      emailAccounts: [],
      selectedEmailAccount: 0,
      emailSuggestions: [],
    };
    this.name = "ComposeContainer";
  }

  makeDefaultMessage = (userSettings) => {
    var message = {
      sender: null,
      receiver: null,
      externalMailReceiver: "",
      subject: "",
      messageText: "",
      draft: true,
      attachments: [],
      bccUsers: [],
    };
    if (userSettings.mailTemplate) {
      message.messageText += this.getRawSignature(userSettings.mailTemplate);
    }
    if (userSettings.mailSignature) {
      message.messageText +=
        "<br/>" + this.getRawSignature(userSettings.mailSignature);
    }
    return message;
  };

  getRawSignature = (signature) => {
    var bodyHtml = /<body.*?>([\s\S]*)<\/body>/.exec(signature)[1];
    return bodyHtml;
  };

  componentDidMount() {
    DataStore.subscribe(this.name, (store) => {
      let filteredLabels = store.labels.filter(
        (l) => l.type === 3 && l.clientIds.length > 0
      );
      let sortedLabels = filteredLabels.sort((l1, l2) =>
        l1.name.localeCompare(l2.name)
      );

      this.setState({
        userProfile: store.userProfile,
        userProfileArray: store.userProfileArray,
        caseMap: store.caseMap,
        friends: store.friends,
        clientProfiles: store.clientProfiles,
        lawyerProfiles: store.lawyerProfiles,
        documentMap: store.documentMap,
        emailAccounts: store.emailAccounts,
        labels: sortedLabels,
        userGroup: store.userGroup,
        userSettings: store.userSettings,
        emailSuggestions: store.emailSuggestions,
        cachedBCCList: store.cachedBCCList,
      });
    });

    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.fetchUserProfileArray();
    DataStore.fetchClientProfiles();
    DataStore.fetchLawyerProfiles();
    DataStore.initializeDocuments();
    DataStore.fetchEmailAccounts();
    DataStore.initializeLabels();
    DataStore.initializeUserGroup();
    DataStore.initializeCases();
  }

  componentDidUpdate(prevProps, prevState) {
    let { selectedEmailAccount, userSettings, emailAccounts, message } =
      this.state;
    if (
      !selectedEmailAccount &&
      (!!userSettings.emailAccountId || emailAccounts.length === 1)
    ) {
      this.setState({
        selectedEmailAccount:
          userSettings.emailAccountId ||
          (emailAccounts.length === 1 ? emailAccounts[0].id : 0),
      });
    }

    if (!!userSettings.id && !prevState.userSettings.id) {
      //Generate message when user settings have been loaded
      this.generateStartMessage(userSettings);
    }

    let { match, singleMessage } = this.props;
    if (match.params.draftId && singleMessage.id !== (message || {}).id) {
      this.setState({ message: singleMessage });
    }
  }

  generateStartMessage(userSettings) {
    let { match } = this.props;
    let cachedMessage = DataStore.getCachedMessage();
    if (cachedMessage) {
      console.log("Generating cached");
      let newMessage = { ...cachedMessage };
      // newMessage.messageText = this.enforceMaxLength(newMessage.messageText);
      // newMessage.messageText = this.removeInlineImages(newMessage.messageText);
      let selectedEmailAccount = this.state.selectedEmailAccount;
      if (newMessage.externalAccountId) {
        selectedEmailAccount = newMessage.externalAccountId;
      }
      if (userSettings.mailSignature) {
        let bodyStart = newMessage.messageText.indexOf("<body");
        let bodyEnd = newMessage.messageText.indexOf(">", bodyStart);
        let sig = this.getRawSignature(userSettings.mailSignature);
        if (bodyStart > -1 && bodyEnd > -1) {
          newMessage.messageText =
            newMessage.messageText.slice(0, bodyEnd + 1) +
            "<p></p>" +
            sig +
            newMessage.messageText.slice(bodyEnd + 1);
        } else {
          newMessage.messageText += sig;
        }
      }

      this.setState({
        selectedEmailAccount,
        message: newMessage,
      });
      DataStore.setCachedMessage(null);
    } else if (match.params.draftId) {
      let { setMessageId } = this.props;
      let draftId = parseInt(match.params.draftId, 10);
      //Retrieve the full message
      setMessageId(draftId);
      //Message set in ComponentDidUpdate

      // ApiService.getMessage(draftId)
      // .then(fullMessage => {
      //   this.setState({ message: fullMessage });
      // });
    } else if (match.params.email) {
      let query = QueryString.parse(this.props.location.search);

      var message = this.makeDefaultMessage(userSettings);
      message.externalMailReceiver = match.params.email;
      message.subject = (query.subject || "").toString() || "";
      message.messageText = (query.body || "").toString() || "";

      this.setState({ message });
    } else {
      console.log("Generating default");

      this.setState({ message: this.makeDefaultMessage(userSettings) });
    }
  }

  enforceMaxLength = (messageText) => {
    if (messageText.length <= 2000000) {
      return messageText;
    }

    return messageText.substring(0, 200000 - 14) + "</body></html>";
  };

  removeInlineImages = (messageText) => {
    let imgIndex = messageText.indexOf("<img");
    let newText = messageText;
    while (imgIndex > -1) {
      let imgEnd = messageText.indexOf(">", imgIndex);
      var imgText = messageText.substring(imgIndex, imgEnd + 1);
      if (imgText.includes("data:image")) {
        newText = newText.replace(imgText, "");
      }
      imgIndex = messageText.indexOf("<img", imgEnd);
    }

    return newText;
  };

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  onSelectEmailAccount = (event) => {
    this.setState({ selectedEmailAccount: parseInt(event.target.value, 10) });
  };

  render() {
    if (!this.state.message || !this.state.userSettings.id) {
      return (
        <div style={{ position: "relative", height: "500px" }}>
          <LoadingIndicator />
        </div>
      );
    }

    var friendsPlus = this.state.friends.slice();
    friendsPlus.push(this.state.userProfile);
    return (
      <ComposeView
        clientCompose={!!this.props.clientCompose}
        {...this.props}
        {...this.state}
        friends={friendsPlus}
        onSelectEmailAccount={this.onSelectEmailAccount}
      />
    );
  }
}

export default withRTKCaseData(
  withRTKMessageData(withRTKData(withRouter(ComposeContainer)))
);
