import React from "react";

import { PanelGroup } from "react-bootstrap";

const LexPanelGroup = (props) => {
  let { className } = props;
  let allClasses = "Lex-Accordion" + (!!className ? " " + className : "");

  return <PanelGroup {...props} className={allClasses}></PanelGroup>;
};

export default LexPanelGroup;
