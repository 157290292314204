import React from "react";
import ClientTrashEntriesView from "./ClientTrashEntriesView";
import { withRouter } from "react-router-dom";

import { DataStore } from "../../services/AxoServices";

import {
  Consumer,
  withRTKData,
  withRTKMessageData,
} from "../../utilities/LexUtilities";

class ClientTrashContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeDocuments();
    DataStore.fetchInvoices();
  }

  render() {
    return (
      <Consumer>
        {(store) => (
          <ClientTrashEntriesView
            {...this.props}
            messages={this.props.messageMap.trashed}
            documents={store.documentMap.trashed}
            contacts={this.props.contactMap.trashed}
            invoices={store.invoices.filter((i) => i.trashed)}
          />
        )}
      </Consumer>
    );
  }
}

export default withRTKMessageData(
  withRTKData(withRouter(ClientTrashContainer))
);
