import React from "react";
import NumericInput from "react-numeric-input";
import Select from "react-select";
import * as $ from "jquery";
import PropTypes from "prop-types";

import { ApiService, DataActions } from "../../services/AxoServices";

import {
  Icon,
  TextAreaWrapper,
  LexButton,
  DocumentSearchBox,
  AxoLocal,
  AxoCheckbox,
} from "../../utilities/LexUtilities";

import {
  Grid,
  Row,
  Col,
  Form,
  Button,
  ButtonToolbar,
  ControlLabel,
  FormGroup,
  FormControl,
} from "react-bootstrap";

export default class CreateItemPriceForm extends React.PureComponent {
  static propTypes = {
    documentMap: PropTypes.object.isRequired,
    itemPriceTypes: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      price: this.makeNewPrice(),
      showSuccessMessage: false,
      showFailureMessage: false,
    };
  }

  makeNewPrice = () => {
    return {
      name: "",
      description: "",
      price: 0.0,
      currency: "DKK",
      pictureId: null,
      itemPriceTypeId: null,
      buyable: false,
      creationDate: new Date(),
    };
  };

  onReset = () => {
    this.setState({
      price: this.makeNewPrice(),
    });
  };

  onCreatePrice = () => {
    let { price } = this.state;
    if (!price.name) {
      return;
    }
    DataActions.createItemPrice(price)
      .then((response) => {
        if (response.ok) {
          this.setState({
            price: this.makeNewPrice(),
            showSuccessMessage: true,
          });
          setTimeout(() => {
            this.setState({ showSuccessMessage: false });
          }, 3000);
        } else {
          return Promise.reject("Could not create price");
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ showFailureMessage: true });
        setTimeout(() => {
          this.setState({ showFailureMessage: false });
        }, 3000);
      });
  };

  onPropertyChange = (propertyName, value) => {
    let price = { ...this.state.price };
    price[propertyName] = value;
    this.setState({ price });
  };

  uploadPicture = () => {
    let component = this;
    let { price } = { ...this.state };

    $("#uploadImage").trigger("click");
    $("#uploadImage").on("change", function () {
      let file = this.files[0];
      if (!file.type.includes("image")) {
        return;
      }
      DataActions.uploadDocuments(this.files).then((response) => {
        if (response.status === 200) {
          response.json().then((addedFiles) => {
            price.pictureId = addedFiles[0].id;
            component.setState({ price });
          });
        }
      });
    });
  };

  render() {
    let { documentMap, itemPriceTypes } = this.props;
    let { price } = this.state;

    let itemPriceOptions = itemPriceTypes.map((type) => {
      return { value: type.id.toString(), label: type.name };
    });

    return (
      <Grid fluid>
        <Row>
          <Col xs={12} smOffset={1} sm={10} style={{ paddingTop: "20px" }}>
            <div className="text-center" style={{ display: "inline-block" }}>
              <h3>
                <AxoLocal entity="Enhedspris1" defaultValue=" Opret ny pris" />
              </h3>
            </div>
            <Form>
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="Enhedspris2" defaultValue="Billede" />
              </ControlLabel>
              {!!price.pictureId ? (
                <div style={{ paddingBottom: "5px" }}>
                  <LexButton
                    onClick={() => this.onPropertyChange("pictureId", null)}
                    style={{ paddingBottom: "5px" }}
                  >
                    <AxoLocal
                      entity="Enhedspris3"
                      defaultValue="Fjern billede"
                    />
                  </LexButton>
                  <img
                    alt=""
                    style={{
                      maxWidth: "150px",
                      height: "auto",
                      display: "block",
                    }}
                    src={ApiService.getFileInlinePath(price.pictureId)}
                  />
                </div>
              ) : null}
              <div className="flexbox-responsive">
                <div>
                  <AxoLocal
                    componentClass={DocumentSearchBox}
                    documentMap={documentMap}
                    handleSelectedDocument={(document) =>
                      this.onPropertyChange("pictureId", document.id)
                    }
                    onlyImages
                    clearOnSelect
                    componentAttribute="placeholder"
                    entity="placeholderSelectexistinglogo"
                  />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div>
                  <LexButton onClick={this.uploadPicture}>
                    <Icon glyph="icon-fontello-desktop" />
                    &nbsp;
                    <AxoLocal
                      entity="Enhedspris4"
                      defaultValue="Upload billede fra PC"
                    />
                  </LexButton>
                </div>
              </div>
              <input
                name="image"
                type="file"
                id="uploadImage"
                accept="image/*"
                style={{ display: "none" }}
              />
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal
                    entity="DocumentGridViewType"
                    defaultValue={"Type"}
                  />
                </ControlLabel>
                <Select
                  name="itemPriceType"
                  menuPlacement="auto"
                  value={itemPriceOptions.find(
                    (o) => o.value === price.itemPriceTypeId
                  )}
                  onChange={(selection, arg) => {
                    this.onPropertyChange(
                      "itemPriceTypeId",
                      !!selection && selection.value !== "0"
                        ? selection.value
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[{ value: "0", label: "..." }].concat(
                    itemPriceOptions
                  )}
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="Enhedspris5" defaultValue="Navn" />
                </ControlLabel>
                <FormControl
                  type="text"
                  value={price.name}
                  onChange={(event) =>
                    this.onPropertyChange("name", event.target.value)
                  }
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="Enhedspris6" defaultValue="Beskrivelse" />
                </ControlLabel>
                <TextAreaWrapper
                  value={price.description}
                  onChange={(event) =>
                    this.onPropertyChange("description", event.target.value)
                  }
                  rows={2}
                />
              </FormGroup>
              <FormGroup>
                <AxoCheckbox
                  checked={price.buyable}
                  onChange={(event) =>
                    this.onPropertyChange("buyable", event.target.checked)
                  }
                />
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="Enhedspris22" defaultValue=" Kan købes" />
                  &nbsp;
                </ControlLabel>
              </FormGroup>
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="AdminTabView29" defaultValue="Pris" />
              </ControlLabel>
              <NumericInput
                className="form-control"
                disabled={!price.buyable}
                onChange={(value) => this.onPropertyChange("price", value)}
                step={1}
                precision={2}
                value={price.price}
              />
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="AdminTabView7" defaultValue="Valuta" />
                </ControlLabel>
                <Select
                  name="form-field-name"
                  menuPlacement="auto"
                  isDisabled={!price.buyable}
                  value={{
                    value: price.currency.toUpperCase(),
                    label: price.currency.toUpperCase(),
                  }}
                  onChange={(selection, arg) => {
                    this.onPropertyChange(
                      "currency",
                      !!selection ? selection.value : "DKK"
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    { value: "DKK", label: "DKK" },
                    { value: "USD", label: "USD" },
                    { value: "GBP", label: "GBP" },
                    { value: "EUR", label: "EUR" },
                    { value: "CNY", label: "CNY" },
                    { value: "SEK", label: "SEK" },
                    { value: "NKK", label: "NKK" },
                    { value: "ISK", label: "ISK" },
                  ]}
                />
              </FormGroup>
            </Form>
            <div style={{ paddingBottom: "15px" }}>
              <div className="text-center" style={{ paddingTop: "10px" }}>
                <ButtonToolbar style={{ display: "inline-block" }}>
                  <Button
                    className="Lex-button-2 "
                    onClick={this.onCreatePrice}
                  >
                    <AxoLocal entity="ADDViewCreate" defaultValue="Opret" />
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button className="Lex-button-2 " onClick={this.onReset}>
                    <AxoLocal entity="AdminTabView16" defaultValue="Nulstil" />
                  </Button>
                </ButtonToolbar>
              </div>
              {this.state.showSuccessMessage ? (
                <div className="text-center axoblue">
                  <h3>
                    <AxoLocal
                      entity="Enhedspris7"
                      defaultValue="Prisen blev oprettet."
                    />
                  </h3>
                </div>
              ) : null}
              {this.state.showFailureMessage ? (
                <div className="text-center axored">
                  <Icon glyph="icon-fontello-info-3" />
                  &nbsp;&nbsp;
                  <h3>
                    <AxoLocal
                      entity="AdminTabView18"
                      defaultValue="Der skete en fejl. Prøv igen senere."
                    />
                  </h3>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
