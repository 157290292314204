import React from "react";
import PropTypes from "prop-types";

export default class AxoDropzone extends React.Component {
  static propTypes = {
    onDrop: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  onDrop = (event) => {
    let { disabled, onDrop } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (disabled) {
      return;
    }

    let files = [];
    if (!!event.dataTransfer.items) {
      for (var i = 0; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === "file") {
          files.push(event.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      files = event.dataTransfer.files;
      // for (var i = 0; i < event.dataTransfer.files.length; i++) {
      //   console.log('... file[' + i + '].name = ' + event.dataTransfer.files[i].name);
      // }
    }

    onDrop(files);

    if (!!event.dataTransfer.items) {
      event.dataTransfer.items.clear();
    } else {
      event.dataTransfer.clearData();
    }
  };

  captureEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  render() {
    let props = {
      ...this.props,
      onDrop: this.onDrop,
      onDragEnter: this.captureEvent,
      onDragOver: this.captureEvent,
    };
    return <div {...props}>{this.props.children}</div>;
  }
}
