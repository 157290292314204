import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import moment from "moment";

import { ButtonToolbar, Table } from "react-bootstrap";

import {
  DataStore,
  NumberService,
  PrintService,
  ContactService,
} from "../../../services/AxoServices";

import {
  Dimensions,
  ClientSearchBox,
  Flexbox,
  FlexElement,
  AxoLocal,
  Link,
  getText,
  LexButton,
  AsyncButton,
  AxoDateTime,
  AxoCheckbox,
  withRouter,
  Icon,
} from "../../../utilities/LexUtilities";

// import AccountingVatTable from './AccountingVatTable'

class AccountingVatViewV2 extends React.Component {
  static propTypes = {
    selectedPlan: PropTypes.object.isRequired,
    selectedContact: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedPeriodIndex: 0, //1. kvartal, etc.
      printing: false,
    };
  }

  doSettleVat = async () => {
    let { doSettleVat, selectedContact, includeDraft } = this.props;

    return doSettleVat(selectedContact.id, includeDraft);
  };

  doPayVat = async () => {
    let { doPayVat, selectedContact, selectedPaymentAccountId } = this.props;
    return doPayVat(
      selectedContact.id,
      selectedPaymentAccountId || this.getDefaultPaymentAccountId()
    );
  };

  getDefaultPaymentAccountId = () => {
    let { selectedPlan } = this.props;

    let paymentAccounts = selectedPlan.accounts.filter(
      (a) =>
        a.number >= selectedPlan.bankAccountIntervalStart &&
        a.number <= selectedPlan.bankAccountIntervalEnd
    );

    return paymentAccounts.length > 1 ? paymentAccounts[0].id : "";
  };

  defaultFiscalYear = { id: 0 };

  monthNames = [
    "Januar",
    "Februar",
    "Marts",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  getSelectedPeriodOptions = (selectedFiscalYear) => {
    let { vatReport } = this.props;

    let vatReportArray = vatReport.current;

    if (!vatReportArray || vatReportArray.length === 0) {
      return [];
    }

    let startYear = moment(selectedFiscalYear.startDate).year();
    let endYear = moment(selectedFiscalYear.endDate).year();

    var showYears = startYear !== endYear;

    let options = [];
    let currentYear = startYear;

    switch (selectedFiscalYear.vatPeriod) {
      case "Quarter":
        let currentQuarterIndex = -1;
        for (let i = 0; i < vatReportArray.length; i++) {
          let quarterIndex = Math.floor(vatReportArray[i].monthIndex / 3);
          if (quarterIndex !== currentQuarterIndex) {
            if (quarterIndex < currentQuarterIndex) {
              currentYear++;
            }
            let label = (quarterIndex + 1).toString() + ". kvartal";
            if (showYears) {
              label = label + " - " + currentYear;
            }
            options.push({ value: i, label });
            currentQuarterIndex = quarterIndex;
          }
        }
        return options;

      case "HalfYear":
        let currentHalfYearIndex = -1;
        for (let i = 0; i < vatReportArray.length; i++) {
          let halfYearIndex = Math.floor(vatReportArray[i].monthIndex / 6);
          if (halfYearIndex !== currentHalfYearIndex) {
            if (halfYearIndex < currentHalfYearIndex) {
              currentYear++;
            }
            let label = (halfYearIndex + 1).toString() + ". halvår";
            if (showYears) {
              label = label + " - " + currentYear;
            }
            options.push({ value: i, label });
            currentHalfYearIndex = halfYearIndex;
          }
        }
        return options;

      case "Month":
        let currentMonthIndex = -1;
        for (let i = 0; i < vatReportArray.length; i++) {
          let monthIndex = vatReportArray[i].monthIndex;
          if (monthIndex < currentMonthIndex) {
            currentYear++;
          }
          let label = this.monthNames[monthIndex];
          if (showYears) {
            label = label + " - " + currentYear;
          }
          options.push({ value: i, label });
          currentMonthIndex = monthIndex;
        }
        return options;
      case "Year":
        let label = currentYear;
        options.push({ value: 0, label });
        for (let i = 1; i < vatReportArray.length; i++) {
          let monthIndex = vatReportArray[i].monthIndex;
          if (monthIndex === 0) {
            currentYear++;
            label = currentYear;
            options.push({ value: i, label });
          }
        }
        return options;
      default:
        return [];
    }
  };

  getPeriodResults = (selectedFiscalYear) => {
    let { selectedPeriodIndex } = this.state;

    let {
      vatReport,
      // vatReportArray
    } = this.props;

    let vatPeriod = selectedFiscalYear.vatPeriod;

    let yearStartDate = moment.utc(selectedFiscalYear.startDate);
    let yearEndDate = moment.utc(selectedFiscalYear.endDate);

    let from = selectedPeriodIndex;
    let to = vatReport.current.length - 1;

    let result = {
      ingoing: 0,
      outgoing: 0,
      foreignGoodsVat: 0,
      foreignServicesVat: 0,
      oil: 0,
      power: 0,
      gas: 0,
      coal: 0,
      carbon: 0,
      water: 0,
      euGoodsPurchaseTotal: 0,
      euServicesPurchaseTotal: 0,
      euGoodsSales: 0,
      installationTotal: 0,
      euServiceSales: 0,
      taxFreeSales: 0,
      settled: false,
      paid: false,
      startDate: yearStartDate,
      endDate: yearEndDate,
    };

    if (vatReport.current.length === 0) {
      return { currentResult: result, settledResult: result };
    }

    let startMonthIndex = vatReport.current[from].monthIndex;

    switch (vatPeriod) {
      case "Quarter":
        let quarterIndex = Math.floor(startMonthIndex / 3);
        let nextQuarterIndex = vatReport.current.findIndex(
          (r, index) =>
            index > from && Math.floor(r.monthIndex / 3) !== quarterIndex
        );

        to = nextQuarterIndex !== -1 ? nextQuarterIndex - 1 : to;
        break;
      case "HalfYear":
        let halfYearIndex = Math.floor(startMonthIndex / 6);
        let nextHalfYearIndex = vatReport.current.findIndex(
          (r, index) =>
            index > from && Math.floor(r.monthIndex / 6) !== halfYearIndex
        );

        to = nextHalfYearIndex !== -1 ? nextHalfYearIndex - 1 : to;
        break;
      case "Month":
        to = from;
        break;
      case "Year":
        let nextYearIndex = vatReport.current.findIndex(
          (r, index) => index > from && r.monthIndex < startMonthIndex
        );

        to = nextYearIndex !== -1 ? nextYearIndex - 1 : to;
        break;
      default:
        break;
    }

    result.startDate = moment
      .utc(yearStartDate)
      .add(from, "months")
      .startOf("month");
    result.endDate = moment.utc(yearStartDate).add(to, "months").endOf("month");

    let settledResult = { ...result };

    for (let i = from; i <= to; i++) {
      let periodResult = vatReport.current[i];
      //Ingoing VAT have negative signs, so we invert the signs here
      result.outgoing -= periodResult.outgoing;
      result.foreignGoodsVat -= periodResult.foreignGoodsVat;
      result.foreignServicesVat -= periodResult.foreignServicesVat;

      result.ingoing += periodResult.ingoing;
      result.oil += periodResult.oil;
      result.power += periodResult.power;
      result.gas += periodResult.gas;
      result.coal += periodResult.coal;
      result.carbon += periodResult.carbon;
      result.water += periodResult.water;
      result.euGoodsPurchaseTotal += periodResult.euGoodsPurchaseTotal;
      result.euServicesPurchaseTotal += periodResult.euServicesPurchaseTotal;
      result.euGoodsSales += periodResult.euGoodsSales;
      result.installationTotal += periodResult.installationTotal;
      result.euServiceSales += periodResult.euServiceSales;
      result.taxFreeSales += periodResult.taxFreeSales;

      result.settled = result.settled || periodResult.settled;
      result.paid = result.paid || periodResult.paid;

      let settledPeriodResult = vatReport.settled[i];
      settledResult.outgoing -= settledPeriodResult.outgoing;
      settledResult.foreignGoodsVat -= settledPeriodResult.foreignGoodsVat;
      settledResult.foreignServicesVat -=
        settledPeriodResult.foreignServicesVat;

      settledResult.ingoing += settledPeriodResult.ingoing;
      settledResult.oil += settledPeriodResult.oil;
      settledResult.power += settledPeriodResult.power;
      settledResult.gas += settledPeriodResult.gas;
      settledResult.coal += settledPeriodResult.coal;
      settledResult.carbon += settledPeriodResult.carbon;
      settledResult.water += settledPeriodResult.water;
      settledResult.euGoodsPurchaseTotal +=
        settledPeriodResult.euGoodsPurchaseTotal;
      settledResult.euServicesPurchaseTotal +=
        settledPeriodResult.euServicesPurchaseTotal;
      settledResult.euGoodsSales += settledPeriodResult.euGoodsSales;
      settledResult.installationTotal += settledPeriodResult.installationTotal;
      settledResult.euServiceSales += settledPeriodResult.euServiceSales;
      settledResult.taxFreeSales += settledPeriodResult.taxFreeSales;
    }

    let currentResult = result;

    return { currentResult, settledResult };
  };

  onPrint = () => {
    this.setState({ printing: true }, () => {
      if (typeof window !== "undefined") {
        PrintService.printElement(document.getElementById("print-report"));
      }
      this.setState({ printing: false });
    });
  };

  render() {
    let {
      selectedPlan,
      contactMap,
      handleSelectedClient,
      selectedContact,
      fiscalYears,
      selectedFiscalYearId,
      onSelectFiscalYear,
      // vatReportArray,
      onSettleVat,
      onCancelSettling,
      settlingVat,
      settlingStartDate,
      settlingEndDate,
      showConfirmationMessage,
      // onPayVat,
      payingVat,
      onCancelPayment,
      selectedPaymentAccountId,
      onSelectPaymentAccount,
      paymentDate,
      onSelectPaymentDate,
      includeDraft,
      onChangeIncludeDraft,
      fixedClient,
    } = this.props;

    let { selectedPeriodIndex, printing } = this.state;

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let standardAccounts = selectedPlan.accounts.filter(
      (a) => a.type === "Standard"
    );
    let accountOptions = standardAccounts.map((f) => {
      return { value: f.id, label: f.number.toString() + " - " + f.name };
    });

    let activePaymentAccount =
      selectedPaymentAccountId || this.getDefaultPaymentAccountId();

    let selectedPeriodOptions =
      this.getSelectedPeriodOptions(selectedFiscalYear);

    let { currentResult, settledResult } =
      this.getPeriodResults(selectedFiscalYear);

    let selectedPeriod = selectedPeriodOptions.find(
      (o) => o.value === selectedPeriodIndex
    );

    let VatResultsPage = this.VatResultsPage;
    return (
      <div style={{ marginLeft: "15px", marginRight: "15px" }}>
        <Flexbox
          responsive
          alignCenter
          style={{ marginTop: "15px", paddingBottom: "10px" }}
        >
          {!fixedClient && (
            <FlexElement className="onTop rightPadding" style={{ zIndex: 10 }}>
              <AxoLocal
                componentClass={ClientSearchBox}
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                componentAttribute="placeholder"
                entity="composeSelectClientPlaceholder1"
              />
            </FlexElement>
          )}
          {selectedContact.id && !!fiscalYears ? (
            <>
              <FlexElement flexBasis="150px" className="onTop rightPadding">
                <Select
                  name="fiscalYear"
                  menuPlacement="auto"
                  value={{
                    value: selectedFiscalYear.id.toString(),
                    label: selectedFiscalYear.name,
                  }}
                  onChange={(selection, arg) => {
                    onSelectFiscalYear(
                      !!selection && selection.value !== "0"
                        ? parseInt(selection.value, 10)
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "0",
                      label: getText("axoidcode100", "Vælg regnskabsår"),
                    },
                    ...fiscalYears.map((year) => {
                      return { value: year.id.toString(), label: year.name };
                    }),
                  ]}
                />
              </FlexElement>
              {!!selectedFiscalYearId && (
                <>
                  <FlexElement flexBasis="200px" className="onTop rightPadding">
                    <Select
                      name="period"
                      menuPlacement="auto"
                      value={selectedPeriod}
                      onChange={(selection, arg) => {
                        if (!selection) {
                          return;
                        }
                        this.setState({ selectedPeriodIndex: selection.value });
                      }}
                      noOptionsMessage={() => ""}
                      options={selectedPeriodOptions}
                    />
                  </FlexElement>
                  <FlexElement className="text-right rightPadding">
                    {currentResult.settled ? (
                      <>
                        {/* <LexButton onClick={() => onPayVat(currentResult.startDate, currentResult.endDate)} disabled={payingVat || currentResult.paid}>
                          <AxoLocal key='pay' entity='axoidcode114' defaultValue={'Betal moms'}/> 
                        </LexButton> */}
                        <LexButton
                          onClick={() =>
                            onSettleVat(
                              currentResult.startDate,
                              currentResult.endDate
                            )
                          }
                          disabled={
                            !selectedPlan.vatSettlementAccountId || settlingVat
                          }
                        >
                          <AxoLocal
                            key="settle"
                            entity="axoidcode113"
                            defaultValue={"Afregn moms"}
                          />
                        </LexButton>
                      </>
                    ) : (
                      <LexButton
                        onClick={() =>
                          onSettleVat(
                            currentResult.startDate,
                            currentResult.endDate
                          )
                        }
                        disabled={
                          !selectedPlan.vatSettlementAccountId || settlingVat
                        }
                      >
                        <AxoLocal
                          key="settle"
                          entity="axoidcode113"
                          defaultValue={"Afregn moms"}
                        />
                      </LexButton>
                    )}
                  </FlexElement>
                  <FlexElement className="rightPadding text-center">
                    <LexButton
                      title={getText("axoEntityidcode142", "Print journal")}
                      onClick={this.onPrint}
                    >
                      <Icon glyph="icon-fontello-print-3" />
                    </LexButton>
                  </FlexElement>
                </>
              )}
              <FlexElement style={{ paddingTop: "10px", paddingLeft: "15px" }}>
                <AxoCheckbox
                  onChange={(event) =>
                    onChangeIncludeDraft(event.target.checked)
                  }
                  checked={includeDraft}
                />
                <AxoLocal entity="axoidcode33" defaultValue={"Medtag kladde"} />
              </FlexElement>
            </>
          ) : null}
        </Flexbox>
        {!selectedPlan.vatSettlementAccountId && (
          <div className="axored largeText">
            <AxoLocal
              entity="axoEntityidcode45"
              defaultValue={
                " Momsafregningskonto skal vælges i kontoplan opsætningen."
              }
            />
          </div>
        )}
        {!!fiscalYears && selectedContact.id && fiscalYears.length === 0 ? (
          <div>
            <span style={{ fontSize: "18px" }}>
              <Link to="Setup">
                {!fixedClient ? (
                  <>
                    <AxoLocal
                      entity="axoidcode67"
                      defaultValue={"Opret et regnskabsår for"}
                    />
                    &nbsp;
                    {ContactService.getContactAccountingName(selectedContact)}
                  </>
                ) : (
                  <>
                    <AxoLocal
                      entity="axoidcode215"
                      defaultValue={"Opret et regnskabsår"}
                    />
                  </>
                )}
              </Link>
            </span>
          </div>
        ) : null}
        {settlingVat ? (
          <Flexbox responsive collapseXS>
            <FlexElement className="rightPadding">
              <h4>
                <AxoLocal
                  entity="axoidcode115"
                  defaultValue={"Afregn moms for perioden"}
                />
                &nbsp;{moment(settlingStartDate).format("L")} -{" "}
                {moment(settlingEndDate).format("L")}
                &nbsp;
                <AxoLocal entity="axoidcode116" defaultValue={"på konto"} />
              </h4>
            </FlexElement>
            <FlexElement flexBasis="250px" className="rightPadding">
              <Select
                name="vatSettlementAccount"
                menuPlacement="auto"
                isDisabled
                value={
                  !!selectedPlan.vatSettlementAccountId
                    ? accountOptions.find(
                        (o) => o.value === selectedPlan.vatSettlementAccountId
                      )
                    : { value: "", label: "" }
                }
                noOptionsMessage={() => ""}
                options={[
                  {
                    value: "",
                    label: getText("axoAccounting6f", "Vælg konto"),
                  },
                  ...accountOptions,
                ]}
              />
            </FlexElement>
            <FlexElement>
              <ButtonToolbar>
                <AsyncButton onClick={this.doSettleVat}>
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </AsyncButton>
                <LexButton onClick={onCancelSettling}>
                  <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
                </LexButton>
              </ButtonToolbar>
            </FlexElement>
          </Flexbox>
        ) : null}
        {payingVat ? (
          <>
            <div>
              <h4>
                <AxoLocal
                  entity="axoidcode117"
                  defaultValue={"Betal moms for perioden"}
                />
                &nbsp;{moment(settlingStartDate).format("L")} -{" "}
                {moment(settlingEndDate).format("L")}
              </h4>
            </div>
            <Flexbox responsive collapseXS>
              <FlexElement className="rightPadding">
                <h4>
                  <AxoLocal entity="axoidcode121" defaultValue={"til konto"} />
                </h4>
              </FlexElement>
              <FlexElement flexBasis="250px" className="rightPadding">
                <Select
                  name="vatSettlementAccount"
                  menuPlacement="auto"
                  isDisabled
                  value={
                    !!selectedPlan.vatSettlementAccountId
                      ? accountOptions.find(
                          (o) => o.value === selectedPlan.vatSettlementAccountId
                        )
                      : { value: "", label: "" }
                  }
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "",
                      label: getText("axoAccounting6f", "Vælg konto"),
                    },
                    ...accountOptions,
                  ]}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <h4>
                  <AxoLocal entity="axoidcode120" defaultValue={"fra konto"} />
                </h4>
              </FlexElement>
              <FlexElement flexBasis="250px" className="rightPadding">
                <Select
                  name="paymentAccount"
                  menuPlacement="auto"
                  value={
                    !!activePaymentAccount
                      ? accountOptions.find(
                          (o) => o.value === activePaymentAccount
                        )
                      : { value: "", label: "" }
                  }
                  onChange={(selectedAccount) => {
                    if (!!selectedAccount) {
                      onSelectPaymentAccount(selectedAccount.value);
                    }
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "",
                      label: getText("axoAccounting6f", "Vælg konto"),
                    },
                    ...accountOptions,
                  ]}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <h4>
                  <AxoLocal entity="axoidcode118" defaultValue={"på dato"} />
                </h4>
              </FlexElement>
              <FlexElement className="rightPadding">
                <AxoDateTime
                  id="paymentDate"
                  value={moment(paymentDate)}
                  isValidDate={(currentDate) => {
                    return !currentDate.isBefore(moment(settlingEndDate));
                  }}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectPaymentDate}
                  // inputProps={ { style: { border: "none", padding: "0px 0px 0px 0px"}}}
                />
              </FlexElement>
              <FlexElement>
                <ButtonToolbar>
                  <AsyncButton onClick={this.doPayVat}>
                    <AxoLocal
                      entity="friendRequestTimelineonApproveRequest"
                      defaultValue={"Godkend"}
                    />
                  </AsyncButton>
                  <LexButton onClick={onCancelPayment}>
                    {" "}
                    <AxoLocal
                      entity="axoAccounting22"
                      defaultValue={"Fortryd"}
                    />
                  </LexButton>
                </ButtonToolbar>
              </FlexElement>
            </Flexbox>
          </>
        ) : null}
        {!settlingVat && showConfirmationMessage ? (
          <div style={{ paddingBottom: "10px" }}>
            <h4>
              <Link to="Drafts" style={{ textDecoration: "underline" }}>
                <AxoLocal
                  entity="axoidcode79"
                  defaultValue={
                    "Posteringen blev oprettet i bogføringskladden."
                  }
                />
              </Link>
            </h4>
          </div>
        ) : null}
        {!!selectedContact.id && !!selectedFiscalYearId && (
          <div id="print-report" className="standardMaxWidth">
            {printing && (
              <div className="largeText text-center">
                {!!selectedContact.id
                  ? selectedContact.firstName +
                    " " +
                    (selectedContact.lastName || "")
                  : ""}
                &nbsp;- {selectedFiscalYear.name} -{" "}
                {!!selectedPeriod ? selectedPeriod.label : ""}
              </div>
            )}
            <VatResultsPage
              currentResult={currentResult}
              settledResult={settledResult}
            />
          </div>
          // <AccountingVatTable
          //   selectedPlan={selectedPlan}
          //   selectedContact={selectedContact}
          //   selectedFiscalYear={selectedFiscalYear}
          //   vatReportArray={vatReportArray}
          //   onSettleVat={onSettleVat}
          //   settlingVat={settlingVat}
          //   onPayVat={onPayVat}
          //   payingVat={payingVat}
          //   includeDraft={includeDraft}
          // />
        )}
      </div>
    );
  }

  DottedLine = () => {
    return (
      <FlexElement
        style={{ borderBottom: "dotted 2px black", margin: "0px 5px 7px 5px" }}
        flexGrow={1}
      ></FlexElement>
    );
  };

  onNavigateToAccount = (number) => {
    let store = DataStore.getStore();

    DataStore.setState({
      accountStartNumber: number,
      accountEndNumber: number,
      selectedPostingStartDate: store.selectedFiscalStartDate,
      selectedPostingEndDate: store.selectedFiscalEndDate,
    });

    //ToDo: Set posting interval to the full fiscal year
    this.props.history.push("Postings");
  };

  VatResultsPage = (props) => {
    let { currentResult, settledResult } = props;
    // let { selectedPlan } = this.props;

    let hasSettledResults = currentResult.settled;

    let newResult = currentResult;
    if (hasSettledResults) {
      newResult = { ...currentResult };
      Object.keys(newResult).forEach((key) => {
        newResult[key] -= settledResult[key];
      });
    }

    return (
      <div className="standardMaxWidth">
        <Table condensed hover>
          <thead>
            <tr>
              <th>
                <div className="mediumText">
                  <strong>
                    <AxoLocal entity="axoAccounting8" defaultValue={"Moms"} />
                  </strong>
                </div>
              </th>
              {hasSettledResults && (
                <>
                  <th className="text-right">
                    <AxoLocal
                      entity="axoEntityidcode46"
                      defaultValue={"Indberettet"}
                    />
                  </th>
                  <th className="text-right">
                    <AxoLocal
                      entity="axoEntityidcode47"
                      defaultValue={"Ændring"}
                    />
                  </th>
                </>
              )}
              <th className="text-right">
                <AxoLocal entity="InvoiceInvoicetotal" defaultValue={"Total"} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode48"
                  defaultValue={"Salgsmoms (udgående moms)"}
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(settledResult.outgoing)}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.outgoing)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.outgoing)}
              </td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode49"
                  defaultValue={
                    "Moms af varekøb i udlandet (både EU og lande uden for EU))"
                  }
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(settledResult.foreignGoodsVat)}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.foreignGoodsVat)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.foreignGoodsVat)}
              </td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode50"
                  defaultValue={
                    "Moms af ydelseskøb i udlandet med omvendt betalingspligt"
                  }
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(
                      settledResult.foreignServicesVat
                    )}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.foreignServicesVat)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.foreignServicesVat)}
              </td>
            </tr>
            <tr>
              <td>
                <div className="mediumText">
                  <strong>
                    <AxoLocal
                      entity="axoEntityidcode51"
                      defaultValue={"Fradrag"}
                    />
                  </strong>
                </div>
              </td>
              {hasSettledResults && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td></td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode52"
                  defaultValue={"Købsmoms (indgående moms)"}
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(settledResult.ingoing)}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.ingoing)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.ingoing)}
              </td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode13"
                  defaultValue={"Olie- og flaskegasafgift"}
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(settledResult.oil)}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.oil)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.oil)}
              </td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode14"
                  defaultValue={"Elafgift"}
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(settledResult.power)}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.power)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.power)}
              </td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode56"
                  defaultValue={"Naturgas- bygasafgift"}
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(settledResult.gas)}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.gas)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.gas)}
              </td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode16"
                  defaultValue={"Kulafgift"}
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(settledResult.coal)}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.coal)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.coal)}
              </td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode17"
                  defaultValue={"CO2-afgift"}
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(settledResult.carbon)}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.carbon)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.carbon)}
              </td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode18"
                  defaultValue={"Vandafgift"}
                />
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(settledResult.water)}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(newResult.water)}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.water)}
              </td>
            </tr>
            <tr>
              <td>
                <div className="mediumText">
                  <strong>Sum</strong>
                </div>
              </td>
              {hasSettledResults && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td></td>
            </tr>
            <tr>
              <td>
                Moms i alt (positivt beløb = betale, negativt beløb = penge til
                gode)
              </td>
              {hasSettledResults && (
                <>
                  <td className="text-right">
                    {NumberService.formatDecimal(
                      settledResult.outgoing +
                        settledResult.foreignGoodsVat +
                        settledResult.foreignServicesVat -
                        settledResult.ingoing -
                        settledResult.oil -
                        settledResult.power -
                        settledResult.gas -
                        settledResult.coal -
                        settledResult.carbon -
                        settledResult.water
                    )}
                  </td>
                  <td className="text-right">
                    {NumberService.formatDecimal(
                      newResult.outgoing +
                        newResult.foreignGoodsVat +
                        newResult.foreignServicesVat -
                        newResult.ingoing -
                        newResult.oil -
                        newResult.power -
                        newResult.gas -
                        newResult.coal -
                        newResult.carbon -
                        newResult.water
                    )}
                  </td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(
                  currentResult.outgoing +
                    currentResult.foreignGoodsVat +
                    currentResult.foreignServicesVat -
                    currentResult.ingoing -
                    currentResult.oil -
                    currentResult.power -
                    currentResult.gas -
                    currentResult.coal -
                    currentResult.carbon -
                    currentResult.water
                )}
              </td>
            </tr>
            <tr>
              <td>
                <div className="mediumText">
                  <strong>Supplerende oplysninger</strong>
                </div>
              </td>
              {hasSettledResults && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td></td>
            </tr>
            <tr>
              <td>
                Rubrik A - varer. Værdien uden moms af varekøb i andre EU-lande
                (EU-erhvervelser)
              </td>
              {hasSettledResults && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(
                  currentResult.euGoodsPurchaseTotal
                )}
              </td>
            </tr>
            <tr>
              <td>
                Rubrik A - ydelser. Værdien uden moms af ydelseskøb i andre
                EU-Lande (EU-erhvervelser)
              </td>
              {hasSettledResults && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(
                  currentResult.euServicesPurchaseTotal
                )}
              </td>
            </tr>
            <tr>
              <td>
                Rubrik B - varer. Værdien af varesalg uden moms til andre
                EU-Lande. Indberettes til systemet EU-salg uden moms.
              </td>
              {hasSettledResults && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.euGoodsSales)}
              </td>
            </tr>
            <tr>
              <td>
                <AxoLocal
                  entity="axoEntityidcode54"
                  defaultValue={
                    "Rubrik B - varer - Indberettes ikke til EU-salg uden moms. Værdien af fx installationer og montage, fjernsalg og nye transportmidler til ikke-momsregistrerede kunder"
                  }
                />
              </td>
              {hasSettledResults && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.installationTotal)}
              </td>
            </tr>
            <tr>
              <td>
                Rubrik B - ydelser. Værdien af visse ydelsessalg uden moms til
                andre EU-lande. Indberettes til EU-salg uden moms.
              </td>
              {hasSettledResults && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.euServiceSales)}
              </td>
            </tr>
            <tr>
              <td>
                Rubrik C. Værdien af andre varer og ydelser, der leveres uden
                afgift her i landet, i andre EU-lande og i lande uden for EU.
              </td>
              {hasSettledResults && (
                <>
                  <td></td>
                  <td></td>
                </>
              )}
              <td className="text-right">
                {NumberService.formatDecimal(currentResult.taxFreeSales)}
              </td>
            </tr>
          </tbody>
        </Table>
        <br />
      </div>
    );
  };
}

export default withRouter(
  Dimensions({
    elementResize: true,
    getHeight: function () {
      return window.innerHeight - 250;
    },
  })(AccountingVatViewV2)
);
