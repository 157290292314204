import React, { useState } from "react";

import AccountingSetupContainer from "./AccountingSetupContainer";
import AccountPlanConsumer from "../AccountPlan/AccountPlanConsumer";

const SetupTypes = {
  FISCALYEARS: 0,
  ACCOUNTPLAN: 1,
};

const AccountingSetupMainContainer = (props) => {
  const [activeType, setActiveType] = useState(SetupTypes.FISCALYEARS);

  switch (activeType) {
    case SetupTypes.FISCALYEARS:
      return (
        <AccountingSetupContainer
          toggleView={() => setActiveType(SetupTypes.ACCOUNTPLAN)}
          {...props}
        />
      );
    case SetupTypes.ACCOUNTPLAN:
      return (
        <AccountPlanConsumer
          toggleView={() => setActiveType(SetupTypes.FISCALYEARS)}
          {...props}
        />
      );
    default:
      return <div></div>;
  }
};

export default React.memo(AccountingSetupMainContainer);
