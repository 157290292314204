import React from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import InboxItem from "../Email/InboxItem";
import LabelNavItem from "./LabelNavItem";
import LabelModal from "./LabelModal";
import { AxoListGroup, AxoListItem } from "../../utilities/AxoListGroup";
import { withRouter } from "react-router-dom";
import moment from "moment";

import {
  Row,
  Col,
  Grid,
  Table,
  FormGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import {
  MessageService,
  RoutingService,
  DataActions,
  DataStore,
  ModalService,
  AuthorizationService,
} from "../../services/AxoServices";

import {
  Icon,
  LexButton,
  AxoLocal,
  FileViewerModal,
  Dimensions,
  getText,
} from "../../utilities/LexUtilities";

import ClientLabelTable from "./ClientLabelTable";

class LabelInbox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      showLabelItems: true,
    };
  }

  // onMailSelected = (selectedMessage, select) => {
  //   var selected = this.state.selected;
  //   if(select) {
  //     selected.push(selectedMessage);
  //   }
  //   else {
  //     selected = selected.filter( message => message.id !== selectedMessage.id);
  //   }
  //   this.setState({selected: selected});
  // }

  onAddCases = (e, data) => {
    this.props.history.push(RoutingService.getPath("Cases"));
  };

  onAddDocuments = (e, data) => {
    this.props.history.push(RoutingService.getPath("Documents"));
  };

  renderAddLabelsItem = (label) => {
    if (this.props.messages) {
      return (
        <MenuItem onClick={this.onAddMails} data={{ label: label }}>
          <AxoLocal
            entity="LabelInboxonAddMails"
            defaultValue={"Tilføj mails til mapper"}
          />
        </MenuItem>
      );
    } else if (this.props.cases) {
      return (
        <MenuItem onClick={this.onAddCases} data={{ label: label }}>
          <AxoLocal
            entity="LabelInboxonAddCases"
            defaultValue={"Tilføj sager til mapper"}
          />
        </MenuItem>
      );
    } else if (this.props.documents) {
      return (
        <MenuItem onClick={this.onAddDocuments} data={{ label: label }}>
          <AxoLocal
            entity="LabelInboxonAddDocuments"
            defaultValue={"Tilføj dokumenter til mapper"}
          />
        </MenuItem>
      );
    }
    return [];
  };

  renderAccordionHeader = (title) => {
    return (
      <div>
        <Col xs={11} style={{ padding: "0px" }}>
          <span>{title}</span>
        </Col>
        <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
          <span style={{ textAlign: "right" }}>
            <Icon glyph="icon-fontello-arrow-combo" />
          </span>
        </Col>
      </div>
    );
  };

  toggleShowLabels = () => {
    this.setState({ showLabelItems: !this.state.showLabelItems });
  };

  onDeleteMail = (messageId, event) => {
    let { updateMessageFromHeader } = this.props;
    event.preventDefault();
    event.stopPropagation();

    var message = this.props.messages.find((m) => m.id === messageId);

    updateMessageFromHeader({
      ...message,
      trashed: true,
    });
  };

  onRenameLabel = (e, data) => {
    this.labelModal.setEdit(data.label).open();
  };

  onDeleteLabel = (e, data) => {
    ModalService.openConfirmModal(
      <span>
        ({data.label.name}){" "}
        <AxoLocal
          entity="ThrashEntriesViewDeletePermanently"
          defaultValue={"Slet permanent?"}
        />
      </span>,
      (value) => {
        if (value) {
          DataActions.deleteLabel(data.label.id);
        }
      }
    );
  };

  onAddMails = (e, data) => {
    this.props.history.push(RoutingService.getPath("mailbox/inbox"));
  };

  componentDidMount() {
    var labels = this.props.labels;
    if (
      labels.length > 0 &&
      !labels.find((l) => l.id === this.props.selectedLabelId)
    ) {
      this.props.onSelectLabel(labels[0].id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.labels.length === 0 && nextProps.labels.length > 0) {
      if (!nextProps.labels.find((l) => l.id === this.props.selectedLabelId)) {
        this.props.onSelectLabel(nextProps.labels[0].id);
      }
    }
  }

  renderLeftPanel() {
    var {
      labels,
      labelType,
      newLabelName,
      updateNewLabelName,
      onLabelCreate,
      containerHeight,
    } = this.props;

    var optionItems = labels.map((label) => {
      return (
        <AxoListItem
          key={label.id}
          active={label.id === this.props.selectedLabelId}
          onClick={this.props.onSelectLabel.bind(this, label.id)}
        >
          <LabelNavItem
            label={label}
            glyph="icon-simple-line-icons-folder-alt"
          />
        </AxoListItem>
      );
    });
    var contextMenus = labels.map((label) => {
      var addLabelsItem = this.renderAddLabelsItem(label);
      return (
        <ContextMenu key={label.id} id={"label-context-menu-" + label.id}>
          <MenuItem onClick={this.onRenameLabel} data={{ label: label }}>
            <AxoLocal
              entity="LabelInboxonRenameLabel"
              defaultValue={"Omdøb mappe"}
            />
          </MenuItem>
          <MenuItem onClick={this.onDeleteLabel} data={{ label: label }}>
            <AxoLocal
              entity="LabelInboxDeleteLabel"
              defaultValue={"Slet mappe"}
            />
          </MenuItem>
          {addLabelsItem}
        </ContextMenu>
      );
    });
    return (
      <div className="topPadding">
        {/* <h5 style={{paddingLeft: '25px'}}><AxoLocal entity='LabelInboxPanelLabel' defaultValue={'Mapper'}/></h5> */}
        <FormGroup>
          <InputGroup>
            <AxoLocal
              componentClass={FormControl}
              value={newLabelName}
              onChange={updateNewLabelName}
              type="text"
              componentAttribute="placeholder"
              entity="placeholderEnterthefoldername"
            />
            <InputGroup.Addon style={{ padding: "0px" }}>
              &nbsp;
              <LexButton onClick={() => onLabelCreate(labelType)}>
                Opret
              </LexButton>
            </InputGroup.Addon>
          </InputGroup>
        </FormGroup>
        <AxoListGroup
          style={{ height: containerHeight - 215, overflowY: "scroll" }}
        >
          {optionItems}
        </AxoListGroup>
        {contextMenus}
      </div>
    );
  }

  renderContentNodes = () => {
    if (this.props.messages) {
      return this.renderMessageNodes();
    } else if (this.props.cases) {
      return this.renderCaseNodes();
    } else if (this.props.documents) {
      return this.renderDocumentNodes();
    } else if (this.props.clients) {
      return this.renderClientNodes();
    }
    return [];
  };

  onRemoveMail = (messageId, labelId, event) => {
    event.preventDefault();
    event.stopPropagation();
    DataActions.removeLabelFromMessage(messageId, labelId);
  };

  onRemoveCase = (caseId, labelId) => {
    //Optimistic updating
    var label = Object.assign(
      {},
      this.props.labels.find((l) => l.id === labelId)
    );
    label.caseIds = label.caseIds.filter((id) => id !== caseId);
    DataStore.updateArrayObject(label, "labels");

    DataActions.removeLabelFromCase(caseId, labelId);
  };

  onDeleteCase = (caseModelId) => {
    var caseModel = Object.assign(
      {},
      this.props.cases.find((caseModel) => caseModel.id === caseModelId)
    );
    caseModel.trashed = true;
    DataActions.updateCaseFromHeader(caseModel);
  };

  onRemoveDocument = (documentId, labelId) => {
    DataActions.removeLabelFromFile(documentId, labelId);
  };

  onTrashDocument = (documentId) => {
    var userProfile = this.props.userProfile;
    if (
      (userProfile.profileImage &&
        userProfile.profileImage.id === documentId) ||
      (userProfile.logo && userProfile.logo.id === documentId)
    ) {
      ModalService.openConfirmModal(
        <AxoLocal
          entity="LabelInboxDeleteLabelConfirm"
          defaultValue={
            "Billedet anvendes som logo eller profilbillede. Er du sikker på at du vil slette det?"
          }
        />,
        (reply) => {
          if (reply) {
            this.removeImageReference(userProfile, documentId);
            this.doTrashFile(documentId);
          }
        }
      );
    } else {
      this.doTrashFile(documentId);
    }
  };

  removeImageReference = (userProfile, documentId) => {
    if (userProfile.logo && userProfile.logo.id === documentId) {
      userProfile.logo = null;
    }
    if (
      userProfile.profileImage &&
      userProfile.profileImage.id === documentId
    ) {
      userProfile.profileImage = null;
    }
    DataActions.updateUserProfile(userProfile);
  };

  doTrashFile = (documentId) => {
    let doc = { ...this.props.documents.find((d) => d.id === documentId) };
    doc.trashed = true;
    DataActions.updateDocument(doc).then(() => {
      DataStore.fetchLabels();
    });
  };

  onRemoveClient = async (clientId, labelId) => {
    let { refetchContacts } = this.props;

    await DataActions.removeLabelFromClient(clientId, labelId);
    refetchContacts();
  };

  onDeleteClient = (clientId) => {
    let { updateContact } = this.props;

    let client = Object.assign(
      {},
      this.props.clients.find((c) => c.id === clientId)
    );
    updateContact({
      ...client,
      trashed: false,
    });
  };

  renderMessageNodes = () => {
    var { selectedLabelId } = this.props;

    var messageNodes = this.props.messages.map((message) => {
      // var isSelected = this.state.selected.find( currentMessage => currentMessage.id === message.id) !== undefined;
      return (
        <InboxItem
          key={message.id}
          message={message}
          userProfile={this.props.userProfile}
          src="/imgs/app/avatars/avatar111-17.png"
          imgClass=""
          labelValue={<AxoLocal entity="axoidcode151" defaultValue={"Emne:"} />}
          labelClass="axoblue"
          showImportant={this.props.match.params.shown < 4}
          shown={"label-" + selectedLabelId}
          date={message.date}
          // selected={isSelected}
          // onSelect={this.onMailSelected}
          onRemove={this.onRemoveMail.bind(this, message.id, selectedLabelId)}
          onDelete={this.onDeleteMail.bind(this, message.id)}
        />
      );
    });
    return messageNodes;
  };

  goToCase = (caseId, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.history.push(RoutingService.getPath("Cases/case/" + caseId));
  };

  renderCaseNodes = () => {
    var { selectedLabelId } = this.props;

    var caseNodes = this.props.cases.map((caseModel) => {
      var clientString =
        caseModel.clients.length > 0 ? caseModel.clients[0].lastName : "";
      return (
        <tr key={caseModel.id}>
          <td>
            <a href="case" onClick={this.goToCase.bind(this, caseModel.id)}>
              {caseModel.caseNumber}
            </a>
          </td>
          <td>{caseModel.subject}</td>
          <td>{caseModel.type}</td>
          <td>{clientString}</td>
          <td>{moment(caseModel.creationDate).format("L LT")}</td>
          <td>
            <Icon
              title={getText("axoEntityidcode264", "Fjern sag fra mappe")}
              className="editable"
              role="button"
              onClick={this.onRemoveCase.bind(
                this,
                caseModel.id,
                selectedLabelId
              )}
              glyph="icon-fontello-scissors axored"
            />
            &ensp;&ensp;&ensp;
            <Icon
              className="editable"
              role="button"
              onClick={this.onDeleteCase.bind(this, caseModel.id)}
              glyph="icon-fontello-trash-1"
            />
          </td>
        </tr>
      );
    });
    return (
      <div className="table-responsive">
        <Table className="table ">
          <thead>
            <tr>
              <th>
                <AxoLocal
                  entity="ContainercaseNumber"
                  defaultValue={"Sagsnummer"}
                />
              </th>
              <th>
                <AxoLocal
                  entity="CaseDataTableFixedsubject"
                  defaultValue={"Emneord"}
                />
              </th>
              <th>
                <AxoLocal
                  entity="CaseEditInlineonCasePropertyChange"
                  defaultValue={"Retsinstans"}
                />
              </th>
              <th>
                <AxoLocal
                  entity="CaseEditFormclients"
                  defaultValue={"Klient"}
                />
              </th>
              <th>
                <AxoLocal
                  entity="CaseDataTableFixedcreationDate"
                  defaultValue={"Oprettelse"}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>{caseNodes}</tbody>
        </Table>
      </div>
    );
  };

  onOpenFile = (document, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.fileModal.open([document]);
  };

  renderDocumentNodes = () => {
    var { selectedLabelId } = this.props;

    var documentNodes = this.props.documents.map((document) => {
      var caseString = "";
      document.cases.forEach((caseModel) => {
        document.caseString = caseModel.caseNumber + ",";
      });
      return (
        <tr key={document.id} className={document.id} id={document.id}>
          <td>
            <a href="OpenFile" onClick={this.onOpenFile.bind(this, document)}>
              {document.fileName}
            </a>
          </td>
          <td>{moment(document.uploadDate).format("L")}</td>
          <td>{caseString}</td>
          <td>
            <Icon
              className="editable"
              role="button"
              onClick={this.onSendDocument.bind(this, document.id)}
              glyph="icon-fontello-mail-1"
            />
            &ensp;&ensp;&ensp; &ensp;&ensp;&ensp;
            <Icon
              title={getText("axoEntityidcode262", "Fjern dokument fra mappe")}
              className="editable"
              role="button"
              onClick={this.onRemoveDocument.bind(
                this,
                document.id,
                selectedLabelId
              )}
              glyph="icon-fontello-scissors"
            />
            &ensp;&ensp;&ensp; &ensp;&ensp;&ensp;
            <Icon
              title={getText("axoEntityidcode263", "Slet dokument")}
              className="editable"
              role="button"
              onClick={this.onTrashDocument.bind(this, document.id)}
              glyph="icon-fontello-trash-1"
            />
          </td>
        </tr>
      );
    });
    return (
      <div className="table-responsive">
        <Table
          ref={(c) => (this.example = c)}
          id="docTable"
          className="display table axotabletextlebelinbox"
          cellSpacing="0"
          width="100%"
        >
          <thead>
            <tr>
              <th>
                <AxoLocal
                  entity="CaseDataTableFixedFilename"
                  defaultValue={"Filnavn"}
                />{" "}
              </th>
              <th>
                <AxoLocal
                  entity="DocumentGridViewuploadDate"
                  defaultValue={"Upload dato"}
                />
              </th>
              <th>
                <AxoLocal
                  entity="LabelInboxdocTableCase"
                  defaultValue={"Sag"}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>
                <AxoLocal
                  entity="CaseDataTableFixedFilename"
                  defaultValue={"Filnavn"}
                />
              </th>
              <th>
                <AxoLocal
                  entity="DocumentGridViewuploadDate"
                  defaultValue={"Upload dato"}
                />
              </th>
              <th>
                <AxoLocal
                  entity="LabelInboxdocTableCase"
                  defaultValue={"Sag"}
                />
              </th>
              <th></th>
            </tr>
          </tfoot>
          <tbody>{documentNodes}</tbody>
        </Table>
      </div>
    );
  };

  onSendDocument = (documentId) => {
    let { documents } = this.props;
    let doc = documents.find((d) => d.id === documentId);
    if (!doc) {
      return;
    }

    DataStore.setCachedMessage(MessageService.makeAttachmentMessage([doc]));
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  renderClientNodes = () => {
    var {
      selectedLabelId,
      clients,
      containerHeight,
      userProfile,
      userSettings,
      labels,
      adminCodeValidated,
    } = this.props;

    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <div className="topPadding" style={{ height: containerHeight - 150 }}>
        <ClientLabelTable
          key={selectedLabelId}
          entries={clients}
          labels={labels}
          userType={userType}
          userSettings={userSettings}
          selectedLabelId={selectedLabelId}
          adminCodeValidated={adminCodeValidated}
          onRemoveClient={this.onRemoveClient}
          onDeleteClient={this.onDeleteClient}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col sm={5} md={4} lg={3}>
              {this.renderLeftPanel()}
            </Col>
            <Col sm={7} md={8} lg={9}>
              {this.renderContentNodes()}
            </Col>
          </Row>
        </Grid>
        <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
        <LabelModal
          labels={this.props.labels}
          labelType={this.props.labelType}
          ref={(c) => (this.labelModal = c)}
        />
      </div>
    );
  }
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight;
    },
  })(LabelInbox)
);
