//HOC for using RTK Query hooks in class componennts
import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import DataStore from "../../services/DataAccess/DataStore";

import {
  useGetCasesQuery,
  useGetCaseModelQuery,
  useRefetchCasesMutation,
  useRefetchCaseModelMutation,
  useUpdateCaseMapStateMutation,
  useUpdateCaseModelStateMutation,
} from "../../services/DataAccess/ApiSlice";

let caseModelId, setCaseModelId;
let caseQueryText, setCaseQueryText;

export default function withRTKCaseData(WrappedComponent) {
  return function (props) {
    let store = DataStore.getStore();
    [caseQueryText, setCaseQueryText] = useState("");
    // const { data, error, isLoading } = useGetContactsQuery(DataStore.getStore().contactQuery);
    const caseQuery = useGetCasesQuery({
      pageSize: store.casePageSize,
      query: caseQueryText,
    });

    [caseModelId, setCaseModelId] = useState(0);
    let caseModelQuery = useGetCaseModelQuery(caseModelId, {
      skip: caseModelId === 0,
    });

    let { isFetching, isSuccess } = caseQuery;

    useEffect(() => {
      if (!isFetching && isSuccess) {
        DataStore.setState({
          caseMap: caseQuery.data,
        });
      }
    }, [isFetching, caseQueryText]);

    const [refetchCases, { isLoading: isRefetchingCases }] =
      useRefetchCasesMutation();

    const [
      refetchCaseModel, // This is the mutation trigger
      { isLoading: isRefetchingCaseModel }, // This is the destructured mutation result
    ] = useRefetchCaseModelMutation();

    const [
      updateCaseMapState, // This is the mutation trigger
      { isLoading: isUpdatingCaseMap }, // This is the destructured mutation result
    ] = useUpdateCaseMapStateMutation();

    const [
      updateCaseModelState, // This is the mutation trigger
      { isLoading: isUpdatingCaseModelState }, // This is the destructured mutation result
    ] = useUpdateCaseModelStateMutation();

    return (
      <WrappedComponent
        caseQuery={caseQuery}
        caseMap={
          caseQuery.date || {
            cases: [],
            saved: [],
            savedCount: 0,
            drafts: [],
            draftCount: 0,
            closed: [],
            closedCount: 0,
            trashed: [],
            trashedCount: 0,
          }
        }
        caseQueryText={caseQueryText}
        setCaseQueryText={setCaseQueryText}
        caseModelQuery={caseModelQuery}
        singleCaseModel={caseModelQuery.data || {}}
        caseModelId={caseModelId}
        setCaseModelId={setCaseModelId}
        refetchCases={refetchCases}
        isRefetchingCases={isRefetchingCases}
        refetchCaseModel={refetchCaseModel}
        isRefetchingCaseModel={isRefetchingCaseModel}
        updateCaseMapState={updateCaseMapState}
        isUpdatingCaseMap={isUpdatingCaseMap}
        updateCaseModelState={updateCaseModelState}
        isUpdatingCaseModelState={isUpdatingCaseModelState}
        {...props}
      />
    );
  };
}
