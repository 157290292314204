// StandardTexts
const boardStatement = `<!DOCTYPE html>
<html>
<head>
</head>
<body>
<p style="text-align: center;"><strong>Ledelsesp&aring;tegning</strong></p>
<p style="text-align: center;"><strong>&nbsp;</strong></p>
<p style="text-align: center;"><strong>&nbsp;</strong></p>
<p>Ledelsen har dags dato behandlet og godkendt &aring;rsrapporten for regnskabsperioden STARTDATE - ENDDATE for COMPANYNAME.</p>
<p>&Aring;rsrapporten afl&aelig;gges i overensstemmelse med &aring;rsregnskabsloven.</p>
<p>Det er ledelsens opfattelse, at &aring;rsregnskabet giver et retvisende billede af virksomhedens aktiver, passiver og finansielle stilling samt af resultatet.</p>
<p>&Aring;rsrapporten indstilles til generalforsamlingens godkendelse.</p>
<p>Ledelsen anser betingelserne for at udelade revision for opfyldt.</p>
<p>CITYNAME, den CURRENTDATE</p>
<p><strong>Direktion</strong></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>DIRECTORNAME</p>
<p>Direkt&oslash;r</p>
</body>
</html>`;

const boardReport = `<!DOCTYPE html>
<html>
<head></style>
</head>
<body>
<p style="text-align: center;"><strong>Ledelsesberetning</strong></p>
<p><strong></strong></p>
<p><strong></strong></p>
<p><strong>Hovedaktiviteter</strong></p>
<p>Selskabets aktivitet best&aring; af at COMPANYACTIVITY</p>
<p><strong>Usikkerhed ved indregning eller m&aring;ling. </strong></p>
<p>Der er ingen v&aelig;sentlige usikkerhed ved indregning eller m&aring;ling.</p>
<p><strong>Us&aelig;dvanligt forhold </strong></p>
<p>Der er ingen us&aelig;dvanligt forhold, som har p&aring;virket indregning eller m&aring;ling.</p>
<p><strong>Udviklingen i aktiviteter og &oslash;konomiske forhold</strong></p>
<p>Selskabets resultatopg&oslash;relse for &aring;rsregnskabet STARTDATE til ENDDATE udviser et resultat p&aring; kr. FINALRESULT og selskabet balance pr. ENDDATE udviser en balancesum p&aring; kr. FINALBALANCE og egenkapital p&aring; kr. FINALCAPITAL.</p>
<p>Ledelsen anser &aring;rets resultatet for at v&aelig;re tilfredsstillende.</p>
<p><strong>Begivenheder efter regnskabets afslutning</strong></p>
<p>Efter regnskabs&aring;rets afslutning er der ikke indtruffet begivenheder, som v&aelig;sentligt vil kunne p&aring;virkevirksomhedens finansielle stilling.</p>
<p><strong>Forventninger til fremtiden</strong></p>
<p><span>Der forventes en fortsat positiv udvikling i selskabets aktiviteter og resultat i det kommende &aring;r. </span></p>
</body>
</html>`;

const accountantStatement = `<!DOCTYPE html>
<html>
<head>
</head>
<body>
<p style="text-align: center;"><strong>Revisor Assistance </strong></p>
<p></p>
<p></p>
<p>Vi har ydet assistance ved udarbejdelse af &aring;rsregnskab for YEAR for COMPANYNAME.</p>
<p>&Aring;rsregnskabet er udarbejdet p&aring; grundlag af forlagt bilag og meddelte oplysninger i &oslash;vrigt.</p>
<p>Kontering af kassekladde og bogf&oslash;ring er foretaget af os.</p>
<p>Det udf&oslash;rte arbejde har ikke omfattet unders&oslash;gelser vedr&oslash;rende pants&aelig;tninger, kautions- eller garantiforpligtelser.</p>
<p>Virksomhedens driftsresultat kr. FINALRESULT</p>
<p>Virksomhedens balancesum kr. FINALBALANCE</p>
<p>&nbsp;</p>
<p>ACCOUNTANTCITY, den CURRENTDATE</p>
<p>_______________________</p>
<p>ACCOUNTANTNAME</p>
</body>
</html>`;

const accountingPolicy = `<!DOCTYPE html>
<html>
<head>
</head>
<body>
<p><strong>Anvendt regnskabspraksis </strong></p>
<p><strong><span>Regnskabsklasse</span></strong></p>
<p><span>&Aring;rsrapporten for COMPANYNAME for YEAR er aflagt i overensstemmelse med &aring;rsregnskabslovens bestemmelser for virksomheder i regnskabsklasse B. </span></p>
<p><span>Virksomheden afl&aelig;gger &aring;rsrapport efter regnskabsklasse B. </span></p>
<p><span>Den anvendte regnskabspraksis er u&aelig;ndret i forhold til tidligere &aring;r. </span></p>
<p><strong><span>Rapporteringsvaluta </span></strong></p>
<p><span>&Aring;rsrapporten er aflagt i danske kroner. </span></p>
<p><strong><span>Omregning af fremmed valuta</span></strong></p>
<p><span>Transaktioner i fremmed valuta omregnes til danske kroner efter transaktionsdagens kurs. Monet&aelig;re aktiver og forpligtelser i fremmed valuta omregnes til danske kroner efter balancedagens valutakurser. Realiserede og urealiserede valutakursgevinster og -tab indg&aring;r i resultatopg&oslash;relsen under finansielle poster. </span></p>
<p><strong><span>Generelt </span></strong></p>
<p><strong><span>Generelt om indregning og m&aring;ling </span></strong></p>
<p><span>Indt&aelig;gter indregnes i resultatopg&oslash;relsen i takt med, at de indtjenes, herunder indregnes v&aelig;rdireguleringer af finansielle aktiver og forpligtelser, der m&aring;les til dagsv&aelig;rdi eller amortiseret kostpris. Endvidere indregnes i resultatopg&oslash;relsen alle omkostninger, der er afholdt for at opn&aring; &aring;rets indtjening, herunder afskrivninger, nedskrivninger og hensatte forpligtelser samt tilbagef&oslash;rsler som f&oslash;lge af &aelig;ndrede regnskabsm&aelig;ssige sk&oslash;n af bel&oslash;b, der tidligere har v&aelig;ret indregnet i resultatopg&oslash;relsen.</span></p>
<p><span>Aktiver indregnes i balancen, n&aring;r det er sandsynligt, at fremtidige &oslash;konomiske fordele vil tilflyde selskabet, og aktivets v&aelig;rdi kan m&aring;les p&aring;lideligt. </span></p>
<p><span>Forpligtelser indregnes i balancen, n&aring;r det er sandsynligt, at fremtidige &oslash;konomiske fordele vil frag&aring; selskabet, og forpligtelsens v&aelig;rdi kan m&aring;les p&aring;lideligt. </span></p>
<p><span>Ved f&oslash;rste indregning m&aring;les aktiver og forpligtelser til kostpris. Efterf&oslash;lgende m&aring;les aktiver og forpligtelser som beskrevet for hver enkelt regnskabspost nedenfor. </span></p>
<p><span>Visse finansielle aktiver og forpligtelser m&aring;les til amortiseret kostpris, hvorved der indregnes en konstant effektiv rente over l&oslash;betiden. Amortiseret kostpris opg&oslash;res som oprindelig kostpris med fradrag af afdrag og till&aelig;g/fradrag af den akkumulerede afskrivning af forskellen mellem kostprisen og det nominelle bel&oslash;b. Herved fordeles kurstab og -gevinst over l&oslash;betiden. </span></p>
<p><span>Ved indregning og m&aring;ling tages hensyn til forudsigelige tab og risici, der fremkommer, inden &aring;rsrapporten afl&aelig;gges, og som be- eller afkr&aelig;fter forhold, der eksisterer p&aring; balancedagen.</span></p>
<p><strong><span>Resultatopg&oslash;relsen </span></strong></p>
<p><strong><span>Bruttofortjeneste og -tab</span></strong></p>
<p><span>Virksomheden har valgt at sammendrage visse poster i resultatopg&oslash;relsen efter bestemmelserne i &aring;rsregnskabslovens &sect; 32. </span></p>
<p><strong><span>Nettooms&aelig;tning</span></strong></p>
<p><span>Indt&aelig;gter ved levering af serviceydelser indregnes som oms&aelig;tning i takt med leveringen af ydelsen.</span></p>
<p><strong><span>Personaleomkostninger</span></strong></p>
<p><span>Personaleomkostninger omfatter l&oslash;nninger og gager, pensioner samt omkostninger til social sikring. Andre personaleomkostninger indregnes under andre eksterne omkostninger. </span></p>
<p><strong><span>Af- og nedskrivninger p&aring; immaterielle og materielle anl&aelig;gsaktiver</span></strong></p>
<p><span>Af- og nedskrivninger p&aring; immaterielle og materielle anl&aelig;gsaktiver er foretaget ud fra en l&oslash;bende vurdering af aktivernes brugstid i virksomheden. Anl&aelig;gsaktiverne afskrives line&aelig;rt p&aring; grundlag af kostprisen, baseret p&aring; f&oslash;lgende vurdering af brugstider og restv&aelig;rdier: </span></p>
<p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Brugstid &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Restv&aelig;rdi</span></p>
<p><span>Bygninger&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 50 &aring;r&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 0%</span></p>
<p><span>Andre anl&aelig;g, driftsmateriel og inventar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3-10 &aring;r 0%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
<p><span>&nbsp;</span></p>
<p><span>Der afskrives ikke p&aring; grunde.</span></p>
<p><strong><span>Skat af &aring;rets resultat</span></strong></p>
<p><span>Skat af &aring;rets resultat omfatter aktuel skat af &aring;rets forventede skattepligtige indkomst og &aring;rets regulering af udskudt skat med fradrag af den del af &aring;rets skat, der vedr&oslash;rer egenkapitalbev&aelig;gelser. Aktuel og udskudt skat vedr&oslash;rende egenkapitalbev&aelig;gelser indregnes direkte i egenkapitalen. Selskabet og de danske tilknyttede virksomheder er sambeskattede. Den danske selskabsskat fordeles mellem overskuds- og underskudsgivende danske selskaber i forhold til disses skattepligtige indkomst (fuld fordeling).</span></p>
<p><strong><span>Balancen</span></strong></p>
<p><strong><span>Materielle anl&aelig;gsaktiver</span></strong></p>
<p><span>Materielle anl&aelig;gsaktiver m&aring;les til kostpris med till&aelig;g af eventuelle opskrivninger og med fradrag af akkumulerede af- og nedskrivninger. Kostpris omfatter anskaffelsesprisen og omkostninger direkte tilknyttet anskaffelsen indtil det tidspunkt, hvor aktivet er klart til at blive taget i brug.</span></p>
<p><strong><span>Tilgodehavender </span></strong></p>
<p><span>Tilgodehavender m&aring;les til amortiseret kostpris, der almindeligvis svarer til nominel v&aelig;rdi. V&aelig;rdien reduceres med nedskrivninger til im&oslash;deg&aring;else af forventede tab.</span></p>
<p><strong><span>Periodeafgr&aelig;nsningsposter, aktiver</span></strong></p>
<p><span>Periodeafgr&aelig;nsningsposter indregnet under aktiver omfatter forudbetalte omkostninger vedr&oslash;rende efterf&oslash;lgende regnskabs&aring;r.</span></p>
<p><strong><span>Likvider </span></strong></p>
<p><span>Likvider omfatter bankindst&aring;ende</span></p>
<p><strong><span>Egenkapital </span></strong></p>
<p><span>Udbytte, som foresl&aring;s udbetalt for regnskabs&aring;ret, pr&aelig;senteres som en s&aelig;rskilt post under egenkapitalen.</span></p>
<p><strong><span>Hensatte forpligtelser </span></strong></p>
<p><strong><span>Udskudt skat </span></strong></p>
<p><span>Udskudt skat og &aring;rets regulering heraf opg&oslash;res efter den balanceorienterede g&aelig;ldsmetode som skattev&aelig;rdien af alle midlertidige forskelle mellem regnskabsm&aelig;ssige og skattem&aelig;ssige v&aelig;rdier af aktiver og forpligtelser. Udskudte skatteaktiver, herunder skattev&aelig;rdien af fremf&oslash;rselsberettigede skattem&aelig;ssige underskud, indregnes med den v&aelig;rdi, hvortil de forventes at blive anvendt, enten ved udligning i skat af fremtidig indtjening eller ved modregning i udskudte skatteforpligtelser i virksomheder inden for samme juridiske skatteenhed og jurisdiktion. Udskudt skat m&aring;les p&aring; grundlag af de skatteregler og skattesatser, der med balancedagens lovgivning vil v&aelig;re g&aelig;ldende, n&aring;r den udskudte skat forventes udl&oslash;st som aktuel skat.</span></p>
<p><strong><span>Finansielle g&aelig;ldsforpligtelser </span></strong></p>
<p><span>G&aelig;ldsforpligtelser m&aring;les til amortiseret kostpris, hvilket almindeligvis svarer til nominel v&aelig;rdi.</span></p>
<p><strong><span>Aktuelle skatteforpligtelser </span></strong></p>
<p><span>Aktuelle skatteforpligtelser og tilgodehavende aktuel skat indregnes i balancen som beregnet skat af &aring;rets forventede skattepligtige indkomst, reguleret for skat af tidligere &aring;rs skattepligtige indkomster samt betalte acontoskatter.</span></p>
<p><strong><span>Eventualaktiver og -forpligtelser</span></strong></p>
<p><span>Eventualaktiver og -forpligtelser indregnes ikke i balancen, men oplyses alene i noterne.</span></p>
<p><strong><span>Forklaring af n&oslash;gletal</span></strong></p>
<p><span>Hoved- og n&oslash;gletal er opgjort efter Finansanalytikerforeningens "Anbefalinger og N&oslash;gletal".</span></p>
<p><span>Likviditetsgrad &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; =&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u> Oms&aelig;tningsaktiver i alt_</u></span></p>
<p style="text-align: left;"><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Kortfristede forpligtelser </span></p>
<p><span>Soliditetsgrad&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; = &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Egenkapital i alt X 100____</u> </span></p>
<p><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Passiver i alt </span></p>
<p><span>Egenkapitalens forrentning = &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>&Aring;rets resultat X 100</u>______</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Gennemsnitlig egenkapital&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
</body>
</html>`;

const notesText = `<!DOCTYPE html>
<html>
<head>
</head>
<body>
<p><strong>Noter</strong></p>
<p></p>
</body>
</html>`;

export default {
  boardStatement,
  boardReport,
  accountantStatement,
  accountingPolicy,
  notesText,
};
