import React from "react";
import FrontPageView from "./FrontPageView";
import { Route, Switch } from "react-router-dom";
// import Signup from '../Login/Signup'
// import SignupAccountantContainer from '../Login/SignupAccountantContainer'
// import GroupLogin from '../Login/GroupLogin'
import FrontPageIndex from "./FrontPageIndex";
import SolutionsContainer from "./SolutionsContainer";
import CompanyContainer from "./CompanyContainer";
import PackagesContainer from "./PackagesContainer";
import IndividualPackage from "./IndividualPackage";
// import Login from '../Login/Login'
// import ForgotPassword from '../Login/ForgotPassword'
// import ResetPassword from '../Login/ResetPassword'
// import ResetPasswordConfirmation from '../Login/ResetPasswordConfirmation'
// import SubscribeContainer from '../Login/SubscribeContainer'
// import AccessDenied from '../Login/AccessDenied'
// import RequestConfirmEmail from '../Login/RequestConfirmEmail'
// import ConfirmEmail from '../Login/ConfirmEmail'
import PaymentDemoView from "../demoPages/PaymentDemoView";
import SocietyPaymentDemoView from "../demoPages/SocietyPaymentDemoView";
import PricingDemoView from "../demoPages/PricingDemoView";
// import ApplicationUDContainer from '../routes/ApplicationUD/ApplicationUDContainer'

import {
  DataStore,
  RoutingService,
  ThemeService,
} from "../services/AxoServices";

import { LoadingIndicator, Consumer } from "../utilities/LexUtilities";

class FrontPageContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.initializeUserSettings();
    DataStore.initializeUserProfile();
    DataStore.fetchGlobalSettings();

    RoutingService.setAccountRootPath("/Users");
  }

  componentDidUpdate(prevProps) {
    let { globalSettings } = this.props;
    if (!!globalSettings.themeFront) {
      ThemeService.setThemeStyle(globalSettings.themeFront);
    }
  }

  render() {
    let { loaded, globalSettings, userSettings, userProfile, match } =
      this.props;

    if (!loaded || !globalSettings.theme) {
      return <LoadingIndicator />;
    }
    return (
      <FrontPageView
        userSettings={userSettings}
        userProfile={userProfile}
        match={match}
      >
        {this.props.children}
      </FrontPageView>
    );
  }
}

class FrontPageConsumer extends React.PureComponent {
  render() {
    let { match } = this.props;

    return (
      <Consumer>
        {(store) => {
          return (
            <FrontPageContainer
              loaded={store.loaded}
              globalSettings={store.globalSettings}
              userSettings={store.userSettings}
              userProfile={store.userProfile}
              match={match}
            >
              <Switch>
                <Route
                  path={match.path + "/Solutions"}
                  component={SolutionsContainer}
                />
                <Route
                  path={match.path + "/Company"}
                  component={CompanyContainer}
                />
                <Route
                  path={match.path + "/Packages"}
                  component={PackagesContainer}
                />
                <Route
                  path={match.path + "/IndividualPackage"}
                  component={IndividualPackage}
                />
                {/* <Route path='/Users/Login' component={Login}/>
                <Route path='/Users/Signup' component={Signup}/>
                <Route path='/Users/SignupAccountant' component={SignupAccountantContainer}/>
                <Route path='/Users/ForgotPassword' component={ForgotPassword}/>
                <Route path='/Users/ResetPassword' component={ResetPassword}/>
                <Route path='/Users/ResetPasswordConfirmation' component={ResetPasswordConfirmation}/>
                <Route path='/Users/Subscribe' component={SubscribeContainer}/>
                <Route path='/Users/AccessDenied' component={AccessDenied}/>
                <Route path='/Users/RequestConfirmEmail' component={RequestConfirmEmail}/>
                <Route path='/Users/ConfirmEmail' component={ConfirmEmail}/>
                <Route path='/Users/GroupLogin' component={GroupLogin}/> */}
                <Route
                  path={match.path + "/PaymentDemo"}
                  component={PaymentDemoView}
                />
                <Route
                  path={match.path + "/SocietyPayment"}
                  component={SocietyPaymentDemoView}
                />
                <Route
                  path={match.path + "/Pricing"}
                  component={PricingDemoView}
                />
                <Route path="/" component={FrontPageIndex} />
                {/* <Route path='/ApplicationUD' component={ApplicationUDContainer} /> */}
              </Switch>
            </FrontPageContainer>
          );
        }}
      </Consumer>
    );
  }
}

export default FrontPageConsumer;
