import ApiService from "./DataAccess/ApiService";

const store = {
  accountTypes: [],
  accounts: [],
  postings: [],
  postingsPageSize: 250,
  postingsPageSizeIncrement: 250,
  selectedClient: {},
};

const dispatchers = {
  accountTypes: dispatchAccountTypes,
  accounts: dispatchAccounts,
  postings: dispatchPostings,
};

var accountTypeSubscribers = [];
function dispatchAccountTypes() {
  accountTypeSubscribers.forEach((subscription) =>
    subscription.callback(store.accountTypes)
  );
}

var accountSubscribers = [];
function dispatchAccounts() {
  accountSubscribers.forEach((subscription) =>
    subscription.callback(store.accounts)
  );
}

var postingSubscribers = [];
function dispatchPostings() {
  postingSubscribers.forEach((subscription) =>
    subscription.callback(store.postings)
  );
}

export default {
  fetchAccountTypes() {
    return ApiService.getPostingAccountTypes().then((accountTypes) => {
      store.accountTypes = accountTypes;
      dispatchAccountTypes();
    });
  },

  subscribeToAccountTypes(subscriberName, callback) {
    accountTypeSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatchAccountTypes();
  },

  unsubscribeFromAccountTypes(subscriberName) {
    accountTypeSubscribers = accountTypeSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },

  fetchAccounts() {
    return ApiService.getPostingAccounts().then((accounts) => {
      store.accounts = accounts;
      dispatchAccounts();
    });
  },

  subscribeToAccounts(subscriberName, callback) {
    accountSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatchAccounts();
  },

  unsubscribeFromAccounts(subscriberName) {
    accountSubscribers = accountSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },

  fetchPostings() {
    if (store.selectedClient.id <= 0) {
      return ApiService.getPostings(store.postingsPageSize);
    }
    return ApiService.getPostingsForClient(
      store.postingsPageSize,
      store.selectedClient.id
    ).then((postings) => {
      store.postings = postings;
      dispatchPostings();
    });
  },

  //When logged in as a client, fetch the clients postings
  fetchMyPostings() {
    return ApiService.getMyPostings(store.postingsPageSize).then((postings) => {
      store.postings = postings;
      dispatchPostings();
    });
  },

  subscribeToPostings(subscriberName, callback) {
    postingSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    dispatchPostings();
  },

  unsubscribeFromPostings(subscriberName) {
    postingSubscribers = postingSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },

  selectClient(client) {
    store.selectedClient = client;
    this.fetchPostings();
  },

  getSelectedClient() {
    return store.selectedClient;
  },

  //Dynamic functions
  updateArrayObject(newObject, typeName) {
    var currentObject = store[typeName].find((o) => o.id === newObject.id);

    //Update or insert
    if (!!currentObject) {
      Object.assign(currentObject, newObject);
    } else {
      store[typeName].push(newObject);
    }

    dispatchers[typeName]();
  },
};
