import React from "react";

import { Button, ButtonGroup, ButtonToolbar, Modal } from "react-bootstrap";

import { AxoLocal, Flexbox } from "../../utilities/LexUtilities";

import EasyIDView from "../EasyID/EasyIDView";

export default class EasyIDModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  onLogin = (userId) => {
    let { onLogin } = this.props;

    if (!!onLogin) {
      onLogin(userId);
    }

    this.setState({ showModal: false });
  };

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.close} bsSize="medium">
        <Modal.Header className="backgroundModalHeaderFooter" closeButton>
          <Modal.Title>
            {" "}
            <AxoLocal
              entity="axoEntityidcode5"
              defaultValue={"Log ind med NemID"}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flexbox justifyCenter>
            <EasyIDView onLogin={this.onLogin} />
          </Flexbox>
        </Modal.Body>
        <Modal.Footer className="backgroundModalHeaderFooter">
          <ButtonToolbar>
            <ButtonGroup>
              <Button className="Lex-button-2" onClick={this.close}>
                <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}
