import React from "react";
import { Consumer, withRTKData } from "../../../utilities/LexUtilities";

import { DataStore } from "../../../services/AxoServices";

import BookkeepingContainer from "./BookkeepingContainer";
import moment from "moment";

class BookkeepingConsumer extends React.PureComponent {
  componentDidMount() {
    DataStore.fetchFiscalYears();
    DataStore.fetchBookkeepingDraftEntries();
    DataStore.fetchClientPlan();
  }

  mapStoreToProps = (store) => {
    let allEntries = store.bookKeepingDraftEntries.filter(
      (e) => e.status !== "Posted"
    ); //Filter in case posted bank reconciliation entries are still in the cache
    let entries = allEntries;

    if (!!store.fiscalYears && store.fiscalYears.length > 0) {
      let selectedFiscalYear = store.fiscalYears.find(
        (f) => f.id === store.selectedFiscalYearId
      ) ||
        store.fiscalYears[0] || { id: 0 };
      let startDate =
        store.selectedFiscalStartDate ||
        moment.utc(selectedFiscalYear.startDate);
      let endDate =
        store.selectedFiscalEndDate || moment.utc(selectedFiscalYear.endDate);
      entries = allEntries.filter((e) => {
        let entryDate = moment.utc(e.creationDate).startOf("day");
        if (
          entryDate.isBefore(
            moment.utc(
              store.fiscalYears[store.fiscalYears.length - 1].startDate
            )
          ) ||
          entryDate.isAfter(moment.utc(store.fiscalYears[0].endDate))
        ) {
          return true; //Always show draft entries outside fiscal years range.
        }

        return (
          entryDate.isSameOrAfter(startDate) &&
          entryDate.isSameOrBefore(endDate)
        );
      });
    }

    let financeAccountMap = {};
    store.clientPlan.accounts.forEach((element) => {
      financeAccountMap[element.id] = element;
    });

    let taxSpecificationMap = {};
    store.clientPlan.taxSpecifications.forEach((element) => {
      taxSpecificationMap[element.id] = element;
    });

    let globalContactSharings = store.sharingGlobals.filter(
      (s) => s.type === 0
    ); //Clients

    let sharedWithMe =
      store.userProfile.userName !== store.selectedContact.userName;
    let sharedWithOthers =
      !sharedWithMe &&
      (globalContactSharings.length > 0 ||
        (!!store.selectedContact.singleSharings &&
          !!store.selectedContact.singleSharings.find(
            (s) => s.userProfileId !== store.userProfile.id
          )));

    let sharedClient = sharedWithMe || sharedWithOthers;

    return {
      clientPlan: store.clientPlan,
      documentMap: store.documentMap,
      draftSimulationResult: store.draftSimulationResult,
      taxSpecificationMap,
      fiscalYears: store.fiscalYears,
      userProfile: store.userProfile,
      financeAccountMap,
      entries,
      allEntries,
      importantEntryIds: store.importantEntryIds,
      showDropdowns: store.showDropdowns,
      scanReceipts: store.scanReceipts,
      splitPDF: store.splitPDF,
      autoAccounts: false,
      // calculateNumbers: store.calculateNumbers,
      calculateNumbers: false,
      selectedStartDate: store.selectedFiscalStartDate,
      selectedEndDate: store.selectedFiscalEndDate,
      selectedFiscalYearId: store.selectedFiscalYearId,
      locale: store.locale,
      sharedClient,
      actions: store.actions,
      storeFunctions: store.storeFunctions,
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => (
          <BookkeepingContainer
            {...this.mapStoreToProps(store)}
            {...this.props}
          />
        )}
      </Consumer>
    );
  }
}

export default withRTKData(BookkeepingConsumer);
