import React from "react";

import { Panel, Col } from "react-bootstrap";

import Icon from "../Icon";

function renderAccordionHeader(title) {
  return (
    <div className="text-center">
      <Col xs={11} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

const AxoPanel = (props) => {
  return (
    <Panel {...props}>
      <Panel.Heading>
        <Panel.Title toggle>
          {renderAccordionHeader(<>{props.title}</>)}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>{props.children}</Panel.Body>
    </Panel>
  );
};

export default AxoPanel;
