import React from "react";

function PricingDemoView() {
  return (
    <div className="standardMaxWidth center-block topPadding bottomPadding">
      <h2>Products and Pricing</h2>
      <h3>Legal Advice</h3>
      <div>
        Axolex ApS provides legal advice within all areas of Danish law, handled
        by insured legal experts.
      </div>
      <div>
        <strong>Pricing:</strong> USD 150 / hour
      </div>
      <h3>Software Development</h3>
      <div>
        Axolex ApS can develop custom software for your company or customize our
        online software to fit your needs.
      </div>
      <div>
        <strong>Pricing:</strong> USD 200 / hour
      </div>
      <h3>Payment</h3>
      <div>
        Payment is collected through online invoices, which can be paid by
        credit card or through AliPay.
      </div>
    </div>
  );
}

export default PricingDemoView;
