import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import moment from "moment";
import Select from "react-select";

import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";

import Steps, { Step } from "rc-steps";

import {
  NumberService,
  UserInfoService,
  ContactService,
} from "../../../services/AxoServices";

import {
  LexButton,
  Flexbox,
  FlexElement,
  ClientSearchBox,
  AxoLocal,
  Link,
  getText,
  AxoDateTime,
  LoadingIcon,
  Dimensions,
  // AxoCheckbox,
  Icon,
  AsyncButton,
  FileViewer,
  FileViewerModal,
  // TextFilterModal,
  // NumberFilterModal,
  // FilterTypes,
  // TextFilter,
  // TextFilterTypes,
  // NumberFilter,
  // NumberFilterTypes,
  // DateFilter,
  // DateFilterTypes,
  // DateFilterModal
} from "../../../utilities/LexUtilities";

import { FormControl, ButtonGroup } from "react-bootstrap";

import BalancingEntryTable from "./BalancingEntryTable";
import BankPostingTable from "./BankPostingTable";

import BookkeepingContainer from "../Bookkeeping/BookkeepingContainer";

const PriceFilter = {
  ALL: 0,
  POSITIVE: 1,
  NEGATIVE: 2,
};

const BankImportProgress = {
  IDLE: 0,
  ACTIVE: 1,
  FAILURE: 2,
  SUCCESS: 3,
};

const StepValues = {
  // UPLOAD: 0, //Uploading bank postings
  AUTO: 0, //Automatic one-to-one matches
  DOUBLES: 1, //Entries with more than one potential match
  APPROX: 2, //Approximate matches
  ALGORITM: 3, //Many-to-one matches
  // FINAL: 5, //Manual matching
  ACCOUNTS: 4, //Changing accounts for entries
  BANKPOSTING: 5, //Selecting accounts for bank postings,
  FINALPOSTING: 6, //Adjust remaining entries and finalize
};

class BankBalancingView extends React.Component {
  static propTypes = {
    contactMap: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
    selectedContact: PropTypes.object.isRequired,
    bookKeepingDraftEntries: PropTypes.array.isRequired,
    onSelectBankAccount: PropTypes.func.isRequired,
    bankAccounts: PropTypes.array.isRequired,
    selectedBankAccountId: PropTypes.number.isRequired,

    selectedEntries: PropTypes.object.isRequired,
    onSelectEntry: PropTypes.func.isRequired,
    onSelectAllEntries: PropTypes.func.isRequired,
    clearSelectedEntries: PropTypes.func.isRequired,

    selectedPostings: PropTypes.object.isRequired,
    onSelectPosting: PropTypes.func.isRequired,
    onSelectAllPostings: PropTypes.func.isRequired,
    clearSelectedPostings: PropTypes.func.isRequired,
    onDeletePostings: PropTypes.func.isRequired,

    onReconciliate: PropTypes.func.isRequired,
    onReconciliateAll: PropTypes.func.isRequired,
    onCancelReconciliation: PropTypes.func.isRequired,

    numberReconciliated: PropTypes.number.isRequired,

    currentStep: PropTypes.number.isRequired,
    stepValues: PropTypes.object.isRequired,
    onForward: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,

    onUpdateEntry: PropTypes.func.isRequired,
  };

  //ToDo: Move the view state into the view component
  constructor(props) {
    super(props);
    this.state = {
      showUploadUI: false,
      displayedEntryId: 0,
      confirmDeleteEntries: false,
      confirmDeletePostings: false,
    };
  }

  onDisplayEntry = (entryId) => {
    this.setState((prevState) => ({
      displayedEntryId: prevState.displayedEntryId !== entryId ? entryId : 0,
    }));
  };

  onUploadCSV = () => {
    $("#csvUpload").trigger("click");
  };

  onCSVChange = async (event) => {
    let { loadBankStatement } = this.props;

    let uploader = event.target;
    if (uploader.files.length === 0) {
      return;
    }

    await loadBankStatement(uploader.files);
    uploader.value = ""; //onChange handler should be triggered when uploading the same file twice.

    setTimeout(() => {
      //Leave some time for the postings to load, before hiding the upload UI
      this.setState({ showUploadUI: false });
    }, 4000);
  };

  enableCancellation = () => {
    let {
      selectedEntries,
      selectedPostings,
      bookKeepingDraftEntries,
      bankPostings,
    } = this.props;

    if (selectedEntries.size === 0 && selectedPostings.size === 0) {
      return false;
    }

    return (
      !!bookKeepingDraftEntries.find(
        (e) => e.approved && selectedEntries.has(e.id)
      ) || !!bankPostings.find((p) => p.approved && selectedPostings.has(p.id))
    );
  };

  onShowReceipt = (receipt) => {
    this.fileModal.open([receipt]);
  };

  isReadOnly = () => {
    let { sharedClient, selectedContact, userProfile } = this.props;

    return (
      sharedClient &&
      (!selectedContact.editor || selectedContact.editor.id !== userProfile.id)
    );
  };

  // onCheckout = async () => {
  //   let { selectedContact, userProfile, checkoutContact } = this.props;

  //   if(!!selectedContact.editor) {
  //     return false;
  //   }

  //   try {
  //     await checkoutContact(
  //       this.props.selectedContact,
  //       userProfile).unwrap();
  //   }
  //   catch {
  //     return false;
  //   }

  //   return true;
  // }

  // onCheckin = async () => {
  //   let { selectedContact, userProfile, updateContact, setSelectedContact } = this.props;

  //   if(userProfile.id !== selectedContact.editor.id) {
  //     return false;
  //   }

  //   let newContact = {
  //     ...this.props.selectedContact,
  //     editor: null,
  //     editorId: null
  //   };

  //   try {
  //     await updateContact(newContact).unwrap();
  //   }
  //   catch {
  //     return false;
  //   }

  //   setSelectedContact(newContact);

  //   return true;
  // }

  render() {
    let {
      contactMap,
      handleSelectedClient,
      selectedContact,
      fiscalYears,
      containerHeight,
      currentStep,
      // onPrevious,
      // onRestart,
      onCancelAllReconciliations,
      onCancelReconciliation,
      selectedEntries,
      selectedPostings,
      stepValues,
      numberReconciliated,
      bankPostings,
      operations,
      onCancelOperation,
      selectedEntryAccountIdMap,
      hiddenBankPostings,
      loadedPostings,
      fixedClient,
      onSelectFiscalYear,
      onSelectStartDate,
      onSelectEndDate,
      selectedStartDate,
      selectedEndDate,
      selectedFiscalYearId,
      sharedClient,
      userProfile,
    } = this.props;

    let onlyUpload = false;
    if (currentStep === stepValues.AUTO) {
      onlyUpload = loadedPostings && bankPostings.length === 0;
    }

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    let showTime =
      currentStep === stepValues.AUTO && numberReconciliated === -1; //Hide dates once reconciliation has been run

    let initializedData =
      !!fiscalYears && selectedContact.id && fiscalYears.length > 0;

    let readOnly = this.isReadOnly();

    return (
      <div className="leftPadding">
        <Flexbox column style={{ height: containerHeight }}>
          <Flexbox
            responsive
            className="onToppest"
            style={{ marginTop: "15px" }}
          >
            {!fixedClient && (
              <FlexElement className="rightPadding">
                <ClientSearchBox
                  clients={contactMap.contacts}
                  startValue={ContactService.getContactAccountingName(
                    selectedContact
                  )}
                  count={contactMap.count}
                  handleSelectedClient={handleSelectedClient}
                  disabled={false}
                  placeholder={getText(
                    "composeSelectClientPlaceholder1",
                    "Vælg klient"
                  )}
                />
              </FlexElement>
            )}
            {initializedData && !readOnly && showTime ? (
              <Flexbox responsive collapseXS className="bottomPadding">
                <FlexElement
                  flexBasis="150px"
                  className="rightPadding onTopper"
                >
                  <Select
                    name="fiscalYear"
                    menuPlacement="auto"
                    value={{
                      value: selectedFiscalYear.id.toString(),
                      label: selectedFiscalYear.name,
                    }}
                    onChange={(selection, arg) => {
                      onSelectFiscalYear(
                        !!selection && selection.value !== "0"
                          ? parseInt(selection.value, 10)
                          : null
                      );
                    }}
                    noOptionsMessage={() => ""}
                    options={[
                      {
                        value: "0",
                        label: getText("axoidcode100", "Vælg regnskabsår"),
                      },
                      ...fiscalYears.map((year) => {
                        return { value: year.id.toString(), label: year.name };
                      }),
                    ]}
                  />
                </FlexElement>
                <FlexElement flexBasis="125px" className="rightPadding">
                  <AxoDateTime
                    utc
                    value={startDateSelection}
                    dateFormat={true}
                    timeFormat={false}
                    onChange={onSelectStartDate}
                    isValidDate={(currentDate) => {
                      return (
                        !currentDate.isBefore(
                          moment(selectedFiscalYear.startDate)
                        ) &&
                        !currentDate.isAfter(
                          moment(selectedFiscalYear.endDate)
                        ) &&
                        !currentDate.isAfter(endDateSelection)
                      );
                    }}
                  />
                </FlexElement>
                <FlexElement flexBasis="125px" className="rightPadding">
                  <AxoDateTime
                    utc
                    value={endDateSelection}
                    dateFormat={true}
                    timeFormat={false}
                    onChange={onSelectEndDate}
                    isValidDate={(currentDate) => {
                      return (
                        !currentDate.isBefore(
                          moment(selectedFiscalYear.startDate)
                        ) &&
                        !currentDate.isAfter(
                          moment(selectedFiscalYear.endDate)
                        ) &&
                        !currentDate.isBefore(startDateSelection)
                      );
                    }}
                  />
                </FlexElement>
              </Flexbox>
            ) : null}
            {currentStep === stepValues.AUTO &&
            !onlyUpload &&
            numberReconciliated > -1 ? (
              <React.Fragment>
                <FlexElement className="rightPadding">
                  <LexButton
                    disabled={
                      selectedEntries.size === 0 && selectedPostings.size === 0
                    }
                    onClick={onCancelReconciliation}
                  >
                    <AxoLocal
                      entity="axoidcode177"
                      defaultValue={"Fortryd valgt"}
                    />
                  </LexButton>
                </FlexElement>
                <FlexElement className="rightPadding">
                  <AsyncButton onClick={onCancelAllReconciliations} hideOkIcon>
                    <AxoLocal
                      entity="axoidcode178"
                      defaultValue={"Fortryd alt"}
                    />
                  </AsyncButton>
                </FlexElement>
              </React.Fragment>
            ) : null}
            {selectedContact.id && currentStep > 0 ? (
              <React.Fragment>
                {/* <FlexElement className='rightPadding'>
                  <LexButton disabled={currentStep < 1} onClick={onPrevious}>{'<--- Tilbage'}</LexButton>
                </FlexElement> */}
                <FlexElement className="rightPadding">
                  <AsyncButton
                    hideOkIcon
                    disabled={
                      operations.length === 0 &&
                      Object.keys(selectedEntryAccountIdMap).length === 0 &&
                      hiddenBankPostings.size === 0
                    }
                    onClick={onCancelOperation}
                  >
                    <AxoLocal
                      entity="axoAccounting22"
                      defaultValue={"Fortryd"}
                    />
                  </AsyncButton>
                </FlexElement>
                <FlexElement className="rightPadding">
                  <AsyncButton
                    disabled={currentStep < 1}
                    onClick={onCancelAllReconciliations}
                    hideOkIcon
                  >
                    <AxoLocal
                      entity="axoidcode178"
                      defaultValue={"Fortryd alt"}
                    />
                    {/* <AxoLocal entity='axoidcode155'defaultValue={'Til start'}/> */}
                  </AsyncButton>
                </FlexElement>
              </React.Fragment>
            ) : null}
            {sharedClient &&
              !!selectedContact.editor &&
              selectedContact.editor.id !== userProfile.id && (
                <FlexElement className="largeText rightPadding">
                  <AxoLocal
                    entity="Casesharing7"
                    defaultValue={"Tjekket ud af"}
                  />{" "}
                  {UserInfoService.getDisplayName(selectedContact.editor)}
                </FlexElement>
              )}
            {/* { sharedClient && (
              <>
                { !!selectedContact.editor ? (
                  <>
                    <FlexElement className='largeText rightPadding'>
                      <AxoLocal entity='Casesharing7' defaultValue={'Tjekket ud af'}/> {UserInfoService.getDisplayName(selectedContact.editor)}
                    </FlexElement>
                    { selectedContact.editor.id === userProfile.id && (
                      <FlexElement className='rightPadding'>
                        <AsyncButton onClick={this.onCheckin}>
                          Tjek ind
                        </AsyncButton>
                      </FlexElement>
                    )}
                  </>
                ) : (
                  <FlexElement className='rightPadding'>
                    <AsyncButton onClick={this.onCheckout}>
                      Tjek ud
                    </AsyncButton>
                  </FlexElement>
                )}
              </>
            )} */}
          </Flexbox>
          {!readOnly &&
          !!fiscalYears &&
          selectedContact.id &&
          fiscalYears.length === 0 ? (
            <div>
              <span className="largeText">
                <Link to="Setup">
                  {!fixedClient ? (
                    <>
                      <AxoLocal
                        entity="axoidcode67"
                        defaultValue={"Opret et regnskabsår for"}
                      />
                      &nbsp;{selectedContact.firstName}{" "}
                      {selectedContact.lastName}
                    </>
                  ) : (
                    <>
                      <AxoLocal
                        entity="axoidcode215"
                        defaultValue={"Opret et regnskabsår"}
                      />
                    </>
                  )}
                </Link>
              </span>
            </div>
          ) : null}
          {initializedData && !readOnly ? this.renderTable() : null}
        </Flexbox>
        <input
          name="csv"
          onChange={this.onCSVChange}
          type="file"
          accept=".csv"
          id="csvUpload"
          style={{ display: "none" }}
        />
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
      </div>
    );
  }

  formatAmount(amount) {
    if (!amount) {
      return "0.00";
    }
    return NumberService.formatDecimal(amount);
  }

  numericOptions = [
    <option key={0} value={0}>
      1
    </option>,
    <option key={1} value={1}>
      2
    </option>,
    <option key={2} value={2}>
      3
    </option>,
    <option key={3} value={3}>
      4
    </option>,
    <option key={4} value={4}>
      5
    </option>,
    <option key={5} value={5}>
      6
    </option>,
    <option key={6} value={6}>
      7
    </option>,
    <option key={7} value={7}>
      8
    </option>,
    <option key={8} value={8}>
      9
    </option>,
    <option key={9} value={9}>
      10
    </option>,
  ];

  onRunSimulation = async () => {
    let { storeFunctions } = this.props;
    let response = await storeFunctions.fetchDraftSimulation();
    return !!response;
  };

  renderTable = () => {
    let {
      bankPostings,
      // fiscalYears,
      // onSelectFiscalYear,
      // selectedFiscalYearId,
      // selectedStartDate,
      // selectedEndDate,
      // onSelectStartDate,
      // onSelectEndDate,
      bookKeepingDraftEntries,
      bankImportSettings,
      updateDateColumnIndex,
      updateTextColumnIndex,
      updateAmountColumnIndex,
      updateAccountBalanceColumnIndex,
      bankImportProgress,
      importResult,
      // bankAccounts,
      selectedBankAccountId,
      // onSelectBankAccount,
      activeBankAccountId,
      selectedEntries,
      onSelectEntry,
      onSelectAllEntries,
      clearSelectedEntries,

      selectedPostings,
      onSelectPosting,
      onSelectAllPostings,
      clearSelectedPostings,
      onDeletePostings,
      entryWarnings,
      postingWarnings,
      currentStep,
      stepValues,
      onForward,
      onReconciliateAll,
      numberReconciliated,
      onReconciliate,
      financeAccounts,
      onUpdateEntry,
      // onApproveEntries,
      selectedPostingAccountIdMap,
      selectedEntryAccountIdMap,
      selectMapAccount,
      createEntriesForBankPostings,
      onNextDouble,
      doubleGroupArray,
      doubleGroupProgress,
      standardReconAccountId,
      selectedContact,
      sendMailToClient,
      bankResult,
      startBankResult,
      startBankBalance,
      finalBankBalance,
      bankBalanceDifference,
      previewDifference,
      selectedEntryColumn,
      selectedBankPostingColumn,
      onSelectEntryColumn,
      onSelectBankPostingColumn,

      entryFilterTexts,
      bankPostingFilterTexts,
      updateEntryFilterText,
      updateBankPostingFilterText,

      creditorEntryIds,
      debitorEntryIds,
      financeAccountMap,
      taxSpecificationMap,

      singlePostingMatch,
      reconciliateSingleMatch,
      cancelSingleMatch,
      entryMultiMatches,
      postingMultiMatches,
      deleteSelectedEntries,
      onApproveAuto,
      loadingMatches,
      // cancelBankPostingAccountSelection,
      // cancelEntryAccountSelection,
      selectEntryMapAccount,

      fiscalYears,

      deleteAllBankPostings,
      selectedPlan,
      // onGotoDraft,
      onFinalizeEntries,
      hideStartValueWarning,
      onHideStartValueWarning,
      actions,
      storeFunctions,
      priceFilter,
      onUpdatePriceFilter,
      monthFilter,
      onUpdateMonthFilter,
      loadedPostings,
      draftSimulationResult,
      entriesWithoutFilter,
      userSettings,
      userProfile,
      editTextFilter,
      editNumberFilter,
      editDateFilter,
      editAccountFilter,
      columnFilters,
      removeColumnFilter,
      getAmountForEntry,
    } = this.props;

    let {
      showUploadUI,
      displayedEntryId,
      confirmDeleteEntries,
      confirmDeletePostings,
    } = this.state;

    let filteredEntries = bookKeepingDraftEntries;
    if (!!selectedBankAccountId) {
      filteredEntries = bookKeepingDraftEntries.filter(
        (e) =>
          e.financeAccountId === selectedBankAccountId ||
          e.balanceFinanceAccountId === selectedBankAccountId
      );
    }

    // let bankAccountOptions = [
    //   { value: 0, label:<AxoLocal entity='axoidcode156'defaultValue={'Alle Konti'}/>}
    // ]
    // .concat(bankAccounts
    // .map(f => {
    //   return { value: f.id, label: f.number.toString() + ' - ' + f.name };
    // }));

    let showSelectors = true;
    let showAccounts =
      currentStep === stepValues.ACCOUNTS ||
      currentStep === stepValues.BANKPOSTING ||
      currentStep === StepValues.APPROX;

    let showDoublesHighlight = currentStep === stepValues.DOUBLES;
    let showColumnSelectors = true;
    // let showColumnSelectors = currentStep === stepValues.ACCOUNTS || currentStep === stepValues.BANKPOSTING;

    let onlyUpload = false;
    if (currentStep === stepValues.AUTO) {
      onlyUpload = loadedPostings && bankPostings.length === 0;
    }

    let showStartValueWarning =
      !hideStartValueWarning &&
      currentStep > stepValues.AUTO &&
      startBankBalance.toFixed(2) !== startBankResult.toFixed(2);
    let showFileViewer =
      currentStep === stepValues.ACCOUNTS && displayedEntryId > 0;
    let selectedSingleEntry = null;
    if (showFileViewer) {
      selectedSingleEntry = bookKeepingDraftEntries.find(
        (e) => !!e.receipt && e.id === displayedEntryId
      );
    }

    let showSteps = currentStep > stepValues.AUTO || numberReconciliated > -1;

    // let currentSelectedEntries = filteredEntries.filter(e => selectedEntries.has(e.id));
    // let currentSelectedPostings = bankPostings.filter(p => selectedPostings.has(p.id));
    return (
      <React.Fragment key={currentStep}>
        {currentStep === stepValues.AUTO &&
        !onlyUpload &&
        numberReconciliated === -1 ? (
          <Flexbox justifyCenter>
            <FlexElement>
              <ButtonGroup>
                <LexButton
                  onClick={onReconciliateAll}
                  disabled={loadingMatches}
                  style={{ fontSize: "30px" }}
                >
                  {numberReconciliated > -1 ? (
                    <React.Fragment>
                      <AxoLocal
                        entity="axoidcode16"
                        defaultValue={"Afstemte"}
                      />
                      &nbsp;{numberReconciliated}
                      &nbsp;
                      {numberReconciliated > 1 || numberReconciliated === 0 ? (
                        <React.Fragment>
                          <AxoLocal
                            entity="axoidcode17"
                            defaultValue={"rækker"}
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <AxoLocal
                            entity="axoidcode18"
                            defaultValue={"række"}
                          />
                        </React.Fragment>
                      )}
                      &nbsp;
                      <LoadingIcon show={loadingMatches} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <AxoLocal entity="axoidcode15" defaultValue={"Afstem"} />{" "}
                      <LoadingIcon show={loadingMatches} />
                    </React.Fragment>
                  )}
                </LexButton>{" "}
                &nbsp; &nbsp;
                <AsyncButton
                  onClick={deleteAllBankPostings}
                  style={{ fontSize: "30px" }}
                >
                  <AxoLocal
                    entity="axoidcode180"
                    defaultValue={"Fortryd import"}
                  />
                </AsyncButton>
              </ButtonGroup>
            </FlexElement>
          </Flexbox>
        ) : null}
        {showSteps ? (
          <div className="topPadding standardMaxWidth">
            {/* Vælg nye ikoner her */}
            <Steps current={currentStep}>
              {/* <Step title="Upload" icon={<Icon glyph='icon-fontello-attach-7'/>} /> */}
              <Step
                title={getText("axoidcode15", "Afstem")}
                icon={<Icon glyph="glyphicon glyphicon-list-alt" />}
              />
              <Step
                title={getText("axoidcode188", "Dubletter")}
                icon={<Icon glyph="glyphicon glyphicon-random" />}
              />
              <Step
                title={getText("axoidcode189", "Cirka")}
                icon={<Icon glyph="glyphicon glyphicon-ok" />}
              />
              <Step
                title={getText("axoidcode190", "Algoritme")}
                icon={<Icon glyph="glyphicon glyphicon-ok" />}
              />
              {/* <Step title="Afslut" icon={<Icon glyph='glyphicon glyphicon-ok'/>} /> */}
              <Step
                title={getText("axoidcode191", "Modkonti")}
                icon={<Icon glyph="glyphicon glyphicon-inbox" />}
              />
              <Step
                title={getText("axoidcode192", "Konti")}
                icon={<Icon glyph="glyphicon glyphicon-book" />}
              />
              <Step
                title={getText("axoidcode193", "Postér")}
                icon={<Icon glyph="glyphicon glyphicon-book" />}
              />
            </Steps>
            {/* <ButtonToolbar>
              <LexButton disabled={currentStep < 1} onClick={onPrevious}>{'<--- Tilbage'}</LexButton>
              <LexButton disabled={currentStep >= Object.keys(stepValues).length - 1} onClick={onForward}>{'Fortsæt --->'}</LexButton>
            </ButtonToolbar> */}
          </div>
        ) : null}
        {showStartValueWarning ? (
          <div className="axored topPadding bottomPadding">
            <AxoLocal
              entity="axoidcode194"
              defaultValue={
                "Startsaldo i balancen matcher ikke med startsaldo i banken."
              }
            />
            &nbsp;
            <AxoLocal
              entity="AccountingTabViewEntity4"
              defaultValue={"Balance"}
            />
            : {NumberService.formatDecimal(startBankResult)}&ensp;
            <AxoLocal
              entity="AccountingTabViewEntity24"
              defaultValue={"Bank"}
            />
            : {NumberService.formatDecimal(startBankBalance)}&ensp;
            <LexButton onClick={onHideStartValueWarning}>
              <AxoLocal entity="AlertshowModaldialogOk" defaultValue={"OK"} />
            </LexButton>
          </div>
        ) : null}
        {!!singlePostingMatch.posting ? (
          <Flexbox className="bottomPadding">
            <FlexElement className="rightPadding largeText">
              <AxoLocal entity="axoidcode195" defaultValue={"Beløbet"} />{" "}
              {NumberService.formatDecimal(singlePostingMatch.posting.amount)}{" "}
              matcher med&nbsp;
              {moment(singlePostingMatch.posting.date).format("L")}:&nbsp;
              {singlePostingMatch.posting.text}&nbsp;
            </FlexElement>
            <FlexElement className="rightPadding">
              <LexButton onClick={reconciliateSingleMatch}>
                <AxoLocal
                  entity="friendRequestTimelineonApproveRequest"
                  defaultValue={"Godkend"}
                />
              </LexButton>
            </FlexElement>
            <FlexElement>
              <LexButton onClick={cancelSingleMatch}>
                <AxoLocal
                  entity="FriendRequestRejected"
                  defaultValue={"Afvist"}
                />
              </LexButton>
            </FlexElement>
          </Flexbox>
        ) : null}
        <Flexbox style={{ flex: 1 }} justified collapseXS>
          {/* Overflow: 'hidden allows to flexbox to adjust its size below that of the table, triggering a table resize. */}
          {currentStep >= stepValues.AUTO &&
          currentStep <= stepValues.ACCOUNTS ? (
            <FlexElement
              className="rightPadding"
              style={{ overflow: "hidden" }}
            >
              <Flexbox column className="fullHeight standardMaxWidth ">
                <FlexElement>
                  <Flexbox
                    responsive
                    collapseXS
                    className="bottomPadding"
                    style={{ minHeight: "45px" }}
                  >
                    {/* <FlexElement className='rightPadding'>
                      <h4>
                      <AxoLocal entity='axoidcode27'defaultValue={'Kontoposteringer'}/>
                        </h4>
                    </FlexElement> */}
                    {/* <FlexElement flexBasis='200px' className='onTop rightPadding'>
                      <Select
                        name="accountSelect"
                        value={bankAccountOptions.find(o => o.value === selectedBankAccountId)}
                        onChange={(selectedAccount) => {
                          if(!!selectedAccount) {
                            onSelectBankAccount(selectedAccount.value)
                          }
                        }}
                        noOptionsMessage={() => ''}
                        options={bankAccountOptions}
                      />
                    </FlexElement> */}
                    <FlexElement className="rightPadding">
                      <LexButton
                        disabled={
                          selectedEntries.size === 0 || confirmDeleteEntries
                        }
                        onClick={() =>
                          this.setState({ confirmDeleteEntries: true })
                        }
                      >
                        <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                      </LexButton>
                    </FlexElement>
                    {confirmDeleteEntries && (
                      <>
                        <FlexElement className="rightPadding">
                          <AsyncButton
                            disabled={selectedEntries.size === 0}
                            onClick={async () => {
                              let result = await deleteSelectedEntries();
                              if (!result) {
                                return false;
                              }

                              this.setState({ confirmDeleteEntries: false });
                            }}
                          >
                            <AxoLocal
                              entity="SidebarRightContainerConfirmPassword"
                              defaultValue={"Bekræft"}
                            />
                          </AsyncButton>
                        </FlexElement>
                        <FlexElement className="rightPadding">
                          <LexButton
                            onClick={() =>
                              this.setState({ confirmDeleteEntries: false })
                            }
                          >
                            <AxoLocal
                              entity="axoAccounting22"
                              defaultValue={"Fortryd"}
                            />
                          </LexButton>
                        </FlexElement>
                      </>
                    )}
                    {currentStep === stepValues.AUTO && !onlyUpload ? (
                      <React.Fragment>
                        {/* <FlexElement className='rightPadding'>
                          <LexButton onClick={onReconciliateAll} disabled={loadingMatches}>
                            { numberReconciliated > -1 ? (
                              <React.Fragment>
                                <AxoLocal entity='axoidcode16'defaultValue={'Afstemte'}/>
                                &nbsp;{numberReconciliated}
                                &nbsp;{ numberReconciliated > 1 || numberReconciliated === 0 
                                  ? <React.Fragment><AxoLocal entity='axoidcode17'defaultValue={'rækker'}/></React.Fragment> 
                                  : <React.Fragment><AxoLocal entity='axoidcode18'defaultValue={'række'}/></React.Fragment> }
                                &nbsp;<LoadingIcon show={loadingMatches} />
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                 <AxoLocal entity='axoidcode15'defaultValue={'Afstem'}/> <LoadingIcon show={loadingMatches} />
                              </React.Fragment>
                            ) }
                            </LexButton>
                        </FlexElement> */}
                        {numberReconciliated > -1 ? (
                          <React.Fragment>
                            <FlexElement className="rightPadding">
                              <AsyncButton
                                onClick={onApproveAuto}
                                disabled={numberReconciliated === -1}
                              >
                                <AxoLocal
                                  entity="friendRequestTimelineonApproveRequest"
                                  defaultValue={"Godkend"}
                                />
                              </AsyncButton>
                            </FlexElement>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                    {currentStep === stepValues.DOUBLES ||
                    currentStep === stepValues.ALGORITM ||
                    currentStep === stepValues.APPROX ||
                    currentStep === stepValues.FINAL ? (
                      <React.Fragment>
                        <FlexElement className="rightPadding">
                          <LexButton
                            disabled={
                              selectedEntries.size === 0 ||
                              selectedPostings.size === 0 ||
                              (currentStep === stepValues.DOUBLES &&
                                selectedEntries.size !== selectedPostings.size)
                            }
                            onClick={onReconciliate}
                          >
                            <AxoLocal
                              entity="axoidcode200"
                              defaultValue={"Afstem valgte"}
                            />
                          </LexButton>
                        </FlexElement>
                        {/* <FlexElement className='rightPadding'>
                          <LexButton 
                            disabled={!this.enableCancellation()}
                            onClick={onCancelReconciliation}
                          >
                            <AxoLocal entity='axoAccounting22'  defaultValue={'Fortryd'}/>
                          </LexButton>
                        </FlexElement> */}
                      </React.Fragment>
                    ) : null}
                    {currentStep === stepValues.ACCOUNTS ? (
                      <React.Fragment>
                        {/* <FlexElement className='rightPadding'>
                          <LexButton 
                            disabled={selectedEntries.size === 0} 
                            onClick={onApproveEntries}>
                            <AxoLocal entity='axoidcode162'defaultValue={' Godkend valgte'}/>      
                          </LexButton>
                        </FlexElement> */}
                        {/* <FlexElement className='rightPadding'>
                          <LexButton
                            disabled={Object.keys(selectedEntryAccountIdMap).length === 0} 
                            onClick={cancelEntryAccountSelection}>
                            Fortryd kontovalg
                          </LexButton>
                        </FlexElement> */}
                      </React.Fragment>
                    ) : null}
                    {currentStep === stepValues.ALGORITM ||
                    currentStep === stepValues.APPROX ||
                    currentStep === stepValues.FINAL ||
                    currentStep === stepValues.ACCOUNTS ? (
                      <FlexElement className="rightPadding">
                        <LexButton
                          onClick={onForward}
                          disabled={
                            selectedPostings.size > 0 ||
                            selectedEntries.size > 0 ||
                            Object.keys(columnFilters).length > 0
                          }
                        >
                          {getText("axoidcode108", "Fortsæt --->")}
                        </LexButton>
                      </FlexElement>
                    ) : null}
                    {loadingMatches ? (
                      <FlexElement className="rightPadding">
                        <span className="largeText">
                          <AxoLocal
                            entity="paymentDate26a"
                            defaultValue={"Loading..."}
                          />
                        </span>
                        <LoadingIcon show={true} />
                      </FlexElement>
                    ) : null}
                    {!!selectedEntryColumn ? (
                      <FlexElement className="rightPadding">
                        <FormControl
                          id="entryFilterTexts"
                          type="text"
                          placeholder={getText("axoidcode201", "Filtrer...")}
                          onChange={(event) =>
                            updateEntryFilterText(event.target.value)
                          }
                          value={entryFilterTexts[selectedEntryColumn] || ""}
                        />
                      </FlexElement>
                    ) : null}
                    {currentStep === stepValues.DOUBLES ? (
                      <FlexElement>
                        {doubleGroupProgress >= doubleGroupArray.length - 1 ? (
                          <LexButton
                            onClick={onForward}
                            disabled={selectedEntries.size > 0}
                          >
                            {getText("axoidcode108", "Fortsæt --->")}
                          </LexButton>
                        ) : (
                          <LexButton
                            onClick={onNextDouble}
                            disabled={
                              selectedPostings.size > 0 ||
                              selectedEntries.size > 0
                            }
                          >
                            {doubleGroupProgress + 1} /{" "}
                            {doubleGroupArray.length}
                            &nbsp;{" "}
                            <AxoLocal
                              entity="axoidcode199"
                              defaultValue={"æste dublet"}
                            />
                          </LexButton>
                        )}
                      </FlexElement>
                    ) : null}
                    {currentStep > stepValues.AUTO ? (
                      <FlexElement flexGrow={1} className="text-right">
                        <h4>
                          {" "}
                          <AxoLocal
                            entity="axoidcode157"
                            defaultValue={"Saldo"}
                          />
                          : {NumberService.formatDecimal(bankResult)}
                        </h4>
                      </FlexElement>
                    ) : null}
                  </Flexbox>
                </FlexElement>
                {/* Height: '0px' is necessary to allow the child to fill the full height. See https://github.com/philipwalton/flexbugs/issues/197#issuecomment-431525827 */}
                <FlexElement flexGrow={1} style={{ height: "0px" }}>
                  <BalancingEntryTable
                    entries={filteredEntries}
                    locale={userSettings.locale}
                    {...{
                      selectedEntries,
                      onSelectEntry,
                      onSelectAllEntries,
                      clearSelectedEntries,
                      selectedBankAccountId,
                      entryWarnings,
                      showSelectors,
                      showAccounts,
                      financeAccounts,
                      standardReconAccountId,
                      onUpdateEntry,
                      showDoublesHighlight,
                      bankResult,
                      showColumnSelectors,
                      selectedEntryColumn,
                      onSelectEntryColumn,
                      creditorEntryIds,
                      debitorEntryIds,
                      financeAccountMap,
                      taxSpecificationMap,
                      entryMultiMatches,
                      selectedEntryAccountIdMap,
                      selectEntryMapAccount,
                      priceFilter,
                      onUpdatePriceFilter,
                      monthFilter,
                      onUpdateMonthFilter,
                      displayedEntryId,
                      activeBankAccountId,
                      selectedPlan,
                      editTextFilter,
                      editAccountFilter,
                      editNumberFilter,
                      editDateFilter,
                      columnFilters,
                      removeColumnFilter,
                      getAmountForEntry,
                    }}
                    hasFilter={
                      Object.keys(entryFilterTexts).length > 0 ||
                      !!selectedEntryColumn ||
                      priceFilter > PriceFilter.ALL ||
                      monthFilter > -1
                    }
                    onShowReceipt={this.onShowReceipt}
                    onDisplayEntry={this.onDisplayEntry}
                  />
                </FlexElement>
              </Flexbox>
            </FlexElement>
          ) : null}
          {currentStep !== stepValues.ACCOUNTS &&
          currentStep < stepValues.FINALPOSTING ? (
            <FlexElement
              className="rightPadding"
              style={{ overflow: "hidden" }}
            >
              <Flexbox column className="fullHeight standardMaxWidth">
                <FlexElement>
                  <Flexbox
                    responsive
                    collapseXS
                    className="bottomPadding"
                    style={{ minHeight: "45px" }}
                  >
                    {/* <FlexElement className='rightPadding'>
                      <h4>
                        <AxoLocal entity='axoidcode28'defaultValue={'Bank'}/>
                      </h4>
                    </FlexElement> */}
                    {currentStep === stepValues.AUTO &&
                    onlyUpload &&
                    !showUploadUI ? (
                      <FlexElement className="rightPadding">
                        <LexButton
                          disabled={showUploadUI}
                          style={{ fontSize: "35px" }}
                          onClick={() => this.setState({ showUploadUI: true })}
                        >
                          <AxoLocal
                            entity="axoidcode24"
                            defaultValue={"Indlæs kontoudtog"}
                          />
                        </LexButton>
                      </FlexElement>
                    ) : null}
                    {currentStep === stepValues.AUTO && showUploadUI ? (
                      <React.Fragment>
                        {!!importResult ? (
                          <Flexbox
                            alignCenter
                            className={
                              importResult.parsedRows === 0 ? "axored" : ""
                            }
                          >
                            <FlexElement className="largeText rightPadding">
                              {importResult.parsedRows}&nbsp;
                              <AxoLocal
                                entity="axoidcode22"
                                defaultValue={"rækker indlæst."}
                              />
                            </FlexElement>
                            <FlexElement className="largeText">
                              {importResult.savedRows}&nbsp;
                              <AxoLocal
                                entity="axoidcode23"
                                defaultValue={"nye rækker gemt."}
                              />
                            </FlexElement>
                          </Flexbox>
                        ) : (
                          <Flexbox responsive collapseXS alignCenter>
                            <FlexElement flexGrow={1}>
                              {/* Left padding */}
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <LexButton
                                disabled={
                                  bankImportProgress > BankImportProgress.IDLE
                                }
                                onClick={this.onUploadCSV}
                              >
                                <AxoLocal
                                  entity="axoidcode24"
                                  defaultValue={"Indlæs"}
                                />
                                <LoadingIcon
                                  show={
                                    bankImportProgress ===
                                    BankImportProgress.ACTIVE
                                  }
                                />
                              </LexButton>
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <LexButton
                                onClick={() =>
                                  this.setState({ showUploadUI: false })
                                }
                              >
                                <AxoLocal
                                  entity="axoAccounting22"
                                  defaultValue={"Fortryd"}
                                />
                              </LexButton>
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <AxoLocal
                                entity="axoidcode25"
                                defaultValue={"Dato"}
                              />
                              :&nbsp;
                              <select
                                value={bankImportSettings.dateColumnIndex}
                                onChange={(event) =>
                                  updateDateColumnIndex(event.target.value)
                                }
                              >
                                {this.numericOptions}
                              </select>
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <AxoLocal
                                entity="axoidcode26"
                                defaultValue={"Tekst"}
                              />
                              :&nbsp;
                              <select
                                value={bankImportSettings.textColumnIndex}
                                onChange={(event) =>
                                  updateTextColumnIndex(event.target.value)
                                }
                              >
                                {this.numericOptions}
                              </select>
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <AxoLocal
                                entity="axoidcode27a"
                                defaultValue={"Beløb"}
                              />
                              :&nbsp;
                              <select
                                value={bankImportSettings.amountColumnIndex}
                                onChange={(event) =>
                                  updateAmountColumnIndex(event.target.value)
                                }
                              >
                                {this.numericOptions}
                              </select>
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <AxoLocal
                                entity="axoidcode157"
                                defaultValue={"Saldo"}
                              />
                              :&nbsp;
                              <select
                                value={
                                  bankImportSettings.accountBalanceColumnIndex
                                }
                                onChange={(event) =>
                                  updateAccountBalanceColumnIndex(
                                    event.target.value
                                  )
                                }
                              >
                                {this.numericOptions}
                              </select>
                            </FlexElement>
                          </Flexbox>
                        )}
                      </React.Fragment>
                    ) : null}
                    {bankImportProgress === BankImportProgress.FAILURE ? (
                      <div className="largeText axored">
                        <AxoLocal
                          entity="axoidcode219"
                          defaultValue={
                            "Fejl. Bankposteringer kunne ikke indlæses"
                          }
                        />
                        .
                      </div>
                    ) : null}
                    {(currentStep === stepValues.AUTO ||
                      currentStep === stepValues.BANKPOSTING) &&
                    !onlyUpload ? (
                      <>
                        <FlexElement className="rightPadding">
                          <LexButton
                            disabled={
                              selectedPostings.size === 0 ||
                              confirmDeletePostings
                            }
                            onClick={() =>
                              this.setState({ confirmDeletePostings: true })
                            }
                          >
                            <AxoLocal
                              entity="axoidcode179"
                              defaultValue={"Slet"}
                            />
                          </LexButton>
                          <LoadingIcon show={!loadedPostings} />
                        </FlexElement>
                        {confirmDeletePostings && (
                          <>
                            <FlexElement className="rightPadding">
                              <AsyncButton
                                disabled={selectedPostings.size === 0}
                                onClick={async () => {
                                  let responses = await onDeletePostings();
                                  if (responses.find((r) => !r.ok)) {
                                    return false;
                                  }

                                  this.setState({
                                    confirmDeletePostings: false,
                                  });
                                }}
                              >
                                <AxoLocal
                                  entity="SidebarRightContainerConfirmPassword"
                                  defaultValue={"Bekræft"}
                                />
                              </AsyncButton>
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <LexButton
                                onClick={() =>
                                  this.setState({
                                    confirmDeletePostings: false,
                                  })
                                }
                              >
                                <AxoLocal
                                  entity="axoAccounting22"
                                  defaultValue={"Fortryd"}
                                />
                              </LexButton>
                            </FlexElement>
                          </>
                        )}
                      </>
                    ) : null}
                    {currentStep === stepValues.BANKPOSTING ? (
                      <React.Fragment>
                        {/* <FlexElement className='rightPadding'>
                          <LexButton 
                            disabled={Object.keys(selectedPostingAccountIdMap).length === 0} 
                            onClick={cancelBankPostingAccountSelection}
                          >
                            Fortryd kontovalg
                          </LexButton>
                        </FlexElement> */}
                        <FlexElement className="rightPadding">
                          <AsyncButton
                            onClick={createEntriesForBankPostings}
                            disabled={
                              !!bankPostings.find(
                                (p) => !selectedPostingAccountIdMap[p.id]
                              ) || Object.keys(columnFilters).length > 0
                            }
                          >
                            {getText("axoidcode108", "Fortsæt --->")}
                            {/* <AxoLocal entity='axoidcode158'defaultValue={'Postér'}/> */}
                          </AsyncButton>
                        </FlexElement>
                        {/* <FlexElement className='rightPadding'>
                          <AsyncButton 
                            disabled={!selectedContact.eMail 
                              || bookKeepingDraftEntries.filter(e => e.financeAccountId === standardReconAccountId).length === 0}
                            onClick={sendMailToClient}
                          >
                            <AxoLocal entity='axoidcode159'defaultValue={'Send mail'}/>
                          </AsyncButton>
                        </FlexElement> */}
                      </React.Fragment>
                    ) : null}
                    {!!selectedBankPostingColumn ? (
                      <FlexElement className="rightPadding">
                        <FormControl
                          id="bankPostingFilterTexts"
                          type="text"
                          placeholder={getText("axoidcode201", "Filtrer...")}
                          onChange={(event) =>
                            updateBankPostingFilterText(event.target.value)
                          }
                          value={
                            bankPostingFilterTexts[selectedBankPostingColumn]
                          }
                        />
                      </FlexElement>
                    ) : null}
                    {currentStep > stepValues.AUTO ? (
                      <FlexElement flexGrow={1} className="text-right">
                        {currentStep === stepValues.BANKPOSTING ? (
                          <h4>
                            <AxoLocal
                              entity="axoidcode197"
                              defaultValue={"Difference"}
                            />
                            :{" "}
                            {NumberService.formatDecimal(bankBalanceDifference)}
                            &nbsp;&nbsp;
                            <AxoLocal
                              entity="axoidcode198"
                              defaultValue={"Difference Efter"}
                            />
                            : {NumberService.formatDecimal(previewDifference)}
                          </h4>
                        ) : (
                          <h4>
                            <AxoLocal
                              entity="timerStartButton"
                              defaultValue={"Start"}
                            />
                            : {NumberService.formatDecimal(startBankBalance)}
                            &nbsp;&nbsp;
                            <AxoLocal
                              entity="CalendarEventBoxFormSlut"
                              defaultValue={"Slut"}
                            />
                            : {NumberService.formatDecimal(finalBankBalance)}
                            &nbsp;&nbsp;
                            <AxoLocal
                              entity="axoidcode197"
                              defaultValue={"Difference"}
                            />
                            :{" "}
                            {NumberService.formatDecimal(bankBalanceDifference)}
                          </h4>
                        )}
                      </FlexElement>
                    ) : null}
                  </Flexbox>
                </FlexElement>
                {/* Height: '0px' is necessary to allow the child to fill the full height. See https://github.com/philipwalton/flexbugs/issues/197#issuecomment-431525827 */}
                <FlexElement flexGrow={1} style={{ height: "0px" }}>
                  <BankPostingTable
                    entries={bankPostings}
                    {...{
                      selectedPostings,
                      onSelectPosting,
                      onSelectAllPostings,
                      clearSelectedPostings,
                      postingWarnings,
                      showSelectors,
                      showAccounts,
                      financeAccounts,
                      standardReconAccountId,
                      activeBankAccountId,
                      selectedPostingAccountIdMap,
                      selectMapAccount,
                      showDoublesHighlight,
                      showColumnSelectors,
                      selectedBankPostingColumn,
                      onSelectBankPostingColumn,
                      postingMultiMatches,
                      priceFilter,
                      onUpdatePriceFilter,
                      monthFilter,
                      onUpdateMonthFilter,
                      editTextFilter,
                      editNumberFilter,
                      editDateFilter,
                      columnFilters,
                      removeColumnFilter,
                    }}
                    hasFilter={
                      Object.keys(bankPostingFilterTexts).length > 0 ||
                      !!selectedBankPostingColumn ||
                      priceFilter > PriceFilter.ALL ||
                      monthFilter > -1
                    }
                  />
                </FlexElement>
              </Flexbox>
            </FlexElement>
          ) : null}
          {currentStep === stepValues.FINALPOSTING ? (
            <div>
              <Flexbox className="bottomPadding">
                <FlexElement className="rightPadding">
                  <LexButton
                    disabled={selectedEntries.size === 0}
                    onClick={deleteSelectedEntries}
                  >
                    <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                  </LexButton>
                </FlexElement>
                <FlexElement className="rightPadding">
                  <AsyncButton
                    disabled={
                      !selectedContact.eMail ||
                      bookKeepingDraftEntries.filter(
                        (e) =>
                          e.financeAccountId === standardReconAccountId ||
                          e.balanceFinanceAccountId === standardReconAccountId
                      ).length === 0
                    }
                    onClick={sendMailToClient}
                  >
                    <AxoLocal
                      entity="axoEntityidcode27"
                      defaultValue={"Send poster til undersøgelse til klient."}
                    />
                  </AsyncButton>
                </FlexElement>
                <FlexElement className="rightPadding">
                  <AsyncButton
                    onClick={this.onRunSimulation}
                    disabled={!selectedContact.id}
                  >
                    <AxoLocal
                      entity="axoidcode130"
                      defaultValue={"Simulering"}
                    />
                  </AsyncButton>
                </FlexElement>
                {/* <FlexElement className='rightPadding axoFrontPage'>
                    <LexButton 
                      disabled={bankPostings.length > 0 && bankBalanceDifference.toFixed(2) !== '0.00'}
                      title={bankBalanceDifference.toFixed(2) !== '0.00' 
                        ? 'Bank ikke afstemt. Difference: ' +  bankBalanceDifference.toFixed(2) : 'Bank er afstemt korrekt.'}
                      onClick={onGotoDraft}>
                      <AxoLocal entity='axoidcode196'defaultValue={'Gå til kladde'}/>
                    </LexButton>
                  </FlexElement> */}
                <FlexElement className="rightPadding axoFrontPage">
                  <AsyncButton
                    disabled={
                      bankPostings.length > 0 &&
                      bankBalanceDifference.toFixed(2) !== "0.00"
                    }
                    title={
                      bankBalanceDifference.toFixed(2) !== "0.00"
                        ? getText(
                            "axoEntityidcode171",
                            "Bank ikke afstemt. Difference:"
                          ) + bankBalanceDifference.toFixed(2)
                        : getText(
                            "axoEntityidcode172",
                            "Bank er afstemt korrekt."
                          )
                    }
                    onClick={() => onFinalizeEntries(entriesWithoutFilter)}
                  >
                    <AxoLocal
                      entity="axoidcode94"
                      defaultValue={"Bogfør kladde"}
                    />
                  </AsyncButton>
                </FlexElement>
              </Flexbox>
              <BookkeepingContainer
                onlyTable
                // viewType={0}
                importantEntryIds={new Set()}
                entries={filteredEntries}
                entriesWithoutFilter={entriesWithoutFilter}
                contactMap={{}}
                selectedContact={selectedContact}
                clientPlan={selectedPlan}
                financeAccountMap={financeAccountMap}
                taxSpecificationMap={taxSpecificationMap}
                {...{
                  selectedEntries,
                  onSelectEntry,
                  clearSelectedEntries,
                  fiscalYears,
                  userProfile,
                }}
                onSelectAll={onSelectAllEntries}
                draftSimulationResult={draftSimulationResult}
                locale={userSettings.locale}
                actions={actions}
                storeFunctions={storeFunctions}
              />
            </div>
          ) : null}
          {!!selectedSingleEntry && !!selectedSingleEntry.receipt ? (
            <FlexElement className="rightPadding">
              <FileViewer file={selectedSingleEntry.receipt} />
            </FlexElement>
          ) : null}
        </Flexbox>
      </React.Fragment>
    );
  };
}

export default Dimensions({
  elementResize: true,
  getHeight: () => {
    return window.innerHeight - 125;
  },
})(BankBalancingView);
