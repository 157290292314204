export default {
  enforceMaxLength(inputString, maxLength) {
    return (inputString =
      inputString.length > maxLength
        ? inputString.substring(0, maxLength - 3) + "..."
        : inputString);
  },

  equalsWithoutCase(l, r) {
    if (!l && !r) {
      return true;
    }
    if (!l || !r) {
      return false;
    }
    return l.localeCompare(r, "en", { sensitivity: "base" }) === 0;
  },
};
