import React from "react";
import ApiService from "../services/DataAccess/ApiService";
import DataStore from "../services/DataAccess/DataStore";
import RoutingService from "../services/RoutingService";
import Conditional from "../utilities/Conditional";
import { withRouter } from "react-router-dom";
import { AxoLocal, Icon } from "../utilities/LexUtilities";

import QueryString from "query-string";

class StripeAccountValidator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
    };
  }

  componentDidMount() {
    this.getStripeUserCredentials();
  }

  getStripeUserCredentials = () => {
    let query = QueryString.parse(this.props.location.search);
    let code = query.code;

    ApiService.getStripeUserCredentials(code).then((response) => {
      if (response.ok) {
        DataStore.fetchUserProfile().then(() => {
          this.props.history.push(RoutingService.getRootPath());
        });
      } else {
        this.setState({ showErrorMessage: true });
        setTimeout(() => {
          this.props.history.push(RoutingService.getRootPath());
        }, 5000);
      }
    });
  };

  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
        }}
      >
        <Conditional show={this.state.showErrorMessage}>
          <h3>
            <Icon glyph="icon-fontello-info-3" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="SubscriptionViewPleasetryagainlater"
              defaultValue="Der skete en fejl. Prøv igen senere."
            />
          </h3>
        </Conditional>
        <Conditional show={!this.state.showErrorMessage}>
          <img
            alt=""
            src="/imgs/app/loading.gif"
            width="250px"
            className="img-circle"
          />
        </Conditional>
      </div>
    );
  }
}

export default withRouter(StripeAccountValidator);
