import React from "react";
import Dispatcher from "../Dispatcher";
import PropTypes from "prop-types";
import withData from "../Data/withData";

var win = null;
if (typeof window === "undefined") {
  win = {
    L20n: {
      getContext: function () {},
    },
  };
} else {
  win = window;

  if (!win.hasOwnProperty("L20n")) {
    win.L20n = {
      getContext: function () {},
    };
  }
}

var ctx = win.L20n.getContext();

var Entities = {
  ready: false,
  entities: {},
  registerEntity: function (entity) {
    if (Entities.hasOwnProperty(entity)) {
      if (!Entities.ready) return;
      Dispatcher.publish("ctx:" + entity);
      return;
    }
    ctx.localize([entity], function (l) {
      Dispatcher.publish("ctx:" + entity);
    });
    Entities[entity] = 1;
  },
};

var initializeLocales = function (locales, rpath) {
  rpath = rpath || "";
  ctx.ready(function () {
    Entities.ready = true;
    for (var i in Entities.entities) {
      Entities.registerEntity(Entities.entities[i]);
    }
    Dispatcher.publish("ctx:ready");
  });
  ctx.linkResource(function (locale) {
    return rpath + "/locales/" + locale + "/strings.txt";
  });
  ctx.registerLocales(locales.default, locales.locales);
  ctx.requestLocales(locales.default);
};

class Entity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entity: this.props.defaultValue || "",
    };
  }

  handler = () => {
    this.setState({
      entity: ctx.getSync(this.props.entity, this.props.data),
    });
  };
  componentDidMount() {
    this.subscription = Dispatcher.subscribe(
      "ctx:" + this.props.entity,
      this.handler
    );
    Entities.registerEntity(this.props.entity);
    if (Entities.ready) {
      this.handler();
    }
  }
  componentWillUnmount() {
    Dispatcher.unsubscribe(this.subscription);
  }
  render() {
    var ComponentClass = this.props.componentClass;
    var componentAttribute = this.props.componentAttribute;

    var props = {
      ...this.props,
      data: null,
      entity: null,
      componentClass: null,
      componentAttribute: null,
    };

    delete props.entity;
    delete props.componentClass;
    delete props.componentAttribute;
    delete props.dangerouslySetInnerHTML;

    if (ComponentClass && componentAttribute.length) {
      props[componentAttribute] = this.state.entity;

      return <ComponentClass {...props} />;
    }

    if (ComponentClass === "input") {
      return <ComponentClass {...props} value={this.state.entity} />;
    }
    if (this.props.dangerouslySetInnerHTML) {
      return (
        <ComponentClass
          {...props}
          dangerouslySetInnerHTML={{ __html: this.state.entity }}
        />
      );
    }
    return <ComponentClass {...props}>{this.state.entity}</ComponentClass>;
  }
}

Entity.defaultProps = {
  componentClass: "span",
  componentAttribute: "",
};

Entity.propTypes = {
  data: PropTypes.object,
  entity: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.bool,
};

class AxoEntity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entity: this.props.defaultValue || "",
    };
  }

  handler = () => {
    this.setState({
      entity: ctx.getSync(this.props.entity, this.props.data),
    });
  };

  componentDidMount() {
    // this.subscription = Dispatcher.subscribe('ctx:'+this.props.entity, this.handler);
    // Entities.registerEntity(this.props.entity);
    if (Entities.ready) {
      this.handler();
    }
  }

  // componentWillUnmount() {
  //   Dispatcher.unsubscribe(this.subscription);
  // }

  //Use normal prop injection for performance reasons.
  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale
        || prevProps.entity !== this.props.entity) {
          
      this.handler();
    }
  }

  render() {
    var ComponentClass = this.props.componentClass;
    var componentAttribute = this.props.componentAttribute;

    var props = {
      ...this.props,
      data: null,
      entity: null,
      componentClass: null,
      componentAttribute: null,
    };

    delete props.entity;
    delete props.componentClass;
    delete props.componentAttribute;
    delete props.dangerouslySetInnerHTML;

    if (ComponentClass && componentAttribute.length) {
      props[componentAttribute] = this.state.entity;

      return <ComponentClass {...props} />;
    }

    if (ComponentClass === "input") {
      return <ComponentClass {...props} value={this.state.entity} />;
    }
    if (this.props.dangerouslySetInnerHTML) {
      return (
        <ComponentClass
          {...props}
          dangerouslySetInnerHTML={{ __html: this.state.entity }}
        />
      );
    }
    return <ComponentClass {...props}>{this.state.entity}</ComponentClass>;
  }
}

AxoEntity.defaultProps = {
  componentClass: "span",
  componentAttribute: "",
};

AxoEntity.propTypes = {
  data: PropTypes.object,
  entity: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.bool,
};

const ready = function () {
  if (Entities.ready) {
    Dispatcher.publish("ctx:ready");
    return;
  }
};

const changeLocale = function (locale) {
  ctx.requestLocales(locale);
};

const getText = (entity, defaultValue) => {
  let text = ctx.getSync(entity);
  if (!text || text === entity) {
    return defaultValue || "";
  }
  return text;
};

const AxoLocal = withData(AxoEntity, (store) => ({ locale: store.locale }));

export {
  ctx,
  initializeLocales,
  ready,
  changeLocale,
  Entity,
  AxoLocal,
  getText,
};
