import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import DataStore from "../../services/DataAccess/DataStore";
import DataActions from "../../services/DataAccess/DataActions";
import OptionNavItem from "./OptionNavItem";
import ModalService from "../../services/ModalService";
import { AuthorizationService } from "../../services/AxoServices";

import { AxoListGroup, AxoListItem } from "../../utilities/AxoListGroup";
import { withRouter } from "react-router-dom";
import moment from "moment";

import {
  Row,
  Col,
  Grid,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Table,
} from "react-bootstrap";

import {
  Icon,
  AxoLocal,
  LexButton,
  getText,
} from "../../utilities/LexUtilities";

class TrashEntriesView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      errorMessage: "",
      isDeleting: false,
      pageSize: 50,
    };
  }

  getOptions = () => {
    let { userProfile } = this.props;
    let userType = AuthorizationService.getUserType(userProfile);

    let options = [
      {
        index: "messages",
        text: (
          <span>
            <AxoLocal entity="TrashEntriesViewEmail" defaultValue={"Mails"} /> (
            {this.getTypeCount("messages")})
          </span>
        ),
        glyph: "icon-fontello-mail-1",
      },
      {
        index: "cases",
        text: (
          <span>
            <AxoLocal entity="TrashEntriesViewCase" defaultValue={"Sager"} /> (
            {this.getTypeCount("cases")})
          </span>
        ),
        glyph: "icon-simple-line-icons-briefcase",
      },
      {
        index: "documents",
        text: (
          <span>
            <AxoLocal
              entity="TrashEntriesViewDocument"
              defaultValue={"Dokumenter"}
            />{" "}
            ({this.getTypeCount("documents")})
          </span>
        ),
        glyph: "icon-fontello-docs-1",
      },
      {
        index: "templates",
        text: (
          <span>
            <AxoLocal
              entity="TrashEntriesViewtemplates"
              defaultValue={"Skabeloner"}
            />{" "}
            ({this.getTypeCount("templates")})
          </span>
        ),
        glyph: "icon-fontello-window",
      },
      {
        index: "contacts",
        text: (
          <span>
            {userType === "Society" ? (
              <span>
                {" "}
                <AxoLocal entity="axoidcode12" defaultValue={"Medlemmer"} />
              </span>
            ) : (
              <AxoLocal
                entity="TrashEntriesViewclients"
                defaultValue={"Klienter"}
              />
            )}
            &nbsp;({this.getTypeCount("contacts")})
          </span>
        ),
        glyph: "icon-simple-line-icons-users",
      },
      {
        index: "timeEntries",
        text: (
          <span>
            <AxoLocal
              entity="TrashEntriesViewTimerecords"
              defaultValue={"Tidsregistreringer"}
            />{" "}
            ({this.getTypeCount("timeEntries")})
          </span>
        ),
        glyph: "icon-fontello-list-3",
      },
      {
        index: "invoices",
        text: (
          <span>
            <AxoLocal
              entity="TrashEntriesViewinvoices"
              defaultValue={"Fakturaer"}
            />{" "}
            ({this.getTypeCount("invoices")})
          </span>
        ),
        glyph: "icon-fontello-doc-4",
      },
    ];
    if (userType === "Society") {
      options = options.filter(
        (o) =>
          o.index !== "cases" &&
          o.index !== "templates" &&
          o.index !== "timeEntries" &&
          o.index !== "invoices"
      );
    }
    if (userType === "Accountant") {
      return options.filter((o) => o.index !== "cases");
    }
    return options;
  };

  renderLeftPanel() {
    var optionItems = this.getOptions().map((option) => {
      return (
        <AxoListItem
          key={option.index}
          active={option.index === this.props.typeShown}
          onClick={this.props.onSelect.bind(this, option.index)}
        >
          <OptionNavItem
            glyph={option.glyph}
            option={option}
            labelClass="axogrouplist"
          />
        </AxoListItem>
      );
    });
    return (
      <div>
        <h5>
          <AxoLocal
            entity="CalendarEventBoxFormFOLDERS"
            defaultValue={"Mapper"}
          />
        </h5>
        <AxoListGroup style={{ padding: "5px" }}>{optionItems}</AxoListGroup>
        {/*{contextMenus}*/}
      </div>
    );
  }

  getNameHeader = () => {
    let { typeShown } = this.props;

    switch (typeShown) {
      case "messages":
        return (
          <AxoLocal
            key={typeShown}
            entity="TrashEntriesViewSubject"
            defaultValue={"Emne"}
          />
        );
      case "cases":
        return (
          <AxoLocal
            key={typeShown}
            entity="TrashEntriesViewcasenumber"
            defaultValue={"Sagsnummer"}
          />
        );
      case "documents":
        return (
          <AxoLocal
            key={typeShown}
            entity="TrashEntriesViewFilename"
            defaultValue={"Filnavn"}
          />
        );
      case "contacts":
        return (
          <AxoLocal
            key={typeShown}
            entity="TrashEntriesViewName"
            defaultValue={"Navn"}
          />
        );
      case "timeEntries":
        return (
          <AxoLocal
            key={typeShown}
            entity="Enhedspris6"
            defaultValue={"Beskrivelse"}
          />
        );
      case "invoices":
        return (
          <AxoLocal
            key={typeShown}
            entity="TrashEntriesViewinvoicenr"
            defaultValue={"Fakturanummer"}
          />
        );
      case "templates":
        return (
          <AxoLocal
            key={typeShown}
            entity="TrashEntriesViewName"
            defaultValue={"Navn"}
          />
        );
      default:
        return "";
    }
  };

  confirmDelete = (confirmMessage, deleteFunc) => {
    ModalService.openConfirmModal(confirmMessage, (value) => {
      if (value) {
        deleteFunc();
      }
    });
  };

  onDeleteMessage = (message) => {
    let { deleteMessage } = this.props;

    this.confirmDelete(
      <AxoLocal
        entity="confirmMessagedeletethismessagepermanently"
        defaultValue={"Vil du slette denne besked permanent?"}
      />,
      () => {
        if (message.externalUniqueId) {
          DataActions.updateExternalMails({
            accountId: message.externalAccountId,
            messageIds: [message.externalUniqueId],
            delete: true,
            isSent: !!(
              message.sender && message.sender.id === this.state.userProfile.id
            ),
          });
        }
        deleteMessage(message.id);
      }
    );
  };

  onRestoreMessage = (message) => {
    let { updateMessageFromHeader } = this.props;

    updateMessageFromHeader({
      ...message,
      trashed: false,
    });
  };

  onDeleteCase = (caseModel) => {
    this.confirmDelete(
      <AxoLocal
        entity="confirmMessagedeletethisCasepermanently"
        defaultValue={"Vil du slette denne sag permanent?"}
      />,
      this.deleteCaseWithDocumentCheck.bind(this, caseModel)
    );
  };

  deleteCaseWithDocumentCheck = (caseModel) => {
    if (caseModel.documents.length > 0) {
      ModalService.openConfirmModal(
        <AxoLocal
          entity="confirmMessagedeletetattachmentsassociatedcase"
          defaultValue={"Vil du også slette bilag tilknyttet til sagen?"}
        />,
        (value) => {
          if (value) {
            this.deleteCasePermanently({ id: caseModel.id, deleteFiles: true });
          } else {
            this.deleteCasePermanently({
              id: caseModel.id,
              deleteFiles: false,
            });
          }
        }
      );
    } else {
      this.deleteCasePermanently({ id: caseModel.id, deleteFiles: false });
    }
  };

  deleteCasePermanently = (options) => {
    DataActions.deleteCase(options)
      .then((response) => {
        if (response.status !== 200) {
          ModalService.openAlertModal(
            <AxoLocal
              entity="confirmMessagecouldotbedeleted"
              defaultValue={"Sagen kunne ikke slettes. Prøv igen."}
            />
          );
        }
      })
      .catch(this.handleServerError);
  };

  handleServerError = (reason) => {
    ModalService.openAlertModal(
      <AxoLocal
        entity="confirmMessagecouldotbedeleted"
        defaultValue={"Sagen kunne ikke slettes. Prøv igen."}
      />
    );
  };

  onRestoreCase = (caseModel) => {
    caseModel.trashed = false;
    DataActions.updateCaseFromHeader(caseModel).then(() => {
      DataStore.fetchCases();
    });
  };

  onDeleteDocument = (documentId) => {
    this.confirmDelete(
      <AxoLocal
        entity="confirmMessagedocumentpermanently"
        defaultValue={"Vil du slette dette dokument permanent?"}
      />,
      () => {
        DataActions.deleteDocument(documentId)
          .then((response) => {
            if (!response.ok) {
              throw new Error();
            }
          })
          .catch((reason) => {
            console.log(reason);
            this.setErrorMessage(
              getText(
                "axoidcode46",
                "Filen kunne ikke slettes. Tjek at filen ikke er i brug andre steder i systemet."
              )
            );
          });
      }
    );
  };

  onRestoreDocument = (document) => {
    let doc = { ...document };
    doc.trashed = false;
    DataActions.updateDocument(doc);
  };

  onDeleteContact = (contactId) => {
    let { deleteContact } = this.props;

    this.confirmDelete(
      <div>
        <div>
          <AxoLocal
            entity="confirmMessagekontactpermanently"
            defaultValue={"Vil du slette denne kontakt permanent?"}
          />
        </div>
        <div className="axored">
          <AxoLocal
            entity="axoidcode243"
            defaultValue={
              "Dette vil slette alle data tilknyttet til denne klient."
            }
          />
        </div>
        <div className="axored">
          <AxoLocal
            entity="axoidcode244"
            defaultValue={"Sletningen kan IKKE fortrydes."}
          />
        </div>
      </div>,
      () => deleteContact(contactId)
    );
  };

  onRestoreContact = (contact) => {
    let { updateContact } = this.props;

    updateContact({
      ...contact,
      trashed: false,
    });
  };

  onDeleteTimeEntry = (timeEntryId) => {
    this.confirmDelete(
      <AxoLocal
        entity="confirmMessagetimeEntrypermanently"
        defaultValue={"Vil du slette denne tidsregistrering permanent?"}
      />,
      () => DataActions.deleteTimeEntry(timeEntryId)
    );
  };

  onRestoreTimeEntry = (timeEntry) => {
    timeEntry.trashed = false;
    DataActions.updateTimeEntry(timeEntry);
  };

  onDeleteInvoice = (invoiceId) => {
    this.confirmDelete(
      <AxoLocal
        entity="confirmMessageinvoicepermanently"
        defaultValue={"Vil du slette denne faktura permanent?"}
      />,
      () => DataActions.deleteInvoice(invoiceId)
    );
  };

  onRestoreInvoice = (invoice) => {
    invoice.trashed = false;
    DataActions.updateInvoice(invoice);
  };

  onDeleteTemplate = (templateId) => {
    this.confirmDelete(
      <AxoLocal
        entity="confirmMessagetemplatepermanently"
        defaultValue={"Vil du slette denne skabelon permanent?"}
      />,
      () => DataActions.deleteTemplate(templateId)
    );
  };

  onRestoreTemplate = (template) => {
    template.trashed = false;
    DataActions.updateTemplate(template);
  };

  getEntryNodes = () => {
    let { pageSize } = this.state;

    switch (this.props.typeShown) {
      case "messages":
        return {
          count: this.props.messages.length,
          nodes: this.props.messages.slice(0, pageSize).map((message) => {
            return (
              <tr key={message.id}>
                <td>{message.subject}</td>
                <td> {moment(message.date).format("L LT")}</td>
                <td>
                  <AxoLocal
                    componentClass={Icon}
                    role="button"
                    onClick={this.onDeleteMessage.bind(this, message)}
                    glyph="icon-fontello-trash-1"
                    componentAttribute="title"
                    className="editable"
                    entity="axoidcode179"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    role="button"
                    onClick={this.onRestoreMessage.bind(this, message)}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-loop"
                      componentAttribute="title"
                      className="editable"
                      entity="DocumentTrashTableViewrestore"
                    />
                    &nbsp;/
                    <AxoLocal
                      entity="DocumentTrashTableViewrestore"
                      defaultValue={"Gendan"}
                    />
                  </span>
                </td>
              </tr>
            );
          }),
        };
      case "cases":
        return {
          count: this.props.cases.length,
          nodes: this.props.cases.slice(0, pageSize).map((caseModel) => {
            return (
              <tr key={caseModel.id}>
                <td>
                  {caseModel.caseNumber +
                    (caseModel.documents.length > 0 ? " (med bilag)" : "")}
                </td>
                <td> {moment(caseModel.creationDate).format("L LT")}</td>
                <td>
                  <AxoLocal
                    componentClass={Icon}
                    role="button"
                    onClick={this.onDeleteCase.bind(this, caseModel)}
                    glyph="icon-fontello-trash-1"
                    componentAttribute="title"
                    className="editable"
                    entity="axoidcode179"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    role="button"
                    onClick={this.onRestoreCase.bind(this, caseModel)}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-loop"
                      componentAttribute="title"
                      className="editable"
                      entity="DocumentTrashTableViewrestore"
                    />
                    &nbsp;/
                    <AxoLocal
                      entity="DocumentTrashTableViewrestore"
                      defaultValue={"Gendan"}
                    />
                  </span>
                </td>
              </tr>
            );
          }),
        };
      case "documents":
        return {
          count: this.props.documents.length,
          nodes: this.props.documents.slice(0, pageSize).map((document) => {
            return (
              <tr key={document.id}>
                <td>{document.fileName}</td>
                <td>{moment(document.uploadDate).format("L LT")}</td>
                <td>
                  <AxoLocal
                    componentClass={Icon}
                    role="button"
                    className="editable"
                    onClick={this.onDeleteDocument.bind(this, document.id)}
                    glyph="icon-fontello-trash-1"
                    componentAttribute="title"
                    entity="axoidcode179"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    role="button"
                    onClick={this.onRestoreDocument.bind(this, document)}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-loop"
                      componentAttribute="title"
                      className="editable"
                      entity="DocumentTrashTableViewrestore"
                    />
                    &nbsp;/
                    <AxoLocal
                      entity="DocumentTrashTableViewrestore"
                      defaultValue={"Gendan"}
                    />
                  </span>
                </td>
              </tr>
            );
          }),
        };
      case "contacts":
        return {
          count: this.props.contacts.length,
          nodes: this.props.contacts.slice(0, pageSize).map((contact) => {
            return (
              <tr key={contact.id}>
                <td>
                  {contact.lastName}, {contact.firstName}
                </td>
                <td>{contact.address}</td>
                <td>
                  <AxoLocal
                    componentClass={Icon}
                    role="button"
                    onClick={this.onDeleteContact.bind(this, contact.id)}
                    glyph="icon-fontello-trash-1"
                    componentAttribute="title"
                    className="editable"
                    entity="axoidcode179"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    role="button"
                    onClick={this.onRestoreContact.bind(this, contact)}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-loop"
                      componentAttribute="title"
                      className="editable"
                      entity="DocumentTrashTableViewrestore"
                    />
                    &nbsp;/
                    <AxoLocal
                      entity="DocumentTrashTableViewrestore"
                      defaultValue={"Gendan"}
                    />
                  </span>
                </td>
              </tr>
            );
          }),
        };
      case "timeEntries":
        return {
          count: this.props.timeEntries.length,
          nodes: this.props.timeEntries.slice(0, pageSize).map((timeEntry) => {
            return (
              <tr key={timeEntry.id}>
                <td>{timeEntry.description}</td>
                <td>{moment(timeEntry.workDate).format("L LT")}</td>
                <td>
                  <AxoLocal
                    componentClass={Icon}
                    role="button"
                    onClick={this.onDeleteTimeEntry.bind(this, timeEntry.id)}
                    glyph="icon-fontello-trash-1"
                    componentAttribute="title"
                    className="editable"
                    entity="axoidcode179"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    role="button"
                    onClick={this.onRestoreTimeEntry.bind(this, timeEntry)}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-loop"
                      componentAttribute="title"
                      className="editable"
                      entity="DocumentTrashTableViewrestore"
                    />
                    &nbsp;/
                    <AxoLocal
                      entity="DocumentTrashTableViewrestore"
                      defaultValue={"Gendan"}
                    />
                  </span>
                </td>
              </tr>
            );
          }),
        };
      case "invoices":
        return {
          count: this.props.invoices.length,
          nodes: this.props.invoices.slice(0, pageSize).map((invoice) => {
            return (
              <tr key={invoice.id}>
                <td>
                  {!!invoice.number ? "#" + invoice.number.toString() : "#0"}
                </td>
                <td>{moment(invoice.creationDate).format("L LT")}</td>
                <td>
                  <AxoLocal
                    componentClass={Icon}
                    role="button"
                    onClick={this.onDeleteInvoice.bind(this, invoice.id)}
                    glyph="icon-fontello-trash-1"
                    componentAttribute="title"
                    className="editable"
                    entity="axoidcode179"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    role="button"
                    onClick={this.onRestoreInvoice.bind(this, invoice)}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-loop"
                      componentAttribute="title"
                      className="editable"
                      entity="DocumentTrashTableViewrestore"
                    />
                    &nbsp;/
                    <AxoLocal
                      entity="DocumentTrashTableViewrestore"
                      defaultValue={"Gendan"}
                    />
                  </span>
                </td>
              </tr>
            );
          }),
        };
      case "templates":
        return {
          count: this.props.contacts.length,
          nodes: this.props.templates.slice(0, pageSize).map((template) => {
            return (
              <tr key={template.id}>
                <td>{template.title}</td>
                <td>{moment(template.creationDate).format("L LT")}</td>
                <td>
                  <AxoLocal
                    componentClass={Icon}
                    role="button"
                    onClick={this.onDeleteTemplate.bind(this, template.id)}
                    glyph="icon-fontello-trash-1"
                    componentAttribute="title"
                    className="editable"
                    entity="axoidcode179"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    role="button"
                    onClick={this.onRestoreTemplate.bind(this, template)}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-loop"
                      componentAttribute="title"
                      className="editable"
                      entity="DocumentTrashTableViewrestore"
                    />
                    &nbsp;/
                    <AxoLocal
                      entity="DocumentTrashTableViewrestore"
                      defaultValue={"Gendan"}
                    />
                  </span>
                </td>
              </tr>
            );
          }),
        };
      default:
        return {
          count: 0,
          nodes: [],
        };
    }
  };

  renderEntriesTable = () => {
    let entryNodes = this.getEntryNodes();
    if (entryNodes.nodes.length === 0) {
      entryNodes.nodes.push(
        <tr key={0}>
          <td>
            {" "}
            <AxoLocal
              entity="DocumentTherubbishbinisempty"
              defaultValue={"Skraldespanden er tom"}
            />{" "}
          </td>
          <td></td>
          <td></td>
        </tr>
      );
    }
    return (
      <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
        <Table
          ref={(c) => (this.example = c)}
          responsive
          id="timeTable"
          className="display"
          cellSpacing="0"
          width="100%"
        >
          <thead>
            <tr>
              <th>{this.getNameHeader()}</th>
              <th>
                <AxoLocal
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>{this.getNameHeader()}</th>
              <th>
                <AxoLocal
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
              </th>
              <th></th>
            </tr>
          </tfoot>
          <tbody>{entryNodes.nodes}</tbody>
        </Table>
        {entryNodes.count > entryNodes.nodes.length ? (
          <LexButton
            onClick={() =>
              this.setState((prevState) => ({
                pageSize: prevState.pageSize + 50,
              }))
            }
          >
            <AxoLocal entity="ViewShowmoreinfo" defaultValue={"Vis flere"} />
          </LexButton>
        ) : null}
      </div>
    );
  };

  getEmptyTrashLabel = () => {
    return (
      <AxoLocal
        entity="TimeEntryFormntimeEntryEmptygarbagebin"
        defaultValue={"Empty trashcan"}
      />
    );
  };

  onEmptyTrash = () => {
    ModalService.openConfirmModal(this.getEmptyTrashMessage(), (value) => {
      if (value) {
        this.emptyCurrentTrashcan();
      }
    });
  };

  getEmptyTrashMessage = () => {
    return (
      <span>
        ({this.getTypeCount(this.props.typeShown)}){" "}
        <AxoLocal
          entity="ThrashEntriesViewDeletePermanently"
          defaultValue={"Slet permanent?"}
        />
      </span>
    );
  };

  getTypeCount = (typeName) => {
    switch (typeName) {
      case "messages":
        return this.props.messages.length;
      case "cases":
        return this.props.cases.length;
      case "documents":
        return this.props.documents.length;
      case "contacts":
        return this.props.contacts.length;
      case "timeEntries":
        return this.props.timeEntries.length;
      case "invoices":
        return this.props.invoices.length;
      case "templates":
        return this.props.templates.length;
      default:
        return 0;
    }
  };

  emptyCurrentTrashcan = async () => {
    let { deleteContact, deleteMessage } = this.props;

    this.setState({ isDeleting: true });
    var promises = [];
    switch (this.props.typeShown) {
      case "messages":
        this.props.messages.forEach((message) => {
          promises.push(deleteMessage(message.id).unwrap());
        });
        DataActions.deleteExternalMessages(
          this.props.messages,
          this.props.userProfile.id
        );
        break;
      case "cases":
        if (this.props.cases.find((c) => c.documents.length > 0)) {
          ModalService.openConfirmModal(
            <AxoLocal
              entity="TimeEntryFormntimeEntrydeletethedocuments"
              defaultValue={"Vil du også slette sagernes bilag?"}
            />,
            (value) => {
              this.props.cases.forEach((caseModel) => {
                promises.push(
                  ApiService.deleteCase({
                    id: caseModel.id,
                    deleteFiles: value,
                  })
                );
              });
            }
          );
        } else {
          this.props.cases.forEach((caseModel) => {
            promises.push(
              ApiService.deleteCase({ id: caseModel.id, deleteFiles: false })
            );
          });
        }
        break;
      case "documents":
        this.props.documents.forEach((document) => {
          promises.push(ApiService.deleteDocument(document.id));
        });
        break;
      case "contacts":
        for (let contact of this.props.contacts) {
          //Process contacts in sequence
          try {
            await deleteContact(contact.id).unwrap();
          } catch {
            this.setErrorMessage(
              <AxoLocal
                entity="deleteAllFromAllTrashcanssetErrorMessage"
                defaultValue={
                  "Der skete en fejl under sletningen. Tjek at ingen af elementerne er i brug andre steder i systemet."
                }
              />
            );
          }
        }
        break;
      case "timeEntries":
        this.props.timeEntries.forEach((timeEntry) => {
          promises.push(ApiService.deleteTimeEntry(timeEntry.id));
        });
        break;
      case "invoices":
        this.props.invoices.forEach((invoice) => {
          promises.push(ApiService.deleteInvoice(invoice.id));
        });
        break;
      case "templates":
        this.props.templates.forEach((template) => {
          promises.push(ApiService.deleteTemplate(template.id));
        });
        break;
      default:
        break;
    }
    this.resolveDeletePromises(promises).then(() => {
      DataStore.fetchCases();
      DataStore.fetchSharedCases();
      DataStore.fetchDocuments();
      DataStore.fetchTimeEntries();
      DataStore.fetchInvoices();
      DataStore.fetchTemplates();
    });
  };

  getEmptyAllLabel = () => {
    return (
      <AxoLocal
        entity="TimeEntryEmptyalltrashcans"
        defaultValue={"Tøm alle skraldespande"}
      />
    );
  };

  onEmptyAll = () => {
    ModalService.openConfirmModal(
      <AxoLocal
        entity="TimeEntryopenConfirmModal"
        defaultValue={
          "Vil du slette alle elementer i alle skraldespande permanent?"
        }
      />,
      (value) => {
        if (value) {
          if (this.props.cases.find((c) => c.documents.length > 0)) {
            ModalService.openConfirmModal(
              <AxoLocal
                entity="deleteAllFromAllTrashcans"
                defaultValue={"Vil du også slette sagernes bilag?"}
              />,
              (value) => {
                this.deleteAllFromAllTrashcans(value);
              }
            );
          } else {
            this.deleteAllFromAllTrashcans(false);
          }
        }
      }
    );
  };

  deleteAllFromAllTrashcans = async (includeFiles) => {
    let { deleteContact, deleteMessage } = this.props;

    this.setState({ isDeleting: true });
    var promises = [];
    this.props.messages.forEach((message) => {
      promises.push(deleteMessage(message.id).unwrap());
    });
    this.props.cases.forEach((caseModel) => {
      promises.push(
        ApiService.deleteCase({ id: caseModel.id, deleteFiles: includeFiles })
      );
    });
    this.props.documents.forEach((document) => {
      promises.push(ApiService.deleteDocument(document.id));
    });
    for (let contact of this.props.contacts) {
      //Process contacts in sequence
      try {
        await deleteContact(contact.id).unwrap();
      } catch {
        this.setErrorMessage(
          <AxoLocal
            entity="deleteAllFromAllTrashcanssetErrorMessage"
            defaultValue={
              "Der skete en fejl under sletningen. Tjek at ingen af elementerne er i brug andre steder i systemet."
            }
          />
        );
      }
    }
    this.props.timeEntries.forEach((timeEntry) => {
      promises.push(ApiService.deleteTimeEntry(timeEntry.id));
    });
    this.props.invoices.forEach((invoice) => {
      promises.push(ApiService.deleteInvoice(invoice.id));
    });
    this.props.templates.forEach((template) => {
      promises.push(ApiService.deleteTemplate(template.id));
    });
    this.resolveDeletePromises(promises).then(() => {
      DataStore.fetchCases();
      DataStore.fetchSharedCases();
      DataStore.fetchDocuments();
      DataStore.fetchTimeEntries();
      DataStore.fetchInvoices();
      DataStore.fetchTemplates();
    });
  };

  resolveDeletePromises = (promises) => {
    return Promise.all(promises)
      .then((responses) => {
        this.setState({ isDeleting: false });
        var errorCount = responses.filter((r) => r.status !== 200).length;
        if (errorCount > 0) {
          this.setErrorMessage(
            <span>
              {errorCount.toString()}&nbsp;
              <AxoLocal
                entity="deleteAllFromAllTrashcansSetCountErrorMessage"
                defaultValue={
                  "elementer kunne ikke slettes. Tjek at disse elementer ikke er i brug andre steder i systemet."
                }
              />
            </span>
          );
        }
      })
      .catch((error) => {
        this.setState({ isDeleting: false });
        this.setErrorMessage(
          <AxoLocal
            entity="deleteAllFromAllTrashcanssetErrorMessage"
            defaultValue={
              "Der skete en fejl under sletningen. Tjek at ingen af elementerne er i brug andre steder i systemet."
            }
          />
        );
      });
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 10000);
  };

  render() {
    var { isDeleting } = this.state;
    return (
      <div>
        <div className="inbox">
          <div style={{ minHeight: "800px", paddingTop: 0 }}>
            <Grid fluid className="nopadding">
              <Row>
                <Col xs={8} style={{ paddingBottom: 5 }}>
                  <ButtonToolbar
                    style={{ paddingTop: "5px", paddingLeft: "20px" }}
                  >
                    <ButtonGroup>
                      <Button
                        className="Lex-button-2"
                        onClick={this.onEmptyTrash}
                      >
                        <Icon glyph="icon-fontello-trash-4" />{" "}
                        {this.getEmptyTrashLabel()}
                        {isDeleting ? (
                          <span>
                            &nbsp;
                            <img
                              alt=""
                              src="/imgs/app/loading.gif"
                              className="img-circle"
                              width="20"
                              height="20"
                            />
                          </span>
                        ) : null}
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                      <Button
                        className="Lex-button-2"
                        onClick={this.onEmptyAll}
                      >
                        <Icon glyph="icon-fontello-trash-1" />{" "}
                        {this.getEmptyAllLabel()}
                        {isDeleting ? (
                          <span>
                            &nbsp;
                            <img
                              alt=""
                              src="/imgs/app/loading.gif"
                              className="img-circle"
                              width="20"
                              height="20"
                            />
                          </span>
                        ) : null}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Grid>
            <div style={{ paddingLeft: "25px" }}>
              <Col md={3} sm={5} className="nopadding">
                {this.renderLeftPanel()}
              </Col>
              <Col md={9} sm={7} xs={12} className="nopadding">
                <Grid fluid>
                  <Row>
                    <Col xs={12}>
                      {this.state.errorMessage ? (
                        <div className="axored">
                          <b>{this.state.errorMessage}</b>
                        </div>
                      ) : null}
                      {this.renderEntriesTable()}
                    </Col>
                  </Row>
                </Grid>
              </Col>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TrashEntriesView);
