import React from "react";
import { Link } from "react-router-dom";
import FlagMenu from "../../utilities/FlagMenu";

import { Row, Col, Grid } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

const PublicLayoutHeader = (props) => {
  if (props.companyHeader) {
    return <CompanyHeader locale={props.locale} header={props.header} />;
  }
  return <UserHeader header={props.header} locale={props.locale} />;
};

const CompanyHeader = (props) => {
  return (
    <div
      className="testbackground"
      style={{ position: "relative", zIndex: 100 }}
    >
      <div className="testtransbox text-center">
        <div
          className="flexbox-layout TopMenuLinknav "
          style={{ border: "0px", paddingLeft: "1%", paddingRight: "1%" }}
        >
          <div>
            <FlagMenu
              className="Lex-button-1xlink AxoNavigationButtonheight"
              style={{ bottom: 0, left: 0, right: 0, zIndex: 1000 }}
              userSettings={{
                locale: props.locale,
              }}
            />
          </div>
          <div style={{ color: "black" }}>
            {props.header ? (
              <span>{props.header}</span>
            ) : (
              <AxoLocal
                entity="presentationMarketing31"
                defaultValue="Offentlige indlæg"
              />
            )}
          </div>
          <div style={{ color: "black" }}>
            <span>
              <Icon role="button" glyph="icon-fontello-plus-3" /> &nbsp;&nbsp;
              <Link to="/xlink/account/Signup">
                <AxoLocal
                  entity="presentationMarketing32"
                  defaultValue="Opret konto"
                />
              </Link>
            </span>
          </div>
          <div style={{ color: "black" }}>
            <Link to="/xlink/account/Login">
              <span>
                <Icon
                  className="frontnavbarimg"
                  bundle="fontello"
                  glyph="off-1"
                />
                &nbsp;&nbsp;
                <AxoLocal
                  className="hidden-sm hidden-xs"
                  key="login"
                  entity="LoginonLogin"
                  defaultValue={"Log ind"}
                />
              </span>
            </Link>
          </div>
        </div>

        {/* <span style={{ fontSize: '25px',color:' black' }} >
          
        </span> &nbsp;&nbsp;&nbsp;&nbsp;
        
          &nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{ fontSize: '25px' }} >
          <Link to='/xlink/account/Signup'><AxoLocal entity='presentationMarketing32' defaultValue='Opret konto'/></Link>
        </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
         */}
      </div>
    </div>
  );
};

const UserHeader = (props) => {
  return (
    <div
      className="testbackground"
      style={{ position: "relative", zIndex: 100 }}
    >
      <div className="testtransbox text-center flexbox-center">
        <div style={{ fontSize: "25px", color: " black" }}>
          {props.header || (
            <AxoLocal
              entity="presentationMarketing33"
              defaultValue="Web Link"
            />
          )}
          &nbsp;&nbsp; &nbsp;&nbsp;
        </div>
        <div>
          <FlagMenu
            className="Lex-button-1xlink AxoNavigationButtonheight"
            userSettings={{
              locale: props.locale,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default class PublicLayoutView extends React.PureComponent {
  render() {
    return (
      <Grid fluid style={{ paddingTop: "0px" }}>
        <Row>
          <Col xs={12} className="nopadding" style={{ paddingTop: "0px" }}>
            <PublicLayoutHeader
              companyHeader={this.props.companyHeader}
              header={this.props.header}
              locale={this.props.locale}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="nopadding" style={{ padding: "0px" }}>
            <div className="bodyxlink text-center">{this.props.children}</div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="nopadding">
            <div
              className="testbackground"
              style={{ position: "relative", zIndex: 100 }}
            >
              <div
                className="testtransbox text-center"
                style={{ bottom: 0, left: 0, right: 0, zIndex: 1000 }}
              >
                <span className="Axooverskrifttext">
                  <Link to="/XLink/Account/Signup" style={{ color: "black" }}>
                    <span>
                      {" "}
                      &#9755;
                      <AxoLocal
                        entity="presentationMarketing29"
                        defaultValue="Lav dine egne præsentationer"
                      />
                      &nbsp;&nbsp;
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-md"
                        src="/imgs/app/Axologo3-30x30.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-md hidden-xs"
                        src="/imgs/app/Axologo3-35x35.png"
                      />
                      <img
                        alt=""
                        className="hidden-lg hidden-sm hidden-xs"
                        src="/imgs/app/Axologo3-50x50.png"
                      />
                      <img
                        alt=""
                        className="hidden-md hidden-sm hidden-xs"
                        src="/imgs/app/Axologo3-60x60.png"
                      />
                    </span>{" "}
                    &nbsp; &#9754;
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
