import React from "react";

import { LexButton, getText } from "../utilities/LexUtilities";

import { ApiService } from "../services/AxoServices";

import {
  Row,
  Col,
  Grid,
} from "react-bootstrap";


// import LexButton from '../utilities/LexButton';

export default class TestView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lightOn: false,
    };

    this.name = "TestView";
  }

  onLight = () => {
    let { lightOn } = this.state;

    if(!lightOn) {
      ApiService.lightOn();
    }
    else {
      ApiService.lightOff();
    }

    this.setState({
      lightOn: !lightOn
    })
  };

  render() {
    let { lightOn } = this.state;

    return (
      <div>
        <Grid fluid className="nopadding">
          <Row>
            <Col xs={12} style={{ maxWidth: "100%" }}>
              <div className="frontPageaxolexdiv Bannecolorlogin flexbox-center">
                Axolex overvågning
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img
                    alt=""
                    className="hidden-lg hidden-sm hidden-md"
                    src="/imgs/app/Axologo3-30x30.png"
                  />
                  <img
                    alt=""
                    className="hidden-lg hidden-md hidden-xs"
                    src="/imgs/app/Axologo3-35x35.png"
                  />
                  <img
                    alt=""
                    className="hidden-lg hidden-sm hidden-xs"
                    src="/imgs/app/Axologo3-40x40.png"
                  />
                  <img
                    alt=""
                    className="hidden-md hidden-sm hidden-xs"
                    src="/imgs/app/Axologo3-40x40.png"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
            </Col>
          </Row>
        </Grid>
        {/* <LexButton onClick={this.onPay}>Betalingstest</LexButton> */}
        <div className='leftPadding topPadding'>
          {/* <video id="vid1" className="azuremediaplayer amp-default-skin" autoPlay controls width={640} height={400} poster="poster.jpg" data-setup="{&quot;techOrder&quot;: [&quot;azureHtml5JS&quot;, &quot;flashSS&quot;, &quot;html5FairPlayHLS&quot;,&quot;silverlightSS&quot;, &quot;html5&quot;], &quot;nativeControlsForTouch&quot;: false, &quot;logo&quot;: {&quot;enabled&quot;: false}}">
            <source src="https://axolexlivefast-axolexmediaservices.preview-euwe.channel.media.azure.net/11eab9e2-4c9a-46cd-b54e-1943c9f58b0a/preview.ism/manifest" type="application/vnd.ms-sstr+xml" />
            <p className="amp-no-js">
              To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video
            </p>
          </video> */}
        <iframe
          title="axoframe"
          src="https://da61-89-23-224-124.ngrok-free.app"
          // onLoad={this.onLoadFrame}
          className="seamless"
          sandbox="allow-same-origin allow-scripts allow-popups allow-top-navigation allow-forms"
          height="500px"
          // ref={ c => this.frame = c }
        />
        </div>
        <div className="leftPadding topPadding">
          <LexButton 
            key={lightOn.toString()}
            onClick={this.onLight}
          >
            { !lightOn ? "Tænd lys" : "Sluk lys"}
          </LexButton>
        </div>
      </div>
    );
  }
}
