import React from "react";
import PropTypes from "prop-types";
// import Select from 'react-select'
import moment from "moment";
import { ApiService } from "../services/AxoServices";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import {
  LexButton,
  AsyncButton,
  AxoCheckbox,
  AxoDateTime,
  // ImageSearchBox,
  // UploadButton,
  AxoLocal,
  Icon,
} from "../utilities/LexUtilities";

//This form receives starting state and submit function from outside.
export default class BusinessOperatingFormView extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    startValue: PropTypes.object,
  };

  constructor(props) {
    super(props);

    if (props.startValue) {
      this.state = { ...props.startValue };
    } else {
      this.state = this.getStartState();
    }
  }
  onUploadImage = async (image) => {
    let response = await ApiService.uploadFileObjects([image]);
    if (!response.ok) {
      return false;
    }

    let addedFiles = await response.json();
    if (!addedFiles || addedFiles.length === 0) {
      return;
    }

    let addedFile = addedFiles[0];

    this.setState({
      pictureID: addedFile.id,
      picture: addedFile,
    });

    return response.ok;
  };

  getStartState = () => {
    return {
      companyName: "",
      ownerName: "",
      ownerAddress: "",
      ownerCode: "",
      shareCourse: 100,
      latestSignedDate: moment.utc(),
      latestPaidDate: moment.utc(),
      companyActiveDate: moment.utc(),
      companyAccountingDate: moment.utc(),
      accountingRequired: false,
      accountingFirmName: "",
      accountingFirmCode: "",
      directorName: "",
      signingDate: moment.utc(),
      foundingCosts: 0,
      founderName: "",
      companyPurpose: "",
      companyCapital: 40000,
      shareValue: 400,
      shareVotes: 1,
      fiscalYearStart: moment.utc().startOf("year").format("DD-MM"),
      fiscalYearEnd: moment.utc().endOf("year").format("DD-MM"),
      firstFiscalYearStart: moment.utc().startOf("year"),
      firstFiscalYearEnd: moment.utc().endOf("year"),
      signingRule: "",
      approvedDate: moment.utc(),
      includeAlcoholRules: false,
      text: "",
      showValidationError: false,
      owners: [],
      shareholding: "",
      votingRights: "",
    };
  };

  handleChange = (event) => {
    let { type, name } = event.target;
    let value = null;
    switch (type) {
      case "number":
        value = event.target.valueAsNumber;
        break;
      case "checkbox":
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
    }

    this.onUpdateProperty(name, value);
  };

  onUpdateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  onSubmit = async () => {
    let { onSubmit } = this.props;

    let result = await onSubmit(this.state);
    return result;
  };

  onCancel = () => {
    let { onCancel, startValue } = this.props;

    if (onCancel) {
      onCancel();
      return;
    }

    if (startValue) {
      this.setState(this.props.startValue);
    } else {
      this.setState(this.getStartState());
    }
  };

  tempId = 0;

  onAddOwner = () => {
    this.setState((prevState) => ({
      owners: [
        ...prevState.owners,
        {
          tempId: ++this.tempId,
          name: "",
          address: "",
          identityCode: "",
          shareholding: "100%",
          votingRights: "100%",
        },
      ],
    }));
  };

  onRemoveOwner = (index) => {
    this.setState((prevState) => ({
      owners: prevState.owners.filter((t, i) => i !== index),
    }));
  };
  onUpdateOwnerProperty = (index, propertyName, value) => {
    this.setState((prevState) => ({
      owners: prevState.owners.map((owner, i) => {
        if (i !== index) {
          return owner;
        }
        return {
          ...owner,
          [propertyName]: value,
        };
      }),
    }));
  };

  //Skabelon. Der skal ikke laves entities
  render() {
    // let {
    //   documentMap
    // } = this.props;
    let {
      companyName,
      shareCourse,
      latestSignedDate,
      latestPaidDate,
      companyActiveDate,
      companyAccountingDate,
      accountingRequired,
      accountingFirmName,
      accountingFirmCode,
      directorName,
      signingDate,
      foundingCosts,
      companyPurpose,
      companyCapital,
      shareValue,
      shareVotes,
      fiscalYearStart,
      fiscalYearEnd,
      firstFiscalYearStart,
      firstFiscalYearEnd,
      signingRule,
      approvedDate,
      includeAlcoholRules,
      // picture,
      // pictureID,
      owners,
      text,
    } = this.state;

    // let selectOptions = [
    //   { value: 'First', label: 'First' },
    //   { value: 'Second', label: 'Second' },
    //   { value: 'Third', label: 'Third' },
    // ];

    // let typeOption = selectOptions.find(o => o.value === type) || selectOptions[0];

    return (
      <Form className="leftPadding standardMaxWidth">
        <FormGroup controlId="companyName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Selskabets navn (XXX ApS/IVS osv.)
          </ControlLabel>
          <FormControl
            name="companyName" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={companyName}
            onChange={this.handleChange}
          />
        </FormGroup>

        <div className="bottomPadding">
          <LexButton onClick={this.onAddOwner}>Tilføj Ejer</LexButton>
        </div>
        {!!owners
          ? owners.map((owner, index) => (
              <div key={owner.id || owner.tempId}>
                <div className="largeText">
                  Ejer# {index + 1}&nbsp;&nbsp;&nbsp;
                  <Icon
                    role="button"
                    onClick={() => this.onRemoveOwner(index)}
                    glyph="icon-fontello-trash-1"
                  />
                </div>
                <FormGroup>
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal
                      entity="SidebarRightContaineruseruserName"
                      defaultValue={"Navn"}
                    />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    className="borderFormControlfocus"
                    value={owner.name}
                    onChange={(event) =>
                      this.onUpdateOwnerProperty(
                        index,
                        "name",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    className="borderFormControlfocus"
                    value={owner.address}
                    onChange={(event) =>
                      this.onUpdateOwnerProperty(
                        index,
                        "address",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    CPR/CVR:
                  </ControlLabel>
                  <FormControl
                    type="text"
                    className="borderFormControlfocus"
                    value={owner.identityCode}
                    onChange={(event) =>
                      this.onUpdateOwnerProperty(
                        index,
                        "identityCode",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    Ejerandel:
                  </ControlLabel>
                  <FormControl
                    type="text"
                    className="borderFormControlfocus"
                    value={owner.shareholding}
                    onChange={(event) =>
                      this.onUpdateOwnerProperty(
                        index,
                        "shareholding",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    Stemmerettigheder:
                  </ControlLabel>
                  <FormControl
                    type="text"
                    className="borderFormControlfocus"
                    value={owner.votingRights}
                    onChange={(event) =>
                      this.onUpdateOwnerProperty(
                        index,
                        "votingRights",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
              </div>
            ))
          : null}

        {/* <FormGroup>
        { !!picture && pictureID ? (
          <div>
            <div>
              &nbsp;<Icon 
                role='button' 
                glyph='icon-fontello-scissors axored'
                onClick={() => this.setState({ pictureID: null } )}
              />
            </div>
            <div style={{ maxWidth: '250px'}}>
              <img width='100%' alt='' src={ApiService.getFileInlinePath(pictureID)} />
            </div>
          </div>
        ) : (
          <div>
            <div className='bottomPadding'>
              <ImageSearchBox
                documentMap={documentMap}
                handleSelectedDocument={document => this.setState({ pictureID: document.id, picture: document })}
                placeholder='Find billede...'
              />
            </div>
            <UploadButton
              onlyImages
              onSelected={this.onUploadImage}
            >
              Upload billede
            </UploadButton>
          </div>
        ) }
        </FormGroup> */}

        {/* <FormGroup controlId='ownerName'>
          <ControlLabel style={{ textAlign: 'left', fontSize:'16px', fontWeight:'normal' }}>
          Ejerens navn
          </ControlLabel>
          <FormControl
            name='ownerName' //Name must correspond to the name of the property
            type='text'
            className='borderFormControlfocus'
            value={ownerName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId='ownerAddress'>
          <ControlLabel style={{ textAlign: 'left', fontSize:'16px', fontWeight:'normal' }}>
          Ejerens adresse
          </ControlLabel>
          <FormControl
            name='ownerAddress' //Name must correspond to the name of the property
            type='text'
            className='borderFormControlfocus'
            value={ownerAddress}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId='ownerCode'>
          <ControlLabel style={{ textAlign: 'left', fontSize:'16px', fontWeight:'normal' }}>
           CVRnr.
          </ControlLabel>
          <FormControl
            name='ownerCode' //Name must correspond to the name of the property
            type='text'
            className='borderFormControlfocus'
            value={ownerCode}
            onChange={this.handleChange}
          />
        </FormGroup> */}

        <hr />
        <FormGroup controlId="companyCapital">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Selskabskapital
          </ControlLabel>
          <FormControl
            name="companyCapital" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={companyCapital}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="shareValue">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Anpartsværdi
          </ControlLabel>
          <FormControl
            name="shareValue" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={shareValue}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="shareCourse">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Anparts tegningskurs
          </ControlLabel>
          <FormControl
            name="shareCourse" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={shareCourse}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="shareVotes">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Stemmer per anpart
          </ControlLabel>
          <FormControl
            name="shareVotes"
            type="text"
            className="borderFormControlfocus"
            value={shareVotes}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="latestSignedDate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Seneste tegningsdato
          </ControlLabel>
          <AxoDateTime
            value={moment(latestSignedDate)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("latestSignedDate", dateTime.format())
            }
          />
        </FormGroup>

        <FormGroup controlId="latestPaidDate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Seneste indbetalingsdato
          </ControlLabel>
          <AxoDateTime
            value={moment(latestPaidDate)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("latestPaidDate", dateTime.format())
            }
          />
        </FormGroup>

        <FormGroup controlId="companyActiveDate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Retsvirkningsdato
          </ControlLabel>
          <AxoDateTime
            value={moment(companyActiveDate)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("companyActiveDate", dateTime.format())
            }
          />
        </FormGroup>

        <FormGroup controlId="companyAccountingDate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Regnskabdato
          </ControlLabel>
          <AxoDateTime
            value={moment(companyAccountingDate)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("companyAccountingDate", dateTime.format())
            }
          />
        </FormGroup>
        <FormGroup controlId="accountingRequired">
          <AxoCheckbox
            name="accountingRequired" //Name must correspond to the name of the property
            type="checkbox"
            className="borderFormControlfocus"
            value={accountingRequired}
            onChange={this.handleChange}
          />
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Selskabet skal underlægges revision
          </ControlLabel>
        </FormGroup>

        {accountingRequired && (
          <>
            <FormGroup controlId="accountingFirmName">
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                Navn på revisionsvirksomhed
              </ControlLabel>
              <FormControl
                name="accountingFirmName" //Name must correspond to the name of the property
                type="text"
                className="borderFormControlfocus"
                value={accountingFirmName}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup controlId="accountingFirmCode">
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                CVR-nummer på revisionsvirksomhed
              </ControlLabel>
              <FormControl
                name="accountingFirmCode" //Name must correspond to the name of the property
                type="text"
                className="borderFormControlfocus"
                value={accountingFirmCode}
                onChange={this.handleChange}
              />
            </FormGroup>
          </>
        )}

        <FormGroup controlId="directorName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Som direktør valgtes... (f.eks. Ole Jensen og Jens Hansen)
          </ControlLabel>
          <FormControl
            name="directorName" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={directorName}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="signingRule">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Tegningsregel
          </ControlLabel>
          <FormControl
            name="signingRule" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={signingRule}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="foundingCosts">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Stiftelsesomkostninger
          </ControlLabel>
          <FormControl
            name="foundingCosts" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={foundingCosts}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="signingDate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Underskriftsdato
          </ControlLabel>
          <AxoDateTime
            value={moment(signingDate)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("signingDate", dateTime.format())
            }
          />
        </FormGroup>

        {/* <FormGroup controlId='founderName'>
          <ControlLabel style={{ textAlign: 'left', fontSize:'16px', fontWeight:'normal' }}>
          Navn på stifter
          </ControlLabel>
          <FormControl
            name='founderName' //Name must correspond to the name of the property
            type='text'
            className='borderFormControlfocus'
            value={founderName}
            onChange={this.handleChange}
          />
        </FormGroup> */}

        <FormGroup controlId="companyPurpose">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Selskabets formål
          </ControlLabel>
          <FormControl
            name="companyPurpose"
            componentClass="textarea"
            className="borderFormControlfocus"
            value={companyPurpose}
            onChange={this.handleChange}
            rows={3}
          />
        </FormGroup>

        {/* <FormGroup controlId='companyCapital'>
          <ControlLabel style={{ textAlign: 'left', fontSize:'16px', fontWeight:'normal' }}>
          Selskabets formål
          </ControlLabel>
          <FormControl
            name='companyCapital'
            type='text'
            className='borderFormControlfocus'
            value={companyCapital}
            onChange={this.handleChange}
          />
        </FormGroup> */}

        {/* <FormGroup controlId='shareValue'>
          <ControlLabel style={{ textAlign: 'left', fontSize:'16px', fontWeight:'normal' }}>
          Anpartsværdi
          </ControlLabel>
          <FormControl
            name='shareValue'
            type='text'
            className='borderFormControlfocus'
            value={shareValue}
            onChange={this.handleChange}
          />
        </FormGroup> */}

        <FormGroup controlId="fiscalYearStart">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Start på regnskabsår
          </ControlLabel>
          <FormControl
            name="fiscalYearStart" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={fiscalYearStart}
            onChange={this.handleChange}
          />
          {/* <AxoDateTime
            value={moment(fiscalYearStart)}
            dateFormat={true}
            timeFormat= {false}
            utc
            onChange={dateTime => this.onUpdateProperty('fiscalYearStart', dateTime.format())}
          /> */}
        </FormGroup>

        <FormGroup controlId="fiscalYearEnd">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Slut på regnskabsår
          </ControlLabel>
          <FormControl
            name="fiscalYearEnd" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={fiscalYearEnd}
            onChange={this.handleChange}
          />
          {/* <AxoDateTime
            value={moment(fiscalYearEnd)}
            dateFormat={true}
            timeFormat= {false}
            utc
            onChange={dateTime => this.onUpdateProperty('fiscalYearEnd', dateTime.format())}
          /> */}
        </FormGroup>
        <FormGroup controlId="firstFiscalYearStart">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Start på første regnskabsår
          </ControlLabel>

          <AxoDateTime
            value={moment(firstFiscalYearStart)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("firstFiscalYearStart", dateTime.format())
            }
          />
        </FormGroup>

        <FormGroup controlId="firstFiscalYearEnd">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Afslutning på første regnskabsår
          </ControlLabel>
          <AxoDateTime
            value={moment(firstFiscalYearEnd)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("firstFiscalYearEnd", dateTime.format())
            }
          />
        </FormGroup>

        <FormGroup controlId="signingRule">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Tegningsregel
          </ControlLabel>
          <FormControl
            name="signingRule" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={signingRule}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="approvedDate">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Stiftelsesdato
          </ControlLabel>
          <AxoDateTime
            value={moment(approvedDate)}
            dateFormat={true}
            timeFormat={false}
            utc
            onChange={(dateTime) =>
              this.onUpdateProperty("approvedDate", dateTime.format())
            }
          />
        </FormGroup>

        <FormGroup controlId="includeAlcoholRules">
          <ControlLabel
            className="rightPadding"
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Inkluder paragraffer til alkoholbevilling
          </ControlLabel>
          <AxoCheckbox
            name="includeAlcoholRules" //Name must correspond to the name of the property
            type="checkbox"
            className="borderFormControlfocus"
            checked={includeAlcoholRules}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup controlId="text">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Fritekst til vedtægter
          </ControlLabel>
          <FormControl
            name="text" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={text}
            onChange={this.handleChange}
          />
        </FormGroup>

        <ButtonToolbar>
          <AsyncButton hideOkIcon onClick={this.onSubmit}>
            <AxoLocal
              entity="friendRequestTimelineonApproveRequest"
              defaultValue={"Godkend"}
            />
          </AsyncButton>
          <LexButton onClick={this.onCancel}>
            {" "}
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </Form>
    );
  }
}
