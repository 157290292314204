import React from "react";
import DataStore from "../services/DataAccess/DataStore";
import TimeEntriesTabView from "./TimeEntries/TimeEntriesTabView";
import { withRouter } from "react-router-dom";
import QueryString from "query-string";
import moment from "moment";

import {
  Uniqueomat,
  withRTKData,
  withRTKCaseData,
} from "../utilities/LexUtilities";

import {
  RoutingService,
  AuthorizationService,
  // TimeEntryService,
  DataActions,
  ApiService,
} from "../services/AxoServices";

class TimeEntriesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      timeEntryMap: {
        timeEntries: [],
        count: 0,
        trashed: [],
        trashedCount: 0,
      },
      contactMap: {
        contacts: [],
      },
      allCases: [],
      casesWithEntries: [],
      sharedCasesWithEntries: [],
      allClients: [],
      clientsWithEntries: [],
      clientsWithInvoices: [],
      clientsWithPDFs: [],
      clientProfiles: [],
      invoices: [],
      PDFs: [],
      documents: [],
      documentMap: {
        documents: [],
        count: 0,
      },
      sharedPDFs: [],
      userProfile: {},
      userSettings: {},
      friends: [],
      clientLabels: [],
      initializedInvoices: false,
      adminCodeValidated: false,
    };
    this.name = "TimeEntriesContainer";
  }

  componentDidMount() {
    let query = QueryString.parse(this.props.location.search);
    let adminCodeValidated = query.adminCode === "AxolexAdmin";
    if (adminCodeValidated) {
      this.setState({ adminCodeValidated: true });
    }

    DataStore.subscribe(this.name, (store) => {
      let activeCases = store.caseMap.cases;
      let casesWithEntries = activeCases.filter(
        (c) => !c.trashed && c.timeEntries.length > 0
      );
      let sharedCasesWithEntries = store.sharedCases.filter(
        (c) => c.timeEntries.length > 0
      );

      let activeInvoices = store.invoices.filter((i) => !i.trashed);
      let sortedInvoices = activeInvoices.sort((l, r) => {
        return r.number - l.number;
      });

      let activePDFs = store.documentMap.invoices;
      let sharedPDFs = store.sharedDocuments.filter(
        (d) => !d.trashed && d.invoice
      );

      let filteredLabels = store.labels.filter((l) => l.type === 3); //Clients
      let sortedLabels = filteredLabels.sort((l1, l2) =>
        l1.name.localeCompare(l2.name)
      );

      this.setState({
        userProfile: store.userProfile,
        userSettings: store.userSettings,
        friends: store.friends,
        clientProfiles: store.clientProfiles,
        timeEntryMap: store.timeEntryMap,
        allCases: activeCases,
        casesWithEntries,
        sharedCasesWithEntries,
        invoices: sortedInvoices,
        PDFs: activePDFs,
        documentMap: store.documentMap,
        sharedPDFs,
        clientLabels: sortedLabels,
        showDropdowns: store.showDropdowns,
      });
    });

    DataStore.initializeUserProfile();
    DataStore.initializeTimeEntries();
    DataStore.initializeCases();
    DataStore.initializeSharedCases();
    DataStore.fetchClientProfiles();
    DataStore.fetchAllInvoices().then(() =>
      this.setState({ initializedInvoices: true })
    );

    DataStore.initializeDocuments();
    DataStore.fetchSharedDocuments();
    DataStore.initializeLabels();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  getPDFs = () => {
    var allPDFs = this.state.PDFs.concat(this.state.sharedPDFs);
    var sortedPDFs = allPDFs.sort((l, r) => {
      return new Date(r.uploadDate) - new Date(l.uploadDate);
    });
    return sortedPDFs;
  };

  getTimeEntries = () => {
    let { timeEntries } = this.state.timeEntryMap;

    let sharedCaseEntries = this.getTimeEntriesFromSharedCases();
    let sharedClientEntries = this.getTimeEntriesFromSharedClients();
    let sharedTimeEntries = sharedCaseEntries.concat(sharedClientEntries);

    let allTimeEntries = timeEntries.concat(sharedTimeEntries);
    let uniqueTimeEntries = Uniqueomat.uniqueById(allTimeEntries);
    return uniqueTimeEntries;
  };

  getTimeEntriesFromSharedCases = () => {
    var sharedTimeEntries = this.state.sharedCasesWithEntries.reduce(
      (accumulator, caseModel) => {
        return accumulator.concat(caseModel.timeEntries);
      },
      []
    );
    return sharedTimeEntries;
  };

  getTimeEntriesFromSharedClients = () => {
    var { userProfile } = this.state;
    var sharedClients = this.state.clientsWithEntries.filter(
      (c) => c.userName !== userProfile.userName
    );
    var sharedTimeEntries = sharedClients.reduce((accumulator, client) => {
      return accumulator.concat(client.timeEntries);
    }, []);

    return sharedTimeEntries;
  };

  getCasesWithEntries = () => {
    let allCases = this.state.casesWithEntries.concat(
      this.state.sharedCasesWithEntries
    );
    let uniqueCases = Uniqueomat.uniqueById(allCases);

    return uniqueCases;
  };

  createSimpleInvoice = async ({
    series,
    title,
    subject,
    amount,
    message,
    timeEntry,
    isRegistration,
    subscriptionAction,
    subscriptionCurrentPeriodEnd,
    subscriptionInterval,
    reverseVAT,
  }) => {
    let { userProfile } = this.state;

    // var timeEntryTotal = TimeEntryService.getTotalValue(timeEntry);

    let now = moment.utc();
    let nextMonth = moment.utc().add(1, "months");
    let invoice = {
      number: series,
      invoiceType: "Faktura #", //Serie
      invoiceNo: title || "",
      currency: userProfile.currency,
      creationDate: now.format(),
      dueDate: nextMonth.format(),
      status: 0,
      outstandingAmount: amount,
      message,
      thankYou: subject,
      client: null,
      userProfile: userProfile,
      // timeEntries: [timeEntry],
      paymentRequests: [],
      isRegistration,
      subscriptionAction,
      subscriptionCurrentPeriodEnd,
      subscriptionInterval,
      reverseVAT,
    };

    let response = await ApiService.createTimeEntry(timeEntry);
    if (!response.ok) {
      return false;
    }

    let entry = await response.json();
    invoice.timeEntries = [entry];
    response = await DataActions.createInvoice(invoice);

    if (response.ok) {
      this.props.history.push(
        RoutingService.getPath(
          !entry.rate ? "Finance/Subscriptions" : "Finance/Collection"
        )
      );
    }
    return response.ok;
  };

  getActiveTab = (props) => {
    let { userProfile, userSettings } = this.state;

    let userType = AuthorizationService.getUserType(userProfile);

    let path = props.location.pathname.toLowerCase();
    if (path.includes("collection")) {
      return "Collection";
    }
    if (path.includes("create")) {
      return "Create"; //Used for simple invoices for society users
    }
    if (path.includes("subscriptions")) {
      return "Subscriptions"; //Used for simple invoices for society users
    }
    if (path.includes("addsubscription")) {
      return "AddSubscription"; //Used for simple invoices for society users
    }
    if (path.includes("timeentryform")) {
      return "TimeEntryForm";
    }
    if (path.includes("clients")) {
      return "Clients";
    }
    if (path.includes("cases")) {
      return "Cases";
    }
    if (path.includes("invoices")) {
      return "Invoices";
    }
    if (path.includes("pdfs")) {
      return "PDFs";
    }
    if (path.includes("setup")) {
      return "Setup";
    }
    if (userType === "Society") {
      if (!userSettings.paymentApiKey) {
        return "Setup";
      }
      if (userProfile.singlePaymentEnabled) {
        return "Collection";
      }
      if (userProfile.subscriptionPaymentEnabled) {
        return "Subscriptions";
      }

      return "Setup";
    }
    return "TimeEntryForm";
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(RoutingService.getPath("Finance/" + eventKey));
  };

  render() {
    var PDFs = this.getPDFs();

    let { contactMap } = this.props;
    let clients = [...contactMap.contacts];
    clients.sort((c1, c2) => {
      return (c1.lastName || "").localeCompare(c2.lastName || "");
    });
    let clientsWithEntries = clients.filter(
      (cl) => cl.timeEntries && cl.timeEntries.length > 0
    );
    let clientsWithInvoices = clients.filter(
      (cl) => cl.invoices && cl.invoices.length > 0
    );
    let clientsWithPDFs = clientsWithInvoices.filter((cl) =>
      cl.invoices.find((i) => i.hasPDFs)
    );

    let clientData = {
      allClients: clients,
      clientsWithEntries,
      clientsWithInvoices,
      clientsWithPDFs,
    };

    return (
      <TimeEntriesTabView
        activeTab={this.getActiveTab(this.props)}
        {...this.state}
        {...clientData}
        contactMap={contactMap}
        handleNavigation={this.handleNavigation}
        timeEntries={this.getTimeEntries()}
        casesWithEntries={this.getCasesWithEntries()}
        PDFs={PDFs}
        createSimpleInvoice={this.createSimpleInvoice}
      />
    );
  }
}

export default withRTKCaseData(withRTKData(withRouter(TimeEntriesContainer)));
