import React from "react";
import { debounce } from "lodash";
import { withRouter } from "react-router-dom";
// import Dropzonejs from '../../utilities/Dropzonejs'
// import InlineTinyMCEEditor from '../../utilities/Editors/InlineTinyMCEEditor'
import CreateCalendarEventModal from "../Calendar/CreateCalendarEventModal";
import InlineEdit from "../../utilities/InlineEdit";
import InlineCountrySelect from "../../utilities/CountrySelect/InlineCountrySelect";
import CaseSharing from "./CaseSharing";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import {
  ApiService,
  RoutingService,
  DataStore,
  DataActions,
  ModalService,
  UserInfoService,
  TimeEntryService,
} from "../../services/AxoServices";

import {
  // AxoFrame,
  LexButton,
  ClientSearchBox,
  AxoLocal,
  Icon,
  PrintCaseModal,
  InlineTextArea,
  InlineSelect,
  AsyncButton,
  FileViewerModal,
  FlexElement,
  UploadButton,
  SimpleTinyMCEEditor,
  MobileTinyMCE,
  getText,
  DownloadButton,
  Flexbox,
} from "../../utilities/LexUtilities";

import { SmallOrSmaller, MediumOrLarger } from "../../utilities/Responsive";

import InboxItem from "../Email/InboxItem";

import {
  Row,
  Col,
  Grid,
  Button,
  ButtonToolbar,
  FormControl,
  FormGroup,
  ControlLabel,
  // Panel,
  // PanelGroup,
  DropdownButton,
  InputGroup,
} from "react-bootstrap";

import CaseFilesView from "./CaseFilesView";
import CaseTimeEntryForm from "./CaseTimeEntryForm";

class CaseListPage extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.openCase) {
      this.state = {
        caseViewModel: { ...props.openCase },
        clientContacts: [...props.openCase.clients],
        counterPartyContacts: [...props.openCase.counterParties],
        searchText: "",
        trumbowygEnabled: false,
      };
    }
    this.state.showSharingUI = false;
    this.state.showEditHistory = false;
    this.state.selectedVersion = 0;
    this.state.versionTitle = "";
    this.state.creatingVersion = false;
    this.state.deletingVersionId = 0;
    this.state.deletingEventId = 0;
    this.state.deletingDocumentId = 0;
    this.state.deletingEntryId = 0;
    this.state.versionListOpen = false;
    this.state.showFrameCloseButton = true;
    this.state.showMissingNameWarning = false;
    this.state.caseInfoEnabled = false;
    this.state.showDocuments = false;
    this.state.creatingTimeEntry = false;

    this.isCaseLoaded = false;
    this.inProgress = true;

    this.editor = React.createRef();
    this.versionList = React.createRef();
    this.linkField = React.createRef();
    this.caseLinkField = React.createRef();
  }

  componentDidMount() {
    this.fetchCase();

    window.scrollTo(0, 0);
    this.scrollToListBottom();
  }

  fetchCase = () => {
    let { setCaseModelId } = this.props;
    let { caseViewModel } = this.state;

    setCaseModelId(caseViewModel.id);
    // ApiService.getCaseModel(this.state.caseViewModel.id)
    // .then( caseViewModel => {
    //   this.setState({ caseViewModel }, () => {
    //     this.scrollToListBottom();
    //   });
    // })
  };

  componentDidUpdate() {
    let { singleCaseModel } = this.props;
    let { caseViewModel } = this.state;

    if (
      !this.isCaseLoaded &&
      !!singleCaseModel.id &&
      singleCaseModel.id === caseViewModel.id
    ) {
      this.isCaseLoaded = true;

      this.setState({ caseViewModel: singleCaseModel }, () => {
        this.scrollToListBottom();
      });
    }
  }

  componentWillUnmount() {
    let { singleCaseModel, refetchCaseModel } = this.props;
    let { caseViewModel } = this.state;

    if (JSON.stringify(singleCaseModel) !== JSON.stringify(caseViewModel)) {
      refetchCaseModel(caseViewModel.id); //Update cache if there have been updates
    }
  }

  scrollToListBottom = () => {
    let { caseInfoEnabled, showDocuments } = this.state;

    if (caseInfoEnabled || showDocuments) {
      this.setState(
        {
          caseInfoEnabled: false,
          showDocuments: false,
        },
        () => this.doScrollToListBottom()
      );
      return;
    }

    this.doScrollToListBottom();
  };

  doScrollToListBottom = () => {
    let objDiv = this.versionList.current;
    if (!objDiv) {
      return;
    }

    objDiv.scrollTop = objDiv.scrollHeight;
  };

  onCasePropertyChange = (propertyName, input) => {
    var caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel[propertyName] = input.value;
    if (!caseViewModel.caseNumber) {
      this.setState({ showMissingNameWarning: true });
      setTimeout(() => {
        this.setState({ showMissingNameWarning: false });
      }, 5000);
      return;
    }
    this.handleViewModelChanges(caseViewModel);
  };

  handleViewModelChanges = (caseViewModel) => {
    this.setState({ caseViewModel: caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  //General case information
  onCaseDescriptionChangeMCE = (content) => {
    let { caseViewModel } = this.state;
    if(content === caseViewModel.description) {
      return;
    }

    this.handleModelChangesDebounced({
      ...this.state.caseViewModel,
      description: content,
    });
  };

  onCasePriorityChange = (input) => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.priority = parseInt(input.value.id, 10);
    this.handleInlineViewModelChanges(caseViewModel);
  };

  onCaseStatusChange = (input) => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.status = input.value.id;
    this.handleInlineViewModelChanges(caseViewModel);
  };

  handleInlineViewModelChanges = (caseViewModel) => {
    this.setState({ caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  //Client information
  onClientPropertyChange = (clientIndex, propertyName, input) => {
    let clientContacts = this.state.clientContacts.slice();
    var client = { ...clientContacts[clientIndex] };
    client[propertyName] = input.value;
    clientContacts[clientIndex] = client;
    this.handleClientModelChanges(clientContacts);
  };

  handleClientModelChanges = (clientContacts) => {
    this.setState({ clientContacts }, () => {
      this.handleInlineModelChanges();
    });
  };

  //Counterparty information
  onCounterPropertyChange = (counterPartyIndex, propertyName, input) => {
    var counterPartyContacts = this.state.counterPartyContacts.slice();
    var newParty = { ...counterPartyContacts[counterPartyIndex] }; //Copy
    newParty[propertyName] = input.value;
    counterPartyContacts[counterPartyIndex] = newParty;
    this.handleInlineCounterModelChanges(counterPartyContacts);
  };

  handleInlineCounterModelChanges = (counterPartyContacts) => {
    this.setState({ counterPartyContacts }, () => {
      this.handleInlineModelChanges();
    });
  };

  handleInlineModelChanges = () => {
    this.saveChanges(false);
  };

  handleModelChangesDebounced = debounce((caseViewModel) => {
    this.setState(
      {
        caseViewModel,
      },
      () => {
        this.saveChanges(false);
        // this.debouncedSave();
      }
    );
  }, 500);

  onFinishDraft = () => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.status = "Active";
    this.setState({ caseViewModel }, () => {
      this.saveChanges(true, (newCaseId) => {
        this.onFinishedDraft(newCaseId);
      });
    });
  };

  onFinishedDraft = (newCaseId) => {
    DataStore.fetchCases();
    if (!!this.props.onFinishDraft) {
      this.props.onFinishDraft(newCaseId);
    }
  };

  onToggleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();

    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.closed = !caseViewModel.closed;
    this.setState({ caseViewModel }, () => {
      this.saveChanges(true, this.fetchCases);
    });
  };

  onToggleDelete = () => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.trashed = !caseViewModel.trashed;
    this.setState({ caseViewModel }, () => {
      this.saveChanges(true, this.fetchCases);
    });
  };

  //Update full case map when the case changes type.
  fetchCases = () => {
    DataStore.fetchCases();
  };

  onPermanentDelete = () => {
    var viewModel = this.state.caseViewModel;
    if (viewModel.documents.length > 0) {
      ModalService.openConfirmModal(
        <AxoLocal
          entity="CaseEditInlinedeleteFiles"
          defaultValue={"Vil du også slette bilag tilknyttet til sagen?"}
        />,
        (value) => {
          if (value) {
            this.deleteCasePermanently({ id: viewModel.id, deleteFiles: true });
          } else {
            this.deleteCasePermanently({
              id: viewModel.id,
              deleteFiles: false,
            });
          }
        }
      );
    } else {
      this.deleteCasePermanently({ id: viewModel.id, deleteFiles: false });
    }
  };

  deleteCasePermanently = (options) => {
    DataActions.deleteCase(options)
      .then((response) => {
        if (response.status === 200) {
          this.props.onClose();
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="CaseEditInlinehandleServerError"
              defaultValue={"Sagen kunne ikke slettes. Prøv igen."}
            />
          );
        }
      })
      .catch(this.handleServerError);
  };

  // debouncedSave = debounce(() => {
  //   this.saveChanges(false);
  // }, 500);

  saveChanges = (closeOnSuccess, callback) => {
    this.inProgress = false;
    return this.updateCase(closeOnSuccess, callback);
  };

  updateCase = (closeOnSuccess, callback) => {
    var currentCase = { ...this.state.caseViewModel };
    currentCase.clients = this.state.clientContacts;
    currentCase.counterParties = this.state.counterPartyContacts;
    return DataActions.updateCase(currentCase)
      .then(this.handleCaseResponse.bind(this, closeOnSuccess, callback))
      .catch(this.handleServerError);
  };

  handleCaseResponse = (closeOnSuccess, callback, response) => {
    if (response.status === 200) {
      response.json().then((caseViewModel) => {
        this.setState({
          caseViewModel,
          creatingVersion: false,
        });
        if (closeOnSuccess) {
          this.props.onClose();
        }
        if (callback) {
          callback(this.state.caseViewModel.id);
        }
      });
    } else {
      this.setState({ creatingVersion: false });
      ModalService.openAlertModal(
        <AxoLocal
          entity="CaseEditInlinereason"
          defaultValue={"Sagen kunne ikke gemmes. Prøv igen."}
        />
      );
    }
  };

  handleServerError = (reason) => {
    this.inProgress = false;
    this.setState({ creatingVersion: false });
    ModalService.openAlertModal(
      <AxoLocal
        entity="CaseEditInlinereason"
        defaultValue={"Sagen kunne ikke gemmes. Prøv igen senere"}
      />
    );
  };

  onAddCounterParty = () => {
    this.setState((prevState) => ({
      counterPartyContacts: [
        ...prevState.counterPartyContacts,
        {
          name: "",
          address: "",
          eMail: "",
          phone: "",
          type: 1,
        },
      ],
    }));
  };

  onRemoveClient = (clientIndex) => {
    let clientContacts = this.state.clientContacts.slice();
    clientContacts.splice(clientIndex, 1);
    this.handleClientModelChanges(clientContacts);
  };

  onRemoveCounterParty = (counterPartyIndex) => {
    let counterPartyContacts = this.state.counterPartyContacts.slice();
    counterPartyContacts.splice(counterPartyIndex, 1);
    this.handleInlineCounterModelChanges(counterPartyContacts);
  };

  onPrint = () => {
    window.print();
  };

  getStatusSelect = () => {
    return {
      id: this.state.caseViewModel.status,
      text: this.getStatusText(this.state.caseViewModel.status),
    };
  };

  getStatusText = (status) => {
    switch (status) {
      case "Draft":
        return (
          <AxoLocal
            entity="CaseEditInlineprioritydraft"
            defaultValue={"Kladde"}
          />
        );
      case "Active":
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityActive"
            defaultValue={"Aktiv"}
          />
        );
      case "Standby":
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityStandby"
            defaultValue={"Standby"}
          />
        );
      default:
        return <span></span>;
    }
  };

  getPrioritySelect = () => {
    return {
      id: this.state.caseViewModel.priority.toString(),
      text: this.getPriorityText(this.state.caseViewModel.priority),
    };
  };

  getPriorityText = (priority) => {
    switch (priority) {
      case 0:
        return (
          <AxoLocal entity="CaseEditInlinepriorityLow" defaultValue={"Lav"} />
        );
      case 1:
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityNormal"
            defaultValue={"Normal"}
          />
        );
      case 2:
        return (
          <AxoLocal entity="CaseEditInlinepriorityHigt" defaultValue={"Høj"} />
        );
      case 3:
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityurgent"
            defaultValue={"Haster"}
          />
        );
      default:
        return <span></span>;
    }
  };

  renderAccordionHeader = (title) => {
    return (
      <div>
        <Col xs={11} style={{ padding: "0px" }}>
          <span>{title}</span>
        </Col>
        <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
          <span style={{ textAlign: "right" }}>
            <Icon glyph="icon-fontello-arrow-combo" />
          </span>
        </Col>
      </div>
    );
  };

  onCaseDateChange = (event) => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.actionDate = moment(event.target.valueAsDate).format();
    caseViewModel.reminded = false;
    this.setState({ caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  removeDeadlineFromCase = () => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.actionDate = new Date(0);
    this.setState({ caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  addDeadlineToCase = () => {
    var newDate = new Date();
    newDate.setTime(newDate.getTime() + 86400000);
    let caseViewModel = Object.assign({}, this.state.caseViewModel);

    caseViewModel.actionDate = newDate;
    caseViewModel.reminded = false;
    this.setState({ caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  renderDeadlineField = (caseModel) => {
    var deadlineMoment = moment(caseModel.actionDate);
    if (deadlineMoment.year() > 1970) {
      return (
        <div>
          <input
            style={{ borderStyle: "none" }}
            type="date"
            onChange={this.onCaseDateChange}
            value={deadlineMoment.format("YYYY-MM-DD")}
          />
          &nbsp;&nbsp;
          <Icon
            onClick={this.removeDeadlineFromCase}
            glyph="icon-fontello-scissors axored"
          />
        </div>
      );
    }
    return (
      <Icon onClick={this.addDeadlineToCase} glyph="icon-fontello-calendar-6" />
    );
  };

  handleSelectedClient = (client) => {
    var clientContacts = this.state.clientContacts.slice();
    clientContacts.push(client);
    this.setState({ clientContacts }, () => {
      this.saveChanges(false);
    });
  };

  onCopyText = () => {
    var copyText = this.linkField.current;

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Kopierede linket: " + copyText.value);
  };

  onCopyLinkText = () => {
    var copyText = this.caseLinkField.current;

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Kopierede linket: " + copyText.value);
  };

  generateCaseGuid = () => {
    this.setState(
      {
        caseViewModel: {
          ...this.state.caseViewModel,
          caseGuid: uuidv4(),
        },
      },
      () => {
        this.saveChanges(false);
      }
    );
  };

  renderCaseInformation = () => {
    if (!this.state.caseInfoEnabled) {
      return null;
    }
    var { readOnly, contactMap } = this.props;
    var caseViewModel = this.state.caseViewModel;
    if(!caseViewModel) {
      return null;
    }

    return (
      <div>
        <Grid fluid className="">
          <Row className="bgcolorone">
            <Col sm={6}>
              <ControlLabel style={{ textAlign: "left" }}>
                <Icon glyph="icon-fontello-users-3" /> &nbsp; &nbsp;
                <AxoLocal
                  entity="SidebarRightContainerfileInformation"
                  defaultValue={"Sagsinformation"}
                />
              </ControlLabel>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <span>
                  <Icon glyph="icon-fontello-barcode-1" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="ContainercaseNumber"
                    defaultValue={"Sagsnummer"}
                  />
                  :&nbsp;
                </span>
                {readOnly ? (
                  <span>{caseViewModel.caseNumber}</span>
                ) : (
                  <InlineEdit
                    value={caseViewModel.caseNumber || "---"}
                    change={this.onCasePropertyChange.bind(this, "caseNumber")}
                  />
                )}
                {this.state.showMissingNameWarning ? (
                  <div className="axored">
                    <AxoLocal
                      entity="CaseDataTableFixedcaseEnabledE"
                      defaultValue={"Sagen skal have et sagsnummer."}
                    />
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <span>
                  <Icon glyph="icon-fontello-qrcode" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseDataTableFixedcaseEnabledF"
                    defaultValue={"Internt sagsnummer:"}
                  />{" "}
                  &nbsp;{" "}
                </span>
                {readOnly ? (
                  <span>{caseViewModel.internalNumber}</span>
                ) : (
                  <InlineEdit
                    value={this.state.caseViewModel.internalNumber || "---"}
                    change={this.onCasePropertyChange.bind(
                      this,
                      "internalNumber"
                    )}
                  />
                )}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <span>
                  <Icon glyph="icon-fontello-tasks" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditInlineonCasePropertyChange"
                    defaultValue={"Retsinstans"}
                  />
                  :&nbsp;{" "}
                </span>
                {readOnly ? (
                  <span>{caseViewModel.type}</span>
                ) : (
                  <InlineEdit
                    value={this.state.caseViewModel.type || "---"}
                    change={this.onCasePropertyChange.bind(this, "type")}
                  />
                )}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <span>
                  <Icon glyph=" icon-fontello-pencil-alt-1" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseDataTableFixedsubject"
                    defaultValue={"Emneord"}
                  />
                  :&nbsp;{" "}
                </span>
                {readOnly ? (
                  <span>{caseViewModel.subject}</span>
                ) : (
                  <InlineEdit
                    value={this.state.caseViewModel.subject || "---"}
                    change={this.onCasePropertyChange.bind(this, "subject")}
                  />
                )}
              </div>

              {!!caseViewModel.caseGuid ? (
                <>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <span>
                      <Icon glyph="icon-fontello-barcode-1" />
                      &nbsp;&nbsp;&nbsp;&nbsp; Sagslink:&nbsp;
                    </span>
                    <input
                      type="text"
                      ref={this.caseLinkField}
                      readOnly
                      value={encodeURI(
                        `${window.location.protocol}//${window.location.host}/CaseLink/${caseViewModel.caseGuid}`
                      )}
                    />
                    &nbsp;
                    <LexButton onClick={this.onCopyLinkText}>
                      Kopier link
                    </LexButton>
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <span>
                      <Icon glyph=" icon-fontello-pencil-alt-1" />
                      &nbsp;&nbsp;&nbsp;&nbsp; Adgangskode:&nbsp;{" "}
                    </span>
                    {readOnly ? (
                      <span>{caseViewModel.accessCode}</span>
                    ) : (
                      <InlineEdit
                        value={this.state.caseViewModel.accessCode || "---"}
                        change={this.onCasePropertyChange.bind(
                          this,
                          "accessCode"
                        )}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div>
                  <LexButton onClick={this.generateCaseGuid}>
                    Generer sagslink
                  </LexButton>
                </div>
              )}
            </Col>
            <Col sm={6}>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <span>
                  <Icon glyph="icon-fontello-statusnet" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditInlineStatusSelect"
                    defaultValue={"Status"}
                  />
                  :&nbsp;
                </span>
                {readOnly ? (
                  <span>{this.getStatusText()}</span>
                ) : (
                  <InlineSelect
                    value={this.getStatusSelect()}
                    options={[
                      {
                        id: "Draft",
                        text: (
                          <AxoLocal
                            entity="CaseEditInlineprioritydraft"
                            defaultValue={"Kladde"}
                          />
                        ),
                      },
                      {
                        id: "Active",
                        text: (
                          <AxoLocal
                            entity="CaseEditInlinepriorityActive"
                            defaultValue={"Aktiv"}
                          />
                        ),
                      },
                      {
                        id: "Standby",
                        text: (
                          <AxoLocal
                            entity="CaseEditInlinepriorityStandby"
                            defaultValue={"Standby"}
                          />
                        ),
                      },
                    ]}
                    change={this.onCaseStatusChange}
                  />
                )}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <span>
                  <Icon glyph="icon-fontello-record-1" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditFormcasepriority"
                    defaultValue={"Prioritet"}
                  />
                  : &nbsp;
                </span>
                {readOnly ? (
                  <span>{this.getPriorityText()}</span>
                ) : (
                  <InlineSelect
                    value={this.getPrioritySelect()}
                    options={[
                      {
                        id: "0",
                        text: (
                          <AxoLocal
                            entity="CaseEditInlinepriorityLow"
                            defaultValue={"Lav"}
                          />
                        ),
                      },
                      {
                        id: "1",
                        text: (
                          <AxoLocal
                            entity="CaseEditInlinepriorityNormal"
                            defaultValue={"Normal"}
                          />
                        ),
                      },
                      {
                        id: "2",
                        text: (
                          <AxoLocal
                            entity="CaseEditInlinepriorityHigt"
                            defaultValue={"Høj"}
                          />
                        ),
                      },
                      {
                        id: "3",
                        text: (
                          <AxoLocal
                            entity="CaseEditInlinepriorityurgent"
                            defaultValue={"Haster"}
                          />
                        ),
                      },
                    ]}
                    change={this.onCasePriorityChange}
                  />
                )}
              </div>
              <div className="flexbox-standard">
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <Icon glyph="icon-fontello-calendar-8" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="EventBoxFormDeadline"
                    defaultValue={"Deadline"}
                  />
                  :&nbsp;
                </div>
                <div>
                  {readOnly ? (
                    <span>{moment(caseViewModel.actionDate).format("L")}</span>
                  ) : (
                    this.renderDeadlineField(this.state.caseViewModel)
                  )}
                </div>
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <span>
                  <Icon glyph="icon-fontello-hammer" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseDataTableFixedcaseEnabledC"
                    defaultValue={"Sagsområde:"}
                  />
                  &nbsp;{" "}
                </span>
                {readOnly ? (
                  <span>{caseViewModel.field}</span>
                ) : (
                  <InlineEdit
                    value={this.state.caseViewModel.field || "---"}
                    change={this.onCasePropertyChange.bind(this, "field")}
                  />
                )}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <span>
                  <Icon glyph="icon-fontello-target-4" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseDataTableFixedcaseEnabledD"
                    defaultValue={"Reference"}
                  />
                  : &nbsp;{" "}
                </span>
                {readOnly ? (
                  <span>{caseViewModel.reference}</span>
                ) : (
                  <InlineEdit
                    value={this.state.caseViewModel.reference || "---"}
                    change={this.onCasePropertyChange.bind(this, "reference")}
                  />
                )}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <span>
                  <Icon glyph="icon-fontello-share-1-alt" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseDataTableFixedcaseEnabledG"
                    defaultValue={"Notat"}
                  />
                  :{" "}
                </span>
                {readOnly ? (
                  <span>{caseViewModel.notes}</span>
                ) : (
                  <InlineTextArea
                    value={this.state.caseViewModel.notes || "---"}
                    change={this.onCasePropertyChange.bind(this, "notes")}
                  />
                )}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <Icon glyph=" icon-fontello-pencil-alt-1" />
                <span>&nbsp;&nbsp;&nbsp;&nbsp; Upload link:&nbsp;</span>
                <input
                  type="text"
                  ref={this.linkField}
                  readOnly
                  value={encodeURI(
                    `${window.location.protocol}//${window.location.host}/UploadDocuments/${caseViewModel.userName}?referenceText=${caseViewModel.caseNumber}`
                  )}
                />
                &nbsp;
                <LexButton onClick={this.onCopyText}>Kopier link</LexButton>
              </div>
            </Col>
          </Row>
          <div className="bgcolortwo">
            <strong>
              <span>
                <Icon glyph="icon-fontello-user-7" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="CaseEditFormclients"
                  defaultValue={"Klient"}
                />
              </span>
            </strong>
          </div>
          <div className="bgcolortwo">{this.renderClientInformation()}</div>
          {!readOnly ? (
            <div className="bgcolortwo">
              <br />
              <div>
                <span>
                  <Icon glyph="icon-fontello-user" />
                  &nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditInlinehandleSelectedClient"
                    defaultValue={"Tilføj klient til sagen"}
                  />
                </span>
              </div>
              <br />
              <div>
                <ClientSearchBox
                  clients={contactMap.contacts}
                  count={contactMap.count}
                  handleSelectedClient={this.handleSelectedClient}
                  clearOnSelect
                  disabled={false}
                />
              </div>
            </div>
          ) : null}
          <br />
        </Grid>
        <div className="bgcolorthree topPadding bottomPadding leftPadding">
          <div className="bgcolorthree">
            {this.renderCounterPartyInformation()}
          </div>
          {!readOnly ? (
            <Button className="Lex-button-2" onClick={this.onAddCounterParty}>
              <strong>
                <span>
                  <Icon glyph="icon-fontello-plus-1" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditFormoAddCounterParty"
                    defaultValue={"Tilføj modpart"}
                  />
                </span>
              </strong>
            </Button>
          ) : (
            <Button
              onClick={this.onCheckout}
              className="Lex-button-2"
              style={{ border: "1px solid #ff6600" }}
            >
              <Icon glyph="icon-fontello-download-alt" /> &nbsp;&nbsp;
              <AxoLocal
                entity="Casesharing9"
                defaultValue={"Tjek ud for redigering"}
              />
            </Button>
          )}
        </div>
      </div>
    );
  };

  getClientTitle = (index, noClients) => {
    return (
      <AxoLocal
        entity="RditInlineclientInfoHeader"
        defaultValue="Information"
      />
    );
  };

  renderClientInformation = () => {
    var readOnly = this.props.readOnly;
    var clientInfoNodes = [];
    for (var i = 0; i < this.state.clientContacts.length; i++) {
      var client = this.state.clientContacts[i];
      clientInfoNodes.push(
        <div key={i} className="bgcolortwo">
          {i > 0 ? <hr /> : null}
          <Grid fluid>
            <Row className="bgcolortwo">
              <Col sm={6}>
                <ControlLabel style={{ textAlign: "left", fontSize: "16px" }}>
                  {this.getClientTitle(i, this.state.clientContacts.length)}
                  {!readOnly ? (
                    <span>
                      &ensp;&ensp;&ensp;
                      <span
                        role="button"
                        onClick={this.onRemoveClient.bind(this, i)}
                        className="Lex-button-2"
                      >
                        <Icon
                          role="button"
                          glyph="icon-fontello-scissors axored"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <AxoLocal
                          entity="CaseEditFormRemoveClient"
                          defaultValue={"Slet klient"}
                        />
                      </span>
                    </span>
                  ) : null}
                </ControlLabel>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-user-outline" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormfirstName"
                      defaultValue={"Fornavn *"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{client.firstName}</span>
                  ) : (
                    <InlineEdit
                      value={client.firstName || "---"}
                      change={this.onClientPropertyChange.bind(
                        this,
                        i,
                        "firstName"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-user-7" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormlastName"
                      defaultValue={"Efternavn"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{client.lastName}</span>
                  ) : (
                    <InlineEdit
                      value={client.lastName || "---"}
                      change={this.onClientPropertyChange.bind(
                        this,
                        i,
                        "lastName"
                      )}
                    />
                  )}
                </div>
              </Col>
              <Col sm={6}>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-location-4" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{client.address}</span>
                  ) : (
                    <InlineEdit
                      value={client.address || "---"}
                      change={this.onClientPropertyChange.bind(
                        this,
                        i,
                        "address"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-mail-1" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormeMail"
                      defaultValue={"Email"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{client.eMail}</span>
                  ) : (
                    <InlineEdit
                      value={client.eMail || "---"}
                      change={this.onClientPropertyChange.bind(
                        this,
                        i,
                        "eMail"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-headphones-2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{client.phone}</span>
                  ) : (
                    <InlineEdit
                      value={client.phone || "---"}
                      change={this.onClientPropertyChange.bind(
                        this,
                        i,
                        "phone"
                      )}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    if (clientInfoNodes.length === 0) {
      return (
        <div className="text-left">
          <AxoLocal
            entity="CaseEditFormphoneThereisnoclientconnectedtothecase"
            defaultValue={"Der er ingen klient tilknyttet sagen."}
          />
        </div>
      );
    }
    return clientInfoNodes;
  };

  selectCounterCountry(counterPartyIndex, country) {
    var counterPartyContacts = this.state.counterPartyContacts.slice();
    var newParty = { ...counterPartyContacts[counterPartyIndex] }; //Copy
    newParty.country = country;
    counterPartyContacts[counterPartyIndex] = newParty;

    this.handleInlineCounterModelChanges(counterPartyContacts);
  }

  renderCounterPartyInformation = () => {
    var readOnly = this.props.readOnly;
    var counterPartyNodes = [];
    for (var i = 0; i < this.state.counterPartyContacts.length; i++) {
      var counterParty = this.state.counterPartyContacts[i];
      counterPartyNodes.push(
        <div key={i}>
          {i > 0 ? <hr /> : null}
          <Grid fluid>
            <Row>
              <Col sm={6}>
                <ControlLabel style={{ textAlign: "left", fontSize: "16px" }}>
                  <AxoLocal
                    entity="RditInlinecounterPartyInfoHeader"
                    defaultValue="Modparternes kontaktinformation"
                  />
                </ControlLabel>
                &nbsp;&nbsp;&nbsp;
                <span
                  role="button"
                  onClick={this.onRemoveCounterParty.bind(this, i)}
                  className="Lex-button-2"
                >
                  <Icon role="button" glyph="icon-fontello-scissors axored" />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditFormRemoveCounterParty"
                    defaultValue={"Slet modpart"}
                  />
                </span>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-user-outline" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormfirstName"
                      defaultValue={"Fornavn *"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.firstName}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.firstName || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "firstName"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-user-7" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormlastName"
                      defaultValue={"Efternavn"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.lastName}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.lastName || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "lastName"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-location-4" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.address}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.address || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "address"
                      )}
                    />
                  )}
                </div>
              </Col>
              <Col sm={6}>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-direction-1" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormpostalCode"
                      defaultValue={"Postnummer"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.postalCode}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.postalCode || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "postalCode"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-warehouse" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="CaseEditFormcity" defaultValue={"By"} />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.city}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.city || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "city"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-globe-6" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormcountry"
                      defaultValue={"Land"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.country}</span>
                  ) : (
                    <InlineCountrySelect
                      className="editable"
                      key={this.state.counterPartyContacts[i].id}
                      value={this.state.counterPartyContacts[i].country}
                      valueType="short"
                      onChange={this.selectCounterCountry.bind(this, i)}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-mail-1" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormeMail"
                      defaultValue={"Email"}
                    />
                    :&nbsp;
                  </span>
                  &nbsp;
                  {readOnly ? (
                    <span>{counterParty.eMail}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.eMail || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "eMail"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-headphones-2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />
                    :&nbsp;
                  </span>
                  &nbsp;
                  {readOnly ? (
                    <span>{counterParty.phone}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.phone || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "phone"
                      )}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    return counterPartyNodes;
  };

  onToggleCaseInfo = () => {
    this.setState((prevState) => ({
      caseInfoEnabled: !prevState.caseInfoEnabled,
      showDocuments: false,
    }));
  };

  onToggleDocuments = () => {
    this.setState((prevState) => ({
      showDocuments: !prevState.showDocuments,
      caseInfoEnabled: false,
    }));
  };

  onFileAdded = (file) => {
    DataActions.createCaseDocumentAssignment({
      caseId: this.state.caseViewModel.id,
      fileInfoModelId: file.id,
    });
  };

  isDraft = () => {
    return this.state.caseViewModel.status === "Draft";
  };

  isClosed = () => {
    return this.state.caseViewModel.closed;
  };

  isTrashed = () => {
    return this.state.caseViewModel.trashed;
  };

  onClickCreateCalendarEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.calendarEventModal.open({
      title: this.state.caseViewModel.caseNumber,
      start: moment(),
      end: moment().add(30, "m"),
      allDay: false,
      description: "",
      bookingCapacity: 1,
    });
  };

  onClickCreateTimeEntry = () => [
    this.setState({
      caseInfoEnabled: false,
      showDocuments: false,
      creatingTimeEntry: true,
    }),
  ];

  onCreateCalendarEvent = async (event) => {
    let response = await DataActions.createCalendarEvent({
      ...event,
      caseModelId: this.state.caseViewModel.id,
    });
    if (!response.ok) {
      return response;
    }
    let newEvent = await response.json();
    this.setState(
      (prevState) => ({
        caseViewModel: {
          ...prevState.caseViewModel,
          events: prevState.caseViewModel.events.concat([newEvent]),
        },
      }),
      () => this.scrollToListBottom()
    );

    return response;
  };

  onPrintCase = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.printCaseModal.open(this.state.caseViewModel, this.props.userProfile);
  };

  onShareCase = () => {
    this.setState({ showSharingUI: true });
  };

  onToggleEditHistory = () => {
    this.setState({ showEditHistory: !this.state.showEditHistory });
  };

  VersionControls = (props) => {
    var { userProfile, caseViewModel } = props;

    const onCheckout = () => {
      DataActions.checkoutCase(props.caseViewModel.id).then((response) => {
        if (response.ok) {
          var caseViewModel = Object.assign({}, this.state.caseViewModel);
          caseViewModel.editor = userProfile;
          this.setState({ caseViewModel });
          this.fetchCase();
        }
      });
    };
    const onCheckin = () => {
      this.setState({
        trumbowygEnabled: false,
      });
      DataActions.checkinCase(props.caseViewModel.id).then((response) => {
        if (response.ok) {
          var caseViewModel = Object.assign({}, this.state.caseViewModel);
          caseViewModel.editor = null;
          this.setState({ caseViewModel });
          this.fetchCase();
        }
      });
    };

    if (this.state.showSharingUI) {
      return null;
    }

    var editHistoryItems = caseViewModel.editHistoryItems;
    if (
      !caseViewModel.sharingUsers ||
      caseViewModel.sharingUsers.length === 0
    ) {
      return null;
    }
    if (caseViewModel.editor) {
      return (
        <div style={{ lineHeight: "1", fontSize: "18px", paddingTop: "10px" }}>
          <Icon glyph="icon-fontello-download-alt" /> &nbsp;&nbsp;
          <AxoLocal
            entity="Casesharing7"
            defaultValue={"Tjekket ud af"}
            style={{ borderBottom: "1px solid #ff0000" }}
          />
          : {UserInfoService.getDisplayName(caseViewModel.editor)}
          {caseViewModel.editor.id === userProfile.id ? (
            <span>
              &nbsp;&nbsp;
              <Button
                onClick={onCheckin}
                className="Lex-button-2"
                style={{ border: "1px solid #4CAF50" }}
              >
                <Icon glyph="icon-fontello-upload" /> &nbsp;&nbsp;
                <AxoLocal entity="Casesharing8" defaultValue={"Tjek ind"} />
              </Button>
            </span>
          ) : null}
        </div>
      );
    }
    return (
      <ButtonToolbar>
        <Button
          onClick={onCheckout}
          className="Lex-button-2"
          style={{ border: "1px solid #ff6600" }}
        >
          <Icon glyph="icon-fontello-download-alt" /> &nbsp;&nbsp;
          <AxoLocal
            entity="Casesharing9"
            defaultValue={"Tjek ud for redigering"}
          />
        </Button>
        {!!editHistoryItems && editHistoryItems.length > 0 ? (
          <Button onClick={props.onToggleEditHistory} className="Lex-button-2">
            {this.state.showEditHistory ? (
              <span>
                <AxoLocal
                  entity="Casesharing9A"
                  defaultValue={"Skjul tjeck ind historik"}
                />{" "}
              </span>
            ) : (
              <span>
                <Icon glyph="icon-fontello-eye-1" /> &nbsp;&nbsp;
                <AxoLocal
                  entity="Casesharing9B"
                  defaultValue={"Vis tjeck ind historik"}
                />{" "}
              </span>
            )}
          </Button>
        ) : null}
      </ButtonToolbar>
    );
  };

  SharingSection = (props) => {
    const onShareWithUsers = (sharingUsers) => {
      DataActions.shareCaseWithList(
        this.state.caseViewModel.id,
        sharingUsers.map((user) => user.id)
      ).then((response) => {
        if (response.ok) {
          var caseViewModel = Object.assign({}, this.state.caseViewModel);
          caseViewModel.sharingUsers = sharingUsers;
          this.setState({
            caseViewModel,
            showSharingUI: false,
          });
        }
      });
    };

    var { userGroup, friends, userProfile, caseViewModel } = props;

    //Only the owner can share the case
    if (caseViewModel.userName !== userProfile.userName) {
      return null;
    }
    if (!this.state.showSharingUI) {
      return (
        <div style={{ padding: "10px" }}>
          <Button className="Lex-button-2" onClick={this.onShareCase}>
            <AxoLocal entity="Casesharing5" defaultValue={"Delegering"} />
            &nbsp;&nbsp;&nbsp;
            <Icon glyph="icon-fontello-slideshare" />
          </Button>
        </div>
      );
    }
    var groupMembers = userGroup.id
      ? userGroup.members.filter((m) => m.id !== userProfile.id)
      : [];
    return (
      <div style={{ marginRight: "10px", paddingTop: "15px" }}>
        <CaseSharing
          sharingUsers={caseViewModel.sharingUsers}
          groupUsers={groupMembers}
          friends={friends}
          onShare={onShareWithUsers}
          onCancel={() => {
            this.setState({ showSharingUI: false });
          }}
        />
      </div>
    );
  };

  EditHistoryList = (props) => {
    if (!props.showEditHistory) {
      return null;
    }
    var sortedItems = props.editHistoryItems.sort((e1, e2) => {
      return new Date(e2.timeStamp) - new Date(e1.timeStamp);
    });

    var itemNodes = sortedItems.map((item) => {
      return (
        <div key={item.id} style={{ display: "inline-block" }}>
          {moment(item.timeStamp).format("LLL")}
          &nbsp;-&nbsp;
          {UserInfoService.getDisplayName(item.editor)}
        </div>
      );
    });

    var rows = [];
    for (var i = 0; i < itemNodes.length; i = i + 3) {
      var cols = [];
      cols.push(itemNodes[i]);
      cols.push(itemNodes.length > i + 1 ? itemNodes[i + 1] : null);
      cols.push(itemNodes.length > i + 2 ? itemNodes[i + 2] : null);
      rows.push(cols);
    }

    var rowNodes = rows.map((row, index) => {
      return (
        <Row key={index} className="caseSharingtimeanduserProfilelist">
          <Col md={4} className="text-center ">
            {row[0]}
          </Col>
          <Col md={4} className="text-center">
            {row[1]}
          </Col>
          <Col md={4} className="text-center">
            {row[2]}
          </Col>
        </Row>
      );
    });

    return (
      <div>
        <h4 className="text-center">
          <AxoLocal
            entity="Casesharing9C"
            defaultValue={"Tjeck ind historik"}
          />{" "}
        </h4>
        <Grid fluid>{rowNodes}</Grid>
        <div
          className="text-center"
          style={{
            paddingTop: "10px",
            marginBottom: "10px",
            borderBottom: "1px solid #4CAF50",
          }}
        >
          <LexButton
            style={{ width: "100%" }}
            onClick={props.onToggleEditHistory}
          >
            <Icon glyph="icon-fontello-up-1" />
          </LexButton>
        </div>
      </div>
    );
  };

  renderDeleteButtons = (caseViewModel, userProfile) => {
    var deleteButtons = [];

    //Shared cases can only be deleted by the user, after checking out the case
    var allowDelete =
      caseViewModel.sharingUsers.length === 0 ||
      (caseViewModel.editor &&
        caseViewModel.editor.id === userProfile.id &&
        caseViewModel.userName === userProfile.userName);
    if (!allowDelete) {
      return null;
    }

    if (this.isTrashed()) {
      deleteButtons.push(
        <AxoLocal
          componentClass={Button}
          className="Lex-button-2"
          onClick={this.onPermanentDelete}
          key="permanent"
          componentAttribute="title"
          entity="CaseEditInlineClearcasepermanently"
        >
          <Icon glyph="icon-fontello-trash-1" />
        </AxoLocal>
      );
      deleteButtons.push(
        <AxoLocal
          componentClass={Button}
          className="Lex-button-2"
          onClick={this.onToggleDelete}
          key="restore"
          componentAttribute="title"
          entity="CaseEditFormToggleClose"
        >
          <Icon glyph="icon-fontello-ccw-2" />
        </AxoLocal>
      );
    } else if (this.isDraft()) {
      deleteButtons.push(
        <AxoLocal
          componentClass={Button}
          className="Lex-button-2"
          onClick={this.onToggleDelete}
          key="discard"
          componentAttribute="title"
          entity="CaseEditFormBoxingdraft"
        >
          <Icon glyph="icon-fontello-trash-1" />
        </AxoLocal>
      );
    } else {
      deleteButtons.push(
        <AxoLocal
          componentClass={Button}
          className="Lex-button-2"
          onClick={this.onToggleDelete}
          key="discard"
          componentAttribute="title"
          entity="CaseEditFormremoveCase"
        >
          <Icon glyph="icon-fontello-trash-1" />
        </AxoLocal>
      );
    }

    return deleteButtons;
  };

  onDeleteCaseVersion = (versionId, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ deletingVersionId: versionId });
  };

  onConfirmDeleteVersion = (versionId, event) => {
    event.preventDefault();
    event.stopPropagation();
    DataActions.deleteCaseVersion(versionId).then((response) => {
      if (response.ok) {
        var caseViewModel = Object.assign({}, this.state.caseViewModel);
        caseViewModel.caseVersions = caseViewModel.caseVersions.filter(
          (v) => v.id !== versionId
        );
        this.setState({ caseViewModel });
      }
    });
  };

  updateCaseVersion = async (version) => {
    return DataActions.updateCaseVersion(version).then((response) => {
      if (!response.ok) {
        return false;
      }

      let { caseViewModel } = this.state;

      this.setState({
        caseViewModel: {
          ...caseViewModel,
          caseVersions: caseViewModel.caseVersions.map((v) => {
            if (v.id !== version.id) {
              return v;
            }
            return version;
          }),
        },
      });
      return true;
    });
  };

  onCancelDeleteVersion = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ deletingVersionId: 0 });
  };

  onRenderFrame = (height) => {
    if (height > 800) {
      this.setState({ showFrameCloseButton: true });
    }
  };

  onCloseFrame = () => {
    this.setState({
      selectedVersion: 0,
      showFrameCloseButton: false,
    });
  };

  onDeleteEvent = (eventId) => {
    this.setState({ deletingEventId: eventId });
  };

  onConfirmDeleteEvent = async (eventId) => {
    let response = await DataActions.deleteCalendarEvent(eventId);
    if (!response.ok) {
      return false;
    }
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        events: prevState.caseViewModel.events.filter((e) => e.id !== eventId),
      },
    }));
  };

  onCancelDeleteEvent = () => {
    this.setState({ deletingEventId: 0 });
  };

  onDeleteDocument = (id) => {
    this.setState({ deletingDocumentId: id });
  };

  onConfirmDeleteDocument = async (doc) => {
    let response = await DataActions.updateDocument({
      ...doc,
      trashed: true,
    });
    if (!response.ok) {
      return false;
    }
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        documents: prevState.caseViewModel.documents.filter(
          (e) => e.id !== doc.id
        ),
      },
    }));

    return true;
  };

  onCancelDeleteDocument = () => {
    this.setState({ deletingDocumentId: 0 });
  };

  onSetDocumentSharingStatus = async (doc, value) => {
    let newDoc = {
      ...doc,
      sharedWithClient: value,
    };

    let response = await DataActions.updateDocument(newDoc);
    if (!response.ok) {
      return false;
    }
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        documents: prevState.caseViewModel.documents.map((d) =>
          d.id !== doc.id ? d : newDoc
        ),
      },
    }));
  };

  onDeleteEntry = (id) => {
    this.setState({ deletingEntryId: id });
  };

  onConfirmDeleteEntry = async (id) => {
    let response = await DataActions.deleteTimeEntry(id);
    if (!response.ok) {
      return false;
    }
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        timeEntries: prevState.caseViewModel.timeEntries.filter(
          (e) => e.id !== id
        ),
      },
    }));

    return true;
  };

  onCancelDeleteEntry = () => {
    this.setState({ deletingEntryId: 0 });
  };

  onCreateTimeEntry = async (entry) => {
    let response = await DataActions.createTimeEntry({
      ...entry,
      workDate: entry.workDate || moment.utc().format(),
      caseModelId: this.state.caseViewModel.id,
    });

    if (!response.ok) {
      return false;
    }

    let createdEntry = await response.json();

    this.setState(
      (prevState) => ({
        caseViewModel: {
          ...prevState.caseViewModel,
          timeEntries: prevState.caseViewModel.timeEntries.concat([
            createdEntry,
          ]),
        },
        creatingTimeEntry: false,
      }),
      () => this.scrollToListBottom()
    );

    return true;
  };

  Version = React.memo(
    ({ version, selectedVersion, deletingVersionId, date }) => {
      var { userProfile, readOnly } = this.props;

      let { caseViewModel } = this.state;

      let className =
        "flexbox-standard CaseVersionListItem leftPadding rightPadding";
      let active = version.id === selectedVersion;
      if (active) {
        className += " active";
      }
      return (
        <div key={version.id}>
          <div className="text-center">{moment(date).format("LLL")}</div>
          <div
            onClick={this.onSelectVersion.bind(this, version.id)}
            className={className}
          >
            <FlexElement>
              <div>{UserInfoService.getDisplayName(version.userProfile)}</div>
              <div>
                <strong>{!!version.title && <>{version.title}</>}</strong>
              </div>
            </FlexElement>
            {!readOnly && (
              <FlexElement flexGrow={1} className="text-right">
                {deletingVersionId === version.id ? (
                  <span>
                    <LexButton
                      onClick={this.onConfirmDeleteVersion.bind(
                        this,
                        version.id
                      )}
                    >
                      <AxoLocal
                        entity="CaseCaseVersionreadOnly1"
                        defaultValue={"Bekræft sletning"}
                      />
                    </LexButton>
                    &nbsp;
                    <LexButton onClick={this.onCancelDeleteVersion}>
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                    &nbsp;
                  </span>
                ) : (
                  <>
                    {caseViewModel.userName === userProfile.userName ||
                    version.userProfile.userName === userProfile.userName ? (
                      <>
                        <Icon
                          className="leftPadding"
                          role="button"
                          glyph="icon-fontello-trash-1"
                          onClick={this.onDeleteCaseVersion.bind(
                            this,
                            version.id
                          )}
                        />
                        {(caseViewModel.sharedWithClient ||
                          !!caseViewModel.caseGuid) && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <AsyncButton
                              onClick={() =>
                                this.updateCaseVersion({
                                  ...version,
                                  sharedWithClient: !version.sharedWithClient,
                                })
                              }
                              hideOkIcon
                            >
                              {!version.sharedWithClient ? (
                                <>
                                  {" "}
                                  <Icon glyph="icon-fontello-eye-6" />
                                  <AxoLocal
                                    entity="InvoiceSendToClient"
                                    defaultValue={"Send til klient"}
                                  />
                                </>
                              ) : (
                                <>
                                  <Icon glyph="icon-fontello-eye-off-1" />
                                  <AxoLocal
                                    entity="axoEntityidcode57"
                                    defaultValue={"Fjern fra klient"}
                                  />{" "}
                                </>
                              )}
                            </AsyncButton>
                          </>
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </FlexElement>
            )}
          </div>
          {!!version.description &&
            !this.htmlStringIsBlank(version.description) && (
              <div>
                <div
                  className="leftPadding"
                  dangerouslySetInnerHTML={{ __html: version.description }}
                />
                {/* <AxoFrame
              bottomPadding={25}
              content={version.description}
              onRender={this.onRenderFrame}
            /> */}
              </div>
            )}
        </div>
      );
    }
  );

  onRemoveMail = async (message, event) => {
    let { updateMessageFromHeader } = this.props;

    event.preventDefault();
    event.stopPropagation();

    try {
      await updateMessageFromHeader({
        ...message,
        caseModel: null,
        caseModelId: null,
      });
    } catch {
      return false;
    }

    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        messages: prevState.caseViewModel.messages.filter(
          (m) => m.id !== message.id
        ),
      },
    }));

    return true;
  };

  onDeleteMail = async (message, event) => {
    let { updateMessageFromHeader } = this.props;

    event.preventDefault();
    event.stopPropagation();

    try {
      updateMessageFromHeader({
        ...message,
        trashed: true,
      });
    } catch {
      return false;
    }

    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        messages: prevState.caseViewModel.messages.filter(
          (m) => m.id !== message.id
        ),
      },
    }));

    return true;
  };

  Message = React.memo(({ message }) => {
    var { userProfile } = this.props;

    let { caseViewModel } = this.state;

    return (
      <div key={message.id}>
        <InboxItem
          key={message.id}
          message={message}
          userProfile={userProfile}
          icon={
            <span>
              &nbsp;&nbsp;&nbsp; <Icon glyph="icon-fontello-mail-1" />
            </span>
          }
          src="/imgs/app/avatars/avatar111-17.png"
          imgClass=""
          labelValue={<AxoLocal entity="axoidcode151" defaultValue={"Emne:"} />}
          labelClass="axoblue"
          shown={"caseModel-" + caseViewModel.id}
          date={message.date}
          // selected={isSelected}
          // onSelect={this.onMailSelected}
          onRemove={this.onRemoveMail.bind(this, message)}
          onDelete={this.onDeleteMail.bind(this, message)}
        />
      </div>
    );
  });

  CaseVersionList = (props) => {
    var {
      caseModel,
      userProfile,
      selectedVersion,
      deletingVersionId,
      deletingEventId,
      deletingDocumentId,
      deletingEntryId,
      formattedSearchText,
      // showFrameCloseButton,
      readOnly,
    } = props;

    if (!caseModel.caseVersions) {
      return null;
    }

    const Event = React.memo(({ event, date }) => {
      let className =
        "flexbox-justified CaseVersionListItem leftPadding rightPadding";
      className += " Caselisevent";

      return (
        <div>
          <div className="text-center">{moment(date).format("LLL")}</div>
          <div key={"event-" + event.id} className={className}>
            <FlexElement>
              {moment(event.start).format("LLL")}
              {!!event.title && <>&nbsp;{event.title}</>}
              {!!event.description && <>&nbsp;{event.description}</>}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Icon glyph="icon-fontello-clock-4" />
            </FlexElement>
            {!readOnly ? (
              <FlexElement flexGrow={1} className="text-right">
                {deletingEventId === event.id ? (
                  <span>
                    <AsyncButton
                      onClick={this.onConfirmDeleteEvent.bind(this, event.id)}
                    >
                      <AxoLocal
                        entity="CaseCaseVersionreadOnly1"
                        defaultValue={"Bekræft sletning"}
                      />
                    </AsyncButton>
                    &nbsp;
                    <LexButton onClick={this.onCancelDeleteEvent}>
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                    &nbsp;
                  </span>
                ) : (
                  <>
                    {caseModel.userName === userProfile.userName ||
                    event.userName === userProfile.userName ? (
                      <div onClick={this.onDeleteEvent.bind(this, event.id)}>
                        <Icon role="button" glyph="icon-fontello-trash-1" />
                      </div>
                    ) : null}
                  </>
                )}
              </FlexElement>
            ) : null}
          </div>
        </div>
      );
    });

    const Document = React.memo(({ doc, index, date }) => {
      let { caseViewModel } = this.state;

      let className =
        "flexbox-standard CaseVersionListItem leftPadding rightPadding";
      className += " Caselistdoc";
      return (
        <div>
          <div>
            <div className="text-center">{moment(date).format("LLL")}</div>
          </div>
          <div key={"document-" + doc.id} className={className}>
            <FlexElement
              role="button"
              onClick={() =>
                this.fileModal.open(caseViewModel.documents, {
                  messageId: null,
                  fileIndex: index,
                })
              }
            >
              <div>
                {!!doc.fileName && (
                  <>
                    &nbsp;{doc.fileName}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <Icon glyph="icon-fontello-attach-outline" />
                  </>
                )}
              </div>
            </FlexElement>
            {!readOnly ? (
              <FlexElement flexGrow={1} className="text-right">
                {deletingDocumentId === doc.id ? (
                  <span>
                    <AsyncButton
                      onClick={this.onConfirmDeleteDocument.bind(this, doc)}
                    >
                      <AxoLocal
                        entity="CaseCaseVersionreadOnly1"
                        defaultValue={"Bekræft sletning"}
                      />
                    </AsyncButton>
                    &nbsp;
                    <LexButton onClick={this.onCancelDeleteDocument}>
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                    &nbsp;
                  </span>
                ) : (
                  <>
                    {caseModel.userName === userProfile.userName ||
                    doc.userName === userProfile.userName ? (
                      <>
                        <Icon
                          role="button"
                          glyph="icon-fontello-scissors"
                          title={getText(
                            "CaseEditRemovedocumentfromcase",
                            "Fjern dokument fra sag"
                          )}
                          onClick={this.removeDocument.bind(this, doc.id)}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Icon
                          role="button"
                          glyph="icon-fontello-trash-1"
                          title={getText("axoidcode261", "Slet dokument")}
                          onClick={this.onDeleteDocument.bind(this, doc.id)}
                        />
                        {(caseViewModel.sharedWithClient ||
                          !!caseViewModel.caseGuid) && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <AsyncButton
                              onClick={() =>
                                this.onSetDocumentSharingStatus(
                                  doc,
                                  !doc.sharedWithClient
                                )
                              }
                              hideOkIcon
                            >
                              {!doc.sharedWithClient ? (
                                <>
                                  {" "}
                                  <Icon glyph="icon-fontello-eye-6" />{" "}
                                  <AxoLocal
                                    entity="InvoiceSendToClient"
                                    defaultValue={"Send til klient"}
                                  />
                                </>
                              ) : (
                                <>
                                  <Icon glyph="icon-fontello-eye-off-1" />
                                  <AxoLocal
                                    entity="axoEntityidcode57"
                                    defaultValue={"Fjern fra klient"}
                                  />
                                </>
                              )}
                            </AsyncButton>
                          </>
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </FlexElement>
            ) : null}
          </div>
        </div>
      );
    });

    const TimeEntry = React.memo(({ timeEntry, date }) => {
      let className =
        "flexbox-justified CaseVersionListItem leftPadding rightPadding";
      className += " CaseliseventtimeSpent";

      return (
        <div>
          <div className="text-center">{moment(date).format("LLL")}</div>
          <div key={"timeEntry-" + timeEntry.id} className={className}>
            <FlexElement>
              <div>
                &nbsp;
                <AxoLocal
                  key="timeSpent"
                  entity="CaseDataTableFixedfileTime"
                  defaultValue={"Tidsforbrug"}
                />
                : &nbsp;{TimeEntryService.getTimeString(timeEntry.units)}
              </div>
              <div>
                {!!timeEntry.description && (
                  <>
                    <strong>
                      <AxoLocal
                        entity="axoEntityidcode58"
                        defaultValue={"Arbejde:"}
                      />
                    </strong>{" "}
                    {timeEntry.description}
                  </>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Icon glyph="icon-fontello-desktop-2" />
              </div>
            </FlexElement>
            {!readOnly ? (
              <FlexElement flexGrow={1} className="text-right">
                {deletingEntryId === timeEntry.id ? (
                  <span>
                    <AsyncButton
                      onClick={this.onConfirmDeleteEntry.bind(
                        this,
                        timeEntry.id
                      )}
                    >
                      <AxoLocal
                        entity="CaseCaseVersionreadOnly1"
                        defaultValue={"Bekræft sletning"}
                      />
                    </AsyncButton>
                    &nbsp;
                    <LexButton onClick={this.onCancelDeleteEntry}>
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                    &nbsp;
                  </span>
                ) : (
                  <>
                    {caseModel.userName === userProfile.userName ||
                    timeEntry.userName === userProfile.userName ? (
                      <div
                        onClick={this.onDeleteEntry.bind(this, timeEntry.id)}
                      >
                        <Icon role="button" glyph="icon-fontello-trash-1" />
                      </div>
                    ) : null}
                  </>
                )}
              </FlexElement>
            ) : null}
          </div>
        </div>
      );
    });

    let Version = this.Version;
    let items = caseModel.caseVersions
      .filter(
        (v) => this.containsTextLowerCase(formattedSearchText, v.description)
      )
      .map((v) => ({
        date: v.date,
        renderItem: (date) => (
          <Version
            key={v.id}
            version={v}
            selectedVersion={selectedVersion}
            deletingVersionId={deletingVersionId}
            date={date}
          />
        ),
      }));

    items = items.concat(
      caseModel.events
        .filter(
          (e) => this.containsTextLowerCase(formattedSearchText, e.title)
        )
        .map((e) => ({
          date: moment.utc(e.creationDate).isAfter(moment.utc(0))
            ? e.creationDate
            : e.start,
          renderItem: (date) => (
            <Event key={"event-" + e.id} event={e} date={date} />
          ),
        }))
    );
    items = items.concat(
      caseModel.documents
        .map((d, index) => ({
          fileName: d.fileName,
          date: moment.utc(d.assignmentDate).isAfter(moment.utc(0))
            ? d.assignmentDate
            : d.uploadDate,
          renderItem: (date) => (
            <Document
              key={"document-" + d.id}
              doc={d}
              index={index}
              date={date}
            />
          ),
        }))
        .filter(
          (d) => this.containsTextLowerCase(formattedSearchText, d.fileName)
        ) //Filter after map to preserve index
    );
    items = items.concat(
      caseModel.timeEntries
        .filter(
          (t) => this.containsTextLowerCase(formattedSearchText, t.description)
        )
        .map((t) => ({
          date: t.workDate,
          renderItem: (date) => (
            <TimeEntry key={"timeEntry-" + t.id} timeEntry={t} date={date} />
          ),
        }))
    );

    let Message = this.Message;
    items = items.concat(
      caseModel.messages
        .filter(
          (m) => this.containsTextLowerCase(formattedSearchText, m.subject)
        )
        .map((m) => ({
          date: m.date,
          renderItem: () => (
            <Message key={"message-" + m.id} message={m} />
          ),
        }))
    );
    items = items.sort(
      (l, r) => moment(l.date).valueOf() - moment(r.date).valueOf()
    );

    let nodes = items.map((item) => {
      if (!!item.renderItem) {
        return item.renderItem(item.date);
      }
      return (
        <div>
          <AxoLocal
            entity="axoEntityidcode207"
            defaultValue={" Element could not be recognized"}
          />
        </div>
      );
    });

    return (
      <div>
        <div
          ref={this.versionList}
          className="CaseVersionList bgcolorfour"
          style={{ maxHeight: "600px", overflowY: "scroll" }}
        >
          {nodes}
        </div>
      </div>
    );
  };

  containsTextLowerCase = (searchText, targetText) => {
    if(!searchText) {
      return true;
    }
    if(!targetText) {
      return false;
    }

    return targetText.toLowerCase().includes(searchText);

  }

  onSelectVersion = (versionId) => {
    var { selectedVersion } = this.state;
    if (selectedVersion === versionId) {
      this.setState({ selectedVersion: 0 });
    } else {
      this.setState({ selectedVersion: versionId });
    }
  };

  onVersionTitleChange = (event) => {
    this.setState({ versionTitle: event.target.value });
  };

  onCreateCaseVersion = () => {
    this.setState(
      {
        creatingVersion: true,
        trumbowygEnabled: false,
      },
      () => {
        this.doCreateCaseVersion();
      }
    );
  };

  doCreateCaseVersion = async () => {
    var { caseViewModel, versionTitle } = this.state;

    var caseVersion = {
      title: versionTitle,
      description: caseViewModel.description,
      caseModelId: caseViewModel.id,
    };
    let response = await ApiService.createCaseVersion(caseVersion);
    if (!response.ok) {
      return false;
    }

    let newVersion = await response.json();
    let caseModel = {
      ...caseViewModel,
      description: "<html></html>",
      caseVersions: caseViewModel.caseVersions.concat([newVersion]),
    };

    this.setState(
      {
        versionTitle: "",
        caseViewModel: caseModel,
        trumbowygEnabled: false,
      },
      () => {
        this.saveChanges(false);
        this.scrollToListBottom();
      }
    );

    this.editor.current.setContent("");
  };

  VersionCreateButton = (props) => {
    var { creatingVersion, hasNonEmptyDescription, onCreateCaseVersion } =
      props;

    return (
      <Button
        disabled={creatingVersion || !hasNonEmptyDescription}
        onClick={onCreateCaseVersion}
        className="Lex-button-2"
      >
        <Icon glyph="icon-fontello-floppy-1" /> &nbsp;&nbsp;
        <AxoLocal entity="CalendarEventBoxFormonSave" defaultValue={"Gem"} />
        {/* <AxoLocal entity='CaseCaseVersion1' defaultValue={'Gem denne sagsfremstilling'}/> */}
        {creatingVersion ? (
          <span>
            &nbsp;
            <img
              alt=""
              src="/imgs/app/loading.gif"
              className="img-circle"
              width="20"
              height="20"
            />
          </span>
        ) : null}
      </Button>
    );
  };

  onCheckout = () => {
    DataActions.checkoutCase(this.state.caseViewModel.id);
  };

  onGeneratedPdf = (newPdf) => {
    DataStore.setActiveDocumentId(newPdf.id);
    this.props.history.push(RoutingService.getPath("Documents"));
  };

  hasNonEmptyDescription = () => {
    let { caseViewModel, versionTitle } = this.state;

    var description = caseViewModel.description;
    if (!versionTitle || this.htmlStringIsBlank(description)) {
      return false;
    }
    return true;
  };

  htmlStringIsBlank = (html) => {
    if (!html) {
      return true;
    }

    let trimmedHtml = html.replace(/\s/g, "");
    return (
      trimmedHtml.indexOf("</body>") === -1 ||
      trimmedHtml.indexOf("</body>") - trimmedHtml.indexOf("<body>") < 7
    );
  };

  noOp = () => {};

  getTotalTimeSpent = () => {
    let timeEntriesWithTime = this.state.caseViewModel.timeEntries.filter(
      (t) => t.unitType === "Hours"
    );
    return timeEntriesWithTime.reduce((acc, timeEntry) => {
      return acc + timeEntry.units;
    }, 0);
  };

  CloseButton = (props) => {
    let { readOnly } = this.props;

    if (this.isDraft()) {
      return null;
    }
    if (this.isClosed()) {
      return (
        <LexButton
          className="axolextopmenuButton"
          disabled={readOnly}
          onClick={this.onToggleClose}
          style={{ height: "45px" }}
        >
          <Icon glyph=" icon-fontello-lock-2-open" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="CaseEditFormToggleClose"
            defaultValue={"Gendan sag"}
          />
        </LexButton>
      );
    }
    return (
      <LexButton
        className="axolextopmenuButton"
        disabled={readOnly}
        onClick={this.onToggleClose}
        style={{ height: "45px" }}
      >
        <AxoLocal entity="CaseEditFormClose" defaultValue={"Afslut sag"} />
        &nbsp;&nbsp;
        <Icon glyph="icon-fontello-lock-2" />
      </LexButton>
    );
  };

  CaseDropDown = (props) => {
    let { readOnly } = this.props;

    let { caseViewModel } = this.state;

    // let {
    //   caseInfoEnabled
    // } = this.state;

    let totalTime = this.getTotalTimeSpent();

    let CloseButton = this.CloseButton;
    return (
      <div className="buttonDropdown" style={{ paddingRight: "150px" }}>
        <DropdownButton
          id="menuDropdown"
          noCaret
          // open={openDropdown}
          // onToggle={onToggleDropdown}
          title={
            <span style={{ fontSize: "25px" }}>
              <Icon
                style={{ fontSize: "30px" }}
                className="navminimenu"
                glyph="icon-fontello-dot-3"
              />
            </span>
          }
        >
          <div style={{ overflowY: "scroll", height: "350px" }}>
            <div className="bottomPadding">
              <LexButton
                className="axolextopmenuButton"
                onClick={this.onToggleCaseInfo}
                style={{ height: "45px" }}
              >
                <Icon glyph="icon-fontello-users-3" /> &nbsp; &nbsp;
                <AxoLocal
                  entity="SidebarRightContainerfileInformation"
                  defaultValue={"Sagsinformation"}
                />
              </LexButton>
            </div>
            <div className="bottomPadding">
              <LexButton
                className="axolextopmenuButton"
                onClick={this.onToggleDocuments}
                style={{ height: "45px" }}
              >
                {/* Skift ikon */}
                <Icon glyph="icon-fontello-upload-4" />
                &nbsp;
                <AxoLocal
                  entity="documentMenuNav"
                  defaultValue={"Dokumenter"}
                />
              </LexButton>
            </div>
            <div className="bottomPadding">
              <UploadButton
                className="axolextopmenuButton"
                disabled={readOnly}
                onSelected={this.onUploadCaseFiles}
                allowMultiple
                style={{ height: "45px" }}
              >
                <Icon glyph="icon-fontello-upload-4" />
                &nbsp;
                <AxoLocal
                  entity="DocumentTabViewUpload"
                  defaultValue={"Upload dokumenter"}
                />
              </UploadButton>
            </div>
            <div className="bottomPadding">
              <DownloadButton
                className="axolextopmenuButton"
                title="Download alle bilag som Zip fil"
                href={ApiService.getCaseDocumentsDownloadPath(caseViewModel.id)}
                disabled={readOnly || caseViewModel.documents.length < 1}
                style={{ height: "45px" }}
              >
                <Icon glyph="icon-fontello-download" />
                &nbsp; Zip
              </DownloadButton>
            </div>
            <div className="bottomPadding">
              <LexButton
                className="axolextopmenuButton"
                disabled={readOnly}
                onClick={this.onClickCreateCalendarEvent}
                style={{ height: "45px" }}
              >
                <Icon glyph="icon-fontello-clock-4" /> &nbsp;
                <AxoLocal
                  entity="CaseEditInlineCreateCalendarEvent"
                  defaultValue={"Opret påmindelse"}
                />
              </LexButton>
            </div>
            <div className="bottomPadding">
              <LexButton
                className="axolextopmenuButton"
                disabled={readOnly}
                onClick={this.onClickCreateTimeEntry}
                style={{ height: "45px" }}
              >
                <Icon glyph="icon-fontello-desktop-2" />
                &nbsp;
                <AxoLocal
                  entity="axoidcode259"
                  defaultValue={"Registrer tidsforbrug"}
                />
                {!!totalTime && (
                  <>({TimeEntryService.getTimeString(totalTime)})</>
                )}
              </LexButton>
            </div>
            {this.isDraft() && !readOnly && (
              <div className="bottomPadding">
                <LexButton
                  className="axolextopmenuButton"
                  onClick={this.onFinishDraft}
                  style={{ height: "45px" }}
                >
                  <AxoLocal
                    entity="CaseEditFormonSaveClicked"
                    defaultValue={"Opret sag"}
                  />
                  &nbsp;&nbsp; <Icon glyph="icon-fontello-plus-squared-alt" />
                </LexButton>
              </div>
            )}
            <div className="bottomPadding">
              <CloseButton />
            </div>
          </div>
        </DropdownButton>
      </div>
    );
  };

  onUploadCaseFiles = async (files) => {
    let response = await DataActions.uploadDocuments(files, {
      caseModelId: this.state.caseViewModel.id,
    });
    if (!response.ok) {
      return false;
    }

    let addedFiles = await response.json();
    if (!addedFiles || addedFiles.length === 0) {
      return;
    }

    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        documents: prevState.caseViewModel.documents.concat(
          addedFiles.map((f) => ({ ...f, assignmentDate: moment.utc() }))
        ),
      },
    }));

    this.scrollToListBottom();
    return response.ok;
  };

  removeDocument = async (documentId) => {
    let response = await DataActions.deleteCaseDocumentAssignment(
      this.state.caseViewModel.id,
      documentId
    );
    if (!response.ok) {
      return false;
    }
    this.onRemoveFileFromCase(documentId);
    return true;
  };

  onRemoveFileFromCase = (documentId) => {
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        documents: prevState.caseViewModel.documents.filter(
          (d) => d.id !== documentId
        ),
      },
    }));
  };

  onEditFile = (newFile) => {
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        documents: prevState.caseViewModel.documents.map((d) =>
          d.id === newFile.id ? newFile : d
        ),
      },
    }));
  };

  render() {
    let {
      userGroup,
      friends,
      userProfile,
      readOnly,
      contactMap,
      userSettings,
    } = this.props;

    let {
      clientContacts,
      caseViewModel,
      creatingVersion,
      trumbowygEnabled,
      attemptingEdit,
      caseInfoEnabled,
      showDocuments,
      creatingTimeEntry,
      searchText,
    } = this.state;

    if(!caseViewModel) {
      return null;
    }

    let formattedSearchText = searchText.toLowerCase();

    //Load editor when full case with description has been fetched
    let tinyMCE = caseViewModel.description !== undefined && (
      <>
        <MediumOrLarger>
          <SimpleTinyMCEEditor
            ref={this.editor}
            text={caseViewModel.description}
            locale={userSettings.locale}
            onTextChange={this.onCaseDescriptionChangeMCE}
            height={250}
          />
        </MediumOrLarger>
        <SmallOrSmaller>
          <MobileTinyMCE
            ref={this.editor}
            text={caseViewModel.description}
            locale={userSettings.locale}
            onTextChange={this.onCaseDescriptionChangeMCE}
            height={250}
          />
        </SmallOrSmaller>
      </>
    );

    var hasNonEmptyDescription = this.hasNonEmptyDescription();

    let totalTime = this.getTotalTimeSpent();

    let clientUsers = clientContacts.filter((c) => !!c.clientUserName);
    let clientLabel =
      clientUsers.length > 1 ? (
        <>
          <AxoLocal
            entity="TimeEntriesSidebarContainerClients"
            defaultValue={"klienter"}
          />
        </>
      ) : (
        <>
          <AxoLocal entity="CaseDataTableFixedClient" defaultValue={"Klient"} />
        </>
      );

    let SharingSection = this.SharingSection;
    let VersionControls = this.VersionControls;
    let CaseDropDown = this.CaseDropDown;
    let EditHistoryList = this.EditHistoryList;
    let CaseVersionList = this.CaseVersionList;
    let VersionCreateButton = this.VersionCreateButton;

    return (
      <div style={{ maxWidth: "1280px" }}>
        <Grid fluid>
          <Row className="hidden-print">
            <Col lg={7} className="nopaddingRight">
              <div className="flexbox-responsive">
                <SharingSection
                  userGroup={userGroup}
                  friends={friends}
                  userProfile={userProfile}
                  caseViewModel={caseViewModel}
                />
                <VersionControls
                  userGroup={userGroup}
                  userProfile={userProfile}
                  caseViewModel={caseViewModel}
                  onToggleEditHistory={this.onToggleEditHistory}
                />
              </div>
              <Flexbox>
                <FlexElement>
                  <div className="largeText bottomPadding rightPadding">
                    {caseViewModel.caseNumber}
                    {clientContacts.length > 0 && (
                      <>
                        &nbsp;- {clientContacts[0].firstName}{" "}
                        {clientContacts[0].lastName}
                        {clientUsers.length > 0 && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <LexButton
                              onClick={() =>
                                this.handleViewModelChanges({
                                  ...caseViewModel,
                                  sharedWithClient:
                                    !caseViewModel.sharedWithClient,
                                })
                              }
                            >
                              {!caseViewModel.sharedWithClient ? (
                                <>
                                  <AxoLocal
                                    key="send"
                                    entity="DocumentGridViewsendto"
                                    defaultValue={"Send til"}
                                  />{" "}
                                  {clientLabel}
                                </>
                              ) : (
                                <>
                                  <AxoLocal
                                    key="remove"
                                    entity="axoEntityidcode59"
                                    defaultValue={"Fjern fra"}
                                  />{" "}
                                  {clientLabel}
                                </>
                              )}
                            </LexButton>
                          </>
                        )}
                      </>
                    )}
                    {(!!caseInfoEnabled || showDocuments) && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <Icon
                          glyph="icon-fontello-cancel-circled2"
                          className="axored largeText"
                          role="button"
                          onClick={() =>
                            this.setState({
                              caseInfoEnabled: false,
                              showDocuments: false,
                            })
                          }
                        />
                      </>
                    )}
                  </div>
                </FlexElement>
                <FlexElement>
                  <div style={{ minWidth: "200px", maxWidth: "600px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        {/* Søg...  */}
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={searchText}
                          onChange={(e) =>
                            this.setState({ searchText: e.target.value })
                          }
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </FlexElement>
              </Flexbox>

              {caseViewModel.owner && (
                <h4>
                  &nbsp;&nbsp;(
                  <AxoLocal
                    entity="CaseEditInlinecaseowner"
                    defaultValue={"Ejer"}
                  />{" "}
                  : {UserInfoService.getDisplayName(caseViewModel.owner)})
                </h4>
              )}
            </Col>
            <Col
              lg={5}
              style={{ paddingTop: "10px" }}
              className="text-right nopadding"
            >
              <CaseDropDown />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <EditHistoryList
                showEditHistory={this.state.showEditHistory}
                editHistoryItems={caseViewModel.editHistoryItems}
                onToggleEditHistory={this.onToggleEditHistory}
              />
            </Col>
          </Row>
        </Grid>
        <Grid fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          {this.renderCaseInformation()}
          {!!showDocuments && (
            <CaseFilesView
              files={caseViewModel.documents.filter(
                (d) =>
                  !searchText || d.fileName.toLowerCase(formattedSearchText)
              )}
              caseModel={caseViewModel}
              userProfile={userProfile}
              onRemoveFromCase={this.onRemoveFileFromCase}
              onEditFile={this.onEditFile}
              removeDocument={this.removeDocument}
              onDelete={this.onConfirmDeleteDocument}
            />
          )}
          {!caseInfoEnabled && !showDocuments && (
            <>
              <CaseVersionList
                caseModel={caseViewModel}
                userProfile={userProfile}
                selectedVersion={this.state.selectedVersion}
                deletingVersionId={this.state.deletingVersionId}
                deletingDocumentId={this.state.deletingDocumentId}
                deletingEventId={this.state.deletingEventId}
                deletingEntryId={this.state.deletingEntryId}
                showFrameCloseButton={this.state.showFrameCloseButton}
                formattedSearchText={formattedSearchText}
                readOnly={readOnly}
              />
              <Grid
                fluid
                style={
                  trumbowygEnabled
                    ? { paddingLeft: "0px", paddingRight: "0px" }
                    : null
                }
              >
                <Row style={trumbowygEnabled ? { paddingLeft: "25px" } : null}>
                  <Col
                    xs={12}
                    className="text-left nopadding"
                    style={{
                      paddingTop: "10px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      margin: "auto",
                    }}
                  >
                    <div
                      className="flexbox-responsive"
                      style={{ minHeight: "40px", color: "#4CAF50" }}
                    >
                      {!readOnly && !creatingTimeEntry ? (
                        <FlexElement>
                          <FormGroup>
                            <Col
                              xs={3}
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                fontWeight: "normal",
                              }}
                              className="nopaddingRight"
                            >
                              <AxoLocal
                                entity="CalendarEventBoxFormtitle"
                                defaultValue={"Titel"}
                              />{" "}
                              &nbsp;&nbsp;
                            </Col>
                            <Col xs={9} className="nopaddingLeft">
                              <FormControl
                                type="text"
                                value={this.state.versionTitle}
                                onChange={this.onVersionTitleChange}
                              />
                            </Col>
                          </FormGroup>
                        </FlexElement>
                      ) : null}
                      <ButtonToolbar>
                        {!readOnly && !creatingTimeEntry ? (
                          <VersionCreateButton
                            show={!!caseViewModel.id}
                            onCreateCaseVersion={this.onCreateCaseVersion}
                            creatingVersion={creatingVersion}
                            hasNonEmptyDescription={hasNonEmptyDescription}
                          />
                        ) : null}
                        <UploadButton
                          disabled={readOnly}
                          onSelected={this.onUploadCaseFiles}
                          allowMultiple
                        >
                          <Icon glyph="icon-fontello-upload-4" />
                          &nbsp;
                          <span className="hidden-xs">
                            &nbsp;
                            <AxoLocal
                              entity="DocumentTabViewUpload"
                              defaultValue={"Upload"}
                            />{" "}
                            <AxoLocal
                              entity="documentMenuNav"
                              defaultValue={"Dokumenter"}
                            />
                          </span>
                        </UploadButton>
                        <DownloadButton
                          title="Download alle bilag som Zip fil"
                          disabled={
                            readOnly || caseViewModel.documents.length < 1
                          }
                          href={ApiService.getCaseDocumentsDownloadPath(
                            caseViewModel.id
                          )}
                        >
                          <Icon glyph="icon-fontello-download" />
                          <span className="hidden-xs">&nbsp; Zip</span>
                        </DownloadButton>
                        <LexButton
                          disabled={readOnly}
                          onClick={this.onClickCreateCalendarEvent}
                        >
                          {" "}
                          <Icon glyph="icon-fontello-clock-4" />
                          <span className="hidden-xs">
                            &nbsp;
                            <AxoLocal
                              entity="CaseEditInlineCreateCalendarEvent"
                              defaultValue={"Opret påmindelse"}
                            />
                          </span>
                        </LexButton>
                        <LexButton
                          disabled={readOnly}
                          onClick={this.onClickCreateTimeEntry}
                        >
                          <Icon glyph="icon-fontello-desktop-2" />
                          &nbsp;
                          <span className="hidden-xs">
                            &nbsp;
                            <AxoLocal
                              entity="axoidcode259"
                              defaultValue={"Registrer tidsforbrug"}
                            />
                          </span>
                          {!!totalTime && (
                            <>({TimeEntryService.getTimeString(totalTime)})</>
                          )}
                        </LexButton>
                        {/* <UploadButton
                          onlyImages
                          // onFileSelected={this.onUploadImage}
                        >
                          Upload billede
                        </UploadButton> */}
                        {/* <Button disabled={!hasNonEmptyDescription} className='Lex-button-2' onClick={this.onPrintCase}>
                          <Icon glyph="icon-fontello-print-3"/>&nbsp;&nbsp; 
                          <AxoLocal entity='DocumentTemplateGenerateButtonContent' defaultValue={'Generer PDF'}/>
                        </Button> */}
                      </ButtonToolbar>
                    </div>
                    {creatingTimeEntry && (
                      <CaseTimeEntryForm
                        onSubmit={this.onCreateTimeEntry}
                        onCancel={() =>
                          this.setState({ creatingTimeEntry: false })
                        }
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{ paddingTop: "15px" }}
                    xs={12}
                    className="nopadding"
                  >
                    {readOnly && attemptingEdit ? (
                      <div style={{ color: "#ff0000" }}>
                        <AxoLocal
                          entity="CaseCaseVersionreadOnly"
                          defaultValue={
                            "Du skal tjekke ud for at redigere sagsfremstillingen."
                          }
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          onClick={this.onCheckout}
                          className="Lex-button-2"
                          style={{ border: "1px solid #ff6600" }}
                        >
                          <Icon glyph="icon-fontello-download-2" /> &nbsp;&nbsp;
                          <AxoLocal
                            entity="Casesharing9"
                            defaultValue={"Tjek ud for redigering"}
                          />
                        </Button>
                      </div>
                    ) : null}
                    {!readOnly && !creatingTimeEntry && tinyMCE}
                  </Col>
                </Row>
              </Grid>
            </>
          )}
        </Grid>
        <CreateCalendarEventModal
          onCreateCalendarEvent={this.onCreateCalendarEvent}
          router={this.props.router}
          ref={(c) => (this.calendarEventModal = c)}
        />
        <PrintCaseModal
          contactMap={contactMap}
          className="screen"
          size="large"
          onGeneratedPdf={this.onGeneratedPdf}
          ref={(c) => (this.printCaseModal = c)}
        />
        <FileViewerModal
          size="large"
          ref={(c) => (this.fileModal = c)}
          selectedCase={caseViewModel}
          onEditFile={this.onEditFile}
          onRemoveFromCase={this.onRemoveFileFromCase}
        />
      </div>
    );
  }
}

export default withRouter(CaseListPage);
