import React from "react";
import CurrentForecast from "./CurrentForecast";
import FutureForecast from "./FutureForecast";
import Conditional from "../Conditional";
import { AxoLocal } from "../LexUtilities";
import { Icon } from "../../utilities/LexUtilities";

import { Button } from "react-bootstrap";
export default class LocalWeather extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    return (
      <div>
        <div style={{ height: "40px" }}>
          <Button
            style={{ width: "100%", height: "100%", borderRadius: "0px" }}
            className="Lex-button-2"
            onClick={this.props.onEnableWeather}
          >
            <Icon
              style={{ fontSize: "20px" }}
              glyph="icon-fontello-right-hand"
            />
            &nbsp;&nbsp;
            <AxoLocal
              style={{ fontSize: "17px" }}
              entity="CurrentForecastAllowAccessA"
              defaultValue={"Vis lokalt vejr"}
            />
            &nbsp;&nbsp;
            <Icon
              style={{ fontSize: "20px" }}
              glyph="icon-fontello-left-hand"
            />
          </Button>
        </div>
        <div className="current-forecast list-group">
          <CurrentForecast
            city={this.props.city}
            coords={this.props.coords}
            refusedGeoCoords={
              this.props.isGeolocationAvailable &&
              !this.props.isGeolocationEnabled
            }
            expanded={this.state.expanded}
            toggleExpanded={this.toggleExpanded}
          />
        </div>
        <Conditional show={this.state.expanded}>
          <div
            className="future-forecast list-group"
            style={{ marginBottom: "0px" }}
          >
            <FutureForecast city={this.props.city} coords={this.props.coords} />
          </div>
        </Conditional>
      </div>
    );
  }
}
