import React from "react";
import ReactDom from "react-dom";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";

var theme = {
  container: {
    position: "relative",
  },
  input: {
    maxWidth: 240,
    height: 30,
    padding: "10px 10px",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 12,
    border: "1px solid #aaa",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 30,
    width: 350,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
    maxHeight: "500px",
    overflowY: "auto",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#e6e6e6",
  },
};

export default class AutoComplete extends React.PureComponent {
  static propTypes = {
    suggestions: PropTypes.array.isRequired, //['Suggestion']
    // handleSelectedOption: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      suggestions: [], //['Suggestion']
    };

    this.container = React.createRef();
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let { suggestions } = this.props;

    return inputLength === 0
      ? []
      : suggestions.filter((suggestion) =>
          suggestion.toLowerCase().includes(inputValue)
        );
  };

  //Value in input element
  getSuggestionValue = (suggestion) => suggestion;

  //Value in dropdown
  renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === "input-focused") {
      return;
    }

    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    let { handleSelectedOption } = this.props;

    handleSelectedOption(suggestion);
  };

  shouldRenderSuggestions(value) {
    return !!value && value.trim().length > 0;
  }

  render() {
    let { id, value, onChange, onBlur, onKeyDown, onFocus } = this.props;

    const inputProps = {
      className: "searchbox",
      id,
      value,
      disabled: !!this.props.disabled,
      onChange: onChange,
      autoFocus: true,
      onBlur,
      onKeyDown,
      onFocus,
    };

    if (this.props.inputBorder) {
      theme.input.border = this.props.inputBorder;
    }
    if (this.props.maxWidth) {
      theme.input.maxWidth = this.props.maxWidth;
      theme.input.display = "block";
      theme.input.width = "100%";
    }

    return (
      <div ref={this.container}>
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          theme={theme}
          renderInputComponent={(inputProps) => (
            <div>
              <input {...inputProps} />
            </div>
          )}
          renderSuggestionsContainer={({ containerProps, children }) => {
            if (!this.container.current) {
              return null;
            }
            let rect = this.container.current.getBoundingClientRect();
            let { style } = containerProps;
            style = {
              ...style,
              position: "fixed",
              top: rect.top + 35,
              left: rect.left,
              zIndex: 1000,
              maxWidth: "250px",
            };
            return ReactDom.createPortal(
              <div {...containerProps} style={style}>
                {children}
              </div>,
              document.body
            );
          }}
        />
      </div>
    );
  }
}
