import React from "react";
import ApiService from "../../services/DataAccess/ApiService";
import RoutingService from "../../services/RoutingService";
import DataStore from "../../services/DataAccess/DataStore";
import DataActions from "../../services/DataAccess/DataActions";
import { debounce } from "lodash";
import { withRouter } from "react-router-dom";
// import Dropzonejs from '../../utilities/Dropzonejs'
// import InlineTinyMCEEditor from '../../utilities/Editors/InlineTinyMCEEditor'
import ModalService from "../../services/ModalService";
import InlineEdit from "../../utilities/InlineEdit";
import InlineCountrySelect from "../../utilities/CountrySelect/InlineCountrySelect";
import UserInfoService from "../../services/UserInfoService";
import moment from "moment";

import { SmallOrSmaller, MediumOrLarger } from "../../utilities/Responsive";

import {
  // AxoFrame,
  LexButton,
  AxoLocal,
  Icon,
  PrintCaseModal,
  AsyncButton,
  FileViewerModal,
  Flexbox,
  FlexElement,
  UploadButton,
  DownloadButton,
  SimpleTinyMCEEditor,
  MobileTinyMCE,
  getText,
} from "../../utilities/LexUtilities";

import {
  Row,
  Col,
  Grid,
  Button,
  ButtonToolbar,
  FormControl,
  FormGroup,
  ControlLabel,
  // Panel,
  // PanelGroup,
  DropdownButton,
} from "react-bootstrap";

import CaseFilesView from "./CaseFilesView";

class CasesView extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.openCase) {
      this.state = {
        caseViewModel: { ...props.openCase },
        clientContacts: [...props.openCase.clients],
        counterPartyContacts: [...props.openCase.counterParties],
        trumbowygEnabled: false,
      };
    }
    this.state.showSharingUI = false;
    this.state.showEditHistory = false;
    this.state.selectedVersion = 0;
    this.state.versionTitle = "";
    this.state.creatingVersion = false;
    this.state.deletingVersionId = 0;
    this.state.deletingDocumentId = 0;
    this.state.deletingEntryId = 0;
    this.state.versionListOpen = false;
    this.state.showFrameCloseButton = true;
    this.state.showMissingNameWarning = false;
    this.state.caseInfoEnabled = false;
    this.state.showDocuments = false;
    this.state.creatingTimeEntry = false;

    this.inProgress = true;

    this.editor = React.createRef();
    this.versionList = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.scrollToListBottom();
  }

  scrollToListBottom = () => {
    let { caseInfoEnabled, showDocuments } = this.state;

    if (caseInfoEnabled || showDocuments) {
      this.setState(
        {
          caseInfoEnabled: false,
          showDocuments: false,
        },
        () => this.doScrollToListBottom()
      );
      return;
    }

    this.doScrollToListBottom();
  };

  doScrollToListBottom = () => {
    let objDiv = this.versionList.current;
    if (!objDiv) {
      return;
    }

    objDiv.scrollTop = objDiv.scrollHeight;
  };

  onCasePropertyChange = (propertyName, input) => {
    var caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel[propertyName] = input.value;
    if (!caseViewModel.caseNumber) {
      this.setState({ showMissingNameWarning: true });
      setTimeout(() => {
        this.setState({ showMissingNameWarning: false });
      }, 5000);
      return;
    }
    this.handleViewModelChanges(caseViewModel);
  };

  handleViewModelChanges = (caseViewModel) => {
    this.setState({ caseViewModel: caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  //General case information
  onCaseDescriptionChangeMCE = (content) => {
    this.handleModelChangesDebounced({
      ...this.state.caseViewModel,
      description: content,
    });
  };

  onCasePriorityChange = (input) => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.priority = parseInt(input.value.id, 10);
    this.handleInlineViewModelChanges(caseViewModel);
  };

  onCaseStatusChange = (input) => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.status = input.value.id;
    this.handleInlineViewModelChanges(caseViewModel);
  };

  handleInlineViewModelChanges = (caseViewModel) => {
    this.setState({ caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  //Client information
  onClientPropertyChange = (clientIndex, propertyName, input) => {
    let clientContacts = this.state.clientContacts.slice();
    var client = { ...clientContacts[clientIndex] };
    client[propertyName] = input.value;
    clientContacts[clientIndex] = client;
    this.handleClientModelChanges(clientContacts);
  };

  handleClientModelChanges = (clientContacts) => {
    this.setState({ clientContacts }, () => {
      this.handleInlineModelChanges();
    });
  };

  //Counterparty information
  onCounterPropertyChange = (counterPartyIndex, propertyName, input) => {
    var counterPartyContacts = this.state.counterPartyContacts.slice();
    var newParty = { ...counterPartyContacts[counterPartyIndex] }; //Copy
    newParty[propertyName] = input.value;
    counterPartyContacts[counterPartyIndex] = newParty;
    this.handleInlineCounterModelChanges(counterPartyContacts);
  };

  handleInlineCounterModelChanges = (counterPartyContacts) => {
    this.setState({ counterPartyContacts }, () => {
      this.handleInlineModelChanges();
    });
  };

  handleInlineModelChanges = () => {
    this.saveChanges(false);
  };

  handleModelChangesDebounced = (caseViewModel) => {
    this.setState(
      {
        caseViewModel,
      },
      () => {
        this.debouncedSave();
      }
    );
  };

  onFinishDraft = () => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.status = "Active";
    this.setState({ caseViewModel }, () => {
      this.saveChanges(true, (newCaseId) => {
        this.onFinishedDraft(newCaseId);
      });
    });
  };

  onFinishedDraft = (newCaseId) => {
    if (!!this.props.onFinishDraft) {
      this.props.onFinishDraft(newCaseId);
    }
  };

  onPermanentDelete = () => {
    var viewModel = this.state.caseViewModel;
    if (viewModel.documents.length > 0) {
      ModalService.openConfirmModal(
        <AxoLocal
          entity="CaseEditInlinedeleteFiles"
          defaultValue={"Vil du også slette bilag tilknyttet til sagen?"}
        />,
        (value) => {
          if (value) {
            this.deleteCasePermanently({ id: viewModel.id, deleteFiles: true });
          } else {
            this.deleteCasePermanently({
              id: viewModel.id,
              deleteFiles: false,
            });
          }
        }
      );
    } else {
      this.deleteCasePermanently({ id: viewModel.id, deleteFiles: false });
    }
  };

  deleteCasePermanently = (options) => {
    DataActions.deleteCase(options)
      .then((response) => {
        if (response.status === 200) {
          this.props.onClose();
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="CaseEditInlinehandleServerError"
              defaultValue={"Sagen kunne ikke slettes. Prøv igen."}
            />
          );
        }
      })
      .catch(this.handleServerError);
  };

  debouncedSave = debounce(() => {
    this.saveChanges(false);
  }, 500);

  saveChanges = (closeOnSuccess, callback) => {
    this.inProgress = false;
    this.updateCase(closeOnSuccess, callback);
  };

  updateCase = (closeOnSuccess, callback) => {
    var currentCase = { ...this.state.caseViewModel };
    currentCase.clients = this.state.clientContacts;
    currentCase.counterParties = this.state.counterPartyContacts;
    DataActions.updateCase(currentCase)
      .then(this.handleCaseResponse.bind(this, closeOnSuccess, callback))
      .catch(this.handleServerError);
  };

  handleCaseResponse = (closeOnSuccess, callback, response) => {
    if (response.status === 200) {
      response.json().then((caseViewModel) => {
        this.setState({
          caseViewModel,
          creatingVersion: false,
        });
        if (closeOnSuccess) {
          this.props.onClose();
        }
        if (callback) {
          callback(this.state.caseViewModel.id);
        }
      });
    } else {
      this.setState({ creatingVersion: false });
      ModalService.openAlertModal(
        <AxoLocal
          entity="CaseEditInlinereason"
          defaultValue={"Sagen kunne ikke gemmes. Prøv igen."}
        />
      );
    }
  };

  handleServerError = (reason) => {
    this.inProgress = false;
    this.setState({ creatingVersion: false });
    ModalService.openAlertModal(
      <AxoLocal
        entity="CaseEditInlinereason"
        defaultValue={"Sagen kunne ikke gemmes. Prøv igen senere"}
      />
    );
  };

  onAddCounterParty = () => {
    this.setState((prevState) => ({
      counterPartyContacts: [
        ...prevState.counterPartyContacts,
        {
          name: "",
          address: "",
          eMail: "",
          phone: "",
          type: 1,
        },
      ],
    }));
  };

  onRemoveClient = (clientIndex) => {
    let clientContacts = this.state.clientContacts.slice();
    clientContacts.splice(clientIndex, 1);
    this.handleClientModelChanges(clientContacts);
  };

  onRemoveCounterParty = (counterPartyIndex) => {
    let counterPartyContacts = this.state.counterPartyContacts.slice();
    counterPartyContacts.splice(counterPartyIndex, 1);
    this.handleInlineCounterModelChanges(counterPartyContacts);
  };

  getStatusSelect = () => {
    return {
      id: this.state.caseViewModel.status,
      text: this.getStatusText(this.state.caseViewModel.status),
    };
  };

  getStatusText = (status) => {
    switch (status) {
      case "Draft":
        return (
          <AxoLocal
            entity="CaseEditInlineprioritydraft"
            defaultValue={"Kladde"}
          />
        );
      case "Active":
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityActive"
            defaultValue={"Aktiv"}
          />
        );
      case "Standby":
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityStandby"
            defaultValue={"Standby"}
          />
        );
      default:
        return <span></span>;
    }
  };

  getPrioritySelect = () => {
    return {
      id: this.state.caseViewModel.priority.toString(),
      text: this.getPriorityText(this.state.caseViewModel.priority),
    };
  };

  getPriorityText = (priority) => {
    switch (priority) {
      case 0:
        return (
          <AxoLocal entity="CaseEditInlinepriorityLow" defaultValue={"Lav"} />
        );
      case 1:
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityNormal"
            defaultValue={"Normal"}
          />
        );
      case 2:
        return (
          <AxoLocal entity="CaseEditInlinepriorityHigt" defaultValue={"Høj"} />
        );
      case 3:
        return (
          <AxoLocal
            entity="CaseEditInlinepriorityurgent"
            defaultValue={"Haster"}
          />
        );
      default:
        return <span></span>;
    }
  };

  renderAccordionHeader = (title) => {
    return (
      <div>
        <Col xs={11} style={{ padding: "0px" }}>
          <span>{title}</span>
        </Col>
        <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
          <span style={{ textAlign: "right" }}>
            <Icon glyph="icon-fontello-arrow-combo" />
          </span>
        </Col>
      </div>
    );
  };

  onCaseDateChange = (event) => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.actionDate = moment(event.target.valueAsDate).format();
    caseViewModel.reminded = false;
    this.setState({ caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  removeDeadlineFromCase = () => {
    let caseViewModel = Object.assign({}, this.state.caseViewModel);
    caseViewModel.actionDate = new Date(0);
    this.setState({ caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  addDeadlineToCase = () => {
    var newDate = new Date();
    newDate.setTime(newDate.getTime() + 86400000);
    let caseViewModel = Object.assign({}, this.state.caseViewModel);

    caseViewModel.actionDate = newDate;
    caseViewModel.reminded = false;
    this.setState({ caseViewModel }, () => {
      this.handleInlineModelChanges();
    });
  };

  renderDeadlineField = (caseModel) => {
    var deadlineMoment = moment(caseModel.actionDate);
    if (deadlineMoment.year() > 1970) {
      return (
        <div>
          <input
            style={{ borderStyle: "none" }}
            type="date"
            onChange={this.onCaseDateChange}
            value={deadlineMoment.format("YYYY-MM-DD")}
          />
          &nbsp;&nbsp;
          <Icon
            onClick={this.removeDeadlineFromCase}
            glyph="icon-fontello-scissors axored"
          />
        </div>
      );
    }
    return (
      <Icon onClick={this.addDeadlineToCase} glyph="icon-fontello-calendar-6" />
    );
  };

  handleSelectedClient = (client) => {
    var clientContacts = this.state.clientContacts.slice();
    clientContacts.push(client);
    this.setState({ clientContacts }, () => {
      this.saveChanges(false);
    });
  };

  renderCaseInformation = () => {
    if (!this.state.caseInfoEnabled) {
      return null;
    }

    return (
      <div>
        <div className="bgcolortwo">{this.renderClientInformation()}</div>
        {this.state.counterPartyContacts.length > 0 && (
          <div className="bgcolorthree topPadding bottomPadding leftPadding">
            <div className="bgcolorthree">
              {this.renderCounterPartyInformation()}
            </div>
          </div>
        )}
      </div>
    );
  };

  getClientTitle = (index, noClients) => {
    return (
      <AxoLocal
        entity="RditInlineclientInfoHeader"
        defaultValue="Information"
      />
    );
  };

  renderClientInformation = () => {
    var clientInfoNodes = [];
    for (var i = 0; i < this.state.clientContacts.length; i++) {
      var client = this.state.clientContacts[i];
      clientInfoNodes.push(
        <div key={i} className="bgcolortwo">
          {i > 0 ? <hr /> : null}
          <Grid fluid>
            <Row className="bgcolortwo">
              <Col sm={6}>
                <ControlLabel style={{ textAlign: "left", fontSize: "16px" }}>
                  {this.getClientTitle(i, this.state.clientContacts.length)}
                </ControlLabel>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-user-outline" />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span>{client.firstName}</span>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-user-7" />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span>{client.lastName}</span>
                </div>
              </Col>
              <Col sm={6}>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-location-4" />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span>{client.address}</span>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-mail-1" />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span>{client.eMail}</span>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-headphones-2" />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span>{client.phone}</span>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    if (clientInfoNodes.length === 0) {
      return (
        <div className="text-left">
          <AxoLocal
            entity="CaseEditFormphoneThereisnoclientconnectedtothecase"
            defaultValue={"Der er ingen klient tilknyttet sagen."}
          />
        </div>
      );
    }
    return clientInfoNodes;
  };

  selectCounterCountry(counterPartyIndex, country) {
    var counterPartyContacts = this.state.counterPartyContacts.slice();
    var newParty = { ...counterPartyContacts[counterPartyIndex] }; //Copy
    newParty.country = country;
    counterPartyContacts[counterPartyIndex] = newParty;

    this.handleInlineCounterModelChanges(counterPartyContacts);
  }

  renderCounterPartyInformation = () => {
    var readOnly = true; //Clients cannot edit case information
    var counterPartyNodes = [];
    for (var i = 0; i < this.state.counterPartyContacts.length; i++) {
      var counterParty = this.state.counterPartyContacts[i];
      counterPartyNodes.push(
        <div key={i}>
          {i > 0 ? <hr /> : null}
          <Grid fluid>
            <Row>
              <Col sm={6}>
                <ControlLabel style={{ textAlign: "left", fontSize: "16px" }}>
                  <AxoLocal
                    entity="RditInlinecounterPartyInfoHeader"
                    defaultValue="Modparternes kontaktinformation"
                  />
                </ControlLabel>
                &nbsp;&nbsp;&nbsp;
                <span
                  role="button"
                  onClick={this.onRemoveCounterParty.bind(this, i)}
                  className="Lex-button-2"
                >
                  <Icon role="button" glyph="icon-fontello-scissors axored" />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <AxoLocal
                    entity="CaseEditFormRemoveCounterParty"
                    defaultValue={"Slet modpart"}
                  />
                </span>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-user-outline" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormfirstName"
                      defaultValue={"Fornavn *"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.firstName}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.firstName || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "firstName"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-user-7" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormlastName"
                      defaultValue={"Efternavn"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.lastName}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.lastName || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "lastName"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-location-4" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.address}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.address || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "address"
                      )}
                    />
                  )}
                </div>
              </Col>
              <Col sm={6}>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-direction-1" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormpostalCode"
                      defaultValue={"Postnummer"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.postalCode}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.postalCode || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "postalCode"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-warehouse" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="CaseEditFormcity" defaultValue={"By"} />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.city}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.city || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "city"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-globe-6" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormcountry"
                      defaultValue={"Land"}
                    />
                    :&nbsp;
                  </span>
                  {readOnly ? (
                    <span>{counterParty.country}</span>
                  ) : (
                    <InlineCountrySelect
                      className="editable"
                      key={this.state.counterPartyContacts[i].id}
                      value={this.state.counterPartyContacts[i].country}
                      valueType="short"
                      onChange={this.selectCounterCountry.bind(this, i)}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-mail-1" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseEditFormeMail"
                      defaultValue={"Email"}
                    />
                    :&nbsp;
                  </span>
                  &nbsp;
                  {readOnly ? (
                    <span>{counterParty.eMail}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.eMail || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "eMail"
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <span>
                    <Icon glyph="icon-fontello-headphones-2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />
                    :&nbsp;
                  </span>
                  &nbsp;
                  {readOnly ? (
                    <span>{counterParty.phone}</span>
                  ) : (
                    <InlineEdit
                      value={counterParty.phone || "---"}
                      change={this.onCounterPropertyChange.bind(
                        this,
                        i,
                        "phone"
                      )}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    return counterPartyNodes;
  };

  onToggleCaseInfo = () => {
    this.setState((prevState) => ({
      caseInfoEnabled: !prevState.caseInfoEnabled,
      showDocuments: false,
    }));
  };

  onToggleDocuments = () => {
    this.setState((prevState) => ({
      showDocuments: !prevState.showDocuments,
      caseInfoEnabled: false,
    }));
  };

  onFileAdded = (file) => {
    DataActions.createCaseDocumentAssignment({
      caseId: this.state.caseViewModel.id,
      fileInfoModelId: file.id,
    });
  };

  isDraft = () => {
    return this.state.caseViewModel.status === "Draft";
  };

  isClosed = () => {
    return this.state.caseViewModel.closed;
  };

  isTrashed = () => {
    return this.state.caseViewModel.trashed;
  };

  onPrintCase = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.printCaseModal.open(this.state.caseViewModel, this.props.userProfile);
  };

  onShareCase = () => {
    this.setState({ showSharingUI: true });
  };

  onToggleEditHistory = () => {
    this.setState({ showEditHistory: !this.state.showEditHistory });
  };

  renderDeleteButtons = (caseViewModel, userProfile) => {
    var deleteButtons = [];

    //Shared cases can only be deleted by the user, after checking out the case
    var allowDelete =
      caseViewModel.sharingUsers.length === 0 ||
      (caseViewModel.editor &&
        caseViewModel.editor.id === userProfile.id &&
        caseViewModel.userName === userProfile.userName);
    if (!allowDelete) {
      return null;
    }

    if (this.isTrashed()) {
      deleteButtons.push(
        <AxoLocal
          componentClass={Button}
          className="Lex-button-2"
          onClick={this.onPermanentDelete}
          key="permanent"
          componentAttribute="title"
          entity="CaseEditInlineClearcasepermanently"
        >
          <Icon glyph="icon-fontello-trash-1" />
        </AxoLocal>
      );
      deleteButtons.push(
        <AxoLocal
          componentClass={Button}
          className="Lex-button-2"
          onClick={this.onToggleDelete}
          key="restore"
          componentAttribute="title"
          entity="CaseEditFormToggleClose"
        >
          <Icon glyph="icon-fontello-ccw-2" />
        </AxoLocal>
      );
    } else if (this.isDraft()) {
      deleteButtons.push(
        <AxoLocal
          componentClass={Button}
          className="Lex-button-2"
          onClick={this.onToggleDelete}
          key="discard"
          componentAttribute="title"
          entity="CaseEditFormBoxingdraft"
        >
          <Icon glyph="icon-fontello-trash-1" />
        </AxoLocal>
      );
    } else {
      deleteButtons.push(
        <AxoLocal
          componentClass={Button}
          className="Lex-button-2"
          onClick={this.onToggleDelete}
          key="discard"
          componentAttribute="title"
          entity="CaseEditFormremoveCase"
        >
          <Icon glyph="icon-fontello-trash-1" />
        </AxoLocal>
      );
    }

    return deleteButtons;
  };

  onDeleteCaseVersion = (versionId, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ deletingVersionId: versionId });
  };

  onConfirmDeleteVersion = (versionId, event) => {
    event.preventDefault();
    event.stopPropagation();
    ApiService.deleteCaseVersion(versionId).then((response) => {
      if (response.ok) {
        var caseViewModel = Object.assign({}, this.state.caseViewModel);
        caseViewModel.caseVersions = caseViewModel.caseVersions.filter(
          (v) => v.id !== versionId
        );
        this.setState({ caseViewModel });
      }
    });
  };

  onCancelDeleteVersion = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ deletingVersionId: 0 });
  };

  onRenderFrame = (height) => {
    if (height > 800) {
      this.setState({ showFrameCloseButton: true });
    }
  };

  onCloseFrame = () => {
    this.setState({
      selectedVersion: 0,
      showFrameCloseButton: false,
    });
  };

  onConfirmDeleteEvent = async (eventId) => {
    let response = await DataActions.deleteCalendarEvent(eventId);
    if (!response.ok) {
      return false;
    }
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        events: prevState.caseViewModel.events.filter((e) => e.id !== eventId),
      },
    }));
  };

  onDeleteDocument = (id) => {
    this.setState({ deletingDocumentId: id });
  };

  onConfirmDeleteDocument = async (doc) => {
    let response = await DataActions.updateDocument({
      ...doc,
      trashed: true,
    });
    if (!response.ok) {
      return false;
    }
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        documents: prevState.caseViewModel.documents.filter(
          (e) => e.id !== doc.id
        ),
      },
    }));

    return true;
  };

  onCancelDeleteDocument = () => {
    this.setState({ deletingDocumentId: 0 });
  };

  onDeleteEntry = (id) => {
    this.setState({ deletingEntryId: id });
  };

  onConfirmDeleteEntry = async (id) => {
    let response = await DataActions.deleteTimeEntry(id);
    if (!response.ok) {
      return false;
    }
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        timeEntries: prevState.caseViewModel.timeEntries.filter(
          (e) => e.id !== id
        ),
      },
    }));

    return true;
  };

  onCancelDeleteEntry = () => {
    this.setState({ deletingEntryId: 0 });
  };

  onCreateTimeEntry = async (entry) => {
    let response = await DataActions.createTimeEntry({
      ...entry,
      workDate: entry.workDate || moment.utc().format(),
      caseModelId: this.state.caseViewModel.id,
    });

    if (!response.ok) {
      return false;
    }

    let createdEntry = await response.json();

    this.setState(
      (prevState) => ({
        caseViewModel: {
          ...prevState.caseViewModel,
          timeEntries: prevState.caseViewModel.timeEntries.concat([
            createdEntry,
          ]),
        },
        creatingTimeEntry: false,
      }),
      () => this.scrollToListBottom()
    );

    return true;
  };

  Version = React.memo(({ version, selectedVersion, deletingVersionId }) => {
    var { userProfile, readOnly } = this.props;

    let { caseViewModel } = this.state;

    let className =
      "flexbox-standard CaseVersionListItem leftPadding rightPadding";
    let active = version.id === selectedVersion;
    if (active) {
      console.log("Active");
      className += " active";
    }
    return (
      <div key={version.id}>
        <div className="text-center">{moment(version.date).format("LLL")}</div>
        <div
          onClick={this.onSelectVersion.bind(this, version.id)}
          className={className}
        >
          <FlexElement>
            <div>{UserInfoService.getDisplayName(version.userProfile)}</div>
            <div>
              <strong>{!!version.title && <>{version.title}</>}</strong>
            </div>
          </FlexElement>
          {!readOnly ? (
            <FlexElement flexGrow={1} className="text-right">
              {deletingVersionId === version.id ? (
                <span>
                  <LexButton
                    onClick={this.onConfirmDeleteVersion.bind(this, version.id)}
                  >
                    <AxoLocal
                      entity="CaseCaseVersionreadOnly1"
                      defaultValue={"Bekræft sletning"}
                    />
                  </LexButton>
                  &nbsp;
                  <LexButton onClick={this.onCancelDeleteVersion}>
                    <AxoLocal
                      entity="axoAccounting22"
                      defaultValue={"Fortryd"}
                    />
                  </LexButton>
                  &nbsp;
                </span>
              ) : (
                <>
                  {caseViewModel.userName === userProfile.userName ||
                  version.userProfile.userName === userProfile.userName ? (
                    <>
                      <Icon
                        className="leftPadding"
                        role="button"
                        glyph="icon-fontello-trash-1"
                        onClick={this.onDeleteCaseVersion.bind(
                          this,
                          version.id
                        )}
                      />
                    </>
                  ) : null}
                </>
              )}
            </FlexElement>
          ) : null}
        </div>
        {!!version.description && !this.htmlStringIsBlank(version.description) && (
          <div>
            <div
              className="leftPadding"
              dangerouslySetInnerHTML={{ __html: version.description }}
            />
            {/* <AxoFrame
              bottomPadding={25}
              content={version.description}
              onRender={this.onRenderFrame}
            /> */}
          </div>
        )}
      </div>
    );
  });

  onRemoveMail = async (message, event) => {
    let { updateMessageFromHeader } = this.props;

    event.preventDefault();
    event.stopPropagation();

    try {
      await updateMessageFromHeader({
        ...message,
        caseModel: null,
        caseModelId: null,
      });
    } catch {
      return false;
    }

    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        messages: prevState.caseViewModel.messages.filter(
          (m) => m.id !== message.id
        ),
      },
    }));

    return true;
  };

  onDeleteMail = async (message, event) => {
    let { updateMessageFromHeader } = this.props;

    event.preventDefault();
    event.stopPropagation();

    try {
      await updateMessageFromHeader({
        ...message,
        trashed: true,
      });
    } catch {
      return false;
    }

    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        messages: prevState.caseViewModel.messages.filter(
          (m) => m.id !== message.id
        ),
      },
    }));

    return true;
  };

  CaseVersionList = (props) => {
    var {
      caseModel,
      userProfile,
      selectedVersion,
      deletingVersionId,
      deletingDocumentId,
      // showFrameCloseButton,
      readOnly,
    } = props;

    if (!caseModel.caseVersions) {
      return null;
    }

    const Document = React.memo(({ doc, index }) => {
      let { caseViewModel } = this.state;

      let className =
        "flexbox-standard CaseVersionListItem leftPadding rightPadding";
      className += " Caselistdoc";
      return (
        <div>
          <div>
            <div className="text-center">{moment(doc.date).format("LLL")}</div>
          </div>
          <div key={"document-" + doc.id} className={className}>
            <FlexElement
              role="button"
              onClick={() =>
                this.fileModal.open(caseViewModel.documents, {
                  messageId: null,
                  fileIndex: index,
                })
              }
            >
              <div>
                {!!doc.fileName && (
                  <>
                    &nbsp;{doc.fileName}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <Icon glyph="icon-fontello-attach-outline" />
                  </>
                )}
              </div>
            </FlexElement>
            {!readOnly ? (
              <FlexElement flexGrow={1} className="text-right">
                {deletingDocumentId === doc.id ? (
                  <span>
                    <AsyncButton
                      onClick={this.onConfirmDeleteDocument.bind(this, doc)}
                    >
                      <AxoLocal
                        entity="CaseCaseVersionreadOnly1"
                        defaultValue={"Bekræft sletning"}
                      />
                    </AsyncButton>
                    &nbsp;
                    <LexButton onClick={this.onCancelDeleteDocument}>
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </LexButton>
                    &nbsp;
                  </span>
                ) : (
                  <>
                    {caseModel.userName === userProfile.userName ||
                    doc.userName === userProfile.userName ? (
                      <>
                        <Icon
                          role="button"
                          glyph="icon-fontello-scissors"
                          title={getText(
                            "CaseEditRemovedocumentfromcase",
                            "Fjern dokument fra sag"
                          )}
                          onClick={this.removeDocument.bind(this, doc.id)}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Icon
                          role="button"
                          glyph="icon-fontello-trash-1"
                          title={getText("axoidcode261", "Slet dokument")}
                          onClick={this.onDeleteDocument.bind(this, doc.id)}
                        />
                      </>
                    ) : null}
                  </>
                )}
              </FlexElement>
            ) : null}
          </div>
        </div>
      );
    });

    let Version = this.Version;
    let items = caseModel.caseVersions.map((v) => ({
      date: v.date,
      renderItem: () => (
        <Version
          key={v.id}
          version={v}
          selectedVersion={selectedVersion}
          deletingVersionId={deletingVersionId}
        />
      ),
    }));
    items = items.concat(
      caseModel.documents.map((d, index) => ({
        date: moment.utc(d.assignmentDate).isAfter(moment.utc(0))
          ? d.assignmentDate
          : d.uploadDate,
        renderItem: () => (
          <Document key={"document-" + d.id} doc={d} index={index} />
        ),
      }))
    );
    items = items.sort(
      (l, r) => moment(l.date).valueOf() - moment(r.date).valueOf()
    );

    let nodes = items.map((item) => {
      if (!!item.renderItem) {
        return item.renderItem();
      }
      return <div>Element could not be recognized</div>;
    });

    return (
      <div>
        <div
          ref={this.versionList}
          className="CaseVersionList bgcolorfour"
          style={{ maxHeight: "600px", overflowY: "scroll" }}
        >
          {nodes}
        </div>
      </div>
    );
  };

  onSelectVersion = (versionId) => {
    var { selectedVersion } = this.state;
    if (selectedVersion === versionId) {
      this.setState({ selectedVersion: 0 });
    } else {
      this.setState({ selectedVersion: versionId });
    }
  };

  onVersionTitleChange = (event) => {
    this.setState({ versionTitle: event.target.value });
  };

  onCreateCaseVersion = () => {
    this.setState(
      {
        creatingVersion: true,
        trumbowygEnabled: false,
      },
      () => {
        this.doCreateCaseVersion();
      }
    );
  };

  doCreateCaseVersion = async () => {
    var { caseViewModel, versionTitle } = this.state;

    var caseVersion = {
      title: versionTitle,
      description: caseViewModel.description,
      caseModelId: caseViewModel.id,
      sharedWithClient: true,
    };

    let response = await ApiService.createCaseVersion(caseVersion);
    if (!response.ok) {
      return false;
    }

    let newVersion = await response.json();
    let caseModel = {
      ...caseViewModel,
      description: "<html></html>",
      caseVersions: caseViewModel.caseVersions.concat([newVersion]),
    };

    this.setState(
      {
        versionTitle: "",
        caseViewModel: caseModel,
        trumbowygEnabled: false,
      },
      () => {
        this.saveChanges(false);
        this.scrollToListBottom();
      }
    );

    this.editor.current.setContent("");
  };

  VersionCreateButton = (props) => {
    var { creatingVersion, hasNonEmptyDescription, onCreateCaseVersion } =
      props;

    return (
      <Button
        disabled={creatingVersion || !hasNonEmptyDescription}
        onClick={onCreateCaseVersion}
        className="Lex-button-2"
      >
        <Icon glyph="icon-fontello-floppy-1" /> &nbsp;&nbsp;
        <AxoLocal
          entity="currentMessageisSendButtonContent"
          defaultValue={"Send"}
        />
        {/* <AxoLocal entity='CaseCaseVersion1' defaultValue={'Gem denne sagsfremstilling'}/> */}
        {creatingVersion ? (
          <span>
            &nbsp;
            <img
              alt=""
              src="/imgs/app/loading.gif"
              className="img-circle"
              width="20"
              height="20"
            />
          </span>
        ) : null}
      </Button>
    );
  };

  onCheckout = () => {
    DataActions.checkoutCase(this.state.caseViewModel.id);
  };

  onGeneratedPdf = (newPdf) => {
    DataStore.setActiveDocumentId(newPdf.id);
    this.props.history.push(RoutingService.getPath("Documents"));
  };

  hasNonEmptyDescription = () => {
    let { caseViewModel, versionTitle } = this.state;

    var description = caseViewModel.description;
    if (!versionTitle || this.htmlStringIsBlank(description)) {
      return false;
    }
    return true;
  };

  htmlStringIsBlank = (html) => {
    if (!html) {
      return true;
    }

    let trimmedHtml = html.replace(/\s/g, "");
    return (
      trimmedHtml.indexOf("</body>") === -1 ||
      trimmedHtml.indexOf("</body>") - trimmedHtml.indexOf("<body>") < 7
    );
  };

  noOp = () => {};

  CaseDropDown = (props) => {
    let { readOnly } = this.props;

    // let {
    //   caseInfoEnabled
    // } = this.state;

    return (
      <div className="buttonDropdown" style={{ paddingRight: "150px" }}>
        <DropdownButton
          id="menuDropdown"
          noCaret
          // open={openDropdown}
          // onToggle={onToggleDropdown}
          title={
            <span style={{ fontSize: "25px" }}>
              <Icon
                style={{ fontSize: "30px" }}
                className="navminimenu"
                glyph="icon-fontello-dot-3"
              />
            </span>
          }
        >
          <div style={{ overflowY: "scroll", height: "200px" }}>
            <div className="bottomPadding">
              <LexButton
                className="axolextopmenuButton"
                onClick={this.onToggleCaseInfo}
                style={{ height: "45px" }}
              >
                <Icon glyph="icon-fontello-users-3" /> &nbsp; &nbsp;
                <AxoLocal
                  entity="SidebarRightContainerfileInformation"
                  defaultValue={"Sagsinformation"}
                />
              </LexButton>
            </div>
            <div className="bottomPadding">
              <LexButton
                className="axolextopmenuButton"
                onClick={this.onToggleDocuments}
                style={{ height: "45px" }}
              >
                {/* Skift ikon */}
                <Icon glyph="icon-fontello-upload-4" />
                &nbsp;
                <AxoLocal
                  entity="documentMenuNav"
                  defaultValue={"Dokumenter"}
                />
              </LexButton>
            </div>
            <div className="bottomPadding">
              <UploadButton
                className="axolextopmenuButton"
                disabled={readOnly}
                onSelected={this.onUploadCaseFiles}
                allowMultiple
                style={{ height: "45px" }}
              >
                <Icon glyph="icon-fontello-upload-4" />
                &nbsp;
                <AxoLocal
                  entity="DocumentTabViewUpload"
                  defaultValue={"Upload"}
                />
              </UploadButton>
            </div>
          </div>
        </DropdownButton>
      </div>
    );
  };

  onUploadCaseFiles = async (files) => {
    let response = await DataActions.uploadDocuments(files, {
      caseModelId: this.state.caseViewModel.id,
    });
    if (!response.ok) {
      return false;
    }

    let addedFiles = await response.json();
    if (!addedFiles || addedFiles.length === 0) {
      return;
    }

    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        documents: prevState.caseViewModel.documents.concat(
          addedFiles.map((f) => ({ ...f, assignmentDate: moment.utc() }))
        ),
      },
    }));

    this.scrollToListBottom();
    return response.ok;
  };

  removeDocument = async (documentId) => {
    let response = await DataActions.deleteCaseDocumentAssignment(
      this.state.caseViewModel.id,
      documentId
    );
    if (!response.ok) {
      return false;
    }
    this.onRemoveFileFromCase(documentId);
    return true;
  };

  onRemoveFileFromCase = (documentId) => {
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        documents: prevState.caseViewModel.documents.filter(
          (d) => d.id !== documentId
        ),
      },
    }));
  };

  onEditFile = (newFile) => {
    this.setState((prevState) => ({
      caseViewModel: {
        ...prevState.caseViewModel,
        documents: prevState.caseViewModel.documents.map((d) =>
          d.id === newFile.id ? newFile : d
        ),
      },
    }));
  };

  render() {
    let { userProfile, readOnly, contactMap, userSettings } = this.props;

    let {
      clientContacts,
      caseViewModel,
      creatingVersion,
      trumbowygEnabled,
      attemptingEdit,
      caseInfoEnabled,
      showDocuments,
      creatingTimeEntry,
    } = this.state;

    //Load editor when full case with description has been fetched
    let tinyMCE = caseViewModel.description !== undefined && (
      <>
        <MediumOrLarger>
          <SimpleTinyMCEEditor
            ref={this.editor}
            text={caseViewModel.description}
            locale={userSettings.locale}
            onTextChange={this.onCaseDescriptionChangeMCE}
            height={250}
          />
        </MediumOrLarger>
        <SmallOrSmaller>
          <MobileTinyMCE
            ref={this.editor}
            text={caseViewModel.description}
            locale={userSettings.locale}
            onTextChange={this.onCaseDescriptionChangeMCE}
            height={250}
          />
        </SmallOrSmaller>
      </>
    );

    // let caseModelOptions = caseModelArray.map(c => ({ value: c.id, label: c.caseNumber }))

    var hasNonEmptyDescription = this.hasNonEmptyDescription();

    let CaseVersionList = this.CaseVersionList;
    let VersionCreateButton = this.VersionCreateButton;
    return (
      <div style={{ maxWidth: "1280px" }}>
        <Grid fluid>
          <Row className="hidden-print">
            <Col lg={7} className="nopaddingRight">
              <Flexbox alignCenter className="largeText">
                {/* { caseModelOptions.length > 1 && (
                  <FlexElement className='rightPadding onToppest' flexBasis='250px'>
                    <ReactSelect
                      name="formerSelect"
                      menuPlacement='auto'
                      value={{ value: caseViewModel.id, label: caseViewModel.caseNumber }}
                      onChange={selection => {
                        if(!selection) {
                          return;
                        }
                        let index = caseModelArray.findIndex(c => c.id === selection.value);
                        if(index > -1) {
                          setActiveCaseIndex(index);
                        }
                      }}
                      noOptionsMessage={() => ''}
                      options={caseModelOptions}
                    />
                  </FlexElement>
                )} */}
                <FlexElement className="rightPadding">
                  {caseViewModel.caseNumber}
                  {clientContacts.length > 0 && (
                    <>
                      &nbsp;- {clientContacts[0].firstName}{" "}
                      {clientContacts[0].lastName}
                    </>
                  )}
                </FlexElement>
                {(!!caseInfoEnabled || showDocuments) && (
                  <FlexElement>
                    <Icon
                      glyph="icon-fontello-cancel-circled2"
                      className="axored largeText"
                      role="button"
                      onClick={() =>
                        this.setState({
                          caseInfoEnabled: false,
                          showDocuments: false,
                        })
                      }
                    />
                  </FlexElement>
                )}
              </Flexbox>
              {caseViewModel.owner && (
                <h4>
                  &nbsp;&nbsp;(
                  <AxoLocal
                    entity="CaseEditInlinecaseowner"
                    defaultValue={"Ejer"}
                  />{" "}
                  : {UserInfoService.getDisplayName(caseViewModel.owner)})
                </h4>
              )}
            </Col>
            <Col
              lg={5}
              className="text-right nopadding bottomPadding rightPadding"
            >
              <LexButton
                className="axolextopmenuButton"
                onClick={this.onToggleCaseInfo}
                style={{ height: "45px" }}
              >
                <Icon glyph="icon-fontello-users-3" />
              </LexButton>
            </Col>
          </Row>
        </Grid>
        <Grid fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          {this.renderCaseInformation()}
          {!!showDocuments && (
            <CaseFilesView
              files={caseViewModel.documents}
              caseModel={caseViewModel}
              userProfile={userProfile}
              onRemoveFromCase={this.onRemoveFileFromCase}
              onEditFile={this.onEditFile}
              removeDocument={this.removeDocument}
              onDelete={this.onConfirmDeleteDocument}
            />
          )}
          {!caseInfoEnabled && !showDocuments && (
            <>
              <CaseVersionList
                caseModel={caseViewModel}
                userProfile={userProfile}
                selectedVersion={this.state.selectedVersion}
                deletingVersionId={this.state.deletingVersionId}
                deletingDocumentId={this.state.deletingDocumentId}
                deletingEntryId={this.state.deletingEntryId}
                showFrameCloseButton={this.state.showFrameCloseButton}
                readOnly={readOnly}
              />
              <Grid
                fluid
                style={
                  trumbowygEnabled
                    ? { paddingLeft: "0px", paddingRight: "0px" }
                    : null
                }
              >
                <Row style={trumbowygEnabled ? { paddingLeft: "25px" } : null}>
                  <Col
                    xs={12}
                    className="text-left nopadding"
                    style={{
                      paddingTop: "10px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      margin: "auto",
                    }}
                  >
                    <div
                      className="flexbox-responsive"
                      style={{ minHeight: "40px", color: "#4CAF50" }}
                    >
                      {!readOnly && !creatingTimeEntry ? (
                        <FlexElement>
                          <FormGroup>
                            <Col
                              xs={3}
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                fontWeight: "normal",
                              }}
                              className="nopaddingRight"
                            >
                              <AxoLocal
                                entity="CalendarEventBoxFormtitle"
                                defaultValue={"Titel"}
                              />{" "}
                              &nbsp;&nbsp;
                            </Col>
                            <Col xs={9} className="nopaddingLeft">
                              <FormControl
                                type="text"
                                value={this.state.versionTitle}
                                onChange={this.onVersionTitleChange}
                              />
                            </Col>
                          </FormGroup>
                        </FlexElement>
                      ) : null}
                      <ButtonToolbar>
                        {!readOnly && !creatingTimeEntry ? (
                          <VersionCreateButton
                            show={!!caseViewModel.id}
                            onCreateCaseVersion={this.onCreateCaseVersion}
                            creatingVersion={creatingVersion}
                            hasNonEmptyDescription={hasNonEmptyDescription}
                          />
                        ) : null}
                        <UploadButton
                          disabled={readOnly}
                          onSelected={this.onUploadCaseFiles}
                          allowMultiple
                        >
                          <Icon glyph="icon-fontello-upload-4" />
                          &nbsp;
                          <span className="hidden-xs">
                            &nbsp;
                            <AxoLocal
                              entity="DocumentTabViewUpload"
                              defaultValue={"Upload"}
                            />{" "}
                            <AxoLocal
                              entity="documentMenuNav"
                              defaultValue={"Dokumenter"}
                            />
                          </span>
                        </UploadButton>
                        <DownloadButton
                          title="Download alle bilag som Zip fil"
                          href={ApiService.getCaseDocumentsDownloadPath(
                            caseViewModel.id
                          )}
                          disabled={
                            readOnly || caseViewModel.documents.length < 1
                          }
                        >
                          <Icon glyph="icon-fontello-download" />
                          &nbsp; Download Zip
                        </DownloadButton>

                        {/* <UploadButton
                          onlyImages
                          // onFileSelected={this.onUploadImage}
                        >
                          Upload billede
                        </UploadButton> */}
                        {/* <Button disabled={!hasNonEmptyDescription} className='Lex-button-2' onClick={this.onPrintCase}>
                          <Icon glyph="icon-fontello-print-3"/>&nbsp;&nbsp; 
                          <AxoLocal entity='DocumentTemplateGenerateButtonContent' defaultValue={'Generer PDF'}/>
                        </Button> */}
                      </ButtonToolbar>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{ paddingTop: "15px" }}
                    xs={12}
                    className="nopadding"
                  >
                    {readOnly && attemptingEdit ? (
                      <div style={{ color: "#ff0000" }}>
                        <AxoLocal
                          entity="CaseCaseVersionreadOnly"
                          defaultValue={
                            "Du skal tjekke ud for at redigere sagsfremstillingen."
                          }
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          onClick={this.onCheckout}
                          className="Lex-button-2"
                          style={{ border: "1px solid #ff6600" }}
                        >
                          <Icon glyph="icon-fontello-download-2" /> &nbsp;&nbsp;
                          <AxoLocal
                            entity="Casesharing9"
                            defaultValue={"Tjek ud for redigering"}
                          />
                        </Button>
                      </div>
                    ) : null}
                    {!readOnly && !creatingTimeEntry && tinyMCE}
                  </Col>
                </Row>
              </Grid>
            </>
          )}
        </Grid>
        <PrintCaseModal
          contactMap={contactMap}
          className="screen"
          size="large"
          onGeneratedPdf={this.onGeneratedPdf}
          ref={(c) => (this.printCaseModal = c)}
        />
        <FileViewerModal
          size="large"
          readOnly
          ref={(c) => (this.fileModal = c)}
        />
      </div>
    );
  }
}

export default withRouter(CasesView);
