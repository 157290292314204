import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import {
  LexButton,
  AsyncButton,
  AxoCheckbox,
  ImageSearchBox,
  Icon,
  UploadButton,
  AxoLocal,
} from "../utilities/LexUtilities";

import { ApiService } from "../services/AxoServices";

//This form receives starting state and submit function from outside.
export default class ModelTemplateFormView extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    startValue: PropTypes.object,
  };

  constructor(props) {
    super(props);

    if (props.startValue) {
      this.state = { ...props.startValue };
    } else {
      this.state = this.getStartState();
    }
  }

  getStartState = () => {
    return {
      text: "",
      longText: "",
      number: 0,
      type: "First",
      isActive: true,
      imageId: null,
      image: null,
      thingies: [],

      showValidationError: false,
    };
  };

  handleChange = (event) => {
    let { type, name } = event.target;

    let value = null;
    switch (type) {
      case "number":
        value = event.target.valueAsNumber;
        break;
      case "checkbox":
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
    }

    this.onUpdateProperty(name, value);
  };

  onUpdateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  onUpdateThingyProperty = (index, propertyName, value) => {
    this.setState((prevState) => ({
      thingies: prevState.thingies.map((thingy, i) => {
        if (i !== index) {
          return thingy;
        }
        return {
          ...thingy,
          [propertyName]: value,
        };
      }),
    }));
  };

  onSubmit = async () => {
    if (!this.validateInput()) {
      this.setState({ showValidationError: true });
      setTimeout(() => {
        this.setState({ showValidationError: false });
      }, 3000);

      return false;
    }

    let { onSubmit } = this.props;

    let result = await onSubmit(this.state);
    return result;
  };

  validateInput = () => {
    //Validation here
    let { text, longText } = this.state;

    //Only text and long text is required in this case. If
    if (!text || !longText) {
      return false;
    }

    return true;
  };

  onCancel = () => {
    let { onCancel, startValue } = this.props;

    if (onCancel) {
      onCancel();
      return;
    }

    if (startValue) {
      this.setState(this.props.startValue);
    } else {
      this.setState(this.getStartState());
    }
  };

  onUploadImage = async (image) => {
    let response = await ApiService.uploadFileObjects([image]);
    if (!response.ok) {
      return false;
    }

    let addedFiles = await response.json();
    if (!addedFiles || addedFiles.length === 0) {
      return;
    }

    let addedFile = addedFiles[0];

    this.setState({
      imageId: addedFile.id,
      image: addedFile,
    });

    return response.ok;
  };

  onAddThingy = () => {
    this.setState((prevState) => ({
      thingies: [
        ...prevState.thingies,
        {
          name: "",
          description: "",
        },
      ],
    }));
  };

  onRemoveThingy = (index) => {
    this.setState((prevState) => ({
      thingies: prevState.thingies.filter((t, i) => i !== index),
    }));
  };

  //Skabelon. Der skal ikke laves entities
  render() {
    let { documentMap } = this.props;

    let {
      text,
      number,
      longText,
      type,
      isActive,
      image,
      imageId,
      thingies,
      showValidationError,
    } = this.state;

    let selectOptions = [
      { value: "First", label: "First" },
      { value: "Second", label: "Second" },
      { value: "Third", label: "Third" },
    ];

    let typeOption =
      selectOptions.find((o) => o.value === type) || selectOptions[0];

    return (
      <Form className="leftPadding standardMaxWidth">
        <FormGroup controlId="text">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Tekst
          </ControlLabel>
          <FormControl
            name="text" //Name must correspond to the name of the property
            type="text"
            className="borderFormControlfocus"
            value={text}
            placeholder="Skriv tekst her..."
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="longText">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Long text
          </ControlLabel>
          <FormControl
            name="longText"
            componentClass="textarea"
            className="borderFormControlfocus"
            value={longText}
            placeholder="Skriv lang tekst her..."
            onChange={this.handleChange}
            rows={3}
          />
        </FormGroup>
        <FormGroup controlId="number">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Number
          </ControlLabel>
          <FormControl
            name="number"
            type="number"
            className="borderFormControlfocus"
            value={number}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="type">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Type
          </ControlLabel>
          <Select
            name="select"
            menuPlacement="auto"
            value={typeOption}
            onChange={(selection) => {
              if (!!selection) {
                this.onUpdateProperty("type", selection.value);
              }
            }}
            noOptionsMessage={() => ""}
            options={selectOptions}
          />
        </FormGroup>
        <FormGroup controlId="number">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Active
          </ControlLabel>
          <AxoCheckbox
            name="isActive"
            checked={isActive}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          {!!image && imageId ? (
            <div>
              <div>
                {image.fileName}
                &nbsp;
                <Icon
                  role="button"
                  glyph="icon-fontello-scissors axored"
                  onClick={() => this.setState({ imageId: null })}
                />
              </div>
              <div style={{ maxWidth: "250px" }}>
                <img
                  width="100%"
                  alt=""
                  src={ApiService.getFileInlinePath(imageId)}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="bottomPadding">
                <ImageSearchBox
                  documentMap={documentMap}
                  handleSelectedDocument={(document) =>
                    this.setState({ imageId: document.id, image: document })
                  }
                  placeholder="Find billede..."
                />
              </div>
              <UploadButton
                id="imageUpload"
                onlyImages
                onSelected={this.onUploadImage}
              >
                Upload billede
              </UploadButton>
            </div>
          )}
        </FormGroup>
        <div className="bottomPadding">
          <LexButton onClick={this.onAddThingy}>Tilføj ting</LexButton>
        </div>
        {!!thingies
          ? thingies.map((thingy, index) => (
              <div>
                <div className="largeText">
                  Ting# {index + 1}&nbsp;&nbsp;&nbsp;
                  <Icon
                    role="button"
                    onClick={() => this.onRemoveThingy(index)}
                    glyph="icon-fontello-trash-1"
                  />
                </div>
                <FormGroup>
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal
                      entity="SidebarRightContaineruseruserName"
                      defaultValue={"Navn"}
                    />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    className="borderFormControlfocus"
                    value={thingy.name}
                    onChange={(event) =>
                      this.onUpdateThingyProperty(
                        index,
                        "name",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <AxoLocal
                      entity="Enhedspris6"
                      defaultValue={"Beskrivelse"}
                    />
                  </ControlLabel>
                  <FormControl
                    type="text"
                    className="borderFormControlfocus"
                    value={thingy.description}
                    onChange={(event) =>
                      this.onUpdateThingyProperty(
                        index,
                        "description",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
              </div>
            ))
          : null}
        {showValidationError ? (
          <div className="axored mediumText">
            Der skal indtastes en tekst og en lang text.
          </div>
        ) : null}
        <ButtonToolbar>
          <AsyncButton hideOkIcon onClick={this.onSubmit}>
            Godkend
          </AsyncButton>
          <LexButton onClick={this.onCancel}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </Form>
    );
  }
}
