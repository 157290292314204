import React from "react";

import { DataStore, DataActions } from "../../../services/AxoServices";

import { withData, withRTKData } from "../../../utilities/LexUtilities";

import AccountingJournalView from "./AccountingJournalView";

class AccountingJournalContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmDeleteId: 0,
      confirmCopyId: 0,
      confirmEditId: 0,
      confirmDeleteSelected: false,
      confirmDeleteJournal: false,
      confirmCopyJournal: false,
      showConfirmationMessage: false,
      selectedJournalId: 0,
    };
  }

  componentDidMount() {
    DataStore.fetchClientPlan();
    DataStore.fetchFiscalYears().then(() => {
      if (!!DataStore.getStore().selectedFiscalYearId) {
        DataStore.fetchPostingEntries();
        DataStore.fetchPostingJournals();
      }
    });
  }

  handleSelectedClient = (selectedClient) => {
    DataStore.setState({
      fiscalYears: null,
    });

    DataStore.ssetSelectedContact(selectedClient);

    DataStore.fetchClientPlan();
    DataStore.fetchFiscalYears().then(() => {
      if (!!DataStore.getStore().selectedFiscalYearId) {
        DataStore.fetchPostingEntries();
        DataStore.fetchPostingJournals();
      }
    });
  };

  onSelectFiscalYear = (fiscalYearId) => {
    this.setState({ selectedJournalId: 0 });

    DataStore.selectFiscalYear(fiscalYearId);
    DataStore.fetchPostingJournals();
    DataStore.fetchPostingEntries();
  };

  onSelectStartDate = (selectedStartDate) => {
    this.setState({ selectedJournalId: 0 });

    DataStore.updateProperty("selectedPostingStartDate", selectedStartDate);
    DataStore.fetchPostingJournals();
    DataStore.fetchPostingEntries();
  };

  onSelectEndDate = (selectedEndDate) => {
    this.setState({ selectedJournalId: 0 });

    DataStore.updateProperty("selectedPostingEndDate", selectedEndDate);
    DataStore.fetchPostingJournals();
    DataStore.fetchPostingEntries();
  };

  queryEntries = async () => {
    try {
      let response = await DataStore.fetchPostingEntriesByPostingDates();
      return !!response;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  onDelete = (id) => {
    this.setState({ confirmDeleteId: id });
  };

  confirmDelete = async (entry) => {
    let result = await this.createDeletionPosting(entry);
    if (result) {
      this.showConfirmationMessage();
    }
    return result;
  };

  onDeleteSelected = () => {
    this.setState({ confirmDeleteSelected: true });
  };

  onCancelDeleteSelected = () => {
    this.setState({ confirmDeleteSelected: false });
  };

  onConfirmDeleteSelected = async (selectedEntries = new Set()) => {
    let { entries } = this.props;

    let selection = entries.filter((e) => selectedEntries.has(e.id));
    return this.doDeleteEntries(selection);
  };

  onDeleteSelectedJournal = () => {
    this.setState({ confirmDeleteJournal: true });
  };

  onCancelDeleteSelectedJournal = () => {
    this.setState({ confirmDeleteJournal: false });
  };

  onConfirmDeleteSelectedJournal = async (journalEntries) => {
    return this.doDeleteEntries(journalEntries);
  };

  doDeleteEntries = async (entries) => {
    let promises = [];
    for (let entry of entries) {
      //Process entries sequentially
      promises.push(this.createDeletionPosting(entry));
    }

    let results = await Promise.all(promises);
    let success = results.find((r) => !!r);

    if (success) {
      this.showConfirmationMessage();
    }
    return success;
  };

  createDeletionPosting = async (entry) => {
    let newEntry = {
      ...entry,
      taxAccountId: entry.taxAccountId || 0, //0 Means to not assign a tax account to the new account
      isIncome: !entry.isIncome,
      status: "Approved",
      postingDate: null,
    };
    delete newEntry.id;

    let result = await this.doCreateEntry(newEntry);
    return result;
  };

  onCopyJournal = () => {
    this.setState({ confirmCopyJournal: true });
  };

  onCancelCopyJournal = () => {
    this.setState({ confirmCopyJournal: false });
  };

  onConfirmCopyJournal = async (journalEntries) => {
    let promises = [];
    for (let entry of journalEntries) {
      //Process entries sequentially
      promises.push(
        this.doCopy({ entry, resetNumber: true, resetTaxAccount: false })
      );
    }

    let results = await Promise.all(promises);
    let success = results.find((r) => !!r);

    if (success) {
      this.showConfirmationMessage();
    }
    return success;
  };

  onCopy = (id) => {
    this.setState({ confirmCopyId: id });
  };

  onConfirmCopy = async (entry) => {
    let result = await this.doCopy({
      entry,
      resetNumber: true,
      resetTaxAccount: false,
    });
    if (result) {
      this.showConfirmationMessage();
    }
    return result;
  };

  doCopy = async ({ entry, resetNumber, resetTaxAccount }) => {
    let newEntry = {
      ...entry,
      taxAccountId: resetTaxAccount ? null : entry.taxAccountId || 0, //0 Means to not assign a tax account to the new account
      status: "Approved",
      postingDate: null,
    };
    delete newEntry.id;
    if (resetNumber) {
      delete newEntry.receiptNumber;
    }

    let result = await this.doCreateEntry(newEntry);
    return result;
  };

  onEdit = (id) => {
    this.setState({ confirmEditId: id });
  };

  onConfirmEdit = async (oldEntry, newEntry) => {
    let result = await this.confirmDelete(oldEntry);
    if (!result) {
      return false;
    }

    result = await this.doCopy({
      entry: newEntry,
      resetNumber: false,
      resetTaxAccount: oldEntry.financeAccountId !== newEntry.financeAccountId, //Recalculate taxes when account changes
    });
    if (result) {
      this.showConfirmationMessage();
    }
    return result;
  };

  doCreateEntry = async (entry) => {
    try {
      let response = await DataActions.createBookkeepingDraftEntry(entry);
      let newEntry = await response.json();
      DataStore.setState({
        importantEntryIds: new Set([
          ...DataStore.getStore().importantEntryIds,
          newEntry.id,
        ]),
      });
      return response.ok;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  showConfirmationMessage = () => {
    this.setState({
      confirmDeleteId: 0,
      confirmCopyId: 0,
      confirmEditId: 0,
      confirmDeleteSelected: false,
      confirmDeleteJournal: false,
      confirmCopyJournal: false,
      showConfirmationMessage: true,
    });
    setTimeout(() => this.setState({ showConfirmationMessage: false }), 3000);
  };

  onSelectJournal = (selectedJournalId) => {
    this.setState({ selectedJournalId });
  };

  render() {
    let { entries, fiscalYears, selectedFiscalYearId } = this.props;
    let { selectedJournalId } = this.state;
    let filteredEntries = entries;
    if (!!selectedJournalId) {
      filteredEntries = filteredEntries.filter(
        (e) => e.postingJournalId === selectedJournalId
      );
    }

    let selectedFiscalYear = !!fiscalYears
      ? fiscalYears.find((f) => f.id === selectedFiscalYearId)
      : null;

    return (
      <AccountingJournalView
        {...this.props}
        {...this.state}
        entries={filteredEntries}
        selectedFiscalYear={selectedFiscalYear}
        handleSelectedClient={this.handleSelectedClient}
        onSelectFiscalYear={this.onSelectFiscalYear}
        onToggleCards={() => {}}
        onSelectStartDate={this.onSelectStartDate}
        onSelectEndDate={this.onSelectEndDate}
        queryEntries={this.queryEntries}
        onDelete={this.onDelete}
        confirmDelete={this.confirmDelete}
        onCopy={this.onCopy}
        onConfirmCopy={this.onConfirmCopy}
        onEdit={this.onEdit}
        onConfirmEdit={this.onConfirmEdit}
        onDeleteSelected={this.onDeleteSelected}
        onCancelDeleteSelected={this.onCancelDeleteSelected}
        onConfirmDeleteSelected={this.onConfirmDeleteSelected}
        onSelectJournal={this.onSelectJournal}
        onDeleteSelectedJournal={this.onDeleteSelectedJournal}
        onCancelDeleteSelectedJournal={this.onCancelDeleteSelectedJournal}
        onConfirmDeleteSelectedJournal={this.onConfirmDeleteSelectedJournal}
        onCopyJournal={this.onCopyJournal}
        onCancelCopyJournal={this.onCancelCopyJournal}
        onConfirmCopyJournal={this.onConfirmCopyJournal}
      />
    );
  }
}

const mapStoreToProps = (store) => {
  let financeAccountMap = {};
  store.clientPlan.accounts.forEach((element) => {
    financeAccountMap[element.id] = element;
  });

  let taxSpecificationMap = {};
  store.clientPlan.taxSpecifications.forEach((element) => {
    taxSpecificationMap[element.id] = element;
  });

  return {
    selectedPlan: store.clientPlan || { accounts: [] },
    entries: store.postingEntries.filter((p) => p.status === "Posted"), //Filter in case the list is still cached with the draft included
    postingJournals: store.postingJournals,
    financeAccountMap,
    taxSpecificationMap,
    fiscalYears: store.fiscalYears,
    selectedFiscalYearId: store.selectedFiscalYearId,
    selectedStartDate: store.selectedPostingStartDate,
    selectedEndDate: store.selectedPostingEndDate,
  };
};

export default withRTKData(
  withData(AccountingJournalContainer, mapStoreToProps)
);
