import React from "react";
import * as $ from "jquery";
import Conditional from "../../utilities/Conditional";
import Select from "react-select";
import moment from "moment";

import {
  Col,
  Form,
  Button,
  FormGroup,
  InputGroup,
  FormControl,
  ButtonToolbar,
  ControlLabel,
} from "react-bootstrap";

import { ApiService } from "../../services/AxoServices";

import {
  Icon,
  AxoLocal,
  TextAreaWrapper,
  CountryDropdown,
  AxoDateTime,
} from "../../utilities/LexUtilities";

const MemberType = {
  NONE: 0,
  SAUNA: 1,
  BEACH: 2,
};

export default class CreateContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userName: props.toUserName,
      firstName: "",
      lastName: props.showGenderField ? "M" : "",
      memberType: MemberType.NONE,
      country: "DK",
      address: "",
      postalCode: "",
      city: "",
      email: "",
      phone: "",
      message: "",
      birthDate: moment.utc(),
      fileAttachments: [],
      showWarning: false,
      showSuccessMessage: false,
      showFailureMessage: false,
    };
  }

  onModelPropertyChange = (propertyName, event) => {
    var model = Object.assign({}, this.state);
    model[propertyName] = event.target.value;
    this.setState(model);
  };

  onPropertyChange = (propertyName, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [propertyName]: value,
    }));
  };

  onMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  onSendMessage = async () => {
    let { singleName, showMemberTypeField } = this.props;

    let {
      firstName,
      lastName,
      email,
      address,
      postalCode,
      message,
      birthDate,
      fileAttachments,
      memberType,
      phone,
    } = this.state;

    if (
      !firstName ||
      (!lastName && !singleName) ||
      (!memberType && showMemberTypeField) ||
      !address ||
      !postalCode ||
      !email ||
      !phone
    ) {
      this.setState({ showWarning: true });
      setTimeout(() => {
        this.setState({ showWarning: false });
      }, 5000);

      return;
    }

    let { toEmail } = this.props;

    this.setState({ sending: true });

    try {
      let response = await ApiService.createWaitingContact({
        ...this.state,
        eMail: email,
        description: `${
          !!memberType
            ? `${memberType === MemberType.BEACH ? "Strandbader. " : "Sauna. "}`
            : ""
        }${message}`,
        birthDate: birthDate.format(),
      });

      if (!response.ok) {
        throw new Error("Contact could not be created.");
      }

      let subject = `Venteliste tilmelding fra (${
        email || "Email adresse ikke angivet"
      }) - ${firstName} ${lastName}`;
      let body = `Du har modtaget en tilmelding${
        !!memberType
          ? `${
              memberType === MemberType.BEACH
                ? " som strandbader"
                : " som saunamedlem"
            }`
          : ""
      } fra ${firstName}${!!lastName ? ` ${lastName}` : ""}.
      Denne email kan IKKE besvares. 
      For at besvare beskeden, så skriv til (${
        email || "Email adresse ikke angivet"
      }).

      ${message || subject}`;

      response = await ApiService.sendContactMail({
        to: toEmail || "info@axolex.com",
        subject,
        body,
        fileAttachments,
      });
      if (!response.ok) {
        throw new Error("Email could not be send");
      }

      this.setState({
        showSuccessMessage: true,
        sending: false,
      });
    } catch (reason) {
      console.log(reason);
      this.setState({
        sending: false,
        showFailureMessage: true,
      });
      setTimeout(() => {
        this.setState({ showFailureMessage: false });
      }, 5000);
    }
  };

  onReset = () => {
    this.setState({
      userName: this.props.toUserName,
      firstName: "",
      lastName: "",
      country: "",
      address: "",
      postalCode: "",
      email: "",
      message: "",
      fileAttachments: [],
      sending: false,
    });
  };

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  getEmailValidationState = (email) => {
    if (email.length === 0) {
      return null;
    }
    return this.validateEmail(email) ? "success" : "error";
  };

  selectCountry = (val) => {
    var model = Object.assign({}, this.state);
    model.country = val;
    this.setState(model);
  };

  renderFileButtonContent = () => {
    if (this.state.showFileMaxSizeWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ContactFormShowMaxSizeWarning"
            defaultValue={"Max 50 mb"}
          />
        </div>
      );
    }
    return (
      <span>
        <Icon glyph="icon-fontello-upload-4" />
        &nbsp; &nbsp;
        <AxoLocal
          entity="ClientDocumentTableUploadFiles"
          defaultValue={"Upload filer"}
        />
      </span>
    );
  };

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  saveBase64File = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var base64string = reader.result.substring(
        reader.result.indexOf("base64") + 7
      );
      this.setState((prevState) => ({
        fileAttachments: [
          ...prevState.fileAttachments,
          {
            name: file.name,
            size: file.size,
            content: base64string,
          },
        ],
      }));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  onUploadFiles = () => {
    var component = this;
    $("#documentUpload").trigger("click");
    $("#documentUpload").on("change", function () {
      if (this.files.length === 0) {
        return;
      }
      try {
        var file = this.files[0];
        if (file.size / Math.pow(1024, 2) > 5) {
          component.setState({ showFileMaxSizeWarning: true });
          setTimeout(() => {
            component.setState({ showFileMaxSizeWarning: false });
          }, 3000);
          return;
        }
        component.saveBase64File(file);
      } finally {
        $("#documentUpload").off("change");
        this.value = ""; //onChange handler should be triggered when uploading the same file twice.
      }
    });
  };

  onRemoveFile = (fileName) => {
    var newFiles = this.state.fileAttachments.filter(
      (f) => f.name !== fileName
    );
    this.setState({ fileAttachments: newFiles });
  };

  renderFileNodes = () => {
    return this.state.fileAttachments.map((file) => {
      return (
        <h5 key={file.name}>
          &nbsp;&nbsp;{file.name} ({(file.size / 1024).toFixed(2)} kb)
          &nbsp;&nbsp;
          <Icon
            role="button"
            onClick={this.onRemoveFile.bind(this, file.name)}
            glyph="icon-fontello-scissors axored"
          />
        </h5>
      );
    });
  };

  MessageView = (props) => {
    return (
      <div className="text-center axoblue largeText topPadding standardMaxWidth">
        <AxoLocal
          entity="ContactFormSuccessMessage"
          defaultValue={"Tak for din henvendelse. Beskeden blev sendt"}
        />
      </div>
    );
  };

  //Props
  //firstNameLabel
  //lastNameLabel
  //countryLabel
  //emailLabel
  //messageLabel
  //sendLabel
  render() {
    let { externalStyle } = this.props;

    var state = this.state;

    var {
      memberType,
      sending,
      showSuccessMessage,
      showFailureMessage,
      showWarning,
    } = state;

    let {
      hideCountry,
      singleName,
      showGenderField,
      showMemberTypeField,
      showCityField,
    } = this.props;

    let firstNameLabel = singleName ? (
      <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />
    ) : (
      <AxoLocal entity="placeholderfirstName" defaultValue={"Fornavn"} />
    );

    let genderOptions = [
      { value: "M", label: "Mand" },
      { value: "K", label: "Kvinde" },
      { value: "Andet", label: "Andet" },
    ];

    let currentGender = genderOptions.find((g) => g.value === state.lastName);

    let memberTypeOptions = [
      { value: 0, label: "Vælg type" },
      { value: 1, label: "Sauna" },
      { value: 2, label: "Strandbader" },
    ];

    let currentMemberType = memberTypeOptions.find(
      (g) => g.value === memberType
    );

    let isValidEmail = this.validateEmail(state.email);

    return (
      <Col
        xs={12}
        className="nopadding topPadding bottomPadding"
        style={externalStyle}
      >
        <Form>
          <FormGroup controlId="firstName">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              {this.props.firstNameLabel || firstNameLabel}
            </ControlLabel>
            <InputGroup bsSize="large">
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-user-outline" />
              </InputGroup.Addon>
              <FormControl
                type="text"
                className="borderFormControlfocus"
                value={state.firstName}
                onChange={this.onModelPropertyChange.bind(this, "firstName")}
                // entity={ singleName ? 'axoAccounting7' : 'placeholderfirstName' }
              />
            </InputGroup>
          </FormGroup>
          {!singleName ? (
            <FormGroup controlId="lastName">
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                {this.props.lastNameLabel || (
                  <AxoLocal
                    entity="placeholderlastName"
                    defaultValue={"Efternavn"}
                  />
                )}
              </ControlLabel>
              <InputGroup bsSize="large">
                <InputGroup.Addon>
                  <Icon glyph="icon-fontello-user-7" />
                </InputGroup.Addon>
                <FormControl
                  type="text"
                  className="borderFormControlfocus"
                  value={state.lastName}
                  onChange={this.onModelPropertyChange.bind(this, "lastName")}
                  // entity='placeholderlastName'
                />
              </InputGroup>
            </FormGroup>
          ) : null}
          {showGenderField ? (
            <FormGroup controlId="gender" className="onTopper">
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="axoidcode169" defaultValue={"Køn"} />
              </ControlLabel>
              <InputGroup bsSize="large">
                <InputGroup.Addon>
                  <Icon glyph="icon-fontello-user-7" />
                </InputGroup.Addon>
                <Select
                  name="select"
                  className="onTop"
                  menuPlacement="auto"
                  value={currentGender}
                  onChange={(selection) => {
                    if (!!selection) {
                      this.onPropertyChange("lastName", selection.value);
                    }
                  }}
                  noOptionsMessage={() => ""}
                  options={genderOptions}
                />
              </InputGroup>
            </FormGroup>
          ) : null}
          {showMemberTypeField ? (
            <FormGroup controlId="gender">
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                Type
              </ControlLabel>
              <InputGroup bsSize="large">
                <InputGroup.Addon>
                  <Icon glyph="icon-fontello-user-7" />
                </InputGroup.Addon>
                <Select
                  name="select"
                  className="onTop"
                  menuPlacement="auto"
                  value={currentMemberType}
                  onChange={(selection) => {
                    if (!!selection) {
                      this.onPropertyChange("memberType", selection.value);
                    }
                  }}
                  noOptionsMessage={() => ""}
                  options={memberTypeOptions}
                />
              </InputGroup>
            </FormGroup>
          ) : null}
          {!hideCountry ? (
            <FormGroup controlId="country">
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                {this.props.countryLabel || (
                  <AxoLocal entity="placeholdercountry" defaultValue={"Land"} />
                )}
              </ControlLabel>
              <InputGroup bsSize="large">
                <InputGroup.Addon>
                  <Icon glyph="icon-fontello-globe-6" />
                </InputGroup.Addon>
                <CountryDropdown
                  value={state.country}
                  valueType="short"
                  large
                  onChange={(val) => this.selectCountry(val)}
                />
              </InputGroup>
            </FormGroup>
          ) : null}
          <FormGroup controlId="address">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
            </ControlLabel>
            <InputGroup bsSize="large">
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-location-4" />
              </InputGroup.Addon>
              <FormControl
                type="text"
                className="borderFormControlfocus"
                value={state.address}
                onChange={this.onModelPropertyChange.bind(this, "address")}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup controlId="postalCode">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="CaseEditFormpostalCode"
                defaultValue={"Postnummer"}
              />
            </ControlLabel>
            <InputGroup bsSize="large">
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-direction-1" />
              </InputGroup.Addon>
              <FormControl
                type="text"
                className="borderFormControlfocus"
                value={state.postalCode}
                onChange={this.onModelPropertyChange.bind(this, "postalCode")}
              />
            </InputGroup>
          </FormGroup>
          {showCityField && (
            <FormGroup controlId="city">
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="CaseEditFormcity" defaultValue={"By"} />
              </ControlLabel>
              <InputGroup bsSize="large">
                <InputGroup.Addon>
                  <Icon glyph="icon-fontello-warehouse" />
                </InputGroup.Addon>
                <FormControl
                  type="text"
                  className="borderFormControlfocus"
                  value={state.city}
                  onChange={this.onModelPropertyChange.bind(this, "city")}
                />
              </InputGroup>
            </FormGroup>
          )}
          <FormGroup
            validationState={this.getEmailValidationState(state.email)}
            controlId="Email"
          >
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              {this.props.emailLabel || (
                <AxoLocal
                  entity="socialSidebarContainerEmail"
                  defaultValue={"Email"}
                />
              )}
            </ControlLabel>
            <InputGroup bsSize="large">
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-mail" />
              </InputGroup.Addon>
              <FormControl
                type="text"
                className="borderFormControlfocus"
                value={state.email}
                onChange={this.onModelPropertyChange.bind(this, "email")}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup controlId="phone">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="ContainerPhone" defaultValue={"Telefon"} />
            </ControlLabel>
            <InputGroup bsSize="large">
              <InputGroup.Addon>
                <Icon glyph="icon-fontello-phone" />
              </InputGroup.Addon>
              <FormControl
                type="text"
                className="borderFormControlfocus"
                value={state.phone}
                onChange={(event) => {
                  this.setState({
                    phone: event.target.value,
                    mobile: event.target.value,
                  });
                }}
              />
            </InputGroup>
          </FormGroup>
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal entity="axoidcode161" defaultValue={"Fødselsdag"} /> (Husk
            at vælge en dato)
          </ControlLabel>
          <div style={{ color: "black" }}>
            <AxoDateTime
              id="birthDate"
              utc
              style={{ color: "black" }}
              value={moment.utc(state.birthDate)}
              dateFormat={true}
              timeFormat={false}
              onChange={(selectedDate) => {
                this.setState({ selectedDate }, () =>
                  this.onPropertyChange("birthDate", selectedDate)
                );
              }}
            />
          </div>
          {this.props.attachFiles ? (
            <div className="flexbox-responsive">
              <Button
                disabled={this.state.sending}
                className="Lex-button-2"
                onClick={this.onUploadFiles}
                style={{
                  borderRadius: "0px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  width: "250px",
                  fontSize: "17px",
                  fontWeight: "normal",
                  height: "55px",
                }}
              >
                {this.renderFileButtonContent()}
              </Button>
              {this.renderFileNodes()}
              <input
                name="document"
                type="file"
                multiple
                id="documentUpload"
                style={{ display: "none" }}
              />
            </div>
          ) : null}
          <Conditional inline show={this.props.attachFiles}>
            <br />
          </Conditional>
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            {this.props.messageLabel || (
              <AxoLocal
                key="Besked"
                entity="ContactFormMessage"
                defaultValue={"Besked"}
              />
            )}
          </ControlLabel>
          <FormGroup controlId="message">
            <AxoLocal
              componentClass={TextAreaWrapper}
              onChange={this.onMessageChange}
              value={this.state.message}
              rows={5}
              componentAttribute="placeholder"
              entity="ContactFormMessagePlaceholder"
            />
          </FormGroup>
          {showWarning ? (
            <div className="axored largeText">
              Du skal udfylde alle felterne.
            </div>
          ) : null}
          {!showSuccessMessage && !showFailureMessage && (
            <div className="flexbox-center">
              <ButtonToolbar>
                <Button
                  className="Lex-button-2"
                  disabled={sending || !isValidEmail}
                  title={
                    !isValidEmail ? "Indtast en gyldig email adresse." : ""
                  }
                  style={{
                    borderRadius: "0px",
                    marginLeft: "0px",
                    marginRight: "0px",
                    width: "250px",
                    fontSize: "17px",
                    fontWeight: "normal",
                    height: "65px",
                  }}
                  onClick={this.onSendMessage}
                >
                  {this.props.sendLabel || (
                    <AxoLocal
                      entity="IndividualPackagetext180"
                      defaultValue={"Send besked"}
                    />
                  )}
                  {sending && (
                    <span>
                      &nbsp;
                      <img
                        alt=""
                        src="/imgs/app/loading.gif"
                        className="img-circle"
                        width="20"
                        height="20"
                      />
                    </span>
                  )}
                </Button>
                <Button
                  disabled={sending}
                  className="Lex-button-2"
                  style={{
                    borderRadius: "0px",
                    marginLeft: "0px",
                    marginRight: "0px",
                    width: "250px",
                    fontSize: "17px",
                    fontWeight: "normal",
                    height: "65px",
                  }}
                  onClick={this.onReset}
                >
                  {" "}
                  <AxoLocal
                    entity="SidebarRightContainerResetCalendarEvent"
                    defaultValue={"Nulstil"}
                  />
                </Button>
              </ButtonToolbar>
            </div>
          )}
          <Conditional show={showSuccessMessage}>
            <div
              className="text-center axogreen hugeText"
              style={{ paddingTop: "15px" }}
            >
              Tak for din tilmelding. Du vil høre fra os snarest.
              {/* <AxoLocal entity='ContactFormSuccessMessage' defaultValue={'Tak for din henvendelse. Beskeden er blevet sendt.'}/> */}
            </div>
          </Conditional>
          <Conditional show={showFailureMessage}>
            <div className="text-center axored largeText">
              Der skete en fejl. Kontakt Axolex support på support@axolex.com.
              {/* <AxoLocal entity='ContactFormFailureMessage' defaultValue={'Beskeden kunne ikke sendes. Prøv igen senere.'}/> */}
            </div>
          </Conditional>
        </Form>
      </Col>
    );
  }
}
