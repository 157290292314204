import React from "react";
import MarketingDashboardView from "./MarketingDashboardView";

import {
  ApiService,
  DataStore,
  RoutingService,
  LocalizationService,
} from "../services/AxoServices";

import {
  LoadingIndicator,
  Consumer,
  withRTKMessageData,
} from "../utilities/LexUtilities";

import { Route, Switch } from "react-router-dom";

import PresentationCreateContainer from "./Presentation/PresentationCreateContainer";
import AdminMainContainer from "./AdminMain/AdminMainContainer";
import AdminListContainer from "./AdminList/AdminListContainer";
import SinglePresentationContainer from "./Presentation/SinglePresentationContainer";
import MarketingSetupContainer from "./setup/MarketingSetupContainer";

class MarketingContainer extends React.PureComponent {
  componentDidUpdate(prevProps) {
    let { userSettings } = this.props;
    if (
      !!userSettings.locale &&
      userSettings.locale !== prevProps.userSettings.locale
    ) {
      //In case the context isn't ready, the language will be loaded later
      try {
        LocalizationService.changeLocale(userSettings.locale);
      } catch (error) {
        console.log(error);
      }
    }

    if (userSettings.locked) {
      this.props.history.push("/Lock");
    }
  }

  render() {
    return (
      <MarketingDashboardView
        messageMap={this.props.messageMap}
        unhandledFriendRequests={this.props.unhandledFriendRequests}
        userSettings={this.props.userSettings}
      >
        {this.props.children}
      </MarketingDashboardView>
    );
  }
}

class MarketingConsumer extends React.PureComponent {
  componentDidMount() {
    ApiService.checkLoginStatus().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.props.history.push("/Xlink/Account/Login");
      } else {
        this.InitializeData();
      }
    });

    RoutingService.setRootPath("/xlink");
    window.document.title = "XLink";
  }

  InitializeData = () => {
    DataStore.initializeDocuments();
    DataStore.fetchFriendRequests();
    DataStore.initializeUserSettings();
    DataStore.fetchGlobalSettings();
  };

  render() {
    let { match } = this.props;

    return (
      <Consumer>
        {(store) => {
          if (!store.loaded || !store.globalSettings.theme) {
            return <LoadingIndicator />;
          }
          let unhandledFriendRequests = store.friendRequests.filter(
            (f) => f.status === 0
          );
          return (
            <MarketingContainer
              {...this.props}
              unhandledFriendRequests={unhandledFriendRequests}
              userSettings={store.userSettings}
            >
              <Switch>
                <Route
                  exact
                  path={match.path}
                  component={PresentationCreateContainer}
                />
                <Route
                  path={match.path + "/adminmain"}
                  component={AdminMainContainer}
                />
                <Route
                  path={match.path + "/adminlist"}
                  component={AdminListContainer}
                />
                <Route
                  path={match.path + "/presentation/:id"}
                  component={SinglePresentationContainer}
                />
                <Route
                  path={match.path + "/setup"}
                  component={MarketingSetupContainer}
                />
              </Switch>
            </MarketingContainer>
          );
        }}
      </Consumer>
    );
  }
}

export default withRTKMessageData(MarketingConsumer);
