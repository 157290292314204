import React from "react";
import { RIESelect } from "./Riek";
import PropTypes from "prop-types";

export default class InlineSelect extends React.Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    change: PropTypes.func.isRequired,
    className: PropTypes.string,
    editStyle: PropTypes.object,
  };

  shouldComponentUpdate(nextProps, nextState) {
    //Ensures that the input retains focus when receiving new props, as long as the value has not changed
    if (nextProps.value === this.props.value) {
      return false;
    }
    return true;
  }

  render() {
    let { value, options, change } = this.props;
    let className = this.props.className || "editable";
    let editStyle = this.props.editStyle || { width: "100%" };

    return (
      <RIESelect
        value={value}
        className={className}
        options={options}
        change={change}
        propName="value"
        editProps={{ style: editStyle }}
      />
    );
  }
}
