import React from "react";
import PropTypes from "prop-types";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
} from "react-bootstrap";

import {
  LexButton,
  Flexbox,
  getText,
  AsyncButton,
  AxoLocal,
  AxoSelect,
} from "../../utilities/LexUtilities";

export default class AccountingEntryEditForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    entry: PropTypes.object,
    financeAccountArray: PropTypes.array,
  };

  constructor(props) {
    super(props);

    if (props.entry) {
      this.state = { ...props.entry };
    } else {
      this.state = this.getStartState();
    }
  }

  getStartState = () => {
    return {
      receiptNumber: 1,
      isIncome: false,
      description: "",
      vatNumber: "",
      financeAccountId: "",
      amount: 0,
      vat: 0,
      balanceFinanceAccountId: 0,
    };
  };

  onUpdateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  onCancel = () => {
    let { onCancel, startValue } = this.props;

    if (onCancel) {
      onCancel();
      return;
    }

    if (startValue) {
      this.setState(this.props.startValue);
    } else {
      this.setState(this.getStartState());
    }
  };

  updateAmountAndVat = (amount) => {
    this.setState({ amount });

    this.updateVat(amount);
  };

  updateVat = (amount) => {
    let { financeAccountMap, taxSpecificationMap } = this.props;

    if (!financeAccountMap || !taxSpecificationMap) {
      return;
    }

    let { financeAccountId } = this.state;

    var account = financeAccountMap[financeAccountId];
    if (!account) {
      return;
    }

    if (amount <= 0) {
      this.setState({ vat: 0 });
      return;
    }

    var taxSpec = taxSpecificationMap[account.taxSpecificationId];
    if (!taxSpec) {
      this.setState({ vat: 0 });
      return;
    }

    let vat = 0;
    let isReverseVat =
      taxSpec.taxType === "ForeignGoods" ||
      taxSpec.taxType === "ServiceReverseCharge";

    if (isReverseVat) {
      //Amount is without vat
      vat = (amount * taxSpec.taxPercentage) / 100;
    } else {
      vat = amount - amount / (1 + taxSpec.taxPercentage / 100);
    }

    let roundedVat = Math.round(vat * 100) / 100;
    this.setState({ vat: roundedVat });
  };

  render() {
    let { onSubmit, financeAccountArray } = this.props;

    let {
      receiptNumber,
      isIncome,
      description,
      financeAccountId,
      amount,
      vat,
      balanceFinanceAccountId,
    } = this.state;

    let standardAccounts = financeAccountArray.filter(
      (a) => a.type === "Standard"
    );
    let accountOptions = standardAccounts.map((f) => {
      return { value: f.id, label: f.number.toString() + " - " + f.name };
    });

    return (
      <Form className="standardMaxWidth">
        <Flexbox justified collapseXS>
          <FormGroup controlId="equityIntervalEnd" className="rightPadding">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="AccountingTabViewEntity41"
                defaultValue={"Kredit"}
              />
              /
              <AxoLocal
                entity="AccountingTabViewEntity42"
                defaultValue={"Debet"}
              />
            </ControlLabel>
            <FormControl
              componentClass="select"
              className="borderFormControlfocus"
              value={isIncome ? "credit" : "debit"}
              onChange={(event) =>
                this.onUpdateProperty(
                  "isIncome",
                  event.target.value === "credit"
                )
              }
            >
              <option value="credit">
                {getText("AccountingTabViewEntity41", "Kredit")}
              </option>
              <option value="debit">
                {getText("AccountingTabViewEntity42", "Debet")}
              </option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="equityIntervalEnd" className="rightPadding">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="axoidcode77" defaultValue={"Note"} />
            </ControlLabel>
            <FormControl
              type="text"
              className="borderFormControlfocus"
              value={description || ""}
              onChange={(event) =>
                this.onUpdateProperty("description", event.target.value)
              }
            />
          </FormGroup>
          <FormGroup controlId="equityIntervalEnd" className="rightPadding">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="AccountingTabViewEntity13"
                defaultValue={"Konto"}
              />
            </ControlLabel>
            <AxoSelect
              name="select"
              menuPlacement="auto"
              value={
                !!financeAccountId
                  ? accountOptions.find((o) => o.value === financeAccountId)
                  : { value: "", label: "" }
              }
              onChange={(selectedAccount) => {
                if (!!selectedAccount) {
                  this.onUpdateProperty(
                    "financeAccountId",
                    selectedAccount.value
                  );
                }
              }}
              noOptionsMessage={() => ""}
              options={[
                { value: "", label: getText("axoAccounting6f", "Vælg konto") },
                ...accountOptions,
              ]}
            />
          </FormGroup>
        </Flexbox>
        <Flexbox justified collapseXS>
          <FormGroup controlId="equityIntervalEnd" className="rightPadding">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal entity="InvoiceInvoicesum" defaultValue={"Beløb"} />
            </ControlLabel>
            <FormControl
              type="number"
              className="borderFormControlfocus"
              value={isNaN(amount) ? "" : amount}
              onChange={(event) =>
                this.updateAmountAndVat(event.target.valueAsNumber)
              }
            />
          </FormGroup>
          <FormGroup controlId="equityIntervalEnd" className="rightPadding">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="invoiPaymentattheSubtotalVAT"
                defaultValue={"Moms"}
              />
            </ControlLabel>
            <FormControl
              type="number"
              className="borderFormControlfocus"
              value={isNaN(vat) ? "" : vat}
              onChange={(event) =>
                this.onUpdateProperty("vat", event.target.valueAsNumber)
              }
            />
          </FormGroup>
          <FormGroup controlId="equityIntervalEnd" className="rightPadding">
            <ControlLabel
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              <AxoLocal
                entity="updategetAccountName1"
                defaultValue={"Modkonto"}
              />
            </ControlLabel>
            <AxoSelect
              name="select"
              menuPlacement="auto"
              value={
                !!balanceFinanceAccountId
                  ? accountOptions.find(
                      (o) => o.value === balanceFinanceAccountId
                    )
                  : { value: "", label: "" }
              }
              onChange={(selectedAccount) => {
                if (!!selectedAccount) {
                  this.onUpdateProperty(
                    "balanceFinanceAccountId",
                    selectedAccount.value
                  );
                }
              }}
              noOptionsMessage={() => ""}
              options={[
                { value: "", label: getText("axoAccounting6f", "Vælg konto") },
                ...accountOptions,
              ]}
            />
          </FormGroup>
        </Flexbox>
        <ButtonToolbar>
          <AsyncButton onClick={() => onSubmit(this.state)}>
            (#{receiptNumber}){" "}
            <AxoLocal
              entity="axoidcode78"
              defaultValue={"Opret retteposteringer"}
            />
          </AsyncButton>
          <LexButton onClick={this.onCancel}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </Form>
    );
  }
}
