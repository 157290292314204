import React from "react";
import { AxoLocal } from "../utilities/LexUtilities";

//TimeEntryService
export default {
  getTotalValue(timeEntry) {
    let total =
      timeEntry.units * timeEntry.rate * (1 + timeEntry.tax) +
      timeEntry.expenses -
      timeEntry.aConto;
    if (!!timeEntry.discountPercentage) {
      total = total * (1 - timeEntry.discountPercentage / 100);
    }
    return total;
  },

  getTotalValueWithoutAConto(timeEntry) {
    return (
      timeEntry.units * timeEntry.rate * (1 + timeEntry.tax) +
      timeEntry.expenses
    );
  },

  calculateInvoiceTotal(invoice) {
    return Math.round(
      invoice.timeEntries.reduce((sum, timeEntry) => {
        return sum + this.getTotalValue(timeEntry);
      }, 0)
    );
  },

  getTimeString(hours) {
    var timeString = Math.floor(hours) + ":";
    var minutes = Math.round((hours % 1) * 60);
    timeString += ("0" + minutes).slice(-2); //Enforces two digits
    return timeString;
  },

  getUnitTypeName(unitType) {
    switch (unitType) {
      case "Hours":
        return (
          <AxoLocal
            key="hours"
            entity="unitHoursShortName"
            defaultValue={"t"}
          />
        );
      case "Units":
        return (
          <AxoLocal
            key="units"
            entity="unitUnitsShortName"
            defaultValue={"stk."}
          />
        );
      default:
        return <span></span>;
    }
  },

  getUnitTypeFullName(unitType) {
    switch (unitType) {
      case "Hours":
        return (
          <AxoLocal
            key="hours"
            entity="unitHoursFullName"
            defaultValue={"Timer"}
          />
        );
      case "Units":
        return (
          <AxoLocal
            key="units"
            entity="unitUnitsFullName"
            defaultValue={"Antal"}
          />
        );
      default:
        return <span></span>;
    }
  },
};
