import React from "react";

import {
  ApiService,
  DataStore,
  AuthorizationService,
} from "../services/AxoServices";

import { withRouter, LoadingIndicator } from "../utilities/LexUtilities";

import ScreenSharingWatch from "./ScreenSharingWatch";

class ScreenSharingWatchContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userProfile: {},
    };

    this.name = "ScreenSharingWatchContainer";
  }

  componentDidMount() {
    ApiService.checkLoginStatus().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.props.history.push("/Users/Login");
        return;
      }
    });
    DataStore.subscribe(this.name, (store) => {
      let userType = AuthorizationService.getUserType(store.userProfile);

      if (store.userProfile.id && userType !== "Admin") {
        this.props.history.push("/Users/Login");
        return;
      }
      this.setState({
        userProfile: store.userProfile,
      });
    });
    DataStore.initializeUserProfile();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  render() {
    let { userProfile } = this.state;
    if (!userProfile.id) {
      return <LoadingIndicator />;
    }

    return <ScreenSharingWatch {...this.state} />;
  }
}

export default withRouter(ScreenSharingWatchContainer);
