import React from "react";
import PropTypes from "prop-types";
import { FormControl } from "react-bootstrap";

import { UserInfoService, RoutingService } from "../../services/AxoServices";

import {
  LexButton,
  Flexbox,
  FlexElement,
  AxoLocal,
} from "../../utilities/LexUtilities";

const signalR = require("@aspnet/signalr/dist/browser/signalr.js");

export default class ChatRoom extends React.PureComponent {
  static propTypes = {
    roomName: PropTypes.number.isRequired,
    userProfile: PropTypes.object.isRequired,
    allowReset: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      content: "",
      inputText: "",
    };
    this.hubConnection = null;
    this.chatText = null;
  }

  componentDidMount() {
    let hubRoute = RoutingService.getHostPath() + "/chathub";

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubRoute)
      .build();

    this.hubConnection.on("ReceiveContent", async (content) => {
      this.setState(
        {
          content,
        },
        () => {
          this.chatText.scrollTop = this.chatText.scrollHeight;
        }
      );
    });
    this.hubConnection.start().then(() => {
      this.hubConnection
        .invoke("JoinRoom", this.props.roomName)
        .catch((reason) => {
          console.error(reason);
        });
    });
  }

  componentWillUnmount() {
    this.hubConnection.stop();
    this.hubConnection = null;
  }

  onSubmit = (message) => {
    let { roomName, userProfile } = this.props;
    this.hubConnection
      .invoke(
        "SendChatMessage",
        roomName,
        UserInfoService.getDisplayName(userProfile),
        message
      )
      .catch((reason) => {
        console.error(reason);
      });
    this.setState({ inputText: "" });
  };

  onReset = () => {
    let { roomName } = this.props;

    this.hubConnection.invoke("ResetRoomContent", roomName);
  };

  render() {
    let { allowReset } = this.props;
    let { content, inputText } = this.state;

    return (
      <div>
        <div>
          <pre ref={(c) => (this.chatText = c)} style={{ height: "200px" }}>
            {content}
          </pre>
        </div>
        <Flexbox>
          <FlexElement flexGrow={1}>
            {/* Skriv her... */}
            <AxoLocal
              componentClass={FormControl}
              type="text"
              value={inputText}
              onChange={(event) =>
                this.setState({ inputText: event.target.value })
              }
              onKeyUp={(event) => {
                if (event.keyCode === 13) {
                  this.onSubmit(event.target.value);
                }
              }}
              componentAttribute="placeholder"
              entity="chatInputPlaceHolder"
            />
          </FlexElement>
          {allowReset ? (
            <LexButton onClick={this.onReset}>
              <AxoLocal entity="axoAccounting48" defaultValue="Ryd samtale" />
            </LexButton>
          ) : null}
        </Flexbox>
      </div>
    );
  }
}
