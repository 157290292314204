import React from "react";
import Select from "react-select";
import * as $ from "jquery";
import PropTypes from "prop-types";

import { ApiService, DataActions } from "../../services/AxoServices";

import {
  Icon,
  TextAreaWrapper,
  LexButton,
  DocumentSearchBox,
  AxoLocal,
} from "../../utilities/LexUtilities";

import {
  Grid,
  Row,
  Col,
  Form,
  Button,
  ButtonToolbar,
  ControlLabel,
  FormGroup,
  FormControl,
} from "react-bootstrap";

export default class CreateItemPriceTypeForm extends React.PureComponent {
  static propTypes = {
    documentMap: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      priceType: this.makeNewType(),
      showSuccessMessage: false,
      showFailureMessage: false,
    };
  }

  makeNewType = () => {
    return {
      name: "",
      description: "",
      FileInfoModelId: null,
      language: "dk",
      creationDate: new Date(),
    };
  };

  onReset = () => {
    this.setState({
      priceType: this.makeNewType(),
    });
  };

  onCreatePriceType = () => {
    let { priceType } = this.state;
    if (!priceType.name) {
      return;
    }
    DataActions.createItemPriceType(priceType)
      .then((response) => {
        if (response.ok) {
          this.setState({
            priceType: this.makeNewType(),
            showSuccessMessage: true,
          });
          setTimeout(() => {
            this.setState({ showSuccessMessage: false });
          }, 3000);
        } else {
          return Promise.reject("Could not create price type");
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ showFailureMessage: true });
        setTimeout(() => {
          this.setState({ showFailureMessage: false });
        }, 3000);
      });
  };

  onPropertyChange = (propertyName, value) => {
    let priceType = { ...this.state.priceType };
    priceType[propertyName] = value;
    this.setState({ priceType });
  };

  uploadPicture = () => {
    let component = this;
    let { priceType } = { ...this.state };

    $("#uploadImage").trigger("click");
    $("#uploadImage").on("change", function () {
      let file = this.files[0];
      if (!file.type.includes("image")) {
        return;
      }
      DataActions.uploadDocuments(this.files).then((response) => {
        if (response.ok) {
          response.json().then((addedFiles) => {
            priceType.fileInfoModelId = addedFiles[0].id;
            component.setState({ priceType });
          });
        }
      });
    });
  };

  getLanguageOptions = () => {
    return [
      { value: "dk", label: "Dansk" },
      { value: "enUS", label: "English - US" },
      { value: "ch", label: "中文" },
    ];
  };

  render() {
    let { documentMap } = this.props;
    let { priceType } = this.state;

    let languageOptions = this.getLanguageOptions();

    return (
      <Grid fluid>
        <Row>
          <Col xs={12} smOffset={1} sm={10} style={{ paddingTop: "20px" }}>
            <div className="text-center" style={{ display: "inline-block" }}>
              <h3>
                <AxoLocal entity="Enhedspris16" defaultValue=" Opret ny type" />
              </h3>
            </div>
            <Form>
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="Enhedspris2" defaultValue="Billede" />
              </ControlLabel>
              {!!priceType.fileInfoModelId ? (
                <div style={{ paddingBottom: "5px" }}>
                  <LexButton
                    onClick={() =>
                      this.onPropertyChange("fileInfoModelId", null)
                    }
                    style={{ paddingBottom: "5px" }}
                  >
                    <AxoLocal
                      entity="Enhedspris3"
                      defaultValue="Fjern billede"
                    />
                  </LexButton>
                  <img
                    alt=""
                    style={{
                      maxWidth: "150px",
                      height: "auto",
                      display: "block",
                    }}
                    src={ApiService.getFileInlinePath(
                      priceType.fileInfoModelId
                    )}
                  />
                </div>
              ) : null}
              <div className="flexbox-responsive">
                <div>
                  <AxoLocal
                    componentClass={DocumentSearchBox}
                    documentMap={documentMap}
                    handleSelectedDocument={(document) =>
                      this.onPropertyChange("fileInfoModelId", document.id)
                    }
                    onlyImages
                    clearOnSelect
                    componentAttribute="placeholder"
                    entity="placeholderSelectexistinglogo"
                  />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div>
                  <LexButton onClick={this.uploadPicture}>
                    <Icon glyph="icon-fontello-desktop" />
                    &nbsp;
                    <AxoLocal
                      entity="Enhedspris4"
                      defaultValue="Upload billede fra PC"
                    />
                  </LexButton>
                </div>
              </div>
              <input
                name="image"
                type="file"
                id="uploadImage"
                accept="image/*"
                style={{ display: "none" }}
              />
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="Enhedspris5" defaultValue="Navn" />
                </ControlLabel>
                <FormControl
                  type="text"
                  value={priceType.name}
                  onChange={(event) =>
                    this.onPropertyChange("name", event.target.value)
                  }
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="Enhedspris6" defaultValue="Beskrivelse" />
                </ControlLabel>
                <TextAreaWrapper
                  value={priceType.description}
                  onChange={(event) =>
                    this.onPropertyChange("description", event.target.value)
                  }
                  rows={2}
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="Enhedspris23" defaultValue="Sprog" />
                </ControlLabel>
                <Select
                  name="form-field-name"
                  menuPlacement="auto"
                  value={languageOptions.find(
                    (o) => o.value === priceType.language
                  )}
                  onChange={(selection, arg) => {
                    this.onPropertyChange(
                      "language",
                      !!selection ? selection.value : "dk"
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={languageOptions}
                />
              </FormGroup>
            </Form>
            <div style={{ paddingBottom: "15px" }}>
              <div className="text-center" style={{ paddingTop: "10px" }}>
                <ButtonToolbar style={{ display: "inline-block" }}>
                  <Button
                    className="Lex-button-2 "
                    onClick={this.onCreatePriceType}
                  >
                    <AxoLocal entity="ADDViewCreate" defaultValue="Opret" />
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button className="Lex-button-2 " onClick={this.onReset}>
                    <AxoLocal entity="AdminTabView16" defaultValue="Nulstil" />
                  </Button>
                </ButtonToolbar>
              </div>
              {this.state.showSuccessMessage ? (
                <div className="text-center axoblue">
                  <h3>
                    <AxoLocal
                      entity="Enhedspris24"
                      defaultValue="Typen blev oprettet "
                    />
                  </h3>
                </div>
              ) : null}
              {this.state.showFailureMessage ? (
                <div className="text-center axored">
                  <Icon glyph="icon-fontello-info-3" />
                  &nbsp;&nbsp;
                  <h3>
                    <AxoLocal
                      entity="AdminTabView18"
                      defaultValue="Der skete en fejl. Prøv igen senere."
                    />
                  </h3>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
