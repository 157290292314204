import React from "react";
import PropTypes from "prop-types";
import CreateItemPriceForm from "./CreateItemPriceForm";
import CreateItemPriceTypeForm from "./CreateItemPriceTypeForm";

import { Panel, PanelGroup } from "react-bootstrap";

import { AccordionUtils, AxoLocal } from "../../utilities/LexUtilities";

export default class ItemPriceFormSelector extends React.PureComponent {
  static propTypes = {
    documentMap: PropTypes.object.isRequired,
    itemPriceTypes: PropTypes.array.isRequired,
  };

  render() {
    return (
      <PanelGroup
        id="ITsubscriptionh"
        accordion
        className="Lex-Accordion"
        defaultActiveKey="0"
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel className="backgroundlineargradient3" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              {AccordionUtils.renderAccordionHeader(
                <span>
                  <AxoLocal entity="Enhedspris31" defaultValue="Opret type" />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <CreateItemPriceTypeForm {...this.props} />
          </Panel.Body>
        </Panel>
        <Panel className="backgroundlineargradient3" eventKey="0">
          <Panel.Heading>
            <Panel.Title toggle>
              {AccordionUtils.renderAccordionHeader(
                <span>
                  <AxoLocal entity="Enhedspris32" defaultValue=" Opret pris" />
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <CreateItemPriceForm {...this.props} />
          </Panel.Body>
        </Panel>
      </PanelGroup>

      //   <Accordion className = 'Lex-Accordion caseeditinline ' defaultActiveKey='0' style={{ marginTop: '10px'}}>
      //   <Panel header={AccordionUtils.renderAccordionHeader(<span>
      //     <AxoLocal entity='Enhedspris31' defaultValue='Opret type '/>
      //     </span>)} eventKey="1">
      //     <CreateItemPriceTypeForm
      //       {...this.props}
      //     />
      //   </Panel>
      //   <Panel header={AccordionUtils.renderAccordionHeader(<span>
      //     <AxoLocal entity='Enhedspris32' defaultValue=' Opret pris'/>
      //     </span>)} eventKey="2">
      //     <CreateItemPriceForm
      //         {...this.props}
      //       />
      //   </Panel>
      // </Accordion>
    );
  }
}
