import React from "react";
import { withRouter } from "react-router-dom";
import { Table, Column, Cell } from "fixed-data-table-2";
import Select from "react-select";
import * as $ from "jquery";
import { Row, Grid, FormControl, FormGroup, InputGroup } from "react-bootstrap";

import { DataActions, ModalService } from "../../services/AxoServices";

import {
  AxoCheckbox,
  LexButton,
  Icon,
  FileViewerModal,
  TableBase,
  SortHeaderCell,
  DataListWrapper,
  ctx,
  AxoLocal,
  InlineEdit,
  Dimensions,
  getText,
  InlineNumberEdit,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  planId: {
    width: 100,
    shown: true,
  },
  name: {
    width: 125,
    shown: true,
  },
  type: {
    width: 200,
    shown: true,
  },
  statement: {
    width: 125,
    shown: true,
  },
  currency: {
    width: 100,
    shown: true,
  },
  amount: {
    width: 180,
    shown: true,
  },
  costPerUnit: {
    width: 180,
    shown: true,
  },
  interval: {
    width: 135,
    shown: true,
  },
  trialPeriod: {
    width: 100,
    shown: true,
  },
  storage: {
    width: 125,
    shown: true,
  },
  userGroupSize: {
    width: 200,
    shown: true,
  },
  freeUnitsPerInterval: {
    width: 200,
    shown: true,
  },
  subscriberCount: {
    width: 125,
    shown: true,
  },
  picture: {
    width: 100,
    shown: true,
  },
  targetUserName: {
    width: 85,
    shown: true,
  },
  hidden: {
    width: 90,
    shown: true,
  },
  actions: {
    width: 90,
    shown: true,
  },
};

class SubscriptionPlanView extends TableBase {
  constructor(props) {
    super(props);
    this.name = "SubscriptionPlanView";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = this.generateDefaultSortIndexes(
      props.subscriptionPlans
    );
    var tableEntries = this.copyItems(props.subscriptionPlans);
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 250,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      showCurrency: "",
    };
  }

  copyItems = (subscriptionPlans) => {
    return subscriptionPlans.map((c) => Object.assign({}, c));
  };

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.copyItems(nextProps.subscriptionPlans);
    let filteredEntries = this.getFilteredEntries(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  onSearch = (event) => {
    this.setState(
      {
        searchText: event.target.value,
      },
      () => {
        this.updateFilteredEntries();
      }
    );
  };

  updateFilteredEntries = () => {
    var entries = this.getFilteredEntries(
      this.state.tableEntries,
      this.state.searchText
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    if (!searchText && !this.state.showCurrency) {
      return entries;
    }
    var filteredEntries = entries;
    if (this.state.showCurrency) {
      filteredEntries = filteredEntries.filter(
        (entry) =>
          entry.currency.toLowerCase() === this.state.showCurrency.toLowerCase()
      );
    }
    return filteredEntries.filter((c) => {
      return (c.planId + " " + c.name)
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  };

  getPeriodEntity = (period) => {
    switch (period) {
      case 0:
        return (
          <span>
            {" "}
            <AxoLocal key="day" entity="AdminTabView9" defaultValue="Dag" />
          </span>
        );
      case 1:
        return (
          <span>
            <AxoLocal key="week" entity="AdminTabView10" defaultValue="Uge" />
          </span>
        );
      case 2:
        return (
          <span>
            <AxoLocal
              key="month"
              entity="AdminTabView11"
              defaultValue="Måned"
            />
          </span>
        );
      case 3:
        return (
          <span>
            <AxoLocal key="year" entity="AdminTabView12" defaultValue="År" />
          </span>
        );
      default:
        return <span></span>;
    }
  };

  onDeletePlan = (id) => {
    var confirmMessage = (
      <div>
        <div>
          <AxoLocal
            entity="AdminTabView19"
            defaultValue="Dette vil slette planen permanent."
          />
        </div>
        <div>
          <AxoLocal
            entity="AdminTabView20"
            defaultValue="Eksisterende abonnenter vil fortsætte med at betale abonnement indtil de skifter plan."
          />{" "}
        </div>
        <div>
          <AxoLocal
            entity="AdminTabView21"
            defaultValue="Er du sikker på at du ønsker at fortsætte?"
          />
        </div>
      </div>
    );
    ModalService.openConfirmModal(confirmMessage, (value) => {
      if (value) {
        DataActions.deleteSubscriptionPlan(id);
      }
    });
  };

  onChangePlanProperty = (planId, propertyName, input) => {
    this.onChangePlanPropertyValue(planId, propertyName, input.value);
  };

  onChangePlanPropertyValue = (planId, propertyName, value) => {
    var { sortedDataList } = this.state;

    var index = sortedDataList._data.findIndex((c) => c.planId === planId);
    if (index === -1) {
      return;
    }

    var plan = { ...sortedDataList._data[index] };
    plan[propertyName] = value;
    var newList = new DataListWrapper(
      sortedDataList._indexMap,
      sortedDataList._data
    );
    var newArray = newList._data.slice();
    newArray[index] = plan;
    newList._data = newArray;
    this.setState({ sortedDataList: newList }); //Optimistic updating

    DataActions.updateSubscriptionPlan(plan);
  };

  onCurrencySelect = (selectedCurrency) => {
    if (!selectedCurrency) {
      return;
    }
    this.setState({ showCurrency: selectedCurrency.value }, () => {
      this.updateFilteredEntries();
    });
  };

  renderCurrencySelect = (userProfile) => {
    let currency = this.state.showCurrency || "";

    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "150px",
          zIndex: 1000,
        }}
      >
        <Select
          name="form-field-name"
          menuPlacement="auto"
          value={{
            value: currency.toUpperCase(),
            label: currency.toUpperCase(),
          }}
          onChange={this.onCurrencySelect}
          noOptionsMessage={() => ""}
          options={[
            { value: "", label: ctx.getSync("AdminTabView24") }, //<AxoLocal entity='AdminTabView24' defaultValue='Valuta'/> },
            { value: "DKK", label: "DKK" },
            { value: "USD", label: "USD" },
            { value: "GBP", label: "GBP" },
            { value: "EUR", label: "EUR" },
            { value: "CNY", label: "CNY" },
            { value: "SEK", label: "SEK" },
            { value: "NKK", label: "NKK" },
            { value: "ISK", label: "ISK" },
          ]}
        />
      </div>
    );
  };

  onStatusSelect = (status) => {
    if (!status || !status.value) {
      return;
    }
    DataActions.createOrUpdateGlobalSetting({
      key: "subscriptionPlansStatus",
      value: status.value,
    });
  };

  uploadPicture = (plan) => {
    let newPlan = { ...plan };
    $("#uploadImage").trigger("click");
    $("#uploadImage").on("change", function () {
      let file = this.files[0];
      if (!file.type.includes("image")) {
        return;
      }
      DataActions.uploadDocuments(this.files).then((response) => {
        if (response.status === 200) {
          response.json().then((addedFiles) => {
            newPlan.pictureId = addedFiles[0].id;
            newPlan.picture = addedFiles[0];
            DataActions.updateSubscriptionPlan(newPlan);
          });
        }
      });
    });
  };

  onShowPicture = (picture) => {
    this.fileModal.open([picture]);
  };

  getStatusOptions = () => {
    return [
      { value: "", label: getText("axoAccounting6g", "Vælg status") },
      { value: "disabled", label: getText("axoAccounting6h", "Skjult") },
      { value: "testing", label: getText("axoAccounting6I", "Test") },
      { value: "released", label: getText("axoAccounting6j", "Udgivet") },
    ];
  };

  render() {
    let { sortedDataList, colSortDirs, columnDefs } = this.state;
    let { containerHeight, containerWidth, globalSettings } = this.props;
    let tableWidth = containerWidth - 30;

    let statusOptions = this.getStatusOptions();

    return (
      <div style={{ paddingTop: "10px" }}>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <div
                  className="flexbox-layout"
                  style={{ paddingLeft: "25px", paddingRight: "25px" }}
                >
                  <h4>
                    <Icon glyph="icon-fontello-align-justify" />
                    &nbsp;&nbsp;
                    <AxoLocal entity="AdminTabView23" defaultValue="Planer" /> (
                    {sortedDataList._data.length})
                  </h4>
                  <div>{this.renderCurrencySelect()}</div>
                  <div style={{ maxWidth: "250px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-search" />
                        </InputGroup.Addon>
                        {/* Søg...  */}
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <div>
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "150px",
                        zIndex: 1000,
                      }}
                    >
                      <Select
                        name="form-field-name"
                        menuPlacement="auto"
                        value={
                          statusOptions.find(
                            (o) =>
                              o.value === globalSettings.subscriptionPlansStatus
                          ) || {
                            value: "",
                            label: getText("axoAccounting6g", "Vælg status"),
                          }
                        }
                        onChange={this.onStatusSelect}
                        noOptionsMessage={() => ""}
                        options={statusOptions}
                      />
                    </div>
                  </div>
                </div>
              </Row>
            </Grid>
          </div>
          <div>
            <div style={{ paddingTop: "0px", paddingLeft: "20px" }}>
              <Table
                rowHeight={40}
                rowsCount={sortedDataList.getSize()}
                width={tableWidth}
                height={containerHeight}
                isColumnResizing={false}
                onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                headerHeight={40}
              >
                <Column
                  columnKey="planId"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.planId.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.planId}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabView25"
                        defaultValue="Plan Id"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>{plan.planId}</div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.planId.width}
                  isResizable={true}
                />
                <Column
                  columnKey="name"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.name.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.name}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal entity="AdminTabView26" defaultValue="Navn" />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>
                          <InlineEdit
                            value={plan.name || "---"}
                            change={this.onChangePlanProperty.bind(
                              this,
                              plan.planId,
                              "name"
                            )}
                          />
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.name.width}
                  isResizable={true}
                />
                <Column
                  columnKey="type"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.type.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.type}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="DocumentGridViewType"
                        defaultValue="Type"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>{plan.type}</div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.type.width}
                  isResizable={true}
                />
                <Column
                  columnKey="statement"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.statement.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.statement}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="Enhedspris6"
                        defaultValue="Beskrivelse"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>
                          <InlineEdit
                            value={plan.statement || "---"}
                            change={this.onChangePlanProperty.bind(
                              this,
                              plan.planId,
                              "statement"
                            )}
                          />
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.statement.width}
                  isResizable={true}
                />
                <Column
                  columnKey="currency"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.currency.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.currency}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal entity="AdminTabView28" defaultValue="Valuta" />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>{plan.currency}</div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.currency.width}
                  isResizable={true}
                />
                <Column
                  columnKey="amount"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.amount.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.amount}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabView29"
                        defaultValue="Pris (ekskl. moms)"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>{(plan.amount / 100).toFixed(2)}</div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.amount.width}
                  isResizable={true}
                />
                <Column
                  columnKey="costPerUnit"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.costPerUnit.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.costPerUnit}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp; Enhedspris
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>{(plan.costPerUnit / 100).toFixed(2)}</div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.costPerUnit.width}
                  isResizable={true}
                />
                <Column
                  columnKey="interval"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.interval.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.interval}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabView30"
                        defaultValue="Periode"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>{this.getPeriodEntity(plan.interval)}</div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.interval.width}
                  isResizable={true}
                />
                <Column
                  columnKey="trialPeriod"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.trialPeriod.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.trialPeriod}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabView31"
                        defaultValue="Prøvetid"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>
                          {plan.trialPeriod}{" "}
                          <AxoLocal
                            entity="AdminTabView32"
                            defaultValue="dage"
                          />
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.trialPeriod.width}
                  isResizable={true}
                />
                <Column
                  columnKey="storage"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.storage.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.storage}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabView33"
                        defaultValue="Lagerplads"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>
                          <InlineNumberEdit
                            value={plan.storage || 10}
                            format={(value) => {
                              return <span>{value.toString()} GB</span>;
                            }}
                            change={this.onChangePlanProperty.bind(
                              this,
                              plan.planId,
                              "storage"
                            )}
                            editProps={{ style: { width: "100%" } }}
                          />
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.storage.width}
                  isResizable={true}
                />
                <Column
                  columnKey="userGroupSize"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.userGroupSize.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.userGroupSize}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="Casesharing9E"
                        defaultValue="Brugergruppe"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>
                          <InlineNumberEdit
                            value={plan.userGroupSize}
                            format={(value) => {
                              return <span>{value.toString()}</span>;
                            }}
                            change={this.onChangePlanProperty.bind(
                              this,
                              plan.planId,
                              "userGroupSize"
                            )}
                          />
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.userGroupSize.width}
                  isResizable={true}
                />
                <Column
                  columnKey="freeUnitsPerInterval"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.freeUnitsPerInterval.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.freeUnitsPerInterval}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp; Gratis enheder
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>
                          <InlineNumberEdit
                            value={plan.freeUnitsPerInterval}
                            format={(value) => {
                              return <span>{value.toString()}</span>;
                            }}
                            change={this.onChangePlanProperty.bind(
                              this,
                              plan.planId,
                              "freeUnitsPerInterval"
                            )}
                          />
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.freeUnitsPerInterval.width}
                  isResizable={true}
                />
                <Column
                  columnKey="subscriberCount"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.subscriberCount.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.subscriberCount}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="AdminTabView34"
                        defaultValue="Brugere"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>{plan.subscriberCount}</div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.subscriberCount.width}
                  isResizable={true}
                />
                <Column
                  columnKey="picture"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.picture.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.picture}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal entity="Enhedspris2" defaultValue="Billede" />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>
                          {!!plan.picture ? (
                            <span>
                              <Icon
                                className="editable"
                                role="button"
                                onClick={() => this.onShowPicture(plan.picture)}
                                glyph="icon-fontello-attach-7"
                              />
                              &nbsp;
                            </span>
                          ) : null}
                          <LexButton onClick={() => this.uploadPicture(plan)}>
                            <AxoLocal
                              entity="DocumentTabViewUpload"
                              defaultValue="Upload"
                            />
                          </LexButton>
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.picture.width}
                  isResizable={true}
                />
                <Column
                  columnKey="targetUserName"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.targetUserName.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.targetUserName}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp; Målbruger
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <div>
                          <InlineEdit
                            value={plan.targetUserName || "---"}
                            change={this.onChangePlanProperty.bind(
                              this,
                              plan.planId,
                              "targetUserName"
                            )}
                          />
                        </div>
                      </Cell>
                    );
                  }}
                  width={columnDefs.targetUserName.width}
                  isResizable={true}
                />
                <Column
                  columnKey="hidden"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-user-outline" />}
                      showAltIcon={columnDefs.hidden.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.hidden}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal entity="Enhedspris9" defaultValue="Skjult" />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <AxoCheckbox
                          checked={plan.hidden}
                          onChange={(event) =>
                            this.onChangePlanPropertyValue(
                              plan.planId,
                              "hidden",
                              event.target.checked
                            )
                          }
                        />
                      </Cell>
                    );
                  }}
                  width={columnDefs.hidden.width}
                  isResizable={true}
                />
                <Column
                  columnKey="actions"
                  header={<Cell></Cell>}
                  cell={(props) => {
                    let plan = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={plan.planId} {...props}>
                        <Icon
                          role="button"
                          onClick={this.onDeletePlan.bind(this, plan.id)}
                          glyph="icon-fontello-trash-1"
                        />
                      </Cell>
                    );
                  }}
                  width={columnDefs.actions.width}
                  isResizable={true}
                />
              </Table>
            </div>
          </div>
        </div>
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
        <input
          name="image"
          type="file"
          id="uploadImage"
          accept="image/*"
          style={{ display: "none" }}
        />
      </div>
    );
  }
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight - 240;
    },
  })(SubscriptionPlanView)
);
