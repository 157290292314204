import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import moment from "moment";

import { ButtonToolbar } from "react-bootstrap";

import { ContactService } from "../../../services/AxoServices";

import {
  Dimensions,
  ClientSearchBox,
  Flexbox,
  FlexElement,
  AxoLocal,
  Link,
  getText,
  LexButton,
  AsyncButton,
  AxoDateTime,
  AxoCheckbox,
} from "../../../utilities/LexUtilities";

import AccountingVatTable from "./AccountingVatTable";

class AccountingVatview extends React.Component {
  static propTypes = {
    selectedPlan: PropTypes.object.isRequired,
    selectedContact: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
  };

  doSettleVat = async () => {
    let { doSettleVat, selectedContact, includeDraft } = this.props;

    return doSettleVat(selectedContact.id, includeDraft);
  };

  doPayVat = async () => {
    let { doPayVat, selectedContact, selectedPaymentAccountId } = this.props;
    return doPayVat(
      selectedContact.id,
      selectedPaymentAccountId || this.getDefaultPaymentAccountId()
    );
  };

  getDefaultPaymentAccountId = () => {
    let { selectedPlan } = this.props;

    let paymentAccounts = selectedPlan.accounts.filter(
      (a) =>
        a.number >= selectedPlan.bankAccountIntervalStart &&
        a.number <= selectedPlan.bankAccountIntervalEnd
    );

    return paymentAccounts.length > 1 ? paymentAccounts[0].id : "";
  };

  defaultFiscalYear = { id: 0 };

  render() {
    let {
      selectedPlan,
      contactMap,
      handleSelectedClient,
      selectedContact,
      fiscalYears,
      selectedFiscalYearId,
      onSelectFiscalYear,
      vatReportArray,
      onSettleVat,
      onCancelSettling,
      settlingVat,
      settlingStartDate,
      settlingEndDate,
      showConfirmationMessage,
      onPayVat,
      payingVat,
      onCancelPayment,
      selectedPaymentAccountId,
      onSelectPaymentAccount,
      paymentDate,
      onSelectPaymentDate,
      includeDraft,
      onChangeIncludeDraft,
      fixedClient,
    } = this.props;

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let standardAccounts = selectedPlan.accounts.filter(
      (a) => a.type === "Standard"
    );
    let accountOptions = standardAccounts.map((f) => {
      return { value: f.id, label: f.number.toString() + " - " + f.name };
    });

    let activePaymentAccount =
      selectedPaymentAccountId || this.getDefaultPaymentAccountId();

    return (
      <div style={{ marginLeft: "15px", marginRight: "15px" }}>
        <Flexbox
          responsive
          style={{ marginTop: "15px", paddingBottom: "10px" }}
        >
          {!fixedClient && (
            <FlexElement className="onTop rightPadding" style={{ zIndex: 10 }}>
              <AxoLocal
                componentClass={ClientSearchBox}
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                componentAttribute="placeholder"
                entity="composeSelectClientPlaceholder1"
              />
            </FlexElement>
          )}
          {selectedContact.id && !!fiscalYears ? (
            <React.Fragment>
              <FlexElement flexBasis="250px" className="onTop rightPadding">
                <Select
                  name="fiscalYear"
                  menuPlacement="auto"
                  value={{
                    value: selectedFiscalYear.id.toString(),
                    label: selectedFiscalYear.name,
                  }}
                  onChange={(selection, arg) => {
                    onSelectFiscalYear(
                      !!selection && selection.value !== "0"
                        ? parseInt(selection.value, 10)
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "0",
                      label: getText("axoidcode100", "Vælg regnskabsår"),
                    },
                    ...fiscalYears.map((year) => {
                      return { value: year.id.toString(), label: year.name };
                    }),
                  ]}
                />
              </FlexElement>
              <FlexElement style={{ paddingTop: "10px", paddingLeft: "15px" }}>
                <AxoCheckbox
                  onChange={(event) =>
                    onChangeIncludeDraft(event.target.checked)
                  }
                  checked={includeDraft}
                />
                <AxoLocal entity="axoidcode33" defaultValue={"Medtag kladde"} />
              </FlexElement>
            </React.Fragment>
          ) : null}
        </Flexbox>
        {!!fiscalYears && selectedContact.id && fiscalYears.length === 0 ? (
          <div>
            <span style={{ fontSize: "18px" }}>
              <Link to="Setup">
                {!fixedClient ? (
                  <>
                    <AxoLocal
                      entity="axoidcode67"
                      defaultValue={"Opret et regnskabsår for"}
                    />
                    &nbsp;{selectedContact.firstName} {selectedContact.lastName}
                  </>
                ) : (
                  <>
                    <AxoLocal
                      entity="axoidcode215"
                      defaultValue={"Opret et regnskabsår"}
                    />
                  </>
                )}
              </Link>
            </span>
          </div>
        ) : null}
        {settlingVat ? (
          <Flexbox responsive collapseXS>
            <FlexElement className="rightPadding">
              <h4>
                <AxoLocal
                  entity="axoidcode115"
                  defaultValue={"Afregn moms for perioden"}
                />
                &nbsp;{moment(settlingStartDate).format("L")} -{" "}
                {moment(settlingEndDate).format("L")}
                &nbsp;
                <AxoLocal entity="axoidcode116" defaultValue={"på konto"} />
              </h4>
            </FlexElement>
            <FlexElement flexBasis="250px" className="rightPadding">
              <Select
                name="vatSettlementAccount"
                menuPlacement="auto"
                isDisabled
                value={
                  !!selectedPlan.vatSettlementAccountId
                    ? accountOptions.find(
                        (o) => o.value === selectedPlan.vatSettlementAccountId
                      )
                    : { value: "", label: "" }
                }
                noOptionsMessage={() => ""}
                options={[
                  {
                    value: "",
                    label: getText("axoAccounting6f", "Vælg konto"),
                  },
                  ...accountOptions,
                ]}
              />
            </FlexElement>
            <FlexElement>
              <ButtonToolbar>
                <AsyncButton onClick={this.doSettleVat}>
                  <AxoLocal
                    entity="friendRequestTimelineonApproveRequest"
                    defaultValue={"Godkend"}
                  />
                </AsyncButton>
                <LexButton onClick={onCancelSettling}>
                  <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
                </LexButton>
              </ButtonToolbar>
            </FlexElement>
          </Flexbox>
        ) : null}
        {payingVat ? (
          <React.Fragment>
            <div>
              <h4>
                <AxoLocal
                  entity="axoidcode117"
                  defaultValue={"Betal moms for perioden"}
                />
                &nbsp;{moment(settlingStartDate).format("L")} -{" "}
                {moment(settlingEndDate).format("L")}
              </h4>
            </div>
            <Flexbox responsive collapseXS>
              <FlexElement className="rightPadding">
                <h4>
                  <AxoLocal entity="axoidcode121" defaultValue={"til konto"} />
                </h4>
              </FlexElement>
              <FlexElement flexBasis="250px" className="rightPadding">
                <Select
                  name="vatSettlementAccount"
                  menuPlacement="auto"
                  isDisabled
                  value={
                    !!selectedPlan.vatSettlementAccountId
                      ? accountOptions.find(
                          (o) => o.value === selectedPlan.vatSettlementAccountId
                        )
                      : { value: "", label: "" }
                  }
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "",
                      label: getText("axoAccounting6f", "Vælg konto"),
                    },
                    ...accountOptions,
                  ]}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <h4>
                  <AxoLocal entity="axoidcode120" defaultValue={"fra konto"} />
                </h4>
              </FlexElement>
              <FlexElement flexBasis="250px" className="rightPadding">
                <Select
                  name="paymentAccount"
                  menuPlacement="auto"
                  value={
                    !!activePaymentAccount
                      ? accountOptions.find(
                          (o) => o.value === activePaymentAccount
                        )
                      : { value: "", label: "" }
                  }
                  onChange={(selectedAccount) => {
                    if (!!selectedAccount) {
                      onSelectPaymentAccount(selectedAccount.value);
                    }
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "",
                      label: getText("axoAccounting6f", "Vælg konto"),
                    },
                    ...accountOptions,
                  ]}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <h4>
                  <AxoLocal entity="axoidcode118" defaultValue={"på dato"} />
                </h4>
              </FlexElement>
              <FlexElement className="rightPadding">
                <AxoDateTime
                  id="paymentDate"
                  value={moment(paymentDate)}
                  isValidDate={(currentDate) => {
                    return !currentDate.isBefore(moment(settlingEndDate));
                  }}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectPaymentDate}
                  // inputProps={ { style: { border: "none", padding: "0px 0px 0px 0px"}}}
                />
              </FlexElement>
              <FlexElement>
                <ButtonToolbar>
                  <AsyncButton onClick={this.doPayVat}>
                    <AxoLocal
                      entity="friendRequestTimelineonApproveRequest"
                      defaultValue={"Godkend"}
                    />
                  </AsyncButton>
                  <LexButton onClick={onCancelPayment}>
                    {" "}
                    <AxoLocal
                      entity="axoAccounting22"
                      defaultValue={"Fortryd"}
                    />
                  </LexButton>
                </ButtonToolbar>
              </FlexElement>
            </Flexbox>
          </React.Fragment>
        ) : null}
        {!settlingVat && showConfirmationMessage ? (
          <div style={{ paddingBottom: "10px" }}>
            <h4>
              <Link to="Drafts" style={{ textDecoration: "underline" }}>
                <AxoLocal
                  entity="axoidcode79"
                  defaultValue={
                    "Posteringen blev oprettet i bogføringskladden."
                  }
                />
              </Link>
            </h4>
          </div>
        ) : null}
        {!!selectedContact.id && !!selectedFiscalYearId ? (
          <AccountingVatTable
            selectedPlan={selectedPlan}
            selectedContact={selectedContact}
            selectedFiscalYear={selectedFiscalYear}
            vatReportArray={vatReportArray}
            onSettleVat={onSettleVat}
            settlingVat={settlingVat}
            onPayVat={onPayVat}
            payingVat={payingVat}
            includeDraft={includeDraft}
          />
        ) : null}
      </div>
    );
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function () {
    return window.innerHeight - 250;
  },
})(AccountingVatview);
