import ApiService from "./ApiService";
import LocalizationService from "./../LocalizationService";
import StorageService from "./../StorageService";
import SocialPostStore from "./../SocialPostStore";
import RoutingService from "./../RoutingService";
import ThemeService from "./../ThemeService";
import ReduxStore from "./ReduxStore";
import { axolexApi } from "./ApiSlice";
import { setSelectedContact } from "./ReduxData/contactData";

import { debounce } from "lodash";
import moment from "moment";

const signalR = require("@aspnet/signalr/dist/browser/signalr.js");

let store = createStore();

//Redux integration while refactoring is ongoing
const refetchContacts = () =>
  ReduxStore.dispatch(axolexApi.endpoints.refetchContacts.initiate());
const refetchMessages = () =>
  ReduxStore.dispatch(axolexApi.endpoints.refetchMessages.initiate());
const setSelectedContactRedux = (selectedContact) =>
  ReduxStore.dispatch(setSelectedContact(selectedContact));
const refetchCases = () =>
  ReduxStore.dispatch(axolexApi.endpoints.refetchCases.initiate());
const updateCaseMapState = (caseMapState) =>
  ReduxStore.dispatch(
    axolexApi.endpoints.updateCaseMapState.initiate(caseMapState)
  );

//Map of data types that have been retrieved from the server.
//Outside the store, since it should not be dispatched.
let initialized = {};

let cachedMessage = null;

const EmailAccountStatus = {
  LOADING: 0,
  FAILURE: 1,
  SUCCESS: 2,
};

function createStore() {
  let store = {
    loaded: false,
    userName: "",
    userProfile: {},
    userSettings: {},
    userProfileArray: [],
    selectedUserProfileId: -1,
    messageMap: {
      messages: [],
      received: [],
      important: [],
      sent: [],
      drafts: [],
      spam: [],
      trashed: [],
    },
    messagePageSize: 50,
    messagePageIncrement: 50,
    messageQuery: {
      searchText: "",
      startDate: null,
      endDate: null,
      labelId: null,
      externalAccountId: null,
      onlyUnread: false,
      onlyWithAttachments: false,
    },
    cachedMessage: null,
    cachedBCCList: "",
    emailSuggestions: [], //[{ displayName: 'Simon', email: 'simonchris1729@gmail.com'}]
    caseMap: {
      cases: [],
      saved: [],
      savedCount: 0,
      drafts: [],
      draftCount: 0,
      closed: [],
      closedCount: 0,
      trashed: [],
      trashedCount: 0,
    },
    currentClientCases: [],

    casePageSize: 500,
    casePageIncrement: 500,

    selectedCaseId: -1,
    clients: [],

    documentMap: {
      documents: [],
      count: 0,
      social: [],
      socialCount: 0,
      invoices: [],
      invoiceCount: 0,
      trashed: [],
      trashedCount: 0,
    },

    documentQuery: {
      // searchText: '',
      // selectedCase: null,
      // selectedLabel: null,
      // selectedClient: null,
      // selectedFromDate: null,
      // selectedToDate: null
    },

    contactMap: {
      contacts: [],
      count: 0,
      trashed: [],
      trashedCount: 0,
    },

    documentPageSize: 500,
    documentPageIncrement: 500,

    activeDocumentId: -1,
    sharedDocuments: [],
    labels: [],
    calendarEvents: [],
    notifications: [],

    timeEntryMap: {
      timeEntries: [],
      count: 0,
      trashed: [],
      trashedCount: 0,
    },
    timeEntryPageSize: 500,
    timeEntryPageIncrement: 500,

    invoices: [],
    templates: [],
    friends: [],
    friendRequests: [],
    clientProfiles: [],
    lawyerProfiles: [],
    userSubscription: {},
    cardInformation: {},
    storageInformation: {},
    systemUsers: [],
    subscriptionPlans: [],
    globalSettings: {},
    userGroup: {},
    sharedCases: [],
    emailAccounts: [],
    accountStatuses: {},
    subscriptionFormulas: [],
    messageCounter: {
      unreadCount: 0,
    },
    sharingGlobals: [],
    itemPrices: [],
    itemPriceTypes: [],
    shoppingCart: StorageService.loadItem("shoppingCart", []), // Item price ids
    theme: StorageService.loadValue("theme", ThemeService.getStandard()),
    showDropdowns: StorageService.loadValue("showDropdowns", "false").includes(
      "true"
    ),
    selectedPlan: StorageService.loadItem("selectedPlan", {}),
    legalPresentations: [],

    bookKeepingDraftEntries: [],
    importantEntryIds: new Set(),
    bankPostings: [],
    bankImportSettings: {
      dateColumnIndex: 0,
      textColumnIndex: 1,
      amountColumnIndex: 2,
      accountBalanceColumnIndex: 3,
      ...StorageService.loadItem("bankImportSettings", {}),
    },
    bankProgress: StorageService.loadItem("bankProgress", {}), //Map from contactId to bank reconciliation step
    postingEntries: [],
    scanReceipts: StorageService.loadValue("scanReceipts", "false").includes(
      "true"
    ),
    splitPDF: StorageService.loadValue("splitPDF", "false").includes("true"),
    convertToPDF: StorageService.loadValue("convertToPDF", "true").includes(
      "true"
    ),
    autoAccounts: StorageService.loadValue("autoAccounts", "true").includes(
      "true"
    ), //Automatically assign accounts based on VAT number and description
    // calculateNumbers: StorageService.loadValue('calculateNumbers', 'true').includes('true'), //Calculate numbers before posting drafts
    calculateNumbers: false, //Calculate numbers before posting drafts

    financeAccountPlans: [],
    standardFinanceAccountPlans: [],
    clientPlan: { accounts: [], taxSpecifications: [] },

    //Accounting search parameters
    fiscalYears: null, //Array. Use null as starting value, to check whether fiscal years have been initialized.
    selectedFiscalYearId: 0,
    selectedFiscalStartDate: null,
    selectedFiscalEndDate: null,
    selectedPostingStartDate: null,
    selectedPostingEndDate: null,
    selectedInvoiceStartDate: moment.utc().startOf("year"),
    selectedInvoiceEndDate: moment.utc().endOf("year"),
    accountStartNumber: null,
    accountEndNumber: null,
    receiptStartNumber: null,
    receiptEndNumber: null,
    includeDraft: false,
    hideEmpty: false,

    accountingReport: {},
    draftSimulationResult: {
      accountResults: {},
      balance: 0,
    },
    vatReportArray: [], //Legacy. Deprecated.
    vatReport: { current: [], settled: [] },
    postingJournals: [],

    hideSidebar: false,

    trustedEmails: StorageService.loadItem("trustedEmails", []), //Map from contactId to bank reconciliation step

    bankBalancingData: StorageService.loadItem("bankBalancingData", {
      //Map from customer id to bank balancing data.
      //32423: {
      //createdEntryIds: [],
      //}
    }), //Created entries in bank balancing, which can be rolled back.

    //Maps
    financeAccounts: {},
    taxSpecifications: {},

    locale: "enUS",

    //Client accounts
    myContactInfo: {},

    //ApplicationUD
    applicationUDs: [],

    //BusinessOperating
    businessOperatings: [],

    //PowerOfAttorney
    powerOfAttorneys: [],

    //EmploymentContract
    employmentContracts: [],

    products: [],

    trashCount: 0,

    //Templates
    modelTemplates: [],

    selectedContact: {},
  };

  return store;
}

var storeSubscribers = [];
function dispatch() {
  storeSubscribers.forEach((subscription) => subscription.callback(store));
}

LocalizationService.subscribeToLocale("DataStore", (locale) => {
  locale = locale.toLowerCase();
  if (locale === "enus") {
    locale = "enUS";
  }
  store = {
    ...store,
    locale,
  };
  dispatch();
});

const getSenderId = (message) => {
  return !!message.sender ? message.sender.id : 0;
};

const getReceiverId = (message) => {
  return !!message.receiver ? message.receiver.id : 0;
};

const makeMessageMap = (messages) => {
  let newMap = { ...store.messageMap };

  newMap.messages = messages;
  newMap.received = messages.filter((message) => {
    return (
      getReceiverId(message) === store.userProfile.id &&
      !message.draft &&
      !message.trashed &&
      !message.spam &&
      !message.addedLabel
    );
  });
  newMap.spam = messages.filter((message) => {
    return getReceiverId(message) === store.userProfile.id && message.spam;
  });
  newMap.important = messages.filter((message) => {
    return (
      (getReceiverId(message) === store.userProfile.id ||
        getSenderId(message) === store.userProfile.id) &&
      message.important &&
      !message.trashed
    );
  });
  newMap.sent = messages.filter((message) => {
    return (
      getSenderId(message) === store.userProfile.id &&
      !message.draft &&
      !message.trashed
    );
  });
  newMap.drafts = messages.filter((message) => {
    return (
      getSenderId(message) === store.userProfile.id &&
      message.draft &&
      !message.trashed
    );
  });
  //Show received mails, drafts and mails sent to external mail servers
  newMap.trashed = messages.filter((message) => {
    return (
      (getReceiverId(message) === store.userProfile.id ||
        (getSenderId(message) === store.userProfile.id && message.draft) ||
        (getSenderId(message) === store.userProfile.id &&
          !!message.sender &&
          !message.receiver)) &&
      message.trashed
    );
  });

  store = {
    ...store,
    messageMap: newMap,
  };

  dispatch();
};

var scrollId = "";
var onLoadCallback;

let dataHubConnection = null;
let hubConnectionTimer = null;
let dataHubConnectionAttemptInProgress = false;

let emailHubConnection = null;
let emailHubConnectionTimer = null;
let emailHubConnectionAttemptInProgress = false;

const DataStore = {
  initializeSignalRHub(notificationCallback) {
    if (dataHubConnection) {
      return;
    }

    let hubRoute = RoutingService.getHostPath() + "/dataupdatehub";

    dataHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubRoute)
      .build();

    dataHubConnection.on("ReceiveUpdateNotification", async (dataType) => {
      console.log(dataType);
      switch (dataType) {
        case "BookkeepingDraftEntries":
          this.fetchBookkeepingDraftEntriesDebounced();
          break;
        case "Documents":
          this.fetchDocumentsDebounced();
          break;
        case "CaseModels":
          this.fetchCasesDebounced();
          break;
        case "SharedDocuments":
          this.fetchSharedDocumentsDebounced();
          break;
        case "Templates":
          this.fetchTemplatesDebounced();
          break;
        case "Notifications":
          this.fetchNotificationsDebounced();
          break;
        case "FriendRequests":
          this.fetchFriendRequestsDebounced();
          break;
        case "Friends":
          this.fetchFriends();
          break;
        case "Messages":
          refetchMessages();
          break;
        case "FinanceAccountPlans":
          this.fetchFinanceAccountPlansDebounced();
          break;
        case "StandardFinanceAccountPlans":
          this.fetchStandardFinanceAccountPlansDebounced();
          break;
        case "Labels":
          this.fetchLabelsDebounced();
          break;
        case "Invoices":
          this.fetchInvoicesDebounced();
          break;
        case "FiscalYears":
          this.fetchFiscalYearsDebounced();
          break;
        case "Contacts":
          refetchContacts();
          break;
        case "CalendarEvents":
          this.fetchCalendarEventsDebounced();
          break;
        case "PrivateConversations":
          SocialPostStore.fetchPrivateConversations();
          break;
        default:
          return;
      }
    });

    dataHubConnection.on("ReceiveMessageNotification", notificationCallback);

    dataHubConnection.onclose((error) => {
      if (!!error) {
        console.log(error.toString());
      }
      console.log(
        new Date().toLocaleTimeString() + " - SignalR data hub connection lost."
      );
      if (!dataHubConnection || !!hubConnectionTimer) {
        return;
      }

      hubConnectionTimer = setInterval(() => {
        if (dataHubConnectionAttemptInProgress) {
          return;
        }

        console.log(
          new Date().toLocaleTimeString() +
            " - Attempting to reconnect with SignalR data hub."
        );
        dataHubConnectionAttemptInProgress = true;
        dataHubConnection
          .start()
          .then(() => {
            console.log(
              new Date().toLocaleTimeString() +
                " - SignalR data hub connection reestablished."
            );
            clearInterval(hubConnectionTimer);
            hubConnectionTimer = null;
            dataHubConnectionAttemptInProgress = false;
          })
          .catch((err) => {
            this.logConnectionError(
              err,
              "Error reestablishing data hub connection."
            );
            dataHubConnectionAttemptInProgress = false;
          });
      }, 3000);
    });

    dataHubConnection
      .start()
      .catch((err) =>
        this.logConnectionError(
          err,
          "Error reestablishing data hub connection."
        )
      );
  },

  initializeEmailHub() {
    if (emailHubConnection) {
      return;
    }

    let hubRoute = RoutingService.getHostPath() + "/emailhub";
    emailHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubRoute)
      .build();

    emailHubConnection.on("ReceiveMailAccountUpdate", async (accountId) => {
      this.fetchEmailsFromAccount(accountId, this);
    });

    emailHubConnection.on("EmailClientDisconnected", async () => {
      this.updateEmailListenersDebounced();
    });

    emailHubConnection.onclose((error) => {
      if (!!error) {
        console.log(error.toString());
      }
      console.log(
        new Date().toLocaleTimeString() +
          " - SignalR email hub connection lost."
      );
      if (!emailHubConnection || !!emailHubConnectionTimer) {
        return;
      }

      emailHubConnectionTimer = setInterval(() => {
        if (emailHubConnectionAttemptInProgress) {
          return;
        }

        console.log(
          new Date().toLocaleTimeString() +
            " - Attempting to reconnect with SignalR email hub."
        );
        emailHubConnectionAttemptInProgress = true;
        emailHubConnection
          .start()
          .then(() => {
            emailHubConnection.invoke("InitializeEmailListeners");
            this.fetchEmailsFromAllAccounts(); //Get lost emails
            console.log(
              new Date().toLocaleTimeString() +
                " - SignalR email hub connection reestablished."
            );
            clearInterval(emailHubConnectionTimer);
            emailHubConnectionTimer = null;
            emailHubConnectionAttemptInProgress = false;
          })
          .catch((err) => {
            this.logConnectionError(
              err,
              "Error reestablishing email hub connection."
            );
            emailHubConnectionAttemptInProgress = false;
          });
      }, 3000);
    });

    emailHubConnection
      .start()
      .then(() => {
        emailHubConnection.invoke("InitializeEmailListeners");
      })
      .catch((err) =>
        this.logConnectionError(
          err,
          "Error reestablishing email hub connection."
        )
      );
  },

  //Wait one minute to retry connection.
  updateEmailListenersDebounced: debounce(() => {
    //Check if connection is active
    if (
      !emailHubConnection ||
      emailHubConnection.connection.connectionState !== 1
    ) {
      return;
    }

    emailHubConnection.invoke("InitializeEmailListeners");
    console.log(
      "Email server disconnected. Refreshing email client listeners."
    );
  }, 60000),

  updateEmailListeners() {
    emailHubConnection.invoke("InitializeEmailListeners");
  },

  //Debounce to avoid loading several times from the same account
  fetchEmailsFromAccount: debounce((accountId, context) => {
    context.setAccountStatus(accountId, EmailAccountStatus.LOADING);

    ApiService.getEmailsFromAccount(accountId)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Emails could not be fetched");
        }

        context.setAccountStatus(accountId, EmailAccountStatus.SUCCESS);
        refetchMessages();
        // context.fetchMessageCounter();
        // context.fetchMessages(store.messageQuery);
      })
      .catch((reason) => {
        console.log(reason);
        context.setAccountStatus(accountId, EmailAccountStatus.FAILURE);
      });
  }, 3000),

  setAccountStatus(accountId, status) {
    this.setState({
      accountStatuses: {
        ...store.accountStatuses,
        [accountId]: status,
      },
    });
  },

  logConnectionError(error, defaultMessage) {
    let dateString = new Date().toLocaleTimeString();

    if (!!error) {
      return dateString + " - " + error.toString();
    }
    return dateString + " - " + defaultMessage;
  },

  getStore() {
    return store;
  },

  resetStore() {
    this.setSelectedContact({}); //Reset stored contact

    store = {
      ...createStore(),
      loaded: store.loaded,
    };

    initialized = {};

    if (!!emailHubConnection) {
      emailHubConnection.stop();
      emailHubConnection = null;
      if (!!emailHubConnectionTimer) {
        clearInterval(emailHubConnectionTimer);
        emailHubConnectionTimer = null;
        emailHubConnectionAttemptInProgress = false;
      }
    }

    if (!!dataHubConnection) {
      dataHubConnection.stop();
      dataHubConnection = null;
      if (!!hubConnectionTimer) {
        clearInterval(hubConnectionTimer);
        hubConnectionTimer = null;
        dataHubConnectionAttemptInProgress = false;
      }
    }

    dispatch();
  },

  setLoaded() {
    store = {
      ...store,
      loaded: true,
    };

    dispatch();
    if (onLoadCallback) {
      onLoadCallback(store.loaded);
    }
  },

  onLoad(callback) {
    onLoadCallback = callback;
    onLoadCallback(store.loaded);
  },

  fetchUserName() {
    return ApiService.getUserName().then((userName) => {
      this.setState({ userName });
    });
  },

  fetchMessages(query = {}) {
    refetchMessages();
    // this.fetchMessageCounter();
    // return ApiService.getMessages(store.messagePageSize, {
    //   ...query,
    //   startDate: query.startDate ? query.startDate.format() : null,
    //   endDate: query.endDate ? query.endDate.format() : null
    // })
    // .then( messageMapResponse => {
    //   this.setState({ messageMap: messageMapResponse });
    //   this.calculateEmailSuggestions();
    //   return store.messageMap;
    // });
  },

  calculateEmailSuggestions() {
    let emailsAdded = new Set();
    let emailSuggestions = [];
    store.messageMap.messages.forEach((message) => {
      if (!!message.externalSender) {
        let names = (message.externalSenderDisplayName || "")
          .split(";")
          .map((n) => n.trim());
        let emails = message.externalSender.split(";").map((n) => n.trim());
        for (let i = 0; i < emails.length; i++) {
          if (emailsAdded.has(emails[i])) {
            continue;
          }

          emailsAdded.add(emails[i]);
          let name = i < names.length && !!names[i] ? names[i] : emails[i];
          emailSuggestions.push({ displayName: name, email: emails[i] });
        }
      }

      if (!!message.externalMailReceiver) {
        let names = (message.externalReceiverDisplayName || "")
          .split(";")
          .map((n) => n.trim());
        let emails = message.externalMailReceiver
          .split(";")
          .map((n) => n.trim());
        for (let i = 0; i < emails.length; i++) {
          if (emailsAdded.has(emails[i])) {
            continue;
          }

          emailsAdded.add(emails[i]);
          let name = i < names.length && !!names[i] ? names[i] : emails[i];
          emailSuggestions.push({ displayName: name, email: emails[i] });
        }
      }
    });
    this.setState({ emailSuggestions });
  },

  calculateEmailSuggestionsV2(messageMap) {
    let emailsAdded = new Set();
    let emailSuggestions = [];
    messageMap.messages.forEach((message) => {
      if (!!message.externalSender) {
        let names = (message.externalSenderDisplayName || "")
          .split(";")
          .map((n) => n.trim());
        let emails = message.externalSender.split(";").map((n) => n.trim());
        for (let i = 0; i < emails.length; i++) {
          if (emailsAdded.has(emails[i])) {
            continue;
          }

          emailsAdded.add(emails[i]);
          let name = i < names.length && !!names[i] ? names[i] : emails[i];
          emailSuggestions.push({ displayName: name, email: emails[i] });
        }
      }

      if (!!message.externalMailReceiver) {
        let names = (message.externalReceiverDisplayName || "")
          .split(";")
          .map((n) => n.trim());
        let emails = message.externalMailReceiver
          .split(";")
          .map((n) => n.trim());
        for (let i = 0; i < emails.length; i++) {
          if (emailsAdded.has(emails[i])) {
            continue;
          }

          emailsAdded.add(emails[i]);
          let name = i < names.length && !!names[i] ? names[i] : emails[i];
          emailSuggestions.push({ displayName: name, email: emails[i] });
        }
      }
    });
    this.setState({ emailSuggestions });
  },

  // async initializeMessages(query) {
  //   await this.initializeData(() => this.fetchMessages(query), 'messageMap');
  // },

  increaseMessagePageSize(query) {
    this.setState({
      messagePageSize: store.messagePageSize + store.messagePageIncrement,
    });
    return Promise.resolve({});
    // return this.fetchMessages(query);
  },

  resetMessagePageSize() {
    if (store.messagePageSize <= store.messagePageIncrement) {
      return;
    }

    this.setState({ messagePageSize: store.messagePageIncrement });
    return Promise.resolve({});

    // return this.fetchMessages();
  },

  fetchMessageHeader(id) {
    return ApiService.getMessageHeader(id).then((message) => {
      this.updateMessageState(message);
    });
  },

  //Used when loading messages for labels
  setActiveMessages(messages) {
    //All label messages are shown in the inbox
    this.setState({
      messageMap: {
        ...store.messageMap,
        messages,
        received: messages,
      },
    });
  },

  updateMessageState(newMessage) {
    var currentMessage = store.messageMap.messages.find(
      (m) => m.id === newMessage.id
    );
    if (!!currentMessage) {
      Object.assign(currentMessage, newMessage);
    } else {
      store.messageMap.messages.push(newMessage);
    }
    makeMessageMap(store.messageMap.messages);
  },

  deleteMessageFromState(id) {
    var newMessages = store.messageMap.messages.filter((m) => m.id !== id);
    makeMessageMap(newMessages);
  },

  setCachedMessage(message) {
    cachedMessage = message;
  },

  getCachedMessage() {
    return cachedMessage;
  },

  setBCCList(cachedBCCList) {
    this.setStateWithoutDispatch({ cachedBCCList });
  },

  getBCCList() {
    return store.cachedBCCList;
  },

  fetchLabels() {
    return ApiService.getLabels().then((labelResponse) => {
      this.setState({ labels: labelResponse });
    });
  },

  async initializeLabels() {
    await this.initializeData(() => this.fetchLabels(), "labels");
  },

  removeLabelAndReferences(labelId) {
    store = {
      ...store,
      labels: store.labels.filter((l) => l.id !== labelId),
      documentMap: {
        ...store.documentMap,
        documents: store.documentMap.documents.map((d) => {
          return {
            ...d,
            labels: d.labels.filter((l) => l.id !== labelId),
          };
        }),
      },
      // contactMap: {
      //   ...store.contactMap,
      //   contacts: store.contactMap.contacts.map(c => {
      //     return {
      //       ...c,
      //       labels: c.labels.filter(l => l.id !== labelId)
      //     }
      //   })
      // }
    };
    this.rebuildDocumentMap();
    // this.rebuildContactMap();

    dispatch();
  },

  fetchCases(query) {
    refetchCases();
    return Promise.resolve();
    // return ApiService.getCases(store.casePageSize, query)
    // .then( caseResponse => {
    //   this.setState({ caseMap: caseResponse });
    // });
  },

  async fetchCurrentClientCases() {
    let caseResponse = await ApiService.getCurrentClientCases();
    this.setState({
      currentClientCases: caseResponse,
    });
    // this.setState({
    //   caseMap: {
    //     cases: caseResponse,
    //     saved: caseResponse,
    //     savedCount: caseResponse.length,
    //     drafts: [],
    //     draftCount: 0,
    //     closed: [],
    //     closedCount: 0,
    //     trashed: [],
    //     trashedCount:  0
    //   }
    // })
  },

  async initializeCases() {
    this.initializeData(() => this.fetchCases(), "caseMap");
  },

  increaseCasePageSize() {
    this.setState({
      casePageSize: store.casePageSize + store.casePageIncrement,
    });
    return this.fetchCases();
  },

  resetCasePageSize() {
    if (store.casePageSize <= store.casePageIncrement) {
      return;
    }

    this.setState({ casePageSize: store.casePageIncrement });
    return this.fetchCases();
  },

  fetchCaseHeader(id) {
    return ApiService.getCaseHeader(id).then((caseHeader) => {
      this.updateCaseModelState(caseHeader);
    });
  },

  //Optimistic updating of case map
  updateCaseModelState(caseHeader) {
    store = {
      ...store,
      caseMap: {
        ...store.caseMap,
        cases: store.caseMap.cases.map((c) => {
          if (c.id !== caseHeader.id) {
            return c;
          }
          return caseHeader;
        }),
      },
    };

    this.rebuildCaseMap();
    dispatch();
  },

  removeCaseFromMap(id) {
    store = {
      ...store,
      caseMap: {
        ...store.caseMap,
        cases: store.caseMap.cases.filter((c) => c.id !== id),
      },
    };
    // store.caseMap.cases = store.caseMap.cases.filter(c => c.id !== id);
    this.rebuildCaseMap();
    dispatch();
  },

  rebuildCaseMap() {
    let caseMap = { ...store.caseMap };
    var oldMap = store.caseMap;
    var cases = caseMap.cases;
    caseMap.drafts = cases.filter(
      (c) => c.status === "Draft" && !c.closed && !c.trashed
    );
    caseMap.saved = cases.filter(
      (c) =>
        (c.status === "Active" || c.status === "Standby") &&
        !c.closed &&
        !c.trashed
    );
    caseMap.closed = cases.filter((c) => c.closed && !c.trashed);
    caseMap.trashed = cases.filter((c) => c.trashed);

    //Update counts if a case has changed type
    caseMap.draftCount += caseMap.drafts.length - oldMap.drafts.length;
    caseMap.savedCount += caseMap.saved.length - oldMap.saved.length;
    caseMap.closedCount += caseMap.closed.length - oldMap.closed.length;
    caseMap.trashedCount += caseMap.trashed.length - oldMap.trashed.length;

    store = {
      ...store,
      caseMap,
    };

    updateCaseMapState(caseMap);
  },

  setActiveCases(cases) {
    store = {
      ...store,
      caseMap: {
        ...store.caseMap,
        cases: cases,
      },
    };
    this.rebuildCaseMap();
    dispatch();
  },

  selectCase(caseId) {
    this.setState({ selectedCaseId: caseId });
  },

  //Selected user profile
  selectUserProfile(userProfileId) {
    this.setState({ selectedUserProfileId: userProfileId });
  },

  getSelectedUserProfileId() {
    return store.selectedUserProfileId;
  },

  //Documents
  fetchDocuments() {
    return ApiService.getDocuments(
      store.documentPageSize,
      store.documentQuery
    ).then((documentResponse) => {
      this.setState({ documentMap: documentResponse });
      return documentResponse;
    });
  },

  fetchSingleDocument(id) {
    return ApiService.getSingleDocument(id).then((documentResponse) => {
      this.updateDocumentState(documentResponse);
      return documentResponse;
    });
  },

  async initializeDocuments() {
    this.initializeData(() => this.fetchDocuments(), "documentMap");
  },

  async initializeData(fetchFunc, typeName) {
    if (initialized[typeName]) {
      return;
    }
    try {
      initialized[typeName] = true;
      await fetchFunc();
    } catch (error) {
      initialized[typeName] = false;
      console.log(error.message);
    }
  },

  increaseDocumentPageSize() {
    this.setState({
      documentPageSize: store.documentPageSize + store.documentPageIncrement,
    });
    return this.fetchDocuments();
  },

  resetDocumentPageSize() {
    if (store.documentPageSize <= store.documentPageIncrement) {
      return;
    }

    this.setState({ documentPageSize: store.documentPageIncrement });
    return this.fetchDocuments();
  },

  //Optimistic updating
  updateDocumentState(document) {
    store = {
      ...store,
      documentMap: {
        ...store.documentMap,
        documents: store.documentMap.documents.map((d) => {
          if (d.id === document.id) {
            return document;
          }
          return d;
        }),
        social: store.documentMap.social.map((d) => {
          if (d.id === document.id) {
            return document;
          }
          return d;
        }),
        invoices: store.documentMap.invoices.map((d) => {
          if (d.id === document.id) {
            return document;
          }
          return d;
        }),
        trashed: store.documentMap.trashed.map((d) => {
          if (d.id === document.id) {
            return document;
          }
          return d;
        }),
      },
    };

    this.rebuildDocumentMap();
    dispatch();
  },

  addDocumentsToLabel(documentIds, labelId) {
    this.doAddDocumentsToLabel(documentIds, labelId);
    this.rebuildDocumentMap();
    dispatch();
  },

  doAddDocumentsToLabel(documentIds, labelId) {
    if (!labelId) {
      return;
    }
    let documents = store.documentMap.documents.filter(
      (c) => c.id === documentIds.find((id) => id === c.id)
    );
    if (documents.length === 0) {
      return;
    }

    let label = store.labels.find((l) => l.id === labelId);
    if (!label) {
      return;
    }

    this.addDocumentToLabelReferences(documentIds, label);
    this.addLabelReferenceToDocuments(documentIds, label);
  },

  addDocumentToLabelReferences(documentIds, label) {
    let newLabel = { ...label };
    if (!!newLabel.documentIds) {
      newLabel.documentIds = newLabel.documentIds.concat(documentIds);
    } else {
      newLabel.documentIds = [...documentIds];
    }

    store = {
      ...store,
      labels: store.labels.map((l) => {
        if (l.id !== newLabel.id) {
          return l;
        }
        return newLabel;
      }),
    };
  },

  addLabelReferenceToDocuments(documentIds, label) {
    store = {
      ...store,
      documentMap: {
        ...store.documentMap,
        documents: store.documentMap.documents.map((c) => {
          if (!documentIds.find((id) => id === c.id)) {
            return c;
          }
          return {
            ...c,
            labels: [...c.labels, label],
          };
        }),
      },
    };
  },

  removeDocumentFromLabel(documentId, labelId) {
    store = {
      ...store,
      labels: store.labels.map((l) => {
        if (l.id !== labelId) {
          return l;
        }
        return {
          ...l,
          documentIds: l.documentIds.filter((id) => id !== documentId),
        };
      }),
      documentMap: {
        ...store.documentMap,
        documents: store.documentMap.documents.map((d) => {
          if (d.id !== documentId) {
            return d;
          }
          return {
            ...d,
            labels: d.labels.filter((label) => label.id !== labelId),
          };
        }),
      },
    };

    this.rebuildDocumentMap();
    dispatch();
  },

  //ToDo: Flatten case and document state, so references will be updated automatically
  updateCaseDocumentReferences(document) {
    this.setState({
      caseMap: {
        ...store.caseMap,
        cases: store.caseMap.cases.map((c) => {
          if (!c.documents.find((d) => d.id === document.id)) {
            return c;
          }
          return {
            ...c,
            documents: c.documents.map((doc) => {
              if (doc.id !== document.id) {
                return doc;
              }
              return document;
            }),
          };
        }),
      },
    });
  },

  addDocuments(documentList, labelId = 0) {
    store = {
      ...store,
      documentMap: {
        ...store.documentMap,
        count: store.documentMap.count + documentList.length,
        documents: documentList.concat(store.documentMap.documents),
      },
    };
    if (!!labelId) {
      let label = store.labels.find((l) => l.id === labelId);
      if (!!label) {
        this.addDocumentToLabelReferences(
          documentList.map((d) => d.id),
          label
        );
      }
    }

    dispatch();
  },

  removeDocument(id) {
    store = {
      ...store,
      documentMap: {
        ...store.documentMap,
        documents: store.documentMap.documents.filter((d) => d.id !== id),
        trashed: store.documentMap.trashed.filter((d) => d.id !== id),
      },
    };
    this.rebuildDocumentMap();
    dispatch();
  },

  rebuildDocumentMap() {
    let oldMap = store.documentMap;
    let documentMap = { ...store.documentMap };
    let allDocuments = documentMap.documents
      .concat(documentMap.social)
      .concat(documentMap.invoices)
      .concat(documentMap.trashed);

    documentMap.documents = allDocuments.filter(
      (c) => !c.trashed && c.sectionFileType === 0 && !c.invoice
    );
    documentMap.social = allDocuments.filter(
      (c) => !c.trashed && c.sectionFileType === 1
    );
    documentMap.invoices = allDocuments.filter(
      (c) => !c.trashed && c.sectionFileType === 0 && c.invoice
    );
    documentMap.trashed = allDocuments.filter((c) => c.trashed);

    //Update counts if a document has changed type
    documentMap.count += documentMap.documents.length - oldMap.documents.length;
    documentMap.socialCount += documentMap.social.length - oldMap.social.length;
    documentMap.invoiceCount +=
      documentMap.invoices.length - oldMap.invoices.length;
    documentMap.trashedCount +=
      documentMap.trashed.length - oldMap.trashed.length;

    store = {
      ...store,
      documentMap,
    };
  },

  getDocuments() {
    return store.documentMap.documents
      .concat(store.documentMap.social)
      .concat(store.documentMap.invoices);
  },

  setActiveDocumentId(id) {
    this.setState({ activeDocumentId: id });
  },

  getActiveDocumentId() {
    return store.activeDocumentId;
  },

  //Storage information
  fetchStorageInformation() {
    ApiService.getStorageInformation().then((storageResponse) => {
      this.setState({ storageInformation: storageResponse });
      dispatch();
    });
  },

  //Shared documents
  fetchSharedDocuments() {
    return ApiService.getSharedDocuments().then((documentResponse) => {
      this.setState({ sharedDocuments: documentResponse });
      return documentResponse;
    });
  },

  async initializeCalendarEvents() {
    await this.initializeData(
      () => this.fetchCalendarEvents(),
      "calendarEvents"
    );
  },

  fetchCalendarEvents() {
    return ApiService.getCalendarEvents().then((eventResponse) => {
      this.setState({ calendarEvents: eventResponse });
      return eventResponse;
    });
  },

  async initializeNotification() {
    await this.initializeData(() => this.fetchNotifications(), "notifications");
  },

  fetchNotifications() {
    ApiService.getNotifications().then((notificationResponse) => {
      this.setState({ notifications: notificationResponse });
    });
  },

  async initializeTimeEntries() {
    await this.initializeData(() => this.fetchTimeEntries(), "timeEntryMap");
  },

  fetchTimeEntries() {
    return ApiService.getTimeEntries(store.timeEntryPageSize).then(
      (timeEntryResponse) => {
        this.setState({ timeEntryMap: timeEntryResponse });
      }
    );
  },

  increaseTimeEntryPageSize() {
    this.setState({
      timeEntryPageSize: store.timeEntryPageSize + store.timeEntryPageIncrement,
    });
    return this.fetchTimeEntries();
  },

  resetTimeEntryPageSize() {
    if (store.timeEntryPageSize <= store.timeEntryPageIncrement) {
      return;
    }

    this.setState({ timeEntryPageSize: store.timeEntryPageIncrement });
    return this.fetchTimeEntries();
  },

  //Contacts
  // fetchContacts() {
  //   return ApiService.getContacts(store.contactPageSize, store.contactQuery)
  //   .then( (contactsResponse) => {
  //     this.setState({
  //       contactMap: contactsResponse,
  //       selectedContact: contactsResponse.contacts.find(c => c.id === store.selectedContact.id) || store.selectedContact
  //     });
  //   })
  // },

  // async initializeContacts() {
  //   await this.initializeData(() => this.fetchContacts(), 'contactMap');
  // },

  // increaseContactPageSize() {
  //   this.setState({ contactPageSize: store.contactPageSize + store.contactPageIncrement });
  //   return this.fetchContacts();
  // },

  // resetContactPageSize() {
  //   if(store.contactPageSize <= store.contactPageIncrement) {
  //     return;
  //   }

  //   this.setState({ contactPageSize: store.contactPageIncrement });
  //   return this.fetchContacts();
  // },

  // fetchContact(id) {
  //   return ApiService.getSingleContact(id)
  //   .then( newContact => {
  //     this.updateContactState(newContact);
  //   });
  // },

  fetchMyContactInfo() {
    return ApiService.getMyContact().then((myContactInfo) => {
      this.setState({
        myContactInfo,
      });
    });
  },

  //Optimistic updating / inserting
  updateContactState(contact) {
    if (store.selectedContact.id === contact.id) {
      store.selectedContact = { ...contact };
    }

    let currentContact =
      store.contactMap.contacts.find((c) => c.id === contact.id) ||
      store.contactMap.trashed.find((c) => c.id === contact.id);

    if (!!currentContact) {
      store = {
        ...store,
        contactMap: {
          ...store.contactMap,
          contacts: store.contactMap.contacts.map((c) => {
            if (c.id !== contact.id) {
              return c;
            }
            return { ...contact };
          }),
          trashed: store.contactMap.trashed.map((c) => {
            if (c.id !== contact.id) {
              return c;
            }
            return { ...contact };
          }),
        },
      };
    } else {
      store = {
        ...store,
        contactMap: {
          ...store.contactMap,
          contacts: [{ ...contact }, ...store.contactMap.contacts],
        },
      };
    }

    //Remove trashed contacts from labels
    if (!!currentContact && !currentContact.trashed && contact.trashed) {
      store = {
        ...store,
        labels: store.labels.map((l) => {
          if (!l.clients && !l.clientIds) {
            return l;
          }
          return {
            ...l,
            clients: !l.clients
              ? l.clients
              : l.clients.filter((c) => c.id !== contact.id),
            clientIds: !l.clientIds
              ? l.clientIds
              : l.clientIds.filter((id) => id !== contact.id),
          };
        }),
      };
    }

    // this.rebuildContactMap();
    dispatch();
  },

  removeContact(contactId) {
    //Only trashed contacts can be removed
    this.setState({
      contactMap: {
        ...store.contactMap,
        trashed: store.contactMap.trashed.filter((c) => c.id !== contactId),
      },
    });
  },

  rebuildContactMap() {
    var newMap = { ...store.contactMap };
    var contactMap = store.contactMap;
    var contacts = contactMap.contacts.concat(contactMap.trashed);
    newMap.contacts = contacts.filter((c) => !c.trashed);
    newMap.trashed = contacts.filter((c) => c.trashed);

    //Update counts if a contact has changed type
    newMap.count += newMap.contacts.length - contactMap.contacts.length;
    newMap.trashedCount += newMap.trashed.length - contactMap.trashed.length;

    store = {
      ...store,
      contactMap: newMap,
      selectedContact: !!store.selectedContact.id
        ? contacts.find((c) => c.id === store.selectedContact.id) ||
          store.selectedContact
        : store.selectedContact,
    };
  },

  addContactsToLabel(contactIds, labelId) {
    // let contacts = store.contactMap.contacts.filter(c => c.id === contactIds.find(id => id === c.id) );
    // if(contacts.length === 0) {
    //   return;
    // }
    let label = store.labels.find((l) => l.id === labelId);
    if (!label) {
      return;
    }

    let newLabel = { ...label };
    if (!!newLabel.clientIds) {
      newLabel.clientIds = newLabel.clientIds.concat(contactIds);
    } else {
      newLabel.clientIds = [...contactIds];
    }

    store = {
      ...store,
      labels: store.labels.map((l) => {
        if (l.id !== newLabel.id) {
          return l;
        }
        return newLabel;
      }),
      // contactMap: {
      //   ...store.contactMap,
      //   contacts: store.contactMap.contacts.map(c => {
      //     if(!contactIds.find(id => id === c.id)) {
      //       return c;
      //     }
      //     return {
      //       ...c,
      //       labels: [...c.labels, newLabel]
      //     }
      //   }),
      // }
    };

    // this.rebuildContactMap();
    dispatch();
  },

  removeContactFromLabel(clientId, labelId) {
    store = {
      ...store,
      labels: store.labels.map((l) => {
        if (l.id !== labelId) {
          return l;
        }
        return {
          ...l,
          clientIds: l.clientIds.filter((id) => id !== clientId),
        };
      }),
      // contactMap: {
      //   ...store.contactMap,
      //   contacts: store.contactMap.contacts.map(c => {
      //     if(c.id !== clientId) {
      //       return c;
      //     }
      //     return {
      //       ...c,
      //       labels: c.labels.filter(label => label.id !== labelId)
      //     }
      //   }),
      // }
    };

    // this.rebuildContactMap();
    dispatch();
  },

  //Should only be used for society users, who have a small number of invoices
  fetchAllInvoices() {
    return ApiService.getInvoices().then((invoiceResponse) => {
      this.setState({ invoices: invoiceResponse });
    });
  },

  //Invoices
  fetchInvoices() {
    let query = {
      clientId: store.selectedContact.id || "",
      ...(store.selectedFiscalYearId > 0
        ? { fiscalYearId: store.selectedFiscalYearId }
        : {}),
      ...(!!store.selectedInvoiceStartDate
        ? { startDate: store.selectedInvoiceStartDate.format() }
        : {}),
      ...(!!store.selectedInvoiceEndDate
        ? { endDate: store.selectedInvoiceEndDate.format() }
        : {}),
    };

    return ApiService.getInvoices(query).then((invoiceResponse) => {
      this.setState({ invoices: invoiceResponse });
    });
  },

  fetchSingleInvoice(id) {
    return ApiService.getSingleInvoice(id).then((invoiceResponse) => {
      this.updateArrayObject(invoiceResponse, "invoices");
      return invoiceResponse;
    });
  },

  //Templates
  fetchTemplates() {
    return ApiService.getTemplates().then((templateResponse) => {
      this.setState({ templates: templateResponse });
      return templateResponse;
    });
  },

  async initializeUserProfile(query) {
    await this.initializeData(
      () => this.fetchUserProfile(query),
      "userProfile"
    );
  },

  //UserProfile
  fetchUserProfile() {
    return ApiService.getUserProfile().then((profileResponse) => {
      let oldId = store.userProfile.id;

      store = {
        ...store,
        userProfile: profileResponse,
      };

      if (store.userProfile.id > 0) {
        if (!!oldId && oldId !== store.userProfile.id) {
          this.fetchMessages();
        }
      }

      dispatch();
      return profileResponse;
    });
  },

  //UserProfileArray
  fetchUserProfileArray() {
    ApiService.getUserProfileArray().then((profileResponse) => {
      this.setState({ userProfileArray: profileResponse });
    });
  },

  async initializeUserSettings() {
    await this.initializeData(() => this.fetchUserSettings(), "userSettings");
  },

  //UserSettings
  fetchUserSettings() {
    return ApiService.getUserSettings().then((settingsResponse) => {
      if (settingsResponse.useLocalSettings) {
        settingsResponse = {
          ...settingsResponse,
          ...StorageService.loadItem("localSettings", {}),
        };
      }
      this.setState({
        userSettings: settingsResponse,
      });
      return settingsResponse;
    });
  },

  updateUserSettingsState(userSettings) {
    this.setState({ userSettings });
    if (userSettings.useLocalSettings) {
      StorageService.saveItem("localSettings", {
        emailAccountId: userSettings.emailAccountId,
        useStandardEmailAsInbox: userSettings.useStandardEmailAsInbox,
      });
    }
  },

  async initializeFriends() {
    await this.initializeData(() => this.fetchFriends(), "friends");
  },

  //Friends
  fetchFriends() {
    return ApiService.getFriends().then((friendsResponse) => {
      this.setState({ friends: friendsResponse });
      return friendsResponse;
    });
  },

  async initializeFriendRequests() {
    await this.initializeData(
      () => this.fetchFriendRequests(),
      "friendRequests"
    );
  },

  //Friend requests
  fetchFriendRequests() {
    ApiService.getFriendRequests().then((requestResponse) => {
      this.setState({ friendRequests: requestResponse });
    });
  },

  //Client profiles
  fetchClientProfiles() {
    ApiService.getClientProfiles().then((clientResponse) => {
      this.setState({ clientProfiles: clientResponse });
    });
  },

  //Lawyer profiles
  fetchLawyerProfiles() {
    ApiService.getLawyerProfiles().then((lawyerResponse) => {
      this.setState({ lawyerProfiles: lawyerResponse });
    });
  },

  //Stripe subscription
  fetchUserSubscription() {
    ApiService.getUserSubscription().then((userSubscriptionResponse) => {
      this.setState({
        userSubscription: userSubscriptionResponse,
        cardInformation: userSubscriptionResponse.card,
      });
    });
  },

  //Card information subscription
  // fetchCardInformation() {
  //   ApiService.getCardInformation().then( (cardInformationResponse) => {
  //     this.setState({ cardInformation: cardInformationResponse });
  //   })
  // },

  //Front page scrolling
  setScrollId(scrollIdVar) {
    scrollId = scrollIdVar;
  },

  getScrollId() {
    return scrollId;
  },

  //Admin
  //System users
  fetchSystemUsers() {
    ApiService.getSystemUsers().then((userResponse) => {
      this.setState({ systemUsers: userResponse });
    });
  },

  //Subscription plans
  fetchSubscriptionPlans() {
    ApiService.getSubscriptionPlans().then((planResponse) => {
      this.setState({ subscriptionPlans: planResponse });
    });
  },

  fetchSubscriptionPlansForAdmin() {
    ApiService.getSubscriptionPlansForAdmin().then((planResponse) => {
      this.setState({ subscriptionPlans: planResponse });
    });
  },

  //Selected plan
  setSelectedPlan(plan) {
    this.setState({ selectedPlan: plan });
    StorageService.saveItem("selectedPlan", plan);
  },

  getSelectedPlan() {
    return StorageService.loadItem("selectedPlan", {});
  },

  //Global settings
  fetchGlobalSettings() {
    ApiService.getGlobalSettings().then((settingsResponse) => {
      let globalSettings = { ...store.globalSettings };
      settingsResponse.forEach((setting) => {
        globalSettings[setting.key] = setting.value;
      });

      this.setState({ globalSettings });
    });
  },

  getGlobalSetting(key) {
    return store.globalSettings[key];
  },

  incrementQRCount() {
    if (store.globalSettings.QRCount) {
      var counter = parseInt(store.globalSettings.QRCount, 10);
      counter++;
      store.globalSettings.QRCount = counter.toString();
      this.setState({
        globalSettings: {
          ...store.globalSettings,
          QRCount: counter.toString(),
        },
      });
      ApiService.updateGlobalSetting({
        key: "QRCount",
        value: store.globalSettings.QRCount,
      });
    } else {
      ApiService.createGlobalSetting({ key: "QRCount", value: "1" });
    }
  },

  async initializeUserGroup() {
    await this.initializeData(() => this.fetchUserGroup(), "userGroup");
  },

  //User group
  fetchUserGroup() {
    ApiService.getUserGroup().then((userGroupResponse) => {
      this.setState({ userGroup: userGroupResponse });
    });
  },

  async initializeSharedCases() {
    await this.initializeData(() => this.fetchSharedCases(), "sharedCases");
  },

  //Shared cases
  fetchSharedCases() {
    return ApiService.getSharedCases().then((sharedCasesResponse) => {
      this.setState({ sharedCases: sharedCasesResponse });
    });
  },

  fetchingEmails: false,
  accountNameWhiteList: ["adventureinternational", "ac-dk.dk", "aclaws", "yingxin"], //Hack to keep old email accounts on the list

  //Call sparingly. Very data intensive.
  async fetchEmailsFromAllAccounts() {
    if (this.fetchingEmails) {
      return;
    }

    this.fetchingEmails = true;
    if (store.emailAccounts.length !== 0) {
      for (let account of store.emailAccounts) {
        //Process accounts sequentially
        if (account.accountType === "Gmail" 
          || this.accountNameWhiteList.some(name => account.account.includes(name))) {
          this.setAccountStatus(account.id, EmailAccountStatus.SUCCESS);
          continue; //Gmail currently not supported
        }

        this.setAccountStatus(account.id, EmailAccountStatus.LOADING);
        try {
          let response = await ApiService.getEmailsFromAccount(account.id);
          this.setAccountStatus(
            account.id,
            response.ok
              ? EmailAccountStatus.SUCCESS
              : EmailAccountStatus.FAILURE
          );
        } catch (reason) {
          console.log(reason);
          this.setAccountStatus(account.id, EmailAccountStatus.FAILURE);
        }
      }
    }
    this.fetchingEmails = false;

    this.fetchMessageCounter();
    this.fetchMessages();
  },

  //Optimistic updating
  deleteEmailAccount(accountId) {
    this.setState({
      emailAccounts: store.emailAccounts.filter((a) => a.id !== accountId),
    });
  },

  //Mail settings
  fetchEmailAccounts() {
    return ApiService.getEmailAccounts().then((emailAccountsResponse) => {
      this.setState({ emailAccounts: emailAccountsResponse });
      if (store.emailAccounts.length > 0) {
        this.initializeEmailHub();
      }

      dispatch();
      return store.emailAccounts;
    });
  },

  //Subscription formulas
  fetchSubscriptionFormulas() {
    return ApiService.getSubscriptionFormulas().then(
      (subscriptionFormulasResponse) => {
        this.setState({ subscriptionFormulas: subscriptionFormulasResponse });
      }
    );
  },

  // async initializeMessageCounter() {
  //   await this.initializeData(() => this.fetchMessageCounter(), 'messageCounter');
  // },

  //Message counter
  fetchMessageCounter() {
    return ApiService.getMessageCounter().then((messageCounterResponse) => {
      this.setState({ messageCounter: messageCounterResponse });
      dispatch();
    });
  },

  addLabelToMessage(messageId, labelId) {
    this.setState({
      messageMap: {
        ...store.messageMap,
        messages: store.messageMap.messages.map((m) =>
          m.id !== messageId ? m : { ...m, addedLabel: true }
        ), //Prevents message from being readded to received by the makeMessageMap
        received: store.messageMap.received.filter((m) => m.id !== messageId),
      },
      labels: store.labels.map((l) =>
        l.id !== labelId
          ? l
          : { ...l, messageIds: l.messageIds.concat([messageId]) }
      ),
    });
  },

  //Sharing globals
  fetchSharingGlobals() {
    return ApiService.getSharingGlobals().then((sharingGlobalsResponse) => {
      this.setState({ sharingGlobals: sharingGlobalsResponse });
    });
  },

  //Item prices
  fetchItemPrices() {
    return ApiService.getItemPrices().then((itemPricesResponse) => {
      this.setState({ itemPrices: itemPricesResponse });
    });
  },

  //Item price types
  fetchItemPriceTypes() {
    return ApiService.getItemPriceTypes().then((response) => {
      this.setState({ itemPriceTypes: response });
    });
  },

  //Legal presentations
  fetchLegalPresentations() {
    return ApiService.getLegalPresentations().then((response) => {
      this.setState({ legalPresentations: response });
    });
  },

  //Book keeping
  // fetchBookkeepingDraftEntriesForUser() {
  //   return ApiService.getBookkeepingDraftEntriesForUser()
  //   .then(response => {
  //     this.setState({ bookKeepingDraftEntries: response });
  //   });
  // },

  fetchBookkeepingDraftEntries() {
    return this.doFetchBookkeepingDraftEntries();
  },

  fetchBankReconEntries() {
    return this.doFetchBookkeepingDraftEntries({
      includeBankReconEntries: true,
    });
  },

  doFetchBookkeepingDraftEntries(
    { includeBankReconEntries } = { includeBankReconEntries: false }
  ) {
    // if(!store.selectedContact.id) {
    //   return;
    // }

    let query = {
      clientId: store.selectedContact.id || "",
      filter: "Drafts",
    };

    //Include posted entries to be included in the bank balancing
    if (includeBankReconEntries) {
      query = {
        ...query,
        filter: "BankRecon", //Fetches the draft, in addition to posted entries from the bank recon intervals
        ...(store.selectedFiscalYearId > 0
          ? { fiscalYearId: store.selectedFiscalYearId }
          : {}),
        ...(!!store.selectedFiscalStartDate
          ? { startDate: store.selectedFiscalStartDate.format() }
          : {}),
        ...(!!store.selectedFiscalEndDate
          ? { endDate: store.selectedFiscalEndDate.format() }
          : {}),
      };
    }

    return ApiService.getBookkeepingEntries(query).then((response) => {
      this.setState({ bookKeepingDraftEntries: response });
    });
  },

  fetchPostingEntries() {
    return this.doFetchPostingEntries();
  },

  fetchPostingEntriesWithDrafts() {
    return this.doFetchPostingEntries({
      forceIncludeDraft: true,
      usePostingDates: false,
    });
  },

  //Entries should always be filtered on receipt date.
  // fetchPostingEntriesByPostingDates() {
  //   return this.doFetchPostingEntries({
  //     forceIncludeDraft: false,
  //     usePostingDates: true
  //   });
  // },

  doFetchPostingEntries(
    { forceIncludeDraft, usePostingDates } = {
      forceIncludeDraft: false,
      usePostingDates: false,
    }
  ) {
    if (!store.selectedContact.id) {
      return;
    }

    let query = {
      clientId: store.selectedContact.id,
      filter: store.includeDraft || forceIncludeDraft ? "All" : "Posted",
      ...(store.selectedFiscalYearId > 0
        ? { fiscalYearId: store.selectedFiscalYearId }
        : {}),
      ...(!!store.selectedPostingStartDate
        ? { startDate: store.selectedPostingStartDate.format() }
        : {}),
      ...(!!store.selectedPostingEndDate
        ? { endDate: store.selectedPostingEndDate.format() }
        : {}),
      usePostingDates,
    };

    return ApiService.getBookkeepingEntries(query).then((response) => {
      this.setState({ postingEntries: response });
      return response;
    });
  },

  fetchPostingJournals() {
    let query = {
      clientId: store.selectedContact.id || "",
      ...(store.selectedFiscalYearId > 0
        ? { fiscalYearId: store.selectedFiscalYearId }
        : {}),
      ...(!!store.selectedPostingStartDate
        ? { startDate: store.selectedPostingStartDate.format() }
        : {}),
      ...(!!store.selectedPostingEndDate
        ? { endDate: store.selectedPostingEndDate.format() }
        : {}),
    };

    return ApiService.getPostingJournals(query).then((response) => {
      this.setState({ postingJournals: response });
    });
  },

  //Finance accounts
  //Flattens state into an id map referenced by id arrays.
  fetchFinanceAccountPlans() {
    return ApiService.getFinanceAccountPlans().then((response) => {
      let financeAccounts = {};
      let taxSpecifications = {};
      response.forEach((plan) => {
        financeAccounts = {
          ...financeAccounts,
          ...this.convertArrayToMap(plan.accounts),
        };
        taxSpecifications = {
          ...taxSpecifications,
          ...this.convertArrayToMap(plan.taxSpecifications),
        };
      });

      this.setState({
        financeAccounts,
        taxSpecifications,
        financeAccountPlans: response.map((plan) => {
          return {
            ...plan,
            accounts: plan.accounts.map((f) => f.id),
            taxSpecifications: plan.taxSpecifications.map((t) => t.id),
          };
        }),
      });
    });
  },

  async initializeFinanceAccountPlans() {
    await this.initializeData(
      () => this.fetchFinanceAccountPlans(),
      "financeAccountPlans"
    );
  },

  updateFinanceAccountPlan(plan) {
    let { account, taxSpecifications, ...corePlan } = plan; //Only update core plan information, not navigation properties
    if (store.clientPlan.id === plan.id) {
      this.setState({
        clientPlan: {
          ...corePlan,
          accounts: store.clientPlan.accounts,
          taxSpecifications: store.clientPlan.taxSpecifications,
        },
      });
    } else {
      this.setState({
        financeAccountPlans: store.financeAccountPlans.map((p) => {
          if (p.id !== plan.id) {
            return p;
          }
          return {
            ...corePlan,
            accounts: p.accounts,
            taxSpecifications: p.taxSpecifications,
          };
        }),
      });
    }
  },

  createFinanceAccount(model) {
    if (store.clientPlan.id === model.financeAccountPlanId) {
      this.setState({
        clientPlan: {
          ...store.clientPlan,
          accounts: [...store.clientPlan.accounts, model],
        },
      });
    } else {
      this.setState({
        financeAccounts: {
          ...store.financeAccounts,
          [model.id]: model,
        },
        financeAccountPlans: store.financeAccountPlans.map((plan) => {
          if (plan.id !== model.financeAccountPlanId) {
            return plan;
          }
          return {
            ...plan,
            accounts: [...plan.accounts, model.id],
          };
        }),
      });
    }
  },

  updateFinanceAccount(model) {
    if (!!store.financeAccounts[model.id]) {
      this.updateMapObject(model, "financeAccounts");
    } else {
      this.setState({
        clientPlan: {
          ...store.clientPlan,
          accounts: store.clientPlan.accounts.map((a) => {
            if (a.id !== model.id) {
              return a;
            }
            return model;
          }),
        },
      });
    }
  },

  removeFinanceAccount(planId, accountId) {
    this.setState({
      financeAccountPlans: store.financeAccountPlans.map((plan) => {
        if (plan.id !== planId) {
          return plan;
        }
        return {
          ...plan,
          accounts: plan.accounts.filter((accId) => accId !== accountId),
        };
      }),
      clientPlan: {
        ...store.clientPlan,
        accounts: store.clientPlan.accounts.filter((a) => a.id !== accountId),
      },
    });
  },

  fetchStandardFinanceAccountPlans() {
    return ApiService.getStandardFinanceAccountPlans().then((response) => {
      this.setState({ standardFinanceAccountPlans: response });
    });
  },

  async fetchClientPlan() {
    if (!store.selectedContact.id) {
      this.setState({
        clientPlan: { accounts: [], taxSpecifications: [] },
      });
      return {};
    }

    let result = await ApiService.getClientPlan(store.selectedContact.id);
    if (!result.id) {
      //Client has no plan
      this.setState({
        clientPlan: { accounts: [], taxSpecifications: [] },
      });
    } else {
      this.setState({
        clientPlan: result,
      });
    }

    return result;
  },

  fetchFiscalYears() {
    if (!store.selectedContact.id) {
      this.setState({ fiscalYears: [] });
      return Promise.resolve([]);
    }

    return ApiService.getFiscalYears(store.selectedContact.id).then(
      (response) => {
        this.setState({ fiscalYears: response });

        if (store.fiscalYears.length === 0) {
          this.resetFiscalYear();
        } else if (
          !store.selectedFiscalYearId ||
          !store.fiscalYears.find((f) => f.id === store.selectedFiscalYearId)
        ) {
          this.selectFiscalYear(store.fiscalYears[0].id);
        }
        return response;
      }
    );
  },

  selectFiscalYear(fiscalYearId) {
    let fiscalYear = store.fiscalYears.find((f) => f.id === fiscalYearId);
    if (!fiscalYear) {
      return;
    }

    //Set posting date interval to the current month during the fiscal year
    // let yearEnd = new Date(fiscalYear.endDate);
    // let month = (new Date()).getMonth();
    // var selectedPostingStartDate = moment(new Date(yearEnd.getFullYear(), month));
    // var selectedPostingEndDate = moment(new Date(yearEnd.getFullYear(), month + 1));
    // if(selectedPostingStartDate > moment(yearEnd)) {
    //   selectedPostingStartDate.subtract(1, 'years')
    //   selectedPostingEndDate.subtract(1, 'years')
    // }

    this.setState({
      selectedFiscalYearId: fiscalYearId,
      selectedFiscalStartDate: null,
      selectedFiscalEndDate: null,
      selectedInvoiceStartDate: null,
      selectedInvoiceEndDate: null,
      selectedPostingStartDate: null,
      selectedPostingEndDate: null,
    });
  },

  resetFiscalYear() {
    this.setState({
      selectedFiscalYearId: 0,
      selectedFiscalStartDate: null,
      selectedFiscalEndDate: null,
      selectedInvoiceStartDate: moment.utc().startOf("year"),
      selectedInvoiceEndDate: moment.utc().endOf("year"),
      selectedPostingStartDate: null,
      selectedPostingEndDate: null,
    });
  },

  fetchAccountingReportWithDraft() {
    this.fetchAccountingReport({ forceIncludeDraft: true });
  },

  fetchAccountingReport({ forceIncludeDraft } = { forceIncludeDraft: false }) {
    if (!store.selectedContact.id || !store.selectedFiscalYearId) {
      this.setState({ accountingReport: {} });
      return Promise.resolve();
    }

    var query = {
      clientId: store.selectedContact.id,
      filter: forceIncludeDraft || store.includeDraft ? "All" : "Posted",
      ...(store.selectedFiscalYearId > 0
        ? { fiscalYearId: store.selectedFiscalYearId }
        : {}),
      ...(!!store.selectedFiscalStartDate
        ? { startDate: store.selectedFiscalStartDate.format() }
        : {}),
      ...(!!store.selectedFiscalEndDate
        ? { endDate: store.selectedFiscalEndDate.format() }
        : {}),
    };

    return ApiService.getAccountingReport(query).then((response) => {
      this.setState({ accountingReport: response });
      return response;
    });
  },

  fetchVatReport() {
    if (!store.selectedContact.id || !store.selectedFiscalYearId) {
      this.setState({ vatReport: { current: [], settled: [] } });
      return Promise.resolve();
    }

    var query = {
      clientId: store.selectedContact.id,
      filter: store.includeDraft ? "All" : "Posted",
      ...(store.selectedFiscalYearId > 0
        ? { fiscalYearId: store.selectedFiscalYearId }
        : {}),
    };

    return ApiService.getVatReport(query).then((response) => {
      this.setState({
        vatReportArray: response.current,
        vatReport: response,
      });
      return response;
    });
  },

  fetchDraftSimulation(selectedEntryIds = []) {
    return this.fetchDraftSimulationWithoutSaving(selectedEntryIds).then(
      (response) => {
        this.setState({ draftSimulationResult: response });
        return response;
      }
    );
  },

  fetchDraftSimulationWithoutSaving(selectedEntryIds = []) {
    if (!store.selectedContact.id) {
      return Promise.resolve({
        draftSimulationResult: { accountResults: {}, balance: 0 },
      });
    }

    let query = {
      clientId: store.selectedContact.id,
      ...(store.selectedFiscalYearId > 0
        ? { fiscalYearId: store.selectedFiscalYearId }
        : {}),
      ...(!!store.selectedFiscalStartDate
        ? { startDate: store.selectedFiscalStartDate.format() }
        : {}),
      ...(!!store.selectedFiscalEndDate
        ? { endDate: store.selectedFiscalEndDate.format() }
        : {}),
    };

    let entryIds =
      selectedEntryIds.length > 0
        ? selectedEntryIds
        : [...store.importantEntryIds];
    return ApiService.getDraftSimulation(query, entryIds);
  },

  fetchBankPostings() {
    if (!store.selectedContact.id) {
      return Promise.resolve();
    }

    var query = {
      clientId: store.selectedContact.id,
      ...(store.selectedFiscalYearId > 0
        ? { fiscalYearId: store.selectedFiscalYearId }
        : {}),
      ...(!!store.selectedFiscalStartDate
        ? { startDate: store.selectedFiscalStartDate.format() }
        : {}),
      ...(!!store.selectedFiscalEndDate
        ? { endDate: store.selectedFiscalEndDate.format() }
        : {}),
    };

    return ApiService.getBankPostings(query).then((response) => {
      this.setState({ bankPostings: response });
      return response;
    });
  },

  updateBankImportSettings(newSetings) {
    this.setState({
      bankImportSettings: {
        ...store.bankImportSettings,
        ...newSetings,
      },
    });

    StorageService.saveItem("bankImportSettings", {
      ...store.bankImportSettings,
      ...newSetings,
    });
  },

  addReconciliation(reconciliation = { entryId: 0, bankPostingId: 0 }) {
    this.setState({
      bookKeepingDraftEntries: store.bookKeepingDraftEntries.map((entry) => {
        if (entry.id !== reconciliation.entryId) {
          return entry;
        }
        return {
          ...entry,
          bankPostingId: reconciliation.bankPostingId,
        };
      }),
      bankPostings: store.bankPostings.map((posting) => {
        if (posting.id !== reconciliation.bankPostingId) {
          return posting;
        }
        return {
          ...posting,
          entryId: reconciliation.entryId,
        };
      }),
    });
  },

  removeReconciliation(reconciliation = { entryId: 0, bankPostingId: 0 }) {
    this.setState({
      bookKeepingDraftEntries: store.bookKeepingDraftEntries.map((entry) => {
        if (entry.id !== reconciliation.entryId) {
          return entry;
        }

        return {
          ...entry,
          bankPostingId: null,
        };
      }),
      bankPostings: store.bankPostings.map((posting) => {
        if (posting.id !== reconciliation.bankPostingId) {
          return posting;
        }

        return {
          ...posting,
          entryId: null,
        };
      }),
    });
  },

  async fetchTrashCount() {
    let count = await ApiService.getTrashCount();
    this.setState({ trashCount: parseInt(count) });
  },

  incrementTrashCount() {
    this.setState({ trashCount: store.trashCount + 1 });
  },

  decrementTrashCount() {
    this.setState({ trashCount: store.trashCount - 1 });
  },

  //Publish/subscribe
  subscribe(subscriberName, callback) {
    this.unsubscribe(subscriberName); //Clear existing
    storeSubscribers.push({
      name: subscriberName,
      callback: callback,
    });
    callback(store);
  },

  unsubscribe(subscriberName) {
    storeSubscribers = storeSubscribers.filter(
      (subscription) => subscription.name !== subscriberName
    );
  },

  //Getters and setters
  setShoppingCart(shoppingCart) {
    this.setState({ shoppingCart: shoppingCart });
    StorageService.saveItem("shoppingCart", store.shoppingCart);
  },

  setShowDropDowns(value) {
    this.setState({ showDropdowns: value });
    StorageService.saveItem("showDropdowns", value.toString());
  },

  setMessageQuery(messageQuery) {
    this.setState({ messageQuery });
  },

  setTheme(theme) {
    this.setState({ theme });
    StorageService.saveValue("theme", theme);
  },

  setScanReceipts(value) {
    this.setState({ scanReceipts: value });
    StorageService.saveItem("scanReceipts", value.toString());
  },

  setSplitPDF(value) {
    this.setState({ splitPDF: value });
    StorageService.saveItem("splitPDF", value.toString());
  },

  setConvertToPDF(convertToPDF) {
    this.setState({ convertToPDF });
    StorageService.saveItem("convertToPDF", convertToPDF.toString());
  },

  setTrustedEmails(list) {
    this.setState({ trustedEmails: list });
    StorageService.saveItem("trustedEmails", list);
  },

  setAutoAccounts(autoAccounts) {
    this.setState({ autoAccounts });
    StorageService.saveItem("autoAccounts", autoAccounts);
  },

  setcalculateNumbers(calculateNumbers) {
    this.setState({ calculateNumbers });
    StorageService.saveItem("calculateNumbers", calculateNumbers);
  },

  setSelectedContact(selectedContact) {
    if (selectedContact.id !== store.selectedContact.id) {
      this.setState({
        fiscalYears: null,
        bookKeepingDraftEntries: [],
        selectedContact,
      });
    } else {
      this.setState({ selectedContact });
    }

    setSelectedContactRedux(selectedContact); //Synchronize with Redux until DataStore can be removed
    StorageService.saveSessionItem("selectedContact", selectedContact);
  },

  updateBankProgress(newSettings) {
    let bankProgress = {
      ...store.bankProgress,
      ...newSettings,
    };
    this.setState({ bankProgress });
    StorageService.saveItem("bankProgress", bankProgress);
  },

  updateBankBalancingData(newData) {
    let bankBalancingData = {
      ...store.bankBalancingData,
      ...newData,
    };
    this.setState({ bankBalancingData });
    StorageService.saveItem("bankBalancingData", bankBalancingData);
  },

  //Standard data handling functions
  convertArrayToMap(array) {
    let map = {};
    array.forEach((element) => {
      map[element.id] = element;
    });
    return map;
  },

  updateProperty(propertyName, newValue) {
    this.updatePropertyWithoutDispatch(propertyName, newValue);
    dispatch();
  },

  updatePropertyWithoutDispatch(propertyName, newValue) {
    store = {
      ...store,
      [propertyName]: newValue,
    };
  },

  setStateWithoutDispatch(newProperties) {
    store = {
      ...store,
      ...newProperties,
    };
  },

  setState(newProperties) {
    this.setStateWithoutDispatch(newProperties);
    dispatch();
  },

  updateMapObject(newObject, typeName) {
    this.setState({
      [typeName]: {
        ...store[typeName],
        [newObject.id]: newObject,
      },
    });
  },

  updateArrayObject(newObject, arrayName, insertIndex) {
    let currentObject = store[arrayName].find((o) => o.id === newObject.id);
    //Update or insert
    if (!!currentObject) {
      this.setState({
        [arrayName]: store[arrayName].map((entry) => {
          if (entry.id !== newObject.id) {
            return entry;
          }
          return newObject;
        }),
      });
    } else {
      if (!!insertIndex) {
        this.setState({
          [arrayName]: [
            ...store[arrayName].slice(0, insertIndex),
            newObject,
            ...store[arrayName].slice(insertIndex),
          ],
        });
      } else {
        this.setState({
          [arrayName]: [newObject, ...store[arrayName]],
        });
      }
    }
  },

  removeArrayObject(id, typeName) {
    this.setState({
      [typeName]: store[typeName].filter((o) => o.id !== id),
    });
  },
  //BusinessOperating
  async fetchBusinessOperatings() {
    let data = await ApiService.getBusinessOperating();
    this.setState({ businessOperatings: data });
  },
  //applicationUD
  async fetchApplicationUDs() {
    let data = await ApiService.getApplicationUD();
    this.setState({ applicationUDs: data });
  },
  //PowerOfAttorney
  async fetchPowerOfAttorneys() {
    let data = await ApiService.getPowerOfAttorney();
    this.setState({ powerOfAttorneys: data });
  },
  // EmploymentContract
  async fetchEmploymentContracts() {
    let data = await ApiService.getEmploymentContract();
    this.setState({ employmentContracts: data });
  },

  //Products
  async fetchProducts() {
    let data = await ApiService.getProducts();
    this.setState({ products: data });
  },

  //Template function
  async fetchModelTemplates() {
    let data = await ApiService.getModelTemplate();
    this.setState({ modelTemplates: data });
  },
};

ReduxStore.subscribe(() => {
  //Synchronize with redux until DataStore can be removed entirely
  let selectedContact = ReduxStore.getState().contactData.selectedContact;
  if (selectedContact.id === store.selectedContact.id) {
    return;
  }

  DataStore.setStateWithoutDispatch({ selectedContact });
});

DataStore.fetchDocumentsDebounced = debounce(() => {
  DataStore.fetchDocuments();
}, 350);

DataStore.fetchSharedDocumentsDebounced = debounce(() => {
  DataStore.fetchSharedDocuments();
}, 350);

DataStore.fetchNotificationsDebounced = debounce(() => {
  DataStore.fetchNotifications();
}, 350);

// DataStore.fetchContactsDebounced = debounce(() => {
//   DataStore.fetchContacts();
// }, 350);

DataStore.fetchInvoicesDebounced = debounce(() => {
  DataStore.fetchInvoices();
}, 350);

DataStore.fetchTemplatesDebounced = debounce(() => {
  DataStore.fetchTemplates();
}, 350);

DataStore.fetchFriendRequestsDebounced = debounce(() => {
  DataStore.fetchFriendRequests();
}, 350);

DataStore.fetchMessageCounterDebounced = debounce(() => {
  DataStore.fetchMessageCounter();
}, 350);

DataStore.fetchFinanceAccountPlansDebounced = debounce(() => {
  DataStore.fetchFinanceAccountPlans();
}, 350);

DataStore.fetchStandardFinanceAccountPlansDebounced = debounce(() => {
  DataStore.fetchStandardFinanceAccountPlans();
}, 350);

DataStore.fetchCasesDebounced = debounce((query) => {
  DataStore.fetchCases(query);
}, 350);

DataStore.fetchLabelsDebounced = debounce(() => {
  DataStore.fetchLabels();
}, 350);

DataStore.fetchBookkeepingDraftEntriesDebounced = debounce(() => {
  DataStore.fetchBookkeepingDraftEntries();
}, 350);

DataStore.fetchMessagesDebounced = debounce((query) => {
  DataStore.fetchMessages(query);
}, 350);

DataStore.fetchFiscalYearsDebounced = debounce(() => {
  DataStore.fetchFiscalYears();
}, 350);

DataStore.fetchCalendarEventsDebounced = debounce(() => {
  DataStore.fetchCalendarEvents();
}, 350);

export default DataStore;
