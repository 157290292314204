import React from "react";
import PropTypes from "prop-types";
import LexButton from "./LexButton";
import Icon from "./Icon";
import LoadingIcon from "./LoadingIcon";

export default class UploadButton extends React.PureComponent {
  static propTypes = {
    onlyImages: PropTypes.bool,
    onSelected: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.input = React.createRef();
  }

  onUpload = () => {
    this.input.current.click();
  };

  onFilesSelected = async (event) => {
    let { allowMultiple, onSelected } = this.props;

    this.setState({ loading: true });

    var control = event.target;
    let result = {};
    if (allowMultiple) {
      result = await onSelected(event.target.files);
    } else {
      result = await onSelected(event.target.files[0]);
    }
    control.value = ""; //Allows for selecting the same file twice

    this.setState({ loading: false });

    return result;
  };

  render() {
    let {
      onlyImages,
      id,
      children,
      onSelected,
      disabled,
      allowMultiple,
      asIcon,
      ...buttonProps
    } = this.props;

    let { loading } = this.state;

    return (
      <>
        {asIcon ? (
          <>
            {loading ? (
              <LoadingIcon show={true} />
            ) : (
              <Icon
                glyph="icon-fontello-upload-4"
                className="editable"
                role="button"
                onClick={this.onUpload}
              />
            )}
          </>
        ) : (
          <LexButton
            {...buttonProps}
            disabled={loading || disabled}
            onClick={this.onUpload}
          >
            {children} <LoadingIcon show={loading} />
          </LexButton>
        )}

        <input
          ref={this.input}
          onChange={this.onFilesSelected}
          type="file"
          multiple={allowMultiple}
          accept={onlyImages ? "image/*" : ""}
          style={{ display: "none" }}
        />
      </>
    );
  }
}
