import React from "react";
import Conditional from "../../utilities/Conditional";
import {
  CountryDropdown,
  RegionDropdown,
} from "../../utilities/CountrySelect/Dropdowns";
import checkdigit from "checkdigit";
import moment from "moment";
// import Select from 'react-select';

import { PanelGroup } from "react-bootstrap";

import {
  ApiService,
  ModalService,
  RoutingService,
  AuthorizationService,
  ContactService,
} from "../../services/AxoServices";

import {
  ClientSearchBox,
  LexButton,
  AccordionUtils,
  Icon,
  AxoLocal,
  ReactOption,
  withRouter,
  AxoDateTime,
  AsyncButton,
} from "../../utilities/LexUtilities";

import {
  Panel,
  Row,
  Col,
  Grid,
  Button,
  ButtonToolbar,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  InputGroup,
} from "react-bootstrap";

class ContactCreateView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contactViewModel: this.makeNewContactViewModel(),
      existingContact: {}, //For change comparisons when editing existing contacts
      validationState: {
        existingClientAccount: false,
        existingForeignClientAccount: false,
        duplicateEmail: false,
        invalidFirstName: false,
        invalidUserName: false,
      },
      clientCreated: false,
      accountMail: "",
      accountConfirmMail: "",
      vatCountryCode: props.userProfile.country,
      detailedCVRInfo: {},
    };

    this.existingAccountName = "";
  }

  makeValidationState = () => {
    return {
      existingClientAccount: false,
      existingForeignClientAccount: false,
      duplicateEmail: false,
      invalidFirstName: false,
      invalidUserName: false,
    };
  };

  makeNewContactViewModel = () => {
    let { userProfile } = this.props;

    return {
      firstName: "",
      lastName: "",
      companyName: "",
      spouse: "",
      birtDate: moment.utc(),
      identityCode: "",
      description: "",
      clientNumber: "",
      address: "",
      city: "",
      postalCode: "",
      country: userProfile.country,
      region: userProfile.region,
      eMail: "",
      phone: "",
      mobile: "",
      facebook: "",
      linkedIn: "",
      twitter: "",
      weChat: "",
      type: 0,
      clientType: 0,
      clientUserName: "",
      clientMessage: "",
      skipReceiptScan: true,
    };
  };

  onClientTypeChange = (event) => {
    this.onClientPropertyValueChange(
      "clientType",
      parseInt(event.target.value, 10)
    );
  };

  onClientPropertyChange = (propertyName, event) => {
    this.onClientPropertyValueChange(propertyName, event.target.value);
  };

  onClientPropertyValueChange = (propertyName, value) => {
    this.setState({
      contactViewModel: {
        ...this.state.contactViewModel,
        [propertyName]: value,
      },
    });
  };

  onGenerateClientNumber = () => {
    var contact = this.state.contactViewModel;
    if (contact.firstName.length < 2 || contact.lastName.length < 2) {
      return;
    }
    var clientNumber =
      contact.firstName.substring(0, 1) + contact.lastName.substring(0, 1);
    var today = new Date();
    clientNumber +=
      today.getDate().toString() + (today.getMonth() + 1).toString();
    clientNumber += Math.floor(Math.random() * 1000);
    contact.clientNumber = clientNumber;
    this.setState({ contactViewModel: contact });
  };

  handleSelectedClient = (client) => {
    var selectedClient = Object.assign({}, client);
    this.setState({
      existingContact: client,
      contactViewModel: selectedClient,
    });
  };

  validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  getEmailValidationState = (email) => {
    if (email.length === 0) {
      return null;
    }
    return this.validateEmail(email) ? "success" : "error";
  };

  getConfirmEmailValidationState = (email) => {
    let { accountMail } = this.state;
    if (accountMail.length === 0) {
      return null;
    }
    return accountMail === email ? "success" : "error";
  };

  onClearMail = () => {
    let { validationState } = this.state;
    this.setState({
      accountMail: "",
      accountConfirmMail: "",
      validationState: {
        ...validationState,
        existingForeignClientAccount: false,
      },
    });
  };

  // onAddExistingClientAccount = () => {
  //   ApiService.getClientProfileFromEmail(this.state.accountMail)
  //     .then(this.parseClientProfile)
  //     .then(this.createFriendRequest)
  //     .then(this.createClient)
  //     .catch(reason => {
  //       console.log(reason);
  //     })
  // }

  // parseClientProfile = (response) => {
  //   if(response.status === 200) {
  //     return response.json();
  //   }
  //   return Promise.reject("Could not find client profile");
  // }

  // createFriendRequest = (clientProfile) => {
  //   var friendRequest = {
  //     receiver: clientProfile
  //   };
  //   this.existingAccountName = clientProfile.userName;
  //   return ApiService.createFriendRequest(friendRequest);
  // }

  // createClient = (response) => {
  //   if(response.status === 200) {
  //     var contact = Object.assign({}, this.state.contactViewModel);
  //     contact.clientUserName = this.existingAccountName;
  //     this.createOrUpdateContact(contact);
  //   }
  //   else {
  //     return Promise.reject("Could not send friend request");
  //   }
  // }

  selectCountry(val) {
    var viewModel = Object.assign({}, this.state.contactViewModel);
    viewModel.country = val;
    this.setState({ contactViewModel: viewModel });
  }

  selectRegion(val) {
    var viewModel = Object.assign({}, this.state.contactViewModel);
    viewModel.region = val;
    this.setState({ contactViewModel: viewModel });
  }

  isValidCVR = (cvr) => {
    //Danish CVR has 8 characters and Norwegian has 9 characters
    if (cvr.length !== 8 && cvr.length !== 9) {
      return;
    }
    //Check that the CVR number fullfills the modulus 11 rule
    var isValid = checkdigit.mod11.isValid(cvr);
    return isValid;
  };

  onFetchCVR = (countryCode) => {
    let contact = Object.assign({}, this.state.contactViewModel);
    if (!this.isValidCVR(contact.identityCode)) {
      return;
    }

    ApiService.getCVRInformation(contact.identityCode, countryCode)
      .then((companyInfo) => {
        contact.clientType = 1; //Business
        contact.firstName = companyInfo.name;
        contact.companyName = companyInfo.name;
        contact.description = companyInfo.companydesc;
        contact.address = companyInfo.address;
        contact.city = companyInfo.city;
        contact.postalCode = companyInfo.zipcode;
        contact.eMail = companyInfo.email || "";
        contact.phone = companyInfo.phone || "";
        contact.lastName = "";
        if (companyInfo.owners && companyInfo.owners.length) {
          contact.lastName = "";
          companyInfo.owners.forEach((owner) => {
            if (contact.lastName) {
              contact.lastName += " & ";
            }
            contact.lastName += owner.name;
          });
        }
        this.setState({
          contactViewModel: contact,
        });
        return companyInfo;
      })
      .then(() => {
        if (countryCode === "dk") {
          return ApiService.getDetailedCVRInformation(contact.identityCode);
        }
        return Promise.resolve({});
      })
      .then(this.extractDetailedCompanyInfo);
  };

  extractDetailedCompanyInfo = (detailedCompanyInfo) => {
    if (!detailedCompanyInfo.hits || detailedCompanyInfo.hits.hits.length < 1) {
      return;
    }

    let companyInfo = detailedCompanyInfo.hits.hits[0]._source.Vrvirksomhed;
    let extractedInfo = {};
    let meta = companyInfo.virksomhedMetadata;
    let adr = meta.nyesteBeliggenhedsadresse;
    extractedInfo.name = meta.nyesteNavn.navn;
    extractedInfo.address = adr.vejnavn + " " + adr.husnummerFra;
    extractedInfo.postalCode = adr.postnummer;
    extractedInfo.city = adr.postdistrikt;
    extractedInfo.startDate = meta.stiftelsesDato;
    extractedInfo.type =
      meta.nyesteVirksomhedsform.langBeskrivelse +
      " " +
      meta.nyesteVirksomhedsform.kortBeskrivelse;
    extractedInfo.adProtection = companyInfo.reklamebeskyttet;
    extractedInfo.status = meta.sammensatStatus;

    extractedInfo.phone = this.extractContactInfo(companyInfo.telefonNummer);
    extractedInfo.email = this.extractContactInfo(companyInfo.elektroniskPost);
    extractedInfo.website = this.extractContactInfo(companyInfo.hjemmeside);

    let attributes = companyInfo.attributter;

    extractedInfo.whiteWash = !!attributes.find(
      (a) => a.type === "OMFATTET_AF_LOV_OM_HVIDVASK_OG_TERRORFINANSIERING"
    );
    extractedInfo.municipality = adr.kommune.kommuneNavn;
    extractedInfo.industryCode =
      meta.nyesteHovedbranche.branchekode +
      " " +
      meta.nyesteHovedbranche.branchetekst;
    extractedInfo.biIndustries = [];
    if (meta.nyesteBibranche1) {
      extractedInfo.biIndustries.push(
        meta.nyesteBibranche1.branchekode +
          " " +
          meta.nyesteBibranche1.branchetekst
      );
    }
    if (meta.nyesteBibranche2) {
      extractedInfo.biIndustries.push(
        meta.nyesteBibranche2.branchekode +
          " " +
          meta.nyesteBibranche2.branchetekst
      );
    }
    if (meta.nyesteBibranche3) {
      extractedInfo.biIndustries.push(
        meta.nyesteBibranche3.branchekode +
          " " +
          meta.nyesteBibranche3.branchetekst
      );
    }

    extractedInfo.purpose = this.extractAttributeValue(attributes, "FORMÅL");

    extractedInfo.biNames = [];
    if (companyInfo.binavne && companyInfo.binavne.length > 0) {
      extractedInfo.biNames = companyInfo.binavne.map((b) => b.navn);
    }
    extractedInfo.financialYearStart = this.extractAttributeValue(
      attributes,
      "REGNSKABSÅR_START"
    );
    extractedInfo.financialYearEnd = this.extractAttributeValue(
      attributes,
      "REGNSKABSÅR_SLUT"
    );
    extractedInfo.statuteDate = this.extractAttributeValue(
      attributes,
      "VEDTÆGT_SENESTE"
    );
    extractedInfo.capitalClasses = this.extractAttributeValue(
      attributes,
      "KAPITALKLASSER"
    );
    extractedInfo.capital = this.extractAttributeValue(attributes, "KAPITAL");
    extractedInfo.firstFinancialYearStart = this.extractAttributeValue(
      attributes,
      "FØRSTE_REGNSKABSPERIODE_START"
    );
    extractedInfo.firstFinancialYearEnd = this.extractAttributeValue(
      attributes,
      "FØRSTE_REGNSKABSPERIODE_SLUT"
    );
    extractedInfo.firstFinancialYearEnd = this.extractAttributeValue(
      attributes,
      "FØRSTE_REGNSKABSPERIODE_SLUT"
    );
    extractedInfo.signatory = this.extractAttributeValue(
      attributes,
      "TEGNINGSREGEL"
    );

    extractedInfo.directors = this.extractParticipants(
      companyInfo.deltagerRelation,
      "LEDELSESORGAN",
      "Direktion"
    );
    extractedInfo.founders = this.extractParticipants(
      companyInfo.deltagerRelation,
      "STIFTERE"
    );
    extractedInfo.legalOwners = this.extractParticipants(
      companyInfo.deltagerRelation,
      "REGISTER",
      "EJERREGISTER"
    );
    extractedInfo.realOwners = this.extractParticipants(
      companyInfo.deltagerRelation,
      "REGISTER",
      "Reelle ejere"
    );

    this.setState({ detailedCVRInfo: extractedInfo });
  };

  extractContactInfo(contactInfoes) {
    if (!contactInfoes || contactInfoes.length === 0) {
      return "";
    }

    let newestNonSecretContact = contactInfoes
      .slice()
      .reverse()
      .find((c) => !c.hemmelig);

    if (!newestNonSecretContact) {
      return "";
    }

    return newestNonSecretContact.kontaktoplysning;
  }

  extractAttributeValue = (attributes, name) => {
    let attribute = attributes.find((a) => a.type === name);
    if (!attribute || !attribute.vaerdier || attribute.vaerdier.length === 0) {
      return "";
    }
    return attribute.vaerdier[attribute.vaerdier.length - 1].vaerdi;
  };

  extractParticipants = (relation, type, subType) => {
    let relations = relation.filter((r) =>
      r.organisationer.find((o) => {
        if (o.hovedtype !== type) {
          return false;
        }
        if (subType && !o.organisationsNavn.find((n) => n.navn === subType)) {
          return false;
        }
        return true;
      })
    );

    return relations.map((r) => {
      let adr = r.deltager.beliggenhedsadresse[0];
      let navn = r.deltager.navne[0].navn;
      let relation = {
        name: navn || "",
      };
      if (!!adr) {
        relation = {
          ...relation,
          address: adr.vejnavn + " " + adr.husnummerFra,
          postalCode: adr.postnummer,
          city: adr.postdistrikt,
        };
      }
      return relation;
    });
  };

  getEUVatInformation = () => {
    var { vatCountryCode } = this.state;

    var countryCode = vatCountryCode.toLowerCase();
    if (countryCode === "dk" || countryCode === "no") {
      //Use the more detailed Scandinavian API
      this.onFetchCVR(countryCode);
      return;
    }

    let contact = { ...this.state.contactViewModel };
    ApiService.getCompanyInfo(countryCode, contact.identityCode).then(
      (companyInfo) => {
        contact.clientType = 1; //Business
        contact.firstName = companyInfo.name[0];
        contact.companyName = companyInfo.name[0];
        contact.address = companyInfo.address[0].replace(/\n/g, " ");

        this.setState({
          contactViewModel: contact,
        });
      }
    );
  };

  // onFinanceAccountPlanSelect = (selectedPlan) => {
  //   if(!selectedPlan) {
  //     return;
  //   }
  //   this.onClientPropertyValueChange('financeAccountPlanId', selectedPlan.value );
  // }

  renderClientForm() {
    let { contactMap, userProfile } = this.props;
    let { existingContact, vatCountryCode, detailedCVRInfo, validationState } =
      this.state;

    let contact = this.state.contactViewModel;
    let userType = AuthorizationService.getUserType(userProfile);
    let existingUserName = !!existingContact.clientUserName; //User name cannot be changed once the account has been created

    return (
      <Row
        className="backgroundlineargradient3"
        style={{ paddingLeft: "10px" }}
      >
        <Col md={6} style={{ textAlign: "left", fontSize: "100%" }}>
          <Form horizontal style={{ marginBottom: 25 }} autoComplete="on">
            {userType !== "Society" ? (
              <>
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      entity="vatCountryCode30"
                      defaultValue={"EU land"}
                    />{" "}
                  </Col>
                  <Col sm={9}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-th-outline" />
                      </InputGroup.Addon>
                      <FormControl
                        value={vatCountryCode}
                        onChange={(event) => {
                          this.setState({ vatCountryCode: event.target.value });
                          this.selectCountry(event.target.value);
                        }}
                        componentClass="select"
                      >
                        <AxoLocal
                          componentClass={ReactOption}
                          value="DK"
                          componentAttribute="text"
                          entity="vatCountryCode1"
                          defaultValue={"DK-Danmark"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="AT"
                          componentAttribute="text"
                          entity="vatCountryCode2"
                          defaultValue={"AT-Østrig"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="BE"
                          componentAttribute="text"
                          entity="vatCountryCode3"
                          defaultValue={"BE-Belgien"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="BG"
                          componentAttribute="text"
                          entity="vatCountryCode4"
                          defaultValue={"BG-Bulgarien"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="CY"
                          componentAttribute="text"
                          entity="vatCountryCode5"
                          defaultValue={"CY-Cypern"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="GZ"
                          componentAttribute="text"
                          entity="vatCountryCode6"
                          defaultValue={"CZ-Tjekkiet"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="DE"
                          componentAttribute="text"
                          entity="vatCountryCode7"
                          defaultValue={"DE-Tyskland"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="EE"
                          componentAttribute="text"
                          entity="vatCountryCode8"
                          defaultValue={"EE-Estland"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="EL"
                          componentAttribute="text"
                          entity="vatCountryCode9"
                          defaultValue={"EL-Grækenland"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="ES"
                          componentAttribute="text"
                          entity="vatCountryCode10"
                          defaultValue={"ES-Spanien"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="FI"
                          componentAttribute="text"
                          entity="vatCountryCode11"
                          defaultValue={"FI-Finland"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="FR"
                          componentAttribute="text"
                          entity="vatCountryCode12"
                          defaultValue={"FR-Frankrig"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="GB"
                          componentAttribute="text"
                          entity="vatCountryCode13"
                          defaultValue={"GB-Storbritannien"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="HR"
                          componentAttribute="text"
                          entity="vatCountryCode14"
                          defaultValue={"HR-Kroatien"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="HU"
                          componentAttribute="text"
                          entity="vatCountryCode15"
                          defaultValue={"HU-Ungarn"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="IE"
                          componentAttribute="text"
                          entity="vatCountryCode16"
                          defaultValue={"IE-Irland"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="IT"
                          componentAttribute="text"
                          entity="vatCountryCode17"
                          defaultValue={"IT-Italien"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="LT"
                          componentAttribute="text"
                          entity="vatCountryCode18"
                          defaultValue={"LT-Litauen"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="LU"
                          componentAttribute="text"
                          entity="vatCountryCode19"
                          defaultValue={"LU-Luxembourg"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="LV"
                          componentAttribute="text"
                          entity="vatCountryCode20"
                          defaultValue={"LV-Letland"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="MT"
                          componentAttribute="text"
                          entity="vatCountryCode21"
                          defaultValue={"MT-Malta"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="NL"
                          componentAttribute="text"
                          entity="vatCountryCode22"
                          defaultValue={"NL-Holland"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="PL"
                          componentAttribute="text"
                          entity="vatCountryCode23"
                          defaultValue={"PL-Polen"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="PT"
                          componentAttribute="text"
                          entity="vatCountryCode24"
                          defaultValue={"PT-Portugal"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="RO"
                          componentAttribute="text"
                          entity="vatCountryCode25"
                          defaultValue={"RO-Rumænien"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="SE"
                          componentAttribute="text"
                          entity="vatCountryCode26"
                          defaultValue={"SE-Sverige"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="SI"
                          componentAttribute="text"
                          entity="vatCountryCode27"
                          defaultValue={"SI-Slovenien"}
                        />
                        <AxoLocal
                          componentClass={ReactOption}
                          value="SK"
                          componentAttribute="text"
                          entity="vatCountryCode28"
                          defaultValue={"SK-Slovakiet"}
                        />
                      </FormControl>
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      entity="vatCountryCode31"
                      defaultValue={"CPR / CVR"}
                    />
                  </Col>
                  <Col sm={9}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-building" />
                      </InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={contact.identityCode}
                        onChange={this.onClientPropertyChange.bind(
                          this,
                          "identityCode"
                        )}
                      />
                      <InputGroup.Addon>
                        <LexButton
                          disabled={!contact.identityCode}
                          onClick={this.getEUVatInformation}
                        >
                          <AxoLocal
                            entity="vatCountryCode33"
                            defaultValue={"Indlæs data"}
                          />
                        </LexButton>
                      </InputGroup.Addon>
                    </InputGroup>
                  </Col>
                </FormGroup>
              </>
            ) : null}

            {!!detailedCVRInfo.name ? (
              <PanelGroup
                id="ITsubscriptionb"
                accordion
                className="Lex-Accordion"
                defaultActiveKey="0"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  borderBottom: "1px solid white",
                }}
              >
                <Panel className="backgroundlineargradient3" eventKey="1">
                  <Panel.Heading>
                    <Panel.Title toggle>
                      {AccordionUtils.renderAccordionHeader(
                        <span>
                          <AxoLocal
                            entity="vatCountryCode34"
                            defaultValue={"Se detaljer om virksomheden"}
                          />{" "}
                        </span>
                      )}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible className="LexAccordionPanelody">
                    <div className="cvrinfotext">
                      <AxoLocal
                        entity="SidebarRightContaineruseruserName"
                        defaultValue={"Navn"}
                      />
                      : {detailedCVRInfo.name}
                    </div>
                    {detailedCVRInfo.biNames.length > 0 ? (
                      <div>
                        <div className="cvrinfotext">
                          <AxoLocal
                            entity="vatCountryCode35"
                            defaultValue={"Binavne"}
                          />
                        </div>
                        {detailedCVRInfo.biNames.map((b) => {
                          return <div>{b}</div>;
                        })}
                      </div>
                    ) : null}
                    <div>
                      <AxoLocal
                        className="cvrinfotext"
                        entity="SignupAdresse"
                        defaultValue={"Adresse"}
                      />
                    </div>
                    <div>{detailedCVRInfo.address}</div>
                    <div>{detailedCVRInfo.postalCode}</div>
                    <div>{detailedCVRInfo.city}</div>
                    <div>{detailedCVRInfo.municipality}</div>

                    {detailedCVRInfo.phone ? (
                      <div>
                        <b>
                          <AxoLocal
                            entity="ContainerPhone"
                            defaultValue={"Telefon"}
                          />
                          :
                        </b>{" "}
                        {detailedCVRInfo.phone}
                      </div>
                    ) : null}
                    {detailedCVRInfo.email ? (
                      <div>
                        <b>
                          <AxoLocal
                            entity="socialSidebarContainerEmail"
                            defaultValue={"Email"}
                          />
                          :
                        </b>{" "}
                        {detailedCVRInfo.email}
                      </div>
                    ) : null}
                    {detailedCVRInfo.website ? (
                      <div>
                        <b>
                          <AxoLocal
                            entity="vatCountryCode37"
                            defaultValue={"Webside"}
                          />
                          :
                        </b>{" "}
                        {detailedCVRInfo.website}
                      </div>
                    ) : null}

                    <div>
                      {" "}
                      <AxoLocal
                        entity="Enhedspris11"
                        defaultValue={"Stiftelsesdato"}
                      />
                      : {detailedCVRInfo.startDate}
                    </div>
                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode38"
                          defaultValue={"Virksomhedstype"}
                        />{" "}
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.type}
                    </div>
                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode39"
                          defaultValue={"Reklamebeskyttet"}
                        />{" "}
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.adProtection ? "Ja" : "Nej"}
                    </div>
                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        <AxoLocal
                          key="statusleft"
                          entity="CaseEditInlineStatusSelect"
                          defaultValue={"Status"}
                        />
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.status}
                    </div>
                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode40"
                          defaultValue={"Omfattet af hvidvaskloven"}
                        />
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.whiteWash ? "Ja" : "Nej"}
                    </div>

                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode41"
                          defaultValue={"Branchekode"}
                        />
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.industryCode}
                    </div>
                    {detailedCVRInfo.biIndustries.length > 0 ? (
                      <div>
                        <div>
                          {" "}
                          <span className="cvrinfotext">
                            <AxoLocal
                              entity="vatCountryCode42"
                              defaultValue={"Bibrancher"}
                            />{" "}
                          </span>
                        </div>
                        {detailedCVRInfo.biIndustries.map((b) => {
                          return (
                            <div>
                              {" "}
                              <span className="cvrinfotext">
                                <AxoLocal
                                  entity="vatCountryCode41"
                                  defaultValue={"Branchekode"}
                                />
                                :{" "}
                              </span>{" "}
                              {b}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode43"
                          defaultValue={"Formål"}
                        />
                        :{" "}
                      </span>
                      {detailedCVRInfo.purpose}
                    </div>
                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode44"
                          defaultValue={"Finansår"}
                        />
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.financialYearStart} -{" "}
                      {detailedCVRInfo.financialYearEnd}
                    </div>
                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode45"
                          defaultValue={"Seneste vedtægter"}
                        />
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.statuteDate}
                    </div>
                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode46"
                          defaultValue={"Kapitalklasser"}
                        />
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.capitalClasses ? "Ja" : "Nej"}
                    </div>
                    <div>
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode47"
                          defaultValue={"Kapital"}
                        />
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.capital} kr.
                    </div>
                    <div>
                      <span className="cvrinfotext">
                        <AxoLocal
                          entity="vatCountryCode49"
                          defaultValue={"Første regnskabsperiode"}
                        />{" "}
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.firstFinancialYearStart} -{" "}
                      {detailedCVRInfo.firstFinancialYearEnd}
                    </div>
                    <div>
                      {" "}
                      <span className="cvrinfotext">
                        {" "}
                        <AxoLocal
                          entity="vatCountryCode50"
                          defaultValue={"Tegningsregel"}
                        />
                        :{" "}
                      </span>{" "}
                      {detailedCVRInfo.signatory}
                    </div>
                    {detailedCVRInfo.directors.length > 0 ? (
                      <div>
                        <div>
                          {" "}
                          <span className="cvrinfotext">
                            <AxoLocal
                              entity="vatCountryCode51"
                              defaultValue={"Direktion"}
                            />{" "}
                          </span>
                        </div>
                        {detailedCVRInfo.directors.map((d) => {
                          return (
                            <div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContaineruseruserName"
                                  defaultValue={"Navn"}
                                />
                                : {d.name}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SignupAdresse"
                                  defaultValue={"Adresse"}
                                />
                                : {d.address}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContainerpostalCode"
                                  defaultValue={"Postnummer"}
                                />
                                : {d.postalCode}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContainerCity"
                                  defaultValue={"By"}
                                />
                                : {d.city}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    {detailedCVRInfo.founders.length > 0 ? (
                      <div>
                        <div>
                          {" "}
                          <span className="cvrinfotext">
                            <AxoLocal
                              entity="vatCountryCode52"
                              defaultValue={"Stiftere"}
                            />{" "}
                          </span>
                        </div>
                        {detailedCVRInfo.founders.map((d) => {
                          return (
                            <div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContaineruseruserName"
                                  defaultValue={"Navn"}
                                />
                                : {d.name}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SignupAdresse"
                                  defaultValue={"Adresse"}
                                />
                                : {d.address}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContainerpostalCode"
                                  defaultValue={"Postnummer"}
                                />
                                : {d.postalCode}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContainerCity"
                                  defaultValue={"By"}
                                />
                                : {d.city}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    {detailedCVRInfo.legalOwners.length > 0 ? (
                      <div>
                        <div>
                          {" "}
                          <span className="cvrinfotext">
                            <AxoLocal
                              entity="vatCountryCode53"
                              defaultValue={"Legale ejere"}
                            />{" "}
                          </span>
                        </div>
                        {detailedCVRInfo.legalOwners.map((d) => {
                          return (
                            <div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContaineruseruserName"
                                  defaultValue={"Navn"}
                                />
                                : {d.name}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SignupAdresse"
                                  defaultValue={"Adresse"}
                                />
                                : {d.address}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContainerpostalCode"
                                  defaultValue={"Postnummer"}
                                />
                                : {d.postalCode}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContainerCity"
                                  defaultValue={"By"}
                                />
                                : {d.city}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    {detailedCVRInfo.realOwners.length > 0 ? (
                      <div>
                        <div>
                          {" "}
                          <span className="cvrinfotext">
                            {" "}
                            <AxoLocal
                              entity="vatCountryCode54"
                              defaultValue={"Reelle ejere"}
                            />{" "}
                          </span>
                        </div>
                        {detailedCVRInfo.realOwners.map((d) => {
                          return (
                            <div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContaineruseruserName"
                                  defaultValue={"Navn"}
                                />
                                : {d.name}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SignupAdresse"
                                  defaultValue={"Adresse"}
                                />
                                : {d.address}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContainerpostalCode"
                                  defaultValue={"Postnummer"}
                                />
                                : {d.postalCode}
                              </div>
                              <div>
                                <AxoLocal
                                  entity="SidebarRightContainerCity"
                                  defaultValue={"By"}
                                />
                                : {d.city}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </Panel.Body>
                </Panel>
              </PanelGroup>
            ) : null}

            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal
                  entity="CaseEditFormfirstName"
                  defaultValue={"Fornavn *"}
                />
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-user-outline" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={contact.firstName}
                    onChange={this.onClientPropertyChange.bind(
                      this,
                      "firstName"
                    )}
                  />
                </InputGroup>
                {this.state.validationState.invalidFirstName ? (
                  <div style={{ color: "red" }}>
                    <AxoLocal
                      entity="ContactCreateViewTheclientname"
                      defaultValue={"* Klienten skal have et navn."}
                    />
                  </div>
                ) : null}
              </Col>
            </FormGroup>
            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal
                  entity="CaseEditFormlastName"
                  defaultValue={"Efternavn"}
                />
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-user-7" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={contact.lastName || ""}
                    onChange={this.onClientPropertyChange.bind(
                      this,
                      "lastName"
                    )}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            {userType === "Society" && (
              <>
                <FormGroup controlId="birthDate">
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    Fødselsdag
                  </Col>
                  <Col sm={9}>
                    <AxoDateTime
                      id="birthDate"
                      value={moment(contact.birthDate)}
                      utc
                      dateFormat={true}
                      timeFormat={false}
                      onChange={(dateTime) =>
                        this.onClientPropertyValueChange(
                          "birthDate",
                          dateTime.format()
                        )
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    Husstandsmedlem
                  </Col>
                  <Col sm={9}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-user-7" />
                      </InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={contact.customFieldOne || ""}
                        onChange={this.onClientPropertyChange.bind(
                          this,
                          "customFieldOne"
                        )}
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>
              </>
            )}
            {userType !== "Society" ? (
              <FormGroup>
                <Col
                  componentClass={ControlLabel}
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={3}
                >
                  <AxoLocal
                    entity="SidebarRightContainercompanyName"
                    defaultValue={"Virksomhed"}
                  />
                </Col>
                <Col sm={9}>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Icon glyph="icon-fontello-building" />
                    </InputGroup.Addon>
                    <FormControl
                      type="text"
                      value={contact.companyName}
                      onChange={this.onClientPropertyChange.bind(
                        this,
                        "companyName"
                      )}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
            ) : null}

            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
              </Col>
              <Col sm={9}>
                <textarea
                  className="form-control"
                  value={contact.description}
                  onChange={this.onClientPropertyChange.bind(
                    this,
                    "description"
                  )}
                ></textarea>
                {/*<FormControl type='textarea' placeholder="Fornavn krævet" value={contact.description} onChange={this.onClientFirstNameChange} />*/}
              </Col>
            </FormGroup>
            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal
                  entity="ContactCreateViewcustomernumber"
                  defaultValue={"ID-nummer"}
                />
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-qrcode" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={contact.clientNumber}
                    onChange={this.onClientPropertyChange.bind(
                      this,
                      "clientNumber"
                    )}
                  />
                  <InputGroup.Addon>
                    <Button
                      className="Lex-button-2"
                      disabled={
                        contact.firstName.length < 3 ||
                        !contact.lastName ||
                        contact.lastName.length < 3
                      }
                      onClick={this.onGenerateClientNumber}
                    >
                      <AxoLocal
                        entity="ContactCreateViewAuto"
                        defaultValue={"Auto"}
                      />
                    </Button>
                  </InputGroup.Addon>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal entity="SignupAdresse" defaultValue={"Adresse"} />
              </Col>
              <Col sm={9} style={{ textAlign: "right" }}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-location-4" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={contact.address}
                    onChange={this.onClientPropertyChange.bind(this, "address")}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal
                  entity="CaseEditFormpostalCode"
                  defaultValue={"Postnummer"}
                />
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-direction-1" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={contact.postalCode}
                    onChange={this.onClientPropertyChange.bind(
                      this,
                      "postalCode"
                    )}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal entity="CaseEditFormcity" defaultValue={"By"} />{" "}
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-warehouse" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={contact.city}
                    onChange={this.onClientPropertyChange.bind(this, "city")}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            {userType !== "Society" && (
              <>
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      entity="ContactCreateViewFacebook"
                      defaultValue={"Facebook"}
                    />
                  </Col>
                  <Col sm={9} style={{ textAlign: "right" }}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-facebook" />
                      </InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={contact.facebook}
                        onChange={this.onClientPropertyChange.bind(
                          this,
                          "facebook"
                        )}
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      entity="ContactCreateViewLinkedIn"
                      defaultValue={"LinkedIn"}
                    />
                  </Col>
                  <Col sm={9} style={{ textAlign: "left" }}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-linkedin" />
                      </InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={contact.linkedIn}
                        onChange={this.onClientPropertyChange.bind(
                          this,
                          "linkedIn"
                        )}
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      entity="ContactCreateViewTwitter"
                      defaultValue={"Twitter"}
                    />
                  </Col>
                  <Col sm={9} style={{ textAlign: "right" }}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-twitter" />
                      </InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={contact.twitter}
                        onChange={this.onClientPropertyChange.bind(
                          this,
                          "twitter"
                        )}
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      key="WeChat"
                      entity="ContactWeChat"
                      defaultValue={"WeChat"}
                    />
                  </Col>
                  <Col sm={9} style={{ textAlign: "right" }}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-chat-1" />
                      </InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={contact.weChat}
                        onChange={this.onClientPropertyChange.bind(
                          this,
                          "weChat"
                        )}
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>
              </>
            )}
          </Form>
        </Col>
        <Col md={6}>
          {/* <ControlLabel style={{textAlign: 'left',  fontSize: '100%', visibility: 'hidden'}}>
              <Icon  glyph='icon-fontello-vcard'/>&nbsp;{title}
            </ControlLabel> */}
          <Form horizontal style={{ marginBottom: 35 }} autoComplete="on">
            <FormGroup className="onTopper">
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal entity="CaseEditFormcountry" defaultValue={"Land"} />
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-globe-6" />
                  </InputGroup.Addon>
                  <CountryDropdown
                    value={contact.country}
                    customSelect
                    valueType="short"
                    onChange={(val) => this.selectCountry(val)}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            {userType !== "Society" && (
              <FormGroup className="onTop">
                <Col
                  componentClass={ControlLabel}
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                  sm={3}
                >
                  <AxoLocal
                    entity="SidebarRightContainerRegion"
                    defaultValue={"Region"}
                  />
                </Col>
                <Col sm={9}>
                  <InputGroup>
                    <InputGroup.Addon>
                      <Icon glyph="icon-fontello-globe-outline" />
                    </InputGroup.Addon>
                    <RegionDropdown
                      country={contact.country}
                      countryValueType="short"
                      customSelect
                      value={contact.region || ""}
                      valueType="short"
                      onChange={(val) => this.selectRegion(val)}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
            )}
            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal
                  entity="CaseEditFormcasesubjectAB"
                  defaultValue={"E-mail"}
                />
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-at-2" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={contact.eMail}
                    onChange={this.onClientPropertyChange.bind(this, "eMail")}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal entity="CaseEditFormphone" defaultValue={"Tlf"} />
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-phone-1" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={contact.phone}
                    onChange={this.onClientPropertyChange.bind(this, "phone")}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col
                componentClass={ControlLabel}
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
                sm={3}
              >
                <AxoLocal
                  entity="socialSidebarContainerMobile"
                  defaultValue={"Mobile"}
                />
              </Col>
              <Col sm={9}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-mobile-4" />
                  </InputGroup.Addon>
                  <FormControl
                    type="text"
                    value={contact.mobile}
                    onChange={this.onClientPropertyChange.bind(this, "mobile")}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            {/* { userType === 'Accountant' || userType === 'Admin' ? (
              <FormGroup>
                <Col componentClass={ControlLabel} style={{ textAlign: 'left',fontSize:'16px',fontWeight:'normal' }} sm={3}>
                <AxoLocal entity='AccountingTabViewEntity27' defaultValue={'Kontoplan'}/>
                </Col>
                <Col sm={9}>
                  <div style={{ position: 'relative', zIndex: 9 }}>
                    <Select
                      name="form-field-name"
                      menuPlacement='auto'
                      value={accountPlanOptions.find(o => o.value === contact.financeAccountPlanId) 
                        || { value: '', label: ctx.getSync("axoAccounting32") }}
                      onChange={(selectedPlan) => this.onFinanceAccountPlanSelect(selectedPlan)}
                      noOptionsMessage={() => ''}
                      options={[
                        { value: '', label: ctx.getSync("axoAccounting32") },
                        ...accountPlanOptions
                      ]}
                    />
                  </div>
                </Col>
              </FormGroup>
            ) : null } */}
            {userType !== "Society" && (
              <>
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                <ControlLabel style={{ textAlign: "left", fontSize: "125%" }}>
                  <Icon glyph="icon-fontello-plus-2" />
                  &nbsp;
                  <AxoLocal
                    entity="ContactCreateViewAddselfserviceclient"
                    defaultValue={"Tilføj selvbetjening til klient"}
                  />
                </ControlLabel>
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      entity="SidebarRightContaineruserProfileuserName"
                      defaultValue={"Brugernavn"}
                    />
                  </Col>
                  <Col sm={9}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph="icon-fontello-lock-2" />
                      </InputGroup.Addon>
                      <FormControl
                        type="text"
                        disabled={existingUserName}
                        value={contact.clientUserName}
                        onChange={this.onClientPropertyChange.bind(
                          this,
                          "clientUserName"
                        )}
                      />
                    </InputGroup>
                    {this.state.validationState.invalidUserName ? (
                      <div style={{ color: "red" }}>
                        <AxoLocal
                          entity="SignupvalidationStateinvalidUserName"
                          defaultValue={"Brugernavnet er optaget."}
                        />
                      </div>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup
                  validationState={this.getEmailValidationState(
                    this.state.accountMail
                  )}
                >
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      entity="CaseEditFormeMail"
                      defaultValue={"Email"}
                    />
                  </Col>
                  <Col sm={9}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph=" icon-fontello-lock-open-2" />
                      </InputGroup.Addon>
                      <AxoLocal
                        componentClass={FormControl}
                        type="email"
                        disabled={existingUserName}
                        value={this.state.accountMail}
                        onChange={(event) => {
                          this.setState({ accountMail: event.target.value });
                        }}
                        componentAttribute="placeholder"
                        entity="placeholderLoginwillbesenthereTest"
                      />
                    </InputGroup>
                    <Conditional
                      show={this.state.validationState.existingClientAccount}
                    >
                      <div style={{ color: "red" }}>
                        <AxoLocal
                          entity="ContactCreateExistingClientAccount"
                          defaultValue={
                            "Du har allerede en klient med den pågældende email adresse."
                          }
                        />
                      </div>
                    </Conditional>
                    <Conditional
                      show={
                        this.state.validationState.existingForeignClientAccount
                      }
                    >
                      <div>
                        <AxoLocal
                          entity="ContactCreateDuplicateEmail"
                          defaultValue={
                            "Der eksisterer allerede en klientkonto med den pågældende email."
                          }
                        />
                      </div>
                      {/* <ButtonToolbar> */}
                      {/* <Button className='Lex-button-2' onClick={this.onAddExistingClientAccount}>
                         <AxoLocal entity='ContactCreateClientAccountA' defaultValue={'Tilføj eksisterende konto.'}/>
                       </Button> */}
                      <Button
                        className="Lex-button-2"
                        onClick={this.onClearMail}
                      >
                        <AxoLocal
                          entity="ContactCreateClientAccountB"
                          defaultValue={"Ryd mail."}
                        />
                      </Button>
                      {/* </ButtonToolbar> */}
                      {/* <div style={{ color: 'red' }}><AxoLocal entity='ContactCreateDuplicateEmail' defaultValue={'Der eksisterer allerede en klientkonto med den pågældende email.'}/></div>  */}
                    </Conditional>
                    {validationState.duplicateEmail ? (
                      <div style={{ color: "red" }}>
                        <AxoLocal
                          entity="SignupEmailrequired1a"
                          defaultValue={"Email adressen anvendes allerede."}
                        />
                      </div>
                    ) : null}
                  </Col>
                </FormGroup>
                <FormGroup
                  validationState={this.getConfirmEmailValidationState(
                    this.state.accountConfirmMail
                  )}
                >
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      entity="signupViewConfirmEmail"
                      defaultValue="Bekræft email"
                    />
                  </Col>
                  <Col sm={9}>
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon glyph=" icon-fontello-lock-open-2" />
                      </InputGroup.Addon>
                      <AxoLocal
                        componentClass={FormControl}
                        type="email"
                        disabled={existingUserName}
                        value={this.state.accountConfirmMail}
                        onChange={(event) => {
                          this.setState({
                            accountConfirmMail: event.target.value,
                          });
                        }}
                        componentAttribute="placeholder"
                        entity="signupViewConfirmEmail"
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  >
                    <AxoLocal
                      entity="friendRequestTimelineNotification"
                      defaultValue={"Besked"}
                    />
                  </Col>
                  <Col sm={9}>
                    <textarea
                      className="form-control"
                      value={contact.clientMessage}
                      onChange={this.onClientPropertyChange.bind(
                        this,
                        "clientMessage"
                      )}
                    ></textarea>
                  </Col>
                </FormGroup>
                <ControlLabel
                  style={{
                    textAlign: "left",
                    color: "#B8C3C8",
                    fontSize: "100%",
                  }}
                  className="axowhite"
                >
                  <AxoLocal
                    entity="ContactCreateViewUserNotification"
                    defaultValue={
                      "* Systemet vil automatisk generere et password og sende login information til email adressen ved oprettelsen.."
                    }
                  />
                </ControlLabel>
                <FormGroup>
                  <Col
                    componentClass={ControlLabel}
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                    sm={3}
                  ></Col>
                  <Col sm={9}>
                    <div>
                      <b>
                        {" "}
                        <AxoLocal
                          entity="ContactCreateViewSelectcontacts"
                          defaultValue={"Hent eksisterende klient"}
                        />
                      </b>
                    </div>
                    <ClientSearchBox
                      clients={contactMap.contacts}
                      count={contactMap.count}
                      handleSelectedClient={this.handleSelectedClient}
                      disabled={false}
                    />
                  </Col>
                </FormGroup>
              </>
            )}
          </Form>
          <br />
          <br />
          <Row>
            <Col xs={12} className="text-center" style={{ marginBottom: 16 }}>
              <ButtonToolbar style={{ display: "inline-block" }}>
                <AsyncButton
                  className="Lex-button-2"
                  onClick={this.onCreateClient}
                >
                  {!!contact.id ? (
                    <span>
                      <Icon glyph="icon-fontello-floppy-1" />
                      &nbsp;
                      <AxoLocal
                        key="save"
                        entity="CaseEditFormonSave"
                        defaultValue={"Gem ændringer"}
                      />
                    </span>
                  ) : (
                    <span>
                      <Icon glyph="icon-fontello-user" />
                      &nbsp;
                      {userType === "Society" ? (
                        <span>
                          {" "}
                          <AxoLocal
                            key="create"
                            entity="ADDViewCreate"
                            defaultValue="Opret"
                          />
                        </span>
                      ) : (
                        <AxoLocal
                          key="create"
                          entity="ADDViewCreate"
                          defaultValue={"Opret"}
                        />
                      )}
                    </span>
                  )}
                </AsyncButton>

                <Button className="Lex-button-2" onClick={this.onResetClient}>
                  {" "}
                  <span>
                    {" "}
                    &#x21BB; &nbsp;
                    <AxoLocal
                      entity="ContactCreateViewReset"
                      defaultValue={"Ryd alt"}
                    />
                  </span>
                </Button>
                {this.props.onCancel ? (
                  <Button className="Lex-button-2" onClick={this.onCancel}>
                    <span>
                      <Icon glyph="con-fontello-left-5" />
                      &nbsp;
                      <AxoLocal
                        entity="axoAccounting22"
                        defaultValue={"Fortryd"}
                      />
                    </span>
                  </Button>
                ) : null}
              </ButtonToolbar>
            </Col>
            <Row className="text-center">
              {this.state.clientCreated ? (
                <span className="axoblue">
                  <h3>
                    <b>
                      <AxoLocal
                        entity="ContactCreateViewClientCreated"
                        defaultValue={"Klienten blev oprettet"}
                      />
                    </b>
                  </h3>
                </span>
              ) : null}
            </Row>
          </Row>
        </Col>
      </Row>
    );
  }

  validateClientAccountInfo = () => {
    let { accountMail, accountConfirmMail } = this.state;
    if (accountMail !== accountConfirmMail) {
      return false;
    }

    if (!this.validateEmail(accountMail)) {
      return false;
    }
    return true;
  };

  onCreateClient = async () => {
    let contact = this.state.contactViewModel;
    if (!contact.firstName) {
      let validationState = Object.assign({}, this.state.validationState);
      validationState.invalidFirstName = true;
      this.setState({ validationState: validationState });
      return false;
    }

    let existingContact = !!this.state.existingContact.id;
    let createAccount =
      contact.clientUserName &&
      (!existingContact || !this.state.existingContact.clientUserName);

    let validationState = this.makeValidationState();

    let contactResult = {};
    if (createAccount) {
      if (!this.validateClientAccountInfo()) {
        return false;
      }

      try {
        contactResult = await this.createOrUpdateContact({
          ...contact,
          clientUserName: this.state.existingContact.clientUserName || "", //Do not save the account name until the account has been created
        });
        if (!contactResult.id) {
          return false;
        }

        await this.createClientAccount(contactResult, contact.clientUserName);
        contactResult = await this.updateContact({
          ...contactResult,
          clientUserName: contact.clientUserName,
        }); //Save the account name.
      } catch (response) {
        let message = response.data;

        if (!message.error) {
          ModalService.openAlertModal(
            <AxoLocal
              entity="ContactCreateViewmessageerror"
              defaultValue={
                "Selvbetjeningskontoen kunne ikke oprettes. Prøv et andet password."
              }
            />
          );
        } else if (message.error === "ExistingClient") {
          validationState.existingClientAccount = true;
        } else if (message.error === "ExistingForeignClient") {
          validationState.existingForeignClientAccount = true;
        } else if (message.error === "DuplicateEmail") {
          validationState.duplicateEmail = true;
        } else if (message.error === "DuplicateUserName") {
          validationState.invalidUserName = true;
        } else if (message.error === "InvalidPassword") {
          validationState.invalidPassword = true;
        } else if (message.error === "EmailNotSend") {
          ModalService.openAlertModal(
            <span>Kontoen blev oprettet, men mailen kunne ikke sendes.</span>
          );
          contactResult = await this.updateContact({
            ...contactResult,
            clientUserName: contact.clientUserName,
          }); //Save the account name.
        }

        this.setState({
          contactViewModel: contactResult,
          existingContact: contactResult,
          validationState: validationState,
        });
        return false;
      }
    } else {
      contactResult = await this.createOrUpdateContact(contact);
    }

    let clientCreated = !contact.id && !!contactResult.id;
    if (clientCreated) {
      let { onClientCreated } = this.props;
      if (!!onClientCreated) {
        onClientCreated(contactResult);
        return true;
      }
    }

    this.setState({
      contactViewModel: contactResult,
      existingContact: contactResult,
      validationState: this.makeValidationState(),
      accountMail: "",
      accountConfirmMail: "",
      clientCreated,
    });

    setTimeout(() => {
      this.setState({ clientCreated: false });
    }, 10000);

    return true;
  };

  createOrUpdateContact = (contact) => {
    if (!contact.id) {
      return this.createContact(contact);
    } else {
      return this.updateContact(contact);
    }
  };

  createClientAccount = (contact, clientUserName) => {
    let { createClientAccount } = this.props;

    return createClientAccount({
      userName: clientUserName,
      contactInfo: contact,
      email: this.state.accountMail,
      messageUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        RoutingService.getAccountPath("ResetPassword"),
      subject: ContactService.getContactMailSubject(),
      body: ContactService.getContactMailBody(contact),
    }).unwrap();
  };

  createContact = async (contact) => {
    let { createContact } = this.props;

    let result = {};
    try {
      result = await createContact(contact).unwrap();
    } catch {
      ModalService.openAlertModal(
        <AxoLocal
          entity="ContactCreateViewmessageSaveError"
          defaultValue={"Klienten kunne ikke oprettes. Prøv igen senere."}
        />
      );
    }
    return result;
  };

  updateContact = async (contact) => {
    let { updateContact } = this.props;

    let result = {};
    try {
      await updateContact(contact).unwrap();
      result = contact;
    } catch {
      ModalService.openAlertModal(
        <AxoLocal
          entity="ContactCreateViewmessagechange"
          defaultValue={"Ændringerne kunne ikke gemmes. Prøv igen senere."}
        />
      );
    }
    return result;
  };

  onResetClient = () => {
    this.setState({
      contactViewModel: this.makeNewContactViewModel(),
      existingContact: {},
      accountMail: "",
      accountConfirmMail: "",
    });
  };

  onCancel = () => {
    this.onResetClient();
    this.props.onCancel();
  };
  render() {
    var title = this.props.title || (
      <AxoLocal
        style={{ fontSize: "17px" }}
        entity="ContactCreateViewclientInformation"
        defaultValue={"Information"}
      />
    );
    return (
      <div style={{ paddingTop: "10px" }}>
        <div
          className="axobg"
          style={{
            whith: "17px",
            paddingLeft: "25px",
            height: "50px",
            lineHeight: "50px",
          }}
        >
          <Icon style={{ whith: "20px" }} glyph="icon-fontello-vcard" />
          &nbsp;&nbsp;&nbsp; {title}
        </div>
        <div style={{ paddingLeft: "5px", minHeight: "800px" }}>
          <div style={{ paddingTop: "15px" }}>
            <Grid fluid>{this.renderClientForm()}</Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ContactCreateView);
