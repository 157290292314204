import React from "react";
import PropTypes from "prop-types";

import {
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";

import { AxoLocal, AsyncButton, Icon } from "../../utilities/LexUtilities";

export default class AccountPlansBasicSetupForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    financeAccountPlan: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // var readOnly = props.financeAccountPlan.resultEnd > 0;
    var readOnly = false;

    this.state = {
      // resultStart: readOnly ? props.financeAccountPlan.resultStart : 0,
      // resultEnd: readOnly ? props.financeAccountPlan.resultEnd : 0,
      // balanceStart: readOnly ? props.financeAccountPlan.balanceStart : 0,
      // balanceEnd: readOnly ? props.financeAccountPlan.balanceEnd : 0,
      resultStart: props.financeAccountPlan.resultStart || 0,
      resultEnd: props.financeAccountPlan.resultEnd || 0,
      balanceStart: props.financeAccountPlan.balanceStart || 0,
      balanceEnd: props.financeAccountPlan.balanceEnd || 0,
      readOnly: readOnly,

      showErrorMessage: false,
      showServerError: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // if(!this.state.readOnly && this.props.financeAccountPlan.resultEnd > 0) {
    //   this.setState({
    //     readOnly: true
    //   });
    // }
  }

  onPropertyChange = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  validateInput = () => {
    let { resultStart, resultEnd, balanceStart, balanceEnd } = this.state;

    if (
      resultEnd <= resultStart ||
      balanceStart <= resultEnd ||
      balanceEnd <= balanceStart
    ) {
      return false;
    }
    return true;
  };

  onSubmit = async () => {
    if (!this.validateInput()) {
      this.setState({ showErrorMessage: true });
      setTimeout(() => this.setState({ showErrorMessage: false }), 3000);
      return false;
    }
    try {
      let result = await this.props.onSubmit({ ...this.state });
      if (!result) {
        throw new Error("Function failed to execute successfully.");
      }
      return true;
    } catch (reason) {
      console.log(reason.message);
      this.setState({ showServerError: true });
      setTimeout(() => this.setState({ showServerError: false }), 3000);
      return false;
    }
  };

  render() {
    let {
      resultStart,
      resultEnd,
      balanceStart,
      balanceEnd,
      showErrorMessage,
      showServerError,
      readOnly,
    } = this.state;

    return (
      <Form>
        <h2>
          <AxoLocal
            entity="AccountingTabViewEntity68"
            defaultValue={"Opsætning af resultatopgørelse og balance"}
          />
        </h2>
        <h4>
          <AxoLocal
            entity="AccountingTabViewEntity3"
            defaultValue={"Resultatopgørelse"}
          />
        </h4>
        <FormGroup controlId="firstName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="AccountingTabViewEntity69"
              defaultValue={"Startnummer"}
            />
          </ControlLabel>
          <InputGroup>
            <FormControl
              autoFocus
              disabled={readOnly}
              type="number"
              className="borderFormControlfocus"
              value={isNaN(resultStart) ? "" : resultStart}
              onChange={(event) =>
                this.onPropertyChange("resultStart", event.target.valueAsNumber)
              }
            />
          </InputGroup>
        </FormGroup>
        <FormGroup controlId="firstName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="AccountingTabViewEntity70"
              defaultValue={"Slutnummer"}
            />
          </ControlLabel>
          <InputGroup>
            <FormControl
              type="number"
              disabled={readOnly}
              className="borderFormControlfocus"
              value={isNaN(resultEnd) ? "" : resultEnd}
              onChange={(event) =>
                this.onPropertyChange("resultEnd", event.target.valueAsNumber)
              }
            />
          </InputGroup>
        </FormGroup>
        <h4>
          <AxoLocal
            entity="AccountingTabViewEntity4"
            defaultValue={"Balance"}
          />
        </h4>
        <FormGroup controlId="firstName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="AccountingTabViewEntity69"
              defaultValue={"Startnummer"}
            />
          </ControlLabel>
          <InputGroup>
            <FormControl
              type="number"
              disabled={readOnly}
              className="borderFormControlfocus"
              value={isNaN(balanceStart) ? "" : balanceStart}
              onChange={(event) =>
                this.onPropertyChange(
                  "balanceStart",
                  event.target.valueAsNumber
                )
              }
            />
          </InputGroup>
        </FormGroup>
        <FormGroup controlId="firstName">
          <ControlLabel
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <AxoLocal
              entity="AccountingTabViewEntity70"
              defaultValue={"Slutnummer"}
            />
          </ControlLabel>
          <InputGroup>
            <FormControl
              type="number"
              disabled={readOnly}
              className="borderFormControlfocus"
              value={isNaN(balanceEnd) ? "" : balanceEnd}
              onChange={(event) =>
                this.onPropertyChange("balanceEnd", event.target.valueAsNumber)
              }
            />
          </InputGroup>
        </FormGroup>
        {/* { !readOnly ? (
          <div className='axored' style={{paddingBottom:'15px'}} > 
            <AxoLocal entity='AccountingTabViewEntity71' defaultValue={'Opsætningen er permanent.'}/>
          </div>
        ) : null } */}
        <AsyncButton onClick={this.onSubmit} disabled={readOnly}>
          <Icon glyph="icon-fontello-floppy-1" />
          &nbsp;&nbsp;
          {readOnly ? (
            <span>
              <AxoLocal kry="1" entity="axoidcode93" defaultValue={"Gemt"} />
            </span>
          ) : (
            <AxoLocal
              kry="2"
              entity="presentationMarketing5"
              defaultValue={"Gem"}
            />
          )}
        </AsyncButton>
        {showErrorMessage ? (
          <div className="axored">
            <AxoLocal
              entity="AccountingTabViewEntity72"
              defaultValue={"Indtast gyldige kontonumre"}
            />
          </div>
        ) : null}
        {showServerError ? (
          <div className="axored">
            <Icon glyph="icon-fontello-info-3" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="LoginshowServerError"
              defaultValue={"Der skete en fejl. Prøv igen senere."}
            />
          </div>
        ) : null}
      </Form>
    );
  }
}
