import React from "react";
import InvoiceV2 from "../routes/Invoices/InvoiceV2";

const InvoicePrintContainer = () => {
  if (!window || !window.invoice) {
    return <div>Fejl ved print af faktura.</div>;
  }

  return (
    <div className="standardMaxWidth">
      <InvoiceV2
        invoice={window.invoice}
        readOnly
        isFetchingHTML
        renderButtons={false}
      />
    </div>
  );
};

export default React.memo(InvoicePrintContainer);
