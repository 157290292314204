// @ts-check
import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import MinyTinyMCE from "./../../utilities/Editors/MinyTinyMCE";
import ImageFooter from "./ImageFooter";
import AttachmentFooter from "./AttachmentFooter";
import ModalService from "../../services/ModalService";
import TextAreaWrapper from "../../utilities/TextAreaWrapper";
import * as $ from "jquery";
import {
  DocumentSearchBox,
  AxoLocal,
  ImageSearchBox,
} from "../../utilities/LexUtilities";
import { Row, Col, Grid, InputGroup, Button } from "react-bootstrap";
import {
  Icon,
  FileViewerModal,
  AlertModal,
} from "../../utilities/LexUtilities";

export default class CommentBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      comment: {
        content: "",
      },
      editing: false,
    };
    this.editor = {};
  }

  onTextChange = (content) => {
    var comment = Object.assign({}, this.state.comment);
    comment.content = content;
    this.setState({ comment: comment });
  };

  //Props
  //onAddComment
  //documents
  onSubmitComment = () => {
    if (!this.state.comment.content) {
      return;
    }
    this.props.onAddComment(this.state.comment).then((response) => {
      if (response.status === 200) {
        this.setState({
          comment: {
            content: "",
          },
          editing: false,
        });
      } else {
        ModalService.openAlertModal(
          <AxoLocal
            entity="messageModalServiceopenAlertcomment"
            defaultValue={"Kommentaren kunne ikke sendes. Prøv igen senere."}
          />
        );
      }
    });
  };

  setupEditor = (editor) => {
    this.editor = editor;
  };

  onClickCommentBox = () => {
    this.setState({ editing: true });
  };

  handleSelectedImage = (image) => {
    var comment = Object.assign({}, this.state.comment);
    this.deleteExistingSocialAttachments(comment);
    comment.image = image;
    comment.attachment = null;
    this.setState({ comment: comment });
  };

  handleSelectedDocument = (document) => {
    var comment = Object.assign({}, this.state.comment);
    this.deleteExistingSocialAttachments(comment);
    comment.attachment = document;
    comment.image = null;
    this.setState({ comment: comment });
  };

  deleteExistingSocialAttachments = (comment) => {
    if (comment.attachment && comment.attachment.sectionFileType === 1) {
      //Social type
      DataActions.deleteDocument(comment.attachment.id);
    }
    if (comment.image && comment.image.sectionFileType === 1) {
      //Social type
      DataActions.deleteDocument(comment.image.id);
    }
  };

  onShowFile = (document) => {
    this.fileModal.open([document]);
  };

  onRemoveAttachment = () => {
    var comment = Object.assign({}, this.state.comment);
    if (comment.attachment && comment.attachment.sectionFileType === 1) {
      DataActions.deleteDocument(comment.attachment.id);
    }
    comment.attachment = null;
    this.setState({ comment: comment });
  };

  onRemoveImage = () => {
    var comment = Object.assign({}, this.state.comment);
    if (comment.image && comment.image.sectionFileType === 1) {
      DataActions.deleteDocument(comment.image.id);
    }
    comment.image = null;
    this.setState({ comment: comment });
  };

  onExternalImage = () => {
    $("#uploadCommentImage").trigger("click");
  };

  onExternalFile = () => {
    $("#uploadCommentAttachment").trigger("click");
  };

  onFileChosen = (event) => {
    var fileSelect = event.target;
    if (fileSelect.files.length === 0) {
      return;
    }
    this.uploadExternalFile(fileSelect.files, this.handleSelectedDocument);
    fileSelect.value = "";
  };

  onImageChosen = (event) => {
    var fileSelect = event.target;
    if (fileSelect.files.length === 0) {
      return;
    }
    if (!fileSelect.files[0].type.includes("image")) {
      this.alertModal.open(
        <AxoLocal
          entity="messageModalServiceopenAlertcommentimage"
          defaultValue={"Vælg venligst en billedfil"}
        />
      );
      return;
    }
    this.uploadExternalFile(fileSelect.files, this.handleSelectedImage);
    fileSelect.value = "";
  };

  uploadExternalFile = (files, fileHandlerCallback) => {
    DataActions.uploadDocuments(files, { isSocial: true })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(new Error("Upload failed"));
      })
      .then((fileModels) => {
        var fileModel = fileModels[0];
        fileHandlerCallback(fileModel);
      })
      .catch((reason) => {
        console.error(reason);
      });
  };

  onCancelComment = () => {
    this.setState({
      comment: {
        content: "",
      },
      editing: false,
    });
  };

  renderEditorSection = () => {
    if (!this.state.editing) {
      return (
        <AxoLocal
          componentClass={TextAreaWrapper}
          onClick={this.onClickCommentBox}
          onChange={this.onTextChange}
          value={this.state.commentText}
          rows={2}
          style={{ border: "1px solid #d9d9d9" }}
          componentAttribute="placeholder"
          entity="placeholderrenderEditorSectioncommentText"
        />
      );
    }

    return (
      <div>
        <div
          className="no-margin"
          style={{ margin: "0px", backgroundColor: "white" }}
        >
          <Grid fluid>
            <Row>
              <Col lg={2} className="nopadding">
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-picture-1 " />
                  </InputGroup.Addon>
                  <AxoLocal
                    componentClass={ImageSearchBox}
                    documentMap={this.props.documentMap}
                    handleSelectedImage={this.handleSelectedImage}
                    componentAttribute="placeholder"
                    entity="TinyMCEEditormyImage"
                  />
                </InputGroup>
              </Col>
              <Col lg={2} className="nopadding">
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon glyph="icon-fontello-doc-text-inv" />
                  </InputGroup.Addon>
                  <AxoLocal
                    componentClass={DocumentSearchBox}
                    documentMap={this.props.documentMap}
                    handleSelectedDocument={this.handleSelectedDocument}
                    componentAttribute="placeholder"
                    entity="placeholderhandleSelectedDocumentAttachinternalfile"
                  />
                </InputGroup>
              </Col>
              <Col lg={8} className="text-right nopadding">
                <Button className="Lex-button-2" onClick={this.onExternalImage}>
                  <AxoLocal
                    entity="socialCommentBoxNavExternalImage"
                    defaultValue={"Eksternt billede"}
                  />
                  &ensp;&ensp;{" "}
                  <Icon role="button" glyph="icon-fontello-camera-7" />
                </Button>
                &nbsp;
                <Button className="Lex-button-2" onClick={this.onExternalFile}>
                  <AxoLocal
                    entity="socialCommentBoxNavExternalFile"
                    defaultValue={"Ekstern fil"}
                  />
                  &ensp;&ensp;{" "}
                  <Icon role="button" glyph="icon-fontello-docs-1" />
                </Button>
                &nbsp;&nbsp;
                <Button className="Lex-button-2" onClick={this.onCancelComment}>
                  <AxoLocal
                    entity="socialCommentBoxNavCancel"
                    defaultValue={"Annuller"}
                  />
                </Button>
                &nbsp;
                <Button className="Lex-button-2" onClick={this.onSubmitComment}>
                  <AxoLocal
                    entity="socialInputBoxNavSubmit"
                    defaultValue={"Slå op"}
                  />
                  &nbsp; <Icon role="button" glyph="icon-fontello-forward" />
                </Button>
              </Col>
            </Row>
          </Grid>
        </div>
        <div style={{ padding: 0 }}>
          <MinyTinyMCE
            text={this.state.comment.content}
            locale={this.props.locale}
            height={250}
            onTextChange={this.onTextChange}
            setupEditor={this.setupEditor}
          />
          <Grid fluid>
            <ImageFooter
              image={this.state.comment.image}
              onRemoveImage={this.onRemoveImage}
            />
            <AttachmentFooter
              attachment={this.state.comment.attachment}
              onClickFile={this.onShowFile}
              onRemoveAttachment={this.onRemoveAttachment}
            />
          </Grid>
          <input
            onChange={this.onFileChosen}
            name="attachment"
            type="file"
            id="uploadCommentAttachment"
            style={{ display: "none" }}
          />
          <input
            onChange={this.onImageChosen}
            name="image"
            type="file"
            id="uploadCommentImage"
            style={{ display: "none" }}
          />
        </div>
        <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
        <AlertModal ref={(c) => (this.alertModal = c)} />
      </div>
    );
  };

  render() {
    return (
      <div>
        <Row>
          <Col
            sm={12}
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              margin: "auto",
            }}
          >
            {this.renderEditorSection()}
          </Col>
        </Row>
      </div>
    );
  }
}
