import React from "react";
import NumericInput from "react-numeric-input";
import Select from "react-select";
import * as $ from "jquery";
// import PropTypes from 'prop-types';

import {
  Grid,
  Row,
  Col,
  Form,
  Button,
  ButtonToolbar,
  ControlLabel,
  FormGroup,
  FormControl,
} from "react-bootstrap";

import { ApiService, DataActions } from "../../services/AxoServices";

import {
  LexButton,
  Icon,
  DocumentSearchBox,
  ctx,
  AxoLocal,
} from "../../utilities/LexUtilities";

export default class CreatePlanView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      plan: this.makeNewPlan(),
      showSuccessMessage: false,
      showFailureMessage: false,
    };
  }

  makeNewPlan = () => {
    return {
      planId: "",
      name: "",
      type: "Standard",
      statement: "",
      currency: "USD",
      amount: 0,
      costPerUnit: 0,
      interval: 2, //Month
      trialPeriod: 0,
      storage: 5,
      userGroupSize: 0,
      freeUnitsPerInterval: 0,
      targetUserName: "",
    };
  };

  onReset = () => {
    this.setState({
      plan: this.makeNewPlan(),
    });
  };

  onCreatePlan = () => {
    var plan = this.state.plan;
    if (!plan.name) {
      return;
    }
    DataActions.createSubscriptionPlan(plan)
      .then((response) => {
        if (response.ok) {
          this.setState({
            plan: this.makeNewPlan(),
            showSuccessMessage: true,
          });
          setTimeout(() => {
            this.setState({ showSuccessMessage: false });
          }, 3000);
        } else {
          return Promise.reject("Could not create plan");
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({ showFailureMessage: true });
        setTimeout(() => {
          this.setState({ showFailureMessage: false });
        }, 3000);
      });
  };

  onPlanPropertyChange = (propertyName, event) => {
    var plan = Object.assign({}, this.state.plan);
    plan[propertyName] = event.target.value;
    this.setState({ plan });
  };

  onPlanPropertyValueChange = (propertyName, value) => {
    var plan = Object.assign({}, this.state.plan);
    plan[propertyName] = value;
    this.setState({ plan });
  };

  onChangeAmount = (valueAsNumber) => {
    var plan = Object.assign({}, this.state.plan);
    plan.amount = valueAsNumber * 100;
    this.setState({ plan });
  };

  onChangeNumberProperty = (propertyName, valueAsNumber) => {
    var plan = Object.assign({}, this.state.plan);
    plan[propertyName] = valueAsNumber;
    this.setState({ plan });
  };

  uploadPicture = () => {
    let component = this;
    let { plan } = { ...this.state };

    $("#uploadImage").trigger("click");
    $("#uploadImage").on("change", function () {
      let file = this.files[0];
      if (!file.type.includes("image")) {
        return;
      }
      DataActions.uploadDocuments(this.files).then((response) => {
        if (response.status === 200) {
          response.json().then((addedFiles) => {
            plan.pictureId = addedFiles[0].id;
            component.setState({ plan });
          });
        }
      });
    });
  };

  getPlanTypeOptions = () => {
    return [
      { value: "Standard", label: "Standard" },
      { value: "IT", label: "IT" },
      { value: "Custom", label: "Custom" },
      { value: "EasyID", label: "NemID" },
    ];
  };

  getPeriodOptions = () => {
    return [
      { value: "0", label: ctx.getSync("AdminTabView9") }, //Dag
      { value: "1", label: ctx.getSync("AdminTabView10") }, //Uge
      { value: "2", label: ctx.getSync("AdminTabView11") }, //Måned
      { value: "3", label: ctx.getSync("AdminTabView12") }, //År
    ];
  };

  render() {
    let { documentMap } = this.props;
    let { plan } = this.state;

    let planTypeOptions = this.getPlanTypeOptions();
    let periodOptions = this.getPeriodOptions();

    console.log(plan);

    return (
      <Grid fluid>
        <Row>
          <Col xs={12} smOffset={1} sm={10} style={{ paddingTop: "20px" }}>
            <div className="text-center">
              <h3>
                {" "}
                <AxoLocal
                  entity="AdminTabView4"
                  defaultValue="Opret nyt abonnement"
                />{" "}
              </h3>
            </div>
            <Form>
              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="Enhedspris2" defaultValue="Billede" />
              </ControlLabel>
              {!!plan.pictureId ? (
                <div style={{ paddingBottom: "5px" }}>
                  <LexButton
                    onClick={() =>
                      this.onPlanPropertyValueChange("pictureId", null)
                    }
                    style={{ paddingBottom: "5px" }}
                  >
                    <AxoLocal
                      entity="Enhedspris3"
                      defaultValue="Fjern billede"
                    />
                  </LexButton>
                  <img
                    alt=""
                    style={{
                      maxWidth: "150px",
                      height: "auto",
                      display: "block",
                    }}
                    src={ApiService.getFileInlinePath(plan.pictureId)}
                  />
                </div>
              ) : null}
              <div className="flexbox-responsive">
                <div>
                  <AxoLocal
                    componentClass={DocumentSearchBox}
                    documentMap={documentMap}
                    handleSelectedDocument={(document) =>
                      this.onPlanPropertyValueChange("pictureId", document.id)
                    }
                    clearOnSelect
                    componentAttribute="placeholder"
                    entity="placeholderSelectexistinglogo"
                  />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div>
                  <LexButton onClick={this.uploadPicture}>
                    <Icon glyph="icon-fontello-desktop" />
                    &nbsp;
                    <AxoLocal
                      entity="Enhedspris4"
                      defaultValue="Upload billede fra PC"
                    />
                  </LexButton>
                </div>
              </div>
              <input
                name="image"
                type="file"
                id="uploadImage"
                accept="image/*"
                style={{ display: "none" }}
              />
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="AdminTabView5" defaultValue="Plan Id" />
                </ControlLabel>
                <FormControl
                  type="text"
                  value={plan.planId}
                  onChange={this.onPlanPropertyChange.bind(this, "planId")}
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="AdminTabView6" defaultValue="Navn" />
                </ControlLabel>
                <FormControl
                  type="text"
                  value={plan.name}
                  onChange={this.onPlanPropertyChange.bind(this, "name")}
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal
                    entity="axoAccounting37"
                    defaultValue="Beskrivelse (vises på kontoudtog)"
                  />
                </ControlLabel>
                <FormControl
                  type="text"
                  value={plan.statement}
                  onChange={this.onPlanPropertyChange.bind(this, "statement")}
                />
              </FormGroup>
              <FormGroup controlId="targetUserName" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  Målbruger
                </ControlLabel>
                <FormControl
                  type="text"
                  value={plan.targetUserName}
                  onChange={this.onPlanPropertyChange.bind(
                    this,
                    "targetUserName"
                  )}
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="DocumentGridViewType" defaultValue="Type" />
                </ControlLabel>
                <Select
                  name="form-field-name"
                  menuPlacement="auto"
                  value={planTypeOptions.find((o) => o.value === plan.type)}
                  onChange={(selection, arg) => {
                    this.onPlanPropertyValueChange(
                      "type",
                      selection ? selection.value : "Standard"
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={planTypeOptions}
                />
              </FormGroup>
              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="AdminTabView7" defaultValue="Valuta" />
                </ControlLabel>
                <Select
                  name="form-field-name"
                  menuPlacement="auto"
                  value={{
                    value: plan.currency.toUpperCase(),
                    label: plan.currency.toUpperCase(),
                  }}
                  onChange={(selection, arg) => {
                    this.onPlanPropertyValueChange(
                      "currency",
                      selection ? selection.value : "USD"
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    { value: "DKK", label: "DKK" },
                    { value: "USD", label: "USD" },
                    { value: "GBP", label: "GBP" },
                    { value: "EUR", label: "EUR" },
                    { value: "CNY", label: "CNY" },
                    { value: "SEK", label: "SEK" },
                    { value: "NKK", label: "NKK" },
                    { value: "ISK", label: "ISK" },
                  ]}
                />
              </FormGroup>

              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="AdminTabView29" defaultValue="Pris" />
              </ControlLabel>
              <NumericInput
                className="form-control"
                onChange={this.onChangeAmount}
                step={1}
                precision={2}
                value={plan.amount / 100}
              />

              {plan.type === "EasyID" && (
                <>
                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    Enhedspris
                  </ControlLabel>
                  <NumericInput
                    className="form-control"
                    onChange={(number) =>
                      this.setState((currentState) => ({
                        plan: {
                          ...currentState.plan,
                          costPerUnit: number * 100,
                        },
                      }))
                    }
                    step={1}
                    precision={2}
                    value={plan.costPerUnit / 100}
                  />

                  <ControlLabel
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    Gratis enheder per måned
                  </ControlLabel>
                  <NumericInput
                    className="form-control"
                    onChange={(number) =>
                      this.setState((currentState) => ({
                        plan: {
                          ...currentState.plan,
                          freeUnitsPerInterval: number,
                        },
                      }))
                    }
                    step={1}
                    precision={0}
                    value={plan.freeUnitsPerInterval}
                  />
                </>
              )}

              <FormGroup controlId="description" bsSize="large">
                <ControlLabel
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <AxoLocal entity="AdminTabView8" defaultValue="Interval" />
                </ControlLabel>
                <Select
                  name="form-field-name"
                  menuPlacement="auto"
                  value={periodOptions.find(
                    (p) => p.value === plan.interval.toString()
                  )}
                  onChange={(selection, arg) => {
                    this.onPlanPropertyValueChange(
                      "interval",
                      selection ? selection.value : "0"
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={periodOptions}
                />
              </FormGroup>

              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="AdminTabView13" defaultValue="Prøveperiode" />
              </ControlLabel>
              <NumericInput
                className="form-control"
                onChange={this.onChangeNumberProperty.bind(this, "trialPeriod")}
                step={1}
                precision={0}
                value={plan.trialPeriod}
              />

              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal entity="AdminTabView14" defaultValue="Lagerplads" />
              </ControlLabel>
              <NumericInput
                className="form-control"
                onChange={this.onChangeNumberProperty.bind(this, "storage")}
                step={1}
                precision={0}
                value={plan.storage}
              />

              <ControlLabel
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                <AxoLocal
                  entity="Casesharing9E"
                  defaultValue={"Brugergruppe"}
                />
              </ControlLabel>
              <NumericInput
                className="form-control"
                onChange={this.onChangeNumberProperty.bind(
                  this,
                  "userGroupSize"
                )}
                step={1}
                precision={0}
                value={plan.userGroupSize}
              />
            </Form>
            <br />
            <div style={{ paddingBottom: "15px" }}>
              <div className="text-center" style={{ paddingTop: "10px" }}>
                <ButtonToolbar style={{ display: "inline-block" }}>
                  <Button className="Lex-button-2 " onClick={this.onCreatePlan}>
                    <AxoLocal entity="ADDViewCreate" defaultValue="Opret" />
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button className="Lex-button-2 " onClick={this.onReset}>
                    <AxoLocal entity="AdminTabView16" defaultValue="Nulstil" />
                  </Button>
                </ButtonToolbar>
              </div>
              {this.state.showSuccessMessage ? (
                <div className="text-center axoblue">
                  <h3>
                    <AxoLocal
                      entity="AdminTabView17"
                      defaultValue=" Abonnementet blev oprettet."
                    />
                  </h3>
                </div>
              ) : null}
              {this.state.showFailureMessage ? (
                <div className="text-center axored">
                  <Icon glyph="icon-fontello-info-3" />
                  &nbsp;&nbsp;
                  <h3>
                    <AxoLocal
                      entity="AdminTabView18"
                      defaultValue="Der skete en fejl. Prøv igen senere."
                    />
                  </h3>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
