import React from "react";

import DataListWrapper from "./DataListWrapper";

var SortTypes = {
  ASC: "ASC",
  DESC: "DESC",
};

export default class TableBaseSimple extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tableEntries: [],
      sortedDataList: new DataListWrapper([], []),
    };
  }

  _onSortChange = (columnKey, sortDir, customFunc) => {
    let sortIndexes = this.state.sortedDataList.getIndexMap().slice();
    sortIndexes.sort((indexA, indexB) => {
      let entryA = this.state.sortedDataList._data[indexA];
      let entryB = this.state.sortedDataList._data[indexB];
      let sortVal = 0;
      if (customFunc) {
        sortVal = customFunc(entryA, entryB);
      } else {
        let valueA = entryA[columnKey] || "";
        let valueB = entryB[columnKey] || "";

        if (typeof valueA === "string" && typeof valueB === "string") {
          sortVal = valueA.localeCompare(valueB, "en", { sensitivity: "base" }); //Case insensitive compare
        } else if (valueA > valueB) {
          sortVal = 1;
        } else if (valueA < valueB) {
          sortVal = -1;
        }
      }
      if (sortVal !== 0 && sortDir === SortTypes.ASC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });

    this.setState({
      sortedDataList: new DataListWrapper(
        sortIndexes,
        this.state.sortedDataList._data
      ),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  };

  //Deprecated
  generateDefaultSortIndexes = (entries) => {
    return TableBaseSimple.generateDefaultSortIndices(entries);
  };

  static generateDefaultSortIndices = (entries) => {
    let defaultSortIndexes = [];
    let size = entries.length;
    for (var index = 0; index < size; index++) {
      defaultSortIndexes.push(index);
    }
    return defaultSortIndexes;
  };

  updateEntries(tableEntries, filteredEntries) {
    let { sortedDataList } = this.state;
    if (filteredEntries.length === sortedDataList.getSize()) {
      this.setState({
        tableEntries: tableEntries,
        sortedDataList: new DataListWrapper(
          sortedDataList._indexMap,
          filteredEntries
        ), //Maintain sort order, but update the entries
      });
      return;
    }
    //Update the sort order when the number of entries changes
    // let defaultSortIndexes = TableBaseSimple.generateDefaultSortIndices(filteredEntries);
    this.setState({
      tableEntries: tableEntries,
      sortedDataList:
        sortedDataList.generateNewWrapperFromData(filteredEntries),
      // sortedDataList: new DataListWrapper(defaultSortIndexes, filteredEntries),
    });
  }

  static getNewEntries(tableEntries, filteredEntries, sortedDataList) {
    if (filteredEntries.length === sortedDataList.getSize()) {
      return {
        tableEntries: tableEntries,
        sortedDataList: new DataListWrapper(
          sortedDataList._indexMap,
          filteredEntries
        ), //Maintain sort order, but update the entries
      };
    }
    //Reset the sort order when the number of entries changes
    let defaultSortIndexes =
      TableBaseSimple.generateDefaultSortIndices(filteredEntries);
    return {
      tableEntries: tableEntries,
      sortedDataList: new DataListWrapper(defaultSortIndexes, filteredEntries),
    };
  }

  render() {
    return <div>Table base must have a subclass.</div>;
  }
}
