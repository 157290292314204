import React from "react";
import PropTypes from "prop-types";

import * as $ from "jquery";

import moment from "moment";
import "moment/locale/da";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";

import {
  ApiService,
  DataStore,
  UserInfoService,
  StringService,
  RoutingService,
  AuthorizationService,
} from "../services/AxoServices";

import { AxoLocal, Link, withRouter } from "../utilities/LexUtilities";

import {
  Row,
  Col,
  Grid,
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";

class Lock extends React.PureComponent {
  static propTypes = {
    userSettings: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
  };

  interval = null;

  state = {
    time: null,
    date: null,
    password: "",
    showPasswordWarning: false,
  };

  getTimeState() {
    return {
      time: moment().format("hh:mm:ss"),
      date: moment().format("dddd, MMMM YYYY"),
    };
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    $("html").removeClass("authentication");
  }

  getMomentLocale = (locale) => {
    switch (locale) {
      case "da":
        return "da";
      case "enUS":
        return "en-US";
      case "ch":
        return "zh-cn";
      default:
        return "en-US";
    }
  };

  componentDidMount() {
    $("html").addClass("authentication");
    this.setState(this.getTimeState());
    this.interval = setInterval(() => {
      this.setState(this.getTimeState());
    }, 500);

    if (this.props.userSettings.locale) {
      moment.locale(this.getMomentLocale(this.props.userSettings.locale));
    }
  }

  componentWillReceiveProps(nextProps) {
    var newLocale = nextProps.userSettings.locale;
    if (newLocale && newLocale !== this.props.userSettings.locale) {
      moment.locale(this.getMomentLocale(newLocale));
    }
  }

  onChangePassword = (event) => {
    this.setState({
      password: event.target.value,
      showPasswordWarning: false,
    });
  };

  onSubmitPassword = (event) => {
    event.preventDefault();
    event.stopPropagation();

    ApiService.validatePassword(
      this.props.userProfile.userName,
      this.state.password
    )
      .then(this.updateUserSettings)
      .then(this.fetchUserSettings)
      .then(this.navigateToDashboard)
      .catch((reason) => {
        console.log(reason);
      });
  };

  updateUserSettings = (response) => {
    if (response.status === 200) {
      var settings = Object.assign({}, this.props.userSettings);
      settings.locked = false;
      return ApiService.updateUserSettings(settings);
    }
    this.setState({ showPasswordWarning: true });
    return Promise.reject("Invalid password");
  };

  fetchUserSettings = (response) => {
    if (response.status === 200) {
      return DataStore.fetchUserSettings();
    }
    return Promise.reject("Failed to update user settings");
  };

  navigateToDashboard = (userSettings) => {
    if (userSettings.id) {
      var userProfile = this.props.userProfile;
      let userType = AuthorizationService.getUserType(userProfile);
      if (
        userType === "Client" ||
        userType === "AccountingClient" ||
        userType === "SocietyMember" ||
        userType === "EasyID"
      ) {
        // Client
        this.props.history.push("/ClientDashboard");
      } else {
        //Lawyer, accountant and admin
        this.props.history.push("/Dashboard");
      }
    } else {
      return Promise.reject("Failed to fetch user settings");
    }
  };

  onGoToFront = () => {
    this.props.history.push("/");
    window.scrollTo(0, 0);
  };

  render() {
    var passwordClass = this.state.showPasswordWarning ? "axored" : null;
    return (
      <div
        style={{
          background: "#20434F",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div id="auth-container">
          <div id="auth-row">
            <div id="auth-cell">
              <Grid fluid>
                <Row>
                  <Col sm={12} className="text-center">
                    <h1
                      className="axowhite"
                      style={{ fontSize: 81, fontWeight: 300 }}
                    >
                      {this.state.time}
                    </h1>
                    <h6 className="axowhite">{this.state.date}</h6>
                  </Col>
                </Row>
                <Row className="flexbox-center" style={{ marginTop: 30 }}>
                  <Col sm={12} className="text-center">
                    <Form
                      className="flexbox-center"
                      onSubmit={this.onSubmitPassword}
                    >
                      <FormGroup controlId="lockFormGroup">
                        <ControlLabel style={{ color: "#fff" }}>
                          {StringService.enforceMaxLength(
                            UserInfoService.getDisplayName(
                              this.props.userProfile
                            ),
                            20
                          )}
                        </ControlLabel>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <img
                          alt=""
                          className="img-circle"
                          src={UserInfoService.getMyProfileImageSource(
                            this.props.userProfile
                          )}
                          width="auto"
                          height="150"
                        />
                        <br />
                        <div
                          className="flexbox-center"
                          style={{ paddingTop: "15px", maxWidth: "300px" }}
                        >
                          <AxoLocal
                            componentClass={FormControl}
                            className={passwordClass}
                            type="password"
                            value={this.state.password}
                            onChange={this.onChangePassword}
                            autoFocus
                            componentAttribute="placeholder"
                            entity="bccMailplaceholderpassword"
                          />
                        </div>
                        <div
                          style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        >
                          <Button className="Lex-button-2 " type="submit">
                            <AxoLocal
                              entity="Casesharing9D"
                              defaultValue={"Lås op"}
                            />
                          </Button>
                        </div>
                        <Link
                          to={RoutingService.getAccountPath("ForgotPassword")}
                          style={{ color: "white" }}
                        >
                          <AxoLocal
                            entity="LoginForgotUsersPassword"
                            defaultValue={"Glemt dit password?"}
                          />
                        </Link>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
        <div className="text-center" role="Button">
          <div onClick={this.onGoToFront}>
            <img
              alt=""
              height="300px"
              width="300px"
              src="/svg/Axolexlogo1.svg"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Lock);
