import React from "react";
import ApiService from "../services/DataAccess/ApiService";
import DataStore from "../services/DataAccess/DataStore";
import SubscriptionSelect from "./SubscriptionSelect";
import ContactForm from "./ContactForm";

import getScrollOffSet from "./getScrollOffSet";

import { Row, Col, Button, Panel, PanelGroup } from "react-bootstrap";
import { Icon, AxoLocal, withRouter } from "../utilities/LexUtilities";

function renderAccordionHeader(title, onClick) {
  return (
    <div onClick={onClick}>
      <Col xs={11} style={{ padding: "0px" }}>
        <span>{title}</span>
      </Col>
      <Col xs={1} style={{ padding: "0px", textAlign: "right" }}>
        <span style={{ textAlign: "right" }}>
          <Icon glyph="icon-fontello-arrow-combo" />
        </span>
      </Col>
    </div>
  );
}

class Packages extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCompletePackageDetails: false,
      activeIndividualAccordion: "",
      showPartnerContactForm: false,
    };
  }

  componentDidMount() {
    var scrollId = DataStore.getScrollId();
    if (scrollId) {
      setTimeout(() => {
        var element = document.getElementById(scrollId);
        this.onScrollToElement(element);
        DataStore.setScrollId("");
      }, 500);
    }
  }

  onScrollToElement(element) {
    if (!element) {
      //Can happen if the user navigates away before the scroll is initiated
      return;
    }
    element.scrollIntoView();
    window.scrollBy(0, getScrollOffSet());
  }

  onToggleCompletePackageDetails = () => {
    this.setState({
      showCompletePackageDetails: !this.state.showCompletePackageDetails,
    });
  };

  onHideCompletePackageDetails = () => {
    this.setState({ showCompletePackageDetails: false });
    document.getElementById("totalPackage").scrollIntoView();
  };

  activateIndividualAccordion = (eventKey) => {
    if (this.state.activeIndividualAccordion === eventKey) {
      this.setState({ activeIndividualAccordion: "" });
    } else {
      this.setState({ activeIndividualAccordion: eventKey }, () => {
        document.getElementById(eventKey).scrollIntoView();
      });
    }
  };

  onToggleContactForm = () => {
    this.setState({
      showPartnerContactForm: !this.state.showPartnerContactForm,
    });
  };

  onSelectPlan = (plan) => {
    DataStore.setSelectedPlan(plan);
    this.props.history.push("/Users/Signup/");
  };

  onSelectFormula = (viewModel) => {
    return ApiService.createSubscriptionPlanFromFormula(viewModel)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject("Could not create plan");
      })
      .then((newPlan) => {
        DataStore.setSelectedPlan(newPlan);
        this.props.history.push("/Users/Signup/");
      });
  };

  render() {
    let { showCompletePackageDetails } = this.state;

    return (
      <div className="text-center" style={{ minHeight: "800px" }}>
        <Col
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px" }}
        >
          <div id="totalPackage" className="frontPageaxolexdiv Bannecolor">
            <AxoLocal
              entity="FrontPageindextaxt8A"
              defaultValue={"Komplet pakkeløsning (Multi-sprog)"}
            />{" "}
          </div>
        </Col>
        <Col
          md={8}
          mdOffset={2}
          sm={12}
          xs={12}
          className="text-center nopadding"
        >
          <table
            className="axotable"
            style={{
              maxWidth: "100%",
              marginTop: "25px",
              borderColor: "white",
              paddingLeft: "2px",
              paddingRight: "2px",
            }}
          >
            <tbody>
              <tr>
                <th
                  className="text-center"
                  style={{
                    fontSize: " 17px",
                    lineHeight: "45px",
                    height: "65px",
                  }}
                >
                  {" "}
                  <AxoLocal
                    entity="IndividualPackagetext112"
                    defaultValue={"Brugere"}
                  />
                </th>
                <th
                  className="text-center axotpprice"
                  style={{
                    fontSize: " 17px",
                    lineHeight: "45px",
                    height: "65px",
                  }}
                >
                  <AxoLocal
                    entity="IndividualPackagetext113"
                    defaultValue={"Måneden"}
                  />{" "}
                </th>
                <th
                  className="text-center axotpprice"
                  style={{
                    fontSize: " 17px",
                    lineHeight: "45px",
                    height: "65px",
                  }}
                >
                  <AxoLocal
                    entity="IndividualPackagetext114"
                    defaultValue={"1 År"}
                  />
                </th>
              </tr>
              <tr>
                <td className="text-center flexbox-center">
                  <div className="axopointer axopointer3">
                    <div className="axopointer1 axopointer13">
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-user-add-outline"
                        style={{ fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <div style={{ width: "55px" }}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="IndividualPackagetext122"
                      defaultValue={"1-5"}
                    />
                  </div>
                </td>
                <td>
                  <AxoLocal
                    entity="IndividualPackagetext118"
                    defaultValue={"Individual aftale"}
                  />
                </td>
                <td>
                  <AxoLocal
                    entity="IndividualPackagetext119"
                    defaultValue={"10%"}
                  />{" "}
                </td>
              </tr>
              <tr>
                <td className="text-center flexbox-center">
                  <div className="axopointer axopointer3">
                    <div className="axopointer1 axopointer13">
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-users-outline"
                        style={{ fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <div style={{ width: "55px" }}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="IndividualPackagetext123"
                      defaultValue={"5+20"}
                    />
                  </div>
                </td>
                <td className="axotpprice">
                  <AxoLocal
                    entity="IndividualPackagetext118"
                    defaultValue={"Individual aftale"}
                  />
                </td>
                <td className="axotpprice">
                  <AxoLocal
                    entity="IndividualPackagetext119"
                    defaultValue={"10%"}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-center flexbox-center">
                  <div className="axopointer axopointer3">
                    <div className="axopointer1 axopointer13">
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-users-2"
                        style={{ fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <div style={{ width: "55px" }}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="IndividualPackagetext124"
                      defaultValue={"20-50"}
                    />
                  </div>
                </td>
                <td>
                  <AxoLocal
                    entity="IndividualPackagetext118"
                    defaultValue={"Individual aftale"}
                  />
                </td>
                <td>
                  <AxoLocal
                    entity="IndividualPackagetext119"
                    defaultValue={"10%"}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-center flexbox-center">
                  <div className="axopointer axopointer3">
                    <div className="axopointer1 axopointer13">
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-building"
                        style={{ fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <div style={{ width: "55px" }}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="IndividualPackagetext125A"
                      defaultValue={"150+"}
                    />
                  </div>
                </td>
                <td className="axotpprice">
                  {" "}
                  <AxoLocal
                    entity="IndividualPackagetext118"
                    defaultValue={"Individual aftale"}
                  />
                </td>
                <td className="axotpprice"></td>
              </tr>
              <tr>
                <td className="text-center flexbox-center">
                  <div className="axopointer axopointer3">
                    <div className="axopointer1 axopointer13">
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-building"
                        style={{ fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <div style={{ width: "55px" }}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="IndividualPackagetext125B"
                      defaultValue={"250+"}
                    />
                  </div>
                </td>
                <td>
                  {" "}
                  <AxoLocal
                    entity="IndividualPackagetext118"
                    defaultValue={"Individual aftale"}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td className="text-center flexbox-center">
                  <div className="axopointer axopointer3">
                    <div className="axopointer1 axopointer13">
                      <Icon
                        className="axolexwebsideicon"
                        glyph="icon-fontello-building"
                        style={{ fontSize: "30px" }}
                      />
                    </div>
                  </div>
                  <div style={{ width: "55px" }}>
                    &nbsp;&nbsp;&nbsp;
                    <AxoLocal
                      entity="IndividualPackagetext125C"
                      defaultValue={"500+"}
                    />
                  </div>
                </td>
                <td className="axotpprice">
                  {" "}
                  <AxoLocal
                    entity="IndividualPackagetext118"
                    defaultValue={"Individual aftale"}
                  />
                </td>
                <td className="axotpprice"></td>
              </tr>
            </tbody>
          </table>
          <br />

          <PanelGroup
            id="Packageb"
            accordion
            className="Lex-Accordion IndividualPackageDetal "
            activeKey={showCompletePackageDetails ? "1" : "0"}
            style={{
              borderRadius: "0px",
              paddingTop: "0px",
              borderBottom: "1px solid white",
            }}
          >
            <Panel className="backgroundlineargradient3" eventKey="1">
              <Panel.Heading>
                <Panel.Title toggle>
                  {renderAccordionHeader(
                    <span>
                      <AxoLocal
                        entity="IndividualPackagetext128"
                        defaultValue={"Detaljer komplet pakke"}
                      />
                    </span>,
                    this.onToggleCompletePackageDetails
                  )}
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible className="LexAccordionPanelody">
                <Row className="text-center" style={{ minHeight: "800px" }}>
                  <Col sm={6} className="text-center nopadding">
                    <div
                      className="table-responsive"
                      style={{ padding: "0px 30px 0px 5px" }}
                    >
                      <table
                        className="table borderless"
                        style={{
                          maxWidth: "100%",
                          borderWidth: "0px",
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                      >
                        <thead>
                          <tr>
                            {/* <th className=" text-left"><h2><AxoLocal entity='FrontPageindextaxt8' defaultValue={'Komplet pakkeløsning (Multi-sprog)'}/></h2></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                              <AxoLocal
                                entity="FrontPageindextaxt9"
                                defaultValue={"Mailsystem"}
                              />
                            </td>
                            <td className=" text-left AxoPackagetd"></td>
                          </tr>
                          <tr>
                            <td>
                              <div
                                className="text-left frontPageaxolexdivtextbox"
                                style={{ width: "400px" }}
                              >
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext1"
                                    defaultValue={"Multi-sprog"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext2"
                                    defaultValue={
                                      "Integrer dokumenter til sager"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext3"
                                    defaultValue={"Intern post"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext4"
                                    defaultValue={"Kryptering"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext5"
                                    defaultValue={"Ekstern mailhåndtering"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext6"
                                    defaultValue={"Del filer"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext7"
                                    defaultValue={"Mailhåndtering"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext8"
                                    defaultValue={"Mailmapper"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext9"
                                    defaultValue={"Sofistikeret tekst editor"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext10"
                                    defaultValue={"Importer word dokumenter"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext11"
                                    defaultValue={"Online backup"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext12"
                                    defaultValue={"Auto klientliste"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext13"
                                    defaultValue={"Auto brugerliste"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext81C"
                                    defaultValue={
                                      "Både intern og ekstern mail konto"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext111"
                                    defaultValue={"Og mere"}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className=" text-left">
                              <div className="text-left frontPageaxolexdivtextbox">
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                              <AxoLocal
                                entity="FrontPageindextaxt4"
                                defaultValue={"Fagligt netværk"}
                              />
                            </td>
                            <td className=" text-left AxoPackagetd"></td>
                          </tr>
                          <tr>
                            <td>
                              <div
                                className="text-left frontPageaxolexdivtextbox"
                                style={{ width: "400px" }}
                              >
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext14"
                                    defaultValue={
                                      "Internationalt fagligt netværk"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext15"
                                    defaultValue={"Internt fagligt netværk"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext16"
                                    defaultValue={"Kontakthåndtering"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext17"
                                    defaultValue={"Offentlige indlæg"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext18"
                                    defaultValue={"Interne indlæg"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext19"
                                    defaultValue={"Importer .docx dokumenter"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext20"
                                    defaultValue={
                                      "Særskilt social kontaktliste"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext21"
                                    defaultValue={"Multi-sprog"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext22"
                                    defaultValue={
                                      "Ubegrænset private konferencerum"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext23"
                                    defaultValue={
                                      "Sofistikeret tekstbehandling"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext24"
                                    defaultValue={"Publicer fagligt materiale"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext25"
                                    defaultValue={"Fildeling"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext26"
                                    defaultValue={"Filhåndtering"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext14"
                                    defaultValue={"Valgfrit fælles netværk"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext28"
                                    defaultValue={
                                      "Integrer filer med dokumenthåndtering"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext111"
                                    defaultValue={"Og mere"}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="text-left frontPageaxolexdivtextbox">
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                              <AxoLocal
                                entity="FrontPageindextaxt12"
                                defaultValue={"Sagsstyringssystem"}
                              />
                            </td>
                            <td className=" text-left AxoPackagetd"></td>
                          </tr>
                          <tr>
                            <td>
                              <div
                                style={{ width: "400px" }}
                                className="text-left frontPageaxolexdivtextbox"
                              >
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext29"
                                    defaultValue={"Multi-sprog"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext30"
                                    defaultValue={"Ubegrænset sagsfremstilling"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext31"
                                    defaultValue={"Sagsopsætning"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext32"
                                    defaultValue={
                                      "Centralisering af sagsmateriale"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext33"
                                    defaultValue={
                                      "Prioritet og status håndtering"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext34"
                                    defaultValue={"Tilknyt klient"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext35"
                                    defaultValue={"Beskriv modparter"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext36"
                                    defaultValue={"Sagsfremstilling"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext37"
                                    defaultValue={"Importer sagsfremstilling"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext38"
                                    defaultValue={"Printhåndtering"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext39"
                                    defaultValue={"Avanceret word processing"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext40"
                                    defaultValue={"Central sagsoversigt"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext41"
                                    defaultValue={"Kartotekskort oversigt"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext42"
                                    defaultValue={"Filtrering og søgning"}
                                  />{" "}
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext44"
                                    defaultValue={"Inline redigering"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext45"
                                    defaultValue={"Sagsdokumenthåndtering"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext46"
                                    defaultValue={"Tilknyt dokumenter til sag"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext47"
                                    defaultValue={"Administrer sagsdokumenter"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext48"
                                    defaultValue={
                                      "Registrer sagsbehandlingstid"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext49"
                                    defaultValue={"Påmindelse"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext50"
                                    defaultValue={
                                      "Integreret med økonomihåndtering"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext51"
                                    defaultValue={
                                      "Integreret med kalendersystem"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext111"
                                    defaultValue={"Og mere"}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="text-left frontPageaxolexdivtextbox">
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                              <AxoLocal
                                entity="FrontPageindextaxt20"
                                defaultValue={"Kalender"}
                              />
                            </td>
                            <td className=" text-left AxoPackagetd"></td>
                          </tr>
                          <tr>
                            <td>
                              <div
                                className="text-left frontPageaxolexdivtextbox"
                                style={{ width: "400px" }}
                              >
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext1"
                                    defaultValue={"Multi-sprog"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext100"
                                    defaultValue={"Aftaleoversigt"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext102"
                                    defaultValue={"Deadline påmindelser"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext103"
                                    defaultValue={"Påmindelser for dokumenter"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext104"
                                    defaultValue={"Aftaleoprettelse"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext105"
                                    defaultValue={"Booking tid oprettelse"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext106"
                                    defaultValue={"Tids- og aftaleredigering"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext107"
                                    defaultValue={"Drag-drop af aftaler og tid"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext108"
                                    defaultValue={
                                      "Oversigt over måned, uge og dagsorden"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="CalendarEventBoxFormreminders"
                                    defaultValue={"Påmindelser"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext81"
                                    defaultValue={
                                      "Integrering på tværs af systemer"
                                    }
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext111"
                                    defaultValue={"Og mere"}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="text-left frontPageaxolexdivtextbox">
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                              <AxoLocal
                                entity="FrontPageviewtext2"
                                defaultValue={"Mappesystem"}
                              />
                            </td>
                            <td className=" text-left AxoPackagetd"></td>
                          </tr>
                          <tr>
                            <td>
                              <div
                                className="text-left frontPageaxolexdivtextbox"
                                style={{ width: "400px" }}
                              >
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext8"
                                    defaultValue={"Mailmapper"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="LabelTabViewCaseFolders"
                                    defaultValue={"Sagsmapper"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="LabelTabViewDocumentfolders"
                                    defaultValue={"Dokumentmapper"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext109"
                                    defaultValue={"Ubegrænset mappeoprettelse"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext110"
                                    defaultValue={"Integration i hele systemet"}
                                  />
                                </div>
                                <div>
                                  &#9632;{" "}
                                  <AxoLocal
                                    entity="IndividualPackagetext111"
                                    defaultValue={"Og mere"}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="text-left frontPageaxolexdivtextbox">
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                                <div>&#10004;</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col sm={6} className="text-center nopadding">
                    <table
                      className="table borderless"
                      style={{ paddingLeft: "2px", paddingRight: "2px" }}
                    >
                      <tbody>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                            <AxoLocal
                              entity="FrontPageindextaxt18"
                              defaultValue={"Dokumenthåndtering"}
                            />
                          </td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="text-left frontPageaxolexdivtextbox"
                              style={{ width: "400px" }}
                            >
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext1"
                                  defaultValue={"Multi-sprog"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext63"
                                  defaultValue={"Upload dokumenter"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext64"
                                  defaultValue={"Drag-drop dokumenter"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext65"
                                  defaultValue={"Central dokumentoversigt"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext66"
                                  defaultValue={"Kartotekskort oversigt"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext67"
                                  defaultValue={"Søgning og sortering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext68"
                                  defaultValue={"Inline redigering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext69"
                                  defaultValue={"Status og prioritet"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext70"
                                  defaultValue={"Tilføj påmindelser"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext71"
                                  defaultValue={"Contract Management"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext72"
                                  defaultValue={"Mappetilknytning"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext25"
                                  defaultValue={"Fildeling"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext73"
                                  defaultValue={"Mailhåndtering af dokumenter"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext74"
                                  defaultValue={"Optag video og lyd"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext77"
                                  defaultValue={"Skabelonsfremstilling"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext78"
                                  defaultValue={"Importer skabelon"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext79"
                                  defaultValue={"PDF generering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext80"
                                  defaultValue={"Skabelondeling"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext81"
                                  defaultValue={
                                    "Integrering på tværs af systemer"
                                  }
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext111"
                                  defaultValue={"Og mere"}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-left frontPageaxolexdivtextbox">
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                            <AxoLocal
                              entity="FrontPageindextaxt13"
                              defaultValue={"Økonomistyring"}
                            />
                          </td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="text-left frontPageaxolexdivtextbox "
                              style={{ width: "400px" }}
                            >
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext1"
                                  defaultValue={"Multi-sprog"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="getHeaderTitletimeEntryAdded"
                                  defaultValue={"Tidsregistrering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext82"
                                  defaultValue={"Betalingstatus"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext83"
                                  defaultValue={"Udgiftshåndtering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext84"
                                  defaultValue={"Tilknyt klient eller sag"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext85"
                                  defaultValue={"Fakturafremstilling"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext86"
                                  defaultValue={"Fakturakonfigurering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext79"
                                  defaultValue={"PDF generering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext87"
                                  defaultValue={"Klient- og sagsfiltrering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext88"
                                  defaultValue={"Søgefunktionalitet"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext89"
                                  defaultValue={
                                    "Digital fakturering til klient"
                                  }
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext90"
                                  defaultValue={"Mailhåndtering af faktura"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext61"
                                  defaultValue={"Online betaling"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext91"
                                  defaultValue={"Påmindelse af betaling"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext111"
                                  defaultValue={"Og mere"}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-left frontPageaxolexdivtextbox">
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                            <AxoLocal
                              entity="FrontPageindextaxt19"
                              defaultValue={"Booking system"}
                            />
                          </td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="text-left frontPageaxolexdivtextbox"
                              style={{ width: "400px" }}
                            >
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext1"
                                  defaultValue={"Multi-sprog"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext92"
                                  defaultValue={"Opsæt online booking"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext93"
                                  defaultValue={"Drag-drop funktionalitet"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext94"
                                  defaultValue={"Fritekst beskrivelse"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext95"
                                  defaultValue={"Send booking link"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext96"
                                  defaultValue={"Plugin til egen hjemmeside"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext97"
                                  defaultValue={
                                    "Integreret med klient selvbetjeningspanel"
                                  }
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext98"
                                  defaultValue={"Godkend booking anmodninger"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext99"
                                  defaultValue={"Automatisk bekræftelse"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="CalendarEventBoxFormreminders"
                                  defaultValue={"Påmindelser"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext81"
                                  defaultValue={
                                    "Integrering på tværs af systemer"
                                  }
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext111"
                                  defaultValue={"Og mere"}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-left frontPageaxolexdivtextbox">
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                            <AxoLocal
                              entity="FrontPageindextaxt16"
                              defaultValue={"Klient selvbetjeningspanel"}
                            />
                          </td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="text-left frontPageaxolexdivtextbox"
                              style={{ width: "400px" }}
                            >
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext53"
                                  defaultValue={"Internt mailprogram"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext54"
                                  defaultValue={"Send krypterede beskeder"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext55"
                                  defaultValue={"Booking system"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext56"
                                  defaultValue={"Klient dokument håndtering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext57"
                                  defaultValue={"Dokumentsøgning og sortering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext58"
                                  defaultValue={"Optag video og lyd"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext59"
                                  defaultValue={"Send dokumenter"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext60"
                                  defaultValue={"Personoplysningshåndtering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext61"
                                  defaultValue={"Online betaling"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext1"
                                  defaultValue={"Multi-sprog"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext81"
                                  defaultValue={
                                    "Integrering på tværs af systemer"
                                  }
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext81A"
                                  defaultValue={
                                    "Ubegrænsede kundeselvbetjeningskonti"
                                  }
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext81B"
                                  defaultValue={
                                    "Kundens egen login og kontrolpanel"
                                  }
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext81C"
                                  defaultValue={
                                    "Både intern og ekstern mail konto"
                                  }
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext111"
                                  defaultValue={"Og mere"}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-left frontPageaxolexdivtextbox">
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold">
                            {" "}
                            <AxoLocal
                              entity="IndividualPackagetext167"
                              defaultValue={"Klient databehandling"}
                            />{" "}
                          </td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="text-left frontPageaxolexdivtextbox"
                              style={{ width: "400px" }}
                            >
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext168"
                                  defaultValue={"Ubegrænset klientregistrering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext169"
                                  defaultValue={"Klient type oversigt"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext171"
                                  defaultValue={"Central klientoversigt"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="CaseDataTableFixedShowCards"
                                  defaultValue={"Vis som kartotekskort"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext173"
                                  defaultValue={"Søgning og sortering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext174"
                                  defaultValue={"Fuld inline redigering"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext175"
                                  defaultValue={"Tilføj selvbetjening"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext176"
                                  defaultValue={"Klient selvbetjeningspanel"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext177"
                                  defaultValue={"Multi-sprog"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext178"
                                  defaultValue={"Integreret med sagsbehandling"}
                                />
                              </div>
                              <div>
                                &#9632;{" "}
                                <AxoLocal
                                  entity="IndividualPackagetext111"
                                  defaultValue={"Og mere"}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-left frontPageaxolexdivtextbox">
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row>
                  <Button
                    className="Lex-button-2"
                    style={{
                      borderRadius: "0px",
                      marginLeft: "0px",
                      marginRight: "0px",
                      width: "250px",
                    }}
                    onClick={this.onHideCompletePackageDetails}
                  >
                    <AxoLocal
                      entity="IndividualPackagetext129"
                      defaultValue={"Skjul detaljer"}
                    />
                  </Button>
                </Row>
              </Panel.Body>
            </Panel>
          </PanelGroup>

          {/* <Accordion className = 'Lex-Accordion' activeKey={this.state.showCompletePackageDetails ? '1' : '0'}>
          <Panel  className='backgroundlineargradient3'   
          header={renderAccordionHeader(<span> 
            <AxoLocal entity='IndividualPackagetext128' defaultValue={'Detaljer komplet pakke'}/> 
            </span>, this.onToggleCompletePackageDetails)} eventKey="1">
            <Row className='text-center' style={{ minHeight: '800px'}}>
                <Col sm={6} className="text-center nopadding" >
                  <div className="table-responsive" style={{padding: '0px 30px 0px 5px'}}>          
                    <table className="table borderless" style={{maxWidth: '100%',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
                        <thead>
                          <tr>
                            <th className=" text-left"><h2><AxoLocal entity='FrontPageindextaxt8' defaultValue={'Komplet pakkeløsning (Multi-sprog)'}/></h2></th> 
                          </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold"><AxoLocal entity='FrontPageindextaxt9' defaultValue={'Mailsystem'}/></td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr >
                          <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>   
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext2' defaultValue={'Integrer dokumenter til sager'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext3' defaultValue={'Intern post'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext4' defaultValue={'Kryptering'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext5' defaultValue={'Ekstern mailhåndtering'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext6' defaultValue={'Del filer'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext7' defaultValue={'Mailhåndtering'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext8' defaultValue={'Mailmapper'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext9' defaultValue={'Sofistikeret tekst editor'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext10' defaultValue={'Importer word dokumenter'}/></div>  
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext11' defaultValue={'Online backup'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext12' defaultValue={'Auto klientliste'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext13' defaultValue={'Auto brugerliste'}/></div> 
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext81C' defaultValue={'Både intern og ekstern mail konto'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>       
                                </div>  
                            </td>
                          <td className=" text-left">
                            <div className='text-left frontPageaxolexdivtextbox'>   
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    <div>&#10004;</div>
                                    <div>&#10004;</div> 
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>        
                                </div>  
                                </td>
                        
                        </tr>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold"><AxoLocal entity='FrontPageindextaxt4' defaultValue={'Fagligt netværk'}/></td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td><div  className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}> 
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext14' defaultValue={'Internationalt fagligt netværk'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext15' defaultValue={'Internt fagligt netværk'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext16' defaultValue={'Kontakthåndtering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext17' defaultValue={'Offentlige indlæg'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext18' defaultValue={'Interne indlæg'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext19' defaultValue={'Importer .docx dokumenter'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext20' defaultValue={'Særskilt social kontaktliste'}/></div>               
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext21' defaultValue={'Multi-sprog'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext22' defaultValue={'Ubegrænset private konferencerum'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext23' defaultValue={'Sofistikeret tekstbehandling'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext24' defaultValue={'Publicer fagligt materiale'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext25' defaultValue={'Fildeling'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext26' defaultValue={'Filhåndtering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext14' defaultValue={'Valgfrit fælles netværk'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext28' defaultValue={'Integrer filer med dokumenthåndtering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                  </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    </div>
                                    </td>
                        </tr>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold"><AxoLocal entity='FrontPageindextaxt12' defaultValue={'Sagsstyringssystem'}/></td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                          <tr>
                          <td><div  style={{ width: '400px' }} className='text-left frontPageaxolexdivtextbox'>   
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext29' defaultValue={'Multi-sprog'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext30' defaultValue={'Ubegrænset sagsfremstilling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext31' defaultValue={'Sagsopsætning'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext32' defaultValue={'Centralisering af sagsmateriale'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext33' defaultValue={'Prioritet og status håndtering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext34' defaultValue={'Tilknyt klient'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext35' defaultValue={'Beskriv modparter'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext36' defaultValue={'Sagsfremstilling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext37' defaultValue={'Importer sagsfremstilling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext38' defaultValue={'Printhåndtering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext39' defaultValue={'Avanceret word processing'}/></div>  
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext40' defaultValue={'Central sagsoversigt'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext41' defaultValue={'Kartotekskort oversigt'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext42' defaultValue={'Filtrering og søgning'}/> </div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext44' defaultValue={'Inline redigering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext45' defaultValue={'Sagsdokumenthåndtering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext46' defaultValue={'Tilknyt dokumenter til sag'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext47' defaultValue={'Administrer sagsdokumenter'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext48' defaultValue={'Registrer sagsbehandlingstid'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext49' defaultValue={'Påmindelse'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext50' defaultValue={'Integreret med økonomihåndtering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext51' defaultValue={'Integreret med kalendersystem'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    </div></td>
                        </tr>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold"><AxoLocal entity='FrontPageindextaxt20' defaultValue={'Kalender'}/></td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>
                                   <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext100' defaultValue={'Aftaleoversigt'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext102' defaultValue={'Deadline påmindelser'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext103' defaultValue={'Påmindelser for dokumenter'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext104' defaultValue={'Aftaleoprettelse'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext105' defaultValue={'Booking tid oprettelse'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext106' defaultValue={'Tids- og aftaleredigering'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext107' defaultValue={'Drag-drop af aftaler og tid'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext108' defaultValue={'Oversigt over måned, uge og dagsorden'}/></div>
                                    <div>&#9632; <AxoLocal entity='CalendarEventBoxFormreminders'defaultValue={'Påmindelser'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext81' defaultValue={'Integrering på tværs af systemer'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                  </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    </div></td>
                          </tr>

                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold"><AxoLocal entity='FrontPageviewtext2' defaultValue={'Mappesystem'}/></td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext8' defaultValue={'Mailmapper'}/></div>
                              <div>&#9632; <AxoLocal entity='LabelTabViewCaseFolders' defaultValue={'Sagsmapper'}/></div>
                              <div>&#9632; <AxoLocal entity='LabelTabViewDocumentfolders' defaultValue={'Dokumentmapper'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext109' defaultValue={'Ubegrænset mappeoprettelse'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext110' defaultValue={'Integration i hele systemet'}/></div>
                              <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                            </div></td>
                              <td><div  className='text-left frontPageaxolexdivtextbox'>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              <div>&#10004;</div>
                              </div></td>
                        </tr>

                       
                          

                      </tbody>
                    </table>
                  </div> 
                  </Col> */}
          {/* <Col sm={6} className="text-center nopadding"> */}
          {/* <table className="table borderless" style={{paddingLeft:'2px',paddingRight:'2px'}} >
                      <tbody>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold"><AxoLocal entity='FrontPageindextaxt18' defaultValue={'Dokumenthåndtering'}/></td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>   
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext63' defaultValue={'Upload dokumenter'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext64' defaultValue={'Drag-drop dokumenter'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext65' defaultValue={'Central dokumentoversigt'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext66' defaultValue={'Kartotekskort oversigt'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext67' defaultValue={'Søgning og sortering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext68' defaultValue={'Inline redigering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext69' defaultValue={'Status og prioritet'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext70' defaultValue={'Tilføj påmindelser'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext71' defaultValue={'Contract Management'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext72' defaultValue={'Mappetilknytning'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext25' defaultValue={'Fildeling'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext73' defaultValue={'Mailhåndtering af dokumenter'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext74' defaultValue={'Optag video og lyd'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext77' defaultValue={'Skabelonsfremstilling'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext78' defaultValue={'Importer skabelon'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext79' defaultValue={'PDF generering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext80' defaultValue={'Skabelondeling'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext81' defaultValue={'Integrering på tværs af systemer'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                  </div></td>
                                <td><div  className='text-left frontPageaxolexdivtextbox'>   
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    </div></td>
                        </tr>
                        <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold"><AxoLocal entity='FrontPageindextaxt13' defaultValue={'Økonomistyring'}/></td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td><div className='text-left frontPageaxolexdivtextbox ' style={{ width: '400px' }}>   
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                  <div>&#9632; <AxoLocal entity='getHeaderTitletimeEntryAdded' defaultValue={'Tidsregistrering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext82' defaultValue={'Betalingstatus'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext83' defaultValue={'Udgiftshåndtering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext84' defaultValue={'Tilknyt klient eller sag'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext85' defaultValue={'Fakturafremstilling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext86' defaultValue={'Fakturakonfigurering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext79' defaultValue={'PDF generering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext87' defaultValue={'Klient- og sagsfiltrering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext88' defaultValue={'Søgefunktionalitet'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext89' defaultValue={'Digital fakturering til klient'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext90' defaultValue={'Mailhåndtering af faktura'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext61' defaultValue={'Online betaling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext91' defaultValue={'Påmindelse af betaling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>  
                                </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    </div></td>
                            </tr> */}
          {/* <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold"><AxoLocal entity='FrontPageindextaxt19' defaultValue={'Booking system'}/></td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>   
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext92' defaultValue={'Opsæt online booking'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext93' defaultValue={'Drag-drop funktionalitet'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext94' defaultValue={'Fritekst beskrivelse'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext95' defaultValue={'Send booking link'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext96' defaultValue={'Plugin til egen hjemmeside'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext97' defaultValue={'Integreret med klient selvbetjeningspanel'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext98' defaultValue={'Godkend booking anmodninger'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext99' defaultValue={'Automatisk bekræftelse'}/></div>
                                  <div>&#9632; <AxoLocal entity='CalendarEventBoxFormreminders'defaultValue={'Påmindelser'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext81' defaultValue={'Integrering på tværs af systemer'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    </div></td>
                            </tr>
                             <tr>
                        <td className=" text-left AxoPackagetd Chfontcssbanne Axobold" ><AxoLocal entity='FrontPageindextaxt16' defaultValue={'Klient selvbetjeningspanel'}/></td>
                        <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                        <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>   
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext53' defaultValue={'Internt mailprogram'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext54' defaultValue={'Send krypterede beskeder'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext55' defaultValue={'Booking system'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext56' defaultValue={'Klient dokument håndtering'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext57' defaultValue={'Dokumentsøgning og sortering'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext58' defaultValue={'Optag video og lyd'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext59' defaultValue={'Send dokumenter'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext60' defaultValue={'Personoplysningshåndtering'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext61' defaultValue={'Online betaling'}/></div>
                                <div>&#9632;  <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext81' defaultValue={'Integrering på tværs af systemer'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext81A' defaultValue={'Ubegrænsede kundeselvbetjeningskonti'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext81B' defaultValue={'Kundens egen login og kontrolpanel'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext81C' defaultValue={'Både intern og ekstern mail konto'}/></div>
                                <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                
                              </div></td>
                        <td><div  className='text-left frontPageaxolexdivtextbox'>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>  
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>
                                  <div>&#10004;</div>  
                                  <div>&#10004;</div>
                                  </div></td> */}
          {/* </tr>
                          <tr>
                          <td className=" text-left AxoPackagetd Chfontcssbanne Axobold"> <AxoLocal entity='IndividualPackagetext167' defaultValue={'Klient databehandling'}/> </td>
                          <td className=" text-left AxoPackagetd"></td>
                        </tr>
                        <tr>
                          <td><div  className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}> 
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext168' defaultValue={'Ubegrænset klientregistrering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext169' defaultValue={'Klient type oversigt'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext171' defaultValue={'Central klientoversigt'}/></div>
                                  <div>&#9632; <AxoLocal entity='CaseDataTableFixedShowCards' defaultValue={'Vis som kartotekskort'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext173' defaultValue={'Søgning og sortering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext174' defaultValue={'Fuld inline redigering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext175' defaultValue={'Tilføj selvbetjening'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext176' defaultValue={'Klient selvbetjeningspanel'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext177' defaultValue={'Multi-sprog'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext178' defaultValue={'Integreret med sagsbehandling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                  
                                  </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    </div>
                                    </td>
                        </tr> */}

          {/* </tbody>
                    </table>
                  </Col>
                </Row>
                <Row>
                  <Button className='Lex-button-2' style={{borderRadius: '0px',marginLeft: '0px',marginRight: '0px', width: '250px'}} onClick={this.onHideCompletePackageDetails}>
                    <AxoLocal entity='IndividualPackagetext129' defaultValue={'Skjul detaljer'}/>
                  </Button>
                </Row>

          </Panel>
        </Accordion> */}
          <br />
        </Col>
        <Col
          xs={12}
          id="trial"
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", paddingBottom: "25px" }}
        >
          <div id="partnerPackage" className="frontPageaxolexdiv Bannecolor">
            <AxoLocal
              entity="FrontPageviewtext3a"
              defaultValue={"Brug vores helt gratis version"}
            />
          </div>
        </Col>
        <Col xs={12} className="nopadding" style={{ maxWidth: "100%" }}>
          <SubscriptionSelect
            subscriptionPlans={this.props.subscriptionPlans}
            subscriptionFormulas={this.props.subscriptionFormulas}
            onSelectPlan={this.onSelectPlan}
            onSelectFormula={this.onSelectFormula}
            userProfile={this.props.userProfile}
            globalSettings={this.props.globalSettings}
            locale={this.props.locale}
          />
        </Col>
        <Col
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px" }}
        >
          <div id="partnerPackage" className="frontPageaxolexdiv Bannecolor">
            <AxoLocal
              entity="FrontPageviewtext3"
              defaultValue={"Partner virksomheder"}
            />
          </div>
        </Col>
        <Col
          md={8}
          mdOffset={2}
          sm={12}
          xs={12}
          className="text-center nopadding"
          style={{ maxWidth: "100%", marginTop: "25px" }}
        >
          <Col
            md={6}
            className="nopadding"
            style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
          >
            <div className="Axolextextbox marginRighttextbox">
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext130"
                  defaultValue={
                    "Velkommen til AxoLex` partner netværk. Vores filosofi er at forbedre vores system hver eneste dag og at opbygge et stærkt fagligt netværk på tværs af internationale grænser. Vi vil gerne fjerne sprogbarrieren mellem alle lande."
                  }
                />
              </div>
              <div className="Axolextextbox ">
                <AxoLocal
                  entity="IndividualPackagetext131"
                  defaultValue={
                    "Hvis du synes godt om vores filosofi, og har interesse til at være en del af vores team, så leder videreudvikle efter partnere til følgende opgaver i alle lande/områder:"
                  }
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext132"
                  defaultValue={"Vidersalg af Axolex licenser."}
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext133"
                  defaultValue={
                    "Implementering af Axolex systemer for advokatkontorer overalt i verden."
                  }
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext134"
                  defaultValue={
                    "Levere lokal bruger support for AxoLex`s end-brugere."
                  }
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext135"
                  defaultValue={
                    "(Har vores system ikke dit lands sprog? Vi er i stand til at tilføje nye sprog på under en uge. Så kontakt os endelig så du kan bidrage fra første dag.)"
                  }
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext136"
                  defaultValue={
                    "Kontakt os allerede i dag for at få flere detaljer om partnerskabet."
                  }
                />
              </div>
            </div>
            <br />
            <div style={{ paddingRight: "10px" }}>
              <PanelGroup
                id="Packagec"
                accordion
                className="Lex-Accordion IndividualPackageDetal "
                defaultActiveKey="0"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  borderBottom: "1px solid white",
                }}
              >
                <Panel className="backgroundlineargradient3" eventKey="1">
                  <Panel.Heading>
                    <Panel.Title toggle>
                      {renderAccordionHeader(
                        <span>
                          <AxoLocal
                            entity="Companyaboutostaxt25"
                            defaultValue={"Kontakt"}
                          />
                        </span>
                      )}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible className="LexAccordionPanelody">
                    <ContactForm
                      messageLabel={
                        <AxoLocal
                          entity="socialSidebarContainercountryA"
                          defaultValue={"Besked"}
                        />
                      }
                      countryLabel={
                        <AxoLocal
                          entity="socialSidebarContainercountry"
                          defaultValue={"Land du vil repræsentere."}
                        />
                      }
                      sendLabel={
                        <AxoLocal
                          entity="ContactFormApply"
                          defaultValue={"Ansøg"}
                        />
                      }
                    />
                  </Panel.Body>
                </Panel>
              </PanelGroup>

              {/* <Accordion defaultActiveKey='0' className = 'Lex-Accordion'>
                      <Panel className='backgroundlineargradient3 compact' 
                        header={renderAccordionHeader(<span><AxoLocal entity='Companyaboutostaxt25' defaultValue={'Kontakt'}/></span>)} 
                        eventKey="1">
                          <ContactForm
                            messageLabel={<AxoLocal entity='socialSidebarContainercountryA' defaultValue={'Besked'}/>}
                            countryLabel={<AxoLocal entity='socialSidebarContainercountry' defaultValue={'Land du vil repræsentere.'}/>}
                            sendLabel={<AxoLocal entity='ContactFormApply' defaultValue={'Ansøg'}/>} 
                          />
                      </Panel>
                    </Accordion> */}
            </div>
          </Col>

          <Col
            md={6}
            className="nopadding"
            style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
          >
            <div>
              <img
                alt=""
                src="/svg/Axolex6 -2.jpg"
                className="bg_img"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </Col>
        </Col>

        <Col
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px" }}
        >
          <div id="tailoredPackage" className="frontPageaxolexdiv Bannecolor">
            <AxoLocal
              entity="FrontPageviewtext4"
              defaultValue={"Skræddersyet løsning"}
            />
          </div>
        </Col>
        <Col
          md={8}
          mdOffset={2}
          sm={12}
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px" }}
        >
          <Col
            md={6}
            className="nopadding"
            style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
          >
            <div>
              <img
                alt=""
                src="/svg/Axolex6 -1.jpg"
                className="bg_img"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </Col>
          <Col
            md={6}
            className="nopadding"
            style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
          >
            <div className="Axolextextbox marginLefttextbox">
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext137"
                  defaultValue={"Fleksibel løsning:"}
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext138"
                  defaultValue={
                    "Passer vores system ikke fuldstændig til dine behov? Så har vi mulighed for at tilpasse systemet, så det passer perfekt netop til dig."
                  }
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext139"
                  defaultValue={
                    "Hvis du er en stærkt faglig person, som har din egen måde at gøre tingene på, så har du mulighed for at være med til at tilpasse systemet til dine ønsker og behov."
                  }
                />
              </div>
            </div>
            <br />
            <div style={{ paddingLeft: "10px" }}>
              <PanelGroup
                id="Packaged"
                accordion
                className="Lex-Accordion IndividualPackageDetal "
                defaultActiveKey="0"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  borderBottom: "1px solid white",
                }}
              >
                <Panel className="backgroundlineargradient3" eventKey="1">
                  <Panel.Heading>
                    <Panel.Title toggle>
                      {renderAccordionHeader(
                        <span>
                          <AxoLocal
                            entity="Companyaboutostaxt25"
                            defaultValue={"Kontakt"}
                          />
                        </span>
                      )}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible className="LexAccordionPanelody">
                    <ContactForm />
                  </Panel.Body>
                </Panel>
              </PanelGroup>
              {/* <Accordion defaultActiveKey='0' className = 'Lex-Accordion'>
                        <Panel className='backgroundlineargradient3 compact'  
                          header={renderAccordionHeader(<span><AxoLocal entity='Companyaboutostaxt25' defaultValue={'Kontakt'}/></span>)} 
                          eventKey="1">
                            <ContactForm  />
                        </Panel>
                      </Accordion> */}
            </div>
          </Col>
        </Col>

        <Col
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px" }}
        >
          <div id="individualPackage" className="frontPageaxolexdiv Bannecolor">
            <AxoLocal
              entity="FrontPageviewtext5"
              defaultValue={"Individuel løsning:"}
            />
          </div>
        </Col>
        <Col
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
        >
          <Col
            md={8}
            mdOffset={2}
            sm={12}
            xs={12}
            className="text-left nopadding"
          >
            <div className="Axolextextbox">
              <AxoLocal
                entity="IndividualPackagetext140"
                defaultValue={
                  "Vi anbefaler stærkt at købe vores komplette IT-løsning, specielt i vores nuværende promoveringsperiode. Fordele: I den komplette pakke er alle funktioner i de forskellige dele fuldt integreret på tværs af systemet. Økonomisk besparelse "
                }
              />
            </div>
            <div>
              <table
                className="axotable"
                style={{
                  maxWidth: "100%",
                  marginTop: "25px",
                  paddingLeft: "2px",
                  paddingRight: "2px",
                }}
              >
                {/* <tr>
                <th className="text-left"  style={{fontSize:' 17px',lineHeight: '45px',paddingLeft: '25px'}}>
                  <AxoLocal entity='IndividualPackagetext141' defaultValue={'Systemet'}/></th>
                <th className="text-center"  style={{fontSize:' 17px',lineHeight: '45px'}} ><AxoLocal entity='IndividualPackagetext113' defaultValue={'Måneden'}/> </th>
              </tr> */}
                <tbody>
                  <tr>
                    <td id="1" className="text-left">
                      <div
                        id="2"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-fontello-mail-1"
                          style={{ fontSize: "40px" }}
                        />
                        <span className="Chfontcssbanne Axobold">
                          &nbsp;&nbsp;&nbsp;
                          <AxoLocal
                            entity="FrontPageindextaxt9"
                            defaultValue={"Mailsystem"}
                          />
                        </span>
                      </div>
                      <PanelGroup
                        id="Packagee"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(this, "1")
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox"
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext1"
                                          defaultValue={"Multi-sprog"}
                                        />{" "}
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext2"
                                          defaultValue={
                                            "Integrer dokumenter til sager"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext3"
                                          defaultValue={"Intern post"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext4"
                                          defaultValue={"Kryptering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext5"
                                          defaultValue={
                                            "Ekstern mailhåndtering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext6"
                                          defaultValue={"Del filer"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext7"
                                          defaultValue={"Mailhåndtering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext8"
                                          defaultValue={"Mailmapper"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext9"
                                          defaultValue={
                                            "Sofistikeret tekst editor"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext10"
                                          defaultValue={
                                            "Importer word dokumenter"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext11"
                                          defaultValue={"Online backup"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext12"
                                          defaultValue={"Auto klientliste"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext13"
                                          defaultValue={"Auto brugerliste"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81C"
                                          defaultValue={
                                            "Både intern og ekstern mail konto"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className=" text-left">
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3'   
          header={renderAccordionHeader(<span> 
            <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/>
             
            </span>, this.activateIndividualAccordion.bind(this, '1'))} eventKey="1"> 
              <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
              <tbody>
              <tr>
                  <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>   
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/>   </div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext2' defaultValue={'Integrer dokumenter til sager'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext3' defaultValue={'Intern post'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext4' defaultValue={'Kryptering'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext5' defaultValue={'Ekstern mailhåndtering'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext6' defaultValue={'Del filer'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext7' defaultValue={'Mailhåndtering'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext8' defaultValue={'Mailmapper'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext9' defaultValue={'Sofistikeret tekst editor'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext10' defaultValue={'Importer word dokumenter'}/></div>  
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext11' defaultValue={'Online backup'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext12' defaultValue={'Auto klientliste'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext13' defaultValue={'Auto brugerliste'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext81C' defaultValue={'Både intern og ekstern mail konto'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>        
                    </div>  
                  </td>
                <td className=" text-left">
                  <div className='text-left frontPageaxolexdivtextbox'>   
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>  
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>       
                      </div>  
                </td>
            </tr>
            </tbody>
            </table>          
            </Panel>
          </Accordion> */}
                    </td>
                  </tr>

                  <tr>
                    <td className="text-left">
                      <div
                        id="3"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-fontello-users-outline"
                          style={{ fontSize: "40px" }}
                        />
                        <span className="Chfontcssbanne Axobold">
                          &nbsp;&nbsp;&nbsp;
                          <AxoLocal
                            entity="IndividualPackagetext167"
                            defaultValue={"Klient databehandling"}
                          />
                        </span>
                      </div>

                      <PanelGroup
                        id="Packagef"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(this, "2")
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox"
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext168"
                                          defaultValue={
                                            "Ubegrænset klientregistrering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext169"
                                          defaultValue={"Klient type oversigt"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext171"
                                          defaultValue={
                                            "Central klientoversigt"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="CaseDataTableFixedShowCards"
                                          defaultValue={"Vis som kartotekskort"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext173"
                                          defaultValue={"Søgning og sortering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext174"
                                          defaultValue={
                                            "Fuld inline redigering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext175"
                                          defaultValue={"Tilføj selvbetjening"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext176"
                                          defaultValue={
                                            "Klient selvbetjeningspanel"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext177"
                                          defaultValue={"Multi-sprog"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext178"
                                          defaultValue={
                                            "Integreret med sagsbehandling"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81A"
                                          defaultValue={
                                            "Ubegrænsede kundeselvbetjeningskonti"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81B"
                                          defaultValue={
                                            "Kundens egen login og kontrolpanel"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81C"
                                          defaultValue={
                                            "Både intern og ekstern mail konto"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className=" text-left">
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3' 
          header={renderAccordionHeader(<span> 
            <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/>
             
            </span>, this.activateIndividualAccordion.bind(this, '2'))} eventKey="2"> 
              <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
              <tbody>
              <tr>
                  <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>   
                   <div>&#9632; <AxoLocal entity='IndividualPackagetext168' defaultValue={'Ubegrænset klientregistrering'}/></div>
                   <div>&#9632; <AxoLocal entity='IndividualPackagetext169' defaultValue={'Klient type oversigt'}/></div>
                   <div>&#9632; <AxoLocal entity='IndividualPackagetext171' defaultValue={'Central klientoversigt'}/></div>
                   <div>&#9632; <AxoLocal entity='CaseDataTableFixedShowCards' defaultValue={'Vis som kartotekskort'}/></div>
                   <div>&#9632; <AxoLocal entity='IndividualPackagetext173' defaultValue={'Søgning og sortering'}/></div>
                   <div>&#9632; <AxoLocal entity='IndividualPackagetext174' defaultValue={'Fuld inline redigering'}/></div>
                   <div>&#9632; <AxoLocal entity='IndividualPackagetext175' defaultValue={'Tilføj selvbetjening'}/></div>
                   <div>&#9632; <AxoLocal entity='IndividualPackagetext176' defaultValue={'Klient selvbetjeningspanel'}/></div>
                   <div>&#9632; <AxoLocal entity='IndividualPackagetext177' defaultValue={'Multi-sprog'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext178' defaultValue={'Integreret med sagsbehandling'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext81A' defaultValue={'Ubegrænsede kundeselvbetjeningskonti'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext81B' defaultValue={'Kundens egen login og kontrolpanel'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext81C' defaultValue={'Både intern og ekstern mail konto'}/></div>
                    <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>      
                    </div>  
                  </td>
                <td className=" text-left">
                  <div className='text-left frontPageaxolexdivtextbox'>   
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>  
                          <div>&#10004;</div>
                          <div>&#10004;</div>
                          <div>&#10004;</div>

                      </div>  
                </td>
            </tr>
            </tbody>
            </table>          
            </Panel>
          </Accordion> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div
                        id="4"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-fontello-network-1"
                          style={{ fontSize: "40px" }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="Chfontcssbanne Axobold">
                          <AxoLocal
                            entity="FrontPageindextaxt4"
                            defaultValue={"Fagligt netværk"}
                          />
                        </span>
                      </div>

                      <PanelGroup
                        id="Packageg"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(this, "3")
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox"
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext14"
                                          defaultValue={
                                            "Internationalt fagligt netværk"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext15"
                                          defaultValue={
                                            "Internt fagligt netværk"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext16"
                                          defaultValue={"Kontakthåndtering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext17"
                                          defaultValue={"Offentlige indlæg"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext18"
                                          defaultValue={"Interne indlæg"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext19"
                                          defaultValue={
                                            "Importer .docx dokumenter"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext20"
                                          defaultValue={
                                            "Særskilt social kontaktliste"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext1"
                                          defaultValue={"Multi-sprog"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext22"
                                          defaultValue={
                                            "Ubegrænset private konferencerum"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext23"
                                          defaultValue={
                                            "Sofistikeret tekstbehandling"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext24"
                                          defaultValue={
                                            "Publicer fagligt materiale"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext25"
                                          defaultValue={"Fildeling"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext26"
                                          defaultValue={"Filhåndtering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext28"
                                          defaultValue={
                                            "Integrer filer med dokumenthåndtering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div
                        id="5"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-simple-line-icons-briefcase"
                          style={{ fontSize: "40px" }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="Chfontcssbanne Axobold">
                          <AxoLocal
                            entity="FrontPageindextaxt12"
                            defaultValue={"Sagsstyringssystem"}
                          />
                        </span>
                      </div>
                      <PanelGroup
                        id="ITsubscriptionb"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(this, "4")
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      style={{ width: "400px" }}
                                      className="text-left frontPageaxolexdivtextbox"
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext1"
                                          defaultValue={"Multi-sprog"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext30"
                                          defaultValue={
                                            "Ubegrænset sagsfremstilling"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext31"
                                          defaultValue={"Sagsopsætning"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext32"
                                          defaultValue={
                                            "Centralisering af sagsmateriale"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext33"
                                          defaultValue={
                                            "Prioritet og status håndtering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext34"
                                          defaultValue={"Tilknyt klient"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext35"
                                          defaultValue={"Beskriv modparter"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext36"
                                          defaultValue={"Sagsfremstilling"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext37"
                                          defaultValue={
                                            "Importer sagsfremstilling"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext38"
                                          defaultValue={"Printhåndtering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext39"
                                          defaultValue={
                                            "Avanceret word processing"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext40"
                                          defaultValue={"Central sagsoversigt"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext41"
                                          defaultValue={
                                            "Kartotekskort oversigt"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext42"
                                          defaultValue={"Filtrering og søgning"}
                                        />{" "}
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext44"
                                          defaultValue={"Inline redigering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext45"
                                          defaultValue={
                                            "Sagsdokumenthåndtering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext46"
                                          defaultValue={
                                            "Tilknyt dokumenter til sag"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext47"
                                          defaultValue={
                                            "Administrer sagsdokumenter"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext48"
                                          defaultValue={
                                            "Registrer sagsbehandlingstid"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext49"
                                          defaultValue={"Påmindelse"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext50"
                                          defaultValue={
                                            "Integreret med økonomihåndtering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext51"
                                          defaultValue={
                                            "Integreret med kalendersystem"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3'  
          header={renderAccordionHeader(<span> 
            <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/>
            </span>, this.activateIndividualAccordion.bind(this, '4'))} eventKey="4">
        <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
        <tbody>
          <tr>
            <td><div style={{ width: '400px' }} className='text-left frontPageaxolexdivtextbox'>   
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext30' defaultValue={'Ubegrænset sagsfremstilling'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext31' defaultValue={'Sagsopsætning'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext32' defaultValue={'Centralisering af sagsmateriale'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext33' defaultValue={'Prioritet og status håndtering'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext34' defaultValue={'Tilknyt klient'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext35' defaultValue={'Beskriv modparter'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext36' defaultValue={'Sagsfremstilling'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext37' defaultValue={'Importer sagsfremstilling'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext38' defaultValue={'Printhåndtering'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext39' defaultValue={'Avanceret word processing'}/></div>  
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext40' defaultValue={'Central sagsoversigt'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext41' defaultValue={'Kartotekskort oversigt'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext42' defaultValue={'Filtrering og søgning'}/> </div>  
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext44' defaultValue={'Inline redigering'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext45' defaultValue={'Sagsdokumenthåndtering'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext46' defaultValue={'Tilknyt dokumenter til sag'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext47' defaultValue={'Administrer sagsdokumenter'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext48' defaultValue={'Registrer sagsbehandlingstid'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext49' defaultValue={'Påmindelse'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext50' defaultValue={'Integreret med økonomihåndtering'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext51' defaultValue={'Integreret med kalendersystem'}/></div>
                  <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
              </div></td>
            <td><div  className='text-left frontPageaxolexdivtextbox'>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>  
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      <div>&#10004;</div>
                      </div></td>
                      </tr>
          </tbody>
        </table>
             
          </Panel>
          </Accordion> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div
                        id="6"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-fontello-layers"
                          style={{ fontSize: "40px" }}
                        />{" "}
                        &nbsp;&nbsp;&nbsp;
                        <span className="Chfontcssbanne Axobold">
                          <AxoLocal
                            entity="FrontPageindextaxt13"
                            defaultValue={"Økonomistyring"}
                          />
                        </span>
                      </div>

                      <PanelGroup
                        id="Packageh"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(this, "5")
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox "
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext1"
                                          defaultValue={"Multi-sprog"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="getHeaderTitletimeEntryAdded"
                                          defaultValue={"Tidsregistrering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext82"
                                          defaultValue={"Betalingstatus"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext83"
                                          defaultValue={"Udgiftshåndtering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext84"
                                          defaultValue={
                                            "Tilknyt klient eller sag"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext85"
                                          defaultValue={"Fakturafremstilling"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext86"
                                          defaultValue={"Fakturakonfigurering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext79"
                                          defaultValue={"PDF generering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext87"
                                          defaultValue={
                                            "Klient- og sagsfiltrering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext88"
                                          defaultValue={"Søgefunktionalitet"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext89"
                                          defaultValue={
                                            "Digital fakturering til klient"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext90"
                                          defaultValue={
                                            "Mailhåndtering af faktura"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext61"
                                          defaultValue={"Online betaling"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext91"
                                          defaultValue={
                                            "Påmindelse af betaling"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3'   
          header={renderAccordionHeader(<span> 
            <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/> 
            </span>, this.activateIndividualAccordion.bind(this, '5'))} eventKey="5">
            <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
            <tbody>
          <tr>
              <td><div className='text-left frontPageaxolexdivtextbox '  style={{ width: '400px' }}>   
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                  <div>&#9632; <AxoLocal entity='getHeaderTitletimeEntryAdded' defaultValue={'Tidsregistrering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext82' defaultValue={'Betalingstatus'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext83' defaultValue={'Udgiftshåndtering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext84' defaultValue={'Tilknyt klient eller sag'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext85' defaultValue={'Fakturafremstilling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext86' defaultValue={'Fakturakonfigurering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext79' defaultValue={'PDF generering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext87' defaultValue={'Klient- og sagsfiltrering'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext88' defaultValue={'Søgefunktionalitet'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext89' defaultValue={'Digital fakturering til klient'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext90' defaultValue={'Mailhåndtering af faktura'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext61' defaultValue={'Online betaling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext91' defaultValue={'Påmindelse af betaling'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div> 
                                </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    </div></td>
        </tr>
        </tbody>
        </table>

             
          </Panel>
          </Accordion> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div
                        id="7"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-fontello-credit-card-1"
                          style={{ fontSize: "40px" }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="Chfontcssbanne Axobold">
                          {" "}
                          <AxoLocal
                            entity="FrontPageindextaxt14"
                            defaultValue={"Online betalingssystem"}
                          />
                        </span>
                      </div>
                      <PanelGroup
                        id="Packagei"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(this, "6")
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox "
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        <AxoLocal
                                          entity="FrontPageindextaxt14B"
                                          defaultValue={
                                            "Fuldt online betalingssystem"
                                          }
                                        />
                                      </div>
                                      <div>
                                        <AxoLocal
                                          entity="FrontPageindextaxt14C"
                                          defaultValue={
                                            "Integreret med kundeselvbetjening og fakturering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        <AxoLocal
                                          entity="FrontPageindextaxt14D"
                                          defaultValue={
                                            "Automatisk registrering af faktura betaling"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3'  
          header={renderAccordionHeader(<span> 
            <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/>  
            </span>, this.activateIndividualAccordion.bind(this, '6'))} eventKey="6">
            <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
            <tbody>
          <tr>
          <td><div className='text-left frontPageaxolexdivtextbox '  style={{ width: '400px' }}>   
                  <div><AxoLocal entity='FrontPageindextaxt14B' defaultValue={'Fuldt online betalingssystem'}/></div>
                  <div><AxoLocal entity='FrontPageindextaxt14C' defaultValue={'Integreret med kundeselvbetjening og fakturering'}/></div>
                  <div><AxoLocal entity='FrontPageindextaxt14D' defaultValue={'Automatisk registrering af faktura betaling'}/></div>
                </div></td>
                <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    </div></td>
          </tr>
          </tbody>
        </table>

          </Panel>
          </Accordion> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div
                        id="8"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-fontello-desktop
"
                          style={{ fontSize: "40px" }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="Chfontcssbanne Axobold">
                          <AxoLocal
                            entity="FrontPageindextaxt16"
                            defaultValue={"Klient selvbetjeningspanel"}
                          />
                        </span>
                      </div>
                      <PanelGroup
                        id="Packageaj"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(this, "7")
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox"
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext53"
                                          defaultValue={"Internt mailprogram"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext54"
                                          defaultValue={
                                            "Send krypterede beskeder"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext55"
                                          defaultValue={"Booking system"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext56"
                                          defaultValue={
                                            "Klient dokument håndtering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext57"
                                          defaultValue={
                                            "Dokumentsøgning og sortering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext58"
                                          defaultValue={"Optag video og lyd"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext59"
                                          defaultValue={"Send dokumenter"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext60"
                                          defaultValue={
                                            "Personoplysningshåndtering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext61"
                                          defaultValue={"Online betaling"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext1"
                                          defaultValue={"Multi-sprog"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext62"
                                          defaultValue={
                                            "Integrering på tværs af systemer"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81A"
                                          defaultValue={
                                            "Ubegrænsede kundeselvbetjeningskonti"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81B"
                                          defaultValue={
                                            "Kundens egen login og kontrolpanel"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81C"
                                          defaultValue={
                                            "Både intern og ekstern mail konto"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3'     
          header={renderAccordionHeader(<span> 
            <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/> 
            </span>, this.activateIndividualAccordion.bind(this, '7'))} eventKey="7">
            <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
            <tbody>
          <tr>
               <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>   
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext53' defaultValue={'Internt mailprogram'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext54' defaultValue={'Send krypterede beskeder'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext55' defaultValue={'Booking system'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext56' defaultValue={'Klient dokument håndtering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext57' defaultValue={'Dokumentsøgning og sortering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext58' defaultValue={'Optag video og lyd'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext59' defaultValue={'Send dokumenter'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext60' defaultValue={'Personoplysningshåndtering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext61' defaultValue={'Online betaling'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext62' defaultValue={'Integrering på tværs af systemer'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext81A' defaultValue={'Ubegrænsede kundeselvbetjeningskonti'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext81B' defaultValue={'Kundens egen login og kontrolpanel'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext81C' defaultValue={'Både intern og ekstern mail konto'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    <div>&#10004;</div>
                                    </div></td>
        </tr>
        </tbody>
        </table>
          </Panel>
          </Accordion> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div
                        id="9"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-fontello-doc-7"
                          style={{ fontSize: "40px" }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="Chfontcssbanne Axobold">
                          <AxoLocal
                            entity="FrontPageindextaxt18"
                            defaultValue={"Dokumenthåndtering"}
                          />
                        </span>
                      </div>
                      <PanelGroup
                        id="Packageak"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(this, "8")
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox"
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext1"
                                          defaultValue={"Multi-sprog"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext63"
                                          defaultValue={"Upload dokumenter"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext64"
                                          defaultValue={"Drag-drop dokumenter"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext65"
                                          defaultValue={
                                            "Central dokumentoversigt"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext66"
                                          defaultValue={
                                            "Kartotekskort oversigt"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext67"
                                          defaultValue={"Søgning og sortering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext68"
                                          defaultValue={"Inline redigering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext69"
                                          defaultValue={"Status og prioritet"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext70"
                                          defaultValue={"Tilføj påmindelser"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext71"
                                          defaultValue={"Contract Management"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext72"
                                          defaultValue={"Mappetilknytning"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext25"
                                          defaultValue={"Fildeling"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext73"
                                          defaultValue={
                                            "Mailhåndtering af dokumenter"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext74"
                                          defaultValue={"Optag video og lyd"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext77"
                                          defaultValue={"Skabelonsfremstilling"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext78"
                                          defaultValue={"Importer skabelon"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext79"
                                          defaultValue={"PDF generering"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext80"
                                          defaultValue={"Skabelondeling"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81"
                                          defaultValue={
                                            "Integrering på tværs af systemer"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3'   
          header={renderAccordionHeader(<span> 
           <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/>
            </span>, this.activateIndividualAccordion.bind(this, '8'))} eventKey="8">
            <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
            <tbody>
          <tr>
              <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>   
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext63' defaultValue={'Upload dokumenter'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext64' defaultValue={'Drag-drop dokumenter'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext65' defaultValue={'Central dokumentoversigt'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext66' defaultValue={'Kartotekskort oversigt'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext67' defaultValue={'Søgning og sortering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext68' defaultValue={'Inline redigering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext69' defaultValue={'Status og prioritet'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext70' defaultValue={'Tilføj påmindelser'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext71' defaultValue={'Contract Management'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext72' defaultValue={'Mappetilknytning'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext25' defaultValue={'Fildeling'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext73' defaultValue={'Mailhåndtering af dokumenter'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext74' defaultValue={'Optag video og lyd'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext77' defaultValue={'Skabelonsfremstilling'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext78' defaultValue={'Importer skabelon'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext79' defaultValue={'PDF generering'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext80' defaultValue={'Skabelondeling'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext81' defaultValue={'Integrering på tværs af systemer'}/></div>
                                      <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                    
                                  </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                               
                                    </div></td>
        </tr>
        </tbody>
        </table>   
          </Panel>
          </Accordion> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div
                        id="10"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-fontello-clock-4"
                          style={{ fontSize: "40px" }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="Chfontcssbanne Axobold">
                          <AxoLocal
                            entity="FrontPageindextaxt19"
                            defaultValue={"Booking system"}
                          />
                        </span>
                      </div>

                      <PanelGroup
                        id="Packageal"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(this, "9")
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox"
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext1"
                                          defaultValue={"Multi-sprog"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext92"
                                          defaultValue={"Opsæt online booking"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext93"
                                          defaultValue={
                                            "Drag-drop funktionalitet"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext94"
                                          defaultValue={"Fritekst beskrivelse"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext95"
                                          defaultValue={"Send booking link"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext96"
                                          defaultValue={
                                            "Plugin til egen hjemmeside"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext97"
                                          defaultValue={
                                            "Integreret med klient selvbetjeningspanel"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext98"
                                          defaultValue={
                                            "Godkend booking anmodninger"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext99"
                                          defaultValue={
                                            "Automatisk bekræftelse"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="CalendarEventBoxFormreminders"
                                          defaultValue={"Påmindelser"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81"
                                          defaultValue={
                                            "Integrering på tværs af systemer"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3' 
          header={renderAccordionHeader(<span> 
             <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/>
            </span>, this.activateIndividualAccordion.bind(this, '9'))} eventKey="9">
            <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
            <tbody>
          <tr>
              <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>   
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext92' defaultValue={'Opsæt online booking'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext93' defaultValue={'Drag-drop funktionalitet'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext94' defaultValue={'Fritekst beskrivelse'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext95' defaultValue={'Send booking link'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext96' defaultValue={'Plugin til egen hjemmeside'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext97' defaultValue={'Integreret med klient selvbetjeningspanel'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext98' defaultValue={'Godkend booking anmodninger'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext99' defaultValue={'Automatisk bekræftelse'}/></div>
                                  <div>&#9632; <AxoLocal entity='CalendarEventBoxFormreminders'defaultValue={'Påmindelser'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext81' defaultValue={'Integrering på tværs af systemer'}/></div>
                                  <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    </div></td>
        </tr>
        </tbody>
        </table>   
          </Panel>
          </Accordion> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div
                        id="11"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-fontello-calendar-5"
                          style={{ fontSize: "40px" }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="Chfontcssbanne Axobold">
                          <AxoLocal
                            entity="FrontPageindextaxt20"
                            defaultValue={"Kalender"}
                          />
                        </span>
                      </div>
                      <PanelGroup
                        id="Packageam"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(
                                  this,
                                  "10"
                                )
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox"
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext1"
                                          defaultValue={"Multi-sprog"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext100"
                                          defaultValue={"Aftaleoversigt"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext102"
                                          defaultValue={"Deadline påmindelser"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext103"
                                          defaultValue={
                                            "Påmindelser for dokumenter"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext104"
                                          defaultValue={"Aftaleoprettelse"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext105"
                                          defaultValue={
                                            "Booking tid oprettelse"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext106"
                                          defaultValue={
                                            "Tids- og aftaleredigering"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext107"
                                          defaultValue={
                                            "Drag-drop af aftaler og tid"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext108"
                                          defaultValue={
                                            "Oversigt over måned, uge og dagsorden"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="CalendarEventBoxFormreminders"
                                          defaultValue={"Påmindelser"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext81"
                                          defaultValue={
                                            "Integrering på tværs af systemer"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3'
          header={renderAccordionHeader(<span> 
             <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/> 
            </span>, this.activateIndividualAccordion.bind(this, '10'))} eventKey="10">
            <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
            <tbody>
          <tr>
              <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext1' defaultValue={'Multi-sprog'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext100' defaultValue={'Aftaleoversigt'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext102' defaultValue={'Deadline påmindelser'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext103' defaultValue={'Påmindelser for dokumenter'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext104' defaultValue={'Aftaleoprettelse'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext105' defaultValue={'Booking tid oprettelse'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext106' defaultValue={'Tids- og aftaleredigering'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext107' defaultValue={'Drag-drop af aftaler og tid'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext108' defaultValue={'Oversigt over måned, uge og dagsorden'}/></div>
                                    <div>&#9632; <AxoLocal entity='CalendarEventBoxFormreminders'defaultValue={'Påmindelser'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext81' defaultValue={'Integrering på tværs af systemer'}/></div>
                                    <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                                  </div></td>
                          <td><div  className='text-left frontPageaxolexdivtextbox'>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>  
                                    <div>&#10004;</div>
                                    <div>&#10004;</div>
                                    </div></td>
        </tr>
        </tbody>
        </table>
          </Panel>
          </Accordion> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <div
                        id="12"
                        className="flexbox-standard"
                        style={{ paddingLeft: "25px" }}
                      >
                        <Icon
                          className="axolexwebsideicon"
                          glyph="icon-simple-line-icons-folder-alt"
                          style={{ fontSize: "40px" }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span className="Chfontcssbanne Axobold">
                          <AxoLocal
                            entity="FrontPageviewtext2"
                            defaultValue={"Mappesystem"}
                          />
                        </span>
                      </div>
                      <PanelGroup
                        id="Packagen"
                        accordion
                        className="Lex-Accordion IndividualPackageDetal "
                        defaultActiveKey="0"
                        style={{
                          borderRadius: "0px",
                          paddingTop: "0px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <Panel
                          className="backgroundlineargradient3"
                          eventKey="1"
                        >
                          <Panel.Heading>
                            <Panel.Title toggle>
                              {renderAccordionHeader(
                                <span>
                                  <AxoLocal
                                    entity="IndividualPackagetext142"
                                    defaultValue={"Se Detaljer"}
                                  />
                                </span>,
                                this.activateIndividualAccordion.bind(
                                  this,
                                  "11"
                                )
                              )}
                            </Panel.Title>
                          </Panel.Heading>
                          <Panel.Body
                            collapsible
                            className="LexAccordionPanelody"
                          >
                            <table
                              className="table borderless"
                              style={{
                                maxWidth: "400px",
                                borderWidth: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      className="text-left frontPageaxolexdivtextbox"
                                      style={{ width: "400px" }}
                                    >
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext8"
                                          defaultValue={"Mailmapper"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="LabelTabViewCaseFolders"
                                          defaultValue={"Sagsmapper"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="LabelTabViewDocumentfolders"
                                          defaultValue={"Dokumentmapper"}
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext109"
                                          defaultValue={
                                            "Ubegrænset mappeoprettelse"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext110"
                                          defaultValue={
                                            "Integration i hele systemet"
                                          }
                                        />
                                      </div>
                                      <div>
                                        &#9632;{" "}
                                        <AxoLocal
                                          entity="IndividualPackagetext111"
                                          defaultValue={"Og mere"}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-left frontPageaxolexdivtextbox">
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                      <div>&#10004;</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Panel.Body>
                        </Panel>
                      </PanelGroup>

                      {/* <Accordion className = 'Lex-Accordion IndividualPackageDetal' activeKey={this.state.activeIndividualAccordion}>
          <Panel className='backgroundlineargradient3'  
          header={renderAccordionHeader(<span> 
             <AxoLocal entity='IndividualPackagetext142' defaultValue={'Se Detaljer'}/>
            </span>, this.activateIndividualAccordion.bind(this, '11'))} eventKey="11">
            <table className="table borderless" style={{maxWidth: '400px',borderWidth: '0px',paddingLeft:'2px',paddingRight:'2px'}}>
            <tbody>
          <tr>
              <td><div className='text-left frontPageaxolexdivtextbox'  style={{ width: '400px' }}>
                     <div>&#9632; <AxoLocal entity='IndividualPackagetext8' defaultValue={'Mailmapper'}/></div>
                     <div>&#9632; <AxoLocal entity='LabelTabViewCaseFolders' defaultValue={'Sagsmapper'}/></div>
                     <div>&#9632; <AxoLocal entity='LabelTabViewDocumentfolders' defaultValue={'Dokumentmapper'}/></div>
                     <div>&#9632; <AxoLocal entity='IndividualPackagetext109' defaultValue={'Ubegrænset mappeoprettelse'}/></div>
                     <div>&#9632; <AxoLocal entity='IndividualPackagetext110' defaultValue={'Integration i hele systemet'}/></div>
                     <div>&#9632; <AxoLocal entity='IndividualPackagetext111' defaultValue={'Og mere'}/></div>
                   </div></td>
          <td><div  className='text-left frontPageaxolexdivtextbox'>
                    <div>&#10004;</div>
                    <div>&#10004;</div>
                    <div>&#10004;</div>
                    <div>&#10004;</div>
                    <div>&#10004;</div>
                    </div></td>
        </tr>
        </tbody>
        </table>
          </Panel>
          </Accordion> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>

          <Col
            md={8}
            mdOffset={2}
            sm={12}
            xs={12}
            className="nopadding"
            style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
          >
            <div className="Axolextextbox">
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext154"
                  defaultValue={
                    "Har du kun behov for en enkelt del af vores program, til at integrere i dit nuværende IT-system."
                  }
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext155"
                  defaultValue={
                    "I så fald har du mulighed for at købe et enkelt af vores programmer, sådan som booking, mailprogram eller et internt socialt medie, osv."
                  }
                />
              </div>
            </div>
            <br />
            <div>
              <PanelGroup
                id="Packageo"
                accordion
                className="Lex-Accordion IndividualPackageDetal "
                defaultActiveKey="0"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  borderBottom: "1px solid white",
                }}
              >
                <Panel className="backgroundlineargradient3" eventKey="1">
                  <Panel.Heading>
                    <Panel.Title toggle>
                      {renderAccordionHeader(
                        <span>
                          <AxoLocal
                            entity="Companyaboutostaxt25"
                            defaultValue={"Kontakt"}
                          />
                        </span>
                      )}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible className="LexAccordionPanelody">
                    <ContactForm />
                  </Panel.Body>
                </Panel>
              </PanelGroup>
              {/* <Accordion defaultActiveKey='0' className = 'Lex-Accordion'>
                <Panel className='backgroundlineargradient3 compact' 
                  header={renderAccordionHeader(<span><AxoLocal entity='Companyaboutostaxt25' defaultValue={'Kontakt'}/></span>)} 
                  eventKey="1">
                    <ContactForm  />
                </Panel>
              </Accordion> */}
            </div>
          </Col>
        </Col>

        <Col
          id="componentPackage"
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
        >
          <div className="frontPageaxolexdiv Bannecolor">
            <AxoLocal
              entity="FrontPageviewtext13"
              defaultValue={"IT komponent"}
            />
          </div>
        </Col>
        <Col
          md={8}
          mdOffset={2}
          sm={12}
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
        >
          <Col
            md={6}
            className="nopadding"
            style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
          >
            <div>
              <img
                alt=""
                src="/svg/Axolex7-1.jpg"
                className="bg_img"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </Col>
          <Col
            md={6}
            className="nopadding"
            style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
          >
            <div className="Axolextextbox marginRighttextbox">
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext156"
                  defaultValue={
                    "Er du IT-udvikler, eller er du i gang med IT-udvikling? Har du brug for forskellige IT-komponenter,såsom log ind system, registreringssystem, betalingssystem, mailprogram, booking system, sagsadministration, klienthåndtering, dokumenthåndtering osv."
                  }
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext157"
                  defaultValue={
                    "I så fald tilbyder vi at du kan købe en enkelt IT-komponent eller plugin til at udvikle dit helt eget system."
                  }
                />
              </div>
              <div className="Axolextextbox">
                <AxoLocal
                  entity="IndividualPackagetext158"
                  defaultValue={
                    "Kontakt vores IT-afdeling for at høre hvad vi kan tilbyde og diskutere dine behov."
                  }
                />
              </div>
            </div>
            <br />
            <div>
              <PanelGroup
                id="Packageap"
                accordion
                className="Lex-Accordion IndividualPackageDetal "
                defaultActiveKey="0"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  borderBottom: "1px solid white",
                }}
              >
                <Panel className="backgroundlineargradient3" eventKey="1">
                  <Panel.Heading>
                    <Panel.Title toggle>
                      {renderAccordionHeader(
                        <span>
                          <AxoLocal
                            entity="Companyaboutostaxt25"
                            defaultValue={"Kontakt"}
                          />
                        </span>
                      )}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible className="LexAccordionPanelody">
                    <ContactForm />
                  </Panel.Body>
                </Panel>
              </PanelGroup>

              {/* <Accordion defaultActiveKey='0' className = 'Lex-Accordion'>
                  <Panel className='backgroundlineargradient3compact'
                    header={renderAccordionHeader(<span><AxoLocal entity='Companyaboutostaxt25' defaultValue={'Kontakt'}/></span>)} 
                    eventKey="1">
                      <ContactForm  />
                  </Panel>
                </Accordion> */}
            </div>
          </Col>

          <br />
        </Col>

        <Col
          id="ITSupport"
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
        >
          <div className="frontPageaxolexdiv Bannecolor">
            <AxoLocal
              entity="IndividualPackagetext164"
              defaultValue={"IT support"}
            />
          </div>
        </Col>
        <Col
          md={8}
          mdOffset={2}
          sm={12}
          xs={12}
          className="nopadding"
          style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
        >
          <Col
            md={6}
            className="nopadding"
            style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
          >
            <div
              className="Axolextextbox marginRighttextbox"
              style={{ maxWidth: "100%" }}
            >
              <div className="Axolextextbox">
                <div className="Axolextextbox">
                  <AxoLocal
                    entity="IndividualPackagetext159"
                    defaultValue={
                      "Er du bruger af vores system? Så husk at at vi giver gratis IT-support til alle aktive brugere af vores system, også på kundeselvbetjeningspanelet."
                    }
                  />
                </div>
                <div className="Axolextextbox">
                  <AxoLocal
                    entity="IndividualPackagetext160"
                    defaultValue={
                      "Såfremt du du benytter vores IT-systemer, og du binder dig til et abonnement på minimum 1 år, så tilbyder vi gratis hjælp til at integrere vores systemer med din egen hjemmeside. Herunder blandt andet betalingssystem og booking system."
                    }
                  />
                </div>
                <div className="Axolextextbox">
                  <AxoLocal
                    entity="IndividualPackagetext161"
                    defaultValue={
                      "Hvis du ikke ønsker at binde dig for et år, inkluderer vores system plugin links, som du nemt selv kan integrere i din hjemmeside. Du kan også få hjælp fra vores IT-konsulenter mod en mindre betaling."
                    }
                  />
                </div>
                <div className="Axolextextbox">
                  <AxoLocal
                    entity="IndividualPackagetext162"
                    defaultValue={
                      "(På nuværende tidspunkt tilbyder vi gratis IT-support uden krav om binding. Gå ikke glip af dette ekstraordinære tilbud.)"
                    }
                  />
                </div>
              </div>
              <div className="Axolextextbox"></div>
              <div className="Axolextextbox"></div>
            </div>
            <br />

            <div>
              <PanelGroup
                id="Packagea"
                accordion
                className="Lex-Accordion IndividualPackageDetal "
                defaultActiveKey="0"
                style={{
                  borderRadius: "0px",
                  paddingTop: "0px",
                  borderBottom: "1px solid white",
                }}
              >
                <Panel className="backgroundlineargradient3" eventKey="1">
                  <Panel.Heading>
                    <Panel.Title toggle>
                      {renderAccordionHeader(
                        <span>
                          <AxoLocal
                            entity="IndividualPackagetext163"
                            defaultValue={"Kontakt IT-support"}
                          />
                        </span>
                      )}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible className="LexAccordionPanelody">
                    <ContactForm />
                  </Panel.Body>
                </Panel>
              </PanelGroup>
              {/* <Accordion defaultActiveKey='0' className = 'Lex-Accordion'>
                  <Panel className='backgroundlineargradient3 compact'
                    header={renderAccordionHeader(<span><AxoLocal entity='IndividualPackagetext163' defaultValue={'Kontakt IT-support'}/></span>)} 
                    eventKey="1">
                      <ContactForm  />
                  </Panel>
                </Accordion> */}
            </div>
          </Col>

          <Col
            md={6}
            className="nopadding"
            style={{ maxWidth: "100%", marginTop: "25px", padding: "0px" }}
          >
            <div style={{ paddingLeft: "5px" }}>
              <img
                alt=""
                src="/svg/Axolex10.jpg"
                className="bg_img "
                style={{ maxWidth: "100%" }}
              />
            </div>
          </Col>
          <br />
        </Col>
      </div>
    );
  }
}

export default withRouter(Packages);
