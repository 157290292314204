import React from "react";
import PresentationApiService from "../services/PresentationApiService";
import PublicLayoutView from "./PublicLayoutView";
import LocalizationService from "../../services/LocalizationService";
import DataStore from "../../services/DataAccess/DataStore";
import ThemeService from "../../services/ThemeService";
import LoadingIndicator from "../../utilities/LoadingIndicator";
import { Route, Switch } from "react-router-dom";
import PublicPresentationContainer from "./PublicPresentationContainer";
import PublicPostListContainer from "./PublicPostListContainer";
import PublicQueryListContainer from "./PublicQueryListContainer";

export default class PublicLayoutViewContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      header: "",
      locale: "enUS",
      companyHeader: true,
      loaded: false,
      globalSettings: {},
    };
    this.name = "PublicLayoutViewContainer";
  }

  componentDidMount() {
    var userId = this.props.match.params.userId; //Presentations for a specific user

    DataStore.onLoad((loaded) => {
      this.setState({ loaded: loaded });
    });
    DataStore.subscribe(this.name, (store) => {
      if (store.globalSettings.theme) {
        ThemeService.setThemeStyle(store.globalSettings.themeFront);
      }
      this.setState({ globalSettings: store.globalSettings });
    });

    if (userId) {
      PresentationApiService.getPublicPresentationInformation(
        this.props.match.params.userId
      ).then((publicInfo) => {
        this.setState({
          header: publicInfo.header,
          locale: publicInfo.locale,
          companyHeader: false,
        });
        LocalizationService.changeLocale(publicInfo.locale);
      });
    }

    DataStore.fetchGlobalSettings();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
  }

  render() {
    let { match } = this.props;
    var { loaded, globalSettings } = this.state;

    if (!loaded || !globalSettings.theme) {
      return <LoadingIndicator />;
    }
    return (
      <PublicLayoutView
        companyHeader={this.state.companyHeader}
        header={this.state.header}
        locale={this.state.locale}
      >
        <Switch>
          <Route
            path={match.path + "/post/:userId/:id"}
            component={PublicPresentationContainer}
          />
          <Route
            path={match.path + "/postList/:userId"}
            component={PublicPostListContainer}
          />
          <Route
            path={match.path + "/all"}
            component={PublicQueryListContainer}
          />
        </Switch>
      </PublicLayoutView>
    );
  }
}
