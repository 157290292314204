import * as $ from "jquery";

export default {
  printElement(elem) {
    // let oldTitle = window.document.title;
    // window.document.title = '';

    $("html").addClass("modal-printing");
    var domClone = elem.cloneNode(true);

    var $printSection = document.getElementById("printSection");

    if (!$printSection) {
      $printSection = document.createElement("div");
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
    }
    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
    $("html").removeClass("modal-printing");
    $printSection.removeChild(domClone);

    // window.document.title = oldTitle;
  },
};
