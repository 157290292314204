import React from "react";
import FreewareLayoutView from "./FreewareLayoutView";
import FreewareFrontPage from "./FreewareFrontPage";
import XLinkView from "./XLinkView";
import { Route, Switch } from "react-router-dom";

import { DataStore } from "../services/AxoServices";

import { LoadingIndicator, Consumer } from "../utilities/LexUtilities";

export default class FreewareLayoutContainer extends React.PureComponent {
  componentDidMount() {
    DataStore.fetchGlobalSettings();
  }

  render() {
    let { match } = this.props;

    return (
      <Consumer>
        {(store) => {
          if (!store.loaded || !store.globalSettings) {
            return <LoadingIndicator />;
          }
          return (
            <FreewareLayoutView>
              <Switch>
                <Route exact path={match.path} component={FreewareFrontPage} />
                <Route path={match.path + "/xlink"} component={XLinkView} />
              </Switch>
            </FreewareLayoutView>
          );
        }}
      </Consumer>
    );
  }
}
