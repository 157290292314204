import React from "react";
import DataActions from "../../services/DataAccess/DataActions";
import { withRouter } from "react-router-dom";
import ModalService from "../../services/ModalService";

import Conditional from "../../utilities/Conditional";
import { Large, MediumOrSmaller } from "../../utilities/Responsive";
import moment from "moment";

import { Row, Col, Grid, Button, ButtonGroup } from "react-bootstrap";

import { Icon, AxoLocal, getText } from "../../utilities/LexUtilities";

class InvoiceCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      invoiceSending: false,
      showSharedMessage: false,
    };
  }

  //Props
  //invoice
  //onInvoiceChange
  onInvoicePropertyChange = (propertyName, input) => {
    var invoice = Object.assign({}, this.props.invoice);
    invoice[propertyName] = input.value;
    this.props.onInvoiceChange(invoice);
  };

  onTrashInvoice = () => {
    var invoice = Object.assign({}, this.props.invoice);
    invoice.trashed = true;
    DataActions.updateInvoice(invoice);
  };

  shortenString(input, maxChars) {
    if (input.length > maxChars) {
      return input.substring(0, maxChars - 3) + "...";
    }
    return input;
  }

  renderStatusSelect = (invoice) => {
    let { statusLabels } = this.props;

    return (
      <select
        className="axoblue selectbg"
        value={invoice.status}
        style={{ border: "none" }}
        onChange={this.props.onStatusSelect.bind(this, invoice.id)}
      >
        <option value="Created">{statusLabels.created}</option>
        <option value="Approved">{statusLabels.approved}</option>
        <option value="Sent">{statusLabels.submitted}</option>
        <option value="Seen">{statusLabels.seen}</option>
        <option value="Paid">{statusLabels.paid}</option>
        <option value="Overdue">{statusLabels.overdue}</option>
      </select>
    );
  };

  renderStatusText = (invoice) => {
    let { statusLabels } = this.props;

    switch (invoice.status) {
      case "Created":
        return statusLabels.created;
      case "Approved":
        return statusLabels.approved;
      case "Sent":
        return statusLabels.submitted;
      case "Seen":
        return statusLabels.seen;
      case "Paid":
        return statusLabels.paid;
      case "Overdue":
        return statusLabels.overdue;
      default:
        return <span></span>;
    }
  };

  onChangeDueDate = (event) => {
    var invoice = Object.assign({}, this.props.invoice);
    invoice.dueDate = moment(event.target.valueAsDate).format();
    invoice.sendReminder = true;
    invoice.reminderSent = false;
    DataActions.updateInvoice(invoice);
  };

  getStatusIcon = (status) => {
    switch (status) {
      case "Created":
        return (
          <div>
            <Icon
              style={{ color: "#428bca" }}
              glyph="icon-fontello-check-empty"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Approved":
        return (
          <div>
            <Icon style={{ color: "#306C67" }} glyph="icon-fontello-export-3" />
            &nbsp;&nbsp;
          </div>
        );
      case "Sent":
        return (
          <div>
            <Icon style={{ color: "#518F41" }} glyph="icon-fontello-check-1" />
            &nbsp;&nbsp;
          </div>
        );
      case "Seen":
        return (
          <div>
            <Icon
              style={{ color: "#D71F4B" }}
              glyph="icon-fontello-cancel-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      case "Paid":
        return (
          <div>
            <Icon
              style={{ color: "#003BFF" }}
              glyph="icon-fontello-ok-squared"
            />
            &nbsp;&nbsp;
          </div>
        );
      default:
        return <div></div>;
    }
  };

  renderInformation = () => {
    var invoice = this.props.invoice;
    return (
      <div>
        <table className="table Lex-Grid-tabl">
          <tbody>
            <tr>
              <th>
                <Icon glyph="icon-fontello-calendar-8" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="Creationdate"
                  entity="DocumentTabViewcreationDate"
                  defaultValue={"Oprettelsesdato"}
                />
                :{" "}
              </th>
              <td>{moment(invoice.creationDate).format("L")}</td>
            </tr>
            <tr>
              <th>
                <Icon glyph="icon-fontello-calendar-1" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="paymentdate"
                  entity="InvoiceGridViewpaymentDate"
                  defaultValue={"Betalingsdato"}
                />
                :{" "}
              </th>
              <td>
                <input
                  type="date"
                  style={{ border: "none" }}
                  onChange={this.onChangeDueDate}
                  value={moment(invoice.dueDate).format("YYYY-MM-DD")}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Icon glyph="icon-fontello-statusnet" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="statusleft"
                  entity="CaseEditInlineStatusSelect"
                  defaultValue={"Status"}
                />
                :{" "}
              </th>
              <td className="flexbox-standard">
                {this.getStatusIcon(invoice.status)}
                {this.renderStatusSelect(invoice)}
              </td>
            </tr>
            <tr>
              <th>
                <Icon glyph="icon-simple-line-icons-users" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="clients"
                  entity="CaseDataTableFixedClient"
                  defaultValue={"Klient"}
                />
                :{" "}
              </th>
              <td>
                {invoice.client ? (
                  <span>
                    {invoice.client.lastName}, {invoice.client.firstName}
                  </span>
                ) : (
                  ""
                )}
              </td>
            </tr>
            <tr>
              <th>
                <Icon glyph="icon-fontello-database-2" />
                &nbsp;&nbsp;
                <AxoLocal
                  key="Amonts"
                  entity="InvoiceGridViewRemainderAmount"
                  defaultValue={"Restbeløb"}
                />
                :{" "}
              </th>
              <td>
                {invoice.currency} {invoice.outstandingAmount}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderContent = () => {
    return this.renderInformation();
  };

  onSendInvoice = () => {
    var invoice = this.props.invoice;
    this.setState({ invoiceSending: true });
    DataActions.generateInvoicePdf(invoice.id)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((newPDF) => {
            this.props.onCreateAndSendPDF(newPDF);
          });
        } else {
          this.showPDFGenerationError();
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.showPDFGenerationError();
      });
  };

  showPDFGenerationError = () => {
    this.setState({ invoiceSending: false });
    ModalService.openAlertModal(
      <AxoLocal
        key="casetable"
        entity="CaseDataTableFixedClient"
        defaultValue={"PDF´en kunne ikke genereres. Prøv igen senere."}
      />
    );
  };

  onShareWithClient = () => {
    var invoice = this.props.invoice;
    invoice.sharedWithClient = true;
    invoice.status = 1;
    DataActions.updateInvoice(invoice)
      .then((response) => {
        if (response.ok) {
          this.setState({ showSharedMessage: true });
          setTimeout(() => {
            this.setState({ showSharedMessage: false });
          }, 5000);
        } else {
          ModalService.openAlertModal(
            <AxoLocal
              entity="InvoiceInvoiceresponse"
              defaultValue={
                "Fakturaen kunne ikke gemmes. Tjek at der er forbindelse til serveren."
              }
            />
          );
        }
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  renderShareButton = () => {
    var invoice = this.props.invoice;
    if (!invoice.client || !invoice.client.clientUserName) {
      return null;
    }
    if (invoice.sharedWithClient) {
      return (
        <ButtonGroup>
          <button disabled type="button" className="btn Lex-button">
            <Icon role="button" glyph="icon-fontello-mail-1" /> &nbsp;&nbsp;
            <AxoLocal entity="InvoiceCardSentToClient" defaultValue={"Sendt"} />
          </button>
        </ButtonGroup>
      );
    }
    return (
      <ButtonGroup>
        <button
          type="button"
          className="btn Lex-button"
          onClick={this.onShareWithClient}
        >
          <Icon role="button" glyph="icon-fontello-mail-1" /> &nbsp;&nbsp;
          <AxoLocal
            entity="InvoiceCardSendToClient"
            defaultValue={"Til klient"}
          />
        </button>
      </ButtonGroup>
    );
  };

  render() {
    var invoice = this.props.invoice;
    return (
      <div>
        <div
          style={{
            marginBottom: "25px",
            border: "1px solid #DCDCDC",
            boxShadow: "10px 10px 9px #ececec",
          }}
        >
          <div>
            <div className="gradient-baggrund">
              <Grid fluid>
                <Row>
                  <Col xs={12} className="editable text-center">
                    <span
                      role="button"
                      onClick={this.props.onSelectInvoice.bind(
                        this,
                        invoice.id
                      )}
                      style={{ lineHeight: "50px" }}
                    >
                      # {invoice.number ? invoice.number.toString() : "0"}
                    </span>
                  </Col>
                </Row>
              </Grid>
            </div>
            <div>
              <Grid fluid>
                <Row>
                  <Col xs={12}>{this.renderContent()}</Col>
                </Row>
              </Grid>
            </div>
            <div className="Lex-CardFooter">
              <Grid fluid>
                <Row>
                  <Col xs={12} style={{ padding: "0px" }}>
                    <Conditional show={this.state.showSharedMessage}>
                      <div className="text-center">
                        <AxoLocal
                          entity="InvoiceGridViewSendtoclientInvoice"
                          defaultValue={
                            "Klienten kan nu se og betale fakturaen på selvbetjeningspanelet"
                          }
                        />
                      </div>
                    </Conditional>
                    <ButtonGroup justified>
                      {this.renderShareButton()}
                      <ButtonGroup>
                        {this.state.invoiceSending ? (
                          <img
                            alt=""
                            src="/imgs/app/loading.gif"
                            className="img-circle"
                            width="20"
                            height="20"
                          />
                        ) : (
                          <button
                            type="button"
                            className="btn Lex-button"
                            onClick={this.onSendInvoice}
                          >
                            <Icon role="button" glyph="icon-fontello-mail-1" />{" "}
                            &nbsp;&nbsp;
                            <AxoLocal
                              key="role"
                              entity="InvoiceGridViewSendtoclient"
                              defaultValue={"Send PDF"}
                            />
                          </button>
                        )}
                      </ButtonGroup>
                      <ButtonGroup>
                        <button
                          type="button"
                          className="btn Lex-button"
                          onClick={this.onTrashInvoice}
                        >
                          <Icon
                            role="button"
                            glyph="icon-fontello-trash-1"
                          ></Icon>
                          &nbsp;&nbsp;
                          <AxoLocal
                            key="typebutton"
                            entity="axoidcode179"
                            defaultValue={"Slet"}
                          />
                        </button>
                      </ButtonGroup>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class InvoiceGridViewV2 extends React.PureComponent {
  //Props
  //invoices
  //onSelectInvoice
  constructor(props) {
    super(props);
    var pageSize = 10;
    this.state = {
      numberShown: pageSize,
      pageSize: pageSize,
    };
  }

  generateInvoiceCols = (invoices) => {
    let statusLabels = {
      created: getText("InvoiceGridViewcreated", "Created"),
      approved: getText("InvoiceGridViewapproved", "Approved"),
      submitted: getText("InvoiceGridViewSubmitted", "Sent"),
      seen: getText("axoidcode233", "Sendt og set"),
      paid: getText("InvoiceInvoicePaid", "Paid"),
      overdue: getText("InvoiceGridViewoverdue", "Overdue"),
    };

    var cols = [[], [], []];
    var smallCols = [[], []];
    for (var i = 0; i < invoices.length; i++) {
      var invoice = invoices[i];

      var invoiceCard = (
        <InvoiceCard
          key={invoice.id}
          invoice={invoice}
          statusLabels={statusLabels}
          active={this.props.highlightedInvoiceId === invoice.id}
          onInvoiceChange={this.onInvoiceChange}
          onSelectInvoice={this.props.onSelectInvoice}
          onStatusSelect={this.props.onStatusSelect}
          onCreateAndSendPDF={this.props.onCreateAndSendPDF}
        />
      );

      cols[i % 3].push(invoiceCard);
      smallCols[i % 2].push(invoiceCard);
    }
    return [cols, smallCols];
  };

  renderInvoiceGrid = (invoices) => {
    var cols = this.generateInvoiceCols(invoices);
    var largeCols = cols[0];
    var smallCols = cols[1];
    return (
      <Grid fluid>
        <Large>
          <Row>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
              {largeCols[0]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
              {largeCols[1]}
            </Col>
            <Col md={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
              {largeCols[2]}
            </Col>
          </Row>
        </Large>
        <MediumOrSmaller>
          <Row>
            <Col md={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[0]}
            </Col>
            <Col md={6} style={{ paddingLeft: "2px", paddingRight: "2px" }}>
              {smallCols[1]}
            </Col>
          </Row>
        </MediumOrSmaller>
      </Grid>
    );
  };

  onInvoiceChange = (invoice) => {
    DataActions.updateInvoice(invoice);
  };

  getShownItems = () => {
    return this.props.invoices.slice(0, this.state.numberShown);
  };

  onShowMore = () => {
    this.setState({
      numberShown: this.state.numberShown + this.state.pageSize,
    });
  };

  render() {
    return (
      <Grid fluid style={{ background: "#ffffff" }}>
        <Row>
          <Col xs={12} style={{ padding: "0px" }}>
            {this.renderInvoiceGrid(this.getShownItems())}
          </Col>
        </Row>
        {this.state.numberShown < this.props.invoices.length ? (
          <Row style={{ paddingBottom: "50px" }}>
            <Col
              xs={12}
              className="text-center"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Button
                onClick={this.onShowMore}
                className="Lex-button-2"
                style={{ width: "75%" }}
              >
                <AxoLocal
                  key="stylewith"
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </Button>
            </Col>
          </Row>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(InvoiceGridViewV2);
