import React from "react";
import { withRouter } from "react-router-dom";
import LabelTabView from "./Labels/LabelTabView";
import QueryString from "query-string";

import {
  LocalizationService,
  DataStore,
  RoutingService,
  AuthorizationService,
} from "../services/AxoServices";

import {
  withRTKData,
  withRTKMessageData,
  withRTKCaseData,
} from "../utilities/LexUtilities";

class LabelContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      cases: [],
      documents: [],
      clients: [],
      labels: [],
      userProfile: {},
      userSettings: {},
      adminCodeValidated: false,
    };
    this.name = "LabelContainer";
  }

  componentDidMount() {
    let query = QueryString.parse(this.props.location.search);
    let adminCodeValidated = query.adminCode === "AxolexAdmin";
    if (adminCodeValidated) {
      this.setState({ adminCodeValidated: true });
    }

    DataStore.subscribe(this.name, (store) => {
      let sortedLabels = store.labels.sort((l, r) => {
        return l.name.localeCompare(r.name);
      });

      this.setState({
        userProfile: store.userProfile,
        userSettings: store.userSettings,
        messages: this.props.messageMap.messages.filter((m) => !m.trashed),
        cases: store.caseMap.cases.filter((c) => !c.trashed),
        documents: store.documentMap.documents,
        labels: sortedLabels,
        showDropdowns: store.showDropdowns,
      });
    });

    LocalizationService.subscribeToLocale(this.name, (locale) => {
      //Triggers a rerender, for moment localization updating
      this.setState({ locale });
    });

    DataStore.initializeUserProfile();
    DataStore.initializeCases();
    DataStore.initializeDocuments();
    DataStore.initializeLabels();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
    LocalizationService.unsubscribeFromLocale("LabelContainer");
  }
  getActiveTab = (props) => {
    let { userProfile } = this.state;
    let userType = AuthorizationService.getUserType(userProfile);
    let path = props.location.pathname.toLowerCase();
    if (path.includes("documents")) {
      return "Documents";
    }
    if (path.includes("mails")) {
      return "Mails";
    }
    if (path.includes("cases")) {
      return "Cases";
    }
    if (path.includes("clients")) {
      return "Clients";
    }
    if (userType === "Society") {
      return "Clients";
    }
    return "Documents";
  };

  handleNavigation = (eventKey) => {
    this.props.history.push(RoutingService.getPath("Labels/" + eventKey));
  };

  render() {
    return (
      <LabelTabView
        activeTab={this.getActiveTab(this.props)}
        handleNavigation={this.handleNavigation}
        {...this.props}
        {...this.state}
        clients={this.props.contactMap.contacts}
      />
    );
  }
}

export default withRTKCaseData(
  withRTKMessageData(withRTKData(withRouter(LabelContainer)))
);
