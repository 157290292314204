import React from "react";
import PrintService from "../services/PrintService";
import Conditional from "../utilities/Conditional";
import moment from "moment";
import TimeEntryService from "../services/TimeEntryService";
import InvoiceV2 from "../routes/Invoices/InvoiceV2";

import {
  Row,
  Col,
  Button,
  ButtonToolbar,
  PanelGroup,
  Panel,
} from "react-bootstrap";

// import CardLogos from '../resources/images/dankort.png';
import MasterCard from "../resources/images/MasterCard.jpg";
import Visa from "../resources/images/Visa.png";
// import AxolexDataBehandlerAftale from '../resources/files/AxolexDatabehandlerAftale.pdf'

import {
  Icon,
  AxoLocal,
  AccordionUtils,
  LexButton,
  // AxoCheckbox
} from "../utilities/LexUtilities";
import { ApiService } from "../services/AxoServices";

export default class FullInvoiceCollectionView extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.invoice) {
      this.state = {
        invoice: Object.assign({}, props.invoice),
        acceptedConditions: true,
        acceptedPrivacyPolicy: true,
      };
    }

    this.state.isGeneratingPayment = false;
    this.state.showPaymentErrorMessage = false;
    this.state.showPaymentCompletedMessage = false;
  }

  onPrint = () => {
    if (typeof window !== "undefined") {
      PrintService.printElement(document.getElementById("print-invoice"));
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.invoice &&
      JSON.stringify(nextProps.invoice) !== JSON.stringify(this.props.invoice)
    ) {
      this.setState({ invoice: Object.assign({}, nextProps.invoice) });
    }
  }

  hasUnits = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.units > 0);
  };

  hasAConto = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.aConto > 0);
  };

  hasExpenses = () => {
    return !!this.state.invoice.timeEntries.find((t) => t.expenses > 0);
  };

  getTotal = (timeEntries) => {
    return timeEntries
      .reduce((acc, timeEntry) => {
        return acc + TimeEntryService.getTotalValue(timeEntry);
      }, 0)
      .toFixed(2);
  };

  onPayInvoice = (invoiceId) => {
    this.setState({ isGeneratingPayment: true });
    this.props
      .onPayInvoice(invoiceId)
      .then((result) => {
        if (!result) {
          this.showPaymentErrorMessage();
          return;
        }
        this.setState({
          isGeneratingPayment: false,
          showPaymentCompletedMessage: true,
        });
      })
      .catch(() => {
        this.showPaymentErrorMessage();
      });
  };

  showPaymentErrorMessage = () => {
    this.setState({
      isGeneratingPayment: false,
      showPaymentErrorMessage: true,
    });
    setTimeout(() => {
      this.setState({ showPaymentErrorMessage: false });
    }, 5000);
  };

  renderPaymentButton = () => {
    let { acceptedConditions, acceptedPrivacyPolicy } = this.state;

    var { invoice, paymentDate, requestGuid } = this.props;

    var isGenerating = this.state.isGeneratingPayment;
    if (invoice.status === "Paid" || !!paymentDate) {
      //Paid or approved
      return (
        <React.Fragment>
          <LexButton disabled className="axoblue">
            <Icon glyph="icon-fontello-check-1" style={{ fontSize: "17px" }} />{" "}
            &nbsp;
            <AxoLocal
              key="paid"
              entity="InvoiceInvoicePaid"
              defaultValue={"Betalt"}
            />
            {!!paymentDate ? (
              <span>&nbsp;{moment(paymentDate).format("L")}</span>
            ) : null}
          </LexButton>
          <LexButton
            className="axoblue hidden-print"
            onClick={() => window.print()}
          >
            <AxoLocal entity="axoidcode212" defaultValue={"Udskriv"} />
          </LexButton>
          {!!requestGuid && invoice.hasPDFs && (
            <LexButton
              className="axoblue hidden-print"
              href={ApiService.getInvoicePDFDownloadPath(requestGuid)}
            >
              <AxoLocal
                entity="axoEntityidcode6"
                defaultValue={"Download PDF"}
              />
            </LexButton>
          )}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button
          disabled={
            !acceptedConditions || !acceptedPrivacyPolicy || isGenerating
          }
          className="Lex-button-2"
          onClick={this.onPayInvoice.bind(this, invoice.id)}
          style={{ fontSize: "17px" }}
        >
          <img alt="" src={Visa} width="40" height="20" />
          &nbsp;&nbsp;
          <img alt="" src={MasterCard} width="40" height="20" />
          &nbsp;&nbsp;
          {/* <img alt=''  src={Visa} width='40' height='20' />&nbsp;&nbsp;
            <img alt=''  src={MasterCard} width='40' height='20' />&nbsp;&nbsp; */}
          <AxoLocal entity="InvoiceRegisterCard" defaultValue={"Betal"} />
          <Conditional inline show={isGenerating}>
            &nbsp;
            <img
              alt=""
              src="/imgs/app/loading.gif"
              className="img-circle"
              width="20"
              height="20"
            />
          </Conditional>
        </Button>
      </React.Fragment>
    );
  };

  renderPaymentButtonGroup = () => {
    if (!this.props.onPayInvoice) {
      return null;
    }
    return <React.Fragment>{this.renderPaymentButton()}</React.Fragment>;
  };
  render() {
    let { acceptedConditions, acceptedPrivacyPolicy } = this.state;

    var invoice = this.state.invoice;
    // var aConto = this.calculateAContoTotal(invoice);

    var { paymentDate, paymentTerms, paymentPrivacyPolicy, requestGuid } =
      this.props;

    let { userProfile } = invoice;

    let paid = invoice.status === "Paid" || !!paymentDate;

    return (
      <Row ref={(r) => (this.invoiceNode = r)} style={{ margin: "auto" }}>
        <Col xs={12}>
          <div id="print-invoice" style={{ border: "2px solid #f2f2f2" }}>
            <InvoiceV2
              invoice={invoice}
              // renderClientButtons
              readOnly
              paymentDate={paymentDate}
              renderButtons={false}
            />
          </div>
          <div className="axoFrontPage text-right">
            <ButtonToolbar className="topPadding bottomPadding inlineBlock">
              <LexButton
                className="axoblue hidden-print"
                onClick={this.onPrint}
              >
                <AxoLocal entity="axoidcode212" defaultValue={"Udskriv"} />
              </LexButton>
              {!!requestGuid && invoice.hasPDFs && (
                <LexButton
                  className="axoblue hidden-print"
                  href={ApiService.getInvoicePDFDownloadPath(requestGuid)}
                >
                  <AxoLocal
                    entity="axoEntityidcode6"
                    defaultValue={"Download PDF"}
                  />
                </LexButton>
              )}
            </ButtonToolbar>
          </div>
          {userProfile.userType === "Admin" && !invoice.invoiceCreditedId ? (
            <div>
              <hr style={{ marginTop: 0 }} />
              <div>
                {!paid ? (
                  <React.Fragment>
                    <PanelGroup
                      id="ITsubscriptionb"
                      accordion
                      className="Lex-Accordion bottomPadding"
                      defaultActiveKey="0"
                      style={{
                        borderRadius: "0px",
                        paddingTop: "0px",
                        borderBottom: "1px solid white",
                      }}
                    >
                      <Panel className="backgroundlineargradient3" eventKey="0">
                        <Panel.Heading>
                          <Panel.Title toggle>
                            {AccordionUtils.renderAccordionHeader(
                              <span>
                                <AxoLocal
                                  entity="updategetAccountName8"
                                  defaultValue="Privatlivspolitik"
                                />
                              </span>
                            )}
                          </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body
                          collapsible
                          className="LexAccordionPanelody"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paymentPrivacyPolicy,
                            }}
                          />
                          {/* <div>Kortoplysninger behandles sikkert af Scanpay og Nets.</div>
                        <div>Vinterbadeforeningen har ikke adgang til medlemmernes kortoplysninger.</div>
                        <div>Vinterbadeforeningen behandler medlemmernes personoplysninger i overensstemmelse med persondataloven.</div>
                        <div>Medlemmernes data behandles sikkert af Axolex ApS, som følger persondataloven og opbevarer persondata krypteret på 
                          Microsofts Azure netværk, på servere inden for EU. <a href={AxolexDataBehandlerAftale} target='_blank' rel='noopener noreferrer'>Se Axolex databehandleraftale her.</a></div>
                        <div>Data vil ikke blive delt med tredjeparter eller andre virksomheder. </div>
                        <div>Hjemlen for databehandlingen er GDPR art. 6, stk. 1, b), da databehandlingen er nødvendig for at leve op til foreningens aftale med medlemmerne.</div> */}
                          {/* <div>
                          <AxoCheckbox
                            checked={acceptedPrivacyPolicy}
                            onChange={() => this.setState(prevState => ({ acceptedPrivacyPolicy: !prevState.acceptedPrivacyPolicy }))}
                          />
                          Jeg accepterer privatlivspolitikken.
                        </div> */}
                        </Panel.Body>
                      </Panel>
                    </PanelGroup>
                    <PanelGroup
                      id="ITsubscriptionb"
                      accordion
                      className="Lex-Accordion bottomPadding"
                      defaultActiveKey="0"
                      style={{
                        borderRadius: "0px",
                        paddingTop: "0px",
                        borderBottom: "1px solid white",
                      }}
                    >
                      <Panel className="backgroundlineargradient3" eventKey="0">
                        <Panel.Heading>
                          <Panel.Title toggle>
                            {AccordionUtils.renderAccordionHeader(
                              <span>
                                <AxoLocal
                                  entity="axoEntityidcode7"
                                  defaultValue={"Handelsbetingelser"}
                                />
                              </span>
                            )}
                          </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body
                          collapsible
                          className="LexAccordionPanelody"
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: paymentTerms }}
                          />
                          {/* <div>Betaling af kontingent kan som udgangspunkt ikke refunderes.</div>
                        <div>Beløb kan kun refunderes i særlige tilfælde og efter aftale med den ansvarlige leder.</div> */}
                          {/* <div>
                          <AxoCheckbox
                            checked={acceptedConditions}
                            onChange={() => this.setState(prevState => ({ acceptedConditions: !prevState.acceptedConditions }))}
                          />
                          Jeg accepterer handelsbetingelserne.
                        </div> */}
                        </Panel.Body>
                      </Panel>
                    </PanelGroup>
                  </React.Fragment>
                ) : null}
                <div className="axoFrontPage text-right">
                  <ButtonToolbar className="topPadding bottomPadding inlineBlock">
                    {this.renderPaymentButtonGroup()}
                  </ButtonToolbar>
                </div>
                {!paid && (!acceptedConditions || !acceptedPrivacyPolicy) ? (
                  <div className="axored">
                    <AxoLocal
                      entity="axoEntityidcode8"
                      defaultValue={
                        "Du skal klikke på Privatlivspolitik og Handelsbetingelser for at acceptere, før du kan komme videre med betalingen."
                      }
                    />
                  </div>
                ) : null}
                {/* <Conditional show={this.state.showPaymentCompletedMessage}>
                  <div style={{marginTop: '15px'}} >
                    <AxoLocal entity='InvoicePaymentCompleted' defaultValue={'Betalingen er gennemført'}/>.&nbsp;
                    {invoice.currency} {(subTotal + taxTotal + expensesTotal - aConto).toFixed(2)}
                    { cardInformation ? (
                      <span>&nbsp;<AxoLocal entity='InvoicePaymentInformation' defaultValue={'vil blive trukket på kortnummer'}/> ************{this.props.cardInformation ? this.props.cardInformation.last4 : ''}</span>
                    ) : null }
                    .
                  </div>
                </Conditional>
                <Conditional show={this.state.showPaymentErrorMessage}>
                  <div className='axored'>
                    <AxoLocal entity='InvoicePaymentFailed' defaultValue={'Betalingen kunne ikke gennemføres. Prøv igen senere.'}/>
                  </div>
                </Conditional> */}
              </div>
              <br />
            </div>
          ) : null}
        </Col>
      </Row>
    );
  }
}
