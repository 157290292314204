import React from "react";
import PropTypes from "prop-types";
import { DanishLicense, EnglishLicense, ChineseLicense } from "./Licenses";

import { Panel, PanelGroup } from "react-bootstrap";

import {
  AccordionUtils,
  AxoCheckbox,
  Icon,
  AxoLocal,
} from "../../utilities/LexUtilities";

export default class LicenseConsentForm extends React.PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onCheckChanged: PropTypes.func,
    showWarning: PropTypes.bool,
    hideControls: PropTypes.bool,
    noMargin: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    let { locale, checked, onCheckChanged, showWarning, hideControls } =
      this.props;
    return (
      <div>
        <PanelGroup
          id="ITsubscriptiond"
          accordion
          className="Lex-Accordion"
          defaultActiveKey="0"
          style={{
            borderRadius: "0px",
            paddingTop: "0px",
            borderBottom: "1px solid white",
          }}
        >
          <Panel className="backgroundlineargradient3" eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                {AccordionUtils.renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-fontello-info-circled" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="AdminTabViewtable2d"
                      defaultValue={"Brugerbetingelser"}
                    />
                    &nbsp;&nbsp;
                    <Icon glyph="icon-fontello-doc-text-2" />
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              {locale === "enUS" ? <EnglishLicense /> : null}
              {locale === "da" ? <DanishLicense /> : null}
              {locale === "ch" ? <ChineseLicense /> : null}
            </Panel.Body>
          </Panel>
        </PanelGroup>

        {/* <Accordion className={ className || 'Lex-Accordion' } defaultActiveKey='0' style={{ marginTop: noMargin ? '0px' : '5px'}}>
          <Panel header={AccordionUtils.renderAccordionHeader(<span>
            <Icon  glyph="icon-fontello-info-circled" />&nbsp;&nbsp; 
            <AxoLocal entity='AdminTabViewtable2d'defaultValue={'Brugerbetingelser'}/>&nbsp;&nbsp; 
            <Icon  glyph="icon-fontello-doc-text-2" />
            </span>)} eventKey="1">
            { locale === 'enUS' ? (
              <EnglishLicense />
            ) : null }
            { locale === 'da' ? (
              <DanishLicense />
            ) : null }
            { locale === 'ch' ? (
              <ChineseLicense />
            ) : null }
          </Panel>
        </Accordion> */}
        {!hideControls ? (
          <React.Fragment>
            <div style={{ marginTop: "10px" }}>
              <AxoCheckbox checked={checked} onChange={onCheckChanged} />
              <AxoLocal
                entity="AdminTabViewtable2b"
                defaultValue="Jeg har læst og accepteret brugerbetingelserne."
              />
            </div>
            {showWarning ? (
              <div style={{ color: "red" }}>
                <AxoLocal
                  entity="AdminTabViewtable2c"
                  defaultValue="Du skal acceptere brugerbetingelserne."
                />
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
