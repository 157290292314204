import React from "react";
import moment from "moment";

import {
  ApiService,
  TimeEntryService,
  NumberService,
  DataActions,
} from "../../services/AxoServices";

import {
  AccordionUtils,
  LexButton,
  Icon,
  LabelSearchBox,
  AxoLocal,
  getText,
  InlineEdit,
  InlineTextArea,
  Flexbox,
  FlexElement,
  AsyncButton,
  SinglePaymentCollectionModal,
  LexPanelGroup,
  ContactSelectModal,
} from "../../utilities/LexUtilities";

import {
  Panel,
  ButtonToolbar,
  Grid,
  Row,
  Col,
  Table,
  FormControl,
  FormGroup,
  InputGroup,
} from "react-bootstrap";

import SubscriptionInfoForm from "./SubscriptionInfoForm";

const SendingEnum = {
  Pending: 0,
  Confirm: 1,
  Sending: 2,
  Error: 3,
  Success: 4,
};

export default class MultiPaymentSection extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: {},
      confirmSendToGroup: false,
      sendingToGroup: false,
      showSendingError: false,
      showSendingSuccess: false,

      enableSendToAll: false,
      confirmSendToAll: false,
      sendingToAll: false,
      remindingAllStatus: SendingEnum.Pending, //1: Confirm. 2: Sending: 3: Error. 4: Success
      showRemindingClientError: -1,
      pageSize: 500,
      searchText: "",
    };

    this.collectionModal = React.createRef();
    this.contactSelectModal = React.createRef();
  }

  selectClientGroup = (selectedGroup) => {
    if (!!selectedGroup.id) {
      this.setState({
        selectedGroup,
        enableSendToAll: false,
      });
    } else {
      this.setState({
        selectedGroup: {},
        enableSendToAll: true,
      });
    }
  };

  onSendToGroup = () => {
    this.setState({ confirmSendToGroup: true });
  };

  onSendToAll = () => {
    this.setState({ confirmSendToAll: true });
  };

  onCancelGroupSend = () => {
    this.setState({
      selectedGroup: {},
      confirmSendToGroup: false,
      confirmSendToAll: false,
    });
  };

  onConfirmGroupSend = () => {
    var { invoice, updateRequests } = this.props;
    var { selectedGroup } = this.state;

    this.setState({ sendingToGroup: true });
    ApiService.sendInvoiceToClients({
      clientIds: selectedGroup.clientIds,
      invoiceId: invoice.id,
      messageSubject: this.getMessageSubject(),
      messageSenderEmail: this.getSenderEmail(),
      messageSenderDescription: this.getSenderDescription(),
      paymentLinkDescription: this.getPaymentLinkDescription(),
      messageText: this.getMessageText(),
      messageUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        "/InvoicePayment",
    })
      .then((response) => {
        if (response.ok) {
          if (!!updateRequests) {
            updateRequests();
          }

          this.setState({
            sendingToGroup: false,
            confirmSendToGroup: false,
            showSendingSuccess: true,
            selectedGroup: {},
          });
          setTimeout(() => {
            this.setState({
              showSendingSuccess: false,
            });
          }, 3000);
          return true;
        }
        return Promise.reject("Email could not be send to group");
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({
          showSendingError: true,
          confirmSendToGroup: false,
          sendingToGroup: false,
        });
        setTimeout(() => {
          this.setState({ showSendingError: false });
        }, 3000);
      });
  };

  onConfirmSelectionSend = async (idArray) => {
    var { invoice, updateRequests } = this.props;

    let response = await ApiService.sendInvoiceToClients({
      clientIds: idArray,
      invoiceId: invoice.id,
      messageSubject: this.getMessageSubject(),
      messageSenderEmail: this.getSenderEmail(),
      messageSenderDescription: this.getSenderDescription(),
      paymentLinkDescription: this.getPaymentLinkDescription(),
      messageText: this.getMessageText(),
      messageUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        "/InvoicePayment",
    });

    if (response.ok) {
      if (!!updateRequests) {
        updateRequests();
      }
    }

    return response.ok;
  };

  onConfirmAllSend = () => {
    var { invoice, updateRequests } = this.props;

    this.setState({ sendingToAll: true });
    ApiService.sendInvoiceToClients({
      sendToAll: true,
      invoiceId: invoice.id,
      messageSubject: this.getMessageSubject(),
      messageSenderEmail: this.getSenderEmail(),
      messageSenderDescription: this.getSenderDescription(),
      paymentLinkDescription: this.getPaymentLinkDescription(),
      messageText: this.getMessageText(),
      messageUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        "/InvoicePayment",
    })
      .then((response) => {
        if (response.ok) {
          if (!!updateRequests) {
            updateRequests();
          }

          this.setState({
            sendingToAll: false,
            confirmSendToAll: false,
            showSendingSuccess: true,
          });
          setTimeout(() => {
            this.setState({
              showSendingSuccess: false,
            });
          }, 3000);
          return true;
        }
        return Promise.reject("Email could not be send to group");
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({
          showSendingError: true,
          confirmSendToAll: false,
          sendingToAll: false,
        });
        setTimeout(() => {
          this.setState({ showSendingError: false });
        }, 3000);
      });
  };

  getTotal = (timeEntries) => {
    return timeEntries.reduce((acc, timeEntry) => {
      return acc + TimeEntryService.getTotalValue(timeEntry);
    }, 0);
  };

  SelectedGroupSection = (props) => {
    var { selectedGroup, confirmSendToGroup, sendingToGroup } = this.state;

    if (!selectedGroup.id) {
      return null;
    }

    if (!confirmSendToGroup) {
      return (
        <div className="topPadding">
          <LexButton onClick={this.onSendToGroup}>
            <AxoLocal
              entity="DocumentGridViewsendto"
              defaultValue={"Send til"}
            />{" "}
            "{selectedGroup.name}" ({selectedGroup.clientIds.length})
          </LexButton>
        </div>
      );
    }

    return (
      <div className="flexbox-responsive topPadding">
        <div style={{ marginRight: "10px" }}>
          <AxoLocal
            entity="paymentDate14a"
            defaultValue={
              "Vil du sende en mail med en opkrævning til samtlige klienter i gruppen"
            }
          />
          &nbsp;"{selectedGroup.name}" ({selectedGroup.clientIds.length})?
        </div>
        <ButtonToolbar>
          <LexButton
            disabled={sendingToGroup}
            onClick={this.onConfirmGroupSend}
          >
            <AxoLocal
              entity="SidebarRightContainerConfirmPassword"
              defaultValue={"Bekræft"}
            />{" "}
            {sendingToGroup ? (
              <span>
                &nbsp;
                <img
                  alt=""
                  src="/imgs/app/loading.gif"
                  className="img-circle"
                  width="20"
                  height="20"
                />
              </span>
            ) : null}
          </LexButton>
          <LexButton disabled={sendingToGroup} onClick={this.onCancelGroupSend}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </div>
    );
  };

  SendToAllSection = (props) => {
    var { enableSendToAll, confirmSendToAll, sendingToAll } = this.state;

    if (!enableSendToAll) {
      return null;
    }

    if (!confirmSendToAll) {
      return (
        <LexButton onClick={this.onSendToAll}>
          <AxoLocal
            entity="paymentDate15a"
            defaultValue={" Send til alle klienter"}
          />
        </LexButton>
      );
    }

    return (
      <div className="flexbox-responsive">
        <div style={{ marginRight: "10px" }}>
          <AxoLocal
            entity="paymentDate16a"
            defaultValue={
              " Vil du sende en mail med en opkrævning til samtlige klienter?"
            }
          />
        </div>
        <ButtonToolbar>
          <LexButton disabled={sendingToAll} onClick={this.onConfirmAllSend}>
            <AxoLocal
              entity="SidebarRightContainerConfirmPassword"
              defaultValue={"Bekræft"}
            />
            {sendingToAll ? (
              <span>
                &nbsp;
                <img
                  alt=""
                  src="/imgs/app/loading.gif"
                  className="img-circle"
                  width="20"
                  height="20"
                />
              </span>
            ) : null}
          </LexButton>
          <LexButton disabled={sendingToAll} onClick={this.onCancelGroupSend}>
            <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
          </LexButton>
        </ButtonToolbar>
      </div>
    );
  };

  updateTimeEntry = (propertyName, value) => {
    let { invoice } = this.props;

    if (!invoice || invoice.timeEntries.length === 0) {
      return;
    }

    DataActions.updateTimeEntry({
      ...invoice.timeEntries[0],
      [propertyName]: value,
    });
  };

  updateInvoice = (propertyName, value) => {
    let { invoice } = this.props;

    if (!invoice) {
      return;
    }

    DataActions.updateInvoice({
      ...invoice,
      [propertyName]: value,
    });
  };

  SendingPanelBody = (props) => {
    var { clientLabels, invoice, userProfile, isRegistration, contacts } =
      this.props;
    var { showSendingSuccess, showSendingError } = this.state;

    if (!invoice.timeEntries || invoice.timeEntries.length === 0) {
      return null;
    }

    // let labelOptions = [
    //  {
    //    id: 0,
    //    name: getText('AdminTabView22', 'Alle')
    //  }
    // ].concat(clientLabels);

    let labelOptions = clientLabels;

    let total = this.getTotal(invoice.timeEntries);

    let SelectedGroupSection = this.SelectedGroupSection;
    let SendToAllSection = this.SendToAllSection;
    return (
      <div>
        <div>
          {!isRegistration && (
            <div className="mediumText">
              <strong>
                <AxoLocal entity="InvoiceInvoicesum" defaultValue={"Beløb"} />
              </strong>
              :{NumberService.formatDecimal(total)}{" "}
              {userProfile.currency.toUpperCase()}
              {invoice.timeEntries[0].vat > 0 ? (
                <span>
                  &nbsp;
                  <AxoLocal
                    entity="axoEntityidcode192"
                    defaultValue={"inkl. moms."}
                  />
                </span>
              ) : null}
            </div>
          )}
          {isRegistration && (
            <div className="mediumText">
              <strong>
                <AxoLocal entity="InvoiceInvoicesum" defaultValue={"Beløb"} />
              </strong>
              : DKK {NumberService.formatDecimal(invoice.outstandingAmount)}
            </div>
          )}
          <div className="mediumText">
            <strong>
              <AxoLocal entity="Enhedspris6" defaultValue={"Beskrivelse"} />
            </strong>
            :&nbsp;
            {isRegistration ? (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {invoice.timeEntries[0].description}
              </div>
            ) : (
              // <InlineTextArea
              //   value={invoice.timeEntries[0].description || "---"}
              //   change={input => this.updateTimeEntry('description', input.value)}
              //   rows={3}
              // />
              <InlineEdit
                value={invoice.timeEntries[0].description || "---"}
                change={(input) =>
                  this.updateTimeEntry("description", input.value)
                }
              />
            )}
          </div>
          <div className="mediumText">
            <strong>
              <AxoLocal entity="axoidcode151" defaultValue={"Emne:"} />
            </strong>
            :
            <InlineEdit
              value={invoice.thankYou || "---"}
              change={(input) => this.updateInvoice("thankYou", input.value)}
            />
          </div>
          <div className="mediumText">
            <div>
              <strong>
                <AxoLocal entity="ContactFormMessage" defaultValue={"Besked"} />
                :
              </strong>
            </div>
            <InlineTextArea
              value={invoice.message || "---"}
              change={(input) => this.updateInvoice("message", input.value)}
              rows={4}
            />
            {/* <div style={{ whiteSpace: 'pre-wrap'}}>
            {invoice.message}
          </div> */}
          </div>
          <div>
            <LexButton
              disabled={!contacts || contacts.length === 0}
              onClick={() => this.contactSelectModal.current.open()}
            >
              Send til udvalgte medlemmer
            </LexButton>
            {!!contacts && contacts.length > 0 && (
              <ContactSelectModal
                ref={this.contactSelectModal}
                callback={this.onConfirmSelectionSend}
                confirmLabel="Send til medlemmer"
                options={contacts.map((c) => ({
                  id: c.id,
                  name: `${!!c.clientNumber ? `(${c.clientNumber}) ` : ""} ${
                    c.firstName
                  } ${c.lastName || ""} (${c.eMail})`,
                }))}
              />
            )}
          </div>
          <AxoLocal entity="paymentDate18a1" defaultValue={"Send til gruppe"} />{" "}
        </div>
        <AxoLocal
          componentClass={LabelSearchBox}
          labels={labelOptions}
          handleSelectedLabel={this.selectClientGroup}
          disabled={labelOptions.length === 0}
          inputBorder="1px solid #4CAF50"
          clearOnSelect
          componentAttribute="placeholder"
          entity="ContactLabelPlaceholder"
        />
        {(() => {
          if (showSendingError) {
            return (
              <div className="axored">
                <AxoLocal
                  entity="LoginshowServerError"
                  defaultValue={"Der skete en fejl. Prøv igen senere."}
                />
              </div>
            );
          }
          if (showSendingSuccess) {
            return (
              <div className="axoblue">
                <AxoLocal
                  entity="axoidcode184"
                  defaultValue={" Opkrævningen blev afsendt."}
                />
              </div>
            );
          }
          return (
            <React.Fragment>
              <SelectedGroupSection />
              <div className="topPadding"></div>
              <SendToAllSection />
            </React.Fragment>
          );
        })()}
      </div>
    );
  };

  SubscriptionPanelBody = () => {
    // var { clientLabels, invoice, userProfile, isRegistration } = this.props;
    // var { showSendingSuccess, showSendingError } = this.state;

    // if(!invoice.timeEntries || invoice.timeEntries.length === 0) {
    //   return null;
    // }

    return <div></div>;
  };

  onSearch = (event) => {
    this.setState({ searchText: event.target.value });
  };

  filterRequest = (request) => {
    let { searchText } = this.state;

    if (!searchText) {
      return true;
    }

    var contact = request.contactInfo;
    //Remove '-' from guid and search text, since it is typically removed from bank statements.
    let text = `${request.guid.replace(/-/g, "")}${contact.clientNumber}${
      contact.firstName
    }${contact.lastName}`.toLowerCase();
    return text.includes(searchText.replace(/-/g, "").toLowerCase());
  };

  sendMailToPending = () => {
    let { sendMailToList, paymentRequests } = this.props;

    if (!sendMailToList) {
      return;
    }

    let pendingRequests = paymentRequests.filter((p) => !p.paymentDate);

    let list = "";
    pendingRequests.forEach((request) => {
      list += request.contactInfo.eMail;
      list += ";";
    });

    sendMailToList(list);
  };

  chargeSubscriber = async (guid) => {
    let { invoice, updateRequests } = this.props;

    let response = await ApiService.chargeRequestSubscriber({
      requestGuid: guid,
      amount: invoice.outstandingAmount,
    });

    if (!response.ok) {
      let error = await response.text();
      alert(error);
      return false;
    }

    if (updateRequests) {
      updateRequests();
    }

    return true;
  };

  sendReceipt = async (guid) => {
    let { invoice } = this.props;

    let response = await ApiService.sendReceiptToRequestSubscriber({
      requestGuid: guid,
      amount: invoice.outstandingAmount,
    });

    if (!response.ok) {
      let error = await response.text();
      alert(error);
      return false;
    }

    return true;
  };

  sortRequests = (l, r) => {
    if (!!l.contactInfo.clientNumber && !!r.contactInfo.clientNumber) {
      return l.contactInfo.clientNumber - r.contactInfo.clientNumber;
    }

    let lName = (l.contactInfo.lastName || "").toUpperCase(); // ignore upper and lowercase
    let rName = (r.contactInfo.lastName || "").toUpperCase(); // ignore upper and lowercase
    if (lName < rName) {
      return -1;
    }
    if (lName > rName) {
      return 1;
    }

    // names must be equal
    return 0;
  };

  sortRequestsByFirstName = (l, r) => {
    if (!!l.contactInfo.clientNumber && !!r.contactInfo.clientNumber) {
      return l.contactInfo.clientNumber - r.contactInfo.clientNumber;
    }

    let lName = (l.contactInfo.firstName || "").toUpperCase(); // ignore upper and lowercase
    let rName = (r.contactInfo.firstName || "").toUpperCase(); // ignore upper and lowercase
    if (lName < rName) {
      return -1;
    }
    if (lName > rName) {
      return 1;
    }

    // names must be equal
    return 0;
  };

  RequestPanelBody = (props) => {
    var {
      paymentRequests,
      invoice,
      deletePaymentRequest,
      cancelRegistrationRequest,
      sendMailToList,
      isRegistration,
      adminCodeValidated,
      updateRequests,
    } = this.props;

    var { remindingAllStatus, pageSize } = this.state;

    var paidRequests = paymentRequests.filter((p) => !!p.paymentDate);

    if (adminCodeValidated) {
      paidRequests = paidRequests.sort(this.sortRequestsByFirstName);
    } else {
      paidRequests = paidRequests.sort((l, r) => {
        if (
          l.paymentStatusMessage === "Paid" &&
          r.paymentStatusMessage !== "Paid"
        ) {
          return 1;
        }
        if (
          l.paymentStatusMessage !== "Paid" &&
          r.paymentStatusMessage === "Paid"
        ) {
          return -1;
        }

        if (l.paymentDate === r.paymentDate) {
          return 0;
        }
        return moment(l.paymentDate).isAfter(moment(r.paymentDate)) ? -1 : 1;
      });
    }

    var pendingRequests = paymentRequests.filter((p) => !p.paymentDate);

    let total = this.getTotal(invoice.timeEntries);

    return (
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <FormGroup controlId="inputWithIcon">
              <InputGroup>
                <InputGroup.Addon>
                  <Icon glyph="icon-fontello-search" />
                </InputGroup.Addon>
                {/* Søg...  */}
                <AxoLocal
                  componentClass={FormControl}
                  type="text"
                  value={this.state.searchText}
                  onChange={this.onSearch}
                  componentAttribute="placeholder"
                  entity="ContactTableViewplaceholderonSearch"
                />
                <FormControl.Feedback>
                  <Icon glyph="icon-fontello-search" />
                </FormControl.Feedback>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Flexbox alignCenter>
              <FlexElement className="rightPadding">
                <h3>
                  {isRegistration ? (
                    <>
                      <AxoLocal
                        entity="axoEntityidcode182"
                        defaultValue={"Tilmeldinger"}
                      />
                    </>
                  ) : (
                    <AxoLocal
                      entity="InvoiceInvoicePaid"
                      defaultValue={"Betalt"}
                    />
                  )}
                  &nbsp;({paidRequests.length})
                </h3>
              </FlexElement>
              {isRegistration && (
                <FlexElement className="topPadding">
                  <LexButton
                    onClick={() => this.collectionModal.current.open()}
                  >
                    <AxoLocal
                      entity="axoEntityidcode188"
                      defaultValue={"Opkræv individuel betaling"}
                    />
                  </LexButton>
                </FlexElement>
              )}
            </Flexbox>
            <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
              <Table condensed hover>
                <thead>
                  <tr>
                    <th>
                      <AxoLocal
                        entity="TimeEntryFormntimeEntry"
                        defaultValue={"Dato"}
                      />
                    </th>
                    <th>
                      <AxoLocal
                        entity="TrashEntriesViewName"
                        defaultValue={"Navn"}
                      />
                    </th>
                    {!isRegistration && (
                      <>
                        <th>
                          <AxoLocal
                            entity="CalendarEventFormbookingLink"
                            defaultValue={"Link"}
                          />{" "}
                        </th>
                        <th>
                          <AxoLocal
                            entity="InvoiceInvoicesum"
                            defaultValue={"Beløb"}
                          />
                        </th>
                      </>
                    )}
                    {isRegistration && (
                      <>
                        <th>
                          <AxoLocal
                            entity="CalendarEventFormbookingLink"
                            defaultValue={"Link"}
                          />{" "}
                        </th>
                        <th>
                          <AxoLocal
                            entity="InvoiceInvoicesum"
                            defaultValue={"Beløb"}
                          />
                        </th>
                        <th></th>
                        {adminCodeValidated && <th></th>}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {paidRequests
                    .filter(this.filterRequest)
                    .slice(0, pageSize)
                    .map((p) => {
                      let contact = p.contactInfo;
                      let paid = p.paymentStatusMessage === "Paid";

                      let label = (
                        <>
                          {!!contact.clientNumber && (
                            <>({contact.clientNumber}) </>
                          )}
                          {contact.firstName} {contact.lastName}
                        </>
                      );

                      return (
                        <tr key={contact.id}>
                          <td>{moment(p.paymentDate).format("L HH:mm")}</td>
                          {!!p.paymentStatusMessage ? (
                            <>
                              {paid ? (
                                <td>
                                  {label}{" "}
                                  <Icon
                                    className="axogreen"
                                    glyph="icon-fontello-check"
                                  />
                                </td>
                              ) : (
                                <td
                                  className="axored"
                                  title={p.paymentStatusMessage}
                                >
                                  {label}
                                  &nbsp;&nbsp;&nbsp;
                                  <AsyncButton
                                    title={getText(
                                      "axoEntityidcode260",
                                      "Send tilmeldingslink til medlem"
                                    )}
                                    onClick={() =>
                                      this.onSendPaymentFailureMessageToClient(
                                        contact.id
                                      )
                                    }
                                  >
                                    <AxoLocal
                                      entity="ForgotPasswordSend"
                                      defaultValue={"Send"}
                                    />
                                  </AsyncButton>
                                </td>
                              )}
                            </>
                          ) : (
                            <td>{label}</td>
                          )}

                          {!isRegistration && (
                            <>
                              <td>
                                <a
                                  href={
                                    window.location.protocol +
                                    "//" +
                                    window.location.host +
                                    "/InvoicePayment?code=" +
                                    p.guid
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <AxoLocal
                                    entity="CalendarEventFormbookingLink"
                                    defaultValue={"Link"}
                                  />
                                </a>
                              </td>
                              <td>DKK {NumberService.formatDecimal(total)}</td>
                            </>
                          )}
                          {isRegistration && (
                            <>
                              <td>
                                <a
                                  href={
                                    window.location.protocol +
                                    "//" +
                                    window.location.host +
                                    "/InvoicePayment?code=" +
                                    p.guid
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <AxoLocal
                                    entity="CalendarEventFormbookingLink"
                                    defaultValue={"Link"}
                                  />
                                </a>
                              </td>
                              <td>
                                <Icon
                                  glyph="icon-fontello-circle"
                                  className="axogreen"
                                />
                                &nbsp;DKK{" "}
                                {NumberService.formatDecimal(
                                  p.latestPaymentAmount ||
                                    invoice.outstandingAmount
                                )}
                              </td>
                              <td>
                                <Icon
                                  role="button"
                                  onClick={() =>
                                    cancelRegistrationRequest(p.guid)
                                  }
                                  title="Afmeld tilmelding"
                                  className="axored"
                                  glyph="icon-fontello-scissors"
                                />
                              </td>
                              {adminCodeValidated && (
                                <td>
                                  {paid ? (
                                    <AsyncButton
                                      onClick={() => this.sendReceipt(p.guid)}
                                    >
                                      S
                                    </AsyncButton>
                                  ) : (
                                    <AsyncButton
                                      onClick={() =>
                                        this.chargeSubscriber(p.guid)
                                      }
                                    >
                                      +
                                    </AsyncButton>
                                  )}
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            {pageSize < paidRequests.length ? (
              <LexButton
                onClick={() =>
                  this.setState((prevState) => ({
                    pageSize: prevState.pageSize + 50,
                  }))
                }
              >
                <AxoLocal
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </LexButton>
            ) : null}
          </Col>
          <Col md={6}>
            <h3>
              <Flexbox>
                <FlexElement className="rightPadding">
                  {isRegistration ? (
                    <>
                      <AxoLocal
                        entity="axoEntityidcode185"
                        defaultValue={"Afventer tilmelding"}
                      />
                    </>
                  ) : (
                    <AxoLocal
                      entity="paymentDate17a"
                      defaultValue={"Afventer betaling"}
                    />
                  )}
                  &nbsp;({pendingRequests.length})
                </FlexElement>
                {remindingAllStatus === SendingEnum.Pending && (
                  <FlexElement className="rightPadding">
                    <LexButton onClick={this.onRemindAll}>
                      <AxoLocal
                        entity="paymentDate18a"
                        defaultValue={"Send påmindelse til alle"}
                      />
                    </LexButton>
                  </FlexElement>
                )}
                {!!sendMailToList && (
                  <FlexElement>
                    <LexButton
                      title={getText(
                        "axoEntityidcode271",
                        "Send mail til alle"
                      )}
                      onClick={this.sendMailToPending}
                    >
                      <Icon glyph="icon-fontello-mail" />
                    </LexButton>
                  </FlexElement>
                )}
              </Flexbox>
            </h3>
            {remindingAllStatus === SendingEnum.Confirm ||
            remindingAllStatus === SendingEnum.Sending ? (
              <div>
                <AxoLocal
                  entity="paymentDate19a"
                  defaultValue={"Vil du sende en påmindelse til"}
                />
                {pendingRequests.length}
                <AxoLocal entity="paymentDate20a" defaultValue={"kunder?"} />
                <ButtonToolbar>
                  <LexButton
                    disabled={remindingAllStatus === SendingEnum.Sending}
                    onClick={this.onConfirmRemindAll}
                  >
                    <AxoLocal
                      entity="SidebarRightContainerConfirmPassword"
                      defaultValue={"Bekræft"}
                    />{" "}
                    {remindingAllStatus === SendingEnum.Sending ? (
                      <span>
                        &nbsp;
                        <img
                          alt=""
                          src="/imgs/app/loading.gif"
                          className="img-circle"
                          width="20"
                          height="20"
                        />
                      </span>
                    ) : null}
                  </LexButton>
                  <LexButton
                    disabled={remindingAllStatus === 2}
                    onClick={this.onCancelRemindAll}
                  >
                    <AxoLocal
                      entity="axoAccounting22"
                      defaultValue={"Fortryd"}
                    />
                  </LexButton>
                </ButtonToolbar>
              </div>
            ) : null}
            {remindingAllStatus === SendingEnum.Error ? (
              <div className="axored">
                <AxoLocal
                  entity="paymentDate17a"
                  defaultValue={"Der skete en fejl. Prøv igen senere."}
                />{" "}
              </div>
            ) : null}
            {remindingAllStatus === SendingEnum.Success ? (
              <div className="axoblue">
                <AxoLocal
                  entity="paymentDate21a"
                  defaultValue={"Påmindelserne blev sendt."}
                />
              </div>
            ) : null}
            <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
              <Table condensed hover>
                <thead>
                  <tr>
                    <th>
                      <AxoLocal
                        entity="TimeEntryFormntimeEntry"
                        defaultValue={"Dato"}
                      />
                    </th>
                    <th>
                      <AxoLocal
                        entity="TrashEntriesViewName"
                        defaultValue={"Navn"}
                      />
                    </th>
                    <th>
                      <AxoLocal
                        entity="CalendarEventFormbookingLink"
                        defaultValue={"Link"}
                      />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pendingRequests
                    .filter(this.filterRequest)
                    .slice(0, pageSize)
                    .map((p) => {
                      let contact = p.contactInfo;

                      let creationDate = moment(p.creationDate).format("L HH:mm");
                      let label = (
                        <>
                          {!!contact.clientNumber && (
                            <>({contact.clientNumber}) </>
                          )}
                          {contact.firstName} {contact.lastName}
                        </>
                      );

                      return (
                        <tr key={contact.id}>
                          <td>{creationDate}</td>
                          <td>{label}</td>
                          <td>
                            <a
                              href={
                                window.location.protocol +
                                "//" +
                                window.location.host +
                                "/InvoicePayment?code=" +
                                p.guid
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <AxoLocal
                                entity="CalendarEventFormbookingLink"
                                defaultValue={"Link"}
                              />
                              {/* {isRegistration ? (
                                <>
                                  <AxoLocal
                                    entity="axoEntityidcode184"
                                    defaultValue={"Tilmeldingslink"}
                                  />
                                </>
                              ) : (
                                <AxoLocal
                                  entity="axoidcode36"
                                  defaultValue={"Betalingslink"}
                                />
                              )} */}
                            </a>
                          </td>
                          <td>
                            <AsyncButton
                              onClick={() => this.onRemindClient(contact.id)}
                            >
                              <AxoLocal
                                entity="paymentDate23a"
                                defaultValue={"Send påmindelse"}
                              />
                            </AsyncButton>
                            {deletePaymentRequest ? (
                              <span>
                                &nbsp;&nbsp;&nbsp;
                                <Icon
                                  role="button"
                                  onClick={() =>
                                    deletePaymentRequest(
                                      p.contactInfoId,
                                      p.invoiceId
                                    )
                                  }
                                  title={
                                    isRegistration
                                      ? "Slet anmodning"
                                      : getText(
                                          "axoidcode40",
                                          "Slet opkrævning"
                                        )
                                  }
                                  className="axored"
                                  glyph="icon-fontello-scissors"
                                />
                              </span>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            {pageSize < pendingRequests.length ? (
              <LexButton
                onClick={() =>
                  this.setState((prevState) => ({
                    pageSize: prevState.pageSize + 50,
                  }))
                }
              >
                <AxoLocal
                  entity="ViewShowmoreinfo"
                  defaultValue={"Vis flere"}
                />
              </LexButton>
            ) : null}
          </Col>
        </Row>
        <SinglePaymentCollectionModal
          ref={this.collectionModal}
          amount={invoice.outstandingAmount}
          updateRequests={updateRequests}
          onSendPaymentFailureMessage={this.onSendPaymentFailureMessageByGuid}
          options={paidRequests.sort(this.sortRequests).map((p) => {
            let contact = p.contactInfo;

            let label = !!contact.clientNumber
              ? "(" + contact.clientNumber + ") "
              : "";

            label += contact.firstName + " " + contact.lastName;

            return {
              id: p.guid,
              name: label,
            };
          })}
        />
      </Grid>
    );
  };

  // remindingClient, sentReminderToClient, showRemindingClientError
  onConfirmRemindAll = () => {
    var { invoice, paymentRequests } = this.props;

    var pendingClients = paymentRequests
      .filter((p) => !p.paymentDate)
      .map((p) => p.contactInfo.id);

    this.setState({ remindingAllStatus: SendingEnum.Sending });
    ApiService.sendInvoiceToClients({
      clientIds: pendingClients,
      invoiceId: invoice.id,
      messageSubject: this.getMessageSubject(),
      messageSenderEmail: this.getSenderEmail(),
      messageSenderDescription: this.getSenderDescription(),
      paymentLinkDescription: this.getPaymentLinkDescription(),
      messageText: this.getMessageText(),
      messageUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        "/InvoicePayment",
    })
      .then((response) => {
        if (response.ok) {
          this.setState({
            remindingAllStatus: SendingEnum.Success,
          });
          setTimeout(() => {
            this.setState({
              remindingAllStatus: SendingEnum.Pending,
            });
          }, 10000);
          return true;
        }
        return Promise.reject("Email could not be send to group");
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({
          remindingAllStatus: SendingEnum.Error,
        });
        setTimeout(() => {
          this.setState({ remindingAllStatus: SendingEnum.Pending });
        }, 3000);
      });
  };

  onRemindAll = () => {
    this.setState({ remindingAllStatus: SendingEnum.Confirm });
  };

  onCancelRemindAll = () => {
    this.setState({ remindingAllStatus: SendingEnum.Pending });
  };

  onSendPaymentFailureMessageByGuid = async (guid) => {
    let { paymentRequests } = this.props;

    var request = paymentRequests.find((r) => r.guid === guid);
    if (!request) {
      return false;
    }

    return await this.onSendPaymentFailureMessageToClient(
      request.contactInfoId
    );
  };

  onSendPaymentFailureMessageToClient = async (contactId) => {
    return await this.onRemindClient(contactId, true);
  };

  onRemindClient = async (contactId, paymentFailureMessage = false) => {
    var { invoice } = this.props;

    this.setState({ remindingClient: contactId });
    let response = await ApiService.sendInvoiceToClients({
      clientIds: [contactId],
      invoiceId: invoice.id,
      messageSubject: this.getMessageSubject(paymentFailureMessage),
      messageSenderEmail: this.getSenderEmail(),
      messageSenderDescription: this.getSenderDescription(),
      paymentLinkDescription: this.getPaymentLinkDescription(),
      messageText: this.getMessageText(paymentFailureMessage),
      messageUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        "/InvoicePayment",
    });

    return response.ok;
  };

  getMessageSubject = (paymentFailureMessage = false) => {
    if (paymentFailureMessage) {
      return getText(
        "axoEntityidcode181",
        "Betaling fejlede. Opdater dine kortoplysninger."
      );
    }

    return this.props.invoice.thankYou;
  };

  getMessageText = (paymentFailureMessage = false) => {
    let { userProfile } = this.props;
    if (paymentFailureMessage) {
      return `<p>${userProfile.company} ${getText(
        "axoEntityidcode186",
        "har forsøgt at hæve betaling på dit kort, men betalingen blev afvist."
      )}</p>
      <p>${getText(
        "axoEntityidcode187",
        "Klik på linket for at opdatere dine betalingsoplysninger og godkende betalingen."
      )}</p>`;
    }
    // return '<div style="white-space: pre-wrap;">' + this.props.invoice.message + '</div>';
    return (
      '<div style="white-space: pre-wrap;">' +
      this.props.invoice.message +
      "</div>"
    );
  };

  getSenderEmail = () => {
    let { invoice } = this.props;
    let email = invoice.userMailOverride || invoice.userProfile.eMail;
    return email;
  };

  getSenderDescription = () => {
    let { invoice } = this.props;
    let company = invoice.userCompanyOverride || invoice.userProfile.company;
    return company;
  };

  getPaymentLinkDescription = () => {
    let { isRegistration } = this.props;

    if (isRegistration) {
      return getText(
        "axoEntityidcode178",
        "--> Klik her for at tilmelde dig <--"
      );
    }

    return getText("axoidcode45", "--> Klik her for at betale <--");
  };

  render() {
    var {
      // paymentRequests,
      startOpen,
      hideSendPanel,
      invoice,
      isRegistration,
    } = this.props;

    let RequestPanelBody = this.RequestPanelBody;
    let SendingPanelBody = this.SendingPanelBody;
    return (
      <LexPanelGroup
        id="ITsubscriptionb"
        accordion
        defaultActiveKey={startOpen ? "1" : "0"}
        style={{
          borderRadius: "0px",
          paddingTop: "0px",
          borderBottom: "1px solid white",
        }}
      >
        <Panel
          className="backgroundlineargradient3"
          style={{ border: "1px solid #9DE99F" }}
          eventKey="1"
        >
          <Panel.Heading>
            <Panel.Title toggle>
              {AccordionUtils.renderAccordionHeader(
                <span>
                  <Icon glyph="icon-simple-line-icons-screen-desktop" />
                  &nbsp;
                  {isRegistration ? (
                    <>
                      {" "}
                      <AxoLocal
                        entity="axoEntityidcode182"
                        defaultValue={"Tilmeldinger"}
                      />{" "}
                    </>
                  ) : (
                    <AxoLocal
                      entity="axoidcode37"
                      defaultValue={"Opkrævninger"}
                    />
                  )}
                </span>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible className="LexAccordionPanelody">
            <RequestPanelBody />
          </Panel.Body>
        </Panel>
        {isRegistration && (
          <Panel
            className="backgroundlineargradient3"
            style={{ border: "1px solid #9DE99F" }}
            eventKey="3"
          >
            <Panel.Heading>
              <Panel.Title toggle>
                {AccordionUtils.renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-simple-line-icons-screen-desktop" />
                    &nbsp;Abonnement
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <div>
                <SubscriptionInfoForm
                  key={invoice.id}
                  startState={{
                    outstandingAmount: invoice.outstandingAmount,
                    description: invoice.timeEntries[0].description,
                    activeSubscription:
                      invoice.subscriptionAction === "Approve",
                    startDate: moment.utc(invoice.subscriptionCurrentPeriodEnd),
                    subscriptionInterval: invoice.subscriptionInterval,
                    chargeFirst: invoice.reverseVAT,
                  }}
                  onSubmit={async (newState) => {
                    let response = await DataActions.updateInvoice({
                      ...invoice,
                      outstandingAmount: newState.outstandingAmount,
                      timeEntries: invoice.timeEntries.map((t) => ({
                        ...t,
                        description: newState.description,
                      })),
                      subscriptionAction: newState.activeSubscription
                        ? "Approve"
                        : "Draft",
                      subscriptionCurrentPeriodEnd: newState.startDate.format(),
                      subscriptionInterval: newState.subscriptionInterval,
                      reverseVAT: newState.chargeFirst,
                    });

                    return response.ok;
                  }}
                />
              </div>
            </Panel.Body>
          </Panel>
        )}

        {!hideSendPanel ? (
          <Panel
            className="backgroundlineargradient3"
            style={{ border: "1px solid #9DE99F" }}
            eventKey="2"
          >
            <Panel.Heading>
              <Panel.Title toggle>
                {AccordionUtils.renderAccordionHeader(
                  <span>
                    <Icon glyph="icon-fontello-mail-1" />
                    &nbsp;
                    {isRegistration ? (
                      <>
                        <AxoLocal
                          entity="axoEntityidcode183"
                          defaultValue={" Send tilmeldingslinks"}
                        />
                      </>
                    ) : (
                      <AxoLocal
                        entity="paymentDate25a"
                        defaultValue={"Send opkrævning til klienter"}
                      />
                    )}
                  </span>
                )}
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible className="LexAccordionPanelody">
              <SendingPanelBody />
            </Panel.Body>
          </Panel>
        ) : null}
      </LexPanelGroup>
    );
  }
}
