import React from "react";
import MicroTinyMCE from "./MicroTinyMCE";
import { AxoLocal } from "../../utilities/LexUtilities";

import { Button } from "react-bootstrap";

export default class InlineMicroTinyMCE extends React.PureComponent {
  //Props
  //text
  //onSave
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      text: props.text,
    };
  }

  onEdit = () => {
    this.setState({ editing: true });
  };

  onSave = () => {
    this.props.onSave(this.state.text);
    this.setState({ editing: false });
  };

  onChange = (content) => {
    this.setState({ text: content });
  };

  render() {
    if (!this.state.editing) {
      return (
        <div className="text-left" style={{ position: "relative" }}>
          <div>
            <div dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
            {/* <div dangerouslySetInnerHTML={ {__html: this.props.text} }></div>
            <AxoFrame
              bottomPadding={35}
              content={this.props.text}
            /> */}
          </div>
          <div
            onClick={this.onEdit}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></div>
        </div>
      );
    }
    return (
      <div style={{ marginLeft: "30px", marginRight: "30px" }}>
        <MicroTinyMCE
          text={this.state.text}
          locale={this.props.locale}
          height={this.props.height}
          onTextChange={this.onChange}
        />
        <Button
          onClick={this.onSave}
          style={{ width: "100%" }}
          className="Lex-button-2"
        >
          <AxoLocal
            entity="SidebarRightContainerSaveCalendarEvent"
            defaultValue={"Gem"}
          />
        </Button>
      </div>
    );
  }
}
