import React from "react";
import CalendarEventBoxForm from "./CalendarEventBoxForm";
import DataStore from "../../services/DataAccess/DataStore";
import ApiService from "../../services/DataAccess/ApiService";
import RoutingService from "../../services/RoutingService";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";
import { Icon, AxoLocal } from "../../utilities/LexUtilities";

export default class CreateCalendarEventModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      calendarEvent: {
        title: "",
        allDay: false,
        bookingTime: false,
        bookingCapacity: 1,
        start: new Date(),
        end: new Date(),
        description: "",
        isDeadline: false,
        color: "",
      },
      showModal: false,
      showTitleWarning: false,
      eventSaveFailed: false,
    };
  }

  close = () => {
    this.setState({
      showModal: false,
      showTitleWarning: false,
      eventSaveFailed: false,
    });
  };

  open = (event) => {
    this.setState({
      showModal: true,
      showTitleWarning: false,
      eventSaveFailed: false,
      calendarEvent: {
        ...event,
        isDeadline: event.deadlineCaseId || event.deadlineDocumentId,
      },
    });
  };

  onOK = () => {
    var calendarEvent = this.state.calendarEvent;
    if (!calendarEvent.title) {
      this.setState({ showTitleWarning: true });
      return;
    }
    this.saveEvent()
      .then((response) => {
        if (response.ok) {
          this.close();
        } else {
          this.setState({
            eventSaveFailed: true,
          });
        }
      })
      .catch(() => {
        this.setState({
          eventSaveFailed: true,
        });
      });
  };

  saveEvent = () => {
    var calendarEvent = this.state.calendarEvent;
    if (this.state.calendarEvent.id) {
      return this.props.onUpdateEvent(this.state.calendarEvent);
    } else if (calendarEvent.isDeadline) {
      if (calendarEvent.deadlineCaseId) {
        return this.props.onUpdateCaseDeadline(
          calendarEvent.deadlineCaseId,
          calendarEvent.start
        );
      } else {
        return this.props.onUpdateDocumentDeadline(
          calendarEvent.deadlineDocumentId,
          calendarEvent.start
        );
      }
    } else {
      return this.props.onCreateCalendarEvent(this.state.calendarEvent).then();
    }
  };

  onDelete = () => {
    if (this.state.calendarEvent.id) {
      this.props.onDelete(this.state.calendarEvent.id);
    }
    this.close();
  };

  onCalendarEventUpdate = (calendarEvent) => {
    this.setState({ calendarEvent: calendarEvent });
  };

  getTitle = () => {
    if (this.state.calendarEvent.id) {
      return (
        <span>
          <AxoLocal
            entity="CreateCalendarEventModalcalendarEvent"
            defaultValue={"Rediger begivenhed"}
          />
        </span>
      );
    } else if (this.state.calendarEvent.isDeadline) {
      return (
        <AxoLocal
          entity="CreateCalendarEventModalEditdeadline"
          defaultValue={"Rediger deadline"}
        />
      );
    } else {
      return (
        <AxoLocal
          entity="CreateCalendarEventModalCreateevent"
          defaultValue={"Opret begivenhed"}
        />
      );
    }
  };

  getBookingUrl = () => {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/Booking/" +
      this.props.userProfile.userId
    );
  };

  sendBookingLink = () => {
    this.saveEvent();
    DataStore.setCachedMessage({
      sender: "",
      receiver: "",
      externalMailReceiver: "",
      subject: "",
      messageText:
        (
          <AxoLocal
            entity="CreateCalendarEventsendBookingLink"
            defaultValue={"Du kan booke tid hos mig på denne adresse:"}
          />
        ) +
        this.getBookingUrl() +
        "</p>",
      draft: true,
      attachments: [],
    });
    this.props.history.push(RoutingService.getPath("mailbox/compose"));
  };

  onShare = (shareAll, shareWithGroup, userId) => {
    var { userGroup, userProfile } = this.props;
    var { calendarEvent } = this.state;

    if (!shareWithGroup && !userId) {
      return Promise.reject("No selected receiver");
    }

    if (!shareAll && !calendarEvent.id) {
      return Promise.reject("No selected clients");
    }

    var promises = [];
    if (shareAll) {
      if (shareWithGroup) {
        promises.push(
          ApiService.createSharingGlobal({
            senderId: userProfile.id,
            userGroupId: userGroup.id,
            type: 1, // Calendar
          })
        );
      }
      if (userId) {
        promises.push(
          ApiService.createSharingGlobal({
            senderId: userProfile.id,
            receiverId: userId,
            type: 1, // Calendar
          })
        );
      }
    } else {
      if (shareWithGroup) {
        promises.push(
          ApiService.createSingleSharing({
            calendarEventId: calendarEvent.id,
            userGroupId: userGroup.id,
          })
        );
      }
      if (userId) {
        promises.push(
          ApiService.createSingleSharing({
            calendarEventId: calendarEvent.id,
            userProfileId: userId,
          })
        );
      }
    }
    return Promise.all(promises).then((responses) => {
      DataStore.fetchCalendarEvents();
      DataStore.fetchSharingGlobals();

      this.close();

      return responses;
    });
  };

  onStopSharing = (calendarEvent) => {
    var { sharingGlobals } = this.props;

    var promises = [];
    sharingGlobals.forEach((s) => {
      promises.push(ApiService.deleteSharingGlobal(s.id));
    });
    calendarEvent.singleSharings.forEach((s) => {
      promises.push(ApiService.deleteSingleSharing(s.id));
    });
    Promise.all(promises).then((responses) => {
      DataStore.fetchCalendarEvents();
      DataStore.fetchSharingGlobals();

      this.close();

      return responses;
    });
  };

  render() {
    var { userProfile, userGroup, friends, sharingGlobals } = this.props;
    var { calendarEvent } = this.state;

    var readOnly =
      calendarEvent.userName && calendarEvent.userName !== userProfile.userName;

    let shared = false;
    //Document and case deadlines cannot be shared
    if (!readOnly && calendarEvent.userName) {
      shared =
        (sharingGlobals && sharingGlobals.length > 0) ||
        (calendarEvent.singleSharings &&
          !!calendarEvent.singleSharings.find(
            (s) => s.userProfileId !== userProfile.id
          ));
    }

    return (
      <Modal show={this.state.showModal} onHide={this.close} bsSize="large">
        {!readOnly ? (
          <Modal.Header
            className="backgroundModalHeaderFooter"
            style={{
              borderStyle: "none none solid none",
              borderWidth: "0px 0px 3px 0px",
              borderColor: "#e6e6e6",
            }}
            closeButton
          >
            <Modal.Title>{this.getTitle()}</Modal.Title>
          </Modal.Header>
        ) : null}
        <Modal.Body
          style={{
            borderStyle: "none none solid none",
            borderWidth: "0px 0px 3px 0px",
            borderColor: "#e6e6e6",
            paddingBottom: "0px",
          }}
        >
          <CalendarEventBoxForm
            calendarEvent={calendarEvent}
            onCalendarEventUpdate={this.onCalendarEventUpdate}
            userProfile={userProfile}
            userGroup={userGroup}
            friends={friends}
            shared={shared}
            onShare={this.onShare}
            onStopSharing={this.onStopSharing}
            showButtons={false}
            readOnly={readOnly}
          />
        </Modal.Body>
        {!readOnly ? (
          <Modal.Footer className="backgroundModalHeaderFooter">
            <div className="text-center">
              <ButtonToolbar style={{ display: "inline-block" }}>
                <Button onClick={this.onOK} className="Lex-button-2">
                  <span>
                    <Icon glyph="icon-fontello-floppy-1" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="CaseGridViewcaseSave"
                      defaultValue={"Gem"}
                    />{" "}
                  </span>
                </Button>
                <Button onClick={this.close} className="Lex-button-2">
                  <span>
                    &#x21bb; &nbsp; &nbsp;
                    <AxoLocal
                      entity="axoAccounting22"
                      defaultValue={"Fortryd"}
                    />{" "}
                  </span>
                </Button>
                {/* {calendarEvent.bookingTime ? <Button className='Lex-button-2' onClick={this.sendBookingLink}>
                    <Icon glyph="icon-fontello-link-3"/>&nbsp; &nbsp;
                    Gem og send tilmeldingslink
                    <AxoLocal entity='CreateCalendarEventModalCreateeventLink'defaultValue={'Gem og send booking link'}/>
                  </Button> : null } */}
                {calendarEvent.id ? (
                  <Button onClick={this.onDelete} className="Lex-button-2">
                    <Icon glyph="icon-fontello-trash-1" />
                    &nbsp; &nbsp;
                    <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                  </Button>
                ) : null}
              </ButtonToolbar>
              {this.state.showTitleWarning ? (
                <div className="axored">
                  <b>
                    <AxoLocal
                      entity="CreateCalendarEventModalshowTitleWarning"
                      defaultValue={"Begivenheden skal have en titel"}
                    />
                  </b>{" "}
                </div>
              ) : null}
              {this.state.eventSaveFailed ? (
                <div className="axored">
                  <b>
                    <AxoLocal
                      entity="CreateCalendarEventModaleventSaveFailed"
                      defaultValue={
                        "Begivenheden kunne ikke gemmes. Prøv igen senere."
                      }
                    />
                  </b>{" "}
                </div>
              ) : null}
            </div>
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }
}
