import React from "react";
import PropTypes from "prop-types";
import EmploymentContractFormView from "./EmploymentContractFormView";
import EmploymentContractList from "./List/EmploymentContractList";
import ManagementContractlList from "./List/ManagementContractlList";
import OfficeWorkList from "./List/OfficeWorkList";
import FlexibleContractList from "./List/FlexibleContractList";

import { DataActions } from "../services/AxoServices";

import { withRouter, LoadingIndicator } from "../utilities/LexUtilities";

class EmploymentContractDetailsView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };
  }

  static propTypes = {
    models: PropTypes.array.isRequired,
  };

  onSubmit = async (model) => {
    let response = await DataActions.updateEmploymentContract(model);

    if (!response.ok) {
      return false;
    }

    this.setState({ editing: false });
  };

  onCancel = () => {
    this.setState({ editing: false });
  };

  render() {
    let { match, models } = this.props;

    let { editing } = this.state;

    let id = parseInt(match.params.id, 10);
    let model = models.find((a) => a.id === id);

    if (!model) {
      return <LoadingIndicator />;
    }

    if (editing) {
      return (
        <EmploymentContractFormView
          key={model.id}
          startValue={model}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      );
    }
    switch (model.contractType) {
      case "EmploymentContract":
        return (
          <EmploymentContractList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
      case "ManagementContract":
        return (
          <ManagementContractlList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
      case "OfficeWork":
        return (
          <OfficeWorkList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
      case "FlexibleContract":
        return (
          <FlexibleContractList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );

      default:
        return (
          <EmploymentContractList
            model={model}
            onEdit={() => this.setState({ editing: true })}
          />
        );
    }
  }
}

export default withRouter(EmploymentContractDetailsView);
