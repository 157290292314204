import moment from "moment";

const FilterTypes = {
  TEXT: 0,
  ACCOUNT: 1,
  NUMBER: 2,
  DATE: 3,
};

const TextFilterTypes = {
  EQUALS: 0,
  NOTEQUEALS: 1,
  STARTSWITH: 2,
  ENDSWITH: 3,
  CONTAINS: 4,
  NOTCONTAINS: 5,
};

class TextFilter {
  constructor(textFilter) {
    this.options = [];
    this.selectedOptions = [];

    this.filterString = "";
    this.filterStringType = TextFilterTypes.EQUALS;

    if (!!textFilter) {
      Object.assign(this, textFilter);
      return;
    }
  }
}

const NumberFilterTypes = {
  NONE: 0,
  EQUALS: 1,
  NOTEQUEALS: 2,
  GREATERTHAN: 3,
  GREATERTHANEQUALS: 4,
  LESSTHAN: 5,
  LESSTHANEQUALS: 6,
  BETWEEN: 7,
};

class NumberFilter {
  constructor(filter) {
    this.options = [];
    this.selectedOptions = [];

    this.filterNumber = 0;
    this.filterNumberType = NumberFilterTypes.NONE;

    if (!!filter) {
      Object.assign(this, filter);
      return;
    }
  }
}

const DateFilterTypes = {
  NONE: 0,
  EQUALS: 1,
  BEFORE: 2,
  BEFOREORSAME: 3,
  AFTER: 4,
  AFTERORSAME: 5,
  BETWEEN: 6,
};

class DateFilter {
  constructor(filter) {
    this.options = [];
    this.selectedOptions = [];

    this.filterMonth = -1;

    this.filterMonthArray = [];

    this.filterDate = moment.utc();
    this.filterDateType = DateFilterTypes.NONE;

    if (!!filter) {
      Object.assign(this, filter);
      return;
    }
  }
}

export {
  FilterTypes,
  TextFilterTypes,
  TextFilter,
  NumberFilterTypes,
  NumberFilter,
  DateFilterTypes,
  DateFilter,
};
